import React from 'react';
import PropTypes from 'prop-types';

// Actions
import { doLogout } from 'actions/Auth';
import { changeDispatcherPassword } from 'api/Setters';

// Components
import ChangePasswordModal from '../view/ChangePasswordModal';

class ChangePassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePasswordModal: {
        show: false,
      },
      isPasswordChanged: false, // if the password was just changed or not
    };
    this.closeModal = this.closeModal.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.userObjectId !== this.props.userObjectId) {
      return true;
    }
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  closeModal() {
    this.setState({ ...this.state, changePasswordModal: { show: false } });
  }

  changePassword(passwordInfo) {
    const promise = new Promise((resolve, reject) => {
      changeDispatcherPassword(this.props.userObjectId, passwordInfo.nextPassword).then(
        () => {
          this.setState({ ...this.state, changePasswordModal: { show: false }, isPasswordChange: true });
          doLogout();
        },
        (error) => reject(error)
      );
    });
    return promise;
  }

  render() {
    return (
      <div className="inline">
        <ChangePasswordModal
          show={this.state.changePasswordModal.show}
          handleClose={this.closeModal}
          changePasswordFormHandler={this.changePassword}
        />
        {!this.state.isPasswordChanged ?
          <button
            className="buttonDefault backgroundRedMaple translate-me"
            onClick={() => this.setState({ ...this.state, changePasswordModal: { show: true } })}
            disabled={!this.props.userObjectId}
          >
            Change Password
          </button>
          :
          <button className="buttonDefault translate-me" disabled={this.state.isPasswordChanged}>Password Changed</button>
        }
      </div>
    );
  }
}

ChangePassword.propTypes = {
  userObjectId: PropTypes.string,
};

export default ChangePassword;
