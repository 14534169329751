import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table } from 'react-bootstrap';

// API
import { fetchDriverReceiptsForState, deleteDriverReceiptsForState } from 'actions/Receipt';
import { scrolledToBottom } from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import SortButton from 'components/SortButton/view/SortButton';
import DriverReceiptsInfoTableBody from './DriverReceiptsInfoTableBody';

// Context
import StoreContext from 'contexts/StoreContext';

class DriverReceiptsInfoTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receiptBodies: [],
      reveal: {},
      sort: {},
    };
    this.scrollHandler = this.scrollHandler.bind(this);
    this.sort = this.setSort.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.revealRow = this.revealRow.bind(this);
    this.addToReceiptsToDownload = this.addToReceiptsToDownload.bind(this);
  }

  componentDidMount() {
    this.receiptsTableHeight = document.documentElement.clientHeight * 0.65; // 65vh defined by CSS
    this.receiptsTableRowAmount = Math.round(this.receiptsTableHeight / 43) + 5; // amount of el's to display
    this.pageCount = 0; // result fetch multiplier
    fetchDriverReceiptsForState(
      this.props.driverObjectId,
      this.pageCount,
      this.receiptsTableRowAmount,
      this.props.filter,
      this.state.sort
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.driverObjectId !== this.props.driverObjectId) {
      this.pageCount = 0;
      fetchDriverReceiptsForState(
        nextProps.driverObjectId,
        this.pageCount,
        this.receiptsTableRowAmount,
        this.props.filter,
        this.state.sort
      );
    }

    if (nextProps.filter !== this.props.filter) {
      deleteDriverReceiptsForState().then(() => {
        this.pageCount = 0;
        fetchDriverReceiptsForState(
          nextProps.driverObjectId,
          this.pageCount,
          this.receiptsTableRowAmount,
          nextProps.filter,
          this.state.sort
        );
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    }
    if (this.props.Receipt !== nextProps.Receipt) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    deleteDriverReceiptsForState();
  }

  setSort(attribute) {
    const newState = { ...this.state };
    const currentSort = this.state.sort[attribute]; // the current sort, if defined
    if (currentSort) {
      if (currentSort === 'ascending') {
        newState.sort[attribute] = 'descending';
      } else {
        newState.sort[attribute] = 'ascending';
      }
    }
    else if (attribute === 'receiptCategory_type') {
      newState.sort = { receiptCategory_type: 'ascending' };
    }
    else if (attribute === 'createdAt') {
      newState.sort = { createdAt: 'ascending' };
    }
    this.sortHandler(newState.sort);
    this.setState(newState);
  }

  scrollHandler(e) {
    if (scrolledToBottom(e, 10, this.lastScrollPos)) {
      this.pageCount++;
      fetchDriverReceiptsForState(
        this.props.driverObjectId,
        this.pageCount,
        this.receiptsTableRowAmount,
        this.props.filter,
        this.state.sort
      );
      this.lastScrollPos = e.target.scrollTop;
    }
  }

  sortHandler(sortBy) {
    deleteDriverReceiptsForState().then(() => {
      this.pageCount = 0;
      fetchDriverReceiptsForState(
        this.props.driverObjectId,
        this.pageCount,
        this.receiptsTableRowAmount,
        this.props.filter,
        sortBy
      );
    });
  }

  revealRow(receiptId) {
    const newState = { ...this.state };
    newState.reveal[receiptId] = !newState.reveal[receiptId];
    this.setState(newState);
  }

  addToReceiptsToDownload(receipt) {
    this.props.receiptsToDownload(receipt);
  }

  render() {
    const { Receipt } = this.props;
    this.state.receiptBodies = Receipt.receipts.map(receipt => {
      return (
        <DriverReceiptsInfoTableBody
          key={receipt.id}
          receipt={receipt}
          reveal={this.state.reveal[receipt.id]}
          revealHandler={() => this.revealRow(receipt.id)}
          receiptsToDownload={this.addToReceiptsToDownload}
        />
      );
    });

    return (
      <div className={`subTable subTableMaxHeight ${this.props.className}`} onScroll={(e) => this.scrollHandler(e)}>
        <Table responsive hover>
          <thead>
            <tr>
              <th width="5%" />
              <th width="15%">
                Category
                <SortButton
                  clickHandler={() => this.setSort('receiptCategory_type')}
                  isActive={this.state.sort.receiptCategory_type}
                />
              </th>
              <th width="15%">
                Received On
                <SortButton
                  clickHandler={() => this.setSort('createdAt')}
                  isActive={this.state.sort.createdAt}
                />
              </th>
              <th width="12%">Truck</th>
              <th width="12%">Trailer</th>
              <th width="12%">Chassis</th>
              <th width="12%">Amount</th>
              <th width="12%" />
              <th width="5%" />
            </tr>
          </thead>
          {Receipt.fetched && Receipt.receipts.length === 0 &&
            <tbody>
              <tr><td colSpan="9" className="emptyRowMessage">No Receipts Added</td></tr>
            </tbody>
          }
          {this.state.receiptBodies}
          {!Receipt.fetched &&
            <tbody>
              <tr><td colSpan="9" className="loadingIconPadding"><LoadingIcon /></td></tr>
            </tbody>
          }
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { Receipt } = state;
  return {
    Receipt,
  };
};

DriverReceiptsInfoTable.propTypes = {
  className: PropTypes.string,
  Receipt: PropTypes.object,
  driverObjectId: PropTypes.string,
  filter: PropTypes.object,
  receiptsToDownload: PropTypes.func,
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(DriverReceiptsInfoTable);

