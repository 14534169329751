export const LocationDirectionTypes = Object.freeze({
  'N': 'N',
  'E': 'E',
  'S': 'S',
  'W': 'W',
  'NW': 'NW',
  'SE': 'SE',
  'SW': 'SW',
  'NW': 'NW',
  'NNE': 'NNE',
  'ENE': 'ENE',
  'ESE': 'ESE',
  'SSE': 'SSE',
  'SSW': 'SSW',
  'WSW': 'WSW',
  'WNW': 'WNW',
  'NNW': 'NNW'
});

export const LocationDirectionTypeDescription = Object.freeze({
  N: 'North',
  E: 'East',
  S: 'South',
  W: 'West',
  NW: 'North East',
  SE: 'South East',
  SW: 'South West',
  NW: 'North West',
  NNE: 'North North East',
  ENE: 'East North East',
  ESE: 'East South East',
  SSE: 'South South East',
  SSW: 'South South West',
  WSW: 'West South West',
  WNW: 'West North West',
  NNW: 'North North West'
});
