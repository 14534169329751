import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-mapbox-gl';

// API
import { getColour } from 'api/Mapbox';

import styles from './ComponentStyles.module.scss';

function FutureMarker(props) {
  return (
    <Marker
      anchor="center"
      onClick={() => { if (props.handleOnClick) props.handleOnClick(); }}
      offset={[8, -7]} // to make flag look like it's on top
      coordinates={props.coordinates}
    >
      <div><i className={`material-icons md-36 ${styles.pointOfInterest} ${styles.marker}`} style={{ color: props.color ? props.color : getColour() }}>flag</i></div>
    </Marker>
  );
}

FutureMarker.propTypes = {
  coordinates: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func,
};

export default FutureMarker;
