import { findIndexOfObjArr, removeDuplicatesByObjectId } from 'api/Helpers';
import * as ActionConstants from '../actions/ActionConstants';

const initialState = {
  vehicles: [],
  fetched: false, // Whether or not drivers are loaded
};

function disableVehicles(stateVehicles, vehiclesToBeDeleted) {
  const newStateVehicles = [].concat(stateVehicles);
  if (newStateVehicles) {
    const vehiclesToBeDeletedLen = vehiclesToBeDeleted.length;
    for (let i = 0; i < vehiclesToBeDeletedLen; i++) {
      const index = findIndexOfObjArr(newStateVehicles, 'id', vehiclesToBeDeleted[i].id);
      newStateVehicles.splice(index, 1);
    }
  }
  return newStateVehicles;
}

export function Vehicle(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_VEHICLES_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.FETCH_VEHICLES_INPROGRESS,
        fetched: false,
        error: undefined,
      };
    }
    case ActionConstants.FETCH_VEHICLES_SUCCESS: {
      return {
        status: ActionConstants.FETCH_VEHICLES_SUCCESS,
        vehicles: removeDuplicatesByObjectId(state.vehicles.concat(action.vehicles)),
        error: undefined,
        fetched: true,
      };
    }
    case ActionConstants.FETCH_VEHICLES_ERROR: {
      return {
        ...state,
        status: ActionConstants.FETCH_VEHICLES_ERROR,
        error: action.error,
        fetched: false,
      };
    }
    case ActionConstants.DELETE_VEHICLES: {
      return {
        ...initialState,
        status: action.type,
      };
    }
    case ActionConstants.ADD_VEHICLE_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.ADD_VEHICLE_INPROGRESS,
        error: undefined,
      };
    }
    case ActionConstants.ADD_VEHICLE_SUCCESS: {
      // Succesfully added to db
      const updatedVehicles = [].concat(state.vehicles);
      updatedVehicles.unshift(action.vehicle);
      return {
        ...state,
        status: ActionConstants.ADD_VEHICLE_SUCCESS,
        vehicles: updatedVehicles,
        error: undefined,
      };
    }
    case ActionConstants.ADD_VEHICLE_ERROR: {
      return {
        ...state,
        status: ActionConstants.ADD_VEHICLE_ERROR,
        error: action.error,
      };
    }
    case ActionConstants.UPDATE_VEHICLE_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.UPDATE_VEHICLE_INPROGRESS,
        updateReference: action.vehicle,
        error: undefined,
      };
    }
    case ActionConstants.UPDATE_VEHICLE_SUCCESS: {
      return {
        ...state,
        status: ActionConstants.UPDATE_VEHICLE_SUCCESS,
        vehicles: action.vehicles ? action.vehicles : state.vehicles,
        updateReference: action.updatedVehicle,
        error: undefined,
      };
    }
    case ActionConstants.UPDATE_VEHICLE_ERROR: {
      return {
        ...state,
        status: ActionConstants.UPDATE_VEHICLE_ERROR,
        error: action.error,
      };
    }
    case ActionConstants.DISABLE_VEHICLES_SUCCESS: {
      return {
        ...state,
        status: ActionConstants.DISABLE_VEHICLES_SUCCESS,
        vehicles: disableVehicles(state.vehicles, action.vehicles),
        error: undefined,
      };
    }
    case ActionConstants.ENABLE_VEHICLES_SUCCESS: {
      const updatedVehicles = [].concat(...state.vehicles);
      updatedVehicles.unshift(...action.vehicles);
      return {
        ...state,
        status: ActionConstants.ENABLE_VEHICLES_SUCCESS,
        vehicles: updatedVehicles,
        error: undefined,
      };
    }
    default:
      return state;
  }
}
