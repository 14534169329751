// @deprecated in favour of JobStatusDropdown
import React from 'react';
import PropTypes from 'prop-types';

import SBEditButton from 'components/Shared/SBEditButton/SBEditButton';

import { MDBBadge } from 'mdbreact';

// Enums
import { Status } from 'sb-csapi/dist/enums/Dispatch/Job';

// CSS
import './style.scss';

class JobStatusBadge extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { containerClassName, className, status, showEditButton, showCancelEditButton, handleEdit } = this.props;

    let _containerClassName = 'sb-job-status-badge-container';
    if (containerClassName) _containerClassName += ` ${containerClassName}`;

    let _className = 'sb-job-status-badge';
    if (showEditButton || showCancelEditButton) {
      _className = 'd-inline-block ' + _className;
    }

    // figure out the value of StatusType{} from the given statusType
    let matchingStatusTypeObject = {};
    Object.keys(Status).find(key => {
      const statusTypeObject = Status[key];
      const isMatchingStatus = statusTypeObject.status === status;

      if (isMatchingStatus) {
        // find the matching background color className (ex. SOME_VALUE -> some-value -> bg-dispatch-job-some-value)
        const colorClassKeyExpression = key.replace(/_/g, '-').toLowerCase();
        _className += ` bg-dispatch-job-${colorClassKeyExpression}`;

        matchingStatusTypeObject = statusTypeObject;
      }
      return isMatchingStatus;
    });

    // add any final classNames (we do the assign here so the user can overwrite our own properties on className)
    if (className) _className += ` ${className}`;

    return (
      <div className={_containerClassName}>
        { matchingStatusTypeObject &&
          <React.Fragment>
            <MDBBadge className={_className}>{ matchingStatusTypeObject.description }</MDBBadge>
            { showEditButton &&
              <SBEditButton containerClassName="align-middle" showCancelButton={showCancelEditButton} onClick={(e) => handleEdit(e)} />
            }
          </React.Fragment>
        }
      </div>
    );
  }
}

JobStatusBadge.defaultProps =  {
  handleEdit: (e) => { console.log(e) }
};

JobStatusBadge.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  status: PropTypes.number.isRequired, // ex. 0, or preferrably StatusType.CONST_NAME
  showEditButton: PropTypes.bool,
  showCancelEditButton: PropTypes.bool,
  handleEdit: PropTypes.func,
};

export default JobStatusBadge;
