import history from 'sbHistory';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// API
import { isSubscribedToModule } from 'api/Helpers';
import { getCurrentUser, getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import PersistentDrawer from 'sbCore/PersistentDrawer/PersistentDrawer';
import VisionSidebar from 'components/Vision/VisionSidebar/VisionSidebar';

// Layouts
import VisionVehicleLayout from 'layouts/Vision/VisionVehicleLayout/VisionVehicleLayout';

// CSS
import './style.scss';

/**
 * @description Initial entry point to Vision module
 * @param {object} props.match
 */
function VisionLayout(props) {
  const subroutes = {
    VEHICLE: 'vehicle',
    SETTINGS: 'settings',
  };

  const [currentRoute, setCurrentRoute] = useState(undefined);
  const [unitId, setUnitId] = useState(props.match.params.vehicleUnitId);
  const [visionEvent, setVisionEvent] = useState(undefined);

  useEffect(() => {
    // Initial useEffect that runs on mount - checks for subscription
    if (!isSubscribedToModule('visionModule')) history.push('/404');
    document.title = 'Vision Dashcams - Switchboard';

    const currentUser = getCurrentUser();
    const userSpecialPermission = getAttribute(currentUser, 'userSpecialPermission');
    // if (userSpecialPermission && getAttribute(userSpecialPermission, 'disableVisionModule')) {
    //   history.push({ pathname: 'home' });
    // }
  }, []);

  useEffect(() => {
    // Retrieve the params from the URL
    setUnitId(props.match.params.vehicleUnitId);
    setCurrentRoute(subroutes[props.match.params.subroute ? props.match.params.subroute.toUpperCase() : 'vehicle']);
  }, [props]);

  useEffect(() => {
    // Re-route to correct URL when the unitId is updated
    if (unitId) history.push(`/vision/vehicle/${unitId}`);
  }, [unitId]);

  return (
    <div className="vision-layout flex">
      <PersistentDrawer
        width="350px"
      >
        <VisionSidebar
          unitId={unitId}
          setSelectedEquipment={(equipmentType, unitId) => setUnitId(unitId)}
        />
      </PersistentDrawer>
      <div className="w-full flex justify-content-center">
        {(!currentRoute || (currentRoute === subroutes.VEHICLE)) && (
          <VisionVehicleLayout
            unitId={unitId}
            visionEvent={visionEvent}
            getSelectedVisionEvent={((visionEvent) => setVisionEvent(visionEvent))}
          />
        )}

        {(currentRoute === subroutes.SETTINGS) && (
          <>
            {/* Future Settings layout */}
          </>
        )}
      </div>
    </div>
  );
}

VisionLayout.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};


export default VisionLayout;
