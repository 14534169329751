import * as ActionConstants from './ActionConstants';
import { fetchSubscriptionSuccess } from 'actions/Subscription';
import store from '../store';
import Parse from 'parse';

import * as Setters from '../api/Setters';
import * as Getters from '../api/Getters';
import * as ParseHelpers from '../api/Parse';

import { addChatFriend } from '../actions/Chat';

export function fetchingDrivers() {
  return {
    type: ActionConstants.FETCH_DRIVERS_INPROGRESS,
    fetched: false,
  };
}

export function fetchDriversSuccess(drivers) {
  return {
    type: ActionConstants.FETCH_DRIVERS_SUCCESS,
    drivers,
  };
}

export function fetchDriversError() {
  return {
    type: ActionConstants.FETCH_DRIVERS_ERROR,
  };
}

export function deleteDrivers() {
  return {
    type: ActionConstants.DELETE_DRIVERS,
  };
}

export function addingDriver() {
  return {
    type: ActionConstants.ADD_DRIVER_INPROGRESS,
  };
}

export function addDriverSuccess(driver) {
  return {
    type: ActionConstants.ADD_DRIVER_SUCCESS,
    driver,
  };
}

export function addDriverError(error) {
  return {
    type: ActionConstants.ADD_DRIVER_ERROR,
    error,
  };
}

export function updatingDriver(driver) {
  return {
    type: ActionConstants.UPDATE_DRIVER_INPROGRESS,
    driver,
  };
}

export function updateDriverSuccess(driver) {
  return {
    type: ActionConstants.UPDATE_DRIVER_SUCCESS,
    driver,
  };
}

export function updateDriverError(driver, error) {
  return {
    type: ActionConstants.UPDATE_DRIVER_ERROR,
    driver,
    error,
  };
}

export function disableDriversSuccess(drivers) {
  return {
    type: ActionConstants.DISABLE_DRIVERS_SUCCESS,
    drivers,
  };
}

export function enableDriversSuccess(drivers) {
  return {
    type: ActionConstants.ENABLE_DRIVERS_SUCCESS,
    drivers,
  };
}

export function disableDriver(driverParseObj) {
  const promise = new Promise((resolve, reject) => {
    driverParseObj.set('enabled', false).save().then(
      driverObject => {
        store.dispatch(disableDriversSuccess([driverObject]));
        Parse.Cloud.run('syncDriverAndSubscription').then((subscriptionParseObj) => {
          store.dispatch(fetchSubscriptionSuccess(subscriptionParseObj));
        });
        resolve();
      },
    );
  });
  return promise;
}

export function enableDriver(driverParseObj) {
  const promise = new Promise((resolve, reject) => {
    const enabled = driverParseObj.get('enabled');
    driverParseObj.set('enabled', true).save().then(
      driverObject => {
        store.dispatch(enableDriversSuccess([driverObject]));
        Parse.Cloud.run('syncDriverAndSubscription').then((subscriptionParseObj) => {
          store.dispatch(fetchSubscriptionSuccess(subscriptionParseObj));
        });
        resolve();
      },
      error => {
        driverParseObj.set('enabled', enabled); // set it back to what it was
        reject(error);
      }
    );
  });
  return promise;
}

export function deleteDriver(driverParseObj) {
  const promise = new Promise((resolve, reject) => {
    driverParseObj.set('enabled', false);
    driverParseObj.set('isHidden', true).save().then(
      driverObject => {
        store.dispatch(disableDriversSuccess([driverObject]));
        Parse.Cloud.run('syncDriverAndSubscription').then((subscriptionParseObj) => {
          store.dispatch(fetchSubscriptionSuccess(subscriptionParseObj));
        });
        resolve();
      },
    );
  });
  return promise;
}

// export function fetchDriverUsersForState(page, limit, filter, view, sortBy) {
//   // page = starting position. should iterate from 1++
//   // limit = how much to display on given page/container
//   // filter = a filter on the driver's attributes (ex. find drivers with phone number 604...)
//   // view = a filter on the subset of drivers (ex. only get active drivers)
//   // sortBy = a filter on the ordering/sorting of attributes
//   const promise = new Promise((resolve, reject) => {
//     store.dispatch(fetchingDrivers());
//     Getters.getAllDriverUsers(page, limit, filter, view, sortBy).then(
//       driverUsers => {
//         resolve(driverUsers);
//         store.dispatch(fetchDriversSuccess(driverUsers));
//       },
//       error => {
//         reject(error);
//         store.dispatch(fetchDriversError(error));
//       }
//     );
//   });
//   return promise;
// }

export function getDriverIncludeArr() {
  return ['user', 'driverStatus', 'vehicle', 'vehicle.licensePlate', 'vehicle.eldHardware', 'latestELDEvent', 'belongsToCompany', 'weighStationBypassDriver'];
}

export function getDriversForState(page, limit, filter, sortBy, view, disabled) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingDrivers());
    let filterWithView = [];
    if (filter && filter.length > 0) {
      filterWithView = filterWithView.concat(filter);
    }
    if (view === 'activeDrivers') {
      filterWithView.push({ name: 'driverStatus', queryType: 'matchesQuery', innerQueryClass: 'DriverStatus', innerQueryType: 'equalTo', innerQueryProperty: 'referenceInt', innerQueryValue: 2 });
    } else if (view === 'inactiveDrivers') {
      filterWithView.push({ name: 'driverStatus', queryType: 'matchesQuery', innerQueryClass: 'DriverStatus', innerQueryType: 'notEqualTo', innerQueryProperty: 'referenceInt', innerQueryValue: 2 });
    }
    if (!disabled) {
      filterWithView.push({ name: 'enabled', queryType: 'equalTo', value: true });
    } else {
      filterWithView.push({ name: 'enabled', queryType: 'equalTo', value: false });
    }
    Getters.queryCompanyObjects('Driver', page, limit, filterWithView, sortBy, getDriverIncludeArr())
      .then(drivers => {
        store.dispatch(fetchDriversSuccess(drivers));
        resolve(drivers);
      });
  });
  return promise;
}

export function getLinkedDriversForState(page, limit, filter, sortBy, view, disabled, _company, queryAll) {
  // For CompanyLinks
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingDrivers());
    let company = _company;
    if (!company || !company.id) {
      company = ParseHelpers.getCurrentUser() && ParseHelpers.getCurrentUser().get('belongsToCompany');
      if (!company || !company.id) {
        store.dispatch(fetchDriversSuccess());
        resolve([]);
        return;
      }
    }
    const companyLinkFilter = [
      {
        queryType: 'equalTo',
        name: 'parentCompany',
        value: company,
      }, {
        queryType: 'equalTo',
        name: 'authorized',
        value: true,
      },
    ];
    Getters.queryCompanyObjects('CompanyLink', undefined, undefined, companyLinkFilter, undefined, undefined, false, true).then((companyLinkArr) => {
      const finalFilter = [].concat(filter);
      finalFilter.push({ name: 'isHidden', queryType: 'notEqualTo', value: true });

      if (view === 'activeDrivers') {
        finalFilter.push({ name: 'driverStatus', queryType: 'matchesQuery', innerQueryClass: 'DriverStatus', innerQueryType: 'equalTo', innerQueryProperty: 'referenceInt', innerQueryValue: 2 });
      } else if (view === 'inactiveDrivers') {
        finalFilter.push({ name: 'driverStatus', queryType: 'matchesQuery', innerQueryClass: 'DriverStatus', innerQueryType: 'notEqualTo', innerQueryProperty: 'referenceInt', innerQueryValue: 2 });
      }
      if (!disabled) {
        finalFilter.push({ name: 'enabled', queryType: 'equalTo', value: true });
      } else {
        finalFilter.push({ name: 'enabled', queryType: 'equalTo', value: false });
      }
      // Filter for company and child companies
      const driverQueryPromises = [];
      driverQueryPromises.push(Getters.queryCompanyObjects('Driver', undefined, undefined, finalFilter.concat({ queryType: 'equalTo', name: 'belongsToCompany', value: company }), sortBy, getDriverIncludeArr(), false, true, undefined, queryAll));
      for (let i = 0; i < companyLinkArr.length; i++) {
        driverQueryPromises.push(Getters.queryCompanyObjects('Driver', undefined, undefined, finalFilter.concat({ queryType: 'equalTo', name: 'belongsToCompany', value: companyLinkArr[i].get('childCompany') }), sortBy, getDriverIncludeArr(), false, true, undefined, queryAll));
      }
      // Uses multiple filters because otherwise it overloads the server..?
      Promise.all(driverQueryPromises).then((driverResults) => {
        // Get rid of duplicates
        // const sortedDrivers = drivers.sort((a, b) => b.get('updatedAt') - a.get('updatedAt'));
        // const filteredDrivers = [];
        // const filteredDriverIds = [];
        // for (let i = 0; i < sortedDrivers.length; i++) {
        //   if (filteredDriverIds.indexOf(sortedDrivers[i].get('unitId')) === -1) {
        //     filteredDrivers.push(sortedDrivers[i]);
        //     filteredDriverIds.push(sortedDrivers[i].get('unitId'));
        //   }
        // }
        // store.dispatch(fetchDriversSuccess(filteredDrivers));
        // resolve(filteredDrivers);
        // console.log(driverResults);
        store.dispatch(fetchDriversSuccess([].concat(...driverResults)));
        resolve([].concat(...driverResults));
      }, error => {
        store.dispatch(fetchDriversError(error));
        reject(error);
      });
    }, error => {
      store.dispatch(fetchDriversError(error));
      reject(error);
    });
  });
  return promise;
}

export function fetchActiveELDDriversForState(page, limit, filter, sortBy, view) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingDrivers());
    let filterWithView = [];
    if (filter && filter.length > 0) {
      filterWithView = filterWithView.concat(filter);
    }
    if (view === 'outOfHours') {
      filterWithView.push({ name: 'usDriveTimeLeft', queryType: 'lessThan', value: 0.5 * 3600000 });
    } else if (view === 'hoursAvailable') {
      filterWithView.push({ name: 'usDriveTimeLeft', queryType: 'greaterThan', value: 0.5 * 3600000 });
    }
    filterWithView.push({ name: 'belongsToCompany', queryType: 'equalTo', value: Parse.User.current().get('belongsToCompany') });
    filterWithView.push({ name: 'enabled', queryType: 'equalTo', value: true });
    const includeArr = ['user', 'latestELDEvent', 'latestELDEvent.vehicleLocation'];

    Getters.queryCompanyObjects('Driver', page, limit, filterWithView, sortBy, includeArr).then(
      drivers => {
        store.dispatch(fetchDriversSuccess(drivers));
        resolve(drivers);
      },
      error => {
        store.dispatch(fetchDriversError(error));
        reject(error);
      }
    );
  });
  return promise;
}

export function deleteDriversForState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteDrivers());
    resolve(store.getState().Driver);
  });
  return promise;
}

export function addDriverToState(userInfo, returnDriverObject, sendTextToDriver) {
  // userInfo comes from the values from add driver form
  const promise = new Promise((resolve, reject) => {
    if (userInfo.email) {
      store.dispatch(addingDriver());
      Setters.addDriver(userInfo, returnDriverObject, sendTextToDriver).then(
        driverObject => {
          store.dispatch(addDriverSuccess(driverObject));
          store.dispatch(addChatFriend(driverObject));
          resolve(driverObject);
        },
        error => {
          store.dispatch(addDriverError(error));
          reject(error);
        }
      );
    } else {
      store.dispatch(addingDriver());
      Setters.addDriver(userInfo, returnDriverObject, sendTextToDriver).then(
        driverObject => {
          store.dispatch(addDriverSuccess(driverObject));
          store.dispatch(addChatFriend(driverObject));
          resolve(driverObject);
        },
        error => {
          store.dispatch(addDriverError(error));
          reject(error);
        }
      );
    }
  });
  return promise;
}

export function isDriverBusy() {
  return store.getState().Driver.status === ActionConstants.FETCH_DRIVERS_INPROGRESS;
}

export function updateDriverForState(driver, property, value, sendTextToDriver) {
  store.dispatch(updatingDriver(driver));
  const promise = new Promise((resolve, reject) => {
    // user-based attributes
    if (property === 'password' && value) {
      Setters.resetDriverCustomPassword(driver.get('user').id, value, sendTextToDriver).then(
        userObject => {
          store.dispatch(updateDriverSuccess());
          resolve(userObject);
        },
        error => {
          store.dispatch(updateDriverError(error));
          reject(error);
        }
      );
    } else if (property === 'phoneNumber' || property === 'username') {
      Setters.updateUser(driver.get('user').id, property, value).then(
        userObject => {
          store.dispatch(updateDriverSuccess());
          resolve(userObject);
        },
        error => {
          console.log(error);
          store.dispatch(updateDriverError(error));
          reject(error);
        }
      );
    } else if (property === 'email') { // parse for some reason doesn't let you save email
      driver.set('user_email', value);
      driver.save().then((driverObject) => {
        resolve(driverObject);
        store.dispatch(updateDriverSuccess());
      }).catch((err) => {
        console.log(err);
      });
    } else if (property === 'defaultVehicle') {
      driver.set('defaultVehicle', value);
      if (!value) {
        driver.unset('defaultVehicle');
      }
      driver.save().then((driverObject) => {
        resolve(driverObject);
        store.dispatch(updateDriverSuccess());
      }).catch((err) => {
        console.log(err);
      });
    } else {
      driver.set(property, value).save().then(
        driverObject => {
          store.dispatch(updateDriverSuccess());
          resolve(driverObject);
        },
        error => {
          driver.revert(property);
          driver.set(property, driver.get(property));
          store.dispatch(updateDriverError(error));
          reject(error);
        }
      );
    }
  });
  return promise;
}
