// API
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { formatName } from 'sb-csapi/dist/utils/String';
import { getDispatchDocuments } from 'api/Dispatch/DispatchDocument';

// Enums
import { QueryRestrictionTypes, QuerySortOrderTypes } from 'sb-csapi/dist/enums/Query';
import { AttributeTypes, StatusTypes } from 'enums/DispatchJob';

// Components
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import Button from 'sbCore/Button/Button';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// import './style.scss';

/**
 * @description Generates a table containing the history of dispatch documents for a particular type
 * @param {String} dispatchJobObjectId dispatch job object id
 * @param {Integer} dispatchDocumentTypeInt dispatch document type
 * @param {String} title name of the document table
 */
function DispatchDocumentHistoryTable({ ...props }) {
  const [first, setFirst] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [dispatchTableContents, setDispatchTableContents] = useState([]);
  const [totalDispatchDocumentsCount, setTotalDispatchDocumentsCount] = useState(0);

  const [fetchAttributes, setFetchAttributes] = useState({
    page: 0,
    count: 5,
    sortBy: {
      attribute: AttributeTypes.CREATED_AT,
      order: QuerySortOrderTypes.DESCENDING,
    },
    filters: [
      new Filter(QueryRestrictionTypes.EXISTS, 'file'),
      new Filter(QueryRestrictionTypes.EQUAL_TO, 'type', props.dispatchDocumentTypeInt),
      new Filter(QueryRestrictionTypes.EQUAL_TO, 'dispatchJob', props.dispatchJobObjectId),
    ],
  });

  async function getDispatchDocumentTableContents() {
    if (!props.dispatchJobObjectId) {
      setTotalDispatchDocumentsCount(0);
      setDispatchTableContents([]);
      setIsLoading(false);

      return;
    }

    try {
      const { totalDispatchDocumentsCount, dispatchDocuments } = await getDispatchDocuments(
        undefined,                          // options - default
        fetchAttributes.filters,            // filters
        undefined,                          // sort - default
        ['uploadedBy'],                     // includes
        undefined,                          // selects
        fetchAttributes.page,               // page
        fetchAttributes.count,              // limit
        false                               // query all
      );

      // Now that we have the DispatchDocuments - convert it to a format that is usable to render the table
      const promises = dispatchDocuments.map(async (dispatchDocument) => {
        if (!dispatchDocument) return {};

        const dispatchDocumentObjectId = getAttribute(dispatchDocument, 'objectId');
        const createdAt = getAttribute(dispatchDocument, 'createdAt', true);
        const uploadedAt = (createdAt && moment(createdAt).format('DD-MM-YYYY HH:mm:ss')) || '-';

        const uploadedBy = getAttribute(dispatchDocument, 'uploadedBy', true);
        const uploadedByFirstName = uploadedBy && getAttribute(uploadedBy, 'firstName', true);
        const uploadedByLastName = uploadedBy && getAttribute(uploadedBy, 'firstName', true);
        const uploadedByFullName = (uploadedByFirstName && uploadedByLastName) ? formatName(`${uploadedByFirstName} ${uploadedByLastName}`) : '-';

        const file = getAttribute(dispatchDocument, 'file');
        const fileURL = file && file.url();

        return ({
          dispatchDocumentObjectId,
          uploadedAt,
          uploadedByFullName,
          fileURL,
        });
      });

      const tableInformation = await Promise.all(promises);
      setTotalDispatchDocumentsCount(totalDispatchDocumentsCount);
      setDispatchTableContents(tableInformation);
      setIsLoading(false);
    } catch (err) {
      setTotalDispatchDocumentsCount(0);
      setDispatchTableContents([]);
      setIsLoading(false);
    }
  }

  // UseEffect triggers whenever there is a change in the fetchAttributes (e.g., page change) or if there is a change in the dispatchJob
  useEffect(() => {
    let unmounted = false;

    async function refresh() {
      if (!unmounted) await getDispatchDocumentTableContents();
    }

    refresh();
    return () => { unmounted = true; };
  }, [fetchAttributes, props.dispatchJobObjectId]);

  function downloadDocument(e, fileURL) {
    e.preventDefault();
    window.open(fileURL);
  }

  // Custom styling templates for the different columns of the table
  const actionsBodyTemplate = (rowData) => {
    return (
      <div className="flex justify-content-end">
        <Button icon="pi pi-external-link" className="p-button-rounded mx-1" onClick={(e) => downloadDocument(e, rowData.fileURL)} />
      </div>
    );
  };
  const uploadedAtDateBodyTemplate = (rowData) => <p className={`${rowData.uploadedAt === '-' && 'text-400'}`}>{rowData.uploadedAt}</p>;
  const uploadedByBodyTemplate = (rowData) => <p className={`${rowData.uploadedByFullName === '-' && 'text-400'}`}>{rowData.uploadedByFullName}</p>;

  return (
    <div className="p-3 flex flex-column w-full">
      <p className="text-2xl font-bold">{props.title || 'Document History'}</p>
      <div className="card">
        <DataTable
          value={dispatchTableContents}
          paginator
          rows={fetchAttributes.count}
          totalRecords={totalDispatchDocumentsCount}
          lazy
          first={first}
          onPage={(e) => { setIsLoading(true); setFirst(e.first); setFetchAttributes({ ...fetchAttributes, page: e.first / e.rows }); }}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          dataKey="id"
          rowHover
          responsiveLayout="scroll"
          emptyMessage="No document history found."
          loading={isLoading}
        >
          <Column field="uploadedAt" header="Uploaded Date" style={{ minWidth: '10rem' }} body={uploadedAtDateBodyTemplate} />
          <Column field="uploadedByFullName" header="Uploaded By" style={{ minWidth: '10rem' }} body={uploadedByBodyTemplate} />
          <Column field="actions" header="" style={{ minWidth: '5rem' }} body={actionsBodyTemplate} />
        </DataTable>
      </div>
    </div>
  );
}

export default DispatchDocumentHistoryTable;
