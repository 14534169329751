import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-mapbox-gl';
import moment from 'moment-timezone';

// CSS
import styles from './ComponentStyles.module.scss';

function PickupDropoffPopup(props) {
  return (
    <Popup
      anchor="bottom"
      offset={{ bottom: [0, -20] }}
      coordinates={props.coordinates}
    >
      <div className={styles.popup}>
        <div><b>{props.vehicleLocation.get('jobAction').get('jobActionType').get('type')} for {props.vehicleLocation.get('jobAction').get('jobId')}</b></div>
        <div><i>{moment(props.vehicleLocation.get('dateTime')).format('LLLL')}</i></div>
        { props.vehicleLocation.get('jobAction').get('notes') &&
          <div className={styles.notes}>{props.vehicleLocation.get('jobAction').get('notes')}</div>
        }
      </div>
    </Popup>
  );
}

PickupDropoffPopup.propTypes = {
  coordinates: PropTypes.array.isRequired,
  vehicleLocation: PropTypes.object.isRequired,
};

export default PickupDropoffPopup;
