import { t } from 'api/Translate';
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import history from 'sbHistory'

// API
import * as Helpers from 'api/Helpers'
import * as Getters from 'api/Getters'
import { getQueryParameter } from 'api/URL'

// Components
import FilterForm from 'components/FilterForm/container/FilterForm.new'
import Title from 'components/LayoutTitle/view/Title'
import HOSViolationsTable from 'components/HOSViolationsTable/container/HOSViolationsTable'
import { MDBBtn, MDBRow, MDBCol, MDBIcon } from 'mdbreact'

// CSS
import styles from './HoursOfServiceViolationsLayout.module.scss'

function getCumulativeViolations(eldDailyCertificationArr) {
  let total = 0
  for (let i = 0; i < eldDailyCertificationArr.length; i++) {
    if (eldDailyCertificationArr[i].get('hosViolations')) {
      total += eldDailyCertificationArr[i].get('hosViolations').length;
    }
  }
  return total;
}

class HoursOfServiceViolationsLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      eldDailyCertificationArr: [],
      fetched: false,
      filter: [],
    }
    let tempDate = getQueryParameter(props.location.search, 'date')
    this.state.date = tempDate ? moment(tempDate, 'DDMMYY') : moment();
    this.state.filterFormFields = [
      {
        attrName: 'month',
        fullName: t('For Month'),
        placeholder: '.....',
        type: 'date',
        queryType: 'date',
        dateQueryType: 'dateMonth',
        value: tempDate ? moment(tempDate, 'DDMMYY') : moment(),
        includeLeftRight: true
      }
    ]

    this.getViolations = this.getViolations.bind(this)
    this.filter = this.filter.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
  }

  componentDidMount() {
    this.getViolations()
  }

  componentWillReceiveProps(nextProps) {
    let newDate = getQueryParameter(nextProps.location.search, 'date')
    let oldDate = getQueryParameter(this.props.location.search, 'date')
    if (newDate !== oldDate) {
      this.setState(
        { ...this.state, fetched: false, eldDailyCertificationArr: [], date: moment(newDate, 'DDMMYY') },
        () => {
          this.getViolations()
        }
      )
    }
  }

  getViolations() {
    const finalFilter = [].concat(...this.state.filter)
    finalFilter.push({
      name: 'driver',
      queryType: 'equalTo',
      value: this.props.driver
    })
    let tempDate = getQueryParameter(this.props.location.search, 'date')
    const startDate = tempDate
      ? Helpers.referenceToTimezone(
        moment(tempDate, 'DDMMYY'),
        this.props.driver.get('timezoneOffsetFromUTC')
      )
        .startOf('month')
        .toDate()
      : Helpers.referenceToTimezone(
        moment(),
        this.props.driver.get('timezoneOffsetFromUTC')
      )
        .startOf('month')
        .toDate()
    const endDate = tempDate
      ? Helpers.referenceToTimezone(
        moment(tempDate, 'DDMMYY'),
        this.props.driver.get('timezoneOffsetFromUTC')
      )
        .endOf('month').add(1, 'millisecond')
        .toDate()
      : Helpers.referenceToTimezone(
        moment(),
        this.props.driver.get('timezoneOffsetFromUTC')
      )
        .endOf('month').add(1, 'millisecond')
        .toDate()
    finalFilter.push({
      name: 'startTimeUTC',
      queryType: 'greaterThanOrEqualTo',
      value: startDate
    })
    finalFilter.push({
      name: 'endTimeUTC',
      queryType: 'lessThanOrEqualTo',
      value: endDate
    })
    finalFilter.push({ queryType: 'equalTo', name: 'disabled', value: false });
    finalFilter.push({ queryType: 'matchesQuery', name: 'hosViolations', innerQueryClass: 'HOSViolation', innerQueryType: 'equalTo', innerQueryProperty: 'potential', innerQueryValue: 'false' });
    finalFilter.push({ queryType: 'matchesQuery', name: 'hosViolations', innerQueryClass: 'HOSViolation', innerQueryType: 'equalTo', innerQueryProperty: 'disabled', innerQueryValue: 'false' });
    // finalFilter.push({ queryType: 'matchesQuery', name:'hosViolations', innerQueryClass:'HOSViolation', innerQueryType:'greaterThanOrEqualTo', innerQueryProperty:'startTimeUTC', innerQueryValue: startDate });
    // finalFilter.push({ queryType: 'matchesQuery', name:'hosViolations', innerQueryClass:'HOSViolation', innerQueryType:'lessThanOrEqualTo', innerQueryProperty:'endTimeUTC', innerQueryValue: endDate });
    // finalFilter.push({ queryType: 'matchesQuery', name:'hosViolations', innerQueryClass:'HOSViolation', innerQueryType:'equalTo', innerQueryProperty:'driver', innerQueryValue: this.props.driver })
    Getters.queryCompanyObjects(
      'ELDDailyCertification',
      undefined,
      undefined,
      finalFilter,
      { startTimeUTC: 'descending' },
      ['hosViolations'],
      false,
      true
    ).then(eldDailyCertificationArr => {
      const newState = { ...this.state }
      if (
        eldDailyCertificationArr.length > 0 &&
        moment(eldDailyCertificationArr[0].get('endTimeUTC')).isAfter(moment())
      ) {
        newState.todayELDDailyCertification = eldDailyCertificationArr[0]
      }
      newState.fetched = true
      newState.eldDailyCertificationArr = eldDailyCertificationArr
      newState.monthlyCumulative = getCumulativeViolations(
        eldDailyCertificationArr
      )
      this.setState(newState)
    })
  }

  filter(filterObject) {
    if (filterObject[0].value) {
      history.push({
        pathname: 'driver',
        search:
          'driver=' +
          this.props.driver.id +
          '&view=hosViolations&date=' +
          moment(filterObject[0].value).format('DDMMYY')
      })
    }
  }

  clearFilter() {
    history.push({
      pathname: 'driver',
      search: 'driver=' + this.props.driver.id + '&view=hosViolations'
    })
  }

  render() {
    console.log(this.state.eldDailyCertificationArr);
    // violations this month, violations this Week
    // violations today - ("this driver has this many violations right now")
    // table of days in the month (w/ filter) show violations for day,
    let monthlyCumulativeStyling = styles.success
    if (this.state.monthlyCumulative > 10) {
      monthlyCumulativeStyling = styles.warning
    }
    if (this.state.monthlyCumulative > 20) {
      monthlyCumulativeStyling = styles.failure
    }
    return (
      <div className={styles.container}>
        {/* <div className={styles.violationsToday}>
          {this.state.todayELDDailyCertification &&
            this.state.todayELDDailyCertification.get('hosViolations') &&
            this.state.todayELDDailyCertification
              .get('hosViolations')
              .filter(hosViolation => hosViolation.get('disabled') === false)
              .length > 0 && (
              <table>
              <tbody>
                  <tr>
                  <td>
                      On their current day,{' '}
                      {Helpers.toTitleCase(
                      this.props.driver.get('user_fullName')
                    )}{' '}
                      has{' '}
                      {this.state.todayELDDailyCertification &&
                        this.state.todayELDDailyCertification.get(
                          'hosViolations'
                        ) &&
                        this.state.todayELDDailyCertification.get(
                          'hosViolations'
                        ).length}{' '}
                      Violation(s)
                    </td>
                  <td>
                      <MDBBtn
                      icon='chevron-right'
                      onClick={() =>
                        history.push({
                          pathname: 'driver',
                          search:
                              'driver=' +
                              this.props.driver.id +
                              '&view=hosEvents'
                        })
                      }
                      color='primary'
                    >
                        Go To Log
                    </MDBBtn>
                    </td>
                </tr>
                </tbody>
            </table>
          )}
          {(!this.state.todayELDDailyCertification ||
            !this.state.todayELDDailyCertification.get('hosViolations') ||
            this.state.todayELDDailyCertification
              .get('hosViolations')
              .filter(hosViolation => hosViolation.get('disabled') === false)
              .length === 0) && (
            <MDBRow>
                <MDBCol className='my-auto'>
                Your Driver has no Violations on their current day!
                <MDBBtn
                    size='sm'
                    onClick={() =>
                    history.push({
                      pathname: 'driver',
                      search:
                        'driver=' + this.props.driver.id + 'view=hosEvents'
                    })
                  }
                    color='primary'
                >
                    <MDBIcon icon='chevron-right' className='black-text mr-2' />
                  Go To Log
                  </MDBBtn>
              </MDBCol>
              </MDBRow>
          )}
        </div>
        <hr style={{ margin: 0 }} /> */}
        <Title title={t('Monthly Violations')}>
          <MDBRow>
            <MDBCol>
              <FilterForm
                handleFilter={this.filter}
                clearFilter={this.clearFilter}
                fields={this.state.filterFormFields}
                submitOnSelect
              />
            </MDBCol>
          </MDBRow>
        </Title>
        <HOSViolationsTable
          driver={this.props.driver}
          eldDailyCertificationArr={this.state.eldDailyCertificationArr}
          fetched={this.state.fetched}
          date={this.state.date}
        />
      </div>
    )
  }
}

HoursOfServiceViolationsLayout.propTypes = {
  driver: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default HoursOfServiceViolationsLayout
