import React, { useState, useEffect } from 'react';

// api
import { getDispatchItemTransfersByJob } from 'api/Dispatch/DispatchShipment';
import { updateDispatchQuote } from 'api/Dispatch/DispatchQuote';
import { updateDispatchTransfer } from 'api/Dispatch/DispatchTransfer';

// sbcore components
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import Column from 'sbCore/Column/Column';
import CurrencyDropdown from 'sbCore/CurrencyDropdown/CurrencyDropdown';
import DataTable from 'sbCore/DataTable/DataTable';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import InputNumber from 'sbCore/InputNumber/InputNumber';
import Fieldset from 'sbCore/Fieldset/Fieldset';
import SelectButton from 'sbCore/SelectButton/SelectButton';

// scapi
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { LengthUnit } from 'sb-csapi/dist/enums/Unit';

// styles
import './style.scss';

/**
 *
 * @param {string} dispatchJobObjectId - objectId of current DispatchJob
 * @param {DispatchQuote} dispatchQuote - record of current DispatchQuote
 * @param {string} dispatchQuoteObjectId - objectId of current DispatchQuote
 * @param {function} setACtiveTabIndex - function to change tabs in a dispatchjob
 *
 * @returns QuoteValueSection
 */
function QuoteValueSection({ dispatchJobObjectId, dispatchQuote, dispatchQuoteObjectId, setActiveTabIndex, toggleQuoteValueState }) {

  const selectRateButtonOptions = ['Flat Rate', 'Rate per Distance'];
  const distanceUnits = Object.keys(LengthUnit).filter((lengthUnit) => lengthUnit === 'MI' || lengthUnit === 'KM');

  // useState
  const [selectRateButtonValue, setSelectRateButtonValue] = useState(selectRateButtonOptions[0]);
  const [distanceUnit, setDistanceUnit] = useState(distanceUnits[0]);
  const [dispatchTransfers, setDispatchTransfers] = useState([]);
  const [currencyUnit, setCurrencyUnit] = useState(getAttribute(dispatchQuote, 'unit', true) || 'CAD');

  // useEffect
  useEffect(() => {
    async function _getDispatchItemTransfersByJob() {
      const _dispatchTransfers = await getDispatchItemTransfersByJob(dispatchJobObjectId, undefined, undefined);
      if (_dispatchTransfers.length < 1) return;

      setDispatchTransfers(_dispatchTransfers.map((dispatchTransfer) => {
        const objectId = getAttribute(dispatchTransfer, 'objectId', true);
        const quoteAmount = getAttribute(dispatchTransfer, 'quoteAmount', true);
        const shipperAddress = getAttribute(dispatchTransfer, 'shipperAddress', true);
        const consigneeAddress = getAttribute(dispatchTransfer, 'consigneeAddress');
        const consigneeDispatchOrganization = getAttribute(dispatchTransfer, 'consigneeDispatchOrganization', true);
        const shipperDispatchOrganization = getAttribute(dispatchTransfer, 'shipperDispatchOrganization', true);
        const estimatedDistance = getAttribute(dispatchTransfer, 'estimatedDistance', true) || 0;

        const shipperName = shipperDispatchOrganization ?
          getAttribute(shipperDispatchOrganization, 'organizationName', true)
          : null;
        const consigneeName = consigneeDispatchOrganization ?
          getAttribute(consigneeDispatchOrganization, 'organizationName', true)
          : null;
        const shipmentDetails = shipperName && consigneeName ?
          shipperName + ' -> ' + consigneeName
          : 'No Shipper or Consignee';

        if (shipperAddress && consigneeAddress) {
          getAttribute(shipperAddress, 'city', true);
          getAttribute(consigneeAddress, 'city', true);
        }

        return {
          objectId,
          shipmentDetails,
          quoteAmount,
          estimatedDistance,
        };
      }));
    }

    _getDispatchItemTransfersByJob();
  }, [dispatchJobObjectId]);

  useEffect(() => {
    const isRatePerDistance = getAttribute(dispatchQuote, 'isRatePerDistance', true);
    if (isRatePerDistance) setSelectRateButtonValue('Rate per Distance');
    else setSelectRateButtonValue('Flat Rate');

    async function _editDistanceUnit() {
      const _distanceUnit = getAttribute(dispatchQuote, 'distanceUnit', true);
      if (_distanceUnit) await editDistanceUnit(_distanceUnit);
      else await editDistanceUnit(distanceUnits[0]);
    }

    _editDistanceUnit();
  }, [dispatchQuote]);

  // ** Functions ** //
  async function onRowEditComplete(e) {
    const _dispatchTransfers = [...dispatchTransfers];
    const { newData, index } = e;
    _dispatchTransfers[index] = newData;
    const { objectId, quoteAmount } = newData;

    const keyValueObj = { quoteAmount };
    if (selectRateButtonValue === selectRateButtonOptions[1]) keyValueObj.estimatedDistance = newData.estimatedDistance;

    await updateDispatchTransfer(undefined, objectId, keyValueObj, true);

    setDispatchTransfers(_dispatchTransfers);
    toggleQuoteValueState();
  }

  async function toggleIsRatePerDistance(value) {
    if (!value) return;
    let isRatePerDistance = false;
    if (value === 'Rate per Distance') isRatePerDistance = true;
    await updateDispatchQuote(dispatchQuoteObjectId, { isRatePerDistance }, true);
    setSelectRateButtonValue(value);
    toggleQuoteValueState();
  }

  async function editDistanceUnit(distanceUnit) {
    if (!dispatchQuoteObjectId) return;
    await updateDispatchQuote(dispatchQuoteObjectId, { distanceUnit }, true);
    setDistanceUnit(distanceUnit);
    toggleQuoteValueState();
  }

  async function selectCurrency(currency) {
    setCurrencyUnit(currency);
    await updateDispatchQuote(dispatchQuoteObjectId, { unit: currency }, true);
  }

  // ** Templates ** //
  const headerTemplate = (
    <div className="d-flex justify-content-between mb-2">
      <SelectButton
        value={selectRateButtonValue}
        onChange={async (e) => await toggleIsRatePerDistance(e.value)}
        options={selectRateButtonOptions}
        unselectable
        allowEmpty={false}
        variant="small"
      />
      <CurrencyDropdown
        hideLabel
        variant="small"
        currency={currencyUnit}
        onSelect={async (currency) => await selectCurrency(currency.short)}
      />
    </div>
  );

  function distanceInputTemplate(options) {
    return (
      <InputNumber
        value={options.value}
        placeholder="Distance"
        className="input-group-field"
        onChange={(e) => options.editorCallback(e.value)}
        minFractionDigits={2}
      />
    );
  }

  function rateInputTemplate(options) {
    return (
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">$</span>
        <InputNumber
          value={options.value}
          placeholder="Amount"
          className="input-group-field"
          onChange={(e) => options.editorCallback(e.value)}
          minFractionDigits={2}
        />
      </div>
    );
  }

  const rateTemplate = (rowData) => {
    return <div>${rowData.quoteAmount && rowData.quoteAmount.toFixed(2)}</div>
  };

  const distanceTemplate = (rowData) => {
    return <div>{rowData.estimatedDistance} {distanceUnit.toLowerCase()}</div>
  };

  const distanceFieldHeader = (
    <div className="d-flex align-items-center">
      Distance ({distanceUnit})
    </div>
  );

  // ** Colored Section Header ** //
  function rateHeader() {
    if (selectRateButtonValue === selectRateButtonOptions[0]) return <div>Flat Rate</div>;
    return (
      <div className="d-flex align-items-center">
        Rate per
        <Dropdown value={distanceUnit} onChange={async (e) => await editDistanceUnit(e.value)} options={distanceUnits} variant="transparent" />
      </div>
    );
  }

  // ** Footer ** //
  const shipmentDetailsFooter = (
    <div className="d-flex justify-content-end mt-4">
      <div className="tab-change-button" onClick={() => setActiveTabIndex(1)}>
        Edit in Shipment Details
        <span className="pi pi-arrow-right ml-1" />
      </div>
    </div>
  );

  return (
    // <ColoredSection
    //   className="quote-information-value-section"
    //   title="Main Quote"
    //   footer={shipmentDetailsFooter}
    //   variant="compact"
    // >
    //   {headerTemplate}
    //   <DataTable
    //     value={dispatchTransfers}
    //     scrollable
    //     scrollHeight="150px"
    //     variant="compact"
    //     className="dispatch-datatable"
    //     editMode="row"
    //     onRowEditComplete={(e) => onRowEditComplete(e)}
    //   >
    //     <Column header="Shipment Details" field="shipmentDetails" headerStyle={{ width: '60%' }} />
    //     {/* <Column header={distanceFieldHeader} field="distance" headerStyle={{ width: '18%' }} /> */}
    //     <Column header={rateHeader()} field="quoteAmount" editor={(options) => rateInputTemplate(options)} headerStyle={{ width: '30%' }} />
    //     <Column rowEditor headerStyle={{ minWidth: '1.5rem' }} bodyStyle={{ textAlign: 'center' }} />
    //   </DataTable>
    // </ColoredSection>
    <Fieldset toggleable className="quote-information-value-section m-3" legend="1. Quote Details" sbVariant="dispatch">
      {headerTemplate}
      <DataTable
        value={dispatchTransfers}
        variant="compact"
        className="dispatch-datatable"
        editMode="row"
        onRowEditComplete={async (e) => await onRowEditComplete(e)}
      >
        <Column
          header="Shipment Details"
          field="shipmentDetails"
          headerStyle={selectRateButtonValue === selectRateButtonOptions[1] ? { width: '40%' } : { width: '60%' }}
        />
        {selectRateButtonValue === selectRateButtonOptions[1] && (
          <Column
            header={distanceFieldHeader}
            field="estimatedDistance"
            editor={(options) => distanceInputTemplate(options)}
            headerStyle={{ width: '25%' }}
            body={distanceTemplate}
          />
        )}
        <Column
          header={rateHeader()}
          field="quoteAmount"
          editor={(options) => rateInputTemplate(options)}
          headerStyle={{ width: '30%' }}
          body={rateTemplate}
        />
        <Column
          rowEditor
          headerStyle={{ minWidth: '1.5rem' }}
          bodyStyle={{ textAlign: 'center' }}
        />
      </DataTable>
      {shipmentDetailsFooter}
    </Fieldset>
  );
}

export default QuoteValueSection;
