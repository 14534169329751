import Parse from 'parse';
import store from '../store';
import * as ActionConstants from './ActionConstants';

import * as Setters from 'api/Setters';

export function fetchingCompany() {
  return {
    type: ActionConstants.FETCH_COMPANY_INPROGRESS,
  };
}

export function fetchCompanySuccess(company) {
  return {
    type: ActionConstants.FETCH_COMPANY_SUCCESS,
    company,
  };
}

export function fetchCompanyError(error) {
  return {
    type: ActionConstants.FETCH_COMPANY_ERROR,
    error,
  };
}

export function updateCompanySuccess(updatedCompany) {
  return {
    type: ActionConstants.UPDATE_COMPANY_SUCCESS,
    company: updatedCompany,
  };
}

export function selfUpdateCompany(userCompany) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingCompany());
    userCompany.fetch().then(
      company => {
        store.dispatch(fetchCompanySuccess(company));
        resolve(company);
      },
      error => reject(error)
    );
  });
  return promise;
}

export function uploadLogo(company, file) {
  const promise = new Promise((resolve, reject) => {
    Setters.uploadLogo(company, file).then(
      updatedCompany => {
        store.dispatch(updateCompanySuccess(updatedCompany));
        resolve(updatedCompany);
      }, error => reject(error)
    );
  });
  return promise;
}
