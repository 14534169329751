import { t } from 'api/Translate';
import React from 'react'
import PropTypes from 'prop-types'
import uniqid from 'uniqid';

import { getObjectDeepCopy } from 'api/Getters';

import { QuerySortOrderTypes } from 'enums/Query';

import { MDBTable, MDBTableBody, MDBTableHead, MDBIcon, MDBTooltip, MDBInput, MDBBtn, } from 'mdbreact';

import SBEditButton from 'components/Shared/SBEditButton/SBEditButton';
import SBDraggableRow from './SBDraggableRow';
import SBDropRow from './SBDropRow';

import './style.scss'

const Paginator = (props) => {

  // const getPageInputValue = () => {
  //   // console.log(props.instanceId);
  //   const inputValue = parseInt(document.querySelector(`.${props.instanceId} .sb-table-paginator-container .sb-table-paginator-page-input-container input`).value);
  //   if (!inputValue || isNaN(inputValue) || (inputValue && (inputValue < 1))) return props.activePage; // handle invalid inputs
  //   return inputValue;
  // };
  const getPageInputValue = (inputValue) => {
    if ((inputValue === undefined) || (inputValue === null) || isNaN(inputValue)) return props.activePage; // handle invalid inputs - return last activePage if bad input
    return inputValue;
  };

  // let className = 'sb-table-paginator-container sb-form text-right';
  let className = 'sb-table-paginator-container sb-form text-right';
  // It's in form control boooo
  if (props.paginatorContainerClassName) {
    className += ` ${props.paginatorContainerClassName}`;
  }
  return (
    <div className={className}>
      <div className="sb-table-paginator">
        <MDBBtn
          className="sb-btn-paginator"
          color="secondary"
          onClick={() => props.handlePaginatorOnChange('PREVIOUS')}
          disabled={props.isLoading || props.isWaiting || props.disablePaginatorPrevious}
        >
          <MDBIcon icon="angle-left" />
        </MDBBtn>

        <MDBInput
          containerClass="d-inline-block sb-table-paginator-page-input-container translate-me"
          className="sb-input-outline"
          label="Displaying Page"
          labelClass="active"
          type="number"
          min="1"
          size="sm"
          value={(props._activePage || '').toString()}
          onChange={(e) => props._handlePageInput(e.target.value)}
          disabled={props.isLoading || props.isWaiting}

        // onInput={(e) => props.handlePageInput(e.target.value)}
        />

        <div className="d-inline-block sb-table-paginator-count translate-me">of <var>{props._pageCount || '0'}</var></div>

        <MDBBtn
          className="sb-btn-paginator"
          color="secondary"
          onClick={() => props.handlePaginatorOnChange('SUBMIT', getPageInputValue(props._activePage))}
          disabled={props.isLoading || props.isWaiting || props.disablePaginatorSubmit}
        >
          {(props.isLoading || props.isWaiting) ?
            <MDBIcon icon="sync-alt" />
            :
            <span className="translate-me">Go</span>
          }
        </MDBBtn>
        <MDBBtn
          className="sb-btn-paginator"
          color="secondary"
          onClick={() => props.handlePaginatorOnChange('NEXT')}
          disabled={props.isLoading || props.isWaiting || props.disablePaginatorNext}
        >
          <MDBIcon icon="angle-right" />
        </MDBBtn>
      </div>
    </div>
  );
};

class SBTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      instanceId: uniqid(), // use this as part of dom identifiers
      _activePage: props.activePage || '0',
      _pageCount: props.pageCount || '0',
      expandedRows: {},
      rows: []
    };

    this.initLazyLoader = this.initLazyLoader.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.toggleExpandRow = this.toggleExpandRow.bind(this);
    this._handlePageInput = this._handlePageInput.bind(this);
  }

  componentDidMount() {
    this.initLazyLoader()
    this.refreshState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.refreshState(nextProps);
  }

  componentWillUnmount() {
    this.lastScrollTop = undefined

    const { instanceId } = this.state

    // remove all event listeners (https://stackoverflow.com/questions/18650385/is-there-a-vanilla-function-to-remove-all-event-listeners-without-affecting-the)
    // only replace the ancestor element
    const tableBodyNode = document.querySelector(
      `.${instanceId} .sb-table-body-container > div:first-child`
    )

    if (tableBodyNode) {
      const clone = tableBodyNode.cloneNode(false)

      // copy children backwards because of the removal
      for (let index = tableBodyNode.childNodes.length - 1; index >= 0; --index) { clone.insertBefore(tableBodyNode.childNodes[index], clone.firstChild) }

      // insert back into DOM
      tableBodyNode.parentNode.replaceChild(clone, tableBodyNode)
    }
  }

  initLazyLoader() {
    const { handleLazyLoad, lazyLoadScrollOffset } = this.props
    const { instanceId } = this.state

    /*
      Note that if lazyLoadScrollOffset is used, scrolling past that buff will trigger the lazy load callback (and hence scrolling
      all the way to the bottom can trigger the lazy loading multiple times)

      The best way to counteract this would be to disable the lazy loader callback in PARENT while data is being fetched

      That way, this component is kept as clean/simple as possible and 'bare-bones'
    */
    if (handleLazyLoad) {
      // let tableBodyNode = ReactDOM.findDOMNode(this.refs['sb-table-body']).parentNode.parentNode;
      let tableBodyNode = document.querySelector(
        `.${instanceId} .sb-table-body-container > div:first-child`
      )
      this.lastScrollTop = tableBodyNode.scrollTop

      tableBodyNode.addEventListener('scroll', e => {
        const { scrollTop, scrollHeight, offsetHeight } = e.target

        // user scrolled to (near) bottom, so call lazy load fn to load moar!
        const scrollBottom = scrollHeight - offsetHeight

        // console.log(`${scrollTop + (lazyLoadScrollOffset || 0)} - ${scrollBottom}`);
        const offsetScrollTop = scrollTop + (lazyLoadScrollOffset || 0)
        if (offsetScrollTop < this.lastScrollTop) return // user is scrolling up
        if (offsetScrollTop >= scrollBottom) {
          handleLazyLoad()
        }
        this.lastScrollTop = offsetScrollTop
      })
    }
  }

  refreshState(nextProps) {
    let shouldRefresh = false;
    const newState = { ...this.state };
    if ((nextProps.activePage !== undefined) && (nextProps.activePage !== this.props.activePage)) {
      shouldRefresh = true;
      newState._activePage = nextProps.activePage;
    }
    if ((nextProps.pageCount !== undefined) && (nextProps.pageCount !== this.props.pageCount)) {
      shouldRefresh = true;
      newState._pageCount = nextProps.pageCount;
    }
    if (nextProps.tableBodyRows !== undefined && nextProps.tableBodyRows != this.state.rows) {
      shouldRefresh = true;
      newState.rows = nextProps.tableBodyRows;
      if (nextProps.solid) {
        nextProps.tableBodyRows.map(row => {
          newState.expandedRows[row.key] = true;
        })
      }
    }

    if (shouldRefresh) {
      this.setState(newState);
    }
  }

  _handlePageInput(inputValue) {
    this.setState({ ...this.state, _activePage: inputValue });
  }

  toggleExpandRow(expandableRowId) {
    const { isLoading, isWaiting } = this.props;
    if (isLoading || isWaiting) return;

    const newState = { ...this.state };
    newState.expandedRows = { ...newState.expandedRows };
    if (!newState.expandedRows[expandableRowId]) {
      newState.expandedRows[expandableRowId] = true;
    } else {
      newState.expandedRows[expandableRowId] = false;
    }

    this.setState(newState);
  }

  render() {
    const props = { ...this.props }
    const state = { ...this.state }
    /**
     * -----------------------------
     * GENERATE ROWS AND COLUMNS
     * -----------------------------
     */
    const isRowExpandable = props.expandable; // if rows are expandable, need to allocate width to it
    const isRowExpandedSolid = props.solid // if rows expanded content be solid 
    const tableHeaderColumnProps = []
    const _tableHeaderRows = (props.tableHeaderRows) ? [].concat(props.tableHeaderRows) : [];

    const tableHeaderRows = _tableHeaderRows.map(
      (row, rowIndex) => {

        const _rowColumns = (row.columns) ? [].concat(row.columns) : [];
        if (isRowExpandable || isRowExpandedSolid) {
          _rowColumns.unshift({
            element: <div />,
            props: { style: { verticalAlign: 'middle', width: '3%' }, className: "print-hide-cell" } // allocate 5% which means we need to take off 5% from another column
          });
        }

        const rowColumns = _rowColumns.map((column, columnIndex) => {
          const columnProps = { ...column.props }
          const tooltip = columnProps.tooltip;
          const isHeaderDisabled = props.isLoading || props.isWaiting;

          if (isRowExpandable && (columnIndex === 1) && columnProps.style && columnProps.style.width) {
            columnProps.style = getObjectDeepCopy(columnProps.style);
            columnProps.style.width = `${parseInt(columnProps.style.width) - 3}%`; // give 5% to the expandable icon column
          }

          if (isRowExpandedSolid && (columnIndex === 1) && columnProps.style && columnProps.style.width) {
            columnProps.style = getObjectDeepCopy(columnProps.style);
            columnProps.style.width = `${parseInt(columnProps.style.width) - 3}%`; // give 5% to the expandable icon column
          }

          let classNames = []
          let onClickFn
          if (columnProps.handleSort) {
            onClickFn = columnProps.handleSort
            classNames.push('clickable')
          }
          if (columnProps.isSortActive) {
            classNames.push('active-sort')
          }
          if (isHeaderDisabled) {
            classNames.push('disabled')
          }

          classNames = classNames.join(' ')

          // determine which sorting icon to show
          let sortIcon = <MDBIcon icon='sort' size='lg' />;
          if (columnProps.isSortActive && columnProps.sortOrder) {
            if (columnProps.sortOrder === QuerySortOrderTypes.DESCENDING) {
              sortIcon = <MDBIcon className="icon-sort-type-descending" icon='sort-down' size='lg' />;
            } else if (columnProps.sortOrder === QuerySortOrderTypes.ASCENDING) {
              sortIcon = <MDBIcon className="icon-sort-type-ascending" icon='sort-up' size='lg' />;
            }
          }

          let headerColumnContent = (
            <div className="header-col-column-content-container">
              <div className="d-inline-block">{column.element}</div>
              {onClickFn && (
                <div className='sort-button-container'>
                  {sortIcon}
                </div>
              )}
              {columnProps.editable && (
                <div className='editable-icon-container'>
                  <MDBIcon icon="edit" />
                </div>
              )}
            </div>
          );

          if (tooltip) {
            headerColumnContent = (
              <MDBTooltip
                placement={tooltip.placement || 'top'}
                tag="div"
                component="div"
                tooltipContent={tooltip.content || 'This is Tooltip Content'}
                tooltipClass="header-col-tooltip"
              >
                {headerColumnContent}
              </MDBTooltip>
            );
          }

          delete columnProps.tooltip;
          delete columnProps.handleSort; // remove handleSort now so react doesn't complain about passing handleSort as a prop
          delete columnProps.isSortActive;
          delete columnProps.sortOrder;
          tableHeaderColumnProps.push({ ...columnProps }) // store the props for the column to pass/replicate to the respective table body columns

          delete columnProps.editable;

          return (
            <th
              key={column.key || `header-col-${rowIndex}-${columnIndex}`}
              className={`position-relative ${classNames}`}
              {...columnProps}
              onClick={() => { (!isHeaderDisabled && onClickFn) ? onClickFn() : 0 }}
            >
              {headerColumnContent}
            </th>
          )
        })

        return (
          <tr key={row.key || `header-row-${rowIndex}`} {...row.props}>
            {rowColumns}
          </tr>
        )
      }
    )

    let tableBodyRows = [];
    if (props.isLoading) {
      tableBodyRows = [
        <tr key={`body-row-0`}>
          <td
            key={`body-col-0-0`}
            colSpan={(tableHeaderRows && tableHeaderRows[0]?.props?.children?.length) || "420"}
            className="loading-spinner-column sb-table-expandable-cell"
          >
            <div className='spinner-border loading-spinner' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          </td>
        </tr>
      ]
    } else {
      if (state.rows && state.rows.length > 0) {
        tableBodyRows = (state.rows || []).map((row, rowIndex) => {
          const rowProps = row.props ? { ...row.props } : {};

          // row properties (excludes expandable row)
          const rowKey = row.key || `body-row-${rowIndex}`;
          const isEvenRow = !(rowIndex % 2); // find out if the main row (excludes the expandable) is even or odd and determine coloring class from that
          let rowClassName = 'sb-table-body-row';

          if (props.hover) rowClassName += ` hover-gray-matte`;
          if (isEvenRow) rowClassName += ` bg-gray-hover`;


          if (isRowExpandable || isRowExpandedSolid) {
            rowClassName += ` expandable-parent`;
          }


          if (rowProps.className) rowClassName += ` ${rowProps.className}`;


          // expandable row properties
          let expandableRowId = rowKey;
          let expandableRowClassName = 'sb-table-body-expanded-row';
          let expandableContent;
          if (state.expandedRows[expandableRowId]) {
            rowClassName += ` bg-gray-matte`;
            expandableRowClassName += ` expanded`;
            expandableContent = rowProps.expandableContent;
          }
          delete rowProps.className;
          delete rowProps.expandable;
          delete rowProps.expandableContent;

          const _rowColumns = (row.columns) ? [].concat(row.columns) : [];
          if (isRowExpandable) {
            let angledArrowElement = <MDBIcon icon="caret-right" />;
            if (state.expandedRows[expandableRowId]) {
              angledArrowElement = <MDBIcon icon="caret-down" />;
            }
            _rowColumns.unshift({
              element: <div>{angledArrowElement}</div>,
              props: { className: "print-hide-cell" }
            });
          }

          if (isRowExpandedSolid) {
            _rowColumns.unshift({
              element: <div></div>,
            });
          }

          const rowColumns = _rowColumns.map((column, columnIndex) => {
            const handleEdit = column.props && column.props.handleEdit;
            const showCancelEditButton = column.props && column.props.showCancelEditButton;
            let columnProps = JSON.parse(JSON.stringify(column.props || {}));
            delete columnProps.handleEdit;
            delete columnProps.showCancelEditButton;
            const tableHeaderColumnProp = tableHeaderColumnProps[columnIndex];

            if ((isRowExpandable || isRowExpandedSolid) && (columnIndex === 0)) {
              columnProps.style = {
                ...columnProps.style,
                width: tableHeaderColumnProp.style.width,
                verticalAlign: tableHeaderColumnProp.style.verticalAlign,
              }
            }

            // if (isRowExpandedSolid && (columnIndex === 0)) {
            //   columnProps.style = {
            //     ...columnProps.style,
            //     width: tableHeaderColumnProp.style.width,
            //     verticalAlign: tableHeaderColumnProp.style.verticalAlign,
            //   }
            // }

            if (
              tableHeaderColumnProp &&
              tableHeaderColumnProp.style &&
              tableHeaderColumnProp.style.width
            ) {
              // apply the respective table header column props
              columnProps.style
                ? (columnProps.style = {
                  ...columnProps.style,
                  width: tableHeaderColumnProp.style.width
                })
                : (columnProps.style = {
                  width: tableHeaderColumnProp.style.width
                })
            }

            return (
              <td
                key={column.key || `body-col-${rowIndex}-${columnIndex}`}
                {...columnProps}
              >
                {column.element}
                {(tableHeaderColumnProp.editable && handleEdit) &&
                  <SBEditButton containerClassName="sb-table-edit-button align-middle" showCancelButton={showCancelEditButton} onClick={(e) => handleEdit(e)} disabled={props.isLoading || props.isWaiting} />
                }
              </td>
            );
          })

          return (
            <React.Fragment key={rowKey}>
              {props.isRowDraggable &&
                <SBDraggableRow index={rowIndex} className={rowClassName} >
                  {rowColumns}
                </SBDraggableRow>
              }
              {!props.isRowDraggable &&
                <tr index={rowIndex} className={rowClassName} onClick={() => { isRowExpandable && this.toggleExpandRow(expandableRowId) }} {...rowProps}>
                  {rowColumns}
                </tr>
              }
              {(isRowExpandable || isRowExpandedSolid) &&
                <tr key={expandableRowId} className={expandableRowClassName}>
                  <td
                    colSpan={(tableHeaderRows && tableHeaderRows[0]?.props?.children?.length) || "69"}
                    className="sb-table-expandable-cell"
                  >
                    {expandableContent}
                  </td>
                </tr>
              }
              {props.isRowDraggable &&
                <SBDropRow index={rowIndex + 1} handleDrop={(itemIndex, dropIndex) => { props.handleDrop ? props.handleDrop(itemIndex, dropIndex) : null }} />
              }
            </React.Fragment>
          );
        })
      } else {
        tableBodyRows = [
          <tr key={`body-row-0`}>
            <td
              key={`body-col-0-0`}
              colSpan={tableHeaderRows && (tableHeaderRows && tableHeaderRows[0]?.props?.children?.length) || "420"}
              style={props.emptyTableMessageStyle}
              className="sb-table-expandable-cell"
            >
              {props.emptyTableMessage}
            </td>
          </tr>
        ]
      }
    }


    /**
     * -----------------------------
     * RETURN TABLE ( + PAGINATOR )
     * -----------------------------
     */
    return (
      <div className={`sb-table-container ${state.instanceId} ${props.containerClassName}`}>
        <div className={`sb-table-head-container translate-me`}>
          <MDBTable className='sb-table' responsive={props.responsive}>
            <MDBTableHead>{tableHeaderRows}</MDBTableHead>
          </MDBTable>
        </div>

        <div className='sb-table-body-container'>
          <MDBTable
            className='sb-table'
            bordered={props.bordered}
            responsive={props.responsive}
            maxHeight={props.height}
            small
          >
            <MDBTableBody>
              {(props.isRowDraggable && state.rows && state.rows.length > 0) &&
                <SBDropRow index={0} handleDrop={(itemIndex, dropIndex) => { props.handleDrop ? props.handleDrop(itemIndex, dropIndex) : null }} />
              }
              {tableBodyRows}
            </MDBTableBody>
          </MDBTable>

          {props.isWaiting && (
            <div className='lazy-loading-spinner-container'>
              <div className='spinner-border loading-spinner' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          )}
        </div>

        {props.showPaginator &&
          <Paginator
            {...props}
            _activePage={state._activePage}
            _pageCount={state._pageCount}
            _handlePageInput={this._handlePageInput}
          />
        }

      </div>
    )
  }
}

SBTable.defaultProps = {
  bordered: true,
  emptyTableMessage: 'No Content Available',
  height: 'auto',
  responsive: true,
  rows: [],
  scrollY: true,
  tableBodyRows: [],
  tableHeaderRows: [],
  showPaginator: false,
  activePage: 1,
  pageCount: 1,
  isRowDraggable: false,
  emptyTableMessageStyle: {},
}

SBTable.propTypes = {
  // ui-lib
  bordered: PropTypes.bool,
  responsive: PropTypes.bool,
  scrollY: PropTypes.bool,
  isRowDraggable: PropTypes.bool,
  handleDrop: PropTypes.func,
  solid: PropTypes.bool,
  expandable: PropTypes.bool,

  // general
  containerClassName: PropTypes.string,
  emptyTableMessage: PropTypes.any,
  tableBodyRows: PropTypes.array,
  tableHeaderRows: PropTypes.array,
  height: PropTypes.any,
  isLoading: PropTypes.bool, // determine if init is still in progress. different from isWaiting where init is completed
  isWaiting: PropTypes.bool, // determines if show content loading on bottom right of currently shown list
  emptyTableMessageStyle: PropTypes.object,

  // lazy loading
  handleLazyLoad: PropTypes.func,
  lazyLoadScrollOffset: PropTypes.number,

  // pagination
  showPaginator: PropTypes.bool,
  activePage: PropTypes.number, // the number of the current page
  pageCount: PropTypes.number, // the total number of pages
  // handlePageInput: PropTypes.func, // the function that returns the page number that was inputted

  disablePaginatorPrevious: PropTypes.bool,
  disablePaginatorSubmit: PropTypes.bool,
  disablePaginatorNext: PropTypes.bool,
  handlePaginatorOnChange: PropTypes.func, // catch-all onChange function to handle Previous/Next/Submit cases. returns type (and pageInput - if type === submit)
  paginatorContainerClassName: PropTypes.string,
}

export default SBTable

/*
  tableHeaderRowProps (same concept as tableBodyRowProps) is an array of objects. Each object in the array
  represents a table row, in that order. In each object (row), contains {{ props }} properties which is a spread on props for that row
  and the columns (array) of that row which in itself also has a props attribute for each column (object)

  tableHeaderRows: [
    row0: {
      key: "...", <-- REQUIRED FOR EXPANDABLE BEHAVIOUR
      props: { ... },
      columns: [
        column0: {
          key: "...",
          element: <div>...</div>,
          props: {
            // general props
            style: { ... }
            colSpan: "3",
            tooltip: "narf",

            // sort handling props
            handleSort: () => {},
            isSortActive: true || false,
            sortOrderType: QuerySortOrderTypes.ASCENDING || QuerySortOrderTypes.DESCENDING <- sortOrder only applies if isSortActive

            // column edit handling props
            editable: bool // allows the column to be editable

            // TABLE BODY COLUMN PROPS
            handleEdit: (e) => {}, // edit callback
            showCancelEditButton: bool // whether or not to show the cancel edit button rather than enable edit button
            ...
          }
        },

        column1: { ... },
        ...
      ]
    },

    row1: ...

*/
