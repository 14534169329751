import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';

// Actions
import { getLinkedDriversForState, deleteDriversForState } from 'actions/Driver';

// API
import * as Helpers from 'api/Helpers';
import { getCurrentUser } from 'api/Getters';
import { getQueryParameter } from 'api/URL';
import * as Parse from 'api/Parse';

// Components
import SpeedPatternsLayout from './SpeedPatternsLayout';
import ViolationsSummaryLayout from './ViolationsSummaryLayout';
import DriverRankingsLayout from './DriverRankingsLayout';
// import ReportsLayout from './ReportsLayout';
import DriverPatternsDashboard from 'components/DriverPatterns/DriverPatternsDashboard';

import Title from 'components/Shared/Title/Title';
import SubNavigationBar from 'components/Shared/SubNavigationBar/SubNavigationBar'
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay';

import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';

// CSS
import styles from './DriverBehaviourLayout.module.scss';
import './styles.scss';

class DriverBehaviourLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      views: {
        DASHBOARD: 'dashboard',
        SPEEDING_IDLING: 'speedingidling',
        VIOLATIONS: 'violations',
        RANKINGS: 'rankings',
      },

      drivers: [],
    };

    this.getDrivers_old = this.getDrivers_old.bind(this);
    this.checkDriverPatternUserpermission = this.checkDriverPatternUserpermission.bind(this);
  }

  componentDidMount() {

    document.title = 'Driver Analytics - Switchboard';
    this.checkDriverPatternUserpermission();
    this.getDrivers_old();
  }

  componentWillUnmount() {
    deleteDriversForState();
  }

  getDrivers_old() {
    // this is only used for the speedpatternslayout
    const promise = new Promise(resolve => {
      const { isLoading, driversFilter, driversSort, driversTableView, queryAllDrivers } = this.state;
      const disabled = false;

      let loadingState = { ...this.state, isLoading: true };

      this.setState(loadingState, () => {
        var self = this;
        getLinkedDriversForState(undefined, undefined, driversFilter, driversSort, driversTableView, disabled, getCurrentUser(true).belongsToCompany.dbObject, queryAllDrivers).then(
          function (drivers) {
            const newState = { ...loadingState, drivers };

            newState.isLoading = false; // we've queried everything, so stop

            self.setState(newState, () => resolve(true));
          }
        );

      });
    });
    return promise;
  }

  checkDriverPatternUserpermission() {
    // verify current user has the subscription privillege for driverBehaviourModule
    const user = Parse.getCurrentUser();
    const belongsToCompany = user.get('belongsToCompany');
    const subscription = belongsToCompany && belongsToCompany.get('subscription');
    const DriverBehaviourisSubscribed = subscription.get('driverBehaviourModule');
    const userSepcialPermission = user.get('userSpecialPermission');
    const driverBehaviourIsNotAllowed = userSepcialPermission && userSepcialPermission.get('disableDriverBehaviourModule');
    if (driverBehaviourIsNotAllowed) {
      history.push({ pathname: '404' });
    }
    if (!DriverBehaviourisSubscribed) {
      history.push({ pathname: '404' });
    }
  }

  render() {

    const { location } = this.props;
    const { views, isLoading, drivers } = this.state;
    const currentView = getQueryParameter(location.search, 'view');

    let navItems = [
      {
        name: 'Dashboard',
        onClick: () => { },
        isActive: !currentView || (currentView === views.DASHBOARD),
        to: '?view=dashboard'
      },
      {
        name: 'Speeding / Idling',
        onClick: () => { },
        isActive: currentView === views.SPEEDING_IDLING,
        to: '?view=speedingidling'
      },
      // {
      //   name: 'Violations Summary',
      //   onClick: () => {},
      //   isActive: currentView === views.VIOLATIONS,
      //   to: '?view=violations'
      // },
      // {
      //   name: 'Driver Rankings',
      //   onClick: () => {},
      //   isActive: currentView === views.RANKINGS,
      //   to: '?view=rankings'
      // }
    ]

    return (
      <MDBContainer className={`${styles.driverBehaviourLayout} driver-patterns-layout px-5`} fluid>
        <MDBRow><MDBCol>
        </MDBCol></MDBRow>

        <MDBRow>
          <MDBCol>
            <SubNavigationBar navItems={navItems} />
          </MDBCol>
        </MDBRow>

        <React.Fragment>
          {(!currentView || (currentView === views.DASHBOARD)) &&
            <MDBRow><MDBCol>
              <DriverPatternsDashboard />
            </MDBCol></MDBRow>
          }
          {currentView === views.SPEEDING_IDLING &&
            <MDBRow><MDBCol>
              <SpeedPatternsLayout drivers={drivers} location={location} />
            </MDBCol></MDBRow>
          }
          {currentView === views.VIOLATIONS &&
            <MDBRow><MDBCol>
              <ViolationsSummaryLayout />
            </MDBCol></MDBRow>
          }
          {currentView === views.RANKINGS &&
            <MDBRow><MDBCol>
              <DriverRankingsLayout />
            </MDBCol></MDBRow>
          }
          {/*subViewSelected === 'reports' &&
              <div>
                <ReportsLayout
                  unit={"km"}
                  browserHistory={browserHistory}
                  previousSubView={previousSubView}
                  styles={styles}
                  company={Company.company}
                />
              </div>
          */}
        </React.Fragment>

      </MDBContainer>
    );
  }
}

DriverBehaviourLayout.propTypes = {
  location: PropTypes.object.isRequired,
};

export default DriverBehaviourLayout;
