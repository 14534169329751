import React from 'react';
import PropTypes from 'prop-types';
import { MDBIcon } from "mdbreact";

import styles from './SortButton.module.scss';

class SortButton extends React.Component {
  constructor(props) {
    super(props);
    let isActive = props.isActive;
    if (!isActive) {
      isActive = false;
    } else {
      isActive = true;
    }
    this.state = {
      isActive,
      activeClass: isActive ? styles.active : '',
    };
    this.setActive = this.setActive.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isActive) {
      this.state.activeClass = styles.active;
      this.setState(this.state);
    } else {
      this.state.activeClass = '';
      this.setState(this.state);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.isActive !== nextProps.isActive) {
      return true;
    }
    return false;
  }

  setActive() {
    if (this.props.clickHandler) {
      this.props.clickHandler();
    }
  }

  render() {
    return (
      <button className={`${styles.sortButton} ${this.state.activeClass}`} onClick={() => this.setActive()}>
        <MDBIcon icon="sort" className="ml-1 textGrayCalm cursor-pointer" />
      </button>
    );
  }
}

SortButton.propTypes = {
  clickHandler: PropTypes.func,
  isActive: PropTypes.any,
};

export default SortButton;
