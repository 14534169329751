// Components
import Skeleton from 'sbCore/Skeleton/Skeleton';

// Style
import './style.scss';

/**
 * @description Placeholder skeleton layout for document
 */
function DispatchDocumentSkeleton() {
  return (
    <div className="dispatch-document-skeleton card flex flex-column w-full lg:w-10 p-5">
      <div className="flex justify-content-between w-full my-1">
        <Skeleton width="30%" height="8rem" />
        <Skeleton width="40%" height="8rem" />
      </div>
      <div className="flex justify-content-between w-full my-1">
        <Skeleton width="100%" height="5rem" />
      </div>
      <div className="flex justify-content-between w-full my-1">
        <Skeleton width="100%" height="30rem" />
      </div>
      <div className="flex justify-content-between w-full my-1">
        <Skeleton width="45%" height="10rem" />
        <Skeleton width="45%" height="10rem" />
      </div>
      <div className="flex justify-content-between w-full my-1">
        <Skeleton width="100%" height="7rem" />
      </div>
      <div className="flex justify-content-between align-items-end w-full my-1">
        <Skeleton width="60%" height="5rem" />
        <Skeleton width="30%" height="3rem" />
      </div>
    </div>
  )
}

export default DispatchDocumentSkeleton;