import React from 'react';
import PropTypes from 'prop-types';

// CSS
import styles from './UpgradeDocumentsModal.module.scss';

function UpgradeDocumentsModal(props) {
  const additionalDocuments = 100;
  const totalUpgradedDocumentsSupported = props.documentsSupported + additionalDocuments;
  let upgradedSubscriptionCharge;

  if (props.currentCharge === undefined) {
    upgradedSubscriptionCharge = (props.chargePerDocument * additionalDocuments);
  } else {
    upgradedSubscriptionCharge = (props.chargePerDocument * additionalDocuments) + props.currentCharge;
  }

  return (
    <div>
      You have reached your limit for monthly documents on Switchboard. To continue using Switchboard, upgrade the number of documents supported selecting 'Upgrade Now' below

      <br />

      <div className={styles.monthlyFee}>
        <table><tbody><tr>
          <td>
            Upgrade to support {totalUpgradedDocumentsSupported} documents per month:
            <br />
            ${upgradedSubscriptionCharge / 100} / month
          </td>
          <td className={styles.upgradeButton}>
            <button className="buttonDefault" onClick={() => props.handleUpgrade(upgradedSubscriptionCharge, totalUpgradedDocumentsSupported)} disabled={props.disableButton}>Upgrade</button>
          </td>
        </tr></tbody></table>
      </div>

      <div className={styles.contactUs}>
        Our team is working very hard to make sure your experience with Switchboard is <b>amazing</b>
        <br />
        Please let us know about anything we can do for you at <a href="mailto:support@onswitchboard.com">support@onswitchboard.com</a>
      </div>
    </div>
  );
}

UpgradeDocumentsModal.propTypes = {
  chargePerDocument: PropTypes.number.isRequired,
  currentCharge: PropTypes.number,
  documentsSupported: PropTypes.number.isRequired,
  handleUpgrade: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired,
};

export default UpgradeDocumentsModal;
