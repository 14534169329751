import React, { useState, useEffect } from 'react';

// api
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import Divider from 'sbCore/Divider/Divider';
import VehicleAutocompleteInput from 'sbCore/VehicleAutocomplete/VehicleAutocompleteInput';
import InputNumber from 'sbCore/InputNumber/InputNumber';

// Enums
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Styles
import './style.scss';

/**
 * @description A place to allow support override tools
 * @param {String} eldOverrideType - Determines which tools to show, from ELDEditModal
 * @param {String} [className]
 * @param {ELDEvent} [eldEvent] - Determines single eldEvent edit
 * @param {Function} [onSelectVehicle] - Callback function that returns the selected vehicle
 * @param {Function} [onUpdateOverrideValues] - Tells the parent which values we want to override
 * @returns
 */
function ELDEditSupportTools({ ...props }) {

  // Single ELDEvent Overrides
  const [eldEventTotalVehicleKm, setELDEventTotalVehicleKm] = useState(Math.floor(getAttribute(props.eldEvent, 'totalVehicleKm', true) || 0));

  // functions
  /**
   * @description READ: This is used to override eldEvent values in-place.
   *              It bypasses the normal edit flow. Use with caution!!
   *              There should be a discussion to approve new use-cases to this function
   *              (ex. Discuss if a value we want to override should go through this function or not)
   */
  function onUpdateOverrideValues(eldEvent, attribute, value) {
    let _value = value; // sanitized value to give to parent

    if (attribute === 'totalVehicleKm') {
      if (!_value) _value = 0;
      setELDEventTotalVehicleKm(value);
    }

    if (props.onUpdateOverrideValues) props.onUpdateOverrideValues(eldEvent, { [attribute]: _value });
  }

  let className = 'eldedit-support-tools';
  if (props.className) className += ` ${props.className}`;

  if (!props.eldOverrideType) {
    // If no override type is given, play it safe and show nothing
    return (
      <div />
    );
  }

  if ((props.eldOverrideType === 'override') && !props.eldEvent) return <div />;

  return (
    <div className={className}>
      <Divider />
      <div className="mt-1 font-bold">For Internal Use Only -- Additional Support Override Tools</div>
      <div className="mb-3 text-gray-lighten" style={{ fontSize: '.85em' }}>(You will only see this section as a Support Personnel)</div>
      {(props.eldOverrideType === 'generate') && (
        <div>
          <div>Select a Vehicle for this Log</div>
          <VehicleAutocompleteInput
            placeholder="Vehicle Unit"
            onSelectVehicle={(vehicle) => props.onSelectVehicle(vehicle)}
            suggestionFilters={[new Filter(QueryRestriction.EQUAL_TO, 'enabled', true)]}
          />
        </div>
      )}

      {(props.eldOverrideType === 'override') && (
        <div />
      )}

      {(props.eldOverrideType === 'override') && props.eldEvent && (
        <div className="flex flex-wrap gap-3 p-fluid">
          <div className="flex-auto">
            <label htmlFor="eldEventTotalVehicleKm" className="font-bold block mb-2" style={{ fontSize: '.85em' }}>Odometer Reading (KM)</label>
            <InputNumber
              className="p-inputtext-sm"
              value={eldEventTotalVehicleKm}
              onChange={(e) => onUpdateOverrideValues(props.eldEvent, 'totalVehicleKm', e.value)}
              useGrouping={false}
              variant="small"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ELDEditSupportTools;
