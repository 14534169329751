import Parse from 'parse';
import moment from 'moment-timezone';

import * as ELD from 'api/ELD';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import { setQueryRestriction, batchContainedInQuery, createQuery, setReturnSelectAttributes, getAttribute } from 'api/Parse';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { DriverViolationTypes } from 'enums/DriverPattern';
import { findRecords } from 'api/Parse';


/**
 * @memberof module:DriverPattern
 * @description Function to create a more primitive speeding/idling object to act on
 *
 * @param {integer} type; 1 = violation; 2 = idling; 3 = driving
 * @param {date} startTime
 * @param {date} endTime
 *
 * @returns {object}
 */
function createSpeedIdleObject(objectId, type, startTime, endTime) {
  const dateTime = moment(startTime || endTime).toDate();
  const speedIdleObject = {
    objectId,
    type,
    dateTime,
  };

  if (type === 0) {
    speedIdleObject.isInterim = true;
  } else if (startTime) {
    speedIdleObject.isStart = true;
  } else if (endTime) {
    speedIdleObject.isEnd = true;
  }
  return speedIdleObject;
}

/**
 * @memberof module:DriverPattern
 * @description IN-PLACE determine where and what type of interim events to add in between intervals of driving, violations, nothing, etc
 *
 * @param {array} speedIdleData - array of speed idle datum in a given day (or other interval)
 *
 * @returns {array} updated speedIdleData
 */
function removeAddInterimEvents(speedIdleData, removeOnly) {
  // first remove any existing interim events to start fresh
  let speedIdleDataLen = speedIdleData.length - 1;
  while (speedIdleDataLen > 0) {
    const isInterimEvent = (speedIdleData[speedIdleDataLen].type === 0) || (speedIdleData[speedIdleDataLen].isInterim);
    if (isInterimEvent) speedIdleData.splice(speedIdleDataLen, 1);
    speedIdleDataLen--;
  }

  if (removeOnly) return;

  // working backwards is easier when inserting into an array
  speedIdleDataLen = speedIdleData.length - 1;
  while (speedIdleDataLen > 0) {
    const speedIdleDatum = speedIdleData[speedIdleDataLen];
    const nextSpeedIdleDatum = speedIdleData[speedIdleDataLen + 1];
    const hasNextEvent = speedIdleDatum && nextSpeedIdleDatum;

    // take the status of the next event to resume (ex. if violation ends in the middle of driving event, we need to resume driving right after)
    const isEventBetweenEvents = hasNextEvent && speedIdleDatum.isEnd && nextSpeedIdleDatum.isEnd;

    // normal end of event and start of the next
    const isEventEnd = hasNextEvent && speedIdleDatum.isEnd && nextSpeedIdleDatum.isStart;
    const interimStartTime = moment(speedIdleDatum.dateTime).add(1, 'second').toDate();
    const interimEvent = createSpeedIdleObject(speedIdleDatum.objectId, 0, interimStartTime);

    if (speedIdleDataLen === speedIdleData.length - 1) {
      speedIdleData.push(interimEvent);
    } else if (isEventBetweenEvents) {
      // take the status of the next event to resume (ex. if violation ends in the middle of driving event, we need to resume driving right after)
      interimEvent.type = nextSpeedIdleDatum.type;
      speedIdleData.splice(speedIdleDataLen + 1, 0, interimEvent);
    } else if (isEventEnd) {
      // if this is the end of some sort of interval and the start of the next, insert an interim event
      speedIdleData.splice(speedIdleDataLen + 1, 0, interimEvent);
    }

    speedIdleDataLen--;
  }
}

/**
 * @memberof module:DriverPattern
 * @description Get all speeding violation and idling data (since idling is retrieved along the way) for calling user's drivers (could include company and child companies)
 *
 * @param {date} startDateTime
 * @param {date} endDateTime
 * @param {drivers} array of driver objects
 *
 * @returns {promise}
 */
function getSpeedIdleDataByDriver(startDateTime, endDateTime, drivers = []) {
  const promise = new Promise((resolve, reject) => {
    if (!drivers || drivers.length === 0) {
      resolve([]);
    } else {
      const speedIdleDataByDriver = {};

      // get all daily certs for given drivers (to their timezone)
      const driverELDDailyCertificationPromises = [];
      for (let i = 0; i < drivers.length; i++) {
        const driver = drivers[i];

        // query for each daily cert from startDateTime to endDateTime
        const _startDateTime = moment(startDateTime);
        const _endDateTime = moment(endDateTime);
        let daysDifference = _endDateTime.diff(_startDateTime, 'days');

        // 1 day difference means we just want one days worth of data (July 25 - 26, where 26th is exclusive), which means max iterator should remain as is (== 1)
        // but if we want ex. July 25 - 29, days difference is 4
        // but since we want July 29 inclusive, need to add 1 extra to max iterator (since it will stop at day + (i = 3) = July 28)
        if (daysDifference > 1) {
          daysDifference++;
        }

        for (let j = 0; j < daysDifference; j++) {
          driverELDDailyCertificationPromises.push(ELD.getELDDailyCertification(driver, moment(_startDateTime).add(j, 'day'), true));
        }
      }

      Promise.all(driverELDDailyCertificationPromises).then(
        eldDailyCertifications => {
          // now we have all daily certs of all drivers for the selected interval
          // next job is to get all speed violations associated with the daily cert
          const speedViolationsPromises = [];

          eldDailyCertifications.map(eldDailyCertification => {
            const driver = eldDailyCertification.get('driver');
            const timezoneOffsetFromUTC = driver.get('timezoneOffsetFromUTC') || moment.tz.guess();
            const startTimeUTC = ELD.getELDDailyCertificationIntervalFromDriverTZ(driver, eldDailyCertification.get('startTimeUTC')).dayOf;
            const endTimeUTC = eldDailyCertification.get('endTimeUTC') ? ELD.getELDDailyCertificationIntervalFromDriverTZ(driver, eldDailyCertification.get('endTimeUTC')).dayOf : moment(eldDailyCertification.get('startTimeUTC')).tz(timezoneOffsetFromUTC).add(1, 'day').toDate();

            const speedViolationQuery = new Parse.Query('SpeedViolation');
            speedViolationQuery.greaterThanOrEqualTo('startTime', startTimeUTC);
            speedViolationQuery.lessThan('startTime', endTimeUTC); // want the violations that started within interval
            speedViolationQuery.equalTo('driver', driver);
            speedViolationQuery.ascending('startTime');
            speedViolationQuery.include(['driver', 'vehicle', 'geofence']);

            speedViolationsPromises.push(speedViolationQuery.find());
          });

          Promise.all(speedViolationsPromises).then(
            speedViolations => {
              // Everything from here deals in local time based on driver timezone (ie. data retrieval was based on driver timezone, data manipulation is now dealt locally based on driver timezone)
              // at this point we have a 1-to-1 relation between each set of speedViolations in speedViolationsPerELDDailyCertification to eldDailyCertification in eldDailyCertifications
              // with eldDailyCertifications being in ascending order. now we categorize everything by driver
              for (let i = 0; i < eldDailyCertifications.length; i++) {
                const eldDailyCertification = eldDailyCertifications[i];
                const driver = eldDailyCertification.get('driver');

                const timezoneOffsetFromUTC = driver.get('timezoneOffsetFromUTC') || moment.tz.guess();
                const startTimeUTC = ELD.getELDDailyCertificationIntervalFromDriverTZ(driver, eldDailyCertification.get('startTimeUTC')).dayOf;
                const endTimeUTC = eldDailyCertification.get('endTimeUTC') ? ELD.getELDDailyCertificationIntervalFromDriverTZ(driver, eldDailyCertification.get('endTimeUTC')).dayOf : moment(eldDailyCertification.get('startTimeUTC')).tz(timezoneOffsetFromUTC).add(1, 'day').toDate();

                const associatedSpeedViolations = speedViolations[i];

                if (!speedIdleDataByDriver[driver.id]) {
                  speedIdleDataByDriver[driver.id] = {
                    driver,
                    speedViolationsByDay: {},
                    speedIdlingObject: {}, // a breakdown of each speedviolation into 2 seperate objects categorized by date
                    eldDailyCertifications: [],
                  }
                }

                const speedIdleDriverData = speedIdleDataByDriver[driver.id];

                // update associated eldDailyCertifications
                speedIdleDriverData.eldDailyCertifications.push(eldDailyCertification);

                const violationDateStart = moment(startTimeUTC); // default violation start is the start of day
                const violationDateEnd = moment(endTimeUTC); // default violation end is the end of day
                const violationDateStartMs = violationDateStart.valueOf();

                // update speedViolationsByDay
                if (!speedIdleDriverData.speedViolationsByDay[violationDateStartMs] && (associatedSpeedViolations.length > 0)) {
                  speedIdleDriverData.speedViolationsByDay[violationDateStartMs] = [];
                  speedIdleDriverData.speedViolationsByDay[violationDateStartMs].push.apply(speedIdleDriverData.speedViolationsByDay[violationDateStartMs], associatedSpeedViolations);
                }

                // update speedViolationObject
                if (!speedIdleDriverData.speedIdlingObject[violationDateStartMs]) {
                  speedIdleDriverData.speedIdlingObject[violationDateStartMs] = [];
                }

                associatedSpeedViolations.map(speedViolation => {

                  speedIdleDriverData.speedIdlingObject[violationDateStartMs].push(createSpeedIdleObject(speedViolation.id, 1, speedViolation.get('startTime')));

                  if (moment(speedViolation.get('endTime')).valueOf() > violationDateEnd.valueOf()) { // violation starts in current day, but extends into next day past the daily cert
                    const violationEndDateTimeDayStartMs = moment(speedViolation.get('endTime')).startOf('day').valueOf();

                    if (!speedIdleDriverData.speedIdlingObject[violationEndDateTimeDayStartMs]) {
                      speedIdleDriverData.speedIdlingObject[violationEndDateTimeDayStartMs] = [];
                    }
                    speedIdleDriverData.speedIdlingObject[violationEndDateTimeDayStartMs].push(createSpeedIdleObject(speedViolation.id, 1, undefined, speedViolation.get('endTime')));
                  } else {
                    speedIdleDriverData.speedIdlingObject[violationDateStartMs].push(createSpeedIdleObject(speedViolation.id, 1, undefined, speedViolation.get('endTime')));
                  }

                });

                // need to add 'interim' type of event to signal theres a gap between the end of a violation and the start of 'nothing'
                removeAddInterimEvents(speedIdleDriverData.speedIdlingObject[violationDateStartMs]);

              }

              resolve(speedIdleDataByDriver);
            }
          );

        }
      );
    }
  });
  return promise;
}

/**
 * @memberof module:DriverPattern
 * @description Get the driving blocks of a driver on a given day
 *
 * @param {parseObject} driver
 * @param {parseObject} eldDailyCertification
 *
 * @returns {promise}
 */
function getDrivingPeriods(driver, eldDailyCertifications) {
  const promise = new Promise((resolve, reject) => {
    const drivingPeriodsByDriver = {};
    const dutyStatusPeriodQuery = new Parse.Query('DutyStatusPeriod');
    dutyStatusPeriodQuery.equalTo('fixed', true);
    if (driver) dutyStatusPeriodQuery.equalTo('driver', driver);
    if (eldDailyCertifications) dutyStatusPeriodQuery.containedIn('eldDailyCertification', eldDailyCertifications);
    dutyStatusPeriodQuery.equalTo('eldTypeCode', 13);
    dutyStatusPeriodQuery.ascending('startTime');
    dutyStatusPeriodQuery.equalTo('inactive', false);

    if (eldDailyCertifications && (!eldDailyCertifications.length)) {
      resolve(drivingPeriodsByDriver);
    } else {
      findRecords(dutyStatusPeriodQuery, false, true).then(
        drivingTimePeriods => {
          let prevStartTime;
          let prevEndTime;

          // categorize driving periods by drivers and then by the day belong to
          drivingTimePeriods.map((drivingPeriod, idx) => {
            const driver = drivingPeriod.get('driver');
            const eldDailyCertification = drivingPeriod.get('eldDailyCertification');
            const startTimeUTC = eldDailyCertification.get('startTimeUTC');
            const startTimeUTCMs = moment(startTimeUTC).valueOf();

            let isDrivingTimeOverlapping = false;
            let startTime = moment(drivingPeriod.get('startTime'));
            const endTime = moment(drivingPeriod.get('endTime'));

            if (idx > 0) {
              if (startTime.isSameOrAfter(prevStartTime) && endTime.isSameOrBefore(prevEndTime)) {
                // Skips record whose time was already contained within the previous record (considers duplicates)
                return;
              } else if (startTime.isSameOrAfter(prevStartTime) && startTime.isBefore(prevEndTime)) {
                // Adjusts record which partly overlaps with the previous record
                startTime = prevEndTime;
                isDrivingTimeOverlapping = true;
              }
            }

            if (!isDrivingTimeOverlapping) prevStartTime = startTime;
            prevEndTime = endTime;

            if (!drivingPeriodsByDriver[driver.id]) {
              drivingPeriodsByDriver[driver.id] = {
                drivingTimePeriodsByDay: {},
                drivingTimePeriodsObject: {},
              };
            }

            const driverDrivingPeriod = drivingPeriodsByDriver[driver.id];

            if (!driverDrivingPeriod.drivingTimePeriodsObject[startTimeUTCMs]) {
              driverDrivingPeriod.drivingTimePeriodsObject[startTimeUTCMs] = [];
            }

            // convert driving period objects to speed idling objects and push
            if (!driverDrivingPeriod.drivingTimePeriodsByDay[startTimeUTCMs]) {
              driverDrivingPeriod.drivingTimePeriodsByDay[startTimeUTCMs] = [];
            }
            driverDrivingPeriod.drivingTimePeriodsByDay[startTimeUTCMs].push(drivingPeriod);
            const drivingPeriodDriverDayArray = drivingPeriodsByDriver[driver.id].drivingTimePeriodsObject[startTimeUTCMs];
            drivingPeriodDriverDayArray.push(createSpeedIdleObject(drivingPeriod.id, 3, startTime));
            drivingPeriodDriverDayArray.push(createSpeedIdleObject(drivingPeriod.id, 3, undefined, endTime));
          });

          Object.keys(drivingPeriodsByDriver).map(driverId => {
            Object.keys(drivingPeriodsByDriver[driverId].drivingTimePeriodsObject).map(startTimeUTCMs => {
              removeAddInterimEvents(drivingPeriodsByDriver[driverId].drivingTimePeriodsObject[startTimeUTCMs]);
            });
          });

          resolve(drivingPeriodsByDriver);
        },
        error => {
          reject(error);
        }
      );
    }

  });
  return promise;
}

/**
 * @memberof module:DriverPattern
 * @description Given speed idling events of any or mixed types, sort them given their start time/end time and type
 *
 * @param {array} speedIdingEvents
 */
function sortSpeedIdlingEvents(speedIdingEvents, orderType = 1) {
  // orderType 1 = ascending
  speedIdingEvents.sort((eventA, eventB) => {
    const eventAEventDateTime = eventA.dateTime;
    const eventBEventDateTime = eventB.dateTime;
    const eventATime = eventAEventDateTime.getTime();
    const eventBTime = eventBEventDateTime.getTime();

    // if the two times are the same, use type to determine the sorting order
    const eventATimeArr = [eventAEventDateTime.getDate(), eventAEventDateTime.getHours(), eventAEventDateTime.getMinutes(), eventAEventDateTime.getSeconds()];
    const eventBTimeArr = [eventBEventDateTime.getDate(), eventBEventDateTime.getHours(), eventBEventDateTime.getMinutes(), eventBEventDateTime.getSeconds()];

    if (Helpers.areArraysEqual(eventATimeArr, eventBTimeArr)) {
      // if eventA is type 0||3 and eventB is type (n = any) and eventB isStart, eventA comes first
      // if eventA is type 0||3 and eventB is type (n = any) and eventB isEnd, eventB comes first
      // if eventA is type n and eventB is type 0||3, and eventA isStart, eventB comes first
      // if eventA is type n and eventB is type 0||3, and eventA isEnd, eventA comes first
      if ([0, 3].indexOf(eventA.type) !== -1) {
        if (eventB.isStart) {
          return -1;
        } else {
          return 1;
        }
      } else if ([0, 3].indexOf(eventB.type) !== -1) {
        if (eventA.isStart) {
          return 1;
        } else {
          return -1;
        }
      }
    }

    return eventATime - eventBTime;
  });

  if (orderType === 1) {
    return speedIdingEvents;
  }
  if (orderType === 2) {
    speedIdingEvents.reverse();
    return speedIdingEvents;
  }

  return speedIdingEvents;
}


/**
 * @memberof module:DriverPattern
 * @description ORIGINAL getDriverPatternDurations function: Given list of drivers and an interval, find all associated driver pattern durations mapped to each driver
 *
 * @param {array} drivers
 * @param {date} intervalStart
 * @param {date} intervalEnd
 */
function _getDriverPatternDurations(drivers = [], intervalStart, intervalEnd) {
  const promise = new Promise((resolve, reject) => {
    const driverPatternDurationsByDriver = {};
    const driverPatternDurationQuery = new Parse.Query('DriverPatternDuration');
    driverPatternDurationQuery.containedIn('driver', drivers);
    driverPatternDurationQuery.greaterThanOrEqualTo('startTimeUTC', intervalStart);
    driverPatternDurationQuery.lessThanOrEqualTo('startTimeUTC', intervalEnd);
    driverPatternDurationQuery.ascending('startTimeUTC');

    Getters.getAllFromQuery(driverPatternDurationQuery).then(
      driverPatternDurations => {
        driverPatternDurations.map(driverPatternDuration => {
          const driver = driverPatternDuration.get('driver');
          const driverId = driver && driver.id;
          if (!driverPatternDurationsByDriver[driverId]) {
            driverPatternDurationsByDriver[driverId] = {
              // driver,
              driverPatternDurations: [],
            }
          }
          driverPatternDurationsByDriver[driverId].driverPatternDurations.push(driverPatternDuration);
        });

        resolve(driverPatternDurationsByDriver);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:DriverPattern
 * @description EXPERIEMENTAL getDriverPatternDurations function: Given list of drivers and an interval, find all associated driver pattern durations mapped to each driver
 *
 * @param {array} drivers
 * @param {date} intervalStart
 * @param {date} intervalEnd
 */
async function getDriverPatternDurations(drivers = [], intervalStart, intervalEnd) {
  const driverPatternDurationsByDriver = {};
  if (drivers.length === 0) return driverPatternDurationsByDriver;

  // Get driver's ids for the containedIn search
  const driversPointers = drivers.map(driver => driver.objectId);

  // Extract driver-related records by objectId in driver field for ELDDailyCertification
  const idleTimeQuery = createQuery('ELDDailyCertification');
  setQueryRestriction(idleTimeQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'disabled', true);
  setQueryRestriction(idleTimeQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'startTimeUTC', intervalStart);
  setQueryRestriction(idleTimeQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'startTimeUTC', intervalEnd);
  setReturnSelectAttributes(idleTimeQuery, ['idleTimeMillis', 'driver']);
  // Returns one array with all the drivers data for ELDDailyCertification
  const idleTimePromises = batchContainedInQuery(idleTimeQuery, 'driver', driversPointers);

  // Extract driver-related records by objectId in driver field for DutyStatusPeriod
  const drivingTimeQuery = createQuery('DutyStatusPeriod');
  setQueryRestriction(drivingTimeQuery, QueryRestrictionTypes.EQUAL_TO, 'inactive', false);
  setQueryRestriction(drivingTimeQuery, QueryRestrictionTypes.EQUAL_TO, 'fixed', true);
  setQueryRestriction(drivingTimeQuery, QueryRestrictionTypes.CONTAINED_IN, 'eldTypeCode', [13, 21, 22]);
  setQueryRestriction(drivingTimeQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'startTime', intervalStart);
  setQueryRestriction(drivingTimeQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'startTime', intervalEnd);
  setReturnSelectAttributes(drivingTimeQuery, ['startTime', 'endTime', 'driver', 'eldDailyCertification']);
  // Returns one array with all the drivers data for DutyStatusPeriod
  const drivingTimePromises = batchContainedInQuery(drivingTimeQuery, 'driver', driversPointers);

  // Extract driver-related records by objectId in driver field for SpeedViolation
  const speedViolationQuery = createQuery('SpeedViolation');
  setQueryRestriction(speedViolationQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'startTime', intervalStart);
  setQueryRestriction(speedViolationQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'startTime', intervalEnd);
  setReturnSelectAttributes(speedViolationQuery, ['startTime', 'endTime', 'driver']);
  // Returns one array with all the drivers data for SpeedViolation
  const speedViolationPromises = batchContainedInQuery(speedViolationQuery, 'driver', driversPointers);

  const [idleTimeArray, drivingTimeArray, speedViolationArray] = await Promise.all([
    idleTimePromises,
    drivingTimePromises,
    speedViolationPromises,
  ]);
  // Process into objects through O(n) mapping to object indexed by driver's objectId
  const has = Object.prototype.hasOwnProperty;
  const idleTimeObjects = {};
  const drivingTimeObjects = {};
  const speedViolationObjects = {};

  idleTimeArray.forEach(idleTimeRecord => {
    const driverObjectId = getAttribute(idleTimeRecord, 'driver') && getAttribute(idleTimeRecord, 'driver').id;
    if (driverObjectId) {
      // If driver has an entry then push to their array else create the array
      if (has.call(idleTimeObjects, driverObjectId)) {
        idleTimeObjects[driverObjectId].push(idleTimeRecord);
      } else {
        idleTimeObjects[driverObjectId] = [idleTimeRecord];
      }
    }
  });
  drivingTimeArray.forEach(drivingTimeRecord => {
    const driverObjectId = getAttribute(drivingTimeRecord, 'driver') && getAttribute(drivingTimeRecord, 'driver').id;
    if (driverObjectId) {
      // If driver has an entry then push to their array else create the array
      if (has.call(drivingTimeObjects, driverObjectId)) {
        drivingTimeObjects[driverObjectId].push(drivingTimeRecord);
      } else {
        drivingTimeObjects[driverObjectId] = [drivingTimeRecord];
      }
    }
  });
  speedViolationArray.forEach(speedViolationRecord => {
    const driverObjectId = getAttribute(speedViolationRecord, 'driver') && getAttribute(speedViolationRecord, 'driver').id;
    if (driverObjectId) {
      // If driver has an entry then push to their array else create the array
      if (has.call(speedViolationObjects, driverObjectId)) {
        speedViolationObjects[driverObjectId].push(speedViolationRecord);
      } else {
        speedViolationObjects[driverObjectId] = [speedViolationRecord];
      }
    }
  });

  // now accumulate all the values together by driver
  drivers.map((driver) => {
    driverPatternDurationsByDriver[driver.objectId] = {
      driver,
      drivingTimeMillis: 0,
      idleTimeMillis: 0,
      speedViolationMillis: 0,
      speedingToDrivingRatio: 0,
      idlingToDrivingRatio: 0,
    };
    const index = driver.objectId;
    const driverPatternDuration = driverPatternDurationsByDriver[driver.objectId]; // assign to constant to reference more easily

    // calculate idling times
    const driverIdleTimeObjects = idleTimeObjects[index];
    if (driverIdleTimeObjects) {
      driverIdleTimeObjects.map(idleTimeObject => {
        driverPatternDuration.idleTimeMillis += (idleTimeObject.get('idleTimeMillis') || 0);
      });
    }
    // calculate driving times
    let driverDrivingTimeObjects = drivingTimeObjects[index];

    // sort the duty status period records in ascending order by startTime and filter out any records without an eldDailyCertification
    driverDrivingTimeObjects = driverDrivingTimeObjects && driverDrivingTimeObjects.sort((driverDrivingTimeObjectsA, driverDrivingTimeObjectsB) => moment(driverDrivingTimeObjectsA.get('startTime')) - moment(driverDrivingTimeObjectsB.get('startTime')));
    driverDrivingTimeObjects = driverDrivingTimeObjects && driverDrivingTimeObjects.filter((drivingTimeObject) => drivingTimeObject.get('eldDailyCertification'));

    let prevStartTime;
    let prevEndTime;

    if (driverDrivingTimeObjects) {
      driverDrivingTimeObjects.map((drivingTimeObject, idx) => {
        let isDrivingTimeOverlapping = false;
        let startTime = moment(drivingTimeObject.get('startTime'));
        const endTime = moment(drivingTimeObject.get('endTime'));

        if (idx > 0) {
          if (startTime.isSameOrAfter(prevStartTime) && endTime.isSameOrBefore(prevEndTime)) {
            // Skips record whose time was already contained within the previous record (considers duplicates)
            return;
          } else if (startTime.isSameOrAfter(prevStartTime) && startTime.isBefore(prevEndTime)) {
            // Adjusts record which partly overlaps with the previous record
            startTime = prevEndTime;
            isDrivingTimeOverlapping = true;
          }
        }

        if (!isDrivingTimeOverlapping) prevStartTime = startTime;
        prevEndTime = endTime;
        driverPatternDuration.drivingTimeMillis += endTime.diff(startTime);
      });
    }
    // calculate speed violation times
    // for speed violation times, there could be duplicate entries with regards to multiple violations with the same startTime
    // therefore, we will only take the one with the longest duration (endTime - startTime)
    const driverSpeedViolationObjects = speedViolationObjects[index];
    const seenDriverSpeedViolationMillisByStartTime = {};
    if (driverSpeedViolationObjects) {
      driverSpeedViolationObjects.map(speedViolationObject => {
        const startTime = moment(speedViolationObject.get('startTime'));
        const endTime = moment(speedViolationObject.get('endTime'));
        const duration = endTime.diff(startTime);
        if (!seenDriverSpeedViolationMillisByStartTime[startTime.valueOf()]) {
          seenDriverSpeedViolationMillisByStartTime[startTime.valueOf()] = duration;
        } else {
          // we've seen this start time before, so if it's a greater duration then use that instead
          if (duration > seenDriverSpeedViolationMillisByStartTime[startTime.valueOf()]) {
            seenDriverSpeedViolationMillisByStartTime[startTime.valueOf()] = duration;
          }
        }
      });
    }
    // now accumulate the violation durations
    Object.keys(seenDriverSpeedViolationMillisByStartTime).map(startTimeMillis => {
      driverPatternDuration.speedViolationMillis += seenDriverSpeedViolationMillisByStartTime[startTimeMillis];
    });

    // now calculate the ratios
    if (driverPatternDuration.drivingTimeMillis) {
      driverPatternDuration.speedingToDrivingRatio = driverPatternDuration.speedViolationMillis / driverPatternDuration.drivingTimeMillis;
      driverPatternDuration.idlingToDrivingRatio = driverPatternDuration.idleTimeMillis / driverPatternDuration.drivingTimeMillis;
    }
  });

  return driverPatternDurationsByDriver;
}

/**
 * @memberof module:DriverPattern
 * @description Given list of drivers and an interval, find all associated given driver violation type mapped to each driver, by count
 *
 * @param {array} drivers
 * @param {date} intervalStart
 * @param {date} intervalEnd
 */
async function getDriverViolationsByCount(drivers = [], driverViolationType = DriverViolationTypes.HOS_VIOLATION, intervalStart, intervalEnd) {
    const violationsCountByDriver = {};
    const violationQueries = [];
    const queryClasses = {
      [DriverViolationTypes.HOS_VIOLATION]: 'HOSViolation',
      [DriverViolationTypes.SPEED_VIOLATION]: 'SpeedViolation',
    };


    drivers.map(driver => {
      const violationQuery = new Parse.Query(queryClasses[driverViolationType]);
      violationQuery.equalTo('driver', driver);
      if (driverViolationType === DriverViolationTypes.HOS_VIOLATION) {
        violationQuery.equalTo('disabled', false);
        violationQuery.greaterThanOrEqualTo('triggerTime', intervalStart);
        violationQuery.lessThanOrEqualTo('triggerTime', intervalEnd);
      } else if (driverViolationType === DriverViolationTypes.SPEED_VIOLATION) {
        violationQuery.greaterThanOrEqualTo('startTime', intervalStart);
        violationQuery.lessThanOrEqualTo('endTime', intervalEnd);
      }

      violationQueries.push(violationQuery.count());
    });

    const violationCounts = await Promise.all(violationQueries);

    drivers.map((driver, index) => {
      violationsCountByDriver[driver.id] = { driver, count: violationCounts[index] };
    });

    return violationsCountByDriver;
}

export {
  createSpeedIdleObject,
  getDriverPatternDurations,
  getDrivingPeriods,
  getDriverViolationsByCount,
  getSpeedIdleDataByDriver,
  removeAddInterimEvents,
  sortSpeedIdlingEvents,
};
