import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import history from 'sbHistory'

// api
import { getCurrentUser, getAttribute } from 'api/Parse';
import { getQueryParameter } from 'api/URL';
import { getCurrentDispatcher } from 'api/Getters';

import { isSubscribedToModule } from 'api/Helpers'
import { getELDEdits } from 'api/ELD';

// components
import DriversHelpDialog from './DriversHelpDialog';
import AddDriver from 'components/Drivers/AddDriver/AddDriver';

import Title from 'components/Shared/Title/Title';
import SBCardEmptyContent from 'components/Shared/SBCard/SBCardEmptyContent';
import SubNavigationBar from 'components/Shared/SubNavigationBar/SubNavigationBar'

import DriversTable from 'components/Drivers/DriversTable/DriversTable';
import UnidentifiedDriversTable from 'components/UnidentifiedDriversTable/container/UnidentifiedDriversTable';
import ELDEditsTable from 'components/ELDEdit/container/ELDEditsTable';
import HOSLogDownloadHistory from 'components/HOSLogDownloadHistory/HOSLogDownloadHistory';
import UncertifiedLogs from 'components/UncertifiedLogs/UncertifiedLogs';
import DriverGroupContainer from 'components/DriverGroup/DriverGroupContainer/DriverGroupContainer';

import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

// css
import styles from './style.module.scss';

class DriversLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      instanceId: uniqid(),
      isLoading: false,
      disableDriversAddDriver: true,
      disableELDEditFeature: true,
      isSubscribedToELDModule: false,
      isAOBRDEnabled: false,
      eldEdits: [],
      views: {
        DRIVERS: 'drivers',
        DRIVER_GROUPS: 'drivergroups',
      },
    };

    this.toggleLoadingState = this.toggleLoadingState.bind(this);
    this.getELDEdits = this.getELDEdits.bind(this)
    this.refreshState = this.refreshState.bind(this);
  }

  componentDidMount() {
    document.title = t('Drivers - Switchboard');
    window.Localize.translatePage();
    this.refreshState();
  }

  async toggleLoadingState(state) {
    return await this.setState({ ...state, isLoading: !state.isLoading });
  }

  async getELDEdits() {
    const completedEditStatuses = [3, 4, 5];
    return await getELDEdits(undefined, undefined, completedEditStatuses);
  }

  async refreshState() {
    await this.toggleLoadingState(this.state);
    let currentView = this.props.location ? getQueryParameter(this.props.location.search, 'view') : undefined

    const newState = { ...this.state };
    const currentUser = getCurrentUser();
    const userSpecialPermission = getAttribute(currentUser, 'userSpecialPermission');

    // if the company is not granted access to the driver module
    if (userSpecialPermission && getAttribute(userSpecialPermission, 'disableDriversModule')) {
      return history.push({ pathname: '404' });
    }

    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

    // this particular user is not allowed to add drivers
    newState.disableDriversAddDriver = userSpecialPermission && getAttribute(userSpecialPermission, 'disableDriversAddDriver');
    newState.disableELDEditFeature = userSpecialPermission && getAttribute(userSpecialPermission, 'disableEldShowEditHistory');

    const aobrdSetting = belongsToCompany && getAttribute(belongsToCompany, 'aobrdSetting');
    newState.isAOBRDEnabled = aobrdSetting && getAttribute(aobrdSetting, 'aobrdEnabled');

    newState.isSubscribedToELDModule = isSubscribedToModule('eldModule'); // if company has eld module

    // redirect them if they try to manual route to the view, but they're not allowed
    if ((currentView === newState.views.AOBRD_EDITS) && !(newState.isAOBRDEnabled && !newState.disableELDEditFeature)) {
      return history.push({ pathname: '404' });
    }
    if ((currentView === newState.views.ASSIGN_UNIDENTIFIED) && (!newState.isSubscribedToELDModule)) {
      return history.push({ pathname: '404' });
    }

    newState.uniqid = uniqid();
    newState.eldEdits = await this.getELDEdits();

    return await this.toggleLoadingState(newState);
  }


  render() {
    const { props, state } = this;

    let currentView = props.location ? getQueryParameter(props.location.search, 'view') : undefined;

    let title = 'Drivers';
    let navItems = [
      {
        name: 'Drivers List',
        onClick: () => { title = 'Drivers'; this.refreshState(); },
        isActive: !currentView || (currentView === state.views.DRIVERS),
        to: '?'
      },
      {
        name: 'Driver Groups',
        onClick: () => { title = 'Driver Groups' },
        isActive: currentView === state.views.DRIVER_GROUPS,
        to: '?view=drivergroups'
      },
    ]

    // if (state.isSubscribedToELDModule) {
    //   navItems.push({
    //     name: 'Unidentified (ELD)',
    //     onClick: () => { title = 'Unidentified Drivers By Vehicle' },
    //     isActive: currentView === state.views.ASSIGN_UNIDENTIFIED,
    //     to: '?view=assignuddrivingtime'
    //   })
    // }

    // if (state.isAOBRDEnabled && !state.disableELDEditFeature) {
    //   const unhandledELDEdits = state.eldEdits.filter(eldEdit => [3].indexOf(getAttribute(eldEdit, 'completed')) !== -1);

    //   const aobrdEditsNavItem = {
    //     name: 'Driver Edits (AOBRD)',
    //     onClick: () => { title = 'AOBRD Driver Requested Edits' },
    //     isActive: currentView === state.views.AOBRD_EDITS,
    //     to: '?view=aobrddriveredits'
    //   };

    //   if (unhandledELDEdits.length > 0) { // add a badge with number of unconfirmed edits
    //     aobrdEditsNavItem.badge = { text: unhandledELDEdits.length + '', color: 'danger' };
    //   }

    //   navItems.push(aobrdEditsNavItem);
    // }

    window.Localize.translatePage();

    return (
      <MDBContainer className={`${styles.driversLayout}`} fluid>
        {/* <Title className="translate-me" title={title}>

          <div className="text-right">
            {!state.disableDriversAddDriver &&
              <AddDriver
                refreshState={this.refreshState}
                containerClassName="mx-1 float-right align-middle"
                callback={this.refreshState}
              />
            }

            <DriversHelpDialog
              buttonStyle={{
                verticalAlign: 'middle',
                margin: '.5em',
                marginTop: '0em',
                backgroundColor: 'rgba(0, 0, 0, .25)',
              }}
            />
          </div>
        </Title> */}

        <MDBRow>
          <MDBCol>
            <SubNavigationBar containerClassName="mb-3 translate-me" navItems={navItems} />
          </MDBCol>
          <MDBCol>
            <div className="text-right">
              {!state.disableDriversAddDriver &&
                <AddDriver
                  refreshState={this.refreshState}
                  containerClassName="mx-1 float-right align-middle"
                  callback={this.refreshState}
                />
              }

              <DriversHelpDialog
                buttonStyle={{
                  verticalAlign: 'middle',
                  margin: '.5em',
                  marginTop: '0em',
                  backgroundColor: 'rgba(0, 0, 0, .25)',
                }}
              />
            </div>
          </MDBCol>
        </MDBRow>

        {state.isLoading && (
          <SBCardEmptyContent isContentLoader className="translate-me">{'Refreshing Data'}</SBCardEmptyContent>
        )}

        {((!currentView) || (currentView === state.views.DRIVERS)) &&
          <MDBRow><MDBCol>
            <div className={styles.driversTable}>
              <DriversTable parentId={state.uniqid} />
            </div>
          </MDBCol></MDBRow>
        }

        {(currentView === state.views.DRIVER_GROUPS) &&
          <MDBRow>
            <MDBCol>
              <DriverGroupContainer />
            </MDBCol>
          </MDBRow>
        }

        {/** TODO */}
        {(currentView === state.views.LOG_DOWNLOAD) &&
          <MDBRow><MDBCol>
            <div style={{ marginTop: '1.5em' }}>
              This page has been moved to the <div className="inline-block roboto text-blue" style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => history.push({ pathname: 'safety', search: 'view=logstodownload' })}>Safety Module</div>
            </div>
          </MDBCol></MDBRow>
        }

        {/* (currentView === state.views.ASSIGN_UNIDENTIFIED) &&
          <MDBRow><MDBCol>
            <UnidentifiedDriversTable
              dispatcher={getCurrentDispatcher(true)}
            />
          </MDBCol></MDBRow>
        */}

        {/* (currentView === state.views.AOBRD_EDITS) &&
          <MDBRow><MDBCol>
            <ELDEditsTable
              eldEdits={state.eldEdits}
              getELDEdits={this.refreshState}
            />
          </MDBCol></MDBRow>
        */}
        {/** TODO */}
        {(currentView === state.views.UNCERTIFIED_LOGS) &&
          <MDBRow><MDBCol>
            <div style={{ marginTop: '1.5em' }}>
              This page has been moved to the <div className="inline-block roboto text-blue" style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => history.push({ pathname: 'safety', search: 'view=uncertifiedlogs' })}>Safety Module</div>
            </div>
          </MDBCol></MDBRow>
        }

      </MDBContainer>
    );
  }
}

DriversLayout.propTypes = {
  location: PropTypes.object.isRequired,
};

export default DriversLayout;
