import uniqid from 'uniqid';
import React, { useEffect, useState, useContext } from "react";
import { PDFExport } from '@progress/kendo-react-pdf';

// CSAPI
import Filter from "sb-csapi/dist/sbObjects/Filter";
import Timezones from 'sb-csapi/dist/lists/timezones';
import { CountryName } from 'sb-csapi/dist/enums/Country';
import { QueryRestrictionTypes } from "sb-csapi/dist/enums/Query";
import { CommodityTypes } from 'sb-csapi/dist/enums/Dispatch/Commodity';
import { getAttribute, getCurrentUserCompanyObjectId, getObjectById } from "sb-csapi/dist/AAPI";

// API
import { getDispatchItems } from "api/Dispatch/DispatchItem";
import { getDispatchDrivers } from "api/Dispatch/DispatchDriver";
import { getDispatchTrailers } from "api/Dispatch/DispatchTrailer";
import { getDispatchVehicles } from "api/Dispatch/DispatchVehicle";

// ENUMS
/** @todo bring to csapi */
import { DispatchReferenceTypes } from 'enums/DispatchReference';
import { FreightCategory } from 'sb-csapi/dist/enums/Dispatch/Freight';
import { Document as DocumentTypes } from 'sb-csapi/dist/enums/Dispatch/Document';

// Components
import DocumentHeader from "components/Dispatch/DispatchDocument/DispatchDocuments/Shared/Header/Header";
import DocumentInputDropdown from "components/Dispatch/DispatchDocument/DispatchDocuments/Shared/Dropdown/Dropdown";
import DocumentInputNumber from "components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputNumber/InputNumber";
import DocumentInputText from "components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputText/InputText";
import DocumentInputTextarea from "components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputTextarea/InputTextarea";
import BOLTable from "components/Dispatch/DispatchDocument/DispatchDocuments/Generators/BOL/BOLTable";

import { LengthUnit, MassUnit } from 'enums/DispatchUnit';

// Context
import DispatchJobLayoutContext from 'contexts/DispatchJobLayoutContext';
import DispatchDocumentsLayoutContext from 'contexts/DispatchDocumentsLayoutContext';

function generateEmptyDispatchItemData() {
  return (
    {
      id: uniqid(),
      dispatchTransferObjectId: undefined,
      shipperDispatchOrganization: undefined,
      consigneeDispatchOrganization: undefined,
      dispatchItem: {
        commodityType: undefined,
        quantity: 0,
        category: 0,
        dimensions: undefined,
        weight: 0,
        massUnitStr: undefined,
        notes: undefined,
      }
    }
  );
}

/**
 * @description The document generator for BOL
 * @param {String} dispatchJobObjectId - the DispatchJob object id
 * @param {String} documentState - contains the state of the latest document in a stringified JSON format
 * @param {Array} dispatchTransfers - Dispatch transfers belonging to the dispatch job
 * @param {Object} referenceNumbersStringObj - An object containing reference numbers sorted by type and within a string separated by commas
 * @param {Function} downloadDispatchDocument - Callback function to generate a DispatchDocument with the current information
 * @param {Boolean} isDownloaded - The current isDownloaded boolean value
 * @param {Function} toggleDownloaded - Sets isDownloaded to the given boolean value
 * @returns {Component}
 */
function BOL({ ...props }) {
  const [userCompany, setUserCompany] = useState(undefined);
  const [dispatchJob, setDispatchJob] = useState(undefined);  
  const [dispatchItemData, setDispatchItemData] = useState([
    generateEmptyDispatchItemData()
  ]);          // Array of DispatchItem records
  const [showBOLTableActions, setShowBOLTableActions] = useState(true);
  const [documentHasLoaded, setDocumentHasLoaded] = useState(false);

  // ** Context ** //
  const { dispatchDocumentTypesToSave, setIsDocumentOutdatedPrompt } = useContext(DispatchJobLayoutContext);
  const { saveDispatchDocumentState, checkDocumentForUnsavedChanges } = useContext(DispatchDocumentsLayoutContext);

  // State holding all fields
  const [documentStateObj, setDocumentStateObj] = useState({
    shipmentNumber: '',
    referenceNumber: '',
    shipmentDate: '',
    shipmentTime: '',
    timezone: '',
    truckNumbers: '',
    trailerNumbers: '',
    driverNames: '',
    referenceNumbers: '',
    customsBroker: '',
    protectiveService: '',
    declaredValue: '',
    liabilityInformation: 'Maximum Liability $2.00 CAD/pound unless declared value states otherwise.\nIf product(s) to be protected from Heat or Frost, state required temperature.',
    notes: '',
    shipperSignature: '',
    shipperPrintName: '',
    shipperSignatureDate: '',
    consigneeSignature: '',
    consigneePrintName: '',
    consigneeSignatureDate: '',
    noticeOfClaim: 'NOTICE OF CLAIM (a) No carrier is liable for loss, damage or delay to any goods under the Bill of Lading unless notice thereof setting out particulars of the origin, destination and date of shipment of the goods and the estimated amount claimed in respect of such loss, damage or delay is given in writing to the originating carrier or the delivering carrier within sixty (60) days after the delivery of the goods or in the case of failure to make delivery within nine (9) months from the date of shipment. (b) The final statement of the claim must be filed within nine (9) months from the date of shipment together with a copy of the paid freight bill.',
  });

  // Initial useEffect that runs when the component is mounted - obtains the DispatchJob and DispatchTransfers associated with the job
  useEffect(() => {
    let didCancel = false;

    async function getDispatchJobandTransfers() {
      if (!didCancel) {
        const userCompanyObjectId = getCurrentUserCompanyObjectId();
        const userCompany = await getObjectById(undefined, 'Company', userCompanyObjectId);

        // Get the DispatchJob for referenceNumber (reference number), batchId (shipper number)
        const dispatchJob = await getObjectById(undefined, 'DispatchJob', props.dispatchJobObjectId);

        setUserCompany(userCompany);
        setDispatchJob(dispatchJob);
      }
    }

    getDispatchJobandTransfers();
    return () => { didCancel = true };
  }, [props.dispatchJobObjectId]);

  // useEffect that triggers whenever there is an update the to dispatchTransfers
  // Grabs all the dispatchItems for all the DispatchTransfers
  // Also triggers when there's an update to dispatchJob to reflect the changes in the documents without having to reload
  useEffect(() => {
    let didCancel = false;

    async function fetchBOLTableInformation() {
      if (!didCancel) {
        const dispatchItemsSortedByDispatchTransferObj = {};

        // Iterates through each dispatch transfer, and retrieves all the dispatch items from each transfer
        const dispatchItemsFromDispatchTransferPromises = props.dispatchTransfers.map((dispatchTransfer) => {
          const dispatchTransferObjectId = getAttribute(dispatchTransfer, 'objectId');

          // Fetch the shipper/consignee information for this transfer
          const consigneeDispatchOrganization = getAttribute(dispatchTransfer, 'consigneeDispatchOrganization');
          const shipperDispatchOrganization = getAttribute(dispatchTransfer, 'shipperDispatchOrganization');

          if (!dispatchItemsSortedByDispatchTransferObj[dispatchTransferObjectId]) dispatchItemsSortedByDispatchTransferObj[dispatchTransferObjectId] = { dispatchTransferObjectId: dispatchTransferObjectId };

          // consignee
          if (consigneeDispatchOrganization) {
            const name = getAttribute(consigneeDispatchOrganization, 'organizationName', true) || '-';
            const address = getAttribute(consigneeDispatchOrganization, 'address', true);

            let addressString = '-';
            let city = '-';
            let stateProvince = '-';
            let zipPostal = '-';
            let country = '-';

            if (address) {
              addressString = getAttribute(address, 'address', true) || '-';
              city = getAttribute(address, 'city', true) || '-';
              stateProvince = getAttribute(address, 'stateProvince', true) || '-';
              zipPostal = getAttribute(address, 'zipPostal', true) || '-';
              country = CountryName[getAttribute(address, 'country', true)] || '-';
            }

            const consigneeOrganizationInformation = `${name}\n${addressString}\n${city}, ${stateProvince}\n${zipPostal}, ${country}`;
            dispatchItemsSortedByDispatchTransferObj[dispatchTransferObjectId]['consigneeDispatchOrganization'] = consigneeOrganizationInformation;
          }

          // shipper
          if (shipperDispatchOrganization) {
            const name = getAttribute(shipperDispatchOrganization, 'organizationName', true) || '-';
            const address = getAttribute(shipperDispatchOrganization, 'address', true) || '-';

            let addressString = '-';
            let city = '-';
            let stateProvince = '-';
            let zipPostal = '-';
            let country = '-';

            if (address) {
              addressString = getAttribute(address, 'address', true) || '-';
              city = getAttribute(address, 'city', true) || '-';
              stateProvince = getAttribute(address, 'stateProvince', true) || '-';
              zipPostal = getAttribute(address, 'zipPostal', true) || '-';
              country = CountryName[getAttribute(address, 'country', true)] || '-';
            }

            const shipperOrganizationInformation = `${name}\n${addressString}\n${city}, ${stateProvince}\n${zipPostal}, ${country}`;
            dispatchItemsSortedByDispatchTransferObj[dispatchTransferObjectId]['shipperDispatchOrganization'] = shipperOrganizationInformation;
          }

          const dispatchItemsResult = getDispatchItems(
            undefined,                          // options - default
            undefined,                          // dispatchJobObjectId - default
            [new Filter(QueryRestrictionTypes.EQUAL_TO, 'dispatchTransfer', dispatchTransferObjectId)],                          // filters
            undefined,                          // sort - default
            [],                                 // includes
            undefined,                          // selects
            undefined,                          // page
            undefined,                          // limit
            true                                // query all
          );
          return dispatchItemsResult;
        });

        const dispatchItemsFromDispatchTransferArr = await Promise.all(dispatchItemsFromDispatchTransferPromises);

        // Once all the dispatchItems have been retrieved for all the dispatch transfers, 
        dispatchItemsFromDispatchTransferArr.map(({ totalDispatchItemsCount, dispatchItems }) => {
          if (!totalDispatchItemsCount) return;

          const dispatchTransfer = dispatchItems && getAttribute(dispatchItems[0], 'dispatchTransfer');
          const dispatchTransferObjectId = dispatchTransfer && getAttribute(dispatchTransfer, 'objectId');

          // Go through the dispatchItem array, and grab the necessary params for the BOL table
          const dispatchItemsState = dispatchItems.map((dispatchItem) => {
            const commodityType = getAttribute(dispatchItem, 'commodityType');
            const commodityTypeCustomName = (CommodityTypes[commodityType] === CommodityTypes[0] ? getAttribute(dispatchItem, 'commodityTypeCustomName') : '');
            const quantity = getAttribute(dispatchItem, 'quantity');
            const categoryInt = getAttribute(dispatchItem, 'category');
            const categoryStr = categoryInt ? Object.values(FreightCategory).find((category) => category.type === categoryInt)?.description : '-';
            const weight = getAttribute(dispatchItem, 'weight') || '0';
            const massUnit = getAttribute(dispatchItem, 'massUnit');
            const itemLength = getAttribute(dispatchItem, 'itemLength') || '0';
            const width = getAttribute(dispatchItem, 'width') || '0';
            const height = getAttribute(dispatchItem, 'height') || '0';
            const lengthUnit = getAttribute(dispatchItem, 'lengthUnit');
            const name = getAttribute(dispatchItem, 'name'); // but this is actually the freight notes

            const dimensionsText = `${itemLength}L x ${width}W x ${height}H`;
            const massUnitStr = massUnit ? MassUnit[massUnit].toLowerCase() : '';

            return {
              commodityType: commodityTypeCustomName || CommodityTypes[commodityType],
              quantity,
              categoryStr,
              dimensions: dimensionsText,
              weight,
              massUnitStr,
              notes: name
            }
          });

          dispatchItemsSortedByDispatchTransferObj[dispatchTransferObjectId]['dispatchItems'] = dispatchItemsState;
        });

        let _dispatchItemData = [];

        // Now, format the data into the expected table row data used in BOLTable
        Object.values(dispatchItemsSortedByDispatchTransferObj).map((dispatchFreightByTransferId) => {
          const { dispatchTransferObjectId, consigneeDispatchOrganization, shipperDispatchOrganization, dispatchItems } = dispatchFreightByTransferId;

          dispatchItems && dispatchItems.map((dispatchItem, index) => {

            _dispatchItemData.push({
              id: uniqid(),
              dispatchTransferObjectId,
              consigneeDispatchOrganization,
              shipperDispatchOrganization,
              dispatchItem,
            });
          });
        });

        if (_dispatchItemData.length === 0) {
          _dispatchItemData.push(generateEmptyDispatchItemData());
        }

        setDispatchItemData(_dispatchItemData);
      }
    }
    if (props.dispatchTransfers) fetchBOLTableInformation();
    return () => { didCancel = true; };
  }, [props.dispatchTransfers, props.dispatchJob]);

  // If a document state exists, update the document fields with the state's values. This also runs whenever dispatchJob updates
  // to override the default shipment and reference number values with the existing document state's values.
  useEffect(() => {
    let didCancel = false;
    
    let latestDocumentStateObj = {};
    if (props.documentState) {
      latestDocumentStateObj = JSON.parse(props.documentState); // De-string the documentState object
    }

    // Fetch dispatch leg info and update document fields with dispatch leg info and latest document state
    async function fetchDispatchLegInformation() {
      let batchId;
      if (dispatchJob) {
        batchId = getAttribute(dispatchJob, 'batchId');
      }
      
      const dispatchLegInformationObj = {};

      // Iterates through each dispatch transfer, and retrieve the object Id
      const dispatchLegInfo = props.dispatchTransfers.map(async (dispatchTransfer) => {
        const dispatchTransferObjectId = getAttribute(dispatchTransfer, 'objectId');
        
        // Fetch driver/vehicle/trailer information associated with this dispatch transfer object Id
        const { dispatchDrivers } = await getDispatchDrivers(undefined, dispatchTransferObjectId, undefined, undefined, ['driver']);
        let driverName;
        if (dispatchDrivers[0]) {
          const driver = getAttribute(dispatchDrivers[0], 'driver');
          driverName = getAttribute(driver, 'user_fullName');
        }

        const { dispatchVehicles } = await getDispatchVehicles(undefined, dispatchTransferObjectId, undefined, undefined, ['vehicle'])
        let vehicleId;
        if (dispatchVehicles[0]) {
          const vehicle = getAttribute(dispatchVehicles[0], 'vehicle');
          vehicleId = getAttribute(vehicle, 'unitId');
        }
  
        const { dispatchTrailers } = await getDispatchTrailers(undefined, dispatchTransferObjectId, undefined, undefined, ['trailer'])
        let trailerId;
        if (dispatchTrailers[0]) {
          const trailer = getAttribute(dispatchTrailers[0], 'trailer');
          trailerId = getAttribute(trailer, 'unitId');
        }

        if (!dispatchLegInformationObj[dispatchTransferObjectId]) dispatchLegInformationObj[dispatchTransferObjectId] = { dispatchTransferObjectId: dispatchTransferObjectId }

        dispatchLegInformationObj[dispatchTransferObjectId]['driver_name'] = driverName;
        dispatchLegInformationObj[dispatchTransferObjectId]['vehicle_id'] = vehicleId;
        dispatchLegInformationObj[dispatchTransferObjectId]['trailer_id'] = trailerId;

        return dispatchLegInformationObj;
      })

      await Promise.all(dispatchLegInfo);

      let legDriverNameArr = []
      let legVehicleIdArr = []
      let legTrailerIdArr = []

      
      Object.keys(dispatchLegInformationObj).forEach(key => {
        if (dispatchLegInformationObj[key]['driver_name']) legDriverNameArr.push(dispatchLegInformationObj[key]['driver_name'])
        if (dispatchLegInformationObj[key]['vehicle_id']) legVehicleIdArr.push(dispatchLegInformationObj[key]['vehicle_id'])
        if (dispatchLegInformationObj[key]['trailer_id']) legTrailerIdArr.push(dispatchLegInformationObj[key]['trailer_id'])
      });

      const formattedDriverName = legDriverNameArr.join(', ');
      const formattedVehicleId = legVehicleIdArr.join(', ');
      const formattedTrailerId = legTrailerIdArr.join(', ');

      if (!didCancel) {
        setDocumentStateObj({
          ...documentStateObj,
          shipmentNumber: latestDocumentStateObj.shipmentNumber,
          referenceNumber: latestDocumentStateObj.referenceNumber,
          shipmentDate: latestDocumentStateObj.shipmentDate,
          shipmentTime: latestDocumentStateObj.shipmentTime,
          timezone: latestDocumentStateObj.timezone,
          shipmentNumber: batchId,
          truckNumbers: formattedVehicleId,
          trailerNumbers: formattedTrailerId,
          driverNames: formattedDriverName,
          referenceNumbers: latestDocumentStateObj.referenceNumbers,
          customsBroker: latestDocumentStateObj.customsBroker,
          protectiveService: latestDocumentStateObj.protectiveService,
          declaredValue: latestDocumentStateObj.declaredValue,
          liabilityInformation: latestDocumentStateObj.liabilityInformation ? latestDocumentStateObj.liabilityInformation : documentStateObj.liabilityInformation,
          notes: latestDocumentStateObj.notes,
          shipperSignature: latestDocumentStateObj.shipperSignature,
          shipperPrintName: latestDocumentStateObj.shipperPrintName,
          shipperSignatureDate: latestDocumentStateObj.shipperSignatureDate,
          consigneeSignature: latestDocumentStateObj.consigneeSignature,
          consigneePrintName: latestDocumentStateObj.consigneePrintName,
          consigneeSignatureDate: latestDocumentStateObj.consigneeSignatureDate,
          noticeOfClaim: latestDocumentStateObj.noticeOfClaim ? latestDocumentStateObj.noticeOfClaim : documentStateObj.noticeOfClaim,
        })
      }
      return {
        formattedDriverName: formattedDriverName,
        formattedVehicleId: formattedVehicleId,
        formattedTrailerId: formattedTrailerId,
      }
    }

    fetchDispatchLegInformation();

    return () => { didCancel = true; };

  }, [props.documentState, dispatchJob]); 

  // Update document information when general information section has an update
  useEffect(() => {
    let batchId;
    if (dispatchJob) {
      batchId = getAttribute(dispatchJob, 'batchId');
    }

    setDocumentStateObj({
      ...documentStateObj,
      shipmentNumber: batchId,
    })
  }, [props.generalInformationState]);

  // If isDownloaded is true, generate a new invoice document
  useEffect(() => {
    if (!props.isDownloaded) return;
    downloadDispatchDocument();
  }, [props.isDownloaded]);

  // Download the new document with the parent callback function
  async function downloadDispatchDocument() {
    if (props.downloadDispatchDocument) await props.downloadDispatchDocument(documentStateObj);
    if (props.toggleDownloaded) props.toggleDownloaded(false);
  }

  function updateDispatchItemsInformation(data) {
    setDispatchItemData(data);
  }

  // Update reference number
  useEffect(() => {
    let didCancel = false;
    let referenceNumber = '';
    if (dispatchJob) {
      referenceNumber = getAttribute(dispatchJob, 'referenceNumber');
    }
    
    if (!didCancel) {
      setDocumentStateObj({
        ...documentStateObj,
        referenceNumber,
      });
    }
    return () => { didCancel = true; }
  }, [props.isRefreshState]);

  // Update reference numbers when props.referenceNumbersStringObj updates to reflect changes in the document without having to reload
  useEffect(() => {
    let didCancel = false;
    let referenceNumbersString = '';
    if (props.referenceNumbersStringObj) {
      const jobRefString = props.referenceNumbersStringObj[DispatchReferenceTypes.JOB.key];
      const bolRefString = props.referenceNumbersStringObj[DispatchReferenceTypes.BOL.key];
      const customerRefString = props.referenceNumbersStringObj[DispatchReferenceTypes.CUSTOMER.key];
      const customRefString = props.referenceNumbersStringObj[DispatchReferenceTypes.CUSTOM.key] || '';

      const formattedJobRefString = `Job Ref #: ${jobRefString}\n`;
      const formattedBOLRefString = `BOL Ref #: ${bolRefString}\n`;
      const formattedCustomerRefString = `Customer Ref #: ${customerRefString}\n`;

      const showJobRefString = (jobRefString && formattedJobRefString) || '';
      const showBOLRefString = (bolRefString && formattedBOLRefString) || '';
      const showCustomerRefString = (customerRefString && formattedCustomerRefString) || '';

      referenceNumbersString = (`${showJobRefString}${showBOLRefString}${showCustomerRefString}${customRefString}`) || '';
    }

    if (!didCancel) {
      setDocumentStateObj({
        ...documentStateObj,
        referenceNumbers: referenceNumbersString,
      })
    }
    return () => { didCancel = true; }
  }, [props.referenceNumbersStringObj]);

  // Check if dispatchDocumentTypesToSave contains this document type
  // If it does, save the current document state
  useEffect(() => {
    if (dispatchDocumentTypesToSave.includes(DocumentTypes.CUSTOMER_BILL_OF_LADING.type)) {
      saveCurrentDocumentState();
    }
  }, [dispatchDocumentTypesToSave]);

  async function saveCurrentDocumentState() {
    await saveDispatchDocumentState(documentStateObj);
  }

  useEffect(() => {
    // Confirm the document has its latestdocumentstate loaded before we check for changes to trigger the prompt
    if (!documentHasLoaded && !checkDocumentForUnsavedChanges(JSON.stringify(documentStateObj)) && documentStateObj.shipmentNumber) {
      setDocumentHasLoaded(!checkDocumentForUnsavedChanges(JSON.stringify(documentStateObj)));
    }

    if (documentHasLoaded) setIsDocumentOutdatedPrompt(true);

  }, [documentStateObj]);

  return (
    <div id="bol" className="document-generator-bol">
      <div className="pdf">

        <div className="card">
          <div className="grid mx-7">
            {/* Document Header */}
            <div className="col-12">
              <DocumentHeader
                title={"Bill of Lading"}
                company={userCompany}
              />
            </div>

            {/* BOL Header Information */}
            <div className="col-3">
              <DocumentInputText
                label="Job ID"
                inputClassName="w-full"
                value={documentStateObj.shipmentNumber !== undefined ? documentStateObj.shipmentNumber : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, shipmentNumber: value })}
                success={documentStateObj.shipmentNumber}
                warning={!documentStateObj.shipmentNumber || documentStateObj.shipmentNumber == ''}
                isDownloaded={props.isDownloaded}
              />
            </div>
            <div className="col-3">
              <DocumentInputText
                label="Job Ref #"
                inputClassName="w-full"
                value={documentStateObj.referenceNumber !== undefined ? documentStateObj.referenceNumber : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, referenceNumber: value })}
                success={documentStateObj.referenceNumber}
                warning={!documentStateObj.referenceNumber || documentStateObj.referenceNumber == ''}
                isDownloaded={props.isDownloaded}
              />
            </div>
            <div className="grid grid col-3">
              <div className="col-8">
                <DocumentInputText
                  label="Date"
                  inputClassName="w-full"
                  value={documentStateObj.shipmentDate !== undefined ? documentStateObj.shipmentDate : ''}
                  onChange={(value) => setDocumentStateObj({ ...documentStateObj, shipmentDate: value })}
                  success={documentStateObj.shipmentDate}
                  warning={!documentStateObj.shipmentDate || documentStateObj.shipmentDate == ''}
                  isDownloaded={props.isDownloaded}
                />
              </div>
              <div className="col-4">
                <DocumentInputText
                  label="Time"
                  inputClassName="w-full"
                  value={documentStateObj.shipmentTime !== undefined ? documentStateObj.shipmentTime : ''}
                  onChange={(value) => setDocumentStateObj({ ...documentStateObj, shipmentTime: value })}
                  success={documentStateObj.shipmentTime}
                  warning={!documentStateObj.shipmentTime || documentStateObj.shipmentTime == ''}
                  isDownloaded={props.isDownloaded}
                />
              </div>
            </div>
            <div className="col-3">
              <DocumentInputDropdown
                label="Timezone"
                inputClassName="w-full"
                value={documentStateObj.timezone !== undefined ? documentStateObj.timezone : ''}
                options={Timezones}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, timezone: value })}
                success={documentStateObj.timezone}
                warning={!documentStateObj.timezone || documentStateObj.timezone == ''}
                isDownloaded={props.isDownloaded}
              />
            </div>
            <div className="col-3">
              <DocumentInputText
                label="Truck Number(s)"
                inputClassName="w-full"
                value={documentStateObj.truckNumbers !== undefined ? documentStateObj.truckNumbers : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, truckNumbers: value })}
                success={documentStateObj.truckNumbers}
                warning={!documentStateObj.truckNumbers || documentStateObj.truckNumbers == ''}
                isDownloaded={props.isDownloaded}
              />
            </div>
            <div className="col-3">
              <DocumentInputText
                label="Trailer Number(s)"
                inputClassName="w-full"
                value={documentStateObj.trailerNumbers !== undefined ? documentStateObj.trailerNumbers : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, trailerNumbers: value })}
                success={documentStateObj.trailerNumbers}
                warning={!documentStateObj.trailerNumbers || documentStateObj.trailerNumbers == ''}
                isDownloaded={props.isDownloaded}
              />
            </div>
            <div className="col-3">
              <DocumentInputText
                label="Driver Name(s)"
                inputClassName="w-full"
                value={documentStateObj.driverNames !== undefined ? documentStateObj.driverNames : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, driverNames: value })}
                success={documentStateObj.driverNames}
                warning={!documentStateObj.driverNames || documentStateObj.driverNames == ''}
                isDownloaded={props.isDownloaded}
              />
            </div>
            <div className="col-3">
              <DocumentInputText
                label="Customs Broker"
                inputClassName="w-full"
                value={documentStateObj.customsBroker !== undefined ? documentStateObj.customsBroker : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, customsBroker: value })}
                success={documentStateObj.customsBroker}
                warning={!documentStateObj.customsBroker || documentStateObj.customsBroker == ''}
                isDownloaded={props.isDownloaded}
              />
            </div>

            {/* BOL Table */}
            <BOLTable
              data={dispatchItemData}
              updateDispatchItemsInformation={updateDispatchItemsInformation}
              isDownloaded={props.isDownloaded}
            />

            {/* BOL extra fields */}
            <div className="col-6">
              <DocumentInputText
                horizontal
                label="Protective Service:"
                inputClassName="w-full"
                value={documentStateObj.protectiveService !== undefined ? documentStateObj.protectiveService : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, protectiveService: value })}
                success={documentStateObj.protectiveService}
                warning={!documentStateObj.protectiveService || documentStateObj.protectiveService == ''}
                isDownloaded={props.isDownloaded}
              />
              <DocumentInputText
                horizontal
                label="Declared Value:"
                inputClassName="w-full"
                value={documentStateObj.declaredValue !== undefined ? documentStateObj.declaredValue : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, declaredValue: value })}
                success={documentStateObj.declaredValue}
                warning={!documentStateObj.declaredValue || documentStateObj.declaredValue == ''}
                isDownloaded={props.isDownloaded}
              />
            </div>
            <div className="col-6">
              <DocumentInputTextarea
                inputClassName={"w-full text-sm"}
                value={documentStateObj.liabilityInformation !== undefined ? documentStateObj.liabilityInformation : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, liabilityInformation: value })}
                rows={2}
                success={documentStateObj.liabilityInformation}
                warning={!documentStateObj.liabilityInformation || documentStateObj.liabilityInformation == ''}
                isDownloaded={props.isDownloaded}
              />
            </div>
            <div className="col-6 my-3">
              <DocumentInputTextarea
                label="Notes"
                inputClassName="w-full"
                value={documentStateObj.notes !== undefined ? documentStateObj.notes : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, notes: value })}
                success={documentStateObj.notes}
                warning={!documentStateObj.notes || documentStateObj.notes == ''}
                isDownloaded={props.isDownloaded}
              />
            </div>
            <div className="col-6 my-3">
              <DocumentInputTextarea
                label="Reference Numbers"
                inputClassName="w-full"
                value={documentStateObj.referenceNumbers !== undefined ? documentStateObj.referenceNumbers : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, referenceNumbers: value })}
              />
            </div>
            {/* The footer information including signatures */}
            <div className="col-6">
              <DocumentInputText
                horizontal
                label="Shipper Signature:"
                inputClassName="w-full"
                value={documentStateObj.shipperSignature !== undefined ? documentStateObj.shipperSignature : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, shipperSignature: value })}
                success={documentStateObj.shipperSignature}
                warning={!documentStateObj.shipperSignature || documentStateObj.shipperSignature == ''}
                isDownloaded={props.isDownloaded}
              />
              <DocumentInputText
                horizontal
                label="Shipper Print Name:"
                inputClassName="w-full"
                value={documentStateObj.shipperPrintName !== undefined ? documentStateObj.shipperPrintName : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, shipperPrintName: value })}
                success={documentStateObj.shipperPrintName}
                warning={!documentStateObj.shipperPrintName || documentStateObj.shipperPrintName == ''}
                isDownloaded={props.isDownloaded}
              />
              <DocumentInputText
                horizontal
                label="Date:"
                inputClassName="w-full"
                value={documentStateObj.shipperSignatureDate !== undefined ? documentStateObj.shipperSignatureDate : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, shipperSignatureDate: value })}
                success={documentStateObj.shipperSignatureDate}
                warning={!documentStateObj.shipperSignatureDate || documentStateObj.shipperSignatureDate == ''}
                isDownloaded={props.isDownloaded}
              />
            </div>
            <div className="col-6">
              <DocumentInputText
                horizontal
                label="Consignee Signature:"
                inputClassName="w-full"
                value={documentStateObj.consigneeSignature !== undefined ? documentStateObj.consigneeSignature : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, consigneeSignature: value })}
                success={documentStateObj.consigneeSignature}
                warning={!documentStateObj.consigneeSignature || documentStateObj.consigneeSignature == ''}
                isDownloaded={props.isDownloaded}
              />
              <DocumentInputText
                horizontal
                label="Consignee Print Name:"
                inputClassName="w-full"
                value={documentStateObj.consigneePrintName !== undefined ? documentStateObj.consigneePrintName : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, consigneePrintName: value })}
                success={documentStateObj.consigneePrintName}
                warning={!documentStateObj.consigneePrintName || documentStateObj.consigneePrintName == ''}
                isDownloaded={props.isDownloaded}
              />
              <DocumentInputText
                horizontal
                label="Date:"
                inputClassName="w-full"
                value={documentStateObj.consigneeSignatureDate !== undefined ? documentStateObj.consigneeSignatureDate : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, consigneeSignatureDate: value })}
                success={documentStateObj.consigneeSignatureDate}
                warning={!documentStateObj.consigneeSignatureDate || documentStateObj.consigneeSignatureDate == ''}
                isDownloaded={props.isDownloaded}
              />
            </div>
            <div className="col-9">
              <DocumentInputTextarea
                inputClassName={"w-full text-sm"}
                value={documentStateObj.noticeOfClaim !== undefined ? documentStateObj.noticeOfClaim : ''}
                onChange={(value) => setDocumentStateObj({ ...documentStateObj, noticeOfClaim: value })}
                success={documentStateObj.noticeOfClaim}
                warning={!documentStateObj.noticeOfClaim || documentStateObj.noticeOfClaim == ''}
                isDownloaded={props.isDownloaded}
              />
            </div>
            <div className="col-3">
              {/* Powered by SB component */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BOL;
