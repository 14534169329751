import React from 'react';
import PropTypes from 'prop-types';

// API
import * as Getters from 'api/Getters';
import { vehicleTypes } from 'api/Lists/EquipmentTypes';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';

import FilterForm from 'components/FilterForm/container/FilterForm.new';
import LazyLoadingTable from 'components/LazyLoadingTable/view/LazyLoadingTable';


class AssociateVehiclesDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isLazyLoading: false,
      hasLoadedAll: false,
      queryFilter: [
        { name: 'enabled', queryType: 'equalTo', value: true }
      ],
      filterFormFilter: [],
      queryPage: 0,

      vehicles: [],
      selectedVehicles: [],
      vehiclesDriven: [],
    }

    this.state.filterFormFields = [
      { key: 1, attrName: 'unitId_lowercase', queryType: 'equalTo', fullName: 'Unit ID', placeholder: '.....', type: 'text' },
      { key: 2, attrName: 'type', fullName: 'Type', queryType: 'equalTo', type: 'select', selectArr: vehicleTypes },
    ];

    this.setInitState = this.setInitState.bind(this);
    this.getVehicles = this.getVehicles.bind(this);
    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.associateSelectedVehicles = this.associateSelectedVehicles.bind(this);
  }

  componentDidMount = () => {
    this.setInitState();
  }

  setInitState = () => {
    this.getVehiclesDriven().then(() => {
      this.getVehicles();
    });
  }

  getVehiclesDriven = () => {
    const promise = new Promise(resolve => {
      this.setState({ ...this.state, isLoading: true }, () => {
        const queryIncludes = ['vehicle', 'vehicle.vehicleLocation', 'vehicle.licensePlate', 'vehicle.eldHardware', 'vehicle.trailer', 'vehicle.trailer.licensePlate'];

        Getters.queryCompanyObjects('VehicleDriverHistory', undefined, 1000, { queryType: 'equalTo', name: 'driver', value: this.props.driver }, { eventDateTime: 'descending' }, queryIncludes, false, true, undefined, true).then((driverVehiclePairs) => {
          // edge case: VehicleDriverHistory has vehicle pointer, but pointer no longer exists. filter them out
          const _driverVehiclePairs = driverVehiclePairs.filter(driverVehiclePair => driverVehiclePair.get('vehicle') && driverVehiclePair.get('vehicle').id);
          const vehiclesDriven = _driverVehiclePairs.map(driverVehiclePair => driverVehiclePair.get('vehicle'));
          this.setState({ ...this.state, isLoading: false, vehiclesDriven });
          resolve(vehiclesDriven);
        });
      });
    });
    return promise;
  }

  getVehicles = () => {
    const { queryPage, queryFilter, filterFormFilter, isLoading, isLazyLoading, hasLoadedAll, vehiclesDriven } = this.state;

    if (!isLoading && !isLazyLoading && !hasLoadedAll) {
      let loadingState = { ...this.state, isLoading: true };
      if (queryPage > 0) {
        loadingState = { ...this.state, isLazyLoading: true };
      }

      const _queryFilter = queryFilter.concat(filterFormFilter || []);

      this.setState(loadingState, () => {
        const queryIncludes = ['vehicle.eldHardware', 'vehicle.licensePlate', 'vehicle.trailer', 'vehicle.trailer.licensePlate'];
        Getters.queryCompanyObjects('Vehicle', queryPage, 150, _queryFilter, { unitId_lowercase: 'ascending' }, queryIncludes, false, false).then((vehicles) => {
          const newState = { ...this.state, vehicles: this.state.vehicles.concat(vehicles), isLoading: false, isLazyLoading: false, queryPage: queryPage + 1 };

          if (vehicles.length === 0) {
            newState.hasLoadedAll = true; // we've queried everything, so stop
          }
          this.setState(newState);
        });
      });
    }
  };

  filter = (filterFormFilter) => {
    this.setState({ ...this.state, vehicles: [], filterFormFilter, queryPage: 0, hasLoadedAll: false }, () => {
      this.getVehicles();
    });
  };

  clearFilter = () => {
    this.setState({ ...this.state, vehicles: [], filterFormFilter: [], queryPage: 0, hasLoadedAll: false }, () => {
      this.getVehicles();
    });
  }

  handleVehicleCellSelect = (rowIndex, columnIndex, e) => {
    // add and remove the correct selectedVehicles taking into account filtering + selecting vehicles skews results
    const { vehicles, selectedVehicles } = this.state;
    const newState = { ...this.state };
    newState.selectedVehicles = [].concat(newState.selectedVehicles);

    // get vehicle ids of those vehicle already selected to easily compare with rowIndex
    const selectedVehicleIds = selectedVehicles.map(selectedVehicle => selectedVehicle.id);

    const selectedVehicle = vehicles[rowIndex];

    if (selectedVehicle) {
      if (selectedVehicleIds.indexOf(selectedVehicle.id) !== -1) {
        // user deselected a row/vehicle that was previously selected
        newState.selectedVehicles.splice(selectedVehicleIds.indexOf(selectedVehicle.id), 1);
      }
      else {
        // user selected a vehicle that was not previously selected
        newState.selectedVehicles.push(selectedVehicle);
      }
    }

    this.setState(newState);
  };

  associateSelectedVehicles = () => {
    // finally we attach these vehicles to the driver through VehicleDriverHistory
    this.props.handleVehiclesChosen(this.state.selectedVehicles);
    this.props.handleClose();
  };


  render () {

    const { isLoading, isLazyLoading, filterFormFields, vehicles, selectedVehicles, vehiclesDriven } = this.state;
    const { open, handleClose } = this.props;

    const selectedVehicleIds = selectedVehicles.map(selectedVehicle => selectedVehicle.id);
    const vehiclesDrivenIds = vehiclesDriven.map(vehicleDriven => vehicleDriven.id);

    let actions = [
      <FlatButton
        label="Cancel"
        primary={true}
        onClick={() => handleClose()}
      />,
      <FlatButton
        label="Associate Vehicles"
        primary={true}
        onClick={() => this.associateSelectedVehicles()}
      />,
    ];
    if (isLoading || isLazyLoading) {
      actions = [];
    }

    const vehicleHeaderRows = [{ key: 'header', columns: [
      { element: <div>Unit ID</div> },
      { element: <div>VIN</div> },
      { element: <div>Plate</div> },
    ]}];

    let vehicleBodyRows = [];

    if (vehicles.length > 0) {
      vehicleBodyRows = vehicles.map((vehicle, index) => {
        // woo hoo
        let selectable = true;
        let selected = false;

        if (vehiclesDrivenIds.indexOf(vehicle.id) !== -1) {
          // driver has driven this vehicle before
          selectable = false;
          selected = true;
        }

        return ({
          key: vehicle.id,
          props: {
            id: vehicle.id,
            selectable,
            selected: selected || (selectedVehicleIds.indexOf(vehicle.id) !== -1),
          },
          columns: [
            {
              element: <div>{ vehicle.get('unitId') }</div>
            },
            {
              element: <div>{ vehicle.get('vin') }</div>
            },
            {
              element: <div>{ vehicle.get('licensePlate').get('plate') }</div>
            },
          ]
        });
      });
    }
    else {
      vehicleBodyRows = [{
        key: 'norows',
        props: {
          selectable: false,
          selected: false,
        },
        columns: [{ element: <div>There are no vehicles in your system matching the filter. To add a vehicle, go to the Equipment tab > Vehicle</div>, props: { colSpan: 420 } }]
      }];
    }
  

    return (
      <Dialog
        title="Associate Vehicles to Driver"
        actions={actions}
        modal={true}
        contentStyle={{ width: '70%', maxWidth: 'none' }}
        open={open}
      >

        { /* <div style={{ marginBottom: '1em' }}></div> */ }

        <span>
          <FilterForm
            className="floatRight"
            handleFilter={this.filter}
            clearFilter={this.clearFilter}
            fields={filterFormFields}
            disabled={isLoading || isLazyLoading}
          />
          <div className="clearBoth" />
        </span>

        <div style={{ marginTop: '1em' }}>
          <LazyLoadingTable
            height="500px"
            tableHeaderRows={vehicleHeaderRows}
            tableBodyRows={vehicleBodyRows}
            selectable={true}
            multiSelectable={true}
            enableSelectAll={false}
            showCheckboxes={true}
            onCellClick={this.handleVehicleCellSelect}
            handleLazyLoad={this.getVehicles}
            isLoading={isLoading}
            isLazyLoading={isLazyLoading}
            lazyLoadScrollOffset={300}
          />
        </div>

      </Dialog>
    );
  }
}

AssociateVehiclesDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  driver: PropTypes.object,
  handleVehiclesChosen: PropTypes.func,
};

export default AssociateVehiclesDialog;
