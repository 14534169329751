import * as ActionConstants from './ActionConstants';

export function fetchingAdmin() {
  return {
    type: ActionConstants.FETCH_ADMIN_INPROGRESS,
  };
}

export function fetchAdminSuccess(admin) {
  return {
    type: ActionConstants.FETCH_ADMIN_SUCCESS,
    admin,
  };
}

export function fetchAdminError(error) {
  return {
    type: ActionConstants.FETCH_ADMIN_ERROR,
    error,
  };
}
