import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';

// Components
import { MDBRow, MDBIcon } from 'mdbreact';

// CSS
import './style.scss';


class FeatureHeaderBar extends React.Component {

  constructor(props) {
    super(props);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.changeRoute = this.changeRoute.bind(this);
    this.isActiveTab = this.isActiveTab.bind(this);
  }

  toggleCollapse() {
    this.setState({ ...this.state, collapsed: !this.state.collapsed });
  }

  changeRoute(route) {
    const { location } = this.props;
    if ((location.pathname !== route) && (location.pathname !== `/${route}`)) {
      // history.push(`${route}`);
      history.push('/' + route);
    }
  }

  isActiveTab(route) {
    const { location } = this.props;
    if (location) {
      return (route === location.pathname || `/${route}` === location.pathname);
    }
  }

  render() {
    const { company, tabStates, location } = this.props;

    return (
      <MDBRow className="feature-header-bar d-flex flex-nowrap mx-0 translate-me">
        {tabStates.map
          && (
            <a href="/map" className={this.isActiveTab('map') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} >
              <div onClick={(e) => { e.preventDefault(); this.changeRoute('map'); }}>
                {this.isActiveTab('map') && <div className="tab-feature">Map</div>}
                {!this.isActiveTab('map') && <div className="tab-feature"><div className="d-none d-lg-block">Map</div><MDBIcon icon="globe-americas" className="d-lg-none"></MDBIcon></div>}
              </div>
            </a>
          )
        }
        {tabStates.dispatch
          && (
            <a href="/dispatch" className={this.isActiveTab('dispatch') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} >
              <div className={this.isActiveTab('dispatch') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} onClick={(e) => { e.preventDefault(); this.changeRoute('dispatch'); }}>
                {this.isActiveTab('dispatch') && <div className="tab-feature">Dispatch</div>}
                {!this.isActiveTab('dispatch') && <div className="tab-feature"><div className="d-none d-lg-block">Dispatch</div><MDBIcon icon="sitemap" className="d-lg-none"></MDBIcon></div>}
              </div>
            </a>
          )
        }
        {tabStates.documents
          && (
            <a href="/documents" className={this.isActiveTab('documents') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} >
              <div className={this.isActiveTab('documents') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} onClick={(e) => { e.preventDefault(); this.changeRoute('documents'); }}>
                {this.isActiveTab('documents') && <div className="tab-feature">Documents</div>}
                {!this.isActiveTab('documents') && <div className="tab-feature"><div className="d-none d-lg-block">Documents</div><MDBIcon icon="folder" className="d-lg-none"></MDBIcon></div>}
              </div>
            </a>
          )
        } 
        {tabStates.drivers
          && (
            <a href="/drivers" className={this.isActiveTab('drivers') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} >
              <div className={this.isActiveTab('drivers') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} onClick={(e) => { e.preventDefault(); this.changeRoute('drivers'); }}>
                {this.isActiveTab('drivers') && <div className="tab-feature">Drivers</div>}
                {!this.isActiveTab('drivers') && <div className="tab-feature"><div className="d-none d-lg-block">Drivers</div><MDBIcon icon="users" className="d-lg-none" /></div>}
              </div>
            </a>
          )
        }
        {tabStates.driverbehaviour
          && (
            <a href="/driverpatterns" className={this.isActiveTab('driverpatterns') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} >
              <div className={this.isActiveTab('driverpatterns') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} onClick={(e) => { e.preventDefault(); this.changeRoute('driverpatterns'); }}>
                {this.isActiveTab('driverpatterns') && <div className="tab-feature">Driver Analytics</div>}
                {!this.isActiveTab('driverpatterns') && <div className="tab-feature"><div className="d-none d-lg-block">Driver Analytics</div><MDBIcon icon="tachometer-alt" className="d-lg-none"></MDBIcon></div>}
              </div>
            </a>
          )
        }
        {tabStates.equipment
          && (
            <a href="/equipment" className={this.isActiveTab('equipment') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} >
              <div className={this.isActiveTab('equipment') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} onClick={(e) => { e.preventDefault(); this.changeRoute('equipment'); }}>
                {this.isActiveTab('equipment') && <div className="tab-feature">Equipment</div>}
                {!this.isActiveTab('equipment') && <div className="tab-feature"><div className="d-none d-lg-block">Equipment</div><MDBIcon icon="truck-moving" className="d-lg-none"></MDBIcon></div>}
              </div>
            </a>
          )
        }
        {tabStates.safety
          && (
            <a href="/safety" className={this.isActiveTab('safety') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} >
              <div className={this.isActiveTab('safety') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} onClick={(e) => { e.preventDefault(); this.changeRoute('safety'); }}>
                {this.isActiveTab('safety') && <div className="tab-feature">Safety</div>}
                {!this.isActiveTab('safety') && <div className="tab-feature"><div className="d-none d-lg-block">Safety</div><MDBIcon icon="shield-alt" className="d-lg-none"></MDBIcon></div>}
              </div>
            </a>
          )
        }
        {tabStates.ifta
          && (
            <a href="/ifta" className={this.isActiveTab('ifta') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} >
              <div className={this.isActiveTab('ifta') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} onClick={(e) => { e.preventDefault(); this.changeRoute('ifta'); }}>
                {this.isActiveTab('ifta') && <div className="tab-feature">IFTA</div>}
                {!this.isActiveTab('ifta') && <div className="tab-feature"><div className="d-none d-lg-block">IFTA</div><MDBIcon icon="gas-pump" className="d-lg-none"></MDBIcon></div>}
              </div>
            </a>
          )
        }
        {tabStates.vision
          && (
            <a href="/vision" className={this.isActiveTab('vision') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} >
              <div className={this.isActiveTab('vision') ? 'sb-nav-item active flex-grow-1' : 'sb-nav-icon flex-fill'} onClick={(e) => { e.preventDefault(); this.changeRoute('vision'); }}>
                {this.isActiveTab('vision') && <div className="tab-feature">VISION</div>}
                {!this.isActiveTab('vision') && <div className="tab-feature"><div className="d-none d-lg-block">VISION</div><MDBIcon icon="video" className="d-lg-none"></MDBIcon></div>}
              </div>
            </a>
          )
        }
      </MDBRow>
    );
  }
}

FeatureHeaderBar.propTypes = {
  company: PropTypes.object,
  tabStates: PropTypes.object,
  location: PropTypes.object,
};

export default FeatureHeaderBar;
