import React, { useState, useEffect } from 'react';

// CSAPI Enums
import { PayableMethodType } from 'sb-csapi/dist/enums/Dispatch/PayableMethodType';

// sbCore Components
import Dropdown from 'sbCore/Dropdown/Dropdown';

// Styles
import './style.scss';

/**
 * @description A dropdown containing a list of selectable payable method types
 * @param {Number} payableMethodType - Number passed to pre-set the payable method type selected
 * @param {Function} onPayableMethodTypeChange - Function that updates the payable method type of the parent's state
 * @param {Boolean} [disabled] - Whether or not to disable the dropdown
 */
function PayableMethodTypeDropdown({ ...props }) {

  const payableMethodTypes = Object.keys(PayableMethodType).map(key => PayableMethodType[key]);

  // ** useStates ** //
  const [selectedPayableMethodType, setSelectedPayableMethodType] = useState(undefined);

  // ** useEffects ** //
  useEffect(() => {
    if (!props.payableMethodType && props.payableMethodType !== 0 && !payableMethodTypes) return;

    const payableMethodType = payableMethodTypes.find((payableMethod) => payableMethod.type === props.payableMethodType);
    setSelectedPayableMethodType(payableMethodType);
  }, [props.payableMethodType, payableMethodTypes]);

  // ** Callback Functions ** //

  // sets the selected service provider type state here and in the the parent component
  function onPayableMethodTypeChange(e) {
    setSelectedPayableMethodType(e.value);

    if (props.onPayableMethodTypeChange) {
      // send the chosen type int back up to parent
      props.onPayableMethodTypeChange(e.value ? e.value.type : e.value);
    }
  }

  return (
    <div className="payable-method-type-dropdown">
      <Dropdown
        className="dropdown"
        value={selectedPayableMethodType}
        options={payableMethodTypes}
        onChange={(e) => onPayableMethodTypeChange(e)}
        optionLabel="description"
        placeholder="Select Payment Type"
        warning={!selectedPayableMethodType}
        success={selectedPayableMethodType}
        disabled={props.disabled}
      />
    </div>
  );
}

export default PayableMethodTypeDropdown;
