import React from 'react';

import { ProgressSpinner as PRProgressSpinner } from 'primereact/progressspinner';

import './style.scss';

export default function ProgressSpinner({ ...props }) {
  let className = 'sbc-progress-spinner';
  if (props.className) className += ` ${props.className}`;

  return (<PRProgressSpinner { ...props } className={className} />);
}
