import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

// API
import HOSRegulation from 'api/Lists/HOSRegulation';

// Assets
const checklist = require('assets/images/violations/checklist.png');
const day = require('assets/images/violations/24hours.png');
const ten = require('assets/images/violations/10.png');
const thirteen = require('assets/images/violations/13.png');
const fourteen = require('assets/images/violations/14.png');
const sixteen = require('assets/images/violations/16.png');
const twentyfour = require('assets/images/violations/24.png');
const coffee = require('assets/images/violations/coffee-cup.png');
const cycle60 = require('assets/images/violations/cycle60.png');
const cycle70 = require('assets/images/violations/cycle70.png');
const cycle80 = require('assets/images/violations/cycle80.png');
const cycle120 = require('assets/images/violations/cycle120.png');
const oilpump = require('assets/images/violations/pumpjack.png');
const sleep = require('assets/images/violations/sleep.png');
const steering = require('assets/images/violations/steering-wheel.png');
const odo = require('assets/images/violations/odo.png');
const clock = require('assets/images/violations/time.png');
const check = require('assets/images/violations/check.png');
const error = require('assets/images/violations/cancel.png');

function HOSRegulationIcon(props) {
  console.log(props);
  if (!props.HOSRegulationInt && !props.check) {
    return <img height="40em" style={{ display: 'inline' }} role="presentation" src={error} />;
  } else if (!props.HOSRegulationInt && props.check) {
    return <img height="40em" style={{ display: 'inline' }} role="presentation" src={check} />;
  }
  const messageToolTip = (
    <ReactTooltip effect="solid" type="dark" id={`${props.HOSRegulationInt}Message`}>
      {props.dateString &&
        <div style={{ maxWidth: '40em', whiteSpace: 'initial' }}><b>{props.dateString}</b></div>
      }
      {HOSRegulation[props.HOSRegulationInt] &&
        <div style={{ maxWidth: '40em', whiteSpace: 'initial' }}>{HOSRegulation[props.HOSRegulationInt].message || HOSRegulation[props.HOSRegulationInt].rule}</div>
      }
      {!HOSRegulation[props.HOSRegulationInt] &&
        <div style={{ whiteSpace: 'initial' }}>General Violation</div>
      }
    </ReactTooltip>
  );
  const image = check;


  const imageIconObj = {
    0: sleep,
    1: fourteen,
    2: steering,
    3: coffee,
    4: clock,
    5: clock,
    6: clock,
    7: fourteen,
    8: sleep,
    9: sleep,
    10: sleep,
    11: sleep,
    12: coffee,
    13: twentyfour,
    14: cycle70,
    15: cycle120,
    10000: steering,
    10040: day,
    10060: coffee,
    10150: clock,
    10250: clock,
    10400: steering,
    10410: day,
    11000: steering,
    11010: day,
    11150: clock,
    11250: clock,
    12000: steering,
    12040: day,
    12050: clock,
    12400: steering,
    12410: day,
    13150: clock,
    13000: day,
    13010: steering,
    20020: steering,
    20030: day,
    20000: steering,
    20010: day,
    20040: day,
    20070: sleep,
    20061: sleep,
    20080: day,
    20150: coffee,
    20350: clock,
    20351: clock,
    100000: checklist,
    14000: steering,
    14040: clock,
    14150: cycle60,
    14250: cycle70,
    15000: clock,
    15010: clock,
    15150: cycle70,
    15250: cycle80,
    100001: odo,
    100002: odo,
    100004: checklist,
    142069: clock,
    16970: checklist,
  };

  // if (props.HOSRegulationInt === 0) {
  //   image = sleep;
  // } else if (props.HOSRegulationInt === 1) {
  //   image = fourteen;
  // } else if (props.HOSRegulationInt === 2) {
  //   image = steering;
  // } else if (props.HOSRegulationInt === 3) {
  //   image = coffee;
  // } else if (props.HOSRegulationInt === 4) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 5) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 6) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 7) {
  //   image = fourteen;
  // } else if (props.HOSRegulationInt === 8) {
  //   image = sleep;
  // } else if (props.HOSRegulationInt === 9) {
  //   image = sleep;
  // } else if (props.HOSRegulationInt === 10) {
  //   image = sleep;
  // } else if (props.HOSRegulationInt === 11) {
  //   image = sleep;
  // } else if (props.HOSRegulationInt === 12) {
  //   image = coffee;
  // } else if (props.HOSRegulationInt === 13) {
  //   // image = twentyfour;
  // } else if (props.HOSRegulationInt === 14) {
  //   image = cycle70;
  // } else if (props.HOSRegulationInt === 15) {
  //   image = cycle120;
  // } else if (props.HOSRegulationInt === 10000) {
  //   image = steering;
  // } else if (props.HOSRegulationInt === 10040) {
  //   image = day;
  // } else if (props.HOSRegulationInt === 10060) {
  //   image = coffee;
  // } else if (props.HOSRegulationInt === 10150) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 10250) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 10400) {
  //   image = steering;
  // } else if (props.HOSRegulationInt === 10410) {
  //   image = day;
  // } else if (props.HOSRegulationInt === 11000) {
  //   image = steering;
  // } else if (props.HOSRegulationInt === 11010) {
  //   image = day;
  // } else if (props.HOSRegulationInt === 11150) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 11250) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 12000) {
  //   image = steering;
  // } else if (props.HOSRegulationInt === 12040) {
  //   image = day;
  // } else if (props.HOSRegulationInt === 12050) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 12400) {
  //   image = steering;
  // } else if (props.HOSRegulationInt === 12410) {
  //   image = day;
  // } else if (props.HOSRegulationInt === 13150) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 13000) {
  //   image = day;
  // } else if (props.HOSRegulationInt === 13010) {
  //   image = steering;
  // } else if (props.HOSRegulationInt === 20020) {
  //   image = steering;
  // } else if (props.HOSRegulationInt === 20030) {
  //   image = day;
  // } else if (props.HOSRegulationInt === 20000) {
  //   image = steering;
  // } else if (props.HOSRegulationInt === 20010) {
  //   image = day;
  // } else if (props.HOSRegulationInt === 20040) {
  //   image = day;
  // } else if (props.HOSRegulationInt === 20070) {
  //   image = sleep;
  // } else if (props.HOSRegulationInt === 20061) {
  //   image = sleep;
  // } else if (props.HOSRegulationInt === 20080) {
  //   image = day;
  // } else if (props.HOSRegulationInt === 20150) {
  //   image = coffee;
  // } else if (props.HOSRegulationInt === 20350) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 20351) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 20080) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 20030) {
  //   image = oilpump;
  // } else if (props.HOSRegulationInt === 100000) {
  //   image = checklist;
  // } else if (props.HOSRegulationInt === 14000) {
  //   image = steering;
  // } else if (props.HOSRegulationInt === 14040) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 14150) {
  //   image = cycle60;
  // } else if (props.HOSRegulationInt === 14250) {
  //   image = cycle70;
  // } else if (props.HOSRegulationInt === 15000) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 15010) {
  //   image = clock;
  // } else if (props.HOSRegulationInt === 15150) {
  //   image = cycle70;
  // } else if (props.HOSRegulationInt === 15250) {
  //   image = cycle80;
  // } else if (props.HOSRegulationInt === 100001) {
  //   image = odo;
  // } else {
  //   image = error;
  // }
  return (
    <div>
      {messageToolTip}
      <img height="40em" src={imageIconObj[props.HOSRegulationInt] || error} role="presentation" data-tip data-for={`${props.HOSRegulationInt}Message`} />
    </div>
  );
}

HOSRegulationIcon.propTypes = {
  dateString: PropTypes.string,
  HOSRegulationInt: PropTypes.number,
  check: PropTypes.bool,
};

export default HOSRegulationIcon;
