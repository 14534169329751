import React from 'react';
import PropTypes from 'prop-types';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';

// API
import { getIFTARouteBreakdown } from 'api/IFTA/RouteBreakdown';
import { getRoute, decodeMapboxRoutePolyline } from 'api/Mapbox';

// Components
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import IFTARouteBreakdownMap from 'components/IFTARouteBreakdown/IFTARouteBreakdownMap';
import IFTARouteBreakdownMileage from 'components/IFTARouteBreakdown/IFTARouteBreakdownMileage';

// CSS
import './styles.scss';


function locationsAreValid(locationsArr) {
  if (!locationsArr || !locationsArr.length || locationsArr.length < 2) return false;
  const validationResults = locationsArr.map(locationObj => {
    if (!locationObj || !locationObj.geometry || !locationObj.geometry.location) return false;
    return true;
  });
  return !validationResults.includes(false);
}

class IFTARouteBreakdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mileageBreakdown: {},
      routeLatLngArr: undefined,
      isFetchingMileageBreakdown: false,
      isFetchingRoute: false,
      locationsArr: [],
      orderedStatesTravelled: undefined,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.locationsArr.length !== this.props.locationsArr.length) {
      this.setState({
        ...this.state,
        routeLatLngArr: null,
        mileageBreakdown: {},
        orderedStatesTravelled: undefined,
      });
    }
  }

  async getMapboxRoute(locationsArr) {
    const lngLatArr = locationsArr.map(locationObj => ({
      longitude: locationObj.geometry.location.lng(),
      latitude: locationObj.geometry.location.lat(),
    }));

    this.setState({ isFetchingRoute: true });

    const mapboxRoutes = [];

    let i = 0;
    while (i < lngLatArr.length - 1) {
      const lngLatStart = lngLatArr[i];
      const lngLatEnd = lngLatArr[i + 1];
      const mapboxRoute = await getRoute([lngLatStart, lngLatEnd]);

      mapboxRoutes.push(mapboxRoute);

      i++;
    }

    if (mapboxRoutes.length === 0) return undefined;

    const mapboxRoutePolylines = [];
    let routeLatLngCoordinates = [];

    mapboxRoutes.forEach(mapboxRoute => {
      const mapboxRoutePolyline = mapboxRoute.polyline;
      const routeLatLngArr = decodeMapboxRoutePolyline(mapboxRoutePolyline, 6);

      // IFTA ROUTE BREAKDOWN can't process polylines that are super short?
      if (routeLatLngArr.length > 2) {
        mapboxRoutePolylines.push(mapboxRoutePolyline);
      }
      routeLatLngCoordinates = [...routeLatLngCoordinates, ...routeLatLngArr];
    });

    this.setState({ routeLatLngArr: routeLatLngCoordinates, isFetchingRoute: false });

    return mapboxRoutePolylines;
  }

  async getRouteBreakdown(locationsArr) {
    try {
      const mapboxRoutePolylines = await this.getMapboxRoute(locationsArr);
      if (!mapboxRoutePolylines) return;

      this.setState({ isFetchingMileageBreakdown: true });

      const allOrderedStatesTravelled = [];
      const allMileageBreakdown = [];

      for (let i = 0; i < mapboxRoutePolylines.length; i++) {
        const mapboxRoutePolyline = mapboxRoutePolylines[i];
        try {
          const { orderedStatesTravelled, mileageBreakdown } = await getIFTARouteBreakdown(mapboxRoutePolyline);

          allOrderedStatesTravelled.push(orderedStatesTravelled);
          allMileageBreakdown.push(mileageBreakdown);

        } catch (e) {
          console.log(e);
          return;
        }
      }

      const combinedOrderedStatesTravelled = [...new Set([].concat(...allOrderedStatesTravelled))];
      const combinedMileageBreakdown = {};

      allMileageBreakdown.forEach(mileageBreakdown => {
        for (const [key, value] of Object.entries(mileageBreakdown)) {
          if (!combinedMileageBreakdown[key] && key === 'total') {
            combinedMileageBreakdown[key] = value;
          } else if (!combinedMileageBreakdown[key] && key !== 'total') {
            combinedMileageBreakdown[key] = value.distance;
          } else if (combinedMileageBreakdown[key] && key === 'total') {
            combinedMileageBreakdown[key] += value;
          } else if (combinedMileageBreakdown[key] && key !== 'total') {
            combinedMileageBreakdown[key] += value.distance;
          }
        }
      });

      this.setState({ orderedStatesTravelled: combinedOrderedStatesTravelled, mileageBreakdown: combinedMileageBreakdown, isFetchingMileageBreakdown: false });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { state, props } = this;
    const filteredLocationArr = props.locationsArr.filter((locationObj) => locationObj.geometry && locationObj.geometry.location);

    return (
      <MDBContainer className="ifta-route-breakdown" fluid>
        <MDBRow>
          <MDBCol>
            <IFTARouteBreakdownMap
              locationsArr={state.locationsArr}
              mileageBreakdown={state.mileageBreakdown}
              isFetchingMileageBreakdown={state.isFetchingMileageBreakdown}
              isFetchingRoute={state.isFetchingRoute}
              routeLatLngArr={state.routeLatLngArr}
            />
          </MDBCol>
        </MDBRow>
        {filteredLocationArr.length > 1 &&
          (
            <MDBRow style={{ marginBottom: '1em' }}>
              <MDBCol>
                <ActionsContainer>
                  <MDBBtn
                    color="primary"
                    size="md"
                    onClick={() => {
                      this.setState({ ...this.state, error: '' });
                      if (locationsAreValid(filteredLocationArr)) {
                        this.setState({ locationsArr: filteredLocationArr }, async () => await this.getRouteBreakdown(filteredLocationArr));
                      } else {
                        this.setState({ ...this.state, error: 'Locations are invalid.' });
                      }
                    }}
                  >
                    Generate Route
                  </MDBBtn>
                </ActionsContainer>
              </MDBCol>
            </MDBRow>
          )
        }
        {this.state.error &&
          <div className="errorText">{this.state.error}</div>
        }
        <MDBRow>
          <MDBCol>
            <IFTARouteBreakdownMileage
              mileageBreakdown={state.mileageBreakdown}
              orderedStatesTravelled={state.orderedStatesTravelled}
              isFetchingMileageBreakdown={state.isFetchingMileageBreakdown}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

IFTARouteBreakdown.propTypes = {
  locationsArr: PropTypes.array, // array of location objects
};

export default IFTARouteBreakdown;
