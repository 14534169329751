import React from 'react';

// sbCore Components
import Tooltip from 'sbCore/Tooltip/Tooltip';

// Styles
import './style.scss';

/**
 * @description Component which displays a warning icon with a tooltip if required
 * @param {Boolean} [showTooltip] - Whether or not to show the tooltip
 * @param {Boolean} showWarning - Whether or not to shown the warning icon
 * @param {String} [tooltipText] - String to show in the tooltip
 * @param {String} [className] - Custom className
 * @returns
 */
function WarningIcon({ ...props }) {

  // ** Components ** //
  const warningIcon = (
    <>
      {props.showTooltip && (
        <Tooltip className="sbc-warning-icon" target=".warning-icon" />
      )}
      <i
        className="pi pi-exclamation-circle warning-icon amber-text"
        style={{ fontSize: '1.5em' }}
        data-pr-tooltip={props.tooltipText}
      />
    </>
  );

  // ** Misc ** //
  let className = 'sbc-warning-icon';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      {props.showWarning && warningIcon}
    </div>
  );
}

export default WarningIcon;
