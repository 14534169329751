import React, { useState, useEffect } from 'react';

// API
import { updateDispatchPayable } from 'api/Dispatch/DispatchPayable';

// CSAPI Attributes
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import PayablesDrawerHeader from 'components/Dispatch/AccountingPayablesTable/PayablesDrawer/PayablesDrawerHeader';
import PayableInformation from 'components/Dispatch/AccountingPayablesTable/PayablesDrawer/PayableInformation';
import DispatchDocumentHistoryTable from 'components/Dispatch/DispatchDocumentHistoryTable/DispatchDocumentHistoryTable';
import TransactionsContainer from 'components/Dispatch/TransactionsContainer/TransactionsContainer';

// CSAPI Enums
import { PayableMethodType } from 'sb-csapi/dist/enums/Dispatch/PayableMethodType';
import { Document } from 'sb-csapi/dist/enums/Dispatch/Document';

// sbCore Components
import Sidebar from 'sbCore/Sidebar/Sidebar';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import InputTextarea from 'sbCore/InputTextarea/InputTextarea';
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';

// Styles
import './style.scss';

/**
 * @description Shows a drawer that displays information
 * @param {DispatchPayable} dispatchPayable - The selected DispatchPayable
 * @param {Boolean} isDrawerOpen - Determines whether the drawer is visible or not
 * @param {Function} closeDrawer - Function that hides the drawer
 * @param {String} [position] - Position of the drawer
 *
 * @returns
 *
 * @example
 * <PayablesDrawer closeDrawer={closeDrawer()} isDrawerOpen={isDrawerOpen} dispatchPayable={selectedDispatchPayable} />
 */
function PayablesDrawer({ ...props }) {
  const payableMethodTypes = Object.keys(PayableMethodType).map((key) => PayableMethodType[key]);

  // ** useStates ** //
  const [dispatchJobAccounting, setDispatchJobAccounting] = useState(null);
  const [dispatchJob, setDispatchJob] = useState(null);
  const [paymentStatusInt, setPaymentStatusInt] = useState(null);
  const [invoiceDateTime, setInvoiceDateTime] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [paymentTerm, setPaymentTerm] = useState(null);
  const [methodType, setMethodType] = useState(null);
  const [notes, setNotes] = useState('');
  const [isVoided, setIsVoided] = useState(false);

  const [refreshInt, setRefreshInt] = useState(0);

  // ** useEffects ** //

  // Retrieve the required information from the given DispatchJob
  useEffect(() => {
    if (!props.dispatchPayable) return;
    const _paymentStatusInt = getAttribute(props.dispatchPayable, 'status');
    const _paymentTerm = getAttribute(props.dispatchPayable, 'paymentTermType');
    const _invoiceDateTime = getAttribute(props.dispatchPayable, 'invoiceDateTime');
    const _invoice = getAttribute(props.dispatchPayable, 'dispatchDocumentInvoice');
    const _isVoided = getAttribute(props.dispatchPayable, 'isVoided');
    const _notes = getAttribute(props.dispatchPayable, 'notes');

    let _methodType = getAttribute(props.dispatchPayable, 'methodType');
    if (_methodType) {
      const payableMethodType = payableMethodTypes.find((payableMethodType) => payableMethodType.type === _methodType);
      _methodType = payableMethodType.description;
    }

    const _dispatchJobAccounting = getAttribute(props.dispatchPayable, 'dispatchJobAccounting');
    let _dispatchJob;
    if (_dispatchJobAccounting) _dispatchJob = getAttribute(_dispatchJobAccounting, 'dispatchJob');
    setDispatchJobAccounting(_dispatchJobAccounting);

    setDispatchJob(_dispatchJob);
    setPaymentStatusInt(_paymentStatusInt);
    setPaymentTerm(_paymentTerm);
    setInvoiceDateTime(_invoiceDateTime);
    setInvoice(_invoice);
    setMethodType(_methodType);
    setNotes(_notes);

    setIsVoided(_isVoided);
  }, [props.dispatchPayable, refreshInt]);

  // Rather than calculate payment term, just use what was set within the DispatchPayable for now
  // useEffect(() => {
  //   if (!invoiceDateTime || paymentStatusInt === PaymentStatus.PAID.status) return;
  //   const currentDateTime = moment();

  //   const duration = moment.duration(currentDateTime.diff(moment(invoiceDateTime)));
  //   const differenceInDays = Math.floor(duration.asDays());
  //   if (differenceInDays < 0) {
  //     return;
  //   } else if (differenceInDays <= NumberOfDays.SIXTY_DAYS.value) {
  //     setPaymentTerm(NumberOfDays.THIRTY_DAYS.value);
  //   } else if (differenceInDays <= NumberOfDays.NINETY_DAYS.value) {
  //     setPaymentTerm(NumberOfDays.SIXTY_DAYS.value);
  //   } else if (differenceInDays > NumberOfDays.NINETY_DAYS.value) {
  //     setPaymentTerm(NumberOfDays.NINETY_DAYS.value);
  //   }
  // }, [invoiceDateTime]);

  // ** Helper Functions ** //
  // Trigger a state refresh
  function toggleRefresh() {
    setRefreshInt(!refreshInt);
  }

  function handleNotesChange(notesValue) {
    if (!props.dispatchPayable) return;
    updateDispatchPayable(props.dispatchPayable, undefined, { notes: notesValue }, true);
    setNotes(notesValue);
  }

  function handleInvoiceUpload(invoiceDocuments) {
    if (!invoiceDocuments || invoiceDocuments.length < 0) return;
    const _invoice = invoiceDocuments[0];
    if (props.dispatchPayable) {
      updateDispatchPayable(props.dispatchPayable, undefined, { dispatchDocumentInvoice: _invoice }, true);
    }
    setInvoice(_invoice);
  }

  function handleInvoiceRemove() {
    if (props.dispatchPayable) updateDispatchPayable(props.dispatchPayable, undefined, { dispatchDocumentInvoice: undefined }, true);
    setInvoice(null);
  }

  function onPaymentStatusChange(status) {
    if (!props.dispatchPayable) return;
    updateDispatchPayable(props.dispatchPayable, undefined, { status }, true);
    setPaymentStatusInt(status);
  }

  function onHide() {
    setPaymentTerm(null);
    if (props.closeDrawer) props.closeDrawer();
  }

  // ** Components ** //

  const payablesDrawerHeader = (
    <PayablesDrawerHeader
      dispatchJob={dispatchJob}
      dispatchPayable={props.dispatchPayable}
      refreshInt={refreshInt}
      isVoided={isVoided}
      onHide={() => props.closeDrawer()}
    />
  );

  const payableInformation = (
    <PayableInformation
      dispatchJob={dispatchJob}
      dispatchPayable={props.dispatchPayable}
      handleInvoiceUpload={(isUploaded, invoiceDocuments) => handleInvoiceUpload(invoiceDocuments)}
      handleInvoiceRemove={handleInvoiceRemove}
      invoiceDateTime={invoiceDateTime}
      invoice={invoice}
      methodType={methodType}
      paymentStatusInt={paymentStatusInt}
      onPaymentStatusChange={onPaymentStatusChange}
      paymentTerm={paymentTerm}
      onHide={() => props.closeDrawer()}
    />
  );

  const transactionsContainer = (
    <TransactionsContainer
      dispatchJobAccounting={dispatchJobAccounting}
      dispatchPayable={props.dispatchPayable}
      toggleRefresh={toggleRefresh}
      isVoided={isVoided}
      disableVoiding
    />
  );

  const invoiceHistoryTable = (
    <DispatchDocumentHistoryTable
      dispatchDocumentTypeInt={Document.INVOICE.type}
      dispatchJobObjectId={dispatchJob && getAttribute(dispatchJob, 'objectId')}
      title="Invoice History"
    />
  );

  const notesSection = (
    <>
      <InputLabel className="payable-notes-label">Notes</InputLabel>
      <InputTextarea
        className="payable-notes"
        value={notes}
        onChange={(event) => handleNotesChange(event.target.value)}
      />
    </>
  );

  const invoiceHistoryNotesSection = (
    <ColoredSection className="payable-history-notes-section">
      <div className="grid">
        <div className="col">
          {invoiceHistoryTable}
        </div>
        <div className="col">
          {notesSection}
        </div>
      </div>
    </ColoredSection>
  );

  return (
    <Sidebar
      modal
      dismissable
      visible={props.isDrawerOpen}
      onHide={onHide}
      position={props.position ? props.position : 'right'}
      className="p-sidebar-lg hide-payables-drawer-header"
    >
      <div className="payables-drawer mt-5">
        {payablesDrawerHeader}
        {payableInformation}
        {transactionsContainer}

        {invoiceHistoryNotesSection}
      </div>
    </Sidebar>
  );
}

export default PayablesDrawer;
