import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// Components
import AssignUnidentifiedToDriverModal from './AssignUnidentifiedToDriverModal';

class AssignUnidentifiedToDriver extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps.unidentifiedObject !== this.props.unidentifiedObject) {
  //     return true;
  //   }
  //   if (nextState.show !== this.state.show) {
  //     return true;
  //   }
  //   return false;
  // }

  handleClose(updateParentState) {
    this.setState({ ...this.state, show: false }, () => {
      this.props.updateHandler();
    });
  }

  render() {
    return (
      <div className="inline">

        { this.state.show &&
          <AssignUnidentifiedToDriverModal
            timezoneOffsetFromUTC={this.props.timezoneOffsetFromUTC}
            show={this.state.show}
            handleClose={this.handleClose}
            unidentifiedObject={this.props.unidentifiedObject}
            updateHandler={this.props.updateHandler}
          />
        }

        <button className={`buttonDefault ${this.props.className}`} disabled={this.props.disabled} onClick={() => this.setState({ ...this.state, show: true })}>
          Assign Events To Drivers
        </button>

      </div>
    );
  }

}

AssignUnidentifiedToDriver.propTypes = {
  timezoneOffsetFromUTC: PropTypes.string,
  className: PropTypes.string,
  unidentifiedObject: PropTypes.object,
  updateHandler: PropTypes.func,
};

export default AssignUnidentifiedToDriver;
