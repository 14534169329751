import React, { useState, useCallback, useEffect } from 'react';

// sb-csapi
import { getAttribute } from 'sb-csapi/dist/AAPI';

// sbCore
import TabMenu from 'sbCore/TabMenu/TabMenu';
import ToggleButton from 'sbCore/ToggleButton/ToggleButton';

// components
import DispatchShipmentContainer from 'components/Dispatch/DispatchShipmentContainer/DispatchShipmentContainer';
import DispatchDocumentsView from 'components/Dispatch/DispatchDocumentsView/DispatchDocumentsView';
import DispatchJobGeneralInformationLayout from 'components/Dispatch/DispatchJobGeneralInformationLayout/DispatchJobGeneralInformationLayout';
import DispatchReferenceNotesView from 'components/Dispatch/DispatchReferenceNotesView/DispatchReferenceNotesView';
import DispatchJobQuoteInformationLayout from 'components/Dispatch/DispatchJobQuoteInformation/DispatchJobQuoteInformation';

// styles
import './style.scss';

function DispatchJobTabMenu({ dispatchJob, isLoading }) {
  // ** useStates ** //
  const [isRefreshState, setIsRefreshState] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [documentsActiveIndex, setDocumentsActiveIndex] = useState(0);
  const [dispatchDocumentTypeInt, setDispatchDocumentTypeInt] = useState(1);

  const tabMenuItems = [
    { label: '1. General Information' },
    { label: '2. Shipment Details' },
    { label: '3. Quote Information' },
    { label: '4. Reference Summary & Notes' },
    { label: '5. Document Generation' },
  ];

  const setRef = useCallback((node) => {
    if (node) {
      const hash = window.location.hash;
      if (hash) node.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="flex flex-column h-100">

      <TabMenu model={tabMenuItems} activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)} />

      {(activeTabIndex === 0) && dispatchJob && (
        <DispatchJobGeneralInformationLayout
          dispatchJob={dispatchJob}
          dispatchJobObjectId={getAttribute(dispatchJob, 'objectId', true)}
          setActiveTabIndex={setActiveTabIndex}
        />
      )}

      {activeTabIndex === 1 && !isLoading && dispatchJob && (
        <div ref={setRef}>
          <DispatchShipmentContainer
            dispatchJobObjectId={getAttribute(dispatchJob, 'objectId', true)}
            dispatchJob={dispatchJob}
            triggerRefreshState={() => setIsRefreshState(!isRefreshState)}
          />
        </div>
      )}

      {activeTabIndex === 2 && !isLoading && (
        <DispatchJobQuoteInformationLayout
          dispatchJob={dispatchJob}
          dispatchJobObjectId={getAttribute(dispatchJob, 'objectId', true)}
          setActiveTabIndex={setActiveTabIndex}
          editDispatchDocumentTypeInt={(documentTypeInt) => setDispatchDocumentTypeInt(documentTypeInt)}
          editDocumentsActiveIndex={(activeIndex) => setDocumentsActiveIndex(activeIndex)}
        />
      )}

      {activeTabIndex === 3 && !isLoading && (
        <DispatchReferenceNotesView
          dispatchJobObjectId={getAttribute(dispatchJob, 'objectId', true)}
          dispatchJob={dispatchJob}
          isRefreshState={isRefreshState}
          triggerRefreshState={() => setIsRefreshState(!isRefreshState)}
        />
      )}

      {activeTabIndex === 4 && !isLoading && (
        <DispatchDocumentsView
          dispatchJobObjectId={getAttribute(dispatchJob, 'objectId', true)}
          dispatchJob={dispatchJob}
          isRefreshState={isRefreshState}
          activeIndex={documentsActiveIndex}
          triggerRefreshState={() => setIsRefreshState(!isRefreshState)}
          dispatchDocumentTypeInt={dispatchDocumentTypeInt}
        />
      )}
    </div>
  );
}

export default DispatchJobTabMenu;
