// Api
import { getAttribute, createQuery, setQueryRestriction, find, sortQuery } from 'api/Parse';

// Enums
import { QueryRestrictionTypes, QuerySortOrderTypes } from 'enums/Query';
import { ELDEventTypeCode } from 'enums/ELDEventTypeCode';
import { ELDEventRecordStatus } from 'enums/ELDEventRecordStatus';

/** @module CycleHourCanada */

/**
 * @memberof module:CycleHourCanada
 * 
 * @param {Record} driver 
 */
export async function isAllowedChangeCycles(driver) {
  const cycleDutyStatusesToCheck = [   // These are the statuses we want to pull events for to check what the most recent duty event is
    ELDEventTypeCode.OFF_DUTY,
    ELDEventTypeCode.SLEEPER_BERTH,
    ELDEventTypeCode.DRIVING,
    ELDEventTypeCode.ON_DUTY_NOT_DRIVING,
    ELDEventTypeCode.AUTHORIZED_PERSONAL_USE_OF_CMV,
    ELDEventTypeCode.YARD_MOVES,
  ];

  const eldEventQuery = createQuery('ELDEvent');
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'driver', getAttribute(driver, 'objectId'));
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.CONTAINED_IN, 'eldEventTypeCodeInt', cycleDutyStatusesToCheck); // Check for most relevant statuses
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'eldEventRecordStatusInt', ELDEventRecordStatus.ACTIVE); // Check for most recent active event
  sortQuery(eldEventQuery, QuerySortOrderTypes.DESCENDING, 'eventDateTime');
  const mostRecentDutyStatusELDEvent = await find(eldEventQuery, true); // Obtain the most recent active driver event

  // If the most recent event is off duty, we can determine how long the driver has been off-duty
  // For CADMAN, off-duty and sleeper berth are treated the same in this situation
  const isConsideredOffDuty = mostRecentDutyStatusELDEvent && ((getAttribute(mostRecentDutyStatusELDEvent, 'eldEventTypeCodeInt') === ELDEventTypeCode.OFF_DUTY) || (getAttribute(mostRecentDutyStatusELDEvent, 'eldEventTypeCodeInt') === ELDEventTypeCode.SLEEPER_BERTH));
  if (isConsideredOffDuty) {
    const currentDateInMilliseconds = new Date().getTime();
    const mostRecentDutyStatusELDEventDateInMilliseconds = getAttribute(mostRecentDutyStatusELDEvent, 'eventDateTime').getTime();
    const calculatedOffDutyTimeInHours = ((currentDateInMilliseconds - mostRecentDutyStatusELDEventDateInMilliseconds) / (1000 * 60 * 60));
    const hourCycleCanadaInt = getAttribute(driver, 'hourCycleCanadaInt');

    if (hourCycleCanadaInt) {
      if (hourCycleCanadaInt === 1 || hourCycleCanadaInt === 4) { // If hourCycleCanadaInt is 1 or 4 then it is a 7-day cycle
        if (calculatedOffDutyTimeInHours >= 36) { // Driver must have been off duty for at least 36 consecutive hours
          return true;
        }
      } else if (hourCycleCanadaInt === 2 || hourCycleCanadaInt === 5) { // If hourCycleCanadaInt is 2 or 5 then it is a 14-day cycle
        if (calculatedOffDutyTimeInHours >= 72) { // Driver must have been off duty for at least 72 consecutive hours
          return true;
        }
      } else if ([1, 2, 4, 5].indexOf(hourCycleCanadaInt) === -1) { // If hourCycleCanadaInt is 3 or 6 then it is a type we currently do not check for
        return true;
      }
    }
  }
  return false;
}
