import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

import { MDBBtn, MDBRow, MDBCol } from 'mdbreact';

// API
import * as IFTA from 'api/IFTA';
import * as Helpers from 'api/Helpers';

// Components
import SBTable from 'components/Shared/SBTable/SBTable';


class OdometerReadingsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      odometerReadings: {},
      loading: false,
    };

    this.getOdometerReadings = this.getOdometerReadings.bind(this);
  }

  componentDidMount() {
    this.getOdometerReadings(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.dateStart !== this.props.dateStart ||
      nextProps.dateEnd !== this.props.dateEnd ||
      nextProps.distanceUnit !== this.props.distanceUnit ||
      nextProps.vehicleUnitId !== this.props.vehicleUnitId
    ) {
      this.getOdometerReadings(nextProps);
    }
  }

  getOdometerReadings(props) {
    this.setState({ ...this.state, loading: true });
    IFTA.getOdometerReadingsForDateRange(props.vehicleUnitId, props.dateStart, props.dateEnd, props.distanceUnit).then(odometerReadings => {
      this.setState({ ...this.state, odometerReadings: (odometerReadings || {}), loading: false });
    });
  }


  render() {
    const tableHeaderStyles = {
      start: { width: '30%' },
      end: { width: '30%' },
      total: { width: '40%' },
    };
    const tableHeaderRows = [{
      key: 'sb-jobs-list', columns: [
        {
          element: <div>Start Odometer { `(${this.props.distanceUnit})` }</div>,
          props: {
            style: tableHeaderStyles.start,
          }
        },
        {
          element: <div>End Odometer { `(${this.props.distanceUnit})` }</div>,
          props: {
            style: tableHeaderStyles.end,
          }
        },
        {
          element: <div>Total Difference { `(${this.props.distanceUnit})` }</div>,
          props: {
            style: tableHeaderStyles.total,
          }
        },
      ]
    }];

    let tableBodyRows = [{
        key: 0,
        columns: [
          { element:
            <div>
              { this.state.odometerReadings.odometerStart ? Math.round(this.state.odometerReadings.odometerStart) : 0}
            </div>, props: { className: 'table-body-column-style' }
          },
          { element:
            <div>
              { this.state.odometerReadings.odometerEnd ? Math.round(this.state.odometerReadings.odometerEnd) : 0}
            </div>, props: { className: 'table-body-column-style' }
          },
          { element:
            <b>
              { (this.state.odometerReadings.odometerStart !== undefined && this.state.odometerReadings.odometerEnd !== undefined) ?
                Math.round(this.state.odometerReadings.odometerEnd - this.state.odometerReadings.odometerStart) : ''
              }
            </b>, props: { className: 'table-body-column-style' }
          },
        ],
    }];

    return (
      <div>
        <SBTable
          tableHeaderRows={tableHeaderRows}
          tableBodyRows={tableBodyRows}
          isLoading={this.state.loading}
        />
      </div>
    );
  }
};

export default OdometerReadingsTable;
