import React from 'react';

// @todo Trying to abstract this component fails for some reason. So for now, we just return the component as is
import { AccordionTab } from 'primereact/accordion';

import './style.scss';

// function AccordionTab({ ...props }) {
//   let className = 'sbc-accordion-tab';
//   if (props.className) className += ` ${props.className}`;
//   return (
//      <PRAccordionTab { ...props } className={className}>
//   );
// }

export default AccordionTab;
