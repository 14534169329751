import React from 'react';

// Styles
import './style.scss';

/**
* @description Renders a trailer icon
* @param {Function} onClick - Callback that triggers when the map icon is clicked.
* @returns
*
* @example
* <TrailerIcon />
*/
function TrailerIcon({ ...props }) {
  return (
    <span className="trailer-icon fa-stack fa-2x" onClick={props.onClick}>
      <i className="fas fa-circle fa-stack-2x background" aria-hidden="true" />
      <i className="fas fa-truck-loading fa-stack-1x icon" />
    </span>
  );
}

export default TrailerIcon;
