import React from 'react';
import PropTypes from 'prop-types';
import SBSelect from '../SBSelect/SBSelect';
import { getVehicleSuggestions } from 'api/Equipment/Vehicle.old';
import { getAttribute } from 'api/Parse';
import { CountryCodeLocationDescriptionTypes } from 'enums/VehicleLocation';

import './styles.scss';

class VehicleAutocomplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      asyncVehicles: []
    };
    this.loadVehicles = this.loadVehicles.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleVehicleSelection = this.handleVehicleSelection.bind(this);
  }

  loadVehicles(term) {
    getVehicleSuggestions(term).then((vehicles) => {
      let tempVechicles = vehicles.map((vehicle, index) => {
        return { key: index, value: vehicle, label: getAttribute(vehicle, 'unitId') };
      });
      if (this.props.allowDeselect) tempVechicles.unshift({ key: 'none', value: undefined, label: 'Clear', isSpecialItem: true, removeableItem: true });
      this.setState({ ...this.state, asyncVehicles: tempVechicles });
    })
  }

  handleVehicleSelection(selection) {
    this.props.onVehicleSelected(selection[0].value);
  }

  handleFilterChange(text) {
    if (this.props.onChange)
      this.props.onChange(text);
  }

  render() {
    return (
      <SBSelect
        containerClassName="flex-grow-1"
        onFilterChange={this.handleFilterChange}
        items={this.state.asyncVehicles}
        getSelectedItems={this.handleVehicleSelection}
        isAutocomplete
        isRequired={this.props.isRequired}
        autocompleteLabel="Search Vehicle by Unit ID"
        onAutocompleteFocus={this.loadVehicles}
        onValueChange={this.loadVehicles}
        placeholder={this.props.placeholder}
      />
    )
  }
}

VehicleAutocomplete.propTypes = {
  onVehicleSelected: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  allowDeselect: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default VehicleAutocomplete
