import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import Title from 'components/Shared/Title/Title';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import Button from 'sbCore/Button/Button';

// Components
import LogDownloadButton from 'components/LogDownload/LogDownloadButton/LogDownloadButton';
import ELDEditModal from 'components/ELDEdit/ELDEditModal/ELDEditModal';

const ELDEventsHeader = React.memo((props) => {
  const { ELDEvent, eldDailyCertification, updateHandler, onDate, userSubscriptionPermission, styles } = props;
  const dateFilterField = [{ attrName: 'onDate', fullName: t('Driver\'s Log Date'), value: moment(onDate), type: 'date', queryType: 'date', dateQueryType: 'onDate', includeLeftRight: true }];

  return (
    <React.Fragment>
      <Title title="Hours of Service Logs" containerClassName="translate-me" />
      <div className="flex align-content-center justify-content-between">
        <span className="flex align-content-center">
          <LogDownloadButton
            showModal={(action) => props.openPrintDialog(action)}
          />
          {/*
            PRESS F TO PAY RESPECKS
            Juan pressed 'f'
            <FilterDropdown
              id="driversFilterDropdown"
              className={styles.filterDropdown}
              title="Active Events"
              handleFilter={props.filterByType}
              menuItems={eventTypeFilterFields}
              disabled={props.disableOptions}
            />
          */}

          <ELDEditModal
            buttonClassName="ml-2"
            eldDailyCertification={eldDailyCertification}
            eldEventStore={ELDEvent}
            userSubscriptionPermission={userSubscriptionPermission}
            onSubmit={() => updateHandler()}
          />

          <Button
            className="ml-2"
            text
            icon="pi pi-refresh"
            severity="secondary"
            sbVariant="short"
            label={t('Refresh Info')}
            onClick={props.updateHandler}
            disabled={props.disableOptions}
          />
        </span>
        <FilterForm
          className={styles.filterForm}
          handleFilter={(filterArr) => props.filterByDate(filterArr[0])}
          clearFilter={() => props.filterByDate(undefined, true)}
          fields={dateFilterField}
          disabled={props.disableOptions}
          submitOnSelect
        />
      </div>
    </React.Fragment>
  );
});

ELDEventsHeader.propTypes = {
  ELDEvent: PropTypes.object,
  eldDailyCertification: PropTypes.object,
  updateHandler: PropTypes.func,
  onDate: PropTypes.instanceOf(Date),
  filterByDate: PropTypes.func,
  openPrintDialog: PropTypes.func,
  styles: PropTypes.any,
  disableOptions: PropTypes.bool,
  userSubscriptionPermission: PropTypes.any,
};

export default ELDEventsHeader;
