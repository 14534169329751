// libraries
import { useState, useEffect } from 'react';

// api
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { formatName } from 'sb-csapi/src/utils/String';

// components
import Skeleton from 'sbCore/Skeleton/Skeleton';
import Button from 'sbCore/Button/Button';

/**
 * @description Display a Driver's information
 * @param {Driver} driver - The Driver
 * @param {bool} isLoading - Whether or not to show a loading skeleton
 * @param {Function} onUnselectDriver - Callback when the remove button is clicked. Passes back the driver if the user wants it
 * @param {Function} onToggleEditDriver - Callback when the edit button is clicked (ex. to toggle edit dialog)
 * @returns
 */
function DriverCard({ ...props }) {

  const [driver, setDriver] = useState(undefined);

  useEffect(() => {
    setDriver(props.driver);
  }, [props.driver]);

  function onUnselectDriver() {
    if (props.onUnselectDriver) {
      props.onUnselectDriver(driver);
    }
    setDriver(undefined);
  }

  // ** Misc ** //
  let name = getAttribute(driver, 'user_fullName', true);
  if (name) name = formatName(name);

  let className = 'driver-card';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      { !props.isLoading && (
        <div className="driver-card">
          <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => onUnselectDriver()} />
          <div>
            <div className={`name font-semibold${!name ? ' text-warning' : ''}`}>{ name || 'No Name Provided' }</div>
          </div>
        </div>
      )}

      { props.isLoading && (
        <div className="custom-skeleton">
          <div className="flex">
            <Skeleton shape="circle" size="2.625rem" className="mr-2"></Skeleton>
            <div>
              <Skeleton width="8rem" height=".6rem" className="mb-2"></Skeleton>
              <Skeleton width="5rem" height=".6rem" className="mb-2"></Skeleton>
              <Skeleton height=".4rem"></Skeleton>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DriverCard;
