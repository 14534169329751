import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import uniqid from 'uniqid';

// CSAPI
import { getAttribute, getCurrentUserSessionToken } from 'sb-csapi/dist/AAPI';
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// Enums
import { CommodityTypes } from 'sb-csapi/dist/enums/Dispatch/Commodity';
import { MassUnit, LengthUnit } from 'sb-csapi/dist/enums/Unit';
import { FreightCategory } from 'sb-csapi/dist/enums/Dispatch/Freight';

/**
 * @todo: move to sappy?
 */
import { ActionTypes } from 'enums/DispatchAction';

// API
import { getDispatchTransfers } from 'api/Dispatch/DispatchTransfer';
import { getDispatchItems } from 'api/Dispatch/DispatchItem';

// Components
import DispatchRoutePointContent from 'components/Dispatch/DispatchRoutePointContent/DispatchRoutePointContent';
import DispatchActionStatusBadge from 'components/Dispatch/DispatchActionStatusBadge/DispatchActionStatusBadge';

// sbCore Components
import Timeline from 'sbCore/Timeline/Timeline';

// sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Styles
import './style.scss';

/**
 * @description Contains a timeline that displays a route preview with information about a dispatch job's transfers.
 * @param {DispatchJob} dispatchJob - Required. The dispatchJob to obtain dispatch items from.
 * @param {Array} dispatchTransferObjectIdArr - Required - Array of dispatch transfer objects used to obtain information.
 * @param {Array} dispatchTransfers- Required - Array of dispatch transfer objects used to obtain information.
 * @returns
 *
 * @example
 * <DispatchRouteTimeline dispatchJob={dispatchJob} dispatchTransfers={dispatchTransfers} />
*/
function DispatchRouteTimeline({ ...props }) {
  // ** useStates **//
  const [dispatchTransfers, setDispatchTransfers] = useState([]);
  const [dispatchItems, setDispatchItems] = useState([]);
  const [routePoints, setRoutePoints] = useState([]);
  const [dispatchJob, setDispatchJob] = useState(undefined);

  // ** useEffects ** //
  useEffect(() => {
    let didCancel = false;

    async function _getDispatchJob() {
      if (!didCancel) {
        const dispatchJob = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'DispatchJob', props.dispatchJobObjectId);
        setDispatchJob(dispatchJob);
      }
    }

    if (props.dispatchJob) {
      setDispatchJob(props.dispatchJob);
    } else {
      _getDispatchJob();
    }

    return () => { didCancel = true; };
  }, [props.dispatchJob, props.dispatchJobObjectId])

  useEffect(() => {
    let didCancel = false;

    // Use to get the dispatch transfers from an array of object ids
    async function _getDispatchTransfersAndDispatchItems() {
      if (!didCancel) {
        const dispatchJobObjectId = getAttribute(dispatchJob, 'objectId');
        const includedPointers = ['shipperDispatchOrganization', 'consigneeDispatchOrganization', 'shipperDispatchOrganization.address', 'consigneeDispatchOrganization.address'];

        const { dispatchTransfers } = await getDispatchTransfers(
          undefined,                        // options - default
          dispatchJobObjectId,              // dispatchJobObjectId - default
          undefined,                        // filters
          undefined,                        // sort - default
          includedPointers,                 // includes
          undefined,                        // selects
          undefined,                        // page
          undefined,                        // limit
          true                              // query all
        );

        const dispatchTransferObjectIdArr = dispatchTransfers.map((dispatchTransfer) => getAttribute(dispatchTransfer, 'objectId'));
        const dispatchItemQueryFilter = [new Filter(QueryRestriction.CONTAINED_IN, 'dispatchTransfer', dispatchTransferObjectIdArr)];

        const { dispatchItems } = await getDispatchItems(
          undefined,                        // options - default
          undefined,                        // dispatchJobObjectId
          dispatchItemQueryFilter,          // filters
          undefined,                        // sort - default
          undefined,                        // includes
          undefined,                        // selects
          undefined,                        // page
          undefined,                        // limit
          true                              // query all
        );

        setDispatchTransfers(dispatchTransfers);
        setDispatchItems(dispatchItems);
      }
    }

    if (dispatchJob) _getDispatchTransfersAndDispatchItems();

    return () => { didCancel = true; };
  }, [dispatchJob]);

  // Retrieve the required information for each dispatch transfer and create a route point object with it
  useEffect(() => {
    if (!dispatchTransfers || dispatchTransfers.length === 0) return;

    let _routePoints = [];

    dispatchTransfers.map((dispatchTransfer) => {
      const dispatchTransferObjectId = getAttribute(dispatchTransfer, 'objectId');
      const transferRoutePoints = generateRoutePoints(dispatchTransfer, dispatchTransferObjectId);

      if (dispatchItems && dispatchItems.length > 0) {
        // filter for dispatchItems linked to the current dispatchTransfer
        const _dispatchItems = dispatchItems.filter((dispatchItem) => {
          const dispatchItemTransfer = getAttribute(dispatchItem, 'dispatchTransfer');
          const dispatchItemTransferObjectId = getAttribute(dispatchItemTransfer, 'objectId', true);

          return dispatchTransferObjectId === dispatchItemTransferObjectId;
        });

        if (_dispatchItems && _dispatchItems.length > 0) {
          const freight = generateFreightInformation(_dispatchItems);
          transferRoutePoints.map((routePoint) => {
            routePoint.freight = freight;
          });
        }
      }

      _routePoints.push(...transferRoutePoints);
    });

    _routePoints = sortRoutePoints(_routePoints);

    setRoutePoints(_routePoints);
  }, [dispatchTransfers, dispatchItems]);

  // ** Functions ** //
  function generateRoutePoints(dispatchTransfer, dispatchTransferObjectId) {
    const _routePoints = [];
    const shipperDispatchOrganization = getAttribute(dispatchTransfer, 'shipperDispatchOrganization');

    const shipperAddressRecord = shipperDispatchOrganization && getAttribute(shipperDispatchOrganization, 'address', true);
    const shipperStateProvince = shipperAddressRecord && getAttribute(shipperAddressRecord, 'stateProvince', true);
    const shipperCity = shipperAddressRecord && getAttribute(shipperAddressRecord, 'city', true);

    const shipperLocationString = locationString(shipperStateProvince, shipperCity);

    const consigneeDispatchOrganization = getAttribute(dispatchTransfer, 'consigneeDispatchOrganization');
    const consigneeAddressRecord = consigneeDispatchOrganization && getAttribute(consigneeDispatchOrganization, 'address', true);
    const consigneeStateProvince = consigneeAddressRecord && getAttribute(consigneeAddressRecord, 'stateProvince', true);
    const consigneeCity = consigneeAddressRecord && getAttribute(consigneeAddressRecord, 'city', true);

    const consigneeLocationString = locationString(consigneeStateProvince, consigneeCity);

    const pickupDateTime = getAttribute(dispatchTransfer, 'pickupDateTime');
    const dropoffDateTime = getAttribute(dispatchTransfer, 'dropoffDateTime');

    const pickupDateTimeString = pickupDateTime ? moment(pickupDateTime).format('DD-MM-YYYY @ HH:mm') : '-';
    const dropoffDateTimeString = dropoffDateTime ? moment(dropoffDateTime).format('DD-MM-YYYY @ HH:mm') : '-';

    const timezone = getAttribute(dispatchTransfer, 'timezoneOffsetFromUTC');

    const pickupRoutePoint = {
      dispatchTransferObjectId,
      shipperConsigneeLocation: shipperLocationString,
      pickupDropoffDateTime: pickupDateTimeString,
      actionType: ActionTypes.PICKUP,
      timezone,
    };
    _routePoints.push(pickupRoutePoint);

    const dropoffRoutePoint = {
      dispatchTransferObjectId,
      shipperConsigneeLocation: consigneeLocationString,
      pickupDropoffDateTime: dropoffDateTimeString,
      actionType: ActionTypes.DROPOFF,
      timezone,
    };
    _routePoints.push(dropoffRoutePoint);

    return _routePoints;
  }

  function generateFreightInformation(dispatchItems) {
    const freight = [];

    dispatchItems.map((dispatchItem) => {
      const commodityType = getAttribute(dispatchItem, 'commodityType');
      const commodityTypeCustomName = getAttribute(dispatchItem, 'commodityTypeCustomName');
      const equipment = commodityTypeCustomName ? commodityTypeCustomName : (commodityType !== undefined && CommodityTypes[commodityType] || '-');

      const quantity = getAttribute(dispatchItem, 'quantity') || '-';
      const categoryInt = getAttribute(dispatchItem, 'category');
      const categoryStr = categoryInt ? Object.values(FreightCategory).find((category) => category.type === categoryInt)?.description : '-';

      const weight = getAttribute(dispatchItem, 'weight') || '-';
      const weightUnit = getAttribute(dispatchItem, 'massUnit');

      // Convert MassUnit enum to array and retrieve the weight based on the index
      const massUnitArr = Object.values(MassUnit);
      const weightUnitString = (weightUnit < massUnitArr.length) ? `${massUnitArr[weightUnit].toLowerCase()}(s)` : '';

      const weightString = `${weight} ${weightUnitString}`;

      const length = getAttribute(dispatchItem, 'itemLength') || '-';
      const width = getAttribute(dispatchItem, 'width') || '-';
      const height = getAttribute(dispatchItem, 'height') || '-';

      const dimensionsUnit = getAttribute(dispatchItem, 'lengthUnit');

      // Convert LengthUnit enum to array and retrieve the unit based on the index
      const lengthUnitArr = Object.values(LengthUnit);
      const dimensionsUnitString = (dimensionsUnit < lengthUnitArr.length) ? lengthUnitArr[dimensionsUnit].toLowerCase() : '';

      const dimensionsString = `${length} x ${width} x ${height} ${dimensionsUnitString}`;

      const referenceNumber = dispatchJob && getAttribute(dispatchJob, 'referenceNumber');
      const referenceNumberString = referenceNumber ? `#${referenceNumber}` : '-';

      const freightObject = {
        key: uniqid(),
        equipment,
        quantity,
        categoryStr,
        weightString,
        dimensionsString,
        referenceNumber: referenceNumberString,
      };

      freight.push(freightObject);
    });

    return freight;
  }

  function sortRoutePoints(routePoints) {
    // sort the routes by ascending time
    const _routePoints = routePoints.sort((a, b) => {
      const dateA = a.pickupDropoffDateTime ? moment(a.pickupDropoffDateTime).format('X') : '';
      const dateB = b.pickupDropoffDateTime ? moment(b.pickupDropoffDateTime).format('X') : '';
      return dateA - dateB;
    });

    return _routePoints;
  }

  // function that returns a location string
  function locationString(stateProvince, city) {
    let location = '';
    if (!stateProvince && !city) {
      location = '-';
    } else if (!city) {
      location = stateProvince;
    } else if (!stateProvince) {
      location = city;
    } else {
      location = `${city}, ${stateProvince} `;
    }

    return location;
  }

  // Passed a route point object to display a card of the route information on the timeline
  const customizedContent = (routePoint) => {
    return (<DispatchRoutePointContent routePoint={routePoint} />);
  };

  const oppositeContent = (routePoint) => {
    const actionType = routePoint.actionType;
    return (<DispatchActionStatusBadge actionType={actionType} />);
  };

  return (
    <div className="dispatch-route-timeline">
      <div className="route-title">Route Details</div>
      <Timeline
        value={routePoints}
        align="left"
        className="customized-timeline text-sm"
        content={customizedContent}
        opposite={oppositeContent}
      />
    </div>
  );
}

export default DispatchRouteTimeline;
