export default class DispatchLeg {
  /**
  * @param {string} dispatchTransferObjectId - the dispatch transfer object id
  * @param {string} dispatchLegId - the dispatch leg id (name of the dispatch leg)
  * @param {string} shipperName - shipper name
  * @param {string} consigneeName - consignee name
  * @param {string} pickupDateTimeString - pickup date time
  * @param {string} dropoffDateTimeString - dropoff date time
  * @param {string} timezone - current timezone
  * @param {array} freight - array of freight objects
  * @param {boolean} isStatusModified - whether or not the the status is manually updated or not
  * @param {string} shippingDocumentNumber - tracking id for dispatch transfer
  * @param {string} routeName - name of route
  */
  constructor(dispatchTransferObjectId, dispatchLegId, shipperName, consigneeName, pickupDateTimeString, dropoffDateTimeString, timezone, freight, isStatusModified, shippingDocumentNumber, routeName) {
    this.dispatchTransferObjectId = dispatchTransferObjectId;
    this.dispatchLegId = dispatchLegId;
    this.shipperName = shipperName;
    this.consigneeName = consigneeName;
    this.pickupDateTimeString = pickupDateTimeString;
    this.dropoffDateTimeString = dropoffDateTimeString;
    this.timezone = timezone;
    this.freight = freight;
    this.isStatusModified = isStatusModified;
    this.shippingDocumentNumber = shippingDocumentNumber;
    this.routeName = routeName;
  }
}
