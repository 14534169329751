import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

// Components
import Title from 'components/LayoutTitle/view/Title';
import FilterForm from 'components/FilterForm/container/FilterForm';
import SortButton from 'components/SortButton/view/SortButton';
import AllIssuesTableRow from './AllIssuesTableRow';

// CSS
import styles from './IssuesTable.module.scss';

class AllIssuesTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: [],
      sort: { dateTime: 'ascending' },
    };
    this.refreshState = this.refreshState.bind(this);
    this.setSort = this.setSort.bind(this);
    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.state.filterFormFields = [
      { attrName: 'user_fullName', fullName: 'Driver Name', placeholder: '.....', type: 'text' },
      { attrName: 'vehicle_plate', fullName: 'Plate Number', placeholder: '.....', type: 'text' },
    ];
  }

  componentDidMount() {
    this.refreshState(this.props.carrierProfileReport, undefined, this.state.sort);
  }

  componentWillReceiveProps(nextProps) {
    const { carrierProfileReport } = nextProps;
    this.refreshState(carrierProfileReport, this.state.filter, this.state.sort);
  }

  setSort(attribute) {
    const newState = { ...this.state };
    const currentSort = this.state.sort[attribute]; // the current sort, if defined

    if (currentSort) {
      if (currentSort === 'ascending') {
        newState.sort[attribute] = 'descending';
      } else {
        newState.sort[attribute] = 'ascending';
      }
    } else if (attribute === 'dateTime') {
      newState.sort = { [attribute]: 'ascending' };
    } else if (attribute === 'user_fullName') {
      newState.sort = { [attribute]: 'ascending' };
    } else if (attribute === 'activePoints') {
      newState.sort = { [attribute]: 'ascending' };
    }
    this.setState(newState, () => {
      this.refreshState(this.props.carrierProfileReport, this.state.filter, this.state.sort);
    });
  }

  refreshState(carrierProfileReport, filterObject, sortBy) {
    const newState = { ...this.state };
    if (carrierProfileReport) {
      // combine everything into one
      let fullReportArr = [];
      const reportKeys = Object.keys(carrierProfileReport);
      for (let i = 0; i < reportKeys.length; i++) {
        if (reportKeys[i].toLowerCase() !== 'carrierprofileobject') {
          fullReportArr = fullReportArr.concat(carrierProfileReport[reportKeys[i]]);
        }
      }

      // filter applied
      if (filterObject) {
        const keys = Object.keys(filterObject);
        fullReportArr = fullReportArr.filter(issue => {
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const searchValue = issue.get(key);
            if (!(searchValue && searchValue.indexOf(filterObject[key].toLowerCase()) > -1)) {
              return false;
            }
          }
          return true;
        });
      }

      if (sortBy) {
        if (sortBy.dateTime) {
          fullReportArr.sort((issueA, issueB) => {
            const issueADateTime = issueA.get('dateTime');
            const issueBDateTime = issueB.get('dateTime');

            if (issueADateTime && issueBDateTime) {
              if (sortBy.dateTime === 'ascending') {
                return issueADateTime.getTime() - issueBDateTime.getTime();
              }
              return issueBDateTime.getTime() - issueADateTime.getTime();
            }
            return 0;
          });
        } else if (sortBy.activePoints) {
          fullReportArr.sort((issueA, issueB) => {
            let issueAPoints = issueA.get('activePoints');
            let issueBPoints = issueB.get('activePoints');
            if (!issueAPoints) {
              issueAPoints = -9999999;
            }
            if (!issueBPoints) {
              issueBPoints = -9999999;
            }

            if (sortBy.activePoints === 'ascending') {
              return parseFloat(issueAPoints) - parseFloat(issueBPoints);
            }
            return parseFloat(issueBPoints) - parseFloat(issueAPoints);
          });
        } else if (sortBy.user_fullName) {
          fullReportArr.sort((issueA, issueB) => {
            let issueADriver = issueA.get('user_fullName');
            let issueBDriver = issueB.get('user_fullName');
            if (!issueADriver) {
              issueADriver = '';
            }
            if (!issueBDriver) {
              issueBDriver = '';
            }
            if (issueADriver === issueBDriver) {
              return 0;
            }
            if (sortBy.user_fullName === 'ascending') {
              if (issueADriver < issueBDriver) {
                return -1;
              }
              return 1;
            } else if (sortBy.user_fullName === 'descending') {
              if (issueADriver > issueBDriver) {
                return -1;
              }
              return 1;
            }
          });
        }
      }

      newState.issueRows = fullReportArr.map(issue => <AllIssuesTableRow issue={issue} key={issue.id} rowKey={issue.id} />);
    } else {
      newState.issueRows = [<tr key="0"><td className="centerText" colSpan="6">Upload your Carrier Profile to view analysis</td></tr>];
    }
    this.setState(newState);
  }

  filter(filterObject) {
    this.setState({ ...this.state, filter: filterObject }, () => {
      this.refreshState(this.props.carrierProfileReport, this.state.filter, this.state.sort);
    });
  }

  clearFilter() {
    this.setState({ ...this.state, filter: [] }, () => {
      this.refreshState(this.props.carrierProfileReport, undefined, this.state.sort);
    });
  }

  render() {
    return (
      <div className={styles.issuesTable}>
        <Title className="h3" title={<div>All Issues</div>} />

        <table className={styles.titleSub}>
          <tbody>
            <tr>
              <td>
                <p>
                  Here you can view all Contraventions, CVSA violations, and Accidents listed under your NSC number
                </p>
              </td>
              <td>
                <FilterForm
                  className="floatRight"
                  handleFilter={this.filter}
                  clearFilter={this.clearFilter}
                  fields={this.state.filterFormFields}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="clearBoth" />

        <div className={`subTable ${styles.issuesSubTable}`}>
          <Table responsive hover>
            <thead>
              <tr>
                <th>
                  Date
                  <SortButton
                    clickHandler={() => this.setSort('dateTime')}
                    isActive={false}
                  />
                </th>
                <th>Type</th>
                <th>Description</th>
                <th>
                  Driver
                  <SortButton
                    clickHandler={() => this.setSort('user_fullName')}
                    isActive={false}
                  />
                </th>
                <th>Plate #</th>
                <th>
                  Points
                  <SortButton
                    clickHandler={() => this.setSort('activePoints')}
                    isActive={false}
                  />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              { this.state.issueRows }
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

AllIssuesTable.propTypes = {
  carrierProfileReport: PropTypes.object,
};

export default AllIssuesTable;
