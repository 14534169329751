import React from 'react';
import PropTypes from 'prop-types';
import { MDBBtn } from 'mdbreact';

// Components
import AddNewJobModal from 'components/AddNewJob/container/AddNewJobModal';
import AddNewJobModalAdvanced from 'components/AddNewJob/container/AddNewJobModalAdvanced';
import AddNewJobModalPickupDropoff from 'components/AddNewJob/container/AddNewJobModalPickupDropoff';

class AddNewJobButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addNewJobModalShow: false };
    this.openAddNewJobModal = this.openAddNewJobModal.bind(this);
    this.closeAddNewJobModal = this.closeAddNewJobModal.bind(this);
  }

  openAddNewJobModal() {
    this.state.addNewJobModalShow = true;
    this.setState(this.state);
  }
  closeAddNewJobModal() {
    this.state.addNewJobModalShow = false;
    this.setState(this.state);
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.advanced &&
          <AddNewJobModalAdvanced show={this.state.addNewJobModalShow} handleClose={this.closeAddNewJobModal} />
        }
        {this.props.pickupAndDropoff &&
          <AddNewJobModalPickupDropoff show={this.state.addNewJobModalShow} handleClose={this.closeAddNewJobModal} />
        }
        {!this.props.advanced && !this.props.pickupAndDropoff &&
          <AddNewJobModal show={this.state.addNewJobModalShow} handleClose={this.closeAddNewJobModal} />
        }
        <MDBBtn size="sm" color="primary" onClick={() => this.openAddNewJobModal()}>
          {this.props.children}
          {!this.props.children &&
            'Add New Job'
          }
        </MDBBtn>
      </div>
    );
  }
}

AddNewJobButton.propTypes = {
  advanced: PropTypes.bool,
  pickupAndDropoff: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string
};

export default AddNewJobButton;
