import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { USPortCodes } from 'api/EManifest/USPortCodes';

// Teach Autosuggest how to calculate suggestions for any given input value. 
function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
 
  return inputLength === 0 ? [] : USPortCodes.filter(port =>
    port.portOfEntry.toLowerCase().slice(0, inputLength) === inputValue
  );
}
 
// When suggestion is clicked, Autosuggest needs to populate the input field 
// based on the clicked suggestion. Teach Autosuggest how to calculate the 
// input value for every given suggestion. 
function getSuggestionValue(suggestion) {
  return suggestion.code;
}
 
// Use your imagination to render suggestions. 
function renderSuggestion(suggestion) {
  return (
    <span>{`${suggestion.code} - ${suggestion.portOfEntry}`}</span>
  );
}

class USPortCodeAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
    };
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsFetchRequested.bind(this);
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
    this.props.portCodeHandler(newValue);
  };

  onSuggestionsFetchRequested(suggestionValueObject) {
    if (!suggestionValueObject) {
      this.setState({
        suggestions: []
      });
      return;
    }
    const { value } = suggestionValueObject;
    this.setState({
      suggestions: getSuggestions(value)
    });
  };
 
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder: 'Code or City Name',
      value,
      onChange: this.onChange
    };

    return (
      <Autosuggest
        focusFirstSuggestion={true}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps} />
    );
  };

};

USPortCodeAutocomplete.propTypes = {
  portCodeHandler: PropTypes.func,
};

export default USPortCodeAutocomplete;
