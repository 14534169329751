// Enums
import { VisionEventType } from 'sb-csapi/dist/enums/Vision/VisionEventType';

// Components
import Chip from 'sbCore/Chip/Chip';

// Styling
import './style.scss';


/**
 * @description The chip for VisionEvent types
 * @param {number} eventCodeInt - An integer that represents the status of the chip
 * @param {string} [className] - Any additional styling classes to apply to the chip
 * @returns The correct chip with styling and label applied
 */
function EventTypeChip({ eventCodeInt, ...props }) {
  let _className = 'sb-vision-event-badge text-sm font-semibold uppercase';

  // Figure out the value of VisionEventType{} from the given eventCodeInt
  let matchingVisionEventTypeObject;

  Object.keys(VisionEventType).find((key) => {
    const visionEventTypeObject = VisionEventType[key];
    const isMatchingStatus = visionEventTypeObject.code === eventCodeInt;

    if (isMatchingStatus) {
      // Find the matching background color className (ex. SOME_VALUE -> some-value -> bg-vision-event-type-some-value)
      const colorClassKeyExpression = key.replace(/_/g, '-').toLowerCase();
      _className += ` bg-vision-event-type-${colorClassKeyExpression}`;

      matchingVisionEventTypeObject = visionEventTypeObject;
    }
    return isMatchingStatus;
  });

  // Add any final classNames (we do the assign here so the user can overwrite our own properties on className)
  if (props.className) _className += ` ${props.className}`;

  return <Chip label={matchingVisionEventTypeObject ? matchingVisionEventTypeObject.label : 'N/A'} className={_className} />;
}

export default EventTypeChip;
