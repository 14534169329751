// SBCore
import Column from "sbCore/Column/Column";
import DataTable from "sbCore/DataTable/DataTable";

// Components
import DocumentInputText from "components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputText/InputText";
import DocumentInputNumber from "components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputNumber/InputNumber";
import DocumentInputTextarea from "components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputTextarea/InputTextarea";

import './style.scss';

/**
 * @description The BOL table for the document generator
 * @param {Array} data - an array of objects which corresponds to the rows of the table
 * @returns {Component}
 */
function BOLTable({ ...props }) {
  function updateDispatchItemsInformation(id, value) {
    const updatedData = [...props.data];
    const originalRowDataIndex = updatedData.findIndex((rowData) => rowData.id === id);

    let updatedRowData = updatedData[originalRowDataIndex];
    const changedKeys = Object.keys(value);

    // Go through each of the keys that were modified, and apply modifications if needed
    changedKeys.map((key) => {
      if (key === "dispatchItem") {
        updatedRowData[key] = Object.assign(updatedRowData[key], value[key])
      } else {
        updatedRowData[key] = value[key]
      }
    });

    updatedData[originalRowDataIndex] = updatedRowData;
    props.updateDispatchItemsInformation(updatedData);
  }

  const consigneeInformationTemplate = (rowData) => {
    const { consigneeDispatchOrganization } = rowData;

    return (
      <DocumentInputTextarea
        inputClassName={"w-full text-sm"}
        value={consigneeDispatchOrganization ? consigneeDispatchOrganization : ''}
        onChange={(value) => updateDispatchItemsInformation(rowData.id, { consigneeDispatchOrganization: value })}
        success={consigneeDispatchOrganization}
        warning={!consigneeDispatchOrganization || consigneeDispatchOrganization == ''}
        isDownloaded={props.isDownloaded}
      />
    );
  };

  const shipperInformationTemplate = (rowData) => {
    const { shipperDispatchOrganization } = rowData;

    return (
      <DocumentInputTextarea
        inputClassName={"w-full text-sm"}
        value={shipperDispatchOrganization ? shipperDispatchOrganization : ''}
        onChange={(value) => updateDispatchItemsInformation(rowData.id, { shipperDispatchOrganization: value })}
        success={shipperDispatchOrganization}
        warning={!shipperDispatchOrganization || shipperDispatchOrganization == ''}
        isDownloaded={props.isDownloaded}
      />
    );
  };

  const commodityTypeTemplate = (rowData) => {
    return (
      <DocumentInputTextarea
        inputClassName={"w-full text-sm"}
        value={rowData.dispatchItem.commodityType ? rowData.dispatchItem.commodityType : ''}
        onChange={(value) => updateDispatchItemsInformation(rowData.id, { dispatchItem: { commodityType: value } })}
        success={rowData.dispatchItem.commodityType}
        warning={!rowData.dispatchItem.commodityType || rowData.dispatchItem.commodityType == ''}
        isDownloaded={props.isDownloaded}
      />
    );
  }

  const quantityTemplate = (rowData) => {
    return (
      rowData.dispatchItem.quantity !== 0 && rowData.dispatchItem.quantity && (
        <DocumentInputNumber
          inputClassName={"w-full text-sm"}
          value={rowData.dispatchItem.quantity ? rowData.dispatchItem.quantity : ''}
          onChange={(value) => updateDispatchItemsInformation(rowData.id, { dispatchItem: { piecesQuantity: value } })}
          success={rowData.dispatchItem.quantity}
          warning={!rowData.dispatchItem.quantity || rowData.dispatchItem.quantity == ''}
          isDownloaded={props.isDownloaded}
        />
      )
    )
  }

  const categoryTemplate = (rowData) => {
    return (
      rowData.dispatchItem.categoryStr && (
        <DocumentInputText
          inputClassName={"w-full text-sm"}
          value={rowData.dispatchItem.categoryStr ? rowData.dispatchItem.categoryStr : ''}
          onChange={(value) => updateDispatchItemsInformation(rowData.id, { dispatchItem: { categoryStr: value } })}
          success={rowData.dispatchItem.categoryStr}
          warning={!rowData.dispatchItem.categoryStr || rowData.dispatchItem.categoryStr == ''}
          isDownloaded={props.isDownloaded}
        />
      )
    )
  }

  const weightTemplate = (rowData) => {
    return (
      rowData.dispatchItem.weight !== "0" && rowData.dispatchItem.weight && (
        <DocumentInputNumber
          inputClassName={"w-full text-sm"}
          value={rowData.dispatchItem.weight ? rowData.dispatchItem.weight : ''}
          onChange={(value) => updateDispatchItemsInformation(rowData.id, { dispatchItem: { weight: value } })}
          success={rowData.dispatchItem.weight}
          warning={!rowData.dispatchItem.weight || rowData.dispatchItem.weight == ''}
          isDownloaded={props.isDownloaded}
        />
      )
    )
  }

  const dimensionsTemplate = (rowData) => {
    return (
      rowData.dispatchItem.dimensions?.replace(/[^0-9]/g, '') !== "000" && rowData.dispatchItem.dimensions && (
        <DocumentInputText
          inputClassName={"w-full text-sm"}
          value={rowData.dispatchItem.dimensions ? rowData.dispatchItem.dimensions : ''}
          onChange={(value) => updateDispatchItemsInformation(rowData.id, { dispatchItem: { dimensions: value } })}
          success={rowData.dispatchItem.dimensions}
          warning={!rowData.dispatchItem.dimensions || rowData.dispatchItem.dimensions == ''}
          isDownloaded={props.isDownloaded}
        />
      )
    )
  }

  const notesTemplate = (rowData) => {
    return (
      <DocumentInputTextarea
        inputClassName={"w-full text-sm"}
        value={rowData.dispatchItem.notes ? rowData.dispatchItem.notes : ''}
        onChange={(value) => updateDispatchItemsInformation(rowData.id, { dispatchItem: { notes: value } })}
        success={rowData.dispatchItem.notes}
        warning={!rowData.dispatchItem.notes || rowData.dispatchItem.notes == ''}
        isDownloaded={props.isDownloaded}
      />
    )
  }

  // Will only consider the first dispatch item for units
  const renderFooter = () => {
    const totalPieces = props.data && props.data.reduce((res, row) => res += row.dispatchItem.piecesQuantity, 0);
    const totalWeight = props.data && props.data.reduce((res, row) => res += row.dispatchItem.weight, 0);
    const massUnitStr = props.data && props.data.length > 0 && props.data[0].dispatchItem.massUnitStr;

    return (
      <div className="flex justify-content-end">
        <span className="mx-2 uppercase">
          {`Total pieces: ${totalPieces ? totalPieces : 0}`}
        </span>
        <span className="mx-2 uppercase">
          {`Total weight: ${totalWeight ? totalWeight : 0}${massUnitStr ? ` ${massUnitStr}` : ''}`}
        </span>
      </div>
    );
  }

  // Check to see whether columns should be hidden
  // If all fields in a column has value of 0 then set hide to true else set hide to false
  let hideTableColumn = {
    quantity: true,
    categoryStr: true,
    weight: true,
    dimensions: true,
  };

  props.data.forEach((shipment) => {
    hideTableColumn.quantity = shipment.dispatchItem.quantity === 0 && hideTableColumn.quantity;
    hideTableColumn.categoryStr = !shipment.dispatchItem.categoryStr;
    // weight can be "0" and 0
    hideTableColumn.weight = +shipment.dispatchItem.weight.valueOf() === 0 && hideTableColumn.weight;
    // dimensions can be values of 0 and undefined
    hideTableColumn.dimensions = (shipment.dispatchItem.dimensions?.replace(/[^0-9]/g, '') === "000" || !shipment.dispatchItem.dimensions) && hideTableColumn.dimensions;
  })

  return (
    <div className="w-full mt-2 mb-4 bol-table">
      <DataTable footer={() => renderFooter()} value={props.data} responsiveLayout="scroll" rowGroupMode="rowspan" groupRowsBy={["consigneeDispatchOrganization", "shipperDispatchOrganization"]}>
        <Column body={shipperInformationTemplate} field="shipperDispatchOrganization" header="Shipper" headerClassName="bol-table-body-header" style={{ width: '9rem' }}></Column>
        <Column body={consigneeInformationTemplate} field="consigneeDispatchOrganization" header="Consignee" headerClassName="bol-table-body-header" style={{ width: '9rem' }}></Column>
        <Column body={commodityTypeTemplate} header="Type" headerClassName="bol-table-body-header" style={{ width: '6rem' }}></Column>
        {!hideTableColumn.quantity && <Column body={quantityTemplate} header="Quantity" headerClassName="bol-table-body-header" style={{ width: '5rem' }}></Column>}
        {!hideTableColumn.categoryStr && <Column body={categoryTemplate} header="Category" headerClassName="bol-table-body-header" style={{ width: '5rem' }}></Column>}
        {!hideTableColumn.dimensions && <Column body={dimensionsTemplate} header={`Dimensions`} headerClassName="bol-table-body-header" style={{ width: '6rem' }}></Column>}
        {!hideTableColumn.weight && <Column body={weightTemplate} header="Weight" headerClassName="bol-table-body-header" style={{ width: '5rem' }}></Column>}
        <Column body={notesTemplate} header="Notes" headerClassName="bol-table-body-header" style={{ width: '10rem' }}></Column>
      </DataTable>
    </div>
  );
}

export default BOLTable;