import React from 'react';
import PropTypes from 'prop-types';
// CSS
import styles from './MapButton.module.scss';

function ShowLSDLinesButton(props) {
  props.a;
  return (
    <div className={props.lsdActive ? styles.container : `${styles.container} ${styles.active}`}>
      <button onClick={() => props.toggleLSD()} className={`${props.lsdActive ? styles.active : ''} ${styles.button} translate-me`}>Show LSD</button>
    </div>
  );
}

ShowLSDLinesButton.propTypes = {
  lsdActive: PropTypes.bool,
  toggleLSD: PropTypes.func.isRequired,
};

export default ShowLSDLinesButton;
