import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Marker } from 'react-mapbox-gl';

import { MDBBtn, MDBIcon } from 'mdbreact';

// API
import { isVehicleDriverDriving } from 'api/ELD';

// CSS
import styles from './ComponentStyles.module.scss';

function VehicleMarker(props) {
  const vehicleLocationObj = props.vehicleObj.get('vehicleLocation');
  const isIdling = isVehicleDriverDriving(props.vehicleObj) && vehicleLocationObj.get('speedKm') === 0;

  const vehicleDirection = props.followBearing || (
    isVehicleDriverDriving(props.vehicleObj) &&
    (
      (vehicleLocationObj && vehicleLocationObj.get('isGpsHeadingSet') && vehicleLocationObj.get('gpsHeading')) ||
      (vehicleLocationObj && vehicleLocationObj.get('altGpsHeading')) ||
      ((vehicleLocationObj && vehicleLocationObj.get('course')))
    )
  );

  const vehicleDirectionArrowStyle = { position: 'absolute', left: '-0.7em', top: '0em', fontSize: '.95em' };
  let arrow;
  if (vehicleDirection) {
    let rotateStyle = {};
    rotateStyle.WebkitTransform = `rotate(${vehicleDirection - 45}deg)`;
    rotateStyle.MozTransform = `rotate(${vehicleDirection - 45}deg)`;
    rotateStyle.msTransform = `rotate(${vehicleDirection - 45}deg)`;
    rotateStyle.OTransform = `rotate(${vehicleDirection - 45}deg)`;
    rotateStyle.transform = `rotate(${vehicleDirection - 45}deg)`;
    rotateStyle.WebkitTransformOrigin = `0.75em 0.75em`;
    rotateStyle.MozTransformOrigin = `0.75em 0.75em`;
    rotateStyle.msTransformOrigin = `0.75em 0.75em`;
    rotateStyle.OTransformOrigin = `0.75em 0.75em`;
    rotateStyle.transformOrigin = `0.75em 0.75em`;

    arrow = (
      <div id={`${props.vehicleObj.id}gps-heading-arrow`} className="text-primary-color" style={rotateStyle}>
        <div style={{ position: 'absolute', zIndex: 21, textShadow: '0 0 0 white' }}><MDBIcon icon="location-arrow" className={isIdling ? 'amber-text' : 'teal-text'} /></div>
        <div style={{ position: 'absolute', left: '-0.36em', top: '-0.20em', zIndex: 20, color: 'rgba(241, 240, 245, 0.2)' }}><MDBIcon size="2x" icon="circle" /></div>
      </div>
    );
  }

  let markerStyle = `${styles.vehicle} ${styles.marker}`;
  if (props.active) {
    markerStyle += ` ${styles.activeMarker}`;
  }
  if (isIdling) {
    markerStyle += ` ${styles.idling}`;
  } else if (isVehicleDriverDriving(props.vehicleObj)) {
    markerStyle += ` ${styles.drivingMarker}`;
  } else {
    markerStyle += ` ${styles.offMarker}`;
  }

  return (
    <Marker
      className={`marker-${props.vehicleObj.id}`}
      anchor="top"
      onClick={() => { if (props.handleOnClick) props.handleOnClick(); }}
      offset={[0, -5]}
      coordinates={props.isFollowActive ? props.followCoordinates : [vehicleLocationObj.get('location').longitude, vehicleLocationObj.get('location').latitude]}
      className={((vehicleDirection !== undefined) && (vehicleDirection !== 0)) ? styles.directionMarker : styles.marker}
    >
      <div>
        {vehicleDirection &&
          <div style={{ ...vehicleDirectionArrowStyle }}>
            {arrow}
            <div className={styles.vehicleLabel}>
              {props.vehicleObj.get('unitId')}
            </div>
          </div>
          // <div className="gps-heading-arrow text-primary-color" style={{ ...vehicleDirectionArrowStyle }}><MDBIcon size="lg" icon="arrow-circle-up" /></div>
        }
        {!vehicleDirection &&
          <div>
            {!props.isTrailer ?
              <div className={markerStyle}><i className={`material-icons md-36 ${styles.marker}`}>local_shipping</i></div>
              :
              <div className={markerStyle}><i className={`material-icons md-36 ${styles.marker}`}>straighten</i></div>
            }
            <div className={styles.vehicleLabel}>
              {props.vehicleObj.get('unitId')}
            </div>
          </div>
        }
      </div>

    </Marker>
  );
}

VehicleMarker.propTypes = {
  Company: PropTypes.object,
  vehicleObj: PropTypes.object.isRequired,
  eldEvent: PropTypes.object,
  active: PropTypes.bool,
  handleOnClick: PropTypes.func,
  isTrailer: PropTypes.bool,
  isFollowActive: PropTypes.bool,
  followCoordinates: PropTypes.array,
};

export default VehicleMarker;
