import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "../../sbHistory";
import { connect } from 'react-redux';

// Enums
import { DriverViolationTypes } from 'enums/DriverPattern';

// Actions
import { getLinkedDriversForState, deleteDriversForState } from 'actions/Driver';

// API
import * as SI from 'api/DriverPatterns/SpeedingIdling';
import { isStringEmpty, formatName, convertDistance, formatDurationString } from 'api/Helpers';

// Components
import Title from 'components/LayoutTitle/view/Title';
import ListSelectField from 'components/ListSelectField/view/ListSelectField';
import SBTable from 'components/Shared/SBTable/SBTable';

import TextField from 'material-ui/TextField';
import CircularProgress from 'material-ui/CircularProgress';
import DatePicker from 'material-ui/DatePicker';
import { Card, CardActions, CardHeader, CardMedia, CardTitle, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';

// Context
import StoreContext from 'contexts/StoreContext';

class DriverRankingsLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDriversFetched: false,
      intervalTypes: {
        0: 'Week',
        1: 'Month',
        2: 'Year',
      },
      selectedIntervalType: 0,
      dataInterval: {
        startDateTime: moment().startOf('isoWeek').toDate(), // local
        endDateTime: moment().endOf('isoWeek').toDate(),
      },
      violationsCountByDriver: {},
      rankingTypes: {
        [DriverViolationTypes.HOS_VIOLATION]: 'Hours of Service',
        [DriverViolationTypes.SPEED_VIOLATION]: 'Speeding',
      },
      sortType: 0, // 0: ascending, 1: desending
      selectedRankingType: DriverViolationTypes.HOS_VIOLATION,
      driverListingOrder: [], // array of {driverId, rank} sorted by rank to determine when to list the respective driver in speedIdlingDataByDriver in our ranking table
      userFullNameSubstr: '', // string used to filter driverListingOrder by driverListingOrder.driver.user_fullName
    };

    this.handleIntervalTypeChange = this.handleIntervalTypeChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.getDriverViolationsByCount = this.getDriverViolationsByCount.bind(this);
    this.handleRankingTypeChange = this.handleRankingTypeChange.bind(this);
    this.rankDrivers = this.rankDrivers.bind(this);
    this.handleRankingSort = this.handleRankingSort.bind(this);
    this.handleFilterDriverListingOrderByDriverName = this.handleFilterDriverListingOrderByDriverName.bind(this);
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {

  }

  componentWillUnmount() {
    deleteDriversForState();
  }

  handleIntervalTypeChange(intervalTypeName) {
    const { intervalTypes, dataInterval } = this.state;
    const newState = { ...this.state };
    newState.selectedIntervalType = Object.keys(intervalTypes).filter(type => {
      return intervalTypes[type] === intervalTypeName;
    })[0];
    newState.selectedIntervalType = parseInt(newState.selectedIntervalType);

    newState.dataInterval = { ...dataInterval };

    switch(newState.selectedIntervalType) {
      case 0:
        newState.dataInterval.endDateTime = moment(newState.dataInterval.startDateTime).add(1, 'week').subtract(1, 'millisecond');
        break;
      case 1:
        newState.dataInterval.endDateTime = moment(newState.dataInterval.startDateTime).add(1, 'month').subtract(1, 'millisecond');
        break;
      case 2:
        newState.dataInterval.endDateTime = moment(newState.dataInterval.startDateTime).add(1, 'year').subtract(1, 'millisecond');
        break;
    }

    newState.dataInterval.endDateTime = newState.dataInterval.endDateTime.toDate();

    this.setState(newState);
  }

  handleDateChange(date) {
    const newState = { ...this.state };
    newState.dataInterval = { startDateTime: date };

    switch(newState.selectedIntervalType) {
      case 0:
        newState.dataInterval.endDateTime = moment(newState.dataInterval.startDateTime).add(1, 'week').subtract(1, 'millisecond');
        break;
      case 1:
        newState.dataInterval.endDateTime = moment(newState.dataInterval.startDateTime).add(1, 'month').subtract(1, 'millisecond');
        break;
      case 2:
        newState.dataInterval.endDateTime = moment(newState.dataInterval.startDateTime).add(1, 'year').subtract(1, 'millisecond');
        break;
    }

    newState.dataInterval.endDateTime = newState.dataInterval.endDateTime.toDate();

    this.setState(newState);
  }


  getDriverViolationsByCount() {
    const { Company } = this.props;
    const { selectedRankingType, dataInterval } = this.state;

    this.setState({ ...this.state, isLoading: true }, () => {
      deleteDriversForState().then(() => {
        getLinkedDriversForState(undefined, undefined, [], {}, undefined, false, Company.company, true).then(
          (drivers) => {
            // now that we at least have the drivers, lets load it into the table to show progress
            SI.getDriverViolationsByCount(drivers, selectedRankingType, dataInterval.startDateTime, dataInterval.endDateTime).then(
              (violationsCountByDriver) => {
                const newState = { ...this.state, violationsCountByDriver, isDriversFetched: true, isLoading: false };
                this.setState(newState, () => {
                  this.rankDrivers();
                });
              }
            );
          }
        );
      });
    });
  }

  handleRankingTypeChange(rankingTypeString) {
    const { rankingTypes } = this.state;
    const newState = { ...this.state };
    newState.selectedRankingType = Object.keys(rankingTypes).filter(key => {
      if (rankingTypes[key] === rankingTypeString) return true;
    })[0];
    newState.selectedRankingType = parseInt(newState.selectedRankingType);
    this.setState(newState, () => {
    });
  }

  rankDrivers() {
    this.setState({ ...this.state, isLoading: true }, () => {
      const { sortType, violationsCountByDriver } = this.state;
      const newState = { ...this.state };

      setTimeout(() => {
        // fake progress to hide UI lag
        newState.isLoading = false;
        newState.driverListingOrder = [];

        // first create an array of objects, based on each object in speedIdlingDataByDriver
        const violationsCountByDriverArr = Object.keys(violationsCountByDriver).map(driverId => {
          return violationsCountByDriver[driverId];
        });

        // now sort this array based on selectedRankingType
        violationsCountByDriverArr.sort((driverViolationDataA, driverViolationDataB) => {

          if (sortType === 0) { // ascending
            return driverViolationDataA.count - driverViolationDataB.count;
          }
          if (sortType === 1) { // descending
            return driverViolationDataB.count - driverViolationDataA.count;
          }
        });

        // now that violationsCountByDriverArr is sorted by given ranking, lets sort driverListingOrder according to violationsCountByDriverArr
        let lastSeenRank;
        if (!sortType) {
          lastSeenRank = 1;
          newState.driverListingOrder = violationsCountByDriverArr.map((driverViolationsCountObject, index) => {
            let rank = lastSeenRank;
            const previousDriverViolationsCountObject = violationsCountByDriverArr[index - 1];

            if (previousDriverViolationsCountObject) {
              if (driverViolationsCountObject.count !== previousDriverViolationsCountObject.count) {
                rank++;
              }
            }

            lastSeenRank = rank;

            return {
              driverId: driverViolationsCountObject.driver.id,
              user_fullName: driverViolationsCountObject.driver.get('user_fullName'),
              rank,
            }
          });
        } else { // reverse order

          // note that in reverse order, we can't simply use the length of violationsCountByDriverArr. Ex.
          // consider the following scores: 14, 7, 0, 0, 0, 0. For a length of 58 drivers, the rankings translate to 58, 57, 56, 56, 56, 56 ...
          // therefore, we need to filter out 0 scores
          lastSeenRank = violationsCountByDriverArr.filter(driverViolationsCountObject => driverViolationsCountObject.count > 0).length + 1;
          newState.driverListingOrder = violationsCountByDriverArr.map((driverViolationsCountObject, index) => {
            let rank = lastSeenRank;
            const previousDriverViolationsCountObject = violationsCountByDriverArr[index - 1];

            if (previousDriverViolationsCountObject) {
              if (driverViolationsCountObject.count !== previousDriverViolationsCountObject.count) {
                rank--;
              }
            }

            lastSeenRank = rank;

            return {
              driverId: driverViolationsCountObject.driver.id,
              user_fullName: driverViolationsCountObject.driver.get('user_fullName'),
              rank,
            }
          });
        }


        this.setState(newState);
      }, 2300);

    });
  }

  handleRankingSort() {
    const { sortType } = this.state;
    this.setState({ ...this.state, sortType: (sortType) ? 0 : 1 }, () => {
      this.rankDrivers();
    });
  }

  handleFilterDriverListingOrderByDriverName(value) {
    // given string value input, filter the driverListingOrder by value substr on driver user_fullName
    let userFullNameSubstr = value;
    if (!isStringEmpty(userFullNameSubstr)) {
      userFullNameSubstr = userFullNameSubstr.trim();
    } else {
      userFullNameSubstr = '';
    }

    setTimeout(() => {
      this.setState({ ...this.state, userFullNameSubstr });
    }, 500);
  }


  render() {
    const { title, handleLazyLoad, height } = this.props;
    const { intervalTypes, selectedIntervalType, dataInterval, isLoading, isDriversFetched, violationsCountByDriver, rankingTypes, selectedRankingType, sortType, driverListingOrder, userFullNameSubstr } = this.state;

    const intervalSelectorStyle = { marginRight: '.5em', width: '8em', textAlign: 'left' };
    const tableStyles = {
      rank: { width: '30%' },
      driverName: { width: '40%' },
      count: { width: '30%' },
    };

    const tableHeaderRows = [{ key: 'driverRankingsTableHeader', columns: [
      { element: <div>Rank</div>, props: { style: tableStyles.rank, handleSort: () => { this.handleRankingSort() }, isSortActive: (sortType === 1) } },
      { element: <div>Driver</div>, props: { style: tableStyles.driverName } },
      { element: <div>Violation Count</div>, props: { style: tableStyles.count } },
    ]}];

    let tableBodyRows = [];

    if (driverListingOrder.length > 0) { // means we have drivers as well

      // first filter by userFullNameSubstr
      const driverListingOrderFiltered = driverListingOrder.filter(orderObject => {
        const driverFullName = orderObject.user_fullName;
        return driverFullName.indexOf(userFullNameSubstr) !== -1;
      });

      driverListingOrderFiltered.map((rankingObject, index) => {
        const driverId = rankingObject.driverId;
        const rank = rankingObject.rank || '-';
        const driverViolationsCount = violationsCountByDriver[driverId];
        const driver = driverViolationsCount.driver;
        const count = driverViolationsCount.count;

        const columns = [
          {
            props: { style: tableStyles.rank },
            element: <div>{ rank }</div>
          },
          {
            props: { style: tableStyles.driverName },
            element: <div>{ formatName(driver.get('user_fullName')) }</div>
          },
          {
            props: { style: tableStyles.count },
            element: (
              <div>
                <div>{ count }</div>
              </div>
            )
          },
        ];

        tableBodyRows.push({
          key: driver.id,
          props: {
            id: driver.id,
          },
          columns,
        });
      });
    }

    return (
      <div className="mt-3">
        <Card>
          <CardHeader title="Select Data Interval" />
          <CardText>
            { !isLoading &&
              <div>
                <div className="inlineBlock" style={{ verticalAlign: 'middle' }}>
                  <ListSelectField
                    onChange={(e, index, rankingTypeString) => this.handleRankingTypeChange(rankingTypeString)}
                    floatingLabelText="Violation Type"
                    value={rankingTypes[selectedRankingType]}
                    maxHeight={200}
                    list={Object.keys(rankingTypes).map(rankingTypeInt => rankingTypes[rankingTypeInt])}
                    disabled={isLoading}
                    style={{ display: 'inline-block', width: '15em', textAlign: 'left' }}
                  />

                  <ListSelectField
                    onChange={(e, index, intervalTypeName) => this.handleIntervalTypeChange(intervalTypeName)}
                    floatingLabelText="Interval"
                    value={intervalTypes[selectedIntervalType]}
                    maxHeight={200}
                    list={Object.keys(intervalTypes).map(type => intervalTypes[type])}
                    style={{ ...intervalSelectorStyle, width: '7em' }}
                  />
                </div>

                <div className="inlineBlock" style={{ verticalAlign: 'middle', top: '-1em', marginTop: '.5em', paddingTop: '3em' }}>
                  <DatePicker
                    id="intervalStart"
                    hintText={moment(dataInterval.startDateTime).format('MMM DD YYYY')}
                    value={dataInterval.startDateTime}
                    onChange={(e, date) => this.handleDateChange(date)}
                    formatDate={(date) => moment(date).format('MMM DD, YYYY')}
                    style={{ display: 'inline-block' }}
                    textFieldStyle={{ width: '8em' }}
                    mode="landscape"
                  />

                  <div className="inlineBlock">
                    <RaisedButton
                      key="filterByDate"
                      label="Confirm"
                      onClick={() => this.getDriverViolationsByCount()}
                      primary
                    />
                  </div>
                </div>
              </div>
            }

            { isLoading &&
              <CircularProgress />
            }

            <div style={{ marginTop: '.5em' }}>
              Currently Selected Interval: <b>{ moment(dataInterval.startDateTime).format('MMMM DD YYYY') } - { moment(dataInterval.endDateTime).format('MMMM DD YYYY') }</b>
            </div>
          </CardText>
        </Card>

        { isDriversFetched && <div style={{ marginTop: '1.5em' }} /> }
        { isDriversFetched &&
          <Card>
            <CardHeader title="Rankings" />
            <CardText>
              <div>
                  <TextField
                    id="driverFilterTextField"
                    defaultValue={''}
                    hintText="Filter by Driver Name"
                    onChange={(e, value) => this.handleFilterDriverListingOrderByDriverName(value)}
                    disabled={isLoading}
                    style={{ display: 'inline-block', top: '-2.05em', width: '15em', textAlign: 'left' }}
                  />
              </div>

              <SBTable
                tableHeaderRows={tableHeaderRows}
                tableBodyRows={tableBodyRows}
                emptyTableMessage={
                  (Object.keys(violationsCountByDriver).length === 0) ?
                    <span>You currently have no drivers in our system. Begin tracking violations by adding a driver in the <b>Drivers</b> tab</span>
                    :
                    <span>Loading...</span>
                }
              />
            </CardText>
          </Card>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { Company, Driver } = state;
  return {
    Company,
    Driver,
  };
};

DriverRankingsLayout.propTypes = {
  Company: PropTypes.object.isRequired,
  Driver: PropTypes.object.isRequired,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  isLazyLoading: PropTypes.bool,
  height: PropTypes.any,
  handleLazyLoad: PropTypes.func,
  startDateTimeUTC: PropTypes.instanceOf(Date),
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(DriverRankingsLayout);
