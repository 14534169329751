import { callCloudFunction } from "api/Parse";

/** @module Odometer */

/**
 * @memberof module:Odometer
 * @description Calls the unified odometer readings function on the server
 * 
 * @param {Array} eldEventObjectIdArr Array of ELDEvent Object Ids
 * @param {String} vehicleUnitId A specific vehicleUnitId, optional
 * @param {Boolean} isSpecificInterval Determines whether we use the eldEvents as the time period or eldDailyCertifications, optional
 */
async function getOdometerReadings(eldEventObjectIdArr, vehicleUnitId, isSpecificInterval) {
  const results = await callCloudFunction('getOdometerReadingsNew', { eldEventObjectIdArr, vehicleUnitId, isSpecificInterval });
  const { odometerReadings, warnings } = results;

  return { odometerReadings, warnings };
}

export {
  getOdometerReadings,
}
