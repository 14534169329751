
/** @module DispatchJobUserTag */

import {
  addRecord, copyQuery, count, createQuery, createQueryOr, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery
} from 'sb-csapi/dist/AAPI';

import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @memberof module:DispatchJobUserTag
 *
 * @description Get DispatchJobUserTags according to filter criteria
 *
 * @param {object} options - See example
 * @param {string} dispatchJobObjectId - The job we wish to retrieve user tags for
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { object } - { dispatchJobUserTag: [DispatchJobUserTag, ...] }
 */
 async function getDispatchJobUserTags(options = { sessionToken: getCurrentUserSessionToken() }, dispatchJobObjectId, filters = [], sortBy = new Sort(QuerySortOrder.ASCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], queryAll) {

  let dispatchJobUserTagQuery = createQuery('DispatchJobUserTag');

  const _filters = [...filters];

  const dispatchJobQuery = createQuery('DispatchJob');
  setQueryRestriction(dispatchJobQuery, QueryRestriction.EQUAL_TO, 'objectId', dispatchJobObjectId);


  setQueryRestriction(dispatchJobUserTagQuery, QueryRestriction.MATCHES_QUERY, 'dispatchJob', dispatchJobQuery);

  _filters.map(filter => {
    setQueryRestriction(dispatchJobUserTagQuery, filter.queryRestriction, filter.attribute, filter.value);
  });

  // sort
  sortQuery(dispatchJobUserTagQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchJobUserTagQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchJobUserTagQuery, selectedAttributes);

  const promises = [findRecords(options, dispatchJobUserTagQuery, false, queryAll)];

  try {
    const [dispatchJobUserTags] = await Promise.all(promises);

    return { dispatchJobUserTags };

  } catch (err) {
    throw new Error(err);
  }
}


/**
 * @memberof module:DispatchJobUserTag
 *
 * @description Add a DispatchJobUserTag
 *
 * @param {object} keyValueObj - The select fields we wish to update
 *
 * @returns {DispatchJobUserTag} - The created dispatchJobUserTag
 */
 async function addDispatchJobUserTag(keyValueObj) {
   if (!keyValueObj.dispatchJob) throw new Error('Cannot create record: Requires DispatchJob pointer');
   if (!keyValueObj.belongsToCompany) throw new Error('Cannot create record: Requires BelongsToCompany pointer');

  try {
    const dispatchJobUserTag = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'DispatchJobUserTag', keyValueObj, getCurrentUserCompanyObjectId());
    return dispatchJobUserTag;
  } catch (err) {
    throw new Error(err);
  }
}


export {
  addDispatchJobUserTag,
  getDispatchJobUserTags,
};
