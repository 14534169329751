import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "../../../sbHistory";
import { RaisedButton, MenuItem, SelectField, Table, TableHeader, TableBody, TableRow, TableRowColumn } from 'material-ui';
import { MDBContainer, MDBRow, MDBIcon, MDBBtn, MDBInput } from 'mdbreact';

// API
import HOSRegulation from 'api/Lists/HOSRegulation';
import * as Helpers from 'api/Helpers';
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getTimezoneAbbreviation } from 'api/utils/timezone';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './ViolationDetailsView.module.scss';

// Enums
import { ExcludedRegulations } from 'sb-csapi/dist/enums/HOS/Regulation';

function getViolations(eldDailyCertificationArr, hideIsHiddenBool) {
  if (eldDailyCertificationArr && eldDailyCertificationArr.length > 0) {
    const violations = [];
    for (let i = 0; i < eldDailyCertificationArr.length; i++) {
      if (eldDailyCertificationArr[i].get('hosViolations')) {
        for (let j = 0; j < eldDailyCertificationArr[i].get('hosViolations').length; j++) {
          const hosViolation = eldDailyCertificationArr[i].get('hosViolations')[j];
          if (
            !hosViolation.get('disabled') &&
            !hosViolation.get('potential') &&
            !hosViolation.get('hideForever') &&
            !(hideIsHiddenBool && hosViolation.get('isHidden')) &&
            !(ExcludedRegulations && ExcludedRegulations.includes(hosViolation.get('regulationInt')))
          ) {
            violations.push(eldDailyCertificationArr[i].get('hosViolations')[j]);
          }
        }
      }
    }
    return violations;
  }
  return [];
}

function ViolationDetailsView(props) {
  const { timezoneOffsetFromUTC } = props;

  const hosViolations = getViolations(props.eldDailyCertificationArr, props.selectedViolationFilter.value === 'hideHidden');
  if (hosViolations.length > 0 && !props.violationsNonZeroBool) {
    props.setViolationsNonZeroBool();
  }

  return (
    <div className={styles.table}>
      <Table
        fixedHeader
      >
        <TableHeader
          adjustForCheckbox={false}
          displaySelectAll={false}
          style={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          <TableRow>
            <TableRowColumn style={{ textAlign: 'left', width: '18em' }}>{t('Date')}</TableRowColumn>
            <TableRowColumn style={{ textAlign: 'center', width: '9em' }}>{t('Time')}</TableRowColumn>
            <TableRowColumn style={{ textAlign: 'center' }}>{t('Violation')}</TableRowColumn>
            <TableRowColumn style={{ textAlign: 'right' }} />
            <TableRowColumn style={{ textAlign: 'right' }} >
              {props.generatingHOSViolationMonthlyReport &&
                <LoadingIcon />
              }
              {!props.generatingHOSViolationMonthlyReport &&
                <MDBBtn
                  onClick={props.generateReport}
                  color="primary"
                  size="sm"
                >
                  <MDBIcon icon="print" /> &nbsp;
                  {t('Print')}
                </MDBBtn>
              }
            </TableRowColumn>
          </TableRow>
        </TableHeader>
        <TableBody
          displayRowCheckbox={false}
        >
          {hosViolations.map((hosViolation) => (
            <TableRow key={hosViolation.id}>
              <TableRowColumn style={{ textAlign: 'left', width: '18em' }}>
                <b>{moment(hosViolation.get('triggerTime')).tz(timezoneOffsetFromUTC || moment.tz.guess()).format('LL')}</b>
              </TableRowColumn>
              <TableRowColumn style={{ textAlign: 'center', width: '13em' }}>
                <b>{moment(hosViolation.get('triggerTime')).tz(timezoneOffsetFromUTC || moment.tz.guess()).format('LT')} ({getTimezoneAbbreviation(getAttribute(hosViolation, 'triggerTime', true), timezoneOffsetFromUTC)})</b>
              </TableRowColumn>
              <TableRowColumn className={styles.violationMessage}>
                <div>{HOSRegulation[hosViolation.get('regulationInt')] && HOSRegulation[hosViolation.get('regulationInt')].message}</div>
                <div>{!HOSRegulation[hosViolation.get('regulationInt')] && <div>{t('General Violation')}</div>}</div>
              </TableRowColumn>
              {/* <TableRowColumn style={{ textAlign: 'center', width: '13em' }} >
                <RaisedButton
                  icon={<i className="material-icons">chevron_right</i>}
                  label="Go To Log"
                  onClick={() => history.push({ pathname: 'driver', search: "driver=" + props.driver.id + "&view=hosEvents&date=" + moment(Helpers.referenceToTimezone(hosViolation.get('triggerTime'), props.driver.get('timezoneOffsetFromUTC'))).format('DDMMYY') })}
                  primary
                  style={{ float: 'right', margin: '1em' }}
                />
              </TableRowColumn> */}
              <TableRowColumn style={{ textAlign: 'center' }}>
                {hosViolation.get('driver') &&
                  <MDBBtn
                    size="sm"
                    color="primary"
                    onClick={() => history.push({ pathname: 'driver', search: "driver=" + props.driver.id + "&view=hosEvents&date=" + moment(Helpers.referenceToTimezone(hosViolation.get('triggerTime'), props.driver.get('timezoneOffsetFromUTC'))).format('DDMMYY') })}
                  >
                    {t('Go To Log')}
                  </MDBBtn>
                }
                <br />
                {hosViolation.get('isHidden') &&
                  <MDBBtn
                    size="sm"
                    color="secondary"
                    onClick={() => props.hideUnhideHosViolation(hosViolation, false)}
                  >
                    {t('Unhide')}
                  </MDBBtn>
                }
                {!hosViolation.get('isHidden') &&
                  <MDBBtn
                    size="sm"
                    color="info"
                    onClick={() => props.hideUnhideHosViolation(hosViolation, true)}
                  >
                    {t('Hide')}
                  </MDBBtn>
                }
              </TableRowColumn>
              <TableRowColumn>
                {/* <label>
                  Notes:
                </label>
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                /> */}
                <MDBInput type="textarea" label={t('Notes')} value={hosViolation.get('note')} size="sm" onChange={(e) => props.updateNote(e, hosViolation)} rows="1" />
              </TableRowColumn>
            </TableRow>
          ))}
          {/* props.fetched && props.eldDailyCertificationArr.length === 0 &&
            <TableRow>
              <TableRowColumn colSpan="42" style={{ textAlign: 'center', fontWeight: 'bold', verticalAlign: 'middle' }}>
                No Logs for this Month
              </TableRowColumn>
            </TableRow>
          */}
          {props.fetched && hosViolations.length === 0 &&
            <TableRow>
              <TableRowColumn colSpan="42" style={{ textAlign: 'center', fontWeight: 'bold', verticalAlign: 'middle' }}>
                {t('No Violations for this Month')}
              </TableRowColumn>
            </TableRow>
          }
          {!props.fetched &&
            <TableRow>
              <TableRowColumn colSpan="42" className="loadingIconPadding">
                <LoadingIcon />
              </TableRowColumn>
            </TableRow>
          }
        </TableBody>
      </Table>
    </div>
  );
}

ViolationDetailsView.propTypes = {
  driver: PropTypes.object.isRequired,
  eldDailyCertificationArr: PropTypes.array.isRequired,
  fetched: PropTypes.bool.isRequired,
  generatingHOSViolationMonthlyReport: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  handleViewChange: PropTypes.func,
  generateReport: PropTypes.func.isRequired,
  violationsNonZeroBool: PropTypes.bool.isRequired,
  setViolationsNonZeroBool: PropTypes.func.isRequired,
  timezoneOffsetFromUTC: PropTypes.string,
  selectedViolationFilter: PropTypes.object,
  hideUnhideHosViolation: PropTypes.func,
  updateNote: PropTypes.func,
};

export default ViolationDetailsView;
