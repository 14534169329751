import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Parse from 'parse';
import history from "../../sbHistory";

import { Card, CardActions, CardHeader, CardMedia, CardTitle, CardText } from 'material-ui/Card';
import { Table, TableHeader, TableRow, TableBody, TableRowColumn, Toggle, TextField } from 'material-ui';

// API
import { queryCompanyObjects } from 'api/Getters';
import * as Helpers from 'api/Helpers';

// Components
import VehiclesTable from 'components/VehiclesTable/container/VehiclesTable';
import Button from 'sbCore/Button/Button';

// CSS

// Lists

class ActiveVehiclesLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.downloadCsv = this.downloadCsv.bind(this);
  }

  componentDidMount() {
  }

  downloadCsv() {
    const csvArr = [`Vehicle,ELD Hardware,Latest Activity`].concat(this.props.vehicleArr.map((obj) => {
      return `${obj.get('unitId')},${obj.get('vehicleLocation') && (obj.get('vehicleLocation').get('eldHardwareSerial') || obj.get('vehicleLocation').get('vehicleSerial'))},${obj.get('vehicleLocation') && obj.get('vehicleLocation').get('dateTime') && `"${moment(obj.get('vehicleLocation').get('dateTime')).format('ll')}"`}`
    }));

    const csvString = csvArr.join('\n');
    // Create the csv file and allow client to download it
    Helpers.createCsvFile(`Vehicles on Switchboard - ${moment().format('YYYY-MM-DD')}`, csvString, true);
  }

  render() {
    return (
      <div>
        <div>
          <div className="col-md-12">
            <br />
          </div>

          <div className="col-md-12">
            <Card className="translate-me">
              <CardHeader
                title="Vehicles on Switchboard"
              />
              <CardText>
                <div>
                  {/* <br />

                  <b>Active Vehicles on Switchboard: </b>
                  <kbd className="notranslate">{
                    this.props.vehicleArr.filter((vehicle) => (
                      vehicle.get('vehicleLocation') &&
                      vehicle.get('vehicleLocation').get('dateTime') &&
                      moment(vehicle.get('vehicleLocation').get('dateTime')).isAfter(moment().subtract(2, 'months'))
                    )).length
                  }</kbd> */}
                  <Button
                    type="button"
                    icon="pi pi-download"
                    label="Download CSV"
                    onClick={(e) => this.downloadCsv()}
                    sbVariant="short"
                  />

                </div>

                <br />
                <Table
                  selectable={false}
                  multiSelectable={false}
                >
                  <TableHeader
                    displaySelectAll={false}
                    adjustForCheckbox={false}
                    enableSelectAll={false}
                  ><TableRow>
                      <TableRowColumn style={{ width: '25%' }}>
                        <b>Vehicle</b>
                        <br />
                      </TableRowColumn>
                      <TableRowColumn style={{ width: '25%' }}>
                        <b>ELD Hardware</b>
                      </TableRowColumn>
                      <TableRowColumn style={{ width: '25%' }}>
                        <b>Latest Activity</b>
                      </TableRowColumn>
                    </TableRow></TableHeader>
                  <TableBody
                    displayRowCheckbox={false}
                  >
                    {this.props.vehicleArr.map((obj) => (
                      <TableRow key={obj.id}>
                        <TableRowColumn style={{ width: '25%' }}>
                          {obj.get('unitId')}
                          {obj.get('user_phoneNumber') &&
                            <div>
                              <i>{obj.get('user_phoneNumber')}</i>
                            </div>
                          }
                        </TableRowColumn>
                        <TableRowColumn style={{ width: '25%' }}>
                          {obj.get('vehicleLocation') && (obj.get('vehicleLocation').get('eldHardwareSerial') || obj.get('vehicleLocation').get('vehicleSerial'))}
                        </TableRowColumn>
                        <TableRowColumn style={{ width: '25%' }}>
                          {obj.get('vehicleLocation') && obj.get('vehicleLocation').get('dateTime') && moment(obj.get('vehicleLocation').get('dateTime')).format('ll')}
                        </TableRowColumn>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </CardText>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

ActiveVehiclesLayout.propTypes = {
  vehicleArr: PropTypes.array,
};

export default ActiveVehiclesLayout;
