import React, { useState, useEffect } from 'react';

// API
import { addDispatchTrailer, getDispatchTrailers, removeDispatchTrailer } from 'api/Dispatch/DispatchTrailer';

// CSAPI
import { getRecordByObjectId, getCurrentUserSessionToken, getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import TrailerAutocomplete from 'sbCore/TrailerAutocomplete/TrailerAutocomplete';

// Styling
import './style.scss';

/**
 * @description Contains a filter to select a trailer and create a dispatch trailer using the trailer and a dispatch transfer
 * @param {string} dispatchTransferObjectId - The objectId of the dispatchTransfer object we wish to update.
 * @param {bool} [hideLabel] - Whether to hide the label of the autocomplete
 * @param {bool} [clearDispatchTrailer] - If passed in, will clear the dispatch trailer if present
 * @param {function} [onSelectDispatchTrailer] - Returns the added/removed dispatchTrailer
 *
 * @returns
 *
 * @example
 * <DispatchTrailerAutocomplete dispatchTransfer={dispatchTransfer} warning />
*/
function DispatchTrailerAutocomplete({ ...props }) {
  // ** useStates ** //
  const [selectedTrailer, setSelectedTrailer] = useState(undefined);
  const [dispatchTrailer, setDispatchTrailer] = useState(undefined);
  const [dispatchTransfer, setDispatchTransfer] = useState(undefined);

  // ** useEffects ** //
  useEffect(() => {
    let didCancel = false;

    async function _getDispatchTransfer() {
      if (!didCancel) {
        const dispatchTransfer = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'DispatchTransfer', props.dispatchTransferObjectId);
        setDispatchTransfer(dispatchTransfer);
      }
    }

    _getDispatchTransfer();

    return () => { didCancel = true; };
  }, [props.dispatchTransferObjectId]);

  useEffect(() => {
    let didCancel = false;

    if (!dispatchTransfer) return;

    async function _getDispatchTrailers() {
      if (!didCancel) {
        const dispatchTransferObjectId = getAttribute(dispatchTransfer, 'objectId');
        const includedPointers = ['trailer', 'transfer'];

        const { dispatchTrailers } = await getDispatchTrailers(undefined, dispatchTransferObjectId, undefined, undefined, includedPointers);

        if (dispatchTrailers && dispatchTrailers.length > 0) {
          // A transfer should only contain one dispatch trailer, so get first element from the array.
          const trailer = getAttribute(dispatchTrailers[0], 'trailer', true);

          setDispatchTrailer(dispatchTrailers[0]);
          setSelectedTrailer(trailer);
        }
      }
    }

    _getDispatchTrailers();

    return () => { didCancel = true; };
  }, [dispatchTransfer]);

  // use isCancel for all async functions
  useEffect(() => {
    let didCancel = false;

    async function _addDispatchTrailer() {
      if (!didCancel) {
        const keyValueObj = {
          dispatchTransfer,
          trailer: selectedTrailer,
        };
        const dispatchTrailer = await addDispatchTrailer(keyValueObj);
        setDispatchTrailer(dispatchTrailer);

        if (props.onSelectDispatchTrailer) props.onSelectDispatchTrailer(dispatchTrailer);
      }
    }

    async function _removeDispatchTrailer() {
      if (!didCancel) {
        await removeDispatchTrailer(dispatchTrailer);
        setDispatchTrailer(undefined);

        if (props.onSelectDispatchTrailer) props.onSelectDispatchTrailer(dispatchTrailer);
      }
    }

    if (selectedTrailer && !dispatchTrailer && dispatchTransfer) {
      _addDispatchTrailer();
    }

    if (!selectedTrailer && dispatchTrailer) {
      _removeDispatchTrailer();
    }

    return () => { didCancel = true; };
  }, [selectedTrailer]);

  useEffect(() => {
    if (props.clearDispatchTrailer) setSelectedTrailer(undefined);
  }, [props.clearDispatchTrailer]);

  // ** Callbacks ** //
  function onSelectTrailer(trailer) {
    setSelectedTrailer(trailer);
  }

  return (
    <TrailerAutocomplete hideLabel={props.hideLabel} onSelectTrailer={(trailer) => onSelectTrailer(trailer)} trailer={selectedTrailer} disabled={props.disabled} warning />
  );
}

export default DispatchTrailerAutocomplete;
