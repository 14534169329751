import React from 'react';
import PropTypes from 'prop-types';

// components
import SBEditButton from 'components/Shared/SBEditButton/SBEditButton';

import { MDBBadge } from 'mdbreact';

// enums
import { StatusTypes, ActionTypes } from 'enums/DispatchAction';

// CSS
import './styles.scss';

class DispatchActionStatusBadge extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { containerClassName, className, showEditButton, showCancelEditButton, handleEdit, actionType } = this.props;
    let currentStatus = this.props.status;

    let _containerClassName = 'sb-transfer-status-badge-container';
    if (containerClassName) _containerClassName += ` ${containerClassName}`;

    let _className = 'sb-transfer-status-badge';
    if (showEditButton || showCancelEditButton) {
      _className = 'd-inline-block ' + _className;
    }

    if (this.props.status === 4){
      if (actionType === 'PU'){
        currentStatus = 1;
      }
      if (actionType === 'DO'){
        currentStatus = 4;
      }
    }

    let matchingActionType = '';

    // since action type could be 0, check if its undefined instead
    const actionTypeExists = actionType !== undefined;

    if (actionTypeExists && !this.props.status) {
      Object.keys(ActionTypes).find(key => {
        const currentActionType = ActionTypes[key];

        if (currentActionType === actionType) {
          // find the matching background color className (ex. SOME_VALUE -> some-value -> bg-pickup)
          const colorClassKeyExpression = key.replace(/_/g, '-').toLowerCase();
          _className += ` bg-${colorClassKeyExpression}`;
  
          matchingActionType = key;
        }

        return currentActionType;
      });
    }

    // figure out the value of StatusType{} from the given statusType
    let matchingStatusTypeObject = {};
    if (actionType && this.props.status) {
      Object.keys(StatusTypes).find(key => {
        const statusTypeObject = StatusTypes[key];
        const isMatchingStatus = statusTypeObject.status === currentStatus;
  
        if (isMatchingStatus) {
          // find the matching background color className (ex. SOME_VALUE -> some-value -> bg-dispatch-transfer-some-value)
          const colorClassKeyExpression = key.replace(/_/g, '-').toLowerCase();
          _className += ` bg-dispatch-action-${colorClassKeyExpression}`;
  
          matchingStatusTypeObject = statusTypeObject;
        }
        return isMatchingStatus;
      });
    }

    // add any final classNames (we do the assign here so the user can overwrite our own properties on className)
    if (className) _className += ` ${className}`;

    return (
      <div className={_containerClassName}>
        { matchingStatusTypeObject &&
          <React.Fragment>
            <MDBBadge className={_className}>{ matchingStatusTypeObject.description }</MDBBadge>
            { showEditButton &&
              <SBEditButton containerClassName="align-middle" showCancelButton={showCancelEditButton} onClick={(e) => handleEdit(e)} />
            }
          </React.Fragment>
        } 

        {matchingActionType && !this.props.status
          && (
            <React.Fragment>
              <MDBBadge className={_className}>{matchingActionType}</MDBBadge>
              {showEditButton &&
                <SBEditButton containerClassName="align-middle" showCancelButton={showCancelEditButton} onClick={(e) => handleEdit(e)} />
              }
            </React.Fragment>
          )}
      </div>
    );
  }
}

DispatchActionStatusBadge.defaultProps =  {
  handleEdit: (e) => { console.log(e) }
};

DispatchActionStatusBadge.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  status: PropTypes.number.isRequired, // ex. 0, or preferrably StatusType.CONST_NAME
  actionType: PropTypes.string.isRequired, //ex. PU or DO
  showEditButton: PropTypes.bool,
  showCancelEditButton: PropTypes.bool,
  handleEdit: PropTypes.func,
};

export default DispatchActionStatusBadge;
