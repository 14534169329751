import moment from 'moment-timezone';
import { addRecord, copyQuery, count, createQuery, createQueryOr, createTempPointer, createTempRecord, find, getAttribute, getCurrentUser, getObjectById, includePointers, setQueryRestriction, sortQuery, updateRecord, destroyRecord } from 'api/Parse';
import { removePricing } from 'api/Pricing';
import Sort from 'sbObjects/Sort';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { addToAddressbook } from 'api/Address';

async function getDispatchItemTransfersByJob(dispatchJobObjectId, sortBy = new Sort('createdAt', QuerySortOrderTypes.ASCENDING), filters = []) {
  if (!dispatchJobObjectId) {
    throw new Error('No dispatchJobObjectId provided');
  }
  const transferQuery = createQuery('DispatchTransfer');
  setQueryRestriction(transferQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchJob', dispatchJobObjectId);

  if (sortBy){
      sortQuery(transferQuery, sortBy.order, sortBy.attribute);
  }

  // apply filter here - this may need to be expanded in future (include filtering of properties not directly in dispatchtransfer)
  filters.map(filter => {
    setQueryRestriction(transferQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
  });
  includePointers(transferQuery, [
    'dispatchItem',
    'dispatchJob',
    'shipperDispatchOrganization',
    'consigneeDispatchOrganization',
    'shipperDispatchOrganization.address',
    'consigneeDispatchOrganization.address',
    'shipperAddress',
    'consigneeAddress',
  ]);
  const dispatchTransfers = await find(transferQuery, false, true);
  // sort all dispatchTransfers by the pickup/dropoff action date before returning
  // dispatchTransfers.sort((dispatchTransferA, dispatchTransferB) => {
  //   const dispatchTransferAPickup = getAttribute(dispatchTransferA, 'pickupDispatchAction');
  //   const dispatchTransferADropoff = getAttribute(dispatchTransferA, 'dropoffDispatchAction');
  //   const dispatchTransferBPickup = getAttribute(dispatchTransferB, 'pickupDispatchAction');
  //   const dispatchTransferBDropoff = getAttribute(dispatchTransferB, 'dropoffDispatchAction');

  //   const dispatchTransferAPickupMillis = moment(getAttribute(dispatchTransferAPickup, 'dateTimeUTC')).valueOf();
  //   const dispatchTransferADropoffMillis = moment(getAttribute(dispatchTransferADropoff, 'dateTimeUTC')).valueOf();

  //   const dispatchTransferBPickupMillis = moment(getAttribute(dispatchTransferBPickup, 'dateTimeUTC')).valueOf();
  //   const dispatchTransferBDropoffMillis = moment(getAttribute(dispatchTransferBDropoff, 'dateTimeUTC')).valueOf();

  //   // if the pickup times are the same
  //   if (dispatchTransferAPickupMillis === dispatchTransferBPickupMillis) {

  //     // if even the dropoff times are the same, use createdAt
  //     if (dispatchTransferADropoffMillis === dispatchTransferBDropoffMillis) {
  //       return getAttribute(dispatchTransferAPickup, 'createdAt') - getAttribute(dispatchTransferBPickup, 'createdAt');
  //     }

  //     return dispatchTransferADropoffMillis - dispatchTransferBDropoffMillis;
  //   }

  //   return dispatchTransferAPickupMillis - dispatchTransferBPickupMillis;
  // });
  return dispatchTransfers;
}

export {
  getDispatchItemTransfersByJob,
}
