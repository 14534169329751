import * as ActionConstants from './ActionConstants';
import store from '../store';

import * as Setters from '../api/Setters';
import * as Getters from '../api/Getters';
import * as Helpers from '../api/Helpers';

import { updateDriverForState } from 'actions/Driver';
import { updateTrailerForState } from 'actions/Trailer';

export function fetchingGlobalVehicles() {
  return {
    type: ActionConstants.FETCH_GLOBALVEHICLES_INPROGRESS,
  };
}

export function fetchGlobalVehiclesSuccess(vehicles) {
  return {
    type: ActionConstants.FETCH_GLOBALVEHICLES_SUCCESS,
    vehicles,
  };
}

export function fetchGlobalVehiclesError(error) {
  return {
    type: ActionConstants.FETCH_GLOBALVEHICLES_ERROR,
    error,
  };
}

export function deleteGlobalVehicles() {
  return {
    type: ActionConstants.DELETE_GLOBALVEHICLES,
  };
}

export function addingGlobalVehicle() {
  return {
    type: ActionConstants.ADD_GLOBALVEHICLE_INPROGRESS,
  };
}

export function addGlobalVehicleSuccess(vehicle) {
  return {
    type: ActionConstants.ADD_GLOBALVEHICLE_SUCCESS,
    vehicle,
  };
}

export function addGlobalVehicleError(error) {
  return {
    type: ActionConstants.ADD_GLOBALVEHICLE_ERROR,
    error,
  };
}

export function updatingGlobalVehicle(vehicles) {
  return {
    type: ActionConstants.UPDATE_GLOBALVEHICLE_INPROGRESS,
    vehicles,
  };
}

export function updateGlobalVehicleSuccess(vehicles, updatedVehicle) {
  return {
    type: ActionConstants.UPDATE_GLOBALVEHICLE_SUCCESS,
    vehicles,
    updatedVehicle,
  };
}

export function updateGlobalVehicleError(vehicle, error) {
  return {
    type: ActionConstants.UPDATE_GLOBALVEHICLE_ERROR,
    vehicle,
    error,
  };
}

export function enableGlobalVehiclesSuccess(vehicles) {
  return {
    type: ActionConstants.ENABLE_GLOBALVEHICLES_SUCCESS,
    vehicles,
  };
}

export function disableGlobalVehiclesSuccess(vehicles) {
  return {
    type: ActionConstants.DISABLE_GLOBALVEHICLES_SUCCESS,
    vehicles,
  };
}

// export function disableGlobalVehicle(vehicleParseObj) {
//   const promise = new Promise((resolve, reject) => {
//     vehicleParseObj.set('enabled', false).save().then(
//       vehicleObject => {
//         resolve();
//         store.dispatch(disableGlobalVehiclesSuccess([vehicleObject]));
//       },
//     );
//   });
//   return promise;
// }

export function getGlobalVehicleIncludeArr() {
  return ['vendor', 'drivers', 'vehicleLocation', 'eldHardware', 'licensePlate', 'weighStationBypassVehicle'];
}

export function fetchGlobalVehiclesForState() {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingGlobalVehicles());

    const filter = [{ name: 'unitId', queryType: 'exists' }, { name: 'enabled', queryType: 'equalTo', value: true }, { name: 'isHidden', queryType: 'notEqualTo', value: true }];
    const sortBy = { unitId: 'descending' };
    Getters.queryCompanyObjects('Vehicle', undefined, undefined, filter, sortBy, getGlobalVehicleIncludeArr(), undefined, undefined, undefined, true).then(
      vehicles => {
        store.dispatch(fetchGlobalVehiclesSuccess(vehicles));
        resolve(vehicles);
      },
      error => {
        store.dispatch(fetchGlobalVehiclesError(error));
        reject(error);
      }
    );
  });
  return promise;
}

export function getLinkedGlobalVehiclesForState(page, limit, filter, sortBy, ignoreEnabledBool, company) {
  // For CompanyLinks
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingGlobalVehicles());
    if (!company || !company.id) {
      store.dispatch(fetchGlobalVehiclesSuccess());
      return;
    }
    const companyLinkFilter = [
      {
        queryType: 'equalTo',
        name: 'parentCompany',
        value: company,
      }, {
        queryType: 'equalTo',
        name: 'authorized',
        value: true,
      },
    ];
    Getters.queryCompanyObjects('CompanyLink', undefined, undefined, companyLinkFilter, undefined, undefined, false, true).then((companyLinkArr) => {
      let finalFilter = [].concat(filter, { name: 'unitId', queryType: 'exists' });
      finalFilter.push({ name: 'isHidden', queryType: 'notEqualTo', value: true });
      if (!ignoreEnabledBool) finalFilter = finalFilter.concat({ name: 'enabled', queryType: 'equalTo', value: true });
      // Filter for company and child companies
      const vehicleQueryPromises = [];
      for (let i = 0; i < companyLinkArr.length; i++) {
        vehicleQueryPromises.push(Getters.queryCompanyObjects('Vehicle', undefined, undefined, finalFilter.concat({ queryType: 'equalTo', name: 'belongsToCompany', value: companyLinkArr[i].get('childCompany') }), sortBy, getVehicleIncludeArr(), false, true));
      }
      // Uses multiple filters because otherwise it overloads the server..?
      vehicleQueryPromises.push(Getters.queryCompanyObjects('Vehicle', undefined, undefined, finalFilter.concat({ queryType: 'equalTo', name: 'belongsToCompany', value: company }), sortBy, getVehicleIncludeArr(), false, true));
      Promise.all(vehicleQueryPromises).then((vehicleResults) => {
        // Get rid of duplicates
        // const sortedVehicles = vehicles.sort((a, b) => b.get('updatedAt') - a.get('updatedAt'));
        // const filteredVehicles = [];
        // const filteredVehicleIds = [];
        // for (let i = 0; i < sortedVehicles.length; i++) {
        //   if (filteredVehicleIds.indexOf(sortedVehicles[i].get('unitId')) === -1) {
        //     filteredVehicles.push(sortedVehicles[i]);
        //     filteredVehicleIds.push(sortedVehicles[i].get('unitId'));
        //   }
        // }
        // store.dispatch(fetchVehiclesSuccess(filteredVehicles));
        // resolve(filteredVehicles);
        store.dispatch(fetchGlobalVehiclesSuccess([].concat(...vehicleResults)));
        resolve([].concat(...vehicleResults));
      }, error => {
        store.dispatch(fetchGlobalVehiclesError(error));
        reject(error);
      });
    }, error => {
      store.dispatch(fetchGlobalVehiclesError(error));
      reject(error);
    });
  });
  return promise;
}

// export function updateVehiclesVehicleLocationsForState(vehicleArr, sort) {
//   const promise = new Promise((resolve, reject) => {
//     // don't need fetching vehicles to hide loading
//     // store.dispatch(fetchingVehicles());
//     const vehicles = vehicleArr || store.getState().Vehicle.vehicles;
//     Getters.queryCompanyObjects('Vehicle', undefined, undefined, [{ queryType: 'containedIn', name: 'objectId', value: vehicles.map((vehicle) => vehicle.id) }], sort, getVehicleIncludeArr(), false, false).then(
//       (updatedVehicles) => {
//         store.dispatch(fetchVehiclesSuccess(updatedVehicles));
//         resolve(updatedVehicles);
//       },
//       error => reject(error)
//     );
//   });
//   return promise;
// }

export function deleteGlobalVehiclesForState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteVehicles());
    resolve(store.getState().Vehicle);
  });
  return promise;
}

export function getMissingGlobalVehicleInfo() {
  const missingInfo = {};
  const GlobalVehicleState = store.getState().GlobalVehicle;
  if (GlobalVehicleState.fetched) {
    GlobalVehicleState.vehicles.map((parsedVehicleObj) => {
      const key = `${parsedVehicleObj.get('unitId')}-${parsedVehicleObj.id}`;
      if (!missingInfo[key]) {
        missingInfo[key] = {};
      }
      if (!parsedVehicleObj.get('type')) {
        missingInfo[key].type = true;
      }
    });
    Object.keys(missingInfo).map((key) => {
      if (Object.keys(missingInfo[key]).length === 0) {
        delete missingInfo[key];
      }
    });
  }
  return missingInfo;
}

// export function enableGlobalVehicle(vehicleParseObj) {
//   const promise = new Promise((resolve, reject) => {
//     vehicleParseObj.set('enabled', true).save().then(
//       vehicleObj => {
//         resolve(vehicleObj);
//         store.dispatch(enableVehiclesSuccess([vehicleObj]));
//       },
//     );
//   });
//   return promise;
// }

// export function addGlobalVehicleToState(vehicleParseObj) {
//   return store.dispatch(addGlobalVehicleSuccess(vehicleParseObj));
// }

// export function updateGlobalVehicleForState(vehicleParseObj, property, value) {
//   store.dispatch(updatingVehicle([vehicleParseObj]));
//   const promise = new Promise((resolve, reject) => {
//     // user-based attributes
//     const fallbackValue = vehicleParseObj.get(property);
//     vehicleParseObj.set(property, value).save().then(
//       vehicleObject => {
//         // For handling side effects in beforeSave
//         store.dispatch(updateGlobalVehicleSuccess(vehicleObject));
//         resolve(vehicleObject);
//       },
//       error => {
//         console.log(error);
//         vehicleParseObj.set(property, fallbackValue);
//         reject(error);
//         store.dispatch(updateGlobalVehicleError(error));
//       }
//     );
//   });
//   return promise;
// }

// export function vehicleUpdateHandler(vehicleJSON) {
//   const vehicleParseObjArr = store.getState().Vehicle.vehicles;
//   const vehicleIndex = Helpers.findIndexOfObjArr(vehicleParseObjArr, 'id', vehicleJSON.objectId);
//   if (vehicleIndex !== -1) {
//     Getters.getObjectById('Vehicle', vehicleJSON.objectId, getGlobalVehicleIncludeArr()).then((vehicleParseObj) => {
//       const newVehicleParseObjArr = vehicleParseObjArr.slice();
//       newVehicleParseObjArr[vehicleIndex] = vehicleParseObj;
//       store.dispatch(updateGlobalVehicleSuccess(newVehicleParseObjArr, vehicleParseObj));
//     });
//   }
// }

// export function assignDriversToGlobalVehicleForState(vehicleParseObj, driversParseArr) {
//   store.dispatch(updatingVehicle([vehicleParseObj]));
//   const promise = new Promise((resolve, reject) => {
//     Setters.assignDriversToVehicle(vehicleParseObj, driversParseArr).then(
//       vehicleObject => {
//         // For handling side effects in beforeSave
//         Getters.fetchParseObjectArr(store.getState().Vehicle.vehicles).then((vehiclesParseObjArr) => {
//           store.dispatch(updateGlobalVehicleSuccess(vehiclesParseObjArr));
//           resolve(vehicleObject);
//         });
//       },
//       error => {
//         console.log(error);
//         reject(error);
//         store.dispatch(updateGlobalVehicleError(error));
//       }
//     );
//   });
//   return promise;
// }
