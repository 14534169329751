import { findIndexOfObjArr, removeDuplicatesByObjectId } from 'api/Helpers';
import * as ActionConstants from '../actions/ActionConstants';

const initialState = {
  drivers: [],
  fetched: false, // Whether or not drivers are loaded
};

function disableDrivers(stateDrivers, driversToBeDeleted) {
  const newStateDrivers = [].concat(stateDrivers);
  if (newStateDrivers) {
    const driversToBeDeletedLen = driversToBeDeleted.length;
    for (let i = 0; i < driversToBeDeletedLen; i++) {
      const index = findIndexOfObjArr(newStateDrivers, 'id', driversToBeDeleted[i].id);
      newStateDrivers.splice(index, 1);
    }
  }
  return newStateDrivers;
}

export function Driver(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_DRIVERS_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.FETCH_DRIVERS_INPROGRESS,
        fetched: false,
        error: undefined,
      };
    }
    case ActionConstants.FETCH_DRIVERS_SUCCESS: {
      return {
        ...state,
        status: ActionConstants.FETCH_DRIVERS_SUCCESS,
        drivers: removeDuplicatesByObjectId(state.drivers.concat(action.drivers)),
        error: undefined,
        fetched: true,
      };
    }
    case ActionConstants.FETCH_DRIVERS_ERROR: {
      return {
        ...state,
        status: ActionConstants.FETCH_DRIVERS_ERROR,
        error: action.error,
        fetched: false,
      };
    }
    case ActionConstants.DELETE_DRIVERS: {
      return {
        ...initialState,
        status: action.type,
      };
    }
    case ActionConstants.ADD_DRIVER_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.ADD_DRIVER_INPROGRESS,
        error: undefined,
      };
    }
    case ActionConstants.ADD_DRIVER_SUCCESS: {
      // Succesfully added to db
      const updatedDrivers = [].concat(state.drivers);
      updatedDrivers.unshift(action.driver);
      return {
        ...state,
        status: ActionConstants.ADD_DRIVER_SUCCESS,
        drivers: updatedDrivers,
        error: undefined,
      };
    }
    case ActionConstants.ADD_DRIVER_ERROR: {
      return {
        ...state,
        status: ActionConstants.ADD_DRIVER_ERROR,
        error: action.error,
      };
    }
    case ActionConstants.UPDATE_DRIVER_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.UPDATE_DRIVER_INPROGRESS,
        updateReference: action.driver,
        error: undefined,
      };
    }
    case ActionConstants.UPDATE_DRIVER_SUCCESS: {
      return {
        ...state,
        status: ActionConstants.UPDATE_DRIVER_SUCCESS,
        drivers: action.drivers || state.drivers,
        error: undefined,
      };
    }
    case ActionConstants.UPDATE_DRIVER_ERROR: {
      return {
        ...state,
        status: ActionConstants.UPDATE_DRIVER_ERROR,
        error: action.error,
      };
    }
    case ActionConstants.DISABLE_DRIVERS_SUCCESS: {
      return {
        ...state,
        status: ActionConstants.DISABLE_DRIVERS_SUCCESS,
        drivers: disableDrivers(state.drivers, action.drivers),
        error: undefined,
      };
    }
    case ActionConstants.ENABLE_DRIVERS_SUCCESS: {
      const updatedDrivers = [].concat(state.drivers);
      updatedDrivers.unshift(action.drivers);
      return {
        ...state,
        status: ActionConstants.ENABLE_DRIVERS_SUCCESS,
        drivers: updatedDrivers,
        error: undefined,
      };
    }
    default:
      return state;
  }
}
