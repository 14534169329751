import * as ActionConstants from './ActionConstants';
import history from "../sbHistory";
import * as Parse from 'api/Parse';
import store from 'store';

export function fetchingUser() {
  return {
    type: ActionConstants.FETCH_USER_INPROGRESS,
  };
}

export function fetchUserSuccess(user) {
  return {
    type: ActionConstants.FETCH_USER_SUCCESS,
    user,
  };
}

export function fetchUserError(error) {
  return {
    type: ActionConstants.FETCH_USER_ERROR,
    error,
  };
}

export function registerUser() {
  return {
    type: ActionConstants.REGISTER_USER_INPROGRESS,
  };
}

export function registerSuccess(user) {
  return {
    type: ActionConstants.REGISTER_USER_SUCCESS,
    user,
  };
}

export function registerError(error) {
  return {
    type: ActionConstants.REGISTER_USER_ERROR,
    error,
  };
}

export function registerAdmin(registerValuesObject) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(registerUser());
    if (Parse.getCurrentUser()) {
      Parse.logout();
    }
    Parse.registerAdminToParse(registerValuesObject).then(parseUser => {
      Parse.login(registerValuesObject.emailAddress, registerValuesObject.password).then(
        user => {
          resolve(user);
          store.dispatch(registerSuccess(parseUser));
        },
        error => {
          reject(error);
          store.dispatch(registerError(error));
        }
      );
    }, error => {
      reject(error);
      store.dispatch(registerError(error));
    });
  });
  return promise;
}
