import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import ReactMapboxGl, { Feature, GeoJSONLayer, Layer, Source, ZoomControl } from 'react-mapbox-gl';

// API
import * as Mapbox from 'api/Mapbox';
import * as Helpers from 'api/Helpers';
import * as GPS from 'api/GPS/GPS';
import { ELDStatusTypes, ELDStatusTypeDescriptionsCondensed } from 'enums/ELDStatus';
import { getAttribute } from 'api/Parse';

import { getColourShade } from 'sb-csapi/dist/utils/Colours';

// components
import Geofence from './components/Geofence';
import GeofencePopup from './components/GeofencePopup';
import VehiclePopup from '../container/components/VehiclePopup';
import PickupDropoffPopup from './components/PickupDropoffPopup';
import GPSPointPopup from './components/GPSPointPopup';
import FutureMarker from './components/FutureMarker';
import CompletedMarker from './components/CompletedMarker';
import ELDEventMarker from './components/ELDEventMarker';
import ELDEventPopup from './components/ELDEventPopup';
import LinePath from './components/LinePath';
import GPSPoint from './components/GPSPoint';
import GPSPointHistory from './components/GPSPointHistory';
import VehicleMarker from './components/VehicleMarker';
import LocationSearchMarker from './components/LocationSearchMarker';
import LocationSearchPopup from './components/LocationSearchPopup';
import TownshipCanadaLayer from './components/TownshipCanadaLayer';
import TrailersLayer from './components/TrailersLayer';
import VehiclesLayer from './components/VehiclesLayer';

//Icon
import straightenGrayCalm from 'assets/icon/straighten/straighten-grayCalm.svg';
import straightenGreenActive from 'assets/icon/straighten/straighten-greenActive.svg';
import straightenMetalgrey from 'assets/icon/straighten/straighten-metalgrey.svg';
import straightenYellowTruck from 'assets/icon/straighten/straighten-yellowTruck.svg';
import localShippingGrayCalm from 'assets/icon/localShipping/localShippingGrayCalm.svg';
import localShippingMentalGray from 'assets/icon/localShipping/localShippingMentalGray.svg';
import localShippingGreenActive from 'assets/icon/localShipping/localShippingGreenActive.svg';
import localShippingYellowTruck from 'assets/icon/localShipping/localShippingYellowTruck.svg';
import directionVehicle from 'assets/icon/arrow/arrowGreen.svg';
import directionVehicleGrey from 'assets/icon/arrow/arrowGrey.svg';
import directionVehicleYellow from 'assets/icon/arrow/arrowYellow.svg';
import inactiveTrailer from 'assets/icon/straighten/inactiveTrailer.svg';
import inactiveVehicle from 'assets/icon/localShipping/inactiveVehicle.svg';

// CSS
import styles from './VehicleMapView.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

const ReactMap = ReactMapboxGl({
  accessToken: Mapbox.getToken(),
  renderWorldCopies: true,
});



const demoPts = [];
// import Parse from 'parse';
// const latLngPts = [
//   { unitId: 286, latitude: 51.053554, longitude: -116.601650 },
//   { unitId: 376, latitude: 36.447345, longitude: -121.368353 },
//   { unitId: 287, latitude: 36.112856, longitude: -120.570164 },
//   { unitId: 283, latitude: 33.517761, longitude: -113.090434 },
//   { unitId: 198, latitude: 34.929488, longitude: -110.182186 },
//   { unitId: 396, latitude: 32.095161, longitude: -110.994479 },
//   { unitId: 245, latitude: 32.286412, longitude: -109.328833 },
//   { unitId: 491, latitude: 40.277462, longitude: -103.879315 },
//   { unitId: 586, latitude: 42.630746, longitude: -93.536163 },
//   { unitId: 372, latitude: 40.696391, longitude: -87.017033 },
//   { unitId: 257, latitude: 41.598268, longitude: -84.172876 },
//   { unitId: 483, latitude: 48.259764, longitude: -80.273202 },
//   { unitId: 639, latitude: 47.158920, longitude: -71.276837 },
//   { unitId: 543, latitude: 45.545682, longitude: -72.031983 },
//   { unitId: 435, latitude: 44.474331, longitude: -69.729683 },
//   { unitId: 522, latitude: 44.306336, longitude: -79.691130 },
//   { unitId: 416, latitude: 43.588019, longitude: -80.306805 },
// ];
// for (let i = 0; i < latLngPts.length; i++) {
//   const demoVehicleObj = new Parse.Object('Vehicle');
//   const driver = new Parse.Object('Driver');
//   driver.set('eldStatusInt', 3);
//   demoVehicleObj.set('drivers', [driver]);
//   demoVehicleObj.set('unitId', latLngPts[i].unitId);
//   const demoVehicleLocationObj = new Parse.Object('VehicleLocation');
//   const geoPoint = new Parse.GeoPoint({ latitude: latLngPts[i].latitude, longitude: latLngPts[i].longitude });
//   demoVehicleLocationObj.set('location', geoPoint);
//   demoVehicleObj.set('vehicleLocation', demoVehicleLocationObj);
//   demoPts.push(demoVehicleObj);
// }

const getInitialFollowObj = () => ({
  active: false,
  activeObj: null,
  vehicleLocationObjArr: [], // history of vehicleLocationObj (needed 2 for starting the follow, then will append as it goes along)
  currentPoint: [],
  pathPointArr: [],
  startPoint: null,
  endPoint: null,
  waypointIter: null,
  msPerWaypoint: null,
  timestampStart: null,
  sourceId: null,
  layerId: null
});
/**
 * @param {array} startLngLat Path origin longitude, latitude array
 * @param {array} endLngLat Path destination longitude, latitude array
 * @param {number} percentageComplete Linear displacement along path from origin, Range: [0, 100]
 * @returns {array} Longitude, latitude array that is percentageComplete along straight line path
 */
function pointAlongPath(startLngLat, endLngLat, percentageComplete) {
  const lng = (startLngLat[0]) + ((endLngLat[0] - startLngLat[0]) * (percentageComplete / 100));
  const lat = (startLngLat[1]) + ((endLngLat[1] - startLngLat[1]) * (percentageComplete / 100));
  return {
    type: 'Point',
    coordinates: [lng, lat],
  };
}

function hasValidVehicleLocation(object) {
  return (
    object.get('vehicleLocation') &&
    object.get('vehicleLocation').get('location')
  );
}

function isActiveObjectELDEnabled(activeObject) {
  return (activeObject && activeObject.get('drivers') && activeObject.get('drivers')[0] && activeObject.get('drivers')[0]);
}

function isActiveObjectDriving(activeObject) {
  if (activeObject && activeObject.get('drivers') && activeObject.get('drivers')[0] && activeObject.get('drivers')[0]) {
    return (activeObject.get('drivers')[0].get('eldStatusInt') === ELDStatusTypes.DRIVING);
  }
  return false;
}

//description: this function is in order to convert raw svg icon into image source which will allow geoJson able to read it
//imageId is String, which can be named as whatever you want
//svg is the raw svg file
function generateSvgImage(imageId, svg) {
  let image = new Image(20, 20);
  image.src = svg;
  return [imageId, image];
}

class VehicleMapView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      movingMethod: 'easeTo',
      follow: getInitialFollowObj(),
      zoomChanged: false,
      isMapLoaded: false,
      lastFollowUnixTimestamp: undefined,
    };

    this.map = React.createRef(null);

    this.initializeFollow = this.initializeFollow.bind(this);
    this.animateActiveObjFromStartPointToEndPoint = this.animateActiveObjFromStartPointToEndPoint.bind(this);
    this.activeObjectHasValidLocation = this.activeObjectHasValidLocation.bind(this);
    this.isActiveObj = this.isActiveObj.bind(this);
    this.activeObjectIsActive = this.activeObjectIsActive.bind(this);
    this.setFollow = this.setFollow.bind(this);
    this.refreshMapCanvasSize = this.refreshMapCanvasSize.bind(this);
  }

  componentDidMount() {
    // Let's initialize all the icons once, and place it in the state
    // trailer icon
    const straightenGrayCalmIcon = generateSvgImage('straightenGrayCalmIcon', straightenGrayCalm);
    const straightenGreenActiveIcon = generateSvgImage('straightenGreenActiveIcon', straightenGreenActive);
    const straightenMetalgreyIcon = generateSvgImage('straightenMetalgreyIcon', straightenMetalgrey);
    const straightenYellowTruckIcon = generateSvgImage('straightenYellowTruckIcon', straightenYellowTruck);
    const inactiveTrailerIcon = generateSvgImage('inactiveTrailerIcon', inactiveTrailer);

    // truck icon
    const localShippingMentalGrayIcon = generateSvgImage('localShippingMentalGrayIcon', localShippingMentalGray);
    const localShippingGreenActiveIcon = generateSvgImage('localShippingGreenActiveIcon', localShippingGreenActive);
    const localShippingYellowTruckIcon = generateSvgImage('localShippingYellowTruckIcon', localShippingYellowTruck);
    const inactiveVehicleIcon = generateSvgImage('inactiveVehicleIcon', inactiveVehicle);

    // directionIcon
    const directionVehicleGreenIcon = generateSvgImage('directionVehicleGreenIcon', directionVehicle);
    const directionVehicleGreyIcon = generateSvgImage('directionVehicleGreyIcon', directionVehicleGrey);
    const directionVehicleYellowIcon = generateSvgImage('directionVehicleYellowIcon', directionVehicleYellow);

    this.setState({
      ...this.state,
      straightenGrayCalmIcon,
      straightenGreenActiveIcon,
      straightenMetalgreyIcon,
      straightenYellowTruckIcon,
      inactiveVehicleIcon,
      localShippingMentalGrayIcon,
      localShippingGreenActiveIcon,
      localShippingYellowTruckIcon,
      directionVehicleGreenIcon,
      directionVehicleGreyIcon,
      directionVehicleYellowIcon,
      inactiveTrailerIcon,
    })

    const requestAnimationFallback = (callback) => {
      setTimeout(callback, 1000 / 60);
    };
    window.requestAnimationFrame = window.requestAnimationFrame
      || window.mozRequestAnimationFrame
      || window.webkitRequestAnimationFrame
      || window.msRequestAnimationFrame
      || requestAnimationFallback;

    window.addEventListener("resize", this.refreshMapCanvasSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.refreshMapCanvasSize);
  }

  async refreshMapCanvasSize(isSidebarOpen) {
    // Resize the canvas size whenever the sidebar is open/closed
    const rootElement = document.getElementById('root');
    const mapElement = document.getElementsByClassName("mapboxgl-map") && document.getElementsByClassName("mapboxgl-map")[0];

    if (!rootElement || !mapElement) return;

    if (isSidebarOpen && window.innerWidth >= 1366) {
      // Only adjust the width if the sidebar is not an overlay
      mapElement.style.width = `${rootElement.offsetWidth - 350}px`;
    } else {
      mapElement.style.width = `${rootElement.offsetWidth}px`;
    }

    await this.map?.current?.resize();
  }

  async componentWillReceiveProps(nextProps) {
    /***
     TO FOLLOW VEHICLE: -- look at BasicVehicleMap for updated implementatino
     companyModule must not disableMapProjection
     activeObject must be selected
     activeObject can only be vehicle or trailer
     last updatedAt location for activeobject must be within last 8 minutes
     zoom must be > 13
    ***/
    // console.log(
    //   this.props.zoom && this.props.zoom[0] >= 4,
    //   nextProps.activeObject,
    //   nextProps.activeObject.object,
    //   nextProps.Company && nextProps.Company.company && (!nextProps.Company.company.get('companyModule') || !nextProps.Company.company.get('companyModule').get('disableMapProjection')),
    //   (nextProps.activeObject.type === 'Vehicle' || nextProps.activeObject.type === 'Trailer'),
    //   nextProps.activeObject.object.get('vehicleLocation') && nextProps.activeObject.object.get('vehicleLocation').get('speedKm'),
    //   !(!nextProps.showGPSHistory && this.props.showGPSHistory), // avoids weird issue where if looking at a previous day's vehicle history, will jump to the end of those vehicleHistories when toggling GPSHisotry
    //   !this.state.follow.active,
    //   nextProps.VehicleLocation.vehicleLocations && nextProps.VehicleLocation.vehicleLocations.length > 0,
    //   nextProps.activeObject.object.get('vehicleLocation') && nextProps.activeObject.object.get('vehicleLocation').get('dateTime') && moment(nextProps.activeObject.object.get('vehicleLocation').get('dateTime')).isAfter(moment().subtract(8, 'minutes')),
    //   (!isActiveObjectELDEnabled(nextProps.activeObject.object) || isActiveObjectDriving(nextProps.activeObject.object))
    // );
    if (this.map.current && (nextProps.isSidebarOpen !== this.props.isSidebarOpen)) {
      // Handles the map resizing when the sidebar is open/closed
      this.refreshMapCanvasSize(nextProps.isSidebarOpen);
    }

    if (nextProps.showGPSHistory) {
      // If showing GPS History, don't follow.
      await this.setState({ ...this.state, follow: getInitialFollowObj() });
    } else if (nextProps.activeObject.object !== this.props.activeObject.object) {
      // If changed active vehicle, don't follow.
      await this.setState({ ...this.state, follow: getInitialFollowObj() });
    } else if (
      nextProps.activeObject &&
      nextProps.activeObject.object &&
      nextProps.Company && nextProps.Company.company && (!nextProps.Company.company.get('companyModule') || !nextProps.Company.company.get('companyModule').get('disableMapProjection')) &&
      (nextProps.activeObject.type === 'Vehicle' || nextProps.activeObject.type === 'Trailer') &&
      nextProps.activeObject.object.get('vehicleLocation') && nextProps.activeObject.object.get('vehicleLocation').get('speedKm') && // Make sure that speedKm is defined and > 0
      !(!nextProps.showGPSHistory && this.props.showGPSHistory) && // avoids weird issue where if looking at a previous day's vehicle history, will jump to the end of those vehicleHistories when toggling GPSHisotry
      nextProps.VehicleLocation.vehicleLocations && nextProps.VehicleLocation.vehicleLocations.length > 0 && // must have vehicleLocations to project follow
      nextProps.activeObject.object.get('vehicleLocation') && nextProps.activeObject.object.get('vehicleLocation').get('dateTime') && moment(nextProps.activeObject.object.get('vehicleLocation').get('dateTime')).isAfter(moment().subtract(8, 'minutes')) && // If location of vehicle was updated within 8 minutes from now
      (!isActiveObjectELDEnabled(nextProps.activeObject.object) || isActiveObjectDriving(nextProps.activeObject.object))
    ) {
      if (
        !this.state.follow.active &&
        this.props.zoom && this.props.zoom[0] >= 4
      ) {
        // Starting the following if meeitng
        const vehicleLocationArr = nextProps.VehicleLocation.vehicleLocations;
        this.initializeFollow(nextProps.activeObject.object, vehicleLocationArr[vehicleLocationArr.length - 2], vehicleLocationArr[vehicleLocationArr.length - 1]);
      } else if (
        this.state.follow.active &&
        nextProps.VehicleLocation.vehicleLocations && nextProps.VehicleLocation.vehicleLocations.length > 0 && this.state.follow.vehicleLocationObjArr.length > 0 && // Make sure the vehicles is already being followed
        nextProps.VehicleLocation.vehicleLocations[nextProps.VehicleLocation.vehicleLocations.length - 1].id !== this.state.follow.vehicleLocationObjArr[this.state.follow.vehicleLocationObjArr.length - 1].id // Make sure that vehicleLocation was already updated
      ) {
        // FOR HANDLING - Vehicle is already being followed, but vehicleLocation is updated for activeObject
        const vehicleLocationArr = nextProps.VehicleLocation.vehicleLocations;
        await this.setState({
          ...this.state,
          follow: {
            ...this.state.follow,
            vehicleLocationObjArr: this.state.follow.vehicleLocationObjArr.concat(nextProps.VehicleLocation.vehicleLocations[nextProps.VehicleLocation.vehicleLocations.length - 1])
          }
        });
        this.initializeFollow(nextProps.activeObject.object, vehicleLocationArr[vehicleLocationArr.length - 2], vehicleLocationArr[vehicleLocationArr.length - 1]);
      }
    } else {
      await this.setState({ ...this.state, follow: getInitialFollowObj() });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // if (
    //   (nextProps.activeObject !== this.props.activeObject) ||
    //   (nextProps.addEditGeofence !== this.props.addEditGeofence) ||
    //   (nextProps.Company !== this.props.Company) ||
    //   (nextProps.geofence !== this.props.geofence) ||
    //   (nextProps.geofences !== this.props.geofences) ||
    //   (nextProps.highlightedObjects !== this.props.highlightedObjects) ||
    //   (nextProps.mapLoaded !== this.props.mapLoaded) ||
    //   (nextProps.initialFit !== this.props.initialFit) ||
    //   (nextProps.vehicles !== this.props.vehicles) ||
    //   (nextProps.fitToArr !== this.props.fitToArr) ||
    //   (nextProps.center !== this.props.center) ||
    //   (nextProps.locationSearch !== this.props.locationSearch) ||
    //   (nextProps.token !== this.props.token) ||
    //   (nextProps.popup !== this.props.popup) ||
    //   (nextProps.gpsPointPopup !== this.props.gpsPointPopup) ||
    //   (nextProps.activeVehicleHistory !== this.props.activeVehicleHistory) ||
    //   (nextProps.activeMapStyle !== this.props.activeMapStyle) ||
    //   (nextProps.displayMarkers !== this.props.displayMarkers) ||
    //   (nextProps.showLSD !== this.props.showLSD)
    // ) {
    //   console.log(
    //     (nextProps.activeObject !== this.props.activeObject),
    //     (nextProps.addEditGeofence !== this.props.addEditGeofence),
    //     (nextProps.Company !== this.props.Company),
    //     (nextProps.geofence !== this.props.geofence),
    //     (nextProps.geofences !== this.props.geofences),
    //     (nextProps.highlightedObjects !== this.props.highlightedObjects),
    //     (nextProps.mapLoaded !== this.props.mapLoaded),
    //     (nextProps.initialFit !== this.props.initialFit),
    //     (nextProps.vehicles !== this.props.vehicles),
    //     (nextProps.fitToArr !== this.props.fitToArr),
    //     (nextProps.center !== this.props.center),
    //     (nextProps.locationSearch !== this.props.locationSearch),
    //     (nextProps.token !== this.props.token),
    //     (nextProps.popup !== this.props.popup),
    //     (nextProps.gpsPointPopup !== this.props.gpsPointPopup),
    //     (nextProps.activeVehicleHistory !== this.props.activeVehicleHistory),
    //     (nextProps.activeMapStyle !== this.props.activeMapStyle),
    //     (nextProps.displayMarkers !== this.props.displayMarkers),
    //     (nextProps.showLSD !== this.props.showLSD),
    //   );
    // }
    // if (nextState.follow.startPoint !== this.state.follow.startPoint) return true;
    if (nextState.follow.currentPoint !== this.state.follow.currentPoint) return true;
    if (nextProps.activeObject !== this.props.activeObject) return true;
    if (nextProps.addEditGeofence !== this.props.addEditGeofence) return true;
    if (nextProps.Company !== this.props.Company) return true;
    if (nextProps.geofence !== this.props.geofence) return true;
    if (nextProps.geofences !== this.props.geofences) return true;
    if (nextProps.highlightedObjects !== this.props.highlightedObjects) return true;
    if (nextProps.mapLoaded !== this.props.mapLoaded) return true;
    if (nextProps.initialFit !== this.props.initialFit) return true;
    if (nextProps.vehicles !== this.props.vehicles) return true;
    if (nextProps.fitToArr !== this.props.fitToArr) return true;
    if (nextProps.center !== this.props.center) return true;
    if (nextProps.locationSearch !== this.props.locationSearch) return true;
    if (nextProps.token !== this.props.token) return true;
    if (nextProps.popup !== this.props.popup) return true;
    if (nextProps.gpsPointPopup !== this.props.gpsPointPopup) return true;
    if (nextProps.activeVehicleHistory !== this.props.activeVehicleHistory) return true;
    if (nextProps.activeMapStyle !== this.props.activeMapStyle) return true;
    if (nextProps.displayMarkers !== this.props.displayMarkers) return true;
    if (nextProps.showLSD !== this.props.showLSD) return true;
    return false;
  }

  getMapStyle() {
    if (this.props.showGPSHistory && this.props.useAlternateGPSMapStyle) {
      return 'mapbox://styles/mapbox/light-v11';
    }
    if (this.props.activeMapStyle === 'street') {
      return 'mapbox://styles/mapbox/navigation-day-v1';
      // return 'mapbox://styles/mapbox/traffic-day-v2';
    } else if (this.props.activeMapStyle === 'streetNight') {
      return 'mapbox://styles/mapbox/streets-v11';
      // return 'mapbox://styles/mapbox/traffic-night-v2';
    } else if (this.props.activeMapStyle === 'satellite') {
      return 'mapbox://styles/mapbox/satellite-streets-v12';
      // return 'mapbox://styles/mapbox/satellite-streets-v10';
    }
    return 'mapbox://styles/mapbox/traffic-day-v2';
  }

  isHighlightedMarker(parseObj) {
    if (Helpers.findIndexOfObjArr(this.props.highlightedObjects, 'id', parseObj.id) !== -1 || (this.props.activeObject && this.props.activeObject.object && this.props.activeObject.object.id === parseObj.id)) {
      return true;
    }
    return false;
  }



  async initializeFollow(activeObj, startVehicleLocationObj, endVehicleLocationObj) {
    if (!startVehicleLocationObj || !endVehicleLocationObj) {
      return;
    }
    const roadRoute = await Mapbox.getRoadRoute(Helpers.convertParseVehicleLocationArrToPointArr([startVehicleLocationObj, endVehicleLocationObj]), true);
    // const msBetweenUpdates = 330000; // 5.5 minutes between updates (will sure that it's always moving..?)
    const msBetweenUpdates = 210000; // 3.5 minutes between updates (more accurate, but more vehicles won't project)
    // This section is to calculate how much of the path the activeObject has completed between the lastVehicleLocation and the one before it.
    // Projection will take this into account so if it's been a while between the last ping, then will be further along in the path etc.
    let msCompleted = Math.min(moment().diff(moment(endVehicleLocationObj.get('dateTime'))), msBetweenUpdates);
    // If it's been a while (beyond msBetweenUpdates length) since the last update, then will project from 50% completion.
    if (msCompleted === msBetweenUpdates) msCompleted = 0.5 * msBetweenUpdates;
    const msRemaining = msBetweenUpdates - msCompleted;
    const fractionOfPathProjectedComplete = msCompleted / msBetweenUpdates;

    let spliceToIndex = Math.floor(roadRoute.length * fractionOfPathProjectedComplete);
    // start 75%
    if (spliceToIndex < roadRoute.length * 0.5) spliceToIndex = Math.ceil(roadRoute.length * 0.5);

    const cutRoadRoute = roadRoute.splice(spliceToIndex);
    const waypointLngLatArr = cutRoadRoute.map((waypoint) => [waypoint.longitude, waypoint.latitude]);
    if (waypointLngLatArr && waypointLngLatArr.length > 2) {
      const msPerWaypoint = msRemaining / waypointLngLatArr.length;
      await this.setFollow({
        active: true,
        activeObj,
        waypointLngLatArr,
        vehicleLocationObjArr: [startVehicleLocationObj, endVehicleLocationObj],
        startPoint: waypointLngLatArr[0],
        currentPoint: waypointLngLatArr[0],
        endPoint: waypointLngLatArr[1],
        bearing: GPS.getBearing(waypointLngLatArr[0][1], waypointLngLatArr[0][0], waypointLngLatArr[2][1], waypointLngLatArr[2][0]),
        waypointIter: 1,
        msPerWaypoint,
        timestampStart: null,
        center: waypointLngLatArr[0],
      });
      window.requestAnimationFrame(this.animateActiveObjFromStartPointToEndPoint);
    }
  }

  async animateActiveObjFromStartPointToEndPoint(timestamp) {
    // Using timestamp to iterate position of activeObject (coordinates of currentPoint) from waypoint to waypoints
    const { state } = this;
    if (state.follow.active) {
      const { waypointIter, msPerWaypoint, startPoint, endPoint, waypointLngLatArr, timestampStart } = state.follow;
      // If timestamp is undefined fallback to less precise Date ms, ok because we care about time difference only
      timestamp = timestamp || (Date.now());
      // If timestampStart not set we are at the beginning of waypoint
      let timestampDiff = 0;
      if (timestampStart) {
        timestampDiff = timestamp - timestampStart;
      } else {
        // If timestampStart not set, set to timestamp
        state.follow.timestampStart = timestamp;
      }
      if (timestampDiff > msPerWaypoint) {
        if (waypointIter + 2 < waypointLngLatArr.length - 1 && waypointLngLatArr && waypointLngLatArr.length > 0) {
          // Completed going to the waypoint, update new one.
          const updatedBearing = GPS.getBearing(waypointLngLatArr[waypointIter + 1][1], waypointLngLatArr[waypointIter + 1][0], waypointLngLatArr[waypointIter + 3][1], waypointLngLatArr[waypointIter + 3][0]) || state.follow.bearing;
          await this.setFollow({
            ...state.follow,
            startPoint: waypointLngLatArr[waypointIter + 1],
            currentPoint: waypointLngLatArr[waypointIter + 1],
            endPoint: waypointLngLatArr[waypointIter + 2],
            bearing: updatedBearing,
            waypointIter: waypointIter + 1,
            timestampStart: timestamp,
          });
          window.requestAnimationFrame(this.animateActiveObjFromStartPointToEndPoint);
        }
      } else {
        // Project between wayPoints
        if (waypointIter < waypointLngLatArr.length - 1 && startPoint && endPoint) {
          const currentPointSource = pointAlongPath(startPoint, endPoint, (timestampDiff / msPerWaypoint) * 100);
          const initializeTimestamp = {};
          if (!timestampStart) initializeTimestamp.timestampStart = timestamp;
          // const updatedBearing = GPS.getBearing(currentPointSource.coordinates[1], currentPointSource.coordinates[0], state.follow.endPoint[1], state.follow.endPoint[0]) || state.follow.bearing;
          await this.setFollow({
            ...state.follow,
            ...initializeTimestamp,
            currentPoint: currentPointSource.coordinates,
            /* bearing: updatedBearing, */
          });
        }
        window.requestAnimationFrame(this.animateActiveObjFromStartPointToEndPoint);
      }
    } else {
      await this.setFollow();
    }
  }

  activeObjectHasValidLocation() {
    return (
      this.props.activeObject &&
      this.props.activeObject.object &&
      hasValidVehicleLocation(this.props.activeObject.object)
    );
  }

  isActiveObj(objectId) {
    return (
      this.props.activeObject &&
      this.props.activeObject.object &&
      this.props.activeObject.object.id === objectId
    );
  }

  activeObjectIsActive() {
    return (
      this.props.activeObject &&
      this.props.activeObject.object
    );
  }

  async setFollow(followObj) {
    const { setActiveFollowObject } = this.props;
    let newFollowObj;
    if (followObj) {
      newFollowObj = { ...this.state.follow, ...followObj };
    } else {
      newFollowObj = getInitialFollowObj();
    }

    return new Promise(resolve => {
      const currentUnixTimestamp = moment().valueOf();

      // Only set the state after a given amount of time when following a vehicle - to reduce amount of re-renders caused by setState
      if (!this.state.lastFollowUnixTimestamp || (currentUnixTimestamp - this.state.lastFollowUnixTimestamp) > 125) {
        this.setState({ ...this.state, follow: newFollowObj, lastFollowUnixTimestamp: currentUnixTimestamp }, () => {
          setActiveFollowObject(newFollowObj);
          resolve();
        });
      }

      resolve();
    });
  }

  render() {
    const { props, state } = this;

    let trailerPerUnitId = {};
    let vehiclePerUnitId = {};

    //trailer
    this.props.displayMarkers.trailers && this.props.trailers !== undefined && this.props.trailers.length > 0 && this.props.trailers.filter((trailer) => trailer.get('vehicleLocation')).sort((a, b) => a.get('vehicleLocation').get('speedKm') > b.get('vehicleLocation').get('speedKm')).map((trailer, trailerIndex) => {
      // since trailers don't have vehiclelocation, we need to simulate
      const unitId = getAttribute(trailer, 'unitId', true);
      const vehicleLocation = getAttribute(trailer, 'vehicleLocation', true);
      const longitude = getAttribute(vehicleLocation, 'longitude', true);
      const latitude = getAttribute(vehicleLocation, 'latitude', true);
      const coordinates = state.follow.active ? state.follow.currentPoint : [longitude, latitude];
      const trailerObject = {};
      const tc_devices = trailer.get('tc_devices');
      if (
        tc_devices &&
        (tc_devices.get('tc_positions') || tc_devices.get('assetLocation'))
      ) {
        if (this.isActiveObj(trailer.id)) {

          trailerObject['coordinate'] = coordinates;
          trailerObject['isActive'] = true;
          trailerObject['trailer'] = trailer;
          trailerPerUnitId[unitId] = trailerObject;

        } else if (!this.activeObjectHasValidLocation() && hasValidVehicleLocation(trailer) && !this.activeObjectIsActive()) {
          trailerObject['coordinate'] = coordinates;
          trailerObject['isActive'] = false;
          trailerObject['trailer'] = trailer;
          trailerPerUnitId[unitId] = trailerObject;
        }
      }
      return null;
    })


    //truck
    this.props.displayMarkers.vehicles && this.props.vehicles !== undefined && this.props.vehicles.length > 0 &&
      [].concat(...this.props.vehicles, ...demoPts).filter((vehicle) => vehicle.get('vehicleLocation')).sort((a, b) => a.get('vehicleLocation').get('speedKm') > b.get('vehicleLocation').get('speedKm')).map((vehicle, vehicleIndex) => {
        const unitId = getAttribute(vehicle, 'unitId', true);
        const vehicleLocation = getAttribute(vehicle, 'vehicleLocation', true);
        const location = vehicleLocation ? getAttribute(vehicleLocation, 'location', true) : undefined;
        // const longitude = getAttribute(location, 'longitude', true);
        // const latitude = getAttribute(location, 'latitude', true);
        let coordinates = [undefined, undefined];
        if (location) {
          coordinates = state.follow.active ? state.follow.currentPoint : [location.longitude, location.latitude];
        }
        const eldEvent = vehicle && vehicleLocation && getAttribute(vehicleLocation, 'eldEvent', true);
        const vehicleObject = {};
        if (this.isActiveObj(vehicle.id) && hasValidVehicleLocation(vehicle)) {
          vehicleObject['coordinate'] = coordinates;
          vehicleObject['isActive'] = true;
          vehicleObject['vehicle'] = vehicle;
          vehicleObject['eldEvent'] = eldEvent;
          vehiclePerUnitId[unitId] = vehicleObject;

        } else if (!this.activeObjectHasValidLocation() && hasValidVehicleLocation(vehicle)) {
          vehicleObject['coordinate'] = coordinates;
          vehicleObject['isActive'] = false;
          vehicleObject['vehicle'] = vehicle;
          vehicleObject['eldEvent'] = eldEvent;
          vehiclePerUnitId[unitId] = vehicleObject;
        }
        return null;
      })

    // Try to force the layers to their correct order
    if (this.map?.current) {
      try {
        const layers = this.map.current.getStyle()?.layers;

        const gpsPointsLayers = layers.filter((layer) => layer.id.startsWith('gps-points'));
        const gpsPathLayers = layers.filter((layer) => layer.id.startsWith('gps-path'));
        const gpsSubTripPathLayers = layers.filter((layer) => layer.id.startsWith('gps-sub-trip-path'));

        // The order should be: gps-path -> gps-sub-trip-path -> gps-points
        gpsPointsLayers.length > 0 && gpsPathLayers.forEach((layer, index) => {
          this.map.current.moveLayer(layer.id, 'gps-points');
        });

        gpsPointsLayers.length > 0 && gpsSubTripPathLayers.forEach((layer, index) => {
          this.map.current.moveLayer(layer.id, 'gps-points');
        });
      } catch (e) {
        console.log(e);
      }
    }

    return (
      <ReactMap
        maxZoom={18}
        style={this.getMapStyle()}
        movingMethod={this.state.movingMethod}
        fitBounds={(this.props.fitToArr && this.props.fitToArr.length > 0) && this.props.fitToArr}
        fitBoundsOptions={{ padding: this.map?.current?.painter?.height > 600 && props.showGPSHistory ? 300 : 200, linear: true }}
        onStyleLoad={(mapObject) => {
          this.map.current = mapObject;
          this.props.setMapLoaded(mapObject);
          mapObject.on('style.load', () => {
            this.props.setMapLoaded(mapObject)
          });
          this.refreshMapCanvasSize(this.props.isSidebarOpen);
        }}
        zoom={this.props.zoom}
        center={(this.props.center && this.props.center[0] && this.props.center[1]) && this.props.center}
        containerStyle={{ height: '100%', width: '100%' }}
        onClick={(map, event) => {
          this.props.handleMapClick(event.lngLat.lat, event.lngLat.lng);
        }}
        onZoomEnd={(map) => this.props.updateZoom(map.getZoom())}
      >
        {this.props.showLSD && <TownshipCanadaLayer />}

        <ZoomControl className={`${styles.zoomControl} d-none d-lg-flex`} />

        {this.props.locationSearch.geometry && !this.props.addEditGeofence.type &&
          <LocationSearchMarker
            coordinates={[this.props.locationSearch.geometry.location.lng(), this.props.locationSearch.geometry.location.lat()]}
            handleOnClick={(lat, lng) => {
              if (lat && lng) {
                this.props.handleMapClick(lat, lng);
              } else {
                this.props.setPopup({ locationSearch: this.props.locationSearch, type: 'locationSearch' });
              }
            }}
          />
        }

        {this.props.mapLoaded && this.props.geofences &&
          this.props.geofences.map((geofence) => {
            if (!this.props.addEditGeofence.geofence || this.props.addEditGeofence.geofence.id !== geofence.id) {
              return (
                <Geofence
                  active={this.isHighlightedMarker(geofence)}
                  key={geofence.id}
                  geofenceParseObj={geofence}
                  handleClick={(e) => this.props.setPopup({ geofence, type: 'geofence', coordinates: [e.lngLat.lng, e.lngLat.lat] })}
                />
              );
            }
            return null;
          })
        }

        {!this.props.addEditGeofence.type && this.props.mapLoaded &&
          <div>
            {/* Displays the GPS path for the selected trip date */}
            {this.props?.activeVehicleHistory?.legs?.length > 0 && this.props?.showGPSHistory &&
              // Selected Vehicle & history
              this.props.activeVehicleHistory.legs.map((legObject, index) => {
                const vehicleLocationArr = (!legObject.connector && legObject.vehicleLocationPointsArr
                  .filter((vehicleLocationObj, gpsPointIndex) => (
                    (!this.state.follow.active || this.state.follow.active && gpsPointIndex !== legObject.vehicleLocationPointsArr.length - 1) && // ignore most recent GPS point
                    (vehicleLocationObj && vehicleLocationObj.get('location'))
                  ))
                );

                return (
                  <div key={index}>
                    {legObject.waypoints && legObject.waypoints.length > 0 && this.props.activeVehicleHistory.showPath && (
                      // History Line
                      <GeoJSONLayer
                        id="gps-path"
                        sourceOptions={{ lineMetrics: true }}
                        data={{
                          type: "Feature",
                          geometry: {
                            type: "LineString",
                            coordinates: Helpers.convertLongitudeLatitudeObjArrToPointArr(legObject.waypoints) || []
                          },
                        }}
                        lineLayout={{
                          "line-cap": "round",
                          "line-join": "round",
                        }}
                        linePaint={{
                          'line-width': 5,
                          "line-gradient": [
                            'interpolate',
                            ['linear'],
                            ['line-progress'],
                            0,
                            getColourShade("#00A3A3", -100),
                            1,
                            getColourShade("#00A3A3", 70),
                          ]
                        }}
                      />
                    )}

                    <GPSPointHistory
                      directionVehicleYellowIcon={this.state.directionVehicleYellowIcon}
                      directionVehicleGreenIcon={this.state.directionVehicleGreenIcon}
                      vehicleLocationArr={vehicleLocationArr}
                      setGPSPointPopup={this.props.setGPSPointPopup}
                      colour={legObject.colour}
                      mapObject={this.map?.current}
                      isGPSReplay={this.props.replay.loading}
                    />
                  </div>
                )
              })
            }

            {this.props.activeVehicleHistory.legs && this.props.activeVehicleHistory.legs.length > 0 && !this.props.replay.loading &&
              // Selected Vehicle & history
              this.props.activeVehicleHistory.legs.map((legObject, index) => (
                <div key={index}>
                  {legObject.importantELDEvents && legObject.importantELDEvents.length > 0 &&
                    legObject.importantELDEvents.filter((eldEventObj) => (eldEventObj && eldEventObj.eldEvent && !(eldEventObj.eldEvent.get('eldEventTypeCodeInt') === 13))).map((eventObject, eldEventIndex) => (
                      // Duty Changes, EXCEPT DRIVING
                      <ELDEventMarker
                        key={`eldEvent${eldEventIndex}`}
                        eldEvent={eventObject.eldEvent}
                        coordinates={Helpers.convertParseVehicleLocationArrToPointArr([eventObject.vehicleLocation], true)}
                        handleOnClick={() => this.props.setPopup({ vehicleLocation: eventObject.vehicleLocation, eldEvent: eventObject.eldEvent, type: 'eldEvent' })}
                      />
                    ))
                  }
                </div>
              ))
            }

            {/* Each <Layer /> render appends that layer to the mapbox layer array which operates as a z-index stack with the last element being the top-most layer */}
            {this.props.displayMarkers.trailers && this.props.mapLoaded && this.props.trailers && this.props.trailers.length > 0 && !props.showGPSHistory &&
              <TrailersLayer
                straightenGrayCalmIcon={this.state.straightenGrayCalmIcon}
                straightenGreenActiveIcon={this.state.straightenGreenActiveIcon}
                straightenMetalgreyIcon={this.state.straightenMetalgreyIcon}
                straightenYellowTruckIcon={this.state.straightenYellowTruckIcon}
                directionVehicleGreenIcon={this.state.directionVehicleGreenIcon}
                directionVehicleGreyIcon={this.state.directionVehicleGreyIcon}
                directionVehicleYellowIcon={this.state.directionVehicleYellowIcon}
                inactiveTrailerIcon={this.state.inactiveTrailerIcon}
                inactiveVehicleIcon={this.state.inactiveVehicleIcon}
                trailerPerUnitId={trailerPerUnitId}
                displayMarkers={props.displayMarkers}
                trailers={props.trailers}
                company={props.Company}
                isFollowActive={state.follow.active}
                followBearing={state.follow.bearing}
              />
            }

            <VehiclesLayer
              inactiveVehicleIcon={this.state.inactiveVehicleIcon}
              localShippingGreenActiveIcon={this.state.localShippingGreenActiveIcon}
              localShippingYellowTruckIcon={this.state.localShippingYellowTruckIcon}
              directionVehicleGreenIcon={this.state.directionVehicleGreenIcon}
              directionVehicleGreyIcon={this.state.directionVehicleGreyIcon}
              directionVehicleYellowIcon={this.state.directionVehicleYellowIcon}
              inactiveTrailerIcon={this.state.inactiveTrailerIcon}
              vehiclePerUnitId={vehiclePerUnitId}
              displayMarkers={props.displayMarkers}
              vehicles={props.vehicles}
              company={props.Company}
              // handleOnClick={(vehicle) => this.props.selectVehicle('Vehicle', vehicle)}
              isFollowActive={state.follow.active}
              followBearing={state.follow.bearing}
              visible={this.props.displayMarkers.vehicles && this.props.mapLoaded && this.props.vehicles && this.props.vehicles.length > 0 && !props.showGPSHistory}
            />

            {this.activeObjectHasValidLocation() && !props.replay.loading && !props.showGPSHistory &&
              <div>
                <VehiclePopup
                  address={props.activeObject.addressString}
                  coordinates={
                    state.follow.active ?
                      state.follow.currentPoint :
                      Helpers.convertParseVehicleLocationArrToPointArr([props.activeObject.object.get('vehicleLocation')], true)
                  }
                  vehicle={props.activeObject.object}
                  hidePopup={props.hidePopup}
                  followBearing={state.follow.bearing}
                  unselectDriver={props.unselectDriver}
                  isMobile={props.isMobile}
                />
              </div>
            }

            {this.props.popup && this.props.popup.type === 'jobAction' && !this.props.replay.loading &&
              <PickupDropoffPopup
                coordinates={Helpers.convertParseVehicleLocationArrToPointArr([this.props.popup.vehicleLocation], true)}
                vehicleLocation={this.props.popup.vehicleLocation}
              />
            }

            {this.props.popup && this.props.popup.type === 'eldEvent' && !this.props.replay.loading &&
              <ELDEventPopup
                coordinates={Helpers.convertParseVehicleLocationArrToPointArr([this.props.popup.vehicleLocation], true)}
                eldEvent={this.props.popup.eldEvent}
              />
            }

            {this.props.gpsPointPopup && this.props.gpsPointPopup.vehicleLocation &&
              <GPSPointPopup
                coordinates={Helpers.convertParseVehicleLocationArrToPointArr([this.props.gpsPointPopup.vehicleLocation], true)}
                addressString={this.props.gpsPointPopup.addressString}
                vehicleLocation={this.props.gpsPointPopup.vehicleLocation}
                setGPSPointPopup={this.props.setGPSPointPopup}
              />
            }

            {this.props.activeVehicleHistory && this.props.activeVehicleHistory.showTripStops && !this.props.replay.loading &&
              <div>
                {this.props.activeVehicleHistory.tripArr && this.props.activeVehicleHistory.tripArr.length > 0 &&
                  this.props.activeVehicleHistory.tripArr.map((tripObj, index) => {
                    if (index === 0 && tripObj.start.vehicleLocationObj) {
                      return (
                        <GPSPointPopup
                          coordinates={Helpers.convertParseVehicleLocationArrToPointArr([tripObj.start.vehicleLocationObj], true)}
                          addressString={tripObj.start.addressString}
                          vehicleLocation={tripObj.start.vehicleLocationObj}
                        />
                      )
                    } else if (tripObj.end.vehicleLocationObj) {
                      return (
                        <GPSPointPopup
                          coordinates={Helpers.convertParseVehicleLocationArrToPointArr([tripObj.end.vehicleLocationObj], true)}
                          addressString={tripObj.end.addressString}
                          vehicleLocation={tripObj.end.vehicleLocationObj}
                        />
                      );
                    }
                  })
                }
                {
                  (!this.props.activeVehicleHistory.tripArr || this.props.activeVehicleHistory.tripArr.length === 0)
                  && this.props?.activeVehicleHistory?.legs?.vehicleLocationArr
                  && this.props?.activeVehicleHistory?.legs?.vehicleLocationArr?.length > 1
                  &&
                  (
                    <div>
                      <GPSPointPopup
                        coordinates={Helpers.convertParseVehicleLocationArrToPointArr([this.props.activeVehicleHistory.legs.vehicleLocationArr[0]], true)}
                        vehicleLocation={this.props.activeVehicleHistory.legs.vehicleLocationArr[0]}
                      />
                      <GPSPointPopup
                        coordinates={Helpers.convertParseVehicleLocationArrToPointArr([this.props.activeVehicleHistory.legs.vehicleLocationArr[this.props.activeVehicleHistory.legs.vehicleLocationArr.length - 1]], true)}
                        vehicleLocation={this.props.activeVehicleHistory.legs.vehicleLocationArr[this.props.activeVehicleHistory.legs.vehicleLocationArr.length - 1]}
                      />
                    </div>
                  )
                }
              </div>
            }

            {this.props.popup && this.props.popup.type === 'locationSearch' && !this.props.addEditGeofence.type &&
              <div>
                <LocationSearchPopup
                  coordinates={[this.props.locationSearch.geometry.location.lng(), this.props.locationSearch.geometry.location.lat()]}
                  locationSearch={this.props.locationSearch}
                />
              </div>
            }

            {this.props.popup && this.props.popup.type === 'geofence' && !this.props.addEditGeofence.type &&
              <GeofencePopup
                coordinates={this.props.popup.coordinates}
                geofence={this.props.popup.geofence}
                editGeofence={this.props.editGeofence}
              />
            }

            {this.props.selectedTrip?.legs?.[0].waypoints && !this.props.replay.loading &&
              // Displays the GPS path for the selected sub trip
              < GeoJSONLayer
                id="gps-sub-trip-path"
                data={{
                  type: "Feature",
                  geometry: {
                    type: "LineString",
                    coordinates: Helpers.convertLongitudeLatitudeObjArrToPointArr(this.props.selectedTrip?.legs?.[0].waypoints) || []
                  },
                }}
                lineLayout={{
                  "line-cap": "round",
                  "line-join": "round",
                }}
                linePaint={{
                  'line-color': '#E03857',
                  'line-width': 5
                }}
              />
            }
          </div>
        }
      </ReactMap>
    );
  }
}

VehicleMapView.propTypes = {
  VehicleLocation: PropTypes.object,
  activeObject: PropTypes.object,
  addEditGeofence: PropTypes.object.isRequired,
  Company: PropTypes.object,
  geofence: PropTypes.object,
  geofences: PropTypes.array,
  handleInitialFit: PropTypes.func.isRequired,
  handleMapClick: PropTypes.func.isRequired,
  highlightedObjects: PropTypes.array,
  mapLoaded: PropTypes.bool,
  initialFit: PropTypes.bool,
  vehicles: PropTypes.array,
  zoom: PropTypes.array,
  fitToArr: PropTypes.array,
  center: PropTypes.array,
  locationSearch: PropTypes.object,
  token: PropTypes.string,
  setPopup: PropTypes.func.isRequired,
  popup: PropTypes.object,
  setGPSPointPopup: PropTypes.func.isRequired,
  setMapLoaded: PropTypes.func.isRequired,
  gpsPointPopup: PropTypes.object,
  activeVehicleHistory: PropTypes.object.isRequired,
  activeMapStyle: PropTypes.string.isRequired,
  updateZoom: PropTypes.func.isRequired,
  hidePopup: PropTypes.func.isRequired,
  displayMarkers: PropTypes.object,
  showLSD: PropTypes.bool,
  setActiveFollowObject: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { VehicleLocation, Company } = state;
  return ({
    Company,
    VehicleLocation,
  });
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(VehicleMapView);
