import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdbreact';


// API
import { getAttribute } from 'api/Parse';
import { getDriverDocuments } from 'api/Driver/Documents';
import { openDocumentLink } from 'api/Helpers';
import { t } from 'api/Translate';
import * as Helpers from 'api/Helpers';

// Enums
import { QueryRestrictionTypes } from 'enums/Query';
import { DocumentTypes } from 'enums/DispatchDocument';

// SBObjects
import Filter from 'sbObjects/Filter';

// Components
import Title from 'components/Shared/Title/Title';
import SBCardEmptyContent from 'components/Shared/SBCard/SBCardEmptyContent';
import SBTable from 'components/Shared/SBTable/SBTable';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import FilterForm from 'components/FilterForm/container/FilterForm.new';


class DriverDocumentsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filtersToQuery: [],
      page: 0,
      limit: 20,
      isBusy: false,
      driverDocuments: [],
      pageCount: 1,
    };

    this.tableHeaderRows = [{
      key: 'driver-documents-table',
      columns: [
        {
          element: (
            <div>Name</div>
          ),
          props: { style: { width: '33%' } }
        },
        {
          element: (
            <div>Date Uploaded (Local)</div>
          ),
          props: { style: { width: '33%' } }
        },
        {
          element: (
            <div />
          ),
          props: { style: { width: '34%' } }
        },
      ],
    }];

    this.filterFormFields = [
      {
        attrName: 'file',
        fullName: t('Name'),
        value: '',
        type: 'text',
        queryType: QueryRestrictionTypes.MATCHES,
      },
      {
        attrName: 'uploadedAt',
        queryType: 'date',
        dateQueryType: QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO,
        fullName: t('To'),
        placeholder: '.....',
        type: 'date',
      },
      {
        attrName: 'uploadedAt',
        queryType: 'date',
        dateQueryType: QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO,
        fullName: t('From'),
        placeholder: '.....',
        type: 'date',
      },
    ];

    const documentTypeKeys = Object.keys(DocumentTypes);
    // filter by document type
    const docTypeFilterItems = documentTypeKeys.map((type, index) => ({
      key: index,
      label: DocumentTypes[type].description,
      value: DocumentTypes[type].type,
    }));
    docTypeFilterItems.sort((typeA, typeB) => {
      // sort the order of items by the label/description rather than type
      if (typeA.label < typeB.label) return -1;
      if (typeA.label > typeB.label) return 1;
      return 0;
    });
    this.state.docTypeFilterItems = docTypeFilterItems;
    this.state.selectedDocTypeFilterItems = [...this.state.docTypeFilterItems];

    this.refreshState = this.refreshState.bind(this);
    this.handleFilterFields = this.handleFilterFields.bind(this);
    this.handlePaginatorOnChange = this.handlePaginatorOnChange.bind(this);
    this.getSelectedDocumentTypes = this.getSelectedDocumentTypes.bind(this);
  }

  async componentDidMount() {
    await this.refreshState();
  }

  handleFilterFields(filters) {
    const filtersToQuery = [];

    filters.forEach(filter => {
      if (filter.type === 'date') {
        const fromDate = filter.fromValue ? filter.fromValue.toDate() : new Date();
        const toDate = filter.toValue ? filter.toValue.toDate() : new Date();

        filtersToQuery.push(
          new Filter(filter.attribute, fromDate, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO),
          new Filter(filter.attribute, toDate, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO),
        );
      } else {
        filtersToQuery.push(new Filter(filter.attribute, filter.value, filter.queryType));
      }
    });

    this.setState({ filtersToQuery }, async () => await this.refreshState());
  }

  handlePaginatorOnChange(type, inputValue) {
    const { state } = this;

    let { page } = state;

    if (type === 'PREVIOUS') {
      page--;

      if (page < 0) {
        page = 0;
      }
    } else if (type === 'NEXT') {
      page++;

      if (page >= state.pageCount - 1) {
        page = state.pageCount - 1;
      }
    } else if (type === 'SUBMIT') {
      let newPage = inputValue - 1;

      if (newPage < 0) {
        newPage = 0;
      } else if (newPage >= (state.pageCount - 1)) {
        newPage = state.pageCount - 1;
      }

      page = newPage;
    }

    if (page !== state.page) this.setState({ page }, async () => await this.refreshState());
  }

  async refreshState() {
    const { props, state } = this;

    if (!props.driver) return;

    this.setState({ isBusy: true });

    const driverUser = getAttribute(props.driver, 'user');

    const _filters = [
      ...state.filtersToQuery,
      new Filter('uploadedBy', driverUser, QueryRestrictionTypes.EQUAL_TO),
    ];

    const { totalDriverDocumentsCount, driverDocuments } = await getDriverDocuments(
      undefined,                          // options - default
      _filters,                           // filters
      undefined,                          // sort - default
      [],                                 // includes
      undefined,                          // selects
      state.page,                         // page
      state.limit,                        // limit
      false                               // query all
    );

    const pageCount = Math.ceil(totalDriverDocumentsCount / state.limit);

    this.setState({ pageCount, driverDocuments, isBusy: false });
  }

  getSelectedDocumentTypes(selectedDocTypeFilterItems) {
    this.setState({ selectedDocTypeFilterItems });
  }

  render() {
    const { props, state } = this;

    // double-ensure they are subscribed
    const eldModuleEnabled = Helpers.isSubscribedToModule('eldModule');
    const driverDocumentsModuleEnabled = Helpers.isSubscribedToModule('driverDocumentModule');
    const isLayoutEnabled = eldModuleEnabled && driverDocumentsModuleEnabled;

    let filteredDriverDocuments = [].concat(state.driverDocuments);
    const selectedDocTypes = state.selectedDocTypeFilterItems.map(item => item.value);

    filteredDriverDocuments = filteredDriverDocuments.filter(doc => {
      const documentType = getAttribute(doc, 'documentType');
      return selectedDocTypes.indexOf(documentType) !== -1;
    });

    const driverDocumentTableRows = filteredDriverDocuments.map(driverDocument => {
      const objectId = getAttribute(driverDocument, 'objectId');
      const file = getAttribute(driverDocument, 'file');

      const uploadedAt = getAttribute(driverDocument, 'uploadedAt');
      const uploadedDate = moment(uploadedAt).format('LL');

      const documentName = file._name.split(/_(.+)/)[1];

      return {
        key: objectId,
        columns: [
          {
            element: (
              <div>{ documentName }</div>
            ),
            props: {
              className: 'table-body-column-style',
              style: { width: '33%' }
            }
          },
          {
            element: (
              <div>{ uploadedDate }</div>
            ),
            props: {
              className: 'table-body-column-style',
              style: { width: '33%' }
            }
          },
          {
            element: (
              <ActionsContainer>
                <MDBBtn size="sm" color="secondary" onClick={() => openDocumentLink(file._url)}>
                  <MDBIcon icon="file-download mr-1" /> Download
                </MDBBtn>
              </ActionsContainer>
            ),
            props: {
              className: 'table-body-column-style',
              style: { width: '34%' }
            }
          }
        ],
        props: { className: 'table-body-column-style', style: { textAlign: 'left' } },
      };
    });

    return (
      <MDBContainer fluid>
        { isLayoutEnabled && (
          <React.Fragment>
            <MDBRow>
              <MDBCol>
                <Title title="Driver Documents" />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <ActionsContainer className="flex-row-reverse">
                  <FilterForm
                    className="d-inline-block mt-0"
                    handleFilter={this.handleFilterFields}
                    clearFilter={() => this.handleFilterFields([])}
                    fields={this.filterFormFields}
                    disabled={state.isBusy}
                  />
                  <SBSelect
                    containerClassName="mr-1 d-inline-block align-middle select-document-filter"
                    dropdownToggleText="Document Types"
                    items={state.docTypeFilterItems}
                    selectedItems={state.selectedDocTypeFilterItems}
                    showFilter
                    multiSelectable
                    showSelectAll
                    getSelectedItems={this.getSelectedDocumentTypes}
                  />
                  <MDBBtn
                    color="secondary"
                    size="sm"
                    className="mr-0 mt-0"
                    onClick={async () => await this.refreshState()}
                  >
                    <MDBIcon icon="sync-alt" className="mr-1" /> Refresh
                  </MDBBtn>
                </ActionsContainer>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <SBTable
                  tableHeaderRows={this.tableHeaderRows}
                  tableBodyRows={driverDocumentTableRows}
                  showPaginator
                  activePage={state.page + 1} // 1-index for display
                  pageCount={state.pageCount}
                  handlePaginatorOnChange={this.handlePaginatorOnChange}
                  emptyTableMessage={(
                    <div>
                      {state.isBusy && (
                        <SBCardEmptyContent isContentLoader={state.isBusy} paddingYLevel={3} containerClassName="">
                          Loading Contacts
                        </SBCardEmptyContent>
                      )}
                    </div>
                  )}
                />
              </MDBCol>
            </MDBRow>
          </React.Fragment>
        )}
      </MDBContainer>
    );
  }
}

DriverDocumentsLayout.propTypes = {
  driver: PropTypes.object,
  driverFetched: PropTypes.bool,
  dispatchers: PropTypes.array,
  detailsUpdatedHandler: PropTypes.func,
};

export default DriverDocumentsLayout;
