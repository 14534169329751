import React from 'react';
import PropTypes from 'prop-types';

// Components
import ELDEvents from 'components/DriverDetails/container/ELDEvents';

// CSS
import styles from './HoursOfServiceAndEventsLayout.module.scss';

class HoursOfServiceAndEventsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = { printShow: false };
  }

  render() {
    const { driver, driverFetched, eldModuleSubscribed, callingUser } = this.props;
    return (
      <div className={styles.content}>
        { driverFetched && eldModuleSubscribed &&
          <ELDEvents
            driver={driver}
            driverFetched={driverFetched}
            location={this.props.location}
          />
        }
      </div>
    );
  }
}

HoursOfServiceAndEventsLayout.propTypes = {
  driver: PropTypes.object,
  driverFetched: PropTypes.bool,
  eldModuleSubscribed: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

export default HoursOfServiceAndEventsLayout;
