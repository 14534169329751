import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Nav, NavItem } from 'react-bootstrap';

// API
import { getRecentCarrierProfile, uploadCarrierProfile } from 'api/CarrierProfile';

// Components
import Title from 'components/LayoutTitle/view/Title';
import AllIssuesTable from 'components/CarrierSafetyIssues/view/AllIssuesTable';
import DriverIssuesTable from 'components/CarrierSafetyIssues/view/DriverIssuesTable';
import VehicleIssuesTable from 'components/CarrierSafetyIssues/view/VehicleIssuesTable';
import CommonIssuesTable from 'components/CarrierSafetyIssues/view/CommonIssuesTable';
import UploadFileButton from 'components/UploadFileButton/view/UploadFileButton';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// Lists
import { StateProvinces } from 'api/Lists/StateProvinces';

// CSS
import styles from './IssuesLayout.module.scss';

class IssuesLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'allIssues',
      isLoading: true,
    };
    this.state.summary = {
      jurisdiction: undefined,
      createdAt: undefined,
      carrierScore: undefined,
      certificateStatus: undefined,
      safetyRating: undefined,
      profileStatus: undefined,
      auditStatus: undefined,
      complianceReview: undefined,
    };
    this.stateConstructor = { ...this.state, summary: Object.assign({}, this.state.summary) }; // create a fresh, default backup
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.setCarrierSummary = this.setCarrierSummary.bind(this);
    // this.setLoading = this.setLoading.bind(this); // fn to send to children to use
    this.upload = this.upload.bind(this);
  }

  componentDidMount() {
    // obtain the latest carrier profile if applicable
    getRecentCarrierProfile().then(carrierProfileReport => {
      if (carrierProfileReport) {
        this.setCarrierSummary(carrierProfileReport);
      }
    });
  }

  setCarrierSummary(carrierProfileReport) {
    // match carrier profile object to state
    const newState = { ...this.stateConstructor };

    if (carrierProfileReport.carrierProfileObject) {
      const carrierProfileObject = carrierProfileReport.carrierProfileObject;
      const summaryKeys = Object.keys(newState.summary);

      for (let i = 0; i < summaryKeys.length; i++) {
        const key = summaryKeys[i];
        newState.summary[key] = carrierProfileObject.get(key);
      }

      newState.carrierProfileReport = carrierProfileReport;

      let allContraventionsAndAccidents = [];
      if (carrierProfileReport.driverContraventions) {
        allContraventionsAndAccidents = allContraventionsAndAccidents.concat(carrierProfileReport.driverContraventions);
      }
      if (carrierProfileReport.carrierContraventions) {
        allContraventionsAndAccidents = allContraventionsAndAccidents.concat(carrierProfileReport.carrierContraventions);
      }
      if (carrierProfileReport.driverAccidents) {
        allContraventionsAndAccidents = allContraventionsAndAccidents.concat(carrierProfileReport.driverAccidents);
      }
      newState.allContraventionsAndAccidents = allContraventionsAndAccidents;
    }

    // remove loading icon
    newState.isLoading = false;

    this.setState(newState);
  }

  handleTabSelect(eventKey) {
    this.setState({ ...this.state, activeTab: eventKey });
  }

  upload(file) {
    // upload file to carrier profile
    this.setState({ ...this.state, isLoading: true });
    uploadCarrierProfile(file).then(carrierProfileReport => {
      // match our state summary keys to that of the profile summary
      // notice that we call it carrierProfileReport; because it returns all important contents of the profile as an object
      this.setCarrierSummary(carrierProfileReport);
    });
  }

  // setLoading(isLoading) {
  //   this.setState({ ...this.state, isLoading });
  // }

  render() {
    const {
      jurisdiction,
      createdAt,
    } = this.state.summary;

    let jurisdictionLong = jurisdiction;
    if (jurisdiction) {
      jurisdictionLong = StateProvinces.filter(province => {
        return province.code.toUpperCase() === jurisdiction.toUpperCase();
      });
      jurisdictionLong = jurisdictionLong[0].name;
    }

    return (
      <div className={styles.issuesLayout}>
        <Title title="Safety">
          <span className="inlineBlock">
            {this.state.isLoading &&
              <LoadingIcon />
            }
            {!this.state.isLoading && jurisdiction &&
              <span className="toUpperCase textGrayCalm">{ jurisdictionLong } - <small>({`Profile Uploaded ${moment(createdAt).format('MMM D, YYYY')}`})</small>&nbsp;-&nbsp;</span>
            }
            {!this.state.isLoading &&
              <UploadFileButton className="inlineBlock" uploadHandler={this.upload}>Upload Profile</UploadFileButton>
            }
          </span>
        </Title>

        <div className={styles.issuesNavbar}>
          <Nav variant="tabs" activeKey={this.state.activeTab} onSelect={this.handleTabSelect}>
            <NavItem eventKey="allIssues" title="Summary of Issues">All Issues</NavItem>
            <NavItem eventKey="driverIssues" title="Driver Issues">Drivers</NavItem>
            <NavItem eventKey="vehicleIssues" title="Vehicle Issues">Vehicles</NavItem>
            <NavItem eventKey="commonIssues" title="Common Issues">Common Issues</NavItem>
          </Nav>
        </div>

        <div>
          {this.state.activeTab === 'allIssues' &&
            <AllIssuesTable carrierProfileReport={this.state.carrierProfileReport} />
          }
          {this.state.activeTab === 'driverIssues' &&
            <DriverIssuesTable contraventions={this.state.allContraventionsAndAccidents} />
          }
          {this.state.activeTab === 'vehicleIssues' &&
            <VehicleIssuesTable inspections={[] || this.state.carrierProfileReport.vehicleInspections} />
          }
          {this.state.activeTab === 'commonIssues' &&
            <CommonIssuesTable carrierProfileReport={this.state.carrierProfileReport} />
          }
        </div>
      </div>
    );
  }
}

export default IssuesLayout;
