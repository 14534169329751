import * as ActionConstants from 'actions/ActionConstants';

const initialState = {
  company: {},
  fetched: false,
};

export function Company(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_COMPANY_INPROGRESS:
      return {
        ...state,
        status: ActionConstants.FETCH_COMPANY_INPROGRESS,
        company: {},
        error: undefined,
        fetched: false,
      };
    case ActionConstants.FETCH_COMPANY_SUCCESS:
      return {
        ...state,
        status: ActionConstants.FETCH_COMPANY_SUCCESS,
        company: action.company,
        error: undefined,
        fetched: true,
      };
    case ActionConstants.FETCH_COMPANY_ERROR:
      return {
        ...state,
        status: ActionConstants.FETCH_COMPANY_ERROR,
        error: action.error,
        fetched: false,
      };
    case ActionConstants.UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        status: ActionConstants.UPDATE_COMPANY_SUCCESS,
        company: action.company || state.company,
      };
    default:
      return state;
  }
}
