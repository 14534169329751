import React from 'react';
import PropTypes from 'prop-types';

// API
import { createNewUserToCompany } from 'api/Setters';
import { compressWhitespace } from 'api/Helpers';

// Components
import AddNewCompanyUserModalView from 'components/AddNewCompanyUser/view/AddNewCompanyUserModal';

function getInitialState() {
  return { user: {}, addingInProgress: false, disableAdd: true, errorString: undefined };
}

class AddNewCompanyUserModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.handleAddNewCompanyUser = this.handleAddNewCompanyUser.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  handleFormChange(property, value) {
    const newState = { ...this.state };
    newState.user[property] = value;
    if (newState.user.firstName && newState.user.lastName && newState.user.emailAddress && newState.user.emailAddress2 && newState.user.password && newState.user.password2) {
      if (newState.user.password !== newState.user.password2) {
        newState.errorString = 'Passwords do not match';
        newState.disableAdd = true;
      } else if (newState.user.emailAddress !== newState.user.emailAddress2) {
        newState.errorString = 'Email Addresses do not match';
        newState.disableAdd = true;
      } else if (newState.user.password.length < 8) {
        newState.errorString = 'Password must be at least 8 characters long';
        newState.disableAdd = true;
      } else {
        newState.errorString = undefined;
        newState.disableAdd = false;
      }
    } else {
      newState.disableAdd = true;
    }

    this.setState(newState);
  }

  handleAddNewCompanyUser() {
    this.setState({ ...this.state, addingInProgress: true });

    createNewUserToCompany(this.state.user.firstName, this.state.user.lastName, compressWhitespace(this.state.user.emailAddress).toLowerCase(), this.state.user.password).then(() => {
      this.closeModal();
    }).catch((err) => {
      console.log(err);
      if (err.message) {
        // email exists
        this.setState({ ...this.state, addingInProgress: false, errorString: err.message });
      } else {
        this.setState({ ...this.state, addingInProgress: false, errorString: err });
      }
    });
  }

  async closeModal() {
    await this.setState(getInitialState());
    this.props.handleClose();
  }

  render() {
    return (
      <AddNewCompanyUserModalView
        {...this.props}
        handleClose={this.closeModal}
        user={this.state.user}
        error={this.state.error}
        handleFormChange={this.handleFormChange}
        handleAddNewCompanyUser={this.handleAddNewCompanyUser}
        disableAdd={this.state.addingInProgress}
        errorString={this.state.errorString}
      />
    );
  }
}

AddNewCompanyUserModal.propTypes = {
  addingInProgress: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  refreshState: PropTypes.func.isRequired,
};

export default AddNewCompanyUserModal;
