import { t } from 'api/Translate';
/*
  WARNING: There may be uses of event.set() as placeholders for attributes but do not actually save!
  Additionally, modify the state by reference as opposed to using setState because chartjs
  likes to retain references to old states

  Be very careful when using .toDate() on moment objects here because it will localize date/time values when we may not want it to
  (can get rid of DST/timezone adjusting if used in incorrect sequence)
*/

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { Line } from 'react-chartjs-2'

// API
import * as ELD from 'api/ELD'
import * as Helpers from 'api/Helpers'
import { cloneRecord, getAttribute } from 'api/Parse';
import { getTimezoneAbbreviation } from 'api/utils/timezone';

import { MDBRow, MDBCol } from 'mdbreact'

class HOSGraph extends React.Component {
  constructor(props) {
    super(props)

    let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    let isMobile = (width > 768 ? false : true);
    const cjsEventsDatasetTemplate = {
      // Our custom ChartJS dataset properties to be used for all datasets for line-drawing
      label: '',
      fill: false,
      lineTension: 0,
      // backgroundColor: props.defaultLineColor || '#0DAAFF', // the colors are set in the graph extension
      // borderColor: props.defaultLineColor || '#0DAAFF',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'bevel', // default 'miter'
      steppedLine: true,
      pointRadius: [],
      pointHitRadius: [],
      pointHoverRadius: [],
      pointBorderColor: [],
      pointBackgroundColor: [],
      // data: [],
    }
    //'rgba(224, 56, 87, 1)'
    const cjsViolationsDatasetTemplate = {
      // Our custom ChartJS dataset properties to be used for all datasets for line-drawing
      label: '',
      fill: false,
      lineTension: 0,
      // backgroundColor: props.defaultLineColor || '#0DAAFF', // the colors are set in the graph extension
      // borderColor: props.defaultLineColor || '#0DAAFF',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'bevel', // default 'miter'
      steppedLine: true
      // data: [],
    }

    const cjsData = {
      // Amalgamation of our datasets and label settings to input to CJs Line Graph
      labels: [],
      datasets: []
    }

    // Graph code array for Y-Axis
    const graphCodes = !props.shortDutyStatus
      ? [
        undefined,
        t('ON DUTY - NOT DRIVING'),
        undefined,
        t('DRIVING'),
        undefined,
        t('SLEEPER BERTH'),
        undefined,
        t('OFF DUTY'),
      ]
      : [
        undefined,
        t('ON'),
        undefined,
        t('D'),
        undefined,
        t('SB'),
        undefined,
        t('OFF'),
      ];

    const cjsOptions = {
      // Custom ChartJS options
      animation: false,
      responsive: isMobile ? false : true,
      maintainAspectRatio: false,
      bezierCurve: false,
      elements: {
        // point: {
        //   pointStyle: 'circle',
        //   radius: 0, // turns the points off. default 3
        //   hitRadius: 0, // 1
        //   hoverRadius: 0 // 4
        // }
      },
      scales: {
        xAxes: [
          {
            type: 'time',
            bounds: 'ticks',
            time: {
              displayFormats: {
                hour: 'X'
              },
              // min: '00:00:00',
              // max: '24:00:00',
              unit: 'hour',
              stepSize: 1,
              unitStepSize: 1,
              minUnit: 'hour',
            },
            ticks: {
              source: 'labels',
              fontColor: '#000000',
              fontSize: 12,
              fontFamily: 'Tahoma',
              fontStyle: 'bold'
            }


            // time: {
            //   // format: 'h:mm a',
            //   // displayFormats: {
            //   //   minute: 'h:mm a',
            //   //   hour: 'h:mm a'
            //   // },
            //   unit: 'hour',
            //   minUnit: 'hour',
            //   stepSize: 1
            // },
            // autoSkip: false,
            // ticks: {
            //   fontColor: '#000000',
            //   fontSize: 12,
            //   fontFamily: 'Tahoma',
            //   fontStyle: 'bold'
            // }
          }
        ],

        yAxes: [
          {
            position: 'left',
            ticks: {
              min: 0,
              // max: 5 // if we're including pu-cmv and ym in the graph
              max: 8,
              fixedStepSize: 1,
              // Return an empty string to draw the tick line but hide the tick label
              // Return `null` or `undefined` to hide the tick line entirely
              userCallback(tick, index, ticks) {
                return graphCodes[tick]
              },
              fontColor: '#337AB7',
              fontSize: 10,
              fontFamily: 'Tahoma',
              fontStyle: 'bold'
            }
          }
        ]
      },
      legend: {
        display: false // don't display chart legend labels
      }
      // hover: {
      //   mode: 'nearest',
      //   intersect: true,
      // },
    }

    this.state = {
      cjsEventsDatasetTemplate,
      cjsViolationsDatasetTemplate,
      cjsData,
      cjsOptions,
      graphCodes,
      datasetTypes: {
        ELD_EVENTS: 0,
        HOS_VIOLATIONS: 1,
      },
      hasDSTJump: undefined,
      hasSTJump: undefined,
    }

    this.addEventGraphDatasets = this.addEventGraphDatasets.bind(this)
    this.populateSpecialEventDutyStatus = this.populateSpecialEventDutyStatus.bind(this)
    this.refreshHOSGraph = this.refreshHOSGraph.bind(this)
    this.setChartRef = this.setChartRef.bind(this)
  }

  componentDidMount() {
    const { outlineEdits, outlineAutoGeneratedDrivingTimes } = this.props

    // Extend graph functionality to allow single-multi-colored line which chartjs does not allow out of the box
    // This removes weird fnality associated with stacking charts on top of each other for different colors
    let originalLineDraw = Chart.controllers.line.prototype.draw
    Chart.helpers.extend(Chart.controllers.line.prototype, {
      draw(ease) {
        // NOTE: This may break if more than 1 dataset
        originalLineDraw.apply(this, arguments)

        let startIndex = 0

        let meta = this.getMeta()
        // metadata

        let points = meta.data || []
        // not sure what this is for

        let area = this.chart.chartArea
        // drawing area, consists of: { bottom: float, left: float, right: float, top: float }
        // the ChartElements corresponding to our data points of a Line chart, filtered for only those with valid Y

        let chartElements = meta.dataset._children.filter(function (
          chartElement
        ) {
          return !isNaN(chartElement._view.y)
        })

        // if theres no legit points, do nothing
        if (chartElements.length === 0) {
          return
        }

        // function to set line color between two+ points
        function _setColor(newColor = 'green', meta) {
          meta.dataset._view.borderColor = newColor
          meta.dataset._view.borderColor = newColor
        }

        // get the cartesian data points we put in place
        const datasets = meta.controller.chart.config.data.datasets

        // we take the last one in case we decide to do multiple datasets in which the last one will contain all coords
        // because the order goes from newest -> oldest datasets. this condition is applied across this component
        const eldEventsGraphData = datasets[datasets.length - 1].data || []

        // now filter to make sure we get the same events which are actually drawable
        const eldEventsGraphDataFiltered = []

        for (let i = 0; i < chartElements.length; i++) {
          const chartElementIndex = chartElements[i]._index
          eldEventsGraphDataFiltered.push(
            eldEventsGraphData[chartElementIndex] || {}
          )
        }

        /*
          now we have a 1-1 match of eldEventsGraphData and chartElements
          so we apply a loop to match the color that should be between 2 points
          based on the duty stauses we have from eldEventsGraphDataFiltered
        */
        let chartElementsLen = chartElements.length
        let lastRecordedLineColor
        let lastChangedColorIndex = chartElementsLen - 1

        while (chartElementsLen--) {
          const chartElement = chartElements[chartElementsLen]
          const graphCoord = eldEventsGraphDataFiltered[chartElementsLen]

          // order matters to determine colors
          let lineColor = '#0DAAFF'
          if (outlineEdits && graphCoord.requestedELDEvent) {
            lineColor = '#FF6666'
          } else if (graphCoord.autoGeneratedDrivingTime) {
            lineColor = '#E4B74E'
          } else if (graphCoord.personalConveyance) {
            lineColor = '#91C100'
          } else if (graphCoord.yardMoves) {
            lineColor = '#0E7A32'
          }

          if (lineColor !== lastRecordedLineColor) {
            // special events type changed, so set the color appropriately from where it should start to where it should end
            _setColor(lineColor, meta)

            // slice chartElements to tell chartjs that these events are of this color
            meta.dataset._children = chartElements.slice(
              0,
              chartElementsLen + 2
            )
            if (outlineEdits && graphCoord.requestedELDEvent) {
              // edits are sliced one less to show only the edited portion
              meta.dataset._children = chartElements.slice(
                0,
                chartElementsLen + 1
              )
            }
            meta.dataset.draw()
            lastChangedColorIndex = chartElementsLen
            lastRecordedLineColor = lineColor
          }
        }

        // remodify children to include all chart elements so they dont disappear
        meta.dataset._children = chartElements

        points.forEach(function (point) {
          point.draw(area)
        })
      }
    })

    this.refreshHOSGraph(this.props)
  }

  componentWillUnmount() {
    // Extend graph functionality to re-draw the original graph when this component dismounts because sometimes
    // colors from things like autoGeneratedDrivingTime stick around since object manipulations are in-place
    let originalLineDraw = Chart.controllers.line.prototype.draw
    Chart.helpers.extend(Chart.controllers.line.prototype, {
      draw(ease) {
        // NOTE: This may break if more than 1 dataset
        originalLineDraw.apply(this, arguments)

        let startIndex = 0

        let meta = this.getMeta()
        // metadata

        let points = meta.data || []
        // not sure what this is for

        let area = this.chart.chartArea
        // drawing area, consists of: { bottom: float, left: float, right: float, top: float }
        // the ChartElements corresponding to our data points of a Line chart, filtered for only those with valid Y

        let chartElements = meta.dataset._children.filter(function (
          chartElement
        ) {
          return !isNaN(chartElement._view.y)
        })

        // if theres no legit points, do nothing
        if (chartElements.length === 0) {
          return
        }

        // function to set line color between two+ points
        function _setColor(newColor = 'green', meta) {
          meta.dataset._view.borderColor = newColor
          meta.dataset._view.borderColor = newColor
        }

        // get the cartesian data points we put in place
        const datasets = meta.controller.chart.config.data.datasets

        // we take the last one in case we decide to do multiple datasets in which the last one will contain all coords
        // because the order goes from newest -> oldest datasets. this condition is applied across this component
        const eldEventsGraphData = datasets[datasets.length - 1].data || []

        // now filter to make sure we get the same events which are actually drawable
        const eldEventsGraphDataFiltered = []

        for (let i = 0; i < chartElements.length; i++) {
          const chartElementIndex = chartElements[i]._index
          eldEventsGraphDataFiltered.push(
            eldEventsGraphData[chartElementIndex] || {}
          )
        }

        /*
          now we have a 1-1 match of eldEventsGraphData and chartElements
          so we apply a loop to match the color that should be between 2 points
          based on the duty stauses we have from eldEventsGraphDataFiltered
        */
        let chartElementsLen = chartElements.length
        let lastRecordedLineColor
        let lastChangedColorIndex = chartElementsLen - 1

        while (chartElementsLen--) {
          const chartElement = chartElements[chartElementsLen]
          const graphCoord = eldEventsGraphDataFiltered[chartElementsLen]

          // order matters to determine colors
          let lineColor = '#0DAAFF'
          if (graphCoord.personalConveyance) {
            lineColor = '#91C100'
          } else if (graphCoord.yardMoves) {
            lineColor = '#0E7A32'
          } else if (graphCoord.autoGeneratedDrivingTime) {
            lineColor = '#E4B74E'
          }

          if (lineColor !== lastRecordedLineColor) {
            // special events type changed, so set the color appropriately from where it should start to where it should end
            _setColor(lineColor, meta)

            // slice chartElements to tell chartjs that these events are of this color
            meta.dataset._children = chartElements.slice(
              0,
              chartElementsLen + 2
            )
            meta.dataset.draw()
            lastChangedColorIndex = chartElementsLen
            lastRecordedLineColor = lineColor
          }
        }

        // remodify children to include all chart elements so they dont disappear
        meta.dataset._children = chartElements

        points.forEach(function (point) {
          point.draw(area)
        })
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    this.refreshHOSGraph(nextProps)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.disableRedraw) return false;
    return true;
  }

  addEventGraphDatasets(eldEvents, cjsDatasetsArray) {
    /*
      - now we have the correct, ordered, eldEvents for the 24hr graph span, convert them into cartesian data for plotting,
      apply them to cjsDatasetsArray in-place, and return the raw data
      - also take into account flags to determine coloring of certain lines
    */

    const { cjsEventsDatasetTemplate, datasetTypes } = this.state
    const {
      outlineEdits,
      outlineAutoGeneratedDrivingTimes,
      driver,
      scaleToDriverTimezone
    } = this.props

    let timezoneOffsetFromUTC =
      (scaleToDriverTimezone &&
        driver &&
        driver.get('timezoneOffsetFromUTC')) ||
      moment.tz.guess()

    if (scaleToDriverTimezone && this.props.timezoneOffsetFromUTC) {
      timezoneOffsetFromUTC = this.props.timezoneOffsetFromUTC;
    }

    const eldEventsGraphData = ELD.convertEventsToGraphCoords(
      eldEvents,
      timezoneOffsetFromUTC
    )

    // now create datasets for each respective special status. they should be placed in the datasets array in the priority we wish to view them
    // (datasets stack on top of each other. start-end -> top->bottom of the stack)
    // such is the order we'd want to see: Edits -> AGDT -> PC <-> YM
    // ensure cjDatasetsArray is empty before usage. if not, you will be appending data what it currently contains
    // const datasets = cjsDatasetsArray
    // datasets.unshift({
    //   ...cjsEventsDatasetTemplate,
    //   data: eldEventsGraphData,
    //   datasetType: datasetTypes.ELD_EVENTS,
    // }) // base data, all in one color

    return eldEventsGraphData // return sorted/converted eventdata for any processing needed afterwards
  }

  populateSpecialEventDutyStatus(combinedEvents) {
    // map the correct duty status to special events
    // if point is an hos violation, give it the same y value as the nearest (previous priority) duty status
    combinedEvents.map((event, index) => {
      const previousEvent = combinedEvents[index - 1];
      const nextEvent = combinedEvents.slice(index).find(event => !event.isSpecialEvent); // next duty status point

      const eventDateTimeMillis = moment(event.get('eventDateTime')).valueOf();
      const previousEventIsSameDateTime = previousEvent && (moment(previousEvent.get('eventDateTime')).valueOf() === eventDateTimeMillis);
      const nextEventIsSameDateTime = nextEvent && (moment(nextEvent.get('eventDateTime')).valueOf() === eventDateTimeMillis);

      if (event.isSpecialEvent) {
        if (previousEventIsSameDateTime) {
          event.set('eldEventTypeCodeInt', previousEvent.get('eldEventTypeCodeInt'));
        } else if (nextEventIsSameDateTime) {
          event.set('eldEventTypeCodeInt', nextEvent.get('eldEventTypeCodeInt'));
        } else if (previousEvent) {
          event.set('eldEventTypeCodeInt', previousEvent.get('eldEventTypeCodeInt'));
        } else if (nextEvent) {
          event.set('eldEventTypeCodeInt', nextEvent.get('eldEventTypeCodeInt'));
        }
      }
    });

    return combinedEvents;
  }

  refreshHOSGraph(props) {
    /*
      Given ELD Events, convert them to proper plotting coordinates. Events provided should fit within the 24 hours
      startTimeUTC defined by the 'eldDailyCertification'. If they do not, the graph will extend over 24 hours
      effectively showing there is a source of error that needs addressing

      For ease of use, we do sorting and active-status filtering for the user so they can just pass in
      raw values unless they choose to use certain prop flags (to adjust filtering)
    */

    const {
      eldDailyCertification,
      eldEvents,
      associatedELDEvents,
      displayHours,
      outlineEdits,
      outlineAutoGeneratedDrivingTimes,
      driver,
      scaleToDriverTimezone,
      hosViolations,
      showHOSViolations,
    } = props
    const { cjsData, cjsOptions, graphCodes, cjsEventsDatasetTemplate } = this.state

    const driverFirstName = driver
      ? driver.get('user').get('firstName')
      : t('Driver');

    // scale to drivers timezone
    let timezoneOffsetFromUTC =
      (scaleToDriverTimezone &&
        driver &&
        driver.get('timezoneOffsetFromUTC')) ||
      moment.tz.guess()

    if (scaleToDriverTimezone && props.timezoneOffsetFromUTC) {
      timezoneOffsetFromUTC = props.timezoneOffsetFromUTC;
    }

    const currentDate = moment().tz(timezoneOffsetFromUTC)

    // get the raw start/end time utc
    const startTimeUTC = moment(eldDailyCertification.get('startTimeUTC')).tz(
      timezoneOffsetFromUTC
    )
    const endTimeUTC = moment(startTimeUTC)
      .tz(timezoneOffsetFromUTC)
      .add(1, 'day')

    // determine if our list of events will contain an event at the start of the day and end of the day
    // (hence we dont need to use associatedELDEvents to fill the start and end)
    let dayStartsAtStartTimeUTC = false
    let dayEndsAtEndTimeUTC = false
    const startTimeUTCArr = [
      startTimeUTC.date(),
      startTimeUTC.hours(),
      startTimeUTC.minutes(),
      startTimeUTC.seconds()
    ]
    const endTimeUTCArr = [
      endTimeUTC.date(),
      endTimeUTC.hours(),
      endTimeUTC.minutes(),
      endTimeUTC.seconds()
    ]

    // ...same as above but we adjust end time value for mobile start/end times
    const oneSecondOffEndTimeUTC = moment(endTimeUTC)
      .tz(timezoneOffsetFromUTC)
      .subtract(1, 'millisecond')
    const oneSecondOffEndTimeUTCArr = [
      oneSecondOffEndTimeUTC.date(),
      oneSecondOffEndTimeUTC.hours(),
      oneSecondOffEndTimeUTC.minutes(),
      oneSecondOffEndTimeUTC.seconds()
    ]
    // --

    /*
      chartjs tends to round the start and end of graph to the nearest hour, therefore we need to adjust eventDateTimes for this
      whatta guy...
    */
    const chartTimeOffset = startTimeUTC.minutes() * 60000

    // the duty statuses our graph is concerned with
    const filterableEventTypeCodes = [11, 12, 13, 14, 21, 22, 31, 32]

    const promise = new Promise(resolve => {
      // guarantee the removal of previous datasets
      cjsData.datasets.splice(0)

      // recalculate labels for new start time
      // note that during dst adding an hour to the time dst occurs automatically jumps another hour
      // ex. 1am + 1hr = 2am; 2am + 1hr = 3am. bc of dst: 1am -> 3am -> 4am -> 5am...
      cjsData.labels.splice(0)

      this.state.hasDSTJump = true

      const startOfCurrentDate = moment(startTimeUTC).tz(timezoneOffsetFromUTC).startOf('day');
      const endOfCurrentDate = moment(startTimeUTC).tz(timezoneOffsetFromUTC).endOf('day');

      const isStartOfDateDST = startOfCurrentDate.isDST();
      const isEndOfDateDST = endOfCurrentDate.isDST();

      // Set the number of hours to iterate through for the graph. When we transition from DST to ST (2:00 -> 1:00), we add an additional hour to the graph
      this.state.hasSTJump = isStartOfDateDST && !isEndOfDateDST;
      const hoursToIterate = this.state.hasSTJump ? 26 : 25;

      for (let i = 0; i < hoursToIterate; i++) {
        // iterate every hour from startTimeUTC + drivers timezone offset
        const newDate = moment(startTimeUTC)
        newDate.add(i, 'hour')
        cjsData.labels.push(newDate)
        if (newDate.format('HH') === '02') {
          this.state.hasDSTJump = false
        }
      }
      if (this.state.hasDSTJump) {
        cjsData.labels.splice(-1, 1)
      }

      // filter events based on flags
      let filteredELDEvents = eldEvents.filter(event => {
        const eldEventRecordStatusInt =
          event.eldEventRecordStatusInt || event.get('eldEventRecordStatusInt')
        const eldEventTypeCodeInt =
          event.eldEventTypeCodeInt || event.get('eldEventTypeCodeInt')
        const isGraphableDutyStatus =
          filterableEventTypeCodes.indexOf(eldEventTypeCodeInt) !== -1
        return isGraphableDutyStatus && eldEventRecordStatusInt === 1
      })

      // sort ascending now with the filtered list. add violations/special events to the mix if needed
      if (showHOSViolations) {
        // merge the hosViolationsGraphData with eldEventsGraphData to sort
        const hosViolationsCloned = hosViolations.map(hosViolation => {
          const hosViolationClone = cloneRecord(hosViolation);
          hosViolationClone.set('eventDateTime', hosViolation.get('triggerTime')); // set an eventDateTime so we can use the ELD sorting function
          hosViolationClone.isSpecialEvent = true;
          hosViolationClone.hosViolation = true;
          return hosViolationClone;
        });

        filteredELDEvents = [].concat(filteredELDEvents, hosViolationsCloned);
      }

      ELD.sortELDEvents(filteredELDEvents, 1);

      // Find the first instance of a requested ELD Event (requested through an edit, etc)
      // Once/if such a first instance is found, and all prior events, including the requested event,
      // have the same eventDateTime as the first fake event, then only that requested event should show up
      // as the first event of the day. Remove all fake events that came prior to it
      if ((filteredELDEvents.length > 1) && !filteredELDEvents[0].id && !filteredELDEvents[1].id) {
        const firstFilteredELDEvent = filteredELDEvents[0];
        const firstFilteredELDEventDateTimeValue = moment(firstFilteredELDEvent.get('eventDateTime')).valueOf();
        let spliceCounter = 0; // how many fake inserted events to chop off of filteredELDEvents

        for (let i = 1; i < filteredELDEvents.length; i++) {
          const filteredELDEvent = filteredELDEvents[i];
          const eldEventRecordOriginInt = filteredELDEvent.get('eldEventRecordOriginInt');
          const eldEventRecordOriginatorInt = filteredELDEvent.get('eldEventRecordOriginatorInt');
          const eldEventRecordStatusInt = filteredELDEvent.get('eldEventRecordStatusInt');
          const eldEventDateTimeValue = moment(filteredELDEvent.get('eventDateTime')).valueOf();

          if (
            (eldEventDateTimeValue === firstFilteredELDEventDateTimeValue) &&
            ([2, 3].indexOf(eldEventRecordOriginInt) !== -1) && // edit submitted by driver or authorized user
            (eldEventRecordOriginatorInt === 2) && // edit event and not entry event
            (eldEventRecordStatusInt === 1) // simulated to be active
          ) {
            spliceCounter++; // increment+1 to eliminate all points up to this event
            break;
          } else if (eldEventDateTimeValue === firstFilteredELDEventDateTimeValue) {
            spliceCounter++;
          }
        }

        filteredELDEvents.splice(0, spliceCounter);
      }

      if (showHOSViolations) {
        this.populateSpecialEventDutyStatus(filteredELDEvents);
      }

      // Note: There are functions that read event.eventDateTime as priority over event.get(eventDateTime), if set
      filteredELDEvents = [].concat(filteredELDEvents).map(_eldEvent => {
        const eldEvent = _eldEvent.clone() // cloning replaced id with a localid
        let eventDateTime = moment(eldEvent.get('eventDateTime')).tz(
          timezoneOffsetFromUTC
        )
        const eventDateTimeArr = [
          eventDateTime.date(),
          eventDateTime.hours(),
          eventDateTime.minutes(),
          eventDateTime.seconds()
        ]

        // if its 1 second off the end of day, just simulate to end of day (adjusts for mobile)
        if (
          Helpers.areArraysEqual(oneSecondOffEndTimeUTCArr, eventDateTimeArr)
        ) {
          eldEvent.set('eventDateTime', endTimeUTC.toDate())
          eventDateTime = moment(eldEvent.get('eventDateTime')).tz(
            timezoneOffsetFromUTC
          )
        }
        // ---

        eldEvent.eventDateTime = moment(eventDateTime)
          .subtract(chartTimeOffset, 'millisecond')
          .toDate()

        if (_eldEvent.isSpecialEvent) eldEvent.isSpecialEvent = true;
        if (_eldEvent.hosViolation) eldEvent.hosViolation = true;

        eldEvent.id = _eldEvent.id // goes last to prevent default parse behaviour when assigning id's early
        return eldEvent
      })

      const firstRecordedEvent = filteredELDEvents[0]
      const lastRecordedEvent = filteredELDEvents[filteredELDEvents.length - 1]

      if (firstRecordedEvent) {
        const firstRecordedEventDateTime = moment(
          firstRecordedEvent.get('eventDateTime')
        ).tz(timezoneOffsetFromUTC)
        if (
          Helpers.areArraysEqual(startTimeUTCArr, [
            firstRecordedEventDateTime.date(),
            firstRecordedEventDateTime.hours(),
            firstRecordedEventDateTime.minutes(),
            firstRecordedEventDateTime.seconds()
          ])
        ) {
          dayStartsAtStartTimeUTC = true
        }
      }

      if (lastRecordedEvent) {
        const lastRecordedEventDateTime = moment(
          lastRecordedEvent.get('eventDateTime')
        ).tz(timezoneOffsetFromUTC)
        const lastRecordedEventDateTimeArr = [
          lastRecordedEventDateTime.date(),
          lastRecordedEventDateTime.hours(),
          lastRecordedEventDateTime.minutes(),
          lastRecordedEventDateTime.seconds()
        ]
        if (
          Helpers.areArraysEqual(endTimeUTCArr, lastRecordedEventDateTimeArr) ||
          Helpers.areArraysEqual(
            oneSecondOffEndTimeUTCArr,
            lastRecordedEventDateTimeArr
          )
        ) {
          dayEndsAtEndTimeUTC = true
        }
      }

      let associatedEventDateTime
      const _lastEventBeforeCert = associatedELDEvents[0]
      const _previousDayEvent = associatedELDEvents[1]
      const _nextDayEvent = associatedELDEvents[2] // no longer exists
      const _nextKnownEvent = associatedELDEvents[3] // no longer exists

      // order of operations as decided by discussion:
      // if ((!eldDailyCertification || !eldDailyCertification.id) && _lastEventBeforeCert) {
      //   // if no true daily certification, find last event and continue form there
      //   const noCertPreviousEventStart = _lastEventBeforeCert.clone();
      //   associatedEventDateTimeMs = (new Date(startTimeUTC)).getTime() - chartTimeOffset;
      //   noCertPreviousEventStart.eventDateTime = new Date(associatedEventDateTimeMs);
      //   noCertPreviousEventStart.id = _lastEventBeforeCert.id;
      //   noCertPreviousEventStart.prepended = true;
      //   filteredELDEvents.unshift(noCertPreviousEventStart);
      //
      //   if (currentDate.getTime() >= endTimeUTC.getTime()) {
      //     if (_nextKnownEvent) {
      //       const noCertPreviousEventEnd = _lastEventBeforeCert.clone();
      //       associatedEventDateTimeMs = (new Date(endTimeUTC)).getTime() - chartTimeOffset;
      //       noCertPreviousEventEnd.eventDateTime = new Date(associatedEventDateTimeMs);
      //       noCertPreviousEventEnd.id = _lastEventBeforeCert.id;
      //       noCertPreviousEventEnd.appended = true;
      //       filteredELDEvents.push(noCertPreviousEventEnd);
      //     } else {
      //       const noCertOffDutyEventEnd = _lastEventBeforeCert.clone();
      //       noCertOffDutyEventEnd.set('eldEventTypeCodeInt', 11);
      //       associatedEventDateTimeMs = (new Date(endTimeUTC)).getTime() - chartTimeOffset;
      //       noCertOffDutyEventEnd.eventDateTime = new Date(associatedEventDateTimeMs);
      //       noCertOffDutyEventEnd.id = _lastEventBeforeCert.id;
      //       noCertOffDutyEventEnd.appended = true;
      //       filteredELDEvents.push(noCertOffDutyEventEnd);
      //     }
      //
      //   }
      //
      // }

      // as of 23-Mar-2018, if no daily cert exists then default the day to off-duty (replaces the commented code above)
      if (!eldDailyCertification || !eldDailyCertification.id) {
        const placeholderOffDutyEventStart = Helpers.createTempParseObject(
          'ELDEvent',
          {
            eldEventTypeCodeInt: 11
          }
        )
        let placeholderOffDutyEventDateTime = moment(startTimeUTC)
          .tz(timezoneOffsetFromUTC)
          .subtract(chartTimeOffset, 'millisecond')
        placeholderOffDutyEventStart.eventDateTime = placeholderOffDutyEventDateTime
        placeholderOffDutyEventStart.prepended = true
        filteredELDEvents.unshift(placeholderOffDutyEventStart)

        const placeholderOffDutyEventEnd = Helpers.createTempParseObject(
          'ELDEvent',
          {
            eldEventTypeCodeInt: 11
          }
        )
        placeholderOffDutyEventDateTime = moment(endTimeUTC)
          .tz(timezoneOffsetFromUTC)
          .subtract(chartTimeOffset, 'millisecond')
        placeholderOffDutyEventEnd.eventDateTime = placeholderOffDutyEventDateTime
        placeholderOffDutyEventEnd.appended = true
        filteredELDEvents.push(placeholderOffDutyEventEnd)
      }

      if (
        eldDailyCertification &&
        eldDailyCertification.id &&
        (eldDailyCertification.get('completed') ||
          currentDate.valueOf() >= endTimeUTC.valueOf() ||
          eldDailyCertification.get('certified')) &&
        lastRecordedEvent
      ) {
        const certCompleteCertifiedContinueEvent = lastRecordedEvent.clone()
        associatedEventDateTime = moment(endTimeUTC)
          .tz(timezoneOffsetFromUTC)
          .subtract(chartTimeOffset, 'millisecond')
        certCompleteCertifiedContinueEvent.eventDateTime = associatedEventDateTime
        certCompleteCertifiedContinueEvent.appended = true
        filteredELDEvents.push(certCompleteCertifiedContinueEvent)
      } else if (
        _nextDayEvent &&
        _nextDayEvent.get('eldDailyCertification') &&
        lastRecordedEvent
      ) {
        const nextDayCertifiedContinueEvent = lastRecordedEvent.clone()
        associatedEventDateTime = moment(endTimeUTC)
          .tz(timezoneOffsetFromUTC)
          .subtract(chartTimeOffset, 'millisecond')
        nextDayCertifiedContinueEvent.eventDateTime = associatedEventDateTime
        nextDayCertifiedContinueEvent.appended = true
        filteredELDEvents.push(nextDayCertifiedContinueEvent)
      }
      // else if (_nextDayEvent && (!eldDailyCertification || !eldDailyCertification.get('certified'))) {
      //   const nextDayEvent = _nextDayEvent.clone();
      //   nextDayEvent.id = _nextDayEvent.id;
      //   const nextDayEventDateTime = new Date(dayEnd);
      //   associatedEventDateTimeMs = nextDayEventDateTime.getTime();
      //   associatedEventDateTimeMs -= (chartTimeOffset * 60000);
      //   nextDayEvent.eventDateTime = new Date(associatedEventDateTimeMs);
      //   _eldEvents.push(nextDayEvent);
      // }

      // get last event from previous day and first event of next day and add to our todays events accordingly
      // this operation must be done (repeated) after the above operations to ensure accuracy as
      // componentWillReceiveProps can affect calculations of in-object attributes
      if (eldDailyCertification && eldDailyCertification.id) {
        if (_previousDayEvent) {
          if (filteredELDEvents.length === 0) {
            const previousDayContinueEventEnd = _previousDayEvent.clone()
            let previousDayContinueEventEndDateTime = moment(endTimeUTC).tz(
              timezoneOffsetFromUTC
            )
            if (currentDate.valueOf() < endTimeUTC.valueOf()) {
              previousDayContinueEventEndDateTime = moment(currentDate).tz(
                timezoneOffsetFromUTC
              )
            }
            previousDayContinueEventEndDateTime = previousDayContinueEventEndDateTime.subtract(
              chartTimeOffset,
              'millisecond'
            )
            previousDayContinueEventEnd.eventDateTime = previousDayContinueEventEndDateTime
            previousDayContinueEventEnd.appended = true
            filteredELDEvents.push(previousDayContinueEventEnd)
          }
          // note if there was an event the day prior that leaks over, we need to start it from today's start
          const previousDayBeginEvent = _previousDayEvent.clone()
          associatedEventDateTime = moment(startTimeUTC)
            .tz(timezoneOffsetFromUTC)
            .subtract(chartTimeOffset, 'millisecond')
          previousDayBeginEvent.eventDateTime = associatedEventDateTime
          previousDayBeginEvent.id = _previousDayEvent.id
          previousDayBeginEvent.prepended = true
          filteredELDEvents.unshift(previousDayBeginEvent)
        } else if (firstRecordedEvent) {
          // there was no event the previous day and todays event don't start exactly at daily cert time
          const startDayOffDutyEvent = firstRecordedEvent.clone()
          const startDayOffDutyEventDateTime = moment(startTimeUTC)
            .tz(timezoneOffsetFromUTC)
            .subtract(chartTimeOffset, 'millisecond')
          startDayOffDutyEvent.eventDateTime = startDayOffDutyEventDateTime

          const doesNotStartAtDayStart =
            moment(firstRecordedEvent.get('eventDateTime'))
              .tz(timezoneOffsetFromUTC)
              .valueOf() !== startTimeUTC.valueOf()
          startDayOffDutyEvent.id = `_${firstRecordedEvent.id}`
          startDayOffDutyEvent.prepended = true
          startDayOffDutyEvent.set('eldEventTypeCodeInt', 11)

          if (
            eldDailyCertification &&
            eldDailyCertification.id &&
            doesNotStartAtDayStart
          ) {
            filteredELDEvents.unshift(startDayOffDutyEvent)
          } else if (!eldDailyCertification) {
            filteredELDEvents.unshift(startDayOffDutyEvent)
          }
        }
      }

      if (
        eldDailyCertification &&
        eldDailyCertification.id &&
        filteredELDEvents.length === 0 &&
        _lastEventBeforeCert
      ) {
        const certPreviousEventBeginEvent = _lastEventBeforeCert.clone()
        associatedEventDateTime = moment(startTimeUTC)
          .tz(timezoneOffsetFromUTC)
          .subtract(chartTimeOffset, 'millisecond')
        certPreviousEventBeginEvent.eventDateTime = associatedEventDateTime
        certPreviousEventBeginEvent.id = _lastEventBeforeCert.id
        certPreviousEventBeginEvent.prepended = true
        filteredELDEvents.unshift(certPreviousEventBeginEvent)

        const certPreviousEventEndEvent = _lastEventBeforeCert.clone()
        // default set to end of day
        let certPreviousEventEndEventDateTime = moment(endTimeUTC).tz(
          timezoneOffsetFromUTC
        )
        // see where we leave off of (if day was ended or not)
        if (currentDate.valueOf() < endTimeUTC.valueOf()) {
          certPreviousEventEndEventDateTime = moment(currentDate).tz(
            timezoneOffsetFromUTC
          )
        }
        associatedEventDateTime = certPreviousEventEndEventDateTime.subtract(
          chartTimeOffset,
          'millisecond'
        )
        certPreviousEventEndEvent.eventDateTime = associatedEventDateTime
        certPreviousEventEndEvent.id = _lastEventBeforeCert.id
        certPreviousEventEndEvent.appended = true
        filteredELDEvents.push(certPreviousEventEndEvent)
      } else if (
        eldDailyCertification &&
        eldDailyCertification.id &&
        filteredELDEvents.length === 0 &&
        currentDate.valueOf() > endTimeUTC.valueOf()
      ) {
        const placeholderOffDutyEventStart = Helpers.createTempParseObject(
          'ELDEvent',
          {
            eldEventTypeCodeInt: 11
          }
        )
        let placeholderOffDutyEventDateTime = moment(startTimeUTC)
          .tz(timezoneOffsetFromUTC)
          .subtract(chartTimeOffset, 'millisecond')
        placeholderOffDutyEventStart.eventDateTime = placeholderOffDutyEventDateTime
        placeholderOffDutyEventStart.prepended = true
        filteredELDEvents.unshift(placeholderOffDutyEventStart)

        const placeholderOffDutyEventEnd = Helpers.createTempParseObject(
          'ELDEvent',
          {
            eldEventTypeCodeInt: 11
          }
        )
        placeholderOffDutyEventDateTime = moment(endTimeUTC)
          .tz(timezoneOffsetFromUTC)
          .subtract(chartTimeOffset, 'millisecond')
        placeholderOffDutyEventEnd.eventDateTime = placeholderOffDutyEventDateTime
        placeholderOffDutyEventEnd.appended = true
        filteredELDEvents.push(placeholderOffDutyEventEnd)
      }

      // if our graph naturally contains events from this 'daily cert' which has a start and/or end, we do not need to any form of continuation
      if (dayStartsAtStartTimeUTC) {
        filteredELDEvents = filteredELDEvents.filter(
          eldEvent => !eldEvent.prepended
        )
      }
      if (dayEndsAtEndTimeUTC) {
        filteredELDEvents = filteredELDEvents.filter(
          eldEvent => !eldEvent.appended
        )
      }

      // hack if eldEvents array is crazy large
      if (filteredELDEvents.length > 250) {
        filteredELDEvents = filteredELDEvents.filter((element, index, array) => {
          if (index === (filteredELDEvents.length - 1)) return true;

          if (index > 0) {
            if (array[index - 1].get('eldEventTypeCodeInt') === element.get('eldEventTypeCodeInt')) {
              return false;
            }
          }
          return true;
        });
      }
      // console.log(filteredELDEvents.length, startTimeUTC, currentDate);
      //  console.log('***', filteredELDEvents);

      // create dataset to hold event/violation/etc info
      const datasets = cjsData.datasets;
      datasets.unshift({
        ...cjsEventsDatasetTemplate,
        pointRadius: [],
        pointHitRadius: [],
        pointHoverRadius: [],
        pointBorderColor: [],
        pointBackgroundColor: [],
        data: [],
      });

      let graphData = [];

      // If the events are after currentDate we check if they were autofilled for OFF-DUTY status
      if (filteredELDEvents.length > 0 && startTimeUTC.valueOf() > currentDate.valueOf()) {
        // We are removing the autogenerated times because we want the graph to be blank for uneventful dates in the future
        let filteredELDEventsOfDailyCerts = filteredELDEvents.filter((eldEvent) => getAttribute(eldEvent, 'objectId')).filter((eldEvent) => moment(getAttribute(eldEvent, 'eventDateTime')).tz(timezoneOffsetFromUTC).format("HH:mm") !== "00:00");
        if (props.isELDOverride) {
          filteredELDEventsOfDailyCerts = filteredELDEvents;
        }

        if (filteredELDEventsOfDailyCerts.length === 0) {
          // Empty array -> Blank graph
          graphData = this.addEventGraphDatasets(filteredELDEventsOfDailyCerts);
        } else {
          graphData = this.addEventGraphDatasets(filteredELDEvents);
        }
      } else {
        // If events were in the past we add them to the graph, even if they were autofilled as OFF-DUTY
        graphData = this.addEventGraphDatasets(filteredELDEvents);
      }

      cjsData.datasets[0].data = graphData;

      // get the duration of each line for duty status lines only
      let dutyStatusGraphData = graphData.filter(datum => {
        return !datum.isSpecialEvent;
      });
      let lastSeenDutyStatusPoint;
      dutyStatusGraphData.map((datum, index) => {
        if (!lastSeenDutyStatusPoint) {
          lastSeenDutyStatusPoint = datum;
          return;
        }

        const hasLineStatusChanged = datum.y !== lastSeenDutyStatusPoint.y;
        const isLastPoint = index === (dutyStatusGraphData.length - 1);

        if (hasLineStatusChanged || isLastPoint) {
          datum.durationMillis = moment(datum.x).tz(timezoneOffsetFromUTC).startOf('minute').diff(moment(lastSeenDutyStatusPoint.x).tz(timezoneOffsetFromUTC).startOf('minute'), 'milliseconds');
          lastSeenDutyStatusPoint = datum;
        }
      });

      // console.log(graphData);

      graphData.map(datum => {
        let pointRadius = 0;
        let pointHitRadius = 0;
        let pointHoverRadius = 0;
        let pointBorderColor = '#FFFFFF';
        let pointBackgroundColor = '#FFFFFF';

        if (showHOSViolations && datum.isSpecialEvent && datum.hosViolation) {
          pointRadius = 3;
          pointHitRadius = 1;
          pointHoverRadius = 4;
          pointBorderColor = '#E03857';
          pointBackgroundColor = '#E03857';
        }

        cjsData.datasets[0].pointRadius.push(pointRadius);
        cjsData.datasets[0].pointHitRadius.push(pointHitRadius);
        cjsData.datasets[0].pointHoverRadius.push(pointHoverRadius);
        cjsData.datasets[0].pointBorderColor.push(pointBorderColor);
        cjsData.datasets[0].pointBackgroundColor.push(pointBackgroundColor);
      });

      if (displayHours) {
        const dutyTimes = ELD.getTotalHoursForGraph([graphData.filter(point => !point.isSpecialEvent)])
        cjsOptions.scales.yAxes[1] = {
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: t('TOTAL HOURS'),
          },
          ticks: {
            min: 0,
            max: 8,
            fixedStepSize: 1,
            userCallback(value, index, values) {
              return dutyTimes[value]
            },
            fontColor: '#585858',
            fontSize: 12,
            fontFamily: 'Tahoma',
            fontStyle: 'bold'
          }
        }
      }

      cjsOptions.tooltips = {
        enabled: true,
        // intersect: true,
        mode: 'x-axis',
        displayColors: false,
        custom: function (tooltipModel) {
          // if (!tooltipModel.body || tooltipModel.body.length < 1) {
          //   tooltipModel.caretSize = 0;
          //   tooltipModel.xPadding = 0;
          //   tooltipModel.yPadding = 0;
          //   tooltipModel.cornerRadius = 0;
          //   tooltipModel.width = 0;
          //   tooltipModel.height = 0;
          // }
        },
        filter: function (tooltipItem, data) {
          // only show tooltip for eld event data
          // console.log(tooltipItem);
          // console.log(tooltipItem);
          // console.log(tooltipItem);
          // console.log(data.datasets[0].data);
          return !showHOSViolations;
          const datasetData = data.datasets[0].data;
          console.log(tooltipItem);
          console.log(datasetData[tooltipItem.index]);
          return !datasetData[tooltipItem.index].isSpecialEvent;
        },
        callbacks: {
          title(chartElementDataset) {
            // renames the title of the tooltip (which would normally display the X-Axis value)
            // but in our case, because the chart x-axis time value scales to the nearest hour and we adjust for it,
            // we need to display the adjusted value
            if (chartElementDataset.length === 0) return;

            const chartElementIndex =
              chartElementDataset[chartElementDataset.length - 1].index
            const associatedGraphCoord = graphData[chartElementIndex]
            // recall we adjusted for charttimeoffset previous, so we need to cancel it out
            const eventDateTime = moment(associatedGraphCoord.x)
              .tz(timezoneOffsetFromUTC)
              .add(chartTimeOffset, 'millisecond')
            const xToolTipLabel = eventDateTime.format(
              'dddd DD MMM, YYYY'
            )
            return `${eventDateTime.format('h:mm A')} (${getTimezoneAbbreviation(eventDateTime, timezoneOffsetFromUTC)}) - ${xToolTipLabel}`
          },
          footer(tooltipItems, data) {
            const tooltipItem = tooltipItems[0];
            const datasetData = data.datasets[0].data;
            if (!tooltipItem) return;

            const durationMillis = datasetData[tooltipItem.index].durationMillis;

            if (durationMillis !== undefined) {
              // duty status durations
              let durationString = '';

              durationString = Helpers.msToTimeString(
                durationMillis, 'HH:mm');
              durationString = durationString.split(':');
              const hours = !durationString[1] ? '00' : durationString[0];
              const minutes = !durationString[1] ? durationString[0] : durationString[1];
              durationString = hours + 'H ' + minutes + 'M';
              return `${t('Status Duration:')} ${durationString}`;
            }
          },
          label(chartElement, data) {
            const chartElementIndex = chartElement.index
            const associatedGraphCoord = graphData[chartElementIndex];
            let statusLabel = `${graphCodes[chartElement.yLabel]}`;

            if (associatedGraphCoord.personalConveyance) {
              statusLabel = `${t('PERSONAL USE CMV')}  (${graphCodes[chartElement.yLabel]
                })`;
            } else if (associatedGraphCoord.yardMoves) {
              statusLabel = `${t('YARD MOVES')}  (${graphCodes[chartElement.yLabel]})`;
            }

            let yTooltipLabel = t(`${driverFirstName} changed status to:  ${statusLabel}`) // yes, two spaces in there

            if (outlineEdits && associatedGraphCoord.requestedELDEvent) {
              yTooltipLabel = `${t('EDIT:')}  ${statusLabel}`;
            } else if (
              outlineAutoGeneratedDrivingTimes &&
              associatedGraphCoord.autoGeneratedDrivingTime
            ) {
              yTooltipLabel = `${t('AutoGenerated Driving Time:')}  ${statusLabel}`;
            }

            return yTooltipLabel;
          }
        }
      }

      // declaring the tick fn here will ensure the graph redraws the x-axis with our changes as well as use the correct startTimeUTC
      cjsOptions.scales.xAxes[0].ticks.callback = function (tick, index, ticks) {
        /*
          Each tick is a time value string (ex. '11:30 pm'); ticks is an array of objects { value: UNIX, major: true/false } of the selected date set to said time value
          A tick is what is shown on the xAxis

          if amount of ticks is 25, we know we're showing a full 24hr span. If its 26, we know the graph
          did some rounding (rounds to nearest whole hr:00) so we have to make sure the correct hour:minute is set
        */
        const tickSplit = moment.unix(tick)
          .tz(timezoneOffsetFromUTC)
          .format('H:mm')
          .split(':')
        let hour = tickSplit[0]
        if (hour.length < 2) {
          hour = '0' + hour;
        }
        let minutes = tickSplit[1].substr(0, 2)

        if (ticks.length === 26) {
          minutes = startTimeUTC.minutes()
          minutes < 10 ? (minutes = `0${minutes}`) : minutes
        }
        return `${hour}:${minutes}`
      }

      this.setState(this.state, () => {
        resolve(true)
      })
    })
    return promise
  }

  setChartRef(chart) {
    // we use this to create/use chart functionality that is otherwise buggy with the cjs api/package or to read data
    // if (chart) {
    //   console.log(chart);
    // }
  }

  render() {
    window.Localize.translatePage();
    return (
      <MDBRow className='subTable' style={this.props.style}>
        {Helpers.isSubscribedToModule('eldModule') ? (
          <MDBCol>
            <MDBRow>
              <MDBCol>
                <Line
                  data={this.state.cjsData}
                  width={800}
                  height={200}
                  options={this.state.cjsOptions}
                  ref={chart => {
                    this.setChartRef(chart)
                  }}
                  redraw
                />
              </MDBCol>
            </MDBRow>
            {this.state.hasSTJump && (
              <MDBRow>
                <MDBCol xs="12" lg="5" xl="3" className="offset-lg-7 offset-xl-9 translate-me">
                  <blockquote className='blockquote bq-warning text-left pt-0 translate-me'>
                    <p className='bq-title p-0 title-font-small'>Standard Time Change Notice</p>
                    <p className='p-0 m-0 font-small'>
                      Please note that there are 25 hours in this day due
                      to DST. To reflect this change, an hour will be added on the graph
                    </p>
                  </blockquote>
                </MDBCol>
              </MDBRow>
            )}
            {this.state.hasDSTJump && (
              <MDBRow>
                <MDBCol xs="12" lg="5" xl="3" className="offset-lg-7 offset-xl-9 translate-me">
                  <blockquote className='blockquote bq-warning text-left pt-0 translate-me'>
                    <p className='bq-title p-0 title-font-small'>Daylight Savings Notice</p>
                    <p className='p-0 m-0 font-small'>
                      Please note that there are only 23 hours in this day due
                      to DST. To reflect this change, an hour will be missing on the graph
                    </p>
                  </blockquote>
                </MDBCol>
              </MDBRow>
            )}
          </MDBCol>
        ) : (
          <div className="translate-me">You must have the ELD Package to view the HOS Graph</div>
        )}
      </MDBRow>
    )
  }
}

HOSGraph.propTypes = {
  driver: PropTypes.object, // driver is optional
  eldDailyCertification: PropTypes.object.isRequired,
  eldEvents: PropTypes.array,
  associatedELDEvents: PropTypes.array,
  hosViolations: PropTypes.array,
  showHOSViolations: PropTypes.bool,
  shortDutyStatus: PropTypes.bool, // whether or not to abbreviate duty statuses
  disableRedraw: PropTypes.bool,
  outlineEdits: PropTypes.bool, // give edits a different color
  outlineAutoGeneratedDrivingTimes: PropTypes.bool,
  defaultLineColor: PropTypes.string,
  isELDOverride: PropTypes.bool, // less restrictive behaviour
  style: PropTypes.object
}

export default HOSGraph
