import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// CSAPI Enums
import { PaymentMethod } from 'sb-csapi/dist/enums/Finance/PaymentMethod';

// sbCore Components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Dropdown from 'sbCore/Dropdown/Dropdown';

// Styles
import './style.scss';

/**
 * @description Select a payment method from a dropdown
 * @param {String} [className] - Custom container className
 * @param {Boolean} [warning] - Makes the border yellow
 * @param {Boolean} [error] - Makes the border red
 * @param {Boolean} [success] - Makes the border green
 * @param {Boolean} [autoFocus] - Whether to automatically focus on the autocomplete on load
 * @param {Boolean} [isFocusedDefault] - Whether this component is the first component to be focused on. Determines if we should set isFocused to true when mounting
 * @param {Boolean} [showOnFocus] - Whether to automatically open the dropdown on focus (not implemented)
 * @param {Boolean} [hideLabel] - Hide the label
 * @param {Boolean} [isLoading] - Show a loading skeleton (not implemented)
 * @param {Boolean} [disabled] - Whether to disable the component
 * @param {Function} onSelect - Send the chosen paymentMethod object back up to the parent component
 * @param {Integer} [paymentMethodInt] - The current payment method int selected
 * @returns
 */
function PaymentMethodDropdown({ ...props }) {

  // Map the PaymentMethod object into an array
  const paymentMethods = Object.keys(PaymentMethod).map(key => {
    return PaymentMethod[key];
  });

  // ** useStates ** //
  // for each of this component that exists on the same page, give it unique identifier for specific dom manipulation
  const [identifier] = useState(uniqid());
  const [isFocused, setIsFocused] = useState(props.isFocusedDefault);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  // ** useEffects ** //
  // Update the selected payment method if passed by the parent component
  useEffect(() => {
    if (props.paymentMethodInt || props.paymentMethodInt === 0) {
      const _selectedPaymentMethod = paymentMethods.find((paymentMethod) => {
        return paymentMethod.type === props.paymentMethodInt;
      });

      setSelectedPaymentMethod(_selectedPaymentMethod);
    }
  }, [props.paymentMethodInt]);

  // ** Callback Functions ** //
  // Update the selected payment method state and pass the type int back to the parent component
  function onChange(e) {
    setSelectedPaymentMethod(e.value);
    if (props.onSelect) props.onSelect(e.value.type);
  }

  // determine whether or not this component is focused on, to enable additional functionality
  function toggleFocus() {
    setIsFocused(!isFocused);
  }

  // ** Dropdown Templates ** //
  function itemTemplate(option) {
    return (
      <div> {option.description} </div>
    );
  }

  // ** Misc ** //
  let className = `sbc-payment-method-dropdown ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      {!props.hideLabel && (
        <InputLabel>Payment Method</InputLabel>
      )}
      <Dropdown
        value={selectedPaymentMethod}
        options={paymentMethods}
        optionLabel="description"
        onChange={(e) => onChange(e)}
        onFocus={(e) => toggleFocus(e)}
        onBlur={() => toggleFocus()}
        placeholder="Select a Payment Method"
        itemTemplate={itemTemplate}
        autoFocus={props.autoFocus}
        warning={props.warning}
        error={props.error}
        success={props.success}
        disabled={props.disabled}
      />
    </div>
  );
}

export default PaymentMethodDropdown;
