import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import AddEditButton from 'components/AddEditModal/container/AddEditButton';
// Actions
import { addVendorToState } from 'actions/Vendor';
import * as ActionConstants from 'actions/ActionConstants';
import * as Setters from 'api/Setters';

// Context
import StoreContext from 'contexts/StoreContext';

function getInitialState() {
  const initialState = {};
  initialState.addVendorFormFields = [
    [{ label: 'Location Name', value: '', type: 'text', required: true, property: 'name' }],
    [{ label: 'Address', required: true, value: { city: '', stateProvince: '', zipPostal: '', country: '', geoPoint: undefined, address: '' }, type: 'address', property: 'address' }],
    [{ label: 'Notes', value: '', type: 'textarea', property: 'notes' }],
  ];
  return initialState;
}

class AddNewVendorButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.addVendor = this.addVendor.bind(this);
    this.refreshState = this.refreshState.bind(this);
  }

  addVendor(formValues) {
    Setters.createNewAddress(formValues.address).then((addressParseObj) => {
      const vendorValuesObject = { ...formValues };
      vendorValuesObject.address = addressParseObj;
      vendorValuesObject.enabled = true;
      Setters.createNewVendor(vendorValuesObject).then((vendorParseObj) => {
        addVendorToState(vendorParseObj);
        this.setState(getInitialState());
      }, error => { console.error(error); });
    });
  }

  refreshState() {
    this.setState(getInitialState());
  }

  render() {
    return (
      <div className="inline">
        <AddEditButton refreshState={this.refreshState} handleAddEdit={this.addVendor} title="Add New Location" formTemplate={this.state.addVendorFormFields} addingInProgress={this.props.Vendor.status === ActionConstants.ADD_VEHICLE_INPROGRESS} />
      </div>
    );
  }
}

AddNewVendorButton.propTypes = {
  Vendor: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Vendor } = state;
  return {
    Vendor,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(AddNewVendorButton);

