export default class Address {
  /**
   * 
   * @param {string} objectId
   * @param {string} address
   * @param {number} latitude
   * @param {number} longitude
   * @param {string} zip 
   * @param {string} city 
   * @param {string} state 
   * @param {string} country 
   * @param {string} name 
   */
  constructor(objectId, address, latitude, longitude, zip, city, state, country, name) {
    this.objectId = objectId;
    this.address = address;
    this.latitude = latitude && !isNaN(latitude) ? parseFloat(latitude) : undefined;
    this.longitude = longitude && !isNaN(longitude) ? parseFloat(longitude) : undefined;
    this.zipPostal = zip;
    this.city = city;
    this.stateProvince = state;
    this.country = country;
    this.name = name;
  }
}