import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// api
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getDispatchOrganizations } from 'api/Dispatch/DispatchOrganization';

// sbobjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// enums
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';
import { Organization, OrganizationName } from 'sb-csapi/dist/enums/Dispatch/Organization';

// sbcore components
import Autocomplete from 'sbCore/Autocomplete/Autocomplete';

/**
 * @description Retrieve DispatchOrganizations given a search term
 * @param {String} className - Custom container className
 * @param {Object} style - Custom inline styles
 * @param {Int} type - The type of organization. If provided, specifically limits queries to that type only. sappy enums/Dispatch/Organization
 * @param {bool} warning - Makes the border yellow
 * @param {bool} autoFocus - Whether to automatically focus on the autocomplete on load
 * @param {bool} disabled - Whether disable the component
 * @param {Function} onSelectDispatchOrganization - A callback function that returns the selected dispatch organization
 * @param {Function} onToggleEditDispatchOrganization - A callback function that toggles open the edit dispatch organization modal
 * @returns
 */
export default function DispatchOrganizationAutocompleteInput({ ...props }) {

  const [identifier] = useState(uniqid()); // for each of this component that exists on the same page, give it unique identifier for specific dom manipulation
  const [selectedDispatchOrganization, setSelectedDispatchOrganization] = useState(undefined); // the selected DispatchOrganization record
  const [dispatchOrganizationObjs, setDispatchOrganizationObjs] = useState([]); // our js object version of DispatchOrganization records
  const [searchTerm, setSearchTerm] = useState(''); // what the user types in to search
  const [hasError, setHasError] = useState(false); // if there is an error with the input

  // ** Hooks Section ** //
  // I define this callback for multiple useEffects. because multiple useEffects use the same fetching logic, just triggered at different at points
  // with query restrictions adjusted
  const useEffectGetDispatchOrganizationsCallback = () => {
    /*
      Logic Summary:
        - Fetch a pre-loaded list of organizations
        - If the user enters a searchTerm, see if it exists within the preloaded results for the user to quickly select. Meanwhile, concurrently run
          a query matching the searchTerm to fetch additional results that may be outside of the preloaded results
    */

    // Begin preloading. First clear any existing errors from the prior search
    setHasError(false);

    // The autocomplete component has a weird functionality where the searchTerm (or value) is a string, but when you select
    // a dropdown item, it becomes the object that the user selected. so, we add a handler here specifying that if searchTerm is an object
    // we just set it as the selected selectedDispatchOrganization and that's it
    if ((typeof searchTerm) === 'object') {
      return setSelectedDispatchOrganization(searchTerm.dispatchOrganization);
    }

    const searchTermTrimmed = (searchTerm || '').trim();

    // quickly filter based on preloaded and loaded results and move on to querying more results
    let _dispatchOrganizationObjs = [...dispatchOrganizationObjs].filter(dispatchOrganizationObj => {
      dispatchOrganizationObj.organizationName.includes(searchTermTrimmed);
    });

    // update the list of results based on the filter above
    setDispatchOrganizationObjs(_dispatchOrganizationObjs);

    // start the additional querying
    let didCancel = false;

    let filters = [
      new Filter(QueryRestriction.LIMIT, undefined, 120),
    ];

    // add filter depending on type
    if (props.type === Organization.CARRIER) {
      filters.push(new Filter(QueryRestriction.EQUAL_TO, 'isCarrier', true));
    } else if (props.type === Organization.CUSTOMER) {
      filters.push(new Filter(QueryRestriction.EQUAL_TO, 'isCustomer', true));
    } else if (props.type === Organization.SHIPPER) {
      filters.push(new Filter(QueryRestriction.EQUAL_TO, 'isShipper', true));
    } else if (props.type === Organization.CONSIGNEE) {
      filters.push(new Filter(QueryRestriction.EQUAL_TO, 'isConsignee', true));
    }

    // if a search term exists, append query search term matching
    if (searchTermTrimmed.length > 0) {
      filters.push(new Filter(QueryRestriction.LIMIT, undefined, 15));
      filters.push(new Filter(QueryRestriction.MATCHES, 'organizationId', searchTermTrimmed));
    }

    async function _getDispatchOrganizations() {
      const { dispatchOrganizations } = await getDispatchOrganizations(
        undefined,  // options - default
        undefined,  // companyobjectid - default
        false,      // include child company results
        filters,
        undefined,  // sort - default
        ['address'],  // includes
        undefined,  // selects
        false       // query all
      );

      if (!didCancel) {
        // Given the dispatchOrganization records, map them to js objects to pass into UI logic
        _dispatchOrganizationObjs = dispatchOrganizations.map(dispatchOrganization => {
          return {
            organizationName: getAttribute(dispatchOrganization, 'organizationName'),
            organizationId: getAttribute(dispatchOrganization, 'organizationId'),
            dispatchOrganization,
          };
        });

        if (!_dispatchOrganizationObjs.length && searchTermTrimmed) setHasError(true);
        setDispatchOrganizationObjs(_dispatchOrganizationObjs);
      }
    }

    _getDispatchOrganizations();

    return () => { didCancel = true; };
  };

  useEffect(useEffectGetDispatchOrganizationsCallback, []);
  useEffect(useEffectGetDispatchOrganizationsCallback, [searchTerm]);

  useEffect(() => {
    if (searchTerm && !selectedDispatchOrganization) {
      setHasError(true);
    }

    if (props.onSelectDispatchOrganization) {
      props.onSelectDispatchOrganization(selectedDispatchOrganization);
    }
  }, [selectedDispatchOrganization]);


  // ** Components Section ** //
  // template for a list item in the autocomplete
  const itemTemplate = (item) => {
    return (
      <div className="organization-item">
        <div>{item.organizationName}{ item.organizationId ? <span className="organization-id"> ({ item.organizationId })</span> : '' }</div>
      </div>
    );
  };

  const inputEl = document.querySelector(`.dispatch-organization-autocomplete-input.${identifier} .p-inputtext.p-component.p-autocomplete-input.p-autocomplete-dd-input`);
  const inputButtonEl = document.querySelector(`.dispatch-organization-autocomplete-input.${identifier} button.p-button`); // the autocomplete dropdown button

  if (inputEl) {
    inputEl.autocomplete = 'disabled'; // disable the browser's default autocomplete for inputs
  }

  function triggerAutocompleteOnClick() {
    // if the user focuses on the input, open the dropdown by simulating click of button
    if (inputButtonEl) {
      inputButtonEl.click();
    }
  }

  function triggerAutocompleteOnKeyPress(e) {
    // if the input is being focused on
    if (inputButtonEl) {
      const code = (e.keyCode ? e.keyCode : e.which);
      const dropdownPanelEl = document.querySelector(`.dispatch-organization-dropdown-panel.${identifier}`); // Dropdown panel element
      if (code === 13) { // Enter
        if (dropdownPanelEl && dispatchOrganizationObjs.length) { // If the dropdown is open and not empty
          setSelectedDispatchOrganization(dispatchOrganizationObjs[0].dispatchOrganization); // Select the first item in dropdown
          return;
        }

        if (hasError || (dropdownPanelEl && dispatchOrganizationObjs.length === 0)) { // If there's no search result
          props.onToggleEditDispatchOrganization();
          return;
        }

        inputButtonEl.click();
      }
      // else if (code === 39) { // Down arrow -- only available onkeydown, which isn't implemented yet
      //   inputButtonEl.click();
      // }
    }
  }

  // ** Misc ** //
  let className = `dispatch-organization-autocomplete-input ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  let placeholder = 'Organization name';
  if (props.type === Organization.CARRIER) {
    placeholder = `${OrganizationName.CARRIER} name`;
  } else if (props.type === Organization.CUSTOMER) {
    placeholder = `${OrganizationName.CUSTOMER} name`;
  } else if (props.type === Organization.SHIPPER) {
    placeholder = `${OrganizationName.SHIPPER} name`;
  } else if (props.type === Organization.CONSIGNEE) {
    placeholder = `${OrganizationName.CONSIGNEE} name`;
  }

  // if the input is not being focused on (which is for some reason dictated by the button's focus), but the user left their search term without selecting a suggestion for the searchTerm
  const isNotFocusedWithText = inputButtonEl && searchTerm && ((typeof searchTerm) !== 'object') && (document.activeElement !== inputButtonEl);
  const _hasError = isNotFocusedWithText || hasError;

  return (
    <div className={className} style={{ ...props.style }}>
      <Autocomplete
        placeholder={placeholder}
        dropdown
        field="organizationName"
        value={searchTerm}
        suggestions={dispatchOrganizationObjs}
        forceSelection={false}
        itemTemplate={itemTemplate}
        autoFocus={props.autoFocus}
        warning={props.warning}
        error={_hasError}
        completeMethod={() => setDispatchOrganizationObjs([...dispatchOrganizationObjs])}
        onChange={(e) => setSearchTerm(e.value)}
        onClick={(e) => triggerAutocompleteOnClick()}
        onKeyPress={(e) => triggerAutocompleteOnKeyPress(e)}
        disabled={props.disabled}
        panelClassName={`dispatch-organization-dropdown-panel ${identifier}`}
      />
    </div>
  );
}
