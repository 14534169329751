import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { getQueryParameter } from 'api/URL';

// API
import {
  getAttribute,
  getObjectById
} from 'api/Parse';

// Components
import BasicVehicleMap from 'components/Map.old/container/BasicVehicleMap';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './ShareLayout.module.scss';

// Assets
const colourLogo = require('assets/images/colour-logo-and-text.png');
const whiteLogo = require('assets/images/white-logo.png');

class ShareLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shareTypes: {
        'DEFAULT': 0,
        'DISPATCH_TRANSFER': 1,
      },
    };
    // this.getInfo = this.getInfo.bind(this);
    // this.handlePassword = this.handlePassword.bind(this);
    this.refreshMapInfoTimer = null;
    this.updateAssets = this.updateAssets.bind(this);
  }

  componentDidMount() {
    this.updateAssets();
    const refreshMapInfoTimer = setInterval(() => {
      this.updateAssets();
    }, 30000); // refresh every 30 seconds 30000
    this.refreshMapInfoTimer = refreshMapInfoTimer;
  }

  componentWillUnmount() {
    clearTimeout(this.refreshMapInfoTimer);
  }

  updateAssets() {
    const { props, state } = this;
    if (props.shareType === state.shareTypes.DEFAULT) {
      const authCode = getQueryParameter(this.props.location.search, 'auth');
      const id = getQueryParameter(this.props.location.search, 'id');
      const s = getQueryParameter(this.props.location.search, 's');
      const type = getQueryParameter(this.props.location.search, 'type');
      const distanceUnit = getQueryParameter(this.props.location.search, 'distanceUnit');
      let locationDataType;
      if (type === 'asset') locationDataType = 'asset';
      else locationDataType = 'VehicleLocation';
      Parse.Cloud.run('getSharedLocationPreviousLocationArr', { authCode, assetObjectId: id, type: locationDataType }).then(async (shareLocationDataObj) => {
        const belongsToCompany = shareLocationDataObj.asset && getAttribute(shareLocationDataObj.asset, 'belongsToCompany');
        let companyModule = getAttribute(belongsToCompany, 'companyModule');
        this.setState({
          ...this.state,
          asset: shareLocationDataObj.asset,
          locationArr: shareLocationDataObj.locationArr.map((locationObj) => locationObj && (locationObj.location || locationObj.get('location'))),
          locationDataObjArr: shareLocationDataObj.locationDataObjArr,
          company: belongsToCompany,
          companyModule,
          locationDataType,
          distanceUnit,
          destinationObj: {
            location: shareLocationDataObj.destination,
            name: shareLocationDataObj.destinationName,
          },
        });
      }, (error) => {
        clearInterval(this.refreshMapInfoTimer);
        this.setState({ ...this.state, error: error.message });
      });
    }
  }

  render() {
    const { props, state } = this;

    const asset = this.state.asset;
    const belongsToCompany = getAttribute(this.state.asset, 'belongsToCompany', true);
    const companyModule = state.companyModule;

    let companyName = getAttribute(belongsToCompany, 'name', true);

    // if the company wants to hide their name from the title, then use Unit ID instead
    const hideCompanyNameFromShareLocation = getAttribute(companyModule, 'hideCompanyNameFromShareLocation', true);
    if (hideCompanyNameFromShareLocation) {
      const unitId = getAttribute(asset, 'unitId', true);
      companyName = `Tracking Unit ${unitId}`;
    }

    return (
      <div className={styles.layout}>
        <div className={(this.state.asset) ? `${styles.background} ${styles.hide}` : styles.background}>
          <div className={styles.content}>
            <img className={styles.logo} role="presentation" src={colourLogo} />
            <div className={styles.inputContainer}>
              {!this.state.error &&
                <LoadingIcon />
              }
              <div className={styles.errorMessage}>{this.state.error}</div>
            </div>
          </div>
        </div>

        <div className={styles.shareView}>
          <div className={(this.state.asset) ? '' : styles.hide}>
            <div className={styles.header}>
              <table><tbody><tr>
                <td>
                  <div className={styles.company}>
                    {companyName}
                  </div>
                </td>
                <td>
                  <div className={styles.companyLogo}>
                    {!hideCompanyNameFromShareLocation && this.state.asset && this.state.asset.get('belongsToCompany').get('logo') &&
                      <img role="presentation" src={this.state.asset.get('belongsToCompany').get('logo').url()} />
                    }
                  </div>
                </td>
                <td className="hide-mobile">
                  <div className={`${styles.poweredBy}`}>Location Share Powered By &nbsp;&nbsp;<img height="30em" role="presentation" src={whiteLogo} /></div>
                </td>
              </tr></tbody></table>
            </div>
            <div className={styles.map}>
              {props.shareType === state.shareTypes.DEFAULT &&
                (
                  <BasicVehicleMap
                    asset={this.state.asset}
                    locationDataType={this.state.locationDataType}
                    locationArr={this.state.locationArr}
                    locationDataObjArr={this.state.locationDataObjArr}
                    destinationObj={this.state.destinationObj}
                    distanceUnit={this.state.distanceUnit}
                  />
                )
              }
              {props.shareType === state.shareTypes.DISPATCH_TRANSFER &&
                <div>suh</div>
              }
            </div>

            <div className={`${styles.footer}`}>
              <table><tbody><tr>
                <td>
                  <div>Location Share Powered By &nbsp;&nbsp;<img height="22.5em" role="presentation" src={whiteLogo} /></div>
                </td>
              </tr></tbody></table>
            </div>

          </div>
        </div>
      </div>
    );
  }
}

ShareLayout.defaultProps = {
  shareType: 0,
};
ShareLayout.propTypes = {
  location: PropTypes.object.isRequired,
  shareType: PropTypes.number,
};

export default ShareLayout;
