import React, { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';

// components
import DispatchShipmentTimelineCard from 'components/Dispatch/DispatchShipmentTimeline/DispatchShipmentTimelineCard';

// sbCore
import Timeline from 'sbCore/Timeline/Timeline';
import Card from 'sbCore/Card/Card';
import Button from 'sbCore/Button/Button';
import InputText from 'sbCore/InputText/InputText';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Checkbox from 'sbCore/Checkbox/Checkbox';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import OverlayPanel from 'sbCore/OverlayPanel/OverlayPanel';
import ConfirmDialog from 'sbCore/ConfirmDialog/ConfirmDialog';

import { Menu } from 'primereact/menu'; // TODO: add speeddial to sbCore
import { Menubar } from 'primereact/menubar';
import { TabView, TabPanel } from 'primereact/tabview';

// API
import { getPDispatcherPropertyFromState } from 'api/Getters';

// sb-csapi
import {
  getAttribute,
  addRecord,
  getCurrentUserCompanyObjectId,
  getRecordByObjectId,
  getCurrentUserSessionToken,
} from 'sb-csapi/dist/AAPI';
import Filter from 'sb-csapi/dist/sbObjects/Filter';
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// sbObjects
import DispatchTransfer from 'sbObjects/DispatchTransfer';

// api
import {
  getDispatchTransfers,
  addDispatchTransfer,
  updateDispatchTransfer,
  updateSameRouteNameDispatchTransfers,
  deleteDispatchTransfer,
} from 'api/Dispatch/DispatchTransfer';

function DispatchShipmentTimeline({ ...props }) {
  // dispatch transfers = all the transfers in a shipment, parse objects
  const [dispatchTransfers, setDispatchTransfers] = useState([]);
  const [shippingDocumentNumber, setShippingDocumentNumber] = useState(undefined);
  // route = grouped up dispatch transfers within a shipment, parse objects
  const [selectedRoute, setSelectedRoute] = useState([]);
  // dispatch transfer = an individual point in a route, object
  const [dispatchTransfer, setDispatchTransfer] = useState();
  const [filteredActionMenu, setFilteredActionMenu] = useState();
  const [menuBarItems, setMenuBarItems] = useState();
  const [refreshState, setRefreshState] = useState(false);
  const [tabMenuIndex, setTabMenuIndex] = useState(0);
  // Confirm dialogs
  const [isRenameDialogVisible, setIsRenameDialogVisible] = useState(false);
  const [isRouteDeleteDialogVisible, setisRouteDeleteDialogVisible] = useState(false);
  const [isPointDeleteDialogVisible, setIsPointDeleteDiablogVisible] = useState(false);

  const overlayPanelRef = useRef(null);

  // Color and icon used for the timeline marker
  const transferTypes = {
    pickup: {
      icon: 'file_upload',
      color: '#00A3A3',
    },
    dropoff: {
      icon: 'file_download',
      color: '#2A5469',
    },
    'transfer point': {
      icon: 'commit',
      color: '#5D879C',
    },
  };

  // Menu items used on the left of timeline
  // overlayPanelRef.current.toggle(e) is used to close the menu after an option is selected
  const actionMenuItems = [
    {
      label: 'Add a point below',
      icon: 'pi pi-fw pi-plus',
      command: (e) => {
        overlayPanelRef.current.toggle(e);
        _addDispatchTransfer(dispatchTransfer.routeName);
      },
    },
    {
      label: 'Change to dropoff',
      icon: (options) => (
        <span {...options.iconProps} className="material-icons pr-1">
          file_download
        </span>
      ),
      command: (e) => {
        overlayPanelRef.current.toggle(e);
        updateDispatchTransferType(dispatchTransfer, 'dropoff');
      },
    },
    {
      label: 'Change to pickup',
      icon: (options) => (
        <span {...options.iconProps} className="material-icons pr-1">
          file_upload
        </span>
      ),
      command: (e) => {
        overlayPanelRef.current.toggle(e);
        updateDispatchTransferType(dispatchTransfer, 'pickup');
      },
    },
    {
      label: 'Change to transfer point',
      icon: (options) => (
        <span {...options.iconProps} className="material-icons pr-1">
          commit
        </span>
      ),
      command: (e) => {
        overlayPanelRef.current.toggle(e);
        updateDispatchTransferType(dispatchTransfer, 'transfer point');
      },
    },
    // {
    //   label: 'Split into pickup/dropoff',
    //   icon: (options) => (
    //     <span {...options.iconProps} className="material-icons pr-1">
    //       splitscreen
    //     </span>
    //   ),
    //   command: (e) => {
    //     splitTransferPoints()
    //   }
    // },
    {
      label: 'Delete',
      icon: 'pi pi-fw pi-trash',
      command: (e) => {
        overlayPanelRef.current.toggle(e);
        setIsPointDeleteDiablogVisible(true);
      },
    },
  ];

  /* -------------------------------- useEffect ------------------------------- */
  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      // Sort dispatch transfers by asc createdAt, used to sort the tabs
      _getDispatchTransfers(undefined).then((dispatchTransfersResult) => {
        dispatchTransfersResult.sort((a, b) => {
          const routeNameA = getAttribute(a, 'createdAt', true);
          const routeNameB = getAttribute(b, 'createdAt', true);

          if (routeNameA < routeNameB) return -1;
          if (routeNameA > routeNameB) return 1;
          return 0;
        });
        setDispatchTransfers(dispatchTransfersResult);
      });
    }

    return () => {
      didCancel = true;
    };
  }, [refreshState]);

  // Update the action menu items
  useEffect(() => {
    let filteredItems = actionMenuItems;
    if (dispatchTransfer?.type) {
      filteredItems = actionMenuItems.filter((item) => !item.label.includes(dispatchTransfer.type));
    }
    setFilteredActionMenu(<Menu model={filteredItems} />);
  }, [dispatchTransfer]);

  // Update menu bar with unique route names from dispatchTransfers
  useEffect(() => {
    let didCancel = false;

    let menuItems = [];
    if (!dispatchTransfers) return;
    // update menu bar items when dispatch transfer record updates
    // menu bar is where user selects/renames different routes
    else {
      const uniqueRouteNames = [];
      for (let i = 0; i < dispatchTransfers.length; i++) {
        const routeName = getAttribute(dispatchTransfers[i], 'routeName', true);
        if (!uniqueRouteNames.includes(routeName)) {
          uniqueRouteNames.push(routeName);
          menuItems.push({
            label: routeName,
            delete: async (e) => {
              try {
                await _removeDispatchTransfer(routeName, undefined);
              } catch (err) {
                console.log(err);
              }
              setRefreshState(!refreshState);
            },
            select: (e) => {
              if (!didCancel) filterTransfersByRouteName(routeName);
            },
            closable: true,
          });
        }
      }
      menuItems.push({
        label: 'Add Route',
        select: (e) => {
          _addDispatchTransfer();
        },
        closable: false,
      });

      if (!didCancel) {
        setMenuBarItems(menuItems);
      }
    }

    // Default the selected route to the first route name in the menu bar
    if (!selectedRoute && dispatchTransfers[0])
      filterTransfersByRouteName(getAttribute(dispatchTransfers[0], 'routeName', true));

    // Update selectedRoute if one exists and dispatchTransfers updates
    // Used for when a new point is added to a route and selected route needs an update
    if (selectedRoute) filterTransfersByRouteName(getAttribute(selectedRoute[0], 'routeName', true));

    return () => {
      didCancel = true;
    };
  }, [dispatchTransfers, refreshState]);

  // useEffect(() => {
  //   console.log('selectedRoute', selectedRoute);
  // }, [selectedRoute]);

  // useEffect(() => {
  //   console.log('dispatchTransfers', dispatchTransfers);
  // }, [dispatchTransfers]);

  // useEffect(() => {
  //   console.log(tabMenuIndex);
  // }, [tabMenuIndex]);

  /* --------------------------------- Helpers -------------------------------- */
  async function _getDispatchTransfers(filter) {
    const dispatchTransfersResult = await getDispatchTransfers(
      undefined, // options - default
      props.dispatchJobObjectId, // dispatchJobObjectId - default
      filter || undefined, // filters
      undefined, // sort - default
      [
        'pickupDispatchAction',
        'pickupDispatchAction.address',
        'dropoffDispatchAction',
        'dropoffDispatchAction.address',
        'shipperDispatchOrganization',
        'shipperDispatchOrganization.address',
        'consigneeDispatchOrganization',
        'consigneeDispatchOrganization.address',
        'shipperAddress',
        'consigneeAddress',
        'shipmentReferenceNumber',
      ], // includes
      undefined, // selects
      undefined, // page
      undefined, // limit
      true // query all
    );

    const totalDispatchTransfersCount = dispatchTransfersResult.totalDispatchTransfersCount;
    const dispatchTransfersArray = dispatchTransfersResult.dispatchTransfers;

    const dispatchJob = getAttribute(dispatchTransfersArray[0], 'dispatchJob', true);

    // Check to see if any of the transfer points has missing values
    // Old dispatch transfers and those ones that are created outside of this component will have missing values that we need for this component, i.e. routeName, shipperOrganization, consigneeOrganization, pickupDateTime, dropoffDateTime
    const renamedDispatchTransfersArray = dispatchTransfersArray.map((dispatchTransfer) => {
      let keyValueObj = {};
      if (!getAttribute(dispatchTransfer, 'routeName', true)) {
        keyValueObj.routeName = createDefaultRouteName(dispatchJob, dispatchTransfersArray);
      }
      // Since dropoffDateTime and pickupDateTime is what we are using to check if a point is pick up or dropoff
      // We set dropoffDateTime to now if neither of them are set (This is mostly used for older records since new ones should have either one of them set)
      if (
        !getAttribute(dispatchTransfer, 'dropoffDateTime', true) &&
        !getAttribute(dispatchTransfer, 'pickupDateTime', true)
      ) {
        keyValueObj.dropoffDateTime = moment().toDate();
      }
      if (Object.keys(keyValueObj).length !== 0) {
        // console.log('keyValueObj', keyValueObj);
        updateDispatchTransfer(dispatchTransfer, undefined, keyValueObj, true).then((updatedDispatchTransfer) => {
          return updatedDispatchTransfer;
        });
      }
      return dispatchTransfer;
    });

    return renamedDispatchTransfersArray;
  }

  function parseDispatchTransfer(dispatchTransfer) {
    // shipper
    const shipperDispatchOrganization = getAttribute(dispatchTransfer, 'shipperDispatchOrganization', true);
    const pickupDateTime = getAttribute(dispatchTransfer, 'pickupDateTime', true);
    const pickupAppointmentStatus = getAttribute(dispatchTransfer, 'pickupAppointmentStatus', true);
    const isShipper = pickupDateTime ? true : false;
    // consignee
    const consigneeDispatchOrganization = getAttribute(dispatchTransfer, 'consigneeDispatchOrganization', true);
    const dropoffDateTime = getAttribute(dispatchTransfer, 'dropoffDateTime', true);
    const dropoffAppointmentStatus = getAttribute(dispatchTransfer, 'dropoffAppointmentStatus', true);
    const isConsignee = dropoffDateTime ? true : false;

    // Using shipperDispatchOrganization and consigneeDispatchOrganization to determine the type of a transfer
    let type;
    if (isShipper && isConsignee) {
      type = 'transfer point';
    } else {
      type = isShipper ? 'pickup' : 'dropoff';
    }

    return {
      type,
      objectId: getAttribute(dispatchTransfer, 'objectId', true),
      shipperDispatchOrganization,
      pickupDateTime,
      pickupAppointmentStatus,
      consigneeDispatchOrganization,
      dropoffDateTime,
      dropoffAppointmentStatus,
      dispatchOrganizationContact: getAttribute(dispatchTransfer, 'dispatchOrganizationContact', true),
      timezone: getAttribute(dispatchTransfer, 'timezoneOffsetFromUTC', true),
      internal: getAttribute(dispatchTransfer, 'internal', true),
      routeName: getAttribute(dispatchTransfer, 'routeName', true),
    };
  }

  // filter by route name and sort the points by datetime
  function filterTransfersByRouteName(routeName) {
    const filteredDispatchTransfers = dispatchTransfers.filter(
      (dispatchTransfer) => getAttribute(dispatchTransfer, 'routeName', true) === routeName
    );
    const sortedDispatchTransfers = filteredDispatchTransfers.sort((a, b) => {
      const dateTimeA = getAttribute(a, 'pickupDateTime', true) || getAttribute(b, 'dropoffDateTime', true);
      const dateTimeB = getAttribute(b, 'pickupDateTime', true) || getAttribute(b, 'dropoffDateTime', true);

      return dateTimeA - dateTimeB;
    });
    const _shippingDocumentNumber = getAttribute(sortedDispatchTransfers[0], 'shippingDocumentNumber', true);
    setShippingDocumentNumber(_shippingDocumentNumber);
    setSelectedRoute(sortedDispatchTransfers);
  }

  // works but needs to find a better way
  async function updateDispatchTransferType(_dispatchTransfer, type) {
    const selectedDateTime = _dispatchTransfer.dropoffDateTime || _dispatchTransfer.pickupDateTime;
    const selectedOrganization =
      _dispatchTransfer.shipperDispatchOrganization || _dispatchTransfer.consigneeDispatchOrganization;
    let keyValueObj = {};
    if (type === 'dropoff')
      keyValueObj = {
        dropoffDateTime: selectedDateTime,
        pickupDateTime: undefined,
        consigneeDispatchOrganization: selectedOrganization,
        shipperDispatchOrganization: undefined,
      };
    if (type === 'pickup')
      keyValueObj = {
        pickupDateTime: selectedDateTime,
        dropoffDateTime: undefined,
        shipperDispatchOrganization: selectedOrganization,
        consigneeDispatchOrganization: undefined,
      };
    if (type === 'transfer point') {
      keyValueObj = {
        pickupDateTime: selectedDateTime,
        dropoffDateTime: selectedDateTime,
        shipperDispatchOrganization: selectedOrganization,
        consigneeDispatchOrganization: selectedOrganization,
      };
    }

    try {
      const updatedDispatchTransfer = await updateDispatchTransfer(
        undefined,
        _dispatchTransfer.objectId,
        keyValueObj,
        true
      );
      if (updatedDispatchTransfer) setRefreshState(!refreshState);
    } catch (err) {
      console.log(err);
    }
  }

  // provide routeName if adding to an exsting route, no routeName means creating a new route
  async function _addDispatchTransfer(routeName) {
    const dispatchJob = getAttribute(dispatchTransfers[0], 'dispatchJob', true);

    let _routeName = routeName;
    // dateTime for the new dispatch transfer should be the same as the currently chosen dispatch transfer
    let dateTime = dispatchTransfer.pickupDateTime || dispatchTransfer.dropoffDateTime;
    // if creating a new route, create a defualt route Name as well as setting the dateTime for the new point to now
    if (!_routeName) {
      _routeName = createDefaultRouteName(dispatchJob, dispatchTransfers);
      dateTime = moment().toDate();
    }

    let dispatchTransferObject = new DispatchTransfer(
      undefined, // object ID
      dispatchJob, // dispatch job
      undefined, // dispatch item ID
      undefined, // status
      undefined, // shipper organization
      undefined, // consginee Organization
      dateTime, // pick up datetime
      undefined, // drop off datetime
      0, // pickup appointment status
      0, // dropoff appointment status
      undefined, // shipper address
      undefined, // consignee address
      false, // hide from customer/internal
      undefined, // transfer type
      getPDispatcherPropertyFromState('timezoneOffsetFromUTC') || 'America/Vancouver' // timezone
    );
    dispatchTransferObject = { ...dispatchTransferObject, routeName: _routeName };
    try {
      const newDispatchTransfer = await addDispatchTransfer(dispatchTransferObject);
      setRefreshState(!refreshState);
    } catch (err) {
      console.log(err);
    }

    return;
  }

  // Pass in routeName to delete all transfers in a Route
  // Pass in dispatchTransfer parse object to delete a single tranfer
  async function _removeDispatchTransfer(routeName, dispatchTransfer) {
    let dispatchTransfersToRemove = [];
    if (routeName) {
      const filteredDispatchTransfers = dispatchTransfers.filter(
        (_dispatchTransfer) => getAttribute(_dispatchTransfer, 'routeName', true) === routeName
      );
      dispatchTransfersToRemove = filteredDispatchTransfers;
    }
    if (dispatchTransfer) {
      // console.log(dispatchTransfer);
      const filteredDispatchTransfers = dispatchTransfers.filter(
        (_dispatchTransfer) => getAttribute(_dispatchTransfer, 'objectId', true) === dispatchTransfer.objectId
      );
      dispatchTransfersToRemove = filteredDispatchTransfers;
    }

    for (let i = 0; i < dispatchTransfersToRemove.length; i++) {
      try {
        const removedDispatchTransfer = await deleteDispatchTransfer(dispatchTransfersToRemove[i]);
      } catch (err) {
        console.log(err);
      }
    }
    setRefreshState(!refreshState);
  }

  // Create default name with given dispatch job and dispatch transfers parse object array
  // Go through dispatch transfers array and find jobs with defualt route name
  // get the highest index of default route name jobs and +1
  function createDefaultRouteName(dispatchJob, _dispatchTransfers) {
    let routeName;
    const batchId = getAttribute(dispatchJob, 'batchId', true);

    const withDefaultRouteName = _dispatchTransfers.filter((dispatchTransfer) =>
      getAttribute(dispatchTransfer, 'routeName', true)?.includes(batchId)
    );

    let aNumber = 1;

    if (withDefaultRouteName.length) {
      withDefaultRouteName.sort((a, b) => {
        const routeNameA = getAttribute(a, 'routeName', true).replace(batchId + '-', '');
        const routeNameB = getAttribute(b, 'routeName', true).replace(batchId + '-', '');
        return parseInt(routeNameA) - parseInt(routeNameB);
      });
      const lastDefaultName = getAttribute(withDefaultRouteName[withDefaultRouteName.length - 1], 'routeName', true);
      aNumber = parseInt(lastDefaultName.replace(batchId + '-', ''));
      aNumber++;
    }
    routeName = batchId + '-' + aNumber.toString();

    return routeName;
  }

  async function renameDispatchTransferRoute() {
    console.log(selectedRoute);
  }

  async function updateShippingDocumentNumber() {
    if (selectedRoute.length < 1) return;
    const routeName = getAttribute(selectedRoute[0], 'routeName', true);
    const dispatchJob = getAttribute(selectedRoute[0], 'dispatchJob', true);
    if (!routeName || !dispatchJob) return;
    await updateSameRouteNameDispatchTransfers(undefined, routeName, dispatchJob, { shippingDocumentNumber }, true);
  }

  /* -------------------------------- Templates ------------------------------- */
  function customizedMarker(transfer) {
    const transferType = transferTypes[transfer.type];
    return (
      <span
        className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
        style={{ backgroundColor: transferType.color }}
      >
        <span className="material-icons">{transferType.icon}</span>
      </span>
    );
  }

  // Action menu on the left
  function actionMenu(dispatchTransfer) {
    return (
      <Button
        type="button"
        icon="pi pi-ellipsis-h"
        // sbVariant="slim"
        outlined
        onClick={(e) => {
          setDispatchTransfer(dispatchTransfer);
          overlayPanelRef.current.toggle(e);
        }}
        className="sb-variant-slim uppercase py-1"
      />
    );
  }

  // "Add Route" button used in the tabs
  function addRouteBtn(item) {
    return <Button label={item.label} sbVariant="slim" className="align-items-center" onClick={() => item.select()} />;
  }

  return (
    <div className="dispatch-shipment-timeline-container">
      {menuBarItems?.length && (
        <TabView
          className="-mt-8 mb-3 dispatch-shipment-tabview"
          activeIndex={tabMenuIndex}
          onTabChange={(e) => {
            menuBarItems[e.index].select();
            setTabMenuIndex(e.index);
          }}
          onBeforeTabClose={(e) => {
            setisRouteDeleteDialogVisible(true);
            return false;
          }}
        >
          {menuBarItems.map((item) => {
            // "Add Route" button tab
            if (!item.delete) {
              return (
                <TabPanel
                  headerTemplate={addRouteBtn(item)}
                  closable={item.closable}
                  className="flex align-items-center"
                />
              );
            }
            // Other tabs
            return (
              <TabPanel header={item.label} closable={item.closable}>
                <Timeline
                  value={selectedRoute}
                  content={(dispatchTransfer) => (
                    <DispatchShipmentTimelineCard
                      dispatchTransfer={parseDispatchTransfer(dispatchTransfer)}
                      onSelectShipment={() => console.log(dispatchTransfer)}
                      setRefreshState={() => setRefreshState(!refreshState)}
                    />
                  )}
                  marker={(dispatchTransfer) => customizedMarker(parseDispatchTransfer(dispatchTransfer))}
                  opposite={(dispatchTransfer) => actionMenu(parseDispatchTransfer(dispatchTransfer))}
                  className="dispatch-shipment-timeline"
                />
                <div className="flex flex-column px-4 w-50">
                  <InputLabel>Shipping ID</InputLabel>
                  <InputText
                    value={shippingDocumentNumber}
                    onChange={(e) => setShippingDocumentNumber(e.target.value)}
                    onBlur={() => updateShippingDocumentNumber()}
                  />
                </div>
              </TabPanel>
            );
          })}
        </TabView>
      )}

      <OverlayPanel innerRef={overlayPanelRef} className="action-menu-overlaypanel">
        {filteredActionMenu}
      </OverlayPanel>
      <ConfirmDialog
        visible={isRenameDialogVisible}
        onHide={() => setIsRenameDialogVisible(false)}
        message="Are you sure you want to proceed?"
        header="Delete Payable Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={async () => await renameDispatchTransferRoute()}
        reject={() => setIsRenameDialogVisible(false)}
      />
      <ConfirmDialog
        visible={isRouteDeleteDialogVisible}
        onHide={() => setisRouteDeleteDialogVisible(false)}
        message={`Are you sure you want to delete ${getAttribute(selectedRoute[0], 'routeName', true)}`}
        header="Confirmation"
        icon="pi pi-trash"
        accept={() => menuBarItems[tabMenuIndex].delete()}
        reject={() => setisRouteDeleteDialogVisible(false)}
      />
      <ConfirmDialog
        visible={isPointDeleteDialogVisible}
        onHide={() => setIsPointDeleteDiablogVisible(false)}
        message="Are you sure you want to delete?"
        header="Confirmation"
        icon="pi pi-trash"
        accept={() => _removeDispatchTransfer(undefined, dispatchTransfer)}
        reject={() => setIsPointDeleteDiablogVisible(false)}
      />
    </div>
  );
}
``;
export default DispatchShipmentTimeline;
