import React from 'react';
import PropTypes from 'prop-types';
import AppBar from 'material-ui/AppBar';
import FontIcon from 'material-ui/FontIcon';
import styles from './ChatModuleAppBar.module.scss';

class ChatModuleAppBar extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { className, onLeftIconButtonTouchTap } = this.props;

    const appBarStyle = {
      background: `${styles.greenKelly} !important`,
    };

    const titleStyle = {
      fontFamily: 'Open Sans',
      fontSize: '1.2em',
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: '.05em',
    };

    return (
      <AppBar
        className={`${className} ${styles.appBar}`}
        title="Contacts"
        iconElementLeft={<FontIcon className="text-white material-icons cursor-pointer mt-large ml-large mr-large">chevron_right</FontIcon>}
        onLeftIconButtonTouchTap={onLeftIconButtonTouchTap}
        style={appBarStyle}
        titleStyle={titleStyle}
      />
    );
  }

}

React.propTypes = {
  className: PropTypes.string,
  onLeftIconButtonTouchTap: PropTypes.func,
};

export default ChatModuleAppBar;
