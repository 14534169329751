import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { Toggle, SelectField, MenuItem } from 'material-ui';

// CSS
import styles from './TrialEndModal.module.scss';

function TrialEndModal(props) {
  return (
    <div className="translate-me">
      Manage your hours-of-service effectively!

      <br />

      <div className={styles.description}>
        Your free trial is ending today and we'd hate to see you go.
        <br />
        <br />
        {!props.resellerCompanyObj &&
          <div>
            For full transparency, <b>your drivers can still run their ELDs and generate logs for up to 14 days</b>, but here's what you'll miss out on:
            <ul>
              <li>Mapping & GPS Tracking on your assets & drivers</li>
              <li>Automated IFTA mileage report generation</li>
              <li>Smart Hours-of-service violation notification</li>
              <li>Integrations with GoHighway, Macropoint, FourKites, Project44</li>
            </ul>
          </div>
        }
        {props.resellerCompanyObj &&
          <div>
            Please contact <var>{props.resellerCompanyObj.get('name')}</var>.
          </div>
        }

        <hr />

        {!props.resellerCompanyObj &&
          <div>
            Contact us at <a href="mailto:team@onswitchboard.com"><b>team@onswitchboard.com</b></a> to learn more!
            {/* <div>
              <b>If you wish to use a new credit card:</b>&nbsp;&nbsp;<button className={`${styles.startButton} buttonDefault`} onClick={props.handleEnterCC} disabled={props.disableButton}>Enter New Credit Card Info</button>
            </div> */}
          </div>
        }
      </div>

      <div className={styles.contactUs}>
        Our team is working hard to make sure your experience with Switchboard is <b>amazing</b>
        <br />
        Please let us know if there's anything we can do for you!
      </div>
    </div>
  );
}

TrialEndModal.propTypes = {
  handleEnterCC: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
  resellerCompanyObj: PropTypes.object,
};

export default TrialEndModal;
