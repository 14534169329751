import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBView, MDBMask } from 'mdbreact';
import PropTypes from 'prop-types';

import './style.scss';

class FooterPromptBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { props } = this;

    let _messageClassName = 'message-container mt-2 h-auto text-right';
    if (props.messageClassName) _messageClassName += ` ${props.messageClassName}`;

    let _buttonContainerClassName = 'w-auto h-auto text-right';
    if (props.buttonContainerClassName) _buttonContainerClassName += ` ${props.buttonContainerClassName}`;
    if (props.adjustForSupportButton) _buttonContainerClassName += ' adjust-for-support-button';

    return (
      <MDBContainer className="footer-prompt-bar m-0" fluid>
        <MDBRow>
          <MDBCol className={`${_messageClassName} translate-me`} size="6">
            { props.message }
          </MDBCol>

          <MDBCol size="6">
            <div className={_buttonContainerClassName}>
              <MDBBtn 
                className="translate-me"
                color={props.confirmButtonColor}
                floating={props.confirmFloating}
                outline={props.confirmOutline} 
                size={props.confirmButtonSize}
                rounded={props.confirmRounded}
                onClick={() => { props.onConfirm(); }}
              >{ props.confirmButtonLabel }
              </MDBBtn>
              <MDBBtn
                className="ml-2 translate-me"
                color={props.cancelButtonColor}
                floating={props.cancelFloating}
                outline={props.cancelOutline}
                size={props.cancelButtonSize}
                rounded={props.cancelRounded}
                onClick={() => { props.onCancel(); }}
              >{ props.cancelButtonLabel }
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
    }
  }


FooterPromptBar.defaultProps ={
  confirmButtonColor: "primary",
  cancelButtonColor: "danger",
  confirmFloating: false,
  cancelFloating: false,
  confirmOutline: true,
  cancelOutline: true,
  confirmButtonSize: "sm",
  cancelButtonSize: "sm",
  confirmRounded: false,
  cancelRounded: false,
  message:"Are you sure?",
  onConfirm: (props)=>{},
  onCancel: (props)=>{},
  confirmButtonLabel: 'Save',
  cancelButtonLabel:'Cancel'
}


FooterPromptBar.propTypes = {
  //for action button and cancel button
  //ui-lib for :string
  cancelButtonColor: PropTypes.string,//eg. primary, secondary, success, info, warning, danger
  confirmButtonColor: PropTypes.string,//eg. primary, secondary, success, info, warning, danger
  confirmButtonSize: PropTypes.string, //eg. sm, lg,default:medium
  cancelButtonSize: PropTypes.string, //eg. sm, lg,default:medium
  buttonContainerClassName: PropTypes.string,
  messageClassName: PropTypes.string,

  //ui-lib:boolean
  confirmFloating: PropTypes.bool,
  cancelFloating: PropTypes.bool,
  confirmOutline: PropTypes.bool,
  cancelOutline: PropTypes.bool,
  confirmRounded: PropTypes.bool,
  cancelRounded: PropTypes.bool,

  //props
  adjustForSupportButton: PropTypes.bool, // adjust the padding on the buttons if theres a floating button on the bottom left of screen that overlaps our bar
  confirmButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message:PropTypes.string,
}
export default FooterPromptBar;

// display the message (centered-left). text color: $black, $black-lighten
// 	buttons - shift right
// 	white background
// 	top border