import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Parse from 'parse';

// Actions
import { updateTemp } from 'actions/Temp';
import { getLinkedDriversForState, deleteDriversForState } from 'actions/Driver';

// API
import * as Analytics from 'api/Analytics';
import * as ELD from 'api/ELD';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import isValidDOTNumber from 'api/DOTNumber';
import { getAttribute } from 'api/Parse';
import {
  getCompanyAnalytics,
  updateCompanyAnalytics,
} from 'api/CompanyAnalytics/CompanyAnalytics';

// Components
import Snackbar from 'material-ui/Snackbar';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import BundleLogsModal from '../view/BundleLogsModal';

// Context
import StoreContext from 'contexts/StoreContext';

class BundleLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showRequirementModal: false,
      missingDOTandNSC: false,
      invalidDOTNumber: false,
      requirementModalWarnings: [],
      errors: [],
      driver: props.driver,
      drivers: [],
      downloadURL: undefined,
      generateSuccess: false,
      sendType: 'downloadPdf',
      emailValue: Getters.getCurrentUser().get('email'),
      faxValue: '',
      disregardLogs: false,
      printOriginalLogs: false,
      printPreTrips: true,
      printCTPAT: true,
      printPostTrips: true,
      printSingle: true,
      showHOSViolations: false,
      selectedDrivers: props.driver ? [props.driver] : [],
      selectedDriverItems: props.driver ? [{ driver: props.driver, value: props.driver.id, key: props.driver.id }] : [],
      companyAnalytics: undefined,
      sendToNSC: false,
      toNSCEmailAddress: '',
    };
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleDisregardLogs = this.toggleDisregardLogs.bind(this);
    this.toggleShowHOSViolations = this.toggleShowHOSViolations.bind(this);
    this.bundleHandler = this.bundleHandler.bind(this);
    this.getSelectedDriverItems = this.getSelectedDriverItems.bind(this);
    this.deleteDriver = this.deleteDriver.bind(this);
    this.bundleHandlerHelper = this.bundleHandlerHelper.bind(this);
  }

  async componentDidMount() {
    const companyAnalytics = await getCompanyAnalytics();

    getLinkedDriversForState(undefined, undefined, [], { user_fullName: 'ascending' }, null, false, this.props.Company.company).then((drivers) => {
      // const newState = { ...this.state, isLoading: false, isLazyLoading: false, queryPage: queryPage + 1 };
      // newState.hasLoadedAll = true; // we've queried everything, so stop
      // self.setState(newState, () => resolve(true));
      this.setState({
        drivers,
        companyAnalytics,
      });
    });

    const { Company } = this.props;
    const requirementModalWarnings = [];
    let { showRequirementModal, missingDOTandNSC, invalidDOTNumber } = this.state;

    if (Company && Company.company && Company.company.id) {
      if (!(Company.company.get('dotNumber') || Company.company.get('nscNumber'))) {
        showRequirementModal = true;
        missingDOTandNSC = true;
        requirementModalWarnings.push(t('US DOT or Canadian NSC Number Required for Printing. Please enter your DOT or NSC Number in the Settings Page'));
      }

      if (Company.company.get('dotNumber')) {
        invalidDOTNumber = !isValidDOTNumber(Company.company.get('dotNumber'));
        if (invalidDOTNumber) {
          showRequirementModal = true;
          requirementModalWarnings.push(
            <span>
              {t('The DOT Number entered for this company is invalid, a valid DOT Number has 1 to 9 digits.')}
              {t('To stop receiving this warning message please correct or remove the DOT Number in the settings page.')}
              <hr />
              {t('Switchboard does not guarantee that the FMCSA will receive any sent logs if you continue')}
            </span>,
          );
        }
      }
    }

    this.setState({ showRequirementModal, missingDOTandNSC, invalidDOTNumber, requirementModalWarnings });

    // Getters.queryCompanyObjects('Driver', undefined, 1000, [{ queryType: 'equalTo', name: 'enabled', value: true }]).then((driverArr) => {
    //   this.setState({
    //     ...this.state,
    //     drivers: driverArr,
    //   });
    // });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.driver) {
      this.setState({ driver: nextProps.driver, selectedDriverItems: [].concat(this.state.selectedDriverItems) });
    }
  }

  componentWillUnmount() {
    updateTemp('eldLogs', {});
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
    if (this.props.handleClose) {
      this.props.handleClose();
    }
  }

  updateHandler(datePickerStates) {
    updateTemp('eldLogs', { ...datePickerStates });
  }

  toggleDisregardLogs(bool, toggleType) {
    if (toggleType === 0) {
      // pretrips only
      const newState = { ...this.state };
      newState.disregardLogs = bool;
      if (bool) {
        newState.printPreTrips = true;
        newState.printCTPAT = false;
        newState.printPostTrips = false;
      } else {
        newState.printCTPAT = true;
        newState.printPostTrips = true;
      }
      this.setState(newState);
    } else if (toggleType === 1) {
      // ctpat only
      const newState = { ...this.state };
      newState.disregardLogs = bool;
      if (bool) {
        newState.printCTPAT = true;
        newState.printPreTrips = false;
        newState.printPostTrips = false;
      } else {
        newState.printPreTrips = true;
        newState.printPostTrips = true;
      }
      this.setState(newState);
    } else if (toggleType === 2) {
      // posttrips only
      const newState = { ...this.state };
      newState.disregardLogs = bool;
      if (bool) {
        newState.printCTPAT = false;
        newState.printPreTrips = false;
        newState.printPostTrips = true;
      } else {
        newState.printPreTrips = true;
        newState.printCTPAT = true;
      }
      this.setState(newState);
    }
  }

  toggleShowHOSViolations() {
    this.setState({ ...this.state, showHOSViolations: !this.state.showHOSViolations });
  }

  bundleHandler(logDatesChosen, cycleTypeObject, zipAll) {
    const promise = new Promise((resolve, reject) => {

      const { state } = this;
      if (state.companyAnalytics) {
        const companyAnalytics = state.companyAnalytics;
        const GENLOGS = getAttribute(companyAnalytics, 'GENLOGS');
        if (!GENLOGS) {
          Analytics.track('Logs Bundled');
          updateCompanyAnalytics(companyAnalytics, { GENLOGS: true }).then().catch(err => console.log(err));
        }
      }

      this.setState({ ...this.state, errors: [] }, () => {
        if (!this.state.selectedDriverItems) {
          this.setState({ ...this.state, errors: [t('Please select a driver')] });
          resolve();
          return;
        }
        if (!this.props.Company.company.get('address') || !this.props.Company.company.get('address').get('address')) {
          this.setState({ ...this.state, errors: [t('Your company needs to have an address. Go to the settings page to enter your company address')] });
          resolve();
          return;
        }

        if (this.state.sendType === 'email' && !this.state.emailValue) {
          this.setState({ ...this.state, errors: [t('Please enter an email address')] });
          resolve();
          return;
        }

        let newState = { ...this.state };
        if (this.state.sendType == 'email') {
          newState = { ...this.state, downloadBool: true };
        } else if (this.state.sendType === 'fmcsa') {
          newState = { ...this.state, showFMCSAConfirmModal: false, errors: [], inProgress: true, saveMessage: '' };
        }

        this.setState(newState, () => {
          let promises = null;
          if (zipAll) {
            promises = [this.bundleHandlerHelper(logDatesChosen, cycleTypeObject, this.state.selectedDriverItems)];
          } else {
            promises = this.state.selectedDriverItems.map(driverItem => {
              this.bundleHandlerHelper(logDatesChosen, cycleTypeObject, [driverItem.driver]);
            });
          }
          Promise.all(promises).then(() => {
            this.setState({ ...this.state, generateSuccess: true });
            this.props.handleClose();
            if (this.state.sendType === 'downloadPdf') {
              setTimeout(() => {
                history.push({ pathname: 'safety', search: 'view=logstodownload' });
              }, 3000);
            } else if (this.state.sendType === 'exportCsv') {
              this.setState({ ...this.state, generateSuccess: true });
            } else {
              this.setState({ ...this.state, generateSuccess: true });
            }
          });
        });
      });
    });
    return promise;
  }


  bundleHandlerHelper(logDatesChosen, cycleTypeObject, drivers) {
    const promise = new Promise((resolve, reject) => {
      const { state } = this;

      this.setState({ ...this.state, errors: [] }, () => {
        let _logDatesChosen = [];
        let recapHoursArr = [];

        if (cycleTypeObject) {
          // associate each log with the same recap hour object
          // recapHoursArr = _logDatesChosen.map(logDate => cycleTypeObject);
          recapHoursArr = logDatesChosen.map(logDate => cycleTypeObject);
        }

        if (this.state.sendType === 'email') {

          let driverIds = drivers.map((driver) => driver.id ? driver.id : driver.value);
          const analyticTrackProperties = {
            status: 'in_progress',
            num_days_printed: logDatesChosen.length,
            print_original: this.state.printOriginalLogs,
            print_pretrip: this.state.printPreTrips,
            print_ctpat: this.state.printCTPAT,
            print_posttrip: this.state.printPostTrips,
            driver_ids: driverIds,
          };
          analyticTrackProperties.download_type = 'email';

          if (state.companyAnalytics) {
            const companyAnalytics = state.companyAnalytics;
            const GENLOGS = getAttribute(companyAnalytics, 'GENLOGS');
            if (!GENLOGS) {
              Analytics.track('bundle_logs', analyticTrackProperties);
              updateCompanyAnalytics(companyAnalytics, { GENLOGS: true }).then().catch(err => console.log(err));
            }
          }

          ELD.generateHOSLogs(undefined, logDatesChosen, driverIds, this.state.printSingle, this.state.disregardLogs, this.state.printOriginalLogs, this.state.printPreTrips, this.state.printCTPAT, this.state.printPostTrips, true, this.state.emailValue, recapHoursArr, this.state.showHOSViolations).then(logParseObj => {
            // this.setState({ ...this.state, generateSuccess: true });
            resolve(logParseObj);
            // this.props.handleClose();
          }, error => {
            analyticTrackProperties.status = 'failed';
            analyticTrackProperties.error = error;

            if (state.companyAnalytics) {
              const companyAnalytics = state.companyAnalytics;
              const GENLOGS = getAttribute(companyAnalytics, 'GENLOGS');
              if (!GENLOGS) {
                Analytics.track('bundle_logs', analyticTrackProperties);
                updateCompanyAnalytics(companyAnalytics, { GENLOGS: true }).then().catch(err => console.log(err));
              }
            }

            reject([error]);
          });
        } else if (this.state.sendType === 'fmcsa') {
          for (let i = 0; i < drivers.length; i++) {
            let driverId = drivers[i].id ? drivers[i].id : drivers[i].value;
            Parse.Cloud.run('sendToFMCSAWebServices', {
              logDatesChosen: logDatesChosen,
              driverId: driverId,
              distanceUnit: 'mi',
              outputFileComment: this.state.outputFileComment,
              // testFlag: true,
            }).then((response) => {
              // this.setState({ ...this.state, generateSuccess: true });
              console.log(response);
              resolve(response);
              // this.props.handleClose();
            }, (error) => {
              console.log(error);
              // console.log(error.message.data.OutputFileBody); // for debugging
              resolve(error);
              // this.props.handleClose();
            });
            // Parse.Cloud.run('getFMCSACsv', {
            //   logDatesChosen,
            //   driverId: this.state.driver.id,
            //   distanceUnit: 'mi',
            //   outputFileComment: this.state.outputFileComment,
            // }).then((response) => {
            //   console.log(response.outputFileBody);
            //   this.setState({ ...this.state, generateSuccess: true });
            //   resolve(response);
            //   this.props.handleClose();
            // });
          }
        } else if (this.state.sendType === 'nsc') {
          // generateHOSLogs currently takes in an empty array for vehicleUnitIds
          // TODO: add in vehicle selection section in bundleLogsModal.js
          const vehicleUnitIds = [];
          let driverIds = drivers.map((driver) => driver.id ? driver.id : driver.value);
          ELD.generateHOSLogs(undefined, logDatesChosen, driverIds, this.state.printSingle, this.state.disregardLogs, this.state.printOriginalLogs, this.state.printPreTrips, this.state.printCTPAT, this.state.printPostTrips, true, this.state.toNSCEmailAddress, recapHoursArr, this.state.showHOSViolations, undefined, this.state.outputFileComment, true, vehicleUnitIds).then(logParseObj => {
            resolve(logParseObj);
          }, error => {
            // analyticTrackProperties.status = 'failed';
            // analyticTrackProperties.error = error;
            // Analytics.track('bundle_logs', analyticTrackProperties);
            reject([error]);
          });
        } else if (this.state.sendType === 'downloadPdf') {
          let driverIds = drivers.map((driver) => driver.id ? driver.id : driver.value);
          const analyticTrackProperties = {
            status: 'in_progress',
            num_days_printed: logDatesChosen.length,
            print_original: this.state.printOriginalLogs,
            print_pretrip: this.state.printPreTrips,
            print_ctpat: this.state.printCTPAT,
            print_posttrip: this.state.printPostTrips,
            driver_ids: driverIds,
          };
          analyticTrackProperties.download_type = 'downloadPdf';

          if (state.companyAnalytics) {
            const companyAnalytics = state.companyAnalytics;
            const GENLOGS = getAttribute(companyAnalytics, 'GENLOGS');
            if (!GENLOGS) {
              Analytics.track('bundle_logs', analyticTrackProperties);
              updateCompanyAnalytics(companyAnalytics, { GENLOGS: true }).then().catch(err => console.log(err));
            }
          }

          ELD.generateHOSLogs(undefined, logDatesChosen, driverIds, this.state.printSingle, this.state.disregardLogs, this.state.printOriginalLogs, this.state.printPreTrips, this.state.printCTPAT, this.state.printPostTrips, true, null, recapHoursArr, this.state.showHOSViolations).then(logParseObj => {
            // this.setState({ ...this.state, generateSuccess: true });
            this.props.handleClose();
            setTimeout(() => {
              // Ensure that the row is there
              history.push({ pathname: 'safety', search: 'view=logstodownload' });
            }, 2000);
            resolve(logParseObj);
          }, error => {
            analyticTrackProperties.status = 'failed';
            analyticTrackProperties.error = error;

            if (state.companyAnalytics) {
              const companyAnalytics = state.companyAnalytics;
              const GENLOGS = getAttribute(companyAnalytics, 'GENLOGS');
              if (!GENLOGS) {
                Analytics.track('bundle_logs', analyticTrackProperties);
                updateCompanyAnalytics(companyAnalytics, { GENLOGS: true }).then().catch(err => console.log(err));
              }
            }

            reject([error]);
          });

        } else if (this.state.sendType === 'exportCsv') {
          for (let i = 0; i < drivers.length; i++) {
            let driverId = drivers[i].id ? drivers[i].id : drivers[i].value;
            const analyticTrackProperties = {
              status: 'in_progress',
              num_days_printed: logDatesChosen.length,
              print_original: this.state.printOriginalLogs,
              print_pretrip: this.state.printPreTrips,
              print_ctpat: this.state.printCTPAT,
              print_posttrip: this.state.printPostTrips,
              driver_id: driverId,
            };
            analyticTrackProperties.download_type = 'exportCsv';

            if (state.companyAnalytics) {
              const companyAnalytics = state.companyAnalytics;
              const GENLOGS = getAttribute(companyAnalytics, 'GENLOGS');
              if (!GENLOGS) {
                Analytics.track('bundle_logs', analyticTrackProperties);
                updateCompanyAnalytics(companyAnalytics, { GENLOGS: true }).then().catch(err => console.log(err));
              }
            }

            Parse.Cloud.run('generateHOSLogsCsv', {
              logDatesChosen: logDatesChosen,
              driverId: driverId,
              distanceUnit: 'mi',
              outputFileComment: this.state.outputFileComment,
            }).catch((error) => {
              console.log(error);
              reject(error);
            });

          }
          resolve(true);
        };
      });
    });
    return promise;
  }

  selectDriver(driver) {
    let { selectedDrivers } = this.state;
    const selectedDriversId = selectedDrivers.map((driver) => driver.id);
    if (selectedDriversId.indexOf(driver.id) == -1) {
      selectedDrivers = selectedDrivers.concat(driver);
      this.setState({ ...this.state, driver, selectedDrivers });
    }
  }

  getSelectedDriverItems(selectedDriverItems = []) {
    this.setState({ ...this.state, selectedDriverItems });
  }

  deleteDriver(driver) {
    if (this.state.selectedDriverItems.length > 1) {
      const newState = { ...this.state };
      newState.selectedDriverItems = [].concat(newState.selectedDriverItems);
      const index = newState.selectedDriverItems.map((driverItem) => driverItem.driver.id).indexOf(driver.id);
      newState.selectedDriverItems.splice(index, 1);
      this.setState(newState);
    }
  }

  render() {
    const { state } = this;
    const { Company, User } = this.props;
    const showBundleLogsModal = Company && Company.company && Company.company.id && Company.company.get('dotNumber') && (this.props.showModal || this.state.showModal);

    const callingUser = User && User.user;
    const userSpecialPermission = callingUser && callingUser.get('userSpecialPermission');
    const disableELDEditFeature = userSpecialPermission && userSpecialPermission.get('disableEldIncludeUneditedLogs');

    return (
      <div className="inline">
        <BundleLogsModal
          company={this.props.Company.company}
          driver={this.state.driver}
          drivers={this.state.drivers}
          errors={this.state.errors}
          selectDriver={this.selectDriver}
          selectedDrivers={this.state.selectedDrivers}
          selectedDriverItems={this.state.selectedDriverItems}
          getSelectedDriverItems={this.getSelectedDriverItems}
          deleteDriver={this.deleteDriver}
          show={this.props.showModal || this.state.showModal}
          handleClose={this.closeModal}
          emailValue={this.state.emailValue}
          updateEmailValue={(value) => this.setState({ ...this.state, emailValue: value })}
          bundleHandler={this.bundleHandler}
          eldLogs={this.props.Temp.temp.eldLogs}
          updateHandler={this.updateHandler}
          downloadURL={this.state.downloadURL}
          miscInfo={this.props.miscInfo}
          selectSendType={(value) => this.setState({ ...this.state, sendType: value })}
          sendType={this.state.sendType}
          togglePrintPreTripsCheckbox={() => this.setState({ ...this.state, printPreTrips: !this.state.printPreTrips })}
          printPreTrips={this.state.printPreTrips}
          printCTPAT={this.state.printCTPAT}
          printPostTrips={this.state.printPostTrips}
          togglePrintOriginalLogsCheckbox={() => this.setState({ ...this.state, printOriginalLogs: !this.state.printOriginalLogs })}
          toggleDisregardLogs={this.toggleDisregardLogs}
          toggleShowHOSViolations={this.toggleShowHOSViolations}
          showHOSViolations={this.state.showHOSViolations}
          disregardLogs={this.state.disregardLogs}
          printOriginalLogs={this.state.printOriginalLogs}
          outputFileComment={this.state.outputFileComment}
          updateOutputComment={(value) => this.setState({ ...this.state, outputFileComment: value })}
          disableELDEditFeature={disableELDEditFeature}
          showEldCsvDownload={this.props.Company.company && this.props.Company.company.get('showEldCsvDownload')}
          toNSCEmailAddress={this.state.toNSCEmailAddress}
          updateToNSCEmailAddress={(value) => this.setState({ ...this.state, toNSCEmailAddress: value })}
          toggleSendToNSC={(bool) => this.setState({ ...this.state, sendToNSC: bool })}
        />
        {showBundleLogsModal && state.showRequirementModal && (
          <ConfirmModal
            show={state.showRequirementModal}
            handleModalChoice={(bool) => {
              if (bool) {
                this.closeModal();
                history.push({ pathname: 'settings' });
              } else {
                this.setState({ showRequirementModal: false });
              }
            }}
            handleClose={() => {
              if (state.missingDOTandNSC) {
                this.closeModal();
                history.push({ pathname: 'settings' });
              } else {
                this.setState({ showRequirementModal: false });
              }
            }}
            title={t(state.missingDOTandNSC ? 'Missing DOT or NSC Number' : 'DOT Number Warning')}
            primaryLabel={t('Go To Settings')}
            secondaryLabel={t('Continue Anyways')}
          >
            <ul>
              {state.requirementModalWarnings.map(val => <li>{val}</li>)}
            </ul>
          </ConfirmModal>
        )}

        <Snackbar
          open={this.state.generateSuccess}
          message={t('Logs are being generated')}
          autoHideDuration={4000}
          onRequestClose={() => { this.setState({ ...this.state, generateSuccess: false }); }}
        />
      </div>
    );
  }
}

BundleLogs.propTypes = {
  driver: PropTypes.object,
  Company: PropTypes.object.isRequired,
  Temp: PropTypes.object.isRequired,
  User: PropTypes.object,
  hideButton: PropTypes.bool,
  handleClose: PropTypes.func,
  miscInfo: PropTypes.object,
  showModal: PropTypes.bool,
};

const mapStateToProps = state => {
  const { Company, Temp, User, Driver } = state;
  return {
    Company,
    Driver,
    Temp,
    User,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(BundleLogs);
