import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'react-bootstrap';

// API
import { formatAddress } from 'api/Helpers';

// Components
import SortButton from 'components/SortButton/view/SortButton';
import SimpleTableBody from 'components/SimpleTableBody/container/SimpleTableBody';
import EditVendorButton from 'components/AddEditLocation/container/EditVendorButton';
import DeleteXButton from 'components/ConfirmModal/container/DeleteXButton';

// CSS
import styles from './LocationsTable.module.scss';

class LocationsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { vendorRows: [], loaded: false };
  }

  componentWillReceiveProps(nextProps) {
    const newState = { ...this.state };
    newState.loaded = nextProps.Vendor.fetched && (nextProps.Vendor.vendors.length === 0 || (nextProps.Vendor.vendors >= this.state.vendorRows));

    let keyCounter = 0;
    newState.vendorRows = nextProps.Vendor.vendors.map(vendor => {
      const vendorRow = (
        <tr key={`${vendor.id}${keyCounter}`} className={styles.vendorRow}>
          <td><b>{vendor.get('name')}</b></td>
          <td>{formatAddress(vendor.get('address').toJSON())}</td>
          <td><textarea value={vendor.get('notes') || ''} placeholder="Location Notes..." readOnly /></td>
          <td className="centerText">
            <div style={{ display: 'inline-block' }}><EditVendorButton vendor={vendor} /></div>
            <div style={{ display: 'inline-block', marginLeft: '1em', cursor: 'pointer' }}><DeleteXButton handleChoice={(confirmBool) => this.props.disableVendor(confirmBool, vendor)} /></div>
          </td>
        </tr>
      );
      keyCounter++;
      return vendorRow; // defaukt
    });
    this.setState(newState);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.sort !== this.props.sort) {
      return true;
    }
    if (nextProps.Vendor !== this.props.Vendor) {
      return true;
    }
    return false;
  }

  render() {
    const { vendorRows } = this.state;
    return (
      <div className="dataTable" onScroll={(e) => this.props.scrollHandler(e)}>
        <Table responsive hover>
          <thead>
            <tr>
              <th>
                Name
                <SortButton
                  clickHandler={() => this.props.sortHandler('name')}
                  isActive={this.props.sort.name}
                />
              </th>
              <th>
                Address
                <SortButton
                  clickHandler={() => this.props.sortHandler('address_address')}
                  isActive={this.props.sort.address_address}
                />
              </th>
              <th>
                Notes
              </th>
              <th className="centerText" />
            </tr>
          </thead>
          <SimpleTableBody rows={vendorRows} loaded={this.state.loaded} columns="4" />
        </Table>
      </div>
    );
  }
}

LocationsTable.propTypes = {
  Vendor: PropTypes.object.isRequired,
  disableVendor: PropTypes.func.isRequired,
  scrollHandler: PropTypes.func,
  sortHandler: PropTypes.func,
  sort: PropTypes.object,
};

export default LocationsTable;

