import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// Components
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import ChooseOrAddModal from 'components/ChooseOrAddModal/container/ChooseOrAddModal';

class AllIssuesTableRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showResolveModal: false,
      showAssignDriverModal: false,
    };
    this.handleResolveChoice = this.handleResolveChoice.bind(this);
    this.handleResolveClose = this.handleResolveClose.bind(this);
    this.assignDriver = this.assignDriver.bind(this);
    this.handleAssignDriverClose = this.handleAssignDriverClose.bind(this);
  }

  handleResolveChoice(choiceBool) {
    if (choiceBool) {
      this.props.issue.set('resolved', true).save().then(issue => {
        this.setState(this.state);
      });
    }
    this.setState({ ...this.state, showResolveModal: false });
  }

  handleResolveClose() {
    this.setState({ ...this.state, showResolveModal: false });
  }

  assignDriver(driver) {
    this.props.issue
      .set('driver', driver)
      .set('fullName', `${driver.get('user').get('firstName')} ${driver.get('user').get('lastName')}`)
      .set('user_fullName', driver.get('user_fullName'))
      .save();
    this.setState({ ...this.state, showAssignDriverModal: false });
  }

  handleAssignDriverClose() {
    this.setState({ ...this.state, showAssignDriverModal: false });
  }

  render() {
    const { issue } = this.props;

    let dateTime = issue.get('dateTime');
    if (dateTime) {
      dateTime = moment(dateTime).format('MMM D, YYYY');
    }

    const driverName = issue.get('fullName');
    const vehicleNumber = issue.get('plate');
    let type = issue.className.toLowerCase();

    let description = '';
    let activePoints = '';
    if (type === 'contravention') {
      const contraventionType = issue.get('contraventionType_type');
      type = `Contravention (${contraventionType[0].toUpperCase()}${contraventionType.slice(1)})`;
      description = `Ticket ${issue.get('ticketNumber')} -- ${issue.get('description')}`;

      if (issue.get('activePoints') && issue.get('activePoints') !== '') {
        activePoints = parseFloat(issue.get('activePoints'));
      }
    }
    else if (type === 'accident') {
      type = 'Accident';
      description = `Report ${issue.get('reportNumber')} -- ${issue.get('type')} (${issue.get('fault')})`;
      if (issue.get('activePoints') && issue.get('activePoints') !== '') {
        activePoints = parseFloat(issue.get('activePoints'));
      }
    }
    else if (type === 'vehicleinspection') {
      type = `CVSA - ${issue.get('result')}`;
      description = `Document ${issue.get('documentNumber')} -- ${issue.get('vehicleResult')} (Item Defect ${issue.get('inspectionItemDefect')})`;
      if (issue.get('activePoints') && issue.get('activePoints') !== '') {
        activePoints = parseFloat(issue.get('activePoints'));
      }
    }

    return (
      <tr key={this.props.rowKey}>
        <td>
          { dateTime }
        </td>
        <td>
          { type }
        </td>
        <td>
          { description }
        </td>
        <td>
          { driverName }
          {!issue.get('driver') && issue.className.toLowerCase() !== 'vehicleinspection' &&
            <button className="buttonDefault block" onClick={() => this.setState({ ...this.state, showAssignDriverModal: true })}>Link Driver</button>
          }
        </td>
        <td>
          { vehicleNumber }
        </td>
        <td>
          { activePoints }
        </td>
        <td>
          {!issue.get('resolved') ?
            <button
              className="buttonDefault longButton floatRight greenKelly"
              onClick={() => this.setState({
                ...this.state,
                showResolveModal: true,
              })}
            >
              Resolve
            </button>
            :
            <button className="buttonDefault longButton floatRight" disabled={issue.get('resolved')}>Resolved</button>
          }

          <ConfirmModal show={this.state.showResolveModal} handleModalChoice={this.handleResolveChoice} handleClose={this.handleResolveClose} />
          <ChooseOrAddModal label="Driver" property="driver" show={this.state.showAssignDriverModal} handleChooseOrAdd={this.assignDriver} handleClose={this.handleAssignDriverClose} />
        </td>
      </tr>
    );
  }
}

AllIssuesTableRow.propTypes = {
  issue: PropTypes.object,
  rowKey: PropTypes.string,
};

export default AllIssuesTableRow;
