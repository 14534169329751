import React from 'react';

import { InputSwitch as PRInputSwitch } from 'primereact/inputswitch';

/**
 * @description Prime React InputSwitch component
 * 
 * @param {string} [className] - styles to apply to the input switch
 * @returns
 * 
 * @example
 * <InputSwitch className="..." />
 */

export default function InputSwitch({ ...props }) {
  let className = 'sbc-input-switch';
  if (props.className) className += ` ${props.className}`;

  return (<PRInputSwitch { ...props } className={className} />)
}