import React, { useState, useEffect } from 'react';

// sbCore Components
import Column from 'sbCore/Column/Column';
import DataTable from 'sbCore/DataTable/DataTable';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import InputText from 'sbCore/InputText/InputText';
import InputNumber from 'sbCore/InputNumber/InputNumber';

// api
import { getDispatchQuoteDiscount, createDispatchQuoteDiscount, updateDispatchQuoteDiscount, deleteDispatchQuoteDiscount } from 'api/Dispatch/DispatchQuoteDiscount';
import { getAttribute } from 'sb-csapi/dist/AAPI';

// styles
import './style.scss';

function QuoteInformationDiscounts({ dispatchQuote, toggleAdditionalDetailsState }) {
  // ** Use States ** //
  const [discounts, setDiscounts] = useState([]);
  const [discountRecords, setDiscountRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // ** use effects ** //
  useEffect(() => {
    setIsLoading(false);
    console.log(discounts);
  }, [discounts]);

  useEffect(() => {
    async function _getDispatchQuoteDiscount() {
      const dispatchQuoteDiscounts = await getDispatchQuoteDiscount(dispatchQuote);
      if (!dispatchQuoteDiscounts) return;
      setDiscounts(dispatchQuoteDiscounts.map((discount) => {
        return {
          objectId: getAttribute(discount, 'objectId'),
          discountType: getAttribute(discount, 'discountType', true) || '',
          description: getAttribute(discount, 'description', true) || '',
          rate: getAttribute(discount, 'rate', true) || 0,
        }
      }));
      setDiscountRecords(dispatchQuoteDiscounts);
    }

    _getDispatchQuoteDiscount();
  }, [dispatchQuote, isLoading]);

  // ** Enums ** //

  const discountTypes = [
    'Delay',
    'Other',
  ];

  // ** Functions ** //

  async function addDiscount() {
    setIsLoading(true);
    await createDispatchQuoteDiscount(dispatchQuote);
  }

  async function deleteDiscount(rowIndex) {
    setIsLoading(true);
    console.log(`DELETING ROW: ${rowIndex}`);
    const _discounts = [...discounts];
    _discounts.splice(rowIndex, 1);
    setDiscounts(_discounts);
    await deleteDispatchQuoteDiscount(discountRecords[rowIndex]);
    toggleAdditionalDetailsState();
  }

  async function onRowEditComplete(e) {
    const _discounts = [...discounts];
    const { newData, index } = e;
    _discounts[index] = newData;

    await updateDispatchQuoteDiscount(_discounts[index].objectId, _discounts[index], true);

    setDiscounts(_discounts);
    toggleAdditionalDetailsState();
  }

  // ** Discounts Templates ** //

  function discountTypeEditor(options) {
    return (
      <Dropdown
        value={options.value}
        options={discountTypes}
        onChange={(e) => options.editorCallback(e.value)}
        variant="small"
        placeholder="Type"
      />
    );
  }

  function discountDescriptionEditor(options) {
    return (
      <InputText
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        variant="small"
        placeholder="Details"
      />
    );
  }

  function discountRateEditor(options) {
    return (
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">$</span>
        <InputNumber
          value={options.value}
          onChange={(e) => options.editorCallback(e.value)}
          placeholder="0.00"
          className="input-group-field"
          minFractionDigits={2}
        />
      </div>
    );
  }

  function deleteAdditionalDiscountTemplate(rowData, options) {
    return (
      <span className="pi pi-trash delete-button" onClick={() => deleteDiscount(options.rowIndex)} />
    );
  }

  const discountRateTemplate = (rowData) => {
    return <div>$ {rowData.rate.toFixed(2)}</div>
  };

  const discountFooterTemplate = (
    <div className="add-detail-button" onClick={() => addDiscount()}>
      <span className="pi pi-plus-circle" /> Add Discount
    </div>
  );

  return (
    <DataTable
      value={discounts}
      editMode="row"
      footer={discountFooterTemplate}
      loading={isLoading}
      variant="compact"
      onRowEditComplete={async (e) => await onRowEditComplete(e)}
    >
      <Column field="discountType" header="Discount Type" className="w-25" editor={(options) => discountTypeEditor(options)} />
      <Column field="description" header="Description" headerStyle={{ width: '35%' }} editor={(options) => discountDescriptionEditor(options)} />
      <Column field="rate" header="Discount" className="w-25" body={discountRateTemplate} editor={(options) => discountRateEditor(options)} />
      <Column headerStyle={{ width: '5%' }} body={(rowData, options) => deleteAdditionalDiscountTemplate(rowData, options)} />
      <Column rowEditor headerStyle={{ minWidth: '1.5rem' }} bodyStyle={{ textAlign: 'center' }} />
    </DataTable>
  );
}

export default QuoteInformationDiscounts;
