import React, { useEffect, useState } from 'react';

// sbCore
import Accordion from 'sbCore/Accordion/Accordion';
import AccordionTab from 'sbCore/Accordion/AccordionTab';
import InputTextarea from 'sbCore/InputTextarea/InputTextarea';

// sb-csapi
import { getAttribute } from 'sb-csapi/dist/AAPI';

// api
import { updateDispatchTransfer } from 'api/Dispatch/DispatchTransfer';

// css
import './style.scss';

/**
 * @description Renders notes section for a dispatch shipment
 * 
 * @param {DispatchTransfer} dispatchTransfer - Dispatch Transfer record
 * 
 * @returns
 */
function DispatchShipmentNotes({ ...props }) {
  const [shipperNotes, setShipperNotes] = useState();
  const [receiverNotes, setReceiverNotes] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [activeIndex, setActiveIndex] = useState([]);

  // Retrieve notes when the shipment tab changes
  useEffect(() => {
    let didCancel = false;
    
    if (props.dispatchTransfer && !didCancel) {
      // Reset active index so the notes tab is closed by default
      setActiveIndex([]);
      setShipperNotes(getAttribute(props.dispatchTransfer, 'shipperNotes', true));
      setReceiverNotes(getAttribute(props.dispatchTransfer, 'receiverNotes', true));
    }

    return () => { didCancel = true; };

  }, [props.dispatchTransfer])

  const shipmentNotesHeader = (
    <div className="font-bold text-lg my-0 inline shipment-notes-header-text">Shipment Notes</div>
  )

  function handleNotesChange(value, attribute) {
    // Check if there's any changes and set the dirty flag accordingly
    if (value === getAttribute(props.dispatchTransfer, attribute, true)) {
      setIsDirty(false);
    } else {
      setIsDirty(true);
    }

    switch(attribute) {
      case 'shipperNotes':
        setShipperNotes(value);
        break;
      case 'receiverNotes':
        setReceiverNotes(value);
        break;
    }
  }

  // handles notes save, triggers on input text area blur
  async function handleNotesSave(value, attribute) {
    // if there's no changes, don't save
    if (!isDirty) return;

    // otherwise, disable the input area and save
    setIsLoading(true);

    let updatedNotes = {};
    updatedNotes[attribute] = value;

    await updateDispatchTransfer(
      props.dispatchTransfer, // Dispatch Transfer record
      undefined, // Dispatch Transfer object ID
      updatedNotes, // keyValueObj
      true // save
    );

    setIsLoading(false);
  }

  return (
    <div className="mt-5 dispatch-shipment-notes">
      <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <AccordionTab
          headerClassName="shipment-notes-header"
          contentClassName="shipment-notes-content"
          headerTemplate={shipmentNotesHeader}
        >
          <div className="shipment-notes-content-container">
            <div className="flex flex-column flex-grow-1">
              <label>Notes for Shipper</label>
              <InputTextarea
                value={shipperNotes}
                onChange={(e) => handleNotesChange(e.target.value, 'shipperNotes')}
                onBlur={(e) => handleNotesSave(e.target.value, 'shipperNotes')}
                disabled={isLoading}
                placeholder="No notes for Shipper."
              />
            </div>
            <div className="flex flex-column flex-grow-1">
              <label>Notes for Receiver</label>
              <InputTextarea
                value={receiverNotes}
                onChange={(e) => handleNotesChange(e.target.value, 'receiverNotes')}
                onBlur={(e) => handleNotesSave(e.target.value, 'receiverNotes')}
                disabled={isLoading}
                placeholder="No notes for Receiver."
              />
            </div>
          </div>
        </AccordionTab>
      </Accordion>

    </div>
  )
}

export default DispatchShipmentNotes;
