import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Col, Row, FormControl, HelpBlock, ControlLabel } from 'react-bootstrap';

// Actions
import * as ActionConstants from 'actions/ActionConstants';

// CSS
import styles from './RegisterForm.module.scss';

function RegisterForm(props) {
  const handleKeyPress = (e) => {
    if (e.charCode === 13) {
      props.register();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.registerForm}>
        <Form horizontal onKeyPress={handleKeyPress}>
          <FormGroup controlId="companyName" validationState={props.getValidationState('companyName')} >
            <Row className="justify-content-md-center">
                {/* <Col as={ControlLabel} sm={4}>
                  Company Name *
                </Col> */}
                <Col sm={12}>
                  <FormControl 
                    value={props.registerProperties.companyName}
                    type="text" 
                    placeholder="Company Name" 
                    onChange={(e) => props.handleChange('companyName', e.target.value)} 
                  />
                  <FormControl.Feedback />
                </Col>
            </Row>
          </FormGroup>
          {/* <FormGroup controlId="dotNumber" validationState={props.getValidationState('dotNumber')} >
            <Row className="justify-content-md-center">
              <Col as={ControlLabel} sm={4}>
                DOT #
              </Col>
              <Col sm={7}>
                <FormControl 
                  value={props.registerProperties.dotNumber}
                  type="text" 
                  placeholder="Enter your USDOT #" 
                  onChange={(e) => 
                  props.handleChange('dotNumber', e.target.value)} 
                />
                <FormControl.Feedback />
              </Col>
            </Row>
          </FormGroup> */}
          {/* <FormGroup controlId="nscNumber" validationState={props.getValidationState('nscNumber')} >
            <Row className="justify-content-md-center">
              <Col as={ControlLabel} sm={4}>
                NSC #
              </Col>
              <Col sm={7}>
                <FormControl 
                  value={props.registerProperties.nscNumber}
                  type="text" 
                  placeholder="Enter your NSC #" 
                  onChange={(e) => props.handleChange('nscNumber', e.target.value)} 
                />
                <FormControl.Feedback />
              </Col>
            </Row>
          </FormGroup> */}
          <Row className="justify-content-md-center">
            <Col>
              <FormGroup controlId="firstName" validationState={props.getValidationState('firstName')} >
                  {/* <Col as={ControlLabel} sm={4}>
                    First Name *
                  </Col> */}
                    <FormControl 
                      value={props.registerProperties.firstName}
                      type="text" 
                      placeholder="First Name" 
                      onChange={(e) => props.handleChange('firstName', e.target.value)} 
                    />
                    <FormControl.Feedback />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup controlId="lastName" validationState={props.getValidationState('lastName')} >
                {/* <Col as={ControlLabel} sm={4}>
                  Last Name *
                </Col> */}
                  <FormControl 
                    value={props.registerProperties.lastName}
                    type="text" 
                    placeholder="Last Name" 
                    onChange={(e) => props.handleChange('lastName', e.target.value)} 
                  />
                  <FormControl.Feedback />
               </FormGroup>
            </Col>
          </Row>
          <FormGroup controlId="phoneNumber" validationState={props.getValidationState('phoneNumber')} >
            <Row className="justify-content-md-center">
              {/* <Col as={ControlLabel} sm={4}>
                Phone Number *
              </Col> */}
              <Col sm={12}>
                <FormControl 
                  value={props.registerProperties.phoneNumber}
                  type="phone" 
                  placeholder="Phone Number" 
                  onChange={(e) => props.handleChange('phoneNumber', e.target.value)} 
                />
                <FormControl.Feedback />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup controlId="emailAddress" validationState={props.getValidationState('emailAddress')} >
            <Row className="justify-content-md-center">
              {/* <Col as={ControlLabel} sm={4}>
                Email Address *
              </Col> */}
              <Col sm={12}>
                <FormControl 
                  value={props.registerProperties.emailAddress}
                  type="email" 
                  placeholder="Email" 
                  onChange={(e) => props.handleChange('emailAddress', e.target.value)} 
                />
                <FormControl.Feedback />
              </Col>
            </Row>
          </FormGroup>
          

          <FormGroup controlId="password" validationState={props.getValidationState('password')}>
            <Row className="justify-content-md-center">
              {/* <Col as={ControlLabel} sm={4}>
                Password *
              </Col> */}
              <Col sm={12}>
                <FormControl
                  value={props.registerProperties.password}
                  type="password" 
                  placeholder="Password" 
                  onChange={(e) => props.handleChange('password', e.target.value)} 
                />
                <FormControl.Feedback />
                {/* {props.getValidationState('password') === 'error' &&
                  <HelpBlock>Must be 8 characters or longer</HelpBlock>
                } */}
                { props.getValidationState('password') && props.registerProperties.password.length > 2 && props.registerProperties.password.length < 8 &&
                  <div className={styles.error}>Must be 8 characters or longer</div>
                }
              </Col>
            </Row>
          </FormGroup>
        </Form>
        { props.registerError &&
          <div className={styles.error}>
            {props.registerError && props.registerError.message ? props.registerError.message : props.registerError }
          </div>
        }

        <div className={styles.footer}>
          <div>
            <button
              className={`buttonDefault ${styles.registerButton}`}
              onClick={props.register}
              disabled={
                props.User.status === ActionConstants.REGISTER_USER_INPROGRESS ||
                (props.getValidationState('companyName') === 'error' ||
                props.getValidationState('firstName') === 'error' ||
                props.getValidationState('lastName') === 'error' ||
                props.getValidationState('emailAddress') === 'error' ||
                props.getValidationState('password') === 'error')
              }
            >
              Create Free Account
            </button>
          </div>
          {/* <div className={styles.terms}>
            <table><tbody><tr>
              <td>
                <input checked={props.agreeTerms} onClick={props.handleAgreeTerms} type="checkbox" id="cbx" className={styles.checkbox} style={{ display: 'none' }} />
                <label htmlFor="cbx" className={styles.check}>
                  <svg width="18px" height="18px" viewBox="0 0 18 18">
                    <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
                    <polyline points="1 9 7 14 15 4" />
                  </svg>
                </label>
              </td>
              <td>
                <div className={styles.agreeText}>
                  By signing up, you agree to our <a href="https://www.onswitchboard.com/termsofservice" target="_blank">Terms</a> and <a href="https://www.onswitchboard.com/privacypolicy" target="_blank">Privacy Policy</a>
                </div>
              </td>
            </tr></tbody></table>
            <div className={styles.agreeText}>
              By signing up, you agree to our <a href="https://www.onswitchboard.com/termsofservice" target="_blank">Terms</a> and <a href="https://www.onswitchboard.com/privacypolicy" target="_blank">Privacy Policy</a>
            </div>
          </div> */}
          <div className={styles.agreeText}>
            By signing up, you agree to our <a href="https://www.onswitchboard.com/termsofservice" target="_blank">Terms</a> and <a href="https://www.onswitchboard.com/privacypolicy" target="_blank">Privacy Policy</a>
          </div>
        </div>
        
      </div>
    </div>
  );
}

RegisterForm.propTypes = {
  handleBack: PropTypes.func.isRequired,
  getValidationState: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  User: PropTypes.object.isRequired,
  registerError: PropTypes.object,
  agreeTerms: PropTypes.bool.isRequired,
  handleAgreeTerms: PropTypes.func.isRequired,
};

export default RegisterForm;
