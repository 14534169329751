/** @module DispatchJobAccounting */

// CSAPI API
import {
  copyQuery, count, createQuery, createQueryOr, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, getCurrentUser, addRecord,
} from 'sb-csapi/dist/AAPI';

// CSAPI sbObjects
import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// CSAPI Enums
import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

/**
 * @memberof module:DispatchJobAccounting
 *
 * @description Get DispatchJobAccounting records according to filter criteria
 *
 * @param {Object} options - See example
 * @param {String} companyObjectId - Company we wish to retrieve dispatch job accounting records for
 * @param {Array} filters - array of Filter objects
 * @param {Object} sortBy - Sort object
 * @param {Array} includedPointers - Included pointers
 * @param {Array} selectedAttributes - Select attributes to return
 * @param {Boolean} includeChildCompanies - Include child/sub-companies of this parent company (not yet implemented)
 * @param {Number} page - The current page for pagination
 * @param {Number} limit - The limit of records obtained per pagination
 * @param {Boolean} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { Object } - { dispatchJobAccounting: [DispatchJobAccounting, ...] }
 */
async function getDispatchJobAccountingRecords(options = { sessionToken: getCurrentUserSessionToken() }, companyObjectId = getCurrentUserCompanyObjectId(), includeChildCompanies, filters = [], sortBy = new Sort(QuerySortOrder.DESCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  const dispatchJobAccountingQuery = createQuery('DispatchJobAccounting');
  setQueryRestriction(dispatchJobAccountingQuery, QueryRestriction.EQUAL_TO, 'belongsToCompany', companyObjectId);

  const _filters = [...filters];
  _filters.map(filter => setQueryRestriction(dispatchJobAccountingQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  const dispatchJobAccountingCountQuery = copyQuery(dispatchJobAccountingQuery);

  // sort
  sortQuery(dispatchJobAccountingQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchJobAccountingQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchJobAccountingQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(dispatchJobAccountingQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(dispatchJobAccountingQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  const promises = [count(options, dispatchJobAccountingCountQuery), findRecords(options, dispatchJobAccountingQuery, false, queryAll)];

  try {
    const [totalDispatchJobAccountingsCount, dispatchJobAccountings] = await Promise.all(promises);
    return { totalDispatchJobAccountingsCount, dispatchJobAccountings };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchJobAccounting
 *
 * @description Update a DispatchJobAccounting record
 * @param {Object} options - Currently contains the current user session token
 * @param {DispatchJobAccounting} dispatchJobAccounting - The DispatchJobAccounting record to update. Use if dispatchJobAccountingObjectId is unavailable
 * @param {String} dispatchJobAccountingObjectId - The objectId of the dispatchJobAccounting record to update. Use if parameter dispatchJobAccounting is unavailable
 * @param {Object} keyValueObj - The select fields we wish to update
 * @param {Boolean} save - If true, executes save on the changes. If false, holds the changes locally but is not saved
 *
 * @returns {DispatchJobAccounting} - The updated DispatchJobAccounting record
 */
async function updateDispatchJobAccounting(options = { sessionToken: getCurrentUserSessionToken() }, dispatchJobAccounting, dispatchJobAccountingObjectId, keyValueObj, save) {
  if (!dispatchJobAccounting && !dispatchJobAccountingObjectId) throw new Error('Missing Arguments: Must provide dispatchJobAccounting record or dispatchJobObjectId');
  if (dispatchJobAccounting && dispatchJobAccountingObjectId) throw new Error('Arguments: Must provide only one of dispatchJobAccounting record or dispatchJobObjectId');

  let _dispatchJobAccounting = dispatchJobAccounting;

  try {
    if (!dispatchJobAccounting && dispatchJobAccountingObjectId) {
      const dispatchJobAccountingQuery = createQuery('DispatchJobAccounting');
      setQueryRestriction(dispatchJobAccountingQuery, QueryRestriction.EQUAL_TO, 'objectId', dispatchJobAccountingObjectId);
      _dispatchJobAccounting = await findRecords(options, dispatchJobAccountingQuery, true);
    }

    if (_dispatchJobAccounting) {
      if (!Object.keys(keyValueObj).length) return _dispatchJobAccounting;

      _dispatchJobAccounting = await updateRecord(options, _dispatchJobAccounting, keyValueObj, save);
      return _dispatchJobAccounting;
    } else {
      throw new Error('DispatchJobAccounting record does not exist');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getDispatchJobAccountingRecords,
  updateDispatchJobAccounting,
};
