import React from 'react';

// Styles
import './style.scss';

/**
* @description Renders a stationary vehicle icon with either a specified background color or the default color
* @param {String} [backgroundColorClassName] - The background color shown for the icon
* @param {Function} onClick - Callback that triggers when the map icon is clicked.
* @returns
*
* @example
* <StationaryIcon />
*/
function StationaryIcon({ ...props }) {
  return (
    <span className="stationary-icon fa-stack fa-2x" onClick={props.onClick}>
      <i className={`fas fa-circle fa-stack-2x ${props.backgroundColorClassName} default-background`} aria-hidden="true" />
      <i className="fas fa-truck fa-stack-1x icon" />
    </span>
  );
}

export default StationaryIcon;
