import React from 'react';

import { MultiSelect as PRMultiSelect } from 'primereact/multiselect';

// Styling
import './style.scss';

/**
 * @description PrimeReact MultiSelect component
 * @param {String} className - Custom container className
 */
export default function MultiSelect({ ...props }) {

  // ** Misc ** //
  let className = 'sbc-multi-select';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <PRMultiSelect {...props} />
    </div>
  );
}
