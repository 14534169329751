import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from "sbHistory";
import moment from 'moment-timezone';

// Actions
import { disableDriver } from 'actions/Driver';

// API
import { getDriverById, getCompanyDispatchers, getCurrentUser, getMostRecentEldEventForDriver } from 'api/Getters';
import * as Helpers from 'api/Helpers';
import * as Analytics from 'api/Analytics';
import { getQueryParameter } from 'api/URL';
import { getAttribute } from 'api/Parse';
import {
  getCompanyAnalytics,
  updateCompanyAnalytics,
} from 'api/CompanyAnalytics/CompanyAnalytics';
import { doesDriverBelongToCompany } from 'sb-csapi/dist/api/Driver/Driver';
import { getCurrentUserSessionToken, getCurrentUserCompanyObjectId } from 'sb-csapi/dist/AAPI';

// Components
import Sidebar from './Sidebar';
import SummaryLayout from './SummaryLayout';
import DriverHoursDetailsLayout from './DriverHoursDetailsLayout';
import HoursOfServiceAndEventsLayout from './HoursOfServiceAndEventsLayout';
import HoursOfServiceViolationsLayout from './HoursOfServiceViolationsLayout';
import TripInspectionsLayout from './TripInspectionsLayout';
import DriverDocumentsLayout from './DriverDocumentsLayout';
import ReceiptsLayout from './ReceiptsLayout';
import SafetyLayout from './SafetyLayout';
import VehiclePreferencesLayout from './VehiclePreferencesLayout';

// CSS
import styles from './DriverDetailsLayout.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

class DriverDetailsLayout extends React.Component {
  constructor(props) {
    super(props);
    let tempView = getQueryParameter(props.location.search, "view");
    let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    let isMobile = (width > 768 ? false : true);
    this.state = {
      title: 'Loading...',
      driver: undefined,
      activeStatus: undefined,
      subViewSelected: tempView ? tempView : 'summary',
      dispatchers: [],
      driverFetched: false,
      eldModuleSubscribed: false,
      driverDocumentModuleSubscribed: false,
      isMobile: isMobile,
      isSidebarOpen: !isMobile,
      companyAnalytics: undefined,
    };
    this.setNewDriverState = this.setNewDriverState.bind(this);
    this.refreshDriverInfo = this.refreshDriverInfo.bind(this);
    this.handleSubViewClick = this.handleSubViewClick.bind(this);
    this.detailsUpdated = this.detailsUpdated.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  async componentDidMount() {
    // check if driver belongs to company
    const driverObjectId = getQueryParameter(history?.location?.search, 'driver');
    const companyObjectId = getCurrentUserCompanyObjectId();
    const _doesDriverBelongToCompany = await doesDriverBelongToCompany(getCurrentUserSessionToken(), driverObjectId, companyObjectId);

    if (!_doesDriverBelongToCompany) history.push({ pathname: 'drivers' });

    document.title = t('Driver Details - Switchboard');
    const userSpecialPermission = getCurrentUser(true).userSpecialPermission;
    if (userSpecialPermission && userSpecialPermission.disableDriversModule) {
      history.push({ pathname: '404' });
    } else {
      const companyAnalytics = await getCompanyAnalytics();
      this.setState({ companyAnalytics }, () => this.refreshDriverInfo(getQueryParameter(this.props.location.search, 'driver')));
    }
  }

  componentWillReceiveProps(nextProps) {
    let tempView = getQueryParameter(nextProps.location.search, 'view');
    this.setState({ ...this.state, subViewSelected: tempView ? tempView : 'summary', eldModuleSubscribed: Helpers.isSubscribedToModule('eldModule'), driverDocumentModuleSubscribed: Helpers.isSubscribedToModule('driverDocumentModule') });
  }

  async setNewDriverState(driverObject) {
    const newState = { ...this.state };
    const currentDriver = driverObject;
    const driverUser = currentDriver.get('user');
    newState.driver = currentDriver;
    newState.activeStatus = false;
    if (currentDriver.get('driverStatus') && currentDriver.get('driverStatus').get('referenceInt') === 2) {
      newState.activeStatus = true;
    }
    newState.title = `${driverUser.get('firstName')} ${driverUser.get('lastName')}`;
    document.title = `${newState.title} - Switchboard`;

    // Fix for driver.latestELDEvent not being the actual latest
    // Remove this if driver.latestELDEvent matches driver - it isfilled out in vehiclelocation-microservice
    const mostRecentELDEvent = await getMostRecentEldEventForDriver(driverObject.id, ['eldDailyCertification']);
    newState.driver.set('latestELDEvent', mostRecentELDEvent);

    getCompanyDispatchers().then(
      dispatchers => {
        const filteredDispatchers = (dispatchers || []).filter(dispatcher => dispatcher.get('enabled') && dispatcher.get('user') && dispatcher.get('user').id);
        newState.dispatchers = filteredDispatchers; // we pass all dispatchers and in the view filter out only the ones that belong to this driver
        newState.driverFetched = true;
        newState.eldModuleSubscribed = Helpers.isSubscribedToModule('eldModule');
        newState.driverDocumentModuleSubscribed = Helpers.isSubscribedToModule('driverDocumentModule');
        this.setState(newState);
      }
    );
  }

  disableDriver(confirmBool) {
    const newState = { ...this.state };
    newState.confirmDeleteModalShow = false;
    this.setState(newState);
    if (confirmBool) {
      disableDriver(this.state.driver).then(() => {
        history.push('drivers');
      });
    }
  }

  async refreshDriverInfo(driverObjectId, driverObject) {
    // if we're supplied the driverObject, no need to re-query
    if (driverObject) {
      this.setNewDriverState(driverObject);
      return;
    }
    this.setState({ ...this.state, driverFetched: false }, async () => {
      const pDriverObject = await getDriverById(driverObjectId);
      if (!pDriverObject) {
        return;
      }
      if (getAttribute(pDriverObject, 'isHidden')) {
        return history.push('drivers');
      }
      this.setNewDriverState(pDriverObject);
    });
  }

  async handleSubViewClick(subview) {
    if (subview === 'hosEvents') {
      // Check company analytics status
      const companyAnalytics = this.state.companyAnalytics;
      const VIEWLOGS = getAttribute(companyAnalytics, 'VIEWLOGS');
      if (!VIEWLOGS) {
        Analytics.track('Logs Viewed');
        await updateCompanyAnalytics(companyAnalytics, { VIEWLOGS: true });
      }
    }
    let tempDriver = getQueryParameter(this.props.location.search, 'driver');
    history.push({ pathname: 'driver', search: "driver=" + tempDriver + "&view=" + subview });
  }

  detailsUpdated() {
    this.refreshDriverInfo(this.state.driver.id);
  }

  toggleSidebar() {
    this.setState({ ...this.state, isSidebarOpen: !this.state.isSidebarOpen });
  }

  render() {
    const { eldModuleSubscribed, driverDocumentModuleSubscribed, driver, dispatchers, driverFetched, subViewSelected } = this.state;
    const { User } = this.props;

    const user = User && User.user;
    const driverObjectId = getQueryParameter(history?.location?.search, 'driver');

    window.Localize.translatePage();

    return (
      <div className="d-flex">
        <Sidebar
          eldModuleSubscribed={eldModuleSubscribed}
          driverDocumentModuleSubscribed={driverDocumentModuleSubscribed}
          isOpen={this.state.isSidebarOpen}
          driver={driver}
          driverObjectId={driverObjectId}
          toggleSidebar={this.toggleSidebar}
          callingUser={user}
          company={this.props.Company && this.props.Company.company}
          driverFetched={driverFetched}
          activeSubview={subViewSelected}
          handleSubViewClick={this.handleSubViewClick}
          title={this.state.title}
          activeStatus={this.state.activeStatus}
        />
        <div className={styles.content + (this.state.isSidebarOpen ? ' ' + styles.sideOpen : '')}>
          {subViewSelected === 'summary' &&
            <SummaryLayout driver={driver} driverFetched={driverFetched} dispatchers={dispatchers} detailsUpdatedHandler={this.detailsUpdated} />
          }
          {subViewSelected === 'driverHoursDetails' && driverFetched && eldModuleSubscribed && driver &&
            <DriverHoursDetailsLayout driver={driver} driverFetched={driverFetched} location={this.props.location} eldModuleSubscribed={eldModuleSubscribed} />
          }
          {subViewSelected === 'hosEvents' && driverFetched && eldModuleSubscribed && driver &&
            <HoursOfServiceAndEventsLayout driver={driver} driverFetched={driverFetched} location={this.props.location} eldModuleSubscribed={eldModuleSubscribed} />
          }
          {subViewSelected === 'hosViolations' && driverFetched && eldModuleSubscribed && driver &&
            <HoursOfServiceViolationsLayout driver={driver} driverFetched={driverFetched} location={this.props.location} eldModuleSubscribed={eldModuleSubscribed} />
          }
          {subViewSelected === 'tripInspections' && driverFetched && eldModuleSubscribed && driver &&
            <TripInspectionsLayout driver={driver} eldModuleSubscribed={eldModuleSubscribed} location={this.props.location} />
          }
          {subViewSelected === 'documents' && driverFetched && eldModuleSubscribed && driver &&
            <DriverDocumentsLayout driver={driver} eldModuleSubscribed={eldModuleSubscribed} location={this.props.location} />
          }
          {subViewSelected === 'receipts' &&
            <ReceiptsLayout driverObjectId={driver.id} />
          }
          {subViewSelected === 'safety' &&
            <SafetyLayout />
          }
          {subViewSelected === 'vehiclePreferences' &&
            <VehiclePreferencesLayout driver={driver} eldModuleSubscribed={eldModuleSubscribed} location={this.props.location} />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { Company, Subscription, User } = state;
  return {
    Company,
    Subscription,
    User,
  };
};


DriverDetailsLayout.propTypes = {
  location: PropTypes.object.isRequired,
  Company: PropTypes.object,
  User: PropTypes.object,
  Subscription: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(DriverDetailsLayout);
