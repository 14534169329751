import React from 'react';
import moment from 'moment-timezone';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import history from '../../../sbHistory';
import { Card, Collapse } from 'react-bootstrap';
import { Toggle } from 'material-ui';
import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact';

// API
import * as Helpers from 'api/Helpers';
import { getPDispatcherPropertyFromState } from 'api/Getters';

// Components

import styles from './TripList.module.scss';

function getDistanceString(distance, removeSpaceBool) {
  const distanceUnit = getPDispatcherPropertyFromState('distanceUnit');
  if (distanceUnit === 'km') {
    if (removeSpaceBool) {
      return `${distance.toFixed(0)}km`;
    }
    return `${distance.toFixed(0)} km`;
  }

  if (removeSpaceBool) {
    return `${parseFloat(Helpers.convertDistance(distance, 'km', 'mi', true).toFixed(0))}mi`;
  }
  return `${parseFloat(Helpers.convertDistance(distance, 'km', 'mi', true).toFixed(0))} mi`;
}

function TripList(props) {
  const tripArr = props.activeVehicleHistory.tripArr;
  let totalDistance = 0;
  if (
    props.activeVehicleHistory &&
    props.activeVehicleHistory.legs && props.activeVehicleHistory.legs.length > 0 &&
    props.activeVehicleHistory.legs[0].results && props.activeVehicleHistory.legs[0].results.length > 0
  ) {
    for (let i = 0; i < props.activeVehicleHistory.legs[0].results.length; i++) {
      totalDistance += props?.activeVehicleHistory?.legs[0]?.results[0]?.distance || 0;
    }
  }

  if (tripArr && tripArr.length > 0) {
    let totalSpeedApproxDistanceKm = 0;
    for (let i = 0; i < tripArr.length; i++) {
      if (tripArr[i].speedApproxDistanceKm) {
        totalSpeedApproxDistanceKm += tripArr[i].speedApproxDistanceKm;
      }
    }

    return (
      <div className={props.isSidebarOpen ? `${styles.container} ${styles.sidebarOpen} d-none d-lg-block` : `${styles.container} ${styles.sidebarClosed} d-none d-lg-block`}>
        <div className={styles.title}>
          Trip <b>{(Helpers.isSubscribedToModule('iftaModule') && totalDistance) ? `(${(getDistanceString(totalDistance / 1000))})` : ''}</b>
          <br />
        </div>
        {tripArr && tripArr.map((tripObj, index) => {
          const startDateMoment = tripObj.start.vehicleLocationObj && moment(tripObj.start.vehicleLocationObj.get('dateTime'));
          const endDateMoment = tripObj.end.vehicleLocationObj && moment(tripObj.end.vehicleLocationObj.get('dateTime'));
          const driver =
            tripObj?.start?.vehicleLocationObj?.get('drivers')?.length > 0 ?
              tripObj?.start?.vehicleLocationObj?.get('drivers')[0] : null;
          let nextEndDateMoment;
          let breakDiffHours;
          if (index !== tripArr.length - 1) {
            nextEndDateMoment = tripArr[index + 1].start.vehicleLocationObj && moment(tripArr[index + 1].start.vehicleLocationObj.get('dateTime'));
            // console.log(
            //   tripObj.start.vehicleLocationObj.get('dateTime')
            // )
            breakDiffHours = moment(nextEndDateMoment).diff(moment(endDateMoment), 'hours', true);
          }
          const diffHours = moment(endDateMoment).diff(moment(startDateMoment), 'hours', true);

          // console.log(
          //   tripObj.speedApproxDistanceKm,
          //   totalSpeedApproxDistanceKm,
          //   !isNaN(tripObj.speedApproxDistanceKm)
          // );
          // console.log(
          //   tripObj.speedApproxDistanceKm,
          //   totalSpeedApproxDistanceKm,
          //   totalDistance,
          //   (tripObj.speedApproxDistanceKm / totalSpeedApproxDistanceKm) * totalDistance / 1000
          // );

          return (
            <div className={styles.trip} key={tripObj.start.vehicleLocationObj && tripObj.start.vehicleLocationObj.id}>
              <table className={styles.tripTable}>
                <tr>
                  <td>
                    <div>
                      <div className={styles.addressString}>{tripObj.start.addressString}</div>
                      <div className={styles.dateTime}>{startDateMoment && startDateMoment.format('lll')}</div>
                    </div>
                    <div>
                      <div className={styles.addressString}>{tripObj.end.addressString}</div>
                      <div className={styles.dateTime}>{endDateMoment && endDateMoment.format('lll')}</div>
                    </div>
                  </td>
                  <td className={styles.timeDiffTd}>
                    {tripObj.start.vehicleLocationObj && tripObj.end.vehicleLocationObj &&
                      (
                        <kbd>
                          {Math.floor(diffHours)}h
                          {Math.round((diffHours - Math.floor(diffHours)) * 60)}m
                        </kbd>
                      )
                    }
                    <br />
                    {Helpers.isSubscribedToModule('iftaModule') &&
                      tripObj.speedApproxDistanceKm &&
                      totalSpeedApproxDistanceKm &&
                      !isNaN(tripObj.speedApproxDistanceKm) &&
                      (
                        <kbd>
                          {getDistanceString((tripObj.speedApproxDistanceKm / totalSpeedApproxDistanceKm) * totalDistance / 1000, true)}
                        </kbd>
                      )
                    }
                  </td>
                </tr>
                {driver &&
                  (
                    <tr rowSpan={69}>
                      <div className={styles.driver}>
                        <br />
                        <i className="pi pi-user ml-1 mr-1" />
                        <a target="_blank" href={`driver?driver=${driver.id}&view=hosEvents&date=${moment(Helpers.referenceToTimezone(startDateMoment.toDate()), driver.get('timezoneOffsetFromUTC')).format('DDMMYY')}`}>
                          {Helpers.toTitleCase(driver.get('user_fullName'))}
                        </a>
                      </div>
                    </tr>
                  )
                }
              </table>
              {
                index !== tripArr.length - 1 &&
                <div>
                  <hr />
                  <table className={styles.tripTable}><tr>
                    <td>
                      <div className={styles.addressString}>
                        Break:
                      </div>
                    </td>
                    <td className={styles.timeDiffTd}>
                      {tripObj.start.vehicleLocationObj && tripObj.end.vehicleLocationObj &&
                        (
                          <kbd>
                            {Math.floor(breakDiffHours)}h
                            {Math.round((breakDiffHours - Math.floor(breakDiffHours)) * 60)}m
                          </kbd>
                        )
                      }
                    </td>
                  </tr></table>
                </div>
              }
              {
                index !== tripArr.length - 1 &&
                <hr />
              }
            </div>
          );
        })
        }
      </div >
    );
  }
  return null;
}

TripList.propTypes = {
  activeVehicle: PropTypes.object,
  activeVehicleHistory: PropTypes.object,
};

export default TripList;
