export default class DispatchJobNotes {
  /**
 * @param {object} dispatchJob Dispatch Job parse object
 * @param {string} notesGeneral General notes
 * @param {string} notesDriver Driver notes
 * @param {string} notesInvoice Invoice notes
 * @param {string} notesBilling Billing notes
 * @param {string} notesDispatch Dispatch notes
   */
  constructor(dispatchJob, notesGeneral, notesDriver, notesInvoice, notesBilling, notesDispatch) {
    this.dispatchJob = dispatchJob;
    this.notesGeneral = notesGeneral;
    this.notesDriver = notesDriver;
    this.notesInvoice = notesInvoice;
    this.notesBilling = notesBilling;
    this.notesDispatch = notesDispatch;
  }
}
