import React from 'react';
import PropTypes from 'prop-types';
import { Feature, Marker } from 'react-mapbox-gl';

import styles from './ComponentStyles.module.scss';

function GPSPoint(props) {
  return (
    <Layer
      type="circle"
      // type="symbol"
      id="gps-points"
      layout={{
        // 'icon-size': 1,
        // 'icon-image': 'circle-11',
      }}
      paint={{
        'circle-radius': 3,
        'circle-color': legObject.colour,
        'circle-blur': 0.2,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#364554',
        'circle-stroke-opacity': 0.8,
      }}
      before="trailers-markers-offDutyTrailers"
    >
      {!legObject.connector && legObject.vehicleLocationPointsArr
        .filter((vehicleLocationObj, gpsPointIndex) => (
          (!this.state.follow.active || this.state.follow.active && gpsPointIndex !== legObject.vehicleLocationPointsArr.length - 1) && // ignore most recent GPS point 
          (vehicleLocationObj && vehicleLocationObj.get('location'))
        ))
        .map((vehicleLocationObj, gpsPointIndex) => {
          // if (vehicleLocationObj.get('jobAction')) {
          //   return (
          //     // POI Markers
          //     <CompletedMarker
          //       key={`pointOfInterest${gpsPointIndex}`}
          //       color={legObject.colour}
          //       coordinates={Helpers.convertParseVehicleLocationArrToPointArr([vehicleLocationObj], true)}
          //       handleOnClick={() => this.props.setPopup({ vehicleLocation: vehicleLocationObj, type: 'jobAction' })}
          //     />
          //   );
          // }
          if (this.state.follow.active && gpsPointIndex === legObject.vehicleLocationPointsArr.length - 1) return undefined;
          return (
            // GPS Location Points
            <Feature
              key={`gpsPoint${gpsPointIndex}`}
              onMouseEnter={() => this.props.setGPSPointPopup(true, { vehicleLocation: vehicleLocationObj })}
              onMouseLeave={() => this.props.setGPSPointPopup(false, { vehicleLocation: vehicleLocationObj })}
              coordinates={Helpers.convertParseVehicleLocationArrToPointArr([vehicleLocationObj], true)}
              color={legObject.colour}
            />
          );
        })}
    </Layer>
  );
}

GPSPoint.propTypes = {
  coordinates: PropTypes.array.isRequired,
  color: PropTypes.string.isRequired,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
};

export default GPSPoint;
