/* eslint-disable react/jsx-no-bind */
import uniqid from 'uniqid';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

// API
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getVendorIntegration, requestVendorIntegration } from 'api/VendorIntegration/VendorIntegration';

// Components
import Card from 'sbCore/Card/Card';
import Button from 'sbCore/Button/Button';
import Column from 'sbCore/Column/Column';
import DataTable from 'sbCore/DataTable/DataTable';
import { ConfirmPopup, confirmPopupSettings } from 'sbCore/ConfirmPopup/ConfirmPopup';
import APIKeyCard from './APIKeyCard';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import Chip from 'sbCore/Chip/Chip';


import APIKeyGeneratorModal from 'components/APIKeyGeneratorModal/APIKeyGeneratorModal';

/**
 * Holds the layout for displaying API credentials
 *
 * @returns {JSX}
 */
function IntegrationsLayout() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [showRequestIntegrationModal, setShowRequestIntegrationModal] = useState(false);
  const [vendorIntegrationInformationArr, setVendorIntegrationInformationArr] = useState([]);

  // Triggers a referesh of the API credentials
  const [refreshToken, setRefreshToken] = useState(uniqid());

  useEffect(() => {
    let didCancel = false;

    async function getVendorIntegrationForState() {
      setIsLoading(true);
      const vendorIntegrationObj = await getVendorIntegration();

      const _vendorIntegrationInformationArr = [
        {
          label: 'ISG / ASKK',
          enabled: !!getAttribute(vendorIntegrationObj, 'isg', true),
        },
        {
          label: 'FourKites',
          enabled: !!getAttribute(vendorIntegrationObj, 'fourKites', true),
        },
        {
          label: 'Project44',
          enabled: !!getAttribute(vendorIntegrationObj, 'p44', true),
        },
        {
          label: 'Trucker Tools',
          enabled: !!getAttribute(vendorIntegrationObj, 'truckerTools', true),
        },
        {
          label: 'Macropoint',
          enabled: !!getAttribute(vendorIntegrationObj, 'macropointUsername', true),
        },
      ];

      if (!didCancel) {
        setVendorIntegrationInformationArr(_vendorIntegrationInformationArr);
        setIsLoading(false);
      }
    }

    getVendorIntegrationForState();
    return () => {
      didCancel = true;
    };
  }, [refreshToken]);

  function labelBodyTemplate(rowData) {
    return rowData.label;
  }

  function enabledBodyTemplate(rowData) {
    if (rowData.enabled) {
      return <Chip label={'Enabled'} />
    } else {
      return (
        <Button
          sbVariant="slim"
          className="p-button-secondary"
          icon={<span className="material-icons mr-1">call</span>}
          label="Request an Integration"
          onClick={() => { setSelectedIntegration(rowData.label); setShowRequestIntegrationModal(true) }}
        />
      );
    }
  }


  const header = (
    <div className="flex flex-row justify-content-between align-items-end px-3 pt-4">
      <h4 className="font-bold mb-0">Integrations</h4>
      <a
        href="https://support.onswitchboard.com/support/solutions/articles/43000710524-tracking-integration-set-up"
        target="_blank"
      >
        <Button
          sbVariant="slim"
          className="p-button"
          icon={<span className="material-icons mr-1">help</span>}
          label="Learn how to track properly through FourKites, Project44, Macropoint"
        />
      </a>
    </div>
  );

  function handleRequestIntegration() {
    requestVendorIntegration(selectedIntegration);
    setShowRequestIntegrationModal(false);
  }

  return (
    <>
      <ConfirmModal
        title={'Request Integration'}
        show={showRequestIntegrationModal}
        handleModalChoice={handleRequestIntegration}
        handleClose={() => setShowRequestIntegrationModal(false)}
      // closeMode={closeMode}
      >
        <div>
          <div style={{ marginTop: '-1em' }}>
            <b>Confirm your request for {selectedIntegration || ''} integration</b>
          </div>

          <div style={{ marginTop: '1em' }}>
            For more information on integrations:
            <ul>
              <li>Email us at <a href="mailto:sales@onswitchboard.com" target="_blank">sales@onswitchboard.com</a></li>
              <li>Live Chat (chat icon on the bottom right of the screen)</li>
              <li>Call us at 1-844-5-FLEETS (1-844-535-3387)</li>
              <li>Visit our <a href="http://support.onswitchboard.com" target="_blank">knowledgebase</a></li>
            </ul>
          </div>
        </div>
      </ConfirmModal>

      <Card
        header={header}
      >
        <DataTable
          value={vendorIntegrationInformationArr}
          // emptyMessage="No API keys were found. Create a new API key to see it here."
          loading={isLoading}
          rowHover
        >
          <Column
            field={labelBodyTemplate}
            header="Integration"
          />
          <Column
            field={enabledBodyTemplate}
            header=""
          />
        </DataTable>
      </Card>

      <br />

      <APIKeyCard />

    </>
  );
}

export default IntegrationsLayout;
