import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// API
import { toTitleCase } from 'api/Helpers';
import { generateHOSViolationMonthlyReport } from 'api/ELD';
import { getQueryParameter } from 'api/URL';

// Components
import FilterDropdown from 'components/FilterDropdown/view/FilterDropdown';
import Checkbox from 'sbCore/Checkbox/Checkbox';
import FullMonthView from '../view/FullMonthView';
import ViolationDaysView from '../view/ViolationDaysView';
import ViolationDetailsView from '../view/ViolationDetailsView';
import SBSelect from 'components/Shared/SBSelect/SBSelect';

class HOSViolationsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: [],
      generatingHOSViolationMonthlyReport: false,
      tableType: { key: 1, value: 'violationDays', label: t('Violation Days') },
      tableTypeItems: [
        { key: 0, value: 'allDays', label: t('All Days') },
        { key: 1, value: 'violationDays', label: t('Violation Days') },
        { key: 2, value: 'violationDetails', label: t('Violation Details') },
      ],
      violationFilter: { key: 1, value: 'hideHidden', label: t('Hide Hidden Violations') },
      tableShowSummary: false,
      violationFilterItems: [
        {
          key: 0,
          value: 'showAll',
          label: t('Show All Violations'),
        },
        {
          key: 1,
          value: 'hideHidden',
          label: t('Hide Hidden Violations'),
        },
      ],
      violationsNonZeroBool: false,
      downloadURL: undefined,
    };
    this.generateHandler = this.generateHandler.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.updateNote = this.updateNote.bind(this);
    this.hideUnhideHosViolation = this.hideUnhideHosViolation.bind(this);
    this.setViolationsNonZeroBool = this.setViolationsNonZeroBool.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.eldDailyCertificationArr !== nextProps.eldDailyCertificationArr) {
      this.setState({ ...this.state, violationsNonZeroBool: false });
      // console.log(nextProps.eldDailyCertificationArr);
    }
  }

  setViolationsNonZeroBool(resetBool) {
    if (resetBool) {
      this.setState({ ...this.state, violationsNonZeroBool: false });
    } else if (!this.state.violationsNonZeroBool) {
      this.setState({ ...this.state, violationsNonZeroBool: true });
    }
  }

  generateHandler() {
    this.setState({ ...this.state, generatingHOSViolationMonthlyReport: true });
    generateHOSViolationMonthlyReport(
      (this.props.eldDailyCertificationArr && this.props.eldDailyCertificationArr.length > 0) ? this.props.eldDailyCertificationArr[0].get('startTimeUTC') : this.props.date.toDate(),
      this.props.driver.id,
      this.state.violationFilter.value === 'hideHidden'
    ).then((parseFileObj) => {
      window.open(parseFileObj.get('file').url(), '_blank');
      this.setState({ ...this.state, generatingHOSViolationMonthlyReport: false });
    });
  }

  handleViewChange(value) {
    for (let i = 0; i < this.state.menuItems.length; i++) {
      if (this.state.menuItems[i].attrName === value) {
        this.setState({ ...this.state, view: this.state.menuItems[i].placeholder });
        return;
      }
    }
  }

  hideUnhideHosViolation(hosViolation, hideBool) {
    const overrideHideForever = getQueryParameter(null, 'overrideHideForever');
    if (overrideHideForever == 'true') {
      hosViolation.set('hideForever', hideBool);
    }
    hosViolation.set('isHidden', hideBool);
    this.setState({ ...this.state, loading: true }, async () => {
      await hosViolation.save();
      this.setState({ ...this.state, loading: false });
    });
  }

  updateNote(e, hosViolation) {
    hosViolation.set('note', e.target.value);
    this.setState(this.state);
    hosViolation.save();
  }

  render() {
    const timezoneOffsetFromUTC = this.props.driver.get('timezoneOffsetFromUTC');

    return (
      <div>
        <div style={{ float: 'right', marginBottom: '2em' }}>
          {/* <SBSelect
            containerClassName="ml-2 mr-2 d-inline-block"
            className="sb-jobs-list-select"
            defaultToggleText={this.state.tableType.label}
            items={this.state.tableTypeItems}
            getSelectedItems={(selectedFilter) => {
              this.setState({ ...this.state, tableType: selectedFilter[0] });
            }}
            showFilter
          /> */}
          <table>
            <tr>
              <td>
                <Checkbox
                  inputId="violationdetails-checkbox"
                  checked={this.state.tableShowSummary}
                  onChange={e => this.setState({ ...this.state, tableShowSummary: e.checked })}
                />
              </td>
              <td>
                <label
                  className="text"
                  htmlFor="violationdetails-checkbox"
                  style={{ margin: '0 auto ' }}
                >
                  Show HOS Violation Summary
                </label>
              </td>
              <td>
                <SBSelect
                  containerClassName="ml-2 mr-2 d-inline-block"
                  className="sb-jobs-list-select"
                  defaultToggleText={this.state.violationFilter.label}
                  items={this.state.violationFilterItems}
                  getSelectedItems={(selectedFilter) => {
                    this.setState({ ...this.state, violationFilter: selectedFilter[0] });
                  }}
                  showFilter
                />
              </td>
            </tr>
          </table>
        </div>
        <div style={{ clear: 'both' }}>
          {/* {this.state.tableType.value === 'allDays' &&
            <FullMonthView
              {...this.props}
              view={this.state.view}
              generatingHOSViolationMonthlyReport={this.state.generatingHOSViolationMonthlyReport}
              handleViewChange={this.handleViewChange}
              generateReport={this.generateHandler}
              violationsNonZeroBool={this.state.violationsNonZeroBool}
              setViolationsNonZeroBool={this.setViolationsNonZeroBool}
              timezoneOffsetFromUTC={timezoneOffsetFromUTC}
              selectedViolationFilter={this.state.violationFilter}
            />
          } */}
          {this.state.tableShowSummary === true &&
            <ViolationDaysView
              {...this.props}
              view={this.state.view}
              generatingHOSViolationMonthlyReport={this.state.generatingHOSViolationMonthlyReport}
              handleViewChange={this.handleViewChange}
              generateReport={this.generateHandler}
              violationsNonZeroBool={this.state.violationsNonZeroBool}
              setViolationsNonZeroBool={this.setViolationsNonZeroBool}
              timezoneOffsetFromUTC={timezoneOffsetFromUTC}
              selectedViolationFilter={this.state.violationFilter}
            />
          }
          {this.state.tableShowSummary === false &&
            <ViolationDetailsView
              {...this.props}
              view={this.state.view}
              generatingHOSViolationMonthlyReport={this.state.generatingHOSViolationMonthlyReport}
              handleViewChange={this.handleViewChange}
              generateReport={this.generateHandler}
              violationsNonZeroBool={this.state.violationsNonZeroBool}
              setViolationsNonZeroBool={this.setViolationsNonZeroBool}
              timezoneOffsetFromUTC={timezoneOffsetFromUTC}
              selectedViolationFilter={this.state.violationFilter}
              hideUnhideHosViolation={this.hideUnhideHosViolation}
              updateNote={this.updateNote}
            />
          }
        </div>
      </div>
    );
  }
}

HOSViolationsTable.propTypes = {
  eldDailyCertificationArr: PropTypes.array.isRequired,
  driver: PropTypes.object.isRequired,
  fetched: PropTypes.bool.isRequired,
};

export default HOSViolationsTable;
