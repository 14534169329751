export const AdminTypes = Object.freeze({
  'ADMIN': {
    type: 0, // corresponding int in userType in db
    description: 'Administrator',
    rank: 0, // ranks determine precedence over others
  },
  'ADMIN_LIMITED': {
    type: 7,
    description: 'Administrator - Limited',
    rank: 1,
  },
  'SAFETY_OFFICER': {
    type: 3,
    description: 'Safety Officer',
    rank: 2,
  },
});

export const UserTypes = Object.freeze({
  'DRIVER': {
    type: 4, // corresponding int in userType in db
    description: 'Administrator',
    rank: undefined,
  }
});

/**
 * @description Given two user type's (userType, compareToUserType) from an userA and userB
 * determine if type > compareToType
 */
export function isUserTypeHigherRank(userType = [], compareToUserType = [], isInclusive) {

  let userTypeRanks = [], compareToUserTypeRanks = [], rank, rankToCompare;
  const adminTypeKeys = Object.keys(AdminTypes);

  userType.map(type => {
    adminTypeKeys.find(key => {
      const adminType = AdminTypes[key];
      if (adminType.type === type) {
        userTypeRanks.push(adminType.rank);
        return true;
      }
    });
  });

  compareToUserType.map(type => {
    adminTypeKeys.find(key => {
      const adminType = AdminTypes[key];
      if (adminType.type === type) {
        compareToUserTypeRanks.push(adminType.rank);
        return true;
      }
    });
  });

  // now get the highest ranking level (ie. lowest rank value)
  // for both the rank and rankToCompare, and compare them both
  rank = Math.min(...userTypeRanks);
  rankToCompare = Math.min(...compareToUserTypeRanks);

  if (isInclusive) {
    return rank <= rankToCompare;
  }
  return rank < rankToCompare;
}