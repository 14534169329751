import { useEffect, useState } from 'react';

import uniqid from 'uniqid';
import moment from 'moment-timezone';

// Enums
import { LengthUnit } from 'sb-csapi/dist/enums/Unit';

// API
import * as Helpers from 'api/Helpers';
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { getTimezoneAbbreviation } from 'api/utils/timezone';

// Components
import Button from 'sbCore/Button/Button';
import Card from 'sbCore/Card/Card';
import Calendar from 'sbCore/Calendar/Calendar';
import Divider from 'sbCore/Divider/Divider';
import Timeline from 'sbCore/Timeline/Timeline';
import ScrollPanel from 'sbCore/ScrollPanel/ScrollPanel';
import Tag from 'sbCore/Tag/Tag';

// CSS
import './style.scss';

/**
 * A card that displays a vehicle's trip history.
 * 
 * @param {Vehicle} vehicle - The vehicle object
 * @param {Object} vehicleHistory - An object describing the vehicle's trip history
 * @param {bool} isShown - Whether or not the card should be shown
 * @param {bool} isTripSelectionDisabled - Whether or not to disable the trip selection
 * 
 * @example
 *  <TripHistoryCard
 *     isShown
 *     vehicle={vehicle}
 *     vehicleHistory={state.activeObjectDetails.activeVehicleHistory}
 *   />
 */
function TripHistoryCard(props) {
  const [trips, setTrips] = useState();
  const [selectedDate, setSelectedDate] = useState(moment().toDate());
  const [selectedTrip, setSelectedTrip] = useState(undefined);
  const [distanceUnit, setDistanceUnit] = useState(LengthUnit.MI);

  useEffect(() => {
    const distanceUnit = getPDispatcherPropertyFromState('distanceUnit');
    let lengthUnit = Object.values(LengthUnit).find((unit) => unit?.toLowerCase() === distanceUnit?.toLowerCase());
    if (lengthUnit) setDistanceUnit(lengthUnit);

    parseTripHistory(props.vehicleHistory.tripArr);
  }, [props.vehicleHistory]);

  useEffect(() => {
    props?.onDateSelected(selectedDate);
  }, [selectedDate]);

  useEffect(() => {
    if (props?.onSelectTrip) props.onSelectTrip(selectedTrip);
  }, [selectedTrip]);

  function getHourMinuteBreakdown(minutes) {
    const hours = Math.floor(minutes / 60);
    const minutesRemainder = minutes % 60;
    return `${hours}h ${minutesRemainder}m`;
  }

  /**
   * Parses a given trip history into a standard format. This is here in case tripArr is refactored in the future.
   * 
   * @param {Array} tripsArr - Array of trip objects
   * @returns 
   */
  function parseTripHistory(tripsArr) {
    if (!tripsArr || tripsArr?.length === 0) return setTrips([]);

    let totalDistanceKm = 0;
    let totalSpeedApproxDistanceKm = 0;

    if (props?.vehicleHistory?.legs?.[0]?.results?.length > 0) {
      for (let i = 0; i < props.vehicleHistory.legs[0].results.length; i++) {
        totalDistanceKm += props?.vehicleHistory?.legs[0].results[0]?.distance || 0;
      }
    }

    for (let i = 0; i < tripsArr.length; i++) {
      if (tripsArr[i].speedApproxDistanceKm) {
        totalSpeedApproxDistanceKm += tripsArr[i].speedApproxDistanceKm;
      }
    }

    const _trips = tripsArr.map((trip, index) => {
      let breakDurationMinutes = 0;

      if (index > 0) {
        const prevTrip = tripsArr[index - 1];

        const currentVehicleLocationStart = trip.start.vehicleLocationObj;
        let previousVehicleLocationEnd = prevTrip.end.vehicleLocationObj;

        if (currentVehicleLocationStart && previousVehicleLocationEnd) {
          breakDurationMinutes = moment(getAttribute(currentVehicleLocationStart, 'dateTime')).diff(moment(getAttribute(previousVehicleLocationEnd, 'dateTime')), 'minutes');
        }
      }

      return {
        id: uniqid(),
        startLocation: {
          vehicleLocation: trip.start.vehicleLocationObj,
          address: trip.start.addressString,
          dateTime: getAttribute(trip.start.vehicleLocationObj, 'dateTime', true),
        },
        endLocation: {
          vehicleLocation: trip.end.vehicleLocationObj,
          address: trip.end.addressString,
          dateTime: getAttribute(trip.end.vehicleLocationObj, 'dateTime', true),
        },
        distanceKm: (trip.speedApproxDistanceKm / totalSpeedApproxDistanceKm) * totalDistanceKm / 1000,
        breakDurationMinutes: breakDurationMinutes,
      }
    });

    setTrips(_trips);
  }

  const header = () => {
    let totalDistance = 0; // This will be converted to km or mi depending on distanceUnit

    if (props?.vehicleHistory?.legs?.[0]?.results?.length > 0) {
      for (let i = 0; i < props.vehicleHistory.legs[0].results.length; i++) {
        totalDistance += props?.vehicleHistory?.legs[0].results[0]?.distance || 0;
      }

      totalDistance = totalDistance / 1000; // Convert to km

      if (distanceUnit === LengthUnit.MI) {
        totalDistance = Helpers.convertDistance(totalDistance, 'km', 'mi', true)
      }
    }

    return (
      <div className="flex flex-column align-items-start justify-content-center px-3 py-2">
        <div className="w-full flex align-items-end justify-content-between">
          {`Trip History (${totalDistance.toFixed(0)} ${distanceUnit})`}
          <div>
            <Button
              text
              icon="pi pi-replay"
              className="trip-history-actions"
              tooltip="Replay GPS Route"
              tooltipOptions={{ position: 'left' }}
              onClick={() => props.onReplayRoute()}
            />
            <Button
              text
              icon="pi pi-download"
              className="trip-history-actions"
              tooltip="Export CSV"
              tooltipOptions={{ position: 'left' }}
              onClick={() => props.onExportCSV()}
            />
            <Button
              text
              icon="pi pi-palette"
              className="trip-history-actions"
              tooltip="Change Map Style"
              tooltipOptions={{ position: 'left' }}
              onClick={() => props.onSwitchMapStyle()}
            />
          </div>
        </div>
        <Calendar
          showIcon
          className="trip-history-calendar w-full mt-2"
          dateFormat="MM dd, yy"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.value)}
        />
      </div>
    );
  }

  let className = "trip-history-card absolute";
  if (!props.isShown) className += ' hidden';

  return (
    <div className={className}>
      <Card title={header}>
        <ScrollPanel style={{ width: '100%', height: '400px' }}>
          {props.vehicleHistory.loading &&
            <div className="flex h-full text-center justify-content-center align-items-center text-lg text-gray-700">
              Loading...
            </div>
          }
          {!props.vehicleHistory.loading && !props.vehicleHistory?.legs &&
            <div className="flex h-full text-center justify-content-center align-items-center text-lg text-gray-700">
              No trip history
            </div>
          }
          {trips !== undefined && trips.length > 0 && trips?.map((trip, index) => {
            let distance = trip.distanceKm; // This will be converted to km or mi depending on distanceUnit

            if (distanceUnit === LengthUnit.MI) {
              distance = Helpers.convertDistance(trip.distanceKm, 'km', 'mi', true);
            }

            if (isNaN(distance)) distance = 0;

            return (
              <>
                {index !== 0 && (
                  <Divider
                    className="my-0 p-1"
                    type="dotted"
                    align="center"
                  >
                    <span className="text-base font-semibold">{`BREAK (${getHourMinuteBreakdown(trip.breakDurationMinutes)})`}</span>
                  </Divider>
                )}
                <Card
                  key={trip.id}
                  className={`trip-history-entry border-round-md mx-1 shadow-none${selectedTrip?.id === trip?.id ? ' trip-history-selected' : ''}`}
                  onClick={() => {
                    if (props.isTripSelectionDisabled) return;
                    if (selectedTrip?.id === trip?.id) return setSelectedTrip(undefined);
                    return setSelectedTrip(trip);
                  }}
                >
                  <Timeline
                    value={[trip.startLocation, trip.endLocation]}
                    content={(item) => {
                      return (
                        <div className={index !== trips.length - 1 ? "pb-2" : ""}>
                          <p className="text-sm font-semibold mb-0">{item.address}</p>
                          <p className="text-xs mb-1">{moment(item.dateTime).format('MMM DD, YYYY @ hh:mm')} {`(${getTimezoneAbbreviation(item.dateTime)})`}</p>
                        </div>
                      );
                    }}
                  />
                  <div className="flex flex-row justify-content-end gap-2 px-2">
                    {distance > 0 && <Tag className="trip-distance-tag" value={`${distance?.toFixed(0)} ${distanceUnit}`} />}
                    <Tag className="trip-duration-tag" value={`${getHourMinuteBreakdown(moment(trip.endLocation.dateTime).diff(moment(trip.startLocation.dateTime), 'minutes'))}`} />
                  </div>
                </Card>
              </>
            );
          })}
        </ScrollPanel>
      </Card>
    </div>
  )
}

export default TripHistoryCard;