import React from 'react';
import PropTypes from 'prop-types';

// Components
import RepairRouteModal from '../view/RepairRouteModal';
import { MDBBtn, MDBIcon } from 'mdbreact';
import Button from 'sbCore/Button/Button';

class RepairRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      repairRouteModal: {
        show: false,
        repairRoutes: false
      }
    }

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal() {
    this.state.repairRouteModal.show = true;
    this.setState({ ...this.state });
  }

  closeModal() {
    this.state.repairRouteModal.show = false;
    this.setState({ ...this.state });
  }

  render() {
    const { unitId, dateStart, dateEnd } = this.props;

    return (
      <div className="ml-2" style={{ display: 'inline-block' }}>
        <RepairRouteModal
          show={this.state.repairRouteModal.show}
          handleClose={this.closeModal}
          unitId={unitId}
          dateStart={dateStart}
          dateEnd={dateEnd}
        />
        <Button
          text
          sbVariant="short"
          onClick={this.showModal}
          label={(
            <span><MDBIcon icon="wrench" className="mr-2" />RESET ROUTES FOR { unitId }</span>
          )}
        />
      </div>
    );
  }
}

export default RepairRoute;
