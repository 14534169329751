/** @module DispatchPayee */

import {
  callCloudFunction, copyQuery, count, createQuery, createQueryOr, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, getCurrentUser, addRecord,
} from 'sb-csapi/dist/AAPI';

// CSAPI Enums
import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

import Sort from 'sb-csapi/dist/sbObjects/Sort';

// CSAPI Filter
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @memberof module:DispatchPayee
 *
 * @description Get DispatchPayees according to filter criteria
 *
 * @param {object} options - See example
 * @param {string} companyObjectId - Company we wish to retrieve payees for
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {int} page - The current page for pagination
 * @param {int} limit - The limit of records obtained per pagination
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { object } - { totalDispatchPayeesCount: Number, dispatchPayees: [DispatchPayee, ...] }
 */
async function getDispatchPayees(options = { sessionToken: getCurrentUserSessionToken() }, companyObjectId = getCurrentUserCompanyObjectId(), filters = [], sortBy = new Sort(QuerySortOrder.ASCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  const dispatchPayeeQuery = createQuery('DispatchPayee');

  // Filter
  const _filters = [...filters];
  _filters.map(filter => setQueryRestriction(dispatchPayeeQuery, filter.queryRestriction, filter.attribute, filter.value));

  // Query for the the current company's DispatchPayees
  setQueryRestriction(dispatchPayeeQuery, QueryRestriction.EQUAL_TO, 'belongsToCompany', companyObjectId);

  // At this point, copy current query to get the number of pages for pagination
  const dispatchPayeeCountQuery = copyQuery(dispatchPayeeQuery);

  // Sort
  sortQuery(dispatchPayeeQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchPayeeQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchPayeeQuery, selectedAttributes);

  // Apply pagination restrictions if required
  if (!queryAll) {
    setQueryRestriction(dispatchPayeeQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(dispatchPayeeQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // Retrieve the count and the payees
  const promises = [count(options, dispatchPayeeCountQuery), findRecords(options, dispatchPayeeQuery, false, queryAll)];

  try {
    const [totalDispatchPayeesCount, dispatchPayees] = await Promise.all(promises);
    return { totalDispatchPayeesCount, dispatchPayees };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchPayee
 *
 * @description Add a DispatchPayee
 *
 * @param {object} keyValueObj - The select fields we wish to update
 *
 * @returns {DispatchPayee} - The created DispatchPayee
 */
async function addDispatchPayee(keyValueObj) {
  if (!keyValueObj.belongsToCompany) throw new Error('Missing attribute required: belongsToCompany');

  try {
    const dispatchPayee = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'DispatchPayee', keyValueObj, getCurrentUserCompanyObjectId());
    return dispatchPayee;
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getDispatchPayees,
  addDispatchPayee,
};
