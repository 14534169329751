import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import { drivewyzeTOS } from 'TOS/drivewyze';

function ActivateDeviceDialog(props) {
  const { title, imei, handleClose, handleActivation, open } = props;

  let error;
  let secondaryLabel;
  let closeMode;

  // styling
  const uListStyle = { marginTop: '1em', paddingLeft: '1.2em', fontSize: '.85em' };

  return (
    <ConfirmModal
      title={title}
      show={open}
      handleModalChoice={handleActivation}
      handleClose={handleClose}
      secondaryLabel={secondaryLabel}
      closeMode={closeMode}
    >
      {error ?
        <div style={{ marginTop: '-1em' }}>{error}</div>
        :
        (
          <div>
            <div>
              Please select confirm to request activation of device: <b>{imei}</b>
            </div>

            <div style={{ marginTop: '1em' }}>
              For more information on activation:
              <ul style={uListStyle}>
                <li>Email us at <a href="mailto:sales@onswitchboard.com" target="_blank">sales@onswitchboard.com</a></li>
                <li>Live Chat (chat icon on the bottom right of the screen)</li>
                <li>Call us at 1-844-5-FLEETS (1-844-535-3387)</li>
                <li>Visit our <a href="http://support.onswitchboard.com" target="_blank">knowledgebase</a></li>
              </ul>
            </div>
          </div>
        )
      }
    </ConfirmModal>
  );
}

ActivateDeviceDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleActivation: PropTypes.func,
  handleClose: PropTypes.func,
};

export default ActivateDeviceDialog;
