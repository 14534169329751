import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';

// Actions
import { disableDriver, enableDriver, deleteDriver } from 'actions/Driver';

// API
import { formatName } from 'api/Helpers';
import { resetDriverPassword, forceUpdateMasonTablets } from 'api/Setters';
import * as Getters from 'api/Getters';
import * as Sim from 'api/Sim';
import * as Email from 'api/Email/Email';

// Components
import Title from 'components/Shared/Title/Title';
import SBCardEmptyContent from 'components/Shared/SBCard/SBCardEmptyContent';
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import ActivateDeviceDialog from 'components/ActivateDeviceDialog/view/ActivateDeviceDialog';
import ResetDataModal from 'components/ResetDataModal/container/ResetDataModal';
import RestartSoftwareModal from 'components/RestartSoftwareModal/container/RestartSoftwareModal';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import DriverDetailsInfoTable from 'components/DriverDetails/view/DriverDetailsInfoTable';
import EditDriverDetails from 'components/DriverDetails/container/EditDriverDetails';
import { MDBBtn } from 'mdbreact';

// CSS
import styles from './SummaryLayout.module.scss';
import './style.scss';

class SummaryLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sim: {
        resetData: {
          show: false,
        },
        activateDeviceDialog: {
          show: false,
        },
        iccid: null,
        status: null,
        lastOnline: null,
        simVendor: null,
        ratePlanName: null,
      },
      restartSoftware: {
        show: false,
      },
      resetPassword: {
        show: false,
        backupPhoneNumber: undefined,
      },
      forceUpdate: {
        show: false,
      },
      resetPasswordSent: false,
      confirmDisableModalShow: false,
      confirmDeleteModalShow: false,
      errorMessage: undefined,
    };
    this.enableDriver = this.enableDriver.bind(this);
    this.disableDriver = this.disableDriver.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
    this.handleResetPasswordClose = this.handleResetPasswordClose.bind(this);
    this.openconfirmDisableModal = this.openconfirmDisableModal.bind(this);
    this.closeconfirmDisableModal = this.closeconfirmDisableModal.bind(this);

    this.openconfirmDeleteModal = this.openconfirmDeleteModal.bind(this);
    this.closeconfirmDeleteModal = this.closeconfirmDeleteModal.bind(this);
    this.handleActivateDevice = this.handleActivateDevice.bind(this);
    this.deleteDriver = this.deleteDriver.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.driver && nextProps.driver.get('deviceIMEI')) {
      Getters.getSimByImei(nextProps.driver.get('deviceIMEI')).then((sim) => {
        if (sim) {
          Sim.getSimInfo(sim.get('iccid')).then((infoObj) => {
            this.setState({
              ...this.state,
              sim: {
                ...this.state.sim,
                resetData: { ...this.state.resetData },
                iccid: sim.get('iccid'),
                status: infoObj.status,
                lastOnline: infoObj.lastOnline,
                simVendor: infoObj.simVendor,
                ratePlanName: infoObj.ratePlanName,
              }
            });
          });
        }
      });
    }
  }

  handleResetPassword(choiceBool) {
    if (choiceBool) {
      resetDriverPassword(this.props.driver.get('user').id, this.state.resetPassword.backupPhoneNumber);
      this.handleResetPasswordClose(true);
    } else {
      this.handleResetPasswordClose(false);
    }
  }

  handleResetPasswordClose(isReset) {
    this.setState({ ...this.state, resetPassword: { ...this.state.resetPassword, show: false }, resetPasswordSent: isReset });
  }

  openconfirmDisableModal() {
    const newState = { ...this.state };
    newState.confirmDisableModalShow = true;
    this.setState(newState);
  }
  closeconfirmDisableModal() {
    const newState = { ...this.state };
    newState.confirmDisableModalShow = false;
    this.setState(newState);
  }


  openconfirmDeleteModal() {
    const newState = { ...this.state };
    newState.confirmDeleteModalShow = true;
    this.setState(newState);
  }
  closeconfirmDeleteModal() {
    const newState = { ...this.state };
    newState.confirmDeleteModalShow = false;
    this.setState(newState);
  }

  async enableDriver() {
    try {
      return await enableDriver(this.props.driver);
    } catch (error) {
      const newState = { ...this.state };
      newState.errorMessage = error.message;
      this.setState(newState);
    }
  }

  disableDriver(confirmBool) {
    const newState = { ...this.state };
    newState.confirmDisableModalShow = false;
    this.setState(newState);
    if (confirmBool) {
      disableDriver(this.props.driver);
      history.push('drivers');
    }
  }
  deleteDriver(confirmBool) {
    const newState = { ...this.state };
    newState.confirmDeleteModalShow = false;
    this.setState(newState);
    if (confirmBool) {
      deleteDriver(this.props.driver);
      history.push('drivers');
    }
  }

  handleActivateDevice() {
    this.setState({ ...this.state, sim: { ...this.state.sim, activateDeviceDialog: { ...this.state.sim.activateDeviceDialog, show: false } } });
    const driver = this.props.driver;
    const company = this.props.driver.get('belongsToCompany');
    const callingUser = Getters.getCurrentUser(true);

    Email.send(
      company.get('name'),
      'contact@onswitchboard.com',
      'support@onswitchboard.com',
      `[Activation Request] - ${driver.get('user_fullName')} - ${driver.get('deviceIMEI')}`,
      `Company ${company.get('name')} is requesting activation:

      Driver: ${driver.get('user_fullName')}
      IMEI: ${driver.get('deviceIMEI')}
      Name: ${callingUser.firstName} ${callingUser.lastName}
      Phone Number: ${callingUser.phoneNumber}
      Email: ${callingUser.email}
      `,
    )
  }

  render() {
    const { driver, driverFetched, dispatchers, detailsUpdatedHandler } = this.props;
    const callingUser = Getters.getCurrentUser(true);
    const userSpecialPermission = callingUser.userSpecialPermission;
    const disableDriversDeleteDriver = userSpecialPermission && userSpecialPermission.disableDriversDeleteDriver;
    const disableDriversEditDriver = userSpecialPermission && userSpecialPermission.disableDriversEditDriver;
    window.Localize.translatePage();
    return (
      <div className={`${styles.summaryLayout} driver-profile-summary-layout`}>
        <Title title="Driver Summary" containerClassName="translate-me" />

        {!driverFetched ?
          <SBCardEmptyContent isContentLoader className="translate-me">{'Loading Driver Profile'}</SBCardEmptyContent>
          :
          (
            <React.Fragment>
              <ActionsContainer>
                {!disableDriversEditDriver &&
                  <EditDriverDetails driver={driver} driverFetched={driverFetched} dispatchers={dispatchers} handleClose={detailsUpdatedHandler} />
                }

                {this.props.driver && !this.props.driver.get('enabled') &&
                  <MDBBtn
                    key="enableDriver"
                    onClick={() => this.enableDriver()}
                    color="secondary"
                    size="sm"
                    className="float-right translate-me"
                    style={{ backgroundColor: '#07DF88' }}
                  >Enable</MDBBtn>
                }
                {this.props.driver && this.props.driver.get('enabled') &&
                  <MDBBtn
                    key="disableDriver"
                    onClick={this.openconfirmDisableModal}
                    color="danger"
                    size="sm"
                    className="float-right translate-me"
                  >Disable</MDBBtn>
                }
                {!disableDriversDeleteDriver && this.props.driver && !this.props.driver.get('enabled') &&
                  <MDBBtn
                    key="deleteDriver"
                    onClick={this.openconfirmDeleteModal}
                    color="danger"
                    size="sm"
                    className="float-right translate-me"
                    style={{ backgroundColor: '#E03857', marginLeft: '.5em' }}
                  >Delete</MDBBtn>

                }

                {!this.state.resetPasswordSent ?
                  <MDBBtn
                    className="float-right px-1 translate-me"
                    key="resetDriverPassword"
                    onClick={() => this.setState({ ...this.state, resetPassword: { ...this.state.resetPassword, show: true } })}
                    disabled={!driverFetched}
                    color="danger"
                    size="sm"
                  >Reset Password</MDBBtn>
                  :
                  <MDBBtn
                    className="float-right px-0 translate-me"
                    key="resetDriverPasswordDisabled"
                    size="sm"
                    disabled
                  >New Password Sent</MDBBtn>
                }

                {driverFetched &&
                  <ConfirmModal show={this.state.resetPassword.show} handleModalChoice={this.handleResetPassword} handleClose={this.handleResetPasswordClose} className="translate-me" >
                    <b>Send <var>{driver.get('user').get('firstName')}</var> new password?</b>
                    <div style={{ marginTop: '1em' }}>
                      <div style={{ marginBottom: '2em' }}>
                        Switchboard will send a new password to your driver&apos;s phone number
                      </div>
                      <div>
                        <label className={'inputLabel'}>(Optional) Enter another phone number to send the password to.</label>
                        <input
                          className={'input'}
                          id="phoneNumber"
                          type="text"
                          value={this.state.resetPassword.backupPhoneNumber || ''}
                          placeholder="....."
                          onChange={(e) => this.setState({ ...this.state, resetPassword: { ...this.state.resetPassword, backupPhoneNumber: e.target.value } })}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </ConfirmModal>
                }

                <ConfirmModal show={this.state.confirmDisableModalShow} handleModalChoice={this.disableDriver} handleClose={this.closeconfirmDisableModal} className="translate-me" />
                <ConfirmModal show={this.state.confirmDeleteModalShow} handleModalChoice={this.deleteDriver} handleClose={this.closeconfirmDeleteModal} className="translate-me" >
                  <div> Are you sure? <var>{formatName(driver.get('user_fullName'))}</var> will be permanently deleted.</div>
                </ConfirmModal>
              </ActionsContainer>
            </React.Fragment>
          )
        }


        <DriverDetailsInfoTable
          driver={driver}
          driverFetched={driverFetched}
          sim={this.state.sim}
          showResetDataModal={() => this.setState({ ...this.state, sim: { ...this.state.sim, resetData: { ...this.state.resetData, show: true } } })}
          showRestartSoftwareModal={() => this.setState({ ...this.state, restartSoftware: { ...this.state.restartSoftware, show: true } })}
          showForceUpdateModal={() => this.setState({ ...this.state, forceUpdate: { ...this.state.forceUpdate, show: true } })}
          showActivateDeviceDialog={() => this.setState({ ...this.state, sim: { ...this.state.sim, activateDeviceDialog: { ...this.state.sim.activateDeviceDialog, show: true } } })}
        />
        {this.state.sim.iccid && driver.get('deviceIMEI') &&
          (
            <ActivateDeviceDialog
              title="Activation"
              open={this.state.sim.activateDeviceDialog.show}
              handleActivation={this.handleActivateDevice}
              handleClose={() => this.setState({ ...this.state, sim: { ...this.state.sim, activateDeviceDialog: { ...this.state.sim.activateDeviceDialog, show: false } } })}
              imei={driver.get('deviceIMEI')}
            />
          )
        }
        {/** TODO: To be translated */}
        {this.state.sim.iccid &&
          (
            <ResetDataModal
              show={this.state.sim.resetData.show}
              simIccid={this.state.sim.iccid}
              handleClose={() => this.setState({ ...this.state, sim: { ...this.state.sim, resetData: { ...this.state.resetData, show: false } } })}
            />
          )
        }
        {/** TODO: To be translated */}
        {this.props.driver && this.props.driver.get('user') &&
          (
            <RestartSoftwareModal
              show={this.state.restartSoftware.show}
              user={this.props.driver.get('user')}
              handleClose={() => this.setState({ ...this.state, restartSoftware: { ...this.state.restartSoftware, show: false } })}
            />
          )
        }
        {driver &&
          <ConfirmModal
            show={this.state.forceUpdate.show}
            imei={driver.get('deviceIMEI')}
            handleModalChoice={(confirmBool) => {
              if (confirmBool) forceUpdateMasonTablets([driver.get('deviceIMEI')]);
              this.setState({ ...this.state, forceUpdate: { ...this.state.forceUpdate, show: false } });
            }}
            handleClose={() => this.setState({ ...this.state, forceUpdate: { ...this.state.forceUpdate, show: false } })}
            className="translate-me"
          >
            <div>
              <h4>Updating Switchboard for {formatName(driver.get('user_fullName'))}</h4>
              Are you sure? Your driver will be <u>logged out.</u>
              <br />
              Make sure they aren't driving, and they have 5-10 minutes for the update process to complete.
              <br />
              <br />
              <b><i>For your driver to receive the update, they must have their tablet <u>turned on.</u></i></b>
            </div>
          </ConfirmModal>
        }

        {this.state.errorMessage &&
          <ConfirmModal
            show={this.state.errorMessage}
            handleModalChoice={() => this.setState({ ...this.state, errorMessage: undefined })}
            handleClose={() => this.setState({ ...this.state, errorMessage: undefined })}
            closeMode
            secondaryLabel="Close"
            className="translate-me"
          >
            {this.state.errorMessage}
          </ConfirmModal>
        }

      </div>
    );
  }
}

SummaryLayout.propTypes = {
  driver: PropTypes.object,
  driverFetched: PropTypes.bool,
  dispatchers: PropTypes.array,
  detailsUpdatedHandler: PropTypes.func,
};

export default SummaryLayout;
