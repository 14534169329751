import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// sb-csapi
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { LengthUnit, LengthConversion } from 'sb-csapi/dist/enums/Unit';

// components
import Card from 'sbCore/Card/Card';
import Button from 'sbCore/Button/Button';

import CardItem from 'sbCore/CardItem/CardItem';
import Divider from 'sbCore/Divider/Divider';

import RouteTimeline from 'components/IFTA/IFTAVehicleDayView/IFTAVehicleDayDetailsCard/RouteTimeline/RouteTimeline';

// styles
import './style.scss';

/**
 * @description Display breakdown information for a specific trip
 * @param {String} dayData - The IFTA data for the vehicle and specific day
 * @param {String} tripData - The IFTA data for a specific trip
 * @param {String} [lengthUnit] - From enums LengthUnit - KM, MI
 */
function IFTAVehicleDayDetailsCard({ ...props }) {

  // const [titleTemplate, setTitleTemplate] = useState(undefined);

  // useEffect(() => {

  // }, [props.tripData]);

  /**
   * JSX
   */

  function titleTemplate() {
    return (
      <div className="flex justify-content-between align-items-center">
        <div className="flex-1">Details</div>
        <div className="flex justify-content-end">
          <Button
            label="Edit Trip"
            onClick={() => console.log('yees')}
            sbVariant="short"
            severity="primary"
          />
        </div>
      </div>
    );
  }

  function noDetailsTemplate() {
    if (!props.tripData || Object.keys(props.tripData).length === 0) {
      return (
        <div className="text-left roboto text-gray-calm">
          Select a Trip from the Trips Table to see more details
        </div>
      );
    }
  }

  function originTemplate() {
    const { iftaRouteObj } = props.tripData;
    const { derivedVehicleLocationStart } = iftaRouteObj;

    const subheading = (
      <div>
        <div>({derivedVehicleLocationStart.location._latitude}, {derivedVehicleLocationStart.location._longitude})</div>
        <div className="mt-1 font-bold">{moment(derivedVehicleLocationStart.dateTime).format('HH:mm')}</div>
      </div>
    );

    return (
      <CardItem
        className="card-item-origin"
        label="Origin"
        leading={`${derivedVehicleLocationStart.aprxShortName}, ${derivedVehicleLocationStart.stateProvince.toUpperCase()}`}
        subheading={subheading}
        trailing=""
        sbVariant="flat"
        style={{ marginTop: '-1.5em' }}
      />
    );
  }

  function destinationTemplate() {
    const { iftaRouteObj } = props.tripData;
    const { derivedVehicleLocationEnd } = iftaRouteObj;

    const subheading = (
      <div>
        <div>({derivedVehicleLocationEnd.location._latitude}, {derivedVehicleLocationEnd.location._longitude})</div>
        <div className="mt-1 font-bold">{moment(derivedVehicleLocationEnd.dateTime).format('HH:mm')}</div>
      </div>
    );

    return (
      <CardItem
        className="card-item-destination"
        label="Destination"
        leading={`${derivedVehicleLocationEnd.aprxShortName}, ${derivedVehicleLocationEnd.stateProvince.toUpperCase()}`}
        subheading={subheading}
        trailing=""
        sbVariant="flat"
        style={{ marginTop: '-.8em' }}
      />
    );
  }

  function distanceTravelledTemplate() {
    // Keep conversion in parody with IFTAVehicleJurisdictionDataTable: generateVehicleJurisdictionMap();
    const { iftaRouteObj } = props.tripData;
    const { iftaRoute } = iftaRouteObj;

    let distanceTravelledKm = 0;
    let totalVehicleKmStart = getAttribute(iftaRoute, 'totalVehicleKmStart');
    let totalVehicleKmEnd = getAttribute(iftaRoute, 'totalVehicleKmEnd');

    if (props.lengthUnit === LengthUnit.MI) {
      totalVehicleKmStart *= LengthConversion.KM2MI;
      totalVehicleKmEnd *= LengthConversion.KM2MI;
    }

    distanceTravelledKm = Math.floor(Math.floor(totalVehicleKmEnd) - Math.floor(totalVehicleKmStart));

    if (distanceTravelledKm === 0) distanceTravelledKm = '-';

    return (
      <CardItem
        className="card-item-distance-travelled"
        label={`Distance Travelled (${props.lengthUnit})`}
        leading={distanceTravelledKm}
        sbVariant="flat"
      />
    );
  }

  function fuellingTemplate() {
    return (
      <CardItem
        className="card-item-fuelling"
        label="Fuel Pumped"
        leading="N/A"
        subheading="Fuelling Info Coming Soon!"
        trailing=""
        sbVariant="flat"
        style={{ marginTop: '-.8em' }}
      />
    );
  }

  let className = 'ifta-vehicle-day-details-card';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <Card
        title={titleTemplate()}
        // footer={shipmentDetailsFooter}
        // variant="compact"
      >
        { noDetailsTemplate() }

        {props.tripData && (
          <div>
            { distanceTravelledTemplate() }
            { originTemplate() }
            { destinationTemplate() }
            { fuellingTemplate() }
          </div>
        )}
        {props.tripData && (
          <Divider />
        )}
        {props.tripData && (
          <div>
            <RouteTimeline
              tripData={props.tripData}
              lengthUnit={props.lengthUnit}
            />
          </div>
        )}
      </Card>
    </div>
  );
}

export default IFTAVehicleDayDetailsCard;
