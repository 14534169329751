import Parse from 'parse';
import moment from 'moment-timezone';

import { setQueryRestriction, setParseObject } from 'api/Parse';
import { formatName } from 'api/Helpers';
import { getCurrentUser, getAllFromQuery } from 'api/Getters';

import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { AttributeTypes, EventTypes, EventTypeDescriptions } from 'enums/HarshDriving';

import Driver from 'sbObjects/Driver';
import HarshDrivingEvent from 'sbObjects/HarshDrivingEvent';
import ELDDailyCertification from 'sbObjects/ELDDailyCertification';

/** @module DriverPattern */

/**
 * @memberof module:DriverPattern
 *
 * @param {*} driverObjectIds
 * @param {*} eventCodes
 * @param {*} intervalStart
 * @param {*} intervalEnd
 *
 * @returns
 */
async function getHarshDrivingEventsByDriver(driverObjectIds = [], eventCodes = [], intervalStart, intervalEnd) {
  const harshDrivingEventsByDriver = {};
  if (driverObjectIds.length === 0) return harshDrivingEventsByDriver;
  const todayEnd = moment().endOf('day').toDate();
  const sevenDaysAgoStart = moment(todayEnd).subtract(1, 'days').startOf('day').toDate();


  const harshDrivingEventPromises = driverObjectIds.map(driverObjectId => {
    const harshDrivingEventQuery = new Parse.Query('HarshDrivingEvent');
    setQueryRestriction(harshDrivingEventQuery, QueryRestrictionTypes.EQUAL_TO, AttributeTypes.DRIVER, driverObjectId);
    setQueryRestriction(harshDrivingEventQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, AttributeTypes.DATE_TIME_UTC, intervalStart || sevenDaysAgoStart);
    setQueryRestriction(harshDrivingEventQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, AttributeTypes.DATE_TIME_UTC, intervalEnd || todayEnd);
    setQueryRestriction(harshDrivingEventQuery, QueryRestrictionTypes.CONTAINED_IN, AttributeTypes.CODE, eventCodes);
    harshDrivingEventQuery[QuerySortOrderTypes.DESCENDING](AttributeTypes.DATE_TIME_UTC); // get the most recent
    harshDrivingEventQuery.include(['driver', 'driver.user', 'eldDailyCertification']);
    return getAllFromQuery(harshDrivingEventQuery);
  });

  try {
    const dbHarshDrivingEvents = await Promise.all(harshDrivingEventPromises);
    driverObjectIds.map((driverObjectId, index) => {
      let correspondingHarshDrivingEvent = dbHarshDrivingEvents[index];
      correspondingHarshDrivingEvent = correspondingHarshDrivingEvent.map(dbHarshDrivingEvent => {
        let driver = dbHarshDrivingEvent.get('driver');
        const user = driver.get('user');

        driver = new Driver(
          driver.id,
          user.get('firstName'),
          user.get('lastName'),
          user.get('phoneNumber'),
          driver.get('driversLicense'),
          driver.get('driversLicenseCountry'),
          driver.get('licenseExpiryDate'),
          driver.get('timezoneOffsetFromUTC'),
          driver.get('eldStatusInt'),
        );

        let eldDailyCertification = dbHarshDrivingEvent.get('eldDailyCertification');
        eldDailyCertification = new ELDDailyCertification(
          eldDailyCertification.id,
          eldDailyCertification.get('startTimeUTC'),
          eldDailyCertification.get('endTimeUTC'),
          driver,
        );
        const harshDrivingEvent = new HarshDrivingEvent(dbHarshDrivingEvent.id, driver, dbHarshDrivingEvent.get('code'), dbHarshDrivingEvent.get('dateTimeUTC'), eldDailyCertification);
        return harshDrivingEvent
      });
      harshDrivingEventsByDriver[driverObjectId] = correspondingHarshDrivingEvent;
    });

    return harshDrivingEventsByDriver;

  } catch(err) {
    throw new Error(err);
  }

}

export {
  getHarshDrivingEventsByDriver,
};
