import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// enums
import { CountryCodeLocationDescriptionTypes } from 'enums/VehicleLocation';

// API
import * as Getters from 'api/Getters';
import * as GPS from 'api/GPS/GPS';
import { convertDistance } from 'api/Helpers';
import { getAttribute } from 'api/Parse';

// Components
import Alert from 'components/Alert/view/Alert';
import { MDBRow, MDBCol, MDBIcon, MDBTooltip } from "mdbreact";

// CSS
import styles from './VehicleRow.module.scss';

// Assets
// const tractor = require('assets/images/equipment/tractor-compressed.png');

function TrailerRow(props) {
  const { trailer, address } = props;

  const distanceUnit = Getters.getPDispatcherPropertyFromState('distanceUnit'); // km or mi

  const vehicleLocation = trailer && trailer.get('vehicleLocation');
  const countryCode = ((vehicleLocation && vehicleLocation.get('countryCode')) || '').toUpperCase();

  let locationDescription = address;
  if (countryCode === 'US') {
    if (getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.US, true)) {
      const locationDescriptionUSArr = getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.US, true).split(' ');
      const locationDescriptionStateProvince = locationDescriptionUSArr[2];
      const trueStateProvince = getAttribute(vehicleLocation, 'stateProvince', true);
      if (trueStateProvince !== locationDescriptionStateProvince.toLowerCase()) {
        const formattedLocationDescription = `${trueStateProvince.toUpperCase()}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      } else {
        const locationDescriptionCity = locationDescriptionUSArr.slice(3, locationDescriptionUSArr.length).join(' ');
        const formattedLocationDescription = `${locationDescriptionCity}, ${locationDescriptionStateProvince}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      }
    }
  } else {
    if (getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.CA, true)) {
      const locationDescriptionCAArr = getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.CA, true).split(' ');
      const locationDescriptionStateProvince = locationDescriptionCAArr[2];
      const trueStateProvince = getAttribute(vehicleLocation, 'stateProvince', true);
      if (trueStateProvince !== locationDescriptionStateProvince.toLowerCase()) {
        const formattedLocationDescription = `${trueStateProvince.toUpperCase()}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      } else {
        const locationDescriptionCity = locationDescriptionCAArr.slice(3, locationDescriptionCAArr.length).join(' ');
        const formattedLocationDescription = `${locationDescriptionCity}, ${locationDescriptionStateProvince}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      }
    }
  }

  // check ex. if from chid company
  const isFromAssociatedCompany = Getters.getCurrentUser().get('belongsToCompany').id !== trailer.get('belongsToCompany').id;

  return (
    <div onClick={() => props.handleClick('Trailer', props.trailer, true)}
      className={(props.active ? `${styles.activeRow} ${styles.row}` : styles.row) + " px-3"}>
      <MDBRow>
        <MDBCol size="4" className="font-weight-bold">
          {!props.trailer.get('unitId') && <span>N/A</span>}
          {props.trailer && props.trailer.get('unitId')}
          {(props.globalSpeedLimitKm && props.trailer.get('vehicleLocation') &&
            !isNaN(props.trailer.get('vehicleLocation').get('speedKm')) &&
            props.trailer.get('vehicleLocation').get('speedKm') > props.globalSpeedLimitKm) ?
            <Alert iconClass="ml-3" tooltipClass="ml-4 translate-me" tooltipString={`${props.trailer.get('unitId')} is speeding above ${distanceUnit === 'km' ?
              (`${Math.round(props.globalSpeedLimitKm)} km`) :
              (`${convertDistance(props.globalSpeedLimitKm, 'km', 'mi')} mi`)}`}
            />
            :
            null
          }
        </MDBCol>
        <MDBCol size="8">
          { props.trailer && props.trailer.get('vehicleLocation') &&
            <div className={props.active ? `${styles.activeUpdated}` : undefined}>
              <div className={styles.address + ' text-truncate'}>{locationDescription}</div>
              <div className={styles.time}>
                {moment(props.trailer.get('vehicleLocation').get('dateTime')).format('lll')}
                {
                  // moment().diff(moment(props.vehicle.get('vehicleLocation').get('dateTime')), 'minutes') > 30 && isVehicleDriverDriving(props.vehicle) &&
                  // <span className={styles.oldLocationError}>&nbsp; Vehicle's location has not updated in a while</span>
                }
              </div>
            </div>
          }
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol size="12" style={{ margin: '0rem', paddingTop: '0rem', paddingBottom: '0rem' }}>
          { props.trailer && props.trailer.get('vehicleLocation') && GPS.isIdling(props.trailer.get('vehicleLocation').get('ignition'), props.trailer.get('vehicleLocation').get('speedKm')) &&
            <span className={styles.isIdling}><b className="translate-me">Idling</b></span>
          }
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol>
          {(isFromAssociatedCompany) &&
            <MDBTooltip className={'d-inline-block translate-me'}
              placement="right"
              tag="div"
              tooltipContent={`Associated Company: ${trailer.get('belongsToCompany').get('name')}`}>
              <MDBIcon icon="network-wired" />
            </MDBTooltip>
          }
        </MDBCol>
      </MDBRow>
    </div>
  );
}

TrailerRow.propTypes = {
  active: PropTypes.bool,
  trailer: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleHover: PropTypes.func.isRequired,
  address: PropTypes.string,
  globalSpeedLimitKm: PropTypes.number,
};

export default TrailerRow;
