import React, { useState, useEffect } from 'react';

// API
import { getDispatchTransfers } from 'api/Dispatch/DispatchTransfer';

// CSAPI API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import DispatchTransferDropdownCard from 'components/Dispatch/DispatchTransferDropdown/DispatchTransferDropdownCard';

// sbCore Components
import Dropdown from 'sbCore/Dropdown/Dropdown';

// Styles
import './style.scss';

/**
 * @description A dropdown containing a list of selectable DispatchTransfers according to the selected DispatchJob
 * @param {DispatchJob} dispatchJob - The selected DispatchJob
 * @param {DispatchTransfer} dispatchTransfer - The selected DispatchTransfer
 * @param {Boolean} disabled - Whether or not to disable the dropdown
 * @param {Function} onDispatchTransferChange - Function that updates the selected DispatchTransfer of the parent's state
 */
function DispatchTransferDropdown({ ...props }) {

  // ** useStates ** //
  const [dispatchTransferDropdownItems, setDispatchTransferDropdownItems] = useState([]);
  const [selectedDispatchTransfer, setSelectedDispatchTransfer] = useState(null);

  // ** useEffects ** //
  useEffect(() => {
    if (!props.dispatchJob) {
      setSelectedDispatchTransfer(null);
      if (props.onDispatchTransferChange) props.onDispatchTransferChange(null);
      return;
    }

    const dispatchJobObjectId = getAttribute(props.dispatchJob, 'objectId');

    async function getDispatchTransferDropdownItems() {
      const { dispatchTransfers } = await getDispatchTransfers(
        undefined,
        dispatchJobObjectId,
        undefined,
        undefined,
        ['consigneeDispatchOrganization', 'shipperDispatchOrganization'],
        undefined,
        undefined,
        undefined,
        true,
      );

      if (!dispatchTransfers.length > 0) return;

      const _dispatchTransferItems = dispatchTransfers.map((dispatchTransfer) => {
        if (!dispatchTransfer) return;
        const shipperDispatchOrganization = getAttribute(dispatchTransfer, 'shipperDispatchOrganization');
        const shipperOrganizationId = getAttribute(shipperDispatchOrganization, 'organizationId', true) || '**';

        const consigneeDispatchOrganization = getAttribute(dispatchTransfer, 'consigneeDispatchOrganization');
        const consigneeOrganizationId = getAttribute(consigneeDispatchOrganization, 'organizationId', true) || '**';

        const description = `${shipperOrganizationId}-${consigneeOrganizationId}`;
        const dispatchTransferDropdownItem = {
          dispatchTransfer,
          description,
        };

        return dispatchTransferDropdownItem;
      });

      setDispatchTransferDropdownItems(_dispatchTransferItems);
    }

    getDispatchTransferDropdownItems();

  }, [props.dispatchJob]);

  useEffect(() => {
    if (!props.dispatchTransfer) return;
    const transferPropObjectId = getAttribute(props.dispatchTransfer, 'objectId');

    const dispatchTransferDropdownItem = dispatchTransferDropdownItems.find((dispatchTransferDropdownItem) => {
      const { dispatchTransfer } = dispatchTransferDropdownItem;
      const itemObjectId = getAttribute(dispatchTransfer, 'objectId', true);

      return itemObjectId === transferPropObjectId;
    });
    setSelectedDispatchTransfer(dispatchTransferDropdownItem);
  }, [props.dispatchTransfer, dispatchTransferDropdownItems]);

  // ** Callback Functions ** //

  // sets the selected DispatchTransfer here and in the the parent component
  const onDispatchTransferChange = (e) => {
    setSelectedDispatchTransfer(e.value);

    if (props.onDispatchTransferChange) {
      // send the chosen DispatchTransfer back up to parent
      props.onDispatchTransferChange(e.value.dispatchTransfer);
    }
  };

  // ** Dropdown Templates ** //
  const dispatchTransferOptionTemplate = (option) => {
    const { dispatchTransfer } = option;
    return (
      <DispatchTransferDropdownCard dispatchTransfer={dispatchTransfer} />
    );
  };

  const selectedDispatchTransferTemplate = (option) => {
    if (!option) {
      return (
        <div>Select Shipment</div>
      );
    }

    const { dispatchTransfer } = option;
    return (
      <DispatchTransferDropdownCard dispatchTransfer={dispatchTransfer} />
    );
  };

  return (
    <div className="dispatch-transfer-dropdown">
      <Dropdown
        className={`dispatch-transfer-dropdown ${!selectedDispatchTransfer ? 'warning-dropdown' : ''}`}
        value={selectedDispatchTransfer}
        options={dispatchTransferDropdownItems}
        onChange={onDispatchTransferChange}
        optionLabel="description"
        placeholder="Select Shipment"
        warning={!selectedDispatchTransfer}
        success={selectedDispatchTransfer}
        itemTemplate={dispatchTransferOptionTemplate}
        disabled={!props.dispatchJob}
        valueTemplate={selectedDispatchTransferTemplate}
      />
    </div>
  );
}

export default DispatchTransferDropdown;
