import React, { useState } from 'react';
import moment from 'moment-timezone';

// API
import { updateDispatchPayable } from 'api/Dispatch/DispatchPayable';

// CSAPI Attributes
import { getAttribute } from 'sb-csapi/dist/AAPI';

// CSAPI Enums
import { Document } from 'sb-csapi/dist/enums/Dispatch/Document';

// Components
import PaymentStatusDropdown from 'components/Dispatch/PaymentStatusDropdown/PaymentStatusDropdown';
import DispatchUploadDocument from 'components/Dispatch/DispatchUploadDocument/DispatchUploadDocument';
import DispatchOrganizationCard from 'components/Dispatch/DispatchOrganizationAutocomplete/DispatchOrganizationCard';
import DispatchContactAutocomplete from 'components/Dispatch/DispatchContactAutocomplete/DispatchContactAutocomplete';

// sbCore Components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Button from 'sbCore/Button/Button';
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import ConfirmDialog from 'sbCore/ConfirmDialog/ConfirmDialog';
import Chip from 'sbCore/Chip/Chip';

// Styles
import './style.scss';

/**
 * @description Section that allows editing job accounting information
 * @param {DispatchJob} dispatchJob - The current DispatchJob
 * @param {DispatchPayable} dispatchPayable - The selected DispatchPayable
 * @param {Date} [invoiceDateTime] - The date the job was invoiced
 * @param {DispatchDocument} [invoice] - The payable's invoice document
 * @param {Function}[handleInvoiceUpload] - Callback function when an invoice is uploaded
 * @param {Function} [handleInvoiceRemove] - Callback function when the invoice is removed
 * @param {Boolean} [isInvoicedDateInvalid] - Boolean for if the invoice date's invalid
 * @param {Number} [methodType] - The payable's method type
 * @param {Number} [paymentStatusInt] - The current payment status int
 * @param {Function} [onPaymentStatusChange] - Callback function when the payment status is changed
 * @param {Number} [paymentTerm] - The current payment term based on the invoice date time
 * @param {Function} [onHide] - Callback function which closes the drawer
 * @returns
 *
 * @example
 */
function PayableInformation({ ...props }) {
  // ** useStates ** //
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  // ** Helper Functions ** //
  async function deleteDispatchPayable() {
    if (!props.dispatchPayable) return;
    await updateDispatchPayable(props.dispatchPayable, undefined, { isHidden: true }, true);
    if (props.onHide) props.onHide();
  }

  // ** UI Components ** //
  const accountingInformationHeader = (
    <div className="flex justify-content-between">
      <div>Payable Information</div>
      <Button
        className="p-button-danger p-button-sm"
        onClick={() => setIsDeleteDialogVisible(true)}
        label="Delete Payable"
      />
    </div>
  );

  const paymentStatus = (
    <>
      <InputLabel>Payment Status</InputLabel>
      <PaymentStatusDropdown
        hideLabel
        status={props.paymentStatusInt}
        onSelect={props.onPaymentStatusChange}
        includeAllStatusesOption={false}
      />
    </>
  );

  const methodType = (
    <>
      <InputLabel>Method Type</InputLabel>
      <div className="text-sm">
        {props.methodType || '-'}
      </div>
    </>
  );

  const invoicedDate = (
    <>
      <InputLabel>Invoiced Date</InputLabel>
      <div className="text-sm">
        {props.invoiceDateTime ? (moment(props.invoiceDateTime)).tz('America/Vancouver').format('DD-MM-YYYY HH:mm') : '-'}
      </div>
    </>
  );

  const paymentTerm = (
    <>
      <InputLabel>Payment Term</InputLabel>
      <div className="text-sm">
        {props.paymentTerm || '-'}
      </div>
    </>
  );

  const deleteDispatchPayableDialog = (
    <ConfirmDialog
      visible={isDeleteDialogVisible}
      onHide={() => setIsDeleteDialogVisible(false)}
      message="Are you sure you want to proceed?"
      header="Delete Payable Confirmation"
      icon="pi pi-exclamation-triangle"
      accept={async () => await deleteDispatchPayable()}
      reject={() => setIsDeleteDialogVisible(false)}
    />
  );

  let fileName = '';
  let url = '';
  if (props.invoice) {
    const file = getAttribute(props.invoice, 'file');
    fileName = file._name;
    url = file._url;
  }

  const invoiceChipTemplate = (
    <div className="text-xs flex invoice-chip-template">
      <a href={url} target="_blank" rel="noopener noreferrer">
        {fileName}
      </a>

      <Button
        icon="pi pi-times"
        className="p-button-rounded p-button-sm p-button-outlined p-button-danger"
        onClick={props.handleInvoiceRemove}
      />
    </div>
  );

  const invoice = (
    <div className="invoice-upload">
      <InputLabel>Invoice</InputLabel>
      {!props.invoice && (
        <DispatchUploadDocument
          dispatchJob={props.dispatchJob}
          documentTypeInt={Document.INVOICE.type}
          disableDocumentTypeSelection
          multiple={false}
          handleDocumentUpload={props.handleInvoiceUpload}
        />
      )}

      {props.invoice && (
        <Chip
          template={invoiceChipTemplate}
          removable
        />
      )}
    </div>
  );

  async function updateDispatchPayableContact(contact) {
    return await updateDispatchPayable(
      props.dispatchPayable, // dispatchPayable
      undefined, // dispatchPayableObjectId
      { contact }, // keyValueObj
      true // save
    )
  }

  return (
    <ColoredSection title={accountingInformationHeader} className="payable-information">

      <div className="flex flex-column">
        <div className="flex">

          <div className="mr-7">
            {paymentStatus}
          </div>

          <div className="mr-7">
            {invoicedDate}
          </div>

          <div className="mr-7">
            {paymentTerm}
          </div>

          <div className="mr-7">
            {methodType}
          </div>

          <div className="mr-7">
            {invoice}
          </div>

        </div>
        
        <div className="mt-2 mb-2">
          <hr />
        </div>

        <div className="flex column-gap-8">
          <div className="flex flex-column">
            <InputLabel>Organization</InputLabel>
            <DispatchOrganizationCard
              dispatchOrganization={getAttribute(props.dispatchJob, 'customerDispatchOrganization', true)}
              disableActions
            />
          </div>
          <DispatchContactAutocomplete
            onSelectDispatchContact={(contact) => updateDispatchPayableContact(contact)}
            customerDispatchOrganization={getAttribute(props.dispatchJob, 'customerDispatchOrganization', true)}
            dispatchJob={props.dispatchJob}
            dispatchContact={getAttribute(props.dispatchPayable, 'contact', true)}
            allowAddDispatchOrganizationContact={!!getAttribute(props.dispatchJob, 'customerDispatchOrganization', true)}
            disabled={!(!!getAttribute(props.dispatchJob, 'customerDispatchOrganization', true))}
          />
        </div>
      </div>

      {deleteDispatchPayableDialog}

    </ColoredSection>
  );
}

export default PayableInformation;
