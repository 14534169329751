import React, { useEffect, useState, useRef, useContext } from 'react';

// Components
import Card from 'sbCore/Card/Card';
import Button from 'sbCore/Button/Button';
import Divider from 'sbCore/Divider/Divider';
import DispatchAddFreight from 'components/Dispatch/DispatchAddFreight/DispatchAddFreight';

// API
import { getAttribute, destroyRecord, getCurrentUserSessionToken, getObjectById } from 'sb-csapi/dist/AAPI';
import { getDispatchItemsByTransferId } from 'api/Dispatch/DispatchItem.old';
import { updateInvoiceOutdated } from 'api/Dispatch/DispatchJob';

// Context
import DispatchJobLayoutContext from 'contexts/DispatchJobLayoutContext';

// Enums
import { CommodityTypes } from 'enums/DispatchItem';
import { MassUnit, LengthUnit } from 'sb-csapi/dist/enums/Unit';
import { FreightCategory } from 'sb-csapi/dist/enums/Dispatch/Freight';

// CSS
import './style.scss';

const CUSTOM_COMMODITY_TYPE = 0;

// Might be good to move this over to a custom hook for use with checking prevProps
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function DispatchFreightSummary(props) {
  // const loadingMessage = 'Loading Freight Information...';
  // const [isLoading, setIsLoading] = useState(false);
  // const [lengthUnit, setLengthUnit] = useState(undefined);
  // const [massUnit, setMassUnit] = useState(undefined);

  // ** Context ** //
  const { refreshJob } = useContext(DispatchJobLayoutContext);

  const [dispatchItems, setDispatchItems] = useState([]);
  const [showItemModal, setShowItemModal] = useState(false);
  const [selectedDispatchItem, setSelectedDispatchItem] = useState(undefined);
  const [dispatchTransferObjectId, setDispatchTransferObjectId] = useState(undefined);
  const [dispatchJob, setDispatchJob] = useState(undefined);
  const prevProps = usePrevious(props);

  const refreshState = async () => {
    let _dispatchItems;
    if (props.dispatchTransferObjectId) _dispatchItems = await getDispatchItemsByTransferId(props.dispatchTransferObjectId);

    _dispatchItems ? setDispatchItems(_dispatchItems) : setDispatchItems([]);
    props.dispatchTransferObjectId ? setDispatchTransferObjectId(props.dispatchTransferObjectId) : setDispatchTransferObjectId(undefined);

    if (props.dispatchJobObjectId) {
      const dispatchJob = await getObjectById(undefined, 'DispatchJob', props.dispatchJobObjectId);
      setDispatchJob(dispatchJob);
    }
  };

  useEffect(() => {
    let unmounted = false;

    async function refresh() { !unmounted && await refreshState(); }
    if (prevProps !== props) refresh();

    return () => { unmounted = true; };
  }, [props]);

  const addDispatchItem = () => {
    setSelectedDispatchItem(undefined);
    setShowItemModal(true);
  };

  const editDispatchItem = (dispatchItem) => {
    setSelectedDispatchItem(dispatchItem);
    setShowItemModal(true);
  };

  const deleteDispatchItem = async (dispatchItem) => {
    const sessionToken = getCurrentUserSessionToken();
    await destroyRecord({ sessionToken }, dispatchItem);
    await updateInvoiceOutdated(dispatchJob, true);
    await refreshState();
    await refreshJob();
  };

  let className = 'dispatch-item-summary';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <div className="flex align-items-center py-2">
        <p className="font-semibold text-lg my-0 inline">Freight Information</p>
        <Button
          label="Freight"
          icon="pi pi-plus"
          className="p-button-sm shadow-none mx-3"
          sbVariant="slim"
          onClick={() => addDispatchItem()}
        />
      </div>

      <Divider className="mt-2" />

      {showItemModal && (
        <DispatchAddFreight
          dispatchTransfer={props.dispatchTransfer}
          dispatchJob={dispatchJob}
          handleClose={() => setShowItemModal(false)}
          item={selectedDispatchItem}
          refreshInfo={() => refreshState()}
        />
      )}

      {!showItemModal && (
        <div className="flex flex-wrap w-full">
          {dispatchItems && dispatchItems.length === 0 && (
            <div>
              <p className="text-400 font-italic">No freight items were found for this shipment. Add a new freight item to see it here.</p>
            </div>
          )}

          {/* All dispatch item info mapped out here */}
          {dispatchItems.map((dispatchItem) => {
            let commodityTypeName;

            const commodityTypeInt = getAttribute(dispatchItem, 'commodityType', true);
            
            if (commodityTypeInt === CUSTOM_COMMODITY_TYPE) {
              commodityTypeName = getAttribute(dispatchItem, 'commodityTypeCustomName', true);
            } else if (commodityTypeInt > CUSTOM_COMMODITY_TYPE) {
              commodityTypeName = CommodityTypes[commodityTypeInt];
            }

            const weightStr = getAttribute(dispatchItem, 'weight', true);
            const weightUnit = getAttribute(dispatchItem, 'massUnit');

            const commodityTypeNameStr = commodityTypeName;
            const categoryInt = getAttribute(dispatchItem, 'category', true);
            const categoryStr = categoryInt ? Object.values(FreightCategory).find((category) => category.type === categoryInt)?.description : '-';
            const quantity = getAttribute(dispatchItem, 'quantity', true);

            // Convert MassUnit enum to array and retrieve the weight based on the index
            const massUnitArr = Object.values(MassUnit);
            const massUnitStr = (weightUnit < massUnitArr.length) ? `${massUnitArr[weightUnit].toLowerCase()}(s)` : '';

            const itemLengthStr = getAttribute(dispatchItem, 'itemLength', true);
            const widthStr = getAttribute(dispatchItem, 'width', true);
            const heightStr = getAttribute(dispatchItem, 'height', true);

            const dimensionsUnit = getAttribute(dispatchItem, 'lengthUnit');

            // Convert LengthUnit enum to array and retrieve the unit based on the index
            const lengthUnitArr = Object.values(LengthUnit);
            const lengthUnitStr = (dimensionsUnit < lengthUnitArr.length) ? lengthUnitArr[dimensionsUnit].toLowerCase() : '';

            const nameStr = getAttribute(dispatchItem, 'name', true);

            return (
              <div className="mr-2 mb-2">
                <Card className="freight-card border-round hover:shadow-1">
                  <div className="grid">
                    <div className="col-8">
                      {
                        commodityTypeNameStr
                          ? <p className="mb-0 font-semibold">{commodityTypeNameStr}</p>
                          : <p className="mb-0 freight-field-warning font-semibold ">No freight type</p>
                      }
                    </div>
                    <div className="col-4 flex justify-content-end">
                      <Button
                        icon="pi pi-pencil"
                        className="p-button-rounded mx-1 w-2rem h-2rem"
                        tooltip="Edit Freight Item"
                        onClick={() => { editDispatchItem(dispatchItem); }}
                      />
                      <Button
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger mx-1 w-2rem h-2rem"
                        tooltip="Remove Freight Item"
                        onClick={() => { deleteDispatchItem(dispatchItem); }}
                      />
                    </div>
                  </div>

                  {(quantity && categoryStr)
                    ? <p className="mb-0">{quantity} {categoryStr}(s)</p>
                    : <p className="mb-0 text-400 font-italic">No category information</p>
                  }
                  {
                    weightStr
                      ? <p className="mb-0">{weightStr} {massUnitStr}</p>
                      : <p className="mb-0 text-400 font-italic">No weight information</p>
                  }
                  {
                    !(itemLengthStr || widthStr || heightStr)
                      ? <p className="text-400 font-italic">No size information</p>
                      : <p>{itemLengthStr ? `${itemLengthStr}L` : '0L'} x {widthStr ? `${widthStr}W` : '0W'} x {heightStr ? `${heightStr}H` : '0H'} {lengthUnitStr ? lengthUnitStr : '-'}</p>
                  }
                  {
                    nameStr
                      ? <p className="mb-0">{nameStr}</p>
                      : <p className="mb-0 text-400 font-italic">No additional details</p>
                  }
                </Card>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default DispatchFreightSummary;
