/*
 * This file contains general getter functions useful throughout the app
 * Please list functions in alphabetical order and comment well unless
 * the function listing order depends on requisite functions (ex. define function before used)
 */

import Parse from 'parse';
import moment from 'moment-timezone';
import * as ParseAPI from 'api/Parse';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import * as Chat from 'api/Chat';
import * as TextService from 'api/TextService';
import * as WSB from 'api/WeighStationBypass';
import { assignTrackerToTrailer } from '../api/Equipment/Trailer.old';
import { assignDashcamHardwareToVehicle } from '../api/Equipment/Vehicle.old';
import { getOrCreateELDHardwareFromSerial } from '../api/ELD/ELDHardware/ELDHardware';

/** @module Setters */

/**
 * @memberof module:Setters
 *
 * @param {*} tableName
 * @param {*} keyValueObject
 * @param {*} parseACL
 *
 * @returns
 */
function addRecord(tableName, keyValueObject, parseACL) {
  if (parseACL) {
    // given a table name in the database, and key-val pairs of attributes and their values
    // create a new record/obj in the Table
    const Record = Parse.Object.extend(tableName);
    const record = new Record();
    const keys = Object.keys(keyValueObject);
    const keysLen = keys.length;
    for (let i = 0; i < keysLen; i++) {
      if (keyValueObject[keys[i]] !== undefined) {
        record.set(keys[i], keyValueObject[keys[i]]);
      }
    }
    record.setACL(parseACL);
    return record.save();
  }
  return Promise.reject('addRecord requires parseACL');
}

/**
 * @memberof module:Setters
 * @param {*} jobInfo
 */
function addJob(jobInfo) {
  Parse.Cloud.run('addJob', jobInfo);
}

/**
 * @memberof module:Setters
 *
 * @param {*} documentFile
 * @param {*} jobLink
 * @param {*} documentCategory
 *
 * @returns
 */
function addDocument(documentFile, jobLink, documentCategory) {
  // ROTATION for constrained aspect ratio: https://github.com/MrRio/jsPDF/commit/b6f019ced0f31939878feaa0dca4f2b3c4c20b84
  // Fit to page: http://stackoverflow.duapp.com/questions/36472094/how-to-set-image-to-fit-width-of-the-page-using-jspdf
  const companyReadWriteACL = Helpers.getCompanyReadWriteACL();
  const parseFileName = documentCategory.get('type').concat('.pdf');
  const promise = new Promise((resolve, reject) => {
    if (/\.(jpe?g|png)$/i.test(documentFile.name)) {
      Helpers.scaleAndConvertToPDF(documentFile).then((dataURI) => {
        const base64 = dataURI.split('base64,')[1];
        const parseFile = new Parse.File(parseFileName, { base64 });
        resolve(addRecord('Document', { file: parseFile, jobLink, jobAction: jobLink.get('jobActions'), documentCategory, uploadedBy: Parse.User.current() }, companyReadWriteACL));
      });
    } else {
      const parseFile = new Parse.File(parseFileName, documentFile, documentFile.type);
      resolve(addRecord('Document', { file: parseFile, jobLink, jobAction: jobLink.get('jobActions'), documentCategory, uploadedBy: Parse.User.current() }, companyReadWriteACL));
    }
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} type
 * @param {*} jobId
 * @param {*} currentUser
 * @param {*} documentName
 * @param {*} recipientUserArr
 * @param {*} taskClass
 * @param {*} taskId
 *
 * @returns
 */
function addDriverTask(type, jobId, currentUser, documentName, recipientUserArr, taskClass, taskId) {
  if (taskClass === undefined || taskId === undefined) {
    // console.error('Need taskClass & taskId');
    return Promise.reject('Need taskClass & taskId');
  }
  const taskACL = new Parse.ACL();
  const recipientUserArrLen = recipientUserArr.length;
  for (let i = 0; i < recipientUserArrLen; i++) {
    taskACL.setWriteAccess(recipientUserArr[0].id, true);
    taskACL.setReadAccess(recipientUserArr[0].id, true);
  }
  taskACL.setWriteAccess(Parse.User.current().id, true);
  taskACL.setReadAccess(Parse.User.current().id, true);
  const timeSinceEpoch = Helpers.getNotificationTimeSinceEpoch();
  const textObj = { type, jobId, name: `${currentUser.get('firstName')} ${currentUser.get('lastName')}`, documentName, timeSinceEpoch };
  const text = `رً${JSON.stringify(textObj)}( ͡° ͜ʖ ͡°)`;
  const taskParseObj = { text, recipientId: recipientUserArr, senderId: Parse.User.current(), timeSinceEpoch, taskClass, taskId };
  return addRecord('DriverTask', taskParseObj, taskACL);
}

/**
 * @memberof module:Setters
 *
 * @param {*} userInfo
 * @param {*} returnUserObject
 *
 * @returns
 */
function addDriver(userInfo, returnUserObject, sendTextToDriver = true) {
  // returnUserObject determines if the return is a DriverObject or a DriverObject
  const credentials = {
    userInfo: { ...userInfo },
    returnUserObject: { ...returnUserObject },
  };
  const firstName = Helpers.toTitleCase(credentials.userInfo.firstName);
  const lastName = Helpers.toTitleCase(credentials.userInfo.lastName);

  const promise = new Promise((resolve, reject) => {
    // generate password
    Helpers.generatePassword().then(
      password => {
        if (!credentials.userInfo.password) {
          credentials.userInfo.password = password;
        }
        credentials.userInfo.dispatcherId = userInfo.dispatchers[0].id; // note we cannot pass in parse objects, so use id
        delete credentials.userInfo.dispatchers;

        Parse.Cloud.run('addDriver', credentials).then(
          driverObject => {
            resolve(driverObject);
            // [NOTIFICATION IN APP SAYING 'Invite sent to your truck driver! He or she will need to download the mobile application and log in before you can start pushing jobs to them']
            // Create chat room first
            Chat.retrieveChatRoom(undefined, [driverObject]);

            if (sendTextToDriver) {
              const { phoneNumber } = credentials.userInfo;
              const username = driverObject.get('user').get('username');
              TextService.sendText(
                phoneNumber,
                `Switchboard Electronic Logs Username: ${username}`
              ).then(() => {
                TextService.sendText(
                  phoneNumber,
                  `Switchboard Electronic Logs Password: ${credentials.userInfo.password || password}`
                ).then(() => {
                  TextService.sendText(
                    phoneNumber,
                    `Take a look at our tutorials to get started with Switchboard: https://www.youtube.com/channel/UCv1Yq2l3AhjRmUCdFAumI7Q/playlists`
                  );
                });
              });
            }

            // create weighStationBypass permissions
            WSB.addWSBDriver(driverObject);
          },
          error => {
            console.log(error);
            reject(error);
          }
        );
      },
    );
  });

  return promise;
}

/**
 * @memberof module:Setters
 * @param {*} driverObjectArr
 * @returns
 */
function addDriversAsTeam(driverObjectArr) {
  // remember these 2 given drivers as a team
  // this is only concerned with client-side, no need to consider when driver adds a job
  // remember search fields are populated on the backend
  const promise = new Promise((resolve, reject) => {
    if (driverObjectArr.length === 2) {
      // first determine if this combo has been used before. if so, mark up the occurences
      const driverA = driverObjectArr[0];
      const driverB = driverObjectArr[1];

      const driverTeamQuery = new Parse.Query('DriverTeam');
      driverTeamQuery.equalTo('driverA', driverA);
      driverTeamQuery.equalTo('driverB', driverB);

      driverTeamQuery.first().then(
        driverTeamObject => {
          if (driverTeamObject) {
            // already exists, increment occurences
            driverTeamObject
              .set('occurences', driverTeamObject.get('occurences') + 1)
              .save().then(
                () => resolve(driverTeamObject),
                error => reject(error)
              );
          } else {
            addRecord('DriverTeam', {
              driverA,
              driverB,
              belongsToCompany: Parse.User.current().get('belongsToCompany'),
              occurences: 1,
            }, Helpers.getCompanyReadWriteACL()).then(
              record => {
                resolve(record);
              },
              error => reject(error)
            );
          }
        },
        error => reject(error)
      );
    }
  });

  return promise;
}

/**
 * @memberof module:Setters
 * @param {*} propertiesObj
 * @returns
 */
function addGeofence(propertiesObj) {
  const belongsToCompanyPointer = ParseAPI.makeParseObjectById('Company', Parse.User.current().get('belongsToCompany').id);
  const parseInputObj = { ...propertiesObj, enabled: true, belongsToCompany: belongsToCompanyPointer };
  return addRecord('Geofence', parseInputObj, Helpers.getCompanyReadWriteACL());
}

/**
 * @memberof module:Setters
 * @param {*} documentFile
 * @returns
 */
function addTempFile(documentFile) {
  const companyReadWriteACL = Helpers.getCompanyReadWriteACL();
  const companyPrefix = Getters.getCompanyPrefix();
  const parseFileName = `${companyPrefix}_carrier_profile.pdf`;

  const promise = new Promise((resolve, reject) => {
    const parseFile = new Parse.File(parseFileName, documentFile, documentFile.type);
    addRecord('TempFile', { file: parseFile }, companyReadWriteACL).then(
      tempFileObj => resolve(tempFileObj),
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} base64String
 * @param {*} filename
 *
 * @returns
 */
function addBase64PNGToTempFile(base64String, filename) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('base64ToPNG', { base64String, filename }).then(
      parseObject => resolve(parseObject),
      error => reject(error),
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} vehicleParseObj
 * @param {*} driversParseArr
 *
 * @returns
 */
function assignDriversToVehicle(vehicleParseObj, driversParseArr) {
  const vehicle = vehicleParseObj.toPointer();
  const drivers = driversParseArr.map((driverParseObj) => driverParseObj.toPointer());
  return Parse.Cloud.run('AssignDriversToVehicle', { vehicle, drivers });
}

/**
 * @memberof module:Setters
 * @param {*} addressInputObj
 * @returns
 */
function createNewAddress(addressInputObj) {
  const belongsToCompanyPointer = ParseAPI.makeParseObjectById('Company', Parse.User.current().get('belongsToCompany').id);
  const parseInputObj = { ...addressInputObj };
  parseInputObj.belongsToCompany = belongsToCompanyPointer;
  const keys = Object.keys(parseInputObj);
  const keysLen = keys.length;
  for (let i = 0; i < keysLen; i++) {
    if (parseInputObj[keys[i]] !== undefined) {
      let value = parseInputObj[keys[i]];
      if (typeof value === 'string') {
        value = value.trim();
      }
      parseInputObj[keys[i]] = value;
    }
  }
  return addRecord('Address', parseInputObj, Helpers.getCompanyReadWriteACL());
}

/**
 * @memberof module:Setters
 *
 * @param {*} childCompanyDotNumber
 * @param {*} childCompanyNscNumber
 *
 * @returns
 */
function createCompanyLink(childCompanyDotNumber, childCompanyNscNumber) {
  return Parse.Cloud.run('createCompanyLink', { childCompanyDotNumber, childCompanyNscNumber });
}

/**
 * @memberof module:Setters
 * @param {*} driverId
 */
function checkAndSetAOBRDELDHardwares(driverId) {
  // function to update eld hardwares
  Parse.Cloud.run('internal:aobrdCheck', { driverId }).then(() => {
  }, (error) => {
    console.log(error);
  });
}

/**
 * @memberof module:Setters
 * @param {*} companyLinkId
 * @returns
 */
function authorizeCompanyLink(companyLinkId) {
  return Parse.Cloud.run('authorizeCompanyLink', { companyLinkId });
}

/**
 * @memberof module:Setters
 * @param {*} companyLinkId
 * @returns
 */
function unauthorizeCompanyLink(companyLinkId) {
  return Parse.Cloud.run('unauthorizeCompanyLink', { companyLinkId });
}

/**
 * @memberof module:Setters
 * @param {*} jobLink
 */
function driversAssignJobCallback(jobLink) {
  // notify the drivers of a joblink they have a new job and add them to driver teams
  // alerted{} is to ensure we don't send the same notification twice
  const alerted = {};
  const jobActions = jobLink.get('jobActions');
  const addDriverTeamPromises = [];
  for (let i = 0; i < jobActions.length; i++) {
    const jobAction = jobActions[i];
    const jobDriver = jobAction.get('jobDriver');

    if (jobDriver) {
      const drivers = jobDriver.get('driver');
      addDriverTeamPromises.push(() => addDriversAsTeam(drivers));
      for (let j = 0; j < drivers.length; j++) {
        const driver = drivers[i];
        if (!alerted[driver.id]) {
          ParseAPI.sendPush([driver.get('user')], `New Job with Id: ${jobAction.get('jobId')}`);
          addDriverTask('New Job', jobAction.get('jobId'), ParseAPI.getCurrentUser(), undefined, [driver.get('user')], 'JobLink', jobLink.id);
          alerted[driver.id] = true; // this have received an alert for this joblink
        }
      }
    }
  }
  Helpers.executePromisesSynchronously(addDriverTeamPromises);
}

/**
 * @memberof module:Setters
 *
 * @param {*} pickupRequestObject
 * @param {*} dropoffRequestObject
 *
 * @returns
 */
function createNewJob(pickupRequestObject, dropoffRequestObject) {
  // both objects MUST contain jobId or an error is thrown
  // passable: jobId, actionDate, driverIdArr, notes, vendor, dispatcherIdArr, etc. look at backend to find
  const promise = new Promise((resolve, reject) => {
    const pickupRequestObj = { ...pickupRequestObject };
    const dropoffRequestObj = { ...dropoffRequestObject };

    Parse.Cloud.run('createNewJob', { pickupRequestObj, dropoffRequestObj }).then(
      jobLink => {
        // Now get associated drivers and create them as a team + alert them of new job
        resolve(jobLink);
        driversAssignJobCallback(jobLink);
      },
      error => {
        reject(error);
      }
    );
  });
  return promise;
}

// function createNewLoad(jobDriverInputArr, jobActionInputArr, jobLinkInputObj, jobEntityInputObj) {
//   const promise = new Promise((resolve, reject) => {
//     const companyReadWriteACL = Helpers.getCompanyReadWriteACL();
//     let jobDriverSavePromises = [];
//     const jobDriverInputArrLen = jobDriverInputArr.length;
//     for (let i = 0; i < jobDriverInputArrLen; i++) {
//       jobDriverSavePromises.push(addRecord('JobDriver', jobDriverInputArr[i], companyReadWriteACL));
//     }
//     // if job driver empty, return an array of promises that resolves to undefined elements
//     if (jobDriverSavePromises.length === 0) {
//       jobDriverSavePromises = Array(jobActionInputArr.length).fill(Promise.resolve(undefined));
//     }
//     Promise.all(jobDriverSavePromises).then((jobDriverParseObjArr) => {
//       const jobActionSavePromises = [];
//       const jobActionInputArrLen = jobActionInputArr.length;
//       for (let i = 0; i < jobActionInputArrLen; i++) {
//         const updatedJobActionInputObj = jobActionInputArr[i];
//         // attach jobDriver to jobAction (after jobDriver objects created)
//         if (jobDriverParseObjArr[i]) {
//           updatedJobActionInputObj.jobDriver = jobDriverParseObjArr[i];
//         }
//         jobActionSavePromises.push(addRecord('JobAction', updatedJobActionInputObj, companyReadWriteACL));
//       }
//       Promise.all(jobActionSavePromises).then((jobActionParseObjArr) => {
//         // Attach jobAction to jobDriver
//         const jobDriverParseObjArrLen = jobDriverParseObjArr.length;
//         for (let i = 0; i < jobDriverParseObjArrLen; i++) {
//           if (jobDriverParseObjArr[i]) {
//             jobDriverParseObjArr[i].set('jobAction', jobActionParseObjArr[i]);
//           }
//         }
//         Parse.Object.saveAll(jobDriverParseObjArr, {
//           success: () => {
//             const jobLinkAndEntitySavePromises = [];
//             const updatedJobLinkInputObj = jobLinkInputObj;
//             const updatedJobEntityInputObj = jobEntityInputObj;
//             const belongsToCompanyPointer = ParseAPI.makeParseObjectById('Company', Parse.User.current().get('belongsToCompany').id);
//             // attach jobAction to jobLink
//             updatedJobLinkInputObj.jobActions = jobActionParseObjArr;
//             updatedJobLinkInputObj.belongsToCompany = belongsToCompanyPointer;

//             jobLinkAndEntitySavePromises.push(addRecord('JobLink', updatedJobLinkInputObj, companyReadWriteACL));
//             // attach jobAction to jobEntity
//             updatedJobEntityInputObj.jobActions = jobActionParseObjArr;
//             updatedJobEntityInputObj.belongsToCompany = belongsToCompanyPointer;
//             jobLinkAndEntitySavePromises.push(addRecord('JobEntity', updatedJobEntityInputObj, companyReadWriteACL));
//             Promise.all(jobLinkAndEntitySavePromises).then((results) => {
//               const jobLinkParseObj = results[0];
//               resolve(jobLinkParseObj);
//             });
//           },
//           error: (error) => {
//             console.log(error);
//             reject(error);
//           },
//         });
//       });
//     });
//   });
//   return promise;
// }

/**
 * @memberof module:Setters
 *
 * @param {*} globalJobDetails
 * @param {*} pickupObj
 * @param {*} dropoffObj
 *
 * @returns
 */
function createNewPickupDropoffLoad(globalJobDetails, pickupObj, dropoffObj) {
  const promise = new Promise((resolve, reject) => {
    let driverIdArr;
    if (globalJobDetails.vehicle && globalJobDetails.vehicle.get('drivers')) {
      driverIdArr = globalJobDetails.vehicle.get('drivers').map(driver => driver.id);
    }

    const jobActionPickupInputObj = {
      actionDate: pickupObj.date ? new Date(pickupObj.date.toDate()) : undefined,
      jobId: globalJobDetails.jobId,
      vendorObjectId: pickupObj.vendor.id,
      vehicleObjectId: globalJobDetails.vehicle.id,
      notes: pickupObj.notes,
      driverIdArr,
    };

    const jobActionDropoffInputObj = {
      actionDate: dropoffObj.date ? new Date(dropoffObj.date.toDate()) : undefined,
      jobId: globalJobDetails.jobId,
      vendorObjectId: dropoffObj.vendor.id,
      vehicleObjectId: globalJobDetails.vehicle.id,
      notes: dropoffObj.notes,
      driverIdArr,
    };

    createNewJob(jobActionPickupInputObj, jobActionDropoffInputObj).then(
      jobLink => resolve(jobLink),
      error => reject(error),
    );
  });
  return promise;
}

/**
 *@memberof module:Setters

 * @param { object } globalJobDetails
 * @param { array } pickupDropoffArr
 *
 * @returns { parseObject } jobLink
 */
function createJob(globalJobDetails, pickupDropoffArr) {
  const promise = new Promise((resolve, reject) => {
    const jobActionArr = [];

    for (let i = 0; i < pickupDropoffArr.length; i++) {
      const pickupDropoff = pickupDropoffArr[i];
      const jobActionType = pickupDropoff.jobActionType.toLowerCase();
      const drivers = pickupDropoff.drivers;
      const vendor = pickupDropoff.vendor;
      const vehicle = pickupDropoff.vehicle;

      const driverIdArr = (drivers || []).map(driver => driver.id);

      let jobActionInputObj = {};
      if (jobActionType === 'pickup') {
        jobActionInputObj = {
          jobActionTypeInt: 0,
          actionDate: pickupDropoff.date ? moment(pickupDropoff.date).toDate() : undefined,
          jobId: pickupDropoff.jobId,
          vendorObjectId: vendor && vendor.id,
          vehicleObjectId: vehicle && vehicle.id,
          notes: pickupDropoff.notes,
          driverIdArr,
        };
      } else if (jobActionType === 'dropoff') {
        jobActionInputObj = {
          jobActionTypeInt: 1,
          actionDate: pickupDropoff.date ? moment(pickupDropoff.date).toDate() : undefined,
          jobId: pickupDropoff.jobId,
          vendorObjectId: vendor && vendor.id,
          vehicleObjectId: vehicle && vehicle.id,
          notes: pickupDropoff.notes,
          driverIdArr,
        };
      }

      jobActionArr.push(jobActionInputObj);
    }

    // console.log(jobActionArr);

    Parse.Cloud.run('createJob', { globalJobDetails, jobActionArr }).then(
      (jobLink) => {
        console.log(jobLink);
        resolve(jobLink);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 * @param {*} vendorInputObj
 * @returns
 */
function createNewVendor(vendorInputObj) {
  const belongsToCompanyPointer = ParseAPI.makeParseObjectById('Company', Parse.User.current().get('belongsToCompany').id);
  const parseInputObj = vendorInputObj;
  parseInputObj.address_address = vendorInputObj.address.get('address').toLowerCase();
  parseInputObj.address_city = vendorInputObj.address.get('city').toLowerCase();
  parseInputObj.address_stateProvince = vendorInputObj.address.get('stateProvince').toLowerCase();
  parseInputObj.address_country = vendorInputObj.address.get('country').toLowerCase();
  parseInputObj.belongsToCompany = belongsToCompanyPointer;
  return addRecord('Vendor', parseInputObj, Helpers.getCompanyReadWriteACL());
}

/**
 * @memberof module:Setters
 * @param {*} trailerInputObj
 * @returns
 */
function createNewTrailer(trailerInputObj) {
  const promise = new Promise((resolve, reject) => {
    const belongsToCompanyPointer = ParseAPI.makeParseObjectById('Company', Parse.User.current().get('belongsToCompany').id);
    const parseInputObj = trailerInputObj;
    parseInputObj.belongsToCompany = belongsToCompanyPointer;
    if (parseInputObj.unitId) {
      parseInputObj.unitId = parseInputObj.unitId.toLowerCase();
    }
    addRecord('Trailer', parseInputObj, Helpers.getCompanyReadWriteACL()).then(
      (trailer) => {
        addRecord('LicensePlate', {
          trailer,
          plate: parseInputObj.plate,
          stateProvince: parseInputObj.stateProvince,
          belongsToCompany: belongsToCompanyPointer,
        }, Helpers.getCompanyReadWriteACL()).then(
          licensePlate => {
            trailer.set('licensePlate', licensePlate).save().then(
              newTrailer => {
                var deviceId = newTrailer.get('tc_devices_uniqueid');
                if (deviceId && deviceId.trim() !== '') {
                  assignTrackerToTrailer(newTrailer.get('tc_devices_uniqueid'), newTrailer.id)
                    .then((trailer) => {
                      resolve(trailer);
                    }).catch((error) => {
                      reject(error);
                    });
                } else {
                  resolve(newTrailer);
                }
              },
              error => reject(error)
            );
          },
          error => reject(error)
        );
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 * @param {*} vehicleInputObj
 * @returns
 */
const createNewVehicle = async (vehicleInputObj) => {
  const promise = new Promise((resolve, reject) => {
    const belongsToCompanyPointer = ParseAPI.makeParseObjectById('Company', Parse.User.current().get('belongsToCompany').id);
    const parseInputObj = { ...vehicleInputObj, eldHardwareSerial: undefined };
    parseInputObj.belongsToCompany = belongsToCompanyPointer;
    if (parseInputObj.unitId) {
      parseInputObj.unitId = parseInputObj.unitId.toLowerCase();
    }

    addRecord('Vehicle', parseInputObj, Helpers.getCompanyReadWriteACL()).then(
      (vehicle) => {
        addRecord('LicensePlate', {
          vehicle,
          plate: parseInputObj.plate,
          stateProvince: parseInputObj.stateProvince,
          belongsToCompany: belongsToCompanyPointer,
        }, Helpers.getCompanyReadWriteACL()).then(
          licensePlate => {
            vehicle.set('licensePlate', licensePlate).save().then(
              async (newVehicle) => {
                if (vehicleInputObj.eldHardwareSerial) {
                  const eldHardware = await getOrCreateELDHardwareFromSerial(vehicleInputObj.eldHardwareSerial);
                  vehicle.set('eldHardware', eldHardware).save();
                }

                // Dashcam ID
                const dashcamHardwareId = vehicleInputObj.dashcamHardwareId;
                if (dashcamHardwareId && dashcamHardwareId.trim() !== '') {
                  assignDashcamHardwareToVehicle(dashcamHardwareId, newVehicle.id).then((vehicle) => {
                    resolve(vehicle);
                  }).catch((error) => {
                    reject(error);
                  });
                } else {
                  resolve(newVehicle);
                }
              },
              error => reject(error)
            );
          },
          error => reject(error)
        );
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 * @param {*} safetyReminderInputObj
 * @returns
 */
function createNewSafetyRecurringReminder(safetyReminderInputObj) {
  const belongsToCompanyPointer = ParseAPI.makeParseObjectById('Company', Parse.User.current().get('belongsToCompany').id);
  const parseInputObj = safetyReminderInputObj;
  parseInputObj.belongsToCompany = belongsToCompanyPointer;
  return addRecord('SafetyRecurringReminder', parseInputObj, Helpers.getCompanyReadWriteACL());
}

/**
 * @memberof module:Setters
 * @param {*} vehicleParseObj
 * @returns
 */
function createShareVehicleLocation(vehicleParseObj) {
  const shareVehicleLocationACL = Helpers.getCompanyReadWriteACL();
  return addRecord('ShareVehicleLocation', { vehicle: vehicleParseObj, expireAt: moment().add(1, 'days').endOf('day').toDate() }, shareVehicleLocationACL);
}

/**
 * @memberof module:Setters
 *
 * @param {*} tcDeviceParseObj
 * @param {*} type
 *
 * @returns
 */
function createShareLocation(tcDeviceParseObj, type) {
  const shareVehicleLocationACL = Helpers.getCompanyReadWriteACL();
  return addRecord('ShareLocation', {
    type,
    tcDevice: tcDeviceParseObj,
    expireAt: moment().add(1, 'days').endOf('day').toDate()
  }, shareVehicleLocationACL);
}

/**
 * @memberof module:Setters
 *
 * @param {*} jobActionObj
 * @param {*} driverParseObjArr
 *
 * @returns
 */
function assignNewDriversToJobAction(jobActionObj, driverParseObjArr) {
  const promise = new Promise((resolve, reject) => {
    const jobDriverACL = Helpers.getCompanyReadWriteACL();
    addRecord('JobDriver', { driver: driverParseObjArr, jobAction: jobActionObj }, jobDriverACL).then(newJobDriverParseObj => {
      jobActionObj.set('jobDriver', newJobDriverParseObj);
      jobActionObj.save().then(jobAction => {
        resolve(jobAction);
      });
    });
  });
  return promise;
}

/**
 * @memberof module:Setters
 * @param {*} vehicle
 * @returns
 */
function pingGNXLocation(vehicle) {
  if (vehicle.get('eldHardware') && vehicle.get('eldHardware').get('MSISDN')) {
    const lastPing = vehicle.get('eldHardware').get('lastEmergencyPing');
    if (!lastPing || moment().subtract(5, 'minutes').isAfter(moment(lastPing))) {
      vehicle.get('eldHardware').set('lastEmergencyPing', moment().toDate()).save();
      return TextService.sendText(vehicle.get('eldHardware').get('MSISDN'), 'DIAG STATE VIASMS REPLYTOME');
    }
    return Promise.reject();
  }
  return Promise.resolve();
}

/**
 * @memberof module:Setters
 * @param {*} vehicle
 * @returns
 */
function pingLocation(vehicle) {
  if (vehicle.get('drivers')) {
    const userArray = [];
    for (let i = 0; i < vehicle.get('drivers').length; i++) {
      userArray.push(vehicle.get('drivers')[i].get('user'));
    }
    return ParseAPI.sendPush(userArray, { type: 3 });
  }
  return Promise.resolve();
}

/**
 * @memberof module:Setters
 * @param {*} parseUser
 * @param {*} onlyDisable
 * @returns
 */
function deleteNonDriverUser(parseUser, onlyDisable) {
  return Parse.Cloud.run('deleteNonDriverUser', { userPointer: parseUser.toPointer(), onlyDisable });
}

/**
 * @memberof module:Setters
 *
 * @param {*} notificationObject
 * @param {*} destroy
 *
 * @returns
 */
function setNotificationRead(notificationObject, destroy) {
  const promise = new Promise((resolve, reject) => {
    const notificationQuery = new Parse.Query('Notification');
    notificationQuery.equalTo('timeSinceEpoch', notificationObject.timeSinceEpoch);
    notificationQuery.find().then(
      (parseNotificationObjects) => {
        parseNotificationObjects.map((parseNotificationObject) => {
          parseNotificationObject.set('read', true);
          parseNotificationObject.save().then(
            savedNotificationObject => {
              // save and returned the notification as 'read', but delete it behind the scenes
              resolve(savedNotificationObject);
              if (destroy) {
                savedNotificationObject.destroy();
              }
            },
            error => reject(error)
          );
        });
      },
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 * @param {*} driverObject
 * @returns
 */
function setDriverStatusActive(driverObject) {
  const promise = new Promise((resolve, reject) => {
    if (driverObject.get('driverStatus') && driverObject.get('driverStatus').get('referenceInt') === 1) {
      // set driver status to in job only if the driver was previously available a
      Getters.getDriverStatuses(2).then(
        activeStatusObject => {
          driverObject.set('driverStatus', activeStatusObject);
          driverObject.save().then(
            driverParseObject => resolve(driverParseObject),
            error => reject(error),
          );
        },
        error => reject(error)
      );
    }
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} firstName
 * @param {*} lastName
 * @param {*} emailAddress
 * @param {*} password
 *
 * @returns
 */
function createNewUserToCompany(firstName, lastName, emailAddress, password) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('registerNewUserToCompany', { firstName, lastName, emailAddress, password }).then((user) => {
      resolve(user);
    }).catch((err) => {
      reject(err);
    });
  });
  return promise;
}

/**
 * @memberof module:Setters
 * @param {*} driverObject
 * @returns
 */
function setDriverStatusAvailable(driverObject) {
  const promise = new Promise((resolve, reject) => {
    Getters.getDriverStatuses(1).then(
      availableStatusObject => {
        driverObject.set('driverStatus', availableStatusObject);
        driverObject.save().then(
          driverParseObject => resolve(driverParseObject),
          error => reject(error),
        );
      },
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} equipmentParseObj
 * @param {*} nextDateMoment
 *
 * @returns
 */
function setEquipmentInspection(equipmentParseObj, nextDateMoment) {
  return equipmentParseObj.set('nextInspectionDate', nextDateMoment.toDate()).save();
}

/**
 * @memberof module:Setters
 * @param {*} jobAction
 * @returns
 */
function removeJobDriverFromJobAction(jobAction) {
  jobAction.set('jobDriver', null);
  return jobAction.save();
}

/**
 * @memberof module:Setters
 *
 * @param {*} userObjectId
 * @param {*} property
 * @param {*} value
 * @param {*} valueClass
 *
 * @returns
 */
function updateUser(userObjectId, property, value, valueClass) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('updateUser', { id: userObjectId, property, value, valueClass }).then(
      userObject => resolve(userObject),
      error => { reject(error); },
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} companyId
 * @param {*} property
 * @param {*} value
 *
 * @returns
 */
function updateCompany(companyId, property, value) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('updateCompany', { companyId, property, value }).then(
      updatedCompany => {
        resolve(updatedCompany);
      },
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} parseObject
 * @param {*} keyValueObject
 * @param {*} shouldTrimStrings
 *
 * @returns
 */
function updateParseObject(parseObject, keyValueObject, shouldTrimStrings) {
  const promise = new Promise((resolve, reject) => {
    const keys = Object.keys(keyValueObject);
    const keysLen = keys.length;
    for (let i = 0; i < keysLen; i++) {
      let value = keyValueObject[keys[i]];
      if (value !== undefined) {
        if (shouldTrimStrings) {
          value = value.trim();
        }
        parseObject.set(keys[i], value);
      }
    }

    parseObject.save().then(
      savedParseObject => resolve(savedParseObject),
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} dispatcherUserObjectId
 * @param {*} property
 * @param {*} value
 *
 * @returns
 */
function updateDispatcher(dispatcherUserObjectId, property, value) {
  // we use dispatcherUserObjectId here in case we want to also modify user objects as a result
  const promise = new Promise((resolve, reject) => {
    const userQuery = new Parse.Query(Parse.User);
    userQuery.equalTo('objectId', dispatcherUserObjectId);
    const dispatcherQuery = new Parse.Query('Dispatcher');
    dispatcherQuery.matchesQuery('user', userQuery);

    dispatcherQuery.first().then(
      dispatcher => {
        if (dispatcher) {
          dispatcher.set(property, value);
          dispatcher.save().then(
            savedDispatcher => resolve(savedDispatcher),
            error => reject(error),
          );
        } else {
          reject('Could not find matching Dispatcher');
        }
      },
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} driverUserId
 * @param {*} nextPassword
 *
 * @returns
 */
function changeDispatcherPassword(driverUserId, nextPassword) {
  // function to reset a driver's password
  const promise = new Promise((resolve, reject) => {
    updateUser(driverUserId, 'password', nextPassword).then(
      userObject => {
        // const phoneNumber = userObject.get('phoneNumber');
        // TextService.sendText(
        //   phoneNumber,
        //   `${userObject.get('firstName')}, Switchboard here to confirm that you have changed your password`
        //   ).then(() => {
        //     TextService.sendText(
        //       phoneNumber,
        //       'Don\'t recognize this activity? Get in touch with your staff or contact us at 1-844-5-FLEETS'
        //       );
        //   });
        resolve(userObject);
      },
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 * @param {*} email
 * @returns
 */
async function resetDispatcherPassword(email) {
  // given an email, generate and send new password to that email
  // for this case, password is generated on the backend (don't want to expose client-side)
  await ParseAPI.logout();
  return Parse.User.requestPasswordReset(email);
}

/**
 * @memberof module:Setters
 *
 * @param {*} driverUserId
 * @param {*} backupPhoneNumber
 *
 * @returns
 */
function resetDriverPassword(driverUserId, backupPhoneNumber) {
  // function to reset a driver's password
  const promise = new Promise((resolve, reject) => {
    Helpers.generatePassword().then(
      password => {
        updateUser(driverUserId, 'password', password).then(
          userObject => {
            const currentUser = Parse.User.current();
            const phoneNumber = userObject.get('phoneNumber');
            const username = userObject.get('username');

            const textMessages = [
              `New Switchboard Electronic Logs Username: ${username}`,
              `New Switchboard Electronic Logs Password: ${password}`,
            ];

            TextService.sendText(
              phoneNumber,
              textMessages[0]
            ).then(() => {
              TextService.sendText(
                phoneNumber,
                textMessages[1]
              );
            });
            if (backupPhoneNumber) {
              textMessages[0] = `New password ${userObject.get('firstName')} ${userObject.get('lastName')} (${username}) is: ${password}`;
              TextService.sendText(
                backupPhoneNumber,
                textMessages[0]
              );
            }

            // now email password as well
            if (userObject.get('email')) {
              let textBody = textMessages[0];
              if (textMessages[1]) textBody += `\n\n${textMessages[1]}`;
              textBody += '\n\nKind Regards,\nThe Switchboard Team';

              Parse.Cloud.run('sendEmail', {
                subject: 'Switchboard Password Reset',
                from: 'team@onswitchboard.com',
                to: userObject.get('email'),
                textBody,
              });
            }

            resolve(userObject);
          },
          error => {
            console.log(error);
            reject(error);
          }
        );
      },
      error => {
        console.log(error);
        reject(error);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} driverUserId
 * @param {*} password
 *
 * @returns
 */
function resetDriverCustomPassword(driverUserId, password, sendTextToDriver = true) {
  // function to reset a driver's password
  const promise = new Promise((resolve, reject) => {

    updateUser(driverUserId, 'password', password).then(
      userObject => {
        const currentUser = Parse.User.current();
        const phoneNumber = userObject.get('phoneNumber');
        const username = userObject.get('username');

        const textMessages = [
          `New Switchboard Electronic Logs Username: ${username}`,
          `New Switchboard Electronic Logs Password: ${password}`,
        ];

        if (sendTextToDriver) {
          TextService.sendText(
            phoneNumber,
            textMessages[0]
          ).then(() => {
            TextService.sendText(
              phoneNumber,
              textMessages[1]
            );
          });
        }

        // now email password as well
        if (userObject.get('email')) {
          let textBody = textMessages[0];
          if (textMessages[1]) textBody += `\n\n${textMessages[1]}`;
          textBody += '\n\nKind Regards,\nThe Switchboard Team';

          Parse.Cloud.run('sendEmail', {
            subject: 'Switchboard Password Reset',
            from: 'team@onswitchboard.com',
            to: userObject.get('email'),
            textBody,
          });
        }

        resolve(userObject);
      },
      error => {
        console.log(error);
        reject(error);
      }
    );
  },
    error => {
      console.log(error);
      reject(error);
    }
  );
  return promise;
}

/**
 * @memberof module:Setters
 * @param {*} simIccid
 * @returns
 */
function resetSim(simIccid) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('_internal:resetSim', { simIccid }).then(
      sim => resolve(sim),
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} tripDefect
 * @param {*} notes
 *
 * @returns
 */
function resolveTripDefect(tripDefect, notes) {
  tripDefect.set('fixed', true);
  tripDefect.set('fixedNotes', notes);
  return tripDefect.save();
}

/**
 * @memberof module:Setters
 *
 * @param {*} driverObjectId
 * @param {*} jobActionArr
 *
 * @returns
 */
function unassignJobActionsFromDriver(driverObjectId, jobActionArr) {
  // function to unassign jobActions of a jobLink from a driver's jobActions
  const promise = new Promise((resolve, reject) => {
    const jobActionIdArr = jobActionArr.map(jobAction => jobAction.id);
    Parse.Cloud.run('unassignJobActionsFromDriver', { driverObjectId, jobActionIdArr }).then(
      driver => {
        resolve(driver);
      },
      error => reject(error)
    );
    // const jobActionIds = [];
    // let jobActions = jobLink.get('jobActions');
    // let driverJobActions = driver.get('jobActions');

    // if (jobActions && driverJobActions) {
    //   jobActions = [].concat(jobActions);
    //   driverJobActions = [].concat(driverJobActions);

    //   let updatedDriverJobActions = [];
    //   const jobActionsLen = jobActions.length;
    //   let driverJobActionsLen = driverJobActions.length;

    //   for (let i = 0; i < jobActionsLen; i++) {
    //     // Get the ids of jobLink's jobactions
    //     jobActionIds.push(jobActions[i].id);
    //   }

    //   // if the driver has jobactions associated with jobActionIds, remove their association to them
    //   while (driverJobActionsLen--) {
    //     const driverJobAction = driverJobActions[driverJobActionsLen];
    //     for (let k = 0; k < jobActionIds.length; k++) {
    //       if (driverJobAction.id === jobActionIds[k]) {
    //         driverJobActions.splice(driverJobActionsLen, 1);
    //         break;
    //       }
    //     }
    //   }

    //   updatedDriverJobActions = [].concat(driverJobActions);
    //   if (updatedDriverJobActions.length === 0) {
    //     Setters.setDriverStatusAvailable(driver);
    //   }

    //   driver.set('jobActions', updatedDriverJobActions).save().then(
    //     () => {
    //       resolve(updatedDriverJobActions);
    //     },
    //     error => reject(error)
    //   );
    // }
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} drivers
 * @param {*} jobLink
 *
 * @returns
 */
function assignDriversToJobLink(drivers, jobLink) {
  const promise = new Promise((resolve, reject) => {
    const driverIdArr = drivers.map(driver => driver.id);

    Parse.Cloud.run('assignDriversToJobLink', { driverIdArr, jobLinkObjectId: jobLink.id }).then(
      updatedJobLink => {
        resolve(updatedJobLink);
        driversAssignJobCallback(updatedJobLink);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 * @param {*} jobLink
 * @returns
 */
function driversUnassignJobCallback(jobLink) {
  // notify the drivers of a joblink they have a new job and add them to driver teams
  // alerted{} is to ensure we don't send the same notification twice
  const promise = new Promise((resolve, reject) => {
    const alerted = {};
    const jobActions = jobLink.get('jobActions');
    for (let i = 0; i < jobActions.length; i++) {
      const jobAction = jobActions[i];
      const jobDriver = jobAction.get('jobDriver');

      if (jobDriver) {
        const drivers = jobDriver.get('driver');
        for (let j = 0; j < drivers.length; j++) {
          const driver = drivers[j];
          if (!alerted[driver.id]) {
            ParseAPI.sendPush([driver.get('user')], `You've been unassigned from Job Id: ${jobAction.get('jobId')}`);
            addDriverTask('Unassigned', jobAction.get('jobId'), ParseAPI.getCurrentUser(), undefined, [driver.get('user')], null, null);
            alerted[driver.id] = true; // this have received an alert for this joblink
          }
        }
      }
    }
    resolve(true);
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} drivers
 * @param {*} jobLink
 *
 * @returns
 */
function unassignDriversFromJobLink(drivers, jobLink) {
  const promise = new Promise((resolve, reject) => {
    const driverIdArr = drivers.map(driver => driver.id);

    // this needs to run first, before we remove references to jobdriver (hence cant send msg to driver)
    driversUnassignJobCallback(jobLink).then(
      () => {
        Parse.Cloud.run('unassignDriversFromJobLink', { driverIdArr, jobLinkObjectId: jobLink.id }).then(
          updatedJobLink => {
            resolve(updatedJobLink);
          }
        );
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Setters
 * @param {*} jobLinkObjectId
 * @returns
 */
function closeJobLink(jobLinkObjectId) {
  return Parse.Cloud.run('closeJobLink', { jobLinkObjectId });
}

/**
 * @memberof module:Setters
 *
 * @param {*} jobActionToBeDestroyed
 * @param {*} jobLink
 * @param {*} reject
 */
function destroyJobLinkCallback(jobActionToBeDestroyed, jobLink, reject) {
  const jobLinkStatusInt = jobLink.get('jobLinkStatusInt') || jobLink.get('jobLinkStatus').get('referenceInt');
  if (jobActionToBeDestroyed.get('jobDriver') && !Getters.isJobLinkComplete(jobLinkStatusInt)) {
    // remove all associated job actions from drivers
    const jobActions = jobLink.get('jobActions');
    const driver = jobActionToBeDestroyed.get('jobDriver').get('driver')[0];
    const coDriver = jobActionToBeDestroyed.get('jobDriver').get('driver')[1];
    if (coDriver && (coDriver.id !== driver.id)) {
      // if we have a second driver (thats not the same as the first)
      // Unassign the jobActions from Drivers
      unassignJobActionsFromDriver(driver.id, jobActions).then(
        () => {
          // console.log('success');
        },
        error => reject(error)
      );
      unassignJobActionsFromDriver(coDriver.id, jobActions).then(
        () => {
          // console.log('also success');
        },
        error => reject(error)
      );

      // ParseAPI.sendPush([driver.get('user'), coDriver.get('user')], `You've been unassigned from Job Id: ${jobActionToBeDestroyed.get('jobId')}`);
      addDriverTask('Unassigned', jobActionToBeDestroyed.get('jobId'), ParseAPI.getCurrentUser(), undefined, [driver.get('user'), coDriver.get('user')], null, null);
    } else {
      unassignJobActionsFromDriver(driver.id, jobActions).then(undefined, error => reject(error));

      // ParseAPI.sendPush([driver.get('user')], `You've been unassigned from Job Id: ${jobActionToBeDestroyed.get('jobId')}`);
      addDriverTask('Unassigned', jobActionToBeDestroyed.get('jobId'), ParseAPI.getCurrentUser(), undefined, [driver.get('user')], null, null);
    }
  }
}

/**
 * @memberof module:Setters
 *
 * @param {*} company
 * @param {*} file
 *
 * @returns
 */
function uploadLogo(company, file) {
  const promise = new Promise((resolve, reject) => {
    const oldLogo = company.get('logo');
    if (!file) {
      company.unset('logo').save().then((updatedCompany) => {
        resolve(updatedCompany);
      }, (error) => {
        console.log(error);
        company.set('logo', oldLogo);
        reject(error);
      });
    } else {
      // Ensure it's an image
      Helpers.scaleImage(file, 800).then((dataURI) => {
        const base64 = dataURI.split('base64,')[1];
        const parseFile = new Parse.File(file.name.replace(/[^\w\s]/gi, ''), { base64 });
        company.set('logo', parseFile).save().then((updatedCompany) => {
          resolve(updatedCompany);
        }, (error) => {
          console.log(error);
          company.set('logo', oldLogo);
          reject(error);
        });
      }, (error) => {
        console.log(error);
        company.set('logo', oldLogo);
        reject(error);
      });
    }
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} dbObject
 * @param {*} attributeName
 * @param {*} imageFile
 *
 * @returns
 */
function uploadImage(dbObject, attributeName, imageFile) {
  /**
   * Set [attributeName] of dbObject to image file
   */
  const promise = new Promise((resolve, reject) => {
    if (!imageFile) {
      dbObject.unset(attributeName).save().then((updatedDBObject) => {
        resolve(updatedDBObject);
      }, (error) => {
        reject(error);
      });
    } else {
      // Ensure it's an image
      Helpers.scaleImage(imageFile, 800).then((dataURI) => {
        const base64 = dataURI.split('base64,')[1];
        const file = new Parse.File(imageFile.name.replace(/[^\w\s\.]/gi, ''), { base64 });
        dbObject.set(attributeName, file).save().then((updatedDBObject) => {
          resolve(updatedDBObject);
        }, (error) => {
          reject(error);
        });
      }, (error) => {
        reject(error);
      });
    }
  });
  return promise;
}

/**
 * @memberof module:Setters
 *
 * @param {*} loadTenderStopObj
 * @param {*} shipmentNumber
 *
 * @returns
 */
const completeDispatchIntegrationLoadTenderStop = async (loadTenderStopObj, shipmentNumber) => {
  loadTenderStopObj.set('statusInt', 1);
  loadTenderStopObj.set('completeDate', moment().toDate());
  await loadTenderStopObj.save();
  return Parse.Cloud.run('sendEdi214', {
    statusInt: 1,
    dispatchIntegrationLoadTenderStopId: loadTenderStopObj.id,
    shipmentNumber,
  });
};

/**
 * @memberof module:Setters
 * @param {*} userId
 * @returns
 */
const restartUserSoftware = async (userId) => {
  return Parse.Cloud.run('restartUserSoftware', { userId });
};

/**
 * @memberof module:Setters
 * @param {*} imeiArr
 * @returns
 */
const forceUpdateMasonTablets = (imeiArr) => {
  return Parse.Cloud.run('updateMasonDevices', { imeis: imeiArr });
};


function activateSwitchboardDevice(imei) {
  return Parse.Cloud.run('activateSwitchboardDevice', {
    imei,
  });
}

const sendEmail = (to, subject, textBody) => {
  return Parse.Cloud.run('sendEmail', {
    subject,
    from: 'team@onswitchboard.com',
    to,
    textBody,
  });
}

function test() {
  console.log('hi');
  const parseObject = new Parse.Query('ELDEvent');
  parseObject.equalTo('objectId', 'UPQFwVZ9sC');
  parseObject.find().then((obj) => {
    console.log(obj);
    const clonedELD = obj[0].clone();
    console.log(clonedELD);
    clonedELD.set('unidentifiedDriver', true).save();
  });
}

export {
  activateSwitchboardDevice,
  addBase64PNGToTempFile,
  addDocument,
  addDriver,
  addDriverTask,
  addDriversAsTeam,
  addGeofence,
  addJob,
  addRecord,
  addTempFile,
  assignDriversToJobLink,
  assignDriversToVehicle,
  assignNewDriversToJobAction,
  createNewAddress,
  createCompanyLink,
  checkAndSetAOBRDELDHardwares,
  authorizeCompanyLink,
  unauthorizeCompanyLink,
  changeDispatcherPassword,
  closeJobLink,
  createJob,
  createNewJob,
  createNewPickupDropoffLoad,
  createNewVendor,
  createNewTrailer,
  createNewVehicle,
  createNewSafetyRecurringReminder,
  createNewUserToCompany,
  createShareVehicleLocation,
  createShareLocation,
  destroyJobLinkCallback,
  deleteNonDriverUser,
  pingGNXLocation,
  pingLocation,
  setDriverStatusActive,
  setDriverStatusAvailable,
  setNotificationRead,
  setEquipmentInspection,
  removeJobDriverFromJobAction,
  resetDispatcherPassword,
  resetDriverPassword,
  resetDriverCustomPassword,
  resetSim,
  resolveTripDefect,
  unassignDriversFromJobLink,
  unassignJobActionsFromDriver,
  updateCompany,
  updateDispatcher,
  updateParseObject,
  updateUser,
  uploadImage,
  uploadLogo,
  completeDispatchIntegrationLoadTenderStop,
  restartUserSoftware,
  forceUpdateMasonTablets,
  sendEmail,
  test,
};
