import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';

import { getQueryParameter } from 'api/URL';
import { setParseObject } from 'api/Parse';
import { getCurrentUser, getUser, getObjectDeepCopy, isUserAdmin } from 'api/Getters';
import { addRecord, updateUser } from 'api/Setters';
import { removeSpecialCharacters, formatName, isSubscribedToModule, getCompanyReadWriteACL } from 'api/Helpers';
import * as ParseHelpers from 'api/Parse';

import { isUserTypeHigherRank } from 'enums/UserTypes';


// Components
import Title from 'components/Shared/Title/Title';
import SBCard from 'components/Shared/SBCard/SBCard';
import RadioSet from 'components/Shared/RadioSet/RadioSet';
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay';

import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import RaisedButton from 'material-ui/RaisedButton';


// CSS
import './style.scss';


class UserPermissionsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      callingUser: getCurrentUser(true),
      user: undefined,
      disabledUserType: [2, 4, 5, 6],
      isLoading: false,
      loadingText: '',
      booleanMap: {
        ENABLED: true,
        DISABLED: false,
        ACTIVATED: true,
        DEACTIVATED: false,
      },
      map: {
        disableMapModule: false,
        disableMapSpeed: false,
      },
      drivers: {
        disableDriversModule: false,
        disableDriversAddDriver: false,
        disableDriversDeleteDriver: false,
        disableDriversEditDriver: false,
        disableEldEditButton: false,
      },
      dispatch: {
        disableDispatchModule: false,
        disableDispatchEditJob: false,
      },
      equipment: {
        disableEquipmentModule: false,
        disableEquipmentAddEquipment: false,
        disableEquipmentDeleteEquipment: false,
        disableEquipmentEditEquipment: false,
      },
      safety: {
        disableSafetyModule: false,
      },
      companyUser: {
        disableCompanyUserFeature: false,
        disableCompanyUserAddUser: false,
        disableCompanyUserDeleteUser: false,
        disableCompanyUserEditUser: false,
      },
      userEmailNotification: {
        disableUserEmailNotificationHosViolations: false,
        disableUserEmailNotificationSpeedViolations: false,
        disableUserEmailNotificationUnreadChatMessages: false,
      },
    };
    this.refreshState = this.refreshState.bind(this);
    this.updateMapPermissions = this.updateMapPermissions.bind(this);
    this.updateDriversPermissions = this.updateDriversPermissions.bind(this);
    this.updateDispatchPermissions = this.updateDispatchPermissions.bind(this);
    this.updateEquipmentPermissions = this.updateEquipmentPermissions.bind(this);
    this.updateCompanyUserPermissions = this.updateCompanyUserPermissions.bind(this);
    this.updateUserEmailNotificationPermissions = this.updateUserEmailNotificationPermissions.bind(this);
    this.updatePermissions = this.updatePermissions.bind(this);
    this.setAdmin = this.setAdmin.bind(this);
  }

  componentDidMount() {
    document.title = 'User Permissions - Switchboard';
    const { callingUser } = this.state;
    /**
     * REDIRECTS:
     * 1. If company users feature is deactivated for calling user
     * 2. If the user is trying to view their own permissions (equivalent case as 3)
     * 3. If the permissions are being viewed by a calling user of lesser (or equal) rank
     * 4. If the user being viewed are not of the type to be viewed (disabledUserType in state)
     * Check 3, 4 are done in refreshState when the user is being retrieved
     */
    const userSpecialPermission = callingUser.userSpecialPermission;
    const disableCompanyUserFeature = userSpecialPermission && userSpecialPermission.disableCompanyUserFeature;
    const disableCompanyUserEditUser = userSpecialPermission && userSpecialPermission.disableCompanyUserEditUser;

    if (disableCompanyUserFeature || disableCompanyUserEditUser) {
      history.push('/404');
    } else {
      this.refreshState();
    }
  }


  async refreshState() {
    const { match } = this.props;
    const { callingUser, disabledUserType } = this.state;
    const { userId } = match.params;

    const loadingState = { ...this.state, isLoading: true, loadingText: 'Loading' };
    const isCallingUserAdmin = callingUser.userType.indexOf(0) !== -1;

    await this.setState(loadingState);

    const user = await getUser(userId);

    // redirect out of page on condition 3 in componentDidMount
    if (isUserTypeHigherRank(user.userType, callingUser.userType, false)) {
      return history.push('/404');
    }

    // redirect out of page on condition 4 in componentDidMount
    for (let i = 0; i < user.userType.length; i++) {
      const type = user.userType[i];
      const isNotViewableUserType = disabledUserType.indexOf(type) !== -1;

      if (isNotViewableUserType) {

        history.push('/404');
        break;
      }
    }

    const newState = { ...this.state, isLoading: false, loadingText: '' };
    newState.user = user;
    const userSpecialPermission = user.userSpecialPermission;

    if (userSpecialPermission) {
      newState.map = {
        disableMapModule: userSpecialPermission.disableMapModule,
        disableMapSpeed: userSpecialPermission.disableMapSpeed,
      };

      newState.drivers = {
        disableDriversModule: userSpecialPermission.disableDriversModule,
        disableDriversAddDriver: userSpecialPermission.disableDriversAddDriver,
        disableDriversDeleteDriver: userSpecialPermission.disableDriversDeleteDriver,
        disableDriversEditDriver: userSpecialPermission.disableDriversEditDriver,
        disableEldEditButton: userSpecialPermission.disableEldEditButton,
      };

      newState.dispatch = {
        disableDispatchModule: userSpecialPermission.disableDispatchModule,
        disableDispatchEditJob: userSpecialPermission.disableDispatchEditJob,
      };

      newState.equipment = {
        disableEquipmentModule: userSpecialPermission.disableEquipmentModule,
        disableEquipmentAddEquipment: userSpecialPermission.disableEquipmentAddEquipment,
        disableEquipmentDeleteEquipment: userSpecialPermission.disableEquipmentDeleteEquipment,
        disableEquipmentEditEquipment: userSpecialPermission.disableEquipmentEditEquipment,
      };

      newState.safety = {
        disableSafetyModule: userSpecialPermission.disableSafetyModule,
      };

      newState.companyUser = {
        disableCompanyUserFeature: userSpecialPermission.disableCompanyUserFeature,
        disableCompanyUserAddUser: userSpecialPermission.disableCompanyUserAddUser,
        disableCompanyUserDeleteUser: userSpecialPermission.disableCompanyUserDeleteUser,
        disableCompanyUserEditUser: userSpecialPermission.disableCompanyUserEditUser,
      };

      newState.userEmailNotification = {
        disableUserEmailNotificationHosViolations: userSpecialPermission.disableUserEmailNotificationHosViolations,
        disableUserEmailNotificationSpeedViolations: userSpecialPermission.disableUserEmailNotificationSpeedViolations,
        disableUserEmailNotificationUnreadChatMessages: userSpecialPermission.disableUserEmailNotificationUnreadChatMessages,
      };

    }

    await this.setState(newState);
    return user;
  }

  updateMapPermissions(attribute, value) {
    this.updatePermissions(attribute, value, 'map');
  }

  updateDriversPermissions(attribute, value) {
    this.updatePermissions(attribute, value, 'drivers');
  }

  updateDispatchPermissions(attribute, value) {
    this.updatePermissions(attribute, value, 'dispatch');
  }

  updateEquipmentPermissions(attribute, value) {
    this.updatePermissions(attribute, value, 'equipment');
  }

  updateSafetyPermissions(attribute, value) {
    this.updatePermissions(attribute, value, 'safety');
  }

  updateCompanyUserPermissions(attribute, value) {
    this.updatePermissions(attribute, value, 'companyUser');
  }

  updateUserEmailNotificationPermissions(attribute, value) {
    this.updatePermissions(attribute, value, 'userEmailNotification');
  }

  /**
   *
   * @param {*} attribute
   * @param {*} value
   * @param {*} permissionsCategory in state: drivers, dispatch, equipment, etc
   */
  async updatePermissions(attribute, value, permissionsCategory) {
    // Get the latest permissions (in case there was an async update / by another user) and update
    // Check if there was really any change
    // start by calling refreshState (this way the state is also the way it was last left off, if any update errors happen)
    await this.refreshState();
    const { user } = this.state;
    if (this.state[permissionsCategory][attribute] === value) return user;
    const waitingState = { ...this.state, isLoading: true, loadingMessage: 'Updating permissions' };
    await this.setState(waitingState);

    if (!user.userSpecialPermission) {
      const userSpecialPermission = await addRecord('UserSpecialPermission', { [attribute]: value, user: user.dbObject }, getCompanyReadWriteACL());
      await updateUser(user.dbObject.id, 'userSpecialPermission', userSpecialPermission.id, 'UserSpecialPermission');
    } else {
      await setParseObject(user.userSpecialPermission.dbObject, { [attribute]: value }, true);
    }

    if (permissionsCategory === 'userEmailNotification') {
      if (!user.userNotificationSetting) {
        const userNotificationSetting = await addRecord('UserNotificationSetting', { user: user.dbObject }, getCompanyReadWriteACL());
        await updateUser(user.dbObject.id, 'userNotificationSetting', userNotificationSetting.id, 'UserNotificationSetting');
      } else {
        // whichever notification permission is disabled, reset the corresponding notification setting attribute
        const permissionsNotificationEnum = {
          disableUserEmailNotificationHosViolations: 'enableHOSViolation',
          disableUserEmailNotificationSpeedViolations: 'enableSpeedViolation',
          disableUserEmailNotificationUnreadChatMessages: 'enableChatMessage',
        };
        await setParseObject(user.userNotificationSetting.dbObject, { [permissionsNotificationEnum[attribute]]: false }, true);
      }
    }

    return this.refreshState();
  }

  setAdmin(bool) {
    const { user } = this.state;

    if (bool) {
      return ParseHelpers.callCloudFunction('addUserToAdminRole', {
        userId: user.dbObject.id,
      });
    } else {
      return ParseHelpers.callCloudFunction('removeUserFromAdminRole', {
        userId: user.dbObject.id,
      });
    }
  }

  render() {
    const { location, match } = this.props;
    const {
      isLoading, loadingText, user, booleanMap, map, drivers, dispatch, safety, equipment, companyUser, userEmailNotification,
    } = this.state;

    const title = user ? <React.Fragment><div>Permissions</div><div className="notranslate"><small>{user.firstName} {user.lastName}</small></div></React.Fragment> : 'Loading...';

    /************************
     * MAP PERMISSIONS
     ***********************/
    const mapPermissions = (
      <React.Fragment>
        <MDBRow className="mt-4">
          <MDBCol md="3">
            <div className="subfeature-title">Show Speed</div>
            <RadioSet
              className={`subfeature-toggle ${map.disableMapSpeed ? 'f-disabled' : 'f-enabled'}`}
              selected={map.disableMapSpeed ? 'Disabled' : 'Enabled'}
              labels={['Enabled', 'Disabled']}
              onChangeCallBack={(value) => this.updateMapPermissions('disableMapSpeed', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>
        </MDBRow>
      </React.Fragment>
    );

    /************************
     * DRIVERS PERMISSIONS
     ***********************/
    const driversPermissions = (
      <React.Fragment>
        <MDBRow className="mt-4">
          <MDBCol md="3">
            <div className="subfeature-title">Add Drivers</div>
            <RadioSet className={`subfeature-toggle ${drivers.disableDriversAddDriver ? 'f-disabled' : 'f-enabled'}`} selected={drivers.disableDriversAddDriver ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateDriversPermissions('disableDriversAddDriver', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>

          <MDBCol md="3">
            <div className="subfeature-title">Delete Drivers</div>
            <RadioSet className={`subfeature-toggle ${drivers.disableDriversDeleteDriver ? 'f-disabled' : 'f-enabled'}`} selected={drivers.disableDriversDeleteDriver ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateDriversPermissions('disableDriversDeleteDriver', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>

          <MDBCol md="3">
            <div className="subfeature-title">Edit Drivers</div>
            <RadioSet className={`subfeature-toggle ${drivers.disableDriversEditDriver ? 'f-disabled' : 'f-enabled'}`} selected={drivers.disableDriversEditDriver ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateDriversPermissions('disableDriversEditDriver', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>

          <MDBCol md="3">
            <div className="subfeature-title">Edit HOS Logs</div>
            <RadioSet className={`subfeature-toggle ${drivers.disableEldEditButton ? 'f-disabled' : 'f-enabled'}`} selected={drivers.disableEldEditButton ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateDriversPermissions('disableEldEditButton', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>
        </MDBRow>
      </React.Fragment>
    );


    /************************
     * DISPATCH PERMISSIONS
     ***********************/
    const dispatchPermissions = (
      <React.Fragment>
        {/*<MDBRow className="mt-4">
          <MDBCol md="3">
            <div className="subfeature-title">Edit Jobs</div>
            <RadioSet className={`subfeature-toggle ${dispatch.disableDispatchEditJob ? 'f-disabled' : 'f-enabled'}`} selected={dispatch.disableDispatchEditJob ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateDispatchPermissions('disableDispatchEditJob', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>
        </MDBRow>*/}
      </React.Fragment>
    );


    /************************
     * EQUIPMENT PERMISSIONS
     ***********************/
    const equipmentPermissions = (
      <React.Fragment>
        <MDBRow className="mt-4">
          <MDBCol md="3">
            <div className="subfeature-title">Add Equipment</div>
            <RadioSet className={`subfeature-toggle ${equipment.disableEquipmentAddEquipment ? 'f-disabled' : 'f-enabled'}`} selected={equipment.disableEquipmentAddEquipment ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateEquipmentPermissions('disableEquipmentAddEquipment', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>
          <MDBCol md="3">
            <div className="subfeature-title">Delete Equipment</div>
            <RadioSet className={`subfeature-toggle ${equipment.disableEquipmentDeleteEquipment ? 'f-disabled' : 'f-enabled'}`} selected={equipment.disableEquipmentDeleteEquipment ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateEquipmentPermissions('disableEquipmentDeleteEquipment', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>
          <MDBCol md="3">
            <div className="subfeature-title">Edit Equipment</div>
            <RadioSet className={`subfeature-toggle ${equipment.disableEquipmentEditEquipment ? 'f-disabled' : 'f-enabled'}`} selected={equipment.disableEquipmentEditEquipment ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateEquipmentPermissions('disableEquipmentEditEquipment', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>
        </MDBRow>
      </React.Fragment>
    );


    /************************
     * COMPANY USER PERMISSIONS
     ***********************/
    const companyUserPermissions = (
      <React.Fragment>
        <MDBRow className="mt-4">
          <MDBCol md="3">
            <div className="subfeature-title">Add Account</div>
            <RadioSet className={`subfeature-toggle ${companyUser.disableCompanyUserAddUser ? 'f-disabled' : 'f-enabled'}`} selected={companyUser.disableCompanyUserAddUser ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateCompanyUserPermissions('disableCompanyUserAddUser', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>
          <MDBCol md="3">
            <div className="subfeature-title">Delete Account</div>
            <RadioSet className={`subfeature-toggle ${companyUser.disableCompanyUserDeleteUser ? 'f-disabled' : 'f-enabled'}`} selected={companyUser.disableCompanyUserDeleteUser ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateCompanyUserPermissions('disableCompanyUserDeleteUser', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>
          <MDBCol md="3">
            <div className="subfeature-title">Edit Account</div>
            <RadioSet className={`subfeature-toggle ${companyUser.disableCompanyUserEditUser ? 'f-disabled' : 'f-enabled'}`} selected={companyUser.disableCompanyUserEditUser ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateCompanyUserPermissions('disableCompanyUserEditUser', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>
        </MDBRow>
      </React.Fragment>
    );


    /************************
     * COMPANY EMAIL PERMISSIONS
     ***********************/
    const userEmailNotificationPermissions = (
      <React.Fragment>
        <MDBRow className="mt-2">
          <MDBCol md="3">
            <div className="subfeature-title">Enable HOS Violations</div>
            <RadioSet className={`subfeature-toggle ${userEmailNotification.disableUserEmailNotificationHosViolations ? 'f-disabled' : 'f-enabled'}`} selected={userEmailNotification.disableUserEmailNotificationHosViolations ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateUserEmailNotificationPermissions('disableUserEmailNotificationHosViolations', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>
          <MDBCol md="3">
            <div className="subfeature-title">Enable Speed Violations</div>
            <RadioSet className={`subfeature-toggle ${userEmailNotification.disableUserEmailNotificationSpeedViolations ? 'f-disabled' : 'f-enabled'}`} selected={userEmailNotification.disableUserEmailNotificationSpeedViolations ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateUserEmailNotificationPermissions('disableUserEmailNotificationSpeedViolations', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>
          <MDBCol md="3">
            <div className="subfeature-title">Enable Unread Chat</div>
            <RadioSet className={`subfeature-toggle ${userEmailNotification.disableUserEmailNotificationUnreadChatMessages ? 'f-disabled' : 'f-enabled'}`} selected={userEmailNotification.disableUserEmailNotificationUnreadChatMessages ? 'Disabled' : 'Enabled'} labels={['Enabled', 'Disabled']} onChangeCallBack={(value) => this.updateUserEmailNotificationPermissions('disableUserEmailNotificationUnreadChatMessages', !booleanMap[value.toUpperCase()])} roundedPill />
          </MDBCol>
        </MDBRow>
      </React.Fragment>
    );

    return (
      <MDBContainer className="user-permissions-layout px-5 translate-me" fluid>
        <Title title={title} />

        {/* {user && user.dbObject && user.dbObject.get('userType').indexOf(0) === -1 &&
          <RaisedButton label="Give Admin Permissions" primary onClick={() => this.setAdmin(true)} />
        }
        {user && user.dbObject && user.dbObject.get('userType').indexOf(0) !== -1 &&
          <RaisedButton label="Save" secondary onClick={() => this.setAdmin(true)} />
        } */}

        {isSubscribedToModule('mapModule') &&
          <MDBRow><MDBCol>

            <SBCard title="Map Module" className="mb-5" isCollapsible={false}>
              <MDBRow><MDBCol md="3">
                <RadioSet
                  className={`feature-toggle ${map.disableMapModule ? 'f-disabled' : 'f-enabled'}`}
                  selected={map.disableMapModule ? 'Deactivated' : 'Activated'}
                  labels={['Activated', 'Deactivated']}
                  onChangeCallBack={(value) => this.updateMapPermissions('disableMapModule', !booleanMap[value.toUpperCase()])}
                />
              </MDBCol></MDBRow>

              {!map.disableMapModule && mapPermissions}
            </SBCard>
          </MDBCol></MDBRow>
        }

        {isSubscribedToModule('eldModule') &&
          <MDBRow><MDBCol>
            <SBCard title="Drivers Module" className="mb-5" isCollapsible={false}>
              <MDBRow><MDBCol md="3">
                <RadioSet className={`feature-toggle ${drivers.disableDriversModule ? 'f-disabled' : 'f-enabled'}`} selected={drivers.disableDriversModule ? 'Deactivated' : 'Activated'} labels={['Activated', 'Deactivated']} onChangeCallBack={(value) => this.updateDriversPermissions('disableDriversModule', !booleanMap[value.toUpperCase()])} />
              </MDBCol></MDBRow>

              {!drivers.disableDriversModule && driversPermissions}
            </SBCard>
          </MDBCol></MDBRow>
        }

        {isSubscribedToModule('dispatchModule') &&
          <MDBRow><MDBCol>
            <SBCard title="Dispatch Module" className="mb-5" isCollapsible={false}>
              <MDBRow><MDBCol md="3">
                <RadioSet className={`feature-toggle ${dispatch.disableDispatchModule ? 'f-disabled' : 'f-enabled'}`} selected={dispatch.disableDispatchModule ? 'Deactivated' : 'Activated'} labels={['Activated', 'Deactivated']} onChangeCallBack={(value) => this.updateDispatchPermissions('disableDispatchModule', !booleanMap[value.toUpperCase()])} />
              </MDBCol></MDBRow>

              {!dispatch.disableDispatchModule && dispatchPermissions}
            </SBCard>
          </MDBCol></MDBRow>
        }

        <MDBRow><MDBCol>
          <SBCard title="Equipment Module" className="mb-5" isCollapsible={false}>
            <MDBRow><MDBCol md="3">
              <RadioSet className={`feature-toggle ${equipment.disableEquipmentModule ? 'f-disabled' : 'f-enabled'}`} selected={equipment.disableEquipmentModule ? 'Deactivated' : 'Activated'} labels={['Activated', 'Deactivated']} onChangeCallBack={(value) => this.updateEquipmentPermissions('disableEquipmentModule', !booleanMap[value.toUpperCase()])} />
            </MDBCol></MDBRow>

            {!equipment.disableEquipmentModule && equipmentPermissions}
          </SBCard>
        </MDBCol></MDBRow>

        {isSubscribedToModule('eldModule') &&
          <MDBRow><MDBCol>
            <SBCard title="Safety Module" className="mb-5" isCollapsible={false}>
              <MDBRow><MDBCol md="3">
                <RadioSet className={`feature-toggle ${safety.disableSafetyModule ? 'f-disabled' : 'f-enabled'}`} selected={safety.disableSafetyModule ? 'Deactivated' : 'Activated'} labels={['Activated', 'Deactivated']} onChangeCallBack={(value) => this.updateSafetyPermissions('disableSafetyModule', !booleanMap[value.toUpperCase()])} />
              </MDBCol></MDBRow>
            </SBCard>
          </MDBCol></MDBRow>
        }

        <MDBRow><MDBCol>
          <SBCard title="Company User List" className="mb-5" isCollapsible={false}>
            <MDBRow><MDBCol md="3">
              <RadioSet className={`feature-toggle ${companyUser.disableCompanyUserFeature ? 'f-disabled' : 'f-enabled'}`} selected={companyUser.disableCompanyUserFeature ? 'Deactivated' : 'Activated'} labels={['Activated', 'Deactivated']} onChangeCallBack={(value) => this.updateCompanyUserPermissions('disableCompanyUserFeature', !booleanMap[value.toUpperCase()])} />
            </MDBCol></MDBRow>

            {!companyUser.disableCompanyUserFeature && companyUserPermissions}
          </SBCard>
        </MDBCol></MDBRow>

        <MDBRow><MDBCol>
          <SBCard title="User Email Notifications" className="mb-5" isCollapsible={false}>
            {userEmailNotificationPermissions}
          </SBCard>
        </MDBCol></MDBRow>

        {isLoading &&
          <LoadingOverlay text={loadingText} />
        }
      </MDBContainer>
    );
  }
}

UserPermissionsLayout.propTypes = {
};

export default UserPermissionsLayout;
