import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

// Components
import Title from 'components/LayoutTitle/view/Title';
import FilterForm from 'components/FilterForm/container/FilterForm';
import SortButton from 'components/SortButton/view/SortButton';

import CommonIssuesRow from './CommonIssuesRow';

// CSS
import styles from './IssuesTable.module.scss';

class CommonIssuesTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: [],
      sort: { activePoints: 'descending' },
    };
    this.combineCommonIssues = this.combineCommonIssues.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.setSort = this.setSort.bind(this);
    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.state.filterFormFields = [
      { attrName: 'search_description', fullName: 'Type', placeholder: '.....', type: 'text' },
    ];
  }

  componentDidMount() {
    this.refreshState(this.props.carrierProfileReport, this.state.filter, this.state.sort);
  }

  setSort(attribute) {
    const newState = { ...this.state };
    const currentSort = this.state.sort[attribute]; // the current sort, if defined

    if (currentSort) {
      if (currentSort === 'ascending') {
        newState.sort[attribute] = 'descending';
      } else {
        newState.sort[attribute] = 'ascending';
      }
    }
    else if (attribute === 'occurences') {
      newState.sort = { [attribute]: 'ascending' };
    }
    else if (attribute === 'activePoints') {
      newState.sort = { [attribute]: 'ascending' };
    }
    this.setState(newState, () => {
      this.refreshState(this.props.carrierProfileReport, this.state.filter, this.state.sort);
    });
  }

  combineCommonIssues(contraventions) {
    // function to group common issues together
    const combinedIssues = [];
    // keep track of which plate/document# have already been seen (so we know which to combine)
    // we must keep track of document# as well as there can be multiple vehicles per inspection
    const seenIssues = {};
    let dummyId = 0;

    for (let i = 0; i < contraventions.length; i++) {
      const contravention = contraventions[i];
      const description = contravention.get('description');

      if (!description || (contravention.get('description').replace(/\s+/g, '').length === 0)) {
        combinedIssues.push({
          occurences: 1,
          activePoints: contravention.get('activePoints'),
          search_description: description.toLowerCase(),
          description,
          id: `commonIssueRow${dummyId}`,
        });
        dummyId++;
      }
      else if (description && !seenIssues[description]) {
        // add it for first time
        seenIssues[description] = {
          occurences: 1,
          activePoints: contravention.get('activePoints'),
          search_description: description.toLowerCase(),
          description,
          id: `commonIssueRow${dummyId}`,
        };
        dummyId++;
      }
      else if (seenIssues[description]) {
        seenIssues[description].occurences++;

        if (!contravention.get('activePoints') || isNaN(parseFloat(contravention.get('activePoints')))) {
          // what we want to add is not a number
          continue;
        }
        else if (isNaN(parseFloat(seenIssues[description].activePoints)) && isNaN(parseFloat(contravention.get('activePoints')))) {
          // both are not numbers
          continue;
        }
        else if (isNaN(parseFloat(seenIssues[description].activePoints)) && !isNaN(parseFloat(contravention.get('activePoints')))) {
          // if what we want to add is a number but the activepoints of the seen driver is not
          seenIssues[description].activePoints = parseFloat(contravention.get('activePoints'));
        }
        else {
          // both exist and are numbers
          seenIssues[description].activePoints = parseFloat(seenIssues[description].activePoints) + parseFloat(contravention.get('activePoints'));
        }
      }
    }

    // now put them all in an array
    const keys = Object.keys(seenIssues);
    for (let j = 0; j < keys.length; j++) {
      combinedIssues.push(seenIssues[keys[j]]);
    }
    return combinedIssues;
  }

  refreshState(carrierProfileReport, filterObject, sortBy) {
    // re-set state with issue prop
    const newState = { ...this.state };
    if (carrierProfileReport && carrierProfileReport.carrierProfileObject) {
      // combine everything into one
      let fullReportArr = [];
      const reportKeys = Object.keys(carrierProfileReport);
      for (let i = 0; i < reportKeys.length; i++) {
        if (reportKeys[i].toLowerCase() === 'drivercontraventions' || reportKeys[i].toLowerCase() === 'carriercontraventions') {
          fullReportArr = fullReportArr.concat(carrierProfileReport[reportKeys[i]]);
        }
      }
      let combinedCommonIssues = this.combineCommonIssues(fullReportArr);

      // filter applied
      if (filterObject) {
        const keys = Object.keys(filterObject);
        combinedCommonIssues = combinedCommonIssues.filter(issue => {
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const searchValue = issue[key];
            if (!(searchValue && searchValue.indexOf(filterObject[key].toLowerCase()) > -1)) {
              return false;
            }
          }
          return true;
        });
      }

      if (sortBy) {
        if (sortBy.activePoints) {
          combinedCommonIssues.sort((issueA, issueB) => {
            let issueAPoints = issueA.activePoints;
            let issueBPoints = issueB.activePoints;
            if (!issueAPoints) {
              issueAPoints = -9999999;
            }
            if (!issueBPoints) {
              issueBPoints = -9999999;
            }

            if (sortBy.activePoints === 'ascending') {
              return parseFloat(issueAPoints) - parseFloat(issueBPoints);
            }
            return parseFloat(issueBPoints) - parseFloat(issueAPoints);
          });
        }
        else if (sortBy.occurences) {
          combinedCommonIssues.sort((issueA, issueB) => {
            let issueAPoints = issueA.occurences;
            let issueBPoints = issueB.occurences;
            if (!issueAPoints) {
              issueAPoints = -9999999;
            }
            if (!issueBPoints) {
              issueBPoints = -9999999;
            }

            if (sortBy.occurences === 'ascending') {
              return parseFloat(issueAPoints) - parseFloat(issueBPoints);
            }
            return parseFloat(issueBPoints) - parseFloat(issueAPoints);
          });
        }
      }

      if (combinedCommonIssues.length > 0) {
        newState.commonIssuesRows = combinedCommonIssues.map(issue => {
          return <CommonIssuesRow issue={issue} key={issue.id} rowKey={issue.id} />;
        });
      } else {
        newState.commonIssuesRows = <tr><td className="centerText" colSpan="3">No issues available</td></tr>;
      }
    } else {
      newState.commonIssuesRows = <tr><td className="centerText" colSpan="3">No issues available</td></tr>;
    }
    return this.setState(newState);
  }

  filter(filterObject) {
    this.setState({ ...this.state, filter: filterObject }, () => {
      this.refreshState(this.props.carrierProfileReport, this.state.filter, this.state.sort);
    });
  }

  clearFilter() {
    this.setState({ ...this.state, filter: [] }, () => {
      this.refreshState(this.props.carrierProfileReport, undefined, this.state.sort);
    });
  }

  render() {
    return (
      <div className={styles.issuesTable}>
        <Title className="h3" title="Common Contraventions" />

        <table className={styles.titleSub}>
          <tbody>
            <tr>
              <td>
                <p>
                  Here you can view all driver/carrier contraventions and their number of occurences under your NSC number
                </p>
              </td>
              <td>
                <FilterForm
                  className="floatRight"
                  handleFilter={this.filter}
                  clearFilter={this.clearFilter}
                  fields={this.state.filterFormFields}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="clearBoth" />

        <div className={`subTable ${styles.issuesSubTable}`}>
          <Table responsive hover>
            <thead>
              <tr>
                <th>
                  Type
                </th>
                <th>
                  Occurences
                  <SortButton
                    clickHandler={() => this.setSort('occurences')}
                    isActive={false}
                  />
                </th>
                <th>
                  Points Accumulated
                  <SortButton
                    clickHandler={() => this.setSort('activePoints')}
                    isActive={false}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              { this.state.commonIssuesRows }
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

CommonIssuesTable.propTypes = {
  carrierProfileReport: PropTypes.object,
};

export default CommonIssuesTable;
