import React, { useCallback } from 'react';

// Components
import SearchBar from 'components/Map/SearchBar/SearchBar';

// sbCore Components
import Button from 'sbCore/Button/Button';

// Styles
import './style.scss';

/**
* @description Component that hosts the non-native mapbox controls. This is rendered on top of the map.
* @param {Function} onMapStyleUpdate - Callback function that passes back the selected map style
* @param {Function} onIsFitToVehiclesToggle - Callback function that toggles the view to fit all vehicles
* @param {Number} [longitude] - The current longitude on the map
* @param {Number} [latitude] - The current latitude on the map
* @param {Number} [zoom] - The current zoom on the map
* @returns
*
* @example
* <Controls
*   longitude={longitude}
*   latitude={latitude}
*   zoom={zoom}
*   onMapStyleUpdate={onMapStyleUpdate}
*   onIsFitToVehiclesToggle={onIsFitToVehiclesToggle}
* />
*/
function Controls({ ...props }) {

  // ** Callback Functions ** //
  function onMapStyleUpdate(mapStyle) {
    if (!props.onMapStyleUpdate) return;
    props.onMapStyleUpdate(mapStyle);
  }

  function onIsFitToVehiclesToggle() {
    if (!props.onIsFitToVehiclesToggle) return;
    props.onIsFitToVehiclesToggle(true);
  }

  // ** useCallbacks ** //

  const handlePlaceSelected = useCallback((place) => {
    if (!props.handlePlaceSelected) return;

    const searchBarLocationObj = {
      longitude: place.geometry.location.lng(),
      latitude: place.geometry.location.lat(),
    };

    props.handlePlaceSelected(searchBarLocationObj);
  }, []);

  // ** UI ** //
  const navigationInfo = (
    <div className="sidebar text-xs">
      Longitude: {props.longitude ? props.longitude.toFixed(4) : '-'} | Latitude: {props.latitude ? props.latitude.toFixed(4) : '-'} | Zoom: {props.zoom ? props.zoom.toFixed(2) : '-'}
    </div>
  );

  const fitToVehiclesButton = (
    <span className="fit-to-vehicles">
      <Button className="button p-button-sm" label="Fit To Vehicles" onClick={() => onIsFitToVehiclesToggle()} />
    </span>
  );

  const mapStylesButtons = (
    <span className="map-styles p-buttonset">
      <Button className="button p-button-sm" label="Street Day" onClick={() => onMapStyleUpdate('mapbox://styles/mapbox/navigation-day-v1')} />
      <Button className="button p-button-sm" label="Street Night" onClick={() => onMapStyleUpdate('mapbox://styles/mapbox/navigation-night-v1')} />
      <Button className="button p-button-sm" label="Satellite" onClick={() => onMapStyleUpdate('mapbox://styles/mapbox/satellite-streets-v11')} />
    </span>
  );

  const searchBar = (
    <div className="search-bar">
      <SearchBar handlePlaceSelected={handlePlaceSelected} />
    </div>
  );

  return (
    <div className="controls">
      <div className="flex justify-content-end">
        {navigationInfo}
      </div>
      <div className="flex justify-content-end">
        <div className="grid top-left-controls">
          <div className="col-12">
            {window?.location?.host?.indexOf('app-support') === -1 &&
              { searchBar }
            }
          </div>
          <div className="col-3">
            {fitToVehiclesButton}
          </div>
          <div className="col-9">
            {mapStylesButtons}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Controls;
