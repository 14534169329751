import React, { useState, useEffect } from 'react';

// components
import Message from 'sbCore/Message/Message';
import InputText from 'sbCore/InputText/InputText';

// styles
import './style.scss';

/**
 * @description
 * @param {Function} onChangeEditReason - Providing the required edit reason back to the parent
 * @param {String} [editReason] - The edit reason provided by the parent, in the case this component unmounts (view is switched)
 * @param {Boolean} [isELDOverride] - If this edit is meant to override logs
 */
function ELDEditConfirmationMessage({ ...props }) {
  const [editReason, setEditReason] = useState('');

  useEffect(() => {
    if (props.editReason) {
      let _editReason = props.editReason;
      if (_editReason.trim().length === 0) _editReason = '';
      const editReasonInput = document.getElementById('editReasonInput');
      if (editReasonInput) editReasonInput.value = _editReason;
      setEditReason(_editReason);
    }
  }, []);

  // functions
  function onChangeEditReason(editReason) {
    // the cleaning and assigning of the input is done in the parent upon submission
    let _editReason = '';
    if (editReason) _editReason = editReason;
    if (_editReason.trim().length === 0) _editReason = ''; // if they only enter spaces
    setEditReason(_editReason);
    props.onChangeEditReason(_editReason);
  }

  // JSX
  // the normal case of user requesting an edit
  let content = (
    <div style={{ fontSize: '.9em' }}>
      Please note that after submitting your changes, the driver must receive and accept (or decline) them before you can make further edits to this log
    </div>
  );

  if (props.isELDOverride) {
    content = (
      <div>
        <div className="mb-3">
          You are attempting to force override driver logs
        </div>
        <div className="mb-3 font-bold">
          By confirming these edits you acknowledge that Switchboard and the Company of this driver are aware of the laws and potential liabilities surrounding such an edit
        </div>
        <div>
          If a normal edit does the job, we recommend using that instead of a force override
        </div>
      </div>
    );
  }

  const confirmationMessage = (
    <div>
      <Message
        className="w-100 text-left"
        severity={!props.isELDOverride ? 'info' : 'error'}
        content={content}
      />
    </div>
  );

  const isMissingEditReason = !editReason.length;
  const inputTextClassName = 'p-inputtext-sm';

  return (
    <div className="eldedit-confirmation-message">
      { confirmationMessage }
      <div className="mt-5 flex flex-column gap-2" style={{ marginTop: '3.5em'}}>
        <label
          htmlFor="editReasonInput"
          className={`${isMissingEditReason ? 'text-red-maple' : ''}`}
          style={{ marginBottom: '-.35em', fontSize: '.85em' }}
        >
          <div className="uppercase inline-block"><b>FMCSA Requirement</b></div>
          &nbsp;-&nbsp;Please enter the reason(s) for making these edits
        </label>
        <InputText
          id="editReasonInput"
          className={inputTextClassName}
          type="text"
          placeholder="Enter reason(s) here..."
          variant="small"
          error={isMissingEditReason}
          onChange={(e) => onChangeEditReason(e.target.value)}
        />
      </div>
    </div>
  );
}

export default ELDEditConfirmationMessage;

// {isOverrideELDEdit && (
//   <div style={{ color: '#E03857', fontWeight: '600', textTransform: 'uppercase' }}>
//     !! Warning !!:
//     <br /><br />
//     You are attempting to force override driver logs. By confirming, you acknowledge that Switchboard
//     and the company of the driver are aware of the liabilities and laws surrounding such an edit
//     <br /><br />
//     This tool should only be used for the purposes of creating driver logs during inactive days, past 14 days ago. To edit for a day
//     with driver activity (or once the manual logs are created), the normal Edit function should be used
//   </div>
// )}
// {isOverrideELDEvents && (
//   <div style={{ color: '#E03857', fontWeight: '600', textTransform: 'uppercase' }}>
//     !! Warning !!:
//     <br /><br />
//     You are attempting to force override driver logs. By confirming, you acknowledge that Switchboard
//     and the company of the driver are aware of the liabilities and laws surrounding such an edit
//     <br /><br />
//     This tool should only be used for the purposes of overriding HOS graph issues. Otherwise, the normal edit function should be used
//   </div>
// )}
// {!isOverrideELDEdit && !isOverrideELDEvents && (
//   <React.Fragment>
//     <div style={{ color: '#E03857', fontWeight: '600' }}>
//       Warning:
//       <br /><br />
//       When confirming: Until the respective driver receives/accepts your changes...
//       <br /><br />
//       <div>- You will not be able to request further edits</div>
//     </div>

//     <br /><br />
//     Request Edit(s)?
//   </React.Fragment>
// )}
// </span>
