import React from 'react';

// sbCore Components
import InputLabel from 'sbCore/InputLabel/InputLabel';

// Shared Document Components
import DocumentInputTextarea from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputTextarea/InputTextarea';

// Styling
import './style.scss';
/**
 * @description Display payment information for invoice document generator
 * @param {Function} updateDocumentStateObj - Callback function that's passed back an attribute and value to update its parent's state
 * @param {String} paymentTo - The current payment to value
 * @returns
 */
const InvoicePaymentInformation = ({ ...props }) => {

  // ** Functions ** //
  // Updates the parent documentStateObj given an attribute and value
  function updateDocumentStateObj(attribute, value) {
    if (props.updateDocumentStateObj) props.updateDocumentStateObj(attribute, value);
  }

  return (
    <div className="grid invoice-payment-information mx-7 mb-0">
      <div className="col-4 input-field">
        <InputLabel className="payment-information">
          Payment Information
        </InputLabel>
        <DocumentInputTextarea
          inputClassName="text-sm"
          rows={5}
          cols={30}
          value={props.paymentTo}
          onChange={(value) => updateDocumentStateObj('paymentTo', value)}
          success={props.paymentTo}
          warning={!props.paymentTo || props.paymentTo == ''}
          isDownloaded={props.isDownloaded}
        />
      </div>
    </div>
  );
};

export default InvoicePaymentInformation;
