import store from 'store';
import * as Getters from 'api/Getters';
import * as ActionConstants from './ActionConstants';

export function fetchingReceipts(driverObjectId) {
  return {
    type: ActionConstants.FETCH_RECEIPTS_INPROGRESS,
    driverObjectId,
  };
}

export function fetchReceiptsSuccess(receipts) {
  return {
    type: ActionConstants.FETCH_RECEIPTS_SUCCESS,
    receipts,
  };
}

export function fetchReceiptsError(error) {
  return {
    type: ActionConstants.FETCH_RECEIPTS_ERROR,
    error,
  };
}

export function deleteReceipts() {
  return {
    type: ActionConstants.DELETE_RECEIPTS,
  };
}

export function fetchDriverReceiptsForState(driverObjectId, page, limit, filter, sortBy) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingReceipts(driverObjectId));
    Getters.getDriverReceipts(driverObjectId, page, limit, filter, sortBy).then(
      receiptObjects => {
        resolve(receiptObjects);
        store.dispatch(fetchReceiptsSuccess(receiptObjects));
      },
      error => {
        reject(error);
        store.dispatch(fetchReceiptsError(error));
      }
    );
  });
  return promise;
}

export function deleteDriverReceiptsForState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteReceipts());
    resolve(store.getState().Receipt);
  });
  return promise;
}

