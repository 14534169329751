import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import AddEditButton from 'components/AddEditModal/container/AddEditButton';

// Actions
import { updateTrailerForState } from 'actions/Trailer';
import { trailerTypes } from 'api/Lists/EquipmentTypes';

// API
import { StateProvinces } from 'api/Lists/StateProvinces';
import { queryCompanyObjects } from 'api/Getters';
import { createTempRecord, createTempPointer, getCurrentUser, getAttribute } from 'api/Parse';

// Context
import StoreContext from 'contexts/StoreContext';

function hasValueChanged(oldValue, newValue) {
  if (oldValue !== newValue && !(!oldValue && !newValue)) {
    return true;
  }
  return false;
}

function getInitialState(props) {
  const initialState = {
    editingInProgress: false,
  };
  const trailer = props.trailer;
  const licensePlate = trailer && trailer.get('licensePlate');
  const trailerPlate = (licensePlate && licensePlate.get('plate')) || (trailer.get('plate'));
  const plateJurisdiction = licensePlate && licensePlate.get('stateProvince');

  const stateProvinces = StateProvinces.map(stateProvince => stateProvince.name).concat('Other');

  initialState.editTrailerFormFields = [
    [
      { label: 'Unit ID', value: trailer.get('unitId') || '', type: 'text', required: true, property: 'unitId', limit: '10' },
    ],
    [
      { label: 'MVI Decal #', value: trailer.get('MVIDecalNumber') || '', type: 'text', property: 'MVIDecalNumber' },
      { label: 'Next Inspection Date', value: trailer.get('nextInspectionDate') ? trailer.get('nextInspectionDate') : null, type: 'date', property: 'nextInspectionDate' },
      { label: 'Inspection Frequency (months)', value: trailer.get('inspectionFrequency') || '', type: 'number', property: 'inspectionFrequency' }
    ],
    [
      { label: 'VIN #', value: trailer.get('vin') || '', type: 'text', property: 'vin' },
      { label: 'License Plate', required: true, value: trailerPlate || '', type: 'text', property: 'plate' },
      { label: 'Type of Trailer', value: trailer.get('type') || '', type: 'select', property: 'type', selectArr: trailerTypes },
      { label: 'Plate Jurisdiction', required: true, value: plateJurisdiction && (StateProvinces.filter(stateProvince => plateJurisdiction === stateProvince.code).length > 0 ? StateProvinces.filter(stateProvince => plateJurisdiction === stateProvince.code)[0].name : plateJurisdiction) || '', type: 'select', property: 'plateJurisdiction', selectArr: stateProvinces },
    ],
    [
      { label: 'Tracker Identifier', value: trailer.get('tc_devices_uniqueid') || '', type: 'text', property: 'tc_devices_uniqueid' },
      { label: 'Temperature Upper Bound', value: trailer.get('tempUpperBoundCelsius'), type: 'number', property: 'tempUpperBoundCelsius' },
      { label: 'Temperature Lower Bound', value: trailer.get('tempLowerBoundCelsius'), type: 'number', property: 'tempLowerBoundCelsius' },
    ],
    [{ label: 'Notes', value: trailer.get('notes') || '', type: 'textarea', property: 'notes' }],
  ];
  return initialState;
}

class EditTrailerButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState(props);
    this.checkOverwriteVehicle = this.checkOverwriteVehicle.bind(this);
    this.editTrailer = this.editTrailer.bind(this);
    // this.editTrailerNew = this.editTrailerNew.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.refreshState(nextProps);
  }

  checkOverwriteVehicle(vehicleParseObj) {
    // hack for driverArr --> one driver
    if (vehicleParseObj.get('trailer')) {
      const overwriteMessage = <div><div>Vehicle {vehicleParseObj.get('unitId')} is currently attached to trailer <b>{vehicleParseObj.get('trailer').get('unitId')}</b>.</div><div> Are you sure you want to detach {vehicleParseObj.get('trailer').get('unitId')} to from {vehicleParseObj.get('unitId')}?</div></div>;
      this.setState({ ...this.state, overwriteMessage });
      return true;
    }
    return false;
  }

  // Old editTrailer implementation
  // async editTrailer(formValues) {
  //   const { trailer } = this.props;
  //   const keys = Object.keys(formValues);
  //   const keysLen = keys.length;
  //   await this.setState({ ...this.state, editingInProgress: true });

  //   if (keysLen > 0) {
  //     const promises = [];
  //     const licensePlate = trailer.get('licensePlate');

  //     for (let i = 0; i < keysLen; i++) {
  //       const currentKey = keys[i];
  //       if (hasValueChanged(formValues[currentKey], this.props.trailer.get(currentKey))) {
  //         if (currentKey === 'plate') {
  //           licensePlate.set('plate', formValues[currentKey]).save().then(
  //             () => promises.push(updateTrailerForState(this.props.trailer, currentKey, formValues[currentKey]))
  //           );
  //         } else {
  //           promises.push(updateTrailerForState(this.props.trailer, currentKey, formValues[currentKey]));
  //         }
  //       }
  //     }

  //     await this.setState({ ...this.state, editingInProgress: false });
  //     return Promise.all(promises);

  //   } else {
  //     await this.setState({ ...this.state, editingInProgress: false });
  //     return Promise.resolve();
  //   }
  // }

  // New editTrailer implementation with plateJurisdiction field
  async editTrailer(formValues) {
    const { trailer } = this.props;
    const keys = Object.keys(formValues);
    const keysLen = keys.length;
    let promises = [];

    const currentUser = getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
    let companyObjectId = getAttribute(belongsToCompany, 'objectId');
    companyObjectId = createTempPointer('Company', companyObjectId);

    await this.setState({ ...this.state, editingInProgress: true });

    const unitId = (formValues.unitId || '').trim();
    if (unitId && (trailer.get('unitId') !== unitId)) {
      // if the trailer's current unitId is not the same as the one being changed to, then
      // the unitId is being changed (as opposed to changing it to the same thing as it is currently)
      const existingTrailer = await queryCompanyObjects(
        'Trailer',
        undefined,
        undefined,
        [
          {
            queryType: 'equalTo',
            name: 'unitId',
            value: unitId,
          },
          { queryType: 'equalTo', name: 'enabled', value: true }
        ],
        undefined,
        undefined,
        true,
        false
      );

      if (existingTrailer) {
        return Promise.reject(`Trailer with unitId ${unitId} already exists`);
      }
    }


    if (keysLen > 0) {

      // Iterate through formValues and update fields accordingly
      for (let i = 0; i < keysLen; i++) {
        const currentKey = keys[i];
        if ((currentKey !== 'plateJurisdiction' && currentKey !== 'plate') && hasValueChanged(formValues[currentKey], trailer.get(currentKey))) {
          promises.push(updateTrailerForState(trailer, currentKey, formValues[currentKey]));
        }
      }

      // If license plate has been created or updated, we need to save it
      // Otherwise, we can avoid a needless save
      let licensePlate = trailer.get('licensePlate');
      let licensePlateShouldBeSaved = false;

      // If licensePlate not defined, create temporary licensePlate record
      if (!licensePlate) {
        licensePlate = createTempRecord(
          'LicensePlate',
          {
            belongsToCompany: companyObjectId,
            trailer
          },
        );
        licensePlateShouldBeSaved = true;
      }

      // If plate changed for trailer OR plate changed for licensePlate
      // Since they are one and the same, we need to check for both and update accordingly
      if (hasValueChanged(formValues.plate, trailer.get('plate')) || hasValueChanged(formValues.plate, licensePlate.get('plate'))) {
        promises.push(updateTrailerForState(trailer, 'plate', formValues.plate));
        licensePlate.set('plate', formValues.plate);
        licensePlateShouldBeSaved = true;
      }

      // If plateJurisdiction changed for trailer, update for trailer and licensePlate as well
      if (formValues.plateJurisdiction) {

        // Obtain stateProvince abbreviation for licensePlate
        const chosenPlateJurisdictionCode = StateProvinces.filter(
          stateProvince => {
            return stateProvince.name === formValues.plateJurisdiction;
          }
        ).length > 0 ?
          StateProvinces.filter(
            stateProvince => {
              return stateProvince.name === formValues.plateJurisdiction;
            }
          )[0].code
          :
          formValues.plateJurisdiction
          ;

        // If plateJurisdiction changed for trailer OR stateProvince changed for licensePlate
        // Since they are one and the same, we need to check for both and update accordingly
        if (hasValueChanged(formValues.plateJurisdiction, trailer.get('plateJurisdiction')) || hasValueChanged(chosenPlateJurisdictionCode, licensePlate.get('stateProvince'))) {
          licensePlate.set('stateProvince', chosenPlateJurisdictionCode);
          licensePlateShouldBeSaved = true;
        }
      }

      if (licensePlateShouldBeSaved) {
        licensePlate.save().then(
          () => {
            promises.push(updateTrailerForState(trailer, 'licensePlate', licensePlate));
            this.setState({ ...this.state, editingInProgress: false });
            return Promise.all(promises);
          },
        );
      } else {
        this.setState({ ...this.state, editingInProgress: false });
        return Promise.all(promises);
      }
    } else {
      this.setState({ ...this.state, editingInProgress: false });
      return Promise.resolve();
    }
  }

  refreshState(props) {
    if (props) {
      this.setState(getInitialState(props));
    }
  }

  render() {
    return (
      <div className="inline">
        <AddEditButton
          refreshState={this.refreshState}
          handleAddEdit={this.editTrailer}
          error={this.state.error}
          title="Edit"
          formTemplate={this.state.editTrailerFormFields}
          disableAdd={this.state.editingInProgress}
        />
      </div>
    );
  }
}

EditTrailerButton.propTypes = {
  trailer: PropTypes.object.isRequired,
  Trailer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Trailer } = state;
  return {
    Trailer,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(EditTrailerButton);

