import * as ActionConstants from './ActionConstants';

import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import * as Parse from 'api/Parse';
import * as Setters from 'api/Setters';
import * as Destroyers from 'api/Destroyers';
import store from 'store';

export function fetchingGeofences() {
  return {
    type: ActionConstants.FETCH_GEOFENCES_INPROGRESS,
  };
}

export function fetchGeofencesSuccess(geofences) {
  return {
    type: ActionConstants.FETCH_GEOFENCES_SUCCESS,
    geofences,
  };
}

export function fetchGeofencesError(error) {
  return {
    type: ActionConstants.FETCH_GEOFENCES_ERROR,
    error,
  };
}

export function addingGeofence() {
  return {
    type: ActionConstants.ADD_GEOFENCE_INPROGRESS,
  };
}

export function addGeofenceSuccess(geofence) {
  return {
    type: ActionConstants.ADD_GEOFENCE_SUCCESS,
    geofence,
  };
}

export function addGeofenceError(error) {
  return {
    type: ActionConstants.ADD_GEOFENCE_ERROR,
    error,
  };
}

export function deleteGeofences() {
  return {
    type: ActionConstants.DELETE_GEOFENCES,
  };
}

export function destroyGeofences(geofenceParseArr) {
  return {
    type: ActionConstants.DESTROY_GEOFENCES,
    geofences: geofenceParseArr,
  };
}

export function updatingGeofence(geofence) {
  return {
    type: ActionConstants.UPDATE_GEOFENCE_INPROGRESS,
    updateReference: geofence,
  };
}

export function updateGeofenceSuccess() {
  return {
    type: ActionConstants.UPDATE_GEOFENCE_SUCCESS,
  };
}

export function updateGeofenceError(error) {
  return {
    type: ActionConstants.UPDATE_GEOFENCE_ERROR,
    error,
  };
}

export function fetchGeofencesForState(page, limit, filter, sortBy) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingGeofences());
    const finalFilter = [].concat(filter, { name: 'enabled', queryType: 'equalTo', value: true });
    Getters.queryCompanyObjects('Geofence', page, limit, finalFilter, sortBy, []).then(
      geofences => {
        store.dispatch(fetchGeofencesSuccess(geofences));
        resolve(geofences);
      },
      error => {
        store.dispatch(fetchGeofencesError(error));
        reject(error);
      }
    );
  });
  return promise;
}

export function fetchLinkedGeofencesForState(page, limit, filter, sortBy) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingGeofences());
    const finalFilter = [].concat(filter, { name: 'enabled', queryType: 'equalTo', value: true });
    // console.log(sortBy)
    Getters.getLinkedObjects(page, limit, finalFilter, sortBy, 'Geofence').then(
      geofences => {
        store.dispatch(fetchGeofencesSuccess(geofences));
        resolve(geofences);
      },
      error => {
        store.dispatch(fetchGeofencesError(error));
        reject(error);
      }
    );
  });
  return promise;
}

export function addGeofenceToState(geofenceParseObj) {
  return store.dispatch(addGeofenceSuccess(geofenceParseObj));
}

export function destroyGeofence(geofenceParseObj) {
  store.dispatch(destroyGeofences([geofenceParseObj]));
  const promise = new Promise((resolve, reject) => {
    Parse.destroyRecords([geofenceParseObj]).then(() => {
      resolve();
    });
  });
  return promise;
}

export function deleteGeofencesForState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteGeofences());
    resolve(store.getState().Geofence);
  });
  return promise;
}

export function updateGeofenceForState(geofenceParseObj) {
  const promise = new Promise(resolve => {
    store.dispatch(updateGeofenceSuccess(geofenceParseObj));
    resolve(store.getState().Geofence);
  });
  return promise;
}
