import React from 'react';
import PropTypes from 'prop-types';

// Components
import HelpDialog from 'components/HelpDialog/container/HelpDialog';

class DriverDetailsSidebarHelpDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const uListStyle = { marginTop: '1em', paddingLeft: '1.2em', fontSize: '.85em' };
    const listStyle = { marginBottom: '.5em' };

    return (
      <HelpDialog title="Driver Details Sidebar" buttonStyle={this.props.buttonStyle}>
        <div style={{ marginTop: '1em', marginBottom: '2em' }}>
          What are the items/categories in the sidebar?

          <div style={{ marginTop: '.5em', marginBottom: '1.5em', fontSize: '.75em' }}>* HOS = Hours of Service</div>

          <ul style={uListStyle}>
            <li style={listStyle}>
              <b>Summary</b>: The summary page details high-level (basic) information about your driver such as their full name, phone number, username, and permissions. It also includes details about the current ELD Device they are using (if applicable). It is also here that you will be able to edit this information, reset their password, or delete them from your account
            </li>
            <li style={listStyle}>
              <b>HOS Recap &amp; Details</b>: The HOS Recap page details the driver&#39;s cycle hours (ex. cycle type, on duty time consumed, etc), hours calculations, and a basic calculator to estimate hours
            </li>
            <li style={listStyle}>
              <b>HOS Logs</b>: The HOS Logs page is arguably the page you&#39;ll be using the most. It contains all Hours of Service logs/information pertaining to the driver (filtered by date), all the events and their details (such as location) that happened at each point on their log, the ability to edit logs and individual events, send logs, and much more
            </li>
            <li style={listStyle}>
              <b>HOS Violations</b>: This is where you will view your driver&#39;s monthly violations such as driving time violations
            </li>
            <li style={listStyle}>
              <b>Trip Inspections</b>: Lists the complete history of the Trip Inspections registered by the driver, along with filters for specific types of inspections
            </li>
          </ul>
        </div>
      </HelpDialog>
    );
  }
}

DriverDetailsSidebarHelpDialog.propTypes = {
  buttonStyle: PropTypes.object,
};

export default DriverDetailsSidebarHelpDialog;
