import { addRecord, copyQuery, count, createQuery, createTempRecord, find, includePointers, setQueryRestriction, sortQuery } from 'api/Parse';

// enums
import { QueryRestrictionTypes, QuerySortOrderTypes } from 'enums/Query';

/**
 * 
 * @param {string} dispatchJobId - id of the dispatch job we wish to retrieve comments for
 * @param {int} page - query page / limit skip multiplier
 * @param {int} limit  - amount of results we want
 * @param {array} filters - array of Filter objects
 */
async function getComments(dispatchJobObjectId, page = 0, limit = 20, filters = [], findAll) {
  const dispatchCommentQuery = createQuery('DispatchComment');
  setQueryRestriction(dispatchCommentQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchJob', dispatchJobObjectId);

  filters.map(filter => {
    setQueryRestriction(dispatchCommentQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
  });

  const dispatchCommentCountQuery = copyQuery(dispatchCommentQuery);

  includePointers(dispatchCommentQuery, ['dispatchJob', 'sentBy']);

  if (!findAll) {
    setQueryRestriction(dispatchCommentQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
    setQueryRestriction(dispatchCommentQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
  }

  sortQuery(dispatchCommentQuery, QuerySortOrderTypes.DESCENDING, 'dateTimeUTC');

  try {
    const [totalCommentsCount, dispatchComments] = await Promise.all([count(dispatchCommentCountQuery), find(dispatchCommentQuery, undefined, findAll)]);
    return { dispatchComments, totalCommentsCount };
  } catch(err) {
    throw new Error(err);
  }
}

async function removeComment(dispatchCommentId) {
  // const dispatchJobQuery = new Parse.Query('DispatchJob');
  // dispatchJobQuery.equalTo('objectId', dispatchJobId);
  // const job = await dispatchJobQuery.first();
  // const updatedJob = await job.set('isHidden', true).save();

  // const dispatchJob = createTempParseObject('DispatchJob', { 'objectId': dispatchJobId });
  // const triggeredAtUTC = new Date();
  // const currentUser = getCurrentUser(true);
  // const currentUserFullName = formatName(`${currentUser.firstName} ${currentUser.lastName}`);

  // addHistory(dispatchJob, {
  //   dispatchJob: updatedJob,
  //   description: `${currentUserFullName} deleted Job ${updatedJob.get('batchId')}`,
  //   dispatchClass: 'DispatchJob',
  //   dispatchClassObjectId: dispatchJobId,
  //   level: 0,
  //   triggeredAtUTC,
  // });

  // return updatedJob;
}

/**
 * @description add a Dispatch Comment to the db
 * @param {object} Comment - Comment
 */
async function addComment(dispatchJobObjectId, commentSbObject) {
  const dispatchComment = { ...commentSbObject };
  dispatchComment.dispatchJob = createTempRecord('DispatchJob', { objectId: dispatchJobObjectId });
  delete dispatchComment.objectId;

  try {
    return await addRecord('DispatchComment', dispatchComment);
  } catch(err) {
    throw new Error(err);
  }
}

export {
  addComment,
  getComments,
  removeComment,
};
