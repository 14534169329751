import React from 'react';
import * as Sentry from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { withRouter } from "react-router";
import { Route, Switch, Navigate } from 'react-router-dom';

import sbStore from 'sbStore';

const chmln = require('@chamaeleonidae/chmln');

// API
import { subscribeUnsubscribeChannel } from 'api/PubNub';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import * as Analytics from 'api/Analytics';
import { identify, page, track } from 'sb-csapi/dist/api/Analytics/Product';
import { getVisitedPathString } from 'sb-csapi/dist/enums/Analytics/Product';
import { getAttribute, getCurrentUser, getObjectById } from 'api/Parse';

// Contexts
import { ToastContextProvider } from 'contexts/ToastContext';

// Components
import Header from 'components/Shared/Header/Header';
import PaymentModal from 'components/PaymentModal/container/paymentModal';
import ChatModule from 'components/Chat/container/ChatModule';
import ToastNotifications from 'components/Shared/ToastNotifications/ToastNotifications';
import ErrorBoundary from 'sbCore/ErrorBoundary/ErrorBoundary';

// CSS
import styles from './InAppLayout.module.scss';

// Import
import { requireAuth } from '../../actions/Auth';

// Layouts
import HomeLayout from 'layouts/HomeLayout/HomeLayout';
import WhatsNewLayout from 'layouts/WhatsNewLayout/WhatsNewLayout';
import JobLinkDetailsLayout from 'layouts/JobLinkDetailsLayout/JobLinkDetailsLayout';
import GeofenceLayout from 'layouts/GeofenceDetailsLayout/GeofenceDetailsLayout';
import EquipmentLayout from 'layouts/EquipmentLayout/EquipmentLayout';

import DispatchJobLayout from 'layouts/Dispatch/DispatchJobLayout/DispatchJobLayout'; // replaces JobLayout when done
import DispatchLayout from 'layouts/Dispatch/DispatchLayout/DispatchLayout';

import DocumentsLayout from 'layouts/DocumentsLayout/DocumentsLayout';
import DriversLayout from 'layouts/DriversLayout/DriversLayout';
import DriverDetailsLayout from 'layouts/DriverDetailsLayout/DriverDetailsLayout';
import DriverBehaviourLayout from 'layouts/DriverBehaviourLayout/DriverBehaviourLayout';
import SafetyLayout from 'layouts/SafetyLayout/SafetyLayout';
import EManifestLayout from 'layouts/EManifestLayout/EManifestLayout';
import SettingsLayout from 'layouts/SettingsLayout/SettingsLayout';
import MapLayoutOld from 'layouts/MapLayout.old/MapLayout.old';
import MapLayout from 'layouts/MapLayout/MapLayout';
import VehicleDetailsLayout from 'layouts/VehicleDetailsLayout/VehicleDetailsLayout';
import VisionLayout from 'layouts/Vision/VisionLayout/VisionLayout';
import IFTALayout from 'layouts/IFTALayout/IFTALayout';
import LoadboardLayout from 'layouts/LoadboardLayout/LoadboardLayout';
import UserPermissionsLayout from 'layouts/UserPermissionsLayout/UserPermissionsLayout';
import moment from 'moment-timezone';

// Context
import StoreContext from 'contexts/StoreContext';
import PASubPermInitializer from './PASubPermInitializer';

class InAppLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      identifiedUser: false,
      isPASubPermInitialized: false,
      loadedChat: false,
      loadedLocalize: false,
    };
    this.refreshState = this.refreshState.bind(this);
    this.checkActiveSession = this.checkActiveSession.bind(this);
    this.onPASubPermInitialized = this.onPASubPermInitialized.bind(this);
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      Analytics.page();
    });
    this.checkActiveSessionTimerId = setInterval(() => this.checkActiveSession(), 1800000); // Runs every 30 minutes
  }


  componentDidMount() {
    document.addEventListener('keydown', (e) => (window.sbKeydownEvent69 = e)); // set global keydown event logging
    const isNotProdEnvironment = Getters.getEnvironment() !== 'prod';

    Analytics.page();

    // Sentry
    Sentry.init({
      dsn: 'https://2219a51c67b243648a22cdb0ef604e17@o4505553196941312.ingest.us.sentry.io/4505553206312960',
      integrations: [
        // new Sentry.BrowserTracing({
        //   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        //   tracePropagationTargets: ['localhost'],
        // }),
        // new Sentry.Replay(),
      ],
      // Performance Monitoring
      sampleRate: 0.25,
      tracesSampleRate: 0.25,
      // Session Replay
      // replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    requireAuth();
  }


  componentWillReceiveProps(nextProps) {
    const { Company, User, location } = nextProps;
    const { identifiedUser, loadedChat, loadedLocalize } = this.state;

    const user = User && User.fetched && User.user;
    const company = Company && Company.fetched && Company.company;

    if (location) {
      // log location visits for analytics
      const locationSplit = location.pathname.split('/');
      const directory = locationSplit[1]; // name of the main page. ex. /drivers/abc123/profile/edit -> drivers
      const subDirectory = locationSplit[2]; // part after the page name. ex. abc123
      // const subpath = locationSplit.slice(2).join('/'); // the combined path after the subdirectory. ex. profile/edit

      // user visited page
      if (window.sbProdAnalIsAnon === false) {
        // Don't want to track anonymous users
        page(window.sbProdAnalEntityId, directory, subDirectory || '');
        track(window.sbProdAnalEntityId, `${getVisitedPathString(location.pathname)}`);
      }

      // company has seen page
      const hasViewed = {
        page: { [directory]: true },
      };

      identify(window.sbProdAnalGroupId, { hasViewed }, undefined, undefined, Company.company);
    }

    if (!identifiedUser) {
      this.refreshState(user, company, identifiedUser);
    }

    const isLocalDomain = window.location.host.indexOf('localhost') !== -1;
    const isSupportToolDomain = window.location.host.indexOf('app-support') !== -1;
    if (!loadedChat && !isLocalDomain && !isSupportToolDomain) {
      // // Freshchat code
      // if (window.fcWidget) {
      //   window.fcWidget.init({
      //     token: 'c8e29901-90ac-4d6a-8eef-0629e5fd10f5',
      //     host: 'https://wchat.freshchat.com',
      //   });
      //   this.setState({ ...this.state, loadedChat: true });
      // }
      // Atlas
      // Freshchat code

      if (window.Atlas) {
        window.Atlas.call('start');
        this.setState({ ...this.state, loadedChat: true });
      }
    }

    if (!loadedLocalize) {
      // Localize hide widget in-app
      if (window.Localize) {
        window.Localize.hideWidget();
        moment.locale(window.Localize.getLanguage());
        this.setState({ ...this.state, loadedLocalize: true });
      }
    }
  }

  componentWillUnmount() {
    if (window.fcWidget) {
      window.fcWidget.destroy();
    }

    this.unlisten();
    clearInterval(this.checkActiveSessionTimerId);
  }

  async checkActiveSession() {
    // Simple check to see if the session has expired by querying for the company of the current user
    const user = getCurrentUser();
    const company = user && getAttribute(user, 'belongsToCompany');

    company && await getObjectById('Company', getAttribute(company, 'objectId'));
  }

  async refreshState(user, company, identifiedUser) {
    if (
      (user && user.id) &&
      (company && company.id) &&
      !identifiedUser
    ) {
      // Analytics
      Analytics.identifyUser(user);
      Analytics.identifyUserForCompany(null, user && user.id);

      const firstName = user.get('firstName');
      const lastName = user.get('lastName');
      const email = `${user.get('email')}`;
      const companyId = `${company.id}`;
      const userId = `${user.id}`;

      // // Freshchat
      // if (window.fcWidget) {
      //   window.fcWidget.setExternalId(userId);
      //   window.fcWidget.user.setFirstName(firstName);
      //   window.fcWidget.user.setLastName(lastName);
      //   window.fcWidget.user.setEmail(email);
      // }

      // Atlas
      if (window.Atlas) {
        window.Atlas.call(
          'identify',
          {
            userId,
            firstName,
            lastName,
            email,
          }
        );
      }

      // Sentry
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: userId,
          email,
        });
      });

      // Sentry Test
      // try {
      //   aFunctionThatMightFail();
      // } catch (err) {
      //   console.log(err);
      //   Sentry.captureException(err);
      // }

      // Chameleon
      if (!window.chmln) {
        chmln.init('SMRBWbOvaSgytoRDK86dOZNmYRkOkr3iKo4jJNrTe3aNGo-1PwNVZ-Efe5nErOuPOerJO8', { fastUrl: 'https://fast.chameleon.io/' });
        chmln.identify(user.id, {
          email,
          name: `${firstName} ${lastName}`,
        });
      }

      // Hide Hubspot
      window?.HubSpotConversations?.widget?.remove();

      // Beginning of Profitwell Awesomeness Code (which loads totally asynchronously)
      profitwell('user_email', email);

      this.setState({ ...this.state, identifiedUser: true });

      if (
        window?.location?.host?.indexOf('app-support') === -1
        && window.location.host.indexOf('app-manager') === -1
      ) {
        subscribeUnsubscribeChannel(company.id, true);
      }

      // Support Staff User Id
      let supportUserId;
      const name = `${supportUserId}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split('; ');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        if (c.indexOf('supportUserId') === 0) {
          supportUserId = c.substring(14);
        }
      }
      const supportUser = await getObjectById('_User', supportUserId);
      this.setState({ ...this.state, supportUser });
    }
  }

  onPASubPermInitialized(isPASubPermInitialized) {
    this.setState({ isPASubPermInitialized });
  }

  render() {
    const { Company, Subscription, location } = this.props;
    const companyId = Company.company && Company.company.id;
    const isSupportToolDomain = window.location.host.indexOf('app-support') !== -1;

    if (window.Localize) {
      // This solved the fresh load rendering problem, look into single-call only optimization necessity
      window.Localize.translatePage();
    }

    return (
      <ToastContextProvider>
        <ErrorBoundary>
          <Header location={location} />
          {this.state.supportUser && isSupportToolDomain &&
            (
              <div className={styles.supportUser}>
                <span>{this.state.supportUser.id}</span>
              </div>
            )
          }
          <Provider store={sbStore}>
            <PASubPermInitializer onInitialized={this.onPASubPermInitialized} />
          </Provider>
          <ToastNotifications />
          {companyId && this.state.isPASubPermInitialized &&
            <div className={styles.inAppContent}>
              <Switch>
                <Route path="/home" component={HomeLayout} />
                <Route path="/new" component={WhatsNewLayout} />
                <Route path="/jobs/:jobLinkId" component={JobLinkDetailsLayout} />
                <Route path="/documents" component={DocumentsLayout} />
                <Route path="/dispatch/job/:dispatchJobObjectId/:subroute?/:dispatchItemObjectId?" component={DispatchJobLayout} />
                <Route path="/dispatch" component={DispatchLayout} />
                <Route path="/drivers" component={DriversLayout} />
                <Route path="/driver" component={DriverDetailsLayout} />
                <Route path="/driverpatterns" component={DriverBehaviourLayout} />
                <Route path="/vehicle" component={VehicleDetailsLayout} />
                <Route path="/vision/:subroute?/:vehicleUnitId?" component={VisionLayout} />
                <Route path="/safety" component={SafetyLayout} />
                <Route path="/equipment" component={EquipmentLayout} />
                <Route path="/emanifest" component={EManifestLayout} />
                <Route path="/map" component={MapLayoutOld} />
                <Route path="/map-2" component={MapLayout} />
                <Route path="/ifta" component={IFTALayout} />
                <Route path="/geofence" component={GeofenceLayout} />
                <Route path="/permissions/:userId" component={UserPermissionsLayout} />
                <Route path="/settings" component={SettingsLayout} />
                <Route path="/loadboard" component={LoadboardLayout} />
                <Route path="*" component={<div>This Page Does Not Exist</div>} />
              </Switch>
            </div>
          }
          <PaymentModal Subscription={Subscription} />
          <ChatModule />
        </ErrorBoundary>
      </ToastContextProvider>
    );
  }
}

InAppLayout.propTypes = {
  Company: PropTypes.object.isRequired,
  Subscription: PropTypes.object.isRequired,
  User: PropTypes.object.isRequired,
  location: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { Company, Subscription, User } = state;
  return {
    Company,
    Subscription,
    User,
  };
};

export default withRouter(connect(mapStateToProps, null, null, { context: StoreContext })(InAppLayout));
