import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';
import Chip from 'material-ui/Chip';

import { getTimezoneAbbreviation } from 'api/utils/timezone';

// Components
import DutyStatusBadge from 'components/Drivers/DutyStatusBadge/DutyStatusBadge';
// import StatusIcon from 'components/StatusIcon/view/StatusIcon';
import DriverDetailsSidebarHelpDialog from './DriverDetailsSidebarHelpDialog';
import { MDBBtn, MDBIcon } from 'mdbreact';

// CSS
import styles from './Sidebar.module.scss';

function Sidebar(props) {
  let summaryStyle = styles.option;
  let hoursDetailsStyle = styles.option;
  let hosEventsStyle = styles.option;
  let hosViolationsStyle = styles.option;
  let receiptsStyle = styles.option;
  let safetyStyle = styles.option;
  let tripInspectionStyle = styles.option;
  let vehiclePreferencesStyle = styles.option;
  let speedingBehaviourStyle = styles.option;
  let documentsStyle = styles.option;

  const callingUser = props.callingUser;
  const userSpecialPermission = callingUser && callingUser.get('userSpecialPermission');

  const disableRecapHours = userSpecialPermission && userSpecialPermission.get('disableRecapHours');
  const disableViolations = userSpecialPermission && userSpecialPermission.get('disableViolations');

  if (props.activeSubview === 'summary') {
    summaryStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'driverHoursDetails') {
    hoursDetailsStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'hosEvents') {
    hosEventsStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'hosViolations') {
    hosViolationsStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'receipts') {
    receiptsStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'safety') {
    safetyStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'tripInspections') {
    tripInspectionStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'vehiclePreferences') {
    vehiclePreferencesStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'documents') {
    documentsStyle = `${styles.active} ${styles.option}`;
  }

  const lastUpdatedDateTime =
    props.driver &&
    props.driver.get('latestELDEvent') &&
    props.driver.get('latestELDEvent').get('eldDailyCertification') &&
    (
      (
        props.driver.get('latestELDEvent').get('eldDailyCertification').get('updatedAt') &&
        moment(props.driver.get('latestELDEvent').get('eldDailyCertification').get('updatedAt')).format('LLL')
      )
      || (moment(props.driver.get('latestELDEvent').get('eventDateTime')).format('LLL'))
    );

  window.Localize.translatePage();
  return (
    <div className={styles.sidebarContainer + (props.isOpen ? '' : ' ' + styles.hide)}>
      <div className={styles.sidebar + (props.isOpen ? '' : ' ' + styles.closed)}>
        <div className={styles.sidebarTitle}>
          <div className={styles.title}>
            <span>{props.title}</span>
            {
              // props.activeStatus !== undefined &&
              // <span>
              //   &nbsp;&nbsp;
              //   <StatusIcon active={props.activeStatus} size="lg" />
              // </span>
            }
          </div>
          <div className={`${styles.subHeader} translate-me`}>Driver Details</div>
          <div>
            {props.company && props.company.get('enableCompanyLinks') &&
              <div className={styles.company}>
                <hr />
                {props?.driver?.get('belongsToCompany')?.get('name')}
                <hr />
              </div>
            }
            {props?.driver?.get('enabled') &&
              <div className={`${styles.dutyInfo} translate-me`}>
                <DutyStatusBadge eldStatusInt={props?.driver?.get('eldStatusInt')} />
                <div className={styles.updatedAt}>
                  Last Updated @ <var>{lastUpdatedDateTime || 'N/A'}</var>
                  <div>{`(${getTimezoneAbbreviation(lastUpdatedDateTime)})`}</div>
                </div>
              </div>
            }
            {props.driver && !props.driver.get('enabled') &&
              <div className={styles.disabled}>
                <hr />
                <Chip className={`${styles.chip} translate-me`} labelColor={'white'}>INACTIVE</Chip>
                <hr />
              </div>
            }
          </div>
        </div>

        <div className={`${styles.subViews} translate-me`}>
          <a href={`/driver?driver=${props.driverObjectId}&view=summary`} onClick={(e) => e.preventDefault()}>
            <div className={summaryStyle} onClick={() => props.handleSubViewClick('summary')}>
              <div className={styles.label}>Summary</div>
            </div>
          </a>

          {props.driverFetched && props.eldModuleSubscribed && !disableRecapHours &&
            <a href={`/driver?driver=${props.driverObjectId}&view=driverHoursDetails`} onClick={(e) => e.preventDefault()}>
              <div className={hoursDetailsStyle} onClick={() => props.handleSubViewClick('driverHoursDetails')}>
                <div className={styles.label}>Hours of Service Recap</div>
              </div>
            </a>
          }

          {props.driverFetched && props.eldModuleSubscribed &&
            <a href={`/driver?driver=${props.driverObjectId}&view=hosEvents`} onClick={(e) => e.preventDefault()}>
              <div className={hosEventsStyle} onClick={() => props.handleSubViewClick('hosEvents')}>
                <div className={styles.label}>Hours of Service Logs</div>
              </div>
            </a>
          }

          {props.driverFetched && props.eldModuleSubscribed && !disableViolations &&
            <a href={`/driver?driver=${props.driverObjectId}&view=hosViolations`} onClick={(e) => e.preventDefault()}>
              <div className={hosViolationsStyle} onClick={() => props.handleSubViewClick('hosViolations')}>
                <div className={styles.label}>Hours of Service Violations</div>
              </div>
            </a>
          }

          {props.driverFetched && props.eldModuleSubscribed &&
            <a href={`/driver?driver=${props.driverObjectId}&view=tripInspections`} onClick={(e) => e.preventDefault()}>
              <div className={tripInspectionStyle} onClick={() => props.handleSubViewClick('tripInspections')}>
                <div className={styles.label}>Trip Inspections</div>
              </div>
            </a>
          }

          {props.driverFetched && props.eldModuleSubscribed && props.driverDocumentModuleSubscribed &&
            <a href={`/driver?driver=${props.driverObjectId}&view=documents`} onClick={(e) => e.preventDefault()}>
              <div className={documentsStyle} onClick={() => props.handleSubViewClick('documents')}>
                <div className={styles.label}>Documents</div>
              </div>
            </a>
          }

          {/* props.driverFetched &&
          <div className={vehiclePreferencesStyle} onClick={() => props.handleSubViewClick('vehiclePreferences')}>
            <div className={styles.label}>Vehicle Preferences</div>
          </div>
        */}

          {/*
        <div className={receiptsStyle} onClick={() => props.handleSubViewClick('receipts')}>
          <div className={styles.label}>Receipts</div>
        </div>
        */}

          {
            // <div className={safetyStyle} onClick={() => props.handleSubViewClick('safety')}>
            //   <div className={styles.label}>Safety Issues</div>
            // </div>
          }

        </div>

        <div style={{ position: 'absolute', bottom: '2em', width: '100%', textAlign: 'center' }}>
          <DriverDetailsSidebarHelpDialog />
        </div>
      </div>
      <MDBBtn size="sm" color="primary" className={styles.drawerButton} onClick={props.toggleSidebar}>
        {!props.isOpen && <MDBIcon icon="list-alt" className="mr-2"></MDBIcon>}
        {!props.isOpen && <MDBIcon icon="angle-right"></MDBIcon>}
        {props.isOpen && <MDBIcon icon="angle-left"></MDBIcon>}
      </MDBBtn>
    </div>
  );
}

Sidebar.propTypes = {
  title: PropTypes.string,
  driver: PropTypes.object,
  driverObjectId: PropTypes.string,
  company: PropTypes.object,
  driverFetched: PropTypes.bool,
  activeStatus: PropTypes.bool,
  activeSubview: PropTypes.string,
  handleSubViewClick: PropTypes.func,
  eldModuleSubscribed: PropTypes.bool,
  driverDocumentModuleSubscribed: PropTypes.bool,
  callingUser: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired
};

export default Sidebar;
