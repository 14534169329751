/** @module DispatchReferenceNumber */

// CSAPI
import {
  copyQuery, count, createQuery, createQueryOr, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, addRecord, destroyRecord
} from 'sb-csapi/dist/AAPI';

// Query
import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

// Sort
import Sort from 'sb-csapi/dist/sbObjects/Sort';

// Filter
import Filter from 'sb-csapi/dist/sbObjects/Filter';

const DISPATCH_REF_NUM_TABLE = 'DispatchReferenceNumber';

/**
 * @category Dispatch
 * @subcategory ReferenceNumber
 *
 * @description Get a list of Dispatch Reference Numbers for a given dispatch job
 *
 * @param {object} options - See example
 * @param {string} dispatchJobObjectId - The specific dispatch job that the reference numbers belong to
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {int} page - The current page for pagination (not yet implemented)
 * @param {int} limit - The limit of records obtained per pagination (not yet implemented)
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { object } - { dispatchReferenceNumbers: [DispatchReferenceNumber, ...] }
 */
async function getDispatchReferenceNumbers(options = { sessionToken: getCurrentUserSessionToken() }, dispatchJobObjectId, filters = [], sortBy = new Sort(QuerySortOrder.ASCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  if (!dispatchJobObjectId) throw new Error('A valid dispatchJobObjectId is required');

  const dispatchReferenceNumberQuery = createQuery(DISPATCH_REF_NUM_TABLE);
  setQueryRestriction(dispatchReferenceNumberQuery, QueryRestriction.EQUAL_TO, 'dispatchJob', dispatchJobObjectId);

  filters.map(filter => setQueryRestriction(dispatchReferenceNumberQuery, filter.queryRestriction, filter.attribute, filter.value));

  sortQuery(dispatchReferenceNumberQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchReferenceNumberQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchReferenceNumberQuery, selectedAttributes);

  return await findRecords(options, dispatchReferenceNumberQuery, false, queryAll);
}

/**
 * @category Dispatch
 * @subcategory ReferenceNumber
 *
 * @description Adds a new DispatchReferenceNumber record with given dispatchReferenceNumber sbObject
 * 
 * @param {object} options - Session Token of user making the request, ex. { sessionToken: getCurrentUserSessionToken() }
 * @param {string} dispatchReferenceNumberTable - Table name 'DispatchReferenceNumber'
 * @param {object} dispatchReferenceNumberSBObject - Dispatch reference number sbObject
 * @param {string} companyObjectId - The company objectId associated with the record
 * 
 * @returns {object} - The DispatchReferenceNumber record that was saved
 * 
 */
async function addDispatchReferenceNumber(options = { sessionToken: getCurrentUserSessionToken() }, dispatchReferenceNumberTable = DISPATCH_REF_NUM_TABLE, dispatchReferenceNumberSBObject, companyObjectId = getCurrentUserCompanyObjectId()) {
  if (!dispatchReferenceNumberSBObject) throw new Error('Valid dispatch reference number key value object is required');

  return await addRecord(options, dispatchReferenceNumberTable, dispatchReferenceNumberSBObject, companyObjectId);
}

/**
 * @category Dispatch
 * @subcategory ReferenceNumber
 *
 * @description Deletes single DispatchReferenceNumber record
 * 
 * @param {object} options - Session Token of user making the request, ex. { sessionToken: getCurrentUserSessionToken() }
 * @param {DispatchReferenceNumber} dispatchReferenceNumberRecord - DispatchReferenceNumber record to remove
 * 
 * @returns {object} - The DispatchReferenceNumber record that was removed
 */
async function removeDispatchReferenceNumber(options = { sessionToken: getCurrentUserSessionToken() }, dispatchReferenceNumberRecord) {
  if (!dispatchReferenceNumberRecord) throw new Error('Valid DispatchReferenceNumber record is required');

  return await destroyRecord(options, dispatchReferenceNumberRecord);
}

export {
  getDispatchReferenceNumbers,
  addDispatchReferenceNumber,
  removeDispatchReferenceNumber,
};
