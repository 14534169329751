import React from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardText, MDBRow, MDBCol } from 'mdbreact';

// Components
import DateTimePicker from 'components/Shared/DateTimePicker/DateTimePicker';
import SBBlock from 'components/Shared/SBBlock/SBBlock';

// Api
import { callCloudFunction, getCurrentUser, getAttribute, createQuery, setQueryRestriction, find, sortQuery } from 'api/Parse';

// Enums
import { QueryRestrictionTypes, QuerySortOrderTypes } from 'enums/Query';

const moment = require('moment');

class RepairRouteModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      vehicleUnitId: this.props.unitId,
      hideConfirmButton: true,
      selectedStartDayForMonth: undefined,
      showIntervalRepairWarning: false,
      showNoRoutesForIntervalWarning: false,
    };
  }

  async repairIFTARoutes(selectedStartDayForMonth, vehicleUnitId) {
    const currentUser = getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
    const companyObjectId = getAttribute(belongsToCompany, 'objectId');

    // Determining endDate from startDate (month interval)
    let startDate = selectedStartDayForMonth;
    let endDate = new Date(selectedStartDayForMonth.getFullYear(), selectedStartDayForMonth.getMonth() + 1, 0);

    // Formatting parameters for mapbox
    startDate = moment.utc(startDate).format('YYYY-MM-DD');
    endDate = moment.utc(endDate).format('YYYY-MM-DD');
    const companyObjectIds = [companyObjectId];

    this.props.handleClose();
    this.setState({ ...this.state, selectedStartDayForMonth: undefined });

    try {
      await callCloudFunction('repairIFTARoute', { startDate, endDate, companyObjectIds, vehicleUnitId });
    } catch (e) {
      console.error(e);
    }
  }

  async checkForRepairInterval(selectedStartDayForMonth, vehicleUnitId) {
    const currentUser = getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
    const companyObjectId = getAttribute(belongsToCompany, 'objectId');

    // Determining endDate from startDate (month interval)
    const startDate = selectedStartDayForMonth;
    const endDate = new Date(selectedStartDayForMonth.getFullYear(), selectedStartDayForMonth.getMonth() + 1, 0);

    // Query for IFTARoutes within selected interval
    // To determine whether or not to allow client to make repairs
    const iftaRouteBetaQuery = createQuery('IFTARoute_Beta');
    setQueryRestriction(iftaRouteBetaQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', companyObjectId);
    setQueryRestriction(iftaRouteBetaQuery, QueryRestrictionTypes.EQUAL_TO, 'vehicleUnitId', vehicleUnitId);
    setQueryRestriction(iftaRouteBetaQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'dateStart', startDate);
    setQueryRestriction(iftaRouteBetaQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'dateEnd', endDate);
    sortQuery(iftaRouteBetaQuery, QuerySortOrderTypes.DESCENDING, 'createdAt');
    const iftaRouteFromBeta = await find(iftaRouteBetaQuery, true, false);

    // Obtain createdAt and hours between currentTime
    if (iftaRouteFromBeta) {
      const createdAtFromIFTARoute = moment(iftaRouteFromBeta.get('createdAt'));
      const currentTime = moment(new Date());
      const createdAtCurrentTimeJump = currentTime.diff(createdAtFromIFTARoute, 'hours');

      if (createdAtCurrentTimeJump > 2) {
        // Last iftaRoute within this interval was created >2 hours ago
        // We can repair for this interval again
        this.setState({ ...this.state, hideConfirmButton: false, showIntervalRepairWarning: false, showNoRoutesForIntervalWarning: false });
      } else {
        // Last iftaRoute within this interval was created <=2 hours ago
        // We should not allow repairs for this interval
        this.setState({ ...this.state, hideConfirmButton: true, showIntervalRepairWarning: true, showNoRoutesForIntervalWarning: false });
      }
    } else {
      // No iftaRoutes found for interval; allow repair
      this.setState({ ...this.state, hideConfirmButton: false, showIntervalRepairWarning: false, showNoRoutesForIntervalWarning: false });
    }
  }

  closeModal() {
    this.setState({ ...this.state, selectedStartDayForMonth: undefined, showIntervalRepairWarning: false, showNoRoutesForIntervalWarning: false });
    this.props.handleClose();
  }

  render() {
    const { dateStart, dateEnd } = this.props;
    const warningMessages = [
      <div style={{ fontSize: '1em' }}>
        <div style={{ fontSize: '1.2em' }}>Reset routes allows Switchboard to reprocess the routes based on ELD & GPS data.</div>
        <b>Note:</b> IFTA data, including route & mileage changes, will be reset.
      </div>,
      <small>It may take a few hours to process the data.</small>,
    ];

    return (
      <MDBModal isOpen={this.props.show} centered>
        <MDBModalHeader>Reset Routes for unit {this.props.unitId}</MDBModalHeader>
        <MDBModalBody>
          <SBBlock
            title="Notice"
            messages={warningMessages}
            listMessages={false}
          />
          <MDBCard className="z-depth-0 text-left">
            <MDBCardBody className="p-0">
              <MDBCardText className="z-depth-0 text-left">Month to Repair</MDBCardText>
              <MDBRow style={{ padding: '0.5em' }}>
                <MDBCol className="w-50">
                  <DateTimePicker
                    label="Select a Month"
                    selectedDate={this.state.selectedStartDayForMonth}
                    dateFormat="MM/yyyy"
                    // minDate={dateStart} don't need to have, it confuses them
                    // maxDate={dateEnd} don't need to have, it confuses them
                    maxDate={moment().endOf('month').toDate()}
                    showMonthYearPicker
                    onDateChange={(e) => this.setState({ ...this.state, selectedStartDayForMonth: e }, () => this.checkForRepairInterval(this.state.selectedStartDayForMonth, this.state.vehicleUnitId))}
                  />
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
          {this.state.showIntervalRepairWarning
            && <div><span style={{ color: 'red' }}>Warning: </span> Repairs are in progress for this interval</div>
          }
          {
            this.state.showNoRoutesForIntervalWarning
            && <div><span style={{ color: 'red' }}>Warning: </span> No routes found for interval</div>
          }
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn className={'closeButton'} onClick={() => this.closeModal()}>Cancel</MDBBtn>
          <MDBBtn className={'buttonDefault'} disabled={this.state.hideConfirmButton} onClick={() => this.repairIFTARoutes(this.state.selectedStartDayForMonth, this.state.vehicleUnitId)}>Confirm</MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

export default RepairRouteModal;
