import React from 'react';

// Material
import { MDBContainer, MDBIcon, MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardText, MDBRow, MDBCol } from 'mdbreact';
import Divider from 'material-ui/Divider';

// Api
import { getVehicleLocationDescription, getLocationDescriptionBreakdown, isValidLocationDescription } from 'api/VehicleLocation/VehicleLocation';
import { createTempRecord, getCurrentUser, getAttribute, updateRecord, addRecord, createTempPointer, createQuery, setQueryRestriction, find } from 'api/Parse';
import { distanceBetweenTwoGeopoints } from '../../../api/Mapbox';

// Components
import DriverAutocomplete from 'components/Shared/DriverAutocomplete/DriverAutocomplete.old';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import LocationAutocomplete from 'components/Shared/LocationAutocomplete/LocationAutocomplete';
import DateTimePicker from 'components/Shared/DateTimePicker/DateTimePicker';

// Helpers
import * as Helpers from '../../../api/Helpers';

// Enums
import { StateProvince } from 'enums/StateProvince';
import { LengthUnit, LengthUnitNames, LengthUnitTypes, VolumeUnitTypes, VolumeUnitNames } from 'enums/DispatchUnit';
import { CurrencyTypes } from 'enums/DispatchCurrency';
import { LocationDirectionTypes, LocationDirectionTypeDescription } from 'enums/LocationDirection';
import { QueryRestrictionTypes } from 'enums/Query';

class AddEditFuelPurchasesCard extends React.Component {
  constructor(props) {
    super();
    this.state = {
      date: undefined,
      volumeUnits: 'L',
      volumePumped: '0',
      fuelType: '',
      currency: 'cad',
      totalPaid: '0',
      city: '',
      stateProvince: '',
      fuelCardFourDigits: '1000',
      allFieldsValid: false,
      errorHash: {
        fuelInformationIsValid: {
          bool: undefined,
          explanation: undefined,
        },
        paymentInformationIsValid: {
          bool: undefined,
          explanation: undefined,
        },
        locationIsValid: {
          bool: undefined,
          explanation: undefined,
        },
        dateIsValid: {
          bool: undefined,
          explanation: undefined,
        },
      },
    };

    this.determineFuelPurchaseFormValidity = this.determineFuelPurchaseFormValidity.bind(this);
    this.determineFuelInformationValidity = this.determineFuelInformationValidity.bind(this);
    this.determinePaymentInformationValidity = this.determinePaymentInformationValidity.bind(this);
    this.determineLocationValidity = this.determineLocationValidity.bind(this);
    this.determineDateValidity = this.determineDateValidity.bind(this);
    this.initializeCard = this.initializeCard.bind(this);
  }

  componentDidMount() {
    this.initializeCard();
  }

  initializeCard() {
    this.setState(this.props.fuelPurchase.purchaseInformation);
  }

  /**
   * @description Determine the validity of the fuelPurchaseInformation the client has inputted
   * @param {Object} purchaseInformation Object containing the information of the fuelPurchaseRecord we are currently on
   * @param {String} validityType String that determines which field we are checking validity for
   */
  determineFuelPurchaseFormValidity(purchaseInformation, validityType) {
    const {
      date,
      volumeUnits,
      volumePumped,
      fuelType,
      currency,
      totalPaid,
      city,
      stateProvince,
      fuelCardFourDigits,
    } = purchaseInformation;
    const newState = { ...this.state };
    newState.errorHash = { ...this.state.errorHash };

    // Determine each field validity
    switch (validityType) {
      case 'date':
        newState.errorHash.dateIsValid = this.determineDateValidity(date);
        break;
      case 'fuel':
        newState.errorHash.fuelInformationIsValid = this.determineFuelInformationValidity(volumePumped, volumeUnits, fuelType);
        break;
      case 'payment':
        newState.errorHash.paymentInformationIsValid = this.determinePaymentInformationValidity(totalPaid, currency, fuelCardFourDigits);
        break;
      case 'location':
        newState.errorHash.locationIsValid = this.determineLocationValidity(city, stateProvince);
        break;
      case 'all':
        newState.errorHash.dateIsValid = this.determineDateValidity(date);
        newState.errorHash.fuelInformationIsValid = this.determineFuelInformationValidity(volumePumped, volumeUnits, fuelType);
        newState.errorHash.paymentInformationIsValid = this.determinePaymentInformationValidity(totalPaid, currency, fuelCardFourDigits);
        newState.errorHash.locationIsValid = this.determineLocationValidity(city, stateProvince);
        break;
      default:
        break;
    }

    // Check to see if the fields are valid or not
    if (
      newState.errorHash.fuelInformationIsValid.bool &&
      newState.errorHash.paymentInformationIsValid.bool &&
      newState.errorHash.locationIsValid.bool &&
      newState.errorHash.dateIsValid.bool
    ) {
      newState.allFieldsValid = true;
    } else {
      newState.allFieldsValid = false;
    }

    this.setState(newState,
      () => {
        this.props.updatePurchaseInformationForFuelPurchaseHash(this.state);
      }
    );
  }

  /**
   * @description Determines if the date inputted by the client is valid
   * @param {Date} date Date inputted by the client
   * @returns Object containing a boolean (true if valid, false if not) and an explanation to display to the client
   */
  determineDateValidity(date) {
    if (date) {
      return {
        bool: true,
      };
    } else {
      return {
        bool: false,
        explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> Date information is incomplete</div>,
      };
    }
  }

  /**
   * @description Determines if the fuelling information inputted by client is valid
   * @param {String} volumePumped String representing the amount of fuel pumped
   * @param {String} volumeUnits The units used to measure the fuel
   * @param {String} fuelType String representing the type of fuel
   * @returns Object containing a boolean (true if valid, false if not) and an explanation to display to the client
   */
  determineFuelInformationValidity(volumePumped, volumeUnits, fuelType) {
    volumePumped = parseInt(volumePumped);

    if (
      (!isNaN(volumePumped) && volumePumped > 0) &&
      volumeUnits &&
      fuelType !== ''
    ) {
      return {
        bool: true,
      };
    } else {
      return {
        bool: false,
        explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> Fuelling information is incomplete</div>,
      };
    }
  }

  /**
   * @description Determines if the payment information inputted by client is valid
   * @param {String} totalPaid String representing the amount of money paid by the client
   * @param {String} currency String representing the currency used
   * @param {String} fuelCardFourDigits String representing the fuel card's four digits
   * @returns Object containing a boolean (true if valid, false if not) and an explanation to display to the client
   */
  determinePaymentInformationValidity(totalPaid, currency, fuelCardFourDigits) {
    totalPaid = parseInt(totalPaid);
    fuelCardFourDigits = parseInt(fuelCardFourDigits);

    if (
      !isNaN(totalPaid) && totalPaid > 0 &&
      currency &&
      !isNaN(fuelCardFourDigits)
    ) {
      return {
        bool: true,
      };
    } else {
      return {
        bool: false,
        explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> Payment information is incomplete</div>,
      };
    }
  }

  /**
   * @description Determines if city and stateProvince inputted by client are valid
   * @param {String} city String representing city inputted by client
   * @param {String} stateProvince String representing stateProvince inputted by client
   * @returns Object containing a boolean (true if valid, false if not) and an explanation to display to the client
   */
  determineLocationValidity(city, stateProvince) {
    if (stateProvince) {
      return {
        bool: true,
      };
    } else {
      return {
        bool: false,
        explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> Location information is incomplete</div>,
      };
    }
  }

  render() {
    const { state, props } = this;

    const volumeUnitsItems = Object.keys(VolumeUnitTypes).map(unit => {
      return {
        key: unit,
        label: VolumeUnitNames[unit],
        value: unit,
      };
    });

    const selectedVolumeUnit = volumeUnitsItems.filter(item => {
      const volumeUnit = state.volumeUnits || {};
      return item.value === (volumeUnit || '');
    })[0] || volumeUnitsItems[0];

    const currencyItems = Object.keys(CurrencyTypes).map(currency => {
      return {
        key: CurrencyTypes[currency].short,
        label: CurrencyTypes[currency].short,
        value: CurrencyTypes[currency].short.toLowerCase(),
      };
    });

    const selectedCurrency = currencyItems.filter(currency => {
      const currencyItem = state.currency || {};
      return currency.value === (currencyItem || '');
    })[0] || currencyItems[0];

    const stateProvinceItems = Object.keys(StateProvince).map(stateProvinceKey => {
      const stateProvinceObject = StateProvince[stateProvinceKey];
      return {
        key: stateProvinceObject.code,
        label: stateProvinceObject.name,
        value: stateProvinceObject.code,
      }
    });

    const selectedStateProvince = stateProvinceItems.filter(stateProvince => {
      const stateProvinceItem = state.stateProvince || {};
      return stateProvince.value === (stateProvinceItem || '');
    })[0];

    return (
      <MDBCard className="z-depth-0 text-left">
        <MDBCardBody className="p-0">
          <MDBCardText className="z-depth-0 text-left">Fuel Purchase {props.index}</MDBCardText>
          <MDBCol>

            {/* Date */}
            <div>
              <div className="mt-2" style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'bolder' }}><MDBIcon icon="calendar-alt" className="mr-2" />Date</div>
              {state.errorHash.dateIsValid.bool === false &&
                state.errorHash.dateIsValid.explanation
              }
              <MDBRow>
                <MDBCol>
                  <DateTimePicker
                    label="Date"
                    selectedDate={state.date}
                    onDateChange={(date) => this.setState({ ...this.state, date }, () => this.determineFuelPurchaseFormValidity(this.state, 'date'))}
                    showTimePicker
                    dateFormat="yyyy-MM-dd HH:mm"
                    required
                  />
                </MDBCol>
              </MDBRow>
            </div>

            {/* Fuel Information */}
            <div>
              <div className="mt-2" style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'bolder' }}><MDBIcon icon="gas-pump" className="mr-2" />Fuel Information</div>
              {state.errorHash.fuelInformationIsValid.bool === false &&
                state.errorHash.fuelInformationIsValid.explanation
              }
              <MDBRow className="w-100">

                {/* volume pumped */}
                <MDBCol className="w-10">
                  <MDBInput
                    type="number"
                    min="0"
                    label="Volume Pumped"
                    labelClass="active"
                    value={state.volumePumped}
                    getValue={(volumePumped) => this.setState({ ...this.state, volumePumped }, () => this.determineFuelPurchaseFormValidity(this.state, 'fuel'))}
                  />
                </MDBCol>

                {/* volume unit dropdown */}
                <MDBCol className="w-10">
                  <div className="md-form d-inline-block align-middle mr-1">
                    <SBSelect
                      onClick={(e) => e.stopPropagation()}
                      items={volumeUnitsItems}
                      selectedItems={selectedVolumeUnit ? [selectedVolumeUnit] : []}
                      getSelectedItems={(volumeUnits) => this.setState({ ...this.state, volumeUnits: volumeUnits[0].value }, () => this.determineFuelPurchaseFormValidity(this.state, 'fuel'))}
                      showFilter
                    />
                    <label className="active" data-error data-success>Unit</label>
                  </div>
                </MDBCol>

                {/* fuel type */}
                <MDBCol className="w-10">
                  <div className="d-inline-block align-middle mr-1">
                    <MDBInput
                      label="Fuel Type"
                      labelClass="active"
                      value={state.fuelType}
                      getValue={(fuelType) => this.setState({ ...this.state, fuelType }, () => this.determineFuelPurchaseFormValidity(this.state, 'fuel'))}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
            </div>

            {/* Payment Information */}
            <div>
              <div className="mt-2" style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'bolder' }}><MDBIcon icon="credit-card" className="mr-2" />Payment Information</div>
              {state.errorHash.paymentInformationIsValid.bool === false &&
                state.errorHash.paymentInformationIsValid.explanation
              }
              <MDBRow>

                {/* total paid */}
                <MDBCol>
                  <MDBInput
                    type="number"
                    min="0"
                    label="Total Payment"
                    labelClass="active"
                    value={state.totalPaid}
                    getValue={(totalPaid) => this.setState({ ...this.state, totalPaid }, () => this.determineFuelPurchaseFormValidity(this.state, 'payment'))}
                  />
                </MDBCol>

                {/* currency dropdown */}
                <MDBCol>
                  <div className="md-form d-inline-block align-middle mr-1">
                    <SBSelect
                      onClick={(e) => e.stopPropagation()}
                      items={currencyItems}
                      selectedItems={selectedCurrency ? [selectedCurrency] : []}
                      getSelectedItems={(currency) => this.setState({ ...this.state, currency: currency[0].value }, () => this.determineFuelPurchaseFormValidity(this.state, 'payment'))}
                      showFilter
                    />
                    <label className="active" data-error data-success>Currency</label>
                  </div>
                </MDBCol>

                {/* fuel card final 4 digits */}
                <MDBCol>
                  <MDBInput
                    type="number"
                    min="1000"
                    max="9999"
                    label="Final 4 Fuel Card Digits"
                    labelClass="active"
                    value={state.fuelCardFourDigits}
                    getValue={(fuelCardFourDigits) => this.setState({ ...this.state, fuelCardFourDigits }, () => this.determineFuelPurchaseFormValidity(this.state, 'payment'))}
                  />
                </MDBCol>
              </MDBRow>
            </div>

            {/* Location */}
            <div>
              <div className="mt-2" style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'bolder' }}><MDBIcon icon="compass" className="mr-2" />Location</div>
              {state.errorHash.locationIsValid.bool === false &&
                state.errorHash.locationIsValid.explanation
              }
              <MDBRow>

                {/* city
                <MDBCol>
                  <div className="d-inline-block align-middle mr-1">
                    <MDBInput
                      label="City"
                      labelClass="active"
                      value={state.city}
                      getValue={(city) => this.setState({ ...this.state, city }, () => this.determineFuelPurchaseFormValidity(this.state, 'location'))}
                    />
                  </div>
                </MDBCol> */}

                {/* stateProvince */}
                <MDBCol>
                  <div className="md-form d-inline-block align-middle mr-1">
                    <SBSelect
                      onClick={(e) => e.stopPropagation()}
                      items={stateProvinceItems}
                      selectedItems={selectedStateProvince ? [selectedStateProvince] : []}
                      getSelectedItems={(stateProvince) => this.setState({ ...this.state, stateProvince: stateProvince[0].value }, () => this.determineFuelPurchaseFormValidity(this.state, 'location'))}
                      showFilter
                    />
                    <label className="active" data-error data-success>State/Province</label>
                  </div>
                </MDBCol>
              </MDBRow>
            </div>

            <MDBRow>
              <MDBCol>
                <Divider />
              </MDBCol>
            </MDBRow>

            {/* delete button */}
            <MDBRow>
              <MDBCol className="pt-2">
                <MDBBtn className={'closeButton'} onClick={() => { props.deleteFuelPurchase(props.keyId) }}>
                  Remove Purchase
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBCardBody>
      </MDBCard>
    );
  }
}

export default AddEditFuelPurchasesCard;