import Parse from 'parse';
import axios from 'axios';
import moment from 'moment';
import momentTz from 'moment-timezone';
import momentDurationFormat from 'moment-duration-format';
import * as ParseAPI from './Parse';
import * as Getters from './Getters';
import * as Helpers from './Helpers';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { DrivingELDEventReferenceInts } from 'enums/ELDEventTypeCode';
import { AttributeTypes } from 'enums/MasonVersion';

import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getELDEventDataMap } from 'sb-csapi/dist/api/ELDEvent/ELDEvent';

import { ELDEventTypeCode } from 'sb-csapi/dist/enums/ELD/ELDEventTypeCode';

/** @module ELD */

const dict = {};
dict['1'] = 1; dict['2'] = 2; dict['3'] = 3; dict['4'] = 4; dict['5'] = 5;
dict['6'] = 6; dict['7'] = 7; dict['8'] = 8; dict['9'] = 9; dict.A = 17;
dict.B = 18; dict.C = 19; dict.D = 20; dict.E = 21; dict.F = 22;
dict.G = 23; dict.H = 24; dict.I = 25; dict.J = 26; dict.K = 27;
dict.L = 28; dict.M = 29; dict.N = 30; dict.O = 31; dict.P = 32;
dict.Q = 33; dict.R = 34; dict.S = 35; dict.T = 36; dict.U = 37;
dict.V = 38; dict.W = 39; dict.X = 40; dict.Y = 41; dict.Z = 42;
dict.a = 49; dict.b = 50; dict.c = 51; dict.d = 52; dict.e = 53;
dict.f = 54; dict.g = 55; dict.h = 56; dict.i = 57; dict.j = 58;
dict.k = 59; dict.l = 60; dict.m = 61; dict.n = 62; dict.o = 63;
dict.p = 64; dict.q = 65; dict.r = 66; dict.s = 67; dict.t = 68;
dict.u = 69; dict.v = 70; dict.w = 71; dict.x = 72; dict.y = 73;
dict.z = 74;

/**
 * @memberof module:ELD
 * @param {*} character
 * @returns
 */
function characterToDecimal(character) {
  if (dict[character] !== undefined) {
    return dict[character];
  }
  return 0;
}

/**
 * @memberof module:ELD
 * @param {*} lineEntriesArr
 * @returns
 */
function getLDCV(lineEntriesArr) {
  const characters = lineEntriesArr.join();
  let sumCharacters = 0;
  for (let i = 0; i < characters.length; i++) {
    sumCharacters += characterToDecimal(characters.charAt(i));
  }
  const binary = `00000000${sumCharacters.toString(2)}`;
  const lowerEightBit = binary.substring(binary.length - 8, binary.length);
  const eightBitShifted = parseInt(lowerEightBit.substr(1) + lowerEightBit.substr(0, 1), 2);
  const XORed = eightBitShifted ^ parseInt('96', 16);
  const hex = XORed.toString(16);
  return hex;
}

/**
 * @memberof module:ELD
 * @param {*} ldcvSum
 * @returns
 */
function getFDCV(ldcvSum) {
  const binary = `0000000000000000${ldcvSum.toString(2)}`;
  const lowerEightBit = binary.substring(binary.length - 16, binary.length);
  const sixteenBitShifted = parseInt(lowerEightBit.substr(1) + lowerEightBit.substr(0, 1), 2);
  const XORed = parseInt(sixteenBitShifted, 2) ^ parseInt('969C', 16);
  const hex = XORed.toString(16);
  return hex;
}

/**
 * @memberof module:ELD
 *
 * @param {*} eldEventQuery
 * @param {*} page
 * @param {*} limit
 * @param {*} resolve
 * @param {*} reject
 * @param {*} eldEventsArr
 */
function getELDEventsFromQuerySub(eldEventQuery, page, limit, resolve, reject, eldEventsArr = []) {
  eldEventQuery.limit(limit);
  eldEventQuery.skip(page * limit);

  eldEventQuery.find().then(
    eldEvents => {
      if (eldEvents.length === 0) {
        resolve(eldEventsArr);
      } else {
        const newELDEventsArr = [].concat(eldEventsArr, eldEvents);
        getELDEventsFromQuerySub(eldEventQuery, page + 1, limit, resolve, reject, newELDEventsArr);
      }
    },
    error => reject(error)
  );
}

/**
 * @memberof module:ELD
 * @param {*} eldEventQuery
 * @returns
 */
function getELDEventsFromQuery(eldEventQuery) {
  // function to get all eld events from a given query (no lazy load)
  const promise = new Promise((resolve, reject) => {
    getELDEventsFromQuerySub(eldEventQuery, 0, 1000, resolve, reject);
  });
  return promise;
}

/**
 * @memberof module:ELD
 *
 * @param {*} driver
 * @param {*} onDate
 *
 * @returns
 */
/* This same method exists in log-bundler: in helpers/driver.js */
function getELDDailyCertificationIntervalFromDriverTZ(driver, onDate, timezoneOffsetFromUTC) {
  /*
    Get drivers timezone and apply it to the given date to find the start and end day interval of their daily cert
    NOTE: This is to find the INTERVAL OF TIME the daily cert will be found in, based on the onDate
    It is NOT a conversion function
  */
  let dayOf;
  let dayAfter;

  const _onDate = momentTz(onDate);
  const date = _onDate.date();
  const month = _onDate.month();
  const year = _onDate.year();

  // create 'hard-coded' string that determines what date for the timezone we want to apply on top of, but not convert to
  // ex: Thu Apr 05 2018 21:00:00 GMT-0700 (PDT) -> Thu Apr 05 2018 21:00:00 GMT-0400 (EST)

  // Thu Apr 06 2018 00:00:00 GMT-0700 (PDT)
  const onDateStr = momentTz().set({ year, month, date }).startOf('day').format('YYYY-MM-DD HH:mm:ss');

  // Create Thu Apr 06 2018 00:00:00 GMT-0400 (EST)
  let driverTimeZoneStr = timezoneOffsetFromUTC || driver.get('timezoneOffsetFromUTC');
  let driverOnDateStr = onDateStr;
  let driverOnDate = momentTz(driverOnDateStr);

  if (driverTimeZoneStr) {
    driverOnDateStr = momentTz.tz(onDateStr, 'YYYY-MM-DDTHH:mm:ss', driverTimeZoneStr).format();
    driverOnDate = momentTz(driverOnDateStr);
  } else {
    driverTimeZoneStr = momentTz.tz.guess();
  }

  dayOf = momentTz(driverOnDate).tz(driverTimeZoneStr).toDate();
  dayAfter = momentTz(dayOf).tz(driverTimeZoneStr).add(1, 'day').toDate();
  // console.log(driverOnDate);
  // console.log(driverOnDate.format('YYYY-MM-DD HH:mm:ss'));
  // console.log(driverOnDate.toDate());
  // console.log(driverOnDate.toISOString());
  return { dayOf, dayAfter, timezoneOffsetFromUTC: driverTimeZoneStr };
}

/**
 * @memberof module:ELD
 * @description Take an eldDailyCertification query and figure out (if duplicates) which one to use based
 * on eldEvents.length or timezone differential
 *
 * This function should be held purely for the above purpose (ie. does not return eld events associated with query)
 *
 * @param { parseQuery } eldDailyCertificationParseQuery: The particular eldDailyCertification query
 * @param { object } - Driver
 * @returns { Promise } The resulting eldDailyCertification to be used as acting
 *
 * @private
 */
function getActingELDDailyCertification(eldDailyCertificationParseQuery, driver) {
  const promise = new Promise((resolve, reject) => {
    eldDailyCertificationParseQuery.find().then(
      (eldDailyCertifications) => {
        if (!eldDailyCertifications.length) {
          resolve(undefined);
        } else if (eldDailyCertifications.length === 1) {
          resolve(eldDailyCertifications[0]);
        } else {
          // first check to see if any of the daily certs are of a separate timezone. if so, use the one that is different from the driver's current
          // (this doesnt cover tons of timeszone changes..mostly banking on it having been changed once)
          const timezoneOffsetFromUTC = driver && driver.get('timezoneOffsetFromUTC');
          const differentTimezoneCerts = timezoneOffsetFromUTC && eldDailyCertifications.filter(eldDailyCertification => {
            const dailyCertTimezoneOffsetFromUTC = eldDailyCertification.get('timezoneOffsetFromUTC');
            return dailyCertTimezoneOffsetFromUTC && (timezoneOffsetFromUTC !== dailyCertTimezoneOffsetFromUTC);
          }) || [];

          if (differentTimezoneCerts[0]) {
            resolve(differentTimezoneCerts[0]);
          } else {
            // if not, check to see which daily cert has the longest duration. if only one, use that one
            let filteredELDDailyCertificationsByDuration = [];
            let longestDuration = 0;
            eldDailyCertifications.map((eldDailyCertification, index) => {
              const startTimeUTC = momentTz(eldDailyCertification.get('startTimeUTC'));
              const endTimeUTC = momentTz(eldDailyCertification.get('endTimeUTC'));
              const duration = endTimeUTC.diff(startTimeUTC, 'milliseconds');

              if (duration === longestDuration) {
                // if two+ daily certs with the same duration, reset index to say there isn't a decider
                filteredELDDailyCertificationsByDuration.push(eldDailyCertification);
              } else if (duration > longestDuration) {
                longestDuration = duration;
                filteredELDDailyCertificationsByDuration = []; // reset because we found something that nullifies the previous certs
                filteredELDDailyCertificationsByDuration.push(eldDailyCertification);
              }
            });

            if (filteredELDDailyCertificationsByDuration.length === 1) {
              // found the one singular longest duration eldDailyCertification
              resolve(filteredELDDailyCertificationsByDuration[0]);
            } else {
              // duplicates
              const eldEventPromises = [];

              for (let i = 0; i < filteredELDDailyCertificationsByDuration.length; i++) {
                const eldDailyCertification = filteredELDDailyCertificationsByDuration[i];
                const eldEventQuery = new Parse.Query('ELDEvent');
                eldEventQuery.equalTo('eldDailyCertification', eldDailyCertification);
                eldEventPromises.push(eldEventQuery.find());
              }

              Promise.all(eldEventPromises).then(
                (eldEventsArr) => {
                  // an array of array of eldEvents corresponding to each eldDailyCertification
                  // get the index of the array (hence eldDailyCertification) with the most events
                  let indexReference = 0;
                  let lastArrLen = 0;

                  eldEventsArr.map((arr, index) => {
                    const arrLen = arr.length;
                    if (index === 0) {
                      lastArrLen = arrLen;
                    } else if (arrLen > lastArrLen) {
                      indexReference = index;
                      lastArrLen = arrLen;
                    }
                  });
                  const actingELDDailyCertification = filteredELDDailyCertificationsByDuration[indexReference];
                  resolve(actingELDDailyCertification);
                }
              );
            }
          }
        }
      }
    );
  });
  return promise;
}

/**
 *@memberof module:ELD

 * @param {parseObject} driver
 * @param {Date} onDate - local, non-scaled Date based on calling user
 * @param {boolean} returnFakeELDDailyCertificationOnUndefined - returns a temp/fake ELDDailyCertification in place of non-existent
 */
function getELDDailyCertification(driver, onDate, returnFakeELDDailyCertificationOnUndefined) {
  const promise = new Promise((resolve, reject) => {
    const startEndTimeUTC = getELDDailyCertificationIntervalFromDriverTZ(driver, onDate);
    const dayOf = startEndTimeUTC.dayOf;
    const dayAfter = startEndTimeUTC.dayAfter;

    const eldDailyCertificationQuery = new Parse.Query('ELDDailyCertification');
    eldDailyCertificationQuery.equalTo('driver', driver);
    eldDailyCertificationQuery.notEqualTo('disabled', true);
    eldDailyCertificationQuery.greaterThanOrEqualTo('startTimeUTC', dayOf);
    eldDailyCertificationQuery.lessThan('startTimeUTC', dayAfter);
    eldDailyCertificationQuery.include(['driver']);

    getActingELDDailyCertification(eldDailyCertificationQuery, driver).then(
      eldDailyCertification => {
        if (eldDailyCertification) {
          resolve(eldDailyCertification);
        } else if (!eldDailyCertification && returnFakeELDDailyCertificationOnUndefined) {
          const tempELDDailyCertification = Helpers.createTempParseObject('ELDDailyCertification', {
            driver,
            startTimeUTC: dayOf,
            endTimeUTC: dayAfter,
          });
          resolve(tempELDDailyCertification);
        } else {
          resolve(undefined);
        }
      },
      error => {
        reject(error);
      }
    );
  });
  return promise;
}

/**
 * @description Sets any supplementary eldEventData data on eldEvents that are not native to the ELDEvent table
 *                but logically should be treated as part of the eldEvents
 *
 * @param {Array} eldEvents - Array of eldEvents which should be populated with eldEventData data
 *
 * @returns {Bool} - The updates are in-place, just returns true
 */
async function setELDEventDataOnELDEvents(eldEvents) {
  // NOTE: Be careful which fields should be populated by setting it on the actual record attribute as opposed to the record object.
  //       Any sets on record attributes could have side-effects from accidental saves, which would update the eldEvent(s) outside
  //       of the ELDEvent Edit flow
  try {
    // make sure we pass legit objectIds into getELDEventDataMap()
    const eldEventObjectIds = eldEvents
      .filter(eldEvent => eldEvent && getAttribute(eldEvent, 'objectId', true))
      .map(eldEvent => getAttribute(eldEvent, 'objectId'));

    const eldEventDataMap = await getELDEventDataMap(eldEventObjectIds);

    // for each event, see if there is a corresponding entry in eldEventDataMap. If so, populate data as needed
    for (let i = 0; i < eldEvents.length; i++) {
      const eldEvent = eldEvents[i];
      const eldEventObjectId = getAttribute(eldEvent, 'objectId');
      const eldEventDatum = eldEventDataMap[eldEventObjectId];

      // if there is an updated shipping number, prioritize eldEventData.shippingDocumentNumber over eldEvent.shippingDocumentNumber
      // const shippingDocumentNumber = getAttribute(eldEventDatum, 'shippingDocumentNumber', true);
      // if (shippingDocumentNumber !== undefined) {
      //   eldEvent.shippingDocumentNumber = shippingDocumentNumber;
      // }
    }
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:ELD
 *
 * @param {*} driver
 * @param {*} filter
 * @param {*} dispatcher
 *
 * @returns
 */
function getDriverELDEvents(driver, filter, dispatcher, timezoneOffsetFromUTC, startDate, endDate) {
  // 24hr period example: JAN 24 08:00 -> JAN 25 08:00
  const promise = new Promise((resolve, reject) => {
    // begin by searching eldDailyCertification for driver on given date
    const queryIncludes = [
      // 'eldHardware',
      'eldDailyCertification',
      'eldDailyCertification.hosViolations',
      'vehicle',
      'vehicle.licensePlate',
      'vehicleLocation',
      'driver',
      'driver.user',
      // 'driver.weighStationBypassDriver',
      'coDriver',
      'coDriver.user',
      'company',
    ];
    // apply drivers timezone onto filter date
    const startEndTimeUTC = getELDDailyCertificationIntervalFromDriverTZ(driver, filter.onDate, timezoneOffsetFromUTC);
    const dayOf = startDate || startEndTimeUTC.dayOf;
    const dayAfter = endDate || startEndTimeUTC.dayAfter;

    const eldDailyCertificationQuery = new Parse.Query('ELDDailyCertification');
    eldDailyCertificationQuery.equalTo('driver', driver);
    eldDailyCertificationQuery.notEqualTo('disabled', true);
    eldDailyCertificationQuery.greaterThanOrEqualTo('startTimeUTC', dayOf);
    eldDailyCertificationQuery.lessThan('startTimeUTC', dayAfter);
    getActingELDDailyCertification(eldDailyCertificationQuery, driver)
      .then(async (eldDailyCertification) => {
        const isBeforeMarch2024 = momentTz(dayOf).isBefore('2024-03-25');
        const isAfterDecember2023 = momentTz(dayOf).isAfter('2024-01-01');
        /**
         * We have a situation where startTimeUTC and other daily cert fields are not being filled out
         * correctly by the mobile app in this time period: [isAfterDecember2023, isBeforeMarch2024]
         *
         * Therefore, these certs are not being retrieved properly since they have no startTimeUTC and
         * we need to try and find if there is indeed a cert on dayOf that is missing startTimeUTC
         *
         * This stop-gap solution only occurs in some places and does not need to be replicated anywhere
         * else, since mobile is releasing a fix by late Feb 2024 that should resolve the issue
         */

        if (isAfterDecember2023 && isBeforeMarch2024) {
          const isMissingELDDailyCertification = !eldDailyCertification;
          let isZeroELDEventDay;
          if (eldDailyCertification) {
            const isZeroELDEventDayQuery = new Parse.Query('ELDEvent');
            isZeroELDEventDayQuery.equalTo('eldDailyCertification', eldDailyCertification);
            isZeroELDEventDay = (await isZeroELDEventDayQuery.find()).length === 0;
          }

          if (isMissingELDDailyCertification || isZeroELDEventDay) {
            const missingELDDCStartTimeUTCELDEventQuery = new Parse.Query('ELDEvent');
            missingELDDCStartTimeUTCELDEventQuery.equalTo('driver', driver);
            missingELDDCStartTimeUTCELDEventQuery.greaterThanOrEqualTo('eventDateTime', dayOf);
            missingELDDCStartTimeUTCELDEventQuery.lessThan('eventDateTime', dayAfter);
            missingELDDCStartTimeUTCELDEventQuery.exists('eldDailyCertification');
            missingELDDCStartTimeUTCELDEventQuery.exists('eventDateTime');
            // make sure the event has a valid eventDateTime within 24 hours of what would be eldDailyCertification.startTimeUTC
            missingELDDCStartTimeUTCELDEventQuery.containedIn('eldEventTypeCodeInt', [
              ELDEventTypeCode.OFF_DUTY,
              ELDEventTypeCode.SLEEPER_BERTH,
              ELDEventTypeCode.DRIVING,
              ELDEventTypeCode.ON_DUTY_NOT_DRIVING,
              ELDEventTypeCode.INTERMEDIATE_LOG_WITH_CONVENTIONAL_LOCATION_PRECISION,
              ELDEventTypeCode.INTERMEDIATE_LOG_WITH_REDUCED_LOCATION_PRECISION,
              ELDEventTypeCode.PC_YM_WT_CLEARED,
              ELDEventTypeCode.AUTHORIZED_PERSONAL_USE_OF_CMV,
              ELDEventTypeCode.YARD_MOVES,
            ]);
            missingELDDCStartTimeUTCELDEventQuery.include(['driver', 'eldDailyCertification']);
            const missingELDDCStartTimeUTCELDEvent = await missingELDDCStartTimeUTCELDEventQuery.first();

            if (missingELDDCStartTimeUTCELDEvent) {
              // we found a reference event with a daily cert for this day
              const eventDateTime = getAttribute(missingELDDCStartTimeUTCELDEvent, 'eventDateTime');
              const missingELDDCStartTimeUTCELDEventDriver = getAttribute(missingELDDCStartTimeUTCELDEvent, 'driver');
              const belongsToCompany = getAttribute(missingELDDCStartTimeUTCELDEvent, 'belongsToCompany');

              const missingELDDCStartTimeUTCELDEventTimezoneOffsetFromUTC = getAttribute(driver, 'timezoneOffsetFromUTC') || momentTz.tz.guess();
              let startTimeUTC = momentTz(eventDateTime).tz(missingELDDCStartTimeUTCELDEventTimezoneOffsetFromUTC).startOf('day');
              let endTimeUTC = momentTz(startTimeUTC).add(1, 'day');
              startTimeUTC = startTimeUTC.toDate();
              endTimeUTC = endTimeUTC.toDate();

              eldDailyCertification = getAttribute(missingELDDCStartTimeUTCELDEvent, 'eldDailyCertification');
              eldDailyCertification.set('startTimeUTC', startTimeUTC);
              eldDailyCertification.set('endTimeUTC', endTimeUTC);
              eldDailyCertification.set('driver', missingELDDCStartTimeUTCELDEventDriver);
              eldDailyCertification.set('belongsToCompany', belongsToCompany);
            }

            // 2024-08-07 company wants this driver's logs temporarily not shown, so no backup logic should run
            // REMOVE this conditional when the company is done their thing PS-1243
            if (getAttribute(driver, 'objectId') === 'bXA479CKjj') {
              eldDailyCertification = undefined;
            }
          }

        }

        if (eldDailyCertification) {
          // if there is an eld daily cert, find all events with it
          const eldEventQuery = new Parse.Query('ELDEvent');
          eldEventQuery.include(queryIncludes);
          eldEventQuery.descending('eventDateTime');
          eldEventQuery.equalTo('eldDailyCertification', eldDailyCertification);
          eldEventQuery.equalTo('company', driver.get('belongsToCompany'));
          Promise.all([Getters.getAllFromQuery(eldEventQuery),
          getELDEdits(eldDailyCertification, undefined, [0, 3, 4, 5], driver),
          getAssociatedELDEvents(driver, eldDailyCertification, false, true)])
            .then(async (eventsAndEdits) => {
              let eldEvents = eventsAndEdits[0];
              const eldEdits = eventsAndEdits[1];
              const associatedELDEvents = eventsAndEdits[2];
              // if somehow there are no eld events this day (where dailycert exists) but there is an edit, use edit events
              if (eldEvents.length === 0) {
                let eldEditRequestedEvents = [];
                for (let i = 0; i < eldEdits.length; i++) {
                  eldEditRequestedEvents = eldEditRequestedEvents.concat(eldEdits[i].get('requestedELDEvents') || []);
                }
                // sort descending
                sortELDEvents(eldEditRequestedEvents, 2);
                eldEvents = eldEvents.concat(eldEditRequestedEvents);
              }

              // get the first active eldevent, if it is an interm and the last known event was not a driving type event
              // then set the duty status to that of the last known event (because there should be no interm events succeeding non-driving)
              for (let i = eldEvents.length - 1; i > -1; i--) {
                const eldEvent = eldEvents[i];
                const eldEventTypeCodeInt = eldEvent.get('eldEventTypeCodeInt');
                const isActiveEvent = [0, 1].indexOf(eldEvent.get('eldEventRecordStatusInt')) !== -1;
                const isDutyStatusEvent = [11, 12, 13, 14, 21, 22, 31, 32].indexOf(eldEventTypeCodeInt) !== -1;
                if (isActiveEvent && isDutyStatusEvent) { // break on the first active duty status event we find
                  const isELDEventInterm = [21, 22].indexOf(eldEventTypeCodeInt) !== -1;
                  if (isELDEventInterm && associatedELDEvents[0] && (associatedELDEvents[0].get('eldEventTypeCodeInt') !== 13)) {
                    // if the first active duty status we find happens to be interm, and the previous found event was not driving, change the duty status
                    const clonedELDEvent = eldEvent.clone();
                    clonedELDEvent.set('eldEventTypeCodeInt', associatedELDEvents[0].get('eldEventTypeCodeInt'));
                    clonedELDEvent.set('eldEventRecordStatusInt', associatedELDEvents[0].get('eldEventRecordStatusInt'));
                    clonedELDEvent.set('eldEventRecordOriginInt', associatedELDEvents[0].get('eldEventRecordOriginInt'));
                    eldEvents.splice(i, 0, clonedELDEvent); // insert the fake ELDEvent into the list
                  }
                  break;
                }
              }

              // at this point, if the first active event is a driving type event (ex. driving through midnight), treat it as autogenerated
              for (let i = eldEvents.length - 1; i > -1; i--) {
                const eldEvent = eldEvents[i];
                const eldEventTypeCodeInt = eldEvent.get('eldEventTypeCodeInt');
                const isActiveEvent = [0, 1].indexOf(eldEvent.get('eldEventRecordStatusInt')) !== -1;
                const isDutyStatusEvent = [11, 12, 13, 14, 21, 22, 31, 32].indexOf(eldEventTypeCodeInt) !== -1;
                if (isActiveEvent && isDutyStatusEvent) { // break on the first active duty status event we find
                  const isDrivingTypeELDEvent = DrivingELDEventReferenceInts.indexOf(eldEventTypeCodeInt) !== -1;
                  const associatedELDEvent = associatedELDEvents[0];
                  const isAssociatedELDEventDrivingTypeELDEvent = associatedELDEvent && (DrivingELDEventReferenceInts.indexOf(associatedELDEvent.get('eldEventTypeCodeInt')) !== -1);
                  // const associatedELDEventEventDateTime = associatedELDEvent && associatedELDEvent.get('eventDateTime');
                  // const isWithinAnHour = associatedELDEventEventDateTime && (momentTz(eldEvent.get('eventDateTime')).diff(associatedELDEventEventDateTime) / 1000 / 60 / 60) <= 1;

                  if (isDrivingTypeELDEvent && isAssociatedELDEventDrivingTypeELDEvent) {
                    const clonedELDEvent = eldEvent.clone();
                    clonedELDEvent.set('eldEventRecordOriginInt', 1);
                    eldEvents.splice(i, 0, clonedELDEvent); // insert the fake ELDEvent into the list
                  }
                  break;
                }
              }

              await setELDEventDataOnELDEvents(eldEvents);

              resolve({ eldEvents, eldDailyCertification, eldEdits, associatedELDEvents });
            }
            );
        } else {
          const previousELDDailyCertificationQuery = new Parse.Query('ELDDailyCertification');
          previousELDDailyCertificationQuery.equalTo('driver', driver);
          previousELDDailyCertificationQuery.notEqualTo('disabled', true);
          previousELDDailyCertificationQuery.lessThan('startTimeUTC', dayOf);
          previousELDDailyCertificationQuery.descending('startTimeUTC');
          previousELDDailyCertificationQuery.limit(1);
          const nextELDDailyCertificationQuery = new Parse.Query('ELDDailyCertification');
          nextELDDailyCertificationQuery.equalTo('driver', driver);
          nextELDDailyCertificationQuery.notEqualTo('disabled', true);
          nextELDDailyCertificationQuery.greaterThanOrEqualTo('startTimeUTC', dayAfter);
          nextELDDailyCertificationQuery.ascending('startTimeUTC');
          nextELDDailyCertificationQuery.limit(1);
          Promise.all([
            getActingELDDailyCertification(previousELDDailyCertificationQuery, driver),
            getActingELDDailyCertification(nextELDDailyCertificationQuery, driver),
          ]).then(eldDailyCertifications => {
            const previousELDDailyCertification = eldDailyCertifications[0];
            const nextELDDailyCertification = eldDailyCertifications[1];
            // no daily cert exists so we create a fake one (identified by no id)
            const simulatedELDDailyCertification = Helpers.createTempParseObject('eldDailyCertification', {
              driver, // do not set startTimeUTC yet, we try to figure it out first
            });
            // search to see if theres an edit for this day. if there is obtain it and return it along with the requested events
            getELDEdits(undefined, filter.onDate, [0, 3, 4, 5], driver)
              .then(async (eldIncompleteEdits) => {
                const eldEdits = eldIncompleteEdits;
                let eldEditRequestedEvents = [];
                for (let i = 0; i < eldEdits.length; i++) {
                  const eldEdit = eldEdits[i];
                  if (eldEdit.get('eldDailyCertificationStartTimeUTC')) {
                    simulatedELDDailyCertification.set('startTimeUTC', eldEdit.get('eldDailyCertificationStartTimeUTC'));
                  }
                  eldEditRequestedEvents = eldEditRequestedEvents.concat(eldEdit.get('requestedELDEvents') || []);
                }
                // if still no startTimeUTC, check if we can obtain it from previousELDDailyCert or nextELDDailyCert
                // if neither, default to using dayOf
                if (!simulatedELDDailyCertification.get('startTimeUTC')) {
                  const driverTimeZoneStr = timezoneOffsetFromUTC || driver.get('timezoneOffsetFromUTC') || momentTz.tz.guess(); // if no timezone, guess local
                  let simulatedStartTimeUTC = momentTz(dayOf); // recall dayOf is local time already scaled to driver timezone, so treat as-is
                  const driverOfStartOfDay = momentTz(dayOf).tz(driverTimeZoneStr).startOf('day');
                  let dayDifference = 0;
                  if (previousELDDailyCertification) {
                    const previousELDDailyCertificationStartTimeUTC = momentTz(previousELDDailyCertification.get('startTimeUTC')).tz(driverTimeZoneStr);
                    /*
                      Find out how many days difference there is between the referenced daily cert and the one we're trying to make
                      Then add the difference to the referenced daily certs start time. The reason we do it this way is because it properly
                      scales and adds any daylight savings times that carry over (ex. if our last referenced cert was in December, but we
                      want to make a daily cert after March 11, we need to properly carry over the hour lost)
                      Ensure these calculations are all done from the drivers perspective. Only the final result will be localized
                    */
                    const previousELDDailyCertificationStartOfDay = momentTz(previousELDDailyCertificationStartTimeUTC).tz(driverTimeZoneStr).startOf('day');
                    dayDifference = driverOfStartOfDay.diff(previousELDDailyCertificationStartOfDay, 'day');
                    previousELDDailyCertificationStartTimeUTC.add(dayDifference, 'day');
                    simulatedStartTimeUTC = previousELDDailyCertificationStartTimeUTC;
                  } else if (nextELDDailyCertification) {
                    const nextELDDailyCertificationStartTimeUTC = momentTz(nextELDDailyCertification.get('startTimeUTC')).tz(driverTimeZoneStr);
                    const nextELDDailyCertificationStartOfDay = momentTz(nextELDDailyCertificationStartTimeUTC).tz(driverTimeZoneStr).startOf('day');
                    dayDifference = driverOfStartOfDay.diff(nextELDDailyCertificationStartOfDay, 'day');
                    nextELDDailyCertificationStartTimeUTC.add(dayDifference, 'day');
                    simulatedStartTimeUTC = nextELDDailyCertificationStartTimeUTC;
                  }
                  simulatedStartTimeUTC = momentTz(simulatedStartTimeUTC).toDate();
                  simulatedELDDailyCertification.set('startTimeUTC', simulatedStartTimeUTC);
                }
                // sort descending
                sortELDEvents(eldEditRequestedEvents, 2);
                const eldEvents = [].concat(eldEditRequestedEvents);

                await setELDEventDataOnELDEvents(eldEvents);

                resolve({ eldEvents, previousELDDailyCertification, eldDailyCertification: simulatedELDDailyCertification, nextELDDailyCertification, eldEdits });
              });
          });
        }
      }).catch(error => reject(error));
  });

  return promise;
}

/**
 * @memberof module:ELD
 * @param {*} driverParseObj
 * @returns
 */
const getELDCertifications = async (driverParseObj, _startDate, _endDate) => {
  const eldDailyCertificationQuery = new Parse.Query('ELDDailyCertification');
  eldDailyCertificationQuery.equalTo('driver', driverParseObj);
  const startDate = momentTz().subtract(64, 'days').toDate();
  const now = momentTz().toDate();
  eldDailyCertificationQuery.notEqualTo('disabled', true);
  eldDailyCertificationQuery.greaterThanOrEqualTo('startTimeUTC', _startDate || startDate);
  eldDailyCertificationQuery.lessThan('startTimeUTC', _endDate || now);
  eldDailyCertificationQuery.descending('startTimeUTC');
  const eldDailyCertificationArr = await eldDailyCertificationQuery.find();
  // Bin the eldDailyCerts by start time which is always set exactly at Midnight UTC (so certs on same day will match)
  const dailyCertificationMap = {};
  eldDailyCertificationArr.forEach(eldDailyCert => {
    const key = eldDailyCert.get('startTimeUTC').getTime();
    if (key) {
      if (!dailyCertificationMap[key]) dailyCertificationMap[key] = [];
      dailyCertificationMap[key].push(eldDailyCert);
    }
  });
  // Sort them in descending start time order and promote the most useful cert
  const dailyCertificationMapKeys = Object.keys(dailyCertificationMap).map(timeString => parseInt(timeString, 10));
  dailyCertificationMapKeys.sort((a, b) => b - a);
  //  console.dir(Object.values(dailyCertificationMap).map(arr => arr.map(record => [record.id, record.get('onDutyConsumed')])));
  const deduplicatedDailyCertificationArr = dailyCertificationMapKeys.map(key => {
    const certArr = dailyCertificationMap[key];
    // The certArr can not be zero length, if it just holds one cert for the day we are done
    if (certArr.length === 1) return certArr[0];

    // We will select maximum onDutyConsumed as a heuristic for determining the 'legit' daily cert
    // From talking to mobile:
    //   There should be only one cert (the correct one) whose onDutyConsumed is populated and the rest are 0

    const onDutyConsumedArr = certArr.map(cert => (cert.get('onDutyConsumed') || 0)); // or 0 for undefined cases
    // const driveConsumedList = certArr.map(cert => cert.get('driveConsumed'));
    let max = 0;
    // Initialize bestFitCert to the first. If all have onDuty as 0 then it's ok the value should be 0 anywho
    let bestFitCert = certArr[0];
    certArr.forEach((cert, index) => {
      const currOnDutyConsumed = onDutyConsumedArr[index];
      if (currOnDutyConsumed > max) {
        max = currOnDutyConsumed;
        bestFitCert = cert;
      }
    });
    return bestFitCert;
  });
  return deduplicatedDailyCertificationArr;
}

/**
 * @memberof module:ELD
 *
 * @param {*} eldDailyCertificationQuery
 * @param {*} page
 * @param {*} limit
 * @param {*} resolve
 * @param {*} reject
 * @param {*} eldDailyCertificationArr
 */
function getUncertifiedLogDriversSub(eldDailyCertificationQuery, page, limit, resolve, reject, eldDailyCertificationArr = []) {
  eldDailyCertificationQuery.limit(limit);
  eldDailyCertificationQuery.skip(page * limit);

  eldDailyCertificationQuery.find().then(
    eldDailyCertifications => {
      if (eldDailyCertifications.length === 0) {
        // now we have all daily certifications where uncertified
        const companyPointer = Parse.User.current().get('belongsToCompany');
        const eldEventQuery = new Parse.Query('ELDEvent');
        eldEventQuery.equalTo('company', companyPointer);
        eldEventQuery.containedIn('eldDailyCertification', eldDailyCertificationArr);
        eldEventQuery.include(['driver', 'driver.user', 'eldDailyCertification']);
        getELDEventsFromQuery(eldEventQuery).then(
          eldEvents => {
            // now that we have all events of daily certification uncertified, remove duplicate daily certifications
            // and push an object of { driver, coDriver, dailyCertification } to uncertifiedLogArray
            const seenIds = {}; // keep track of which dailycertifications have been seen
            const uncertifiedLogArray = [];
            for (let i = 0; i < eldEvents.length; i++) {
              const eldEvent = eldEvents[i];
              if (!seenIds[eldEvent.get('eldDailyCertification').id]) {
                seenIds[eldEvent.get('eldDailyCertification').id] = true;
                uncertifiedLogArray.push({
                  driver: eldEvent.get('driver'),
                  coDriver: eldEvent.get('coDriver'),
                  eldDailyCertification: eldEvent.get('eldDailyCertification'),
                });
              }
            }

            // now we have an array of unique eld events, each representing a daily certification
            resolve(uncertifiedLogArray);
          },
          error => reject(error)
        );
      } else {
        const newELDDailyCertificationArr = [].concat(eldDailyCertificationArr, eldDailyCertifications);
        getUncertifiedLogDriversSub(eldDailyCertificationQuery, page + 1, limit, resolve, reject, newELDDailyCertificationArr);
      }
    },
    error => reject(error)
  );
}

/**
 * @memberof module:ELD
 * @returns
 */
function getUncertifiedLogDrivers() {
  // get drivers who have not yet signed off on their logs yet
  // Get all daily certifications where certified is false
  // returns objects containing driver, co-driver, and eldDailyCertifications in which certifications are false
  const promise = new Promise((resolve, reject) => {
    const eldDailyCertificationQuery = new Parse.Query('ELDDailyCertification');
    eldDailyCertificationQuery.equalTo('certified', false);
    getUncertifiedLogDriversSub(eldDailyCertificationQuery, 0, 1000, resolve, reject);
  });
  return promise;
}

/**
 * @memberof module:ELD
 *
 * @param {*} driverObjectId
 * @param {*} onDate
 *
 * @returns
 */
function checkShouldDisableEdit(driverObjectId, onDate) {
  // check to see if ELDEdit-ing feature should be disabled for ud time
  // obtain the correct eldDailyCertification and match against ELDEdit
  // if there is an ELDEdit with completed = false, disable editing until all
  // edits are resolved
  // Return TRUE if should disable, FALSE if shouldn't
  const promise = new Promise((resolve, reject) => {
    const driverQuery = new Parse.Query('Driver');
    driverQuery.equalTo('objectId', driverObjectId);

    driverQuery.first().then(
      driver => {
        const startEndTimeUTCInterval = getELDDailyCertificationIntervalFromDriverTZ(driver, onDate);
        const dayOf = startEndTimeUTCInterval.dayOf;
        const dayAfter = startEndTimeUTCInterval.dayAfter;

        const eldDailyCertificationQuery = new Parse.Query('ELDDailyCertification');
        eldDailyCertificationQuery.equalTo('driver', driver);
        eldDailyCertificationQuery.greaterThanOrEqualTo('startTimeUTC', dayOf);
        eldDailyCertificationQuery.lessThan('startTimeUTC', dayAfter);
        eldDailyCertificationQuery.descending('requestedAt');

        eldDailyCertificationQuery.first().then(
          eldDailyCertification => {
            if (eldDailyCertification) {
              // if the eldDailyCertification 24hrs is not done yet, do not allow edits
              const endTimeUTC = momentTz(eldDailyCertification.get('startTimeUTC')).add(24, 'hour');
              if (endTimeUTC.valueOf() > momentTz().valueOf()) {
                resolve(true);
              } else {
                getELDEdits(eldDailyCertification, undefined, [0, 3, 4, 5], driver).then(
                  eldEdits => {
                    if (eldEdits.length > 0) {
                      resolve(true);
                    } else {
                      resolve(false);
                    }
                  }
                );
              }
            } else {
              // no daily cert
              getELDEdits(undefined, onDate, [0, 3, 4, 5], driver).then(
                eldEdits => {
                  if (eldEdits.length > 0) {
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                }
              );
            }
          },
          error => reject(error)
        );
      },
      (error) => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:ELD
 * @param {*} referenceInt
 * @returns
 */
function getELDEventRecordOrigin(referenceInt) {
  const eldEventRecordOriginQuery = new Parse.Query('ELDEventRecordOrigin');
  if (referenceInt !== undefined) {
    eldEventRecordOriginQuery.equalTo('referenceInt', referenceInt);
    return eldEventRecordOriginQuery.first();
  }
  return eldEventRecordOriginQuery.find();
}

/**
 * @memberof module:ELD
 * @param {*} referenceInt
 * @returns
 */
function getELDEventRecordStatus(referenceInt) {
  const eldEventRecordStatusQuery = new Parse.Query('ELDEventRecordStatus');
  if (referenceInt !== undefined) {
    eldEventRecordStatusQuery.equalTo('referenceInt', referenceInt);
    return eldEventRecordStatusQuery.first();
  }
  return eldEventRecordStatusQuery.find();
}

/**
 * @memberof module:ELD
 * @param {*} referenceInt
 * @returns
 */
function getELDEventTypeCode(referenceInt) {
  const eldEventTypeCodeQuery = new Parse.Query('ELDEventTypeCode');
  if (referenceInt !== undefined) {
    eldEventTypeCodeQuery.equalTo('referenceInt', referenceInt);
    return eldEventTypeCodeQuery.first();
  }
  return eldEventTypeCodeQuery.find();
}

/**
 * @memberof module:ELD
 * @param {*} code
 * @returns
 */
function getELDMalfunctionDataCode(code) {
  const eldMalfunctionDataCodeQuery = new Parse.Query('ELDMalfunctionDataCode');
  if (code !== undefined) {
    eldMalfunctionDataCodeQuery.equalTo('code', code);
    return eldMalfunctionDataCodeQuery.first();
  }
  return eldMalfunctionDataCodeQuery.find();
}

/**
 * @memberof module:ELD
 * @returns
 */
function getELDStatuses() {
  // get the ELD status relation
  const eldStatusQuery = new Parse.Query('ELDStatus');
  return eldStatusQuery.find();
}

/**
 * @memberof module:ELD
 * @param {*} eldViolationTypeInt
 * @returns
 */
function getELDViolationType(eldViolationTypeInt) {
  if (eldViolationTypeInt === 1) {
    return 'Cycle Violation';
  } else if (eldViolationTypeInt === 2) {
    return 'On Duty Time Violation';
  } else if (eldViolationTypeInt === 3) {
    return 'Driving Time Violation';
  }
  return '';
}

/**
 * @memberof module:ELD
 *
 * @param {*} referenceTable
 * @param {*} referenceInt
 * @param {*} code
 * @returns
 */
function getTypeFromELDReferenceTable(referenceTable, referenceInt, code) {
  // referenceTable refers to array of objects of a db eld reference table
  // given this table, find the object w/ matching referenceInt and return type
  for (let i = 0; i < referenceTable.length; i++) {
    const record = referenceTable[i];
    if (referenceInt) {
      if (record.get('referenceInt') && record.get('referenceInt') === referenceInt) {
        return record.get('type');
      }
    } else if (code) {
      if (record.get('code') && record.get('code') === code) {
        return record.get('type');
      }
    }
  }
  return 'Could not retrieve Type';
}

/**
 * @memberof module:ELD
 * @param {*} int
 * @returns
 */
function matchELDEventTypeCode(int) {
  const ELDEventTypeCodeDict = {};
  ELDEventTypeCodeDict[11] = 'Driver\'s duty status changed to "Off-Duty"'; ELDEventTypeCodeDict[12] = 'Driver\'s duty status changed to "Sleeper Berth"'; ELDEventTypeCodeDict[13] = 'Driver\'s duty status changed to "Driving"'; ELDEventTypeCodeDict[14] = 'Driver\'s duty status changed to "On-Duty not driving"';
  ELDEventTypeCodeDict[21] = 'Intermediate log with conventional location precision'; ELDEventTypeCodeDict[22] = 'Intermediate log with reduced location precision';
  ELDEventTypeCodeDict[30] = 'Driver\'s indication for PC, YM, and WT cleared'; ELDEventTypeCodeDict[31] = 'Driver indicates "Authorized Personal Use of CMV"'; ELDEventTypeCodeDict[32] = 'Driver indicates "Yard Moves"';
  ELDEventTypeCodeDict[41] = 'Driver\'s first certification of a daily record'; ELDEventTypeCodeDict[42] = 'Driver\'s second certification of a daily record'; ELDEventTypeCodeDict[43] = 'Driver\'s third certification of a daily record'; ELDEventTypeCodeDict[44] = 'Driver\'s fourth certification of a daily record'; ELDEventTypeCodeDict[45] = 'Driver\'s fifth certification of a daily record'; ELDEventTypeCodeDict[46] = 'Driver\'s sixth certification of a daily record'; ELDEventTypeCodeDict[47] = 'Driver\'s seventh certification of a daily record'; ELDEventTypeCodeDict[48] = 'Driver\'s eighth certification of a daily record'; ELDEventTypeCodeDict[49] = 'Driver\'s ninth certification of a daily record';
  ELDEventTypeCodeDict[51] = 'Authenticated driver\'s ELD login activity'; ELDEventTypeCodeDict[52] = 'Authenticated driver\'s ELD logout activity';
  ELDEventTypeCodeDict[61] = 'Engine power-up with conventional location precision'; ELDEventTypeCodeDict[62] = 'Engine power-up with reduced location precision'; ELDEventTypeCodeDict[63] = 'Engine shut-down with conventional location precision'; ELDEventTypeCodeDict[64] = 'Engine shut-down with reduced location precision';
  ELDEventTypeCodeDict[71] = 'An ELD malfunction logged'; ELDEventTypeCodeDict[72] = 'An ELD malfunction cleared'; ELDEventTypeCodeDict[73] = 'A data diagnostic event logged'; ELDEventTypeCodeDict[74] = 'A data diagnostic event cleared';

  return ELDEventTypeCodeDict[int];
}

/**
 * @memberof module:ELD
 * @param {*} int
 * @returns
 */
function matchELDEventRecordStatus(int) {
  const ELDEventRecordStatusDict = {};
  ELDEventRecordStatusDict[0] = '';
  ELDEventRecordStatusDict[1] = 'Active';
  ELDEventRecordStatusDict[2] = 'Inactive - Changed';
  ELDEventRecordStatusDict[3] = 'Inactive - Change Requested';
  ELDEventRecordStatusDict[4] = 'Inactive - Change Rejected';

  return ELDEventRecordStatusDict[int];
}

/**
 * @memberof module:ELD
 * @returns
 */
function getDefectiveELDEvents() {
  // get eldEvents (containing drivers) with a defective ELD. begin by obtaining record status === active, then check for eldMalfunction exists
  const promise = new Promise((resolve, reject) => {
    const eldMalfunctionDataCodeQuery = new Parse.Query('ELDMalfunctionDataCode');
    eldMalfunctionDataCodeQuery.find().then(
      eldMalfunctionDataCode => {
        const eldMalfunctionDataCodeCodes = [];
        for (let i = 0; i < eldMalfunctionDataCode.length; i++) {
          eldMalfunctionDataCodeCodes.push(eldMalfunctionDataCode[i].get('code'));
        }
        const eldEventQuery = new Parse.Query('ELDEvent');
        eldEventQuery.equalTo('company', Parse.User.current().get('belongsToCompany'));
        eldEventQuery.containedIn('eldMalfunctionDataCodeCode', eldMalfunctionDataCodeCodes);
        eldEventQuery.equalTo('eldEventRecordStatusInt', 1);
        eldEventQuery.include(['driver', 'driver.user', 'eldHardware', 'eldMalfunctionDataCode']);
        getELDEventsFromQuery(eldEventQuery, 0, 1000, resolve, reject).then(
          activeMalfunctionEvents => {
            resolve(activeMalfunctionEvents);
          },
          error => reject(error)
        );
      },
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:ELD
 *
 * @param {*} driver
 * @param {*} editRequests
 * @param {*} defaultELDEventTypeCodeInt
 * @param {*} eldEventsIdString
 * @param {*} returnPreview
 * @param {*} editType
 *
 * @returns
 */
function requestELDEdits(driver, editRequests, defaultELDEventTypeCodeInt, eldEventsIdString, returnPreview, editType, isSupport) {
  // assumes all eld event ids in eldEventsIdString are of the same day/daily certification as it should be
  const promise = new Promise((resolve, reject) => {
    const sanitizedEditRequests = [];
    for (let i = 0; i < editRequests.length; i++) {
      const sanitizedEditRequest = {
        ...editRequests[i],
        referenceVehicleLocationObj: editRequests[i]?.referenceVehicleLocationObj?.toJSON(),
      };
      sanitizedEditRequests.push(sanitizedEditRequest);
    }
    if (driver && driver.get('belongsToCompany').id !== ParseAPI.getCurrentUser().get('belongsToCompany').id) {
      Getters.queryCompanyObjects('_User', undefined, undefined,
        [
          { queryType: 'matchesQuery', name: 'belongsToCompany', innerQueryType: 'equalTo', innerQueryClass: 'Company', innerQueryProperty: 'objectId', innerQueryValue: driver.get('belongsToCompany').id },
          { queryType: 'containedIn', name: 'userType', value: [4] },
        ], undefined, undefined, true, true, undefined)
        .then((dispatcherUser) => {
          Parse.Cloud.run('requestELDEdits', {
            callingUserId: dispatcherUser.id,
            editRequests: sanitizedEditRequests,
            defaultELDEventTypeCodeInt,
            eldEventsIdString,
            returnPreview,
            editType,
            isSupport,
          }).then(eldEditResponse => {
            resolve(eldEditResponse);
          });
        });
    } else {
      Parse.Cloud.run('requestELDEdits', {
        editRequests: sanitizedEditRequests,
        defaultELDEventTypeCodeInt,
        eldEventsIdString,
        returnPreview,
        editType,
        isSupport
      }).then(eldEditResponse => {
        resolve(eldEditResponse);
      });
    }
  });
  return promise;
}

/**
 * @memberof module:ELD
 *
 * @param {*} driver
 * @param {*} editType
 * @param {*} propertyChangesByEldEventId
 *
 * @returns
 */
function requestSingleELDEventEdits(driver, editType, propertyChangesByEldEventId) {
  // clone the events given in propertyChangesByEldEventId and make new objects with the values to be changed
  const promise = new Promise((resolve, reject) => {
    if (driver && driver.get('belongsToCompany').id !== ParseAPI.getCurrentUser().get('belongsToCompany').id) {
      Getters.queryCompanyObjects('_User', undefined, undefined,
        [
          { queryType: 'matchesQuery', name: 'belongsToCompany', innerQueryType: 'equalTo', innerQueryClass: 'Company', innerQueryProperty: 'objectId', innerQueryValue: driver.get('belongsToCompany').id },
          { queryType: 'containedIn', name: 'userType', value: [4] },
        ], undefined, undefined, true, true, undefined)
        .then((dispatcherUser) => {
          Parse.Cloud.run('requestSingleELDEventEdits', { driverId: driver.id, editType, callingUserId: dispatcherUser.id, propertyChangesByEldEventId }).then(eldEditResponse => {
            resolve(eldEditResponse);
          });
        });
    } else {
      Parse.Cloud.run('requestSingleELDEventEdits', { driverId: driver.id, editType, propertyChangesByEldEventId }).then(eldEditResponse => {
        resolve(eldEditResponse);
      });
    }
  });
  return promise;
}

/**
 * @memberof module:ELD
 *
 * @param {*} actionType
 * @param {*} editRequests
 * @param {*} eventToBeUpdated
 * @param {*} editNote
 * @param {*} startingStatus
 * @param {*} onDate
 * @param {*} returnPreview
 *
 * @returns
 */
function eldEditHandler(actionType, editRequests, eventToBeUpdated, editNote, startingStatus, onDate, returnPreview) {
  // type 0: insert; type 1: update; type 2: driving time swap
  // eldDeriveObject contains attr-values we wish to derive/update ELD events from depending on action type
  const promise = new Promise((resolve, reject) => {
    // only usable for which the event record status is 1
    const requestObject = { actionType, editRequests, note: editNote, startingStatus, onDate, returnPreview };
    if (eventToBeUpdated) {
      requestObject.eventToBeUpdatedId = eventToBeUpdated.id;
    }
    Parse.Cloud.run('eldHandleEdits', requestObject).then(
      eldEventOrELDEdit => {
        resolve(eldEventOrELDEdit);
      },
      error => {
        reject(error);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:ELD
 * @param {*} event
 * @returns
 */
function isEventOfDriverIndicationTypeCode(event) {
  // if the event type code is a driver indicated event (yard move/personal cmv)
  const eldEventTypeCodeInt = event.get('eldEventTypeCodeInt');
  return eldEventTypeCodeInt === 31 || eldEventTypeCodeInt === 32;
}

/**
 * @memberof module:ELD
 * @param {*} mostRecentEventParseObj
 * @returns
 */
function getFormattedELDFileName(mostRecentEventParseObj) {
  // Get first five letters of last name (if < 5 then use '_');
  const lastName = mostRecentEventParseObj.get('driverLastName');
  const lastNameLen = lastName.length;
  const lastNameFive = lastNameLen > 5 ? lastName.substring(lastNameLen - 5, lastNameLen) : `${lastName}${'_'.repeat(5 - lastNameLen)}`;

  // get last two digits of drivers license;
  const driversLicense = mostRecentEventParseObj.get('driverLicenseNumber');
  const driversLicenseLastTwo = driversLicense.substring(driversLicense.length - 2, driversLicense.length);

  // get (2 digits) sum of all numeric digits in license # (if > 99, use last two digits; if < 10, first digit is 0);
  let licenseSumLastTwo = 0;
  for (let i = 0; i < driversLicense.length; i++) {
    if (driversLicense.charAt(i) >= '0' && driversLicense.charAt(i) <= '9') {
      licenseSumLastTwo += parseInt(driversLicense.charAt(i), 10);
    }
  }
  if (licenseSumLastTwo > 99) { licenseSumLastTwo = licenseSumLastTwo.substring(licenseSumLastTwo.length - 2, licenseSumLastTwo.length); } else if (licenseSumLastTwo < 10) { licenseSumLastTwo = `0${licenseSumLastTwo}`; }

  // get date (MMDDYY);
  const dateString = momentTz().format('MMDDYY');

  // get 000000000 to ZZZZZZZZZ (9 characters can be used to create uniqueness, use own formula w/e)
  const unique = mostRecentEventParseObj.id.substring(mostRecentEventParseObj.id.length - 9, mostRecentEventParseObj.id.length).toUpperCase();

  // Return complete
  return `${lastNameFive}${driversLicenseLastTwo}${licenseSumLastTwo}${dateString}-${unique}`;
}

/**
 * @memberof module:ELD
 *
 * @param {*} latestEventParseObj
 * @param {*} comment
 *
 * @returns
 */
function getELDHeaderSegment(latestEventParseObj, comment) {
  const x = latestEventParseObj;
  const headerSegment = { array: [], sumLDCV: 0 };
  let lineEntryArr = [];
  const twentyFourHourStartTime = x.get('eldDailyCertification') ? (x.get('eldDailyCertification').get('startTimeUTC') ? momentTz(x.get('eldDailyCertification').get('startTimeUTC')).format('HHmmss') : '000000') : '000000';
  const multiDayBasis = x.get('eldDailyCertification') ? x.get('eldDailyCertification').get('multiDayBasis') : undefined;
  const exemptDriverConfiguration = x.get('driver') ? (x.get('driver').get('exempt') ? 'E' : '0') : '0';

  // ELD File Header Segment
  headerSegment.array.push(['ELD File Header Segment']);
  // driver last name, driver first name, username, license state, license #, LDCV
  lineEntryArr = [x.get('driverLastName'), x.get('driverFirstName'), x.get('driverUsername'), x.get('driverLicenseJurisdiction'), x.get('driverLicenseNumber')];
  lineEntryArr.push(getLDCV(lineEntryArr));
  headerSegment.sumLDCV += parseInt(lineEntryArr[lineEntryArr.length - 1], 16);
  headerSegment.array.push(lineEntryArr);
  // co-driver last name, co-driver first name, username, LDCV
  lineEntryArr = [x.get('coDriverLastName'), x.get('coDriverFirstName'), x.get('coDriverUsername')];
  lineEntryArr.push(getLDCV(lineEntryArr));
  headerSegment.sumLDCV += parseInt(lineEntryArr[lineEntryArr.length - 1], 16);
  headerSegment.array.push(lineEntryArr);
  // CMV power unit #, VIN, trailer #(s), LDCV
  lineEntryArr = [x.get('vehicleUnitId'), x.get('vehicleVIN'), x.get('trailerNumbers')];
  lineEntryArr.push(getLDCV(lineEntryArr));
  headerSegment.sumLDCV += parseInt(lineEntryArr[lineEntryArr.length - 1], 16);
  headerSegment.array.push(lineEntryArr);
  // USDOT #, carrier name, muldi-day basis used, 24-hour period starting time, time zone offset from UTC, LDCV
  lineEntryArr = [x.get('companyDOTNumber'), x.get('companyName'), multiDayBasis, twentyFourHourStartTime, x.get('timezoneOffsetFromUTC')];
  lineEntryArr.push(getLDCV(lineEntryArr));
  headerSegment.sumLDCV += parseInt(lineEntryArr[lineEntryArr.length - 1], 16);
  headerSegment.array.push(lineEntryArr);
  // shipping document #, exempt driver config, LDCV
  lineEntryArr = [x.get('shippingDocumentNumber'), exemptDriverConfiguration];
  lineEntryArr.push(getLDCV(lineEntryArr));
  headerSegment.sumLDCV += parseInt(lineEntryArr[lineEntryArr.length - 1], 16);
  headerSegment.array.push(lineEntryArr);
  // date, time, latitude, longitude, current total vehicle miles, current total engine hours, LDCV
  const date = momentTz(x.get('eventDateTime')).format('MMDDYY');
  const time = momentTz(x.get('eventDateTime')).format('HHMMSS');
  const latitude = x.get('vehicleLocation') && x.get('vehicleLocation').get('location') && x.get('vehicleLocation').get('location').latitude.toString();
  const longitude = x.get('vehicleLocation') && x.get('vehicleLocation').get('location') && x.get('vehicleLocation').get('location').longitude.toString();
  const totalEngineHours = x.get('totalEngineHours') && x.get('totalEngineHours').toString();
  const totalVehicleKm = x.get('totalVehicleKm') && x.get('totalVehicleKm').toString();
  lineEntryArr = [date, time, latitude, longitude, totalVehicleKm, totalEngineHours];
  lineEntryArr.push(getLDCV(lineEntryArr));
  headerSegment.sumLDCV += parseInt(lineEntryArr[lineEntryArr.length - 1], 16);
  headerSegment.array.push(lineEntryArr);
  // ELD registration ID, ELD identifier, ELD authentication value, output file comment, LDCV
  lineEntryArr = [x.get('eldRegistrationId'), x.get('eldAuthenticationValue'), comment];
  lineEntryArr.push(getLDCV(lineEntryArr));
  headerSegment.sumLDCV += parseInt(lineEntryArr[lineEntryArr.length - 1], 16);
  headerSegment.array.push(lineEntryArr);

  return headerSegment;
}

/**
 * @memberof module:ELD
 *
 * @param {*} eventParseObjArr
 * @param {*} exportComment
 *
 * @returns
 */
function parseThroughEvents(eventParseObjArr, exportComment) {
  const headerSegmentAndLDCV = getELDHeaderSegment(eventParseObjArr[0], exportComment);
  const headerSegment = headerSegmentAndLDCV.array;
  const driverListSegment = [['User List:']];
  const cmvListSegment = [['CMV List:']];
  const eventsSegment = [['ELD Event List:']];
  const annotationsSegment = [['ELD Event Annotations or Comments:']];
  const malfunctionsAndDataDiagnosticsSegment = [['Malfunctions and Data Diagnostic Events:']];
  const loginLogoutsSegment = [['ELD Login/Logout Report:']];
  const enginePowerUpAndShutDownsSegment = [['CMV Engine Power-Up and Shut Down Activity:']];
  const unidentifiedDriverProfileRecordsSegment = [['Unidentified Driver Profile Records:']];
  const endOfFileSegment = [['End of File:']];

  let sumLDCV = headerSegmentAndLDCV.LDCV;

  const driverIdOrderNumberMap = {};
  const vehicleIdOrderNumberMap = {};
  let driverCount = 1;
  let vehicleCount = 1;

  const eventParseObjArrLen = eventParseObjArr.length;

  for (let i = 0; i < eventParseObjArrLen; i++) {
    const x = eventParseObjArr[i];

    const vehicleUnitId = x.get('vehicleUnitId');
    const vehicleVIN = x.get('vehicleVIN');
    const driverFirstName = x.get('driverFirstName');
    const driverLastName = x.get('driverLastName');
    const eldAccountType = 'S';

    // Check for unique users for User List & add to User List
    if (x.get('driver') && driverIdOrderNumberMap[x.get('driver').id] === undefined) {
      driverIdOrderNumberMap[x.get('driver').id] = driverCount;
      // Driver Order #, ELD account type, last name, first name, LDCV
      const driverListSegmentLine = [driverCount.toString(), eldAccountType, driverLastName, driverFirstName];
      driverListSegmentLine.push(getLDCV(driverListSegmentLine));
      driverListSegment.push(driverListSegmentLine);
      sumLDCV += parseInt(driverListSegmentLine[driverListSegmentLine.length - 1], 16);
      driverCount++;
    }
    // Check for unique vehicles for CMV List & add to CMV List
    if (x.get('vehicle') && vehicleIdOrderNumberMap[x.get('vehicle').id] === undefined) {
      vehicleIdOrderNumberMap[x.get('vehicle').id] = vehicleCount;
      // CMV Order #, power unit #, VIN, LDCV
      const cmvListSegmentLine = [vehicleCount.toString(), eldAccountType, vehicleUnitId, vehicleVIN];
      cmvListSegmentLine.push(getLDCV(cmvListSegmentLine));
      cmvListSegment.push(cmvListSegmentLine);
      sumLDCV += parseInt(cmvListSegmentLine[cmvListSegmentLine.length - 1], 16);
      vehicleCount++;
    }

    const accumulatedVehicleKm = x.get('accumulatedVehicleKm') && x.get('accumulatedVehicleKm').toString();
    const cmvOrderNumber = x.get('vehicle') && vehicleIdOrderNumberMap[x.get('vehicle').id].toString();
    const distanceSinceLastCoordinates = x.get('distanceSinceLastValidCoord') && x.get('distanceSinceLastValidCoord').toString();
    const elapsedEngineHours = x.get('elapsedEngineHours') && x.get('elapsedEngineHours').toString();
    const eldDataDiagnosticIndicatorStatus = x.get('dataDiagnostic') && x.get('dataDiagnostic') * 1;
    const eldEventRecordStatus = x.get('eldEventRecordStatusInt') && x.get('eldEventRecordStatusInt').toString();
    const eldEventRecordOrigin = x.get('eldEventRecordOriginInt') && x.get('eldEventRecordOriginInt').toString();
    const eldEventType = x.get('eldEventTypeInt') && x.get('eldEventTypeInt').toString();
    const eldEventTypeCode = x.get('eldEventTypeCodeInt') && x.get('eldEventTypeCodeInt').toString();
    const eldMalfunctionDataCode = x.get('eldMalfunctionDataCodeCode');
    const eldMalfunctionIndicatorStatus = x.get('malfunction') && x.get('malfunction') * 1;
    const eventSequenceId = x.get('eventSequenceId') && x.get('eventSequenceId').toString();
    const dataCheckValue = x.get('eventDataCheck') && x.get('eventDataCheck').toString();
    const date = momentTz(x.get('eventDateTime')).format('MMDDYY');
    const latitude = x.get('vehicleLocation') && x.get('vehicleLocation').get('location') && x.get('vehicleLocation').get('location').latitude.toString();
    const locationDescription = x.get('locationDescription');
    const longitude = x.get('vehicleLocation') && x.get('vehicleLocation').get('location') && x.get('vehicleLocation').get('location').longitude.toString();
    const note = x.get('note');
    const shippingDocumentNumber = x.get('shippingDocumentNumber');
    const time = momentTz(x.get('eventDateTime')).format('HHMMSS');
    const totalEngineHours = x.get('totalEngineHours') && x.get('totalEngineHours').toString();
    const totalVehicleKm = x.get('totalVehicleKm') && x.get('totalVehicleKm').toString();
    const trailerNumbers = x.get('trailerNumbers');
    const driverUsername = x.get('driverUsername');
    const userOrderNumber = x.get('driver') && driverIdOrderNumberMap[x.get('driver').id].toString();

    // add Event to Events Segment
    // Event sequence ID #, Record status, Record origin, Type, Code, Date, Time, Accumulated vehicle miles, Elapsed engine hours, Latitude, Longitude, Distance since last coordinates, CMV order number, User Order number, ELD Malfunction indicator status, Driver data Diagnostic event indicator status, Data check value, LDCV
    const eventsSegmentLine = [eventSequenceId, eldEventRecordStatus, eldEventRecordOrigin, eldEventType, eldEventTypeCode, date, time, accumulatedVehicleKm, elapsedEngineHours, latitude, longitude, distanceSinceLastCoordinates, cmvOrderNumber, userOrderNumber, eldMalfunctionIndicatorStatus, eldDataDiagnosticIndicatorStatus, dataCheckValue];
    eventsSegmentLine.push(getLDCV(eventsSegmentLine));
    eventsSegment.push(eventsSegmentLine);
    sumLDCV += parseInt(eventsSegmentLine[eventsSegmentLine.length - 1], 16);

    // add Annotation Event to Annotations Segment
    // Event sequence ID #, ELD username, Event comment text or annotation, Date, Time, Location description, LDCV
    const annotationsSegmentLine = [eventSequenceId, driverUsername, note, date, time, locationDescription];
    annotationsSegmentLine.push(getLDCV(annotationsSegmentLine));
    annotationsSegment.push(annotationsSegmentLine);
    sumLDCV += parseInt(annotationsSegmentLine[annotationsSegmentLine.length - 1], 16);

    // add Malfunction/Data Diagnostic Event to Malfunctions & Data Diagnostics Segment
    // Event Sequence ID #, Code, Malfunction/Diagnostic Code, Date, Time, Total Vehicle Km, Total Engine Hours, CMV Order #, LDCV
    const malfunctionsAndDataDiagnosticsSegmentLine = [eventSequenceId, eldEventTypeCode, eldMalfunctionDataCode, date, time, totalVehicleKm, totalEngineHours, cmvOrderNumber];
    malfunctionsAndDataDiagnosticsSegmentLine.push(getLDCV(malfunctionsAndDataDiagnosticsSegmentLine));
    malfunctionsAndDataDiagnosticsSegment.push(malfunctionsAndDataDiagnosticsSegmentLine);
    sumLDCV += parseInt(malfunctionsAndDataDiagnosticsSegmentLine[malfunctionsAndDataDiagnosticsSegmentLine.length - 1], 16);

    // add Login/Logout Event to Malfunctions & Data Diagnostics Segment
    // Event Sequence ID #, Code, ELD username, Date, Time, Total Vehicle Km, Total Engine Hours, LDCV
    const loginLogoutsSegmentLine = [eventSequenceId, eldEventTypeCode, driverUsername, date, time, totalVehicleKm, totalEngineHours];
    loginLogoutsSegmentLine.push(getLDCV(loginLogoutsSegmentLine));
    loginLogoutsSegment.push(loginLogoutsSegmentLine);
    sumLDCV += parseInt(loginLogoutsSegmentLine[loginLogoutsSegmentLine.length - 1], 16);

    // add Engine Power Up & Shut Down Event to Engine Power Up & Shut Downs Segment
    // Event Sequence ID #, Code, Date, Time, Total Vehicle Km, Total Engine Hours, Latitude, Longitude, CMV Power Unit #, VIN, Trailer #s, Shipping Doc #, LDCV
    const enginePowerUpAndShutDownsSegmentLine = [eventSequenceId, eldEventTypeCode, date, time, totalVehicleKm, totalEngineHours, latitude, longitude, vehicleUnitId, vehicleVIN, trailerNumbers, shippingDocumentNumber];
    enginePowerUpAndShutDownsSegmentLine.push(getLDCV(enginePowerUpAndShutDownsSegmentLine));
    enginePowerUpAndShutDownsSegment.push(enginePowerUpAndShutDownsSegmentLine);
    sumLDCV += parseInt(enginePowerUpAndShutDownsSegmentLine[enginePowerUpAndShutDownsSegmentLine.length - 1], 16);

    // add Unidentified Driver Profile Event to UnidentifiedDriverProfileRecords Segment
    // Event Sequence ID #, Event Record Status, Event Record Origin, Type, Code, Date, Time, Accumulated Vehicle Km, Elapsed Engine Hours, Latitude, Longitude, Distance Since Last Valid Coordinates, CMV Order #, Malfunction Indicator Status, Event Data Check Value, LDCV
    const unidentifiedDriverProfileRecordsSegmentLine = [eventSequenceId, eldEventRecordStatus, eldEventRecordOrigin, eldEventType, eldEventTypeCode, date, time, accumulatedVehicleKm, elapsedEngineHours, latitude, longitude, distanceSinceLastCoordinates, cmvOrderNumber, eldMalfunctionIndicatorStatus, dataCheckValue];
    unidentifiedDriverProfileRecordsSegmentLine.push(getLDCV(unidentifiedDriverProfileRecordsSegmentLine));
    unidentifiedDriverProfileRecordsSegment.push(unidentifiedDriverProfileRecordsSegmentLine);
    sumLDCV += parseInt(unidentifiedDriverProfileRecordsSegmentLine[unidentifiedDriverProfileRecordsSegmentLine.length - 1], 16);
  }

  endOfFileSegment.push([getFDCV(sumLDCV)]);

  return {
    headerSegment,
    driverListSegment,
    cmvListSegment,
    eventsSegment,
    annotationsSegment,
    malfunctionsAndDataDiagnosticsSegment,
    loginLogoutsSegment,
    enginePowerUpAndShutDownsSegment,
    unidentifiedDriverProfileRecordsSegment,
    endOfFileSegment,
  };
}

/**
 * @memberof module:ELD
 *
 * @param {*} referenceParseObj
 * @param {*} fromDateMoment
 * @param {*} toDateMoment
 * @param {*} comment
 *
 * @returns
 */
function getELDCSVContent(referenceParseObj, fromDateMoment, toDateMoment, comment) {
  // ELD Test Plan & Procedures 4.8.2
  // Data File Name
  /*
    first five letters of last name (if < 5 then use '_');
    last two digits of drivers license;
    (2 digits) sum of all numeric digits in license # (if > 99, use last two digits; if < 10, first digit is 0);
    date (MMDDYY);
    hypen ('-');
    000000000 to ZZZZZZZZZ (9 characters can be used to create uniqueness, use own formula w/e)
  */
  // Data File
  /*
    ELD File Header Segment
    driver last name, driver first name, username, license state, license #, LCDV
    co-driver last name, co-driver first name, username, LCDV
    CMV power unit #, VIN, trailer #(s), LCDV
    USDOT #, carrier name, muldi-day basis used, 24-hour period starting time, time zone offset from UTC, LCDV
    shipping document #, exempt driver config, LDCV

    User List:
    Driver Order #, ELD account type, last name, first name, LCDV

    CMV List:
    CMV Order #, power unit #, VIN, LCDV

    ELD Event List:
    Event sequence ID #, Record status, Record origin, Type, Code, Date, Time, Accumulated vehicle miles, Elapsed engine hours, Latitude,
  Longitude, Distance since last coordinates, CMV order number, User Order number, ELD Malfunction indicator status, Driver data Diagnostic event indicator status,
  Data check value, LCDV

    ELD Event Annotations or Comments:
    Event sequence ID #, ELD username, Event comment text or annotation, Date, Time, Location description, LCDV

    Malfunctions and Data Diagnostic Events:
    Event Sequence ID #, Code, Malfunction/Diagnostic Code, Date, Time, Total Vehicle Km, Total Engine Hours, CMV Order #, LCDV

    ELD Login/Logout Report:
    Event Sequence ID #, Code, ELD username, Date, Time, Total Vehicle Km, Total Engine Hours, LCDV

    CMV Engine Power-Up and Shut Down Activity:
    Event Sequence ID #, Code, Date, Time, Total Vehicle Km, Total Engine Hours, Latitutde, Longitude, CMV Power Unit #, VIN, Trailer #s, Shipping Doc #, LCDV

    Unidentified Driver Profile Records:
    Event Sequence ID #, Event Record Status, Event Record Origin, Type, Code, Date, Time, Accumulated Vehicle Km, Elapsed Engine HOurs, Latitude, Longitude,
  Distance Since Last Valid Coordinates, CMV Order #, Mulfunction Indicator Status, Event Data Check Value, LDCV

    End of File:
    File Data Check Value
  */
  const promise = new Promise((resolve, reject) => {
    Getters.getELDEvents(referenceParseObj, fromDateMoment, toDateMoment).then((eventParseObjArr) => {
      const eldParsedObj = parseThroughEvents(eventParseObjArr);
      const csvContent = Helpers.convertArrayToCSVString([].concat(...[
        eldParsedObj.headerSegment,
        eldParsedObj.driverListSegment,
        eldParsedObj.cmvListSegment,
        eldParsedObj.eventsSegment,
        eldParsedObj.annotationsSegment,
        eldParsedObj.malfunctionsAndDataDiagnosticsSegment,
        eldParsedObj.loginLogoutsSegment,
        eldParsedObj.enginePowerUpAndShutDownsSegment,
        eldParsedObj.unidentifiedDriverProfileRecordsSegment,
        eldParsedObj.endOfFileSegment,
      ]));
      resolve(csvContent);
      // const encodedUri = encodeURI(csvContent);
      // const link = document.createElement('a');
      // link.setAttribute('href', `data:attachment/csv,${encodedUri}`);
      // link.setAttribute('download', `${getFormattedELDFileName(eventParseObjArr[0])}.csv`);
      // document.body.appendChild(link); // Required for FF
      // link.click(); // This will download the data file named "my_data.csv".
    });
  });
  return promise;
}

/**
 * @memberof module:ELD
 *
 * @param {*} driverQuery
 * @param {*} page
 * @param {*} limit
 * @param {*} resolve
 * @param {*} reject
 * @param {*} activeELDDrivers
 */
function getActiveELDDriversSub(driverQuery, page, limit, resolve, reject, activeELDDrivers = []) {
  driverQuery.limit(limit);
  driverQuery.skip(page * limit);

  driverQuery.find().then(
    drivers => {
      if (drivers.length === 0) {
        resolve(activeELDDrivers);
      } else {
        const newActiveELDDriversArr = [].concat(activeELDDrivers, drivers);
        getActiveELDDriversSub(driverQuery, page + 1, limit, resolve, reject, newActiveELDDriversArr);
      }
    },
    error => reject(error)
  );
}

/**
 * @memberof module:ELD
 * @returns
 */
function getActiveELDDrivers() {
  // obtain all drivers with latestELDEventIsActive = true
  const promise = new Promise((resolve, reject) => {
    const driverQuery = new Parse.Query('Driver');
    driverQuery.notEqualTo('isHidden', true);
    driverQuery.equalTo('belongsToCompany', Parse.User.current().get('belongsToCompany'));
    driverQuery.equalTo('latestELDEventIsActive', true);
    driverQuery.include('latestELDEvent');
    driverQuery.include('user');
    driverQuery.include('weighStationBypassDriver');
    getActiveELDDriversSub(driverQuery, 0, 1000, resolve, reject);
  });
  return promise;
}

/**
 * @memberof module:ELD
 * @returns
 */
function getDriverHours() {
  // get all drivers who are out of hours on their current cycle/time plans, or those who are near it
  const promise = new Promise((resolve, reject) => {
    const outOfHoursResults = {}; // holds out of hour drivers and drivers nearing out of hours

    getActiveELDDrivers().then(
      activeELDDrivers => {
        // now that we have drivers who have active eld jobs, filter those who are out of hours
        // and those who are near it (within an hour)
        const allHoursDrivers = [];
        const outOfHoursDrivers = [];
        const nearOutOfHoursDrivers = [];

        for (let i = 0; i < activeELDDrivers.length; i++) {
          const driver = activeELDDrivers[i];
          const latestELDEvent = driver.get('latestELDEvent');
          if (latestELDEvent) {
            const eventDateTime = latestELDEvent.get('eventDateTime');

            const drivingTimeRemaining = Helpers.convertMillisecondsToHours(projectAndFormatDrivingTime(driver));
            const onDutyTimeRemaining = Helpers.convertMillisecondsToHours(projectAndFormatOnDutyTime(driver));

            const driverHoursObject = { driver, latestELDEvent };

            allHoursDrivers.push(driverHoursObject);

            // if no hours remaining
            if (drivingTimeRemaining <= 0 || onDutyTimeRemaining <= 0) {
              outOfHoursDrivers.push(driverHoursObject);
            }

            // if <= 1 hour remaining but not out of hours yet
            if ((drivingTimeRemaining <= 1 && drivingTimeRemaining >= 0) || (onDutyTimeRemaining <= 1 && onDutyTimeRemaining >= 0)) {
              nearOutOfHoursDrivers.push(driverHoursObject);
            }
          }
        }

        outOfHoursResults.allHours = allHoursDrivers;
        outOfHoursResults.outOfHours = outOfHoursDrivers;
        outOfHoursResults.nearOutOfHours = nearOutOfHoursDrivers;
        resolve(outOfHoursResults);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:ELD
 * @param {*} milliTime
 * @returns
 */
function formatMilliTime(milliTime, altBool) {
  if (milliTime !== undefined) {
    const hours = Helpers.convertMillisecondsToHours(milliTime);
    const hourTime = Math.floor(hours);
    const minuteTime = (`0${Math.round((hours - Math.floor(hours)) * 60)}`).slice(-2);
    if (altBool) {
      return `${hourTime}h ${minuteTime} min`;
    } else {
      return `${hourTime}:${minuteTime}`;
    }
  }
  return undefined;
}

/**
 * @memberof module:ELD
 * @param {*} hours
 * @returns
 */
function isAlmostOutOfHours(hours) {
  if (hours < 0.5) {
    return true;
  }
  return false;
}

/**
 * @memberof module:ELD
 * @param {*} hours
 * @returns
 */
function isOutOfHours(hours) {
  if (hours < 0.01) {
    return true;
  }
  return false;
}

/**
 * @memberof module:ELD
 * @param {*} driverParseObj
 * @returns
 */
function isDriverDriving(driverParseObj) {
  return driverParseObj.get('eldStatusInt') === 3 || driverParseObj.get('eldStatusInt') === 6;
}

/**
 * @memberof module:ELD
 * @param {*} driverParseObj
 * @returns
 */
function isDriverOnDuty(driverParseObj) {
  return driverParseObj.get('eldStatusInt') === 3 || driverParseObj.get('eldStatusInt') === 4 || driverParseObj.get('eldStatusInt') === 6;
}

/**
 * @memberof module:ELD
 * @param {*} vehicleParseObj
 * @returns
 */
function isVehicleDriverDriving(vehicleParseObj) {
  if (vehicleParseObj.get('drivers') && vehicleParseObj.get('drivers')[0]) {
    return isDriverDriving(vehicleParseObj.get('drivers')[0]);
  }
  return false;
}

/**
 * @memberof module:ELD
 * @param {*} vehicleParseObj
 * @returns
 */
function isVehicleDriverOnDuty(vehicleParseObj) {
  if (vehicleParseObj.get('drivers') && vehicleParseObj.get('drivers')[0]) {
    return isDriverOnDuty(vehicleParseObj.get('drivers')[0]);
  }
  return false;
}

/**
 * @memberof module:ELD
 *
 * @param {*} drivingTimeRemaining
 * @param {*} driverParseObj
 *
 * @returns
 */
function projectAndFormatDrivingTime(drivingTimeRemaining, driverParseObj, eldDailyCertification) {
  const latestELDEvent = driverParseObj.get('latestELDEvent');
  if (eldDailyCertification && latestELDEvent && latestELDEvent.get('eventDateTime') && isDriverDriving(driverParseObj)) {
    // If driving, then project more
    const difference = ((new Date()).getTime()) - (eldDailyCertification.get('updatedAt').getTime());
    const projectedTimeRemaining = Math.max(drivingTimeRemaining - difference, 0);
    return projectedTimeRemaining;
  }
  return drivingTimeRemaining;
}

/**
 * @memberof module:ELD
 *
 * @param {*} onDutyTimeRemaining
 * @param {*} driverParseObj
 *
 * @returns
 */
function projectAndFormatOnDutyTime(onDutyTimeRemaining, driverParseObj, eldDailyCertification) {
  const latestELDEvent = driverParseObj.get('latestELDEvent');
  if (eldDailyCertification && latestELDEvent && latestELDEvent.get('eventDateTime') && isDriverOnDuty(driverParseObj)) {

    // If driver, on-duty, or yard move, then project
    const difference = ((new Date()).getTime()) - (eldDailyCertification.get('updatedAt').getTime());
    const projectedTimeRemaining = Math.max(onDutyTimeRemaining - difference, 0);
    return projectedTimeRemaining;
  }
  return onDutyTimeRemaining;
}

/**
 * @memberof module:ELD
 *
 * @param {*} driverObject
 * @param {*} eldDailyCertificationObject
 * @param {*} getOrigin
 * @param {*} getActive
 *
 * @returns
 */
function getAssociatedELDEvents(driverObject, eldDailyCertificationObject, getOrigin, getActive) {
  // mainly for fetching the last event of the previous day and the first event of the next
  // use startTimeUTC is used if there is no eld daily certification
  const promise = new Promise((resolve, reject) => {
    const filterableEventTypeCodes = [11, 12, 13, 14, 31, 32];

    const queryIncludes = [
      'eldHardware',
      'eldDailyCertification',
      'vehicle',
      'vehicle.licensePlate',
      'vehicleLocation',
      'driver',
      'driver.user',
      'coDriver',
      'coDriver.user',
      'company',
    ];

    if (driverObject && eldDailyCertificationObject) {
      const timezoneOffsetFromUTC = driverObject.get('timezoneOffsetFromUTC') || momentTz.tz.guess();
      const startTimeUTC = momentTz(eldDailyCertificationObject.get('startTimeUTC')).tz(timezoneOffsetFromUTC).toDate();
      const dayBeforeStartTimeUTC = momentTz(startTimeUTC).tz(timezoneOffsetFromUTC).subtract(1, 'day').toDate();

      const previousDayEventsQuery = new Parse.Query('ELDEvent');
      previousDayEventsQuery.equalTo('driver', driverObject);
      previousDayEventsQuery.greaterThanOrEqualTo('eventDateTime', dayBeforeStartTimeUTC);
      previousDayEventsQuery.lessThanOrEqualTo('eventDateTime', startTimeUTC);
      previousDayEventsQuery.containedIn('eldEventTypeCodeInt', filterableEventTypeCodes);
      previousDayEventsQuery.descending('eventDateTime');
      previousDayEventsQuery.include(queryIncludes);

      const lastKnownEventQuery = new Parse.Query('ELDEvent'); // query for last known event prior to elddailycert
      lastKnownEventQuery.equalTo('driver', driverObject);
      lastKnownEventQuery.lessThanOrEqualTo('eventDateTime', startTimeUTC);
      lastKnownEventQuery.containedIn('eldEventTypeCodeInt', filterableEventTypeCodes);
      lastKnownEventQuery.descending('eventDateTime');
      lastKnownEventQuery.include(queryIncludes);

      if (getOrigin) {
        previousDayEventsQuery.equalTo('eldEventRecordOriginInt', 1);
        lastKnownEventQuery.equalTo('eldEventRecordOriginInt', 1);
      }
      if (getActive) {
        previousDayEventsQuery.equalTo('eldEventRecordStatusInt', 1);
        lastKnownEventQuery.equalTo('eldEventRecordStatusInt', 1);
      }

      const associatedEventsPromises = [lastKnownEventQuery.first(), previousDayEventsQuery.first()];

      Promise.all(associatedEventsPromises).then(
        associatedELDEvents => {
          const lastKnownEvent = associatedELDEvents[0];
          const previousDayEvent = associatedELDEvents[1];
          resolve([lastKnownEvent, previousDayEvent]);
        },
        error => reject(error)
      );
    } else {
      resolve([]);
    }
  });
  return promise;
}

/**
 * @memberof module:ELD
 * @Deprecated
 *
 * @param {*} driverObject
 * @param {*} eldDailyCertificationObject
 */
function getAssociatedAOBRDELDEvents(driverObject, eldDailyCertificationObject) {
  // same as getAssociatedELDEvents but for aobrd edit simulations
  // ex. if previous or day after current contains an aobrd edit, we need to override the typical associated eld events
  const promise = new Promise((resolve, reject) => {
    const filterableEventTypeCodes = [11, 12, 13, 14, 21, 22, 31, 32];

    const queryIncludes = [
      'eldHardware',
      'eldDailyCertification',
      'vehicle',
      'vehicle.licensePlate',
      'vehicleLocation',
      'driver',
      'driver.user',
      'coDriver',
      'coDriver.user',
      'company',
    ];

    if (driverObject && eldDailyCertificationObject) {
      let _startTimeUTC;
      if (eldDailyCertificationObject) {
        _startTimeUTC = moment.utc(eldDailyCertificationObject.get('startTimeUTC'));
      } else {
        _startTimeUTC = moment.utc(startTimeUTC);
      }
      _startTimeUTC = _startTimeUTC.toDate();

      const eldEditQuery = new Parse.Query('ELDEdit');
      eldEditQuery.equalTo('belongsToCompany', Parse.User.current().get('belongsToCompany'));
      eldEditQuery.include(['requestedBy', 'requestedBy.user', 'eldDailyCertification', 'eldDailyCertification.driver', 'eldDailyCertification.driver.user', 'requestedELDEvents', 'eldEventsToBeInactive']);
      eldEditQuery.descending('requestedAt');

      let associatedEditEventDayBefore = moment.utc(_startTimeUTC);
      associatedEditEventDayBefore.hours(-24 + associatedEditEventDayBefore.hours()); // roll back to the previous day
      associatedEditEventDayBefore = associatedEditEventDayBefore.toDate();

      let associatedEditEventDayAfter = moment.utc(_startTimeUTC);
      associatedEditEventDayAfter.hours(24 + associatedEditEventDayAfter.hours()); // beginning of the next day
      associatedEditEventDayAfter = associatedEditEventDayAfter.toDate();


      let twoDaysAfterStartDate = moment.utc(associatedEditEventDayAfter);
      twoDaysAfterStartDate = twoDaysAfterStartDate.hours(24 + twoDaysAfterStartDate.hours()); // day after the next day
      twoDaysAfterStartDate = twoDaysAfterStartDate.toDate();

      const previousDayEditEventQuery = new Parse.Query('ELDEvent');
      previousDayEditEventQuery.equalTo('eldEventRecordStatusInt', 3);
      previousDayEditEventQuery.equalTo('aobrdEnabled', true);
      previousDayEditEventQuery.equalTo('driver', driverObject);
      previousDayEditEventQuery.greaterThanOrEqualTo('eventDateTime', associatedEditEventDayBefore);
      previousDayEditEventQuery.lessThanOrEqualTo('eventDateTime', _startTimeUTC);
      previousDayEditEventQuery.containedIn('eldEventTypeCodeInt', filterableEventTypeCodes);
      previousDayEditEventQuery.descending('eventDateTime');
      previousDayEditEventQuery.include(queryIncludes);

      const nextDayEditEventQuery = new Parse.Query('ELDEvent');
      nextDayEditEventQuery.equalTo('eldEventRecordStatusInt', 3);
      nextDayEditEventQuery.equalTo('aobrdEnabled', true);
      nextDayEditEventQuery.equalTo('driver', driverObject);
      nextDayEditEventQuery.greaterThanOrEqualTo('eventDateTime', associatedEditEventDayAfter);
      nextDayEditEventQuery.lessThan('eventDateTime', twoDaysAfterStartDate);
      nextDayEditEventQuery.containedIn('eldEventTypeCodeInt', filterableEventTypeCodes);
      nextDayEditEventQuery.ascending('eventDateTime');
      nextDayEditEventQuery.include(queryIncludes);

      const lastKnownEditEventQuery = new Parse.Query('ELDEvent');
      lastKnownEditEventQuery.equalTo('eldEventRecordStatusInt', 3);
      lastKnownEditEventQuery.equalTo('aobrdEnabled', true);
      lastKnownEditEventQuery.equalTo('driver', driverObject);
      lastKnownEditEventQuery.lessThanOrEqualTo('eventDateTime', _startTimeUTC);
      lastKnownEditEventQuery.containedIn('eldEventTypeCodeInt', filterableEventTypeCodes);
      lastKnownEditEventQuery.descending('eventDateTime');
      lastKnownEditEventQuery.include(queryIncludes);

      const nextKnownEditEventQuery = new Parse.Query('ELDEvent');
      nextKnownEditEventQuery.equalTo('eldEventRecordStatusInt', 3);
      nextKnownEditEventQuery.equalTo('aobrdEnabled', true);
      nextKnownEditEventQuery.equalTo('driver', driverObject);
      nextKnownEditEventQuery.greaterThanOrEqualTo('eventDateTime', associatedEditEventDayAfter);
      nextKnownEditEventQuery.containedIn('eldEventTypeCodeInt', filterableEventTypeCodes);
      nextKnownEditEventQuery.ascending('eventDateTime');
      nextKnownEditEventQuery.include(queryIncludes);

      const associatedAOBRDEditEventsPromises = [lastKnownEditEventQuery.first(), previousDayEditEventQuery.first(), nextDayEditEventQuery.first(), nextKnownEditEventQuery.first()];

      Promise.all(associatedAOBRDEditEventsPromises).then(
        associatedAOBRDEditELDEvents => {
          const lastKnownEvent = associatedAOBRDEditELDEvents[0];
          const previousDayEvent = associatedAOBRDEditELDEvents[1];
          const nextDayEvent = associatedAOBRDEditELDEvents[2];
          const nextKnownEvent = associatedAOBRDEditELDEvents[3];
          resolve([lastKnownEvent, previousDayEvent, nextDayEvent, nextKnownEvent]);
        },
        error => reject(error)
      );
    } else {
      resolve([]);
    }
  });
  return promise;
}

/**
 * @memberof module:ELD
 * @param {*} eventsDataArray
 * @returns
 */
function getTotalHoursForGraph(eventsDataArray) {
  // eventsDataArray is an array of arrays (array of seperate eventdatas)
  // given sorted+filtered event data, find out the amount of time spent off duty, on duty, etc
  // ['OFF DUTY', 'SLEEPER BERTH', 'DRIVING', 'ON DUTY - NOT DRIVING', // 'PERSONAL USE CMV', 'YARD MOVES'];
  // 1: off duty, 3: sleeper, 5: driving, 7: on duty
  const dutyTimeInfo = [
    undefined,
    { type: 'offDuty', ms: 0 },
    undefined,
    { type: 'sleeperBerth', ms: 0 },
    undefined,
    { type: 'driving', ms: 0 },
    undefined,
    { type: 'onDuty', ms: 0 },
  ];

  for (let g = 0; g < eventsDataArray.length; g++) {
    const eventsData = [].concat(eventsDataArray[g]);
    if (eventsData.length > 0) {
      let i = eventsData.length - 1;
      while (i > 0) {
        const data = eventsData[i];
        const precedingData = eventsData[i - 1];
        const dutyStatus = data.y;

        if (precedingData && (precedingData.y === dutyStatus)) {
          // if the duty status of one before it is of the same duty status, compute the difference and add it
          if (dutyStatus) {
            dutyTimeInfo[dutyStatus].ms += data.x.valueOf() - precedingData.x.valueOf();
          }
        } else {
          // the one before the current is of a different status, so add that time to that previous status instead
          if (precedingData.y) {
            dutyTimeInfo[precedingData.y].ms += data.x.valueOf() - precedingData.x.valueOf();
          }
        }

        i--;
      }
    }
  }

  let hoursSum = 0;
  let minutesSum = 0;
  // now we want to convert the dutytimeinfo to duty times (convert ms to hours/mins)
  const uncheckedDutyTimes = dutyTimeInfo.map(dutyTimeObject => {
    if (dutyTimeObject) {
      let timeString = Helpers.msToTimeString(dutyTimeObject.ms, 'HH:mm');
      // Figure out if the minutes have been rounded up or down by finding decimal
      // (e.g. 1m45s === 105s === 1.75m) so number has been rounded up to 2m cause 0.75 >= 0.5
      const inSeconds = Helpers.msToTimeString(dutyTimeObject.ms, 'ss').replace(',', '');
      const inMinutes = Number(inSeconds) / 60;
      const secondsDecimal = inMinutes - Math.floor(inMinutes);

      timeString = timeString.split(':');
      const hours = !timeString[1] ? '00' : timeString[0];
      const minutes = !timeString[1] ? timeString[0] : timeString[1];

      hoursSum += Number(hours);
      minutesSum += Number(minutes);

      timeString = `${hours}H ${minutes}M`;

      return { timeString, hours: Number(hours), minutes: Number(minutes), isRounded: secondsDecimal !== 0, roundedUp: secondsDecimal >= 0.5 };
    }
    return undefined;
  });

  if (minutesSum > 59) {
    hoursSum += Math.floor(minutesSum / 60);
    minutesSum %= 60;
  }

  let dutyTimes = uncheckedDutyTimes.map(dutyTimeObject => {
    if (!dutyTimeObject) return undefined;
    return dutyTimeObject.timeString;
  });

  let changesToRounding = 0;

  // Check if rounding made it go over or under 24 hours and fix it
  if (hoursSum === 23 && minutesSum === 59) { // Most have been rounded down, 23h59m
    dutyTimes = uncheckedDutyTimes.map(dutyTimeObject => {
      if (!dutyTimeObject) return undefined;
      // Don't change if they have been rounded or rounded up
      if (!dutyTimeObject.isRounded || changesToRounding === 1 || dutyTimeObject.roundedUp) return dutyTimeObject.timeString;
      changesToRounding += 1;
      let hours = dutyTimeObject.hours;
      let minutes = dutyTimeObject.minutes + 1;
      if (minutes > 59) {
        hours += 1;
        minutes %= 60;
      }
      const timeString = `${hours.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}H ${minutes.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}M`;
      return timeString;
    });
  } else if (minutesSum === 1 && hoursSum === 24) { // went over 24 hours, 24h1m
    dutyTimes = uncheckedDutyTimes.map(dutyTimeObject => {
      if (!dutyTimeObject) return undefined;
      // Don't change if they have been rounded or rounded down
      if (!dutyTimeObject.isRounded || changesToRounding === 1 || !dutyTimeObject.roundedUp) return dutyTimeObject.timeString;
      changesToRounding += 1;
      let hours = dutyTimeObject.hours;
      let minutes = dutyTimeObject.minutes - 1;
      if (dutyTimeObject.minutes === 0) {
        hours -= 1;
        minutes = 59;
      }
      const timeString = `${hours.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}H ${minutes.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}M`;
      return timeString;
    });
  }

  return dutyTimes;
}

/**
 * @memberof module:ELD
 *
 * @param {*} eldEvent
 * @param {*} specialDutyStatusInt
 * @param {*} timezoneOffsetFromUTC
 *
 * @returns
 */
function convertEventToGraphCoord(eldEvent, specialDutyStatusInt, timezoneOffsetFromUTC) {
  // take an event and create an [cartesian] to plot on graph
  // filterableEventTypeCodes = [11, 12, 13, 14, 31, 32];
  // specialDutyStatusInt is either a Off Duty, Driving, PC, or YM status which determines the Y result of interm events
  if (!eldEvent) {
    return null;
  }
  const dataObject = {};
  const eldEventTypeCodeInt = eldEvent.eldEventTypeCodeInt ? eldEvent.eldEventTypeCodeInt : eldEvent.get('eldEventTypeCodeInt');
  dataObject.x = eldEvent.eventDateTime ? eldEvent.eventDateTime : momentTz(eldEvent.get('eventDateTime')).tz(timezoneOffsetFromUTC);
  dataObject.id = eldEvent.id;

  if (eldEvent.isSpecialEvent) {
    dataObject.isSpecialEvent = true; // distinction between eld events and other events

    if (eldEvent.hosViolation) {
      dataObject.hosViolation = true;
    }
  }

  // figures out if the event is part of a requested eld edit
  const isRequestedEditEvent = (eldEvent) => !eldEvent.id && eldEvent._localId && !eldEvent.appended && !eldEvent.prepended && (eldEvent.get('eldEventRecordOriginInt') === 3) && (eldEvent.get('eldEventRecordOriginatorInt') === 2);
  if (isRequestedEditEvent(eldEvent)) {
    dataObject.requestedELDEvent = true;
  }

  // figure out if event is agdt
  if (isAutoGeneratedDrivingTime(eldEvent) || eldEvent.simulateAsIntermELDEvent) {
    dataObject.autoGeneratedDrivingTime = true;
  }
  if (([21, 22].indexOf(eldEventTypeCodeInt) !== -1) && (specialDutyStatusInt && specialDutyStatusInt === 11) && dataObject.autoGeneratedDrivingTime) {
    dataObject.autoGeneratedDrivingTime = false; // if interm driving and previous status was off duty, mark as false autogen
  }

  switch (eldEventTypeCodeInt) {
    case 11: // off duty
      dataObject.y = 7;
      break;
    case 12: // sleeper
      dataObject.y = 5;
      break;
    case 13: // driving
      dataObject.y = 3;
      break;
    case 14: // on duty
      dataObject.y = 1;
      break;
    case 21: // interm event (driving)
      dataObject.y = 3;
      if (specialDutyStatusInt && specialDutyStatusInt === 31) {
        // PC
        dataObject.y = 7; // 9
        dataObject.personalConveyance = true;
      } else if (specialDutyStatusInt && specialDutyStatusInt === 32) {
        // YM
        dataObject.y = 1; // 11
        dataObject.yardMoves = true;
      } else if (specialDutyStatusInt && specialDutyStatusInt === 11) {
        // Off Duty
        dataObject.y = 7;
      }
      break;
    case 22: // interm event (driving)
      dataObject.y = 3;
      if (specialDutyStatusInt && specialDutyStatusInt === 31) {
        // PC
        dataObject.y = 7; // 9
        dataObject.personalConveyance = true;
      } else if (specialDutyStatusInt && specialDutyStatusInt === 32) {
        // YM
        dataObject.y = 1; // 11
        dataObject.yardMoves = true;
      } else if (specialDutyStatusInt && specialDutyStatusInt === 11) {
        // Off Duty
        dataObject.y = 7;
      }
      break;
    case 31: // personal use cmv
      dataObject.y = 7; // 9
      dataObject.personalConveyance = true;
      break;
    case 32: // yard moves
      dataObject.y = 1; // 11
      dataObject.yardMoves = true;
      break;
    // case 51: // login activity
    //   dataObject.y = 7;
    //   break;
    // case 52: // logout activity
    //   dataObject.y = 7;
    //   break;
    default:
      dataObject.y = null;
  }

  return dataObject;
}

/**
 * @memberof module:ELD
 *
 * @param {*} eldEvents
 * @param {*} timezoneOffsetFromUTC
 *
 * @returns
 */
function convertEventsToGraphCoords(eldEvents, timezoneOffsetFromUTC) {
  /*
    Firstly, given the eldevents, figure out which should be marked autogenerated for the user even if they aren't truly autogenerated
    This is based on the following criteria:
    - The driving-type event should be touching an autogenerated event within a 1 hour interval

    So we want to
    1. Iterate through all events
    2. If we hit a driving-type event, look forwards and backwards 1 hour to see if there are any other consecutive driving-type that all link together
        i.e. not separated by duty status.  [13, 21, 21, 13, 21] is good, [13, 21, 21, 14, 21, 21, 13] would need to be treated as [13, 21, 21], [21, 21, 13]
    3. If there is a consecutive driving type event within the hour, treat it as autogenerated
  */

  function getIsDrivingTypeELDEvent(eldEvent) {
    return DrivingELDEventReferenceInts.indexOf(eldEvent.get('eldEventTypeCodeInt')) !== -1;
  }

  for (let i = 0; i < eldEvents.length; i++) {
    const eldEvent = eldEvents[i]; // aka the pivot event
    const isDrivingTypeELDEvent = getIsDrivingTypeELDEvent(eldEvent);
    const isDrivingTypeAndAutogenerated = (isDrivingTypeELDEvent && isAutoGeneratedDrivingTime(eldEvent)) || eldEvent.simulateAsIntermELDEvent;

    if (isDrivingTypeAndAutogenerated) {
      const eventDateTime = eldEvent.get('eventDateTime');

      if (eldEvents[i + 1]) {
        // start looking forwards until it is not a driving type status
        for (let k = i + 1; k < eldEvents.length; k++) {
          const nextELDEvent = eldEvents[k];
          const isWithinAnHour = (momentTz(nextELDEvent.get('eventDateTime')).diff(eventDateTime) / 1000 / 60 / 60) <= 1.25;
          if (getIsDrivingTypeELDEvent(nextELDEvent) && isWithinAnHour) {
            nextELDEvent.simulateAsIntermELDEvent = true;
          } else {
            break;
          }
        }
      }

      if (eldEvents[i - 1]) {
        // start looking backwards until it is not a driving type status or it is past an hour from the pivot event
        for (let k = i - 1; k >= 0; k--) {
          const previousELDEvent = eldEvents[k];
          const isWithinAnHour = (momentTz(eventDateTime).diff(previousELDEvent.get('eventDateTime')) / 1000 / 60 / 60) <= 1.25;
          if (getIsDrivingTypeELDEvent(previousELDEvent) && isWithinAnHour) {
            previousELDEvent.simulateAsIntermELDEvent = true;
          } else {
            break;
          }
        }
      }
    }
  }

  // note that there are actually 3 driving statuses (based on intermediate events that follow after: driving, ym, pc)
  let lastSpecialDutyStatusInt;
  const specialDutyStatusInts = [11, 13, 31, 32];

  const graphCoords = eldEvents.map(eldEvent => {
    const graphCoord = convertEventToGraphCoord(eldEvent, lastSpecialDutyStatusInt, timezoneOffsetFromUTC);

    // if this event is an intermediate event and the last driving status was a pc or ym, change to it to that status Y
    const eldEventTypeCodeInt = eldEvent.get('eldEventTypeCodeInt');
    if (specialDutyStatusInts.indexOf(eldEventTypeCodeInt) !== -1) {
      // update lastSpecialDutyStatusInt if needed
      lastSpecialDutyStatusInt = eldEventTypeCodeInt;
    }

    return graphCoord;
  });

  return graphCoords;
}

/**
 * @memberof module:ELD
 * @param {*} graphCoordData
 * @returns
 */
function getSpecialEventSequences(graphCoordData) {
  /*
    iterate through our graph data to figure out the different intervals in which events like
    ym, pc, autogenerated driving time, etc, occur. Store these array sequences in respective array
  */
  const specialEventSequences = {
    yardMoves: [],
    personalConveyance: [],
    autoGeneratedDrivingTime: [],
    requestedELDEvent: [],
  };

  // names of the attributes of special events in coordinate objects
  const specialEventAttributes = Object.keys(specialEventSequences);

  // now create local array to keep track of event sequences to be pushed to their respective specialEvents sequence
  let currentSequence = [];
  const graphCoordDataLen = graphCoordData.length;

  // we run a map-loop for each special event status (possible overlaps if all done in a single loop)
  for (let i = 0; i < specialEventAttributes.length; i++) {
    const attribute = specialEventAttributes[i];

    for (let j = 0; j < graphCoordDataLen; j++) {
      const graphCoordDatum = graphCoordData[j];
      if (graphCoordDatum[attribute]) {
        currentSequence.push(graphCoordDatum);
        if (j === graphCoordDataLen - 1 && (currentSequence.length > 0)) {
          specialEventSequences[attribute].push(currentSequence);
          currentSequence = [];
        }
      } else if (currentSequence.length > 0) {
        if (j !== graphCoordDataLen - 1 && (attribute.toLowerCase() !== 'requestedeldevent')) {
          // if we've reached the end and its not an requested eld event, push the next point to draw up to it
          // the reason we excluse requestedeldevent is because there will always be at least 2 and we only want to draw the line
          // between those two points
          currentSequence.push(graphCoordDatum);
        }
        specialEventSequences[attribute].push(currentSequence);
        currentSequence = [];
      }
    }
  }

  return specialEventSequences;
}

/**
 * @memberof module:ELD
 *
 * @param {*} eldEventsArr
 * @param {*} distanceThreshold
 *
 * @returns
 */
function filterOdometerReadingsJumpNew(eldEventsArr, distanceThreshold) {
  return Parse.Cloud.run('getFilterOdometerReadingsJumpNew', { eldEventIds: eldEventsArr.map((eldEvent) => eldEvent.id), distanceThreshold });

  // // For light duty engines, where the vehicle jumps to 0 when the engine restarts, will count up continuously even if it jumps down!
  // const filteredEldEventsArr = eldEventsArr.filter((eldEvent) => eldEvent.get('totalVehicleKm'));
  // if (!filteredEldEventsArr || filteredEldEventsArr.length === 0) return { odometerStart: 0, odometerEnd: 0 };
  // const odometerStart = filteredEldEventsArr && filteredEldEventsArr[0].get('totalVehicleKm');
  // let afterOdometerJumpIndex = 0;
  // let referenceOdometer = (odometerStart === undefined ? 0 : odometerStart);
  // let odometerEnd;
  // let correctedOdometer;
  // const eventMap = {};
  // eventMap[filteredEldEventsArr[0].id] = odometerStart;

  // for (let i = 1; i < filteredEldEventsArr.length; i++) {
  //   const currentEldEvent = filteredEldEventsArr[i];
  //   const previousEldEvent = filteredEldEventsArr[i - 1];
  //   if (previousEldEvent.get('totalVehicleKm') > currentEldEvent.get('totalVehicleKm') || currentEldEvent.get('totalVehicleKm') - previousEldEvent.get('totalVehicleKm') > jumpThreshold) {
  //     referenceOdometer = (odometerStart === undefined ? 0 : correctedOdometer);
  //     afterOdometerJumpIndex = i;
  //   }
  //   correctedOdometer = referenceOdometer + (currentEldEvent.get('totalVehicleKm') - filteredEldEventsArr[afterOdometerJumpIndex].get('totalVehicleKm'));
  //   eventMap[currentEldEvent.id] = correctedOdometer;
  // }
  // odometerEnd = correctedOdometer;
  // return {
  //   odometerStart,
  //   odometerEnd,
  //   eventMap,
  // };
};

/**
 * @memberof module:ELD
 *
 * @param {*} eldEvents
 * @param {*} distanceUnit
 * @param {*} useProjectionMethod
 * @param {*} useAccumulated
 * @param {*} useVehicleUnitId
 * @param {*} useCorrections
 * @param {*} correctForCoDriverEvents
 *
 * @returns
 */
function getOdometerReadings(
  eldEvents,
  distanceUnit,
  useProjectionMethod,
  useAccumulated,
  useVehicleUnitId,
  useCorrections,
  correctForCoDriverEvents
) {
  /*
   useProjection indicates we want to predict odometer reading values if there are any missing/jumps
   useProjection also returns a 1-to-1 mapping of events-to-reading whereas if not useProjection,
   we take the raw values only and return what non-0 values we find
  */
  const promise = new Promise((resolve, reject) => {
    if (!useProjectionMethod) {
      // const rawOdometerReadingsPromise = getRawOdometerReadings(eldEvents, distanceUnit, useAccumulated, useVehicleUnitId, false, useCorrections);
      // rawOdometerReadingsPromise.then((odos) => {
      //   console.log(odos);
      // });
      // resolve(rawOdometerReadingsPromise);

      const eldEventIds = eldEvents.map(eldEvent => eldEvent.id);
      // Parse.Cloud.run('getRawOdometerReadings', { eldEventIds, distanceUnit, useAccumulated, useVehicleUnitId, useDrivingBool: false, useCorrections }).then((odos) => {
      //   console.log(odos);
      // });
      resolve(Parse.Cloud.run('getRawOdometerReadings', { eldEventIds, distanceUnit, useAccumulated, useVehicleUnitId, useDrivingBool: false, useCorrections, correctForCoDriverEvents }));
    } else {
      const eldEventIds = eldEvents.map(eldEvent => eldEvent.id);
      Parse.Cloud.run('getOdometerReadings', { eldEventIds, distanceUnit }).then((odometerReadings) => {
        resolve(odometerReadings);
      }, (error) => {
        reject(error);
      });
    }
  });
  return promise;
}


/**
 * @memberof module:ELD
 * @description - Given a list of eld events, and a particular vehicle unit, search through to find out which events' odometer reading to change
 * such that the start and end odometer reading of that vehicle is odometerStartKm and odometerEndKm - bypassing all vehicle/codriver/break rulesets used in
 * getRawOdometerReadings. All values are treated in km as default

 * @param {array} eldEvents
 * @param {string} vehicleUnitId
 * @param {float} odometerStartKm
 * @param {float} odometerEndKm
 * @param {string} distanceUnit
 * @param {bool} useAccumulated
 *
 * @returns {array}
 *
 */
function getOdometerEldEventsToModify(eldEvents, vehicleUnitId, odometerStartKm, odometerEndKm, vehicleKmType = 'totalVehicleKm') {
  // get rid of all undefined or 0's for the reading type
  const _eldEvents = eldEvents.filter(eldEvent => eldEvent.get(vehicleKmType)).length !== 0 ? eldEvents.filter(eldEvent => eldEvent.get(vehicleKmType)) : eldEvents;
  const _eldEventsLen = _eldEvents.length;

  let eldEventsToModify;
  let startEldEvent;
  let endEldEvent;
  for (let i = 0; i < _eldEventsLen; i++) {
    const eldEvent = _eldEvents[i];
    if (eldEvent) {
      const _eldEvent = eldEvent.clone(); // we clone so we dont accidentally mess with the original event
      _eldEvent.set(vehicleKmType, !startEldEvent ? odometerStartKm : odometerEndKm);
      _eldEvents[i] = _eldEvent;

      // now that we have replaced the event with the clone that has the odometerStartKm, check to see if this is actually the event we needed to modify
      const vehicleOdometerReadings = getRawOdometerReadings(_eldEvents, 'km', vehicleKmType === 'accumulatedVehicleKm')[vehicleUnitId];
      if (vehicleOdometerReadings) {
        const vehicleOdometerReadingsOdometerStart = vehicleOdometerReadings.odometerStart;
        const vehicleOdometerReadingsOdometerEnd = vehicleOdometerReadings.odometerEnd;
        if (!startEldEvent && (vehicleOdometerReadingsOdometerStart === odometerStartKm)) {
          // we found the matching event we need to edit to modify the odometer start. so set it and forget it
          startEldEvent = eldEvent;
          // _eldEvents[i] = eldEvent;
          // i = i - 1;
        } else if (startEldEvent && (vehicleOdometerReadingsOdometerEnd === odometerEndKm)) {
          // look for the most recent end event that matches to the odometer readings we want
          endEldEvent = eldEvent;
          _eldEvents[i] = eldEvent;
          // break;
        } else {
          _eldEvents[i] = eldEvent;
        }
      } else {
        _eldEvents[i] = eldEvent;
      }
    }
  }
  // last ditch
  if (!endEldEvent && _eldEventsLen > 0) {
    endEldEvent = _eldEvents[_eldEventsLen - 1];
  }
  eldEventsToModify = { startEldEvent, endEldEvent };
  // console.log(odometerStartKm, odometerEndKm);
  // console.log(eldEventsToModify);
  return eldEventsToModify;
}

/**
 * @memberof module:ELD
 *
 * @param {*} eldEvents
 * @param {*} distanceUnit
 * @param {*} useAccumulated
 * @param {*} useVehicleUnitId
 * @param {*} useDrivingBool
 * @param {*} useCorrections
 *
 * @returns
 */
async function getRawOdometerReadings(eldEvents, distanceUnit, useAccumulated, useVehicleUnitId, useDrivingBool, useCorrections) {
  /*
   - Given eldEvents (usually of a particular driver/vehicle/filtered active events only) determine the first odometer reading
     and the last odometer reading without simulation
   - Seperate readings by vehicle (vehicles can change)
   - distanceUnit: km|mi|etc..

    Note that we cannot rely on sorting by odometerReading for max and min; eventDateTime is more of a guarantee for an accurate picture
    of odometer reading sequence/jumping

    We also cant rely directly on taking the readings of the first and last event as there may be gaps caused by the codriver driving
    To account for this, we get the first odo reading we see for this driver and then add sb/onnd blocks
  */
  const odometerReadingsByVehicle = {}; // all our info goes here
  const driverBreakStatuses = [12, 14]; // times when codriver takes over
  const vehicleKmType = !useAccumulated ? 'totalVehicleKm' : 'accumulatedVehicleKm';
  let dayHasCoDriver = false;

  let segmentStart = 0; // start->end of drivers activity w/o codriver
  let segmentEnd = 0;

  // get rid of all undefined or 0's for the reading type
  const _eldEvents = eldEvents.filter(eldEvent => eldEvent.get(vehicleKmType)).filter((eldEvent) => useDrivingBool ? (eldEvent.get('eldEventTypeCodeInt') === 21) : true);
  // sort ascending
  sortELDEvents(_eldEvents, 1);
  const _eldEventsLen = _eldEvents.length; // length after all filtering

  // most readable way to do things would to first figure out all the vehicles and when they were seen
  // this would also avoid edge cases where vehicles would be left out (use original eld events given)
  let lastSeenVehicle;
  let seenOrder = -1; // keeps track of when the vehicle was seen in case of vehicle changes

  for (let i = 0; i < eldEvents.length; i++) {
    if (!dayHasCoDriver && eldEvents[i].get('coDriver')) {
      dayHasCoDriver = true;
      break;
    }
  }

  // using vehicleUnitId
  let vehicleUnitIdMap = {};
  const filteredEldEvents = eldEvents.filter((eldEvent) => (eldEvent.get('vehicleUnitId') && eldEvent.get('company')));
  if (filteredEldEvents.length > 0) {
    const uniqueVehicleUnitIdArr = [];

    for (let i = 0; i < filteredEldEvents.length; i++) uniqueVehicleUnitIdArr.indexOf(filteredEldEvents[i].get('vehicleUnitId')) === -1 && filteredEldEvents[i].get('vehicleUnitId') && uniqueVehicleUnitIdArr.push(filteredEldEvents[i].get('vehicleUnitId'));
    const vehicleQuery = new Parse.Query('Vehicle');
    const companyObj = filteredEldEvents[0].get('company');
    vehicleQuery.equalTo('belongsToCompany', companyObj);
    vehicleQuery.containedIn('unitId', uniqueVehicleUnitIdArr);
    const vehicleArr = await vehicleQuery.find();
    for (let i = 0; i < vehicleArr.length; i++) {
      vehicleUnitIdMap[vehicleArr[i].get('unitId')] = vehicleArr[i];
    }
  }

  eldEvents.map(eldEvent => {
    const vehicle = (useVehicleUnitId ? vehicleUnitIdMap[eldEvent.get('vehicleUnitId')] : eldEvent.get('vehicle')) || eldEvent.get('vehicle') || vehicleUnitIdMap[eldEvent.get('vehicleUnitId')];

    if (vehicle && (!lastSeenVehicle || (vehicle.id !== lastSeenVehicle.id))) {
      const unitId = vehicle.get('unitId');
      seenOrder++;

      if (!odometerReadingsByVehicle[unitId]) {
        odometerReadingsByVehicle[unitId] = {
          vehicle,
          odometerStart: 0, // raw start
          odometerEnd: 0, // raw end
          odometerEndTotal: 0, // raw start + total
          total: 0, // total of driver odo activity w/o codriver
          seenOrder: [seenOrder],
          dayHasCoDriver, // determines priority of whether we want to use odometerEnd or odometerEndTotal
        };
      } else {
        odometerReadingsByVehicle[unitId].seenOrder.push(seenOrder);
      }

      lastSeenVehicle = vehicle;
    }
  });

  lastSeenVehicle = undefined; // re-use this

  // now calculate odometer readings
  let segmentStartEldEventIndex = 0; // start->end of drivers activity w/o codriver
  let segmentEndEldEventIndex = 0;
  let referenceOdometerStart = _eldEventsLen > 0 ? _eldEvents[0].get('totalVehicleKm') : 0;
  for (let i = 0; i < _eldEventsLen; i++) {
    const eldEvent = _eldEvents[i];
    const vehicle = (useVehicleUnitId ? vehicleUnitIdMap[eldEvent.get('vehicleUnitId')] : eldEvent.get('vehicle')) || eldEvent.get('vehicle') || vehicleUnitIdMap[eldEvent.get('vehicleUnitId')];
    // console.log(vehicleUnitIdMap);
    // if (!vehicle) {
    //   console.log(eldEvent.get('vehicle'), eldEvent.get('vehicleUnitId'), vehicleUnitIdMap[eldEvent.get('vehicleUnitId')]);
    // }
    const eventHasCoDriver = eldEvent.get('coDriver');
    const isDriverBreak = driverBreakStatuses.indexOf(eldEvent.get('eldEventTypeCodeInt')) !== -1; // typeCodeInt = 12 || 14
    let odometerReading = eldEvent.get(vehicleKmType);
    // const vehicleChanged = vehicle && (!lastSeenVehicle || (vehicle.id !== lastSeenVehicle.id));
    const vehicleChanged = vehicle && lastSeenVehicle && (vehicle.id !== lastSeenVehicle.id);

    // console.log(vehicle.get('unitId') + ': ' + eldEvent.get('totalVehicleKm') + ' | ' + eldEvent.get('eldEventTypeCodeInt'));

    if (!segmentStart) {
      segmentStart = odometerReading;
      segmentEnd = odometerReading;
    } else {
      segmentEnd = odometerReading;
      segmentEndEldEventIndex = i;
    }

    const jumpThreshold = 100;
    if (i !== 0 && (segmentEnd - segmentStart < 0 || segmentEnd - segmentStart > jumpThreshold) && useCorrections) {
      const filteredOdometerReadingsObj = filterOdometerReadingsJumpNew(_eldEvents.slice(segmentStartEldEventIndex, segmentEndEldEventIndex + 1), jumpThreshold);
      segmentStart = filteredOdometerReadingsObj.odometerStart;
      odometerReading = filteredOdometerReadingsObj.odometerEnd;
      segmentEnd = odometerReading;
    }

    if (vehicleChanged || (isDriverBreak && eventHasCoDriver) || i === _eldEventsLen - 1) {
      // changed vehicles
      const unitId = vehicle.get('unitId');
      const lastSeenVehicleUnitId = lastSeenVehicle ? lastSeenVehicle.get('unitId') : vehicle.get('unitId');
      const difference = ((segmentEnd - segmentStart) < 0) ? 0 : (segmentEnd - segmentStart); // if difference is negative, return 0

      if (!odometerReadingsByVehicle[lastSeenVehicleUnitId].odometerStart) {
        odometerReadingsByVehicle[lastSeenVehicleUnitId].odometerStart = segmentStart;
        // if (!referenceOdometerStart) {
        //   // For when using accumulated, will get the odometerStart then use it to reference
        //   console.log(referenceOdometerStart);
        //   referenceOdometerStart = eldEvent.get('totalVehicleKm');
        // }
        odometerReadingsByVehicle[lastSeenVehicleUnitId].odometerEnd = odometerReading;
      }
      if (odometerReading > odometerReadingsByVehicle[lastSeenVehicleUnitId].odometerEnd) {
        odometerReadingsByVehicle[lastSeenVehicleUnitId].odometerEnd = odometerReading;
      }
      odometerReadingsByVehicle[lastSeenVehicleUnitId].total += difference;
      odometerReadingsByVehicle[lastSeenVehicleUnitId].odometerEndTotal = odometerReadingsByVehicle[lastSeenVehicleUnitId].odometerStart + odometerReadingsByVehicle[lastSeenVehicleUnitId].total;

      if (_eldEventsLen > 1 && (i !== 0)) {
        segmentStart = 0;
        segmentEnd = 0;
        segmentStartEldEventIndex = i;
      }

      lastSeenVehicle = vehicle;
    }

    // if (!segmentStart) {
    //   segmentStart = odometerReading;
    // }
    // else {
    //   segmentEnd = odometerReading;
    // }
  }

  if (useAccumulated) {
    const unitIds = Object.keys(odometerReadingsByVehicle);
    // use referenceOdometerStart
    unitIds.map(unitId => {
      const vehicleOdometerReading = odometerReadingsByVehicle[unitId];
      vehicleOdometerReading.odometerStart = vehicleOdometerReading.odometerStart + referenceOdometerStart;
      vehicleOdometerReading.odometerEnd = vehicleOdometerReading.odometerEnd + referenceOdometerStart;
      vehicleOdometerReading.odometerEndTotal = vehicleOdometerReading.odometerEndTotal + referenceOdometerStart;
      vehicleOdometerReading.total = vehicleOdometerReading.total;
    });
  }

  if (distanceUnit && distanceUnit.toLowerCase() === 'mi') {
    const unitIds = Object.keys(odometerReadingsByVehicle);
    unitIds.map(unitId => {
      const vehicleOdometerReading = odometerReadingsByVehicle[unitId];
      vehicleOdometerReading.odometerStart = Helpers.convertDistance(vehicleOdometerReading.odometerStart, 'km', 'mi', true);
      vehicleOdometerReading.odometerEnd = Helpers.convertDistance(vehicleOdometerReading.odometerEnd, 'km', 'mi', true);
      vehicleOdometerReading.odometerEndTotal = Helpers.convertDistance(vehicleOdometerReading.odometerEndTotal, 'km', 'mi', true);
      vehicleOdometerReading.total = Helpers.convertDistance(vehicleOdometerReading.total, 'km', 'mi', true);
    });
  }

  let totalDifference = 0;
  if (useCorrections) {
    const unitIds = Object.keys(odometerReadingsByVehicle);
    for (let i = 0; i < unitIds.length; i++) {
      const unitId = unitIds[i];
      const vehicleOdometerReadingObj = odometerReadingsByVehicle[unitId];
      totalDifference += vehicleOdometerReadingObj.total;
      if (totalDifference > 0) break;
    }
  }

  if (useCorrections && totalDifference === 0) {
    return getRawOdometerReadings(eldEvents, distanceUnit, useAccumulated, useVehicleUnitId, useDrivingBool, false);
  } else {
    return odometerReadingsByVehicle;
  }

  // console.log(odometerReadingsByVehicle);
}

/**
 * @memberof module:ELD
 *
 * @param {*} eldEvents
 * @param {*} startTimeUTC
 * @param {*} driver
 *
 * @returns
 */
function findCoDriverInfoFromEvents(eldEvents, startTimeUTC, driver) {
  // given a list of eld events, figure out which codrivers are involved in them
  // return more info if dailyCertification date is provided
  const promise = new Promise((resolve, reject) => {
    const seenCoDriverIds = {};
    const coDrivers = [];
    for (let i = 0; i < eldEvents.length; i++) {
      const event = eldEvents[i];
      if (event.get('coDriver') && !seenCoDriverIds[event.get('coDriver').id]) {
        coDrivers.push(event.get('coDriver'));
        seenCoDriverIds[event.get('coDriver').id] = true;
      }
    }

    // now that we have our initial list of codrivers that we can find from these eld events,
    // figure out if our driver is a co-driver for someone else on this day (using startTimeUTC)
    // the driver we find from said query would also be co-drivers that our initial search missed out on
    // query: for the most recent eldevent in the date interval provided (from startTimeUTC)
    // where the co-driver field is the 'main' driver; retrieve the driver of those events
    const queryIncludes = ['eldDailyCertification', 'driver', 'driver.user'];
    let dayOf = new Date(startTimeUTC);
    dayOf = moment.utc(dayOf);
    let dayAfter = moment.utc(dayOf);
    dayAfter.hours(24 + dayOf.hours());

    dayOf = dayOf.toDate();
    dayAfter = dayAfter.toDate();

    const driverAsCoDriverEventQuery = new Parse.Query('ELDEvent');
    driverAsCoDriverEventQuery.greaterThanOrEqualTo('eventDateTime', dayOf);
    driverAsCoDriverEventQuery.lessThan('eventDateTime', dayAfter);
    driverAsCoDriverEventQuery.exists('driver');
    driverAsCoDriverEventQuery.exists('eldDailyCertification');
    driverAsCoDriverEventQuery.equalTo('coDriver', driver);
    driverAsCoDriverEventQuery.equalTo('eldEventRecordStatusInt', 1);
    driverAsCoDriverEventQuery.descending('eventDateTime');
    driverAsCoDriverEventQuery.include(queryIncludes);

    driverAsCoDriverEventQuery.first().then(
      eldEvent => {
        if (eldEvent && !seenCoDriverIds[eldEvent.get('driver').id]) {
          // if this event has a driver and we have not seen it before, add it to list of codrivers
          coDrivers.push(eldEvent.get('driver'));
          seenCoDriverIds[eldEvent.get('driver').id] = true;
        }

        const coDriverInfoPromises = [];
        for (let j = 0; j < coDrivers.length; j++) {
          (() => {
            const coDriver = coDrivers[j];
            const eldDailyCertificationQuery = new Parse.Query('ELDDailyCertification');
            eldDailyCertificationQuery.greaterThanOrEqualTo('startTimeUTC', dayOf);
            eldDailyCertificationQuery.lessThan('startTimeUTC', dayAfter);
            eldDailyCertificationQuery.equalTo('driver', coDriver);
            eldDailyCertificationQuery.include(['driver', 'driver.user']);
            coDriverInfoPromises.push(eldDailyCertificationQuery.first());
          })();
        }
        Promise.all(coDriverInfoPromises).then(
          coDriverInfo => {
            resolve(coDriverInfo);
          },
          error => reject(error)
        );
      }
    );
  });
  return promise;
}

/**
 * @memberof module:ELD
 *
 * @param {*} order
 * @param {*} eldEvents
 *
 * @returns
 */
function sortByEventDateTime(order, eldEvents) {
  let events = [].concat(eldEvents);
  // ascending order: 0
  if (!order) {
    events = events.sort((eventA, eventB) => eventA.get('eventDateTime').getTime() - eventB.get('eventDateTime').getTime());
  } else {
    events = events.sort((eventA, eventB) => eventB.get('eventDateTime').getTime() - eventA.get('eventDateTime').getTime());
  }
  return events;
}

/**
 * @memberof module:ELD
 * @param {*} refInt
 * @returns
 */
function getDutyStatusDesc(refInt) {
  // a quick way out
  let description;
  if (refInt === 11) {
    description = 'Off Duty';
  } else if (refInt === 12) {
    description = 'Sleeper Berth';
  } else if (refInt === 13) {
    description = 'Driving';
  } else if (refInt === 14) {
    description = 'On Duty';
  } else if (refInt === 31) {
    description = 'Personal Use CMV';
  } else if (refInt === 32) {
    description = 'Yard Moves';
  }
  return description;
}

/**
 * @memberof module:ELD
 * @returns
 */
function getUnidentifiedDrivers() {
  // its called get unidentified drivers but we get them through vehicles
  const promise = new Promise((resolve, reject) => {
    // query all vehicles for their eld events. limit queries to those who have unidentified drivers
    const vehicleQuery = new Parse.Query('Vehicle');
    vehicleQuery.include(['eldHardware', 'licensePlate']);
    vehicleQuery.equalTo('belongsToCompany', Parse.User.current().get('belongsToCompany'));
    Getters.getAllFromQuery(vehicleQuery).then(
      allVehicles => {
        // filter vehicles for those with aobrdEnabled false
        const vehicles = allVehicles.filter(vehicle => {
          let passable = true; // passable by default
          if (vehicle.get('eldHardware') && vehicle.get('eldHardware').get('aobrdEnabled')) {
            // hardware exists and is aobrdEnabled -> unpassable
            passable = false;
          }
          return passable;
        });

        // console.log(vehicles);

        let unidentifiedDriverObjects = [];
        const unidentifiedPromises = [];

        const eldQueryIncludes = [
          'eldHardware',
          'eldDailyCertification',
          'vehicle',
          'vehicle.licensePlate',
          'vehicleLocation',
          'driver',
          'driver.user',
          'coDriver',
          'coDriver.user',
          'company',
        ];

        for (let i = 0; i < vehicles.length; i++) {
          const vehicle = vehicles[i];
          unidentifiedDriverObjects.push({ vehicle });

          const eldEventQuery = new Parse.Query('ELDEvent');
          eldEventQuery.equalTo('vehicle', vehicle);
          eldEventQuery.equalTo('unidentifiedDriver', true);
          eldEventQuery.equalTo('eldEventRecordStatusInt', 1);
          eldEventQuery.descending('eventDateTime');
          eldEventQuery.include(eldQueryIncludes);
          unidentifiedPromises.push(Getters.getAllFromQuery(eldEventQuery));
        }

        // now that we have all vehicles, get all events of that vehicle that have unidentified driver true
        const indexesToRemove = [];
        Promise.all(unidentifiedPromises).then(
          respectiveELDEvents => {
            for (let i = 0; i < respectiveELDEvents.length; i++) {
              if (respectiveELDEvents[i].length === 0) {
                // if there are no unidentified drivers for the vehicle, remove it from our return objectId
                indexesToRemove.push(i);
                continue;
              }
              unidentifiedDriverObjects[i].unidentifiedDriverEvents = respectiveELDEvents[i];
            }

            unidentifiedDriverObjects = unidentifiedDriverObjects.filter((obj, index) => {
              if (indexesToRemove.indexOf(index) !== -1) {
                return false;
              }
              return true;
            });

            // now we have unidentifieddriverobjects with only unidentified driver events
            // we have to make sure to only show events that are not in eldedit or eldedit status 1 (rejected)
            let allUnidentifiedEvents = [];
            for (let j = 0; j < unidentifiedDriverObjects.length; j++) {
              allUnidentifiedEvents = allUnidentifiedEvents.concat(unidentifiedDriverObjects[j].unidentifiedDriverEvents);
            }

            const editCheckPromises = [];
            for (let k = 0; k < allUnidentifiedEvents.length; k++) {
              const eldEditQuery = new Parse.Query('ELDEdit');
              eldEditQuery.equalTo('belongsToCompany', Parse.User.current().get('belongsToCompany'));
              eldEditQuery.containedIn('eldEventsToBeInactive', [allUnidentifiedEvents[k]]);
              eldEditQuery.descending('requestedAt'); // we want to find all, but only use the most recent
              editCheckPromises.push(eldEditQuery.find());
            }

            const unidentifiedEventsToHide = [];
            Promise.all(editCheckPromises).then(
              editsForEachUnidentifiedEvent => {
                for (let l = 0; l < editsForEachUnidentifiedEvent.length; l++) {
                  const editsForAnUnidentifiedEvent = editsForEachUnidentifiedEvent[l];
                  const mostRecentEditForAnEvent = editsForAnUnidentifiedEvent[0];
                  if (mostRecentEditForAnEvent && (mostRecentEditForAnEvent.get('completed') === 0 || mostRecentEditForAnEvent.get('completed') === 2)) {
                    unidentifiedEventsToHide.push(allUnidentifiedEvents[l]); // get the corresponding event to this edit
                  }
                }

                const unidentifiedEventsToHideIds = unidentifiedEventsToHide.map(event => event.id);

                // finally, remove these events
                for (let m = 0; m < unidentifiedDriverObjects.length; m++) {
                  let n = unidentifiedDriverObjects[m].unidentifiedDriverEvents.length;
                  while (n--) {
                    const unidentifiedDriverEvent = unidentifiedDriverObjects[m].unidentifiedDriverEvents[n];
                    if (unidentifiedEventsToHideIds.indexOf(unidentifiedDriverEvent.id) !== -1) {
                      unidentifiedDriverObjects[m].unidentifiedDriverEvents.splice(n, 1);
                    }
                  }
                }

                // now iterate through all unidentifieddriverobjects, if there are no unidentified events in them,
                // remove that object
                let o = unidentifiedDriverObjects.length;
                while (o--) {
                  if (unidentifiedDriverObjects[o].unidentifiedDriverEvents.length === 0) {
                    unidentifiedDriverObjects.splice(o, 1);
                  }
                }

                resolve(unidentifiedDriverObjects);
              }
            );
          }
        );
      }
    );
  });
  return promise;
}

/**
 * @memberof module:ELD
 * @param {*} vehicle
 * @returns
 */
function getVehicleDriverHistory(vehicle) {
  const promise = new Promise((resolve, reject) => {
    const vehicleDriverQuery = new Parse.Query('VehicleDriverHistory');
    vehicleDriverQuery.equalTo('vehicle', vehicle);
    vehicleDriverQuery.include(['vehicle.eldHardware', 'vehicle.licensePlate', 'vehicle.weighStationBypassVehicle', 'driver.user', 'driver.weighStationBypassDriver']);
    vehicleDriverQuery.find().then(
      vehicleDriverHistories => {
        resolve(vehicleDriverHistories);
      },
      error => reject(error)
    );
  });
  return promise;
}

// function getVehiclesFromEvents(eldEvents) {
//   // get all vehicles from eld events in the order seen
//   const seen = {}; // keep track of which vehicles we already went over
//   const vehicles = [];

//   // ascending order
//   const events = [].concat(eldEvents).filter((event) => event.get('vehicle') && event.get('eldEventRecordStatusInt') === 1);

//   // events.sort((eventA, eventB) => eventA.get('eventDateTime').getTime() - eventB.get('eventDateTime').getTime());

//   for (let i = 0, eventsLen = events.length; i < eventsLen; i++) {
//     const event = events[i];
//     const vehicle = event.get('vehicle');
//     if (vehicle && !seen[vehicle.id]) {
//       vehicles.push(vehicle);
//       seen[vehicle.id] = true;
//     }
//   }

//   return vehicles;
// }

/**
 * @memberof module:ELD
 *
 * @param {*} eldDailyCertificationIds
 * @param {*} logDatesChosen
 * @param {*} driverIds
 * @param {*} returnSingleFile
 * @param {*} disregardLogs
 * @param {*} getOriginalLogs
 * @param {*} printPreTrips
 * @param {*} printCTPAT
 * @param {*} formatFMCSACompliant
 * @param {*} email
 * @param {*} recapHoursArr
 * @param {*} showHOSViolations
 * @param {*} fileTitle
 *
 * @returns
 */
function generateHOSLogs(
  eldDailyCertificationIds,
  logDatesChosen,
  driverIds,
  returnSingleFile,
  disregardLogs,
  getOriginalLogs,
  getUDLogs,
  printPreTrips,
  printCTPAT,
  printPostTrips,
  formatFMCSACompliant,
  email,
  recapHoursArr,
  showHOSViolations,
  fileTitle,
  fileComment,
  sendToNSC,
  vehicleUnitIds
) {
  let disableInstantSuccess = true;
  if (email) {
    disableInstantSuccess = false;
  }
  const promise = new Promise((resolve, reject) => {
    Getters.getCurrentDispatcher(true).then(
      dispatcher => {
        Parse.Cloud.run('generateHOSLogs', {
          eldDailyCertificationIds,
          logDatesChosen,
          driverIds,
          returnSingleFile,
          disregardLogs,
          getOriginalLogs,
          getUDLogs,
          printPreTrips,
          printCTPAT,
          printPostTrips,
          distanceUnit: dispatcher.get('distanceUnit'),
          email,
          disableInstantSuccess,
          recapHoursArr,
          showHOSViolations,
          fileTitle,
          sendToNSC,
          sendToNSCEmailServicesHash: sendToNSC ? { emailAddress: email, outputFileComment: fileComment } : {},
          vehicleUnitIds,
        }).then((response) => {
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      }
    );
  });
  return promise;
}

/**
 * @memberof module:ELD
 *
 * @param {*} date
 * @param {*} driverId
 * @param {*} hideHidden
 *
 * @returns
 */
function generateHOSViolationMonthlyReport(date, driverId, hideHidden) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('generateHOSViolationMonthlyReport', {
      date,
      driverId,
      hideHidden,
    }).then(
      parseFileObject => {
        resolve(parseFileObject);
      },
      error => {
        reject(error);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:ELD
 * @description gets the latest version from the MasonVersion table
 * @returns the current version (e.g. 1.75)
 */
async function getCurrentELDVersion() {
  const currentELDQuery = await ParseAPI.createQuery('MasonVersion');
  currentELDQuery[QuerySortOrderTypes.DESCENDING](AttributeTypes.VERSION_CODE);
  const currentELD = await ParseAPI.find(currentELDQuery, true, false);
  const currentELDVersion = parseFloat(currentELD.get(AttributeTypes.VERSION_NAME));
  return currentELDVersion;
}

/**
 * @memberof module:ELD
 * @description gets the createdAt date of the specified version from the MasonVersion table
 *
 * @param {number} version - current version of the ELD (e.g. 1.75)
 * @returns {ELDDate} a Date
 */
async function getELDDate(version) {
  // if no version specified
  if (!version) {
    return false;
  }
  const ELDDateQuery = await ParseAPI.createQuery('MasonVersion');
  const versionStr = version.toString();
  ParseAPI.setQueryRestriction(ELDDateQuery, QueryRestrictionTypes.EQUAL_TO, AttributeTypes.VERSION_NAME, versionStr);
  const currentELD = await ParseAPI.find(ELDDateQuery, true, false);
  if (currentELD) {
    // how through does the enums have to be, createdAt won't ever be changed would it?
    const ELDDate = currentELD.get(AttributeTypes.CREATED_AT);
    return ELDDate;
  }
  return false;
}

/**
 * @memberof module:ELD
 * @description given an array of drivers, returns an array of object with driver's id and the date of their ELD version
 *
 * @param {array} drivers - an array of drivers object
 * @returns - an array of object of the format [ {id: driversId, date: Date() object}, ... ] for all drivers
 */
async function mapELDDates(drivers) {
  if (drivers) {
    return await Promise.all(drivers.map(driverObj => {
      const ELDDateObj = {};
      ELDDateObj.id = driverObj.id;
      const ELDVersion = driverObj.get('currentELDVersion');
      getELDDate(ELDVersion).then(date => {
        ELDDateObj.date = date;
      });
      return ELDDateObj;
    }));
  }
  return [];
}

/**
 * @memberof module:ELD
 * @description given a driver's object, check whether the driver's ELD version is older than 2 weeks
 *
 * @param {*} driverObj - a driver object
 * @returns a boolean, true if version is out of date and false otherwise
 */
async function shouldDriverUpdateVersion(driverObj) {
  return new Promise(async function (resolve) {
    const ELDVersion = driverObj.get('currentELDVersion');
    const date = await getELDDate(ELDVersion);
    let result = false;
    if (date) {
      const durationDiff = Date.now() - date;
      const duration = moment.duration(durationDiff, 'milliseconds');
      const diffInWeeks = duration.asWeeks();
      (diffInWeeks >= 2) ? result = true : null;
    }
    resolve(result);
  });
}

/**
 * @memberof module:ELD
 * @description given an array of driver objects, filter and return an
 *              array of drivers whose version is older than 2 weeks
 *
 * @param {*} drivers - an array  of drivers
 * @returns an array (filtered) of driver objects
 */
async function filterELDVersionUpdate(drivers) {
  if (drivers) {
    const promises = [];
    for (let i = 0; i < drivers.length; i++) {
      promises.push(shouldDriverUpdateVersion(drivers[i]));
    }
    const results = await Promise.all(promises);
    const filtered = [];

    for (let i = 0; i < drivers.length; i++) {
      if (results[i]) {
        filtered.push(drivers[i]);
      }
    }
    return filtered;
  }
  return [];
}

/**
 * @memberof module:ELD
 *
 * @param {*} eldDailyCertification
 * @param {*} onDate
 * @param {*} completedIntArr
 * @param {*} driver
 * @param {*} resultsLimit
 *
 * @returns
 */
function getELDEdits(eldDailyCertification, onDate, completedIntArr, driver, resultsLimit) {
  // get the company's edits currently requested
  // 0 - unfinished
  // 1 - Rejected
  // 2 - Completed
  const promise = new Promise((resolve, reject) => {
    const eldEditQuery = new Parse.Query('ELDEdit');

    eldEditQuery.equalTo('belongsToCompany', Parse.User.current().get('belongsToCompany'));
    eldEditQuery.include(['requestedBy', 'requestedBy.user', 'eldDailyCertification', 'eldDailyCertification.driver', 'eldDailyCertification.driver.user', 'requestedELDEvents', 'eldEventsToBeInactive']);
    eldEditQuery.descending('requestedAt');

    if (completedIntArr !== undefined && completedIntArr.length > 0) {
      eldEditQuery.containedIn('completed', completedIntArr);
    }

    if (driver) {
      eldEditQuery.equalTo('driver', driver);
    }

    let dayOf;
    let dayAfter;

    if (eldDailyCertification && eldDailyCertification.id) {
      eldEditQuery.equalTo('eldDailyCertification', eldDailyCertification);
    } else if (eldDailyCertification) {
      // spoofed daily cert
      const timezoneOffsetFromUTC = driver.get('timezoneOffsetFromUTC') || momentTz.tz.guess();
      dayOf = eldDailyCertification.get('startTimeUTC');
      dayAfter = momentTz(dayOf).tz(timezoneOffsetFromUTC).add(1, 'day').toDate();
      eldEditQuery.greaterThanOrEqualTo('eldDailyCertificationStartTimeUTC', dayOf);
      eldEditQuery.lessThan('eldDailyCertificationStartTimeUTC', dayAfter);
    } else if (onDate) {
      dayOf = moment.utc(onDate).startOf('day');
      dayAfter = moment.utc(dayOf).add(1, 'day');
      dayOf = dayOf.toDate();
      dayAfter = dayAfter.toDate();

      if (driver) {
        const startEndTimeUTC = getELDDailyCertificationIntervalFromDriverTZ(driver, onDate);
        dayOf = startEndTimeUTC.dayOf;
        dayAfter = startEndTimeUTC.dayAfter;
      }
      eldEditQuery.greaterThanOrEqualTo('eldDailyCertificationStartTimeUTC', dayOf);
      eldEditQuery.lessThan('eldDailyCertificationStartTimeUTC', dayAfter);
    }

    Getters.getAllFromQuery(eldEditQuery, resultsLimit).then(
      eldEdits => {
        // filter for only last 14 days of edits if applies
        let _eldEdits = [].concat(eldEdits);
        _eldEdits = _eldEdits.filter(eldEdit => {
          if (!eldEdit.get('eldDailyCertification') && !eldEdit.get('eldDailyCertificationStartTimeUTC')) {
            // remove errored edits
            return false;
          }

          const driver = eldEdit.get('driver');
          const timezoneOffsetFromUTC = (driver && driver.get('timezoneOffsetFromUTC')) || momentTz.tz.guess();

          const currentDate = momentTz().tz(timezoneOffsetFromUTC).startOf('day');
          const fourteenDaysAgo = momentTz(currentDate).tz(timezoneOffsetFromUTC).subtract(14, 'days');

          const currentELDVersion = driver && driver.get('currentELDVersion');

          if (currentELDVersion && (parseFloat(currentELDVersion) > 0.89)) {
            return true;
          }

          let startTimeUTC = eldEdit.get('eldDailyCertificationStartTimeUTC');
          if (eldEdit.get('eldDailyCertification')) {
            startTimeUTC = eldEdit.get('eldDailyCertification').get('startTimeUTC');
          }
          startTimeUTC = momentTz(startTimeUTC).tz(timezoneOffsetFromUTC);
          if (startTimeUTC) {
            return startTimeUTC.valueOf() >= fourteenDaysAgo.valueOf();
          }
          return false;
        });

        for (let i = 0; i < _eldEdits.length; i++) {
          // mark if edit is aobrd (because completed 0 can be eld and aobrd)
          const eldEdit = _eldEdits[i];
          const eldEventsToBeInactive = eldEdit.get('eldEventsToBeInactive') || [];
          if (isAOBRDEnabledELDEvents(eldEventsToBeInactive)) {
            eldEdit.aobrdEnabled = true;
          }
        }
        resolve(_eldEdits);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:ELD
 * @param {*} eldEvent
 * @returns
 */
function isAutoGeneratedDrivingTime(eldEvent, ignoreZeroSpeedEvents) {
  const drivingEventTypeCodeInts = [13, 21, 22];
  const isDrivingEvent = drivingEventTypeCodeInts.indexOf(eldEvent.get('eldEventTypeCodeInt')) !== -1;
  let isConsideredAutoGeneratedDrivingTime = isDrivingEvent && eldEvent.get('eldEventRecordStatusInt') === 1 && (eldEvent.get('eldEventRecordOriginInt') === 0 || eldEvent.get('eldEventRecordOriginInt') === 1);
  if (ignoreZeroSpeedEvents) {
    const speedKm = getAttribute(eldEvent, 'speedKm');
    isConsideredAutoGeneratedDrivingTime = isConsideredAutoGeneratedDrivingTime && (speedKm !== 0);
  }
  return isConsideredAutoGeneratedDrivingTime;
}

// function isDrivingEditViolation(eldEvents = [], eldEvent) {
//   // faux insert eldEvent (or something signifying the event) into eldEvents and sort ascending
//   // find that eldEvent, and if the event before it is a driving event, that is a violation
//   // the eldEvent MUST have an eventDateTime property whether its a .eventDateTime or .get('eventDateTime')
//   let eldEventClone;
//   if (eldEvent.id && eldEvent._objCount && eldEvent.className && eldEvent.className.toLowerCase() === 'eldevent') {
//     // this is a parse object
//     eldEventClone = eldEvent.clone();
//   } else {
//     // custom object
//     eldEventClone = Helpers.createTempParseObject('ELDEvent', { ...eldEvent });
//   }

//   eldEventClone.marked = true;

//   const _eldEvents = sortByEventDateTime(0, [].concat(eldEvents, [eldEventClone]));
//   for (let i = 0; i < _eldEvents.length; i++) {
//     const eldEvent = _eldEvents[i];
//     const previousEvent = _eldEvents[i - 1];
//     const nextEvent = _eldEvents[i + 1];

//     if (eldEvent.marked) {
//       if (previousEvent && isAutoGeneratedDrivingTime(previousEvent)) {
//         return true;
//       }

//       if (nextEvent && nextEvent.get('eventDateTime').getTime() === eldEvent.get('eventDateTime').getTime() && isAutoGeneratedDrivingTime(nextEvent)) {
//         return true;
//       }

//       return false;
//     }
//   }

//   return false;
// }

/**
 * @memberof module:ELD
 * @description Get AGDT intervals
 * @param {Boolean} [reduceStrictness] - Amount of leeway given to edit inside an AGDT interval
 * @param {String} [timezoneOffsetFromUTC] - Timezone string to scale values to. Used with returnAsDateTimeStamps
 * @param {Boolean} [returnAsDateTimeStamps] - Returns the intervals as timestamps instead of millisecond values
 *
 * @returns
 */
function getAutogeneratedDrivingTimeIntervals(eldEvents = [], reduceStrictness, timezoneOffsetFromUTC = momentTz.tz.guess(), returnAsDateTimeStamps) {
  const STRICTNESS_MS = 900000; // 15 minutes of reduced strictness, if reduceStrictness
  const dateTimeStampFormat = 'YYYY-MM-DDTHH:mm:ss.SSSSZ'; // important format. retains timezone and all seconds values
  const _eldEvents = sortELDEvents(eldEvents, 1); // sort ascending

  // first get all instances of autogen driving intervals [ [startMs, endMs], [startMs, endMs], ... ]
  let autogenDrivingTimeIntervals = [];
  if ((eldEvents.length === 1) || (eldEvents.length === 0)) return autogenDrivingTimeIntervals;

  let agdtStartMs; // keep track of the autogen driving interval we're currently looking for

  _eldEvents.map((eldEvent, index) => {
    const eventDateTimeMs = momentTz(eldEvent.get('eventDateTime')).valueOf();
    if (isAutoGeneratedDrivingTime(eldEvent, reduceStrictness) && (agdtStartMs === undefined)) {
      agdtStartMs = eventDateTimeMs;
    } else if (!isAutoGeneratedDrivingTime(eldEvent, reduceStrictness) && (agdtStartMs !== undefined)) {
      // end of the interval
      if (!returnAsDateTimeStamps) {
        autogenDrivingTimeIntervals.push([agdtStartMs, eventDateTimeMs]);

      } else {
        autogenDrivingTimeIntervals.push([
          momentTz(agdtStartMs).tz(timezoneOffsetFromUTC).format(dateTimeStampFormat),
          momentTz(eventDateTimeMs).tz(timezoneOffsetFromUTC).format(dateTimeStampFormat),
        ]);
      }
      agdtStartMs = undefined;
    } else if ((agdtStartMs !== undefined) && (index === _eldEvents.length - 1)) {
      // end of the array
      if (!returnAsDateTimeStamps) {
        autogenDrivingTimeIntervals.push([agdtStartMs, eventDateTimeMs]);
      } else {
        autogenDrivingTimeIntervals.push([
          momentTz(agdtStartMs).tz(timezoneOffsetFromUTC).format(dateTimeStampFormat),
          momentTz(eventDateTimeMs).tz(timezoneOffsetFromUTC).format(dateTimeStampFormat),
        ]);
      }
      agdtStartMs = undefined;
    }
  });

  if (reduceStrictness) {
    const reducedStrictnessAutogenDrivingTimeIntervals = [];
    autogenDrivingTimeIntervals.map(interval => {
      const startMs = momentTz(interval[0]);
      const endMs = momentTz(interval[1]);

      // get the length of the interval in milliseconds
      const duration = momentTz.duration(endMs.diff(startMs));
      const durationMs = duration.asMilliseconds();

      /**
       * If an interval duration is greater than the strictness threshold, that means there is time in between
       * (startMs + threshold) and (endMs - threshold) that can be considered editable with reduceStrictness
       *
       * If such is the case, we will split the current agdt [startMs, endMs] duration into
       * [startMs, startMs + threshold] and [endMs - threshold, endMs], leaving the time that is in between as non-agdt
       *
       * The 2 new intervals will get pushed into reducedStrictnessAutogenDrivingTimeIntervals, instead of interval, which
       * reducedStrictnessAutogenDrivingTimeIntervals will eventually be the new autogenDrivingTimeIntervals
       */
      if (durationMs > STRICTNESS_MS) {
        const firstSplitInterval = [startMs.valueOf(), startMs.valueOf() + STRICTNESS_MS];
        const secondSplitInterval = [endMs.valueOf() - STRICTNESS_MS, endMs.valueOf()];

        if (returnAsDateTimeStamps) {
          firstSplitInterval[0] = moment(firstSplitInterval[0]).tz(timezoneOffsetFromUTC).format(dateTimeStampFormat);
          firstSplitInterval[1] = moment(firstSplitInterval[1]).tz(timezoneOffsetFromUTC).format(dateTimeStampFormat);
          secondSplitInterval[0] = moment(firstSplitInterval[0]).tz(timezoneOffsetFromUTC).format(dateTimeStampFormat);
          secondSplitInterval[1] = moment(firstSplitInterval[1]).tz(timezoneOffsetFromUTC).format(dateTimeStampFormat);
        }

        reducedStrictnessAutogenDrivingTimeIntervals.push(firstSplitInterval, secondSplitInterval);
      } else {
        reducedStrictnessAutogenDrivingTimeIntervals.push(interval);
      }
    });

    autogenDrivingTimeIntervals = reducedStrictnessAutogenDrivingTimeIntervals;
  }

  return autogenDrivingTimeIntervals;
}

/**
 * @memberof module:ELD
 * @description Determine if an autgen driving event would be overwritten or manipulated due to an edit
 * @param {Array} eldEvents - The list of events to check against
 * @param {DateTime} editStartDateTime - Start of the dateTime interval
 * @param {DateTime} editEndDateTime - End of the dateTime interval
 * @param {Boolean} [reduceStrictness] - Amount of leeway given to edit inside an AGDT interval
 *
 * @returns
 */
function isDrivingEditViolation(eldEvents = [], editStartDateTime, editEndDateTime, reduceStrictness = true) {
  const _eldEvents = sortELDEvents(eldEvents, 1); // sort ascending

  // if there's only one event in the day and it's an autogen event (it means the whole day is autogen)
  if ((_eldEvents.length === 1) && isAutoGeneratedDrivingTime(_eldEvents[0], reduceStrictness)) {
    return true;
  }

  // the edit request eventdatetime start and end
  const editStartDateTimeMs = momentTz(editStartDateTime).valueOf();
  const editEndDateTimeMs = momentTz(editEndDateTime).valueOf();

  const autogenDrivingTimeIntervals = getAutogeneratedDrivingTimeIntervals(_eldEvents, reduceStrictness);

  /**
   * now that we have all agdt intervals, there are 2 ways to determine if the user is trying to edit agdt
   * you can imagine that each interval is like a agdt line from startMs - endMs
   *
   * Case 1. If the edit start time is in any of these agdt intervals, then we know the edit cuts into agdt
   * Case 2. If the edit start time and edit end time eclipse an interval start time
   *          Ex. agdt interval from 5:00 - 6:00. Event interval requested from 3:00 - 8:00. Then we know the edit overwrites the interval
   *          agdt interval from 5:00 - 6:00. Event interval requested from 5:00 - 5:30. Then we know the edit writes into the interval
   */

  for (let i = 0; i < autogenDrivingTimeIntervals.length; i++) {
    const agdtInterval = autogenDrivingTimeIntervals[i];
    const intervalStartMs = agdtInterval[0];
    const intervalEndMs = agdtInterval[1];

    // case 1
    if ((editStartDateTimeMs >= intervalStartMs) && (editStartDateTimeMs < intervalEndMs)) {
      return true;
    }

    // case 2
    if ((intervalStartMs >= editStartDateTimeMs) && (intervalStartMs <= editEndDateTimeMs)) {
      return true;
    }

  }

  return false;
}

/**
 * @deprecrated -- Was used on the old ELDEditModal. Use/modify getAutogeneratedDrivingTimeIntervals instead
 * Obtains all the AGDT intervals within the start/end times - this only applies for a single day
 * @param {Array} eldEvents Array of ELDEvents
 * @param {String} startDateTime The start time to check for AGDT events
 * @param {String} endDateTime The end time to check for AGDT events
 * @param {Boolean} ignoreIgnitionEvents Whether or not to ignore ignition events when finding AGDT intervals
 * @returns Returns an array of arrays containing the ms of the intervals ([ [startMs, endMs], [startMs, endMs] ])
 */
function getAGDTIntervals(eldEvents = [], startDateTime, endDateTime, ignoreIgnitionEvents) {
  // the start and end time which we want to find ADGT intervals
  const startDateTimeMs = momentTz(startDateTime).valueOf();
  const endDateTimeMs = momentTz(endDateTime).valueOf();

  // We filter out the events that lie within the start/end times
  let _eldEvents = sortELDEvents(eldEvents, 1).filter((eldEvent) => {
    const eventDateTimeMs = momentTz(getAttribute(eldEvent, 'eventDateTime')).valueOf();
    return (eventDateTimeMs >= startDateTimeMs && eventDateTimeMs <= endDateTimeMs);
  });

  // If the parameter is set, then we ignore the ignition events when looking for AGDT intervals
  if (ignoreIgnitionEvents) {
    _eldEvents = _eldEvents.filter((eldEvent) => {
      const eldEventTypeCodeInt = getAttribute(eldEvent, 'eldEventTypeCodeInt');
      const isIgnitionEvent = [61, 62, 63, 64].includes(eldEventTypeCodeInt);
      if (isIgnitionEvent) return false;
      return true;
    });
  }

  if (!_eldEvents || _eldEvents.length === 0) return [];

  // if there's only one event and it's an autogen event (it means the whole interval is autogen)
  if ((_eldEvents.length === 1) && isAutoGeneratedDrivingTime(_eldEvents[0])) {
    return [[momentTz(startDateTime).valueOf(), momentTz(endDateTime).valueOf()]];
  }

  // get all instances of autogen driving intervals [ [startMs, endMs], [startMs, endMs], ... ]
  const autogenDrivingTimeIntervals = [];
  let agdtStartMs; // keep track of the autogen driving interval we're currently looking for

  _eldEvents.map((eldEvent, index) => {
    const eventDateTimeMs = momentTz(getAttribute(eldEvent, 'eventDateTime')).valueOf();

    if (isAutoGeneratedDrivingTime(eldEvent) && (agdtStartMs === undefined)) {
      agdtStartMs = eventDateTimeMs;
    } else if (!isAutoGeneratedDrivingTime(eldEvent) && (agdtStartMs !== undefined)) {
      // end of the interval
      autogenDrivingTimeIntervals.push([agdtStartMs, eventDateTimeMs]);
      agdtStartMs = undefined;
    } else if ((agdtStartMs !== undefined) && (index === _eldEvents.length - 1)) {
      // end of the array
      autogenDrivingTimeIntervals.push([agdtStartMs, eventDateTimeMs]);
      agdtStartMs = undefined;
    }
  });

  return autogenDrivingTimeIntervals;
}

/**
 * @memberof module:ELD
 *
 * @param {*} eldEdit
 * @param {*} acceptEdit
 * @param {*} acceptEditReason
 *
 * @returns
 */
function handleDriverEdit(eldEdit, acceptEdit, acceptEditReason) {
  // function to set eldEdit status for AOBRDs
  const promise = new Promise(resolve => {
    let completed = 4; // default is rejected-pending (in case something goes wrong)
    if (acceptEdit) {
      eldEdit.set('note', acceptEditReason);
      completed = 5;
    }

    eldEdit.set('completed', completed).save().then(
      savedELDEdit => {
        resolve(savedELDEdit);
      },
      error => resolve(eldEdit)
    );
  });
  return promise;
}

/**
 * @memberof module:ELD
 * @param {*} eldEvents
 * @returns
 */
function isAOBRDEnabledELDEvents(eldEvents) {
  // determine aobrd eligibility based on if there is a nonaobrd event, mainly for edit reasons
  // go through eldEvents - if at least 1 has aobrdEnabled, return true
  for (let i = 0; i < eldEvents.length; i++) {
    const eldEvent = eldEvents[i];
    if (eldEvent.get('aobrdEnabled') === false) {
      // false means the event is of a device in ELD mode
      // true or undefined means event is of a device in AOBRD mode
      return false;
    }
  }
  return true;
}

/**
 * @memberof module:ELD
 * @param {*} eldEvents
 * @returns
 */
function getVehiclesFromEvents(eldEvents) {
  // get all vehicles from eld events in order from seen
  if (!eldEvents) return [];
  const seen = {}; // keep track of which vehicles we already went over
  const vehicles = [];
  for (let i = 0, eldEventsLen = eldEvents.length; i < eldEventsLen; i++) {
    const event = eldEvents[i];
    const vehicle = event.get('vehicle');
    if (event.get('eldEventRecordStatusInt') === 1 && vehicle && !seen[vehicle.id]) {
      vehicles.push(vehicle);
      seen[vehicle.id] = true;
    }
  }
  return vehicles;
}

/**
 * @memberof module:ELD
 * @param {*} eldEvents
 * @returns
 */
function isAOBRDEnabledByCount(eldEvents) {
  // determine aobrd eligibility based on comparison between number of aobrd vs nonaobrd events, usually for daily logs
  const dutyStatusTypeCodeInts = [11, 12, 13, 14, 21, 22, 31, 32];

  const _eldEvents = eldEvents.filter(eldEvent => dutyStatusTypeCodeInts.indexOf(eldEvent.get('eldEventTypeCodeInt')) !== -1);

  let aobrdCounter = 0;
  let nonAOBRDCounter = 0;

  for (let i = 0; i < _eldEvents.length; i++) {
    // recall an event is nonAOBRD ONLY if aobrdEnabled is False. All else is value True
    if (_eldEvents[i].get('aobrdEnabled') === false) {
      nonAOBRDCounter++;
    } else {
      aobrdCounter++;
    }
  }

  if (aobrdCounter >= nonAOBRDCounter) {
    return true;
  }
  return false;
}

/**
 * @memberof module:ELD
 * @param {*} driver
 * @returns
 */
async function isWithinAutogeneratedBypassDate(driver) {
  if (driver) {
    const driverModuleQuery = new Parse.Query('DriverModule');
    driverModuleQuery.equalTo('driver', driver);
    try {
      const driverModuleObj = await driverModuleQuery.first();

      if (driverModuleObj && driverModuleObj.get('autogeneratedEditBypassDate')) {
        return (momentTz(driverModuleObj.get('autogeneratedEditBypassDate')).isAfter(momentTz()));
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  }
  return false;
}

/**
 * @memberof module:ELD
 *
 * @param {*} eldEvents
 * @param {*} orderType
 *
 * @returns
 */
function sortELDEvents(eldEvents, orderType) {
  // NOTE: This function has a different sorting algorithm from the one found in the cloud. They are not interchangable
  // orderType 1 = ascending
  eldEvents.sort((eventA, eventB) => {
    const eventAEventDateTime = eventA.get('eventDateTime') || momentTz(eventA.eventDateTime).toDate();
    const eventBEventDateTime = eventB.get('eventDateTime') || momentTz(eventB.eventDateTime).toDate();
    const eventATime = eventAEventDateTime.getTime();
    const eventBTime = eventBEventDateTime.getTime();

    // if the two times are the same, use createdAt to determine the sorting order
    const eventATimeArr = [eventAEventDateTime.getDate(), eventAEventDateTime.getHours(), eventAEventDateTime.getMinutes(), eventAEventDateTime.getSeconds()];
    const eventBTimeArr = [eventBEventDateTime.getDate(), eventBEventDateTime.getHours(), eventBEventDateTime.getMinutes(), eventBEventDateTime.getSeconds()];


    if (Helpers.areArraysEqual(eventATimeArr, eventBTimeArr)) {
      // the older event created takes priority
      const eventACreatedAtMs = eventA.createdAt ? eventA.createdAt.getTime() : eventA._createdAt ? eventA._createdAt.getTime() : eventATime;
      const eventBCreatedAtMs = eventB.createdAt ? eventB.createdAt.getTime() : eventB._createdAt ? eventB._createdAt.getTime() : eventBTime;
      return eventACreatedAtMs - eventBCreatedAtMs;
    }

    return eventATime - eventBTime;
  });

  if (orderType === 1) {
    return eldEvents;
  }
  if (orderType === 2) {
    eldEvents.reverse();
    return eldEvents;
  }

  return eldEvents;
}

/**
 * @memberof module:ELD
 * @param {*} locationDescription
 * @returns
 */
function getLocationDescriptionBreakdown(locationDescription) {
  // function to check if location description is of standard format
  // assumes locationDescription string exists
  // stringToReturn is the part of the locationDescription we want if we can get it
  const locationDescSplit = locationDescription.split(' ');

  const breakdown = {
    distance: locationDescSplit[0] || '',
    direction: locationDescSplit[1] || '',
    stateProvince: locationDescSplit[2] || '',
    aprxShortName: '', // aka city
  };

  // cases that break string formatting overall
  const isNotOverallValidLength = locationDescSplit.length < 4;
  let isNotDistanceValidLength = breakdown.distance.length < 3;
  if (!isNotDistanceValidLength) {
    // if distance seems to be a valid length, check if it looks right
    const distanceLen = breakdown.distance.length;
    const distanceUnit = (breakdown.distance[distanceLen - 2] + breakdown.distance[distanceLen - 1]).toLowerCase();

    if (isNaN(breakdown.distance[0])) isNotDistanceValidLength = true; // very first char should be a number
    if ((distanceUnit !== 'mi') && (distanceUnit !== 'km')) isNotDistanceValidLength = true; // followed by distance unit
  }
  const isNotDirectionValidLength = breakdown.direction.length >= 4; // shouldnt be more than 4 chars long
  const isNotStateProvinceValidLength = breakdown.stateProvince.length !== 2;

  if (isNotOverallValidLength || isNotDistanceValidLength || isNotDirectionValidLength || isNotStateProvinceValidLength) {
    Object.keys(breakdown).map((key) => {
      breakdown[key] = '';
    });
    return breakdown;
  }

  // All tests pass, it seems like this is the correct format for vehicle location description
  for (let i = 3; i < locationDescSplit.length; i++) {
    breakdown.aprxShortName += `${locationDescSplit[i]} `;
  }

  breakdown.aprxShortName = breakdown.aprxShortName.trim();

  return breakdown;
}

/**
 * @memberof module:ELD
 *
 * @param {*} eldEvents
 * @param {*} eldDailyCertification
 * @param {*} exemptELDEvents
 *
 * @returns
 */
function getELDEventsIdString(eldEvents, eldDailyCertification, exemptELDEvents) {
  /*
    - generates a string of events ids we want to pass for edits (that way we dont rely on 2 seperate queries on
    the front and back end to try and achieve the same result)
    - it filters for active and relevant duty-status only events except for the exemptELDEvents given
  */
  const exemptELDEventIds = (exemptELDEvents || []).map(eldEvent => eldEvent.id);
  const _eldEvents = eldEvents.filter(eldEvent => {
    if (exemptELDEventIds.indexOf(eldEvent.id) !== -1) return true;
    const isActiveEvent = [0, 1].indexOf(eldEvent.get('eldEventRecordStatusInt')) !== -1;
    const isDutyStatusEvent = [11, 12, 13, 14, 21, 22, 30, 31, 32].indexOf(eldEvent.get('eldEventTypeCodeInt')) !== -1;
    let isOfDailyCert = true;
    if (eldEvent.get('eldDailyCertification')) {
      isOfDailyCert = eldEvent.get('eldDailyCertification') && eldEvent.get('eldDailyCertification').id && (eldEvent.get('eldDailyCertification').id === eldDailyCertification.id);
    }
    if (isActiveEvent && isDutyStatusEvent && isOfDailyCert) return true;
    return false;
  });
  let eldEventsIdString = _eldEvents.map(eldEvent => eldEvent.id);
  eldEventsIdString = eldEventsIdString.join(',');
  return eldEventsIdString;
}

export {
  checkShouldDisableEdit,
  convertEventsToGraphCoords,
  eldEditHandler,
  filterELDVersionUpdate,
  findCoDriverInfoFromEvents,
  formatMilliTime,
  filterOdometerReadingsJumpNew,
  generateHOSLogs,
  generateHOSViolationMonthlyReport,
  getAGDTIntervals,
  getAssociatedELDEvents,
  getAssociatedAOBRDELDEvents,
  getAutogeneratedDrivingTimeIntervals,
  getELDDate,
  getCurrentELDVersion,
  getDefectiveELDEvents,
  getDutyStatusDesc,
  getDriverELDEvents,
  getDriverHours,
  getELDCSVContent,
  getELDDailyCertification,
  getELDEdits,
  getELDEventRecordOrigin,
  getELDEventRecordStatus,
  getELDEventTypeCode,
  getELDEventsIdString,
  getELDDailyCertificationIntervalFromDriverTZ,
  getELDMalfunctionDataCode,
  getELDStatuses,
  getELDViolationType,
  getFormattedELDFileName,
  getOdometerEldEventsToModify,
  getOdometerReadings,
  getSpecialEventSequences,
  getELDCertifications,
  getLocationDescriptionBreakdown,
  getTotalHoursForGraph,
  getTypeFromELDReferenceTable,
  getUncertifiedLogDrivers,
  getUnidentifiedDrivers,
  getVehicleDriverHistory,
  getVehiclesFromEvents,
  handleDriverEdit,
  isAlmostOutOfHours,
  isAOBRDEnabledELDEvents,
  isAOBRDEnabledByCount,
  isWithinAutogeneratedBypassDate,
  isEventOfDriverIndicationTypeCode,
  isDriverDriving,
  isDriverOnDuty,
  isDrivingEditViolation,
  isVehicleDriverDriving,
  isVehicleDriverOnDuty,
  isOutOfHours,
  mapELDDates,
  matchELDEventRecordStatus,
  matchELDEventTypeCode,
  projectAndFormatDrivingTime,
  projectAndFormatOnDutyTime,
  requestELDEdits,
  requestSingleELDEventEdits,
  setELDEventDataOnELDEvents,
  sortELDEvents,
  sortByEventDateTime,
};
