import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

// CSS
import './style.scss';

function SBBlock(props) {
  let className = 'sb-block translate-me';
  if (props.className) className += ` ${props.className}`;
  
  let blockquoteClassName = 'blockquote';
  if (props.success) {
    blockquoteClassName += ` bq-success`;
  } else if (props.warning) {
    blockquoteClassName += ` bq-warning`;
  } else if (props.error) {
    blockquoteClassName += ` bq-error`;
  } else {
    blockquoteClassName += ` bq-info`;
  }
  // const addLineBreaks = string =>
  // string.split('\n').map((text, index) => (
  //   <React.Fragment key={`${text}-${index}`}>
  //     {text}
  //     <br />
  //   </React.Fragment>
  // ));

  let messages = props.messages && props.messages.map((message, index) => <li key={`wl-message-${uniqid()}`} className="sbb-message">{ message }</li>);
  if (!props.listMessages) messages = props.messages && props.messages.map((message, index) => <div key={`sbb-message-${uniqid()}`} className="sbb-message">{ message }</div>);

  return (
    <div className={className}>
      <blockquote className={blockquoteClassName}>
        <div className="bq-title">{ props.title }</div>
        { props.header &&
          <div className="sbb-header">{ props.header }</div>
        }
        { props.listMessages ?
          <ul>
            { messages }
          </ul>
          :
          <div className="sbb-message-container">
            { messages }
          </div>
        }
      </blockquote>

    </div>
  );
}

SBBlock.defaultProps = {
  listMessages: true,
  title: 'Warning Block',
};
SBBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  header: PropTypes.string,
  messages: PropTypes.array,
  warning: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  listMessages: PropTypes.bool, // whether or not to show messages as a formatted list or simply print line-by-line
  addLineBreaks: PropTypes.bool
};

export default SBBlock;
