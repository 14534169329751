import React from 'react';
import PropTypes from 'prop-types';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol, MDBContainer, MDBBtn, MDBIcon, MDBInput } from 'mdbreact';

// API
import { getAttribute, getCurrentUser } from 'api/Parse';
import { getEquipmentGroupsForCurrentUser, createEquipmentGroup, addEquipmentToEquipmentGroup, removeEquipmentGroup, updateEquipmentGroup, getEquipmentGroupByObjectId } from 'api/Equipment/Groups.old';
import { getQueryParameter } from 'api/URL';

// Enums
import { QueryRestrictionTypes } from 'enums/Query';

// sbObjects
import history from 'sbHistory';
import Filter from 'sbObjects/Filter';

// Components
import SBTable from 'components/Shared/SBTable/SBTable';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import Title from 'components/LayoutTitle/view/Title';
import ConfirmModal from 'components/ConfirmModal/view/ConfirmModal';
import EquipmentGroupView from 'components/EquipmentGroup/EquipmentGroupView/EquipmentGroupView';
import EquipmentGroupModal from 'components/EquipmentGroup/EquipmentGroupModal/EquipmentGroupModal';

class EquipmentGroupList extends React.Component {
  constructor(props) {
    super(props);
    const groupQueryParameter = getQueryParameter(props.location.search, 'groupId');
    this.state = {
      groupQueryParameter,
      activeGroup: undefined,
      isLoading: false,
      groupName: undefined,
      groupRecord: undefined,
      groupRecords: [],
      showAddGroupModal: false,
      showEditEquipmentGroupModal: false,
      showConfirmModal: false,
      // filters for searching group by name
      filterFormFields: [
        {
          attrName: 'name',
          fullName: 'Group Name',
          queryType: QueryRestrictionTypes.MATCHES,
          type: 'text',
        },
      ],
      // search filters to apply to the parse query
      fieldFiltersToQuery: [],
      // filters for selecting between groups that have vehicles and/or trailers
      items: [
        {
          key: 1,
          label: 'Vehicles',
          value: 'Vehicles',
          filter: {
            attribute: 'numberOfVehicles',
            value: 0,
            queryType: QueryRestrictionTypes.GREATER_THAN,
          },
        },
        {
          key: 2,
          label: 'Trailers',
          value: 'Trailers',
          filter: {
            attribute: 'numberOfTrailers',
            value: 0,
            queryType: QueryRestrictionTypes.GREATER_THAN,
          },
        },
      ],
      // select filters to apply to the parse query
      itemFiltersToQuery: [],
      selectedItems: [],
      page: 0,
      pageCount: 0,
      pageLimit: 15,
    };

    this.refreshState = this.refreshState.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleFieldsFilter = this.handleFieldsFilter.bind(this);
    this.handleEquipmentFilter = this.handleEquipmentFilter.bind(this);
    this.handlePaginatorOnChange = this.handlePaginatorOnChange.bind(this);
    this.handleViewGroup = this.handleViewGroup.bind(this);
  }

  async componentDidMount() {
    const { groupQueryParameter } = this.state;
    let activeGroup;
    if (groupQueryParameter) {
      activeGroup = await getEquipmentGroupByObjectId(groupQueryParameter);
    }
    await this.refreshState(activeGroup, groupQueryParameter);
  }

  async componentDidUpdate(prevProps) {
    const { props } = this;
    if (props.location.search !== prevProps.location.search) {
      const groupQueryParameter = getQueryParameter(props.location.search, 'groupId');
      let activeGroup;
      // Similar to mount, if we are on new page get group then refresh state accordingly
      if (groupQueryParameter) {
        activeGroup = await getEquipmentGroupByObjectId(groupQueryParameter);
      }
      await this.refreshState(activeGroup, groupQueryParameter);
    }
  }

  async refreshState(activeGroup, groupQueryParameter) {
    const { state } = this;

    this.setState({ groupQueryParameter, activeGroup, isLoading: true });

    const { groupRecords, groupRecordsCount } = await getEquipmentGroupsForCurrentUser(
      state.page,
      undefined,
      undefined,
      [...state.fieldFiltersToQuery, ...state.itemFiltersToQuery],
    );

    const pageCount = Math.ceil(groupRecordsCount / state.pageLimit);

    this.setState({ groupRecords, pageCount, isLoading: false });
  }

  async handleAdd(group) {
    const { name, vehicle, trailer } = group;

    const equipmentGroup = await createEquipmentGroup(name);

    await addEquipmentToEquipmentGroup(equipmentGroup, vehicle, trailer);

    this.setState({ showAddGroupModal: false }, async () => await this.refreshState());
  }

  async handleEdit() {
    const { state } = this;

    const group = {
      name: state.groupName,
    }

    await updateEquipmentGroup(state.groupRecord, group);

    this.setState({ showEditEquipmentGroupModal: false }, async () => await this.refreshState());
  }

  async handleDelete(bool) {
    const { state } = this;

    if (bool) {
      await removeEquipmentGroup(state.groupRecord);
      this.setState({ showConfirmModal: false }, async () => await this.refreshState());
    } else {
      this.setState({ showConfirmModal: false });
    }
  }

  async handleFieldsFilter(filters = []) {
    const fieldFiltersToQuery = filters.map(filter => {
      return new Filter(filter.attribute, filter.value, filter.queryType);
    });

    this.setState({ fieldFiltersToQuery }, async () => await this.refreshState());
  }

  async handleEquipmentFilter(selectedItems) {
    const itemFiltersToQuery = selectedItems.map(item => {
      return new Filter(item.filter.attribute, item.filter.value, item.filter.queryType);
    });

    this.setState({ selectedItems, itemFiltersToQuery }, async () => await this.refreshState());
  }

  async handlePaginatorOnChange(type, inputValue) {
    const { state } = this;

    let page = state.page;

    if (type === 'PREVIOUS') {
      page--;

      if (page < 0) {
        page = 0;
      }
    } else if (type === 'NEXT') {
      page++;

      if (page >= state.pageCount - 1) {
        page = state.pageCount - 1;
      }
    } else if (type === 'SUBMIT') {
      let newPage = inputValue - 1;

      if (newPage < 0) {
        newPage = 0;
      } else if (newPage >= (state.pageCount - 1)) {
        newPage = state.pageCount - 1;
      }

      page = newPage;
    }

    this.setState({ page }, async () => await this.refreshState());
  }

  handleViewGroup(record) {
    this.setState({ activeGroup: record });
    history.push({ 
      pathName: '/equipment',
      search: `?view=groups&groupId=${getAttribute(record, 'objectId')}`,
    });
  }

  render() {
    const { props, state } = this;

    const tableHeaderRows = [{
      key: 'groups-list',
      columns: [
        {
          element: <div>Name</div>,
          props: {
            style: {
              width: '20%',
            },
          },
        },
        {
          element: <div># of Vehicles</div>,
          props: {
            style: {
              width: '15%',
            },
          },
        },
        {
          element: <div># of Trailers</div>,
          props: {
            style: {
              width: '15%',
            },
          },
        },
        {
          element: <div />,
          props: {
            style: {
              width: '50%',
            },
          },
        },
      ],
    }];

    let tableBodyRows = state.groupRecords.map(record => {
      const objectId = getAttribute(record, 'objectId');
      const name = getAttribute(record, 'name');
      const numberOfVehicles = getAttribute(record, 'numberOfVehicles');
      const numberOfTrailers = getAttribute(record, 'numberOfTrailers');

      const createdByUser = getAttribute(record, 'createdBy');
      const createdByUserObjectId = getAttribute(createdByUser, 'objectId');

      const currentUser = getCurrentUser();
      const currentUserObjectId = getAttribute(currentUser, 'objectId');

      return {
        key: objectId,
        columns: [
          {
            element: <div>{ name }</div>,
          },
          {
            element: <div>{ numberOfVehicles }</div>,
          },
          {
            element: <div>{ numberOfTrailers }</div>,
          },
          {
            element: (
              <ActionsContainer>
                <MDBBtn
                  size="sm"
                  color="primary"
                  onClick={() => this.handleViewGroup(record)}
                >
                  <MDBIcon className="mr-1" icon="eye" />
                  View
                </MDBBtn>
                {createdByUserObjectId === currentUserObjectId && (
                  <>
                    <MDBBtn
                      size="sm"
                      color="secondary"
                      onClick={() => this.setState({ groupRecord: record, groupName: name, showEditEquipmentGroupModal: true })}
                    >
                      <MDBIcon className="mr-1" icon="pen" />
                      Edit
                    </MDBBtn>
                    <MDBBtn
                      size="sm"
                      color="danger"
                      onClick={() => this.setState({ groupRecord: record, showConfirmModal: true })}
                    >
                      <MDBIcon className="mr-1" icon="trash" />
                      Delete
                    </MDBBtn>
                  </>
                )}
              </ActionsContainer>
            ),
          }
        ],
      }
    });

    return (
      <MDBContainer
        style={{
          marginTop: '3em',
          marginBottom: '3em',
          paddingLeft: '3em',
          paddingRight: '3em',
        }}
        fluid
      >
        {state.groupQueryParameter && state.activeGroup && (
          <EquipmentGroupView
            group={state.activeGroup}
            location={props.location}
          />
        )}
        {!state.groupQueryParameter && (
          <>
            <MDBRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <MDBCol xs='12' lg='2'>
                <Title title="Groups" />
              </MDBCol>
              <MDBCol xs='12' lg='5'>
                <FilterForm
                  fields={state.filterFormFields}
                  handleFilter={this.handleFieldsFilter}
                  clearFilter={() => this.handleFieldsFilter([])}
                />
              </MDBCol>
              <MDBCol xs='12' lg='5'>
                <ActionsContainer>
                  <SBSelect
                    items={state.items}
                    selectedItems={state.selectedItems}
                    getSelectedItems={this.handleEquipmentFilter}
                    multiSelectable
                    containerClassName="ml-2 mr-2 d-inline-block mt-0"
                    defaultToggleText="Equipment"
                    size="md"
                  />
                  <MDBBtn size="sm" color="primary" onClick={() => this.setState({ showAddGroupModal: true })}>
                    <MDBIcon className="mr-1" icon="plus" />
                    Group
                  </MDBBtn>
                  <MDBBtn size="sm" color="secondary" onClick={this.refreshState}>
                    <MDBIcon className="mr-1" icon="sync-alt" />
                    Refresh
                  </MDBBtn>
                </ActionsContainer>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <SBTable
                  tableHeaderRows={tableHeaderRows}
                  tableBodyRows={tableBodyRows}
                  showPaginator
                  activePage={state.page + 1}
                  pageCount={state.pageCount}
                  handlePaginatorOnChange={this.handlePaginatorOnChange}
                  paginatorContainerClassName="paginator-container"
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <EquipmentGroupModal
                  show={state.showAddGroupModal}
                  handleClose={() => this.setState({ showAddGroupModal: false })}
                  handleAdd={this.handleAdd}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <ConfirmModal
                  show={state.showConfirmModal}
                  title="Delete Group"
                  body="Are you sure you want to delete this group?"
                  handleClose={() => this.setState({ showConfirmModal: false })}
                  handleModalChoice={async (bool) => await this.handleDelete(bool)}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBModal isOpen={state.showEditEquipmentGroupModal} centered data-backdrop="static">
                  <MDBModalHeader>
                    Edit Group Name
                  </MDBModalHeader>
                  <MDBModalBody>
                    <MDBInput
                      type="text"
                      label="Name"
                      containerClass="required"
                      labelClass="active"
                      value={state.groupName}
                      getValue={(groupName) => this.setState({ groupName, disableSubmit: groupName.length === 0 })}
                    />
                  </MDBModalBody>
                  <MDBModalFooter>
                    <ActionsContainer>
                      <MDBBtn
                        color="default"
                        size="sm"
                        onClick={() => this.setState({ showEditEquipmentGroupModal: false })}
                      >
                        Cancel
                      </MDBBtn>
                      <MDBBtn
                        color="primary"
                        size="sm"
                        onClick={this.handleEdit}
                      >
                        Update
                      </MDBBtn>
                    </ActionsContainer>
                  </MDBModalFooter>
                </MDBModal>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                {state.isLoading && (
                  <LoadingIcon />
                )}
              </MDBCol>
            </MDBRow>
          </>
        )}
      </MDBContainer>
    );
  }
}

EquipmentGroupList.propTypes = {
  location: PropTypes.object.isRequired,
};

export default EquipmentGroupList;
