export const StateProvinces = [
  { code: '', name: '', country: '' },
  { code: 'AB', name: 'Alberta', country: 'CA' },
  { code: 'BC', name: 'British Columbia', country: 'CA' },
  { code: 'MB', name: 'Manitoba', country: 'CA' },
  { code: 'NB', name: 'New Brunswick', country: 'CA' },
  { code: 'NF', name: 'New Foundland and Labrador', country: 'CA' },
  { code: 'NT', name: 'Northwest Territories', country: 'CA' },
  { code: 'NS', name: 'Nova Scotia', country: 'CA' },
  { code: 'NU', name: 'Nunavut', country: 'CA' },
  { code: 'ON', name: 'Ontario', country: 'CA' },
  { code: 'PE', name: 'Prince Edward Island', country: 'CA' },
  { code: 'QC', name: 'Quebec', country: 'CA' },
  { code: 'SK', name: 'Saskatchewan', country: 'CA' },
  { code: 'YT', name: 'Yukon Territory', country: 'CA' },
  { code: 'AG', name: 'Aguascalientes', country: 'MX' },
  { code: 'AL', name: 'Alabama', country: 'US' },
  { code: 'AK', name: 'Alaska', country: 'US' },
  { code: 'AS', name: 'American Samoa', country: 'US' },
  { code: 'AZ', name: 'Arizona', country: 'US' },
  { code: 'AR', name: 'Arkansas', country: 'US' },
  { code: 'BN', name: 'Baja California Nord', country: 'MX' },
  { code: 'BS', name: 'Baja California Sur', country: 'MX' },
  { code: 'CA', name: 'California', country: 'US' },
  { code: 'CP', name: 'Campeche', country: 'MX' },
  { code: 'CS', name: 'Chiapas', country: 'MX' },
  { code: 'CI', name: 'Chihuahua', country: 'MX' },
  { code: 'CH', name: 'Coahuila', country: 'MX' },
  { code: 'CL', name: 'Colima', country: 'MX' },
  { code: 'CO', name: 'Colorado', country: 'US' },
  { code: 'CT', name: 'Connecticut', country: 'US' },
  { code: 'DE', name: 'Delaware', country: 'US' },
  { code: 'DF', name: 'Districto Federal', country: 'MX' },
  { code: 'DC', name: 'District of Columbia', country: 'US' },
  { code: 'DIF', name: 'Distrito Federal', country: 'MX' },
  { code: 'DG', name: 'Durango', country: 'MX' },
  { code: 'FL', name: 'Florida', country: 'US' },
  { code: 'GA', name: 'Georgia', country: 'US' },
  { code: 'GU', name: 'Guam', country: 'US' },
  { code: 'GJ', name: 'Guanajuato', country: 'MX' },
  { code: 'GE', name: 'Guerrero', country: 'MX' },
  { code: 'HI', name: 'Hawaii', country: 'US' },
  { code: 'HD', name: 'Hidalgo', country: 'MX' },
  { code: 'ID', name: 'Idaho', country: 'US' },
  { code: 'IL', name: 'Illinois', country: 'US' },
  { code: 'IN', name: 'Indiana', country: 'US' },
  { code: 'IA', name: 'Iowa', country: 'US' },
  { code: 'JA', name: 'Jalisco', country: 'MX' },
  { code: 'KS', name: 'Kansas', country: 'US' },
  { code: 'KY', name: 'Kentucky', country: 'US' },
  { code: 'LA', name: 'Louisiana', country: 'US' },
  { code: 'ME', name: 'Maine', country: 'US' },
  { code: 'MD', name: 'Maryland', country: 'US' },
  { code: 'MA', name: 'Massachusetts', country: 'US' },
  { code: 'MX', name: 'Mexico State', country: 'MX' },
  { code: 'MI', name: 'Michigan', country: 'US' },
  { code: 'MC', name: 'Michoacan', country: 'MX' },
  { code: 'MN', name: 'Minnesota', country: 'US' },
  { code: 'MS', name: 'Mississippi', country: 'US' },
  { code: 'MO', name: 'Missouri', country: 'US' },
  { code: 'MT', name: 'Montana', country: 'US' },
  { code: 'MR', name: 'Morelos', country: 'MX' },
  { code: 'NA', name: 'Nayarit', country: 'MX' },
  { code: 'NE', name: 'Nebraska', country: 'US' },
  { code: 'NV', name: 'Nevada', country: 'US' },
  { code: 'NH', name: 'New Hampshire', country: 'US' },
  { code: 'NJ', name: 'New Jersey', country: 'US' },
  { code: 'NM', name: 'New Mexico', country: 'US' },
  { code: 'NY', name: 'New York', country: 'US' },
  { code: 'NP', name: 'No Plate', country: 'US' },
  { code: 'NC', name: 'North Carolina', country: 'US' },
  { code: 'ND', name: 'North Dakota', country: 'US' },
  { code: 'MP', name: 'Northern Marianas', country: 'US' },
  { code: 'NL', name: 'Nuevo Leon', country: 'MX' },
  { code: 'OA', name: 'Oaxaca', country: 'MX' },
  { code: 'OH', name: 'Ohio', country: 'US' },
  { code: 'OK', name: 'Oklahoma', country: 'US' },
  { code: 'OR', name: 'Oregon', country: 'US' },
  { code: 'PA', name: 'Pennsylvania', country: 'US' },
  { code: 'PU', name: 'Puebla', country: 'MX' },
  { code: 'PR', name: 'Puerto Rico', country: 'US' },
  { code: 'QE', name: 'Queretaro', country: 'MX' },
  { code: 'QI', name: 'Quintana Roo', country: 'MX' },
  { code: 'RI', name: 'Rhode Island', country: 'US' },
  { code: 'SL', name: 'San Luis Potosi', country: 'MX' },
  { code: 'SI', name: 'Sinaloa', country: 'MX' },
  { code: 'SO', name: 'Sonora', country: 'MX' },
  { code: 'SC', name: 'South Carolina', country: 'US' },
  { code: 'SD', name: 'South Dakota', country: 'US' },
  { code: 'TB', name: 'Tabasco', country: 'MX' },
  { code: 'TA', name: 'Tamaulipas', country: 'MX' },
  { code: 'TN', name: 'Tennessee', country: 'US' },
  { code: 'TX', name: 'Texas', country: 'US' },
  { code: 'TL', name: 'Tlaxcala', country: 'MX' },
  { code: 'UT', name: 'Utah', country: 'US' },
  { code: 'VC', name: 'Veracruz', country: 'MX' },
  { code: 'VT', name: 'Vermont', country: 'US' },
  { code: 'VA', name: 'Virginia', country: 'US' },
  { code: 'VI', name: 'Virgin Islands', country: 'US' },
  { code: 'WA', name: 'Washington', country: 'US' },
  { code: 'WV', name: 'West Virginia', country: 'US' },
  { code: 'WI', name: 'Wisconsin', country: 'US' },
  { code: 'WY', name: 'Wyoming', country: 'US' },
  { code: 'YU', name: 'Yucatan', country: 'MX' },
  { code: 'ZA', name: 'Zacatecas', country: 'MX' },
];
