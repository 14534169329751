export default class SBFile {
  /**
   * 
   * @param {string} url - url to the file
   * @param {any}    source - any; could be the datauri, an encoding, 
   * @param {string} fileName - name of the file
   * @param {string} fileType - pdf, jpg, etc
   * @param {number} timestamp - the time the file was requested
   */
  constructor(url, source, fileName, fileType, timestamp) {
    this.url = url;
    this.source = source;
    this.fileName = fileName;
    this.fileType = fileType;
    this.timestamp = timestamp;
  }
}