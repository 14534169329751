import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//api
import { getUnidentifiedELDEvents } from 'api/ELD/ELDEvent/ELDEvent';

//components
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//styles
import './styles.scss';

// Context
import StoreContext from 'contexts/StoreContext';


class ToastNotifications extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uniqueNotifications: {}
    };
    this.checkPopupNotifications = this.checkPopupNotifications.bind(this);
  }

  componentDidUpdate(prevProps) {
    if(prevProps !== this.props){
      this.checkPopupNotifications();
    }
  }

  componentDidMount() {
    // this.notify();
    this.checkPopupNotifications();
    //this check certified driving
  }

  //check for any popup notifications from global notification state
  async checkPopupNotifications() {
    const notifications = this.props.Notification.notifications;
    const uniqueNotifications = this.state.uniqueNotifications;
    
    for (let i = 0; i < notifications.length; i++){
      try {
      if (notifications[i].message.notificationClass === 'unidentifiedDriving' && !uniqueNotifications['unidentifiedDriving']){
        toast.warning("Unidentified Driving: Please assign unidentified driving events to a driver", { autoClose: false, delay: 5000, className: 'toast-style' });
        uniqueNotifications['unidentifiedDriving'] = true;
      }
      if (notifications[i].message.notificationClass === 'uncertifiedLogs' && !uniqueNotifications['uncertifiedLogs']){
        toast.info("Uncertified Logs: Please have your drivers certify their logs", { autoClose: false, delay: 5000, className: 'toast-style' });
        uniqueNotifications['uncertifiedLogs'] = true;
      }
    } catch(e) {

    }
    }
  }

  render() {

    return (
      <ToastContainer 
      className='toast-container'
    />
    );
  }
}

const mapStateToProps = (state) => {
  const { Notification } = state;
  return {
    Notification,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(ToastNotifications);

