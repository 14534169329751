import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// components
import DispatchContactAutocompleteInput from 'components/Dispatch/DispatchContactAutocomplete/DispatchContactAutocompleteInput';
import DispatchContactCard from 'components/Dispatch/DispatchContactAutocomplete/DispatchContactCard';
import DispatchContactEditDialog from 'components/Dispatch/DispatchContactAutocomplete/DispatchContactEditDialog';

// sbcore components
import InputLabel from 'sbCore/InputLabel/InputLabel';

/**
 * @description Contains an input to filter contacts, a button to add/edit a contact, and the form to add/edit a contact
 * @param {String} className - Custom container className
 * @param {Object} style - Custom styling
 * @param {function} onSelectDispatchContact - Callback which returns the selected dispatch contact
 * @param {CustomerDispatchOrganization} customerDispatchOrganization - CustomerDispatchOrganization that's linked to the dispatchContacts in the autocomplete list
 * @param {DispatchJob} dispatchJob - Passed as a prop to DispatchContactAutocompleteInput to get the contacts belonging to the job's customerDispatchOrganization
 * @param {bool} disabled - Selects whether or not to disable an input
 * @param {bool} autoFocus - autoFocus on input field on load
 * @param {bool} warning - Switches input border colour to yellow
 * @param {bool} isLoading - Displays loading signs depending on if loading
 * @param {bool} allowAddDispatchOrganizationContact - Shows button under input to add a contact
 * @param {bool} hideLabel - Shows/hides input label
 *
 * @returns
 *
 * @example
 * <DispatchContactAutocomplete />
 */
export default function DispatchContactAutocomplete({ ...props }) {

  // ** useStates ** //
  const [identifier] = useState(uniqid());
  const [customerDispatchOrganization, setCustomerDispatchOrganization] = useState(undefined);
  const [dispatchContact, setDispatchContact] = useState(undefined);
  const [showEditContactForm, setShowEditContactForm] = useState(false);
  const [dispatchContactTrigger, setDispatchContactTrigger] = useState(undefined); // 0: remove the contact from the job. 1: add the selected contact to the job
  const [isLoading, setIsLoading] = useState(false); // local version is isLoading (in addition to props.isLoading)

  // ** useEffects ** //
  useEffect(() => {
    setCustomerDispatchOrganization(props.customerDispatchOrganization);
    if (!props.customerDispatchOrganization) {
      props.onSelectDispatchContact(undefined);
    }
  }, [props.customerDispatchOrganization]);

  useEffect(() => {
    setDispatchContact(props.dispatchContact);
  }, [props.dispatchContact]);

  // ** Callbacks ** //
  const onSelectDispatchContact = (dispatchContact) => {
    setDispatchContact(dispatchContact);
    // add check for dispatchContact to prevent accidental triggering of function
    if (dispatchContact) {
      setDispatchContact(dispatchContact);
      setShowEditContactForm(false);
      if (props.onSelectDispatchContact) props.onSelectDispatchContact(dispatchContact);
      if (props.dispatchJob) {
        setDispatchContactTrigger(1);
      }
    }
  };

  const onUnselectDispatchContact = () => {
    setDispatchContact(undefined);

    // pass in undefined instead of dispatchContact (which is being set to undefined above) since the state might not be updated yet. we do this over using a useEffect for convenience
    if (props.onSelectDispatchContact) props.onSelectDispatchContact(undefined);
    if (props.dispatchJob) setDispatchContactTrigger(0);
  };

  const onToggleEditDispatchOrganizationContact = () => {
    setShowEditContactForm(!showEditContactForm);

    if (props.onToggleEditDispatchOrganizationContact) {
      props.onToggleEditDispatchOrganization(showEditContactForm);
    }
  };

  // ** Components ** //
  const customerRequiredLabel = (
    <InputLabel warning>* Customer Field is Required</InputLabel>
  );

  const contactAutocompleteField = (
    <DispatchContactAutocompleteInput
      dispatchJob={props.dispatchJob}
      autoFocus={props.autoFocus}
      warning={props.warning}
      disabled={props.disabled}
      onSelectDispatchContact={(dispatchContact) => onSelectDispatchContact(dispatchContact)}
      customerDispatchOrganization={props.customerDispatchOrganization}
      onToggleEditDispatchOrganizationContact={() => onToggleEditDispatchOrganizationContact()}
    />
  );

  const editContactDialog = (
    <DispatchContactEditDialog
      visible={showEditContactForm}
      customerDispatchOrganization={customerDispatchOrganization}
      dispatchContact={dispatchContact}
      onSave={(dispatchContact) => onSelectDispatchContact(dispatchContact)}
      onCancel={() => onToggleEditDispatchOrganizationContact()}
    />
  );

  const dispatchContactCard = (
    <DispatchContactCard
      dispatchContact={dispatchContact}
      onUnselectDispatchContact={() => onUnselectDispatchContact()}
      onToggleEditDispatchOrganizationContact={() => onToggleEditDispatchOrganizationContact()}
    />
  );

  const loadingDispatchContactCard = (
    <DispatchContactCard isLoading={props.isLoading || isLoading} />
  );

  // ** Misc ** //
  let className = `dispatch-contact-autocomplete ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  const label = 'Contact';

  let disabled = props.disabled;
  if (!disabled && showEditContactForm) {
    disabled = true;
  }

  const addContactLabelButton = (
    <span
      className="label-button"
      disabled={disabled}
      onClick={() => onToggleEditDispatchOrganizationContact()}
    >
      + Create new {label}
    </span>
  );

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && (
        <InputLabel>{label}</InputLabel>
      )}

      {!props.isLoading && !dispatchContact && contactAutocompleteField}

      {!props.isLoading && props.disabled && customerRequiredLabel}

      {!props.isLoading && props.allowAddDispatchOrganizationContact && !dispatchContact && addContactLabelButton}

      {!props.isLoading && !props.disabled && dispatchContact && dispatchContactCard}

      {props.isLoading && loadingDispatchContactCard}

      {editContactDialog}
    </div>
  );
}
