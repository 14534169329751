import React from 'react';
import PropTypes from 'prop-types';

// API
import { isAlmostOutOfHours, projectAndFormatDrivingTime, projectAndFormatOnDutyTime } from 'api/ELD';
import { convertMillisecondsToHours, formatTimeNumberToHoursMinutes } from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './USHoursView.module.scss';

function USHoursView(props) {
  const { driver, usHours, capHours } = props;
  const { usCycleType, usOnDutyCycleHours, usDriveTimeLeft, usOnDutyTimeLeft, usBreakTime, eldDailyCertification } = usHours;

  const usOnDutyCycleHoursMilli = Math.max(0, projectAndFormatOnDutyTime(usOnDutyCycleHours, driver, eldDailyCertification));
  const usOnDutyTimeLeftMilli = capHours ? Math.min(Math.max(0, projectAndFormatOnDutyTime(usOnDutyTimeLeft, driver, eldDailyCertification)), usOnDutyCycleHoursMilli) : Math.max(0, projectAndFormatOnDutyTime(usOnDutyTimeLeft, driver, eldDailyCertification));
  const usDriveTimeLeftMilli = capHours ? Math.min(Math.max(0, projectAndFormatDrivingTime(usDriveTimeLeft, driver, eldDailyCertification)), usOnDutyTimeLeftMilli) : Math.max(0, projectAndFormatDrivingTime(usDriveTimeLeft, driver, eldDailyCertification));
  const usBreakTimeMilli = capHours ? Math.min(Math.max(0, projectAndFormatDrivingTime(usBreakTime, driver, eldDailyCertification)), usDriveTimeLeftMilli) : Math.max(0, projectAndFormatDrivingTime(usBreakTime, driver, eldDailyCertification));

  return (
    <div className={styles.usHoursView}>
      <table className={styles.usHoursViewTable}>
        <tbody>
          <tr className={`${styles.header} translate-me`}>
            <td className={styles.title} rowSpan={2}>
              US
              <br />
              Hours-of-Service
            </td>
            <td>
              Cycle Type
            </td>
            <td>
              Cycle Hours
              <br />
              Remaining
            </td>
            <td>
              Drive Time
              <br />
              Remaining
            </td>
            <td>
              Time Until
              <br />
              30-minute Break
            </td>
            <td>
              On Duty Time
              <br />
              Remaining
            </td>
          </tr>
          {!props.fetched && (
            <tr>
              <td colSpan={4}><LoadingIcon /></td>
            </tr>
          )}
          {props.fetched && (
            <tr>
              <td>{usCycleType}</td>
              <td className={usOnDutyCycleHours !== 'N/A' && isAlmostOutOfHours(usOnDutyCycleHoursMilli) ? `${styles.hourNumberOut}` : null}>
                {(usOnDutyCycleHours === 'N/A' || usOnDutyCycleHours === undefined) ?
                  'N/A'
                  :
                  (
                    <span>
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(usOnDutyCycleHoursMilli))}
                      {/* <br />
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(usOnDutyCycleHours))}
                      <br />
                      {usOnDutyCycleHours} */}
                    </span>
                  )
                }
              </td>
              <td className={usDriveTimeLeft !== 'N/A' && isAlmostOutOfHours(usDriveTimeLeftMilli) ? `${styles.hourNumberOut}` : null}>
                {(usDriveTimeLeft === 'N/A' || usDriveTimeLeft === undefined) ?
                  'N/A'
                  :
                  (
                    <span>
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(usDriveTimeLeftMilli))}
                      {/* <br />
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(usDriveTimeLeft))}
                      <br />
                      {usDriveTimeLeft} */}
                    </span>
                  )
                }
              </td>
              <td className={usBreakTime !== 'N/A' && isAlmostOutOfHours(usBreakTimeMilli) ? `${styles.hourNumberOut}` : null}>
                {(usBreakTime === 'N/A' || usBreakTime === undefined) ?
                  'N/A'
                  :
                  (
                    <span>
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(usBreakTimeMilli))}
                      {/* <br />
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(usBreakTime))}
                      <br />
                      {usBreakTime} */}
                    </span>
                  )
                }
              </td>
              <td className={usOnDutyTimeLeft !== 'N/A' && isAlmostOutOfHours(usOnDutyTimeLeftMilli) ? `${styles.hourNumberOut}` : null}>
                {(usOnDutyTimeLeft === 'N/A' || usOnDutyTimeLeft === undefined) ?
                  'N/A'
                  :
                  (
                    <span>
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(usOnDutyTimeLeftMilli))}
                      {/* <br />
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(usOnDutyTimeLeft))}
                      <br />
                      {usOnDutyTimeLeft} */}
                    </span>
                  )
                }
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

USHoursView.propTypes = {
  usHours: PropTypes.instanceOf(Object).isRequired,
  fetched: PropTypes.bool.isRequired,
  driver: PropTypes.object.isRequired,
  capHours: PropTypes.bool.isRequired,
};

export default USHoursView;
