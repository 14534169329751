import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import uniqid from 'uniqid';
import history from 'sbHistory'

// api
import { getCurrentUser, getAttribute } from 'api/Parse';
import { getQueryParameter } from 'api/URL';
import { getCurrentDispatcher } from 'api/Getters';
import { isSubscribedToModule } from 'api/Helpers'

// components
import Title from 'components/Shared/Title/Title';
import SBCardEmptyContent from 'components/Shared/SBCard/SBCardEmptyContent';

import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

// css
import './style.scss';

class LoadboardLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      token: '12345',
      userId: '',
      authData: null,
    };

    this.getFleetOpsAuth = this.getFleetOpsAuth.bind(this);
  }

  componentDidMount() {
    document.title = t('Loadboard - Switchboard');
    this.getFleetOpsAuth();
    window.Localize.translatePage();
  }

  async getFleetOpsAuth() {
    const { token, userId } = this.state;
    axios(
      {
        method: 'POST',
        url: 'https://sandbox.fleetops.ai/samples/auth',
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify({ token, userId }),
      }
    ).then((response) => {
      console.log(response);
      this.setState({ ...this.state, authData: response.data });
      // setUser(response.data.user);
      // setValid(response.data.valid.toString());
    }).catch((error) => {
      if (error.response) {
        console.log('Error is', error.response);
      } else if (error.request) {
        console.log('Request error is', error.request);
      } else {
        console.log('Error', error.message);
      }
    });
  }

  render() {
    const { props, state } = this;

    let title = 'Loadboard';

    window.Localize.translatePage();

    return (
      <MDBContainer className="drivers-layout px-5" fluid>
        <Title className="translate-me" title={title}>
        </Title>

        {state.isLoading && (
          <SBCardEmptyContent isContentLoader className="translate-me">{'Refreshing Data'}</SBCardEmptyContent>
        )}

        <MDBRow><MDBCol>
          <div>
            {state.authData && (
              <div>
                valid: {`${state.authData.valid}`}
                <br />
                dot: {state.authData.dot}
                <br />
                {state.authData.user && (
                  <div>
                    email: {state.authData.user.email}
                    <br />
                    fullName: {state.authData.user.fullName}
                    <br />
                    id: {state.authData.user.id}
                  </div>
                )}
                <br />
              </div>
            )}
          </div>
        </MDBCol></MDBRow>

      </MDBContainer>
    );
  }
}

LoadboardLayout.propTypes = {
  location: PropTypes.object.isRequired,
};

export default LoadboardLayout;
