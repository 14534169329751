/** @module DispatchDriver */

import {
  addRecord, copyQuery, count, createQuery, createQueryOr, destroyRecord, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord,
} from 'sb-csapi/dist/AAPI';

import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @memberof module:DispatchDriver
 *
 * @description Get DispatchDrivers according to filter criteria and a DispatchTransfer
 *
 * @param {object} options - See example
 * @param {string} dispatchTransferObjectId - DispatchTransfer we wish to retrieve dispatch drivers for
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { object } - { totalDispatchDriversCount: Number, dispatchDrivers: [DispatchDriver, ...] }
 */
async function getDispatchDrivers(options = { sessionToken: getCurrentUserSessionToken() }, dispatchTransferObjectId, filters = [], sortBy = new Sort(QuerySortOrder.ASCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  if (!dispatchTransferObjectId) new Error('No DispatchTransfer specified');

  let dispatchDriverQuery = createQuery('DispatchDriver');

  let driverQuery = createQuery('Driver');
  setQueryRestriction(driverQuery, QueryRestriction.EQUAL_TO, 'belongsToCompany', getCurrentUserCompanyObjectId());
  setQueryRestriction(driverQuery, QueryRestriction.EQUAL_TO, 'enabled', true);

  const _filters = [...filters];

  // set universal driver filters
  _filters.push(new Filter(QueryRestriction.EQUAL_TO, 'dispatchTransfer', dispatchTransferObjectId)); // set to specific dispatchTransfer
  _filters.push(new Filter(QueryRestriction.MATCHES_QUERY, 'driver', driverQuery)); // enabled Drivers only

  _filters.map(filter => setQueryRestriction(dispatchDriverQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  const dispatchDriverCountQuery = copyQuery(dispatchDriverQuery);

  // sort
  sortQuery(dispatchDriverQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchDriverQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchDriverQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(dispatchDriverQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(dispatchDriverQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the count and the drivers
  const promises = [count(options, dispatchDriverCountQuery), findRecords(options, dispatchDriverQuery, false, queryAll)];

  try {
    const [totalDispatchDriversCount, dispatchDrivers] = await Promise.all(promises);
    return { totalDispatchDriversCount, dispatchDrivers };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchDriver
 * @description Add a new dispatch driver record
 *
 * @param {object} keyValueObj - The selected fields we would like to save, should be a dispatchTransfer and driver object
 */
async function addDispatchDriver(keyValueObj) {
  try {
    const dispatchDriver = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'DispatchDriver', keyValueObj, getCurrentUserCompanyObjectId());
    return dispatchDriver;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchDriver
 * @description Remove a dispatch driver record and return the last version of it
 *
 * @param {object} record - The dispatch driver record we would like to remove
 */
async function removeDispatchDriver(record) {
  try {
    const dispatchDriver = await destroyRecord({ sessionToken: getCurrentUserSessionToken() }, record);
    return dispatchDriver;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchDriver
 *
 * @description Update a DispatchDriver
 *
 * @param {DispatchDriver} dispatchDriver - The dispatchDriver to update. If not available, use parameter dispatchDriverObjectId instead
 * @param {string} dispatchDriverObjectId - The objectId of the dispatchDriver object we wish to update. Use if parameter dispatchDriver is unavailable
 * @param {object} keyValueObj - The select fields we wish to update
 * @param {bool} save - If true, executes save on the changes. If false, holds the changes locally but is not saved
 *
 * @returns {DispatchDriver} - The updated DispatchDriver
 */
async function updateDispatchDriver(dispatchDriver, dispatchDriverObjectId, keyValueObj, save) {
  if (!dispatchDriver && !dispatchDriverObjectId) throw new Error('Missing Arguments: Must provide dispatchDriver or dispatchDriverObjectId');
  if (dispatchDriver && dispatchDriverObjectId) throw new Error('Arguments: Must provide only one of dispatchDriver or dispatchDriverObjectId');

  let _dispatchDriver = dispatchDriver;

  try {
    if (!dispatchDriver && dispatchDriverObjectId) {
      const dispatchDriverQuery = createQuery('DispatchDriver');
      setQueryRestriction(dispatchDriverQuery, QueryRestriction.EQUAL_TO, 'objectId', dispatchDriverObjectId);
      _dispatchDriver = await findRecords({ sessionToken: getCurrentUserSessionToken() }, dispatchDriverQuery, true);
    }

    if (_dispatchDriver) {
      if (!Object.keys(keyValueObj).length) return _dispatchDriver;

      _dispatchDriver = await updateRecord({ sessionToken: getCurrentUserSessionToken() }, _dispatchDriver, keyValueObj, save);
      return _dispatchDriver;
    } else {
      throw new Error('DispatchDriver does not exist');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getDispatchDrivers,
  addDispatchDriver,
  removeDispatchDriver,
  updateDispatchDriver,
};
