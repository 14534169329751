// LENGTH / DISTANCE
export const LengthUnit = Object.freeze({
  0: 'CM',
  1: 'IN',
  2: 'FT',
  3: 'KM',
  4: 'MI',
});

export const LengthUnitName = Object.freeze({
  0: 'Centimeter',
  1: 'Inch',
  2: 'Foot',
  3: 'Kilometer(s)',
  4: 'Mile(s)',
});

export const LengthUnitTypes = Object.freeze({
  'CM': 'CM',
  'IN': 'IN',
  'FT': 'FT',
  'KM': 'KM',
  'MI': 'MI',
});
export const LengthUnitNames = Object.freeze({
  'CM': 'Centimeter',
  'IN': 'Inch',
  'FT': 'Foot',
  'KM': 'Kilometer(s)',
  'MI': 'Mile(s)',
});

export const LengthConversion = Object.freeze({
  'CM2IN': 0.393701,
  'CM2FT': 0.0328084,
  'IN2CM': 2.54,
  'IN2FT': 0.0833333,
  'FT2CM': 30.48,
  'FT2IN': 12,
  'KM2MI': 0.621371,
  'MI2KM': 1.60934,
});

// MASS
export const MassUnit = Object.freeze({
  0: 'KG',
  1: 'LB'
});
export const MassUnitName = Object.freeze({
  0: 'Kilogram',
  1: 'Pound'
});

export const MassConversion = Object.freeze({
  'KG2LB': 2.20462,
  'LB2KG': 0.453592,
});

// TEMPERATURE
export const TemperatureUnit = Object.freeze({
  0: 'C',
  1: 'F',
});

export const TemperatureUnitName = Object.freeze({
  0: 'Celsius',
  1: 'Fahrenheit',
});

export const TemperatureConversionMagnitude = Object.freeze({
  'C2F': 1.8,
  'F2C': 0.555556,
});

export const TemperatureConversionVector = Object.freeze({
  'C2F': 32,
  'F2C': -17.7792,
});

// VOLUME

export const VolumeUnitTypes = Object.freeze({
  'L': 'L',
  'G': 'G',
});

export const VolumeUnitNames = Object.freeze({
  'L': 'Litres',
  'G': 'Gallons',
});


