import React from 'react';

import SBInputNumber from 'sbCore/InputNumber/InputNumber';

import './style.scss';

/**
 * @description customizable number input for document generation
 * @param {String} value - the value of the input for a controlled input, requires onChange callback to be specified
 * @param {Function} onChange - the callback function for a controlled input, requires the value 
 * @param {String} [label] - the label that is attached to this input
 * @param {bool} [horizontal] - determines whether the label is on the top of the input or on the left of the input
 * @param {String} [labelClassName] - styles to be applied to the label itself
 * @param {String} [inputClassName] - styles to be applied to the input
 * @param {String} mode - value type of the number, ex: mode="decimal"
 * @param {Int} minFractionDigits - minimum number of decimal places
 * @param {String} currency - the type of currency for mode="currency", ex: currency="USD"
 * @param {String} prefix - character to display before the input value
 * @param {String} suffix - character to display after the input value
 * @param {bool} [success] - show success
 * @param {bool} [warning] - show warning
 * @param {bool} [error] - show error
 * @param {bool} [isDownloaded] - show/hide input borders when downloading pdf
 * @returns {Component}
 */
function InputNumber({ label, horizontal, value, success, warning, error, isDownloaded, ...props }) {
  const labelClassName = props.labelClassName;
  const inputClassName = props.inputClassName;

  return (
    <div className={`docgen-input-number${horizontal ? ' flex align-items-center' : ''}`}>
      {label && 
        <div className={`information-sub-title${labelClassName ? ` ${labelClassName}` : ''}`}>{label}</div>
      }
      <div className={`${isDownloaded ? ' invisible-border' : ''} ${horizontal ? ' horizontal-document-input-text' : ''}`}>
        <SBInputNumber
          className={inputClassName}
          value={value}
          onChange={(e) => props.onChange(e.value)}
          mode={props.mode}
          currency={props.currency}
          minFractionDigits={props.minFractionDigits}
          prefix={props.prefix}
          suffix={props.suffix}
          success={success}
          warning={warning}
          error={error}
        />
      </div>
    </div>
  );
}

export default InputNumber;