import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';
import moment from 'moment-timezone';

// api
import { getAttribute, getCurrentUser, getCurrentUserSessionToken, getRecordByObjectId } from 'sb-csapi/dist/AAPI';
import { getUserSubscriptionPermission } from 'sb-csapi/dist/api/Subscription/Subscription';

import { getELDDailyCertificationIntervalFromDriverTZ } from 'api/ELD';

// enums
import { ELDEventTypeCode } from 'sb-csapi/dist/enums/ELD/ELDEventTypeCode';
import { ELDEventRecordStatus } from 'sb-csapi/dist/enums/ELD/ELDEventRecordStatus';

// components
import Button from 'sbCore/Button/Button';
import Skeleton from 'sbCore/Skeleton/Skeleton';

// styles
import './style.scss';

/**
 * @description
 *
 * @param {ELDDailyCertification} eldDailyCertification - ELDEditModal - The daily cert of the logs. We also rely on eldDailyCertification.driver to exist (can be a faked pointer)
 * @param {Array} eldEdits - ELDEditModal - Array of ELDEdits belonging to the day
 * @param {Boolean} [isFetched] - ELDEditModal - Tells the button whether or not all information (daily cert + edits) are fetched, so it can begin parsing "true" info
 * @param {ELDEvent} [eldEvent] - ELDEditModal - Determines that this is the only event we are editing out of n-events. Editing single events have a different flow
 * @param {Object} [userSubscriptionPermission] - ELDEditModal - From getUserSubscriptionPermission(). If provided, it bypasses this component doing all the fetching itself
 * @param {Boolean} [disabled] - Whether the button should be disabled according to a parent
 * @param {Boolean} [forceEnable] - Keep the button logic, but force it to be enabled
 */
function ELDEditModalButton({ ...props }) {
  const DEFAULT_BUTTON_LABEL = 'Edit Log';

  const [isLoading, setIsLoading] = useState(false); // whether processing is still happening. shows the skeleton
  const [disabled, setDisabled] = useState(true); // shows disabled button. should default to true so button does not initially render as active
  const [buttonLabel, setButtonLabel] = useState(DEFAULT_BUTTON_LABEL); // if '', hides the button
  const [tooltipMessage, setTooltipMessage] = useState('');

  useEffect(() => {
    // prevent unnecessary calls and also prevent the button from enabling when data is not fetched yet. we compare using object references
    const isSingleELDEventEdit = !!props.eldEvent;
    const _buttonLabel = !isSingleELDEventEdit ? DEFAULT_BUTTON_LABEL : 'Edit';
    setIsLoading(true);
    setButtonLabel(_buttonLabel);
    const { isFetched, userSubscriptionPermission } = props;

    if (!isFetched) {
      return setDisabled(true);
    }

    // if [props.eldEvent] is provided, check if it is editable in the first place
    if (isSingleELDEventEdit) {
      const eldEventTypeCodeInt = getAttribute(props.eldEvent, 'eldEventTypeCodeInt');
      const eldEventRecordStatusInt = getAttribute(props.eldEvent, 'eldEventRecordStatusInt');

      const editableELDEventDutyStatusTypeCodes = [
        ELDEventTypeCode.OFF_DUTY,
        ELDEventTypeCode.SLEEPER_BERTH,
        ELDEventTypeCode.DRIVING,
        ELDEventTypeCode.ON_DUTY_NOT_DRIVING,
        ELDEventTypeCode.AUTHORIZED_PERSONAL_USE_OF_CMV,
        ELDEventTypeCode.YARD_MOVES,
      ];

      const isNotEditableByDutyStatus = editableELDEventDutyStatusTypeCodes.indexOf(eldEventTypeCodeInt) === -1;
      const isNotEditableByRecordStatusInt = [ELDEventRecordStatus.ACTIVE_OLD, ELDEventRecordStatus.ACTIVE].indexOf(eldEventRecordStatusInt) === -1;

      // not allowed to edit
      if (isNotEditableByDutyStatus || isNotEditableByRecordStatusInt) {
        setButtonLabel(_buttonLabel);
        setTooltipMessage(isNotEditableByDutyStatus ? 'Cannot edit Non-Duty Status Events' : 'Cannot edit Inactive Events');
        setIsLoading(false);
        return setDisabled((props.isFetched && props.forceEnable) ? false : true);
      }
    }

    let didCancel = false;

    async function init() {
      let _disabled = false;
      let _buttonlabel = !props.eldEvent ? DEFAULT_BUTTON_LABEL : 'Edit';
      let _tooltipMessage = '';
      let isPastFourteenDayLimit = true;

      let driver = props.eldDailyCertification && props.eldDailyCertification.get('driver');
      driver = driver && await getRecordByObjectId(
        { sessionToken: getCurrentUserSessionToken() },
        'Driver',
        getAttribute(driver, 'objectId'),
        undefined,
        ['currentELDVersion'],
      );
      const currentELDVersion = getAttribute(driver, 'currentELDVersion', true);

      let _userSubscriptionPermission = userSubscriptionPermission;
      if (!_userSubscriptionPermission) {
        _userSubscriptionPermission = await getUserSubscriptionPermission(
          { sessionToken: getCurrentUserSessionToken() },
          getAttribute(getCurrentUser(), 'objectId'),
        );
      } else {
        if (!_userSubscriptionPermission.permissions.eldEditFeature.enabled) {
          _disabled = true;
          _tooltipMessage = 'Administrator permission required to edit';
        }
        if (!_userSubscriptionPermission.permissions.eldEditButton.enabled) {
          _disabled = true;
          _tooltipMessage = 'Administrator permission required to edit';
        }
        if (!_userSubscriptionPermission.modules.eld.enabled) {
          _disabled = true;
          _tooltipMessage = 'ELD Feature activation required to edit';
        }
      }

      if (!_disabled) {
        if (!getAttribute(props.eldDailyCertification, 'objectId', true)) {
          // if the daily cert is a faked one (uses _localId vs objectId), for now we do not allow edits
          _disabled = true;
          _tooltipMessage = 'Cannot edit logs on days with no Driver Activity';
        }
      }

      if (!_disabled) {
        if ((props.eldEdits || []).length > 0) {
          _disabled = true;
          _tooltipMessage = 'Awaiting ELD Confirmation of Edit';
          _buttonlabel = 'Edit Requested';

          if (props.eldEvent) _buttonlabel = 'Edit';
        }
      }

      if (!_disabled) {
        if (currentELDVersion && (parseFloat(currentELDVersion) > 0.89)) {
          isPastFourteenDayLimit = false;
        } else if (props.eldDailyCertification) {
          const startTimeUTC = props.eldDailyCertification.get('startTimeUTC');
          const currentDateStartOf = getELDDailyCertificationIntervalFromDriverTZ(driver, moment().startOf('day').toDate()).dayOf;
          const fourteenDaysAgo = moment(currentDateStartOf).subtract(14, 'days').toDate();
          isPastFourteenDayLimit = startTimeUTC.getTime() < fourteenDaysAgo.getTime();
        }

        if (isPastFourteenDayLimit) {
          _disabled = true;
          _tooltipMessage = 'Cannot edit logs past 14 Days (Applies to ELD Version <= 0.89)';
        }
      }

      // if overrides are allowed, we enable the button but still show the correct tooltip
      if (props.isFetched && props.forceEnable) _disabled = false;

      if (!didCancel) {
        setIsLoading(false);
        setDisabled(_disabled);
        setTooltipMessage(_tooltipMessage);
        setButtonLabel(_buttonlabel);
      }

    }

    init();
    return () => { didCancel = true; };
  }, [props.isFetched]);

  // JSX
  let className = `eldedit-modal-button ${uniqid()}`;
  if (!buttonLabel) className = `${className} no-label`;
  if (isLoading) className = `${className} no-label`;
  if (props.className) className += ` ${props.className}`;

  return (
    <>
      <Button
        className={className}
        label={buttonLabel}
        severity="secondary"
        onClick={() => props.toggleModalVisible()}
        sbVariant="short"
        disabled={disabled}
        tooltip={props.isFetched && tooltipMessage}
        tooltipOptions={{ position: 'mouse', showOnDisabled: props.isFetched && true }}
      />
      <Skeleton
        className={(!isLoading) ? 'hidden' : `eldedit-modal-button-skeleton ${uniqid()} ${props.className}`}
        width="105px"
        height="38px"
      />
    </>
  );
}

export default ELDEditModalButton;
