import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Feature, Layer } from 'react-mapbox-gl';

// API
import { getAttribute } from 'api/Parse';
import { isVehicleDriverDriving } from 'api/ELD';

// CSS
import styles from './ComponentStyles.module.scss';

// import shipping from 'assets/icon/local_shipping.png'

class VehiclesLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeVehicles: [],
      isIdlingArrowVehicles: [],
      isDrivingArrowVehicles: [],
      offDutyVehicles: [],
      directionVehicle: [],
    }
    this.refreshState = this.refreshState.bind(this);
  }

  componentDidMount() {
    this.refreshState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props !== prevProps) {
      this.refreshState();
    }
  }

  refreshState() {
    const { props } = this;

    const mentalGrey = '#292E37';
    const yellowTruck = '#E1AE37';
    const greenActive = '#2BBBA7';
    const grayCalm = '#656565';

    // Different type of vehicles array for different type of layers
    const activeVehicles = [];
    const isIdlingArrowVehicles = [];
    const isDrivingArrowVehicles = [];
    const offDutyVehicles = [];
    const directionVehicle = [];

    Object.keys(props.vehiclePerUnitId).forEach((vehicleUnitId, index) => {
      const vehicleIsActive = props.vehiclePerUnitId[vehicleUnitId].isActive;
      const { vehicle } = props.vehiclePerUnitId[vehicleUnitId];
      const vehicleLocationObj = getAttribute(vehicle, 'vehicleLocation', true);
      const isIdling = isVehicleDriverDriving(vehicle) && getAttribute(vehicleLocationObj, 'speedKm') === 0;
      const vehicleDirection = props.followBearing || isVehicleDriverDriving(vehicle) && ((vehicleLocationObj && getAttribute(vehicleLocationObj, 'gpsHeading')) || ((vehicleLocationObj && getAttribute(vehicleLocationObj, 'course'))));

      const vehicleProperties = { title: vehicleUnitId };
      if (vehicleDirection) {
        vehicleProperties.rotate = vehicleDirection - 90;
        if (isIdling) {
          vehicleProperties.color = yellowTruck;
          isIdlingArrowVehicles.push(
            <Feature
              onClick={() => { if (props.handleOnClick) props.handleOnClick(vehicle); }}
              properties={vehicleProperties}
              key={`${getAttribute(vehicle, 'objectId', true)}-idling`}
              coordinates={props.vehiclePerUnitId[vehicleUnitId].coordinate}
            />,
          );
        } else if (isVehicleDriverDriving(vehicle)) {
          vehicleProperties.color = greenActive;
          isDrivingArrowVehicles.push(
            <Feature
              onClick={() => { if (props.handleOnClick) props.handleOnClick(vehicle); }}
              properties={vehicleProperties}
              key={`${getAttribute(vehicle, 'objectId', true)}-driving`}
              coordinates={props.vehiclePerUnitId[vehicleUnitId].coordinate}
            />,
          );
        } else {
          vehicleProperties.color = mentalGrey;
          directionVehicle.push(
            <Feature
              onClick={() => { if (props.handleOnClick) props.handleOnClick(vehicle); }}
              properties={vehicleProperties}
              key={`${getAttribute(vehicle, 'objectId', true)}-direction`}
              coordinates={props.vehiclePerUnitId[vehicleUnitId].coordinate}
            />,
          );
        }
      } else if (vehicleIsActive) {
        vehicleProperties.color = mentalGrey;
        activeVehicles.push(
          <Feature
            onClick={() => { if (props.handleOnClick) props.handleOnClick(vehicle); }}
            properties={vehicleProperties}
            key={`${getAttribute(vehicle, 'objectId', true)}-active`}
            coordinates={props.vehiclePerUnitId[vehicleUnitId].coordinate}
          />,
        );
      } else {
        vehicleProperties.color = grayCalm;
        offDutyVehicles.push(
          <Feature
            onClick={() => { if (props.handleOnClick) props.handleOnClick(vehicle); }}
            properties={vehicleProperties}
            key={`${getAttribute(vehicle, 'objectId', true)}-offduty`}
            coordinates={props.vehiclePerUnitId[vehicleUnitId].coordinate}
          />,
        );
      }
    });

    this.setState({ ...this.state, activeVehicles, isIdlingArrowVehicles, isDrivingArrowVehicles, offDutyVehicles, directionVehicle });
  }

  render() {
    const { props, state } = this;
    const { activeVehicles, isIdlingArrowVehicles, isDrivingArrowVehicles, offDutyVehicles, directionVehicle } = state;
    /**
     * Desired Layer Ordering (bottom to top)
     * Off duty Vehicles
     * Active Vehicles
     * Direction Vehicles
     * Idling Vehicles
     * Driving Arrow Vehicles
     */
    return (
      <div className="vehicle-layers">
        <Layer
          type="symbol"
          id="vehicles-markers-offDutyVehicles"
          images={props.inactiveVehicleIcon}
          layout={{
            'icon-image': 'inactiveVehicleIcon',
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': ['format', ['get', 'title'], { 'font-scale': 0.7 }],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.6],
            'text-anchor': 'top',
            'visibility': props.visible ? 'visible' : 'none',
          }}
          paint={{ 'text-color': ['get', 'color'], 'text-halo-color': '#e1e1e1', 'text-halo-width': 2 }}
        >
          {offDutyVehicles}
        </Layer>
        <Layer
          type="symbol"
          id="vehicles-markers-activeVehicles"
          images={props.inactiveVehicleIcon}
          layout={{
            'icon-image': 'inactiveVehicleIcon',
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': ['format', ['get', 'title'], { 'font-scale': 0.7 }],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.6],
            'text-anchor': 'top',
            'visibility': props.visible ? 'visible' : 'none',
          }}
          paint={{ 'text-color': ['get', 'color'], 'text-halo-color': '#e1e1e1', 'text-halo-width': 2 }}

        >
          {activeVehicles}
        </Layer>
        <Layer
          type="symbol"
          id="vehicles-markers-directionVehicle"
          images={props.directionVehicleGreenIcon}
          layout={{
            'icon-image': 'directionVehicleGreenIcon',
            'icon-rotate': ['get', 'rotate'],
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': ['format', ['get', 'title'], { 'font-scale': 0.7 }],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.6],
            'text-anchor': 'top',
            'visibility': props.visible ? 'visible' : 'none',
          }}
          paint={{ 'text-color': ['get', 'color'], 'text-halo-color': '#e1e1e1', 'text-halo-width': 2 }}

        >
          {directionVehicle}
        </Layer>
        <Layer
          type="symbol"
          id="vehicles-markers-isIdlingArrowVehicles"
          images={props.directionVehicleYellowIcon}
          layout={{
            'icon-image': 'directionVehicleYellowIcon',
            'icon-rotate': ['get', 'rotate'],
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': ['format', ['get', 'title'], { 'font-scale': 0.7 }],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.6],
            'text-anchor': 'top',
            'visibility': props.visible ? 'visible' : 'none',
          }}
          paint={{ 'text-color': ['get', 'color'], 'text-halo-color': '#e1e1e1', 'text-halo-width': 2 }}

        >
          {isIdlingArrowVehicles}
        </Layer>
        <Layer
          type="symbol"
          id="vehicles-markers-isDrivingArrowVehicles"
          images={props.directionVehicleGreenIcon}
          layout={{
            'icon-image': 'directionVehicleGreenIcon',
            'icon-rotate': ['get', 'rotate'],
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': ['format', ['get', 'title'], { 'font-scale': 0.7 }],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.6],
            'text-anchor': 'top',
            'visibility': props.visible ? 'visible' : 'none',
          }}
          paint={{ 'text-color': ['get', 'color'], 'text-halo-color': '#e1e1e1', 'text-halo-width': 2 }}

        >
          {isDrivingArrowVehicles}
        </Layer>
      </div>
    );
  }
}

VehiclesLayer.propTypes = {
  inactiveVehicleIcon: PropTypes.array,
  inactiveVehicleIcon: PropTypes.array,
  localShippingGreenActiveIcon: PropTypes.array,
  localShippingYellowTruckIcon: PropTypes.array,
  vehiclePerUnitId: PropTypes.object,
  active: PropTypes.bool,
  company: PropTypes.object,
  displayMarkers: PropTypes.object,
  eldEvent: PropTypes.object,
  followCoordinates: PropTypes.array,
  handleOnClick: PropTypes.func,
  isTrailer: PropTypes.bool,
  isFollowActive: PropTypes.bool,
  vehicles: PropTypes.array,
};

export default VehiclesLayer;
