import { addRecord, createQuery, setQueryRestriction, updateRecord, find, includePointers, getAttribute, sortQuery, getCurrentUser } from 'api/Parse';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import Sort from 'sbObjects/Sort';

/** @module ELDDailyCertification */

 /**
 * @memberof module:ELDDailyCertification
 * @description get all ELD uncertified daily log based on the driverId
 * 
 * @param { object(Sort) } sortBy 
 * @param { string } driverId 
 * @param { filterObjectArray } filters 
 * @param { number } page // the page you want to show in the pagignation
 * @param { number } limit // the limit number for row that
 */
// sort = new Sort(AttributeTypes.USER_FULLNAME, QuerySortOrderTypes.DESCENDING),
// add belongtoCompany for restriction

// sort query 
async function getUncertifiedELDDailyCetifications(page = 0, limit = 25, sortBy = new Sort('startTimeUTC', QuerySortOrderTypes.DESCENDING), driverObjectIds = [], filters=[]) {
  
  const eldUncertifiedDailyQuery = createQuery('ELDDailyCertification');

  if (driverObjectIds.length) { 
    setQueryRestriction(eldUncertifiedDailyQuery, QueryRestrictionTypes.CONTAINED_IN, 'driver', driverObjectIds) 
  }
  else {
    setQueryRestriction(eldUncertifiedDailyQuery, QueryRestrictionTypes.EXISTS, 'driver');
  };
  setQueryRestriction(eldUncertifiedDailyQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'certified', true);


  // set subQuery for pointer driver in order to find the drivers who belongs to same company as user
  const driverQuery = createQuery('Driver');
  const currentUserBelongsToCompanyObjectId = getAttribute(getCurrentUser(), 'belongsToCompany', true);
  setQueryRestriction(driverQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany',currentUserBelongsToCompanyObjectId);
  setQueryRestriction(eldUncertifiedDailyQuery, QueryRestrictionTypes.MATCHES_QUERY, 'driver', driverQuery);

  // set query restricitons from filter
  filters.map(filter => {
    setQueryRestriction(eldUncertifiedDailyQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
  });

  includePointers( eldUncertifiedDailyQuery , ['driver', 'driver.user']);

  // set restriciton for the pagination
  setQueryRestriction(eldUncertifiedDailyQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  setQueryRestriction(eldUncertifiedDailyQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
  sortQuery(eldUncertifiedDailyQuery, sortBy.order, sortBy.attribute);

  return await find(eldUncertifiedDailyQuery, false, true);
}

export {
  getUncertifiedELDDailyCetifications
};