/** @module DispatchPayable */

import {
  callCloudFunction, copyQuery, count, createQuery, createQueryOr, destroyRecord, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, getCurrentUser, addRecord,
} from 'sb-csapi/dist/AAPI';

import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @memberof module:DispatchPayable
 *
 * @description Get DispatchPayables according to filter criteria
 *
 * @param {Object} [options] - Currently contains the current user session token
 * @param {Array} [sortBy] - Sort array of objects
 * @param {String} [companyObjectId] - Company we wish to retrieve payables for
 * @param {Array} [filters] - array of Filter objects
 * @param {Array} [includedPointers] - Included pointers
 * @param {Array} [selectedAttributes] - Select attributes to return
 * @param {Integer} [page] - The current page for pagination
 * @param {Integer} [limit] - The limit of records obtained per pagination
 * @param {Boolean} [queryAll] - Get all records, ignoring pages/limits
 *
 * @returns { DispatchPayable } - DispatchJobPayable record(s)
 */
async function getDispatchPayables(options = { sessionToken: getCurrentUserSessionToken() }, companyObjectId = getCurrentUserCompanyObjectId(), filters = [], sortBy = [new Sort(QuerySortOrder.DESCENDING, 'updatedAt')], includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  const dispatchPayableQuery = createQuery('DispatchPayable');

  const dispatchPayeeQuery = createQuery('DispatchPayee');
  setQueryRestriction(dispatchPayeeQuery, QueryRestriction.EQUAL_TO, companyObjectId);

  setQueryRestriction(dispatchPayableQuery, QueryRestriction.MATCHES_QUERY, 'dispatchPayee', dispatchPayeeQuery);
  // Set filters
  filters.map(filter => setQueryRestriction(dispatchPayableQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  const dispatchPayableCountQuery = copyQuery(dispatchPayableQuery);

  // Sort
  sortBy.forEach(sort => sortQuery(dispatchPayableQuery, sort.order, sort.attribute));

  if (includedPointers.length > 0) includePointers(dispatchPayableQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchPayableQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(dispatchPayableQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(dispatchPayableQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the count and the jobs
  const promises = [count(options, dispatchPayableCountQuery), findRecords(options, dispatchPayableQuery, false, queryAll)];

  try {
    const [totalDispatchPayablesCount, dispatchPayables] = await Promise.all(promises);
    return { totalDispatchPayablesCount, dispatchPayables };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchPayable
 *
 * @description Add a Dispatch Payable
 *
 * @param {object} keyValueObj - The select fields we wish to update
 *
 * @returns {DispatchPayable} - The created DispatchPayable
 */
async function addDispatchPayable(keyValueObj) {
  try {
    const dispatchPayable = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'DispatchPayable', keyValueObj, getCurrentUserCompanyObjectId());
    return dispatchPayable;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchPayable
 *
 * @description Update a DispatchPayable
 *
 * @param {DispatchPayable} dispatchPayable - The dispatchPayable to update. If not available, use parameter dispatchPayableObjectId instead
 * @param {string} dispatchPayableObjectId - The objectId of the dispatchPayable object we wish to update. Use if parameter dispatchPayable is unavailable
 * @param {object} keyValueObj - The select fields we wish to update
 * @param {bool} save - If true, executes save on the changes. If false, holds the changes locally but is not saved
 *
 * @returns {DispatchPayable} - The updated DispatchPayable
 */
async function updateDispatchPayable(dispatchPayable, dispatchPayableObjectId, keyValueObj, save) {
  if (!dispatchPayable && !dispatchPayableObjectId) throw new Error('Missing Arguments: Must provide dispatchPayable or dispatchPayableObjectId');
  if (dispatchPayable && dispatchPayableObjectId) throw new Error('Arguments: Must provide only one of dispatchPayable or dispatchPayableObjectId');

  let _dispatchPayable = dispatchPayable;

  try {
    if (!dispatchPayable && dispatchPayableObjectId) {
      const dispatchPayableQuery = createQuery('DispatchPayable');
      setQueryRestriction(dispatchPayableQuery, QueryRestriction.EQUAL_TO, 'objectId', dispatchPayableObjectId);
      _dispatchPayable = await findRecords({ sessionToken: getCurrentUserSessionToken() }, dispatchPayableQuery, true);
    }

    if (_dispatchPayable) {
      if (!Object.keys(keyValueObj).length) return _dispatchPayable;

      _dispatchPayable = await updateRecord({ sessionToken: getCurrentUserSessionToken() }, _dispatchPayable, keyValueObj, save);
      return _dispatchPayable;
    } else {
      throw new Error('DispatchPayable does not exist');
    }
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchPayable
 *
 * @description Void a DispatchPayable and append a given messages to its notes
 *
 * @param {DispatchPayable} dispatchPayable - The selected DispatchPayable
 * @param {String} message - The message to append to the notes
 *
 * @returns {DispatchPayable} - The created DispatchPayable
 */
async function voidDispatchPayable(dispatchPayable, message) {
  const notes = getAttribute(dispatchPayable, 'notes') || '';
  const updatedNotes = `${notes}\n ${message}`;
  try {
    const updatedDispatchPayable = await updateDispatchPayable(dispatchPayable, undefined, { isVoided: true, notes: updatedNotes }, true);
    return updatedDispatchPayable;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchPayable
 *
 * @description Destroy a DispatchPayable record
 *
 * @param {DispatchPayable} record - The DispatchPayable record to destroy
 *
 * @returns {DispatchPayable} - The destroyed DispatchPayable record
 */
async function destroyDispatchPayable(record) {
  if (!record) throw new Error('Missing Arguments: Must provide DispatchPayable record');
  try {
    const dispatchPayable = await destroyRecord({ sessionToken: getCurrentUserSessionToken() }, record);
    return dispatchPayable;
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getDispatchPayables,
  addDispatchPayable,
  updateDispatchPayable,
  voidDispatchPayable,
  destroyDispatchPayable,
};
