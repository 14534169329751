import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { createQuery, createTempRecord, find, getAttribute, getCurrentUser, includePointers, setQueryRestriction, sortQuery, updateRecord } from 'api/Parse';
import { formatStringLength, openDocumentLink } from 'api/Helpers';

import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

import { QueryRestrictionTypes, QuerySortOrderTypes } from 'enums/Query';

import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import SBEditButton from 'components/Shared/SBEditButton/SBEditButton';
import BundleLogs from 'components/BundleLogs/container/BundleLogs';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBProgress, MDBTooltip } from 'mdbreact';


// CSS
import './style.scss';

class HOSLogDownloadHistoryRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logDownload: props.logDownload,
    };

    this.updateProgress = this.updateProgress.bind(this);
  }

  componentDidMount() {
    const createdAt = getAttribute(this.props.logDownload, 'createdAt');
    const logFile = getAttribute(this.props.logDownload, 'file');
    if (!logFile && !moment().subtract('6', 'hours').isAfter(moment(createdAt))) {
      const downloadProgressRefreshTimer = setInterval(() => {
        this.updateProgress();
      }, 5000); // refresh every 5 seconds
      this.downloadProgressRefreshTimer = downloadProgressRefreshTimer;
    }
  }

  componentWillUnmount() {
    clearTimeout(this.downloadProgressRefreshTimer);
    this.downloadProgressRefreshTimer = null;
  }

  updateProgress() {
    this.props.logDownload.fetch().then((updatedLogDownload) => {
      const logFile = getAttribute(updatedLogDownload, 'file');
      if (logFile) {
        clearTimeout(this.downloadProgressRefreshTimer);
        this.downloadProgressRefreshTimer = null;
      }
      this.setState({ ...this.state, logDownload: updatedLogDownload });
    })
  }

  render() {
    const { props, state } = this;
    // here we not only group logs together by date, but while we're at it we can render what the row for this individual download will look like
    // first get the start of the request date by millis
    const createdAt = getAttribute(state.logDownload, 'createdAt');
    const logFile = getAttribute(state.logDownload, 'file');
    const errorString = getAttribute(state.logDownload, 'errorString');
    const logDownloadForDates = getAttribute(state.logDownload, 'dates');
    const driver = getAttribute(state.logDownload, 'driver');
    const timezoneOffsetFromUTC = getAttribute(driver, 'timezoneOffsetFromUTC', true) || moment.tz.guess();

    return (
      <MDBContainer key={getAttribute(state.logDownload, 'objectId')} className="log-download-row mt-3 p-0" fluid>
        <MDBRow>
          <MDBCol size="3">
            <div className="log-download-value" style={{ paddingTop: '.45em' }}>{getAttribute(state.logDownload, 'type') || 'N/A'}</div>
          </MDBCol>

          <MDBCol size="3">

            <div className="log-download-value text-center" style={{ paddingTop: '.45em' }}>
              {logDownloadForDates &&
                <MDBTooltip
                  placement="top"
                  tooltipContent="Download for these Driver Log Dates"
                  tooltipClass="log-download-tooltip"
                >
                  <React.Fragment>
                    {logDownloadForDates[3] &&
                      <div>{moment(logDownloadForDates[logDownloadForDates.length - 1]).tz(timezoneOffsetFromUTC).format('LL')}</div>
                    }
                    {logDownloadForDates[2] &&
                      <div>{moment(logDownloadForDates[Math.max(2, logDownloadForDates.length - 2)]).tz(timezoneOffsetFromUTC).format('LL')}</div>
                    }
                    {(logDownloadForDates.length > 5) &&
                      <div>...</div>
                    }
                    {logDownloadForDates[1] &&
                      <div>{moment(logDownloadForDates[1]).tz(timezoneOffsetFromUTC).format('LL')}</div>
                    }
                    {logDownloadForDates[0] &&
                      <div>{moment(logDownloadForDates[0]).tz(timezoneOffsetFromUTC).format('LL')}</div>
                    }
                  </React.Fragment>
                </MDBTooltip>
              }
            </div>
          </MDBCol>

          <MDBCol size="2">
            <div className="log-download-value text-center" style={{ paddingTop: '.45em' }}>
              <MDBTooltip
                placement="top"
                tooltipContent="Time of Request"
                tooltipClass="log-download-tooltip"
              >
                {moment(createdAt).format('hh:mm a')}
              </MDBTooltip>
            </div>
          </MDBCol>

          <MDBCol size="3">
            <div className="text-right">
              {!errorString && !logFile && !moment().subtract('6', 'hours').isAfter(moment(createdAt)) && state.loadingProgress !== null &&
                <MDBProgress value={getAttribute(state.logDownload, 'progressPercent')} className="my-2 log-download-status-bar" />
              }
              {!logFile && !errorString && moment().subtract('6', 'hours').isAfter(moment(createdAt)) &&
                <MDBBtn className="align-middle d-inline-block" color="primary" size="sm" disabled>
                  Failed
                </MDBBtn>
              }
              {errorString &&
                <span className="errorText">
                  {errorString}
                </span>
              }
              {logFile &&
                <MDBBtn className="align-middle d-inline-block" color="primary" size="sm" onClick={() => { openDocumentLink(logFile._url, '_blank') }}>
                  Download
                </MDBBtn>
              }
            </div>
          </MDBCol>

          <MDBCol size="1">
            <div className="text-right">
              <SBEditButton containerClassName="align-middle mt-1 d-inline-block" showCancelButton onClick={() => this.props.deleteLogDownload(state.logDownload)} />
            </div>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12">
            <hr />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

HOSLogDownloadHistoryRow.propTypes = {
  title: PropTypes.any,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  iconStyle: PropTypes.object,
};

export default HOSLogDownloadHistoryRow;
