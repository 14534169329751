/* eslint-disable react/react-in-jsx-scope */
import uniqid from 'uniqid';
import moment from 'moment-timezone';
import { useEffect, useState, useContext, useRef } from 'react';

// Hooks
import { useClickOutside } from 'primereact/hooks';

// API
import { addDispatchItem } from 'api/Dispatch/DispatchItem.old';
import { updateInvoiceOutdated } from 'api/Dispatch/DispatchJob';
import { getAttribute, updateRecord, getCurrentUserSessionToken } from 'sb-csapi/dist/AAPI';

// Context
import DispatchJobLayoutContext from 'contexts/DispatchJobLayoutContext';

// sbObjects
import DispatchItem from 'sbObjects/DispatchItem';

// Enums
import { FreightCategory } from 'sb-csapi/dist/enums/Dispatch/Freight';
import { CommodityTypes } from 'sb-csapi/dist/enums/Dispatch/Commodity';
import { MassUnitName, LengthUnitName } from 'sb-csapi/dist/enums/Unit';

// Components
import Card from 'sbCore/Card/Card';
import Button from 'sbCore/Button/Button';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import InputNumber from 'sbCore/InputNumber/InputNumber';
import InputText from 'sbCore/InputText/InputText';
import InputTextarea from 'sbCore/InputTextarea/InputTextarea';

import './style.scss';

export default function DispatchAddFreight(props) {
  const initialForm = {
    commodityType: undefined,
    commodityTypeCustomName: '',
    quantity: 0,
    category: undefined,
    weight: 0,
    massUnit: 1,
    itemLength: 0,
    width: 0,
    height: 0,
    lengthUnit: 1,
    name: '', // but this is actually the freight notes
  };

  // ** Context ** //
  const { refreshJob } = useContext(DispatchJobLayoutContext);

  const [dispatchItemRecord, setDispatchItemRecord] = useState(undefined);
  const [originalDispatchItem, setOriginalDispatchItem] = useState({ ...initialForm });
  const [dispatchItem, setDispatchItem] = useState({ ...initialForm });

  const [isSaving, setIsSaving] = useState(undefined);
  const [lastSavedDateTime, setLastSavedDateTime] = useState(undefined);

  const [undoStack, setUndoStack] = useState([]);
  const [undoRefreshToken, setUndoRefreshToken] = useState(undefined);

  const cardRef = useRef(null);

  // Callback function when closing the card
  function handleClose() {
    refreshJob();

    props.refreshInfo();
    props.handleClose();
  }

  // Hook that checks if the user clicks outside of the card
  useClickOutside(cardRef, () => {
    handleClose();
  });

  useEffect(() => {
    const { item } = props;

    if (item) {
      // Grab all the current attributes from the item and update state
      const _dispatchItem = getDispatchItemInformation(item);
      setDispatchItem(_dispatchItem);
      setOriginalDispatchItem(_dispatchItem);

      setDispatchItemRecord(item);
    } else {
      setDispatchItem({ ...initialForm });
      setOriginalDispatchItem({ ...initialForm });
    }
  }, [props]);

  useEffect(() => {
    handleSave(false);
  }, [undoRefreshToken]);

  // Generates an object from the dispatch item record to be used within the card and related functions
  function getDispatchItemInformation(dispatchItemRecord) {
    const commodityType = getAttribute(dispatchItemRecord, 'commodityType');
    const commodityTypeCustomName = (CommodityTypes[commodityType] === CommodityTypes[0] ? getAttribute(dispatchItemRecord, 'commodityTypeCustomName') : '');
    const quantity = getAttribute(dispatchItemRecord, 'quantity');
    const category = getAttribute(dispatchItemRecord, 'category');
    const weight = getAttribute(dispatchItemRecord, 'weight');
    const massUnit = getAttribute(dispatchItemRecord, 'massUnit');
    const itemLength = getAttribute(dispatchItemRecord, 'itemLength');
    const width = getAttribute(dispatchItemRecord, 'width');
    const height = getAttribute(dispatchItemRecord, 'height');
    const lengthUnit = getAttribute(dispatchItemRecord, 'lengthUnit');
    const name = getAttribute(dispatchItemRecord, 'name'); // but this is actually the freight notes

    const _dispatchItem = {
      commodityType,
      commodityTypeCustomName,
      quantity,
      category,
      weight,
      massUnit,
      itemLength,
      width,
      height,
      lengthUnit,
      name, // but this is actually the freight notes
    };

    return _dispatchItem;
  }

  // Helper function to check for any changes in the dispatch item
  function getDispatchItemChanges() {
    return Object.entries(dispatchItem).filter(([key, value]) => dispatchItem[key] !== originalDispatchItem[key]);
  }

  // Adds an "action" to the undo stack - this keeps track of the original values of fields before they are changed
  function addHistory() {
    const dispatchItemChanges = getDispatchItemChanges();

    // Fetch the original values before the changes
    const originalValues = [];

    dispatchItemChanges.forEach(([key, value]) => {
      originalValues.push([key, originalDispatchItem[key]]);
    });

    setUndoStack([...undoStack, ...originalValues]);
  }

  // Handles saving information from the card
  async function handleSave(writeToHistory) {
    const { dispatchTransfer, dispatchJob } = props;
    let _dispatchItem = { ...dispatchItem };

    const commodityTypeCustomName = (CommodityTypes[_dispatchItem.commodityType] === CommodityTypes[0] ? (_dispatchItem.commodityTypeCustomName || '').trim() : '');

    // if a custom name is filled in yet the freight type isn't custom, remove the custom name before submitting
    if (CommodityTypes[_dispatchItem.commodityType] !== CommodityTypes[0] && _dispatchItem.commodityTypeCustomName) {
      _dispatchItem.commodityTypeCustomName = '';
    }

    if (writeToHistory) addHistory();

    // First, check to see if there was actually any change to the dispatch item
    const dispatchItemChanges = getDispatchItemChanges();
    if (!dispatchItemChanges || dispatchItemChanges.length === 0) return;

    setIsSaving(true);

    let _dispatchItemRecord;

    if (dispatchItemRecord) {
      const sessionToken = getCurrentUserSessionToken();
      _dispatchItemRecord = await updateRecord({ sessionToken }, dispatchItemRecord, dispatchItem, true);
      _dispatchItem = getDispatchItemInformation(_dispatchItemRecord);

      setDispatchItem(_dispatchItem);
      setOriginalDispatchItem(_dispatchItem);
      setDispatchItemRecord(_dispatchItemRecord);
    } else if (dispatchTransfer) {
      // add new dispatch item object
      const dispatchItemObject = new DispatchItem(
        undefined,
        dispatchJob,
        dispatchTransfer,
        dispatchItem.quantity,
        dispatchItem.category,
        dispatchItem.weight,
        dispatchItem.massUnit,
        dispatchItem.itemLength,
        dispatchItem.width,
        dispatchItem.height,
        dispatchItem.lengthUnit,
        dispatchItem.name, // but this is actually the freight notes
        dispatchItem.commodityType,
        commodityTypeCustomName,
      );

      _dispatchItemRecord = await addDispatchItem(dispatchItemObject);
      _dispatchItem = getDispatchItemInformation(_dispatchItemRecord);

      setDispatchItem(_dispatchItem);
      setOriginalDispatchItem(_dispatchItem);
      setDispatchItemRecord(_dispatchItemRecord);
    }

    // Add a timeout so that its noticeable to the user that the dispatch item was saved
    setTimeout(() => {
      const lastUpdatedAt = getAttribute(_dispatchItemRecord, 'updatedAt');
      setLastSavedDateTime(lastUpdatedAt);
      setIsSaving(false);
    }, 750);

    await updateInvoiceOutdated(dispatchJob, true);
  }

  function undoAction() {
    const _undoStack = [...undoStack];
    if (!_undoStack || _undoStack.length === 0) return;

    // Grab the last action that was performed
    const lastAction = _undoStack.pop();

    const attribute = lastAction[0];
    const value = lastAction[1];

    // Create the new dispatch item with the modified attributes
    const _dispatchItem = { ...dispatchItem };
    _dispatchItem[attribute] = value;

    // Update the dispatch item with the new attributes and pop from the undo stack
    setDispatchItem(_dispatchItem);
    setUndoStack([...undoStack.slice(0, undoStack.length - 1)]);

    // This helps trigger the saving at the correct time, after the dispatch item state has fully been updated
    // Setting the state is not a synchronous operation, therefore without this, if we attempt to perform a save right away,
    // the save function would not be able to detect that there was a change in the state, and would skip saving
    setUndoRefreshToken(uniqid());
  }

  const title = (
    <div className="flex px-2 justify-content-between align-items-end">
      <div>
        <span>Freight Details</span>
      </div>
      <div className="flex flex-column align-items-end text-base font-normal mr-3 text-600">
        {(isSaving) && (
          <div className="flex justify-content-center align-items-center">
            <i className="pi pi-spin pi-spinner" />
            <p className="ml-2 mb-0">Saving...</p>
          </div>
        )}
        {(isSaving === false) && (
          <div className="dispatch-item-saved-message flex justify-content-center align-items-center">
            <i className="pi pi-check" />
            <p className="ml-2 mb-0">Saved</p>
          </div>
        )}
        {isSaving !== undefined && lastSavedDateTime && (
          <div className="text-sm font-normal text-500">
            {`Last saved at: ${moment(lastSavedDateTime).format('YYYY-MM-DD @ HH:mm:ss')}`}
          </div>
        )}
      </div>
    </div>
  );

  const footer = (
    <div className="flex flex-row-reverse justify-content-between">
      <Button
        icon="pi pi-times"
        label="Close"
        className="p-button-text"
        onClick={() => handleClose()}
      />
      <Button
        icon="pi pi-undo"
        tooltip="Undo last action"
        className="p-button-text"
        disabled={!undoStack || undoStack.length === 0}
        onClick={() => undoAction()}
      />
    </div>
  );

  // These are needed to convert the enums to a format recognized by primereact dropdown options - there might be a better way to do this
  const CommodityTypeOptions = Object.keys(CommodityTypes).map((key, index) => ({ label: CommodityTypes[key], value: index }));
  const MassUnitOptions = Object.keys(MassUnitName).map((key, index) => ({ label: `${MassUnitName[key]}s (${key.toLowerCase()})`, value: index }));
  const LengthUnitOptions = Object.keys(LengthUnitName).map((key, index) => index < 4 && ({ label: `${LengthUnitName[key]} (${key.toLowerCase()})`, value: index })).filter((option) => option);
  const FreightCategoryOptions = Object.entries(FreightCategory).map(([key, category]) => ({ label: category.description, value: category.type }));

  const isCommodityTypeCustom = CommodityTypes[dispatchItem.commodityType] === CommodityTypes[0];

  return (
    <Card
      cardRef={cardRef}
      title={title}
      footer={footer}
      className="freight-add-edit-card my-3 fadein animation-duration-500"
    >
      <div className="grid">
        {/* General */}
        <div className="col-12 flex">
          <div className="col-3">
            <InputLabel>Freight Type</InputLabel>
            <Dropdown
              appendTo="self"
              value={dispatchItem.commodityType}
              options={CommodityTypeOptions}
              onChange={(e) => setDispatchItem({ ...dispatchItem, commodityType: e.value })}
              onBlur={() => handleSave(true)}
              optionLabel="label"
              placeholder="Select Freight Type"
              className="mr-2 w-full"
              success={dispatchItem.commodityType !== undefined}
              warning={dispatchItem.commodityType === undefined}
            />
          </div>
          <div className="col-3">
            <InputLabel>Custom Freight</InputLabel>
            <InputText
              value={dispatchItem.commodityTypeCustomName}
              onChange={(e) => setDispatchItem({ ...dispatchItem, commodityTypeCustomName: e.target.value })}
              onBlur={() => handleSave(true)}
              placeholder="Name"
              className="w-full"
              disabled={!isCommodityTypeCustom}
            />
            <InputLabel
              className={`${!isCommodityTypeCustom ? 'hidden' : ''}`}
              warning
            >
              * Select Custom Freight Type to Add Custom Name
            </InputLabel>
          </div>
          <div className="col-3">
            <InputLabel>Quantity</InputLabel>
            <InputNumber
              value={dispatchItem.quantity}
              onChange={(e) => setDispatchItem({ ...dispatchItem, quantity: e.value })}
              onBlur={() => handleSave(true)}
              mode="decimal"
              min={0}
              className="w-full"
            />
          </div>
          <div className="col-3">
            <InputLabel>Category</InputLabel>
            <Dropdown
              filter
              appendTo="self"
              value={dispatchItem.category}
              options={FreightCategoryOptions}
              onChange={(e) => setDispatchItem({ ...dispatchItem, category: e.value })}
              onBlur={() => handleSave(true)}
              optionLabel="label"
              placeholder="Select Category"
              className="mr-2 w-full"
              success={dispatchItem.category !== undefined}
              warning={dispatchItem.category === undefined}
            />
          </div>
        </div>

        {/* Weight */}
        <div className="col-12 flex">
          <div className="col-3">
            <InputLabel>Total Weight</InputLabel>
            <InputNumber
              value={dispatchItem.weight}
              onChange={(e) => setDispatchItem({ ...dispatchItem, weight: e.value })}
              onBlur={() => handleSave(true)}
              mode="decimal"
              min={0}
              maxFractionDigits={2}
              className="w-full"
            />
          </div>
          <div className="col-3">
            <InputLabel>Weight Unit</InputLabel>
            <Dropdown
              appendTo="self"
              value={dispatchItem.massUnit}
              options={MassUnitOptions}
              onChange={(e) => setDispatchItem({ ...dispatchItem, massUnit: e.value })}
              onBlur={() => handleSave(true)}
              optionLabel="label"
              className="w-full"
            />
          </div>
        </div>

        {/* Dimensions */}
        <div className="col-12 flex">
          <div className="col-3">
            <InputLabel>Length</InputLabel>
            <InputNumber
              value={dispatchItem.itemLength}
              onChange={(e) => setDispatchItem({ ...dispatchItem, itemLength: e.value })}
              onBlur={() => handleSave(true)}
              mode="decimal"
              min={0}
              maxFractionDigits={2}
              className="w-full"
            />
          </div>
          <div className="col-3">
            <InputLabel>Width</InputLabel>
            <InputNumber
              value={dispatchItem.width}
              onChange={(e) => setDispatchItem({ ...dispatchItem, width: e.value })}
              onBlur={() => handleSave(true)}
              mode="decimal"
              min={0}
              maxFractionDigits={2}
              className="w-full"
            />
          </div>
          <div className="col-3">
            <InputLabel>Height</InputLabel>
            <InputNumber
              value={dispatchItem.height}
              onChange={(e) => setDispatchItem({ ...dispatchItem, height: e.value })}
              onBlur={() => handleSave(true)}
              mode="decimal"
              min={0}
              maxFractionDigits={2}
              className="w-full"
            />
          </div>
          <div className="col-3">
            <InputLabel>Dimension Unit</InputLabel>
            <Dropdown
              appendTo="self"
              value={dispatchItem.lengthUnit}
              options={LengthUnitOptions}
              onChange={(e) => setDispatchItem({ ...dispatchItem, lengthUnit: e.value })}
              onBlur={() => handleSave(true)}
              optionLabel="label"
              className="w-full"
            />
          </div>
        </div>

        {/* Freight Notes */}
        <div className="col-12 px-3">
          <InputLabel>Freight Notes</InputLabel>
          <InputTextarea
            value={dispatchItem.name} // but this is actually the freight notes
            onChange={(e) => setDispatchItem({ ...dispatchItem, name: e.target.value })}
            onBlur={() => handleSave(true)}
            rows={5}
            cols={30}
            autoResize
          />
        </div>
      </div>
    </Card>
  );
}
