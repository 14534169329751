import React from 'react';
import moment from 'moment-timezone';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// SB-CSAPI
import { getAttribute } from 'sb-csapi/dist/AAPI';
import Filter from 'sb-csapi/dist/sbObjects/Filter';
import { formatName } from 'sb-csapi/dist/utils/String';
import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';


// Components
import Skeleton from 'sbCore/Skeleton/Skeleton';
import Column from 'sbCore/Column/Column';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import DataTable from 'sbCore/DataTable/DataTable';
import PayableMissingWarning from 'components/Dispatch/PayableMissingWarning/PayableMissingWarning';

// API
import { getDispatchJobs } from 'api/Dispatch/DispatchJob';
import { getDispatchDrivers } from 'api/Dispatch/DispatchDriver';
import { getDispatchVehicles } from 'api/Dispatch/DispatchVehicle';
import { getDispatchTrailers } from 'api/Dispatch/DispatchTrailer';
import { getDispatchTransfers } from 'api/Dispatch/DispatchTransfer';

// Enums
import { AssignStatusTypes } from 'enums/DispatchJob';
import { AttributeTypes, StatusTypes } from 'enums/DispatchJob';

import './style.scss';

function DispatchingTable({ ...props }) {
  const [isLoading, setIsLoading] = useState(true);
  const [dispatchingTableContents, setDispatchingTableContents] = useState([]);
  const [unfilteredDispatchingTableContents, setUnfilteredDispatchingTableContents] = useState([]);
  const [totalDispatchJobsCount, setTotalDispatchJobsCount] = useState(0);

  const [fetchAttributes, setFetchAttributes] = useState({
    first: 0,
    rows: 15,
    page: 0,
    sortField: null,
    sortOrder: null,
    sortBy: {
      attribute: AttributeTypes.CREATED_AT,
      order: QuerySortOrder.DESCENDING,
    },
    filters: {
      'status': { value: undefined, matchMode: 'equals' },
    },
    dbFilters: [],
  });

  /**
   * @description This function retrieves the information required for the Dispatching table, and creates an array of objects corresponding to the dispatchJobs
   * @param {string} updatedDispatchJob - given a dispatch job, fetch any updates for that specific dispatch job
   */
  async function getDispatchingTableContents(updatedDispatchJob) {
    setIsLoading(true);

    let _dispatchingTableContents = {};
    const updatedDispatchJobObjectId = updatedDispatchJob && getAttribute(updatedDispatchJob, 'objectId');

    const _filters = [...fetchAttributes.dbFilters];
    if (updatedDispatchJobObjectId) {
      // If there is a DispatchJob passed in, then only retrieve information about that dispatch job
      _filters.push(new Filter(QueryRestriction.EQUAL_TO, 'objectId', updatedDispatchJobObjectId));
    }

    // First, grab the dispatch jobs - based on pagination
    const { totalDispatchJobsCount, dispatchJobs } = await getDispatchJobs(
      undefined,                          // options - default
      undefined,                          // companyobjectid - default
      false,                              // include child company results
      _filters,                           // filters
      undefined,                          // sort - default
      ['carrierDispatchOrganization'],    // includes
      undefined,                          // selects
      updatedDispatchJobObjectId ? 0 : fetchAttributes.page,               // page - if updatedDispatchJobObjectId, then reset the page back to 0 otherwise it will not be able to find the record
      fetchAttributes.rows,               // limit
      false                               // query all
    );

    // Now that we have the DispatchJobs - get the associated DispatchTransfers for each Job
    const dispatchTransfersFromDispatchJobsPromise = dispatchJobs.map((dispatchJob) => {
      const dispatchJobObjectId = getAttribute(dispatchJob, 'objectId');
      const batchId = getAttribute(dispatchJob, 'batchId', true);
      const carrierDispatchOrganization = getAttribute(dispatchJob, 'carrierDispatchOrganization');

      // Add in the relevant information from the dispatch job to the table information
      _dispatchingTableContents[dispatchJobObjectId] = {
        dispatchJob,
        orderNo: batchId,
        carrierDispatchOrganization,
      };

      let dispatchTransfersResult = getDispatchTransfers(
        undefined,                        // options - default
        dispatchJobObjectId,              // dispatchJobObjectId - default
        undefined,                        // filters
        undefined,                        // sort - default
        [
          'pickupDispatchAction',
          'pickupDispatchAction.address',
          'dropoffDispatchAction',
          'dropoffDispatchAction.address',
          'shipperDispatchOrganization',
          'shipperDispatchOrganization.address',
          'consigneeDispatchOrganization',
          'consigneeDispatchOrganization.address',
          'shipperAddress',
          'consigneeAddress',
        ],                                // includes
        undefined,                        // selects
        undefined,                        // page
        undefined,                        // limit
        true                              // query all
      );

      return dispatchTransfersResult;
    });

    let dispatchTransfersSortedByDispatchJobArray = await Promise.all(dispatchTransfersFromDispatchJobsPromise);
    dispatchTransfersSortedByDispatchJobArray = dispatchTransfersSortedByDispatchJobArray.map(({ dispatchTransfers }) => dispatchTransfers).filter((dispatchTransfers) => dispatchTransfers && dispatchTransfers.length > 0);

    // Set the state a bit earlier before everything is loaded in so that we can slowly fill in the information
    if (!updatedDispatchJobObjectId) {
      setIsLoading(false);
      setTotalDispatchJobsCount(totalDispatchJobsCount);
      setDispatchingTableContents(Object.values(_dispatchingTableContents));
      setUnfilteredDispatchingTableContents(Object.values(_dispatchingTableContents));
    }

    for await (const dispatchTransferArr of dispatchTransfersSortedByDispatchJobArray) {
      const dispatchJob = dispatchTransferArr && dispatchTransferArr.length > 0 && getAttribute(dispatchTransferArr[0], 'dispatchJob');
      const dispatchJobObjectId = dispatchJob && getAttribute(dispatchJob, 'objectId');
      const carrierDispatchOrganization = dispatchJob && getAttribute(dispatchJob, 'carrierDispatchOrganization');

      if (!_dispatchingTableContents[dispatchJobObjectId]['dispatchTransfers']) {
        _dispatchingTableContents[dispatchJobObjectId]['dispatchTransfers'] = {};
      }

      const dispatchDriverAndEquipmentPromises = dispatchTransferArr.map(async (dispatchTransfer) => {
        const dispatchTransferObjectId = getAttribute(dispatchTransfer, 'objectId');

        // Get the equipment and driver information for this transfer
        const dispatchDriversPromises = getDispatchDrivers(
          undefined,                        // options - default
          dispatchTransferObjectId,         // dispatchTransferObjectId - default
          undefined,                        // filters
          undefined,                        // sort - default
          ['driver'],                       // includes
          undefined,                        // selects
          undefined,                        // page
          undefined,                        // limit
          true                              // query all
        );

        const dispatchVehiclesPromises = getDispatchVehicles(
          undefined,                        // options - default
          dispatchTransferObjectId,         // dispatchTransferObjectId - default
          undefined,                        // filters
          undefined,                        // sort - default
          ['vehicle'],                      // includes
          undefined,                        // selects
          undefined,                        // page
          undefined,                        // limit
          true                              // query all
        );

        const dispatchTrailersPromises = getDispatchTrailers(
          undefined,                        // options - default
          dispatchTransferObjectId,         // dispatchTransferObjectId - default
          undefined,                        // filters
          undefined,                        // sort - default
          ['trailer'],                      // includes
          undefined,                        // selects
          undefined,                        // page
          undefined,                        // limit
          true                              // query all
        );

        return Promise.all([dispatchDriversPromises, dispatchVehiclesPromises, dispatchTrailersPromises]);
      });

      let dispatchDriverAndEquipmentSortedByDispatchTransferArr = await Promise.all(dispatchDriverAndEquipmentPromises);

      // These are to determine the overall status of the job
      let dispatchJobStatus;
      const dispatchTransferStatusesSet = new Set();

      dispatchTransferArr.forEach((dispatchTransfer, index) => {
        const dispatchTransferObjectId = getAttribute(dispatchTransfer, 'objectId');
        const dispatchDriverAndEquipmentArr = dispatchDriverAndEquipmentSortedByDispatchTransferArr[index];

        const dispatchDriverArr = dispatchDriverAndEquipmentArr[0].dispatchDrivers.filter((dispatchDriver) => dispatchDriver);
        const dispatchVehicleArr = dispatchDriverAndEquipmentArr[1].dispatchVehicles.filter((dispatchVehicle) => dispatchVehicle);
        const dispatchTrailerArr = dispatchDriverAndEquipmentArr[2].dispatchTrailers.filter((dispatchTrailer) => dispatchTrailer);

        // DateTime for pickup and dropoff
        const pickupDateTime = getAttribute(dispatchTransfer, 'pickupDateTime');
        const dropoffDateTime = getAttribute(dispatchTransfer, 'dropoffDateTime');

        // Pickup and dropoff locations

        // const pickupDispatchAction = getAttribute(dispatchTransfer, 'pickupDispatchAction');
        // const pickupAddress = pickupDispatchAction && getAttribute(pickupDispatchAction, 'address');
        // const dropoffDispatchAction = getAttribute(dispatchTransfer, 'pickupDispatchAction');
        // const dropoffAddress = dropoffDispatchAction && getAttribute(dropoffDispatchAction, 'address');

        const shipperDispatchOrganization = getAttribute(dispatchTransfer, 'shipperDispatchOrganization');
        let shipperDispatchOrganizationAddress = shipperDispatchOrganization && getAttribute(shipperDispatchOrganization, 'address');
        const shipperAddress = getAttribute(dispatchTransfer, 'shipperAddress');

        const consigneeDispatchOrganization = getAttribute(dispatchTransfer, 'consigneeDispatchOrganization');
        let consigneeDispatchOrganizationAddress = consigneeDispatchOrganization && getAttribute(consigneeDispatchOrganization, 'address');
        const consigneeAddress = getAttribute(dispatchTransfer, 'consigneeAddress');

        let pickupCity;
        let pickupStateProvince;

        let dropoffCity;
        let dropoffStateProvince;

        if (shipperAddress) {
          pickupCity = getAttribute(shipperAddress, 'city');
          pickupStateProvince = getAttribute(shipperAddress, 'stateProvince');
        } else if (shipperDispatchOrganizationAddress) {
          pickupCity = getAttribute(shipperDispatchOrganizationAddress, 'city');
          pickupStateProvince = getAttribute(shipperDispatchOrganizationAddress, 'stateProvince');
        }

        if (consigneeAddress) {
          dropoffCity = getAttribute(consigneeAddress, 'city');
          dropoffStateProvince = getAttribute(consigneeAddress, 'stateProvince');
        } else if (consigneeDispatchOrganizationAddress) {
          dropoffCity = getAttribute(consigneeDispatchOrganizationAddress, 'city');
          dropoffStateProvince = getAttribute(consigneeDispatchOrganizationAddress, 'stateProvince');
        }

        // const pickupCity = pickupAddress && getAttribute(pickupAddress, 'city');
        // const pickupStateProvince = pickupAddress && getAttribute(pickupAddress, 'stateProvince');
        // const dropoffCity = dropoffAddress && getAttribute(dropoffAddress, 'city');
        // const dropoffStateProvince = dropoffAddress && getAttribute(dropoffAddress, 'stateProvince');

        const pickupLocation = (pickupCity && pickupStateProvince) && `${pickupCity}, ${pickupStateProvince}`;
        const dropoffLocation = (dropoffCity && dropoffStateProvince) && `${dropoffCity}, ${dropoffStateProvince}`;

        _dispatchingTableContents[dispatchJobObjectId]['dispatchTransfers'][dispatchTransferObjectId] = {
          pickupDateTime,
          dropoffDateTime,
          pickupLocation,
          dropoffLocation,
        };

        if (dispatchDriverArr.length > 0 && dispatchVehicleArr.length > 0) {
          _dispatchingTableContents[dispatchJobObjectId]['dispatchTransfers'][dispatchTransferObjectId]['status'] = AssignStatusTypes.ASSIGNED
        } else if (dispatchDriverArr.length > 0 || dispatchVehicleArr.length > 0) {
          _dispatchingTableContents[dispatchJobObjectId]['dispatchTransfers'][dispatchTransferObjectId]['status'] = AssignStatusTypes.PARTIAL
        } else {
          _dispatchingTableContents[dispatchJobObjectId]['dispatchTransfers'][dispatchTransferObjectId]['status'] = AssignStatusTypes.UNASSIGNED
        }

        // Add in the statuses
        dispatchTransferStatusesSet.add(_dispatchingTableContents[dispatchJobObjectId]['dispatchTransfers'][dispatchTransferObjectId]['status']);

        // Retrieves the driver, vehicle, and trailer records from the corresponding Dispatch records and adds it into the table information
        _dispatchingTableContents[dispatchJobObjectId]['dispatchTransfers'][dispatchTransferObjectId]['drivers'] = dispatchDriverArr.map((dispatchDriver) => getAttribute(dispatchDriver, 'driver')).filter((driver) => driver);
        _dispatchingTableContents[dispatchJobObjectId]['dispatchTransfers'][dispatchTransferObjectId]['vehicles'] = dispatchVehicleArr.map((dispatchVehicle) => getAttribute(dispatchVehicle, 'vehicle')).filter((vehicle) => vehicle);
        _dispatchingTableContents[dispatchJobObjectId]['dispatchTransfers'][dispatchTransferObjectId]['trailers'] = dispatchTrailerArr.map((dispatchTrailer) => getAttribute(dispatchTrailer, 'trailer')).filter((trailer) => trailer);
      });

      // If a dispatch job is assigned to a carrier, then the status should be assigned
      if ((carrierDispatchOrganization) || (dispatchTransferStatusesSet.has(AssignStatusTypes.ASSIGNED) && !dispatchTransferStatusesSet.has(AssignStatusTypes.UNASSIGNED) && !dispatchTransferStatusesSet.has(AssignStatusTypes.PARTIAL))) {
        // set only contains assigned
        dispatchJobStatus = AssignStatusTypes.ASSIGNED;
      } else if (dispatchTransferStatusesSet.has(AssignStatusTypes.ASSIGNED) && (dispatchTransferStatusesSet.has(AssignStatusTypes.UNASSIGNED) || dispatchTransferStatusesSet.has(AssignStatusTypes.PARTIAL))) {
        // set contains assigned as well as a mixture of unassigned and partial
        dispatchJobStatus = AssignStatusTypes.PARTIAL;
      } else {
        // set contains no assigned legs, thus the job cannot be in a partial assigned state
        dispatchJobStatus = AssignStatusTypes.UNASSIGNED;
      }

      _dispatchingTableContents[dispatchJobObjectId]['status'] = dispatchJobStatus.key;

      // Set the state a bit earlier before everything is loaded in so that we can slowly fill in the information
      if (!updatedDispatchJobObjectId) {
        setTotalDispatchJobsCount(totalDispatchJobsCount);
        setDispatchingTableContents(Object.values(_dispatchingTableContents));
        setUnfilteredDispatchingTableContents(Object.values(_dispatchingTableContents));
      }
    }

    // Sample of dispatchingTableContents object
    // dispatchingTableContents = {
    //   dispatchJobObjectId: {
    //     dispatchJob,
    //     status,
    //     orderNo,
    //     dispatchTransfers: {
    //       dispatchTransferObjectId: {
    //         pickupDateTime,
    //         dropoffDateTime,
    //         vehicle,
    //         driver,
    //         equipment,
    //       },
    //       dispatchTransferObjectId: {
    //         pickupDateTime,
    //         dropoffDateTime,
    //         vehicle,
    //         driver,
    //         equipment,
    //       },
    //     }
    //   }
    // }

    if (updatedDispatchJobObjectId) {
      // Keep the existing table contents as is, and update the affected dispatch job
      const refreshedIndex = dispatchingTableContents.findIndex((dispatchJobTableObject) => getAttribute(dispatchJobTableObject.dispatchJob, 'objectId') === updatedDispatchJobObjectId);
      const updatedDispatchingTableContents = [...dispatchingTableContents];

      const _dispatchingTableContentsArr = Object.values(_dispatchingTableContents);
      if (_dispatchingTableContentsArr && _dispatchingTableContentsArr.length > 0 && refreshedIndex !== -1) updatedDispatchingTableContents[refreshedIndex] = _dispatchingTableContentsArr[0];

      setDispatchingTableContents(Object.values(updatedDispatchingTableContents));
      setUnfilteredDispatchingTableContents(Object.values(updatedDispatchingTableContents)); // Only here for filtering of statuses locally
    } else {
      setTotalDispatchJobsCount(totalDispatchJobsCount);
      setDispatchingTableContents(Object.values(_dispatchingTableContents));
      setUnfilteredDispatchingTableContents(Object.values(_dispatchingTableContents)); // Only here for filtering of statuses locally
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (fetchAttributes.filters.status.value) onFilter(fetchAttributes); // Workaround for not filtering when switching pages - this is due to lazy loading not working with filters
  }, [unfilteredDispatchingTableContents]);

  // useEffect which triggers whenever there is a change in the refresh id - used for when the dispatch drawer closes
  useEffect(() => {
    let didCancel = false;

    async function refresh() {
      if (!didCancel) {
        await getDispatchingTableContents(props.updatedDispatchJob);
      }
    }

    refresh();
    return () => { didCancel = true; };
  }, [props.refreshContent]);

  useEffect(() => {
    let didCancel = false;

    async function refresh() {
      if (!didCancel) {
        await getDispatchingTableContents();
      }
    }

    refresh();
    return () => { didCancel = true; };
  }, [fetchAttributes.page]);

  function getJobStatusColorClass(status) {
    let className = '';

    Object.keys(AssignStatusTypes).find(key => {
      const statusObject = AssignStatusTypes[key];
      const isMatchingStatus = statusObject.key === status;

      if (isMatchingStatus) {
        // find the matching background color className (ex. SOME_VALUE -> some-value -> bg-dispatch-job-some-value)
        const colorClassKeyExpression = key.replace(/_/g, '-').toLowerCase();
        className = ` bg-dispatch-job-${colorClassKeyExpression}`;
      }
      return isMatchingStatus;
    });

    return className;
  }

  function renderHeader() {
    return (
      <div className="flex justify-content-between align-items-center">
        <h5 className="m-0 text-2xl font-semibold">Dispatch</h5>
      </div>
    );
  };

  // This is only here to allow for filtering with lazy tables - currently only filters items that are already loaded
  // be careful when using ANYTHING OTHER THAN events.filters as the event object may be different depending on where its being called
  // the event obj is different when called from getDispatchingTableContents than when it gets called by the DataTable callback
  function onFilter(event) {
    setIsLoading(true);

    const newFetchAttributes = { ...fetchAttributes, filters: event.filters };
    setFetchAttributes(newFetchAttributes);

    if (event.filters.status) {
      // Filter by the status
      if (event.filters.status.value) {
        const filteredTableContents = unfilteredDispatchingTableContents.filter((rowData) => rowData.status === event.filters.status.value);
        setDispatchingTableContents(filteredTableContents);
      } else {
        setDispatchingTableContents(unfilteredDispatchingTableContents);
      }
    }

    setIsLoading(false);
  }

  function statusBodyTemplate(rowData) {
    if (!rowData.hasOwnProperty('status')) {
      return <Skeleton height="2rem" width="14rem" />
    }

    const { status } = rowData;
    const className = `dispatching-job-status text-center ${getJobStatusColorClass(status)}`;

    if (!status) return <div>-</div>;
    return (
      <div className="flex">
        <div className={className}>{status}</div>
        <PayableMissingWarning
          dispatchJob={rowData.dispatchJob}
        />
      </div>
    );
  }

  function orderNumberBodyTemplate(rowData) {
    return (
      <div>
        <Link target="_blank" to={`/dispatch/job/${getAttribute(rowData.dispatchJob, 'objectId')}`}>{rowData.orderNo}</Link>
      </div>
    );
  }

  function dateDueBodyTemplate(rowData) {
    if (!rowData.hasOwnProperty('dispatchTransfers')) {
      return <Skeleton height="2rem" />
    }

    const { dispatchTransfers } = rowData;
    if (!dispatchTransfers) return <div>-</div>;

    let latestDateTime = undefined;

    // Go through the DispatchTransfer objects and find the latest dropoffDateTime
    Object.values(dispatchTransfers).map((dispatchTransferObj) => {
      const { dropoffDateTime } = dispatchTransferObj;
      if (!dropoffDateTime) return;
      if (!latestDateTime || moment(dropoffDateTime).isAfter(moment(latestDateTime))) latestDateTime = dropoffDateTime;
    });

    return <div>{latestDateTime ? moment(latestDateTime).format('DD-MM-YYYY @ HH:mm') : '-'}</div>
  }

  function dateStartBodyTemplate(rowData) {
    if (!rowData.hasOwnProperty('dispatchTransfers')) {
      return <Skeleton height="2rem" />
    }

    const { dispatchTransfers } = rowData;
    if (!dispatchTransfers) return <div>-</div>;

    let earliestDateTime;

    // Go through the DispatchTransfer objects and find the earliest pickupDateTime
    Object.values(dispatchTransfers).map((dispatchTransferObj) => {
      const { pickupDateTime } = dispatchTransferObj;
      if (!pickupDateTime) return;
      if (!earliestDateTime || moment(pickupDateTime).isBefore(moment(earliestDateTime))) earliestDateTime = pickupDateTime;
    });

    return (<div>{earliestDateTime ? moment(earliestDateTime).format('DD-MM-YYYY @ HH:mm') : '-'}</div>);
  }

  function originBodyTemplate(rowData) {
    if (!rowData.hasOwnProperty('dispatchTransfers')) {
      return <Skeleton height="2rem" />
    }

    const { dispatchTransfers } = rowData;
    if (!dispatchTransfers) return <div>-</div>;

    // First, filter out any transfers without a pickupDateTime or pickupLocation
    // Next, sort the transfers by ascending pickupdateTime and take the first transfer's pickup location
    const filteredDispatchTransfers = Object.values(dispatchTransfers).filter((dispatchTransferObj) => dispatchTransferObj.pickupLocation && dispatchTransferObj.pickupDateTime)
    const sortedDispatchTransfers = filteredDispatchTransfers.sort((dispatchTransferObjA, dispatchTransferObjB) => moment(dispatchTransferObjA.pickupDateTime) - moment(dispatchTransferObjB.pickupDateTime))

    return <div>{(sortedDispatchTransfers && sortedDispatchTransfers.length > 0) ? sortedDispatchTransfers[0].pickupLocation : '-'}</div>;
  }

  function destinationBodyTemplate(rowData) {
    if (!rowData.hasOwnProperty('dispatchTransfers')) {
      return <Skeleton height="2rem" />
    }

    const { dispatchTransfers } = rowData;
    if (!dispatchTransfers) return <div>-</div>;

    // First, filter out any transfers without a dropoffDateTime or dropoffLocation
    // Next, sort the transfers by descending dropoffDateTime and take the first transfer's dropoffLocation
    const filteredDispatchTransfers = Object.values(dispatchTransfers).filter((dispatchTransferObj) => dispatchTransferObj.dropoffLocation && dispatchTransferObj.dropoffDateTime)
    const sortedDispatchTransfers = filteredDispatchTransfers.sort((dispatchTransferObjA, dispatchTransferObjB) => moment(dispatchTransferObjB.dropoffDateTime) - moment(dispatchTransferObjA.dropoffDateTime))

    return <div>{(sortedDispatchTransfers && sortedDispatchTransfers.length > 0) ? sortedDispatchTransfers[0].dropoffLocation : '-'}</div>;
  }

  function driversBodyTemplate(rowData) {
    if (!rowData.hasOwnProperty('dispatchTransfers') && !rowData.hasOwnProperty('carrierDispatchOrganization')) {
      return <Skeleton height="2rem" />
    }

    const { dispatchTransfers, carrierDispatchOrganization } = rowData;

    if (carrierDispatchOrganization) {
      // Return back carrier information rather than driver information if the job has been dispatched to a carrier
      const name = getAttribute(carrierDispatchOrganization, 'organizationName', true) || '-';
      return <div>{name}</div>
    }

    if (!dispatchTransfers) return <div>-</div>;

    // Grab all the drivers from the Dispatch transfers, and format their names
    const allDriversArr = Object.values(dispatchTransfers).map((dispatchTransfer) => dispatchTransfer.drivers).flat();
    const driverFullNamesArr = allDriversArr.map((driver) => getAttribute(driver, 'user_fullName') && formatName(getAttribute(driver, 'user_fullName'))).filter((driverFullName) => driverFullName);

    return (
      <div>
        {(driverFullNamesArr && driverFullNamesArr.length > 0) ? driverFullNamesArr.map((driverFullName) => <div>{driverFullName}</div>) : '-'}
      </div>
    );
  }

  function vehiclesBodyTemplate(rowData) {
    if (!rowData.hasOwnProperty('dispatchTransfers')) {
      return <Skeleton height="2rem" />
    }

    const { dispatchTransfers } = rowData;
    if (!dispatchTransfers) return <div>-</div>;

    const allVehiclesArr = Object.values(dispatchTransfers).map((dispatchTransfer) => dispatchTransfer.vehicles).flat();

    return (
      <div>
        {(allVehiclesArr && allVehiclesArr.length > 0) ? allVehiclesArr.map((vehicle) => <div>{getAttribute(vehicle, 'unitId')}</div>) : '-'}
      </div>
    );
  }

  function trailersBodyTemplate(rowData) {
    if (!rowData.hasOwnProperty('dispatchTransfers')) {
      return <Skeleton height="2rem" />
    }

    const { dispatchTransfers } = rowData;
    if (!dispatchTransfers) return <div>-</div>;

    const allTrailersArr = Object.values(dispatchTransfers).map((dispatchTransfer) => dispatchTransfer.trailers).flat();

    return (
      <div>
        {(allTrailersArr && allTrailersArr.length > 0) ? allTrailersArr.map((trailer) => <div>{getAttribute(trailer, 'unitId')}</div>) : '-'}
      </div>
    );
  }

  // Format the AssignStatusTypes to be used in the filter dropdown
  const dispatchAssignStatusTypeOptions = Object.entries(AssignStatusTypes).map((key, value) => key[1].key);

  function itemTemplate(option) {
    const className = `dispatching-job-status-dropdown-item${getJobStatusColorClass(option)}`;

    return (
      <div className={className}>
        {option}
      </div>
    );
  }

  function valueTemplate(option) {
    const className = `dispatching-job-status-dropdown-item text-center${getJobStatusColorClass(option)}`;

    return (
      <div className={className}>
        {option || 'Select Status'}
      </div>
    );
  }

  const statusFilter = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={dispatchAssignStatusTypeOptions}
        onChange={(e) => options.filterCallback(e.value)}
        itemTemplate={itemTemplate}
        valueTemplate={valueTemplate}
        placeholder="Status"
      />
    );
  }

  /**
   * Some information pertaining to the columns of the table and where they come from for reference:
   *    Status: determined locally
   *    Order No: corresponds to the batchId in DispatchJob - DONE
   *    Date Due: corresponds to the last dropoffDateTime from the list of DispatchTransfers - DONE
   *    Origin: corresponds to the pickup address of the earliest DispatchTransfer
   *    Destination: corresponds to the dropoff address of the latest DispatchTransfer
   *    Drivers: corresponds to the DispatchDrivers for all the DispatchTransfers belonging to a DispatchJob
   *    Vehicles: corresponds to the DispatchVehicles for all the DispatchTransfers belonging to a DispatchJob
   *    Trailers: corresponds to the DispatchTrailers for all the DispatchTransfers belonging to a DispatchJob
   */
  return (
    <div className="dispatching-table card">
      <DataTable
        value={dispatchingTableContents}
        header={() => renderHeader()}
        paginator
        rows={fetchAttributes.rows}
        totalRecords={totalDispatchJobsCount}
        lazy
        first={fetchAttributes.first}
        onPage={(e) => { setIsLoading(true); setFetchAttributes({ ...fetchAttributes, page: e.first / e.rows, first: e.first }); }}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        dataKey="id"
        rowHover
        onRowClick={(event) => props.openDispatchingDrawer(event.data)}
        responsiveLayout="scroll"
        emptyMessage="No jobs found."
        loading={isLoading}
        filters={fetchAttributes.filters}
        globalFilterFields={['status']}
        onFilter={onFilter}
        rowClassName={() => 'dispatching-table-row'}
      >
        <Column
          field="status"
          header="Status"
          style={{ minWidth: "10rem" }}
          filterMenuStyle={{ width: '18rem' }}
          body={statusBodyTemplate}
          filter
          showClearButton
          showApplyButton
          showFilterMenu
          filterElement={statusFilter}
        />
        <Column
          field="orderNo"
          header="Order #"
          style={{ minWidth: "8rem" }}
          body={orderNumberBodyTemplate}
        />
        <Column
          field="date"
          header="Date Start"
          style={{ minWidth: '8rem' }}
          body={dateStartBodyTemplate}
        />
        <Column
          field="date"
          header="Date Due"
          style={{ minWidth: "8rem" }}
          body={dateDueBodyTemplate}
        />
        <Column
          field="origin"
          header="Origin"
          style={{ minWidth: "10rem" }}
          body={originBodyTemplate}
        />
        <Column
          field="destination"
          header="Destination"
          style={{ minWidth: "10rem" }}
          body={destinationBodyTemplate}
        />
        <Column
          field="drivers"
          header="Driver(s) / Carrier"
          style={{ minWidth: "10rem" }}
          body={driversBodyTemplate}
        />
        <Column
          field="vehicles"
          header="Vehicle(s)"
          style={{ minWidth: "8rem" }}
          body={vehiclesBodyTemplate}
        />
        <Column
          field="trailers"
          header="Trailer(s)"
          style={{ minWidth: "8rem" }}
          body={trailersBodyTemplate}
        />
      </DataTable>
    </div>
  );
}

export default DispatchingTable;
