import React from 'react';
import PropTypes from 'prop-types';
import { HelpBlock, Modal } from 'react-bootstrap';

// Components
import AddNewJobForm from './AddNewJobForm';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './AddNewJobModal.module.scss';

function AddNewJobModal(props) {
  return (
    <Modal backdrop="static" show={props.show} onHide={() => props.handleClose()}>

      <Modal.Header closeButton>
        <Modal.Title>Add New Job</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <AddNewJobForm {...props} />
        {props.validateError &&
          <HelpBlock>Must enter Job ID to add a job</HelpBlock>
        }
      </Modal.Body>

      <Modal.Footer>
        {!props.addingInProgress ?
          <div>
            <button className="closeButton" onClick={() => props.handleClose()}>Cancel</button>
            <button className="buttonDefault" onClick={() => props.onSubmit(props.job)} disabled={props.addingInProgress}>Add Job</button>
          </div>
          :
          <LoadingIcon />
        }
      </Modal.Footer>

    </Modal>
  );
}

AddNewJobModal.propTypes = {
  addingInProgress: PropTypes.bool.isRequired,
  chooseDriverModalShow: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  openChooseOrAddModal: PropTypes.func.isRequired,
  closeChooseOrAddModal: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  validateError: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired,
  chooseDriversHandler: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddNewJobModal;
