import React from 'react';
import uniqid from 'uniqid';

// Components
import AddEditFuelPurchasesCard from 'components/IFTARoute/view/AddEditFuelPurchasesCard';

// Api
import { createTempRecord, getAttribute } from 'api/Parse';

// Material
import { MDBContainer, MDBIcon, MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardText, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from 'mdbreact';

const getInitialFuelPurchaseHash = (props) => ({
  fuelPurchase: createTempRecord('FuelPurchase', {}),
  purchaseInformation: {
    date: props.initialDate,
    volumeUnits: 'L',
    volumePumped: '0',
    fuelType: '',
    currency: 'cad',
    totalPaid: '0',
    city: '',
    stateProvince: props.initialStateProvince,
    fuelCardFourDigits: '1000',
    allFieldsValid: false,
    errorHash: {
      fuelInformationIsValid: {
        bool: undefined,
        explanation: undefined,
      },
      paymentInformationIsValid: {
        bool: undefined,
        explanation: undefined,
      },
      locationIsValid: {
        bool: props.initialStateProvince !== undefined,
        explanation: undefined,
      },
      dateIsValid: {
        bool: props.initialDate !== undefined,
        explanation: undefined,
      },
    },
  },
});

class AddEditFuelPurchasesModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      show: false,
      disableConfirmButton: true,
      fuelPurchaseHash: {},
      originalFuelPurchaseHash: {}, // Keep a record of the fuelPurchaseHash the client entered the modal with, in case we need to restore it
    };

    this.addNewFuelPurchase = this.addNewFuelPurchase.bind(this);
    this.deleteFuelPurchase = this.deleteFuelPurchase.bind(this);
    this.updatePurchaseInformationForFuelPurchaseHash = this.updatePurchaseInformationForFuelPurchaseHash.bind(this);
    this.confirmAllFuelPurchases = this.confirmAllFuelPurchases.bind(this);
    this.shouldCloseModal = this.shouldCloseModal.bind(this);
    this.initializeFuelPurchaseHash = this.initializeFuelPurchaseHash.bind(this);
  }

  componentDidMount() {
    this.initializeFuelPurchaseHash();
  }

  /**
   * @description Initialize the fuelPurchaseHash by either using the fuelPurchaseHash that is passed in or creating a new one
   */
  initializeFuelPurchaseHash() {
    const newState = { ...this.state };
    newState.fuelPurchaseHash = this.state.fuelPurchaseHash;

    if (Object.keys(this.props.fuelPurchaseHash).length === 0) {
      const uniqId = uniqid();
      newState.fuelPurchaseHash[uniqId] = getInitialFuelPurchaseHash(this.props);

      this.setState(newState, () => {
        this.updatePurchaseInformationForFuelPurchaseHash(uniqId, this.state.fuelPurchaseHash[`${uniqId}`].purchaseInformation);
      });
    } else {
      newState.fuelPurchaseHash = { ...this.props.fuelPurchaseHash }; // Deep copy the fuel purchase hash
      newState.disableConfirmButton = false;
      this.setState(newState);
    }
  }

  /**
   * @description Initialize a new fuelPurchase within the fuelPurchaseHash
   */
  addNewFuelPurchase() {
    const newState = { ...this.state };
    newState.fuelPurchaseHash = { ...this.state.fuelPurchaseHash };
    const uniqId = uniqid();
    const tempFuelPurchaseRecord = createTempRecord('FuelPurchase', {});

    newState.fuelPurchaseHash[uniqId] = getInitialFuelPurchaseHash(this.props);

    this.setState(newState, () => {
      this.updatePurchaseInformationForFuelPurchaseHash(uniqId, this.state.fuelPurchaseHash[`${uniqId}`].purchaseInformation);
    });
  }

  /**
   * @description Delete a fuelPurchaseRecord and its corresponding fuelPurchaseInformation within the fuelPurchaseHash
   * @param key The key which allows us to obtain the fuelPurchaseRecord and fuelPurchaseInformation we want to delete
   */
  deleteFuelPurchase(key) {
    const newState = { ...this.state };
    delete newState.fuelPurchaseHash[key];

    this.setState(newState,
      () => {
        this.updatePurchaseInformationForFuelPurchaseHash();
        // this.props.updateRouteInformationWithFuelPurchases(this.state.fuelPurchaseHash);
        // this.shouldCloseModal(Object.keys(this.state.fuelPurchaseHash));
      }
    );
  }

  /**
   * @description Determine whether or not to close the modal if all fuelPurchases have been deleted
   * @param {Array} keys 
   */
  shouldCloseModal(keys) {
    if (keys.length === 0) {
      this.props.handleClose();
    }
  }

  /**
   * @description Obtain information passed from AddEditFuelPurchasesCard to update the route within the hash
   * @param key The key of the fuelPurchaseRecord we want to update within the fuelPurchaseHash
   * @param {Object} purchaseInformation 
   */
  updatePurchaseInformationForFuelPurchaseHash(key, purchaseInformation) {
    const { fuelPurchaseHash } = this.state;
    const newState = { ...this.state };

    if (key && purchaseInformation) {
      fuelPurchaseHash[`${key}`].purchaseInformation = purchaseInformation;
    }

    let validFuelPurchaseCardKeys = [];
    const allFuelPurchaseCardKeys = Object.keys(fuelPurchaseHash);
    for (const key in fuelPurchaseHash) {
      const currentPurchaseInformation = fuelPurchaseHash[key].purchaseInformation;
      const currentFuelPurchaseFieldsAreValid = currentPurchaseInformation.allFieldsValid;

      if (currentFuelPurchaseFieldsAreValid) {
        validFuelPurchaseCardKeys.push(key);
      }
    }

    if ((validFuelPurchaseCardKeys.length === allFuelPurchaseCardKeys.length)) {
      newState.disableConfirmButton = false;
    } else {
      newState.disableConfirmButton = true;
    }

    newState.fuelPurchaseHash = fuelPurchaseHash;
    this.setState(newState);
  }

  /**
   * @description Send validated fuel purchases to AddEditRoute card
   */
  confirmAllFuelPurchases() {
    const { fuelPurchaseHash, originalFuelPurchaseHash } = this.state;
    const { updateRouteInformationWithFuelPurchases } = this.props;

    updateRouteInformationWithFuelPurchases(fuelPurchaseHash);
    this.props.handleClose();
  }

  render() {
    const { state, props } = this;

    return (
      <div>
        <MDBModal isOpen={props.show} centered>
          <MDBModalHeader>Fuel Purchases</MDBModalHeader>
          <MDBModalBody style={{ 'height': '50vh', 'overflowY': 'auto' }}>

            {state.fuelPurchaseHash && Object.keys(state.fuelPurchaseHash).map((key, index) => {
              return <AddEditFuelPurchasesCard
                key={key}
                keyId={key}
                index={index + 1}
                fuelPurchase={state.fuelPurchaseHash[key]}
                deleteFuelPurchase={(keyForFuelPurchase) => this.deleteFuelPurchase(keyForFuelPurchase)}
                updatePurchaseInformationForFuelPurchaseHash={(purchaseInformation) => this.updatePurchaseInformationForFuelPurchaseHash(key, purchaseInformation)}
                initialDate={props.initialDate}
                initialStateProvince={props.initialStateProvince}
                isEdit={props.isEdit}
              />
            })}

          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn className={'buttonDefault'} disabled={state.disableConfirmButton} onClick={() => this.confirmAllFuelPurchases()}>Confirm Fuel Purchases</MDBBtn>
            <MDBBtn className={'buttonDefault'} onClick={() => this.addNewFuelPurchase()}>Add New Fuel Purchase</MDBBtn>
            <MDBBtn className={'closeButton'} onClick={() => this.props.handleClose()}>Cancel</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </div>
    );
  }
}

export default AddEditFuelPurchasesModal;
