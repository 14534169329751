import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// API
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getVehicles } from 'api/Equipment/Vehicle';
import { getVisionEvents } from 'api/Vision/VisionEvent';

// Enums
import { EquipmentTypes } from 'enums/Equipment';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';

// SBObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';
import Sort from 'sb-csapi/dist/sbObjects/Sort';

// Components
import Column from 'sbCore/Column/Column';
import DataTable from 'sbCore/DataTable/DataTable';
import NoDashcamBtn from 'components/Vision/NoDashcamBtn/NoDashcamBtn';

import './style.scss';


/**
 * @description The sidebar content and logic for Vision module
 * @param {Function} getSelectedEquipment - Callback function when an equipment is selected
 */
function VisionSidebar(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [selectedItem, setSelectedItem] = useState();

  const [totalVehiclesCount, setTotalVehiclesCount] = useState(0);
  const [fetchAttributes, setFetchAttributes] = useState({
    first: 0,
    rows: 50,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      unitId: { value: null, matchMode: 'contains' },
    }
  });

  useEffect(() => {
    let unmounted = false;

    async function refresh() {
      if (!unmounted) {
        const filters = await getFilters();
        _getVehicles(filters);
      }
    }

    refresh();
    return () => { unmounted = true; };
  }, [fetchAttributes]);

  /**
   * @description Aggregates all the filter properties and creates filters for the database fetch
   * @returns {Array} An array of Filter objects
   */
  async function getFilters() {
    const dataTableFilters = fetchAttributes.filters;
    const filters = [];

    Object.entries(dataTableFilters).forEach(([key, filter]) => {
      if (filter.value === null || filter.value === '') return;
      if (key === 'unitId') filters.push(new Filter(QueryRestrictionTypes.CONTAINS, 'unitId', filter.value));
    });

    return filters;
  }

  /**
   * @description Retrieves all items for the sidebar - no pagination yet
   */
  async function _getVehicles(filters = []) {
    setIsLoading(true);

    const { totalVehiclesCount, vehicles } = await getVehicles(
      undefined,  // options
      undefined,  // companyObjectId
      true,       // includeChildCompanies
      [...filters,
      new Filter(QueryRestrictionTypes.EQUAL_TO, 'enabled', true),
      new Filter(QueryRestrictionTypes.EXISTS, 'unitId', undefined),
      ],         // filters
      undefined,  // sortBy
      [],         // includedPointers
      [],         // selectedAttributes
      fetchAttributes.page, // page
      fetchAttributes.rows, // limit
      false,       // queryAll
    );

    if ((!vehicles || vehicles.length === 0) && (filters && filters.length > 0)) {
      // If there are no vehicles and there was a filter applied
      setTotalVehiclesCount(0);
      setTableData([]);
      setIsLoading(false);
      return;
    };

    if (!vehicles || vehicles.length === 0) {
      setIsLoading(false);
      return;
    };

    // We'll set up the DataTable data information
    let _tableData = {};

    vehicles.forEach((vehicle) => {
      const unitId = getAttribute(vehicle, 'unitId');
      const vehicleObjectId = getAttribute(vehicle, 'objectId');

      if (!unitId) return; // Don't show any vehicles with missing unit ids

      const tableDataItem = {
        vehicleObjectId,
        unitId,
        containsVisionEvents: false,
      }

      if (props.unitId === unitId) setSelectedItem(tableDataItem);
      _tableData[vehicleObjectId] = tableDataItem;
    });

    // Get an array of all the vehicles that do not have dashcams (aka. VisionEvents)
    let vehiclesWithVisionEvents = await Promise.all(vehicles.map(async (vehicle) => {
      const unitId = getAttribute(vehicle, 'unitId');
      if (!unitId) return;

      const visionEvents = await getVisionEvents(unitId, undefined, 1, undefined, [new Filter(QueryRestrictionTypes.EXISTS, 'video', undefined)]);
      const vehicleHasVisionEvents = visionEvents.totalVisionEventsCount !== 0;

      if (vehicleHasVisionEvents) return vehicle;
      return;
    }));

    // Filter out undefined values
    vehiclesWithVisionEvents = vehiclesWithVisionEvents.filter((vehicleWithVisionEvents) => vehicleWithVisionEvents)

    // For each of the values, set the containsVisionEvents to true
    vehiclesWithVisionEvents.forEach((vehicle) => {
      const vehicleObjectId = getAttribute(vehicle, 'objectId');
      _tableData[vehicleObjectId].containsVisionEvents = true;
    });

    setTotalVehiclesCount(totalVehiclesCount);
    setTableData(Object.values(_tableData));
    setIsLoading(false);
  }

  function itemBodyTemplate(rowData) {
    const { unitId, containsVisionEvents } = rowData;

    return <div className={`w-full flex align-items-center justify-content-between h-2rem ${!unitId ? "font-italic font-normal" : "font-semibold"}`}>
      <div>
        <i className="material-icons md-36 mr-2">local_shipping</i>
        {unitId || "Missing Unit Id"}
      </div>
      {!containsVisionEvents && <NoDashcamBtn />}
    </div>
  }

  function handleSelectionChange(event) {
    const { value } = event;
    const { unitId } = value;

    setSelectedItem(value);
    props.setSelectedEquipment(EquipmentTypes.VEHICLE, unitId);
  }

  function onFilter(event) {
    event.first = 0;
    setFetchAttributes(event);
  }

  return (
    <div className="vision-sidebar">
      <DataTable
        value={tableData}
        rowClassName="sidebar-datatable-row"
        cellClassName="sidebar-datatable-cell"
        tableClassName="sidebar-datatable"
        selectionMode="single"
        selection={selectedItem}
        onSelectionChange={e => handleSelectionChange(e)}
        filterDisplay="row"
        onFilter={(event) => onFilter(event)}
        filters={fetchAttributes.filters}
        dataKey="vehicleObjectId"
        rowHover
        scrollable
        scrollHeight="flex"
        responsiveLayout="scroll"
        emptyMessage="No vehicles found"
        loading={isLoading}
        sortField="containsVisionEvents"
        sortOrder={-1}
        paginator
        rows={fetchAttributes.rows}
        totalRecords={totalVehiclesCount}
        lazy
        first={fetchAttributes.first}
        onPage={(e) => { setIsLoading(true); setFetchAttributes({ ...fetchAttributes, page: e.first / e.rows, first: e.first }); }}
        paginatorTemplate="FirstPageLink PrevPageLink NextPageLink LastPageLink"
        paginatorClassName="sidebar-datatable-paginator"
      >
        <Column
          field="unitId"
          header="Switchboard Vision"
          style={{ minWidth: "10rem" }}
          body={itemBodyTemplate}
          filter
          filterPlaceholder="Search by Unit ID"
          headerClassName="sidebar-datatable-header text-xl pb-0 px-3 font-medium"
          filterHeaderClassName="sidebar-datatable-filter pt-3 px-3"
        />
      </DataTable>
    </div>
  );
}

VisionSidebar.propTypes = {
  getSelectedEquipment: PropTypes.func,
};

export default VisionSidebar;
