import { t } from 'api/Translate';
// WARNING: ALL CHANGES IN THIS FILE MUST BE REFLECTED IN ITS EQUIVALENT PDF-CLOUD CODE
// THIS MEANS ALL DATA FORMATTING (quantitative such as rounding coords; not stylistic) IS THE SAME

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from 'sbHistory';
import { connect } from 'react-redux';

// Actions
import { fetchELDEventsForState, deleteELDEventsForState } from 'actions/ELDEvent';

// API
import * as ELD from 'api/ELD';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import * as Analytics from 'api/Analytics';
import { getAttribute, getCurrentUser } from 'api/Parse';
import { addReplaceQueryParameter, getQueryParameter } from 'api/URL';
import { getOdometerReadings } from 'api/Odometer/Odometer';
import { isAutoGeneratedDrivingTime } from 'sb-csapi/dist/api/ELDEvent/ELDEvent';
import { getCurrentUserSessionToken } from 'sb-csapi/dist/AAPI';
import { getUserSubscriptionPermission } from 'sb-csapi/dist/api/Subscription/Subscription';
import { ELDEventRecordStatus } from 'sb-csapi/dist/enums/ELD/ELDEventRecordStatus';

// Components
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';
import BundleLogs from 'components/BundleLogs/container/BundleLogs';
import ErrorList from 'components/ErrorList/view/ErrorList';
import EventsList from './EventsList';
import ELDEventsHeader from '../view/ELDEventsHeader';
import OdometerReadings from '../view/OdometerReadings';
import DrivenByDriverTable from '../view/DrivenByDriverTable';
import HOSViolationsTable from '../view/HOSViolationsTable';
import CoDriversTable from '../view/CoDriversTable';
import TripInspections from '../view/TripInspections';
import DriverELDEditsTable from '../view/DriverELDEditsTable';
import LogDownloadButton from 'components/LogDownload/LogDownloadButton/LogDownloadButton';
import LogDownloadModal from 'components/LogDownload/LogDownloadModal/LogDownloadModal';

import HOSGraph from './HOSGraph';

import SBCardEmptyContent from 'components/Shared/SBCard/SBCardEmptyContent';
import SBCheckbox from 'components/Shared/SBCheckbox/SBCheckbox';
import TimezoneDropdown from 'sbCore/TimezoneDropdown/TimezoneDropdown';

import { MDBIcon } from 'mdbreact';

// Enums
import { ExcludedRegulations } from 'sb-csapi/dist/enums/HOS/Regulation';

// CSS
import styles from './ELDEvents.module.scss';
import './style.scss';
import { ELDEditType } from 'enums/ELDEditType';

// Context
import StoreContext from 'contexts/StoreContext';

class ELDEvents extends React.Component {

  constructor(props) {
    super(props);
    const tempDate = getQueryParameter(props.location.search, 'date');
    this.state = {
      isLoading: false,
      userSubscriptionPermission: undefined,
      timezoneOffsetFromUTC: undefined,
      _eldEvents: [], // contains the ELD events from props PLUS any filtering applied
      actionType: 'download',
      associatedELDEvents: [],
      coDriverELDDailyCertifications: [],
      tripInspections: [],
      filter: {
        onDate: tempDate ? moment(tempDate, 'DDMMYY') : moment(new Date()),
      },
      eventTypeIndex: 0, // determines which types of events to show based on the dropdown filter fields
      showBundleModal: false,
      showViolations: true,
      hosViolationArr: [],
      showGraphViolations: false,
      dialogDriverEdit: {
        eldEdit: undefined,
        open: false,
        acceptEdit: false,
        dialogTitle: '',
        dialogBody: '',
      },
      dialogOdometerEdit: {
        open: false,
        vehicle: undefined,
        odometerStart: 0,
        odometerEnd: 0,
        isLoading: false,
        distanceUnit: 'km',
        errors: [],
      },
      vehicles: [],
    };
    this.state.eldDailyCertification = Helpers.createTempParseObject('ELDDailyCertification', {
      driver: this.props.driver,
      startTimeUTC: new Date(this.state.filter.onDate),
    });
    this.fetchELDEvents = this.fetchELDEvents.bind(this);
    this.clearELDEventsData = this.clearELDEventsData.bind(this);
    this.refreshELDData = this.refreshELDData.bind(this);
    this._getUserSubscriptionPermission = this._getUserSubscriptionPermission.bind(this);
    this.getTripInspections = this.getTripInspections.bind(this);
    this.filterByDate = this.filterByDate.bind(this);
    this.onSelectTimezoneOffsetFromUTC = this.onSelectTimezoneOffsetFromUTC.bind(this);
    this.filterByType = this.filterByType.bind(this);
    this.openPrintDialog = this.openPrintDialog.bind(this);
    this.handleDriverEditDialogToggle = this.handleDriverEditDialogToggle.bind(this);
    this.toggleEditOdometerReading = this.toggleEditOdometerReading.bind(this);
    this.editOdometerReading = this.editOdometerReading.bind(this);
    this.handleOdometerChange = this.handleOdometerChange.bind(this);
    this.getOdometerReadings = this.getOdometerReadings.bind(this);
  }

  componentDidMount() {
    this.refreshELDData();
  }

  componentWillReceiveProps(nextProps) {
    const { ELDEvent, location } = this.props;
    const { eventTypeIndex } = this.state;

    const currentDate = getQueryParameter(location.search, 'date');
    const nextDate = getQueryParameter(nextProps.location.search, 'date');
    const isDateChanged = nextDate !== currentDate;
    const isELDEventUpdated = nextProps.ELDEvent.fetched !== ELDEvent.fetched;

    // if a timezone was already selected by the user, stick with it by default. otherwise, guesswork using the driver's timezone
    let timezoneOffsetFromUTC = this.state.timezoneOffsetFromUTC;
    if (!timezoneOffsetFromUTC) {
      timezoneOffsetFromUTC = (this.props.driver && this.props.driver.get('timezoneOffsetFromUTC')) ? this.props.driver.get('timezoneOffsetFromUTC') : moment.tz.guess();
    }

    if (isDateChanged) {
      return this.setState({
        ...this.state,
        filter: { onDate: nextDate ? moment(nextDate, 'DDMMYY') : moment() },
        timezoneOffsetFromUTC: (this.props.driver && this.props.driver.get('timezoneOffsetFromUTC')) ? this.props.driver.get('timezoneOffsetFromUTC') : moment.tz.guess(), // reset to driver timezone, clear existing timezoneOffsetFromUTC
        _eldEvents: [],
        associatedELDEvents: [],
        coDriverELDDailyCertifications: [],
        tripInspections: [],
        vehicles: [],
        unifiedOdometerReadings: {},
        unifiedOdometerReadingsWarnings: {},
      }, () => {
        this.refreshELDData();
      });
    } else if (isELDEventUpdated) {
      // where a lot of the magic happens. this runs every time we retrieve new eld info
      // props properties that are going to be saved to state with the same name, begin with _
      let { eldEvents, eldDailyCertification } = nextProps.ELDEvent; // dont mess with what will be saved to state
      eldEvents = ELD.sortELDEvents([].concat(eldEvents), 1);
      const vehicles = ELD.getVehiclesFromEvents(eldEvents);
      if (eventTypeIndex === 1) { // if the user wants to only show Origin events
        eldEvents = eldEvents.filter(event => event.get('eldEventRecordOriginInt') === 1);
      }
      this.setState({
        ...this.state,
        timezoneOffsetFromUTC,
        _eldEvents: eldEvents,
        _eldDailyCertification: eldDailyCertification,
        vehicles,
      }, () => {
        this.getExtraELDInfo();
        this.getOdometerReadings();
        if (eldEvents.length > 0) {
          Analytics.identifyOnceForCompany('VIEWLOGS', {
            'Logs Viewed': true,
            'LogsViewDate': moment().toISOString(),
          });
        }
      });
    }
  }

  async getOdometerReadings() {
    const { state } = this;
    const eldEventObjectIdArr = state._eldEvents.map((eldEvent) => getAttribute(eldEvent, 'objectId'));
    const { odometerReadings, warnings } = await getOdometerReadings(eldEventObjectIdArr)
    this.setState({ ...this.state, unifiedOdometerReadings: odometerReadings, unifiedOdometerReadingsWarnings: warnings });
  }

  getExtraELDInfo() {
    // function to get extra log information from other queries (inspections, codrivers, etc) separate from the info needed to generate hos logs
    // (thus allowing the daily logs essential info to load first, then secondary info after)

    const { _eldEvents, _eldDailyCertification, eventTypeIndex } = this.state;

    const extraELDInfoPromises = [];
    if (_eldEvents.length > 0 && _eldEvents[0].get('eldDailyCertification')) { // use authentic daily cert of event
      const refELDDailyCertification = _eldEvents[0].get('eldDailyCertification');
      extraELDInfoPromises.push(ELD.findCoDriverInfoFromEvents(_eldEvents, refELDDailyCertification.get('startTimeUTC'), this.props.driver));
      extraELDInfoPromises.push(this.getTripInspections(refELDDailyCertification));
    }

    if (extraELDInfoPromises.length > 0) {
      Promise.all(extraELDInfoPromises).then(extraELDInfo => {
        const coDriverELDDailyCertifications = extraELDInfo[0];
        const tripInspections = extraELDInfo[1];
        this.setState({
          ...this.state,
          coDriverELDDailyCertifications,
          tripInspections,
        });
      });
    }
  }

  componentWillUnmount() {
    this.clearELDEventsData();
  }

  fetchELDEvents() {
    const { driver } = this.props;
    const filter = { ...this.state.filter };

    fetchELDEventsForState(driver, filter, this.state.timezoneOffsetFromUTC);
  }

  clearELDEventsData() {
    return deleteELDEventsForState();
  }

  refreshELDData() {
    this.setState({ ...this.state, hosViolationArr: [] }, () => {
      this.clearELDEventsData().then(() => {
        const dateString = getQueryParameter(this.props.location.search, 'date');
        const date = dateString ? moment(dateString, 'DDMMYY') : moment();
        const adjustedDate = moment.tz(date, this.state.timezoneOffsetFromUTC);
        this._getUserSubscriptionPermission();
        this.fetchELDEvents();
        Getters.getHOSViolations(this.props.driver, adjustedDate.startOf('day').toDate(), adjustedDate.endOf('day').toDate()).then((hosViolationArr) => {
          this.setState({ ...this.state, hosViolationArr });
        });
      });
    });
  }

  async _getUserSubscriptionPermission() {
    const userSubscriptionPermission = await getUserSubscriptionPermission(
      { sessionToken: getCurrentUserSessionToken() },
      getAttribute(getCurrentUser(), 'objectId'),
    );

    this.setState({ ...this.state, userSubscriptionPermission });
  }

  getTripInspections(eldDailyCertificationParseObj) {
    const promise = new Promise(resolve => {
      Getters.queryCompanyObjects('TripInspection', undefined, undefined, [{ queryType: 'equalTo', name: 'eldDailyCertification', value: eldDailyCertificationParseObj }, { name: 'disabled', queryType: 'notEqualTo', value: true }], undefined, ['tripDefects', 'seals', 'trailerFirst', 'trailerSecond', 'trailerFirst.licensePlate', 'trailerSecond.licensePlate'], false, true).then((tripInspectionParseArr) => {
        resolve(tripInspectionParseArr);
      });
    });
    return promise;
  }

  filterByDate(dateFilterObj, clear) {
    if (clear) {
      return history.push({
        pathname: this.props.location.pathname, search: this.props.location.search.length > 0 ? addReplaceQueryParameter(this.props.location.search, 'date', moment().format('DDMMYY')) : `date=${moment().format('DDMMYY')}`,
      });
    }
    const date = dateFilterObj.value ? dateFilterObj.value : moment();
    history.push({ pathname: this.props.location.pathname, search: this.props.location.search.length > 0 ? addReplaceQueryParameter(this.props.location.search, 'date', date.format('DDMMYY')) : `date=${date.format('DDMMYY')}` });
  }

  onSelectTimezoneOffsetFromUTC(timezoneOffsetFromUTC) {
    this.setState({ timezoneOffsetFromUTC }, () => {
      this.refreshELDData();
    });
  }

  filterByType(eventTypeIndex) {
    const index = parseFloat(eventTypeIndex);
    this.setState({ ...this.state, eventTypeIndex: index }, () => {
      this.refreshELDData();
    });
  }

  openPrintDialog(actionType) {
    this.setState({ ...this.state, actionType, showBundleModal: true });
  }

  handleDriverEditDialogToggle(eldEdit, acceptEdit, submit) {
    // dialogDriverEdit: {
    //   eldEdit: undefined,
    //   open: false,
    //   acceptEdit: false,
    //   dialogTitle: '',
    //   dialogBody: '',
    // }
    const newState = { ...this.state };
    const acceptEditReasonDOM = document.getElementById('driverAcceptEditReason');
    if (acceptEdit && submit) {
      const disableAcceptEdit = !acceptEditReasonDOM || !acceptEditReasonDOM.value || !acceptEditReasonDOM.value.trim();
      if (disableAcceptEdit) return;
    }
    newState.dialogDriverEdit.open = !this.state.dialogDriverEdit.open;

    if (!newState.dialogDriverEdit.open) {
      // if dialog is a no show or closed, reset dialogDriverEdit state
      newState.dialogDriverEdit.eldEdit = undefined;
      newState.dialogDriverEdit.acceptEdit = false;
      newState.dialogDriverEdit.dialogTitle = '';
      newState.dialogDriverEdit.dialogBody = '';
    } else {
      newState.dialogDriverEdit.eldEdit = eldEdit;
      newState.dialogDriverEdit.acceptEdit = acceptEdit;
      if (acceptEdit) {
        newState.dialogDriverEdit.dialogTitle = 'Accept Edit';
        newState.dialogDriverEdit.dialogBody = 'Confirming will Accept this Edit. Are you sure?';
      } else {
        newState.dialogDriverEdit.dialogTitle = 'Reject Edit';
        newState.dialogDriverEdit.dialogBody = 'Confirming will Reject this Edit. Are you sure?';
      }
    }

    if (submit) {
      return this.setState(newState, () => {
        ELD.handleDriverEdit(eldEdit, acceptEdit, acceptEditReasonDOM && acceptEditReasonDOM.value && acceptEditReasonDOM.value.trim()).then(
          () => this.refreshELDData()
        );
      });
    }

    this.setState(newState);
  }

  toggleEditOdometerReading(vehicle, odometerStart = 0, odometerEnd = 0, refreshELDData) {
    const promise = new Promise(resolve => {
      const dialogOdometerEdit = { ...this.state.dialogOdometerEdit };
      const open = !dialogOdometerEdit.open;

      const newState = {
        ...this.state,
        dialogOdometerEdit: {
          open,
          vehicle: open ? vehicle : undefined,

          odometerStart: open ? odometerStart : 0,
          odometerEnd: open ? odometerEnd : 0,
          distanceUnit: open ? Getters.getPDispatcherPropertyFromState('distanceUnit') || 'km' : 'km',
          isLoading: false,
          errors: [],
        },
      };

      this.setState(newState, () => {
        if (refreshELDData) {
          this.refreshELDData();
        } else {
          resolve(true);
        }
      });
    });
    return promise;
  }

  editOdometerReading() {
    const { driver, ELDEvent } = this.props;
    const { _eldDailyCertification, _eldEvents } = this.state;
    const newState = { ...this.state };
    newState.dialogOdometerEdit = { ...newState.dialogOdometerEdit, errors: [] };
    // newState.dialogOdometerEdit.isLoading = true;

    this.setState(newState, () => {
      const { dialogOdometerEdit } = newState;
      const vehicle = dialogOdometerEdit.vehicle;
      let odometerStart = dialogOdometerEdit.odometerStart;
      let odometerEnd = dialogOdometerEdit.odometerEnd;
      const distanceUnit = dialogOdometerEdit.distanceUnit;


      const propertyChangesByEldEventId = {};

      if (distanceUnit === 'mi') {
        odometerStart = Helpers.convertDistance(odometerStart, 'mi', 'km');
        odometerEnd = Helpers.convertDistance(odometerEnd, 'mi', 'km');
      }
      const eldEventsToModify = ELD.getOdometerEldEventsToModify(_eldEvents, vehicle.get('unitId'), odometerStart, odometerEnd);

      if (!eldEventsToModify.startEldEvent || !eldEventsToModify.endEldEvent) {
        const dialogOdometerEdit = { ...this.state.dialogOdometerEdit, isLoading: false, errors: [t('Unable to edit odometer reading. Could not find matching event to modify reading')] };
        return this.setState({ ...this.state, dialogOdometerEdit });
      }

      propertyChangesByEldEventId[eldEventsToModify.startEldEvent.id] = {
        totalVehicleKm: odometerStart,
        eventsNote: 'Changed odometer start',
      };
      propertyChangesByEldEventId[eldEventsToModify.endEldEvent.id] = {
        totalVehicleKm: odometerEnd,
        eventsNote: 'Changed odometer end',
      };

      this.setState({ ...this.state, isLoading: true }, () => {
        ELD.requestSingleELDEventEdits(driver, ELDEditType.MAJOR, propertyChangesByEldEventId).then(
          eldEdit => {
            this.toggleEditOdometerReading(undefined, undefined, undefined, true);
          }
        );
      });
    });
  }

  handleOdometerChange(attribute, value) {
    const newState = { ...this.state };
    let _value = value;
    if (['odometerStart', 'odometerEnd'].indexOf(attribute) !== -1) {
      _value = parseFloat(_value || 0);
    }
    newState.dialogOdometerEdit = { ...newState.dialogOdometerEdit };
    newState.dialogOdometerEdit[attribute] = _value;
    this.setState(newState);
  }

  render() {
    const { Company, User, ELDEvent, driver, driverFetched, location } = this.props;
    const { eldEvents, eldDailyCertification, eldEdits } = ELDEvent;

    const {
      filter, eventTypeIndex, showBundleModal, associatedELDEvents, coDriverELDDailyCertifications, tripInspections,
      _eldDailyCertification, _eldEvents, dialogDriverEdit, dialogOdometerEdit, showGraphViolations, userSubscriptionPermission
    } = this.state;

    const callingUser = User && User.user;
    const userSpecialPermission = callingUser && callingUser.get('userSpecialPermission');

    const debug = getQueryParameter(location.search, 'debug');
    const disableViolations = userSpecialPermission && userSpecialPermission.get('disableViolations');
    const disableELDEditHistory = userSpecialPermission && userSpecialPermission.get('disableEldShowEditHistory');

    // filter _eldEvents even more to 'simulate' aobrd edits as if they were auto-applied/accepted
    let eldEventsForSimulation = [].concat(_eldEvents);
    for (let i = 0; i < eldEdits.length; i++) {
      const eldEdit = eldEdits[i];
      if (([0, 5].indexOf(eldEdit.get('completed')) !== -1) && eldEdit.aobrdEnabled) {
        // aobrd edit, so auto apply it to the graph
        const eldEventsToBeInactiveIds = eldEdit.get('eldEventsToBeInactive').map(event => event.id);

        // filter out events to be removed
        eldEventsForSimulation = eldEventsForSimulation.filter(event => eldEventsToBeInactiveIds.indexOf(event.id) === -1);

        const requestedELDEvents = eldEdit.get('requestedELDEvents').map(event => {
          const requestedELDEvent = event.clone();
          requestedELDEvent.set('eldEventRecordStatusInt', 1);
          requestedELDEvent.id = requestedELDEvent._localId;
          return requestedELDEvent;
        });

        eldEventsForSimulation = eldEventsForSimulation.concat(requestedELDEvents);
      }
    }

    ELD.sortELDEvents(eldEventsForSimulation, 1); // ascending order

    // remove all interm events that appear after duty statuses they should not be appearing after
    const mainELDEventDutyStatusTypeCodes = [11, 12, 13, 14, 31, 32];
    const ignoreIfELDEventDutyStatusTypeCodes = [11, 12, 14]; // if interm events come after one of these statuses, ignore them
    const intermELDEventTypeCodes = [21, 22];
    let lastSeenELDEventTypeCode;
    eldEventsForSimulation = eldEventsForSimulation.filter(eldEvent => {
      // console.log(eldEvent.get('totalVehicleKm') * 0.6213712);
      const eldEventTypeCodeInt = eldEvent.get('eldEventTypeCodeInt');
      const isMainELDEventTypeCode = mainELDEventDutyStatusTypeCodes.indexOf(eldEventTypeCodeInt) !== -1;
      const isIntermEvent = intermELDEventTypeCodes.indexOf(eldEventTypeCodeInt) !== -1;

      // if the lastSeenELDEventTypeCode is a duty status where we should ignore interm events
      const isLastSeenELDEventTypeCodeShouldIgnore = (lastSeenELDEventTypeCode !== undefined) && (ignoreIfELDEventDutyStatusTypeCodes.indexOf(lastSeenELDEventTypeCode) !== -1);

      if ([0, 1].indexOf(eldEvent.get('eldEventRecordStatusInt')) !== -1) { // only concerned with active events here
        if (isMainELDEventTypeCode) {
          lastSeenELDEventTypeCode = eldEventTypeCodeInt;
        } else if (isIntermEvent && isLastSeenELDEventTypeCodeShouldIgnore) {
          return false;
        }
      }

      return true;
    });

    // lastly for eldEventsForSimulation, filter out Inactive Autogenerated Driving events
    eldEventsForSimulation = eldEventsForSimulation.filter(eldEvent => {
      const isInactiveAutogeneratedDrivingTimeELDEvent = isAutoGeneratedDrivingTime(eldEvent, ELDEventRecordStatus.INACTIVE_CHANGED);
      return !isInactiveAutogeneratedDrivingTimeELDEvent;
    });

    const hosViolations = (
      eldDailyCertification &&
      eldDailyCertification.get('hosViolations') &&
      eldDailyCertification.get('hosViolations').filter((hosViolation) =>
        !hosViolation.get('isHidden') && !
        hosViolation.get('potential') &&
        !hosViolation.get('disabled') &&
        !hosViolation.get('hideForever') &&
        !(ExcludedRegulations && ExcludedRegulations.includes(hosViolation.get('regulationInt')))
      )
    );


    // double-ensure they are subscribed
    const eldModuleEnabled = driverFetched && Helpers.isSubscribedToModule('eldModule');

    const dialogDriverEditActions = [
      <FlatButton
        label="Cancel"
        primary
        onClick={() => this.handleDriverEditDialogToggle(undefined, false)}
      />,
      <FlatButton
        label="Confirm"
        primary
        onClick={() => this.handleDriverEditDialogToggle(dialogDriverEdit.eldEdit, dialogDriverEdit.acceptEdit, true)}
      />,
    ];

    let dialogOdometerEditActions = [
      <FlatButton
        label="Cancel"
        primary
        onClick={() => this.toggleEditOdometerReading()}
      />,
      <FlatButton
        label="Confirm"
        primary
        onClick={() => this.editOdometerReading()}
        disabled={(dialogOdometerEdit.odometerEnd < dialogOdometerEdit.odometerStart) || (dialogOdometerEdit.odometerStart < 0) || (dialogOdometerEdit.odometerEnd < 0)}
      />,
    ];

    if (dialogOdometerEdit.isLoading) {
      dialogOdometerEditActions = [<CircularProgress />];
    }

    // if the timezone on this daily cert is different than the driver's current timezone
    const isCertTimezoneDifferentFromDriverTimezone = (driver.get('timezoneOffsetFromUTC') && _eldDailyCertification && _eldDailyCertification.get('timezoneOffsetFromUTC')) && (driver.get('timezoneOffsetFromUTC') !== _eldDailyCertification.get('timezoneOffsetFromUTC'));

    // if the driver's company and the current user's company are not the same
    const currentUser = getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
    const driverBelongsToCompany = getAttribute(driver, 'belongsToCompany');
    const isCurrentUserDifferentCompany = getAttribute(belongsToCompany, 'objectId') !== getAttribute(driverBelongsToCompany, 'objectId');

    window.Localize.translatePage();
    return (
      <div className="driver-eld-events-container">
        {eldModuleEnabled && (
          <span>
            {showBundleModal && (
              <LogDownloadModal
                showModal={showBundleModal}
                actionType={this.state.actionType}
                selectedDriver={driver}
                selectedDate={filter.onDate ? new Date(filter.onDate) : new Date()}
                handleClose={() => this.setState({ ...this.state, showBundleModal: false })}
                belongsToCompany={Company && Company.company}
              />
            )}
            <ELDEventsHeader
              userSubscriptionPermission={userSubscriptionPermission}
              ELDEvent={ELDEvent}
              eldDailyCertification={_eldDailyCertification}
              onDate={filter.onDate ? new Date(filter.onDate) : new Date()}
              filterByDate={this.filterByDate}
              openPrintDialog={this.openPrintDialog}
              updateHandler={this.refreshELDData}
              styles={styles}
              disableOptions={!ELDEvent.fetched}
            />
            {!ELDEvent.fetched &&
              <SBCardEmptyContent isContentLoader>{t('Refreshing Data')}</SBCardEmptyContent>
            }
            {/*  --- This is where the HOS Graph goes --- */}
            <div style={{ textAlign: 'center' }}>
              <div className={`${styles.legend} translate-me`}>
                <span className={styles.legendItem}><span className={styles.pucmv}>&mdash;</span>Personal Use CMV</span>
                <span className={styles.legendItem}><span className={styles.ym}>&mdash;</span>Yard Moves</span>
                <span className={styles.legendItem}><span className={styles.agdt}>&mdash;</span>AutoGenerated Driving Time</span>
                {!disableViolations &&
                  <span className={styles.legendItem}><span className={styles.violation}><SBCheckbox className="d-inline-block" checked={showGraphViolations} onChange={() => this.setState({ ...this.state, showGraphViolations: !this.state.showGraphViolations })} /> <MDBIcon icon="circle" /></span>Show Violation(s)</span>
                }

                <span className={styles.legendItem}>
                  [&nbsp;All Times Displayed as&nbsp;
                  <TimezoneDropdown
                    className="inline-block p-inputtext-sm"
                    timezone={this.state.timezoneOffsetFromUTC}
                    onSelect={(timezoneOffsetFromUTC) => this.onSelectTimezoneOffsetFromUTC(timezoneOffsetFromUTC)}
                    hideLabel
                  />
                  &nbsp;]
                </span>

                {isCertTimezoneDifferentFromDriverTimezone &&
                  <div className={styles.hosTimezoneWarning}>
                    <div>* Driver appears to have changed their time zone setting since this date *</div>
                    <div>Time zone logged this date: <var>{_eldDailyCertification.get('timezoneOffsetFromUTC')}&nbsp;&nbsp;<MDBIcon icon="long-arrow-alt-right" /></var>&nbsp;&nbsp;Time zone currently using: <var>{driver.get('timezoneOffsetFromUTC')}</var></div>
                  </div>
                }

                {isCurrentUserDifferentCompany &&
                  <div className={styles.childCompanyWarning}>
                    <div>* This driver is currently managed by <var>{getAttribute(driverBelongsToCompany, 'name')}</var> *</div>
                    <div>You're logged in under <var>{getAttribute(belongsToCompany, 'name')}</var>. To make edits to this log, please log into an account under the driver's company</div>
                  </div>
                }
              </div>

              {(ELDEvent.fetched && _eldDailyCertification) ?
                <HOSGraph
                  disableRedraw={this.state.showBundleModal}
                  eldDailyCertification={_eldDailyCertification}
                  eldEvents={[].concat(eldEventsForSimulation)}
                  associatedELDEvents={[].concat(associatedELDEvents)}
                  hosViolations={(hosViolations && hosViolations.filter((hosViolation) => (!hosViolation.get('isHidden') && !hosViolation.get('hideForever')))) || []}
                  driver={driver}
                  scaleToDriverTimezone
                  displayHours
                  outlineAutoGeneratedDrivingTimes
                  showHOSViolations={!disableViolations && showGraphViolations}
                  timezoneOffsetFromUTC={this.state.timezoneOffsetFromUTC}
                />
                :
                <CircularProgress />
              }

            </div>

            {/* ELD Version */}
            <div>
              <label className="label-right translate-me">{"ELD Version: " + (getAttribute(eldDailyCertification, 'driverSoftwareVersion', true) ? getAttribute(eldDailyCertification, 'driverSoftwareVersion', true) : 'None')}</label>
            </div>

            {/*  --- This is where the Edits/Odometer Readings/Co Driver/TripInspection/Events tables go --- */}
            {ELDEvent.eldEdits.length > 0 &&
              <DriverELDEditsTable
                ELDEvent={ELDEvent}
                eldEvents={[].concat(_eldEvents)}
                associatedELDEvents={associatedELDEvents}
                eldDailyCertification={_eldDailyCertification}
                eventTypeIndex={eventTypeIndex}
                onDate={new Date(filter.onDate) || new Date()}
                handleDriverEdit={this.handleDriverEditDialogToggle}
                scaleToDriverTimezone
                styles={styles}
              />
            }

            {/* <OdometerReadings
              eldEvents={[].concat(_eldEvents)}
              company={Company && Company.company}
              toggleEditOdometerReading={this.toggleEditOdometerReading}
              disableELDEdit={(ELDEvent && ELDEvent.eldEdits.length > 0)}
              unifiedOdometerReadings={this.state.unifiedOdometerReadings}
              unifiedOdometerReadingsWarnings={this.state.unifiedOdometerReadingsWarnings}
            /> */}

            {_eldEvents.length > 0 &&
              <DrivenByDriverTable
                eldDailyCertification={_eldDailyCertification}
                eldEvents={[].concat(_eldEvents)}
                unifiedOdometerReadings={this.state.unifiedOdometerReadings}
                unifiedOdometerReadingsWarnings={this.state.unifiedOdometerReadingsWarnings}
                location={this.props.location}
                timezoneOffsetFromUTC={this.state.timezoneOffsetFromUTC}
              />
            }


            {!disableViolations &&
              <HOSViolationsTable
                show={this.state.showViolations}
                toggleShowViolations={() => this.setState({ ...this.state, showViolations: !this.state.showViolations })}
                hosViolations={hosViolations}
                hideHosViolation={(hosViolation) => {
                  hosViolation.set('isHidden', true).save().then(() => {
                    this.setState(this.state);
                  });
                }}
                date={new Date(filter.onDate)}
                driver={driver}
                scaleToDriverTimezone
                timezoneOffsetFromUTC={this.state.timezoneOffsetFromUTC}
              />
            }

            <CoDriversTable
              driver={driver}
              onDate={new Date(filter.onDate)}
              updateHandler={this.refreshELDData}
              eldEvents={eldEvents}
              coDriverELDDailyCertifications={coDriverELDDailyCertifications}
              ELDEvent={ELDEvent}
              eventTypeIndex={eventTypeIndex}
              timezoneOffsetFromUTC={this.state.timezoneOffsetFromUTC}
            />

            {tripInspections &&
              <div>
                <hr />
                <TripInspections
                  tripInspections={tripInspections}
                  eldDailyCertification={_eldDailyCertification}
                  location={this.props.location}
                  vehicles={this.state.vehicles}
                  driver={driver}
                  company={Company && Company.company}
                  scaleToDriverTimezone
                  timezoneOffsetFromUTC={this.state.timezoneOffsetFromUTC}
                />
                <hr />
              </div>
            }

            <EventsList
              userSubscriptionPermission={userSubscriptionPermission}
              driver={driver}
              debug={!!debug}
              ELDEvent={ELDEvent}
              Company={Company}
              eldDailyCertification={_eldDailyCertification}
              eldEvents={[].concat(eldEventsForSimulation)}
              updateHandler={this.refreshELDData}
              scaleToDriverTimezone
              disableELDEditHistory={disableELDEditHistory}
              styles={styles}
              onDate={filter.onDate ? new Date(filter.onDate) : new Date()}
              unifiedOdometerReadings={this.state.unifiedOdometerReadings}
              unifiedOdometerReadingsWarnings={this.state.unifiedOdometerReadingsWarnings}
              timezoneOffsetFromUTC={this.state.timezoneOffsetFromUTC}
            />

            {/* {showBundleModal &&
              <BundleLogs
                driver={driver}
                showModal={showBundleModal}
                handleClose={() => this.setState({ ...this.state, showBundleModal: false })}
                miscInfo={{ modalOpenDate: new Date(filter.onDate) }}
                hideButton
              />
            } */}

            {dialogDriverEdit.open && (
              <Dialog
                title={dialogDriverEdit.dialogTitle}
                actions={dialogDriverEditActions}
                contentStyle={{ width: '50%', maxWidth: 'none' }}
                open={dialogDriverEdit.open}
              >

                {dialogDriverEdit.dialogBody}

                {dialogDriverEdit.acceptEdit && (
                  <div style={{ marginTop: '2em' }}>
                    <div>{t('A reason must be provided to accept:')}</div>
                    <div>
                      <TextField
                        id="driverAcceptEditReason"
                        hintText={t('Enter reason for accepting')}
                      />
                    </div>
                  </div>
                )}

              </Dialog>
            )}

            {dialogOdometerEdit.open && (
              <Dialog
                title={t(`Edit odometer for vehicle ${(dialogOdometerEdit.vehicle && dialogOdometerEdit.vehicle.get('unitId')) || '(No Unit ID)'}`)}
                actions={dialogOdometerEditActions}
                contentStyle={{ width: '50%', maxWidth: 'none' }}
                open={dialogOdometerEdit.open}
                className="translate-me"
              >
                <div style={{ marginBottom: '1em' }}>
                  {t('Note: Please ensure the correct values are entered')}
                  <br />
                  {t('Editing odometer readings will skew distances driven and affect your logs. Odometer edits are treated the same as log edits')}
                </div>

                <TextField
                  value={dialogOdometerEdit.odometerStart}
                  hintText={t(`Odometer Start (${dialogOdometerEdit.distanceUnit})`)}
                  floatingLabelText={t(`Odometer Start (${dialogOdometerEdit.distanceUnit})`)}
                  onChange={(e, value) => this.handleOdometerChange('odometerStart', value)}
                  disabled={dialogOdometerEdit.isLoading}
                />

                <TextField
                  value={dialogOdometerEdit.odometerEnd}
                  hintText={t(`Odometer End (${dialogOdometerEdit.distanceUnit})`)}
                  floatingLabelText={t(`Odometer End (${dialogOdometerEdit.distanceUnit})`)}
                  onChange={(e, value) => this.handleOdometerChange('odometerEnd', value)}
                  disabled={dialogOdometerEdit.isLoading}
                />

                {dialogOdometerEdit.errors.length > 0 &&
                  <ErrorList errors={dialogOdometerEdit.errors} containerStyle={{ marginBottom: '1em' }} />
                }
              </Dialog>
            )}
          </span>
        )}
      </div>
    );
  }
}


ELDEvents.propTypes = {
  Company: PropTypes.object, // is required
  ELDEvent: PropTypes.object, // is required
  User: PropTypes.object, // not required but recommended for permissions
  Temp: PropTypes.object,
  date: PropTypes.instanceOf(Date),
  driver: PropTypes.object, // is required
  driverFetched: PropTypes.bool, // is required
  location: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const { ELDEvent, Company, Temp, User } = state;
  return {
    ELDEvent,
    Company,
    Temp,
    User,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(ELDEvents);
