import React from 'react';
import PropTypes from 'prop-types';

// Components
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';

class VehicleIssuesRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showResolveModal: false,
    };
    this.handleResolveChoice = this.handleResolveChoice.bind(this);
    this.handleResolveClose = this.handleResolveClose.bind(this);
  }

  handleResolveChoice(choiceBool) {
    if (choiceBool) {
      this.state.currentIssue.set('resolved', true).save().then(issue => {
        this.setState(this.state);
      });
    }
    this.setState({ ...this.state, showResolveModal: false });
  }

  handleResolveClose() {
    this.setState({ ...this.state, showResolveModal: false });
  }

  render() {
    const { inspection } = this.props;

    const plate = inspection.plate;
    const activePoints = inspection.activePoints;

    return (
      <tr key={this.props.rowKey}>
        <td>
          { plate }
        </td>
        <td>
          { activePoints }
        </td>
        <td>
          {/*
          <button
            className="buttonDefault longButton floatRight"
            onClick={() => this.setState({
              ...this.state,
              showResolveModal: true,
            })}
          >
            Details
          </button>

          <ConfirmModal show={this.state.showResolveModal} handleModalChoice={this.handleResolveChoice} handleClose={this.handleResolveClose} />
          */}
        </td>
      </tr>
    );
  }
}

VehicleIssuesRow.propTypes = {
  inspection: PropTypes.object,
  rowKey: PropTypes.string,
};

export default VehicleIssuesRow;
