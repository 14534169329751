import React from 'react';
import PropTypes from 'prop-types';

// Components
import Title from 'components/LayoutTitle/view/Title';
import DriverIssuesTable from 'components/DriverDetails/container/DriverIssuesTable';

// CSS
import styles from './SafetyLayout.module.scss';

class SafetyLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    const { driverObjectId } = this.props;

    return (
      <div className={styles.safetyLayout}>
        <Title superClassName={styles.title} title="Contraventions & Accidents" />
        <DriverIssuesTable
          className={styles.tableMaxHeight}
          driverObjectId={driverObjectId}
        />
      </div>
    );
  }
}

SafetyLayout.propTypes = {
  driverObjectId: PropTypes.string,
};

export default SafetyLayout;
