import React, { useState, useEffect } from 'react';
import { t } from 'api/Translate';
import moment from 'moment-timezone';
import history from 'sbHistory';

// Api
import * as ELD from 'api/ELD';
import * as Getters from 'api/Getters';
import * as Analytics from 'api/Analytics';
import isValidDOTNumber from 'api/DOTNumber';
import { getCompanyAnalytics, updateCompanyAnalytics } from 'api/CompanyAnalytics/CompanyAnalytics';

// csapi
import {
  callCloudFunction, getAttribute, getCurrentUserCompany,
  getCurrentUserCompanyObjectId, getCurrentUserSessionToken
} from 'sb-csapi/dist/AAPI';
import { getDrivers } from 'sb-csapi/dist/api/Driver/Driver';
import { formatName } from 'sb-csapi/dist/utils/String';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// sbcore components
import AddEditModal from 'sbCore/AddEditModal/AddEditModal';
import ConfirmDialog from 'sbCore/ConfirmDialog/ConfirmDialog';
import InputText from 'sbCore/InputText/InputText';
import Message from 'sbCore/Message/Message';
import Steps from 'sbCore/Steps/Steps';

// components
import LogDownloadDates from 'components/LogDownload/LogDownloadDates/LogDownloadDates';
import LogDownloadDrivers from 'components/LogDownload/LogDownloadDrivers/LogDownloadDrivers';
import LogDownloadTypes from 'components/LogDownload/LogDownloadTypes/LogDownloadTypes';
import MissingDOTandNSCDialog from 'components/MissingDOTandNSCDialog/MissingDOTandNSCDialog';

// enums
import { QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';
import { Action } from 'sb-csapi/dist/enums/Action';
import { Months } from 'api/Lists/Months';

// style
import './style.scss';

/**
 *
 * @param {bool} showModal - Open modal
 * @param {string} actionType - Check if the user is downloading or sending
 * @param {function} handleClose - Callback to function when modal closes
 * @param {Driver} selectedDriver - Pre-selected Driver from Drivers module
 * @param {Date} selectedDate - Pre-selected Date from Drviers module
 * @returns
 */

function LogDownloadModal({ showModal, actionType, handleClose, selectedDriver, selectedDate }) {
  // ** Lists ** //
  const stepsItems = (
    actionType === 'fmcsa' || actionType === 'downloadCsv' ?
      [
        { label: <div className="flex align-items-center"><i className="pi pi-user mr-1" /> Select Drivers</div> },
        { label: <div className="flex align-items-center"><i className="pi pi-calendar mr-1" />Select Dates</div> },
        { label: <div className="flex align-items-center"><i className="pi pi-check-circle mr-1" />Confirmation</div> },
      ]
      :
      [
        { label: <div className="flex align-items-center"><i className="pi pi-user mr-1" /> Select Drivers</div> },
        { label: <div className="flex align-items-center"><i className="pi pi-calendar mr-1" />Select Dates</div> },
        { label: <div className="flex align-items-center"><i className="pi pi-file mr-1" />Select Add-Ons</div> },
        { label: <div className="flex align-items-center"><i className="pi pi-check-circle mr-1" />Confirmation</div> },
      ]
  );

  const today = moment().startOf('day').toDate();
  // ** UseStates ** //
  // Boolean
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [isSuccessfulRequest, setIsSuccessfulRequest] = useState(false);
  const [missingDOTandNSC, setMissingDOTandNSC] = useState(false);
  const [dismissedMissingDOTAndNSCDialog, setDismissedMissingDOTAndNSCDialog] = useState(false);
  const [invalidDOTNumber, setInvalidDOTNumber] = useState(false);
  const [isMissingFields, setIsMissingFields] = useState(false);
  const [isMissingDialogOpen, setIsMissingDialogOpen] = useState(false);
  const [isExitDialogVisible, setIsExitDialogVisible] = useState(false);
  const [fromStepper, setFromStepper] = useState(false);
  // Labels
  const [headerActionLabel, setHeaderActionLabel] = useState('');
  const [infoActionLabel, setInfoActionLabel] = useState('');
  // Driver States
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [selectableDrivers, setSelectableDrivers] = useState([]);
  // Dates
  const [dates, setDates] = useState({
    dateRange: [],
    byMonth: null,
    last7Days: null,
    last14Days: null,
    selectedDates: [],
  });
  const [selectedDateFormat, setSelectedDateFormat] = useState(0);
  const [dateFormatEnum, setDateFormatEnum] = useState({
    dateRange: 0,
    byMonth: 1,
    last7Days: 2,
    last14Days: 3,
    selectedDates: 4,
  });
  // Others
  const [addOns, setAddOns] = useState({
    printPreTrips: true,
    printPostTrips: false,
    printCTPAT: false,
    showHOSViolations: false,
    printOriginalLogs: false,
    printRecapTableCanada: false,
    printRecapTableUSA: false,
    printUDLogs: true,
  });
  const [cycleObject, setCycleObject] = useState({
    ruleint: null,
    name: null,
    country: null,
  });
  const [emailAddress, setEmailAddress] = useState(null);
  const [outputFileComment, setOutputFileComment] = useState(null);
  const [companyAnalytics, setCompanyAnalytics] = useState(null);
  const [belongsToCompany, setBelongsToCompany] = useState(getCurrentUserCompany());
  // Index States
  const [stepsActiveIndex, setStepsActiveIndex] = useState(0);
  const [stepsActiveIndexEnum, setStepsActiveIndexEnum] = useState((
    actionType === 'fmcsa' || actionType === 'downloadCsv' ?
      {
        drivers: 0,
        dates: 1,
        confirmation: 2,
      }
      :
      {
        drivers: 0,
        dates: 1,
        addOns: 2,
        confirmation: 3,
      }
  ));

  // ** UseEffects ** //
  // Setting date from props
  useEffect(() => {
    if (!selectedDate) return;
    const _dates = { ...dates, selectedDates: [selectedDate] };
    setDates(_dates);
    setSelectedDateFormat(dateFormatEnum.selectedDates);
  }, [selectedDate]);

  // Setting drivers from props
  useEffect(() => {
    if (!selectedDriver) return;
    const _selectedDriver = { objectId: getAttribute(selectedDriver, 'objectId'), user_fullName: formatName(getAttribute(selectedDriver, 'user_fullName')) || '' };
    setSelectedDrivers([_selectedDriver]);
  }, [selectedDriver]);

  // Setting action labels
  useEffect(() => {
    switch (actionType) {
      case 'email':
        setHeaderActionLabel(' - Email');
        setInfoActionLabel('Enter the email address to which you would like to send these logs. ');
        break;
      case 'downloadCsv':
        setHeaderActionLabel(' - Download CSV');
        setInfoActionLabel('');
        break;
      case 'fmcsa':
        setHeaderActionLabel(' - Send to FMCSA');
        setInfoActionLabel('Enter the reason for generating these logs (for FMCSA/NSC compliance). ');
        break;
      case 'nsc':
        setHeaderActionLabel(' - Send to NSC');
        setInfoActionLabel('Enter the reason for generating these logs (for FMCSA/NSC compliance). ');
        break;
      default:
        setHeaderActionLabel(' - Download PDF');
        setInfoActionLabel('');
    }
  }, [actionType]);

  // Getting company drivers
  useEffect(() => {
    let didCancel = false;
    async function _getDrivers() {
      const { drivers } = await getDrivers(
        { sessionToken: getCurrentUserSessionToken() },
        getCurrentUserCompanyObjectId(),
        false,                                                            // get child company drivers
        [new Filter(QueryRestrictionTypes.EQUAL_TO, 'enabled', true)],    // filters
        undefined,
        ['vehicle', 'user'],
        undefined,
        true,
      );
      const _drivers = drivers.map((driver) => ({ objectId: getAttribute(driver, 'objectId'), user_fullName: formatName(getAttribute(driver, 'user_fullName')) || '' }))
      setSelectableDrivers(_drivers);
    }

    if (!(getAttribute(belongsToCompany, 'dotNumber', true) && !getAttribute(belongsToCompany, 'nscNumber', true))) setMissingDOTandNSC(true);
    if (getAttribute(belongsToCompany, 'dotNumber')) setInvalidDOTNumber(!isValidDOTNumber(getAttribute(belongsToCompany, 'dotNumber')));

    async function _getCompanyAnalytics() {
      const _companyAnalytics = await getCompanyAnalytics();
      if (!didCancel) setCompanyAnalytics(_companyAnalytics);
    }
    if (!didCancel) {
      _getCompanyAnalytics();
      _getDrivers();
    }

    return () => { didCancel = true; };
  }, [belongsToCompany]);

  // Check if user entered DOT or NSC
  useEffect(() => {
    if (getAttribute(belongsToCompany, 'dotNumber', true) || getAttribute(belongsToCompany, 'nscNumber', true)) setMissingDOTandNSC(false);
    if (getAttribute(belongsToCompany, 'dotNumber')) setInvalidDOTNumber(!isValidDOTNumber(getAttribute(belongsToCompany, 'dotNumber')));
  }, [isMissingDialogOpen]);

  // Check if there are missing fields
  useEffect(() => {
    // Find if drivers and dates have been added to generate the logs
    if (stepsActiveIndex !== stepsActiveIndexEnum.confirmation) {
      setIsMissingFields(false);
      return;
    }

    if (selectedDrivers.length === 0) setIsMissingFields(true);
    else if (selectedDateFormat === 0 && (dates.dateRange.length !== 2 || !dates.dateRange[1])) setIsMissingFields(true);
    else if (selectedDateFormat === 1 && !dates.byMonth) setIsMissingFields(true);
    else if (selectedDateFormat === 4 && dates.selectedDates.length === 0) setIsMissingFields(true);
    else setIsMissingFields(false);

    if (actionType === 'email' && !emailAddress) setIsMissingFields(true);
    if (actionType === 'fmcsa' && !outputFileComment) setIsMissingFields(true);
    if (actionType === 'nsc' && (!emailAddress || !outputFileComment)) setIsMissingFields(true);
  }, [dates, selectedDrivers, stepsActiveIndex, selectedDateFormat, emailAddress, outputFileComment]);

  // ** Functions ** //
  async function handleNextModal(action = null) {
    // Cancel Action
    if (!action) {
      handleClose(false);
      return;
    }
    // Close Modal after Generating Logs
    if (action === 'closeModal') {
      // Ensure that the row is there
      if (actionType === 'downloadPdf' || actionType === 'downloadCsv') {
        // Downloading PDF will go to logstodownload
        handleClose(true);
        history.push({ pathname: 'safety', search: 'view=logstodownload' });
      } else {
        handleClose(true);
      }
      return;
    }

    if (action === 'closeConfirmDialog') {

    } else if (
      stepsActiveIndex === stepsActiveIndexEnum.drivers &&
      (missingDOTandNSC)
    ) {
      // Open confirm dialog
      setFromStepper(false);
      setIsMissingDialogOpen(true);
      return;
    }
    // Continue
    if (stepsActiveIndex === stepsActiveIndexEnum.confirmation) generateLogs();
    else setStepsActiveIndex(stepsActiveIndex + 1);
  }

  function editStepsActiveIndex(stepsIndex) {
    // if moving from first step and has missing DOT and NSC, open dialog
    if (stepsActiveIndex === stepsActiveIndexEnum.drivers && (missingDOTandNSC || invalidDOTNumber)) {
      setIsMissingDialogOpen(true);
      setFromStepper(true);
    }
    setStepsActiveIndex(stepsIndex);
  }

  function getDateRange(startDate, endDate, days = null) {
    const daysInBetween = days || moment(endDate).diff(startDate, 'days');

    const _dates = [];
    for (let i = 0; i <= daysInBetween; i++) {
      const newDate = moment(startDate).add(i, 'days').toDate();
      _dates.push(newDate);
      if (newDate === today) return _dates;
    }
    return _dates;
  }

  function generateLogs() {
    let logDatesChosen;
    if (selectedDateFormat === 0) logDatesChosen = getDateRange(dates.dateRange[0], dates.dateRange[1]);
    if (selectedDateFormat === 1) logDatesChosen = getDateRange(dates.byMonth, null, moment(dates.byMonth).daysInMonth() - 1);
    if (selectedDateFormat === 2) logDatesChosen = getDateRange(dates.last7Days[0], dates.last7Days[1]);
    if (selectedDateFormat === 3) logDatesChosen = getDateRange(dates.last14Days[0], dates.last14Days[1]);
    if (selectedDateFormat === 4) logDatesChosen = [...dates.selectedDates];
    const selectedDriverIds = selectedDrivers.map((driver) => driver.objectId);
    const analyticTrackProperties = {
      status: 'in_progress',
      num_days_printed: logDatesChosen.length,
      print_original: addOns.printOriginalLogs,
      print_pretrip: addOns.printPreTrips,
      print_ctpat: addOns.printCTPAT,
      print_posttrip: addOns.printPostTrips,
      driver_ids: selectedDriverIds,
      download_type: 'downloadPdf',
    };
    const recapHoursArr = (addOns.printRecapTableCanada || addOns.printRecapTableUSA) ? [{ ruleint: cycleObject.ruleint, country: cycleObject.country }] : undefined;
    const promise = new Promise((resolve, reject) => {
      if (actionType === 'downloadPdf') {
        ELD.generateHOSLogs(
          undefined,
          logDatesChosen,
          selectedDriverIds,
          true,
          addOns.disregardLogs,
          addOns.printOriginalLogs,
          addOns.printUDLogs,
          addOns.printPreTrips,
          addOns.printCTPAT,
          addOns.printPostTrips,
          true,
          null,
          recapHoursArr,
          addOns.showHOSViolations
        ).then(logParseObj => {
          resolve(logParseObj);
        }).catch(error => {
          reject(error);
          analyticTrackProperties.status = 'failed';
          analyticTrackProperties.error = error;
        });
        setIsSuccessfulRequest(true);
      } else if (actionType === 'downloadCsv') {
        for (let i = 0; i < selectedDriverIds.length; i++) {
          try {
            callCloudFunction('generateHosCsv', {
              logDatesChosen,
              driverId: selectedDriverIds[i],
              distanceUnit: 'mi',
              outputFileComment: 'CSV Generated by Switchboard',
            });
          } catch (err) {
            console.log(err);
          }
        }
        resolve();
        setIsSuccessfulRequest(true);
      } else if (actionType === 'email') {
        analyticTrackProperties.download_type = 'email';
        ELD.generateHOSLogs(undefined, logDatesChosen, selectedDriverIds, true, false, addOns.printOriginalLogs, addOns.printUDLogs, addOns.printPreTrips, addOns.printCTPAT, addOns.printPostTrips, true, emailAddress, recapHoursArr, addOns.showHOSViolations).then(logParseObj => {
          resolve(logParseObj);
        }).catch(error => {
          reject(error);
          analyticTrackProperties.status = 'failed';
          analyticTrackProperties.error = error;
        });
        setIsSuccessfulRequest(true);
      } else if (actionType === 'nsc') {
        ELD.generateHOSLogs(undefined, logDatesChosen, selectedDriverIds, true, false, addOns.printOriginalLogs, addOns.printUDLogs, addOns.printPreTrips, addOns.printCTPAT, addOns.printPostTrips, true, emailAddress, recapHoursArr, addOns.showHOSViolations, undefined, outputFileComment, true).then(logParseObj => {
          resolve(logParseObj);
        }).catch(error => {
          reject(error);
        });
        setIsSuccessfulRequest(true);
      } else if (actionType === 'fmcsa') {
        for (let i = 0; i < selectedDriverIds.length; i++) {
          try {
            callCloudFunction('sendToFMCSAWebServices', {
              logDatesChosen,
              driverId: selectedDriverIds[i],
              distanceUnit: 'mi',
              outputFileComment,
              // testFlag: true,
            });
          } catch (err) {
            console.log(err);
          }
        }
        setIsSuccessfulRequest(true);
      }
    });
    if (companyAnalytics) {
      const GENLOGS = getAttribute(companyAnalytics, 'GENLOGS');
      if (!GENLOGS) {
        Analytics.track('bundle_logs', analyticTrackProperties);
        updateCompanyAnalytics(companyAnalytics, { GENLOGS: true }).then().catch(err => console.log(err));
      }
    }
    //setTimeout(() => {
    setSuccessModalIsOpen(true); // ensure request has been completed
    //}, 2000);
    return promise;
  }

  // ** Date Field Templates ** //
  const last7DaysTemplate = (
    <div>{dates.last7Days && dates.last7Days[0].toDateString()} to {dates.last7Days && dates.last7Days[1].toDateString()}</div>
  );

  const last14DaysTemplate = (
    <div>{dates.last14Days && dates.last14Days[0].toDateString()} to {dates.last14Days && dates.last14Days[1].toDateString()}</div>
  );

  // ** Confirmation Step Templates ** //
  function logInformationTemplate() {
    const logInformation = [];
    if (addOns.disregardLogs) logInformation.push('Trip Inspections Only');
    if (addOns.printPreTrips) logInformation.push('Pre Trip Reports');
    if (addOns.printPostTrips) logInformation.push('Post Trip Reports');
    if (addOns.printCTPAT) logInformation.push('CTPAT');
    if (addOns.showHOSViolations) logInformation.push('HOS Violations');
    if (addOns.printOriginalLogs) logInformation.push('Available Unedited Logs');
    if (addOns.printUDLogs) logInformation.push('Unidentified Driving Logs');
    if (addOns.printRecapTableCanada) logInformation.push('Recap Table (Canada)');
    if (addOns.printRecapTableUSA) logInformation.push('Recap Table (US)');
    return (
      <div>{logInformation.join(', ').toString()}</div>
    );
  }

  function dateTemplate() {
    if (selectedDateFormat === 0 && dates.dateRange?.length === 2) return `${dates.dateRange[0]?.toDateString()} to ${dates.dateRange[1]?.toDateString()}`;
    if (selectedDateFormat === 1 && dates.byMonth) return `${Months[dates.byMonth.getMonth()]} ${dates.byMonth.getFullYear()}`;
    if (selectedDateFormat === 2) return last7DaysTemplate;
    if (selectedDateFormat === 3) return last14DaysTemplate;
    if (selectedDateFormat === 4 && dates.selectedDates?.length > 0) {
      const customDates = dates.selectedDates.map((date) => ` ${date.toDateString()}`);
      return customDates.toString();
    }
    const messageText = <div>No<span className="settings-link mx-1" onClick={() => setStepsActiveIndex(stepsActiveIndexEnum.dates)}>Dates</span>Selected</div>;
    return <Message severity="warn" text={messageText} className="w-25" />;
  }

  const confirmationTemplate = (
    <div className="modal-template-content">
      {infoActionLabel} To make changes, click on the steps above. To finish, click on &quot;Generate.&quot;
      <div className="d-flex align-items-center mt-4 mb-2">
        <span className="confirmation-label">Drivers: </span>
        {selectedDrivers.length > 0 ? selectedDrivers.map((driver) => ` ${driver.user_fullName}`).toString() : <Message severity="warn" text={<div>No<span className="settings-link mx-1" onClick={() => setStepsActiveIndex(stepsActiveIndexEnum.drivers)}>Drivers</span>Selected</div>} className="w-25" />}
      </div>
      <div className="d-flex align-items-center mb-2">
        <span className="confirmation-label">Dates: </span>
        {dateTemplate()}
      </div>
      {!(actionType === 'fmcsa' || actionType === 'downloadCsv') &&
        <div className="d-flex align-items-center mb-2">
          <span className="confirmation-label">Add-Ons: </span>
          {logInformationTemplate()}
        </div>
      }
      {actionType === 'email' && (
        <div className="d-flex align-items-center">
          <span className="confirmation-label">Email: </span>
          <InputText value={emailAddress} error={!emailAddress} onChange={(e) => setEmailAddress(e.target.value)} placeholder="Email" className="w-75" />
        </div>
      )}
      {actionType === 'nsc' && (
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center mb-1">
            <span className="confirmation-label">NSC Email: </span>
            <InputText value={emailAddress} error={!emailAddress} onChange={(e) => setEmailAddress(e.target.value)} placeholder="NSC Email" className="w-75" />
          </div>
          <div className="d-flex align-items-center">
            <span className="confirmation-label">NSC Output File Comment: </span>
            <InputText value={outputFileComment} error={!outputFileComment} onChange={(e) => setOutputFileComment(e.target.value)} placeholder="Message to NSC" className="w-75" />
          </div>
        </div>
      )}
      {actionType === 'fmcsa' && (
        <div className="d-flex align-items-center">
          <span className="confirmation-label">FMCSA Output File Comment: </span>
          <InputText value={outputFileComment} error={!outputFileComment} onChange={(e) => setOutputFileComment(e.target.value)} placeholder="Message to FMCSA" className="w-75" />
        </div>
      )}
    </div>
  );

  return (
    <div className="log-generation-modal">
      <MissingDOTandNSCDialog
        isConfirmDialogVisible={isMissingDialogOpen}
        setIsConfirmDialogVisible={(value) => { setIsMissingDialogOpen(value); if (value === false) setDismissedMissingDOTAndNSCDialog(true) }}
        handleNextModal={(action) => handleNextModal(action)}
        fromStepper={fromStepper}
        belongsToCompany={belongsToCompany}
      />
      <ConfirmDialog
        visible={isExitDialogVisible}
        onHide={() => setIsExitDialogVisible(false)}
        message={t('Are you sure you want to exit? Changes will not be saved.')}
        header={t('Cancel Log Generation')}
        acceptLabel={t('Stay')}
        rejectLabel={t('Exit')}
        rejectClassName="p-button-outlined"
        icon="pi pi-question-circle"
        accept={() => setIsExitDialogVisible(false)}
        reject={() => handleNextModal()}
        focusOnShow={false}
        closable={false}
        draggable={false}
      />
      <AddEditModal
        isOpen={successModalIsOpen}
        action={Action.HIDE}
        cancelButtonLabel="Ok"
        onCancel={() => handleNextModal('closeModal')}
        hideDeleteButton
        sbVariant="square"
        isErrorMessage={!isSuccessfulRequest}
      >
        <div className="w-full flex flex-column justify-content-center align-items-center">
          {isSuccessfulRequest && <i className="pi pi-check-circle success-icon" />}
          {!isSuccessfulRequest && <i className="pi pi-times-circle failure-icon" />}
          <span className="font-weight-bold mb-2" style={{ fontSize: '1.5rem' }}>{isSuccessfulRequest ? 'Success!' : 'Whoops!'}</span>
          {isSuccessfulRequest ? 'Driver Logs will be generated shortly.' : 'An error has occured, but try again shortly!'}
        </div>
      </AddEditModal>
      <AddEditModal
        isOpen={showModal}
        className="log-download-modal"
        action={Action.ADD}
        addButtonLabel={stepsActiveIndex === stepsActiveIndexEnum.confirmation ? 'Generate' : 'Continue'}
        cancelButtonLabel="Cancel"
        headerTemplate={'Generating Logs' + headerActionLabel}
        onCreate={() => handleNextModal('continue')}
        onCancel={() => setIsExitDialogVisible(true)}
        disableCreateButton={isMissingFields || !selectedDrivers || selectedDrivers.length === 0} // disable if nothing has changed
        disableDeleteButton
        hideDeleteButton
        isCancelButtonIcon
        outlineCancelButton
        draggable={false}
        sbVariant="compact"
      >
        <Steps
          model={stepsItems}
          activeIndex={stepsActiveIndex}
          onSelect={(e) => editStepsActiveIndex(e.index)}
          readOnly={false}
          className="mt-3 mb-5"
        />
        {stepsActiveIndex === stepsActiveIndexEnum.drivers && (
          <LogDownloadDrivers
            selectableDrivers={selectableDrivers}
            selectedDrivers={selectedDrivers}
            editSelectedDrivers={(drivers) => setSelectedDrivers(drivers)}
          />
        )}
        {stepsActiveIndex === stepsActiveIndexEnum.dates && (
          <LogDownloadDates
            dates={dates}
            onSelectDates={setDates}
            onSelectDateFormat={setSelectedDateFormat}
            dateFormat={selectedDateFormat}
            last7DaysTemplate={last7DaysTemplate}
            last14DaysTemplate={last14DaysTemplate}
          />
        )}
        {stepsActiveIndex === stepsActiveIndexEnum.addOns && (
          <LogDownloadTypes
            addOns={addOns}
            onSelectAddOns={setAddOns}
            cycleObject={cycleObject}
            onSelectCycleObject={setCycleObject}
            belongsToCompany={belongsToCompany}
          />
        )}
        {stepsActiveIndex === stepsActiveIndexEnum.confirmation && confirmationTemplate}
      </AddEditModal>
    </div>
  );
}

export default LogDownloadModal;
