import Constants from 'actions/SBAlert/Constants';
import AlertTypes from 'enums/SBAlert';

const initialState = {
  type: undefined,
  alerts: [
    // { id: '1', code: 0, description: 'This is a test Warning', type: AlertTypes.WARNING },
    // { id: '2', code: 0, description: 'This is a test Warning Duplicate', type: AlertTypes.WARNING },
    // { id: '3', code: 0, description: 'This is a test Error', type: AlertTypes.ERROR },
    // { id: '4', code: 0, description: 'This is a test Update', type: AlertTypes.UPDATE },
    // { id: '5', code: 0, description: 'This is a test Update Duplicate', type: AlertTypes.UPDATE },
    // { id: '6', code: 0, description: 'This is a test Dismissable Success', showCloseButton: true, type: AlertTypes.SUCCESS },
    // { id: '7', code: 0, description: 'This is a test Auto Dismissable Update', showCloseButton: true, autoDismissInMillis: 5000, type: AlertTypes.UPDATE },
    // { id: '8', code: 0, description: 'This is a test Dismissable Default', showCloseButton: true, type: AlertTypes.WTF },
  ],
};

const helpers = {
  removeDuplicates: function (alerts, sortByReverse) {
    // allows us to use multiple alerts with same ID but without the user seeing a spam of alerts
    const seen = {};
    if (alerts) {
      let _alerts = [].concat(alerts);
      if (sortByReverse) _alerts.reverse();
      return _alerts.filter((alert) => {
        const objectId = alert.id || alert._localId;
        if (seen[objectId]) {
          return false;
        }
        seen[objectId] = true;
        return true;
      });
    }
    return alerts;
  },
  deleteAlertFromState: function (alert, alerts) {
    const updatedAlerts = [ ...alerts ];
    const alertId = alert.id;
    for (let i = 0; i < alerts.length; i++) {
      const current = updatedAlerts[i];
      if (current.id === alertId) {
        updatedAlerts.splice(i, 1);
        break;
      }
    }

    return updatedAlerts;
  },
};

export function SBAlert(state = initialState, action) {
  switch (action.type) {
    case Constants.ADD_ALERT: {
      return {
        ...state,
        alerts: helpers.removeDuplicates(state.alerts.concat([action.alert]), true),
        type: action.type,
      };
    }
    case Constants.DELETE_ALERT: {
      return {
        ...state,
        alerts: helpers.deleteAlertFromState(action.alert, state.alerts),
        type: action.type,
      };
    }
    default:
      return state;
  }
}
