import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import * as Helpers from 'api/Helpers';
import * as Getters from 'api/Getters';
import * as ELD from 'api/ELD';
import { getTimezoneAbbreviation } from 'api/utils/timezone';
import { ELDEventTypeCodeIntDescriptions } from 'enums/ELDEventTypeCode';

// Components
import DefaultShippingDocumentNumberPopup from 'components/ShippingDocumentNumber/DefaultShippingDocumentNumberPopup/DefaultShippingDocumentNumberPopup';
import ELDEditModal from 'components/ELDEdit/ELDEditModal/ELDEditModal';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Checkbox from 'sbCore/Checkbox/Checkbox';

// CSAPI
import { getAttribute, getCurrentUserSessionToken, updateRecord } from 'sb-csapi/dist/AAPI';
import { updateELDEventData } from 'sb-csapi/dist/api/ELDEvent/ELDEvent';
import { compressWhitespace } from 'sb-csapi/dist/utils/String';

import ReactTooltip from 'react-tooltip';

import {
  Table,
  TableHeader,
  TableRow,
  TableRowColumn,
  TableHeaderColumn,
  TableBody,
} from 'material-ui';

import ELDEdit from 'components/ELDEdit/container/ELDEdit';
import Title from 'components/LayoutTitle/view/Title';
import FilterDropdown from 'components/FilterDropdown/view/FilterDropdown';
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';

// sbCore Components
import Button from 'sbCore/Button/Button';
import IconLabel from 'sbCore/IconLabel/IconLabel';
import InputText from 'sbCore/InputText/InputText';

class EventsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventListIndex: 0,
      eventListEditIndex: 0,
      locationDetailsListIndex: 0,
      showPreviewDialog: false,
      filteredOdometerReadingVehicleMapObj: {},
      downloadCSVInProgress: false,
      showEditInputShipment: false,
      shippingDocumentNumberMap: {}, // Keys are ELDEvent ObjectIds, Values are arrays [eldEvent record, shippingDocumentNumber, bool edit?
      eventListSortType: 0, // Sorting for event list: 0 = descending, 1 = ascending
    };
    this.state.eventListFilterFields = [
      { attrName: '0', placeholder: t('Duty Status View') },
      { attrName: '1', placeholder: t('All Events View') },
    ];
    this.state.eventListEditFilterFields = [
      { attrName: '0', placeholder: t('Hide Edit History') },
      { attrName: '1', placeholder: t('Show Edit History') },
    ];
    this.state.locationDetailsFilterFields = [
      { attrName: '0', placeholder: t('Hide Location Details') },
      { attrName: '1', placeholder: t('Show Location Details') },
    ];

    this.handleEventListDropdownView = this.handleEventListDropdownView.bind(this);
    this.handleEventListEditDropdownView = this.handleEventListEditDropdownView.bind(this);
    this.filterELDEvents = this.filterELDEvents.bind(this);
    this.getCategoryHTMLFromEventTypeCode = this.getCategoryHTMLFromEventTypeCode.bind(this);
    this.handleEditShippingDocumentNumber = this.handleEditShippingDocumentNumber.bind(this);
    this.handleSaveEditToShippingDocumentNumber = this.handleSaveEditToShippingDocumentNumber.bind(this);
    // this.getLocationDescriptionBreakdown = this.getLocationDescriptionBreakdown.bind(this);
    this.processListAsCSV = this.processListAsCSV.bind(this);
    this.getMalfunctionDescriptionFromMalfunctionCode = this.getMalfunctionDescriptionFromMalfunctionCode.bind(this);
    this.getStatusFromEventTypeCode = this.getStatusFromEventTypeCode.bind(this);
    this.resetShippingDocumentNumber = this.resetShippingDocumentNumber.bind(this);
    this.updateELDEventListSortType = this.updateELDEventListSortType.bind(this);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { eldEvents } = nextProps;

    const shippingDocumentNumberMap = await this.resetShippingDocumentNumber(eldEvents);

    // If debug is true, don't correct for odos
    const filteredOdometerReadingVehicleMapObj = await ELD.filterOdometerReadingsJumpNew(this.props.eldEvents, nextProps.debug && 999999999999999999999999999);
    const eventListSortType = Getters.getPDispatcherPropertyFromState('eldEventListSortType');
    this.setState({ ...this.state, shippingDocumentNumberMap, filteredOdometerReadingVehicleMapObj, eventListSortType });
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps.eldEvents.length === this.props.eldEvents.length) return false;
  //   return true;
  // }

  handleEventListDropdownView(eventListIndex) {
    // 0 = duty status view; 1 = all
    const index = parseFloat(eventListIndex);
    this.setState({ ...this.state, eventListIndex: index }, () => {
      // refresh table
    });
  }

  handleEventListEditDropdownView(eventListEditIndex) {
    // 0 = do not display edit requests; 1 = display edit requests
    const index = parseFloat(eventListEditIndex);
    this.setState({ ...this.state, eventListEditIndex: index }, () => {
      // refresh table
    });
  }

  handleEditShippingDocumentNumber(eldEvent, edittedShippingDocumentNumber) {
    const eldEventObjectId = getAttribute(eldEvent, 'objectId');
    const shippingDocumentNumberMap = {
      ...this.state.shippingDocumentNumberMap,
      [eldEventObjectId]: [eldEvent, edittedShippingDocumentNumber, true],
    };
    this.setState({ ...this.state, shippingDocumentNumberMap });
  }

  filterELDEvents(eldEvents, eventListIndex, eventListEditIndex) {
    let filteredELDEvents = [].concat(eldEvents);

    if (!eventListIndex) {
      const dutyStatusTypeCodeInts = [11, 12, 13, 14, 30, 31, 32];
      filteredELDEvents = filteredELDEvents.filter(event => dutyStatusTypeCodeInts.indexOf(event.get('eldEventTypeCodeInt')) !== -1);
    }

    if (!eventListEditIndex) {
      filteredELDEvents = filteredELDEvents.filter(event => event.get('eldEventRecordStatusInt') === 1);
    }

    filteredELDEvents = filteredELDEvents.filter((currentValue, index, arr) => {
      return true
      if (index === arr.length - 1) {
        return true;
      } else {
        if (currentValue.get('eldEventTypeCodeInt') === arr[index + 1].get('eldEventTypeCodeInt') && currentValue.get('note') === arr[index + 1].get('note')) {
          return false;
        } else {
          return true;
        }
      }
    });
    return filteredELDEvents;
  }

  getMalfunctionDescriptionFromMalfunctionCode(eldMalfunctionDataCodeCode) {
    let description = " ";
    switch (eldMalfunctionDataCodeCode) {
      case 'P':
        description = t('Power compliance');
        break;
      case 'E':
        description = t('Engine Syncronization compliance');
        break;
      case 'T':
        description = t('Timing compliance (device clock)');
        break;
      case 'L':
        description = t('Position compliance (GPS)');
        break;
      case 'R':
        description = t('Data recording compliance (device storage space)');
        break;
      case 'S':
        description = t('Data transfer compliance');
        break;
      case '1':
        description = t('Power compliance');
        break;
      case '2':
        description = t('Engine Syncronization compliance');
        break;
      case '3':
        description = t('Missing required data elements');
        break;
      case '4':
        description = t('Data transfer compliance');
        break;
      case '5':
        description = t('Unidentified driving records');
        break;
      default: // Event not yet defined
        description = t('Event not Recognized');
        break;
    }

    return description;
  }

  // Given an eldEventTypeCode, determine which status to display in the CSV row
  getStatusFromEventTypeCode(referenceInt) {
    let status = " ";
    switch (referenceInt) {
      case 11: // off duty
        status = t('OF');
        break;
      case 12: // sleeper
        status = t('SB');
        break;
      case 13: // driving
        status = t('D');
        break;
      case 14: // on duty
        status = t('OD');
        break;
      case 31: // personal use cmv
        status = t('PC (OF)');
        break;
      case 32: // yard moves
        status = t('YM (OD)');
        break;
      // case 41:
      //   status = "Driver's First Certification of Daily Record";
      //   break;
      // case 42:
      //   status = "Driver's Second Certification of Daily Record";
      //   break;
      // case 43:
      //   status = "Driver's Third Certification of Daily Record";
      //   break;
      // case 44:
      //   status = "Driver's Fourth Certification of Daily Record";
      //   break;
      // case 45:
      //   status = "Driver's Fifth Certification of Daily Record";
      //   break;
      // case 46:
      //   status = "Driver's Sixth Certification of Daily Record";
      //   break;
      // case 47:
      //   status = "Driver's Seventh Certification of Daily Record";
      //   break;
      // case 48:
      //   status = "Driver's Eighth Certification of Daily Record";
      //   break;
      // case 49:
      //   status = "Driver's Ninth Certification of Daily Record";
      //   break;
      // case 51: // login activity
      //   status = "Driver Login";
      //   break;
      // case 52: // logout activity
      //   status = "Driver Logout";
      //   break;
      // case 61: // engine activity
      //   status = "Power-Up (Conventional Location Precision)";
      //   break;
      // case 62: // engine activity
      //   status = "Power-Up (Reduced Location Precision)";
      //   break;
      // case 63: // engine activity
      //   status = "Shut-Down (Conventional Location Precision)";
      //   break;
      // case 64: // engine activity
      //   status = "Shut-Down (Reduced Location Precision)";
      //   break;
      // case 71:
      //   status = "ELD Malfunction Logged";
      //   break;
      // case 72:
      //   status = "ELD Malfunction Cleared";
      //   break;
      // case 73:
      //   status = "Data Diagnostic Event Logged";
      //   break;
      // case 74:
      //   status = "Data Diagnostic Event Cleared";
      //   break;

      // case 200: // Off-duty Time Deferral set to None
      //   status = "Off-duty Time Deferral set to None";
      //   break;
      // case 201: // Off-duty Time Deferral set to Day 1
      //   status = "Off-duty Time Deferral set to Day 1";
      //   break;
      // case 202: // Off-duty Time Deferral set to Day 2
      //   status = "Off-duty Time Deferral set to Day 2";
      //   break;
      // case 211: // Cycle set to Cycle 1
      //   status = "Cycle set to Cycle 1";
      //   break;
      // case 212: // Cycle set to Cycle 2
      //   status = "Cycle set to Cycle 2";
      //   break;
      // case 221: // Operating Zone set to South of Latitude 60°N in Canada
      //   status = "Operating Zone set to South of Latitude 60°N in Canada";
      //   break;
      // case 222: // Operating Zone set to North of Latitude 60°N in Canada
      //   status = "Operating Zone set to North of Latitude 60°N in Canada";
      //   break;
      // case 223: // Operating Zone set to United States
      //   status = "Operating Zone set to United States";
      //   break;
      // case 231: // Additional Hours not Recorded
      //   status = "Additional Hours not Recorded";
      //   break;
      default: // Event not yet defined
        return undefined;
    }

    return status;
  }

  getCategoryHTMLFromEventTypeCode(referenceInt, eldMalfunctionDataCodeCode, styles) {
    // given an eldEventTypeCode referenceInt, determine what HTML to output to display category
    let html = <div>Could not retrieve Type</div>;
    switch (referenceInt) {
      case 11: // off duty
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[DUTY STATUS]')}
            </div>
            <div>
              {t('Off Duty')}
            </div>
          </div>
        );
        break;
      case 12: // sleeper
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[DUTY STATUS]')}
            </div>
            <div>
              {t('Sleeper Berth')}
            </div>
          </div>
        );
        break;
      case 13: // driving
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[DUTY STATUS]')}
            </div>
            <div>
              {t('Driving')}
            </div>
          </div>
        );
        break;
      case 14: // on duty
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[DUTY STATUS]')}
            </div>
            <div>
              {t('On Duty')}
            </div>
          </div>
        );
        break;
      case 21: // intermediate log
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[LOG]')}
            </div>
            <div>
              {t('Intermediate Log')}
            </div>
            <div className="textSmallRelative">
              {t('(Conventional Location Precision)')}
            </div>
          </div>
        );
        break;
      case 22: // intermediate log
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[LOG]')}
            </div>
            <div>
              {t('Intermediate Log')}
            </div>
            <div className="textSmallRelative">
              {t('(Reduced Location Precision)')}
            </div>
          </div>
        );
        break;
      case 30:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[DUTY STATUS]')}
            </div>
            <div>
              {t('Driver\'s indication for PC, YM, and WT cleared')}
            </div>
          </div>
        );
        break;
      case 31: // personal use cmv
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[DUTY STATUS]')}
            </div>
            <div>
              {t('Personal Use CMV (Off Duty)')}
            </div>
          </div>
        );
        break;
      case 32: // yard moves
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[DUTY STATUS]')}
            </div>
            <div>
              {t('Yard Moves (On Duty)')}
            </div>
          </div>
        );
        break;
      case 41:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[CERTIFICATION]')}
            </div>
            <div>
              {t('Driver\'s First Certification of Daily Record')}
            </div>
          </div>
        );
        break;
      case 42:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[CERTIFICATION]')}
            </div>
            <div>
              {t('Driver\'s Second Certification of Daily Record')}
            </div>
          </div>
        );
        break;
      case 43:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[CERTIFICATION]')}
            </div>
            <div>
              {t('Driver\'s Third Certification of Daily Record')}
            </div>
          </div>
        );
        break;
      case 44:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[CERTIFICATION]')}
            </div>
            <div>
              {t('Driver\'s Fourth Certification of Daily Record')}
            </div>
          </div>
        );
        break;
      case 45:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[CERTIFICATION]')}
            </div>
            <div>
              {t('Driver\'s Fifth Certification of Daily Record')}
            </div>
          </div>
        );
        break;
      case 46:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[CERTIFICATION]')}
            </div>
            <div>
              {t('Driver\'s Sixth Certification of Daily Record')}
            </div>
          </div>
        );
        break;
      case 47:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[CERTIFICATION]')}
            </div>
            <div>
              {t('Driver\'s Seventh Certification of Daily Record')}
            </div>
          </div>
        );
        break;
      case 48:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[CERTIFICATION]')}
            </div>
            <div>
              {t('Driver\'s Eighth Certification of Daily Record')}
            </div>
          </div>
        );
        break;
      case 49:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[CERTIFICATION]')}
            </div>
            <div>
              {t('Driver\'s Ninth Certification of Daily Record')}
            </div>
          </div>
        );
        break;
      case 51: // login activity
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[LOGIN/OUT]')}
            </div>
            <div>
              {t('Driver Login')}
            </div>
          </div>
        );
        break;
      case 52: // logout activity
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[LOGIN/OUT]')}
            </div>
            <div>
              {t('Driver Logout')}
            </div>
          </div>
        );
        break;
      case 61: // engine activity
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[ENGINE]')}
            </div>
            <div>
              {t('Power-Up')}
            </div>
            <div className="textSmallRelative">
              {t('(Conventional Location Precision)')}
            </div>
          </div>
        );
        break;
      case 62: // engine activity
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[ENGINE]')}
            </div>
            <div>
              {t('Power-Up')}
            </div>
            <div className="textSmallRelative">
              {t('(Reduced Location Precision)')}
            </div>
          </div>
        );
        break;
      case 63: // engine activity
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[ENGINE]')}
            </div>
            <div>
              {t('Shut-Down')}
            </div>
            <div className="textSmallRelative">
              {t('(Conventional Location Precision)')}
            </div>
          </div>
        );
        break;
      case 64: // engine activity
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[ENGINE]')}
            </div>
            <div>
              {t('Shut-Down')}
            </div>
            <div className="textSmallRelative">
              {t('(Reduced Location Precision)')}
            </div>
          </div>
        );
        break;
      case 71:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[DIAGNOSTIC]')}
            </div>
            <div>
              {t('ELD Malfunction Logged')}
              {eldMalfunctionDataCodeCode &&
                (
                  <div>
                    <i><b>{this.getMalfunctionDescriptionFromMalfunctionCode(eldMalfunctionDataCodeCode)}</b></i>
                  </div>
                )
              }
            </div>
          </div>
        );
        break;
      case 72:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[DIAGNOSTIC]')}
            </div>
            <div>
              {t('ELD Malfunction Cleared')}
              {eldMalfunctionDataCodeCode &&
                (
                  <div>
                    <i><b>{this.getMalfunctionDescriptionFromMalfunctionCode(eldMalfunctionDataCodeCode)}</b></i>
                  </div>
                )
              }
            </div>
          </div>
        );
        break;
      case 73:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[DIAGNOSTIC]')}
            </div>
            <div>
              {t('Data Diagnostic Event Logged')}
              {eldMalfunctionDataCodeCode &&
                (
                  <div>
                    <i><b>{this.getMalfunctionDescriptionFromMalfunctionCode(eldMalfunctionDataCodeCode)}</b></i>
                  </div>
                )
              }
            </div>
          </div>
        );
        break;
      case 74:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[DIAGNOSTIC]')}
            </div>
            <div>
              {t('Data Diagnostic Event Cleared')}
              {eldMalfunctionDataCodeCode &&
                (
                  <div>
                    <i><b>{this.getMalfunctionDescriptionFromMalfunctionCode(eldMalfunctionDataCodeCode)}</b></i>
                  </div>
                )
              }
            </div>
          </div>
        );
        break;
      case 200:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[OFF-DUTY TIME DEFERRAL]')}
            </div>
            <div>
              {t('Off-Duty Time Deferral set to "None"')}
            </div>
          </div>
        );
        break;
      case 201:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[OFF-DUTY TIME DEFERRAL]')}
            </div>
            <div>
              {t('Off-Duty Time Deferral set to "Day 1"')}
            </div>
          </div>
        );
        break;
      case 202:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[OFF-DUTY TIME DEFERRAL]')}
            </div>
            <div>
              {t('Off-Duty Time Deferral set to "Day 2"')}
            </div>
          </div>
        );
        break;
      case 211:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[CYCLE]')}
            </div>
            <div>
              {t('Cycle set to "Cycle 1"')}
            </div>
          </div>
        );
        break;
      case 212:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[CYCLE]')}
            </div>
            <div>
              {t('Cycle set to "Cycle 2"')}
            </div>
          </div>
        );
        break;
      case 221:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[OPERATING ZONE]')}
            </div>
            <div>
              {t('Operating Zone set to "South of Latitude 60°N in Canada"')}
            </div>
          </div>
        );
        break;
      case 222:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[OPERATING ZONE]')}
            </div>
            <div>
              {t('Operating Zone set to "North of Latitude 60°N in Canada"')}
            </div>
          </div>
        );
        break;
      case 223:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[OPERATING ZONE]')}
            </div>
            <div>
              {t('Operating Zone set to "United States"')}
            </div>
          </div>
        );
        break;
      case 231:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[ADDITIONAL HOURS NOT RECORDED]')}
            </div>
            <div>
              {t('Additional Hours not Recorded')}
            </div>
          </div>
        );
        break;
      default:
        html = (
          <div>
            <div className={styles.eventTypeCategory}>
              {t('[EVENT NOT RECOGNIZED]')}
            </div>
            <div>
              {t('Event not Recognized')}
            </div>
          </div>
        );
        break;
    }
    return html;
  }

  async resetShippingDocumentNumber(eldEvents) {
    const shippingDocumentNumbers = eldEvents.map((event) => [
      getAttribute(event, 'objectId'),
      [
        event,
        event.shippingDocumentNumber || getAttribute(event, 'shippingDocumentNumber'),
        false, // means it has not been editted
      ]]);
    const shippingDocumentNumberMap = Object.fromEntries(shippingDocumentNumbers);

    this.setState({ ...this.state, shippingDocumentNumberMap, showEditInputShipment: false });
    return shippingDocumentNumberMap;
  }

  async handleSaveEditToShippingDocumentNumber() {
    const shippingDocumentNumbers = Object.values(this.state.shippingDocumentNumberMap);
    shippingDocumentNumbers.map(async (eldEventData) => {
      if (!eldEventData[2]) return false;
      const company = getAttribute(eldEventData[0], 'company');
      await updateELDEventData(
        { sessionToken: getCurrentUserSessionToken() },
        getAttribute(company, 'objectId'),
        undefined,
        [getAttribute(eldEventData[0], 'objectId')],
        { shippingDocumentNumber: eldEventData[1] && compressWhitespace(eldEventData[1]) },
      );
      return true;
    });
    this.setState({ ...this.state, showEditInputShipment: false });
    this.props.updateHandler();
  }

  // getLocationDescriptionBreakdown = (locationDescription) => {
  //   // function to check if location description is of standard format
  //   // assumes locationDescription string exists
  //   // stringToReturn is the part of the locationDescription we want if we can get it
  //   const locationDescSplit = locationDescription.split(' ');
  //
  //   const breakdown = {
  //     distance: locationDescSplit[0] || '',
  //     direction: locationDescSplit[1] || '',
  //     stateProvince: locationDescSplit[2] || '',
  //     aprxShortName: '' // aka city
  //   };
  //
  //   // cases that break string formatting overall
  //   const isNotOverallValidLength = locationDescSplit.length < 4;
  //   const isNotDistanceValidLength = breakdown.distance.length < 3;
  //   if (!isNotDistanceValidLength) {
  //     // if distance seems to be a valid length, check if it looks right
  //     const distanceLen = breakdown.distance.length;
  //     const distanceUnit = (breakdown.distance[distanceLen - 2] + breakdown.distance[distanceLen - 1]).toLowerCase();
  //
  //     if (isNaN(breakdown.distance[0])) isNotDistanceValidLength = true; // very first char should be a number
  //     if ((distanceUnit !== 'mi') && (distanceUnit !== 'km')) isNotDistanceValidLength = true; // followed by distance unit
  //   }
  //   const isNotDirectionValidLength = breakdown.direction.length >= 4; // shouldnt be more than 4 chars long
  //   const isNotStateProvinceValidLength = breakdown.stateProvince.length !== 2;
  //
  //   if (isNotOverallValidLength || isNotDistanceValidLength || isNotDirectionValidLength || isNotStateProvinceValidLength) {
  //     Object.keys(breakdown).map(function (key) {
  //       breakdown[key] = '';
  //     });
  //     return breakdown;
  //   }
  //
  //   // All tests pass, it seems like this is the correct format for vehicle location description
  //   for (let i = 3; i < locationDescSplit.length; i++) {
  //     breakdown.aprxShortName += locationDescSplit[i] + ' ';
  //   }
  //
  //   breakdown.aprxShortName = breakdown.aprxShortName.trim();
  //
  //   return breakdown;
  // }

  processListAsCSV(csvString) {
    const { onDate, eldEvents } = this.props;

    // Obtain date and vehicleUnitId
    const dateForTitle = moment(onDate).format('MMMM YYYY');
    let unitIdForTitle = eldEvents[0] && eldEvents[0].get('vehicleUnitId');

    // In case of error, obtain vehicleUnitid from vehicle
    if (!unitIdForTitle) {
      unitIdForTitle = eldEvents[0] && eldEvents[0].get('vehicle') && eldEvents[0].get('vehicle').get('unitId');
    }

    // Create the csv file and allow client to download it
    Helpers.createCsvFile(`${unitIdForTitle} Switchboard - HOS Logs Summary (${dateForTitle})`, csvString, true);
  }

  async updateELDEventListSortType() {
    const eventListSortType = this.state.eventListSortType ? 0 : 1;
    this.setState({ ...this.state, eventListSortType });
    const dispatcher = await Getters.getCurrentDispatcher();
    return await updateRecord({ sessionToken: getCurrentUserSessionToken() }, dispatcher, { eldEventListSortType: eventListSortType }, true);
  }

  render() {
    const { driver, ELDEvent, eldDailyCertification, eldEvents, updateHandler, scaleToDriverTimezone, disableELDEditHistory, userSubscriptionPermission, styles } = this.props;
    const { filteredOdometerReadingVehicleMapObj, showEditInputShipment, shippingDocumentNumberMap } = this.state;
    let timezoneOffsetFromUTC = (scaleToDriverTimezone && driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();
    if (this.props.timezoneOffsetFromUTC && scaleToDriverTimezone) timezoneOffsetFromUTC = this.props.timezoneOffsetFromUTC;

    const useUnifiedOdometerReadings = eldEvents && eldEvents.length > 0 && moment(eldEvents[0].get('eventDateTime')).tz(timezoneOffsetFromUTC).isAfter(moment('2020-09-30'));

    // 'flattened' event list (removes successive duplicate event types)
    let eventsForList = [].concat(eldEvents); // already ascending order from edit simulation list
    const eventsForListRef = [].concat(eventsForList);
    const lastRecordedIntermediateEvents = []; // array[2] that holds last seen intermediate events 21, and 22 respectively

    const isAOBRDDay = ELD.isAOBRDEnabledByCount(eventsForList);

    // Initialize string for CSV download
    let csvString = `Unit,Status,Date/Time (${getTimezoneAbbreviation(getAttribute(eldDailyCertification, 'startTimeUTC', true), timezoneOffsetFromUTC)}),Location,City,State,Notes\n`;

    // the indicies of the first of each type of interm event we come across. this is to help figure out when the first event type has no location and therefore
    // we try to find the next event of same type that does
    // const firstIntermEventIndices = { 21: undefined, 22: undefined };
    // eventsForList = eventsForList.filter((event) => Helpers.isZeroVehicleLocation(event)).filter((event, index) => {
    let lastEldEventForList = null;
    eventsForList = eventsForList.filter((event) => Helpers.isZeroVehicleLocation(event)).filter((event, index) => {
      if (index > 0 && index !== eventsForList.length - 1) {
        const previousEvent = eventsForList[index - 1];
        const eldEventTypeCodeInt = event.get('eldEventTypeCodeInt');
        const previousELDEventTypeCodeInt = previousEvent.get('eldEventTypeCodeInt');

        const isDifferentStatus = eldEventTypeCodeInt !== previousELDEventTypeCodeInt;

        // IF-BLOCK: exception where we can show two of the same intermediate events simultaneously, only if they are 30min apart
        // if ((eldEventTypeCodeInt === 21 || eldEventTypeCodeInt === 22)) {
        if ((eldEventTypeCodeInt === 21 || eldEventTypeCodeInt === 22) && (previousELDEventTypeCodeInt === 21 || previousELDEventTypeCodeInt === 22)) {
          // const lastDigit = eldEventTypeCodeInt - 21; // returns 0 or 1
          // const vehicleLocation = event.get('vehicleLocation');

          // if (!firstIntermEventIndices[eldEventTypeCodeInt]) {
          //   firstIntermEventIndices[eldEventTypeCodeInt] = index;
          // }

          // const firstIntermIndex = firstIntermEventIndices[eldEventTypeCodeInt];

          // if (!lastRecordedIntermediateEvents[lastDigit]) {
          //   // if we never recorded one of this type, its the first so allow it to pass
          //   lastRecordedIntermediateEvents[lastDigit] = event;
          //   return true;
          // }

          // if (firstIntermIndex !== undefined && Helpers.isZeroVehicleLocation(eventsForListRef[firstIntermIndex].get('vehicleLocation')) && !Helpers.isZeroVehicleLocation(vehicleLocation)) {
          //   // the first interm event of this type had a 0,0 location and now we found the first one after it that has a legit location
          //   // lastRecordedIntermediateEvents[lastDigit] = event;
          //   firstIntermEventIndices[eldEventTypeCodeInt] = index; // we set the index to the valid location event so this if-clause never runs again
          //   return true;
          // }

          // if (moment(event.get('eventDateTime')).diff(lastRecordedIntermediateEvents[lastDigit].get('eventDateTime')) >= 1800000) {
          //   // otherwise, the interm event of this type exists and so we compare the time difference
          //   lastRecordedIntermediateEvents[lastDigit] = event;
          //   return true;
          // }
          if (moment(event.get('eventDateTime')).diff(lastEldEventForList.get('eventDateTime')) >= 1800000) {
            lastEldEventForList = event;
            return true;
          } else if (this.state.showAllEvents) {
            return true;
          }
          return false;
        }

        const isDifferentActive = event.get('eldEventRecordStatusInt') !== lastEldEventForList.get('eldEventRecordStatusInt');
        const containsNote = event.get('note');
        if (isDifferentStatus || isDifferentActive || containsNote) {
          lastEldEventForList = event;
          return true;
        } else if (this.state.showAllEvents) {
          return true;
        }
        return false;
      }
      lastEldEventForList = event;
      return true;
    });

    eventsForList.reverse();

    let isPastChosenDay = true;
    if (eldDailyCertification && driver) {
      const driverCurrentTime = moment().tz(timezoneOffsetFromUTC);
      isPastChosenDay = driverCurrentTime.valueOf() >= moment(eldDailyCertification.get('endTimeUTC')).valueOf();
    }

    const firstFoundCoDriverELDEvent = eventsForList.find(eldEvent => {
      const coDriver = eldEvent.get('coDriver');
      return coDriver;
    });

    const filteredELDEvents = this.filterELDEvents(eventsForList, this.state.eventListIndex, this.state.eventListEditIndex);
    let eldEventRows = filteredELDEvents.map((event, index) => {
      const nextEvent = filteredELDEvents[index + 1];
      const isEventLogin = event.get('eldEventTypeCodeInt') === 51;
      let isEventLoginAfterNonLogout = false; // is this a login event, but somehow it doesn't come after a logout event (ex. tablet was shut off without logging out)

      if (nextEvent && isEventLogin) {
        if (nextEvent.get('eldEventTypeCodeInt') !== 52) {
          isEventLoginAfterNonLogout = true;
        }
      }

      const vehicleLocation = event.get('vehicleLocation');

      const distanceUnit = Getters.getPDispatcherPropertyFromState('distanceUnit');
      let locationGeo;
      let locationDesc;
      let locationGeoForCSV;
      if (vehicleLocation && !vehicleLocation.get('location')) {
        // no gps points, somethings wrong -- usually happens when receiving events from an unfinished swap
        locationDesc = '*';
        locationGeo = '';
      }
      else if (vehicleLocation) {
        locationGeo = { ...vehicleLocation.get('location') };
        if (event.get('eldEventTypeCodeInt') === 31) { // according to eld rules...
          // decimal to a 100th
          locationGeo._latitude = locationGeo._latitude.toFixed(4);
          locationGeo._longitude = locationGeo._longitude.toFixed(4);
        } else {
          // decimal to a 10th
          locationGeo._latitude = locationGeo._latitude.toFixed(4);
          locationGeo._longitude = locationGeo._longitude.toFixed(4);
        }

        locationDesc = vehicleLocation.get('locationDescriptionUS') || '';
        if (vehicleLocation.get('countryCode') === 'ca') {
          locationDesc = vehicleLocation.get('locationDescriptionCA') || '';
        }

        const locationStatus = vehicleLocation.get('locationStatus');
        const isManualLocation = locationStatus && locationStatus.toLowerCase() === 'm';
        const isInvalidLocation = locationStatus && locationStatus.toLowerCase() === 'x';
        const isZeroVehicleLocation = Helpers.isZeroVehicleLocation(vehicleLocation);

        // if aobrd, we attempt to show only the city name -> fall back to reverse geocoder city name if none
        // if its a manual location, use the full locationdesc
        if (isAOBRDDay && locationDesc && !isManualLocation && (isAOBRDDay && this.state.locationDetailsListIndex === 0)) {
          const locationDescBreakdown = ELD.getLocationDescriptionBreakdown(locationDesc);
          let aprxShortName = locationDescBreakdown.aprxShortName;
          let stateProvince = locationDescBreakdown.stateProvince;

          if (!aprxShortName) {
            aprxShortName = vehicleLocation.get('aprxShortName') || '';
            stateProvince = vehicleLocation.get('stateProvince') || '';
          }

          locationDesc = aprxShortName + (stateProvince ? `, ${stateProvince.toUpperCase()}` : '');
        }

        locationGeoForCSV = locationGeo;

        locationGeo = <div>{`(${locationGeo._latitude}, ${locationGeo._longitude})`}</div>;
        if (isAOBRDDay) {
          locationGeo = '';
        } else if (isInvalidLocation) {
          locationGeo = <div>{'(X, X)'}</div>;
        } else if (isManualLocation) {
          locationGeo = <div>{'(M, M)'}</div>;
        } else if (isZeroVehicleLocation) {
          locationGeo = '';
        }
      }

      let odometerReading = (filteredOdometerReadingVehicleMapObj && filteredOdometerReadingVehicleMapObj.eventMap && filteredOdometerReadingVehicleMapObj.eventMap[event.id]) ? filteredOdometerReadingVehicleMapObj.eventMap[event.id] : event.get('totalVehicleKm') || 0;
      if (firstFoundCoDriverELDEvent) {
        odometerReading = event.get('totalVehicleKm') || 0;
      }

      if (useUnifiedOdometerReadings) {
        let odometerReadingsCorrection = this.props.unifiedOdometerReadingsWarnings && this.props.unifiedOdometerReadingsWarnings.INVALID_ODOMETER_READINGS;
        odometerReading = (odometerReadingsCorrection && odometerReadingsCorrection[event.id]) ? odometerReadingsCorrection[event.id].adjustedTotalVehicleKm : event.get('totalVehicleKm') || 0;
      }

      if (distanceUnit === 'mi') {
        odometerReading = Helpers.convertDistance(odometerReading, 'km', 'mi', true);
      }
      odometerReading = Math.floor(odometerReading) || '*';

      /**
       * finding out event durations
       */
      let durationString = '';
      const isLastEventOfFinishedDay = isPastChosenDay && (index === 0); // if this event is the last (most recent) event, and the day is finished
      const previousELDEvent = filteredELDEvents[index - 1];
      let lastRecordedEventDateTime;

      if (eldDailyCertification && driver) {
        if (isLastEventOfFinishedDay || (previousELDEvent && previousELDEvent.get('eventDateTime'))) {
          if (isLastEventOfFinishedDay) {
            lastRecordedEventDateTime = moment(eldDailyCertification.get('endTimeUTC')).tz(timezoneOffsetFromUTC).startOf('minute');
          } else {
            lastRecordedEventDateTime = moment(previousELDEvent.get('eventDateTime')).tz(timezoneOffsetFromUTC).startOf('minute');
          }

          const eventDurationMs = lastRecordedEventDateTime.diff(moment(event.get('eventDateTime')).tz(timezoneOffsetFromUTC).startOf('minute'), 'milliseconds');
          durationString = Helpers.msToTimeString(eventDurationMs, 'HH:mm');
          durationString = durationString.split(':');
          const hours = !durationString[1] ? '00' : durationString[0];
          const minutes = !durationString[1] ? durationString[0] : durationString[1];
          durationString = hours + 'H ' + minutes + 'M';
        }
      }

      // If the status is not a duty status, remove the duration
      if (this.getStatusFromEventTypeCode(event.get('eldEventTypeCodeInt')) === undefined) {
        durationString = '-';
      }

      // If the status is not duty status, don't include the row
      if (this.getStatusFromEventTypeCode(event.get('eldEventTypeCodeInt')) !== undefined) {
        // Generate row in CSV file
        csvString += `"${event.get('vehicleUnitId')}",`; // Unit
        csvString += `"${ELDEventTypeCodeIntDescriptions[event.get('eldEventTypeCodeInt')]}",`; // Status
        csvString += `"${moment(event.get('eventDateTime')).tz(timezoneOffsetFromUTC).format('MM/DD/YYYY HH:mm')}",`; // Start Date
        csvString += locationGeoForCSV !== undefined ? `"${locationGeoForCSV._latitude}, ${locationGeoForCSV._longitude}",` : `" ",`; // Location
        csvString += `"${locationDesc !== undefined ? ELD.getLocationDescriptionBreakdown(locationDesc).aprxShortName : ' '}",`; // City
        csvString += `"${locationDesc !== undefined ? ELD.getLocationDescriptionBreakdown(locationDesc).stateProvince : ' '}",`; // State
        csvString += `"${event.get('note') || ' '}"\n`; // Notes
      }

      return (
        (
          <div>
            <TableRow key={event.id}>
              <TableRowColumn className={`${styles.wrapColumn} verticalAlignMiddle`} style={{ width: '12%' }}>
                <span>{moment(event.get('eventDateTime')).tz(timezoneOffsetFromUTC).format('HH:mm')}</span>
                {event.get('eldEventTypeCodeInt') > 40 && event.get('eldEventTypeCodeInt') < 50 &&
                  (
                    <div className={`${styles.subDate}`}>
                      {moment(event.get('eventDateTime')).tz(timezoneOffsetFromUTC).format('ll')}
                    </div>
                  )
                }
              </TableRowColumn>
              <TableRowColumn className={`${styles.wrapColumn} verticalAlignMiddle`} style={{ width: '16%' }}>
                <div>{locationGeo}</div>
                <div>{locationDesc}</div>
                {(locationGeo || locationDesc) &&
                  <div style={{ marginBottom: '.5em' }} />
                }
                <div style={{ fontSize: '.82em' }}>
                  {t('Odometer:')} {odometerReading}
                </div>
              </TableRowColumn>
              <TableRowColumn className={`${styles.wrapColumn} verticalAlignMiddle`} style={{ width: '18%' }}>
                {this.getCategoryHTMLFromEventTypeCode(event.get('eldEventTypeCodeInt'), event.get('eldMalfunctionDataCodeCode'), styles)}

                {isEventLoginAfterNonLogout && (
                  <div style={{ marginTop: '.39em', fontSize: '.8em', color: '#E4B74E' }}>
                    <MDBIcon icon="exclamation-circle" style={{ marginRight: '.5em' }} />
                    <span>{t('Driver Login without Logout event')}</span>
                  </div>
                )}
              </TableRowColumn>
              <TableRowColumn className={`${styles.wrapColumn} verticalAlignMiddle`} style={{ width: '12%' }}>
                {durationString}
              </TableRowColumn>
              <TableRowColumn className={`${styles.wrapColumn} verticalAlignMiddle`} style={{ width: '10%' }}>
                {ELD.matchELDEventRecordStatus(event.get('eldEventRecordStatusInt'))}
              </TableRowColumn>
              <TableRowColumn className={`${styles.wrapColumn} verticalAlignMiddle`} style={{ width: '10%' }}>
                {showEditInputShipment ?
                  (
                    <InputText
                      type="text"
                      className="p-inputtext-sm"
                      style={{ width: '100%' }}
                      value={shippingDocumentNumberMap[event.id][1]}
                      onChange={(e) => this.handleEditShippingDocumentNumber(event, e.target.value)}
                    />
                  )
                  :
                  event.shippingDocumentNumber || event.get('shippingDocumentNumber') || '-'
                }
              </TableRowColumn>
              <TableRowColumn className={`${styles.wrapColumn} verticalAlignMiddle`} style={{ width: '14%' }}>
                {/* right now we cant let dispatcher edit events so the driver will have to upload notes only */}
                <textarea
                  value={event.get('note') || ''}
                  readOnly
                />
              </TableRowColumn>
              <TableRowColumn data-tip data-for={`disabledELDEditTooltip${event.id}`} style={{ width: '8%' }}>
                <div style={{ marginTop: '1em' }}>
                  <ELDEditModal
                    eldDailyCertification={eldDailyCertification}
                    eldEvent={event}
                    eldEventStore={ELDEvent}
                    userSubscriptionPermission={userSubscriptionPermission}
                    onSubmit={() => updateHandler()}
                  />
                </div>
              </TableRowColumn>
            </TableRow>
            {window.location.host.indexOf('app-support') !== -1 && !this.state.hideDebugValues &&
              (
                <TableRow>
                  [SUPPORT DEBUG DATA:]
                  <TableRowColumn>
                    <div><b>eldEvent Id</b>: {event.id}</div>
                    <div><b>totalVehicleKm</b>:</div>
                    <div>{event.get('totalVehicleKm') && Math.round(event.get('totalVehicleKm') * 10) / 10} (Offset: {event.get('odometerOffset') && Math.round(event.get('odometerOffset') * 10) / 10})</div>
                  </TableRowColumn>
                  <TableRowColumn style={{ whiteSpace: 'normal' }}>
                    <div><b>eldLastSyncValues</b></div>
                    <div style={{ fontSize: '0.5em' }}>
                      {event.get('eldLastSyncValues')}
                    </div>
                  </TableRowColumn>
                  <TableRowColumn>
                    <div><b>eldHardwareSerial</b>: {event.get('eldHardwareSerial')}</div>
                    <div><b>deviceIMEI</b>: {event.get('deviceIMEI')}</div>
                  </TableRowColumn>
                  <TableRowColumn>
                  </TableRowColumn>

                </TableRow>
              )
            }
          </div>
        )
      );
    });

    if (eldEventRows.length === 0) {
      eldEventRows = [
        <TableRow><TableRowColumn colSpan="69" className="emptyRowMessage verticalAlignMiddle">
          {t('No Events Logged This Day')}
        </TableRowColumn></TableRow>
      ];
    }

    // Sort in ascending order
    if (this.state.eventListSortType === 1) {
      eldEventRows.reverse();
    }

    return (
      <div className={styles.eldEvents}>
        <Title className="h3" title={<span>{t('Event List')}</span>} />
        <ActionsContainer>
          {window.location.host.indexOf('app-support') !== -1 &&
            <div className="inlineBlock">
              <Checkbox
                checked={this.state.hideDebugValues}
                onChange={e => this.setState({ ...this.state, hideDebugValues: e.checked })}
              />
              <InputLabel className="pt-1">Hide Debug Values</InputLabel>
              <Checkbox
                checked={this.state.showAllEvents}
                onChange={e => this.setState({ ...this.state, showAllEvents: e.checked })}
              />
              <InputLabel className="pt-1">Show All Events</InputLabel>
            </div>
          }
          <MDBBtn
            size="sm"
            color="secondary"
            onClick={() => this.processListAsCSV(csvString)}
            style={{ width: '14em', marginRight: '.5em' }}
          ><MDBIcon icon="file-csv" className="mr-2" />{t('Download CSV')}</MDBBtn>
          <DefaultShippingDocumentNumberPopup
            className="inline-block"
            eldDailyCertification={eldDailyCertification}
          />
          <FilterDropdown
            id="eventsFilterDropdown"
            className={styles.eventsListFilterDropdown}
            title={t('Duty Status View')}
            handleFilter={this.handleEventListDropdownView}
            menuItems={this.state.eventListFilterFields}
          />
          {!disableELDEditHistory &&
            <FilterDropdown
              id="editEventsFilterDropdown"
              className={styles.eventsListFilterDropdown}
              title={t('Hide Edit History')}
              handleFilter={this.handleEventListEditDropdownView}
              menuItems={this.state.eventListEditFilterFields}
            />
          }
          {isAOBRDDay &&
            <FilterDropdown
              id="locationDetailsFilterDropdown"
              className={styles.eventsListFilterDropdown}
              title={t('Hide Location Details')}
              handleFilter={(locationDetailsListIndex) => this.setState({ ...this.state, locationDetailsListIndex: parseFloat(locationDetailsListIndex) })}
              menuItems={this.state.locationDetailsFilterFields}
            />
          }
        </ActionsContainer>

        {this.props.unifiedOdometerReadingsWarnings && this.props.unifiedOdometerReadingsWarnings.STUCK_ODOMETER_READING && (
          <IconLabel
            className="warning-label"
            iconClassName="pi pi-exclamation-circle amber-text"
          >
            {`${t('Unchanged Odometer Readings Detected - May be due to connectivity with the engine')}`}
          </IconLabel>
        )}

        <Table
          wrapperStyle={{ minWidth: '800px' }}
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          >
            <TableRow style={{ height: 'auto' }}>
              <TableHeaderColumn style={{ width: '12%' }}>
                <div className='flex align-items-center cursor-pointer' onClick={() => this.updateELDEventListSortType()}>
                  <div>
                    {t(`Time (${getTimezoneAbbreviation(getAttribute(eldDailyCertification, 'startTimeUTC', true), timezoneOffsetFromUTC)})`)}
                  </div>
                  <span className="material-icons pl-2 text-lg ">{this.state.eventListSortType ? 'arrow_upward' : 'arrow_downward'}</span>
                </div>
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: '16%' }}>
                {t('Location')}
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: '18%' }}>
                {t('Event Type')}
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: '12%' }}>
                {t('Duration')}
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: '10%' }}>
                {t('Event Status')}
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: '10%' }}>
                {showEditInputShipment ?
                  (
                    <div>
                      <Button icon="pi pi-times" className="p-button-rounded p-button-danger p-button-text p-button-sm " onClick={() => this.resetShippingDocumentNumber(eldEvents)} />
                      <Button icon="pi pi-save" className="p-button-rounded p-button-success p-button-text p-button-sm " onClick={() => this.handleSaveEditToShippingDocumentNumber()}></Button>
                    </div>
                  )
                  :
                  (
                    <div className="d-inline-flex d-flex-row align-items-center">
                      {t('Shipping ID')}
                      {/*<Button icon="pi pi-pencil" className="p-button-rounded p-button-info p-button-text p-button-sm" onClick={() => this.setState({ ...this.state, showEditInputShipment: true })} />*/}
                    </div>
                  )
                }
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: '14%' }}>
                {t('Driver\'s Note')}
              </TableHeaderColumn>
              <TableHeaderColumn style={{ width: '8%' }}>
                {t('Options')}
              </TableHeaderColumn>
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={false}
            showRowHover
          >
            {eldEventRows}
          </TableBody>
        </Table>
      </div>
    );
  }

}

EventsList.propTypes = {
  driver: PropTypes.object,
  eldDailyCertification: PropTypes.object,
  ELDEvent: PropTypes.object,
  eldEvents: PropTypes.array,
  updateHandler: PropTypes.func,
  scaleToDriverTimezone: PropTypes.bool,
  disableELDEditHistory: PropTypes.bool,
  userSubscriptionPermission: PropTypes.any,
  debug: PropTypes.bool,
  styles: PropTypes.any,
};

export default EventsList;
