import React, { useState, useEffect } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';

// CSAPI API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// API
import { getDispatchPayables } from 'api/Dispatch/DispatchPayable';

// CSAPI Enums
import { Payee } from 'sb-csapi/dist/enums/Dispatch/Payee';
import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';
import { PaymentStatus } from 'sb-csapi/dist/enums/Dispatch/PaymentStatus';

// CSAPI sbObjects
import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// sbCore Components
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import PaymentStatusBadge from 'sbCore/PaymentStatusBadge/PaymentStatusBadge';
import InputTextarea from 'sbCore/InputTextarea/InputTextarea';

// Components
import PayablesDrawer from 'components/Dispatch/AccountingPayablesTable/PayablesDrawer/PayablesDrawer';
import PayableTypeBadge from 'components/Dispatch/PayableTypeBadge/PayableTypeBadge';
import PaymentStatusMultiSelect from 'components/Dispatch/PaymentStatusMultiSelect/PaymentStatusMultiSelect';
import PaymentTermMultiSelect from 'components/Dispatch/PaymentTermMultiSelect/PaymentTermMultiSelect';

// Styles
import './style.scss';

/**
 * @description Renders a Payables table which represents Payees that need to be paid/was paid
 * @returns
 */
function AccountingPayablesTable({ ...props }) {

  // ** useStates ** //
  const [dispatchPayableTableObjectsArr, setDispatchPayableTableObjectsArr] = useState([]);
  const [totalDispatchPayablesCount, setTotalDispatchPayablesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedPayable, setSelectedPayable] = useState(null);

  const [lazyLoadingParams, setLazyLoadingParams] = useState({
    first: 0,
    page: 0,
    rows: 20,
    filters: {
      statusInt: { value: [PaymentStatus.UNPAID, PaymentStatus.PARTIALLY_PAID], matchMode: 'equals' },
      paymentTermType: { value: undefined, matchMode: 'equals' },
    },
  });

  // ** useEffects ** //
  // Whenever a new page is rendered, fill the table with the relevant DispatchPayables
  useEffect(() => {
    let didCancel = false;

    async function refresh() {
      const { dispatchPayableFilters } = await getFilters();
      _getDispatchPayables(dispatchPayableFilters);
    }

    async function _getDispatchPayables(dispatchPayableFilters) {
      const _dispatchPayableTableObjectsArr = [];
      const { totalDispatchPayablesCount, dispatchPayables } = await getDispatchPayables(
        undefined, // options - default
        undefined, // company object id - default
        dispatchPayableFilters, // filters
        [new Sort(QuerySortOrder.ASCENDING, 'status'), new Sort(QuerySortOrder.ADD_ASCENDING, 'invoiceDateTime')], // sort
        ['dispatchPayee', 'status', 'dispatchJobAccounting', 'dispatchJobAccounting.dispatchJob', 'dispatchJobAccounting.dispatchJob.customerDispatchOrganization', 'dispatchDocumentInvoice'], // includes
        undefined, // selects
        lazyLoadingParams.page, // page
        lazyLoadingParams.rows, // limit
        false, // query all
      );

      // Create dispatch payable table objects to be used in the datatable
      dispatchPayables.forEach((dispatchPayable) => {
        if (!dispatchPayable) return;
        const dispatchPayee = getAttribute(dispatchPayable, 'dispatchPayee');
        let nameCode;
        let typeInt;
        if (dispatchPayee) {
          const name = getAttribute(dispatchPayee, 'name');
          const code = getAttribute(dispatchPayee, 'code');
          typeInt = getAttribute(dispatchPayee, 'type');

          switch (typeInt) {
            case Payee.DRIVER.type:
              if (name && code) {
                nameCode = `${name} (${code})`;
              } else if (name) {
                nameCode = name;
              } else if (code) {
                nameCode = `(${code})`;
              }
              break;
            case Payee.COMPANY.type:
              nameCode = code;
              break;
            default:
          }
        }

        const statusInt = getAttribute(dispatchPayable, 'status');
        const paymentTermType = getAttribute(dispatchPayable, 'paymentTermType');
        const invoiceDateTime = getAttribute(dispatchPayable, 'invoiceDateTime');
        const notes = getAttribute(dispatchPayable, 'notes');
        const isVoided = getAttribute(dispatchPayable, 'isVoided');
        const invoiceDaysOutstanding = getInvoiceDaysOutstanding(invoiceDateTime);

        const dispatchPayableTableObject = {
          dispatchPayable,
          name: nameCode || '-',
          typeInt,
          statusInt,
          paymentTermType: paymentTermType || '-',
          isVoided,
          invoiceDateTime: invoiceDateTime ? (momentTz(invoiceDateTime)).tz('America/Vancouver').format('DD-MM-YYYY HH:mm') : '-',
          invoiceDaysOutstanding,
          notes: notes || '-',
        };

        _dispatchPayableTableObjectsArr.push(dispatchPayableTableObject);
      });

      if (!didCancel) {
        setDispatchPayableTableObjectsArr(_dispatchPayableTableObjectsArr);
        setTotalDispatchPayablesCount(totalDispatchPayablesCount);
        setIsLoading(false);
      }
    }

    async function getFilters() {
      const datatableFilters = lazyLoadingParams.filters;

      // Don't display the hidden/voided payables
      const dispatchPayableFilters = [new Filter(QueryRestriction.NOT_EQUAL_TO, 'isHidden', true), new Filter(QueryRestriction.NOT_EQUAL_TO, 'isVoided', true)];

      Object.entries(datatableFilters).forEach(async ([key, filter]) => {
        if (filter.value === null || filter.value === '') return;
        if (key === 'statusInt') {
          const paymentStatuses = filter.value;
          if (paymentStatuses.length > 0) {
            const paymentStatusesInt = paymentStatuses.map((paymentStatus) => paymentStatus.status);
            dispatchPayableFilters.push(new Filter(QueryRestriction.CONTAINED_IN, 'status', paymentStatusesInt));
          }
        }

        if (key === 'paymentTermType') {
          const paymentTermTypes = filter.value;
          if (paymentTermTypes && paymentTermTypes.length > 0) {
            dispatchPayableFilters.push(new Filter(QueryRestriction.CONTAINED_IN, 'paymentTermType', paymentTermTypes));
          }
        }
      });

      return { dispatchPayableFilters };
    }

    refresh();
    return () => { didCancel = true; };
  }, [lazyLoadingParams, selectedPayable]);

  // ** Helper Functions ** //
  // Return the number of days since the invoice has been sent and hasn't been fully paid
  function getInvoiceDaysOutstanding(invoiceDateTime) {
    if (!invoiceDateTime) return null;
    const currentDateTime = momentTz();

    const duration = moment.duration(currentDateTime.diff(momentTz(invoiceDateTime)));
    const differenceInDays = Math.floor(duration.asDays());

    return differenceInDays;
  }

  // ** DataTable Templates ** //
  function paymentStatusTemplate(rowData) {
    return (
      <PaymentStatusBadge
        className="z-depth-0"
        paymentStatusInt={rowData.statusInt}
      // isVoided={rowData.isVoided}
      />
    );
  }

  function nameCodeTemplate(rowData) {
    return (
      <div>{rowData.name}</div>
    );
  }

  function typeTemplate(rowData) {
    return (
      <PayableTypeBadge className="z-depth-0" typeInt={rowData.typeInt} />
    );
  }

  function invoiceDateTimeBodyTemplate(rowData) {
    let invoiceDaysOutstanding = rowData.invoiceDaysOutstanding ? ` (${rowData.invoiceDaysOutstanding} days past due)` : '';
    if ((rowData.paymentStatusInt === PaymentStatus.PAID.status) || rowData.invoiceDaysOutstanding < 0) invoiceDaysOutstanding = '';
    return (
      <div className="flex">
        {rowData.invoiceDateTime}
        {invoiceDaysOutstanding}
      </div>
    );
  }

  function paymentTermTemplate(rowData) {
    const paymentTermString = rowData.paymentTermType !== '-' ? `${rowData.paymentTermType} days` : rowData.paymentTermType;
    return (
      <div>{paymentTermString}</div>
    );
  }

  function notesBodyTemplate(rowData) {
    return (
      <InputTextarea
        className="text-sm notes-body"
        value={rowData.notes}
        rows={5}
        cols={30}
        disabled
        autoResize
      />
    );
  }

  // ** DataTable Filters ** //
  function statusFilter(options) {
    return (
      <PaymentStatusMultiSelect
        selectedPaymentStatuses={options.value}
        onChange={(value) => options.filterApplyCallback(value)}
      />
    );
  }

  function paymentTermFilter(options) {
    return (
      <PaymentTermMultiSelect
        selectedPaymentTerms={options.value}
        onChange={(value) => options.filterApplyCallback(value)}
      />
    );
  }

  // ** DataTable Callback Functions ** //

  // Updates table when new page is selected
  function onPage(event) {
    setIsLoading(true);
    setLazyLoadingParams(event);
  }

  function renderHeader() {
    return (
      <div className="flex justify-content-between align-items-center">
        <h5 className="m-0 text-2xl font-semibold">Payables</h5>
      </div>
    );
  }

  // Triggers when the filter is updated
  function onFilter(event) {
    event.first = 0;
    setLazyLoadingParams(event);
  }

  // ** Misc ** //
  let className = 'accounting-payables-table card mt-3';
  if (props.className) className += ` ${props.className}`;

  // ** Components ** //
  const dispatchPayablesTable = (
    <DataTable
      value={dispatchPayableTableObjectsArr}
      header={() => renderHeader()}
      responsiveLayout="scroll"
      emptyMessage="No Payables Found"
      loading={isLoading}
      rowHover
      // Table Pagination Props
      paginator
      rows={lazyLoadingParams.rows}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      // Lazy Loading Props
      lazy
      first={lazyLoadingParams.first}
      totalRecords={totalDispatchPayablesCount}
      onPage={onPage}
      // Selection Props
      selectionMode="single"
      selection={selectedPayable}
      onSelectionChange={e => setSelectedPayable(e.value)}
      // Filter Props
      filterDisplay="row"
      onFilter={(event) => onFilter(event)}
      filters={lazyLoadingParams.filters}
    >
      <Column
        header="Payment Status"
        body={paymentStatusTemplate}
        className="payment-status-filter-dropdown"
        field="statusInt"
        style={{ minWidth: '16rem' }}
        filter
        filterElement={statusFilter}
        showClearButton={(![undefined, null, -1].includes(lazyLoadingParams.filters.statusInt.value))}
      />
      <Column
        body={typeTemplate}
        header="Payable Type"
        style={{ minWidth: '16rem' }}
      />
      <Column
        body={nameCodeTemplate}
        header="Name"
        style={{ minWidth: '16rem' }}
      />
      <Column
        field={invoiceDateTimeBodyTemplate}
        header="Date of Invoice"
        style={{ minWidth: '16rem' }}
      />
      <Column
        header="Payment Term"
        body={paymentTermTemplate}
        className="payment-term-filter-dropdown"
        field="paymentTermType"
        style={{ minWidth: '16rem' }}
        filter
        filterElement={paymentTermFilter}
        showClearButton={(![undefined, null, -1].includes(lazyLoadingParams.filters.paymentTermType.value))}
      />
      <Column
        field={notesBodyTemplate}
        header="Notes"
        style={{ minWidth: '24rem' }}
      />
    </DataTable>
  );

  const payablesDrawer = (
    <PayablesDrawer
      dispatchPayable={selectedPayable && selectedPayable.dispatchPayable}
      isDrawerOpen={selectedPayable}
      closeDrawer={() => setSelectedPayable(null)}
    />
  );

  return (
    <div className={className}>
      {dispatchPayablesTable}
      {payablesDrawer}
    </div>
  );
}

export default AccountingPayablesTable;
