import React, { useState } from 'react';

// Components
import AccountingReceivablesTable from 'components/Dispatch/AccountingReceivablesTable/AccountingReceivablesTable';
import AccountingPayablesTable from 'components/Dispatch/AccountingPayablesTable/AccountingPayablesTable';

// sbCore Components
import TabMenu from 'sbCore/TabMenu/TabMenu';

// Styles
import './style.scss';

/**
 * @description Provides a tab menu to change views between the receivables and payables view
 * @returns
 */
function AccountingView({ ...props }) {

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabMenuItems = [
    { label: 'Receivables' },
    { label: 'Payables' },
  ];

  return (
    <div className="accounting-receivables-payables-tab-menu">
      <TabMenu model={tabMenuItems} activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)} />

      {activeTabIndex === 0 && <AccountingReceivablesTable />}

      {activeTabIndex === 1 && <AccountingPayablesTable />}
    </div>
  );
}

export default AccountingView;
