import Parse from 'parse';
import moment from 'moment-timezone';

import { setQueryRestriction, setParseObject } from 'api/Parse';
import { getCurrentUser, getAllFromQuery } from 'api/Getters';
import * as ELD from 'api/ELD';
import * as Helpers from 'api/Helpers';

import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { AttributeTypes } from 'enums/Driver';

import ELDEvent from 'sbObjects/ELDEvent';

/**
 * @memberof module:DriverPattern
 * @description get speeding data (driving against the speed limit) for each driver
 *
 * @param {array} driverObjectIds
 */
async function getSpeedingDataByDriver(driverObjectIds = []) {
  const speedingDataByDriver = {};
  if (driverObjectIds.length === 0) return speedingDataByDriver;

  // since all we have for speeding data (for now) is on eld event...
  const driverQuery = new Parse.Query('Driver');
  setQueryRestriction(driverQuery, QueryRestrictionTypes.CONTAINED_IN, 'objectId', driverObjectIds);

  driverQuery[QuerySortOrderTypes.DESCENDING](AttributeTypes.USER_FULLNAME);
  driverQuery.include(['latestELDEvent']);

  const dbDrivers = await getAllFromQuery(driverQuery);

  dbDrivers.map(driver => {
    const latestELDEvent = new ELDEvent();
    const dbLatestELDEvent = driver.get('latestELDEvent');
    if (dbLatestELDEvent) {
      latestELDEvent.speedKm = dbLatestELDEvent.get('speedKm');
    }

    if (!speedingDataByDriver[driver.id]) {
      speedingDataByDriver[driver.id] = {};
    }

    if (!speedingDataByDriver[driver.id].speedLimitKm) {
      speedingDataByDriver[driver.id] = undefined;
    } else {
      speedingDataByDriver[driver.id].speedLimitKm = undefined;
      speedingDataByDriver[driver.id].speedKm = latestELDEvent.speedKm;
    }
  });

  console.log(speedingDataByDriver);
  return speedingDataByDriver;
}

export {
  getSpeedingDataByDriver,
};
