import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import * as MB from 'api/Mapbox';
import * as Getters from 'api/Getters';
import * as IFTA from 'api/IFTA';
import { sortELDEvents } from 'api/ELD';

import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

import SBTable from 'components/Shared/SBTable/SBTable';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

import {
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

import styles from './VehicleMileagesSummary.module.scss';



class VehicleMileagesIntervalSummary extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fetchAttributesFilters: [
        new Filter('vehicleUnitId', props.vehicle ? props.vehicle.get('unitId') : props.vehicleUnitId, QueryRestrictionTypes.EQUAL_TO),
        new Filter('company', props.vehicle ? props.vehicle.get('belongsToCompany') : Getters.getCurrentUserCompany(), QueryRestrictionTypes.EQUAL_TO),
        new Filter('eventDateTime', props.iftaInterval.start, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO),
        new Filter('eventDateTime', props.iftaInterval.end, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO),
        new Filter('eldEventTypeCodeInt', [11, 12, 13, 14, 31, 32, 51, 52], QueryRestrictionTypes.CONTAINED_IN),
        new Filter('vehicleLocation', null, QueryRestrictionTypes.EXISTS),
      ],
      fetchAttributesFiltersFull: [
        new Filter('vehicleUnitId', props.vehicle ? props.vehicle.get('unitId') : props.vehicleUnitId, QueryRestrictionTypes.EQUAL_TO),
        new Filter('company', props.vehicle ? props.vehicle.get('belongsToCompany') : Getters.getCurrentUserCompany(), QueryRestrictionTypes.EQUAL_TO),
        new Filter('eventDateTime', props.iftaInterval.start, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO),
        new Filter('eventDateTime', props.iftaInterval.end, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO),
        new Filter('eldEventTypeCodeInt', [11, 12, 13, 14, 21, 22, 31, 32, 51, 52, 61, 62, 63, 64], QueryRestrictionTypes.CONTAINED_IN),
        new Filter('vehicleLocation', null, QueryRestrictionTypes.EXISTS),
      ],
      fetchAttributes: {
        page: 0,
        count: 20,
        sortBy: new Sort('eventDateTime', QuerySortOrderTypes.ASCENDING),
        filters: [
          new Filter('vehicleUnitId', props.vehicle ? props.vehicle.get('unitId') : props.vehicleUnitId, QueryRestrictionTypes.EQUAL_TO),
          new Filter('company', props.vehicle ? props.vehicle.get('belongsToCompany') : Getters.getCurrentUserCompany(), QueryRestrictionTypes.EQUAL_TO),
          new Filter('eventDateTime', props.iftaInterval.start, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO),
          new Filter('eventDateTime', props.iftaInterval.end, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO),
          new Filter('eldEventTypeCodeInt', [11, 12, 13, 14, 31, 32, 51, 52], QueryRestrictionTypes.CONTAINED_IN),
          new Filter('vehicleLocation', null, QueryRestrictionTypes.EXISTS),
        ],
      },
      pageCount: 1,
      isLoading: false,
      isLazyLoading: false,
      hasLoadedAll: false,
      eldEvents: [],
    };

    this.getIFTAVehicleLocationsByInterval = this.getIFTAVehicleLocationsByInterval.bind(this);
    this.handlePaginatorOnChange = this.handlePaginatorOnChange.bind(this);
  }

  componentDidMount = () => {
    this.getIFTAVehicleLocationsByInterval();
  }

  async getIFTAVehicleLocationsByInterval() {
    const { fetchAttributes } = this.state;
    await this.setState({ ...this.state, eldEvents: [], isLoading: true });
    const { eldEvents, totalELDEventsCount } = await IFTA.getIFTAVehicleLocationsByInterval(fetchAttributes.page, fetchAttributes.count, fetchAttributes.sortBy, fetchAttributes.filters);
    const pageCount = Math.ceil(totalELDEventsCount / fetchAttributes.count) || 1;
    let startEldEvent;
    let endEldEvent;
    if (fetchAttributes.page === 0) {
      const startEldEvents = (await IFTA.getIFTAVehicleLocationsByInterval(0, 1, fetchAttributes.sortBy, this.state.fetchAttributesFiltersFull)).eldEvents;
      if (startEldEvents && startEldEvents.length > 0) startEldEvent = startEldEvents[0];
    } else if (pageCount === fetchAttributes.page + 1) {
      const endEldEvents = (await IFTA.getIFTAVehicleLocationsByInterval(0, 1, new Sort('eventDateTime', QuerySortOrderTypes.DESCENDING), this.state.fetchAttributesFiltersFull)).eldEvents;
      if (endEldEvents && endEldEvents.length > 0) endEldEvent = endEldEvents[0];
    }
    if (eldEvents.length === 0 || (startEldEvent && startEldEvent.id !== eldEvents[0].id)) eldEvents.unshift(startEldEvent);
    if (eldEvents.length === 0 || (endEldEvent && endEldEvent.id !== eldEvents[eldEvents.length - 1].id)) eldEvents.push(endEldEvent);
    const newState = { ...this.state, isLoading: false, eldEvents };
    if (pageCount !== newState.pageCount) newState.pageCount = pageCount;
    return await this.setState(newState);
  }

  handlePaginatorOnChange(type, inputValue) {
    const { fetchAttributes, pageCount } = this.state;
    const newState = { ...this.state };
    const updatedFetchAttributes = { ...fetchAttributes };
    newState.fetchAttributes = updatedFetchAttributes;

    if (type === 'PREVIOUS') {
      updatedFetchAttributes.page--;
      if (updatedFetchAttributes.page < 0) updatedFetchAttributes.page = 0; // don't let values before 0
    } else if (type === 'NEXT') {
      updatedFetchAttributes.page++;
      if (updatedFetchAttributes.page >= pageCount - 1) updatedFetchAttributes.page = pageCount - 1;
    } else if (type === 'SUBMIT') {
      let page = inputValue - 1; // the actual value is one less, since we pass +1 page in the component (so paging doesnt start at 0)

      if (page < 0) {
        page = 0;
      } else if (page >= (pageCount - 1)) {
        page = pageCount - 1;
      }
      updatedFetchAttributes.page = page;
    }

    this.setState(newState, () => {
      this.getIFTAVehicleLocationsByInterval();
    });
  }

  render() {
    const { open, handleClose, iftaInterval } = this.props;
    const { eldEvents, isLoading, pageCount, isLazyLoading, fetchAttributes } = this.state;

    const filteredELDEvents = eldEvents.filter(eldEvent => eldEvent.get('vehicleLocation') && eldEvent.get('vehicleLocation').get('stateProvince') && eldEvent.get('vehicleLocation').get('location'));

    const tableHeaderStyles = {
      dateTime: { width: '50%' },
      location: { width: '50%' },
    };
    const tableHeaderRows = [{
      key: 'sb-locations-list',
      columns: [
        {
          element: <div>Date/Time (Local)</div>,
          props: {
            style: tableHeaderStyles.dateTime,
            isSortActive: true,
          },
        },
        {
          element: <div>Location</div>,
          props: {
            style: tableHeaderStyles.location,
          },
        },
      ],
    }];


    let tableBodyRows = [];
    if (filteredELDEvents.length > 0) {
      tableBodyRows = filteredELDEvents.map((eldEvent, index) => {
        const rowObj = {
          key: eldEvent.objectId,
          columns: [],
          props: { style: { fontSize: '.85em' } },
        };

        rowObj.columns = [
          { element: <span><b>{ moment(eldEvent.get('eventDateTime')).format('hh:mm a') }</b> { moment(eldEvent.get('eventDateTime')).format('/ MMMM D YYYY') }</span>, props: { className: 'table-body-column-style' } },
          {
            element: (
              <span>
                {`${eldEvent.get('vehicleLocation').get('aprxShortName')}, ${eldEvent.get('vehicleLocation').get('stateProvince').toUpperCase()}`}
                <a
                  href={`https://maps.google.com/maps?q=${eldEvent.get('vehicleLocation').get('location').latitude},${eldEvent.get('vehicleLocation').get('location').longitude}&t=h&iwd=0&z=18`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="text-blue-sea" style={{ fontSize: '.8em', fontWeight: '400', textDecoration: 'underline' }}>
                    {`(${eldEvent.get('vehicleLocation').get('location').latitude},  ${eldEvent.get('vehicleLocation').get('location').longitude})`}
                  </div>
                </a>
              </span>
              ),
          },
        ];

        return rowObj;
      });
    }

    return (
      <div className={styles.iftaVehicleSummary}>
        <div>
          <Dialog
            title={
              `Vehicle Locations (${moment(iftaInterval.start).format('MMM D, YYYY')} - ${moment(iftaInterval.end).format('MMM D, YYYY')})`
            }
            actions={[
              <FlatButton
                label="Close"
                primary
                onClick={() => handleClose()}
              />,
            ]}
            modal
            contentStyle={{ width: '70%', maxWidth: 'none' }}
            open={open}
            autoScrollBodyContent
          >
            <SBTable
              hover
              tableHeaderRows={tableHeaderRows}
              tableBodyRows={tableBodyRows}
              showPaginator
              activePage={fetchAttributes.page + 1}
              pageCount={pageCount}
              handlePaginatorOnChange={this.handlePaginatorOnChange}
              isLoading={isLoading}
              emptyTableMessage={
                <div className="text-center">No Locations Found</div>
              }
              paginatorContainerClassName="paginator-container"
            />
          </Dialog>
        </div>
      </div>
    );
  }
}

VehicleMileagesIntervalSummary.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  iftaInterval: PropTypes.object,
  vehicle: PropTypes.object,
  vehicleUnitId: PropTypes.string,
};

export default VehicleMileagesIntervalSummary;
