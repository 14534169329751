/** @module APIAuth */

// API
import {
  createQuery, findRecords, updateRecord,
  getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, getCurrentUser, getRecordByObjectId,
} from 'sb-csapi/dist/AAPI';

// Enums
import { QuerySortOrder, QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';

// Sort
import Sort from 'sb-csapi/dist/sbObjects/Sort';

// Filter
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @memberof module:APIAuth
 * @description Obtains APIAuth records for a given user within the company
 *
 * @param {object} [options] - Object containing the session token to use for requests
 * @param {array} [filters] - Array of Filter objects
 * @param {object} [sortBy] - Sort object
 * @param {array} [includedPointers] - Included pointers
 * @param {array} [selectedAttributes] - Select attributes to return
 *
 * @returns {object} - { apiAuths: [] }
 */
async function getAPIAuths(options = { sessionToken: getCurrentUserSessionToken }, filters = [], sortBy = new Sort(QuerySortOrder.DESCENDING, 'createdAt'), includedPointers = [], selectedAttributes = []) {
  const currentUser = getCurrentUser();
  const companyObjectId = getCurrentUserCompanyObjectId();

  const _filters = [...filters];

  const apiAuthQuery = createQuery('APIAuth');
  setQueryRestriction(apiAuthQuery, QueryRestrictionTypes.EQUAL_TO, 'generatedByUser', currentUser);
  setQueryRestriction(apiAuthQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', companyObjectId);
  setQueryRestriction(apiAuthQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'isHidden', true);

  // Set query restrictions from filter
  _filters.map(filter => setQueryRestriction(apiAuthQuery, filter.queryRestriction, filter.attribute, filter.value));

  if (includedPointers.length > 0) includePointers(apiAuthQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(apiAuthQuery, selectedAttributes);

  // Sort the APIAuth records by the sortBy
  sortQuery(apiAuthQuery, sortBy.order, sortBy.attribute);

  // Find all APIAuth records for the given user
  const promises = [findRecords(options, apiAuthQuery, false, true)];

  try {
    const [apiAuths] = await Promise.all(promises);
    return { apiAuths };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:APIAuth
 *
 * @description Update a APIAuth record
 *
 * @param {APIAuth} apiAuth - The APIAuth record to update. If not available, use parameter apiAuthObjectId instead
 * @param {string} apiAuthObjectId - The objectId of the APIAuth record we wish to update. Use if parameter apiAuth is unavailable
 * @param {object} keyValueObj - The select fields we wish to update
 * @param {bool} save - If true, executes save on the changes. If false, holds the changes locally but is not saved
 *
 * @returns {APIAuth} - The updated APIAuth
 */
async function updateAPIAuth(apiAuth, apiAuthObjectId, keyValueObj, save) {
  if (!apiAuth && !apiAuthObjectId) throw new Error('Error: Must provide apiAuth or apiAuthObjectId');
  if (apiAuth && apiAuthObjectId) throw new Error('Error: Must provide only one of apiAuth or apiAuthObjectId');

  let _apiAuth = apiAuth;

  try {
    if (!apiAuth && apiAuthObjectId) {
      _apiAuth = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'APIAuth', apiAuthObjectId);
    }

    if (_apiAuth) {
      if (!Object.keys(keyValueObj).length) return _apiAuth;

      _apiAuth = await updateRecord({ sessionToken: getCurrentUserSessionToken() }, _apiAuth, keyValueObj, save);
      return _apiAuth;
    } else {
      throw new Error('APIAuth does not exist');
    }
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getAPIAuths,
  updateAPIAuth,
};
