import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import history from "../../sbHistory";

import { Card, CardActions, CardHeader, CardMedia, CardTitle, CardText } from 'material-ui/Card';

// API
import { queryCompanyObjects } from 'api/Getters';

// Components
import SimTable from 'components/SimTable/container/SimTable';
import ToggleSimModal from 'layouts/SettingsLayout/DeviceLayout/ToggleSimModal';

// CSS
import styles from './DataLayout.module.scss';

// Lists

class DataLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showToggleSimModal: false,
    };
  }

  componentDidMount() {
  }

  render() {
    return (
      <div>
        <div>
          <div className="col-md-12">
            <br />
          </div>

          <div className="col-md-12">
            <Card className="translate-me">
              <CardHeader
                title="Switchboard Devices"
              />
              <CardText>
                <div>
                  <br />

                  <b>Active Devices on Switchboard: </b>
                  <kbd className="notranslate">{ this.props.simArr.filter((sim) => sim.get('isActive')).length }</kbd>
                  <button className="buttonDefault floatRight" onClick={() => this.setState({ ...this.state, showToggleSimModal: true })}>Activate Device</button>

                  <div>
                    <ToggleSimModal
                      show={this.state.showToggleSimModal}
                      handleClose={() => this.setState({ ...this.state, showToggleSimModal: false })}
                    />
                  </div>

                </div>

                <br />
                <SimTable
                  simArr={this.props.simArr}
                />
              </CardText>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

DataLayout.propTypes = {
  simArr: PropTypes.array,
};

export default DataLayout;
