import React, { useState, useContext } from 'react';

// sbCore
import useToastContext from 'hooks/ToastHook';
import Button from 'sbCore/Button/Button';
import ToastContent from 'sbCore/ToastContent/ToastContent';

// Styles
import './style.scss';

// context
import DispatchJobLayoutContext from 'contexts/DispatchJobLayoutContext';

// enums
import { Document as DocumentTypes } from 'sb-csapi/dist/enums/Dispatch/Document';

/**
 * @description Renders toast notification for a dispatch job if the document is outdated
 * @param {object} dispatchJob - Dispatch job parse object
 * 
 * @example
 * <DocumentOutdatedToast dispatchJob={...} />
 */

function DocumentOutdatedToast({ ...props }) {
  const { addToast, clearToasts } = useToastContext();
  const { setDispatchDocumentTypesToSave } = useContext(DispatchJobLayoutContext);

  function onSaveDocument() {
    if (!props.dispatchJob) return;

    setDispatchDocumentTypesToSave([DocumentTypes.CUSTOMER_BILL_OF_LADING.type, DocumentTypes.INVOICE.type, DocumentTypes.LOAD_CONFIRMATION.type])

    // Using clearToast() clears all the toasts on screen
    // So we use HTMLelement.click() to trigger onClick of the close button
    const toastCloseBtn = document.querySelectorAll('.document-outdated-toast .p-toast-icon-close')[0];
    toastCloseBtn.click();
  }

  // Render content using toastContent component
  const toastContent = (
    <ToastContent 
      hideCurrentDateTime
      header={(
        <div>There are unsaved changes in documents for this job</div>
      )}
      body={(
        <div>Documents have been updated to reflect your changes. Would you like to save and overwrite previous changes?</div>
      )}
      footer={(
        <div className="flex">
          <Button label="Save" className="toast-save-btn ml-auto" onClick={() => onSaveDocument()} />
        </div>
      )}
    />
  )

  const [toastSettings, setToastSettings] = useState({
    // variant sets the colors of toast
    variant: 'warning',
    sticky: true,
    content: toastContent,
    className: 'document-outdated-toast',
    contentClassName: 'document-outdated-toast-content',
  })

  // Check to see if document outdated toast is already on the screen to avoid multiple toast showing up.
  function checkIfToastAlreadyExist() {
    const toastArr = document.getElementsByClassName('document-outdated-toast');
    if (toastArr.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
        {!checkIfToastAlreadyExist() && addToast(toastSettings)}
    </>
  );
}

export default DocumentOutdatedToast;
