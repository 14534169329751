import React from 'react';
import PropTypes from 'prop-types';
import { MDBRow, MDBCol } from 'mdbreact';

// API
import * as Helpers from 'api/Helpers';

// Components
import SBCard from 'components/Shared/SBCard/SBCard';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import './styles.scss';


class IFTARouteBreakdownMileage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { orderedStatesTravelled, mileageBreakdown, isFetchingMileageBreakdown } = this.props;

    return (
      <>
        {isFetchingMileageBreakdown && (
          <LoadingIcon />
        )}

        {!isFetchingMileageBreakdown && orderedStatesTravelled && mileageBreakdown && (
          <SBCard sbRoundedBorder title="Mileage Breakdown" isCollapsible={false}>
            <MDBRow key="ifta-rtbrkdwn-total">
              <MDBCol>
                <h3>Total Mileage</h3>
              </MDBCol>
              <MDBCol>
                <h3>{mileageBreakdown.total} km ({Math.floor(Helpers.convertDistance(mileageBreakdown.total, 'km', 'mi'))} mi)</h3>
              </MDBCol>
            </MDBRow>
            {orderedStatesTravelled.map(stateName => {
              if (stateName === 'total') return undefined;
              if (Math.floor(Helpers.convertDistance(mileageBreakdown[stateName], 'km', 'mi')) === 0) return undefined;
              return (
                <MDBRow key={`ifta-rtbrkdwn-${stateName}`} className="ifta-route-breakdown-mileage-card-row">
                  <MDBCol>
                    <b>{stateName}</b>
                  </MDBCol>
                  <MDBCol>
                    {mileageBreakdown[stateName]} km ({Math.floor(Helpers.convertDistance(mileageBreakdown[stateName], 'km', 'mi'))} mi)
                  </MDBCol>
                </MDBRow>
              );
            })}
          </SBCard>
        )}
      </>
    );
  }
}

IFTARouteBreakdownMileage.propTypes = {
  orderedStatesTravelled: PropTypes.arrayOf(PropTypes.string),
  mileageBreakdown: PropTypes.object,
  isFetchingMileageBreakdown: PropTypes.bool,
};

export default IFTARouteBreakdownMileage;
