import React from 'react';
import PropTypes from 'prop-types';
import history from "../../../sbHistory";

import { Table } from 'react-bootstrap';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import SortButton from 'components/SortButton/view/SortButton';

class EManifestTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sort: {},
    };
    // this.emanifestSort = this.emanifestSort.bind(this);
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextState.sort !== this.state.sort) {
  //     return true;
  //   }
  //   // if (nextProps.Driver !== this.props.Driver) {
  //   //   return true;
  //   // }
  //   return false;
  // }

  // componentWillUpdate(nextProps) {
  //   const { Driver } = nextProps;

  //   // if Driver state not fetched yet and there are no drivers to display
  //   if (!Driver.fetched && Driver.drivers.length === 0) {
  //     this.state.driverUserRows = null;
  //     return;
  //   }

  //   // if driver state is not fetched yet and there are drivers to display
  //   if (!Driver.fetched) {
  //     return;
  //   }

  //   this.driverUsers = [].concat(nextProps.Driver.drivers);
  //   let keyCounter = 0;

  //   // if the dispatcher has no drivers to begin with
  //   if (this.driverUsers.length === 0) {
  //     this.state.driverUserRows = (
  //       <tr>
  //         <td colSpan="7" className="emptyRowMessage">No Drivers to Display</td>
  //       </tr>
  //     );
  //     return;
  //   }

  //   this.state.driverUserRows = this.driverUsers.map(driverUser => {
  //     const driverJSON = driverUser.toJSON();
  //     const username = driverJSON.username;
  //     const firstName = driverJSON.firstName;
  //     const lastName = driverJSON.lastName;
  //     const email = driverJSON.email;
  //     const phoneNumber = driverJSON.phoneNumber;

  //     const driverRow = (
  //       <tr key={`${driverUser.id}${keyCounter}`} className={styles.driverRow}>
  //         <td>{username}</td>
  //         <td>{firstName}</td>
  //         <td>{lastName}</td>
  //         <td>{email}</td>
  //         <td>{phoneNumber}</td>
  //         <td className="centerText">
  //           <button
  //             className="floatRight buttonDefault"
  //             onClick={() => history.push(`drivers/${driverUser.id}`)}
  //           >
  //             View Details
  //           </button>
  //         </td>
  //       </tr>
  //     );

  //     keyCounter++;
  //     return driverRow; // defaukt
  //   });
  // }

  // emanifestSort(attribute) {
  //   const newState = { ...this.state };
  //   const currentSort = this.state.sort[attribute]; // the current sort, if defined
  //   if (currentSort) {
  //     if (currentSort === 'ascending') {
  //       newState.sort[attribute] = 'descending';
  //     } else {
  //       newState.sort[attribute] = 'ascending';
  //     }
  //   }
  //   else if (attribute === 'username') {
  //     newState.sort = { username: 'ascending' };
  //   }
  //   else if (attribute === 'firstName') {
  //     newState.sort = { firstName: 'ascending' };
  //   }
  //   else if (attribute === 'lastName') {
  //     newState.sort = { lastName: 'ascending' };
  //   }
  //   else if (attribute === 'email') {
  //     newState.sort = { email: 'ascending' };
  //   }
  //   else if (attribute === 'phoneNumber') {
  //     newState.sort = { phoneNumber: 'ascending' };
  //   }
  //   this.props.sortHandler(newState.sort);
  //   this.setState(newState);
  // }

  render() {
    return (
      <div className="defaultTable">
        <Table responsive hover>
          <thead>
            <tr>
              <th>
                eManifest Type
              </th>
              <th>
                Created On
              </th>
              <th>
                Last Modified
              </th>
              <th>
                Status
              </th>
              <th>
                Cancel
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="5" className="emptyRowMessage">
                No eManifests Available
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

EManifestTable.propTypes = {
  // Driver: PropTypes.object.isRequired,
  // scrollHandler: PropTypes.func,
  // sortHandler: PropTypes.func,
};

export default EManifestTable;

