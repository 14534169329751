import React from 'react';
import PropTypes from 'prop-types';
import { getQueryParameter } from 'api/URL';

// Components
import Title from 'components/LayoutTitle/view/Title';
import EManifestTable from 'components/EManifestTable/view/EManifestTable';
import ACEModule from 'components/ACEModule/view/ACEModule';

// CSS
import styles from 'layouts/EManifestLayout/EManifestLayout.module.scss'

class EManifestLayout extends React.Component {
  constructor(props) {
    super(props);
    let reportType;
    if (getQueryParameter(props.location.search, "type") === 'ace') {
      reportType = 'ace';
    } else if (getQueryParameter(props.location.search, "type") === 'aci') {
      reportType = 'aci';
    }
    this.state = {
      reportType, // ace or aci
    };
    this.selectReportType = this.selectReportType.bind(this);
    this.cancelChanges = this.cancelChanges.bind(this);
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state.type !== nextState.type) {

  //   }
  // }

  componentDidMount() {

  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.type) {
  //     this.setState(this.state);
  //   }
  // }

  selectReportType(type) {
    // determine whether to show ACE or ACI report creation
    history.push({ pathname: 'emanifest', search: type });
    this.setState({ ...this.state, reportType: type });
  }

  cancelChanges() {
    // function to cancel report changes and set state back to default
    history.push('emanifest');
    this.setState({ ...this.state, reportType: undefined });
  }

  render() {
    return (
      <div className={styles.EManifestLayout}>
        {!this.state.reportType &&
          <span>
            <Title title="eMANIFEST" className="textTransformNone">
              <span className="inlineBlock">
                <button
                  className="buttonDefault buttonSpacedRight"
                  onClick={() => this.selectReportType('ace')}
                >
                  Create ACE <span className="textTransformNone">eMANIFEST</span>
                </button>
                <button
                  className="buttonDefault"
                  onClick={() => this.selectReportType('aci')}
                >
                  Create ACI <span className="textTransformNone">eMANIFEST</span>
                </button>
              </span>
            </Title>

            <EManifestTable />
          </span>
        }
        {this.state.reportType === 'ace' &&
          <ACEModule cancelChanges={this.cancelChanges} />
        }
      </div>
    );
  }
}

EManifestLayout.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,
};

export default EManifestLayout;
