export default class UserNotificationSetting {
  /**
   * 
   * @param {string} objectId
   * @param {bool} enableSpeedViolation
   * @param {bool} enableHOSViolation
   * @param {bool} enableChatMessage
   * @param {string} emailTo
   */
	constructor(objectId, enableSpeedViolation, enableHOSViolation, enableChatMessage, emailTo) {
		this.objectId = objectId;
		this.enableSpeedViolation = enableSpeedViolation;
    this.enableHOSViolation = enableHOSViolation;
    this.enableChatMessage = enableChatMessage;
    this.emailTo = emailTo;
	}
}