import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

function ChooseOrAddModal(props) {
  return (
    <Modal backdrop="static" show={props.show} onHide={props.handleClose}>

      <Modal.Header closeButton>
        <Modal.Title>Choose {props.label}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {props.children}
      </Modal.Body>

      <Modal.Footer>
        <button className="closeButton" onClick={props.handleClose}>Close</button>
        <button className="buttonDefault redButton" onClick={() => props.handleModalChoice(null)}>Clear</button>
      </Modal.Footer>

    </Modal>
  );
}

ChooseOrAddModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleModalChoice: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default ChooseOrAddModal;
