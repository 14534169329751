import React from 'react';

// Components
import { Tooltip as PRTooltip } from 'primereact/tooltip';

// Styling
import './style.scss';

/**
 * @description PrimeReact Tooltip component
 * @param {String} className - Custom container className
 */
function Tooltip({ ...props }) {

  let className = 'sbc-tooltip';
  if (props.className) className += ` ${props.className}`;

  return (
    <PRTooltip {...props} className={className} />
  );
}

export default Tooltip;
