import React, { useState, useEffect, useContext } from 'react';
import history from 'sbHistory';

// api
import { getDispatchJobUserTags } from 'api/Dispatch/DispatchJobUserTag';
import { updateDispatchJob } from 'api/Dispatch/DispatchJob';

// sb-csapi
import { getAttribute } from 'sb-csapi/dist/AAPI';

// sbCore
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import LegendItem from 'sbCore/LegendItem/LegendItem';
import TabMenu from 'sbCore/TabMenu/TabMenu';
import Skeleton from 'sbCore/Skeleton/Skeleton';
import Button from 'sbCore/Button/Button';
import InputLabel from 'sbCore/InputLabel/InputLabel';

// sbobject
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// components
import DispatchJobTaggedUsers from 'components/Dispatch/DispatchJobTaggedUsers/DispatchJobTaggedUsers';
import DispatchDuplicateJob from 'components/Dispatch/DispatchDuplicateJob/DispatchDuplicateJob';
import DispatchJobTabMenu from 'components/Dispatch/DispatchJobTabMenu/DispatchJobTabMenu';
import JobStatusDropdown from 'components/Dispatch/JobStatusDropdown/JobStatusDropdown';

// enums
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// context
import DispatchJobLayoutContext from 'contexts/DispatchJobLayoutContext';

// styles
import './style.scss';

/**
 * @description Renders dispatch job order components
 * @param {Boolean} isLoading - Determine whether or not DispatchJob record is fetching
 * @param {DispatchJob} dispatchJob - DispatchJob record
 * @param {Boolean} generalInformationState - Determine whether generalInformation has changed
 * @returns
 */
function DispatchOrdersSummary({ isLoading, dispatchJob, generalInformationState }) {
  const { refreshJob } = useContext(DispatchJobLayoutContext);

  // ** useStates ** //
  const [dispatchJobUserTag, setDispatchJobUserTag] = useState(null);
  const [batchId, setBatchId] = useState(getAttribute(dispatchJob, 'batchId', true));
  const [jobStatus, setJobStatus] = useState(getAttribute(dispatchJob, 'status', true));

  // let legendTemplate = (
  //   <Skeleton width="10rem" className="ml-5 mt-2 mb-2" />
  // );

  let backButtonTemplate = (
    <Skeleton width="10rem" className="p-mb-2" />
  );

  let duplicateJobButtonTemplate = (
    <Skeleton width="10rem" className="p-mb-2" />
  );

  useEffect(() => {

    let didCancel = false;

    async function _getDispatchJobUserTags() {
      const filters = [new Filter(QueryRestriction.LIMIT, undefined, 1)]; // set limit to 1. ie. we just want the first tag created for this job

      const { dispatchJobUserTags } = await getDispatchJobUserTags(
        undefined, // default session
        dispatchJob,
        filters,
        undefined, // default sort ascending createdAt
        ['user, belongsToCompany'], // pointers
        ['user'], // selected attributes
      );

      if (!didCancel) {
        setDispatchJobUserTag(dispatchJobUserTags[0]);
        setBatchId(getAttribute(dispatchJob, 'batchId', true));
        setJobStatus(getAttribute(dispatchJob, 'status', true));
      }
    }

    _getDispatchJobUserTags();

    return () => { didCancel = true; };
  }, [dispatchJob]);

  useEffect(() => {
    if (!dispatchJob) return;
    async function _updateDispatchJob() {
      await updateDispatchJob(dispatchJob, undefined, { status: jobStatus }, true);
      refreshJob();
    }

    _updateDispatchJob();
  }, [jobStatus]);

  if (dispatchJob) {
    backButtonTemplate = (
      <Button className="back-button" label="Orders List" icon="pi pi-arrow-left" onClick={() => history.push('/dispatch')} />
    );

    duplicateJobButtonTemplate = (
      <DispatchDuplicateJob dispatchJob={dispatchJob} dispatchJobUserTag={dispatchJobUserTag} />
    );

    // legendTemplate = (
    //   <div className="flex flex-row value pl-5 pt-2">
    //     <div className="value-sub">
    //       <LegendItem success>
    //         Input Complete
    //       </LegendItem>

    //       <LegendItem warning>
    //         Input Recommended
    //       </LegendItem>

    //       <LegendItem error>
    //         Input Required
    //       </LegendItem>
    //     </div>
    //   </div>
    // );
  }

  return (
    <div className="flex flex-column dispatch-orders-summary">

      <div className="button-header">
        <div className="flex align-items-center justify-content-start w-75 mt-1">
          <span className="job-id-title-header">{batchId}</span>
          <JobStatusDropdown
            className="p-inputtext-md job-status-dropdown ml-1 mr-1"
            status={jobStatus}
            onSelect={status => setJobStatus(status)}
            isLoading={!dispatchJob}
            hideLabel
            variant="small"
          />
        </div>
        <div className="flex align-items-center justify-content-start w-25">
          {duplicateJobButtonTemplate}
        </div>
      </div>

      <DispatchJobTabMenu
        dispatchJob={dispatchJob}
        isLoading={isLoading}
        generalInformationState={generalInformationState}
      />

      {/* <DispatchJobTaggedUsers dispatchJob={dispatchJob} /> */}
    </div>
  );
}

export default DispatchOrdersSummary;
