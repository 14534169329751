import React from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import history from 'sbHistory';
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol } from 'mdbreact';

// API
import * as PDF from 'api/PDFer';
import * as Helpers from 'api/Helpers';
import { getCurrentDispatcher, getAllFromQuery } from 'api/Getters';
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL';
import { getOdometerJumps } from 'api/OdometerJump/OdometerJump';
import { getAttribute, getCurrentUser } from 'api/Parse';
import { getVehicles } from 'api/Equipment/Vehicle.old';

// Components
import SubNavigationBar from 'components/Shared/SubNavigationBar/SubNavigationBar';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import SBCard from 'components/Shared/SBCard/SBCard';
import Title from 'components/Shared/Title/Title';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import SBTable from 'components/Shared/SBTable/SBTable';
import CircularProgress from 'material-ui/CircularProgress';
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import DeleteXButton from 'components/ConfirmModal/container/DeleteXButton';

// SBObjects
import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

// Enums
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { LengthUnitTypes, LengthUnitNames, LengthConversion } from 'enums/DispatchUnit';

// Styles
import './style.scss';

const initialFilterFormFields = (tempDate) => {
  return [
    {
      attrName: 'month',
      fullName: 'Month',
      type: 'date',
      dateQueryType: 'dateMonth',
      includeLeftRight: true,
      value: tempDate ? momentTz(tempDate, 'DDMMYY') : momentTz(),
    },
  ];
}

class OdometerJumpReportLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: momentTz().subtract(14, 'days'),
      endDate: momentTz(),
      odometerJumpByVehicleUnitIdObj: {},
      vehicleFilter: { key: -1, value: null, label: 'All Vehicles' },
      vehicleFilterItems: [],
      odometerReadingFilter: { key: 0, value: 0, label: 'Hide large odometer jumps' },
      odometerReadingFilterItems: [{ key: 0, value: 0, label: 'Hide large odometer jumps' }, { key: 1, value: 1, label: 'Show all odometer jumps' }],
      printInProgress: false,
      isLoading: false,
      vehicleUnitIdArr: [],
      vehicleUnitIdsToBeLoaded: [],
      vehicleUnitIdsAlreadyLoaded: [],
    };

    const tempDate = getQueryParameter(props.location.search, 'date');
    this.state.filterFormFields = initialFilterFormFields(tempDate);
    this.handleFilter = this.handleFilter.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.printReport = this.printReport.bind(this);
    this.generateTableBodyRows = this.generateTableBodyRows.bind(this);
    this.loadMoreOdometerJumps = this.loadMoreOdometerJumps.bind(this)
    this.hideOdometerJumpRecord = this.hideOdometerJumpRecord.bind(this)
  }

  async componentWillMount() {
    // Obtain the list of enabled/active vehicles for the company (up to 100 vehicles)
    const limit = 100;
    const sort = new Sort('unitId', QuerySortOrderTypes.ASCENDING);
    const filters = [new Filter('enabled', true, QueryRestrictionTypes.EQUAL_TO), new Filter('unitId', undefined, QueryRestrictionTypes.EXISTS)];

    const vehicleQueryResult = await getVehicles(undefined, limit, sort, filters);
    const { vehicles, totalVehicleCount } = vehicleQueryResult;
    const vehicleUnitIdArr = vehicles.map((vehicle) => getAttribute(vehicle, 'unitId'));

    const vehicleFilterItems = vehicleUnitIdArr.sort().map((vehicleUnitId) => ({ key: vehicleUnitId, value: vehicleUnitId, label: 'Unit ' + vehicleUnitId }));
    vehicleFilterItems.unshift({ key: -1, value: null, label: 'All Vehicles' });

    // Obtain the distance unit to use
    const dispatcher = await getCurrentDispatcher();
    const distanceUnit = getAttribute(dispatcher, 'distanceUnit') === 'mi' ? LengthUnitTypes.MI : LengthUnitTypes.KM;

    this.setState({ ...this.state, vehicleUnitIdArr, vehicleFilterItems, distanceUnit }, () => {
      this.processDate();
    });
  }

  componentWillReceiveProps(nextProps) {
    this.processDate(nextProps);
  }

  async processDate(nextProps) {
    const props = nextProps || this.props;
    const dQuery = (props.location && getQueryParameter(props.location.search, 'd'));
    if (dQuery === 'lastTwoWeeks') {
      await this.setState(
        {
          ...this.state,
          startDate: momentTz().subtract(14, 'days'),
          endDate: momentTz(),
        },
      );
    } else if (dQuery === '24hours') {
      await this.setState(
        {
          ...this.state,
          startDate: momentTz().subtract(24, 'hours'),
          endDate: momentTz(),
        },
      );
    } else if (dQuery === 'month') {
      const dateString = getQueryParameter(props.location.search, 'date');
      await this.setState(
        {
          ...this.state,
          startDate: dateString ? momentTz(dateString, 'DDMMYY').startOf('month') : momentTz().startOf('month'),
          endDate: dateString ? momentTz(dateString, 'DDMMYY').endOf('month') : momentTz().endOf('month'),
        },
      );
    }
    this.refreshState();
  }

  refreshState() {
    this.setState({ ...this.state, odometerJumpByVehicleUnitIdObj: {}, vehicleUnitIdsAlreadyLoaded: [], isLoading: false }, async () => {
      const { state } = this;

      let vehicleUnitIdsToBeLoaded = [];
      const vehicleUnitIdFilter = state.vehicleFilter.value;
      if (!vehicleUnitIdFilter) {
        vehicleUnitIdsToBeLoaded = [...state.vehicleUnitIdArr]; // If theres no filter, then prepare to load data for all vehicles
      } else {
        vehicleUnitIdsToBeLoaded = [vehicleUnitIdFilter];
      }

      this.setState({ ...this.state, vehicleUnitIdsToBeLoaded }, () => this.loadMoreOdometerJumps());
    });
  }

  async loadMoreOdometerJumps() {
    const { state } = this;
    const currentUser = getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

    const limit = 500; // Max limit of 500 odometer jump records per 20 vehicles
    const sort = new Sort('dateTimeStart', QuerySortOrderTypes.ASCENDING);
    let filters = [
      new Filter('isHidden', true, QueryRestrictionTypes.NOT_EQUAL_TO),
      new Filter('totalVehicleKmStart', 0, QueryRestrictionTypes.GREATER_THAN),
      new Filter('totalVehicleKmEnd', 0, QueryRestrictionTypes.GREATER_THAN),
      new Filter('belongsToCompany', belongsToCompany, QueryRestrictionTypes.EQUAL_TO),
      new Filter('dateTimeStart', momentTz(state.startDate).toDate(), QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO),
      new Filter('dateTimeEnd', momentTz(state.endDate).toDate(), QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO),
    ];

    const vehiclesToBeProcessedAtOnce = 20;
    let vehicleUnitIdsToBeLoaded = [...state.vehicleUnitIdsToBeLoaded];
    let vehicleUnitIdsAlreadyLoaded = [...state.vehicleUnitIdsAlreadyLoaded];

    if (state.vehicleUnitIdsToBeLoaded.length === 0) return;
    if (state.vehicleUnitIdsToBeLoaded.length === 1) {
      const vehicleUnitId = state.vehicleUnitIdsToBeLoaded[0];
      filters.push(new Filter('vehicleUnitId', vehicleUnitId, QueryRestrictionTypes.EQUAL_TO));
      // Set the new values
      vehicleUnitIdsToBeLoaded = vehicleUnitIdsToBeLoaded.filter((vehicleUnitId) => vehicleUnitId !== state.vehicleUnitIdsToBeLoaded[0]);
      vehicleUnitIdsAlreadyLoaded = [...vehicleUnitIdsAlreadyLoaded, vehicleUnitId];
    } else {
      // Grab the first 15 vehicles to load from the list
      const vehicleUniIdArr = vehicleUnitIdsToBeLoaded.splice(0, vehiclesToBeProcessedAtOnce);
      filters.push(new Filter('vehicleUnitId', vehicleUniIdArr, QueryRestrictionTypes.CONTAINED_IN));
      vehicleUnitIdsAlreadyLoaded = [...vehicleUnitIdsAlreadyLoaded, ...vehicleUniIdArr];
    }

    // Get Odometer Jumps
    const odometerJumpQueryResults = await getOdometerJumps(undefined, limit, sort, filters);
    const { odometerJumps, totalOdometerJumpCount } = odometerJumpQueryResults;

    // Sort Table by Vehicle
    const odometerJumpByVehicleUnitIdObj = { ...state.odometerJumpByVehicleUnitIdObj };

    odometerJumps.forEach((odometerJump) => {
      const vehicleUnitId = getAttribute(odometerJump, 'vehicleUnitId');
      const totalVehicleKmStart = getAttribute(odometerJump, 'totalVehicleKmStart');
      const totalVehicleKmEnd = getAttribute(odometerJump, 'totalVehicleKmEnd');
      const totalVehicleKmDiff = totalVehicleKmEnd - totalVehicleKmStart;

      const differenceThreshold = 25; // 10 km
      const largeEldJumpThreshold = 805; // 500mi max threshold to show
      if (
        state.odometerReadingFilter.key === 0 && 
        (
          totalVehicleKmDiff > largeEldJumpThreshold ||
          totalVehicleKmDiff < 0
        )
      ) return;

      if (Math.abs(totalVehicleKmDiff) < differenceThreshold || totalVehicleKmStart === 0 || totalVehicleKmEnd === 0) return;

      if (!odometerJumpByVehicleUnitIdObj[vehicleUnitId]) {
        odometerJumpByVehicleUnitIdObj[vehicleUnitId] = [odometerJump];
      } else {
        odometerJumpByVehicleUnitIdObj[vehicleUnitId].push(odometerJump);
      }
    });

    this.setState({ ...this.state, odometerJumpByVehicleUnitIdObj, vehicleUnitIdsToBeLoaded, vehicleUnitIdsAlreadyLoaded }, () => {
      if (vehicleUnitIdsToBeLoaded.length > 0) this.loadMoreOdometerJumps();
    });
  }

  async hideOdometerJumpRecord(odometerJump) {
    if (odometerJump) {
      await odometerJump.set('isHidden', true).save();
      this.refreshState();
    }
  }

  async handleFilter(filters = []) {
    let month = momentTz();
    let startDate;
    let endDate;
    if (filters.length === 0) {
      this.setState({ ...this.state, filterFormFields: initialFilterFormFields(), month });
    } else {
      const filterFormFields = [].concat(...this.state.filterFormFields);
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        for (let j = 0; j < filterFormFields.length; j++) {
          if (filterFormFields[j].attrName === filter.attribute) {
            filterFormFields[j].value = filter.value;
          }
        }
        if (filter.attribute === 'month') {
          month = filter.value;
          startDate = momentTz(month).startOf('month');
          endDate = momentTz(month).endOf('month');
        }
      }
      this.setState({ ...this.state, filterFormFields, startDate, endDate }, () => {
        const queryStrObj = getDeserialized(this.props.location.search).params;
        history.push({
          search: getSerialized({ ...queryStrObj, date: momentTz(month).format('DDMMYY') }).query,
        });
      });
    }
  }

  async printReport() {
    let reportHTML;
    let reportName;
    this.setState({ ...this.state, printInProgress: true });
    reportHTML = document.querySelector('.odometer-jump-report-printable').outerHTML;
    reportName = `Odometer Jump Report`;
    PDF.generateTempPDFFromHTML(document.querySelector('.odometer-jump-report-printable'), reportName, undefined).then(
      tempFile => {
        const reportURL = tempFile.get('file')._url;
        Helpers.openDocumentLink(reportURL);
        this.setState({ ...this.state, printInProgress: false });
      }
    );
  }

  /**
 * @description Creates the rows to be displayed on the table
 * @param {String} vehicleUnitId vehicleUnitId
 * @param {Array} jumpedOdometerReadings Array of objects containing the jumped odometer readings information
 */
  generateTableBodyRows(vehicleUnitId, odometerJumpsArr) {
    const { state } = this;
    const tableBodyRow = odometerJumpsArr.map((odometerJump) => {
      let dateTimeStart = getAttribute(odometerJump, 'dateTimeStart');
      let dateTimeEnd = getAttribute(odometerJump, 'dateTimeEnd');
      const totalVehicleKmStart = getAttribute(odometerJump, 'totalVehicleKmStart');
      const totalVehicleKmEnd = getAttribute(odometerJump, 'totalVehicleKmEnd');
      const eldEventStart = getAttribute(odometerJump, 'eldEventStart');
      const eldEventEnd = getAttribute(odometerJump, 'eldEventEnd');
      const totalVehicleKmDiff = totalVehicleKmEnd - totalVehicleKmStart;

      // figure out if the difference between totalVehicleKmStart and totalVehicleKmEnd
      // is within a 100km/hr range. if not, then hide the dateTimeStart as it potentially
      // signifies a multiday jump. we will just want to show when the trigger was found (dateTimeEnd)
      let isJumpMajorOutlier = false;
      const startTime = momentTz(dateTimeStart);
      const endTime = momentTz(dateTimeEnd);
      const duration = moment.duration(endTime.diff(startTime));
      const hours = duration.asHours(); // hours travelled in this time period
      const kmPerHour = hours * 100; // 100km/hr

      // if vehicle is going at 100km/hr based on kmPerHour, what would the theoretical totalVehicleKmEnd be:
      const estimatedTotalVehicleKmEnd = totalVehicleKmStart + kmPerHour;

      // if the odometer jump end value recorded, is greater than the estimated estimatedTotalVehicleKmEnd, do the things
      if (totalVehicleKmEnd > estimatedTotalVehicleKmEnd) {
        isJumpMajorOutlier = true;
      }


      const eldDailyCertification = getAttribute(odometerJump, 'eldDailyCertification');
      const driver = getAttribute(eldEventStart, 'driver');

      let locationDescriptionStart;
      let locationDescriptionEnd;

      const timezoneOffsetFromUTC = (driver && driver.get('timezoneOffsetFromUTC')) || (eldDailyCertification && eldDailyCertification.get('timezoneOffsetFromUTC')) || momentTz.tz.guess();
      dateTimeStart = momentTz(dateTimeStart).tz(timezoneOffsetFromUTC).format('YYYY-MM-DD @ HH:mm');
      dateTimeEnd = momentTz(dateTimeEnd).tz(timezoneOffsetFromUTC).format('YYYY-MM-DD @ HH:mm');

      // console.log(eldEventStart, eldEventEnd);
      // if (eldEventStart && eldEventEnd) {
      //   const vehicleLocationStart = getAttribute(eldEventStart, 'vehicleLocation');
      //   const vehicleLocationEnd = getAttribute(eldEventEnd, 'vehicleLocation');
      //   console.log(vehicleLocationStart, vehicleLocationEnd, eldEventStart, eldEventEnd)

      //   if (state.distanceUnit === LengthUnitTypes.MI) {
      //     locationDescriptionStart = vehicleLocationStart && getAttribute(vehicleLocationStart, 'locationDescriptionUS');
      //     locationDescriptionEnd = vehicleLocationEnd && getAttribute(vehicleLocationEnd, 'locationDescriptionUS');
      //   } else {
      //     locationDescriptionStart = vehicleLocationStart && getAttribute(vehicleLocationStart, 'locationDescriptionCA');
      //     locationDescriptionEnd = vehicleLocationEnd && getAttribute(vehicleLocationEnd, 'locationDescriptionCA');
      //   }
      // }

      const vehicleRowObj = {
        key: odometerJump.id,
        columns: [
          {
            element: <b>{vehicleUnitId}</b>,
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          {
            element: (
              <div>
                { !isJumpMajorOutlier && (
                  <React.Fragment>
                    <b>
                      {dateTimeStart} -
                    </b>
                    <br />
                    <b>
                      {dateTimeEnd}
                    </b>
                  </React.Fragment>
                )}

                { isJumpMajorOutlier && (

                  <div>
                    <b>
                      {dateTimeEnd}
                    </b>

                    <div className="odometer-jump-footnotes">
                      * This jump appears to be much larger than expected<br />
                      It may span multiple days or the Engine Module connection was lost
                    </div>
                  </div>

                )}

              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          {
            element:
              (
                <div>
                  <b>
                    {state.distanceUnit === LengthUnitTypes.MI ? Math.floor((totalVehicleKmStart * LengthConversion.KM2MI).toFixed(1)) : totalVehicleKmStart.toFixed(1)}
                  </b>
                  <br />
                  <b>
                    {state.distanceUnit === LengthUnitTypes.MI ? Math.floor((totalVehicleKmEnd * LengthConversion.KM2MI).toFixed(1)) : totalVehicleKmEnd.toFixed(1)}
                  </b>
                </div>
              ),
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          // {
          //   element:
          //     (
          //       <div>
          //           {locationDescriptionStart ? locationDescriptionStart : 'N/A'}
          //         <br />
          //           {locationDescriptionEnd ? locationDescriptionEnd : 'N/A'}
          //       </div>
          //     ),
          //   props: { className: 'table-body-column-style verticalAlignMiddle' },
          // },
          {
            element: <b>{state.distanceUnit === LengthUnitTypes.MI ? Math.floor((totalVehicleKmDiff * LengthConversion.KM2MI).toFixed(1)) : totalVehicleKmDiff.toFixed(1)}</b>,
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          {
            element: driver ?
              <a href={`/driver?driver=${driver.id}&view=hosEvents&date=${momentTz(dateTimeStart).format('DDMMYY')}`}>
                <MDBBtn
                  className="mt-2"
                  size="sm"
                  color='primary'
                  onClick={() => history.push({ pathname: 'driver', search: "driver=" + driver.id + "&view=hosEvents" + "&date=" + momentTz(dateTimeStart).format('DDMMYY') })}
                >
                  {'Go To Log'}
                </MDBBtn
              ></a> : <b>Unidentified Driving</b>,
              
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          {
            element:
              <div>
                <span>
                  <DeleteXButton handleChoice={(confirmBool) => confirmBool && this.hideOdometerJumpRecord(odometerJump)} />
                </span>
              </div>,
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
        ],
      };

      return vehicleRowObj;
    });

    return tableBodyRow;
  }

  render() {
    const { state, props } = this;

    const tableHeaderStyles = {
      vehicleUnitId: { width: '5%' },
      date: { width: '10%' },
      jumpedKm: { width: '10%' },
      odometer: { width: '10%' },
      button: { width: '5%' },
      hideButton: { width: '2%' },
    };

    const tableHeaderRows = [{
      key: 'sb-jobs-list',
      columns: [
        {
          element: <div>Unit Id</div>,
          props: { style: tableHeaderStyles.vehicleUnitId },
        },
        {
          element: <div>Date</div>,
          props: { style: tableHeaderStyles.date },
        },
        {
          element: <div>{`Odometer Readings (${state.distanceUnit})`}</div>,
          props: { style: tableHeaderStyles.odometer },
        },
        {
          element: <div>{`Jumped Mileages (${state.distanceUnit})`}</div>,
          props: { style: tableHeaderStyles.jumpedKm },
        },
        {
          element: <div></div>,
          props: { style: tableHeaderStyles.button },
        },
        {
          element: <div></div>,
          props: { style: tableHeaderStyles.hideButton },
        },
      ],
    }];

    const vehiclesTable = Object.keys(state.odometerJumpByVehicleUnitIdObj).map(vehicleUnitId => {
      const odometerJumpRows = this.generateTableBodyRows(vehicleUnitId, state.odometerJumpByVehicleUnitIdObj[vehicleUnitId]);
      return (
        <SBCard key={vehicleUnitId} isCollapsible={false} className="" title={`Vehicle: ${vehicleUnitId}`} cardBodyStyle={{ maxHeight: 'none' }} headerBorder>
          <SBTable
            hover
            tableHeaderRows={tableHeaderRows}
            tableBodyRows={odometerJumpRows}
            emptyTableMessage={`No jumped mileages were found for Unit ${vehicleUnitId} for this interval`}

          />
        </SBCard>
      );
    });

    const tempView = this.props.location ? getQueryParameter(this.props.location.search, 'd') : undefined;
    const queryStrObj = getDeserialized(this.props.location.search).params;
    const navItems = [
      {
        name: 'Last 24 Hours',
        isActive: tempView === '24hours',
        to: getSerialized({ ...queryStrObj, d: '24hours' }).query,
      },
      {
        name: 'Last Two Weeks',
        isActive: !tempView || tempView === 'lastTwoWeeks',
        to: getSerialized({ ...queryStrObj, d: 'lastTwoWeeks' }).query,
      },
      {
        name: 'By Month',
        isActive: tempView === 'month',
        to: getSerialized({ ...queryStrObj, d: 'month', date: momentTz().format('DDMMYY') }).query,
      },
    ];

    return (
      <MDBContainer className="px-5 hosViolationDetails-layout" fluid>
        <Title title="Odometer Jump Reports"></Title>
        <SBBlock
          warning
          listMessages={false}
          title="Disclaimer"
          messages={[
            <div style={{ fontSize: '1em' }}>
              Odometer Jump Reports should be used to check for cases where drivers have ELDs unplugged or disconnected.
            </div>,
            <div style={{ fontSize: '1em' }}>
              Not for use in IFTA calculations.
            </div>,
          ]}
        />
        <MDBCol>

          {/* Sub Navigation Bar */}
          <MDBRow>
            <MDBCol xs="3">
              <SubNavigationBar navItems={navItems} />
            </MDBCol>
            <MDBCol xs="3">
              <MDBBtn
                size="sm"
                color="primary"
                className="align-middle"
                onClick={this.printReport}
                disabled={state.printInProgress}
                style={{ marginLeft: '4em' }}
              >
                <MDBIcon icon="print"></MDBIcon> Print
              </MDBBtn>
            </MDBCol>
          </MDBRow>

          {/* Filters  */}
          <MDBRow className="mt-2">
            <MDBCol xs="1" className="pt-4">
              <SBSelect
                containerClassName="ml-2 mr-2 d-inline-block"
                className="sb-jobs-list-select"
                defaultToggleText={state.vehicleFilter.label}
                items={state.vehicleFilterItems}
                selectedItems={[state.vehicleFilter]}
                getSelectedItems={(selectedFilter) => this.setState({ ...this.state, vehicleFilter: selectedFilter[0] }, () => this.refreshState())}
                showFilter
              />
            </MDBCol>
            <MDBCol xs="1" className="pt-4">
              <SBSelect
                containerClassName="ml-2 mr-2 d-inline-block"
                className="sb-jobs-list-select"
                defaultToggleText={state.odometerReadingFilter.label}
                items={state.odometerReadingFilterItems}
                selectedItems={[state.odometerReadingFilter]}
                getSelectedItems={(selectedFilter) => this.setState({ ...this.state, odometerReadingFilter: selectedFilter[0] }, () => this.refreshState())}
              />
            </MDBCol>
            {tempView === 'month' && (
              <MDBCol>
                <FilterForm
                  className="d-inline-block"
                  handleFilter={this.handleFilter}
                  clearFilter={() => this.handleFilter()}
                  fields={state.filterFormFields}
                  submitOnSelect
                  disabled={state.isLoading}
                />
              </MDBCol>
            )}
          </MDBRow>
        </MDBCol>
        { Object.keys(state.odometerJumpByVehicleUnitIdObj).length > 0 &&
          <React.Fragment>
            <div className="odometer-jump-report-printable">
              {vehiclesTable}
            </div>
          </React.Fragment>
        }
        { state.vehicleUnitIdsToBeLoaded.length > 0 && Object.keys(state.odometerJumpByVehicleUnitIdObj).length === 0 &&
          <React.Fragment>
            <MDBContainer className="py-5 odometer-jump-report-loading" fluid>
              <div className="py-5">
                Loading Data
              </div>
              <CircularProgress />
            </MDBContainer>
          </React.Fragment>
        }
        { state.vehicleUnitIdsToBeLoaded.length === 0 && Object.keys(state.odometerJumpByVehicleUnitIdObj).length === 0 && state.vehicleUnitIdsAlreadyLoaded.length > 0 &&
          <React.Fragment>
            <div className="odometer-jump-report-printable">
              {state.vehicleFilter.value !== null ?
                <div className="my-2 py-3 no-results-message-container">There are currently no jumped mileages detected for Unit {state.vehicleFilter.value} for this time period</div>
                :
                <div className="my-2 py-3 no-results-message-container">There are currently no jumped mileages detected for this time period</div>
              }

            </div>
          </React.Fragment>
        }
      </MDBContainer>
    );
  }
}

OdometerJumpReportLayout.propTypes = {
};

export default OdometerJumpReportLayout;
