import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

// Components
import Dialog from 'sbCore/Dialog/Dialog';
import Button from 'sbCore/Button/Button';
import AddNewCompanyUserForm from './AddNewCompanyUserForm';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './AddNewCompanyUserModal.module.scss';

function AddNewCompanyUserModal(props) {
  return (
    <Dialog
      className="translate-me"
      header={`Add New Company User`}
      visible={props.show}
      onHide={() => props.handleClose()}
      resizeable={false}
      draggable={false}
      style={{ width: '50vw' }}
    >
      <div>
        <AddNewCompanyUserForm {...props} />

        <div className="flex flex-row" style={{ float: 'left' }}>
          <span className="errorText">{props.errorString}</span>
        </div>

        <div className="flex flex-row" style={{ float: 'right' }}>
          <Button icon="pi pi-times" label="Cancel" disabled={props.disableAdd} onClick={() => props.handleClose()} />
          &nbsp;
          <Button icon="pi pi-check" label="Add User" disabled={props.disableAdd} onClick={() => props.handleAddNewCompanyUser()} />
        </div>
      </div>

    </Dialog >

  );
}

AddNewCompanyUserModal.propTypes = {
  handleAddNewCompanyUser: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  disableAdd: PropTypes.bool.isRequired,
  addingInProgress: PropTypes.bool.isRequired,
  errorString: PropTypes.string,
};

export default AddNewCompanyUserModal;
