import * as ActionConstants from 'actions/ActionConstants';
import * as Helpers from 'api/Helpers';

const initialState = {
  jobLinks: [],
  fetched: false,
};

function addJobLink(jobLinks, newJobLink) {
  const newJobLinks = [].concat(jobLinks);
  newJobLinks.unshift(newJobLink);
  return Helpers.removeDuplicatesByObjectId(newJobLinks);
}

function destroyJobLinks(stateJobLinks, jobLinksToBeDeleted) {
  const newStateJobLinks = [].concat(stateJobLinks);
  if (newStateJobLinks) {
    const jobLinksToBeDeletedLen = jobLinksToBeDeleted.length;
    for (let i = 0; i < jobLinksToBeDeletedLen; i++) {
      const index = Helpers.findIndexOfObjArr(newStateJobLinks, 'id', jobLinksToBeDeleted[i].id);
      newStateJobLinks.splice(index, 1);
    }
  }
  return newStateJobLinks;
}

function setJobLinkUploadingDocument(stateJobLinks, uploadingDocumentBool, jobLink) {
  const index = Helpers.findIndexOfObjArr(stateJobLinks, 'id', jobLink.id);
  const newStateJobLinks = [].concat(stateJobLinks);
  newStateJobLinks[index].addDocumentInProgress = uploadingDocumentBool;
  return newStateJobLinks;
}

export function JobLink(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_JOBLINKS_INPROGRESS:
      return {
        ...state,
        status: action.type,
        fetched: false,
        error: undefined,
      };
    case ActionConstants.FETCH_JOBLINKS_SUCCESS:
      return {
        ...state,
        status: action.type,
        jobLinks: state.jobLinks.concat(action.jobLinks),
        fetched: true,
        error: undefined,
      };
    case ActionConstants.FETCH_JOBLINKS_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
        fetched: false,
      };
    case ActionConstants.DELETE_JOBLINKS:
      return {
        ...initialState,
        status: action.type,
      };
    case ActionConstants.ADD_JOBLINK_INPROGRESS:
      return {
        ...state,
        status: action.type,
      };
    case ActionConstants.ADD_JOBLINK_SUCCESS:
      return {
        ...state,
        status: action.type,
        jobLinks: addJobLink(state.jobLinks, action.jobLink),
        updated: action.jobLink,
      };
    case ActionConstants.ADD_JOBLINK_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    case ActionConstants.DESTROY_JOBLINKS_SUCCESS:
      return {
        ...state,
        status: action.type,
        jobLinks: destroyJobLinks(state.jobLinks, action.jobLinks),
        updated: action.jobLinks,
      };
    case ActionConstants.JOBLINK_ASSIGN_DRIVER:
      return {
        ...state,
        status: action.type,
        updated: action.jobLink,
      };
    case ActionConstants.JOBLINK_UNASSIGN_DRIVER:
      return {
        ...state,
        status: action.type,
        updated: action.jobLink,
      };
    case ActionConstants.JOBLINKS_COMPLETED_SUCCESS:
      return {
        ...state,
        status: action.type,
        updated: action.jobLinks,
      };
    case ActionConstants.JOBLINK_DOCUMENTUPLOAD_INPROGRESS:
      return {
        ...state,
        jobLinks: setJobLinkUploadingDocument(state.jobLinks, true, action.jobLink),
      };
    case ActionConstants.JOBLINK_DOCUMENTUPLOAD_SUCCESS:
      return {
        ...state,
        jobLinks: setJobLinkUploadingDocument(state.jobLinks, false, action.jobLink),
      };
    case ActionConstants.JOBLINK_DOCUMENTUPLOAD_ERROR:
      return {
        ...state,
        jobLinks: setJobLinkUploadingDocument(state.jobLinks, false, action.jobLink),
      };
    default:
      return state;
  }
}
