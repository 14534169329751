import store from 'store';
import history from 'sbHistory';
import * as ParseAPI from 'api/Parse';
import * as PubNub from 'api/PubNub';
import * as ActionConstants from './ActionConstants';

import { clearState, fetchAndLoadUserGlobalsForState, initialize } from './Main';
import { fetchNotificationsForState } from './Notification';
import { fetchGlobalTrailersForState } from './GlobalTrailer';
import { fetchGlobalVehiclesForState } from './GlobalVehicle';

function loggingIn() {
  return {
    type: ActionConstants.LOGIN_INPROGRESS,
  };
}

function loginSuccess() {
  return {
    type: ActionConstants.LOGIN_SUCCESS,
  };
}

function loginError(error) {
  return {
    type: ActionConstants.LOGIN_ERROR,
    error,
  };
}

function logoutSuccess() {
  return {
    type: ActionConstants.LOGGED_OUT,
  };
}

export function tryLogin(values, disableRedirect) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(loggingIn());
    ParseAPI.login(values.email, values.password).then(user => {
      if (user.get('userType').indexOf(4) === -1) {
        if (!disableRedirect) {
          history.push('/home');
        }
        store.dispatch(loginSuccess());
        resolve(user);
      } else {
        ParseAPI.logout();
        store.dispatch(loginError({ message: 'Cannot log in with a driver account, please use your web account' }));
      }
    }, error => {
      console.log('tryLogin error');
      console.log(error);
      store.dispatch(loginError(error));
      reject(error);
    });
  });
  return promise;
}

export function doLogout(bypassRedirect) {
  const promise = new Promise((resolve) => {
    // unsubscribeNotificationsForState()(dispatch);
    ParseAPI.logout().then(
      () => {
        PubNub.unsubscribeAll();
        // window.location.href('/login');
        history.push('/login');
        store.dispatch(clearState());
        resolve();
      }
    );
  });
  return promise;
}

export function pubNubSetUpInProgress(setupPromise) {
  return {
    type: ActionConstants.PUBNUB_SETUP_INPROGRESS,
    setupPromise,
  };
}

export function pubNubSetUpSuccess(pubNubClient) {
  return {
    type: ActionConstants.PUBNUB_SETUP_SUCCESS,
    pubNubClient,
  };
}

export function requireAuth() {
  // return (nextState, replace) => {
  // If there is no user logged in, refer to login page
  const parseUser = ParseAPI.getCurrentUser();
  if (!parseUser || !parseUser.authenticated()) {
    history.push('/login?error=Session%20has%20expired.%20Please%20login.');
  } else if (!parseUser.get('enabled')) {
    history.push('/login?error=User%20is%20not%20enabled.');
  } else {
    initialize();
    fetchAndLoadUserGlobalsForState().then(() => {
      fetchNotificationsForState();
      fetchGlobalTrailersForState();
      fetchGlobalVehiclesForState();
    }, (error) => {
      console.log(error);
    });

    if (
      window?.location?.host?.indexOf('app-support') === -1
      && window.location.host.indexOf('app-manager') === -1
    ) {
      const pubNubSetupPromise = PubNub.setUpPubNubClient(parseUser);
      store.dispatch(pubNubSetUpInProgress(pubNubSetupPromise));
      pubNubSetupPromise.then((pubNubClient) => {
        store.dispatch(pubNubSetUpSuccess(pubNubClient));
      });
    }

    store.dispatch(loginSuccess());
    parseUser.fetch().then(() => {
    }, (error) => {
      console.error(error);
      history.push('/login');
    });
  }
  // };
}
