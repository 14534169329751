import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// api
import { createTempParseObject } from 'api/Helpers';
import { cloneRecord, getAttribute, getCurrentUserSessionToken, updateRecord } from 'sb-csapi/dist/AAPI';
import { formatName, compressWhitespace } from 'sb-csapi/dist/utils/String';
import { isAutoGeneratedDrivingTime, updateELDEvent } from 'sb-csapi/dist/api/ELDEvent/ELDEvent';
import { getVehicleLocationDescription, getLocationDescriptionBreakdown, isValidLocationDescription } from 'api/VehicleLocation/VehicleLocation';

import { getDriverELDEvents, sortELDEvents, requestELDEdits, getAutogeneratedDrivingTimeIntervals } from 'api/ELD';

// enums
import { ELDEventRecordStatus } from 'sb-csapi/dist/enums/ELD/ELDEventRecordStatus';
import { ELDEdit, ELDEditCompleted } from 'sb-csapi/dist/enums/ELD/ELDEdit';
import { ELDEventTypeCode } from 'sb-csapi/dist/enums/ELD/ELDEventTypeCode';
import { Country } from 'sb-csapi/dist/enums/Country';
import { CountryCodeLocationDescriptionTypes } from 'enums/VehicleLocation';

// components
import Dialog from 'sbCore/Dialog/Dialog';
import Button from 'sbCore/Button/Button';

import HOSGraph from 'components/DriverDetails/container/HOSGraph';
import ELDEditModalButton from 'components/ELDEdit/ELDEditModal/ELDEditModalButton/ELDEditModalButton';
import ELDOverrideInputSwitch from 'components/ELDEdit/ELDEditModal/ELDOverrideInputSwitch/ELDOverrideInputSwitch';
import ELDEditModalEditBody from 'components/ELDEdit/ELDEditModal/ELDEditModalEditBody/ELDEditModalEditBody';
import ELDEditModalMessageBody from 'components/ELDEdit/ELDEditModal/ELDEditModalMessageBody/ELDEditModalMessageBody';
import ELDEditSupportTools from 'components/ELDEdit/ELDEditModal/ELDEditSupportTools/ELDEditSupportTools';
import ELDEditConfirmationMessage from 'components/ELDEdit/ELDEditConfirmationMessage/ELDEditConfirmationMessage';

// styles
import './style.scss';

/**
 * @description
 *
 * @param {ELDDailyCertification} eldDailyCertification - The daily cert of the logs. We also rely on eldDailyCertification.driver to exist (can be a faked pointer)
 * @param {ELDEvent} [eldEvent] - Determines that this is the only event we are editing out of n-events. Editing single events have a different flow
 * @param {Function} [onSubmit] - Callback function to call after the edit is confirmed and submitted
 * @param {String} [buttonClassName] - ClassName of the ELDEditModalButton
 * @param {Any} [refreshSwitch] - Providing any (new) value here will call the initializer again
 * @param {Object} [eldEventStore] - The ELDEvent Store. If provided, it bypasses this component doing all the fetching itself
 * @param {Object} [userSubscriptionPermission] - From getUserSubscriptionPermission(). If provided, it bypasses this component doing all the fetching itself
 *
 * @example
 *
    This is all you need for it to work. The rest of the props are optimization/customization:
    <ELDEditModal
      eldDailyCertification={eldDailyCertification}
    />
 *
 */
function ELDEditModal({ ...props }) {
  // ************ ENUMS ************ //
  // Step enum to track the steps (what step is next) of the edit modal, towards the final
  // edit confirmation
  const Step = Object.freeze({
    SUBMISSION: 0, // Confirmation #Final step to submit (submit popup) and then send the edit off
    DATA_ENTRY: 1, // Inputting edit data
    CONFIRMATION: 2, // Confirmation #1 of the edit, before actually submitting it
  });

  const initialEditErrorMap = { hasEditErrors: true, validEditObjectLocalIdMap: {} };

  // ************ STATE/USEEFFECTS ************ //
  // Visibility, Loading, and View switchers
  const [visible, setVisible] = useState(null); // initially null instead of false to distinguish between onMount (null) and user-closed (false)
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false); // whether all essential info is now retrieved
  const [step, setStep] = useState(Step.DATA_ENTRY); // view stepper

  // Errors
  const [editErrorMap, setEditErrorMap] = useState({ ...initialEditErrorMap }); // if there are issues with the edit and which editObjects fail validation
  const [editReason, setEditReason] = useState(''); // reason for the edit, required by fmcsa

  // "Controls" for initial event and edit info
  const [eldEvents, setELDEvents] = useState([]); // the initial retrieved eldEvents of the eldDailyCertification
  const [filteredELDEvents, setFilteredELDEvents] = useState([]); // one-time filtered version of the initial eldEvents that will be considered in the edit
  const [eldEdits, setELDEdits] = useState([]); // Existing edits for the driver, prior to any changes

  // Graphing and Editing
  const [defaultELDEventTypeCodeInt, setDefaultELDEventTypeCodeInt] = useState(ELDEventTypeCode.OFF_DUTY); // the default status to start the edit off from, if we lack event info
  const [eldEventsForPreview, setELDEventsForPreview] = useState([]); // the events used for HOS graph preview (requestedELDEvents, eldEventsToBeInactive included)
  const [disableRedraw, setDisableRedraw] = useState(false); // toggle whether or not to re-render the graph (it re-renders with every state change here)
  const [editObjects, setEditObjects] = useState([]); // The array of editObjects from the ELDEditModalEditBody. The order of the edits are important.
  const [agdtIntervals, setAGDTIntervals] = useState([]); // AGDT intervals for the ELDEditModalEditBody callback
  const [eldEventOverrideValueMap, setELDEventOverrideValueMap] = useState(undefined); // From onUpdateOverrideValues() for support overrides

  // const [associatedELDEvents, setAssociatedELDEvents] = useState([]); // DEPRECATED: the last known/next known eldEvents after the eldDailyCertification considered in the edit

  // Fetching + Misc
  const [driver, setDriver] = useState(undefined); // based on eldDailyCertification
  const [startTimeUTC, setStartTimeUTC] = useState(undefined); // based on eldDailyCertification
  const [timezoneOffsetFromUTC, setTimezoneOffsetFromUTC] = useState(moment.tz.guess());

  // Overrides
  const [eldOverrideType, setELDOverrideType] = useState(''); // currently supported: 'generate', 'override'
  const [overrideVehicle, setOverrideVehicle] = useState(undefined); // the selected override vehicle
  const isSupportToolDomain = window.location.host.indexOf('app-support') !== -1;
  const isLocalhostDomain = window.location.host.indexOf('app.localhost') !== -1;
  const isELDOverrideAvailable = isSupportToolDomain || isLocalhostDomain; // show the override elements/options

  useEffect(() => {
    // Basically the initializer
    let didCancel = false;
    setIsFetched(false);

    const _driver = getAttribute(props.eldDailyCertification, 'driver', true);
    let _timezoneOffsetFromUTC = getAttribute(props.eldDailyCertification, 'timezoneOffsetFromUTC', true);
    _timezoneOffsetFromUTC = _timezoneOffsetFromUTC || (_driver && _driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();

    const _startTimeUTC = getAttribute(props.eldDailyCertification, 'startTimeUTC', true);

    async function _getDriverELDEvents() {
      setIsLoading(true);
      const _eldEventStore = props.eldEventStore;
      const filter = { onDate: _startTimeUTC ? moment(_startTimeUTC, 'DDMMYY') : moment() };

      let eldEventsObject;
      if (_eldEventStore) {
        eldEventsObject = { eldEvents: _eldEventStore.eldEvents, eldEdits: _eldEventStore.eldEdits };
      } else {
        eldEventsObject = await getDriverELDEvents(_driver, filter, undefined, _timezoneOffsetFromUTC);
      }

      // @todo put filtering logic in it's own function
      let _eldEvents = sortELDEvents([].concat(eldEventsObject.eldEvents), 1);
      const _eldEdits = eldEventsObject.eldEdits;
      const mainELDEventDutyStatusTypeCodes = [
        ELDEventTypeCode.OFF_DUTY,
        ELDEventTypeCode.SLEEPER_BERTH,
        ELDEventTypeCode.DRIVING,
        ELDEventTypeCode.ON_DUTY_NOT_DRIVING,
        ELDEventTypeCode.AUTHORIZED_PERSONAL_USE_OF_CMV,
        ELDEventTypeCode.YARD_MOVES,
      ];

      // filter _eldEvents even more to 'simulate' aobrd edits as if they were auto-applied/accepted
      for (let i = 0; i < _eldEdits.length; i++) {
        const eldEdit = _eldEdits[i];
        const eldEditCompletedStatuses = [ELDEditCompleted.UNSEEN_NEW.status, ELDEditCompleted.AOBRD_DRIVING_TIME_EDIT_PENDING_ACCEPTED.status];

        if ((eldEditCompletedStatuses.indexOf(getAttribute(eldEdit, 'completed')) !== -1) && eldEdit.aobrdEnabled) {
          // aobrd edit, so auto apply it to the graph
          const eldEventsToBeInactiveObjectIds = getAttribute(eldEdit, 'eldEventsToBeInactive').map(eldEvent => getAttribute(eldEvent, 'objectId'));

          // filter out events to be removed
          _eldEvents = _eldEvents.filter(eldEvent => eldEventsToBeInactiveObjectIds.indexOf(getAttribute(eldEvent, 'objectId')) === -1);

          const requestedELDEvents = getAttribute(eldEdit, 'requestedELDEvents').map(eldEvent => {
            const requestedELDEvent = cloneRecord(eldEvent);
            updateRecord(undefined, requestedELDEvent, { eldEventRecordStatusInt: 1 });
            requestedELDEvent.id = requestedELDEvent._localId;
            return requestedELDEvent;
          });

          _eldEvents = _eldEvents.concat(requestedELDEvents);
        }
      }

      sortELDEvents(_eldEvents, 1); // sort again in ascending order

      // remove all interm events that appear after duty statuses that they should not be appearing after
      // if interm events come after one of these statuses, ignore them
      const ignoreIfELDEventDutyStatusTypeCodes = [ELDEventTypeCode.OFF_DUTY, ELDEventTypeCode.SLEEPER_BERTH, ELDEventTypeCode.ON_DUTY_NOT_DRIVING];
      const intermELDEventTypeCodes = [
        ELDEventTypeCode.INTERMEDIATE_LOG_WITH_CONVENTIONAL_LOCATION_PRECISION,
        ELDEventTypeCode.INTERMEDIATE_LOG_WITH_REDUCED_LOCATION_PRECISION,
      ];

      let lastSeenELDEventTypeCode;
      _eldEvents = _eldEvents.filter(eldEvent => {
        const eldEventTypeCodeInt = getAttribute(eldEvent, 'eldEventTypeCodeInt');
        const isMainELDEventTypeCode = mainELDEventDutyStatusTypeCodes.indexOf(eldEventTypeCodeInt) !== -1;
        const isIntermEvent = intermELDEventTypeCodes.indexOf(eldEventTypeCodeInt) !== -1;
        const eldEventActiveRecordStatuses = [ELDEventRecordStatus.ACTIVE_OLD, ELDEventRecordStatus.ACTIVE];

        // if the lastSeenELDEventTypeCode is a duty status where we should ignore interm events
        const isLastSeenELDEventTypeCodeShouldIgnore = (lastSeenELDEventTypeCode !== undefined) && (ignoreIfELDEventDutyStatusTypeCodes.indexOf(lastSeenELDEventTypeCode) !== -1);

        if (eldEventActiveRecordStatuses.indexOf(getAttribute(eldEvent, 'eldEventRecordStatusInt')) !== -1) { // only concerned with active events here
          if (isMainELDEventTypeCode) {
            lastSeenELDEventTypeCode = eldEventTypeCodeInt;
          } else if (isIntermEvent && isLastSeenELDEventTypeCodeShouldIgnore) {
            return false;
          }
        }

        return true;
      });

      // lastly for eldEvents for simulation, filter out Inactive Autogenerated Driving events
      _eldEvents = _eldEvents.filter(eldEvent => {
        const isInactiveAutogeneratedDrivingTimeELDEvent = isAutoGeneratedDrivingTime(eldEvent, ELDEventRecordStatus.INACTIVE_CHANGED);
        return !isInactiveAutogeneratedDrivingTimeELDEvent;
      });

      if (props.eldEvent) {
        // because of timezone autoscaling (ex. dispatcher is in diff timezone than driver)
        // we want to use a standardized format
        props.eldEvent.eventDateTime = getAttribute(props.eldEvent, 'eventDateTime');
        props.eldEvent.eventDateTime = moment(props.eldEvent.eventDateTime).tz(_timezoneOffsetFromUTC).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      }

      const _agdtIntervals = getAutogeneratedDrivingTimeIntervals(_eldEvents, false, _timezoneOffsetFromUTC, true);

      let _isFetched = true;
      if (_eldEventStore) {
        _isFetched = _eldEventStore.fetched;
      }

      if (!didCancel) {
        setDriver(_driver);
        setStartTimeUTC(_startTimeUTC);
        setTimezoneOffsetFromUTC(_timezoneOffsetFromUTC);
        setELDEvents(eldEventsObject.eldEvents);
        setFilteredELDEvents(_eldEvents);
        setELDEventsForPreview(_eldEvents);
        setAGDTIntervals(_agdtIntervals);
        setELDEdits(_eldEdits);
        setIsFetched(_isFetched);
        setIsLoading(false);
      }
    }

    _getDriverELDEvents();

    return () => {
      didCancel = true;
    };
  }, [props.eldDailyCertification, props.eldEvent, props.eldEventStore, props.refreshSwitch]);

  useEffect(() => {
    // Treat this useEffect as an unmount, which is determined by !visible
    if (visible) return;
    if (visible === null) return; // keep in mind visible onMount is initially null

    resetState();
  }, [visible]);

  useEffect(() => {
    if (!isFetched) return;

    if (eldEventsForPreview[0] && !getAttribute(eldEventsForPreview[0], 'objectId')) {
      // if the start event is fake (ex. a patch to replace a bad event, or an eld edit simulation event)
      // in which case, we want to take on that status of the fake event as a default status
      setDefaultELDEventTypeCodeInt(getAttribute(eldEventsForPreview[0], 'eldEventTypeCodeInt'));
    }

    if (eldEventsForPreview.length && eldOverrideType) {
      // If an override type exists, it means AGDT can be edited and therefore we need to find out
      // any new intervals that were created/changed
      setAGDTIntervals(getAutogeneratedDrivingTimeIntervals(eldEventsForPreview, false, timezoneOffsetFromUTC, true));
    }
  }, [isFetched, eldEventsForPreview]);

  // Triggers whenever there is a change to editObjects in order to show an updated preview of the edit.
  // Note that it doesn't trigger when filling support override values, as that is done in the submit/preview
  // function. So log those values in the submit function after triggering it
  useEffect(() => {
    let validEditObjects = editObjects.filter(editObject => {
      // this is for the edge case where there is currently no error, then Add Another Edit is used,
      // so dates are undefined and cannot be handled by the server. This case is indeed handled by
      // ELDEditMessageBody but what makes it an edge case to solve here is that the result returned by message body
      // is not instantaneous enough for this particular case, to stop the submission from happening
      // @todo - Add a isComplete status to message body to indicate when validation is done and
      //         use (isComplete === true) here
      const isNotEmptyAddEditObject = editObject.startDateTime && editObject.endDateTime;
      return isNotEmptyAddEditObject;
    });

    if (!editErrorMap.hasEditErrors) {
      submitELDEdit(true, validEditObjects); // all is good, get the preview
    } else {
      // This section: There are edit errors, but let the editObjects with no errors pass through
      // In this case, there is an issue with the edit overall, but not one particular edit object
      // Ex. No daily cert, or edit objects werent passed properly, etc
      if (Object.keys(editErrorMap.validEditObjectLocalIdMap).length === 0) return;

      validEditObjects = validEditObjects.filter(editObject => {
        const isValidEditObject = editErrorMap.validEditObjectLocalIdMap[editObject.localId];

        return isValidEditObject;
      });
      submitELDEdit(true, validEditObjects);
    }
  }, [editObjects, editErrorMap.hasEditErrors]);

  function resetState() {
    // sets appropriate state variables to what they should be when the modal is closed (unmount)
    // note that some resets already happen in the 'initializer' useEffect when it's triggered via useEffect props
    // if you want to reset those states, we suggest to re-trigger the initializer than coding the reset here
    setStep(Step.DATA_ENTRY);
    setEditErrorMap({ ...initialEditErrorMap });
    setEditReason('');
    setELDEventsForPreview(filteredELDEvents); // gets rid of saved graph objects from previous edit preview
    setEditObjects([]);
    setELDOverrideType('');
    setOverrideVehicle(undefined);
    setELDEventOverrideValueMap(undefined);
  }

  function onValidationCompleted(hasEditErrors, validEditObjectLocalIdMap) {
    setEditErrorMap({ hasEditErrors, validEditObjectLocalIdMap });
  }

  // Note: This is an updated copy of the legacy getELDEventsIdString function
  //
  // Generates a string of event ids we want to pass for edits to reduce number of queries
  // It filters for active and relevant duty-status only events except for the exemptELDEvents given
  function getELDEventsIdString(exemptELDEvents = []) {
    const eldDailyCertificationObjectId = getAttribute(props.eldDailyCertification, 'objectId', true);

    // Create a set of exempt event ids to be compared against later
    const exemptELDEventObjectIdSet = new Set(exemptELDEvents.map(eldEvent => getAttribute(eldEvent, 'objectId')));

    // Filter for active and relevant duty status events
    const _filteredELDEvents = filteredELDEvents.filter((eldEvent) => {
      const eldEventObjectId = getAttribute(eldEvent, 'objectId');
      if (exemptELDEventObjectIdSet.has(eldEventObjectId)) return true;

      const eldEventTypeCodeInt = getAttribute(eldEvent, 'eldEventTypeCodeInt');
      const eldEventRecordStatusInt = getAttribute(eldEvent, 'eldEventRecordStatusInt');

      const isActiveEvent = [ELDEventRecordStatus.ACTIVE_OLD, ELDEventRecordStatus.ACTIVE].includes(eldEventRecordStatusInt);
      const isDutyStatusEvent = [
        ELDEventTypeCode.OFF_DUTY,
        ELDEventTypeCode.SLEEPER_BERTH,
        ELDEventTypeCode.DRIVING,
        ELDEventTypeCode.ON_DUTY_NOT_DRIVING,
        ELDEventTypeCode.INTERMEDIATE_LOG_WITH_CONVENTIONAL_LOCATION_PRECISION,
        ELDEventTypeCode.INTERMEDIATE_LOG_WITH_REDUCED_LOCATION_PRECISION,
        ELDEventTypeCode.PC_YM_WT_CLEARED,
        ELDEventTypeCode.AUTHORIZED_PERSONAL_USE_OF_CMV,
        ELDEventTypeCode.YARD_MOVES,
      ].includes(eldEventTypeCodeInt);

      let isOfDailyCert = true;

      const eldEventELDDailyCertification = getAttribute(eldEvent, 'eldDailyCertification');
      const eldEventELDDailyCertificationObjectId = getAttribute(eldEventELDDailyCertification, 'objectId', true);

      if (eldEventELDDailyCertificationObjectId) {
        isOfDailyCert = eldEventELDDailyCertificationObjectId === eldDailyCertificationObjectId;
      }

      // 2023-07-19 - If the support team is editing with the overrideeldevents url param, we allow for all events to be edited, excluding the following:
      // driver certification events (41-49), off-duty time deferrals (200-202), cycle sets (211/212)
      if (eldOverrideType === 'override') {
        const driverCertificationELDEventTypeCodeInts = [
          ELDEventTypeCode.DRIVERS_FIRST_CERTIFICATION_OF_A_DAILY_RECORD,
          ELDEventTypeCode.DRIVERS_SECOND_CERTIFICATION_OF_A_DAILY_RECORD,
          ELDEventTypeCode.DRIVERS_THIRD_CERTIFICATION_OF_A_DAILY_RECORD,
          ELDEventTypeCode.DRIVERS_FOURTH_CERTIFICATION_OF_A_DAILY_RECORD,
          ELDEventTypeCode.DRIVERS_FIFTH_CERTIFICATION_OF_A_DAILY_RECORD,
          ELDEventTypeCode.DRIVERS_SIXTH_CERTIFICATION_OF_A_DAILY_RECORD,
          ELDEventTypeCode.DRIVERS_SEVENTH_CERTIFICATION_OF_A_DAILY_RECORD,
          ELDEventTypeCode.DRIVERS_EIGHTH_CERTIFICATION_OF_A_DAILY_RECORD,
          ELDEventTypeCode.DRIVERS_NINTH_CERTIFICATION_OF_A_DAILY_RECORD,
        ];
        const offDutyDeferralELDEventTypeCodeInts = [
          ELDEventTypeCode.OFF_DUTY_TIME_DEFERRAL_NONE,
          ELDEventTypeCode.OFF_DUTY_TIME_DEFERRAL_DAY_ONE,
          ELDEventTypeCode.OFF_DUTY_TIME_DEFERRAL_DAY_TWO,
        ];
        const canadianCycleSetELDEventTypeCodeInts = [
          ELDEventTypeCode.CYCLE_ONE,
          ELDEventTypeCode.CYCLE_TWO,
        ];

        if (
          !driverCertificationELDEventTypeCodeInts.includes(eldEventTypeCodeInt)
          && !offDutyDeferralELDEventTypeCodeInts.includes(eldEventTypeCodeInt)
          && !canadianCycleSetELDEventTypeCodeInts.includes(eldEventTypeCodeInt)) return true;
      }

      if (isActiveEvent && isDutyStatusEvent && isOfDailyCert) return true;
      return false;
    });

    let eldEventsObjectIdString = _filteredELDEvents.map(eldEvent => getAttribute(eldEvent, 'objectId'));
    eldEventsObjectIdString = eldEventsObjectIdString.join(',');

    return eldEventsObjectIdString;
  }

  /**
   * @description From ELDEditSupportTools. For things we want to bypass the entire edit flow
   *              and update the actual eldEvent itself, knowing the consequences
   */
  function onUpdateOverrideValues(eldEvent, overrideValueMap) {
    const objectId = getAttribute(eldEvent, 'objectId');
    const _eldEventOverrideValueMap = structuredClone(eldEventOverrideValueMap || {});
    if (!_eldEventOverrideValueMap[objectId]) _eldEventOverrideValueMap[objectId] = {};

    _eldEventOverrideValueMap[objectId] = { ..._eldEventOverrideValueMap[objectId], ...overrideValueMap };
    setELDEventOverrideValueMap(_eldEventOverrideValueMap);
  }

  /**
   * @description Submit the edits to generate a preview of the edit, or create the edit
   * @param {Bool} [returnPreview] - Return a preview of the edit instead of saving
   * @param {Array} [filteredEditObjects] - A filtered version of editObjects to allow preview of a subset of edits made
   */
  async function submitELDEdit(returnPreview = true, filteredEditObjects) {
    const eldEventsIdString = getELDEventsIdString();
    const _editReason = compressWhitespace(editReason || '');

    let _editObjects = editObjects;
    if (filteredEditObjects) _editObjects = filteredEditObjects;

    _editObjects = _editObjects.map(editObject => {
      const _editObject = { ...editObject };

      _editObject.editNote = _editReason;
      _editObject.eventsNote = compressWhitespace(editObject.eventsNote || '');

      if (overrideVehicle) {
        // These 4 attributes are needed to fill in the eld events when generate logs is enabled
        _editObject.vehicleObjectId = getAttribute(overrideVehicle, 'objectId');
        _editObject.vehicleUnitId = getAttribute(overrideVehicle, 'unitId');
        _editObject.vehiclePlate = getAttribute(overrideVehicle, 'plate');
        _editObject.vehicleVIN = getAttribute(overrideVehicle, 'vin');
      }

      return _editObject;
    });

    // This section handles override values
    if (eldEventOverrideValueMap) {
      // if eldEventOverrideValueMap exists and !!props.eldEvent === true, it means there are
      // values we want to override on the original event, and also have to determine if we also
      // create a new edit afterwards (if the duty status was changed along with an overriden value)
      if (props.eldEvent) {
        const eldEventObjectId = getAttribute(props.eldEvent, 'objectId');
        const editObject = editObjects[0];
        const isSameELDEventTypeCodeInt = getAttribute(props.eldEvent, 'eldEventTypeCodeInt') === editObject.eldEventTypeCodeInt;
        const isSameEventsNote = (getAttribute(props.eldEvent, 'note') || '') === (editObject.eventsNote || '');

        if (!returnPreview) {
          // if submitted
          await updateELDEvent({ sessionToken: getCurrentUserSessionToken() }, props.eldEvent, eldEventOverrideValueMap[eldEventObjectId], true);
          if (isSameELDEventTypeCodeInt && isSameEventsNote) {
            // if nothing else has changed besides override values, then close everything as if edit was submitted
            // otherwise, continue the flow
            if (props.onSubmit) props.onSubmit();
            return setVisible(false);
          }
        }
      }
    }

    // This section determines the edit type
    let editType = ELDEdit.MAJOR;

    // if it is a single event edit, loop through the requests
    // this part is mostly legacy carry-over
    if (props.eldEvent) {
      for (let i = 0; i < _editObjects.length; i++) {
        const editObject = _editObjects[i];
        // Note: only checking eldEventTypeCodeInt because in singleEdit mode the time cannot be changed
        if (getAttribute(props.eldEvent, 'eldEventTypeCodeInt') !== editObject.eldEventTypeCodeInt) {
          editType = ELDEdit.MAJOR;
          break;
        } else if (props.eldEvent.eventsNote && (props.eldEvent.eventsNote !== editObject.eventsNote)) {
          editType = ELDEdit.ADD_NOTE;
        } else if (getAttribute(props.eldEvent, 'note') !== editObject.eventsNote) {
          editType = ELDEdit.ADD_NOTE;
        }
      }
    }

    // Major edits bypass driver approval
    if (eldOverrideType === 'generate') editType = ELDEdit.OVERRIDE_MAJOR;

    // console.log('Request Summary: ', {
    //   driver,
    //   _editObjects,
    //   defaultELDEventTypeCodeInt,
    //   eldEventsIdString,
    //   returnPreview, // return preview
    //   editType,
    //   isSupportToolDomain,
    // });

    // This section handles edit submission and previewing
    const { eldEventsToBeInactive, requestedELDEvents } = await requestELDEdits(
      driver,
      _editObjects,
      defaultELDEventTypeCodeInt,
      eldEventsIdString,
      returnPreview, // return preview
      editType,
      isSupportToolDomain, // a flag indicating is support edit
    );
    // console.log(eldEventsToBeInactive, requestedELDEvents);
    if (!returnPreview) {
      // if submitted
      if (props.onSubmit) props.onSubmit();
      return setVisible(false);
    }

    // Create a copy of the eldEvents array and remove the inactive events
    const inactiveELDEventObjectIdsSet = new Set(eldEventsToBeInactive.map(eldEvent => getAttribute(eldEvent, 'objectId')));

    const _filteredELDEvents = [...filteredELDEvents].filter((eldEvent) => {
      const eldEventObjectId = getAttribute(eldEvent, 'objectId');
      return !inactiveELDEventObjectIdsSet.has(eldEventObjectId);
    });

    // From the requested events, create temporary records to be passed in to the HOS graph
    // The event returned back from requestELDEdits is a JSON representation of the event record
    const _requestedELDEvents = requestedELDEvents.map((event) => {
      const _event = event;
      _event.eldEventRecordStatusInt = 1; // Fake the event status to be an active event for the sake of previews
      return createTempParseObject('ELDEvent', _event);
    });

    // Create a new array of ELDEvents for previews. We'll also sort it just in case.
    const _eldEventsForPreview = [..._filteredELDEvents, ..._requestedELDEvents];
    sortELDEvents(_eldEventsForPreview, 1);
    setELDEventsForPreview(_eldEventsForPreview);
    // console.log(_eldEventsForPreview);
  }

  // Get the most recent vehicle location prior to eldEventDateTime and return the strings and the breakdown
  // This is mostly legacy carry-over.
  function getRecentVehicleLocationInfo(eldEventDateTime) {
    const _eldEventsForPreview = sortELDEvents([...eldEventsForPreview], 1);

    // By default return the default VehicleLocation breakdown
    const result = {
      breakdown: getLocationDescriptionBreakdown(''),
      startCustomLocation: undefined,
      endCustomLocation: undefined,
    };

    let mostRecentVehicleLocation;

    if (props.eldEvent) {
      // If its a single event edit, just get the location off the event
      const vehicleLocation = getAttribute(props.eldEvent, 'vehicleLocation', true);
      if (vehicleLocation) mostRecentVehicleLocation = vehicleLocation;
    } else if (_eldEventsForPreview.length > 0) {
      const eldEventDateTimeMillis = eldEventDateTime.valueOf();

      const filteredEldEventsForPreview = _eldEventsForPreview.filter((eldEvent) => getAttribute(eldEvent, 'vehicleLocation', true));
      for (let i = 0; i < filteredEldEventsForPreview.length; i++) {
        const _eldEvent = filteredEldEventsForPreview[i];
        const eventDateTime = getAttribute(_eldEvent, 'eventDateTime', true);
        const vehicleLocation = getAttribute(_eldEvent, 'vehicleLocation', true);

        const _eldEventDateTimeMillis = moment(eventDateTime).valueOf();

        if (vehicleLocation && (_eldEventDateTimeMillis <= eldEventDateTimeMillis)) {
          mostRecentVehicleLocation = vehicleLocation;
        } else if (!mostRecentVehicleLocation && _eldEventDateTimeMillis > eldEventDateTimeMillis && i > 0) {
          // If no recentVehicleLocation retrieved, get from before
          mostRecentVehicleLocation = getAttribute(filteredEldEventsForPreview[i - 1], 'vehicleLocation', true);
        }
      }
    }

    // Finally, if we have a most recent location prior to eldEventDateTime, get the breakdown if it is of valid format
    if (mostRecentVehicleLocation) {
      const countryCode = getAttribute(mostRecentVehicleLocation, 'countryCode', true) ?? Country.CA;
      const locationDescription = getAttribute(mostRecentVehicleLocation, CountryCodeLocationDescriptionTypes[countryCode.toUpperCase()], true);

      const isValid = isValidLocationDescription(locationDescription);

      if (isValid) {
        result.breakdown = getLocationDescriptionBreakdown(locationDescription);
        result.startCustomLocation = locationDescription;
        result.endCustomLocation = locationDescription;
        result.referenceVehicleLocationObj = mostRecentVehicleLocation;
      }
    }

    return result;
  }

  // ************ Derived Variables ************ //
  const driverFullName = formatName(getAttribute(driver, 'user_fullName', true));

  // ************ JSX ************ //
  let headerTitle = '';
  if (startTimeUTC) {
    headerTitle = moment(startTimeUTC).tz(timezoneOffsetFromUTC).format('MMM DD, YYYY');
  }
  if (props.eldEvent) headerTitle = moment(getAttribute(props.eldEvent, 'eventDateTime')).tz(timezoneOffsetFromUTC).format('HH:mm (MMM DD, YYYY)');
  headerTitle = `Editing ${headerTitle} - ${driverFullName}`;

  // we also put the graph/message body in the header to keep it static, so we have to do some interesting formatting
  const headerTemplate = [
    <div className="eld-edit-modal-header">
      {props.eldDailyCertification && (
        <div>{headerTitle}</div>
      )}
      {!props.eldDailyCertification && (
        <div>Edit Log</div>
      )}
      {isELDOverrideAvailable && isFetched && (
        <ELDOverrideInputSwitch
          eldDailyCertification={props.eldDailyCertification}
          onChange={(_eldOverrideType) => setELDOverrideType(_eldOverrideType)}
          isChecked // @todo 2024-08-09 - remove isChecked here once support issue is over, to re-enable manual toggling. Also see in ELDOverrideInputSwitch stylesheet for what to remove
        />
      )}
    </div>,
  ];

  if (visible && props.eldDailyCertification && !isLoading) {
    headerTemplate.push(
      <div className="graph-message-body">
        <HOSGraph
          eldDailyCertification={props.eldDailyCertification}
          eldEvents={eldEventsForPreview}
          associatedELDEvents={[]}
          driver={driver}
          scaleToDriverTimezone
          disableRedraw={disableRedraw}
          outlineEdits
          outlineAutoGeneratedDrivingTimes
          shortDutyStatus
          isELDOverride={eldOverrideType === 'generate'}
          // breakpoints={{ '900px': '100vw' }}
          style={{ marginBottom: '1em', overflow: 'hidden' }}
        />

        {(step === Step.DATA_ENTRY) && (
          <ELDEditModalMessageBody
            className="mb-5"
            eldDailyCertification={props.eldDailyCertification}
            driver={driver}
            timezoneOffsetFromUTC={timezoneOffsetFromUTC}
            eldEvents={filteredELDEvents}
            eldEventsForPreview={eldEventsForPreview}
            editObjects={editObjects}
            eldEvent={props.eldEvent}
            onValidationCompleted={(hasEditErrors, errors, validEditObjectLocalIdMap) => onValidationCompleted(hasEditErrors, validEditObjectLocalIdMap)}
            isELDOverride={!!eldOverrideType}
          />
        )}
      </div>
    );
  }

  // do not allow continuation if there are errors or if it is a generate
  // edit but it was not toggled on
  const didNotEnableELDOverride = isFetched && !getAttribute(props.eldDailyCertification, 'objectId', true) && !eldOverrideType;
  const footerTemplate = (
    <div className="eld-edit-modal-footer mt-3">
      {(step === Step.DATA_ENTRY) && (
        <>
          <Button
            text
            label="CANCEL"
            severity="secondary"
            onClick={() => setVisible(false)}
            sbVariant="short"
          />
          <Button
            label="CONTINUE"
            onClick={() => setStep(Step.CONFIRMATION)}
            severity="primary"
            disabled={editErrorMap.hasEditErrors || didNotEnableELDOverride}
            tooltip={editErrorMap.hasEditErrors && 'Please resolve the issues listed above before continuing'}
            tooltipOptions={{ position: 'left', showOnDisabled: editErrorMap.hasEditErrors }}
            sbVariant="short"
          />
        </>
      )}
      {(step === Step.CONFIRMATION) && (
        <>
          <Button
            text
            label="BACK"
            severity="secondary"
            onClick={() => setStep(Step.DATA_ENTRY)}
            sbVariant="short"
          />
          <Button
            label="SUBMIT EDITS"
            onClick={() => submitELDEdit(false)}
            severity="primary"
            disabled={editErrorMap.hasEditErrors || !editReason.length}
            sbVariant="short"
          />
        </>
      )}
    </div>
  );

  let className = 'eld-edit-modal';
  if (props.className) className += ` ${props.className}`;

  return (
    <>
      {visible && (
        <Dialog
          className={className}
          header={headerTemplate}
          footer={footerTemplate}
          contentClassName="eld-edit-modal-content"
          visible={visible}
          warning={!!eldOverrideType}
          style={{ width: '70em' }} // TODO: Add responsiveness to the width based on the screen size
          closable={false}
          sbVariant="compact"
        >
          {visible && isLoading && (
            <div className="flex flex-column h-full w-full justify-content-center align-items-center">
              <i className="pi pi-spin pi-spinner text-switchboard-cyan" />
            </div>
          )}
          {visible && props.eldDailyCertification && !isLoading && (
            <div className="relative">
              {(step === Step.DATA_ENTRY) && (
                <ELDEditModalEditBody
                  editObjects={editObjects.map((editObject) => {
                    // Get the breakdown from the location description. This is passed back in the edit object to properly restore the state
                    const startLocationDescriptionBreakdown = getLocationDescriptionBreakdown(editObject.startCustomLocation);
                    const endLocationDescriptionBreakdown = getLocationDescriptionBreakdown(editObject.endCustomLocation);

                    // The startDateTime/endDateTime are in the driver's timezone. We want to convert it to the current timezone of the user,
                    // but have the time be the same as what the driver would be. This is so that the edit body time matches the time of the driver's timezone
                    // e.g., if the driver's timezone is 00:00, and the user's timezone is 03:00, the edit body time should be 00:00 (driver's timezone).
                    let adjustedStartDateTime = moment.utc(editObject.startDateTime);
                    let adjustedEndDateTime = moment.utc(editObject.endDateTime);

                    const driverTimezoneOffset = moment.tz.zone(timezoneOffsetFromUTC).utcOffset(adjustedStartDateTime);
                    const currentTimezoneOffset = moment.tz.zone(moment.tz.guess()).utcOffset(adjustedStartDateTime);
                    const offsetDifference = currentTimezoneOffset - driverTimezoneOffset;

                    adjustedStartDateTime = adjustedStartDateTime.clone().add(offsetDifference, 'minutes').toDate();
                    adjustedEndDateTime = adjustedEndDateTime.clone().add(offsetDifference, 'minutes').toDate();

                    // Map the attributes from editObject to match the object used in the edit body
                    return ({
                      id: editObject.localId,
                      startDateTime: adjustedStartDateTime,
                      endDateTime: adjustedEndDateTime,
                      eldEventTypeCodeInt: editObject.eldEventTypeCodeInt,
                      notes: editObject.eventsNote,
                      startAddress: editObject.isCustomStartLocation && {
                        city: startLocationDescriptionBreakdown.city,
                        stateProvince: startLocationDescriptionBreakdown.stateProvince.code,
                        distance: startLocationDescriptionBreakdown.distance,
                        distanceUnit: startLocationDescriptionBreakdown.distanceUnit,
                        heading: startLocationDescriptionBreakdown.heading.value,
                        isCustomAddress: editObject.isCustomStartLocation
                      },
                      startAddressLocationDescription: editObject.startCustomLocation,
                      endAddress: editObject.isCustomEndLocation && {
                        city: endLocationDescriptionBreakdown.city,
                        stateProvince: endLocationDescriptionBreakdown.stateProvince.code,
                        distance: endLocationDescriptionBreakdown.distance,
                        distanceUnit: endLocationDescriptionBreakdown.distanceUnit,
                        heading: endLocationDescriptionBreakdown.heading.value,
                        isCustomAddress: editObject.isCustomEndLocation,
                      },
                      endAddressLocationDescription: editObject.endCustomLocation,
                      referenceVehicleLocationObj: editObject.referenceVehicleLocationObj,
                    });
                  })}
                  eldEvent={props.eldEvent}
                  agdtIntervals={agdtIntervals}
                  disableAddAnotherEditButton={editErrorMap.hasEditErrors}
                  onUpdate={(updatedEditObjects) => {
                    // Map the new edit attributes to conform with the legacy edit object
                    const _updatedEditObjects = updatedEditObjects.map((updatedEditObject) => {
                      // Create a proper dateTime for the given startDateTime (startDateTime has the proper time, but not date)
                      // We take the startTimeUTC, apply the proper timezone, and change the time based on startDateTime
                      const editStartDateTime = moment(updatedEditObject.startDateTime);
                      const _startDateTime = moment(startTimeUTC).tz(timezoneOffsetFromUTC);
                      _startDateTime.hours(editStartDateTime.hour());
                      _startDateTime.minutes(editStartDateTime.minute());
                      _startDateTime.seconds(editStartDateTime.second());
                      _startDateTime.milliseconds(editStartDateTime.millisecond());

                      // Create a proper dateTime for the given editEndDateTime (editEndDateTime has the proper time, but not date)
                      // We take the startTimeUTC, apply the proper timezone, and change the time based on editEndDateTime
                      const editEndDateTime = moment(updatedEditObject.endDateTime);
                      const _endDateTime = moment(startTimeUTC).tz(timezoneOffsetFromUTC);
                      _endDateTime.hours(editEndDateTime.hour());
                      _endDateTime.minutes(editEndDateTime.minute());
                      _endDateTime.seconds(editEndDateTime.second());
                      _endDateTime.milliseconds(editEndDateTime.millisecond());
                      if (editEndDateTime.minute() === 59) {
                        _endDateTime.seconds(59);
                        _endDateTime.milliseconds(59);
                      }

                      // Generate the proper vehicle location description for the custom locations
                      let _startCustomLocation;
                      let _endCustomLocation;
                      let _referenceVehicleLocationObj;

                      if (updatedEditObject.startAddress?.isCustomAddress) {
                        _startCustomLocation = getVehicleLocationDescription(
                          updatedEditObject.startAddress.city,
                          updatedEditObject.startAddress.stateProvince,
                          updatedEditObject.startAddress.distance,
                          updatedEditObject.startAddress.distanceUnit,
                          updatedEditObject.startAddress.heading,
                        );
                      }

                      if (updatedEditObject.endAddress?.isCustomAddress) {
                        _endCustomLocation = getVehicleLocationDescription(
                          updatedEditObject.endAddress.city,
                          updatedEditObject.endAddress.stateProvince,
                          updatedEditObject.endAddress.distance,
                          updatedEditObject.endAddress.distanceUnit,
                          updatedEditObject.endAddress.heading,
                        );
                      }

                      // If there is no custom location, use the most recent vehicle location
                      if (!updatedEditObject.startAddress?.isCustomAddress || !updatedEditObject.endAddress?.isCustomAddress) {
                        const { breakdown, startCustomLocation, endCustomLocation, referenceVehicleLocationObj } = getRecentVehicleLocationInfo(_startDateTime);
                        _startCustomLocation = _startCustomLocation ?? startCustomLocation;
                        _endCustomLocation = _endCustomLocation ?? endCustomLocation;
                        _referenceVehicleLocationObj = _referenceVehicleLocationObj ?? referenceVehicleLocationObj;
                      }

                      return ({
                        localId: updatedEditObject.id,
                        startDateTime: updatedEditObject.startDateTime && _startDateTime.toISOString(),
                        endDateTime: updatedEditObject.endDateTime && _endDateTime.toISOString(),
                        eldEventTypeCodeInt: updatedEditObject.eldEventTypeCodeInt || (props.eldEvent.get('eldEventTypeCodeInt')),
                        eventsNote: updatedEditObject.notes,
                        startCustomLocation: _startCustomLocation,
                        endCustomLocation: _endCustomLocation,
                        referenceVehicleLocationObj: _referenceVehicleLocationObj,
                        isCustomStartLocation: updatedEditObject.startAddress?.isCustomAddress,
                        isCustomEndLocation: updatedEditObject.endAddress?.isCustomAddress,

                        // Apply the proper values to the legacy attributes. Some of these are done in submitELDEdit()
                        // The following attributes are filled in the submitELDEdit() function: editNote, vehicleObjectId, vehicleUnitId, vehiclePlate, vehicleVIN
                        driverId: getAttribute(driver, 'objectId'),
                        eldDailyCertificationStartTimeUTC: moment.utc(startTimeUTC).toDate(),
                      });
                    });

                    setEditObjects(_updatedEditObjects);
                  }}
                />
              )}

              {(step === Step.DATA_ENTRY) && eldOverrideType && (
                <ELDEditSupportTools
                  eldOverrideType={eldOverrideType}
                  eldEvent={props.eldEvent}
                  onSelectVehicle={(vehicle) => setOverrideVehicle(vehicle)}
                  onUpdateOverrideValues={(eldEvent, overrideValueMap) => onUpdateOverrideValues(eldEvent, overrideValueMap)}
                />
              )}

              {(step === Step.CONFIRMATION) && (
                <ELDEditConfirmationMessage
                  onChangeEditReason={(_editReason) => setEditReason(_editReason)}
                  editReason={editReason}
                  isELDOverride={!!eldOverrideType}
                />
              )}
            </div>
          )}
        </Dialog>
      )}
      <ELDEditModalButton
        className={props.buttonClassName}
        isFetched={isFetched}
        eldDailyCertification={props.eldDailyCertification}
        eldEdits={eldEdits}
        eldEvent={props.eldEvent}
        toggleModalVisible={() => setVisible(true)}
        userSubscriptionPermission={props.userSubscriptionPermission}
        forceEnable={isELDOverrideAvailable}
      />
    </>
  );
}

export default ELDEditModal;
