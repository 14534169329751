import React from 'react';
import PropTypes from 'prop-types';

// CSS
import styles from './RestartPaymentModal.module.scss';

function RestartPaymentInvalidCreditCardModal(props) {
  return (
    <div>
      Your credit card information is not valid. To continue your plan, select 'Renew Now' and re-enter your information or contact us for more details.

      <br />

      <div className={styles.monthlyFee}>
        ${props.monthlyFee / 100} {props.currency}/ ELD / month
        <button className={`${styles.renewButton} buttonDefault`} onClick={props.handleRenew} disabled={props.disableButton}>Renew Now</button>
      </div>

      <div className={styles.contactUs}>
        Our team is working very hard to make sure your experience with Switchboard is <b>amazing</b>
        <br />
        Please let us know about anything we can do for you at <a href="mailto:support@onswitchboard.com">support@onswitchboard.com</a>
      </div>
    </div>
  );
}

RestartPaymentInvalidCreditCardModal.propTypes = {
  monthlyFee: PropTypes.number,
  currency: PropTypes.string.isRequired,
  handleRenew: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired,
};

export default RestartPaymentInvalidCreditCardModal;
