import React from 'react';

// Components
import InputMask from 'sbCore/InputMask/InputMask';

// Styles
import './style.scss';

/**
 * @description A component which uses InputMask.js to choose time in hours and minutes
 * @param {String} className - Custom container className
 */
export default function TimePicker({ warning, error, success, ...props }) {

  // ** Misc ** //
  let className = 'sbc-time-picker';
  if (error) {
    className += ` sbc-error`;
  } else if (warning) {
    className += ` sbc-warning`;
  } else if (success) {
    className += ` sbc-success`;
  }

  return (
    <div className={className}>
      <InputMask placeholder="hh:mm" mask="99:99" slotChar="hh/mm" {...props} />
    </div>
  );
}
