import React from 'react';
import PropTypes from 'prop-types';

// Components
import ChooseOrAddModalView from 'components/ChooseOrAddModal/view/ChooseOrAddModal';
import ChooseOrAddList from 'components/ChooseOrAddModal/container/ChooseOrAddList';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';

class ChooseOrAddModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showConfirmModal: false, showFilter: false, loading: false, filter: [], sort: {}, lastScrollPos: 0, objects: [] };
    this.handleModalChoice = this.handleModalChoice.bind(this);
    this.handleConfirmChoice = this.handleConfirmChoice.bind(this);
  }

  handleModalChoice(choiceObj) {
    if (this.props.checkFunction) {
      const needConfirm = this.props.checkFunction(choiceObj);
      if (needConfirm) {
        this.setState({ ...this.state, showConfirmModal: true, choiceObj });
      } else {
        this.props.handleChooseOrAdd(choiceObj);
        this.props.handleClose();
      }
    } else {
      this.props.handleChooseOrAdd(choiceObj);
      this.props.handleClose();
    }
  }

  handleConfirmChoice(confirmBool) {
    if (confirmBool) {
      this.props.handleChooseOrAdd(this.state.choiceObj);
      this.props.handleClose();
    } else {
      this.setState({ ...this.state, showConfirmModal: false, choiceObj: undefined });
    }
  }

  render() {
    return (
      <ChooseOrAddModalView {...this.props} handleModalChoice={this.handleModalChoice} onSubmit={null}>
        <ConfirmModal show={this.state.showConfirmModal} handleModalChoice={this.handleConfirmChoice} handleClose={() => this.setState({ ...this.state, showConfirmModal: false, choiceObj: undefined })}>
          { this.props.confirmMessage }
        </ConfirmModal>
        <ChooseOrAddList {...this.props} handleModalChoice={this.handleModalChoice} />
      </ChooseOrAddModalView>
    );
  }
}

ChooseOrAddModal.propTypes = {
  label: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
  handleChooseOrAdd: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  checkFunction: PropTypes.func,
  confirmMessage: PropTypes.node,
};

export default ChooseOrAddModal;
