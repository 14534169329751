import {
  addRecord, createTempRecord, createQuery, destroyRecord, find, getAttribute, includePointers, setQueryRestriction,
  updateRecord, getCurrentUser, copyQuery, sortQuery, count
} from 'api/Parse';
import Sort from 'sbObjects/Sort';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

/** @module OdometerJump */

/**
 * @memberof module:OdometerJump
 * @description Obtains odometer jumps from a company
 * 
 * @param {int} page - query page / limit skip multiplier
 * @param {int} limit  - amount of results we want
 * @param {array} sortBy - array of Sort objects
 * @param {array} filters - array of Filter objects
 * @param {bool} considerChildCompanies - whether or not to include child company results
 * 
 * @returns { object } - { odometerJumps: [], totalOdometerJumpCount: int }
 */
async function getOdometerJumps(page = 0, limit = 10, sortBy = new Sort('vehicleUnitId', QuerySortOrderTypes.ASCENDING), filters = [], considerChildCompanies) {
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  const companiesToConsider = [belongsToCompany];

  const odometerJumpQuery = createQuery('OdometerJump');

  if (considerChildCompanies) {
    const childrenQuery = createQuery('CompanyLink');
    setQueryRestriction(childrenQuery, QueryRestrictionTypes.EQUAL_TO, 'parentCompany', belongsToCompany);
    const childCompanies = await find(childrenQuery);
    childCompanies.map((childCompany) => companiesToConsider.push(childCompany));

    setQueryRestriction(odometerJumpQuery, QueryRestrictionTypes.CONTAINED_IN, 'belongsToCompany', companiesToConsider);
  } else {
    setQueryRestriction(odometerJumpQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
  }

  // set query restricitons from filter
  filters.map(filter => setQueryRestriction(odometerJumpQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  const odometerJumpCountQuery = copyQuery(odometerJumpQuery);

  includePointers(odometerJumpQuery, [
    'eldEventStart',
    'eldEventEnd',
    'eldEventStart.driver',
    'eldEventEnd.driver',
    'eldEventStart.vehicleLocation',
    'eldEventEnd.vehicleLocation',
    'eldDailyCertification',
    'eldDailyCertification.driver',
  ]);

  setQueryRestriction(odometerJumpQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  setQueryRestriction(odometerJumpQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
  // call the ascending/descending function on the query, passing in the attribute
  sortQuery(odometerJumpQuery, sortBy.order, sortBy.attribute);

  try {
    const [totalOdometerJumpCount, odometerJumps] = await Promise.all([count(odometerJumpCountQuery), find(odometerJumpQuery)]);
    return { odometerJumps, totalOdometerJumpCount };
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getOdometerJumps,
};
