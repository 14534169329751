import React from 'react';

import { Fieldset as PRFieldset } from 'primereact/fieldset';

// styles
import './style.scss';

function Fieldset({ ...props }) {

  let className = 'sbc-fieldset';
  if (props.className) className += ` ${props.className}`;

  if (props.sbVariant === 'dispatch') className += ' sb-dispatch';

  return (
    <PRFieldset
      {...props}
      className={className}
    />
  );

}

export default Fieldset;
