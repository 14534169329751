import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import {
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

import CircularProgress from 'material-ui/CircularProgress';

class LazyLoadingTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // ui lib
      fixedHeader: props.fixedHeader,
      fixedFooter: props.fixedFooter,
      stripedRows: props.stripedRows,
      showRowHover: props.showRowHover,
      selectable: props.selectable,
      multiSelectable: props.multiSelectable,
      enableSelectAll: props.enableSelectAll,
      deselectOnClickaway: props.deselectOnClickaway,
      showCheckboxes: props.showCheckboxes,
      height: props.height || 'auto',
    };

    this.lazyLoader = this.lazyLoader.bind(this);
  }

  componentDidMount() {
    this.lazyLoader();
  }

  componentWillReceiveProps(nextProps) {
    // this.isLazyLoading = nextProps.isLazyLoading;
  }

  componentWillMount() {
    // remove all event listeners (https://stackoverflow.com/questions/18650385/is-there-a-vanilla-function-to-remove-all-event-listeners-without-affecting-the)
    // only replace the ancestor element
    const tableBodyNode = document.querySelector('.lzyld-table-body');

    if (tableBodyNode) {
      const clone = tableBodyNode.cloneNode(false);

      //copy children backwards because of the removal
      for (let index = tableBodyNode.childNodes.length - 1; index >= 0; --index)
        clone.insertBefore(tableBodyNode.childNodes[index], clone.firstChild);

      //insert back into DOM
      tableBodyNode.parentNode.replaceChild(clone, tableBodyNode);
    }
  }

  lazyLoader() {
    const { lazyLoadScrollOffset, handleLazyLoad } = this.props;

    /*
      Note that if lazyLoadScrollOffset is used, scrolling past that buff will trigger the lazy load callback (and hence scrolling
      all the way to the bottom can trigger the lazy loading multiple times)

      The best way to counteract this would be to disable the lazy loader callback in PARENT while data is being fetched

      That way, this component is kept as clean/simple as possible and 'bare-bones'
    */
    if (handleLazyLoad) {
      let tableBodyNode = ReactDOM.findDOMNode(this.refs['lzld-table-body']).parentNode.parentNode.parentNode;

      tableBodyNode.addEventListener('scroll', (e) => {
        const { scrollTop, scrollHeight, offsetHeight } = e.target;

        // user scrolled to (near) bottom, so call lazy load fn to load moar!
        const scrollBottom = scrollHeight - offsetHeight;

        if ((scrollTop + (lazyLoadScrollOffset || 0)) >= scrollBottom) {
          handleLazyLoad();
        }
      });
    }
  }

  render() {

    const props = { ...this.props };
    const state = { ...this.state };

    const tableHeaderRows = (props.tableHeaderRows || []).map((row, rowIndex) => {
      const rowColumns = (row.columns || []).map((column, columnIndex) => {

        return (
          <TableHeaderColumn key={column.key || `header-col-${rowIndex}-${columnIndex}`} {...column.props}>
            {column.element}
          </TableHeaderColumn>
        );
      });

      return (
        <TableRow key={row.key || `header-row-${rowIndex}`} {...row.props}>
          {rowColumns}
        </TableRow>
      );
    });

    let tableBodyRows = [];
    if (props.isLoading) {
      tableBodyRows.push(
        <TableRow key="circularProgress">
          <TableRowColumn key="circularProgress" colSpan="420" style={{ paddingTop: '2em', textAlign: 'center' }}>
            <CircularProgress />
          </TableRowColumn>
        </TableRow>
      );
    }
    else {
      tableBodyRows = (props.tableBodyRows || []).map((row, rowIndex) => {

        const rowColumns = (row.columns || []).map((column, columnIndex) => {

          return (
            <TableRowColumn key={column.key || `body-col-${rowIndex}-${columnIndex}`} {...column.props}>
              {column.element}
            </TableRowColumn>
          );
        });

        return (
          <TableRow key={row.key || `body-row-${rowIndex}`} {...row.props}>
            {rowColumns}
          </TableRow>
        );
      });
    }

    return (
      <div className='overflow-auto'>
        {props.isLazyLoading &&
          <CircularProgress
            style={{ position: 'absolute', bottom: 0, right: '1.3em', zIndex: '9999999' }}
          />
        }
        <Table
          className={`lzld-table ${(props.tableClass ? props.tableClass : '')}`}
          height={state.height}
          fixedHeader={state.fixedHeader}
          fixedFooter={state.fixedFooter}
          selectable={state.selectable}
          multiSelectable={state.multiSelectable}
          onCellClick={props.onCellClick}
          bodyStyle={{ overflow: 'visible' }}
        >
          <TableHeader
            displaySelectAll={state.showCheckboxes}
            adjustForCheckbox={state.showCheckboxes}
            enableSelectAll={state.enableSelectAll}
            className="translate-me"
          >
            {tableHeaderRows}
          </TableHeader>

          <TableBody
            className={`lzld-table-body translate-me ${(props.tableClass ? props.tableClass : '')}`}
            ref="lzld-table-body"
            displayRowCheckbox={state.showCheckboxes}
            deselectOnClickaway={state.deselectOnClickaway}
            showRowHover={state.showRowHover}
            stripedRows={state.stripedRows}
          >
            {tableBodyRows}
          </TableBody>

        </Table>
      </div>
    );
  }
}

LazyLoadingTable.propTypes = {
  isLoading: PropTypes.bool, // determine if init is still in progress. different from isLazyLoading where init is completed
  isLazyLoading: PropTypes.bool, // determines if show content loading on bottom of list
  tableHeaderRows: PropTypes.array,  // see example below
  tableBodyRows: PropTypes.array, // see example below
  handleLazyLoad: PropTypes.func,
  lazyLoadScrollOffset: PropTypes.number,

  // UI Lib Table props
  onCellClick: PropTypes.func,
  fixedHeader: PropTypes.bool,
  fixedFooter: PropTypes.bool,
  stripedRows: PropTypes.bool,
  showRowHover: PropTypes.bool,
  selectable: PropTypes.bool,
  multiSelectable: PropTypes.bool,
  enableSelectAll: PropTypes.bool,
  deselectOnClickaway: PropTypes.bool,
  showCheckboxes: PropTypes.bool,
  height: PropTypes.any,
  tableClass: PropTypes.string
};

/*
  tableHeaderRowProps (same concept as tableBodyRowProps) is an array of objects. Each object in the array
  represents a table row, in that order. In each object (row), contains {{ props }} properties which is a spread on props for that row
  and the columns (array) of that row which in itself also has a props attribute for each column (object)

  tableHeaderRows: [
    row0: {
      key: "...",
      props: { ... },
      columns: [
        column0: {
          key: "...",
          element: <div>...</div>,
          props: {
            style: { ... }
            colSpan: "3",
            tooltip: "narf",
            ...
          }
        },

        column1: { ... },
        ...
      ]
    },

    row1: ...

*/


export default LazyLoadingTable;