import React from 'react';

import { Chip as PRChip } from 'primereact/chip';

import './style.scss';

/**
 * @description An input label that accepts text
 * @param {String} className - Custom container className
 * @returns
 */
function Chip({ warning, error, ...props }) {
  let className = 'sbc-chip';
  if (props.className) className += ` ${props.className}`;
  // if (error) {
  //   className += ` sbc-error`;
  // } else if (warning) {
  //   className += ` sbc-warning`;
  // }

  return (<PRChip {...props} className={className} />);
}

export default Chip;
