import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// API
import { saveNoteToEDC } from 'api/Equipment/Vehicle.old';
import { toTitleCase } from 'api/Helpers';

// Components
import LazyLoadingTable from 'components/LazyLoadingTable/view/LazyLoadingTable';
import CircularProgress from 'material-ui/CircularProgress';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import Toggle from 'material-ui/Toggle';
import TextField from 'material-ui/TextField';
import { MDBRow, MDBCol } from 'mdbreact';
import VehicleDiagnosticsDescription from './VehicleDiagnosticsDescription';

// CSS
import styles from '../view/VehicleDiagnosticsTable.module.scss';

class VehicleDiagnosticsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      walkthroughState: {
        aboutPreferences: false,
      },
      tableDisplays: {}
    }
    this.toggleWalkthoughState = this.toggleWalkthoughState.bind(this);
    this.handleDetailsTableToggle = this.handleDetailsTableToggle.bind(this);
    this.saveNoteOnEDC = this.saveNoteOnEDC.bind(this);
  }

  toggleWalkthoughState(walkthroughStateAttribute) {
    const newState = { ...this.state };
    newState.walkthroughState = { ...this.state.walkthroughState };
    newState.walkthroughState[walkthroughStateAttribute] = !this.state.walkthroughState[walkthroughStateAttribute];
    this.setState(newState);
  }

  handleDetailsTableToggle(event, isInputChecked, id) {
    const newState = { ...this.state };
    newState.tableDisplays[id] = { 'showSummary': isInputChecked };
    this.setState(newState);
  }

  saveNoteOnEDC(edcId, note) {
    saveNoteToEDC(edcId, note);
  }

  render() {
    const { edcs, vehicles, filter } = this.props;
    const column10 = { width: '10%' };
    const column15 = { width: '15%' };
    const column15pl0 = { width: '15%', paddingLeft: 0 };
    const column20 = { width: '20%' };
    const column30 = { width: '30%' };
    const column40 = { width: '40%' };
    const column50 = { width: '50%' };
    const column55pl0 = { width: '55%', paddingLeft: 0 };
    const column85 = { width: '85%' };
    const dateViewHeaderRows = [{
      key: 'header', columns: [
        {
          element: <div>First Seen</div>, props: { style: column15 }
        },
        {
          element: <div>Fault Code</div>, props: { style: column15 }
        },
        {
          element: <div className="pl-2">Time</div>, props: { style: column15pl0 }
        },
        {
          element: <div>Note</div>, props: { style: column55pl0 }
        }
      ]
    }];
    const summaryHeaderRows = [{
      key: 'header', columns: [
        {
          element: <div>Fault Code</div>, props: { style: column10 }
        },
        {
          element: <div>Frequency</div>, props: { style: column30 }
        },
        {
          element: <div>Drivers</div>, props: { style: column20 }
        },
        {
          element: <div>Description</div>, props: { style: column40 }
        }
      ]
    }];

    let mainBodyRows = [];
    if (this.props.isLoading) {
      return <CircularProgress />
    } else if (edcs && edcs.length > 0) {

      // Sort vehicles according to
      let sortedVehicles = vehicles.sort(function (a, b) {
        let aEDC = edcs.filter((value) => {
          return value.get('vehicle').id === a.id;
        });
        let bEDC = edcs.filter((value) => {
          return value.get('vehicle').id === b.id;
        });
        return (aEDC > bEDC ? -1 : (aEDC === bEDC ? 0 : 1));
      });

      mainBodyRows = sortedVehicles.map((vehicle, index) => {
        const unitId = vehicle.get('unitId');
        const vin = vehicle.get('vin');
        let tByDate = []; // Current vehicle's malfunction data (broken down by malfunction code)
        let tByMalfunction = []; // Current vehicle's date data (broken down by date)
        let vehicleEDCs = edcs.filter((value, index, arr) => {
          return value.get('vehicle').id === vehicle.id;
        });
        let edcCount = {};
        let edcOccuranceDates = {};
        let dateBreakdown = {};
        vehicleEDCs.forEach((edc) => {
          let malcode = edc.get('malfunctionCode');
          edcCount[malcode] = edcCount[malcode] ? edcCount[malcode] + 1 : 1;
          edcOccuranceDates[malcode] = edcOccuranceDates[malcode] ? [...edcOccuranceDates[malcode], (edc.get('loggedAtUTC') ? edc.get('loggedAtUTC') : edc.get('createdAt'))] : [edc.get('loggedAtUTC') ? edc.get('loggedAtUTC') : edc.get('createdAt')];
          let d = moment(edc.get('loggedAtUTC') ? edc.get('loggedAtUTC') : edc.get('createdAt')).format('MMM D, YYYY');
          dateBreakdown[d] = dateBreakdown[d] ? [...dateBreakdown[d], edc] : [edc];
        });
        let uniqueDates = Object.keys(dateBreakdown);
        tByDate = uniqueDates.sort((a, b) => {
          let at = dateBreakdown[a][0].get('loggedAtUTC') ? dateBreakdown[a][0].get('loggedAtUTC').getTime() : dateBreakdown[a][0].get('createdAt').getTime();
          let bt = dateBreakdown[b][0].get('loggedAtUTC') ? dateBreakdown[b][0].get('loggedAtUTC').getTime() : dateBreakdown[b][0].get('createdAt').getTime();
          return at > bt ? 1 : (at == bt ? 0 : -1);
        }).map((date) => {
          let vehicleMalcodesOn = vehicleEDCs.filter((value, index, array) => {
            let d = moment(value.get('loggedAtUTC') ? value.get('loggedAtUTC') : value.get('createdAt')).format('MMM D, YYYY');
            return d === date;
          });
          let malsBody = new Array();
          for (let i = 0; i < vehicleMalcodesOn.length; i++) {
            let current = vehicleMalcodesOn[i];
            malsBody.push(<MDBRow key={current.id}>
              <MDBCol size="2" className="d-flex align-items-center">{current.get('malfunctionCode')}</MDBCol>
              <MDBCol size="2" className="d-flex align-items-center">{moment(current.get('loggedAtUTC') ? current.get('loggedAtUTC') : current.get('createdAt')).format('HH:mm')}</MDBCol>
              <MDBCol size="8">
                {/* <TextField defaultValue={current.get('note')} hintText="Quick note..." fullWidth={true} onBlur={(e) => { this.saveNoteOnEDC(current.id, e.target.value) }} /> */}
                <VehicleDiagnosticsDescription code={current.get('malfunctionCode')} />
              </MDBCol>
            </MDBRow>);
          }
          return ({
            key: date,
            props: {
              id: date,
              selectable: false,
            },
            columns: [
              {
                props: { style: column15 },
                element: <div>{date}</div>
              },
              {
                props: { style: column85 },
                element: <span>{malsBody}</span>
              }
            ]
          });
        });
        let uniqueMals = Object.keys(edcCount);
        tByMalfunction = uniqueMals.map((edc) => {
          let uniqueDrivers = [];
          let edcsWithDriver = vehicleEDCs.filter((value, index, arr) => {
            return value.get('driver') != null;
          }).map((value, index, arr) => {
            return value.get('driver').get('user_fullName');
          });
          edcsWithDriver.map((value, index, arr) => {
            if (uniqueDrivers.indexOf(value) === -1) {
              uniqueDrivers.push(value);
            }
          });
          edcOccuranceDates[edc] = edcOccuranceDates[edc].sort((a, b) => {
            return (a.getTime() > b.getTime() ? 1 : a.getTime() < b.getTime() ? -1 : 0)
          });
          let hoursBetween = ((edcOccuranceDates[edc][edcOccuranceDates[edc].length - 1].getTime() - edcOccuranceDates[edc][0].getTime()) / 3600000).toFixed(2);
          let daysBetween = Math.round(hoursBetween / 24);
          return ({
            key: edc,
            props: {
              id: edc,
              selectable: false,
            },
            columns: [
              {
                props: { style: column10},
                element: <div><b>{edc}</b></div>
              },
              {
                props: { style: column30},
                element: <div>{(edcCount[edc] < 2 ? "Occured once in period" : (edcCount[edc] + " times in " + (daysBetween > 1 ? daysBetween + " days" : hoursBetween + " hours")))}</div>
              },
              {
                props: { style: column20},
                element: <div>{uniqueDrivers.map((value) => toTitleCase(value)).join(', ') || 'Unknown'}</div>
              },
              {
                props: { style: column40},
                element: <VehicleDiagnosticsDescription code={edc} />
              },
            ]
          });
        });
        let rowTitle =
          <MDBRow>
            <MDBCol size="1" >{unitId}</MDBCol>
            <MDBCol size="3" className={styles.normal}>{vin}</MDBCol>
            <MDBCol size="2" className={styles.normal}>{vehicle.get('type')}</MDBCol>
            <MDBCol size="1" className="text-center">{vehicleEDCs.length}</MDBCol>
            <MDBCol size="1" className="text-center">{uniqueMals.length}</MDBCol>
          </MDBRow>;
        return (
          <Card key={vehicle.id} className={styles.noPadding}>
            <CardHeader
              className={styles.cardHeader}
              title={rowTitle}
              actAsExpander={true}
              showExpandableButton={true}
            />
            <CardText className={styles.borderBottom} expandable={true}>
              <div style={{ width: '100%', overflow: 'hidden' }}>
                <Toggle style={{ width: '150px', float: 'right' }} label="Fault View" onToggle={(event, checked) => { this.handleDetailsTableToggle(event, checked, vehicle.id) }} />
              </div>
              {(!this.state.tableDisplays[vehicle.id] || !this.state.tableDisplays[vehicle.id].showSummary) &&
                <LazyLoadingTable
                  tableHeaderRows={dateViewHeaderRows}
                  tableBodyRows={tByDate}
                  selectable={false}
                  multiSelectable={false}
                  enableSelectAll={false}
                  showCheckboxes={false}
                  handleLazyLoad={undefined}
                  isLoading={this.props.isLoading}
                  isLazyLoading={this.props.isLazyLoading}
                />}
              {(this.state.tableDisplays[vehicle.id] && this.state.tableDisplays[vehicle.id].showSummary) &&
                <LazyLoadingTable
                  tableHeaderRows={summaryHeaderRows}
                  tableBodyRows={tByMalfunction}
                  selectable={false}
                  multiSelectable={false}
                  enableSelectAll={false}
                  showCheckboxes={false}
                  handleLazyLoad={undefined}
                  isLoading={this.props.isLoading}
                  isLazyLoading={this.props.isLazyLoading}
                />}
            </CardText>
          </Card >
        );
      });

    } else {
      return (<div className={styles.nonAvailable}>No diagnostic information available for time period</div>)
    }
    // let period;
    // if (filter.length > 0) {
    //   for (var i = 0; i < filter.length; i++) {
    //     if (filter[i].fromValue) {
    //       period = filter[i].toValue ? filter[i].toValue.valueOf() - filter[i].fromValue.valueOf() : new Date().getTime() - filter[i].fromValue.valueOf();
    //       break;
    //     }
    //   }
    // }
    // console.log("PERIOD = " + period);
    // console.log("PERIOD HOURS = " + Math.round(period / 3600000));
    // console.log("PERIOD DAYS = " + Math.round(period / 3600000 / 24));
    return (
      <div style={{ position: 'relative' }} className={styles.vehicleDiagnosticsTable}>
        <MDBRow className={styles.tHeader}>
          <MDBCol size="1" className="pl-0">Unit ID</MDBCol>
          <MDBCol size="3">VIN</MDBCol>
          <MDBCol size="2">Type</MDBCol>
          <MDBCol size="1">Faults {filter.length > 0 ? <span></span> : <b>(live)</b>}</MDBCol>
          <MDBCol size="1">Unique Fault</MDBCol>
        </MDBRow>
        {mainBodyRows}
      </div>
    );
  }
}

VehicleDiagnosticsTable.propTypes = {
  vehicles: PropTypes.any.isRequired,
  edcs: PropTypes.any,
  isLoading: PropTypes.bool,
  isLazyLoading: PropTypes.bool,
  handleLazyLoad: PropTypes.func,
  filter: PropTypes.array,
};

export default VehicleDiagnosticsTable;
