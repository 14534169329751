import { sortQuery, getCurrentUser, addRecord, createQuery, setQueryRestriction, updateRecord, find, includePointers, getAttribute } from 'api/Parse';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';
import moment from 'moment-timezone';
import { ExcludedRegulations } from 'sb-csapi/dist/enums/HOS/Regulation';

/** @module HOSViolation */

/**
 * @memberof module:HOSViolation
 *
 * @param {*} driver
 * @param {*} regulationIntArr
 * @param {*} startDate
 * @param {*} endDate
 * @param {array} excludedRegulationIntArr
 * @param {*} getHidden
 * @param {*} getDisabled
 * @param {*} getPotential
 *
 * @returns
 */
async function getHosViolations(driver, regulationIntArr, startDate, endDate, excludedRegulationIntArr, getHidden, getDisabled, getPotential) {
  const hosViolationQuery = createQuery('HOSViolation');
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
  setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.EXISTS, 'eldDailyCertification');
  setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.EXISTS, 'isHidden');
  if (startDate) {
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'triggerTime', moment(startDate).toDate());
  }
  if (endDate) {
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'triggerTime', moment(endDate).toDate());
  }
  if (driver) {
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.EQUAL_TO, 'driver', driver);
  }
  if (regulationIntArr) {
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.CONTAINED_IN, 'regulationInt', regulationIntArr);
  }
  if (excludedRegulationIntArr) {
    // hosViolationQuery.notContainedIn('regulationInt', excludedRegulationIntArr);
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.NOT_CONTAINED_IN, 'regulationInt', excludedRegulationIntArr);
  }
  if (!getDisabled) {
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.EQUAL_TO, 'disabled', false);
  } else {
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.EQUAL_TO, 'disabled', true);
  }
  if (!getPotential) {
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.EQUAL_TO, 'potential', false);
  } else {
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.EQUAL_TO, 'potential', true);
  }
  sortQuery(hosViolationQuery, QuerySortOrderTypes.DESCENDING, 'triggerTime');
  includePointers(hosViolationQuery, [
    'driver',
    'eldDailyCertification',
  ]);

  const hosViolationArr = (await find(hosViolationQuery, false, true));
  const filteredHosViolationArr = hosViolationArr.filter((hosViolation) => {
    if (hosViolation.get('hideForever')) return false;

    if (
      !getHidden
      && hosViolation.get('isHidden')
    ) {
      return false;
    }

    if (
      !getHidden
      && hosViolation?.get('eldDailyCertification')?.get('disabled')
    ) {
      return false;
    }

    return true;
  });
  return filteredHosViolationArr;
}

export {
  getHosViolations,
};
