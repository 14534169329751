import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { Toggle, SelectField, MenuItem } from 'material-ui';

// CSS
import styles from './PastDueModal.module.scss';

function PastDueModal(props) {
  return (
    <div className="translate-me">
      Your subscription with Switchboard is currently <kbd>PAST DUE</kbd>

      <br />

      <div className={styles.description}>
        {props.resellerCompanyObj &&
          (
            <div>
              This is most likely due to an issue with <var>{props.resellerCompanyObj.get('name')}</var>. Please contact <var>{props.resellerCompanyObj.get('name')}</var> to resolve.
            </div>
          )
        }
        {!props.resellerCompanyObj &&
          (
            <div>
              This is most likely due to the fact that your credit card had an error with being charged.
              <br />
              <br />
              <b>If this error is unexpected:</b> please contact your bank to see if there was something wrong with the charge.
              <br />
              <br />
              {!props.disableCCEntry &&
                <div>
                  <b>If you wish to use a new credit card:</b>&nbsp;&nbsp;
                  <button
                    className={`${styles.startButton} buttonDefault`}
                    onClick={props.handleEnterCC}
                    disabled={props.disableButton}
                  >
                    Enter New Credit Card Info
                  </button>
                </div>
              }
            </div>
          )
        }
      </div>

      <hr />

      <div className={styles.contactUs}>
        Our team is working hard to make sure your experience with Switchboard is <b>amazing</b>
        <br />
        Please let us know about anything we can do for you at <a href="mailto:support@onswitchboard.com">support@onswitchboard.com</a>
      </div>
    </div>
  );
}

PastDueModal.propTypes = {
  handleEnterCC: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
};

export default PastDueModal;
