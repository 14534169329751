import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// API
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';

// Components
import LinkedCompaniesView from 'components/LinkedCompanies/view/LinkedCompanies';
import LinkCompanyModal from 'components/LinkedCompanies/container/LinkCompanyModal';

// Context
import StoreContext from 'contexts/StoreContext';

class LinkedCompanies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyLinkArr: [],
      showLinkCompanyModal: false,
      loading: true,
    };

    this.refreshCompanyLinks = this.refreshCompanyLinks.bind(this);
    this.deleteChildCompanyLink = this.deleteChildCompanyLink.bind(this);
    this.authorizeCompanyLink = this.authorizeCompanyLink.bind(this);
    this.unauthorizeCompanyLink = this.unauthorizeCompanyLink.bind(this);
  }

  componentDidMount() {
    if (this.props.Company.company) {
      this.refreshCompanyLinks();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.companyLinkArr && nextProps.Company.company && nextProps.Company.company.id) {
      this.refreshCompanyLinks();
    }
  }

  refreshCompanyLinks() {
    this.setState({ ...this.state, companyLinkArr: [], loading: true }, () => {
      Getters.queryCompanyObjects('CompanyLink', undefined, undefined, [{ queryType: 'equalTo', name: 'childCompany', value: this.props.Company.company }], undefined, ['parentCompany', 'childCompany'], false, true).then((childCompanyLinkArr) => {
        Getters.queryCompanyObjects('CompanyLink', undefined, undefined, [{ queryType: 'equalTo', name: 'parentCompany', value: this.props.Company.company }], undefined, ['parentCompany', 'childCompany'], false, true).then((parentCompanyLinkArr) => {
          this.setState({ ...this.state, companyLinkArr: this.state.companyLinkArr.concat(childCompanyLinkArr, parentCompanyLinkArr), loading: false });
        });
      });
    });
  }

  deleteChildCompanyLink(companyLinkObj) {
    this.setState({ ...this.state, companyLinkArr: [], loading: true }, () => {
      Setters.unauthorizeCompanyLink(companyLinkObj.id).then(() => {
        companyLinkObj.destroy().then(() => {
          this.refreshCompanyLinks();
        });
      });
    });
  }

  authorizeCompanyLink(companyLinkObj) {
    this.setState({ ...this.state, companyLinkArr: [], loading: true }, () => {
      Setters.authorizeCompanyLink(companyLinkObj.id).then(() => {
        this.refreshCompanyLinks();
      });
    });
  }

  unauthorizeCompanyLink(companyLinkObj) {
    this.setState({ ...this.state, companyLinkArr: [], loading: true }, () => {
      Setters.unauthorizeCompanyLink(companyLinkObj.id).then(() => {
        this.refreshCompanyLinks();
      });
    });
  }

  render() {
    return (
      <div>
        <LinkedCompaniesView
          company={this.props.Company.company}
          companyLinkArr={this.state.companyLinkArr}
          showLinkCompanyModal={() => this.setState({ ...this.state, showLinkCompanyModal: true })}
          loading={this.state.loading}
          authorizeCompanyLink={this.authorizeCompanyLink}
          unauthorizeCompanyLink={this.unauthorizeCompanyLink}
          deleteChildCompanyLink={this.deleteChildCompanyLink}
        />
        <LinkCompanyModal
          show={this.state.showLinkCompanyModal}
          handleClose={() => {
            this.setState({ ...this.state, showLinkCompanyModal: false }, () => {
              this.refreshCompanyLinks();
            });
          }}
        />
      </div>
    );
  }
}

LinkedCompanies.propTypes = {
  Company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Company } = state;
  return {
    Company,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(LinkedCompanies);

