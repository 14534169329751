import React from 'react';
import PropTypes from 'prop-types';

// Context
import DispatchJobLayoutContext from 'contexts/DispatchJobLayoutContext';

// sbCore
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';

// api
import { getAttribute } from 'api/Parse';

// enums
import { DispatchReferenceTypes, getRefDescriptionByTypeValue } from 'enums/DispatchReference';

// styles
import './styles.scss';

class ReferenceNumberSet extends React.Component {
  static contextType = DispatchJobLayoutContext;

  constructor(props) {
    super(props);
  }

  async refreshState() {
    const { props } = this;
    return await props.refreshState();
  }

  render() {
    const { props, state } = this;

    const referenceNumbersTable = (
      <DataTable
        value={props.referenceNumbers}
        emptyMessage="No Reference Numbers"
      >
        <Column header="Type" body={(row) => <p>{getAttribute(row, 'type') === DispatchReferenceTypes.CUSTOM.value ? getAttribute(row, 'customName') : getRefDescriptionByTypeValue(getAttribute(row, 'type'))}</p>}/>
        <Column header="Value" body={(row) => <p>{ getAttribute(row, 'value') }</p>} />
      </DataTable>
    );

    return (
      <div className="job-reference-numbers flex flex-column" style={{ gap: '1rem' }}>
        <div className="flex" style={{ gap: '1rem' }}>
          <div className="flex" style={{ gap: '1rem', width: '100%' }}>
            <p className="font-bold text-xl">{props.order ? `Shipment #${props.order}` : 'General'}</p>
          </div>
        </div>

        <div className="flex flex-column mb-4" style={{ gap: '1rem' }}>
          {referenceNumbersTable}
        </div>
      </div>
    );
  }
}

ReferenceNumberSet.propTypes = {
  order: PropTypes.number, // The shipment number, 0 if not linked to a shipment
  referenceNumbers: PropTypes.arrayOf(PropTypes.object),
};

ReferenceNumberSet.defaultProps = {
  referenceNumbers: [],
};

export default ReferenceNumberSet;
