import React from 'react';

import { InputTextarea as PRInputTextarea } from 'primereact/inputtextarea';

import './styles.scss';

/**
 * @description Prime React InputTextarea component
 */
export default function InputTextarea({ warning, error, success, ...props }) {
  let className = 'sbc-input-textarea';
  if (props.className) className += ` ${props.className}`;

  if (error) {
    className += ` sbc-error`;
  } else if (warning) {
    className += ` sbc-warning`;
  } else if (success) {
    className += ` sbc-success`;
  }

  return (<PRInputTextarea { ...props } className={className} />);
}
