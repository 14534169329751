import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// Components
import ELDDrivingTimeSwapModal from '../view/ELDDrivingTimeSwapModal';

class ELDDrivingTimeSwap extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      driverELDEvents: [],
      driverAssociatedELDEvents: [],
      coDriverELDEvents: [],
      coDriverAssociatedELDEvents: [],
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose(updateParentState) {
    this.setState({ ...this.state, show: false }, () => {
      if (updateParentState) {
        this.props.updateHandler(); // send response back to parent container telling it to update eld events
      }
    });
  }

  render() {

    return (
      <div className="inline">

        { this.state.show &&
          <ELDDrivingTimeSwapModal
            show={this.state.show}
            handleClose={this.handleClose}
            driver={this.props.driver}
            coDriver={this.props.coDriver}
            scaleToDriverTimezone
            onDate={new Date(this.props.onDate)}
          />
        }


        <button className={`buttonDefault ${this.props.className}`} disabled={this.props.disabled} onClick={() => this.setState({ ...this.state, show: true })}>
          <span>{t('Swap Driving Time')}</span>
        </button>

      </div>
    );
  }

}

ELDDrivingTimeSwap.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  driver: PropTypes.object,
  coDriver: PropTypes.object,
  onDate: PropTypes.any, // should be a date tho, keep as any for now
  updateHandler: PropTypes.func, // call it if we want the list of eld event to be updated upon change/add event
};

export default ELDDrivingTimeSwap;
