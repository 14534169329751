import React from 'react';

// sbCore Components
import Button from 'sbCore/Button/Button';

// Styles
import './style.scss';

/**
 * @description Button that displays the upload document dialog when clicked
 * @param {Function} showUploadDocument - Sets the parent component's showUploadDocumentDialog state to true
 */
function DispatchUploadDocumentButton({ ...props }) {

  // ** Callback Functions ** //
  // Sets the parent component's showUploadDocumentDialog state to true if the prop function exists
  const showUploadDocument = () => {
    if (!props.showUploadDocument) return;

    props.showUploadDocument();
  };

  return (
    <div className="dispatch-upload-document-button">
      <Button icon="pi pi-upload" label="Upload Document" onClick={showUploadDocument} />
    </div>
  );
}

export default DispatchUploadDocumentButton;
