import { useEffect, useState } from "react";

// Components
import Dropdown from 'sbCore/Dropdown/Dropdown';
import InputText from 'sbCore/InputText/InputText';
import InputLabel from 'sbCore/InputLabel/InputLabel';

import './style.scss';

/**
 * Contains the filter and sortby components for the Maps Sidebar. This search/filter handles both "All Equipment" and "Equipment Group" views.
 * @param {bool} isDisabled - Determines whether the filters and sortby options are disabled
 * @param {int} activeIndex - The active tab index (0 = vehicles, 1 = trailers, 2 = geofences)
 * @param {bool} hideSort - Determines whether the sortby field should be hidden
 * @param {bool} hideSearch - Determines whether the search field should be hidden
 * @param {Function} onSortOptionChange - callback function when the sort option changes
 * @param {Function} onSearchValueChange - callback function when the search value changes
 * @returns 
 */
function SidebarFilter({ ...props }) {
  const [searchValue, setSearchValue] = useState('');
  const [sortBy, setSortBy] = useState('Last Updated');

  useEffect(() => {
    if (props.onSortOptionChange) props.onSortOptionChange(sortBy);
  }, [sortBy]);

  useEffect(() => {
    if (props.onSearchValueChange) props.onSearchValueChange(searchValue);
  }, [searchValue]);

  const sortByOptions = [
    { value: 'Last Updated', icon: 'update' },
    { value: props.activeIndex === 2 ? 'Name' : 'Unit ID', icon: props.activeIndex === 2 ? 'terrain' : 'local_shipping' },
  ];

  // Template for the currently selected sortBy type
  const selectedTypeTemplate = (option) => {
    if (option) {
      return (
        <div className="flex align-content-center">
          <span className={`material-icons${props.hideSearch ? ' mr-2' : ''}`}>
            {option.icon}
          </span>
          {props.hideSearch && option.value}
        </div>
      );
    }
  }

  // Template for the dropdown options of sortBy types
  const typeOptionTemplate = (option) => {
    return (
      <div>
        <span className="material-icons mr-2">{option.icon}</span>
        {option.value}
      </div>
    );
  }

  // set default sorting icon when tabs are changed, geofence is sorted by name and equipments are sorted by last updated by default
  useEffect(() => {
    setSearchValue('');
    setSortBy(props.activeIndex === 2 ? 'Name' : 'Last Updated')
  }, [props.activeIndex]);

  return (
    <div className="sidebar-filter flex pt-2 pb-3">
      {!props.hideSearch &&
        <div className={`${props.hideSort ? 'w-full' : 'w-9 mr-2'}`}>
          <InputLabel className="sidebar-label">Search By</InputLabel>
          <InputText
            className="w-full text-sm"
            placeholder={props.searchPlaceholder ?? "Unit ID"}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            disabled={props.isDisabled}
          />
        </div>
      }
      {!props.hideSort &&
        <div className={`${props.hideSearch ? 'w-full' : 'w-3'}`}>
          <InputLabel className="sidebar-label">Sort By</InputLabel>
          <Dropdown
            className="sidebar-filter-sortby w-full"
            value={sortBy}
            options={sortByOptions}
            optionLabel="value"
            tooltip={sortByOptions.find((option) => option.value === sortBy)?.value}
            valueTemplate={selectedTypeTemplate}
            itemTemplate={typeOptionTemplate}
            onChange={(e) => setSortBy(e.value)}
            disabled={props.isDisabled}
          />
        </div>
      }
    </div>
  );
}

export default SidebarFilter;