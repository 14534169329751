import React, { useState, useEffect } from 'react';

// API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import Skeleton from 'sbCore/Skeleton/Skeleton';
import Button from 'sbCore/Button/Button';

// Styling
import './style.scss';

/**
 * @description Display a Vehicle's name
 * @param {Vehicle} vehicle - The selected Vehicle record
 * @param {bool} isLoading - Whether or not to show a loading skeleton
 * @param {Function} onUnselectVehicle - Callback when the remove button is clicked. Passes back the vehicle if the user wants it
 * @param {Function} onToggleEditVehicle - Callback when the edit button is clicked (ex. to toggle edit dialog)
 * @returns
 */
function VehicleCard({ ...props }) {

  // ** useStates ** //
  const [vehicle, setVehicle] = useState(undefined);

  // ** useEffects ** //
  useEffect(() => {
    setVehicle(props.vehicle);
  }, [props.vehicle]);

  // ** Callbacks ** //
  function onUnselectVehicle() {
    if (props.onUnselectVehicle) {
      props.onUnselectVehicle(vehicle);
    }
    setVehicle(undefined);
  }

  // ** Button Components ** //
  const unselectButton = (
    <Button
      icon="pi pi-trash"
      className="p-button-rounded p-button-danger"
      onClick={() => onUnselectVehicle()}
    />
  );

  // ** Vehicle Attributes ** //
  const vehicleUnitId = getAttribute(vehicle, 'unitId', true);

  // ** Card Text Information ** //
  const unitIdText = (
    <div>
      <div className={`name font-semibold${!vehicleUnitId ? ' text-warning' : ''}`}>{vehicleUnitId || 'No Id Provided'}</div>
    </div>
  );

  // ** Loading Skeleton ** //
  const skeleton = (
    <div className="custom-skeleton">
      <div className="flex">
        <Skeleton shape="circle" size="2.625rem" className="mr-2"></Skeleton>
        <div>
          <Skeleton width="8rem" height=".6rem" className="mb-2"></Skeleton>
          <Skeleton width="5rem" height=".6rem" className="mb-2"></Skeleton>
          <Skeleton height=".4rem"></Skeleton>
        </div>
      </div>
    </div>
  );

  // ** classNames ** //
  let className = 'vehicle-card';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      {!props.isLoading && (
        <div className="vehicle-card">
          {unselectButton}
          {unitIdText}
        </div>
      )}
      {props.isLoading && skeleton}
    </div>
  );
}

export default VehicleCard;
