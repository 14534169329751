export default class Company {
  /**
   * 
   * @param {string} objectId
   * @param {string} name
   */
	constructor(objectId, name) {
		this.objectId = objectId;
		this.name = name;
	}
}