import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';

// Maps
import Map, { NavigationControl, FullscreenControl, Marker, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// API
import * as Mapbox from 'api/Mapbox';

// Components
import MapIcon from 'components/Map/MapIcon/MapIcon';
// import DutyStatusBadge from 'sbCore/DutyStatusBadge/DutyStatusBadge';

// Styles
import './style.scss';

// Access token required to use Mapbox
const mapboxAccessToken = Mapbox.getToken();

const VEHICLES = [
  {
    longitude: -100,
    latitude: 40,
    eldEventTypeCodeInt: 14,
    iconType: 'driving',
  },
  {
    longitude: -111.0982,
    latitude: 40.4667,
    eldEventTypeCodeInt: 11,
    iconType: 'stationary',
  },
  {
    longitude: -113.0026,
    latitude: 47.8506,
    eldEventTypeCodeInt: 12,
    iconType: 'stationary',
  },
  {
    longitude: -124.9391,
    latitude: 48.3492,
    eldEventTypeCodeInt: 13,
    iconType: 'driving',
  },
];

/**
* @description Component that hosts the map itself
* @param {Function} onMapStyleUpdate - Callback function that passes back the selected map style
* @param {Function} onIsFitToVehiclesToggle - Callback function that when used here toggles isFitVehicles state to false
* @param {String} mapStyle - The current style map to display
* @param {Boolean} isFitToVehicles - Boolean that causes map to focus on all vehicles when true
* @returns
*
* @example
* <Map
*   mapStyle={mapStyle}
*   onNavigationInfoUpdate={onNavigationInfoUpdate}
*   onIsFitToVehiclesToggle={onIsFitToVehiclesToggle}
*   isFitToVehicles={isFitToVehicles}
* />
*/
function ReactMap({ ...props }) {

  const [popupInfo, setPopupInfo] = useState(null);

  const vehicleMarkers = useMemo(
    () => VEHICLES.map((vehicle, index) => (
      <Marker
        key={`marker-${index}`}
        longitude={vehicle.longitude}
        latitude={vehicle.latitude}
        anchor="bottom"
      >
        <MapIcon iconType={vehicle.iconType} eldEventTypeCodeInt={vehicle.eldEventTypeCodeInt} onClick={() => setPopupInfo(vehicle)} />
      </Marker>
    )), [],
  );

  // ** useRefs ** //

  // reference of the map itself
  const mapRef = useRef(null);

  // ** useStates ** //
  const [longitude, setLongitude] = useState(-96.878330);
  const [latitude, setLatitude] = useState(44.127648);
  const [zoom, setZoom] = useState(3);

  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v9');

  // ** useCallbacks ** //

  const onMapLoad = useCallback(() => {
    mapRef.current.on('move', () => {
      setLongitude(mapRef.current.getCenter().lng);
      setLatitude(mapRef.current.getCenter().lat);
      setZoom(mapRef.current.getZoom());
    });
  }, []);

  // ** useEffects ** //

  // When the map moves, updates the navigation info states in the map layout. Since the map
  // also moves when the map moves to fit all vehicles, if it was recently toggled to true, set it back to false
  useEffect(() => {
    if (!props.onNavigationInfoUpdate || !props.onIsFitToVehiclesToggle) return;
    props.onNavigationInfoUpdate(longitude, latitude, zoom);

    if (props.isFitToVehicles) {
      props.onIsFitToVehiclesToggle(false);
    }
  }, [longitude, latitude, zoom]);

  // If the map style is changed, update the map to account for the change
  useEffect(() => {
    if (!props.mapStyle) return;
    setMapStyle(props.mapStyle);
  }, [props.mapStyle]);

  // If isFitToVehicles is toggled true, update the map to account for the change and move the map to encompass all vehicles
  useEffect(() => {
    if (!props.isFitToVehicles) return;

    /** @todo: These are just temporary values. Logic will be added in the future to calculate the boundaries of all vehicles on the map */
    const centerLongitude = -96.878330;
    const centerLatitude = 44.127648;
    const centerZoom = 3.5;
    const duration = 2000;

    mapRef.current.easeTo({ center: [centerLongitude, centerLatitude], zoom: centerZoom, duration });
  }, [props.isFitToVehicles]);

  // If a location is searched, zoom to that location's coordinates
  useEffect(() => {
    if (!props.searchBarLocationObj) return;

    const centerLongitude = props.searchBarLocationObj.longitude;
    const centerLatitude = props.searchBarLocationObj.latitude;
    const centerZoom = 15;
    const duration = 2000;

    mapRef.current.easeTo({ center: [centerLongitude, centerLatitude], zoom: centerZoom, duration });
  }, [props.searchBarLocationObj]);

  return (
    <div className="map">
      <Map
        ref={mapRef}
        mapboxAccessToken={mapboxAccessToken}
        initialViewState={{
          longitude,
          latitude,
          zoom,
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle={mapStyle}
        onLoad={onMapLoad}
      >
        <NavigationControl
          position="bottom-right"
        />

        <FullscreenControl
          position="bottom-right"
        />

        {vehicleMarkers}

        {popupInfo && (
          <Popup
            anchor="top"
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            closeOnClick={false}
            onClose={() => setPopupInfo(null)}
          >
            <div className="mt-1">
              <p>Longitude: {popupInfo.longitude}</p>
              <p>Latitude: {popupInfo.latitude}</p>
              <DutyStatusBadge eldEventTypeCodeInt={popupInfo.eldEventTypeCodeInt} />
            </div>
          </Popup>
        )}
      </Map>
    </div>

  );
}

export default ReactMap;