import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import FilterDropdown from 'components/FilterDropdown/view/FilterDropdown';
import { MDBRow, MDBCol, MDBIcon, MDBBtnGroup, MDBBtn } from "mdbreact";

// CSS
import styles from './FilterBar.module.scss';

function FilterBar(props) {

  const equipmentDropdownFieldAttrs = [
    { attrName: 'Vehicles', placeholder: 'Vehicles List' },
    { attrName: 'Trailers', placeholder: 'Trailers List' },
  ];

  const isEquipmentTypeSelected = (['Vehicles', 'Trailers'].indexOf(props.label) !== -1);

  return (
    <div className={styles.filterBar}>
      {
        // <div className={styles.filterBar}>
        //   <div className={styles.dropdown}>
        //     <DropdownButton title={props.label} id="bg-vertical-dropdown-2">
        //       <MenuItem onSelect={() => props.select('Vehicles')}>Vehicles</MenuItem>
        //       <MenuItem onSelect={() => props.select('Geofences')}>Geofences</MenuItem>
        //       {
        //         // <MenuItem onSelect={() => props.select('Trailer')}>Trailer</MenuItem>
        //       }
        //     </DropdownButton>
        //   </div>
        //   <span className={styles.glyph}><Glyphicon glyph="search" /></span>
        //   <div className={styles.input}>
        //     <input value={props.value} type="text" placeholder="....." onChange={props.handleChange} onKeyPress={props.handleKeyPress} />
        //   </div>
        // </div>
      }
      <MDBRow className="px-0 mx-0">
        <MDBBtnGroup size="sm" className="w-100 translate-me">
          <MDBBtn color="blue" className={(isEquipmentTypeSelected ? styles.sidebarButton + ' ' + styles.active : styles.sidebarButton) + ' rounded-0'} onClick={() => props.select('Vehicles')}>Equipment</MDBBtn>
          <MDBBtn color="blue" className={(props.label === 'Geofences' ? styles.sidebarButton + ' ' + styles.active : styles.sidebarButton) + ' rounded-0'} onClick={() => props.select('Geofences')}>Geofences</MDBBtn>
        </MDBBtnGroup>
      </MDBRow>

      {isEquipmentTypeSelected &&
        <div className="bs-style-overwrite bs-full-width">
          <FilterDropdown
            className={styles.equipmentSelector}
            id="equipmentSelector"
            title={`${props.label} List`}
            handleFilter={(equipmentType) => props.select(equipmentType)}
            menuItems={equipmentDropdownFieldAttrs}
          />
        </div>
      }

      {isEquipmentTypeSelected &&
        <MDBRow className="px-0 mx-0 translate-me">
          <MDBBtnGroup size="sm" className="w-100">
            <MDBBtn color="light-blue" className={(props.sortBy === 'updatedAt' ? styles.sidebarButton + ' ' + styles.active : '') + ' rounded-0 px-0'} onClick={() => props.handleSortSelect('updatedAt')}>Sort By Updated</MDBBtn>
            <MDBBtn color="light-blue" className={(props.sortBy === 'unitId' ? styles.sidebarButton + ' ' + styles.active : styles.sidebarButton) + ' rounded-0 px-0'} onClick={() => props.handleSortSelect('unitId')}>Sort By Unit ID</MDBBtn>
          </MDBBtnGroup>
        </MDBRow>
      }

      <MDBRow className="px-0 mx-0 input-group">
        <div className="input-group-prepend">
          <span className="input-group-text border-0 position-absolute h-100">
            <i className="fa fa-search prefix textGrayCalm"></i>
          </span>
        </div>
        <input className="form-control-sm w-100 transparent border-0 rounded-0 pl-6" type="text" placeholder={t('Search for...')} aria-label="Search" value={props.value} onChange={props.handleChange} onKeyPress={props.handleKeyPress} />
      </MDBRow>
    </div>
  );
}

FilterBar.propTypes = {
  sortBy: PropTypes.string,
  label: PropTypes.string.isRequired,
  select: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSortSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
  handleKeyPress: PropTypes.func.isRequired,
};

export default FilterBar;
