/** @module Trailer */
import { createApi } from '@reduxjs/toolkit/query/react';

// CSAPI
import {
  copyQuery, count, createQuery, createQueryOr, createTempRecord, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, addRecord, destroyRecord, cloneRecord,
} from 'sb-csapi/dist/AAPI';

// API
import { getEquipmentGroupEquipmentMapping } from 'api/Equipment/Groups';

// Enums
import { QuerySortOrder, QueryRestrictionTypes, QueryRestriction } from 'sb-csapi/dist/enums/Query';

// Sort
import Sort from 'sb-csapi/dist/sbObjects/Sort';

// Filter
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @memberof module:Trailer
 * @description Obtains trailers from a company
 * @param {object} options - See example
 * @param {string} companyObjectId - Company we wish to retrieve contact for
 * @param {bool} includeChildCompanies - Include child/sub-companies of this parent company (currently not used)
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers (currently not used)
 * @param {array} selectedAttributes - Select attributes to return (currently not used)
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 * 
 * @returns {object} - { trailers: [] }
 */
async function getTrailers(options = { sessionToken: getCurrentUserSessionToken }, companyObjectId = getCurrentUserCompanyObjectId(), includeChildCompanies, filters = [], sortBy = new Sort(QuerySortOrder.ASCENDING, 'unitId'), includedPointers = [], selectedAttributes = [], queryAll) {
  const trailerQuery = createQuery('Trailer');
  setQueryRestriction(trailerQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', companyObjectId);

  // set query restrictions from filter
  if (filters.length > 0) {
    filters.forEach(filterObject => {
      setQueryRestriction(
        trailerQuery,
        filterObject.queryRestriction,
        filterObject.attribute,
        filterObject.value,
      );
    });
  }

  if (includedPointers.length > 0) includePointers(trailerQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(trailerQuery, selectedAttributes);

  // call the ascending/descending function on the query, passing in the attribute
  sortQuery(trailerQuery, sortBy.order, sortBy.attribute);

  const promises = [findRecords(options, trailerQuery, false, queryAll)];

  try {
    const [trailers] = await Promise.all(promises);
    return { trailers };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * The query function performed when querying for Trailers from the TrailerApi
 * @param {Object} args The arguments that are passed into the query
 * @param {Object} api The api arguments as sepcified in https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#implementing-a-custom-basequery 
 * @param {Object} extraOptions The extraOptions argument as specified in https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#implementing-a-custom-basequery
 * @returns {Object} Returns an object either containing a "data" or "error" property
 */
const trailersQuery = async (args, { signal, dispatch, getState }, extraOptions = {}, baseQuery) => {
  const { options, companyObjectId, includeChildCompanies, filters, sortBy, includedPointers, selectedAttributes, queryAll } = args;

  const { equipmentGroupInformationMap, equipmentGroupEquipmentTrailersMap } = await getEquipmentGroupEquipmentMapping();

  // Step 4: Retrieve the trailers for the given company
  const trailerQueryResult = await getTrailers(options, companyObjectId, includeChildCompanies, filters, sortBy, includedPointers, selectedAttributes, queryAll);
  const { trailers } = trailerQueryResult;

  // Step 5: Run through each trailer, check to see if its part of any groups, and add it to the corresponding group
  // If the trailer is not part of any group, then add it to the default group
  const trailersArray = trailers.map((trailer) => {
    try {
      const referenceVehicleLocation = trailer.attributes.vehicleLocation; // Get the reference to the temp vehicleLocation object created by old redux store
      // Unsetting, jsonifying, and resetting the vehicleLocation pointer that was created by the old redux store
      if (referenceVehicleLocation !== undefined) trailer.unset('vehicleLocation');
      const trailerJson = trailer.toJSON();
      if (referenceVehicleLocation !== undefined) {
        trailer.set('vehicleLocation', referenceVehicleLocation);
        trailerJson.vehicleLocation = referenceVehicleLocation.toJSON();
      }
      // Find out if theres an equipment group for this vehicle
      const isPartOfEquipmentGroup = equipmentGroupEquipmentTrailersMap[trailerJson.objectId] !== undefined;

      if (isPartOfEquipmentGroup) {
        equipmentGroupEquipmentTrailersMap[trailerJson.objectId].forEach((equipmentGroupObjectId) => {
          equipmentGroupInformationMap[equipmentGroupObjectId].trailers.push(trailerJson);
        })
      } else {
        equipmentGroupInformationMap.default.trailers.push(trailerJson);
      }
      return trailerJson;
    } catch (err) {
      throw Error(err.message);
    }
  });
  return { data: { equipmentGroup: equipmentGroupInformationMap, trailers: trailersArray } };
};

/**
 * Defines an API slice for the Trailer API. This is used to generate the appropriate hooks for various actions on a query/mutation
 * 
 * @todo: Add more endpoints to allow for mutations/updates to trailers
 * 
 * To get more information on this topic, see https://redux-toolkit.js.org/rtk-query/overview#create-an-api-slice
 */
const trailerApi = createApi({
  reducerPath: 'trailerApi',
  endpoints: (builder) => ({
    getTrailers: builder.query({
      queryFn: trailersQuery,
    }),
  }),
});

// Exports React hooks for api endpoints
export const { useGetTrailersQuery } = trailerApi;
export { getTrailers, trailerApi };