import React, { useState, useEffect } from 'react';

// Shared Document Components
import DocumentInputText from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputText/InputText';
import DocumentInputNumber from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputNumber/InputNumber';

// Styling
import './style.scss';

/**
 * @description Invoice Document Generator total section
 * @param {array} additionalChargeRates - array of additional charges rates
 * @param {array} rates - array of rates
 * @param {Function} updateDocumentStateObj - Callback function that's passed back an attribute and value to update its parent's state
 * @param {String} taxRate - The current tax rate value
 * @param {String} currency - The current currency value
 * @param {Number} discount - The current discount value
 * @param {Number} amountDue - The current amount due value
 * @returns
 */
const InvoiceTotal = ({ ...props }) => {

  // ** useStates ** //
  const [subtotal, setSubtotal] = useState(0);
  const [additionalChargeRates, setAdditionalChargeRates] = useState([]);
  const [additionalChargesTotal, setAdditionalChargesTotal] = useState(0);
  const [rates, setRates] = useState([]);
  const [tax, setTax] = useState(0);

  // ** useEffects ** //
  useEffect(() => {
    setAdditionalChargeRates(props.additionalChargeRates);
  }, [props.additionalChargeRates]);

  useEffect(() => {
    setRates(props.rates);
  }, [props.rates]);

  // Calculates totals when any given values change
  useEffect(() => {
    let additionalChargesTotal = 0;
    let ratesTotal = 0;

    let totalBeforeTax = 0;
    let totalTax = 0;
    let totalAfterTax = 0;

    async function calculateChanges() {
      if (additionalChargeRates && additionalChargeRates.length > 0) {
        additionalChargeRates.map((additionalChargeRate) => {
          additionalChargesTotal += additionalChargeRate;
        });
      }

      if (rates && rates.length > 0) {
        rates.map((rate) => {
          ratesTotal += parseFloat(rate);
        });
      }

      totalBeforeTax = (ratesTotal - props.discount + additionalChargesTotal);
      totalTax = totalBeforeTax * (props.taxRate / 100);
      totalAfterTax = totalBeforeTax + totalTax;
      updateDocumentStateObj('amountDue', totalAfterTax);

      setSubtotal(ratesTotal.toFixed(2));
      setTax(totalTax.toFixed(2));
      setAdditionalChargesTotal(additionalChargesTotal.toFixed(2));
    }

    calculateChanges();
  }, [props.discount, additionalChargeRates, props.taxRate, rates]);

  // ** Functions ** //
  // Updates the parent documentStateObj given an attribute and value
  function updateDocumentStateObj(attribute, value) {
    if (props.updateDocumentStateObj) props.updateDocumentStateObj(attribute, value);
  }

  // ** Components ** //
  const discountField = (
    <div className="pl-1">
      <DocumentInputNumber
        inputClassName="w-4rem text-sm"
        value={props.discount}
        onChange={(value) => updateDocumentStateObj('discount', value)}
        mode="decimal"
        minFractionDigits={2}
        success={props.discount}
        warning={!props.discount || props.discount === ''}
        isDownloaded={props.isDownloaded}
      />
    </div>
  );

  const taxRateField = (
    <DocumentInputNumber
      inputClassName="text-sm"
      value={props.taxRate}
      onChange={(value) => updateDocumentStateObj('taxRate', value)}
      success={props.taxRate}
      warning={!props.taxRate || props.taxRate === ''}
      isDownloaded={props.isDownloaded}
    />
  );

  const currencyField = (
    <div className="pl-1">
      <DocumentInputText
        inputClassName="text-sm"
        value={props.currency}
        onChange={(value) => updateDocumentStateObj('currency', value)}
        success={props.currency}
        warning={!props.currency || props.currency === ''}
        isDownloaded={props.isDownloaded}
      />
    </div>
  );

  const amountDueField = (
    <DocumentInputNumber
      inputClassName="text-sm"
      value={props.amountDue}
      mode="decimal"
      minFractionDigits={2}
      onChange={(value) => updateDocumentStateObj('amountDue', value)}
      success={props.amountDue}
      warning={!props.amountDue || props.amountDue === ''}
      isDownloaded={props.isDownloaded}
    />
  );

  return (
    <div className="grid invoice-total mr-7">

      <div className="col-7 total-heading">
        SUBTOTAL
      </div>
      <div className="col-5 total-number flex flex-row text-sm">
        $ {subtotal}
      </div>

      <div className="col-7 total-heading">
        DISCOUNT
      </div>
      <div className="col-5 total-number flex flex-row">
        $ {discountField}
      </div>

      <div className="col-7 total-heading">
        ADDITIONAL CHARGE(S)
      </div>
      <div className="col-5 total-number flex flex-row text-sm">
        $ {additionalChargesTotal}
      </div>

      <div className="col-7 total-heading">
        (TAX RATE)
      </div>
      <div className="col-5 total-number flex flex-row tax-rate-field">
        {taxRateField} %
      </div>

      <div className="col-7 total-heading">
        TAX
      </div>
      <div className="col-5 total-number flex flex-row text-sm">
        $ {tax}
      </div>

      <div className="col-12 border-bottom-1" />

      <div className="col-7 total-heading">
        AMOUNT DUE
      </div>
      <div className="col-5 total-number flex flex-row text-sm">
        <div className="flex amount-due-field">
          $ {amountDueField}
        </div>
        <div className="currency-field">
          {currencyField}
        </div>
      </div>

    </div>
  );
};

export default InvoiceTotal;
