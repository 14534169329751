import React from 'react';
import PropTypes from 'prop-types';
import history from "../../sbHistory";

// Actions
import * as Auth from 'actions/Auth';

// API
import * as Helpers from 'api/Helpers';

// Components

// CSS

class LoginLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: '',
    };

    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    document.title = 'Upgrade - Switchboard';
  }

  handleLogin() {
    Auth.tryLogin({ email: this.state.username, password: this.state.password }, true).then(() => {
      this.props.handleLoginSuccess();
    }, (error) => {
      this.setState({ ...this.state, error });
    });
  }

  render() {
    return (
      <div>
        <div>
          <input
            placeholder="Username"
            type="name"
            name="username"
            value={this.state.username}
            onChange={(e) => this.setState({ ...this.state, username: e.target.value })}
            onKeyPress={(e) => { if (e.key === 'Enter') this.handleLogin(); }}
          />
          <input
            placeholder="Password"
            type="password"
            name="password"
            value={this.state.password}
            onChange={(e) => this.setState({ ...this.state, password: e.target.value })}
            onKeyPress={(e) => { if (e.key === 'Enter') this.handleLogin(); }}
          />
        </div>
      </div>
    );
  }
}

LoginLayout.propTypes = {
  handleLoginSuccess: PropTypes.func.isRequired,
};

export default LoginLayout;
