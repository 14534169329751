import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, RaisedButton, Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn } from 'material-ui';

import { getCurrentUser } from 'api/Parse';

// Components
import DeleteXButton from 'components/DeleteXButton/view/DeleteXButton';

// Styles
import styles from './LinkedCompanies.module.scss';

function LinkedCompanies(props) {
  return (
    <Table>
      <TableHeader
        adjustForCheckbox={false}
        displaySelectAll={false}
        className="translate-me"
      >
        <TableRow>
          <TableHeaderColumn>Company Name</TableHeaderColumn>
          <TableHeaderColumn>DOT/NSC Number</TableHeaderColumn>
          <TableHeaderColumn>Status</TableHeaderColumn>
          <TableHeaderColumn>Type</TableHeaderColumn>
          <TableHeaderColumn>
            { getCurrentUser() && getCurrentUser().get('belongsToCompany').get('enableCompanyLinks') &&
              <RaisedButton
                label="Link Company"
                primary
                onClick={props.showLinkCompanyModal}
              />
            }
          </TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody
        displayRowCheckbox={false}
      >
        { !props.loading && props.companyLinkArr.map((companyLink) => {
          if (companyLink.get('parentCompany').id === props.company.id) {
            // Child Company
            return (
              <TableRow key={companyLink.id}>
                <TableRowColumn><b>{ companyLink.get('childCompany').get('name') }</b></TableRowColumn>
                <TableRowColumn>
                  { companyLink.get('childCompany').get('dotNumber') && 
                    <div>
                      { companyLink.get('childCompany').get('dotNumber') }
                    </div>
                  }
                  { companyLink.get('childCompany').get('nscNumber') && 
                    <div>
                      { companyLink.get('childCompany').get('nscNumber') }
                    </div>
                  }
                </TableRowColumn>
                <TableRowColumn className="translate-me">
                  { companyLink.get('authorized') &&
                    <span className={styles.authorized}>Authorized</span>
                  }
                  { !companyLink.get('authorized') &&
                    <span className={styles.notAuthorized}>Awaiting Authorization</span>
                  }
                </TableRowColumn>
                <TableRowColumn className="translate-me">You have access</TableRowColumn>
                <TableRowColumn>
                  <div style={{ display: 'inline-block', cursor: 'pointer' }}><DeleteXButton handleClick={() => props.deleteChildCompanyLink(companyLink)} /></div>
                </TableRowColumn>
              </TableRow>
            );
          }
          // Parent Company
          return (
            <TableRow>
              <TableRowColumn><b>{ companyLink.get('parentCompany').get('name') }</b></TableRowColumn>
              <TableRowColumn>{ companyLink.get('parentCompany').get('dotNumber') }</TableRowColumn>
              <TableRowColumn className="translate-me">
                { companyLink.get('authorized') &&
                  <span className={styles.authorized}>Authorized</span>
                }
                { !companyLink.get('authorized') &&
                  <span className={styles.notAuthorized}>Awaiting Authorization</span>
                }
              </TableRowColumn>
              <TableRowColumn className="translate-me">Has access to you</TableRowColumn>
              <TableRowColumn className="translate-me">
                { companyLink.get('authorized') &&
                  <RaisedButton secondary label="Unauthorize" onClick={() => props.unauthorizeCompanyLink(companyLink)} />
                }
                { !companyLink.get('authorized') &&
                  <RaisedButton primary label="Authorize" onClick={() => props.authorizeCompanyLink(companyLink)} />
                }
              </TableRowColumn>
            </TableRow>
          );
        })}
        { !props.loading && props.companyLinkArr.length === 0 &&
          <TableRow>
            <TableRowColumn
              colSpan={42}
              style={{ textAlign: 'center' }}
            >
              <span className="translate-me">No Company Links</span>
            </TableRowColumn>
          </TableRow>
        }
        { props.loading &&
          <TableRow>
            <TableRowColumn
              colSpan={42}
              style={{ textAlign: 'center' }}
            >
              <div style={{ padding: '1em' }}><CircularProgress /></div>
            </TableRowColumn>
          </TableRow>
        }
      </TableBody>
    </Table>
  );
}

LinkedCompanies.propTypes = {
  company: PropTypes.object.isRequired,
  companyLinkArr: PropTypes.array.isRequired,
  showLinkCompanyModal: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  authorizeCompanyLink: PropTypes.func.isRequired,
  unauthorizeCompanyLink: PropTypes.func.isRequired,
  deleteChildCompanyLink: PropTypes.func.isRequired,
};

export default LinkedCompanies;
