import React from 'react';

// component
import Divider from 'sbCore/Divider/Divider';
import DispatchReferenceNumbersInput from 'components/Dispatch/DispatchReferenceNumbers/DispatchReferenceNumbersInput/DispatchReferenceNumbersInput';

// style
import './style.scss';

/**
 * @description A container which holds general and shipment reference number input areas
 * @param {String} dispatchTransferObjectId - Dispatch transfer object ID
 * @param {String} dispatchJobObjectId - Dispatch job object ID
 * @param {DispatchJob} dispatchJob - Dispatch job record
 * @param {Function} triggerRefreshState - Invert refreshState boolean
 * @returns 
 */
function DispatchReferenceNumbersContainer({ ...props }) {

  return (
    <div className="mt-5 dispatch-reference-numbers-container">
      <div className="flex flex-row py-2">
        <div className="font-semibold text-lg my-0 inline">Reference Numbers</div>
      </div>
      <Divider className="mt-2" />
      <div className="dispatch-reference-numbers-input-container flex flex-row">
        <div className="shipment-reference-numbers-container">
          {props.dispatchTransferObjectId && props.dispatchJob && (
            <DispatchReferenceNumbersInput
              title="Shipment"
              dispatchTransferObjectId={props.dispatchTransferObjectId}
              dispatchJobObjectId={props.dispatchJobObjectId}
              dispatchJob={props.dispatchJob}
              triggerRefreshState={props.triggerRefreshState}
            />
          )}
        </div>
        <div className="general-reference-numbers-container">
          {props.dispatchJob && (
            <DispatchReferenceNumbersInput
              title="General"
              dispatchJobObjectId={props.dispatchJobObjectId}
              dispatchJob={props.dispatchJob}
              triggerRefreshState={props.triggerRefreshState}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default DispatchReferenceNumbersContainer;
