import React from 'react';

// PrimeReact Component
import { Sidebar as PRSidebar } from 'primereact/sidebar';

// Styling
import './style.scss';

/**
 * @description PrimeReact Sidebar component
 * @param {String} className - Custom container className
 */
function Sidebar({ ...props }) {
  let className = 'sbc-sidebar';
  if (props.className) className += ` ${props.className}`;

  return (<PRSidebar {...props} className={className} />);
}

export default Sidebar;
