import uniqid from 'uniqid';
import React, { useState, useEffect } from 'react';

// Enums
import { ELDEventTypeCode, ELDEventTypeCodeDescription, ELDEventTypeCodeDescriptionShort } from 'sb-csapi/dist/enums/ELD/ELDEventTypeCode';

// SBCore Components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Dropdown from 'sbCore/Dropdown/Dropdown';

// Style
import './style.scss';

/**
 * @description Select a duty status. By default, it will only show six duty statuses related to the driver.
 *
 * @param {ELDEventTypeCode} eldEventTypeCode - Sets the default duty status.
 * @param {bool} [useShortDescription] - Whether to use the short description of a duty status (if available)
 * @param {Array<ELDEventTypeCode>} [includedELDEventTypeCodes] - The duty statuses to include in the dropdown. When this is provided, only these statuses will be shown.
 * @param {Array<ELDEventTypeCode>} [excludedELDEventTypeCodes] - The duty statuses to exclude from the dropdown. When this is provided, these statuses will not be shown.
 *
 * @param {String} [className] - Custom container className
 * @param {Object} [style] - Custom inline styles
 * @param {bool} [warning] - Makes the border yellow
 * @param {bool} [hideLabel] - Hide the label
 * @param {bool} [disabled] - Whether disable the component
 * @param {Function} [onSelect] - A callback function that returns the selected duty status
 *
 * @returns {JSX.Element}
 */
function DutyStatusDropdown({ ...props }) {
  const [identifier] = useState(uniqid()); // Give it unique identifier for specific dom manipulation

  const [dutyStatuses, setDutyStatuses] = useState([]);
  const [selectedDutyStatus, setSelectedDutyStatus] = useState(undefined);

  // ** Hooks Section ** //
  useEffect(() => {
    // These are the default statuses available if none are provided in includedELDEventTypeCodes or excludedELDEventTypeCodes
    let defaultDutyStatuses = [
      ELDEventTypeCode.OFF_DUTY,
      ELDEventTypeCode.SLEEPER_BERTH,
      ELDEventTypeCode.DRIVING,
      ELDEventTypeCode.ON_DUTY_NOT_DRIVING,
      ELDEventTypeCode.AUTHORIZED_PERSONAL_USE_OF_CMV,
      ELDEventTypeCode.YARD_MOVES,
    ];

    // If includedELDEventTypeCodes is provided, use those statuses only
    if (props.includedELDEventTypeCodes) {
      defaultDutyStatuses = props.includedELDEventTypeCodes;
    }

    // If excludedELDEventTypeCodes is provided, exclude those statuses from the entire ELDEventTypeCode enum list
    if (props.excludedELDEventTypeCodes) {
      defaultDutyStatuses = Object.values(ELDEventTypeCode).filter((eldEventTypeCode) => !props.excludedELDEventTypeCodes.includes(eldEventTypeCode));
    }

    // Build the options for the dropdown
    const _dutyStatuses = Object.entries(ELDEventTypeCode)
      .map(([key, value]) => ({
        label: props.useShortDescription ? (ELDEventTypeCodeDescriptionShort[key] ?? ELDEventTypeCodeDescription[key]) : ELDEventTypeCodeDescription[key],
        value,
      }))
      .filter((dutyStatus) => defaultDutyStatuses.includes(dutyStatus.value));

    setDutyStatuses(_dutyStatuses);
    setSelectedDutyStatus(props.eldEventTypeCode);
  }, []);

  useEffect(() => {
    setSelectedDutyStatus(props.eldEventTypeCode);
  }, [props.eldEventTypeCode]);

  // ** Components Section ** //
  function onChange(e) {
    setSelectedDutyStatus(e.value);
    if (props.onSelect) props.onSelect(e.value); // Send the chosen duty status back up to parent
  }

  // ** Misc ** //
  let className = `duty-status-dropdown ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && (
        <InputLabel>Duty Status</InputLabel>
      )}
      <Dropdown
        className={props.dropdownClassName}
        value={selectedDutyStatus}
        options={dutyStatuses}
        onChange={(e) => onChange(e)}
        warning={props.warning}
        disabled={props.disabled}
        variant="inputsmall"
      />
    </div>
  );
}

export default DutyStatusDropdown;
