import React from 'react';
import PropTypes from 'prop-types';
import { MDBIcon, MDBTooltip } from 'mdbreact';
// CSS
import styles from './Alert.module.scss';

function Alert(props) {
  return (
    <MDBTooltip className={styles.alertIcon + ' d-inline-block'}
      placement="right"
      tag="div"
      tooltipContent={props.tooltipString || props.tooltipContent}
      tooltipClass={props.tooltipClass}>
      <MDBIcon icon="exclamation-circle" className={props.iconClass} onClick={() => props.handleClick()} />
    </MDBTooltip>
  );
}

Alert.propTypes = {
  handleClick: PropTypes.func,
  tooltipString: PropTypes.string,
  tooltipContent: PropTypes.any,
  tooltipClass: PropTypes.string,
  iconClass: PropTypes.string
};

export default Alert;
