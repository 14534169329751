import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MDBBtn } from 'mdbreact';

// API
import * as Helpers from 'api/Helpers';
import { getLocationDescriptionBreakdown } from 'api/VehicleLocation/VehicleLocation';
import { getAttribute } from 'api/Parse';
import { findIFTARouteIssues, getFuelCardData } from 'api/IFTARoute/IFTARoute.old';

// Components
import SBTable from 'components/Shared/SBTable/SBTable';
import AddRoute from 'components/IFTARoute/container/AddRoute';
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import ErrorMessages from 'components/IFTARoute/container/ErrorMessages';
import Tooltip from 'sbCore/Tooltip/Tooltip';
import Badge from 'sbCore/Badge/Badge';

// Enums
import ErrorTypes from 'enums/IFTARoute';

import './styles.scss';

class VehicleRouteBreakdownDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iftaRouteDayIssuesArr: props.iftaRouteDayIssuesArr,
      iftaRouteDetailsIssuesArr: [],
    };
    this.generateDetailsViewTable = this.generateDetailsViewTable.bind(this);
    this.generateErrorMessages = this.generateErrorMessages.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  async componentDidMount() {
    const { props } = this;
    const iftaRouteDetailsIssuesArr = findIFTARouteIssues(props.iftaRouteRecordsArr);
    const iftaFuelCardDataHash = await getFuelCardData(props.iftaRouteRecordsArr.map((iftaRoute) => getAttribute(iftaRoute, 'objectId')), true); // This view will only show up on the new IFTA
    this.setState({ ...this.state, iftaRouteDetailsIssuesArr, iftaFuelCardDataHash });
  }

  handleScroll(e, anchorTargets) {
    const { state, props } = this;

    // We need to grab the row element, hence we retrieve the grandparent element
    const firstElement = document.getElementById(anchorTargets[0]).parentElement.parentElement;

    e.preventDefault();
    firstElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

    // Toggle the classes to temporarily remove styling (workaround due to the classes using !important)
    anchorTargets.forEach((anchorTarget) => {
      const element = document.getElementById(anchorTarget).parentElement.parentElement;
      const hasGrayBg = element.classList.contains('bg-gray-hover');

      element.classList.toggle('error-alert');
      hasGrayBg && element.classList.toggle('bg-gray-hover');
      element.classList.toggle('hover-gray-matte');

      setTimeout(() => {
        element.classList.toggle('error-alert');
        hasGrayBg && element.classList.toggle('bg-gray-hover');
        element.classList.toggle('hover-gray-matte');
      }, 3000);
    });
  }

  generateErrorMessages() {
    const { state, props } = this;
    const errorMessages = [];

    // We'll perform some logic here and infer what type of error the client is experiencing
    // We'll use a combination of the errors generated from the day-day, and the errors generated for the current day
    if (state.iftaRouteDayIssuesArr && state.iftaRouteDayIssuesArr.length > 0) {
      // These are errors which appear in the day-day view
      const dayHasStateProvinceNotAdjacentIssueArr = state.iftaRouteDayIssuesArr.filter((entry) => entry.stateProvinceNotAdjacent);
      const dayHasUnidentifiedDrivingIssueArr = state.iftaRouteDayIssuesArr.filter((entry) => entry.isUnidentifiedDriving);
      const dayHasLargeJumpGapIssueArr = state.iftaRouteDayIssuesArr.filter((entry) => entry.jumpGapKmOutsideThreshold);
      // const dayHasJumpIssueArr = state.iftaRouteDayIssuesArr.filter((entry) => entry.isDayJump);
      const dayHasOdometerReadingIssueArr = state.iftaRouteDayIssuesArr.filter((entry) => entry.isIncorrectOdometerReading);

      // These are the errors appearing within a single day
      // const stateProvinceNotAdjacentIssueArr = state.iftaRouteDetailsIssuesArr.filter((entry) => entry.stateProvinceNotAdjacent);
      const unidentifiedDrivingIssueArr = state.iftaRouteDetailsIssuesArr.filter((entry) => entry.isUnidentifiedDriving);
      // const largeJumpGapIssueArr = state.iftaRouteDetailsIssuesArr.filter((entry) => entry.jumpGapKmOutsideThreshold);
      // const isNonContinuousIssueArr = state.iftaRouteDetailsIssuesArr.filter((entry) => entry.isNonContinuous);
      const odometerReadingIssueArr = state.iftaRouteDetailsIssuesArr.filter((entry) => entry.isIncorrectOdometerReading);

      // 1. Inaccurate odometer readings
      if (dayHasOdometerReadingIssueArr.length > 0) {
        const problematicIFTARouteObjectIdArr = odometerReadingIssueArr.map((issue) => issue.endIFTARouteObjectId);
        const problematicIFTARouteArr = props.iftaRouteRecordsArr.filter((iftaRoute) => problematicIFTARouteObjectIdArr.includes(iftaRoute.id));

        // Further improvements to be made: smart suggestions - use data from previous IFTARoutes to give suggestions for the client to use
        // For odometer readings, we can use the previous/next IFTARoute of the day to determine suggested values to use
        const addEditRouteButtonArr = problematicIFTARouteArr.map((iftaRoute) => (
          <AddRoute
            unitId={props.vehicleUnitId}
            isEdit
            iftaRoute={iftaRoute}
            distanceUnitFilter={props.distanceUnitFilter}
            refreshState={(callRefreshState) => this.props.parseTableBodyRowData(callRefreshState)}
            driverTimeZoneStr={props.driverTimeZoneStr}
            iftaRouteRecordsForTablePreview={props.iftaRouteRecordsForTablePreview}
            customEditButtonText="EDIT AFFECTED ROUTE"
          />
        ));

        errorMessages.push(
          <div className="error-message" style={{ fontSize: '1em' }}>
            <MDBBtn
              size="sm"
              className="error-message-highlight"
              onClick={(e) => this.handleScroll(e, problematicIFTARouteObjectIdArr)}
              style={{ width: '14em' }}
            >
              Highlight Issue(s)
            </MDBBtn>
            <ErrorMessages
              errorType={ErrorTypes.UNUSUAL_ODOMETER_READING}
              recommendedActionString="Apply the correct start/end odometer readings to the affected route"
              handleResolution={addEditRouteButtonArr}
            />
            {odometerReadingIssueArr.length === 1 && `${odometerReadingIssueArr.length} instance of unusual odometer readings was detected`}
            {odometerReadingIssueArr.length > 1 && `${odometerReadingIssueArr.length} instances of unusual odometer readings were detected`}
          </div>,
        );
      }

      // 2. Missing routes - includes unassigned odometer mileages for a route or stateProvinces not being adjacent
      if (dayHasLargeJumpGapIssueArr.length > 0 || dayHasStateProvinceNotAdjacentIssueArr > 0) {
        const problematicIFTARoute = props.iftaRouteRecordsArr[props.iftaRouteRecordsArr.length - 1];
        let shouldEditRoute = true;
        let errorType = ErrorTypes.NO_ERROR;

        const vehicleLocationStart = getAttribute(problematicIFTARoute, 'vehicleLocationEnd');
        const vehicleLocationEnd = getAttribute(props.nextDayIFTARoute, 'vehicleLocationStart');

        // The end vehicleLocation for the last IFTARoute of the current day
        let vehicleLocationStartStateProvince = getAttribute(vehicleLocationStart, 'stateProvince');
        let vehicleLocationStartAprxShortName = getAttribute(vehicleLocationStart, 'aprxShortName');
        const vehicleLocationStartLocationDescriptionUS = getAttribute(vehicleLocationStart, 'locationDescriptionUS');
        const vehicleLocationStartBreakdown = getLocationDescriptionBreakdown(vehicleLocationStartLocationDescriptionUS);
        if (!vehicleLocationStartStateProvince) vehicleLocationStartStateProvince = vehicleLocationStartBreakdown.stateProvince.code.toLowerCase();
        if (!vehicleLocationStartAprxShortName) vehicleLocationStartAprxShortName = vehicleLocationStartBreakdown.city;

        // The start vehicleLocation for the first IFTARoute for the next day
        let vehicleLocationEndStateProvince = getAttribute(vehicleLocationEnd, 'stateProvince');
        let vehicleLocationEndAprxShortName = getAttribute(vehicleLocationEnd, 'aprxShortName');
        const vehicleLocationEndLocationDescriptionUS = getAttribute(vehicleLocationEnd, 'locationDescriptionUS');
        const vehicleLocationEndBreakdown = getLocationDescriptionBreakdown(vehicleLocationEndLocationDescriptionUS);
        if (!vehicleLocationEndStateProvince) vehicleLocationEndStateProvince = vehicleLocationEndBreakdown.stateProvince.code.toLowerCase();
        if (!vehicleLocationEndAprxShortName) vehicleLocationEndAprxShortName = vehicleLocationEndBreakdown.city;

        // If there is a difference in locations, then we suggest adding a new route, and if there is no difference in location, then we suggest appending the odometer jump to the last IFTARoute
        if ((vehicleLocationStartAprxShortName !== vehicleLocationEndAprxShortName) || (vehicleLocationStartStateProvince !== vehicleLocationEndStateProvince)) {
          shouldEditRoute = false;
          errorType = ErrorTypes.MISSING_MILEAGE_INFORMATION;
        } else {
          shouldEditRoute = true;
          errorType = ErrorTypes.JUMPED_MILEAGES;
        }

        // Further improvements to be made: smart suggestions - use data from next day's IFTARoutes to give suggestions for the client to use
        // If jumped mileage, then give suggestion of next day's totalVehicleKmStart, if different state/provinces, then give suggestion of next day's start location
        errorMessages.push(
          <div className="error-message" style={{ fontSize: '1em' }}>
            <MDBBtn
              size="sm"
              className="error-message-highlight"
              onClick={(e) => this.handleScroll(e, [problematicIFTARoute.id])}
              style={{ width: '14em' }}
            >
              Highlight Issue(s)
            </MDBBtn>
            <ErrorMessages
              errorType={errorType}
              recommendedActionString={shouldEditRoute === true ? 'Adjust the end odometer of the affected route to match with the start odometer of the next route' : 'Create a new route containing the missing mileage information'}
              handleResolution={(
                <AddRoute
                  unitId={props.vehicleUnitId}
                  isEdit={shouldEditRoute}
                  iftaRoute={shouldEditRoute ? problematicIFTARoute : undefined}
                  distanceUnitFilter={props.distanceUnitFilter}
                  refreshState={(callRefreshState) => this.props.parseTableBodyRowData(callRefreshState)}
                  driverTimeZoneStr={props.driverTimeZoneStr}
                  iftaRouteRecordsForTablePreview={props.iftaRouteRecordsForTablePreview}
                  customEditButtonText="EDIT AFFECTED ROUTE"
                />
              )}
            />
            Potential missing route information was detected for this day
          </div>,
        );
      }

      // 3. Unidentified Driver
      if (dayHasUnidentifiedDrivingIssueArr > 0) {
        const problematicIFTARouteObjectIdArr = unidentifiedDrivingIssueArr.map((issue) => issue.endIFTARouteObjectId);
        const problematicIFTARouteArr = props.iftaRouteRecordsArr.filter((iftaRoute) => problematicIFTARouteObjectIdArr.includes(iftaRoute.id));

        // Further improvements to be made: smart suggestions - use data from previous IFTARoutes to give suggestions for the client to use
        // For driver, we can use the previous/next IFTARoute of the day to determine a suggested driver to use
        const addEditRouteButtonArr = problematicIFTARouteArr.map((iftaRoute) => (
          <AddRoute
            unitId={props.vehicleUnitId}
            isEdit
            iftaRoute={iftaRoute}
            distanceUnitFilter={props.distanceUnitFilter}
            refreshState={(callRefreshState) => this.props.parseTableBodyRowData(callRefreshState)}
            driverTimeZoneStr={props.driverTimeZoneStr}
            iftaRouteRecordsForTablePreview={props.iftaRouteRecordsForTablePreview}
            customEditButtonText="EDIT AFFECTED ROUTE"
          />
        ));

        errorMessages.push(
          <div className="error-message" style={{ fontSize: '1em' }}>
            <MDBBtn
              size="sm"
              className="error-message-highlight"
              onClick={(e) => this.handleScroll(e, problematicIFTARouteObjectIdArr)}
              style={{ width: '14em' }}
            >
              Highlight Issue(s)
            </MDBBtn>
            <ErrorMessages
              errorType={ErrorTypes.UNIDENTIFIED_DRIVING}
              recommendedActionString="Add the missing driver information to the affected route"
              handleResolution={addEditRouteButtonArr}
            />
            {unidentifiedDrivingIssueArr.length === 1 && `${unidentifiedDrivingIssueArr.length} instance of unidentified driving was detected`}
            {unidentifiedDrivingIssueArr.length > 1 && `${unidentifiedDrivingIssueArr.length} instances of unidentified driving were detected`}
          </div>,
        );
      }
    }

    return errorMessages;
  }

  generateDetailsViewTable() {
    const { props, state } = this;
    const showOdometerValues = props.showOdometerValuesFilter.value === 'showOdometerValues';

    return props.iftaRouteRecordsArr.map((iftaRoute, index) => {
      const objectId = iftaRoute.id;
      const dateStart = getAttribute(iftaRoute, 'dateStart');
      const dateEnd = getAttribute(iftaRoute, 'dateEnd');
      const vehicleLocationStart = getAttribute(iftaRoute, 'vehicleLocationStart');
      const vehicleLocationEnd = getAttribute(iftaRoute, 'vehicleLocationEnd');
      // const stateProvince = getAttribute(iftaRoute, 'stateProvince');
      // const vehicleUnitId = getAttribute(iftaRoute, 'vehicleUnitId');
      const iftaRouteDriverPeriods = getAttribute(iftaRoute, 'iftaRouteDriverPeriods');
      const totalVehicleKmStart = getAttribute(iftaRoute, 'totalVehicleKmStart') || 0;
      const totalVehicleKmEnd = getAttribute(iftaRoute, 'totalVehicleKmEnd') || 0;
      // const totalVehicleKmDiff = getAttribute(iftaRoute, 'totalVehicleKmDiff');
      const savedVehicleKm = getAttribute(iftaRoute, 'savedVehicleKm') || 0;
      // const distanceKm = getAttribute(iftaRoute, 'distanceKm');
      const fuelPurchases = getAttribute(iftaRoute, 'fuelPurchases');
      // const totalGallons = getAttribute(iftaRoute, 'totalGallons');
      const isAutoCreated = getAttribute(iftaRoute, 'isAutoCreated');
      const fuelCardDataArr = state.iftaFuelCardDataHash && state.iftaFuelCardDataHash[objectId];

      const prevDateEnd = index > 0 && moment(getAttribute(props.iftaRouteRecordsArr[index - 1], 'dateEnd')).tz(props.driverTimeZoneStr);
      const dateStartFormatted = moment(dateStart).tz(props.driverTimeZoneStr).format('HH:mm');
      const dateEndFormatted = moment(dateEnd).tz(props.driverTimeZoneStr).format('HH:mm');

      let prevVehicleLocationEnd;
      let prevEndVehicleLocationStateProvince;
      let prevEndVehicleLocationAprxShortName;

      if (index > 0) {
        prevVehicleLocationEnd = getAttribute(props.iftaRouteRecordsArr[index - 1], 'vehicleLocationEnd');

        // Obtain previous day's city + stateProvince
        prevEndVehicleLocationStateProvince = getAttribute(prevVehicleLocationEnd, 'stateProvince');
        prevEndVehicleLocationAprxShortName = getAttribute(prevVehicleLocationEnd, 'aprxShortName');
        const prevVehicleLocationEndString = getAttribute(prevVehicleLocationEnd, 'locationDescriptionUS');
        const prevVehicleLocationEndBreakdown = getLocationDescriptionBreakdown(prevVehicleLocationEndString);
        if (!prevEndVehicleLocationStateProvince) prevEndVehicleLocationStateProvince = prevVehicleLocationEndBreakdown.stateProvince.code.toLowerCase();
        if (!prevEndVehicleLocationAprxShortName) prevEndVehicleLocationAprxShortName = prevVehicleLocationEndBreakdown.city;
      }

      // Obtain current day's start city + stateProvince
      let startVehicleLocationStateProvince = getAttribute(vehicleLocationStart, 'stateProvince');
      let startVehicleLocationAprxShortName = getAttribute(vehicleLocationStart, 'aprxShortName');
      const startVehicleLocationString = getAttribute(vehicleLocationStart, 'locationDescriptionUS');
      const startVehicleLocationBreakdown = getLocationDescriptionBreakdown(startVehicleLocationString);
      if (!startVehicleLocationStateProvince) startVehicleLocationStateProvince = startVehicleLocationBreakdown.stateProvince.code.toLowerCase();
      if (!startVehicleLocationAprxShortName) startVehicleLocationAprxShortName = startVehicleLocationBreakdown.city;

      // Obtain current day's end city + stateProvince
      let endVehicleLocationStateProvince = getAttribute(vehicleLocationEnd, 'stateProvince');
      let endVehicleLocationAprxShortName = getAttribute(vehicleLocationEnd, 'aprxShortName');
      const endVehicleLocationString = getAttribute(vehicleLocationEnd, 'locationDescriptionUS');
      const endVehicleLocationBreakdown = getLocationDescriptionBreakdown(endVehicleLocationString);
      if (!endVehicleLocationStateProvince) endVehicleLocationStateProvince = endVehicleLocationBreakdown.stateProvince.code.toLowerCase();
      if (!endVehicleLocationAprxShortName) endVehicleLocationAprxShortName = endVehicleLocationBreakdown.city;
      const vehicleRowObj = {
        key: objectId,
        props: { className: 'sb-table-row' },
        columns: [
          {
            // Trip Start
            element: (
              <div className="sb-table-cell-div" id={objectId}>
                <b>
                  {!(!prevDateEnd || (moment(dateStart).tz(props.driverTimeZoneStr).isSame(prevDateEnd) || (moment(dateStart).tz(props.driverTimeZoneStr).diff(prevDateEnd, 'minutes') <= 1))) &&
                    <Tooltip target={'.' + `dateTime${objectId}`} />
                  }
                  <span
                    className={(!prevDateEnd || (moment(dateStart).tz(props.driverTimeZoneStr).isSame(prevDateEnd) || (moment(dateStart).tz(props.driverTimeZoneStr).diff(prevDateEnd, 'minutes') <= 1))) ? '' : `errorText dateTime${objectId}`}
                    data-pr-tooltip="Date/Time not matching previous"
                  >
                    {dateStartFormatted}
                  </span>
                </b>
                <br />
                {!(index === 0 || (index > 0 && (startVehicleLocationStateProvince === prevEndVehicleLocationStateProvince))) &&
                  <Tooltip target={'.' + `stateProvince${objectId}`} />
                }
                <span
                  className={(index === 0 || (index > 0 && (startVehicleLocationStateProvince === prevEndVehicleLocationStateProvince))) ? '' : `errorText boldText stateProvince${objectId}`}
                  data-pr-tooltip="State/Province not matching previous"
                >
                  {vehicleLocationStart && `${startVehicleLocationAprxShortName}, ${startVehicleLocationStateProvince.toUpperCase()}`}
                </span>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          {
            // Trip End
            element: (
              <div className="sb-table-cell-div">
                <b> {dateEndFormatted} </b>
                <br />
                {`${endVehicleLocationAprxShortName}, ${endVehicleLocationStateProvince.toUpperCase()}`}
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          showOdometerValues && {
            // Odometer Start
            element: (
              <div className="sb-table-cell-div">
                <b>{props.distanceUnitFilter.value === 'km' ? totalVehicleKmStart.toFixed(0) : Helpers.convertDistance(totalVehicleKmStart, 'km', 'mi').toFixed(0)}</b>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          showOdometerValues && {
            // Odometer End
            element: (
              <div className="sb-table-cell-div">
                <b>{props.distanceUnitFilter.value === 'km' ? totalVehicleKmEnd.toFixed(0) : Helpers.convertDistance(totalVehicleKmEnd, 'km', 'mi').toFixed(0)}</b>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          {
            // Drivers
            element: (
              <div className="sb-table-cell-div">
                {iftaRouteDriverPeriods && iftaRouteDriverPeriods.map((iftaRouteDriverPeriod) => {
                  const driver = getAttribute(iftaRouteDriverPeriod, 'driver');
                  if (!driver) return <div />;
                  return (
                    <div key={iftaRouteDriverPeriod.id}>
                      <Link
                        className="inlineBlock clickable textHoverHighlight"
                        to={{ pathname: '/driver', search: `?driver=${driver.id}&view=hosEvents&date=${moment(getAttribute(iftaRouteDriverPeriod, 'dateStart')).format('DDMMYY')}` }}
                      >
                        <b>{Helpers.toTitleCase(getAttribute(driver, 'user_fullName'))}</b>
                      </Link>
                    </div>
                  );
                })}
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          {
            // Fuel Purchase
            element: (
              <div className="sb-table-cell-div">
                {fuelPurchases && fuelPurchases.map((fuelPurchase) => {
                  const timeMillis = getAttribute(fuelPurchase, 'timeMillis');
                  const volumeUnits = getAttribute(fuelPurchase, 'volumeUnits');
                  const volumePumped = getAttribute(fuelPurchase, 'volumePumped');
                  const fuelType = getAttribute(fuelPurchase, 'fuelType');
                  const currency = getAttribute(fuelPurchase, 'currency');
                  const totalPaid = getAttribute(fuelPurchase, 'totalPaid');

                  let volumePumpedConverted = volumePumped;

                  if (volumeUnits === 'g' && props.volumeUnitFilter.value === 'l') {
                    volumePumpedConverted = Helpers.convertFuelUnit(volumePumped, 'gal', 'l').toFixed(2);
                  } else if (volumeUnits === 'l' && props.volumeUnitFilter.value === 'gal') {
                    volumePumpedConverted = Helpers.convertFuelUnit(volumePumped, 'l', 'gal').toFixed(2);
                  }

                  return (
                    <div>
                      <div key={fuelPurchase.id} className="inlineBlock">
                        <b>{`${fuelType && `${fuelType && fuelType.toUpperCase()}`}: ${volumePumpedConverted}`}</b>
                        &nbsp;—&nbsp;
                        <b>{`$${totalPaid} ${currency && currency.toUpperCase()}`}</b>
                      </div>
                    </div>
                  );
                })}
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          {
            // Fuel Card
            element: (
              <div className="sb-table-cell-div">
                {fuelCardDataArr && fuelCardDataArr.map((fuelCardData) => {
                  const fuelQuantity = getAttribute(fuelCardData, 'fuelQuantity');
                  const currency = getAttribute(fuelCardData, 'currency');
                  const fuelMeasurementUnit = getAttribute(fuelCardData, 'fuelMeasurementUnit');
                  const total = getAttribute(fuelCardData, 'total');
                  const item = getAttribute(fuelCardData, 'item');

                  let volumePumpedConverted = fuelQuantity;

                  if (fuelMeasurementUnit?.toLowerCase() === 'g' && props.volumeUnitFilter.value === 'l') {
                    volumePumpedConverted = Helpers.convertFuelUnit(fuelQuantity, 'gal', 'l').toFixed(2);
                  } else if (fuelMeasurementUnit?.toLowerCase() === 'l' && props.volumeUnitFilter.value === 'gal') {
                    volumePumpedConverted = Helpers.convertFuelUnit(fuelQuantity, 'l', 'gal').toFixed(2);
                  }

                  return (
                    <div>
                      <div key={fuelCardData.id} className="inlineBlock">
                        <b>{`${item && `${item && item.toUpperCase()}`}: ${volumePumpedConverted}`}</b>
                        &nbsp;—&nbsp;
                        <b>{`$${total} ${currency && currency.toUpperCase()}`}</b>
                      </div>
                    </div>
                  );
                })
                }
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          {
            // Calculated Mileage
            element: (
              <div className="sb-table-cell-div">
                <b>
                  {props.distanceUnitFilter.value === 'km' ? savedVehicleKm?.toFixed(0) : Helpers.convertDistance(savedVehicleKm, 'km', 'mi').toFixed(0)}
                </b>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          {
            // Edit Button
            element: (
              <div className="print-hide px-2 flex align-items-center justify-content-end">
                {isAutoCreated && (
                  <>
                    <Tooltip target=".autofix-badge" />
                    <Badge className="autofix-badge min-w-0 px-2 text-xs" value="AUTO" data-pr-tooltip="This route was automatically created" data-pr-position="left" />
                  </>
                )}
                <AddRoute
                  unitId={props.vehicleUnitId}
                  isEdit={true}
                  iftaRoute={iftaRoute}
                  distanceUnitFilter={props.distanceUnitFilter}
                  refreshState={(callRefreshState) => this.props.parseTableBodyRowData(callRefreshState)}
                  driverTimeZoneStr={this.props.driverTimeZoneStr}
                  iftaRouteRecordsForTablePreview={props.iftaRouteRecordsForTablePreview}
                />
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell print-hide-cell' },
          },
        ].filter((row) => row), // Filters out any rows that are false (hiding odometer readings)
      };

      return vehicleRowObj;
    });
  }

  render() {
    const { props } = this;
    const showOdometerValues = props.showOdometerValuesFilter.value === 'showOdometerValues';

    const tableHeaderStyles = {
      date: { width: '10%' },
      odometers: { width: '8%' },
      button: { width: '5%' },
      driver: { width: '10%' },
      fuelCard: { width: '13%' },
      saved: { width: '8%' },
      button: { width: '12%' },
    };

    const tableHeaderRows = [{
      key: 'sb-ifta-details-header',
      columns: [
        {
          element: <div>Trip Start</div>,
          props: { style: tableHeaderStyles.date },
        },
        {
          element: <div>Trip End</div>,
          props: { style: tableHeaderStyles.date },
        },
        showOdometerValues && {
          element: <div>Odometer Start</div>,
          props: { style: tableHeaderStyles.odometers },
        },
        showOdometerValues && {
          element: <div>Odometer End</div>,
          props: { style: tableHeaderStyles.odometers },
        },
        {
          element: <div>Driver</div>,
          props: { style: tableHeaderStyles.driver },
        },
        {
          element: <div className="mr-3">{`Fuel Purchases (${props.volumeUnitFilter.value})`}</div>,
          props: { style: tableHeaderStyles.fuelCard },
        },
        {
          element: <div className="mr-3">{`Fuel Card Transactions (${props.volumeUnitFilter.value})`}</div>,
          props: { style: tableHeaderStyles.fuelCard },
        },
        {
          element: <div className="mr-3" >{`Calculated Mileage (${props.distanceUnitFilter.value})`}</div>,
          props: { style: tableHeaderStyles.saved },
        },
        {
          element: <div className="print-hide" />,
          props: { style: tableHeaderStyles.button, className: "print-hide-cell" },
        },
      ].filter((row) => row), // Filters out any rows that are false (hiding odometer readings),
    }];

    const dateForRoutes = moment(getAttribute(props.iftaRouteRecordsArr[0], 'dateStart')).tz(props.driverTimeZoneStr).format('YYYY-MM-DD');
    const tableBodyRows = this.generateDetailsViewTable();
    const errorMessages = this.generateErrorMessages();

    return (
      <div>
        <div style={{ display: 'flex' }}>
          <h4 style={{ paddingTop: '0.5em', marginRight: '1em', paddingBottom: '0.5em' }}><b>Routes for {dateForRoutes}</b></h4>
          <div className="print-hide" style={{ paddingBottom: '0.5em', paddingTop: '0.25em' }}>
            <AddRoute
              unitId={props.vehicleUnitId}
              distanceUnitFilter={props.distanceUnitFilter}
              refreshState={(callRefreshState) => this.props.parseTableBodyRowData(callRefreshState)}
              dateForRoutes={dateForRoutes}
              customAddButtonText={`ADD ROUTES FOR ${dateForRoutes}`}
              iftaRouteRecordsArr={props.iftaRouteRecordsArr}
              iftaRouteRecordsForTablePreview={props.iftaRouteRecordsForTablePreview}
              driverTimeZoneStr={this.props.driverTimeZoneStr}
            />
          </div>
        </div>

        {errorMessages.length > 0 && (
          <SBBlock
            warning
            title="Detected Issues"
            messages={[
              ...errorMessages,
            ]}
            listMessages={false}
          />
        )}

        <SBTable
          hover
          tableHeaderRows={tableHeaderRows}
          tableBodyRows={tableBodyRows}
        />
      </div>

    );
  }
}

export default VehicleRouteBreakdownDetailsView;
