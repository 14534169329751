/** @module DispatchItem */

import {
  copyQuery, count, createQuery, createQueryOr, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, addRecord
} from 'sb-csapi/dist/AAPI';

import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @category Dispatch
 * @subcategory Item
 *
 * @description Get a list of Dispatch items for a given dispatch job
 *
 * @param {object} options - See example
 * @param {string} dispatchJobObjectId - The specific dispatch job that the item belongs to
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {int} page - The current page for pagination
 * @param {int} limit - The limit of records obtained per pagination
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { object } - { dispatchItems: [DispatchItem, ...] }
 */
async function getDispatchItems(options = { sessionToken: getCurrentUserSessionToken() }, dispatchJobObjectId, filters = [], sortBy = new Sort(QuerySortOrder.DESCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  // if (!dispatchJobObjectId) throw new Error('No dispatch job specified');

  let dispatchItemQuery = createQuery('DispatchItem');
  if (dispatchJobObjectId) setQueryRestriction(dispatchItemQuery, QueryRestriction.EQUAL_TO, 'dispatchJob', dispatchJobObjectId);

  const _filters = [...filters];

  // set universal filters
  // _filters.push(new Filter(QueryRestriction.NOT_EQUAL_TO, 'isHidden', true)); // get all non-deleted organizations

  _filters.map(filter => setQueryRestriction(dispatchItemQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  const dispatchItemCountQuery = copyQuery(dispatchItemQuery);

  // sort
  sortQuery(dispatchItemQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchItemQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchItemQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(dispatchItemQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(dispatchItemQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the count and the documents
  const promises = [count(options, dispatchItemCountQuery), findRecords(options, dispatchItemQuery, false, queryAll)];

  try {
    const [totalDispatchItemsCount, dispatchItems] = await Promise.all(promises);
    return { totalDispatchItemsCount, dispatchItems };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @category Dispatch
 * @subcategory Item
 *
 * @description Get a list of Dispatch items for a given dispatch transfer
 *
 * @param {object} options - See example
 * @param {string} dispatchJobObjectId - The specific dispatch transfer that the item belongs to
 *
 * @returns { object } - { dispatchItems: [DispatchItem, ...] }
 */
async function getDispatchItemsByTransferObjectId(options = { sessionToken: getCurrentUserSessionToken() }, dispatchTransferObjectId) {
  if (!dispatchTransferObjectId) return;
  const dispatchItemQuery = createQuery('DispatchItem');
  setQueryRestriction(dispatchItemQuery, QueryRestriction.EQUAL_TO, 'dispatchTransfer', dispatchTransferObjectId);
  return await findRecords(options, dispatchItemQuery, false, true);
}

export {
  getDispatchItems,
  getDispatchItemsByTransferObjectId,
};
