import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';


// enums
import { Status } from 'sb-csapi/dist/enums/Dispatch/Job';

// sbcore components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import Skeleton from 'sbCore/Skeleton/Skeleton';

// styles
import './style.scss';

/**
 * @description Select a Job Status
 * @param {String} className - Custom container className
 * @param {Object} style - Custom inline styles
 * @param {bool} warning - Makes the border yellow
 * @param {bool} autoFocus - Whether to automatically focus on the autocomplete on load
 * @param {int} status - Sets the status value to this by default. The status provided must be recognized in our enums: sappy/enums/DispatchJob Status
 * @param {bool} isFocusedDefault - Whether this component is the first component to be focused on. Determines if we should set isFocused to true when mounting
 * @param {bool} showOnFocus - Whether to automatically open the dropdown on focus
 * @param {bool} hideLabel - Hide the label
 * @param {bool} isLoading - Show a loading skeleton
 * @param {bool} disabled - TODO: Whether disable the component
 * @param {Function} onSelect - A callback function that returns the selected status (int)
 * @param {bool} includeAllStatusesOption - A boolean indicating whether this dropdown should show an option for all statuses
 * @param {String} variant - Make dropdown smaller
 * @returns
 */
export default function JobStatusDropdown({ ...props }) {

  const statusArray = Object.keys(Status).map(key => {
    return Status[key];
  });

  const [identifier] = useState(uniqid()); // for each of this component that exists on the same page, give it unique identifier for specific dom manipulation
  const [selectedStatus, setSelectedStatus] = useState(undefined); // the selected status object
  const [statuses, setStatuses] = useState(props.includeAllStatusesOption && [{status: -1, description: 'All Statuses', key: 'ALL'}, ...statusArray] || statusArray);
  const [isFocused, setIsFocused] = useState(props.isFocusedDefault);
  const [hasError, setHasError] = useState(false); // if there is an error with the input
  const [variant, setVariant] = useState('');

  // ** Hooks Section ** //
  useEffect(() => {
    let defaultStatus = props.status;

    if (defaultStatus !== undefined && defaultStatus !== null) {
      defaultStatus = statuses.filter(statusObj => statusObj.status === defaultStatus)[0];
    } else if (props.includeAllStatusesOption) {
      defaultStatus = statuses.filter(statusObj => statusObj.status === -1)[0]; // This int indicates all statuses
    } else {
      defaultStatus = statuses.filter(statusObj => statusObj.status === Status.INITIATED.status)[0];
    }
    setSelectedStatus(defaultStatus);
  }, [props.status]); // This hook runs the first time it is mounted and after any changes to the status prop

  useEffect(() => {
    setVariant(props.variant);
  }, [props.variant]);


  // ** Components Section ** //
  function onChange(e) {
    setSelectedStatus(e.value);
    if (props.onSelect) props.onSelect(e.value ? e.value.status : e.value); // send the chosen status int back up to parent
  }

  function toggleFocus() {
    // determine whether or not this component is focused on, to enable additional functionality
    setIsFocused(!isFocused);
  }

  // if the component is focused, allow it to be prompted by keypresses
  // if (isFocused) {
  //   const inputButtonEl = document.querySelector(`.timezone-dropdown.${identifier} .p-dropdown .p-dropdown-trigger`);
  //   const dropdownPanelEl = document.querySelector(`.timezone-dropdown.${identifier} .p-dropdown-panel .p-dropdown-items`);
  //   if (inputButtonEl && !dropdownPanelEl) {
  //     if (window.sbKeydownEvent69.keyCode === 40) {
  //       inputButtonEl.click();
  //     }

  //     console.log(dropdownPanelEl);
  //     console.log(window.sbKeydownEvent69);
  //   }
  // }

  function getJobStatusColorClass(status) {
    let className;

    statuses.find((statusObject) => {
      const isMatchingStatus = statusObject.status === status;

      if (isMatchingStatus) {
        // find the matching background color className (ex. SOME_VALUE -> some-value -> bg-dispatch-job-some-value)
        const colorClassKeyExpression = statusObject.key.replace(/_/g, '-').toLowerCase();
        className = `bg-dispatch-job-${colorClassKeyExpression}`;
      }
      return isMatchingStatus;
    });
    return className;
  }

  function itemTemplate(option, props) {
    const className = `job-status-dropdown-item ${getJobStatusColorClass(option.status)}`;

    return (
      <div className={className}>
        { option.description }
      </div>
    );
  }

  function valueTemplate(option, props) {
    if (!option) return;
    const className = `job-status-dropdown-item text-center ${getJobStatusColorClass(option.status)}`;

    return (
      <div className={className}>
        { option.description }
      </div>
    );
  }


  // // ** Misc ** //
  let className = `job-status-dropdown ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  // // if the input is not being focused on (which is for some reason dictated by the button's focus), but the user left their search term without selecting a suggestion for the searchTerm
  // const isNotFocusedWithText = inputButtonEl && searchTerm && ((typeof searchTerm) !== 'object') && (document.activeElement !== inputButtonEl);
  // const _hasError = isNotFocusedWithText || hasError;
  const _hasError = false;

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && (
        <InputLabel>Status</InputLabel>
      )}

      {props.isLoading && (
        <Skeleton width="10rem" height="2.5rem"></Skeleton>
      )}

      {!props.isLoading && (
        <Dropdown
          value={selectedStatus}
          options={statuses}
          onChange={(e) => onChange(e)}
          onFocus={(e) => toggleFocus(e)}
          onBlur={() => toggleFocus()}
          filter
          filterBy="description"
          optionLabel="description"
          placeholder="Select Status"
          valueTemplate={valueTemplate}
          itemTemplate={itemTemplate}
          autoFocus={props.autoFocus}
          warning={props.warning}
          error={_hasError}
          showOnFocus={props.showOnFocus || true}
          disabled={props.disabled}
          variant={variant}
        />
      )}

    </div>
  );
}
