import * as ActionConstants from 'actions/ActionConstants';
import * as Helpers from 'api/Helpers';

const initialState = {
  chats: {}, // specific to user loaded
  friends: [],
  fetchedFriends: false,
  status: undefined,
  error: undefined,
};

// function addNotification(stateNotifications, notificationObj) {
//   const newNotifications = stateNotifications;
//   newNotifications.unshift(notificationObj);
//   return newNotifications;
// }
//
// function notificationRead(stateNotifications, notificationObj, removeFromState) {
//   const newStateNotifications = [].concat(stateNotifications);
//   const index = Helpers.findIndexOfObjArr(stateNotifications, 'objectId', notificationObj.objectId);
//   if (removeFromState) {
//     newStateNotifications.splice(index, 1);
//   } else {
//     newStateNotifications[index] = notificationObj;
//   }
//   return newStateNotifications;
// }
//
// function getUnreadCount(notificationArr) {
//   const notificationArrLen = notificationArr.length;
//   let unreadCount = 0;
//   for (let i = 0; i < notificationArrLen; i++) {
//     if (!notificationArr[i].read) {
//       unreadCount++;
//     }
//   }
//   return unreadCount;
// }

// function destroyNotifications(stateNotifications, parseObjectArr) {
//   const newStateNotifications = [].concat(stateNotifications);
//   if (newStateNotifications) {
//     const parseObjectArrLen = parseObjectArr.length;
//     for (let i = 0; i < parseObjectArrLen; i++) {
//       const index = Helpers.findIndexOfObjArr(newStateNotifications, 'objectId', parseObjectArr[i].id);
//       newStateNotifications.splice(index, 1);
//     }
//   }
//   return newStateNotifications;
// }

function checkAddChat(state, room, status) {
  const chats = { ...state.chats };
  if (!state.chats[room.roomId || room.get('roomId')]) {
    chats[room.roomId || room.get('roomId')] = { room, messages: [], fetched: false, status, unreadCount: 0 };
  }
  return chats;
}

function updateChats(state, roomId, messages, status, fetched, unreadCount, error) {
  const chats = { ...state.chats };
  const chat = { ...chats[roomId] };
  if (chat) {
    if (messages) chat.messages = [].concat(messages);
    if (status) chat.status = status;
    if (fetched) chat.fetched = fetched;
    if (unreadCount !== undefined) chat.unreadCount = unreadCount;
    chat.error = error;

    chats[roomId] = chat;
    // console.log(chats[roomId]);
    return chats;
  }
}

function deleteChatMessages(state, roomId) {
  const chats = { ...state.chats };
  delete chats[roomId].messages;
  return chats;
}

export function Chat(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_CHATROOM_INPROGRESS:
      // console.log("1");
      return {
        ...state,
        status: action.type,
      }
    case ActionConstants.FETCH_CHATROOM_SUCCESS: {
      // console.log("2");
      return {
        ...state,
        chats: checkAddChat(state, action.room, action.type),
        status: action.type,
      }
    }
    case ActionConstants.FETCH_CHATROOM_ERROR:
      // console.log("3");
      return {
        ...state,
        status: action.type,
        error: action.error,
      }
    case ActionConstants.FETCH_CHAT_MESSAGES_INPROGRESS:
      // console.log("4");
      const chats = { ...state.chats };
      return {
        ...state,
        chats: updateChats(state, action.roomId, [], action.type, false, 0),
      };
    case ActionConstants.FETCH_CHAT_MESSAGES_SUCCESS:
      // console.log("5");
      return {
        ...state,
        chats: updateChats(state, action.roomId, action.messages, action.type, true, 0, undefined),
      };
    case ActionConstants.FETCH_CHAT_MESSAGES_ERROR:
      // console.log("6");
      return {
        ...state,
        chats: updateChats(state, action.roomId, undefined, action.type, undefined, undefined, action.error),
      };
    case ActionConstants.CHAT_MESSAGES_ADD:
      // console.log("7");
      return {
        ...state,
        chats: updateChats(state, action.roomId, state.chats[action.roomId].messages.concat([action.message]), action.type, state.chats[action.roomId].fetched, 0),
      };
    case ActionConstants.CHAT_MESSAGES_RECEIVE:
      // console.log("8");
      state.chats = checkAddChat(state, action.room, action.type);
      const roomId = action.room.get('roomId');
      return {
        ...state,
        chats: updateChats(state, roomId, state.chats[roomId].messages.concat([action.message]), action.type, state.chats[roomId].fetched, state.chats[roomId].unreadCount + 1),
      };
    case ActionConstants.CHAT_MESSAGES_MARK_READ:
      // console.log("9");
      return {
        ...state,
        chats: updateChats(state, action.roomId, undefined, action.type, undefined, 0),
      }
    case ActionConstants.DELETE_CHAT_MESSAGES:
      // console.log("10");
      return {
        ...state,
        chats: deleteChatMessages(state, action.roomId),
      };
    case ActionConstants.FETCH_CHAT_FRIENDS_INPROGRESS:
      // console.log("11");
      return {
        ...state,
        status: action.type,
        fetchedFriends: false,
      };
    case ActionConstants.FETCH_CHAT_FRIENDS_SUCCESS:
      // console.log("12");
      return {
        ...state,
        status: action.type,
        friends: action.users,
        fetchedFriends: true,
      };
    case ActionConstants.FETCH_CHAT_FRIENDS_ERROR:
      // console.log("13");
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    case ActionConstants.ADD_CHAT_FRIEND_SUCCESS:
      // console.log("14");
      return {
        ...state,
        friends: state.friends.concat([action.friend]),
        status: action.type,
      }
    case ActionConstants.DELETE_CHAT_FRIENDS:
      // console.log("15");
      return {
        ...state,
        friends: [],
        status: action.type,
      };
    default:
      return state;
  }
}
