export const DocumentTypes = Object.freeze({
  'PROOF_OF_DELIVERY': {
    type: 0,
    description: 'Proof of Delivery',
    code: 'POD',
  },
  'CUSTOMER_BILL_OF_LADING': {
    type: 1,
    description: 'Customer Bill of Lading',
    code: 'cusBOL',
  },
  'SHIPPER_BILL_OF_LADING': {
    type: 2,
    description: 'Shipper Bill of Lading',
    code: 'shpBOL',
  },
  'CARRIER_BILL_OF_LADING': {
    type: 3,
    description: 'Carrier Bill of Lading',
    code: 'carBOL',
  },
  'DAMAGE': {
    type: 4,
    description: 'Damage',
    code: 'DMG',
  },
  'CUSTOMS': {
    type: 5,
    description: 'Customs',
    code: 'CUTM',
  },
  // 'LOAD_CONFIRMATION': {
  //   type: 6,
  //   description: 'Load Confirmation',
  //   code: 'LC',
  // },
  'INTERCHANGE': {
    type: 7,
    description: 'Interchange',
    code: 'INT',
  },
  'OTHER': {
    type: 8,
    description: 'Other',
    code: 'OTH',
  },
  'INVOICE': {
    type: 9,
    description: 'Invoice',
    code: 'INV',
  },
  'LOAD_CONFIRMATION': {
    type: 10,
    description: 'Load Confirmation',
    code: 'RC'
  },
  'COMMERCIAL_INVOICE': {
    type: 11,
    description: 'Commercial Invoice',
    code: 'comINV'
  },
  'CARRIER_INVOICE': {
    type: 12,
    description: 'Carrier Invoice',
    code: 'carINV'
  }
});

export function getDocumentTypeFromTypeCode(typeCode) {
  const keys = Object.keys(DocumentTypes);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (DocumentTypes[key].type === typeCode) {
      return DocumentTypes[key];
    }
  }
  return undefined;
}