import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import history from "sbHistory";
import moment from 'moment-timezone';

import { TableHeader, TableBody, TableRow, TableRowColumn } from 'material-ui';

// API
import { isAlmostOutOfHours, projectAndFormatDrivingTime, projectAndFormatOnDutyTime } from 'api/ELD';
import { convertMillisecondsToHours, formatTimeNumberToHoursMinutes } from 'api/Helpers';
import { getDriverHOSRecap } from 'api/DriverHOSRecap/DriverHOSRecap';

// Components
import LazyLoadTable from 'components/LazyLoadTable/container/LazyLoadTable';
import SortButton from 'components/SortButton/view/SortButton';

// CSS
import styles from './DriverHoursTable.module.scss';

function DriverHoursTable(props) {
  const [reset, setReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableBodyArr, setTableBodyArr] = useState([]);

  useEffect(() => {
    let didCancel = false;

    async function getDriverHourInformation() {
      if (props.Driver.drivers.length === 0) {
        setReset(true);
        return;
      } else {
        setIsLoading(true);
      }

      const sortedDrivers = props.Driver.drivers.sort((a, b) => {
        if (props.sort && Object.keys(props.sort).length === 0) {
          if (a.get('latestELDEvent') && b.get('latestELDEvent')) {
            return (moment(a.get('latestELDEvent').get('eventDateTime')).isAfter(moment(b.get('latestELDEvent').get('eventDateTime'))) ? -1 : 1);
          } else if (a.get('latestELDEvent')) {
            return -1;
          } else if (b.get('latestELDEvent')) {
            return 1;
          }
          return 0;
        }
        return 0;
      });

      const filteredDrivers = sortedDrivers.filter((driver) => {
        if (props.tableView === 'active') {
          if (
            driver.get('latestELDEvent') &&
            driver.get('latestELDEvent').get('eventDateTime') &&
            moment(driver.get('latestELDEvent').get('eventDateTime')).isAfter(moment().subtract(1, 'day'))
          ) {
            return true;
          } else {
            return false;
          }
        }
        return true;
      });

      const tableBodyPromisesArr = filteredDrivers.map(async (driver, index) => {
        const driverUser = driver.get('user');
        const fullName = `${driverUser.get('firstName')} ${driverUser.get('lastName')}`;
        const location = (driver.get('latestELDEvent') && driver.get('latestELDEvent').get('vehicleLocation') && driver.get('latestELDEvent').get('vehicleLocation').get('aprxShortName')) ? `${driver.get('latestELDEvent').get('vehicleLocation').get('aprxShortName')}, ${driver.get('latestELDEvent').get('vehicleLocation').get('stateProvince') && driver.get('latestELDEvent').get('vehicleLocation').get('stateProvince').toUpperCase()}` : null;

        const {
          caOnDutyCycleHours,
          caDriveTimeLeft,
          caOnDutyTimeLeft,
          usOnDutyCycleHours,
          usDriveTimeLeft,
          usOnDutyTimeLeft,
          usBreakTime,
        } = await getDriverHOSRecap(driver, undefined, props.capHours);

        return (
          <TableRow key={`${driver.id}${index}`} className={styles.driverRow}>
            <TableRowColumn style={{ width: '10.8%' }}>
              <div className={styles.driver} onClick={() => history.push({ pathname: 'driver', search: "driver=" + driver.id })}>
                {driver.get('vehicle_unitId')}
                &nbsp;
                <span style={{ fontSize: '0.8em', paddingTop: '0.1em', float: 'right', fontWeight: 'initial' }}>
                  ({fullName})
                </span>
              </div>
            </TableRowColumn>

            <TableRowColumn style={{ textAlign: 'center', width: '11%' }}>
              {driver.get('eldStatusInt') && driver.get('latestELDEvent') &&
                <div>
                  <b>{location}</b>
                  {driver.get('latestELDEvent') &&
                    <div style={{ fontSize: '0.8em', paddingTop: '0.1em' }}>
                      @ {moment(driver.get('latestELDEvent').get('eventDateTime')).format('MMM')} {moment(driver.get('latestELDEvent').get('eventDateTime')).format('D')} '{moment(driver.get('latestELDEvent').get('eventDateTime')).format('YY')}, {moment(driver.get('latestELDEvent').get('eventDateTime')).format('LT')}
                    </div>
                  }
                </div>
              }
            </TableRowColumn>

            <TableRowColumn style={{ textAlign: 'center', borderLeft: '0.1em solid #BFBFBF', width: '9%' }}>
              {(caOnDutyCycleHours !== 'N/A') &&
                <div className={isAlmostOutOfHours(caOnDutyCycleHours) ? `${styles.hourNumberOut}` : null}>{formatTimeNumberToHoursMinutes(convertMillisecondsToHours(projectAndFormatOnDutyTime(caOnDutyCycleHours, driver)))}</div>
              }
              {(caOnDutyCycleHours === 'N/A') &&
                <div>N/A</div>
              }
            </TableRowColumn>

            <TableRowColumn style={{ textAlign: 'center', width: '9%' }}>
              {(caDriveTimeLeft !== 'N/A') &&
                <div className={isAlmostOutOfHours(caDriveTimeLeft) ? `${styles.hourNumberOut}` : null}>{formatTimeNumberToHoursMinutes(convertMillisecondsToHours(projectAndFormatDrivingTime(caDriveTimeLeft, driver)))}</div>
              }
              {(caDriveTimeLeft === 'N/A') &&
                <div>N/A</div>
              }
            </TableRowColumn>

            <TableRowColumn style={{ textAlign: 'center', width: '9%' }}>
              {(caOnDutyTimeLeft !== 'N/A') &&
                <div className={isAlmostOutOfHours(caOnDutyTimeLeft) ? `${styles.hourNumberOut}` : null}>{formatTimeNumberToHoursMinutes(convertMillisecondsToHours(projectAndFormatOnDutyTime(caOnDutyTimeLeft, driver)))}</div>
              }
              {(caOnDutyTimeLeft === 'N/A') &&
                <div>N/A</div>
              }
            </TableRowColumn>

            <TableRowColumn style={{ textAlign: 'center', borderLeft: '0.1em solid #BFBFBF', width: '9%' }}>
              {(usOnDutyCycleHours !== 'N/A') &&
                <div className={isAlmostOutOfHours(usOnDutyCycleHours) ? `${styles.hourNumberOut}` : null}>{formatTimeNumberToHoursMinutes(convertMillisecondsToHours(projectAndFormatOnDutyTime(usOnDutyCycleHours, driver)))}</div>
              }
              {(usOnDutyCycleHours === 'N/A') &&
                <div>N/A</div>
              }
            </TableRowColumn>

            <TableRowColumn style={{ textAlign: 'center', width: '9%' }}>
              {(usDriveTimeLeft !== 'N/A') &&
                <div className={isAlmostOutOfHours(usDriveTimeLeft) ? `${styles.hourNumberOut}` : null}>{formatTimeNumberToHoursMinutes(convertMillisecondsToHours(projectAndFormatDrivingTime(usDriveTimeLeft, driver)))}</div>
              }
              {(usDriveTimeLeft === 'N/A') &&
                <div>N/A</div>
              }
            </TableRowColumn>

            <TableRowColumn style={{ textAlign: 'center', width: '9%' }}>
              {(usBreakTime !== 'N/A') &&
                <div className={isAlmostOutOfHours(usBreakTime) ? `${styles.hourNumberOut}` : null}>{formatTimeNumberToHoursMinutes(convertMillisecondsToHours(projectAndFormatDrivingTime(usBreakTime, driver)))}</div>
              }
              {(usBreakTime === 'N/A') &&
                <div>N/A</div>
              }
            </TableRowColumn>

            <TableRowColumn style={{ textAlign: 'center', borderRight: '0.1em solid #BFBFBF', width: '9%' }}>
              {(usOnDutyTimeLeft !== 'N/A') &&
                <div className={isAlmostOutOfHours(usOnDutyTimeLeft) ? `${styles.hourNumberOut}` : null}>{formatTimeNumberToHoursMinutes(convertMillisecondsToHours(projectAndFormatOnDutyTime(usOnDutyTimeLeft, driver)))}</div>
              }
              {(usOnDutyTimeLeft === 'N/A') &&
                <div>N/A</div>
              }
            </TableRowColumn>

          </TableRow>
        );
      });

      Promise.all(tableBodyPromisesArr).then((result) => {
        if (!didCancel) {
          setTableBodyArr(result);
          setIsLoading(false);
        }
      });
    }

    getDriverHourInformation();
    return () => { didCancel = true };
  }, [props.Driver.drivers.length]);

  useEffect(() => {
    if (reset) setReset(false);
  }, [reset]);

  return (
    <div className={styles.container}>
      <LazyLoadTable
        fixedHeader
        fetched={props.Driver.fetched}
        fetch={(page, limit) => props.fetchDrivers(page, limit)}
        resetBool={reset}
        isLazyLoading={isLoading}
        rowHeight={48}
        height={Math.max(0.5 * document.documentElement.clientHeight, 0.5 * window.innerHeight || 0)}
      >
        <TableHeader
          adjustForCheckbox={false}
          displaySelectAll={false}
          style={{ fontWeight: 'bold' }}
          className="translate-me"
        >
          <TableRow style={{ textAlign: 'center' }}>
            <TableRowColumn style={{ width: '15%' }} />
            <TableRowColumn style={{ width: '11%' }} />
            <TableRowColumn
              colSpan={3}
              style={{ textAlign: 'center', borderLeft: '0.1em solid #BFBFBF', width: '32%' }}
            >
              Canadian Hours-of-Service (Time Remaining)
            </TableRowColumn>
            <TableRowColumn
              colSpan={4}
              style={{ textAlign: 'center', borderLeft: '0.1em solid #BFBFBF', borderRight: '0.1em solid #BFBFBF', width: '43%' }}
            >
              US Hours-of-Service (Time Remaining)
            </TableRowColumn>
          </TableRow>
          <TableRow>
            <TableRowColumn style={{ textAlign: 'center', width: '15%' }}>
              Vehicle
              <SortButton
                clickHandler={() => props.sortHandler('user_fullName')}
                isActive={props.sort.user_fullName}
              />
            </TableRowColumn>
            <TableRowColumn style={{ textAlign: 'center', width: '11%' }}>
              Location
              <SortButton
                clickHandler={() => props.sortHandler()}
                isActive={props.sort.eldStatusInt}
              />
            </TableRowColumn>

            <TableRowColumn
              style={{ textAlign: 'center', borderLeft: '0.1em solid #BFBFBF', width: '9%' }}
            >
              Cycle
              <SortButton
                clickHandler={() => props.sortHandler('usOnDutyTimeLeft')}
                isActive={props.sort.usOnDutyTimeLeft}
              />
            </TableRowColumn>
            <TableRowColumn style={{ textAlign: 'center', width: '9%' }}>
              Driving
              <SortButton
                clickHandler={() => props.sortHandler('caDriveTimeLeft')}
                isActive={props.sort.caDriveTimeLeft}
              />
            </TableRowColumn>
            <TableRowColumn style={{ textAlign: 'center', borderRight: '0.1em solid #BFBFBF', width: '9%' }}>
              On Duty
              <SortButton
                clickHandler={() => props.sortHandler('caOnDutyTimeLeft')}
                isActive={props.sort.caOnDutyTimeLeft}
              />
            </TableRowColumn>
            <TableRowColumn style={{ textAlign: 'center', width: '9%' }}>
              Cycle
              <SortButton
                clickHandler={() => props.sortHandler('usOnDutyTimeLeft')}
                isActive={props.sort.usOnDutyTimeLeft}
              />
            </TableRowColumn>
            <TableRowColumn style={{ textAlign: 'center', width: '9%' }}>
              Driving
              <SortButton
                clickHandler={() => props.sortHandler('usDriveTimeLeft')}
                isActive={props.sort.usDriveTimeLeft}
              />
            </TableRowColumn>
            <TableRowColumn style={{ textAlign: 'center', width: '9%' }}>
              Break
              <SortButton
                clickHandler={() => props.sortHandler('usBreakTime')}
                isActive={props.sort.usBreakTime}
              />
            </TableRowColumn>
            <TableRowColumn style={{ textAlign: 'center', borderRight: '0.1em solid #BFBFBF', width: '9%' }}>
              On Duty
              <SortButton
                clickHandler={() => props.sortHandler('usOnDutyTimeLeft')}
                isActive={props.sort.usOnDutyTimeLeft}
              />
            </TableRowColumn>
          </TableRow>
        </TableHeader>
        <TableBody key="1" displayRowCheckbox={false}>
          {tableBodyArr}
        </TableBody>
      </LazyLoadTable>
    </div>
  );
}

DriverHoursTable.propTypes = {
  capHours: PropTypes.bool,
  Driver: PropTypes.object.isRequired,
  fetchDrivers: PropTypes.func.isRequired,
  sortHandler: PropTypes.func,
  sort: PropTypes.object,
};

export default DriverHoursTable;
