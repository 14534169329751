import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import history from 'sbHistory';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { TableHeader, TableBody, TableRow, TableRowColumn } from 'material-ui';
import { addReplaceQueryParameter, getQueryParameter } from 'api/URL';

// Actions
import { deleteGeofenceEventsForState, fetchLinkedGeofenceEventsForState } from 'actions/GeofenceEvent';

// API
import * as Analytics from 'api/Analytics';
import { getAttribute } from 'api/Parse';
import {
  getCompanyAnalytics,
  updateCompanyAnalytics,
} from 'api/CompanyAnalytics/CompanyAnalytics';

// Components
import Title from 'components/LayoutTitle/view/Title';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import LazyLoadTable from 'components/LazyLoadTable/container/LazyLoadTable';

// CSS
import styles from './GeofenceEventsLayout.module.scss';
import { MDBBtn } from 'mdbreact';

// Context
import StoreContext from 'contexts/StoreContext';

function getEnterExit(geofenceObj) {
  if (geofenceObj.get('geofenceEventStateInt') === 1) {
    return 'Enter';
  } else if (geofenceObj.get('geofenceEventStateInt') === 2) {
    return 'Exit';
  }
  return '';
}

class GeofenceEventsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      limit: 0,
      geofenceEventsToDownload: [],
      geofenceEventDownloadStatus: 0, // 0: nothing, 1: loading, 2: ready
      geofenceEventFilter: [],
    };
    this.state.filterFields = [
      {
        attrName: 'dateTime',
        fullName: 'Date',
        queryType: 'date',
        dateQueryType: 'dateMonth',
        placeholder: '.....',
        fromValue: props.location.search.date ? moment(props.location.search.date, 'DDMMYY').startOf('month') : moment().startOf('month'),
        toValue: props.location.search.date ? moment(props.location.search.date, 'DDMMYY').endOf('month') : moment().endOf('month'),
        type: 'date'
      },
      { attrName: 'vehicleUnitId', fullName: 'Vehicle', queryType: 'contains', placeholder: '.....', type: 'text' },
    ];
    this.state.geofenceEventFilter = [
      {
        name: 'dateTime',
        queryType: 'date',
        dateQueryType: 'dateMonth',
        fromValue: props.location.search.date ? moment(props.location.search.date, 'DDMMYY').startOf('month') : moment().startOf('month'),
        toValue: props.location.search.date ? moment(props.location.search.date, 'DDMMYY').endOf('month') : moment().endOf('month'),
      },
    ];
    this.filterSubmit = this.filterSubmit.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.fetchGeofenceEvents = this.fetchGeofenceEvents.bind(this);
    this.printReport = this.printReport.bind(this);
  }

  async componentDidMount() {
    // Check company analytics status
    const companyAnalytics = await getCompanyAnalytics();
    const GEOFVIEW = getAttribute(companyAnalytics, 'GEOFVIEW');
    if (!GEOFVIEW) {
      Analytics.track('GeofenceEvents Viewed');
      await updateCompanyAnalytics(companyAnalytics, { GEOFVIEW: true });
    }

    deleteGeofenceEventsForState();
    this.fetchGeofenceEvents(undefined, undefined);

    Analytics.identifyOnceForCompany('GEOFVIEW', {
      'GeofenceEvents Viewed': true,
      'GeofenceViewDate': moment().toISOString(),
    });
  }

  componentWillReceiveProps(nextProps) {
    const newDate = getQueryParameter(nextProps.location.search, 'date');
    const oldDate = getQueryParameter(this.props.location.search, 'date');
    if (oldDate !== newDate || (this.props.geofence !== nextProps.geofence)) {
      const newState = { ...this.state };
      newState.filterFields[0].value = moment(newDate, 'DDMMYY');
      this.setState(newState, () => {
        const newFilter = [].concat(this.state.geofenceEventFilter);
        newFilter[0].fromDate = newDate ? moment(newDate, 'DDMMYY').startOf('month') : moment().startOf('month');
        newFilter[0].toDate = newDate ? moment(newDate, 'DDMMYY').endOf('month') : moment().endOf('month');
        // newFilter[0].value = newDate ? moment(newDate, 'DDMMYY') : moment();
        this.filterSubmit(newFilter);
      });
    }
  }

  filterSubmit(filterArr) {
    deleteGeofenceEventsForState();
    let month = moment();
    let startDate;
    let endDate;
    const filterFormFields = [].concat(...this.state.geofenceEventFilter);
    for (let i = 0; i < filterArr.length; i++) {
      const filter = filterArr[i];
      for (let j = 0; j < filterFormFields.length; j++) {
        if (filterFormFields[j].attrName === filter.attribute) {
          filterFormFields[j].value = filter.value;
        }
      }
      if (filter.dateQueryType === 'month') {
        month = filter.value;
        filter.fromValue = moment(month).startOf('month');
        filter.toValue = moment(month).endOf('month');
      }
    }
    this.setState({ ...this.state, geofenceEventFilter: filterArr }, () => {
      this.fetchGeofenceEvents(this.state.page, this.state.limit);
      history.push({
        pathname: this.props.location.pathname, search: this.props.location.search ? addReplaceQueryParameter(this.props.location.search, 'date', moment(this.state.geofenceEventFilter[0].value).format('DDMMYY')) : 'date=' + moment(this.state.geofenceEventFilter[0].value).format('DDMMYY'),
      });
    });
  }

  fetchGeofenceEvents(page, limit) {
    this.setState({ ...this.state, page, limit });
    const filter = [].concat(this.state.geofenceEventFilter, { name: 'geofence', value: this.props.geofence, queryType: 'equalTo' });
    // This doesn't handle a case where the geofence's name will be changed in the future
    // const filter = [].concat(this.state.geofenceEventFilter, { name: 'geofence_name', value: this.props.geofence && this.props.geofence.get('name'), queryType: 'equalTo' });
    fetchLinkedGeofenceEventsForState(page, limit, filter, { dateTime: 'descending' });
  }

  clearFilter() {
    deleteGeofenceEventsForState();
    const tempDate = getQueryParameter(this.props.location.search, 'date');
    this.setState({ ...this.state, geofenceEventFilter: [{ name: 'dateTime', queryType: 'date', value: tempDate ? moment(tempDate, 'DDMMYY') : moment(new Date()) }] }, () => {
      this.fetchGeofenceEvents(this.state.page, this.state.limit);
      history.push({
        pathname: this.props.location.pathname, search: this.props.location.search ? addRemoveQueryParameter(this.props.location.search, 'date', '') : 'date=',
      });
    });
  }

  printReport() {
    this.setState({ ...this.state, loadingPrint: true });
    const date = this.state.geofenceEventFilter[0] ? this.state.geofenceEventFilter[0].value.format() : undefined;
    const vehicleUnitId = this.state.geofenceEventFilter[1] ? this.state.geofenceEventFilter[1].value : undefined;
    const geofenceId = this.props.geofence.id;
    Parse.Cloud.run('generateGeofenceEventReport', { date, vehicleUnitId, geofenceId, type: 'Geofence' }).then((parseObject) => {
      window.open(parseObject.get('file').url());
      this.setState({ ...this.state, loadingPrint: false });
    });
  }

  render() {
    return (
      <div className={styles.geofenceEventsLayout}>
        <Title superClassName={styles.title} title="Geofence Events">
          <span>
            <FilterForm
              className={styles.filterForm}
              handleFilter={this.filterSubmit}
              clearFilter={this.clearFilter}
              fields={this.state.filterFields}
              submitOnSelect
            />
          </span>
          <MDBBtn color="primary" size="sm" className="mr-5 mt-4" disabled={this.state.loadingPrint} onClick={() => this.printReport()}>Print Report</MDBBtn>
        </Title>

        <LazyLoadTable
          className={styles.maxTableHeight}
          fetched={this.props.GeofenceEvent.fetched}
          rowHeight={0}
          disableFetchOnInit
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            enableSelectAll={false}
          ><TableRow>
              <TableRowColumn><b>Date</b></TableRowColumn>
              <TableRowColumn style={{ textAlign: 'center' }}><b>Vehicle/Trailer</b></TableRowColumn>
              <TableRowColumn><b>Time</b></TableRowColumn>
              <TableRowColumn><b>Enter/Exit</b></TableRowColumn>
            </TableRow></TableHeader>
          <TableBody displayRowCheckbox={false}>
            {this.props.GeofenceEvent.geofenceEvents && this.props.GeofenceEvent.geofenceEvents.length > 0 &&
              this.props.GeofenceEvent.geofenceEvents.map((geofenceEvent) => (
                <TableRow
                  key={geofenceEvent.id}
                  style={getEnterExit(geofenceEvent) === 'Enter' ? { backgroundColor: '#e2fee2' } : { backgroundColor: '#fee2e2' }}
                >
                  <TableRowColumn>{geofenceEvent.get('dateTime') && moment(geofenceEvent.get('dateTime')).format('ll')}</TableRowColumn>
                  <TableRowColumn style={{ textAlign: 'center' }}><b>{geofenceEvent.get('vehicleUnitId') || geofenceEvent.get('trailerUnitId')}</b></TableRowColumn>
                  <TableRowColumn>{geofenceEvent.get('dateTime') && moment(geofenceEvent.get('dateTime')).format('LT')}</TableRowColumn>
                  <TableRowColumn>{getEnterExit(geofenceEvent)}</TableRowColumn>
                </TableRow>
              ))
            }
            {!this.props.GeofenceEvent || (this.props.GeofenceEvent.fetched && this.props.GeofenceEvent.geofenceEvents.length === 0) &&
              <TableRow >
                <TableRowColumn colSpan={42} style={{ textAlign: 'center' }}><b>No Geofence Events for this date</b></TableRowColumn>
              </TableRow>
            }
          </TableBody>
        </LazyLoadTable>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { GeofenceEvent } = state;
  return {
    GeofenceEvent,
  };
};

GeofenceEventsLayout.propTypes = {
  GeofenceEvent: PropTypes.object.isRequired,
  geofence: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(GeofenceEventsLayout);
