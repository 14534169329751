import React, { useState } from 'react';

// Sappy API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import DispatchUploadDocument from 'components/Dispatch/DispatchUploadDocument/DispatchUploadDocument';
import DispatchAllDocumentsTable from 'components/Dispatch/DispatchAllDocumentsTable/DispatchAllDocumentsTable';

// Styles
import './style.scss';

/**
 * @description Renders an accordion type table that displays all documents associated with a job
 * @param {DispatchJob} dispatchJob - Current dispatch job
 */
function DispatchAllDocuments({ ...props }) {

  // ** useStates ** //
  const [isDocumentUploaded, setIsDocumentUploaded] = useState(false);

  // ** Callback Functions ** //
  const handleDocumentUpload = (_isDocumentUploaded) => {
    setIsDocumentUploaded(_isDocumentUploaded);
  };

  return (
    <div className="dispatch-all-documents">
      <div className="flex flex-column justify-content-center lg:justify-content-end xl:flex-row w-full p-2">
        <DispatchUploadDocument
          dispatchJob={props.dispatchJob}
          handleDocumentUpload={handleDocumentUpload}
        />
      </div>

      <div className="mb-5">
        <DispatchAllDocumentsTable
          dispatchJobObjectId={props.dispatchJob && getAttribute(props.dispatchJob, 'objectId')}
          isDocumentUploaded={isDocumentUploaded}
          handleDocumentUpload={handleDocumentUpload}
        />
      </div>
    </div>
  );
}

export default DispatchAllDocuments;
