const ErrorTypes = Object.freeze({
  JUMPED_MILEAGES: {
    title: 'JUMPED MILEAGES',
    key: 'JUMPED_MILEAGES',
  },
  UNUSUAL_ODOMETER_READING: {
    title: 'UNUSUAL ODOMETER READING',
    key: 'UNUSUAL_ODOMETER_READING',
  },
  STATE_PROVINCE_NOT_ADJACENT: {
    title: 'STATE/PROVINCES NOT ADJACENT',
    key: 'STATE_PROVINCE_NOT_ADJACENT',
  },
  UNIDENTIFIED_DRIVING: {
    title: 'UNIDENTIFIED DRIVING',
    key: 'UNIDENTIFIED_DRIVING',
  },
  DAY_JUMP_WITH_MISSING_MILEAGES: {
    title: 'MISSING MILEAGES',
    key: 'DAY_JUMP_WITH_MISSING_MILEAGES',
  },
  GPS_CALCULATED_MILEAGE_DIFFERENCE: {
    title: 'GPS & CALCULATED MILEAGE DIFFERENCES',
    key: 'GPS_CALCULATED_MILEAGE_DIFFERENCE',
  },
  TOTAL_ODOMETER_CALCULATED_DIFFERENCE: {
    title: 'TOTAL ODOMETER AND CALCULATED MILEAGE DIFFERENCES',
    key: 'TOTAL_ODOMETER_CALCULATED_DIFFERENCE',
  },
  MISSING_MILEAGE_INFORMATION: {
    title: 'MISSING MILEAGE INFORMATION',
    key: 'MISSING_MILEAGE_INFORMATION',
  },
  NO_MILEAGE_INFORMATION: {
    title: 'NO MILEAGE INFORMATION',
    key: 'NO_MILEAGE_INFORMATION',
  },
  NO_ERROR: {
    title: 'NO ERROR',
    key: 'NO ERROR',
  },
});

export default ErrorTypes;
