
/** @module DispatchJob */

import {
  callCloudFunction, copyQuery, count, createQuery, createQueryOr, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, getCurrentUser, addRecord,
} from 'sb-csapi/dist/AAPI';

import { getPrefix, getAbbreviatedPrefix } from 'api/Company/Company';
import { countJobs } from 'api/Dispatch/DispatchJob.old';

import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';
// import { AttributeTypes, StatusTypes } from 'enums/DispatchJob';

import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @memberof module:DispatchJob
 *
 * @description Get DispatchJob according to filter criteria
 *
 * @param {object} options - See example
 * @param {string} companyObjectId - Company we wish to retrieve jobs for
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {bool} includeChildCompanies - Include child/sub-companies of this parent company
 * @param {int} page - The current page for pagination
 * @param {int} limit - The limit of records obtained per pagination
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { object } - { dispatchOrganizations: [DispatchOrganization, ...] }
 */
async function getDispatchJobs(options = { sessionToken: getCurrentUserSessionToken() }, companyObjectId = getCurrentUserCompanyObjectId(), includeChildCompanies, filters = [], sortBy = new Sort(QuerySortOrder.DESCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  let dispatchJobQuery = createQuery('DispatchJob');

  const _filters = [...filters];

  const companyQuery = createQuery('Company');
  setQueryRestriction(companyQuery, QueryRestriction.EQUAL_TO, 'objectId', companyObjectId);

  if (includeChildCompanies) {
    // if getting child companies' organizations, the additional queries
    const companyLinkQuery = createQuery('CompanyLink');

    const companyLinkFilters = [
      new Filter(QueryRestriction.MATCHES_QUERY, 'parentCompany', companyQuery),
      new Filter(QueryRestriction.EQUAL_TO, 'authorized', true),
    ];

    companyLinkFilters.map(filter => setQueryRestriction(companyLinkQuery, filter.queryRestriction, filter.attribute, filter.value));

    // now that we have all the child companies, create queries that get the jobs of them
    const companyLinks = await findRecords(options, companyLinkQuery);
    const childCompanyDispatchJobQueries = companyLinks.map(company => {
      const childCompanyDispatchJobQuery = createQuery('DispatchJob');
      setQueryRestriction(childCompanyDispatchJobQuery, QueryRestriction.EQUAL_TO, 'belongsToCompany', getAttribute(company, 'childCompany'));
      return childCompanyDispatchJobQuery;
    });

    // the main current user's company's job query
    const mainCompanyDispatchJobQuery = createQuery('DispatchJob');
    setQueryRestriction(mainCompanyDispatchJobQuery, QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery);

    // altogether
    dispatchJobQuery = createQueryOr([mainCompanyDispatchJobQuery, ...childCompanyDispatchJobQueries]);

  } else {
    // lock queries to the current users company
    setQueryRestriction(dispatchJobQuery, QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery);
  }

  // set universal filters
  // _filters.push(new Filter(QueryRestriction.NOT_EQUAL_TO, 'isHidden', true)); // get all non-deleted organizations

  _filters.map(filter => setQueryRestriction(dispatchJobQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  const dispatchJobCountQuery = copyQuery(dispatchJobQuery);

  // sort
  sortQuery(dispatchJobQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchJobQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchJobQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(dispatchJobQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(dispatchJobQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the count and the jobs
  const promises = [count(options, dispatchJobCountQuery), findRecords(options, dispatchJobQuery, false, queryAll)];

  try {
    const [totalDispatchJobsCount, dispatchJobs] = await Promise.all(promises);
    return { totalDispatchJobsCount, dispatchJobs };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchJob
 *
 * @description Update a dispatch job
 *
 * @param {DispatchJob} dispatchJob - The dispatchJob to update. If not available, use parameter dispatchJobObjectId instead
 * @param {string} dispatchJobObjectId - The objectId of the dispatchJob object we wish to update. Use if parameter dispatchJob is unavailable
 * @param {object} keyValueObj - The select fields we wish to update
 * @param {bool} save - If true, executes save on the changes. If false, holds the changes locally but is not saved
 *
 * @returns {DispatchJob} - The updated DispatchJob
 */
 async function updateDispatchJob(dispatchJob, dispatchJobObjectId, keyValueObj, save) {
  if (!dispatchJob && !dispatchJobObjectId) throw new Error('Missing Arguments: Must provide dispatchJob or dispatchJobObjectId');
  if (dispatchJob && dispatchJobObjectId) throw new Error('Arguments: Must provide only one of dispatchJob or dispatchJobObjectId');

  let _dispatchJob = dispatchJob;

  try {
    if (!dispatchJob && dispatchJobObjectId) {
      const dispatchJobQuery = createQuery('DispatchJob');
      setQueryRestriction(dispatchJobQuery, QueryRestriction.EQUAL_TO, 'objectId', dispatchJobObjectId);
      _dispatchJob = await findRecords({ sessionToken: getCurrentUserSessionToken() }, dispatchJobQuery, true);
    }

    if (_dispatchJob) {
      if (!Object.keys(keyValueObj).length) return _dispatchJob;

      _dispatchJob = await updateRecord({ sessionToken: getCurrentUserSessionToken() }, _dispatchJob, keyValueObj, save);
      return _dispatchJob;
    } else {
      throw new Error(`DispatchJob does not exist`);
    }
  } catch (err) {
    throw new Error(err);
  }
}


/**
 * @memberof module:DispatchJob
 *
 * @description Add a Dispatch Job
 *
 * @param {object} keyValueObj - The select fields we wish to update
 *
 * @returns {DispatchJob} - The created dispatchJob
 */
 async function addDispatchJob(keyValueObj) {
  if (!keyValueObj.belongsToCompany) throw new Error('Cannot create record: Requires BelongsToCompany pointer');

  try {
    const dispatchJob = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'DispatchJob', keyValueObj, getCurrentUserCompanyObjectId());
    return dispatchJob;
  } catch (err) {
    throw new Error(err);
  }
}


/**
 * @memberof module:DispatchJob
 *
 * @description Generate a dispatch job batch ID
 *
 * @param {DispatchOrganization} customerDispatchOrganization - Customer DispatchOrganization record
 *
 * @return {string} The batch ID
 */
async function generateBatchId(customerDispatchOrganization, dispatchJob) {

  const count = await countJobs();

  let batchId = count;

  // If batchid exists already, don't change the number
  if (dispatchJob) {
    const batchIdOld = getAttribute(dispatchJob, 'batchId');
    const batchIdItems = batchIdOld.split('-');
    batchId = batchIdItems[batchIdItems.length - 1];
  }

  if (customerDispatchOrganization) {
    if (batchId < 1000) batchId += 1000;
    const organizationId = getAttribute(customerDispatchOrganization, 'organizationId');
    if (organizationId) batchId = `${organizationId}-${batchId}`;
  }

  const prefix = getPrefix();
  if (prefix) {
    const abbreviatedPrefix = getAbbreviatedPrefix(prefix, 4).toUpperCase();
    batchId = `${abbreviatedPrefix}-${batchId}`;
  }

  return batchId;
}

/**
 * @memberof module:DispatchJob
 *
 * @description Clone a dispatch job given the job object id
 *
 * @param {String} dispatchJobObjectId - Dispatch job's object id to clone
 *
 * @return {DispatchJob} The dispatch job clone
 */
async function cloneDispatchJob(dispatchJobObjectId) {
  if (!dispatchJobObjectId) throw new Error('Missing Arguments: Must provide dispatchJobObjectId');
  const dispatchJobClone = await callCloudFunction('cloneDispatchJob', { dispatchJobObjectId });
  return dispatchJobClone;
}

/**
 * @memberof module:DispatchJob
 *
 * @description Updates the DispatchJob given so that isInvoiceOutdated is false
 *
 * @param {DispatchJob} dispatchJob - The selected DispatchJob
 * @param {Boolean} isInvoiceOutdated - Set the isInvoiceOutdated attribute to this value
 *
 * @return {DispatchJob} The selected DispatchJob
 */
async function updateInvoiceOutdated(dispatchJob, isInvoiceOutdated) {
  if (!dispatchJob) throw new Error('Missing Argument: Must provide DispatchJob');

  const updatedDispatchJob = await updateDispatchJob(dispatchJob, undefined, { isInvoiceOutdated }, true);
  return updatedDispatchJob;
}

export {
  updateDispatchJob,
  addDispatchJob,
  cloneDispatchJob,
  generateBatchId,
  getDispatchJobs,
  updateInvoiceOutdated,
};
