import React, { useState, useEffect } from 'react';

// api
import { addRecord, getAttribute, getCurrentUser, createGeoPoint, updateRecord, getCurrentUserSessionToken, getCurrentUserCompanyObjectId } from 'sb-csapi/dist/AAPI';
import { addDispatchOrganization, updateDispatchOrganization, getDispatchOrganizations, generateDispatchOrganizationCode } from 'api/Dispatch/DispatchOrganization';

// enums
import { Organization, OrganizationName } from 'sb-csapi/dist/enums/Dispatch/Organization';

// sbcore
import Dialog from 'sbCore/Dialog/Dialog';
import InputText from 'sbCore/InputText/InputText';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import StateProvinceDropdown from 'sbCore/StateProvinceDropdown/StateProvinceDropdown';
import CountryDropdown from 'sbCore/CountryDropdown/CountryDropdown';
import InputMask from 'sbCore/InputMask/InputMask';
import Checkbox from 'sbCore/Checkbox/Checkbox';
import InputTextarea from 'sbCore/InputTextarea/InputTextarea';
import Button from 'sbCore/Button/Button';
import Divider from 'sbCore/Divider/Divider';

// components
import LocationAutocomplete from 'components/Shared/LocationAutocomplete/LocationAutocomplete';

/**
 * @description Add / Edit DispatchOrganization
 * @param {DispatchOrganization} dispatchOrganization - The DispatchOrganization we want to edit. If none passed, the form is treated as an "Add" instead of "Edit"
 * @param {Int} type - Required. The type of organization. sappy enums/Dispatch/Organization
 * @param {bool} visible - If the form is visible
 * @param {Function} onCancel - Required. Callback for the cancel button
 * @param {Function} onSave - Callback when the organization is saved. Passes back the saved organization
 * @returns
 */
export default function DispatchOrganizationEditDialog({ ...props }) {
  const [dispatchOrganization, setDispatchOrganization] = useState(null);
  const [dispatchOrganizationAddress, setDispatchOrganizationAddress] = useState(null);
  const [dispatchOrganizationObj, setDispatchOrganizationObj] = useState({}); // holds attributes of dispatchOrganization for the state
  const [dispatchOrganizationAddressObj, setDispatchOrganizationAddressObj] = useState({ geoPoint: createGeoPoint(0, 0) }); // holds attributes of dispatchOrganization.address for the state
  const [saveTrigger, setSaveTrigger] = useState(undefined); // trigger to save updates
  const [isLoading, setIsLoading] = useState(false);

  const [showAddressDetails, setShowAddressDetails] = useState(false);
  const [dispatchOrganizationIds, setDispatchOrganizationIds] = useState([]);
  const [isOrganizationIdError, setIsOrganizationIdError] = useState(false);

  // Retrieve all dispatch organization ids associated with the company
  useEffect(() => {
    let didCancel = false;

    async function getDispatchOrganizationIds() {
      const { dispatchOrganizations } = await getDispatchOrganizations(
        undefined, // options - default
        undefined, // companyobjectid - default
        false, // include child company results
        undefined,
        undefined, // sort - default
        undefined, // includes
        ['organizationId'], // selects
        true, // query all
      );

      const _dispatchOrganizationIds = dispatchOrganizations.map((_dispatchOrganization) => {
        const dispatchOrganizationId = getAttribute(_dispatchOrganization, 'organizationId');
        return dispatchOrganizationId;
      });

      if (!didCancel) {
        setDispatchOrganizationIds(_dispatchOrganizationIds);
      }
    }

    getDispatchOrganizationIds();

    return () => { didCancel = true; };
  }, []);

  useEffect(() => {
    const dispatchOrganization = props.dispatchOrganization;
    setDispatchOrganization(dispatchOrganization);

    let address = getAttribute(props.dispatchOrganization, 'address', true);
    if (address) setShowAddressDetails(true);
    setDispatchOrganizationAddress(address);

    setDispatchOrganizationObj({
      organizationName: getAttribute(props.dispatchOrganization, 'organizationName', true),
      organizationId: getAttribute(props.dispatchOrganization, 'organizationId', true),
      defaultRole: getAttribute(props.dispatchOrganization, 'defaultRole', true) || props.type,
      isCustomer: getAttribute(props.dispatchOrganization, 'isCustomer', true),
      isCarrier: getAttribute(props.dispatchOrganization, 'isCarrier', true),
      isConsignee: getAttribute(props.dispatchOrganization, 'isConsignee', true) || (props.type === Organization.CUSTOMER && !props.dispatchOrganization),
      isShipper: getAttribute(props.dispatchOrganization, 'isShipper', true) || (props.type === Organization.CUSTOMER && !props.dispatchOrganization),
      isSales: getAttribute(props.dispatchOrganization, 'isSales', true),
      isPurchasing: getAttribute(props.dispatchOrganization, 'isPurchasing', true),
      isAdmin: getAttribute(props.dispatchOrganization, 'isAdmin', true),
      isManager: getAttribute(props.dispatchOrganization, 'isManager', true),
      isSafetySuper: getAttribute(props.dispatchOrganization, 'isSafetySuper', true),
      openHours: getAttribute(props.dispatchOrganization, 'openHours', true) || '00:00',
      closeHours: getAttribute(props.dispatchOrganization, 'closeHours', true) || '23:59',
      appointmentRequired: getAttribute(props.dispatchOrganization, 'appointmentRequired', true),
      profileNotes: getAttribute(props.dispatchOrganization, 'profileNotes', true),
      belongsToCompany: getAttribute(props.dispatchOrganization, 'belongsToCompany', true) || getAttribute(getCurrentUser(), 'belongsToCompany'),
    });

    setDispatchOrganizationAddressObj({
      address: getAttribute(address, 'address', true),
      city: getAttribute(address, 'city', true),
      stateProvince: getAttribute(address, 'stateProvince', true),
      zipPostal: getAttribute(address, 'zipPostal', true),
      country: getAttribute(address, 'country', true),
      geoPoint: getAttribute(address, 'geoPoint', true) || createGeoPoint(0, 0),
      belongsToCompany: getAttribute(address, 'belongsToCompany', true) || getAttribute(getCurrentUser(), 'belongsToCompany'),
    });

    setSaveTrigger(undefined) // reset the trigger

  }, [props.dispatchOrganization, props.visible]); // props.visible is here so it resets the form to initial state when "closed"

  useEffect(() => {
    if (saveTrigger === undefined) return;
    let didCancel = false;

    async function _updateDispatchOrganization() {
      setIsLoading(true);
      // first save the address
      let updatedAddress;
      let updatedDispatchOrganization;

      if (dispatchOrganizationAddress) {
        updatedAddress = await updateRecord({ sessionToken: getCurrentUserSessionToken() }, dispatchOrganizationAddress, dispatchOrganizationAddressObj, true);
      } else {
        updatedAddress = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'Address', dispatchOrganizationAddressObj, getCurrentUserCompanyObjectId());
      }

      if (dispatchOrganization) {
        updatedDispatchOrganization = await updateDispatchOrganization(dispatchOrganization, undefined, { ...dispatchOrganizationObj, address: updatedAddress }, true);
      } else {
        const _dispatchOrganizationObj = {
          ...dispatchOrganizationObj,
          defaultRole: props.type,
          address: updatedAddress
        };

        switch (props.type) {
          case Organization.CUSTOMER:
            _dispatchOrganizationObj.isCustomer = true;
            break;
          case Organization.CARRIER:
            _dispatchOrganizationObj.isCarrier = true;
            break;
          case Organization.CONSIGNEE:
            _dispatchOrganizationObj.isConsignee = true;
            break;
          case Organization.SHIPPER:
            _dispatchOrganizationObj.isShipper = true;
            break;
          case Organization.SALES:
            _dispatchOrganizationObj.isSales = true;
            break;
          case Organization.PURCHASING:
            _dispatchOrganizationObj.isPurchasing = true;
            break;
          case Organization.ADMIN:
            _dispatchOrganizationObj.isAdmin = true;
            break;
          case Organization.MANAGER:
            _dispatchOrganizationObj.isManager = true;
            break;
          case Organization.SAFETY_SUPER:
            _dispatchOrganizationObj.isSafetySuper = true;
            break;
        }

        updatedDispatchOrganization = await addDispatchOrganization(_dispatchOrganizationObj);
      }

      if (!didCancel) {
        setIsLoading(false);
        if (props.onSave) {
          props.onSave(updatedDispatchOrganization);
        }
      }
    }

    _updateDispatchOrganization();

    return () => { didCancel = true; };
  }, [saveTrigger]);

  // ** Components ** //

  // function to handle any cleaning / validating of non-address inputs
  function handleInput(attribute, value) {
    const _dispatchOrganizationObj = { ...dispatchOrganizationObj };

    if ((attribute === 'openHours') || (attribute === 'closeHours')) {
      // if invalid time was given, set it back to the way it was before
      if (!value || (value.trim().length < 1)) {
        if (attribute === 'closeHours') {
          _dispatchOrganizationObj[attribute] = '23:59'; // for if the input is cleared by user/component
        } else {
          _dispatchOrganizationObj[attribute] = '00:00'; // for if the input is cleared by user/component
        }
      } else {
        const hoursSplit = value.split(':');
        const hours = parseInt(hoursSplit[0]);
        const minutes = parseInt(hoursSplit[1]);
        if ((hours < 0) || (hours > 23)) {
          _dispatchOrganizationObj[attribute] = `00:${hoursSplit[1]}`;
        } else if ((minutes < 0) || (minutes > 59)) {
          _dispatchOrganizationObj[attribute] = `${hoursSplit[0]}:00`;
        } else {
          _dispatchOrganizationObj[attribute] = value;
        }
      }
    } else if (attribute === 'isShipperConsignee') {
      // Update the isShipper and isConsignee attributes with the same value
      _dispatchOrganizationObj.isShipper = value;
      _dispatchOrganizationObj.isConsignee = value;
    } else if (attribute === 'organizationId') {
      if (isOrganizationIdError) setIsOrganizationIdError(false);

      const currentOrganizationId = getAttribute(dispatchOrganization, 'organizationId', true);
      const isOrganizationIdUsed = dispatchOrganizationIds.includes(value);
      if (isOrganizationIdUsed && (value !== '') && (value !== currentOrganizationId)) {
        setIsOrganizationIdError(true);
      }
      _dispatchOrganizationObj[attribute] = value;
    } else {
      _dispatchOrganizationObj[attribute] = value;
    }

    setDispatchOrganizationObj(_dispatchOrganizationObj);
  }

  function handleInputBlur(attribute, value) {
    if (attribute === 'organizationName') {
      // Logic to generate organization code if there is no organization code already
      if (dispatchOrganizationObj['organizationId'] && !isOrganizationIdError) return;

      const organizationId = generateDispatchOrganizationCode(value);
      handleInput('organizationId', organizationId);
    }
  }

  // function to handle any cleaning / validating of address inputs
  function handleAddressInput(attribute, value) {
    const _dispatchOrganizationAddressObj = { ...dispatchOrganizationAddressObj };

    // note: valid range of latitude in degrees is -90 and +90 for the southern and northern hemisphere, respectively. Longitude is in the range -180 and +180
    // the inputs will ignore invalid coordinates
    if (attribute === 'latitude') {
      const _value = parseFloat(value || 0);
      _dispatchOrganizationAddressObj.geoPoint = createGeoPoint(_value, _dispatchOrganizationAddressObj.geoPoint._longitude);
    } else if (attribute === 'longitude') {
      const _value = parseFloat(value || 0);
      _dispatchOrganizationAddressObj.geoPoint = createGeoPoint(_dispatchOrganizationAddressObj.geoPoint._latitude, _value);
    } else {
      _dispatchOrganizationAddressObj[attribute] = value;
    }

    setDispatchOrganizationAddressObj(_dispatchOrganizationAddressObj);
  }

  // from the location autocomplete. parse the address it gives us and update state
  function handleSearchAddress(addressSbObj) {
    if (!addressSbObj) {
      // Handling cases where there is no search address autofill selected when the user presses enter
      setDispatchOrganizationAddressObj({ geoPoint: createGeoPoint(0, 0) });
      setShowAddressDetails(true);
      return;
    }

    const dispatchOrganizationAddressObj = {
      address: addressSbObj.address,
      city: addressSbObj.city,
      stateProvince: addressSbObj.stateProvince,
      zipPostal: addressSbObj.zipPostal,
      country: addressSbObj.country,
      geoPoint: createGeoPoint(addressSbObj.latitude, addressSbObj.longitude),
    };

    setShowAddressDetails(true);
    setDispatchOrganizationAddressObj(dispatchOrganizationAddressObj);
  }

  function onCancel() {
    if (isOrganizationIdError) setIsOrganizationIdError(false);
    setShowAddressDetails(false);
    if (props.onCancel) props.onCancel();
  }

  // inputs in order
  const organizationNameInput = (
    <React.Fragment>
      <InputLabel>Organization Name</InputLabel>
      <InputText
        type="text"
        placeholder="Enter Organization Name"
        className={`p-inputtext-sm w-full`}
        value={dispatchOrganizationObj.organizationName}
        onChange={(e) => handleInput('organizationName', e.target.value)}
        onBlur={(e) => handleInputBlur('organizationName', e.target.value)}
        error={!dispatchOrganizationObj.organizationName}
        autoFocus
      />
    </React.Fragment>
  );

  const organizationIdInput = (
    <React.Fragment>
      <InputLabel>Organization Code (Short Code)</InputLabel>
      <InputText type="text"
        tooltip="Used to generate job ids"
        tooltipOptions={{ className: "text-xs", position: "bottom" }}
        placeholder="Enter Organization Code"
        className={`p-inputtext-sm w-full`}
        value={dispatchOrganizationObj.organizationId}
        onChange={(e) => handleInput('organizationId', e.target.value.toUpperCase())}
        error={!dispatchOrganizationObj.organizationId || isOrganizationIdError}
      />
      {isOrganizationIdError && (
        <InputLabel className="font-normal mt-2" error>{`Code already in use. You may already have an organization under this name. Change the organization name or code.`}</InputLabel>
      )}
    </React.Fragment>
  );

  const searchAddressInput = (
    <React.Fragment>
      <InputLabel>Search Address</InputLabel>
      <LocationAutocomplete
        usePRStyle
        containerClass="search-address-autocomplete"
        inputClassName="p-inputtext-sm w-full"
        onLocationSelected={(addressSbObj) => handleSearchAddress(addressSbObj)}
      />
    </React.Fragment>
  );

  const addressInput = (
    <React.Fragment>
      <InputLabel>Address / Street</InputLabel>
      <InputText type="text"
        className={`p-inputtext-sm w-full`}
        value={dispatchOrganizationAddressObj.address}
        onChange={(e) => handleAddressInput('address', e.target.value)}
      />
    </React.Fragment>
  );

  const cityInput = (
    <React.Fragment>
      <InputLabel>City</InputLabel>
      <InputText type="text"
        className={`p-inputtext-sm w-full`}
        value={dispatchOrganizationAddressObj.city}
        onChange={(e) => handleAddressInput('city', e.target.value)}
      />
    </React.Fragment>
  );

  const stateProvinceDropdown = (
    <React.Fragment>
      <InputLabel>State / Province</InputLabel>
      <StateProvinceDropdown
        dropdownClassName="p-inputtext-sm w-full"
        stateProvince={dispatchOrganizationAddressObj.stateProvince}
        onSelect={(stateProvince) => handleAddressInput('stateProvince', stateProvince.code)}
        showOnFocus
        hideLabel
      />
    </React.Fragment>
  );

  const zipPostalInput = (
    <React.Fragment>
      <InputLabel>ZIP / Postal Code</InputLabel>
      <InputText type="text"
        placeholder=""
        className={`p-inputtext-sm w-full`}
        value={dispatchOrganizationAddressObj.zipPostal}
        onChange={(e) => handleAddressInput('zipPostal', e.target.value)}
      />
    </React.Fragment>
  );

  const countryDropdown = (
    <React.Fragment>
      <InputLabel>Country</InputLabel>
      <CountryDropdown
        dropdownClassName="p-inputtext-sm w-full"
        country={dispatchOrganizationAddressObj.country}
        onSelect={(country) => handleAddressInput('country', country.code)}
        showOnFocus
        hideLabel
      />
    </React.Fragment>
  );

  const coordinatesInputs = (
    <React.Fragment>
      <InputLabel>Latitude / Longitude</InputLabel>
      <div className="coordinates-inputs w-full flex align-items-center">
        <span className="text-black-lighten" style={{ fontSize: '1.2em' }}>(</span>
        <InputText
          type="number"
          placeholder="0"
          className={`p-inputtext-sm w-full`}
          value={dispatchOrganizationAddressObj.geoPoint._latitude}
          onChange={(e) => handleAddressInput('latitude', e.target.value)}
        />
        <span className="text-black-lighten" style={{ fontSize: '1.2em' }}>, </span>
        <InputText
          type="number"
          placeholder="0"
          className={`p-inputtext-sm w-full`}
          value={dispatchOrganizationAddressObj.geoPoint._longitude}
          onChange={(e) => handleAddressInput('longitude', e.target.value)}
        />
        <span className="text-black-lighten" style={{ fontSize: '1.2em' }}>)</span>
      </div>
    </React.Fragment>
  );

  const openCloseHoursInputs = (
    <React.Fragment>
      <InputLabel>Business Hours (24-Hour Format)</InputLabel>
      <div className="flex align-items-center w-full open-close-hours-inputs">
        <InputMask
          containerClassName=""
          className={`p-inputtext-sm w-10rem`}
          slotChar="HH:mm"
          mask="99:99"
          value={dispatchOrganizationObj.openHours}
          onChange={(e) => handleInput('openHours', e.target.value)}
        />
        <span className="text-black-lighten px-2" style={{ fontSize: '1.2em' }}> - </span>
        <InputMask
          containerClassName="w-full"
          className={`p-inputtext-sm w-10rem`}
          slotChar="HH:mm"
          mask="99:99"
          value={dispatchOrganizationObj.closeHours}
          onChange={(e) => handleInput('closeHours', e.target.value)}
        />
      </div>
    </React.Fragment>
  );

  const appointmentRequiredCheckbox = (
    <div style={{ paddingTop: '1.5em' }}>
      <Checkbox className="inline-block" inputId="organizationAppointment" name="appointment" value={dispatchOrganizationObj.appointmentRequired} onChange={() => handleInput('appointmentRequired', !dispatchOrganizationObj.appointmentRequired)} checked={dispatchOrganizationObj.appointmentRequired} />
      <label htmlFor="organizationAppointment" className="sb-font-secondary text-black-lighten" style={{ fontSize: '.75em' }}>Appointment Required</label>
    </div>
  );

  const customerRoleCheckbox = (
    <React.Fragment>
      <Checkbox className="inline-block" inputId="isCustomer" name="customer" value={dispatchOrganizationObj.isCustomer} onChange={() => handleInput('isCustomer', !dispatchOrganizationObj.isCustomer)} checked={dispatchOrganizationObj.isCustomer} />
      <label htmlFor="isCustomer" className="sb-font-secondary text-black-lighten" style={{ fontSize: '.75em' }}>Customer</label>
    </React.Fragment>
  );

  const carrierRoleCheckbox = (
    <React.Fragment>
      <Checkbox className="inline-block" inputId="isCarrier" name="carrier" value={dispatchOrganizationObj.isCarrier} onChange={() => handleInput('isCarrier', !dispatchOrganizationObj.isCarrier)} checked={dispatchOrganizationObj.isCarrier} />
      <label htmlFor="isCarrier" className="sb-font-secondary text-black-lighten" style={{ fontSize: '.75em' }}>Carrier</label>
    </React.Fragment>
  );

  const shipperConsigneeRoleCheckbox = (
    <React.Fragment>
      <Checkbox className="inline-block" inputId="isShipperConsignee" name="shipperConsignee" value={dispatchOrganizationObj.isShipper} onChange={() => handleInput('isShipperConsignee', !dispatchOrganizationObj.isShipper)} checked={dispatchOrganizationObj.isShipper && dispatchOrganizationObj.isConsignee} />
      <label htmlFor="isShipperConsignee" className="sb-font-secondary text-black-lighten" style={{ fontSize: '.75em' }}>Shipper/Consignee</label>
    </React.Fragment>
  );

  const salesRoleCheckbox = (
    <React.Fragment>
      <Checkbox className="inline-block" inputId="isSales" name="sales" value={dispatchOrganizationObj.isSales} onChange={() => handleInput('isSales', !dispatchOrganizationObj.isSales)} checked={dispatchOrganizationObj.isSales} />
      <label htmlFor="isSales" className="sb-font-secondary text-black-lighten" style={{ fontSize: '.75em' }}>Sales</label>
    </React.Fragment>
  );

  const purchasingRoleCheckbox = (
    <React.Fragment>
      <Checkbox className="inline-block" inputId="isPurchasing" name="purchasing" value={dispatchOrganizationObj.isPurchasing} onChange={() => handleInput('isPurchasing', !dispatchOrganizationObj.isPurchasing)} checked={dispatchOrganizationObj.isPurchasing} />
      <label htmlFor="isPurchasing" className="sb-font-secondary text-black-lighten" style={{ fontSize: '.75em' }}>Purchasing</label>
    </React.Fragment>
  );

  const adminRoleCheckbox = (
    <React.Fragment>
      <Checkbox className="inline-block" inputId="isAdmin" name="admin" value={dispatchOrganizationObj.isAdmin} onChange={() => handleInput('isAdmin', !dispatchOrganizationObj.isAdmin)} checked={dispatchOrganizationObj.isAdmin} />
      <label htmlFor="isAdmin" className="sb-font-secondary text-black-lighten" style={{ fontSize: '.75em' }}>Admin</label>
    </React.Fragment>
  );

  const managerRoleCheckbox = (
    <React.Fragment>
      <Checkbox className="inline-block" inputId="isManager" name="manager" value={dispatchOrganizationObj.isManager} onChange={() => handleInput('isManager', !dispatchOrganizationObj.isManager)} checked={dispatchOrganizationObj.isManager} />
      <label htmlFor="isManager" className="sb-font-secondary text-black-lighten" style={{ fontSize: '.75em' }}>Manager</label>
    </React.Fragment>
  );

  const safetySuperRoleCheckbox = (
    <React.Fragment>
      <Checkbox className="inline-block" inputId="isSafetySuper" name="safetySuper" value={dispatchOrganizationObj.isSafetySuper} onChange={() => handleInput('isSafetySuper', !dispatchOrganizationObj.isSafetySuper)} checked={dispatchOrganizationObj.isSafetySuper} />
      <label htmlFor="isSafetySuper" className="sb-font-secondary text-black-lighten" style={{ fontSize: '.75em' }}>Safety/Super</label>
    </React.Fragment>
  );

  // Type that combines the shipper and consignee types together
  const shipperConsigneeType = `${Organization.SHIPPER}, ${Organization.CONSIGNEE}`;

  // goal is to get rid of checkbox for this role, since we just want checkboxes for other roles. a mapping of organization type to checkbox is created to easily eliminate the target checkbox
  // the name key is used only to sort the checkboxes alphabetically by the role name
  const organizationCheckboxMap = {
    [Organization.ADMIN]: {
      checkbox: adminRoleCheckbox,
      name: OrganizationName.ADMIN,
    },
    [Organization.CUSTOMER]: {
      checkbox: customerRoleCheckbox,
      name: OrganizationName.CUSTOMER,
    },
    [Organization.CARRIER]: {
      checkbox: carrierRoleCheckbox,
      name: OrganizationName.CARRIER,
    },
    [Organization.MANAGER]: {
      checkbox: managerRoleCheckbox,
      name: OrganizationName.MANAGER,
    },
    [Organization.PURCHASING]: {
      checkbox: purchasingRoleCheckbox,
      name: OrganizationName.PURCHASING,
    },
    [Organization.SAFETY_SUPER]: {
      checkbox: safetySuperRoleCheckbox,
      name: OrganizationName.SAFETY_SUPER,
    },
    [Organization.SALES]: {
      checkbox: salesRoleCheckbox,
      name: OrganizationName.SALES,
    },
    [shipperConsigneeType]: {
      checkbox: shipperConsigneeRoleCheckbox,
      name: 'Shipper/Consignee',
    },
  };

  // If the default role is a shipper/consignee, remove the shipper/consignee checkbox
  if (dispatchOrganizationObj.defaultRole === Organization.CONSIGNEE || dispatchOrganizationObj.defaultRole === Organization.SHIPPER) {
    delete organizationCheckboxMap[shipperConsigneeType];
  }

  delete organizationCheckboxMap[dispatchOrganizationObj.defaultRole];

  // now create an array of these checkboxes, sorted alphabetically by the role name
  const organizationCheckboxMapKeysSorted = Object.keys(organizationCheckboxMap)
    .sort((a, b) => {
      const nameA = organizationCheckboxMap[a].name;
      const nameB = organizationCheckboxMap[b].name;
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

  const roleCheckboxes = organizationCheckboxMapKeysSorted
    .map((type, index) => {
      let _checkbox = organizationCheckboxMap[type].checkbox;

      if (index === 0) {
        _checkbox = (
          <React.Fragment>
            <InputLabel className="pb-2">Select other applicable roles</InputLabel>
            {_checkbox}
          </React.Fragment>
        );

        return _checkbox;
      }

      return (
        <div style={{ paddingTop: '0.5em' }}>{_checkbox}</div>
      );
    });

  const profileNotesTextarea = (
    <React.Fragment>
      <InputLabel>Notes</InputLabel>
      <InputTextarea value={dispatchOrganizationObj.profileNotes} onChange={(e) => handleInput('profileNotes', e.target.value)} rows={5} cols={30} autoResize />
    </React.Fragment>
  );

  const renderFooter = (name) => {
    return (
      <div className="text-right">
        {!isLoading && (
          <div className="inline-block flex-1">
            <Button className="p-button-sm p-button-default" label="Cancel" onClick={() => onCancel()} disabled={isLoading} />
          </div>
        )}
        <div className="inline-block">
          <Button
            className="p-button-sm p-button-info"
            label={!isLoading ? 'Save' : 'Saving...'}
            onClick={() => setSaveTrigger((!saveTrigger) ? 1 : 0)}
            disabled={!dispatchOrganizationObj.organizationName || !dispatchOrganizationObj.organizationId || isOrganizationIdError || isLoading}
          />
        </div>
      </div>
    );
  }


  // ** Misc ** //
  const name = getAttribute(dispatchOrganization, 'organizationName', true);

  let className = 'dispatch-organization-edit-dialog';
  if (props.className) className += ` ${props.className}`;

  // apologies for all the if/else. there used to be just a couple of them, had to add more in a rush
  let label = 'Organization';
  if (props.type === Organization.CARRIER) {
    label = `${OrganizationName.CARRIER}`;
  } else if (props.type === Organization.CUSTOMER) {
    label = `${OrganizationName.CUSTOMER}`;
  } else if (props.type === Organization.SHIPPER) {
    label = `${OrganizationName.SHIPPER}`;
  } else if (props.type === Organization.CONSIGNEE) {
    label = `${OrganizationName.CONSIGNEE}`;
  } else if (props.type === Organization.SALES) {
    label = `${OrganizationName.SALES}`;
  } else if (props.type === Organization.PURCHASING) {
    label = `${OrganizationName.PURCHASING}`;
  } else if (props.type === Organization.ADMIN) {
    label = `${OrganizationName.ADMIN}`;
  } else if (props.type === Organization.MANAGER) {
    label = `${OrganizationName.MANAGER}`;
  } else if (props.type === Organization.SAFETY_SUPER) {
    label = `${OrganizationName.SAFETY_SUPER}`;
  }

  return (
    <Dialog
      className={className}
      visible={props.visible}
      header={dispatchOrganization ? <div>{name}<span className="text-gray-calm" style={{ fontSize: '.7em', textTransform: 'uppercase' }}>&nbsp;&nbsp;({label})</span></div> : `Add a new ${label}`}
      footer={renderFooter()} onHide={() => onCancel()}
      closable={false}
      style={{ width: '70vw' }}
    >
      <div className="flex flex-column lg:flex-row w-full mb-5 lg:mb-2">
        <div className="flex-1 lg:pr-3 mb-2 lg:mb-0">
          {organizationNameInput}
        </div>
        <div className="mb-2 lg:mb-0 lg:w-20rem">
          {organizationIdInput}
        </div>
      </div>

      <div className="mb-2 lg:mb-2">
        {searchAddressInput}
      </div>

      <div className={`${!showAddressDetails ? 'hidden' : 'mb-5'}`}>
        <div className="flex flex-column lg:flex-row lg:mb-3">
          <div className="lg:pr-3 mb-2 lg:mb-0 w-full">
            {addressInput}
          </div>
          <div className="lg:pr-3 mb-2 lg:mb-0 w-full">
            {cityInput}
          </div>
          <div className="mb-2 lg:mb-0 lg:w-20rem">
            {stateProvinceDropdown}
          </div>
        </div>
        <div className="flex flex-column lg:flex-row">
          <div className="lg:pr-3 mb-2 lg:mb-0 lg:w-full">
            {zipPostalInput}
          </div>
          <div className="lg:pr-3 mb-2 lg:mb-0 lg:w-20rem">
            {countryDropdown}
          </div>
          <div className="mb-2 lg:mb-0 lg:w-full">
            {coordinatesInputs}
          </div>
        </div>
      </div>

      <Divider className="mb-5" />
      <div className="flex flex-column lg:flex-row w-full mb-5 lg:mb-5">
        <div className="lg:pr-6 flex flex-column w-full mb-5 lg:mb-0">
          {openCloseHoursInputs}
          {appointmentRequiredCheckbox}
        </div>
        <Divider layout="vertical" className="hidden lg:block" />
        <div className="flex flex-row lg:flex-column w-full lg:w-30rem lg:pl-3">
          {roleCheckboxes.splice(0, (roleCheckboxes.length / 2) + 1).map((checkbox, index) => {
            return (
              <div className="w-full" key={index}>
                {checkbox}
              </div>
            );
          })}
        </div>
        <div className="flex flex-row lg:flex-column w-full lg:w-30rem lg:pl-3" style={{ paddingTop: '1.3rem' }}>
          {roleCheckboxes.map((checkbox, index) => {
            return (
              <div className="w-full" key={index}>
                {checkbox}
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex">
        <div className="w-full profile-notes">
          {profileNotesTextarea}
        </div>
      </div>
    </Dialog>
  )
}
