import React from 'react';
import PropTypes from 'prop-types';
import styles from './StatusIcon.module.scss';

class StatusIcon extends React.Component {

  shouldComponentUpdate(nextProps) {
    if (nextProps.active !== this.props.active) {
      return true;
    }
    if (nextProps.size !== this.props.size) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <span className={styles.statusIcon}>
        {this.props.active === undefined &&
          <span className={`${styles.defunct} ${styles[this.props.size] || styles.sm}`}>&#8226;</span>
        }
        {this.props.active === true &&
          <span className={`${styles.active} ${styles[this.props.size] || styles.sm}`}>&#8226;</span>
        }
        {this.props.active === false &&
          <span className={`${styles.inactive} ${styles[this.props.size] || styles.sm}`}>&#8226;</span>
        }
      </span>
    );
  }
}

StatusIcon.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.string,
};

export default StatusIcon;
