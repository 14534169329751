/* global Twilio */
import Parse from 'parse';
import * as ParseAPI from './Parse';

/** @module TripInspection */

/**
 * @memberof module:TripInspection
 * 
 * @param {*} tripInspection 
 * @param {*} propertyValueObject 
 * 
 * @returns 
 */
function editTripInspection(tripInspection, propertyValueObject) {
  const promise = new Promise((resolve, reject) => {
    const keys = Object.keys(propertyValueObject);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      tripInspection.set(key, propertyValueObject[key]);
    }

    tripInspection.save().then(
      savedTripInspection => {
        resolve(savedTripInspection);
        ParseAPI.sendPush(
          [tripInspection.get('driver').get('user')],
          { type: 8, objectId: tripInspection.id }
        );
      },
      error => reject(error)
    );
  });
  return promise;
}

export {
  editTripInspection,
};
