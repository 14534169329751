import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

// Components
import SimpleTableBody from 'components/SimpleTableBody/container/SimpleTableBody';

// CSS
import styles from './ChooseList.module.scss';

function VendorList(props) {
  const vendorRows = props.vendors.map((vendor) => (
    <tr key={vendor.id} className={styles.vendorRow} onClick={() => props.handleModalChoice(vendor)} >
      <td>{vendor.get('name')}</td>
      <td>{`${vendor.get('address').get('address')}, ${vendor.get('address').get('city') || ''}, ${vendor.get('address').get('stateProvince') || ''}, ${vendor.get('address').get('country')}`}</td>
    </tr>
  ));
  return (
    <div className="defaultTable" className={styles.rowsPointer} onScroll={(e) => this.scrollHandler(e)}>
      <Table responsive condensed hover>
        <thead>
          <tr>
            <th>
              Name
            </th>
            <th>
              Address
            </th>
          </tr>
        </thead>
        <SimpleTableBody rows={vendorRows} loaded={props.loaded} columns="2" />
      </Table>
    </div>
  );
}

VendorList.propTypes = {
  vendors: PropTypes.array.isRequired,
  handleModalChoice: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  loaded: PropTypes.bool.isRequired,
};

export default VendorList;
