import React from 'react';
import PropTypes from 'prop-types';

// Actions

// Components

// CSS
import styles from './OwnerOperatorDownloadScreen.module.scss';

// Assets
const googlePlay = require('assets/images/googleplay.png');
const playStore = require('assets/images/marketing/play-store.png');

function OwnerOperatorDownloadScreen(props) {
  return (
    <div className={styles.container}>

      <div className={styles.box}>
        <div className={styles.message}>
          <div className={styles.back} onClick={props.handleBack}>
            <i className="material-icons">chevron_left</i>
            <div>Back</div>
          </div>
          You're all set!
          <br />
          <span>Click below to download the app and get started.</span>
        </div>

        <a href="https://play.google.com/store/apps/details?id=com.onswitchboard.eld" target="_blank">
          <div className={styles.intro}>
          {/* <img className={styles.background} src={playStore} /> */}
            <img alt="Get it on Google Play" src={googlePlay} />
          </div>
        </a>
      </div>

    </div>
  );
}

OwnerOperatorDownloadScreen.propTypes = {
  handleBack: PropTypes.func.isRequired,
};

export default OwnerOperatorDownloadScreen;
