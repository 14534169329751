import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// API
import { getRecentCarrierProfile, uploadCarrierProfile } from 'api/CarrierProfile';

// Components
import Title from 'components/LayoutTitle/view/Title';
import CarrierPointsGraph from 'components/CarrierSafetySummary/container/CarrierPointsGraph';
import CarrierSafetyAlertsTable from 'components/CarrierSafetySummary/container/CarrierSafetyAlertsTable';
import UploadFileButton from 'components/UploadFileButton/view/UploadFileButton';

// Lists
import { StateProvinces } from 'api/Lists/StateProvinces';

// CSS
import styles from './SummaryLayout.module.scss';

class SummaryLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carrierProfileObject: undefined,
      parseFile: undefined,
      summary: {},
    };
    this.state.summary = {
      jurisdiction: undefined,
      createdAt: undefined,
      carrierScore: undefined,
      certificateStatus: undefined,
      safetyRating: undefined,
      profileStatus: undefined,
      auditStatus: undefined,
      complianceReview: undefined,
    };
    this.stateConstructor = { ...this.state, summary: Object.assign({}, this.state.summary) }; // create a fresh, default backup
    this.setCarrierSummary = this.setCarrierSummary.bind(this); // fn to get the basics from reading carrier profile
    this.upload = this.upload.bind(this);
  }

  componentDidMount() {
    // obtain the latest carrier profile if applicable
    getRecentCarrierProfile().then(carrierProfileObject => {
      if (carrierProfileObject) {
        this.setCarrierSummary(carrierProfileObject);
      }
    });
  }

  setCarrierSummary(carrierProfileObject) {
    // match carrier profile object to state
    const newState = { ...this.stateConstructor };
    const summaryKeys = Object.keys(newState.summary);

    for (let i = 0; i < summaryKeys.length; i++) {
      const key = summaryKeys[i];
      newState.summary[key] = carrierProfileObject.get(key);
    }

    newState.carrierProfileObject = carrierProfileObject;
    newState.parseFile = carrierProfileObject.get('file');
    this.setState(newState);
  }

  upload(file) {
    // upload file to carrier profile
    uploadCarrierProfile(file).then(carrierProfileObject => {
      // match our state summary keys to that of the profile summary
      this.setCarrierSummary(carrierProfileObject);
    });
  }

  render() {
    const {
      jurisdiction,
      createdAt,
      carrierScore,
      certificateStatus,
      safetyRating,
      profileStatus,
      auditStatus,
      complianceReview,
    } = this.state.summary;

    let jurisdictionLong = jurisdiction;
    if (jurisdiction) {
      jurisdictionLong = StateProvinces.filter(province => province.code.toUpperCase() === jurisdiction.toUpperCase());
      jurisdictionLong = jurisdictionLong[0].name;
    }

    let complianceReviewShort = 'N/A';
    if (complianceReview) {
      complianceReviewShort = moment(complianceReview).format('MMM D, YYYY');
    }

    return (
      <div className={styles.summaryLayout}>
        <Title title="Carrier NSC Information">
          <span className="inlineBlock">
            {jurisdiction &&
              <span className="toUpperCase textGrayCalm">{ jurisdictionLong } - <small>({`Profile Uploaded ${moment(createdAt).format('MMM D, YYYY')}`})</small>&nbsp;-&nbsp;</span>
            }
            <UploadFileButton className="inlineBlock" uploadHandler={this.upload}>Upload Profile</UploadFileButton>
          </span>
        </Title>

        <div>
          <table className={styles.outerTable}>
            <tbody className={styles.highLevelSummaryTable}>
              <tr>
                <td className={styles.carrierProfileSummary}>
                  <span>Certificate Information</span>
                  <table>
                    <tbody>
                      <tr>
                        <td>Certificate Status:</td><td>{ certificateStatus || 'N/A' }</td>
                      </tr>
                      <tr>
                        <td>Safety Rating:</td><td>{ safetyRating || 'N/A' }</td>
                      </tr>
                      <tr>
                        <td>Profile Status:</td><td>{ profileStatus || 'N/A' }</td>
                      </tr>
                      <tr>
                        <td>Audit Status:</td><td>{ auditStatus || 'N/A' }</td>
                      </tr>
                      <tr>
                        <td>Compliance Review:</td><td>{ complianceReviewShort }</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td className={styles.carrierProfileScore}>
                  <div>Current Carrier Score</div>
                  <div className={styles.scoreBubble}>
                    { carrierScore || 'N/A'}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <CarrierPointsGraph carrierProfileObject={this.state.carrierProfileObject} />
        <CarrierSafetyAlertsTable carrierProfileObject={this.state.carrierProfileObject} />
      </div>
    );
  }
}

SummaryLayout.propTypes = {

};

export default SummaryLayout;
