/* eslint-disable react/jsx-no-bind */
import uniqid from 'uniqid';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

// API
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getHosViolations } from 'api/ELD/HOSViolation/HOSViolation';
import { convertMillisecondsToHours, formatTimeNumberToHoursMinutes, toTitleCase } from 'api/Helpers';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import HOSRegulation from 'api/Lists/HOSRegulation';

// Components
import DutyStatusBadge from 'sbCore/DutyStatusBadge/DutyStatusBadge';
import { FontIcon } from 'material-ui';
import Card from 'sbCore/Card/Card';
import Button from 'sbCore/Button/Button';
import Column from 'sbCore/Column/Column';
import DataTable from 'sbCore/DataTable/DataTable';
import { ConfirmPopup, confirmPopupSettings } from 'sbCore/ConfirmPopup/ConfirmPopup';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import Chip from 'sbCore/Chip/Chip';

import styles from './DriverHoursLayout.module.scss';

/**
 * Holds the layout for displaying API credentials
 *
 * @returns {JSX}
 */
function WeeklyDriverViolationsCard() {
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedIntegration, setSelectedIntegration] = useState(null);
  // const [showRequestIntegrationModal, setShowRequestIntegrationModal] = useState(false);
  const [informationArr, setInformationArr] = useState([]);

  // Triggers a referesh of the API credentials
  const [refreshToken, setRefreshToken] = useState(uniqid());

  useEffect(() => {
    let didCancel = false;

    async function getHOSViolationsForState() {
      setIsLoading(true);
      const hosViolationsArr = (await getHosViolations(
        undefined,
        undefined,
        moment().subtract(14, 'days'),
      )).filter((hosViolationObj) => hosViolationObj.get('driver'));
      const driversAndHosViolationsObj = {};
      for (let i = 0; i < hosViolationsArr.length; i++) {
        const hosViolationObj = hosViolationsArr[i];
        const driverId = hosViolationObj?.get('driver').id
        if (!driversAndHosViolationsObj[driverId]) {
          driversAndHosViolationsObj[driverId] = {
            hosViolationLastSevenDaysArr: [],
            hosViolationSevenDaysBeforeArr: [],
            driverName: toTitleCase(hosViolationObj?.get('driver')?.get('user_fullName')),
            driverId,
            phoneNumber: hosViolationObj?.get('driver')?.get('user_phoneNumber'),
          };
        }

        const triggerTime = hosViolationObj.get('triggerTime');
        if (moment().subtract(7, 'days').isBefore(moment(triggerTime))) {
          driversAndHosViolationsObj[driverId].hosViolationLastSevenDaysArr.push(hosViolationObj);
        } else {
          driversAndHosViolationsObj[driverId].hosViolationSevenDaysBeforeArr.push(hosViolationObj);
        }
      }

      const _driverInformationArr = Object.values(driversAndHosViolationsObj);

      if (!didCancel) {
        setInformationArr(_driverInformationArr);
        setIsLoading(false);
      }
    }

    getHOSViolationsForState();
    return () => {
      didCancel = true;
    };
  }, [refreshToken]);

  function driverBodyTemplate(rowData) {
    return (
      <div>
        <a
          href={`/driver?driver=${rowData.driverId}&view=hosEvents`}
          target="_blank"
        >
          <b>{rowData.driverName}</b>
        </a>
        <br />
        <div className={styles.subtitle}>
          <FontIcon className={`${styles.subtitle} material-icons`}>phone</FontIcon>
          {rowData.phoneNumber}
        </div>
      </div>
    );
  }

  function driverInfoTemplate(rowData) {
    return (
      <DutyStatusBadge className="z-depth-0" eldStatusInt={rowData.eldStatusInt} />
    );
  }


  function violationsInfoTemplate(rowData) {
    return (
      <div>
        <a
          href={`/driver?driver=${rowData.driverId}&view=hosViolations`}
          target="_blank"
        >
          <b>{rowData.hosViolationLastSevenDaysArr.length} violation{rowData.hosViolationLastSevenDaysArr.length !== 1 && 's'} in the last week</b>
          &nbsp;
          &nbsp;
          &nbsp;
          <i>
            {(rowData.hosViolationSevenDaysBeforeArr.length !== 0) &&
              (
                <span>
                  {(rowData.hosViolationLastSevenDaysArr.length > rowData.hosViolationSevenDaysBeforeArr.length) &&
                    (
                      <span style={{ color: 'red' }}>
                        <FontIcon className={`${styles.subtitle} material-icons`}>north</FontIcon>
                        {Math.round((rowData.hosViolationLastSevenDaysArr.length - rowData.hosViolationSevenDaysBeforeArr.length) / (rowData.hosViolationSevenDaysBeforeArr.length) * 100)} %  (Change compared to week before)
                      </span>
                    )
                  }
                  {(rowData.hosViolationLastSevenDaysArr.length < rowData.hosViolationSevenDaysBeforeArr.length) &&
                    (
                      <span style={{ color: 'green' }}>
                        <FontIcon className={`${styles.subtitle} material-icons`}>south</FontIcon>
                        {Math.round((rowData.hosViolationLastSevenDaysArr.length - rowData.hosViolationSevenDaysBeforeArr.length) / (rowData.hosViolationSevenDaysBeforeArr.length) * 10)} %  (Change compared to week before)
                      </span>
                    )
                  }
                </span>
              )
            }
            {(rowData.hosViolationSevenDaysBeforeArr.length === 0) && (rowData.hosViolationLastSevenDaysArr.length > 0) &&
              (
                <span style={{ color: 'red' }}>
                  <FontIcon className={`${styles.subtitle} material-icons`}>north</FontIcon>
                  100 %  (Change compared to week before)
                </span>
              )
            }
          </i>
        </a>

        {/* <div>
          {rowData.violationDescription}
        </div>
        <span className={styles.subtitle}>
          @ {moment(rowData.dateTime).format('h:mm a')}
        </span> */}
      </div>
    );
  }


  const header = (
    <div className="flex flex-row justify-content-between align-items-end px-3 pt-4">
      <h6 className="font-bold mb-0">Driver Hours of Service Trends</h6>
    </div>
  );


  return (
    <Card
      header={header}
    >
      <DataTable
        value={informationArr}
        emptyMessage="No HOS violations found in the last 7 days. Great job!"
        loading={isLoading}
        rowHover
        paginator
        rows={5}
        size="small"
      >
        <Column
          field={driverBodyTemplate}
          header=""
        />
        {/* <Column
          field={driverInfoTemplate}
          header=""
        /> */}
        <Column
          field={violationsInfoTemplate}
          header=""
        />
      </DataTable>
    </Card>
  );
}

export default WeeklyDriverViolationsCard;
