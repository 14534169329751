import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// API
import { updateDispatchReceivable } from 'api/Dispatch/DispatchReceivable';

// CSAPI
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import ReceivablesDrawerHeader from 'components/Dispatch/AccountingReceivablesTable/ReceivablesDrawer/ReceivablesDrawerHeader';
import ReceivableInformation from 'components/Dispatch/AccountingReceivablesTable/ReceivablesDrawer/ReceivableInformation';
import DispatchDocumentHistoryTable from 'components/Dispatch/DispatchDocumentHistoryTable/DispatchDocumentHistoryTable';
import TransactionsContainer from 'components/Dispatch/TransactionsContainer/TransactionsContainer';

// Enums
import { Document as DocumentTypes } from 'sb-csapi/dist/enums/Dispatch/Document';

// sbCore Components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import InputTextarea from 'sbCore/InputTextarea/InputTextarea';
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import Sidebar from 'sbCore/Sidebar/Sidebar';
import ConfirmDialog from 'sbCore/ConfirmDialog/ConfirmDialog';

// Styles
import './style.scss';

/**
 * @description Shows a drawer that displays information
 * @param {DispatchReceivable} dispatchReceivable - The selected DispatchReceivable
 * @param {DispatchJob} dispatchJob - The DispatchJob linked to the DispatchReceivable
 * @param {Boolean} isDrawerOpen - Determines whether the drawer is visible or not
 * @param {Function} closeDrawer - Function that hides the drawer
 * @param {String} [position] - Position of the drawer
 *
 * @returns
 *
 * @example
 * <AccountingDrawer closeDrawer={closeDrawer()} isDrawerOpen={isDrawerOpen} dispatchReceivable={selectedDispatchReceivable} />
 */
function AccountingDrawer({ ...props }) {

  // ** useStates ** //
  const [dispatchJobAccounting, setDispatchJobAccounting] = useState(null);
  const [notes, setNotes] = useState('');
  const [paymentStatusInt, setPaymentStatusInt] = useState(null);
  const [isVoided, setIsVoided] = useState(false);
  const [showVoidConfirmationModal, setShowVoidConfirmationModal] = useState(false);

  const [invoicedDate, setInvoicedDate] = useState(null);
  const [isInvoicedDateInvalid, setIsInvoicedDateInvalid] = useState(false);

  const [refreshInt, setRefreshInt] = useState(0);

  // ** useEffects ** //

  // Retrieve the required information from the given DispatchJob
  useEffect(() => {
    if (!props.dispatchReceivable) return;
    const _notes = getAttribute(props.dispatchReceivable, 'notes');
    const _invoicedDate = getAttribute(props.dispatchReceivable, 'invoicedDateTime');
    const _paymentStatusInt = getAttribute(props.dispatchReceivable, 'status');
    const _dispatchJobAccounting = getAttribute(props.dispatchReceivable, 'dispatchJobAccounting');
    const _isVoided = getAttribute(props.dispatchReceivable, 'isVoided');

    setNotes(_notes);
    setInvoicedDate(_invoicedDate);
    setPaymentStatusInt(_paymentStatusInt);
    setDispatchJobAccounting(_dispatchJobAccounting);
    setIsVoided(_isVoided);
  }, [props.dispatchReceivable, refreshInt]);

  // ** Helper Functions ** //
  function handleNotesChange(notesValue) {
    if (!props.dispatchReceivable) return;
    updateDispatchReceivable(undefined, props.dispatchReceivable, undefined, { notes: notesValue }, true);
    setNotes(notesValue);
  }

  // Note: The string value that PrimeReact's calendar component passes back looks like this:
  // Ex: __-__-____, 14-10-____, etc.
  async function handleInvoicedDateChange(dateValue) {
    const isDateValid = moment(dateValue, 'DD-MM-YYYY', true).isValid();
    if (isDateValid || !dateValue) {
      // If date is valid or empty, update the record + states
      let formattedDateValue;
      if (dateValue) {
        formattedDateValue = moment(dateValue, 'DD-MM-YYYY').toDate();
      }

      /** @TODO: Replace function with the DispatchReceivable equivalent  */
      // await updateDispatchJobAccountingCustomer(undefined, dispatchJobAccountingCustomer, undefined, { invoicedDateTime: formattedDateValue }, true);

      setIsInvoicedDateInvalid(false);
      setInvoicedDate(formattedDateValue);
    } else if (!isDateValid && !(dateValue.includes('_'))) {
      // If date is filled in but invalid, display an invalid date field
      setIsInvoicedDateInvalid(true);
    }
  }

  // ** Callback Functions ** //

  // Trigger a state refresh
  function toggleRefresh() {
    setRefreshInt(!refreshInt);
  }

  // Update the isVoided attribute with the given value
  function onVoidedChange(value) {
    if (!props.dispatchReceivable) return;
    updateDispatchReceivable(undefined, props.dispatchReceivable, undefined, { isVoided: value }, true);
    toggleRefresh();
  }

  // Open the void receivable confirmation modal depending on the void status to be passed
  function onShowVoidConfirmationModal(changeToVoided) {
    if (changeToVoided) {
      setShowVoidConfirmationModal(changeToVoided);
    } else {
      onVoidedChange(changeToVoided);
    }
  }

  // ** Components ** //

  const receivablesDrawerHeader = (
    <ReceivablesDrawerHeader
      dispatchJob={props.dispatchJob}
      dispatchReceivable={props.dispatchReceivable}
      refreshInt={refreshInt}
      isVoided={isVoided}
      onHide={() => props.closeDrawer()}
    />
  );

  const receivableInformation = (
    <ReceivableInformation
      dispatchReceivable={props.dispatchReceivable}
      handleInvoicedDateChange={(dateTimeValue) => handleInvoicedDateChange(dateTimeValue)}
      invoicedDate={invoicedDate}
      isInvoicedDateInvalid={isInvoicedDateInvalid}
      paymentStatusInt={paymentStatusInt}
      isVoided={isVoided}
    />
  );

  const transactionsContainer = (
    <TransactionsContainer
      dispatchJobAccounting={dispatchJobAccounting}
      dispatchReceivable={props.dispatchReceivable}
      toggleRefresh={toggleRefresh}
      onShowVoidConfirmationModal={(value) => onShowVoidConfirmationModal(value)}
      isVoided={isVoided}
    />
  );

  const invoiceHistoryTable = (
    <DispatchDocumentHistoryTable
      dispatchDocumentTypeInt={DocumentTypes.INVOICE.type}
      dispatchJobObjectId={props.dispatchJob && getAttribute(props.dispatchJob, 'objectId')}
      title="Invoice History"
    />
  );

  const notesSection = (
    <>
      <InputLabel className="accounting-notes-label">Notes</InputLabel>
      <InputTextarea
        className="accounting-notes"
        value={notes}
        onChange={(event) => handleNotesChange(event.target.value)}
      />
    </>
  );

  const invoiceHistoryNotesSection = (
    <ColoredSection className="accounting-history-notes-section">
      <div className="grid">
        <div className="col">
          {invoiceHistoryTable}
        </div>
        <div className="col">
          {notesSection}
        </div>
      </div>
    </ColoredSection>
  );

  const voidConfirmationModal = (
    <ConfirmDialog
      className="void-receivable-confirmation-modal"
      visible={showVoidConfirmationModal}
      onHide={() => setShowVoidConfirmationModal(false)}
      message="Are you sure you want to void this receivable?"
      header="Void Receivable Confirmation"
      accept={() => onVoidedChange(true)}
      reject={() => setShowVoidConfirmationModal(false)}
    />
  );

  return (
    <Sidebar
      modal
      dismissable
      visible={props.isDrawerOpen}
      onHide={() => props.closeDrawer()}
      position={props.position ? props.position : 'right'}
      className="p-sidebar-lg hide-receivables-drawer-header"
    >
      <div className="receivables-drawer mt-5">
        {receivablesDrawerHeader}

        {receivableInformation}

        {transactionsContainer}

        {invoiceHistoryNotesSection}
      </div>

      {voidConfirmationModal}
    </Sidebar>
  );
}

export default AccountingDrawer;
