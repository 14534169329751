import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment-timezone';

// API
import { getDispatchReceivables } from 'api/Dispatch/DispatchReceivable';

// CSAPI API
import { getCurrentUserCompanyObjectId, getObjectById, getAttribute } from 'sb-csapi/dist/AAPI';

// CSAPI Enums
import { QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';

// CSAPI sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// ENUMS
/** @todo bring to csapi */
import { DispatchReferenceTypes } from 'enums/DispatchReference';
import { Document as DocumentTypes } from 'sb-csapi/dist/enums/Dispatch/Document';

// Components
import InvoiceJobInformation from 'components/Dispatch/DispatchDocument/DispatchDocuments/Generators/Invoice/InvoiceJobInformation';
import InvoiceRateTable from 'components/Dispatch/DispatchDocument/DispatchDocuments/Generators/Invoice/InvoiceRateTable';
import InvoiceAdditionalCharges from 'components/Dispatch/DispatchDocument/DispatchDocuments/Generators/Invoice/InvoiceAdditionalCharges';
import InvoiceTotal from 'components/Dispatch/DispatchDocument/DispatchDocuments/Generators/Invoice/InvoiceTotal';
import InvoicePaymentInformation from 'components/Dispatch/DispatchDocument/DispatchDocuments/Generators/Invoice/InvoicePaymentInformation';
import InvoiceFooter from 'components/Dispatch/DispatchDocument/DispatchDocuments/Generators/Invoice/InvoiceFooter';

// Context
import DispatchJobLayoutContext from 'contexts/DispatchJobLayoutContext';
import DispatchDocumentsLayoutContext from 'contexts/DispatchDocumentsLayoutContext';

// Shared Components
import DocumentHeader from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/Header/Header';
import DocumentInputTextarea from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputTextarea/InputTextarea';

// Styling
import './style.scss';

/**
 * @description Generates invoice document
 * @param {String} dispatchJobObjectId - dispatchJobObjectId used to get dispatch job
 * @param {String} documentState - contains the state of the latest document in a stringified JSON format
 * @param {DispatchDocument} dispatchDocument - The current DispatchDocument
 * @param {Object} referenceNumbersStringObj - An object containing reference numbers sorted by type and within a string separated by commas
 * @param {Function} downloadDispatchDocument - Callback function to generate a DispatchDocument with the current information
 * @param {Boolean} isDownloaded - The current isDownloaded boolean value
 * @param {Function} toggleDownloaded - Sets isDownloaded to the given boolean value
 * @returns {Component}
 */
function Invoice({ ...props }) {
  // ** Context ** //
  const { refreshJob, dispatchDocumentRefreshToken, resetDispatchDocumentRefreshToken, dispatchDocumentTypesToSave, setIsDocumentOutdatedPrompt } = useContext(DispatchJobLayoutContext);
  const { saveDispatchDocumentState, checkDocumentForUnsavedChanges } = useContext(DispatchDocumentsLayoutContext);

  // ** useStates ** //
  const [dispatchJob, setDispatchJob] = useState(undefined);
  const [userCompany, setUserCompany] = useState(undefined);

  const [additionalChargeRates, setAdditionalChargeRates] = useState([]);
  const [rates, setRates] = useState([]);

  const [documentHasLoaded, setDocumentHasLoaded] = useState(false);

  // State holding all fields
  const [documentStateObj, setDocumentStateObj] = useState({
    batchId: '',
    invoiceDate: '',
    paymentTermType: null,
    paymentDue: '',
    billTo: '',
    currency: 'CDN',
    taxRate: 5,
    discount: 0,
    paymentTo: '',
    notes: '',
    referenceNumbers: '',
    amountDue: 0,
  });

  // ** useEffects ** //

  // Get dispatchJob and user's company pertaining to document
  useEffect(() => {
    if (!props.dispatchJobObjectId) {
      return;
    }

    let didCancel = false;

    async function getDispatchJobInformation() {
      const dispatchJob = await getObjectById(undefined, 'DispatchJob', props.dispatchJobObjectId);
      const userCompanyObjectId = getCurrentUserCompanyObjectId();
      const userCompany = await getObjectById(undefined, 'Company', userCompanyObjectId);

      if (!didCancel) {
        setDispatchJob(dispatchJob);
        setUserCompany(userCompany);
      }
    }

    getDispatchJobInformation();

    return () => { didCancel = true; };
  }, [props.dispatchJobObjectId]);

  // If a document state exists, update the document fields with the state's values. This also runs whenever dispatchJob updates
  // to override the default values with the existing document state's values.
  useEffect(() => {
    let latestDocumentStateObj = {};
    if (props.documentState) {
      latestDocumentStateObj = JSON.parse(props.documentState); // De-string the documentState object
    }

    let invoiceDateString;
    let paymentDueInDaysString;
    let paymentDue;
    if (dispatchJob) {
      const invoiceDate = getAttribute(dispatchJob, 'invoiceDate') || '';
      invoiceDateString = invoiceDate ? moment(invoiceDate).format('YYYY-MM-DD') : '';

      const paymentDueInDays = getAttribute(dispatchJob, 'paymentDueInDays');
      paymentDueInDaysString = paymentDueInDays ? `${paymentDueInDays} days` : '';

      paymentDue = (invoiceDate && paymentDueInDays) ? moment(invoiceDate).add(paymentDueInDays, 'day').format('YYYY-MM-DD') : '';
    }

    setDocumentStateObj({
      ...documentStateObj,
      batchId: latestDocumentStateObj.batchId,
      invoiceDate: latestDocumentStateObj.invoiceDate ? latestDocumentStateObj.invoiceDate : invoiceDateString,
      paymentDue: latestDocumentStateObj.paymentDue ? latestDocumentStateObj.paymentDue : paymentDue,
      amountDue: latestDocumentStateObj.amountDue ? latestDocumentStateObj.amountDue : documentStateObj.amountDue,
      billTo: latestDocumentStateObj.billTo ? latestDocumentStateObj.billTo : documentStateObj.billTo,
      currency: latestDocumentStateObj.currency ? latestDocumentStateObj.currency : documentStateObj.currency,
      taxRate: latestDocumentStateObj.taxRate ? latestDocumentStateObj.taxRate : documentStateObj.taxRate,
      discount: latestDocumentStateObj.discount ? latestDocumentStateObj.discount : documentStateObj.discount,
      paymentTo: latestDocumentStateObj.paymentTo,
      notes: latestDocumentStateObj.notes || '',
      referenceNumbers: latestDocumentStateObj.referenceNumbers,
    });

  }, [props.documentState, dispatchJob]);

  // Update reference numbers when props.referenceNumbersStringObj updates to reflect changes in the document without having to reload
  useEffect(() => {
    let didCancel = false;
    const referenceNumbersString = formatInvoiceReferenceNumbersString(props.referenceNumbersStringObj);

    if (!didCancel) {
      setDocumentStateObj({
        ...documentStateObj,
        referenceNumbers: referenceNumbersString,
      })
    }

    return () => { didCancel = true; }
  }, [props.referenceNumbersStringObj]);

  // Used to update document information when General Information section has an update
  // Right now only used for batch id
  useEffect(() => {
    if (dispatchJob) {
      setDocumentStateObj({
        ...documentStateObj,
        batchId: getAttribute(dispatchJob, 'batchId'),
      })
    }
  }, [props.generalInformationState]);

  useEffect(() => {
    if (!props.dispatchDocument) return;

    async function getPaymentTermType() {
      const { dispatchReceivables } = await getDispatchReceivables(
        undefined,
        undefined,
        undefined,
        [new Filter(QueryRestrictionTypes.EQUAL_TO, 'dispatchDocumentInvoice', props.dispatchDocument)],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      );

      if (dispatchReceivables && dispatchReceivables.length > 0) {
        const dispatchReceivable = dispatchReceivables[0];
        const paymentTermType = getAttribute(dispatchReceivable, 'paymentTermType');
        updateDocumentStateObj('paymentTermType', paymentTermType);
      }
    }

    getPaymentTermType();
  }, [props.dispatchDocument]);

  // If isDownloaded is true, generate a new invoice document
  useEffect(() => {
    if (!props.isDownloaded) return;
    downloadDispatchDocument();
  }, [props.isDownloaded]);

  // If the invoice is generated through the prompt, update the document state and create a new invoice
  useEffect(() => {
    if (!dispatchDocumentRefreshToken || !dispatchJob) return;
    resetDispatchDocumentRefreshToken();

    let batchId;
    if (dispatchJob) batchId = getAttribute(dispatchJob, 'batchId') || '';

    const referenceNumbersString = formatInvoiceReferenceNumbersString(props.referenceNumbersStringObj);

    const _documentStateObj = {
      ...documentStateObj,
      batchId,
      referenceNumbers: referenceNumbersString,
    };

    setDocumentStateObj(_documentStateObj);

    if (props.toggleDownloaded) props.toggleDownloaded(true);
  }, [dispatchDocumentRefreshToken, dispatchJob]);

  // ** Callback Functions ** //
  // Download the new document with the parent function
  async function downloadDispatchDocument() {
    if (props.downloadDispatchDocument) await props.downloadDispatchDocument(documentStateObj);
    if (props.toggleDownloaded) props.toggleDownloaded(false);
  }

  function formatInvoiceReferenceNumbersString(referenceNumbersStringObj) {
    let referenceNumbersString = '';
    if (referenceNumbersStringObj) {
      const jobRefString = referenceNumbersStringObj[DispatchReferenceTypes.JOB.key];
      const customerRefString = referenceNumbersStringObj[DispatchReferenceTypes.CUSTOMER.key];
      const customRefString = referenceNumbersStringObj[DispatchReferenceTypes.CUSTOM.key] || '';

      const formattedJobRefString = `Job Ref #: ${jobRefString}\n`;
      const formattedCustomerRefString = `Customer Ref #: ${customerRefString}\n`;

      const showJobRefString = (jobRefString && formattedJobRefString) || '';
      const showCustomerRefString = (customerRefString && formattedCustomerRefString) || '';

      referenceNumbersString = (`${showJobRefString}${showCustomerRefString}${customRefString}`) || '';
    }

    return referenceNumbersString;
  }

  // Updates the documentStateObj given an attribute and value
  function updateDocumentStateObj(attribute, value) {
    setDocumentStateObj({ ...documentStateObj, [attribute]: value });
  }

  const updateAdditionalChargeRates = (additionalChargeRates) => {
    setAdditionalChargeRates(additionalChargeRates);
  };

  const updateRates = (rates) => {
    setRates(rates);
  };

  // Check if dispatchDocumentTypesToSave contains this document type
  // If it does, save the current document state
  useEffect(() => {
    if (dispatchDocumentTypesToSave.includes(DocumentTypes.INVOICE.type)) {
      saveCurrentDocumentState();
    }
  }, [dispatchDocumentTypesToSave]);

  async function saveCurrentDocumentState() {
    await saveDispatchDocumentState(documentStateObj);
  }

  useEffect(() => {
    // Confirm the document has its latestdocumentstate loaded before we check for changes to trigger the propmt
    if (!documentHasLoaded && !checkDocumentForUnsavedChanges(JSON.stringify(documentStateObj)) && documentStateObj.batchId) {
      setDocumentHasLoaded(!checkDocumentForUnsavedChanges(JSON.stringify(documentStateObj)));
    }

    if (documentHasLoaded) setIsDocumentOutdatedPrompt(true);

  }, [documentStateObj]);

  return (
    <div id="invoice" className="document-generator-invoice">

      <div className="pdf">

        <div className="header">
          <DocumentHeader
            title="Invoice"
            company={userCompany}
          />
        </div>

        <InvoiceJobInformation
          updateDocumentStateObj={updateDocumentStateObj}
          batchId={documentStateObj.batchId}
          invoiceDate={documentStateObj.invoiceDate}
          paymentDue={documentStateObj.paymentDue}
          paymentTermType={documentStateObj.paymentTermType}
          billTo={documentStateObj.billTo}
          isDownloaded={props.isDownloaded}
        />

        <InvoiceRateTable
          dispatchJobObjectId={props.dispatchJobObjectId}
          updateRates={updateRates}
          isDownloaded={props.isDownloaded}
          dispatchJob={props.dispatchJob}
        />

        <div className="grid mx-7">
          <div className="col-6">
            <InvoiceAdditionalCharges
              updateAdditionalChargeRates={updateAdditionalChargeRates}
              isDownloaded={props.isDownloaded}
            />
          </div>

          <div className="col-offset-1 col-5">
            <InvoiceTotal
              additionalChargeRates={additionalChargeRates}
              rates={rates}
              updateDocumentStateObj={updateDocumentStateObj}
              taxRate={documentStateObj.taxRate}
              currency={documentStateObj.currency}
              discount={documentStateObj.discount}
              amountDue={documentStateObj.amountDue}
              isDownloaded={props.isDownloaded}
            />
          </div>

          <div className="col-6 my-3">
            <DocumentInputTextarea
              label="Notes"
              inputClassName="w-full"
              value={documentStateObj.notes}
              onChange={(value) => updateDocumentStateObj('notes', value)}
              autoResize
              rows={5}
              cols={20}
              success={documentStateObj.notes}
              warning={!documentStateObj.notes || documentStateObj.notes === ''}
              isDownloaded={props.isDownloaded}
            />
          </div>

          <div className="col-6 my-3">
            <DocumentInputTextarea
              label="Reference Numbers"
              inputClassName="w-full"
              value={documentStateObj.referenceNumbers}
              onChange={(value) => setDocumentStateObj({ ...documentStateObj, referenceNumbers: value })}
              autoResize
              rows={5}
              cols={20}
            />
          </div>

        </div>

        <InvoicePaymentInformation
          updateDocumentStateObj={updateDocumentStateObj}
          paymentTo={documentStateObj.paymentTo}
          isDownloaded={props.isDownloaded}
        />

        <InvoiceFooter isDownloaded={props.isDownloaded} />

      </div>

    </div>

  );
}

export default Invoice;
