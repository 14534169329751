import React, { useState, useEffect, useContext } from 'react';

// Context
import DispatchJobLayoutContext from 'contexts/DispatchJobLayoutContext';

// Components
import DispatchDocumentsLayout from 'layouts/Dispatch/DocumentsLayout/DocumentsLayout';

// sbCore Components
import TabMenu from 'sbCore/TabMenu/TabMenu';
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';

// CSAPI Enums
import { Document as DocumentTypes } from 'sb-csapi/dist/enums/Dispatch/Document';

// Styles
import './style.scss';

/**
 * @description Renders a tab menu to view different document generators
 * @param {String} dispatchJobObjectId - id used to retrieve documents
 * @param {Boolean} isRefreshState - Boolean that checks if the states should be refreshed
 * @param {Number} dispatchDocumentTypeInt - The document type integer value
 * @param {Number} activeIndex - The active index for the tabMenu
 * @returns
 *
 * @example
 * <DispatchDocumentsView dispatchJobObjectId={dispatchJobObjectId} />
 */
function DispatchDocumentsView({ ...props }) {

  // ** Context ** //
  const { refreshJob, dispatchDocumentRefreshToken } = useContext(DispatchJobLayoutContext);

  // ** useStates ** //

  // default to the bill of lading which is in the first tab
  const [activeIndex, setActiveIndex] = useState(0);
  const [dispatchDocumentTypeInt, setDispatchDocumentTypeInt] = useState(1);

  // ** useEffects ** //
  // Whenever a dispatchDocumentRefreshToken's passed, set the view on the selected document type
  useEffect(() => {
    if (!dispatchDocumentRefreshToken) return;
    setActiveIndex(2);
    setDispatchDocumentTypeInt(DocumentTypes.INVOICE.type);
  }, [dispatchDocumentRefreshToken]);

  useEffect(() => {
    if (!props.dispatchDocumentTypeInt) return;
    setDispatchDocumentTypeInt(props.dispatchDocumentTypeInt);
  }, [props.dispatchDocumentTypeInt]);

  useEffect(() => {
    if (!props.activeIndex) return;
    setActiveIndex(props.activeIndex);
  }, [props.activeIndex]);

  // current document types that can be generated.
  // temporarily use the customer bill of lading for the bill of lading type
  // temporarily use '68' as the type for the all documents tab
  const documents = [
    { label: 'Bill of Lading', type: DocumentTypes.CUSTOMER_BILL_OF_LADING.type },
    { label: 'Load Confirmation', type: DocumentTypes.LOAD_CONFIRMATION.type },
    { label: 'Invoice', type: DocumentTypes.INVOICE.type },
    { label: 'All Documents', type: 68 },
  ];

  // ** Functions ** //

  // when the selected tab is changed, find the associated document to display
  function changeDocumentsTab(index) {
    const selectedDocument = documents.find((document, documentIndex) => {
      return index === documentIndex;
    });

    if (selectedDocument) {
      setActiveIndex(index);
      setDispatchDocumentTypeInt(selectedDocument.type);
    }
  }

  return (
    <ColoredSection className="dispatch-documents-view" title="Document Generation">
      <TabMenu id="dispatch-documents-tab" model={documents} activeIndex={activeIndex} onTabChange={(tab) => changeDocumentsTab(tab.index)} />
      <DispatchDocumentsLayout 
        dispatchDocumentTypeInt={dispatchDocumentTypeInt} 
        dispatchJobObjectId={props.dispatchJobObjectId} 
        isRefreshState={props.isRefreshState} 
        triggerRefreshState={props.triggerRefreshState}
        dispatchJob={props.dispatchJob}
      />
    </ColoredSection>
  );
}

export default DispatchDocumentsView;
