import React, { useState, useEffect } from 'react';

// Components
import Skeleton from 'sbCore/Skeleton/Skeleton';
import Button from 'sbCore/Button/Button';

// Styles
import './style.scss';

/**
 * @description Display an item's information
 * @param {String} [className] - Custom container className
 * @param {Object} [item] - The item who's information's being displayed
 * @param {Boolean} [isLoading] - Whether or not to show a loading skeleton
 * @param {Boolean} [isButtonsHidden] - Whether or not to hide the remove and edit buttons
 * @param {Function} onUnselectItem - Callback when the remove button is clicked. Passes back the item if the user wants it
 * @param {Function} onToggleEditItem - Callback when the edit button is clicked (ex. to toggle edit dialog)
 * @returns
 */
function AutocompleteCard({ ...props }) {

  // ** useStates ** //
  const [item, setItem] = useState(undefined);

  // ** useEffects ** //
  useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  // ** Callback Functions ** //
  function onUnselectItem() {
    if (props.onUnselectItem) props.onUnselectItem(item);

    setItem(undefined);
  }

  // ** Components ** //
  const autocompleteButtons = (
    <div className="autocomplete-buttons">
      <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => onUnselectItem()} />
      <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary" onClick={() => props.onToggleEditItem && props.onToggleEditItem()} />
    </div>
  );

  const autocompleteSkeleton = (
    <div className="flex">
      <Skeleton shape="circle" size="2.625rem" className="mr-2" />
      <div>
        <Skeleton width="8rem" height=".6rem" className="mb-2" />
        <Skeleton width="5rem" height=".6rem" className="mb-2" />
        <Skeleton height=".4rem" />
      </div>
    </div>
  );

  // ** Misc ** //
  let className = 'sbc-autocomplete-card';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      {!props.isLoading && (

        <div className="grid autocomplete-card">

          <div className="col-10 children-container">
            {!props.isLoading && props.children}
          </div>

          {!props.isButtonsHidden && (
            <div className="col-2 pt-0">
              {autocompleteButtons}
            </div>
          )}

        </div>

      )}

      {props.isLoading && autocompleteSkeleton}
    </div>
  );
}

export default AutocompleteCard;
