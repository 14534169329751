import React, { useState, useEffect } from 'react';

// api
import { getAttribute } from 'sb-csapi/dist/AAPI';

// components
import Skeleton from 'sbCore/Skeleton/Skeleton';
import Button from 'sbCore/Button/Button';

import './style.scss';

/**
 * @description Display a DispatchContact's information
 * @param {String} className - Custom container className
 * @param {DispatchOrganizationContact} dispatchContact - DispatchOrganizationContact that's information is displayed
 * @param {Function} onUnselectDispatchContact - Callback when remove button is clicked. Passes back dispatchContact if user wants it
 * @param {Function} onToggleEditDispatchOrganizationContact - Callback to show the contact edit form
 * @param {bool} isLoading - Whether or not to show loading skeleton or information card
 * @returns
 *
 * @example
 * <DispatchContactCard />
 */
export default function DispatchContactCard({ ...props }) {

  // ** useStates ** //
  const [dispatchContact, setDispatchContact] = useState(undefined);

  // ** useEffects ** //
  useEffect(() => {
    setDispatchContact(props.dispatchContact);
  }, [props.dispatchContact]);

  // ** Callbacks ** //
  function onUnselectDispatchContact() {
    if (props.onUnselectDispatchContact) {
      props.onUnselectDispatchContact(dispatchContact);
    }
    setDispatchContact(undefined);
  }

  // ** Dispatch Contact Attributes ** //
  const name = getAttribute(dispatchContact, 'name', true);
  const department = getAttribute(dispatchContact, 'department', true);
  const primaryPhone = getAttribute(dispatchContact, 'primaryPhone', true);
  const title = getAttribute(dispatchContact, 'title', true);
  const extension = getAttribute(dispatchContact, 'extension', true);
  const cellPhone = getAttribute(dispatchContact, 'cellPhone', true);

  // ** Buttons ** //
  const unselectButton = (
    <Button
      icon="pi pi-trash"
      className="p-button-rounded p-button-danger"
      onClick={() => onUnselectDispatchContact()}
    />
  );

  const editButton = (
    <Button
      icon="pi pi-pencil"
      className="p-button-rounded p-button-secondary"
      onClick={() => props.onToggleEditDispatchOrganizationContact && props.onToggleEditDispatchOrganizationContact()}
    />
  );

  // ** Card Text Information ** //
  const nameText = (name
    ? <div className="name font-semibold">{name}</div>
    : <div className="name font-semibold text-warning">No Name Provided</div>
  );

  const titleText = (title
    ? <div className="title font-semibold">{` Title: ${title}`}</div>
    : <div className="department font-semibold text-warning">No Title Provided</div>
  );

  const departmentText = (department
    ? <div className="department font-semibold">{`Department: ${department} `}</div>
    : <div className="department font-semibold text-warning">No Department Provided</div>
  );

  const primaryPhoneText = (primaryPhone
    ? <div className="phone">{`Primary: ${primaryPhone}`}</div>
    : <div className="phone text-warning">No Primary Phone Number Provided</div>
  );

  const cellPhoneText = (cellPhone
    ? <div className="phone">{`Cell: ${cellPhone}`}</div>
    : <div className="phone text-warning">No Cell Phone Number Provided</div>
  );

  const extensionText = (extension
    ? <div className="phone">{`Ext: ${extension}`}</div>
    : <div className="phone text-warning">No Extension Number Provided</div>
  );

  // ** Loading Skeleton ** //
  const skeleton = (
    <div className="custom-skeleton">
      <div className="flex">
        <Skeleton shape="circle" size="2.625rem" className="mr-2" />
        <div>
          <Skeleton width="8rem" height=".6rem" className="mb-2" />
          <Skeleton width="5rem" height=".6rem" className="mb-2" />
          <Skeleton height=".4rem" />
        </div>
      </div>
    </div>
  );

  // ** classNames ** //
  let className = 'dispatch-contact-card';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      {!props.isLoading && (
        <div className="contact-card">
          {unselectButton}
          {editButton}

          <div>
            {nameText}

            {titleText}

            {departmentText}

            {primaryPhoneText}

            {cellPhoneText}

            {extensionText}
          </div>
        </div>
      )}

      {props.isLoading && skeleton}
    </div>
  );
}
