import * as ActionConstants from 'actions/ActionConstants';
import * as Helpers from 'api/Helpers';

const initialState = {
  jobActions: [],
};

function addJobAction(jobActions, newJobAction) {
  const newJobActions = [].concat(jobActions);
  newJobActions.unshift(newJobAction);
  return newJobActions;
}

function deleteJobActions(stateJobActions, jobActionsToBeDeleted) {
  const newStateJobActions = stateJobActions;
  const jobActionsToBeDeletedLen = jobActionsToBeDeleted.length;
  for (let i = 0; i < jobActionsToBeDeletedLen; i++) {
    const index = Helpers.findIndexOfObjArr(newStateJobActions, 'id', jobActionsToBeDeleted[i].id);
    newStateJobActions.splice(index, 1);
  }
  return newStateJobActions;
}

export function JobAction(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_JOBACTIONS_INPROGRESS:
      return {
        ...state,
        status: action.type,
      };
    case ActionConstants.FETCH_JOBACTIONS_SUCCESS:
      return {
        ...state,
        status: action.type,
        jobActions: action.jobActions,
        error: undefined,
      };
    case ActionConstants.FETCH_JOBACTIONS_ERROR:
      return {
        status: action.type,
        error: action.error,
      };
    case ActionConstants.ADD_JOBACTION_INPROGRESS:
      return {
        ...state,
        status: action.type,
        jobActions: state.jobActions,
        error: undefined,
      };
    case ActionConstants.ADD_JOBACTION_SUCCESS:
      return {
        ...state,
        status: action.type,
        jobActions: addJobAction(state.jobActions, action.jobAction),
        error: undefined,
      };
    case ActionConstants.ADD_JOBACTION_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    case ActionConstants.DESTROY_JOBLINKS_SUCCESS:
      return {
        status: action.type,
        jobActions: deleteJobActions(state.jobActions, action.jobActionsToBeDestroyed),
        error: undefined,
      };
    case ActionConstants.UPDATE_JOBACTION_INPROGRESS:
      return {
        ...state,
        status: action.type,
        updateReference: action.updateReference,
        error: undefined,
      };
    case ActionConstants.UPDATE_JOBACTION_SUCCESS:
      return {
        ...state,
        status: action.type,
        error: undefined,
      };
    case ActionConstants.UPDATE_JOBACTION_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    default:
      return state;
  }
}
