import React from 'react';
import moment from 'moment-timezone';

// CSAPI API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// sbCore Components
import Divider from 'sbCore/Divider/Divider';

// Styles
import './style.scss';

/**
 * @description A card displaying DispatchTransfer information
 * @param {DispatchTransfer} dispatchTransfer - The selected DispatchTransfer
 */
function DispatchTransferDropdownCard({ ...props }) {
  if (!props.dispatchTransfer) return null;

  let pickupDateTime = getAttribute(props.dispatchTransfer, 'pickupDateTime');
  if (pickupDateTime) {
    pickupDateTime = moment(pickupDateTime).format('DD-MM-YYYY HH:mm');
  }

  let dropoffDateTime = getAttribute(props.dispatchTransfer, 'dropoffDateTime');
  if (dropoffDateTime) {
    dropoffDateTime = moment(dropoffDateTime).format('DD-MM-YYYY HH:mm');
  }

  const timezone = getAttribute(props.dispatchTransfer, 'timezoneOffsetFromUTC');

  const shipperDispatchOrganization = getAttribute(props.dispatchTransfer, 'shipperDispatchOrganization');
  const shipperOrganizationId = getAttribute(shipperDispatchOrganization, 'organizationId', true) || '**';
  const shipperOrganizationName = getAttribute(shipperDispatchOrganization, 'organizationName', true) || '';

  const consigneeDispatchOrganization = getAttribute(props.dispatchTransfer, 'consigneeDispatchOrganization');
  const consigneeOrganizationId = getAttribute(consigneeDispatchOrganization, 'organizationId', true) || '**';
  const consigneeOrganizationName = getAttribute(consigneeDispatchOrganization, 'organizationName', true) || '';

  const description = `${shipperOrganizationId}-${consigneeOrganizationId}`;

  return (
    <div className="dispatch-transfer-dropdown-card">
      <div className="font-bold description">
        {description}
      </div>

      <Divider />

      <div className={`organization ${!shipperOrganizationName ? ' text-warning' : ''}`}>
        <span className="font-bold">Shipper:</span> {shipperOrganizationName || 'No Shipper Name'}
      </div>

      <div className={`organization ${!consigneeOrganizationName ? ' text-warning' : ''}`}>
        <span className="font-bold">Consignee:</span> {consigneeOrganizationName || 'No Consignee Name'}
      </div>

      <div className={`date ${!pickupDateTime ? ' text-warning' : ''}`}>
        Pickup Date: {pickupDateTime || 'No Pickup Date'}
      </div>

      <div className={`date ${!dropoffDateTime ? ' text-warning' : ''}`}>
        Dropoff Date: {dropoffDateTime || 'No Dropoff Date'}
      </div>

      <div className="timezone">
        Timezone: {timezone || 'No Timezone'}
      </div>
    </div>
  );
}

export default DispatchTransferDropdownCard;
