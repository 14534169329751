// driving duty statuses
export const DrivingELDEventReferenceInts = Object.freeze([13, 21, 22]);

export const ELDEventTypeCode = Object.freeze({
  OFF_DUTY: 11,
  SLEEPER_BERTH: 12,
  DRIVING: 13,
  ON_DUTY_NOT_DRIVING: 14,
  INTERMEDIATE_LOG_WITH_CONVENTIONAL_LOCATION_PRECISION: 21,
  INTERMEDIATE_LOG_WITH_REDUCED_LOCATION_PRECISION: 22,
  PC_YM_WT_CLEARED: 30,
  AUTHORIZED_PERSONAL_USE_OF_CMV: 31,
  YARD_MOVES: 32,
  DRIVERS_FIRST_CERTIFICATION_OF_A_DAILY_RECORD: 41,
  DRIVERS_SECOND_CERTIFICATION_OF_A_DAILY_RECORD: 42,
  DRIVERS_THIRD_CERTIFICATION_OF_A_DAILY_RECORD: 43,
  DRIVERS_FOURTH_CERTIFICATION_OF_A_DAILY_RECORD: 44,
  DRIVERS_FIFTH_CERTIFICATION_OF_A_DAILY_RECORD: 45,
  DRIVERS_SIXTH_CERTIFICATION_OF_A_DAILY_RECORD: 46,
  DRIVERS_SEVENTH_CERTIFICATION_OF_A_DAILY_RECORD: 47,
  DRIVERS_EIGHTH_CERTIFICATION_OF_A_DAILY_RECORD: 48,
  DRIVERS_NINTH_CERTIFICATION_OF_A_DAILY_RECORD: 49,
  AUTHENTICATED_DRIVER_ELD_LOGIN_ACTIVITY: 51,
  AUTHENTICATED_DRIVER_ELD_LOGOUT_ACTIVITY: 52,
  ENGINE_POWER_UP_WITH_CONVENTIONAL_LOCATION_PRECISION: 61,
  ENGINE_POWER_UP_WITH_REDUCED_LOCATION_PRECISION: 62,
  ENGINE_SHUT_DOWN_WITH_CONVENTIONAL_LOCATION_PRECISION: 63,
  ENGINE_SHUT_DOWN_WITH_REDUCED_LOCATION_PRECISION: 64,
  ELD_MALFUNCTION_LOGGED: 71,
  ELD_MALFUNCTION_CLEARED: 72,
  DATA_DIAGNOSTIC_EVENT_LOGGED: 73,
  DATA_DIAGNOSTIC_EVENT_CLEARED: 74,
  OFF_DUTY_TIME_DEFERRAL_NONE: 200,
  OFF_DUTY_TIME_DEFERRAL_DAY_ONE: 201,
  OFF_DUTY_TIME_DEFERRAL_DAY_TWO: 202,
  CYCLE_ONE: 211,
  CYCLE_TWO: 212,
  OPERATING_ZONE_SOUTH_LATITUDE_CANADA: 221,
  OPERATING_ZONE_NORTH_LATITUDE_CANADA: 222,
  OPERATING_ZONE_USA: 223,
  ADDITIONAL_HOURS_NOT_RECORDED: 231,
});

export const ELDEventTypeCodeDescriptions = Object.freeze({
  OFF_DUTY: 'Off Duty',
  SLEEPER_BERTH: 'Sleeper Berth',
  DRIVING: 'Driving',
  ON_DUTY_NOT_DRIVING: 'On Duty',
  INTERMEDIATE_LOG_WITH_CONVENTIONAL_LOCATION_PRECISION: 'Intermediate Log (Conventional Location Precision)',
  INTERMEDIATE_LOG_WITH_REDUCED_LOCATION_PRECISION: 'Intermediate Log (Reduced Location Precision)',
  PC_YM_WT_CLEARED: 'Driver\'s indication for PC, YM, and WT cleared',
  AUTHORIZED_PERSONAL_USE_OF_CMV: 'Personal Use CMV (Off Duty)',
  YARD_MOVES: 'Yard Moves (On Duty)',
  DRIVERS_FIRST_CERTIFICATION_OF_A_DAILY_RECORD: 'Driver Certification',
  DRIVERS_SECOND_CERTIFICATION_OF_A_DAILY_RECORD: 'Driver Certification',
  DRIVERS_THIRD_CERTIFICATION_OF_A_DAILY_RECORD: 'Driver Certification',
  DRIVERS_FOURTH_CERTIFICATION_OF_A_DAILY_RECORD: 'Driver Certification',
  DRIVERS_FIFTH_CERTIFICATION_OF_A_DAILY_RECORD: 'Driver Certification',
  DRIVERS_SIXTH_CERTIFICATION_OF_A_DAILY_RECORD: 'Driver Certification',
  DRIVERS_SEVENTH_CERTIFICATION_OF_A_DAILY_RECORD: 'Driver Certification',
  DRIVERS_EIGHTH_CERTIFICATION_OF_A_DAILY_RECORD: 'Driver Certification',
  DRIVERS_NINTH_CERTIFICATION_OF_A_DAILY_RECORD: 'Driver Certification',
  AUTHENTICATED_DRIVER_ELD_LOGIN_ACTIVITY: 'Driver Login',
  AUTHENTICATED_DRIVER_ELD_LOGOUT_ACTIVITY: 'Driver Logout',
  ENGINE_POWER_UP_WITH_CONVENTIONAL_LOCATION_PRECISION: 'Power-Up (Conventional)',
  ENGINE_POWER_UP_WITH_REDUCED_LOCATION_PRECISION: 'Power-Up (Reduced)',
  ENGINE_SHUT_DOWN_WITH_CONVENTIONAL_LOCATION_PRECISION: 'Shut-Down (Conventional)',
  ENGINE_SHUT_DOWN_WITH_REDUCED_LOCATION_PRECISION: 'Shut-Down (Reduced)',
  ELD_MALFUNCTION_LOGGED: 'ELD Malfunction Logged',
  ELD_MALFUNCTION_CLEARED: 'ELD Malfunction Cleared',
  DATA_DIAGNOSTIC_EVENT_LOGGED: 'Data Diagnostic Logged',
  DATA_DIAGNOSTIC_EVENT_CLEARED: 'Data Diagnostic Cleared',
  OFF_DUTY_TIME_DEFERRAL_NONE: 'Off-Duty Time Deferral set to "None"',
  OFF_DUTY_TIME_DEFERRAL_DAY_ONE: 'Off-Duty Time Deferral set to "Day 1"',
  OFF_DUTY_TIME_DEFERRAL_DAY_TWO: 'Off-Duty Time Deferral set to "Day 2"',
  CYCLE_ONE: 'Cycle set to "Cycle 1"',
  CYCLE_TWO: 'Cycle set to "Cycle 2"',
  OPERATING_ZONE_SOUTH_LATITUDE_CANADA: 'Operating Zone set to "South of Latitude 60°N in Canada"',
  OPERATING_ZONE_NORTH_LATITUDE_CANADA: 'Operating Zone set to "North of Latitude 60°N in Canada"',
  OPERATING_ZONE_USA: 'Operating Zone set to "United States"',
  ADDITIONAL_HOURS_NOT_RECORDED: 'Additional Hours not Recorded',
});

export const ELDEventTypeCodeIntDescriptions = Object.freeze({
  11: 'Off Duty',
  12: 'Sleeper Berth',
  13: 'Driving',
  14: 'On Duty',
  21: 'Intermediate Log (Conventional Location Precision)',
  22: 'Intermediate Log (Reduced Location Precision)',
  30: 'Driver\'s indication for PC, YM, and WT cleared',
  31: 'Personal Use CMV (Off Duty)',
  32: 'Yard Moves (On Duty)',
  41: 'Driver Certification',
  42: 'Driver Certification',
  43: 'Driver Certification',
  44: 'Driver Certification',
  45: 'Driver Certification',
  46: 'Driver Certification',
  47: 'Driver Certification',
  48: 'Driver Certification',
  49: 'Driver Certification',
  51: 'Driver Login',
  52: 'Driver Logout',
  61: 'Power-Up (Conventional)',
  62: 'Power-Up (Reduced)',
  63: 'Shut-Down (Conventional)',
  64: 'Shut-Down (Reduced)',
  71: 'ELD Malfunction Logged',
  72: 'ELD Malfunction Cleared',
  73: 'Data Diagnostic Logged',
  74: 'Data Diagnostic Cleared',
  200: 'Off-Duty Time Deferral set to "None"',
  201: 'Off-Duty Time Deferral set to "Day 1"',
  202: 'Off-Duty Time Deferral set to "Day 2"',
  211: 'Cycle set to "Cycle 1"',
  212: 'Cycle set to "Cycle 2"',
  221: 'Operating Zone set to "South of Latitude 60°N in Canada"',
  222: 'Operating Zone set to "North of Latitude 60°N in Canada"',
  223: 'Operating Zone set to "United States"',
  231: 'Additional Hours not Recorded',
});
