import React, { useState, useEffect } from 'react';

// CSAPI
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import Image from 'sbCore/Image/Image';

// Styling
import './style.scss';

/**
 * @description Document generator header
 * @param {Company} userCompany - company object of user used to obtain information
 * @param {boolean} [hideCompanyLogo] - if set, hides the company logo
 * @param {boolean} [hideCompanyAddress] - if set, hids the company address
 * @param {String} [documentHeaderTitle] - the title of the document header
 * @returns {Component}
 */
function Header({ hideCompanyAddress, ...props }) {
  const [userCompany, setUserCompany] = useState(undefined);
  const [companyLogoURL, setCompanyLogoURL] = useState(undefined);
  const [companyAddressDetails, setCompanyAddressDetails] = useState({
    name: '',
    mcNumber: '',
    dotNumber: '',
    address: {
      city: '',
      stateProvince: '',
      country: '',
      postalCode: '',
    },
    phoneNumber: '',
    faxNumber: '',
  });

  useEffect(() => {
    if (!props.company) return;

    setUserCompany(props.company);
  }, [props.company]);


  // UseEffect which updates the company information whenever the user company changes
  useEffect(() => {
    if (userCompany) {
      let companyLogoURL = '';
      const companyLogo = getAttribute(userCompany, 'logo', true);
      if (companyLogo) companyLogoURL = companyLogo._url;

      const companyName = getAttribute(userCompany, 'name', true) || '-';

      let companyMCNumber = getAttribute(userCompany, 'mcNumber', true);
      if (companyMCNumber) {
        companyMCNumber = parseInt(companyMCNumber).toString();
      } else {
        companyMCNumber = '-';
      }

      const companyDOTNumber = getAttribute(userCompany, 'dotNumber', true) || '-';

      const address = getAttribute(userCompany, 'address', true);

      let companyAddress = '-';
      let companyCity = '-';
      let companyStateProvince = '-';
      let companyCountry = '-';
      let companyZipPostalCode = '-';

      if (address) {
        companyAddress = getAttribute(address, 'address', true) || '-';
        companyCity = getAttribute(address, 'city', true) || '-';
        companyStateProvince = getAttribute(address, 'stateProvince', true) || '-';
        companyCountry = getAttribute(address, 'country', true) || '-';
        companyZipPostalCode = getAttribute(address, 'zipPostal', true) || '-';
      }

      const companyPhoneNumber = getAttribute(userCompany, 'phoneNumber', true) || '-';
      const companyFax = getAttribute(userCompany, 'faxNumber', true) || '-';

      const _companyAddressDetails = {
        name: companyName,
        mcNumber: companyMCNumber,
        dotNumber: companyDOTNumber,
        address: {
          street: companyAddress,
          city: companyCity,
          stateProvince: companyStateProvince,
          country: companyCountry,
          zipPostalCode: companyZipPostalCode,
        },
        phoneNumber: companyPhoneNumber,
        faxNumber: companyFax,
      }

      setCompanyLogoURL(companyLogoURL);
      setCompanyAddressDetails(_companyAddressDetails);
    }
  }, [userCompany]);

  const companyLogo = <Image src={companyLogoURL} alt="No Company Logo Available" />;
  const { street, city, stateProvince, country, zipPostalCode } = companyAddressDetails.address;

  return (
    <div className="document-header my-5 grid">
      <div className="col-7 flex align-items-center">
        {!props.hideCompanyLogo && (
          <div className="logo">
            {companyLogoURL && companyLogo}
          </div>
        )}
        <div className="document-title">{props.title}</div>
      </div>

      {!hideCompanyAddress && (
        <div className="col-5 flex justify-content-end">
          <div className="pt-2 company">
            <div className="company-name">{companyAddressDetails.name}</div>
            {(companyAddressDetails.mcNumber && companyAddressDetails.dotNumber) && 
              <div className="company-text">MC-{companyAddressDetails.mcNumber} DOT-{companyAddressDetails.dotNumber}</div>
            }
            {(street && city && stateProvince && country && zipPostalCode) &&
              <>
                <div className="company-text">{companyAddressDetails.address.street}</div>
                <div className="company-text">{companyAddressDetails.address.city}, {companyAddressDetails.address.stateProvince},</div>
                <div className="company-text">{companyAddressDetails.address.country}, {companyAddressDetails.address.zipPostalCode}</div>
              </>
            }
            {companyAddressDetails.phoneNumber && <div className="company-text">Phone: {companyAddressDetails.phoneNumber}</div>}
            {companyAddressDetails.faxNumber && <div className="company-text">Fax: {companyAddressDetails.faxNumber}</div>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;