import React, { useState, useEffect } from 'react';
import history from 'sbHistory';

// CSAPI API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// sbCore Components
import Button from 'sbCore/Button/Button';

// Styles
import './style.scss';

/**
 * @description A header for the receivables drawer
 * @param {DispatchJob} dispatchJob - The selected DispatchJob
 * @param {Number} refreshInt - Refreshes the state if the number is changed
 * @param {Boolean} isVoided - Whether or not the DispatchReceivable is voided
 * @param {Function} onHide - Runs when the receivables drawer is closed
 * @returns
 *
 * @example
 * <ReceivablesDrawerHeader dispatchJob={dispatchJob} />
 */
function ReceivablesDrawerHeader({ ...props }) {

  // ** useStates ** //
  const [customerDispatchOrganizationName, setCustomerDispatchOrganizationName] = useState('');
  const [batchId, setBatchId] = useState('');
  const [orderSummaryRoute, setOrderSummaryRoute] = useState('');

  // ** useEffects ** //
  useEffect(() => {
    if (!props.dispatchJob) return;
    const customerDispatchOrganization = getAttribute(props.dispatchJob, 'customerDispatchOrganization');
    if (customerDispatchOrganization) {
      let _customerDispatchOrganizationName = getAttribute(customerDispatchOrganization, 'organizationName');
      _customerDispatchOrganizationName = _customerDispatchOrganizationName ? `(${_customerDispatchOrganizationName})` : '';
      setCustomerDispatchOrganizationName(_customerDispatchOrganizationName);
    }

    const dispatchJobObjectId = getAttribute(props.dispatchJob, 'objectId');
    const _orderSummaryRoute = `/dispatch/job/${dispatchJobObjectId}`;
    setOrderSummaryRoute(_orderSummaryRoute);

    const _batchId = getAttribute(props.dispatchJob, 'batchId');
    setBatchId(_batchId);
  }, [props.dispatchJob, props.refreshInt]);

  // ** Components ** //
  const hideDrawerButton = (
    <Button
      icon="pi pi-times"
      className="ml-3 p-button-rounded p-button-text hide-drawer-button"
      onClick={() => props.onHide()}
    />
  );

  // ** Header Text ** //
  const voidedWarning = ' Voided ';

  const headerText = (
    <div className="flex">
      <div>
        <a className="text-2xl font-bold mr-2" onClick={() => history.push(orderSummaryRoute)}>
          {`${batchId} ${customerDispatchOrganizationName}`}
        </a>
      </div>

      {props.isVoided && (
        <div className="flex">
          <p className="text-2xl font-bold mr-2"> - </p>
          <i className="pi pi-exclamation-circle mr-2 voided-icon" style={{ fontSize: '2em' }} />
          <p className="text-2xl font-bold">{voidedWarning}</p>
        </div>
      )}

    </div>
  );

  return (
    <div className="flex justify-content-between flex-wrap receivables-drawer-header mx-4">
      {headerText}

      <div className="flex flex-row justify-content-end">
        {hideDrawerButton}
      </div>

    </div>
  );
}

export default ReceivablesDrawerHeader;
