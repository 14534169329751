// libraries
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { ConfirmDialog } from 'primereact/confirmdialog';

// api
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getSafetyRecurringReminders, addSafetyRecurringReminder, updateSafetyRecurringReminder, deleteSafetyRecurringReminder } from 'api/SafetyRecurringReminder/SafetyRecurringReminder';

// enums
import { QueryRestrictionTypes } from 'enums/Query';
import { StatusInt } from 'enums/SafetyRecurringReminder';

// sbobjects
import Filter from 'sbObjects/Filter';

// components
import PastSafetyRecurringRemindersTable from 'components/SafetyRecurringReminder/PastSafetyRecurringRemindersTable/PastSafetyRecurringRemindersTable';

function PastSafetyRecurringRemindersView() {
  const [isLoading, setIsLoading] = useState(true);
  const [safetyRecurringReminders, setSafetyRecurringReminders] = useState([]);
  const [selectedSafetyRecurringReminders, setSelectedSafetyRecurringReminders] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(function() {
    (async function () {
      await refreshState();
    }())
  }, []);

  async function refreshState() {
    setIsLoading(true);

    const safetyRecurringReminderObj = await getSafetyRecurringReminders(
      undefined,
      undefined,
      undefined,
      [new Filter('statusInt', StatusInt.PAST, QueryRestrictionTypes.EQUAL_TO)],
      true,
      true,
      true
    );

    const safetyRecurringReminderRecords = safetyRecurringReminderObj.objArr;
    const safetyRecurringReminders = safetyRecurringReminderRecords.map(safetyRecurringReminderRecord => {
      return {
        safetyRecurringReminderRecord,
        id: getAttribute(safetyRecurringReminderRecord, 'objectId'),
        name: getAttribute(safetyRecurringReminderRecord, 'name'),
        notes: getAttribute(safetyRecurringReminderRecord, 'notes'),
        reminderType: getAttribute(safetyRecurringReminderRecord, 'reminderType'),
        frequencyType: getAttribute(safetyRecurringReminderRecord, 'frequencyType'),
        triggerDate: getAttribute(safetyRecurringReminderRecord, 'triggerDate'),
      };
    });

    setSafetyRecurringReminders(safetyRecurringReminders);
    setIsLoading(false);
  }

  async function handleCloneSafetyRecurringReminders() {
    setIsLoading(true);

    let i = 0;
    while (i < selectedSafetyRecurringReminders.length) {
      const safetyRecurringReminderRecord = selectedSafetyRecurringReminders[i].safetyRecurringReminderRecord;
      const safetyRecurringReminderClone = {};
      Object.assign(safetyRecurringReminderClone, safetyRecurringReminderRecord.attributes);
      await addSafetyRecurringReminder(safetyRecurringReminderClone);
      i++;
    }

    setSelectedSafetyRecurringReminders([]);
    await refreshState();
  }

  async function handleDeleteSafetyRecurringReminders() {
    setIsLoading(true);

    let i = 0;
    while (i < selectedSafetyRecurringReminders.length) {
      const safetyRecurringReminderRecord = selectedSafetyRecurringReminders[i].safetyRecurringReminderRecord;
      await updateSafetyRecurringReminder(safetyRecurringReminderRecord, { isHidden: true });
      i++;
    }

    setSelectedSafetyRecurringReminders([]);
    await refreshState();
  }

  async function handleReturnSafetyRecurringReminder(safetyRecurringReminderRecord) {
    // currently, this only handles one-time reminders
    const keyValueObj = {
      isHidden: false,
      statusInt: StatusInt.UPCOMING,
      triggerDate: moment().add(1, 'day').toDate(),
    };

    await updateSafetyRecurringReminder(safetyRecurringReminderRecord, keyValueObj);
    await refreshState();
  }

  return (
    <>
      <ConfirmDialog
        visible={showConfirmModal}
        style={{ width: '50vw '}}
        header={selectedSafetyRecurringReminders.length >= 2 ? 'Delete Reminders' : 'Delete Reminder'}
        message={selectedSafetyRecurringReminders.length >= 2 ? 'Are you sure you want to delete the reminders?' : 'Are you sure you want to delete the reminder?'}
        acceptLabel="Confirm"
        rejectLabel="Cancel"
        accept={async () => await handleDeleteSafetyRecurringReminders()}
        reject={() => setShowConfirmModal(false)}
        onHide={() => setShowConfirmModal(false)}
      />

      <PastSafetyRecurringRemindersTable
        safetyRecurringReminders={safetyRecurringReminders}
        loading={isLoading}
        selection={selectedSafetyRecurringReminders}
        onSelectionChange={(safetyRecurringReminderRecords) => setSelectedSafetyRecurringReminders(safetyRecurringReminderRecords)}
        handleClone={async () => await handleCloneSafetyRecurringReminders()}
        handleDelete={() => setShowConfirmModal(true)}
        handleReturn={async (safetyRecurringReminderRecord) => await handleReturnSafetyRecurringReminder(safetyRecurringReminderRecord)}
      />
    </>
  );
}

export default PastSafetyRecurringRemindersView;
