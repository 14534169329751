import React, { useState, useEffect } from 'react';

// api
import { getAttribute, getCurrentUser, createGeoPoint } from 'sb-csapi/dist/AAPI';

// sbcore
import Dialog from 'sbCore/Dialog/Dialog';
import InputText from 'sbCore/InputText/InputText';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import StateProvinceDropdown from 'sbCore/StateProvinceDropdown/StateProvinceDropdown';
import CountryDropdown from 'sbCore/CountryDropdown/CountryDropdown';
import Button from 'sbCore/Button/Button';

// components
import LocationAutocomplete from 'components/Shared/LocationAutocomplete/LocationAutocomplete';

// styles
import './style.scss';

/**
 * @description Handle custom addresses
 * @param {String} [className] - Custom container className
 * @param {Address} [address] - Address record
 * @param {Object} [addressObject] - Address object
 * @param {Function} onSaveAddress - Callback for saving address
 * @param {Function} onCancelAddress - Callback for cancelling address
 * @param {Boolean} visible - If the form is visible
 * @param {Boolean} [returnOnlyCities] - Determines if only cities should be returned from the autocomplete
 * @param {Boolean} [forceValidLatLng] - Determines if strict requirement on valid lat/lng
 * @returns
 */

function AddressDialog(props) {
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [addressObj, setAddressObj] = useState({ geoPoint: createGeoPoint(0, 0) }); // holds attributes of dispatchOrganization.address for the state

  useEffect(() => {
    setLatitude(getAttribute(props.address, 'geoPoint', true) ? getAttribute(props.address, 'geoPoint', true).latitude : 0);
    setLongitude(getAttribute(props.address, 'geoPoint', true) ? getAttribute(props.address, 'geoPoint', true).longitude : 0);

    if (props.address) {
      setAddressObj({
        address: getAttribute(props.address, 'address', true),
        city: getAttribute(props.address, 'city', true),
        stateProvince: getAttribute(props.address, 'stateProvince', true),
        zipPostal: getAttribute(props.address, 'zipPostal', true),
        country: getAttribute(props.address, 'country', true),
        geoPoint: getAttribute(props.address, 'geoPoint', true) || createGeoPoint(0, 0),
        belongsToCompany: getAttribute(props.address, 'belongsToCompany', true) || getAttribute(getCurrentUser(), 'belongsToCompany'),
      });
    }

    if (props.addressObject) {
      setAddressObj(props.addressObject);
    }

  }, [props.address, props.addressObject, props.visible]); // props.visible is here so it resets the form to initial state when "closed"

  // ** Components ** //

  // function to handle any cleaning / validating of address inputs
  function handleAddressInput(attribute, value) {
    const _addressObj = { ...addressObj };

    // note: valid range of latitude in degrees is -90 and +90 for the southern and northern hemisphere, respectively. Longitude is in the range -180 and +180
    // the inputs will ignore invalid coordinates
    if (attribute === 'latitude') {
      const _value = parseFloat(value || 0);
      _addressObj.geoPoint = createGeoPoint(_value, _addressObj.geoPoint._longitude);
    } else if (attribute === 'longitude') {
      const _value = parseFloat(value || 0);
      _addressObj.geoPoint = createGeoPoint(_addressObj.geoPoint._latitude, _value);
    } else {
      _addressObj[attribute] = value;
    }

    setAddressObj(_addressObj);
  }

  // from the location autocomplete. parse the address it gives us and update state
  function handleSearchAddress(addressSbObj) {
    setLatitude(addressSbObj.latitude);
    setLongitude(addressSbObj.longitude);
    const addressObj = {
      address: addressSbObj.address,
      city: addressSbObj.city,
      stateProvince: addressSbObj.stateProvince,
      zipPostal: addressSbObj.zipPostal,
      country: addressSbObj.country,
      geoPoint: createGeoPoint(addressSbObj.latitude, addressSbObj.longitude),
    };

    setAddressObj(addressObj);
  }

  const searchAddressInput = (
    <React.Fragment>
      <InputLabel>Search Address</InputLabel>
      <LocationAutocomplete
        usePRStyle
        types={props.returnOnlyCities && ['(cities)']}
        containerClass="search-address-autocomplete"
        inputClassName="p-inputtext-sm w-full"
        onLocationSelected={(addressSbObj) => handleSearchAddress(addressSbObj)}
      />
    </React.Fragment>
  );

  const addressInput = (
    <React.Fragment>
      <InputLabel>Address / Street</InputLabel>
      <InputText type="text"
        className={`p-inputtext-sm w-full`}
        value={addressObj.address}
        onChange={(e) => handleAddressInput('address', e.target.value)}
      />
    </React.Fragment>
  );

  const cityInput = (
    <React.Fragment>
      <InputLabel>City</InputLabel>
      <InputText type="text"
        className={`p-inputtext-sm w-full`}
        value={addressObj.city}
        onChange={(e) => handleAddressInput('city', e.target.value)}
      />
    </React.Fragment>
  );

  const stateProvinceDropdown = (
    <React.Fragment>
      <InputLabel>State / Province</InputLabel>
      <StateProvinceDropdown
        dropdownClassName="p-inputtext-sm w-full"
        stateProvince={addressObj.stateProvince}
        onSelect={(stateProvince) => handleAddressInput('stateProvince', stateProvince.code)}
        showOnFocus
        hideLabel
      />
    </React.Fragment>
  );

  const zipPostalInput = (
    <React.Fragment>
      <InputLabel>ZIP / Postal Code</InputLabel>
      <InputText type="text"
        placeholder=""
        className={`p-inputtext-sm w-full`}
        value={addressObj.zipPostal}
        onChange={(e) => handleAddressInput('zipPostal', e.target.value)}
      />
    </React.Fragment>
  );

  const countryDropdown = (
    <React.Fragment>
      <InputLabel>Country</InputLabel>
      <CountryDropdown
        dropdownClassName="p-inputtext-sm w-full"
        country={addressObj.country}
        onSelect={(country) => handleAddressInput('country', country.code)}
        showOnFocus
        hideLabel
      />
    </React.Fragment>
  );

  const coordinatesInputs = (
    <React.Fragment>
      <InputLabel>Latitude / Longitude</InputLabel>
      <div className="coordinates-inputs w-full flex align-items-center">
        {/* <span className="text-black-lighten" style={{ fontSize: '1.2em' }}>(</span> */}
        <InputText
          placeholder="0"
          className={`p-inputtext-sm w-full`}
          value={latitude}
          onChange={(e) => {
            setLatitude(e.target.value);
            handleAddressInput('latitude', parseFloat(e.target.value))
          }}
        />
        <span className="text-black-lighten" style={{ fontSize: '1.2em' }}> &nbsp; </span>
        <InputText
          placeholder="0"
          className={`p-inputtext-sm w-full`}
          value={longitude}
          onChange={(e) => {
            setLongitude(e.target.value);
            handleAddressInput('longitude', parseFloat(e.target.value))
          }}
        />
        {/* <span className="text-black-lighten" style={{ fontSize: '1.2em' }}>)</span> */}
      </div>
    </React.Fragment>
  );

  const renderFooter = (name) => {
    return (
      <div className="text-right">
        <div className="inline-block mr-3">
          <Button className="p-button-sm p-button-default" text severity="secondary" label="Cancel" onClick={() => props.onCancelAddress()} sbVariant="short" />
        </div>
        <div className="inline-block">
          <Button
            label="Save"
            disabled={props.forceValidLatLng ? (!addressObj.geoPoint._latitude || !addressObj.geoPoint._longitude) : false}
            onClick={() => props.onSaveAddress(props.address, addressObj)}
            sbVariant="short"
            severity="primary"
          />
        </div>
      </div>
    );
  }


  // ** Misc ** //
  const name = 'Address';

  let className = 'sbc-address-dialog';
  if (props.className) className += ` ${props.className}`;

  return (
    <Dialog
      className={className}
      visible={props.visible}
      header={<div>{name}</div>}
      footer={renderFooter()} onHide={() => props.onCancelAddress()}
      closable={false}
      style={{ width: '50vw' }}
    >
      <div className="flex flex-column lg:flex-row w-full mb-5">
        <div className="flex-1 mb-2 lg:mb-0">
          {searchAddressInput}
        </div>
      </div>

      <hr />

      <div className="flex flex-column lg:flex-row lg:mb-3">
        <div className="lg:pr-3 mb-2 lg:mb-0 w-full">
          {addressInput}
        </div>

      </div>
      <div className="flex flex-column lg:flex-row lg:mb-3">
        <div className="lg:pr-3 mb-2 lg:mb-0 w-full">
          {cityInput}
        </div>
        <div className="lg:pr-3 mb-2 lg:mb-0 lg:w-20rem">
          {stateProvinceDropdown}
        </div>
        <div className="lg:pr-3 mb-2 lg:mb-0 lg:w-20rem">
          {zipPostalInput}
        </div>
      </div>
      <div className="flex flex-column lg:flex-row">
        <div className="lg:pr-3 mb-2 lg:mb-0 lg:w-20rem">
          {countryDropdown}
        </div>
        <div className="lg:pr-3 mb-2 lg:mb-0 lg:w-full">
          {coordinatesInputs}
        </div>
      </div>
    </Dialog>
  );
}

export default AddressDialog;
