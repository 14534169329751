import React from 'react';

// CSAPI
import { ELDEventTypeCode } from 'sb-csapi/dist/enums/ELD/ELDEventTypeCode';

// Components
import DrivingIcon from 'sbCore/DirectionalIcons/DrivingIcon/DrivingIcon';
import StationaryIcon from 'sbCore/EquipmentIcons/StationaryIcon/StationaryIcon';
import TrailerIcon from 'sbCore/EquipmentIcons/TrailerIcon/TrailerIcon';

// Styles
import './style.scss';

/**
* @description Renders a map icon given an icon type
* @param {Number} eldEventTypeCodeInt - The event type number associated with a certain icon type.
* @param {Function} onClick - Callback that triggers when the map icon is clicked.
* @returns
*
* @example
* <MapIcon icontype="driving" eldEventTypeCodeInt={14} onClick={onClick} />
*/
function MapIcon({ ...props }) {

  let backgroundColorClassName;

  // Duty Status:
  // 11: Off Duty
  // 12: Sleeper
  // 33: Driving
  // 14: On Duty Not Driving
  switch (props.eldEventTypeCodeInt) {
    case ELDEventTypeCode.OFF_DUTY:
      backgroundColorClassName = 'off-duty';
      break;

    case ELDEventTypeCode.SLEEPER_BERTH:
      backgroundColorClassName = 'sleeper';
      break;

    case ELDEventTypeCode.DRIVING:
      backgroundColorClassName = 'driving';
      break;

    case ELDEventTypeCode.ON_DUTY_NOT_DRIVING:
      backgroundColorClassName = 'on-duty';
      break;

    default:
      backgroundColorClassName = 'off-duty';
  }

  return (
    <div className="map-icon">
      {props.iconType === 'driving' && (
        <DrivingIcon className="icon" backgroundColorClassName={backgroundColorClassName} onClick={props.onClick} />
      )}

      {props.iconType === 'stationary' && (
        <StationaryIcon className="icon" backgroundColorClassName={backgroundColorClassName} onClick={props.onClick} />
      )}

      {props.iconType === 'trailer' && (
        <TrailerIcon className="icon" onClick={props.onClick} />
      )}
    </div>
  );
}

export default MapIcon;
