import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// sappy
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { formatName } from 'sb-csapi/dist/utils/String';

// components
import Card from 'sbCore/Card/Card';
import Dropdown from 'sbCore/Dropdown/Dropdown';

import VehiclePathMap from 'components/IFTA/IFTAVehicleDayView/IFTAVehicleDayBreakdownCard/VehiclePathMap/VehiclePathMap';
import VehicleTripTable from 'components/IFTA/IFTAVehicleDayView/IFTAVehicleDayBreakdownCard/VehicleTripTable/VehicleTripTable';

// styles
import './style.scss';

/**
 * @description Display the list of trips and map visualization
 * @param {String} dayData - The IFTA data for the vehicle and specific day
 * @param {String} [lengthUnit] - From enums LengthUnit - KM, MI
 * @param {Function} [onSelectTrip] - Callback to return info from the selected trip
 */
function IFTAVehicleDayBreakdownCard({ ...props }) {

  // From VehicleTripTable
  const [iftaRouteObj, setIFTARouteObj] = useState(undefined);
  const [derivedVehicleLocations, setDerivedVehicleLocations] = useState(undefined);

  /**
   * Functions
   */
  function onSelectTrip(iftaRouteObj, derivedVehicleLocations) {
    setIFTARouteObj(iftaRouteObj);
    setDerivedVehicleLocations(derivedVehicleLocations);

    if (props.onSelectTrip) props.onSelectTrip(iftaRouteObj, derivedVehicleLocations);
  }

  function onSelectDriver(driverObj) {
    // console.log(driverObj);
  }

  /**
   * JSX
   */
  const driversDropdownItemTemplate = (driverObj) => {
    if (!props.dayData?.dateStart) return <div />;

    return (
      <a
        key={driverObj.objectId}
        href={`/driver?driver=${driverObj.objectId}&view=hosEvents&date=${moment(props.dayData.dateStart).format('DDMMYY')}`}
        target="_blank"
        rel="noreferrer"
      >
        View Logs - { formatName(driverObj.user_fullName) }
      </a>
    );
  };

  const driverObjs = props.dayData?.driverObjs || []; // next, sort by alphabetical order of driver name
// console.log('***');
// console.log('Drivers Before:');
// console.log(driverObjs);
  driverObjs.sort((a, b) => {
    if (a.user_fullName < b.user_fullName) return -1;
    if (a.user_fullName > b.user_fullName) return 1;
    return 0;
  });
// console.log('Drivers After:');
// console.log(driverObjs);

  let className = 'ifta-vehicle-day-breakdown-card';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <Card
        title={(
          <div>
            <div className="inline-block">{props.dayData?.dateStart || 'Loading...'}</div>
            <Dropdown
              options={driverObjs}
              optionLabel="user_fullName"
              itemTemplate={driversDropdownItemTemplate}
              onChange={(e) => onSelectDriver(e.value)}
              variant="caret"
              style={{ marginLeft: '.25em' }}
              emptyMessage="No Drivers Found for this day"
              disabled={!props.dayData?.dateStart}
            />
          </div>
        )}
        // footer={shipmentDetailsFooter}
        // variant="compact"
      >
        <div className="mb-1 roboto text-gray-calm font-bold" style={{ fontSize: '.9em' }}>Day Breakdown</div>

        <VehiclePathMap iftaRouteObj={iftaRouteObj} derivedVehicleLocations={derivedVehicleLocations} />

        <div className="mt-5 mb-3 roboto text-gray-calm font-bold" style={{ fontSize: '1em' }}>Trips</div>
        <VehicleTripTable
          dayData={props.dayData}
          lengthUnit={props.lengthUnit}
          onSelect={(iftaRouteObj, derivedVehicleLocations) => onSelectTrip(iftaRouteObj, derivedVehicleLocations)}
        />
      </Card>
    </div>
  );
}

export default IFTAVehicleDayBreakdownCard;
