import React from 'react';

// Components
import { TabMenu as PRTabMenu } from 'primereact/tabmenu';

// Styling
import './style.scss';

export default function TabMenu({ ...props }) {
  return (
    <div className="sbc-tab-menu">
      <PRTabMenu {...props} />
    </div>
  );
}
