import { findIndexOfObjArr, removeDuplicatesByObjectId } from 'api/Helpers';
import * as ActionConstants from '../actions/ActionConstants';

const initialState = {
  trailers: [],
  fetched: false, // Whether or not drivers are loaded
};

function disableTrailers(stateTrailers, trailersToBeDeleted) {
  const newStateTrailers = [].concat(stateTrailers);
  if (newStateTrailers) {
    const trailersToBeDeletedLen = trailersToBeDeleted.length;
    for (let i = 0; i < trailersToBeDeletedLen; i++) {
      const index = findIndexOfObjArr(newStateTrailers, 'id', trailersToBeDeleted[i].id);
      newStateTrailers.splice(index, 1);
    }
  }
  return newStateTrailers;
}

export function Trailer(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_TRAILERS_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.FETCH_TRAILERS_INPROGRESS,
        fetched: false,
        error: undefined,
      };
    }
    case ActionConstants.FETCH_TRAILERS_SUCCESS: {
      return {
        status: ActionConstants.FETCH_TRAILERS_SUCCESS,
        trailers: removeDuplicatesByObjectId(state.trailers.concat(action.trailers)),
        error: undefined,
        fetched: true,
      };
    }
    case ActionConstants.FETCH_TRAILERS_ERROR: {
      return {
        ...state,
        status: ActionConstants.FETCH_TRAILERS_ERROR,
        error: action.error,
        fetched: false,
      };
    }
    case ActionConstants.DELETE_TRAILERS: {
      return {
        ...initialState,
        status: action.type,
      };
    }
    case ActionConstants.ADD_TRAILER_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.ADD_TRAILER_INPROGRESS,
        error: undefined,
      };
    }
    case ActionConstants.ADD_TRAILER_SUCCESS: {
      // Succesfully added to db
      const updatedTrailers = [].concat(state.trailers);
      updatedTrailers.unshift(action.trailer);
      return {
        ...state,
        status: ActionConstants.ADD_TRAILER_SUCCESS,
        trailers: updatedTrailers,
        error: undefined,
      };
    }
    case ActionConstants.ADD_TRAILER_ERROR: {
      return {
        ...state,
        status: ActionConstants.ADD_TRAILER_ERROR,
        error: action.error,
      };
    }
    case ActionConstants.UPDATE_TRAILER_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.UPDATE_TRAILER_INPROGRESS,
        updateReference: action.trailer,
        error: undefined,
      };
    }
    case ActionConstants.UPDATE_TRAILER_SUCCESS: {
      return {
        ...state,
        status: ActionConstants.UPDATE_TRAILER_SUCCESS,
        trailers: action.trailers || state.trailers,
        error: undefined,
      };
    }
    case ActionConstants.UPDATE_TRAILER_ERROR: {
      return {
        ...state,
        status: ActionConstants.UPDATE_TRAILER_ERROR,
        error: action.error,
      };
    }
    case ActionConstants.DISABLE_TRAILERS_SUCCESS: {
      return {
        ...state,
        status: ActionConstants.DISABLE_TRAILERS_SUCCESS,
        trailers: disableTrailers(state.trailers, action.trailers),
        error: undefined,
      };
    }
    default:
      return state;
  }
}
