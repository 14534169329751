import React from 'react';
import PropTypes from 'prop-types';
import { Layer, Feature, Marker } from 'react-mapbox-gl';

import styles from './ComponentStyles.module.scss';

// API
import { getAttribute } from 'api/Parse';

function GPSPointHistory(props) {
  const vehicleLocationObjWithBearingFeatures = props.vehicleLocationArr
    .filter((vehicleLocationObj) => (getAttribute(vehicleLocationObj, 'gpsHeading') !== undefined || getAttribute(vehicleLocationObj, 'course') !== undefined))
    .map((vehicleLocationObj, index) => {
      const vehicleDirection = (
        (vehicleLocationObj && getAttribute(vehicleLocationObj, 'gpsHeading') !== 360 && getAttribute(vehicleLocationObj, 'gpsHeading'))
        || (vehicleLocationObj && getAttribute(vehicleLocationObj, 'altGpsHeading'))
        || ((vehicleLocationObj && getAttribute(vehicleLocationObj, 'course')))
      );
      const featureProperties = {};
      featureProperties['rotate'] = vehicleDirection - 90;
      return (
        <Feature
          key={vehicleLocationObj.id + index}
          properties={featureProperties}
          onMouseEnter={() => props.setGPSPointPopup(true, { vehicleLocation: vehicleLocationObj })}
          onMouseLeave={() => props.setGPSPointPopup(false, { vehicleLocation: vehicleLocationObj })}
          coordinates={[getAttribute(vehicleLocationObj, 'location').longitude, getAttribute(vehicleLocationObj, 'location').latitude]}
        />
      );
    });

  const vehicleLocationObjNoBearingFeatures = props.vehicleLocationArr
    .filter((vehicleLocationObj) => (getAttribute(vehicleLocationObj, 'gpsHeading') === undefined && getAttribute(vehicleLocationObj, 'course') === undefined))
    .map((vehicleLocationObj, index) => {
      return (
        <Feature
          key={vehicleLocationObj.id + index}
          onMouseEnter={() => props.setGPSPointPopup(true, { vehicleLocation: vehicleLocationObj })}
          onMouseLeave={() => props.setGPSPointPopup(false, { vehicleLocation: vehicleLocationObj })}
          coordinates={[getAttribute(vehicleLocationObj, 'location').longitude, getAttribute(vehicleLocationObj, 'location').latitude]}
        />
      );
    });

  return (
    <div>
      <Layer
        type='symbol'
        id='gps-points'
        images={props.directionVehicleGreenIcon}
        layout={{
          'icon-image': 'directionVehicleGreenIcon',
          'icon-size': 0.5,
          'icon-rotate': ['get', 'rotate'],
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'text-field': ['format', ['get', 'title'], { 'font-scale': 0.7 }],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 0.6],
          'text-anchor': 'top'
        }}
        paint={
          {
            'icon-opacity': props.isGPSReplay ? 0.45 : 1,
            'text-color': ['get', 'color'],
            "text-halo-color": "#e1e1e1",
            "text-halo-width": "10px",
            "text-halo-width": 2
          }
        }
      >
        {vehicleLocationObjWithBearingFeatures}
      </Layer>
      <Layer
        type="circle"
        id="gps-points-nogpsheading"
        paint={{
          'circle-radius': 3,
          'circle-color': props && props.colour,
          'circle-blur': 0.2,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#364554',
          'circle-stroke-opacity': 0.8,
        }}
      >
        {vehicleLocationObjNoBearingFeatures}
      </Layer>
    </div>
  );
}

GPSPointHistory.propTypes = {
  vehicleLocationArr: PropTypes.array.isRequired,
  colour: PropTypes.string,
};

export default GPSPointHistory;
