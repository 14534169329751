import React from 'react';
import moment from 'moment-timezone';
import history from 'sbHistory';
import PropTypes from 'prop-types';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';

// API
import { getCurrentUser, getAttribute } from 'api/Parse';
import * as ELD from 'api/ELD';
import * as IFTA from 'api/IFTA';
import * as Getters from 'api/Getters';
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL';
import Button from 'sbCore/Button/Button';

// Modules
import CircularProgress from 'material-ui/CircularProgress';

// Components
import Title from 'components/Shared/Title/Title';
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import SubNavigationBar from 'components/Shared/SubNavigationBar/SubNavigationBar';

import ListSelectField from 'components/ListSelectField/view/ListSelectField';

import TaxReportFleetTotalsTable from 'components/IFTARoute/container/TaxReportFleetTotalsTable';
import TaxReportVehicleTotalsSummaryTable from 'components/IFTARoute/container/TaxReportVehicleTotalsSummaryTable';

// Lists
import { Months } from 'api/Lists/Months';

// Tax Rates (Static declaration is required at compile-time)
const Q1_2020 = require('assets/taxRates/1Q2020.json');
const Q2_2020 = require('assets/taxRates/2Q2020.json');
const Q3_2020 = require('assets/taxRates/3Q2020.json');
const Q4_2020 = require('assets/taxRates/4Q2020.json');
const Q1_2021 = require('assets/taxRates/1Q2021.json');
const Q2_2021 = require('assets/taxRates/2Q2021.json');
const Q3_2021 = require('assets/taxRates/3Q2021.json');
// const Q4_2021 = require('assets/taxRates/4Q2021.json');

const taxRates = {
  2020: {
    1: Q1_2020,
    2: Q2_2020,
    3: Q3_2020,
    4: Q4_2020,
  },
  2021: {
    1: Q1_2021,
    2: Q2_2021,
    3: Q3_2021,
    // 4: Q4_2021,
  },
};

// De-stringify the tax year and quarter integers from const taxRates
const taxYears = Object.keys(taxRates).map(yearStr => parseInt(yearStr, 10));
const taxQuarters = {};
Object.keys(taxRates).forEach(year => {
  // For each year get all the keys of it's object (the quarters) and cast to integer
  taxQuarters[year] = Object.keys(taxRates[year]).map(quarterInt => parseInt(quarterInt, 10));
});

/**
 * Contains the IFTA Monthly Mileage / IFTA Tax Report
 * Copied from the **IFTARouteLayout** with some unneede messages and tabs deleted
 * Defaults to using IFTARoute_Beta
 * Uses new "TaxReport{Original_Name}" components which copy the original subcomponents in the IFTARouteLayout,
 *   their changes include adding selectors for currency/fuel and changing the colums of the tables.
 */
class IFTAReportLayout extends React.Component {
  constructor(props) {
    super(props);
    const latestYear = taxYears[taxYears.length - 1];
    const latestQuarter = taxQuarters[latestYear][taxQuarters[latestYear].length - 1];
    this.state = {
      selected: false,
      isLoading: false,
      routeToNewIFTA: true,
      displayEndDate: undefined,
      displayStartDate: undefined,
      vehicles: {
        vehicleMileagesArr: [],
        showTable: false,
        vehicleArr: [],
        selectedVehicleMileageObj: null,
        selectedVehicleUnitId: null,
      },
      filter: {
        startMonth: moment().subtract(1, 'months').format('MMMM'),
        startYear: parseInt(moment().subtract(1, 'months').format('YYYY'), 10),
        endMonth: moment().subtract(1, 'months').format('MMMM'),
        endYear: parseInt(moment().subtract(1, 'months').format('YYYY'), 10),
      },
      quarterFilter: {
        year: latestYear,
        quarter: latestQuarter,
      },
      currentQuarter: taxRates[latestYear][latestQuarter],
    };
    this.getIFTAData = this.getIFTAData.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.selectActiveVehicle = this.selectActiveVehicle.bind(this);
    this.selectIFTAQuarter = this.selectIFTAQuarter.bind(this);
  }

  async getIFTAData(fuelCardTransactionsByVehicle, resetViews) {
    const { state } = this;

    // Determine which quarter the quarter or month selected falls in
    const selectedYear = state.filter.startYear;
    const selectedQuarter = Math.floor(Months.indexOf(state.filter.startMonth) / 3) + 1;

    // Use original dateStart and dateEnd values to display correct information to client
    let dateStart = moment().month(state.filter.startMonth).startOf('month').year(state.filter.startYear)
      .toDate();
    let dateEnd = moment().month(state.filter.endMonth).endOf('month').year(state.filter.endYear)
      .toDate();

    this.setState({
      isLoading: true,
      displayStartDate: dateStart,
      displayEndDate: dateEnd,
      currentQuarter: taxRates[selectedYear][selectedQuarter]
    });

    const currentUser = await getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

    // Obtain dispatcher timezone
    const dispatcher = await Getters.getCurrentDispatcher();
    const dispatcherStartTimezoneObject = ELD.getELDDailyCertificationIntervalFromDriverTZ(dispatcher, dateStart);
    const dispatcherEndTimezoneObject = ELD.getELDDailyCertificationIntervalFromDriverTZ(dispatcher, dateEnd);

    // Update dateStart and dateEnd values with timezone adjusted dates
    dateStart = dispatcherStartTimezoneObject.dayOf;
    dateEnd = dispatcherEndTimezoneObject.dayAfter;

    // vehicleMileageObj = { unitId: string, iftaRouteArr: array, stateProvinceMileages: [ { stateProvince (string): totalKm (number) }] }
    const vehicleArr = await IFTA.getIFTAVehicles(dateStart, true);
    const vehicleUnitIdArr = vehicleArr.map((vehicleObj) => vehicleObj.get('unitId'));
    const vehicleMileagesArr = [];
    const promiseArr = [];

    const getVehicleMileagesObj = async (unitId) => {
      const queryNewIfta = state.routeToNewIFTA;
      let iftaRouteArr;

      iftaRouteArr = await IFTA.getIFTARoutesForVehicle(unitId, dateStart, dateEnd, queryNewIfta);
      return vehicleMileagesArr.push({ unitId, iftaRouteArr });
    };

    for (let i = 0; i < vehicleUnitIdArr.length; i++) {
      const vehicleUnitId = vehicleUnitIdArr[i];
      promiseArr.push(getVehicleMileagesObj(vehicleUnitId));
    }

    Promise.all(promiseArr).then(() => {
      this.setState({
        isLoading: false,
        dateStart,
        dateEnd,
        vehicles: {
          ...this.state.vehicles,
          vehicleMileagesArr,
          vehicleArr,
        }
      }, () => {
        if (this.state.vehicles.selectedVehicleUnitId) this.selectActiveVehicle(this.state.vehicles.selectedVehicleUnitId);
      });
    });
  }

  refreshState(customStateUpdates = {}) {
    const { props } = this;
    const { location } = props;

    const queryStrObj = getDeserialized(location.search).params;
    const dateStart = (location && getQueryParameter(location.search, 'dateStart')) && moment(getQueryParameter(location.search, 'dateStart'), 'YYYYMMDD');
    const dateEnd = (location && getQueryParameter(location.search, 'dateEnd')) && moment(getQueryParameter(location.search, 'dateEnd'), 'YYYYMMDD');

    if (dateStart && dateEnd) {
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          startMonth: moment(dateStart).format('MMMM'),
          startYear: parseInt(moment(dateStart).format('YYYY')),
          endMonth: moment(dateEnd).format('MMMM'),
          endYear: parseInt(moment(dateEnd).format('YYYY')),
        },
        ...customStateUpdates,
      }, () => {
        this.getIFTAData().then(() => {
          if (queryStrObj.vehicle) this.selectActiveVehicle(queryStrObj.vehicle);
        });
      });
    }
  }

  selectActiveVehicle(unitId) {
    const { state } = this;

    const newState = { ...this.state };
    let selectedVehicleMileageObj;
    const vehicleMileagesArr = state.vehicles.vehicleMileagesArr;

    for (let i = 0; i < vehicleMileagesArr.length; i++) {
      if (vehicleMileagesArr[i].unitId === unitId) {
        selectedVehicleMileageObj = vehicleMileagesArr[i];
        break;
      }
    }

    newState.vehicles.selectedVehicleMileageObj = selectedVehicleMileageObj;
    newState.vehicles.selectedVehicleUnitId = unitId;
    newState.vehicles.showTable = true;
    this.setState(newState);
  }

  async selectIFTAQuarter() {
    // TODO: Add some checks
    const { state } = this;
    this.setState(
      {
        filter: {
          startYear: state.quarterFilter.year,
          endYear: state.quarterFilter.year,
          startMonth: Months[(state.quarterFilter.quarter - 1) * 3], // 0-index & scale to quarter size (3mths)
          endMonth: Months[((state.quarterFilter.quarter - 1) * 3) + 2], // repeat ^ and add 2 months to reach last month
        },
      },
      async () => { await this.getIFTAData(); },
    );
  }

  render() {
    const { props, state } = this;

    const title = (<span style={{ position: 'relative' }}> IFTA Reports </span>);
    const tempView = props.location ? getQueryParameter(props.location.search, 'p') : undefined;
    const queryStrObj = getDeserialized(props.location.search).params;

    const navItems = [
      {
        name: 'Vehicle Mileages',
        isActive: tempView === 'vehicleTotals',
        to: getSerialized({ ...queryStrObj, p: 'vehicleTotals' }).query,
      },
      {
        name: 'Total Fleet Mileage',
        isActive: tempView === 'fleetTotals',
        to: getSerialized({ ...queryStrObj, p: 'fleetTotals' }).query,
      },
    ];

    return (
      <MDBContainer className={`px-7 ${props.styles.container}`} fluid>
        {/* Title */}
        <MDBRow>
          <MDBCol>
            <Title title={title} leftColumnStyle={props.styles.titleLeftColumnStyle} />
          </MDBCol>
        </MDBRow>

        {/* Month Selector */}
        <MDBRow className={`${props.styles.iftaDataOptionsContainer}`}>
          <MDBCol className="d-flex">
            <div className="mt-auto mb-3" style={{ display: 'inline-block', marginLeft: '0em', marginRight: '1em', fontWeight: '600' }}>
              Monthly Tax Report
            </div>
            <ListSelectField
              onChange={(e, index, month) => this.setState({ filter: { ...state.filter, startMonth: month, endMonth: month } })}
              floatingLabelText="Month"
              value={state.filter.startMonth}
              maxHeight={200}
              list={Months}
              style={{ width: '13em', marginLeft: '1em', marginRight: '1em' }}
            />
            <ListSelectField
              onChange={(e, index, year) => this.setState({ filter: { ...state.filter, startYear: year, endYear: year } })}
              floatingLabelText="Year"
              value={state.filter.startYear}
              maxHeight={200}
              list={taxYears}
              style={{ width: '6em', marginRight: '1em' }}
            />

            <div className="mt-auto mb-2" style={{ display: 'inline-block' }}>
              <MDBBtn
                size="sm"
                onClick={async () => await this.getIFTAData()}
                disabled={state.isLoading}
                style={{ fontWeight: '500' }}
                color="primary"
              >LOAD MONTHLY MILES
              </MDBBtn>
            </div>

            {state.selected && (
              <div className="mt-auto mb-2" style={{ display: 'inline-block' }}>
                <MDBBtn
                  size="sm"
                  onClick={() => {
                    const queryStrObj = getDeserialized(props.location.search).params;
                    queryStrObj.vehicle = undefined;
                    queryStrObj.driver = undefined;
                    history.push({ search: getSerialized(queryStrObj).query });
                  }}
                  color="secondary"
                  style={{ fontWeight: '500' }}
                >
                  BACK
                </MDBBtn>
              </div>
            )}
          </MDBCol>
        </MDBRow>

        {/* Quarter Selector */}
        <MDBRow className={`${props.styles.iftaDataOptionsContainer}`}>
          <MDBCol className="d-flex">
            <div className="mt-auto mb-3" style={{ display: 'inline-block', marginLeft: '0em', marginRight: '1em', fontWeight: '600' }}>
              Quarterly Tax Report
            </div>

            <ListSelectField
              onChange={(e, index, quarter) => this.setState({ quarterFilter: { ...state.quarterFilter, quarter } })}
              floatingLabelText="Quarter"
              value={state.quarterFilter.quarter}
              maxHeight={200}
              list={state.quarterFilter.year ? taxQuarters[state.quarterFilter.year] : [1, 2, 3, 4]}
              style={{ width: '13em', marginLeft: '1em', marginRight: '1em' }}
            />
            <ListSelectField
              onChange={(e, index, year) => this.setState({ quarterFilter: { ...state.quarterFilter, year } })}
              floatingLabelText="Year"
              value={state.quarterFilter.year}
              maxHeight={200}
              list={taxYears}
              style={{ width: '6em', marginRight: '1em' }}
            />

            <div className="mt-auto mb-2" style={{ display: 'inline-block' }}>
              <MDBBtn
                size="sm"
                onClick={async () => await this.selectIFTAQuarter()}
                disabled={state.isLoading}
                style={{ fontWeight: '500' }}
                color="primary"
              >LOAD QUARTER MILES
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>

        {/* IFTA Warning */}
        {!state.dateEnd && (
          <SBBlock
            title={"Switchboard processes IFTA vehicle mileages up to 10 days before today's date."}
            header="If you are generating reports for the previous month, make sure you generate them on the 11th of this month to get the most updated mileages."
            warning
          />
        )}

        {/* IFTA Loading */}
        {state.isLoading && (
          <MDBRow>
            <MDBCol style={{ textAlign: 'center' }}>
              <CircularProgress />
            </MDBCol>
          </MDBRow>
        )}

        {/* IFTA Tables */}
        {!state.isLoading && (state.vehicles.vehicleMileagesArr.length !== 0 && !state.vehicles.selectedVehicleMileageObj) && (
          <div>
            <MDBRow>
              <MDBCol>
                <Title
                  title={`IFTA Mileages for ${state.displayStartDate && moment(state.displayStartDate).format('MMMM YYYY')} to ${state.displayEndDate && moment(state.displayEndDate).format('MMMM YYYY')}`}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>

              </MDBCol>
            </MDBRow>

            {!state.selected && (
              <SubNavigationBar navItems={navItems} />
            )}
            {tempView === 'fleetTotals' && (
              <TaxReportFleetTotalsTable
                vehicleMileagesArr={state.vehicles.vehicleMileagesArr.sort((a, b) => a.unitId - b.unitId)}
                dateStart={state.dateStart}
                dateEnd={state.dateEnd}
                displayStartDate={this.state.displayStartDate}
                displayEndDate={this.state.displayEndDate}
                taxRates={state.currentQuarter}
              />
            )}
            {tempView === 'vehicleTotals' && (
              <TaxReportVehicleTotalsSummaryTable
                vehicleMileagesArr={this.state.vehicles.vehicleMileagesArr.sort((a, b) => a.unitId - b.unitId)}
                dateStart={this.state.dateStart}
                dateEnd={this.state.dateEnd}
                displayStartDate={this.state.displayStartDate}
                displayEndDate={this.state.displayEndDate}
                routeToNewIFTA={this.state.routeToNewIFTA}
                location={this.props.location}
                taxRates={state.currentQuarter}
              />
            )}
          </div>
        )}
      </MDBContainer>
    );
  }
}

IFTAReportLayout.propTypes = {
  styles: PropTypes.any,
  location: PropTypes.instanceOf(Object),
};

export default IFTAReportLayout;
