import * as ActionConstants from 'actions/ActionConstants';

const initialState = {
};

export function DocumentLayout(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
