import React from 'react';
import PropTypes from 'prop-types';

// Components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import InputText from 'sbCore/InputText/InputText';

// CSS
import styles from './AddNewCompanyUserForm.module.scss';

function AddNewCompanyUserForm(props) {
  return (
    <div className={styles.body}>
      <table className={styles.table}><tbody>
        <tr>
          <td>
            <div className={styles.formGroup}>
              <InputLabel>First Name</InputLabel>
              <InputText
                type="text"
                className="p-inputtext-sm p-d-block "
                value={props.user.firstName}
                onChange={(e) => props.handleFormChange('firstName', e.target.value)}
              />
              <InputLabel>Last Name</InputLabel>
              <InputText
                type="text"
                className="p-inputtext-sm p-d-block "
                value={props.user.lastName}
                onChange={(e) => props.handleFormChange('lastName', e.target.value)}
              />
              {/* <label className={styles.label}>First Name<span className={`${styles.required} notranslate`}>&nbsp;*</span></label>
              <br />
              <input className="input" selected={props.user.firstName} placeholder="....." onChange={(e) => props.handleFormChange('firstName', e.target.value)} />
              <br />
              <label className={styles.label}>Last Name<span className={`${styles.required} notranslate`}>&nbsp;*</span></label>
              <br />
              <input className="input" selected={props.user.lastName} placeholder="....." onChange={(e) => props.handleFormChange('lastName', e.target.value)} /> */}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className={styles.formGroup}>
              <InputLabel>Email Address</InputLabel>
              <InputText
                type="text"
                className="p-inputtext-sm p-d-block "
                value={props.user.emailAddress}
                onChange={(e) => props.handleFormChange('emailAddress', e.target.value)}
              />
              <InputLabel>Confirm Email Address</InputLabel>
              <InputText
                type="text"
                className="p-inputtext-sm p-d-block "
                value={props.user.emailAddress2}
                onChange={(e) => props.handleFormChange('emailAddress2', e.target.value)}
              />
              {/* <label className={styles.label}>Email Address<span className={`${styles.required} notranslate`}>&nbsp;*</span></label>
              <br />
              <input className="input" selected={props.user.emailAddress} placeholder="....." onChange={(e) => props.handleFormChange('emailAddress', e.target.value)} />
              <br />
              <label className={styles.label}>Confirm Email Address<span className={`${styles.required} notranslate`}>&nbsp;*</span></label>
              <br />
              <input className="input" selected={props.user.emailAddress2} placeholder="....." onChange={(e) => props.handleFormChange('emailAddress2', e.target.value)} /> */}
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div className={styles.formGroup}>
              <InputLabel>Password</InputLabel>
              <InputText
                type="password"
                className="p-inputtext-sm p-d-block "
                value={props.user.password}
                onChange={(e) => props.handleFormChange('password', e.target.value)}
              />
              <InputLabel>Confirm Password</InputLabel>
              <InputText
                type="password"
                className="p-inputtext-sm p-d-block "
                value={props.user.password2}
                onChange={(e) => props.handleFormChange('password2', e.target.value)}
              />
              {/* <label className={styles.label}>Password<span className={`${styles.required} notranslate`}>&nbsp;*</span></label>
              <br />
              <input className="input" type="password" selected={props.user.password} placeholder="....." onChange={(e) => props.handleFormChange('password', e.target.value)} />
              <br />
              <label className={styles.label}>Confirm Password<span className={`${styles.required} notranslate`}>&nbsp;*</span></label>
              <br />
              <input className="input" type="password" selected={props.user.password2} placeholder="....." onChange={(e) => props.handleFormChange('password2', e.target.value)} /> */}
            </div>
          </td>
        </tr>
      </tbody></table>
    </div>
  );
}

AddNewCompanyUserForm.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default AddNewCompanyUserForm;
