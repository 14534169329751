import React, { useRef } from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// API
import { updateDispatchTransfer, addDispatchTransfer } from 'api/Dispatch/DispatchTransfer';
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { getAttribute, getObjectById, getCompanyReadWriteACL, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, cloneRecord, updateRecord, addRecord } from 'sb-csapi/dist/AAPI';
import { getDispatchItemTransfersByJob } from 'api/Dispatch/DispatchShipment';
import { updateInvoiceOutdated } from 'api/Dispatch/DispatchJob';

// Context
import DispatchJobLayoutContext from 'contexts/DispatchJobLayoutContext';

// Components
import SBCard from 'components/Shared/SBCard/SBCard';
import DispatchFreightSummary from 'components/Dispatch/DispatchFreightSummary/DispatchFreightSummary';
import DispatchOrganizationAutocomplete from 'components/Dispatch/DispatchOrganizationAutocomplete/DispatchOrganizationAutocomplete';
import DispatchBorderConnect from 'components/Dispatch/DispatchBorderConnect/DispatchBorderConnect';
import DispatchReferenceNumbersContainer from 'components/Dispatch/DispatchReferenceNumbers/DispatchReferenceNumbersContainer/DispatchReferenceNumbersContainer';
import DispatchShipmentNotes from 'components/Dispatch/DispatchShipmentNotes/DispatchShipmentNotes';

// sbCore
import Calendar from 'sbCore/Calendar/Calendar';
import Checkbox from 'sbCore/Checkbox/Checkbox';
import Divider from 'sbCore/Divider/Divider';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import TimePicker from 'sbCore/TimePicker/TimePicker';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import TimezoneDropdown from 'sbCore/TimezoneDropdown/TimezoneDropdown';

// SBObjects
import DispatchTransfer from 'sbObjects/DispatchTransfer';
import Address from 'sbObjects/Address';

// enums
import { Organization } from 'sb-csapi/dist/enums/Dispatch/Organization';
import { Status } from 'sb-csapi/dist/enums/Dispatch/Transfer';

// style
import './styles.scss';
import { Button } from '@material-ui/core';

class DispatchShipmentDetail extends React.Component {
  static contextType = DispatchJobLayoutContext;

  constructor(props) {
    super(props);
    this.state = {
      dispatchJob: undefined,
      selectedDispatchTransfer: undefined,
      selectedPickupDate: undefined,
      selectedDropoffDate: undefined,
      selectedPickupTime: undefined,
      selectedDropoffTime: undefined,
      isInternalDispatchTransfer: undefined,
      dispatchTransferChanges: {
        pickupDateTime: undefined,
        dropoffDateTime: undefined,
        pickupAppointmentStatus: 0,
        dropoffAppointmentStatus: 0,
      },
      isPickupDateTimeInvalid: false,
      isDropoffDateTimeInvalid: false,
    };
    this.refreshState = this.refreshState.bind(this);
    this.handleDispatchTransferChange = this.handleDispatchTransferChange.bind(this);
    this.handleAppointmentReqCheck = this.handleAppointmentReqCheck.bind(this);
    this.addOrganization = this.addOrganization.bind(this);
    this.removeOrganization = this.removeOrganization.bind(this);
    this.handleDispatchTransferUpdate = this.handleDispatchTransferUpdate.bind(this);
    this.formatSelectedDateTime = this.formatSelectedDateTime.bind(this);
    this.isValidTime = this.isValidTime.bind(this);
    this.updateDispatchTransferAddress = this.updateDispatchTransferAddress.bind(this);
    this.isDateTimePastCurrent = this.isDateTimePastCurrent.bind(this);
    this.getIntelligentStatusInt = this.getIntelligentStatusInt.bind(this);
  }


  async componentDidMount() {
    await this.refreshState();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      await this.refreshState();
    }
  }

  async refreshState() {
    const sessionToken = getCurrentUserSessionToken();
    const dispatchJob = await getObjectById({ sessionToken }, 'DispatchJob', this.props.dispatchJobObjectId);

    let selectedDispatchTransfer = this.props.dispatchTransfer;
    if (!selectedDispatchTransfer) {
      const selectedDispatchTransfers = await getDispatchItemTransfersByJob(this.props.dispatchJobObjectId, undefined, undefined);
      selectedDispatchTransfer = selectedDispatchTransfers[0];
    }
    const selectedPickupDate = getAttribute(selectedDispatchTransfer, 'pickupDateTime', true);
    const selectedDropoffDate = getAttribute(selectedDispatchTransfer, 'dropoffDateTime', true);

    const selectedPickupTime = this.formatSelectedDateTime(selectedPickupDate);
    const selectedDropoffTime = this.formatSelectedDateTime(selectedDropoffDate);

    const isInternalDispatchTransfer = getAttribute(selectedDispatchTransfer, 'internal');

    this.setState({ ...this.state, dispatchJob, selectedDispatchTransfer, selectedPickupDate, selectedDropoffDate, selectedPickupTime, selectedDropoffTime, isInternalDispatchTransfer });

  }

  async handleDispatchTransferChange(attribute, value, isTime) {
    const dispatchTransferChanges = { ...this.state.dispatchTransferChanges };
    let dispatchTransferUpdateAttr = {};
    if ((attribute === 'shipperDispatchOrganization') || (attribute === 'consigneeDispatchOrganization')) {
      // create an address record clone of the dispatch organization's address for the dispatch transfer
      const address = getAttribute(value, 'address', true);

      let addressCopy;
      if (address) {
        addressCopy = cloneRecord(address);
      }

      // set the dispatch transfer's shipperAddress to the address copy if value exists, else set it to undefined
      if (attribute === 'shipperDispatchOrganization') {
        if (value) {
          dispatchTransferUpdateAttr.shipperAddress = addressCopy;
        } else {
          dispatchTransferUpdateAttr.shipperAddress = undefined;
        }
      }

      // set the dispatch transfer's consigneeAddress to the address copy if value exists, else set it to undefined
      if (attribute === 'consigneeDispatchOrganization') {
        if (value) {
          dispatchTransferUpdateAttr.consigneeAddress = addressCopy;
        } else {
          dispatchTransferUpdateAttr.consigneeAddress = undefined;
        }
      }

      dispatchTransferChanges[attribute] = value;
    }

    // Ensure that the transfer changes object isn't modified by unfinished values here
    if (value !== undefined && !isTime && attribute !== 'pickupDateTime' && attribute !== 'dropoffDateTime') {
      dispatchTransferChanges[attribute] = value;
    }

    // Note: The string value that PrimeReact's calendar component passes back looks like this:
    // Ex: __-__-____, 14-10-____, etc.
    let isPickupDateTimeInvalid = this.state.isPickupDateTimeInvalid;
    if (attribute === 'pickupDateTime') {
      const pickupDateTime = getAttribute(this.state.selectedDispatchTransfer, 'pickupDateTime', true);
      let pickupTime = this.state.selectedPickupTime;
      let isSameTime = false;
      if (value && pickupDateTime && isTime) {
        // Updates date if hours/minutes (time) is updated
        if (!this.isValidTime(value)) {
          value = '00:00';
        }
        const formattedTime = value;
        const dateString = moment(pickupDateTime).format('DD-MM-YYYY');
        const timeString = formattedTime.replace(';', '-');
        const dateTime = `${dateString} ${timeString}`;

        const newPickupDateTime = moment(dateTime, 'DD-MM-YYYY hh:mm').toDate();
        dispatchTransferChanges.pickupDateTime = newPickupDateTime;

        // Update status if required
        dispatchTransferUpdateAttr.status = this.getIntelligentStatusInt(newPickupDateTime);

      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        // Updates date if a valid date is past
        if (pickupTime) {
          // If a time currently exists, append it to the date
          pickupTime = pickupTime.substr(0, 2) + ':' + pickupTime.substr(2);
          const dateTime = `${value} ${pickupTime}`;
          value = dateTime;
          dispatchTransferChanges[attribute] = moment(dateTime, 'DD-MM-YYYY hh:mm').toDate();
          isSameTime = true;
        } else {
          // Else update the transfer with the newly added date
          dispatchTransferChanges[attribute] = moment(value, 'DD-MM-YYYY').toDate();
        }

        // Update status if required
        const pickupDateTime = moment(value, 'DD-MM-YYYY hh:mm').toDate();
        dispatchTransferUpdateAttr.status = this.getIntelligentStatusInt(pickupDateTime);

        isPickupDateTimeInvalid = false;
      } else if (!moment(value, 'DD-MM-YYYY', true).isValid() && !value.includes('_') && value !== '') {
        // Displays an error message if an invalid date is given (a complete value shouldn't have any underscores)
        isPickupDateTimeInvalid = true;
      } else if (!(/\d/.test(value))) {
        // If the value is empty (no digits are contained), set the state to nothing
        dispatchTransferChanges[attribute] = undefined;
        isPickupDateTimeInvalid = false;

        // Update status if required
        dispatchTransferUpdateAttr.status = this.getIntelligentStatusInt(null);
      }
      this.setState({ ...this.state, selectedPickupDate: dispatchTransferChanges.pickupDateTime, selectedPickupTime: isSameTime ? pickupTime : this.formatSelectedDateTime(dispatchTransferChanges.pickupDateTime), isPickupDateTimeInvalid });
      // kept for future to prevent dates colliding
      // if (!dispatchTransferChanges.pickupDateTime && getAttribute(getAttribute(this.state.dispatchTransfer, 'pickupDispatchAction', true), 'dateTimeUTC', true)){
      //   dispatchTransferChanges['pickupDateTime'] = getAttribute(getAttribute(this.state.dispatchTransfer, 'pickupDispatchAction'), 'dateTimeUTC', true);
      // }
      // if (value && dispatchTransferChanges.destinationDate){
      //   dropoffWarning = dispatchTransferChanges.originDate.valueOf() > dispatchTransferChanges.destinationDate.valueOf() ? true : false;
      // }
    }

    let isDropoffDateTimeInvalid = this.state.isDropoffDateTimeInvalid;
    if (attribute === 'dropoffDateTime') {
      const dropoffDateTime = getAttribute(this.state.selectedDispatchTransfer, 'dropoffDateTime', true);
      let dropoffTime = this.state.selectedDropoffTime;
      let isSameTime = false;
      if (value && dropoffDateTime && isTime) {
        // Updates date if hours/minutes (time) is updated
        if (!this.isValidTime(value)) {
          value = '00:00';
        }
        const formattedTime = value;
        const dateString = moment(dropoffDateTime).format('DD-MM-YYYY');
        const timeString = formattedTime.replace(';', '-');
        const dateTime = `${dateString} ${timeString}`;

        const newDropoffDateTime = moment(dateTime, 'DD-MM-YYYY hh:mm').toDate();
        dispatchTransferChanges.dropoffDateTime = newDropoffDateTime;

        // Update status if required
        dispatchTransferUpdateAttr.status = this.getIntelligentStatusInt(undefined, newDropoffDateTime);

      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        // Updates date if a valid date is past
        if (dropoffTime) {
          // If a time currently exists, append it to the date
          dropoffTime = dropoffTime.substr(0, 2) + ':' + dropoffTime.substr(2);
          const dateTime = `${value} ${dropoffTime}`;
          value = dateTime;
          dispatchTransferChanges[attribute] = moment(dateTime, 'DD-MM-YYYY hh:mm').toDate();
          isSameTime = true;
        } else {
          dispatchTransferChanges[attribute] = moment(value, 'DD-MM-YYYY').toDate();
        }

        // Update status if required
        const dropoffDateTime = moment(value, 'DD-MM-YYYY hh:mm').toDate();
        dispatchTransferUpdateAttr.status = this.getIntelligentStatusInt(undefined, dropoffDateTime);

        isDropoffDateTimeInvalid = false;
      } else if (!moment(value, 'DD-MM-YYYY', true).isValid() && !value.includes('_') && value !== '') {
        // Displays an error message if an invalid date is given (a complete value shouldn't have any underscores)
        isDropoffDateTimeInvalid = true;
      } else if (!(/\d/.test(value))) {
        // If the value is empty (no digits are contained), set the state to nothing
        dispatchTransferChanges[attribute] = undefined;
        isDropoffDateTimeInvalid = false;

        // Update status if required
        dispatchTransferUpdateAttr.status = this.getIntelligentStatusInt(undefined, null);
      }
      this.setState({ ...this.state, selectedDropoffDate: dispatchTransferChanges.dropoffDateTime, selectedDropoffTime: isSameTime ? dropoffTime : this.formatSelectedDateTime(dispatchTransferChanges.dropoffDateTime), isDropoffDateTimeInvalid });
      // kept for future to prevent dates colliding
      // if (!dispatchTransferChanges.dropoffDateTime && getAttribute(getAttribute(this.state.dispatchTransfer, 'dropoffDispatchAction', true), 'dateTimeUTC', true)){
      //   dispatchTransferChanges['dropoffDateTime'] = getAttribute(getAttribute(this.state.dispatchTransfer, 'dropoffDispatchAction'), 'dateTimeUTC', true);
      // }
      // if (value && dispatchTransferChanges.originDate){
      //   dropoffWarning = dispatchTransferChanges.originDate.valueOf() > dispatchTransferChanges.destinationDate.valueOf() ? true : false;
      // }
    }

    if (attribute === 'internal') {
      dispatchTransferUpdateAttr.internal = value;
      this.setState({ ...this.state, isInternalDispatchTransfer: value });
    }

    dispatchTransferUpdateAttr[attribute] = dispatchTransferChanges[attribute];

    if (this.state.selectedDispatchTransfer) {
      if ((attribute === 'pickupDateTime' && isPickupDateTimeInvalid) || (attribute === 'dropoffDateTime' && isDropoffDateTimeInvalid)) return;
      const updatedDispatchTransfer = await updateDispatchTransfer(this.state.selectedDispatchTransfer, undefined, dispatchTransferUpdateAttr, true);
      if (updatedDispatchTransfer) {
        await updateInvoiceOutdated(this.state.dispatchJob, true);
        await this.context.refreshJob();
      }
    }

    // kept for future for date warning
    // const disableSubmit = dropoffWarning ? dropoffWarning : !this.isFormValid(dispatchTransferChanges);
    // await this.setState({ ...this.state, dispatchTransferChanges, dropoffWarning, disableSubmit });
    this.setState({ ...this.state, dispatchTransferChanges });
  }

  async addOrganization(organization, attribute) {
    await this.handleDispatchTransferChange(attribute, organization);
    await this.handleDispatchTransferUpdate();
    // return await this.setState({ ...this.state, dispatchJob, selectedDispatchTransfer });
  }

  async removeOrganization(attribute) {
    // const dispatchJob = this.props.dispatchJob;
    // const selectedDispatchTransfer = this.state.selectedDispatchTransfer;
    // let tmpDispatchTransfer;
    // if (attribute === 'shipper') tmpDispatchTransfer = await updateRecord(selectedDispatchTransfer, { shipperDispatchOrganization: undefined }, true);
    // if (attribute === 'consignee') tmpDispatchTransfer = await updateRecord(selectedDispatchTransfer, { consigneeDispatchOrganization: undefined }, true);
    // return await this.setState({ ...this.state, selectedDispatchTransfer: tmpDispatchTransfer });
    await this.handleDispatchTransferChange(attribute, undefined);
    await this.handleDispatchTransferUpdate();
  }

  async handleAppointmentReqCheck(type) {
    let { selectedDispatchTransfer } = this.state;
    const activeReq = [1, 2];
    let appointmentValue;
    if (type === 'pickupAppointmentStatus') appointmentValue = activeReq.includes(getAttribute(selectedDispatchTransfer, 'pickupAppointmentStatus', true)) ? 0 : 1;
    if (type === 'dropoffAppointmentStatus') appointmentValue = activeReq.includes(getAttribute(selectedDispatchTransfer, 'dropoffAppointmentStatus', true)) ? 0 : 1;

    await this.handleDispatchTransferChange(type, appointmentValue);
  }

  async handleDispatchTransferUpdate() {
    const { state } = this;
    const { dispatchTransferChanges, selectedDispatchTransfer } = state;

    // if no existing dispatchTransfer, add new
    if (!selectedDispatchTransfer) {
      let dispatchTransferObject =
        new DispatchTransfer(
          undefined,
          this.state.dispatchJob,
          undefined,
          undefined,
          dispatchTransferChanges.shipperDispatchOrganization,
          dispatchTransferChanges.consigneeDispatchOrganization,
          dispatchTransferChanges.pickupDateTime,
          dispatchTransferChanges.dropoffDateTime,
          dispatchTransferChanges.pickupAppointmentStatus,
          dispatchTransferChanges.dropoffAppointmentStatus,
          undefined, undefined, undefined, undefined,
          getPDispatcherPropertyFromState('timezoneOffsetFromUTC') || 'America/Vancouver'
        );
      const dispatchTransfer = await addDispatchTransfer(dispatchTransferObject);
      await this.setState({ ...this.state, selectedDispatchTransfer: dispatchTransfer });
    }
    this.props.refreshState();
  }

  formatSelectedDateTime(selectedDate) {
    let selectedDateTime = moment(selectedDate).format('HHmm');
    if (!selectedDate) {
      selectedDateTime = '';
    }
    return selectedDateTime;
  }

  isValidTime(time) {
    let isValid = true;
    let hours = time.substring(0, 3);
    let minutes = time.substring(3);
    hours = parseInt(hours);
    minutes = parseInt(minutes);

    if (hours > 24 || minutes >= 60 || (hours === 24 && minutes > 0)) {
      isValid = false;
    }
    return isValid;
  }

  async updateDispatchTransferAddress(address, keyValueObj) {
    if (address) {
      // if we provided an address, we will modify it
      await updateRecord({ sessionToken: getCurrentUserSessionToken() }, address, keyValueObj, true);
    } else {
      // if we did not provide an address, we will create a new one
      await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'Address', keyValueObj, getCurrentUserCompanyObjectId());
    }
  }

  // Checks if the given date time is past the current date time
  isDateTimePastCurrent(dateTime) {
    const currentDateTime = moment();
    const dateTimeDifference = currentDateTime.diff(dateTime);
    const dateTimeDifferenceSign = Math.sign(dateTimeDifference);

    let isDateTimePast = false;
    if (dateTimeDifferenceSign === 1) {
      isDateTimePast = true;
    }

    return isDateTimePast;
  }

  // Updates the transfer status depending on transfer dates and returns an updated status
  getIntelligentStatusInt(pickupDateTime, dropoffDateTime) {
    let status = getAttribute(this.state.selectedDispatchTransfer, 'status', true);

    // If status has been manually modified, don't update the status
    const isStatusModified = getAttribute(this.state.selectedDispatchTransfer, 'isStatusModified', true);
    if (isStatusModified) {
      return status;
    }

    let isPickupDatePast;
    let isDropoffDatePast;

    // If pickupDateTime exists or is blank, check if its past the current date time
    if (pickupDateTime || pickupDateTime === null) {
      if (pickupDateTime) {
        const timezone = getAttribute(this.state.selectedDispatchTransfer, 'timezoneOffsetFromUTC') || moment.tz.guess();
        pickupDateTime = moment(pickupDateTime).tz(timezone).format('YYYY-MM-DD HH:mm');
        isPickupDatePast = this.isDateTimePastCurrent(pickupDateTime);
      }

      // Check if the record's dropoffDateTime is past the current date time
      let _dropoffDateTime = getAttribute(this.state.selectedDispatchTransfer, 'dropoffDateTime');
      if (_dropoffDateTime) {
        _dropoffDateTime = moment(_dropoffDateTime);
        isDropoffDatePast = this.isDateTimePastCurrent(_dropoffDateTime);
      }
    }

    // If dropoffDateTime exists or is blank, check if its past the current date time
    if (dropoffDateTime || dropoffDateTime === null) {
      if (dropoffDateTime) {
        const timezone = getAttribute(this.state.selectedDispatchTransfer, 'timezoneOffsetFromUTC') || moment.tz.guess();
        dropoffDateTime = moment(dropoffDateTime).tz(timezone).format('YYYY-MM-DD HH:mm');
        isDropoffDatePast = this.isDateTimePastCurrent(dropoffDateTime);
      }

      // Check if the record's pickupDateTime is past the current date time
      let _pickupDateTime = getAttribute(this.state.selectedDispatchTransfer, 'pickupDateTime');
      if (_pickupDateTime) {
        _pickupDateTime = moment(_pickupDateTime);
        isPickupDatePast = this.isDateTimePastCurrent(_pickupDateTime);
      }
    }

    // Update the status to delivered - presumed if dropoffDateTime is past the current date time, else
    // to in-transit if pickupDateTime is past the current date, else default to scheduled.
    if (isDropoffDatePast && (status !== Status.DELIVERED_PRESUMED.status)) {
      status = Status.DELIVERED_PRESUMED.status
    } else if (isPickupDatePast && (status !== Status.IN_TRANSIT.status) && !isDropoffDatePast) {
      status = Status.IN_TRANSIT.status;
    } else if (!isPickupDatePast && !isDropoffDatePast) {
      status = Status.SCHEDULED.status;
    }
    return status;
  }

  render() {
    const { props, state } = this;

    const shipperDispatchOrganization = getAttribute(state.selectedDispatchTransfer, 'shipperDispatchOrganization', true);
    const consigneeDispatchOrganization = getAttribute(state.selectedDispatchTransfer, 'consigneeDispatchOrganization', true);

    const shipperRequiredWarning = (
      !shipperDispatchOrganization && <InputLabel warning>* Required to add a dropoff</InputLabel>
    );

    const consigneeRequiredWarning = (
      !consigneeDispatchOrganization && <InputLabel warning>* Required to add a pickup</InputLabel>
    );

    const pickupDateRequiredWarning = (
      (state.isPickupDateTimeInvalid && <InputLabel error>* Pickup date is invalid</InputLabel>)
      || (!state.selectedPickupDate && <InputLabel warning>* Required to add a pickup time</InputLabel>)
    );

    const dropoffDateRequiredWarning = (
      (state.isDropoffDateTimeInvalid && <InputLabel error>* Dropoff date is invalid</InputLabel>)
      || (!state.selectedDropoffDate && <InputLabel warning>* Required to add a dropoff time</InputLabel>)
    );

    const selectedPickupDate = getAttribute(state.selectedDispatchTransfer, 'pickupDateTime', true);
    const selectedDropoffDate = getAttribute(state.selectedDispatchTransfer, 'dropoffDateTime', true);

    const appointmentSelectItems = [
      { label: 'Pending', value: 1 },
      { label: 'Confirmed', value: 2 },
    ];

    const pickupAppointmentStatus = getAttribute(state.selectedDispatchTransfer, 'pickupAppointmentStatus', true);
    const showPickupAppointmentDropdown = (
      (pickupAppointmentStatus === 1 || pickupAppointmentStatus === 2)
      && (
        <div className="appointment-dropdown">
          <Dropdown
            options={appointmentSelectItems}
            onChange={(e) => this.handleDispatchTransferChange('pickupAppointmentStatus', e.value)}
            value={pickupAppointmentStatus}
            showOnFocus
          />
        </div>
      )
    );

    const dropoffAppointmentStatus = getAttribute(state.selectedDispatchTransfer, 'dropoffAppointmentStatus', true);
    const showDropoffAppointmentDropdown = (
      (dropoffAppointmentStatus === 1 || dropoffAppointmentStatus === 2)
      && (
        <div className="appointment-dropdown">
          <Dropdown
            options={appointmentSelectItems}
            onChange={(e) => this.handleDispatchTransferChange('dropoffAppointmentStatus', e.value)}
            value={dropoffAppointmentStatus}
            showOnFocus
          />
        </div>
      )
    );

    const dispatchTransferShipperAddress = getAttribute(state.selectedDispatchTransfer, 'shipperAddress', true);
    const dispatchTransferConsigneeAddress = getAttribute(state.selectedDispatchTransfer, 'consigneeAddress', true);

    return (
      <React.Fragment>
        <SBCard className="dispatch-shipment-detail job-shipment-card py-4" isCollapsible={false} cardBodyStyle={{ maxHeight: 'none' }}>

          <div className="grid mt-3">

            <div className="col flex flex-row">
              <div className="flex">
                <div className="pr-3 font-semibold text-lg my-0 inline">Shipment Options: </div>
              </div>
              <div className="flex field-checkbox mb-0">
                <Checkbox
                  checked={state.isInternalDispatchTransfer}
                  onChange={(event) => this.handleDispatchTransferChange('internal', event.checked)}
                />
                <InputLabel className="internal-leg-label">Mark as Internal Shipment</InputLabel>
              </div>
            </div>

          </div>

          <Divider className="mt-2" />

          <div className="grid formgrid">

            <div className="col-3" style={{ paddingTop: '0.9em' }}>
              <div className="grid formgrid">
                <div className="col-12">
                  <DispatchOrganizationAutocomplete
                    className="p-inputtext-sm"
                    type={Organization.SHIPPER}
                    dispatchOrganization={shipperDispatchOrganization}
                    address={dispatchTransferShipperAddress}
                    onSaveAddress={async (address, keyValueObj) => await this.updateDispatchTransferAddress(address, keyValueObj)}
                    onSelectDispatchOrganization={(organization) => this.addOrganization(organization, 'shipperDispatchOrganization')}
                    warning
                    isLoading={!state.selectedDispatchTransfer}
                    allowAddDispatchOrganization
                  />
                  {shipperRequiredWarning}
                </div>

                <div className="col-12" style={{ paddingTop: '3em' }}>
                  <DispatchOrganizationAutocomplete
                    className="p-inputtext-sm"
                    type={Organization.CONSIGNEE}
                    dispatchOrganization={consigneeDispatchOrganization}
                    address={dispatchTransferConsigneeAddress}
                    onSaveAddress={async (address, keyValueObj) => await this.updateDispatchTransferAddress(address, keyValueObj)}
                    onSelectDispatchOrganization={(organization) => this.addOrganization(organization, 'consigneeDispatchOrganization')}
                    warning
                    isLoading={!state.selectedDispatchTransfer}
                    allowAddDispatchOrganization
                  />
                  {consigneeRequiredWarning}
                </div>
              </div>
            </div>

            <Divider layout="vertical" />

            <div className="col-8" style={{ fontSize: 'small', paddingTop: '1em' }}>
              {/* Pickup Date, Time, & Appointment Check */}
              <div className="p-fluid grid formgrid" style={{ paddingBottom: '1em' }}>
                <div className="col-4">
                  <InputLabel>Pickup Date</InputLabel>
                  <Calendar
                    className="p-inputtext-sm"
                    value={state.selectedPickupDate}
                    onFocus={(event) => event.nativeEvent?.target?.setSelectionRange(0, 0)}
                    onChange={(event) => this.handleDispatchTransferChange('pickupDateTime', event.originalEvent.target.value)}
                    mask="99-99-9999"
                    placeholder="dd-mm-yyyy"
                    dateFormat="dd-mm-yy"
                    showOnFocus={false}
                    warning={!state.selectedPickupDate}
                    error={state.isPickupDateTimeInvalid}
                    success={state.selectedPickupDate}
                    keepInvalid
                  />
                  {pickupDateRequiredWarning}
                </div>
                <div className="col-4">
                  <InputLabel>Pickup Time</InputLabel>
                  <TimePicker
                    className="p-inputtext-sm"
                    value={state.selectedPickupTime || ''}
                    onComplete={(time) => this.handleDispatchTransferChange('pickupDateTime', time.value, true)}
                    onFocus={() => this.setState({ selectedPickupTime: undefined })}
                    onBlur={() => this.setState({ selectedPickupTime: this.formatSelectedDateTime(selectedPickupDate) })}
                    warning={!state.selectedPickupDate}
                    success={state.selectedPickupDate}
                    disabled={!state.selectedPickupDate || state.isPickupDateTimeInvalid}
                  />
                </div>
                <div className="col-3">
                  <TimezoneDropdown
                    className="p-inputtext-sm"
                    timezone={getAttribute(state.selectedDispatchTransfer, 'timezoneOffsetFromUTC', true)}
                    onSelect={(timezoneOffsetFromUTC) => this.handleDispatchTransferChange('timezoneOffsetFromUTC', timezoneOffsetFromUTC)}
                    warning={!(!!getAttribute(state.selectedDispatchTransfer, 'timezoneOffsetFromUTC', true))}
                    success={getAttribute(state.selectedDispatchTransfer, 'timezoneOffsetFromUTC', true)}
                    showOnFocus
                  />
                </div>
                <div className="col-3">
                  <div className="field grid" style={{ paddingTop: '2em' }}>
                    <div className="appointment-checkbox">
                      <Checkbox
                        inputId="shipperappointment"
                        checked={!pickupAppointmentStatus ? false : true}
                        onChange={async () => await this.handleAppointmentReqCheck('pickupAppointmentStatus')}
                      />
                    </div>
                    <div className="appointment-label">
                      <InputLabel>Appointment Required</InputLabel>
                    </div>
                    {showPickupAppointmentDropdown}
                  </div>
                </div>
              </div>

              {/* Dropoff Date, Time, & Appointment Check */}
              <div className="p-fluid grid formgrid">
                <div className="col-4">
                  <InputLabel>Dropoff Date</InputLabel>
                  <Calendar
                    className="p-inputtext-sm"
                    value={state.selectedDropoffDate}
                    onFocus={(event) => event.nativeEvent?.target?.setSelectionRange(0, 0)}
                    onChange={(event) => this.handleDispatchTransferChange('dropoffDateTime', event.originalEvent.target.value)}
                    mask="99-99-9999"
                    placeholder="dd-mm-yyyy"
                    dateFormat="dd-mm-yy"
                    showOnFocus={false}
                    warning={!state.selectedDropoffDate}
                    error={state.isDropoffDateTimeInvalid}
                    success={state.selectedDropoffDate}
                    keepInvalid
                  />
                  {dropoffDateRequiredWarning}
                </div>
                <div className="col-4">
                  <InputLabel>Dropoff Time</InputLabel>
                  <TimePicker
                    className="p-inputtext-sm"
                    value={state.selectedDropoffTime || ''}
                    onComplete={(time) => this.handleDispatchTransferChange('dropoffDateTime', time.value, true)}
                    onFocus={() => this.setState({ selectedDropoffTime: undefined })}
                    onBlur={() => this.setState({ selectedDropoffTime: this.formatSelectedDateTime(selectedDropoffDate) })}
                    warning={!state.selectedDropoffDate}
                    success={state.selectedDropoffDate}
                    disabled={!state.selectedDropoffDate || state.isDropoffDateTimeInvalid}
                  />
                </div>
                <div className="col-3">
                  <TimezoneDropdown
                    className="p-inputtext-sm"
                    timezone={getAttribute(state.selectedDispatchTransfer, 'timezoneOffsetFromUTC', true)}
                    onSelect={(timezoneOffsetFromUTC) => this.handleDispatchTransferChange('timezoneOffsetFromUTC', timezoneOffsetFromUTC)}
                    warning={!(!!getAttribute(state.selectedDispatchTransfer, 'timezoneOffsetFromUTC', true))}
                    success={getAttribute(state.selectedDispatchTransfer, 'timezoneOffsetFromUTC', true)}
                  />
                </div>
                <div className="col-3">
                  <div className="field grid" style={{ paddingTop: '2em' }}>
                    <div className="appointment-checkbox">
                      <Checkbox
                        inputId="consigneeappointment"
                        checked={!dropoffAppointmentStatus ? false : true}
                        onChange={async () => await this.handleAppointmentReqCheck('dropoffAppointmentStatus')}
                      />
                    </div>
                    <div className="appointment-label">
                      <InputLabel>Appointment Required</InputLabel>
                    </div>
                    {showDropoffAppointmentDropdown}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <DispatchBorderConnect
            dispatchTransferObjectId={getAttribute(this.state.selectedDispatchTransfer, 'objectId', true)}
            dispatchTransfer={this.state.selectedDispatchTransfer}
            dispatchJobObjectId={this.props.dispatchJobObjectId}
            dispatchJob={this.state.dispatchJob}
          /> */}

          {/* Used to display all the related dispatch item info */}
          <DispatchFreightSummary
            className="mt-5"
            title="Freight Summary"
            dispatchTransferObjectId={getAttribute(this.state.selectedDispatchTransfer, 'objectId', true)}
            dispatchTransfer={this.state.selectedDispatchTransfer}
            dispatchJobObjectId={this.props.dispatchJobObjectId}
          />
          {/* Reference numbers input section */}
          <DispatchReferenceNumbersContainer
            dispatchTransferObjectId={getAttribute(this.state.selectedDispatchTransfer, 'objectId', true)}
            dispatchJobObjectId={this.props.dispatchJobObjectId}
            dispatchJob={this.state.dispatchJob}
            triggerRefreshState={this.props.triggerRefreshState}
          />

          {/* Shipment notes input section */}
          <DispatchShipmentNotes dispatchTransfer={this.state.selectedDispatchTransfer} />
        </SBCard>
      </React.Fragment>
    );
  }
}

DispatchShipmentDetail.propTypes = {
  dispatchJobObjectId: PropTypes.string.isRequired,
  // handleSelect: PropTypes.func, // only return the resulting selections/changes vs. automatically saving them
  // drivers: PropTypes.array, // force to show these drivers over defaults
  // carrier: PropTypes.object, // force to show this carrier over default
  // vehicle: PropTypes.object, // force to show vehicle over default
};

export default DispatchShipmentDetail;
