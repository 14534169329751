import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import * as ELD from 'api/ELD';

import {
  Table,
  TableHeader,
  TableRow,
  TableRowColumn,
  TableHeaderColumn,
  TableBody,
} from 'material-ui';
import RaisedButton from 'material-ui/RaisedButton';

import Title from 'components/LayoutTitle/view/Title';
import HOSPreviewDialog from 'components/HOSPreviewDialog/container/HOSPreviewDialog';

const DriverELDEditsTable = (props) => {
  const edits = props.ELDEvent.eldEdits;
  const { eldEvents, onDate, eldDailyCertification, associatedELDEvents, eventTypeIndex, scaleToDriverTimezone, styles } = props;

  const driver = eldDailyCertification && eldDailyCertification.get('driver');
  const timezoneOffsetFromUTC = (scaleToDriverTimezone && driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();

  const getCategoryHTMLFromEventTypeCode = (referenceInt) => {
    // given an eldEventTypeCode referenceInt, determine what HTML to output to display category
    let html = <div style={{ display: 'inline-block', fontWeight: '500' }}>N/A</div>;
    switch (referenceInt) {
      case 11: // off duty
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Off Duty')}
          </div>
        );
        break;
      case 12: // sleeper
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Sleeper Berth')}
          </div>
        );
        break;
      case 13: // driving
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Driving')}
          </div>
        );
        break;
      case 14: // on duty
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('On Duty')}
          </div>
        );
        break;
      case 21: // intermediate log
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Intermediate Log (Conventional Location Precision)')}
          </div>
        );
        break;
      case 22: // intermediate log
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Intermediate Log (Reduced Location Precision)')}
          </div>
        );
        break;
      case 30:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Driver\'s indication for PC, YM, and WT cleared')}
          </div>
        );
        break;
      case 31: // personal use cmv
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Personal Use CMV (Off Duty)')}
          </div>
        );
        break;
      case 32: // yard moves
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Yard Moves (On Duty)')}
          </div>
        );
        break;
      case 41:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Driver Certification')}
          </div>
        );
        break;
      case 42:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Driver Certification')}
          </div>
        );
        break;
      case 43:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Driver Certification')}
          </div>
        );
        break;
      case 44:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Driver Certification')}
          </div>
        );
        break;
      case 45:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Driver Certification')}
          </div>
        );
        break;
      case 46:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Driver Certification')}
          </div>
        );
        break;
      case 47:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Driver Certification')}
          </div>
        );
        break;
      case 48:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Driver Certification')}
          </div>
        );
        break;
      case 49:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Driver Certification')}
          </div>
        );
        break;
      case 51: // login activity
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Driver Login')}
          </div>
        );
        break;
      case 52: // logout activity
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Driver Logout')}
          </div>
        );
        break;
      case 61: // engine activity
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Power-Up (Conventional)')}
          </div>
        );
        break;
      case 62: // engine activity
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Power-Up (Reduced)')}
          </div>
        );
        break;
      case 63: // engine activity
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Shut-Down (Conventional)')}
          </div>
        );
        break;
      case 64: // engine activity
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Shut-Down (Reduced)')}
          </div>
        );
        break;
      case 71:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('ELD Malfunction Logged')}
          </div>
        );
        break;
      case 72:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('ELD Malfunction Cleared')}
          </div>
        );
        break;
      case 73:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Data Diagnostic Logged')}
          </div>
        );
        break;
      case 74:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Data Diagnostic Cleared')}
          </div>
        );
        break;
      case 200:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Off-Duty Time Deferral set to "None"')}
          </div>
        );
        break;
      case 201:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Off-Duty Time Deferral set to "Day 1"')}
          </div>
        );
        break;
      case 202:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Off-Duty Time Deferral set to "Day 2"')}
          </div>
        );
        break;
      case 211:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Cycle set to "Cycle 1"')}
          </div>
        );
        break;
      case 212:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Cycle set to "Cycle 2"')}
          </div>
        );
        break;
      case 221:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Operating Zone set to "South of Latitude 60°N in Canada"')}
          </div>
        );
        break;
      case 222:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Operating Zone set to "North of Latitude 60°N in Canada"')}
          </div>
        );
        break;
      case 223:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Operating Zone set to "United States"')}
          </div>
        );
        break;
      case 231:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Additional Hours not Recorded')}
          </div>
        );
        break;
      default:
        html = (
          <div style={{ display: 'inline-block', fontWeight: '500' }}>
            {t('Event not Recognized')}
          </div>
        );
        break;
    }

    return html;
  };

  // combine all requested events and requested to be inactive events for a combined edit preview
  let eldEventsForEditPreview = [].concat(eldEvents); // events for edit previews. cancel out inactive, input requested
  let requestedELDEvents = [];
  let eldEventsToBeInactiveIds = [];

  for (let i = 0; i < edits.length; i++) {
    const eldEdit = edits[i];
    const eldEditRequestedELDEvents = (eldEdit.get('requestedELDEvents') || []).map(event => event.clone().set('eldEventRecordStatusInt', 1));
    const eldEditELDEventsToBeInactiveIds = (eldEdit.get('eldEventsToBeInactive') || []).map(event => event.id);
    requestedELDEvents = requestedELDEvents.concat(eldEditRequestedELDEvents);
    eldEventsToBeInactiveIds = eldEventsToBeInactiveIds.concat(eldEditELDEventsToBeInactiveIds);
  }
  eldEventsForEditPreview = eldEventsForEditPreview.concat(requestedELDEvents);
  eldEventsForEditPreview = eldEventsForEditPreview.filter(event => {
    return eldEventsToBeInactiveIds.indexOf(event.id) === -1;
  });
  ELD.sortELDEvents(eldEventsForEditPreview, 1);

  // individual edit rows
  const editRows = edits.map(edit => {
    const requestedBy = edit.get('requestedBy');
    const requestedByFullName = `${requestedBy.get('firstName')} ${requestedBy.get('lastName') && requestedBy.get('lastName')[0].toUpperCase()}`;
    let editsRequestedDisplay = []; // holds requested and to-be inactive events info for display

    const requestedELDEvents = (edit.get('requestedELDEvents') || []).filter((event, index) => {
      const pastEvent = (edit.get('requestedELDEvents') || [])[index - 1];
      if (pastEvent) {
        if ([21, 22].indexOf(event.get('eldEventTypeCodeInt')) !== -1) {
          if ([21, 22].indexOf(pastEvent.get('eldEventTypeCodeInt')) !== -1) {
            return false;
          }
          return true;
        }
      }
      return true;
    });

    const requestedELDEventsDisplay = requestedELDEvents.map(requestedEvent => {
      return (
        <div key={requestedEvent.id}>
          <div style={{ display: 'inline-block', width: '.5em' }}>+</div>&nbsp;
          { getCategoryHTMLFromEventTypeCode(requestedEvent.get('eldEventTypeCodeInt')) } {`@ ${moment(requestedEvent.get('eventDateTime')).tz(timezoneOffsetFromUTC).format('HH:mm')}`}
        </div>
      );
    });

    const eldEventsToBeInactive = (edit.get('eldEventsToBeInactive') || []).filter((event, index) => {
      const pastEvent = (edit.get('eldEventsToBeInactive') || [])[index - 1];
      if (pastEvent) {
        if ([21, 22].indexOf(event.get('eldEventTypeCodeInt')) !== -1) {
          if ([21, 22].indexOf(pastEvent.get('eldEventTypeCodeInt')) !== -1) {
            return false;
          }
          return true;
        }
      }
      return true;
    });
    const eldEventsToBeInactiveDisplay = eldEventsToBeInactive.map(eventToBeInactive => {
      return (
        <div key={eventToBeInactive.id}>
          <div style={{ display: 'inline-block', width: '.5em', paddingLeft: '.1em' }}>-</div>&nbsp;
          { getCategoryHTMLFromEventTypeCode(eventToBeInactive.get('eldEventTypeCodeInt')) } {`@ ${moment(eventToBeInactive.get('eventDateTime')).tz(timezoneOffsetFromUTC).format('HH:mm')}`}
        </div>
      );
    });

    editsRequestedDisplay = requestedELDEventsDisplay.concat(eldEventsToBeInactiveDisplay);

    // edit preview for this specific edit
    let eventsForPreviewSpecificEdit = [].concat(eldEvents);
    const specificEditRequestedELDEvents = (edit.get('requestedELDEvents') || []).map(event => event.clone().set('eldEventRecordStatusInt', 1));
    const specificEditELDEventsToBeInactiveIds = (edit.get('eldEventsToBeInactive') || []).map(event => event.id);
    eventsForPreviewSpecificEdit = eventsForPreviewSpecificEdit.concat(specificEditRequestedELDEvents);
    eventsForPreviewSpecificEdit = eventsForPreviewSpecificEdit.filter(event => {
      return specificEditELDEventsToBeInactiveIds.indexOf(event.id) === -1;
    });
    ELD.sortELDEvents(eventsForPreviewSpecificEdit, 1);

    let isRequestedByDriver = false;
    let requestedOn = moment(edit.get('requestedAt'));

    if (driver && (driver.get('user').id === requestedBy.id)) {
      requestedOn = requestedOn.tz(timezoneOffsetFromUTC);
      isRequestedByDriver = true;
    }

    return (
      <TableRow key={edit.id} style={{ verticalAlign: 'middle' }}>
        <TableRowColumn style={{ verticalAlign: 'middle', width: '25%', paddingTop: '.5em' }}>
          { editsRequestedDisplay }
        </TableRowColumn>

        <TableRowColumn style={{ verticalAlign: 'middle', width: '15%', paddingTop: '.5em' }}>
          { requestedByFullName }
        </TableRowColumn>

        <TableRowColumn style={{ verticalAlign: 'middle', width: '25%', paddingTop: '.5em' }}>
          { requestedOn.format('LLL') } { isRequestedByDriver ? t(`(${timezoneOffsetFromUTC})`) : t(`(${moment.tz.guess()})`) }
        </TableRowColumn>

        <TableRowColumn style={{ verticalAlign: 'middle', paddingTop: '.5em', paddingBottom: '.5em', textAlign: 'right' }}>
          <HOSPreviewDialog
            label={t('View Preview')}
            title={t('Edit Preview')}
            disabled={eventTypeIndex >= 1}
            onDate={new Date(onDate)}
            eldDailyCertification={eldDailyCertification}
            compareAgainstELDEvents={eldEvents}
            eldEvents={eventsForPreviewSpecificEdit}
            associatedELDEvents={[].concat(associatedELDEvents) || []}
            primary
          />

          { edit.get('completed') === 3 &&
            <div style={{ display: 'inline-block' }}>
              <RaisedButton
                key="driverEditAccept"
                label={t('Accept')}
                onClick={(e) => props.handleDriverEdit(edit, true)}
                primary
                disabled={eventTypeIndex >= 1}
                style={{ verticalAlign: 'middle', marginLeft: '.5em' }}
              />
              <RaisedButton
                key="driverEditReject"
                label={t('Reject')}
                onClick={(e) => props.handleDriverEdit(edit, false)}
                secondary
                disabled={eventTypeIndex >= 1}
                style={{ verticalAlign: 'middle', marginLeft: '.5em' }}
              />
            </div>
          }

          { edit.get('completed') === 4 &&
            <span>
              <div style={{ display: 'inline-block' }}>
                <RaisedButton
                  key="pendingELDConfirmationRejected"
                  label={t('Rejected - Pending')}
                  disabled
                  style={{ verticalAlign: 'middle', marginLeft: '.5em' }}
                />
              </div>

              <div style={{ marginTop: '.5em', fontSize: '.8em' }}>{t('Awaiting ELD Confirmation')}</div>
            </span>
          }

          { edit.get('completed') === 5 &&
            <span>
              <div style={{ display: 'inline-block' }}>
                <RaisedButton
                  key="pendingELDConfirmationAccepted"
                  label={t('Accepted - Pending')}
                  disabled
                  style={{ verticalAlign: 'middle', marginLeft: '.5em' }}
                />
              </div>

              <div style={{ marginTop: '.5em', fontSize: '.8em' }}>{t('Awaiting ELD Confirmation')}</div>
            </span>
          }

        </TableRowColumn>
      </TableRow>
    );
  });

  window.Localize.translatePage();

  return (
    <div>
      <Title className="h3" title={<span className="translate-me">Edits Awaiting Confirmation</span>} />
      <Table
        wrapperStyle={{ overflow: 'hidden' }}
      >
        <TableHeader
          displaySelectAll={false}
          adjustForCheckbox={false}
          enableSelectAll={false}
        >
          <TableRow>
            <TableHeaderColumn style={{ width: '25%' }}>
              {t('Edit Requested (+ Add, - Remove)')}
            </TableHeaderColumn>
            <TableHeaderColumn style={{ width: '15%' }}>
              {t('Requested By')}
            </TableHeaderColumn>
            <TableHeaderColumn style={{ width: '25%' }}>
              {t('Requested On')}
            </TableHeaderColumn>
            <TableHeaderColumn style={{ textAlign: 'right' }}>
              { edits.length > 1 &&
                <HOSPreviewDialog
                  label={t('Preview Combined Edits')}
                  title={t('Combined Edits Preview')}
                  disabled={eventTypeIndex >= 1}
                  onDate={new Date(onDate)}
                  eldDailyCertification={eldDailyCertification}
                  compareAgainstELDEvents={eldEvents}
                  eldEvents={eldEventsForEditPreview}
                  associatedELDEvents={[].concat(associatedELDEvents) || []}
                  raised
                />
              }
            </TableHeaderColumn>
          </TableRow>
        </TableHeader>
        <TableBody
          displayRowCheckbox={false}
          showRowHover
        >
          { editRows }
        </TableBody>
      </Table>
    </div>
  );
};

DriverELDEditsTable.propTypes = {
  ELDEvent: PropTypes.object,
  associatedELDEvents: PropTypes.array,
  eldDailyCertification: PropTypes.object,
  eventTypeIndex: PropTypes.any,
  onDate: PropTypes.instanceOf(Date),
  handleDriverEdit: PropTypes.func,
  scaleToDriverTimezone: PropTypes.bool,
  styles: PropTypes.object,
};

export default DriverELDEditsTable;
