export const ELDStatusTypes = Object.freeze({
  OFF_DUTY: 1,
  SLEEPER_BERTH: 2,
  DRIVING: 3,
  ON_DUTY_NOT_DRIVING: 4,
  PERSONAL_USE_CMV: 5,
  YARD_MOVE: 6,
  EXEMPT: 7,
});

export const ELDStatusTypeDescriptions = Object.freeze({
  OFF_DUTY: 'Off Duty',
  SLEEPER_BERTH: 'Sleeper Berth',
  DRIVING: 'Driving',
  ON_DUTY_NOT_DRIVING: 'On Duty - Not Driving',
  PERSONAL_USE_CMV: 'Personal Use CMV',
  YARD_MOVE: 'Yard Move',
  EXEMPT: 'Exempt',
});

export const ELDStatusTypeDescriptionsCondensed = Object.freeze({
  OFF_DUTY: 'Off Duty',
  SLEEPER_BERTH: 'Sleeper',
  DRIVING: 'Driving',
  ON_DUTY_NOT_DRIVING: 'On Duty',
  PERSONAL_USE_CMV: 'Personal',
  YARD_MOVE: 'Yard',
  EXEMPT: 'Exempt',
});

export const ELDStatusTypeDescriptionsShort = Object.freeze({
  OFF_DUTY: 'OFF',
  SLEEPER_BERTH: 'SB',
  DRIVING: 'DR',
  ON_DUTY_NOT_DRIVING: 'OD - ND',
  PERSONAL_USE_CMV: 'PU - CMV',
  YARD_MOVE: 'YM',
  EXEMPT: 'EX',
});

export const RevELDStatusTypes = Object.freeze({
  1: 'Off Duty',
  2: 'Sleeper Berth',
  3: 'Driving',
  4: 'On Duty - Not Driving',
  5: 'Personal Use CMV',
  6: 'Yard Move',
  7: 'Exempt',
});