import moment from 'moment-timezone';

// API
import { getAttribute, getCurrentUser, createQuery, setQueryRestriction, copyQuery, includePointers, sortQuery, count, find, getObjectById, updateRecord, destroyRecord } from 'api/Parse';
import * as IFTA from 'api/IFTA';
import { addIFTARoute, updateIFTARoute } from 'api/IFTARoute/IFTARoute';
import TouchingStateProvinces from 'api/Lists/TouchingStateProvinces';
import { getLocationDescriptionBreakdown } from 'api/VehicleLocation/VehicleLocation';

// SBObjects
import Sort from 'sbObjects/Sort';

// Enums
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

/** @module IFTARoute */

/**
 * @memberof module:IFTARoute
 * @description Obtains iftaRoutes from a company
 *
 * @param {int} page - query page / limit skip multiplier
 * @param {int} limit  - amount of results we want
 * @param {array} sortBy - array of Sort objects
 * @param {array} filters - array of Filter objects
 * @param {bool} considerChildCompanies - whether or not to include child company results
 *
 * @returns { object } - { iftaRoutes: [], totalIFTARouteCount: int }
 */
async function getIFTARoutes(page = 0, limit = 10, sortBy = new Sort('dateStart', QuerySortOrderTypes.ASCENDING), filters = [], considerChildCompanies) {
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  const companiesToConsider = [belongsToCompany];

  // Using new IFTARoutes
  const iftaRouteQuery = createQuery('IFTARoute_Beta');

  if (considerChildCompanies) {
    const childrenQuery = createQuery('CompanyLink');
    setQueryRestriction(childrenQuery, QueryRestrictionTypes.EQUAL_TO, 'parentCompany', belongsToCompany);
    const childCompanies = await find(childrenQuery);
    childCompanies.map((childCompany) => companiesToConsider.push(childCompany));

    setQueryRestriction(iftaRouteQuery, QueryRestrictionTypes.CONTAINED_IN, 'belongsToCompany', companiesToConsider);
  } else {
    setQueryRestriction(iftaRouteQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
  }

  // Set query restricitons from filter
  filters.map(filter => setQueryRestriction(iftaRouteQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value));

  // Copy current query to get the number of pages for pagination
  const iftaRouteCountQuery = copyQuery(iftaRouteQuery);

  includePointers(iftaRouteQuery, [
    'vehicleLocationStart',
    'vehicleLocationEnd',
    'belongsToCompany',
  ]);

  setQueryRestriction(iftaRouteQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  setQueryRestriction(iftaRouteQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);

  // Call the ascending/descending function on the query, passing in the attribute
  sortQuery(iftaRouteQuery, sortBy.order, sortBy.attribute);

  try {
    const [totalIFTARouteCount, iftaRoutes] = await Promise.all([count(iftaRouteCountQuery), find(iftaRouteQuery)]);
    return { iftaRoutes, totalIFTARouteCount };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:IFTARoute
 * @description Retrieves the timezone from a driver if applicable, otherwise returns a guess of the timezone
 *
 * @param {ParseObject} iftaRoute iftaRoute record
 *
 * @returns {String} Returns the timezone retrieved from the driver if applicable
 */
const getTimezoneString = (iftaRoute) => {
  const iftaRouteDriverPeriods = iftaRoute && getAttribute(iftaRoute, 'iftaRouteDriverPeriods');
  const driver = iftaRouteDriverPeriods && iftaRouteDriverPeriods.length > 0 && getAttribute(iftaRouteDriverPeriods[0], 'driver');

  if (!driver) return moment.tz.guess();

  const driverTimeZoneStr = getAttribute(driver, 'timezoneOffsetFromUTC') || moment.tz.guess();
  return driverTimeZoneStr;
};

/**
 * @memberof module:IFTARoute
 * @description Attempts to retrieve the first and last odometer readings from an array if IFTARoutes if it exists, otherwise it falls back to IFTA.getOdometerReadingsForDateRange
 *
 * @param {String} vehicleUnitId vehicleUnitId
 * @param {Array} iftaRouteArr Array of IFTARoutes
 * @param {Date} dateStart dateStart
 * @param {Date} dateEnd dateEnd
 */
const getOdometerReadingsFromIFTARoute = async (vehicleUnitId, iftaRouteArr, dateStart, dateEnd) => {
  let odometerReadings = { odometerStartKm: 0, odometerEndKm: 0, odometerDiffKm: 0 };

  const iftaRouteArrLength = iftaRouteArr.length;
  if (iftaRouteArrLength === 0) {
    // If there doesnt seem to be any IFTARoutes, we'll fallback to using odometer readings
    const odometerReadingsResult = await IFTA.getOdometerReadingsForDateRange(vehicleUnitId, dateStart, dateEnd, 'km');
    odometerReadings = { odometerStartKm: odometerReadingsResult.odometerStart || 0, odometerEndKm: odometerReadingsResult.odometerEndTotal || 0, odometerDiffKm: odometerReadingsResult.total || 0 };
    return odometerReadings;
  }

  let totalSavedVehicleKm = 0;
  // Obtain all the savedVehicleKm values from the iftaRoutes, we'll use this value to compare the determined odometer difference
  for (let i = 0; i < iftaRouteArrLength; i++) {
    const iftaRoute = iftaRouteArr[i];
    const savedVehicleKm = getAttribute(iftaRoute, 'savedVehicleKm');
    if (savedVehicleKm) totalSavedVehicleKm += savedVehicleKm;
  }

  // Go through and find the first and last odometer readings from the IFTARoute array
  for (let i = 0; i < iftaRouteArrLength; i++) {
    const firstIFTARoute = iftaRouteArr[0];
    const lastIFTARoute = iftaRouteArr[iftaRouteArrLength - (i + 1)];
    const odometerStartKm = getAttribute(firstIFTARoute, 'totalVehicleKmStart');
    const odometerEndKm = getAttribute(lastIFTARoute, 'totalVehicleKmEnd');
    const odometerDiffKm = (odometerStartKm && odometerEndKm) && Math.floor(odometerEndKm - odometerStartKm);

    // Determine the threshold of the odometer difference that we should at most see
    const totalDateInterval = moment(dateEnd).diff(moment(dateStart), 'hours');
    const totalOdometerDiffKmThreshold = 200 * totalDateInterval; // Threshold will be calculated by the 200km per hour for the whole interval of time

    // We'll make sure that the values we get back make sense (using odometer threshold and the savedVehicleKm)
    if (odometerDiffKm > 0 && odometerDiffKm < totalOdometerDiffKmThreshold && (Math.abs(totalSavedVehicleKm - odometerDiffKm) < (5000 * (moment(dateEnd).diff(moment(dateStart), 'months') + 1)))) {
      odometerReadings = { odometerStartKm, odometerEndKm, odometerDiffKm };
      return odometerReadings;
    }
  }

  // Last line of defense if none of the IFTARoutes have any correct values, we'll fallback and use IFTA.getOdometerReadingsForDateRange
  const odometerReadingsResult = await IFTA.getOdometerReadingsForDateRange(vehicleUnitId, dateStart, dateEnd, 'km');
  odometerReadings = { odometerStartKm: odometerReadingsResult.odometerStart || 0, odometerEndKm: odometerReadingsResult.odometerEndTotal || 0, odometerDiffKm: odometerReadingsResult.total || 0 };
  return odometerReadings;
}

/**
 * @memberof module:IFTARoute
 * @description Checks to see if two state/provinces are adjacent
 *
 * @param {String} currentStateProvince String containing the current state/province abbreviation
 * @param {String} previousStateProvince String containing the previous state/province abbreviation
 */
const areStatesTouching = (currentStateProvince, previousStateProvince) => {
  if (currentStateProvince && previousStateProvince && currentStateProvince !== previousStateProvince && TouchingStateProvinces[currentStateProvince.toUpperCase()]) {
    return (TouchingStateProvinces[currentStateProvince.toUpperCase()].indexOf(previousStateProvince.toUpperCase()) !== -1);
  } else {
    return true;
  }
};

/**
 * @memberof module:IFTARoute
 * @description Checks the gps/calculated mileages and the odometer differences with the total calculated mileages for any potential errors
 *
 * @param {Array} gpsMileageArray Array of objects containing the stateProvince and gps mileage for all stateProvinces
 * @param {Array} calculatedMileageArray Array of objects containin the stateProvince and calculated mileage for all stateProvinces
 * @param {Number} totalCalculatedMileage A number containing the total calculated mileage
 * @param {Number} odometerDifference A number containin the total odometer difference mileage
 *
 * @returns {Object} Returns an object containing all the potential issues. gpsMileageAndCalculatedMileageOutsideThreshold is an array containing objects for each stateProvince that has a potential error
 */
const findTotalMileageIssues = (gpsMileageArray, calculatedMileageArray, totalCalculatedMileage, odometerDifference) => {
  const issuesObject = {
    isOdometerDifferenceAndTotalMileageDifferent: false,
    gpsMileageAndCalculatedMileageOutsideThreshold: [],
  };

  if (!gpsMileageArray || !calculatedMileageArray || !totalCalculatedMileage || !odometerDifference) return {};

  // Check for any difference between totalCalculatedMileage and odometerDifference
  // Give a threshold of 1 to account for rounding errors
  if (Math.abs(Math.round(totalCalculatedMileage) - Math.round(odometerDifference)) > 1) issuesObject.isOdometerDifferenceAndTotalMileageDifferent = true;

  // Note: The stateProvinces for the gpsMileageArray and the calculatedMileageArray are always in the same order
  for (let i = 0; i < gpsMileageArray.length; i++) {
    const { stateProvince, subtotalDistanceKm } = gpsMileageArray[i];
    const { subtotalCalculatedKm } = calculatedMileageArray[i];
    const percentThreshold = 1.5;

    if (subtotalDistanceKm && subtotalCalculatedKm) {
      const upperThreshold = subtotalDistanceKm + (subtotalDistanceKm * percentThreshold);
      const lowerThreshold = subtotalDistanceKm - (subtotalDistanceKm * percentThreshold);

      // subtotalCalculatedKm is not within the threshold
      if (subtotalCalculatedKm < lowerThreshold || subtotalCalculatedKm > upperThreshold) {
        issuesObject.gpsMileageAndCalculatedMileageOutsideThreshold.push({ stateProvince, warning: true });
      }
    }
  }

  return issuesObject;
};

/**
 * @memberof module:IFTARoute
 * @description Checks for potential IFTA errors associated with the IFTA Day View Page
 *
 * @param {Array} iftaRouteByDayArr An array containing the parsed iftaRoute records categorized by day
 *
 * @returns {Array} Returns an array of objects which contain information about the dates, and associated errors
 */
const findDayViewIssues = (iftaRouteByDayArr) => {
  const potentialIFTARouteErrorsArr = [];
  const iftaRouteArrLength = iftaRouteByDayArr.length;
  if (iftaRouteArrLength === 0) return potentialIFTARouteErrorsArr;

  for (let i = 0; i < iftaRouteArrLength; i++) {
    const previousIFTARoute = i !== 0 ? iftaRouteByDayArr[i - 1] : undefined;
    const currentIFTARoute = iftaRouteByDayArr[i];

    let isDayJump = false;
    let stateProvinceNotAdjacent = false;
    let jumpGapKmOutsideThreshold = false;

    if (previousIFTARoute) {
      // Checks for jumps in days greater than 1
      const dateEnd = moment(previousIFTARoute.dateEnd).startOf('day');
      const dateStart = moment(currentIFTARoute.dateStart).startOf('day');
      const dayEndStartDifference = dateStart.diff(dateEnd, 'days');
      isDayJump = dayEndStartDifference > 1;

      // Checks to see if the state/province of the end of the day and the start of the next day are adjacent to each other
      const prevVehicleLocationEnd = previousIFTARoute.vehicleLocationEnd;
      let prevEndVehicleLocationStateProvince = prevVehicleLocationEnd.stateProvince;
      const prevVehicleLocationEndString = prevVehicleLocationEnd.locationDescriptionUS;
      const prevVehicleLocationEndBreakdown = getLocationDescriptionBreakdown(prevVehicleLocationEndString);
      if (!prevEndVehicleLocationStateProvince) prevEndVehicleLocationStateProvince = prevVehicleLocationEndBreakdown.stateProvince.code.toLowerCase();

      const currentVehicleLocationStart = currentIFTARoute.vehicleLocationStart;
      let startVehicleLocationStateProvince = currentVehicleLocationStart.stateProvince;
      const currentVehicleLocationStartString = currentVehicleLocationStart.locationDescriptionUS;
      const currentVehicleLocationStartBreakdown = getLocationDescriptionBreakdown(currentVehicleLocationStartString);
      if (!startVehicleLocationStateProvince) startVehicleLocationStateProvince = currentVehicleLocationStartBreakdown.stateProvince.code.toLowerCase();

      stateProvinceNotAdjacent = !areStatesTouching(startVehicleLocationStateProvince, prevEndVehicleLocationStateProvince);

      // Checks to see if there is a jumped mileage for between the end of the current day and the next day
      const { odometerJumpGapKm } = previousIFTARoute;
      const odometerJumpGapKmThreshold = 50; // Jump gap of 50 or more km
      const odometerJumpGapKmMaxThreshold = 2000; // Limit the maximum jump to 2000km per day
      jumpGapKmOutsideThreshold = odometerJumpGapKm >= odometerJumpGapKmThreshold && odometerJumpGapKm <= ((dayEndStartDifference + 1) * odometerJumpGapKmMaxThreshold);
    }

    const { totalVehicleKmDiff } = currentIFTARoute;
    const odometerDifferenceUpperThreshold = 200 * 24; // 200km per hour for a whole day
    const isIncorrectOdometerReading = Math.abs(totalVehicleKmDiff) > odometerDifferenceUpperThreshold;

    let isUnidentifiedDriving = false;
    if (!currentIFTARoute.iftaRouteDriverPeriods || currentIFTARoute.iftaRouteDriverPeriods.length === 0) isUnidentifiedDriving = true;

    if (previousIFTARoute && ((isDayJump && jumpGapKmOutsideThreshold) || stateProvinceNotAdjacent || jumpGapKmOutsideThreshold)) {
      const uniqueId = moment(previousIFTARoute.dateStart).tz(previousIFTARoute.driverTimeZoneStr).format('YYYYMMDD');
      potentialIFTARouteErrorsArr.push({ uniqueId, stateProvinceNotAdjacent, isDayJump, jumpGapKmOutsideThreshold, isIncorrectOdometerReading, isUnidentifiedDriving });
    } else if (isIncorrectOdometerReading || isUnidentifiedDriving) {
      const uniqueId = moment(currentIFTARoute.dateStart).tz(currentIFTARoute.driverTimeZoneStr).format('YYYYMMDD');
      potentialIFTARouteErrorsArr.push({ uniqueId, stateProvinceNotAdjacent, isDayJump, jumpGapKmOutsideThreshold, isIncorrectOdometerReading, isUnidentifiedDriving });
    }
  }

  return potentialIFTARouteErrorsArr;
};

/**
 * @memberof module:IFTARoute
 * @description Checks for potential IFTA errors associated with the IFTA Day View Page
 *
 * @param {Array} iftaRouteArr An array containing the parsed iftaRoute records
 *
 * @returns {Array} Returns an array of objects which contain information about the dates, and associated errors
 */
const findIFTARouteIssues = (iftaRouteArr) => {
  // This function detects the following situations:
  // 1. Missing time between 2 iftaRoutes: 11:10 - 13:50, 15:33 - 16:45 ==> Should detect a missing timeframe between 13:50 - 15:33
  // 2. Jump in stateProvinces between 2 iftaRoutes (stateprovinces are not adjacent to one another)
  // 3. Jump in odometer readings between 2 iftaRoutes
  // 4. Unusual odometer readings - speed over 200km/hr
  // 5. Unidentified driving for the individual iftaRoute

  const potentialIFTARouteErrorsArr = [];
  const iftaRouteArrLength = iftaRouteArr.length;

  for (let i = 0; i < iftaRouteArrLength; i++) {
    const previousIFTARoute = i !== 0 ? iftaRouteArr[i - 1] : undefined;
    const currentIFTARoute = iftaRouteArr[i];

    let isNonContinuous = false;
    let stateProvinceNotAdjacent = false;
    let jumpGapKmOutsideThreshold = false;

    if (previousIFTARoute) {
      // If there exists a previous IFTARoute, then we'll do checks which require past IFTARoutes
      // First, check for missing times (threshold will be 1 minute)
      const dateEnd = moment(getAttribute(previousIFTARoute, 'dateEnd'));
      const dateStart = moment(getAttribute(currentIFTARoute, 'dateStart'));
      const dayEndStartDifference = dateStart.diff(dateEnd, 'minutes');
      isNonContinuous = dayEndStartDifference > 1; // difference was greater than 1 minute

      // Checks to see if the state/province of the end of the day and the start of the next day are adjacent to each other
      const prevVehicleLocationEnd = getAttribute(previousIFTARoute, 'vehicleLocationEnd');
      let prevEndVehicleLocationStateProvince = getAttribute(prevVehicleLocationEnd, 'stateProvince');
      const prevVehicleLocationEndString = getAttribute(prevVehicleLocationEnd, 'locationDescriptionUS');
      const prevVehicleLocationEndBreakdown = getLocationDescriptionBreakdown(prevVehicleLocationEndString);
      if (!prevEndVehicleLocationStateProvince) prevEndVehicleLocationStateProvince = prevVehicleLocationEndBreakdown.stateProvince.code.toLowerCase();

      const currentVehicleLocationStart = getAttribute(currentIFTARoute, 'vehicleLocationStart');
      let startVehicleLocationStateProvince = getAttribute(currentVehicleLocationStart, 'stateProvince');
      const currentVehicleLocationStartString = getAttribute(currentVehicleLocationStart, 'locationDescriptionUS');
      const currentVehicleLocationStartBreakdown = getLocationDescriptionBreakdown(currentVehicleLocationStartString);
      if (!startVehicleLocationStateProvince) startVehicleLocationStateProvince = currentVehicleLocationStartBreakdown.stateProvince.code.toLowerCase();

      stateProvinceNotAdjacent = !areStatesTouching(startVehicleLocationStateProvince, prevEndVehicleLocationStateProvince);

      // Checks to see if there is a jumped mileage for between the iftaRoutes
      const jumpGapKm = getAttribute(currentIFTARoute, 'totalVehicleKmStart') - getAttribute(previousIFTARoute, 'totalVehicleKmEnd');
      const jumpGapKmThreshold = 1; // 1km difference
      jumpGapKmOutsideThreshold = Math.abs(jumpGapKm) >= jumpGapKmThreshold;
    }

    // Check odometer readings to make sure they make sense
    const totalVehicleKmDiff = getAttribute(currentIFTARoute, 'totalVehicleKmDiff');
    const totalTimeDifference = moment(getAttribute(currentIFTARoute, 'dateEnd')).diff(moment(getAttribute(currentIFTARoute, 'dateStart')), 'seconds');
    const speedKm = totalVehicleKmDiff / (totalTimeDifference / 3600);
    const speedThreshold = 200; // 200km per hour for a whole day
    const isIncorrectOdometerReading = Math.abs(speedKm) > speedThreshold;

    let isUnidentifiedDriving = false;
    const iftaRouteDriverPeriods = getAttribute(currentIFTARoute, 'iftaRouteDriverPeriods');
    if (!iftaRouteDriverPeriods || iftaRouteDriverPeriods.length === 0) isUnidentifiedDriving = true;

    if (previousIFTARoute && (isNonContinuous || stateProvinceNotAdjacent || jumpGapKmOutsideThreshold)) {
      // We take the dateEnd/dateStart from previous and next IFTARoute
      potentialIFTARouteErrorsArr.push({ startIFTARouteObjectId: previousIFTARoute.id, endIFTARouteObjectId: currentIFTARoute.id, dateEnd: getAttribute(previousIFTARoute, 'dateEnd'), dateStart: getAttribute(previousIFTARoute, 'dateStart'), stateProvinceNotAdjacent, isNonContinuous, jumpGapKmOutsideThreshold, isIncorrectOdometerReading, isUnidentifiedDriving });
    } else if (isIncorrectOdometerReading || isUnidentifiedDriving) {
      // We take the dateEnd/dateStart from current IFTARoute
      potentialIFTARouteErrorsArr.push({ startIFTARouteObjectId: currentIFTARoute.id, endIFTARouteObjectId: currentIFTARoute.id, dateStart: getAttribute(currentIFTARoute, 'dateStart'), dateEnd: getAttribute(currentIFTARoute, 'dateEnd'), stateProvinceNotAdjacent, isNonContinuous, jumpGapKmOutsideThreshold, isIncorrectOdometerReading, isUnidentifiedDriving });
    }
  }
  return potentialIFTARouteErrorsArr;
};

/**
 * @memberof module:IFTARoute
 * @description Given an array of IFTARoutes, find any issues associated with them.
 *
 * @param {Array} iftaRouteArr An array containing the parsed iftaRoute records
 *
 * @returns {Array} Returns an array of objects which contain information about the dates, and associated errors
 */
const detectIFTARouteIssues = (iftaRouteArr) => {
  // This function detects the following situations:
  //
  // There is a previous IFTA route:
  // 1. Jump in time between two routes: 11:10 - 13:50, 15:33 - 16:45 ==> Should detect a missing timeframe between 13:50 - 15:33
  // 2. Jump in state/provinces between two iftaRoutes
  // 3. Whether the end state/province matches the start state/province
  // 4. Jump in odometer readings between two iftaRoutes
  // 
  // All IFTA routes:
  // 4. Unusual odometer readings - speed over 200km/hr
  // 5. Unidentified driving for the individual iftaRoute

  const potentialIFTARouteErrorsArr = [];
  const iftaRouteArrLength = iftaRouteArr.length;

  for (let i = 0; i < iftaRouteArrLength; i++) {
    const previousIFTARoute = i !== 0 ? iftaRouteArr[i - 1] : undefined;
    const currentIFTARoute = iftaRouteArr[i];

    let isNonContinuous = false;
    let stateProvinceNotAdjacent = false;
    let stateProvinceNotMatching = false;
    let jumpGapKmOutsideThreshold = false;

    if (previousIFTARoute) {
      // Check for non-continuous routes (threshold; 1 minute)
      const dateEnd = moment(getAttribute(previousIFTARoute, 'dateEnd'));
      const dateStart = moment(getAttribute(currentIFTARoute, 'dateStart'));
      const dayEndStartDifference = dateStart.diff(dateEnd, 'minutes');
      isNonContinuous = dayEndStartDifference > 1;

      // Check for adjacent state/provinces
      const prevVehicleLocationEnd = getAttribute(previousIFTARoute, 'vehicleLocationEnd');
      let prevEndVehicleLocationStateProvince = getAttribute(prevVehicleLocationEnd, 'stateProvince');
      const prevVehicleLocationEndString = getAttribute(prevVehicleLocationEnd, 'locationDescriptionUS');
      const prevVehicleLocationEndBreakdown = getLocationDescriptionBreakdown(prevVehicleLocationEndString);
      if (!prevEndVehicleLocationStateProvince) prevEndVehicleLocationStateProvince = prevVehicleLocationEndBreakdown.stateProvince.code.toLowerCase();

      const currentVehicleLocationStart = getAttribute(currentIFTARoute, 'vehicleLocationStart');
      let startVehicleLocationStateProvince = getAttribute(currentVehicleLocationStart, 'stateProvince');
      const currentVehicleLocationStartString = getAttribute(currentVehicleLocationStart, 'locationDescriptionUS');
      const currentVehicleLocationStartBreakdown = getLocationDescriptionBreakdown(currentVehicleLocationStartString);
      if (!startVehicleLocationStateProvince) startVehicleLocationStateProvince = currentVehicleLocationStartBreakdown.stateProvince.code.toLowerCase();

      stateProvinceNotAdjacent = !areStatesTouching(startVehicleLocationStateProvince, prevEndVehicleLocationStateProvince);
      stateProvinceNotMatching = startVehicleLocationStateProvince.toLowerCase() !== prevEndVehicleLocationStateProvince.toLowerCase();

      // Check for jumped odometer readings (threshold; 1km)
      const jumpGapKm = getAttribute(currentIFTARoute, 'totalVehicleKmStart') - getAttribute(previousIFTARoute, 'totalVehicleKmEnd');
      const jumpGapKmThreshold = 1;
      jumpGapKmOutsideThreshold = jumpGapKm >= jumpGapKmThreshold; // Only account for postive odometer jumps (100 -> 120). Ignore negative jumps (120 -> 100) for now
    }

    // Check overall distance (threshold: speeds over 200km/hr)
    const totalVehicleKmDiff = getAttribute(currentIFTARoute, 'totalVehicleKmDiff');
    const totalTimeDifference = moment(getAttribute(currentIFTARoute, 'dateEnd')).diff(moment(getAttribute(currentIFTARoute, 'dateStart')), 'seconds');
    const speedKm = totalVehicleKmDiff / (totalTimeDifference / 3600);
    const speedThreshold = 200;
    const isIncorrectOdometerReading = Math.abs(speedKm) > speedThreshold;

    // Check for unidentified driving
    let isUnidentifiedDriving = false;
    const iftaRouteDriverPeriods = getAttribute(currentIFTARoute, 'iftaRouteDriverPeriods');
    if (!iftaRouteDriverPeriods || iftaRouteDriverPeriods.length === 0) isUnidentifiedDriving = true;

    // Create the error object
    if (previousIFTARoute && (isNonContinuous || stateProvinceNotAdjacent || stateProvinceNotMatching || jumpGapKmOutsideThreshold)) {
      // We take the dateEnd/dateStart from previous and next IFTARoute
      potentialIFTARouteErrorsArr.push({
        startIFTARouteObjectId: previousIFTARoute.id,
        startIFTARoute: previousIFTARoute,
        endIFTARouteObjectId: currentIFTARoute.id,
        endIFTARoute: currentIFTARoute,
        dateEnd: getAttribute(previousIFTARoute, 'dateEnd'),
        dateStart: getAttribute(previousIFTARoute, 'dateStart'),
        stateProvinceNotAdjacent,
        stateProvinceNotMatching,
        isNonContinuous,
        jumpGapKmOutsideThreshold,
        isIncorrectOdometerReading,
        isUnidentifiedDriving
      });
    } else if (isIncorrectOdometerReading || isUnidentifiedDriving) {
      // We take the dateEnd/dateStart from current IFTARoute
      potentialIFTARouteErrorsArr.push({
        startIFTARouteObjectId: currentIFTARoute.id,
        startIFTARoute: currentIFTARoute,
        endIFTARouteObjectId: currentIFTARoute.id,
        endIFTARoute: currentIFTARoute,
        dateStart: getAttribute(currentIFTARoute, 'dateStart'),
        dateEnd: getAttribute(currentIFTARoute, 'dateEnd'),
        stateProvinceNotAdjacent,
        stateProvinceNotMatching,
        isNonContinuous,
        jumpGapKmOutsideThreshold,
        isIncorrectOdometerReading,
        isUnidentifiedDriving
      });
    }
  }

  return potentialIFTARouteErrorsArr;
};

/**
 * Automatically detects, and applies fixes to existing IFTARoute issues. This function only covers fixes for odometer jumps, stateProvince non-adjacency, and unidentified driving.
 * 
 * @param {Array} iftaRouteArr - The original array of IFTARoutes to check for potential IFTA errors
 * @returns {Array} Returns an array of new/fixed IFTARoutes. Use this array to determine if the data should be updated.
 */
const fixDetectedIFTARouteIssues = async (iftaRouteArr) => {
  let iftaRouteIssues = detectIFTARouteIssues(iftaRouteArr);

  // Filter out issues that are non-actionable or difficult to fix automatically (e.g., isNonContinuous, isIncorrectOdometerReading).
  // We'll focus on non-adjacent stateProvinces, odometer jump gaps, and unidentified driving
  iftaRouteIssues = iftaRouteIssues.filter((iftaRouteIssue) => {
    const { stateProvinceNotAdjacent, stateProvinceNotMatching, isNonContinuous, jumpGapKmOutsideThreshold, isIncorrectOdometerReading, isUnidentifiedDriving } = iftaRouteIssue;
    return (stateProvinceNotAdjacent || stateProvinceNotMatching || jumpGapKmOutsideThreshold || isUnidentifiedDriving);
  });

  if (iftaRouteIssues.length === 0) return [];

  // Create a queue of IFTARoutes to be created
  const queuedIFTARoutes = [];

  // Create an array of all the applied IFTARoutes
  const appliedIFTARoutes = [];

  for (let i = 0; i < iftaRouteIssues.length; i++) {
    const iftaRouteIssue = iftaRouteIssues[i];

    const { startIFTARoute, endIFTARoute } = iftaRouteIssue;
    const { jumpGapKmOutsideThreshold, isIncorrectOdometerReading, isUnidentifiedDriving } = iftaRouteIssue;

    // If there was an odometer jump, we'll create a new IFTARoute which spans that jump
    // This generally covers these additional cases: stateProvinceNotAdjacent, stateProvinceNotMatching, isNonContinuous
    if (jumpGapKmOutsideThreshold) {
      const iftaRoute = {
        vehicleUnitId: getAttribute(startIFTARoute, 'vehicleUnitId'),
        belongsToCompany: getAttribute(startIFTARoute, 'belongsToCompany'),
        dateStart: getAttribute(startIFTARoute, 'dateEnd'),
        dateEnd: getAttribute(endIFTARoute, 'dateStart'),
        vehicleLocationStart: getAttribute(startIFTARoute, 'vehicleLocationEnd'),
        vehicleLocationEnd: getAttribute(endIFTARoute, 'vehicleLocationStart'),
        stateProvince: getAttribute(startIFTARoute, 'stateProvince'), // TODO: use stateProvince from start or end?
        totalVehicleKmStart: getAttribute(startIFTARoute, 'totalVehicleKmEnd'),
        totalVehicleKmEnd: getAttribute(endIFTARoute, 'totalVehicleKmStart'),
        totalVehicleKmDiff: getAttribute(endIFTARoute, 'totalVehicleKmStart') - getAttribute(startIFTARoute, 'totalVehicleKmEnd'),
        distanceKm: 0, // TODO: should we calculate GPS distance?
        fuelPurchases: [], // N/A - cannot infer from the given data
        iftaRouteDriverPeriods: getAttribute(endIFTARoute, 'iftaRouteDriverPeriods'), // Use the same drivers as the start route
        savedVehicleKm: getAttribute(endIFTARoute, 'totalVehicleKmStart') - getAttribute(startIFTARoute, 'totalVehicleKmEnd'), // For now, use the difference
        isAutoCreated: true,
      }

      // Check odometer readings to make sure they make sense
      const totalTimeDifference = moment(iftaRoute.dateEnd).diff(moment(iftaRoute.dateStart), 'seconds');
      const speedKm = iftaRoute.totalVehicleKmDiff / (totalTimeDifference / 3600);
      const speedThreshold = 200; // 200km per hour for a whole day
      const isValidOdometerReading = Math.abs(speedKm) < speedThreshold;

      if (isValidOdometerReading) queuedIFTARoutes.push(iftaRoute);
      continue;
    }

    // If there was unidentified driving, we'll update the IFTARoute to use the previous IFTARoute's drivers
    if (isUnidentifiedDriving) {
      const startIFTARouteDriverPeriods = getAttribute(startIFTARoute, 'iftaRouteDriverPeriods');
      const endIFTARouteDriverPeriods = getAttribute(endIFTARoute, 'iftaRouteDriverPeriods');

      let _iftaRouteDriverPeriods = [];

      if (startIFTARouteDriverPeriods && startIFTARouteDriverPeriods.length > 0) {
        _iftaRouteDriverPeriods = startIFTARouteDriverPeriods;
      } else if (endIFTARouteDriverPeriods && endIFTARouteDriverPeriods.length > 0) {
        _iftaRouteDriverPeriods = endIFTARouteDriverPeriods;
      }

      // For now, just ignore if we cannot find the drivers from the start/end IFTARoutes
      if (_iftaRouteDriverPeriods.length === 0) continue;

      const iftaRoute = {
        iftaRouteDriverPeriods: startIFTARouteDriverPeriods || endIFTARouteDriverPeriods,
      }

      const updatedIFTARoute = await updateIFTARoute(endIFTARoute.id, iftaRoute, true);
      appliedIFTARoutes.push(updatedIFTARoute);
      continue;
    }
  }

  // Apply all the queued IFTARoutes
  for (let i = 0; i < queuedIFTARoutes.length; i++) {
    const iftaRoute = queuedIFTARoutes[i];

    const iftaRouteRecord = await addIFTARoute(iftaRoute);
    appliedIFTARoutes.push(iftaRouteRecord);
  }

  return appliedIFTARoutes;
}

const getFuelCardData = async (iftaRouteObjectIdArr, routeToNewIFTA) => {
  const iftaFuelCardDataQuery = createQuery('IFTAFuelCardData');

  if (routeToNewIFTA) {
    setQueryRestriction(iftaFuelCardDataQuery, QueryRestrictionTypes.CONTAINED_IN, 'iftaRouteBeta', iftaRouteObjectIdArr);
  } else {
    setQueryRestriction(iftaFuelCardDataQuery, QueryRestrictionTypes.CONTAINED_IN, 'iftaRoute', iftaRouteObjectIdArr);
  }

  includePointers(iftaFuelCardDataQuery, [
    'iftaRoute',
    'iftaRouteBeta',
  ]);

  const iftaFuelCardDataArr = await find(iftaFuelCardDataQuery, false, true);

  let iftaFuelCardDataHash = {};

  iftaFuelCardDataArr.map((iftaFuelCardData) => {
    let iftaRoute;

    if (routeToNewIFTA) {
      iftaRoute = getAttribute(iftaFuelCardData, 'iftaRouteBeta');
    } else {
      iftaRoute = getAttribute(iftaFuelCardData, 'iftaRoute');
    }

    const iftaRouteObjectId = iftaRoute && getAttribute(iftaRoute, 'objectId');

    if (iftaFuelCardDataHash[iftaRouteObjectId]) {
      iftaFuelCardDataHash[iftaRouteObjectId].push(iftaFuelCardData);
    } else {
      iftaFuelCardDataHash[iftaRouteObjectId] = [iftaFuelCardData];
    }
  });

  return iftaFuelCardDataHash;
};

/**
 * @memberof module:IFTARoute
 * @description Replaces all vehicle unitId and pointer fields of an IFTARoute_beta and its relations with a new vehicle
 *
 * @param {object} iftaRoute IFTARoute_beta whose vehicle is to be changed
 * @param {object} vehicle The new vehicle to replace the current for the iftaRoute
 *
 * @returns {object} Updated IFTARoute_beta record
 */
async function migrateIFTARoute(iftaRoute, vehicle) {
  if (!iftaRoute || !vehicle || !getAttribute(iftaRoute, 'objectId')) throw new Error('No route or vehicle provided');
  /**
   * Driver periods won't need adjusting as they point to the original record which we are keeping. (Assumption)
   * */
  const vehicleUnitId = getAttribute(vehicle, 'unitId');
  // 1) Fuel purchases will need vehicle: Pointer<Vehicle> and vehicleUnitId: String adjusted to vehicle
  const fuelPurchases = getAttribute(iftaRoute, 'fuelPurchases');
  const fuelPurchasePromises = fuelPurchases.map(purchase => updateRecord(purchase, { vehicle, vehicleUnitId }, true));
  const updatedFuelPurchases = await Promise.all(fuelPurchasePromises);
  // 2) Save the IFTARoute_Beta with the vehicleUnitId and updated fuelPurchases (attributes updated in (1))
  return await updateRecord(iftaRoute, { vehicleUnitId, fuelPurchases: updatedFuelPurchases }, true);
}

/**
 * @memberof module:IFTARoute
 * @description Override existing fuel card transactions
 * @param {IFTARoute} iftaRoute - The route that existing fuel card transactions are associated with
 * @param {Object} fuelCardDataObj - The fuel card transaction we wish to create (in object form)
 * @param {DateTime} createdDateTime - Remove transactions created before this date/time. Avoids unintentionally removing newly created transactions
 *                                     when searching for duplicates
 * @param {Boolean} [returnPreview] - Returns the transactions that would be removed, but does not actually remove them
 * @returns {Array} The array of removed IFTARoutes
 */
async function removeDuplicateIFTAFuelCardData(iftaRoute, fuelCardDataObj = {}, createdDateTime, returnPreview) {
  let iftaFuelCardDataRemoved = [];

  // Don't do anything if the required parameters do not exist
  if (!iftaRoute) return iftaFuelCardDataRemoved;
  if (!createdDateTime) return iftaFuelCardDataRemoved;

  // Information required to find duplicates are required
  if (!fuelCardDataObj.stateProvinceAbbrv) return iftaFuelCardDataRemoved;
  if (!fuelCardDataObj.item) return iftaFuelCardDataRemoved;
  if (!fuelCardDataObj.transactionDateTime) return iftaFuelCardDataRemoved;

  const TRANSACTION_TIME_THRESHOLD_MS = 300000; // time threshold that constitutes as duplicates in milliseconds. 5 minutes

  const iftaRouteQuery = createQuery('IFTAFuelCardData');
  setQueryRestriction(iftaRouteQuery, QueryRestrictionTypes.EQUAL_TO, 'iftaRouteBeta', iftaRoute);
  setQueryRestriction(iftaRouteQuery, QueryRestrictionTypes.LESS_THAN, 'createdAt', createdDateTime);

  /**
   * Duplicates are categorized as:
   * - Same State/Province
   * - Same Fuel Type
   * - Within 5 minutes of the transaction date/time
   */
  setQueryRestriction(iftaRouteQuery, QueryRestrictionTypes.EQUAL_TO, 'stateProvinceAbbrv', fuelCardDataObj.stateProvinceAbbrv.toLowerCase());
  setQueryRestriction(iftaRouteQuery, QueryRestrictionTypes.EQUAL_TO, 'item', fuelCardDataObj.item);

  const minutesBeforeTransaction = moment.utc(fuelCardDataObj.transactionDateTime).subtract(TRANSACTION_TIME_THRESHOLD_MS, 'milliseconds').toDate();
  const minutesAfterTransaction = moment.utc(fuelCardDataObj.transactionDateTime).add(TRANSACTION_TIME_THRESHOLD_MS, 'milliseconds').toDate();
  setQueryRestriction(iftaRouteQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'transactionDateTime', minutesAfterTransaction);
  setQueryRestriction(iftaRouteQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'transactionDateTime', minutesBeforeTransaction);

  const iftaFuelCardDataArr = await find(iftaRouteQuery, false, true);

  // if there are duplicates in the outputted array, make all entries unique
  const seenIFTAFuelCardData = {};
  const iftaFuelCardDataArrFiltered = iftaFuelCardDataArr.filter(iftaFuelCardData => {
    const objectId = getAttribute(iftaFuelCardData, 'objectId');

    if (!seenIFTAFuelCardData[objectId]) {
      seenIFTAFuelCardData[objectId] = true;
      return true;
    }
    return false;
  });

  if (returnPreview) {
    iftaFuelCardDataRemoved = iftaFuelCardDataArrFiltered;
    return iftaFuelCardDataRemoved;
  }

  // Now destroy these transactions and return them :(
  const destroyPromises = [];
  iftaFuelCardDataArrFiltered.map(iftaFuelCardData => {
    destroyPromises.push(
      destroyRecord(iftaFuelCardData),
    );
  });

  iftaFuelCardDataRemoved = await Promise.all(destroyPromises);
  return iftaFuelCardDataRemoved;
}

export {
  detectIFTARouteIssues,
  fixDetectedIFTARouteIssues,
  getIFTARoutes,
  getTimezoneString,
  getOdometerReadingsFromIFTARoute,
  findTotalMileageIssues,
  findDayViewIssues,
  areStatesTouching,
  findIFTARouteIssues,
  getFuelCardData,
  migrateIFTARoute,
  removeDuplicateIFTAFuelCardData,
};
