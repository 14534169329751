import { useState } from 'react';

// components
import GlobalDocumentsTable from 'components/GlobalDocuments/GlobalDocumentsTable/GlobalDocumentsTable';
import GlobalDocumentsUploadDialog from 'components/GlobalDocuments/GlobalDocumentsUploadDialog/GlobalDocumentsUploadDialog';
import GlobalDocumentsDeleteDialog from 'components/GlobalDocuments/GlobalDocumentsDeleteDialog/GlobalDocumentsDeleteDialog';
/* HIDDEN FOR NOW */
import GlobalDocumentsEditDialog from 'components/GlobalDocuments/GlobalDocumentsEditDialog/GlobalDocumentsEditDialog';

/**
 * @description Renders components for the global documents module
 * @param {*}
 * @returns
 */
function DocumentsLayout() {
  const [triggerRefreshState, setTriggerRefreshState] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  /* HIDDEN FOR NOW */
  // const [showEditDialog, setShowEditDialog] = useState(false);
  const [selectedDocumentTableContentObj, setSelectedDocumentTableContentObj] = useState(undefined);

  function onTriggerRefreshState() {
    setTriggerRefreshState(!triggerRefreshState);
  }

  function onShowUploadDialog() {
    setShowUploadDialog(true);
  }

  function onShowDeleteDialog(documentTableContentObj) {
    setSelectedDocumentTableContentObj(documentTableContentObj);
    setShowDeleteDialog(true);
  }

  function onShowEditDialog(documentTableContentObj) {
    setSelectedDocumentTableContentObj(documentTableContentObj);
    /* HIDDEN FOR NOW */
    // setShowEditDialog(true);
  }

  function onHideUploadDialog() {
    setShowUploadDialog(false);
  }

  function onHideDeleteDialog() {
    setSelectedDocumentTableContentObj(undefined);
    setShowDeleteDialog(false);
  }

  /* HIDDEN FOR NOW */
  // function onHideEditDialog() {
  //   setSelectedDocumentTableContentObj(undefined);
  //   setShowEditDialog(false);
  // }

  return (
    <div className="flex w-full">
      <GlobalDocumentsTable
        triggerRefreshState={triggerRefreshState}
        onShowUploadDialog={onShowUploadDialog}
        onShowDeleteDialog={onShowDeleteDialog}
        onShowEditDialog={onShowEditDialog}
      />

      <GlobalDocumentsUploadDialog
        visible={showUploadDialog}
        onHideUploadDialog={onHideUploadDialog}
        onTriggerRefreshState={onTriggerRefreshState}
      />

      <GlobalDocumentsDeleteDialog
        visible={showDeleteDialog}
        selectedDocumentTableContentObj={selectedDocumentTableContentObj}
        onHideDeleteDialog={onHideDeleteDialog}
        onTriggerRefreshState={onTriggerRefreshState}
      />

      {/* HIDDEN FOR NOW */}
      {/* <GlobalDocumentsEditDialog
        visible={showEditDialog}
        selectedDocumentTableContentObj={selectedDocumentTableContentObj}
        onHideEditDialog={onHideEditDialog}
        onTriggerRefreshState={onTriggerRefreshState}
      /> */}
    </div>
  );
}

export default DocumentsLayout;
