export const QuerySortOrderTypes = Object.freeze({
  'ASCENDING': 'ascending',
  'DESCENDING': 'descending',
  'ADD_DESCENDING': 'addDescending', // for fall-back sorting (ex. if sort by name but 2 names are the same, sort by property ADD_DESCENDING next)
  'ADD_ASCENDING': 'addAscending',
});

export const QueryRestrictionTypes = Object.freeze({
  'MATCHES_QUERY': 'matchesQuery',
  'MATCHES_KEY_IN_QUERY': 'matchesKeyInQuery',
  'NOT_EQUAL_TO': 'notEqualTo',
  'EQUAL_TO': 'equalTo',
  'LESS_THAN': 'lessThan',
  'LESS_THAN_OR_EQUAL_TO': 'lessThanOrEqualTo',
  'GREATER_THAN': 'greaterThan',
  'GREATER_THAN_OR_EQUAL_TO': 'greaterThanOrEqualTo',
  'DOES_NOT_EXIST': 'doesNotExist',
  'EXISTS': 'exists',
  'CONTAINS': 'contains',
  'CONTAINED_IN': 'containedIn',
  'NOT_CONTAINED_IN': 'notContainedIn',
  'MATCHES': 'matches',
  'DISTINCT': 'distinct',
  'SKIP': 'skip',
  'LIMIT': 'limit',
});
