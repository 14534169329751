import React from 'react';
import PropTypes from 'prop-types';
import { Map, GeoJSON, Marker } from 'react-leaflet';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { lineString } from '@turf/turf';

// JSON
import iftaStates from 'assets/ifta/ifta_states.json';

// CSS
import 'leaflet/dist/leaflet.css';
import './styles.scss';


class IFTARouteBreakdownMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showStateProvinceInfo: false,
      stateProvinceInfo: { name: 'Total', distanceTravelled: (props.mileageBreakdown && props.mileageBreakdown.total) || 0 },
    }
  }

  renderMarkers() {
    const { state, props } = this;

    // icon to render
    let markerIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow,
      iconAnchor:   [12, 40],
      shadowAnchor: [12, 40],
    });
  
    L.Marker.prototype.options.icon = markerIcon;

    return props.locationsArr.map((location) => {  
      const latitude = location.geometry.location.lat();
      const longitude = location.geometry.location.lng();
      
      return (
        <>
          <Marker position={[latitude, longitude]} />
        </>
      );
    });
  }

  renderRoute() {
    const { state, props } = this;

    if (props.routeLatLngArr && props.routeLatLngArr.length > 0) {
      // reverse coordinates from [lat, lng] to [lng, lat] for GeoJSON 
      props.routeLatLngArr.forEach(coordinate => {
        const lat = coordinate[0];
        const lng = coordinate[1];

        coordinate[0] = lng;
        coordinate[1] = lat;
      });

      const route = lineString(props.routeLatLngArr);

      return (
        <>
          <GeoJSON
            data={route}
            style={() => {
              return { color: 'black' }
            }}
          />
        </>
      );
    }
  }

  renderGeoJSON() {
    const { state, props } = this;

    return iftaStates.features.map(stateProvince => {
      return (
        <>
          <GeoJSON
            key={stateProvince.properties.code}
            data={stateProvince}
            style={() => {
              return { fillColor: stateProvince.properties.name === state.stateProvinceInfo.name ? 'white' : 'blue' }
            }}
            onMouseOver={() => this.setState({
              showStateProvinceInfo: true,
              stateProvinceInfo: {
                name: stateProvince.properties.name,
                distanceTravelled: (props.mileageBreakdown && props.mileageBreakdown[stateProvince.properties.name]) ? props.mileageBreakdown[stateProvince.properties.name] : 0
              }
            })}
            onMouseOut={() => this.setState({
              showStateProvinceInfo: false,
              stateProvinceInfo: {
                name: 'Total',
                distanceTravelled: (props.mileageBreakdown && props.mileageBreakdown.total) || 0,
              }
            })}
          />
        </>
      );
    });
  }

  render() {
    const { state, props } = this;
  
    return (
      <>
        <Map
          center={[49.069434, -98.467324]}
          zoom={3}
          style={{ position: 'relative', width: '100%', height: '50vh' }}
          attributionControl={false}
        >
          { this.renderGeoJSON() }

          {!props.isFetchingRoute && (
            <>
              { this.renderRoute() }
              { this.renderMarkers() }
            </>
          )}

          {props.mileageBreakdown && (
            <div className="ifta-route-breakdown-map-info-card">
              <h5>{ state.stateProvinceInfo.name }</h5>
              <div>Distance Travelled: <strong>{ state.stateProvinceInfo.distanceTravelled } km</strong></div>
            </div>
          )}
        </Map>
      </>
    );
  }
}

IFTARouteBreakdownMap.propTypes = {
  locationsArr: PropTypes.array, // array of location objects
  mileageBreakdown: PropTypes.object,
  routeLatLngArr: PropTypes.array,
  isFetchingRoute: PropTypes.bool,
}

export default IFTARouteBreakdownMap;
