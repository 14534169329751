import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import AddEditButton from 'components/AddEditModal/container/AddEditButton';

// Actions
import { addVehicleToState } from 'actions/Vehicle';
import { updateDriverForState } from 'actions/Driver';
import * as ActionConstants from 'actions/ActionConstants';

// API
import { vehicleTypes } from 'api/Lists/EquipmentTypes';
import { StateProvinces } from 'api/Lists/StateProvinces';
import * as Setters from 'api/Setters';
import * as Getters from 'api/Getters';

// Context
import StoreContext from 'contexts/StoreContext';

function getInitialState() {
  const initialState = {};
  initialState.addVehicleFormFields = [
    [
      { label: 'Unit ID', value: '', type: 'text', required: true, property: 'unitId', limit: '10' }
    ],
    [
      { label: 'MVI Decal #', value: '', type: 'text', property: 'MVIDecalNumber' },
      { label: 'Next Inspection Date', value: null, type: 'date', property: 'nextInspectionDate' },
      { label: 'Inspection Frequency (months)', value: '', type: 'number', property: 'inspectionFrequency' }
    ],
    [
      { label: 'License Plate', required: true, value: '', type: 'text', property: 'plate' },
      { label: 'Plate Jurisdiction', required: true, value: '', type: 'select', property: 'stateProvince', selectArr: StateProvinces.map(stateProvince => stateProvince.name).concat('Other') },
    ],
    [
      { label: 'VIN #', value: '', type: 'text', property: 'vin' },
      { label: 'Type of Vehicle', value: '', type: 'select', property: 'type', selectArr: vehicleTypes }
    ],
    [
      { label: 'Engine Module ID:', value: '', type: 'text', property: 'eldHardwareSerial' },
      { label: 'Dashcam Hardware ID:', value: '', type: 'text', property: 'dashcamHardwareId' }
    ],
    [
      { label: 'Notes', value: '', type: 'textarea', property: 'notes' }
    ],
  ];
  initialState.addingInProgress = false;
  return initialState;
}

class AddNewVehicleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.checkOverwriteDriver = this.checkOverwriteDriver.bind(this);
    this.addVehicle = this.addVehicle.bind(this);
    this.refreshState = this.refreshState.bind(this);
  }

  checkOverwriteDriver(driverParseArr) {
    // hack for driverArr --> one driver
    if (driverParseArr[0].get('vehicle')) {
      const overwriteMessage = <div><div>{driverParseArr[0].get('user_fullName')} is currently with vehicle <b>{driverParseArr[0].get('vehicle').get('unitId')}</b>.</div><div> Are you sure you want to move {driverParseArr[0].get('user_fullName')} to this vehicle?</div></div>;
      this.setState({ ...this.state, overwriteMessage });
      return true;
    }
    return false;
  }

  addVehicle(formValues) {
    const promise = new Promise((resolve, reject) => {
      const vehicleValuesObject = { ...formValues };
      const unitId = vehicleValuesObject.unitId.trim();
      Getters.queryCompanyObjects('Vehicle', undefined, undefined, [{ queryType: 'equalTo', name: 'unitId', value: unitId }, { queryType: 'equalTo', name: 'enabled', value: true }], undefined, undefined, true, false).then((vehicleParseObj) => {
        if (vehicleParseObj) {
          reject('Vehicle Unit ID already exists');
        } else {
          if (vehicleValuesObject.driver) {
            vehicleValuesObject.drivers = [vehicleValuesObject.driver];
            vehicleValuesObject.user_fullName = [vehicleValuesObject.driver.get('user_fullName')];
            delete vehicleValuesObject.driver;
          }

          if (vehicleValuesObject.vendor) {
            vehicleValuesObject.vendor_name = vehicleValuesObject.vendor.get('name');
          }

          if (vehicleValuesObject.stateProvince) {
            const chosenPlateJurisdictionCode = StateProvinces.filter(stateProvince => {
              return stateProvince.name === vehicleValuesObject.stateProvince;
            })[0].code;
            vehicleValuesObject.stateProvince = chosenPlateJurisdictionCode;
          }

          vehicleValuesObject.enabled = true;

          Setters.createNewVehicle(vehicleValuesObject).then((newVehicleParseObj) => {
            addVehicleToState(newVehicleParseObj);
            if (vehicleValuesObject.driver) {
              updateDriverForState(vehicleValuesObject.driver, 'vehicle', newVehicleParseObj);
              if (vehicleParseObj.get('unitId')) {
                updateDriverForState(vehicleValuesObject.driver, 'vehicle_unitId', newVehicleParseObj.get('unitId').toLowerCase());
              }
            }

            this.setState(getInitialState());
            resolve();
            // if (vehicleValuesObject.trailer) {
            //   updateTrailerForState(vehicleValuesObject.trailer, 'vehicle', vehicleParseObj);
            //   updateTrailerForState(vehicleValuesObject.trailer, 'vehicle_unitId', vehicleParseObj.get('unitId'));
            // }
          }, error => {
            this.setState({ ...this.state, error: error.message });
            reject(error.message);
          });
        }
      });
    });
    return promise;
  }

  refreshState() {
    this.setState(getInitialState());
  }

  render() {
    return (
      <AddEditButton
        className={this.props.className}
        refreshState={this.refreshState}
        handleAddEdit={this.addVehicle}
        error={this.state.error}
        title="Vehicle"
        formTemplate={this.state.addVehicleFormFields}
        addingInProgress={this.props.Vehicle.status === ActionConstants.ADD_VEHICLE_INPROGRESS}
        subtitle="* FMCSA requires Unit IDs to be less than or equal to 10 characters"
      />
    );
  }
}

AddNewVehicleButton.propTypes = {
  Vehicle: PropTypes.object.isRequired,
  className: PropTypes.string
};

const mapStateToProps = (state) => {
  const { Vehicle } = state;
  return {
    Vehicle,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(AddNewVehicleButton);

