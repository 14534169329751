import { t } from 'api/Translate';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "../../../sbHistory";

// Actions
import * as Trailer from 'actions/Trailer';
import * as Vehicle from 'actions/Vehicle';

// API
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { convertDistance } from 'api/Helpers';
import * as TI from 'api/TripInspection';

// Components
import Dialog from 'material-ui/Dialog';
import DatePicker from 'components/DatePicker/container/FormControlDatePicker';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import TextField from 'material-ui/TextField';
import CircularProgress from 'material-ui/CircularProgress';
import TripInspectionView from './TripInspectionView';

import TimePicker from 'rc-time-picker';

// CSS
import styles from './TripInspections.module.scss';
import 'rc-time-picker/assets/index.css';

// Context
import StoreContext from 'contexts/StoreContext';
import AddEditTripInspection from '../../AddEditTripInspection/container/AddEditTripInspection';

class TripInspections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addTripInspectionDialog: {
        show: false,
      },
      editTripInspectionDialog: {
        show: false,
        tripInspection: undefined,
        locationDescription: undefined,
        removeState: false,
        dateTime: undefined,
        distanceUnit: undefined,
      },
      tripInspectionDetails: {
        vehicle: null,
        trailer1: null,
        trailer2: null,
        container: null,
      },
      changesProcessing: false,
    };

    this.toggleEditTripInspectionDialog = this.toggleEditTripInspectionDialog.bind(this);
    this.editTripInspection = this.editTripInspection.bind(this);
    this.viewTripInspectionDetails = this.viewTripInspectionDetails.bind(this);
  }

  componentDidMount() {
  }

  toggleEditTripInspectionDialog(tripInspection, removeState) {
    const newState = { ...this.state };
    const { editTripInspectionDialog } = newState;

    if (!editTripInspectionDialog.show) {
      editTripInspectionDialog.show = true;
      editTripInspectionDialog.tripInspection = tripInspection;
      editTripInspectionDialog.locationDescription = tripInspection.get('locationDescriptionCA') ? tripInspection.get('locationDescriptionCA') : tripInspection.get('locationDescriptionUS');
      editTripInspectionDialog.distanceUnit = getPDispatcherPropertyFromState('distanceUnit');
      editTripInspectionDialog.odometerValue = tripInspection.get('odometerKm') ? (editTripInspectionDialog.distanceUnit === 'mi' ? (convertDistance(tripInspection.get('odometerKm'), 'km', 'mi')) : tripInspection.get('odometerKm')) : 0;
      editTripInspectionDialog.removeState = removeState || tripInspection.get('disabled');
      editTripInspectionDialog.dateTime = tripInspection.get('dateTime');
    } else {
      editTripInspectionDialog.show = false;
      editTripInspectionDialog.tripInspection = undefined;
      editTripInspectionDialog.locationDescription = undefined;
      editTripInspectionDialog.removeState = undefined;
      editTripInspectionDialog.dateTime = undefined;
      newState.changesProcessing = false;
      editTripInspectionDialog.distanceUnit = undefined;
    }

    this.setState(newState);
  }

  editTripInspection(property, value, submit) {
    if (submit) {
      const { driver, scaleToDriverTimezone } = this.props;
      let timezoneOffsetFromUTC = (scaleToDriverTimezone && driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();
      if (scaleToDriverTimezone && this.props.timezoneOffsetFromUTC) timezoneOffsetFromUTC = this.props.timezoneOffsetFromUTC;

      return this.setState({ ...this.state, changesProcessing: true }, () => {
        const { editTripInspectionDialog } = this.state;
        TI.editTripInspection(editTripInspectionDialog.tripInspection, {
          locationDescriptionCA: editTripInspectionDialog.locationDescription,
          locationDescriptionUS: editTripInspectionDialog.locationDescription,
          dateTime: moment.tz(editTripInspectionDialog.dateTime, 'YYYY-MM-DDTHH:mm:ss', timezoneOffsetFromUTC).toDate(),
          disabled: editTripInspectionDialog.removeState,
          odometerKm: editTripInspectionDialog.distanceUnit === 'mi' ? convertDistance(editTripInspectionDialog.odometerValue, 'mi', 'km') : parseFloat(editTripInspectionDialog.odometerValue),
        }).then(
          success => this.toggleEditTripInspectionDialog()
        );
      });
    }
    const newState = { ...this.state };
    const { editTripInspectionDialog } = newState;
    let dateTime = moment(editTripInspectionDialog.dateTime);

    if (property === 'time' || property === 'date') {
      if (property === 'time') {
        dateTime.hour(value.getHours());
        dateTime.minute(value.getMinutes());
      } else {
        // property === date
        dateTime.year(value.getFullYear());
        dateTime.month(value.getMonth());
        dateTime.date(value.getDate());
      }

      dateTime = dateTime.toDate();
      editTripInspectionDialog.dateTime = dateTime;
    } else {
      editTripInspectionDialog[property] = value.trim();
    }
    this.setState(newState);
  }

  viewTripInspectionDetails(tripInspection) {
    history.push({
      pathname: this.props.location.pathname, search: "view=tripInspections&tripInspection=" + tripInspection.id,
    });
  }

  render() {
    let { company, tripInspections, eldDailyCertification, driver, scaleToDriverTimezone } = this.props;
    const { editTripInspectionDialog, changesProcessing } = this.state;
    const distanceUnit = getPDispatcherPropertyFromState('distanceUnit');
    const aobrdSetting = company && company.get('aobrdSetting');

    let timezoneOffsetFromUTC = (scaleToDriverTimezone && driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();
    if (scaleToDriverTimezone && this.props.timezoneOffsetFromUTC) timezoneOffsetFromUTC = this.props.timezoneOffsetFromUTC;

    tripInspections = tripInspections.filter(tripInspection => tripInspection && !tripInspection.get('disabled'));
    // passing now in case eldDailyCertification is undefined
    const startTimeUTC = eldDailyCertification ? moment(eldDailyCertification.get('startTimeUTC')).tz(timezoneOffsetFromUTC) : moment().tz(timezoneOffsetFromUTC);
    const endTimeUTC = moment(startTimeUTC).tz(timezoneOffsetFromUTC).add(1, 'day').subtract(1, 'millisecond');

    // ui categories
    const categoryStyle = { display: 'inline-block' };
    const titleStyle = { fontSize: '.9em' };

    let actions = [
      <FlatButton
        key="cancelEdit"
        label={t('Cancel')}
        onClick={() => this.toggleEditTripInspectionDialog()}
      />,
      <RaisedButton
        key="saveEdit"
        label={t('Confirm')}
        onClick={() => this.editTripInspection(undefined, undefined, true)}
        primary
        style={{ marginLeft: '.5em' }}
        disabled={!editTripInspectionDialog.locationDescription || !editTripInspectionDialog.locationDescription.trim()}
      />,
    ];

    if (changesProcessing) {
      actions = [<CircularProgress />];
    }

    window.Localize.translatePage();
    return (
      <div className={styles.tripInspections}>
        <div className={`${styles.title} translate-me`}>
          Trip Inspections
          {/* WIP
          <Button
            sbVariant="slim"
            className="p-button-secondary floatRight"
            icon={<span className="material-icons mr-1">add</span>}
            label="Create Trip Inspection"
            onClick={() => this.setState({ ...this.state, addTripInspectionDialog: { ...this.state.addTripInspectionDialog, show: true } })}
          /> */}
        </div>

        {/* wip <AddEditTripInspection
          show={this.state.addTripInspectionDialog.show}
          hideModal={() => this.setState({ ...this.state, addTripInspectionDialog: { ...this.state.addTripInspectionDialog, show: false } })}
        /> */}

        {tripInspections.map((tripInspection) => {
          return (
            <TripInspectionView
              tripInspection={tripInspection}
              toggleEditTripInspectionDialog={this.toggleEditTripInspectionDialog}
              timezoneOffsetFromUTC={timezoneOffsetFromUTC}
              distanceUnit={distanceUnit}
            />
          );
        })}

        {tripInspections.length === 0 &&
          <div
            className={styles.emptyText}
          >
            No Trip Inspections Found
          </div>
        }

        {editTripInspectionDialog.show &&
          (
            <Dialog
              className={`${styles.editTripInspectionDialog} translate-me`}
              bodyClassName="dialog-tripinspection-edit"
              title={`Edit Trip Inspection`}
              actions={actions}
              open={editTripInspectionDialog.show}
              onRequestClose={() => this.toggleEditTripInspectionDialog()}
              autoScrollBodyContent
              contentStyle={{ width: '70em' }}
              modal
            >
              {!editTripInspectionDialog.removeState ?
                (
                  <div>
                    <div style={categoryStyle}>
                      <table>
                        <tr>
                          <td>
                            <div style={titleStyle}>
                              Time of Inspection
                              <table>
                                <td>
                                  <DatePicker
                                    className="input"
                                    type="date"
                                    selected={moment(editTripInspectionDialog.dateTime).tz(timezoneOffsetFromUTC)}
                                    // selected={this.state.estimatedArrivalDate}
                                    onChange={(date) => this.editTripInspection('date', date)}
                                    // onChange={(date) => this.handleDateChange(date)}
                                    style={{ display: 'inline' }}
                                    dateFormat="MMM d, yyyy"
                                    autoComplete="off"
                                  />
                                  {/* <DatePicker
                                    hintText={moment(editTripInspectionDialog.dateTime).tz(timezoneOffsetFromUTC).format('YYYY-MM-DD')}
                                    value={moment(editTripInspectionDialog.dateTime).tz(timezoneOffsetFromUTC).format('YYYY-MM-DD')}
                                    formatDate={(dateTime) => moment(dateTime).tz(timezoneOffsetFromUTC).format('MMM D, YYYY')}
                                    style={{ display: 'inline-block' }}
                                    textFieldStyle={{ width: '8em' }}
                                    mode="landscape"
                                    className="notranslate"
                                  /> */}
                                </td>
                                <td>
                                  <TimePicker
                                    showSecond={false}
                                    format="h:mm a"
                                    value={moment(editTripInspectionDialog.dateTime).tz(timezoneOffsetFromUTC)}
                                    onChange={(dateTime) => this.editTripInspection('time', dateTime.toDate())}
                                    className="notranslate"
                                  />
                                </td>
                              </table>
                            </div>
                          </td>
                          <td>
                            <div style={{ ...categoryStyle, marginLeft: '3em' }}>
                              <div style={titleStyle}>
                                Location Description
                              </div>
                              <TextField
                                id="tripInspectionLocation"
                                defaultValue={editTripInspectionDialog.locationDescription || ''}
                                hintText="Vancouver, British Columbia"
                                onChange={(e, value) => this.editTripInspection('locationDescription', value)}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div>
                              <div style={titleStyle}>
                                Odometer {editTripInspectionDialog.distanceUnit && `(${editTripInspectionDialog.distanceUnit})`}
                              </div>
                              <TextField
                                value={editTripInspectionDialog.odometerValue}
                                type="number"
                                hintText={`Odometer (${editTripInspectionDialog.distanceUnit})`}
                                onChange={(e, value) => this.editTripInspection('odometerValue', value)}
                              />
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                )
                :
                (
                  <div>
                    Are you sure you wish to remove this Trip Inspection?
                  </div>
                )
              }
            </Dialog>
          )
        }

      </div>
    );
  }
}

TripInspections.propTypes = {
  tripInspections: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  eldDailyCertification: PropTypes.object,
  location: PropTypes.object.isRequired,
  driver: PropTypes.object,
  scaleToDriverTimezone: PropTypes.bool,
};

const mapStateToProps = state => {
  const { Vehicle, Trailer } = state;
  return {
    Vehicle,
    Trailer,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(TripInspections);
