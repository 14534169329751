export const ELDEditType = Object.freeze({
    'UNKNOWN': 0,
    'MAJOR': 1,
    'ADD_NOTE': 2,
    'UNIDENTIFIED_ACCEPTANCE': 3,
    'EDIT_SWAP': 4,
    'OVERRIDE_MAJOR': 5,
});

export const ELDEditCompleted = Object.freeze({
    'UNSEEN_NEW': {
        status: 0,
        description: 'Awaiting Driver',
        key: 'UNSEEN_NEW',
        colorKey: 'UNSEEN',
    }, // ELD Mode
    'REJECTED': {
        status: 1,
        description: 'Rejected',
        key: 'REJECTED',
        colorKey: 'REJECTED',
    }, // Reject on ELD Mode
    'COMPLETED': {
        status: 2,
        description: 'Completed',
        key: 'COMPLETED',
        colorKey: 'ACCEPTED',
    }, // Accepted on ELD Mode
    'AOBRD_DRIVING_TIME_EDIT_UNSEEN': {
        status: 3,
        description: 'Awaiting Driver (AOBRD)',
        key: 'AOBRD_DRIVING_TIME_EDIT_UNSEEN',
        colorKey: 'UNSEEN',
    }, // Nothing has happened
    'AOBRD_DRIVING_TIME_EDIT_PENDING_REJECTED': {
        status: 4,
        description: 'Rejected - Pending (AOBRD)',
        key: 'AOBRD_DRIVING_TIME_EDIT_PENDING_REJECTED',
        colorKey: 'REJECTED',
    }, // Dispatcher rejected edit, app (tablet) is pending to process the rejection
    'AOBRD_DRIVING_TIME_EDIT_PENDING_ACCEPTED': {
        status: 5,
        description: 'Accepted - Pending (AOBRD)',
        key: 'AOBRD_DRIVING_TIME_EDIT_PENDING_ACCEPTED',
        colorKey: 'ACCEPTED',
    }, // Dispatcher accepted edit, app (tablet) is pending to process the acceptance
    'AOBRD_DRIVING_TIME_EDIT_REJECTED': {
        status: 6,
        description: 'Rejected (AOBRD)',
        key: 'AOBRD_DRIVING_TIME_EDIT_REJECTED',
        colorKey: 'REJECTED',
    }, // Dispatcher rejected edit, app has processed the rejection
    'AOBRD_DRIVING_TIME_EDIT_ACCEPTED': {
        status: 7,
        description: 'Accepted (AOBRD)',
        key: 'AOBRD_DRIVING_TIME_EDIT_ACCEPTED',
        colorKey: 'ACCEPTED',
    }, // Dispatcher accepted edit, app has processed the acceptance
});
