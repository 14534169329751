import moment from 'moment-timezone';
import * as ActionConstants from './ActionConstants';
import store from '../store';

import * as Setters from '../api/Setters';
import * as Getters from '../api/Getters';
import { assignTrackerToTrailer } from '../api/Equipment/Trailer.old';

function setVehicleLocationOnTrailer(trailerArr) {
  trailerArr.filter(async (trailer) => {
    if (trailer && trailer.id) {
      const tc_devices = await trailer.get('tc_devices');
      const hasValidPosition = tc_devices && (tc_devices.get('tc_positions') || tc_devices.get('assetLocation'));
      if (hasValidPosition) {
        if (tc_devices.get('tc_positions')) { // Calamp uses mi/h
          const locationObj = tc_devices.get('tc_positions');
          const vehicleLocation = locationObj.clone();
          // console.log(tc_positions.get('devicetime'));
          // console.log(moment(tc_positions.get('devicetime')).add(7, 'hours').toDate());
          vehicleLocation.set('dateTime', locationObj.get('devicetime'));
          vehicleLocation.set('speedKm', locationObj.get('speed') * 1.852);
          vehicleLocation.set('gpsHeading', locationObj.get('course'));
          vehicleLocation.set('ignition', locationObj.get('ignition'));
          vehicleLocation.set('tempCelsius', locationObj.get('tempCelsius'));
          trailer.set('vehicleLocation', vehicleLocation);
          return true;
        } else if (tc_devices.get('assetLocation')) { // Queclink uses km/h
          const locationObj = tc_devices.get('assetLocation');
          const vehicleLocation = locationObj.clone();
          // console.log(tc_positions.get('devicetime'));
          // console.log(moment(tc_positions.get('devicetime')).add(7, 'hours').toDate());
          vehicleLocation.set('dateTime', locationObj.get('devicetime'));
          vehicleLocation.set('speedKm', locationObj.get('speed'));
          vehicleLocation.set('gpsHeading', locationObj.get('course'));
          vehicleLocation.set('ignition', locationObj.get('ignition'));
          vehicleLocation.set('tempCelsius', locationObj.get('tempCelsius'));
          trailer.set('vehicleLocation', vehicleLocation);
          return true;
        }
      }
    }
    return false;
  });
}


export function fetchingTrailers() {
  return {
    type: ActionConstants.FETCH_TRAILERS_INPROGRESS,
  };
}

export function fetchTrailersSuccess(trailers) {
  setVehicleLocationOnTrailer(trailers);
  return {
    type: ActionConstants.FETCH_TRAILERS_SUCCESS,
    trailers,
  };
}

export function fetchTrailersError() {
  return {
    type: ActionConstants.FETCH_TRAILERS_ERROR,
  };
}

export function deleteTrailers() {
  return {
    type: ActionConstants.DELETE_TRAILERS,
  };
}

export function addingTrailer() {
  return {
    type: ActionConstants.ADD_TRAILER_INPROGRESS,
  };
}

export function addTrailerSuccess(trailer) {
  return {
    type: ActionConstants.ADD_TRAILER_SUCCESS,
    trailer,
  };
}

export function addTrailerError(error) {
  return {
    type: ActionConstants.ADD_TRAILER_ERROR,
    error,
  };
}

export function updatingTrailer(trailer) {
  return {
    type: ActionConstants.UPDATE_TRAILER_INPROGRESS,
    trailer,
  };
}

export function updateTrailerSuccess(trailer) {
  setVehicleLocationOnTrailer([trailer]);
  return {
    type: ActionConstants.UPDATE_TRAILER_SUCCESS,
    trailer,
  };
}

export function updateTrailerError(trailer, error) {
  return {
    type: ActionConstants.UPDATE_TRAILER_ERROR,
    trailer,
    error,
  };
}

export function disableTrailersSuccess(trailers) {
  return {
    type: ActionConstants.DISABLE_TRAILERS_SUCCESS,
    trailers,
  };
}

export function enableTrailersSuccess(trailers) {
  return {
    type: ActionConstants.DISABLE_TRAILERS_SUCCESS,
    trailers,
  };
}

export function disableTrailer(trailerParseObj) {
  const promise = new Promise((resolve, reject) => {
    trailerParseObj.set('enabled', false).save().then(
      trailerObject => {
        store.dispatch(disableTrailersSuccess([trailerObject]));
        resolve();
      },
    );
  });
  return promise;
}

export function enableTrailer(trailerParseObj) {
  const promise = new Promise((resolve, reject) => {
    trailerParseObj.set('enabled', true).save().then(
      trailerObject => {
        store.dispatch(enableTrailersSuccess([trailerObject]));
        resolve();
      },
    );
  });
  return promise;
}

export function deleteTrailer(trailerParseObj) {
  const promise = new Promise((resolve, reject) => {
    trailerParseObj.set('isHidden', true).save().then(
      trailerObject => {
        store.dispatch(disableTrailersSuccess([trailerObject]));
        resolve();
      },
    );
  });
  return promise;
}

export function getTrailerIncludeArr() {
  return ['vendor', 'licensePlate', 'tc_devices', 'tc_devices.tc_positions', 'tc_devices.assetLocation', 'belongsToCompany'];
}

export function fetchTrailersForState(page, limit, filter, sortBy, ignoreEnabledBool) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingTrailers());
    let finalFilter = [].concat(filter, { name: 'unitId', queryType: 'exists' });
    finalFilter.push({ name: 'isHidden', queryType: 'notEqualTo', value: true });
    if (!ignoreEnabledBool) {
      finalFilter = finalFilter.concat({ name: 'enabled', queryType: 'equalTo', value: true });
    }
    Getters.queryCompanyObjects('Trailer', page, limit, finalFilter, sortBy, getTrailerIncludeArr()).then(
      trailers => {
        store.dispatch(fetchTrailersSuccess(trailers));
        resolve(trailers);
      },
      error => {
        store.dispatch(fetchTrailersError(error));
        reject(error);
      }
    );
  });
  return promise;
}

export function getLinkedTrailersForState(page, limit, filter, sortBy, ignoreEnabledBool, company) {
  // For CompanyLinks
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingTrailers());
    if (!company || !company.id) {
      store.dispatch(fetchTrailersSuccess());
      return;
    }
    const companyLinkFilter = [
      {
        queryType: 'equalTo',
        name: 'parentCompany',
        value: company,
      }, {
        queryType: 'equalTo',
        name: 'authorized',
        value: true,
      },
    ];
    Getters.queryCompanyObjects('CompanyLink', undefined, undefined, companyLinkFilter, undefined, undefined, false, true, null, true).then((companyLinkArr) => {
      let finalFilter = [].concat(filter, { name: 'unitId', queryType: 'exists' });
      if (!ignoreEnabledBool) finalFilter = finalFilter.concat({ name: 'enabled', queryType: 'equalTo', value: true });
      // Filter for company and child companies
      const trailerQueryPromises = [];
      for (let i = 0; i < companyLinkArr.length; i++) {
        trailerQueryPromises.push(
          Getters.queryCompanyObjects(
            'Trailer',
            undefined,
            undefined,
            finalFilter.concat({ queryType: 'equalTo', name: 'belongsToCompany', value: companyLinkArr[i].get('childCompany') }),
            sortBy,
            getTrailerIncludeArr(),
            false,
            true,
            null,
            true
          )
        );
      }
      // Uses multiple filters because otherwise it overloads the server..?
      trailerQueryPromises.push(
        Getters.queryCompanyObjects(
          'Trailer',
          undefined,
          undefined,
          finalFilter.concat({ queryType: 'equalTo', name: 'belongsToCompany', value: company }),
          sortBy,
          getTrailerIncludeArr(),
          false,
          true,
          null,
          true,
        )
      );
      Promise.all(trailerQueryPromises).then((trailerResults) => {
        store.dispatch(fetchTrailersSuccess([].concat(...trailerResults)));
        resolve([].concat(...trailerResults));
      }, error => {
        store.dispatch(fetchTrailersError(error));
        reject(error);
      });
    }, error => {
      store.dispatch(fetchTrailersError(error));
      reject(error);
    });
  });
  return promise;
}


export function deleteTrailersForState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteTrailers());
    resolve(store.getState().Trailer);
  });
  return promise;
}

export function addTrailerToState(trailerParseObj) {
  return store.dispatch(addTrailerSuccess(trailerParseObj));
}

export async function updateTrailerForState(trailerParseObj, property, value) {
  store.dispatch(updatingTrailer(trailerParseObj));
  return new Promise(async (resolve, reject) => {
    if (property === 'tc_devices_uniqueid') {
      assignTrackerToTrailer(value, trailerParseObj.id)
        .then((result) => {
          store.dispatch(updateTrailerSuccess(result));
          resolve(result);
        }).catch((error) => {
          store.dispatch(updateTrailerError(error));
          reject(error);
        });
    } else {
      // user-based attributes
      // Solves some weird thing with tc_positions.deviceid being a number not a string
      const revertedTrailerParseObj = await trailerParseObj.fetch();
      revertedTrailerParseObj.set(property, value).save()
        .then(savedTrailerObject => {
          store.dispatch(updateTrailerSuccess(savedTrailerObject));
          resolve(savedTrailerObject);
        },
          error => {
            store.dispatch(updateTrailerError(error));
            reject(error);
          }
        );
    }
  });
}


export function updateTrailersLocationsForState(trailerArr, sort) {
  const promise = new Promise((resolve, reject) => {
    const trailers = trailerArr || store.getState().Trailer.trailers;
    Getters.queryCompanyObjects('Trailer', undefined, undefined, [{ queryType: 'containedIn', name: 'objectId', value: trailers.map((trailer) => trailer.id) }], sort, getTrailerIncludeArr(), false, true).then(
      (updatedTrailers) => {
        store.dispatch(fetchTrailersSuccess(updatedTrailers));
        resolve(updatedTrailers);
      },
      error => reject(error)
    );
  });
  return promise;
}
