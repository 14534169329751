import React, { useState, useEffect } from 'react';

// Enums
import { LocationDirectionDescription } from 'sb-csapi/dist/enums/LocationDirection';

// SBCore
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Dropdown from 'sbCore/Dropdown/Dropdown';

// Styles
import './style.scss';

/**
 * @description Location direction dropdown
 * 
 * @param {String} [className] - Custom container className
 * @param {String} [dropdownClassName] - Custom dropdown className
 * @param {Object} [style] = Custom inline styles
 * @param {String} [label] - Custom label
 * @param {Bool} [warning] - Makes the border yellow
 * @param {Bool} [error] - Makes the border red
 * @param {LocationDirection} locationDirection - The LocationDirection type
 * @param {Bool} [hideLabel] - Hide the label
 * @param {Bool} [disabled] - Whether to disable the dropdown or not
 * @param {Function} onSelect - Callback function that returns the selected location direction
 * @param {String} [variant] - Smaller dropdown
 * 
 * @example
 * <LocationDirectionDropdown locationDirection={LocationDirection.N} onSelect={(e) => handleSelect(e)} />
 */
function LocationDirectionDropdown({ ...props }) {
  const [selectedLocationDirection, setSelectedLocationDirection] = useState(props.locationDirection);

  // Parse location direction enum into dropdown options
  const locationDirectionOptions = Object.entries(LocationDirectionDescription).map(([key, value]) => {
    return { value: key, label: `${value} (${key})` };
  });

  // Set the LocationDirectionDescription.N item as default
  const defaultOption = locationDirectionOptions[0];

  /* ------------------- useEffect ------------------ */
  // Check to see if dropdown value is changed from parent
  useEffect(() => {
    if (props.locationDirection !== selectedLocationDirection) {
      setSelectedLocationDirection(props.locationDirection);
    }
  }, [props.locationDirection])

  /* ------------------- Helpers ------------------ */
  function onChange(e) {
    setSelectedLocationDirection(e.value);
    if (props.onSelect) props.onSelect(e.value);
  }

  /* --------------------- Misc -------------------- */
  let className = 'sbc-location-direction-dropdown';
  if (props.className) className += ` ${props.className}`;

  let variant = '';
  if (props.variant === 'small') variant += 'small';

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && <InputLabel>{props.label || 'Heading'}</InputLabel>}
      <Dropdown
        className={props.dropdownClassName}
        value={selectedLocationDirection || defaultOption}
        options={locationDirectionOptions}
        onChange={(e) => onChange(e)}
        onBlur={(e) => props.onBlur && props.onBlur(e)}
        disabled={props.disbaled}
        error={props.error}
        warning={props.warning}
        variant={variant}
      />
    </div>
  )
}

export default LocationDirectionDropdown;
