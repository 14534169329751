import React, { useState, useEffect } from 'react';

// CSAPI
import { getRecordByObjectId, getCurrentUserSessionToken } from 'sb-csapi/dist/AAPI';

// Components
import AccountingView from 'components/Dispatch/AccountingView/AccountingView';

// sbCore Components
import Button from 'sbCore/Button/Button';

// Styles
import './style.scss';

/**
 * @description Provides the top level layout for accounting. Hosts the accounting table and drawer.
 * @returns
 */
function AccountingLayout({ ...props }) {

  // ** useStates ** //
  const [selectedDispatchJob, setSelectedDispatchJob] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isRefreshNeeded, setIsRefreshNeeded] = useState(false);

  // ** useEffects ** //

  // A test useEffect that retrieves a DispatchJob for testing
  useEffect(() => {
    let didCancel = false;

    async function _getDispatchJob() {
      const _dispatchJob = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'DispatchJob', 'lwDCjWIf5U', ['customerDispatchOrganization']);
      if (!didCancel) setSelectedDispatchJob(_dispatchJob);
    }

    _getDispatchJob();
    return () => { didCancel = true; };
  }, [isRefreshNeeded]);

  // Reset the selected DispatchJob when a refresh is needed
  useEffect(() => {
    setSelectedDispatchJob(null);
  }, [isRefreshNeeded]);

  // ** Callback Functions ** //

  // When a table row is clicked, pass back the DispatchJob and open the drawer
  function openDrawer(data) {
    const { dispatchJob } = data;
    setSelectedDispatchJob(dispatchJob);
    setIsDrawerOpen(true);
  }

  // Close the drawer and refresh state
  function closeDrawer() {
    setIsDrawerOpen(false);
    setIsRefreshNeeded(!isRefreshNeeded);
  }

  return (
    <div className="dispatch-accounting-layout">
      <AccountingView />
    </div>
  );
}

export default AccountingLayout;
