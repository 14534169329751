import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "../../../sbHistory";
import { RaisedButton, MenuItem, SelectField, Table, TableHeader, TableBody, TableRow, TableRowColumn } from 'material-ui';

// API
import * as Helpers from 'api/Helpers';

// Components
import HOSRegulationIcon from 'components/HOSRegulationIcon/view/HOSRegulationIcon';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './FullMonthView.module.scss';

// Enums
import { ExcludedRegulations } from 'sb-csapi/dist/enums/HOS/Regulation';

// Assets
const check = require('assets/images/violations/check.png');

function FullMonthView(props) {
  const { timezoneOffsetFromUTC } = props;
  const filteredHiddenViolationsEldDailyCertificationArr = props.eldDailyCertificationArr.filter((eldDailyCertificationObj) => {
    // console.log(eldDailyCertificationObj.get('hosViolations'));
    // console.log(eldDailyCertificationObj.get('hosViolations').filter((hosViolation) => !hosViolation.get('isHidden')));
    // console.log((props.selectedViolationFilter.value !== 'hideHidden' || eldDailyCertificationObj.get('hosViolations').filter((hosViolation) => !hosViolation.get('isHidden')).length > 0));
    return (props.selectedViolationFilter.value !== 'hideHidden' || eldDailyCertificationObj.get('hosViolations').filter((hosViolation) => (!hosViolation.get('isHidden') && !hosViolation.get('hideForever'))).length > 0)
  });
  return (
    <div className={styles.table}>
      <Table
        fixedHeader
      >
        <TableHeader
          adjustForCheckbox={false}
          displaySelectAll={false}
          style={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          <TableRow>
            <TableRowColumn style={{ textAlign: 'center' }}>{t(`Date ${timezoneOffsetFromUTC ? '(Driver Time)' : '(Local)'}`)}</TableRowColumn>
            <TableRowColumn style={{ textAlign: 'center' }}>{t('Violations')}</TableRowColumn>
            <TableRowColumn style={{ textAlign: 'right' }}>
              { props.generatingHOSViolationMonthlyReport &&
                <LoadingIcon />
              }
              { !props.generatingHOSViolationMonthlyReport &&
                <RaisedButton
                  icon={<i className="material-icons">print</i>}
                  label={t('Print')}
                  onClick={props.generateReport}
                  primary
                />
              }
            </TableRowColumn>
          </TableRow>
        </TableHeader>
        <TableBody
          displayRowCheckbox={false}
        >
          { filteredHiddenViolationsEldDailyCertificationArr.length > 0 && filteredHiddenViolationsEldDailyCertificationArr.map((eldDailyCertification) => (
            <TableRow key={eldDailyCertification.id}>
              <TableRowColumn style={{ textAlign: 'center' }}>
                <b>{ moment(eldDailyCertification.get('startTimeUTC')).tz(timezoneOffsetFromUTC || moment.tz.guess()).format('LL') }</b>
              </TableRowColumn>
              <TableRowColumn style={{ textAlign: 'center' }} >
                <div className={styles.violationIconRow}>
                  { eldDailyCertification.get('hosViolations') && eldDailyCertification.get('hosViolations').length > 0 && eldDailyCertification.get('hosViolations').filter(hosViolation => props.selectedViolationFilter.value === 'hideHidden' ? !hosViolation.get('isHidden') : !hosViolation.get('isHidden')).filter((hosViolation) => hosViolation.get('disabled') !== true && !hosViolation.get('potential') && !(ExcludedRegulations.includes(hosViolation.get('regulationInt')))).map((hosViolation) =>
                    <div className={styles.violationIcon}><HOSRegulationIcon key={hosViolation.id} HOSRegulationInt={hosViolation.get('regulationInt')} /></div>)
                      }
                  { !(eldDailyCertification.get('hosViolations') && eldDailyCertification.get('hosViolations').filter((hosViolation) => hosViolation.get('disabled') !== true).length > 0) &&
                    <img height="40em" style={{ display: 'inline' }} src={check} role="presentation" />
                  }
                </div>
              </TableRowColumn>
              <TableRowColumn style={{ textAlign: 'center' }} >
                <RaisedButton
                  icon={<i className="material-icons">chevron_right</i>}
                  label="Go To Log"
                  onClick={() => history.push({ pathname: 'driver', search: "driver=" + props.driver.id + "&view=hosEvents&date=" + moment(Helpers.referenceToTimezone(eldDailyCertification.get('startTimeUTC'), props.driver.get('timezoneOffsetFromUTC'))).format('DDMMYY') })}
                  primary
                  style={{ float: 'right', margin: '1em' }}
                />
              </TableRowColumn>
            </TableRow>
              )) }
          { props.fetched && filteredHiddenViolationsEldDailyCertificationArr.length === 0 &&
            <TableRow>
              <TableRowColumn colSpan="42" style={{ textAlign: 'center', fontWeight: 'bold', verticalAlign: 'middle' }}>
                {t('No Logs with Violations for this Month')}
              </TableRowColumn>
            </TableRow>
          }
          { !props.fetched &&
            <TableRow>
              <TableRowColumn colSpan="42" className="loadingIconPadding">
                <LoadingIcon />
              </TableRowColumn>
            </TableRow>
          }
        </TableBody>
      </Table>
    </div>
  );
}

FullMonthView.propTypes = {
  driver: PropTypes.object.isRequired,
  eldDailyCertificationArr: PropTypes.array.isRequired,
  fetched: PropTypes.bool.isRequired,
  generatingHOSViolationMonthlyReport: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  handleViewChange: PropTypes.func.isRequired,
  generateReport: PropTypes.func.isRequired,
  timezoneOffsetFromUTC: PropTypes.string,
  selectedViolationFilter: PropTypes.object,
};

export default FullMonthView;
