import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import history from 'sbHistory';
import store from 'store';


import RaisedButton from 'material-ui/RaisedButton';
import NotificationBox from 'components/NotificationBox/container/NotificationBox';

import { getMissingGlobalTrailerInfo } from 'actions/GlobalTrailer';
import { getMissingGlobalVehicleInfo } from 'actions/GlobalVehicle';
import { getLinkedDriversForState } from 'actions/Driver'

// API
import * as ELD from 'api/ELD'
import * as Helpers from 'api/Helpers'

// Context
import StoreContext from 'contexts/StoreContext';

class HeaderNotificationBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showNotificationBox: false,
      missingGlobalTrailerInfo: {},
      missingGlobalVehicleInfo: {},
      companyReady: false,
      company: {},
      versionOutOfDateDrivers: [],
      currentELDVersion: -1,
    };

    this.toggleShow = this.toggleShow.bind(this);
    this.handleOnClickForTrailers = this.handleOnClickForTrailers.bind(this);
    this.handleOnClickForVehicles = this.handleOnClickForVehicles.bind(this);
    this.handleOnClickForDrivers = this.handleOnClickForDrivers.bind(this);
    this.getCompany = this.getCompany.bind(this);
    this.getCurrentELDVersion = this.getCurrentELDVersion.bind(this);
  }

  async componentDidMount() {
    await this.getCurrentELDVersion();
  }

  componentWillReceiveProps(nextProps) {
    const isChangedGlobalTrailer = nextProps.GlobalTrailer !== this.props.GlobalTrailer;
    const isChangedGlobalVehicle = nextProps.GlobalVehicle !== this.props.GlobalVehicle;

    if (isChangedGlobalTrailer || isChangedGlobalVehicle) {
      const newState = { ...this.state };
      newState.missingGlobalTrailerInfo = getMissingGlobalTrailerInfo();
      newState.missingGlobalVehicleInfo = getMissingGlobalVehicleInfo();
      this.setState(newState);
    }
  }

  toggleShow() {
    const promise = new Promise((resolve, reject) => {
      const newState = { ...this.state, showNotificationBox: !this.state.showNotificationBox };
      this.setState(newState, () => { resolve(true); });
    });
    return promise;
  }

  handleOnClickForTrailers() {
    this.toggleShow().then(() => {
      history.push('equipment?view=trailers');
    });
  }
  handleOnClickForVehicles(objectId) {
    this.toggleShow().then(() => {
      history.push({ pathname: 'vehicle', search: `?vehicle=${objectId}` });
    });
  }

  async handleOnClickForDrivers(objectId) {
    // e.preventDefault();
    await this.toggleShow();
    history.push({ pathname: 'driver', search: "driver=" + objectId });
  }

  async getCompany() {
    const company = store.getState().Company.company;
    await this.setState({ ...this.state, company: company, companyReady: true })
    const filter = [];
    const sort = { user_fullName: 'ascending' };
    const tableView = 'allDrivers';
    const disabled = false;
    getLinkedDriversForState(undefined, undefined, filter, sort, tableView, disabled, company)
      .then(drivers => {
        ELD.filterELDVersionUpdate(drivers).then(fetchedELDDates => {
          this.setState({ ...this.state, versionOutOfDateDrivers: fetchedELDDates });
        });
      });
  }

  async getCurrentELDVersion() {
    const mostCurrentELD = await ELD.getCurrentELDVersion();
    await this.setState({ ...this.state, currentELDVersion: mostCurrentELD });
  }


  render() {
    const { title, address } = this.props;
    const { showNotificationBox, missingGlobalTrailerInfo, missingGlobalVehicleInfo, companyReady, versionOutOfDateDrivers, currentELDVersion } = this.state;

    if (!companyReady) {
      store.getState().Company.fetched ? this.getCompany() : null;
    }

    const isAddressMissingInfo = (!address || !address.get('address'));
    const isTrailerMissingInfo = Object.keys(missingGlobalTrailerInfo).length !== 0;
    const isVehicleMissingInfo = Object.keys(missingGlobalVehicleInfo).length !== 0;
    const isDriverMissingInfo = versionOutOfDateDrivers.length > 0;

    let hasMissingInfo = false;
    if (isTrailerMissingInfo || isVehicleMissingInfo || isDriverMissingInfo) {
      hasMissingInfo = true;
    }
    const trailerInfoDivs = [];
    const vehicleInfoDivs = [];
    const driverInfoDivs = [];

    if (isTrailerMissingInfo) {
      trailerInfoDivs.push(<div key="trailer-title" style={{ fontWeight: '600', paddingBottom: '0.5em', borderBottom: '0.1em solid #dddddd' }} > {'\n'}Trailers are missing information {'\n'}</div>);
      Object.keys(missingGlobalTrailerInfo).map((key, index) => {
        const splitKey = key.split('-');
        const unitId = splitKey[0];
        const objectId = splitKey[1];
        trailerInfoDivs.push(
          <div key={`trailer${objectId}`} style={{ paddingTop: '1em', paddingBottom: '1em' }} className="translate-me">
            <div key={`trailer${objectId}-${index}`} style={{ display: 'inline-block', width: '50%' }}>{'Trailer '}<b><var>{unitId}</var></b> is missing <var>{Object.keys(missingGlobalTrailerInfo[key])}</var></div>
            <div key={`trailer${objectId}-${index}-buttonWrapper`} style={{ display: 'inline-block', textAlign: 'right', width: '50%' }}>
              <RaisedButton
                label="View"
                style={{ verticalAlign: 'middle' }}
                onClick={(e) => { this.handleOnClickForTrailers(); }}
              />
            </div>
          </div>
        );
      });
    }

    if (isVehicleMissingInfo) {
      vehicleInfoDivs.push(<div key="vehicle-title" style={{ fontWeight: '600', paddingBottom: '0.5em', borderBottom: '0.1em solid #dddddd' }} >{'\n'} {'\n'} Vehicles are missing information{'\n'}</div>);
      Object.keys(this.state.missingGlobalVehicleInfo).map((key, index) => {
        const splitKey = key.split('-');
        const unitId = splitKey[0];
        const objectId = splitKey[1];
        vehicleInfoDivs.push(
          <div key={`vehicle${objectId}`} style={{ paddingTop: '1em', paddingBottom: '1em' }} >
            <div key={`vehicle${objectId}-${index}`} style={{ display: 'inline-block', width: '50%' }} >{'Vehicle '}<b><var>{unitId}</var></b> is missing <var>{Object.keys(this.state.missingGlobalVehicleInfo[key])}</var></div>
            <div key={`vehicle${objectId}-${index}-buttonWrapper`} style={{ display: 'inline-block', textAlign: 'right', width: '50%' }}>
              <RaisedButton label="View" style={{ verticalAlign: 'middle' }} onClick={(e) => this.handleOnClickForVehicles(objectId)} />
            </div>

          </div>
        );
      });
    }

    if (isDriverMissingInfo) {
      driverInfoDivs.push(<div key="driver-title" style={{ fontWeight: '600', paddingBottom: '0.5em', borderBottom: '0.1em solid #dddddd' }} >{'\n'} {'\n'} Drivers version(s) are out of date{'\n'}</div>);
      versionOutOfDateDrivers.map((driver, index) => {
        const objectId = driver.id;
        const driverName = Helpers.toTitleCase(driver.get('user_fullName'));
        driverInfoDivs.push(
          <div key={`driver${objectId}`} style={{ paddingTop: '1em', paddingBottom: '1em' }} >
            <div key={`driver${objectId}-${index}`} style={{ display: 'inline-block', width: '50%' }}><b><var>{driverName}</var></b>'s version may be out of date, update to <var>{currentELDVersion}</var></div>
            <div key={`driver${objectId}-${index}-buttonWrapper`} style={{ display: 'inline-block', textAlign: 'right', width: '50%' }}>
              <RaisedButton
                label="View"
                style={{ verticalAlign: 'middle' }}
                onClick={(e) => { this.handleOnClickForDrivers(objectId); }}
              />
            </div>
          </div>
        );
      });
    }
    const iconContainerStyle = {
      position: 'absolute',
      display: 'block',
      left: '50%',
      WebkitTransform: 'translateX(-50%)',
      MozTransform: 'translateX(-50%)',
      Oransform: 'translateX(-50%)',
      transform: 'translateX(-50%)',
    };

    return (
      <div style={hasMissingInfo ? {} : { display: 'none' }}>
        <NotificationBox title={title} show={showNotificationBox} toggleShow={() => this.toggleShow()} iconContainerStyle={iconContainerStyle}>
          <div>
            {isAddressMissingInfo &&
              <div>
                <div style={{ fontWeight: '600', paddingBottom: '0.5em', borderBottom: '0.1em solid #dddddd' }} > {'\n'}Missing Address {'\n'}</div>
                <div>You are missing an Address for your company. Please enter it in the <a onClick={() => history.push('/settings')}><b>Settings</b></a> page</div>
              </div>
            }
            {isTrailerMissingInfo && <div style={{ padding: '.5em' }} />}
            <div>{trailerInfoDivs}</div>
            {isVehicleMissingInfo && <div style={{ padding: '.5em' }} />}
            <div>{vehicleInfoDivs}</div>
            {isDriverMissingInfo && <div style={{ padding: '.5em' }} />}
            <div>{driverInfoDivs}</div>
          </div>
        </NotificationBox>
      </div>
    );
  }
}

HeaderNotificationBox.defaultProps = {
};

HeaderNotificationBox.propTypes = {
  title: PropTypes.any,
  address: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { GlobalTrailer, GlobalVehicle } = state;
  return {
    GlobalTrailer,
    GlobalVehicle,
  };
};

export default withRouter(connect(mapStateToProps, null, null, { context: StoreContext })(HeaderNotificationBox));
