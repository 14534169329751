import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Col, Row, FormControl, HelpBlock, ControlLabel } from 'react-bootstrap';

// Components
import RegisterForm from './RegisterForm';

// CSS
import styles from './OwnerOperatorRegisterScreen.module.scss';

// Assets
const driverBehaviour = require('assets/images/marketing/driver-behaviour.png');

function OwnerOperatorRegisterScreen(props) {
  return (
    <div>
      <div>
        <div className={styles.intro}>
          {/* <div className={styles.back} onClick={props.handleBack}>
            <i className="material-icons">chevron_left</i>
            <div>Back</div>
          </div> */}
          Try Switchboard for free today
          <br />
          <span>Explore our ELD cloud platform and what it can do for your fleet.</span>
          {/* <span>Already have a Switchboard account? <a href={'/login'}>Sign in.</a></span> */}
        </div>
      </div>
      <table className={styles.getUserDetailsTable}><tr>
        <td>
          <div className={styles.registerForm}>
            <div>
              I'm a:
              <table><tr>
                <td>
                  <div>
                    <div className={styles.icon}>
                      <i className="material-icons">check</i>
                    </div>
                    <div className={styles.title} onClick={() => props.handleUserTypeChange('fleet')}>
                      Fleet or Safety Manager
                      {/* <br />
                      <span>Two or more vehicles</span> */}
                    </div>
                  </div>
                </td>
                <td>
                  <div>
                    <div className={styles.icon}>
                      <i className="material-icons">check</i>
                    </div>
                    <div className={styles.title} >
                      Owner Operator
                      {/* <br />
                      <span>Two or more vehicles</span> */}
                    </div>
                  </div>
                </td>
              </tr></table>
            </div>
            <RegisterForm
              {...props}
            />
          </div>
        </td>
        <td>
          <div className={styles.endorsement}>
            <img src={driverBehaviour} />
            {/* <div className={styles.logo}><img src={logo} /></div>
            <div className={styles.profilePic}><img src={profilePic} /></div>
            <div>
              <div className={styles.quote}>
                “Switchboard is truly built to what my fleet, dispatch, and safety divisions need. It’s simple to use, and also has all the features required for proper compliance and fleet management.”
              </div>
              <br />
              <div>
                <b>Daman Grewal</b>
                <br />
                <i>Operations Manager</i>
              </div>
            </div> */}
          </div>
        </td>
      </tr></table>
    </div>
  );
}

OwnerOperatorRegisterScreen.propTypes = {
  handleBack: PropTypes.func.isRequired,
  getValidationState: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  User: PropTypes.object.isRequired,
  registerError: PropTypes.object,
  agreeTerms: PropTypes.bool.isRequired,
  handleAgreeTerms: PropTypes.func.isRequired,
};

export default OwnerOperatorRegisterScreen;
