import React from 'react';
import PropTypes from 'prop-types';

// Components
import DatePicker from 'components/DatePicker/container/DatePicker';
import ChooseDriverButton from 'components/ChooseDriver/container/ChooseDriverButton';

// CSS
import styles from './AddNewJobForm.module.scss';

// const validate = values => {
//   const errors = {};
//   if (!values.email) {
//     errors.email = 'Please enter an email.';
//   } else if (isInvalidEmailFormat(values.email)) {
//     errors.email = 'Invalid Email Address.';
//   }

//   if (!values.password) {
//     errors.password = 'Please enter a password';
//   }
//   return errors;
// };

function AddNewJobForm(props) {
  // let customer = <span></span>;
  const driverObjects = props.job.drivers;
  let driverNamesSpan;
  if (driverObjects && driverObjects.length > 0) {
    if (driverObjects[1]) {
      driverNamesSpan = (
        <span className={styles.chosenDrivers}>
          {`${driverObjects[0].get('user').get('firstName')}`},&nbsp;
          {`${driverObjects[1].get('user').get('firstName')}`}
        </span>
      );
    } else {
      driverNamesSpan = (
        <span className={styles.chosenDrivers}>{`${driverObjects[0].get('user').get('firstName')}`}</span>
      );
    }
  }

  return (
    <table className={styles.addNewJobForm}><tbody>
      <tr>
        <td>
          <div className={styles.formGroup}>
            <label className="inputLabel">Driver</label>
            {driverNamesSpan &&
              <div className={styles.driverInput}>
                { driverNamesSpan }
                <br />
              </div>
            }
            <ChooseDriverButton chooseDriversHandler={props.chooseDriversHandler} />
          </div>
        </td>
        <td>
          <div className={styles.formGroup}>
            <label className="inputLabel">Job ID</label>
            <input className="input" value={props.job.jobId} type="text" placeholder="....." onChange={(e) => props.handleFormChange('jobId', e.target.value)} />
          </div>
        </td>
        <td>
          <div className={styles.formGroup}>
            <label className="inputLabel">Start Date</label>
            <DatePicker className="input" selected={props.job.date} placeholderText="....." onChange={(date) => props.handleFormChange('date', date)} /><br />
          </div>
        </td>
      </tr>
      <tr>
        <td colSpan="3">
          <div className={styles.formGroupTextarea}>
            <label className="inputLabel">Notes</label>
            <textarea value={props.job.notes} onChange={(e) => props.handleFormChange('notes', e.target.value)} placeholder="Any comments related to the job..."></textarea>
          </div>
        </td>
      </tr>
    </tbody></table>
  );
}

AddNewJobForm.propTypes = {
  chooseDriverModalShow: PropTypes.bool.isRequired,
  closeChooseOrAddModal: PropTypes.func.isRequired,
  openChooseOrAddModal: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  job: PropTypes.object.isRequired,
  chooseDriversHandler: PropTypes.func,
};

export default AddNewJobForm;
