export const FreightTypes = Object.freeze({
  0: 'Full Truckload',
  1: 'Barrel',
  2: 'Box',
  3: 'Carton',
  4: 'Crate',
  5: 'Pallet',
});

export const TrailerType = Object.freeze({
  0: 'Container - 20\'',
  1: 'Container - 20\' High Cube',
  2: 'Container - 40\'',
  3: 'Container - 40\' High Cube',
  4: 'Dry Van',
  5: 'Flat Deck',
  6: 'Oversize',
  7: 'Temperature Controlled - Frozen',
  8: 'Temperature Controlled - Heated',
  9: 'Temperature Controlled - Refrigerated',
  10: 'Temperature Controlled - Multi-temp',
});

export const CommodityTypes = Object.freeze({
  0: 'Custom',
  1: 'Dry Van',
  2: 'Flat Deck',
  3: 'Oversize',
  4: 'Temperature Controlled - Frozen',
  5: 'Temperature Controlled - Heated',
  6: 'Temperature Controlled - Refrigerated',
  7: 'Temperature Controlled - Multi-temp',
});

export const Accessorials = Object.freeze({
  0: { name: 'Appointment - Delivery', dataType: 'datetime', defaultPrice: '', isCustom: false },
  1: { name: 'Appointment - Pickup', dataType: 'datetime', defaultPrice: '', isCustom: false },
  2: { name: 'Tailgate - Delivery', dataType: undefined, defaultPrice: '', isCustom: false },
  3: { name: 'Tailgate - Pickup', dataType: undefined, defaultPrice: '', isCustom: false },
  4: { name: 'Temperature Service', dataType: 'temperature', defaultPrice: '', isCustom: false },
  5: { name: 'Tarping', dataType: undefined, defaultPrice: '', isCustom: false },
  6: { name: 'Prepull', dataType: undefined, defaultPrice: '', isCustom: false },
  7: { name: 'Redelivery', dataType: 'datetime', defaultPrice: '', isCustom: false },
  8: { name: 'Chassis', dataType: undefined, defaultPrice: '', isCustom: false },
  9: { name: 'Storage', dataType: undefined, defaultPrice: '', isCustom: false },
  10: { name: 'Detention', dataType: undefined, defaultPrice: '', isCustom: false },
  11: { name: 'Layover', dataType: undefined, defaultPrice: '', isCustom: false },
  12: { name: 'Chassis Split', dataType: undefined, defaultPrice: '', isCustom: false },
  13: { name: 'Expedite Fee', dataType: 'pricing', defaultPrice: '', isCustom: false },
  14: { name: 'Fuel Surcharge', dataType: 'percentage', defaultPrice: '', isCustom: false },
  15: { name: 'Custom Accessorials', dataType: undefined, defaultPrice: '', isCustom: true },
});

export const AttributeTypes = Object.freeze({
  'CREATED_AT': 'createdAt',
});
