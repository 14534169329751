import React from 'react';
import { DragSource } from 'react-dnd';
import { DNDTypes } from 'enums/DNDTypes';

/**
 * Specifies the drag source contract.
 * Only `beginDrag` function is required.
 */
const actionSource = {
  beginDrag(props) {
    // Return the data describing the dragged item
    const item = { index: props.index }
    return item
  },

  // endDrag(props, monitor, component) {
  //   if (!monitor.didDrop()) {
  //     return
  //   }

  //   // When dropped on a compatible target, do something
  //   const item = monitor.getItem()
  //   const dropResult = monitor.getDropResult()
  //   CardActions.moveCardToList(item.id, dropResult.listId)
  // },
}

/**
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging(),
  }
}

function SBDraggableRow(props) {
  // Your component receives its own props as usual
  const { id } = props

  // These two props are injected by React DnD,
  // as defined by your `collect` function above:
  const { isDragging, connectDragSource } = props

  return connectDragSource(<tr>{props.children}</tr>);
}

export default DragSource(DNDTypes.ACTION, actionSource, collect)(SBDraggableRow)