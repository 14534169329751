import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// components
import CardItem from 'sbCore/CardItem/CardItem';
import Timeline from 'sbCore/Timeline/Timeline';

// styles
import './style.scss';

/**
 * @description A timeline of events for a given trip
 *
 * @param {String} tripData - The IFTA data for a specific trip
 * @param {String} [lengthUnit] - From enums LengthUnit - KM, MI
 * @param {Object} [style] - Custom in-line styles
 *
 * @param {Any} [label] - A small header that appears above <leading>
 * @param {Any} leading - Consider this the main content of the card
 * @param {Any} [subheading] - A small subtitle that appears below <leading>
 * @param {Any} [trailing] - Content that is right-justified from the <leading>
 * @param {String} [sbVariant] - Supported: ["flat" for no shadows]
 *
 * @todo Add isEditiable props for each editable aspect of the CardItem
 *
 * @example
 *  <CardItem
 *    label="Origin"
 *    leading="Vancouver, BC"
 *    subheading="(123.456, -789.123)"
 *    trailing="10:45"
 *  />
 */
function RouteTimeline({ ...props }) {

  const [timelineDataObjects, setTimelineDataObjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // we want to convert tripData into timelineData by normalizing location data, fuelling data, etc
    let didCancel = false;

    async function init() {
      // Keep in mind when dealing with coordinates and mapbox, they do [lng, lat]
      setIsLoading(true);

      const { vehicleLocations } = props.tripData;
      const _timelineDataObjects = [];
      let previousTimelineDataObject = {}; // keep track of the last one that was made

      // if there is fuelling data (or other data besides location), merge and sort by time so
      // everything is chronological
      vehicleLocations.map((vehicleLocation, i) => {
        // Avoid printing duplicate locations, but we also want to print the last 2 (ex. if there's only 2 items total, we want to print both)
        const isDuplicate = vehicleLocations[i - 1]
                            && (vehicleLocations[i - 1].aprxShortName === vehicleLocation.aprxShortName)
                            && (vehicleLocations[i - 1].stateProvince === vehicleLocation.stateProvince)
                            && (vehicleLocations.length > 2);

        if (isDuplicate) return;

        const timelineDataObject = getTimelineDataObject(vehicleLocation);

        _timelineDataObjects.push(timelineDataObject);
      });

      // reset previousTimelineDataObject for the next iterations
      previousTimelineDataObject = {};

      // if somehow there's nothing at all to show, default to the "No Data" timelinedataobject
      if (_timelineDataObjects.length === 0) {
        _timelineDataObjects.push(getTimelineDataObject());
      }

      if (!didCancel) {
        setTimelineDataObjects(_timelineDataObjects);
        setIsLoading(false);
      }
    }

    init();

    return () => { didCancel = true; };
  }, [props.tripData]);

  /**
   * Functions
   */
  function getTimelineDataObject(vehicleLocation) {
    const timelineDataObject = {
      leading: 'N/A',
      subheading: 'No data available',
      trailing: '-',
    };

    if (vehicleLocation) {
      timelineDataObject.leading = `${vehicleLocation.aprxShortName}, ${vehicleLocation.stateProvince.toUpperCase()}`;
      timelineDataObject.subheading = `(${vehicleLocation.location._latitude}, ${vehicleLocation.location._longitude})`;
      timelineDataObject.trailing = `${moment(vehicleLocation.dateTime).format('HH:mm')}`;
    }

    return timelineDataObject;
  }

  /**
   * JSX
   */
  function timelineItemTemplate(item) {
    const { leading, subheading, trailing } = item;

    return (
      <div className="timeline-item-container">
        <div className="left-half">
          <div className="leading">{ leading || <span>&nbsp;</span> }</div>
          <div className="subheading">{ subheading || <span>&nbsp;</span> }</div>
        </div>
        <div className="right-half">
          <div className="leading">{ trailing || <span>&nbsp;</span> }</div>
          <div className="subheading">&nbsp;</div>
        </div>
      </div>
    );
  }

  const timelineTemplate = () => (
    <Timeline
      value={timelineDataObjects}
      content={(item) => timelineItemTemplate(item)}
    />
  );

  let className = 'ifta-route-timeline';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className} style={props.style}>
      <CardItem
        label="Timeline"
        sbVariant="flat"
        leading={timelineTemplate()}
      />
    </div>
  );
}

export default RouteTimeline;
