
/** @module DispatchOrganization */

// API
import { getAbbreviatedPrefix } from 'api/Company/Company';
import {
  addRecord, copyQuery, count, createQuery, createQueryOr, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord
} from 'sb-csapi/dist/AAPI';

// Enums
import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

// SBObjects
import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';


/**
 * @memberof module:DispatchOrganization
 *
 * @description Get DispatchOrganizations according to filter criteria
 *
 * @param {object} options - See example
 * @param {string} companyObjectId - Company we wish to retrieve organizations for
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {bool} includeChildCompanies - Include child/sub-companies of this parent company
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { object } - { dispatchOrganizations: [DispatchOrganization, ...] }
 */
async function getDispatchOrganizations(options = { sessionToken: getCurrentUserSessionToken() }, companyObjectId = getCurrentUserCompanyObjectId(), includeChildCompanies, filters = [], sortBy = new Sort(QuerySortOrder.ASCENDING, 'organizationName'), includedPointers = [], selectedAttributes = [], queryAll) {

  let dispatchOrganizationQuery = createQuery('DispatchOrganization');

  const _filters = [...filters];

  const companyQuery = createQuery('Company');
  setQueryRestriction(companyQuery, QueryRestriction.EQUAL_TO, 'objectId', companyObjectId);

  if (includeChildCompanies) {
    // if getting child companies' organizations, the additional queries
    const companyLinkQuery = createQuery('CompanyLink');

    const companyLinkFilters = [
      new Filter(QueryRestriction.MATCHES_QUERY, 'parentCompany', companyQuery),
      new Filter(QueryRestriction.EQUAL_TO, 'authorized', true),
    ];

    companyLinkFilters.map(filter => {
      setQueryRestriction(companyLinkQuery, filter.queryRestriction, filter.attribute, filter.value);
    });

    // now that we have all the child companies, create queries that get the drivers of them
    const companyLinks = await findRecords(options, companyLinkQuery);
    const childCompanyDispatchOrganizationQueries = companyLinks.map(company => {
      const childCompanyDispatchOrganizationQuery = createQuery('DispatchOrganization');
      setQueryRestriction(childCompanyDispatchOrganizationQuery, QueryRestriction.EQUAL_TO, 'belongsToCompany', getAttribute(company, 'childCompany'));
      return childCompanyDispatchOrganizationQuery;
    })

    // the main current user's company's organization query
    const mainCompanyDispatchOrganizationQuery = createQuery('DispatchOrganization');
    setQueryRestriction(mainCompanyDispatchOrganizationQuery, QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery);

    // altogether
    dispatchOrganizationQuery = createQueryOr([mainCompanyDispatchOrganizationQuery, ...childCompanyDispatchOrganizationQueries]);

  } else {
    // lock queries to the current users company
    setQueryRestriction(dispatchOrganizationQuery, QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery);
  }

  // set universal driver filters
  // _filters.push(new Filter(QueryRestriction.NOT_EQUAL_TO, 'isHidden', true)); // get all non-deleted organizations

  // mainQueryFilters are filters we want to apply to the entire/end-game query of multiple subqueries (ie. The parent query of all "OR"-subqueries)
  let mainQueryFilters = [
    new Filter(QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery),
  ];
  let secondaryDispatchOrganizationQuery = undefined; // defined if an OR query is to be created to account organizationName and organizationId search

  _filters.find(filter => {
    if (filter.attribute === 'organizationName' || filter.attribute === 'organizationId') {
      secondaryDispatchOrganizationQuery = copyQuery(dispatchOrganizationQuery, true); // copy everything the dispatchOrganizationQuery was up to this point
      setQueryRestriction(secondaryDispatchOrganizationQuery, QueryRestriction.MATCHES, filter.attribute === 'organizationName' ? 'organizationId' : 'organizationName', filter.value);
      return true;
    }
  });

  _filters.map(filter => {
    setQueryRestriction(dispatchOrganizationQuery, filter.queryRestriction, filter.attribute, filter.value);

    if (secondaryDispatchOrganizationQuery && (filter.attribute !== 'organizationName') && (filter.attribute !== 'organizationId')) {
      // if an OR query exists for organizationName/id, apply the very same filters as the first query (barring restrictions to organizationName/id)
      setQueryRestriction(secondaryDispatchOrganizationQuery, filter.queryRestriction, filter.attribute, filter.value);

      if (filter.queryRestriction === QueryRestriction.LIMIT) {
        mainQueryFilters.push(new Filter(filter.queryRestriction, undefined, filter.value));
      }
    }
  });

  if (secondaryDispatchOrganizationQuery) {
    dispatchOrganizationQuery = createQueryOr([dispatchOrganizationQuery, secondaryDispatchOrganizationQuery]);
    mainQueryFilters.forEach((mainQueryFilter) => {
      setQueryRestriction(dispatchOrganizationQuery, mainQueryFilter.queryRestriction, mainQueryFilter.attribute, mainQueryFilter.value);
    })
  }

  // ignore pagination for now. not needed
  // at this point, copy current query to get the number of pages for pagination
  // let driverCountQuery = copyQuery(dispatchOrganizationQuery);

  // sort
  sortQuery(dispatchOrganizationQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchOrganizationQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchOrganizationQuery, selectedAttributes);

  // now get the count and the drivers
  // const promises = [count(options, dispatchOrganizationCountQuery), findRecords(options, dispatchOrganizationQuery, false, queryAll)];
  const promises = [findRecords(options, dispatchOrganizationQuery, false, queryAll)];

  try {
    // const [totalDispatchOrganizationsCount, dispatchOrganizations] = await Promise.all(promises);
    const [dispatchOrganizations] = await Promise.all(promises);

    return { dispatchOrganizations };

  } catch (err) {
    throw new Error(err);
  }
}


/**
 * @memberof module:DispatchOrganization
 *
 * @description Update a dispatch organization
 *
 * @param {dispatchOrganization} dispatchOrganization - The dispatchOrganization to update. If not available, use parameter dispatchOrganizationObjectId instead
 * @param {string} dispatchOrganizationObjectId - The objectId of the dispatchOrganization object we wish to update. Use if parameter dispatchOrganization is unavailable
 * @param {object} keyValueObj - The select fields we wish to update
 * @param {bool} save - If true, executes save on the changes. If false, holds the changes locally but is not saved
 *
 * @returns {DispatchOrganization} - The updated dispatchOrganization
 */
async function updateDispatchOrganization(dispatchOrganization, dispatchOrganizationObjectId, keyValueObj, save) {
  if (!dispatchOrganization && !dispatchOrganizationObjectId) throw new Error('Missing Arguments: Must provide dispatchOrganization or dispatchOrganizationObjectId');
  if (dispatchOrganization && dispatchOrganizationObjectId) throw new Error('Arguments: Must provide only one of dispatchOrganization or dispatchOrganizationObjectId');

  let _dispatchOrganization = dispatchOrganization;

  try {
    if (!dispatchOrganization && dispatchOrganizationObjectId) {
      const dispatchOrganizationQuery = createQuery('dispatchOrganization');
      setQueryRestriction(dispatchOrganizationQuery, QueryRestriction.EQUAL_TO, 'objectId', dispatchOrganizationObjectId);
      _dispatchOrganization = await findRecords({ sessionToken: getCurrentUserSessionToken() }, dispatchOrganizationQuery, true);
    }

    if (_dispatchOrganization) {
      if (!Object.keys(keyValueObj).length) return _dispatchOrganization;

      _dispatchOrganization = await updateRecord({ sessionToken: getCurrentUserSessionToken() }, _dispatchOrganization, keyValueObj, save);
      return _dispatchOrganization;
    } else {
      throw new Error(`DispatchOrganization does not exist`);
    }
  } catch (err) {
    throw new Error(err);
  }
}


/**
 * @memberof module:DispatchOrganization
 *
 * @description Add new dispatch organization record
 * @param {object} keyValueObj - The select fields we wish to save
 *
 * @returns {DispatchOrganization} - The added DispatchOrganization
 */
async function addDispatchOrganization(keyValueObj) {
  try {
    const dispatchOrganization = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'DispatchOrganization', keyValueObj, getCurrentUserCompanyObjectId());
    return dispatchOrganization;
  } catch (err) {
    throw new Error(err);
  }
}


/**
 * @memberof module:DispatchOrganization
 *
 * @description Generates a n-length letter code from a given string
 * @param {String} name - The string to base the code off
 * @param {Number} [length=4] - The length of the code
 *
 * @returns {String} - A code based off the name
 */
function generateDispatchOrganizationCode(name, length = 4) {
  if (!name) return;

  const getRandomInteger = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

  // First, remove all special characters, excluding spaces from the name
  const cleanedName = name.replace(/[^a-zA-Z ]/gi, '');
  const nameArr = cleanedName.split(' ').filter((word) => word);

  // Logic if there is only one word in the name
  if (nameArr.length === 1) {
    const singleWordName = nameArr[0];
    const firstLetter = singleWordName.charAt(0);
    const lastLetter = singleWordName.charAt(singleWordName.length - 1);
    const singleWordNameConsonants = singleWordName.slice(1, -1).replace(/[aeiou ]/gi, '');

    if (singleWordNameConsonants.length >= length - 2) {
      // If theres enough consonants in the name to generate the code, use the consonants (and truncate the the required length)
      // Using (length - 2) since we will always use the first and last letter of the name regardless of whether its a vowel or not
      const prefix = getAbbreviatedPrefix(singleWordNameConsonants, length - 2);
      const finalName = (firstLetter + prefix + lastLetter).toUpperCase();
      return finalName;
    } else {
      // If theres not enough consonants for fill in the remaining characters
      // We will first take all the consonants of the name, then start adding in random characters from the name until we reach the required length
      const lettersArr = singleWordName.split('');
      const fillerLetters = singleWordNameConsonants.split('');
      const lettersRemaining = length - singleWordNameConsonants.length - 2;

      for (let i = 0; i < lettersRemaining; i++) {
        fillerLetters.push(lettersArr[getRandomInteger(0, singleWordName.length - 1)]);
      }

      const finalName = (firstLetter + fillerLetters.join('') + lastLetter).toUpperCase();
      return finalName;
    }
  }

  // If theres multiple words in the name, then get the initials and end initials
  let organizationInitials = cleanedName.split(' ').map((word) => word.charAt(0)).filter((letter) => letter);
  let organizationInitialsEnd = cleanedName.split(' ').map((word) => word.charAt(word.length - 1)).filter((letter) => letter);

  // First check to see if the initials suffice
  if (organizationInitials.length === length) {
    return organizationInitials.join('').toUpperCase();
  } else if (organizationInitials.length > length) {
    // Remove characters from the middle initials
    while (organizationInitials.length > length) {
      const randomIndex = getRandomInteger(1, organizationInitials.length - 2);
      organizationInitials.splice(randomIndex, 1);
    }

    return organizationInitials.join('').toUpperCase();
  }

  // If the initials dont suffice, start adding in some of the end initials
  const organizationCode = [...organizationInitials];
  let numberOfCharactersAdded = 0;

  for (let i = 0; i < organizationInitialsEnd.length; i++) {
    const charToAdd = organizationInitialsEnd[organizationInitialsEnd.length - 1 - i];

    organizationCode.splice(organizationInitialsEnd.length - numberOfCharactersAdded, 0, charToAdd);
    numberOfCharactersAdded += 1;

    if (organizationCode.length === length) {
      return organizationCode.join('').toUpperCase();
    }
  }

  // If adding in the end initials dont suffice, then start adding in random characters from the name
  const nameCharArr = cleanedName.replace(' ', '').split('');

  while (organizationCode.length < length) {
    const randomCharacter = nameCharArr[getRandomInteger(0, nameCharArr.length - 1)];
    const randomIndex = getRandomInteger(1, organizationCode.length - 2);
    organizationCode.splice(randomIndex, 0, randomCharacter);
  }

  return organizationCode.join('').toUpperCase();
}


export {
  addDispatchOrganization,
  getDispatchOrganizations,
  updateDispatchOrganization,
  generateDispatchOrganizationCode,
};
