import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// Components
import HelpDialogView from '../view/HelpDialogView';

class HelpDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
    };
    this.toggleDialog = this.toggleDialog.bind(this);
  }

  toggleDialog() {
    this.setState({ ...this.state, showDialog: !this.state.showDialog });
  }

  render() {
    const { buttonStyle, disabled, title, buttonChildren, children, modal, style } = this.props;
    const { showDialog } = this.state;

    return (
      <div className="translate-me">
        <button
          title="Need Help? Click me!"
          className={`buttonDefaultRound`}
          onClick={() => this.toggleDialog()}
          style={{ position: 'relative', width: '1.8em', height: '1.8em', boxShadow: 'none', ...buttonStyle }}
          disabled={disabled}
        >
          {buttonChildren ?
            buttonChildren
            :
            <div style={{ position: 'relative', marginTop: '-.39em', marginLeft: '-.15em', fontSize: (buttonStyle && buttonStyle.fontSize) || '1.3em' }}>?</div>
          }
        </button>

        {showDialog &&
          (
            <HelpDialogView
              show={showDialog}
              title={title}
              modal={modal}
              style={style}
              onClose={this.toggleDialog}
            >
              {children}
            </HelpDialogView>
          )
        }
      </div>
    );
  }

}

HelpDialog.propTypes = {
  buttonStyle: PropTypes.object,
  buttonChildren: PropTypes.node,
  disabled: PropTypes.bool,

  // for the actual dialog
  title: PropTypes.string,
  children: PropTypes.node,
  modal: PropTypes.bool,
  style: PropTypes.object,
};

export default HelpDialog;
