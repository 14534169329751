import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Dialog from 'material-ui/Dialog';

// Components
import AddEditForm from './AddEditForm';
// CSS
import styles from './AddEditModal.module.scss';

function AddEditModal(props) {

  const actions = [
    <button className="closeButton" onClick={() => props.handleClose()}>Cancel</button>,
    <button className="buttonDefault" onClick={() => props.handleAddEdit()} disabled={props.disableAdd}>Save</button>
  ]

  if (props.error) {
    actions.unshift(
      <div className={styles.error}>
          {props.error}
      </div>
    );
  }
  return (
    <Dialog
      title={props.title}
      actions={actions}
      modal={true}
      open={props.show}
      onRequestClose={props.handleClose}
      contentStyle={{ ...styles }}
      autoScrollBodyContent
      className="translate-me"
    >
      <div className="mt-1 mb-2 text-gray-calm">
        { props.subtitle }
      </div>
      <AddEditForm {...props} />
      <div className={styles.required}><span className="colorRed notranslate">*</span> Required</div>
    </Dialog>
  );
}

AddEditModal.propTypes = {
  handleAddEdit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disableAdd: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  formEntries: PropTypes.array.isRequired,
};

export default AddEditModal;
