import React, { useEffect, useState } from 'react';

// Components
import Button from 'sbCore/Button/Button';

// Styles
import './style.scss';

/**
 * @description Responsive persistent drawer
 * @param {JSX} children The children components to be rendered within the drawer
 * @param {String} [className] - Styles for the overall drawer
 * @param {String} [itemClassName] - Styles for the container of the children prop. Note: apply any custom bg color to this class rather than className
 * @param {String} [buttonClassName] - Styles for the drawer button
 * @param {String} [buttonIconCollapsed] - Custom icon for the drawer button when the drawer is collapsed
 * @param {String} [buttonIconExpanded] - Custom icon for the drawer button when the drawer is expanded
 * @param {Function} [onActiveChanged] - Callback function to indicate a change in the state of the drawer (collapsed/expanded)
 * @param {boolean} [forceOverlay] - forces the drawer to be an overlay even when the screen width is wide enough
 * @param {String} [width] - Custom width for the sidebar
 * @returns JSX
 *
 * @example
 * <div className='flex'>
 *   <PersistentDrawer className="" itemClassName="bg-blue-500" buttonClassName="bg-cyan-500" buttonIconCollapsed="pi pi-angle-double-right" buttonIconExpanded="pi pi-angle-double-left" >
 *     { children }
 *   </PersistentDrawer>
 *   <div>
 *     { mainContent }
 *   </div>
 * </div>
 */
export default function PersistentDrawer({ ...props }) {
  const [isExpanded, setIsExpanded] = useState(undefined); // Indicates whether the drawer is expanded/collapsed

  // This useEffect creates an event listener to check when the window size has changed and lets the drawer automatically collapse/expand
  useEffect(() => {
    const handler = (e) => {
      if (e.matches) {
        setIsExpanded(false);
      } else {
        setIsExpanded(true);
      }
    };

    if (props.width) document.documentElement.style.setProperty("--sidebarMenuWidth", props.width);

    // When first loaded, check to see the window size, and keep drawer closed if the window size is too small
    if (window.matchMedia('(max-width: 1366px)').matches) {
      setIsExpanded(false);
    } else {
      setIsExpanded(true);
    }

    window.matchMedia('(max-width: 1366px)').addEventListener('change', handler);
    return window.matchMedia('(max-width: 1366px)').removeEventListener('change', handler);
  }, []);

  useEffect(() => {
    if (props.isExpanded !== undefined && props.isExpanded !== isExpanded) setIsExpanded(props.isExpanded);
  }, [props.isExpanded]);

  useEffect(() => {
    props.onActiveChanged && props.onActiveChanged(isExpanded);
  }, [isExpanded]);

  let className = 'sbc-persistent-drawer';
  let itemClassName = 'drawer-item-container';
  let buttonClassName = 'drawer-button-expand shadow-3';

  let buttonIconExpanded = props.buttonIconExpanded || 'pi pi-angle-left';
  let buttonIconCollapsed = props.buttonIconCollapsed || 'pi pi-angle-right';

  if (isExpanded) {
    className += ' expanded';
    itemClassName += ' expanded';
    buttonClassName += ' expanded';
  }

  if (props.forceOverlay) className += ' overlay';

  // Apply custom styles to the drawer
  if (props.className) className += ` ${props.className}`;
  if (props.itemClassName) itemClassName += ` ${props.itemClassName}`;
  if (props.buttonClassName) buttonClassName += ` ${props.buttonClassName}`;

  return (
    <div className={className}>
      <div className={itemClassName}>
        {props.children}
      </div>
      <Button className={buttonClassName} icon={isExpanded ? buttonIconExpanded : buttonIconCollapsed} onClick={() => setIsExpanded(!isExpanded)} />
    </div>
  );
}
