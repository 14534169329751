import HOSRegulation from 'enums/HOSRegulation';

export default class ViolationHOS {
  /**
   * 
   * @param {*} objectId string
   * @param {*} regulationInt number
   * @param {*} triggerTime object ( Date )
   * @param {ELDDailyCertification} ELDDailyCertification
   */
  constructor(objectId, regulationInt = 0, triggerTime, eldDailyCertification) {
    this.objectId = objectId;
    this.regulationInt = regulationInt;
    this.triggerTime = triggerTime;
    this.rule = HOSRegulation[regulationInt.toString()] ? HOSRegulation[regulationInt.toString()].RULE : 'General Violation';
    this.eldDailyCertification = eldDailyCertification;
  }
}