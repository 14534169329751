import { t } from 'api/Translate';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import history from "../../../../sbHistory";
import { Popup } from 'react-mapbox-gl';

import { MDBBtn } from 'mdbreact';
// Components
import Skeleton from 'sbCore/Skeleton/Skeleton';


// CSS
import styles from './ComponentStyles.module.scss';

// API
import * as Getters from 'api/Getters';

function GeofencePopup({ coordinates, geofence, editGeofence }) {

  const [vehiclesInGeofenceArr, setVehiclesInGeofenceArr] = useState([]);
  const [trailersInGeofenceArr, setTrailersInGeofenceArr] = useState([]);
  const [isLoadingInGeofence, setIsLoadingInGeofence] = useState(false);

  function goToReport() {
    history.push({ pathname: 'geofence', search: "geofence=" + geofence.id });
  }

  useEffect(() => {
    setVehiclesInGeofenceArr([]);
    setTrailersInGeofenceArr([]);
    // setInvalidDOTNumber(!isValidDOTNumber(getAttribute(belongsToCompany, 'dotNumber')));
    const getVehiclesInGeofence = async (geofenceId) => {
      const equipmentInGeofenceQueryResults = await Promise.all(
        [
          Getters.getVehiclesInGeofence(geofenceId),
          Getters.getTrailersInGeofence(geofenceId)
        ],
      );
      const vehicleArr = equipmentInGeofenceQueryResults[0];
      const trailerArr = equipmentInGeofenceQueryResults[1];
      setVehiclesInGeofenceArr(vehicleArr);
      setTrailersInGeofenceArr(trailerArr);
      setIsLoadingInGeofence(false);
    }
    setIsLoadingInGeofence(true);
    getVehiclesInGeofence(geofence.id);
  }, [geofence]);

  return (
    <Popup
      anchor="bottom"
      coordinates={coordinates}
    >
      <div className={styles.popup}>
        <div className="notranslate"><b>{geofence.get('name')}</b></div>
        <br />
        <div>
          Vehicles in Geofence:
          {vehiclesInGeofenceArr.length > 0 &&
            vehiclesInGeofenceArr.map((vehicleObj) => (
              <div>
                <kbd>{vehicleObj.get('unitId')}</kbd>
              </div>
            ))
          }
          {isLoadingInGeofence &&
            <Skeleton />
          }
          {!isLoadingInGeofence && vehiclesInGeofenceArr.length === 0 &&
            (
              <div>
                <kbd>No Vehicles in Geofence</kbd>
              </div>
            )
          }
        </div>
        <br />
        <div>
          Assets in Geofence:
          {trailersInGeofenceArr.length > 0 &&
            trailersInGeofenceArr.map((obj) => (
              <div>
                <kbd>{obj.get('unitId')}</kbd>
              </div>
            ))
          }
          {isLoadingInGeofence &&
            <Skeleton />
          }
          {!isLoadingInGeofence && trailersInGeofenceArr.length === 0 &&
            (
              <div>
                <kbd>No Assets in Geofence</kbd>
              </div>
            )
          }
        </div>
        <br />
        <MDBBtn size="sm" color="info" style={{ padding: '0.5em' }} onClick={() => goToReport()}>
          {t('Enter/Exit Times')}
        </MDBBtn>
        <br />
        <MDBBtn size="sm" color="secondary" style={{ padding: '0.5em' }} onClick={() => editGeofence(geofence)}>
          {t('Edit Geofence')}
        </MDBBtn>
      </div>
    </Popup>
  );
}

GeofencePopup.propTypes = {
  coordinates: PropTypes.array.isRequired,
  geofence: PropTypes.object.isRequired,
  editGeofence: PropTypes.func.isRequired,
};

export default GeofencePopup;
