// http://londoncycle.alexrieux.fr/
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactMapboxGl, { Feature, Layer, ZoomControl } from 'react-mapbox-gl';

// API
import * as Mapbox from 'api/Mapbox';
import * as Getters from 'api/Getters';

// Components
import VehicleMarker from 'components/Map.old/view/components/VehicleMarker';

// Context
import StoreContext from 'contexts/StoreContext';

const ReactMap = ReactMapboxGl({
  accessToken: Mapbox.getToken(),
});

class VehicleMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: [-98.467324, 49.069434],
      zoom: [2],
      latLngArr: [],
      fitToArr: [],
    };
  }

  async componentDidMount() {
    const vehicleFilter = [].concat(
      {
        name: 'unitId',
        queryType: 'equalTo',
        value: this.props.vehicleUnitId,
      },
    )
    const vehicleWithSameUnitId = await Getters.queryCompanyObjects('Vehicle', undefined, undefined, vehicleFilter, undefined, undefined, false, false, undefined, true);
    const vehicleLocationFilter = [].concat( 
      {
        name: 'vehicle',
        queryType: 'containedIn',
        value: vehicleWithSameUnitId,
      },
      {
        name: 'eldEvent_eldEventTypeCodeInt',
        queryType: 'notEqualTo',
        value: '31',
      },
      {
        name: 'dateTime',
        queryType: 'greaterThanOrEqualTo',
        value: this.props.dateStart,
      },
      {
        name: 'dateTime',
        queryType: 'lessThanOrEqualTo',
        value: this.props.dateEnd,
      }
    );
    const vehicleLocationArr = await Getters.queryCompanyObjects('VehicleLocation', undefined, undefined, vehicleLocationFilter, { dateTime: 'ascending' }, undefined, false, false, undefined, true);
    const filteredVehicleLocationArr = vehicleLocationArr.filter((vl, index) => {
      const loc = vl.get('location');
      return !(loc.latitude === 0 && loc.longitude === 0);
    });
    const latLngArr = filteredVehicleLocationArr.map((vehicleLocation) => ({ lat: vehicleLocation.get('location').latitude, lng: vehicleLocation.get('location').longitude }));
    const lngLatArr = filteredVehicleLocationArr.map((vehicleLocation) => [ vehicleLocation.get('location').longitude, vehicleLocation.get('location').latitude ]);
    this.setState({ ...this.state, latLngArr, fitToArr: Mapbox.getBoundsFromLngLatArr(lngLatArr) });
  }

  render() {
    return (
      <div style={{ height: '100%', width: '100%' }}>
        { this.props.Main.mapboxToken &&
          <ReactMap
            style="mapbox://styles/mapbox/streets-v9"
            accessToken={this.props.Main.mapboxToken}
            fitBounds={this.state.fitToArr && this.state.fitToArr.length > 0 && this.state.fitToArr}
            movingMethod="easeTo"
            zoom={this.state.zoom}
            center={this.state.center}
            containerStyle={{ height: '100%', width: '100%' }}
          >

            <ZoomControl />

            <div>
              <Layer
                type="circle"
                // type="symbol"
                id="gps-points"
                layout={{
                  // 'icon-size': 1,
                  // 'icon-image': 'circle-11',
                }}
                paint={{
                  'circle-radius': 3,
                  'circle-color': '#ABB7B7',
                  'circle-blur': 0.2,
                  'circle-stroke-width': 1,
                  'circle-stroke-color': '#364554',
                  'circle-stroke-opacity': 0.8,
                }}
                >
                { this.state.latLngArr && this.state.latLngArr.map((latLngObj, gpsPointIndex) => {
                  // if (vehicleLocationObj.get('jobAction')) {
                    //   return (
                  //     // POI Markers
                  //     <CompletedMarker
                  //       key={`pointOfInterest${gpsPointIndex}`}
                  //       color={legObject.colour}
                  //       coordinates={Helpers.convertParseVehicleLocationArrToPointArr([vehicleLocationObj], true)}
                  //       handleOnClick={() => this.props.setPopup({ vehicleLocation: vehicleLocationObj, type: 'jobAction' })}
                  //     />
                  //   );
                  // }
                  return (
                    // GPS Location Points
                    <Feature
                      key={`gpsPoint${gpsPointIndex}`}
                      coordinates={[latLngObj.lng, latLngObj.lat]}
                    />
                    );
                  })}
              </Layer>
            </div>

          </ReactMap>
        }
      </div>
    );
  }
}

VehicleMap.propTypes = {
  vehicleUnitId: PropTypes.object,
  dateStart: PropTypes.object,
  dateEnd: PropTypes.object,
  Main: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Main } = state;
  return ({
    Main,
  });
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(VehicleMap);
