import React from 'react';

// sbcore components
import Button from 'sbCore/Button/Button';

// styling
import './style.scss';

/**
 * @description Dispatch Email Button
 * @param {Function} setShowEmail - Required. calls function which shows email dialog when button is clicked
 * @param {boolean} isLoading - Whether or not to disable the email button
 * @returns
 *
 * @example
 * <DispatchEmailButton setShowEmail={setShowEmail} />
 */
const DispatchEmailButton = (props) => {
  return (
    <div className="dispatch-email-button">
      <Button icon="pi pi-envelope" label="Email Document" disabled={props.isLoading} onClick={() => { props.setShowEmail(); }} />
    </div>
  );
};

export default DispatchEmailButton;
