import React from 'react';
import PropTypes from 'prop-types';
import history from "../../../sbHistory";
import moment from 'moment-timezone';
import { RaisedButton, TableHeader, TableBody, TableRow, TableRowColumn } from 'material-ui';

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';

// CSS
import styles from './ViolationsSummaryTableRow.module.scss';

class ViolationsSummaryTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hosViolation: undefined,
    };

    this.getHosViolation = this.getHosViolation.bind(this);
  }

  componentDidMount() {
    this.getHosViolation(this.props.driver, this.props.date);
  }

  componentWillReceiveProps(nextProps) {
    this.getHosViolation(nextProps.driver, nextProps.date);
  }

  getHosViolation(driver, date) {
    Getters.queryCompanyObjects(
      'HOSViolation',
      undefined,
      undefined,
      [
        { queryType: 'equalTo', name: 'driver', value: driver },
        { queryType: 'date', name: 'triggerTime', fromValue: moment(date).startOf('month') },
        { queryType: 'notEqualTo', name: 'regulationInt', value: 100002 },
      ],
      { hosViolationCount: 'descending' },
      [],
      true,
      false
    ).then((hosViolation) => {
      this.setState({ ...this.state, hosViolation });
    });
  }

  render() {
    const fullName = Helpers.toTitleCase(this.props.driver.get('user_fullName'));
    return (
      <TableRow className={styles.driverRow}>

        <TableRowColumn
          style={{ borderRight: '0.01em solid #BFBFBF' }}
        >
          <div className={styles.driver} onClick={() => history.push({ pathname: 'driver', search: "driver=" + this.props.driver.id })}>{ fullName }</div>
        </TableRowColumn>

        <TableRowColumn
          style={{ borderRight: '0.01em solid #BFBFBF', fontSize: '2em', textAlign: 'center' }}
        >
          { this.state.hosViolation && this.state.hosViolation.get('monthlyCount') && this.state.hosViolation.get('monthlyCount') }
          { this.state.hosViolation && !this.state.hosViolation.get('monthlyCount') && '0' }
        </TableRowColumn>

        <TableRowColumn>
          <RaisedButton
            icon={<i className="material-icons">chevron_right</i>}
            label="Violation Details"
            onClick={() => history.push({ pathname: 'driver', search: "driver=" + this.props.driver.id + "&view=hosViolations" })}
            primary
            style={{ float: 'right', margin: '1em' }}
          />
        </TableRowColumn>

      </TableRow>
    );
  }
}

ViolationsSummaryTableRow.propTypes = {
  driver: PropTypes.object.isRequired,
  date: PropTypes.array.isRequired,
};

export default ViolationsSummaryTableRow;
