import React from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import history from 'sbHistory'

// API
import * as PDF from 'api/PDFer';
import * as Helpers from 'api/Helpers';
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL';
import { getELDDailyCertification } from 'api/ELD';
import { getUnidentifiedELDEvents, getNextEldEvents, getPriorIdentifiedDrivingEldEvent, getSuggestedDriversForUD } from 'api/ELD/ELDEvent/ELDEvent';
import { getUnidentifiedELDEdits } from 'api/ELD/ELDEdit/ELDEdit';
import { getAttribute, callCloudFunction, getObjectById, updateRecord, getRecordByObjectId, getCurrentUserSessionToken } from 'sb-csapi/dist/AAPI';
import { getTimezoneAbbreviation } from 'api/utils/timezone';

// Components
import SubNavigationBar from 'components/Shared/SubNavigationBar/SubNavigationBar';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import SBCard from 'components/Shared/SBCard/SBCard';
import Title from 'components/Shared/Title/Title';
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay';
import DriverAutocomplete from 'components/Shared/DriverAutocomplete/DriverAutocomplete.old';
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol } from 'mdbreact';

// CSS
import './styles.scss';

const DEBUG = false;

const initialFilterFormFields = (tempDate) => {
  return [
    {
      attrName: 'month',
      fullName: 'Month',
      type: 'date',
      dateQueryType: 'dateMonth',
      includeLeftRight: true,
      value: tempDate ? momentTz(tempDate, 'DDMMYY') : momentTz(),
    },
  ];
}

class UnidentifiedDrivers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: momentTz().subtract(14, 'days'),
      endDate: momentTz(),
      printInProgress: false,
      isLoading: false,
      dailyCertsByVehicleHash: {},
      nextEldEventsByEldEventsId: {},
      vehiclesCount: {},
      driverAssignChanges: {},
      dailyCertInEditObjectId: undefined,
      assignedDailyCertDriver: undefined,
      assignedDailyCertCoDriver: undefined,
      driver: undefined,
      coDriver: undefined,
      showTimeDetailsForRowArr: [],
      mergedIntervalsByVehicleHash: {},
      validUDEventsByVehicleDailyCertHash: {},
      loadingMessage: "Loading Unidentified Events",
      warningMessage: false
    };
    const tempDate = getQueryParameter(props.location.search, 'date');
    this.state.filterFormFields = initialFilterFormFields(tempDate);
    this.handleFilter = this.handleFilter.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.printReport = this.printReport.bind(this);
    this.handleAssign = this.handleAssign.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleDriverSelection = this.handleDriverSelection.bind(this);
    this.getVehicleUDIntervals = this.getVehicleUDIntervals.bind(this);
    this.mergeVehicleUDIntervals = this.mergeVehicleUDIntervals.bind(this);
    this.filterVehicleUDIntervals = this.filterVehicleUDIntervals.bind(this);
  }

  async componentDidMount() {
    await this.refreshState();
  }

  componentWillReceiveProps(nextProps) {
    this.processDate(nextProps);
  }

  async processDate(nextProps) {
    const props = nextProps || this.props;
    const dQuery = (props.location && getQueryParameter(props.location.search, 'd'));
    if (dQuery === 'lastTwoWeeks') {
      await this.setState(
        {
          ...this.state,
          startDate: momentTz().subtract(14, 'days'),
          endDate: momentTz(),
        },
      );
    } else if (dQuery === '24hours') {
      await this.setState(
        {
          ...this.state,
          startDate: momentTz().subtract(24, 'hours'),
          endDate: momentTz(),
        },
      );
    } else if (dQuery === 'month') {
      const dateString = getQueryParameter(props.location.search, 'date');
      await this.setState(
        {
          ...this.state,
          startDate: dateString ? momentTz(dateString, 'DDMMYY').startOf('month') : momentTz().startOf('month'),
          endDate: dateString ? momentTz(dateString, 'DDMMYY').endOf('month') : momentTz().endOf('month'),
        },
      );
    }
    this.refreshState();
  }

  async refreshState() {
    await this.setState({
      ...this.state,
      isLoading: true,
      dailyCertsByVehicleHash: {},
      nextEldEventsByEldEventsId: {},
      loadingMessage: 'Loading Unidentified Events'
    });
    const unidentifiedELDEvents = await getUnidentifiedELDEvents(this.state.startDate, this.state.endDate);
    const vehiclesCount = {};
    const dailyCertsByVehicleHash = {};
    const unidentifiedELDEdits = await getUnidentifiedELDEdits(unidentifiedELDEvents);
    const _combinedEvents = unidentifiedELDEvents.map(unidentifiedELDEvent => {
      return getNextEldEvents(unidentifiedELDEvent);
    });
    const combinedEvents = await Promise.all(_combinedEvents);

    const nextEldEventsByEldEventsId = {};
    combinedEvents.map(combinedEvent => {
      const eldEventsId = getAttribute(combinedEvent.eldEvent, 'objectId');
      nextEldEventsByEldEventsId[eldEventsId] = combinedEvent;
    });

    unidentifiedELDEvents
      .filter(eldEvent => (
        eldEvent
        && [13, 21, 22].indexOf(getAttribute(eldEvent, 'eldEventTypeCodeInt')) !== -1
      )).map(eldEvent => {
        const vehicle = getAttribute(eldEvent, 'vehicle');
        const vehicleObjectId = getAttribute(vehicle, 'objectId');
        const vehicleUnitId = getAttribute(vehicle, 'unitId');
        const dailyCertObject = getAttribute(eldEvent, 'eldDailyCertification');
        const dailyCertObjectId = getAttribute(dailyCertObject, 'objectId');
        const dailyCertStartTimeUTC = getAttribute(dailyCertObject, 'startTimeUTC');
        let editPending = false;

        unidentifiedELDEdits.map(eldEdit => { // filter out ELDEdits that are pending
          const editedELDEvents = getAttribute(eldEdit, 'eldEventsToBeInactive');
          editedELDEvents.map(editedELDEvent => {
            if (getAttribute(eldEvent, 'objectId') === getAttribute(editedELDEvent, 'objectId')) {
              editPending = true;
            }
          });
        });

        DEBUG && console.log('vehicleUnitId, dailyCertStartTimeUTC, editPending', vehicleUnitId, dailyCertStartTimeUTC, editPending);

        if (dailyCertStartTimeUTC && !editPending) { // don't include the daily certs w/o start time
          // const _combinedEvent = getNextEldEvents(eldEvent);
          // const combinedEvent = await Promise.all(_combinedEvent);
          // const combinedEldEvent = nextEldEventsByEldEventsId[getAttribute(eldEvent, 'objectId')];
          let count = 1;
          if (vehiclesCount[vehicleUnitId]) { // keeps track of vehicle with the same unitIds (so plate # is added to differentiate)
            count += vehiclesCount[vehicleUnitId];
          }
          vehiclesCount[vehicleUnitId] = count;

          if (!dailyCertsByVehicleHash[vehicleObjectId]) {
            dailyCertsByVehicleHash[vehicleObjectId] = {};
            dailyCertsByVehicleHash[vehicleObjectId].vehicle = vehicle;
            dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications = {};
          }

          if (!dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId]) {
            dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId] = {};
            dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId].dailyCertification = dailyCertObject;
            dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId].eldEvents = [];
          }
          dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId].eldEvents.push(eldEvent);
          // dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId].combinedEvents.push(combinedEvent);
        }
      }
      );
    if (DEBUG === true) console.log('dailyCertsByVehicleHash', dailyCertsByVehicleHash);
    await this.setState({ ...this.state, dailyCertsByVehicleHash, nextEldEventsByEldEventsId });
    await this.getVehicleUDIntervals();
  }

  async handleFilter(filters = []) {
    let month = momentTz();
    let startDate;
    let endDate;
    if (filters.length === 0) {
      this.setState({ ...this.state, filterFormFields: initialFilterFormFields(), month });
    } else {
      const filterFormFields = [].concat(...this.state.filterFormFields);
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        for (let j = 0; j < filterFormFields.length; j++) {
          if (filterFormFields[j].attrName === filter.attribute) {
            filterFormFields[j].value = filter.value;
          }
        }
        if (filter.attribute === 'month') {
          month = filter.value;
          startDate = momentTz(month).startOf('month');
          endDate = momentTz(month).endOf('month');
        }
      }
      this.setState({ ...this.state, filterFormFields, startDate, endDate }, () => {
        const queryStrObj = getDeserialized(this.props.location.search).params;
        history.push({
          search: getSerialized({ ...queryStrObj, date: momentTz(month).format('DDMMYY') }).query,
        });
      });
    }
  }

  async printReport() {
    let reportHTML;
    let reportName;
    this.setState({ ...this.state, printInProgress: true });
    reportHTML = document.querySelector('.unidentifiedDriving-printable').outerHTML;
    reportName = `Unidentified Driving Summary`;
    PDF.generateTempPDFFromHTML(document.querySelector('.unidentifiedDriving-printable'), reportName, undefined).then(
      tempFile => {
        const reportURL = tempFile.get('file')._url;
        Helpers.openDocumentLink(reportURL);
        this.setState({ ...this.state, printInProgress: false });
      }
    );
  }

  async handleAssign(vehicleObjectId, dailyCertObjectId, dateTimeUTC) {
    await this.setState({ ...this.state, isLoading: true, warningMessage: false });
    const { state } = this;
    const driverAssignChanges = { ...state.driverAssignChanges };
    const driverAssignKey = `${vehicleObjectId}-${dailyCertObjectId}`;
    const udIntervals = state.validUDEventsByVehicleDailyCertHash[vehicleObjectId + '-' + dailyCertObjectId].udIntervals;

    await Promise.all(state.mergedIntervalsByVehicleHash[dailyCertObjectId].filteredByIdentifiedEventDurations.map(async udEvent => {
      let udObjectIds = [];
      Object.keys(udIntervals).map(udInterval => {
        if (momentTz(udInterval, 'HH:mm').isSameOrAfter(momentTz(udEvent.startEventDate, 'HH:mm')) && momentTz(udInterval, 'HH:mm').isSameOrBefore(momentTz(udEvent.endEventDate, 'HH:mm'))) {
          udObjectIds.push(udIntervals[udInterval]);
        }
      })
      const intervalKey = `${udEvent.startEventDate}-${udEvent.endEventDate}`;
      const driverObjectId = driverAssignChanges[driverAssignKey][intervalKey].driverObjectId;
      const coDriverObjectId = driverAssignChanges[driverAssignKey][intervalKey].coDriverObjectId;
      if (driverObjectId) { //call cloud function if driver exists
        //find the dailycert of the driver, else pass null
        const driver = await getObjectById('Driver', driverObjectId);
        const driverDailyCert = await getELDDailyCertification(driver, momentTz(dateTimeUTC), true);
        const eldDailyCertificationObjectId = getAttribute(driverDailyCert, 'objectId', true);
        //call cloud function here
        if (eldDailyCertificationObjectId) {
          await callCloudFunction('addUnidentifiedDriverELDEdit', { eldEventObjectIds: udObjectIds, driverObjectId, coDriverObjectId, eldDailyCertificationObjectId });
          delete driverAssignChanges[dailyCertObjectId];
        }
        else { //if the dailyCertObjectId doesn't exist, we cannot pass a null daily cert, display warning message
          await this.setState({ ...this.state, warningMessage: true });
        }
      }
    }))

    await this.setState({ ...this.state, isLoading: false, driverAssignChanges });
    await this.refreshState();
  }

  async handleClear(vehicleObjectId, dailyCertObjectId) {
    await this.setState({ ...this.state, isLoading: true, warningMessage: false });
    const { state } = this;
    const udIntervals = state.validUDEventsByVehicleDailyCertHash[vehicleObjectId + '-' + dailyCertObjectId].udIntervals;

    const setEldEventToIdentified = async (udObjectId) => {
      const udObject = await getRecordByObjectId(
        {
          sessionToken: getCurrentUserSessionToken()
        },
        'ELDEvent',
        udObjectId
      );
      return updateRecord(
        { sessionToken: getCurrentUserSessionToken() },
        udObject,
        { unidentifiedDriver: false },
        true
      );
    }

    const promises = [];
    for (let i = 0; i < state.mergedIntervalsByVehicleHash[dailyCertObjectId].filteredByIdentifiedEventDurations.length; i++) {
      const udEvent = state.mergedIntervalsByVehicleHash[dailyCertObjectId].filteredByIdentifiedEventDurations[i];
      Object.keys(udIntervals).map(udInterval => {
        if (momentTz(udInterval, 'HH:mm').isSameOrAfter(momentTz(udEvent.startEventDate, 'HH:mm')) && momentTz(udInterval, 'HH:mm').isSameOrBefore(momentTz(udEvent.endEventDate, 'HH:mm'))) {
          promises.push(setEldEventToIdentified(udIntervals[udInterval]));
        }
      });
    }
    await Promise.all(promises);
    await this.setState({ ...this.state, isLoading: false });
    await this.refreshState();
  }

  async handleDriverSelection(startDateTime, endDateTime, selection, vehicleInEditObjectId, dailyCertInEditObjectId, isCoDriver = false) {
    const driverAssignChanges = { ...this.state.driverAssignChanges };
    const driverAssignKey = `${vehicleInEditObjectId}-${dailyCertInEditObjectId}`;
    const intervalKey = `${startDateTime}-${endDateTime}`;
    if (!driverAssignChanges[driverAssignKey]) {
      driverAssignChanges[driverAssignKey] = {};
      driverAssignChanges[driverAssignKey][intervalKey] = {};
    }
    if (!isCoDriver) {
      driverAssignChanges[driverAssignKey][intervalKey].driverObjectId = getAttribute(selection, 'objectId');
    } else {
      driverAssignChanges[driverAssignKey][intervalKey].coDriverObjectId = getAttribute(selection, 'objectId');
    }

    await this.setState({ ...this.state, driverAssignChanges });
  }

  async handleShowHideTimeDetail(dailyCertObjectId) {
    let timeDetailsArr = this.state.showTimeDetailsForRowArr;
    if (timeDetailsArr.includes(dailyCertObjectId)) {
      const index = timeDetailsArr.indexOf(dailyCertObjectId);
      timeDetailsArr.splice(index, 1);
    }
    else {
      timeDetailsArr.push(dailyCertObjectId);
    }
    await this.setState({ ...this.state, showTimeDetailsForRowArr: timeDetailsArr });
  }

  async getVehicleUDIntervals() {
    // 1: Get UD ELDDailyCertifications by Vehicle
    // 2: Get Unidentified Durations (Group by segments of ELDEvents by time)
    // 3: Filter
    const { state, props } = this;

    let mergedIntervalsByVehicleHash = {};
    let validUDEventsByVehicleDailyCertHash = {};

    await this.setState({ ...this.state, loadingMessage: 'Generating Unidentified Driving Events' });

    // ordered array of vehicle objectId's based on unitId's
    const orderedUnitIdsByObjectIds = Object.keys(state.dailyCertsByVehicleHash).sort((a, b) => (
      getAttribute(state.dailyCertsByVehicleHash[a].vehicle, 'unitId') - getAttribute(state.dailyCertsByVehicleHash[b].vehicle, 'unitId')
    ));

    if (DEBUG === true) console.log('orderedUnitIdsByObjectIds', orderedUnitIdsByObjectIds);
    await Promise.all(orderedUnitIdsByObjectIds.map(async vehicleObjectId => {
      const eldDailyCertificationsByVehicle = state.dailyCertsByVehicleHash[vehicleObjectId];
      const vehicleUnitId = getAttribute(eldDailyCertificationsByVehicle.vehicle, 'unitId');
      const vehicleELDDailyCertificationObjectIds = Object.keys(eldDailyCertificationsByVehicle.dailyCertifications);

      await Promise.all(vehicleELDDailyCertificationObjectIds.map(async dailyCertObjectId => {
        const dailyCertObject = eldDailyCertificationsByVehicle.dailyCertifications[dailyCertObjectId].dailyCertification;
        const time = getAttribute(dailyCertObject, 'startTimeUTC');
        const timezoneOffsetFromUTC = getAttribute(dailyCertObject, 'timezoneOffsetFromUTC') ? getAttribute(dailyCertObject, 'timezoneOffsetFromUTC') : momentTz.tz.guess();
        const timeUTC = momentTz(time).tz(timezoneOffsetFromUTC);
        const endTimeUTC = momentTz(getAttribute(dailyCertObject, 'endTimeUTC')).tz(timezoneOffsetFromUTC);

        const unidentifiedDrivingELDEvents = state.dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId].eldEvents;

        validUDEventsByVehicleDailyCertHash[vehicleObjectId + '-' + dailyCertObjectId] = {};
        validUDEventsByVehicleDailyCertHash[vehicleObjectId + '-' + dailyCertObjectId].udIntervals = {};
        if (DEBUG === true) console.log('vehicleUnitId, dailyCertObjectId, unidentifiedDrivingELDEvents', vehicleUnitId, dailyCertObjectId, unidentifiedDrivingELDEvents);

        let eventDurations = unidentifiedDrivingELDEvents.map((eldEvent) => {
          const eldEventGroup = state.nextEldEventsByEldEventsId[getAttribute(eldEvent, 'objectId')]; // group of associated/successive ud events\
          const nextELDEvent = eldEventGroup.nextEldEvents;
          const startEventDateTime = momentTz(getAttribute(eldEvent, 'eventDateTime'));
          let endEventDateTime = momentTz(getAttribute(nextELDEvent, 'eventDateTime'));
          if (DEBUG === true) {
            console.log(
              'vehicleUnitId, vehicleUnitId, eldEventGroup, nextELDEvent',
              vehicleUnitId,
              eldEventGroup,
              nextELDEvent
            );
          }
          const startVehicleKm = getAttribute(eldEvent, 'totalVehicleKm');
          const endVehicleKm = getAttribute(nextELDEvent, 'totalVehicleKm');
          const isDurationSameELDEvent = getAttribute(eldEvent, 'objectId') === getAttribute(nextELDEvent, 'objectId');
          const startEventDateTimeStr = startEventDateTime.format('HH:mm');
          const endEventDateTimeStr = endEventDateTime.format('HH:mm');
          if (DEBUG === true) console.log('vehicleUnitId, startEventDateTime', vehicleUnitId, startEventDateTime);
          validUDEventsByVehicleDailyCertHash[vehicleObjectId + '-' + dailyCertObjectId].udIntervals[startEventDateTimeStr] = getAttribute(eldEvent, 'objectId');
          if (isDurationSameELDEvent) {
            endEventDateTime = momentTz(getAttribute(eldEvent, 'eventDateTime')).add(5, 'minutes');
          } else {
            validUDEventsByVehicleDailyCertHash[vehicleObjectId + '-' + dailyCertObjectId].udIntervals[endEventDateTimeStr] = getAttribute(nextELDEvent, 'objectId');
          }

          const hours = moment.duration(endEventDateTime.diff(startEventDateTime)).hours();
          const minutes = moment.duration(endEventDateTime.diff(startEventDateTime)).minutes();
          const seconds = moment.duration(endEventDateTime.diff(startEventDateTime)).seconds();

          // if (!hours && !minutes && !seconds) return undefined;
          // if (startVehicleKm === endVehicleKm) return undefined;

          return {
            startEventDateMoment: startEventDateTime,
            endEventDateMoment: endEventDateTime,
            startEventDate: startEventDateTimeStr,
            endEventDate: endEventDateTimeStr,
            startVehicleKm,
            endVehicleKm,
            hours,
            minutes,
            seconds: !hours && !minutes ? seconds : 0,
          };
        });

        eventDurations = eventDurations.filter(eventDuration => eventDuration); // filter out undefined
        eventDurations = eventDurations.reverse();
        if (DEBUG === true) console.log('vehicleUnitId, eventDurations', vehicleUnitId, eventDurations);
        const mergedEventDurations = this.filterVehicleUDIntervals(this.mergeVehicleUDIntervals(eventDurations));
        if (DEBUG === true) console.log('vehicleUnitId, mergedEventDurations', vehicleUnitId, mergedEventDurations);

        // where all the filtering occurs FILTER OUT ANY UD with prior identified events or identified events in between
        let filteredByIdentifiedEventDurations = [];
        let suggestedDrivers = [];
        let totalDurationTimeHash = { hours: 0, minutes: 0, seconds: 0 };

        if (mergedEventDurations.length > 0) {
          await Promise.all(mergedEventDurations.map(async (udDuration) => {
            if (DEBUG === true) console.log('vehicleUnitId, dailyCertObjectId, udDuration', vehicleUnitId, dailyCertObjectId, udDuration);
            const udStartEventDate = momentTz(timeUTC).format('YYYY-MM-DD') + ' ' + udDuration.startEventDate;
            const udEndEventDate = momentTz(timeUTC).format('YYYY-MM-DD') + ' ' + udDuration.endEventDate;
            if (DEBUG === true) console.log('vehicleUnitId roowoo');
            const priorIdentifiedEvent = await getPriorIdentifiedDrivingEldEvent(udStartEventDate, undefined, getAttribute(state.dailyCertsByVehicleHash[vehicleObjectId].vehicle, 'unitId'));
            if (DEBUG === true) console.log('vehicleUnitId, dailyCertObjectId, priorIdentifiedEvent', vehicleUnitId, dailyCertObjectId, priorIdentifiedEvent);

            // let priorIdentifiedEventCheck = false;
            let priorIdentifiedEventCheck = true;
            if (priorIdentifiedEvent && [13, 21, 22].indexOf(getAttribute(priorIdentifiedEvent, 'eldEventTypeCodeInt')) === -1) {
              priorIdentifiedEventCheck = true;
            }
            if (priorIdentifiedEventCheck) {
              // SKIPPING THIS CHECK BECAUSE THERE ARE MISSED UD EVENTS SHOWING UP ON TABLETS/PDFS; if we have something that's not a driving event (status change), push to UD
              if (moment.duration(momentTz(udEndEventDate).diff(momentTz(udStartEventDate))).minutes() > 5) {
                const inBetweenIdentifiedEvent = await getPriorIdentifiedDrivingEldEvent(udStartEventDate, udEndEventDate, getAttribute(state.dailyCertsByVehicleHash[vehicleObjectId].vehicle, 'unitId'));

                if (inBetweenIdentifiedEvent) {
                  // have a new end time equal to the active event in between the interval
                  udDuration.endEventDate = momentTz(getAttribute(inBetweenIdentifiedEvent, 'eventDateTime')).format('HH:mm');
                  udDuration.endVehicleKm = getAttribute(inBetweenIdentifiedEvent, 'totalVehicleKm') === 0 ? udDuration.startVehicleKm : getAttribute(inBetweenIdentifiedEvent, 'totalVehicleKm');
                  // adjust the time for the new end interval
                  udDuration.hours = moment.duration(momentTz(getAttribute(inBetweenIdentifiedEvent, 'eventDateTime')).diff(udStartEventDate)).hours();
                  udDuration.minutes = moment.duration(momentTz(getAttribute(inBetweenIdentifiedEvent, 'eventDateTime')).diff(udStartEventDate)).minutes();
                  udDuration.seconds = !udDuration.hours && !udDuration.minutes ? moment.duration(momentTz(getAttribute(inBetweenIdentifiedEvent, 'eventDateTime')).diff(udStartEventDate)).seconds() : undefined;
                }
              }

              // const odometerChanged = udDuration.startVehicleKm !== udDuration.endVehicleKm;
              const odometerChanged = true;
              // Skipping check to show same UD events for tablet
              if (odometerChanged) {
                totalDurationTimeHash.hours += udDuration.hours;
                totalDurationTimeHash.minutes += udDuration.minutes;
                if (udDuration.seconds) {
                  totalDurationTimeHash.seconds += udDuration.seconds;
                }
                filteredByIdentifiedEventDurations.push(udDuration);
              }
              // otherwise if we have an active driving event, it shouldn't be considered a UD event
            }
          }));
        }

        if (DEBUG) console.log('vehicleUnitId, dailyCertObjectId, filteredByIdentifiedEventDurations', vehicleUnitId, dailyCertObjectId, filteredByIdentifiedEventDurations);

        // find suggested drivers for the UD duration
        if (filteredByIdentifiedEventDurations.length > 0) {
          await this.setState({ ...this.state, loadingMessage: 'Processing Found Unidentified Events' });

          await Promise.all(filteredByIdentifiedEventDurations.map(async udDuration => {
            const udStartEventDate = momentTz(timeUTC).format('YYYY-MM-DD') + ' ' + udDuration.startEventDate
            suggestedDrivers = await getSuggestedDriversForUD(dailyCertObjectId, vehicleUnitId, momentTz(udStartEventDate).subtract(1, 'hours'), momentTz(udStartEventDate).add(1, 'hours'));
          }));
        }

        // order dates by start time
        if (filteredByIdentifiedEventDurations.length > 1) {
          filteredByIdentifiedEventDurations.sort((a, b) => {
            return (momentTz(a.startEventDate, 'HH:mm').toDate() - momentTz(b.startEventDate, 'HH:mm').toDate())
          });
        }

        // format the total duration time
        const totalTimeInSeconds = (totalDurationTimeHash.hours * 60 * 60) + (totalDurationTimeHash.minutes * 60) + totalDurationTimeHash.seconds;
        const totalTimeMoment = moment.duration(totalTimeInSeconds, 'seconds');

        let formattedTotalDurationTimeString = 'Total: ' + totalTimeMoment.hours() + 'H ' + totalTimeMoment.minutes() + 'M ';
        if (totalTimeMoment.seconds()) formattedTotalDurationTimeString += totalTimeMoment.seconds() + 'S';

        if (filteredByIdentifiedEventDurations.length > 0) {
          mergedIntervalsByVehicleHash[dailyCertObjectId] = {
            filteredByIdentifiedEventDurations,
            formattedTotalDurationTimeString,
            suggestedDrivers,
          };
        }
      }));
    }));
    // if (DEBUG) console.log(mergedIntervalsByVehicleHash, validUDEventsByVehicleDailyCertHash);
    await this.setState({ ...this.state, mergedIntervalsByVehicleHash, validUDEventsByVehicleDailyCertHash, isLoading: false });
  }

  mergeVehicleUDIntervals(eventDurations) {
    //merge the event durations that are overlapping and calculate the total
    let mergedEventDurations = []; //array of total merged events
    let currEventDuration = {};

    if (eventDurations.length > 0) {
      currEventDuration = {
        startEventDate: eventDurations[0].startEventDate,
        endEventDate: eventDurations[0].endEventDate,
        startEventDateMoment: eventDurations[0].startEventDateMoment,
        endEventDateMoment: eventDurations[0].endEventDateMoment,
        startVehicleKm: eventDurations[0].startVehicleKm,
        endVehicleKm: eventDurations[0].endVehicleKm,
        hours: eventDurations[0].hours,
        minutes: eventDurations[0].minutes,
        seconds: eventDurations[0].seconds,
      };
      eventDurations.map((durationObject, index) => {
        if (eventDurations.length > index + 1) {
          //if the durations overlap
          if (currEventDuration.endEventDate === eventDurations[index + 1].startEventDate) {
            currEventDuration.endEventDate = eventDurations[index + 1].endEventDate;
            currEventDuration.endEventDateMoment = eventDurations[index + 1].endEventDateMoment;
            currEventDuration.endVehicleKm = eventDurations[index + 1].endVehicleKm;
            currEventDuration.hours += eventDurations[index + 1].hours;
            currEventDuration.minutes += eventDurations[index + 1].minutes;
            currEventDuration.seconds += eventDurations[index + 1].seconds;
          }
          //no overlap then push this interval into the list
          else {
            let diffString = `${currEventDuration.hours}H ${currEventDuration.minutes}M`;
            if (currEventDuration.seconds) {
              diffString += ` ${currEventDuration.seconds}S`
            }
            mergedEventDurations.push({ startEventDate: currEventDuration.startEventDate, endEventDate: currEventDuration.endEventDate, hours: currEventDuration.hours, minutes: currEventDuration.minutes, seconds: currEventDuration.seconds, startVehicleKm: currEventDuration.startVehicleKm, endVehicleKm: currEventDuration.endVehicleKm });
            //set the curr to the next event to compare
            currEventDuration.startEventDate = eventDurations[index + 1].startEventDate;
            currEventDuration.endEventDate = eventDurations[index + 1].endEventDate;
            currEventDuration.startEventDateMoment = eventDurations[index + 1].startEventDateMoment;
            currEventDuration.endEventDateMoment = eventDurations[index + 1].endEventDateMoment;
            currEventDuration.startVehicleKm = eventDurations[index + 1].startVehicleKm;
            currEventDuration.endVehicleKm = eventDurations[index + 1].endVehicleKm;
            currEventDuration.hours = eventDurations[index + 1].hours;
            currEventDuration.minutes = eventDurations[index + 1].minutes;
            currEventDuration.seconds = eventDurations[index + 1].seconds;
          }
        }
        //if there are no remaining intervals we should push the last one
        else {
          mergedEventDurations.push({
            startEventDate: currEventDuration.startEventDate,
            endEventDate: currEventDuration.endEventDate,
            startEventDateMoment: currEventDuration.startEventDateMoment,
            endEventDateMoment: currEventDuration.endEventDateMoment,
            hours: currEventDuration.hours,
            minutes: currEventDuration.minutes,
            seconds: currEventDuration.seconds,
            startVehicleKm: currEventDuration.startVehicleKm?.toFixed(0),
            endVehicleKm: currEventDuration.endVehicleKm?.toFixed(0),
          });
        }
      })
    }
    return mergedEventDurations;
  }

  filterVehicleUDIntervals(eventDurations) {

    if (eventDurations.length > 0) {
      return eventDurations.filter((durationObject, index) => {
        return (
          true
          // moment(durationObject.endEventDateMoment).diff(durationObject.startEventDateMoment, 'minutes') > 5 // not needed for now (need to find a way to have parity with eldCsv/nsc/fmcsa exports)
        );
      })
    }
    return mergedEventDurations;
  }

  render() {
    const { state, props } = this;
    const dailyCertsByVehicleTableHeaderRows =
      (<MDBRow className="unidentified-daily-cert-header p-2">
        <MDBCol sm="2">
          Date
        </MDBCol>
        <MDBCol sm="2">
          Time
        </MDBCol>
        <MDBCol sm="8">
          Assign
        </MDBCol>
      </MDBRow>
      );

    const vehicleTitles = Object.keys(state.dailyCertsByVehicleHash).map(vehicleObjectId => {
      return getAttribute(state.dailyCertsByVehicleHash[vehicleObjectId].vehicle, 'unitId');
    });

    // ordered array of vehicle objectId's based on unitId's
    const orderedUnitIdsByObjectIds = Object.keys(state.dailyCertsByVehicleHash).sort((a, b) =>
      (getAttribute(state.dailyCertsByVehicleHash[a].vehicle, 'unitId') - getAttribute(state.dailyCertsByVehicleHash[b].vehicle, 'unitId'))
    );

    const unidentifiedDriversByVehicle = orderedUnitIdsByObjectIds.map(vehicleObjectId => {
      const eldDailyCertificationsByVehicle = state.dailyCertsByVehicleHash[vehicleObjectId];
      const vehicleELDDailyCertificationObjectIds = Object.keys(eldDailyCertificationsByVehicle.dailyCertifications);

      if (DEBUG) if (vehicleObjectId === 'SGr4Y0UUqL') console.log(vehicleObjectId, state.mergedIntervalsByVehicleHash, eldDailyCertificationsByVehicle, vehicleELDDailyCertificationObjectIds);
      let dailyCertsByVehicleRows = vehicleELDDailyCertificationObjectIds.map(dailyCertObjectId => {
        if (DEBUG) if (vehicleObjectId === 'SGr4Y0UUqL') console.log(vehicleObjectId, dailyCertObjectId);
        const dailyCertObject = eldDailyCertificationsByVehicle.dailyCertifications[dailyCertObjectId].dailyCertification;
        const time = getAttribute(dailyCertObject, 'startTimeUTC');
        const timezoneOffsetFromUTC = getAttribute(dailyCertObject, 'timezoneOffsetFromUTC') ? getAttribute(dailyCertObject, 'timezoneOffsetFromUTC') : momentTz.tz.guess();
        const timeUTC = momentTz(time).tz(timezoneOffsetFromUTC);
        const endTimeUTC = momentTz(getAttribute(dailyCertObject, 'endTimeUTC')).tz(timezoneOffsetFromUTC);
        const unidentifiedDrivingELDEvents = state.dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId].eldEvents;

        const eldEvent = unidentifiedDrivingELDEvents[0];
        const childCompanyObjectIds = [getAttribute(getAttribute(eldEvent, 'company'), 'objectId')];

        const eventDurationsHash = {};

        if (state.mergedIntervalsByVehicleHash[dailyCertObjectId]) {
          if (DEBUG) console.log(dailyCertObjectId, state.mergedIntervalsByVehicleHash[dailyCertObjectId]);
          state.mergedIntervalsByVehicleHash[dailyCertObjectId].filteredByIdentifiedEventDurations.map(durationString => {
            let diffString = `${durationString.hours}H ${durationString.minutes}M`;
            if (durationString.seconds) {
              diffString += ` ${durationString.seconds}S`
            }
            const vehicleIntervalString = `${durationString.startEventDate} - ${durationString.endEventDate} (${diffString})`;
            const vehicleKmTitleString = `Distance Driven: ${(durationString.endVehicleKm - durationString.startVehicleKm).toFixed(0)} KM`;
            // const vehicleKmString = `${durationString.startVehicleKm} - ${durationString.endVehicleKm}`
            eventDurationsHash[vehicleIntervalString] = (
              <MDBRow>
                <MDBCol>
                  <div className="mt-3 time-detail-label">{vehicleIntervalString}</div>
                  {(durationString.endVehicleKm - durationString.startVehicleKm).toFixed(0) > 0 &&
                    (
                      <div>
                        <div className="mt-1 km-detail-label">{vehicleKmTitleString}</div>
                        {/* <div className="km-detail-label">{vehicleKmString}</div> */}
                      </div>
                    )
                  }
                </MDBCol>
                <MDBCol>
                  <DriverAutocomplete
                    onDriverSelected={selection => this.handleDriverSelection(durationString.startEventDate, durationString.endEventDate, selection, vehicleObjectId, dailyCertObjectId)}
                    childCompanyObjectIds={childCompanyObjectIds}
                    autocompleteLabel={'Assign Driver'}
                    enableStatePlaceholder
                  />
                  {this.state.warningMessage &&
                    <label className="mod-warning">
                      <MDBIcon icon="exclamation-circle" className="amber-text pr-1" />
                      Could not assign Unidentified Driving - Selected driver has no events that day
                    </label>
                  }
                  <React.Fragment>
                    <div className="mt-2 km-detail-label">Suggested Drivers: </div>
                    {this.state.mergedIntervalsByVehicleHash[dailyCertObjectId].suggestedDrivers.map(suggestedDriver => {
                      const driverObjectId = getAttribute(suggestedDriver, 'objectId');
                      return (
                        <div className="mt-1 time-detail-label">
                          {getAttribute(suggestedDriver, 'vehicle_unitId', true) && (getAttribute(suggestedDriver, 'vehicle_unitId') + ': ')}
                          <a className="details-btn"
                            target="_blank"
                            href={`/driver?driver=${driverObjectId}&view=hosEvents&date=${momentTz(timeUTC).format('DDMMYY')}`}
                            onClick={(e) => { e.preventDefault(); history.push({ pathname: 'driver', search: "driver=" + driverObjectId + "&view=hosEvents" + "&date=" + momentTz(timeUTC).format('DDMMYY') }); }}>
                            {Helpers.formatName(getAttribute(suggestedDriver, 'user_fullName'))}
                          </a>
                        </div>
                      )
                    })}
                  </React.Fragment>
                </MDBCol>
                <MDBCol>
                  <DriverAutocomplete
                    onDriverSelected={selection => this.handleDriverSelection(durationString.startEventDate, durationString.endEventDate, selection, vehicleObjectId, dailyCertObjectId, true)}
                    childCompanyObjectIds={childCompanyObjectIds}
                    autocompleteLabel={'Assign CoDriver (Optional)'}
                    isRequired={false}
                    enableStatePlaceholder
                  />
                </MDBCol>

              </MDBRow>
            );
          });
        }

        const driverAssignKey = `${vehicleObjectId}-${dailyCertObjectId}`;

        const eventDurationStrings = Object.values(eventDurationsHash);

        // turns out there are no valid duration strings for this vehicle, hide the vehicle day entirely
        if (!eventDurationStrings.length) {
          return undefined;
        }

        return (
          <MDBRow key={dailyCertObjectId} className={"unidentified-daily-cert-row p-2"}>
            <MDBCol sm="2">
              <MDBRow>
                <MDBCol>
                  {momentTz(endTimeUTC).format('HH-mm-SS') === '00-00-00' &&
                    (
                      <div className="mt-3 time-detail-label" style={{ fontSize: '.9em' }}>
                        {`${momentTz(timeUTC).format('YYYY-MM-DD')} (${getTimezoneAbbreviation(timeUTC)})`}
                      </div>
                    )
                  }
                  {momentTz(endTimeUTC).format('HH-mm-SS') !== '00-00-00' &&
                    (
                      <div className="mt-3 time-detail-label" style={{ fontSize: '.9em' }}>
                        {`${momentTz(timeUTC).format('YYYY-MM-DD')} / ${momentTz(endTimeUTC).format('YYYY-MM-DD')}`}
                      </div>
                    )
                  }
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol sm="8">
              {/* <div className="mt-3 time-detail-label">{ eldEventStartTime === eldEventEndTime ? eldEventStartTime : eldEventStartTime + ' -> ' + eldEventEndTime }</div> */}
              {/* <div className="mt-3 time-detail-label time-total-label">
                {this.state.mergedIntervalsByVehicleHash[dailyCertObjectId].formattedTotalDurationTimeString}
              </div> */}
              <div>{eventDurationStrings}</div>
              {/* <a className="details-btn" onClick={() => this.handleShowHideTimeDetail(dailyCertObjectId)}>
                {this.state.showTimeDetailsForRowArr.includes(dailyCertObjectId) ? 'Hide Details' : 'Show Details'}
              </a> */}
            </MDBCol>

            <MDBCol sm="2" className="text-right">
              <MDBBtn
                className="unidentified-drivers-assign-btn mt-2"
                size="sm"
                disabled={!this.state.driverAssignChanges[driverAssignKey]}
                color="primary"
                size="sm"
                onClick={() => this.handleAssign(vehicleObjectId, dailyCertObjectId, timeUTC)}
              >
                {'Assign'}
              </MDBBtn>
              {window.location.host.indexOf('app-support') !== -1 &&
                (
                  <MDBBtn
                    className="unidentified-drivers-assign-btn mt-2"
                    size="sm"
                    disabled={state.isLoading}
                    color="warning"
                    size="sm"
                    onClick={() => this.handleClear(vehicleObjectId, dailyCertObjectId, timeUTC)}
                  >
                    {'Clear'}
                  </MDBBtn>
                )
              }
              <div>
              </div>
            </MDBCol>
          </MDBRow>
        );
      });

      dailyCertsByVehicleRows = dailyCertsByVehicleRows.filter(dailyCertsByVehicleRow => dailyCertsByVehicleRow);
      if (!dailyCertsByVehicleRows.length) return undefined;

      let vehicleTitleToDisplay = getAttribute(state.dailyCertsByVehicleHash[vehicleObjectId].vehicle, 'unitId');
      const plate = getAttribute(state.dailyCertsByVehicleHash[vehicleObjectId].vehicle, 'licensePlate') ? getAttribute(getAttribute(state.dailyCertsByVehicleHash[vehicleObjectId].vehicle, 'licensePlate'), 'plate') : getAttribute(state.dailyCertsByVehicleHash[vehicleObjectId].vehicle, 'plate');
      let titleCount = 0;
      vehicleTitles.map(vehicleTitle => {
        if (vehicleTitle === vehicleTitleToDisplay) {
          titleCount++;
        }
      });
      if (titleCount > 1) {
        vehicleTitleToDisplay = `${vehicleTitleToDisplay} - ${plate}`;
      }
      return (
        <SBCard key={vehicleObjectId} isCollapsible={false} className="daily-certs-by-vehicle-card" title={`Vehicle: ${vehicleTitleToDisplay}`} cardBodyStyle={{ maxHeight: 'none' }} headerBorder>
          <MDBRow className="p-0 px-2">
            <MDBCol className="p-0">
              {(Object.keys(state.dailyCertsByVehicleHash).length < 1)
                ? <div className="no-results-message-container">There are currently no Unidentified Events</div>
                : (
                  <MDBRow>
                    <MDBCol>
                      {dailyCertsByVehicleTableHeaderRows}
                      {dailyCertsByVehicleRows}
                    </MDBCol>
                  </MDBRow>
                )
              }
            </MDBCol>
          </MDBRow>
        </SBCard>
      );
    });


    const tempView = this.props.location ? getQueryParameter(this.props.location.search, 'd') : undefined;
    const queryStrObj = getDeserialized(this.props.location.search).params;
    const navItems = [
      {
        name: 'Last 24 Hours',
        isActive: tempView === '24hours',
        to: getSerialized({ ...queryStrObj, d: '24hours' }).query,
      },
      {
        name: 'Last Two Weeks',
        isActive: !tempView || tempView === 'lastTwoWeeks',
        to: getSerialized({ ...queryStrObj, d: 'lastTwoWeeks' }).query,
      },
      {
        name: 'By Month',
        isActive: tempView === 'month',
        to: getSerialized({ ...queryStrObj, d: 'month', date: momentTz().format('DDMMYY') }).query,
      },
    ];

    return (
      <MDBContainer className="px-5 unidentified-drivers-layout" fluid>
        {/* {state.isLoading && <LoadingOverlay />} */}
        <Title title="Unidentified Driving Days"></Title>
        <MDBRow>
          <MDBCol sm="6">
            <SubNavigationBar navItems={navItems} />
          </MDBCol>
          <MDBCol sm="4">
            {tempView === 'month' &&
              <FilterForm
                className="d-inline-block"
                handleFilter={this.handleFilter}
                clearFilter={() => this.handleFilter()}
                fields={state.filterFormFields}
                submitOnSelect
                disabled={state.isLoading}
              />
            }
          </MDBCol>
          <MDBCol sm="2">
            <MDBBtn
              size="sm"
              color="primary"
              className="align-middle"
              onClick={this.printReport}
              disabled={this.state.printInProgress}
            >
              <MDBIcon icon="print"></MDBIcon> Print
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        {!state.isLoading ?
          <React.Fragment>
            <div className="unidentifiedDriving-printable">
              {unidentifiedDriversByVehicle}
            </div>
          </React.Fragment>
          :
          <div className="no-results-message-container">
            <label className="label-center p-4 pt-5">{this.state.loadingMessage}
              <div className="spinner-border spinner-border-sm ml-1" role="status">
                <span className="sr-only"></span>
              </div>
            </label>
          </div>
        }
        {Object.keys(state.mergedIntervalsByVehicleHash).length === 0 && !state.isLoading &&
          <div className="no-results-message-container">There are currently no Unidentified Events</div>
        }
      </MDBContainer>
    );
  }
}

UnidentifiedDrivers.propTypes = {
};

// Notes on objects in the state:

// driverAssignChanges: {
//   vehicleObjectId-dailyCertObjectId: {
//     driverObjectId: driverObject <Driver>
//     coDriverObjectId: driverObject <Driver>
//   }
// }

// dailyCertsByVehicleHash: {
//   vehicleObjectId: {
//     vehicle: vehicleObject <Vehicle>,
//     dailyCertifications: {
//       dailyCertObjectId: {
//         dailyCertification: dailyCertificationObject <ELDDailyCertification>,
//         eldEvents [array of eldEvents <ELDEvent>],
//       },
//       ...
//     }
//   },
//   ...
// }

export default UnidentifiedDrivers;
