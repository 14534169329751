// API
import { callCloudFunction } from 'api/Parse';

async function getIFTARouteBreakdown(mapboxRoutePolyline, unorderedIsOkay) {
  try {
    const { orderedStatesTravelled, IFTARouteBreakdown } = await callCloudFunction('getIFTARouteBreakdown', { mapboxRoutePolyline, unorderedIsOkay })
    return { orderedStatesTravelled, mileageBreakdown: IFTARouteBreakdown };
  } catch (e) {
    return Promise.reject(e);
  }
}

export {
  getIFTARouteBreakdown,
};
