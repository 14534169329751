/* eslint-disable max-len */
import moment from 'moment';
import React, { useEffect, useState } from 'react';

// API
import { convertDistance } from 'api/Helpers';
import { getLocalReverseGeocode } from 'api/Geocode';
import { isIdling, getGPSDirection } from 'api/GPS/GPS';
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { getVehicleLocationDescription, getLocationDescriptionBreakdown } from 'sb-csapi/dist/api/VehicleLocation/VehicleLocation';

// Hooks
import { usePrevious } from 'hooks/usePrevious';

// Enums
import { EquipmentTypes } from 'enums/Equipment'; // need to move to sb-csapi
import { LengthUnit } from 'sb-csapi/dist/enums/Unit';
import { StateProvinces } from 'api/Lists/StateProvinces'; // Move to sb-csapi

// Components
import Button from 'sbCore/Button/Button';
import ScrollPanel from 'sbCore/ScrollPanel/ScrollPanel';
import SidebarFilter from 'components/Map/Sidebar/SidebarFilter/SidebarFilter';
import EquipmentGroupCardList from 'components/Map/Sidebar/EquipmentGroupCardList/EquipmentGroupCardList';
import SidebarState from 'components/Map/Sidebar/SidebarState/SidebarState';
import { convertCornersToCenterAndLength } from '../../../../api/Helpers';

/**
 * @description The sidebar content and logic for "Equipment Group" view
 *
 * @param {Object} vehicleQueryResult - The results of the vehicleAPI query
 * @param {Object} trailerQueryResult - The results of the trailerAPI query
 * @param {Number} equipmentType - type of equipment to display (vehicle, trailer)
 * @param {Number} speedLimitKm - speed limit in km/h
 * @param {Object} activeEquipment - currently active equipment
 *
 * @param {Function} isActionDisabled - callback function to see if a particular action is disabled
 * @param {Function} setActiveEquipmentInformation - callback function for handling when a equipment card is set to active
 * @param {Function} handleOnEquipmentSelect - callback function for when an equipment is selected on the sidebar
 *
 * @returns {JSX} The sidebar view for "Equipment Group"
 */
function EquipmentGroupView({ vehicleQueryResult, trailerQueryResult, equipmentType, ...props }) {
  // Filtering
  const [searchValue, setSearchValue] = useState('');

  // Sorted vehicle and trailer equipment groups
  const [sortedEquipmentGroupVehicles, setSortedEquipmentGroupVehicles] = useState([]); // Array of maps
  const [sortedEquipmentGroupTrailers, setSortedEquipmentGroupTrailers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const prevEquipmentType = usePrevious(equipmentType);
  const prevSearchValue = usePrevious(searchValue);

  /**
  * @description Perfoms sorting between two sidebar objects lastUpdated
  * @param {Object} objectA - A parsed Vehicle/Trailer/Geofence object
  * @param {Object} objectB - A parsed Vehicle/Trailer/Geofence object
  * @returns {int} sorting order
  */
  function sortSidebarObjectByLastUpdated(objectA, objectB) {
    if (objectA?.lastUpdated && objectB?.lastUpdated) {
      return moment(objectB.lastUpdated).valueOf() - moment(objectA.lastUpdated).valueOf();
    } else if (objectA.locationDescription) {
      return -1;
    } else if (objectB.locationDescription) {
      return 1;
    }

    if (objectA.unitId < objectB.unitId) return -1;
    if (objectA.unitID > objectB.unitId) return 1;
    return 0;
  }

  /* Functions to parse equipment groups */
  function parseVehicleEquipmentGroups(vehiclesMap) {
    const distanceUnit = getPDispatcherPropertyFromState('distanceUnit') || LengthUnit.MI.toLowerCase();

    const parsedVehicleEquipmentGroupMap = Object.entries(vehiclesMap).map(([equipmentGroupObjectId, equipmentGroupDetails]) => {
      const parsedVehicles = equipmentGroupDetails.vehicles.map((vehicle) => {
        const { objectId, unitId, drivers, vehicleLocation, belongsToCompany } = vehicle;
        const driverEldStatusInt = drivers && drivers.length > 0 && drivers[0].eldStatusInt;
        const isDriverDriving = driverEldStatusInt === 3 || driverEldStatusInt === 6;
        const speedKm = vehicleLocation?.speedKm; // Vehicle location speed is recorded in km
        const isVehicleIdling = isIdling(isDriverDriving, speedKm);
        const isVehicleSpeeding = speedKm && speedKm > props.speedLimitKm;
        // Convert speed based on the dispatcher's distance unit
        let speedStr = '-';
        if (speedKm) {
          speedStr = (distanceUnit === LengthUnit.KM.toLowerCase() ? (`${Math.round(speedKm)} ${distanceUnit}/h`) : (`${convertDistance(speedKm, 'km', 'mi')} ${distanceUnit}/h`));
        }

        // Covnert speed limit distance unit
        const speedLimitStr = (distanceUnit === LengthUnit.KM.toLowerCase() ? (`${Math.round(props.speedLimitKm)} ${distanceUnit}/h`) : (`${convertDistance(props.speedLimitKm, 'km', 'mi')} ${distanceUnit}/h`));
        const countryCode = ((vehicleLocation && vehicleLocation?.countryCode) || '').toUpperCase();

        let locationDescription;
        /**
         * This code here is used to handle cases where the VehicleLocation's stateProvince and locationDescription's stateProvince attributes do not match
         * In the original code, the locationDescription's stateProvince is replaced with the VehicleLocation's stateProvince attribute
         * However, it seems like the opposite is true: the VehicleLocation's stateProvince is the value that is incorrect
         * E.g., VehicleLocation's stateProvince = BC, locationDescription's stateProvince = WA. The correct city/stateProvince is Blaine, WA
         */
        if (countryCode === 'US') {
          const locationDescriptionUS = vehicleLocation?.locationDescriptionUS;
          locationDescription = locationDescriptionUS;
          if (locationDescriptionUS) {
            const locationDescriptionBreakdown = getLocationDescriptionBreakdown(locationDescriptionUS);
            const { distance, distanceUnit, city, heading, stateProvince } = locationDescriptionBreakdown;
            const trueStateProvince = vehicleLocation?.stateProvince?.toUpperCase();
            if (trueStateProvince?.toUpperCase() !== stateProvince?.code) {
              // locationDescription = getVehicleLocationDescription(city, trueStateProvince, distance, distanceUnit, heading.value);
              // console.log('city', city, 'stateProvince', trueStateProvince, '| locationDescription stateProvince', stateProvince?.code);
            }
          }
        } else if (countryCode === 'CA') {
          const locationDescriptionCA = vehicleLocation?.locationDescriptionCA;
          locationDescription = locationDescriptionCA;
          if (locationDescriptionCA) {
            const locationDescriptionBreakdown = getLocationDescriptionBreakdown(locationDescriptionCA);
            const { distance, distanceUnit, city, heading, stateProvince } = locationDescriptionBreakdown;
            const trueStateProvince = vehicleLocation?.stateProvince?.toUpperCase();
            if (trueStateProvince !== stateProvince?.code) {
              // locationDescription = getVehicleLocationDescription(city, trueStateProvince, distance, distanceUnit, heading.value);
              // console.log('city', city, 'stateProvince', trueStateProvince, '| locationDescription stateProvince', stateProvince?.code);
            }
          }
        }

        // GPS Heading
        let gpsHeadingDirection = '-';
        if (vehicle && vehicleLocation?.gpsHeading) {
          gpsHeadingDirection = getGPSDirection(vehicleLocation?.gpsHeading).direction;
        }

        const longitude = vehicleLocation?.location?.longitude;
        const latitude = vehicleLocation?.location?.latitude;

        return {
          objectId,
          equipmentGroupObjectId,
          unitId,
          drivers: drivers?.map((driver) => ({ fullName: driver.user_fullName, driverObjectId: driver.objectId })),
          locationDescription,
          lastUpdated: vehicleLocation?.dateTime?.iso,
          locationObj: vehicleLocation,
          eldStatusInt: driverEldStatusInt,
          companyName: belongsToCompany?.name,
          isIdling: isVehicleIdling,
          isSpeeding: isVehicleSpeeding,
          speedStr,
          speedLimitStr,
          speedKm,
          gpsHeading: vehicleLocation?.gpsHeading,
          gpsHeadingDirection,
          coordinates: {
            longitude,
            latitude,
          }
        }
      });

      return {
        equipmentGroupObjectId,
        equipmentGroupName: equipmentGroupDetails.name,
        vehicles: parsedVehicles,
      };
    });

    // Sort the vehicles in each group by last updated
    parsedVehicleEquipmentGroupMap.forEach((equipmentGroup) => {
      const { vehicles } = equipmentGroup;

      vehicles.sort((equipmentA, equipmentB) => sortSidebarObjectByLastUpdated(equipmentA, equipmentB));
    });

    return parsedVehicleEquipmentGroupMap;
  }

  async function parseTrailerEquipmentGroups(trailersMap) {
    const distanceUnit = getPDispatcherPropertyFromState('distanceUnit') || LengthUnit.MI.toLowerCase();

    const parsedTrailerEquipmentGroupMap = await Promise.all(Object.entries(trailersMap).map(async ([equipmentGroupObjectId, equipmentGroupDetails]) => {
      const parsedTrailers = await Promise.all(equipmentGroupDetails.trailers.map(async (trailer) => {
        // Return back only the necessary information
        const { objectId, unitId, belongsToCompany, tc_devices: tcDevices } = trailer;
        let locationDescription;
        let lastUpdated;
        const tcPositions = tcDevices?.tc_positions;
        const assetLocation = tcDevices?.assetLocation;
        // Perform reverse geocode on the coordinates
        let _latitude;
        let _longitude;
        let speedStr = '-';
        let trailerTemperatureCelsius;
        let gpsHeadingDirection = '-';
        let speedKm;
        let course;
        if (tcPositions || assetLocation) {
          if (tcPositions) {
            _latitude = tcPositions.location?.latitude;
            _longitude = tcPositions.location?.longitude;
            lastUpdated = (tcPositions.devicetime && tcPositions.devicetime.iso) || (tcPositions.dateTime);
            // tc_positions speed is recorded in mi
            const speedMile = tcPositions?.speed;
            // convert speed to dispatcher's preferred distance unit
            if (speedMile) {
              speedStr = (distanceUnit === LengthUnit.KM.toLowerCase() ? (`${convertDistance(speedMile, 'mi', 'km')} km/h`) : (`${Math.round(speedMile)} mi/h`));
              speedKm = distanceUnit === LengthUnit.KM.toLowerCase() ? convertDistance(speedMile, 'mi', 'km') : Math.round(speedMile);
            }

            // trailer temp
            trailerTemperatureCelsius = tcPositions?.tempCelsius;

            // trailer heading
            if (tcPositions?.course) {
              gpsHeadingDirection = getGPSDirection(tcPositions?.course).direction;
              course = tcPositions?.course;
            }
          } else if (assetLocation) {
            _latitude = assetLocation.location?.latitude;
            _longitude = assetLocation.location?.longitude;
            lastUpdated = (assetLocation.devicetime && assetLocation.devicetime.iso) || assetLocation.dateTime;

            // convert speed to dispatcher's preferred distance unit
            if (assetLocation.speedKm) {
              speedStr = (distanceUnit === LengthUnit.MI.toLowerCase() ? (`${Math.round(convertDistance(assetLocation.speedKm, 'km', 'mi'))} mi/h`) : (`${Math.round(assetLocation.speedKm)} km/h`));
              speedKm = distanceUnit === LengthUnit.KM.toLowerCase() ? convertDistance(assetLocation.speedKm, 'km', 'mi') : Math.round(assetLocation.speedKm);
            }

            // trailer temp
            trailerTemperatureCelsius = assetLocation?.tempCelsius;

            // trailer heading
            if (assetLocation.course) {
              gpsHeadingDirection = getGPSDirection(assetLocation?.course).direction;
              course = assetLocation?.course;
            }
          }
          const reverseGeocodeResults = await getLocalReverseGeocode([[_latitude, _longitude]]);
          if (reverseGeocodeResults && reverseGeocodeResults.length > 0) {
            const address = reverseGeocodeResults[0];
            const stateProvinces = StateProvinces.filter((value) => value.name === address?.admin1Code?.asciiName);
            locationDescription = getVehicleLocationDescription(address?.asciiName, stateProvinces && stateProvinces.length > 0 && stateProvinces[0].code);
          }
        }

        return {
          objectId,
          unitId,
          locationDescription,
          lastUpdated,
          companyName: belongsToCompany?.name,
          locationObj: tcPositions || assetLocation,
          trailerTemperatureCelsius,
          gpsHeadingDirection,
          isTrailer: true,
          speedStr,
          speedKm,
          course,
          coordinates: {
            latitude: _latitude,
            longitude: _longitude,
          }
        }
      }));

      return {
        equipmentGroupObjectId,
        equipmentGroupName: equipmentGroupDetails.name,
        trailers: parsedTrailers,
      };
    }));

    // Sort the trailers in each group by last updated
    parsedTrailerEquipmentGroupMap.forEach((equipmentGroup) => {
      const { trailers } = equipmentGroup;

      trailers.sort((equipmentA, equipmentB) => sortSidebarObjectByLastUpdated(equipmentA, equipmentB));
    });

    return parsedTrailerEquipmentGroupMap;
  }

  /* UseEffects */
  useEffect(() => {
    let didCancel = false;
    let filteredEquipment = [];

    function _parseVehicles(vehicles) {
      if (!didCancel && (prevEquipmentType !== equipmentType || prevSearchValue !== searchValue)) setIsLoading(true);
      const parsedVehicleEquipmentGroups = parseVehicleEquipmentGroups(vehicles);

      if (!didCancel) {
        setIsLoading(false);
        setSortedEquipmentGroupVehicles(parsedVehicleEquipmentGroups);
      }
    }

    async function _parseTrailers(trailers) {
      if (!didCancel && (prevEquipmentType !== equipmentType || prevSearchValue !== searchValue)) setIsLoading(true);
      const parsedTrailerEquipmentGroups = await parseTrailerEquipmentGroups(trailers);

      if (!didCancel) {
        setIsLoading(false);
        setSortedEquipmentGroupTrailers(parsedTrailerEquipmentGroups);
      }
    }

    // Set the equipment to the one that matches the current equipmentType we are viewing
    if (equipmentType === EquipmentTypes.VEHICLE && vehicleQueryResult.isSuccess) {
      filteredEquipment = vehicleQueryResult.data.equipmentGroup;
    } else if (equipmentType === EquipmentTypes.TRAILER && trailerQueryResult.isSuccess) {
      filteredEquipment = trailerQueryResult.data.equipmentGroup;
    }

    let _filteredEquipment = { ...filteredEquipment };

    // Perform filtering from search value
    if (searchValue) {

      _filteredEquipment = {};

      // if (equipmentGroupInformationMap.name.toLowerCase().includes(searchValue)) groupNameContainsSearchResults = true
      let foundEquipmentGroup = false;
      const equipmentGroupNames = Object.keys(filteredEquipment);
      const equipmentGroupInformationMapArr = Object.values(filteredEquipment);
      for (let i = 0; i < equipmentGroupNames.length; i++) {
        const equipmentGroupName = equipmentGroupNames[i];
        if (equipmentGroupName !== 'default' && equipmentGroupName.toLowerCase().includes(searchValue)) {
          _filteredEquipment[equipmentGroupName] = filteredEquipment[equipmentGroupName];
          foundEquipmentGroup = true;
          break;
        }
      }

      if (!foundEquipmentGroup) {
        for (let i = 0; i < equipmentGroupInformationMapArr.length; i++) {
          const equipmentGroupName = equipmentGroupNames[i];
          const equipmentGroupInformationMap = equipmentGroupInformationMapArr[i];
          _filteredEquipment[equipmentGroupName] = { ...equipmentGroupInformationMap };
          _filteredEquipment[equipmentGroupName].vehicles = equipmentGroupInformationMap.vehicles.filter((vehicleObj) => vehicleObj?.unitId?.includes(searchValue));
          _filteredEquipment[equipmentGroupName].trailers = equipmentGroupInformationMap.trailers.filter((trailerObj) => trailerObj?.unitId?.includes(searchValue));
          // const groupVehicles = equipmentGroupInformationMap[i].vehicles;
          // const groupTrailers = equipmentGroupInformationMap[i].trailers;
          // if (equipmentGroupName.toLowerCase().includes(searchValue)) {
          //   _filteredEquipment[equipmentGroupName] = filteredEquipment[equipmentGroupName];
          //   foundEquipmentGroup = true;
          //   console.log(_filteredEquipment);
          //   break;
          // }
        }
      }

      // old way psh
      // Object.entries(filteredEquipment).forEach(([key, equipmentGroupInformationMap]) => {
      //   console.log(key, equipmentGroupInformationMap);
      //   let hasEquipmentSearchResults = false;
      //   let groupNameContainsSearchResults = false;

      //   // Check to see if any equipment unit ids are part of the search query
      //   if (equipmentType === EquipmentTypes.VEHICLE) {
      //     const filteredEquipmentGroupEquipment = equipmentGroupInformationMap.vehicles.filter((vehicle) => vehicle?.unitId?.includes(searchValue));
      //     if (filteredEquipmentGroupEquipment.length > 0) hasEquipmentSearchResults = true;
      //   } else if (equipmentType === EquipmentTypes.TRAILER) {
      //     const filteredEquipmentGroupEquipment = equipmentGroupInformationMap.trailers.filter((trailer) => trailer?.unitId?.includes(searchValue));
      //     if (filteredEquipmentGroupEquipment.length > 0) hasEquipmentSearchResults = true;
      //   }

      //   // Check to see if search query is part of any group names
      //   if (equipmentGroupInformationMap.name.toLowerCase().includes(searchValue)) groupNameContainsSearchResults = true;

      //   // Keep only groups that either have equipment, or has a matching group name
      //   if (!hasEquipmentSearchResults && !groupNameContainsSearchResults) {
      //     _filteredEquipment[key] = undefined;
      //   }
      // });
    }

    // Go through and remove any equipment groups that are now undefined from the search results
    Object.entries(_filteredEquipment).forEach(([key, equipmentGroupInformationMap]) => {
      if (equipmentGroupInformationMap === undefined) delete _filteredEquipment[key];
    });

    filteredEquipment = _filteredEquipment;

    // Perfom parsing on the respective equipment
    if (equipmentType === EquipmentTypes.VEHICLE && vehicleQueryResult.isSuccess) {
      _parseVehicles(filteredEquipment);
    } else if (equipmentType === EquipmentTypes.TRAILER && trailerQueryResult.isSuccess) {
      _parseTrailers(filteredEquipment);
    }

    return (() => { didCancel = true; });
  }, [vehicleQueryResult, trailerQueryResult, equipmentType, searchValue]);

  return (
    <>
      <SidebarFilter
        searchPlaceholder="Unit ID or Group Name"
        onSearchValueChange={(value) => setSearchValue(value)}
        isDisabled={props.isActionDisabled()}
        activeIndex={equipmentType}
        hideSort
      />

      {/* Equipment List - Each view should handle the following states: loading, empty, success, error */}
      <ScrollPanel className="sidebar-content">
        {equipmentType === EquipmentTypes.VEHICLE && (
          <>
            {/* Display error state */}
            {vehicleQueryResult.isError &&
              <SidebarState equipmentType={equipmentType} isError />
            }

            {/* Display loading state */}
            {(isLoading && !vehicleQueryResult.isError)
              && <SidebarState equipmentType={equipmentType} isLoading />
            }

            {/* Display empty state */}
            {(!isLoading && vehicleQueryResult.isSuccess && sortedEquipmentGroupVehicles.length === 0)
              && <SidebarState equipmentType={equipmentType} isEmpty />}

            {/* Display card to add equipment group */}
            {(!isLoading && vehicleQueryResult.isSuccess && sortedEquipmentGroupVehicles.length !== 0) && (
              <div className="flex">
                <Button
                  className="mx-2 my-2 w-full shadow-none p-button-outlined p-button-secondary"
                  icon="pi pi-plus"
                  label="Add Equipment Group"
                  onClick={() => props.handleOnEquipmentGroupAddEdit()}
                />
              </div>
            )}

            {/* Display success state */}
            {(!isLoading && vehicleQueryResult.isSuccess) && sortedEquipmentGroupVehicles.map((vehicleEquipmentGroupMap, index) => (
              <EquipmentGroupCardList
                index={index}
                equipmentType={equipmentType}
                equipmentGroup={vehicleEquipmentGroupMap}
                setActiveEquipmentInformation={(vehicleInformation) => props.setActiveEquipmentInformation(vehicleInformation)}
                handleOnEquipmentSelect={(vehicleInformation) => props.handleOnEquipmentSelect(vehicleInformation)}
                activeEquipment={props.activeEquipment}
                onEquipmentGroupEdit={(equipmentGroupObjectId) => props.handleOnEquipmentGroupAddEdit(equipmentGroupObjectId)}
              />
            ))}
          </>
        )}
        {equipmentType === EquipmentTypes.TRAILER && (
          <>
            {/* Display error state */}
            {trailerQueryResult.isError && <SidebarState equipmentType={equipmentType} isError />}

            {/* Display loading state */}
            {(isLoading && !trailerQueryResult.isError) && <SidebarState equipmentType={equipmentType} isLoading />}

            {/* Display empty state */}
            {(!isLoading && trailerQueryResult.isSuccess && sortedEquipmentGroupTrailers.length === 0) && <SidebarState equipmentType={equipmentType} isEmpty />}

            {/* Display card to add equipment group */}
            {(!isLoading && trailerQueryResult.isSuccess && sortedEquipmentGroupTrailers.length !== 0) && (
              <div className="flex">
                <Button
                  className="mx-2 my-2 w-full shadow-none p-button-outlined p-button-secondary"
                  icon="pi pi-plus"
                  label="Add Equipment Group"
                  onClick={() => props.handleOnEquipmentGroupAddEdit()}
                />
              </div>
            )}

            {/* Display success state */}
            {(!isLoading && trailerQueryResult.isSuccess) && sortedEquipmentGroupTrailers.map((trailerEquipmentGroupMap, index) => (
              <EquipmentGroupCardList
                index={index}
                equipmentType={equipmentType}
                equipmentGroup={trailerEquipmentGroupMap}
                setActiveEquipmentInformation={(trailerInformation) => props.setActiveEquipmentInformation(trailerInformation)}
                handleOnEquipmentSelect={(trailerInformation) => props.handleOnEquipmentSelect(trailerInformation)}
                activeEquipment={props.activeEquipment}
                onEquipmentGroupEdit={(equipmentGroupObjectId) => props.handleOnEquipmentGroupAddEdit(equipmentGroupObjectId)}
              />
            ))}
          </>
        )}
      </ScrollPanel>
    </>
  );
}

export default EquipmentGroupView;
