import React, { useState, useEffect } from 'react';

// CSAPI Enums
import { PaymentStatus } from 'sb-csapi/dist/enums/Dispatch/PaymentStatus';

// sbCore Components
import MultiSelect from 'sbCore/MultiSelect/MultiSelect';
import PaymentStatusBadge from 'sbCore/PaymentStatusBadge/PaymentStatusBadge';

// Styles
import './style.scss';

/**
 * @description Component that allows selecting multiple payment statuses from a dropdown
 * @param {String} [className] - Optional classNames
 * @param {Array} [selectedPaymentStatuses] - Possible pre-selected payment statuses
 * @param {Function} [onChange] - Callback function which runs additional logic when selecting/unselecting statuses
 * @returns
 *
 * @example
 * <PaymentStatusMultiSelect />
 */
function PaymentStatusMultiSelect({ ...props }) {
  const paymentStatuses = Object.keys(PaymentStatus).map(key => PaymentStatus[key]);

  // ** useStates ** //
  const [selectedPaymentStatuses, setSelectedPaymentStatuses] = useState([]);

  // ** useEffects ** //
  useEffect(() => {
    setSelectedPaymentStatuses(props.selectedPaymentStatuses);
  }, [props.selectedPaymentStatuses]);

  // ** Callback Functions ** //
  function onChange(value) {
    if (props.onChange) props.onChange(value);
    setSelectedPaymentStatuses(value);
  }

  // ** MultiSelect Templates ** //
  const paymentStatusTemplate = (option) => <PaymentStatusBadge paymentStatusInt={option.status} />;

  const selectedPaymentStatusesTemplate = (option) => {
    if (option) {
      return (
        <PaymentStatusBadge paymentStatusInt={option.status} />
      );
    }

    return 'Payment Status Filter';
  };

  let className = 'payment-status-multi-select';
  if (props.className) className += ` ${props.className}`;
  return (
    <div className={className}>
      <MultiSelect
        value={selectedPaymentStatuses}
        options={paymentStatuses}
        onChange={(e) => onChange(e.value)}
        itemTemplate={paymentStatusTemplate}
        selectedItemTemplate={selectedPaymentStatusesTemplate}
        optionLabel="description"
      />
    </div>
  );
}

export default PaymentStatusMultiSelect;
