import React, { useState, useEffect } from 'react';

// CSAPI Enums
import { Payee } from 'sb-csapi/dist/enums/Dispatch/Payee';

// sbCore Components
import Badge from 'sbCore/Badge/Badge';

// Styles
import './style.scss';

/**
* @description Renders a type badge associated with the given type int
* @param {Number} typeInt - The payable type number associated with a certain payable badge.
* @returns
*
* @example
* <PayableTypeBadge typeInt={0} />
*/
function PayableTypeBadge({ ...props }) {
  let badgeClassName;
  let value;

  // if status doesn't exist, default to N/A
  // Payable Type:
  // 0: Driver
  // 1: Vehicle
  // 2: Company
  switch (props.typeInt) {
    case Payee.DRIVER.type:
      badgeClassName = Payee.DRIVER.description.toLowerCase();
      value = Payee.DRIVER.description;
      break;

    case Payee.VEHICLE.type:
      badgeClassName = Payee.VEHICLE.description.toLowerCase();
      value = Payee.VEHICLE.description;
      break;

    case Payee.COMPANY.type:
      badgeClassName = Payee.COMPANY.description.toLowerCase();
      value = Payee.COMPANY.description;
      break;

    default:
      badgeClassName = 'no-payee';
      value = 'N/A';
  }

  // ** Misc ** //
  let className = 'payables-badge';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className="payables-badge">
      <Badge className={badgeClassName} value={value} />
    </div>
  );
}

export default PayableTypeBadge;