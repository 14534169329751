import React from 'react';

// sbCore Components
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import Button from 'sbCore/Button/Button';

// Styles
import './style.scss';

/**
 * @description Table that displays the selected documents for uploading
 * @param {Function} showUploadDocument - Sets the parent component's showUploadDocumentDialog state to true
 */
function DispatchUploadDocumentTable({ ...props }) {

  // ** Data Table Templates ** //
  // Removes the document associated with the clicked button
  const removeFileTemplate = (rowData) => {
    return (
      <Button className="p-button-danger" icon="pi pi-trash" label="Remove" onClick={() => handleDocumentRemoval(rowData.documentName)} />
    );
  };

  // ** Callback Functions **//
  // Calls handleDocumentRemoval to remove the selected document
  const handleDocumentRemoval = (documentName) => {
    if (props.handleDocumentRemoval) props.handleDocumentRemoval(documentName);
  };

  return (
    <DataTable
      value={props.documents}
      className="document-data-table"
      rowGroupMode="subheader"
      groupRowsBy="documentType"
      sortMode="single"
      sortField="documentType"
      sortOrder={1}
      scrollable
      scrollHeight="600px"
      responsiveLayout="scroll"
      emptyMessage="No Documents Uploaded"
    >
      <Column field="documentName" header="File Name" />
      <Column field="" header="" body={removeFileTemplate} />
    </DataTable>
  );
}

export default DispatchUploadDocumentTable;
