import React from 'react';

// Components
import sbLogo from 'assets/images/switchboard-horizontal.png';

// Styling
import './style.scss';

/**
 * @description Footer for the invoice document generator
 *
 * @returns
 */
const InvoiceFooter = () => {
  return (
    <div className="grid invoice-footer mx-7 mb-4 flex flex-row">
      <div className="col-8 contact-us">
        Please contact us within 7 days should there be an discrepancies.We appreciate doing business with you.
      </div>

      <div className="col-4">
        <div className="flex justify-content-end powered-by">
          Powered By
          <img src={sbLogo} style={{ width: '120px' }} alt="" />
        </div>
      </div>
    </div>
  );
};

export default InvoiceFooter;
