/** @module DispatchQuoteDiscount */

// sb-csapi
import {
  createQuery,
  findRecords,
  getAttribute,
  getCurrentUserSessionToken,
  setQueryRestriction,
  updateRecord,
  addRecord,
  getCurrentUser,
  destroyRecord,
  sortQuery
} from 'sb-csapi/dist/AAPI';

// Query
import { QueryRestrictionTypes, QuerySortOrderTypes } from 'sb-csapi/dist/enums/Query';

const DISPATCH_QUOTE_DISCOUNT_TABLE = 'DispatchQuoteDiscount';

/**
 *
 * @description - Fetch DispatchQuoteDiscount records with given DispatchQuote
 *
 * @param {DispatchQuote} dispatchQuote - DispatchQuote record
 *
 * @returns {Array} An array with DispatchQuoteDiscount records related to the given DispatchQuote
 */
async function getDispatchQuoteDiscount(dispatchQuote) {
  const dispatchQuoteDiscountQuery = createQuery(DISPATCH_QUOTE_DISCOUNT_TABLE);
  setQueryRestriction(dispatchQuoteDiscountQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchQuote', dispatchQuote);
  sortQuery(dispatchQuoteDiscountQuery, QuerySortOrderTypes.DESCENDING, 'createdAt');

  try {
    return await findRecords({ sessionToken: getCurrentUserSessionToken() }, dispatchQuoteDiscountQuery, false, true);
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * 
 * @param {*} dispatchQuote 
 * @returns 
 */
async function getTotalDispatchQuoteDiscountAmount(dispatchQuote) {
  const dispatchQuoteDiscountQuery = createQuery(DISPATCH_QUOTE_DISCOUNT_TABLE);
  setQueryRestriction(dispatchQuoteDiscountQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchQuote', dispatchQuote);

  try {
    const dispatchQuoteDiscounts = await findRecords({ sessionToken: getCurrentUserSessionToken() }, dispatchQuoteDiscountQuery, false, true);

    if (!dispatchQuoteDiscounts) return new Error('Error [api/DispatchQuoteDiscount getTotalDispatchQuoteDiscountAmount]: No DispatchQuoteDiscounts');

    const totalDispatchQuoteDiscountAmount = dispatchQuoteDiscounts.reduce((totalSum, discount) => totalSum + getAttribute(discount, 'rate'), 0);
    return totalDispatchQuoteDiscountAmount;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 *
 * @description - Create DispatchQuoteDiscount records with given DispatchQuote
 *
 * @param {DispatchQuote} dispatchQuote - DispatchQuote record
 *
 * @returns {DispatchQuoteDiscount} The created DispatchQuoteDiscount record
 */
async function createDispatchQuoteDiscount(dispatchQuote) {
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

  try {
    // Default additional charge values
    let dispatchQuoteDiscount = {
      rate: 0,
      dispatchQuote,
    };

    dispatchQuoteDiscount = await addRecord(
      { sessionToken: getCurrentUserSessionToken() },
      DISPATCH_QUOTE_DISCOUNT_TABLE,
      dispatchQuoteDiscount,
      getAttribute(belongsToCompany, 'objectId'),
    );

    return dispatchQuoteDiscount;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 *
 * @description - Updat DispatchQuoteDiscount record with given DispatchQuoteObjectId
 *
 * @param {string} dispatchQuoteDiscountObjectId - dispatchQuoteDiscount record id
 * @param {object} keyValueObj - The key value object we wish to update
 * @param {bool} save - Whether to save the changes to db or not
 *
 * @returns {DispatchQuoteDiscount} The updated DispatchQuoteDiscount record
 */
async function updateDispatchQuoteDiscount(dispatchQuoteDiscountObjectId, keyValueObj, save) {
  const dispatchQuoteDiscountQuery = createQuery(DISPATCH_QUOTE_DISCOUNT_TABLE);
  setQueryRestriction(dispatchQuoteDiscountQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', dispatchQuoteDiscountObjectId);

  try {
    const dispatchQuoteDiscount = await findRecords(
      { sessionToken: getCurrentUserSessionToken() },
      dispatchQuoteDiscountQuery,
      true,
      false,
    );

    if (!dispatchQuoteDiscount) {
      throw new Error(`Error [api/DispatchQuoteDiscount updateDispatchQuoteAdditionalCharge]: No DispatchQuoteDiscount with objectId ${dispatchQuoteDiscountObjectId}`);
    }

    return await updateRecord(
      { sessionToken: getCurrentUserSessionToken() },
      dispatchQuoteDiscount,
      keyValueObj,
      save,
    );

  } catch (err) {
    throw new Error(err);
  }
}

/**
 *
 * @description - Delete DispatchQuoteDiscount records with given DispatchQuote
 *
 * @param {string} dispatchQuoteDiscountRecord - dispatchQuoteDiscount record id
 *
 * @returns {DispatchQuoteDiscount} The deleted DispatchQuoteDiscount record
 */
async function deleteDispatchQuoteDiscount(dispatchQuoteDiscountRecord) {
  if (!dispatchQuoteDiscountRecord) throw new Error('A valid dispatchQuoteDiscount record is required');

  return await destroyRecord({ sessionToken: getCurrentUserSessionToken() }, dispatchQuoteDiscountRecord);
}

export {
  getDispatchQuoteDiscount,
  getTotalDispatchQuoteDiscountAmount,
  createDispatchQuoteDiscount,
  updateDispatchQuoteDiscount,
  deleteDispatchQuoteDiscount,
};
