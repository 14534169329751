import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

// api
import { getDispatchItemTransfersByJob } from 'api/Dispatch/DispatchShipment';

// sbCore Components
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';

// scapi
import { getAttribute } from 'sb-csapi/dist/AAPI';

// styles
import './style.scss';

/**
 * @description
 * @param {DispatchJob} dispatchJob - Current DispatchJob Record
 * @param {string} dispatchJobObjectId - Current DispatchJob objectId
 * @param {function} setActiveTabIndex - Function to change Dispatch Tab
 *
 * @returns
 *
 * @example
 */
function ShipmentDetailsSubsection({ dispatchJob, dispatchJobObjectId, setActiveTabIndex }) {
  // ** Hooks ** //
  const [dispatchTransfers, setDispatchTransfers] = useState([{
    referenceNumber: null,
    shipperName: null,
    consigneeName: null,
    pickupDateTimeString: null,
    dropoffDateTimeString: null,
    timezoneOffsetFromUTC: null,
  }]);
  const [dispatchTransferCount, setDispatchTransferCount] = useState(0);

  // ** UseEffect ** //
  useEffect(() => {
    async function _getDispatchItemTransfersByJob() {
      const _dispatchTransfers = await getDispatchItemTransfersByJob(dispatchJobObjectId, undefined, undefined);
      if (_dispatchTransfers.length < 1) return;
      setDispatchTransfers(_dispatchTransfers.map((dispatchTransfer, dispatchTransferKey) => {
        const referenceNumber = getAttribute(dispatchJob, 'referenceNumber', true);
        const consigneeDispatchOrganization = getAttribute(dispatchTransfer, 'consigneeDispatchOrganization', true);
        const shipperDispatchOrganization = getAttribute(dispatchTransfer, 'shipperDispatchOrganization', true);
        const pickupDateTime = getAttribute(dispatchTransfer, 'pickupDateTime', true);
        const dropoffDateTime = getAttribute(dispatchTransfer, 'dropoffDateTime', true);
        const timezoneOffsetFromUTC = getAttribute(dispatchTransfer, 'timezoneOffsetFromUTC', true);

        const shipperName = shipperDispatchOrganization ?
          getAttribute(shipperDispatchOrganization, 'organizationName', true)
          : '-';
        const consigneeName = consigneeDispatchOrganization ?
          getAttribute(consigneeDispatchOrganization, 'organizationName', true)
          : '-';
        const pickupDateTimeString = pickupDateTime ?
          moment(pickupDateTime).tz(timezoneOffsetFromUTC).format('DD-MM-YYYY @ HH:mm ')
          : '-';
        const dropoffDateTimeString = dropoffDateTime ?
          moment(dropoffDateTime).tz(timezoneOffsetFromUTC).format('DD-MM-YYYY @ HH:mm ')
          : '-';

        return {
          referenceNumber,
          shipperName,
          consigneeName,
          pickupDateTimeString,
          dropoffDateTimeString,
          timezoneOffsetFromUTC,
        };
      }));
      setDispatchTransferCount(_dispatchTransfers.length);
    }

    _getDispatchItemTransfersByJob();
  }, [dispatchJob]);

  // ** Footer ** //
  const shipmentDetailsFooter = (
    <div className="d-flex justify-content-end my-2">
      <div className="tab-change-button" onClick={() => setActiveTabIndex(1)}>
        {dispatchTransferCount > 1 ? `View All(${dispatchTransferCount}) in Shipment Details` : 'Go to Shipment Details'}
        <span className="pi pi-arrow-right ml-1" />
      </div>
    </div>
  );

  // ** Section Template ** //
  const shipmentDetailsPanelTemplate = (
    <ColoredSection
      className="shipment-details-subsection"
      title="2. Shipment Details"
      footer={shipmentDetailsFooter}
      variant="compact"
    >
      <DataTable value={dispatchTransfers} scrollable scrollHeight="200px" className="shipment-details-datatable">
        <Column field="shipperName" header="Shipper" />
        <Column field="consigneeName" header="Consignee" />
        <Column field="pickupDateTimeString" header="Pickup" />
        <Column field="dropoffDateTimeString" header="Dropoff" />
      </DataTable>
    </ColoredSection>
  );

  return (
    <div>
      { shipmentDetailsPanelTemplate }
    </div>
  );
}

export default ShipmentDetailsSubsection;
