import React from 'react';

import { Tag as PRTag } from 'primereact/tag';

import './style.scss';

export default function Tag({ ...props }) {
  let className = 'sbc-tag';
  if (props.className) className += ` ${props.className}`;

  return (<PRTag { ...props } className={className} />);
}
