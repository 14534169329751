import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';

// API
import { getCompanyNonDriverUsers } from 'api/Getters';
import { getCurrentUser, isUserAdmin } from 'api/Getters';
import { deleteNonDriverUser } from 'api/Setters';

import { isUserTypeHigherRank } from 'enums/UserTypes';

// Components
import SBTable from 'components/Shared/SBTable/SBTable';
import AddNewCompanyUserButton from 'components/AddNewCompanyUser/container/AddNewCompanyUserButton';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import DeleteXButton from 'components/ConfirmModal/container/DeleteXButton';
import EditUserTypeModal from 'components/EditUserTypeModal/EditUserTypeModal';

import { MDBBtn } from 'mdbreact';

// CSS
import styles from './CompanyUsersTable.module.scss';

class CompanyUsersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyUsers: [],
      fetched: false,
      errorMessage: '',
      isEditUserTypeModalOpen: false,
      selectedUser: undefined,
    };
    this.deleteUser = this.deleteUser.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.toggleEditUserTypeModal = this.toggleEditUserTypeModal.bind(this);
  }

  componentWillMount() {
    this.getUsers();
  }

  getUsers() {
    this.setState({ ...this.state, fetched: false }, () => {
      getCompanyNonDriverUsers().then((companyUserParseArr) => {
        this.setState({ ...this.state, companyUsers: companyUserParseArr.filter((user) => user.get('enabled')), fetched: true });
      }).catch((error) => {
        this.setState({ ...this.state, fetched: true, errorMessage: error.message });
      });
    });
  }

  deleteUser(user) {
    this.setState({ ...this.state, fetched: false }, () => {
      deleteNonDriverUser(user, true).then(() => {
        this.getUsers();
      }).catch((error) => {
        this.setState({ ...this.state, fetched: true, errorMessage: error.message });
      });
    });
  }

  toggleEditUserTypeModal(user, open) {
    this.setState({ ...this.state, isEditUserTypeModalOpen: open, selectedUser: user });
  }

  render() {
    const { fetched, companyUsers, isEditUserTypeModalOpen, selectedUser } = this.state;

    const currentUser = getCurrentUser(true);
    // const isCurrentUserAdmin = currentUser.userType.indexOf(0) !== -1; // admins only, admin-limited not allowed
    const isCurrentUserAdmin = isUserAdmin();

    const userSpecialPermission = currentUser.userSpecialPermission;
    const disableCompanyUserAddUser = userSpecialPermission && userSpecialPermission.disableCompanyUserAddUser;
    const disableCompanyUserDeleteUser = userSpecialPermission && userSpecialPermission.disableCompanyUserDeleteUser;
    const disableCompanyUserEditUser = userSpecialPermission && userSpecialPermission.disableCompanyUserEditUser;

    const tableHeaderStyles = {
      user: { width: '30%' },
      emailAddress: { width: '30%' },
      options: { width: '40%', textAlign: 'right' },
    };

    const tableHeaderRows = [{
      key: 'sb-users-list', columns: [
        {
          element: <div>User</div>,
          props: {
            style: tableHeaderStyles.user,
          }
        },
        {
          element: <div>Email Address</div>,
          props: {
            style: tableHeaderStyles.emailAddress,
          }
        },
        {
          element: (
            <div>
              {!disableCompanyUserAddUser && <AddNewCompanyUserButton refreshState={this.getUsers} />}
            </div>
          ),
          props: {
            style: tableHeaderStyles.options,
          }
        },
      ]
    }];

    const tableBodyRows = companyUsers.map(user => {
      const isUserAdmin = user.get('userType').indexOf(0) !== -1; // the highest level admin - any user cannot edit an admin (trying to change it so admins can change other admins)
      const isUserAdminLimited = user.get('userType').indexOf(7) !== -1;

      // console.log(user, currentUser);
      const isUserHigherRank = isUserTypeHigherRank(user.get('userType'), currentUser.userType, false); // not inclusive (admins can delete other admins)

      let showCompanyUserEditButton = false;
      let showCompanyUserDeleteButton = false;
      // console.log('showCompanyUserDeleteButton1', showCompanyUserDeleteButton);
      if (!disableCompanyUserEditUser) {
        showCompanyUserEditButton = true;
      }
      // console.log('showCompanyUserDeleteButton2', showCompanyUserDeleteButton);
      if (!disableCompanyUserDeleteUser) {
        showCompanyUserDeleteButton = true;
      }
      // console.log('showCompanyUserDeleteButton3', showCompanyUserDeleteButton);

      if (isUserHigherRank) {
        showCompanyUserEditButton = false;
        showCompanyUserDeleteButton = false;
      }
      // console.log('showCompanyUserDeleteButton4', showCompanyUserDeleteButton);

      if (user.get('username') === currentUser.email) {
        showCompanyUserEditButton = false;
        showCompanyUserDeleteButton = false;
      }
      // console.log('showCompanyUserDeleteButton5', showCompanyUserDeleteButton);

      const columns = [
        { element: <div>{`${user.get('firstName')} ${user.get('lastName')}`}</div>, props: { className: 'align-middle', style: { fontSize: '.85em' } } },
        { element: <div>{user.get('username')}</div>, props: { className: 'align-middle', style: { fontSize: '.85em' } } },
        {
          element: (
            <div>
              {(user.id !== currentUser.objectId) && (
                <React.Fragment>
                  <MDBBtn
                    className="d-inline-block align-middle my-0 translate-me"
                    style={(!(isCurrentUserAdmin && (showCompanyUserEditButton))) ? {marginRight: '16.55em'} : {marginRight: '1.7em'} } // keep aligned when don't have permissions button
                    size="sm"
                    onClick={() => this.toggleEditUserTypeModal(user, true)}>
                    User Type
                  </MDBBtn>

                  {(isCurrentUserAdmin && (showCompanyUserEditButton)) && (
                    <MDBBtn
                      className="d-inline-block align-middle m-0 mr-3 translate-me"
                      size="sm"
                      color="secondary"
                      onClick={() => history.push(`/permissions/${user.id}`)}
                    >
                      Permissions
                    </MDBBtn>
                  )}

                  {(isCurrentUserAdmin && (showCompanyUserDeleteButton)) && (
                    <div className="d-inline-block align-middle">
                      <DeleteXButton handleChoice={(confirmBool) => { if (confirmBool) this.deleteUser(user); }} />
                    </div>
                  )}
                </React.Fragment>)
              }
            </div>
          ),
          props: { className: 'align-middle text-right' }
        },
      ];

      return {
        key: user.id,
        columns,
        props: {},
      };
    });

    return (
      <div>
        {this.state.errorMessage &&
          <div className="errorText">{this.state.errorMessage}</div>
        }
        <SBTable
          hover
          tableHeaderRows={tableHeaderRows}
          tableBodyRows={tableBodyRows}
          isLoading={!fetched}
        />
        {isEditUserTypeModalOpen && (
          <EditUserTypeModal
            user={this.state.selectedUser}
            isOpen={isEditUserTypeModalOpen}
            handleOnClose={() => this.toggleEditUserTypeModal(selectedUser, false)}
          />
        )}
      </div>
    );
  }
}

CompanyUsersTable.propTypes = {

};

export default CompanyUsersTable;
