export default class DispatchTransfer {
	/**
	 * @param {string} objectId
	 * @param {string} dispatchJob
	 * @param {string} dispatchItemId
	 * @param {number} status
   * @param {object} shipperDispatchOrganization
   * @param {object} consigneeDispatchOrganization
   * @param {object} pickupDateTime
   * @param {object} dropoffDateTime
   * @param {number} pickupAppointmentStatus
   * @param {number} dropoffAppointmentStatus
   * @param {object} shipperAddress
   * @param {object} consigneeAddress
   * @param {boolean} internal
   * @param {number} dispatchTransferType
	 * @param {string} timezoneOffsetFromUTC
	 */
	constructor(objectId, dispatchJob, dispatchItemId, status, shipperDispatchOrganization, consigneeDispatchOrganization, pickupDateTime, dropoffDateTime, pickupAppointmentStatus, dropoffAppointmentStatus, shipperAddress, consigneeAddress, internal, dispatchTransferType, timezoneOffsetFromUTC) {
		this.objectId = objectId;
		this.dispatchJob = dispatchJob;
		this.dispatchItemId = dispatchItemId;
		this.status = status;
		this.shipperDispatchOrganization = shipperDispatchOrganization;
		this.consigneeDispatchOrganization = consigneeDispatchOrganization;
		this.pickupDateTime = pickupDateTime;
		this.dropoffDateTime = dropoffDateTime;
		this.pickupAppointmentStatus = pickupAppointmentStatus;
		this.dropoffAppointmentStatus = dropoffAppointmentStatus;
    this.shipperAddress = shipperAddress;
    this.consigneeAddress = consigneeAddress;
    this.internal = internal;
    this.dispatchTransferType = dispatchTransferType;
		this.timezoneOffsetFromUTC = timezoneOffsetFromUTC;
	}
}
