import * as ActionConstants from 'actions/ActionConstants';

export function Main(state = {}, action) {
  switch (action.type) {
    case ActionConstants.FETCH_USER_GLOBALS_INPROGRESS:
      return {
        ...state,
        status: action.type,
        error: undefined,
      };
    case ActionConstants.FETCH_USER_GLOBALS_SUCCESS:
      return {
        ...state,
        status: action.type,
        error: undefined,
      };
    case ActionConstants.FETCH_USER_GLOBALS_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    case ActionConstants.PUBNUB_SETUP_INPROGRESS:
      return {
        ...state,
        status: action.type,
        pubNub: {
          ...state.pubNub,
          setupPromise: action.setupPromise,
        },
      };
    case ActionConstants.PUBNUB_SETUP_SUCCESS:
      return {
        ...state,
        status: action.type,
        pubNub: {
          ...state.pubNub,
          client: action.pubNubClient,
        },
      };
    case ActionConstants.INITIALIZE:
      return {
        ...state,
        status: action.type,
        env: action.env,
        error: undefined,
      };
    case ActionConstants.HEADER_PANEL_SELECT:
      return {
        ...state,
        headerPanel: action.selection,
      };
    case ActionConstants.INITIALIZE_MAPBOX:
      return {
        ...state,
        status: action.status,
        mapboxToken: action.token,
        error: undefined,
      };
      case ActionConstants.INITIALIZE_HERE:
        return {
          ...state,
          status: action.status,
          hereApiKey: action.apiKey,
          hereAppId: action.appId,
          error: undefined,
        };
    default:
      return state;
  }
}
