import React from 'react';
import PropTypes from 'prop-types';

// import { isInvalidEmailFormat } from 'api/Helpers';
import { MDBBtn } from 'mdbreact';
import InputText from 'sbCore/InputText/InputText';
import Button from 'sbCore/Button/Button';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
// CSS
import styles from './LoginForm.module.scss';


function LoginForm(props) {
  return (
    <div className={styles.loginForm} onKeyPress={(e) => { if (e.key === 'Enter') props.handleSubmit(); }} >

      {/* Hide login details if using wialon login */}
      {props.hideLoginFields &&
        <div>
          {props.loading &&
            <LoadingIcon />
          }
        </div>
      }
      {!props.hideLoginFields &&
        (
          <div>
            <div className={styles.loginFormInputContainer}>
              <div>
                <InputText
                  type="text"
                  placeholder="Email"
                  // className="p-inputtext-sm w-full opacity-100"
                  value={props.fields.email}
                  onChange={(e) => props.handleFormChange('email', e.target.value)}
                  className={styles.loginInput}
                />

                {/* <input
                placeholder="Email Address"
                type="email"
                name="email"
                className={styles.loginInput}
                value={props.fields.email}
                onChange={(e) => props.handleFormChange('email', e.target.value)}
              /> */}
              </div>
            </div>
            <div className={styles.loginFormInputContainer}>
              <div>
                <InputText
                  type="password"
                  placeholder="Password"
                  // className="p-inputtext-sm w-full opacity-100"
                  value={props.fields.password}
                  onChange={(e) => props.handleFormChange('password', e.target.value)}
                  className={styles.loginInput}
                />
                <div className={styles.forgotPassword}>
                  <a
                    onClick={() => props.emailHandler(props.fields.email)}
                  >
                    Forgot your password?
                  </a>
                </div>
                {/* <input
                placeholder="Password"
                type="password"
                name="password"
                className={styles.loginInput}
                value={props.fields.password}
                onChange={(e) => props.handleFormChange('password', e.target.value)}
              /> */}
              </div>
              {!props.error &&
                <div>&nbsp;</div>
              }
              {props.error &&
                <div className={styles.error}>{props.error}</div>
              }
              <Button
                className={styles.loginButton}
                label="Sign In"
                onClick={props.handleSubmit}
                disabled={props.disableLogin}
              />
            </div>
            <div className={styles.loginButtonContainer}>
              {/* <MDBBtn
              className={styles.loginButton}
              onClick={props.handleSubmit}
              disabled={props.disableLogin}
              type="submit"
              color="primary"
              size="sm"
            >
              Login
            </MDBBtn> */}

            </div>
            <br /><br />
            <br />
            <div className={styles.additional}>
              <div className='rightText'>
                Don’t have an account? &nbsp;
                <a href="https://www.onswitchboard.com/contact-us"><span className="font-bold">Get in touch with our team ►</span></a>
              </div>
              <div className='mt-1 rightText'>
                <a href="https://support.onswitchboard.com"><span className="font-bold">Visit our Help Desk</span></a>
              </div>
              {/* <a onClick={() => props.handleSignup()}>Sign up ►</a> */}
            </div>
          </div>
        )
      }
    </div>
  );
}

LoginForm.propTypes = {
  error: PropTypes.string,
  emailHandler: PropTypes.func,
  fields: PropTypes.object,
  disableLogin: PropTypes.bool,
  handleFormChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default LoginForm;
