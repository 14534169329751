import React from 'react';
import PropTypes from 'prop-types';

import { MDBBtn, MDBRow, MDBCol } from 'mdbreact';

import './styles.scss';

class RadioSet extends React.Component {

  constructor(props) {
    super(props);
    this.state = { selected: props.selected || props.labels[0] };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selected != this.state.selected) {
      this.setState({ ... this.state, selected: nextProps.selected });
    }
  }

  handleClick(selectedValue) {
    this.setState({ ...this.state, selected: selectedValue });
    if (this.props.onChangeCallBack) {
      this.props.onChangeCallBack(selectedValue);
    }
  }

  render() {
    const { props, state } = this;
    let className = 'radio-set';
    if (props.className) className += ` ${props.className}`;

    let buttons = props.labels.map((value, index, array) => {
      let size = props.size ? props.size : 'sm';
      let color = props.color ? props.color : 'primary';

      const isActive = state.selected === value;

      let btnClassName = 'w-100 mx-0 ' + (index === 0 ? 'left-most' : (index === array.length - 1 ? 'right-most' : ''));
      if (props.roundedPill) btnClassName += ` border-rounded`;
      if (!isActive) btnClassName += ` sb-fade`;

      return (
        <MDBCol className="px-0 mx-0" key={index}>
          <MDBBtn className={btnClassName} size={size} color={color}
            active={isActive}
            onClick={(event) => this.handleClick(value)}>{value}</MDBBtn>
        </MDBCol>
      );
    });
    return (
      <MDBRow className={className}>
        {buttons}
      </MDBRow>
    );
  }
}

RadioSet.propTypes = {
  labels: PropTypes.array.isRequired,
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  selected: PropTypes.string,
  onChangeCallBack: PropTypes.func,
  roundedPill: PropTypes.bool, // whether or not to fully round the buttons
}

export default RadioSet;