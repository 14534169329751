import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Col, Row, FormControl, HelpBlock, ControlLabel } from 'react-bootstrap';

// Components
import RegisterForm from '../view/RegisterForm';

// CSS
import styles from './FleetInterestScreen.module.scss';

// Assets
const logo = require('assets/images/marketing/centurion-logo.png');
const profilePic = require('assets/images/marketing/centurion-profilepic.png');
const driverBehaviour = require('assets/images/marketing/driver-behaviour.png');

function FleetInterestScreen(props) {
  const reducedInterestArr = props.interestProperties.interests.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 2)
    if(!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, []);

  return (
    <div>
      <div>
        <div className={styles.intro}>
          <div className={styles.back} onClick={props.handleBack}>
            <i className="material-icons">chevron_left</i>
            <div>Back</div>
          </div>
          Almost there!
          <br />
          <span>Finish setting up your account</span>
          {/* <span>Already have a Switchboard account? <a href={'/login'}>Sign in.</a></span> */}
        </div>
      </div>
      <table className={styles.getUserDetailsTable}><tr>
        <td>
          <div className={styles.registerForm}>
            I'm interested in:
            <table>
              { reducedInterestArr.map((interestRowArr) => (
                <tr>
                  { interestRowArr.map((interestObj) => (
                    <td>
                      <div>
                        { interestObj.name }
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </table>
            <input value={props.interestProperties.fleetSize} onChange={(e) => props.interestProperties('fleetSize', e.target.value)} type="number" />
            <div className={styles.footer}>
              <div>
                <button
                  className={`buttonDefault ${styles.registerButton}`}
                  onClick={props.launchSwitchboard}
                >
                  Launch Switchboard
                </button>
              </div>
            </div>
          </div>
        </td>
        <td>
          <div>
            <div className={styles.product}><img src={driverBehaviour} /></div>
          </div>
          {/* <div className={styles.endorsement}>
            <div className={styles.logo}><img src={logo} /></div>
            <div className={styles.profilePic}><img src={profilePic} /></div>
            <div>
              <div className={styles.quote}>
                “Switchboard is truly built to what my fleet, dispatch, and safety divisions need. It’s simple to use, and also has all the features required for proper compliance and fleet management.”
              </div>
              <br />
              <div>
                <b>Daman Grewal</b>
                <br />
                <i>Operations Manager</i>
              </div>
            </div>
          </div> */}
        </td>
      </tr></table>
    </div>
  );
}

FleetInterestScreen.propTypes = {
  handleBack: PropTypes.func.isRequired,
  getValidationState: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  User: PropTypes.object.isRequired,
  registerError: PropTypes.object,
  agreeTerms: PropTypes.bool.isRequired,
  handleAgreeTerms: PropTypes.func.isRequired,
};

export default FleetInterestScreen;
