import { t } from 'api/Translate';
import React from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { createQuery, createTempRecord, find, getAttribute, getCurrentUser, includePointers, setQueryRestriction, sortQuery, updateRecord } from 'api/Parse';
import { formatStringLength, openDocumentLink } from 'api/Helpers';

import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

import { QueryRestrictionTypes, QuerySortOrderTypes } from 'enums/Query';

// Components
import HOSLogDownloadHistoryRow from 'components/HOSLogDownloadHistory/HOSLogDownloadHistoryRow';
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import SBEditButton from 'components/Shared/SBEditButton/SBEditButton';
import BundleLogs from 'components/BundleLogs/container/BundleLogs';
import LogDownloadButton from 'components/LogDownload/LogDownloadButton/LogDownloadButton';
import LogDownloadModal from 'components/LogDownload/LogDownloadModal/LogDownloadModal';
import { Timeline, TimelineEvent } from 'react-event-timeline';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBProgress, MDBTooltip } from 'mdbreact';

// sbCore
import Button from 'sbCore/Button/Button';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import SplitButton from 'sbCore/SplitButton/SplitButton';

// CSS
import './style.scss';

class HOSLogDownloadHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showBundleModal: false,
      logDownloadArr: [],
      viewType: 0,
      actionType: '',
      fetchAttributes: {
        filters: [
          new Filter('requestedBy', getCurrentUser(), QueryRestrictionTypes.EQUAL_TO),
          new Filter('createdAt', moment().subtract(1, 'weeks').toDate(), QueryRestrictionTypes.GREATER_THAN),
        ],
        sortBy: new Sort('createdAt', QuerySortOrderTypes.ASCENDING),
      },
      logGroupingsToHide: {}, // hash of all the keys of the log groupings to hide
    };
    this.state.selectableLogStatusItems = [
      {
        key: 0,
        value: 0,
        label: t('Show All'),
      },
      {
        key: 1,
        value: 1,
        label: t('Pending'),
      },
      {
        key: 2,
        value: 2,
        label: t('Completed'),
      }
    ];
    this.state.selectedLogStatusItems = [
      this.state.selectableLogStatusItems[0],
    ];

    this.refreshState = this.refreshState.bind(this);
    this.deleteAllLogs = this.deleteAllLogs.bind(this);
    this.toggleShowBundleModal = this.toggleShowBundleModal.bind(this);
    this.getSelectedLogStatusItems = this.getSelectedLogStatusItems.bind(this);
    this.deleteLogDownload = this.deleteLogDownload.bind(this);
    this.toggleLogGroupingHide = this.toggleLogGroupingHide.bind(this);
  }

  componentDidMount() {
    this.refreshState();
  }

  async refreshState() {
    const { props, state } = this;
    await this.setState({ ...this.state, isLoading: true, showBundleModal: false });
    const fetchAttributes = { ...this.state.fetchAttributes };
    const sortBy = fetchAttributes.sortBy;
    const filters = [...fetchAttributes.filters]; // new reference so it doesnt affect current state

    if (state.viewType === 1) {
      filters.push(new Filter('file', undefined, QueryRestrictionTypes.DOES_NOT_EXIST));
    } else if (state.viewType === 2) {
      filters.push(new Filter('file', undefined, QueryRestrictionTypes.EXISTS));
    }

    const logDownloadQuery = createQuery('LogDownload');
    filters.map(filter => {
      setQueryRestriction(logDownloadQuery, filter.queryRestriction, filter.attribute, filter.value);
    });

    sortQuery(logDownloadQuery, sortBy.order, sortBy.attribute);
    includePointers(logDownloadQuery, ['driver', 'driver.user', 'requestedBy', 'requestedBy.user'])

    try {
      const logDownloadArr = (await find(logDownloadQuery, false, true)).filter((logDownloadObj) => !logDownloadObj?.get('disabled'));
      return await this.setState({ ...this.state, logDownloadArr, isLoading: false });
    } catch (err) {
      throw new Error(err);
    }
  }

  async toggleShowBundleModal(refreshState, actionType = '') {
    await this.setState({ ...this.state, showBundleModal: !this.state.showBundleModal, actionType });
    if (refreshState) {
      return await this.refreshState();
    }
    return this.state;
  }

  async getSelectedLogStatusItems(selectedLogStatusItems) {
    await this.setState({ ...this.state, viewType: selectedLogStatusItems, selectedLogStatusItems });
    return await this.refreshState();
  }

  async deleteLogDownload(logDownload) {
    await updateRecord(logDownload, { disabled: true }, true);
    return await this.refreshState();
  };

  async deleteAllLogs() {
    try {
      await Parse.Object.destroyAll(this.state.logDownloadArr);
      return await this.refreshState();
    } catch (e) {
      return await this.refreshState();
    }
  }

  async toggleLogGroupingHide(logGroupingMillis) {
    const newState = { ...this.state };
    newState.logGroupingsToHide = { ...newState.logGroupingsToHide };
    newState.logGroupingsToHide[logGroupingMillis] = !newState.logGroupingsToHide[logGroupingMillis];
    return await this.setState(newState);
  }

  render() {
    const { props, state } = this;
    let _logDownloadArr = [].concat(state.logDownloadArr);

    /**
     * Create each individual log downlaod "row" - this is not the UI where we group them together yet
     */
    // group each log by date/earliest time of date
    // create a hash to group logs of the same date - note that they are initially retrieved in createdAt ascending order
    // the key will be the millis value of the day (therefore the object is sorted by earliest -> latest date groupings by millis)
    const logGroupingsByDate = {};
    _logDownloadArr.map(logDownload => {
      // here we not only group logs together by date, but while we're at it we can render what the row for this individual download will look like
      // first get the start of the request date by millis
      const createdAt = getAttribute(logDownload, 'createdAt');
      const startOfCreatedAtDateTime = moment(createdAt).startOf('day');
      const startOfCreatedAtDateTimeMillis = startOfCreatedAtDateTime.valueOf();

      if (!logGroupingsByDate[startOfCreatedAtDateTimeMillis]) {
        logGroupingsByDate[startOfCreatedAtDateTimeMillis] = {
          logDownloads: [],
          logDownloadRows: [], // html/row representation of logDownloads
        };
      }

      logGroupingsByDate[startOfCreatedAtDateTimeMillis].logDownloads.push(logDownload);
      logGroupingsByDate[startOfCreatedAtDateTimeMillis].logDownloadRows.push(
        <HOSLogDownloadHistoryRow
          key={logDownload.id}
          logDownload={logDownload}
          deleteLogDownload={this.deleteLogDownload}
        />
      );
    });


    /**
     * Put it all in the timeline
     */
    let _containerClassName = 'log-download-history-container p-0';
    if (props.containerClassName) _containerClassName += ` ${props.containerClassName}`;

    let iconClassName = 'timeline-icon';

    // styles
    const titleStyle = { fontWeight: '600', fontSize: '.95em' };
    const bubbleStyle = { marginLeft: '0em', width: 'auto', height: 'auto', border: '.18em solid #EAEAEA', borderTop: '0em', borderLeft: '0em', fontFamily: 'Roboto, Tahoma', backgroundColor: '#FBFBFB' };

    const _iconStyle = { color: '#B8B8B8', fontSize: '1.35em', ...props.iconStyle };
    const contentStyle = {
      marginTop: '.5em',
      marginBottom: '.5em',
      paddingRight: '0em',
      width: '100%',
      fontSize: '.95em',
      fontFamily: 'Roboto, Tahoma',
      background: 'transparent',
      WebkitBoxShadow: 'none',
      MozBoxShadow: 'none',
      boxShadow: 'none',
    };


    // reverse map millis so it's most recent first
    const timelineEvents = Object.keys(logGroupingsByDate).reverse().map(logGroupingMillis => {
      const logGrouping = logGroupingsByDate[logGroupingMillis];
      let title = '';
      let icon = <MDBIcon className={iconClassName} icon="file-pdf" />;
      let className = '';

      const content = logGrouping.logDownloadRows.reverse();

      const collapseButton = (
        <MDBTooltip
          className="d-inline-block"
          placement="top"
          tooltipContent="Toggle Section"
          tooltipClass="log-download-tooltip"
        >
          <div
            className="d-inline-block"
            onClick={() => this.toggleLogGroupingHide(logGroupingMillis)}
            style={{ color: '#686868', cursor: 'pointer' }}
          >
            {!state.logGroupingsToHide[logGroupingMillis] ?
              <MDBIcon icon="minus-square" />
              :
              <MDBIcon icon="plus-square" />
            }
          </div>
        </MDBTooltip>
      );

      return (
        <TimelineEvent
          key={logGroupingMillis}
          title={title}
          createdAt={
            <React.Fragment>
              <div className="mr-3 d-inline-block" style={{ width: '12em', paddingTop: '.65em', fontSize: '.95em', textTransform: 'uppercase' }}>
                {moment(getAttribute(logGrouping.logDownloads[logGrouping.logDownloads.length - 1], 'createdAt')).format('YYYY-MMM-DD / hh:mm a')}
              </div>
              <React.Fragment>{collapseButton}</React.Fragment>
            </React.Fragment>
          }
          icon={icon}
          // buttons={}
          titleStyle={titleStyle}
          bubbleStyle={bubbleStyle}
          iconStyle={_iconStyle}
          contentStyle={contentStyle}
        // collapsible
        >
          {!state.logGroupingsToHide[logGroupingMillis] &&
            <React.Fragment>{content}</React.Fragment>
          }
        </TimelineEvent>
      );
    });

    /**
     * RETURN
     */
    return (
      <MDBContainer className={_containerClassName} style={props.containerStyle} fluid>
        {state.isLoading &&
          <LoadingOverlay />
        }

        {/* { state.showBundleModal &&
          <BundleLogs
            showModal={state.showBundleModal}
            handleClose={() => { this.toggleShowBundleModal(true) }}
          />
        } */}

        {state.showBundleModal && (
          <LogDownloadModal
            showModal={state.showBundleModal}
            actionType={state.actionType}
            handleClose={(refresh) => this.toggleShowBundleModal(refresh)}
            belongsToCompany={props.belongsToCompany?.company}
          />
        )}

        <div className="d-flex justify-content-between w-100">
          <div className="d-flex">
            <LogDownloadButton
              showModal={(action) => this.toggleShowBundleModal(false, action)}
            />
            <Button
              text
              disabled={state.isLoading}
              label="Refresh"
              className="ml-2"
              icon="pi pi-refresh"
              severity="secondary"
              onClick={() => this.refreshState()}
              sbVariant="short"
            />
            <Dropdown
              className="ml-2"
              placeholder="Show All"
              value={state.selectedLogStatusItems}
              options={state.selectableLogStatusItems}
              optionLabel="label"
              onChange={(e) => this.getSelectedLogStatusItems(e.value)}
              sbVariant="small"
            />
            {/* <SBSelect
              containerClassName="d-inline-block"
              defaultToggleText="Log Status"
              items={state.selectableLogStatusItems}
              selectedItems={state.selectedLogStatusItems}
              getSelectedItems={this.getSelectedLogStatusItems}
            /> */}
          </div>
          <Button
            label="Delete All"
            severity="danger"
            icon="pi pi-trash"
            onClick={() => this.deleteAllLogs()}
            sbVariant="short"
          />
        </div>

        <MDBRow>
          <MDBCol>
            {props.title &&
              <div className="log-download-history-title">{title}</div>
            }

            {(timelineEvents.length > 0) &&
              <Timeline className="react-event-timeline m-0 p-0 w-100" lineColor="#EAEAEA">
                {timelineEvents}
              </Timeline>
            }

            {(timelineEvents.length) === 0 &&
              <div className="log-download-empty-message">
                There are no logs to show! Click <b>Generate Logs</b> to download Driver logs
              </div>
            }
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

HOSLogDownloadHistory.defaultProps = {
  containerStyle: {},
  iconStyle: {},
};

HOSLogDownloadHistory.propTypes = {
  title: PropTypes.any,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  belongsToCompany: PropTypes.object,
};

export default HOSLogDownloadHistory;
