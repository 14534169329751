import React from 'react';
import PropTypes from 'prop-types';

// API
import { addDispatchTransfer } from 'api/Dispatch/DispatchTransfer';
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { getDispatchItemTransfersByJob } from 'api/Dispatch/DispatchShipment';

// CSAPI API
import { destroyRecord, getAttribute, getObjectById, getCompanyReadWriteACL, getCurrentUserSessionToken, cloneRecord } from 'sb-csapi/dist/AAPI';

// Components
import DispatchShipmentDetail from 'components/Dispatch/DispatchShipmentDetail/DispatchShipmentDetail';
import DispatchShipmentConfirmModal from 'components/Dispatch/DispatchShipmentConfirmModal/DispatchShipmentConfirmModal';
import DispatchShipmentTabs from 'components/Dispatch/DispatchShipmentTabs/DispatchShipmentTabs';
import DispatchAccessorialSummary from 'components/Dispatch/DispatchAccessorialSummary/DispatchAccessorialSummary';
import DispatchShipmentTimeline from 'components/Dispatch/DispatchShipmentTimeline/DispatchShipmentTimeline';

// sbCore Components
import Button from 'sbCore/Button/Button';
import SelectButton from 'sbCore/SelectButton/SelectButton';
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import Card from 'sbCore/Card/Card';
import ScrollTop from 'sbCore/ScrollTop/ScrollTop';
import Dropdown from 'sbCore/Dropdown/Dropdown';

// SBObjects
import DispatchTransfer from 'sbObjects/DispatchTransfer';

// style
import './styles.scss';

class DispatchShipmentContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dispatchJob: undefined,
      dispatchTransfers: [],
      dispatchTransfersCount: 1,
      activeIndex: 0,
      selectedDispatchTransfer: undefined,
      selectedShipper: undefined,
      selectedConsignee: undefined,
      showConfirmModal: false,
      confirmModalMessage: '',
      renderedHashView: false,
      value: null,
    };
    this.refreshState = this.refreshState.bind(this);
    this.addPick = this.addPick.bind(this);
    this.addDrop = this.addDrop.bind(this);
    this.deleteDispatchShipment = this.deleteDispatchShipment.bind(this);
    this.onClickDispatchShipment = this.onClickDispatchShipment.bind(this);
    this.onClickConfirmDeleteModal = this.onClickConfirmDeleteModal.bind(this);
    this.scrollOnClick = this.scrollOnClick.bind(this);
  }

  async componentDidMount() {
    await this.refreshState();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.dispatchJobObjectId !== this.props.dispatchJobObjectId) {
      await this.refreshState();
    }
  }

  async onClickConfirmDeleteModal(dispatchShipment, index) {
    this.setState({ ...this.state, showConfirmModal: true, activeIndex: index, selectedDispatchTransfer: dispatchShipment });
  }

  async onClickDispatchShipment(dispatchShipment, index) {
    this.setState({ ...this.state, selectedDispatchTransfer: dispatchShipment, activeIndex: index });
    await this.refreshState();
  }

  async deleteDispatchShipment(dispatchTransfer) {
    if (dispatchTransfer) {
      const sessionToken = getCurrentUserSessionToken();
      await destroyRecord({ sessionToken }, dispatchTransfer);
      this.setState({ ...this.state, activeIndex: 0, selectedDispatchTransfer: undefined });
    }
    await this.refreshState();
  }

  async refreshState() {
    const sessionToken = getCurrentUserSessionToken();
    const dispatchJob = await getObjectById({ sessionToken }, 'DispatchJob', this.props.dispatchJobObjectId);
    const dispatchTransfers = await getDispatchItemTransfersByJob(this.props.dispatchJobObjectId, undefined, undefined);
    let selectedDispatchTransfer = (dispatchTransfers.length > 0 && !this.state.selectedDispatchTransfer)
      ? dispatchTransfers[0]
      : this.state.selectedDispatchTransfer;

    const dispatchTransfersCount = dispatchTransfers.length < 1 ? 1 : dispatchTransfers.length;

    // check if hash was passed into url
    const hash = window.location.hash;
    let renderedHashView = this.state.renderedHashView;
    let activeIndex = this.state.activeIndex;

    if (hash && !renderedHashView) {
      const _hash = hash.substring(1).split('-');

      if (_hash[0] && _hash[1]) {
        const dispatchTransferObjectId = _hash[0];
        activeIndex = +_hash[1];
  
        selectedDispatchTransfer = dispatchTransfers.find(dispatchTransfer => getAttribute(dispatchTransfer, 'objectId') === dispatchTransferObjectId);

        if (!selectedDispatchTransfer) selectedDispatchTransfer = this.state.selectedDispatchTransfer;
        if (isNaN(activeIndex) || activeIndex < 0 || activeIndex >= dispatchTransfers.length) activeIndex = this.state.activeIndex;
  
        renderedHashView = true;
      }
    }

    // store the shipper and consignee of selected shipment for adding picks and drops
    let selectedShipper;
    let selectedConsignee;
    if (selectedDispatchTransfer) {
      selectedShipper = getAttribute(selectedDispatchTransfer, 'shipperDispatchOrganization', true);
      selectedConsignee = getAttribute(selectedDispatchTransfer, 'consigneeDispatchOrganization', true);
    }

    await this.setState({
      ...this.state,
      dispatchJob,
      dispatchTransfers,
      dispatchTransfersCount,
      selectedShipper,
      selectedConsignee,
      selectedDispatchTransfer,
      activeIndex,
      renderedHashView,
    }, () => this.props.triggerRefreshState());

  }

  // use selected consignee to add a new shipment
  async addPick() {
    // use selected consignee
    const selectedConsigneeObj = this.state.selectedConsignee;

    // yes, this will only work if there's a consignee
    if (selectedConsigneeObj) {
      const address = getAttribute(selectedConsigneeObj, 'address');

      let addressCopy;
      if (address) {
        addressCopy = cloneRecord(address);
      }

      const dispatchTransferObject = new DispatchTransfer(
        undefined,
        this.state.dispatchJob,
        undefined,
        undefined,
        undefined,
        selectedConsigneeObj,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        addressCopy,
        undefined,
        undefined,
        getPDispatcherPropertyFromState('timezoneOffsetFromUTC') || 'America/Vancouver'
      );
      const dispatchTransfer = await addDispatchTransfer(dispatchTransferObject);
      const newTransfersCount = this.state.dispatchTransfersCount;

      this.setState({
        ...this.state,
        activeIndex: newTransfersCount,
        selectedDispatchTransfer: dispatchTransfer,
      });

      await this.refreshState();
    }
  }

  // use selected shipper to add new shipment
  async addDrop() {
    // use selected shipper
    const selectedShipperObj = this.state.selectedShipper;

    // will only work if there exists a shipper
    if (selectedShipperObj) {
      const address = getAttribute(selectedShipperObj, 'address');

      let addressCopy;
      if (address) {
        addressCopy = cloneRecord(address);
      }

      const dispatchTransferObject = new DispatchTransfer(
        undefined,
        this.state.dispatchJob,
        undefined,
        undefined,
        selectedShipperObj,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        addressCopy,
        undefined,
        undefined,
        undefined,
        getPDispatcherPropertyFromState('timezoneOffsetFromUTC') || 'America/Vancouver'
      );
      const dispatchTransfer = await addDispatchTransfer(dispatchTransferObject);
      const newTransfersCount = this.state.dispatchTransfersCount;

      this.setState({
        ...this.state,
        activeIndex: newTransfersCount,
        selectedDispatchTransfer: dispatchTransfer,
      });

      await this.refreshState();
    }
  }

  scrollOnClick(value) {
    const classNames = [
      'dispatch-shipment-detail',
      'dispatch-item-summary',
      'dispatch-reference-numbers-container',
      'dispatch-shipment-notes',
      'dispatch-accessorial-summary',
    ];
    this.setState({ ...this.state, value });
    const section = document.querySelector(`.${classNames[value - 1]}`);
    section && section.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  render() {
    const { props, state } = this;

    const options = (
      <div>
        <div className="shipment-button">
          <Button
            label="Pick Up"
            onClick={() => this.addPick()}
            icon="pi pi-plus"
            disabled={!this.state.selectedConsignee}
          />
        </div>
        <div className="shipment-button">
          <Button
            label="Drop Off"
            onClick={() => this.addDrop()}
            icon="pi pi-plus"
            disabled={!this.state.selectedShipper}
          />
        </div>
      </div>
    );

    const items = [
      { name: 'Shipment Options', value: 1 },
      { name: 'Freight Options', value: 2 },
      { name: 'Reference Numbers', value: 3 },
      { name: 'Shipment Notes', value: 4 },
      { name: 'Accessorials and Adjustments', value: 5 },
    ];

    return (
      <React.Fragment>
        <SelectButton
          value={this.state.value}
          optionLabel="name"
          onChange={(e) => this.scrollOnClick(e.value)}
          options={items}
          className="shipment-select-button"
        />

        <ColoredSection>
          <DispatchShipmentTimeline
              dispatchJobObjectId={this.props.dispatchJobObjectId}
          />
        </ColoredSection>
        <ColoredSection title="Shipment Details" options={options}>
          <DispatchShipmentTabs
            dispatchTransfers={this.state.dispatchTransfers}
            activeIndex={this.state.activeIndex}
            onClickDispatchShipment={(dispatchShipment, index) => this.onClickDispatchShipment(dispatchShipment, index)}
            onClickConfirmDeleteModal={(dispatchShipment, index) => this.onClickConfirmDeleteModal(dispatchShipment, index)}
          />

          <DispatchShipmentDetail
            dispatchJobObjectId={this.props.dispatchJobObjectId}
            dispatchJob={props.dispatchJob}
            dispatchTransfers={this.state.dispatchTransfers}
            dispatchTransfer={this.state.selectedDispatchTransfer}
            refreshState={() => this.refreshState()}
            deleteDispatchShipment={() => this.deleteDispatchShipment()}
            triggerRefreshState={() => this.props.triggerRefreshState()}
          />
        </ColoredSection>

        <DispatchAccessorialSummary
          dispatchJob={props.dispatchJob}
          dispatchTransfer={state.selectedDispatchTransfer}
        />

        <DispatchShipmentConfirmModal
          show={state.showConfirmModal}
          title="Delete Shipment"
          body={state.confirmModalMessage}
          handleClose={() => this.setState({ ...state, showConfirmModal: false })}
          handleSubmit={() => this.deleteDispatchShipment(state.selectedDispatchTransfer)}
        />
        {/* <ScrollTop target="parent" threshold={10} /> */}
      </React.Fragment>
    );
  }
}

DispatchShipmentContainer.propTypes = {
  dispatchJobObjectId: PropTypes.string.isRequired,
  // handleSelect: PropTypes.func, // only return the resulting selections/changes vs. automatically saving them
  // drivers: PropTypes.array, // force to show these drivers over defaults
  // carrier: PropTypes.object, // force to show this carrier over default
  // vehicle: PropTypes.object, // force to show vehicle over default
};

export default DispatchShipmentContainer;
