import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';

require('react-datepicker/dist/react-datepicker.css');

class FormControlDatePicker extends React.Component {
  render() {
    return (
      <ReactDatePicker
        {...this.props}
        placeholderText={this.props.placeholder}
        selected={this.props.selected ? this.props.selected.toDate() : undefined}
      />
    );
  }
}

FormControlDatePicker.propTypes = {
  placeholder: PropTypes.string,
  selected: PropTypes.object,
};

export default FormControlDatePicker;
