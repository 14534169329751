import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip';

// Actions
import {
  fetchActiveELDDriversForState,
  deleteDriversForState
} from 'actions/Driver'

// Components
import DriversDrivingCard from './DriversDrivingCard';
import Title from 'components/LayoutTitle/view/Title'
import FilterForm from 'components/FilterForm/container/FilterForm.new'
import FilterDropdown from 'components/FilterDropdown/view/FilterDropdown'
import DriverHoursTable from 'components/DriverHoursTable/container/DriverHoursTable'
import { MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact'
import { Toggle } from 'material-ui'

// CSS
import styles from './DriverHoursLayout.module.scss'

// Context
import StoreContext from 'contexts/StoreContext';
import ViolationsTodayCard from './ViolationsTodayCard';
import WeeklyDriverViolationsCard from './WeeklyDriverViolationsCard';

class DriverHoursLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayFilterForm: false,
      filter: [],
      sort: {},
      // sort: { user_fullName: 'ascending' },
      tableView: 'all'
    }

    this.state.filterFormFields = [
      {
        attrName: 'user_fullName',
        fullName: 'Driver Name',
        placeholder: '.....',
        type: 'text'
      },
      {
        attrName: 'vehicle_unitId',
        fullName: 'Vehicle',
        placeholder: '.....',
        type: 'text'
      }
    ]

    this.state.dropdownFilterFields = [
      { attrName: 'active', placeholder: 'Drivers - Active' },
      { attrName: 'all', placeholder: 'Drivers - All' },
      { attrName: 'outOfHours', placeholder: 'Drivers -  Out of Hours' },
      { attrName: 'hoursAvailable', placeholder: 'Drivers - Hours Available' }
    ]
    this.fetchDrivers = this.fetchDrivers.bind(this)
    this.filter = this.filter.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.handleDropdownView = this.handleDropdownView.bind(this)
    this.sortHandler = this.sortHandler.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.Driver !== this.props.Driver || nextState !== this.state) {
      return true
    }
    return false
  }

  componentWillUnmount() {
    deleteDriversForState()
  }

  fetchDrivers(page, limit) {
    return fetchActiveELDDriversForState(
      page,
      limit,
      this.state.filter,
      this.state.sort,
      this.state.tableView
      // 'all',
    )
  }

  filter(filterObject) {
    this.pageCount = 0
    const newState = { ...this.state, filter: filterObject }
    deleteDriversForState().then(() => {
      this.setState(newState, () => {
        this.fetchDrivers(undefined, 20)
      })
    })
  }

  clearFilter() {
    this.pageCount = 0
    deleteDriversForState().then(() => {
      this.setState({ ...this.state, filter: [] }, () => {
        this.fetchDrivers(undefined, 20)
      })
    })
  }

  handleDropdownView(tableView) {
    this.pageCount = 0
    deleteDriversForState().then(() => {
      this.setState({ ...this.state, tableView }, () => {
        this.fetchDrivers(undefined, 20)
      })
    })
  }

  sortHandler(attribute) {
    // sort list of drivers based on attribute
    deleteDriversForState().then(() => {
      this.pageCount = 0
      const newState = { ...this.state }
      const currentSort = this.state.sort[attribute] // the current sort, if defined
      if (currentSort) {
        if (currentSort === 'ascending') {
          newState.sort[attribute] = 'descending'
        } else {
          newState.sort[attribute] = 'ascending'
        }
      } else {
        newState.sort = {}
        if (attribute) {
          newState.sort[attribute] = 'ascending'
        }
      }
      this.setState({ ...this.state, sort: newState.sort }, () => {
        fetchActiveELDDriversForState(
          this.pageCount,
          this.driversTableRowAmount,
          this.state.filter,
          this.state.sort,
          this.state.tableView
        )
      })
    })
  }

  render() {
    const showTrueRemainingTimeHelpTooltip = (
      <ReactTooltip effect="solid" type="dark" id="showTrueRemainingTimeHelp">
        When enabled, <b>Show True Remaining Time</b> will limit remaining Driving time
        <br />
        by On-Duty time, if On-Duty time is lower than Driving.
      </ReactTooltip>
    );

    return (
      <div className={styles.container}>
        <Title className="translate-me" title='Hours of Service Dashboard'>
          {/* <MDBRow className='d-flex flex-row-reverse'>
            <MDBCol xs='12' lg='4' className='my-auto'>
              <MDBRow>
                <MDBCol>
                  <MDBBtn
                    className='float-right mx-1 translate-me'
                    size='sm'
                    color='primary'
                    onClick={() => this.filter(this.state.filter)}
                  >
                    <MDBIcon icon='sync' /> Refresh
                  </MDBBtn>
                  <FilterDropdown
                    id='driversFilterDropdown'
                    className='float-right mx-1 translate-me'
                    title='Drivers - All'
                    handleFilter={this.handleDropdownView}
                    menuItems={this.state.dropdownFilterFields}
                    disabled={this.state.disableViewDropdown}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className='my-auto'>
                  <div
                    data-tip
                    data-for="showTrueRemainingTimeHelp"
                  >
                    {showTrueRemainingTimeHelpTooltip}
                    <Toggle
                      className="float-right mx-1 sb-mw-250 translate-me"
                      label="Show True Remaining Time"
                      toggled={this.props.Company.company.get('capHoursSetting')}
                      onToggle={(e, value) => {
                        this.props.Company.company
                          .set('capHoursSetting', value)
                          .save()
                        this.setState()
                      }}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCol>
            <MDBCol xs='12' lg='8'>
              <FilterForm
                className="translate-me"
                handleFilter={this.filter}
                clearFilter={this.clearFilter}
                fields={this.state.filterFormFields}
              />
            </MDBCol>
          </MDBRow> */}
        </Title>


        <DriversDrivingCard />

        <br />

        <ViolationsTodayCard />

        <br />

        <WeeklyDriverViolationsCard />
        {/* <DriverHoursTable
          capHours={this.props.Company.company.get('capHoursSetting')}
          Driver={this.props.Driver}
          disableDriver={this.disableDriver}
          fetchDrivers={this.fetchDrivers}
          sortHandler={this.sortHandler}
          sort={this.state.sort}
          tableView={this.state.tableView}
        /> */}
      </div>
    )
  }
}

DriverHoursLayout.propTypes = {
  Company: PropTypes.object.isRequired,
  Driver: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  const { Company, Driver } = state
  return {
    Company,
    Driver
  }
}

export default connect(mapStateToProps, null, null, { context: StoreContext })(DriverHoursLayout);

