import React, { useEffect, useState } from 'react';

// components
import Skeleton from 'sbCore/Skeleton/Skeleton';

// sb-csapi
import { createQuery, findRecords, getCurrentUserSessionToken, setQueryRestriction, getAttribute, sortQuery } from 'sb-csapi/dist/AAPI';

// enums
import { QuerySortOrder, QueryRestriction } from 'sb-csapi/dist/enums/Query';


/**
 * @description A display of trailer unitIds most recently used by the driver
 * @param {Driver} driver - Driver record
 * @returns {JSX} - A div containing the unitIds of recently used trailers for that driver
 */
function AssociatedTrailers({ ...props }) {
  const [eldEventRecord, setELDEventRecord] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let didCancel = false;

    // find the eld event object containing trailers that are last used by the vehicle the driver used
    async function getMostRecentELDEvent() {
      setIsLoading(true);
      const eldEventQuery = createQuery('ELDEvent');
      // same driver, vehicle and company
      setQueryRestriction(eldEventQuery, QueryRestriction.EQUAL_TO, 'driver', props.driver);
      setQueryRestriction(eldEventQuery, QueryRestriction.EQUAL_TO, 'vehicleUnitId', getAttribute(props.driver, 'vehicle_unitId'));
      setQueryRestriction(eldEventQuery, QueryRestriction.EQUAL_TO, 'company', getAttribute(props.driver, 'belongsToCompany'));
      setQueryRestriction(eldEventQuery, QueryRestriction.EXISTS, 'trailer1UnitId');
      sortQuery(eldEventQuery, QuerySortOrder.DESCENDING, 'eventDateTime');
      const _mostRecentEldEvent = await findRecords({ sessionToken: getCurrentUserSessionToken() }, eldEventQuery, true);

      if (!didCancel) {
        setIsLoading(false);
        setELDEventRecord(_mostRecentEldEvent);
      }
    }

    getMostRecentELDEvent();

    return () => { didCancel = true; };
  }, [props.driver]);

  let trailer1UnitId;
  let trailer2UnitId;
  if (eldEventRecord) {
    trailer1UnitId = getAttribute(eldEventRecord, 'trailer1UnitId');
    trailer2UnitId = getAttribute(eldEventRecord, 'trailer2UnitId', true);
  }

  return (
    <div>
      {isLoading && (
        <Skeleton width="5rem" className="mb-2" />
      )}
      {!isLoading && (
        <span>{trailer1UnitId || 'N/A'}{trailer2UnitId ? `, ${trailer2UnitId}` : ''}</span>
      )}
    </div>
  );
}

export default AssociatedTrailers;
