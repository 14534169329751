import Parse from 'parse';
import store from 'store';

/** @module ACL */

/**
 * @memberof module:ACL
 * 
 * @returns 
 */
function getCompanyReadWriteACL() {
  const companyACL = new Parse.ACL();
  companyACL.setRoleWriteAccess(store.getState().Company.company.id, true);
  companyACL.setRoleReadAccess(store.getState().Company.company.id, true);
  return companyACL;
}

/**
 * @memberof module:ACL
 * 
 * @param {*} setPublicReadAccess 
 * @param {*} setPublicWriteAccess 
 * 
 * @returns 
 */
function getPublicACL(setPublicReadAccess = false, setPublicWriteAccess = false) {
  const publicACL = new Parse.ACL();
  publicACL.setPublicReadAccess(setPublicReadAccess);
  publicACL.setPublicWriteAccess(setPublicWriteAccess);
  return publicACL;
}

export {
  getCompanyReadWriteACL,
  getPublicACL,
}