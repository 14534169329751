import React from 'react';

import { Toast as PRToast } from 'primereact/toast';

import './style.scss';

/**
 * @description PrimeReact Toast component
 * @param {*} ref - Reference used to access the toast - likely need to use innerRef so that the reference can be used
 * @param {*} innerRef - Regular prop that contains a reference to pass to the Toast
 * @param {String} className - Custom className
 * @returns
 */
export default function Toast({ ...props }) {
  let className = 'sbc-toast';
  if (props.className) className += ` ${props.className}`;

  let ref = props.ref;
  if (props.innerRef) {
    ref = props.innerRef;
  }

  return (<PRToast ref={ref} {...props} className={className} />);
}
