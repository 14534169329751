import React from 'react';
import PropTypes from 'prop-types';
import StatusIcon from 'components/StatusIcon/view/StatusIcon';
import { MDBRow, MDBCol } from 'mdbreact';

// CSS
import styles from './Title.module.scss';

class Title extends React.Component {

  shouldComponentUpdate(nextProps) {
    if (nextProps !== this.props) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div className={`${styles.layoutTitle} ${this.props.superClassName}`} style={this.props.containerStyle}>
        <MDBRow className="m-0">
          <MDBCol xs="12" lg="6" className='my-auto'>
            <h1 className={`${styles.title} ${this.props.className}`}>
              {this.props.title}
              {this.props.activeStatus !== undefined &&
                <StatusIcon active={this.props.activeStatus} size="lg" />
              }
            </h1>
          </MDBCol>
          <MDBCol xs="12" md="6" style={this.props.optionsStyle}>
            {this.props.children}
          </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

Title.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  superClassName: PropTypes.string, // use this only when really needed to overwrite conventional title div formatting
  leftColumnStyle: PropTypes.string,
  containerStyle: PropTypes.object,
  optionsStyle: PropTypes.string, // class styling for options
  title: PropTypes.any,
  activeStatus: PropTypes.bool, // used if want to represent an online status
};

export default Title;
