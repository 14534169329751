import React, { useState } from 'react';

// API
import { cloneDispatchJob } from 'api/Dispatch/DispatchJob';

// CSAPI
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import DispatchDuplicateJobButton from 'components/Dispatch/DispatchDuplicateJob/DispatchDuplicateJobButton';

// sbCore Components
import Dialog from 'sbCore/Dialog/Dialog';
import Button from 'sbCore/Button/Button';
import ProgressSpinner from 'sbCore/ProgressSpinner/ProgressSpinner';
import Message from 'sbCore/Message/Message';
// import Checkbox from 'sbCore/Checkbox/Checkbox';

// Styles
import './style.scss';

/**
 * @description Component that allows the duplication of a dispatch job
 * @param {DispatchJob} dispatchJob - The dispatch job to duplicate
 * @param {Boolean} [isLoading] - The current loading state of the parent component
 * @returns
 *
 * @example
 * <DispatchDuplicateJob dispatchJob={props.dispatchJob} />
 */
function DispatchDuplicateJob({ ...props }) {

  // ** useStates ** //
  const [showDuplicateJobDialog, setShowDuplicateJobDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /** @todo An array used to render checkboxes for selecting sections to duplicate in the future */
  // const [sectionsArr, setSectionsArr] = useState([
  //   {
  //     name: 'Shipments',
  //     key: 'shipments',
  //   },
  //   {
  //     name: 'Documents',
  //     key: 'documents',
  //   },
  //   {
  //     name: 'Reference Numbers and Notes',
  //     key: 'references_and_notes',
  //   },
  // ]);

  /** @todo An array to hold the selected sections to duplicate */
  // const [selectedSections, setSelectedSections] = useState([]);

  // ** Callback Functions ** //
  const showDialog = () => {
    setShowDuplicateJobDialog(true);
  };

  /** @todo Updates the selected sections state */
  // const onSelectionChange = (e) => {
  //   const _selectedSections = [...selectedSections];

  //   if (e.checked) {
  //     _selectedSections.push(e.value);
  //   } else {
  //     for (let i = 0; i < _selectedSections.length; i++) {
  //       const selectedSection = _selectedSections[i];

  //       if (selectedSection.key === e.value.key) {
  //         _selectedSections.splice(i, 1);
  //         break;
  //       }
  //     }
  //   }

  //   setSelectedSections(_selectedSections);
  // };

  // Callback function that begins the cloning process
  const handleOnSubmit = async () => {
    if (!props.dispatchJob) return;
    setIsLoading(true);
    const dispatchJobObjectId = getAttribute(props.dispatchJob, 'objectId');
    const dispatchJobClone = await cloneDispatchJob(dispatchJobObjectId);
    if (dispatchJobClone) {
      setShowDuplicateJobDialog(false);
      setIsLoading(false);
    }
  };

  /* Misc */
  // const title = 'Duplicate Job';
  const title = 'Are You Sure?';

  return (
    <div className="dispatch-duplicate-job">
      {/* @todo Dialog that offers the option to select sections to duplicate */}
      {/* <Dialog
        className="dispatch-duplicate-job"
        header={title}
        visible={showDuplicateJobDialog}
        onHide={() => setShowDuplicateJobDialog(false)}
        resizeable={false}
        draggable={false}
      >
        <h5>Select Information to Duplicate</h5>
        {
          sectionsArr.map((section) => {
            return (
              <div key={section.key} className="field-checkbox">
                <Checkbox
                  inputId={section.key}
                  name="section"
                  value={section}
                  onChange={onSelectionChange}
                  checked={selectedSections.some((item) => item.key === section.key)}
                />
                <label htmlFor={section.key}>{section.name}</label>
              </div>
            );
          })
        }

        <Button icon="pi pi-check" label="Submit" disabled={props.isLoading} onClick={async () => await cloneDispatchJob()} />
      </Dialog> */}

      <Dialog
        className="dispatch-duplicate-job"
        header={title}
        visible={showDuplicateJobDialog}
        onHide={() => setShowDuplicateJobDialog(false)}
        resizeable={false}
        draggable={false}
      >

        {isLoading && (
          <div className="grid">
            <div className="col-3">
              <ProgressSpinner className="duplicate-loading" strokeWidth={3} />
            </div>
            <div className="col">
              <Message severity="warn" text="Please stay on this page until the process is complete" />
            </div>
          </div>
        )}

        <Button icon="pi pi-times" label="Cancel" disabled={props.isLoading || isLoading} onClick={() => setShowDuplicateJobDialog(false)} />
        <Button icon="pi pi-check" label="Submit" disabled={props.isLoading || isLoading} onClick={() => handleOnSubmit()} />

      </Dialog>

      <DispatchDuplicateJobButton showDialog={showDialog} />
    </div>
  );
}

export default DispatchDuplicateJob;
