import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import history from 'sbHistory';
// Reducers
import { reducer as ReduxForm } from 'redux-form';
import { Main } from './Main';
import { Admin } from './Admin';
import { Auth } from './Auth';
import { SBAlert } from './SBAlert/SBAlert';
import { Chat } from './Chat';
import { Company } from './Company';
import { CloudUserPreference } from './CloudUserPreference';
import { Dispatcher } from './Dispatcher';
import { Document } from './Document';
import { DocumentLayout } from './DocumentLayout';
import { DocumentCategory } from './DocumentCategory';
import { Driver } from './Driver';
import { ELDEvent } from './ELDEvent';
import { ELDViolation } from './ELDViolation';
import { Geofence } from './Geofence';
import { GeofenceEvent } from './GeofenceEvent';
import { JobAction } from './JobAction';
import { JobLink } from './JobLink';
import { Notification } from './Notification';
import { Receipt } from './Receipt';
import { SafetyOfficer } from './SafetyOfficer';
import { Subscription } from './Subscription';
import { Temp } from './Temp';
import { Trailer } from './Trailer';
import { GlobalTrailer } from './GlobalTrailer';
import { User } from './User';
import { Vehicle } from './Vehicle';
import { GlobalVehicle } from './GlobalVehicle';
import { VehicleLocation } from './VehicleLocation';
import { Vendor } from './Vendor';

// Combine Reducers
const appReducer = combineReducers({
  form: ReduxForm,
  Main,
  Auth,
  Chat,
  Company,
  CloudUserPreference,
  Admin,
  Vendor,
  Document,
  DocumentCategory,
  DocumentLayout,
  Dispatcher,
  Driver,
  ELDEvent,
  ELDViolation,
  Geofence,
  GeofenceEvent,
  Notification,
  SafetyOfficer,
  Subscription,
  JobAction,
  JobLink,
  Receipt,
  Temp,
  Trailer,
  User,
  Vehicle,
  VehicleLocation,
  GlobalTrailer,
  GlobalVehicle,
  SBAlert,
  // routing: routerReducer,
  router: connectRouter(history),
});

// const rootReducer = (state = {}, action) => {
//   if (action.type === ActionConstants.CLEAR_STATE) {
//     return {
//       routing: state.routing,
//     };
//   }
//   return appReducer(state, action);
// };

export default appReducer;
