import React from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';

// API
import * as Getters from 'api/Getters';
import * as Geocode from 'api/Geocode';

// Components
import VehiclePopupView from '../../view/components/VehiclePopup';


function isTrailer(object) {
  return object.className === 'Trailer' || object.className === 'tc_devices';
}

class VehiclePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      lastActiveDate: null,
      formattedAddress: '',
    };

    this.getlastActiveDate = this.getlastActiveDate.bind(this);
  }

  componentDidMount() {
    if (!this.props.vehicle.get('speedKm')) {
      this.getlastActiveDate(this.props.vehicle);
    }

    if (isTrailer(this.props.vehicle)) {
      this.setState({ ...this.state, formattedAddress: '' });
      Geocode.getReverseGeocode([[(this.props.coordinates && this.props.coordinates[1]) || this.props.vehicle.get('vehicleLocation').get('location').latitude, (this.props.coordinates && this.props.coordinates[0]) || this.props.vehicle.get('vehicleLocation').get('location').longitude]]).then((geocodeDetails) => {
        if (geocodeDetails && geocodeDetails[0] && geocodeDetails[0].formatted_address) {
          this.setState({ ...this.state, formattedAddress: geocodeDetails[0].formatted_address });
        }
      });
    }

  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.vehicle.get('speedKm') && nextProps.vehicle.id !== this.props.vehicle.id) {
      this.setState({ ...this.state, lastActiveDate: null });
      this.getlastActiveDate(nextProps.vehicle);
    }

    if (nextProps.vehicle.id !== this.props.vehicle.id) {
      this.setState({ ...this.state, formattedAddress: '' });
      if (isTrailer(this.props.vehicle)) {
        Geocode.getReverseGeocode([[(nextProps.coordinates && nextProps.coordinates[1]) || nextProps.vehicle.get('vehicleLocation').get('location').latitude, (nextProps.coordinates && nextProps.coordinates[0]) || nextProps.vehicle.get('vehicleLocation').get('location').longitude]]).then((geocodeDetails) => {
          if (geocodeDetails && geocodeDetails[0] && geocodeDetails[0].formatted_address) {
            this.setState({ ...this.state, formattedAddress: geocodeDetails[0].formatted_address });
          }
        });
      }
    }
  }

  async getlastActiveDate(vehicle) {
    if (!vehicle.get('speedKm')) {
      if (vehicle.className === 'Trailer') {
        const tcDevicesQuery = new Parse.Query('tc_devices');
        tcDevicesQuery.equalTo('uniqueid', vehicle.get('tc_devices_uniqueid'));
        const tcDevicesObj = await tcDevicesQuery.first();
        if (tcDevicesObj) {
          const lastActivePositionArr = await Getters.queryCompanyObjects(
            'tc_positions',
            null,
            25,
            [
              // { name: 'speedKm', value: 0, queryType: 'notEqualTo' }, // MAKES DB SLOW
              { name: 'deviceid', value: tcDevicesObj.get('id'), queryType: 'equalTo' }
            ],
            { devicetime: 'descending' },
            null,
            // true
          );

          const filteredLastActivePositionArr = lastActivePositionArr.filter((vehicleLocationObj) => vehicleLocationObj.get('speedKm'))
          const lastActivePosition = filteredLastActivePositionArr.length > 0 && filteredLastActivePositionArr[0];
          if (lastActivePosition) this.setState({ ...this.state, lastActiveDate: lastActivePosition.get('devicetime') })
        }
      } else {
        const lastActivePositionArr = await Getters.queryCompanyObjects(
          'VehicleLocation',
          null,
          25,
          [
            // { name: 'speedKm', value: 0, queryType: 'notEqualTo' }, // MAKES DB SLOW
            { name: 'vehicle', value: vehicle, queryType: 'equalTo' }
          ],
          { dateTime: 'descending' },
          null,
          // true
        );

        const filteredLastActivePositionArr = lastActivePositionArr.filter((vehicleLocationObj) => vehicleLocationObj.get('speedKm'))
        const lastActivePosition = filteredLastActivePositionArr.length > 0 && filteredLastActivePositionArr[0];

        if (lastActivePosition) this.setState({ ...this.state, lastActiveDate: lastActivePosition.get('dateTime') })
      }
    }
  }

  render() {
    return (
      <VehiclePopupView
        {...this.props}
        lastActiveDate={this.state.lastActiveDate}
        showDetails={this.state.showDetails}
        formattedAddress={this.state.formattedAddress}
      />
    );
  }
}

export default VehiclePopup;
