import Parse from 'parse';
import { copyQuery, count, createQuery, createQueryOr, find, getAttribute, getCurrentUser, includePointers, setQueryRestriction, sortQuery } from 'api/Parse';

import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { AttributeTypes } from 'enums/Driver';

import Driver from 'sbObjects/Driver';
import Company from 'sbObjects/Company';
import ELDEvent from 'sbObjects/ELDEvent';
import ViolationHOS from 'sbObjects/ViolationHOS';
import ViolationSpeed from 'sbObjects/ViolationSpeed';
import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

/** @module Driver */

const _helpers = {
  getIncludedAttributes: function () {
    return ['user', 'driverStatus', 'vehicle', 'vehicle.licensePlate', 'vehicle.eldHardware', 'latestELDEvent', 'belongsToCompany', 'weighStationBypassDriver'];
  },
}

/**
 * @memberof module:Driver
 * 
 * @param {*} driverId 
 * @param {*} fromTime 
 * @param {*} toTime 
 * @returns 
 */
const getHOSViolations = async (driverId, fromTime, toTime) => {
  if (driverId) {
    const hosQuery = new Parse.Query('HOSViolation');
    hosQuery.equalTo('driver', driverId);
    if (fromTime) {
      hosQuery.greaterThan('triggerTime', fromTime);
    }
    if (toTime) {
      hosQuery.lessThanOrEqualTo('triggerTime', toTime);
    }
    return hosQuery.find().then((violations) => {
      let vs = violations.map((violation, index) => {
        return new ViolationHOS(violation.id,
          violation.get('regulationInt'),
          violation.get('triggerTime')
        );
      });
      return vs;
    });
  } else {
    throw new Error("The Driver ID is required.");
  }
}

/**
 * @memberof module:Driver
 * 
 * @param {*} driverId 
 * @param {*} fromTime 
 * @param {*} toTime 
 * @returns 
 */
const getSpeedViolations = async (driverId, fromTime, toTime) => {
  if (driverId) {
    const speedQuery = new Parse.Query('SpeedViolation');
    speedQuery.equalTo('driver', driverId);
    if (fromTime) {
      speedQuery.greaterThan('startTime', fromTime);
    }
    if (toTime) {
      speedQuery.lessThanOrEqualTo('startTime', toTime);
    }
    return speedQuery.find().then((violations) => {
      let vs = violations.map((violation, index) => {
        return new ViolationSpeed(violation.id,
          violation.get('speedKm'),
          violation.get('startTime'),
          violation.get('endTime')
        );
      });
      return vs;
    });
  } else {
    throw new Error("The Driver ID is required.");
  }
}

/**
 * @memberof module:Driver
 * 
 * @param {*} searchTerm 
 * @param {*} company 
 * @returns 
 */
const getDriverQuery = (searchTerm, company) => {
  const driverQuery = new Parse.Query('Driver');
  if (searchTerm) driverQuery.matches('user_fullName', searchTerm, 'i');
  driverQuery.equalTo('belongsToCompany', company);
  driverQuery.include(['user', 'currentELDVersion']);
  return driverQuery;
}

/**
 * @memberof module:CycleHourUSA
 * @description Fetches the top 10 drivers matching the search term
 * 
 * @param {*} searchTerm The search term
 */
async function getSuggestedDrivers(searchTerm, considerChildCompanies, childCompanyObjectIds = []) {
  const queries = [];
  if (childCompanyObjectIds.length > 0) {
    childCompanyObjectIds.map(childCompanyObjectId => {
      return queries.push(getDriverQuery(searchTerm, childCompanyObjectId));
    });
  } else {
    queries.push(getDriverQuery(searchTerm, getAttribute(getCurrentUser(), 'belongsToCompany')));
    if (considerChildCompanies) {
      const childrenQuery = createQuery('CompanyLink');
      setQueryRestriction(childrenQuery, QueryRestrictionTypes.EQUAL_TO, 'parentCompany', getAttribute(getCurrentUser(), 'belongsToCompany'));
      const childCompanies = await find(childrenQuery);
      childCompanies.map((child) => {
        queries.push(getDriverQuery(searchTerm, getAttribute(child, 'childCompany').id));
      });
    }
  }
  const allDriversQuery = createQueryOr(queries);
  setQueryRestriction(allDriversQuery, QueryRestrictionTypes.LIMIT, undefined, 10);
  const drivers = await find(allDriversQuery);
  return drivers;
}

/**
 * @memberof module:Driver
 * 
 * @param {int} page 
 * @param {int} limit 
 * @param {object} sortBy - Sort object
 * @param {array} filters - array of Filter objects
 * @param {bool} includeChildCompanies - include child/sub-companies of this parent company
 * @param {bool} queryAll
 * @param {bool} returnDBObject - return db object, not sb objects 
 */
async function getDrivers(page = 0, limit = 20, sortBy = new Sort(AttributeTypes.USER_FULLNAME, QuerySortOrderTypes.DESCENDING), filters = [], includeChildCompanies, queryAll, returnDBObject) {

  let drivers = [];
  let driverQuery = createQuery('Driver');

  const _filters = [].concat(filters);
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  const pointersArr = [
    'user',
    'driverStatus',
    'vehicle',
    'vehicle.licensePlate',
    'vehicle.eldHardware',
    'latestELDEvent',
    'latestELDEvent.vehicleLocation',
    'latestELDEvent.eldDailyCertification',
    'belongsToCompany',
    'weighStationBypassDriver'
  ];

  if (includeChildCompanies) {
    // if getting child companies' drivers, the additional queries
    const companyLinkQuery = createQuery('CompanyLink');

    const companyLinkFilters = [
      new Filter('parentCompany', belongsToCompany, QueryRestrictionTypes.EQUAL_TO),
      new Filter('authorized', true, QueryRestrictionTypes.EQUAL_TO),
    ];

    companyLinkFilters.map(filter => {
      setQueryRestriction(companyLinkQuery, filter.queryRestriction, filter.attribute, filter.value);
    });

    // now that we have all the child companies, create queries that get the drivers of them
    const companyLinks = await find(companyLinkQuery);
    const childCompanyDriverQueries = companyLinks.map(company => {
      const childCompanyDriverQuery = createQuery('Driver');
      setQueryRestriction(childCompanyDriverQuery, QueryRestrictionTypes.EQUAL_TO, AttributeTypes.BELONGS_TO_COMPANY, getAttribute(company, 'childCompany'));
      return childCompanyDriverQuery;
    })

    // the main current user's company's driver query
    const mainCompanyDriverQuery = createQuery('Driver');
    setQueryRestriction(mainCompanyDriverQuery, QueryRestrictionTypes.EQUAL_TO, AttributeTypes.BELONGS_TO_COMPANY, belongsToCompany);

    // altogether
    driverQuery = createQueryOr([mainCompanyDriverQuery, ...childCompanyDriverQueries]);

  } else {
    setQueryRestriction(driverQuery, QueryRestrictionTypes.EQUAL_TO, AttributeTypes.BELONGS_TO_COMPANY, belongsToCompany);
  }

  // set universal driver filters
  _filters.push(new Filter('isHidden', true, QueryRestrictionTypes.NOT_EQUAL_TO)); // get all non-deleted users

  _filters.map(filter => {
    setQueryRestriction(driverQuery, filter.queryRestriction, filter.attribute, filter.value);
  });

  // at this point, copy current query to get the number of pages for pagination
  let driverCountQuery = copyQuery(driverQuery);

  // sort
  sortQuery(driverQuery, sortBy.order, sortBy.attribute);

  if (!queryAll) {
    setQueryRestriction(driverQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
    setQueryRestriction(driverQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
  }

  includePointers(driverQuery, pointersArr);

  // now get the count and the drivers
  const promises = [count(driverCountQuery), find(driverQuery, false, queryAll)];

  try {
    const [totalDriversCount, dbDrivers] = await Promise.all(promises);
    drivers = dbDrivers;

    if (!returnDBObject) {
      drivers = dbDrivers.map(dbDriver => {
        const dbBelongsToCompany = getAttribute(dbDriver, 'belongsToCompany', true);
        const dbUser = getAttribute(dbDriver, 'user', true);

        const driver = new Driver(
          dbDriver.id,
          getAttribute(dbUser, 'firstName', true),
          getAttribute(dbUser, 'lastName', true),
          getAttribute(dbUser, 'phoneNumber', true),
          getAttribute(dbDriver, 'driversLicense', true),
          getAttribute(dbDriver, 'driversLicenseCountry', true),
          getAttribute(dbDriver, 'licenseExpiryDate', true),
          getAttribute(dbDriver, 'timezoneOffsetFromUTC', true),
          getAttribute(dbDriver, 'eldStatusInt', true),
          getAttribute(dbDriver, 'latestELDEvent', true),
        );

        driver.belongsToCompany = new Company(
          dbBelongsToCompany.id,
          getAttribute(dbBelongsToCompany, 'name', true),
        );

        driver.dbObject = dbDriver;

        return driver;
      });
    }

    return { drivers, totalDriversCount }

  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:Driver
 * @description Determine whether or not the driver is driving based on ELD Status
 * 
 * @param {Driver} driver 
 */
function isDriving(driver) {
  const eldStatusInt = getAttribute(driver, 'eldStatusInt');
  return (eldStatusInt === 3) || (eldStatusInt === 6);
}

export {
  getDrivers,
  getHOSViolations,
  getSpeedViolations,
  getSuggestedDrivers,
  isDriving,
};
