export const AttributeTypes = Object.freeze({
  'DATE_TIME_UTC': 'dateTimeUTC',
  'DRIVER': 'driver',
  'CODE': 'code',
});

export const EventTypes = Object.freeze({
  'ACCELERATION': 1,
  'BRAKING': 2,
  'CORNERING': 3,
});

export const EventTypeDescriptions = Object.freeze({
  'ACCELERATION': 'Acceleration',
  'BRAKING': 'Braking',
  'CORNERING': 'Cornering',
}); 