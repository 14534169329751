import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import FontIcon from 'material-ui/FontIcon';
import { Table } from 'react-bootstrap';

// API
import { getCurrentUser } from 'api/Getters';
import { trailerTypes } from 'api/Lists/EquipmentTypes';
import { formatName } from 'api/Helpers';
// import { getSimUsageRecord } from 'api/Getters'; // WIP

// Actions
import * as ActionConstants from 'actions/ActionConstants';
import { updateTrailerForState } from 'actions/Trailer';

// Components
import SortButton from 'components/SortButton/view/SortButton';
import SimpleTableBody from 'components/SimpleTableBody/container/SimpleTableBody';
import EditTrailerButton from 'components/AddEditEquipment/container/EditTrailerButton';
import DeleteXButton from 'components/ConfirmModal/container/DeleteXButton';
import { MDBIcon, MDBTooltip } from 'mdbreact';

// CSS
import styles from './TrailersTable.module.scss';
import StatusChangeButton from '../../Shared/StatusChangeButton/StatusChangeButton';

class TrailersTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { trailerRows: [], loaded: false };
  }

  componentWillReceiveProps(nextProps) {
    const userSpecialPermission = getCurrentUser(true).userSpecialPermission;
    const disableEquipmentEditEquipment = userSpecialPermission && userSpecialPermission.disableEquipmentEditEquipment;
    const disableEquipmentDeleteEquipment = userSpecialPermission && userSpecialPermission.disableEquipmentDeleteEquipment;

    const newState = { ...this.state };
    newState.loaded = nextProps.Trailer.fetched && (nextProps.Trailer.trailers.length === 0 || (nextProps.Trailer.status === ActionConstants.DISABLE_TRAILERS_SUCCESS || nextProps.Trailer.trailers >= this.state.trailerRows));

    let keyCounter = 0;

    // check ex. if from chid company
    const currentCompanyId = getCurrentUser().get('belongsToCompany').id;

    newState.trailerRows = nextProps.Trailer.trailers.filter(trailer => trailer).map((trailer) => {
      const nextInspectionDate = <span>{`${moment(trailer.get('nextInspectionDate')).format('MMM D, YYYY')}`}</span>;
      const status = trailer.get('jobActions') && trailer.get('jobActions').length !== 0 ? <div className={styles.inJob}>In Job <b>{trailer.get('jobActions')[0].get('jobId')}</b></div> : <div className={styles.sitting}>At <b>{trailer.get('vendor') ? trailer.get('vendor').get('name') : 'n/a'}</b></div>;
      const isFromAssociatedCompany = currentCompanyId !== trailer.get('belongsToCompany').id;
      const trailerRow = (
        <tr key={`${trailer.id}${keyCounter}`} className={styles.trailerRow}>
          <td>
            <div className="d-inline-block font-weight-500">{trailer.get('unitId')}</div>
            {isFromAssociatedCompany &&
              <MDBTooltip className={'d-inline-block'}
                placement="right"
                tag="div"
                tooltipContent={`Associated Company: ${trailer.get('belongsToCompany').get('name')}`}>
                <MDBIcon icon="network-wired" className="ml-3" />
              </MDBTooltip>
            }
          </td>
          <td>
            {trailer.get('nextInspectionDate') && moment(trailer.get('nextInspectionDate')).subtract(1, 'months').isBefore(moment()) &&
              <span className={styles.alert}>{moment(trailer.get('nextInspectionDate')).format('LL')}</span>
            }
            {trailer.get('nextInspectionDate') && !moment(trailer.get('nextInspectionDate')).subtract(1, 'months').isBefore(moment()) &&
              <span>{moment(trailer.get('nextInspectionDate')).format('LL')}</span>
            }
            {!trailer.get('nextInspectionDate') &&
              <span className={styles.alert}>No MVI Inspection</span>
            }
          </td>
          <td className="center">
            <select
              value={trailer.get('type') || ''} onChange={(event) => {
                updateTrailerForState(trailer, 'type', event.target.value);
              }}
            >
              {trailerTypes.map((type) => <option key={type} value={type}>{type}</option>)}
            </select>
          </td>
          <td>
            {trailer.get('tc_devices_uniqueid')}
            {trailer?.get('tc_devices')?.get('lastupdate') &&
              (
                <div>
                  <kbd>{`Last Pinged at ${moment(trailer?.get('tc_devices')?.get('lastupdate'))?.format('lll')}`}</kbd>
                </div>
              )
            }
          </td>
          <td><textarea className={styles.notes} value={trailer.get('notes') || ''} placeholder="Vehicle Notes..." readOnly /></td>
          <td className="centerText">
            {!disableEquipmentEditEquipment &&
              <div style={{ display: 'inline-block' }}><EditTrailerButton trailer={trailer} /></div>
            }
            {trailer.get('enabled') &&
              <StatusChangeButton buttonText="Disable" buttonColor="warning" handleAction={(confirmBool) => this.props.disableTrailer(confirmBool, trailer)} />
            }
            {!trailer.get('enabled') &&
              <span>
                <StatusChangeButton buttonText="Enable" buttonColor="secondary" handleAction={(confirmBool) => this.props.enableTrailer(confirmBool, trailer)} />
                {!disableEquipmentDeleteEquipment &&
                  <StatusChangeButton buttonText="Delete" confirmationContent={'Are you sure you want to permanently delete Trailer:' + formatName(trailer.get('unitId')) + '?'} buttonColor="danger" handleAction={(confirmBool) => this.props.deleteTrailer(confirmBool, trailer)} />
                }
              </span>
            }
          </td>
        </tr>
      );
      keyCounter++;
      return trailerRow; // defaukt
    });
    this.setState(newState);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.sort !== this.props.sort) {
      return true;
    }
    if (nextProps.Trailer !== this.props.Trailer) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <div className="dataTable mt-0" onScroll={(e) => { if (this.state.loaded) this.props.scrollHandler(e); }}>
        <Table responsive hover>
          <thead>
            <tr>
              <th>
                Unit ID
                <SortButton
                  clickHandler={() => this.props.sortHandler('unitId')}
                  isActive={this.props.sort.unitId}
                />
              </th>
              <th>
                Next Inspection
                <SortButton
                  clickHandler={() => this.props.sortHandler('nextInspectionDate')}
                  isActive={this.props.sort.nextInspectionDate}
                />
              </th>
              <th>
                Type
                <SortButton
                  clickHandler={() => this.props.sortHandler('type')}
                  isActive={this.props.sort.type}
                />
              </th>
              <th>
                Tracker
                <SortButton
                  clickHandler={() => this.props.sortHandler('tc_devices_uniqueid')}
                  isActive={this.props.sort.tc_devices_uniqueid}
                />
              </th>
              <th>
                Notes
                <SortButton
                  clickHandler={() => this.props.sortHandler('notes')}
                  isActive={this.props.sort.notes}
                />
              </th>
              <th className="centerText" />
            </tr>
          </thead>
          <SimpleTableBody rows={this.state.trailerRows} loaded={this.state.loaded} columns="5" />
        </Table>
      </div>
    );
  }
}

TrailersTable.propTypes = {
  Trailer: PropTypes.object.isRequired,
  disableTrailer: PropTypes.func.isRequired,
  deleteTrailer: PropTypes.func,
  enableTrailer: PropTypes.func,
  scrollHandler: PropTypes.func,
  sortHandler: PropTypes.func,
  sort: PropTypes.object,
};

export default TrailersTable;
