import React from 'react';
import PropTypes from 'prop-types';
import { FontIcon } from 'material-ui';
import { Table } from 'react-bootstrap';

// Components
import DeleteXButton from 'components/ConfirmModal/container/DeleteXButton';

// API
import * as Helpers from 'api/Helpers';

// CSS
import styles from './ActionList.module.scss';

function ActionList(props) {
  function getVehicle(jobActionEntry) {
    if (jobActionEntry.driver && jobActionEntry.driver.get('vehicle')) {
      return jobActionEntry.driver.get('vehicle').get('unitId');
    }
    if (jobActionEntry.vehicle) {
      return jobActionEntry.vehicle.get('unitId');
    }
    return undefined;
  }

  function getDrivers(jobActionEntry) {
    if (jobActionEntry.vehicle && jobActionEntry.vehicle.get('drivers')) {
      return Helpers.concatenateStringsInParseObjectsArray(jobActionEntry.vehicle.get('drivers'), 'user_fullName', true);
    }
    if (jobActionEntry.driver) {
      return jobActionEntry.driver.get('user_fullName');
    }
    return undefined;
  }

  return (
    <div className="dataTable" className={styles.actionList}>
      <Table className={styles.table}>
        <thead><tr>
          <td>Date</td>
          <td>#</td>
          <td>Location</td>
          <td>Vehicle</td>
          <td>Driver(s)</td>
          <td>Trailer</td>
          <td>Type</td>
          <td></td>
        </tr></thead>
        <tbody>
          {props.jobActionEntries && props.jobActionEntries.map((jobActionEntry) => (
            <tr key={jobActionEntry.jobActionId} className={props.jobActionEntries[props.selectedIndex].jobActionId === jobActionEntry.jobActionId && styles.active} onClick={() => props.selectIndex(jobActionEntry)}>
              <td>{jobActionEntry.date && jobActionEntry.date.format('MMM D, Y')}</td>
              <td>{jobActionEntry.jobId}</td>
              <td>{jobActionEntry.vendor && jobActionEntry.vendor.get('name')}</td>
              <td>{getVehicle(jobActionEntry)}</td>
              <td>{getDrivers(jobActionEntry)}</td>
              <td>{jobActionEntry.trailer && jobActionEntry.trailer.get('unitId')}</td>
              <td>{jobActionEntry.jobActionType}</td>
              <td className={styles.actionOptionButtons}>
                <span>
                  <FontIcon className="material-icons">arrow_upward</FontIcon>
                </span>
                <span>
                  <FontIcon className="material-icons">arrow_downward</FontIcon>
                </span>
                <span><DeleteXButton handleChoice={(confirmBool) => confirmBool && props.deleteJobAction(jobActionEntry)} /></span>
              </td>
            </tr>
          ))}
          <tr className={styles.addAction}><td colSpan="42">
            <div onClick={() => props.addJobAction()}>
              <FontIcon className="material-icons">add</FontIcon>
              &nbsp;Add Action
            </div>
          </td></tr>
        </tbody>
      </Table>
    </div>
  );
}

ActionList.propTypes = {
  openChooseOrAddModal: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  jobActionEntries: PropTypes.array.isRequired,
  addJobAction: PropTypes.func.isRequired,
  deleteJobAction: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  selectIndex: PropTypes.func.isRequired,
  moveJobAction: PropTypes.func.isRequired,
};

export default ActionList;
