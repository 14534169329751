import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { getELDDailyCertificationIntervalFromDriverTZ, isAOBRDEnabledByCount } from 'api/ELD';
import { getPDispatcherPropertyFromState } from 'api/Getters';

import ELDDrivingTimeSwap from 'components/ELDEdit/container/ELDDrivingTimeSwap';

import {
  Table,
  TableHeader,
  TableRow,
  TableRowColumn,
  TableHeaderColumn,
  TableBody,
} from 'material-ui';

import Title from 'components/LayoutTitle/view/Title';

const CoDriversTable = (props) => {
  let { driver, onDate, ELDEvent, eldEvents, coDriverELDDailyCertifications, fetched, eventTypeIndex } = props;

  // only take active eld events
  // note that: eldEvents can be different from the one in ELDEvent prop. it could have a custom filter on events
  const _eldEvents = [].concat(eldEvents).filter(eldEvent => {
    return eldEvent.get('eldEventRecordStatusInt') && eldEvent.get('eldEventRecordStatusInt') === 1;
  });

  let _coDriverELDDailyCertifications = [];
  if (coDriverELDDailyCertifications) _coDriverELDDailyCertifications = [].concat(coDriverELDDailyCertifications);


  const disableELDEditBasedOnPending = (ELDEvent && ELDEvent.eldEdits.length > 0);

  let dayIncomplete = true;
  const currentDriverDate = moment();
  if (driver.get('timezoneOffsetFromUTC')) {
    currentDriverDate.tz(driver.get('timezoneOffsetFromUTC'));
  }
  if (props.timezoneOffsetFromUTC) {
    currentDriverDate.tz(props.timezoneOffsetFromUTC);
  }

  if (ELDEvent.eldDailyCertification) {
    const endTime = moment(ELDEvent.eldDailyCertification.get('startTimeUTC')).add(24, 'hour');
    dayIncomplete = endTime.valueOf() > currentDriverDate.valueOf(); // 22:00
  }


  let isPastFourteenDays = true;
  const currentELDVersion = driver && driver.get('currentELDVersion');
  if (currentELDVersion && (parseFloat(currentELDVersion) > .89)) {
    isPastFourteenDays = false;
  }
  else if (ELDEvent.eldDailyCertification) {
    const startTimeUTC = ELDEvent.eldDailyCertification.get('startTimeUTC');
    const currentDateStartOf = getELDDailyCertificationIntervalFromDriverTZ(driver, moment().startOf('day').toDate()).dayOf;
    const fourteenDaysAgo = moment(currentDateStartOf).subtract(14, 'days').toDate();
    isPastFourteenDays = startTimeUTC.getTime() < fourteenDaysAgo.getTime();
  }

  const isAOBRDDay = isAOBRDEnabledByCount(_eldEvents);

  // Styles
  const tableHeaderColumnStyle = { width: '25%' };


  // Renderings
  let coDriverRows = _coDriverELDDailyCertifications.map(eldDailyCertification => {
    if (!eldDailyCertification || !eldDailyCertification.get('driver')) return;

    const coDriverUser = eldDailyCertification.get('driver').get('user');
    const dayIsCertified = eldDailyCertification && _eldEvents[0] && _eldEvents[0].get('eldDailyCertification').get('certified') && ELDEvent.fetched;

    const disabled = isPastFourteenDays || eventTypeIndex >= 1 || dayIncomplete || disableELDEditBasedOnPending || (!isAOBRDDay && !dayIsCertified);

    return (
      <TableRow key={Math.random() + eldDailyCertification.get('driver').id}>
        <TableRowColumn className="verticalAlignMiddle leftText" style={tableHeaderColumnStyle}>
          <span>
          {/* onClick={() => history.push({ pathname: 'driver', search: `driver=${driverPeriod.driver.objectId}&view=hosEvents&date=${moment(driverPeriod.dateStart.iso).format('DDMMYY')}` })} */}
            <a href={`/driver?driver=${eldDailyCertification.get('driver').id}&view=hosEvents&date=${moment(onDate).format('DDMMYY')}`}>
              {`${coDriverUser.get('firstName')} ${coDriverUser.get('lastName')}`}
            </a>
          </span>
        </TableRowColumn>
        <TableRowColumn className="verticalAlignMiddle leftText" style={tableHeaderColumnStyle}>
          { eldDailyCertification.get('certified') ?
            <span>{t('Certified')}</span>
            :
            <span>{t('Uncertified')}</span>
          }
        </TableRowColumn>
        <TableRowColumn className="verticalAlignMiddle leftText" style={{ width: '50%' }}>
          <ELDDrivingTimeSwap
            driver={driver}
            coDriver={eldDailyCertification.get('driver')}
            onDate={new Date(onDate)}
            updateHandler={props.updateHandler}
            disabled={disabled}
          />
        </TableRowColumn>
      </TableRow>
    );
  });
  if (coDriverRows.length === 0) {
    coDriverRows = (
      <TableRow><TableRowColumn colSpan="69" className="emptyRowMessage verticalAlignMiddle">
        {t('No Co-Drivers This Day')}
      </TableRowColumn></TableRow>
    );
  }

  return (
    <div>
      { eldEvents && eldEvents.length > 0 && eldEvents[0].get('eldDailyCertification') &&
        <span>
          <Title className="h3" title={<span className="translate-me">Co-Driver Info</span>} />
          <Table
            wrapperStyle={{ overflow: 'hidden' }}
          >
            <TableHeader
              displaySelectAll={false}
              adjustForCheckbox={false}
              enableSelectAll={false}
            >
              <TableRow className="translate-me">
                <TableHeaderColumn style={tableHeaderColumnStyle}>
                  Co-Driver Name
                </TableHeaderColumn>
                <TableHeaderColumn style={tableHeaderColumnStyle}>
                  Day Certified
                </TableHeaderColumn>
                <TableHeaderColumn style={{ width: '50%' }} />
              </TableRow>
            </TableHeader>
            <TableBody
              displayRowCheckbox={false}
              showRowHover
            >
              { coDriverRows }
            </TableBody>
          </Table>
        </span>
      }
    </div>
  );
};

CoDriversTable.propTypes = {
  driver: PropTypes.object,
  onDate: PropTypes.instanceOf(Date),
  updateHandler: PropTypes.func,
  eldEvents: PropTypes.array, // need eld event info from original driver
  coDriverELDDailyCertifications: PropTypes.array,
  ELDEvent: PropTypes.object,
  eventTypeIndex: PropTypes.any,
};

export default CoDriversTable;
