// libraries
import moment from 'moment-timezone';

// sbCore
import Divider from 'sbCore/Divider/Divider';

// styles
import './style.scss';

/**
 * @description sbCore Toast Component content. This component is used for creating/formatting custom toast nofitications, and is passed to the Primereact Toast settings object property "content" (overwriting the "summary" and "detail" properties).
 * @param {String} [className] - Custom className
 * @param {Object} [header] - Toast header
 * @param {Object} [body] - Toast body
 * @param {Object} [footer] - Toast footer
 * @param {Boolean} [hideCurrentDateTime] - Hide the toast's current date/time
 * @returns
 */
export default function ToastContent({ ...props }) {
  let className = 'sbc-toast-content';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={`flex ${className}`}>
      <Divider layout="vertical" align="top">
        <i className="pi pi-info-circle toast-divider-icon" style={{ fontSize: '1.5em' }} />
      </Divider>

      <div className="flex flex-column">
        {props.header && <div className="toast-header">{props.header}</div>}
        {!props.hideCurrentDateTime && <div className="toast-date">{moment().format("MMMM D, YYYY HH:mm")}</div>}
        {props.body && <div className="toast-body">{props.body}</div>}
        {props.footer && <div className="toast-footer">{props.footer}</div>}
      </div>
    </div>
  );
}
