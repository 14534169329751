import React, { useState, useEffect } from 'react';

// CSAPI Enums
import { NumberOfDays } from 'sb-csapi/dist/enums/Dispatch/Invoice';

// sbCore Components
import MultiSelect from 'sbCore/MultiSelect/MultiSelect';
import Chip from 'sbCore/Chip/Chip';

// Styles
import './style.scss';

/**
 * @description Component that allows selecting multiple payment terms from a dropdown
 * @param {String} [className] - Optional classNames
 * @param {Array} [selectedPaymentTerms] - Possible pre-selected payment terms
 * @param {Function} [onChange] - Callback function which runs additional logic when selecting/unselecting payment terms
 * @returns
 *
 * @example
 * <PaymentTermMultiSelect />
 */
function PaymentTerMultiSelect({ ...props }) {
  // Skip the first element which is zero days
  const paymentTermDays = Object.keys(NumberOfDays).slice(1).map(key => NumberOfDays[key]);

  // ** useStates ** //
  const [selectedPaymentTerms, setSelectedPaymentTerms] = useState([]);

  // ** useEffects ** //
  useEffect(() => {
    setSelectedPaymentTerms(props.selectedPaymentTerms);
  }, [props.selectedPaymentTerms]);

  // ** Callback Functions ** //
  function onChange(value) {
    if (props.onChange) props.onChange(value);
    setSelectedPaymentTerms(value);
  }

  // ** MultiSelect Templates ** //
  const paymentTermTemplate = (option) => (<Chip label={`${option.value} days`} />);

  const selectedPaymentTermsTemplate = (option) => {
    if (option) {
      return (
        <Chip
          className="payment-term-chip"
          label={`${option} days`}
        />
      );
    }

    return 'Payment Terms Filter';
  };

  let className = 'payment-term-multi-select';
  if (props.className) className += ` ${props.className}`;
  return (
    <div className={className}>
      <MultiSelect
        value={selectedPaymentTerms}
        options={paymentTermDays}
        onChange={(e) => onChange(e.value)}
        itemTemplate={paymentTermTemplate}
        selectedItemTemplate={selectedPaymentTermsTemplate}
        optionLabel="value"
      />
    </div>
  );
}

export default PaymentTerMultiSelect;
