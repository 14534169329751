import React from 'react';
import { Layer, Source } from 'react-mapbox-gl';

// API


// CSS
// import styles from './TownshipCanadaLayer.module.scss';

const twpSource = {
  type: 'vector',
  url: 'mapbox://mepa1363.4q99xeko',
};

const twpLabelSource = {
  type: 'vector',
  url: 'mapbox://mepa1363.4bmywlh3',
};

const secSource = {
  type: 'vector',
  url: 'mapbox://mepa1363.90h41gu1',
};

const secLabelSource = {
  type: 'vector',
  url: 'mapbox://mepa1363.4512mtgn',
};

const qtrSource = {
  type: 'vector',
  url: 'mapbox://mepa1363.4qqr1a07',
};

const qtrLabelSource = {
  type: 'vector',
  url: 'mapbox://mepa1363.0zihk19q',
};

const lsd1Source = {
  type: 'vector',
  url: 'mapbox://mepa1363.c06tbvl9',
};

const lsd2Source = {
  type: 'vector',
  url: 'mapbox://mepa1363.265dstdf',
};

const lsdLabelSource = {
  type: 'vector',
  url: 'mapbox://mepa1363.9iqm3vj8',
};

class TownshipCanadaLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (

      <div>
        <Source id="twpSource" tileJsonSource={twpSource} />
        <Layer
          id="twpLayer" sourceId="twpSource"
          sourceLayer="twp-8ybbc5"
          type="line"
          maxZoom={12}
          layout={{
            'line-join': 'miter',
            'line-cap': 'square',
          }}
          paint={{
            'line-color': '#333',
            'line-width': 1,
          }}
        />

        <Source id="twpLabelSource" tileJsonSource={twpLabelSource} />
        <Layer
          id="twpLabelLayer" sourceId="twpLabelSource"
          sourceLayer="twp_centroid-d961xb"
          minZoom={9}
          maxZoom={12}
          layout={{
            'symbol-placement': 'point',
            'text-anchor': 'center',
            'text-field': '{descriptor}',
            'text-justify': 'center',
            'text-keep-upright': true,
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'], // if light-v9 remove Bold
          }}
          paint={{
            'text-color': '#333',
            'text-halo-color': '#dedede',
            'text-halo-width': 2,
            'text-halo-blur': 2,
          }}
        />

        <Source id="secSource" tileJsonSource={secSource} />
        <Layer
          id="secLayer" sourceId="secSource"
          sourceLayer="sec-3nuegb"
          type="line"
          minZoom={12}
          maxZoom={14}
          layout={{
            'line-join': 'miter',
            'line-cap': 'square',
          }}
          paint={{
            'line-color': '#333',
            'line-width': 1,
          }}
        />

        <Source id="secLabelSource" tileJsonSource={secLabelSource} />
        <Layer
          id="secLabelLayer" sourceId="secLabelSource"
          sourceLayer="sec_centroid-3upcye"
          minZoom={12}
          maxZoom={14}
          layout={{
            'symbol-placement': 'point',
            'text-anchor': 'center',
            'text-field': '{descriptor}',
            'text-justify': 'center',
            'text-keep-upright': true,
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'], // if light-v9 remove Bold
          }}
          paint={{
            'text-color': '#333',
            'text-halo-color': '#dedede',
            'text-halo-width': 2,
            'text-halo-blur': 2,
          }}
        />

        <Source id="qtrSource" tileJsonSource={qtrSource} />
        <Layer
          id="qtrLayer" sourceId="qtrSource"
          sourceLayer="qtr-7o1e9e"
          type="line"
          minZoom={14}
          maxZoom={15}
          layout={{
            'line-join': 'miter',
            'line-cap': 'square',
          }}
          paint={{
            'line-color': '#333',
            'line-width': 1,
          }}
        />

        <Source id="qtrLabelSource" tileJsonSource={qtrLabelSource} />
        <Layer
          id="qtrLabelLayer" sourceId="qtrLabelSource"
          sourceLayer="qtr_centroid-bw0d2d"
          minZoom={14}
          maxZoom={15}
          layout={{
            'symbol-placement': 'point',
            'text-anchor': 'center',
            'text-field': '{descriptor}',
            'text-justify': 'center',
            'text-keep-upright': true,
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'], // if light-v9 remove Bold
          }}
          paint={{
            'text-color': '#333',
            'text-halo-color': '#dedede',
            'text-halo-width': 2,
            'text-halo-blur': 2,
          }}
        />

        <Source id="lsd1Source" tileJsonSource={lsd1Source} />
        <Layer
          id="lsd1Layer" sourceId="lsd1Source"
          sourceLayer="lsd_1-8ga2lk"
          type="line"
          minZoom={15}
          maxZoom={20}
          layout={{
            'line-join': 'miter',
            'line-cap': 'square',
          }}
          paint={{
            'line-color': '#333',
            'line-width': 1,
          }}
        />

        <Source id="lsd2Source" tileJsonSource={lsd2Source} />
        <Layer
          id="lsd2Layer" sourceId="lsd2Source"
          sourceLayer="lsd_2-dnfr29"
          type="line"
          minZoom={15}
          maxZoom={20}
          layout={{
            'line-join': 'miter',
            'line-cap': 'square',
          }}
          paint={{
            'line-color': '#333',
            'line-width': 1,
          }}
        />

        <Source id="lsdLabelSource" tileJsonSource={lsdLabelSource} />
        <Layer
          id="lsdLabelLayer" sourceId="lsdLabelSource"
          sourceLayer="lsd_centroid-0jsyk9"
          minZoom={15}
          maxZoom={20}
          layout={{
            'symbol-placement': 'point',
            'text-anchor': 'center',
            'text-field': '{descriptor}',
            'text-justify': 'center',
            'text-keep-upright': true,
            'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'], // if light-v9 remove Bold
          }}
          paint={{
            'text-color': '#333',
            'text-halo-color': '#dedede',
            'text-halo-width': 2,
            'text-halo-blur': 2,
          }}
        />

      </div>
    );
  }
}

TownshipCanadaLayer.propTypes = {
};

export default TownshipCanadaLayer;
