import React from 'react';
import PropTypes from 'prop-types';

// Components
import AddEditRouteModal from '../view/AddEditRouteModal';
import { MDBBtn, MDBIcon } from 'mdbreact';
import Button from 'sbCore/Button/Button';

// Api
import { updateRecord } from 'api/Parse';

class AddRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addRouteModal: {
        show: false,
      }
    }

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal() {
    this.state.addRouteModal.show = true;
    this.setState({ ...this.state });
  }

  async closeModal(hideRoute) {
    if (hideRoute && this.props.isEdit) {
      await updateRecord(
        this.props.iftaRoute,
        {
          isHidden: true,
        },
        true,
      );
    }
    this.state.addRouteModal.show = false;
    this.setState({ ...this.state });
  }

  render() {
    const { unitId, isEdit, iftaRoute, distanceUnitFilter } = this.props;
    const { state, props } = this;
    if (isEdit) {
      return (
        <React.Fragment>
          {state.addRouteModal.show && (
            <AddEditRouteModal
              show={state.addRouteModal.show}
              handleClose={async (hideRoute) => await this.closeModal(hideRoute)}
              unitId={unitId}
              isEdit={isEdit}
              iftaRoute={iftaRoute}
              distanceUnitFilter={distanceUnitFilter}
              refreshState={(callRefreshState) => this.props.refreshState(callRefreshState)}
              iftaRouteRecordsArr={this.props.iftaRouteRecordsArr}
              driverTimeZoneStr={this.props.driverTimeZoneStr}
              iftaRouteRecordsForTablePreview={this.props.iftaRouteRecordsForTablePreview}
              unhideRouteFromCancel={() => this.unhideRouteFromCancel()}
            />
          )}
          <MDBBtn
            size="sm"
            style={(props.customEditButtonText && { width: '18em', margin: '0em' }) || { width: '6em', margin: '0em' }}
            color="secondary"
            onClick={this.showModal}
          >
            <MDBIcon icon="edit" className={props.customEditButtonText && 'mr-2'} />{props.customEditButtonText}
          </MDBBtn>
        </React.Fragment>
      );
    } else {
      return (
        <div className="inline-block">
          {state.addRouteModal.show && (
            <AddEditRouteModal
              show={state.addRouteModal.show}
              handleClose={(hideRoute) => this.closeModal(hideRoute)}
              unitId={unitId}
              isEdit={isEdit}
              distanceUnitFilter={distanceUnitFilter}
              refreshState={(callRefreshState) => this.props.refreshState(callRefreshState)}
              iftaRouteRecordsArr={this.props.iftaRouteRecordsArr}
              driverTimeZoneStr={this.props.driverTimeZoneStr}
              iftaRouteRecordsForTablePreview={this.props.iftaRouteRecordsForTablePreview}
              unhideRouteFromCancel={() => this.unhideRouteFromCancel()}
            />
          )}
          <Button
            text
            sbVariant="short"
            onClick={this.showModal}
            label={(
              <span>
                <MDBIcon icon="plus" className="mr-2" />
                {(props.customAddButtonText && props.customAddButtonText) || `ADD ROUTES FOR ${unitId}`}
              </span>
            )}
          />
        </div>
      );
    }
  }
}

export default AddRoute;
