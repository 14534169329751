import React from 'react';
import PropTypes from 'prop-types';
import history from "../../../sbHistory";
import moment from 'moment-timezone';
import { RaisedButton, TableHeader, TableBody, TableRow, TableRowColumn } from 'material-ui';

// Components
import LazyLoadTable from 'components/LazyLoadTable/container/LazyLoadTable';
import SortButton from 'components/SortButton/view/SortButton';

// CSS
import styles from './ViolationsSummaryTable.module.scss';
import ViolationsSummaryTableRow from '../container/ViolationsSummaryTableRow';

class ViolationsSummaryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetBool: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.Driver.drivers.length > 0 && nextProps.Driver.drivers.length === 0) {
      this.setState({ ...this.state, resetBool: true }, () => {
        this.setState({ ...this.state, resetBool: false });
      });
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <LazyLoadTable
          fixedHeader
          fetched={this.props.Driver.fetched}
          resetBool={this.state.resetBool}
          rowHeight={48}
          height={Math.max(0.6 * document.documentElement.clientHeight, 0.6 * window.innerHeight || 0)}
        >
          <TableHeader
            adjustForCheckbox={false}
            displaySelectAll={false}
            style={{ fontWeight: 'bold' }}
          >
            <TableRow>
              <TableRowColumn
                style={{ textAlign: 'center' }}
              >
                Driver
              </TableRowColumn>
              <TableRowColumn
                style={{ textAlign: 'center' }}
              >
                Violations This Month
              </TableRowColumn>

              <TableRowColumn />
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={false}
          >
            { this.props.Driver.drivers.length > 0 &&
              this.props.Driver.drivers.map((driver) => (
                <ViolationsSummaryTableRow
                  key={driver.id}
                  driver={driver}
                  date={this.props.date}
                />
              ))
            }
          </TableBody>
        </LazyLoadTable>
      </div>
    );
  }
}

ViolationsSummaryTable.propTypes = {
  Driver: PropTypes.object.isRequired,
  date: PropTypes.object,
  sortHandler: PropTypes.func,
  sort: PropTypes.object,
};

export default ViolationsSummaryTable;
