import React from 'react';

// PrimeReact Component
import { InputMask as PRInputMask } from 'primereact/inputmask';

// Styles
import './style.scss';

export default function InputMask({ warning, error, success, ...props }) {
  let className = 'sbc-input-mask';
  if (error) {
    className += ` sbc-error`;
  } else if (warning) {
    className += ` sbc-warning`;
  } else if (success) {
    className += ` sbc-success`;
  }

  if (props.containerClassName) className += ` ${props.containerClassName}`;

  return (
    <div className={className}>
      <PRInputMask {...props} />
    </div>
  );
}
