import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// API
import { getDispatchAccessorials, removeDispatchAccessorial } from 'api/Dispatch/DispatchAccessorial';
import { getDispatchPayables, updateDispatchPayable } from 'api/Dispatch/DispatchPayable';

// CSAPI API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// CSAPI Enums
import { AccountingEntryType } from 'sb-csapi/dist/enums/Dispatch/AccountingEntryType';
import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

// CSAPI Objects
import Filter from 'sb-csapi/dist/sbObjects/Filter';
import Sort from 'sb-csapi/dist/sbObjects/Sort';

// Components
import DispatchAccessorialEditDialog from 'components/Dispatch/DispatchAccessorialEditDialog/DispatchAccessorialEditDialog';
import DispatchAccessorialCardsView from 'components/Dispatch/DispatchAccessorialSummary/DispatchAccessorialCardsView';

// sbCore Components
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import Button from 'sbCore/Button/Button';
import Message from 'sbCore/Message/Message';

// Styles
import './style.scss';

/**
 * @description A colored section which displays the DispatchAccessorials belonging to a DispatchJob
 * @param {String} className - Custom container className
 * @param {DispatchJob} dispatchJob - DispatchJob to retrieve DispatchAccessorials with
 * @param {DispatchTransfer} dispatchTransfer - dispatchTransfer to be passed as a preselected value
 * @param {Boolean} isLoading - Parent's isLoading state
 *
 * @returns
 *
 * @example
 * <DispatchAccessorialSummary />
 */
function DispatchAccessorialSummary({ ...props }) {

  // ** useStates ** //
  const [isLoading, setIsLoading] = useState(false);
  const [dispatchAccessorials, setDispatchAccessorials] = useState(null);
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const [selectedDispatchJob, setSelectedDispatchJob] = useState(null);
  const [selectedDispatchTransfer, setSelectedDispatchTransfer] = useState(null);
  const [selectedDispatchAccessorial, setSelectedDispatchAccessorial] = useState(null);

  const [refreshState, setRefreshState] = useState(null);

  // ** useEffects ** //
  // Retrieve the DispatchAccessorials from the associated job, or whenever a state refresh is triggered
  useEffect(() => {
    let didCancel = false;
    if (!props.dispatchJob) return;

    async function getAssociatedDispatchAccessorials() {
      const { dispatchAccessorials } = await getDispatchAccessorials(
        undefined,
        props.dispatchJob,
        undefined,
        new Sort(QuerySortOrder.ASCENDING, 'dispatchTransfer'),
        ['dispatchTransfer', 'dispatchTransfer.shipperDispatchOrganization', 'dispatchTransfer.consigneeDispatchOrganization', 'dispatchPayee'],
        undefined,
        undefined,
        undefined,
        true,
      );

      if (!didCancel) {
        setDispatchAccessorials(dispatchAccessorials);
      }
    }

    getAssociatedDispatchAccessorials();
    return () => { didCancel = true; };
  }, [props.dispatchJob, refreshState]);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  // ** Callback Functions ** //
  // If a DispatchAccessorial is being edited, set only the Accessorial
  const openAccessorialEditDialog = (dispatchAccessorial) => {
    setSelectedDispatchAccessorial(dispatchAccessorial);
    setSelectedDispatchTransfer(null);
    setSelectedDispatchJob(null);
    setIsDialogVisible(true);
  };

  // If a DispatchAccessorial is being added, set only the Transfer and Job
  const openAccessorialAddDialog = () => {
    setSelectedDispatchAccessorial(null);
    setSelectedDispatchTransfer(props.dispatchTransfer);
    setSelectedDispatchJob(props.dispatchJob);
    setIsDialogVisible(true);
  };

  // When the dialog is closed, reset the state
  const onCancel = () => {
    setIsDialogVisible(false);
    setSelectedDispatchTransfer(null);
    setSelectedDispatchJob(null);
    setSelectedDispatchAccessorial(null);
    setRefreshState(uniqid());
  };

  const deleteDispatchAccessorial = async (dispatchAccessorial) => {
    // If the entryType is a payable and the accessorial's being updated, void the old payable
    const entryType = getAttribute(dispatchAccessorial, 'entryType');
    if (entryType === AccountingEntryType.PAYABLE.type) {

      const filters = ([
        new Filter(QueryRestriction.EQUAL_TO, 'dispatchAccessorial', dispatchAccessorial),
        new Filter(QueryRestriction.EQUAL_TO, 'isVoided', false || undefined),
      ]);

      const { dispatchPayables } = await getDispatchPayables(
        undefined,
        undefined,
        filters,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      );
      if (dispatchPayables?.length > 0) await updateDispatchPayable(dispatchPayables[0], undefined, { isVoided: true }, true);
    }
    await removeDispatchAccessorial(dispatchAccessorial);
    setRefreshState(uniqid());
  };

  // ** Components ** //
  const addAccessorialDialogButton = (
    <Button
      className="text-xs"
      label="Add Accessorial / Adjustment"
      onClick={openAccessorialAddDialog}
      disabled={isLoading}
    />
  );

  // ** Misc ** //
  let className = 'dispatch-accessorial-summary';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>

      <ColoredSection title="Accessorials and Adjustments" options={addAccessorialDialogButton}>
        {dispatchAccessorials && (
          <DispatchAccessorialCardsView
            dispatchAccessorials={dispatchAccessorials}
            openAccessorialEditDialog={openAccessorialEditDialog}
            deleteDispatchAccessorial={deleteDispatchAccessorial}
          />
        )}

        {!dispatchAccessorials || (dispatchAccessorials && dispatchAccessorials.length <= 0 && (
          <Message className="w-full" severity="info" text="No Accessorials Available To Display" />
        ))}
      </ColoredSection>

      <DispatchAccessorialEditDialog
        dispatchJob={selectedDispatchJob}
        dispatchTransfer={selectedDispatchTransfer}
        dispatchAccessorial={selectedDispatchAccessorial}
        visible={isDialogVisible}
        onCancel={onCancel}
      />

    </div>
  );
}

export default DispatchAccessorialSummary;
