import React, { createContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';
import uniqid from 'uniqid';

// api
import { getCurrentUser, getAttribute, getObjectById, updateRecord } from 'api/Parse';
import { isSubscribedToModule, formatName, compressWhitespace } from 'api/Helpers';
import { getHistory, addHistory } from 'api/Dispatch/DispatchHistory';
import { addComment, getComments } from 'api/Dispatch/DispatchComment';
import * as DispatchJob from 'api/Dispatch/DispatchJob.old';
import { getDispatchItems } from 'api/Dispatch/DispatchItem.old';
import { getDispatchItemTransfers } from 'api/Dispatch/DispatchTransfer.old';
// import { getDocuments, getAllDocuments } from 'api/Dispatch/DispatchDocument';
// import { getTransferDrivers } from 'api/Dispatch/DispatchDriver.old';
// import { getDispatchQuotes } from 'api/Dispatch/DispatchQuote';
// import { convertParseVehicleLocationArrToPointArr } from 'api/Helpers';

// Context
import { DispatchJobLayoutProvider } from 'contexts/DispatchJobLayoutContext';

// enums
import { DispatchSubroute } from 'enums/URL';
import { DocumentTypes } from 'enums/DispatchDocument';
import { StatusTypes, getStatusDescription, StatusTypesOrderedFull } from 'enums/DispatchJob';
import { LengthUnit, LengthUnitName, LengthConversion, MassUnit, MassUnitName, MassConversion } from 'enums/DispatchUnit';

//CSAPI Enums
import { Document } from 'sb-csapi/dist/enums/Dispatch/Document';

// sbobjects
import Comment from 'sbObjects/Comment';

// layouts
import DispatchDocumentsLayout from 'layouts/Dispatch/DocumentsLayout/DocumentsLayout';

// sbCore
import Divider from 'sbCore/Divider/Divider';

// components
import CommentSystem from 'components/Shared/CommentSystem/CommentSystem';
import FooterPromptBar from 'components/Shared/FooterPromptBar/FooterPromptBar';
// import JobUserAssign from 'components/Dispatch/JobUserAssign/JobUserAssign';
import DispatchConfirmModal from 'components/Dispatch/DispatchConfirmModal/DispatchConfirmModal';
import DispatchOrdersSummary from 'components/Dispatch/DispatchOrdersSummary/DispatchOrdersSummary';
import Sidebar from '../DispatchLayout/Sidebar';
import DocumentOutdatedPrompt from 'components/Dispatch/DocumentOutdatedPrompt/DocumentOutdatedPrompt';
import DocumentOutdatedToast from 'components/Dispatch/DocumentOutdatedPrompt/DocumentOutdatedToast';

// CSS
import './style.scss';

class DispatchJobLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqid: uniqid(),
      dispatchJob: undefined,
      history: [],
      comments: [],
      isLoading: false,
      showConfirmModal: false,
      dispatchJobAttributeChanges: {},
      showFooterPromptBar: false,
      // compareTransferHash: {},
      assignMode: undefined,
      items: [],
      addresses: [],
      selectedDocumentTypes: [],
      routes: [],
      itemsPerRouteHash: {},
      reminders: {},
      defaultLengthUnit: undefined,
      defaultMassUnit: undefined,
      selectedStatus: undefined,
      views: {
        ORDERS_LIST: 'orders',
        DISPATCH: 'dispatching',
        ACCOUNTING: 'accounting',
        ORGANIZATIONS: 'organizations',
        DRIVERS_LIST: 'drivers',
        INVOICES: 'invoices',
        COMPANY_SETTINGS: 'company_settings',
      },
      searchIsOpen: false,
      dispatchDocumentRefreshToken: null,
      generalInformationState: false,
      isDocumentOutdated: false,
      dispatchDocumentTypesToSave: [],
    };

    this.state.selectableStatusItems = Object.keys(StatusTypesOrderedFull).map(statusType => {
      const statusTypeObj = StatusTypesOrderedFull[statusType];
      const classKeyExpression = statusTypeObj.description.replace(/_/g, '-').toLowerCase();
      const labelContainerTextClassName = `text-dispatch-job-${classKeyExpression}`
      const labelContainerHoverClassName = `hover-dispatch-job-${classKeyExpression}`;
      const activeClassName = `bg-dispatch-job-${classKeyExpression}`;

      return {
        key: statusTypeObj.status,
        value: statusTypeObj.status,
        label: statusTypeObj.description,
        labelContainerClassName: `${labelContainerTextClassName} ${labelContainerHoverClassName}`,
        activeClassName,
      };
    });

    this.toggleLoadingState = this.toggleLoadingState.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.handleCommentSubmit = this.handleCommentSubmit.bind(this);
    this.updateDispatchJobAttributeChanges = this.updateDispatchJobAttributeChanges.bind(this);
    this.updateJob = this.updateJob.bind(this);
    this.removeJob = this.removeJob.bind(this);
    this.refreshJob = this.refreshJob.bind(this);
    this.setDispatchDocumentRefreshToken = this.setDispatchDocumentRefreshToken.bind(this);
    // this.toggleGeneralInformationState = this.toggleGeneralInformationState.bind(this);
    this.setIsDocumentOutdatedPrompt = this.setIsDocumentOutdatedPrompt.bind(this);
    this.setDispatchDocumentTypesToSave = this.setDispatchDocumentTypesToSave.bind(this);
  }

  async componentDidMount() {
    const { props, state } = this;
    const currentUser = getCurrentUser();
    const userSpecialPermission = getAttribute(currentUser, 'userSpecialPermission');
    const isNotUserAllowedPermission = userSpecialPermission && getAttribute(userSpecialPermission, 'disableDispatchModule');
    const isNotCompanyAllowedPermission = !isSubscribedToModule('dispatchModule');

    document.title = `Job Details - Switchboard`;

    if (isNotUserAllowedPermission || isNotCompanyAllowedPermission) {
      return history.push('/404');
    }

    return await this.refreshState(props.match.params.subroute);
  }

  async componentDidUpdate(prevProps, prevState) {
    const { props } = this;
    const propParams = props.match && props.match.params;
    const prevPropParams = prevProps.match && prevProps.match.params;
    if (propParams && prevPropParams) {
      if (propParams.dispatchJobObjectId !== prevPropParams.dispatchJobObjectId) {
        // Update when job changes with the subroute
        this.refreshState(propParams.subroute);
      } else if (prevState.searchIsOpen) {
        this.setState({ searchIsOpen: false });
      }
    }
  }

  // search for dispatch outdated toast element and remove it before component unmounts
  componentWillUnmount() {
    const dispatchOutdatedToast = document.querySelectorAll('.document-outdated-toast')?.[0];
    if (dispatchOutdatedToast) {
      dispatchOutdatedToast.remove();
    }
  }

  async toggleLoadingState(state) {
    return await this.setState({ ...state, isLoading: !state.isLoading, searchIsOpen: false });
  }

  // Refreshing the job after updating it, like changing isInvoiceOutdated to true, allows the invoice prompt to display
  async refreshJob() {
    const { match } = this.props;
    const dispatchJobObjectId = match.params.dispatchJobObjectId;
    const dispatchJob = await getObjectById('DispatchJob', dispatchJobObjectId, [
      'customerDispatchOrganization',
      'shipperDispatchOrganization',
      'consigneeDispatchOrganization',
      'customerDispatchOrganization.address',
      'shipperDispatchOrganization.address',
      'consigneeDispatchOrganization.address',
      'brokerDispatchOrganization.address',
      'brokerDispatchOrganization',
      'carrierDispatchOrganization',
      'carrierDispatchOrganization.address',
      'defaultVehicle'
    ]);

    this.setState({ ...this.state, dispatchJob })
  }

  async refreshState(subroute = DispatchSubroute.JOB_SUMMARY) {
    const { props, state } = this;
    const { match } = props;
    const dispatchJobObjectId = match.params.dispatchJobObjectId;
    await this.toggleLoadingState(state);
    const dispatchJob = await getObjectById('DispatchJob', dispatchJobObjectId, [
      'customerDispatchOrganization',
      'shipperDispatchOrganization',
      'consigneeDispatchOrganization',
      'customerDispatchOrganization.address',
      'shipperDispatchOrganization.address',
      'consigneeDispatchOrganization.address',
      'brokerDispatchOrganization.address',
      'brokerDispatchOrganization',
      'carrierDispatchOrganization',
      'carrierDispatchOrganization.address',
      'defaultVehicle'
    ]);

    if (!dispatchJob) { // job does not exist
      return history.push('/404');
    } else if (getAttribute(dispatchJob, 'isHidden')) {
      return history.push('/dispatch'); // job was "removed"
    }

    if (subroute === DispatchSubroute.ACCOUNTING){
      return history.push({pathname:'/dispatch', search: '?view=invoices', batchId: getAttribute(dispatchJob, 'batchId')});
    }

    const subroutes = Object.keys(DispatchSubroute).map(key => DispatchSubroute[key]);
    // visiting a subroute that does not exist
    if (subroutes.indexOf(subroute) === -1) {
      return history.push('/404');
    }

    // all is good - proceed
    document.title = `Job ${getAttribute(dispatchJob, 'batchId')} Details - Switchboard`;
    const defaultMassUnit = (getAttribute(dispatchJob, 'defaultMassUnit') !== undefined) ? MassUnit[getAttribute(dispatchJob, 'defaultMassUnit')] : MassUnit[1];
    const defaultLengthUnit = (getAttribute(dispatchJob, 'defaultLengthUnit') !== undefined) ? LengthUnit[getAttribute(dispatchJob, 'defaultLengthUnit')] : LengthUnit[1];
    // await this.taskReminder(dispatchJob, dispatchJobObjectId);
    const newState = { ...this.state, dispatchJob, dispatchJobAttributeChanges: {}, showFooterPromptBar: false, defaultMassUnit, defaultLengthUnit };

    // because of the functional JobTimeline and CommentSystem component structure, we load that info here
    // otherwise, components shoud just be passed in the DispatchJob as a prop and handle its own logic
    if (subroute === DispatchSubroute.JOB_HISTORY) {
      newState.history = await getHistory(dispatchJobObjectId, undefined, undefined, [], undefined, true);
    }

    if (subroute === DispatchSubroute.JOB_COMMENTS) {
      const commentsInfo = await getComments(dispatchJobObjectId, undefined, undefined, [], undefined, true);
      newState.comments = commentsInfo.dispatchComments;
    }

    if (subroute === DispatchSubroute.JOB_FREIGHT || subroute === DispatchSubroute.JOB_ROUTES || subroute === DispatchSubroute.JOB_SUMMARY) {
      let dispatchItems;
      let routes = [];
      const itemsPerRouteHash = {};
      if (dispatchJobObjectId) {
        dispatchItems = await getDispatchItems(dispatchJobObjectId);
        const dispatchTransferRecords = await Promise.all(dispatchItems.map(dispatchItem => {
          return getDispatchItemTransfers(getAttribute(dispatchItem, 'objectId'));
        }));

        dispatchTransferRecords.map(dispatchTransferRecord => {
          routes = routes.concat(dispatchTransferRecord.dispatchTransfers);
        });

        routes.map(routeRecord => {
          const pickupActionId = getAttribute(getAttribute(getAttribute(routeRecord, 'pickupDispatchAction'), 'address'), 'address');
          const dropoffActionId = getAttribute(getAttribute(getAttribute(routeRecord, 'dropoffDispatchAction'), 'address'), 'address');
          const dispatchItem = getAttribute(routeRecord, 'dispatchItem');
          const routesId = pickupActionId + dropoffActionId;
          if (itemsPerRouteHash[routesId]) {
            itemsPerRouteHash[routesId].push({ dispatchItem, routeRecord });
          } else {
            itemsPerRouteHash[routesId] = [{ dispatchItem, routeRecord }];
          }
        });
      }
      newState.itemsPerRouteHash = itemsPerRouteHash;
      newState.routes = routes;
    }
    return await this.toggleLoadingState(newState);
  }

  async updateJob() {
    await this.toggleLoadingState(this.state);
    const { state } = this;
    await DispatchJob.updateJob(getAttribute(state.dispatchJob, 'objectId'), state.dispatchJobAttributeChanges);
    await this.toggleLoadingState(this.state);
    await this.refreshState();
  }

  async removeJob(dispatchJobObjectId) {
    await this.toggleLoadingState(this.state);
    const removedJob = await DispatchJob.removeJob(dispatchJobObjectId);
    await this.refreshState();
    return removedJob;
    // add history in the background after confirming job was removed
    // const currentUser = getCurrentUser();
    // const currentUserFullName = formatName(`${getAttribute(currentUser, 'firstName')} ${getAttribute(currentUser, 'lastName')}`);

    // const history = new DispatchHistory(
    //   undefined,
    //   `${currentUserFullName} deleted Job ${getAttribute(removedJob, 'batchId')}`,
    //   new Date(),
    //   0,
    //   'DispatchJob',
    //   dispatchJobObjectId,
    //   removedJob
    // );
    // addHistory(history);

    // return history.push('/home');
  }

  async handleCommentSubmit(commentString) {
    const comment = compressWhitespace(commentString);
    if (!comment) return;
    const dispatchComment = new Comment(undefined, new Date(), getCurrentUser(), comment);
    await addComment(getAttribute(this.state.dispatchJob, 'objectId'), dispatchComment);
    return await this.refreshState(DispatchSubroute.JOB_COMMENTS);
  }

  async updateDispatchJobAttributeChanges(attributeChanges) {
    // combine all changes made to the DispatchJob from the child components
    // compare the new attribute changes to the previous one, if they're the same, dont show the prompt bar because there were "no" changes
    const dispatchJobAttributeChanges = { ...this.state.dispatchJobAttributeChanges, ...attributeChanges };
    const prevChanges = JSON.stringify(this.state.dispatchJobAttributeChanges);
    const newChanges = JSON.stringify(dispatchJobAttributeChanges);
    if (prevChanges === newChanges) return this.state;
    await this.setState({
      ...this.state,
      dispatchJobAttributeChanges,
    });
    return await this.updateJob();
    // return await this.refreshState();
    // return await this.setState({
    //   ...this.state,
    //   dispatchJobAttributeChanges,
    //   showFooterPromptBar: true,
    // });
  }

  async handleStatusChange(statusObject) {
    const dispatchJob = this.state.dispatchJob;
    await updateRecord(dispatchJob, { status: statusObject.value }, true);
    const newState = { ...this.state, selectedStatus: statusObject.label };
    return await this.setState(newState);
  }

  async setDispatchDocumentRefreshToken(dispatchDocumentRefreshToken) {
    this.setState({ ...this.state, dispatchDocumentRefreshToken })
  }

  // Used to set generalInformationState between true and false whenever a field in General Information updates
  // Right now only calls when job ID and customer contact fields are updated, other fields are not used in document
  // toggleGeneralInformationState(generalInformationState) {
  //   this.setState({ generalInformationState: generalInformationState });
  // }

  // Document outdated toast shows when isDocumentOudated is true
  setIsDocumentOutdatedPrompt(isDocumentOutdated) {
    this.setState({ ...this.state, isDocumentOutdated: isDocumentOutdated });
  }

  // set dispatchDocumentTypesToSave to an array of documentTypes
  // ex. [documentTypes.INVOICE.type, documentTypes.CUSTOMER_BILL_OF_LADING.type, documentTypes.LOAD_CONFIRMATION.type]
  setDispatchDocumentTypesToSave(dispatchDocumentTypesToSave) {
    this.setState({ ...this.state, dispatchDocumentTypesToSave: dispatchDocumentTypesToSave })
  }

  render() {
    const { props, state } = this;
    const dispatchJob = state.dispatchJob;
    let dispatchJobStatus = StatusTypes.INITIATED.status;
    if (dispatchJob) {
      dispatchJobStatus = getAttribute(dispatchJob, 'status', true);
    }
    const currentSubroute = props.match.params.subroute;

    // console.log(currentSubroute);

    /**
    * SIDEBAR NAVIGATION
    */
    let sidebarCurrentView = state.views.ORDERS_LIST;
    const locationPathname = props.location.pathname;
    if (locationPathname && locationPathname.split('/')[2]) { // this part shouldnt matter, but kept cause making quick changes
      // ex. '/dispatch/accounting' -> 'accounting'
      sidebarCurrentView = locationPathname.split('/')[2];
    }

    const sidebarNavItems = [
      {
        name: 'Orders List',
        isActive: !sidebarCurrentView || (sidebarCurrentView === state.views.ORDERS_LIST),
        to: `/dispatch/${state.views.ORDERS_LIST}`,
      },
      {
        name: 'Dispatch',
        isActive: !sidebarCurrentView || (sidebarCurrentView === state.views.DISPATCH),
        to: `/dispatch/${state.views.DISPATCH}`,
      },
      // {
      //   name: 'Organizations',
      //   isActive: !sidebarCurrentView || (sidebarCurrentView === state.views.ORGANIZATIONS),
      //   to: `?view=${state.views.ORGANIZATIONS}`,
      // },
      // {
      //   name: 'Drivers',
      //   isActive: (sidebarCurrentView === state.views.DRIVERS_LIST),
      //   to: `?view=${state.views.DRIVERS_LIST}`,
      // },
      {
        name: 'Accounting',
        isActive: (sidebarCurrentView === state.views.ACCOUNTING),
        to: `/dispatch/${state.views.ACCOUNTING}`,
      },
      // {
      //   name: 'Company Settings',
      //   isActive: (sidebarCurrentView === state.views.COMPANY_SETTINGS),
      //   to: `?view=${state.views.COMPANY_SETTINGS}`,
      // },
    ];

    /**
     *  NAVIGATION
     */
    // getting the path of the route up to/before the subroute
    const pathSplit = props.location.pathname.split('/');
    const mainPath = pathSplit.slice(0, 4).join('/'); // ex. /dispatch/job/objectId

    const isDefaultRoute = !currentSubroute || (currentSubroute === DispatchSubroute.JOB_SUMMARY);

    const navItemsPrimary = [
      {
        name: 'Summary',
        isActive: isDefaultRoute,
        to: `${mainPath}/${DispatchSubroute.JOB_SUMMARY}`,
        onClick: () => this.refreshState(DispatchSubroute.JOB_SUMMARY),
      },
      {
        name: 'Reference/Notes',
        isActive: currentSubroute === DispatchSubroute.JOB_REF_NOTES,
        to: `${mainPath}/${DispatchSubroute.JOB_REF_NOTES}`,
        onClick: () => this.refreshState(DispatchSubroute.JOB_REF_NOTES),
      },
      // May have to phase these out later
      // {
      //   name: 'Add/View Freight',
      //   isActive: currentSubroute === DispatchSubroute.JOB_FREIGHT,
      //   to: `${mainPath}/${DispatchSubroute.JOB_FREIGHT}`,
      //   onClick: () => this.refreshState(DispatchSubroute.JOB_FREIGHT),
      // },
      // {
      //   name: 'Routes/Assign',
      //   isActive: currentSubroute === DispatchSubroute.JOB_ASSIGN,
      //   to: `${mainPath}/${DispatchSubroute.JOB_ASSIGN}`,
      //   onClick: () => this.refreshState(DispatchSubroute.JOB_ASSIGN),
      // },
      {
        name: 'Documents',
        isActive: currentSubroute === DispatchSubroute.JOB_DOCUMENTS,
        to: `${mainPath}/${DispatchSubroute.JOB_DOCUMENTS}`,
        onClick: () => this.refreshState(DispatchSubroute.JOB_DOCUMENTS),
      },
      {
        name: 'Generate Docs',
        isDropdown: true,
        dropdownItems: getAttribute(dispatchJob, 'invoiceCustomerPricing', true)
        //if invoice pricing exists then show accounting, else hide
        ?
        [
          {
            name: 'Bill of Lading',
            to: `${mainPath}/${DispatchSubroute.JOB_BOL}`,
            onClick: () => this.refreshState(DispatchSubroute.JOB_BOL),
          },
          {
            name: 'Load Confirmation',
            to: `${mainPath}/${DispatchSubroute.JOB_RATE_CONFIRMATION}`,
            onClick: () => this.refreshState(DispatchSubroute.JOB_RATE_CONFIRMATION),
          },
          {
            name: 'Invoice',
            to: `${mainPath}/${DispatchSubroute.JOB_INVOICE}`,
            onClick: () => this.refreshState(DispatchSubroute.JOB_INVOICE),
          },
          {
            name: 'Accounting',
            to: `${mainPath}/${DispatchSubroute.ACCOUNTING}`,
            onClick: () => this.refreshState(DispatchSubroute.ACCOUNTING),
          }
        ]
        :
        [
          {
            name: 'Bill of Lading',
            to: `${mainPath}/${DispatchSubroute.JOB_BOL}`,
            onClick: () => this.refreshState(DispatchSubroute.JOB_BOL),
          },
          {
            name: 'Load Confirmation',
            to: `${mainPath}/${DispatchSubroute.JOB_RATE_CONFIRMATION}`,
            onClick: () => this.refreshState(DispatchSubroute.JOB_RATE_CONFIRMATION),
          },
          {
            name: 'Invoice',
            to: `${mainPath}/${DispatchSubroute.JOB_INVOICE}`,
            onClick: () => this.refreshState(DispatchSubroute.JOB_INVOICE),
          },
        ]
      },
      {
        name: 'Event Log',
        isActive: currentSubroute === DispatchSubroute.JOB_HISTORY,
        to: `${mainPath}/${DispatchSubroute.JOB_HISTORY}`,
        onClick: () => this.refreshState(DispatchSubroute.JOB_HISTORY),
      },
      // {
      //   name: 'Event Log',
      //   isDropdown: true,
      //   dropdownItems: [
      //     {
      //       name: 'Job History',
      //       isActive: currentSubroute === DispatchSubroute.JOB_HISTORY,
      //       to: `${mainPath}/${DispatchSubroute.JOB_HISTORY}`,
      //       onClick: () => this.refreshState(DispatchSubroute.JOB_HISTORY),
      //     },
      //     {
      //       name: 'Job Notes',
      //       isActive: currentSubroute === DispatchSubroute.JOB_COMMENTS,
      //       to: `${mainPath}/${DispatchSubroute.JOB_COMMENTS}`,
      //       onClick: () => this.refreshState(DispatchSubroute.JOB_COMMENTS),
      //     }
      //     // {
      //     //   name: 'Tagged User(s)',
      //     //   isActive: currentSubroute === DispatchSubroute.JOB_ASSIGN_USERS,
      //     //   to: `${mainPath}/${DispatchSubroute.JOB_ASSIGN_USERS}`,
      //     //   onClick: () => this.refreshState(DispatchSubroute.JOB_ASSIGN_USERS),
      //     // },
      //   ]
      // }
    ];

    // selectable document items
    const documentTypeSelectableItems = Object.keys(DocumentTypes).sort().map(key => {
      const documentType = DocumentTypes[key];
      return {
        key: documentType.type,
        label: documentType.description,
        value: documentType.type,
      }
    });

    /**
     * RETURN
     */

    const selectedStatusItems = state.selectableStatusItems.filter(item => (item.value === getAttribute(dispatchJob, 'status', true)));

    return (
      <DispatchJobLayoutProvider
        value={{
          refreshJob: async () => this.refreshJob(),
          dispatchDocumentRefreshToken: this.state.dispatchDocumentRefreshToken,
          resetDispatchDocumentRefreshToken: () => this.setState({ ...this.state, dispatchDocumentRefreshToken: null }),
          // isDocumentOutdated used to show document outdated toast
          isDocumentOutdated: this.state.isDocumentOutdated,
          setIsDocumentOutdatedPrompt: (isDocumentOutdated) => this.setIsDocumentOutdatedPrompt(isDocumentOutdated),
          // dispatchDocumentTypesToSave - used to indicate which document type should have its document state saved
          dispatchDocumentTypesToSave: this.state.dispatchDocumentTypesToSave,
          setDispatchDocumentTypesToSave: (dispatchDocumentTypesToSave) => this.setDispatchDocumentTypesToSave(dispatchDocumentTypesToSave),
          // setIsSaveDocumentState: (isSaveDocumentState) => this.setIsSaveDocumentState(isSaveDocumentState),
        }}
      >
      <div className="flex dispatch-job-layout">
        <div className="flex">
          {/* {state.isLoading &&
            <LoadingOverlay />
          } */}
          {/* <div >
              <SBSelect
              containerClassName="mt-2 d-inline-block align-middle"
              className="sbselect-job-status"
              items={state.selectableStatusItems}
              selectedItems={state.selectedStatusItems}
              getSelectedItems={(selectedItems) => this.handleStatusChange(selectedItems[0])}
              defaultToggleText="Job Status"
            />
          </div> */}
          <Sidebar
            navTitle="Dispatch"
            navItems={sidebarNavItems}
            openSearch={() => this.setState({ searchIsOpen: true })}
          />
        </div>
        <div className="flex flex-column flex-grow-1">
          {/* {
            this.state.dispatchJob && getAttribute(this.state.dispatchJob, 'isInvoiceOutdated') && (
              <DocumentOutdatedPrompt
                documentTypeName={Document.INVOICE.description}
                setDispatchDocumentRefreshToken={(dispatchDocumentRefreshToken) => this.setDispatchDocumentRefreshToken(dispatchDocumentRefreshToken)}
                dispatchJob={this.state.dispatchJob}
                documentTypeInt={Document.INVOICE.type}
              />
            )
          } */}
          { this.state.isDocumentOutdated && (
            <DocumentOutdatedToast
              dispatchJob={this.state.dispatchJob}
            />
          )}

          {/* <DispatchJobGeneralInformation
            dispatchJob={state.dispatchJob}
            toggleGeneralInformationState={() => this.toggleGeneralInformationState(!this.state.generalInformationState)}
          /> */}

          {/* <Divider className="mb-3" /> */}

          {/* <div className="sb-title">
            {dispatchJob ? `Job ${getAttribute(dispatchJob, 'batchId')}` : 'Job Loading'}
          </div>
          <div>
            <SBSelect
              containerClassName="mt-2 d-inline-block align-middle"
              className="sbselect-job-status"
              items={state.selectableStatusItems}
              selectedItems={selectedStatusItems}
              getSelectedItems={(selectedItems) => this.handleStatusChange(selectedItems[0])}
              defaultToggleText="Job Status"
            />
          </div>
          <JobGeneralInformationCard
            dispatchJob={state.dispatchJob}
            handleChange={this.updateDispatchJobAttributeChanges}
            refreshState={this.refreshState}
            clickEmailDocs={() => this.setState({ ...state, showEmailModal: true })}
            clickDelete={() => this.setState({ ...state, showConfirmModal: true })}
            clickRefresh={() => this.refreshState(currentSubroute)}
          />
          <div className="align-middle">
            {dispatchJob &&
              <div className="text-right">
                <SBSelect
                  containerClassName="align-middle mr-2 d-inline-block"
                  defaultToggleText="Email Document(s)"
                  dropdownToggleText="Email Document(s)"
                  items={documentTypeSelectableItems}
                  selectedItems={[]}
                  getSelectedItems={(items) => this.setState({ ...state, selectedDocumentTypes: [items[0].value], showEmailModal: true })}
                  showFilter
                />

                <MDBBtn onClick={() => this.setState({ ...state, showEmailModal: true })} color="primary" size="sm">Email Doc(s)</MDBBtn>
                <MDBBtn onClick={() => this.setState({ ...state, showConfirmModal: true })} color="danger" size="sm">Delete Job</MDBBtn>
                <MDBBtn onClick={() => this.refreshState(currentSubroute)} color="secondary" size="sm">Refresh Info</MDBBtn>
              </div>
            }
          </div>
          <JobStatusBar containerClassName="mb-3 w-100" status={dispatchJobStatus} />
          <div>
            {state.dispatchJob && <TaskReminder dispatchJob={state.dispatchJob}  refreshSate = {(subroute)=>{this.refreshState(subroute)}}/>}
          </div> */}

          {/* <div className="flex flex-column" style={{ paddingLeft: '1em' }}>
            <SubNavigationBar containerClassName="mb-1" navItems={navItemsPrimary} />
          </div> */}

          {/* <JobStatusBar containerClassName="mb-3 w-100" status={dispatchJobStatus} />
          <div>
          {state.dispatchJob && <TaskReminder dispatchJob={state.dispatchJob}  refreshSate = {(subroute)=>{this.refreshState(subroute)}}/>}
          </div> */}
          {isDefaultRoute && state.dispatchJob && (
            <>
              {/* { state.dispatchJob && <DispatchUserAssignCard  dispatchJobObjectId = {getAttribute(state.dispatchJob, 'objectId', true)}/> } */}
              {/* <DispatchShipmentContainer
                dispatchJobObjectId = {getAttribute(state.dispatchJob, 'objectId', true)}
                dispatchJob={state.dispatchJob}
              /> */}

              <DispatchOrdersSummary
                isLoading={state.isLoading}
                dispatchJob={state.dispatchJob}
                location={props.location}
                generalInformationState={state.generalInformationState}
              />
              {/* NEW SHIPMENT DETAIL TAB SHOULD GO HERE */}
            </>
          )}
          {/* {isDefaultRoute && (
            <>
              { state.dispatchJob && <DispatchUserAssignCard  dispatchJobObjectId = {getAttribute(state.dispatchJob, 'objectId', true)}/> }
            </>
          )} */}
          {/* {currentSubroute === DispatchSubroute.JOB_REF_NOTES && (
            <>
              <DispatchReferenceNotesView
                title="Reference/Notes"
                dispatchJobObjectId={props.match.params.dispatchJobObjectId}
                dispatchJob={state.dispatchJob}
                location={props.location}
                refreshState={async () => { await this.refreshState(currentSubroute); }}
              />
            </>
          )} */}
          {currentSubroute === DispatchSubroute.JOB_FREIGHT && (
            <>
              <div />
            </>
          )}
          {currentSubroute === DispatchSubroute.JOB_ASSIGN && (
            <div />
          )}
          {currentSubroute === DispatchSubroute.JOB_HISTORY && (
            <>
              {dispatchJob && (
                <div />
              )}
            </>
          )}
          {currentSubroute === DispatchSubroute.JOB_COMMENTS && (
            <>
              {dispatchJob && (
                <CommentSystem
                  className="mt-3"
                  handleCommentSubmit={this.handleCommentSubmit}
                  currentUser={getCurrentUser()}
                  comments={state.comments}
                  commentContainerSize={8}
                />
              )}
            </>
          )}
          {currentSubroute === DispatchSubroute.JOB_INVOICE && (
            <>
              <DispatchDocumentsLayout
                dispatchJobObjectId={props.match.params.dispatchJobObjectId}
                dispatchDocumentTypeInt={DocumentTypes.INVOICE.type}
              />
            </>
          )}
          {currentSubroute === DispatchSubroute.JOB_RATE_CONFIRMATION && (
            <>
              <DispatchDocumentsLayout
                dispatchJobObjectId={props.match.params.dispatchJobObjectId}
                dispatchDocumentTypeInt={DocumentTypes.LOAD_CONFIRMATION.type}
              />
            </>
          )}
          {currentSubroute === DispatchSubroute.JOB_BOL && (
            <>
              {/* @todo missing DocumentTypes.BOL enum, use 1 to temporarily represent BOL */}
              <DispatchDocumentsLayout
                dispatchJobObjectId={props.match.params.dispatchJobObjectId}
                dispatchDocumentTypeInt={1}
              />
            </>
          )}
          {currentSubroute === DispatchSubroute.JOB_DOCUMENTS && (
            <>
              {state.dispatchJob && (
                <div />
              )}
            </>
          )}
          {state.showFooterPromptBar && (
            <FooterPromptBar
              message="Would you like to save your changes?"
              onConfirm={() => this.updateJob()}
              onCancel={() => this.setState({ ...state, showFooterPromptBar: false })}
              adjustForSupportButton
            />
          )}
          <DispatchConfirmModal
            show={state.showConfirmModal}
            confirmText={"Are you sure you'd like to delete this job?"}
            handleClose={() => { this.setState({ ...state, showConfirmModal: false }) }}
            handleSubmit={() => this.removeJob(getAttribute(dispatchJob, 'objectId'))}
            refreshState={this.props.refreshState}
          />
        </div>
      </div>
      </DispatchJobLayoutProvider>
    );
  }
}

export default DispatchJobLayout;
