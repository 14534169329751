import React, { useState, useEffect } from 'react';
import history from 'sbHistory';

// api
import { getDriverGroupDrivers, addDriverToDriverGroup, removeDriverFromDriverGroup } from 'api/Driver/DriverGroup';

// sbCore
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import Button from 'sbCore/Button/Button';
import DriverAutocompleteInput from 'sbCore/DriverAutocomplete/DriverAutocompleteInput';

// sb-csapi
import { getAttribute, getCurrentUserSessionToken, getRecordByObjectId } from 'sb-csapi/dist/AAPI';
import { formatName } from 'sb-csapi/dist/utils/String';
import { Action } from 'sb-csapi/dist/enums/Action';

// styling
import './style.scss';

/**
 * @description Renders driverGroup drivers in a table
 *
 * @param {int} type - Action enum
 * @param {driverGroup} driverGroup - An object which contains driver group info
 * @param {bool} isSaving - Indicates whether the modal is saving
 * @param {function} setIsSaving - sets isSaving state of the modal
 *
 * @example
 * <DriverGroupDriverTable
 *  type={Action.ADD}
 *  driverGroup={{ groupName, obejctId, createdBy, numberOfDrivers, objectId }}
 *  isSaving={isSaving}
 *  setIsSaving={(bool) => setIsSaving(bool)}
 * />
 */
function DriverGroupDriverTable({ ...props }) {
  const [drivers, setDrivers] = useState([]); // True state from the db
  const [driversPreview, setDriversPreview] = useState([]); // Temporary state including pending changes for the drivers

  const [pendingDriversToAdd, setPendingDriversToAdd] = useState([]);
  const [pendingDriversToRemove, setPendingDriversToRemove] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [showAddDriverDropdown, setShowAddDriverDropdown] = useState(false);

  //* ------- Helper Functions ------- */

  /**
   * @description Parses driver records into an object for use with the datatable
   *
   * @param {Driver} driver - Driver record
   * @param {Int} status - Action enum
   *
   * @returns {Object} Returns the parsed driver object
   */
  function parseDriver(driver, status) {
    const name = formatName(getAttribute(driver, 'user_fullName'));
    const company = getAttribute(getAttribute(driver, 'belongsToCompany'), 'name');
    const recentVehicle = getAttribute(getAttribute(driver, 'vehicle', true), 'unitId', true);
    const phoneNumber = getAttribute(driver, 'user_phoneNumber');
    const objectId = getAttribute(driver, 'objectId');

    return {
      name,
      company,
      recentVehicle,
      phoneNumber,
      objectId,
      status, // Action enum, should only be either Action.ADD or Action.DELETE
    };
  }

  /**
   * @description Given an array of parsed drivers, sort by ascending driver name
   *
   * @param {Array<Object>} drivers - Array of parsed drivers
   *
   * @returns Sorted array of parsed drivers
   */
  function sortDriversByName(drivers) {
    return drivers.sort((driverA, driverB) => driverA.name.toLowerCase().localeCompare(driverB.name.toLowerCase()));
  }

  /**
   * @description Updates the driver group table whenever there's new pending changes
   */
  function updateDriverGroupTablePreview() {
    let _driversPreview = [...drivers, ...pendingDriversToAdd];

    // Get the object ids to remove
    const pendingDriversToRemoveObjectIdArr = pendingDriversToRemove.map((driver) => driver.objectId);

    // Run through the driver preview, and update the status for the drivers to remove
    _driversPreview = _driversPreview.map((driver) => {
      if (pendingDriversToRemoveObjectIdArr.includes(driver.objectId)) {
        return {
          ...driver,
          status: Action.DELETE,
        };
      }
      return driver;
    });

    const sortedDrivers = sortDriversByName(_driversPreview);

    setDriversPreview(sortedDrivers);
  }

  // Determines classname for each datatable row
  const determineRowClassNames = (data) => ({
    'pending-driver-add': data.status === Action.ADD,
    'pending-driver-remove': data.status === Action.DELETE,
  });

  //* ------- useEffect ------- */

  // initial fetch for drivers when this component is rendered
  useEffect(() => {
    let didCancel = false;

    async function fetchDriverGroupDrivers() {
      setIsLoading(true);

      const _driverGroupDrivers = await getDriverGroupDrivers(props.driverGroup.objectId);

      const parsedDrivers = _driverGroupDrivers.map(driverGroupDriver => {
        const _driver = getAttribute(driverGroupDriver, 'driver');
        return parseDriver(_driver);
      });

      const sortedDrivers = sortDriversByName(parsedDrivers);

      if (!didCancel) {
        setDrivers(sortedDrivers);
        setDriversPreview(sortedDrivers);
        setIsLoading(false);
      }
    }

    if (props.type !== Action.ADD) fetchDriverGroupDrivers();

    return () => { didCancel = true; };
  }, [])

  // Handles driver saving for driver group
  useEffect(() => {
    async function handleSaveDriverGroupDrivers() {
      // If new driver list exists, save drivers
      if (props.isSaving && pendingDriversToAdd.length > 0) {
        await Promise.all(pendingDriversToAdd.map(async newDriver => {
          const _newDriver = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'Driver', newDriver.objectId);
          await addDriverToDriverGroup(props.driverGroup.objectId, _newDriver);
        }));
      }

      // If delete driver list exists, delete drivers
      if (props.isSaving && pendingDriversToRemove.length > 0) {
        await Promise.all(pendingDriversToRemove.map(async deleteDriver => {
          const _deleteDriver = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'Driver', deleteDriver.objectId);
          await removeDriverFromDriverGroup(props.driverGroup.objectId, _deleteDriver);
        }));
      }

      props.setIsSaving(false);
    }

    handleSaveDriverGroupDrivers();

  }, [props.isSaving])

  useEffect(() => {
    updateDriverGroupTablePreview();
  }, [pendingDriversToAdd, pendingDriversToRemove])

  //* ----------- Templates ----------- */

  // Template for the delete button in driver table
  function deleteButtonTemplate(rowData) {
    // If the driver is pending add, don't show the action button
    if (rowData.status === Action.ADD) return <div />;

    // else show either the delete or undo button
    return (
      <div className="flex justify-content-center align-items-center">
        {rowData.status !== Action.DELETE && (
          <Button
            icon={<span className="material-icons">delete</span>}
            tooltip={`Remove ${rowData.name} from group`}
            className="p-button-danger p-button-text p-button-rounded driver-table-action-btn"
            onClick={() => {
              setPendingDriversToRemove([...pendingDriversToRemove, rowData]);
            }}
          />
        )}
        {rowData.status === Action.DELETE && (
          <Button
            icon={<span className="material-icons">undo</span>}
            tooltip={`Undo removal of ${rowData.name} from group`}
            className="p-button-secondary p-button-text p-button-rounded driver-table-action-btn"
            onClick={() => {
              const _pendingDriversToRemove = pendingDriversToRemove.filter((driver) => driver.objectId !== rowData.objectId);
              setPendingDriversToRemove(_pendingDriversToRemove);
            }}
          />
        )}
      </div>
    );
  }

  // Allows the name in the datatable to be clicked on and also redirects to the driver page
  function driverTableNameTemplate(rowData) {
    return (
      <div 
        onClick={(e) => {
          e.preventDefault();
          history.push({ pathname: 'driver', search: 'driver=' + rowData.objectId })
        }}
        className="driver-table-name"
      >
        {rowData.name}
      </div>
    );
  }

  const driverGroupDriverTable = (
    <DataTable
      value={driversPreview}
      responsiveLayout="scroll"
      dataKey="id"
      emptyMessage="No drivers"
      loading={isLoading}
      scrollable
      scrollHeight="20rem"
      rowClassName={determineRowClassNames}
      className="driver-datatable"
    >
      <Column
        field="name"
        header="Name"
        body={(rowData) => driverTableNameTemplate(rowData)}
      />
      <Column
        field="company"
        header="Company"
        body={(rowData) => rowData.company}
      />
      <Column
        field="recentVehicle"
        header="Vehicle"
        body={(rowData) => rowData.recentVehicle}
      />
      <Column
        field="phoneNumber"
        header="Phone Number"
        body={(rowData) => rowData.phoneNumber}
      />
      <Column
        body={(rowData) => deleteButtonTemplate(rowData)}
        exportable={false}
      />
    </DataTable>
  );

  return (
    <div className="driver-group-driver-table">
      <div className="flex column-gap-3 mt-4 mb-3">
        <div className="flex flex-initila text-lg font-semibold align-items-center">Drivers</div>
        <Button
          icon={<span className="material-icons mr-1">add</span>}
          label="Add Driver to Group"
          sbVariant="slim"
          className="p-button-info"
          onClick={() => setShowAddDriverDropdown(!showAddDriverDropdown)}
          disabled={showAddDriverDropdown}
        />
        <div className="flex-grow-1">
          <hr />
        </div>
      </div>

      {showAddDriverDropdown && (
        <div className="driver-autocomplete flex w-full mb-3 pl-1">
          <DriverAutocompleteInput
            multiple
            className="flex-grow-1 mr-1"
            placeholder="Search for Drivers"
            hideSelectedDrivers
            onSelectDrivers={(drivers) => {
              const _pendingDriversToAdd = drivers.map((driver) => parseDriver(driver, Action.ADD));
              setPendingDriversToAdd(_pendingDriversToAdd);
            }}
            excludedDriverObjectIds={drivers.map((driver) => driver.objectId)}
          />
          <Button
            className="p-button-icon p-button-text p-button-danger"
            icon={<span className="material-icons">close</span>}
            tooltip="Cancel"
            onClick={() => setShowAddDriverDropdown(false)}
          />
        </div>
      )}

      {driverGroupDriverTable}
    </div>
  );
}

export default DriverGroupDriverTable;
