import Parse from 'parse';
import moment from 'moment-timezone';
import store from '../store';
import * as ELD from './ELD';
import * as Getters from './Getters';
import * as Helpers from './Helpers';
import * as Setters from './Setters';
import * as File from './File';
import * as MB from './Mapbox';

import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

import { setQueryRestriction, setParseObject } from 'api/Parse';
import * as FCConvert from 'api/IFTA/FuelCardConversionFunctions';
import { StateProvinces } from 'api/Lists/StateProvinces';

import { addAlert } from 'actions/SBAlert/SBAlert';
import AlertTypes from 'enums/SBAlert';
import { getCurrentUser, getAttribute } from './Parse';

const getIFTAMonthInterval = (iftaMonth, getForQuarter, iftaYear = (new Date()).getFullYear()) => {
  let month = iftaMonth + 1;
  if (month < 10) month = `0${month}`;

  const startDate = moment(`${iftaYear}-${month}-01 00:00`);
  let endDate = moment(startDate).endOf('month');
  if (getForQuarter) {
    endDate = moment(startDate);
    endDate.month(endDate.month() + 2);
    endDate = moment(endDate).endOf('month');
  }

  return [new Date(startDate.toDate()), new Date(endDate.toDate())];
};

const associateEventsWithVehicles = (eldEvents) => {
  // given eld events, match them with respective vehicle
  const vehicles = {}; // keep track of which drivers were seen
  for (let i = 0, eldEventsLen = eldEvents.length; i < eldEventsLen; i++) {
    const event = eldEvents[i];
    const eldVehicle = event.get('vehicle');
    if (eldVehicle) {
      if (!vehicles[eldVehicle.id]) vehicles[eldVehicle.id] = { vehicle: eldVehicle, eldEvents: [] };
      vehicles[eldVehicle.id].eldEvents.push(eldEvents[i]);
    }
  }
  return vehicles;
};

const categorizeEventsByCountry = (eventsWithLocations) => {
  const promise = new Promise(resolve => {
    const countryData = {};
    for (let i = 0, eventsWithLocationsLen = eventsWithLocations.length; i < eventsWithLocationsLen; i++) {
      const event = eventsWithLocations[i];
      const vehicleLocation = event.get('vehicleLocation');
      const countryCode = vehicleLocation.get('countryCode');
      if (!countryCode) continue;
      if (!countryData[countryCode]) countryData[countryCode] = { eldEvents: [], country: countryCode };
      countryData[countryCode].eldEvents.push(event);
    }
    resolve(countryData);
  });

  return promise;
};

const updatePreviousTripObject = (isDifferentStateProvince, previousDataObj, isTotalEventsLen, eldEvent, vehicle, distanceUnit) => {
  // **** DEPRECATED ****
  // helper for categorizeCountryDataByStateProvince
  // update the last trip object if the stateprovince has changed or we reached the end of the array
  //   if (previousDataObj && (isDifferentStateProvince || isTotalEventsLen)) {
  //     const odometerReadings = ELD.getOdometerReadingsFromEvents(vehicle, previousDataObj.eldEvents, distanceUnit, true, true);
  //     const odometerValue = odometerReadings[1] - odometerReadings[0];
  // // if (eldEvent.get('vehicleLocation').get('stateProvince') === 'bc') {
  // //   console.log(eldEvent, previousDataObj.eldEvents, odometerValue);
  // // }
  //     previousDataObj.intervalEnd = new Date(previousDataObj.eldEvents[previousDataObj.eldEvents.length - 1].get('eventDateTime'));
  //     previousDataObj.totalMiles = odometerValue;
  //
  //     if (isDifferentStateProvince) {
  //       // if it was a shift in stateprovince, then use the event that shift's its, eventdatetime
  //       previousDataObj.intervalEnd = new Date(eldEvent.get('eventDateTime'));
  //       let nextTripStartOdometer = eldEvent.get('totalVehicleKm') || 0;
  //       nextTripStartOdometer = Helpers.convertDistance(nextTripStartOdometer, 'km', 'mi');
  //
  //       if (odometerValue === 0) {
  //         // if the odometer values difference is 0, then see if either [0] or [1] are not 0
  //         // if all else fails, set to value 0
  //         if (odometerReadings[0] > 0) {
  //           previousDataObj.totalMiles = nextTripStartOdometer - odometerReadings[0];
  //         } else if (odometerReadings[1] > 0) {
  //           previousDataObj.totalMiles = nextTripStartOdometer - odometerReadings[1];
  //         } else {
  //           previousDataObj.totalMiles = 0;
  //         }
  //       }
  //     }
  //
  //     previousDataObj.totalMiles = parseFloat(previousDataObj.totalMiles.toFixed(2));
  //     previousDataObj.distanceUnit = 'mi';
  //   }
};

const categorizeCountryDataByStateProvince = (vehicle, countryData, distanceUnit, iftaInterval) => {
  // *** DEPRECATED ***
  const promise = new Promise(resolve => {
    const seenData = {}; // keep track of whats been seen that we dont want to store in the return object
    const _countryData = { ...countryData }; // this is what we want to return at the end
    const countryCodes = Object.keys(_countryData);

    if (countryCodes.length === 0) {
      resolve({});
    } else {
      const _distanceUnit = distanceUnit || Getters.getPDispatcherPropertyFromState('distanceUnit');
      let idIncrementor = -1;

      let previousDataObj;
      let eldEventsDateSorted = [];
      for (let i = 0; i < countryCodes.length; i++) {
        const countryCode = countryCodes[i];
        eldEventsDateSorted = eldEventsDateSorted.concat(countryData[countryCode].eldEvents);
        delete _countryData[countryCode].eldEvents;
      }

      eldEventsDateSorted.sort((eldEventA, eldEventB) => eldEventA.get('eventDateTime').getTime() - eldEventB.get('eventDateTime').getTime());
      const totalEventsLen = eldEventsDateSorted.length;

      let lastSeenStateProvince;
      let dataId;

      for (let j = 0; j < eldEventsDateSorted.length; j++) {
        const eldEvent = eldEventsDateSorted[j];
        const vehicleLocation = eldEvent.get('vehicleLocation');
        const countryCode = vehicleLocation.get('countryCode');
        const stateProvince = vehicleLocation.get('stateProvince');
        if (!stateProvince) continue; // should always be a state province but we do this anyway to future proof

        // since we are currently iterating through this specific country's eld events, create jurisdiction data
        // of each trip of each province from start to finish
        const isDifferentStateProvince = lastSeenStateProvince !== stateProvince;
        if (isDifferentStateProvince) {
          updatePreviousTripObject(isDifferentStateProvince, previousDataObj, j === totalEventsLen - 1, eldEvent, vehicle, distanceUnit);
          if (_countryData[countryCode][dataId] && _countryData[countryCode][dataId].totalMiles < 10) {
            console.log(previousDataObj.totalMiles);
            delete _countryData[countryCode][dataId];
          }
          // if we find that the stateprovince has changed, then create a new trip object and update the last one
          const eventDateTime = eldEvent.get('eventDateTime');
          // now start a new trip
          idIncrementor++;
          dataId = `${((new Date()).getTime())}+${countryCode}+${idIncrementor}`;
          _countryData[countryCode][dataId] = {
            stateProvinceAbbrv: stateProvince,
            intervalStart: new Date(eventDateTime),
            intervalEnd: new Date('April 20 2069'),
            eldEvents: [eldEvent],
            totalMiles: 0,
            totalTPKMiles: 0,
            totalQuantity: 0,
          };
          lastSeenStateProvince = stateProvince;
          previousDataObj = _countryData[countryCode][dataId];
        } else {
          // this event belongs to the current trip
          _countryData[countryCode][dataId].eldEvents.push(eldEvent);
          updatePreviousTripObject(isDifferentStateProvince, previousDataObj, j === totalEventsLen - 1, eldEvent, vehicle, distanceUnit);
        }
      }
      resolve(_countryData);
    }
  });
  return promise;
};

// const getEventsWithValidReversedLocations = (eldEvents) =>
//   // we know for sure these events will have vehicle locations and a vehicle
//    eldEvents.filter(eldEvent => {
//      const vehicleLocation = eldEvent.get('vehicleLocation');
//      return vehicleLocation.get('countryCode') && vehicleLocation.get('stateProvince');
//    });

const getEventsFromVehicle = (vehicleUnitId, dateInterval) => {
  // function to return all events with valid locations from a vehicle
  // console.log('Getting events for vehicle on date:', dateInterval);
  const promise = new Promise(resolve => {
    const company = Parse.User.current().get('belongsToCompany');
    const queryIncludes = [
      'eldEvent', 'eldEvent.driver', 'eldEvent.vehicleLocation', 'driver.user', 'vehicle', 'vehicle.licensePlate', 'vehicle.vehicleLocation', 'eldDailyCertification',
    ];

    const vehicleQuery = new Parse.Query('Vehicle');
    vehicleQuery.equalTo('belongsToCompany', company);
    vehicleQuery.equalTo('unitId', vehicleUnitId);
    vehicleQuery.equalTo('enabled', true);

    const vehicleLocationQuery = new Parse.Query('VehicleLocation');
    vehicleLocationQuery.matchesQuery('vehicle', vehicleQuery);
    vehicleLocationQuery.equalTo('belongsToCompany', company);
    vehicleLocationQuery.exists('eldEvent');
    vehicleLocationQuery.exists('vehicle');
    vehicleLocationQuery.exists('stateProvince');
    vehicleLocationQuery.exists('countryCode');
    vehicleLocationQuery.notEqualTo('countryCode', 'gh'); // ghana is (0,0) ... ignore those
    vehicleLocationQuery.greaterThanOrEqualTo('dateTime', new Date(dateInterval[0]));
    vehicleLocationQuery.lessThanOrEqualTo('dateTime', new Date(dateInterval[1].getTime() + 0.01));
    vehicleLocationQuery.ascending('dateTime');
    vehicleLocationQuery.include(queryIncludes);

    Getters.getAllFromQuery(vehicleLocationQuery).then(vehicleLocations => {
      // const allEvents = vehicleLocations.map(vehicleLocation => vehicleLocation.get('eldEvent'));
      // console.log(allEvents);
      const locationsWithEvents = vehicleLocations.filter(vehicleLocation => vehicleLocation.get('eldEvent'));
      const events = locationsWithEvents.map(locationsWithEvents => locationsWithEvents.get('eldEvent'));
      resolve(events);
    });
  });
  return promise;
};

const getIFTAEventsFromVehicle = (vehicleUnitId, dateInterval) => {
  // get all IFTA eligible locations -> events from a vehicle given the interval
  const promise = new Promise(resolve => {
    const company = Parse.User.current().get('belongsToCompany');
    const queryIncludes = [
      'eldEvent', 'eldEvent.driver', 'eldEvent.vehicleLocation', 'driver.user', 'vehicle', 'vehicle.licensePlate', 'vehicle.vehicleLocation', 'eldDailyCertification',
    ];

    const vehicleQuery = new Parse.Query('Vehicle');
    vehicleQuery.equalTo('belongsToCompany', company);
    vehicleQuery.equalTo('unitId', vehicleUnitId);

    const vehicleLocationQuery = new Parse.Query('VehicleLocation');
    vehicleLocationQuery.matchesQuery('vehicle', vehicleQuery);
    vehicleLocationQuery.equalTo('belongsToCompany', company);
    vehicleLocationQuery.exists('eldEvent');
    vehicleLocationQuery.exists('vehicle');
    vehicleLocationQuery.exists('stateProvince');
    vehicleLocationQuery.exists('countryCode');
    vehicleLocationQuery.notEqualTo('countryCode', 'gh'); // ghana is (0,0) ... ignore those

    vehicleLocationQuery.equalTo('changedStateProvince', true); // set this to true after testing

    vehicleLocationQuery.greaterThanOrEqualTo('dateTime', new Date(dateInterval[0]));
    vehicleLocationQuery.lessThanOrEqualTo('dateTime', new Date(dateInterval[1]));
    vehicleLocationQuery.ascending('dateTime');
    vehicleLocationQuery.include(queryIncludes);

    // also get the first and last event that occurs on or after the start of the date interval as it may not have the changedStateProvince flag
    const firstVehicleLocationQuery = new Parse.Query('VehicleLocation');
    firstVehicleLocationQuery.matchesQuery('vehicle', vehicleQuery);
    firstVehicleLocationQuery.equalTo('belongsToCompany', company);
    firstVehicleLocationQuery.exists('eldEvent');
    firstVehicleLocationQuery.exists('vehicle');
    firstVehicleLocationQuery.exists('stateProvince');
    firstVehicleLocationQuery.exists('countryCode');
    firstVehicleLocationQuery.notEqualTo('countryCode', 'gh');
    firstVehicleLocationQuery.greaterThanOrEqualTo('dateTime', new Date(dateInterval[0]));
    firstVehicleLocationQuery.lessThanOrEqualTo('dateTime', new Date(dateInterval[1]));
    firstVehicleLocationQuery.ascending('dateTime');
    firstVehicleLocationQuery.include(queryIncludes);

    const lastVehicleLocationQuery = new Parse.Query('VehicleLocation');
    lastVehicleLocationQuery.matchesQuery('vehicle', vehicleQuery);
    lastVehicleLocationQuery.equalTo('belongsToCompany', company);
    lastVehicleLocationQuery.exists('eldEvent');
    lastVehicleLocationQuery.exists('vehicle');
    lastVehicleLocationQuery.exists('stateProvince');
    lastVehicleLocationQuery.exists('countryCode');
    lastVehicleLocationQuery.notEqualTo('countryCode', 'gh');
    lastVehicleLocationQuery.greaterThanOrEqualTo('dateTime', new Date(dateInterval[0]));
    lastVehicleLocationQuery.lessThanOrEqualTo('dateTime', new Date(dateInterval[1]));
    lastVehicleLocationQuery.descending('dateTime');
    lastVehicleLocationQuery.include(queryIncludes);
    // PDu jQl
    Promise.all([firstVehicleLocationQuery.first(), lastVehicleLocationQuery.first(), Getters.getAllFromQuery(vehicleLocationQuery)]).then(
      vehicleLocationResults => {
        let combinedVehicleLocations = [];
        const firstVehicleLocation = vehicleLocationResults[0];
        const lastVehicleLocation = vehicleLocationResults[1];
        const vehicleLocations = vehicleLocationResults[2];
        if (firstVehicleLocation && vehicleLocations[0] && firstVehicleLocation.id === vehicleLocations[0].id) {
          // the first vehicle location and start location of the get all query are the same, then just return vehicleLocations
          combinedVehicleLocations = vehicleLocations;
        } else if (firstVehicleLocation) {
          // otherwise, if firstlocation exists, append firstVehicleLocation to the start of vehicleLocations
          combinedVehicleLocations = [].concat([firstVehicleLocation], vehicleLocations);
        } else {
          // no first vehicle location
          combinedVehicleLocations = vehicleLocations;
        }

        // do the same as above, but for the last location
        const lastCombinedLocation = combinedVehicleLocations[combinedVehicleLocations.length - 1];
        if (lastVehicleLocation && lastCombinedLocation && lastVehicleLocation.id === lastCombinedLocation.id) {
          // do nothing
        } else if (lastVehicleLocation) {
          combinedVehicleLocations = [].concat(combinedVehicleLocations, [lastVehicleLocation]);
        }

        // now extract the events from all these legit locations that are active and re-sort ascending for robustness
        const eldEvents = combinedVehicleLocations.map(vehicleLocation => vehicleLocation.get('eldEvent'));
        const eldEventsByStatusSorted = eldEvents.filter(eldEvent => eldEvent.get('eldEventRecordStatusInt') === 1);
        eldEventsByStatusSorted.sort((eventA, eventB) => eventA.get('eventDateTime').getTime() - eventB.get('eventDateTime').getTime());
        // console.log('vehiclelocation results: ', eldEventsByStatusSorted);
        resolve(eldEventsByStatusSorted);
      },
      error => {
        console.log('Error could not retrieve events');
        resolve([]);
      }
    );
  });
  return promise;
};

const getVehicleDistances = (vehicle, startDate, endDate, distanceUnit) => {
  /*
   * given a vehicle, get all the jurisdictions they travelled over and the mileage corresponding with it over the interval of events given
   * begin by separating the drivers events by country, then by state/province whilst keeping the order of events for correct readings
   * we split processing here instead of sending a huge payload of events
   */

  const promise = new Promise((resolve, reject) => {
    const filter = [
      { name: 'vehicleUnitId', queryType: 'equalTo', value: vehicle.get('unitId') },
      { name: 'belongsToCompany', queryType: 'equalTo', value: vehicle.get('belongsToCompany') },
      { name: 'exitDate', queryType: 'greaterThanOrEqualTo', value: startDate },
      { name: 'exitDate', queryType: 'lessThan', value: endDate },
      { name: 'exitDate', queryType: 'exists' },
    ];

    Getters.queryCompanyObjects('IFTAVehicleStateProvinceMileage', undefined, undefined, filter, { enterDate: 'ascending' }, ['enterVehicleLocation', 'exitVehicleLocation', 'vehicle', 'vehicle.licensePlate'], false, false).then((iftaVehicleStateProvinceMileageArr) => {
      resolve({ vehicle, startDate, endDate, distanceUnit, iftaVehicleStateProvinceMileageArr });
    });
  });
  return promise;
};

const getVehicleMileages = (vehicleArr, startDate, endDate, distanceUnit, intervalType = 'Month') => {
  // note that we do not sort jurisdictiondata by intervalstart here. only for ui purposes atm
  // they are however, sorted by stateProvince visited for each country individually, but not sorted in the order visited by stateProvince by country
  // as are their eldevents
  const promise = new Promise((resolve, reject) => {
    const promises = [];
    for (let i = 0; i < vehicleArr.length; i++) {
      promises.push(getVehicleDistances(vehicleArr[i], startDate, endDate, distanceUnit));
    }
    Promise.all(promises).then((vehicleData) => {
      const vehicleMilegeDataArr = [];
      for (let i = 0; i < vehicleData.length; i++) {
        vehicleMilegeDataArr.push({
          vehicle: vehicleData[i].vehicle,
          unitId: vehicleData[i].vehicle.get('unitId').toString(), // the reason why we include this is to easily access the unitId when cross-referencing fc data
          iftaVehicleStateProvinceMileage: vehicleData[i].iftaVehicleStateProvinceMileageArr,
          intervalType,
          intervalStart: new Date(vehicleData[i].startDate),
          intervalEnd: new Date(vehicleData[i].endDate),
        });
      }
      resolve(vehicleMilegeDataArr);
    });
  });
  return promise;
};

const convertFuelCardDataToJSONArray = (fuelCardDataArray) => {
  // helper function
  // set i to 1 since the first row contains the fuel card categories
  const dataArray = [].concat(fuelCardDataArray);
  const jsonArray = [];

  for (let i = 1, arrayLen = dataArray.length; i < arrayLen; i++) {
    const stringData = dataArray[i];
    const splitData = stringData.split(',');
    const jsonData = {
      cardNo: splitData[0],
      transactionDate: splitData[1],
      transactionTime: splitData[2],
      invoice: splitData[3],
      unitId: splitData[4],
      driverName: splitData[5],
      odometer: splitData[6],
      locationName: splitData[7],
      city: splitData[8],
      stateProvinceAbbrv: splitData[9],
      fees: splitData[10],
      item: splitData[11],
      unitPrice: splitData[12],
      quantity: splitData[13],
      cost: splitData[14],
      db: splitData[15],
      currencyUnit: splitData[16],
    };
    jsonArray.push(jsonData);
  }

  return jsonArray;
};
// split data up more for loading time
// 2 decimal places
// negative values => 0
const getFuelCardDataByUnitId = (fuelCardJSONArray) => {
  // helper function
  // given fuel card json array group by state province
  const fcArray = [].concat(fuelCardJSONArray);
  const fcJSON = {};
  const seenIds = {};
  let idIncrementor = 0;

  for (let i = 0, fcArrayLen = fcArray.length; i < fcArrayLen; i++) {
    const fcData = fcArray[i];
    let unitId = fcData.unitId;
    const stateProvinceAbbrv = fcData.stateProvinceAbbrv;

    if (unitId && stateProvinceAbbrv) {
      unitId = unitId.toString();

      if (!fcJSON[unitId]) fcJSON[unitId] = { unitId, jurisdictionData: {} }; // instantiate if vehicle not seen before

      const stateProvinceAbbrvLowered = stateProvinceAbbrv.toLowerCase();

      const jurisdictionDataKeys = Object.keys(fcJSON[unitId].jurisdictionData);

      let jurisdictionExists = false; // find out if our stateprovince exists for this vehicle unit jurisdiction data
      let dataId = `${(new Date()).getTime()}+${idIncrementor}`;

      for (let j = 0; j < jurisdictionDataKeys.length; j++) {
        const jurisdictionDataKey = jurisdictionDataKeys[j]; // ca/bc/etc
        const jurisdictionData = fcJSON[unitId].jurisdictionData[jurisdictionDataKey];
        if (jurisdictionData && (jurisdictionData.stateProvinceAbbrv === stateProvinceAbbrvLowered)) {
          jurisdictionExists = true;
          dataId = jurisdictionDataKey;
          break;
        }
      }

      if (!jurisdictionExists) {
        seenIds[dataId] = true;
        idIncrementor++;
        fcJSON[unitId].jurisdictionData[dataId] = {
          // if we have not seen this province befo, instantiate it
          stateProvinceAbbrv: stateProvinceAbbrvLowered,
          totalCost: 0,
          totalQuantity: 0,
          transactions: [],
        };
      }

      if (fcData.cost) {
        fcJSON[unitId].jurisdictionData[dataId].totalCost += parseFloat(fcData.cost);
        const totalCost = fcJSON[unitId].jurisdictionData[dataId].totalCost;
        fcJSON[unitId].jurisdictionData[dataId].totalCost = parseFloat(totalCost.toFixed(2));
      }
      if (fcData.quantity) {
        fcJSON[unitId].jurisdictionData[dataId].totalQuantity += parseFloat(fcData.quantity);
        const totalQuantity = fcJSON[unitId].jurisdictionData[dataId].totalQuantity;
        fcJSON[unitId].jurisdictionData[dataId].totalQuantity = parseFloat(totalQuantity.toFixed(2));
      }
      if (fcData.transactionDate) {
        fcJSON[unitId].jurisdictionData[dataId].transactions.push({
          transactionDate: fcData.transactionDate,
          transactionTime: fcData.transactionTime,
          cost: fcData.cost ? parseFloat(parseFloat(fcData.cost).toFixed(2)) : undefined,
          fuelQuantity: fcData.quantity ? parseFloat(parseFloat(fcData.quantity).toFixed(2)) : undefined,
          fuelItem: fcData.item,
          fuelCardLocationName: fcData.locationName,
          fuelCardLocationCity: fcData.city,
          miles: 0,
          tpkMiles: 0,
          id: (new Date()).getTime() + Math.random(),
        });
      }

      fcJSON[unitId].jurisdictionData[dataId].intervalStart = new Date();
      fcJSON[unitId].jurisdictionData[dataId].intervalEnd = new Date();

      const firstTransaction = fcJSON[unitId].jurisdictionData[dataId].transactions[0];
      const lastTransaction = fcJSON[unitId].jurisdictionData[dataId].transactions[fcJSON[unitId].jurisdictionData[dataId].transactions.length - 1];

      if (firstTransaction) {
        fcJSON[unitId].jurisdictionData[dataId].intervalStart = new Date(firstTransaction.transactionDate);
      }
      if (lastTransaction) {
        fcJSON[unitId].jurisdictionData[dataId].intervalEnd = new Date(lastTransaction.transactionDate);
      }
    }
  }

  return fcJSON;
};

const getIFTAFuelCardInfo = (fuelCardFile) => {
  const promise = new Promise((resolve, reject) => {
    File.readFileAsText(fuelCardFile).then(fuelCardData => {
      // now that we've gotten the string data from the fuel card file, break it up line-by-line
      const fuelCardDataArray = fuelCardData.split('\n');

      const header = fuelCardDataArray[0];
      const transactions = FCConvert.formatCSV(header, fuelCardDataArray);

      resolve({ header, transactions });
    });
  });

  return promise;
};

const getInfoFromFuelCard = (fuelCardFile, fuelCardType) => {
  /*
    Read and parse file given and output universal fuel card format
    fuelCardTypes:
      - BVD (Petroleum) -> bvd
      - ESSO (CND) -> esso
      - TCH (CND) -> tchcnd
      - TCH (USD) -> tchusd
      - TCH 2 (USD) -> tch2
      - Flying J / EFS -> tchusd
      - Husky Comdata -> huskycomdata
      - Nationwide -> nationwide
  */

  const promise = new Promise((resolve, reject) => {
    File.readFileAsText(fuelCardFile).then(fuelCardData => {
      // now that we've gotten the string data from the fuel card file, break it up line-by-line
      const fuelCardDataArray = fuelCardData.split('\n');
      let transactions = [];

      /*
        - All fuel cards will be processed to output the same core information. the only difference is how its processed (according to card type)
        Fields: cardNo, transactionDateTime, unitId, odometer, locationName, city, stateProvinceAbbrv, item/items (if more than 1 fuel type),
                unitPrice, fuelQuantity, total, currency, fuelMeasurementUnit
      */
      const convertFunctionMap = {
        bvd: FCConvert.formatFromBVD,
        esso: FCConvert.formatFromESSO,
        huskycomdata: FCConvert.formatFromHuskyComdata,
        nationwide: FCConvert.formatFromNationwide,
        tchcnd: FCConvert.formatFromTCH,
        tchusd: FCConvert.formatFromTCH,
        tch2: FCConvert.formatFromTCH2,
        fcl: FCConvert.formatFromFCL,
      };

      const header = fuelCardDataArray[0];
      transactions = convertFunctionMap[fuelCardType](fuelCardDataArray);

      /*
        now that we have a standardized output, we note there are a few variabilities in every fuel card:
        1. The currencies arent a constant (can switch between CAD and USD in a single card)
        2. Same applies to fuel unit of measurement (both Liters and Gallons possible in a single card)
        3. Same applies for fuel types. additionally a single transaction can have multiple fuel types (one for tractor, one for reefer)
        4. Odometer readings arent reliable. Some cards have them, some dont. The ones that do, dont always log it for each transaction

        At the moment, the best we can do is to standardize at least the unit of measurement to be all gallons
      */

      // group each transaction by unit id, standardize measurements where applicable, and sort by transactionDateTime
      const transactionsByVehicleUnit = {};
      transactions.map(transaction => {
        const unitId = transaction.unitId.toLowerCase();
        if (transaction.fuelMeasurementUnit === 'l') {
          const fuelQuantityGallons = (transaction.fuelQuantity * 0.264172).toFixed(2);
          transaction.fuelQuantity = parseFloat(fuelQuantityGallons);
          transaction.fuelMeasurementUnit = 'g';
        }

        if (!transactionsByVehicleUnit[unitId]) {
          transactionsByVehicleUnit[unitId] = {
            transactions: [transaction],
          };
        } else {
          transactionsByVehicleUnit[unitId].transactions.push(transaction);
        }
      });

      // sort transactions for each unit by transactionDateTime ascending
      Object.keys(transactionsByVehicleUnit).map(unitId => {
        const transactions = transactionsByVehicleUnit[unitId].transactions;
        transactionsByVehicleUnit[unitId].transactions.sort((transactionA, transactionB) => {
          const transactionADateTime = !isNaN(transactionA.transactionDateTime) ? transactionA.transactionDateTime : new Date('Jan 01 1911 00:00');
          const transactionBDateTime = !isNaN(transactionB.transactionDateTime) ? transactionB.transactionDateTime : new Date('Jan 01 1911 00:00');
          return transactionADateTime.getTime() - transactionBDateTime.getTime();
        });
      });

      resolve({ header, transactionsByVehicleUnit });
    });
  });
  return promise;
};

const getStateProvinceSequenceData = (vehicleUnitId, dateInterval, stateProvince) => {
  // given a vehicle unit id and date interval, figure out the events of each time spent in
  // stateProvince from the start of the trip to the end of the trip
  // ex. if we want for BC... bc -> sea -> sf -> sea === [bc.start, bc.mid, bc.end] -> [sea.start, sea.mid, sea.end] -> ...etc
  // return only bc
  const promise = new Promise(resolve => {
    console.log(vehicleUnitId, dateInterval, stateProvince);
    getEventsFromVehicle(vehicleUnitId, dateInterval).then(
      events => {
        // const vancouver = [-123.1207, 49.2827];
        // const sanfrancisco = [-122.4194, 37.77490];
        // console.log('*****', dateInterval);
        // console.log(events);
        const filteredEvents = events.filter(event => event.get('vehicleLocation') && event.get('vehicleLocation').get('stateProvince'));

        const filteredEventsLen = filteredEvents.length;
        // console.log(filteredEvents);
        if (filteredEventsLen.length === 0) return resolve({});

        let stateProvinceQueue = []; // holds the event sequences of stateProvince until change of stateprovince/end of array
        const results = []; // holds all the sequences in total [[lng: x, lat: y], [], ...]

        let eventQueue = []; // same as above except holds the whole eld event instead
        const eventResults = [];

        for (let i = 0; i < filteredEventsLen; i++) {
          // [bc, bc, ca, ca, ca, ca, bc, bc]
          const event = filteredEvents[i];
          const vehicleLocation = event.get('vehicleLocation');
          const eventStateProvince = vehicleLocation.get('stateProvince');
          const isSameStateProvince = eventStateProvince === stateProvince;
          // console.log(eventStateProvince);
          if (isSameStateProvince || (!isSameStateProvince && i === filteredEventsLen - 1)) {
            // if the same state province or stateprovince has just changed
            const location = vehicleLocation.get('location');
            stateProvinceQueue.push([location._longitude, location._latitude]);
            eventQueue.push(event);
          }
          // else if (stateProvinceQueue.length > 0) {
          //   results.push([].concat(stateProvinceQueue));
          //   stateProvinceQueue = [];
          //
          //   eventResults.push([].concat(eventQueue));
          //   eventQueue = [];
          // }

          if ((i === filteredEventsLen - 1) && stateProvinceQueue.length > 0) {
            results.push([].concat(stateProvinceQueue));
            stateProvinceQueue = [];

            eventResults.push([].concat(eventQueue));
            eventQueue = [];
          }
        }

        // now we have results which is an array that contains array sequences of events spent in stateProvince
        // from this we can iterate each 'trip' sequence to get the distances travelled each time and total them
        const distanceInfoPromises = [];
        for (let i = 0; i < results.length; i++) {
          const result = results[i];
          distanceInfoPromises.push(MB.getRoadRoute(result, true));
        }

        Promise.all(distanceInfoPromises).then(
          mbDirectionObjects => {
            const distanceResults = mbDirectionObjects.map((directions, index) => ({ eldEvents: eventResults[index], directions, id: (new Date()).getTime() + index }));
            resolve(distanceResults);
          }
        );
      }
    );
  });
  return promise;
};

const deleteParseIFTAReportEntries = () => {
  const promise = new Promise(resolve => {
    const currentUser = Parse.User.current();
    const iftaFuelMileageDataQuery = new Parse.Query('IFTAFuelMileageData');
    iftaFuelMileageDataQuery.equalTo('belongsToCompany', currentUser.get('belongsToCompany'));
    iftaFuelMileageDataQuery.equalTo('savedBy', currentUser);
    iftaFuelMileageDataQuery.find().then(
      dataEntries => {
        const destroyPromises = [];
        for (let i = 0; i < dataEntries.length; i++) {
          destroyPromises.push(dataEntries[i].destroy());
        }

        Promise.all(destroyPromises).then(
          () => {
            resolve(true);
          },
          () => {
            resolve(false);
          }
        );
      }
    );
  });
  return promise;
};

const getReportStyles = () => {
  // styles for printable ifta reports
  const styles = `
    .vehicle-report {
      overflow: hidden;
    }

    .vehicle-report input {
      border: 0 !important;
    }

    .vehicle-report .switchboard-trademark-ifta-header div {
      clear: both;
      margin-bottom: 3em;
      display: inline-block;
      font-family: 'Tahoma', 'Georgia';
      font-size: 10px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: .2em;
    }

    .vehicle-report .switchboard-trademark-ifta-header div:nth-child(2) {
      float: right;
    }

    .vehicle-report .ifta-header {
      margin-bottom: 1em;
      color: #282828;
      font-family: 'Tahoma', 'Georgia';
      font-size: 18px;
      text-transform: uppercase;
    }

    .vehicle-report .ifta-state-container {
      margin-bottom: 3em;
    }

    .vehicle-report .state-province-abbrv {
      font-weight: 600 !important;
    }

    .vehicle-report .vehicle-totals {
      padding-top: 1em;
      padding-bottom: 2em !important;
      border-bottom: '1px solid #585858' !important;
      font-weight: 600 !important;
    }

    .vehicle-report .all-totals {
      font-weight: 600 !important;
    }

    .show-on-print {
      display: inline-block !important;
    }

    .hide-on-print {
      display: none !important;
    }

    .vehicle-report .oosVehicleHeader {
      color: #282828;
      font-size: 12px;
      font-family: 'Tahoma', 'Georgia';
    }

    .vehicle-report .oosReport th {
      padding: 0em !important;
      font-size: 10px !important;
    }

    .vehicle-report .oosReport td {
      padding: 0em !important;
      font-size: 10px !important;
    }
  `;
  return styles;
};

const getGeocode = (addressString, dataOnly) => {
  const promise = new Promise((resolve, reject) => {
    MB.getGeocode(addressString, dataOnly).then(
      result => resolve(result),
      error => reject(error)
    );
  });
  return promise;
};

const mapORTaxFormMapping = (stateProvinceTotals) => {
  Parse.Cloud.run('pdfReadFormValues', {
    pdfURL: 'http://www.oregon.gov/ODOT/Forms/Motcarr/9740.pdf',
    filename: `or-tax-${Parse.User.current().id}-${Math.random()}.pdf`,
  }).then(
    formValueMappings => {
      console.log(formValueMappings);
    }
  );

  // requires guestimating which rows correspond to which state/province. we first get
  // all states and provinces then sort them alphabetically
  const filteredStateProvinces = StateProvinces.filter(stateProvince => {
    const countryCode = stateProvince.country.toLowerCase();
    return countryCode === 'ca' || countryCode === 'us';
  });

  const filteredStateProvinceAbbrvs = filteredStateProvinces.map(stateProvince => stateProvince.code);

  // finally, sort alphabetically
  filteredStateProvinceAbbrvs.sort();

  console.log(filteredStateProvinceAbbrvs);

  const fieldValuePairs = {};

  for (let i = 0; i < filteredStateProvinceAbbrvs.length; i++) {
    const stateProvinceAbbrv = filteredStateProvinceAbbrvs[i];
    const stateProvinceDatum = stateProvinceTotals[stateProvinceAbbrv];
    fieldValuePairs[`TotMi.${i}`] = i;
    // fieldValuePairs[`C4.${i}`] = stateProvinceDatum.stateProvinceAbbrv.toUpperCase(); // jurisdiction
    // fieldValuePairs[`C5.${i}`] = 'D' // fuel type. D for diesel (default)
    // fieldValuePairs[`C6.${i}`] = parseFloat((stateProvinceDatum.totalDistance + stateProvinceDatum.totalTPKDistance).toFixed(2)); // total kms travelled
    // fieldValuePairs[`C7.${i}`] = stateProvinceDatum.totalDistance; // taxable kms travelled
    // fieldValuePairs[`C9.${i}`] = stateProvinceDatum.totalQuantity; // tax paid litres purchased
  }

  return fieldValuePairs;
};

const mapBCTaxFormMapping = (stateProvinceTotals, businessInformation) => {
  // take stateprovinceifta data and use them to fill out the pdf form
  const stateProvinceAbbrvs = Object.keys(stateProvinceTotals);
  const fieldValuePairs = {
    name: businessInformation.name || '[LEGAL NAME]',
    address1: businessInformation.address1 || '[MAILING ADDRESS]',
    iftanumber: businessInformation.iftanumber || 'BC300[#\'s]',
    media: businessInformation.media || '[MEDIA#]',
  };

  for (let i = 0; i < stateProvinceAbbrvs.length; i++) {
    // pattern goes C4.0, C5.0, C6.0 ...... next row: C4.1, C5.1, C6.1 ...... next row: C4.2, C5.2, C6.2, etc
    const stateProvinceAbbrv = stateProvinceAbbrvs[i];
    const stateProvinceDatum = stateProvinceTotals[stateProvinceAbbrv];
    fieldValuePairs[`C4.${i}`] = stateProvinceDatum.stateProvinceAbbrv.toUpperCase(); // jurisdiction
    fieldValuePairs[`C5.${i}`] = 'D'; // fuel type. D for diesel (default)
    fieldValuePairs[`C6.${i}`] = parseFloat((stateProvinceDatum.totalDistance + stateProvinceDatum.totalTPKDistance).toFixed(2)); // total kms travelled
    fieldValuePairs[`C7.${i}`] = stateProvinceDatum.totalDistance; // taxable kms travelled
    fieldValuePairs[`C9.${i}`] = stateProvinceDatum.totalQuantity; // tax paid litres purchased
  }
  return fieldValuePairs;
};

const pdfFillTaxReport = (stateProvinceAbbrv, fieldValuePairs) => {
  // take le fieldvaluepairs and use them to map into pdf
  const promise = new Promise((resolve, reject) => {
    let pdfURL;
    if (stateProvinceAbbrv === 'bc') {
      pdfURL = 'https://www2.gov.bc.ca/assets/gov/taxes/sales-taxes/forms/fin-360-international-fuel-tax-agreement-ifta-quarterly-tax-return.pdf';
    } else if (stateProvinceAbbrv === 'or') {
      pdfURL = 'http://www.oregon.gov/ODOT/Forms/Motcarr/9740.pdf';
    }

    Parse.Cloud.run('pdfFillForm', {
      pdfURL,
      filename: `${stateProvinceAbbrv}-tax-${Parse.User.current().id}-${Math.random()}.pdf`,
      fieldValuePairs,
    }).then(
      parseTempFile => {
        resolve(parseTempFile);
      }
    );
  });
  return promise;
};

const getIFTAVehicles = (dateStart, returnArr) => {
  const promise = new Promise((resolve, reject) => {
    const vehicleQuery = new Parse.Query('Vehicle');
    const company = Parse.User.current().get('belongsToCompany');
    vehicleQuery.equalTo('belongsToCompany', company);
    vehicleQuery.notEqualTo('isHidden', true);
    // vehicleQuery.equalTo('enabled', true);
    vehicleQuery.include(['licensePlate', 'vehicleLocation']);
    vehicleQuery.exists('unitId');
    vehicleQuery.limit(1000);
    vehicleQuery.descending('updatedAt');
    vehicleQuery.find().then(
      unsortedVehicles => {
        const vehicles = unsortedVehicles.sort((a, b) => {
          if (
            a.get('vehicleLocation') && 
            a.get('vehicleLocation').get('dateTime') && 
            b.get('vehicleLocation') && 
            b.get('vehicleLocation').get('dateTime')
          ) {
            return (b.get('vehicleLocation').get('dateTime') - a.get('vehicleLocation').get('dateTime'));
          } else if (a.get('vehicleLocation') && a.get('vehicleLocation').get('dateTime')) {
            return -1;
          } else if (b.get('vehicleLocation') && b.get('vehicleLocation').get('dateTime')) {
            return 1;
          }
          return 0;
        });
        const iftaVehicles = {};

        for (let i = 0; i < vehicles.length; i++) {
          const vehicle = vehicles[i];
          const unitId = vehicle.get('unitId');
          if (!iftaVehicles[unitId]) {
            iftaVehicles[unitId] = vehicle;
          }
        }
        if (returnArr) {
          if (Object.values(iftaVehicles).length === 0) return resolve(Object.values(iftaVehicles));
          resolve(Object.values(iftaVehicles));
        } else {
          if (Object.values(iftaVehicles).length === 0) return resolve(iftaVehicles);
          resolve(iftaVehicles);
        }
      },
      error => {
        if (returnArr) {
          resolve([]);
        } else {
          resolve({});
        }
        console.log(error);
      }
    );
  });
  return promise;
};

const getIFTAUnitIds = async (dateStart) => {
  const vehicleArr = (await Getters.queryCompanyObjects('Vehicle', undefined, undefined, [{ queryType: 'equalTo', name: 'enabled', value: true }], { unitId: 'descending' }, undefined, undefined, undefined, undefined, true));
  const uniqueUnitIds = [];
  for (let i = 0; i < vehicleArr.length; i++) {
    const vehicleObj = vehicleArr[i];
    if (uniqueUnitIds.indexOf(vehicleObj.get('unitId')) === -1 && vehicleObj.get('unitId')) {
      uniqueUnitIds.push(vehicleObj.get('unitId'));
    }
  }
  return uniqueUnitIds;
};

const vehicleSetFuelCardData = (vehicleJurisdictionDataArr, fuelCardJurisdictionDataArr) => {
  // given trip info from a vehicle and trip/jurisdiction info from fuel card, match them in-place
  // console.log(vehicleJurisdictionDataArr, '**', fuelCardJurisdictionDataArr);
  for (let i = 0; i < vehicleJurisdictionDataArr.length; i++) {
    const vehicleJurDatum = vehicleJurisdictionDataArr[i];
    const dateTimeInterval = [vehicleJurDatum.intervalStart.getTime(), vehicleJurDatum.intervalEnd.getTime()];

    for (let j = 0; j < fuelCardJurisdictionDataArr.length; j++) {
      const fcJurDatum = fuelCardJurisdictionDataArr[j];
      if (vehicleJurDatum.stateProvinceAbbrv === fcJurDatum.stateProvinceAbbrv) {
        const transactions = fcJurDatum.transactions;
        const transactionsInInterval = transactions.filter(transaction => {
          const transactionDate = new Date(`${transaction.transactionDate} ${transaction.transactionTime}`);
          const transactionDateMs = transactionDate.getTime();
          // console.log('***', [vehicleJurDatum.intervalStart, vehicleJurDatum.intervalEnd], transactionDate, [transactionDateMs, dateTimeInterval[0]]);

          // right now cannot add transaction if transaction date/time is exactly same as an interval start or end time due to javascripts date/time object
          // implementation (creating 2 date objects with the exact same date but different string format yields the same date/time, but different Milli's)
          return transactionDateMs >= dateTimeInterval[0] && transactionDateMs <= dateTimeInterval[1];
        });

        transactionsInInterval.map(transaction => {
          // update totals for vehicleJurDatum
          vehicleJurDatum.totalQuantity += parseFloat(transaction.fuelQuantity);
        });

        vehicleJurDatum.totalQuantity = parseFloat(vehicleJurDatum.totalQuantity.toFixed(2));
        break;
      }
    }
  }
};

const applyFuelCardToIFTAData = (iftaData, fuelCardData) => {
  // apply fuel data from transactions from fuel card data to ifta data
  // console.log(iftaData, fuelCardData);
  const newIFTAData = { ...iftaData };
  const vehicleDataThatHasMatch = {};

  // iterate through each vehicle iftaData. if vehicle exists in fuel card data then for each stateprovince
  // of the ifta datum, find a matching stateprovince fuel card datum. then apply transaction data to the ifta datum
  // if the transaction date falls into the ifta datum interval
  let vehicleUnitIds = Object.keys(newIFTAData);

  // filter out for the vehicles that have matching fuel card data
  vehicleUnitIds = vehicleUnitIds.filter(unitId => newIFTAData[unitId] && fuelCardData[unitId]);

  vehicleUnitIds.map(unitId => {
    vehicleDataThatHasMatch[unitId] = newIFTAData[unitId];
  });

  // now that we have matching vehicles, iterate through each one and apply transaction data to matching state provinces and interval dates
  const unitIdsThatMatch = Object.keys(vehicleDataThatHasMatch);
  for (let i = 0; i < unitIdsThatMatch.length; i++) {
    const unitId = unitIdsThatMatch[i];
    const vehicleDatum = { ...newIFTAData[unitId] };
    const matchingIFTADatum = fuelCardData[unitId];

    const jurisdictionData = { ...vehicleDatum.jurisdictionData };
    const jurisdictionDataKeys = Object.keys(jurisdictionData);

    const jurisdictionDataArr = jurisdictionDataKeys.map(key => ({
      ...jurisdictionData[key], eldEvents: [].concat(jurisdictionData[key].eldEvents), key,
    }));

    const fuelCardJurisdictionDataArr = Object.keys(matchingIFTADatum.jurisdictionData).map(key => matchingIFTADatum.jurisdictionData[key]);

    vehicleSetFuelCardData(jurisdictionDataArr, fuelCardJurisdictionDataArr);

    // now that we have updated array of jursidiction data, reassign everything under newIFTAData to return
    // newIFTAData -> vehicleDatum -> jurisdictionData
    jurisdictionDataArr.map(jurisdictionDatum => {
      jurisdictionData[jurisdictionDatum.key] = jurisdictionDatum;
    });

    vehicleDatum.jurisdictionData = jurisdictionData;
    newIFTAData[unitId] = vehicleDatum;
  }

  return newIFTAData;
};

const getIFTAVehicleDataEdits = (iftaInterval, unitId, savedBy, returnArr) => {
  const promise = new Promise((resolve, reject) => {
    // load all edits so that they can be applied to the generated data
    const belongsToCompany = Parse.User.current().get('belongsToCompany');
    const vehicleDataEditQuery = new Parse.Query('IFTAVehicleDataEdit');
    vehicleDataEditQuery.equalTo('belongsToCompany', belongsToCompany);
    vehicleDataEditQuery.greaterThanOrEqualTo('tripStart', iftaInterval[0]);
    vehicleDataEditQuery.lessThanOrEqualTo('tripStart', iftaInterval[1]);
    // vehicleDataEditQuery.lessThanOrEqualTo('tripEnd', iftaInterval[1]);
    if (unitId) {
      vehicleDataEditQuery.equalTo('vehicleUnitId', unitId);
    }
    if (savedBy) {
      vehicleDataEditQuery.equalTo('savedBy', savedBy);
    }

    vehicleDataEditQuery.include(['eldEvents']);

    Getters.getAllFromQuery(vehicleDataEditQuery).then(
      editsArr => {
        if (returnArr) {
          resolve(editsArr);
        } else {
          const edits = {};
          editsArr.map(edit => {
            const unitId = edit.get('vehicleUnitId');

            if (!edits[unitId]) {
              edits[unitId] = {
                intervalStart: edit.get('intervalStart'),
                intervalEnd: edit.get('intervalEnd'),
                jurisdictionData: {},
              };
            }

            edits[unitId].jurisdictionData[edit.get('jurisdictionDataId') || edit.id] = {
              unitId: edit.get('vehicleUnitId'),
              stateProvinceAbbrv: edit.get('stateProvinceAbbrv'),
              intervalStart: edit.get('tripStart'),
              intervalEnd: edit.get('tripEnd'),
              eldEvents: edit.get('eldEvents'),
              totalMiles: edit.get('totalMiles'),
              totalTPKMiles: edit.get('totalTPKMiles'),
              totalQuantity: edit.get('totalQuantity'),
              distanceUnit: edit.get('distanceUnit'),
              showRowsAsDeleted: edit.get('showRowsAsDeleted'),
            };
          });

          resolve(edits);
        }
      }
    );
  });
  return promise;
};


const integrateFuelCardToFuelMileageData = (fuelCardTransactionsByVehicle, vehicleMileagesDataArr) => {
  const promise = new Promise((resolve, reject) => {
    // handleIFTAVehicleDataUpdate = (vehicle, iftaVehicleStateProvinceMileageId, property, value, convertDistance)
    /*
      We want to iterate through all vehicles in the fuel card:
      - If the vehicle exists add the transaction in between the vehicleMileageData whose start/end dates
        correspond with the transaction date. If there is none, create a new vehicleMileageData for that transaction
      - If the vehicle does not exist, add it and add all transactions for it in the form of vehicleMileageData
    */
    const belongsToCompany = Parse.User.current().get('belongsToCompany');
    const fcUnitIds = Object.keys(fuelCardTransactionsByVehicle);
    const vehicleMileageUpdatePromises = []; // all the updates we want to do, to integrate fuel card data

    fcUnitIds.map(fcUnitId => {
      const unitId = fcUnitId.toLowerCase();
      let vehicleFuelCardTransactions = fuelCardTransactionsByVehicle[fcUnitId].transactions || [];
      const transactionsToCreate = {}; // keeps track of the transactions we need to create in the db

      // filter for vehicleMileagesDataArr objects with the same unitId
      let vehicleMileagesData = vehicleMileagesDataArr.filter(vehicleMileageDatum => vehicleMileageDatum.unitId && (vehicleMileageDatum.unitId.toLowerCase() === unitId))[0] || undefined;

      if (!vehicleMileagesData) {
        // this vehicle has no data at all. warn the user and alert them to add this vehicle and try again
      } else {
        // vehicle exists and has existing data so go through each of the vehicle's fuel card transactions
        // if it matches with the enter/exit date of a vehicleMileageDatum then apply it there. if it doesnt apply to any,
        // create a vehicleMileageDatum row for the transaction and vehicle

        const vehicle = vehicleMileagesData.vehicle;
        const intervalStartMs = vehicleMileagesData.intervalStart.getTime();
        const intervalEndMs = vehicleMileagesData.intervalEnd.getTime();
        vehicleMileagesData = vehicleMileagesData.iftaVehicleStateProvinceMileage || [];

        // filter vehicleFuelCardTransactions for only those in the specified ifta interval
        vehicleFuelCardTransactions = vehicleFuelCardTransactions.filter(transaction => {
          const transactionDateTimeMs = transaction.transactionDateTime.getTime();
          return (transactionDateTimeMs >= intervalStartMs) && (transactionDateTimeMs <= intervalEndMs);
        });

        // technically there should be an error preventing fuel card uploading if fuel card has bad data
        // but here we apply a filter to filter out bad transactions anyway for easy testing
        vehicleFuelCardTransactions = vehicleFuelCardTransactions.filter(transaction => !isNaN(transaction.transactionDateTime));

        let lastSeenStateProvince; // this is to be used when a transaction doesnt correspond to an existing vehiclemileagedata
        let lastSeenTransTimeSinceEpoch; // same as above. corresponds with above (the 2 are associated)

        // recall: vehicleMileagesData contains all mileage data filtered for this particular vehicle
        vehicleFuelCardTransactions.map(transaction => {
          // transactions for this particular vehicle. recall: transactions are sorted in order from transactionDateTime: ascending

          // now we begin checking for associated vehicleMileageDatum
          // check to see if this transaction already falls into a vehicleMileageDatum
          let transactionHasCorrespondingMileageDatum = false;
          const stateProvinceAbbrvLowered = transaction.stateProvinceAbbrv ? transaction.stateProvinceAbbrv.toLowerCase() : undefined;
          const transactionDateTime = moment(moment.utc(transaction.transactionDateTime));

          for (let i = 0; i < vehicleMileagesData.length; i++) {
            const vehicleMileageDatum = vehicleMileagesData[i];
            const enterDate = vehicleMileageDatum.get('enterDate') ? moment(vehicleMileageDatum.get('enterDate')) : undefined;
            const exitDate = vehicleMileageDatum.get('exitDate') ? moment(vehicleMileageDatum.get('exitDate')) : undefined;

            const isValidTimeInterval = enterDate && exitDate && (exitDate.diff(enterDate, 'ms') >= 0) && (enterDate.diff(transactionDateTime, 'ms') <= 0) && (exitDate.diff(transactionDateTime, 'ms') >= 0);

            let isSameStateProvinceAbbrv = false;
            if (vehicleMileageDatum.get('stateProvince')) {
              isSameStateProvinceAbbrv = vehicleMileageDatum.get('stateProvince').toLowerCase() === stateProvinceAbbrvLowered;
            }
            if (isSameStateProvinceAbbrv && isValidTimeInterval && !vehicleMileageDatum.get('disabled')) {
              transactionHasCorrespondingMileageDatum = true;

              const iftaFuelCardDatum = Helpers.createTempParseObject('IFTAFuelCardData', {
                ...transaction,
                iftaVehicleStateProvinceMileage: undefined,
              });
              iftaFuelCardDatum.setACL(Helpers.getCompanyReadWriteACL());

              // apply this transaction to this vehiclemileagedatum
              const updateVehicleMileageDatum = (vehicleMileageDatum) => {
                const promise = new Promise((resolve, reject) => {
                  const currentFuelGallons = vehicleMileageDatum.get('fuelGallons') || 0;
                  vehicleMileageDatum.set('fuelGallons', currentFuelGallons + transaction.fuelQuantity).save().then(
                    vehicleMileageDatum => {
                      resolve(vehicleMileageDatum);
                      iftaFuelCardDatum.set('iftaVehicleStateProvinceMileage', vehicleMileageDatum).save();
                    }
                  );
                });
                return promise;
              };
              vehicleMileageUpdatePromises.push(updateVehicleMileageDatum(vehicleMileageDatum));
              break;
            }
          }

          if (!transactionHasCorrespondingMileageDatum) {
            // still this transaction doesnt fit into any of the vehicles vehicleMileageDatum, so we need to make a new vehicleMileageDatum for it
            // stateProvince (lowercase), enterDate, exitDate, vehicle, belongsToCompany, distanceKm, fuelGallons

            if (stateProvinceAbbrvLowered && (stateProvinceAbbrvLowered === lastSeenStateProvince)) {
              // if this is consecutive stateprovince, add on to existing
              transactionsToCreate[stateProvinceAbbrvLowered][lastSeenTransTimeSinceEpoch].distanceKm += (transaction.distanceKm ? parseFloat(transaction.distanceKm) : 0);
              transactionsToCreate[stateProvinceAbbrvLowered][lastSeenTransTimeSinceEpoch].fuelGallons += (transaction.fuelQuantity ? parseFloat(transaction.fuelQuantity) : 0);
              transactionsToCreate[stateProvinceAbbrvLowered][lastSeenTransTimeSinceEpoch].exitDate = transactionDateTime.toDate();

              transactionsToCreate[stateProvinceAbbrvLowered][lastSeenTransTimeSinceEpoch].transactions.push(transaction);
            } else {
              const transTimeSinceEpoch = transactionDateTime.valueOf();
              if (!transactionsToCreate[stateProvinceAbbrvLowered]) transactionsToCreate[stateProvinceAbbrvLowered] = {};

              if (!transactionsToCreate[stateProvinceAbbrvLowered][transTimeSinceEpoch]) {
                transactionsToCreate[stateProvinceAbbrvLowered][transTimeSinceEpoch] = {
                  stateProvince: stateProvinceAbbrvLowered,
                  enterDate: transactionDateTime.toDate(),
                  exitDate: transactionDateTime.toDate(),
                  vehicle,
                  belongsToCompany,
                  distanceKm: 0,
                  tpkDistanceKm: 0,
                  fuelGallons: 0,
                  transactions: [], // will be removed later when creating objects from transactionsToCreate
                };
              }

              transactionsToCreate[stateProvinceAbbrvLowered][transTimeSinceEpoch].distanceKm += (transaction.distanceKm ? parseFloat(transaction.distanceKm) : 0);
              transactionsToCreate[stateProvinceAbbrvLowered][transTimeSinceEpoch].fuelGallons += (transaction.fuelQuantity ? parseFloat(transaction.fuelQuantity) : 0);

              transactionsToCreate[stateProvinceAbbrvLowered][transTimeSinceEpoch].transactions.push(transaction);

              lastSeenStateProvince = stateProvinceAbbrvLowered;
              lastSeenTransTimeSinceEpoch = transTimeSinceEpoch;
            }
          }
        });

        // now that we know all the transactions we have to make for this vehicle, lets do it
        Object.keys(transactionsToCreate).map(stateProvinceAbbrv => {
          const stateProvinceData = transactionsToCreate[stateProvinceAbbrv];
          const transactionKeys = Object.keys(stateProvinceData);
          for (let i = 0; i < transactionKeys.length; i++) {
            const transactionKey = transactionKeys[i];
            const vehicleStateProvinceMileage = stateProvinceData[transactionKey];

            const createMileageDatumAndTransaction = (vehicleStateProvinceMileage) => {
              const promise = new Promise((resolve, reject) => {
                const transactions = [].concat(vehicleStateProvinceMileage.transactions);
                vehicleStateProvinceMileage.transactions = undefined;

                Setters
                  .addRecord('IFTAVehicleStateProvinceMileage', vehicleStateProvinceMileage, Helpers.getCompanyReadWriteACL())
                  .then(
                    (vehicleMileageDatum) => {
                      resolve(vehicleMileageDatum);

                      for (let i = 0; i < transactions.length; i++) {
                        const transaction = transactions[i];
                        const iftaFuelCardDatum = Helpers.createTempParseObject('IFTAFuelCardData', {
                          ...transaction,
                          iftaVehicleStateProvinceMileage: vehicleMileageDatum,
                        });
                        iftaFuelCardDatum.setACL(Helpers.getCompanyReadWriteACL());
                        iftaFuelCardDatum.save();
                      }
                    }
                  );
              });
              return promise;
            };

            vehicleMileageUpdatePromises.push(createMileageDatumAndTransaction(vehicleStateProvinceMileage));
          }
        });
      }
    });

    Promise.all(vehicleMileageUpdatePromises).then(
      results => {
        resolve(vehicleMileagesDataArr);
      }
    );
  });
  return promise;
};

const getIFTAFuelCardData = (iftaVehicleStateProvinceMileage) => {
  const promise = new Promise(resolve => {
    const iftaFuelCardDataQuery = new Parse.Query('IFTAFuelCardData');
    iftaFuelCardDataQuery.equalTo('iftaVehicleStateProvinceMileage', iftaVehicleStateProvinceMileage);
    iftaFuelCardDataQuery.descending('transactionDateTime');
    iftaFuelCardDataQuery.limit(100);
    iftaFuelCardDataQuery.find().then(
      iftaFuelCardData => resolve(iftaFuelCardData)
    );
  });
  return promise;
};

const getIFTAVehicleLocationsByInterval = async (page = 0, limit = 20, sortBy, filters = []) => {
  const eldEventQuery = new Parse.Query('ELDEvent');

  // apply filter here
  filters.map(filter => {
    setQueryRestriction(eldEventQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
  });

  // at this point, copy current query to get the number of pages for pagination
  let eldEventCountQuery = eldEventQuery.toJSON();
  eldEventCountQuery = Parse.Query.fromJSON('ELDEvent', eldEventCountQuery);

  eldEventQuery.include(['vehicleLocation']);
  // call the ascending/descending function on the query, passing in the attribute
  eldEventQuery[sortBy.order](sortBy.attribute);

  eldEventQuery.limit(limit);
  eldEventQuery.skip(page * limit);

  return await Promise.all([eldEventCountQuery.count(), eldEventQuery.find()]).then(
    ([totalELDEventsCount, eldEvents]) => ({ eldEvents, totalELDEventsCount })
  ).catch(error => {
    console.log(error);
    const alertObject = { id: 'events-fetch-fail', description: 'Oops! Unable fetch location data. Please try refreshing', showCloseButton: true, autoDismissInMillis: 3500, type: AlertTypes.ERROR };
    addAlert(alertObject);

    throw new Error(error);
  });
};

const getIFTARoutesForVehicle = (unitId, dateStart, dateEnd, queryNewIfta = false) => {
  const filterBeta = [
    { name: 'vehicleUnitId', queryType: 'equalTo', value: unitId },
    { name: 'dateStart', queryType: 'greaterThanOrEqualTo', value: dateStart },
    { name: 'dateEnd', queryType: 'lessThanOrEqualTo', value: dateEnd },
    { name: 'isHidden', queryType: 'doesNotExist' },
  ];

  const filter = [
    { name: 'vehicleUnitId', queryType: 'equalTo', value: unitId },
    { name: 'dateStart', queryType: 'greaterThanOrEqualTo', value: dateStart },
    { name: 'dateEnd', queryType: 'lessThanOrEqualTo', value: dateEnd },
  ];

  if (queryNewIfta) {
    return Getters.queryCompanyObjects('IFTARoute_Beta', undefined, undefined, filterBeta, { dateStart: 'ascending' }, ['vehicleLocationStart', 'vehicleLocationEnd', 'iftaRouteDriverPeriods', 'iftaRouteDriverPeriods.driver', 'fuelPurchases'], undefined, undefined, undefined, true);
  }

  return Getters.queryCompanyObjects('IFTARoute', undefined, undefined, filter, { dateStart: 'ascending' }, ['vehicleLocationStart', 'vehicleLocationEnd', 'iftaRouteDriverPeriods', 'iftaRouteDriverPeriods.driver', 'fuelPurchases'], undefined, undefined, undefined, true);
};

const sumStateProvinceForIFTARoutes = (iftaRouteArr) => {
  const stateProvinceMileagesObj = {};
  for (let i = 0; i < iftaRouteArr.length; i++) {
    const iftaRouteObj = iftaRouteArr[i];
    if (stateProvinceMileagesObj[iftaRouteObj.get('stateProvince')] !== undefined) {
      stateProvinceMileagesObj[iftaRouteObj.get('stateProvince')] = iftaRouteObj.get('savedVehicleKm');
    } else {
      stateProvinceMileagesObj[iftaRouteObj.get('stateProvince')] += iftaRouteObj.get('savedVehicleKm');
    }
  }

  return stateProvinceMileagesObj;
};

const getOdometerReadingsForDateRange = async (vehicleUnitId, dateStart, dateEnd, distanceUnit) => {
  // apply filter here
  const filters = [
    new Filter('totalVehicleKm', 0, QueryRestrictionTypes.GREATER_THAN),
    new Filter('eventDateTime', dateStart, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO),
    new Filter('eventDateTime', dateEnd, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO),
    new Filter('company', Getters.getCurrentUserCompany(), QueryRestrictionTypes.EQUAL_TO),
    new Filter('eldEventTypeCodeInt', [11, 12, 13, 14, 31, 32, 51, 52], QueryRestrictionTypes.CONTAINED_IN),
    new Filter('eldEventRecordStatusInt', 1, QueryRestrictionTypes.EQUAL_TO),
    new Filter('vehicleUnitId', vehicleUnitId, QueryRestrictionTypes.EQUAL_TO),
  ];

  // const ascendingEldEventQuery = new Parse.Query('ELDEvent');
  // filters.map(filter => {
  //   setQueryRestriction(ascendingEldEventQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
  // });
  // ascendingEldEventQuery.ascending('eventDateTime');
  // ascendingEldEventQuery.limit(50);

  // const descendingEldEventQuery = new Parse.Query('ELDEvent');
  // filters.map(filter => {
  //   setQueryRestriction(descendingEldEventQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
  // });
  // descendingEldEventQuery.descending('eventDateTime');
  // descendingEldEventQuery.limit(50);

  const eldEventQuery = new Parse.Query('ELDEvent');
  filters.map(filter => {
    setQueryRestriction(eldEventQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
  });

  // return await Promise.all([ascendingEldEventQuery.find(), descendingEldEventQuery.find()]).then(async results => {
  //   const startEldEvents = results[0];
  //   const endEldEvents = results[1].reverse();
  //   const eldEvents = [].concat(...startEldEvents, ...endEldEvents);

  return await Getters.getAllFromQuery(eldEventQuery).then(async eldEvents => {
    let odoObject = (await ELD.getOdometerReadings(eldEvents, distanceUnit, false, false, true))[vehicleUnitId];
    if (!odoObject) return {};
    if (odoObject.odometerEnd - odoObject.odometerStart >= 0) return odoObject;

    odoObject = (await ELD.getOdometerReadings(eldEvents, distanceUnit, false, false, true, true))[vehicleUnitId];
    if (odoObject.odometerEnd - odoObject.odometerStart >= 0) return odoObject;

    odoObject = (await ELD.getOdometerReadings(eldEvents, distanceUnit, false, true, true))[vehicleUnitId];
    if (odoObject.odometerEnd - odoObject.odometerStart >= 0) return odoObject;

    odoObject = (await ELD.getOdometerReadings(eldEvents, distanceUnit, false, true, true, true))[vehicleUnitId];
    if (odoObject.odometerEnd - odoObject.odometerStart >= 0) return odoObject;

    return {};
  });
  // ELD.getOdometerReadings(eldEvents, distanceUnit, useProjectionMethod, useAccumulated);
};


// const routeToNewIFTA = () => {
//   const companyObjectIds = ['79P6o8YmqD', 'melDYxYIQj', 'op7MuH6Lyr', 'tTkMjvmhji'];
//   const currentUser = getCurrentUser();
//   const company = getAttribute(currentUser, 'belongsToCompany')
//   const companyObjectId = getAttribute(company, 'objectId');

//   if (companyObjectIds.includes(companyObjectId)) {
//     return true;
//   }

//   return false;
// }

// main function order: getTruckMileage/getInfoFromFuelCard -> reconcileTruckAndFuelCardData
export {
  applyFuelCardToIFTAData,
  getGeocode,
  getIFTAFuelCardData,
  getIFTAMonthInterval,
  getIFTAVehicleDataEdits,
  getIFTAVehicleLocationsByInterval,
  getIFTAVehicles,
  getIFTAUnitIds,
  getIFTAFuelCardInfo,
  getInfoFromFuelCard,
  getReportStyles,
  getStateProvinceSequenceData,
  getVehicleMileages,
  getVehicleDistances,
  integrateFuelCardToFuelMileageData,
  mapBCTaxFormMapping,
  mapORTaxFormMapping,
  pdfFillTaxReport,
  getIFTARoutesForVehicle,
  sumStateProvinceForIFTARoutes,
  getOdometerReadingsForDateRange,
};
