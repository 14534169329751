import Constants from './Constants';

import AlertTypes from 'enums/SBAlert';
import store from 'store';

const dispatchFns = {
  add: function(alert) {
    return {
      type: Constants.ADD_ALERT,
      alert,
    }; 
  },
  delete: function(alert) {
    return {
      type: Constants.DELETE_ALERT,
      alert,
    };
  }
};


export function addAlert(alert = { id: '42069', code: 0, description: '', type: AlertTypes.ERROR }) {
  const alerts = store.getState().SBAlert.alerts;
  if (alerts.length < 5) store.dispatch(dispatchFns.add(alert));
}

export function deleteAlert(alert) {
  store.dispatch(dispatchFns.delete(alert));
}
