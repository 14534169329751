import React from 'react';
import PropTypes from 'prop-types';
// CSS
import styles from './JobActionTypePicker.module.scss';

function JobActionTypePicker(props) {
  return (
    <table className={styles.picker}><tbody><tr>
      <td className={props.selectedJobAction.jobActionType === 'Pickup' && styles.active} onClick={() => props.handleFormChange('jobActionType', 'Pickup')}>Pickup</td>
      <td className={props.selectedJobAction.jobActionType === 'Dropoff' && styles.active} onClick={() => props.handleFormChange('jobActionType', 'Dropoff')}>Dropoff</td>
      {
        // <td className={props.selectedJobAction.jobActionType === 'Transfer' && styles.active} onClick={() => props.handleFormChange('jobActionType', 'Transfer')}>Transfer</td>
      }
    </tr></tbody></table>
  );
}

JobActionTypePicker.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  selectedJobAction: PropTypes.object.isRequired,
};

export default JobActionTypePicker;
