import React, { useState, useEffect } from 'react';

// Enums
import { Document } from 'sb-csapi/dist/enums/Dispatch/Document';

// sbCore Components
import Dropdown from 'sbCore/Dropdown/Dropdown';

// Styles
import './style.scss';

/**
 * @description A dropdown containing a list of selectable document types
 * @param {Function} onDocumentTypeChange - Function that updates the document type int of the parent's state
 * @param {Number} [documentTypeInt] - A pre-selected document type
 */
function DispatchDocumentTypeDropdown({ ...props }) {
  const documentsArray = Object.keys(Document).map(key => Document[key]);

  // ** useStates ** //
  const [selectedDocumentType, setSelectedDocumentType] = useState(undefined);

  // ** useEffects ** //
  useEffect(() => {
    if (!documentsArray) return;
    const _selectedDocumentType = documentsArray.find((document) => document.type === props.documentTypeInt);
    setSelectedDocumentType(_selectedDocumentType);
  }, [props.documentTypeInt, documentsArray]);

  // ** Callback Functions ** //

  // sets the selected document type state here and in the the parent component
  function onDocumentTypeChange(e) {
    setSelectedDocumentType(e.value);

    if (props.onDocumentTypeChange) {
      // send the chosen type int back up to parent
      props.onDocumentTypeChange(e.value ? e.value.type : e.value);
    }
  }

  // ** Dropdown Templates ** //
  function itemTemplate(option) {
    return (
      <div>
        {option.description}
      </div>
    );
  }

  function valueTemplate(option) {
    return (
      <>
        {option && (
          <div>
            {option.description}
          </div>
        )}

        {!option && (
          <div>
            No Document Type Selected
          </div>
        )}
      </>
    );
  }

  return (
    <div className="dispatch-document-type-dropdown">
      <Dropdown
        className="dropdown"
        value={selectedDocumentType}
        options={documentsArray}
        onChange={(e) => onDocumentTypeChange(e)}
        filter
        filterBy="description"
        optionLabel="description"
        placeholder="Select Document Type"
        valueTemplate={valueTemplate}
        itemTemplate={itemTemplate}
        disabled={props.disableDocumentTypeSelection}
      />
    </div>
  );
}

export default DispatchDocumentTypeDropdown;
