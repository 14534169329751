import React from 'react';
import PropTypes from 'prop-types';
import { Toggle, SelectField, MenuItem } from 'material-ui';

// CSS
import styles from './StartPaymentModal.module.scss';

function StartPaymentModal(props) {
  return (
    <div>
      Your account is not under a subscription with Switchboard. 
      { !props.disableCCEntry &&
        <div>
          To continue using Switchboard, Start your plan by clicking 'Start Plan' below

          <br />

          <div className={styles.monthlyFee}>
            { !props.optionsAvailable &&
              <div className={styles.options}>
                <Toggle
                  label="GPS Tracking & Geofence Alerts Module"
                  toggled={props.gpsBool}
                  onToggle={props.toggleGPSBool}
                  style={{ width: '50%', margin: '0 auto' }}
                />
                <Toggle
                  label="IFTA Module"
                  toggled={props.iftaBool}
                  onToggle={props.toggleIFTABool}
                  style={{ width: '50%', margin: '0 auto' }}
                />
                <SelectField
                  floatingLabelText="Currency"
                  value={props.currency}
                  onChange={props.handleChangeCurrency}
                  style={styles.currency}
                  autoWidth
                >
                  <MenuItem value={'CAD'} primaryText="CAD" />
                  <MenuItem value={'USD'} primaryText="USD" />
                </SelectField>
              </div>
            }
            <div className={styles.plan}>
              {props.currency}$ {props.monthlyFee / 100} / ELD / month
              <button className={`${styles.startButton} buttonDefault`} onClick={props.handleStart} disabled={props.disableButton}>Start Plan</button>
            </div>
          </div>
        </div>
      }

      <div className={styles.contactUs}>
        Our team is working very hard to make sure your experience with Switchboard is <b>amazing</b>
        <br />
        Please let us know about anything we can do for you at <a href="mailto:support@onswitchboard.com">support@onswitchboard.com</a>
      </div>
    </div>
  );
}

StartPaymentModal.propTypes = {
  currency: PropTypes.string,
  handleChangeCurrency: PropTypes.func,
  gpsBool: PropTypes.bool,
  iftaBool: PropTypes.bool,
  toggleGPSBool: PropTypes.func,
  toggleIFTABool: PropTypes.func,
  monthlyFee: PropTypes.number,
  optionsAvailable: PropTypes.bool.isRequired,
  handleStart: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired,
};

export default StartPaymentModal;
