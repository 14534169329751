import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// mapbox
import * as Mapbox from 'api/Mapbox';
import ReactMapboxGl, { Feature, Layer, Marker } from 'react-mapbox-gl';

const ReactMap = ReactMapboxGl({
  accessToken: Mapbox.getToken(),
  renderWorldCopies: true,
});

// styles
import './style.scss';

/**
 * @description Show trip paths
 * @param {Object} iftaRouteObj - The IFTA route data whose path we want to display
 * @param {Array} derivedVehicleLocations - The array of derived vehiclelocations that make up the trip path
 */
function VehiclePathMap({ ...props }) {

  const [iftaRouteObj, setIFTARouteObj] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  // Mapping
  const [centerCoords, setCenterCoords] = useState([0, 0]);
  const [tripCoords, setTripCoords] = useState([]);

  useEffect(() => {
    let didCancel = false;

    if (!props.iftaRouteObj || !props.derivedVehicleLocations) return;

    async function init() {
      // Keep in mind when dealing with coordinates and mapbox, they do [lng, lat]
      setIsLoading(true);

      const { iftaRouteObj, derivedVehicleLocations } = props;

      const _tripCoords = [];
      let _centerCoords = [0, 0];

      for (let i = 0; i < derivedVehicleLocations.length; i++) {
        const derivedVehicleLocation = derivedVehicleLocations[i];

        const location = derivedVehicleLocation.location;
        if (!location) continue;

        const coords = [location._longitude, location._latitude];
        _tripCoords.push(coords);
      }

      if (_tripCoords.length > 1) {
        // get the midpoint of the start-end of trip. then we have to bound it to mapbox's [-90, 90] criteria
        // _centerCoords = calculateMidpoint(_tripCoords[0], _tripCoords[_tripCoords.length - 1]);
        _centerCoords = _tripCoords[0];
      }

      if (!didCancel) {
        setIFTARouteObj(iftaRouteObj);
        setTripCoords(_tripCoords);
        setCenterCoords(_centerCoords);
        setIsLoading(false);
      }
    }

    init();

    return () => { didCancel = true; };
  }, [props.iftaRouteObj, props.derivedVehicleLocations]);

  /**
   * Maps logic
   */
  // Get the middle coordinates centering the pair of coordinates given
  function calculateMidpoint(coord1, coord2) {
    const lat1 = coord1[0];
    const lon1 = coord1[1];
    const lat2 = coord2[0];
    const lon2 = coord2[1];

    const avgLat = (lat1 + lat2) / 2;
    const avgLon = (lon1 + lon2) / 2;

    return [avgLat, avgLon];
  }

  let routeLine;
  if (tripCoords.length > 0) {
    routeLine = (
      // <Layer type="line" id="marker" layout={{ 'icon-image': 'marker-15' }}>
      //   <Feature coordinates={[-0.481747846041145, 51.3233379650232]} />
      // </Layer>
      <Layer type="line" layout={{ 'line-cap': 'round', 'line-join': 'round' }} paint={{ 'line-color': '#1B6099', 'line-width': 3 }}>
        <Feature coordinates={[...tripCoords]} />
      </Layer>
    );
  }

  let className = 'ifta-vehicle-path-map';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <ReactMap
        className="lg:flex-1 border-round h-25rem"
        containerClassName="h-25rem lg:h-auto"
        zoom={(centerCoords[0] !== 0 && centerCoords[1] !== 0) ? [10] : [1]}
        style="mapbox://styles/mapbox/navigation-preview-day-v4"
        center={(centerCoords[0] !== 0 && centerCoords[1] !== 0) ? centerCoords : [-90, 50]}
      >
        { routeLine }
      </ReactMap>
    </div>
  );
}

export default VehiclePathMap;
