import SBInputTextarea from 'sbCore/InputTextarea/InputTextarea';

import './style.scss';

/**
 * @description customizable textarea input for document generation
 * @param {String} value - the value of the input for a controlled input, requires onChange callback to be specified
 * @param {Function} onChange - the callback function for a controlled input, requires the value 
 * @param {String} [label] - the label that is attached to this input
 * @param {bool} [horizontal] - determines whether the label is on the top of the input or on the left of the input
 * @param {String} [labelClassName] - styles to be applied to the label itself
 * @param {String} [inputClassName] - styles to be applied to the input
 * @param {Integer} [rows] - the number of rows for the textarea
 * @param {Integer} [cols] - the number of columns for the textarea
 * @param {bool} [success] - show success
 * @param {bool} [warning] - show warning
 * @param {bool} [error] - show error
 * @param {bool} [isDownloaded] - show/hide input borders when downloading pdf
 * @returns {Component}
 */
function InputTextarea({ label, horizontal, value, success, warning, error, isDownloaded, ...props }) {
  const labelClassName = props.labelClassName;
  const inputClassName = props.inputClassName;

  return (
    <div className={`docgen-input-textarea${horizontal ? ' flex' : ''}`}>
      {label && 
        <div className={`information-sub-title${labelClassName ? ` ${labelClassName}` : ''}${horizontal ? ` pt-3` : ''}`}>{label}</div>
      }
      <div className={`${isDownloaded ? ' invisible-border' : ''} ${horizontal ? ' horizontal-document-input-text' : ''}`}>
        <SBInputTextarea className={inputClassName} value={value} placeholder={'-'} onChange={(e) => props.onChange(e.target.value)} rows={props.rows || 5} cols={props.cols || 30} autoResize success={success} warning={warning} error={error} />
      </div>
    </div>
  );
}

export default InputTextarea;

// invisible-border // need this for printing