import * as ActionConstants from './ActionConstants';
import * as ELD from 'api/ELD';
import moment from 'moment-timezone';

export function fetchingSafetyOfficer() {
  return {
    type: ActionConstants.FETCH_SAFETYOFFICER_INPROGRESS,
  };
}

export function fetchSafetyOfficerSuccess(safetyOfficer) {
  return {
    type: ActionConstants.FETCH_SAFETYOFFICER_SUCCESS,
    safetyOfficer,
  };
}

export function fetchSafetyOfficerError(error) {
  return {
    type: ActionConstants.FETCH_SAFETYOFFICER_ERROR,
    error,
  };
}

export function exportELDDataFile(driverParseObj, fromDateObj, toDateObj) {

  console.log(driverParseObj);
  ELD.getELDCSVContent(driverParseObj, moment([2017, 0, 1]), moment([2017, 0, 31])).then((csvContent) => {
    console.log(csvContent);
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', `data:attachment/csv,${encodedUri}`);
    link.setAttribute('download', 'csv_woo.csv');
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "my_data.csv".
  });

}
