import Parse from 'parse';
import moment from 'moment-timezone';

import { setQueryRestriction, setParseObject } from 'api/Parse';
import { formatName } from 'api/Helpers';
import { getCurrentUser, getAllFromQuery } from 'api/Getters';

import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

import Driver from 'sbObjects/Driver';

/**
 * @memberof module:DriverPattern
 * @description get the latest engineDTC's that occured while driver was driving between intervalStart and intervalEnd
 *
 * @param {*} driverObjectIds
 * @param {*} intervalStart
 * @param {*} intervalEnd
 */
async function getEngineDiagnosticsByDriver(driverObjectIds = [], intervalStart, intervalEnd) {
  const engineDiagnosticDataByDriver = {};
  if (driverObjectIds.length === 0) return engineDiagnosticDataByDriver;
  const todayEnd = moment().endOf('day').toDate();
  const sevenDaysAgoStart = moment(todayEnd).subtract(7, 'days').startOf('day').toDate();


  const engineDTCPromises = driverObjectIds.map(driverObjectId => {
    const engineDTCQuery = new Parse.Query('EngineDTC');
    setQueryRestriction(engineDTCQuery, QueryRestrictionTypes.EQUAL_TO, 'driver', driverObjectId);
    setQueryRestriction(engineDTCQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'loggedAtUTC', intervalStart || sevenDaysAgoStart);
    setQueryRestriction(engineDTCQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'loggedAtUTC', intervalEnd || todayEnd);
    setQueryRestriction(engineDTCQuery, QueryRestrictionTypes.EQUAL_TO, 'set', true);
    engineDTCQuery[QuerySortOrderTypes.DESCENDING]('loggedAtUTC'); // get the most recent
    engineDTCQuery.include(['driver', 'driver.user']);
    return getAllFromQuery(engineDTCQuery);
  });

  try {
    const engineDTCs = await Promise.all(engineDTCPromises);
    driverObjectIds.map((driverObjectId, index) => {
      let correspondingEngineDTCs = engineDTCs[index];
      correspondingEngineDTCs = correspondingEngineDTCs
        .filter((engineDTC) => (engineDTC.get('malfunctionCode') !== '0000007F'))
        .map(engineDTC => {
          let driver = engineDTC.get('driver');
          const user = driver.get('user');
          if (driver) {
            driver = new Driver(
              driver.id,
              user.get('firstName'),
              user.get('lastName'),
              user.get('phoneNumber'),
              driver.get('driversLicense'),
              driver.get('driversLicenseCountry'),
              driver.get('licenseExpiryDate'),
              driver.get('timezoneOffsetFromUTC'),
              driver.get('eldStatusInt'),
            );
          }

          return {
            objectId: engineDTC.id,
            loggedAtUTC: engineDTC.get('loggedAtUTC'),
            malfunctionCode: engineDTC.get('malfunctionCode'),
            driver,
          }
        });
      engineDiagnosticDataByDriver[driverObjectId] = correspondingEngineDTCs;
    });

    return engineDiagnosticDataByDriver;

  } catch (err) {
    throw new Error(err);
  }

}

export {
  getEngineDiagnosticsByDriver,
};
