/** @module Vehicle */
import { createApi } from '@reduxjs/toolkit/query/react';

// CSAPI
import {
  copyQuery, count, createQuery, createQueryOr, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, addRecord, destroyRecord,
} from 'sb-csapi/dist/AAPI';

// API
import { getEquipmentGroupEquipmentMapping } from 'api/Equipment/Groups';

// Enums
import { QuerySortOrder, QueryRestriction } from 'sb-csapi/dist/enums/Query';

// Sort
import Sort from 'sb-csapi/dist/sbObjects/Sort';

// Filter
import Filter from 'sb-csapi/dist/sbObjects/Filter';

const fetchedDrivers = {};
/**
 * 
 * @param {array} objects - An array of records to retrieve attributes from
 * @param {array} attributes  - An array of strings or other arrays of 2 elements (first element a string, second element an array of attributes)
 * @param {bool} saveInObject - Save in fetchedDrivers or not
 * @param {bool} isOutputArray - Whether the desired output is in form of an array or just the first object
 * @returns An array or individual object with the desired attributes
 * 
 * @example getAttributesObject(drivers, ['name', 'objectId', ['location', ['ycoordinate', 'xcoordinate']], true])
 */
function getAttributesObject(objects, attributes, saveInObject, isOutputArray = true) {
  const attributesObjects = objects.map((object) => {
    const objectId = getAttribute(object, 'objectId');
    if (fetchedDrivers[objectId]) return fetchedDrivers[objectId];
    const attributeObj = {};
    attributes.forEach((attribute) => {
      if (typeof attribute === 'string') {
        attributeObj[attribute] = getAttribute(object, attribute);
        return;
      } // else, we are looking at a set of attributes within the attribute
      const _attribute = getAttribute(object, attribute[0]);
      const newAttribute = getAttributesObject([_attribute], attribute[1], false, false);
      attributeObj[attribute[0]] = newAttribute;
    });
    if (saveInObject) fetchedDrivers[objectId] = attributeObj;
    return attributeObj;
  });
  if (isOutputArray) return attributesObjects;
  return attributesObjects[0];
}

/**
 * @memberof module:Vehicle
 * @description Obtains vehicles from a company
 * @param {object} options - See example
 * @param {string} companyObjectId - Company we wish to retrieve vehicles for
 * @param {bool} includeChildCompanies - Include child/sub-companies of this parent company
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {int} page - The current page for pagination
 * @param {int} limit - The limit of records obtained per pagination
 * @param {bool} queryAll - Get all records
 *
 * @returns {object} - { totalVehiclesCount: 0, vehicles: [] }
 */
async function getVehicles(options = { sessionToken: getCurrentUserSessionToken() }, companyObjectId = getCurrentUserCompanyObjectId(), includeChildCompanies, filters = [], sortBy = new Sort(QuerySortOrder.ASCENDING, 'unitId'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  let vehicleQuery = createQuery('Vehicle');

  const _filters = [...filters];

  const companyQuery = createQuery('Company');
  setQueryRestriction(companyQuery, QueryRestriction.EQUAL_TO, 'objectId', companyObjectId);

  if (includeChildCompanies) {
    // if getting child companies' vehicles, the additional queries
    const companyLinkQuery = createQuery('CompanyLink');

    const companyLinkFilters = [
      new Filter(QueryRestriction.MATCHES_QUERY, 'parentCompany', companyQuery),
      new Filter(QueryRestriction.EQUAL_TO, 'authorized', true),
    ];

    companyLinkFilters.map(filter => setQueryRestriction(companyLinkQuery, filter.queryRestriction, filter.attribute, filter.value));

    // now that we have all the child companies, create queries that get the vehicles of them
    const companyLinks = await findRecords(options, companyLinkQuery);
    const childCompanyVehicleQueries = companyLinks.map(company => {
      const childCompanyVehicleQuery = createQuery('Vehicle');
      setQueryRestriction(childCompanyVehicleQuery, QueryRestriction.EQUAL_TO, 'belongsToCompany', getAttribute(company, 'childCompany'));
      return childCompanyVehicleQuery;
    });

    // the main current user's company's vehicle query
    const mainCompanyVehicleQuery = createQuery('Vehicle');
    setQueryRestriction(mainCompanyVehicleQuery, QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery);

    // altogether
    vehicleQuery = createQueryOr([mainCompanyVehicleQuery, ...childCompanyVehicleQueries]);

  } else {
    // lock queries to the current users company
    setQueryRestriction(vehicleQuery, QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery);
  }

  // set universal filters
  // _filters.push(new Filter(QueryRestriction.NOT_EQUAL_TO, 'isHidden', true));

  _filters.map(filter => setQueryRestriction(vehicleQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  const vehicleCountQuery = copyQuery(vehicleQuery);

  // sort
  sortQuery(vehicleQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(vehicleQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(vehicleQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(vehicleQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(vehicleQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the count and the vehicles
  const promises = [count(options, vehicleCountQuery), findRecords(options, vehicleQuery, false, queryAll)];

  try {
    const [totalVehiclesCount, vehicles] = await Promise.all(promises);
    return { totalVehiclesCount, vehicles };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * The query function performed when querying for Vehicles from the VehicleApi
 * @param {Object} args The arguments that are passed into the query
 * @param {Object} api The api arguments as sepcified in https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#implementing-a-custom-basequery 
 * @param {Object} extraOptions The extraOptions argument as specified in https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#implementing-a-custom-basequery
 * @returns {Object} Returns an object either containing a "data" or "error" property
 */
const vehiclesQuery = async (args, { signal, dispatch, getState }, extraOptions = {}, baseQuery) => {
  const { options, companyObjectId, includeChildCompanies, filters, sortBy, includedPointers, selectedAttributes, page, limit, queryAll } = args;

  const { equipmentGroupInformationMap, equipmentGroupEquipmentVehiclesMap } = await getEquipmentGroupEquipmentMapping();

  // Step 4: Retrieve the vehicles for the given company (limited to the "limit" param)
  const vehicleQueryResult = await getVehicles(options, companyObjectId, includeChildCompanies, filters, sortBy, includedPointers, selectedAttributes, page, limit, queryAll);
  const { vehicles } = vehicleQueryResult;

  // Step 5: Run through each vehicle, check to see if its part of any groups, and add it to the corresponding group
  // If the vehicle is not part of any group, then add it to the default group
  const vehiclesArray = vehicles.map((vehicle) => {
    try {
      const vehicleObj = vehicle.toJSON();

      // check if drivers were parsed correctly, if not, boohoo, jk, i got ya -juan ;)
      if (vehicleObj.drivers && vehicleObj.drivers.length > 0 && !vehicleObj.drivers[0].user_fullName) {
        const drivers = getAttribute(vehicle, 'drivers');
        const _drivers = getAttributesObject(drivers, ['objectId', 'user_fullName', 'eldStatusInt', ['latestELDEvent', ['shippingDocumentNumber']]], true);
        vehicleObj.drivers = _drivers;
      }

      // Find out if theres an equipment group for this vehicle
      const isPartOfEquipmentGroup = equipmentGroupEquipmentVehiclesMap[vehicleObj.objectId] !== undefined;

      if (isPartOfEquipmentGroup) {
        equipmentGroupEquipmentVehiclesMap[vehicleObj.objectId].forEach((equipmentGroupObjectId) => {
          equipmentGroupInformationMap[equipmentGroupObjectId].vehicles.push(vehicleObj);
        })
      } else {
        equipmentGroupInformationMap.default.vehicles.push(vehicleObj);
      }

      return vehicleObj;
    } catch (err) {
      throw Error(err.message);
    }
  });

  return { data: { equipmentGroup: equipmentGroupInformationMap, vehicles: vehiclesArray } };
};

/**
 * Defines an API slice for the Vehicle API. This is used to generate the appropriate hooks for various actions on a query/mutation
 * 
 * @todo: Add more endpoints to allow for mutations/updates to vehicles
 * 
 * To get more information on this topic, see https://redux-toolkit.js.org/rtk-query/overview#create-an-api-slice
 */
const vehicleApi = createApi({
  reducerPath: 'vehicleApi',
  endpoints: (builder) => ({
    getVehicles: builder.query({
      queryFn: vehiclesQuery,
    }), 
  }),
});

// Exports React hooks for api endpoints
export const { useGetVehiclesQuery } = vehicleApi;
export { getVehicles, vehicleApi };