export default class DispatchHistory {
  /**
   * 
   * @param {string} objectId - db
   * @param {string} description - db
   * @param {date} triggeredAtUTC - db
   * @param {int} level - db
   * @param {string} dispatchClass - db 
   * @param {string} dispatchClassObjectId - db 
   * @param {string || object} dispatchJob - DispatchJob
   */
  constructor(objectId, description, triggeredAtUTC, level, dispatchClass, dispatchClassObjectId, dispatchJob) {
    this.objectId = objectId;
    this.description = description;
    this.triggeredAtUTC = triggeredAtUTC;
    this.level = level;
    this.dispatchClass = dispatchClass;
    this.dispatchClassObjectId = dispatchClassObjectId;
    this.dispatchJob = dispatchJob;
  }
}