const AttributeTypes = Object.freeze({
  'DISPATCH_JOB': 'dispatchJob',
  'NOTES_GENERAL': 'notesGeneral',
  'NOTES_DRIVER': 'notesDriver',
  'NOTES_DISPATCH': 'notesDispatch',
  'NOTES_INVOICE': 'notesInvoice',
  'NOTES_BILLING': 'notesBilling',
});

export default AttributeTypes;
