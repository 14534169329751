import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Actions
import { addDriverToState } from 'actions/Driver'

// API
import { getAttribute } from 'api/Parse';
import { getCompanyDispatchers } from 'api/Getters'
import * as Analytics from 'api/Analytics'
import {
  getCompanyAnalytics,
  updateCompanyAnalytics,
} from 'api/CompanyAnalytics/CompanyAnalytics';

// Components
import AddDriverModal from '../view/AddDriverModal'
import { MDBBtn, MDBIcon } from 'mdbreact'

// Context
import StoreContext from 'contexts/StoreContext';

// CSS

class AddDriver extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dispatchers: [],
      addDriverModal: {
        show: false,
        fetchingData: false
      },
    }
    this.getDispatchers = this.getDispatchers.bind(this)
    this.showModal = this.showModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.addDriver = this.addDriver.bind(this)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.Company !== this.props.Company || nextState !== this.state) {
      return true
    }
    return false
  }

  getDispatchers() {
    this.setState(
      {
        ...this.state,
        addDriverModal: { ...this.state.addDriverModal, fetchingData: true }
      },
      () => {
        getCompanyDispatchers().then(allDispatchers => {
          const dispatchers = allDispatchers.filter(
            dispatcher => dispatcher.get('user') && dispatcher.get('user').id
          );
          this.setState({
            ...this.state,
            dispatchers,
            addDriverModal: {
              ...this.state.addDriverModal,
              fetchingData: false
            }
          })
        })
      }
    )
  }

  showModal() {
    this.state.addDriverModal.show = true
    this.setState(this.state, () => {
      this.getDispatchers()
    })
  }

  closeModal() {
    this.state.addDriverModal.show = false
    this.setState(this.state)
  }

  async addDriver(userInfo) {
    try {
      await addDriverToState(userInfo, this.props.returnUser);

      // Check company analytics status (handled in AddDriverModal)
      // const companyAnalytics = await getCompanyAnalytics();
      // const DRIVERCREA = getAttribute(companyAnalytics, 'DRIVERCREA');
      // if (!DRIVERCREA) {
      //   Analytics.track('Driver Created');
      //   await updateCompanyAnalytics(companyAnalytics, { DRIVERCREA: true });
      // }

      const newState = Object.assign({}, this.state)
      newState.addDriverModal.show = false
      this.setState(newState);
      this.props.refreshState();
    } catch (err) {
      throw new Error(err);
    }

    // const promise = new Promise((resolve, reject) => {
    //   // The userInfo are obtained from the form in DriverModal
    //   addDriverToState(userInfo, this.props.returnUser).then(
    //     () => {
    //       // this.props.refreshState();
    //       Analytics.track('Driver Created')

    //       const newState = Object.assign({}, this.state)
    //       newState.addDriverModal.show = false
    //       this.setState(newState, () => {
    //         resolve()
    //       })
    //       this.props.refreshState()
    //     },
    //     error => reject(error)
    //   )
    // })
    // return promise
  }

  render() {
    const dispatchers = [].concat(this.state.dispatchers)
    const stateDispatcher = this.props.Dispatcher.dispatcher;

    if (
      stateDispatcher.id &&
      stateDispatcher
        .get('user')
        .get('userType')
        .indexOf(1) !== -1
    ) {
      // if calling user is a dispatcher, then its in the dispatcher list. make it the first item
      let indexToRemove = 0
      for (let i = 0; i < dispatchers.length; i++) {
        if (dispatchers[i].id === stateDispatcher.id) {
          indexToRemove = i
          break
        }
      }

      dispatchers.splice(indexToRemove, 1)
      dispatchers.unshift(stateDispatcher)
    }

    return (
      <div className={this.props.className}>
        <AddDriverModal
          show={this.state.addDriverModal.show}
          handleClose={this.closeModal}
          addDriverFormHandler={this.addDriver}
          company={this.props.Company.company}
          dispatchers={dispatchers}
          subscription={this.props.Subscription.subscription}
          fetchingData={this.state.addDriverModal.fetchingData}
        />
        <MDBBtn
          color='primary'
          size="sm"
          disabled={!this.props.Company.fetched}
          onClick={this.showModal}
        >
          <MDBIcon icon="plus-square" className="mr-1" /> DRIVER
        </MDBBtn>
      </div>
    )
  }
}

AddDriver.propTypes = {
  User: PropTypes.object.isRequired,
  Company: PropTypes.object.isRequired,
  Dispatcher: PropTypes.object.isRequired,
  className: PropTypes.string,
  Subscription: PropTypes.object.isRequired,
  returnUser: PropTypes.bool, // whether or not to return the userObject instead of driverObject to store
  refreshState: PropTypes.func
}

const mapStateToProps = state => {
  const { User, Company, Dispatcher, Subscription } = state
  return {
    User,
    Company,
    Dispatcher,
    Subscription
  }
}

export default connect(mapStateToProps, null, null, { context: StoreContext })(AddDriver);


