// Holds all eManifest related objects and checks

function isGoodTripNumber(tripNumber, type) {
  // tripNumber is the full trip number (carrier code included)
  if (type === 'ace') {
    return /^[A-Z]{4}[A-Z0-9]{4,21}$/.test(tripNumber);
  } else {
    return false;
  }
}

export {
  isGoodTripNumber,
};
