import { findIndexOfObjArr, removeDuplicatesByObjectId } from 'api/Helpers';
import * as ActionConstants from '../actions/ActionConstants';

const initialState = {
  trailers: [],
  fetched: false, // Whether or not drivers are loaded
};

function disableTrailers(stateTrailers, trailersToBeDeleted) {
  const newStateTrailers = [].concat(stateTrailers);
  if (newStateTrailers) {
    const trailersToBeDeletedLen = trailersToBeDeleted.length;
    for (let i = 0; i < trailersToBeDeletedLen; i++) {
      const index = findIndexOfObjArr(newStateTrailers, 'id', trailersToBeDeleted[i].id);
      newStateTrailers.splice(index, 1);
    }
  }
  return newStateTrailers;
}

export function GlobalTrailer(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_GLOBALTRAILERS_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.FETCH_GLOBALTRAILERS_INPROGRESS,
        fetched: false,
        error: undefined,
      };
    }
    case ActionConstants.FETCH_GLOBALTRAILERS_SUCCESS: {
      return {
        status: ActionConstants.FETCH_GLOBALTRAILERS_SUCCESS,
        trailers: removeDuplicatesByObjectId(state.trailers.concat(action.trailers)),
        error: undefined,
        fetched: true,
      };
    }
    case ActionConstants.FETCH_GLOBALTRAILERS_ERROR: {
      return {
        ...state,
        status: ActionConstants.FETCH_GLOBALTRAILERS_ERROR,
        error: action.error,
        fetched: false,
      };
    }
    case ActionConstants.DELETE_GLOBALTRAILERS: {
      return {
        ...initialState,
        status: action.type,
      };
    }
    case ActionConstants.ADD_GLOBALTRAILER_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.ADD_GLOBALTRAILER_INPROGRESS,
        error: undefined,
      };
    }
    case ActionConstants.ADD_GLOBALTRAILER_SUCCESS: {
      // Succesfully added to db
      const updatedTrailers = [].concat(state.trailers);
      updatedTrailers.unshift(action.trailer);
      return {
        ...state,
        status: ActionConstants.ADD_GLOBALTRAILER_SUCCESS,
        trailers: updatedTrailers,
        error: undefined,
      };
    }
    case ActionConstants.ADD_GLOBALTRAILER_ERROR: {
      return {
        ...state,
        status: ActionConstants.ADD_GLOBALTRAILER_ERROR,
        error: action.error,
      };
    }
    case ActionConstants.UPDATE_GLOBALTRAILER_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.UPDATE_GLOBALTRAILER_INPROGRESS,
        updateReference: action.trailer,
        error: undefined,
      };
    }
    case ActionConstants.UPDATE_GLOBALTRAILER_SUCCESS: {
      return {
        ...state,
        status: ActionConstants.UPDATE_GLOBALTRAILER_SUCCESS,
        trailers: action.trailers || state.trailers,
        error: undefined,
      };
    }
    case ActionConstants.UPDATE_GLOBALTRAILER_ERROR: {
      return {
        ...state,
        status: ActionConstants.UPDATE_GLOBALTRAILER_ERROR,
        error: action.error,
      };
    }
    case ActionConstants.DISABLE_GLOBALTRAILERS_SUCCESS: {
      return {
        ...state,
        status: ActionConstants.DISABLE_GLOBALTRAILERS_SUCCESS,
        trailers: disableTrailers(state.trailers, action.trailers),
        error: undefined,
      };
    }
    default:
      return state;
  }
}
