// API
import moment from 'moment-timezone';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { updateInvoiceOutdated } from 'api/Dispatch/DispatchJob';
import { addDispatchDocument, getLatestDispatchDocument } from 'api/Dispatch/DispatchDocument';
import { getDispatchTransfers } from 'api/Dispatch/DispatchTransfer';
import { getDispatchReferenceNumbers } from 'api/Dispatch/DispatchReferenceNumber';
import { addDispatchReceivable, getDispatchReceivableByDispatchDocumentInvoice, updateDispatchReceivable, voidDispatchReceivable } from 'api/Dispatch/DispatchReceivable';
import { getDispatchJobAccountingRecords } from 'api/Dispatch/DispatchJobAccounting';
import { QueryRestrictionTypes, QuerySortOrderTypes, QuerySortOrder } from 'sb-csapi/dist/enums/Query';
import { createQuery, findRecords, getAttribute, getCurrentUser, getCurrentUserSessionToken, setQueryRestriction, sortQuery } from 'sb-csapi/dist/AAPI';
import { PDFExport } from '@progress/kendo-react-pdf';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { createFile } from 'api/PDF/PDFLocal';
import Sort from 'sb-csapi/dist/sbObjects/Sort';

// Context
import DispatchJobLayoutContext from 'contexts/DispatchJobLayoutContext';
import { DispatchDocumentsLayoutProvider } from 'contexts/DispatchDocumentsLayoutContext';

// CSAPI Enums
import { Document as DocumentTypes } from 'sb-csapi/dist/enums/Dispatch/Document';
import { PaymentStatus } from 'sb-csapi/dist/enums/Dispatch/PaymentStatus';

// ENUMS
/** @todo bring to csapi */
import { DispatchReferenceTypes } from 'enums/DispatchReference';

// Components
import DispatchDocumentSkeleton from 'components/Dispatch/DispatchDocumentSkeleton/DispatchDocumentSkeleton';
import DispatchDocumentHistoryTable from 'components/Dispatch/DispatchDocumentHistoryTable/DispatchDocumentHistoryTable';
import DispatchEmail from 'components/Dispatch/DispatchEmail/DispatchEmail';
import DispatchAllDocuments from 'components/Dispatch/DispatchAllDocuments/DispatchAllDocuments';

// sbCore Components
import Button from 'sbCore/Button/Button';
import OverlayPanel from 'sbCore/OverlayPanel/OverlayPanel';
import ProgessSpinner from 'sbCore/ProgressSpinner/ProgressSpinner';

// Document Generators
import Invoice from 'components/Dispatch/DispatchDocument/DispatchDocuments/Generators/Invoice/Invoice';
import BOL from 'components/Dispatch/DispatchDocument/DispatchDocuments/Generators/BOL/BOL';
import LoadConfirmation from 'components/Dispatch/DispatchDocument/DispatchDocuments/Generators/LoadConfirmation/LoadConfirmation';

// sbObjects
import DispatchDocument from 'sbObjects/DispatchDocument';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Styling
import './style.scss';

/**
 * @description Provides the top level layout for the dispatch documents. Hold the components for the document generation and document history
 * @param {Integer} dispatchDocumentTypeInt - the document type integer value
 * @param {String} dispatchJobObjectId - the dispatch job object id
 * @param {Boolean} isRefreshState - Boolean that checks if the states should be refreshed
 * @returns JSX layout
 */
function DispatchDocumentsLayout({ ...props }) {

  // ** Context ** //
  const { refreshJob, setDispatchDocumentTypesToSave, dispatchDocumentTypesToSave, setIsDocumentOutdatedPrompt } = useContext(DispatchJobLayoutContext);

  // ** useStates ** //
  const [dispatchDocumentTypeInt, setDispatchDocumentTypeInt] = useState(null);   // dispatch document type
  const [dispatchDocument, setDispatchDocument] = useState(undefined);            // dispatch document record
  const [dispatchDocumentId, setDispatchDocumentId] = useState('');               // id used to retrieve the correct document element
  const [dispatchTransfers, setDispatchTransfers] = useState(undefined);
  const [dispatchJob, setDispatchJob] = useState(undefined);                      // dispatchJob record
  const [documentState, setDocumentState] = useState({});                         // documentState gets passed to the child component and contains the state of a document in stringified JSON format
  const [isLoading, setIsLoading] = useState(true);

  const [isDownloaded, setIsDownloaded] = useState(false);                        // boolean that contains whether the document has been downloaded

  const [referenceNumbersStringObj, setReferenceNumbersStringObj] = useState({});

  // ** useRefs ** //
  const pdfExportComponent = useRef(null);
  const overlayPanelRef = useRef(null);

  function checkDocumentForUnsavedChanges(currentDocumentState = documentState) {
    if (!dispatchDocument) return false;

    // Grab the latest document with a state
    // Compare the current document state with the latest one found on the db
    // right now, do a simple equality check for stringedJSON
    // this is under the assumption that the stringedJSON has the properties laid out in the same order
    const latestDispatchDocumentStateJSONStringed = getAttribute(dispatchDocument, 'stateJSONStringed');
    if (latestDispatchDocumentStateJSONStringed === currentDocumentState) return false;
    return true;
  }

  async function _addDispatchDocument() {
    const currentUser = getCurrentUser();

    // Create empty dispatch document
    const dispatchDocumentObject = new DispatchDocument(
      undefined,                // objectId
      dispatchDocumentTypeInt,  // type
      undefined,                // fileURL
      moment.utc().toDate(),    // uploadedAtUTC
      currentUser,              // uploadedBy
      moment.utc().toDate(),    // modifiedAtUTC
      currentUser,              // modifiedBy
      dispatchJob,              // dispatchJob
      undefined,                // dispatchItem
    );

    try {
      const dispatchDocument = await addDispatchDocument(dispatchDocumentObject);
      return dispatchDocument;
    } catch (error) {
      // Catch errors creating document - could add in toast notification in the future
    }
  }

  // Attempts to find the latest dispatch document for this dispatch job and document type
  // If no document is found, creates a new document, and updates the state
  async function loadLatestDispatchDocument() {
    const latestDispatchDocument = await getLatestDispatchDocument(undefined, getAttribute(dispatchJob, 'objectId'), dispatchDocumentTypeInt);

    // Update the rest of the states
    const stateJSONStringed = latestDispatchDocument && getAttribute(latestDispatchDocument, 'stateJSONStringed');

    setDispatchDocument(latestDispatchDocument);
    setDocumentState(stateJSONStringed);
    setIsLoading(false);
  }

  // ** useEffects ** //

  // UseEffect runs whenever there is a change in the dispatchDocumentType
  // Could potentially add a further check to see if the document type passed in is one of the valid types
  useEffect(() => {
    setDispatchDocumentTypeInt(props.dispatchDocumentTypeInt);

    // If "all documents" tab is selected, don't continue
    if (props.dispatchDocumentTypeInt === 68) return;

    setIsLoading(true);
  }, [props.dispatchDocumentTypeInt]);

  // UseEffect runs whenever there is a change in the dispatchJobObjectId
  // Grabs the dispatchJob from the db
  useEffect(() => {
    if (!props.dispatchJobObjectId) return;

    let didCancel = false;

    async function generateReferenceNumbersStringObj() {
      const dispatchTransferLabelsObj = await createDispatchTransferLabels();
      const dispatchReferenceNumbersStringObj = await retrieveDispatchReferenceNumbersAsStrings(props.dispatchJobObjectId, dispatchTransferLabelsObj);
      setReferenceNumbersStringObj(dispatchReferenceNumbersStringObj);
    }

    generateReferenceNumbersStringObj();

    async function refresh() {
      const { dispatchTransfers } = await getDispatchTransfers(
        undefined, // options - default
        props.dispatchJobObjectId, // dispatchJobObjectId - default
        undefined, // filters
        new Sort(QuerySortOrder.ASCENDING, 'createdAt'), // sort - default
        [
          'consigneeDispatchOrganization',
          'consigneeDispatchOrganization.address',
          'shipperDispatchOrganization',
          'shipperDispatchOrganization.address',
        ], // includes
        undefined, // selects
        undefined, // page
        undefined, // limit
        true, // query all
      );

      if (!didCancel) {
        const sessionToken = getCurrentUserSessionToken();

        const dispatchJobQuery = createQuery('DispatchJob');
        setQueryRestriction(dispatchJobQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', props.dispatchJobObjectId);
        const dispatchJob = await findRecords({ sessionToken }, dispatchJobQuery, true, false); // Return the first result
        setDispatchJob(dispatchJob);

        setDispatchTransfers(dispatchTransfers);
      }
    }

    refresh();
    return (() => { didCancel = true; });
  }, [props.dispatchJobObjectId, props.isRefreshState]);

  // UseEffect triggers whenever there is a change in the dispatchDocumentTypeInt or dispatchJob
  // First, checks to see if the current document has unsaved changes
  // Then, it either prompts the user if we want to save changes, or it updates the document with the latest document from db
  useEffect(() => {
    let didCancel = false;

    // If "all documents" tab is selected, don't continue
    if (props.dispatchDocumentTypeInt === 68) return;

    async function refresh() {
      if (!didCancel) {
        const documentHasUnsavedChanges = checkDocumentForUnsavedChanges();

        if (documentHasUnsavedChanges) {
          // Stub
        } else {
          await loadLatestDispatchDocument();
        }
      }
    }

    if (dispatchDocumentTypeInt) {
      switch (dispatchDocumentTypeInt) {
        /* @todo missing DocumentTypes.BOL enum, use 1 to temporarily represent BOL */
        case 1:
          setDispatchDocumentId('bol');
          break;
        case DocumentTypes.INVOICE.type:
          setDispatchDocumentId('invoice');
          break;
        case DocumentTypes.LOAD_CONFIRMATION.type:
          setDispatchDocumentId('load_confirmation');
          break;
        default:
          setDispatchDocumentId('undefined');
      }
    }

    if (dispatchDocumentTypeInt && dispatchJob) refresh();
    return (() => { didCancel = true; });
  }, [dispatchDocumentTypeInt, dispatchJob]);

  // Enables/Disables selecting document tabs depending on the isDownloaded state
  useEffect(() => {
    const dispatchDocumentTabsElement = document.getElementById('dispatch-documents-tab');

    if (!dispatchDocumentTabsElement) return;

    if (isDownloaded) {
      dispatchDocumentTabsElement.classList.add('dispatch-document-disabled-tabs');
      return;
    }

    dispatchDocumentTabsElement.classList.remove('dispatch-document-disabled-tabs');
  }, [isDownloaded]);

  // ** Functions ** //
  // Generates a new document with the documentState passed as a parameter and loads the new document. If an invoice, create a new DispatchReceivable as well.
  async function downloadDispatchDocument(documentStateObj) {
    // Stringify the document state
    const stringedJSON = JSON.stringify(documentStateObj);

    // Creates a new dispatch document record with the document information
    const gridElement = document.getElementById(dispatchDocumentId);
    if (!gridElement) return;

    const drawRoot = await drawDOM(gridElement, { paperSize: 'A4', margin: 0, scale: 0.5 });
    const dataUri = await exportPDF(drawRoot);
    const file = await createFile(dispatchDocumentId, { base64: dataUri });

    const dispatchDocumentObj = {
      file: file.source,
      type: dispatchDocumentTypeInt,
      uploadedAtUTC: moment.utc().toDate(),
      dispatchJob,
      uploadedBy: getCurrentUser(),
      stateJSONStringed: stringedJSON,
    };

    // Create the new DispatchDocument
    const newDispatchDocument = await addDispatchDocument(dispatchDocumentObj);

    // Downloads the document on the user's end
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }

    // If the document is an invoice, create a new DispatchReceivable for it and update the DispatchJob's isInvoiceOutdated
    if (props.dispatchDocumentTypeInt === DocumentTypes.INVOICE.type) {
      if (dispatchDocument) {
        const currentDispatchReceivable = await getDispatchReceivableByDispatchDocumentInvoice(undefined, dispatchDocument);
        if (currentDispatchReceivable) {
          const user = getCurrentUser();
          const username = getAttribute(user, 'username', true);
          const currentDateTime = moment().format('DD-MM-YYYY HH:mm');
          const message = `- Receivable voided by ${username} on ${currentDateTime}`;
          await voidDispatchReceivable(currentDispatchReceivable, message);
        }
      }

      // Format the invoicedDateTime, retrieve the DispatchJobAccounting record, and create the new DispatchReceivable
      const invoicedDateTime = documentStateObj.invoiceDate ? moment(documentStateObj.invoiceDate, 'DD-MM-YYYY HH:mm').toDate() : undefined;
      const { dispatchJobAccountings } = await getDispatchJobAccountingRecords(
        undefined,
        undefined,
        undefined,
        [new Filter(QueryRestrictionTypes.EQUAL_TO, 'dispatchJob', props.dispatchJobObjectId)],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      );
      let dispatchJobAccounting;
      if (dispatchJobAccountings && dispatchJobAccountings.length > 0) {
        dispatchJobAccounting = dispatchJobAccountings[0];
      }

      const dispatchReceivableObj = {
        dispatchDocumentInvoice: newDispatchDocument,
        invoicedDateTime,
        status: PaymentStatus.UNPAID.status,
        dispatchJobAccounting,
        isVoided: false,
        paymentTermType: documentStateObj.paymentTermType,
      };
      await addDispatchReceivable(dispatchReceivableObj);

      await updateInvoiceOutdated(dispatchJob, false);

      // Refresh the job so that invoice prompt is toggled off
      await refreshJob();
    }

    // Reset isDownloaded and stringedJSON for possible future downloads
    await loadLatestDispatchDocument();
  }

  function generateDocumentPDF() {
    // Stub
  }

  function emailDocument() {
    generateDocumentPDF();
  }

  function updateDocumentState(stateJSONStringed) {
    setDocumentState(stateJSONStringed);
  }

  function displayDocumentHistory(e) {
    overlayPanelRef.current.toggle(e);
  }

  // Returns a shipment label relative to the given reference number and the transfer it's linked to
  function updateReferenceNumberShipmentLabel(dispatchReferenceNumber, dispatchTransferLabelsObj) {
    let shipmentLabel = '';
    const dispatchTransfer = getAttribute(dispatchReferenceNumber, 'dispatchTransfer');
    if (dispatchTransfer) {
      const dispatchTransferObjectId = getAttribute(dispatchTransfer, 'objectId');

      for (const [key, value] of Object.entries(dispatchTransferLabelsObj)) {
        if (dispatchTransferObjectId === value) {
          shipmentLabel = key;
          break;
        }
      }
    }

    return shipmentLabel;
  }

  // Filters an array of reference numbers given the reference number type and whether or not the associated transfer is internal
  function filterDispatchReferenceNumbers(dispatchReferenceNumbers, referenceNumberType) {
    const filteredDispatchReferenceNumbers = dispatchReferenceNumbers.filter((dispatchReferenceNumber) => {
      const dispatchTransfer = getAttribute(dispatchReferenceNumber, 'dispatchTransfer');
      let isDispatchTransferInternal = false;
      if (dispatchTransfer) {
        isDispatchTransferInternal = getAttribute(dispatchTransfer, 'internal');
      }

      const type = getAttribute(dispatchReferenceNumber, 'type');
      return ((type === referenceNumberType) && !isDispatchTransferInternal);
    });

    return filteredDispatchReferenceNumbers;
  }

  // Formats an array of reference numbers into a string separated by commas
  function formatReferenceNumbers(dispatchReferenceNumbers, dispatchTransferLabelsObj) {
    let dispatchReferenceNumbersString;

    dispatchReferenceNumbers.forEach((dispatchReferenceNumber) => {
      const referenceNumberValue = getAttribute(dispatchReferenceNumber, 'value');

      const shipmentLabel = updateReferenceNumberShipmentLabel(dispatchReferenceNumber, dispatchTransferLabelsObj);

      if (!dispatchReferenceNumbersString) {
        dispatchReferenceNumbersString = shipmentLabel ? `${referenceNumberValue} (${shipmentLabel})` : referenceNumberValue;
      } else {
        dispatchReferenceNumbersString = (
          dispatchReferenceNumbersString.concat(
            ', ', shipmentLabel ? `${referenceNumberValue} (${shipmentLabel})` : referenceNumberValue,
          )
        );
      }
    });

    return dispatchReferenceNumbersString;
  }

  // Formats an array of custom reference numbers into a string separated by commas
  function formatCustomReferenceNumbers(customReferenceNumbers, dispatchTransferLabelsObj) {
    const customReferenceNumberNamesObj = {};
    let customReferenceNumbersString = '';

    // Formats the reference numbers into strings and organizes them into an object sorted by custom names
    customReferenceNumbers.forEach((customReferenceNumber) => {
      const customReferenceNumberName = getAttribute(customReferenceNumber, 'customName');
      const customReferenceNumberValue = getAttribute(customReferenceNumber, 'value');

      const shipmentLabel = updateReferenceNumberShipmentLabel(customReferenceNumber, dispatchTransferLabelsObj);

      if (Object.prototype.hasOwnProperty.call(customReferenceNumberNamesObj, customReferenceNumberName)) {
        customReferenceNumberNamesObj[customReferenceNumberName] = (
          customReferenceNumberNamesObj[customReferenceNumberName].concat(
            ', ', shipmentLabel ? `${customReferenceNumberValue} (${shipmentLabel})` : customReferenceNumberValue,
          )
        );
      } else {
        customReferenceNumberNamesObj[customReferenceNumberName] = (
          shipmentLabel
            ? `${customReferenceNumberValue} (${shipmentLabel})`
            : customReferenceNumberValue
        );
      }
    });

    // Formats the reference numbers grouped by custom name together in a string
    for (const [key, value] of Object.entries(customReferenceNumberNamesObj)) {
      if (!customReferenceNumbersString) {
        customReferenceNumbersString = `${key}: ${value}`;
      } else {
        customReferenceNumbersString = customReferenceNumbersString.concat('\n', `${key}: ${value}`);
      }
    }

    return customReferenceNumbersString;
  }

  // Formats and groups a job's reference numbers into a single string and groups them all into an object
  async function retrieveDispatchReferenceNumbersAsStrings(dispatchJobObjectId, dispatchTransferLabelsObj) {
    const dispatchReferenceNumbers = await getDispatchReferenceNumbers(
      undefined, // options
      dispatchJobObjectId, // dispatchJobObjectId
      undefined, // filters
      undefined, // sort
      ['dispatchTransfer'], // includes
      undefined, // selects
      undefined, // page
      undefined, // limit
      true, // query all
    );

    let bolReferenceNumbersString = '';
    let otherJobReferenceNumbersString = '';
    let customReferenceNumbersString = '';
    let customerReferenceNumbersString = '';

    // Filter and format the reference numbers
    if (dispatchReferenceNumbers) {
      const bolReferenceNumbers = filterDispatchReferenceNumbers(dispatchReferenceNumbers, DispatchReferenceTypes.BOL.value);
      if (bolReferenceNumbers) bolReferenceNumbersString = formatReferenceNumbers(bolReferenceNumbers, dispatchTransferLabelsObj);

      const otherJobReferenceNumbers = filterDispatchReferenceNumbers(dispatchReferenceNumbers, DispatchReferenceTypes.JOB.value);
      if (otherJobReferenceNumbers) otherJobReferenceNumbersString = formatReferenceNumbers(otherJobReferenceNumbers, dispatchTransferLabelsObj);

      const customReferenceNumbers = filterDispatchReferenceNumbers(dispatchReferenceNumbers, DispatchReferenceTypes.CUSTOM.value);
      if (customReferenceNumbers) customReferenceNumbersString = formatCustomReferenceNumbers(customReferenceNumbers, dispatchTransferLabelsObj);

      const customerReferenceNumbers = filterDispatchReferenceNumbers(dispatchReferenceNumbers, DispatchReferenceTypes.CUSTOMER.value);
      if (customerReferenceNumbers) customerReferenceNumbersString = formatReferenceNumbers(customerReferenceNumbers, dispatchTransferLabelsObj);
    }

    const dispatchReferenceNumbersStringObj = {
      [DispatchReferenceTypes.BOL.key]: bolReferenceNumbersString,
      [DispatchReferenceTypes.JOB.key]: otherJobReferenceNumbersString,
      [DispatchReferenceTypes.CUSTOM.key]: customReferenceNumbersString,
      [DispatchReferenceTypes.CUSTOMER.key]: customerReferenceNumbersString,
    };

    return dispatchReferenceNumbersStringObj;
  }

  // For each transfer, add them into an object with the key being its shipment index and its value being its object id
  async function createDispatchTransferLabels() {
    let shipmentIndex = 1;
    const dispatchTransferLabelsObj = {};
    if (dispatchTransfers && dispatchTransfers.length > 0) {
      for (let i = 0; i < dispatchTransfers.length; i++) {
        const dispatchTransfer = dispatchTransfers[i];
        const isDispatchTransferInternal = getAttribute(dispatchTransfer, 'internal');
        if (isDispatchTransferInternal) return;

        const dispatchTransferObjectId = getAttribute(dispatchTransfer, 'objectId');
        dispatchTransferLabelsObj[`Shipment#${shipmentIndex}`] = dispatchTransferObjectId;
        shipmentIndex++;
      }
    }

    return dispatchTransferLabelsObj;
  }

  /**
   * Only saves the document state for whichever document is displayed
   * @todo Add functionality to save the document state for all 3 documents
   */
  async function saveDispatchDocumentState(currentDispatchDocumentStateObj) {
    setIsDocumentOutdatedPrompt(false);

    // Stringify the document state
    const stringedJSON = JSON.stringify(currentDispatchDocumentStateObj);

    // Creates a new dispatch document record with the document information
    const gridElement = document.getElementById(dispatchDocumentId);
    if (!gridElement) return;

    // Create file to save in the database
    const drawRoot = await drawDOM(gridElement, { paperSize: 'A4', margin: 0, scale: 0.5 });
    const dataUri = await exportPDF(drawRoot);
    const file = await createFile(dispatchDocumentId, { base64: dataUri });

    const dispatchDocumentObj = {
      file: file.source,
      type: dispatchDocumentTypeInt,
      uploadedAtUTC: moment.utc().toDate(),
      dispatchJob,
      uploadedBy: getCurrentUser(),
      stateJSONStringed: stringedJSON,
    };

    // Create the new DispatchDocument
    const newDispatchDocument = await addDispatchDocument(dispatchDocumentObj);

    // Remove current dispatchDocumentTypeInt from the array to indicate it's been saved
    // setDispatchDocumentTypesToSave(dispatchDocumentTypesToSave.filter(dispatchDocumentType => dispatchDocumentType != dispatchDocumentTypeInt));

    // Removing one document type causes this function to trigger when user switch tabs to the other 2 documents since the other 2 documentTypeInt are still in the array
    // Instead of removing just one document type, set the state to an empty array
    setDispatchDocumentTypesToSave([]);
  }

  // ** Misc ** //
  let generateDocumentLabel = 'Generate Document';
  switch (dispatchDocumentTypeInt) {
    case 1: /** @todo missing DocumentTypes.BOL enum, use 1 to temporarily represent BOL */
      generateDocumentLabel = 'Generate BOL';
      break;
    case DocumentTypes.INVOICE.type:
      generateDocumentLabel = `Generate ${DocumentTypes.INVOICE.description}`;
      break;
    case DocumentTypes.LOAD_CONFIRMATION.type:
      generateDocumentLabel = `Generate ${DocumentTypes.LOAD_CONFIRMATION.description}`;
      break;
    default:
  }

  return (
    <DispatchDocumentsLayoutProvider
      value={{
        saveDispatchDocumentState: (currentDispatchDocumentStateObj) => saveDispatchDocumentState(currentDispatchDocumentStateObj),
        checkDocumentForUnsavedChanges: (currentDocumentState) => checkDocumentForUnsavedChanges(currentDocumentState),
      }}
    >
      <div>
        <div className="dispatch-document-container flex flex-column xl:flex-row pt-4">
          <div className="document-generation flex flex-column align-items-center">
            {dispatchDocumentTypeInt !== 68 && (
              <>

                <div className="flex flex-column justify-content-center lg:justify-content-end xl:flex-row w-full lg:w-10 p-2">
                  {(isLoading || isDownloaded) && (
                    <ProgessSpinner className="document-loading" strokeWidth={3} />
                  )}
                  <Button icon="pi pi-download" className="mx-2 my-1 xl:my-0 document-button" label={generateDocumentLabel} disabled={isLoading || isDownloaded} onClick={() => setIsDownloaded(true)} />
                  <DispatchEmail className="mx-2 my-1 xl:my-0 document-button" dispatchJobObjectId={props.dispatchJobObjectId} isLoading={isLoading || isDownloaded} />
                  <Button icon="pi pi-list" className="mx-2 my-1 xl:my-0 document-button" label="Document History" disabled={isLoading || isDownloaded} onClick={(e) => displayDocumentHistory(e)} />
                </div>

                <div className="p-3 flex justify-content-center w-full">
                  {isLoading && (
                    <DispatchDocumentSkeleton />
                  )}
                  {!isLoading && (
                    <div className="pdf-export">
                      <PDFExport
                        ref={pdfExportComponent}
                        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                        scale={0.5}
                        paperSize="A4"
                      >
                        {/** @todo missing DocumentTypes.BOL enum, use 1 to temporarily represent BOL */}
                        {(dispatchDocumentTypeInt === 1) && (
                          <BOL
                            dispatchJobObjectId={props.dispatchJobObjectId}
                            documentState={documentState}
                            isDownloaded={isDownloaded}
                            dispatchTransfers={dispatchTransfers}
                            toggleDownloaded={(value) => setIsDownloaded(value)}
                            downloadDispatchDocument={downloadDispatchDocument}
                            referenceNumbersStringObj={referenceNumbersStringObj}
                            dispatchJob={props.dispatchJob}
                            isRefreshState={props.isRefreshState}
                            generalInformationState={props.generalInformationState}
                          />
                        )}

                        {(dispatchDocumentTypeInt === DocumentTypes.INVOICE.type) && (
                          <Invoice
                            dispatchJobObjectId={dispatchJob && getAttribute(dispatchJob, 'objectId')}
                            dispatchDocument={dispatchDocument}
                            documentState={documentState}
                            isDownloaded={isDownloaded}
                            toggleDownloaded={(value) => setIsDownloaded(value)}
                            downloadDispatchDocument={downloadDispatchDocument}
                            referenceNumbersStringObj={referenceNumbersStringObj}
                            isRefreshState={props.isRefreshState}
                            dispatchJob={props.dispatchJob}
                            generalInformationState={props.generalInformationState}
                          />
                        )}

                        {(dispatchDocumentTypeInt === DocumentTypes.LOAD_CONFIRMATION.type) && (
                          <LoadConfirmation
                            dispatchJobObjectId={props.dispatchJobObjectId}
                            documentState={documentState}
                            isDownloaded={isDownloaded}
                            dispatchTransfers={dispatchTransfers}
                            toggleDownloaded={(value) => setIsDownloaded(value)}
                            downloadDispatchDocument={downloadDispatchDocument}
                            referenceNumbersStringObj={referenceNumbersStringObj}
                            isRefreshState={props.isRefreshState}
                            dispatchJob={props.dispatchJob}
                          />
                        )}
                      </PDFExport>
                    </div>
                  )}
                </div>

              </>
            )}

            {dispatchDocumentTypeInt === 68 && (
              <DispatchAllDocuments dispatchJob={dispatchJob} />
            )}
          </div>

          <OverlayPanel innerRef={overlayPanelRef} showCloseIcon dismissable>
            <div className="document-history flex flex-column">
              <DispatchDocumentHistoryTable
                dispatchDocumentTypeInt={dispatchDocumentTypeInt}
                dispatchJobObjectId={dispatchJob && getAttribute(dispatchJob, 'objectId')}
              />
            </div>
          </OverlayPanel>

        </div>
      </div>
    </DispatchDocumentsLayoutProvider>
  );
}

export default DispatchDocumentsLayout;
