import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MDBNav, MDBNavItem, MDBNavLink, MDBBadge, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact';

import './style.scss';

class SubNavigationBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {};
  }

  render() {
    const { navItems, containerClassName } = this.props

    let _containerClassName = 'sub-navigation-bar nav-pills nav-fill';
    if (containerClassName) _containerClassName += ` ${containerClassName}`;

    let navLinks = navItems.map((navItem, index) => {
      let className = `pl-0 pr-2 ${navItem.className}`;
      navItem.isActive ? className += ' child-active' : className;



      return (
        <MDBNavItem key={index + 'main'} className={className}>
          { navItem.isDropdown && 
            <MDBDropdown>
              <MDBDropdownToggle nav caret>
                { navItem.name }
              </MDBDropdownToggle>
              <MDBDropdownMenu color="default" basic>
                { navItem.dropdownItems.map((dropdownItem, index) => {
                  return (
                    <MDBNavLink key={index + dropdownItem.name + 'navLink'}
                      to={dropdownItem.to}
                      onClick={dropdownItem.onClick}
                    >
                      <MDBDropdownItem> 
                        { dropdownItem.name }
                      </MDBDropdownItem>
                    </MDBNavLink>
                  )
                })}
              </MDBDropdownMenu>
            </MDBDropdown>
          }
          { !navItem.isDropdown &&
            <MDBNavLink
              className={`w-100 h-100 d-table translate-me`}
              active={navItem.isActive}
              onClick={navItem.onClick}
              to={navItem.to}
              disabled={navItem.disabled}
            >
              <span className="d-table-cell align-middle">{ navItem.name }</span>
              { navItem.badge && (
                <MDBBadge
                  color={navItem.badge.color}
                  className={navItem.badge.className ? `nav-badge ${navItem.badge.className}` : 'nav-badge'}
                >
                  { navItem.badge.text }
                </MDBBadge>
              )}
            </MDBNavLink>
          }
        </MDBNavItem>
      )
    })
    return <MDBNav className={_containerClassName}>{navLinks}</MDBNav>
  }
}

/**
 * * Requires to be wrapped in MDBRow/MDBCol for styles to appear properly (bc of MDBNavItems)
 * navItems: array of objects
 *      - name (string)
 *      - className (string)
 *      - onClick (function)
 *      - isActive (boolean)
 *      - to (string)
 *      - disabled (bool)
 *      - badge (object)
 *          - text (string)
 *          - color (string) - MDB bootstrap colors
 *          - className (string)
 */
SubNavigationBar.propTypes = {
  containerClassName: PropTypes.string,
  navItems: PropTypes.array.isRequired
}

export default SubNavigationBar
