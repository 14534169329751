import React from 'react';

import { TieredMenu as PRTieredMenu } from 'primereact/tieredmenu';

import './style.scss';

function TieredMenu({ ...props }) {
  let className = 'sbc-tiered-menu';

  return (
    <PRTieredMenu className={className} {...props} />
  );
};

export default TieredMenu;
