import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import history from 'sbHistory';

// API
import { getCurrentUser, getAttribute } from 'api/Parse';
import { getReverseGeocode } from 'api/Mapbox';
import { retrieveAndSaveDetailedReverseGeocode } from 'api/VehicleLocation/VehicleLocation';
import * as Helpers from 'api/Helpers';
// sbCore
import Card from 'sbCore/Card/Card';
import DutyStatusBadge from 'sbCore/DutyStatusBadge/DutyStatusBadge';
import Alert from 'sbCore/Alert/Alert';
import Button from 'sbCore/Button/Button';

//sb-csapi
import { getLocationDescriptionBreakdown } from 'sb-csapi/dist/api/VehicleLocation/VehicleLocation';
import { formatName } from 'sb-csapi/dist/utils/String';

// Components
import HistoryComponent from './HistoryComponent';
import TripListComponent from './TripListComponent';
import InputSwitch from 'sbCore/InputSwitch/InputSwitch';

// enums
import { AlertStatus } from 'sb-csapi/dist/enums/Notification/AlertStatus';

// CSS
import './style.scss';

/**
 * @description Renders the active trailer/vehicle view card when a vehicle is selected on the sidebar
 * @param {object} activeVehicleTrailerObj - An object containing all the necessary information to be displayed on the card
 * @param {Function} getLinkShare - Generate a link to track the current vehicle/trailer
 * @param {Function} toggleGPSHistory - Toggles GPS hisotry view on and off
 * @param {Function} zoomToActive - Zoom in on the current vehicle/trailer
 * @param {Function} setActiveEquipmentInformation - Upload the current vehicle/trailer's information
 * @param {bool} isSidebarOpen - Wheather if the side bar is open or not
 * @returns
 *
 * @example
 * <VehicleTrailerActiveView
 *  activeVehicleTrailerObj={activeVehicleTrailerObj}
 *  getLinkShare={() => getLinkShare()}
 *  toggleGPSHistory={() => toggleGPSHistory()}
 *  zoomToActive={() => zoomToActive()}
 *  setActiveEquipmentInformation={(equipmentInformation) => setActiveEquipmentInformation(equipmentInformation)}
 *  isSidebarOpen={isSidebarOpen}
 * />
 */

function VehicleTrailerActiveView(props) {
  const {
    objectId,
    trailerTemperatureCelsius,
    isIdling,
    isSpeeding,
    drivers,
    unitId,
    eldStatusInt,
    locationDescription,
    lastUpdated,
    speedLimitStr,
    speedStr,
    speedKm,
    gpsHeadingDirection,
    gpsHeading,
    coordinates: {
      latitude,
      longitude,
    }
  } = props.activeVehicleTrailerObj;

  const [showNewGPSHistory, setShowNewGPSHistory] = useState(true);
  const [reverseGeocodeAddress, setReverseGeocodeAddress] = useState(null);

  useEffect(() => {
    setReverseGeocodeAddress(null);
    async function getAndSetReverseGeocode(longitude, latitude) {
      const coordinates = props?.activeVehicleTrailerObj?.coordinates;
      if (coordinates) {
        const { longitude, latitude } = coordinates;
        if (longitude && latitude) {
          const detailedReverseGeocodeAddress = await retrieveAndSaveDetailedReverseGeocode(
            props?.activeVehicleTrailerObj?.locationObj?.objectId,
            props?.activeVehicleTrailerObj?.locationObj?.className,
            longitude,
            latitude
          );
          setReverseGeocodeAddress(detailedReverseGeocodeAddress);
        }
      }
    }
    getAndSetReverseGeocode();
  }, [props?.activeVehicleTrailerObj?.coordinates?.latitude]);

  useEffect(() => {
    setShowNewGPSHistory(props.showGPSHistory);
    props.toggleNewGPSHistory(props.showGPSHistory);
  }, [props.showGPSHistory]);

  // Move active vehicle/trailer view to middle of the screen if sidebar is closed
  let className = 'active-card-container';
  if (props.isSidebarOpen) {
    className += ' sidebar-open';
  } else {
    className += ' sidebar-closed';
  }

  // Generate alert int and description
  let alertStatusInt;
  let alertDescription;

  if (isIdling) {
    alertStatusInt = AlertStatus.WARNING;
    alertDescription = `${unitId} is currently idling`;
  }

  if (isSpeeding) {
    alertStatusInt = AlertStatus.ERROR;
    alertDescription = `${unitId} is speeding above ${speedLimitStr}`;
  }


  // Format location
  const locationDescriptionBreakdown = locationDescription && getLocationDescriptionBreakdown(locationDescription);

  let _locationDescription = '-';
  if (locationDescriptionBreakdown) {
    _locationDescription = `${locationDescriptionBreakdown.city}, ${locationDescriptionBreakdown.stateProvince.code}`;
  }
  // Format last updated
  let _lastUpdated = '-';
  const lastUpdatedMoment = lastUpdated && moment(lastUpdated);
  if (lastUpdatedMoment) {
    _lastUpdated = lastUpdatedMoment.format('YYYY-MM-DD @ HH:mm');
  }

  // if there is no last location data, make the location UI formatting look nicer by making _lastUpdated an empty string
  if (!locationDescription && !lastUpdated) _lastUpdated = '';

  // Title of the card -> Unit ID, duty status badge and alert
  const title = (
    <div className="flex flex-row align-items-center column-gap-2 ml-5 pt-2">
      <div className="mr-3">
        {unitId || '-'}
      </div>
      <div className="flex column-gap-2">
        {eldStatusInt &&
          <DutyStatusBadge eldStatusInt={eldStatusInt} className="h-2rem" />
        }
        {alertStatusInt !== undefined &&
          <Alert alertStatusInt={alertStatusInt} alertDescription={alertDescription} className="h-full border-round-2xl" />
        }
      </div>
      <div className="flex column-gap-2">
        {drivers &&
          (
            <div className={`flex flex-row align-items-center ml-5 ${drivers}`} style={{ gap: '1rem' }}>
              <div className="font-semibold">
                <span className="material-icons mr-1">person</span>
                <span>{drivers?.length > 0 && (
                  <a
                    className="driver-link text-sm"
                    href={`driver?driver=${drivers[0].driverObjectId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>
                      {formatName(drivers[0].fullName)}
                    </span>
                  </a>
                ) || '-'}</span>
              </div>
              <div className="font-semibold">
                <span className="material-icons mr-1">people</span>
                <span>{drivers?.length > 1 && (
                  <a
                    className="driver-link text-sm"
                    href={`driver?driver=${drivers[1].driverObjectId}`}
                  >
                    <span
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`driver?driver=${drivers[1].driverObjectId}`)
                      }}>
                      {formatName(drivers[1].fullName)}
                    </span>
                  </a>
                ) || '-'}</span>
              </div>
            </div>
          )
        }
      </div>
      <div
        className="ml-auto mr-5 cursor-pointer"
        onClick={(event) => {
          props.setActiveEquipmentInformation(undefined);
          props.setActiveEquipmentInformation(undefined);
        }}
        onKeyPress={(event) => {
          if (event.nativeEvent.key === ' ') {
            props.setActiveEquipmentInformation(undefined);
          }
        }}
      >
        <i className="pi pi-times font-semibold" />
      </div>
    </div>
  )

  // Subtitle -> driver names for vehicle or temperature for trailers
  const subTitle = (
    <div className="subtitle-container">
      <div className={`flex flex-row align-items-center`}>
        <div className="flex flex-column row-gap-2 mr-auto pl-5">
          <div className="flex flex-column w-full">
            <div className="text-sm font-semibold">
              <a onClick={() => getStreetView(longitude, latitude)} className="cursor-pointer">
                {reverseGeocodeAddress || _locationDescription}
              </a>
            </div>
            <div className="text-xs">
              {_lastUpdated}
            </div>
          </div>
        </div>
      </div>
    </div>

  )

  // Temperature report with trailer id
  function goToTemperatureReport(trailerId) {
    history.push({ pathname: 'equipment', search: 'view=trailers&t=temperature&id=' + trailerId });
  }

  // Get street view with given latitude and longitude
  function getStreetView(long, lat) {
    window.open(`https://maps.google.com/maps?q=${lat},${long}&t=h&iwd=0&z=18`);
  }

  const currentUser = getCurrentUser();
  const userSpecialPermission = getAttribute(currentUser, 'userSpecialPermission');
  // if the company is not granted access to the driver module
  const disableMapSpeed = userSpecialPermission && getAttribute(userSpecialPermission, 'disableMapSpeed');
  const body = (
    <div>
      <div className="flex flex-row align-items-center">
        {/* First Column */}
        <div className="flex-1 flex-column flex align-items-center">
          {/* container */}
          <div className="flex flex-column row-gap-2 mr-auto pl-5">
            {props.showGPSHistory &&
              (
                <HistoryComponent
                  activeVehicle={props.activeVehicle}
                  activeVehicleHistory={props.activeVehicleHistory}
                  handleDateFilterChange={props.handleDateFilterChange}
                  togglePath={props.togglePath}
                  toggleTripStops={props.toggleTripStops}
                  toggleReplay={props.toggleReplay}
                  replay={props.replay}
                  downloadHistoryCsv={props.downloadHistoryCsv}
                />
              )
            }
            {!props.showGPSHistory &&
              (
                <div>
                  {/* First column first row */}
                  {/* <div className="flex flex-row align-items-center">
              <span className="material-icons mr-2 vertical-align-middle text-3xl">place</span>
              <div className="flex flex-column w-full">
                <div className="text-sm font-semibold">
                  {reverseGeocodeAddress || _locationDescription}
                </div>
                <div className="text-xs">
                  {_lastUpdated}
                </div>
              </div>
            </div> */}
                  {/* First column second row */}
                  {!disableMapSpeed &&
                    <div className="font-semibold">
                      <span className="material-icons mr-2 vertical-align-middle text-3xl">speed</span>
                      <span className="text-sm vertical-align-middle">{speedKm ? speedStr : 'Not Moving'}</span>
                    </div>
                  }
                  {/* First column third row */}
                  <div className="font-semibold">
                    <span className="material-icons mr-2 text-3xl vertical-align-middle">near_me</span>
                    <span className="text-sm vertical-align-middle">{speedKm ? (gpsHeadingDirection || ``) : 'Not Moving'}</span>
                  </div>
                  {/* column */}
                  {trailerTemperatureCelsius &&
                    (
                      <div className="font-semibold">
                        <span className="material-icons mr-2 text-3xl vertical-align-middle">device_thermostat</span>
                        <span className="text-sm vertical-align-middle">{Math.round(Helpers.convertTemperature(trailerTemperatureCelsius, 'c', 'f') * 10) / 10}&#176; Farenheit</span>
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
        </div>
        {/* Second column */}
        <div className="flex-1 flex-column flex align-items-center border-x-1 border-gray-300 pt-2 pb-2">
          {props.showGPSHistory &&
            (
              <TripListComponent
                activeVehicle={props.activeVehicle}
                activeVehicleHistory={props.activeVehicleHistory}
              />
            )
          }
          {/* container */}
          {!props.showGPSHistory &&
            (
              <div className="flex flex-column row-gap-2">
                {/* Second column first row */}
                <div onClick={() => props.zoomToActive()} className="cursor-pointer">
                  <span className="material-icons mr-2 text-3xl vertical-align-middle">my_location</span>
                  <span className="clickable-link font-semibold text-sm vertical-align-middle">Zoom to Equipment</span>
                </div>
                {/* Second column second row */}
                {lastUpdated &&
                  <div onClick={() => props.getLinkShare(props.activeVehicleTrailerObj?.isTrailer ? 'asset' : null)} className="cursor-pointer">
                    <span className="material-icons mr-2 text-3xl vertical-align-middle">insert_link</span>
                    <span className="clickable-link font-semibold text-sm vertical-align-middle">Share Location/Tracking</span>
                  </div>
                }
                {/* Second column third row */}
                <div onClick={() => getStreetView(longitude, latitude)} className="cursor-pointer">
                  <span className="material-icons mr-2 text-3xl vertical-align-middle">map</span>
                  <span className="clickable-link font-semibold text-sm vertical-align-middle">Google Maps Link</span>
                </div>
              </div>
            )
          }
        </div>
        {/* Third column */}
        <div className="flex-1 flex-column flex align-items-center">
          {/* container */}
          <div className="flex flex-column" style={{ gap: '1rem' }}>
            {/* Third column first row */}
            <div className="flex flex-column gap-2">
              <Button
                label={`${props.showGPSHistory ? 'Hide GPS History' : 'Show GPS History'}`}
                icon={<span className="material-icons mr-2">history</span>}
                onClick={() => { props.toggleGPSHistory() }}
                className="p-button-sm w-full"
              />
              {props.showGPSHistory &&
                <div className="flex flex-row align-items-center justify-content-between">
                  <span className="text-sm mr-2">New Trip List</span>
                  <InputSwitch
                    checked={showNewGPSHistory}
                    onChange={() => {
                      setShowNewGPSHistory(!showNewGPSHistory);
                      props.toggleNewGPSHistory(!showNewGPSHistory)
                    }}
                  />
                </div>
              }
            </div>
            {/* Third column second row */}
            {props.activeVehicleTrailerObj?.isTrailer && unitId &&
              <div>
                <Button
                  label="Temp Report"
                  icon={<span className="material-icons mr-2">ac_unit</span>}
                  onClick={() => goToTemperatureReport(objectId)}
                  className="p-button-sm w-full temp-report-btn"
                />
              </div>
            }
          </div>
        </div>
      </div >
    </div >
  );

  return (
    <div className={className}>
      <Card title={title} subTitle={subTitle}>
        {body}
      </Card>
    </div>
  );
}

export default VehicleTrailerActiveView;
