import moment from 'moment-timezone';
import { addRecord, copyQuery, count, createQuery, createQueryOr, createTempPointer, createTempRecord, find, getAttribute, getCurrentUser, getObjectById, includePointers, setQueryRestriction, sortQuery, updateRecord, destroyRecord } from 'api/Parse';
import { removePricing } from 'api/Pricing';
import Sort from 'sbObjects/Sort';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { addToAddressbook } from 'api/Address';
import { getDispatchItem, getDispatchItems } from 'api/Dispatch/DispatchItem.old';

/**
 * Fetches all DispatchTransfer objects that belong to any of the DispatchItems
 * @param {string} dispatchItemObjectIds The objectIds of the DispatchItems for which we are fetching transfer objects
 *
 * @param {object} sortBy - Sort - only applies to case where a dispatchAction query is matched to transfer query
 * @param {array} filters - array of Filter objects
 */
async function getDispatchItemTransfersByJob(dispatchJobObjectId, sortBy, filters = []) {
  const transferQuery = createQuery('DispatchTransfer');
  if (!dispatchJobObjectId) {
    throw new Error('No dispatchJobObjectId provided');
  }
  const dispatchItems = await getDispatchItems(dispatchJobObjectId);
  if (!dispatchItems.length) { return []; }
  const dispatchItemObjectIds = dispatchItems.map(dispatchItem => {
    return getAttribute(dispatchItem, 'objectId');
  });
  setQueryRestriction(transferQuery, QueryRestrictionTypes.CONTAINED_IN, 'dispatchItem', dispatchItemObjectIds);
  if (sortBy) {
    sortQuery(transferQuery, sortBy.order, sortBy.attribute);
  }
  // apply filter here - this may need to be expanded in future (include filtering of properties not directly in dispatchtransfer)
  filters.map(filter => {
    setQueryRestriction(transferQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
  });
  includePointers(transferQuery, [
    'pickupDispatchAction',
    'pickupDispatchAction.address',
    'dropoffDispatchAction',
    'dropoffDispatchAction.address',
    'dispatchCarrier',
    'dispatchCarrier.address',
    'dispatchCarrier.location',
    'carrierPricing',
    'dispatchItem',
    'dispatchItem.dispatchJob',
    'dispatchItem.quotePricing',
    'dispatchItem.declaredValuePricing',
  ]);
  const dispatchTransfers = await find(transferQuery, false, true);
  // sort all dispatchTransfers by the pickup/dropoff action date before returning
  dispatchTransfers.sort((dispatchTransferA, dispatchTransferB) => {
    const dispatchTransferAPickup = getAttribute(dispatchTransferA, 'pickupDispatchAction');
    const dispatchTransferADropoff = getAttribute(dispatchTransferA, 'dropoffDispatchAction');
    const dispatchTransferBPickup = getAttribute(dispatchTransferB, 'pickupDispatchAction');
    const dispatchTransferBDropoff = getAttribute(dispatchTransferB, 'dropoffDispatchAction');

    const dispatchTransferAPickupMillis = moment(getAttribute(dispatchTransferAPickup, 'dateTimeUTC')).valueOf();
    const dispatchTransferADropoffMillis = moment(getAttribute(dispatchTransferADropoff, 'dateTimeUTC')).valueOf();

    const dispatchTransferBPickupMillis = moment(getAttribute(dispatchTransferBPickup, 'dateTimeUTC')).valueOf();
    const dispatchTransferBDropoffMillis = moment(getAttribute(dispatchTransferBDropoff, 'dateTimeUTC')).valueOf();

    // if the pickup times are the same
    if (dispatchTransferAPickupMillis === dispatchTransferBPickupMillis) {

      // if even the dropoff times are the same, use createdAt
      if (dispatchTransferADropoffMillis === dispatchTransferBDropoffMillis) {
        return getAttribute(dispatchTransferAPickup, 'createdAt') - getAttribute(dispatchTransferBPickup, 'createdAt');
      }

      return dispatchTransferADropoffMillis - dispatchTransferBDropoffMillis;
    }

    return dispatchTransferAPickupMillis - dispatchTransferBPickupMillis;
  });
  return dispatchTransfers;
}

/**
 * Fetches all DispatchTransfer objects that belong to a specific DispatchItem or date range
 * @param {string} dispatchItemObjectId The objectId of the DispatchItem for which we are fetching transfer objects
 *
 * IF NO dispatchItemObjectId:
 * @param {date} intervalStartDateTime - inclusive start date to retrieve transfers after
 * @param {date} intervalEndDateTime - inclusive end date to retrieve transfers before
 *
 * @param {object} sortBy - Sort - only applies to case where a dispatchAction query is matched to transfer query
 * @param {array} filters - array of Filter objects
 */
async function getDispatchItemTransfers(dispatchItemObjectId, intervalStartDateTime = undefined, intervalEndDateTime = undefined, sortBy, filters = []) {
  const transferQuery = createQuery('DispatchTransfer');

  if (dispatchItemObjectId) {
    setQueryRestriction(transferQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchItem', dispatchItemObjectId);
  } else if (intervalStartDateTime && intervalEndDateTime) {
    /*
      Query using the date interval
      The query needs to start by subquerying all pickupDispatchActions for the ones that occur within the interval given
      Then based off that, query for the dispatchTransfers they correspond with
    */

    const pickupDispatchActionQuery = createQuery('DispatchAction');
    setQueryRestriction(pickupDispatchActionQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'dateTimeUTC', intervalStartDateTime);
    setQueryRestriction(pickupDispatchActionQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'dateTimeUTC', intervalEndDateTime);

    if (sortBy) {
      sortQuery(pickupDispatchActionQuery, sortBy.order, sortBy.attribute);
    }

    setQueryRestriction(transferQuery, QueryRestrictionTypes.EXISTS, 'pickupDispatchAction');
    setQueryRestriction(transferQuery, QueryRestrictionTypes.MATCHES_QUERY, 'pickupDispatchAction', pickupDispatchActionQuery);

    setQueryRestriction(transferQuery, QueryRestrictionTypes.EXISTS, 'dropoffDispatchAction');
  }

  // apply filter here - this may need to be expanded in future (include filtering of properties not directly in dispatchtransfer)
  filters.map(filter => {
    setQueryRestriction(transferQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
  });

  let transferCountQuery = copyQuery(transferQuery);

  includePointers(transferQuery, [
    'pickupDispatchAction',
    'pickupDispatchAction.address',
    'dropoffDispatchAction',
    'dropoffDispatchAction.address',
    'dispatchCarrier',
    'dispatchCarrier.address',
    'dispatchCarrier.location',
    'carrierPricing',
    'dispatchItem',
    'dispatchItem.dispatchJob',
    'dispatchItem.quotePricing',
    'dispatchItem.declaredValuePricing',
  ]);

  let [totalTransfersCount, dispatchTransfers] = await Promise.all([count(transferCountQuery), find(transferQuery, false, true)]);

  // sort all dispatchTransfers by the pickup/dropoff action date before returning
  dispatchTransfers.sort((dispatchTransferA, dispatchTransferB) => {
    const dispatchTransferAPickup = getAttribute(dispatchTransferA, 'pickupDispatchAction');
    const dispatchTransferADropoff = getAttribute(dispatchTransferA, 'dropoffDispatchAction');
    const dispatchTransferBPickup = getAttribute(dispatchTransferB, 'pickupDispatchAction');
    const dispatchTransferBDropoff = getAttribute(dispatchTransferB, 'dropoffDispatchAction');

    const dispatchTransferAPickupMillis = moment(getAttribute(dispatchTransferAPickup, 'dateTimeUTC')).valueOf();
    const dispatchTransferADropoffMillis = moment(getAttribute(dispatchTransferADropoff, 'dateTimeUTC')).valueOf();

    const dispatchTransferBPickupMillis = moment(getAttribute(dispatchTransferBPickup, 'dateTimeUTC')).valueOf();
    const dispatchTransferBDropoffMillis = moment(getAttribute(dispatchTransferBDropoff, 'dateTimeUTC')).valueOf();

    // if the pickup times are the same
    if (dispatchTransferAPickupMillis === dispatchTransferBPickupMillis) {

      // if even the dropoff times are the same, use createdAt
      if (dispatchTransferADropoffMillis === dispatchTransferBDropoffMillis) {
        return getAttribute(dispatchTransferAPickup, 'createdAt') - getAttribute(dispatchTransferBPickup, 'createdAt');
      }

      return dispatchTransferADropoffMillis - dispatchTransferBDropoffMillis;
    }

    return dispatchTransferAPickupMillis - dispatchTransferBPickupMillis;
  });

  return { dispatchTransfers, totalTransfersCount };
}

async function getDispatchTransfersByObjectIds(dispatchTransferObjectIds) {
  if (!dispatchTransferObjectIds.length) throw new Error('Must provide at least one DispatchTransfer objectId');
  const dispatchTransferQuery = createQuery('DispatchTransfer');
  setQueryRestriction(dispatchTransferQuery, QueryRestrictionTypes.CONTAINED_IN, 'objectId', dispatchTransferObjectIds);
  includePointers(dispatchTransferQuery, [
    'pickupDispatchAction',
    'pickupDispatchAction.address',
    'dropoffDispatchAction',
    'dropoffDispatchAction.address',
    'dispatchCarrier',
    'dispatchCarrier.address',
    'dispatchCarrier.location',
    'carrierPricing',
    'dispatchItem',
    'dispatchItem.dispatchJob',
    'dispatchItem.quotePricing',
    'dispatchItem.declaredValuePricing',
  ]);

  const dispatchTransfers = await find(dispatchTransferQuery, false, true);
  // sort all dispatchTransfers by the pickup/dropoff action date before returning
  dispatchTransfers.sort((dispatchTransferA, dispatchTransferB) => {
    const dispatchTransferAPickup = getAttribute(dispatchTransferA, 'pickupDispatchAction');
    const dispatchTransferADropoff = getAttribute(dispatchTransferA, 'dropoffDispatchAction');
    const dispatchTransferBPickup = getAttribute(dispatchTransferB, 'pickupDispatchAction');
    const dispatchTransferBDropoff = getAttribute(dispatchTransferB, 'dropoffDispatchAction');

    const dispatchTransferAPickupMillis = moment(getAttribute(dispatchTransferAPickup, 'dateTimeUTC')).valueOf();
    const dispatchTransferADropoffMillis = moment(getAttribute(dispatchTransferADropoff, 'dateTimeUTC')).valueOf();

    const dispatchTransferBPickupMillis = moment(getAttribute(dispatchTransferBPickup, 'dateTimeUTC')).valueOf();
    const dispatchTransferBDropoffMillis = moment(getAttribute(dispatchTransferBDropoff, 'dateTimeUTC')).valueOf();

    // if the pickup times are the same
    if (dispatchTransferAPickupMillis === dispatchTransferBPickupMillis) {

      // if even the dropoff times are the same, use createdAt
      if (dispatchTransferADropoffMillis === dispatchTransferBDropoffMillis) {
        return getAttribute(dispatchTransferAPickup, 'createdAt') - getAttribute(dispatchTransferBPickup, 'createdAt');
      }

      return dispatchTransferADropoffMillis - dispatchTransferBDropoffMillis;
    }

    return dispatchTransferAPickupMillis - dispatchTransferBPickupMillis;
  });

  return dispatchTransfers;

}

/**
 * @description Update a DispatchTransfer's carrier and carrierPricing
 * @param {string} dispatchTransferObjectId - target dispatch transfer
 * @param {string} dispatchBusinessObjectId - objectId of dispatchbusiness of type carrier. undefined means unset field
 * @param {string} pricingObjectId - pricing objectId to assign to carrierPricing field. undefined means unset field
 */
async function updateDispatchTransferCarrier(dispatchTransferObjectId, dispatchBusinessObjectId, pricingObjectId) {
  const dispatchTransferQuery = createQuery('DispatchTransfer');
  setQueryRestriction(dispatchTransferQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', dispatchTransferObjectId);
  includePointers(dispatchTransferQuery, ['carrierPricing']);

  try {
    const dispatchTransfer = await find(dispatchTransferQuery, true);
    if (!dispatchTransfer) throw new Error(`DispatchTransfer objectId ${dispatchTransferObjectId} does not exist`);

    const dispatchCarrier = dispatchBusinessObjectId ? createTempRecord('DispatchBusiness', { objectId: dispatchBusinessObjectId }) : undefined;
    let carrierPricing = undefined;
    if (pricingObjectId) {
      carrierPricing = createTempRecord('Pricing', { objectId: pricingObjectId });
    } else {
      // no pricing passed in, means destroy pricing
      const carrierPricingToRemove = getAttribute(dispatchTransfer, 'carrierPricing');
      carrierPricingToRemove && removePricing(carrierPricingToRemove);
    }

    const updatedDispatchTransfer = await updateRecord(dispatchTransfer, {
      dispatchCarrier,
      carrierPricing,
    });

    return updatedDispatchTransfer;

  } catch (err) {
    throw new Error(err);
  }
}


/**
 * @description remove a DispatchTransfer given it's objectId
 * @param {string} dispatchTransferId
 */
async function removeDispatchItemTransfers(dispatchTransferId) {
  const transferQuery = createQuery('DispatchTransfer');
  if (dispatchTransferId) {
    try {
      setQueryRestriction(transferQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', dispatchTransferId);
      const transferRecord = await find(transferQuery, true);
      await destroyRecord(transferRecord);
    } catch (err) {
      throw new Error(err);
    }
  }
  else {
    throw new Error('The Dispatch Transfer ID is not valid.');
  }
}

/**
 * @description get dispatch transfer by dispatchItemId
 */
async function getTransferByDispatchItem(dispatchItemObjectId) {
  const dispatchTransferQuery = createQuery('DispatchTransfer');
  if (dispatchItemObjectId) {
    setQueryRestriction(dispatchTransferQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchItem', dispatchItemObjectId);
    includePointers(dispatchTransferQuery, ['dispatchItem']);
    const transferRecord = await find(dispatchTransferQuery, true);
    return transferRecord;
  }
  else {
    throw new Error('Need to provide DispatchItem id.');
  }
}

async function getTransferByObjectId(dispatchTransferObjectId) {
  const dispatchTransferQuery = createQuery('DispatchTransfer');
  if (dispatchTransferObjectId) {
    setQueryRestriction(dispatchTransferQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', dispatchTransferObjectId);
    includePointers(dispatchTransferQuery, ['dispatchItem', 'pickupDispatchAction', 'dropoffDispatchAction']);
    const transferRecord = await find(dispatchTransferQuery, true);
    return transferRecord;
  }
  else {
    throw new Error('Need to provide dispatchTransferObjectId.');
  }
}

async function getCarrierForDispatchTransfer(dispatchJobObjectId){
  if (!dispatchJobObjectId) {throw new Error('Need to provide Job Id.')};
  const dispatchJobQuery = createQuery('DispatchJob');
  setQueryRestriction(dispatchJobQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', dispatchJobObjectId);
  setQueryRestriction(dispatchJobQuery, QueryRestrictionTypes.EXISTS, 'carrierDispatchBusiness');
  includePointers(dispatchJobQuery, 'carrierDispatchBusiness');
  const carrierRecord = await find(dispatchJobQuery, true);
  return carrierRecord;
}

// NEW API FUNCTION
/**
 * @description Add new dispatch transfer record
 * @param {object} dispatchTransferObj Dispatch transfer sbObject
 * @return Dispatch transfer record
 */
async function addDispatchTransfer(dispatchTransferObj) {
  const dispatchTransferRecord = await addRecord('DispatchTransfer', dispatchTransferObj);

  return dispatchTransferRecord;
}

/**
 * @description Get all Dispatch Transfer records for a given Dispatch Job
 * @param {string} dispatchJobObjectId Dispatch Job ObjectId
 * @param {array} filters Array of filters
 * @return Object containing Dispatch Transfer records and count
 */
async function getDispatchTransfersForDispatchJob(dispatchJobObjectId, sortBy = new Sort('createdAt', QuerySortOrderTypes.ASCENDING), filters = []) {
  if (!dispatchJobObjectId) {
    throw new Error('Must provide Dispatch Job objectId.');
  };

  const dispatchTransferQuery = createQuery('DispatchTransfer');

  includePointers(dispatchTransferQuery, [
    'dispatchJob',
    'dispatchItem',
    'dispatchItem.dispatchJob',
    'dispatchItem.quotePricing',
    'dispatchItem.declaredValuePricing',
    'pickupDispatchAction',
    'pickupDispatchAction.address',
    'dropoffDispatchAction',
    'dropoffDispatchAction.address',
    'dispatchCarrier',
    'dispatchCarrier.address',
    'dispatchCarrier.location',
    'carrierPricing',
    'consigneeDispatchOrganization',
    'shipperDispatchOrganization',
    'consigneeAddress',
    'shipperAddress',
  ]);

  setQueryRestriction(dispatchTransferQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchJob', dispatchJobObjectId);

  filters.map(filter => {
    setQueryRestriction(dispatchTransferQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
  });

  sortQuery(dispatchTransferQuery, sortBy.order, sortBy.attribute);

  const dispatchTransferRecords = await find(dispatchTransferQuery, false, true);
  const dispatchTransferRecordsCount = await count(dispatchTransferQuery);

  return {
    dispatchTransferRecords,
    dispatchTransferRecordsCount,
  }
}

export {
  getDispatchItemTransfers,
  getDispatchItemTransfersByJob,
  getDispatchTransfersByObjectIds,
  updateDispatchTransferCarrier,
  removeDispatchItemTransfers,
  getTransferByDispatchItem,
  getTransferByObjectId,
  getCarrierForDispatchTransfer,
  addDispatchTransfer,
  getDispatchTransfersForDispatchJob,
};
