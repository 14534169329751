import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// API
import * as Helpers from 'api/Helpers';
import history from 'sbHistory';
import { getDeserialized } from 'api/URL';
import { getLocationDescriptionBreakdown } from 'api/VehicleLocation/VehicleLocation';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import SBTable from 'components/Shared/SBTable/SBTable';
import { MDBBtn, MDBModal, MDBModalBody } from 'mdbreact';
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay';
import { getAttribute } from 'api/Parse';

class DriverRoutes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      tableBodyRowData: [],
      driverTimezoneStr: '',
    };

    this.downloadCsv = this.downloadCsv.bind(this);
    this.parseTableBodyRowData = this.parseTableBodyRowData.bind(this);
  }

  componentDidMount() {
    this.setState({ ...this.state, loading: true }, () => {
      this.parseTableBodyRowData();
    });
  }

  componentWillReceiveProps(nextProps) {
    const newQueryStrObj = getDeserialized(nextProps.location.search).params;
    const oldQueryStrObj = getDeserialized(this.props.location.search).params;
    if (newQueryStrObj.dateStart !== oldQueryStrObj.dateStart || newQueryStrObj.dateEnd !== oldQueryStrObj.dateEnd) this.parseTableBodyRowData();
  }

  downloadCsv() {
    this.setState({ ...this.state, downloadCsvInProgress: true });
    const driver = this.props.driver;
    let csvString = `Date Start,Date End,Odometer Start (${this.props.distanceUnitFilter.value}),Odometer End (${this.props.distanceUnitFilter.value}),Calculated Mileage (${this.props.distanceUnitFilter.value})\n`;

    for (let i = 0; i < this.state.tableBodyRowData.length; i++) {
      const iftaRoute = this.state.tableBodyRowData[i];
      const dateStartFormatted = moment(iftaRoute.dateStart.iso).tz(this.state.driverTimezoneStr).format('YYYY-MM-DD');
      const dateEndFormatted = moment(iftaRoute.dateEnd.iso).tz(this.state.driverTimezoneStr).format('YYYY-MM-DD');
      const totalVehicleKmStart = iftaRoute.totalVehicleKmStart;
      const totalVehicleKmEnd = iftaRoute.totalVehicleKmEnd;
      const totalVehicleKmDiff = Math.round(totalVehicleKmEnd - totalVehicleKmStart);

      csvString += `${dateStartFormatted},`;
      csvString += `${dateEndFormatted},`;
      csvString += `${totalVehicleKmStart ? (this.props.distanceUnitFilter.value === 'km' ? totalVehicleKmStart.toFixed(0) : Helpers.convertDistance(totalVehicleKmStart, 'km', 'mi').toFixed(0)) : 0},`;
      csvString += `${totalVehicleKmEnd ? (this.props.distanceUnitFilter.value === 'km' ? totalVehicleKmEnd.toFixed(0) : Helpers.convertDistance(totalVehicleKmEnd, 'km', 'mi').toFixed(0)) : 0},`;
      csvString += `${totalVehicleKmDiff ? (this.props.distanceUnitFilter.value === 'km' ? totalVehicleKmDiff.toFixed(0) : Helpers.convertDistance(totalVehicleKmDiff, 'km', 'mi').toFixed(0)) : 0}\n`;
    }

    Helpers.createCsvFile(`Switchboard - IFTA Mileage Summary - ${driver && Helpers.toTitleCase(driver.get('user_fullName'))}`, csvString, true);
    this.setState({ ...this.state, downloadCsvInProgress: false });
  }

  async parseTableBodyRowData() {
    const driverTimezoneStr = getAttribute(this.props.driver, 'timezoneOffsetFromUTC');
    await this.setState({ ...this.state, driverTimezoneStr });

    const parse = async () => {
      // Going through by day
      let previousStartMoment;
      let previousEndMoment;
      const tableBodyRowData = [];

      for (let i = 0; i < this.props.iftaRouteArr.length; i++) {
        const iftaRouteObj = this.props.iftaRouteArr[i].toJSON();
        let currentStartMoment = moment(this.props.iftaRouteArr[i].get('dateStart')).tz(this.state.driverTimezoneStr);
        let currentEndMoment = moment(this.props.iftaRouteArr[i].get('dateEnd')).tz(this.state.driverTimezoneStr);

        if (i === 0) {
          tableBodyRowData.push({ ...iftaRouteObj });
        } else if (currentStartMoment.format('YYYYMMDD') === previousEndMoment.format('YYYYMMDD') && currentStartMoment.format('YYYYMMDD') === currentEndMoment.format('YYYYMMDD')) {
          // Current IFTARoute is still in the same day, merge their information together
          const previousIftaRouteObj = tableBodyRowData[tableBodyRowData.length - 1];

          if (iftaRouteObj.iftaRouteDriverPeriods.length > 1) previousIftaRouteObj.iftaRouteDriverPeriods = iftaRouteObj.iftaRouteDriverPeriods;
          previousIftaRouteObj.dateEnd = iftaRouteObj.dateEnd;
          previousIftaRouteObj.vehicleLocationEnd = iftaRouteObj.vehicleLocationEnd;
          previousIftaRouteObj.totalVehicleKmEnd = iftaRouteObj.totalVehicleKmEnd;
          previousIftaRouteObj.savedVehicleKm += iftaRouteObj.savedVehicleKm;
          previousIftaRouteObj.totalVehicleKmDiff += iftaRouteObj.totalVehicleKmDiff;
          previousIftaRouteObj.distanceKm += iftaRouteObj.distanceKm;
        } else {
          tableBodyRowData.push({ ...iftaRouteObj });
        }

        previousStartMoment = currentStartMoment;
        previousEndMoment = currentEndMoment;
      }

      return tableBodyRowData;
    }

    const tableBodyRowData = await parse();
    await this.setState({ ...this.state, loading: false, tableBodyRowData });
  }

  render() {
    const { props, state } = this;

    const tableHeaderStyles = {
      date: { width: '10%' },
      driver: { width: '20%' },
      km: { width: '10%' },
      saved: { width: '15%' },
    };

    const tableHeaderRows = [{
      key: 'sb-jobs-list',
      columns: [
        {
          element: <div>Trip Start</div>,
          props: { style: tableHeaderStyles.date },
        },
        {
          element: <div>Trip End</div>,
          props: { style: tableHeaderStyles.date },
        },
        {
          element: <div>Driver</div>,
          props: { style: tableHeaderStyles.driver },
        },
        {
          element: <div>Start Odometer ({props.distanceUnitFilter.value})</div>,
          props: { style: tableHeaderStyles.km },
        },
        {
          element: <div>End Odometer ({props.distanceUnitFilter.value})</div>,
          props: { style: tableHeaderStyles.km },
        },
        {
          element: <div>Calculated Mileage ({props.distanceUnitFilter.value})</div>,
          props: { style: tableHeaderStyles.saved },
        },
      ]
    }];

    let tableBodyRows;

    if (state.loading) {
      tableBodyRows = [{
        key: 'loading',
        columns: [{
          element: <div><LoadingIcon /></div>,
          props: { className: 'table-body-column-style' },
        }],
      }];
    } else {
      const tableBodyRowData = state.tableBodyRowData;

      tableBodyRows = state.tableBodyRowData.map((iftaRoute, index) => {
        const dateStartFormatted = moment(iftaRoute.dateStart.iso).tz(state.driverTimezoneStr).format('ll');
        const dateEndFormatted = moment(iftaRoute.dateEnd.iso).tz(state.driverTimezoneStr).format('ll');
        const vehicleLocationStart = iftaRoute.vehicleLocationStart;
        const vehicleLocationEnd = iftaRoute.vehicleLocationEnd;
        const totalVehicleKmStart = iftaRoute.totalVehicleKmStart;
        const totalVehicleKmEnd = iftaRoute.totalVehicleKmEnd;
        const iftaRouteDriverPeriods = iftaRoute.iftaRouteDriverPeriods;
        const totalVehicleKmDiff = Math.round(totalVehicleKmEnd - totalVehicleKmStart);

        let startVehicleLocationStateProvince = vehicleLocationStart.stateProvince;
        let endVehicleLocationStateProvince = vehicleLocationEnd.stateProvince;
        let startVehicleLocationAprxShortName = vehicleLocationStart.aprxShortName;
        let endVehicleLocationAprxShortName = vehicleLocationEnd.aprxShortName;

        const startVehicleLocationString = vehicleLocationStart.locationDescriptionUS;
        const endVehicleLocationString = vehicleLocationEnd.locationDescriptionUS;
        const startVehicleLocationBreakdown = getLocationDescriptionBreakdown(startVehicleLocationString);
        const endVehicleLocationBreakdown = getLocationDescriptionBreakdown(endVehicleLocationString);

        if (!startVehicleLocationStateProvince) startVehicleLocationStateProvince = startVehicleLocationBreakdown.stateProvince.code;
        if (!endVehicleLocationStateProvince) endVehicleLocationStateProvince = endVehicleLocationBreakdown.stateProvince.code;
        if (!startVehicleLocationAprxShortName) startVehicleLocationAprxShortName = startVehicleLocationBreakdown.city;
        if (!endVehicleLocationAprxShortName) endVehicleLocationAprxShortName = endVehicleLocationBreakdown.city;

        const vehicleRowObj = {
          key: iftaRoute.objectId,
          columns: [],
        };

        vehicleRowObj.columns = [
          {
            element:
              <div>
                <b>
                  <span>
                    {dateStartFormatted}
                  </span>
                </b>
                <br />
                {vehicleLocationStart && `${startVehicleLocationAprxShortName}, ${startVehicleLocationStateProvince.toUpperCase()}`}
              </div>,
            props: { className: 'table-body-column-style' },
          },
          {
            element:
              <div>
                <b>
                  <span>
                    {dateEndFormatted}
                  </span>
                </b>
                <br />
                {vehicleLocationEnd && `${endVehicleLocationAprxShortName}, ${endVehicleLocationStateProvince.toUpperCase()}`}
              </div>,
            props: { className: 'table-body-column-style' },
          },
          {
            element:
              <div>
                {iftaRouteDriverPeriods && iftaRouteDriverPeriods.map((driverPeriod) => {
                  return (
                    <div
                      className="inlineBlock clickable textHoverHighlight"
                      onClick={() => history.push({
                        pathname: 'driver', search: `driver=${driverPeriod.driver.objectId}&view=hosEvents&date=${moment(driverPeriod.dateStart.iso).format('DDMMYY')}`,
                      })}
                    >
                      <b>{driverPeriod.driver && Helpers.toTitleCase(driverPeriod.driver.user_fullName)}</b>
                    </div>
                  );
                })}
              </div>,
            props: { className: 'table-body-column-style' },
          },
          {
            element:
              <div>
                <b>{totalVehicleKmStart ? (props.distanceUnitFilter.value === 'km' ? totalVehicleKmStart.toFixed(0) : Helpers.convertDistance(totalVehicleKmStart, 'km', 'mi').toFixed(0)) : 0}</b>
              </div>,
            props: { className: 'table-body-column-style' },
          },
          {
            element:
              <div>
                <b>{totalVehicleKmEnd ? (props.distanceUnitFilter.value === 'km' ? totalVehicleKmEnd.toFixed(0) : Helpers.convertDistance(totalVehicleKmEnd, 'km', 'mi').toFixed(0)) : 0}</b>
              </div>,
            props: { className: 'table-body-column-style' },
          },
          {
            element:
              <div>
                <b>{totalVehicleKmDiff ? (props.distanceUnitFilter.value === 'km' ? totalVehicleKmDiff.toFixed(0) : Helpers.convertDistance(totalVehicleKmDiff, 'km', 'mi').toFixed(0)) : 0}</b>
              </div>,
            props: { className: 'table-body-column-style' },
          },
        ];

        return vehicleRowObj;
      });
    }

    return (

      <div>
        {state.loading &&
          <LoadingOverlay />
        }

        <div>
          <MDBBtn
            size="sm"
            color="secondary"
            disabled={state.downloadCsvInProgress}
            onClick={() => this.downloadCsv()}
            style={{ marginRight: '.5em', width: '14em' }}
          >
            Download CSV
          </MDBBtn>
        </div>

        <div className='DriverRoutes-printable'>
          <SBTable
            hover
            tableHeaderRows={tableHeaderRows}
            tableBodyRows={tableBodyRows}
            isWaiting={state.loading}
          />
        </div>
      </div>
    );
  }
}

DriverRoutes.propTypes = {
  driver: PropTypes.object,
  selectedFilter: PropTypes.object,
  distanceUnitFilter: PropTypes.object.isRequired,
  iftaRouteArr: PropTypes.array.isRequired,
};

export default DriverRoutes;
