import React from 'react';
import { Button as PRButton } from 'primereact/button';

import './style.scss';

/**
 * Button component that wraps around UI library button (PrimeReact)
 *
 * @param {string} sbVariant - A custom re-usable variant that can be applied to a button
 * @param {bool} disableTranslate - Exempt this button from being translated
 *
 * @returns {JSX} Returns the button
 */
function Button({ sbVariant, disableTranslate, ...props }) {
  let className = 'sbc-button translate-me';
  if (disableTranslate) className = 'sbc-button';

  if (props.className) className += ` ${props.className}`;

  // Apply variant styles here
  if (sbVariant === 'slim') {
    className += ' sb-variant-slim uppercase py-1';
  }

  if (sbVariant === 'short') {
    className += ' sb-variant-short';
  }

  if (sbVariant === 'light') className += ' sb-variant-light';

  return (<PRButton {...props} className={className} />);
}

export default Button;
