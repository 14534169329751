import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// API
import * as IFTA from 'api/IFTA';
import { StateProvinces } from 'api/Lists/StateProvinces';
import * as PDF from 'api/PDFer';
import * as Helpers from 'api/Helpers';
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { getAttribute } from 'api/Parse';
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL';
import { getOdometerReadingsFromIFTARoute } from 'api/IFTARoute/IFTARoute.old';

// Components
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import { MDBBtn } from 'mdbreact';
import VehicleSummary from 'components/IFTARoute/view/VehicleSummary';
import TotalSummary from 'components/IFTARoute/view/TotalSummary';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import VehicleSummaryViewBeta from 'components/IFTARoute/view/VehicleSummaryBeta';


// function convertVehicleMileagesArrToSummary(vehicleMileagesArr, vehicleOdometerReadingsObj) {
//   const stateProvinceCodes = StateProvinces.map((stateProvince) => stateProvince.code).filter((stateProvinceCode) => stateProvinceCode);

//   // Set up stateProvinceMileages
//   const stateProvinceMileages = {};
//   for (let i = 0; i < stateProvinceCodes.length; i++) {
//     stateProvinceMileages[stateProvinceCodes[i]] = {
//       vehicleKm: 0,
//       spreadKm: 0,
//     };
//   }

//   const iftaVehicleArrMiles = {};
//   // Loop through Vehicles
//   for (let i = 0; i < vehicleMileagesArr.length; i++) {
//     // Tally up savedVehicleKm into iftaArrMiles
//     const vehicleMileagesObj = vehicleMileagesArr[i];
//     let totalSavedKm = 0;
//     let dateStart;
//     let dateEnd;
//     iftaVehicleArrMiles[vehicleMileagesObj.unitId] = { };
//     // Loop through iftaRoutes for period
//     for (let j = 0; j < vehicleMileagesObj.iftaRouteArr.length; j++) {
//       const iftaRoute = vehicleMileagesObj.iftaRouteArr[j];
//       if (!dateStart) dateStart = iftaRoute.get('dateStart');
//       if (!dateEnd || (iftaRoute.get('dateEnd') && moment(dateEnd).isBefore(moment(iftaRoute.get('dateEnd'))))) dateEnd = iftaRoute.get('dateEnd');
//       const stateProvinceCode = iftaRoute.get('stateProvince').toUpperCase();
//       const savedVehicleKm = iftaRoute.get('savedVehicleKm');
//       totalSavedKm += savedVehicleKm;
//       if (iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvinceCode]) {
//         iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvinceCode].vehicleKm += savedVehicleKm;
//       } else {
//         iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvinceCode] = {};
//         iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvinceCode].vehicleKm = savedVehicleKm || 0;
//       }
//     }

//     const odometerReadings = vehicleOdometerReadingsObj[vehicleMileagesObj.unitId];
//     let odometerDiff;
//     let shouldUseSpreadKm = false;
//     if (
//       odometerReadings &&
//       odometerReadings.odometerEnd &&
//       odometerReadings.odometerStart
//     ) {
//       odometerDiff = odometerReadings.odometerEnd - odometerReadings.odometerStart;
//       if (odometerDiff > 0 &&(odometerDiff < (moment(dateEnd).diff(moment(dateStart), 'hours') * 200))) {
//         shouldUseSpreadKm = true;
//       }
//     }

//     // Use spreadKm if applicable
//     for (let j = 0; j < stateProvinceCodes.length; j++) {
//       const stateProvince = stateProvinceCodes[j];
//       if (iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvince]) {
//         const stateProvinceSavedKm = (Math.round(iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvince].vehicleKm * 100) / 100);
//         const spreadKm = Math.round(totalSavedKm) !== 0 ? stateProvinceSavedKm && totalSavedKm && odometerDiff && Math.round((stateProvinceSavedKm / Math.round(totalSavedKm)) * odometerDiff * 100) / 100 : odometerDiff;
//         if (stateProvinceSavedKm) {
//           stateProvinceMileages[stateProvince].vehicleKm += stateProvinceSavedKm;
//         }
//         if (shouldUseSpreadKm && spreadKm) {
//           stateProvinceMileages[stateProvince].spreadKm += spreadKm;
//         } else if (stateProvinceSavedKm) {
//           stateProvinceMileages[stateProvince].spreadKm += stateProvinceSavedKm;
//         }
//       }
//     }
//   }
//   return stateProvinceMileages;
// }

class VehicleTotalsSummaryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      printInProgress: false,
      downloadCsvInProgress: false,
      loading: true,
      distanceUnitFilter: getPDispatcherPropertyFromState('distanceUnit') === 'mi' ?
        {
          key: 0,
          value: 'mi',
          label: 'mi',
        }
        :
        {
          key: 1,
          value: 'km',
          label: 'km',
        },
      distanceUnitFilterItems: [
        {
          key: 0,
          value: 'km',
          label: 'km',
        },
        {
          key: 1,
          value: 'mi',
          label: 'mi',
        },
      ],
      showOdometerValuesFilter: {
        key: 1,
        value: 'hideOdometerValues',
        label: 'Hide Odometer Values',
      },
      showOdometerValuesFilterItems: [
        {
          key: 0,
          value: 'showOdometerValues',
          label: 'Show Odometer Values',
        },
        {
          key: 1,
          value: 'hideOdometerValues',
          label: 'Hide Odometer Values',
        },
      ],
      vehicleOdometerReadingsObj: {},
      totalStateProvinceMileages: {},
      stateProvinceMileages: {},
    };

    this.printReport = this.printReport.bind(this);
    this.getOdometerReadings = this.getOdometerReadings.bind(this);
  }

  async componentDidMount() {
    const originalTotalMileage = getQueryParameter(this.props.location.search, 'originaltotalmileage');
    if (this.props.routeToNewIFTA && !originalTotalMileage) {
      this.setState({ ...this.state, loading: true });
      await this.getOdometerReadings();
    } else {
      const promises = [];
      await this.setState({ ...this.state, loading: true });
      for (let i = 0; i < this.props.vehicleMileagesArr.length; i++) {
        const vehicleMileagesObj = this.props.vehicleMileagesArr[i];
        promises.push((new Promise((resolve) => {
          const vehicleOdometerReadingsObj = { odometerReadings: null, loading: true };
          this.setState({ ...this.state, vehicleOdometerReadingsObj }, () => {
            IFTA.getOdometerReadingsForDateRange(vehicleMileagesObj.unitId, this.props.dateStart, this.props.dateEnd, 'km').then(odometerReadings => {
              const vehicleOdometerReadingsObj = this.state.vehicleOdometerReadingsObj;
              vehicleOdometerReadingsObj[vehicleMileagesObj.unitId] = {
                odometerReadings,
                loading: false,
              };
              this.setState({ ...this.state, vehicleOdometerReadingsObj }, () => {
                resolve();
              });
            });
          });
        })));
      }

      Promise.all(promises).then(() => {
        this.setState({ ...this.state, loading: false });
      })
    }
  }

  async getOdometerReadings() {
    const { props } = this;
    let vehicleOdometerReadings = {};

    for (let i = 0; i < props.vehicleMileagesArr.length; i++) {
      const vehicleMileagesObj = props.vehicleMileagesArr[i];
      const vehicleUnitId = vehicleMileagesObj.unitId;
      const iftaRouteArr = vehicleMileagesObj.iftaRouteArr;

      const odometerReadings = await getOdometerReadingsFromIFTARoute(vehicleUnitId, iftaRouteArr, props.dateStart, props.dateEnd);
      vehicleOdometerReadings[vehicleUnitId] = odometerReadings;
    }
    this.setState({ ...this.state, odometerReadings: vehicleOdometerReadings, loading: false });
  }

  printReport = () => {
    let reportHTML;
    let reportName;
    this.setState({ ...this.state, printInProgress: true });
    reportHTML = document.querySelector('.vehicleSummary-printable').outerHTML;
    reportName = `Total Mileage Summary`;
    PDF.generateTempPDFFromHTML(document.querySelector('.vehicleSummary-printable'), reportName, undefined).then(
      tempFile => {
        const reportURL = tempFile.get('file')._url;
        Helpers.openDocumentLink(reportURL);
        this.setState({ ...this.state, printInProgress: false });
      }
    );
  }

  downloadCsv = () => {
    const originalTotalMileage = getQueryParameter(this.props.location.search, 'originaltotalmileage');
    this.setState({ ...this.state, downloadCsvInProgress: true });
    const stateProvinceCodes = StateProvinces.map((stateProvince) => stateProvince.code).filter((stateProvinceCode) => stateProvinceCode);
    let csvString = `Unit ID,State/Province,Fuel (Gallons),Calculated Mileage (${this.state.distanceUnitFilter.value})\n`;
    const iftaArrMilesAll = {};

    // for each vehicleUnitId
    for (let i = 0; i < this.props.vehicleMileagesArr.length; i++) {
      const vehicleMileagesObj = this.props.vehicleMileagesArr[i];
      const iftaRouteArr = vehicleMileagesObj.iftaRouteArr;
      let vehicleOdometerReadings;
      let odometerDiff = 0;

      if (this.props.routeToNewIFTA && !originalTotalMileage) {
        // Use new method of retrieving odo diff
        odometerDiff = this.state.odometerReadings[vehicleMileagesObj.unitId].odometerDiffKm;
      } else {
        vehicleOdometerReadings = this.state.vehicleOdometerReadingsObj[vehicleMileagesObj.unitId];

        if (vehicleOdometerReadings.odometerReadings && vehicleOdometerReadings.odometerReadings.odometerEnd && vehicleOdometerReadings.odometerReadings.odometerStart) {
          odometerDiff = vehicleOdometerReadings.odometerReadings.odometerEnd - vehicleOdometerReadings.odometerReadings.odometerStart;
        }
      }

      const stateProvinceDataArr = [];

      const iftaArrMiles = {};
      let totalOdometerKm = 0;
      let totalGpsKm = 0;
      let totalSavedKm = 0;
      let totalFuelPurchases = {};
      let totalFuelTotalPaidCAD = 0;
      let totalFuelTotalPaidUSD = 0;
      for (let i = 0; i < iftaRouteArr.length; i++) {
        const iftaRoute = iftaRouteArr[i];
        const stateProvince = iftaRoute.get('stateProvince').toUpperCase();
        const savedVehicleKm = (iftaRoute.get('savedVehicleKm') > 0 ? iftaRoute.get('savedVehicleKm') : 0);
        const totalVehicleKmDiff = (iftaRoute.get('totalVehicleKmDiff') > 0 ? iftaRoute.get('totalVehicleKmDiff') : 0);
        const distanceKm = (iftaRoute.get('distanceKm') > 0 ? iftaRoute.get('distanceKm') : 0);
        totalOdometerKm += totalVehicleKmDiff;
        totalGpsKm += distanceKm;
        totalSavedKm += savedVehicleKm;
        if (iftaArrMiles[stateProvince]) {
          iftaArrMiles[stateProvince].odometerKm += totalVehicleKmDiff;
          iftaArrMiles[stateProvince].gpsKm += distanceKm;
          iftaArrMiles[stateProvince].savedKm += savedVehicleKm;
        } else {
          iftaArrMiles[stateProvince] = {};
          iftaArrMiles[stateProvince].odometerKm = totalVehicleKmDiff || 0;
          iftaArrMiles[stateProvince].gpsKm = distanceKm || 0;
          iftaArrMiles[stateProvince].savedKm = savedVehicleKm || 0;
          iftaArrMiles[stateProvince].fuelPurchaseSubtotals = {};
          iftaArrMiles[stateProvince].fuelTotalPaidCAD = 0;
          iftaArrMiles[stateProvince].fuelTotalPaidUSD = 0;
        }
        // Fuel Purchases
        if (iftaRoute.get('fuelPurchases')) {
          for (let j = 0; j < iftaRoute.get('fuelPurchases').length; j++) {
            const fuelPurchase = iftaRoute.get('fuelPurchases')[j];
            const fuelType = fuelPurchase.get('fuelType') && fuelPurchase.get('fuelType').toUpperCase();
            const totalPaid = fuelPurchase.get('totalPaid');
            const currency = fuelPurchase.get('currency');
            const volumePumpedGallons = fuelPurchase.get('volumeUnits') === 'l' ? Helpers.convertFuelUnit(fuelPurchase.get('volumePumped'), 'l', 'gal') : fuelPurchase.get('volumePumped')
            if (iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType]) {
              iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType] += volumePumpedGallons;
            } else {
              iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType] = volumePumpedGallons;
            }
            if (totalFuelPurchases[fuelType]) {
              totalFuelPurchases[fuelType] += volumePumpedGallons;
            } else {
              totalFuelPurchases[fuelType] = volumePumpedGallons;
            }
            if (currency === 'cad') {
              iftaArrMiles[stateProvince].fuelTotalPaidCAD += totalPaid;
              totalFuelTotalPaidCAD += totalPaid;
            } else if (currency === 'usd') {
              iftaArrMiles[stateProvince].fuelTotalPaidUSD += totalPaid;
              totalFuelTotalPaidUSD += totalPaid;
            }
          }
        }
      }

      // This is for deciding whether to spread the calculated Vehicle Km
      // Should Use SpreadKm when the odometerDifference is not negative or way too large!
      // Otherwise will just use calculatedOdometerKm
      let shouldUseSpreadKm = false;
      if
        (odometerDiff > 0
        && totalSavedKm > 0
        && (odometerDiff < (moment(this.props.dateEnd).diff(moment(this.props.dateStart), 'hours') * 200))
        && (totalSavedKm < (moment(this.props.dateEnd).diff(moment(this.props.dateStart), 'hours') * 200))
        && (Math.abs(totalSavedKm - odometerDiff) < (5000 * (moment(this.props.dateEnd).diff(moment(this.props.dateStart), 'months') + 1)))
      ) {
        shouldUseSpreadKm = true;
      }

      for (let i = 0; i < stateProvinceCodes.length; i++) {
        const stateProvince = stateProvinceCodes[i];
        const subtotalSavedKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].savedKm ? Math.round(iftaArrMiles[stateProvince].savedKm * 100) / 100 : 0;
        const subtotalOdometerKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].odometerKm ? Math.round(iftaArrMiles[stateProvince].odometerKm * 100) / 100 : 0;
        const subtotalGpsKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].gpsKm ? Math.round(iftaArrMiles[stateProvince].gpsKm * 100) / 100 : 0;
        const fuelPurchaseSubtotals = iftaArrMiles[stateProvince] ? iftaArrMiles[stateProvince].fuelPurchaseSubtotals : {};

        const subtotalSpreadKm = Math.round(totalSavedKm) !== 0 ? subtotalSavedKm && totalSavedKm && odometerDiff && Math.round((subtotalSavedKm / Math.round(totalSavedKm)) * odometerDiff * 100) / 100 : subtotalSavedKm;
        const stateProvinceDataObj = {
          code: stateProvince,
          subtotalSavedKm,
          subtotalOdometerKm,
          subtotalGpsKm,
          fuelPurchaseSubtotals,
          subtotalSpreadKm: shouldUseSpreadKm ? subtotalSpreadKm : subtotalSavedKm,
          odometerDiff: odometerDiff,
        };
        stateProvinceDataArr.push(stateProvinceDataObj);
      }
      iftaArrMilesAll[vehicleMileagesObj.unitId] = stateProvinceDataArr;

      stateProvinceDataArr.filter((stateProvinceData) => stateProvinceData.subtotalSavedKm)
        .map(stateProvinceData => {
          let fuelPurchaseSubtotalsString = '';

          for (let fuelType in stateProvinceData.fuelPurchaseSubtotals) {
            fuelPurchaseSubtotalsString += `${fuelType}: ${stateProvinceData.fuelPurchaseSubtotals[fuelType].toFixed(2)} `;
          }

          csvString += `"${vehicleMileagesObj.unitId}",`;
          csvString += `"${stateProvinceData.code}",`;
          // getting rid of gps mileage
          // csvString += `"${(this.state.distanceUnitFilter.value === 'km' ? (Math.round(stateProvinceData.subtotalGpsKm * 100) / 100) : (Math.round(Helpers.convertDistance(stateProvinceData.subtotalGpsKm, 'km', 'mi', true) * 100) / 100).toFixed(0))}",`;
          csvString += `"${fuelPurchaseSubtotalsString}",`;
          csvString += `"${shouldUseSpreadKm ?
            (this.state.distanceUnitFilter.value === 'km' ? (Math.round(stateProvinceData.subtotalSpreadKm * 100) / 100) : (Math.round(Helpers.convertDistance(stateProvinceData.subtotalSpreadKm, 'km', 'mi', true) * 100) / 100).toFixed(0)) :
            (this.state.distanceUnitFilter.value === 'km' ? (Math.round(stateProvinceData.savedVehicleKm * 100) / 100) : (Math.round(Helpers.convertDistance(stateProvinceData.savedVehicleKm, 'km', 'mi', true) * 100) / 100).toFixed(0))
            }"\n`;

        });
    }

    Helpers.createCsvFile(`Switchboard - IFTA Mileage Summary`, csvString, true);
    this.setState({ ...this.state, downloadCsvInProgress: false });
  }

  render() {
    const originalTotalMileage = getQueryParameter(this.props.location.search, 'originaltotalmileage');

    return (
      <div>
        <div style={{ margin: '1em' }}>
          <SBSelect
            containerClassName="ml-2 mr-2 d-inline-block"
            className="sb-jobs-list-select"
            defaultToggleText={this.state.distanceUnitFilter.label}
            items={this.state.distanceUnitFilterItems}
            getSelectedItems={(selectedFilter) => this.setState({ ...this.state, distanceUnitFilter: selectedFilter[0] })}
            showFilter
          />
          <SBSelect
            containerClassName="ml-2 mr-2 d-inline-block"
            className="sb-jobs-list-select"
            defaultToggleText={this.state.showOdometerValuesFilter.label}
            items={this.state.showOdometerValuesFilterItems}
            getSelectedItems={(selectedFilter) => this.setState({ ...this.state, showOdometerValuesFilter: selectedFilter[0] })}
            showFilter
          />
          <MDBBtn
            size="sm"
            color="secondary"
            disabled={this.state.printInProgress || this.state.loading}
            onClick={() => this.printReport()}
            style={{ marginRight: '.5em' }}
          >
            Print Report
          </MDBBtn>
          <MDBBtn
            size="sm"
            color="secondary"
            disabled={this.state.downloadCsvInProgress || this.state.loading}
            onClick={() => this.downloadCsv()}
            style={{ marginRight: '.5em', width: '14em' }}
          >
            Download CSV
          </MDBBtn>
        </div>
        <div className='vehicleSummary-printable'>
          {(moment(this.props.dateEnd).isAfter(moment().subtract(11, 'days'))) &&
            <SBBlock
              title={`Switchboard processes IFTA vehicle mileages up to 10 days before today's date.`}
              header={`If you are generating reports for the previous month, make sure you generate them on the 11th of this month to get the most updated mileages.`}
              warning
            />
          }
          {this.props.vehicleMileagesArr.map((vehicleMileagesObj) => (
            <div>
              <div><h3><b>Unit {vehicleMileagesObj.unitId}</b></h3></div>
              {(this.props.routeToNewIFTA && !originalTotalMileage) ?
                <VehicleSummaryViewBeta
                  isLoading={this.state.loading}
                  unitId={vehicleMileagesObj.unitId}
                  dateStart={this.props.dateStart}
                  dateEnd={this.props.dateEnd}
                  displayStartDate={this.props.displayStartDate}
                  displayEndDate={this.props.displayEndDate}
                  iftaRouteArr={vehicleMileagesObj.iftaRouteArr}

                  stateProvinceMileages={vehicleMileagesObj.stateProvinceMileages}
                  selectedFilter={{
                    key: 1,
                    value: 'hideZeroMileages',
                    label: 'Hide Empty States/Provinces',
                  }}
                  distanceUnitFilter={this.state.distanceUnitFilter}
                  odometerReadings={this.state.odometerReadings && this.state.odometerReadings[vehicleMileagesObj.unitId]}
                  showOdometerValuesFilter={this.state.showOdometerValuesFilter}
                />
                :
                <VehicleSummary
                  unitId={vehicleMileagesObj.unitId}
                  dateStart={this.props.dateStart}
                  dateEnd={this.props.dateEnd}
                  displayStartDate={this.props.displayStartDate}
                  displayEndDate={this.props.displayEndDate}
                  iftaRouteArr={vehicleMileagesObj.iftaRouteArr}
                  stateProvinceMileages={vehicleMileagesObj.stateProvinceMileages}
                  selectedFilter={{
                    key: 1,
                    value: 'hideZeroMileages',
                    label: 'Hide Empty States/Provinces',
                  }}
                  odometerReadings={this.state.vehicleOdometerReadingsObj && this.state.vehicleOdometerReadingsObj.odometerReadings}
                  loading={this.state.vehicleOdometerReadingsObj && this.state.vehicleOdometerReadingsObj.loading}
                  distanceUnitFilter={this.state.distanceUnitFilter}
                  usePropsVehicleOdometerReadingsObj
                />
              }
              <hr />
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default VehicleTotalsSummaryTable;
