/** @module DispatchJobTransactions */

// CSAPI API
import {
  addRecord, copyQuery, count, createQuery, createQueryOr, destroyRecord, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord,
} from 'sb-csapi/dist/AAPI';

// CSAPI Enums
import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

// CSAPI sbObjects
import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @memberof module:DispatchJobTransactions
 *
 * @description Get DispatchJobTransactions according to filter criteria
 *
 * @param {Object} [options] - Currently contains the current user session token
 * @param {Array} [filters] - array of Filter objects
 * @param {Object} [sortBy] - Sort object
 * @param {Array} [includedPointers] - Included pointers
 * @param {Array} [selectedAttributes] - Select attributes to return
 * @param {Integer} [page] - The current page for pagination
 * @param {Integer} [limit] - The limit of records obtained per pagination
 * @param {Boolean} [queryAll] - Get all records, ignoring pages/limits
 *
 * @returns { DispatchJobTransactions } - DispatchJobTransaction record
 */
async function getDispatchJobTransactions(options = { sessionToken: getCurrentUserSessionToken() }, filters = [], sortBy = new Sort(QuerySortOrder.DESCENDING, 'updatedAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  const dispatchJobTransactionsQuery = createQuery('DispatchJobTransactions');

  // Set filters
  filters.map(filter => setQueryRestriction(dispatchJobTransactionsQuery, filter.queryRestriction, filter.attribute, filter.value));
  sortQuery(dispatchJobTransactionsQuery, sortBy.order, sortBy.attribute);

  try {
    const dispatchJobTransactions = await findRecords(options, dispatchJobTransactionsQuery, false, queryAll);
    return dispatchJobTransactions;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchJobTransactions
 *
 * @description Add a DispatchJobTransactions record
 *
 * @param {object} [keyValueObj] - The select fields we wish to add
 *
 * @returns {DispatchJobTransactions} - The created DispatchJobTransactions record
 */
async function addDispatchJobTransaction(keyValueObj) {
  try {
    const dispatchJobTransactions = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'DispatchJobTransactions', keyValueObj, getCurrentUserCompanyObjectId());
    return dispatchJobTransactions;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchJobTransactions
 *
 * @description Update a DispatchJobTransactions record
 * @param {Object} [options] - Currently contains the current user session token
 * @param {DispatchJobTransactions} dispatchJobTransactions - The DispatchJobTransactions record to update. Use if dispatchJobTransactionsObjectId is unavailable
 * @param {String} dispatchJobTransactionsObjectId - The objectId of the dispatchJobTransactions record to update. Use if dispatchJobTransactions is unavailable
 * @param {Object} keyValueObj - The select fields we wish to update
 * @param {Boolean} [save] - If true, executes save on the changes. If false, holds the changes locally but is not saved
 *
 * @returns {DispatchJobTransactions} - The updated DispatchJobTransactions record
 */
async function updateDispatchJobTransaction(options = { sessionToken: getCurrentUserSessionToken() }, dispatchJobTransactions, dispatchJobTransactionsObjectId, keyValueObj, save) {
  if (!dispatchJobTransactions && !dispatchJobTransactionsObjectId) throw new Error('Missing Arguments: Must provide dispatchJobTransactions record or dispatchJobTransactionsObjectId');
  if (dispatchJobTransactions && dispatchJobTransactionsObjectId) throw new Error('Arguments: Must provide only one of dispatchJobTransactions record or dispatchJobTransactionsObjectId');

  let _dispatchJobTransactions = dispatchJobTransactions;

  try {
    if (!dispatchJobTransactions && dispatchJobTransactionsObjectId) {
      const dispatchJobTransactionsQuery = createQuery('DispatchJobTransactions');
      setQueryRestriction(dispatchJobTransactionsQuery, QueryRestriction.EQUAL_TO, 'objectId', dispatchJobTransactionsObjectId);
      _dispatchJobTransactions = await findRecords(options, dispatchJobTransactionsQuery, true);
    }

    if (_dispatchJobTransactions) {
      if (!Object.keys(keyValueObj).length) return _dispatchJobTransactions;

      _dispatchJobTransactions = await updateRecord(options, _dispatchJobTransactions, keyValueObj, save);
      return _dispatchJobTransactions;
    } else {
      throw new Error('DispatchJobTransactions record does not exist');
    }
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchJobTransactions
 *
 * @description Destroy a DispatchJobTransactions record
 *
 * @param {DispatchJobTransactions} record - The DispatchJobTransactions record to destroy
 *
 * @returns {DispatchJobTransactions} - The destroyed DispatchJobTransactions record
 */
async function destroyDispatchJobTransaction(record) {
  if (!record) throw new Error('Missing Arguments: Must provide DispatchJobTransactions record');
  try {
    const dispatchJobTransaction = await destroyRecord({ sessionToken: getCurrentUserSessionToken() }, record);
    return dispatchJobTransaction;
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getDispatchJobTransactions,
  addDispatchJobTransaction,
  updateDispatchJobTransaction,
  destroyDispatchJobTransaction,
};
