import { sortQuery, getCurrentUser, addRecord, createQuery, setQueryRestriction, updateRecord, find, includePointers, getAttribute } from 'api/Parse';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { ELDEventTypeCode } from 'enums/ELDEventTypeCode';
import { ELDEventRecordStatus } from 'enums/ELDEventRecordStatus';
import moment from 'moment-timezone';

/** @module ELDEvent */

/**
 * @memberof module:ELDEvent
 * @description gets the unidentified ELDEvents in the last 7 days, excluding half days (events in days that haven't ended)
 *
 * @returns an array of ELDEvent parse objects
 */
async function getUnidentifiedELDEvents(startDate, endDate) {
  const eldEventQuery = createQuery('ELDEvent');
  const dateNow = new Date();
  const startTimeMoment = (startDate ? moment(startDate) : moment.utc(dateNow).subtract(7, 'days')).startOf('day');
  const endTimeMoment = (endDate ? moment(endDate) : moment.utc(dateNow).subtract(1, 'days')).endOf('day'); // exclude half day events
  const startTimeUTC = startTimeMoment.toDate();
  const endTimeUTC = endTimeMoment.toDate();
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'eventDateTime', endTimeUTC);
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'eventDateTime', startTimeUTC);
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'company', belongsToCompany);
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'unidentifiedDriver', true);
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EXISTS, 'eldDailyCertification');
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.DOES_NOT_EXIST, 'driver');
  // setQueryRestriction(eldEventQuery, QueryRestrictionTypes.CONTAINED_IN, 'eldEventRecordStatusInt', [0, 1]);
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'eldEventRecordStatusInt', 1);
  // setQueryRestriction(eldEventQuery, QueryRestrictionTypes.CONTAINED_IN, 'eldEventTypeCodeInt', [13, 21, 22]);
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'note', "");
  // setQueryRestriction(eldEventQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'isHidden', true);

  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EXISTS, 'vehicle', undefined);
  sortQuery(eldEventQuery, QuerySortOrderTypes.DESCENDING, 'eventDateTime');
  includePointers(eldEventQuery, [
    'eldDailyCertification',
    'vehicle',
  ]);
  const unidentifiedELDEvents = await find(eldEventQuery, false, true);
  return unidentifiedELDEvents;
}

/**
 * @memberof module:ELDEvent
 *
 * @param {*} eldEvent
 *
 * @returns
 */
async function getNextEldEvents(eldEvent) {
  const eldEventQuery = createQuery('ELDEvent');
  const eldDailyCertificationId = getAttribute(eldEvent, 'eldDailyCertification');
  // 'dzErAyUdx0'
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'eldDailyCertification', eldDailyCertificationId);
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'eldEventRecordStatusInt', 1);
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'unidentifiedDriver', true);
  sortQuery(eldEventQuery, QuerySortOrderTypes.DESCENDING, 'eventDateTime');
  const eldEvents = await find(eldEventQuery, false, true);
  let currentEventIndex = 0;
  const eldEventsLength = eldEvents.length;

  for (let eventIndex = 0; eventIndex < eldEventsLength; eventIndex++) {
    if (getAttribute(eldEvents[eventIndex], 'objectId') === getAttribute(eldEvent, 'objectId')) {
      currentEventIndex = eventIndex;
      break;
    };
  };

  const nextEldEvents = eldEvents[currentEventIndex - 1] ? eldEvents[currentEventIndex - 1] : eldEvents[currentEventIndex];
  return { eldEvent, nextEldEvents };
}

/**
 * @memberof module:ELDEvent
 * @description get the prior active driving eld event to verify for UD events
 *
 * @param {*} eventDateTime start time of the UD interval
 *
 * @returns eldEvent object that was prior to UD event
 */
async function getPriorIdentifiedDrivingEldEvent(startDateTime, endDateTime, vehicleUnitId) {
  const eldEventQuery = createQuery('ELDEvent');
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  const startTimeOfUDEvent = moment(startDateTime);
  const endTimeOfUDEvent = moment(endDateTime).toDate();
  const startIntervalOfIDEvents = moment(startDateTime).subtract(2, 'hours').toDate();

  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'company', belongsToCompany);
  //we want to grab the most recent event that happened right before
  if (!endDateTime) {
    setQueryRestriction(eldEventQuery, QueryRestrictionTypes.LESS_THAN, 'eventDateTime', startTimeOfUDEvent.toDate());
    setQueryRestriction(eldEventQuery, QueryRestrictionTypes.GREATER_THAN, 'eventDateTime', startIntervalOfIDEvents);
    sortQuery(eldEventQuery, QuerySortOrderTypes.DESCENDING, 'eventDateTime');
  }
  if (endDateTime) { //the purpose of this is to check if there's events happening between intervals
    setQueryRestriction(eldEventQuery, QueryRestrictionTypes.LESS_THAN, 'eventDateTime', endTimeOfUDEvent);
    setQueryRestriction(eldEventQuery, QueryRestrictionTypes.GREATER_THAN, 'eventDateTime', startTimeOfUDEvent.add(50, 'seconds').toDate());
    sortQuery(eldEventQuery, QuerySortOrderTypes.ASCENDING, 'eventDateTime');
  }
  //we also only want to grab events that are driving events or duty status changes
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.CONTAINED_IN, 'eldEventTypeCodeInt', [11, 12, 14, 13, 21, 22]);
  //the following makes sure it is an identified active event
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'eldEventRecordStatusInt', 1);
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'unidentifiedDriver', false);
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EXISTS, 'eldDailyCertification');
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EXISTS, 'driver');
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'vehicleUnitId', vehicleUnitId);
  includePointers(eldEventQuery, [
    'eldDailyCertification',
    'vehicle',
    'driver'
  ]);
  //we only want the first ID event that happened prior
  const identifiedELDEvent = await find(eldEventQuery, true);
  // console.log(startTimeOfUDEvent, startIntervalOfIDEvents, vehicleUnitId);
  // console.log(getAttribute(identifiedELDEvent, 'vehicleUnitId'), getAttribute(identifiedELDEvent, 'eventDateTime'), getAttribute(identifiedELDEvent, 'eldEventTypeCodeInt'), identifiedELDEvent);
  return identifiedELDEvent;
}

/**
 * @memberof module:ELDEvent
 * @description get suggested drivers within a 24 hour period of UD for that vehicle
 *
 * @param {*} udDailyCertObjectId daily cert object id of unidentified events
 * @param {*} vehicleUnitId vehicle unit id
 * @param {*} startTimeUTC start of 24 hour period
 * @param {*} endTimeUTC end of 24 hour period
 */
async function getSuggestedDriversForUD(udDailyCertObjectId, vehicleUnitId, startTimeUTC, endTimeUTC) {
  const eldEventQuery = createQuery('ELDEvent');
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'company', belongsToCompany);
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'eldDailyCertification', udDailyCertObjectId);
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.GREATER_THAN, 'eventDateTime', moment(startTimeUTC).toDate());
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.LESS_THAN, 'eventDateTime', moment(endTimeUTC).toDate());
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'vehicleUnitId', vehicleUnitId);
  includePointers(eldEventQuery, [
    'eldDailyCertification',
    'eldDailyCertification.driver'
  ]);
  const identifiedELDEvents = await find(eldEventQuery, false, true);

  const suggestedDrivers = [];
  const suggestedDriverIds = [];
  identifiedELDEvents.map(eldEvent => {
    if (eldEvent) {
      const dailyCert = getAttribute(eldEvent, 'eldDailyCertification');
      if (dailyCert) {
        const dailyCertDriver = getAttribute(dailyCert, 'driver');
        if (dailyCertDriver) {
          const driverObjectId = getAttribute(dailyCertDriver, 'objectId', true);
          if (driverObjectId && !suggestedDriverIds.includes(driverObjectId)) {
            suggestedDrivers.push(dailyCertDriver);
            suggestedDriverIds.push(driverObjectId);
          }
        }
      }
    }
  });

  return suggestedDrivers;
}

/**
 * @memberof module:ELDEvent
 * @description Given an array of eldEvents, remove interm events that appear out of place (ex. interms following a non-driving status)
 *
 * @param {array} eldEvents - array of eldEvent records
 *
 * @returns Array of filtered eld events
 */
function removeMisplacedIntermELDEvents(eldEvents) {
  const mainELDEventDutyStatusTypeCodes = [
    ELDEventTypeCode.OFF_DUTY, ELDEventTypeCode.SLEEPER_BERTH, ELDEventTypeCode.DRIVING,
    ELDEventTypeCode.ON_DUTY_NOT_DRIVING, ELDEventTypeCode.AUTHORIZED_PERSONAL_USE_OF_CMV,
    ELDEventTypeCode.YARD_MOVES
  ];

  // if interm events come after one of these statuses, ignore them
  const ignoreIfELDEventDutyStatusTypeCodes = [ELDEventTypeCode.OFF_DUTY, ELDEventTypeCode.SLEEPER_BERTH, ELDEventTypeCode.ON_DUTY_NOT_DRIVING];
  const intermELDEventTypeCodes = [ELDEventTypeCode.INTERMEDIATE_LOG_WITH_CONVENTIONAL_LOCATION_PRECISION, ELDEventTypeCode.INTERMEDIATE_LOG_WITH_REDUCED_LOCATION_PRECISION];

  let lastSeenELDEventTypeCode;
  const filteredELDEvents = eldEvents.filter(eldEvent => {
    const eldEventTypeCodeInt = getAttribute(eldEvent, 'eldEventTypeCodeInt');
    const isMainELDEventTypeCode = mainELDEventDutyStatusTypeCodes.indexOf(eldEventTypeCodeInt) !== -1;
    const isIntermEvent = intermELDEventTypeCodes.indexOf(eldEventTypeCodeInt) !== -1;

    // if the lastSeenELDEventTypeCode is a duty status where we should ignore interm events
    const isLastSeenELDEventTypeCodeShouldIgnore = (lastSeenELDEventTypeCode !== undefined) && (ignoreIfELDEventDutyStatusTypeCodes.indexOf(lastSeenELDEventTypeCode) !== -1);

    if ([ELDEventRecordStatus.ACTIVE_OLD, ELDEventRecordStatus.ACTIVE].indexOf(eldEvent.get('eldEventRecordStatusInt')) !== -1) { // only concerned with active events here
      if (isMainELDEventTypeCode) {
        lastSeenELDEventTypeCode = eldEventTypeCodeInt;
      } else if (isIntermEvent && isLastSeenELDEventTypeCodeShouldIgnore) {
        return false;
      }
    }

    return true;
  });

  return filteredELDEvents;
}

export {
  getUnidentifiedELDEvents,
  getNextEldEvents,
  getPriorIdentifiedDrivingEldEvent,
  getSuggestedDriversForUD,
  removeMisplacedIntermELDEvents,
};
