import React from 'react';

// Components
import DispatchAccessorialCard from 'components/Dispatch/DispatchAccessorialSummary/DispatchAccessorialCard';

// Styles
import './style.scss';

/**
 * @description Displays a list of DispatchAccessorials as cards
 * @param {String} className - Custom container className
 * @param {Array} dispatchAccessorials - List of DispatchAccessorials to display
 * @param {Boolean} isLoading - Parent's isLoading state
 * @param {Function} openAccessorialEditDialog - Function that opens the add/edit accessorial dialog
 * @param {Function} deleteDispatchAccessorial - Function called to delete a DispatchAccessorial
 *
 * @returns
 *
 * @example
 * <DispatchAccessorialCardsView />
 */
function DispatchAccessorialCardsView({ ...props }) {

  // ** Misc ** //
  let className = 'dispatch-accessorial-cards-view';
  if (props.className) className += ` ${props.className}`;

  const dispatchAccessorialCards = (
    <>
      {props.dispatchAccessorials && props.dispatchAccessorials.map((dispatchAccessorial) => (
        <DispatchAccessorialCard
          dispatchAccessorial={dispatchAccessorial}
          isLoading={props.isLoading}
          openAccessorialEditDialog={props.openAccessorialEditDialog}
          deleteDispatchAccessorial={props.deleteDispatchAccessorial}
        />
      ))}
    </>
  );

  return (
    <div className={className}>
      <div className="grid">
        {dispatchAccessorialCards}
      </div>
    </div>
  );
}

export default DispatchAccessorialCardsView;
