import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';
import { connect } from 'react-redux';
import { getQueryParameter } from 'api/URL';
import { getAttribute, getCurrentUser } from 'sb-csapi/dist/AAPI';

// Components
import Sidebar from './Sidebar';
import SummaryLayout from './SummaryLayout';
import IssuesLayout from './IssuesLayout';
import TripInspectionsLayout from './TripInspectionsLayout';
import DriverELDIssuesLayout from './DriverELDIssuesLayout';
import DriverHoursLayout from './DriverHoursLayout/DriverHoursLayout';
import HOSViolationsLayout from './HOSViolationsLayout';
// import ELDViolationsLayout from './ELDViolationsLayout';
import HOSEditsLayout from './HOSEditsLayout';
import UnidentifiedDrivers from 'components/UnidentifiedDrivers/UnidentifiedDrivers';
import HOSViolationDetails from 'components/HOSViolationDetails/HOSViolationDetails';
import OdometerJumpReportLayout from 'layouts/SafetyLayout/OdometerJumpReportLayout/OdometerJumpReportLayout';
import RemindersLayout from 'layouts/SafetyLayout/RemindersLayout';
import SpeedReportLayout from 'layouts/SafetyLayout/SpeedReportLayout/SpeedReportLayout';
import UncertifiedLogs from 'components/UncertifiedLogs/UncertifiedLogs';
import HOSLogDownloadHistory from 'components/HOSLogDownloadHistory/HOSLogDownloadHistory';

// CSS
import styles from './SafetyLayout.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

class SafetyLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subViewSelected: '',
      walkthroughState: {
        hosViolations: false,
      },
      isSidebarOpen: true,
    };
    this.handleSubViewClick = this.handleSubViewClick.bind(this);
    this.toggleWalkthroughState = this.toggleWalkthroughState.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  componentDidMount() {
    document.title = 'Safety - Switchboard';

    let tempView = getQueryParameter(this.props.location.search, 'view');
    this.setState({ ...this.state, subViewSelected: tempView ? tempView : 'driverHours' });
  }

  componentWillReceiveProps(nextProps) {
    let newView = getQueryParameter(nextProps.location.search, 'view');

    const currentUser = getCurrentUser();
    const userSpecialPermission = getAttribute(currentUser, 'userSpecialPermission', true);
    const isDisableDriversModule = getAttribute(userSpecialPermission, 'disableDriversModule', true);

    if (isDisableDriversModule && newView === 'uncertifiedlogs') {
      return history.push({ pathname: '404' });
    }

    if (isDisableDriversModule && newView === 'logstodownload') {
      return history.push({ pathname: '404' });
    }

    this.setState({ ...this.state, subViewSelected: newView ? newView : 'driverHours' });
  }

  handleSubViewClick(subview) {
    history.push({ pathname: 'safety', search: 'view=' + subview });
  }

  toggleWalkthroughState(walkthroughStateAttribute) {
    const newState = { ...this.state };
    newState.walkthroughState = { ...this.state.walkthroughState };
    newState.walkthroughState[walkthroughStateAttribute] = !this.state.walkthroughState[walkthroughStateAttribute];
    this.setState(newState);
  }

  toggleSidebar() {
    this.setState({ ...this.state, isSidebarOpen: !this.state.isSidebarOpen });
  }

  render() {
    return (
      <div>
        <Sidebar isOpen={this.state.isSidebarOpen} activeSubview={this.state.subViewSelected} handleSubViewClick={this.handleSubViewClick} Company={this.props.Company} toggleWalkthroughState={this.toggleWalkthroughState} walkthroughState={this.state.walkthroughState} toggleSidebar={this.toggleSidebar} />
        <div className={styles.content + (this.state.isSidebarOpen ? ' ' + styles.sideOpen : '')}>
          {this.state.subViewSelected === 'summary' &&
            <SummaryLayout />
          }
          {this.state.subViewSelected === 'issues' &&
            <IssuesLayout />
          }
          {this.state.subViewSelected === 'tripInspections' &&
            <TripInspectionsLayout location={this.props.location} />
          }
          {this.state.subViewSelected === 'driverHours' &&
            <DriverHoursLayout />
          }
          {this.state.subViewSelected === 'hosViolations' &&
            <HOSViolationsLayout location={this.props.location} />
          }
          {this.state.subViewSelected === 'hosEdits' &&
            <HOSEditsLayout dispatcher={this.props.Dispatcher.dispatcher} />
          }
          {this.state.subViewSelected === 'unsignedLogs' &&
            <DriverELDIssuesLayout />
          }
          {this.state.subViewSelected === 'unidentifiedDriving' &&
            <UnidentifiedDrivers location={this.props.location} />
          }
          {this.state.subViewSelected === 'hosViolationDetails' &&
            <HOSViolationDetails location={this.props.location} />
          }
          {this.state.subViewSelected === 'odometerJumpReport' &&
            <OdometerJumpReportLayout location={this.props.location} />
          }
          {this.state.subViewSelected === 'reminders' &&
            <RemindersLayout location={this.props.location} />
          }
          {this.state.subViewSelected === 'speedReport' && (
            <SpeedReportLayout location={this.props.location} />
          )}
          {this.state.subViewSelected === 'uncertifiedlogs' && (
            <div style={{ padding: '1.5em', paddingLeft: '3em' }}>
              <UncertifiedLogs />
            </div>
          )}
          {this.state.subViewSelected === 'logstodownload' && (
            <div style={{ padding: '1.5em', paddingLeft: '3.25em', paddingRight: '3.25em' }}>
              <HOSLogDownloadHistory belongsToCompany={this.props.Company} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

SafetyLayout.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,
  Company: PropTypes.object,
  Dispatcher: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { Dispatcher, Company } = state;
  return {
    Company,
    Dispatcher,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(SafetyLayout);
