import React from 'react';
import PropTypes from 'prop-types';

// Components
import HelpDialog from 'components/HelpDialog/container/HelpDialog';

class DriversHelpDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const uListStyle = { marginTop: '1em', paddingLeft: '1.2em', fontSize: '.85em' };
    const listStyle = { marginBottom: '.5em' };

    return (
      <HelpDialog title="Drivers Page" buttonStyle={this.props.buttonStyle}>
        <div style={{ marginTop: '2em', marginBottom: '2em' }}>
          What can I do here?
          <ul style={uListStyle}>
            <li style={listStyle}>
              <b>Tabs</b>: Depending on what device settings you have, you will notice various sub-tabs on this page, including but not limited to: <b>All Drivers</b> (list of all your drivers), <b>Unidentified ELD</b> (list of unidentified driving time), <b>Driver Edits</b> (list of Driver requested AOBRD Edits), and <b>Download Logs</b> (download your driver logs)
            </li>
            <li style={listStyle}>
              <b>Add a Driver</b>: Adding a Driver(s) is the first thing to do if you have not already. By adding a Driver, you can begin to see their logs, live tracking of their driving, and much more. To add a Driver, simply click on the <b>Add Driver</b> button and enter the required fields. When you&#39;re done you&#39;ll notice they got added to your <b>All Drivers</b> list
            </li>
            <li style={listStyle}>
              <b>Driver Details</b>: After adding a Driver they will appear as a row on the <b>All Drivers</b> list with a button named <b>View Details</b> on the right-hand side. Click that button to view and edit all information pertaining to your driver and their logs. This button is essentially an access point to all things related to your driver
            </li>
          </ul>
        </div>
      </HelpDialog>
    );
  }
}

DriversHelpDialog.propTypes = {
  buttonStyle: PropTypes.object,
};

export default DriversHelpDialog;
