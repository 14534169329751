import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

// sbCore
import Card from 'sbCore/Card/Card';
import Button from 'sbCore/Button/Button';
import Checkbox from 'sbCore/Checkbox/Checkbox';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Calendar from 'sbCore/Calendar/Calendar';
import Divider from 'sbCore/Divider/Divider';

// component
import DispatchOrganizationAutocompleteInput from 'components/Dispatch/DispatchOrganizationAutocomplete/DispatchOrganizationAutocompleteInput';
import DispatchContactAutocompleteInput from 'components/Dispatch/DispatchContactAutocomplete/DispatchContactAutocompleteInput';
import DispatchAppointmentDropdown from 'components/Dispatch/DispatchAppointmentDropdown/DispatchAppointmentDropdown';

// api
import { getDispatchTransfers, addDispatchTransfer, updateDispatchTransfer } from 'api/Dispatch/DispatchTransfer';

// sb-csapi
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getAddressString } from 'sb-csapi/dist/api/Address/Address';

// style
import './style.scss';

/**
 * @description Renders the content for dispatch shipment timeline
 * @param {Object} dispatchTransfer - parsed dispatch transfer object
 * @param {Function} onSelectShipment - Call back when a transfer is selected
 * @param {Function} setRefreshState - Call back to flip the refresh state flag, no param needed
 * @returns
 */
function DispatchShipmentTimelineCard({ ...props }) {
  const [dispatchTransfer, setDispatchTransfer] = useState({}); // parsed dispatch transfer
  const [isActive, setIsActive] = useState(false); // whether the card should be highlighted
  const [showOrganizationInput, setShowOrganizationInput] = useState(); // whether the organization input/dropdown should be shown
  const [showContactInput, setShowContactInput] = useState(); // whether the contact input/dropdown should be shown

  /* -------------------------------- useEffect ------------------------------- */
  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      setDispatchTransfer(props.dispatchTransfer);
      setShowOrganizationInput(
        props.dispatchTransfer?.shipperDispatchOrganization || props.dispatchTransfer?.consigneeDispatchOrganization
          ? false
          : true
      );
      setShowContactInput(props.dispatchTransfer?.dispatchOrganizationContact ? false : true);
    }

    return () => {
      didCancel = true;
    };
  }, [props.dispatchTransfer]);

  /* --------------------------------- Helper --------------------------------- */
  // Handles dispatch transfer update and refreshes dispatch transfer object
  async function handleDispatchTransferUpdate(keyValueObj) {
    setDispatchTransfer({ ...dispatchTransfer, keyValueObj });
    const objectId = dispatchTransfer.objectId;

    if (dispatchTransfer.objectId) {
      try {
        const dispatchTransfer = await updateDispatchTransfer(undefined, objectId, keyValueObj, true);
      } catch (err) {
        console.log(err);
      }
    }
    props.setRefreshState();
  }

  function getOrganizationString() {
    const dispatchOrganization =
      dispatchTransfer.shipperDispatchOrganization || dispatchTransfer.consigneeDispatchOrganization;

    const name = getAttribute(dispatchOrganization, 'organizationName', true);

    const address = getAttribute(getAttribute(dispatchOrganization, 'address', true), 'address', true) || '-';

    return `${name} | ${address}`;
  }

  function getDestinationLabel(type) {
    if (type === 'pickup') return 'Pickup';
    if (type === 'dropoff') return 'Dropoff';
    if (type === 'transfer point') return 'Transfer Point';
  }

  /* --------------------------------- content -------------------------------- */
  // Main timeline content
  function timelineContent(dispatchTransfer) {
    return (
      <Card
        className={`${isActive ? 'active-shipment-timeline-card' : ''} shipment-timeline-card mb-2 flex flex-column`}
      >
        <div className="flex flex-row mb-3">
          {destinationTemplate(dispatchTransfer)}
          <Divider layout="vertical" />
          {datetimeTemplate(dispatchTransfer)}
          <Divider layout="vertical" />
          <div
            onClick={() => {
              props.onSelectShipment(); // debug
              setIsActive(!isActive);
            }}
            className="timeline-card-clickable"
          >
            <span className="material-icons text-2xl my-auto">{isActive ? 'expand_less' : 'expand_more'}</span>
          </div>
        </div>
        {isActive && expandContentTemplate(dispatchTransfer)}
      </Card>
    );
  }

  /* -------------------------------- Template -------------------------------- */
  // template for destination and contact
  function destinationTemplate(dispatchTransfer) {
    return (
      <div>
        <InputLabel>{getDestinationLabel(dispatchTransfer.type)}</InputLabel>
        {!showOrganizationInput && (
          <Button
            label={getOrganizationString()}
            text
            size="small"
            onClick={() => setShowOrganizationInput(true)}
            severity="secondary"
          />
        )}
        {showOrganizationInput && (
          <DispatchOrganizationAutocompleteInput
            type="isShipper"
            className="max-w-12rem"
            CustomerDispatchOrganization={
              dispatchTransfer.shipperDispatchOrganization || dispatchTransfer.consigneeDispatchOrganization
            }
            onSelectDispatchOrganization={(organization) => {
              // Don't update if there's no organization selected, cuz there shouldn't be a time where the user would want to clear the organization
              if (!organization) return;
              if (dispatchTransfer.type === 'transfer point') {
                handleDispatchTransferUpdate({
                  shipperDispatchOrganization: organization,
                  consigneeDispatchOrganization: organization,
                });
              }
              if (dispatchTransfer.type === 'pickup') {
                handleDispatchTransferUpdate({ shipperDispatchOrganization: organization });
              }
              if (dispatchTransfer.type === 'dropoff') {
                handleDispatchTransferUpdate({ consigneeDispatchOrganization: organization });
              }
            }}
          />
        )}
      </div>
    );
  }

  // template for pickup/dropoff date/time
  function datetimeTemplate(dispatchTransfer) {
    // Disable calendar input from before yesterday
    const now = moment().subtract(1, 'days').toDate();

    return (
      <div className="flex flex-row column-gap-3">
        <div className="flex flex-column row-gap-3">
          {(dispatchTransfer.type === 'transfer point' || dispatchTransfer.type === 'pickup') && (
            <div className="flex flex-row column-gap-3">
              <div className="shipment-timeline-date max-w-10rem">
                <InputLabel>Pickup DateTime</InputLabel>
                <Calendar
                  className="p-inputtext-sm"
                  showTime
                  hourFormat="24"
                  dateFormat="yy-mm-dd"
                  mask="9999-99-99 99:99"
                  value={dispatchTransfer.pickupDateTime}
                  warning={!dispatchTransfer.pickupDateTime}
                  error={!dispatchTransfer.pickupDateTime}
                  onChange={(e) => {
                    handleDispatchTransferUpdate({ pickupDateTime: e.value });
                  }}
                  minDate={now}
                />
              </div>
              <DispatchAppointmentDropdown
                label="Pickup Appointment"
                className="p-inputtext-sm"
                appointment={dispatchTransfer.pickupAppointmentStatus}
                onSelect={(e) => {
                  handleDispatchTransferUpdate({ pickupAppointmentStatus: e.value });
                }}
              />
            </div>
          )}
          {(dispatchTransfer.type === 'transfer point' || dispatchTransfer.type === 'dropoff') && (
            <div className="flex flex-row column-gap-3">
              <div className="shipment-timeline-date max-w-10rem">
                <InputLabel>Dropoff DateTime</InputLabel>
                <Calendar
                  className="p-inputtext-sm"
                  showTime
                  hourFormat="24"
                  dateFormat="yy-mm-dd"
                  mask="9999-99-99 99:99"
                  value={dispatchTransfer.dropoffDateTime}
                  warning={!dispatchTransfer.dropoffDateTime}
                  error={!dispatchTransfer.dropoffDateTime}
                  onChange={(e) => {
                    handleDispatchTransferUpdate({ dropoffDateTime: e.value });
                  }}
                  minDate={now}
                />
              </div>
              <DispatchAppointmentDropdown
                label="Dropoff Appointment"
                className="p-inputtext-sm"
                appointment={dispatchTransfer.dropoffAppointmentStatus}
                onSelect={(e) => {
                  handleDispatchTransferUpdate({ dropoffAppointmentStatus: e.value });
                }}
              />
            </div>
          )}
        </div>
      </div>
    );
  }

  // template for expandible part of the card
  function expandContentTemplate(dispatchTransfer) {
    return (
      <div className="flex flex-row column-gap-3">
        <div>
          <InputLabel>Contact</InputLabel>
          {!showContactInput && (
            <Button
              label={getAttribute(dispatchTransfer.dispatchOrganizationContact, 'name', true)}
              text
              size="small"
              onClick={() => setShowContactInput(true)}
              severity="secondary"
            />
          )}
          {showContactInput && (
            <DispatchContactAutocompleteInput
              className="max-w-12rem"
              onSelectDispatchContact={(contact) => {
                if (!contact) return; // prevents user from unselecting contact
                handleDispatchTransferUpdate({ dispatchOrganizationContact: contact });
              }}
            />
          )}
        </div>
        {dispatchTransfer.type === 'transfer point' && (
          <div className="shipment-timeline-internal-shipment">
            <InputLabel>Hide from customer</InputLabel>
            <Checkbox
              checked={dispatchTransfer.internal}
              onChange={(e) => {
                handleDispatchTransferUpdate({ internal: e.checked });
              }}
              className="pt-1"
            />
          </div>
        )}
      </div>
    );
  }

  return timelineContent(dispatchTransfer);
}

export default DispatchShipmentTimelineCard;
