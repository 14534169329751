import React, { useState, useEffect, useRef } from 'react';
import uniqid from 'uniqid';
import moment from 'moment-timezone';

// API
import { updateDispatchTransfer } from 'api/Dispatch/DispatchTransfer';

// CSAPI
import { getRecordByObjectId, getCurrentUserSessionToken, getAttribute } from 'sb-csapi/dist/AAPI';

// Enums
import { Status } from 'sb-csapi/dist/enums/Dispatch/Transfer';

// sbCore Components
import Button from 'sbCore/Button/Button';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import Dialog from 'sbCore/Dialog/Dialog';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Skeleton from 'sbCore/Skeleton/Skeleton';

// Styles
import './style.scss';

/**
 * @description Dropdown that allows selecing a dispatch transfer status and displays the selected status
 * @param {String} dispatchTransferObjectId - Required.The dispatchTransfer we want to retrieve/update the status for
 * @param {String} className - Optional. Custom container className
 * @param {Object} style - Optional. Custom inline styles
 * @param {bool} warning - Optional. Makes the border yellow
 * @param {bool} autoFocus - Optional. Whether to automatically focus on the autocomplete on load
 * @param {bool} isFocusedDefault - Optional. Whether this component is the first component to be focused on. Determines if we should set isFocused to true when mounting
 * @param {bool} showOnFocus - Optional. Whether to automatically open the dropdown on focus
 * @param {bool} hideLabel - Optional. Hide the label
 * @param {bool} isLoading - Optional. Show a loading skeleton
 * @param {bool} disabled - TODO: Whether to disable the component
 * @param {Function} [handleRefresh] - Refreshes the state of the parent component
 * @returns
 */
function DispatchTransferStatusDropdown({ ...props }) {

  // determines whether or not the dispatchTransfer should be updated
  const dispatchTransferUpdaterRef = useRef(false);

  // retrieve an array of transfer statuses
  const statuses = Object.keys(Status).map(key => {
    return Status[key];
  });

  // ** useStates ** //

  // for each of this component that exists on the same page, give it unique identifier for specific dom manipulation
  const [identifier] = useState(uniqid());

  // the selected status object
  const [selectedStatusObj, setSelectedStatusObj] = useState(null);

  const [dispatchTransfer, setDispatchTransfer] = useState(undefined);
  const [isInitialized, setIsInitialized] = useState(undefined);

  const [isFocused, setIsFocused] = useState(props.isFocusedDefault);

  // if there is an error with the input
  const [hasError, setHasError] = useState(false);

  const [showChangeStatusDialog, setShowChangeStatusDialog] = useState(false);
  const [dialogStatusValue, setDialogStatusValue] = useState(null);

  // ** useEffects ** //

  // runs once, when a props.dispatchTransferObjectId is past
  useEffect(() => {
    if (!props.dispatchTransferObjectId) return;

    let didCancel = false;

    async function _getDispatchTransfer() {
      const _dispatchTransfer = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'DispatchTransfer', props.dispatchTransferObjectId);
      let status = getAttribute(_dispatchTransfer, 'status', true);

      // If status hasn't been modified by user, use the transfer dates to estimate the current status
      const isStatusModified = getAttribute(_dispatchTransfer, 'isStatusModified');
      if (!isStatusModified) {
        let isPickupDatePast;
        let isDropoffDatePast;

        let pickupDateTime = getAttribute(_dispatchTransfer, 'pickupDateTime');
        if (pickupDateTime) {
          const timezone = getAttribute(_dispatchTransfer, 'timezoneOffsetFromUTC') || moment.tz.guess();
          pickupDateTime = moment(pickupDateTime).tz(timezone).format('YYYY-MM-DD HH:mm');
          isPickupDatePast = isDateTimePastCurrent(pickupDateTime);
        }

        let dropoffDateTime = getAttribute(_dispatchTransfer, 'dropoffDateTime');
        if (dropoffDateTime) {
          const timezone = getAttribute(_dispatchTransfer, 'timezoneOffsetFromUTC') || moment.tz.guess();
          dropoffDateTime = moment(dropoffDateTime).tz(timezone).format('YYYY-MM-DD HH:mm');
          isDropoffDatePast = isDateTimePastCurrent(dropoffDateTime);
        }

        // Update the status to delivered - presumed if dropoffDateTime is past the current date time, else
        // to in-transit if pickupDateTime is past the current date, else default to scheduled.
        let newStatus = status;
        if (isDropoffDatePast && (status !== Status.DELIVERED_PRESUMED.status)) {
          newStatus = Status.DELIVERED_PRESUMED.status;
        } else if (isPickupDatePast && (status !== Status.IN_TRANSIT.status) && !isDropoffDatePast) {
          newStatus = Status.IN_TRANSIT.status;
        } else if (!isPickupDatePast && !isDropoffDatePast) {
          newStatus = Status.SCHEDULED.status;
        }

        if (newStatus !== status) {
          status = newStatus;
          await updateDispatchTransfer(_dispatchTransfer, undefined, { status }, true);
        }
      }

      let selectedStatusObj;

      // if the dispatchTransfer doesn't have a status, update it and set it's default status to SCHEDULED
      // else, find the status object associated with the dispatch transfer's status.
      // since there's a status value that's 0, check if status is undefined instead
      if (status === undefined) {
        selectedStatusObj = statuses.find((statusObj) => {
          return statusObj.status === Status.SCHEDULED.status;
        });
      } else {
        selectedStatusObj = statuses.find((statusObj) => {
          return statusObj.status === status;
        });
      }

      if (!didCancel) {
        setDispatchTransfer(_dispatchTransfer);
        setSelectedStatusObj(selectedStatusObj);
      }
    }

    _getDispatchTransfer();

    return () => { didCancel = true; };
  }, [props.dispatchTransferObjectId]);

  // runs each time the selectedStatusObj changes
  useEffect(() => {
    let didCancel = false;

    if (!selectedStatusObj) return;

    // if dispatchTransfer's status has already initially been updated, set isInitialized to false and return
    if (!dispatchTransferUpdaterRef.current) {
      dispatchTransferUpdaterRef.current = true;
      return;
    }

    const keyValueObj = {
      status: selectedStatusObj.status,
    };

    async function _updateDispatchTransfer() {
      if (!didCancel && dispatchTransfer) {
        await updateDispatchTransfer(dispatchTransfer, undefined, keyValueObj, true);
      }
    }

    _updateDispatchTransfer();

    return () => { didCancel = true; };
  }, [selectedStatusObj]);

  // ** Helper Functions ** //
  function onChange(e) {
    if (!dispatchTransfer) return;

    const isStatusModified = getAttribute(dispatchTransfer, 'isStatusModified');
    if (!isStatusModified) {
      setDialogStatusValue(e.value);
      setShowChangeStatusDialog(true);
    } else {
      setSelectedStatusObj(e.value);
    }
  }

  // determine whether or not this component is focused on, to enable additional functionality
  function toggleFocus() {
    setIsFocused(!isFocused);
  }

  // find the matching background color className (ex. SOME_VALUE -> some-value -> bg-dispatch-action-some-value)
  function getTransferStatusColorClass(status) {
    let className;

    Object.keys(Status).find(key => {
      const statusObject = Status[key];
      const isMatchingStatus = statusObject.status === status;

      if (isMatchingStatus) {
        const colorClassKeyExpression = key.replace(/_/g, '-').toLowerCase();
        className = `bg-dispatch-action-${colorClassKeyExpression}`;
      }
      return isMatchingStatus;
    });

    return className;
  }

  // Checks if the given date time is past the current date time
  function isDateTimePastCurrent(dateTime) {
    const currentDateTime = moment();
    const dateTimeDifference = currentDateTime.diff(dateTime);
    const dateTimeDifferenceSign = Math.sign(dateTimeDifference);

    let isDateTimePast = false;
    if (dateTimeDifferenceSign === 1) {
      isDateTimePast = true;
    }

    return isDateTimePast;
  }

  // Callback function that updates the status and transfer record after manually changing the status
  async function handleShowChangeStatusDialog() {
    await updateDispatchTransfer(dispatchTransfer, undefined, { isStatusModified: true }, true);
    setSelectedStatusObj(dialogStatusValue);
    setShowChangeStatusDialog(false);
    if (props.handleRefresh) props.handleRefresh();
  }

  // ** Dropdown Templates ** //
  const itemTemplate = (option) => {
    const className = `transfer-status-dropdown-item ${getTransferStatusColorClass(option.status)}`;

    return (
      <div className={className}>
        {option.description}
      </div>
    );
  };

  const valueTemplate = (option) => {
    if (!option) return;
    const className = `dispatch-transfer-status-dropdown-item text-center ${getTransferStatusColorClass(option.status)}`;

    return (
      <div className={className}>
        {option.description}
      </div>
    );
  };

  // ** Misc ** //
  let className = `dispatch-transfer-status-dropdown ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  const title = 'Update This Status?';

  // if the input is not being focused on (which is for some reason dictated by the button's focus), but the user left their search term without selecting a suggestion for the searchTerm
  // const isNotFocusedWithText = inputButtonEl && searchTerm && ((typeof searchTerm) !== 'object') && (document.activeElement !== inputButtonEl);
  // const _hasError = isNotFocusedWithText || hasError;
  const _hasError = false;

  return (
    <div className={className} style={{ ...props.style }}>

      {!props.hideLabel && (
        <InputLabel>Status</InputLabel>
      )}

      {props.isLoading && (
        <Skeleton width="10rem" height="2.5rem" />
      )}

      {!props.isLoading && (
        <Dropdown
          value={selectedStatusObj}
          options={statuses}
          onChange={(e) => onChange(e)}
          onFocus={(e) => toggleFocus(e)}
          onBlur={() => toggleFocus()}
          filter
          filterBy="description"
          optionLabel="description"
          placeholder="Select Status"
          valueTemplate={valueTemplate}
          itemTemplate={itemTemplate}
          autoFocus={props.autoFocus}
          warning={props.warning}
          error={_hasError}
          showOnFocus={props.showOnFocus || true}
          disabled={props.disabled}
        />
      )}

      {!props.isLoading && (
        <Dialog
          className="dispatch-transfer-status-change-dialog"
          header={title}
          visible={showChangeStatusDialog}
          onHide={() => setShowChangeStatusDialog(false)}
          resizeable={false}
          draggable={false}
        >
          <div className="flex flex-row">
            <Button icon="pi pi-times" label="Cancel" disabled={props.isLoading} onClick={() => setShowChangeStatusDialog(false)} />
            <Button icon="pi pi-check" label="Confirm" disabled={props.isLoading} onClick={async () => handleShowChangeStatusDialog()} />
          </div>
        </Dialog>
      )}

    </div>
  );
}

export default DispatchTransferStatusDropdown;
