import React from 'react';
import { DropTarget } from 'react-dnd';
import { DNDTypes } from 'enums/DNDTypes';

import './style.scss'
/**
 * Specifies the drag source contract.
 * Only `beginDrag` function is required.
 */
const actionTarget = {
  drop(props, monitor) {
    props.handleDrop(monitor.getItem().index, props.index);
  }
}

/**
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  }
}

function SBDropRow(props) {
  // Your component receives its own props as usual
  const { id } = props

  // These two props are injected by React DnD,
  // as defined by your `collect` function above:
  const { isOver, connectDropTarget, canDrop } = props

  return connectDropTarget(<tr className="drop-target"><td colSpan="69"></td></tr>);
}

export default DropTarget(DNDTypes.ACTION, actionTarget, collect)(SBDropRow)