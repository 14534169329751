import { configureStore } from '@reduxjs/toolkit';

// Import APIs
import { vehicleApi } from 'api/Equipment/Vehicle';
import { trailerApi } from 'api/Equipment/Trailer';

// Import slices
import subscriptionReducer from 'slices/SubscriptionPermissionSlice';

// This contains the new store
export default configureStore({
  reducer: {
    [vehicleApi.reducerPath]: vehicleApi.reducer,
    [trailerApi.reducerPath]: trailerApi.reducer,
    subscriptions: subscriptionReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(vehicleApi.middleware, trailerApi.middleware),
});
