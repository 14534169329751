import React, { useState, useEffect } from 'react';

// api
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { CountryName } from 'sb-csapi/dist/enums/Country';

// components
import Skeleton from 'sbCore/Skeleton/Skeleton';
import Button from 'sbCore/Button/Button';

// enums
// import { Organization } from 'sb-csapi/dist/enums/Dispatch/Organization';

/**
 * @description Display a DispatchOrganization's information
 * @param {DispatchOrganization} dispatchOrganization - The DispatchOrganization
 * @param {bool} isLoading - Whether or not to show a loading skeleton
 * @param {Function} onUnselectDispatchOrganization - Callback when the remove button is clicked. Passes back the dispatchOrganization if the user wants it
 * @param {Function} onToggleEditDispatchOrganization - Callback when the edit button is clicked (ex. to toggle edit dialog)
 * @param {Address} [customAddress] - Address record 
 * @param {Function} onToggleAddressDialog - Callback to render AddressDialog
 * @param {bool} [disableActions] - Disable all actions on the card, making it read only
 * @returns
 */
export default function DispatchOrganizationCard({ ...props }) {

  const [dispatchOrganization, setDispatchOrganization] = useState(undefined);

  useEffect(() => {
    setDispatchOrganization(props.dispatchOrganization);
  }, [props.dispatchOrganization]);

  function onUnselectDispatchOrganization() {
    if (props.onUnselectDispatchOrganization) {
      props.onUnselectDispatchOrganization(dispatchOrganization);
    }
    setDispatchOrganization(undefined);
  }

  // ** Misc ** //

  const name = getAttribute(dispatchOrganization, 'organizationName', true);
  const id = getAttribute(dispatchOrganization, 'organizationId', true);

  let address = getAttribute(dispatchOrganization, 'address', true);
  if (props.customAddress) address = props.customAddress;

  const addressString = getAttribute(address, 'address', true);
  const city = getAttribute(address, 'city', true);
  const stateProvince = getAttribute(address, 'stateProvince', true);
  const zipPostal = getAttribute(address, 'zipPostal', true);
  const country = getAttribute(address, 'country', true);

  const fullAddressString = [addressString, city, stateProvince, zipPostal, CountryName[country]].filter(part => part).join(', ');

  let className = 'dispatch-organization-card';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      { !props.isLoading && (
        <div className="org-card">
          {!props.disableActions &&
            <>
              <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => onUnselectDispatchOrganization()} />
              <Button icon="pi pi-pencil" className="p-button-rounded p-button-secondary" onClick={() => props.onToggleEditDispatchOrganization && props.onToggleEditDispatchOrganization()} />
            </>
          }
          <div>
            <div className={`name font-semibold${!name ? ' text-warning' : ''}`}>{ name || 'No Name Provided' }</div>
            <div className={`id font-semibold${!id ? ' text-warning' : ''}`}>({ id || 'No ID Provided' })</div>

            { fullAddressString && (
              <div className="address">
                { fullAddressString }
              </div>
            )}
            { !fullAddressString && (
              <div className="address text-warning">{'No Address Provided' }</div>
            )}
            
            {!props.disableActions && 
              <span className="label-button" onClick={() => props.onToggleAddressDialog()}>+ Custom address</span>
            }
          </div>
        </div>
      )}

      { props.isLoading && (
        <div className="custom-skeleton">
          <div className="flex">
            <Skeleton shape="circle" size="2.625rem" className="mr-2"></Skeleton>
            <div>
              <Skeleton width="8rem" height=".6rem" className="mb-2"></Skeleton>
              <Skeleton width="5rem" height=".6rem" className="mb-2"></Skeleton>
              <Skeleton height=".4rem"></Skeleton>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
