import React, { useState } from 'react';

// sbCore
import SidebarCard from 'sbCore/Card/SidebarCard';

// sb-csapi
import { Action } from 'sb-csapi/dist/enums/Action';

// components
import DriverGroupModal from 'components/DriverGroup/DriverGroupModal/DriverGroupModal';

/**
 * @description Renders the driver group card
 * 
 * @param {int} type - Action enum to determine the type of the card
 * @param {object} driverGroup - An object which contains information to be displayed on the card
 * @param {function} triggerRefresh - Invert isRefresh bool
 * @returns 
 */
function DriverGroupCard({ ...props }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  // only generate driver group summary card content if type is edit
  const cardTitleLeft = props.type === Action.EDIT && (
    <div>
      { props.driverGroup.groupName }
    </div>
  )

  const cardBody = props.type === Action.EDIT && (
    <div className="text-xs">
      <span>Created By: { props.driverGroup.createdBy }</span>
    </div>
  )

  const cardFooter = props.type === Action.EDIT && (
    <div className="text-sm flex align-items-center">
      <span className="material-icons mr-1">group</span>
      <span>{props.driverGroup.numberOfDrivers} Drivers</span>
    </div>
  )

  // content for add group card
  const addGroupCardContent = (
    <div className="flex flex-column justify-content-center align-items-center row-gap-2 pt-2">
      <i className="pi pi-plus-circle text-4xl" />
      <div className="text-xl font-semibold">
        ADD GROUP
      </div>
    </div>
  )

  return (
    <div className="col-3">
      {props.type === Action.ADD &&
        <div onClick={() => setIsModalOpen(true)}>
          <SidebarCard
            isActive={false}
            body={addGroupCardContent}
          />
        </div>
      }
      {props.type === Action.EDIT && 
        <div onClick={() => setIsModalOpen(true)}>
          <SidebarCard
            isActive={false}
            cardTitleLeft={cardTitleLeft}
            body={cardBody}
            footer={cardFooter}
          />
        </div>
      }
      <DriverGroupModal
        setVisible={(isVisible) => setIsModalOpen(isVisible)}
        driverGroup={props.driverGroup}
        type={props.type}
        isOpen={isModalOpen}
        triggerRefresh={() => props.triggerRefresh()}
      />
    </div>
  );
}

export default DriverGroupCard;
