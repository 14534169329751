import React from 'react';
import PropTypes from 'prop-types';
import history from "../../../sbHistory";

// Components
import ChooseOrAddModal from 'components/ChooseOrAddModal/container/ChooseOrAddModal';

class DriverIssuesRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showAssignDriverModal: false,
    };
    this.assignDriver = this.assignDriver.bind(this);
    this.handleAssignDriverClose = this.handleAssignDriverClose.bind(this);
  }

  assignDriver(driver) {
    this.props.contravention.parseContravention
      .set('driver', driver)
      .set('fullName', `${driver.get('user').get('firstName')} ${driver.get('user').get('lastName')}`)
      .set('user_fullName', driver.get('user_fullName'))
      .save();
    this.props.contravention.fullName = `${driver.get('user').get('firstName')} ${driver.get('user').get('lastName')}`;
    this.props.contravention.user_fullName = driver.get('user_fullName');
    this.setState({ ...this.state, showAssignDriverModal: false });
  }

  handleAssignDriverClose() {
    this.setState({ ...this.state, showAssignDriverModal: false });
  }

  render() {
    const { contravention } = this.props;

    const driverName = contravention.fullName;
    const activePoints = contravention.activePoints;

    return (
      <tr key={this.props.rowKey}>
        <td>
          { driverName }
          {!contravention.parseContravention.get('driver') &&
            <button className="buttonDefault block" onClick={() => this.setState({ ...this.state, showAssignDriverModal: true })}>Link Driver</button>
          }
        </td>
        <td>
          { activePoints }
        </td>
        <td>
          {contravention.parseContravention.get('driver') &&
            <button
              className="buttonDefault longButton floatRight"
              onClick={() => history.push({ pathname: 'driver', search: "driver=" + contravention.parseContravention.get('driver').id })}
            >
              View Details
            </button>
          }
          <ChooseOrAddModal chooseType="Driver" show={this.state.showAssignDriverModal} handleModalChoice={this.assignDriver} handleClose={this.handleAssignDriverClose} />
        </td>
      </tr>
    );
  }
}

DriverIssuesRow.propTypes = {
  contravention: PropTypes.object,
  rowKey: PropTypes.string,
};

export default DriverIssuesRow;
