import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-mapbox-gl';

// API
import { getColour } from 'api/Mapbox';

import styles from './ComponentStyles.module.scss';

function LocationSearchMarker(props) {
  return (
    <Marker
      anchor="center"
      onClick={(map, event) => {
        if (props.handleOnClick) props.handleOnClick(props.coordinates[1], props.coordinates[0]);
      }}
      offset={[0, -7]} // to make flag look like it's on top
      coordinates={props.coordinates}
    >
      <div><i className={`material-icons md-36 ${styles.pointOfInterest} ${styles.marker}`} style={{ color: '#85144b' }}>pin_drop</i></div>
    </Marker>
  );
}

LocationSearchMarker.propTypes = {
  coordinates: PropTypes.array.isRequired,
  handleOnClick: PropTypes.func,
};

export default LocationSearchMarker;
