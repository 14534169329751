import React from 'react';
import PropTypes from 'prop-types';
import history from "../../sbHistory";

// API
// import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Helpers from 'api/Helpers';
// import * as PDF from 'api/PDFer';

// Components
import FlatButton from 'material-ui/FlatButton/FlatButton';
import RaisedButton from 'material-ui/RaisedButton/RaisedButton';

import Title from 'components/LayoutTitle/view/Title';
import DriverVehiclesTable from 'components/DriverDetails/container/DriverVehiclesTable';
import AssociateVehiclesDialog from './AssociateVehiclesDialog';

// CSS
import styles from './VehiclePreferencesLayout.module.scss';


class VehiclePreferencesLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVehicle: undefined,
      openAddAssociatedVehiclesDialog: false,
      updateInt: 0,
    };

    this.handleSelectVehicle = this.handleSelectVehicle.bind(this);
    this.toggleAddAssociatedVehiclesDialog = this.toggleAddAssociatedVehiclesDialog.bind(this);
    this.handleVehiclesChosen = this.handleVehiclesChosen.bind(this);
  }

  componentDidMount() {
    document.title = 'Driver Vehicle Preferences - Switchboard';
  }

  componentWillReceiveProps(nextProps) {

  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedVehicle) {
      document.querySelector('.vehicle-preferences').scrollTop = 0;
    }
  }

  handleSelectVehicle(selectedVehicle) {
    this.setState({ ...this.state, selectedVehicle });
  }

  toggleAddAssociatedVehiclesDialog() {
    const newState = { ...this.state };
    newState.openAddAssociatedVehiclesDialog = !this.state.openAddAssociatedVehiclesDialog;
    this.setState(newState);
  }

  handleVehiclesChosen(selectedVehicles) {
    const { driver } = this.props;
    const vehicleDriverHistoryPromises = [];

    for (let i = 0; i < selectedVehicles.length; i++) {
      const selectedVehicle = selectedVehicles[i];
      vehicleDriverHistoryPromises.push(
        Setters.addRecord(
          'VehicleDriverHistory',
          {
            driver: driver,
            vehicle: selectedVehicle,
          },
          Helpers.getCompanyReadWriteACL()
        )
      );
    }

    Promise.all(vehicleDriverHistoryPromises).then(() => {
      this.setState({ ...this.state, updateInt: this.state.updateInt + 1 });
    });
  }

  render() {

    const { driver } = this.props;
    const { selectedVehicle, openAddAssociatedVehiclesDialog, updateInt } = this.state;

    return (
      <div className="vehicle-preferences-layout">
        <div className={`vehicle-preferences ${styles.table}`}>

          <Title title="Vehicle Preferences">
          </Title>

          { selectedVehicle &&
            <div id="vehiclePreferences" style={{ marginTop: '1em' }}>
              <Title className="h3" title="Ayy lmao">
              </Title>
            </div>
          }

          { selectedVehicle &&
            <hr style={{ marginTop: '1em' }} />
          }

          <div style={{ marginTop: '1em' }}>
            <Title className="h3" title="Vehicles Driven">
              <RaisedButton
                label="Add Vehicle Driven"
                primary
                onClick={this.toggleAddAssociatedVehiclesDialog}
                disabled={!driver}
              />
            </Title>
            <DriverVehiclesTable driver={driver} handleSelectVehicle={this.handleSelectVehicle} updateInt={updateInt} />
          </div>

          { openAddAssociatedVehiclesDialog &&
            <AssociateVehiclesDialog
              driver={driver}
              open={openAddAssociatedVehiclesDialog}
              handleClose={this.toggleAddAssociatedVehiclesDialog}
              handleVehiclesChosen={this.handleVehiclesChosen}
            />
          }

        </div>
      </div>
    );
  }
}

VehiclePreferencesLayout.propTypes = {
  driver: PropTypes.object,
  eldModuleSubscribed: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
};

export default VehiclePreferencesLayout;
