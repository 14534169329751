import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBInput, MDBIcon } from "mdbreact";

// CSS
import './style.scss';

class SBSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instanceId: uniqid(),
      selectedItems: [],
      isAllSelected: false,
      filterString: '',
    };

    this.refreshState = this.refreshState.bind(this);
    this.handleFilterInput = this.handleFilterInput.bind(this);
    this.handleDropdownItemSelect = this.handleDropdownItemSelect.bind(this);
    this.toggleSelectAll = this.toggleSelectAll.bind(this);
    this.openDropdown = this.openDropdown.bind(this);
    this.forceCloseDropdown = this.forceCloseDropdown.bind(this);
  }

  componentDidMount() {
    this.refreshState(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.refreshState(nextProps);
  }

  refreshState(props) {
    if (props) {
      const newState = { ...this.state };
      if (props.selectedItems) {
        newState.selectedItems = [...props.selectedItems];

        // find out if isAllSelected is still in play by:
        // 1. checking to see if selectedItems and non-disabled items are the same length
        // 2. check to see if selectedItems is a 1-to-1 to items. if not, then something is off is !isAllSelected (ex. user forced a disabled value to be selected)
        newState.isAllSelected = true;

        const nonDisabledItemValues = props.items.map(item => !item.disabled && item.value);
        const selectedItemValues = newState.selectedItems.map(item => item.value);
        if (nonDisabledItemValues.length === newState.selectedItems.length) {
          for (let i = 0; i < selectedItemValues.length; i++) {
            const selectedItemValue = selectedItemValues[i];
            if (nonDisabledItemValues.indexOf(selectedItemValue) === -1) { // mismatch
              newState.isAllSelected = false;
              break;
            }
          }
        } else {
          newState.isAllSelected = false;
        }
      }

      this.setState(newState);
    }
  }

  handleFilterInput(filterString = '') {
    this.setState({ ...this.state, filterString }, () => {
      if (this.props.onFilterChange) {
        this.props.onFilterChange(filterString);
      }
    });
  }

  handleDropdownItemSelect(selectedItem) {
    const { getSelectedItems, isAutocomplete } = this.props;
    const newState = { ...this.state };
    newState.isAllSelected = false;

    if (!this.props.multiSelectable) {
      newState.selectedItems = [selectedItem];
      this.forceCloseDropdown();
    } else {
      let isRemovableItem = false; // determine whether or not the item was already in the list, therefore we remove instead of add
      newState.selectedItems = [].concat(newState.selectedItems);
      for (let i = 0; i < newState.selectedItems.length; i++) {
        const item = newState.selectedItems[i];
        if (item.value === selectedItem.value) {
          newState.selectedItems.splice(i, 1);
          isRemovableItem = true;
          break;
        }
      }

      if (!isRemovableItem) newState.selectedItems.push(selectedItem);
    }

    // if (isAutocomplete) {
    //   newState.filterString = selectedItem.label;
    //   if (selectedItem.addableItem || selectedItem.removableItem) {
    //     newState.filterString = '';
    //   }
    // }
    newState.filterString = '';

    this.setState(newState, () => {
      if (selectedItem.onClick) selectedItem.onClick();
      if (getSelectedItems) {
        getSelectedItems(this.state.selectedItems);
      }
    });
  }

  toggleSelectAll() {
    // select all applicable (ex. non-disabled) items
    const { items, getSelectedItems } = this.props;
    const { isAllSelected } = this.state;

    const newState = { ...this.state };
    newState.filterString = '';

    if (isAllSelected) {
      newState.selectedItems = [];
      newState.isAllSelected = false;
    } else {
      newState.isAllSelected = true;
      newState.selectedItems = items.filter(item => {
        return !item.disabled;
      });
    }

    this.setState(newState, () => {
      if (getSelectedItems) {
        getSelectedItems(this.state.selectedItems);
      }
    });
  }

  openDropdown() {
    // DOM function for autofill. When the autofill input is clicked or focused, check and set the dropdown to be shown
    // by simulating click on lib's dropdown button - since that is what runs the js to show the dropdown
    const elDropdown = document.querySelector('.' + this.state.instanceId + ' .dropdown.sb-select-dropdown');
    const dropdownClassNames = elDropdown.className.split(' ');
    if (dropdownClassNames.indexOf('show') === -1) {
      document.querySelector('.' + this.state.instanceId + ' .sb-select-toggle').click();
    }

    if (this.props.onAutocompleteFocus) this.props.onAutocompleteFocus();
  }

  forceCloseDropdown() {
    document.querySelector('.' + this.state.instanceId + ' .click-close').click();
  }

  render() {
    const { props, state } = this;

    // filter items for the ones containing the partial string filterString
    const filterString = state.filterString.toLowerCase().replace(/\s/g, ''); // remove all whitespace
    const items = props.items.filter(item => {
      const label = (item.label || '').toLowerCase().replace(/\s/g, '');
      return label.indexOf(filterString) !== -1;
    });

    // if is multiSelectable and showSelectAll, unshift the Select All item
    const selectAllKey = `${state.instanceId}-select-all`;
    if (props.multiSelectable && props.showSelectAll) items.unshift({ key: selectAllKey, value: selectAllKey, label: 'Select All', disabled: props.disabled });

    // get a map of selecteditemvalues to quickly check which ones items are selected when compared against each other
    const selectedItemValues = state.selectedItems.map(item => item.value);

    // map each filtered item to the proper item component
    let dropdownMenuItems = items.map(item => {
      if (item.isDivider) return <MDBDropdownItem key={item.key} divider />;

      if (item.isHeader) return <MDBDropdownItem key={item.key} header>{item.label}</MDBDropdownItem>

      // item classes
      let className = 'sb-dropdown-item';
      if (item.disabled) className += ' disabled';
      if (item.className) className += ` ${item.className}`;

      // label classes
      let labelContainerClassName = 'sb-dropdown-item-label-container';
      if (item.labelContainerClassName) labelContainerClassName += ` ${item.labelContainerClassName}`;

      let checkbox = <MDBIcon far icon="square" />;
      if ((selectedItemValues.indexOf(item.value) !== -1) || state.isAllSelected) {
        checkbox = <MDBIcon far icon="check-square" />;
        labelContainerClassName += ' active';
        if (item.activeClassName) labelContainerClassName += ` ${item.activeClassName}`;
      }

      // determine which onClick function to use
      let onClickFnReference = this.handleDropdownItemSelect;
      if (item.value === selectAllKey) onClickFnReference = this.toggleSelectAll;

      let plusSquare = <MDBIcon icon="plus-square" className="mr-1"/>;
      let minusSquare = <MDBIcon icon="minus-square" className="mr-1"/>;
      let labelIconElement;
      if (item.addableItem) {
        labelIconElement = <div className="d-inline-block mr-2">{plusSquare}</div>;
      } else if (item.removableItem) {
        labelIconElement = <div className="d-inline-block mr-2">{minusSquare}</div>;
      } else if (props.multiSelectable) {
        labelIconElement = <div className="d-inline-block mr-2">{checkbox}</div>;
      }

      // we return a div instead of a dropdown item to stop native dropdown force-close (ie. if use a dropdown item itll force the dropdown to close on click)
      return (
        <div key={item.key}>
          <div key={item.key} className={className} onClick={(e) => { if (!item.disabled) e.stopPropagation(); onClickFnReference(item); }} disabled={item.disabled}>
            <div className={`${labelContainerClassName}`}>
              {labelIconElement && labelIconElement}
              <div className="d-inline-block">{item.label}</div>
            </div>
          </div>

          {(item.key === `${state.instanceId}-select-all`) &&
            <MDBDropdownItem divider />
          }
        </div>
      );
    });

    if (dropdownMenuItems.length === 0) {
      dropdownMenuItems = [
        <div key="no-items">
          <div className="sb-dropdown-item" disabled>
            <div className="d-inline-block">No Items Found</div>
          </div>
        </div>
      ];
    }


    // determine what text to show on the toggle button
    let dropdownToggleText = props.dropdownToggleText;
    if (!dropdownToggleText) {
      if (!props.multiSelectable) {
        dropdownToggleText = props.defaultToggleText || 'Select a value';
      } else {
        dropdownToggleText = props.defaultToggleText || 'Select values';
      }

      if (props.multiSelectable && (state.selectedItems.length > 0)) {
        // multiple selected, show a count of how many
        dropdownToggleText = `${state.selectedItems.length} selected`;
      } else if (!props.multiSelectable && state.selectedItems[0]) {
        // only one selectable item at a time, display THE chosen one's label
        dropdownToggleText = state.selectedItems[0].label;
      }

      if (props.maxToggleTextLength && (dropdownToggleText.length > props.maxToggleTextLength) && (!props.multiSelectable && state.selectedItems[0])) {
        dropdownToggleText = `${dropdownToggleText.substr(0, props.maxToggleTextLength).trim()}...`;
      }
    }

    // determine classNames
    let containerClassName = 'sb-select-container ' + state.instanceId;
    if (props.isAutocomplete) containerClassName += ` sb-autocomplete`;
    if (props.containerClassName) containerClassName += ` ${props.containerClassName}`;

    let dropdownToggleClassName = 'sb-select-toggle';
    if (props.className) dropdownToggleClassName += ` ${props.className}`;

    // get list of selectedItems that are not isSpecialItem to determine whether or not only regular items were selected
    const isNotSpecialItemSelectedItems = state.selectedItems.filter(item => {
      return !item.isSpecialItem;
    });

    return (
      <div className={containerClassName}>
        {props.isAutocomplete &&
          <div>
            <MDBInput
              label={props.autocompleteLabel}
              labelClass="active"
              hint={props.placeholder}
              containerClass={props.isRequired ? ' required' : ''}
              size={props.autocompleteSize || 'md'}
              value={(state.filterString || '').toString()}
              onChange={(e) => this.handleFilterInput(e.target.value)}
              disabled={props.disabled}
              getValue={props.onValueChange}
              onClick={() => this.openDropdown()}
              onFocus={() => this.openDropdown()}
            >
            <div className="invalid-feedback translate-me" style={{display: state.filterString && isNotSpecialItemSelectedItems.length === 0 ? 'block' : 'none'}}>
              { this.props.errorMessage || 'Item not found' }
            </div>
            </MDBInput>
          </div>
        }
        <MDBDropdown className="sb-select-dropdown translate-me" size={props.size} dropup={props.dropup}>
          <MDBDropdownToggle className={dropdownToggleClassName} caret disabled={props.disabled} onClick={(e) => { props.onClick ? props.onClick(e) : undefined }}>
            <span>{dropdownToggleText}</span>
          </MDBDropdownToggle>
          <MDBDropdownMenu className="sb-select-dropdown-menu" basic>
            {(props.showFilter && !props.isAutocomplete) &&
              <div>
                <div className="sb-form sb-select-autocomplete-dropdown-item">
                  <MDBInput
                    label={props.autocompleteLabel}
                    labelClass="active"
                    containerClass="sb-select-autocomplete-container"
                    size={props.autocompleteSize || 'sm'}
                    value={(state.filterString || '').toString()}
                    onChange={(e) => this.handleFilterInput(e.target.value)}
                    disabled={props.disabled}
                  />
                </div>
                <MDBDropdownItem divider />
              </div>
            }

            <div className="sb-select-menu-items-container">
              {dropdownMenuItems}
            </div>
          </MDBDropdownMenu>
        </MDBDropdown>
        <div className="click-close" onClick={(e) => e.stopPropagation()}></div>
      </div>
    );
  }
}

SBSelect.defaultProps = {
  items: [],
  size: "sm",

  autocompleteLabel: "Filter",
  onValueChange: () => { }
};

SBSelect.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.array,
  selectedItems: PropTypes.array, // force selected items rather than sbselect handling it
  dropup: PropTypes.bool,
  disabled: PropTypes.bool,
  getSelectedItems: PropTypes.func,
  multiSelectable: PropTypes.bool,
  showSelectAll: PropTypes.bool,
  showFilter: PropTypes.bool, // shows filter at header (first item in the dropdown)

  // dropdown button-specific properties
  dropdownToggleText: PropTypes.any, // 'forcing' button text to what you want
  defaultToggleText: PropTypes.any, // if no values are selected, default to this button text
  size: PropTypes.string,
  onClick: PropTypes.func, // clicking the dropdown button (ex. stop propagation function)
  maxToggleTextLength: PropTypes.number, // number of characters for the toggle button selected item text before it gets cut off

  // filter / autocomplete-specific properties
  autocompleteLabel: PropTypes.string,
  autocompleteSize: PropTypes.string,
  onValueChange: PropTypes.func,
  onFilterChange: PropTypes.func,

  // autocomplete properties
  isAutocomplete: PropTypes.bool, // turns the dropdown button into an autocomplete and disables showFilter
  isRequired: PropTypes.bool, // shows a red asterisk
  placeholder: PropTypes.string, // placeholder text for the input
};

/**
 * items: [
 *  {
 *    className: '...' // style for the item
 *    label: 'some option',
 *    labelContainerClassName: '...', // styles for the label container
 *    activeClassName: '...', // styles for when an item's label is active
 *    value: 'someOptionAttr'
 *    disabled: bool,
 *    isDivider: bool, // render as a divider item
 *    isHeader: bool, // render as a header item
 *    onClick: () => {},
 *  }
 *  ...
 * ]
 */

export default SBSelect;
