import Parse from 'parse';
import moment from 'moment-timezone';
import store from '../store';
import * as Helpers from './Helpers';
import * as Getters from './Getters';

/** @module WeighStationBypass */

/**
 * @memberof module:WeighStationBypass
 * @param {*} driver
 * @returns
 */
function addWSBDriver(driver) {
  const promise = new Promise((resolve, reject) => {
    const user = Parse.User.current();
    const company = user.get('belongsToCompany');
    Parse.Cloud.run('createWSBDriver', { companyId: company.id, subscriptionId: company.get('subscription').id, addressId: company.get('address') ? company.get('address').id : undefined, driverId: driver.id }).then(
      ([apiDriver, weighStationBypassDriver]) => {
        resolve([apiDriver, weighStationBypassDriver]);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:WeighStationBypass
 *
 * @param {*} vehicle
 * @param {*} enabled
 *
 * @returns
 */
function handleWSBVehicleSubscription(vehicle, enabled) {
  const promise = new Promise((resolve, reject) => {
    const user = Parse.User.current();
    const company = user.get('belongsToCompany');
    Parse.Cloud.run('handleWSBVehicleSubscription', { companyId: company.id, subscriptionId: company.get('subscription').id, addressId: company.get('address') ? company.get('address').id : undefined, vehicleId: vehicle.id, enabled }).then(
      ([apiVehicle, weighStationBypassVehicle]) => {
        resolve([apiVehicle, weighStationBypassVehicle]);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:WeighStationBypass
 * @param {*} vehicle
 * @returns
 */
function updateWSBVehicleImage(vehicle) {
  const promise = new Promise((resolve, reject) => {
    var vehicleApiId = vehicle.get('weighStationBypassVehicle') && vehicle.get('weighStationBypassVehicle').get('apiId');
    var vehicleImageURL = vehicle.get('vehicleImage') && vehicle.get('vehicleImage')._url;

    if (vehicleApiId && vehicleImageURL) {
      Parse.Cloud.run('updateWSBVehicleImage', { vehicleApiId, vehicleImageURL }).then(
        function (success) {
          resolve(success);
        },
        function (error) {
          console.log(error);
        }
      );
    }
    else {
      resolve(true);
    }
  });
  return promise;
}

/**
 * @memberof module:WeighStationBypass
 * @returns
 */
function getWeighStationBypassVehicles() {
  // check if weigh station bypass vehicles are in use for a companys current set of vehicles. Counts active and inactive vehicles
  const promise = new Promise((resolve, reject) => {
    const queryFilter = [{
      queryType: 'exists',
      name: 'weighStationBypassVehicle',
    }];
    Getters.queryCompanyObjects('Vehicle', undefined, undefined, queryFilter, undefined, ['weighStationBypassVehicle'], undefined, false, undefined, true).then(
      (vehicles) => {
        const weighStationBypassVehicles = vehicles.map(vehicle => {
          return vehicle.get('weighStationBypassVehicle');
        });
        resolve(weighStationBypassVehicles);
      },
      error => {
        reject(error);
      }
    );
  });
  return promise;
}

export {
  addWSBDriver,
  getWeighStationBypassVehicles,
  handleWSBVehicleSubscription,
  updateWSBVehicleImage,
};
