// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapboxgl-popup-content {
  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.298039) !important;
}`, "",{"version":3,"sources":["webpack://./app/styles/_mapbox.css"],"names":[],"mappings":"AAAA;EACE,2DAA2D;AAC7D","sourcesContent":[".mapboxgl-popup-content {\n  box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.298039) !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
