/** @module DispatchQuoteAdditionalCharge */

// sb-csapi
import {
  createQuery,
  findRecords,
  getAttribute,
  getCurrentUserSessionToken,
  setQueryRestriction,
  updateRecord,
  addRecord,
  getCurrentUser,
  destroyRecord,
  sortQuery,
} from 'sb-csapi/dist/AAPI';

// Query
import { QueryRestrictionTypes, QuerySortOrderTypes } from 'sb-csapi/dist/enums/Query';

const DISPATCH_QUOTE_ADDITIONAL_CHARGE_TABLE = 'DispatchQuoteAdditionalCharge';

/**
 *
 * @description - Fetch DispatchQuoteAdditionalCharge records with given DispatchQuote
 *
 * @param {DispatchQuote} dispatchQuote - DispatchQuote record
 *
 * @returns {Array} An array with DispatchQuoteAdditionalCharge records related to the given DispatchQuote
 */
async function getDispatchQuoteAdditionalCharge(dispatchQuote) {
  const dispatchQuoteAdditionalChargeQuery = createQuery(DISPATCH_QUOTE_ADDITIONAL_CHARGE_TABLE);
  setQueryRestriction(dispatchQuoteAdditionalChargeQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchQuote', dispatchQuote);
  sortQuery(dispatchQuoteAdditionalChargeQuery, QuerySortOrderTypes.DESCENDING, 'createdAt');

  try {
    return await findRecords({ sessionToken: getCurrentUserSessionToken() }, dispatchQuoteAdditionalChargeQuery, false, true);
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * 
 * @param {*} dispatchQuote 
 * @returns 
 */
async function getTotalDispatchQuoteChargeAmount(dispatchQuote) {
  const dispatchQuoteAdditionalChargeQuery = createQuery(DISPATCH_QUOTE_ADDITIONAL_CHARGE_TABLE);
  setQueryRestriction(dispatchQuoteAdditionalChargeQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchQuote', dispatchQuote);

  try {
    const dispatchQuoteAdditionalCharges = await findRecords({ sessionToken: getCurrentUserSessionToken() }, dispatchQuoteAdditionalChargeQuery, false, true);

    if (!dispatchQuoteAdditionalCharges) return 0;

    const totalDispatchQuoteChargeAmount = dispatchQuoteAdditionalCharges.reduce((totalSum, additionalCharge) => totalSum + getAttribute(additionalCharge, 'rate'), 0);
    return totalDispatchQuoteChargeAmount;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 *
 * @description - Create DispatchQuoteAdditionalCharge records with given DispatchQuote
 *
 * @param {DispatchQuote} dispatchQuote - DispatchQuote record
 *
 * @returns {DispatchQuoteAdditionalCharge} The created DispatchQuoteAdditionalCharge record
 */
async function createDispatchQuoteAdditionalCharge(dispatchQuote) {
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

  try {
    // Default additional charge values
    let dispatchQuoteAdditionalCharge = {
      rate: 0,
      dispatchQuote,
    };

    dispatchQuoteAdditionalCharge = await addRecord(
      { sessionToken: getCurrentUserSessionToken() },
      DISPATCH_QUOTE_ADDITIONAL_CHARGE_TABLE,
      dispatchQuoteAdditionalCharge,
      getAttribute(belongsToCompany, 'objectId'),
    );

    return dispatchQuoteAdditionalCharge;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 *
 * @description - Fetch DispatchQuoteAdditionalCharge records with given DispatchQuote
 *
 * @param {string} dispatchQuoteAdditionalChargeObjectId - dispatchQuoteAdditionalCharge record id
 * @param {object} keyValueObj - The key value object we wish to update
 * @param {bool} save - Whether to save the changes to db or not
 *
 * @returns {DispatchQuoteAdditionalCharge} The updated DispatchQuoteAdditionalCharge record
 */
async function updateDispatchQuoteAdditionalCharge(dispatchQuoteAdditionalChargeObjectId, keyValueObj, save) {
  const dispatchQuoteAdditionalChargeQuery = createQuery(DISPATCH_QUOTE_ADDITIONAL_CHARGE_TABLE);
  setQueryRestriction(dispatchQuoteAdditionalChargeQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', dispatchQuoteAdditionalChargeObjectId); 

  try {
    const dispatchQuoteAdditionalCharge = await findRecords(
      { sessionToken: getCurrentUserSessionToken() },
      dispatchQuoteAdditionalChargeQuery,
      true,
      false,
    );

    if (!dispatchQuoteAdditionalCharge) {
      throw new Error(`Error [api/DispatchQuoteAdditionalCharge updateDispatchQuoteAdditionalCharge]: No DispatchQuoteAdditionalCharge with objectId ${dispatchQuoteAdditionalChargeObjectId}`);
    }

    return await updateRecord(
      { sessionToken: getCurrentUserSessionToken() },
      dispatchQuoteAdditionalCharge,
      keyValueObj,
      save,
    );

  } catch (err) {
    throw new Error(err);
  }
}

/**
 *
 * @description - Delete DispatchQuoteAdditionalCharge records with given DispatchQuote
 *
 * @param {string} dispatchQuoteAdditionalChargeRecord - dispatchQuoteAdditionalCharge record id
 *
 * @returns {DispatchQuoteAdditionalCharge} The deleted DispatchQuoteAdditionalCharge record
 */
async function deleteDispatchQuoteAdditionalCharge(dispatchQuoteAdditionalChargeRecord) {
  if (!dispatchQuoteAdditionalChargeRecord) throw new Error('A valid dispatchQuoteAdditionalCharge record is required');

  return await destroyRecord({ sessionToken: getCurrentUserSessionToken() }, dispatchQuoteAdditionalChargeRecord);
}

export {
  getDispatchQuoteAdditionalCharge,
  getTotalDispatchQuoteChargeAmount,
  createDispatchQuoteAdditionalCharge,
  updateDispatchQuoteAdditionalCharge,
  deleteDispatchQuoteAdditionalCharge,
};
