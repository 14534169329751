import React from 'react';
import PropTypes from 'prop-types';
import { HelpBlock, Modal } from 'react-bootstrap';

// Components
import GlobalJobDetails from './AdvancedViews/GlobalJobDetails.js';
import JobActionTypePicker from './AdvancedViews/JobActionTypePicker.js';
import ActionList from './AdvancedViews/ActionList.js';
import PickupAndDropoffDetails from './AdvancedViews/PickupAndDropoffDetails.js';

import ErrorList from 'components/ErrorList/view/ErrorList';

import CircularProgress from 'material-ui/CircularProgress';

// CSS
import styles from './AddNewJobModalAdvanced.module.scss';

function AddNewJobModal(props) {
  return (
    <Modal keyboard={false} backdrop="static" bsSize="large" show={props.show} onHide={() => props.handleClose()}>

      <Modal.Header closeButton>
        <Modal.Title>Add New Job</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.content}>
          <GlobalJobDetails {...props} />
          <ActionList {...props} />
          { props.jobActionEntries.length > 0 && props.selectedIndex < props.jobActionEntries.length &&
            <div className={styles.jobActionEntryDetails}>
              <JobActionTypePicker {...props} selectedJobAction={props.jobActionEntries[props.selectedIndex]} />
              { (props.jobActionEntries[props.selectedIndex].jobActionType === 'Pickup' || props.jobActionEntries[props.selectedIndex].jobActionType === 'Dropoff') &&
                <PickupAndDropoffDetails {...props} />
              }
            </div>
          }
        </div>
        { (props.errors && props.errors.length > 0) &&
          <ErrorList errors={props.errors} containerStyle={{ marginLeft: '2em' }} />
        }

      </Modal.Body>

      <Modal.Footer>
        { props.isLoading ?
          <div style={{ textAlign: 'right' }}><CircularProgress /></div>
          :
          <div>
            <button className="closeButton" onClick={() => props.handleClose()}>Cancel</button>
            <button className="buttonDefault" onClick={() => props.onSubmit()} disabled={props.addingInProgress}>Add Job</button>
          </div>
        }
      </Modal.Footer>

    </Modal>
  );
}

AddNewJobModal.propTypes = {
  addingInProgress: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  jobActionEntries: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validateError: PropTypes.bool,
  isLoading: PropTypes.bool,
  errors: PropTypes.array,
};

export default AddNewJobModal;
