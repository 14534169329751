import * as ActionConstants from './ActionConstants';
import history from "../sbHistory";
import * as Parse from 'api/Parse';
import store from 'store';

export function updatingTemp() {
  return {
    type: ActionConstants.UPDATE_TEMP_INPROGRESS,
  };
}

export function updateTempSuccess(attribute, value) {
  return {
    type: ActionConstants.UPDATE_TEMP_SUCCESS,
    attribute,
    value,
  };
}

export function updateTempError(error) {
  return {
    type: ActionConstants.UPDATE_TEMP_ERROR,
    error,
  };
}

export function updateTemp(attribute, value) {
  store.dispatch(updatingTemp());
  store.dispatch(updateTempSuccess(attribute, value));
}
