import React, { useState, useEffect } from 'react';

// components
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import ProgressSpinner from 'sbCore/ProgressSpinner/ProgressSpinner';
import Button from 'sbCore/Button/Button';
import ConfirmDialog from 'sbCore/ConfirmDialog/ConfirmDialog';

import OrganizationTypeDropdown from 'components/Dispatch/OrganizationsTable/OrganizationTypeDropdown/OrganizationTypeDropdown';
import DispatchOrganizationEditDialog from 'components/Dispatch/DispatchOrganizationAutocomplete/DispatchOrganizationEditDialog';

// api
import { getDispatchOrganizations, updateDispatchOrganization } from 'api/Dispatch/DispatchOrganization';

// sb-csapi
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { truncateString } from 'sb-csapi/dist/utils/String';

// enums
import { Organization } from 'sb-csapi/dist/enums/Dispatch/Organization';
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// style
import './style.scss';

/**
 * @description A table of organizations
 * @param {String} [className]
 * @param {String} [scrollHeight] - Scroll height of the table
 * @returns
 */
function OrganizationsTable({ ...props }) {

  const [dispatchOrganizations, setDispatchOrganizations] = useState([]);
  const [dispatchOrganization, setDispatchOrganization] = useState(null); // if an organization is selected to edit
  const [dispatchOrganizationType, setDispatchOrganizationType] = useState(Organization.CUSTOMER); // the type of organization to display
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  // Loading indicators
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false); // if all init data is fetched

  // JSX
  const [tableColumns, setTableColumns] = useState([]); // table columns

  // Set organization type filter. First find the mapping from type <-> database field
  const dispatchOrganizationTypeToAttributeMap = Object.freeze({
    [Organization.CARRIER]: 'isCarrier',
    [Organization.CONSIGNEE]: 'isConsignee',
    [Organization.CUSTOMER]: 'isCustomer',
    [Organization.SHIPPER]: 'isShipper',
  });

  // Same as above, but for labelling purposes
  const dispatchOrganizationTypeToLabelMap = Object.freeze({
    [Organization.CARRIER]: 'CARRIER',
    [Organization.CONSIGNEE]: 'CONSIGNEE',
    [Organization.CUSTOMER]: 'CUSTOMER',
    [Organization.SHIPPER]: 'SHIPPER',
  });

  useEffect(() => {
    if (isFetched) return;

    let didCancel = false;
    async function init() {
      setIsLoading(true);

      const filters = [
        new Filter(QueryRestriction.EQUAL_TO, 'isHidden', undefined),
      ];

      filters.push(
        // ex. (QueryRestriction.EQUAL_TO, 'isCustomer', true)
        new Filter(QueryRestriction.EQUAL_TO, dispatchOrganizationTypeToAttributeMap[dispatchOrganizationType], true),
      );

      const { dispatchOrganizations } = await getDispatchOrganizations(
        undefined,  // options - default
        undefined,  // companyobjectid - default
        false,      // include child company results
        filters,    // filters
        undefined,  // sort - default
        ['address'],  // includes
        undefined,  // selects
        true       // query all
      );

      if (!didCancel) {
        setDispatchOrganizations(dispatchOrganizations);
        setIsFetched(true);
        setIsLoading(false);
      }
    }

    init();

    return () => { didCancel = true; };
  }, [isFetched]);

  useEffect(() => {
    generateTableColumns();
  }, [dispatchOrganizations]);

  /**
   * Functions
   */
  function onSaveDispatchOrganization() {
    setDispatchOrganization(null);
    setIsFetched(false);
    setShowEditDialog(false);
  }

  async function onRemoveDispatchOrganization() {
    const _dispatchOrganization = dispatchOrganization;
    setDispatchOrganization(null);
    await updateDispatchOrganization(_dispatchOrganization, undefined, { isHidden: true }, true);

    setIsFetched(false);
    setShowDeleteDialog(false);
  }

  /**
   * Template/JSX Functions
   */
  function organizationIdTemplate(rowData) {
    let organizationId = getAttribute(rowData, 'organizationId');
    organizationId = truncateString(organizationId, 8);

    return <div className="font-bold">{organizationId}</div>;
  }

  function organizationNameTemplate(rowData) {
    let organizationName = getAttribute(rowData, 'organizationName');
    organizationName = truncateString(organizationName, 17);

    return <div>{organizationName}</div>;
  }

  function openCloseHoursTemplate(rowData, type) {
    let hours = getAttribute(rowData, 'openHours');
    if (type === 'closeHours') {
      hours = getAttribute(rowData, 'closeHours');
    }

    return <div>{hours}</div>;
  }

  function profileNotesTemplate(rowData) {
    let profileNotes = getAttribute(rowData, 'profileNotes');
    profileNotes = truncateString(profileNotes, 25);

    return <div>{profileNotes}</div>;
  }

  function editButtonTemplate(rowData) {
    const _dispatchOrganization = rowData;

    return (
      <div>
        <Button
          text
          label=""
          icon="pi pi-pencil"
          onClick={() => {
            setDispatchOrganization(_dispatchOrganization);
            setShowEditDialog(true);
          }}
          severity="secondary"
          sbVariant="short"
        />
        <Button
          text
          label=""
          icon="pi pi-times"
          onClick={() => {
            setDispatchOrganization(_dispatchOrganization);
            setShowDeleteDialog(true);
          }}
          severity="secondary"
          sbVariant="short"
        />
      </div>
    );
  }

  function generateTableColumns() {
    const _tableColumns = [
      <Column
        key="organizationId"
        field="organizationId"
        header="ID"
        body={(rowData) => organizationIdTemplate(rowData)}
        headerStyle={{ minWidth: '80px' }}
        frozen
      />,
      <Column
        key="organizationName"
        field="organizationName"
        header="Name"
        body={(rowData) => organizationNameTemplate(rowData)}
        headerStyle={{ minWidth: '150px' }}
      />,
      <Column
        key="openHours"
        field="openHours"
        header="Open Hours"
        body={(rowData) => openCloseHoursTemplate(rowData)}
      />,
      <Column
        key="closeHours"
        field="closeHours"
        header="Closed Hours"
        body={(rowData) => openCloseHoursTemplate(rowData, 'closeHours')}
      />,
      <Column
        key="profileNotes"
        field="profileNotes"
        header="Notes"
        body={(rowData) => profileNotesTemplate(rowData)}
        headerStyle={{ minWidth: '150px' }}
      />,
      <Column
        key="editButton"
        body={(rowData) => editButtonTemplate(rowData)}
        frozen
        alignFrozen="right"
      />,
    ];

    setTableColumns(_tableColumns);
  }

  let className = 'organizations-table';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <div className="mb-3 text-right">
        {isLoading && (
          <ProgressSpinner className="ml-5" style={{ width: '2em' }} strokeWidth={5} />
        )}

        {!isLoading && (
          <div className="flex justify-content-between">
            <div>
              <OrganizationTypeDropdown
                type={dispatchOrganizationType}
                onSelect={(_dispatchOrganizationType) => {
                  setDispatchOrganizationType(_dispatchOrganizationType);
                  setIsFetched(false);
                }}
                pluralizeTypes
                hideLabel
              />
            </div>

            <div className="flex align-items-center">
              <Button
                label={`ADD ${dispatchOrganizationTypeToLabelMap[dispatchOrganizationType]}`}
                icon="pi pi-plus"
                severity="primary"
                onClick={() => setShowEditDialog(true)}
                sbVariant="short"
              />
            </div>
          </div>
        )}
      </div>
      <DataTable
        value={dispatchOrganizations}
        scrollable
        scrollHeight={props.scrollHeight || '80vh'}
      >
        {tableColumns}
      </DataTable>

      <DispatchOrganizationEditDialog
        visible={showEditDialog}
        dispatchOrganization={dispatchOrganization}
        type={dispatchOrganizationType}
        onCancel={() => {
          setShowEditDialog(false);
          setDispatchOrganization(null);
        }}
        onSave={(_dispatchOrganization) => onSaveDispatchOrganization(_dispatchOrganization)}
      />

      <ConfirmDialog
        visible={showDeleteDialog}
        onHide={() => {
          setDispatchOrganization(null);
          setShowDeleteDialog(false);
        }}
        message={(
          <div>
            <div className="mb-2">
              Delete {(getAttribute(dispatchOrganization, 'organizationName', true) || 'this organization')}?
            </div>
            They will be removed from all lists
          </div>
        )}
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={() => onRemoveDispatchOrganization(dispatchOrganization)}
        reject={() => {
          setDispatchOrganization(null);
          setShowDeleteDialog(false);
        }}
      />
    </div>
  );
}

export default OrganizationsTable;
