import React from 'react';
import { t } from 'api/Translate';

// Components
import Autocomplete from 'react-google-autocomplete';

// Styles
import './style.scss';

/**
* @description Map search bar component that passes back the selected location
* @param {Function} handlePlaceSelected - Callback function that passes back the selected location
* @returns
*
* @example
* <SearchBar handleAutocomplete={onSearchBarComplete} />
*/
function SearchBar({ ...props }) {
  return (
    <div className="map-search-bar">
      <Autocomplete
        className="autocomplete"
        placeholder={t('Search Map')}
        types={['establishment', 'geocode']}
        componentRestrictions={{ country: ['ca', 'us'] }}
        onPlaceSelected={(place) => {
          props.handlePlaceSelected(place);
        }}
      />
    </div>
  );
}

export default SearchBar;
