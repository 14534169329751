import * as ActionConstants from 'actions/ActionConstants';

export function SafetyOfficer(state = {}, action) {
  switch (action.type) {
    case ActionConstants.FETCH_SAFETYOFFICER_INPROGRESS:
      return {
        status: action.type,
        safetyOfficer: {},
        error: {},
      };
    case ActionConstants.FETCH_SAFETYOFFICER_SUCCESS:
      return {
        status: action.type,
        safetyOfficer: action.safetyOfficer,
        error: {},
      };
    case ActionConstants.FETCH_SAFETYOFFICER_ERROR:
      return {
        status: action.type,
        safetyOfficer: {},
        error: action.error,
      };
    default:
      return state;
  }
}
