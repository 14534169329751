import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { findDOMNode } from 'react-dom';
import screenfull from 'screenfull';

// Style
import './style.scss';

const initialPlayerState = {
  playing: true,
  previousVolume: 0,
  volume: 0,
  muted: true, // Starts off muted because having videos autoplaying with the volume on causes this error: DOMException: play() failed because the user didn't interact with the document first.
  seeking: false,
  played: 0,
  playedSeconds: 0,
  loaded: 0,
  duration: 0,
  pip: false,
  playbackRate: 1.0,
  loop: true,
  buffering: true,
}

/**
 * @description Video player
 * @param {string} url - The URL of the video. This can be in any compatible extension
 * @param {Function} onVideoPlayerReady - Callback function for when the video is ready to be played
 */
function VideoPlayer({ url, onVideoPlayerReady, ...props }) {
  const player = useRef();
  const [playerState, setPlayerState] = useState({ ...initialPlayerState });

  useEffect(() => {
    // Reset parts of the state when a new url comes in
    setPlayerState({ ...playerState, played: 0, playedSeconds: 0, duration: 0, buffering: true, playing: true });
  }, [url]);

  function handlePlay() {
    setPlayerState({ ...playerState, playing: true });
  }

  function handlePause() {
    setPlayerState({ ...playerState, playing: false });
  }

  function handlePlayPause() {
    setPlayerState({ ...playerState, playing: !playerState.playing });
  }

  function handleSeekMouseDown(e) {
    setPlayerState({ ...playerState, seeking: true });
  }

  function handleSeekChange(e) {
    setPlayerState({ ...playerState, played: e.target.value });
  }

  function handleSeekMouseUp(e) {
    setPlayerState({ ...playerState, playing: true, seeking: false });
    player.current.seekTo(playerState.played);
  }

  function handleFullScreen() {
    screenfull.request(findDOMNode(player.current));
  }

  function handleVolumeChange(e) {
    const _volume = parseFloat(e.target.value);
    setPlayerState({ ...playerState, previousVolume: _volume, volume: _volume });
  }

  function handleMute() {
    if (playerState.volume === 0) {
      setPlayerState({ ...playerState, volume: playerState.previousVolume });
    } else {
      setPlayerState({ ...playerState, volume: 0 });
    }
  }

  function handleSpeed(e) {
    setPlayerState({ ...playerState, playbackRate: parseFloat(e.target.value) });
  }

  function handleLoop() {
    setPlayerState({ ...playerState, loop: !playerState.loop });
  }

  function handleProgress(e) {
    if (!playerState.seeking) {
      setPlayerState({ ...playerState, played: e.played, loaded: e.loaded, playedSeconds: e.playedSeconds });
    }
  }

  function handleDuration(duration) {
    setPlayerState({ ...playerState, duration });
  }

  function formatSeconds(seconds) {
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = ('0' + date.getUTCSeconds()).slice(-2);

    if (hh) {
      return `${hh}:${('0' + mm).slice(-2)}:${ss}`;
    }
    return `${mm}:${ss}`;
  }

  return (
    <div className="video-player-wrapper w-full shadow-1">
      <div className="video-player-display">
        <img src="https://s3.amazonaws.com/onswitchboard.com/white-logo.png" className="video-player-logo" />
        {url
          ? <ReactPlayer
            className="player"
            ref={player}
            url={url}
            playing={playerState.playing}
            loop={playerState.loop}
            muted={playerState.muted}
            playbackRate={playerState.playbackRate}
            onBuffer={() => setPlayerState({ ...playerState, buffering: true })}
            onBufferEnd={() => setPlayerState({ ...playerState, buffering: false })}
            onProgress={handleProgress}
            onDuration={handleDuration}
            onPlay={handlePlay}
            onEnded={handlePause}
            onReady={() => props.onVideoPlayerReady && props.onVideoPlayerReady()}
            volume={playerState.volume}
            width="100%"
            height="100%"
            onError={(err) => { props.isLive ? setTimeout(() => handlePlay(), 1000) : console.log(err) }} // If its a livestream, keep attempting to play the stream when it encounters an error
          />
          : <div className="flex align-items-center justify-content-center overlay-message translate-me">PLEASE SELECT A VIDEO</div>
        }
        {url && playerState.buffering &&
          <div className="video-player-loading">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        }
      </div>
      <div className="video-player-controls">
        <div className="video-controls-top">
          <div className={`seek-bar${props.isLive ? ' seek-bar-live' : ''}`}>
            <input
              disabled={!url || props.isLive}
              type='range'
              className="w-full"
              min={0}
              max={0.9999999}
              step='any'
              value={props.isLive ? 1 : playerState.played}
              onMouseDown={(e) => handleSeekMouseDown(e.nativeEvent)}
              onMouseUp={(e) => handleSeekMouseUp(e.nativeEvent)}
              onDragStart={(e) => handleSeekMouseDown(e.nativeEvent)}
              onDragEnd={(e) => handleSeekMouseUp(e.nativeEvent)}
              onChange={(e) => handleSeekChange(e.nativeEvent)}
            />
          </div>
        </div>

        <div className="video-controls-bottom flex justify-content-between">
          <div className="video-controls-bottom-left flex align-items-center">
            {props.isLive
              ? <div className="display-live">
                <span className="live-icon material-icons md-48">circle</span>
                LIVE
              </div>
              : (
                <>
                  <div>
                    <span className="play-button material-icons md-48" onClick={handlePlayPause}>{playerState.playing ? 'pause' : 'play_arrow'}</span>
                  </div>
                  <div className="display-time">{formatSeconds(playerState.duration * playerState.played)} / {formatSeconds(playerState.duration)}</div>
                </>
              )
            }
          </div>
          <div className="flex align-items-center">
            {playerState.volume === 0 ? <span className="volume-icon-off material-icons md-48" onClick={handleMute}>volume_mute</span> : <span className="volume-icon-on material-icons md-48" onClick={handleMute}>volume_up</span>}
            <input
              type='range'
              className="volume-bar w-full"
              min={0}
              max={1}
              step='any'
              value={playerState.volume}
              onChange={(e) => handleVolumeChange(e.nativeEvent)}
            />
            {!props.isLive &&
              <div className="loop-button">
                <span className={`material-icons md-48${playerState.loop ? ' loop-button-on' : ' loop-button-off'}`} onClick={handleLoop}>replay</span>
              </div>
            }
            {<span className="fullscreen-button material-icons md-48" onClick={handleFullScreen}>fullscreen</span>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoPlayer;
