import React from 'react';
import PropTypes from 'prop-types';
// Components
import ChooseOrAddButton from 'components/ChooseOrAddModal/container/ChooseOrAddButton';
import DatePicker from 'components/DatePicker/container/DatePicker';
// CSS
import styles from './GlobalJobDetails.module.scss';

function GlobalJobDetails(props) {
  const globalJobDetails = props.globalJobDetails;

  return (
    <div className={styles.globalJobDetails}>
      <table className={styles.table}><tbody>
        <tr>
          <td>
            <div>
              <label className="inputLabel">Job ID<span className={styles.required}>&nbsp;*</span></label>
              <input className="input" value={globalJobDetails.jobId} autoFocus type="text" placeholder="....." onChange={(e) => props.handleFormChange('jobId', e.target.value, 'global')} />
            </div>
          </td>
          <td>
            <div>
              <label className="inputLabel">Vehicle<span className={styles.required}>&nbsp;*</span></label>
              { globalJobDetails.vehicle &&
                <div>
                  <div><b>{ globalJobDetails.vehicle.get('unitId') }</b></div>
                </div>
              }
              <ChooseOrAddButton label="Vehicle" property="vehicle" handleChooseOrAdd={(vehicleParseObj) => props.handleFormChange('vehicle', vehicleParseObj, 'global')}>Choose Vehicle</ChooseOrAddButton>
            </div>
          </td>
        </tr>
      </tbody></table>
    </div>
  );
}

GlobalJobDetails.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  globalJobDetails: PropTypes.object.isRequired,
};

export default GlobalJobDetails;
