import { callCloudFunction, createQuery, findRecords, setQueryRestriction } from 'api/Parse';
import { QueryRestrictionTypes } from 'enums/Query';

/** @module DriverModule */

/**
 * @memberof module:DriverModule
 * @description Given an objectId or driverObjectId, retrieve the DriverModule record
 * 
 * @param {string} objectId
 * @param {string} driverObjectId
 * 
 * @returns DriverModule record
 */
async function getDriverModule(objectId, driverObjectId) {
  const driverModuleQuery = createQuery('DriverModule');

  if (objectId) {
    setQueryRestriction(driverModuleQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', objectId);
  } else {
    setQueryRestriction(driverModuleQuery, QueryRestrictionTypes.EQUAL_TO, 'driver', driverObjectId);
  }

  return await findRecords(driverModuleQuery, true);
}

/**
 * @memberof module:DriverModule
 * @description Given an array of driver objectIds, update properties of their driverModule
 * 
 * @param {array} driverObjectIds - ObjectIds of drivers whom we want to edit driverModules for
 * @param {object} keyValueObject - Key-value map of the attributes we want to change, and the values to change to
 */
async function updateDriverModule(driverObjectIds = [], keyValueObject = {}) {
  return await callCloudFunction('updateDriverModule', { driverObjectIds, keyValueObject });
}

/**
 * @memberof module:DriverModule
 * @description For all drivers in the company, update properties of their driverModule
 * 
 * @param {object} keyValueObject - Key-value map of the attributes we want to change, and the values to change to
 */
async function updateAllDriversDriverModule(keyValueObject = {}) {
  return await callCloudFunction('updateAllDriversDriverModule', { keyValueObject });
}

export {
  getDriverModule,
  updateAllDriversDriverModule,
  updateDriverModule,
};
