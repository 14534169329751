import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from 'sbHistory';
import moment from 'moment-timezone';

// API
import * as Getters from 'api/Getters';
import { getQueryParameter } from 'api/URL';

// Components
import Sidebar from 'components/EquipmentSidebar/view/Sidebar';
import VehiclesLayout from './VehiclesLayout';
import TrailersLayout from './TrailersLayout';
import LocationsLayout from './LocationsLayout';
import EquipmentGroupList from 'components/EquipmentGroup/EquipmentGroupList/EquipmentGroupList';

// CSS
import styles from './EquipmentLayout.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

class EquipmentLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subViewSelected: 'vehicles',
      expiredEquipment: [],
      expiredEquipmentLoaded: false,
    };
    // this.refreshExpiredEquipment = this.refreshExpiredEquipment.bind(this);
    this.handleSubViewClick = this.handleSubViewClick.bind(this);
  }

  componentWillMount() {
    const userSpecialPermission = Getters.getCurrentUser(true).userSpecialPermission;
    if (userSpecialPermission && userSpecialPermission.disableEquipmentModule) {
      history.push({ pathname: '404' });
    } else {
      this.setState({ ...this.state, expiredEquipmentLoaded: false });
      const expiredVehicleQuery = Getters.queryCompanyObjects('Vehicle', undefined, undefined, [{ queryType: 'date', name: 'nextInspectionDate', toValue: moment().add(14, 'days') }, { name: 'enabled', queryType: 'equalTo', value: true }, { name: 'unitId', queryType: 'exists' }, { queryType: 'equalTo', name: 'enabled', value: true }], { nextInspectionDate: 'ascending' }, ['licensePlate', 'trailer', 'trailer.licensePlate']);
      const expiredvTrailerQuery = Getters.queryCompanyObjects('Trailer', undefined, undefined, [{ queryType: 'date', name: 'nextInspectionDate', toValue: moment().add(14, 'days') }, { name: 'enabled', queryType: 'equalTo', value: true }, { name: 'unitId', queryType: 'exists' }, { queryType: 'equalTo', name: 'enabled', value: true }], { nextInspectionDate: 'ascending' }, ['licensePlate', 'vehicle', 'vehicle.licensePlate']);
      const vehicleWithoutExpiry = Getters.queryCompanyObjects('Vehicle', undefined, undefined, [{ queryType: 'equalTo', name: 'nextInspectionDate', value: null }, { name: 'enabled', queryType: 'equalTo', value: true }, { name: 'unitId', queryType: 'exists' }, { queryType: 'equalTo', name: 'enabled', value: true }], undefined, ['licensePlate', 'trailer', 'trailer.licensePlate']);
      const trailerWithoutExpiry = Getters.queryCompanyObjects('Trailer', undefined, undefined, [{ queryType: 'equalTo', name: 'nextInspectionDate', value: null }, { name: 'enabled', queryType: 'equalTo', value: true }, { name: 'unitId', queryType: 'exists' }, { queryType: 'equalTo', name: 'enabled', value: true }], undefined, ['licensePlate', 'vehicle', 'vehicle.licensePlate']);
      Promise.all([expiredVehicleQuery, expiredvTrailerQuery, vehicleWithoutExpiry, trailerWithoutExpiry]).then((response) => {
        this.setState({ ...this.state, expiredEquipmentLoaded: true, expiredEquipment: [].concat(response[0], response[1], response[2], response[3]) });
      });
    }
  }

  componentDidMount() {
    const tempView = getQueryParameter(this.props.location.search, 'view');
    this.setState({ ...this.state, subViewSelected: tempView ? tempView : 'vehicles' });
  }

  componentWillReceiveProps(nextProps) {
    const tempView = getQueryParameter(nextProps.location.search, 'view');
    this.setState({ ...this.state, subViewSelected: tempView ? tempView : 'vehicles' });
  }

  handleSubViewClick(subview) {
    history.push({ pathname: 'equipment', search: "view=" + subview });
  }

  render() {
    let companyName = '';
    if (this.props.Company.fetched === true) { // ensures that company is loaded
      companyName = this.props.Company.company.get('name');
    }
    return (
      <div>
        <Sidebar activeSubview={this.state.subViewSelected} handleSubViewClick={this.handleSubViewClick} companyName={companyName} alerts={this.state.expiredEquipment.length} />
        <div className={styles.content}>
          {this.state.subViewSelected === 'vehicles' &&
            <VehiclesLayout
              location={this.props.location}
            />
          }
          {this.state.subViewSelected === 'trailers' &&
            <TrailersLayout
              location={this.props.location}
            />
          }
          {this.state.subViewSelected === 'locations' &&
            <LocationsLayout />
          }
          {this.state.subViewSelected === 'groups' &&
            <EquipmentGroupList Company={this.props.Company} location={this.props.location} />
          }
        </div>
      </div>
    );
  }
}

EquipmentLayout.propTypes = {
  params: PropTypes.object,
  location: PropTypes.object,
  Company: PropTypes.object,
  location: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { Company } = state;
  return {
    Company,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(EquipmentLayout);
