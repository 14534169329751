import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "sbHistory";

// API
import * as Helpers from 'api/Helpers';
import * as Getters from 'api/Getters';
import * as SI from 'api/DriverPatterns/SpeedingIdling';
import { MonthsHash } from 'api/Lists/Months';
import { addReplaceQueryParameter, getQueryParameter } from 'api/URL';

// Components
import RadioSet from 'components/Shared/RadioSet/RadioSet';
import ListSelectField from 'components/ListSelectField/view/ListSelectField';
import DriverSpeedViolationsTable from 'components/SpeedingIdling/container/DriverSpeedViolationsTable';
import DriverIdlingTable from 'components/SpeedingIdling/container/DriverIdlingTable';
import DriverRankingsTable from 'components/SpeedingIdling/container/DriverRankingsTable';

import AutoComplete from 'material-ui/AutoComplete';
import RaisedButton from 'material-ui/RaisedButton';
import DatePicker from 'material-ui/DatePicker';
import CircularProgress from 'material-ui/CircularProgress';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import Chip from 'material-ui/Chip';
import { Tabs, Tab } from 'material-ui/Tabs';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';


// CSS
import styles from './SpeedPatternsLayout.module.scss';

class SpeedingPatternsLayout extends React.Component {
  constructor(props) {
    super(props);

    let startTimeUTC = moment().startOf('day').toDate();
    let tempDate = getQueryParameter(props.location.search, 'date');
    if (tempDate) {
      startTimeUTC = moment(tempDate, 'DDMMYY');
    }

    let filterType = 0;
    let tempDateBy = getQueryParameter(props.location.search, 'dateby');
    if (tempDateBy === 'month') {
      filterType = 1;
    }

    this.state = {
      isLoading: false,
      isLoadingDrivingTimeIntervals: false,
      selectedDrivers: [],
      speedIdleDataByDriver: {},
      eldDailyCertifications: [],
      sort: undefined,
      startTimeUTC: moment(startTimeUTC).toDate(),
      filter: [],
      filterTypes: [
        { code: 0, name: 'Day' },
        { code: 1, name: 'Month' },
      ],
      filterType,
      filterTypeForDisplay: filterType, // filterType but only for this state changes (so it doesnt affect child components)
      distanceUnit: Getters.getPDispatcherPropertyFromState('distanceUnit') || 'km',

      LayoutTypes: {
        'OVERALL_SUMMARY': 'Overall Summary',
        'DRIVER_BREAKDOWN': 'Driver Breakdown',
      },
      layoutType: 'Overall Summary', // 0 summary, 1 breakdown
      viewType: 0, // 0 speeding, 1 idling

      rankingTypes: {
        0: {
          0: 'Total Time Speeding',
          1: 'Time Speeding vs Driving',
        },
        1: {
          0: 'Total Time Idling',
          1: 'Time Idling vs Driving',
        }
      },
      rankingType: 0, // viewType + rankingType make up which ranking in rankingTypes to use

      dateBreakdown: {
        month: parseInt(moment(startTimeUTC).format('M')) - 1,
        monthName: moment(startTimeUTC).format('MMMM'),
        year: parseInt(moment(startTimeUTC).format('YYYY')),
      },

    };
    this.refreshState = this.refreshState.bind(this);
    this.getDateStates = this.getDateStates.bind(this);
    this.handleIntervalChange = this.handleIntervalChange.bind(this);
    this.filterByDate = this.filterByDate.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.filterByMonth = this.filterByMonth.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleDistanceUnitChange = this.handleDistanceUnitChange.bind(this);
    this.handleViewTypeChange = this.handleViewTypeChange.bind(this);
    this.getSpeedPatternData = this.getSpeedPatternData.bind(this);
    this.integrateDrivingTimeIntervals = this.integrateDrivingTimeIntervals.bind(this);
    this.getIdlingPatternData = this.getIdlingPatternData.bind(this);
    this.addSelectedDriver = this.addSelectedDriver.bind(this);
    this.deleteFromSelectedDrivers = this.deleteFromSelectedDrivers.bind(this);
    this.handleRankingTypeChange = this.handleRankingTypeChange.bind(this);
    this.handleLayoutTypeChange = this.handleLayoutTypeChange.bind(this);
    // this.filter = this.filter.bind(this);
    // this.sort = this.sort.bind(this);
  }

  componentDidMount() {
    // this.refreshState(this.props.location);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.location.search !== this.props.location.search) {
      this.refreshState(nextProps.location);
    }
  }

  getDateStates(date = (new Date())) {
    let startTimeUTC = moment(date).startOf('day');

    let dateBreakdown = {
      month: parseInt(startTimeUTC.format('M')) - 1,
      monthName: startTimeUTC.format('MMMM'),
      year: parseInt(startTimeUTC.format('YYYY')),
    };

    return { startTimeUTC: startTimeUTC.toDate(), dateBreakdown };
  }

  refreshState(location) {
    // need this cause date filter has problems (?) updating its state properly
    let tempDate = getQueryParameter(location.search, 'date');
    const locationDate = tempDate ? moment(tempDate, 'DDMMYY') : moment();
    const { startTimeUTC, dateBreakdown } = this.getDateStates(locationDate);

    const newState = {
      ...this.state,
      startTimeUTC,
      dateBreakdown,
    };
    this.setState(newState, () => {
      this.getSpeedPatternData();
    });
  }

  handleIntervalChange(intervalTypeName) {
    const filterTypeForDisplay = this.state.filterTypes.filter(type => type.name === intervalTypeName)[0].code;

    const d = moment(this.state.startTimeUTC);
    if (filterTypeForDisplay === 1) {
      d.startOf('month');
    }

    const { startTimeUTC, dateBreakdown } = this.getDateStates(d);

    this.setState({
      ...this.state,
      startTimeUTC,
      dateBreakdown,
      filterTypeForDisplay,
    });
  }

  filterByDate(filterDate) {
    const { location } = this.props;

    let date = filterDate ? moment(filterDate) : moment();

    history.push({
      pathname: location.pathname, search: location.search ? addReplaceQueryParameter(addReplaceQueryParameter(location.search, "date", date.format('DDMMYY')), "databy", "date") : "date=" + date.format('DDMMYY') + "&databy=date"
    });
  }

  handleMonthChange(monthName) {
    const { startTimeUTC, dateBreakdown } = this.getDateStates(moment().month(monthName).startOf('month').startOf('date').toDate());

    this.setState({
      ...this.state,
      startTimeUTC,
      dateBreakdown,
    });
  }

  handleYearChange(year) {
    const date = moment(this.state.startTimeUTC).year(year);
    const { startTimeUTC, dateBreakdown } = this.getDateStates(date);

    this.setState({
      ...this.state,
      startTimeUTC,
      dateBreakdown,
    });
  }

  filterByMonth() {
    history.push({
      pathname: location.pathname, search: location.search ? addReplaceQueryParameter(addReplaceQueryParameter(location.search, "date", moment(this.state.startTimeUTC).format('DDMMYY')), "databy", "month") : "date=" + moment(this.state.startTimeUTC).format('DDMMYY') + "&databy=month"
    });
  }

  handleDistanceUnitChange(distanceUnit) {
    this.setState({ ...this.state, distanceUnit });
  }

  handleViewTypeChange(viewTypeString) {
    let viewType = 0;
    if (viewTypeString.toLowerCase() === 'idling') {
      viewType = 1;
    }
    this.setState({ ...this.state, viewType, isLoading: true }, () => {
      this.getSpeedPatternData();
    });
  }

  getIdlingPatternData() {
    this.setState({ ...this.state, isLoading: true }, () => {
      const updatedSpeedIdleDataByDriver = { ...speedIdleDataByDriver };
      const driverIds = this.props.drivers.map(driver => driver.id);

      // get all daily certs
      let eldDailyCertifications = [];
      driverIds.map(driverId => {
        if (!updatedSpeedIdleDataByDriver) {
          updatedSpeedIdleDataByDriver[driverId] = {

          };
        }
        eldDailyCertifications.push.apply(eldDailyCertifications, speedIdleDataByDriver[driverId].eldDailyCertifications);
      });
      // filter out fake daily certs
      eldDailyCertifications = eldDailyCertifications.filter((eldDailyCertification) => {
        return eldDailyCertification.id;
      });

      // now that we have all daily certifications, get all driving periods related to them
      SI.getDrivingPeriods(undefined, eldDailyCertifications).then(
        drivingPeriodsByDriver => {

          // now that we have driving periods organized by day organized by driver, match the day to the speedIdlingObject in speedIdleDataByDriver
          Object.keys(drivingPeriodsByDriver).map(driverId => {

            if (speedIdleDataByDriver[driverId]) { // if matching driver exists in speedIdleDataByDriver
              // get all daily cert ms from the driving periods
              updatedSpeedIdleDataByDriver[driverId].drivingTimePeriodsByDay = { ...drivingPeriodsByDriver[driverId].drivingTimePeriodsByDay };
              const eldDailyCertificationStartTimeUTCMsArr = Object.keys(drivingPeriodsByDriver[driverId].drivingTimePeriodsObject);
              eldDailyCertificationStartTimeUTCMsArr.map(eldDailyCertificationStartTimeUTCMs => {
                // if matching daily cert time from speedIdleDataByDriver, combine the driving and speedIdling data
                let speedIdlingObjectsByDay = speedIdleDataByDriver[driverId].speedIdlingObject[eldDailyCertificationStartTimeUTCMs];
                if (speedIdlingObjectsByDay) {

                  const driverDrivingPeriodDay = drivingPeriodsByDriver[driverId].drivingTimePeriodsObject[eldDailyCertificationStartTimeUTCMs];
                  SI.removeAddInterimEvents(driverDrivingPeriodDay, true);
                  SI.removeAddInterimEvents(speedIdlingObjectsByDay, true);

                  speedIdlingObjectsByDay = [].concat(speedIdlingObjectsByDay, driverDrivingPeriodDay);

                  SI.sortSpeedIdlingEvents(speedIdlingObjectsByDay);
                  SI.removeAddInterimEvents(speedIdlingObjectsByDay);

                  updatedSpeedIdleDataByDriver[driverId].speedIdlingObject[eldDailyCertificationStartTimeUTCMs] = speedIdlingObjectsByDay;

                }
              });
            }
          });

          /*
            at this point we have the following: driver, speedViolations, eldDailyCertifications, drivingTimePeriods, speedIdlingObject
            where speedIdlingObject holds all the driving and speeding data in the order which they occured

            currently, driving time and time of violations dont have any associations in terms of data structure (no pointers to one or the other)
            however, based on the principle that violations happen in between driving time periods (note that this can change due to the process of edits)
            we do our best to make associations between violations and driving time given speedIdlingObject
          */
          this.setState({
            ...this.state,
            speedIdleDataByDriver: updatedSpeedIdleDataByDriver,
            isLoadingDrivingTimeIntervals: false,
          });
        }
      );

    });
  }

  integrateDrivingTimeIntervals(speedIdleDataByDriver) {
    this.setState({ ...this.state, isLoadingDrivingTimeIntervals: true }, () => {
      const updatedSpeedIdleDataByDriver = { ...speedIdleDataByDriver };
      const driverIds = Object.keys(speedIdleDataByDriver);

      // get all daily certs
      let eldDailyCertifications = [];
      driverIds.map(driverId => {
        eldDailyCertifications.push.apply(eldDailyCertifications, speedIdleDataByDriver[driverId].eldDailyCertifications);
      });
      // filter out fake daily certs
      eldDailyCertifications = eldDailyCertifications.filter((eldDailyCertification) => {
        return eldDailyCertification.id;
      });

      // now that we have all daily certifications, get all driving periods related to them
      SI.getDrivingPeriods(undefined, eldDailyCertifications).then(
        drivingPeriodsByDriver => {
          // now that we have driving periods organized by day organized by driver, match the day to the speedIdlingObject in speedIdleDataByDriver
          Object.keys(drivingPeriodsByDriver).map(driverId => {

            if (speedIdleDataByDriver[driverId]) { // if matching driver exists in speedIdleDataByDriver
              // get all daily cert ms from the driving periods
              updatedSpeedIdleDataByDriver[driverId].drivingTimePeriodsByDay = { ...drivingPeriodsByDriver[driverId].drivingTimePeriodsByDay };
              const eldDailyCertificationStartTimeUTCMsArr = Object.keys(drivingPeriodsByDriver[driverId].drivingTimePeriodsObject);
              eldDailyCertificationStartTimeUTCMsArr.map(eldDailyCertificationStartTimeUTCMs => {
                // if matching daily cert time from speedIdleDataByDriver, combine the driving and speedIdling data
                let speedIdlingObjectsByDay = speedIdleDataByDriver[driverId].speedIdlingObject[eldDailyCertificationStartTimeUTCMs];
                if (speedIdlingObjectsByDay) {

                  const driverDrivingPeriodDay = drivingPeriodsByDriver[driverId].drivingTimePeriodsObject[eldDailyCertificationStartTimeUTCMs];
                  SI.removeAddInterimEvents(driverDrivingPeriodDay, true);
                  SI.removeAddInterimEvents(speedIdlingObjectsByDay, true);

                  speedIdlingObjectsByDay = [].concat(speedIdlingObjectsByDay, driverDrivingPeriodDay);

                  SI.sortSpeedIdlingEvents(speedIdlingObjectsByDay);
                  SI.removeAddInterimEvents(speedIdlingObjectsByDay);

                  updatedSpeedIdleDataByDriver[driverId].speedIdlingObject[eldDailyCertificationStartTimeUTCMs] = speedIdlingObjectsByDay;

                }
              });
            }
          });

          /*
            at this point we have the following: driver, speedViolations, eldDailyCertifications, drivingTimePeriods, speedIdlingObject
            where speedIdlingObject holds all the driving and speeding data in the order which they occured

            currently, driving time and time of violations dont have any associations in terms of data structure (no pointers to one or the other)
            however, based on the principle that violations happen in between driving time periods (note that this can change due to the process of edits)
            we do our best to make associations between violations and driving time given speedIdlingObject
          */
          this.setState({
            ...this.state,
            speedIdleDataByDriver: updatedSpeedIdleDataByDriver,
            isLoadingDrivingTimeIntervals: false,
          });
        }
      );

    });
  }

  getSpeedPatternData() {
    // const { drivers } = this.props;
    const { selectedDrivers, startTimeUTC, filterType, filterTypeForDisplay, viewType } = this.state;
    this.setState({ ...this.state, isLoading: true, speedIdleDataByDriver: {} }, () => {
      let intervalStart = moment(startTimeUTC);
      let intervalEnd = moment(intervalStart).add(1, 'day');
      if (filterTypeForDisplay === 1) {
        intervalStart = intervalStart.startOf('month'); // we do this in case user tries to be tricky with the url date param
        intervalEnd = moment(intervalStart).endOf('month').add(1, 'millisecond');
      }

      intervalStart = intervalStart.toDate();
      intervalEnd = intervalEnd.toDate();
      SI.getSpeedIdleDataByDriver(intervalStart, intervalEnd, selectedDrivers).then(
        (speedIdleDataByDriver) => {

          if (viewType === 0) {
            // filter violation data
            // Object.keys(speedIdleDataByDriver).map(driverId => {
            //   // if no speed violations, do not show
            //   if (Object.keys(speedIdleDataByDriver[driverId].speedViolationsByDay).length === 0) {
            //     delete speedIdleDataByDriver[driverId];
            //   }
            // });
          }
          else {

            // remove violation data since we want driving/idling data only
            Object.keys(speedIdleDataByDriver).map(driverId => {
              Object.keys(speedIdleDataByDriver[driverId].speedIdlingObject).map(startTimeUTCMs => {
                speedIdleDataByDriver[driverId].speedIdlingObject[startTimeUTCMs] = [];
              });
            });
          }

          this.setState({ ...this.state, isLoading: false, speedIdleDataByDriver, filterType: filterTypeForDisplay }, () => {
            // now pull driving time info
            this.integrateDrivingTimeIntervals(this.state.speedIdleDataByDriver);
          });
        }
      );
    });
  }

  addSelectedDriver(driver) {
    if (driver) {
      const selectedDriverIds = this.state.selectedDrivers.map(driver => driver.id);
      if (selectedDriverIds.indexOf(driver.id) === -1) {
        const newState = { ...this.state };
        newState.selectedDrivers = [...this.state.selectedDrivers, driver];
        this.setState(newState);
      }
    }
  }

  deleteFromSelectedDrivers(driver) {
    const selectedDriverIds = this.state.selectedDrivers.map(driver => driver.id);
    const driverIndex = selectedDriverIds.indexOf(driver.id);
    const newState = { ...this.state };
    newState.selectedDrivers = [...this.state.selectedDrivers];
    newState.selectedDrivers.splice(driverIndex, 1);
    this.setState(newState);
  }

  handleRankingTypeChange(rankingTypeString) {
    const { rankingTypes, viewType } = this.state;
    const newState = { ...this.state };
    const rankingTypeObject = rankingTypes[viewType]; // whether its speeding or idling ranking types
    newState.rankingType = Object.keys(rankingTypeObject).filter(key => {
      if (rankingTypeObject[key] === rankingTypeString) return true;
    })[0];
    this.setState(newState);
  }

  handleLayoutTypeChange(layoutType) {
    const newState = { ...this.state };
    newState.layoutType = layoutType;
    this.setState(newState);
  }


  render() {
    const { drivers, location } = this.props;
    const {
      layoutType, selectedDrivers, speedIdleDataByDriver, filterType, filterTypeForDisplay, filterTypes, dateFilterFields, dateBreakdown, startTimeUTC, isLoading,
      isLoadingDrivingTimeIntervals, distanceUnit, viewType, rankingTypes, rankingType, LayoutTypes
    } = this.state;
    const intervalSelectorStyle = { marginRight: '.5em', width: '8em', textAlign: 'left' };

    let viewTypeString = (viewType === 0) ? 'Speeding' : 'Idling';

    const yearsList = [];
    let currentYear = moment().year();
    while (currentYear > 2014) { yearsList.push(currentYear); currentYear-- }

    const selectedDriverChips = selectedDrivers.map(driver => {
      return (
        <Chip
          key={driver.id}
          onRequestDelete={() => this.deleteFromSelectedDrivers(driver)}
          style={{ margin: '2' }}
        >
          {Helpers.formatName(driver.get('user_fullName'))}
        </Chip>
      );
    });

    // console.log(speedIdleDataByDriver);
    const driverIds = Object.keys(speedIdleDataByDriver);
    const driverSpeedIdlingTableContainerStyle = {};
    if (driverIds.length > 0) {
      driverSpeedIdlingTableContainerStyle.marginBottom = '1em';
    }

    let speedIdlingTables = !isLoading && !isLoadingDrivingTimeIntervals && <div>No data during selected interval for the selected drivers</div>;
    let showRankingTables = false;
    const rankingTypeList = Object.keys(rankingTypes[viewType]).map(key => {
      return rankingTypes[viewType][key];
    });

    if (driverIds.length > 0) {

      speedIdlingTables = driverIds.map(
        driverId => {
          const driverSpeedIdleData = speedIdleDataByDriver[driverId];
          const driver = driverSpeedIdleData.driver;
          const driverFullName = Helpers.formatName(driver.get('user_fullName'));

          if (viewType === 0) {
            return (
              <div key={driverId} style={driverSpeedIdlingTableContainerStyle}>
                <DriverSpeedViolationsTable
                  title={driverFullName}
                  driverSpeedIdleData={driverSpeedIdleData}
                  isLoading={isLoading}
                  height="auto"
                  monthView={filterType === 1}
                  startTimeUTC={startTimeUTC}
                  distanceUnit={distanceUnit.toLowerCase()}
                />
              </div>
            );
          }

          return (
            <div key={driverId} style={driverSpeedIdlingTableContainerStyle}>
              <DriverIdlingTable
                title={driverFullName}
                driverSpeedIdleData={driverSpeedIdleData}
                isLoading={isLoading}
                height="auto"
                monthView={filterType === 1}
                startTimeUTC={startTimeUTC}
                distanceUnit={distanceUnit.toLowerCase()}
              />
            </div>
          );
        }
      );


      showRankingTables = false;

    }

    return (
      <MDBContainer fluid>
        <MDBRow className="mt-2"><MDBCol>
          <RadioSet
            selected={layoutType}
            labels={Object.keys(LayoutTypes).map(key => LayoutTypes[key])}
            onChangeCallBack={this.handleLayoutTypeChange}
          />
        </MDBCol></MDBRow>

        {layoutType === LayoutTypes.OVERALL_SUMMARY &&
          <div style={{ marginTop: '1em' }}>
            <DriverRankingsTable />
          </div>
        }

        {layoutType === LayoutTypes.DRIVER_BREAKDOWN &&
          <div style={{ marginTop: '1em' }}>
            <Card>
              <CardHeader title="Select Drivers" />
              <CardText>

                <AutoComplete
                  filter={AutoComplete.caseInsensitiveFilter}
                  dataSource={drivers.map((driver) => ({ text: Helpers.toTitleCase(driver.get('user_fullName')), value: driver }))}
                  onNewRequest={(data) => this.addSelectedDriver(data.value)}
                  hintText="Enter a Driver"
                  fullWidth
                  disabled={isLoading}
                />

                <div style={{ marginTop: '1em', display: 'flex', flexWrap: 'wrap' }}>
                  {selectedDriverChips}
                </div>

              </CardText>
            </Card>

            <div style={{ marginTop: '1.5em' }} />

            <Card>
              <CardHeader title="Select Data Interval" />

              {selectedDrivers.length === 0 &&
                <div style={{ marginTop: '.5em', marginBottom: '.5em', marginLeft: '1em' }}>
                  You must select drivers by using the selector above before obtaining their data
                </div>
              }

              <div>
                {!isLoading &&
                  <CardText>

                    <div className="inlineBlock" style={{ verticalAlign: 'middle' }}>
                      <ListSelectField
                        onChange={(e, index, viewTypeString) => this.handleViewTypeChange(viewTypeString)}
                        floatingLabelText="View"
                        value={['Speeding', 'Idling'][viewType]}
                        maxHeight={200}
                        list={['Speeding', 'Idling']}
                        disabled={selectedDrivers.length === 0}
                        style={{ ...intervalSelectorStyle, marginRight: '1em', width: '8em' }}
                      />
                    </div>

                    <div className="inlineBlock" style={{ verticalAlign: 'middle' }}>
                      <ListSelectField
                        onChange={(e, index, distanceUnit) => this.handleDistanceUnitChange(distanceUnit)}
                        floatingLabelText="Distance"
                        value={distanceUnit}
                        maxHeight={200}
                        list={['km', 'mi']}
                        disabled={selectedDrivers.length === 0}
                        style={{ ...intervalSelectorStyle, marginRight: '1em', width: '5em' }}
                      />
                    </div>

                    <div className="inlineBlock" style={{ verticalAlign: 'middle' }}>
                      <ListSelectField
                        onChange={(e, index, filterTypeName) => this.handleIntervalChange(filterTypeName)}
                        floatingLabelText="Data By"
                        value={filterTypes.filter(type => type.code === filterTypeForDisplay)[0].name}
                        maxHeight={200}
                        list={filterTypes.map(type => type.name)}
                        disabled={selectedDrivers.length === 0}
                        style={{ ...intervalSelectorStyle, width: '7em' }}
                      />
                    </div>

                    {filterTypeForDisplay === 0 &&
                      <div className="inlineBlock" style={{ verticalAlign: 'middle', top: '-.96em', marginTop: '.5em', paddingTop: '3em' }}>
                        <DatePicker
                          id="speedingDate"
                          hintText={moment(startTimeUTC).format('MMM DD, YYYY')}
                          value={startTimeUTC}
                          onChange={(e, date) => this.filterByDate(date)}
                          formatDate={(date) => moment(date).format('MMM DD, YYYY')}
                          style={{ display: 'inline-block' }}
                          textFieldStyle={{ width: '8em' }}
                          mode="landscape"
                          disabled={selectedDrivers.length === 0}
                        />

                        <div className="inlineBlock">
                          <RaisedButton
                            key="filterByDate"
                            label="Confirm"
                            primary
                            onClick={() => this.filterByDate(startTimeUTC)}
                            disabled={selectedDrivers.length === 0}
                          />
                        </div>
                      </div>
                    }

                    {filterTypeForDisplay === 1 &&
                      <div className="inlineBlock" style={{ verticalAlign: 'middle', marginTop: '1.05em' }}>
                        <span>
                          <ListSelectField
                            onChange={(e, index, monthName) => this.handleMonthChange(monthName)}
                            floatingLabelText="For Month"
                            value={dateBreakdown.monthName}
                            maxHeight={200}
                            list={Object.keys(MonthsHash)}
                            disabled={selectedDrivers.length === 0}
                            style={{ display: 'inline-block', top: '-.18em', width: '10em', textAlign: 'left' }}
                          />

                          <ListSelectField
                            onChange={(e, index, year) => this.handleYearChange(year)}
                            floatingLabelText="For Year"
                            value={dateBreakdown.year}
                            maxHeight={200}
                            list={yearsList}
                            disabled={selectedDrivers.length === 0}
                            style={{ display: 'inline-block', top: '-.18em', width: '8em', textAlign: 'left' }}
                          />

                          <div className="inlineBlock" style={{ top: '-1.5em' }}>
                            <RaisedButton
                              key="filterByMonth"
                              label="Confirm"
                              primary
                              onClick={this.filterByMonth}
                              disabled={selectedDrivers.length === 0}
                            />
                          </div>
                        </span>
                      </div>
                    }
                  </CardText>
                }

                {isLoading &&
                  <CardText style={{ textAlign: 'right' }}>
                    <CircularProgress />
                  </CardText>
                }
              </div>
            </Card>

            {isLoadingDrivingTimeIntervals &&
              <div style={{ textAlign: 'right' }}>
                <span>Loading Driving Data...</span><CircularProgress />
              </div>
            }

            {showRankingTables && <div style={{ marginTop: '1.5em' }} />}

            {showRankingTables &&
              <Card>
                <CardHeader
                  title="Driver Rankings"
                  subtitle={`Click to expand and view rankings for Driver ${viewTypeString}`}
                  actAsExpander={true}
                  showExpandableButton={true}
                />
                <CardText expandable={true}>
                  <div>
                    <ListSelectField
                      onChange={(e, index, rankingTypeString) => this.handleRankingTypeChange(rankingTypeString)}
                      floatingLabelText="Driver Ranking By"
                      value={rankingTypeList[rankingType]}
                      maxHeight={200}
                      list={rankingTypeList}
                      disabled={selectedDrivers.length === 0}
                      style={{ display: 'inline-block', top: '-1em', width: '15em', textAlign: 'left' }}
                    />
                  </div>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Donec mattis pretium massa. Aliquam erat volutpat. Nulla facilisi.
                  Donec vulputate interdum sollicitudin. Nunc lacinia auctor quam sed pellentesque.
                  Aliquam dui mauris, mattis quis lacus id, pellentesque lobortis odio.
                </CardText>
              </Card>
            }

            <div style={{ marginTop: '1.5em' }} />

            <Card>
              <CardHeader title={`${viewTypeString} Data Breakdown`} />

              <CardText>
                {speedIdlingTables}
              </CardText>
            </Card>
          </div>
        }

      </MDBContainer>
    );
  }
}

SpeedingPatternsLayout.propTypes = {
  drivers: PropTypes.array,
  location: PropTypes.object.isRequired,
};

export default SpeedingPatternsLayout;
