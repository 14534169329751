import React from 'react';

import { OverlayPanel as PROverlayPanel } from 'primereact/overlaypanel';

import './style.scss';

/**
 * @description PrimeReact OverlayPanel component
 * @param {*} ref - Reference used to access the overlay panel - may need to use innerRef so that the reference can be used
 * @param {*} innerRef - Regular prop that contains a reference to pass to the OverlayPanel
 * @param {String} className - Custom className
 * @param {String} sbVariant - Make the OverlayPannel compact (no padding)
 * @returns JSX layout
 */
function OverlayPanel({ ...props }) {
  let className = 'sbc-overlaypanel';
  if (props.className) className += ` ${props.className}`;

  if (props.sbVariant === 'compact') className += ' sb-overlaypanel-compact';

  let ref = props.ref;
  if (props.innerRef) {
    ref = props.innerRef;
  }

  return (<PROverlayPanel ref={ref} {...props} className={className} />);
}

export default OverlayPanel;
