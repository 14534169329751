import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';

// API
import { getCurrentUser, getAttribute } from 'sb-csapi/dist/AAPI';
import { isSubscribedToModule } from 'api/Helpers';

// Components
import JobsList from 'components/Dispatch/JobsList/JobsList';
import DispatchingLayout from 'layouts/Dispatch/DispatchingLayout/DispatchingLayout';
import AccountingLayout from 'layouts/Dispatch/AccountingLayout/AccountingLayout';

import OrganizationsTable from 'components/Dispatch/OrganizationsTable/OrganizationsTable';
// import DispatchInvoiceList from 'components/Dispatch/DispatchInvoiceList/DispatchInvoiceList';
import Sidebar from './Sidebar';

import TabMenu from 'sbCore/TabMenu/TabMenu';

// CSS
import './style.scss';

class DispatchLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      views: {
        ORDERS_LIST: 'orders',
        DISPATCH: 'dispatching',
        // ORGANIZATIONS_LIST: 'organizations_list',
        // ORGANIZATION: 'organization',
        // DRIVERS_LIST: 'drivers',
        INVOICES: 'invoices',
        COMPANY_SETTINGS: 'company_settings',
        ACCOUNTING: 'accounting',
        // LOAD_TENDER: 'load_tender',
        // SCHEDULER: 'scheduler',
        // LANEPRICE: 'lanes',
        // CARRIERS: 'carrier',
        // CUSTOMER_LIST: 'customer',
        // CONSIGNEES_LIST: 'consignee',
        // BROKERS_LIST: 'broker',
        // SHIPPER_LIST: 'shipper',
      },
      Subsection: Object.freeze({
        ORGANIZATIONS_LIST: {
          name: 'Organizations',
          path: 'organizations',
          index: 1,
        },
      }),
      searchIsOpen: false,
      subsectionIndex: 0,
    };
    // this.dispatchModuleTitles keys must match above ^ state.views right hand side values.
    this.dispatchModuleTitles = {
      orders: 'Dispatch Orders',
      dispatching: 'Dispatching',
      organizations: 'Dispatch Organizations',
      drivers: 'Dispatch Drivers',
      invoices: 'Dispatch Invoices',
      accounting: 'Dispatch Accounting',
      // load_tender: 'Dispatch Load Tenders',
      // scheduler: 'Dispatch Scheduler',
      // lanes: 'Dispatch Lanes',
      // carrier: 'Dispatch Carriers',
      // customer: 'Dispatch Customers',
      // consignee: 'Dispatch Consignees',
      // broker: 'Dispatch Brokers',
      // shipper: 'Dispatch Shippers',
    };

    this.setSubsectionIndex = this.setSubsectionIndex.bind(this);
  }

  componentDidMount() {
    document.title = 'Dispatch - Switchboard';
    if (!isSubscribedToModule('dispatchModule')) {
      return history.push('/404');
    }

    // let companyObj = this.props.Company.company;
    // getDispatchCompanySettings(companyObj).then((dispatchCompanyObj) => {
    //   if (!dispatchCompanyObj) {
    //     addNewDispatchCompanySettings(companyObj);
    //   }
    // })

    const currentUser = getCurrentUser();
    const userSpecialPermission = getAttribute(currentUser, 'userSpecialPermission');
    if (userSpecialPermission && getAttribute(userSpecialPermission, 'disableDispatchModule')) {
      history.push('/404');
    }
  }

  setSubsectionIndex(subsectionIndex) {
    this.setState({ subsectionIndex });
  }

  render() {
    const { props, state } = this;

    // Navigation
    let currentView = state.views.ORDERS_LIST;
    const locationPathname = props.location.pathname;
    if (locationPathname && locationPathname.split('/')[2]) {
      // ex. '/dispatch/accounting' -> 'accounting'
      currentView = locationPathname.split('/')[2];
    }

    const navItems = [
      {
        name: 'Orders List',
        isActive: !currentView || (currentView === state.views.ORDERS_LIST),
        to: `/dispatch/${state.views.ORDERS_LIST}`,
      },
      {
        name: 'Dispatch',
        isActive: !currentView || (currentView === state.views.DISPATCH),
        to: `/dispatch/${state.views.DISPATCH}`,
      },
      // {
      //   name: 'Organizations',
      //   isActive: !currentView || (currentView === state.views.ORGANIZATIONS_LIST),
      //   to: `?view=${state.views.ORGANIZATIONS_LIST}`,
      // },
      // {
      //   name: 'Drivers',
      //   isActive: (currentView === state.views.DRIVERS_LIST),
      //   to: `?view=${state.views.DRIVERS_LIST}`,
      // },
      {
        name: 'Accounting',
        isActive: (currentView === state.views.ACCOUNTING),
        to: `/dispatch/${state.views.ACCOUNTING}`,
        // isDropdown: true,
        // dropdownItems: [
        //   {
        //     name: 'Invoices',
        //     isActive: (currentView === state.views.INVOICES),
        //     to: `?view=${state.views.INVOICES}`,
        //   }
        // ]
      },
      // {
      //   name: 'Company Settings',
      //   isActive: (currentView === state.views.COMPANY_SETTINGS),
      //   to: `?view=${state.views.COMPANY_SETTINGS}`,
      // },
      // {
      //   name: 'Load Tenders',
      //   isActive: (currentView === views.LOAD_TENDER),
      //   to: `?view=${views.LOAD_TENDER}`,
      // },
      // {
      //   name: 'Scheduler',
      //   isActive: (currentView === views.SCHEDULER),
      //   to: `?view=${views.SCHEDULER}`,
      // },
      // {
      //   name: "Lanes/Prices",
      //   isActive: (currentView === views.LANEPRICE),
      //   to: `?view=${views.LANEPRICE}`
      // },
      // {
      //   name: 'Business Lists',
      //   isDropdown: true,
      //   dropdownItems: [
      //     {
      //       name: 'Carriers',
      //       isActive: (currentView === views.CARRIERS),
      //       to: `?view=${views.CARRIERS}`,
      //     },
      //     {
      //       name: 'Customers',
      //       isActive: (currentView === views.CUSTOMER_LIST),
      //       to: `?view=${views.CUSTOMER_LIST}`,
      //     },
      //     {
      //       name: 'Consignees',
      //       isActive: (currentView === views.CONSIGNEES_LIST),
      //       to: `?view=${views.CONSIGNEES_LIST}`,
      //     },
      //     {
      //       name: 'Custom Brokers',
      //       isActive: (currentView === views.BROKERS_LIST),
      //       to: `?view=${views.BROKERS_LIST}`,
      //     },
      //     {
      //       name: 'Shippers',
      //       isActive: (currentView === views.SHIPPER_LIST),
      //       to: `?view=${views.SHIPPER_LIST}`,
      //     },
      //   ],
      // },
    ];

    // @todo - The TabMenu is put here instead of its own layout because uncertainty atm how we want to
    //         organize the different views with Dispatching and Accounting
    const model = [
      { label: 'Jobs List', icon: 'pi pi-list' },
      { label: 'Organizations', icon: 'pi pi-sitemap' },
    ];

    document.title = `${this.dispatchModuleTitles[currentView] || 'Dispatch'} - Switchboard`;

    return (
      <div className="grid grid-nogutter dispatch-layout">
        <div className="col-fixed dispatch-sidebar">
          <Sidebar
            navTitle="Dispatch"
            navItems={navItems}
            openSearch={() => this.setState({ searchIsOpen: true })}
          />
        </div>
        {/* <DispatchAlgolia searchIsOpen={state.searchIsOpen} toggle={(bool) => this.setState({ searchIsOpen: bool })} /> */}
        <div className="col p-3 dispatch-content">

          {(!currentView || ([state.views.ORDERS_LIST].indexOf(currentView) !== -1)) && (
            <div className="mb-3">
              <TabMenu model={model} activeIndex={state.subsectionIndex} onTabChange={(e) => this.setSubsectionIndex(e.index)} />
            </div>
          )}

          {(currentView === state.views.ORDERS_LIST) && (
            <div>
              {(state.subsectionIndex === 0) && (
                <JobsList location={props.location} />
              )}
              {(state.subsectionIndex === state.Subsection.ORGANIZATIONS_LIST.index) && (
                <OrganizationsTable
                  scrollHeight="60vh"
                />
              )}
            </div>
          )}

          {(currentView === state.views.DISPATCH) && (
            <DispatchingLayout />
          )}

          {(currentView === state.views.ORGANIZATIONS_LIST) && (
            <div />
          )}

          {(currentView === state.views.ORGANIZATION) && (
            <div />
          )}

          {(currentView === state.views.DRIVERS_LIST) && (
            <div />
          )}

          {(currentView === state.views.ACCOUNTING) && (
            <AccountingLayout />
          )}

          {(currentView === state.views.COMPANY_SETTINGS) && (
            <div />
          )}

          {/* {(currentView === views.LOAD_TENDER) && (
            <LoadTendersList />
          )}

          {(currentView === views.SCHEDULER) && (
            <DispatchScheduler />
          )}

          {(currentView === views.LANEPRICE) && (
            <LanesPricesList />
          )}

          {(currentView === views.CARRIERS || currentView === views.CUSTOMER_LIST || currentView === views.SHIPPER_LIST || currentView === views.CONSIGNEES_LIST || currentView === views.BROKERS_LIST) && (
            <DispatchBusinessList
              location={location}
            />
          )} */}
        </div>
      </div>
    );
  }
}

DispatchLayout.propTypes = {
  location: PropTypes.object.isRequired,
};

export default DispatchLayout;
