import React from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Glyphicon } from 'react-bootstrap'
import { Line } from 'react-chartjs-2'
import { MDBRow, MDBCol } from 'mdbreact'

// Actions

// API
// import { trailerTypes } from 'api/Lists/EquipmentTypes';
// import * as Getters from 'api/Getters';
// import * as Helpers from 'api/Helpers';

// Components


// CSS
// import styles from './TrailerTemperatureLayout.module.scss'

class TrailerTemperatureChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    };
  }

  render() {
    const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    const isMobile = (width > 768 ? false : true);
    const tempUnit = this.props.tempUnit;

    const defaultYMax = this.state.tempUnit === 'c' ? 50 : 120;
    const defaultYMin = this.state.tempUnit === 'c' ? -25 : -20;
    let dataYMax = null;
    let dataYMin = null;
    for (let i = 0; i < this.props.temperatureArr.length; i++) {
      const tempInt = this.props.temperatureArr[i];
      if (!dataYMax || tempInt > dataYMax) dataYMax = tempInt;
      if (!dataYMin || tempInt < dataYMin) dataYMin = tempInt;
    }

    const yMax = dataYMax ? Math.ceil((dataYMax + 10) / 10) * 10 : defaultYMax;
    const yMin = dataYMin ? Math.floor((dataYMin - 10) / 10) * 10 : defaultYMin;

    const cjsData = {
      // Amalgamation of our datasets and label settings to input to CJs Line Graph
      datasets: [
        {
          // Our custom ChartJS dataset properties to be used for all datasets for line-drawing
          label: '',
          fill: false,
          lineTension: 0.4, // for line curving
          // backgroundColor: props.defaultLineColor || '#0DAAFF', // the colors are set in the graph extension
          // borderColor: props.defaultLineColor || '#0DAAFF',
          cubicInterpolationMode: 'default',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'bevel', // default 'miter'
          borderColor: '#0DAAFF',
          backgroundColor: '#0DAAFF',
          pointRadius: 0,
          pointHitRadius: [],
          pointHoverRadius: [],
          pointBorderColor: [],
          pointBackgroundColor: [],
          data: this.props.dateTemperatureArr,
          // data: this.props.temperatureArr,
        },
      ],
    };


    const cjsOptions = {
      responsive: isMobile ? false : true,
      maintainAspectRatio: false,
      elements: {
        // point: {
        //   pointStyle: 'circle',
        //   radius: 0, // turns the points off. default 3
        //   hitRadius: 0, // 1
        //   hoverRadius: 0 // 4
        // }
      },
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'hour'
          }
        },
        xAxes: [
          {
            type: 'time',
            bounds: 'ticks',
            // source: 'auto',
            time: {
              unit: 'hour',
              displayFormats: {
                hour: 'H:mm',
              },
              stepSize: 1,
              // unitStepSize: 1,
            },
            ticks: {
              min: this.props.dateArr && this.props.dateArr.length > 0 ? moment(this.props.dateArr[0]).startOf('day').utc() : moment().startOf('day').utc(),
              max: this.props.dateArr && this.props.dateArr.length > 0 ? moment(this.props.dateArr[0]).endOf('day').add(1, 'second').utc() : moment().endOf('day').add(1, 'second').utc(),
              source: 'auto',
              fontColor: '#000000',
              fontSize: 12,
              fontFamily: 'Tahoma',
              fontStyle: 'bold',
            },
          },
        ],

        yAxes: [
          {
            position: 'left',
            ticks: {
              fixedStepSize: 10,
              min: yMin,
              max: yMax,
              // Return an empty string to draw the tick line but hide the tick label
              // Return `null` or `undefined` to hide the tick line entirely
              fontColor: '#337AB7',
              fontSize: 10,
              fontFamily: 'Tahoma',
              fontStyle: 'bold'
            },
          },
        ],
      },
      legend: {
        display: false // don't display chart legend labels
      },
      // hover: {
      //   mode: 'nearest',
      //   intersect: true,
      // },
      tooltips: {
        enabled: true,
        intersect: true,
        mode: 'x-axis',
        displayColors: false,
        callbacks: {
          // title(chartElementDataset) {
          //   // renames the title of the tooltip (which would normally display the X-Axis value)
          //   // but in our case, because the chart x-axis time value scales to the nearest hour and we adjust for it,
          //   // we need to display the adjusted value
          //   if (chartElementDataset.length === 0) return;

          //   const chartElementIndex =
          //     chartElementDataset[chartElementDataset.length - 1].index;
          //   const associatedGraphCoord = graphData[chartElementIndex];
          //   // recall we adjusted for charttimeoffset previous, so we need to cancel it out
          //   const eventDateTime = moment(associatedGraphCoord.x)
          //     .tz(timezoneOffsetFromUTC)
          //     .add(chartTimeOffset, 'millisecond')
          //   const xToolTipLabel = eventDateTime.format(
          //     'h:mm A  (dddd DD MMM, YYYY)'
          //   );
          //   return xToolTipLabel;
          // },
          label(chartElement, data) {
            return `Temperature (°${tempUnit.toUpperCase()}): ${chartElement.yLabel}`;
          }
        }
      }
    };

    // // declaring the tick fn here will ensure the graph redraws the x-axis with our changes as well as use the correct startTimeUTC
    // cjsOptions.scales.xAxes[0].ticks.callback = (tick, index, ticks) => {
    //   /*
    //     Each tick is a time value string (ex. '11:30 pm'); ticks is an array of objects { value: UNIX, major: true/false } of the selected date set to said time value
    //     A tick is what is shown on the xAxis

    //     if amount of ticks is 25, we know we're showing a full 24hr span. If its 26, we know the graph
    //     did some rounding (rounds to nearest whole hr:00) so we have to make sure the correct hour:minute is set
    //   */
    //   const tickSplit = moment.unix(tick)
    //     // .tz(timezoneOffsetFromUTC)
    //     .format('H:mm')
    //     .split(':')
    //   let hour = tickSplit[0]
    //   if (hour.length < 2) {
    //     hour = '0' + hour;
    //   }
    //   let minutes = tickSplit[1].substr(0, 2)

    //   if (ticks.length === 26) {
    //     minutes = startTimeUTC.minutes()
    //     minutes < 10 ? (minutes = `0${minutes}`) : minutes

    //     // don't draw the last rounded tick which is indicated by ticks.length = 26 && index = 25
    //     if (index === 25) return
    //   }
    //   return `${hour}:${minutes}`
    // }

    return (
      <MDBRow className="subTable">
        <MDBCol>
          <Line
            data={cjsData}
            width={800}
            height={200}
            options={cjsOptions}
            ref={chart => {
              // this.setChartRef(chart)
            }}
            redraw
          />
        </MDBCol>
      </MDBRow>
    );
  }
}

export default TrailerTemperatureChart;
