import moment from 'moment';
import React, { useEffect, useState } from 'react';

// SBCore
import Calendar from 'sbCore/Calendar/Calendar';
import InputLabel from 'sbCore/InputLabel/InputLabel';

// Styles
import './style.scss';

/**
 * A component for selecting a date range. This allows the user to select the start/end date (date, month, or year).
 *
 * When the start date is selected, it sets its value to be at the start of the date, month, or year (depending on the view type).
 * Similarly, when the end date is selected, it sets its value to be at the end of the date, month, or year (depending on the view type).
 *
 * For example (assuming the selected date is January 01, 2024):
 * - viewType => 'month': Start date will be January 01, 2024, and end date will be January 31, 2024
 * - viewType => 'year': Start date will be January 01, 2024, and end date will be December 31, 2024
 * - viewType => 'date': Start date will be January 01, 2024 @ 00:00:00, and end date will be January 01, 2024 @ 23:59:59
 *
 * @param {String} [className] - Custom container className
 * @param {String} [viewType = 'month'] - The type of calendar view to use. Available options are: 'month', 'year', 'date'
 * @param {String} [customDateFormat] - The date format to use for the input field. Defaults to "MM yy"
 * @param {bool} [allowSelectingFutureDates] - Whether to allow selecting future dates
 * @param {String} [startDateLabel] - A custom label for the start date input
 * @param {String} [endDateLabel] - A custom label for the end date input
 * @param {String} [startDatePlaceholder] - A custom placeholder for the start date input
 * @param {String} [endDatePlaceholder] - A custom placeholder for the end date input
 * @param {Date} [initialStartDate] - The default start date to use as the initial value
 * @param {Date} [initialEndDate] - The default end date to use as the initial value
 * @param {Function} [onStartDateSelected] - A callback function that returns the selected start date
 * @param {Function} [onEndDateSelected] - A callback function that returns the selected end date
 *
 * @returns {JSX.Element}
 */
function DateRangeSelector({
  className,
  viewType = 'month',
  customDateFormat,
  allowSelectingFutureDates,
  startDateLabel,
  endDateLabel,
  startDatePlaceholder,
  endDatePlaceholder,
  initialStartDate,
  initialEndDate,
  onStartDateSelected,
  onEndDateSelected,
  ...props
}) {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  useEffect(() => {
    // Set the end date to the start date if it is after the current date
    if (moment(startDate).isAfter(endDate)) _onEndDateSelected(startDate);
  }, [startDate]);

  /**
   * When the end date is selected, determine the proper date based on the view type.
   * If the view type is 'year', set the end date to the end of the year.
   * If the view type is 'month', set the end date to the end of the month.
   * Otherwise, set the end date to the end of the day.
   *
   * @param {String} value - The selected date in ISO format
   */
  function _onEndDateSelected(value) {
    let date = moment(value);

    if (viewType === 'month') {
      date = date.endOf('month');
    } else if (viewType === 'year') {
      date = date.endOf('year');
    } else {
      date = date.endOf('day');
    }

    setEndDate(date.toDate());
    onEndDateSelected && onEndDateSelected(date.toDate());
  }

  // Get the default date format based on the view type. This is used to display the date in the input field.
  function _getDefaultDateFormat() {
    if (viewType === 'month') {
      return 'MM yy';
    } else if (viewType === 'year') {
      return 'yy';
    } else {
      return 'mm-dd-yy';
    }
  }

  const _className = `date-range-selector flex align-items-center ${className ?? ''}`;
  const _viewTypeLabel = `${viewType.charAt(0).toUpperCase()}${viewType.slice(1)}`;

  return (
    <div className={_className}>
      <div>
        <InputLabel>{startDateLabel ?? `Start ${_viewTypeLabel}`}</InputLabel>
        <Calendar
          value={startDate}
          view={viewType}
          dateFormat={customDateFormat ?? _getDefaultDateFormat()}
          maxDate={!allowSelectingFutureDates && moment().toDate()}
          onSelect={({ _, value }) => {
            setStartDate(moment(value).toDate());
            onStartDateSelected && onStartDateSelected(value);
          }}
          placeholder={startDatePlaceholder}
        />
      </div>

      <div className="ml-2">
        <InputLabel>{endDateLabel ?? `End ${_viewTypeLabel}`}</InputLabel>
        <Calendar
          value={endDate}
          minDate={startDate}
          view={viewType}
          dateFormat={customDateFormat ?? _getDefaultDateFormat()}
          maxDate={!allowSelectingFutureDates && moment().toDate()}
          onSelect={({ _, value }) => _onEndDateSelected(value)}
          placeholder={endDatePlaceholder}
        />
      </div>
    </div>
  );
}

export default DateRangeSelector;
