import * as ActionConstants from './ActionConstants';

export function fetchingDispatcher() {
  return {
    type: ActionConstants.FETCH_DISPATCHER_INPROGRESS,
  };
}

export function fetchDispatcherSuccess(dispatcher) {
  return {
    type: ActionConstants.FETCH_DISPATCHER_SUCCESS,
    dispatcher,
  };
}

export function fetchDispatcherError(error) {
  return {
    type: ActionConstants.FETCH_DISPATCHER_ERROR,
    error,
  };
}
