/** @module DispatchQuote */

// sb-csapi
import {
  createQuery,
  findRecords,
  getAttribute,
  getCurrentUserSessionToken,
  setQueryRestriction,
  updateRecord,
  addRecord,
  getCurrentUser,
} from 'sb-csapi/dist/AAPI';

// Query
import { QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';

// enums
import { Currency } from 'sb-csapi/dist/enums/Finance/Currency';

const DISPATCH_QUOTE_TABLE = 'DispatchQuote';
const DISPATCHER_TABLE = 'Dispatcher';

/**
 * @memberof module:DispatchQuote
 *
 * @description Fetch DispatchQuote record with given dispatch job
 *
 * @param {DispatchJob} dispatchJob - DispatchJob record
 *
 * @returns {DispatchQuote} A DispatchQuote record related to the given dispatch job
 */
async function getDispatchQuote(dispatchJob) {
  const dispatchQuoteQuery = createQuery(DISPATCH_QUOTE_TABLE);
  setQueryRestriction(dispatchQuoteQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchJob', dispatchJob);

  try {
    return await findRecords({ sessionToken: getCurrentUserSessionToken() }, dispatchQuoteQuery, true, false);
  } catch (err) {
    throw new Error(err);
  }
}

async function getDispatchQuoteByObjectId(dispatchQuoteObjectId) {
  const dispatchQuoteQuery = createQuery(DISPATCH_QUOTE_TABLE);
  setQueryRestriction(dispatchQuoteQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', dispatchQuoteObjectId);

  try {
    return await findRecords({ sessionToken: getCurrentUserSessionToken() }, dispatchQuoteQuery, true, false);
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchQuote
 *
 * @description Create a new DispatchQuote record
 *
 * @param {DispatchJob} dispatchJob - DispatchJob record
 *
 * @returns {DispatchQuote} A new DispatchQuote record
 */
async function createDispatchQuote(dispatchJob) {
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

  // Find the dispatcher to get currency setting
  const dispatcherQuery = createQuery(DISPATCHER_TABLE);
  setQueryRestriction(dispatcherQuery, QueryRestrictionTypes.EQUAL_TO, 'user', currentUser);

  try {
    const dispatcher = await findRecords(
      { sessionToken: getCurrentUserSessionToken() },
      dispatcherQuery,
      true, //  return first result
      false, // find all
    );

    // Default dispatch quote values
    let dispatchQuote = {
      tax: 10,
      unit: getAttribute(dispatcher, 'currency') || Currency.CA.short,
      dispatchJob,
      amount: 0,
    };

    dispatchQuote = await addRecord(
      { sessionToken: getCurrentUserSessionToken() },
      DISPATCH_QUOTE_TABLE,
      dispatchQuote,
      getAttribute(belongsToCompany, 'objectId'),
    );

    return dispatchQuote;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchQuote
 *
 * @description Update a DisaptchQuote record with given key value obj
 *
 * @param {string} dispatchQuoteObjectId - DispatchQuote objectId
 * @param {object} keyValueObj - The key value object we wish to update
 * @param {bool} save - Whether to save the changes to db or not
 *
 * @returns {DispatchQuote} The updated DispatchQuote record
 */
async function updateDispatchQuote(dispatchQuoteObjectId, keyValueObj, save) {
  // Look for dispatch quote with given dispatchQuote objectId
  const dispatchQuoteQuery = createQuery(DISPATCH_QUOTE_TABLE);
  setQueryRestriction(dispatchQuoteQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', dispatchQuoteObjectId);

  try {
    const dispatchQuote = await findRecords(
      { sessionToken: getCurrentUserSessionToken() },
      dispatchQuoteQuery,
      true,
      false,
    );

    if (!dispatchQuote) {
      throw new Error(`Error [api/DispatchQuote updateDispatchQuote]: No DispatchQuote with objectId ${dispatchQuoteObjectId}`);
    }

    return await updateRecord(
      { sessionToken: getCurrentUserSessionToken() },
      dispatchQuote,
      keyValueObj,
      save,
    );
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getDispatchQuote,
  getDispatchQuoteByObjectId,
  createDispatchQuote,
  updateDispatchQuote, // update dispatch quote if exists otherwise create one
};
