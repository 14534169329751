import React from 'react';
import PropTypes from 'prop-types';

// Components
import RegisterForm from '../view/RegisterForm';

// CSS
import styles from './FleetRegisterScreen.module.scss';

// Assets
const logo = require('assets/images/marketing/centurion-logo.png');
const profilePic = require('assets/images/marketing/centurion-profilepic.png');

function FleetRegisterScreen(props) {
  return (
    <div>
      <div>
        <div className={styles.intro}>
          <div className={styles.back} onClick={props.handleBack}>
            <i className="material-icons">chevron_left</i>
            <div>Back</div>
          </div>
          Try Switchboard for free today
          <br />
          <span>Explore our ELD cloud platform and what it can do for your fleet.</span>
          {/* <span>Already have a Switchboard account? <a href={'/login'}>Sign in.</a></span> */}
        </div>
      </div>
      <table className={styles.getUserDetailsTable}><tr>
        <td>
          <div className={styles.registerForm}>
            <div className={styles.userTypeSelect}>
              <div className={styles.choicePrompt}>I'm a:</div>
              <table><tr>
                <td>
                  <div className={`${styles.choice} ${styles.activeChoice}`}>
                    <div className={styles.icon}>
                      <i className="material-icons">check</i>
                    </div>
                    <div className={styles.title} >
                      <b>Fleet or Safety Manager</b>
                      {/* <br />
                      <span>Two or more vehicles</span> */}
                    </div>
                  </div>
                </td>
                <td className={`hide-mobile`}>
                  <div className={`hide-mobile ${styles.choice}`} onClick={() => props.handleUserTypeChange('oo')}>
                    <div className={styles.icon}>
                      <i className="material-icons">check</i>
                    </div>
                    <div className={styles.title} >
                      Owner Operator
                      {/* <br />
                      <span>Two or more vehicles</span> */}
                    </div>
                  </div>
                </td>
              </tr></table>
            </div>
            <RegisterForm
              {...props}
            />
          </div>
        </td>
        <td className={`hide-mobile`}>
          <div className={`hide-mobile ${styles.endorsement}`}>
            <div className={styles.logo}><img src={logo} /></div>
            <table><tr>
              <td>
                <div className={styles.profilePic}><img src={profilePic} /></div>
              </td>
              <td>
                <div>
                  <div className={styles.quote}>
                    “Switchboard is truly built to what my fleet, dispatch, and safety divisions need. It’s simple to use, and also has all the features required for proper compliance and fleet management.”
                  </div>
                  <br />
                  <div>
                    <b>Daman Grewal</b>
                    <br />
                    <i>Operations Manager</i>
                  </div>
                </div>
              </td>
            </tr></table>
          </div>
        </td>
      </tr></table>
    </div>
  );
}

FleetRegisterScreen.propTypes = {
  handleBack: PropTypes.func.isRequired,
  getValidationState: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  User: PropTypes.object.isRequired,
  registerError: PropTypes.object,
  agreeTerms: PropTypes.bool.isRequired,
  handleAgreeTerms: PropTypes.func.isRequired,
};

export default FleetRegisterScreen;
