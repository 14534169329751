import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { MDBBtn } from 'mdbreact';

// API
import { StateProvinces } from 'api/Lists/StateProvinces';
import * as PDF from 'api/PDFer';
import * as Helpers from 'api/Helpers';
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { getOdometerReadingsFromIFTARoute, fixDetectedIFTARouteIssues, findDayViewIssues, getTimezoneString, getFuelCardData } from 'api/IFTARoute/IFTARoute.old';
import { getAttribute } from 'api/Parse';
// import { isFlagOn } from 'sb-csapi/dist/api/Analytics/Flagging';

// Components
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import VehicleSummaryViewBeta from 'components/IFTARoute/view/VehicleSummaryBeta';

const getFilteredTotalVehicleKmDiff = (iftaRoute) => {
  if (!iftaRoute) return 0;

  const savedVehicleKm = getAttribute(iftaRoute, 'savedVehicleKm') || 0;
  const totalVehicleKmDiff = getAttribute(iftaRoute, 'totalVehicleKmDiff') || 0;

  if (Math.abs(savedVehicleKm - totalVehicleKmDiff) > 1000) return savedVehicleKm;
  return totalVehicleKmDiff;
};

class VehicleSummaryBeta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      printInProgress: false,
      downloadCSVInProgress: false,
      vehicleSummaryFilter: {
        key: 1,
        value: 'hideZeroMileages',
        label: 'Hide Empty States/Provinces',
      },
      distanceUnitFilter: getPDispatcherPropertyFromState('distanceUnit') === 'km'
        ? {
          key: 0,
          value: 'km',
          label: 'km',
        } : {
          key: 1,
          value: 'mi',
          label: 'mi',
        },
      distanceUnitFilterItems: [
        {
          key: 0,
          value: 'km',
          label: 'km',
        },
        {
          key: 1,
          value: 'mi',
          label: 'mi',
        },
      ],
      volumeUnitFilter: getPDispatcherPropertyFromState('volumeUnit') === 'l' ?
        {
          key: 0,
          value: 'l',
          label: 'l',
        }
        :
        {
          key: 1,
          value: 'gal',
          label: 'gal',
        },
      volumeUnitFilterItems: [
        {
          key: 0,
          value: 'l',
          label: 'l',
        },
        {
          key: 1,
          value: 'gal',
          label: 'gal',
        },
      ],
      showOdometerValuesFilter: {
        key: 1,
        value: 'hideOdometerValues',
        label: 'Hide Odometer Values',
      },
      showOdometerValuesFilterItems: [
        {
          key: 0,
          value: 'showOdometerValues',
          label: 'Show Odometer Values',
        },
        {
          key: 1,
          value: 'hideOdometerValues',
          label: 'Hide Odometer Values',
        },
      ],
      selectableVehicleSummaryFilterItems: [
        {
          key: 0,
          value: 'showAllStateProvinces',
          label: 'Show All States/Provinces',
        },
        {
          key: 1,
          value: 'hideZeroMileages',
          label: 'Hide Empty States/Provinces',
        },
      ],
      odometerReadings: {
        odometerStartKm: undefined,
        odometerEndKm: undefined,
        odometerDiffKm: undefined,
      },
      isLoading: true,
      iftaRouteDayIssuesArr: [],
    };

    this.printReport = this.printReport.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.getOdometerReadings = this.getOdometerReadings.bind(this);
    this.generateIFTARouteDayIssuesArr = this.generateIFTARouteDayIssuesArr.bind(this);
  }

  async componentDidMount() {
    const { props } = this;
    // const isAutofixIFTARoutesFlagOn = isFlagOn('flag-web-2448-autofix-ifta-routes');
    const isAutofixIFTARoutesFlagOn = true;

    if (isAutofixIFTARoutesFlagOn) {
      const changedIFTARoutes = await fixDetectedIFTARouteIssues(props.iftaRouteArr);
      if (changedIFTARoutes?.length > 0) return props.onRefresh();
    }

    await this.getOdometerReadings();
    this.generateIFTARouteDayIssuesArr();
  }

  async componentDidUpdate(prevProps) {
    const { props } = this;
    if (prevProps.iftaRouteArr !== props.iftaRouteArr) {
      // const isAutofixIFTARoutesFlagOn = isFlagOn('flag-web-2448-autofix-ifta-routes');
      const isAutofixIFTARoutesFlagOn = true;

      if (isAutofixIFTARoutesFlagOn) {
        const changedIFTARoutes = await fixDetectedIFTARouteIssues(props.iftaRouteArr);
        if (changedIFTARoutes?.length > 0) return props.onRefresh();
      }

      await this.getOdometerReadings();
      this.generateIFTARouteDayIssuesArr();
    }
  }

  async getOdometerReadings() {
    const { props } = this;
    const odometerReadings = await getOdometerReadingsFromIFTARoute(props.unitId, props.iftaRouteArr, props.dateStart, props.dateEnd);
    this.setState({ ...this.state, odometerReadings, isLoading: false });
  }

  printReport() {
    const { props } = this;
    this.setState({ ...this.state, printInProgress: true });
    const reportName = `${props.unitId} - Vehicle Mileage Summary`;

    // remove all warning messages from all children (includes child components) belonging to printable
    const printableHTML = document.querySelector('.vehicleSummary-printable');
    const sbBlocks = printableHTML.querySelectorAll('.vehicleSummary-printable .sb-block');
    for (let i = 0; i < sbBlocks.length; i++) {
      sbBlocks[i].style.display = 'none';
    }

    PDF.generateTempPDFFromHTML(printableHTML, reportName, undefined).then(
      tempFile => {
        const reportURL = tempFile.get('file')._url;
        Helpers.openDocumentLink(reportURL);
        for (let i = 0; i < sbBlocks.length; i++) {
          sbBlocks[i].style.display = 'block';
        }
        this.setState({ ...this.state, printInProgress: false });
      },
    );
  }

  async downloadCSV() {
    const { state, props } = this;
    await this.setState({ ...this.state, downloadCSVInProgress: true });

    const iftaFuelCardDataHash = await getFuelCardData(props.iftaRouteArr.map((iftaRoute) => getAttribute(iftaRoute, 'objectId')), props.routeToNewIFTA);
    let csvString = `State/Province,Fuel Purchases (Gallons),Fuel Card Transactions (Gallons),Calculated Mileage (${state.distanceUnitFilter.value})\n`;
    const stateProvinceCodes = StateProvinces.map((stateProvince) => stateProvince.code).filter((stateProvinceCode) => stateProvinceCode);

    const stateProvinceDataArr = [];
    const iftaArrMiles = {};

    const totalFuelPurchases = {};
    let totalFuelTotalPaidCAD = 0;
    let totalFuelTotalPaidUSD = 0;

    let totalDistanceKm = 0;
    let totalSavedVehicleKm = 0;

    const totalFuelCardData = {};
    let totalFuelCardPaidCAD = 0;
    let totalFuelCardPaidUSD = 0;

    // Categorize the iftaRoutes into state/provinces and combines the following information
    // distanceKm, savedVehicleKm, totalVehicleKmDiff
    for (let i = 0; i < props.iftaRouteArr.length; i++) {
      const iftaRoute = props.iftaRouteArr[i];
      const stateProvince = getAttribute(iftaRoute, 'stateProvince') && getAttribute(iftaRoute, 'stateProvince').toUpperCase();
      const distanceKm = getAttribute(iftaRoute, 'distanceKm') || 0;
      const totalVehicleKmDiff = getFilteredTotalVehicleKmDiff(iftaRoute);
      const savedVehicleKm = getAttribute(iftaRoute, 'savedVehicleKm') || 0;
      const fuelPurchases = getAttribute(iftaRoute, 'fuelPurchases');

      totalSavedVehicleKm += savedVehicleKm;
      totalDistanceKm += distanceKm;

      if (iftaArrMiles[stateProvince]) {
        iftaArrMiles[stateProvince].distanceKm += distanceKm;
        iftaArrMiles[stateProvince].totalVehicleKmDiff += totalVehicleKmDiff;
        iftaArrMiles[stateProvince].savedVehicleKm += savedVehicleKm;
      } else {
        iftaArrMiles[stateProvince] = {};
        iftaArrMiles[stateProvince].distanceKm = distanceKm;
        iftaArrMiles[stateProvince].totalVehicleKmDiff = totalVehicleKmDiff;
        iftaArrMiles[stateProvince].savedVehicleKm = savedVehicleKm;
        iftaArrMiles[stateProvince].fuelPurchaseSubtotals = {};
        iftaArrMiles[stateProvince].fuelCardSubtotals = {};
        iftaArrMiles[stateProvince].fuelTotalPaidCAD = 0;
        iftaArrMiles[stateProvince].fuelTotalPaidUSD = 0;
        iftaArrMiles[stateProvince].fuelCardTotalPaidCAD = 0;
        iftaArrMiles[stateProvince].fuelCardTotalPaidUSD = 0;
      }

      // Fuel Purchases
      if (fuelPurchases) {
        for (let j = 0; j < fuelPurchases.length; j++) {
          const fuelPurchase = fuelPurchases[j];
          const fuelType = getAttribute(fuelPurchase, 'fuelType') && getAttribute(fuelPurchase, 'fuelType').toUpperCase();
          const totalPaid = getAttribute(fuelPurchase, 'totalPaid');
          const currency = getAttribute(fuelPurchase, 'currency');

          const volumeUnits = getAttribute(fuelPurchase, 'volumeUnits');
          const volumePumped = getAttribute(fuelPurchase, 'volumePumped');

          const volumePumpedGallons = (volumeUnits === 'l') ? Helpers.convertFuelUnit(volumePumped, 'l', 'gal') : volumePumped;
          console.log(iftaArrMiles[stateProvince]);

          if (iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType]) {
            iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType] += volumePumpedGallons;
          } else {
            iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType] = volumePumpedGallons;
          }

          if (totalFuelPurchases[fuelType]) {
            totalFuelPurchases[fuelType] += volumePumpedGallons;
          } else {
            totalFuelPurchases[fuelType] = volumePumpedGallons;
          }

          if (currency === 'cad') {
            iftaArrMiles[stateProvince].fuelTotalPaidCAD += totalPaid;
            totalFuelTotalPaidCAD += totalPaid;
          } else if (currency === 'usd') {
            iftaArrMiles[stateProvince].fuelTotalPaidUSD += totalPaid;
            totalFuelTotalPaidUSD += totalPaid;
          }
        }
      }

      console.log(iftaArrMiles);
      // Fuel Card
      if (iftaFuelCardDataHash && iftaFuelCardDataHash[getAttribute(iftaRoute, 'objectId')]) {
        const fuelCardDataArr = iftaFuelCardDataHash[getAttribute(iftaRoute, 'objectId')];

        for (let j = 0; j < fuelCardDataArr.length; j++) {
          const fuelCardData = fuelCardDataArr[j];

          const fuelQuantity = getAttribute(fuelCardData, 'fuelQuantity');
          const currency = getAttribute(fuelCardData, 'currency');
          const fuelMeasurementUnit = getAttribute(fuelCardData, 'fuelMeasurementUnit');
          const total = getAttribute(fuelCardData, 'total');
          const item = getAttribute(fuelCardData, 'item');
          const stateProvinceAbbrv = getAttribute(fuelCardData, 'stateProvinceAbbrv')?.toUpperCase();

          if (iftaArrMiles[stateProvinceAbbrv].fuelCardSubtotals[item]) {
            iftaArrMiles[stateProvinceAbbrv].fuelCardSubtotals[item] += (fuelMeasurementUnit === 'l') ? Helpers.convertFuelUnit(fuelQuantity, 'l', 'gal') : fuelQuantity;
          } else {
            iftaArrMiles[stateProvinceAbbrv].fuelCardSubtotals[item] = (fuelMeasurementUnit === 'l') ? Helpers.convertFuelUnit(fuelQuantity, 'l', 'gal') : fuelQuantity;;
          }

          if (totalFuelCardData[item]) {
            totalFuelCardData[item] += (fuelMeasurementUnit === 'l') ? Helpers.convertFuelUnit(fuelQuantity, 'l', 'gal') : fuelQuantity;;
          } else {
            totalFuelCardData[item] = (fuelMeasurementUnit === 'l') ? Helpers.convertFuelUnit(fuelQuantity, 'l', 'gal') : fuelQuantity;;
          }

          if (currency && currency.toUpperCase() === 'CAD') {
            iftaArrMiles[stateProvinceAbbrv].fuelCardTotalPaidCAD += total;
            totalFuelCardPaidCAD += total;
          } else if (currency && currency.toUpperCase() === 'USD') {
            iftaArrMiles[stateProvinceAbbrv].fuelCardTotalPaidUSD += total;
            totalFuelCardPaidUSD += total;
          }
        }
      }
    }

    // This is for deciding whether to spread the calculated Vehicle Km
    // Should Use SpreadKm when the odometerDifference is not negative or way too large!
    // Otherwise will just use calculatedOdometerKm
    let shouldUseSpreadKm = false;
    const { odometerDiffKm } = state.odometerReadings;

    // We will check to see that both the odometer difference and the totalVehicleKmSaved are within reasonable values
    // And we will also place a restriction of how close the values have to be
    if
      (odometerDiffKm > 0
      && totalSavedVehicleKm > 0
      && (odometerDiffKm < (moment(props.dateEnd).diff(moment(props.dateStart), 'hours') * 200))
      && (totalSavedVehicleKm < (moment(props.dateEnd).diff(moment(props.dateStart), 'hours') * 200))
      && (Math.abs(totalSavedVehicleKm - odometerDiffKm) < (5000 * (moment(props.dateEnd).diff(moment(props.dateStart), 'months') + 1)))
    ) {
      shouldUseSpreadKm = true;
    }

    for (let i = 0; i < stateProvinceCodes.length; i++) {
      const stateProvince = stateProvinceCodes[i];
      const subtotalSavedVehicleKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].savedVehicleKm ? Math.round(iftaArrMiles[stateProvince].savedVehicleKm * 100) / 100 : 0;
      const subtotalTotalVehicleKmDiff = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].totalVehicleKmDiff ? Math.round(iftaArrMiles[stateProvince].totalVehicleKmDiff * 100) / 100 : 0;
      const subtotalDistanceKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].distanceKm ? Math.round(iftaArrMiles[stateProvince].distanceKm * 100) / 100 : 0;
      const fuelPurchaseSubtotals = iftaArrMiles[stateProvince] ? iftaArrMiles[stateProvince].fuelPurchaseSubtotals : {};
      const fuelCardSubtotals = iftaArrMiles[stateProvince] ? iftaArrMiles[stateProvince].fuelCardSubtotals : {};

      const subtotalSpreadKm = Math.round(totalSavedVehicleKm) !== 0 ? totalSavedVehicleKm && totalSavedVehicleKm && odometerDiffKm && Math.round((subtotalSavedVehicleKm / Math.round(totalSavedVehicleKm)) * odometerDiffKm * 100) / 100 : subtotalSavedVehicleKm;
      // totalSpreadKm += subtotalSpreadKm;
      const stateProvinceDataObj = {
        code: stateProvince,
        subtotalSavedVehicleKm,
        subtotalTotalVehicleKmDiff,
        subtotalDistanceKm,
        fuelPurchaseSubtotals,
        fuelCardSubtotals,
        subtotalSpreadKm: shouldUseSpreadKm ? subtotalSpreadKm : subtotalSavedVehicleKm,
      };
      stateProvinceDataArr.push(stateProvinceDataObj);
    }

    stateProvinceDataArr.filter((stateProvinceData) => stateProvinceData.subtotalSavedVehicleKm)
      .map(stateProvinceData => {
        let fuelPurchaseSubtotalsString = '';
        let fuelCardSubtotalsString = '';

        const fuelTypeArr = Object.keys(stateProvinceData.fuelPurchaseSubtotals);
        for (let i = 0; i < fuelTypeArr.length; i++) {
          const fuelType = fuelTypeArr[i];
          // Convert volumes (already in Gallons)
          if (state.volumeUnitFilter.value === 'gal') {
            fuelPurchaseSubtotalsString += `${fuelType}: ${stateProvinceData.fuelPurchaseSubtotals[fuelType].toFixed(2)} `;
          } else if (state.volumeUnitFilter.value === 'l') {
            fuelPurchaseSubtotalsString += `${fuelType}: ${Helpers.convertFuelUnit(stateProvinceData.fuelPurchaseSubtotals[fuelType], 'gal', 'l').toFixed(2)} `;
          }
        }

        const fuelCardArr = Object.keys(stateProvinceData.fuelCardSubtotals);
        for (let i = 0; i < fuelCardArr.length; i++) {
          const item = fuelCardArr[i];
          fuelCardSubtotalsString += `${item}: ${stateProvinceData.fuelCardSubtotals[item].toFixed(2)} `;
        }

        csvString += `"${stateProvinceData.code}",`;
        // getting rid of gps mileage
        // csvString += `"${(state.distanceUnitFilter.value === 'km' ? (Math.round(stateProvinceData.subtotalDistanceKm * 100) / 100) : (Math.round(Helpers.convertDistance(stateProvinceData.subtotalDistanceKm, 'km', 'mi', true) * 100) / 100).toFixed(0))}",`;
        csvString += `"${fuelPurchaseSubtotalsString}",`;
        csvString += `"${fuelCardSubtotalsString}",`;
        csvString += `"${shouldUseSpreadKm ?
          (state.distanceUnitFilter.value === 'km' ? (Math.round(stateProvinceData.subtotalSpreadKm * 100) / 100) : (Math.round(Helpers.convertDistance(stateProvinceData.subtotalSpreadKm, 'km', 'mi', true) * 100) / 100).toFixed(0)) :
          (state.distanceUnitFilter.value === 'km' ? (Math.round(stateProvinceData.subtotalSavedVehicleKm * 100) / 100) : (Math.round(Helpers.convertDistance(stateProvinceData.subtotalSavedVehicleKm, 'km', 'mi', true) * 100) / 100).toFixed(0))
          }"\n`;

      });

    const totalSpreadKm = Math.round(totalSavedVehicleKm) !== 0 ? totalSavedVehicleKm && odometerDiffKm && Math.round((totalSavedVehicleKm / Math.round(totalSavedVehicleKm)) * odometerDiffKm * 100) / 100 : totalSavedVehicleKm;
    let totalFuelPurchaseString = '';
    let totalFuelCardString = '';


    for (let fuelType in totalFuelPurchases) {
      // Already converted to gallons
      totalFuelPurchaseString += `${fuelType}: ${totalFuelPurchases[fuelType].toFixed(2)} `;
    }

    for (let item in totalFuelCardData) {
      totalFuelCardString += `${item}: ${totalFuelCardData[item].toFixed(2)} `;
    }

    csvString += `TOTAL,`;
    // getting rid of gps mileage
    // csvString += `"${(state.distanceUnitFilter.value === 'km' ? totalDistanceKm : Helpers.convertDistance(totalDistanceKm, 'km', 'mi', true).toFixed(0))}",`;
    csvString += `"${totalFuelPurchaseString}",`;
    csvString += `"${totalFuelCardString}",`;
    csvString += `"${shouldUseSpreadKm ?
      (state.distanceUnitFilter.value === 'km' ? (Math.round(totalSpreadKm * 100) / 100) : (Math.round(Helpers.convertDistance(totalSpreadKm, 'km', 'mi', true) * 100) / 100).toFixed(0)) :
      (state.distanceUnitFilter.value === 'km' ? (Math.round(totalSavedVehicleKm * 100) / 100) : (Math.round(Helpers.convertDistance(totalSavedVehicleKm, 'km', 'mi', true) * 100) / 100).toFixed(0))
      }"\n`;


    Helpers.createCsvFile(`${props.unitId} Switchboard - IFTA Mileage Summary (${props.dateStart && moment(props.dateStart).format('MMMM YYYY')} to ${props.dateEnd && moment(props.dateEnd).format('MMMM YYYY')})`, csvString, true);
    this.setState({ ...this.state, downloadCSVInProgress: false });
  }

  // Filter the iftaRoutes in a similar fashion to view by day and determine issues
  generateIFTARouteDayIssuesArr() {
    const { props } = this;
    const driverTimeZoneStr = props.iftaRouteArr && props.iftaRouteArr.length > 0 && getTimezoneString(props.iftaRouteArr[0]);
    let iftaRouteDayArr = [];

    const generateIFTARouteDayObj = (iftaRoute) => {
      // We're going to create a custom object containing all the information needed for a day's IFTARoutes
      const vehicleLocationStart = getAttribute(iftaRoute, 'vehicleLocationStart');
      const vehicleLocationEnd = getAttribute(iftaRoute, 'vehicleLocationEnd');
      const iftaRouteDriverPeriods = getAttribute(iftaRoute, 'iftaRouteDriverPeriods');

      const iftaRouteDayObj = {
        uniqueId: moment(getAttribute(iftaRoute, 'dateStart')).tz(driverTimeZoneStr).format('YYYYMMDD'), // Unique identifier will be the day in which this IFTARoute represents
        driverTimeZoneStr,
        dateStart: getAttribute(iftaRoute, 'dateStart'),
        dateEnd: getAttribute(iftaRoute, 'dateEnd'),
        vehicleLocationStart: {
          stateProvince: getAttribute(vehicleLocationStart, 'stateProvince'),
          aprxShortName: getAttribute(vehicleLocationStart, 'aprxShortName'),
          locationDescriptionUS: getAttribute(vehicleLocationStart, 'locationDescriptionUS'),
        },
        vehicleLocationEnd: {
          stateProvince: getAttribute(vehicleLocationEnd, 'stateProvince'),
          aprxShortName: getAttribute(vehicleLocationEnd, 'aprxShortName'),
          locationDescriptionUS: getAttribute(vehicleLocationEnd, 'locationDescriptionUS'),
        },
        stateProvince: getAttribute(iftaRoute, 'stateProvince'),
        vehicleUnitId: getAttribute(iftaRoute, 'vehicleUnitId'),
        iftaRouteDriverPeriods: (iftaRouteDriverPeriods || []).map((iftaRouteDriverPeriod) => {
          const driver = getAttribute(iftaRouteDriverPeriod, 'driver');

          return {
            objectId: iftaRouteDriverPeriod.id,
            dateStart: getAttribute(iftaRouteDriverPeriod, 'dateStart'),
            driver: {
              objectId: driver.id,
              user_fullName: getAttribute(driver, 'user_fullName'),
            },
          };
        }),
        totalVehicleKmStart: getAttribute(iftaRoute, 'totalVehicleKmStart'),
        totalVehicleKmEnd: getAttribute(iftaRoute, 'totalVehicleKmEnd'),
        totalVehicleKmDiff: getAttribute(iftaRoute, 'totalVehicleKmDiff'),
        savedVehicleKm: getAttribute(iftaRoute, 'savedVehicleKm'),
        distanceKm: getAttribute(iftaRoute, 'distanceKm'),
        // fuelPurchases: getAttribute(iftaRoute, 'fuelPurchases'),
        // totalGallons: getAttribute(iftaRoute, 'totalGallons'),
        odometerJumpGapKm: 0,
        iftaRouteRecordsArr: [iftaRoute], // Contains all the IFTARoute records which are part of this day
        lastIFTARouteForPrevDay: undefined, // Contains the last IFTARoute record for the previous day
        firstIFTARouteForNextDay: undefined, // Contains the first IFTARoute record for the next day
      };

      return iftaRouteDayObj;
    };

    const updateIFTARouteDayObj = (iftaRouteDayObj, iftaRoute) => {
      // Obtain the relevant information from the current IFTARoute
      const dateEnd = getAttribute(iftaRoute, 'dateEnd');
      const vehicleLocationEnd = getAttribute(iftaRoute, 'vehicleLocationEnd');
      const iftaRouteDriverPeriods = getAttribute(iftaRoute, 'iftaRouteDriverPeriods');
      const totalVehicleKmEnd = getAttribute(iftaRoute, 'totalVehicleKmEnd');
      const totalVehicleKmDiff = getAttribute(iftaRoute, 'totalVehicleKmDiff');
      const distanceKm = getAttribute(iftaRoute, 'distanceKm');
      const savedVehicleKm = getAttribute(iftaRoute, 'savedVehicleKm');
      // const fuelPurchases = getAttribute(iftaRoute, 'fuelPurchases');
      // const totalGallons = getAttribute(iftaRoute, 'totalGallons');

      if (iftaRouteDriverPeriods && (iftaRouteDriverPeriods.length > 1)) {
        iftaRouteDayObj.iftaRouteDriverPeriods = iftaRouteDriverPeriods.map((iftaRouteDriverPeriod) => {
          const driver = getAttribute(iftaRouteDriverPeriod, 'driver');

          return {
            objectId: iftaRouteDriverPeriod.id,
            dateStart: getAttribute(iftaRouteDriverPeriod, 'dateStart'),
            driver: {
              objectId: driver.id,
              user_fullName: getAttribute(driver, 'user_fullName'),
            },
          };
        });
      }

      iftaRouteDayObj.vehicleLocationEnd = {
        stateProvince: getAttribute(vehicleLocationEnd, 'stateProvince'),
        aprxShortName: getAttribute(vehicleLocationEnd, 'aprxShortName'),
        locationDescriptionUS: getAttribute(vehicleLocationEnd, 'locationDescriptionUS'),
      };

      iftaRouteDayObj.dateEnd = dateEnd;
      iftaRouteDayObj.savedVehicleKm += savedVehicleKm;
      iftaRouteDayObj.totalVehicleKmEnd = totalVehicleKmEnd;
      iftaRouteDayObj.totalVehicleKmDiff = totalVehicleKmEnd - iftaRouteDayObj.totalVehicleKmStart;
      iftaRouteDayObj.distanceKm += distanceKm;
      // iftaRouteDayObj.fuelPurchases = fuelPurchases;
      // iftaRouteDayObj.totalGallons += totalGallons;
      iftaRouteDayObj.iftaRouteRecordsArr.push(iftaRoute);
    };

    let prevDateEndMoment;

    for (let i = 0; i < props.iftaRouteArr.length; i++) {
      const iftaRoute = props.iftaRouteArr[i]; // Obtain the current IFTARoute record
      const dateStart = getAttribute(iftaRoute, 'dateStart');
      const dateEnd = getAttribute(iftaRoute, 'dateEnd');

      const currentDateStartMoment = moment(dateStart).tz(driverTimeZoneStr);
      const currentDateEndMoment = moment(dateEnd).tz(driverTimeZoneStr);

      if (i === 0) {
        const newIFTARouteDayObj = generateIFTARouteDayObj(iftaRoute);
        iftaRouteDayArr.push(newIFTARouteDayObj);
      } else if (currentDateStartMoment.format('YYYYMMDD') === prevDateEndMoment.format('YYYYMMDD') && currentDateStartMoment.format('YYYYMMDD') === currentDateEndMoment.format('YYYYMMDD')) {
        // Merge information together, keep all the start values the same, but change the end values
        const previousIFTARouteDayObj = iftaRouteDayArr[iftaRouteDayArr.length - 1];
        updateIFTARouteDayObj(previousIFTARouteDayObj, iftaRoute);
      } else {
        // Create a new IFTARoute for the day and update the values from the previous day
        const newIFTARouteDayObj = generateIFTARouteDayObj(iftaRoute);
        const previousIFTARouteDayObj = iftaRouteDayArr[iftaRouteDayArr.length - 1];

        previousIFTARouteDayObj.odometerJumpGapKm = newIFTARouteDayObj.totalVehicleKmStart - previousIFTARouteDayObj.totalVehicleKmEnd;
        previousIFTARouteDayObj.firstIFTARouteForNextDay = iftaRoute;
        newIFTARouteDayObj.lastIFTARouteForPrevDay = props.iftaRouteArr[i - 1];

        iftaRouteDayArr.push(newIFTARouteDayObj);
      }

      prevDateEndMoment = currentDateEndMoment;
    }

    const iftaRouteDayIssuesArr = findDayViewIssues(iftaRouteDayArr);
    this.setState({ ...this.state, iftaRouteDayIssuesArr });
  }

  render() {
    const { state, props } = this;

    return (
      <React.Fragment>
        <div style={{ margin: '1em' }}>
          <SBSelect
            containerClassName="ml-2 mr-2 d-inline-block"
            className="sb-jobs-list-select"
            defaultToggleText={state.vehicleSummaryFilter.label}
            items={state.selectableVehicleSummaryFilterItems}
            getSelectedItems={(selectedFilter) => this.setState({ ...this.state, vehicleSummaryFilter: selectedFilter[0] })}
            showFilter
          />
          <SBSelect
            containerClassName="ml-2 mr-2 d-inline-block"
            className="sb-jobs-list-select"
            defaultToggleText={state.distanceUnitFilter.label}
            items={state.distanceUnitFilterItems}
            getSelectedItems={(selectedFilter) => this.setState({ ...this.state, distanceUnitFilter: selectedFilter[0] })}
            showFilter
          />
          <SBSelect
            containerClassName="ml-2 mr-2 d-inline-block"
            className="sb-jobs-list-select"
            defaultToggleText={state.volumeUnitFilter.label}
            items={state.volumeUnitFilterItems}
            getSelectedItems={(selectedFilter) => this.setState({ ...state, volumeUnitFilter: selectedFilter[0] })}
            showFilter
          />
          <SBSelect
            containerClassName="ml-2 mr-2 d-inline-block"
            className="sb-jobs-list-select"
            defaultToggleText={state.showOdometerValuesFilter.label}
            items={state.showOdometerValuesFilterItems}
            getSelectedItems={(selectedFilter) => this.setState({ ...state, showOdometerValuesFilter: selectedFilter[0] })}
            showFilter
          />
          <MDBBtn
            size="sm"
            color="secondary"
            disabled={state.printInProgress || state.isLoading}
            onClick={() => this.printReport()}
            style={{ marginRight: '.5em' }}
          >
            Print Report
          </MDBBtn>
          <MDBBtn
            size="sm"
            color="info"
            onClick={() => this.downloadCSV()}
            disabled={state.isLoading}
            style={{ marginRight: '.5em', width: '14em' }}
          >
            Download CSV
          </MDBBtn>
        </div>

        <div className="vehicleSummary-printable">
          {moment(props.dateEnd).isAfter(moment().subtract(11, 'days')) && (
            <SBBlock
              title="Switchboard processes IFTA vehicle mileages up to 10 days before today's date."
              header="If you are generating reports for the previous month, make sure you generate them on the 11th of this month to get the most updated mileages."
              warning
            />
          )}
          {/* {state.iftaRouteDayIssuesArr.length > 0 && (
            <SBBlock
              warning
              listMessages={false}
              title="Warning"
              messages={[
                <div style={{ fontSize: '1em' }}>
                  There are errors detected in the generated data which may impact your IFTA reporting.
                  Please navigate to the <b>Chronological Order</b> tab to view more details and ways to resolve them.
                </div>,
              ]}
            />
          )} */}
          <VehicleSummaryViewBeta
            routeToNewIFTA={props.routeToNewIFTA}
            isLoading={state.isLoading}
            unitId={props.unitId}
            dateStart={props.dateStart}
            dateEnd={props.dateEnd}
            displayStartDate={props.displayStartDate}
            displayEndDate={props.displayEndDate}
            iftaRouteArr={props.iftaRouteArr}
            stateProvinceMileages={props.stateProvinceMileages}
            selectedFilter={state.vehicleSummaryFilter}
            distanceUnitFilter={state.distanceUnitFilter}
            volumeUnitFilter={state.volumeUnitFilter}
            showOdometerValuesFilter={state.showOdometerValuesFilter}
            odometerReadings={state.odometerReadings}
            odometerReadingsDisplayingZero={this.props.odometerReadingsDisplayingZero}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default VehicleSummaryBeta;
