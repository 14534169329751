import React from 'react';
import PropTypes from 'prop-types';
import { HelpBlock, Modal } from 'react-bootstrap';

// Components
import GlobalJobDetails from './PickupAndDropoffViews/GlobalJobDetails.js';
import JobActionBlock from './PickupAndDropoffViews/JobActionBlock.js';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './AddNewJobModalPickupDropoff.module.scss';

function getValidateErrorString(validateErrorsObj) {
  const keys = Object.keys(validateErrorsObj);
  let string = '';
  for (let i = 0; i < keys.length; i++) {
    if (validateErrorsObj[keys[i]] && keys[i] !== 'error' && keys[i] !== 'trigger') {
      string += `${validateErrorsObj[keys[i]].label}, `;
    }
  }
  return string.substring(0, string.length - 2);
}

function AddNewJobModal(props) {
  return (
    <Modal keyboard={false} backdrop="static" bsSize="large" show={props.show} onHide={() => props.handleClose()}>

      <Modal.Header closeButton>
        <Modal.Title>Add New Job</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className={styles.content}>
          <GlobalJobDetails {...props} />
          <div className={styles.jobBlocks}>
            <table><tbody><tr>
              <td><JobActionBlock {...props} jobActionBlock={props.jobActionPickup} type="pickup" /></td>
              <td><JobActionBlock {...props} jobActionBlock={props.jobActionDropoff} type="dropoff" /></td>
            </tr></tbody></table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        {props.addingInProgress &&
          <div className="centerText"><LoadingIcon /></div>
        }
        {!props.addingInProgress && props.validationErrors.error && props.validationErrors.trigger &&
          <div className={styles.error}>Missing {getValidateErrorString(props.validationErrors)}</div>
        }
        {!props.addingInProgress &&
          <div>
            <button className="closeButton" onClick={() => props.handleClose()}>Cancel</button>
            <button className="buttonDefault" onClick={() => props.onSubmit()} disabled={props.addingInProgress}>Add Job</button>
          </div>
        }
      </Modal.Footer>

    </Modal>
  );
}

AddNewJobModal.propTypes = {
  jobActionPickup: PropTypes.object.isRequired,
  jobActionDropoff: PropTypes.object.isRequired,
  addingInProgress: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  validationErrors: PropTypes.object,
};

export default AddNewJobModal;
