import React from 'react';
import PropTypes from 'prop-types';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';

class FileUploader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // we need to write our file handler like this in order to allow multiple uploading of the same files

    const { uploadHandler, multiple } = this.props; // init props here before 'this' is overrite-d
    var fileChooser = document.getElementById(this.props.inputId || 'fileUploaderInput');
    fileChooser.onclick = function () {
      this.value = '';
      fileChooser.onchange = function (e) {
        const files = e.target.files;
        if (files) {
          if (multiple) {
            uploadHandler(files);
          } else {
            uploadHandler(files[0]);
          }
        }
      };
    };
  }

  render() {

    const { inputId, multiple, label, raised, disabled, style, acceptedFileType } = this.props;

    const accept = acceptedFileType || '.csv';

    const fileInputStyle = {
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      width: '100%',
      opacity: 0,
    };

    const buttonLabel = label || 'Upload File';

    let fileInput = (
      <input
        id={ inputId || 'fileUploaderInput' }
        type="file"
        accept={accept}
        style={fileInputStyle}
      />
    );

    if (multiple) {
      fileInput = (
        <input
          id={ inputId || 'fileUploaderInput' }
          type="file"
          accept={accept}
          multiple="multiple"
          name="file[]"
          style={fileInputStyle}
        />
      );
    }

    if (disabled) fileInput = undefined;

    let uploadButton = (
      <FlatButton
        className="buttonSpacedRight"
        label={buttonLabel}
        labelPosition="before"
        containerElement="label"
        disabled={disabled}
        style={style}>
        { fileInput }
      </FlatButton>
    );

    if (raised) {
      uploadButton = (
        <RaisedButton
          className="buttonSpacedRight"
          label={buttonLabel}
          labelPosition="before"
          containerElement="label"
          disabled={disabled}
          style={style}>
          { fileInput }
        </RaisedButton>
      );
    }

    return (
      uploadButton
    );
  }
}

FileUploader.propTypes = {
  inputId: PropTypes.string,
  acceptedFileType: PropTypes.string,
  multiple: PropTypes.bool,
  uploadHandler: PropTypes.func.isRequired,
  label: PropTypes.string,
  raised: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
};

export default FileUploader;
