import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-google-autocomplete';

import { track } from 'sb-csapi/dist/api/Analytics/Product';
import { Feature, getEventString } from 'sb-csapi/dist/enums/Analytics/Product';

// CSS
import styles from './SearchBar.module.scss';

function SearchBar(props) {
  return (
    <div className={styles.searchBar}>
      <Autocomplete
        className={styles.autocomplete}
        placeholder={t('search for location...')}
        types={['establishment', 'geocode']}
        componentRestrictions={{ country: ['ca', 'us'] }}
        onPlaceSelected={(place) => {
          props.handleAutocomplete(place);

          if (window.sbProdAnalIsAnon === false) {
            // Don't want to track anonymous users
            track(
              window.sbProdAnalEntityId,
              getEventString(Feature.MAP, 'Used Location Searchbar Autocomplete'),
            );
          }
        }}
      />
    </div>
  );
}

SearchBar.propTypes = {
  handleAutocomplete: PropTypes.func.isRequired,
};

export default SearchBar;
