import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// API
import { sortQuery, getCurrentUser, addRecord, createQuery, setQueryRestriction, updateRecord, find, includePointers, getAttribute } from 'api/Parse';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';
import * as Helpers from 'api/Helpers';

// Containers
import Login from 'components/Login/container/Login';

// CSS
import styles from './LoginLayout.module.scss';
import { getResellerCompany } from '../../api/Getters';

// Assets
const colourLogo = require('assets/images/switchboard-horizontal.png');

function getLogoURLFromCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(cname) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export default function (props) {
  document.title = 'Login - Switchboard Transportation Technology';

  const [resellerLogoUrl, setResellerLogoUrl] = useState(null);
  const [companyLogoUrl, setCompanyLogoUrl] = useState(null);

  useEffect(() => {
    if (getLogoURLFromCookie('SwitchboardResellerLogoURL') && !resellerLogoUrl) {
      setResellerLogoUrl(getLogoURLFromCookie('SwitchboardResellerLogoURL'));
    }
    if (getLogoURLFromCookie('SwitchboardCompanyLogoURL') && !companyLogoUrl) {
      setCompanyLogoUrl(getLogoURLFromCookie('SwitchboardCompanyLogoURL'));
    }
  }, [document?.cookie]);


  useEffect(() => {
    const referrerUri = document?.referrer;
    let referrerDomain;
    if (referrerUri) {
      referrerDomain = Helpers.getHostnameFromRegex(referrerUri);
    }

    if (
      referrerDomain
      && referrerDomain.indexOf('localhost') === -1
      && referrerDomain.indexOf('onswitchboard') === -1
    ) {
      const getResellerCompany = async () => {
        const resellerCompanyQuery = createQuery('ResellerCompany');
        setQueryRestriction(resellerCompanyQuery, QueryRestrictionTypes.MATCHES, 'domain', referrerDomain);
        setQueryRestriction(resellerCompanyQuery, QueryRestrictionTypes.EXISTS, 'logo');
        const resellerCompanyObj = (await find(resellerCompanyQuery, true));
        if (resellerCompanyObj) setResellerLogoUrl(getAttribute(resellerCompanyObj, 'logo', true).url());
      }

      getResellerCompany();
    }
  }, [document?.referrer]);

  return (
    <div className={styles.loginBackground}>
      <div className={styles.loginContent}>
        {!companyLogoUrl &&
          (
            <div className={styles.logoContainer}>
              {resellerLogoUrl &&
                <img className={styles.loginLogo} role="presentation" src={resellerLogoUrl} />
              }
              {!resellerLogoUrl &&
                <a href="https://www.onswitchboard.com">
                  <img className={styles.loginLogo} role="presentation" src={colourLogo} />
                </a>
              }
            </div>
          )
        }
        {companyLogoUrl &&
          (
            <div className={styles.logoContainer}>
              <table><tbody><tr>
                <td>
                  {resellerLogoUrl &&
                    <img className={styles.loginLogo} role="presentation" src={resellerLogoUrl} />
                  }
                  {!resellerLogoUrl &&
                    <a href="https://www.onswitchboard.com">
                      <img className={styles.loginLogo} role="presentation" src={colourLogo} />
                    </a>
                  }
                </td>
                <td>
                  <img className={styles.loginLogo} role="presentation" src={companyLogoUrl} />
                </td>
              </tr></tbody></table>
            </div>
          )
        }
        <h1>Fleet Management Cloud</h1>
        <h2>Powered by Switchboard</h2>
        <br />
        <Login
          {...props}
        />
      </div>
    </div>
  );
}
