import React from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from 'sbHistory';

import { RaisedButton, Table, TableHeader, TableBody, TableRow, TableRowColumn } from 'material-ui';
import { removeSpecialCharacters, openDocumentLink, isSubscribedToModule } from 'api/Helpers';
import { getCurrentUser, getResellerCompany } from 'api/Getters';

import { doLogout } from 'actions/Auth';

// Components
import {
  MDBBtn, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBDropdown,
  MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon,
} from 'mdbreact';

import NotificationDropdown from 'components/Shared/NotificationDropdown/NotificationDropdown';
import TrialCountdownWarning from 'components/Shared/TrialCountdownWarning/TrialCountdownWarning';
import HomeHelpDialog from 'layouts/HomeLayout/HomeHelpDialog';

// Assets
const appleBadge = require('assets/images/download-on-the-app-store-original.png');
const googleBadge = require('assets/images/google-play-badge-original.png');
const canadaFlag = require('assets/images/fr_CA.png');
const usaFlag = require('assets/images/en_US.png');

// CSS
import './style.scss';


class UserHeaderBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      currentLanguageCode: window.Localize.getLanguage(),
      availableLanguages: [
        { code: 'en', name: 'English', image: usaFlag },
        { code: 'fr-CA', name: 'Français', image: canadaFlag },
      ],
      resellerCompany: null,
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.logout = this.logout.bind(this);
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.company) {
      const promoCode = nextProps.company.get('promoCode');
      if (promoCode) {
        const resellerCompany = await getResellerCompany();
        this.setState({ ...this.state, resellerCompany });
      }
    }
  }

  toggleCollapse() {
    this.setState({ ...this.state, collapsed: !this.state.collapsed });
  }

  setLanguage(code) {
    window.Localize.setLanguage(code);
    moment.locale(code);
    this.setState({ currentLanguageCode: code });
  }

  // Check if the user is a reseller or not
  logout() {
    if (!this.props.company.get('promoCode')) return doLogout(); // not a reseller so actually log out
    history.push('/login'); // reseller so fake the logout
  }

  render() {
    const { user, company, subscription } = this.props;
    const { collapsed, currentLanguageCode, availableLanguages } = this.state;

    const currentUser = getCurrentUser();
    const userSpecialPermission = currentUser && currentUser.get('userSpecialPermission');
    const disableNotifications = userSpecialPermission && (userSpecialPermission.get('disableViolations') || userSpecialPermission.get('disableEldShowEditHistory'));

    let userFullName;
    if (user) {
      userFullName = `${removeSpecialCharacters(user.get('firstName'))} ${(user.get('lastName') && removeSpecialCharacters(user.get('lastName'))[0]) || ''}`.toUpperCase();
    }

    const companyName = ((company && company.get('name')) || '').toUpperCase();
    const companyLogoFile = company && company.get('logo');

    const showTrialCountdownWarning = subscription && !subscription.get('subscriptionId') && subscription.get('startSubscription') && subscription.get('ignoreSubscriptionUntil') && moment(subscription.get('ignoreSubscriptionUntil')).isAfter(moment());

    return (
      <MDBNavbar className="user-header-bar" dark expand="xs">
        <MDBNavbarBrand>
          <strong className="white-text">
            <a onClick={() => history.push('/home')}>
              <img className="logo-brand" src="https://s3.amazonaws.com/onswitchboard.com/white-logo.png" alt="Switchboard Logo White" />
            </a>
            {this.state.resellerCompany && this.state.resellerCompany.get('logo') &&
              (
                <span className="hide-mobile">
                  &nbsp; &nbsp;
                  <a onClick={() => history.push('/home')}>
                    <img className="logo-brand" src={this.state.resellerCompany.get('logo').url()} alt={`${this.state.resellerCompany.get('name')} logo`} />
                  </a>
                </span>
              )
            }
          </strong>
        </MDBNavbarBrand>

        {showTrialCountdownWarning &&
          <div className="breakpoint-hide">
            <TrialCountdownWarning subscription={subscription} />
          </div>
        }

        <div className="company-brand-container notranslate">
          {!companyLogoFile &&
            <div>{companyName}</div>
          }
          {companyLogoFile &&
            <div>
              <img src={companyLogoFile.url()} alt={`${companyName} logo`} />
            </div>
          }
        </div>

        {/* FMM */}
        {isSubscribedToModule('mapModule') && (
          <div style={{ marginLeft: '0.5em' }} className="inlineBlock">
            <div className="inline">
              <a href={'https://apps.apple.com/us/app/switchboard-fleet-management/id1512850497'} target="_blank">
                <img src={appleBadge} width={'80em'} />
              </a>
            </div>
            <div className="inline">
              <a href={'https://play.google.com/store/apps/details?id=com.onswitchboard.fleet_management'} target="_blank">
                <img src={googleBadge} width={'80em'} />
              </a>
            </div>
          </div>
        )}

        <MDBNavbarNav right className="translate-me">
          <MDBNavItem>
            <div style={{ marginTop: '.035em', paddingTop: '.33em' }}>
              <HomeHelpDialog
                buttonStyle={{
                  width: 'auto',
                  height: 'auto',
                  padding: '0.5em 1.25em',
                  cursor: 'pointer',
                  fontSize: '0.7em',
                  fontWeight: '300',
                  backgroundColor: 'rgba(0, 0, 0, 0.15)',
                }}
                buttonChildren={(
                  <div className="translate-me">
                    Need Help?
                  </div>
                )}
              />
            </div>
          </MDBNavItem>

          {showTrialCountdownWarning && (
            <MDBNavItem className="breakpoint-show">
              <TrialCountdownWarning subscription={subscription} />
            </MDBNavItem>
          )}

          {!disableNotifications && (
            <MDBNavItem className="notification-dropdown-container ml-3" style={{ paddingTop: '.46em' }}>
              <NotificationDropdown />
            </MDBNavItem>
          )}

          <MDBNavItem className="ml-3">
            <div className="user-dropdown-container">
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  <div className="user-full-name mr-2 d-none d-md-inline-block notranslate">{userFullName}</div>
                  <MDBIcon icon="user" />
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default" right>
                  <MDBDropdownItem
                    className="account-options-title"
                    key="sb-header-dropdown-title"
                  >
                    Account Options
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    className="settings-option"
                    key="sb-header-dropdown-settings"
                    onClick={() => history.push('/settings')}
                  >
                    <MDBIcon icon="cog" /><div className="d-inline-block ml-3">Settings</div>
                  </MDBDropdownItem>
                  <MDBDropdownItem
                    className="settings-option"
                    key="sb-header-dropdown-help"
                    onClick={() => openDocumentLink('http://support.onswitchboard.com')}
                  >
                    <MDBIcon icon="info-circle" /><div className="d-inline-block ml-3">Help</div>
                  </MDBDropdownItem>
                  {/* <MDBDropdownItem className="settings-option" onClick={() => history.push('/new')}>
                    <MDBIcon icon="newspaper" /><div className="d-inline-block ml-3">What's New?</div>
                  </MDBDropdownItem> */}
                  {this.props.company && (
                    <MDBDropdownItem
                      className="settings-option"
                      key="sb-header-dropdown-logout"
                      onClick={() => this.logout()}
                    >
                      <MDBIcon icon="sign-out-alt" /><div className="d-inline-block ml-3">Log Out</div>
                    </MDBDropdownItem>
                  )}
                  {
                    availableLanguages.map(language => {
                      const { code, name, image } = language;
                      if (code === currentLanguageCode) return undefined;

                      return (
                        <MDBDropdownItem
                          key={`sb-header-dropdown-language-${code}`}
                          onClick={() => this.setLanguage(code)}
                        >
                          <img src={image} alt={code} title={name} width="18" height="12" />
                          <div className="d-inline-block ml-3">{name}</div>
                        </MDBDropdownItem>
                      );
                    })
                  }
                </MDBDropdownMenu>
              </MDBDropdown>
            </div>
          </MDBNavItem>
        </MDBNavbarNav>
      </MDBNavbar >
    );
  }
}

UserHeaderBar.propTypes = {
  company: PropTypes.object,
  subscription: PropTypes.object,
  user: PropTypes.object,
  walkthroughState: PropTypes.object,
  toggleWalkthroughState: PropTypes.func,
};

export default UserHeaderBar;
