import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { getAttribute } from 'api/Parse';
import { formatName, removeSpecialCharacters } from 'api/Helpers';

// Components
import { MDBRow, MDBCol, MDBBtn, MDBInput, MDBCard, MDBCardBody, MDBCardText } from 'mdbreact';
import SBCard from 'components/Shared/SBCard/SBCard';

// CSS
import './style.scss';

class CommentSystem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      commentInputText: '',
    };

    this.handleCommentInputChange = this.handleCommentInputChange.bind(this);
    this.handleCommentSubmit = this.handleCommentSubmit.bind(this);
  }

  handleCommentInputChange(commentInputText = '') {
    this.setState({ ...this.state, commentInputText, });
  }

  handleCommentSubmit() {
    const cleanedInputText = this.state.commentInputText.trim();
    this.setState({ ...this.state, commentInputText: '' }, () => {
      this.props.handleCommentSubmit(cleanedInputText);
    });
  }

  render() {
    const { className, showCommentInput, showComments, currentUser, comments, formatAsList, commentContainerSize, commentContainerTitle, showLoadCommentsButton, handleLoadComments, loadCommentsButtonText, cardBodyStyle } = this.props;
    const { commentInputText } = this.state;
    let hasComments = true;

    let commentBoxes = comments.map((commentObject, index) => {
      let commentRowClassName = `${formatAsList ? 'mb-2' : 'mb-1'} comment-row`;
      if (index === comments.length - 1) commentRowClassName = 'mb-1 comment-row';
      if (!index) commentRowClassName = `mt-4 ${formatAsList ? 'mb-2' : 'mb-1'} comment-row`;

      let commentCardClassName = 'comment-card';

      const sentByUser = getAttribute(commentObject, 'sentBy');
      let sentByFullName = formatName(`${removeSpecialCharacters(getAttribute(sentByUser, 'firstName'))} ${removeSpecialCharacters(getAttribute(sentByUser, 'lastName'))[0]}`);

      const isSentByThisUser = currentUser && sentByUser && (getAttribute(currentUser, 'objectId') === getAttribute(sentByUser, 'objectId'));
      if (isSentByThisUser) {
        sentByFullName = 'you';
        commentCardClassName = commentCardClassName += ` box-shadow-blue is-user`;
      } else {
        commentCardClassName = commentCardClassName += ` box-shadow-green-kelp is-friend`;
      }

      let cardContent = (
        <MDBCard className={commentCardClassName}>
          <MDBCardBody>
            <MDBCardText className="comment">
              { getAttribute(commentObject, 'comment') }
            </MDBCardText>
            <MDBCardText small muted>
              Sent by <span className="font-weight-600">{ sentByFullName }</span>
              <span>&nbsp;&nbsp;({ moment(getAttribute(commentObject, 'dateTimeUTC')).format('DD-MMM-YYYY / HH:mm') } {moment.tz(moment.tz.guess()).zoneAbbr()})</span>
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      );

      return (
        <MDBRow key={getAttribute(commentObject, 'objectId')} className={commentRowClassName}>
          { formatAsList ?
            <MDBCol>
              { cardContent }
            </MDBCol>
            :
            <React.Fragment>
              <MDBCol md="5">
                { !isSentByThisUser && cardContent }
              </MDBCol>
              <MDBCol md="2" />
              <MDBCol md="5">
                { isSentByThisUser && cardContent }
              </MDBCol>
            </React.Fragment>
          }
        </MDBRow>
      );
    });

    if (!commentBoxes.length) {
      hasComments = false;
      let noCommentsClassName = 'mt-1 pt-3';
      if (formatAsList) {
        noCommentsClassName = 'mt-1';
      }
      commentBoxes = [
        <MDBRow key="0"><MDBCol className={noCommentsClassName}>
          <div className="empty-comment-container">No Comments Found</div>
        </MDBCol></MDBRow>
      ];
    }

    let _className = "d-flex sb-form sb-comment-system-container";
    if (className) _className += ` ${className}`;

    return (
      <div className={_className}>
        { showCommentInput &&
          <MDBRow>
            <MDBCol>
              <div className="input-container">
                <MDBInput className="active comment-input" hint="Add comment" icon="pen" onChange={(e) => this.handleCommentInputChange(e.target.value)} value={commentInputText} />
                <MDBBtn
                  className="d-inline-block"
                  color="primary"
                  size="sm"
                  onClick={this.handleCommentSubmit}
                >
                  Submit
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>
        }
        { showComments &&
          <MDBRow>
            <MDBCol md={commentContainerSize.toString()}>
              <SBCard className="mt-3 comment-container" animateEntrance title={commentContainerTitle} isCollapsible={false} cardBodyStyle={{ maxHeight: 'none', ...cardBodyStyle }}>
                <div style={hasComments ? { fontSize: '.9em' } : {}}>{ commentBoxes }</div>
                { showLoadCommentsButton &&
                  <div className="mt-4 options-container">
                    <MDBBtn color="secondary" size="sm" onClick={() => handleLoadComments()}>
                      { loadCommentsButtonText }
                    </MDBBtn>
                  </div>
                }
              </SBCard>
            </MDBCol>
          </MDBRow>
        }
      </div>
    );
  }
}


CommentSystem.defaultProps = {
  comments: [],
  handleCommentSubmit: (e) => console.log(e),
  showCommentInput: true,
  showComments: true,
  commentContainerSize: '12',
  commentContainerTitle: '',
  handleLoadComments:(e) => console.log(e),
  loadCommentsButtonText: 'View Older',
};
CommentSystem.propTypes = {
  className: PropTypes.string,
  comments: PropTypes.array,
  formatAsList: PropTypes.bool, // determine how to show the comment bubbles - by list or conventional chat style
  cardBodyStyle: PropTypes.object,

  // comment input
  showCommentInput: PropTypes.bool,
  handleCommentSubmit: PropTypes.func, // callback to handle comment input submit

  // comments
  commentContainerTitle: PropTypes.string,
  commentContainerSize: PropTypes.number, // col md size
  showComments: PropTypes.bool,
  currentUser: PropTypes.object, // user object - determines how to display comments (ex. if user who sent msg is props.currentUser)

  // load comments button
  showLoadCommentsButton: PropTypes.bool,
  loadCommentsButtonText: PropTypes.string,
  handleLoadComments: PropTypes.func,
};

export default CommentSystem;
