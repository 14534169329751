import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Feature, Layer } from 'react-mapbox-gl';

// API
import { getAttribute } from 'api/Parse';
import { isVehicleDriverDriving } from 'api/ELD';

// CSS
import styles from './ComponentStyles.module.scss';

// import shipping from 'assets/icon/local_shipping.png'

class TrailersLayer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { props, state } = this;
    const mentalGrey = '#292E37';
    const yellowTruck = '#E1AE37';
    const greenActive = '#2BBBA7';
    const grayCalm = '#656565';

    // These are the 5 types of tailers we have
    const activeTrailers = [];
    const isIdlingTrailers = [];
    const isDrivingTrailers = [];
    const offDutyTrailers = [];
    const directionTrailers = [];

    // We bin the trailers into their corresponding arrays
    Object.keys(props.trailerPerUnitId).forEach((trailerUnitId, index) => {
      const trailerIsActive = props.trailerPerUnitId[trailerUnitId].isActive;
      const { trailer } = props.trailerPerUnitId[trailerUnitId];
      // const unitId = getAttribute(trailer ,'unitId', true) || 'N/A';
      const trailerLocationObj = getAttribute(trailer, 'vehicleLocation', true);
      const isDriving = trailerLocationObj && getAttribute(trailerLocationObj, 'speedKm') > 0;
      const trailerDirection = props.followBearing || isDriving && ((trailerLocationObj && getAttribute(trailerLocationObj, 'gpsHeading')) || ((trailerLocationObj && getAttribute(trailerLocationObj, 'course'))));
      const trailerProperties = { title: trailerUnitId };

      if (trailerDirection) {
        trailerProperties.rotate = trailerDirection - 90;
        // if (isIdling) {
        //   isIdlingTrailers.color = yellowTruck;
        //   activeTrailers.push(
        //     <Feature
        //       onClick={() => { if (props.handleOnClick) props.handleOnClick(trailer); }}
        //       properties={trailerProperties}
        //       key={`${getAttribute(trailer, 'objectId', true)}-active`}
        //       coordinates={props.trailerPerUnitId[trailerUnitId].coordinate}
        //     />,
        //   );
        // }
        if (isDriving) {
          trailerProperties.color = greenActive;
          isDrivingTrailers.push(
            <Feature
              onClick={() => { if (props.handleOnClick) props.handleOnClick(trailer); }}
              properties={trailerProperties}
              key={`${getAttribute(trailer, 'objectId', true)}-driving`}
              coordinates={props.trailerPerUnitId[trailerUnitId].coordinate}
            />,
          );
        } else {
          trailerProperties.color = mentalGrey;
          directionTrailers.push(
            <Feature
              onClick={() => { if (props.handleOnClick) props.handleOnClick(trailer); }}
              properties={trailerProperties}
              key={`${getAttribute(trailer, 'objectId', true)}-direction`}
              coordinates={props.trailerPerUnitId[trailerUnitId].coordinate}
            />,
          );
        }
      } else if (trailerIsActive) {
        trailerProperties.color = mentalGrey;
        activeTrailers.push(
          <Feature
            onClick={() => { if (props.handleOnClick) props.handleOnClick(trailer); }}
            properties={trailerProperties}
            key={`${getAttribute(trailer, 'objectId', true)}-active`}
            coordinates={props.trailerPerUnitId[trailerUnitId].coordinate}
          />,
        );
      } else {
        trailerProperties.color = grayCalm;
        offDutyTrailers.push(
          <Feature
            onClick={() => { if (props.handleOnClick) props.handleOnClick(trailer); }}
            properties={trailerProperties}
            key={`${getAttribute(trailer, 'objectId', true)}-offduty`}
            coordinates={props.trailerPerUnitId[trailerUnitId].coordinate}
          />,
        );
      }
      // if uncommenting this block change the iteration function from .forEach to .map
      // if ( trailerIsActive ) {
      // return <Feature
      //         onClick={() => { if (props.handleOnClick) props.handleOnClick(trailer); }}
      //         properties={ trailerProperties }
      //         key={trailerUnitId + index}
      //         coordinates={ props.trailerPerUnitId[trailerUnitId]['coordinate'] }/>

      // } else {
      //   return <Feature
      //           onClick={() => { if (props.handleOnClick) props.handleOnClick(trailer); }}
      //           properties={{ 'title': trailerUnitId, 'color': '#00BCD4' }}
      //           key={trailerUnitId + index}
      //           coordinates={ props.trailerPerUnitId[trailerUnitId]['coordinate'] }/>
      // }
    });
    /**
     * Desired Layer Ordering (bottom to top)
     * Off Duty Trailers
     * Driving Trailers
     * Direction Trailers
     * Active Trailers
     * Idling Trailers
     * ...Vehicle Layers
     */
    return (
      <div className="trailer-layers">
        <Layer
          type="symbol"
          id="trailers-markers-offDutyTrailers"
          images={props.inactiveTrailerIcon}
          layout={{
            'icon-image': 'inactiveTrailerIcon',
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': ['format', ['get', 'title'], { 'font-scale': 0.7 }],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.6],
            'text-anchor': 'top',
          }}
          paint={{ 'text-color': ['get', 'color'], 'text-halo-color': '#fff', 'text-halo-width': 2 }}
        >
          {offDutyTrailers}
        </Layer>
        <Layer
          type="symbol"
          id="trailers-markers-isDrivingTrailers"
          images={props.directionVehicleGreenIcon}
          layout={{
            'icon-image': 'directionVehicleGreenIcon',
            'icon-rotate': ['get', 'rotate'],
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': ['format', ['get', 'title'], { 'font-scale': 0.7 }],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.6],
            'text-anchor': 'top',
          }}
          paint={{ 'text-color': ['get', 'color'], 'text-halo-color': '#fff', 'text-halo-width': 2 }}
        >
          {isDrivingTrailers}
        </Layer>
        <Layer
          type="symbol"
          id="trailers-markers-directionTrailers"
          images={props.directionVehicleGreenIcon}
          layout={{
            'icon-image': 'directionVehicleGreenIcon',
            'icon-rotate': ['get', 'rotate'],
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': ['format', ['get', 'title'], { 'font-scale': 0.7 }],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.6],
            'text-anchor': 'top',
          }}
          paint={{ 'text-color': ['get', 'color'], 'text-halo-color': '#fff', 'text-halo-width': 2 }}
        >
          {directionTrailers}
        </Layer>
        <Layer
          type="symbol"
          id="trailers-markers-activeTrailers"
          images={props.inactiveTrailerIcon}
          layout={{
            'icon-image': 'inactiveTrailerIcon',
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': ['format', ['get', 'title'], { 'font-scale': 0.7 }],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.6],
            'text-anchor': 'top',
          }}
          paint={{ 'text-color': ['get', 'color'], 'text-halo-color': '#fff', 'text-halo-width': 2 }}
        >
          {activeTrailers}
        </Layer>
        <Layer
          type="symbol"
          id="trailers-markers-isIdlingTrailers"
          images={props.directionVehicleYellowIcon}
          layout={{
            'icon-image': 'directionVehicleYellowIcon',
            'icon-allow-overlap': true,
            'text-allow-overlap': true,
            'text-field': ['format', ['get', 'title'], { 'font-scale': 0.7 }],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-offset': [0, 0.6],
            'text-anchor': 'top',
          }}
          paint={{ 'text-color': ['get', 'color'], 'text-halo-color': '#fff', 'text-halo-width': 2 }}
        >
          {isIdlingTrailers}
        </Layer>
      </div>
    );
  }

}

TrailersLayer.propTypes = {
  straightenGrayCalmIcon: PropTypes.array,
  straightenGreenActiveIcon: PropTypes.array,
  straightenMetalgreyIcon: PropTypes.array,
  straightenYellowTruckIcon: PropTypes.array,
  trailerPerUnitId: PropTypes.object,
  active: PropTypes.bool,
  company: PropTypes.object,
  displayMarkers: PropTypes.object,
  eldEvent: PropTypes.object,
  followCoordinates: PropTypes.array,
  handleOnClick: PropTypes.func,
  isTrailer: PropTypes.bool,
  isFollowActive: PropTypes.bool,
  trailers: PropTypes.array,
};

export default TrailersLayer;
