import React from 'react';
import PropTypes from 'prop-types';
import {
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBBtn,
  MDBInput,
  MDBRow,
  MDBCol,
} from 'mdbreact';

// Components
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import VehicleAutocomplete from 'components/Shared/VehicleAutocomplete/VehicleAutocomplete';
import TrailerAutocomplete from 'components/Shared/TrailerAutocomplete/TrailerAutocomplete';

class EquipmentGroupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: undefined,
      selectedVehicle: undefined,
      selectedTrailer: undefined,
      disableSubmit: true,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleClose() {
    const { props } = this;

    this.setState({
      name: undefined,
      selectedVehicle: undefined,
      selectedTrailer: undefined,
      disableSubmit: true,
    }, () => props.handleClose());
  }

  async handleSubmit() {
    const { props, state } = this;

    const group = {
      name: state.name,
      vehicle: state.selectedVehicle,
      trailer: state.selectedTrailer,
    };

    this.setState({
      name: undefined,
      selectedVehicle: undefined,
      selectedTrailer: undefined,
      disableSubmit: true,
    }, () => props.handleAdd(group));
  }

  render() {
    const { props, state } = this;

    return (
      <>
        <MDBModal isOpen={props.show} centered data-backdrop="static">
          <MDBModalHeader>
            Add group
          </MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol>
                <MDBInput
                  type="text"
                  label="Name"
                  containerClass="required"
                  labelClass="active"
                  value={state.name}
                  getValue={(name) => this.setState({
                    ...state,
                    name,
                    disableSubmit: name.length === 0,
                  })}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <VehicleAutocomplete
                  onVehicleSelected={(selectedVehicle) => this.setState({ selectedVehicle })}
                  allowDeselect
                />
              </MDBCol>
              <MDBCol>
                <TrailerAutocomplete
                  onTrailerSelected={(selectedTrailer) => this.setState({ selectedTrailer })}
                  allowDeselect
                />
              </MDBCol>
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            <ActionsContainer>
              <MDBBtn
                color="default"
                size="sm"
                onClick={this.handleClose}
              >
                Cancel
              </MDBBtn>
              <MDBBtn
                color="primary"
                size="sm"
                onClick={this.handleSubmit}
                disabled={state.disableSubmit}
              >
                Create
              </MDBBtn>
            </ActionsContainer>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

EquipmentGroupModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAdd: PropTypes.func.isRequired,
};

export default EquipmentGroupModal;
