import React from 'react';
import PropTypes from 'prop-types';

// Actions
import { updateDriverForState } from 'actions/Driver';

// Components
import { MDBBtn } from 'mdbreact';
import EditDriverDetailsModal from '../view/EditDriverDetailsModal';

class EditDriverDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editDriverModal: {
        show: false,
      },
      driverModuleObj: null,
      error: undefined,
    };
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.updateDriver = this.updateDriver.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.driver !== this.props.driver || nextState !== this.state) {
      return true;
    }
    return false;
  }

  showModal() {
    this.state.editDriverModal.show = true;
    this.setState(this.state);
  }

  closeModal() {
    this.state.editDriverModal.show = false;
    this.setState(this.state);
    this.props.handleClose();
  }

  updateDriver(driverInfo, sendTextToDriver) {
    const promise = new Promise(async (resolve, reject) => {
      const driverKeys = Object.keys(driverInfo);
      const driverKeysLen = driverKeys.length;
      if (driverKeysLen > 0) {
        // check drivers license first
        if (driverInfo['driversLicense']) {
          try {
            await updateDriverForState(this.props.driver, 'driversLicense', driverInfo['driversLicense']);
          } catch (err) {
            reject(err);
            throw new Error(err);
          }
        }

        for (let i = 0; i < driverKeysLen; i++) {
          const currentKey = driverKeys[i];
          if (currentKey === 'driversLicense') continue;
          updateDriverForState(this.props.driver, currentKey, driverInfo[currentKey], sendTextToDriver).then(() => {
            resolve();
          }, (error) => {
            console.log(error);
            const newState = { ...this.state };
            newState.error = error;
            this.setState(newState);
            reject(error);
          }
          );
        }
      }
      //
      // this.props.handleClose();
      // this.closeModal();

    });
    return promise;
  }

  render() {
    window.Localize.translatePage();
    return (
      <div className="inline float-right">
        {this.props.driverFetched &&
          <EditDriverDetailsModal
            show={this.state.editDriverModal.show}
            handleClose={this.closeModal}
            editDriverFormHandler={this.updateDriver}
            driver={this.props.driver}
            driverModuleObj={this.props.driverModule}
            dispatchers={this.props.dispatchers}
            error={this.state.error}
          />
        }
        <MDBBtn key="editDriverDetails" size="sm" onClick={this.showModal} color="primary" className="translate-me">Edit Driver</MDBBtn>
      </div>
    );
  }
}

EditDriverDetails.propTypes = {
  driver: PropTypes.object,
  driverFetched: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  dispatchers: PropTypes.array,
};

export default EditDriverDetails;
