/** @module DispatchVehicle */

import {
  addRecord, copyQuery, count, createQuery, createQueryOr, destroyRecord, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord,
} from 'sb-csapi/dist/AAPI';

import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @memberof module:DispatchVehicle
 *
 * @description Get DispatchVehicles according to filter criteria and a DispatchTransfer
 *
 * @param {object} options - See example
 * @param {string} dispatchTransferObjectId - DispatchTransfer we wish to retrieve dispatch vehicles for
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { object } - { totalDispatchVehiclesCount: Number, dispatchVehicles: [DispatchVehicle, ...] }
 */
async function getDispatchVehicles(options = { sessionToken: getCurrentUserSessionToken() }, dispatchTransferObjectId, filters = [], sortBy = new Sort(QuerySortOrder.ASCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  if (!dispatchTransferObjectId) new Error('No DispatchTransfer specified');

  let dispatchVehicleQuery = createQuery('DispatchVehicle');

  const _filters = [...filters];

  // set universal vehicle filters
  _filters.push(new Filter(QueryRestriction.EQUAL_TO, 'dispatchTransfer', dispatchTransferObjectId)); // set to specific dispatchTransfer

  _filters.map(filter => setQueryRestriction(dispatchVehicleQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  const dispatchVehicleCountQuery = copyQuery(dispatchVehicleQuery);

  // sort
  sortQuery(dispatchVehicleQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchVehicleQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchVehicleQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(dispatchVehicleQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(dispatchVehicleQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the count and the vehicles
  const promises = [count(options, dispatchVehicleCountQuery), findRecords(options, dispatchVehicleQuery, false, queryAll)];

  try {
    const [totalDispatchVehiclesCount, dispatchVehicles] = await Promise.all(promises);
    return { totalDispatchVehiclesCount, dispatchVehicles };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchVehicle
 * @description Add a new dispatch vehicle record
 *
 * @param {object} keyValueObj - The selected fields we would like to save, should be a dispatchTransfer and vehicle object
 */
async function addDispatchVehicle(keyValueObj) {
  try {
    const dispatchVehicle = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'DispatchVehicle', keyValueObj, getCurrentUserCompanyObjectId());
    return dispatchVehicle;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchVehicle
 * @description Remove a dispatch vehicle record and return the last version of it
 *
 * @param {object} record - The dispatch vehicle record we would like to remove
 */
async function removeDispatchVehicle(record) {
  try {
    const dispatchVehicle = await destroyRecord({ sessionToken: getCurrentUserSessionToken() }, record);
    return dispatchVehicle;
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getDispatchVehicles,
  addDispatchVehicle,
  removeDispatchVehicle,
};
