import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';

// api
import * as PDF from 'api/PDFer';
import * as Helpers from 'api/Helpers';
import { getAttribute } from 'api/Parse';
import { getVehicleLocations } from 'api/VehicleLocation/VehicleLocation';

// enums
import { QueryRestrictionTypes } from 'enums/Query';

import { LengthUnit } from 'sb-csapi/dist/enums/Unit';

// sbObjects
import Filter from 'sbObjects/Filter';

// components
import SBTable from 'components/Shared/SBTable/SBTable';
import DateTimePicker from 'components/Shared/DateTimePicker/DateTimePicker';
import VehicleAutocomplete from 'components/Shared/VehicleAutocomplete/VehicleAutocomplete';
import SBCheckbox from 'components/Shared/SBCheckbox/SBCheckbox';
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import Title from 'components/Shared/Title/Title';

import DistanceUnitDropdown from 'sbCore/DistanceUnitDropdown/DistanceUnitDropdown';

// css
import './styles.scss';


class SpeedReportLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedVehicle: undefined,
      isDateRange: false,
      selectedDate: undefined,
      selectedStartDate: undefined,
      selectedEndDate: undefined,
      isPrintDisabled: true,
      printInProgress: false,
      vehicleLocations: [],
      distanceUnit: { short: LengthUnit.KM }, // pseudo unit dropdown object
    };

    this.handlePrint = this.handlePrint.bind(this);
    this.onSelectDistanceUnit = this.onSelectDistanceUnit.bind(this);
  }

  async handlePrint() {
    const { state } = this;

    // fetch vehicle location records
    const filters = [];

    let startDate;
    let endDate;

    if (state.isDateRange) {
      startDate = state.selectedStartDate;
      endDate = new Date(state.selectedEndDate.getTime() + (24 * 60 * 60 * 1000));
    } else {
      startDate = state.selectedDate;
      endDate = new Date(state.selectedDate.getTime() + (24 * 60 * 60 * 1000));
    }

    const vehicleUnitId = getAttribute(state.selectedVehicle, 'unitId');

    filters.push(
      new Filter('vehicleUnitId', vehicleUnitId, QueryRestrictionTypes.EQUAL_TO),
      new Filter('dateTime', startDate, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO),
      new Filter('dateTime', endDate, QueryRestrictionTypes.LESS_THAN),
    );

    const { vehicleLocations } = await getVehicleLocations(
      undefined,
      undefined,
      undefined,
      filters,
      true,
    );

    // generate speed report pdf
    this.setState({ printInProgress: true, vehicleLocations });

    const reportHTML = document.querySelector('.speed-report-printable');
    const reportName = 'Speed Report';

    PDF.generateTempPDFFromHTML(reportHTML, reportName, undefined).then(tempFile => {
      const reportURL = tempFile.get('file')._url;

      Helpers.openDocumentLink(reportURL);

      this.setState({ printInProgress: false });
    });
  }

  handlePrintDisabled() {
    const { state } = this;

    let isPrintDisabled = state.isPrintDisabled;

    if (state.isDateRange && state.selectedVehicle && state.selectedStartDate && state.selectedEndDate) {
      isPrintDisabled = false;
    } else if (!state.isDateRange && state.selectedVehicle && state.selectedDate) {
      isPrintDisabled = false;
    } else {
      isPrintDisabled = true;
    }

    this.setState({ isPrintDisabled });
  }

  onSelectDistanceUnit(unit) {
    this.setState({ distanceUnit: unit });
  }

  render() {
    const { state } = this;

    const tableHeaderStyles = {
      date: { width: '20%' },
      time: { width: '20%' },
      location: { width: '20%' },
      speed: { width: '20%' },
      truck: { width: '20%' },
    };

    const tableHeaderRows = [{
      key: 'sb-speed-report',
      columns: [
        {
          element: <div>Date</div>,
          props: { style: tableHeaderStyles.date },
        },
        {
          element: <div>Time</div>,
          props: { style: tableHeaderStyles.time },
        },
        {
          element: <div>Location</div>,
          props: { style: tableHeaderStyles.location }
        },
        {
          element: <div>Speed ({(state.distanceUnit.short === LengthUnit.KM) ? 'km/h' : 'mi/h'})</div>,
          props: { style: tableHeaderStyles.speed },
        },
        {
          element: <div>Truck</div>,
          props: { style: tableHeaderStyles.truck },
        },
      ],
    }];

    const tableBodyRows = state.vehicleLocations.map(vehicleLocation => {
      const vehicleLocationObjectId = getAttribute(vehicleLocation, 'objectId');

      const dateTime = getAttribute(vehicleLocation, 'dateTime');
      const date = moment(dateTime).format('MMMM DD, YYYY');
      const time = moment(dateTime).format('HH:mm');

      const location = getAttribute(vehicleLocation, 'locationDescriptionCA');

      let speedKm = getAttribute(vehicleLocation, 'speedKm');

      // if miles is selected, we convert
      if (state.distanceUnit.short === LengthUnit.MI) {
        speedKm = speedKm * state.distanceUnit.kmToMiConversion;
        speedKm = parseFloat(speedKm).toFixed(2);
      }

      const vehicle = getAttribute(vehicleLocation, 'vehicle');
      const unitId = getAttribute(vehicle, 'unitId');

      return {
        key: vehicleLocationObjectId,
        columns: [
          {
            element: <b>{ date }</b>,
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          {
            element: <b>{ time }</b>,
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          {
            element: <b>{ location }</b>,
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          {
            element: <b>{ speedKm }</b>,
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          {
            element: <b>{ unitId }</b>,
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
        ],
      };
    });

    return (
      <MDBContainer className="safety-speed-report" fluid>
        <MDBRow>
          <MDBCol>
            <Title title="Speed Report" />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <VehicleAutocomplete
              onVehicleSelected={(vehicle) => this.setState({ selectedVehicle: vehicle }, () => this.handlePrintDisabled())}
              allowDeselect
              placeholder={state.selectedVehicle ? getAttribute(state.selectedVehicle, 'unitId') : ''}
            />
          </MDBCol>

          {!state.isDateRange && (
            <MDBCol>
              <DateTimePicker
                label="Date"
                onDateChange={(date) => this.setState({ selectedDate: date }, () => this.handlePrintDisabled())}
                selectedDate={state.selectedDate}
                shouldCloseOnSelect
              />
            </MDBCol>
          )}

          {state.isDateRange && (
            <>
              <MDBCol>
                <DateTimePicker
                  label="Start Date"
                  onDateChange={(date) => this.setState({ selectedStartDate: date }, () => this.handlePrintDisabled())}
                  selectedDate={state.selectedStartDate}
                  shouldCloseOnSelect
                />
              </MDBCol>
              <MDBCol>
                <DateTimePicker
                  label="End Date"
                  onDateChange={(date) => this.setState({ selectedEndDate: date }, () => this.handlePrintDisabled())}
                  selectedDate={state.selectedEndDate}
                  shouldCloseOnSelect
                />
              </MDBCol>
            </>
          )}

          <MDBCol>
            <ActionsContainer>
              {/*
                <SBCheckbox
                  className="speed-report-date-range-checkbox"
                  onChange={() => this.setState({ isDateRange: !state.isDateRange, selectedDate: undefined, selectedStartDate: undefined, selectedEndDate: undefined }, () => this.handlePrintDisabled())}
                  checked={state.isDateRange}
                />
                <div className="speed-report-date-range-checkbox">Date Range</div>
              */}

              <DistanceUnitDropdown
                className="inline-block"
                unit={state.distanceUnit.short}
                onSelect={(distanceUnit) => this.onSelectDistanceUnit(distanceUnit)}
                variant="small"
              />

              <MDBBtn
                className="inline-block"
                size="sm"
                color="primary"
                onClick={async () => await this.handlePrint()}
                disabled={state.isPrintDisabled}
              >
                Print
              </MDBBtn>
            </ActionsContainer>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol>
            <div className="speed-report-printable">
              <SBTable
                hover
                tableHeaderRows={tableHeaderRows}
                tableBodyRows={tableBodyRows}
                emptyTableMessage={`Empty Speed Report`}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

SpeedReportLayout.propTypes = {
  location: PropTypes.object,
};

export default SpeedReportLayout;
