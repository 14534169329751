import React from 'react';
import uniqid from 'uniqid';
import moment from 'moment-timezone';
import Blur from 'react-css-blur';
import Pagination from 'react-js-pagination';

// MDB
import { MDBContainer, MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardText, MDBRow, MDBCol } from 'mdbreact';

// Components
import AddEditRouteCard from 'components/IFTARoute/view/AddEditRouteCard';
import AddEditRouteModalTablePreview from 'components/IFTARoute/view/AddEditRouteModalTablePreview';

// API
import { distanceBetweenTwoGeopoints } from 'api/Mapbox';
import { createTempRecord, getCurrentUser, getAttribute, updateRecord, addRecord, createTempPointer, createQuery, setQueryRestriction, find, cloneRecord } from 'api/Parse';
import { getLocationDescriptionBreakdown, getVehicleLocationDescription } from 'api/VehicleLocation/VehicleLocation';
import { getFuelCardData, migrateIFTARoute } from 'api/IFTARoute/IFTARoute.old';

// Helpers
import * as Helpers from 'api/Helpers';

// Enums
import { QueryRestrictionTypes } from 'enums/Query';
import { LocationDirectionTypes, LocationDirectionTypeDescription } from 'enums/LocationDirection';

// CSS
import './style.scss';

class AddEditRouteModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      iftaRouteCardHash: {},
      disableConfirmButton: true,
      disableConfirmEditButton: true,
      isLoading: false,
      fuelPurchaseHash: {},
      iftaRouteRecordsForTablePreview: [...props.iftaRouteRecordsForTablePreview],
      cardToDisplay: undefined,
      activePage: 1,
      autoScrollOnMount: true,
    };

    this.addNewIFTARoute = this.addNewIFTARoute.bind(this);
    this.convertDistanceForLocationBreakdown = this.convertDistanceForLocationBreakdown.bind(this);
    this.deleteRoute = this.deleteRoute.bind(this);
    this.updateRouteInformationForIFTARouteCardHash = this.updateRouteInformationForIFTARouteCardHash.bind(this);
    this.confirmAllRoutes = this.confirmAllRoutes.bind(this);
    this.addNewEditIFTARoute = this.addNewEditIFTARoute.bind(this);
    this.shouldCloseModal = this.shouldCloseModal.bind(this);
    this.createOrUpdateIFTARoute = this.createOrUpdateIFTARoute.bind(this);
    this.displaySelectedCardAndTablePreview = this.displaySelectedCardAndTablePreview.bind(this);
    this.updateRowInTablePreview = this.updateRowInTablePreview.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.updateFuelPurchasesForIFTARoute = this.updateFuelPurchasesForIFTARoute.bind(this);
    this.migrateIFTARouteToVehicle = this.migrateIFTARouteToVehicle.bind(this);
  }

  componentDidMount() {
    if (this.props.isEdit) {
      this.addNewEditIFTARoute(this.props.iftaRoute);
    } else {
      this.addNewIFTARoute();
    }
  }

  /**
   * @description Auto scrolls to the route that the client is editing
   * @param {*} e Unused parameter
   * @param {*} anchorTargets Array of keys (_localIds) from the iftaRoutes within the table preview
   * @param {*} key The key of the iftaRoute we want to auto-scroll to
   * @param {*} enableAutoScroll Boolean to explicitly tell the function to scroll to the corresponding row in the table preview
   */
  handleScroll(e, anchorTargets, key, enableAutoScroll = false) {
    const { state, props } = this;

    if (anchorTargets[0] === undefined) return;

    // We need to grab the row element, hence we retrieve the grandparent element
    if (!document.getElementById(key)) return;
    const firstElement = document.getElementById(key).parentElement.parentElement;

    // Auto scrolling after every edit made it hard for the clients to properly edit their routes
    // We now only auto scroll if enableAutoScroll is explicitly set to true or if the client switches to a new route
    if (enableAutoScroll || state.autoScrollOnMount) {
      if (e) e.preventDefault();
      setTimeout(() => {
        // element.classList.toggle('error-alert');
        // hasGrayBg && element.classList.toggle('bg-gray-hover');
        // element.classList.toggle('hover-gray-matte');
        firstElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }, 500);
    }


    // Toggle the classes to temporarily remove styling (workaround due to the classes using !important)
    anchorTargets.forEach((anchorTarget) => {
      const element = document.getElementById(anchorTarget).parentElement.parentElement;
      const hasGrayBg = element.classList.contains('bg-gray-hover');

      if (key === anchorTarget) {
        element.classList.add('route-preview-highlight');
        hasGrayBg && element.classList.toggle('bg-gray-hover');
        element.classList.toggle('hover-gray-matte');
      } else {
        element.classList.remove('route-preview-highlight');
        hasGrayBg && element.classList.toggle('bg-gray-hover');
        element.classList.toggle('hover-gray-matte');
      }


      // setTimeout(() => {
      //   element.classList.toggle('error-alert');
      //   hasGrayBg && element.classList.toggle('bg-gray-hover');
      //   element.classList.toggle('hover-gray-matte');
      // }, 3000);
    });

    this.setState({ ...this.state, autoScrollOnMount: false, })
  }

  /**
   * @description Allows a client to edit a pre-exisitng route by auto-filling the modal fields
   * @param {Parse Record} iftaRoute The iftaRoute record passed in that the client wants to edit
   */
  async addNewEditIFTARoute(iftaRoute) {
    const { props } = this;
    const newState = { ...this.state };
    newState.iftaRouteCardHash = { ...this.state.iftaRouteCardHash };
    newState.fuelPurchaseHash = { ...this.state.fuelPurchaseHash };
    newState.cardToDisplay = { ...this.state.cardToDisplay };
    newState.activePage = { ...this.state.cardToDisplay };
    newState.iftaRouteRecordsForTablePreview = this.state.iftaRouteRecordsForTablePreview;

    // Display spinner to signify that content is loading
    this.setState({ ...this.state, isLoading: true });

    // Obtain all information required from iftaRoute client has chosen
    const objectId = getAttribute(iftaRoute, 'objectId');
    const vehicleLocationStart = getAttribute(iftaRoute, 'vehicleLocationStart');
    const startLocationDescriptionCA = getAttribute(vehicleLocationStart, 'locationDescriptionCA');
    const startLocationDescriptionUS = getAttribute(vehicleLocationStart, 'locationDescriptionCA');
    const vehicleLocationEnd = getAttribute(iftaRoute, 'vehicleLocationEnd');
    const endLocationDescriptionCA = getAttribute(vehicleLocationEnd, 'locationDescriptionCA');
    const endLocationDescriptionUS = getAttribute(vehicleLocationEnd, 'locationDescriptionCA');
    const iftaRouteDriverPeriods = getAttribute(iftaRoute, 'iftaRouteDriverPeriods');
    const startAprxShortName = getAttribute(vehicleLocationStart, 'aprxShortName');
    const endAprxShortName = getAttribute(vehicleLocationEnd, 'aprxShortName');
    const fuelPurchases = getAttribute(iftaRoute, 'fuelPurchases');
    const dateStart = getAttribute(iftaRoute, 'dateStart');
    const dateEnd = getAttribute(iftaRoute, 'dateEnd');
    const startLocation = getAttribute(vehicleLocationStart, 'location');
    const endLocation = getAttribute(vehicleLocationEnd, 'location');
    const totalVehicleKmStart = getAttribute(iftaRoute, 'totalVehicleKmStart');
    const totalVehicleKmEnd = getAttribute(iftaRoute, 'totalVehicleKmEnd');

    // No longer need to query for iftaRoute since we pass it in
    // // Query for IFTARoute
    // this.setState({ ...this.state, isLoading: true });
    // const iftaRouteQuery = createQuery('IFTARoute_Beta');
    // setQueryRestriction(iftaRouteQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', objectId);
    // const iftaRouteQueryResult = await find(iftaRouteQuery, true, false);

    // Break location descriptions down into their parts
    const startLocationBreakdownCA = getLocationDescriptionBreakdown(startLocationDescriptionCA);
    const startLocationBreakdownUS = getLocationDescriptionBreakdown(startLocationDescriptionUS);
    const endLocationBreakdownCA = getLocationDescriptionBreakdown(endLocationDescriptionCA);
    const endLocationBreakdownUS = getLocationDescriptionBreakdown(endLocationDescriptionUS);

    // Determine distances and heading
    let startDistance;
    let startHeadingValue;
    let endDistance;
    let endHeadingValue;
    if (props.distanceUnitFilter === 'km') {
      startDistance = startLocationBreakdownCA.distance;
      startHeadingValue = startLocationBreakdownCA.heading.value;
      endDistance = endLocationBreakdownCA.distance;
      endHeadingValue = endLocationBreakdownCA.heading.value;
    } else {
      startDistance = startLocationBreakdownUS.distance;
      startHeadingValue = startLocationBreakdownUS.heading.value;
      endDistance = endLocationBreakdownUS.distance;
      endHeadingValue = endLocationBreakdownUS.heading.value;
    }

    // Obtain driver information
    const driverForRoute = undefined;
    let driverQueryResult;
    if (iftaRouteDriverPeriods && iftaRouteDriverPeriods.length > 0) {
      if (getAttribute(iftaRouteDriverPeriods[0], 'driver')) {
        const currentDriver = getAttribute(iftaRouteDriverPeriods[0], 'driver');
        const driverQuery = createQuery('Driver');
        setQueryRestriction(driverQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', getAttribute(currentDriver, 'objectId'));
        driverQueryResult = await find(driverQuery, true, false);
      }
    }

    // Obtain city name in similar fashion to view by day
    let startVehicleLocationAprxShortName = startAprxShortName;
    let endVehicleLocationAprxShortName = endAprxShortName;
    if (!startVehicleLocationAprxShortName) startVehicleLocationAprxShortName = startLocationBreakdownCA.city;
    if (!endVehicleLocationAprxShortName) endVehicleLocationAprxShortName = endLocationBreakdownCA.city;

    // Obtain locationDescription from start vehicleLocation
    const startCustomLocation = getVehicleLocationDescription(
      startLocationBreakdownCA.city,
      startLocationBreakdownCA.stateProvince.code,
      startDistance,
      props.distanceUnitFilter.value.toUpperCase(),
      startHeadingValue,
    );

    // Obtain locationDescription from end vehicleLocation
    const endCustomLocation = getVehicleLocationDescription(
      endLocationBreakdownCA.city,
      endLocationBreakdownCA.stateProvince.code,
      endDistance,
      props.distanceUnitFilter.value.toUpperCase(),
      endHeadingValue,
    );

    // Iterate through fuelPurchases and populate information
    for (let i = 0; i < fuelPurchases.length; i++) {
      const currentFuelPurchase = fuelPurchases[i];
      const purchaseInformation = {
        date: getAttribute(currentFuelPurchase, 'timeMillis'),
        volumeUnits: getAttribute(currentFuelPurchase, 'volumeUnits'),
        volumePumped: getAttribute(currentFuelPurchase, 'volumePumped'),
        fuelType: getAttribute(currentFuelPurchase, 'fuelType'),
        currency: getAttribute(currentFuelPurchase, 'currency'),
        totalPaid: getAttribute(currentFuelPurchase, 'totalPaid'),
        city: '',
        stateProvince: getAttribute(currentFuelPurchase, 'stateProvince'),
        fuelCardFourDigits: '1000',
        allFieldsValid: false,
        errorHash: {
          fuelInformationIsValid: {
            bool: true,
            explanation: undefined,
          },
          paymentInformationIsValid: {
            bool: true,
            explanation: undefined,
          },
          locationIsValid: {
            bool: true,
            explanation: undefined,
          },
          dateIsValid: {
            bool: true,
            explanation: undefined,
          },
        },
      };

      // Create entry for fuelPurchase in fuelPurchaseHash
      const uniqId = uniqid();
      newState.fuelPurchaseHash[uniqId] = {
        fuelPurchase: currentFuelPurchase,
        purchaseInformation,
      };
    }

    // Pre-Populate iftaRouteCard Fields
    const routeInformation = {
      startDate: dateStart,
      endDate: dateEnd,
      errorHash: {
        datesAreValid: {
          bool: true,
        },
        driverAndFuelAreValid: true,
        routesAreValid: {
          bool: true,
        },
        startAndEndLocationsAreValid: true,
        startAndEndOdometerReadingsAreValid: {
          bool: true,
        },
        startAndEndStateProvincesAreValid: {
          bool: true,
        },
      },
      customLocationBuilder: {
        startCustomLocationObject: {
          location: startLocation,
          distance: startDistance,
          distanceUnit: props.distanceUnitFilter.value.toUpperCase(),
          heading: {
            type: LocationDirectionTypes.N,
            value: startHeadingValue,
          },
          city: startVehicleLocationAprxShortName,
          stateProvince: {
            code: startLocationBreakdownCA.stateProvince.code,
          },
        },
        endCustomLocationObject: {
          location: endLocation,
          distance: endDistance,
          distanceUnit: props.distanceUnitFilter.value.toUpperCase(),
          heading: {
            type: LocationDirectionTypes.N,
            value: endHeadingValue,
          },
          city: endVehicleLocationAprxShortName,
          stateProvince: {
            code: endLocationBreakdownCA.stateProvince.code,
          },
        },
        isManuallyEntered: false,
      },
      editInfo: {
        startCustomLocation,
        endCustomLocation,
      },
      driver: driverQueryResult ? driverQueryResult : driverForRoute,
      odometerReadings: {
        odometerStart: totalVehicleKmStart,
        odometerEnd: totalVehicleKmEnd,
        unit: props.distanceUnitFilter.value.toUpperCase(),
      },
      unit: props.distanceUnitFilter.value.toUpperCase(),
      fuelPurchaseHash: newState.fuelPurchaseHash,
      inputtedOdometerStart: this.props.distanceUnitFilter.value === "mi" ? Helpers.convertDistance(totalVehicleKmStart, 'km', 'mi').toFixed(0) : totalVehicleKmStart.toFixed(0),
      inputtedOdometerEnd: this.props.distanceUnitFilter.value === "mi" ? Helpers.convertDistance(totalVehicleKmEnd, 'km', 'mi').toFixed(0) : totalVehicleKmEnd.toFixed(0),
    };

    const tempIFTARouteRecord = cloneRecord(iftaRoute);

    // Create IFTARouteCardHash entry to edit IFTARoute
    const uniqId = uniqid();
    newState.iftaRouteCardHash[uniqId] = {
      iftaRoute: tempIFTARouteRecord,
      routeInformation,
    };

    // Display the card on the modal
    newState.cardToDisplay = (
      <AddEditRouteCard
        key={uniqId}
        keyId={uniqId}
        iftaRoute={newState.iftaRouteCardHash[uniqId]}
        deleteRoute={(keyForRoute) => this.deleteRoute(keyForRoute)}
        updateRouteInformationForIFTARouteCardHash={(routeInfo) => { this.updateRouteInformationForIFTARouteCardHash(uniqId, routeInfo); }}
        isEdit={props.isEdit}
        distanceUnit={props.distanceUnitFilter.value}
        unitId={props.unitId}
        migrateRouteFunction={(vehicle) => this.migrateIFTARouteToVehicle(props.iftaRoute, vehicle)}
      />
    );
    newState.activePage = Object.entries(newState.iftaRouteCardHash).length;

    // Disable spinner to signify that content has loaded
    newState.isLoading = false;

    // Replace iftaRoute in table preview array with new temp iftaRoute record
    const iftaRouteToEditObjectId = getAttribute(iftaRoute, 'objectId');
    for (let i = 0; i < newState.iftaRouteRecordsForTablePreview.length; i++) {
      const currentIFTARouteRecord = newState.iftaRouteRecordsForTablePreview[i];
      if (getAttribute(currentIFTARouteRecord, 'objectId') === iftaRouteToEditObjectId) {
        newState.iftaRouteRecordsForTablePreview[i] = tempIFTARouteRecord;
      }
    }

    const tempIFTARoutesIds = Object.values(newState.iftaRouteCardHash).map((tempIFTARouteObj) => tempIFTARouteObj.iftaRoute._localId);
    const currentIFTARouteId = newState.iftaRouteCardHash[uniqId].iftaRoute._localId;

    this.setState(newState, () => {
      this.updateRouteInformationForIFTARouteCardHash(uniqId, this.state.iftaRouteCardHash[`${uniqId}`].routeInformation);
      this.handleScroll(undefined, tempIFTARoutesIds, currentIFTARouteId);
    });
  }

  /**
   * @description Allows a client to add a new iftaRoute
   */
  addNewIFTARoute() {
    const newState = { ...this.state };
    newState.iftaRouteCardHash = { ...this.state.iftaRouteCardHash };
    newState.cardToDisplay = { ...this.state.cardToDisplay };
    newState.fuelPurchaseHash = { ...this.state.fuelPurchaseHash };
    newState.activePage = { ...this.state.activePage };
    newState.iftaRouteRecordsForTablePreview = this.state.iftaRouteRecordsForTablePreview;

    // Obtain company objectId
    const currentUser = getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
    let companyObjectId = getAttribute(belongsToCompany, 'objectId');
    companyObjectId = createTempPointer('Company', companyObjectId);

    // Create temporary vehicleLocation record for start of IFTARoute
    const tempVehicleLocationStartRecord = createTempRecord(
      'VehicleLocation',
      {
        stateCodeMobile: undefined,
        stateProvince: undefined,
        belongsToCompany: companyObjectId,
        dateTime: undefined,
      },
    );

    // Create temporary vehicleLocation record for end of IFTARoute
    const tempVehicleLocationEndRecord = createTempRecord(
      'VehicleLocation',
      {
        stateCodeMobile: undefined,
        stateProvince: undefined,
        belongsToCompany: companyObjectId,
        dateTime: undefined,
      },
    );

    // Create temporary iftaRoute record for card created by client
    const tempIFTARouteRecord = createTempRecord(
      'IFTARoute_Beta',
      {
        vehicleLocationStart: tempVehicleLocationStartRecord,
        vehicleLocationEnd: tempVehicleLocationEndRecord,
        belongsToCompany: companyObjectId,
        vehicleUnitId: this.props.unitId,
        totalVehicleKmStart: 0,
        totalVehicleKmEnd: 0,
        totalVehicleKmDiff: 0,
        distanceKm: 0,
        savedVehicleKm: 0,
        iftaRouteDriverPeriods: 'undefined',
        fuelPurchases: [],
        dateStart: undefined,
        dateEnd: undefined,
      },
    );

    // Create new entry in hash for this iftaRoute
    const uniqId = uniqid();
    newState.iftaRouteCardHash[uniqId] = {
      iftaRoute: tempIFTARouteRecord,
      routeInformation: {
        errorHash: {
          datesAreValid: {
            bool: undefined,
            explanation: undefined,
          },
          routesAreValid: {
            bool: undefined,
            explanation: undefined,
          },
          startAndEndLocationsAreValid: undefined,
          startAndEndDistancesAreValid: undefined,
          startAndEndStateProvincesAreValid: {
            bool: undefined,
            explanation: undefined,
          },
          startAndEndOdometerReadingsAreValid: {
            bool: undefined,
            explanation: undefined,
          },
          driverAndFuelAreValid: undefined,
        },
        fuelPurchaseHash: {},
        startMileageLocation: undefined,
        endMileageLocation: undefined,
        allFieldsValid: false,
        startDate: undefined,
        endDate: undefined,
        customLocationBuilder: {
          startCustomLocationObject: {
            location: undefined,
            distance: undefined,
            distanceUnit: this.props.distanceUnitFilter.value.toUpperCase(),
            heading: {
              type: LocationDirectionTypes.N,
              value: LocationDirectionTypes.N,
            },
            city: '',
            stateProvince: {
              code: undefined,
            }
          },
          endCustomLocationObject: {
            location: undefined,
            distance: undefined,
            distanceUnit: this.props.distanceUnitFilter.value.toUpperCase(),
            heading: {
              type: LocationDirectionTypes.N,
              value: LocationDirectionTypes.N,
            },
            city: '',
            stateProvince: {
              code: undefined,
            }
          },
          isManuallyEntered: false,
        },
        driver: undefined,
        odometerReadings: {
          odometerStart: 0,
          odometerEnd: 0,
          unit: this.props.distanceUnitFilter.value.toUpperCase(),
        },
        unit: this.props.distanceUnitFilter.value.toUpperCase(),
        mileageCalculatorDistance: ` `,
      },
    };

    // Push new iftaRoute into records we want to display for table preview
    newState.iftaRouteRecordsForTablePreview.push(tempIFTARouteRecord);

    // Display the card on the modal with corresponding row in table preview
    newState.cardToDisplay =
      <AddEditRouteCard
        key={uniqId}
        keyId={uniqId}
        iftaRoute={newState.iftaRouteCardHash[uniqId]}
        deleteRoute={(keyForRoute) => this.deleteRoute(keyForRoute)}
        updateRouteInformationForIFTARouteCardHash={(routeInformation) => { this.updateRouteInformationForIFTARouteCardHash(uniqId, routeInformation) }}
        isEdit={this.props.isEdit}
        distanceUnit={this.props.distanceUnitFilter.value}
        unitId={this.props.unitId}
      />;
    newState.activePage = Object.entries(newState.iftaRouteCardHash).length;

    // const tempIFTARoutesLocalIds = Object.entries(newState.iftaRouteCardHash).map((tempIFTARouteObj) => tempIFTARouteObj.iftaRoute).map((iftaRoute) => iftaRoute._localId);
    const tempIFTARoutesIds = Object.values(newState.iftaRouteCardHash).map((tempIFTARouteObj) => tempIFTARouteObj.iftaRoute._localId);
    const currentIFTARouteId = newState.iftaRouteCardHash[uniqId].iftaRoute._localId;

    // Update the state with our changes
    this.setState(newState, () => {
      this.updateRouteInformationForIFTARouteCardHash(uniqId, this.state.iftaRouteCardHash[`${uniqId}`].routeInformation);
      this.handleScroll(undefined, tempIFTARoutesIds, currentIFTARouteId);
    });
  }

  /**
   * @description Uses information inputted by client to automatically update the corresponding row in the table preview
   * @param key The key in the hash of the iftaRoute we want to update
   */
  updateRowInTablePreview(key) {
    const newState = { ...this.state };
    newState.iftaRouteCardHash = { ...this.state.iftaRouteCardHash };
    newState.iftaRouteRecordsForTablePreview = this.state.iftaRouteRecordsForTablePreview;

    // Obtain company objectId
    const currentUser = getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
    let companyObjectId = getAttribute(belongsToCompany, 'objectId');
    companyObjectId = createTempPointer('Company', companyObjectId);

    // Obtain the iftaRoute and corresponding information that matches the key for the route the client updated
    const selectedIFTARouteCard = newState.iftaRouteCardHash[key];
    const selectedIFTARouteRecord = selectedIFTARouteCard.iftaRoute;
    const selectedStartVehicleLocationRecord = getAttribute(selectedIFTARouteRecord, 'vehicleLocationStart');
    const selectedEndVehicleLocationRecord = getAttribute(selectedIFTARouteRecord, 'vehicleLocationEnd');
    const selectedRouteInformation = selectedIFTARouteCard.routeInformation;
    const { customLocationBuilder, driver, endDate, fuelPurchaseHash, odometerReadings, startDate, unit, editInfo, enableAutoScroll } = selectedRouteInformation;

    // Determine index for route we want to replace in table preview array
    let indexInPreviewArray;
    for (let i = 0; i < newState.iftaRouteRecordsForTablePreview.length; i++) {
      if (newState.iftaRouteRecordsForTablePreview[i] === selectedIFTARouteRecord) {
        indexInPreviewArray = i;
        break;
      }
    }

    // Update start vehicleLocation record for table preview
    updateRecord(
      selectedStartVehicleLocationRecord,
      {
        stateCodeMobile: customLocationBuilder.startCustomLocationObject.stateProvince.code ? customLocationBuilder.startCustomLocationObject.stateProvince.code : 'undefined',
        stateProvince: customLocationBuilder.startCustomLocationObject.stateProvince.code ? customLocationBuilder.startCustomLocationObject.stateProvince.code.toLowerCase() : 'undefined',
        belongsToCompany: companyObjectId,
        dateTime: startDate,
        aprxShortName: customLocationBuilder.startCustomLocationObject.city ? customLocationBuilder.startCustomLocationObject.city : 'undefined',
      },
      false,
    );

    // Update end vehicleLocation record for table preview
    updateRecord(
      selectedEndVehicleLocationRecord,
      {
        stateCodeMobile: customLocationBuilder.endCustomLocationObject.stateProvince.code ? customLocationBuilder.endCustomLocationObject.stateProvince.code : 'undefined',
        stateProvince: customLocationBuilder.endCustomLocationObject.stateProvince.code ? customLocationBuilder.endCustomLocationObject.stateProvince.code.toLowerCase() : 'undefined',
        belongsToCompany: companyObjectId,
        dateTime: endDate,
        aprxShortName: customLocationBuilder.endCustomLocationObject.city ? customLocationBuilder.endCustomLocationObject.city : 'undefined',
      },
      false,
    );

    // Obtain locationDescription for start and end vehicleLocations from routeInformation
    const locationBreakdownForStartVehicleLocation = editInfo ? editInfo.startCustomLocation : 'undefined';
    const locationBreakdownForEndVehicleLocation = editInfo ? editInfo.endCustomLocation : 'undefined';

    // Convert units for start and end vehicleLocation records
    let odometerStart = odometerReadings ? odometerReadings.odometerStart : "0";
    let odometerEnd = odometerReadings ? odometerReadings.odometerEnd : "0";

    if (unit === 'KM') {
      const convertedStartVehicleLocationDescription = locationBreakdownForStartVehicleLocation ? this.convertDistanceForLocationBreakdown(locationBreakdownForStartVehicleLocation, 'km', 'mi') : 'undefined';
      const convertedEndVehicleLocationDescription = locationBreakdownForEndVehicleLocation ? this.convertDistanceForLocationBreakdown(locationBreakdownForEndVehicleLocation, 'km', 'mi') : 'undefined';
      updateRecord(selectedStartVehicleLocationRecord, { locationDescriptionCA: locationBreakdownForStartVehicleLocation, locationDescriptionUS: convertedStartVehicleLocationDescription }, false);
      updateRecord(selectedEndVehicleLocationRecord, { locationDescriptionCA: locationBreakdownForEndVehicleLocation, locationDescriptionUS: convertedEndVehicleLocationDescription }, false);
    } else if (unit === 'MI') {
      const convertedStartVehicleLocationDescription = locationBreakdownForStartVehicleLocation ? this.convertDistanceForLocationBreakdown(locationBreakdownForStartVehicleLocation, 'mi', 'km') : 'undefined';
      const convertedEndVehicleLocationDescription = locationBreakdownForEndVehicleLocation ? this.convertDistanceForLocationBreakdown(locationBreakdownForEndVehicleLocation, 'km', 'mi') : 'undefined';
      updateRecord(selectedStartVehicleLocationRecord, { locationDescriptionUS: locationBreakdownForStartVehicleLocation, locationDescriptionCA: convertedStartVehicleLocationDescription }, false);
      updateRecord(selectedEndVehicleLocationRecord, { locationDescriptionUS: locationBreakdownForEndVehicleLocation, locationDescriptionCA: convertedEndVehicleLocationDescription }, false);
    }

    // Update fuelPurchases if any
    let fuelPurchases = [];
    for (const key in fuelPurchaseHash) {
      fuelPurchases.push(fuelPurchaseHash[key].fuelPurchase);
    }

    // Update temporary iftaRoute record for table preview
    updateRecord(
      selectedIFTARouteRecord,
      {
        vehicleLocationStart: selectedStartVehicleLocationRecord,
        stateProvince: customLocationBuilder.startCustomLocationObject.stateProvince.code ? customLocationBuilder.startCustomLocationObject.stateProvince.code.toLowerCase() : "undefined",
        vehicleUnitId: this.props.unitId,
        dateStart: startDate,
        dateEnd: endDate,
        belongsToCompany: companyObjectId,
        totalVehicleKmEnd: parseFloat(odometerEnd),
        isHidden: undefined,
        totalVehicleKmStart: parseFloat(odometerStart),
        vehicleLocationEnd: selectedEndVehicleLocationRecord,
        fuelPurchases: fuelPurchases,
        totalVehicleKmDiff: parseFloat(odometerEnd) - parseFloat(odometerStart),
        savedVehicleKm: parseFloat(odometerEnd) - parseFloat(odometerStart),
        iftaRouteDriverPeriods: driver ? [driver] : undefined,
      },
      false,
    );

    // Replace iftaRoute in table preview array with updated iftaRoute
    newState.iftaRouteRecordsForTablePreview[indexInPreviewArray] = selectedIFTARouteRecord;

    // Order the array by start date
    newState.iftaRouteRecordsForTablePreview.sort((iftaRouteA, iftaRouteB) => getAttribute(iftaRouteA, 'dateStart') - getAttribute(iftaRouteB, 'dateStart'));

    const tempIFTARoutesIds = Object.values(newState.iftaRouteCardHash).map((tempIFTARouteObj) => tempIFTARouteObj.iftaRoute._localId);
    const currentIFTARouteId = selectedIFTARouteRecord._localId;

    this.setState(newState, () => {
      this.handleScroll(undefined, tempIFTARoutesIds, currentIFTARouteId, enableAutoScroll);
    });
  }

  /**
   * @description Display the card containing the information of the iftaRoute that the client has selected
   * @param {Number} indexForPagination The page number of the route we want to display
   */
  // Display the card that the client has selected from pagination
  displaySelectedCardAndTablePreview(indexForPagination) {
    const newState = { ...this.state };
    newState.iftaRouteCardHash = { ...this.state.iftaRouteCardHash };
    newState.activePage = { ...this.state.activePage };
    newState.cardToDisplay = { ...this.state.cardToDisplay };
    newState.tablePreviewToDisplay = { ...this.state.tablePreviewToDisplay };
    newState.canDisplayTablePreview = { ...this.state.canDisplayTablePreview };

    // Obtain company objectId
    const currentUser = getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
    let companyObjectId = getAttribute(belongsToCompany, 'objectId');
    companyObjectId = createTempPointer('Company', companyObjectId);

    // Obtain information pertaining to card the client has displayed
    const indexForArray = indexForPagination - 1;
    const routesFromHash = Object.entries(newState.iftaRouteCardHash);
    const selectedIFTARouteCard = routesFromHash[indexForArray];
    const selectedKey = selectedIFTARouteCard[0];

    // Update in the state which card we will display to the client
    newState.activePage = indexForPagination;
    newState.cardToDisplay =
      <AddEditRouteCard
        key={selectedKey}
        keyId={selectedKey}
        iftaRoute={this.state.iftaRouteCardHash[selectedKey]}
        deleteRoute={(keyForRoute) => this.deleteRoute(keyForRoute)}
        updateRouteInformationForIFTARouteCardHash={(routeInformation) => { this.updateRouteInformationForIFTARouteCardHash(selectedKey, routeInformation) }}
        isEdit={this.props.isEdit}
        distanceUnit={this.props.distanceUnitFilter.value}
        unitId={this.props.unitId}
      />

    const tempIFTARoutesIds = Object.values(newState.iftaRouteCardHash).map((tempIFTARouteObj) => tempIFTARouteObj.iftaRoute._localId);
    const currentIFTARouteId = newState.iftaRouteCardHash[selectedKey].iftaRoute._localId;

    this.setState(newState, () => {
      this.handleScroll(undefined, tempIFTARoutesIds, currentIFTARouteId);
    });
  }

  /**
   * @description Update fuel purchases after the server afterSave()
   * @param {Object} iftaRouteCardHashItem A given iftaRoute and its corresponding routeInformation within the iftaRouteCardHash
   */
  async updateFuelPurchasesForIFTARoute(iftaRouteCardHashItem) {
    const { iftaRoute, routeInformation } = iftaRouteCardHashItem;
    const { fuelPurchaseHash } = routeInformation;
    let fuelPurchases = [];

    for (const key in fuelPurchaseHash) {
      fuelPurchases.push(fuelPurchaseHash[key].fuelPurchase);
    }

    await updateRecord(iftaRoute, { fuelPurchases: fuelPurchases }, true);
  }

  /**
   * @description Create or Update IFTARoutes with the client's inputted information
   * @param {Object} iftaRouteCardHashItem A given iftaRoute and its corresponding routeInformation within the iftaRouteCardHash
   */
  async createOrUpdateIFTARoute(iftaRouteCardHashItem) {
    const { iftaRoute, routeInformation } = iftaRouteCardHashItem;
    const { customLocationBuilder, driver, editInfo, endDate, odometerReadings, startDate, unit, fuelPurchaseHash } = routeInformation;
    let vehicleLocationPromises = [];

    // Obtain company objectId
    const currentUser = getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
    let companyObjectId = getAttribute(belongsToCompany, 'objectId');
    companyObjectId = createTempPointer('Company', companyObjectId);

    // Obtain locationDescription for start and end vehicleLocations from routeInformation
    const locationBreakdownForStartVehicleLocation = editInfo.startCustomLocation;
    const locationBreakdownForEndVehicleLocation = editInfo.endCustomLocation;

    // Create start vehicleLocation for IFTARoute Record
    const vehicleLocationStartRecord = createTempRecord(
      'VehicleLocation',
      {
        stateCodeMobile: customLocationBuilder.startCustomLocationObject.stateProvince.code,
        stateProvince: customLocationBuilder.startCustomLocationObject.stateProvince.code.toLowerCase(),
        belongsToCompany: companyObjectId,
        dateTime: startDate,
        // location: customLocationBuilder.startCustomLocationObject.location,
      }
    );

    // Convert units for iftaRoute Record
    let odometerStart = parseFloat(odometerReadings.odometerStart);
    if (unit === 'KM') {
      const convertedLocationDescription = this.convertDistanceForLocationBreakdown(locationBreakdownForStartVehicleLocation, 'km', 'mi');
      updateRecord(vehicleLocationStartRecord, { locationDescriptionCA: locationBreakdownForStartVehicleLocation, locationDescriptionUS: convertedLocationDescription });
    } else if (unit === 'MI') {
      const convertedLocationDescription = this.convertDistanceForLocationBreakdown(locationBreakdownForStartVehicleLocation, 'mi', 'km');
      updateRecord(vehicleLocationStartRecord, { locationDescriptionUS: locationBreakdownForStartVehicleLocation, locationDescriptionCA: convertedLocationDescription });
      // odometerStart = Helpers.convertDistance(odometerStart, 'mi', 'km');
    }

    // Create end vehicleLocation for IFTARoute Record
    const vehicleLocationEndRecord = createTempRecord(
      'VehicleLocation',
      {
        stateCodeMobile: customLocationBuilder.endCustomLocationObject.stateProvince.code,
        stateProvince: customLocationBuilder.endCustomLocationObject.stateProvince.code.toLowerCase(),
        belongsToCompany: companyObjectId,
        dateTime: endDate,
        // location: customLocationBuilder.endCustomLocationObject.location,
      }
    );

    // Convert units for iftaRoute record
    let odometerEnd = parseFloat(odometerReadings.odometerEnd);
    if (unit === 'KM') {
      const convertedLocationDescription = this.convertDistanceForLocationBreakdown(locationBreakdownForEndVehicleLocation, 'km', 'mi');
      updateRecord(vehicleLocationEndRecord, { locationDescriptionCA: locationBreakdownForEndVehicleLocation, locationDescriptionUS: convertedLocationDescription });
    } else if (unit === 'MI') {
      const convertedLocationDescription = this.convertDistanceForLocationBreakdown(locationBreakdownForEndVehicleLocation, 'mi', 'km');
      updateRecord(vehicleLocationEndRecord, { locationDescriptionUS: locationBreakdownForEndVehicleLocation, locationDescriptionCA: convertedLocationDescription });
      // odometerEnd = Helpers.convertDistance(odometerEnd, 'mi', 'km');
    }

    // Update fuel purchase records with edited information and save them to table
    let fuelPurchasePromises = [];
    for (const key in fuelPurchaseHash) {
      const { fuelPurchase, purchaseInformation } = fuelPurchaseHash[key];
      fuelPurchasePromises.push(
        updateRecord(
          fuelPurchase,
          {
            volumePumped: parseInt(purchaseInformation.volumePumped),
            totalPaid: parseInt(purchaseInformation.totalPaid),
            stateProvince: purchaseInformation.stateProvince,
            driver: driver,
            vehicleUnitId: this.props.unitId,
            vehicle: undefined,
            belongsToCompany: companyObjectId,
            fuelType: purchaseInformation.fuelType,
            currency: purchaseInformation.currency,
            timeMillis: purchaseInformation.date,
            volumeUnits: purchaseInformation.volumeUnits.toLowerCase(),
            location: undefined,
            eldDailyCertification: undefined,
          },
          true,
        )
      );
    }
    await Promise.all(fuelPurchasePromises);

    // Push saved fuel purchases into array for iftaRoute record
    let fuelPurchases = [];
    for (const key in fuelPurchaseHash) {
      fuelPurchases.push(fuelPurchaseHash[key].fuelPurchase);
    }

    // Save vehicleLocation records
    vehicleLocationPromises.push(addRecord('VehicleLocation', {}, undefined, vehicleLocationStartRecord));
    vehicleLocationPromises.push(addRecord('VehicleLocation', {}, undefined, vehicleLocationEndRecord));
    await Promise.all(vehicleLocationPromises);

    // Update and save iftaRoute record with new information
    const newIFTARouteRecord = await updateRecord(
      iftaRoute,
      {
        vehicleLocationStart: vehicleLocationStartRecord,
        stateProvince: customLocationBuilder.startCustomLocationObject.stateProvince.code.toLowerCase(),
        vehicleUnitId: this.props.unitId,
        dateStart: startDate,
        dateEnd: endDate,
        belongsToCompany: companyObjectId,
        totalVehicleKmEnd: odometerEnd,
        isHidden: undefined,
        totalVehicleKmStart: odometerStart,
        vehicleLocationEnd: vehicleLocationEndRecord,
        fuelPurchases: fuelPurchases,
        totalVehicleKmDiff: odometerEnd - odometerStart,
        iftaRouteDriverPeriods: undefined,
        // distanceKm: distanceKm,
      },
      true,
    );

    // Update the fuel card transactions to this newly updated ifta route (if any transactions exist)
    if (this.props.iftaRoute) {
      const iftaRoute = this.props.iftaRoute;
      const originalIFTARouteObjectId = getAttribute(iftaRoute, 'objectId');
      const fuelCardDataHash = await getFuelCardData([originalIFTARouteObjectId], true);
      const fuelCardDataRecordsArr = fuelCardDataHash && fuelCardDataHash[originalIFTARouteObjectId];

      let fuelCardDataPromises = [];

      if (fuelCardDataRecordsArr) {
        fuelCardDataRecordsArr.map((fuelCardData) => {
          try {
            fuelCardDataPromises.push(updateRecord(fuelCardData, { iftaRouteBeta: newIFTARouteRecord }, true));
          } catch (err) {
            console.log(err);
          }
        });
      }

      await Promise.all(fuelCardDataPromises);
    }

    if (driver) {
      // Create IFTARouteDriverPeriod_Beta record for IFTARoute
      const iftaRouteDriverPeriodBetaRecord = await addRecord(
        'IFTARouteDriverPeriod_Beta',
        {
          vehicleLocationStart: vehicleLocationStartRecord,
          vehicleLocationEnd: vehicleLocationEndRecord,
          driver,
          belongsToCompany: companyObjectId,
          iftaRoute,
          dateStart: startDate,
          dateEnd: endDate,
        },
      );

      // Update the IFTARoute_Beta record with the IFTARouteDriverPeriod_Beta record
      await updateRecord(iftaRoute, { iftaRouteDriverPeriods: [iftaRouteDriverPeriodBetaRecord] }, true);
    }
  }

  /**
   * @description When "Confirm All Routes" is pressed, this function organizes the information and sends it to createOrUpdateIFTARoute for each iftaRoute
   */
  async confirmAllRoutes() {
    const { iftaRouteCardHash, newVehicleForRoute } = this.state;
    const { props } = this;

    this.setState({ ...this.state, isLoading: true, });

    // Iterate through the routes and save them
    let iftaRoutePromises = [];
    for (const key in iftaRouteCardHash) {
      iftaRoutePromises.push(this.createOrUpdateIFTARoute(iftaRouteCardHash[key]));
    }
    await Promise.all(iftaRoutePromises);

    // Iterate through all saved routes and update their fuel purchases
    let fuelPurchasePromises = [];
    for (const key in iftaRouteCardHash) {
      fuelPurchasePromises.push(this.updateFuelPurchasesForIFTARoute(iftaRouteCardHash[key]));
    }
    await Promise.all(fuelPurchasePromises);

    this.setState({ ...this.state, isLoading: false, });

    // Close modal and refresh table; If editing, set hide in handleClose( hide ) to true, to hide original record.
    await props.handleClose(!!(props.isEdit));
    props.refreshState(true);
  }

  async hideRoute(key) {
    const { iftaRouteCardHash } = this.state;
    const { props } = this;

    this.setState({ ...this.state, isLoading: true });

    const iftaRouteObj = iftaRouteCardHash[key].iftaRoute;

    iftaRouteObj.set('isHidden')
    await updateRecord(iftaRouteObj, { isHidden: true }, true);

    this.setState({ ...this.state, isLoading: false });

    // Close modal and refresh table; If editing, set hide in handleClose( hide ) to true, to hide original record.
    props.handleClose(!!(props.isEdit));
    props.refreshState(true);
  }

  /**
   * @description Deletes the iftaRoute and routeInformation of the iftaRouteCardHash with the corresponding key
   * @param key The key in the hash of the iftaRoute we want to delete
   */
  deleteRoute(key) {
    const newState = { ...this.state };
    newState.iftaRouteCardHash = { ...this.state.iftaRouteCardHash };
    newState.cardToDisplay = { ...this.state.cardToDisplay };
    newState.activePage = { ...this.state.activePage };
    newState.iftaRouteRecordsForTablePreview = this.state.iftaRouteRecordsForTablePreview;

    // Determine index for route we want to delete for further calculations
    const routesFromHash = Object.entries(this.state.iftaRouteCardHash);
    let deletedRouteIndex;
    for (let i = 0; i < routesFromHash.length; i++) {
      const currentKey = routesFromHash[i][0];
      if (currentKey === key) {
        deletedRouteIndex = i;
        break;
      }
    }

    // Using the deletedRouteIndex, determine which card to display to client
    // If there are no routes to the right or left, it means that there was only one route in the array
    // We can let the algorithm then delete the route and close the modal
    let currentIFTARouteId;
    if (routesFromHash[deletedRouteIndex - 1]) { // If there is a route to the left of the deleted route, display that
      const routeToDisplay = routesFromHash[deletedRouteIndex - 1];
      currentIFTARouteId = this.state.iftaRouteCardHash[routeToDisplay[0]].iftaRoute._localId;
      newState.activePage = (deletedRouteIndex - 1) + 1;
      newState.cardToDisplay =
        <AddEditRouteCard
          key={routeToDisplay[0]}
          keyId={routeToDisplay[0]}
          iftaRoute={this.state.iftaRouteCardHash[routeToDisplay[0]]}
          deleteRoute={(keyForRoute) => this.deleteRoute(keyForRoute)}
          updateRouteInformationForIFTARouteCardHash={(routeInformation) => this.updateRouteInformationForIFTARouteCardHash(routeToDisplay[0], routeInformation)}
          isEdit={this.props.isEdit}
          distanceUnit={this.props.distanceUnitFilter.value}
          unitId={this.props.unitId}
        />;
    } else if (routesFromHash[deletedRouteIndex + 1]) { // If there is no route to the left, but one to the right, display that
      const routeToDisplay = routesFromHash[deletedRouteIndex + 1];
      currentIFTARouteId = this.state.iftaRouteCardHash[routeToDisplay[0]].iftaRoute._localId;
      newState.activePage = (deletedRouteIndex + 1) + 1;
      newState.cardToDisplay =
        <AddEditRouteCard
          key={routeToDisplay[0]}
          keyId={routeToDisplay[0]}
          iftaRoute={this.state.iftaRouteCardHash[routeToDisplay[0]]}
          deleteRoute={(keyForRoute) => this.deleteRoute(keyForRoute)}
          updateRouteInformationForIFTARouteCardHash={(routeInformation) => this.updateRouteInformationForIFTARouteCardHash(routeToDisplay[0], routeInformation)}
          isEdit={this.props.isEdit}
          distanceUnit={this.props.distanceUnitFilter.value}
          unitId={this.props.unitId}
        />;
    }

    // Delete route from array for table preview
    for (let i = 0; i < newState.iftaRouteRecordsForTablePreview.length; i++) {
      const currentIFTARouteRecord = newState.iftaRouteRecordsForTablePreview[i];
      if (currentIFTARouteRecord === newState.iftaRouteCardHash[key].iftaRoute) {
        newState.iftaRouteRecordsForTablePreview.splice(i, 1);
      }
    }

    // Delete entry from hash
    delete newState.iftaRouteCardHash[key];

    // Determine where to scroll
    const tempIFTARoutesIds = Object.values(newState.iftaRouteCardHash).map((tempIFTARouteObj) => tempIFTARouteObj.iftaRoute._localId);

    this.setState(newState,
      () => {
        this.updateRouteInformationForIFTARouteCardHash();
        this.shouldCloseModal(Object.keys(this.state.iftaRouteCardHash));
        this.handleScroll(undefined, tempIFTARoutesIds, currentIFTARouteId);
      }
    );
  }

  /**
   * @description Checks to see if there are no keys in the hash, meaning all routes have been deleted and the modal should be closed
   * @param {Array} keys
   */
  shouldCloseModal(keys) {
    if (keys.length === 0) {
      this.props.handleClose(false);
    }
  }

  /**
   * @description Converts the location description string to whichever unit has been selected and formats it
   * @param {String} locationBreakdown The location description string we want to convert
   * @param {String} inputUnit The unit of the location description string
   * @param {String} outputUnit The unit we want to convert the location description string to
   * @returns A location description string converted to the desired unit
   */
  convertDistanceForLocationBreakdown(locationBreakdown, inputUnit, outputUnit) {
    const locationBreakdownForVehicleLocation = getLocationDescriptionBreakdown(locationBreakdown);
    const distanceForVehicleLocation = parseInt(locationBreakdownForVehicleLocation.distance);
    const convertedDistanceForVehicleLocation = Math.round(Helpers.convertDistance(distanceForVehicleLocation, inputUnit, outputUnit)).toString() + outputUnit;
    const locationSplit = locationBreakdown.split(' ');
    const originalDistanceAndUnit = locationSplit[0];

    return locationBreakdown.replace(originalDistanceAndUnit, convertedDistanceForVehicleLocation);
  }

  /**
   * @description Update the corresponding iftaRoute with the information from the AddEditRouteCard, also checks to see if all cards contain valid information to save
   * @param key The key of the iftaRoute we want to update
   * @param {Object} routeInformation The information we want to update the iftaRoute with
   */
  updateRouteInformationForIFTARouteCardHash(key, routeInformation) {
    const shouldUpdateRouteInformation = key && routeInformation;
    const iftaRouteCardHash = this.state.iftaRouteCardHash;

    if (shouldUpdateRouteInformation) {
      iftaRouteCardHash[`${key}`].routeInformation = routeInformation;
      this.setState({ ...this.state, iftaRouteCardHash }, () => {
        this.updateRowInTablePreview(key);
      });
    }

    if (this.props.isEdit && shouldUpdateRouteInformation) {
      if (iftaRouteCardHash[`${key}`].routeInformation.allFieldsValid) {
        this.setState({ ...this.state, disableConfirmEditButton: false, });
      } else {
        this.setState({ ...this.state, disableConfirmEditButton: true });
      }
    } else {
      let validIFTARouteCardHashKeys = [];
      const allIFTARouteCardHashKeys = Object.keys(iftaRouteCardHash);

      for (const key in iftaRouteCardHash) {
        const currentIFTARouteCardRouteInformation = iftaRouteCardHash[key].routeInformation;
        const currentFieldsAreValid = currentIFTARouteCardRouteInformation.allFieldsValid;

        if (currentFieldsAreValid) {
          validIFTARouteCardHashKeys.push(key);
        }
      }

      // If the valid keys are equal to all keys, all cards are valid so enable confirm all button
      if (validIFTARouteCardHashKeys.length === allIFTARouteCardHashKeys.length) {
        this.setState({ ...this.state, disableConfirmButton: false });
      } else {
        this.setState({ ...this.state, disableConfirmButton: true });
      }
    }
  }

  async migrateIFTARouteToVehicle(iftaRoute, vehicle) {
    if (iftaRoute && vehicle) {
      try {
        this.setState({ isLoading: true });
        await migrateIFTARoute(iftaRoute, vehicle);

        this.setState({ migrationError: false, isLoading: false });
        // Hide original is false because we are using the original IFTARoute
        this.props.handleClose(false);
        this.props.refreshState(true);
      } catch (err) {
        // Guarantees transition to Loading Spinner before the error message shows
        setTimeout(() => this.setState({ migrationError: true, isLoading: false }), 300);
      }
    }
  }

  render() {
    const { state, props } = this;
    const iftaRouteCardHashKeys = Object.keys(state.iftaRouteCardHash);

    // Determine modal title and footer based on whether we are editing or not
    let modalTitle;
    let modalFooter;
    if (props.isEdit) {
      modalTitle = <React.Fragment>Edit Route for unit {props.unitId}</React.Fragment>;
      modalFooter = (
        <React.Fragment>
          {state.migrationError && (
            <span className="mr-auto text-danger">
              There was an error migrating the route
            </span>
          )}
          {iftaRouteCardHashKeys && iftaRouteCardHashKeys.length === 1 &&
            (
              <div style={{ position: 'absolute', left: '0', marginLeft: '2em' }}>
                <MDBBtn
                  // className={'closeButton'}
                  style={{ width: '16em' }}
                  size="md"
                  color="danger"
                  onClick={() => {
                    console.log(iftaRouteCardHashKeys[0])
                    this.hideRoute(iftaRouteCardHashKeys[0]);
                  }}
                >
                  Delete Route
                </MDBBtn>
              </div>
            )
          }
          <MDBBtn
            // className={'closeButton'}
            style={{ width: '16em' }}
            size="md"
            color="primary"
            disabled={state.disableConfirmEditButton}
            onClick={() => this.confirmAllRoutes()}
          >
            Confirm Edits
          </MDBBtn>
          <MDBBtn
            // className={'closeButton'}
            size="md"
            color="primary"
            onClick={() => this.props.handleClose(false)}
          >
            Cancel
          </MDBBtn>
        </React.Fragment>
      );
    } else {
      modalTitle = <React.Fragment>Add Routes for unit {props.unitId}</React.Fragment>;
      modalFooter = (
        <React.Fragment>
          <MDBBtn
            // className={'closeButton'}
            style={{ width: '16em' }}
            size="md"
            color="primary"
            disabled={state.disableConfirmButton}
            onClick={() => this.confirmAllRoutes()}
          >
            Confirm All Routes
          </MDBBtn>
          <MDBBtn
            // className={'buttonDefault'}
            style={{ width: '16em' }}
            size="md"
            color="primary"
            onClick={() => this.addNewIFTARoute()}
          >
            Add New Route
          </MDBBtn>
          <MDBBtn
            // className={'closeButton'}
            size="md"
            color="primary"
            onClick={() => this.props.handleClose(false)}
          >
            Cancel
          </MDBBtn>
        </React.Fragment>
      );
    }

    if (state.isLoading) {
      return (
        <MDBModal className="add-edit-route-modal" isOpen={props.show} centered size="lg" style={{ 'maxHeight': '90vh' }}>
          <MDBModalHeader>
            {modalTitle}
          </MDBModalHeader>
          <div className='spinner-border loading-spinner' role='status' style={{ "zIndex": "300000", "position": "absolute", "top": "45%", "left": "45%" }}>
            <span className='sr-only'>Loading...</span>
          </div>
          <Blur radius="5px" transition="400ms" style={{ "position": "relative" }}>
            <MDBModalBody style={{ 'height': '75vh', 'overflowY': 'auto' }}>

              {state.iftaRouteCardHash && iftaRouteCardHashKeys.map((key) => {
                return <AddEditRouteCard
                  key={key}
                  keyId={key}
                  iftaRoute={state.iftaRouteCardHash[key]}
                  deleteRoute={(keyForRoute) => this.deleteRoute(keyForRoute)}
                  updateRouteInformationForIFTARouteCardHash={(routeInformation) => this.updateRouteInformationForIFTARouteCardHash(key, routeInformation)}
                  isEdit={props.isEdit}
                  distanceUnit={props.distanceUnitFilter.value}
                  unitId={props.unitId}
                  migrateRouteFunction={(vehicle) => this.migrateIFTARouteToVehicle(props.iftaRoute, vehicle)}
                />
              })}

            </MDBModalBody>
            <MDBModalFooter>
              {modalFooter}
            </MDBModalFooter>
          </Blur>
        </MDBModal>
      );
    } else {
      return (
        <MDBModal className="add-edit-route-modal" isOpen={props.show} centered size="lg" style={{ 'maxHeight': '90vh' }}>

          {/* Modal Header */}
          <MDBModalHeader>
            {modalTitle}
          </MDBModalHeader>

          <MDBModalBody>
            {/* Table Preview */}
            <div style={{ height: '25em', 'overflowY': 'auto', border: '3px solid black', marginBottom: '0.5em' }}>
              <AddEditRouteModalTablePreview
                iftaRouteRecordsForTablePreview={state.iftaRouteRecordsForTablePreview}
                distanceUnitFilter={props.distanceUnitFilter}
                driverTimeZoneStr={props.driverTimeZoneStr}
                deleteRoute={this.deleteRoute}
              />
            </div>

            {/* Pagination */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Pagination
                totalItemsCount={Object.entries(this.state.iftaRouteCardHash).length}
                onChange={(index) => this.displaySelectedCardAndTablePreview(index)}
                activePage={this.state.activePage}
                itemsCountPerPage={1}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>

            {/* Card Display */}
            <div>
              {this.state.cardToDisplay}
            </div>
          </MDBModalBody>

          {/* Modal Footer */}
          <MDBModalFooter>
            {modalFooter}
          </MDBModalFooter>
        </MDBModal>
      );
    }
  }
}

export default AddEditRouteModal;
