import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// API
import * as Setters from 'api/Setters';

// Components
import AddNewJobModalPickupAndDropoffView from 'components/AddNewJob/view/AddNewJobModalPickupDropoff';

// Context
import StoreContext from 'contexts/StoreContext';

function getInitialState() {
  const initialState = { addingInProgress: false, validationErrors: {} };
  initialState.globalJobDetails = { jobId: '', vehicle: null };
  initialState.jobActionPickup = { vendor: null, date: null, notes: '' };
  initialState.jobActionDropoff = { vendor: null, date: null, notes: '' };
  return initialState;
}

class AddNewJobModalPickupAndDropoff extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.handleFormChange = this.handleFormChange.bind(this);
    this.addJob = this.addJob.bind(this);
    this.validateEntries = this.validateEntries.bind(this);
  }

  componentDidMount() {
    this.validateEntries();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) this.setState(getInitialState());
  }

  handleFormChange(jobProperty, jobValue, type) {
    const newState = { ...this.state };
    if (type === 'global') {
      newState.globalJobDetails[jobProperty] = jobValue;
    } else if (type === 'pickup') {
      newState.jobActionPickup[jobProperty] = jobValue;
    } else if (type === 'dropoff') {
      newState.jobActionDropoff[jobProperty] = jobValue;
    }
    this.setState(newState);
    this.validateEntries();
  }

  addJob() {
    if (this.state.validationErrors.error) {
      this.setState({ ...this.state, validationErrors: { ...this.state.validationErrors, trigger: true } });
      return;
    }
    this.setState({ ...this.state, addingInProgress: true }, () => {
      Setters.createNewPickupDropoffLoad(this.state.globalJobDetails, this.state.jobActionPickup, this.state.jobActionDropoff).then(
        jobLink => {
          this.props.handleClose();
          this.props.refreshState();
          this.setState(getInitialState());
        }
      );
    });
  }

  validateEntries() {
    const newState = { ...this.state };
    let validationError = false;
    newState.validationErrors.error = false;
    if (this.state.globalJobDetails.jobId) newState.validationErrors.jobId = false;
    else {
      newState.validationErrors.jobId = { label: 'Job ID' };
      newState.validationErrors.error = true;
      validationError = true;
    }

    if (this.state.jobActionPickup.vendor) newState.validationErrors.pickupVendor = false;
    else {
      newState.validationErrors.pickupVendor = { label: 'Pickup Location' };
      newState.validationErrors.error = true;
      validationError = true;
    }

    if (this.state.jobActionDropoff.vendor) newState.validationErrors.dropoffVendor = false;
    else {
      newState.validationErrors.dropoffVendor = { label: 'Dropoff Location' };
      newState.validationErrors.error = true;
      validationError = true;
    }
    this.setState(newState);
    return validationError;
  }

  render() {
    return (
      <AddNewJobModalPickupAndDropoffView
        {...this.props}
        globalJobDetails={this.state.globalJobDetails}
        validationErrors={this.state.validationErrors}
        handleFormChange={this.handleFormChange}
        jobActionPickup={this.state.jobActionPickup}
        jobActionDropoff={this.state.jobActionDropoff}
        onSubmit={this.addJob}
        addingInProgress={this.state.addingInProgress}
      />
    );
  }
}

AddNewJobModalPickupAndDropoff.propTypes = {
  Dispatcher: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  JobAction: PropTypes.object.isRequired,
  JobLink: PropTypes.object.isRequired,
  refreshState: PropTypes.func,
};


const mapStateToProps = (state) => {
  const { addNewJob } = state.form;
  const { JobAction, JobLink, Dispatcher } = state;
  return {
    addNewJob,
    JobAction,
    JobLink,
    Dispatcher,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(AddNewJobModalPickupAndDropoff);

