import { getAttribute } from 'api/Parse';
import moment from 'moment-timezone';


/** @module Timezone */

/**
 * @memberof module:Timezone
 * @description Given an eldDailyCertification and/or driver record, obtain the timezoneOffsetFromUTC field from the order of precedence of eldDailyCertification -> eldDailyCertification.driver -> driver
 *
 *  WARNING: When passing in the function arguments (eldDailyCertification, driver), the "timezoneOffsetFromUTC" field must be included on the record(s). If timezoneOffsetFromUTC exists on the record(s), but were not included in a query that obtains
 *  eldDailyCertification or driver used in this function, the function may not return the expected result.
 *  For consistency across the application, it is best only the eldDailyCertification is used to retrieve the timezoneOffsetFromUTC when scaling times
 *
 * @param {ELDDailyCertification} eldDailyCertification - ELDDailyCertification we wish to get eldDailyCertification.timezoneOffsetFromUTC or eldDailyCertification.driver.timezoneOffsetFromUTC
 * @param {ELDEvent} - Not yet necessary nor functional, this parameter is in place for future implementation if we ever scale sections of days (events) into certain timezones. ELDEvent we wish to derive the timezone off of
 * @param {Driver} driver - Driver we wish to get driver.timezoneOffsetFromUTC
 *
 * @returns {String} - The timezoneOffsetFromUTC string, an official timezone string format
 * @example
 * const eldDailyCertification = await callCloudFunction('getELDDailyCertification', { driver, ... });
 * const timezoneOffsetFromUTC = getTimezoneOffsetFromUTC(eldDailyCertification);
 * // timezoneOffsetFromUTC returns 'America/Vancouver'
 */
function getTimezoneOffsetFromUTC(eldDailyCertification, eldEvent, driver) {
  let timezoneOffsetFromUTC = 'yolo'; // default result is a guess

  // timezone from the daily cert argument
  const eldDailyCertificationTimezoneOffsetFromUTC = getAttribute(eldDailyCertification, 'timezoneOffsetFromUTC', true);
  if (eldDailyCertificationTimezoneOffsetFromUTC) {
    timezoneOffsetFromUTC = eldDailyCertificationTimezoneOffsetFromUTC;
    return timezoneOffsetFromUTC;
  }

  // timezone from the daily cert argument's driver
  const eldDailyCertificationDriver = getAttribute(eldDailyCertification, 'driver', true);
  const eldDailyCertificationDriverTimezoneOffsetFromUTC = getAttribute(eldDailyCertificationDriver, 'timezoneOffsetFromUTC', true);
  if (eldDailyCertificationDriverTimezoneOffsetFromUTC) {
    timezoneOffsetFromUTC = eldDailyCertificationDriverTimezoneOffsetFromUTC;
    return timezoneOffsetFromUTC;
  }

  // timezone from the driver argument
  const driverTimezoneOffsetFromUTC = driver && getAttribute(driver, 'timezoneOffsetFromUTC');
  if (driverTimezoneOffsetFromUTC) {
    timezoneOffsetFromUTC = driverTimezoneOffsetFromUTC;
    return timezoneOffsetFromUTC;
  }

  return timezoneOffsetFromUTC;
}

function getTimezoneAbbreviation(dateTime, timezoneOffsetFromUTC) {
  return moment(dateTime).tz(timezoneOffsetFromUTC ?? moment.tz.guess()).zoneAbbr();
}

export {
  getTimezoneOffsetFromUTC,
  getTimezoneAbbreviation,
}
