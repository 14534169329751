import axios from 'axios';
import * as ParseAPI from 'api/Parse';
import store from 'store';

import { fetchUserGlobals } from 'api/Getters';
import { initializeMapbox } from 'api/Mapbox';
import { initializeStripe } from 'api/Stripe';

import * as ActionConstants from './ActionConstants';
import { fetchingUser, fetchUserSuccess, fetchUserError } from './User';
import { fetchingCompany, fetchCompanySuccess, fetchCompanyError } from './Company';
import { fetchingCloudUserPreference, fetchCloudUserPreferenceSuccess, fetchCloudUserPreferenceError } from './CloudUserPreference';
import { fetchingAdmin, fetchAdminSuccess, fetchAdminError } from './Admin';
import { fetchingDispatcher, fetchDispatcherSuccess, fetchDispatcherError } from './Dispatcher';
import { fetchingSafetyOfficer, fetchSafetyOfficerSuccess, fetchSafetyOfficerError } from './SafetyOfficer';
import { fetchingSubscription, fetchSubscriptionSuccess, fetchSubscriptionError } from './Subscription';
import { fetchingGlobalTrailers, fetchGlobalTrailersSuccess, fetchGlobalTrailersError } from './GlobalTrailer';
import { fetchingGlobalVehicles, fetchGlobalVehiclesSuccess, fetchGlobalVehiclesError } from './GlobalVehicle';

function fetchingUserGlobals(promises) {
  return {
    type: ActionConstants.FETCH_USER_GLOBALS_INPROGRESS,
    promises,
  };
}

function fetchUserGlobalsSuccess() {
  return {
    type: ActionConstants.FETCH_USER_GLOBALS_SUCCESS,
  };
}

function fetchUserGlobalsError(error) {
  return {
    type: ActionConstants.FETCH_USER_GLOBALS_ERROR,
    error,
  };
}

export function fetchAndLoadUserGlobalsForState() {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingUserGlobals());
    store.dispatch(fetchingUser());
    store.dispatch(fetchingCompany());
    store.dispatch(fetchingCloudUserPreference());
    store.dispatch(fetchingAdmin());
    store.dispatch(fetchingDispatcher());
    store.dispatch(fetchingSafetyOfficer());
    store.dispatch(fetchingSubscription());
    store.dispatch(fetchingGlobalTrailers());
    store.dispatch(fetchingGlobalVehicles());
    fetchUserGlobals().then(
      (results) => {
        // fetchUserGlobals() also updates globalTrailer using globalTrailers' own action function.
        store.dispatch(fetchUserSuccess(results.user));
        store.dispatch(fetchCompanySuccess(results.company));
        store.dispatch(fetchCloudUserPreferenceSuccess(results.cloudUserPreference));
        store.dispatch(fetchAdminSuccess(results.Admin));
        store.dispatch(fetchDispatcherSuccess(results.dispatcher));
        store.dispatch(fetchSafetyOfficerSuccess(results.safetyOfficer));
        store.dispatch(fetchSubscriptionSuccess(results.subscription));
        store.dispatch(fetchUserGlobalsSuccess());
        resolve();
      },
      (error) => {
        store.dispatch(fetchUserError());
        store.dispatch(fetchCompanyError());
        store.dispatch(fetchCloudUserPreferenceError());
        store.dispatch(fetchAdminError());
        store.dispatch(fetchDispatcherError());
        store.dispatch(fetchSafetyOfficerError());
        store.dispatch(fetchSubscriptionError());
        store.dispatch(fetchUserGlobalsError(error));
        store.dispatch(fetchGlobalTrailersError());
        store.dispatch(fetchGlobalVehiclesError());
        reject();
      }
    );
  });
  return promise;
}

export function initialize() {
  if (!store.getState().Main.status) {
    const promise = new Promise((resolve, reject) => {
      axios.post('/switchboard/env').then((configObj) => {
        const { appId, jsKey, serverURL, env } = configObj.data;
        ParseAPI.start(appId, jsKey, serverURL);
        // ParseAPI.start(appId, jsKey, 'http://localhost:1337/parse');
        store.dispatch({ type: ActionConstants.INITIALIZE, env });
        initializeStripe(store);
        initializeMapbox(store);
        resolve();
      });
    });
    return promise;
  }

  return Promise.resolve();
}

export function checkUserGlobalsInState() {
  return new Promise((resolve) => {
    const checkStore = store.subscribe(() => {
      if (store.getState().Main.status === ActionConstants.FETCH_USER_GLOBALS_SUCCESS) {
        store.subscribe(checkStore); // unsubscribe
        resolve(true);
      }
    });
  });
}

export function selectHeaderPanel(selection) {
  if (selection === store.getState().Main.headerPanel) {
    return store.dispatch({
      type: ActionConstants.HEADER_PANEL_SELECT,
      selection: undefined,
    });
  }
  return store.dispatch({
    type: ActionConstants.HEADER_PANEL_SELECT,
    selection,
  });
}

export function clearState() {
  return {
    type: ActionConstants.CLEAR_STATE,
  };
}
