// api
import { copyQuery, count, createQuery, createQueryOr, find, getAttribute, getCurrentUser, includePointers, setQueryRestriction, sortQuery, updateRecord, addRecord, destroyRecord } from 'api/Parse';

// enums
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { AttributeTypes } from 'enums/SafetyRecurringReminder';

// sbObjects
import SafetyRecurringReminder from 'sbObjects/SafetyRecurringReminder';
import Company from 'sbObjects/Company';
import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

/** @module SafetyRecurringReminder */

/**
 * @memberof module:SafetyRecurringReminder
 *
 * @description Fetch SafetyRecurringReminder records
 * @param {int} page
 * @param {int} limit
 * @param {object} sortBy Sort object
 * @param {array} filters Array of Filter objects
 * @param {bool} includeChildCompanies Include child/sub-companies of this parent company
 * @param {bool} queryAll
 * @param {bool} returnDBObject Return db object, not sb objects
 * @return Object containing SafetyRecurringReminder records and count
 */
const getSafetyRecurringReminders = async function(
  page = 0,
  limit = 20,
  sortBy = new Sort(AttributeTypes.TRIGGER_DATE, QuerySortOrderTypes.DESCENDING),
  filters = [],
  includeChildCompanies,
  queryAll,
  returnDBObject
) {
  const className = 'SafetyRecurringReminder';
  const pointersArr = ['vehicles', 'drivers'];

  let objArr = [];
  let query = createQuery(className);

  const _filters = [].concat(filters);
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

  if (includeChildCompanies) {
    // if getting child companies', the additional queries
    const companyLinkQuery = createQuery('CompanyLink');

    const companyLinkFilters = [
      new Filter('parentCompany', belongsToCompany, QueryRestrictionTypes.EQUAL_TO),
      new Filter('authorized', true, QueryRestrictionTypes.EQUAL_TO),
    ];

    companyLinkFilters.map(filter => {
      setQueryRestriction(companyLinkQuery, filter.queryRestriction, filter.attribute, filter.value);
    });

    // now that we have all the child companies, create queries
    const companyLinks = await find(companyLinkQuery);
    const childCompanyQueries = companyLinks.map(company => {
      const childCompanyQuery = createQuery(className);
      setQueryRestriction(childCompanyQuery, QueryRestrictionTypes.EQUAL_TO, AttributeTypes.BELONGS_TO_COMPANY, getAttribute(company, 'childCompany'));
      return childCompanyQuery;
    })

    // the main current user's company's obj query
    const mainCompanyQuery = createQuery(className);
    setQueryRestriction(mainCompanyQuery, QueryRestrictionTypes.EQUAL_TO, AttributeTypes.BELONGS_TO_COMPANY, belongsToCompany);

    // altogether
    query = createQueryOr([mainCompanyQuery, ...childCompanyQueries]);

  } else {
    setQueryRestriction(query, QueryRestrictionTypes.EQUAL_TO, AttributeTypes.BELONGS_TO_COMPANY, belongsToCompany);
  }

  setQueryRestriction(query, QueryRestrictionTypes.EQUAL_TO, 'isHidden', false);

  _filters.map(filter => setQueryRestriction(query, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  let countQuery = copyQuery(query);

  // sort
  sortQuery(query, sortBy.order, sortBy.attribute);

  if (!queryAll) {
    setQueryRestriction(query, QueryRestrictionTypes.LIMIT, undefined, limit);
    setQueryRestriction(query, QueryRestrictionTypes.SKIP, undefined, page * limit);
  }

  includePointers(query, pointersArr);

  // now get the count and the objs
  const promises = [count(countQuery), find(query, false, queryAll)];

  try {
    const [totalObjCount, dbObjs] = await Promise.all(promises);
    objArr = dbObjs;
    if (!returnDBObject) {
      objArr = dbObjs.map(dbObj => {
        const dbBelongsToCompany = getAttribute(dbObj, 'belongsToCompany', true);

        const obj = new SafetyRecurringReminder(
          dbObj.id,
          getAttribute(dbObj, 'name', true),
          getAttribute(dbObj, 'reminderType', true),
          getAttribute(dbObj, 'triggerDate', true),
          getAttribute(dbObj, 'frequencyType', true),
          getAttribute(dbObj, 'driver', true),
          getAttribute(dbObj, 'vehicles', true),
          getAttribute(dbObj, 'notes', true),
        );

        obj.belongsToCompany = new Company(
          dbBelongsToCompany.id,
          getAttribute(dbBelongsToCompany, 'name', true),
        );

        obj.dbObject = dbObj;
        return obj;
      });
    }

    return { objArr, totalObjCount };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:SafetyRecurringReminder
 * @description Update SafetyRecurringReminder record
 * 
 * @param {object} safetyRecurringReminder SafetyRecurringReminder record
 * @param {object} keyValueObj Key-value pairs object
 * 
 * @returns Updated SafetyRecurringReminder record
 */
async function updateSafetyRecurringReminder(safetyRecurringReminder, keyValueObj = {}) {
  if (!safetyRecurringReminder) throw new Error('Must provide SafetyRecurringReminder record');

  try {
    return await updateRecord(safetyRecurringReminder, keyValueObj, true);
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:SafetyRecurringReminder
 * @description Create SafetyRecurringReminder record
 * 
 * @param {object} keyValueObj Key-value pairs object
 * 
 * @returns Created SafetyRecurringReminder record
 */
async function addSafetyRecurringReminder(keyValueObj) {
  if (!keyValueObj) throw new Error('Must provide key-value object');

  try {
    const currentUser = getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  
    keyValueObj.belongsToCompany = belongsToCompany;
  
    return await addRecord('SafetyRecurringReminder', keyValueObj);
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:SafetyRecurringReminder
 * @description Destroy SafetyRecurringReminder record
 * 
 * @param {object} safetyRecurringReminder SafetyRecurringReminder record
 * 
 * @returns Destroyed SafetyRecurringReminder record
 */
async function deleteSafetyRecurringReminder(safetyRecurringReminder) {
  if (!safetyRecurringReminder) throw new Error('Must provide SafetyRecurringReminder record');

  try {
    return await destroyRecord(safetyRecurringReminder);
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getSafetyRecurringReminders,
  updateSafetyRecurringReminder,
  addSafetyRecurringReminder,
  deleteSafetyRecurringReminder,
};
