import React from 'react';
import PropTypes from 'prop-types';

// CSS
import styles from './JobLinkDetailsSidebar.module.scss';

class JobLinkDetailsSideBar extends React.Component {
  constructor(props) {
    super(props);
    this.isActive = this.isActive.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.pageLocation !== this.props.pageLocation) {
      return true;
    }
    return false;
  }

  isActive(nameOfRoute) {
    if (nameOfRoute === this.props.pageLocation) {
      return styles.active;
    }
    return '';
  }

  render() {
    return (
      <div className={styles.sideBar}>
        <table>
          <tbody>
            <tr>
              <td className={this.isActive('/documents')} onClick={() => this.props.changeRoute('documents')}>Job Information</td>
            </tr>
            <tr>
              <td className={this.isActive('/drivers')} onClick={() => this.props.changeRoute('drivers')}>ACE/ACI</td>
            </tr>
            <tr>
              <td className={this.isActive('/drivers')} onClick={() => this.props.changeRoute('drivers')}>IFTA</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

JobLinkDetailsSideBar.propTypes = {
  changeRoute: PropTypes.func.isRequired,
  pageLocation: PropTypes.string.isRequired,
};

export default JobLinkDetailsSideBar;
