import React from 'react';
import PropTypes from 'prop-types';

// CSS
import styles from './LoadingIcon.module.scss';

function LoadingIcon() {
  return (
    <div className={styles.loadingIcon}>
      <div className={styles.bounce1}></div>
      <div className={styles.bounce2}></div>
      <div className={styles.bounce3}></div>
    </div>
  );
}

export default LoadingIcon;
