import React from 'react';
import PropTypes from 'prop-types';
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact';

// Components
import SubNavigationBar from 'components/Shared/SubNavigationBar/SubNavigationBar';
import EquipmentGroupTrailersList from 'components/EquipmentGroup/EquipmentGroupTrailersList/EquipmentGroupTrailersList';
import EquipmentGroupVehiclesList from 'components/EquipmentGroup/EquipmentGroupVehiclesList/EquipmentGroupVehiclesList';
import EquipmentGroupUsersList from 'components/EquipmentGroup/EquipmentGroupUsersList/EquipmentGroupUsersList';
import EquipmentGroupGeofenceView from 'components/EquipmentGroup/EquipmentGroupGeofenceView/EquipmentGroupGeofenceView';

class EquipmentGroupView extends React.Component {
  constructor(props) {
    super(props);

    this.subRoutes = {
      VEHICLES: 'vehicles',
      TRAILERS: 'trailers',
      GEOFENCES: 'geofences',
      USERS: 'users',
    };

    this.state = {
      currentRoute: this.subRoutes.VEHICLES,
    };
  }

  componentDidMount() {
    this.refreshState();
  }

  async refreshState(currentRoute = this.subRoutes.VEHICLES) {
    this.setState({ currentRoute });
  }

  render() {
    const { state, props, subRoutes } = this;

    const navItemsPrimary = [
      {
        name: 'Vehicles',
        isActive: state.currentRoute === subRoutes.VEHICLES,
        to: `${props.location.pathname}${props.location.search}`,
        onClick: () => this.refreshState(subRoutes.VEHICLES),
      },
      {
        name: 'Trailers',
        isActive: state.currentRoute === subRoutes.TRAILERS,
        to: `${props.location.pathname}${props.location.search}`,
        onClick: () => this.refreshState(subRoutes.TRAILERS),
      },
      {
        name: 'Geofence',
        isActive: state.currentRoute === subRoutes.GEOFENCES,
        to: `${props.location.pathname}${props.location.search}`,
        onClick: () => this.refreshState(subRoutes.GEOFENCES),
      },
      {
        name: 'Users',
        isActive: state.currentRoute === subRoutes.USERS,
        to: `${props.location.pathname}${props.location.search}`,
        onClick: () => this.refreshState(subRoutes.USERS),
      },
    ];

    return (
      <MDBContainer style={{ marginTop: '2em' }} fluid>
        <MDBRow>
          <MDBCol>
            <SubNavigationBar containerClassName="mb-1" navItems={navItemsPrimary} />
          </MDBCol>
        </MDBRow>
        {state.currentRoute === subRoutes.VEHICLES && (
          <MDBRow>
            <MDBCol>
              <EquipmentGroupVehiclesList group={props.group} />
            </MDBCol>
          </MDBRow>
        )}
        {state.currentRoute === subRoutes.TRAILERS && (
          <MDBRow>
            <MDBCol>
              <EquipmentGroupTrailersList group={props.group} />
            </MDBCol>
          </MDBRow>
        )}
        {state.currentRoute === subRoutes.GEOFENCES && (
          <MDBRow>
            <MDBCol>
              <EquipmentGroupGeofenceView group={props.group} />
            </MDBCol>
          </MDBRow>
        )}
        {state.currentRoute === subRoutes.USERS && (
          <MDBRow>
            <MDBCol>
              <EquipmentGroupUsersList group={props.group} />
            </MDBCol>
          </MDBRow>
        )}
      </MDBContainer>
    );
  }
}

EquipmentGroupView.propTypes = {
  group: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default EquipmentGroupView;
