import React from 'react';
import PropTypes from 'prop-types';
import { MDBCard, MDBCardBody, MDBCardText, MDBBtn, MDBIcon, MDBTooltip } from 'mdbreact';

import './styles.scss';

class SBCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    if (props.isCollapsible === false) {
      this.state.isCollapsible = false;
    } else {
      this.state.isCollapsible = true;
    }
    if (!props.isCollapsed) {
      this.state.isCollapsed = false;
    } else {
      this.state.isCollapsed = props.isCollapsed;
    }
    if (props.animateEntrance === false) {
      this.state.animateEntrance = false;
    } else {
      this.state.animateEntrance = props.animateEntrance;
    }
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  componentDidMount() {
    if (this.props.animateEntrance) {
      // remove collapsed from parent
      this.timeoutId = setTimeout(function () {
        this.setState({ ...this.state, animateEntrance: false });
      }.bind(this), 100);
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  toggleCollapse() {
    this.setState({ ...this.state, isCollapsed: !this.state.isCollapsed });
  }

  render() {
    const { sbRoundedBorder, headerBorder, shadowDepth } = this.props;
    let cardClassName = `sb-card z-depth-${shadowDepth ? shadowDepth : 0} text-left ` + (this.props.animateEntrance ? ' collapsible ' : '') + (this.state.animateEntrance ? ' collapsed ' : '') + (this.props.className ? this.props.className : '') + (this.state.isCollapsed ? ' contentCollapsed' : '');
    if (sbRoundedBorder) cardClassName += ` sb-rounded-border`;
    if (headerBorder) cardClassName += ` header-border`;
    
    return (
      <React.Fragment>
        {this.props.ref && (
          <div ref={this.props.ref} />
        )}
        <MDBCard className={cardClassName}>
          <MDBCardBody className="p-0">
            {this.state.isCollapsible &&
              <MDBTooltip
                placement="right"
                className="collapse-tooltip"
                tag="div"
                tooltipContent={this.state.isCollapsed ? 'Maximize' : 'Minimize'} >
                <MDBBtn size="sm" color="default" className="collapse-button rounded-pill z-depth-1"
                  onClick={() => this.toggleCollapse()}>
                  <MDBIcon icon={this.state.isCollapsed ? 'angle-down' : 'angle-up'} ></MDBIcon>
                </MDBBtn>
              </MDBTooltip>
            }
            <MDBCardText className="sb-card-title mb-0 translate-me">{this.props.title}</MDBCardText>
            <div className={"collapsible" + (this.props.contentClassName ? ' ' + this.props.contentClassName : '') + (this.state.isCollapsed ? ' collapsed' : '')} style={this.props.cardBodyStyle}>{this.props.children}</div>
          </MDBCardBody>
        </MDBCard>
      </React.Fragment>
    );
  }
}

/**
 * children: The content of the card.
 * className: Added to the container parent.
 * title: Title of the card.
 * isCollapsible: True by default.
 * isCollapsed: False by default.
 * animateEntrance: False by default.
 * cardBodyStyle: In-line styles for card body
 */
SBCard.defaultProps = {
  cardBodyStyle: {},
};
SBCard.propTypes = {
  children: PropTypes.node,
  contentClassName: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  isCollapsible: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  animateEntrance: PropTypes.bool,
  cardBodyStyle: PropTypes.object,
  headerOnly: PropTypes.bool,
  sbRoundedBorder: PropTypes.bool,
  shadowDepth: PropTypes.number,
}

export default SBCard;