export default class Comment {
  /**
   * 
   * @param {string} objectId 
   * @param {date} dateTimeUTC 
   * @param {object || string} sentBy _User
   * @param {string} comment 
   */
  constructor(objectId, dateTimeUTC, sentBy, comment) {
    this.objectId = objectId;
    this.dateTimeUTC = dateTimeUTC;
    this.sentBy = sentBy;
    this.comment = comment;
  }
}