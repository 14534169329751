export const ELDEventRecordStatus = Object.freeze({
  ACTIVE_OLD: 0, // very old use case where active used to be 0
  ACTIVE: 1,
  INACTIVE_CHANGED: 2,
  INACTIVE_CHANGE_REQUESTED: 3,
  INACTIVE_CHANGE_REJECTED: 4,
});

export const ELDEventRecordStatusDescription = Object.freeze({
  ACTIVE_OLD: 'Active', // very old use case where active used to be 0
  ACTIVE: 'Active',
  INACTIVE_CHANGED: 'Inactive - Changed',
  INACTIVE_CHANGE_REQUESTED: 'Inactive - Change Requested',
  INACTIVE_CHANGE_REJECTED: 'Inactive - Change Rejected',
});
