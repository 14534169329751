/* LES CONVENTIONS DES NOMMES OV DA CONSTUNTS (for the most part)
 * - FETCH: Fetch from database
 * - DELETE: Delete a STATE from the STORE
 * - DESTROY: Any other types of deletes
 */

// Admin.js
export const FETCH_ADMIN_INPROGRESS = 'FETCH_ADMIN_INPROGRESS';
export const FETCH_ADMIN_SUCCESS = 'FETCH_ADMIN_SUCCESS';
export const FETCH_ADMIN_ERROR = 'FETCH_ADMIN_ERROR';

// Dispatcher.js
export const FETCH_DISPATCHER_INPROGRESS = 'FETCH_DISPATCHER_INPROGRESS';
export const FETCH_DISPATCHER_SUCCESS = 'FETCH_DISPATCHER_SUCCESS';
export const FETCH_DISPATCHER_ERROR = 'FETCH_DISPATCHER_ERROR';

// Documents.js
export const FETCH_DOCUMENTS_INPROGRESS = 'FETCH_DOCUMENTS_INPROGRESS';
export const FETCH_DOCUMENTS_SUCCESS = 'FETCH_DOCUMENTS_SUCCESS';
export const FETCH_DOCUMENTS_ERROR = 'FETCH_DOCUMENTS_ERROR';
export const DELETE_DOCUMENTS = 'DELETE_DOCUMENTS';
export const ADD_DOCUMENTS_SUCCESS = 'ADD_DOCUMENTS_SUCCESS';
export const ADD_DOCUMENTS_INPROGRESS = 'ADD_DOCUMENTS_INPROGRESS';
export const ADD_DOCUMENTS_ERROR = 'ADD_DOCUMENTS_ERROR';
export const UPDATE_DOCUMENT_INPROGRESS = 'UPDATE_DOCUMENT_INPROGRESS';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_ERROR = 'UPDATE_DOCUMENT_ERROR';
export const DESTROY_DOCUMENTS_SUCCESS = 'DESTROY_DOCUMENTS_SUCCESS';
export const DOCUMENT_REUPLOAD_PROMPT = 'DOCUMENT_REUPLOAD_PROMPT';
export const ADD_DOCUMENT_RETAKE = 'ADD_DOCUMENT_RETAKE';

// Chat.js
export const FETCH_CHATROOM_INPROGRESS = 'FETCH_CHATROOM_INPROGRESS';
export const FETCH_CHATROOM_SUCCESS = 'FETCH_CHATROOM_SUCCESS';
export const FETCH_CHATROOM_ERROR = 'FETCH_CHATROOM_ERROR';
export const FETCH_CHAT_MESSAGES_INPROGRESS = 'FETCH_CHAT_MESSAGES_INPROGRESS';
export const FETCH_CHAT_MESSAGES_SUCCESS = 'FETCH_CHAT_MESSAGES_SUCCESS';
export const FETCH_CHAT_MESSAGES_ERROR = 'FETCH_CHAT_MESSAGES_ERROR';
export const CHAT_MESSAGES_ADD = 'CHAT_MESSAGES_ADD';
export const CHAT_MESSAGES_RECEIVE = 'CHAT_MESSAGES_RECEIVE';
export const CHAT_MESSAGES_MARK_READ = 'CHAT_MESSAGES_MARK_READ';
export const DELETE_CHAT_MESSAGES = 'DELETE_CHAT_MESSAGES';
export const FETCH_CHAT_FRIENDS_INPROGRESS = 'FETCH_CHAT_FRIENDS_INPROGRESS';
export const FETCH_CHAT_FRIENDS_SUCCESS = 'FETCH_CHAT_FRIENDS_SUCCESS';
export const FETCH_CHAT_FRIENDS_ERROR = 'FETCH_CHAT_FRIENDS_ERROR';
export const ADD_CHAT_FRIEND_SUCCESS = 'ADD_CHAT_FRIEND_SUCCESS';
export const DELETE_CHAT_FRIENDS = 'DELETE_CHAT_FRIENDS';

// Company.js
export const FETCH_COMPANY_INPROGRESS = 'FETCH_COMPANY_INPROGRESS';
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_ERROR = 'FETCH_COMPANY_ERROR';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';

// CloudUserPreference.js
export const FETCH_CLOUDUSERPREFERENCE_INPROGRESS = 'FETCH_CLOUDUSERPREFERENCE_INPROGRESS';
export const FETCH_CLOUDUSERPREFERENCE_SUCCESS = 'FETCH_CLOUDUSERPREFERENCE_SUCCESS';
export const FETCH_CLOUDUSERPREFERENCE_ERROR = 'FETCH_CLOUDUSERPREFERENCE_ERROR';
export const UPDATE_CLOUDUSERPREFERENCE_SUCCESS = 'UPDATE_CLOUDUSERPREFERENCE_SUCCESS';

// DocumentLayout.js
export const CHANGE_DOCUMENTS_VIEW = 'CHANGE_DOCUMENTS_VIEW';

// DocumentCategory.js
export const FETCH_DOCUMENTCATEGORIES_INPROGRESS = 'FETCH_DOCUMENTCATEGORIES_INPROGRESS';
export const FETCH_DOCUMENTCATEGORIES_SUCCESS = 'FETCH_DOCUMENTCATEGORIES_SUCCESS';
export const FETCH_DOCUMENTCATEGORIES_ERROR = 'FETCH_DOCUMENTCATEGORIES_ERROR';

// Driver.js
export const FETCH_DRIVERS_INPROGRESS = 'FETCH_DRIVERS_INPROGRESS';
export const FETCH_DRIVERS_SUCCESS = 'FETCH_DRIVERS_SUCCESS';
export const FETCH_DRIVERS_ERROR = 'FETCH_DRIVERS_ERROR';
export const ADD_DRIVER_INPROGRESS = 'ADD_DRIVER_INPROGRESS';
export const ADD_DRIVER_SUCCESS = 'ADD_DRIVER_SUCCESS';
export const ADD_DRIVER_ERROR = 'ADD_DRIVER_ERROR';
export const UPDATE_DRIVER_INPROGRESS = 'UPDATE_DRIVER_INPROGRESS';
export const UPDATE_DRIVER_SUCCESS = 'UPDATE_DRIVER_SUCCESS';
export const UPDATE_DRIVER_ERROR = 'UPDATE_DRIVER_ERROR';
export const DELETE_DRIVERS = 'DELETE_DRIVERS';
export const DISABLE_DRIVERS_SUCCESS = 'DISABLE_DRIVERS_SUCCESS';
export const ENABLE_DRIVERS_SUCCESS = 'ENABLE_DRIVERS_SUCCESS';

// ELDEvents.js
export const FETCH_ELDEVENTS_INPROGRESS = 'FETCH_ELDEVENTS_INPROGRESS';
export const FETCH_ELDEVENTS_SUCCESS = 'FETCH_ELDEVENTS_SUCCESS';
export const FETCH_ELDEVENTS_ERROR = 'FETCH_ELDEVENTS_ERROR';
export const ADD_ELDEVENT_SUCCESS = 'ADD_ELDEVENTS_SUCCESS';
export const ADD_ELDEVENT_INPROGRESS = 'ADD_ELDEVENTS_INPROGRESS';
export const ADD_ELDEVENT_ERROR = 'ADD_ELDEVENTS_ERROR';
export const UPDATE_ELDEVENTS_INPROGRESS = 'UPDATE_ELDEVENTS_INPROGRESS';
export const UPDATE_ELDEVENTS_SUCCESS = 'UPDATE_ELDEVENTS_SUCCESS';
export const UPDATE_ELDEVENTS_ERROR = 'UPDATE_ELDEVENTS_ERROR';
export const DELETE_ELDEVENTS = 'DELETE_ELDEVENTS';

export const ADD_ELDEDIT_SUCCESS = 'ADD_ELDEDIT_SUCCESS';

// ELDViolations.js
export const FETCH_ELDVIOLATIONS_INPROGRESS = 'FETCH_ELDVIOLATIONS_INPROGRESS';
export const FETCH_ELDVIOLATIONS_SUCCESS = 'FETCH_ELDVIOLATIONS_SUCCESS';
export const FETCH_ELDVIOLATIONS_ERROR = 'FETCH_ELDVIOLATIONS_ERROR';
export const ADD_ELDVIOLATION_SUCCESS = 'ADD_ELDVIOLATIONS_SUCCESS';
export const ADD_ELDVIOLATION_INPROGRESS = 'ADD_ELDVIOLATIONS_INPROGRESS';
export const ADD_ELDVIOLATION_ERROR = 'ADD_ELDVIOLATIONS_ERROR';
export const UPDATE_ELDVIOLATIONS_INPROGRESS = 'UPDATE_ELDVIOLATIONS_INPROGRESS';
export const UPDATE_ELDVIOLATIONS_SUCCESS = 'UPDATE_ELDVIOLATIONS_SUCCESS';
export const UPDATE_ELDVIOLATIONS_ERROR = 'UPDATE_ELDVIOLATIONS_ERROR';
export const DELETE_ELDVIOLATIONS = 'DELETE_ELDVIOLATIONS';

// Geofence.js
export const FETCH_GEOFENCES_INPROGRESS = 'FETCH_GEOFENCES_INPROGRESS';
export const FETCH_GEOFENCES_SUCCESS = 'FETCH_GEOFENCES_SUCCESS';
export const FETCH_GEOFENCES_ERROR = 'FETCH_GEOFENCES_ERROR';
export const ADD_GEOFENCE_INPROGRESS = 'ADD_GEOFENCE_INPROGRESS';
export const ADD_GEOFENCE_SUCCESS = 'ADD_GEOFENCE_SUCCESS';
export const ADD_GEOFENCE_ERROR = 'ADD_GEOFENCE_ERROR';
export const DELETE_GEOFENCES = 'DELETE_GEOFENCES';
export const DESTROY_GEOFENCES = 'DESTROY_GEOFENCES';
export const UPDATE_GEOFENCE_INPROGRESS = 'UPDATE_GEOFENCE_INPROGRESS';
export const UPDATE_GEOFENCE_SUCCESS = 'UPDATE_GEOFENCE_SUCCESS';
export const UPDATE_GEOFENCE_ERROR = 'UPDATE_GEOFENCE_ERROR';

// GeofenceEvents.js
export const FETCH_GEOFENCEEVENTS_INPROGRESS = 'FETCH_GEOFENCEEVENTS_INPROGRESS';
export const FETCH_GEOFENCEEVENTS_SUCCESS = 'FETCH_GEOFENCEEVENTS_SUCCESS';
export const FETCH_GEOFENCEEVENTS_ERROR = 'FETCH_GEOFENCEEVENTS_ERROR';
export const ADD_GEOFENCEEVENT_INPROGRESS = 'ADD_GEOFENCEEVENT_INPROGRESS';
export const ADD_GEOFENCEEVENT_SUCCESS = 'ADD_GEOFENCEEVENT_SUCCESS';
export const ADD_GEOFENCEEVENT_ERROR = 'ADD_GEOFENCEEVENT_ERROR';
export const DELETE_GEOFENCEEVENTS = 'DELETE_GEOFENCEEVENTS';
export const DESTROY_GEOFENCEEVENTS = 'DESTROY_GEOFENCEEVENTS';
export const UPDATE_GEOFENCEEVENT_INPROGRESS = 'UPDATE_GEOFENCEEVENT_INPROGRESS';
export const UPDATE_GEOFENCEEVENT_SUCCESS = 'UPDATE_GEOFENCEEVENT_SUCCESS';
export const UPDATE_GEOFENCEEVENT_ERROR = 'UPDATE_GEOFENCEEVENT_ERROR';

// JobAction.js
export const FETCH_JOBACTIONS_INPROGRESS = 'FETCH_JOBACTIONS_INPROGRESS';
export const FETCH_JOBACTIONS_SUCCESS = 'FETCH_JOBACTIONS_SUCCESS';
export const FETCH_JOBACTIONS_ERROR = 'FETCH_JOBACTIONS_ERROR';
export const ADD_JOBACTION_INPROGRESS = 'ADD_JOBACTION_INPROGRESS';
export const ADD_JOBACTION_SUCCESS = 'ADD_JOBACTION_SUCCESS';
export const ADD_JOBACTION_ERROR = 'ADD_JOBACTION_ERROR';
export const UPDATE_JOBACTION_INPROGRESS = 'UPDATE_JOBACTION_INPROGRESS';
export const UPDATE_JOBACTION_SUCCESS = 'UPDATE_JOBACTION_SUCCESS';
export const UPDATE_JOBACTION_ERROR = 'UPDATE_JOBACTION_ERROR';

// JobLink.js
export const FETCH_JOBLINKS_INPROGRESS = 'FETCH_JOBLINKS_INPROGRESS';
export const FETCH_JOBLINKS_SUCCESS = 'FETCH_JOBLINKS_SUCCESS';
export const FETCH_JOBLINKS_ERROR = 'FETCH_JOBLINKS_ERROR';
export const DELETE_JOBLINKS = 'DELETE_JOBLINKS';
export const ADD_JOBLINK_INPROGRESS = 'ADD_JOBLINK_INPROGRESS';
export const ADD_JOBLINK_SUCCESS = 'ADD_JOBLINK_SUCCESS';
export const ADD_JOBLINK_ERROR = 'ADD_JOBLINK_ERROR';
export const DESTROY_JOBLINKS_SUCCESS = 'DESTROY_JOBLINKS_SUCCESS';
export const JOBLINK_ASSIGN_DRIVER = 'JOBLINK_ASSIGN_DRIVER';
export const JOBLINK_UNASSIGN_DRIVER = 'JOBLINK_UNASSIGN_DRIVER';
export const JOBLINKS_COMPLETED_SUCCESS = 'JOBLINKS_COMPLETED_SUCCESS';
export const JOBLINK_DOCUMENTUPLOAD_INPROGRESS = 'JOBLINK_DOCUMENTUPLOAD_INPROGRESS';
export const JOBLINK_DOCUMENTUPLOAD_SUCCESS = 'JOBLINK_DOCUMENTUPLOAD_SUCCESS';
export const JOBLINK_DOCUMENTUPLOAD_ERROR = 'JOBLINK_DOCUMENTUPLOAD_ERROR';

// Login.js
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGIN_INPROGRESS = 'LOGIN_INPROGRESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

// Main.js
export const CLEAR_STATE = 'CLEAR_STATE';
export const FETCH_USER_GLOBALS_INPROGRESS = 'FETCH_USER_GLOBALS_INPROGRESS';
export const FETCH_USER_GLOBALS_SUCCESS = 'FETCH_USER_GLOBALS_SUCCESS';
export const FETCH_USER_GLOBALS_ERROR = 'FETCH_USER_GLOBALS_ERROR';
export const INITIALIZE = 'INITIALIZE';
export const HEADER_PANEL_SELECT = 'HEADER_PANEL_SELECT';
export const PUBNUB_SETUP_INPROGRESS = 'PUBNUB_SETUP_INPROGRESS';
export const PUBNUB_SETUP_SUCCESS = 'PUBNUB_SETUP_SUCCESS';

// Mapbox.js
export const INITIALIZE_MAPBOX = 'INITIALIZE_MAPBOX';

// Here.js
export const INITIALIZE_HERE = 'INITIALIZE_HERE';

// Notification.js
export const FETCH_NOTIFICATIONS_INPROGRESS = 'FETCH_NOTIFICATIONS_INPROGRESS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_ERROR = 'FETCH_NOTIFICATIONS_ERROR';
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';
export const NOTIFICATION_SET_READ = 'NOTIFICATION_SET_READ';
export const NOTIFICATION_RECEIVE = 'NOTIFICATION_RECEIVE';
export const NOTIFICATION_PANEL_TOGGLE = 'NOTIFICATION_PANEL_TOGGLE';
export const NOTIFICATION_SUBSCRIBE_SUCCESS = 'NOTIFICATION_SUBSCRIBE_SUCCESS';
export const NOTIFICATION_UNSUBSCRIBE_SUCCESS = 'NOTIFICATION_UNSUBSCRIBE_SUCCESS';
export const DESTROY_NOTIFICATION_SUCCESS = 'DESTROY_NOTIFICATION_SUCCESS';

// SafetyOfficer.js
export const FETCH_SAFETYOFFICER_INPROGRESS = 'FETCH_SAFETYOFFICER_INPROGRESS';
export const FETCH_SAFETYOFFICER_SUCCESS = 'FETCH_SAFETYOFFICER_SUCCESS';
export const FETCH_SAFETYOFFICER_ERROR = 'FETCH_SAFETYOFFICER_ERROR';

// Subscription.js
export const FETCH_SUBSCRIPTION_INPROGRESS = 'FETCH_SUBSCRIPTION_INPROGRESS';
export const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS';
export const FETCH_SUBSCRIPTION_ERROR = 'FETCH_SUBSCRIPTION_ERROR';
export const INITIALIZE_STRIPE = 'INITIALIZE_STRIPE';
export const SUBSCRIPTION_CREATE_INPROGRESS = 'SUBSCRIPTION_CREATE_INPROGRESS';
export const SUBSCRIPTION_CREATE_SUCCESS = 'SUBSCRIPTION_CREATE_SUCCESS';
export const SUBSCRIPTION_UPGRADE_SUCCESS = 'SUBSCRIPTION_UPGRADE_SUCCESS';
export const SUBSCRIPTION_WARNING_IGNORE = 'SUBSCRIPTION_WARNING_IGNORE';
export const SUBSCRIPTION_STATUS_SUCCESS = 'SUBSCRIPTION_STATUS_SUCCESS';

// Trailer.js
export const FETCH_TRAILERS_INPROGRESS = 'FETCH_TRAILERS_INPROGRESS';
export const FETCH_TRAILERS_SUCCESS = 'FETCH_TRAILERS_SUCCESS';
export const FETCH_TRAILERS_ERROR = 'FETCH_TRAILERS_ERROR';
export const ADD_TRAILER_INPROGRESS = 'ADD_TRAILER_INPROGRESS';
export const ADD_TRAILER_SUCCESS = 'ADD_TRAILER_SUCCESS';
export const ADD_TRAILER_ERROR = 'ADD_TRAILER_ERROR';
export const UPDATE_TRAILER_INPROGRESS = 'UPDATE_TRAILER_INPROGRESS';
export const UPDATE_TRAILER_SUCCESS = 'UPDATE_TRAILER_SUCCESS';
export const UPDATE_TRAILER_ERROR = 'UPDATE_TRAILER_ERROR';
export const DELETE_TRAILERS = 'DELETE_TRAILERS';
export const DISABLE_TRAILERS_SUCCESS = 'DISABLE_TRAILERS_SUCCESS';
export const ENABLE_TRAILERS_SUCCESS = 'ENABLE_TRAILERS_SUCCESS';

// GlobalTrailer.js
export const FETCH_GLOBALTRAILERS_INPROGRESS = 'FETCH_GLOBALTRAILERS_INPROGRESS';
export const FETCH_GLOBALTRAILERS_SUCCESS = 'FETCH_GLOBALTRAILERS_SUCCESS';
export const FETCH_GLOBALTRAILERS_ERROR = 'FETCH_GLOBALTRAILERS_ERROR';
export const ADD_GLOBALTRAILER_INPROGRESS = 'ADD_GLOBALTRAILER_INPROGRESS';
export const ADD_GLOBALTRAILER_SUCCESS = 'ADD_GLOBALTRAILER_SUCCESS';
export const ADD_GLOBALTRAILER_ERROR = 'ADD_GLOBALTRAILER_ERROR';
export const UPDATE_GLOBALTRAILER_INPROGRESS = 'UPDATE_GLOBALTRAILER_INPROGRESS';
export const UPDATE_GLOBALTRAILER_SUCCESS = 'UPDATE_GLOBALTRAILER_SUCCESS';
export const UPDATE_GLOBALTRAILER_ERROR = 'UPDATE_GLOBALTRAILER_ERROR';
export const DELETE_GLOBALTRAILERS = 'DELETE_GLOBALTRAILERS';
export const DISABLE_GLOBALTRAILERS_SUCCESS = 'DISABLE_GLOBALTRAILERS_SUCCESS';

// User.js
export const FETCH_USER_INPROGRESS = 'FETCH_USER_INPROGRESS';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR';
export const REGISTER_USER_INPROGRESS = 'REGISTER_USER_INPROGRESS';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

// Receipt.js
export const FETCH_RECEIPTS_INPROGRESS = 'FETCH_RECEIPTS_INPROGRESS';
export const FETCH_RECEIPTS_SUCCESS = 'FETCH_RECEIPTS_SUCCESS';
export const FETCH_RECEIPTS_ERROR = 'FETCH_RECEIPTS_ERROR';
export const DELETE_RECEIPTS = 'DELETE_RECEIPTS';
export const ADD_RECEIPT_SUCCESS = 'ADD_RECEIPT_SUCCESS';

// Temp.js
export const DELETE_TEMP = 'DELETE_TEMP';
export const UPDATE_TEMP_INPROGRESS = 'UPDATE_TEMP_INPROGRESS';
export const UPDATE_TEMP_SUCCESS = 'UPDATE_TEMP_SUCCESS';
export const UPDATE_TEMP_ERROR = 'UPDATE_TEMP_ERROR';

// Vehicle.js
export const FETCH_VEHICLES_INPROGRESS = 'FETCH_VEHICLES_INPROGRESS';
export const FETCH_VEHICLES_SUCCESS = 'FETCH_VEHICLES_SUCCESS';
export const FETCH_VEHICLES_ERROR = 'FETCH_VEHICLES_ERROR';
export const ADD_VEHICLE_INPROGRESS = 'ADD_VEHICLE_INPROGRESS';
export const ADD_VEHICLE_SUCCESS = 'ADD_VEHICLE_SUCCESS';
export const ADD_VEHICLE_ERROR = 'ADD_VEHICLE_ERROR';
export const UPDATE_VEHICLE_INPROGRESS = 'UPDATE_VEHICLE_INPROGRESS';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_ERROR = 'UPDATE_VEHICLE_ERROR';
export const DELETE_VEHICLES = 'DELETE_VEHICLES';
export const DISABLE_VEHICLES_SUCCESS = 'DISABLE_VEHICLES_SUCCESS';
export const ENABLE_VEHICLES_SUCCESS = 'ENABLE_VEHICLES_SUCCESS';

// GlobalVehicle.js
export const FETCH_GLOBALVEHICLES_INPROGRESS = 'FETCH_GLOBALVEHICLES_INPROGRESS';
export const FETCH_GLOBALVEHICLES_SUCCESS = 'FETCH_GLOBALVEHICLES_SUCCESS';
export const FETCH_GLOBALVEHICLES_ERROR = 'FETCH_GLOBALVEHICLES_ERROR';
export const ADD_GLOBALVEHICLE_INPROGRESS = 'ADD_GLOBALVEHICLE_INPROGRESS';
export const ADD_GLOBALVEHICLE_SUCCESS = 'ADD_GLOBALVEHICLE_SUCCESS';
export const ADD_GLOBALVEHICLE_ERROR = 'ADD_GLOBALVEHICLE_ERROR';
export const UPDATE_GLOBALVEHICLE_INPROGRESS = 'UPDATE_GLOBALVEHICLE_INPROGRESS';
export const UPDATE_GLOBALVEHICLE_SUCCESS = 'UPDATE_GLOBALVEHICLE_SUCCESS';
export const UPDATE_GLOBALVEHICLE_ERROR = 'UPDATE_GLOBALVEHICLE_ERROR';
export const DELETE_GLOBALVEHICLES = 'DELETE_GLOBALVEHICLES';
export const DISABLE_GLOBALVEHICLES_SUCCESS = 'DISABLE_GLOBALVEHICLES_SUCCESS';
export const ENABLE_GLOBALVEHICLES_SUCCESS = 'ENABLE_GLOBALVEHICLES_SUCCESS';
// VehicleLocation.js
export const FETCH_VEHICLELOCATIONS_INPROGRESS = 'FETCH_VEHICLELOCATIONS_INPROGRESS';
export const FETCH_VEHICLELOCATIONS_SUCCESS = 'FETCH_VEHICLELOCATIONS_SUCCESS';
export const FETCH_VEHICLELOCATIONS_ERROR = 'FETCH_VEHICLELOCATIONS_ERROR';
export const DELETE_VEHICLELOCATIONS = 'DELETE_VEHICLELOCATIONS';

// Vendor.js
export const FETCH_VENDORS_INPROGRESS = 'FETCH_VENDORS_INPROGRESS';
export const FETCH_VENDORS_SUCCESS = 'FETCH_VENDORS_SUCCESS';
export const FETCH_VENDORS_ERROR = 'FETCH_VENDORS_ERROR';
export const ADD_VENDOR_INPROGRESS = 'ADD_VENDOR_INPROGRESS';
export const ADD_VENDOR_SUCCESS = 'ADD_VENDOR_SUCCESS';
export const ADD_VENDOR_ERROR = 'ADD_VENDOR_ERROR';
export const UPDATE_VENDOR_INPROGRESS = 'UPDATE_VENDOR_INPROGRESS';
export const UPDATE_VENDOR_SUCCESS = 'UPDATE_VENDOR_SUCCESS';
export const UPDATE_VENDOR_ERROR = 'UPDATE_VENDOR_ERROR';
export const DELETE_VENDORS = 'DELETE_VENDORS';
export const DISABLE_VENDORS_SUCCESS = 'DISABLE_VENDORS_SUCCESS';
