import React, { useState, useEffect } from 'react';

// API
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { formatStringLength } from 'api/Helpers'; // Won't be needed when tab name creation is refactored

// Components
import TabMenu from 'sbCore/TabMenu/TabMenu';
import Button from 'sbCore/Button/Button';
import Tooltip from 'sbCore/Tooltip/Tooltip';

// Styling
import './style.scss';

/**
* @description When passed dispatchTransfers, renders the shipment tabs of a dispatch job.
* @param {Array} dispatchTransfers - The array of dispatchTransfers to render tabs for
* @param {Integer} activeIndex - The index of the currently selected dispatch transfer
* @param {Function} onClickDispatchShipment - Function that's called when a dispatchTransfer tab is clicked
* @param {Function} onClickConfirmDeleteModal - Function that's called when a dispatchTransfer is deleted
* @returns
*
* @example
* <DispatchShipmentTabs
*   dispatchTransfers={this.state.dispatchTransfers}
*   activeIndex={this.state.activeIndex}
*   onClickDispatchShipment={(dispatchShipment, index) => this.onClickDispatchShipment(dispatchShipment, index)}
*   onClickConfirmDeleteModal={(dispatchShipment, index) => this.onClickConfirmDeleteModal(dispatchShipment, index)}
*  />
*/
function DispatchShipmentTabs(props) {

  // ** useStates ** //
  const [shipmentTabs, setShipmentTabs] = useState([]);

  // ** useEffects ** //

  // When the dispatchTransfers are updated, generate the shipment tabs
  useEffect(() => {
    createShipmentTabs();
  }, [props.dispatchTransfers]);

  function createShipmentTabs() {
    const shipmentTabs = [];
    const shipperConsigneeHash = {};

    let numberOfShippers = 0;
    let numberOfConsignees = 0;

    // for each dispatchTransfer, generate a shipment tab and give it the correct shipment code
    props.dispatchTransfers.forEach((dispatchTransfer, index) => {
      let shipperCodeShort = '**';
      let consigneeCodeShort = '**';
      let shipperCodeLong = '**';
      let consigneeCodeLong = '**';
      let shipperDispatchOrganizationObjectId = '';
      let consigneeDispatchOrganizationObjectId = '';

      // if the dispatchTransfer exists, create the shipper/consignee names for the tab.
      if (dispatchTransfer) {

        // Create short and long shipper names for the the dispatch transfer's shipment tab
        const shipperDispatchOrganization = getAttribute(dispatchTransfer, 'shipperDispatchOrganization');

        if (shipperDispatchOrganization) {
          shipperDispatchOrganizationObjectId = getAttribute(shipperDispatchOrganization, 'objectId');

          const shipperOrganizationName = getAttribute(shipperDispatchOrganization, 'organizationName');
          const shipperOrganizationId = getAttribute(shipperDispatchOrganization, 'organizationId');

          if (shipperOrganizationId) {
            shipperCodeShort = formatStringLength(shipperOrganizationId, 4).replace('...', '').toUpperCase();
            shipperCodeLong = shipperOrganizationId.toUpperCase();
          } else {
            shipperCodeShort = formatStringLength(shipperOrganizationName, 2).replace('...', '').toUpperCase();
            shipperCodeLong = shipperOrganizationName.toUpperCase();
          }
        }

        // Create short and long consignee names for the the dispatch transfer's shipment tab
        const consigneeDispatchOrganization = getAttribute(dispatchTransfer, 'consigneeDispatchOrganization');

        if (consigneeDispatchOrganization) {
          consigneeDispatchOrganizationObjectId = getAttribute(consigneeDispatchOrganization, 'objectId');

          const consigneeOrganizationName = getAttribute(consigneeDispatchOrganization, 'organizationName');
          const consigneeOrganizationId = getAttribute(consigneeDispatchOrganization, 'organizationId');

          if (consigneeOrganizationId) {
            consigneeCodeShort = formatStringLength(consigneeOrganizationId, 4).toUpperCase();
            consigneeCodeLong = consigneeOrganizationId.toUpperCase();
          } else {
            consigneeCodeShort = formatStringLength(consigneeOrganizationName, 2).toUpperCase();
            consigneeCodeLong = consigneeOrganizationName.toUpperCase();
          }
        }
      }

      // Define a prop named after a unique shipperDispatchOrganizationObjectId for
      // the shipperConsigneeHash which contains the current number of shippers
      if (shipperDispatchOrganizationObjectId !== '' && !shipperConsigneeHash.hasOwnProperty(`${shipperDispatchOrganizationObjectId}-shipper`)) {
        numberOfShippers++;

        Object.defineProperty(shipperConsigneeHash, `${shipperDispatchOrganizationObjectId}-shipper`, {
          value: numberOfShippers,
          writable: true,
        });
      }

      // Define a prop named after a unique consigneeDispatchOrganizationObjectId for
      // the shipperConsigneeHash which contains the current number of consignees
      if (consigneeDispatchOrganizationObjectId !== '' && !shipperConsigneeHash.hasOwnProperty(`${consigneeDispatchOrganizationObjectId}-consignee`)) {
        numberOfConsignees++;

        Object.defineProperty(shipperConsigneeHash, `${consigneeDispatchOrganizationObjectId}-consignee`, {
          value: numberOfConsignees,
          writable: true,
        });
      }

      // Assign the pickup number to the shipperConsigneeHash attribute associated with the current
      // dispatchTransfer's shipperDispatchOrganizationObjectId
      const pickupNumber = shipperConsigneeHash[`${shipperDispatchOrganizationObjectId}-shipper`]
        ? shipperConsigneeHash[`${shipperDispatchOrganizationObjectId}-shipper`]
        : 0;

      // Assign the dropoff number to the shipperConsigneeHash attribute associated with the current
      // dispatchTransfer's consigneeDispatchOrganizationObjectId
      const dropoffNumber = shipperConsigneeHash[`${consigneeDispatchOrganizationObjectId}-consignee`]
        ? shipperConsigneeHash[`${consigneeDispatchOrganizationObjectId}-consignee`]
        : 0;

      const shipmentTabNameShort = `P${pickupNumber}D${dropoffNumber}-${shipperCodeShort}-${consigneeCodeShort}`;
      const shipmentTabNameLong = `P${pickupNumber}D${dropoffNumber}-${shipperCodeLong}-${consigneeCodeLong}`;

      // Create a new shipment tab and push it to the shipment tabs array
      const newShipmentTab = {
        label: (
          <>
            <Tooltip className="shipment-tooltip" target=".shipment-tab" />
            <div
              className="dispatch-shipment-tabs shipment-tab"
              data-pr-tooltip={`${shipmentTabNameLong}`}
              data-pr-position="bottom"
            >
              <div className="shipment-tab-text">
                {shipmentTabNameShort}
              </div>
              <div className="delete-shipment-button">
                <Button
                  icon="pi pi-times"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickConfirmDeleteModal(index);
                  }}
                  className="p-button-rounded p-button-text"
                />
              </div>
            </div>
          </>
        ),
      };

      shipmentTabs.push(newShipmentTab);
    });

    setShipmentTabs(shipmentTabs);
  }

  // ** Callback Functions ** //

  // When a tab is deleted, the dispatchTransfer deleted and the index of its tab is passed to delete it
  function onClickConfirmDeleteModal(index) {
    if (Object.keys(props.dispatchTransfers).length > 1) {
      props.onClickConfirmDeleteModal(props.dispatchTransfers[index], index);
    }
  }

  // When a tab is clicked, the dispatchTransfer tab clicked and its tab index is passed to update the dispatchTransfer being viewed
  function onClickDispatchShipment(index) {
    if (props.onClickDispatchShipment) {
      props.onClickDispatchShipment(props.dispatchTransfers[index], index);
    }
  }

  // ** Components ** //
  const shipmentTabsMenu = (
    <TabMenu className="temp" tooltip="Enter your username" model={shipmentTabs} activeIndex={props.activeIndex} onTabChange={(tab) => onClickDispatchShipment(tab.index)} />
  );

  return (
    <div className="dispatch-shipment-tabs">
      {shipmentTabsMenu}
    </div>
  );
}

export default DispatchShipmentTabs;
