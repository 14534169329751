import React from 'react';
import PropTypes from 'prop-types';

// Components
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';

class DispatchShipmentConfirmModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(confirmBool) {
    const { props } = this;

    if (confirmBool) {
      props.handleSubmit();
    }

    props.handleClose();
  }

  render() {
    const { props } = this;

    return (
      <ConfirmModal
        title={props.title}
        show={props.show}
        handleModalChoice={(confirmBool) => this.handleSubmit(confirmBool)}
        handleClose={props.handleClose}
      >
        {props.body}
      </ConfirmModal>
    );
  }
}

DispatchShipmentConfirmModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export default DispatchShipmentConfirmModal;
