import React, { useState, useEffect } from 'react';

// components
import Message from 'sbCore/Message/Message';

import IFTALayoutOld from 'layouts/IFTALayout.old/IFTALayout';
import IFTAVehicleJurisdictionDataTable from 'components/IFTA/IFTAVehicleJurisdictionView/IFTAVehicleJurisdictionDataTable';
import IFTAVehicleIntervalDataTable from 'components/IFTA/IFTAVehicleIntervalView/IFTAVehicleIntervalDataTable';
import IFTAVehicleDayView from 'components/IFTA/IFTAVehicleDayView/IFTAVehicleDayView';

function IFTALayout({ ...props }) {

  const View = Object.freeze({
    JURISDICTION_OVERALL: 1,
    VEHICLE_INTERVAL: 2,
    VEHICLE_DAY: 3,
  });

  const [showNewIFTA, setShowNewIFTA] = useState(false);
  const [currentView, setCurrentView] = useState(View.JURISDICTION_OVERALL);

  // From IFTAVehicleJurisdictionDataTable
  const [vehicleJurisdictionMap, setVehicleJurisdictionMap] = useState({});
  const [selectedVehicleIFTAData, setSelectedVehicleIFTAData] = useState(null);

  // From IFTAVehicleIntervalDataTable
  const [selectedDayData, setSelectedDayData] = useState(null);

  // Settings (onUpdateSettings)
  const [dateInterval, setDateInterval] = useState(null);
  const [lengthUnit, setLengthUnit] = useState(null);
  const [hideEmptyStateProvinces, setHideEmptyStateProvinces] = useState(null);

  // fetch indicators
  const [isFetchedIFTAVehicleJurisdictionData, setIsFetchedIFTAVehicleJurisdictionData] = useState(false);

  /**
   * @description Handles the data retrieved from IFTAVehicleJurisdictionDataTable
   *              that would be useful for this parent or other child components
   *              Only runs after IFTAVehicleJurisdictionDataTable is done fetching
  */
  function handleFetchedIFTAData(_vehicleJurisdictionMap) {
    setVehicleJurisdictionMap(_vehicleJurisdictionMap);
    setIsFetchedIFTAVehicleJurisdictionData(true); // determine this part of IFTA as fetched
  }

  /**
   * @description Handles the input settings from child components, for if they need to be
   *              replicated in a parent or sibling
  */
  function onUpdateSettings(_dateInterval, _lengthUnit, _hideEmptyStateProvinces) {
    setDateInterval(_dateInterval);
    setHideEmptyStateProvinces(_hideEmptyStateProvinces);
    setLengthUnit(_lengthUnit);
  }

  const messageContent = (
    <div>
      <div className="inline-block">
        New and improved IFTA still under construction. Check in once in a while for updates! &nbsp;
        <div className="inline-block underline cursor-pointer text-blue"  onClick={() => setShowNewIFTA(!showNewIFTA)}>
          Click here
        </div> to go back
      </div>
    </div>
  );

  return (
    <div className="ifta-layout">
      {!showNewIFTA && (
        <IFTALayoutOld
          location={props.location}
          showNewIFTA={showNewIFTA}
          toggleNewIFTA={() => setShowNewIFTA(!showNewIFTA)}
        />
      )}
      {showNewIFTA && (
        <div className="px-5 py-5">
          { currentView === View.JURISDICTION_OVERALL && (
            <div className="mb-5 text-center">
              <Message content={messageContent} severity="warn" />
            </div>
          )}
          {currentView === View.JURISDICTION_OVERALL && (
            <IFTAVehicleJurisdictionDataTable
              scrollHeight="60vh"
              handleFetchedIFTAData={
                (_dateInterval, _vehicleJurisdictionMap, _lengthUnit, _hideEmptyStateProvinces) => handleFetchedIFTAData(_dateInterval, _vehicleJurisdictionMap, _lengthUnit, _hideEmptyStateProvinces)
              }
              onUpdateSettings={(_dateInterval, _lengthUnit, _hideEmptyStateProvinces) => onUpdateSettings(_dateInterval, _lengthUnit, _hideEmptyStateProvinces)}
              onSelectVehicle={(_selectedVehicleIFTAData) => {
                setSelectedVehicleIFTAData(_selectedVehicleIFTAData);
                setCurrentView(View.VEHICLE_INTERVAL);
              }}
              dateInterval={dateInterval}
              lengthUnit={lengthUnit}
              hideEmptyStateProvinces={hideEmptyStateProvinces}
            />
          )}
          {currentView === View.VEHICLE_INTERVAL && (
            <IFTAVehicleIntervalDataTable
              scrollHeight="60vh"
              vehicleIFTAData={selectedVehicleIFTAData}
              lengthUnit={lengthUnit}
              hideEmptyStateProvinces={hideEmptyStateProvinces}
              dateInterval={dateInterval}
              onUpdateSettings={(_dateInterval, _lengthUnit, _hideEmptyStateProvinces) => onUpdateSettings(_dateInterval, _lengthUnit, _hideEmptyStateProvinces)}
              onSelectDay={(_selectedDayData) => {
                setSelectedDayData(_selectedDayData);
                setCurrentView(View.VEHICLE_DAY);
              }}
              onBack={() => setCurrentView(View.JURISDICTION_OVERALL)}
            />
          )}
          {currentView === View.VEHICLE_DAY && (
            <IFTAVehicleDayView
              vehicleIFTAData={selectedVehicleIFTAData}
              dayData={selectedDayData}
              lengthUnit={lengthUnit}
              onBack={() => setCurrentView(View.VEHICLE_INTERVAL)}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default IFTALayout;
