import React, { useEffect, useState } from 'react';

// sbCore
import Card from 'sbCore/Card/Card';
import Button from 'sbCore/Button/Button';

// Styles
import './style.scss';

/**
 * @description Card has 3 sections from top to bottom (title, body, footer, expandedContent)
 * 
 * @param {String} [className] - class name of the SidebarCard
 * @param {JSX} [cardTitleLeft] - left side of card title
 * @param {JSX} [cardTitleRight] - right side of card title
 * @param {JSX} [body] - card body element
 * @param {JSX} [footer] - card footer element (when card is not expanded)
 * @param {JSX} [expandedContent] - expanded card content (when supplied, this will show the expand button toggle)
 * @param {bool} [initiallyExpanded] - determines whether the card should be initially expanded
 * @param {boolean} [isActive] - determines whether the card is "active" (aka. selected)
 * @returns 
 */
function SidebarCard(props) {
  const [isActive, setIsActive] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    // Set any initial state
    setIsCollapsed(!props.initiallyExpanded);
  }, []);

  useEffect(() => {
    setIsActive(props.isActive);
  }, [props.isActive]);

  let className = 'sidebar-card';
  if (props.className) className += ` ${props.className}`;
  if (isActive) className += ` active-card`;

  function toggleCollapse(event) {
    event.stopPropagation();
    setIsCollapsed(!isCollapsed);
  }

  // Card title
  const title = (
    <div className="sidebar-card-title flex align-items-center justify-content-between">
      {/* Break the title into left and right sections for easier customization */}
      <span className="sidebar-card-title-left">{props.cardTitleLeft}</span>
      <span className="sidebar-card-title-right">{props.cardTitleRight}</span>
    </div>
  );

  // Card body
  const body = (props.body &&
    <div className="sidebar-card-body my-1 xl:my-2">
      {props.body}
    </div>
  );

  // Card footer
  function generateExpandableContent() {
    return (
      <div className="sidebar-card-expandable-content">
        {isCollapsed ? props.footer : props.expandedContent}

        {/* Expand button - uses absolute positioning in css to be positioned in bottom corner */}
        {props.expandedContent && (
          <span className="collapsible-btn">
            <Button
              className="p-button-text"
              onClick={(e) => toggleCollapse(e)}
              icon={`pi ${isCollapsed ? 'pi-angle-down' : 'pi pi-angle-up'}`}
              tooltip={`${isCollapsed ? 'Expand' : 'Collapse'} Details`}
            />
          </span>
        )}
      </div>
    );
  }

  return (
    <div className={className}>
      <Card
        title={title}
        footer={generateExpandableContent()}
      >
        {body}
      </Card>
    </div>
  );
}

export default SidebarCard;