import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment-timezone';

// components
import CSVUploader from 'sbCore/CSVUploader/CSVUploader';
import VehicleAutocompleteInput from 'sbCore/VehicleAutocomplete/VehicleAutocompleteInput';
import Button from 'sbCore/Button/Button';
import TimezoneDropdown from 'sbCore/TimezoneDropdown/TimezoneDropdown';
import Checkbox from 'sbCore/Checkbox/Checkbox';
import InputLabel from 'sbCore/InputLabel/InputLabel';

// sb-csapi
import { addRecord, cloneRecord, getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, updateRecord } from 'sb-csapi/dist/AAPI';
import { getMatchingDateTimeBreakdown } from 'sb-csapi/dist/utils/Time';

import { Canada, United_States } from 'sb-csapi/dist/enums/StateProvince';
import { VolumeUnit, VolumeUnitName, VolumeConversion } from 'sb-csapi/dist/enums/Unit';
import { Currency } from 'sb-csapi/dist/enums/Finance/Currency';

// api
import { getVehicles } from 'api/Equipment/Vehicle';
import { removeDuplicateIFTAFuelCardData } from 'api/IFTARoute/IFTARoute.old';

// styles
import './style.scss';

/**
 * @description Upload fuel cards (CSV/Excel)
 * @param {String} [className]
 * @param {Array} [iftaRoutes] - The IFTA routes that the fuel card will be uploaded to
 * @param {Function} [onSuccessClose] - Callback for after the fuel card is uploaded and the success dialog is closed
 * @returns
 */
function FuelCardCSVUploader({ ...props }) {
  const defaultHeaderMap = {
    'Unit ID': ['unit id', 'unit #', 'unit number', 'unit#', 'unit', 'vehicle unit', 'vehicle'],
    'State/Province': ['state/province', 'state/prov', 'state/ prov', 'merchant state', 'merchant province', 'state', 'province'],
    'Date': ['transaction date', 'tran date', 'purchase date', 'date'],
    'Time': ['transaction time', 'tran time', 'purchase time', 'time'],
    'Fuel Name': ['item name', 'item', 'fuel', 'tractor fuel', 'product name', 'product description', 'product'],
    'Fuel Quantity': ['qty', 'volume'],
    'Fuel Unit (G/L)': ['uom'],
    'Cost': ['total amt', 'amt', 'amount $', 'total transaction', 'total amount',  'amount'],
    'Currency': ['currency'],
  };

  const [headerMap, setHeaderMap] = useState(defaultHeaderMap);
  const [overrideCellMap, setOverrideCellMap] = useState({});
  const [errors, setErrors] = useState([]);
  const uploadContentCache = useRef({});

  // The finalized data to create records from
  const fuelCardDataCache = useRef({});

  // Remove existing transactions on the db (records) that are similar to the ones being uploaded now
  const [removeDuplicateFuelCardTransactions, setRemoveDuplicateFuelCardTransactions] = useState(false);

  // UI states
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false); // did everything go through properly

  // Vehicle validation
  const [unitIdMap, setUnitIdMap] = useState({}); // map of available unit ids for validation
  const [ignoreUnitIdMap, setIgnoreUnitIdMap] = useState({}); // map of unit ids that should be ignored in validation

  // Timezone validation
  const [timezoneOffsetFromUTC, setTimezoneOffsetFromUTC] = useState(null);

  useEffect(() => {
    // Initializer
    setIsLoading(true);
    let didCancel = false;

    async function init() {
      const _disabled = props.disabled ?? false;

      // get list of vehicles to check against the fuel card unit ids
      const { vehicles } = await getVehicles(
        undefined, // options
        undefined, // companyObjectId
        undefined, // includeChildCompanies
        undefined, // filters
        undefined, // sortBy
        undefined, // includedPointers
        undefined, // selectedAttributes
        undefined, // page
        undefined, // limit
        true, // queryAll
      );

      let _unitIdMap = {};
      vehicles.filter(vehicle => {
        const unitId = getAttribute(vehicle, 'unitId');
        const unitIdCleaned = (unitId || '').toLowerCase().trim();
        if (unitIdCleaned) _unitIdMap[unitIdCleaned] = unitId;
      });

      if (!didCancel) {
        setUnitIdMap(_unitIdMap);
        setDisabled(_disabled);
        setIsLoading(false);
        setErrors([]);
        setIsUploadSuccess(false);
        setRemoveDuplicateFuelCardTransactions(false);
        fuelCardDataCache.current = {};
      }
    }

    init();
    return () => { didCancel = true; };
  }, []);

  function resetState() {
    const _isUploadSuccess = isUploadSuccess;

    setHeaderMap(defaultHeaderMap);
    setErrors([]);
    setIgnoreUnitIdMap({});
    setOverrideCellMap({});
    setTimezoneOffsetFromUTC(null);
    setIsUploadSuccess(false);
    setRemoveDuplicateFuelCardTransactions(false);
    fuelCardDataCache.current = {};

    if (_isUploadSuccess && props.onSuccessClose) props.onSuccessClose();
  }

  // content from the parent. this is where we can parse through file content
  function getUploadContent(uploadContent) {
    // We use this fn to determine modifications to the headerMap
    // ex. figure out if the headerMap should be a split date/time or a combined Date/Time field
    const { header, body, raw } = uploadContent;

    let shouldUpdateHeader = false;
    const headersNamesToDelete = []; // the headers to remove in favour of ones added
    const _headerMap = structuredClone({ ...headerMap });

    // loop through content
    const rows = Object.values(body) || [];
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      const content = row.content || [];

      // We assume if we see a date/time field formatted one way, that it is consistent
      // throughout the whole file. Check the first instance of a date field and see how it is formatted
      // to determine if we need to split the date/time fields in the header
      for (let j = 0; j < content.length; j++) {
        const value = (content[j] || '').toString().trim();

        // check if this is a combined datetime field
        const { isValid, isDateTime } = getMatchingDateTimeBreakdown(value);

        if (isValid && isDateTime && _headerMap.Date && _headerMap.Time) {
          // should be a combined datetime field
          _headerMap['Date/Time'] = [...headerMap.Date, ...headerMap.Time];
          headersNamesToDelete.push('Date', 'Time');
          shouldUpdateHeader = true;
        }

        // check if this is a combined Currency/Fuel Unit field (ex. CAD/Liters, USD/Gallons)
        if (_headerMap.Currency) {
          const valueSplit = value.split('/');
          const currency = (valueSplit[0] || '').toUpperCase();
          const fuelUnit = (valueSplit[1] || '').toLowerCase();

          const isCurrency = (currency === Currency.CA.short) || (currency === Currency.US.short);
          const isFuelUnit = (fuelUnit === 'liters') || (fuelUnit === VolumeUnitName.L.toLowerCase()) || (fuelUnit === VolumeUnitName.G.toLowerCase()) || (fuelUnit === 'gallon');
          const isCurrencyFuelUnitField = isCurrency && isFuelUnit;

          if (isCurrencyFuelUnitField) {
            _headerMap['Currency/Fuel Unit'] = [...headerMap.Currency];
            headersNamesToDelete.push('Currency', 'Fuel Unit (G/L)');
            shouldUpdateHeader = true;
          }
        }

      }
    }

    if (shouldUpdateHeader) {
      headersNamesToDelete.map(headerName => delete _headerMap[headerName]);
      setHeaderMap(_headerMap);
    }
  }

  // Helpers for validateAndUploadFiles
  const validation = {
    pleaseFixMessage: 'Please resolve the issue by assigning the correct Header to the column or by correcting the value',
    getHeaderColumnIndex: (headerName, header) => {
      // Gets the columnIndex assigned to headerName
      const headerObjects = Object.values(header);
      let columnIndex = -1;
      for (let i = 0; i < headerObjects.length; i++) {
        const headerObject = headerObjects[i];
        if (headerObject.name === headerName) {
          columnIndex = headerObject.columnIndex;
          break;
        }
      }
      return columnIndex;
    },

    getRowString: (rowNumber) => {
      const rowString = `Row ${rowNumber}: `;
      return rowString;
    },

    getUnassignedHeaderNames: (header) => {
      // finds which headers have not been assigned
      const unassignedHeaderNames = [];
      const headerObjects = Object.values(header);
      headerObjects.map((headerObject) => {
        if (headerObject.columnIndex === -1) {
          unassignedHeaderNames.push(headerObject.name);
        }
      });

      return unassignedHeaderNames;
    },
    getInvalidDates: (header, body) => {
      const invalidDateErrors = [];
      const validDates = [];

      // first we need to figure out if we're checking for a unified Date/Time field or separate Date and Time fields
      const headerName = headerMap['Date/Time'] ? 'Date/Time' : 'Date';

      const columnIndex = validation.getHeaderColumnIndex(headerName, header);

      // loop through the body using columnIndex and validate
      const rows = Object.values(body) || [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowIndex = row.rowIndex + 1;
        const content = row.content || [];
        const value = (content[columnIndex] || '').toString().trim();
        const { isValid, isValidDateObject, isDateTime, isDateOnly } = getMatchingDateTimeBreakdown(value);
        if (value === '') {
          invalidDateErrors.push(`${validation.getRowString(rowIndex)}Missing a valid ${headerName} format`);
        } else if (!isValid) {
          invalidDateErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName} format`);
        } else if (!isValidDateObject) {
          invalidDateErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName}`);
        } else if ((headerName === 'Date') && (!isDateOnly)) {
          invalidDateErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName} format (ex. YYYY-MM-DD)`);
        } else if ((headerName === 'Date/Time') && (!isDateTime)) {
          invalidDateErrors.push(`${validation.getRowString(rowIndex)}"${value}" is missing a Time value`);
        } else {
          validDates.push(value);
        }
      }

      if (!invalidDateErrors.length) {
        fuelCardDataCache.current = { ...fuelCardDataCache.current, [headerName]: validDates };
      }
      return invalidDateErrors;
    },

    getInvalidTimes: (header, body) => {
      const invalidTimeErrors = [];
      const validTimes = [];

      const headerName = 'Time';

      const validTimeFormats = ['h:mm:ss', 'h:mm:ss A', 'h:mm', 'HH:mm:ss', 'HH:mm'];

      const columnIndex = validation.getHeaderColumnIndex(headerName, header);

      // loop through the body using columnIndex and validate
      const rows = Object.values(body) || [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowIndex = row.rowIndex + 1;
        const content = row.content || [];
        const value = (content[columnIndex] || '').toString().trim();
        const isValid = moment(value, validTimeFormats, true).isValid();
        if (!value) {
          invalidTimeErrors.push(`${validation.getRowString(rowIndex)}Missing a valid ${headerName} format (ex. HH:mm)`);
        } else if (!isValid) {
          invalidTimeErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName} format (ex. HH:mm)`);
        } else if (isValid) {
          // The time format is valid, but is the time itself actually valid?
          let isValidTimeObject = true;
          const timeParts = value.split(':');
          const hours = parseInt(timeParts[0], 10);
          const minutes = parseInt(timeParts[1], 10);
          let seconds = 0;
          let milliseconds = 0;

          if (timeParts.length > 2) {
            const secParts = timeParts[2].split('.');
            seconds = parseInt(secParts[0], 10);
            if (secParts.length > 1) {
              milliseconds = parseInt(secParts[1], 10);
            }
          }

          // Check if the time components are within valid ranges
          if (hours < 0 || hours > 23) isValidTimeObject = false;
          if (minutes < 0 || minutes > 59) isValidTimeObject = false;
          if (seconds < 0 || seconds > 59) isValidTimeObject = false;
          if (milliseconds < 0 || milliseconds > 999) isValidTimeObject = false;

          if (!isValidTimeObject) {
            invalidTimeErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName}`);
          } else {
            validTimes.push(value);
          }
        }
      }

      if (!invalidTimeErrors.length) {
        fuelCardDataCache.current = { ...fuelCardDataCache.current, [headerName]: validTimes };
      }
      return invalidTimeErrors;
    },

    getInvalidStateProvinces: (header, body) => {
      const invalidStateProvincesErrors = [];
      const validStateProvinces = [];

      const headerName = 'State/Province';
      const columnIndex = validation.getHeaderColumnIndex(headerName, header);
      const rows = Object.values(body) || [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowIndex = row.rowIndex + 1;
        const content = row.content || [];
        const value = (content[columnIndex] || '').toString().trim();

        let isValidStateProvince = false;
        if (Canada[value.toUpperCase()]) isValidStateProvince = true;
        if (United_States[value.toUpperCase()]) isValidStateProvince = true;

        let stateProvince;
        if (!isValidStateProvince) {
          // try to find a match for a full stateprovince string such as 'British Columbia'
          const stateProvincesCA = Object.values(Canada);
          stateProvince = stateProvincesCA.find(stateProvince => stateProvince.name.toLowerCase() === value.toLowerCase());
          if (stateProvince) isValidStateProvince = true;
        }

        if (!isValidStateProvince) {
          const stateProvincesUS = Object.values(United_States);
          stateProvince = stateProvincesUS.find(stateProvince => stateProvince.name.toLowerCase() === value.toLowerCase());
          if (stateProvince) isValidStateProvince = true;
        }

        if (value === '') {
          invalidStateProvincesErrors.push(`${validation.getRowString(rowIndex)}Missing a valid ${headerName}`);
        } else if (!isValidStateProvince) {
          invalidStateProvincesErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName}`);
        } else {
          validStateProvinces.push(value);
        }
      }

      if (!invalidStateProvincesErrors.length) {
        fuelCardDataCache.current = { ...fuelCardDataCache.current, [headerName]: validStateProvinces };
      }
      return invalidStateProvincesErrors;
    },

    getInvalidFuelUnits: (header, body) => {
      const invalidFuelUnitErrors = [];
      const validFuelUnits = [];

      const headerName = 'Fuel Unit (G/L)';
      const columnIndex = validation.getHeaderColumnIndex(headerName, header);
      const rows = Object.values(body) || [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowIndex = row.rowIndex + 1;
        const content = row.content || [];
        const value = (content[columnIndex] || '').toString().trim();

        // see: getInvalidCurrencyFuelUnits for equivalent logic
        let isValidFuelUnit = false;
        if (value.toLowerCase() === VolumeUnit.L.toLowerCase()) isValidFuelUnit = true;
        if (value.toLowerCase() === VolumeUnit.G.toLowerCase()) isValidFuelUnit = true;

        if (value.toLowerCase() === VolumeUnitName.L.toLowerCase()) isValidFuelUnit = true;
        if (value.toLowerCase() === VolumeUnitName.G.toLowerCase()) isValidFuelUnit = true;

        if (value === '') {
          invalidFuelUnitErrors.push(`${validation.getRowString(rowIndex)}Missing a valid ${headerName}`);
        } else if (!isValidFuelUnit) {
          invalidFuelUnitErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName} (ex. L, G, Litres, Gallons)`);
        } else {
          validFuelUnits.push(value);
        }
      }

      if (!invalidFuelUnitErrors.length) {
        fuelCardDataCache.current = { ...fuelCardDataCache.current, [headerName]: validFuelUnits };
      }
      return invalidFuelUnitErrors;
    },

    getInvalidCurrencies: (header, body) => {
      const invalidCurrencyErrors = [];
      const validCurrencies = [];

      const headerName = 'Currency';
      const columnIndex = validation.getHeaderColumnIndex(headerName, header);
      const rows = Object.values(body) || [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowIndex = row.rowIndex + 1;
        const content = row.content || [];
        const value = (content[columnIndex] || '').toString().trim();

        // see: getInvalidCurrencyFuelUnits for equivalent logic
        let isValidCurrency = false;
        if (value.toLowerCase() === Currency.CA.short.toLowerCase()) isValidCurrency = true;
        if (value.toLowerCase() === Currency.US.short.toLowerCase()) isValidCurrency = true;

        if (value === '') {
          invalidCurrencyErrors.push(`${validation.getRowString(rowIndex)}Missing a valid ${headerName}`);
        } else if (!isValidCurrency) {
          invalidCurrencyErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName} (ex. CAD, USD)`);
        } else {
          validCurrencies.push(value);
        }
      }

      if (!invalidCurrencyErrors.length) {
        fuelCardDataCache.current = { ...fuelCardDataCache.current, [headerName]: validCurrencies };
      }
      return invalidCurrencyErrors;
    },

    getInvalidCurrencyFuelUnits: (header, body) => {
      const invalidCurrencyFuelUnitErrors = [];
      const validCurrencyFuelUnits = [];

      const headerName = 'Currency/Fuel Unit';
      const columnIndex = validation.getHeaderColumnIndex(headerName, header);
      const rows = Object.values(body) || [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowIndex = row.rowIndex + 1;
        const content = row.content || [];
        const value = (content[columnIndex] || '').toString().trim();

        const valueSplit = value.split('/');
        const currency = (valueSplit[0] || '').trim();
        const fuelUnit = (valueSplit[1] || '').trim();

        // see: getInvalidCurrencies for equivalent logic
        let isValidCurrency = false;
        if (currency.toLowerCase() === Currency.CA.short.toLowerCase()) isValidCurrency = true;
        if (currency.toLowerCase() === Currency.US.short.toLowerCase()) isValidCurrency = true;

        // see: getInvalidFuelUnits for equivalent logic
        let isValidFuelUnit = false;
        if (fuelUnit.toLowerCase() === VolumeUnit.L.toLowerCase()) isValidFuelUnit = true;
        if (fuelUnit.toLowerCase() === VolumeUnit.G.toLowerCase()) isValidFuelUnit = true;

        if (fuelUnit.toLowerCase() === VolumeUnitName.L.toLowerCase()) isValidFuelUnit = true;
        if (fuelUnit.toLowerCase() === 'liters') isValidFuelUnit = true;
        if (fuelUnit.toLowerCase() === VolumeUnitName.G.toLowerCase()) isValidFuelUnit = true;
        if (fuelUnit.toLowerCase() === 'gallon') isValidFuelUnit = true;

        if (value === '') {
          invalidCurrencyFuelUnitErrors.push(`${validation.getRowString(rowIndex)}Missing a valid ${headerName}`);
        } else if (!isValidCurrency) {
          invalidCurrencyFuelUnitErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName} (ex. CAD/Litres, USD/Gallons, CAD/Gallons, etc)`);
        } else if (!isValidFuelUnit) {
          invalidCurrencyFuelUnitErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName} (ex. CAD/Litres, USD/Gallons, CAD/Gallons, etc)`);
        } else {
          validCurrencyFuelUnits.push(value);
        }
      }

      if (!invalidCurrencyFuelUnitErrors.length) {
        fuelCardDataCache.current = { ...fuelCardDataCache.current, [headerName]: validCurrencyFuelUnits };
      }
      return invalidCurrencyFuelUnitErrors;
    },

    getInvalidUnitIds: (header, body) => {
      const invalidUnitIdErrors = [];
      const validUnitIds = [];

      const headerName = 'Unit ID';
      const columnIndex = validation.getHeaderColumnIndex(headerName, header);
      const rows = Object.values(body) || [];
      const invalidUnitIds = []; // track which unitids are invalid
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowIndex = row.rowIndex + 1;
        const content = row.content || [];
        const value = (content[columnIndex] || '').toString().trim();
        if (value === '') {
          invalidUnitIdErrors.push(`${validation.getRowString(rowIndex)}Missing a valid ${headerName}`);
          invalidUnitIds.push(value);
        } else if (!ignoreUnitIdMap[value] && !unitIdMap[value.toLowerCase()]) {
          // if the unit is not being ignored for validation and does not exist in the system
          invalidUnitIdErrors.push(`${validation.getRowString(rowIndex)}${headerName} "${value}" does not exist in the system`);
          invalidUnitIds.push(value);
        } else {
          validUnitIds.push(value);
        }
      }

      if (!invalidUnitIdErrors.length) {
        fuelCardDataCache.current = { ...fuelCardDataCache.current, [headerName]: validUnitIds };
      }
      return { invalidUnitIdErrors, invalidUnitIds };
    },

    getInvalidFuelNames: (header, body) => {
      const invalidFuelNameErrors = [];
      const validFuelNames = [];

      const headerName = 'Fuel Name';
      const columnIndex = validation.getHeaderColumnIndex(headerName, header);
      const rows = Object.values(body) || [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowIndex = row.rowIndex + 1;
        const content = row.content || [];
        let value = (content[columnIndex] || '').toString().trim();
        if (!value) value = 'No Fuel Name';
        if (value === '') {
          invalidFuelNameErrors.push(`${validation.getRowString(rowIndex)}Missing a ${headerName}`);
        } else {
          validFuelNames.push(value);
        }
      }

      if (!invalidFuelNameErrors.length) {
        fuelCardDataCache.current = { ...fuelCardDataCache.current, [headerName]: validFuelNames };
      }
      return invalidFuelNameErrors;
    },

    getInvalidFuelQuantities: (header, body) => {
      const invalidFuelQuantityErrors = [];
      const validFuelQuantities = [];

      const headerName = 'Fuel Quantity';
      const columnIndex = validation.getHeaderColumnIndex(headerName, header);
      const rows = Object.values(body) || [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowIndex = row.rowIndex + 1;
        const content = row.content || [];
        const value = (content[columnIndex] || '').toString().trim();

        const floatRegex = /^-?\d+(\.\d+)?$/; // Define a regex pattern to match valid float numbers without scientific notation (ex. "12e3")
        const floatVal = parseFloat(value);

        if (value === '') {
          invalidFuelQuantityErrors.push(`${validation.getRowString(rowIndex)}Missing a ${headerName}`);
        } else if (!floatRegex.test(value)) {
          invalidFuelQuantityErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName} number`);
        } else if (isNaN(floatVal) && value === floatVal.toString()) {
          invalidFuelQuantityErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName} number`);
        } else {
          validFuelQuantities.push(floatVal);
        }
      }

      if (!invalidFuelQuantityErrors.length) {
        fuelCardDataCache.current = { ...fuelCardDataCache.current, [headerName]: validFuelQuantities };
      }
      return invalidFuelQuantityErrors;
    },

    getInvalidCosts: (header, body) => {
      const invalidCostErrors = [];
      const validCosts = [];

      const headerName = 'Cost';
      const columnIndex = validation.getHeaderColumnIndex(headerName, header);
      const rows = Object.values(body) || [];
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        const rowIndex = row.rowIndex + 1;
        const content = row.content || [];
        const value = (content[columnIndex] || '').toString().trim();

        const floatRegex = /^-?\d+(\.\d+)?$/; // Define a regex pattern to match valid float numbers without scientific notation (ex. "12e3")
        const floatVal = parseFloat(value);

        if (value === '') {
          invalidCostErrors.push(`${validation.getRowString(rowIndex)}Missing a ${headerName}`);
        } else if (!floatRegex.test(value)) {
          invalidCostErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName} number`);
        } else if (isNaN(floatVal) && value === floatVal.toString()) {
          invalidCostErrors.push(`${validation.getRowString(rowIndex)}"${value}" is not a valid ${headerName} number`);
        } else {
          validCosts.push(floatVal);
        }
      }

      if (!invalidCostErrors.length) {
        fuelCardDataCache.current = { ...fuelCardDataCache.current, [headerName]: validCosts };
      }
      return invalidCostErrors;
    },
  };

  // Replace all instances of the given unitId in the file contents
  function replaceUnitId(unitId, vehicle) {
    if (!vehicle) return;
    setIsLoading(true);
    const vehicleUnitId = getAttribute(vehicle, 'unitId');
    const _overrideCellMap = { ...overrideCellMap };

    const { header, body } = uploadContentCache.current;

    const headerName = 'Unit ID';
    const columnIndex = validation.getHeaderColumnIndex(headerName, header);
    const rows = Object.values(body) || [];

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      if (row.content[columnIndex].toString().trim() === unitId) {
        _overrideCellMap[`${i}:${columnIndex}`] = vehicleUnitId;
      }
    }

    setOverrideCellMap(_overrideCellMap);
    setErrors([]);
    setIsLoading(false);
  }

  function replaceFuelUnits(fuelUnit = '') {
    if (!fuelUnit) return;
    setIsLoading(true);
    const _overrideCellMap = { ...overrideCellMap };

    const { header, body } = uploadContentCache.current;

    const headerName = 'Fuel Unit (G/L)';
    const columnIndex = validation.getHeaderColumnIndex(headerName, header);
    const rows = Object.values(body) || [];

    for (let i = 0; i < rows.length; i++) {
      _overrideCellMap[`${i}:${columnIndex}`] = fuelUnit;
    }

    setOverrideCellMap(_overrideCellMap);
    setErrors([]);
    setIsLoading(false);
  }

  function replaceCurrency(currencyShort = '') {
    if (!currencyShort) return;
    setIsLoading(true);
    const _overrideCellMap = { ...overrideCellMap };

    const { header, body } = uploadContentCache.current;

    const headerName = 'Currency';
    const columnIndex = validation.getHeaderColumnIndex(headerName, header);
    const rows = Object.values(body) || [];

    for (let i = 0; i < rows.length; i++) {
      _overrideCellMap[`${i}:${columnIndex}`] = currencyShort;
    }

    setOverrideCellMap(_overrideCellMap);
    setErrors([]);
    setIsLoading(false);
  }

  // Callback with info that would be uploaded
  async function validateAndUploadFiles(uploadContent) {
    let uploadSuccess = true; // true unless proven false
    const _errors = [];

    uploadContentCache.current = uploadContent;
    const { header, body, raw } = uploadContent;

    // Validate Header selections for completeness
    const unassignedHeaderNames = validation.getUnassignedHeaderNames(header);
    if (unassignedHeaderNames.length > 0) {
      _errors.push(
        <div>Please assign the following headers to a column: <b>{unassignedHeaderNames.join(', ')}</b></div>,
      );
      return setErrors(_errors);
    }

    // Validate date and date/time fields
    const invalidDateErrors = validation.getInvalidDates(header, body);
    if (invalidDateErrors.length > 0) {
      _errors.push(
        <div className="text-center">
          <div><b>{ invalidDateErrors[0] }</b></div>
          <div className="mt-2">{ validation.pleaseFixMessage }</div>
        </div>,
      );
      return setErrors(_errors);
    }

    // Validate Time if not a combined Date/Time field
    if (headerMap.Time) {
      const invalidTimeErrors = validation.getInvalidTimes(header, body);
      if (invalidTimeErrors.length > 0) {
        _errors.push(
          <div className="text-center">
            <div><b>{ invalidTimeErrors[0] }</b></div>
            <div className="mt-2">{ validation.pleaseFixMessage }</div>
          </div>,
        );
        return setErrors(_errors);
      }
    }

    // Validate State/Province
    const invalidStateProvincesErrors = validation.getInvalidStateProvinces(header, body);
    if (invalidStateProvincesErrors.length > 0) {
      _errors.push(
        <div className="text-center">
          <div><b>{ invalidStateProvincesErrors[0] }</b></div>
          <div className="mt-2">{ validation.pleaseFixMessage }</div>
        </div>,
      );
      return setErrors(_errors);
    }

    // Validate Fuel Unit (G/L)
    if (headerMap['Fuel Unit (G/L)']) {
      const invalidFuelUnitErrors = validation.getInvalidFuelUnits(header, body);
      if (invalidFuelUnitErrors.length > 0) {
        _errors.push(
          <div className="text-center">
            <div><b>{ invalidFuelUnitErrors[0] }</b></div>
            <div className="mt-2">{ validation.pleaseFixMessage }</div>
            { uploadContentCache.current.header && uploadContentCache.current.header['Fuel Unit (G/L)'].columnIndex !== -1 && (
              <div className="mt-3">
                <div
                  className="inline-block text-blue cursor-pointer underline"
                  style={{ fontSize: '.8em' }}
                  onClick={() => replaceFuelUnits(VolumeUnit.L)}
                >
                  Click here to set column as Litres (L)
                </div>
                <div className="my-1">or</div>
                <div
                  className="inline-block text-blue cursor-pointer underline"
                  style={{ fontSize: '.8em' }}
                  onClick={() => replaceFuelUnits(VolumeUnit.G)}
                >
                  Click here to set column as Gallons (G)
                </div>
              </div>
            )}
          </div>,
        );
        return setErrors(_errors);
      }
    }

    // Validate Currency
    if (headerMap.Currency) {
      const invalidCurrencyErrors = validation.getInvalidCurrencies(header, body);
      if (invalidCurrencyErrors.length > 0) {
        _errors.push(
          <div className="text-center">
            <div><b>{ invalidCurrencyErrors[0] }</b></div>
            <div className="mt-2">{ validation.pleaseFixMessage }</div>
            { uploadContentCache.current.header && uploadContentCache.current.header.Currency.columnIndex !== -1 && (
              <div className="mt-3">
                <div
                  className="inline-block text-blue cursor-pointer underline"
                  style={{ fontSize: '.8em' }}
                  onClick={() => replaceCurrency(Currency.US.short)}
                >
                  Click here to set column as USD
                </div>
                <div className="my-1">or</div>
                <div
                  className="inline-block text-blue cursor-pointer underline"
                  style={{ fontSize: '.8em' }}
                  onClick={() => replaceCurrency(Currency.CA.short)}
                >
                  Click here to set column as CAD
                </div>
              </div>
            )}
          </div>,
        );
        return setErrors(_errors);
      }
    }

    // Validate Currency/Fuel Unit
    if (headerMap['Currency/Fuel Unit']) {
      const invalidCurrencyFuelUnitErrors = validation.getInvalidCurrencyFuelUnits(header, body);
      if (invalidCurrencyFuelUnitErrors.length > 0) {
        _errors.push(
          <div className="text-center">
            <div><b>{ invalidCurrencyFuelUnitErrors[0] }</b></div>
            <div className="mt-2">{ validation.pleaseFixMessage }</div>
          </div>,
        );
        return setErrors(_errors);
      }
    }

    // Validate Unit ID
    const { invalidUnitIdErrors, invalidUnitIds } = validation.getInvalidUnitIds(header, body);
    if (invalidUnitIdErrors.length > 0) {
      const replaceUnitIDJSX = (
        <div className="mt-3">
          <div className="inline-block">
            <VehicleAutocompleteInput
              placeholder="Enter Replacement Unit ID"
              onSelectVehicle={(vehicle) => replaceUnitId(invalidUnitIds[0], vehicle)}
            />
          </div>

          <div className="inline-block mx-3">or</div>

          <Button
            text
            label="Cancel"
            severity="secondary"
            onClick={() => setErrors([])}
            sbVariant="short"
            style={{ marginBottom: '.3em' }}
          />
        </div>
      );

      const unitIdError = (
        <div className="text-center">
          <div><b>{ invalidUnitIdErrors[0] }</b></div>
          <div>{ validation.pleaseFixMessage }</div>
          { invalidUnitIds[0] && (
            <div className="mt-3">
              <div
                className="inline-block text-blue cursor-pointer underline"
                style={{ fontSize: '.8em' }}
                onClick={() => {
                  setIgnoreUnitIdMap({ ...ignoreUnitIdMap, [invalidUnitIds[0]]: true });
                  setErrors([]); // start over validation
                }}
              >
                Click here to ignore transactions for Unit &quot;{ invalidUnitIds[0] }&quot;
              </div>
              <div className="my-1">or</div>
              <div
                className="inline-block text-blue cursor-pointer underline"
                style={{ fontSize: '.8em' }}
                onClick={() => {
                  setErrors([replaceUnitIDJSX]);
                }}
              >
                Click here to swap all transactions for Unit &quot;{ invalidUnitIds[0] }&quot; with a different unit
              </div>
            </div>
          )}
        </div>
      );

      _errors.push(unitIdError);
      return setErrors(_errors);
    }

    // Validate Fuel Name
    const invalidFuelNameErrors = validation.getInvalidFuelNames(header, body);
    if (invalidFuelNameErrors.length > 0) {
      _errors.push(
        <div className="text-center">
          <div><b>{ invalidFuelNameErrors[0] }</b></div>
          <div className="mt-2">{ validation.pleaseFixMessage }</div>
        </div>,
      );
      return setErrors(_errors);
    }

    // Validate Fuel Quantity
    const invalidFuelQuantityErrors = validation.getInvalidFuelQuantities(header, body);
    if (invalidFuelQuantityErrors.length > 0) {
      _errors.push(
        <div className="text-center">
          <div><b>{ invalidFuelQuantityErrors[0] }</b></div>
          <div className="mt-2">{ validation.pleaseFixMessage }</div>
        </div>,
      );
      return setErrors(_errors);
    }

    // Validate Cost
    const invalidCostErrors = validation.getInvalidCosts(header, body);
    if (invalidCostErrors.length > 0) {
      _errors.push(
        <div className="text-center">
          <div><b>{ invalidCostErrors[0] }</b></div>
          <div className="mt-2">{ validation.pleaseFixMessage }</div>
        </div>,
      );
      return setErrors(_errors);
    }

    // At this stage, we should ask the user to confirm a timezone since we will need that for date/time processing
    if (!timezoneOffsetFromUTC) {
      _errors.push(
        <div className="text-center">
          <div className="mb-2"><b>Please select a time zone for the transaction dates</b></div>
          <TimezoneDropdown
            hideLabel
            style={{ marginLeft: '3em' }}
            onSelect={(_timezoneOffsetFromUTC) => {
              setTimezoneOffsetFromUTC(_timezoneOffsetFromUTC);
              setErrors([]); // get rid of the error message since this is the last error that is resolved
            }}
          />
        </div>,
      );
      return setErrors(_errors);
    }

    setErrors(_errors);

    // If we have reached this point, everything is validated according in accordance to our headers
    if ((props.iftaRoutes || []).length === 0) {
      return uploadSuccess; // no routes to upload to, so just return success
    }

    const currentDateTime = new Date(); // mark the datetime that transactions are being created

    // Now map the validated results of the headers, to fields in the database
    let transactionDateTimes = fuelCardDataCache.current['Date/Time']; // the array order matches 1-1 to the rest of the arrays
    if (fuelCardDataCache.current.Time) { // if date and time are separate
      transactionDateTimes = fuelCardDataCache.current.Date.map((dateString, index) => `${dateString} ${fuelCardDataCache.current.Time[index]}`);
    }

    const fuelMeasurementUnits = fuelCardDataCache.current['Fuel Unit (G/L)'] || [];
    const currencies = fuelCardDataCache.current.Currency || [];
    if (!fuelCardDataCache.current.Currency) { // if currency and fuel unit are combined
      fuelCardDataCache.current['Currency/Fuel Unit'].map(fuelCurrency => {
        const [currency, fuelMeasurementUnit] = fuelCurrency.split('/');
        currencies.push(currency);
        fuelMeasurementUnits.push(fuelMeasurementUnit[0].toLowerCase()); // force it to be 'g' or 'l'
      });
    }

    const stateProvinceAbbrvs = (fuelCardDataCache.current['State/Province'] || []).map(stateProvinceString => {
      let stateProvinceAbbrv = '';
      if (Canada[stateProvinceString]) {
        stateProvinceAbbrv = Canada[stateProvinceString].code;
      } else if (United_States[stateProvinceString]) {
        stateProvinceAbbrv = United_States[stateProvinceString].code;
      } else {
        // it is a full name and not a code (British Columbia vs BC)
        let stateProvince;
        const stateProvincesCA = Object.values(Canada);
        stateProvince = stateProvincesCA.find(stateProvince => stateProvince.name.toLowerCase() === stateProvinceString.toLowerCase());

        if (!stateProvince) {
          const stateProvincesUS = Object.values(United_States);
          stateProvince = stateProvincesUS.find(stateProvince => stateProvince.name.toLowerCase() === stateProvinceString.toLowerCase());
        }

        stateProvinceAbbrv = stateProvince.code;
      }

      return stateProvinceAbbrv.toLowerCase();
    });

    const unitIds = fuelCardDataCache.current['Unit ID'];

    const items = fuelCardDataCache.current['Fuel Name'];

    const fuelQuantities = fuelCardDataCache.current['Fuel Quantity'];

    const totals = fuelCardDataCache.current['Cost'];

    // now begin creating and saving the fuel card records
    const iftaFuelCardDataPromises = [];
    const iftaRoutePromises = [];

    for (let g = 0; g < props.iftaRoutes.length; g++) {
      const iftaRoute = props.iftaRoutes[g];
      const iftaRouteDateStart = moment.utc(getAttribute(iftaRoute, 'dateStart'));
      const iftaRouteDateEnd = moment.utc(getAttribute(iftaRoute, 'dateEnd'));

      const isIFTARouteDateValid = iftaRouteDateEnd.diff(iftaRouteDateStart, 'ms') >= 0;
      if (!isIFTARouteDateValid) continue;

      const iftaRouteStateProvince = getAttribute(iftaRoute, 'stateProvince');

      // we need to calculate IFTARoute_Beta.totalGallons
      let totalGallons = getAttribute(iftaRoute, 'totalGallons') || 0;

      for (let i = 0; i < transactionDateTimes.length; i++) {
        const transactionDateTime = moment(transactionDateTimes[i]).tz(timezoneOffsetFromUTC);
        const stateProvinceAbbrv = stateProvinceAbbrvs[i];
        const fuelQuantity = fuelQuantities[i] || 0;
        const fuelMeasurementUnit = fuelMeasurementUnits[i];
        const unitId = unitIds[i] || '';

        if (ignoreUnitIdMap[unitId] || ignoreUnitIdMap[unitId.toLowerCase()]) continue;

        const iftaFuelCardData = {
          transactionDateTime: transactionDateTime.toDate(),
          unitId,
          stateProvinceAbbrv,
          item: items[i],
          fuelQuantity,
          fuelMeasurementUnit,
          total: totals[i],
          currency: currencies[i],
          iftaRouteBeta: iftaRoute,
        };

        const isMatchingStateProvince = iftaRouteStateProvince.toLowerCase() === stateProvinceAbbrv;
        // if the transaction happened between the ifta route start and end
        const isTransactionWithinIFTARouteDate = transactionDateTime.isBetween(iftaRouteDateStart, iftaRouteDateEnd, null, '[]');
        const shouldCreateIFTAFuelCardData = isMatchingStateProvince && isTransactionWithinIFTARouteDate;

        if (!shouldCreateIFTAFuelCardData) continue;

        const iftaFuelCardDataPromise = addRecord({ sessionToken: getCurrentUserSessionToken() }, 'IFTAFuelCardData', iftaFuelCardData, getCurrentUserCompanyObjectId());
        iftaFuelCardDataPromises.push(iftaFuelCardDataPromise);

        // calculate totalGallons
        if (removeDuplicateFuelCardTransactions) { // want to remove similar transactions to iftaFuelCardData
          const removedDuplicates = await removeDuplicateIFTAFuelCardData(iftaRoute, iftaFuelCardData, currentDateTime);

          // For each removed duplicate, we want to less the fuelQuantityTotalForIFTARoute
          for (let j = 0; j < removedDuplicates.length; j++) {
            const removedDuplicate = removedDuplicates[j];
            let duplicateFuelQuantity = getAttribute(removedDuplicate, 'fuelQuantity') || 0;
            const isDuplicateFuelQuantityLitres = getAttribute(removedDuplicate, 'fuelMeasurementUnit') === VolumeUnit.L.toLowerCase();
            if (isDuplicateFuelQuantityLitres) duplicateFuelQuantity *= VolumeConversion.L2G;

            // Check if the fuelQuantity we're about to remove puts us in the negatives (< 0)
            // This could be due to bad/test data that causes subtractions past 0
            if (duplicateFuelQuantity > totalGallons) {
              totalGallons = 0;
            } else {
              totalGallons -= duplicateFuelQuantity;
            }
          }
        }

        const isLitres = fuelMeasurementUnit === VolumeUnit.L.toLowerCase();
        if (isLitres) {
          totalGallons += (fuelQuantity * VolumeConversion.L2G);
        } else {
          totalGallons += fuelQuantity;
        }
      }

      iftaRoutePromises.push(updateRecord({ sessionToken: getCurrentUserSessionToken() }, iftaRoute, { totalGallons }, true));
    }

    await Promise.all(iftaFuelCardDataPromises);
    await Promise.all(iftaRoutePromises);
    setIsUploadSuccess(true);
    return uploadSuccess;
  }

  let className = 'fuel-card-csv-uploader';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <CSVUploader
        buttonLabel="Import Fuel Card"
        headerMap={headerMap}
        validateAndUploadFiles={(uploadInformation) => validateAndUploadFiles(uploadInformation)}
        getUploadContent={(uploadContent) => getUploadContent(uploadContent)}
        overrideCellMap={overrideCellMap}
        isLoading={isLoading}
        errors={errors}
        onClose={() => resetState()}
        options={(
          <div className="flex flex-row">
            <Checkbox
              checked={removeDuplicateFuelCardTransactions}
              onChange={() => setRemoveDuplicateFuelCardTransactions(!removeDuplicateFuelCardTransactions)}
              disabled={isLoading}
            />
            <InputLabel className="pt-1 pl-1">Remove existing duplicate transactions</InputLabel>
          </div>
        )}
        successMessage={<div>Done! Your Fuel Card has been successfully uploaded. Click <b>Close</b> to refresh the page</div>}
      />
    </div>
  );
}

export default FuelCardCSVUploader;
