import { t } from 'api/Translate';
import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import Autocomplete from 'react-google-autocomplete';
import {
  Table,
  TableHeader,
  TableRow,
  TableRowColumn,
  TableHeaderColumn,
  TableBody,
} from 'material-ui';

// API
import { getLinkedDriversForState } from 'actions/Driver';
import * as Helpers from 'api/Helpers';
import * as Getters from 'api/Getters';

// Components
import Title from 'components/LayoutTitle/view/Title';
import CloseXButton from 'components/CloseXButton/view/CloseXButton';
import IFTARouteBreakdown from 'components/IFTARouteBreakdown/IFTARouteBreakdown';
import SubNavigationBar from 'components/Shared/SubNavigationBar/SubNavigationBar'
import DriverAutocomplete from 'components/Shared/DriverAutocomplete/DriverAutocomplete.old';
import DatePicker from 'components/DatePicker/container/DatePicker';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './CalculatorLayout.module.scss';

const getInitialLocations = () => ([
  {
    index: 0,
    place: {},
  },
  {
    index: 1,
    place: {},
  },
]);

class CalculatorLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      locations: getInitialLocations(),
      forceGenerateRoute: false,
      type: 'manual',
      driver: {
        selectableDriverItems: [],
        selectedDriver: null,
        selectedDate: moment().toDate(),
      },
    };
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.getPlaceholder = this.getPlaceholder.bind(this);
    this.getLocationArr = this.getLocationArr.bind(this);
  }

  componentDidMount() {
    getLinkedDriversForState(undefined, undefined, [], { user_fullName: 'ascending' }, null, false).then((drivers) => {
      // const newState = { ...this.state, isLoading: false, isLazyLoading: false, queryPage: queryPage + 1 };
      // newState.hasLoadedAll = true; // we've queried everything, so stop
      // self.setState(newState, () => resolve(true));
      const selectableDriverItems = drivers.map(driver => {

        const driverFullName = this.props.company && this.props.company.get('enableCompanyLinks') ?
          `${Helpers.toTitleCase(driver.get('user_fullName'))} - ${driver.get('belongsToCompany') && driver.get('belongsToCompany').get('name')}`
          :
          `${Helpers.toTitleCase(driver.get('user_fullName'))}`;

        return {
          key: driver.id,
          value: driver.id,
          label: driverFullName,
          driver,
        };
      });

      this.setState({
        ...this.state,
        driver: {
          ...this.state.driver,
          selectableDriverItems,
        }
      });
    });
  }

  addRow() {
    const locations = this.state.locations;
    locations.push({
      index: this.state.locations.length,
      place: {},
    });
    this.setState({ ...this.state, locations });
  }

  removeRow(index) {
    const newState = { ...this.state };
    for (let i = 0; i < this.state.locations.length; i++) {
      if (this.state.locations[i].index === index) {
        newState.locations.splice(i, 1);
        this.setState(newState);
        return;
      }
    }
  }

  getPlaceholder(index) {
    if (index === 0) {
      return 'Choose starting point...';
    } else if (index === 1) {
      return 'Choose destination...';
    }
    return 'Enter an additional city or location...';
  }

  async getLocationArr() {
    if (this.state.driver.selectedDriver && this.state.driver.selectedDate) {
      this.setState({ ...this.state, loading: true });
      const fromDateValue = moment(this.state.driver.selectedDate).startOf('day');
      const toDateValue = moment(this.state.driver.selectedDate).endOf('day');

      const finalFilter = [].concat(
        {
          fromValue: fromDateValue,
          toValue: toDateValue,
          name: 'dateTime',
          queryType: 'date',
        },
        {
          name: 'drivers',
          queryType: 'containedIn',
          value: [this.state.driver.selectedDriver],
        },
      );

      let vehicleLocations = await Getters.queryCompanyObjects(
        'VehicleLocation',
        undefined,
        undefined,
        finalFilter,
        { dateTime: 'ascending' },
        null,
        false,
        true,
        undefined,
        true
      );

      let lastSavedIndex = 0;
      // Get only engine power up/down, log in/out, and duty status changes
      const filteredVehicleLocations = vehicleLocations
        .filter((vehicleLocation, index) => {
          if (!vehicleLocation.get('location') || !(vehicleLocation.get('location').longitude && vehicleLocation.get('location').latitude)) {
            return false;
          }
          if (index === 0 || index === vehicleLocations.length - 1) return true;

          // Ensure at least 15 minutes between vehicleLocations
          if (
            index > 0 &&
            moment(vehicleLocation.get('dateTime')).isBefore(moment(vehicleLocations[lastSavedIndex].get('dateTime')).add(15, 'minutes'))
          ) {
            return false;
          }

          if (vehicleLocation.get('eldEvent_eldEventTypeCodeInt') === 11 ||
          vehicleLocation.get('eldEvent_eldEventTypeCodeInt') === 12 ||
          vehicleLocation.get('eldEvent_eldEventTypeCodeInt') === 13 ||
          vehicleLocation.get('eldEvent_eldEventTypeCodeInt') === 14 ||
          vehicleLocation.get('eldEvent_eldEventTypeCodeInt') === 21 ||
          vehicleLocation.get('eldEvent_eldEventTypeCodeInt') === 22 ||
          vehicleLocation.get('eldEvent_eldEventTypeCodeInt') === 30 ||
          vehicleLocation.get('eldEvent_eldEventTypeCodeInt') === 31 ||
          vehicleLocation.get('eldEvent_eldEventTypeCodeInt') === 32 ||
          vehicleLocation.get('eldEvent_eldEventTypeCodeInt') === 51 ||
          vehicleLocation.get('eldEvent_eldEventTypeCodeInt') === 52) {
            lastSavedIndex = index;
            return true;
          }
          return false;
        });
      this.setState({
        ...this.state,
        loading: false,
        locations: filteredVehicleLocations.map((vehicleLocation, index) => ({
          index,
          place: {
            geometry: {
              location: {
                lng: () => vehicleLocation.get('location').longitude,
                lat: () => vehicleLocation.get('location').latitude,
              },
            },
          },
        })),
      });
    }
  }

  render() {

    const navItems = [
      {
        name: 'Enter Locations',
        onClick: () => { this.setState({ ...this.state, type: 'manual' }) },
        isActive: this.state.type === 'manual',
        to: '?view=calculator&type=manual',
      },
      {
        name: 'Driver & Date',
        onClick: () => { this.setState({ ...this.state, type: 'driver' }) },
        isActive: this.state.type === 'driver',
        to: '?view=calculator&type=driver',
      },
    ];

    return (
      <div>
        <Title superClassName={styles.title} title={<div>Mileage Calculator</div>} />
        <div className="row mx-0">
          <div className="col-md-6">
            <SubNavigationBar containerClassName="mb-3 translate-me" navItems={navItems} />

            {this.state.type === 'manual' &&
              (
                <Table
                  wrapperStyle={{ overflow: 'hidden' }}
                >
                  <TableHeader
                    displaySelectAll={false}
                    adjustForCheckbox={false}
                    enableSelectAll={false}
                  >
                    <TableRow>
                      <TableHeaderColumn />
                      <TableHeaderColumn>
                        Location
                      </TableHeaderColumn>
                      <TableHeaderColumn />
                    </TableRow>
                  </TableHeader>
                  <TableBody
                    displayRowCheckbox={false}
                    showRowHover
                  >
                    {this.state.locations.map((location, index) => (
                      <TableRow key={location.index}>
                        <TableRowColumn style={{ width: '10%' }} className="verticalAlignMiddle">
                          <div className={styles.numbering}>{index + 1}</div>
                        </TableRowColumn>
                        <TableRowColumn style={{ width: '70%' }} className="verticalAlignMiddle">
                          <Autocomplete
                            className={styles.autocomplete}
                            placeholder={this.getPlaceholder(index)}
                            types={['establishment', 'geocode']}
                            componentRestrictions={{ country: ['ca', 'us'] }}
                            defaultValue={location.place.formatted_address}
                            onPlaceSelected={(place) => {
                              if (place && place.address_components) {
                                const newState = { ...this.state };
                                newState.locations[index].place = place;
                                newState.locations[index].error = null;
                                this.setState(newState);
                              } else {
                                const newState = { ...this.state };
                                newState.locations[index].error = 'Please select a location from the dropdown list';
                                this.setState(newState);
                              }
                            }}
                          />
                          {location.error &&
                            <div className="errorText">{location.error}</div>
                          }
                        </TableRowColumn>
                        <TableRowColumn style={{ width: '20%' }} className="verticalAlignMiddle">
                          {index > 1 &&
                            <CloseXButton handleClick={() => this.removeRow(index)} />
                          }
                        </TableRowColumn>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableRowColumn className={styles.newRow} colSpan={3}>
                        <button onClick={() => this.addRow()}>
                          Add additional location
                        </button>
                        {this.state.locations.filter((locationObj) => Object.keys(locationObj.place).length > 0).length > 1 &&
                          (
                            <div className="roboto text-gray-calm" style={{ marginTop: '2em', fontSize: '.9em' }}>
                              Click <span style={{ fontWeight: '700' }}>Generate Route</span> located below the map after adding locations
                            </div>
                          )
                        }
                      </TableRowColumn>
                    </TableRow>
                  </TableBody>
                </Table>
              )
            }

            {this.state.type === 'driver' &&
              (
                <div>
                  <Table
                    wrapperStyle={{ overflow: 'hidden' }}
                  >
                    <TableHeader
                      displaySelectAll={false}
                      adjustForCheckbox={false}
                      enableSelectAll={false}
                    >
                      {/* <TableRow>
                        <TableHeaderColumn>
                          Driver
                        </TableHeaderColumn>
                        <TableHeaderColumn>
                          Day
                        </TableHeaderColumn>
                      </TableRow> */}
                    </TableHeader>
                    <TableBody
                      displayRowCheckbox={false}
                      showRowHover
                    >
                      <TableRow>
                        <TableRowColumn style={{ height: '350px', verticalAlign: 'top' }}>
                          <DriverAutocomplete
                            onDriverSelected={selection => {
                              this.setState({
                                ...this.state,
                                locations: getInitialLocations(),
                                driver: {
                                  ...this.state.driver,
                                  selectedDriver: selection,
                                }
                              }, () => {
                                this.getLocationArr()
                              });
                            }}
                            enableStatePlaceholder
                          />
                        </TableRowColumn>
                        <TableRowColumn style={{ height: '350px', verticalAlign: 'top' }}>
                          <DatePicker
                            dateFormat="MMMM dd, yyyy "
                            selected={this.state.driver.selectedDate}
                            onChange={(date) => {
                              this.setState({ ...this.state, locations: getInitialLocations(), driver: { ...this.state.driver, selectedDate: moment(date).toDate() } }, () => {
                                this.getLocationArr();
                              });
                            }}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </TableRowColumn>
                      </TableRow>
                      <TableRow>
                        <TableRowColumn className={styles.newRow}>
                          {this.state.loading && (
                            <LoadingIcon />
                          )}
                          {!this.state.loading && this.state.locations.filter((locationObj) => Object.keys(locationObj.place).length > 0).length === 0 &&
                            (
                              <div className="roboto text-gray-calm" style={{ marginTop: '2em', fontSize: '.9em' }}>
                                No location found for this driver & date
                              </div>
                            )
                          }
                          {this.state.locations.filter((locationObj) => Object.keys(locationObj.place).length > 0).length > 1 &&
                            (
                              <div className="roboto text-gray-calm" style={{ marginTop: '2em', fontSize: '.9em' }}>
                                Click <span style={{ fontWeight: '700' }}>Generate Route</span> located below the map
                              </div>
                            )
                          }
                        </TableRowColumn>
                        <TableRowColumn className={styles.newRow} />

                      </TableRow>
                    </TableBody>
                  </Table>
                  {/* <Table
                wrapperStyle={{ overflow: 'hidden' }}
              >
                <TableHeader
                  displaySelectAll={false}
                  adjustForCheckbox={false}
                  enableSelectAll={false}
                >
                  <TableRow>
                    <TableHeaderColumn />
                    <TableHeaderColumn>
                      Location
                    </TableHeaderColumn>
                    <TableHeaderColumn />
                  </TableRow>
                </TableHeader>
                <TableBody
                  displayRowCheckbox={false}
                  showRowHover
                >
                  {this.state.locations.map((location, index) => (
                    <TableRow key={location.index}>
                      <TableRowColumn style={{ width: '10%' }} className="verticalAlignMiddle">
                        <div className={styles.numbering}>{index + 1}</div>
                      </TableRowColumn>
                      <TableRowColumn style={{ width: '70%' }} className="verticalAlignMiddle">
                        <Autocomplete
                          className={styles.autocomplete}
                          placeholder={this.getPlaceholder(index)}
                          types={['establishment', 'geocode']}
                          componentRestrictions={{ country: ['ca', 'us'] }}
                          defaultValue={location.place.formatted_address}
                          onPlaceSelected={(place) => {
                            if (place && place.address_components) {
                              const newState = { ...this.state };
                              newState.locations[index].place = place;
                              newState.locations[index].error = null;
                              this.setState(newState);
                            } else {
                              const newState = { ...this.state };
                              newState.locations[index].error = 'Please select a location from the dropdown list';
                              this.setState(newState);
                            }
                          }}
                        />
                        {location.error &&
                          <div className="errorText">{location.error}</div>
                        }
                      </TableRowColumn>
                      <TableRowColumn style={{ width: '20%' }} className="verticalAlignMiddle">
                        {index > 1 &&
                          <CloseXButton handleClick={() => this.removeRow(index)} />
                        }
                      </TableRowColumn>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableRowColumn className={styles.newRow} colSpan={3}>
                      <button onClick={() => this.addRow()}>
                        Add additional location
                      </button>
                      {this.state.locations.filter((locationObj) => Object.keys(locationObj.place).length > 0).length > 1 &&
                        (
                          <div className="roboto text-gray-calm" style={{ marginTop: '2em', fontSize: '.9em' }}>
                            Click <span style={{ fontWeight: '700' }}>Generate Route</span> located below the map after adding locations
                          </div>
                        )
                      }
                    </TableRowColumn>
                  </TableRow>
                </TableBody>
              </Table> */}
                </div>
              )
            }
          </div>

          <div className={`col-md-6 ${styles.map}`}>
            <IFTARouteBreakdown
              locationsArr={this.state.locations.map(location => location.place)}
              forceGenerateRoute={this.state.forceGenerateRoute}
            />
          </div>
        </div>
      </div >
    );
  }
}

export default CalculatorLayout;
