import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

function UploadErrorModal(props) {
  return (
    <Modal backdrop="static" show={props.show} onHide={props.handleClose}>

      <Modal.Header closeButton>
        <Modal.Title>Upload Error</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h3>Document uploaded must be in one of the following formats:</h3>
        <br />
        <h4>.pdf .jpg .png</h4>
      </Modal.Body>

      <Modal.Footer>
        <button className="closeButton" onClick={() => props.handleClose()}>Close</button>
      </Modal.Footer>

    </Modal>
  );
}

UploadErrorModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default UploadErrorModal;
