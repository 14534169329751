import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { Table } from 'react-bootstrap';

// Components
import SimpleTableBody from 'components/SimpleTableBody/container/SimpleTableBody';

// CSS
import styles from '../view/GeofenceEvents.module.scss';

function getEnterExit(geofenceObj) {
  if (geofenceObj.get('geofenceEventStateInt') === 1) {
    return 'Enter';
  } else if (geofenceObj.get('geofenceEventStateInt') === 2) {
    return 'Exit';
  }
  return '';
}

class GeofenceEventsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { geofenceEventRows: [], loaded: false };
  }

  componentWillReceiveProps(nextProps) {
    const newState = { ...this.state };
    let keyCounter = 0;
    newState.geofenceEventRows = nextProps.geofenceEvents.map(geofenceEvent => {
      const geofenceEventRow = (
        <tr key={`${geofenceEvent.id}${keyCounter}`} className={styles.geofenceEventRow}>
          <td>{moment(geofenceEvent.get('dateTime')).format('h:mm a')}</td>
          <td>{geofenceEvent.get('vehicleUnitId') || geofenceEvent.get('trailerUnitId')}</td>
          <td>{geofenceEvent.get('geofence_name')}</td>
          <td>{getEnterExit(geofenceEvent)}</td>
        </tr>
      );
      keyCounter++;
      return geofenceEventRow;
    });
    this.setState(newState);
  }

  render() {
    return (
      <div className="dataTable mt-0" onScroll={(e) => { if (this.state.loaded) this.props.scrollHandler(e); }}>
        <Table responsive hover>
          <thead>
            <tr>
              <th>
                Date
              </th>
              <th>
                Vehicle
              </th>
              <th>
                Location
              </th>
              <th>
                Enter / Exit
              </th>
            </tr>
          </thead>
          <SimpleTableBody
            rows={this.state.geofenceEventRows}
            loaded={!this.props.isLoading}
            columns="4"
          />
        </Table>
      </div>
    );
  }
}

GeofenceEventsTable.propTypes = {
  geofenceEvents: PropTypes.array,
  isLoading: PropTypes.bool,
  scrollHandler: PropTypes.func,
  sortHandler: PropTypes.func,
  sort: PropTypes.object,
};

export default GeofenceEventsTable;
