import React, { useState } from 'react';

// Components
import Button from 'sbCore/Button/Button';
import Dialog from 'sbCore/Dialog/Dialog';

// API
import * as Analytics from 'api/Analytics';
import { getAttribute, getCurrentUser } from 'sb-csapi/dist/AAPI';
import * as Email from 'api/Email/Email';

// CSS
import './style.scss';

/**
 * @description A button that opens up a modal for Vision module whenever there is no dashcam installed
 */
function NoDashcamBtn({ ...props }) {
  const [showModal, setShowModal] = useState(false);

  function handleConfirm(confirm) {
    if (confirm) {
      const user = getCurrentUser();
      const userFirstName = getAttribute(user, 'firstName', true);
      const userLastName = getAttribute(user, 'lastName', true);
      const userPhoneNumber = getAttribute(user, 'phoneNumberCleaned', true);
      const email = getAttribute(user, 'email', true);

      const company = getAttribute(user, 'belongsToCompany', true);
      const companyName = getAttribute(company, 'name', true);

      Analytics.track('Switchboard Vision Interest');
      // Email.send(
      //   'Switchboard Vision',
      //   'contact@onswitchboard.com',
      //   'sales@onswitchboard.com',
      //   `[Current Customer Interest] Switchboard Vision - ${companyName || 'N/A'}`,
      //   `Company ${companyName || 'N/A'} is interested in Switchboard Vision Smart Dashcam (from Switchboard Web App). Give them a call!

      //   Name: ${userFirstName || 'N/A'} ${userLastName || 'N/A'}
      //   Phone Number: ${userPhoneNumber || 'N/A'}
      //   Email: ${email || 'N/A'}
      //   `,
      // );
    }

    setShowModal(false);
  }

  const footer = (
    <div>
      <Button label="Cancel" className="p-button-sm p-button-text uppercase" onClick={() => setShowModal(false)} />
      <Button label="Request Vision Dashcams" className="p-button-sm uppercase" onClick={() => handleConfirm(true)} />
    </div>
  );

  return (
    <div>
      <Button
        label="No Dashcam"
        sbVariant="slim"
        onClick={() => setShowModal(!showModal)}
        className="p-button-outlined p-button-danger"
      />

      <Dialog
        header="Request Switchboard Vision Smart Dashcam"
        footer={footer}
        visible={showModal}
        style={{ width: '70vw', maxWidth: '900px' }}
        onHide={() => setShowModal(false)}
      >
        <div>
          <div>
            <span className="font-bold">Switchboard Vision Smart Dashcams help keep your drivers safe while they're on the road.</span>
            <br /><br />
            Switchboard Vision technology maximizes the safety and protection of your fleet by allowing you to be there for any unforeseen events that may occur on the road.
            <br /><br />
            If you'd like to request Switchboard Vision, click <span className="font-bold">Request Vision Dashcams</span> below to get connected with one of our Switchboard representatives so that they can get you set up quickly.
          </div>

          <div className="mt-4">
            For more information regarding Switchboard Vision:
            <ul className="text-sm mt-1">
              <li>Email us at <a href="mailto:sales@onswitchboard.com" target="_blank">sales@onswitchboard.com</a></li>
              <li>Live Chat (chat icon on the bottom right of the screen)</li>
              <li>Call us at 1-844-5-FLEETS (1-844-535-3387)</li>
              <li>Visit our <a href="http://support.onswitchboard.com" target="_blank">knowledgebase</a></li>
            </ul>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default NoDashcamBtn;
