import React from 'react';

import { DataTable as PRDataTable } from 'primereact/datatable';

import './style.scss';

/**
 * 
 * @description - PrimeReact DataTable component
 * @param {String} className - Class
 * @param {String} variant - compact: for less padding
 * @returns
 */

export default function DataTable({ ...props }) {
  let className = 'sbc-datatable translate-me';
  if (props.className) className += ` ${props.className}`;
  if (props.variant === 'compact') className += ' datatable-compact';

  return (<PRDataTable {...props} className={className} />);
}
