import React from 'react';
import PropTypes from 'prop-types';
import { MDBInput, MDBRow, MDBCol } from 'mdbreact';

// CSS
import styles from './EquipmentDisplayFilter.module.scss';

function EquipmentDisplayFilter(props) {
  const { handleCheckboxToggle, vehiclesChecked, trailersChecked } = props;

  return (
    <MDBRow className={`${styles.equipmentDisplayFilterContainer} translate-me ${props.className ? props.className : ''}`} style={props.containerStyle}>
      <MDBCol onClick={(e, val) => handleCheckboxToggle('Vehicles')} style={{ margin: '0rem', paddingTop: '0rem', paddingBottom: '0rem' }}>
        <MDBInput type="checkbox" containerClass={styles.smallMargin} style={{ height: 'auto', width: 'auto' }} filled label="Display Vehicles" labelClass={styles.labels} checked={vehiclesChecked} />
      </MDBCol>
      <MDBCol onClick={(e, val) => handleCheckboxToggle('Trailers')} style={{ margin: '0rem', paddingTop: '0rem', paddingBottom: '0rem' }}>
        <MDBInput type="checkbox" containerClass={styles.smallMargin} style={{ height: 'auto', width: 'auto' }} filled label="Display Trailers" labelClass={styles.labels} checked={trailersChecked} />
      </MDBCol>
    </MDBRow>
  );
}

EquipmentDisplayFilter.propTypes = {
  handleCheckboxToggle: PropTypes.func,
  vehiclesChecked: PropTypes.bool,
  trailersChecked: PropTypes.bool,
  containerStyle: PropTypes.object,
  paperStyle: PropTypes.object,
};

export default EquipmentDisplayFilter;
