import React from 'react';
import uniqid from 'uniqid';

// Material
import { MDBContainer, MDBIcon, MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardText, MDBRow, MDBCol, MDBPopover, MDBPopoverBody } from 'mdbreact';
import Divider from 'material-ui/Divider';

// Api
import { getVehicleLocationDescription, getLocationDescriptionBreakdown, isValidLocationDescription } from 'api/VehicleLocation/VehicleLocation';
import { createTempRecord, getCurrentUser, getAttribute, updateRecord, addRecord, createTempPointer, createQuery, setQueryRestriction, find } from 'api/Parse';
import TouchingStateProvinces from 'api/Lists/TouchingStateProvinces';
import { distanceBetweenTwoGeopoints } from '../../../api/Mapbox';

// Components
import DriverAutocomplete from 'components/Shared/DriverAutocomplete/DriverAutocomplete.old';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import LocationAutocomplete from 'components/Shared/LocationAutocomplete/LocationAutocomplete';
import DateTimePicker from 'components/Shared/DateTimePicker/DateTimePicker';
import VehicleAutocomplete from 'components/Shared/VehicleAutocomplete/VehicleAutocomplete';


// Helpers
import * as Helpers from '../../../api/Helpers';

// Enums
import { StateProvince } from 'enums/StateProvince';
import { LengthUnit, LengthUnitNames, LengthUnitTypes } from 'enums/DispatchUnit';
import { LocationDirectionTypes, LocationDirectionTypeDescription } from 'enums/LocationDirection';
import { QueryRestrictionTypes } from 'enums/Query';
import AddEditFuelPurchases from './AddEditFuelPurchases';

class AddEditRouteCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errorHash: {
        datesAreValid: {
          bool: undefined,
          explanation: undefined,
        },
        routesAreValid: {
          bool: undefined,
          explanation: undefined,
        },
        startAndEndLocationsAreValid: undefined,
        // startAndEndDistancesAreValid: undefined,
        startAndEndStateProvincesAreValid: {
          bool: undefined,
          explanation: undefined,
        },
        startAndEndOdometerReadingsAreValid: {
          bool: undefined,
          explanation: undefined,
        },
        driverAndFuelAreValid: true, // Initialize this to true to begin with
      },
      fuelPurchaseHash: {},
      startMileageLocation: undefined,
      endMileageLocation: undefined,
      allFieldsValid: false,
      startDate: undefined,
      endDate: undefined,
      customLocationBuilder: {
        startCustomLocationObject: {
          location: undefined,
          distance: 0,
          distanceunit: props.distanceUnit.toUpperCase(),
          heading: {
            type: LocationDirectionTypes.N,
            value: LocationDirectionTypes.N,
          },
          city: '',
          stateProvince: {
            code: undefined,
          }
        },
        endCustomLocationObject: {
          location: undefined,
          distance: 0,
          distanceUnit: props.distanceUnit.toUpperCase(),
          heading: {
            type: LocationDirectionTypes.N,
            value: LocationDirectionTypes.N,
          },
          city: '',
          stateProvince: {
            code: undefined,
          }
        },
        isManuallyEntered: false,
      },
      driver: undefined,
      odometerReadings: {
        odometerStart: "0",
        odometerEnd: "0",
        unit: props.distanceUnit.toUpperCase(),
      },
      unit: props.distanceUnit.toUpperCase(),
      mileageCalculatorDistance: ` `,
      inputtedOdometerStart: '',
      inputtedOdometerEnd: '',
      enableAutoScroll: false,
    };

    this.updateCustomLocation = this.updateCustomLocation.bind(this);
    this.determineFormValidity = this.determineFormValidity.bind(this);
    this.determineDateValidity = this.determineDateValidity.bind(this);
    this.determineStartAndEndLocationValidity = this.determineStartAndEndLocationValidity.bind(this);
    // this.determineStartAndEndDistancesValidity = this.determineStartAndEndDistancesValidity.bind(this);
    this.determineStartAndEndOdometerReadingValidity = this.determineStartAndEndOdometerReadingValidity.bind(this);
    this.determineDriverAndFuelValidity = this.determineDriverAndFuelValidity.bind(this);
    this.calculateMileageFromLocations = this.calculateMileageFromLocations.bind(this);
    this.determineRouteValidity = this.determineRouteValidity.bind(this);
    this.initializeCard = this.initializeCard.bind(this);
    this.updateRouteInformationWithFuelPurchases = this.updateRouteInformationWithFuelPurchases.bind(this);
    this.determineStateProvinceValidity = this.determineStateProvinceValidity.bind(this);
  }

  componentDidMount() {
    this.initializeCard();
  }

  initializeCard() {
    if (this.props.isEdit) {
      this.setState(this.props.iftaRoute.routeInformation);
    } else {
      this.setState(this.props.iftaRoute.routeInformation);
    }
  }

  /**
   * @description Create/Update the customLocation object using the client's information
   * @param {String} value The actual value of the attribute we are updating
   * @param {String} attribute The attribute we are updating (city, stateProvince etc.)
   * @param {Number} type 0: startCustomLocation, 1: endCustomLocation
   */
  updateCustomLocation(value, attribute, type) {
    const newState = { ...this.state };
    newState.editInfo = { ...newState.editInfo };
    newState.customLocationBuilder = { ...newState.customLocationBuilder };
    newState.enableAutoScroll = false;

    // 'city', 'stateProvince', 'distance', 'heading'
    let validityType;
    switch (attribute) {
      case 'city':
        validityType = 'locations';
        break;
      case 'stateProvince':
        validityType = 'locations';
        break;
      // case 'distance':
      //   validityType = 'distances';
      //   break;
      // case 'heading':
      //   validityType = 'distances';
      //   break;
      default:
        break;
    }

    const startCustomLocationObject = newState.customLocationBuilder.startCustomLocationObject = {
      ...newState.customLocationBuilder.startCustomLocationObject,
      heading: {
        ...newState.customLocationBuilder.startCustomLocationObject.heading,
      },
      stateProvince: {
        ...newState.customLocationBuilder.startCustomLocationObject.stateProvince,
      }
    };
    const endCustomLocationObject = newState.customLocationBuilder.endCustomLocationObject = {
      ...newState.customLocationBuilder.endCustomLocationObject,
      heading: {
        ...newState.customLocationBuilder.endCustomLocationObject.heading,
      },
      stateProvince: {
        ...newState.customLocationBuilder.endCustomLocationObject.stateProvince,
      }
    };

    if (!type) {
      if (attribute === 'distance') {
        newState.customLocationBuilder.startCustomLocationObject[attribute] = (value < 0 ? 0 : value).toString();
      } else if (attribute === 'heading') {
        newState.customLocationBuilder.startCustomLocationObject[attribute].value = (value || '').toString();
      } else if (attribute.toLowerCase() === 'distanceUnit') {
        newState.customLocationBuilder.startCustomLocationObject[attribute] = (value || '').toString();
        newState.unit = (value || '').toString();
      } else if (attribute.toLowerCase() === 'stateprovince') {
        newState.customLocationBuilder.startCustomLocationObject[attribute].code = (value || '').toString();
      } else {
        newState.customLocationBuilder.startCustomLocationObject[attribute] = (value || '').toString();
      }

      newState.editInfo.startCustomLocation = getVehicleLocationDescription(
        startCustomLocationObject.city,
        startCustomLocationObject.stateProvince && startCustomLocationObject.stateProvince.code,
        startCustomLocationObject.distance,
        startCustomLocationObject.distanceUnit,
        startCustomLocationObject.heading && startCustomLocationObject.heading.value
      );
    } else {
      if (attribute === 'distance') {
        newState.customLocationBuilder.endCustomLocationObject[attribute] = (value < 0 ? 0 : value).toString();
      } else if (attribute === 'heading') {
        newState.customLocationBuilder.endCustomLocationObject[attribute].value = (value || '').toString();
      } else if (attribute.toLowerCase() === 'distanceUnit') {
        newState.customLocationBuilder.endCustomLocationObject[attribute] = (value || '').toString();
        newState.unit = (value || '').toString();
      } else if (attribute.toLowerCase() === 'stateprovince') {
        newState.customLocationBuilder.endCustomLocationObject[attribute].code = (value || '').toString();
      } else {
        newState.customLocationBuilder.endCustomLocationObject[attribute] = (value || '').toString();
      }

      newState.editInfo.endCustomLocation = getVehicleLocationDescription(
        endCustomLocationObject.city,
        endCustomLocationObject.stateProvince && endCustomLocationObject.stateProvince.code,
        endCustomLocationObject.distance,
        endCustomLocationObject.distanceUnit,
        endCustomLocationObject.heading && endCustomLocationObject.heading.value
      );
    }

    newState.customLocationBuilder.isManuallyEntered = true;
    this.setState(newState,
      () => {
        this.determineFormValidity(this.state, validityType);
      });
  }

  /**
   * @description Determine if all necessary fields are filled in by client and update the card in the modal
   * @param {Object} routeInformation Object containing all the information pertaining to a given iftaRoute
   * @param {String} validityType String that determines which field we should check for validity
   */
  async determineFormValidity(routeInformation, validityType) {
    const { customLocationBuilder, driver, editInfo, endDate, odometerReadings, startDate, unit, startMileageLocation, endMileageLocation } = routeInformation;
    const newState = { ...this.state };
    newState.errorHash = { ...this.state.errorHash };
    newState.allFieldsValid = { ...this.state.allFieldsValid };

    // Determine each field validity
    switch (validityType) {
      case 'dates':
        newState.errorHash.datesAreValid = this.determineDateValidity(startDate, endDate);
        newState.errorHash.routesAreValid = await this.determineRouteValidity(startDate, endDate);
        break;
      case 'locations':
        newState.errorHash.startAndEndLocationsAreValid = this.determineStartAndEndLocationValidity(customLocationBuilder.startCustomLocationObject, customLocationBuilder.endCustomLocationObject);
        newState.errorHash.startAndEndStateProvincesAreValid = this.determineStateProvinceValidity(customLocationBuilder.startCustomLocationObject, customLocationBuilder.endCustomLocationObject);
        break;
      // case 'distances':
      //   newState.errorHash.startAndEndDistancesAreValid = this.determineStartAndEndDistancesValidity(customLocationBuilder.startCustomLocationObject, customLocationBuilder.endCustomLocationObject);
      //   break;
      case 'odometers':
        newState.errorHash.startAndEndOdometerReadingsAreValid = this.determineStartAndEndOdometerReadingValidity(odometerReadings);
        break;
      case 'driver':
        newState.errorHash.driverAndFuelAreValid = this.determineDriverAndFuelValidity(driver);
        break;
      default:
        break;
    }

    // Determine if all fields are valid to allow client to confirm their routes
    if (this.props.isEdit) {
      if (
        newState.errorHash.datesAreValid.bool &&
        newState.errorHash.startAndEndLocationsAreValid &&
        // newState.errorHash.startAndEndDistancesAreValid &&
        newState.errorHash.startAndEndOdometerReadingsAreValid.bool &&
        // newState.errorHash.driverAndFuelAreValid &&
        newState.errorHash.startAndEndStateProvincesAreValid.bool
      ) {
        newState.allFieldsValid = true;
      } else {
        newState.allFieldsValid = false;
      }
    } else {
      if (
        newState.errorHash.datesAreValid.bool &&
        newState.errorHash.routesAreValid.bool &&
        newState.errorHash.startAndEndLocationsAreValid &&
        // newState.errorHash.startAndEndDistancesAreValid &&
        newState.errorHash.startAndEndOdometerReadingsAreValid.bool &&
        // newState.errorHash.driverAndFuelAreValid &&
        newState.errorHash.startAndEndStateProvincesAreValid.bool
      ) {
        newState.allFieldsValid = true;
      } else {
        newState.allFieldsValid = false;
      }
    }

    this.setState(newState,
      () => {
        this.props.updateRouteInformationForIFTARouteCardHash(this.state);
      }
    );
  }

  /**
   * @description Determines if the dates inputted by the client are valid
   * @param {Date} startDate Start date
   * @param {Date} endDate End Date
   * @returns Object containing a boolean (true if valid, false if not) and an explanation to display to the client
   */
  determineDateValidity(startDate, endDate) {
    const newState = { ...this.state };
    if (startDate && endDate) {
      const startDateInMilliSeconds = startDate.getTime();
      const endDateInMilliSeconds = endDate.getTime();

      if (startDateInMilliSeconds > endDateInMilliSeconds) {
        return {
          bool: false,
          explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> Start date cannot be greater than end date</div>,
        };
      } else {
        return {
          bool: true,
        };
      }
    } else {
      this.setState(newState);
      return {
        bool: false,
        explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> One or more dates are undefined</div>,
      };
    }
  }

  /**
   * @description Determines if routes chosen by client are overlapping
   * @param {Date} startDate Start date
   * @param {Date} endDate End date
   * @returns Object containing a boolean (true if valid, false if not) and an explanation to display to the client
   */
  async determineRouteValidity(startDate, endDate) {
    const currentUser = getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
    let companyObjectId = getAttribute(belongsToCompany, 'objectId');

    if (startDate && endDate && (startDate.getTime() < endDate.getTime())) {

      // We want client to avoid creating routes with an interval that is partially contained within a route (startDate)
      // * = startDate, ** = endDate, |----| = iftaRoute
      // *------------|------------**----------|
      const intervalStartDatePartiallyContainsIFTARoutesQuery = createQuery('IFTARoute_Beta');
      setQueryRestriction(intervalStartDatePartiallyContainsIFTARoutesQuery, QueryRestrictionTypes.GREATER_THAN, 'dateStart', startDate);
      setQueryRestriction(intervalStartDatePartiallyContainsIFTARoutesQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'dateEnd', endDate);
      setQueryRestriction(intervalStartDatePartiallyContainsIFTARoutesQuery, QueryRestrictionTypes.LESS_THAN, 'dateStart', endDate);
      setQueryRestriction(intervalStartDatePartiallyContainsIFTARoutesQuery, QueryRestrictionTypes.EQUAL_TO, 'vehicleUnitId', this.props.unitId);
      setQueryRestriction(intervalStartDatePartiallyContainsIFTARoutesQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', companyObjectId);
      setQueryRestriction(intervalStartDatePartiallyContainsIFTARoutesQuery, QueryRestrictionTypes.EQUAL_TO, 'isHidden', undefined);
      const iftaRoutesPartiallyContainIntervalStartDate = await find(intervalStartDatePartiallyContainsIFTARoutesQuery, true, false);

      // We want client to avoid creating routes with an interval that is partially contained within a route (endDate)
      // * = startDate, ** = endDate, |----| = iftaRoute
      // |--------------*--------|--------**
      const intervalEndDatePartiallyContainsIFTARoutesQuery = createQuery('IFTARoute_Beta');
      setQueryRestriction(intervalEndDatePartiallyContainsIFTARoutesQuery, QueryRestrictionTypes.GREATER_THAN, 'dateEnd', startDate);
      setQueryRestriction(intervalEndDatePartiallyContainsIFTARoutesQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'dateStart', startDate);
      setQueryRestriction(intervalEndDatePartiallyContainsIFTARoutesQuery, QueryRestrictionTypes.LESS_THAN, 'dateEnd', endDate);
      setQueryRestriction(intervalEndDatePartiallyContainsIFTARoutesQuery, QueryRestrictionTypes.EQUAL_TO, 'vehicleUnitId', this.props.unitId);
      setQueryRestriction(intervalEndDatePartiallyContainsIFTARoutesQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', companyObjectId);
      setQueryRestriction(intervalEndDatePartiallyContainsIFTARoutesQuery, QueryRestrictionTypes.EQUAL_TO, 'isHidden', undefined);
      const iftaRoutesPartiallyContainIntervalEndDate = await find(intervalEndDatePartiallyContainsIFTARoutesQuery, true, false);

      // We want client to avoid creating routes with an interval that already contains routes
      // * = startDate, ** = endDate, |----| = iftaRoute
      // *--|----------------------|---**
      const intervalWithinIFTARoutesQuery = createQuery('IFTARoute_Beta');
      setQueryRestriction(intervalWithinIFTARoutesQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'dateStart', startDate);
      setQueryRestriction(intervalWithinIFTARoutesQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'dateEnd', endDate);
      setQueryRestriction(intervalWithinIFTARoutesQuery, QueryRestrictionTypes.EQUAL_TO, 'vehicleUnitId', this.props.unitId);
      setQueryRestriction(intervalWithinIFTARoutesQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', companyObjectId);
      setQueryRestriction(intervalWithinIFTARoutesQuery, QueryRestrictionTypes.EQUAL_TO, 'isHidden', undefined);
      const iftaRoutesContainInterval = await find(intervalWithinIFTARoutesQuery, true, false);

      // We want client to avoid creating routes with an interval that is contained within a route
      // * = startDate, ** = endDate, |----| = iftaRoute
      // |---*------------**----------|
      const intervalContainsIFTARoutesQuery = createQuery('IFTARoute_Beta');
      setQueryRestriction(intervalContainsIFTARoutesQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'dateStart', startDate);
      setQueryRestriction(intervalContainsIFTARoutesQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'dateEnd', endDate);
      setQueryRestriction(intervalContainsIFTARoutesQuery, QueryRestrictionTypes.EQUAL_TO, 'vehicleUnitId', this.props.unitId);
      setQueryRestriction(intervalContainsIFTARoutesQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', companyObjectId);
      setQueryRestriction(intervalContainsIFTARoutesQuery, QueryRestrictionTypes.EQUAL_TO, 'isHidden', undefined);
      const iftaRoutesWithinInterval = await find(intervalContainsIFTARoutesQuery, true, false);


      if (iftaRoutesContainInterval) {
        return {
          bool: false,
          explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> Interval chosen contains other routes</div>,
        };
      } else if (iftaRoutesWithinInterval) {
        return {
          bool: false,
          explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> Interval chosen is contained within another route</div>,
        };
      } else if (iftaRoutesPartiallyContainIntervalStartDate) {
        return {
          bool: false,
          explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> Interval chosen partially contains other routes</div>,
        };
      } else if (iftaRoutesPartiallyContainIntervalEndDate) {
        return {
          bool: false,
          explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> Interval chosen partially contains other routes</div>,
        };
      } else {
        return {
          bool: true,
        };
      }
    } else {
      return {
        bool: false,
        explanation: <div></div>
      };
    }
  }

  /**
   * @description Determines if start and end locations inputted by client are valid
   * @param {Object} startLocationObj Object containing city and stateProvince of start location
   * @param {Object} endLocationObj Object containing city and stateProvince of end location
   * @returns True or false if start and end locations are valid
   */
  determineStartAndEndLocationValidity(startLocationObj, endLocationObj) {
    const startCity = startLocationObj.city;
    const startStateProvince = startLocationObj.stateProvince.code;

    const endCity = endLocationObj.city;
    const endStateProvince = endLocationObj.stateProvince.code;

    if (
      startStateProvince && startCity !== '' &&
      endStateProvince && endCity !== ''
    ) {
      return true;
    } else {
      return false;
    }
  }

  // determineStartAndEndDistancesValidity(startLocationObj, endLocationObj) {
  //   const startDistance = parseInt(startLocationObj.distance);
  //   const endDistance = parseInt(endLocationObj.distance);

  //   if (startDistance && endDistance) {
  //     if (startDistance > -1 && endDistance > -1) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  /**
   * @description Determines if odometer readings inputted by client are valid
   * @param {Object} odometerReadings Object containing the odometer readings inputted by client
   * @returns Object containing a boolean (true if valid, false if not) and an explanation to display to the client
   */
  determineStartAndEndOdometerReadingValidity(odometerReadings) {
    const startOdometerReading = parseInt(odometerReadings.odometerStart);
    const endOdometerReading = parseInt(odometerReadings.odometerEnd);

    if (isNaN(startOdometerReading) || isNaN(endOdometerReading)) {
      return {
        bool: false,
        explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> One or more readings are undefined</div>,
      };
    } else {
      if (
        startOdometerReading >= 0 &&
        endOdometerReading >= 0 &&
        startOdometerReading <= endOdometerReading
      ) {
        return {
          bool: true,
        };
      } else {
        return {
          bool: false,
          explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> Odometer start cannot be greater than odometer end</div>,
        };
      }
    }
  }

  /**
   * @description Determines if driver chosen by client is valid
   * @param {Parse Record} driver Driver record
   * @returns True or false if driver is valid or not
   */
  determineDriverAndFuelValidity(driver) {
    return true; // Remove checks for driver as it is now optional
    // if (driver !== '') {
    //   return true;
    // } else {
    //   return false;
    // }
  }

  /**
   * @description Determine if stateProvinces are valid and not touching
   * @param {Object} startLocationObj Object containing city and stateProvince of start location
   * @param {Object} endLocationObj Object containing city and stateProvince of end location
   * @returns Object containing a boolean (true if valid, false if not) and an explanation to display to the client
   */
  determineStateProvinceValidity(startLocationObj, endLocationObj) {
    const startStateProvince = startLocationObj.stateProvince.code;
    const endStateProvince = endLocationObj.stateProvince.code;

    if (startStateProvince && endStateProvince) {
      if (startStateProvince !== endStateProvince) {
        if (TouchingStateProvinces[startStateProvince.toUpperCase()].indexOf(endStateProvince.toUpperCase()) !== -1) {
          // StateProvinces are defined and touching
          return {
            bool: true,
          };
        } else {
          // StateProvinces are defined but not touching
          return {
            bool: false,
            explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> Start and end state/provinces are not touching</div>,
          };
        }
      } else {
        // StateProvinces both defined and unequal
        return {
          bool: true,
        };
      }
    } else {
      // One or more stateProvinces are undefined
      return {
        bool: false,
        explanation: <div style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'normal' }}><span style={{ 'fontSize': '0.9em', 'color': 'red' }}>Warning: </span> One or more state/provinces are not defined</div>,
      };
    }
  }

  /**
   * @description Calculates the mileage between the start and end locations inputted by client
   * @param {Object} startLocationObj Object containing city and stateProvince of start location
   * @param {Object} endLocationObj Object containing city and stateProvince of end location
   * @param {String} unit Unit client is using
   */
  calculateMileageFromLocations(startLocationObj, endLocationObj, unit) {
    let distanceKm = distanceBetweenTwoGeopoints(
      startLocationObj.latitude,
      startLocationObj.longitude,
      endLocationObj.latitude,
      endLocationObj.longitude
    );

    if (unit === 'KM') {
      this.setState({ ...this.state, mileageCalculatorDistance: `${Math.round(distanceKm)} ` + unit });
    } else {
      distanceKm = Helpers.convertDistance(distanceKm, 'km', 'mi');
      this.setState({ ...this.state, mileageCalculatorDistance: `${Math.round(distanceKm)} ` + unit });
    }
  }

  /**
   * @description Updates the iftaRoute with the fuelPurchases that the client has inputted
   * @param {Object} fuelPurchaseHash Object with fuelPurchaseRecords and their corresponding fuelInformation indexed by key
   */
  updateRouteInformationWithFuelPurchases(fuelPurchaseHash) {
    // Obtain company objectId
    const currentUser = getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
    let companyObjectId = getAttribute(belongsToCompany, 'objectId');
    companyObjectId = createTempPointer('Company', companyObjectId);

    // Update the record with the purchase information given by the client
    for (const key in fuelPurchaseHash) {
      const currentFuelPurchaseRecord = fuelPurchaseHash[key].fuelPurchase;
      const {
        city,
        currency,
        date,
        fuelCardFourDigits,
        fuelType,
        stateProvince,
        totalPaid,
        volumePumped,
        volumeUnits,
      } = fuelPurchaseHash[key].purchaseInformation;

      updateRecord(
        currentFuelPurchaseRecord,
        {
          volumePumped: parseInt(volumePumped),
          stateProvince: stateProvince,
          vehicleUnitId: this.props.unitId,
          driver: undefined,
          belongsToCompany: companyObjectId,
          totalPaid: parseInt(totalPaid),
          fuelType: fuelType,
          currency: currency,
          timeMillis: date,
          volumeUnits: volumeUnits,
        },
        false,
      );
    }

    this.setState({ ...this.state, fuelPurchaseHash, enableAutoScroll: false, }, () => this.determineFormValidity(this.state));
  }

  handleVehicleSelection(vehicle) {
    this.setState({ newVehicleForRoute: vehicle });
  }

  render() {
    const { state, props } = this;

    const distanceUnitItems = [LengthUnitTypes.KM, LengthUnitTypes.MI].map(unit => {
      return {
        key: unit,
        label: unit,
        value: unit,
      }
    });

    const selectedUnit = distanceUnitItems.filter(item => {
      return item.value.toLowerCase() === (state.unit || '').toLowerCase();
    })[0] || distanceUnitItems[0];

    const headingItems = Object.keys(LocationDirectionTypes).map(heading => {
      return {
        key: heading,
        label: LocationDirectionTypeDescription[heading],
        value: heading,
      }
    });

    const selectedStartLocationHeading = headingItems.filter(item => {
      const customStartLocationObjectHeading = state.customLocationBuilder.startCustomLocationObject.heading || {};
      return item.value.toLowerCase() === (customStartLocationObjectHeading.value || '').toLowerCase();
    })[0] || headingItems[0];

    const selectedEndLocationHeading = headingItems.filter(item => {
      const customEndLocationObjectHeading = state.customLocationBuilder.endCustomLocationObject.heading || {};
      return item.value.toLowerCase() === (customEndLocationObjectHeading.value || '').toLowerCase();
    })[0] || headingItems[0];

    const stateProvinceItems = Object.keys(StateProvince).map(stateProvinceKey => {
      const stateProvinceObject = StateProvince[stateProvinceKey];
      return {
        key: stateProvinceObject.code,
        label: stateProvinceObject.name,
        value: stateProvinceObject.code,
      }
    });

    const selectedStartLocationStateProvince = stateProvinceItems.filter(item => {
      const customStartLocationObjectStateProvince = state.customLocationBuilder.startCustomLocationObject.stateProvince || {};
      return item.value.toLowerCase() === (customStartLocationObjectStateProvince.code || '').toLowerCase();
    })[0];

    const selectedEndLocationStateProvince = stateProvinceItems.filter(item => {
      const customEndLocationObjectStateProvince = state.customLocationBuilder.endCustomLocationObject.stateProvince || {};
      return item.value.toLowerCase() === (customEndLocationObjectStateProvince.code || '').toLowerCase();
    })[0];

    let cardFooter;
    if (props.isEdit) {
      cardFooter = <div></div>;
    } else {
      cardFooter =
        <div>
          <Divider />
          <MDBRow className="pt-2 pl-2">
            <MDBBtn className={'buttonDefault'} onClick={() => props.deleteRoute(props.keyId)}>Cancel Route</MDBBtn>
          </MDBRow>
        </div>
    }

    return (
      <MDBCard className="z-depth-0 text-left" style={{ 'overflow': 'auto' }}>
        <MDBCardBody className="p-0" >
          <form
            className="needs-validation"
            onSubmit={this.submitHandler}
            noValidate
          ></form>

          {/* Start and End Date */}
          <div>
            <div className="mt-2" style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'bolder' }}><MDBIcon icon="calendar" className="mr-2" />Dates</div>
            {this.state.errorHash.datesAreValid.bool === false &&
              this.state.errorHash.datesAreValid.explanation
            }
            {this.state.errorHash.routesAreValid.bool === false &&
              this.state.errorHash.routesAreValid.explanation
            }
            <MDBRow>
              <MDBCol className="w-50">
                <DateTimePicker
                  label="Trip Start"
                  selectedDate={state.startDate}
                  onDateChange={(startDate) => this.setState({ ...this.state, startDate, enableAutoScroll: true, }, () => this.determineFormValidity(this.state, 'dates'))}
                  showTimePicker
                  dateFormat="yyyy-MM-dd HH:mm"
                  required
                />
              </MDBCol>
              <MDBCol className="w-50">
                <DateTimePicker
                  label="Trip End"
                  selectedDate={state.endDate}
                  onDateChange={(endDate) => this.setState({ ...this.state, endDate, enableAutoScroll: true, }, () => this.determineFormValidity(this.state, 'dates'))}
                  showTimePicker
                  dateFormat="yyyy-MM-dd HH:mm"
                  required
                />
              </MDBCol>
            </MDBRow>
          </div>

          {/* Mileage Calculator  */}
          {/* <div>
            <div className="mt-2" style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'bolder' }}><MDBIcon icon="calculator" className="mr-2"/>Mileage Calculator</div>
            <MDBRow>
              <MDBCol>
                <div className="d-inline-block align-middle mr-1">
                  <LocationAutocomplete
                    placeholder=" "
                    label="Start"
                    onLocationSelected={(locationSelected) => this.setState({ ...this.state, startMileageLocation: locationSelected }, () => this.determineFormValidity(this.state, true))}
                  />
                </div>
              </MDBCol>
              <MDBCol>
                <div className="d-inline-block align-middle mr-1">
                  <LocationAutocomplete
                    placeholder=" "
                    label="Destination"
                    onLocationSelected={(locationSelected) => this.setState({ ...this.state, endMileageLocation: locationSelected }, () => this.determineFormValidity(this.state, true))}
                  />
                </div>
              </MDBCol>
              <MDBCol>
                <MDBInput
                  label="Calculated Mileage"
                  labelClass="inactive"
                  value={state.mileageCalculatorDistance}
                />
              </MDBCol>
            </MDBRow>
          </div> */}

          {/* Start Location */}
          <div>
            <div className="mt-2" style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'bolder' }}><MDBIcon icon="compass" className="mr-2" />Start Location</div>
            {this.state.errorHash.startAndEndStateProvincesAreValid.bool === false &&
              this.state.errorHash.startAndEndStateProvincesAreValid.explanation
            }
            <MDBRow>
              <MDBCol className="w-25">
                <div className="d-inline-block align-middle mr-1">
                  <MDBInput
                    label="City"
                    labelClass="active"
                    value={state.customLocationBuilder.startCustomLocationObject.city}
                    getValue={(value) => this.updateCustomLocation(value, 'city', 0)}
                  />
                </div>
              </MDBCol>
              <MDBCol className="w-25">
                <div className="md-form d-inline-block align-middle mr-1">
                  <SBSelect
                    onClick={(e) => e.stopPropagation()}
                    items={stateProvinceItems}
                    selectedItems={selectedStartLocationStateProvince ? [selectedStartLocationStateProvince] : []}
                    getSelectedItems={(items) => { this.updateCustomLocation(items[0].value.toUpperCase(), 'stateProvince', 0) }}
                    showFilter
                  />
                  <label className="active" data-error data-success>State/Province</label>
                </div>
              </MDBCol>
              <MDBCol className="w-25" />
              <MDBCol className="w-25" />
              {/* <MDBCol>
                <div className="d-inline-block align-middle mr-1">
                  <MDBInput
                    type="number"
                    label="Distance"
                    labelClass="active"
                    value={parseInt(state.customLocationBuilder.startCustomLocationObject.distance)}
                    getValue={(value) => this.updateCustomLocation(value, 'distance', 0)}
                  />
                </div>
              </MDBCol>
              <MDBCol>
                <div className="md-form d-inline-block align-middle">
                  <SBSelect
                    onClick={(e) => e.stopPropagation()}
                    items={headingItems}
                    selectedItems={[selectedStartLocationHeading]}
                    getSelectedItems={(items) => { this.updateCustomLocation(items[0].value.toUpperCase(), 'heading', 0) }}
                    showFilter
                  />
                  <label className="active" data-error data-success>Heading</label>
                </div>
              </MDBCol> */}
            </MDBRow>
          </div>

          {/* End Location */}
          <div>
            <div className="mt-2" style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'bolder' }}><MDBIcon icon="compass" className="mr-2" />End Location</div>
            <MDBRow>
              <MDBCol className="w-25">
                <div className="d-inline-block align-middle mr-1">
                  <MDBInput
                    label="City"
                    labelClass="active"
                    value={state.customLocationBuilder.endCustomLocationObject.city || ''}
                    getValue={(value) => this.updateCustomLocation(value, 'city', 1)}
                  />
                </div>
              </MDBCol>
              <MDBCol className="w-25">
                <div className="md-form d-inline-block align-middle mr-1">
                  <SBSelect
                    onClick={(e) => e.stopPropagation()}
                    items={stateProvinceItems}
                    selectedItems={selectedEndLocationStateProvince ? [selectedEndLocationStateProvince] : []}
                    getSelectedItems={(items) => { this.updateCustomLocation(items[0].value.toUpperCase(), 'stateProvince', 1) }}
                    showFilter
                  />
                  <label className="active" data-error data-success>State/Province</label>
                </div>
              </MDBCol>
              <MDBCol className="w-25" />
              <MDBCol className="w-25" />
              {/* <MDBCol>
                <div className="d-inline-block align-middle mr-1">
                  <MDBInput
                    type="number"
                    label="Distance"
                    labelClass="active"
                    value={parseInt(state.customLocationBuilder.endCustomLocationObject.distance)}
                    getValue={(value) => this.updateCustomLocation(value, 'distance', 1)}
                  />
                </div>
              </MDBCol>
              <MDBCol>
                <div className="md-form d-inline-block align-middle">
                  <SBSelect
                    onClick={(e) => e.stopPropagation()}
                    items={headingItems}
                    selectedItems={[selectedEndLocationHeading]}
                    getSelectedItems={(items) => { this.updateCustomLocation(items[0].value.toUpperCase(), 'heading', 1) }}
                    showFilter
                  />
                  <label className="active" data-error data-success>Heading</label>
                </div>
              </MDBCol> */}
            </MDBRow>
          </div>

          {/* Start and End Odometer Readings */}
          <div>
            <div className="mt-2" style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'bolder' }}><MDBIcon icon="tachometer-alt" className="mr-2" />Odometer Readings ({this.props.distanceUnit.toUpperCase()})</div>
            {this.state.errorHash.startAndEndOdometerReadingsAreValid.bool === false &&
              this.state.errorHash.startAndEndOdometerReadingsAreValid.explanation
            }
            <MDBRow>
              <MDBCol className="w-25">
                <div className="d-inline-block align-middle mr-1">
                  <MDBInput
                    type="number"
                    min={0}
                    label="Odometer Start"
                    labelClass="active"
                    value={state.inputtedOdometerStart}
                    getValue={(odometerStart) => {
                      let odometerStartKm = this.props.distanceUnit === 'mi' ? Helpers.convertDistance(odometerStart, 'mi', 'km') : odometerStart;
                      this.setState(prevState => ({
                        enableAutoScroll: false,
                        odometerReadings: {
                          ...prevState.odometerReadings,
                          odometerStart: odometerStartKm,
                        },
                        inputtedOdometerStart: odometerStart !== "0" ? odometerStart.replace(/^0+/, '') : odometerStart,
                      }), () => { this.determineFormValidity(this.state, 'odometers') })
                    }
                    }
                  />
                </div>
              </MDBCol>
              <MDBCol className="w-25">
                <div className="d-inline-block align-middle mr-1">
                  <MDBInput
                    type="number"
                    min={0}
                    label="Odometer End"
                    labelClass="active"
                    value={state.inputtedOdometerEnd}
                    getValue={(odometerEnd) => {
                      let odometerEndKm = this.props.distanceUnit === 'mi' ? Helpers.convertDistance(odometerEnd, 'mi', 'km') : odometerEnd;
                      this.setState(prevState => ({
                        enableAutoScroll: false,
                        odometerReadings: {
                          ...prevState.odometerReadings,
                          odometerEnd: odometerEndKm,
                        },
                        inputtedOdometerEnd: odometerEnd !== "0" ? odometerEnd.replace(/^0+/, '') : odometerEnd,
                      }), () => { this.determineFormValidity(this.state, 'odometers') })
                    }
                      //   this.setState(prevState => ({
                      //   odometerReadings: {
                      //     ...prevState.odometerReadings,
                      //     odometerEnd,
                      //   }
                      // }), () => {this.determineFormValidity(this.state)})
                    }
                  />
                </div>
              </MDBCol>
              <MDBCol className="w-25" />
              <MDBCol className="w-25" />
            </MDBRow>
          </div>

          {/* Driver Autocomplete */}
          <div>
            <MDBRow>
              <MDBCol className="w-50">
                <div className="mt-2" style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'bolder' }}>
                  <MDBIcon icon="id-card" className="mr-2" />Driver
                </div>
              </MDBCol>
              <MDBCol className="w-50">
                {props.isEdit && (
                  <div className="mt-2" style={{ 'fontSize': '0.9em', 'fontFamily': '$sb-font-primary', 'fontWeight': 'bolder' }}>
                    Migrate IFTA Route and fuel purchases {state.newVehicleForRoute ? `to ${getAttribute(state.newVehicleForRoute, 'unitId', true)} ` : ''}
                    (other changes ignored)
                  </div>
                )}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol className="w-25">
                <div className="md-form d-inline-block align-middle">
                  <DriverAutocomplete
                    isRequired={false}
                    onDriverSelected={(driver) => this.setState({ ...this.state, driver, enableAutoScroll: false, }, () => { this.determineFormValidity(this.state, 'driver') })}
                    placeholder={getAttribute(state.driver, 'user_fullName', true) ? Helpers.toTitleCase(getAttribute(state.driver, 'user_fullName', true)) : ''}
                  />
                </div>
              </MDBCol>
              <MDBCol className="w-25" style={{ 'paddingTop': '3em' }}>
                <AddEditFuelPurchases
                  isEdit={props.isEdit}
                  iftaRoute={props.iftaRoute.iftaRoute}
                  updateRouteInformationWithFuelPurchases={(fuelPurchaseHash) => this.updateRouteInformationWithFuelPurchases(fuelPurchaseHash)}
                  fuelPurchaseHash={this.state.fuelPurchaseHash}
                  initialDate={state.startDate}
                  initialStateProvince={selectedStartLocationStateProvince && selectedStartLocationStateProvince.value}
                />
              </MDBCol>
              <MDBCol className="w-25">
                {props.isEdit && (
                  <VehicleAutocomplete
                    onVehicleSelected={(vehicle) => this.handleVehicleSelection(vehicle)}
                    placeholder={getAttribute(state.newVehicleForRoute, 'unitId', true) || 'Select A Vehicle'}
                  />
                )}
              </MDBCol>
              <MDBCol className="w-25">
                {props.isEdit && (
                  <MDBBtn
                    disabled={!state.newVehicleForRoute}
                    onClick={() => props.migrateRouteFunction(state.newVehicleForRoute)}
                  >
                    Migrate
                  </MDBBtn>
                )}
              </MDBCol>
            </MDBRow>
          </div>
        </MDBCardBody>

        {cardFooter}

      </MDBCard>
    );
  }
}

export default AddEditRouteCard;
