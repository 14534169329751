import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { Router, Route, Switch } from 'react-router-dom';
import history from './sbHistory';

import LoginLayout from 'layouts/LoginLayout/LoginLayout';
import RegistrationLayout from 'layouts/RegistrationLayout/RegistrationLayout';
import InAppLayout from 'layouts/InAppLayout/InAppLayout';
import ShareLayout from 'layouts/ShareLayout/ShareLayout';
import UpgradeLayout from 'layouts/UpgradeLayout/UpgradeLayout';

function fireTracking() {
  ReactGA.pageview(window.location.pathname + window.location.search);
}
// onUpdate = { fireTracking }

export default (
  <Router history={history}>
    <Switch>
      <Route exact path="/" component={LoginLayout} />
      <Route path="/login" component={LoginLayout} />
      <Route path="/register" component={RegistrationLayout} />
      <Route path="/share" component={ShareLayout} />
      <Route path="/upgrade" component={UpgradeLayout} />

      <Route path="" component={InAppLayout} />
    </Switch>
  </Router>
);
