import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components

// CSS
import styles from './WhatsNewLayout.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

// Assets
const colourLogo = require('assets/images/colour-logo-inline.png');

class WhatsNewLayout extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.title = 'What\'s New - Switchboard';
  }

  render() {
    const companyName = Object.keys(this.props.Company.company).length > 0 ? this.props.Company.company.get('name') : '';
    return (
      <div className={styles.WhatsNewLayout}>
        <div className={`${styles.title} translate-me`}>What's New with Switchboard</div>
        <div className={styles.content}>
          <iframe
            src="https://docs.google.com/document/d/e/2PACX-1vSkO0HfByqHUTcjAoz5KAGBWYuqJSALkTGjP8dpT8V1aDXasd6LYv3j3HgcEyed8DNT712XvDH73qSY/pub?embedded=true"
          />
        </div>
      </div>
    );
  }
}

WhatsNewLayout.propTypes = {
  Company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Company } = state;
  return {
    Company,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(WhatsNewLayout);

