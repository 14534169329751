import React from 'react';
import PropTypes from 'prop-types';

// Components
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';

const HelpDialogView = (props) => {
  const { show, title, children, modal, style, onClose } = props;

  const actions = [
    <FlatButton
      key="close"
      label="Close"
      primary
      keyboardFocused
      onClick={onClose}
    />,
  ];

  return (
    <Dialog
      title={title || 'Help Dialog'}
      actions={actions}
      modal={modal}
      open={show}
      onRequestClose={onClose}
      contentStyle={{ ...style, maxWidth: 'none' }}
      autoScrollBodyContent
      className="translate-me"
    >
      { children }
    </Dialog>
  );
};

HelpDialogView.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.node,
  modal: PropTypes.bool,
  style: PropTypes.object,

  onClose: PropTypes.func.isRequired,
};

export default HelpDialogView;
