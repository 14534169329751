import React from 'react';
import PropTypes from 'prop-types';

// CSS
import styles from './DriversLimitWarningModal.module.scss';

function DriversLimitWarningModal(props) {
  const additionalDrivers = 25;
  const totalUpgradedDriversSupported = props.driversSupported + additionalDrivers;
  let upgradedSubscriptionCharge;

  if (props.currentCharge === undefined) {
    upgradedSubscriptionCharge = (props.chargePerDriver * additionalDrivers);
  } else {
    upgradedSubscriptionCharge = (props.chargePerDriver * additionalDrivers) + props.currentCharge;
  }

  return (
    <div>
      You are nearing your limit for drivers on Switchboard. To continue using Switchboard, upgrade the numbers of drivers supported selecting 'Upgrade Now' below

      <br />

      <div className={styles.monthlyFee}>
        <table><tbody><tr>
          <td>
            Upgrade to support {totalUpgradedDriversSupported} drivers:
            <br />
            ${upgradedSubscriptionCharge / 100} / month
          </td>
          <td className={styles.upgradeButton}>
            <button className="buttonDefault" onClick={() => props.handleUpgrade(upgradedSubscriptionCharge, totalUpgradedDriversSupported)} disabled={props.disableButton}>Upgrade</button>
          </td>
        </tr></tbody></table>
      </div>

      <div className={styles.contactUs}>
        Our team is working very hard to make sure your experience with Switchboard is <b>amazing</b>
        <br />
        Please let us know about anything we can do for you at <a href="mailto:support@onswitchboard.com">support@onswitchboard.com</a>
      </div>

      <div className={styles.closeButton}>
        <button className="closeButton" onClick={props.closeModal}>Close</button>
      </div>
    </div>
  );
}

DriversLimitWarningModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  chargePerDriver: PropTypes.number.isRequired,
  currentCharge: PropTypes.number,
  driversSupported: PropTypes.number.isRequired,
  handleUpgrade: PropTypes.func.isRequired,
  disableButton: PropTypes.bool.isRequired,
};

export default DriversLimitWarningModal;
