import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// API
import { addDispatchJobTransaction, getDispatchJobTransactions, updateDispatchJobTransaction, destroyDispatchJobTransaction } from 'api/Dispatch/DispatchJobTransactions';

// CSAPI API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// CSAPI Enums
import { PaymentMethod } from 'sb-csapi/dist/enums/Finance/PaymentMethod';
import { Currency } from 'sb-csapi/dist/enums/Finance/Currency';
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// CSAPI sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// sbCore Components
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import Button from 'sbCore/Button/Button';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import InputText from 'sbCore/InputText/InputText';
import InputNumber from 'sbCore/InputNumber/InputNumber';
import InputMask from 'sbCore/InputMask/InputMask';
import InputTextarea from 'sbCore/InputTextarea/InputTextarea';
import ToggleButton from 'sbCore/ToggleButton/ToggleButton';
import Toolbar from 'sbCore/Toolbar/Toolbar';
import PaymentMethodDropdown from 'sbCore/PaymentMethodDropdown/PaymentMethodDropdown';
import Message from 'sbCore/Message/Message';

// Styles
import './style.scss';

/**
 * @description Displays a history of transactions for the dispatch job
 * @param {Array} dispatchJobTransactions - Array of DispatchJobTransactions to display
 * @param {Function} [toggleRefresh] - Function to refresh state of the parent component
 * @param {Function} [onLastActionChange] - Update parent component's lastAction state to open the edit mode of a newly added transaction
 * @param {Boolean} [isLoading] - Whether or not parent component is loading
 * @param {DispatchReceivable} [dispatchReceivable] - Record passed when adding a new transaction
 * @param {DispatchPayable} [dispatchPayable] - Record passed when adding a new transaction
 * @param {Integer} [currencyInt] - The currency integer of the current currency being used
 * @param {Function} [onShowVoidConfirmationModal] - Displays the void receivable confirmation modal when ran. Is also used as a condition to show the voided button
 * @param {Boolean} [isVoided] - Whether or not the DispatchReceivable is voided
 * @param {Boolean} [disableVoiding] - Whether or not to disable voiding the payable/receivable
 * @returns
 */
function TransactionHistoryTable({ ...props }) {

  // Map the PaymentMethod object into an array
  const paymentMethods = Object.keys(PaymentMethod).map((key) => PaymentMethod[key]);

  // ** useStates ** //
  const [dispatchJobTransactionsObjectArray, setDispatchJobTransactionsObjectArray] = useState([]);
  const [isTransactionRowEditActive, setIsTransactionRowEditActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // ** useEffects ** //
  // Create datatable transaction objects
  useEffect(() => {
    if (!props.dispatchJobTransactions) return;

    const _dispatchJobTransactionsObjectArray = (props.dispatchJobTransactions).map((dispatchJobTransaction) => {
      const objectId = getAttribute(dispatchJobTransaction, 'objectId');
      const amount = getAttribute(dispatchJobTransaction, 'amount');
      const paymentMethod = getAttribute(dispatchJobTransaction, 'paymentMethod');
      const paymentMethodNumber = getAttribute(dispatchJobTransaction, 'paymentMethodNumber');
      const dateTime = getAttribute(dispatchJobTransaction, 'dateTime') && moment(getAttribute(dispatchJobTransaction, 'dateTime'), 'DD-MM-YYYY').format('DD-MM-YYYY');
      const notes = getAttribute(dispatchJobTransaction, 'notes');

      const dispatchJobTransactionObject = {
        objectId,
        amount,
        paymentMethod,
        paymentMethodNumber,
        dateTime,
        notes,
      };

      return dispatchJobTransactionObject;
    });

    setDispatchJobTransactionsObjectArray(_dispatchJobTransactionsObjectArray);
  }, [props.dispatchJobTransactions]);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  // Because Primereact's row edtior doesn't allow us access to any edit button elements,
  // we'll hide the edit/delete transaction row buttons when a transaction row is being edited. This
  // prevents any bugs from editing multiple transaction at the same time
  useEffect(() => {
    const rowEditButtonsELArray = document.querySelectorAll('.p-row-editor-init.p-link');
    const rowDeleteButtonsELArray = document.querySelectorAll('.delete-transaction-button');
    if (isTransactionRowEditActive) {
      rowEditButtonsELArray.forEach((element) => {
        element.classList.add('hide-element');
      });

      rowDeleteButtonsELArray.forEach((element) => {
        element.classList.add('hide-element');
      });
    } else {
      rowEditButtonsELArray.forEach((element) => {
        element.classList.remove('hide-element');
      });

      rowDeleteButtonsELArray.forEach((element) => {
        element.classList.remove('hide-element');
      });
    }

  }, [isTransactionRowEditActive]);

  // ** Helper Functions ** //
  // Find the payment method name relative to the given payment method int
  function getPaymentMethodName(paymentMethodInt) {
    let paymentMethodName = '-';

    const selectedPaymentMethod = paymentMethods.find((paymentMethod) => paymentMethod.type === paymentMethodInt);
    if (selectedPaymentMethod) {
      paymentMethodName = selectedPaymentMethod.description;
    }
    return paymentMethodName;
  }

  // Check if a inputted date time is valid
  function checkValidDateTime(value) {
    const isValidDateTime = moment(value, 'DD-MM-YYYY', true).isValid();
    const isDateTimeContainCharacters = /[a-zA-Z]/.test(value);
    const isDateTimeEmpty = !(/\d/.test(value));
    let isDateTimeSuccess;
    if (!isValidDateTime && !isDateTimeContainCharacters && !isDateTimeEmpty) {
      // If the date time field is invalid, is fully filled in, and isn't empty, trigger an error border
      isDateTimeSuccess = false;
    } else if (isValidDateTime) {
      // Trigger a success border
      isDateTimeSuccess = true;
    }

    return isDateTimeSuccess;
  }

  // ** Toolbar Templates ** //
  // Render an add transaction button within the toolbar and disable it if a transaction row is being edited
  const rightToolbarTemplate = (
    <>
      {isTransactionRowEditActive && (
        <Message className="add-transaction-message" severity="info" text="Finish all edits before adding a transaction" />
      )}
      {/* If props.onShowVoidConfirmationModal exists, show the voided button */}
      {props.onShowVoidConfirmationModal && (
        <ToggleButton
          className="toggle-voided-button sbc-toggle-button justify-content-end"
          onLabel="Voided"
          offLabel="Unvoided"
          checked={props.isVoided}
          onChange={(e) => props.onShowVoidConfirmationModal(e.value)}
          disabled={props.disableVoiding}
        />
      )}
      <Button
        className="add-transaction-button"
        label="Add Transaction"
        icon="pi pi-plus"
        onClick={async () => addTransactionRow()}
        disabled={isLoading || isTransactionRowEditActive}
      />
    </>
  );

  // ** Callback Functions ** //
  // Adds a new transaction record which may be edited and refreshes the parent state to reflect the change
  async function addTransactionRow() {
    if (!props.dispatchReceivable && !props.dispatchPayable) return;
    setIsLoading(true);
    setDispatchJobTransactionsObjectArray([]);

    const keyValueObj = {
      dispatchReceivable: props.dispatchReceivable,
      dispatchPayable: props.dispatchPayable,
      currency: props.currencyInt,
      amount: 0,
    };
    await addDispatchJobTransaction(keyValueObj);

    if (props.onLastActionChange) props.onLastActionChange('addtransaction'); // Initialize the newly added transaction's edit mode
    if (props.toggleRefresh) props.toggleRefresh();
  }

  // Delete the selected transaction
  async function deleteTransaction(dispatchJobTransactionObjectId) {
    if (!dispatchJobTransactionObjectId) return;
    setIsLoading(true);
    const dispatchJobTransactions = await getDispatchJobTransactions(
      undefined,
      [new Filter(QueryRestriction.EQUAL_TO, 'objectId', dispatchJobTransactionObjectId)],
    );

    let dispatchJobTransaction;
    if (dispatchJobTransactions && dispatchJobTransactions.length > 0) dispatchJobTransaction = dispatchJobTransactions[0];
    if (dispatchJobTransaction) {
      // Hide the transaction rather than destroy it
      // await destroyDispatchJobTransaction(dispatchJobTransaction);
      await updateDispatchJobTransaction(undefined, dispatchJobTransaction, undefined, { isHidden: true }, true);
    }

    if (props.toggleRefresh) props.toggleRefresh();
  }

  // ** Datatable Templates ** //
  // Templates which formats how the data is shown on the datatable
  function amountBodyTemplate(rowData) {
    return (
      <div className="font-bold">
        {new Intl.NumberFormat('en-US', { style: 'currency', currency: props.currencyInt === Currency.CA.index ? Currency.CA.short : Currency.US.short }).format(rowData.amount)}
      </div>
    );
  }

  function paymentMethodBodyTemplate(rowData) {
    const paymentMethodName = getPaymentMethodName(rowData.paymentMethod);
    return (<div>{paymentMethodName || '-'}</div>);
  }

  function paymentMethodNumberBodyTemplate(rowData) {
    return (<div>{rowData.paymentMethodNumber || '-'}</div>);
  }

  function dateTimeBodyTemplate(rowData) {
    return (<div className="font-bold">{rowData.dateTime || '-'}</div>);
  }

  function notesBodyTemplate(rowData) {
    return (
      <InputTextarea
        className="p-inputtext-sm p-0"
        style={{ border: 'none', color: 'black', height: 'auto' }}
        value={rowData.notes}
        autoResize
        disabled
      />
    );
  }

  function deleteButtonBodyTemplate(rowData) {
    return (<Button icon="pi pi-trash" className="p-button-rounded delete-transaction-button" onClick={async () => deleteTransaction(rowData.objectId)} />);
  }

  function emptyMessageDisplay() {
    return (<Message className="w-full" severity="info" text="No Transactions Available" />);
  }

  // ** Datatable Editors ** //
  // Editors which allows users to edit the selected transaction row and update it using the new values
  function amountEditor(options) {
    return (
      <InputNumber
        className="p-inputtext-sm"
        value={options.value}
        onValueChange={(e) => options.editorCallback(e.value)}
        mode="currency"
        currency={props.currencyInt === Currency.CA.index ? Currency.CA.short : Currency.US.short}
        locale="en-US"
        warning={!options.value}
        success={options.value}
        placeholder="$0.00"
      />
    );
  }

  function paymentMethodEditor(options) {
    return (
      <PaymentMethodDropdown
        className="p-inputtext-sm"
        hideLabel
        onSelect={(value) => options.editorCallback(value)}
        paymentMethodInt={options.value}
        success={options.value || options.value === 0}
        warning={options.value === undefined}
      />
    );
  }

  function paymentMethodNumberEditor(options) {
    return (
      <InputText
        className="p-inputtext-sm"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        warning={!options.value}
        success={options.value}
        placeholder="Card/Cheque #"
      />
    );
  }

  function dateTimeEditor(options) {
    const isDateTimeError = !checkValidDateTime(options.value) && !(/[a-zA-Z]/.test(options.value)) && /\d/.test(options.value);
    return (
      <>
        <InputText
          className={`p-inputtext-sm ${isDateTimeError && 'invalid-date'}`}
          placeholder="dd-mm-yyyy"
          value={options.value}
          onChange={(e) => options.editorCallback(e.target.value)}
          warning={!options.value}
          error={isDateTimeError}
          success={checkValidDateTime(options.value)}
        />
        {
          isDateTimeError && (
            <InputLabel error>Invalid date. Try dd-mm-yyy</InputLabel>
          )
        }
      </>
    );
  }

  function notesEditor(options) {
    return (
      <InputTextarea
        className="notes-editor p-inputtext-sm"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        rows={5}
        cols={15}
      />
    );
  }

  // ** Datatable Callback Functions ** //
  // Updates the DispatchJobTransaction record with the new changes and refreshes the parent's state to reflect the changes
  async function onRowEditComplete(newData) {
    setIsLoading(true);
    setIsTransactionRowEditActive(false);

    const dispatchJobTransactionObject = {
      amount: (newData.amount || newData.amount === 0) ? newData.amount : 0,
      paymentMethod: (newData.paymentMethod || newData.paymentMethod === 0) ? newData.paymentMethod : undefined,
      paymentMethodNumber: newData.paymentMethodNumber ? newData.paymentMethodNumber : undefined,
      dateTime: checkValidDateTime(newData.dateTime) ? moment(newData.dateTime, 'DD-MM-YYYY hh:mm').toDate() : undefined,
      notes: newData.notes ? newData.notes : undefined,
    };

    // Update the record with the new attributes
    const dispatchJobTransactions = await getDispatchJobTransactions(
      undefined,
      [new Filter(QueryRestriction.EQUAL_TO, 'objectId', newData.objectId)],
    );

    let dispatchJobTransaction;
    if (dispatchJobTransactions && dispatchJobTransactions.length > 0) dispatchJobTransaction = dispatchJobTransactions[0];
    if (dispatchJobTransaction) await updateDispatchJobTransaction(undefined, dispatchJobTransaction, undefined, dispatchJobTransactionObject, true);
    if (props.toggleRefresh) props.toggleRefresh();
  }

  // ** Components ** //
  const transactionTableToolbar = (
    <Toolbar right={rightToolbarTemplate} />
  );

  const transactionHistoryTable = (
    <DataTable
      value={dispatchJobTransactionsObjectArray}
      responsiveLayout="scroll"
      editMode="row"
      dataKey="id"
      onRowEditComplete={async (event) => onRowEditComplete(event.newData)}
      onRowEditInit={() => setIsTransactionRowEditActive(true)}
      onRowEditCancel={() => setIsTransactionRowEditActive(false)}
      emptyMessage={emptyMessageDisplay()}
      loading={isLoading}
    >
      <Column
        field="amount"
        header="Payment Amount"
        body={(rowData) => amountBodyTemplate(rowData)}
        style={{ width: '20%' }}
        editor={(options) => amountEditor(options)}
      />
      <Column
        field="paymentMethod"
        header="Payment Method"
        body={(rowData) => paymentMethodBodyTemplate(rowData)}
        style={{ width: '20%' }}
        editor={(options) => paymentMethodEditor(options)}
      />
      <Column
        field="paymentMethodNumber"
        header="Card/Cheque Number"
        body={(rowData) => paymentMethodNumberBodyTemplate(rowData)}
        style={{ width: '20%' }}
        editor={(options) => paymentMethodNumberEditor(options)}
      />
      <Column
        field="dateTime"
        header="Payment Date"
        body={(rowData) => dateTimeBodyTemplate(rowData)}
        style={{ width: '20%' }}
        editor={(options) => dateTimeEditor(options)}
      />
      <Column
        field="notes"
        header="Notes"
        body={(rowData) => notesBodyTemplate(rowData)}
        bodyStyle={{ padding: '1rem 0 1rem 1rem' }}
        style={{ width: '20%', minWidth: '4rem' }}
        editor={(options) => notesEditor(options)}
      />
      <Column
        rowEditor
        bodyStyle={{ textAlign: 'right' }}
        style={{ width: '8%', minWidth: '8rem', paddingRight: '0.5rem' }}
      />
      <Column
        body={(rowData) => deleteButtonBodyTemplate(rowData)}
        exportable={false}
        style={{ paddingLeft: '0' }}
      />
    </DataTable>
  );

  return (
    <div className="transaction-history-table">
      {transactionTableToolbar}
      {transactionHistoryTable}
    </div>
  );
}

export default TransactionHistoryTable;
