// libraries
import { useState, useEffect } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';

// api
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getSafetyRecurringReminders, updateSafetyRecurringReminder, addSafetyRecurringReminder, deleteSafetyRecurringReminder } from 'api/SafetyRecurringReminder/SafetyRecurringReminder';

// enums
import { QueryRestrictionTypes } from 'enums/Query';
import { StatusInt } from 'enums/SafetyRecurringReminder';

// sbobjects
import Filter from 'sbObjects/Filter';

// components
import AddEditSafetyRecurringRemindersModal from 'components/SafetyRecurringReminder/AddEditSafetyRecurringRemindersModal/AddEditSafetyRecurringRemindersModal';
import ResolveSafetyRecurringReminderModal from 'components/SafetyRecurringReminder/ResolveSafetyRecurringReminderModal/ResolveSafetyRecurringReminderModal';
import UpcomingSafetyRecurringRemindersTable from 'components/SafetyRecurringReminder/UpcomingSafetyRecurringRemindersTable/UpcomingSafetyRecurringRemindersTable';

function UpcomingSafetyRecurringRemindersView(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [safetyRecurringReminders, setSafetyRecurringReminders] = useState([]);
  const [selectedSafetyRecurringReminders, setSelectedSafetyRecurringReminders] = useState([]);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showResolveReminderModal, setShowResolveReminderModal] = useState(false);
  const [selectedSafetyRecurringReminderToResolve, setSelectedSafetyRecurringReminderToResolve] = useState();

  useEffect(function() {
    (async function() {
      await refreshState();
    }());
  }, []);

  async function refreshState() {
    setIsLoading(true);

    const safetyRecurringReminderObj = await getSafetyRecurringReminders(
      undefined,
      undefined,
      undefined,
      [new Filter('statusInt', StatusInt.UPCOMING, QueryRestrictionTypes.EQUAL_TO)],
      true,
      true, 
      true
    );

    const safetyRecurringReminderRecords = safetyRecurringReminderObj.objArr;
    const safetyRecurringReminders = safetyRecurringReminderRecords.map(safetyRecurringReminderRecord => {
      return {
        safetyRecurringReminderRecord,
        id: getAttribute(safetyRecurringReminderRecord, 'objectId'),
        name: getAttribute(safetyRecurringReminderRecord, 'name'),
        notes: getAttribute(safetyRecurringReminderRecord, 'notes'),
        reminderType: getAttribute(safetyRecurringReminderRecord, 'reminderType'),
        frequencyType: getAttribute(safetyRecurringReminderRecord, 'frequencyType'),
        triggerDate: getAttribute(safetyRecurringReminderRecord, 'triggerDate'),
      };
    });

    setSafetyRecurringReminders(safetyRecurringReminders);
    setIsLoading(false);
  }

  async function handleCloneSafetyRecurringReminders() {
    setIsLoading(true);

    let i = 0;
    while (i < selectedSafetyRecurringReminders.length) {
      const safetyRecurringReminderRecord = selectedSafetyRecurringReminders[i].safetyRecurringReminderRecord;
      const safetyRecurringReminderClone = {};
      Object.assign(safetyRecurringReminderClone, safetyRecurringReminderRecord.attributes);
      await addSafetyRecurringReminder(safetyRecurringReminderClone);
      i++;
    }

    setSelectedSafetyRecurringReminders([]);
    await refreshState();
  }

  async function handleAddSafetyRecurringReminders(_formValues) {
    setIsLoading(true);
    await addSafetyRecurringReminder(_formValues);
    await refreshState();
  }

  async function handleEditSafetyRecurringReminders(_formValues) {
    setIsLoading(true);

    let i = 0;
    while (i < selectedSafetyRecurringReminders.length) {
      const safetyRecurringReminderRecord = selectedSafetyRecurringReminders[i].safetyRecurringReminderRecord;
      await updateSafetyRecurringReminder(safetyRecurringReminderRecord, _formValues);
      i++;
    }

    setSelectedSafetyRecurringReminders([]);
    await refreshState();
  }

  async function handleDeleteSafetyRecurringReminders() {
    setIsLoading(true);

    let i = 0;
    while (i < selectedSafetyRecurringReminders.length) {
      const safetyRecurringReminderRecord = selectedSafetyRecurringReminders[i].safetyRecurringReminderRecord;
      await updateSafetyRecurringReminder(safetyRecurringReminderRecord, { isHidden: true });
      i++;
    }

    setSelectedSafetyRecurringReminders([]);
    await refreshState();
  }

  async function handleResolveSafetyRecurringReminder(_formValues) {
    setIsLoading(true);
    await updateSafetyRecurringReminder(selectedSafetyRecurringReminderToResolve, _formValues);
    await refreshState();
  }

  return (
    <>
      <AddEditSafetyRecurringRemindersModal
        isEdit={isEdit}
        showModal={showAddEditModal}
        handleClose={() => setShowAddEditModal(false)}
        handleAdd={handleAddSafetyRecurringReminders}
        handleEdit={handleEditSafetyRecurringReminders}
      />

      <ConfirmDialog
        visible={showConfirmModal}
        style={{ width: '50vw '}}
        header={selectedSafetyRecurringReminders.length >= 2 ? 'Delete Reminders' : 'Delete Reminder'}
        message={selectedSafetyRecurringReminders.length >= 2 ? 'Are you sure you want to delete the reminders?' : 'Are you sure you want to delete the reminder?'}
        acceptLabel="Confirm"
        rejectLabel="Cancel"
        accept={async () => await handleDeleteSafetyRecurringReminders()}
        reject={() => setShowConfirmModal(false)}
        onHide={() => setShowConfirmModal(false)}
      />

      <ResolveSafetyRecurringReminderModal
        safetyRecurringReminderRecord={selectedSafetyRecurringReminderToResolve}
        showModal={showResolveReminderModal}
        handleResolve={handleResolveSafetyRecurringReminder}
        handleClose={() => setShowResolveReminderModal(false)}
      />

      <UpcomingSafetyRecurringRemindersTable
        safetyRecurringReminders={safetyRecurringReminders}
        loading={isLoading}
        selection={selectedSafetyRecurringReminders}
        onSelectionChange={(safetyRecurringReminders) => setSelectedSafetyRecurringReminders(safetyRecurringReminders)}
        handleAdd={() => {
          setIsEdit(false);
          setShowAddEditModal(true);
        }}
        handleEdit={() => {
          setIsEdit(true);
          setShowAddEditModal(true);
        }}
        handleClone={async () => await handleCloneSafetyRecurringReminders()}
        handleDelete={() => setShowConfirmModal(true)}
        handleResolve={(safetyRecurringReminder) => {
          setSelectedSafetyRecurringReminderToResolve(safetyRecurringReminder);
          setShowResolveReminderModal(true);
        }}
      />
    </>
  );
}

export default UpcomingSafetyRecurringRemindersView;