import React from 'react';
import PropTypes from 'prop-types'
import moment from 'moment-timezone';
import history from 'sbHistory'
import Parse from 'parse';

import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

import { MDBBtn, MDBIcon, MDBRow, MDBCol } from 'mdbreact'
import CircularProgress from 'material-ui/CircularProgress';

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import Filter from 'sbObjects/Filter';
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL';
import { getAttribute, callCloudFunction } from 'api/Parse';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import SubNavigationBar from 'components/Shared/SubNavigationBar/SubNavigationBar';
import HOSRegulationIcon from 'components/HOSRegulationIcon/view/HOSRegulationIcon';
import Title from 'components/LayoutTitle/view/Title';
import SBSelect from 'components/Shared/SBSelect/SBSelect';

import styles from './HOSViolationsLayout.module.scss';
import HOSViolationsRow from './HOSViolationsRow';

const initialFilterFormFields = (tempDate) => {
  return [
    {
      attrName: 'month',
      fullName: 'Month',
      type: 'date',
      dateQueryType: 'dateMonth',
      includeLeftRight: true,
      value: tempDate ? moment(tempDate, 'DDMMYY') : moment(),
    },
  ];
}

class HOSViolationsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      driverArr: [],
      startDate: moment().subtract(14, 'days'),
      endDate: moment(),
      violationFilter: {
        key: 1,
        value: 'hideNoViolations',
        label: 'Hide Drivers Without Violations',
      },
      violationFilterItems: [
        {
          key: 0,
          value: 'showAll',
          label: 'Show All Drivers',
        },
        {
          key: 1,
          value: 'hideNoViolations',
          label: 'Hide Drivers Without Violations',
        },
      ],
      generatingReport: false,
      isLoading: false,
    };

    const tempDate = getQueryParameter(props.location.search, 'date')
    this.state.filterFormFields = initialFilterFormFields(tempDate);
    this.state.month = tempDate ? moment(tempDate, 'DDMMYY') : moment(),

      this.tableHeight = document.documentElement.clientHeight * 0.6; // 60vh defined by CSS
    if (document.documentElement.clientHeight <= 1366) { // laptop size
      this.tableHeight = document.documentElement.clientHeight * 0.6;
    }
    this.rowNum = Math.round(this.tableHeight / 36) + 5; // amount of el's to display
    this.pageCount = 0; // result fetch multiplier

    this.getDrivers = this.getDrivers.bind(this);
    this.updateNote = this.updateNote.bind(this);
    this.hideUnhideHosViolation = this.hideUnhideHosViolation.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.generateReport = this.generateReport.bind(this);
  }

  async componentDidMount() {
    document.title = 'HOS Violations - Switchboard';
    await this.getDrivers();
  }

  componentWillReceiveProps(nextProps) {
    this.processDate(nextProps);
    // const dQuery = (this.props.location && getQueryParameter(this.props.location.search, 'd'));
    // if (dQuery === 'currentWeek') {
    //   this.setState(
    //     { ...this.state,
    //       month: null,
    //       startDate: moment().subtract(14, 'days'),
    //       endDate: moment(),
    //     },
    //   );
    // } else if (dQuery === 'month') {
    //   let newDate = getQueryParameter(nextProps.location.search, 'date');
    //   let oldDate = getQueryParameter(this.props.location.search, 'date');
    //   if (newDate !== oldDate) {
    //     this.setState(
    //       { ...this.state,
    //         month: moment(newDate, 'DDMMYY'),
    //         startDate: moment(newDate, 'DDMMYY').startOf('month'),
    //         endDate: moment(newDate, 'DDMMYY').endOf('month')
    //       },
    //     );
    //   }
    // }
  }

  async processDate(nextProps) {
    const props = nextProps || this.props;
    const dQuery = (props.location && getQueryParameter(props.location.search, 'd'));
    if (dQuery === 'lastTwoWeeks') {
      await this.setState(
        { ...this.state,
          startDate: moment().subtract(14, 'days'),
          endDate: moment(),
        },
      );
    } else if (dQuery === '24hours') {
      await this.setState(
        { ...this.state,
          startDate: moment().subtract(24, 'hours'),
          endDate: moment(),
        },
      );
    } else if (dQuery === 'month') {
      const dateString = getQueryParameter(props.location.search, 'date');
      await this.setState(
        { ...this.state,
          startDate: dateString ? moment(dateString, 'DDMMYY').startOf('month') : moment().startOf('month'),
          endDate: dateString ? moment(dateString, 'DDMMYY').endOf('month') : moment().endOf('month'),
        },
      );
    }
  }

  async getDrivers() {
    this.setState({ ...this.state, isLoading: true });
    const driverArr = await Getters.getDrivers({ enabled: true });
    this.setState({ ...this.state, isLoading: false, driverArr });
  }

  async handleFilter(filters = []) {
    let month = moment();
    let startDate;
    let endDate;
    if (filters.length === 0) {
      this.setState({ ...this.state, filterFormFields: initialFilterFormFields(), month });
    } else {
      const filterFormFields = [].concat(...this.state.filterFormFields);
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        for (let j = 0; j < filterFormFields.length; j++) {
          if (filterFormFields[j].attrName === filter.attribute) {
            filterFormFields[j].value = filter.value;
          }
        }
        if (filter.attribute === 'month') {
          month = filter.value;
          startDate = moment(month).startOf('month');
          endDate = moment(month).endOf('month');
        }
      }
      this.setState({ ...this.state, filterFormFields, startDate, endDate }, () => {
        const queryStrObj = getDeserialized(this.props.location.search).params;
        history.push({
          pathname: 'safety',
          search: getSerialized({ ...queryStrObj, date: moment(month).format('DDMMYY') }).query,
        });
      });
    }
  }

  hideUnhideHosViolation(hosViolation, hideBool) {
    const overrideHideForever = getQueryParameter(null, 'overrideHideForever');
    if (overrideHideForever == 'true') {
      hosViolation.set('hideForever', hideBool);
    }
    hosViolation.set('isHidden', hideBool);
    this.setState({ ...this.state, isLoading: true }, async () => {
      await hosViolation.save();
      this.setState({ ...this.state, isLoading: false });
    });
  }

  updateNote(e, hosViolation) {
    hosViolation.set('note', e.target.value);
    this.setState(this.state);
    hosViolation.save();
  }

  async generateReport() {
    // const date = getAttribute(this.props.eldDailyCertificationArr[0], 'startTimeUTC');
    const date = this.state.month.toDate();
    const driverIds = this.state.driverArr.map(driver => {
      return getAttribute(driver, 'objectId');
    });
    const hideHidden = true;
    const isPotential = true;
    try {
      this.setState({ ...this.state, generatingReport: true });
      const parseFileObj = await callCloudFunction('generateAllDriversHOSViolationMonthlyReport', {
        date,
        driverIds,
        hideHidden,
        isPotential
      });
      this.setState({ ...this.state, generatingReport: false });
      window.open(getAttribute(parseFileObj, 'file').url(), '_blank');
    } catch (e) {
      this.setState({ ...this.state, generatingReport: false });
      console.log(e);
    }
  }

  scrollHandler(e) {
    // if (Helpers.scrolledToBottom(e, 10, this.lastScrollPos)) {
    //   this.pageCount++;
    //   this.getHOSViolations();
    //   this.lastScrollPos = e.target.scrollTop;
    // }
  }

  render() {
    const { props, state } = this;

    const tempView = this.props.location ? getQueryParameter(this.props.location.search, 'd') : undefined;
    const queryStrObj = getDeserialized(this.props.location.search).params;
    const navItems = [
      {
        name: 'Last Two Weeks',
        isActive: !tempView || tempView === 'lastTwoWeeks',
        to: getSerialized({ ...queryStrObj, d: 'lastTwoWeeks' }).query,
      },
      {
        name: 'By Month',
        isActive: tempView === 'month',
        to: getSerialized({ ...queryStrObj, d: 'month' }).query,
      },
      {
        name: 'Last 24 Hours',
        isActive: tempView === '24hours',
        to: getSerialized({ ...queryStrObj, d: '24hours' }).query,
      },
    ];

    return (
      <div className={styles.hosViolationsLayout} onScroll={(e) => this.scrollHandler(e)}>
        <SubNavigationBar navItems={navItems} />

        <MDBRow>
          <MDBCol sm="8">
            { tempView === 'month' &&
              <FilterForm
                className="d-inline-block"
                handleFilter={this.handleFilter}
                clearFilter={() => this.handleFilter()}
                fields={state.filterFormFields}
                submitOnSelect
                disabled={state.isLoading}
              />
            }
          </MDBCol>
          <MDBCol>
            <MDBBtn
              size="sm"
              color="primary"
              className="align-middle"
              onClick={this.generateReport}
              disabled={this.state.generatingReport}
            >
              <MDBIcon icon="print"></MDBIcon> Violations
            </MDBBtn>
            <br />
            <SBSelect
              containerClassName="ml-2 mr-2 d-inline-block"
              className="sb-jobs-list-select"
              defaultToggleText={this.state.violationFilter.label}
              items={this.state.violationFilterItems}
              getSelectedItems={(selectedFilter) => {
                this.setState({ ...this.state, violationFilter: selectedFilter[0] });
                // this.setState({ ...this.state, violationFilter: selectedFilter[0] }, () => this.getHOSViolations(true));
              }}
              showFilter
            />
          </MDBCol>
        </MDBRow>

        <Table
          selectable={false}
          height="60vh"
        >
          <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
          >
            <TableRow>
              <TableHeaderColumn style={{ textAlign: 'center' }}>Driver</TableHeaderColumn>
              <TableHeaderColumn style={{ textAlign: 'center' }}>Violation (Mouse over icon for more info)</TableHeaderColumn>
              <TableHeaderColumn />
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={false}
            showRowHover
          >
            {!this.state.isLoading && this.state.driverArr.length === 0 &&
              <TableRow colSpan="69">
                <TableRowColumn>
                  No Drivers Found
                </TableRowColumn>
              </TableRow>
            }
            {this.state.isLoading &&
              <TableRow colSpan="69">
                <TableRowColumn className="centerText">
                  <CircularProgress />
                </TableRowColumn>
              </TableRow>
            }
            {!this.state.isLoading &&
              this.state.driverArr.map(driver => (
                <HOSViolationsRow
                  key={driver.id}
                  driver={driver}
                  violationFilter={this.state.violationFilter.value}
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
                // <TableRow key={hosViolation.id}>
                //   <TableRowColumn>
                //     { Helpers.toTitleCase(hosViolation.get('driver').get('user_fullName')) }
                //   </TableRowColumn>
                //   <TableRowColumn style={{ textAlign: 'center' }}>
                //     {moment(hosViolation.get('triggerTime')).format('LL')}
                //   </TableRowColumn>
                //   <TableRowColumn style={{ textAlign: 'center' }}>
                //     <div className={styles.violationIcon}>
                //       <HOSRegulationIcon key={hosViolation.id} HOSRegulationInt={hosViolation.get('regulationInt')} />
                //     </div>
                //   </TableRowColumn>
                //   <TableRowColumn style={{ textAlign: 'center' }}>
                //     {hosViolation.get('driver') &&
                //       <MDBBtn
                //         size="sm"
                //         color="primary"
                //         disabled={this.state.printInProgress}
                //         onClick={() => history.push({ pathname: 'driver', search: 'driver=' + hosViolation.get('driver').id + '&view=hosViolations&date=' + moment(Helpers.referenceToTimezone(hosViolation.get('triggerTime'), hosViolation.get('driver').get('timezoneOffsetFromUTC'))).format('DDMMYY') })}
                //       >
                //         Details
                //       </MDBBtn>
                //     }
                //     <br />
                //     { hosViolation.get('isHidden') &&
                //       <MDBBtn
                //         size="sm"
                //         color="secondary"
                //         onClick={() => this.hideUnhideHosViolation(hosViolation, false)}
                //       >
                //         Unhide
                //       </MDBBtn>
                //     }
                //     { !hosViolation.get('isHidden') &&
                //       <MDBBtn
                //         size="sm"
                //         color="info"
                //         onClick={() => this.hideUnhideHosViolation(hosViolation, true)}
                //       >
                //         Hide
                //       </MDBBtn>
                //     }
                //   </TableRowColumn>
                //   <TableRowColumn>
                //     {/* <label>
                //       Notes:
                //     </label>
                //     <textarea
                //       className="form-control"
                //       id="exampleFormControlTextarea1"
                //       rows="5"
                //     /> */}
                //     <MDBInput type="textarea" label="Notes" value={hosViolation.get('note')} size="sm" onChange={(e) => this.updateNote(e, hosViolation)} rows="1" />
                //   </TableRowColumn>
                // </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </div >
    );
  }
}


HOSViolationsLayout.propTypes = {
  location: PropTypes.object.isRequired,
}

export default HOSViolationsLayout;
