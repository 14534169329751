import React from 'react';
import PropTypes from 'prop-types';

// API
import { getTrailerSuggestions } from 'api/Equipment/Trailer.old';
import { getAttribute } from 'api/Parse';

// Components
import SBSelect from 'components/Shared/SBSelect/SBSelect';

// CSS
import './styles.scss';

class TrailerAutocomplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      asyncTrailers: []
    };
    this.loadTrailers = this.loadTrailers.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleTrailerSelection = this.handleTrailerSelection.bind(this);
  }

  loadTrailers(term) {
    getTrailerSuggestions(term).then((trailers) => {
      let tempTrailers = trailers.map((trailer, index) => {
        return { key: index, value: trailer, label: getAttribute(trailer, 'unitId') };
      });
      if (this.props.allowDeselect) tempTrailers.unshift({ key: 'none', value: undefined, label: 'Clear', isSpecialItem: true, removeableItem: true });
      this.setState({ ...this.state, asyncTrailers: tempTrailers });
    })
  }

  handleTrailerSelection(selection) {
    this.props.onTrailerSelected(selection[0].value);
  }

  handleFilterChange(text) {
    if (this.props.onChange)
      this.props.onChange(text);
  }

  render() {
    return (
      <SBSelect
        containerClassName="flex-grow-1"
        onFilterChange={this.handleFilterChange}
        items={this.state.asyncTrailers}
        getSelectedItems={this.handleTrailerSelection}
        isAutocomplete
        isRequired={this.props.isRequired}
        autocompleteLabel="Search Trailer by Unit ID"
        onAutocompleteFocus={this.loadTrailers}
        onValueChange={this.loadTrailers}
        placeholder={this.props.placeholder}
      />
    )
  }
}

TrailerAutocomplete.propTypes = {
  onTrailerSelected: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  allowDeselect: PropTypes.bool,
  placeholder: PropTypes.string,
}

export default TrailerAutocomplete;
