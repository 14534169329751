import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { MDBBtn, MDBModal, MDBModalBody, MDBIcon } from 'mdbreact';

// API
import * as PDF from 'api/PDFer';
import * as IFTA from 'api/IFTA';
import * as Helpers from 'api/Helpers';
import { StateProvinces } from 'api/Lists/StateProvinces';
import { getTimezoneString, getFuelCardData, removeDuplicateIFTAFuelCardData } from 'api/IFTARoute/IFTARoute.old';
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { getAttribute, updateRecord, addRecord } from 'api/Parse';
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL';
import { getLocationDescriptionBreakdown } from 'api/VehicleLocation/VehicleLocation';

// Components
import FileUpload from 'sbCore/FileUpload/FileUpload';

import Title from 'components/Shared/Title/Title';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import SubNavigationBar from 'components/Shared/SubNavigationBar/SubNavigationBar';

import DayLogs from 'components/IFTARoute/container/DayLogs';
import AddRoute from 'components/IFTARoute/container/AddRoute';
import MapPopup from 'components/IFTARoute/container/MapPopup';
import RepairRoute from 'components/IFTARoute/container/RepairRoute';
import FileUploader from 'components/FileUploader/container/FileUploader';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import VehicleSummary from 'components/IFTARoute/container/VehicleSummary';
import ListSelectField from 'components/ListSelectField/view/ListSelectField';
import VehicleSummaryBeta from 'components/IFTARoute/container/VehicleSummaryBeta';
import VehicleRouteBreakdown from 'components/IFTARoute/container/VehicleRouteBreakdown';
import VehicleMileagesIntervalSummary from 'components/IFTA.old/container/VehicleMileagesIntervalSummary';
import MigrateRoutes from 'components/IFTARoute/container/MigrateRoutes';
// import IFTAFuelCardDialog from 'components/IFTAFuelCardDialog/IFTAFuelCardDialog';
import FuelCardCSVUploader from 'components/IFTA/FuelCardCSVUploader/FuelCardCSVUploader';

function getYears() {
  const years = [];
  for (let i = 2017; i <= moment().year(); i++) {
    years.unshift(i);
  }
  return years;
}

class VehicleRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      printInProgress: false,
      downloadCsvInProgress: false,
      vehicleSummaryFilter: {
        key: 1,
        value: 'hideZeroMileages',
        label: 'Hide Empty States/Provinces',
      },
      vehicleBreakdownFilter: {
        key: 1,
        value: 'dayView',
        label: 'View By Day',
      },
      distanceUnitFilter: getPDispatcherPropertyFromState('distanceUnit') === 'mi' ?
        {
          key: 0,
          value: 'mi',
          label: 'mi',
        }
        :
        {
          key: 1,
          value: 'km',
          label: 'km',
        },
      distanceUnitFilterItems: [
        {
          key: 0,
          value: 'km',
          label: 'km',
        },
        {
          key: 1,
          value: 'mi',
          label: 'mi',
        },
      ],
      volumeUnitFilter: getPDispatcherPropertyFromState('volumeUnit') === 'l' ?
        {
          key: 0,
          value: 'l',
          label: 'l',
        }
        :
        {
          key: 1,
          value: 'gal',
          label: 'gal',
        },
      volumeUnitFilterItems: [
        {
          key: 0,
          value: 'l',
          label: 'l',
        },
        {
          key: 1,
          value: 'gal',
          label: 'gal',
        },
      ],
      showOdometerValuesFilter: {
        key: 1,
        value: 'hideOdometerValues',
        label: 'Hide Odometer Values',
      },
      showOdometerValuesFilterItems: [
        {
          key: 0,
          value: 'showOdometerValues',
          label: 'Show Odometer Values',
        },
        {
          key: 1,
          value: 'hideOdometerValues',
          label: 'Hide Odometer Values',
        },
      ],
      selectableVehicleSummaryFilterItems: [
        {
          key: 0,
          value: 'showAllStateProvinces',
          label: 'Show All States/Provinces',
        },
        {
          key: 1,
          value: 'hideZeroMileages',
          label: 'Hide Empty States/Provinces',
        },
      ],
      selectableVehicleBreakdownFilterItems: [
        {
          key: 0,
          value: 'showAllDetails',
          label: 'Show All Details',
        },
        {
          key: 1,
          value: 'hideZeroMileages',
          label: 'Hide Empty States/Provinces',
        },
        {
          key: 2,
          value: 'combineStateProvinces',
          label: 'Combine State/Provinces',
        },
        {
          key: 3,
          value: 'dayView',
          label: 'View By Day',
        },
      ],
      vehicleMileagesIntervalSummary: {
        open: false,
        dateInterval: {
          start: props.dateStart,
          end: props.dateEnd,
        },
      },
      vehicleRouteBreakdownData: null,
      odometerDiff: null,
      showDataButtons: false,
      map: {
        showPopup: false,
        selectedIftaRouteArr: [],
        data: {
          vehicleUnitId: null,
          dateStart: props.dateStart,
          dateEnd: props.dateEnd,
        },
      },
      driverTimeZoneStr: undefined,
      confirmUploadFuelCardDialog: {
        show: false,
        toggleCounter: 0,
      },
      fuelCardValidationDialog: {
        errors: [],
        warnings: [],
        recommendations: [],
        show: false,
      },
      resetGallonsDialog: {
        show: false,
      },
      fuelUnits: {
        gal: 'gallons',
        l: 'litres',
      },
      iftaVehicles: [],
      showUploadIFTAButton: true,
      disableIFTAButtonGroup: false,
      fuelCardTypes: {
        'Select Fuel Card': undefined,
        'BVD Petroleum': 'bvd',
        'ESSO (CND)': 'esso',
        'Flying J / EFS': 'tchusd',
        'Husky Comdata': 'huskycomdata',
        'Nationwide Fuel': 'nationwide',
        'TCH (CND)': 'tchcnd',
        'TCH (USD)': 'tchusd',
        'TCH 2 (USD)': 'tch2',
        'FCL (Co-op)': 'fcl',
      },
      fuelCardType: 'Select Fuel Card',
      snackbar: {
        activate: false,
        message: '',
      },
      iftaFuelCardData: undefined,
      showIFTAFuelCardDialog: false,
    };

    this.toggleMap = this.toggleMap.bind(this);
    this.printReport = this.printReport.bind(this);
    this.downloadCsv = this.downloadCsv.bind(this);
    this.fuelCardUploadHandler = this.fuelCardUploadHandler.bind(this);
    this.getFuelCardTypeShortCode = this.getFuelCardTypeShortCode.bind(this);
    this.toggleUploadFuelCardDialog = this.toggleUploadFuelCardDialog.bind(this);
    this.getIFTADataImproved = this.getIFTADataImproved.bind(this);
  }

  componentDidMount() {
    const driverTimeZoneStr = this.props.iftaRouteArr && getTimezoneString(this.props.iftaRouteArr[0]);
    this.setState({ ...this.state, rendering: false, driverTimeZoneStr });
  }

  printReport = (type) => {
    this.setState({ ...this.state, printInProgress: true });

    const { props } = this;
    let reportHTML;
    let reportName;

    if (type === 'vehicleSummary') {
      reportHTML = document.querySelector('.vehicleSummary-printable');
      reportName = `${props.unitId} - Vehicle Mileage Summary`;
    } else if (type === 'vehicleRouteBreakdown') {
      reportHTML = document.querySelector('.vehicleRouteBreakdown-printable');
      reportName = `${props.unitId} - Vehicle Route Breakdown`;
    }

    // remove all warning messages from all children (includes child components) belonging to printable
    const nonPrintable = [];

    if (reportHTML) nonPrintable.push(...reportHTML.querySelectorAll('.print-hide'));
    if (reportHTML) nonPrintable.push(...reportHTML.querySelectorAll('.sb-block'));
    for (let i = 0; i < nonPrintable.length; i++) {
      nonPrintable[i].style.display = 'none';
    }

    // hide any table cells that should not be in the printed report
    let nonPrintableTableCells = [];
    if (reportHTML) nonPrintableTableCells = document.querySelectorAll('.print-hide-cell');

    for (let i = 0; i < nonPrintableTableCells.length; i++) {
      nonPrintableTableCells[i].style.display = 'none';
    }

    // Grab the cell of the expandable content, and set the colspan accordingly
    let expandedContentCells = [];
    if (reportHTML) expandedContentCells = document.querySelectorAll('.sb-table-expandable-cell');

    for (let i = 0; i < expandedContentCells.length; i++) {
      // We're subtracting 2 here - one for the expandable cell, and one for the warning cell for each main row
      expandedContentCells[i].colSpan -= 2;
    }

    PDF.generateTempPDFFromHTML(reportHTML, reportName, { scale: 0.4 }).then(
      tempFile => {
        const reportURL = tempFile.get('file')._url;
        Helpers.openDocumentLink(reportURL);
        for (let i = 0; i < nonPrintable.length; i++) {
          nonPrintable[i].style.display = 'block';
        }

        // Bring back the hidden cells
        for (let i = 0; i < nonPrintableTableCells.length; i++) {
          nonPrintableTableCells[i].style.display = 'table-cell';
        }

        // Undo the changes to the colSpan
        for (let i = 0; i < expandedContentCells.length; i++) {
          expandedContentCells[i].colSpan += 2;
        }

        this.setState({ ...this.state, printInProgress: false });
      }
    );
  }

  downloadCsv = async (type) => {
    this.setState({ ...this.state, downloadCsvInProgress: true });
    const { state, props } = this;
    const routeToNewIFTA = props.routeToNewIFTA;

    if (type === 'vehicleSummary') {
      let csvString = `State/Province,Total Calculated Mileage (${state.distanceUnitFilter.value})\n`;
      const stateProvinceCodes = StateProvinces.map((stateProvince) => stateProvince.code).filter((stateProvinceCode) => stateProvinceCode);
      const iftaArrMiles = {};

      for (let i = 0; i < this.props.iftaRouteArr.length; i++) {
        const iftaRoute = this.props.iftaRouteArr[i];
        const stateProvince = iftaRoute.get('stateProvince').toUpperCase();
        const savedVehicleKm = (iftaRoute.get('savedVehicleKm') > 0 ? iftaRoute.get('savedVehicleKm') : 0);
        const totalVehicleKmDiff = (iftaRoute.get('totalVehicleKmDiff') > 0 ? iftaRoute.get('totalVehicleKmDiff') : 0);
        const distanceKm = (iftaRoute.get('distanceKm') > 0 ? iftaRoute.get('distanceKm') : 0);

        if (iftaArrMiles[stateProvince]) {
          iftaArrMiles[stateProvince].odometerKm += totalVehicleKmDiff;
          iftaArrMiles[stateProvince].gpsKm += distanceKm;
          iftaArrMiles[stateProvince].savedKm += savedVehicleKm;
        } else {
          iftaArrMiles[stateProvince] = {};
          iftaArrMiles[stateProvince].odometerKm = totalVehicleKmDiff || 0;
          iftaArrMiles[stateProvince].gpsKm = distanceKm || 0;
          iftaArrMiles[stateProvince].savedKm = savedVehicleKm || 0;
        }
      }

      for (let i = 0; i < stateProvinceCodes.length; i++) {
        const stateProvince = stateProvinceCodes[i];

        if (iftaArrMiles[stateProvince]) {
          csvString += `"${stateProvince}",`;
          csvString += `"${(this.state.distanceUnitFilter.value === 'km' ? (Math.round(iftaArrMiles[stateProvince].savedKm * 100) / 100).toFixed(0) : Helpers.convertDistance((Math.round(iftaArrMiles[stateProvince].savedKm * 100) / 100), 'km', 'mi', true).toFixed(0))}"\n`;
        }
      }

      Helpers.createCsvFile(`${this.props.unitId} Switchboard - IFTA Mileage Summary (${this.props.dateStart && moment(this.props.dateStart).format('MMMM YYYY')} to ${this.props.dateEnd && moment(this.props.dateEnd).format('MMMM YYYY')})`, csvString, true);
      await this.setState({ ...this.state, downloadCsvInProgress: false });
    }

    if (type === 'vehicleRouteBreakdown') {
      const timezone = props.iftaRouteArr && getTimezoneString(props.iftaRouteArr[0]);

      if (!routeToNewIFTA && state.vehicleBreakdownFilter.value === 'dayView') {
        // This CSV will be for old IFTA's view by day
        let csvString = `Date Start,Date End,Odometer Start,Odometer End,Difference (${this.state.distanceUnitFilter.value}), Jumped Mileage (${this.state.distanceUnitFilter.value})\n`;

        for (let i = 0; i < this.state.vehicleRouteBreakdownData.length; i++) {
          const iftaRoute = this.state.vehicleRouteBreakdownData[i];
          const dateStartFormatted = moment(iftaRoute.dateStart.iso).tz(timezone).format('YYYY-MM-DD');
          const dateEndFormatted = moment(iftaRoute.dateEnd.iso).tz(timezone).format('YYYY-MM-DD');
          const totalVehicleKmStart = iftaRoute.totalVehicleKmStart;
          const totalVehicleKmEnd = iftaRoute.totalVehicleKmEnd;
          const iftaRouteDriverPeriods = iftaRoute.iftaRouteDriverPeriods;
          const vehicle = Math.round(iftaRoute.vehicleUnitId);
          const savedVehicleKm = Math.round(iftaRoute.savedVehicleKm);
          const spreadKm = Math.round(iftaRoute.spreadKm);
          const totalVehicleKmDiff = totalVehicleKmEnd - totalVehicleKmStart;
          const jumpGapKm = iftaRoute.jumpGapKm && Math.round(iftaRoute.jumpGapKm);
          csvString += `${dateStartFormatted},`;
          csvString += `${dateEndFormatted},`;
          csvString += `${totalVehicleKmStart ? (this.state.distanceUnitFilter.value === 'km' ? totalVehicleKmStart.toFixed(0) : Helpers.convertDistance(totalVehicleKmStart, 'km', 'mi').toFixed(0)) : 0},`;
          csvString += `${totalVehicleKmEnd ? (this.state.distanceUnitFilter.value === 'km' ? totalVehicleKmEnd.toFixed(0) : Helpers.convertDistance(totalVehicleKmEnd, 'km', 'mi').toFixed(0)) : 0},`;
          csvString += `${totalVehicleKmDiff ? (this.state.distanceUnitFilter.value === 'km' ? totalVehicleKmDiff.toFixed(0) : Helpers.convertDistance(totalVehicleKmDiff, 'km', 'mi').toFixed(0)) : 0},`;
          csvString += `${jumpGapKm ? (this.state.distanceUnitFilter.value === 'km' ? jumpGapKm.toFixed(0) : Helpers.convertDistance(jumpGapKm, 'km', 'mi').toFixed(0)) : 0}\n`;
        }
        Helpers.createCsvFile(`${this.props.unitId} Switchboard - IFTA Breakdown (${this.props.dateStart && moment(this.props.dateStart).format('MMMM YYYY')} to ${this.props.dateEnd && moment(this.props.dateEnd).format('MMMM YYYY')})`, csvString, true);
      } else if (!routeToNewIFTA && (state.vehicleBreakdownFilter.value === 'showAllDetails' || state.vehicleBreakdownFilter.value === 'hideZeroMileages' || state.vehicleBreakdownFilter.value === 'combineStateProvinces')) {
        // Responsible for old IFTA Chronological View: Show All Details, Hide Empty State/Provinces, Combine State/Provinces
        let csvString = `Start Date,Start Location,End Date,End Location,State/Province,Odometer Travel (${this.state.distanceUnitFilter.value}),Calculated Mileage (${this.state.distanceUnitFilter.value}),Fuel (Gallons)\n`;
        for (let i = 0; i < this.state.vehicleRouteBreakdownData.length; i++) {
          const iftaRoute = this.state.vehicleRouteBreakdownData[i];
          let startVehicleLocationStateProvince = iftaRoute.vehicleLocationStart.stateProvince;
          let endVehicleLocationStateProvince = iftaRoute.vehicleLocationEnd.stateProvince;
          let startVehicleLocationAprxShortName = iftaRoute.vehicleLocationStart.aprxShortName;
          let endVehicleLocationAprxShortName = iftaRoute.vehicleLocationEnd.aprxShortName;

          const startVehicleLocationString = iftaRoute.vehicleLocationStart.locationDescriptionUS;
          const endVehicleLocationString = iftaRoute.vehicleLocationEnd.locationDescriptionUS;
          const startVehicleLocationBreakdown = getLocationDescriptionBreakdown(startVehicleLocationString);
          const endVehicleLocationBreakdown = getLocationDescriptionBreakdown(endVehicleLocationString);

          if (!startVehicleLocationStateProvince) startVehicleLocationStateProvince = startVehicleLocationBreakdown.stateProvince.code.toLowerCase();
          if (!endVehicleLocationStateProvince) endVehicleLocationStateProvince = endVehicleLocationBreakdown.stateProvince.code.toLowerCase();
          if (!startVehicleLocationAprxShortName) startVehicleLocationAprxShortName = startVehicleLocationBreakdown.city;
          if (!endVehicleLocationAprxShortName) endVehicleLocationAprxShortName = endVehicleLocationBreakdown.city;

          const dateStartFormatted = `"${moment(iftaRoute.dateStart.iso).tz(timezone).format('ll LT')}"`;
          const vehicleLocationStart = `"${startVehicleLocationAprxShortName}, ${startVehicleLocationStateProvince.toUpperCase()}"`;
          const dateEndFormatted = `"${moment(iftaRoute.dateEnd.iso).tz(timezone).format('ll LT')}"`;
          const vehicleLocationEnd = `"${endVehicleLocationAprxShortName}, ${endVehicleLocationStateProvince.toUpperCase()}"`;
          const stateProvince = iftaRoute.stateProvince && `"${iftaRoute.stateProvince.toUpperCase()}"`;
          const savedVehicleKm = `"${iftaRoute.savedVehicleKm ? (this.state.distanceUnitFilter.value === 'km' ? Math.round(iftaRoute.savedVehicleKm).toFixed(0) : Helpers.convertDistance(Math.round(iftaRoute.savedVehicleKm), 'km', 'mi').toFixed(0)) : 0}"`;
          const totalVehicleKmDiff = `"${iftaRoute.totalVehicleKmDiff ? (this.state.distanceUnitFilter.value === 'km' ? Math.round(iftaRoute.totalVehicleKmDiff).toFixed(0) : Helpers.convertDistance(Math.round(iftaRoute.totalVehicleKmDiff), 'km', 'mi').toFixed(0)) : 0}"`;
          const distanceKm = `"${iftaRoute.distanceKm ? (this.state.distanceUnitFilter.value === 'km' ? Math.round(iftaRoute.distanceKm).toFixed(0) : Helpers.convertDistance(Math.round(iftaRoute.distanceKm), 'km', 'mi').toFixed(0)) : 0}"`;

          const fuelPurchases = iftaRoute.fuelPurchases;
          let fuelPurchasesString = '';

          if (fuelPurchases && fuelPurchases.length > 0) {
            fuelPurchases.map((fuelPurchase) => {
              const { volumeUnits, fuelType, totalPaid, currency } = fuelPurchase;
              const volumePumped = volumeUnits && volumeUnits === 'l' ? Helpers.convertFuelUnit(fuelPurchase.volumePumped, 'l', 'gal') : fuelPurchase.volumePumped;

              if (volumePumped && fuelType && totalPaid && currency) {
                let volumePumpedString;
                if (state.volumeUnitFilter.value === 'gal') {
                  if (volumeUnits === 'gal') {
                    volumePumpedString = volumePumped.toFixed(2);
                  } else {
                    volumePumpedString = Helpers.convertFuelUnit(volumePumped, 'l', 'gal').toFixed(2);
                  }
                } else if (state.volumeUnitFilter.value === 'l') {
                  if (volumeUnits === 'gal') {
                    volumePumpedString = Helpers.convertFuelUnit(volumePumped, 'gal', 'l').toFixed(2);
                  } else {
                    volumePumpedString = volumePumped.toFixed(2);
                  }
                }
                fuelPurchasesString += `${volumePumpedString} (${fuelType.toUpperCase()}) - ${currency.toUpperCase()}$${totalPaid}`;
              }
            });
          }

          csvString += `${dateStartFormatted},`;
          csvString += `${vehicleLocationStart},`;
          csvString += `${dateEndFormatted},`;
          csvString += `${vehicleLocationEnd},`;
          csvString += `${stateProvince},`;
          csvString += `${totalVehicleKmDiff},`;
          // getting rid of gps mileage
          // csvString += `${distanceKm},`;
          csvString += `${savedVehicleKm},`;
          csvString += `${fuelPurchasesString}\n`;
        }
        Helpers.createCsvFile(`${this.props.unitId} Switchboard - IFTA Breakdown (${this.props.dateStart && moment(this.props.dateStart).format('MMMM YYYY')} to ${this.props.dateEnd && moment(this.props.dateEnd).format('MMMM YYYY')})`, csvString, true);
      } else {
        // This will be for new IFTA
        const iftaRouteArr = props.iftaRouteArr;
        const iftaFuelCardDataHash = await getFuelCardData(iftaRouteArr.map((iftaRoute) => getAttribute(iftaRoute, 'objectId')), true);

        let csvString = `Unit,Start Date,Start Location,End Date,End Location,State/Province,Odometer Start (${state.distanceUnitFilter.value}),Odometer End (${state.distanceUnitFilter.value}),Odometer Difference (${state.distanceUnitFilter.value}),Driver,Fuel Purchases (Gallons),Fuel Card Transactions (Gallons),Calculated Mileage (${state.distanceUnitFilter.value})\n`;

        for (let i = 0; i < iftaRouteArr.length; i++) {
          const iftaRoute = iftaRouteArr[i];
          const objectId = getAttribute(iftaRoute, 'objectId');
          const driverTimeZoneStr = iftaRoute && getTimezoneString(iftaRoute);

          const dateStart = getAttribute(iftaRoute, 'dateStart');
          const dateEnd = getAttribute(iftaRoute, 'dateEnd');
          const vehicleLocationStart = getAttribute(iftaRoute, 'vehicleLocationStart');
          const vehicleLocationEnd = getAttribute(iftaRoute, 'vehicleLocationEnd');
          const stateProvince = getAttribute(iftaRoute, 'stateProvince');
          const vehicleUnitId = getAttribute(iftaRoute, 'vehicleUnitId');
          const iftaRouteDriverPeriods = getAttribute(iftaRoute, 'iftaRouteDriverPeriods');
          const totalVehicleKmStart = getAttribute(iftaRoute, 'totalVehicleKmStart');
          const totalVehicleKmEnd = getAttribute(iftaRoute, 'totalVehicleKmEnd');
          // const totalVehicleKmDiff = getAttribute(iftaRoute, 'totalVehicleKmDiff');
          // const totalGallons = getAttribute(iftaRoute, 'totalGallons');
          const savedVehicleKm = getAttribute(iftaRoute, 'savedVehicleKm');
          const distanceKm = getAttribute(iftaRoute, 'distanceKm');
          const fuelPurchases = getAttribute(iftaRoute, 'fuelPurchases');
          const fuelCardDataArr = iftaFuelCardDataHash && iftaFuelCardDataHash[objectId];

          const dateStartFormatted = moment(dateStart).tz(driverTimeZoneStr).format('YYYY-MM-DD @ HH:mm');
          const dateEndFormatted = moment(dateEnd).tz(driverTimeZoneStr).format('YYYY-MM-DD @ HH:mm');

          // Obtain current day's start city + stateProvince
          let startVehicleLocationStateProvince = getAttribute(vehicleLocationStart, 'stateProvince');
          let startVehicleLocationAprxShortName = getAttribute(vehicleLocationStart, 'aprxShortName');
          const startVehicleLocationString = getAttribute(vehicleLocationStart, 'locationDescriptionUS') || getAttribute(vehicleLocationStart, 'locationDescriptionCA');
          const startVehicleLocationBreakdown = getLocationDescriptionBreakdown(startVehicleLocationString);
          if (!startVehicleLocationStateProvince) startVehicleLocationStateProvince = startVehicleLocationBreakdown.stateProvince.code.toLowerCase();
          if (!startVehicleLocationAprxShortName) startVehicleLocationAprxShortName = startVehicleLocationBreakdown.city;

          // Obtain current day's end city + stateProvince
          let endVehicleLocationStateProvince = getAttribute(vehicleLocationEnd, 'stateProvince');
          let endVehicleLocationAprxShortName = getAttribute(vehicleLocationEnd, 'aprxShortName');
          const endVehicleLocationString = getAttribute(vehicleLocationEnd, 'locationDescriptionUS') || getAttribute(vehicleLocationEnd, 'locationDescriptionCA');
          const endVehicleLocationBreakdown = getLocationDescriptionBreakdown(endVehicleLocationString);
          if (!endVehicleLocationStateProvince) endVehicleLocationStateProvince = endVehicleLocationBreakdown.stateProvince.code.toLowerCase();
          if (!endVehicleLocationAprxShortName) endVehicleLocationAprxShortName = endVehicleLocationBreakdown.city;

          let driverString = '';
          iftaRouteDriverPeriods && iftaRouteDriverPeriods.map((iftaRouteDriverPeriod, index) => {
            const driver = getAttribute(iftaRouteDriverPeriod, 'driver');
            if (driver) driverString += `${Helpers.toTitleCase(getAttribute(driver, 'user_fullName'))}`;
            if ((iftaRouteDriverPeriods.length > 1) && (index !== iftaRouteDriverPeriods.length - 1)) driverString += ', ';
          });

          let fuelPurchasesString = '';
          fuelPurchases && fuelPurchases.map((fuelPurchase, index) => {
            const volumeUnits = getAttribute(fuelPurchase, 'volumeUnits');
            const volumePumped = getAttribute(fuelPurchase, 'volumePumped');
            const fuelType = getAttribute(fuelPurchase, 'fuelType');
            const currency = getAttribute(fuelPurchase, 'currency');
            const totalPaid = getAttribute(fuelPurchase, 'totalPaid');



            if (volumePumped && fuelType && totalPaid && currency) {
              let volumePumpedString;
              if (state.volumeUnitFilter.value === 'gal') {
                if (volumeUnits === 'gal') {
                  volumePumpedString = volumePumped.toFixed(2);
                } else {
                  volumePumpedString = Helpers.convertFuelUnit(volumePumped, 'l', 'gal').toFixed(2);
                }
              } else if (state.volumeUnitFilter.value === 'l') {
                if (volumeUnits === 'gal') {
                  volumePumpedString = Helpers.convertFuelUnit(volumePumped, 'gal', 'l').toFixed(2);
                } else {
                  volumePumpedString = volumePumped.toFixed(2);
                }
              }

              fuelPurchasesString += `${fuelType.toUpperCase()}: ${volumePumpedString} - $${totalPaid} (${currency.toUpperCase()})`;
              if ((fuelPurchases.length > 1) && (index !== fuelPurchases.length - 1)) fuelPurchasesString += ', ';
            }
          });

          let fuelCardTransactionsString = '';
          fuelCardDataArr && fuelCardDataArr.map((fuelCardData, index) => {
            const fuelQuantity = getAttribute(fuelCardData, 'fuelQuantity');
            const currency = getAttribute(fuelCardData, 'currency');
            const fuelMeasurementUnit = getAttribute(fuelCardData, 'fuelMeasurementUnit');
            const total = getAttribute(fuelCardData, 'total');
            const item = getAttribute(fuelCardData, 'item');

            if (fuelQuantity && currency && fuelMeasurementUnit && total && item) {
              fuelCardTransactionsString += `${item.toUpperCase()}: ${fuelMeasurementUnit === 'l' ? Helpers.convertFuelUnit(fuelQuantity, 'l', 'gal').toFixed(2) : fuelQuantity.toFixed(2)} - $${total} (${currency.toUpperCase()})`;
              if ((fuelCardDataArr.length > 1) && (index !== fuelCardDataArr.length - 1)) fuelCardTransactionsString += ', ';
            }
          });

          csvString += `${vehicleUnitId},`;
          csvString += `${dateStartFormatted},`;
          csvString += `"${startVehicleLocationAprxShortName}, ${startVehicleLocationStateProvince.toUpperCase()}",`;
          csvString += `${dateEndFormatted},`;
          csvString += `"${endVehicleLocationAprxShortName}, ${endVehicleLocationStateProvince.toUpperCase()}",`;
          csvString += `${stateProvince.toUpperCase()},`;
          csvString += `${state.distanceUnitFilter.value === 'km' ? totalVehicleKmStart.toFixed(0) : Helpers.convertDistance(totalVehicleKmStart, 'km', 'mi').toFixed(0)},`;
          csvString += `${state.distanceUnitFilter.value === 'km' ? totalVehicleKmEnd.toFixed(0) : Helpers.convertDistance(totalVehicleKmEnd, 'km', 'mi').toFixed(0)},`;
          csvString += `${state.distanceUnitFilter.value === 'km' ? (totalVehicleKmEnd - totalVehicleKmStart).toFixed(0) : Helpers.convertDistance((totalVehicleKmEnd - totalVehicleKmStart), 'km', 'mi').toFixed(0)},`;
          csvString += `"${driverString}",`;
          csvString += `"${fuelPurchasesString}",`;
          csvString += `"${fuelCardTransactionsString}",`;
          // getting rid of gps mileage

          // csvString += `${state.distanceUnitFilter.value === 'km' ? distanceKm.toFixed(0) : Helpers.convertDistance(distanceKm, 'km', 'mi').toFixed(0)},`;
          csvString += `${state.distanceUnitFilter.value === 'km' ? savedVehicleKm.toFixed(0) : Helpers.convertDistance(savedVehicleKm, 'km', 'mi').toFixed(0)}\n`;
        }

        Helpers.createCsvFile(`${props.unitId} Switchboard - IFTA Breakdown (${props.displayStartDate && moment(props.displayStartDate).format('MMMM YYYY')} to ${props.displayEndDate && moment(props.displayEndDate).format('MMMM YYYY')})`, csvString, true);
      }
      this.setState({ ...this.state, downloadCsvInProgress: false });
    }
  }

  toggleMap(showBool) {
    if (showBool) {
      const sortedSelectedIftaRouteArr = this.state.selectedIftaRouteArr.sort((b, a) => a.dateStart.iso - b.dateEnd.iso);
      this.setState({
        ...this.state,
        map: {
          ...this.state.map,
          showPopup: showBool,
          data: {
            vehicleUnitId: this.props.unitId,
            dateStart: sortedSelectedIftaRouteArr[0].dateStart,
            dateEnd: sortedSelectedIftaRouteArr[sortedSelectedIftaRouteArr.length - 1].dateEnd
          }
        }
      });
    } else {
      this.setState({ ...this.state, map: { ...this.state.map, showPopup: false } });
    }
  }

  async getIFTADataImproved(fuelCardTransactionsByVehicle, removeDuplicateFuelCardTransactions) {
    const { props, state } = this;

    const iftaRouteTransactionObject = {};

    // Determine IFTARoute Data
    const iftaRouteArr = props.iftaRouteArr;
    const routeToNewIFTA = props.routeToNewIFTA;

    // Determine transactions for current vehicle
    let transactionsForVehicle = undefined;
    const unitId = props.unitId;
    for (let key in fuelCardTransactionsByVehicle) {
      if (unitId === key) {
        transactionsForVehicle = fuelCardTransactionsByVehicle[key];
      }
    }

    if (!transactionsForVehicle) {
      // If no transactions found for current vehicle or vehicle not added to CSV
      // console.log('No transactions found for vehicle')
    } else {
      const transactions = transactionsForVehicle.transactions;
      const currentDateTime = new Date(); // mark the datetime that transactions are being created

      // Iterating through IFTARoutes
      for (let i = 0; i < iftaRouteArr.length; i++) {
        const currentIFTARoute = iftaRouteArr[i];
        const currentIFTARouteId = currentIFTARoute.get('objectId');
        const currentIFTARouteDateStart = moment.utc(currentIFTARoute.get('dateStart'));
        const currentIFTARouteDateEnd = moment.utc(currentIFTARoute.get('dateEnd'));
        const currentIFTARouteStateProvince = currentIFTARoute.get('stateProvince');

        let fuelCardPromises = [];
        let fuelPurchasesForIFTARoute = [];
        let fuelQuantityTotalForIFTARoute = 0;

        // Iterating through transactions
        for (let j = 0; j < transactions.length; j++) {
          const currentTransaction = transactions[j];
          const currentTransactionDateTime = moment.utc(currentTransaction.transactionDateTime);
          const currentTransactionStateProvince = currentTransaction.stateProvinceAbbrv.toLowerCase();

          // Check to see if any transaction lands between interval and matches stateProvince
          if (
            currentIFTARouteDateEnd.diff(currentIFTARouteDateStart, 'ms') >= 0 &&
            currentIFTARouteDateStart.diff(currentTransactionDateTime, 'ms') <= 0 &&
            currentIFTARouteDateEnd.diff(currentTransactionDateTime, 'ms') >= 0 &&
            currentIFTARouteStateProvince === currentTransactionStateProvince
          ) {
            let fuelCardDataObj = {
              locationName: currentTransaction.locationName,
              fuelQuantity: currentTransaction.fuelQuantity,
              stateProvinceAbbrv: currentTransaction.stateProvinceAbbrv,
              total: currentTransaction.total,
              unitPrice: currentTransaction.unitPrice,
              fuelMeasurementUnit: currentTransaction.fuelMeasurementUnit,
              city: currentTransaction.city,
              odometer: currentTransaction.odometer,
              item: currentTransaction.item,
              currency: currentTransaction.currency,
              cardNo: currentTransaction.cardNo,
              transactionDateTime: currentTransactionDateTime.toDate(),
              transactionRow: currentTransaction.transactionRow,
              unitId: currentTransaction.unitId,
              iftaRouteBeta: routeToNewIFTA ? currentIFTARoute : undefined,
              iftaRoute: routeToNewIFTA ? undefined : currentIFTARoute,
            };

            fuelCardPromises.push(addRecord('IFTAFuelCardData', fuelCardDataObj));

            if (removeDuplicateFuelCardTransactions) {
              const removedDuplicates = await removeDuplicateIFTAFuelCardData(currentIFTARoute, fuelCardDataObj, currentDateTime);

              // For each removed duplicate, we want to less the fuelQuantityTotalForIFTARoute
              for (let i = 0; i < removedDuplicates.length; i++) {
                const removedDuplicate = removedDuplicates[i];
                const fuelQuantity = getAttribute(removedDuplicate, 'fuelQuantity') || 0;

                // Check if the fuelQuantity we're about to remove puts us in the negatives (< 0)
                // This could be due to bad/test data that causes subtractions past 0
                if (fuelQuantity > fuelQuantityTotalForIFTARoute) {
                  fuelQuantityTotalForIFTARoute = 0;
                } else {
                  fuelQuantityTotalForIFTARoute -= fuelQuantity;
                }
              }
            }
            fuelQuantityTotalForIFTARoute += currentTransaction.fuelQuantity;
          }
        }

        // Update the IFTARoute's totalGallons
        fuelCardPromises.push(updateRecord(currentIFTARoute, { 'totalGallons': fuelQuantityTotalForIFTARoute }, true));

        // Save fuelCard information to database
        try {
          fuelCardPromises = await Promise.all(fuelCardPromises);
        } catch (error) {
          console.log(error);
        }
      }
    }

    this.setState({ showIFTAFuelCardDialog: false }, () => {
      if (props.refreshState) {
        props.refreshState();
        // 2023-06-12: At the moment, there is a lot of technical debt around reloading data to show the updated transactions
        // It still shows old data in the view even after refreshState. As a result, to show the updated information we will force a window refresh
        window.location.reload();
      }
    });
  }

  // ----------------------- Introduce new functions for IFTA CSV Fuel Card Upload ----------------------- //
  toggleUploadFuelCardDialog = () => {
    const newState = { ...this.state };
    newState.confirmUploadFuelCardDialog = {
      ...newState.confirmUploadFuelCardDialog,
      show: !newState.confirmUploadFuelCardDialog.show,
      toggleCounter: newState.confirmUploadFuelCardDialog.toggleCounter + 1,
    };

    if (this.state.confirmUploadFuelCardDialog.toggleCounter <= 1) {
      this.setState(newState);
    }

    return;
  }

  getFuelCardTypeShortCode = (fullVehicleCardType) => {
    const fuelCardShortCode = this.state.fuelCardTypes[fullVehicleCardType];
    return fuelCardShortCode;
  }

  fuelCardUploadHandler = (fuelCardType, file) => {
    if (fuelCardType) {
      if (!fuelCardType || fuelCardType.toLowerCase() === 'select fuel card') return;

      const newState = { ...this.state, fuelCardType };
      this.setState(newState, () => document.getElementById('iftaFuelCardUploaderInput').click());
    } else {
      const fuelCardType = this.state.fuelCardType;

      // IFTA.getInfoFromFuelCard(file, this.getFuelCardTypeShortCode(fuelCardType))
      // .then(iftaFuelCardData => this.setState({ iftaFuelCardData, showIFTAFuelCardDialog: true }));

      IFTA.getIFTAFuelCardInfo(file).then(iftaFuelCardData => {
        this.setState({ iftaFuelCardData, showIFTAFuelCardDialog: true });
      });
    }
  }
  // ----------------------- Introduce new functions for IFTA CSV Fuel Card Upload ----------------------- //

  render() {
    const { state, props } = this;
    const tempView = (props.location && getQueryParameter(props.location.search, 'v')) || 'summary';
    const queryStrObj = getDeserialized(props.location.search).params;
    const originalTotalMileage = getQueryParameter(props.location.search, 'originaltotalmileage');

    // Iterate through routes and total ones without odometer readings
    let odometerReadingsDisplayingZero = false;
    let odometerReadingsDisplayingZeroTotal = 0;
    const filteredIFTARouteArr = this.props.iftaRouteArr.filter((iftaRouteObj) => !iftaRouteObj.get('isHidden'));
    for (let i = 0; i < filteredIFTARouteArr.length; i++) {
      const currentIFTARoute = filteredIFTARouteArr[i];
      const startOdometerReading = getAttribute(currentIFTARoute, 'totalVehicleKmStart');
      const endOdometerReading = getAttribute(currentIFTARoute, 'totalVehicleKmEnd');

      if (startOdometerReading === 0 && endOdometerReading === 0) {
        odometerReadingsDisplayingZeroTotal++;

        // Check to see if most routes are not showing odometer readings (greater than 50%)
        if (((odometerReadingsDisplayingZeroTotal / filteredIFTARouteArr.length) * 100) > 50) {
          odometerReadingsDisplayingZero = true;
          break;
        }
      }
    }

    const navItems = [
      {
        name: 'Chronological Order',
        isActive: tempView === 'breakdown',
        to: getSerialized({ ...queryStrObj, v: 'breakdown' }).query,
      },
      {
        name: 'Total Mileage',
        isActive: tempView === 'summary',
        to: getSerialized({ ...queryStrObj, v: 'summary' }).query,
      },
    ];

    return (
      <>
        <Title title={`Unit ${props.unitId} Mileage Details`} />
        <SubNavigationBar navItems={navItems} />

        {(tempView === 'summary') && (props.routeToNewIFTA && !originalTotalMileage) &&
          // New Vehicle Summary Page
          <VehicleSummaryBeta
            routeToNewIFTA={props.routeToNewIFTA}
            unitId={props.unitId}
            dateStart={props.dateStart}
            dateEnd={props.dateEnd}
            iftaRouteArr={props.iftaRouteArr}
            selectedFilter={state.vehicleSummaryFilter}
            distanceUnitFilter={state.distanceUnitFilter}
            volumeUnitFilter={state.volumeUnitFilter}
            displayStartDate={props.displayStartDate}
            displayEndDate={props.displayEndDate}
            odometerReadingsDisplayingZero={odometerReadingsDisplayingZero}
            showOdometerValuesFilter={state.showOdometerValuesFilter}
            onRefresh={() => props.refreshState()}
          />
        }
        {(tempView === 'summary') && ((props.routeToNewIFTA && originalTotalMileage === 'true') || !(props.routeToNewIFTA)) &&
          // Old Vehicle Summary Page
          <VehicleSummary
            unitId={props.unitId}
            dateStart={props.dateStart}
            dateEnd={props.dateEnd}
            iftaRouteArr={props.iftaRouteArr}
            selectedFilter={state.vehicleSummaryFilter}
            distanceUnitFilter={state.distanceUnitFilter}
            handleGetOdometerReadings={(odometerReadingsObj) => this.setState({
              ...state, odometerDiff: ((state.odometerReadings.odometerStart !== undefined && state.odometerReadings.odometerEnd !== undefined) &&
                Math.round(state.odometerReadings.odometerEnd - state.odometerReadings.odometerStart)
              )
            })}
            odometerDiff={state.odometerDiff}
            displayStartDate={props.displayStartDate}
            displayEndDate={props.displayEndDate}
          />
        }
        {(tempView === 'breakdown') &&
          <div>
            <div style={{ margin: '1em' }}>
              {/* <SBSelect
                containerClassName="ml-2 mr-2 d-inline-block"
                className="sb-jobs-list-select"
                defaultToggleText={state.vehicleBreakdownFilter.label}
                items={state.selectableVehicleBreakdownFilterItems}
                getSelectedItems={(selectedFilter) => this.setState({ ...state, vehicleBreakdownFilter: selectedFilter[0] })}
                showFilter
              /> */}
              <SBSelect
                containerClassName="ml-2 mr-2 d-inline-block"
                className="sb-jobs-list-select"
                defaultToggleText={state.distanceUnitFilter.label}
                items={state.distanceUnitFilterItems}
                getSelectedItems={(selectedFilter) => this.setState({ ...state, distanceUnitFilter: selectedFilter[0] })}
                showFilter
              />
              <SBSelect
                containerClassName="ml-2 mr-2 d-inline-block"
                className="sb-jobs-list-select"
                defaultToggleText={state.volumeUnitFilter.label}
                items={state.volumeUnitFilterItems}
                getSelectedItems={(selectedFilter) => this.setState({ ...state, volumeUnitFilter: selectedFilter[0] })}
                showFilter
              />
              <SBSelect
                containerClassName="ml-2 mr-2 d-inline-block"
                className="sb-jobs-list-select"
                defaultToggleText={state.showOdometerValuesFilter.label}
                items={state.showOdometerValuesFilterItems}
                getSelectedItems={(selectedFilter) => this.setState({ ...state, showOdometerValuesFilter: selectedFilter[0] })}
                showFilter
              />
              <MDBBtn
                size="sm"
                color="secondary"
                disabled={state.printInProgress}
                onClick={() => this.printReport('vehicleRouteBreakdown')}
                style={{ marginRight: '.5em' }}
              >
                Print Report
              </MDBBtn>
              <MDBBtn
                size="sm"
                color="secondary"
                disabled={state.downloadCsvInProgress}
                onClick={() => this.downloadCsv('vehicleRouteBreakdown')}
                style={{ marginRight: '.5em', width: '14em' }}
              >
                Download CSV
              </MDBBtn>
              {props.routeToNewIFTA &&
                <div>
                  <FuelCardCSVUploader
                    className="my-3 inline-block"
                    iftaRoutes={props.iftaRouteArr}
                    onSuccessClose={() => {
                      if (props.refreshState) {
                        props.refreshState();
                        // 2023-06-12: At the moment, there is a lot of technical debt around reloading data to show the updated transactions
                        // It still shows old data in the view even after refreshState. As a result, to show the updated information we will force a window refresh
                        window.location.reload();
                      }
                    }}
                  />
                  <RepairRoute
                    unitId={props.unitId}
                    dateStart={props.dateStart}
                    dateEnd={props.dateEnd}
                  ></RepairRoute>
                  <AddRoute
                    unitId={props.unitId}
                    isEdit={false}
                    distanceUnit={state.distanceUnitFilter.value}
                    distanceUnitFilter={state.distanceUnitFilter}
                    volumeUnit={state.volumeUnitFilter.value}
                    refreshState={() => props.refreshState()}
                    iftaRouteRecordsForTablePreview={props.iftaRouteArr.filter((iftaRouteObj) => !getAttribute(iftaRouteObj, 'isHidden', true))}
                    volumeUnitFilter={state.volumeUnitFilter}
                    driverTimeZoneStr={state.driverTimeZoneStr}
                  />
                  <MigrateRoutes
                    unitId={props.unitId}
                    dateStart={props.dateStart}
                    dateEnd={props.dateEnd}
                    iftaRoutes={props.iftaRouteArr.filter((iftaRouteObj) => !getAttribute(iftaRouteObj, 'isHidden', true))}
                    refreshState={() => props.refreshState()}
                  />
                </div>
              }
              {/* state.confirmUploadFuelCardDialog.toggleCounter <= 1 ?
                <MDBBtn
                  color="secondary"
                  size="sm"
                  onClick={() => this.toggleUploadFuelCardDialog()}
                  disabled={state.disableIFTAButtonGroup}
                  style={{ width: '18em', marginRight: '.5em' }}
                ><MDBIcon icon="gas-pump" className="mr-2" />
                  Upload Fuel Card
                </MDBBtn>
                :
                <FileUpload
                  mode="basic"
                  customUpload
                  uploadHandler={async (e) => {
                    this.fuelCardUploadHandler(undefined, e.files[0]);
                    e.options.clear();
                  }}
                  chooseLabel="Choose File"
                  auto
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                />
                // <span>
                //   <ListSelectField
                //     id="fuelCardSelector"
                //     onClick={() => this.toggleUploadFuelCardDialog()}
                //     onChange={(e, index, value) => this.fuelCardUploadHandler(value)}
                //     value={state.fuelCardType}
                //     maxHeight={200}
                //     list={Object.keys(state.fuelCardTypes)}
                //     disabledItemIndices={[0]}
                //     disabled={state.disableIFTAButtonGroup}
                //     style={{ display: 'inline-block', marginLeft: '1em', top: '1.52em', width: '12em' }}
                //   />
                //   <FileUploader
                //     inputId="iftaFuelCardUploaderInput"
                //     uploadHandler={(file) => this.fuelCardUploadHandler(undefined, file)}
                //     disabled={state.disableIFTAButtonGroup}
                //     style={{ cursor: 'pointer', position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, width: '0em', opacity: 0, zIndex: '-1' }}
                //   />
                // </span>
                */}
              {state.confirmUploadFuelCardDialog.show &&
                <ConfirmModal
                  show={state.confirmUploadFuelCardDialog.show}
                  handleModalChoice={() => this.toggleUploadFuelCardDialog()}
                  handleClose={() => this.toggleUploadFuelCardDialog()}
                  alertMode
                  primaryLabel="Close"
                >
                  <b>Warning: </b> Uploading a Fuel Card could overwrite your current changes, if data in the fuel card overlaps with changes you have made
                  <br /><br />
                  <div style={{ fontSize: '.85em' }}>
                    Example: If you previously entered the number of Gallons purchased to 60 for one of your vehicles between February 1 - 28, and the fuel card you are uploading contains information saying you have purchased 70 Gallons for that specific vehicle between February 1 - 28, the 70 gallons stated in the fuel card will accumulate on top of the 60 you entered, changing the value to 130
                  </div>
                </ConfirmModal>
              }
              {state.showDataButtons &&
                <MDBBtn
                  size="sm"
                  color="info"
                  onClick={() => this.toggleMap(true)}
                  style={{ marginRight: '.5em', width: '14em' }}
                >
                  View Map
                </MDBBtn>
              }
              {state.showDataButtons &&
                <MDBBtn
                  size="sm"
                  color="info"
                  onClick={() => {
                    const sortedSelectedIftaRouteArr = state.selectedIftaRouteArr.sort((b, a) => a.dateStart.iso - b.dateEnd.iso);
                    this.setState({
                      ...state,
                      vehicleMileagesIntervalSummary: {
                        open: true,
                        dateInterval: {
                          start: sortedSelectedIftaRouteArr[0].dateStart,
                          end: sortedSelectedIftaRouteArr[sortedSelectedIftaRouteArr.length - 1].dateEnd,
                        },
                      }
                    });
                  }}
                  style={{ marginRight: '.5em', width: '14em' }}
                >
                  View Locations
                </MDBBtn>
              }
            </div>

            {/* <IFTAFuelCardDialog
              showIFTAFuelCardDialog={state.showIFTAFuelCardDialog}
              handleIFTAFuelCardDialogClose={() => this.setState({ showIFTAFuelCardDialog: false })}
              iftaFuelCardData={state.iftaFuelCardData}
              getIFTADataImproved={this.getIFTADataImproved}
              fuelCardUploadHandler={(file) => this.fuelCardUploadHandler(undefined, file)}
            /> */}

            <VehicleRouteBreakdown
              unitId={props.unitId}
              iftaRouteArr={props.iftaRouteArr}
              vehicleBreakdownFilter={state.vehicleBreakdownFilter}
              distanceUnitFilter={state.distanceUnitFilter}
              volumeUnitFilter={state.volumeUnitFilter}
              showOdometerValuesFilter={state.showOdometerValuesFilter}
              refreshState={() => this.props.refreshState()}
              selectedIftaRoute={(selectedIftaRouteArr) => {
                let showDataButtons = false;
                if (selectedIftaRouteArr.length > 0) showDataButtons = true;
                this.setState({ ...state, showDataButtons, selectedIftaRouteArr });
              }}
              location={props.location}
              dateStart={props.dateStart}
              dateEnd={props.dateEnd}
              displayStartDate={props.displayStartDate}
              displayEndDate={props.displayEndDate}
              setVehicleRouteBreakdownData={(data) => this.setState({ ...state, vehicleRouteBreakdownData: data })}
              routeToNewIFTA={props.routeToNewIFTA}
              odometerReadingsDisplayingZero={odometerReadingsDisplayingZero}
            />
            {state.vehicleMileagesIntervalSummary.open &&
              <VehicleMileagesIntervalSummary
                open={state.vehicleMileagesIntervalSummary.open}
                handleClose={() => this.setState({ ...state, vehicleMileagesIntervalSummary: { ...state.vehicleMileagesIntervalSummary, open: false } })}
                iftaInterval={state.vehicleMileagesIntervalSummary.dateInterval}
                vehicleUnitId={props.unitId}
              />
            }
            <MDBModal
              centered
              isOpen={state.map.showPopup}
              toggle={() => this.toggleMap(false)}
            >
              <MDBModalBody>
                <div style={{ height: '30em', width: '30em' }}>
                  {state.map.showPopup &&
                    <MapPopup
                      {...state.map.data}
                    />
                  }
                </div>
              </MDBModalBody>
            </MDBModal>
          </div>
        }
      </>
    );
  }
};

export default VehicleRoutes;
