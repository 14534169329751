/* global Twilio */
import Parse from 'parse';

/** @module TextService */

/**
 * @memberof module:TextService
 * 
 * @param {*} number 
 * @param {*} strMessage 
 * 
 * @returns 
 */
function sendText(number, strMessage) {
  const promise = new Promise((resolve, reject) => {
    const textMessage = { receiver: number, message: strMessage };
    Parse.Cloud.run('twilioSendText', textMessage).then(
      response => {
        resolve(response);
      },
      error => {
        console.log(error);
        reject(error);
      }
    );
  });
  return promise;
}

export {
  sendText,
};
