import { addRecord, createQuery, find, includePointers, setQueryRestriction, sortQuery } from 'api/Parse';
import { QueryRestrictionTypes, QuerySortOrderTypes } from 'enums/Query';

/**
 * @description Given a dispatch job objectid, find all related job/action history
 * @param {string} dispatchJobObjectId
 * @param {array} filter - array of Filter objects
 */
async function getHistory(dispatchJobObjectId, filters = [], limit = 1000, findAll) {
  const historyQuery = createQuery('DispatchHistory');
  setQueryRestriction(historyQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchJob', dispatchJobObjectId);

  filters.map(filter => {
    setQueryRestriction(historyQuery, filter.queryRestriction, filter.attribute, filter.value);
  });

  sortQuery(historyQuery, QuerySortOrderTypes.DESCENDING, 'triggeredAtUTC');
  if (!findAll) {
    setQueryRestriction(historyQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  }
  includePointers(historyQuery, ['dispatchJob']);

  try {
    const history = await find(historyQuery, undefined, findAll);
    return history;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @description add history logging to the job
 * @param {object} historySbObject - DispatchHistory
 */
async function addHistory(dispatchHistorySbObject) {
  const keyAttrObj = { ...dispatchHistorySbObject };
  delete keyAttrObj.objectId;

  return await addRecord('DispatchHistory', keyAttrObj);
}

export {
  addHistory,
  getHistory,
};