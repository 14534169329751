import React, { useEffect } from 'react';

// sbCore
import Divider from 'sbCore/Divider/Divider';

// shared components
import DocumentInputText from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputText/InputText';
import DocumentInputNumber from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputNumber/InputNumber';

// styles
import './style.scss';

/**
 * @description Load Confirmation Document Generator total section
 * @param {Function} updateDocumentStateObj - Callback function that's passed back an attribute and value to update its parent's state
 * @param {Number} quoteRate - The current quote rate value
 * @param {Number} taxRate - The current tax rate value
 * @param {Number} taxAmount - The current tax amount value
 * @param {Number} amountDue - The current amount due value
 * @param {Number} currency - The current currency type value
 * @returns
 */

const LoadConfirmationTotal = (props) => {

  // Calculates totals when any given values change
  useEffect(() => {
    let totalBeforeTax = 0;
    let totalTax = 0;
    let totalAfterTax = 0;

    async function calculateChanges() {
      totalBeforeTax = props.quoteRate;
      totalTax = totalBeforeTax * (props.taxRate / 100);
      totalAfterTax = totalBeforeTax + totalTax;

      if (props.onAmountDueChange) {
        props.onAmountDueChange(totalAfterTax.toFixed(2));
      }

      if (props.onTaxAmountChange) {
        props.onTaxAmountChange(totalTax.toFixed(2));
      }
    }

    calculateChanges();
  }, [props.taxRate, props.quoteRate]);

  // ** Functions ** //
  // Updates the parent documentStateObj given an attribute and value
  function updateDocumentStateObj(attribute, value) {
    if (props.updateDocumentStateObj) props.updateDocumentStateObj(attribute, value);
  }

  // ** Components ** //
  const quoteField = (
    <>
      <DocumentInputNumber
        label="QUOTE"
        horizontal
        inputClassName="text-sm"
        value={props.quoteRate}
        onChange={(value) => updateDocumentStateObj('quoteRate', value)}
        mode="currency"
        currency="USD"
        success={props.quoteRate}
        warning={!props.quoteRate || props.quoteRate == ''}
        isDownloaded={props.isDownloaded}
      />
    </>
  );

  const taxRateField = (
    <>
      <DocumentInputNumber
        label="(TAX RATE)"
        horizontal
        inputClassName="text-sm"
        value={props.taxRate}
        onChange={(value) => updateDocumentStateObj('taxRate', value)}
        suffix="%"
        success={props.taxRate}
        warning={!props.taxRate || props.taxRate == ''}
        isDownloaded={props.isDownloaded}
      />
    </>
  );

  const taxField = (
    <>
      <DocumentInputNumber
        label="TAX"
        horizontal
        inputClassName="text-sm"
        value={props.taxAmount}
        onChange={(value) => updateDocumentStateObj('taxAmount', value)}
        mode={props.currency ? "currency" : undefined}
        currency="USD"
        success={props.taxAmount}
        warning={!props.taxAmount || props.taxAmount == ''}
        isDownloaded={props.isDownloaded}
      />
    </>
  );

  const amountDueField = (
    <>
      <DocumentInputNumber
        label="AMOUNT DUE"
        horizontal
        inputClassName="text-sm"
        value={props.amountDue}
        onChange={(value) => updateDocumentStateObj('amountDue', value)}
        mode="currency"
        currency="USD"
        success={props.amountDue}
        warning={!props.amountDue || props.amountDue == ''}
        isDownloaded={props.isDownloaded}
      />
    </>
  );

  const currencyField = (
    <>
      <DocumentInputText
        inputClassName="text-sm"
        value={props.currency}
        onChange={(value) => updateDocumentStateObj('currency', value)}
        success={props.currency}
        warning={!props.currency || props.currency == ''}
        isDownloaded={props.isDownloaded}
      />
    </>
  );

  return (
    <div className="flex flex-column">
      <div className="flex flex-row">
        {quoteField}
      </div>
      <div className="flex flex-row">
        {taxRateField}
      </div>
      <div className="flex flex-row">
        {taxField}
      </div>
      <Divider />
      <div className="flex flex-row">
        {amountDueField}
        {currencyField}
      </div>
    </div>
  );
};

export default LoadConfirmationTotal;
