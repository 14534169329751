/**
 * Emailing
 */
import Parse from 'parse';
import moment from 'moment-timezone';

/** @module Email */

/**
 * @memberof module:Email
 * @description high level generic email validation
 *
 * @param {string} email
 */
function isInvalidEmailFormat(email) {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(email);
}

/**
 * @memberof module:Email
 * @description send an email
 *
 * @param {string} senderName - name of the sender
 * @param {string} senderEmail - email of the sender
 * @param {string} recipientEmail - emails of the recipients
 * @param {string} subject - email subject line
 * @param {string} textStringBody - email content by text string
 * @param {string} htmlStringBody - email content by html string
 * @param {object} additionalOptions - additional emailing options: { ccEmail: cc@email.com, bccEmail: bcc@gmail.com }
 */
async function send(senderName = '', senderEmail = '', recipientEmail = '', subject = '', textStringBody, htmlStringBody, switchboardFormatted, switchboardFormattedFooterOnly = false, replyToName = undefined, replyToEmail = undefined, additionalOptions = {}, attachments = []) {
  // validate sender and recipient emails
  if (isInvalidEmailFormat(senderEmail)) {
    throw new Error(`Sender Email <${senderEmail}> is of invalid format`);
  }

  if (replyToEmail && isInvalidEmailFormat(replyToEmail)) {
    throw new Error(`ReplyTo Email <${replyToEmail}> is of invalid format`);
  }

  const recipientEmails = recipientEmail.split(',');
  if (recipientEmails.length > 0) {
    recipientEmails.map(email => {
      const trimmedRecipientEmail = email.trim();
      if (isInvalidEmailFormat(trimmedRecipientEmail)) {
        throw new Error(`Recipient Email <${trimmedRecipientEmail}> is of invalid format`);
      }
    });
  }

  const ccEmail = additionalOptions.ccEmail;
  additionalOptions.ccEmail = ccEmail ? additionalOptions.ccEmail.split(',') : [];
  if (additionalOptions.ccEmail && additionalOptions.ccEmail.length > 0) {
    additionalOptions.ccEmail.map(email => {
      const trimmedCCRecipientEmail = email.trim();
      if (isInvalidEmailFormat(trimmedCCRecipientEmail)) {
        throw new Error(`Recipient Email <${trimmedCCRecipientEmail}> is of invalid format`);
      }
    });
  }

  const bccEmail = additionalOptions.bccEmail;
  additionalOptions.bccEmail = bccEmail ? additionalOptions.bccEmail.split(',') : [];
  if (additionalOptions.bccEmail && additionalOptions.bccEmail.length > 0) {
    additionalOptions.bccEmail.map(email => {
      const trimmedBCCRecipientEmail = email.trim();
      if (isInvalidEmailFormat(trimmedBCCRecipientEmail)) {
        throw new Error(`Recipient Email <${trimmedBCCRecipientEmail}> is of invalid format`);
      }
    });
  }

  const hasMessageBody = textStringBody || htmlStringBody;
  if (!hasMessageBody ||(textStringBody || htmlStringBody).trim() === '') {
    throw new Error(`Email is missing message body`);
  }

  if (switchboardFormatted && !htmlStringBody) {
    throw new Error(`Cannot send Switchboard formatted email without htmlStringBody`);
  }

  // create the email sender string ex. Jamie Doe <jamie@doe.com>
  const sender = `${senderName} <${senderEmail}>`;
  let replyTo;
  if (replyToName && replyToEmail) {
    replyTo = `${replyToName} <${replyToEmail}>`;
  }
  const response = await Parse.Cloud.run('sendEmail', {
    from: sender,
    replyTo,
    to: recipientEmails,
    subject,
    textBody: textStringBody,
    htmlBody: htmlStringBody,
    switchboardFormatted,
    switchboardFormattedFooterOnly,
    additionalOptions,
    attachments,
  });
  return response;
}

export {
  send,
};
