import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';

// CSS
import styles from './WalkthroughSelectTip.module.scss';

class WalkthroughSelectTip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { children, expand, handleToggle, showBoxTop, showBoxBottom, style, toolTipStyle, selectorContainerStyle } = this.props;

    const paperStyle = { padding: '.5em', paddingLeft: '1.2em' };

    return (
      <div className={styles.walkthroughSelectTip} style={style}>
        { expand && showBoxTop &&
          <div className={styles.toolTipContainer} style={{ top: '-8em', ...toolTipStyle }}>
            <Paper style={paperStyle} zDepth={2}>
              { children }
            </Paper>
          </div>
        }
        <div className={styles.selectorContainer} style={selectorContainerStyle} onClick={(e) => { e.stopPropagation(); handleToggle(); }}>
          <span className={styles.walkthroughPulse} />
          {/* <img src="https://s3.amazonaws.com/miscellaneous-file-store/animated_target_bolded.gif" alt="target" />*/}
        </div>
        { expand && showBoxBottom &&
          <div className={styles.toolTipContainer} style={toolTipStyle}>
            <Paper style={paperStyle} zDepth={2}>
              { children }
            </Paper>
          </div>
        }
      </div>
    );
  }
}

WalkthroughSelectTip.propTypes = {
  children: PropTypes.node,
  expand: PropTypes.bool,
  handleToggle: PropTypes.func,
  showBoxTop: PropTypes.bool,
  showBoxBottom: PropTypes.bool,
  selectorContainerStyle: PropTypes.obj,
  toolTipStyle: PropTypes.obj,
  style: PropTypes.obj,
};

export default WalkthroughSelectTip;
