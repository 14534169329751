import React from 'react';
import PropTypes from 'prop-types';

// Components
import HelpDialog from 'components/HelpDialog/container/HelpDialog';

class HomeHelpDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const uListStyle = { marginTop: '1em', paddingLeft: '1.2em', fontSize: '1em', fontWeight: 500 };
    const listStyle = { marginBottom: '.5em' };

    return (
      <HelpDialog title="Need help?" buttonStyle={this.props.buttonStyle} buttonChildren={this.props.buttonChildren}>
        <div style={{ marginTop: '2em', marginBottom: '2em' }}>
          Here are ways you can get your questions answered:
          <ul style={uListStyle}>
            <li>Visit our Support Portal at <a href="http://support.onswitchboard.com" target="_blank">support.onswitchboard.com</a></li>
            <li>Email us at <b><a href="mailto:support@onswitchboard.com" target="_blank">support@onswitchboard.com</a></b></li>
            <li>Live Chat (chat icon on the bottom right of the screen)</li>
            <li>Call us at <b>1-844-5-FLEETS (1-844-535-3387)</b></li>
          </ul>
        </div>

        {/* <div>
          How and where do I start?
          <div style={{ marginTop: '1em', fontSize: '.85em' }}>
            Let&#39;s start by explaining the 2 bars at the top of your screen...
            <br /><br />
            On the first bar, you&#39;ll notice an icon that looks like a person(s), a bell, a question mark,
            and the name of the owner of this account. Here&#39;s the breakdown:
            <ul style={{ ...uListStyle, fontSize: '1em' }}>
              <li style={listStyle}>
                <b>Bell</b>: This is the <b>Notifications</b> icon. This is where you&#39;ll receive different types of notifications depending on which feature services you have with us. For example, if one of your drivers makes an edit to their logs, you&#39;ll see the icon light up notifying you. Or if you have the Map Module, you will get geofence notifications that lets you know when drivers have entered specific areas (contact us for more details). Click the icon to view your notifications and the actions available
              </li>
              <li style={listStyle}>
                <b>Question Mark</b>: This is the <b>Help</b> icon. You&#39;ll see this icon around the website to help you with various features. Click on them, and they&#39;ll help explain or elaborate certain features to assist you
              </li>
              <li style={listStyle}>
                <b>Name</b>: This is a <b>Settings</b> dropdown. Click it and it will present you with multiple options from logging out to customizing your account settings
              </li>
            </ul>
            <br /><br />
            The second bar contains multiple tabs depending on which feature services you have with us. Click on a tab to visit that feature. Features include, but are not limited to, Drivers, Equipment, Map, Safety, Documents, Driver Behaviour, and IFTA. Here&#39;s the breakdown:
            <ul style={{ ...uListStyle, fontSize: '1em' }}>
              <li style={listStyle}>
                <b>Drivers</b>: The Drivers page is like a hub for all things related to your drivers and their co-drivers Hours of Service (HOS). Add a driver, view all your drivers, edit their personal and company details, view their Hours of Service logs and information, edit their logs, inspect unidentified driving time, and much more
              </li>
              <li style={listStyle}>
                <b>Equipment</b>: The Equipment page serves a similar purpose as the Drivers page, except it&#39;s for your vehicles, trailers, and other equipment. For example, you can add a vehicle and edit information such as the Unit ID
              </li>
              <li style={listStyle}>
                <b>Map</b>: The page where Drivers and Equipments tie together. Click on one of your Drivers here to view a live tracking of your driver/vehicle, view and share their driving paths/history, create geofences and get notified when they have entered certain areas, view both the driver and vehicle information, etc
              </li>
              <li style={listStyle}>
                <b>Safety</b>: The Safety page allows you to view and track your driver&#39;s and vehicle&#39;s (cycle) hours, violations, and inspections
              </li>
              <li style={listStyle}>
                <b>Documents</b>: The Documents page is where you can add Jobs and assign them to your driving teams, in addition to uploading and receiving documents respective of each driver/job
              </li>
              <li style={listStyle}>
                <b>IFTA</b>: IFTA is our fuel tax calculation service. Using the data we have of your drivers and their vehicles, in addition to any other additional information you input (including fuel cards), we can generate various fuel tax reports for you
              </li>
            </ul>

            <br /><br />
            Having a hard time? Contact us or visit a feature&#39;s page and look for the help icon where available
          </div>
        </div> */}


      </HelpDialog>
    );
  }
}

HomeHelpDialog.propTypes = {
  buttonStyle: PropTypes.object,
};

export default HomeHelpDialog;
