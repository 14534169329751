import React from 'react';
import PropTypes from 'prop-types';
import Chip from 'material-ui/Chip';

// Components
import StatusIcon from 'components/StatusIcon/view/StatusIcon';

// CSS
import styles from './Sidebar.module.scss';

function Sidebar(props) {
  let summaryStyle = styles.option;
  let geofenceEventsStyle = styles.option;
  if (props.activeSubview === 'summary' || !props.activeSubview) {
    summaryStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'geofenceEvents') {
    geofenceEventsStyle = `${styles.active} ${styles.option}`;
  }
  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarTitle}>
        <div className={styles.title}>
          <span>{props.title}</span>
        </div>
        <div className={styles.subHeader}>Vehicle Details</div>
        { props.vehicle && !props.vehicle.get('enabled') &&
          <div className={styles.disabled}>
            <Chip className={styles.chip} labelColor={'white'}>INACTIVE</Chip>
          </div>
        }
      </div>

      <div className={styles.subViews}>
        <div className={summaryStyle} onClick={() => props.handleSubViewClick('summary')}>
          <div className={styles.label}>Summary</div>
        </div>

        <div className={geofenceEventsStyle} onClick={() => props.handleSubViewClick('geofenceEvents')}>
          <div className={styles.label}>Geofence Events</div>
        </div>

      </div>
    </div>
  );
}

Sidebar.propTypes = {
  title: PropTypes.string,
  vehicle: PropTypes.object,
  vehicleFetched: PropTypes.bool,
  activeSubview: PropTypes.string,
  handleSubViewClick: PropTypes.func,
  eldModuleSubscribed: PropTypes.bool,
};

export default Sidebar;
