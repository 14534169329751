/** @module GPS */

/**
 * @memberof module:GPS
 * 
 * @param {*} num 
 * @param {*} min 
 * @param {*} max 
 * 
 * @returns 
 */
function getGPSDegreeRange(num, min, max) {
  return num > min && num < max;
}

/**
 * @memberof module:GPS
 * @description get the direction of the GPS degree
 * 
 * @param {number} gpsHeading the degree of the vehicle currently is running
 */
function getGPSDirection(gpsHeading) {
  const roundedGpsHeading = gpsHeading && Math.round(gpsHeading);
  switch (true) {
    // North
    case (roundedGpsHeading === 0):
      return { degree: roundedGpsHeading, direction: 'North' };
    case (!roundedGpsHeading):
      return { degree: roundedGpsHeading, direction: '' };
    // NorthEast
    case getGPSDegreeRange(roundedGpsHeading, 0, 90):
      return { degree: roundedGpsHeading, direction: `North ${Math.round(roundedGpsHeading)}° East` };
    // East
    case (roundedGpsHeading === 90):
      return { degree: roundedGpsHeading, direction: `East` };
    // EastSouth
    case getGPSDegreeRange(roundedGpsHeading, 90, 180):
      return { degree: roundedGpsHeading, direction: `South ${Math.round(180 - roundedGpsHeading)}° East` };
    // South
    case (roundedGpsHeading === 180):
      return { degree: roundedGpsHeading, direction: `South` };
    // SouthWest
    case getGPSDegreeRange(roundedGpsHeading, 180, 270):
      return { degree: roundedGpsHeading, direction: `South ${Math.round(roundedGpsHeading - 180)}° West` };
    // West
    case (roundedGpsHeading === 270):
      return { degree: roundedGpsHeading, direction: `West` };
    // WestNorth
    case getGPSDegreeRange(roundedGpsHeading, 270, 360):
      return { degree: roundedGpsHeading, direction: `North ${Math.round(360 - roundedGpsHeading)}° West` };
    // Stand
    case (roundedGpsHeading === 360):
      return { degree: roundedGpsHeading, direction: `North` };
  }
}

/**
 * @memberof module:GPS
 * 
 * @param {*} deg 
 * 
 * @returns 
 */
const toRad = (deg) => {
  return deg * Math.PI / 180;
};

/**
 * @memberof module:GPS
 * 
 * @param {*} rad 
 * 
 * @returns 
 */
const toDeg = (rad) => {
  return rad * 180 / Math.PI;
};

/**
 * @memberof module:GPS
 * 
 * @param {*} lat1 
 * @param {*} lng1 
 * @param {*} lat2 
 * @param {*} lng2 
 * 
 * @returns 
 */
const getBearing = (lat1, lng1, lat2, lng2) => {
  var dLon = (lng2 - lng1);
  var dLat = (lat2 - lat1);
  var distance = Math.sqrt(dLat * dLat + dLon * dLon);
  if (distance > 0.0001) {
    var dLonRad = toRad(dLon);
    var dLatRad = toRad(dLat);
    var y = Math.sin(dLonRad) * Math.cos(toRad(lat2));
    var x = Math.cos(toRad(lat1)) * Math.sin(toRad(lat2)) - Math.sin(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.cos(dLonRad);
    var bearing = Math.round((toDeg(Math.atan2(y, x)) + 360) % 360);
    if (bearing === 0) return 360;
    return bearing;
  }
  return 0;
}

/**
 * @memberof module:GPS
 * 
 * @param {*} drivingBool 
 * @param {*} speedInt 
 * 
 * @returns 
 */
const isIdling = (drivingBool, speedInt) => {
  return (drivingBool && !speedInt);
}

/**
 * @param {array} startLngLat Path origin longitude, latitude array
 * @param {array} endLngLat Path destination longitude, latitude array
 * @param {number} percentageComplete Linear displacement along path from origin, Range: [0, 100]
 * @returns {array} Longitude, latitude array that is percentageComplete along straight line path
 */
const pointAlongPath = (startLngLat, endLngLat, percentageComplete) => {
  const lng = (startLngLat[0]) + ((endLngLat[0] - startLngLat[0]) * (percentageComplete / 100));
  const lat = (startLngLat[1]) + ((endLngLat[1] - startLngLat[1]) * (percentageComplete / 100));
  return {
    type: 'Point',
    coordinates: [lng, lat],
  };
}


const interpolateGPSPoint = (pathWaypointLngLatArr, totalDurationMs, elapsedMs) => {
  // Get the correct waypoint
  const pathWaypointLngLatArrLen = pathWaypointLngLatArr.length;
  const fractionCompletedAlongEntirePath = elapsedMs / totalDurationMs;
  const waypointIter = Math.min(Math.floor(fractionCompletedAlongEntirePath * pathWaypointLngLatArrLen), pathWaypointLngLatArr.length - 1);

  // Project Between Waypoints (straight line)
  const fractionCompletedBetweenWaypoints = (fractionCompletedAlongEntirePath - (waypointIter / pathWaypointLngLatArrLen)) * pathWaypointLngLatArrLen;

  // Get Bearing
  try {
    const { type, coordinates } = pointAlongPath(pathWaypointLngLatArr[waypointIter], pathWaypointLngLatArr[waypointIter + 1], fractionCompletedBetweenWaypoints * 100);
    const bearing = getBearing(coordinates[1], coordinates[0], pathWaypointLngLatArr[waypointIter + 1][1], pathWaypointLngLatArr[waypointIter + 1][0]);
    return ({
      coordinates,
      bearing
    });
  } catch (e) {
    console.log(e);
    return ({
      coordinates: [null, null],
      bearing: 0,
    });
  }
}

export {
  getGPSDirection,
  getBearing,
  isIdling,
  pointAlongPath,
  interpolateGPSPoint,
}