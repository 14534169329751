import React from 'react';
import PropTypes from 'prop-types';

//Components
import SBSelect from 'components/Shared/SBSelect/SBSelect';

//enums 
import { StateProvince } from 'enums/StateProvince';


class StateProvinceSelector extends React.Component{
  constructor(props){
    super(props);
  }

  render() {
    const { props, state } = this;
    // let filteredCountryKeys  = keys.filter( _key =>{
    //   if(props.country) return StateProvince[_key].country === props.country;
    //   return true;
    // });

    const filteredCountryKeys = Object.keys(StateProvince).filter(stateProvinceKey => {
      if (!props.countries || props.countries.length < 1) { return true; }
      let stateProvincedWillShow = false;
      props.countries.map(country => {
        if (StateProvince[stateProvinceKey].country === country) stateProvincedWillShow = true;
        return;
      });
      return stateProvincedWillShow;
    });

    const stateOptions = filteredCountryKeys.map(filtedKey =>{
      return { key: filtedKey, label: StateProvince[filtedKey].name, value: filtedKey, onClick: () => { props.onSelectedItem(StateProvince[filtedKey]) } }
    });


    return(

      <SBSelect
        containerClassName = {props.containerClassName}
        items = { stateOptions }
        multiSelectable = { props.multiSelectable }
        className = { props.innerClassName }
        defaultToggleText = { props.defaultToggleText ? props.defaultToggleText : 'States/Provinces'}
        showFilter = { props.showFilter }
        disabled = { props.disabled }
        isAutocomplete={props.isAutocomplete}
        autocompleteLabel={props.autocompleteLabel ? props.autocompleteLabel : ''}
        placeholder={props.placeholder ? props.placeholder : ''}
        onValueChange = {(value) => props.getValue(value)}
      />
    );
  }
}

StateProvinceSelector.propTypes = {
  containerClassName: PropTypes.string,
  // country: PropTypes.string, // country code: 'CA','US','MX'
  countries: PropTypes.array, // [] countryCode array
  disabled: PropTypes.bool,
  innerClassName: PropTypes.string,
  multiSelectable: PropTypes.bool,
  onSelectedItem: PropTypes.func,
  showFilter: PropTypes.bool,
  defaultToggleText: PropTypes.string,
  isAutocomplete: PropTypes.bool,
  autocompleteLabel: PropTypes.string,
  placeholder: PropTypes.string
}

StateProvinceSelector.defaultProps = {
  disabled: false,
  multiSelectable: false,
  showFilter: false
}

export default StateProvinceSelector;