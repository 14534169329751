import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// API
import * as IFTA from 'api/IFTA';
import { StateProvinces } from 'api/Lists/StateProvinces';
import * as PDF from 'api/PDFer';
import * as Helpers from 'api/Helpers';
import { getPDispatcherPropertyFromState } from 'api/Getters';

// Components
import { MDBBtn } from 'mdbreact';
import VehicleSummary from 'components/IFTARoute/view/VehicleSummary';
import SBSelect from 'components/Shared/SBSelect/SBSelect';

class DayLogsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      printInProgress: false,
      downloadCsvInProgress: false,
      distanceUnitFilter: getPDispatcherPropertyFromState('distanceUnit') === 'km' ?
      {
        key: 0,
        value: 'km',
        label: 'km',
      }
      :
      {
        key: 1,
        value: 'mi',
        label: 'mi',
      },
      distanceUnitFilterItems: [
        {
          key: 0,
          value: 'km',
          label: 'km',
        },
        {
          key: 1,
          value: 'mi',
          label: 'mi',
        },
      ],
      vehicleOdometerReadingsObj: {},
      dates: [],
    };

    this.printReport = this.printReport.bind(this);
  }

  componentDidMount() {
    let date = moment(this.props.dateStart).startOf('month').toDate();
    const monthDates = [date];
    let i = 0;
    while (moment(date).startOf('month').isSame(moment(monthDates[0])) && i < 40) {
      const dateMoment = moment(date);
      console.log(dateMoment.toDate());
      console.log(dateMoment.startOf('month').toDate());
      console.log(moment(monthDates[0]).toDate());
      date = moment(date).add(1, 'day').toDate();
      monthDates.push(dateMoment.toDate());
      console.log(i);
      i += 1;
    }
    console.log(monthDates);
  }

  printReport = () => {
    // let reportHTML;
    // let reportName;
    // this.setState({ ...this.state, printInProgress: true });
    // reportHTML = document.querySelector('.vehicleSummary-printable').outerHTML;
    // reportName = `Total Mileage Summary`;
    // PDF.generatePDFFromHTML(reportHTML, reportName, { orientation: 'landscape' }).then(
    //   tempFile => {
    //     const reportURL = tempFile.get('file')._url;
    //     Helpers.openDocumentLink(reportURL);
    //     this.setState({ ...this.state, printInProgress: false });
    //   }
    // );
  }

  downloadCsv = () => {
    // this.setState({ ...this.state, downloadCsvInProgress: true });

    // let csvString = `Unit ID,State/Province,Total Calculated Mileage (${this.state.distanceUnitFilter.value}),Spread Mileage (${this.state.distanceUnitFilter.value})\n`;
    // const stateProvinceCodes = StateProvinces.map((stateProvince) => stateProvince.code).filter((stateProvinceCode) => stateProvinceCode);
    // for (let i = 0; i < this.props.vehicleMileagesArr.length; i++) {
    //   const vehicleMileagesObj = this.props.vehicleMileagesArr[i];
    //   let totalSavedKm = 0;
    //   const odometerReadings = this.state.vehicleOdometerReadingsObj[vehicleMileagesObj.unitId];
    //   let odometerDiff;
    //   if (odometerReadings && odometerReadings.odometerEnd && odometerReadings.odometerStart) {
    //     odometerDiff = odometerReadings.odometerEnd - odometerReadings.odometerStart;
    //   }
    //   const iftaRouteArr = vehicleMileagesObj.iftaRouteArr;
    //   const iftaArrMiles = {};
    //   for (let j = 0; j < iftaRouteArr.length; j++) {
    //     const iftaRoute = iftaRouteArr[j];
    //     const stateProvince = iftaRoute.get('stateProvince').toUpperCase();
    //     const savedVehicleKm = iftaRoute.get('savedVehicleKm');
    //     totalSavedKm += savedVehicleKm;
    //     if (iftaArrMiles[stateProvince]) {
    //       iftaArrMiles[stateProvince] += savedVehicleKm;
    //     } else {
    //       iftaArrMiles[stateProvince] = savedVehicleKm || 0;
    //     }
    //   }
    //   for (let j = 0; j < stateProvinceCodes.length; j++) {
    //     const stateProvince = stateProvinceCodes[j];
    //     if (iftaArrMiles[stateProvince]) {
    //       const stateProvinceSavedKm = (Math.round(iftaArrMiles[stateProvince] * 100) / 100).toFixed(0);
    //       const spreadKm = (stateProvinceSavedKm && totalSavedKm && odometerDiff) && (Math.round((stateProvinceSavedKm / totalSavedKm) * odometerDiff * 100) / 100).toFixed(0);
    //       csvString += `"${this.props.vehicleMileagesArr[i].unitId}",`;
    //       csvString += `"${stateProvince}",`;
    //       csvString += `"${(this.state.distanceUnitFilter.value === 'km' ? stateProvinceSavedKm : Helpers.convertDistance(stateProvinceSavedKm, 'km', 'mi', true).toFixed(0))}",`;
    //       csvString += `"${(this.state.distanceUnitFilter.value === 'km' ? spreadKm : Helpers.convertDistance(spreadKm, 'km', 'mi', true).toFixed(0))}"\n`;
    //     }
    //   }
    //   // csvString += `"${this.props.vehicleMileagesArr[i].unitId}",`;
    //   // csvString += `"TOTAL",`;
    //   // csvString += `"${totalKm}"\n`; //THERE IS A BUG WHERE IT ONLY SHOWS KM TOTALS
    // }

    // Helpers.createCsvFile(`Switchboard - IFTA Mileage Summary`, csvString, true);
    // this.setState({ ...this.state, downloadCsvInProgress: false });
  }

  render() {
    return (
      <div>
        <div style={{ margin: '1em' }}>
          <SBSelect
            containerClassName="ml-2 mr-2 d-inline-block"
            className="sb-jobs-list-select"
            defaultToggleText={this.state.distanceUnitFilter.label}
            items={this.state.distanceUnitFilterItems}
            getSelectedItems={(selectedFilter) => this.setState({ ...this.state, distanceUnitFilter: selectedFilter[0] })}
            showFilter
          />
          <MDBBtn
            size="sm"
            color="secondary"
            disabled={this.state.printInProgress}
            onClick={() => this.printReport()}
            style={{ marginRight: '.5em' }}
          >
            Print Report
          </MDBBtn>
          {/* <MDBBtn
            size="sm"
            color="secondary"
            disabled={this.state.downloadCsvInProgress}
            onClick={() => this.downloadCsv()}
            style={{ marginRight: '.5em', width: '14em' }}
          >
            Download CSV
          </MDBBtn> */}
        </div>
        <div className='vehicleSummary-printable'>
          {/* <DayLogsTable
            unitId={this.props.unitId}
            date={this.props.dateStart}
          /> */}
        </div>
      </div>
    );
  }
};

export default DayLogsTable;
