import * as ActionConstants from './ActionConstants';
import store from 'store';
import * as ELD from 'api/ELD';

// Note there is no update functionality here as updates must be approved by driver before displaying permanent changes

export function fetchingELDEvents() {
  return {
    type: ActionConstants.FETCH_ELDEVENTS_INPROGRESS,
  };
}

export function fetchELDEventsSuccess(eldEventsInfo) {
  return {
    type: ActionConstants.FETCH_ELDEVENTS_SUCCESS,
    eldEventsInfo,
  };
}

export function fetchELDEventsError() {
  return {
    type: ActionConstants.FETCH_ELDEVENTS_ERROR,
  };
}

// Adding functionality comes from when we get updates from db, found in notifications
export function deleteELDEvents() {
  return {
    type: ActionConstants.DELETE_ELDEVENTS,
  };
}

export function fetchELDEventsForState(driver, filter, timezoneOffsetFromUTC) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingELDEvents());

    ELD.getDriverELDEvents(driver, filter, store.getState().Dispatcher.dispatcher, timezoneOffsetFromUTC).then(
      eldEventsInfo => {
        store.dispatch(fetchELDEventsSuccess(eldEventsInfo));
        resolve(eldEventsInfo);
      },
      error => {
        store.dispatch(fetchELDEventsError(error));
        reject(error);
      }
    );
  });
  return promise;
}

export function deleteELDEventsForState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteELDEvents());
    resolve(store.getState().ELDEvent);
  });
  return promise;
}
