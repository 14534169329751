import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "../../../sbHistory";
import { connect } from 'react-redux';

// API
import * as SI from 'api/DriverPatterns/SpeedingIdling';
import { getDrivers } from 'api/Driver/Driver';
import { getCurrentUser } from 'api/Getters';
import { isStringEmpty, formatName, convertDistance, formatDurationString } from 'api/Helpers';

import { AttributeTypes } from 'enums/Driver';
import { QuerySortOrderTypes } from 'enums/Query';

import Sort from 'sbObjects/Sort';


// Components
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import ListSelectField from 'components/ListSelectField/view/ListSelectField';
import LazyLoadingTable from 'components/LazyLoadingTable/view/LazyLoadingTable';
import { MDBRow, MDBCol } from 'mdbreact';

import TextField from 'material-ui/TextField';
import CircularProgress from 'material-ui/CircularProgress';
import DatePicker from 'material-ui/DatePicker';
import { Card, CardActions, CardHeader, CardMedia, CardTitle, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';


class DriverRankingsTable extends React.Component {

  constructor(props) {
    super(props);

    const lastWeek = moment().subtract(1, 'week');
    this.state = {
      isLoading: false,
      isDriversFetched: false,
      driverQuery: {
        filter: [],
        sortBy: new Sort(AttributeTypes.USER_FULLNAME, QuerySortOrderTypes.ASCENDING),
      },
      drivers: [],
      intervalTypes: {
        0: 'Week',
        1: 'Month',
      },
      selectedIntervalType: 0,
      dataInterval: {
        startDateTime: moment(lastWeek).startOf('isoWeek').toDate(), // local
        endDateTime: moment(lastWeek).endOf('isoWeek').toDate(),
      },
      speedIdlingDataByDriver: {},
      rankingTypes: {
        0: 'Speeding Time',
        1: 'Idling Time',
        2: 'Speeding (% of Driving)',
        3: 'Idling (% of Driving)',
      },
      selectedRankingType: 3,
      driverListingOrder: [], // array of {driverObjectId, rank} sorted by rank to determine when to list the respective driver in speedIdlingDataByDriver in our ranking table
      userFullNameSubstr: '', // string used to filter driverListingOrder by driverListingOrder.driver.user_fullName
    };

    this.handleIntervalTypeChange = this.handleIntervalTypeChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.getDriverPatternDurations = this.getDriverPatternDurations.bind(this);
    this.getDriverSpeedIdlingSummaries = this.getDriverSpeedIdlingSummaries.bind(this);
    this.handleRankingTypeChange = this.handleRankingTypeChange.bind(this);
    this.rankDrivers = this.rankDrivers.bind(this);
    this.handleFilterDriverListingOrderByDriverName = this.handleFilterDriverListingOrderByDriverName.bind(this);
  }

  componentDidMount() {
    this.getDriverSpeedIdlingSummaries();
  }

  // componentWillReceiveProps(nextProps) {

  // }

  handleIntervalTypeChange(intervalTypeName) {
    const { intervalTypes, dataInterval } = this.state;
    const newState = { ...this.state };
    newState.selectedIntervalType = Object.keys(intervalTypes).filter(type => {
      return intervalTypes[type] === intervalTypeName;
    })[0];
    newState.selectedIntervalType = parseInt(newState.selectedIntervalType);

    newState.dataInterval = { ...dataInterval };

    switch(newState.selectedIntervalType) {
      case 0:
        newState.dataInterval.endDateTime = moment(newState.dataInterval.startDateTime).add(1, 'week').subtract(1, 'millisecond');
        break;
      case 1:
        newState.dataInterval.endDateTime = moment(newState.dataInterval.startDateTime).add(1, 'month').subtract(1, 'millisecond');
        break;
    }

    newState.dataInterval.endDateTime = newState.dataInterval.endDateTime.toDate();

    this.setState(newState);
  }

  handleDateChange(date) {
    const newState = { ...this.state };
    newState.dataInterval = { startDateTime: date };

    switch(newState.selectedIntervalType) {
      case 0:
        newState.dataInterval.endDateTime = moment(newState.dataInterval.startDateTime).add(1, 'week').subtract(1, 'millisecond');
        break;
      case 1:
        newState.dataInterval.endDateTime = moment(newState.dataInterval.startDateTime).add(1, 'month').subtract(1, 'millisecond');
        break;
    }

    newState.dataInterval.endDateTime = newState.dataInterval.endDateTime.toDate();

    this.setState(newState);
  }

  async getDriverPatternDurations() {
    await this.setState({ isLoading: true });
    const { speedIdlingDataByDriver, dataInterval, driverListingOrder } = this.state;
    const drivers = Object.keys(speedIdlingDataByDriver).map(driverObjectId => {
      return speedIdlingDataByDriver[driverObjectId].driver;
    });

    const driverPatternDurationsByDriver = await SI.getDriverPatternDurations(drivers, dataInterval.startDateTime, dataInterval.endDateTime);
    const newState = { isLoading: false, driverListingOrder };
    newState.speedIdlingDataByDriver = { ...this.state.speedIdlingDataByDriver };

    Object.keys(driverPatternDurationsByDriver).map(driverObjectId => {
      if (newState.speedIdlingDataByDriver[driverObjectId]) {
        newState.speedIdlingDataByDriver[driverObjectId] = { ...this.state.speedIdlingDataByDriver[driverObjectId], ...driverPatternDurationsByDriver[driverObjectId] };
      }
    });

    // filter out those with no driving time
    newState.driverListingOrder = newState.driverListingOrder.filter(orderObject => {
      const speedIdlingDataByDriver = newState.speedIdlingDataByDriver[orderObject.driverObjectId];
      if (speedIdlingDataByDriver && !speedIdlingDataByDriver.drivingTimeMillis) {
        delete newState.speedIdlingDataByDriver[orderObject.driverObjectId];
      }
      if (speedIdlingDataByDriver) return speedIdlingDataByDriver.drivingTimeMillis;
    });

    this.setState(newState, this.rankDrivers);
  }

  async getDriverSpeedIdlingSummaries() {
    const { driverQuery } = this.state;
    this.setState({ isLoading: true, isDriversFetched: false });
    const { drivers, totalDriversCount } = await getDrivers(undefined, undefined, driverQuery.sortBy, driverQuery.filter, true, true);

    this.setState({ drivers });

    const newState = {};
    newState.speedIdlingDataByDriver = {};
    newState.driverListingOrder = [];

    drivers.map((driver, rank) => {
      if (!newState.speedIdlingDataByDriver[driver.objectId]) newState.speedIdlingDataByDriver[driver.objectId] = {
        driver,
        drivingTimeMillis: 0, // total driving time from all driverPatternDurations for driver in interval
        idleTimeMillis: 0,
        speedViolationMillis: 0,
        speedingToDrivingRatio: 0,
        idlingToDrivingRatio: 0,
        driverPatternDurations: [], // stores all DriverPatternDuration records for driver in interval
      };
      newState.driverListingOrder.push({ driverObjectId: driver.objectId, user_fullName: driver.user_fullName, rank });
    });

    this.setState(newState, this.getDriverPatternDurations);
  }

  handleRankingTypeChange(rankingTypeString) {
    const { rankingTypes } = this.state;
    const newState = { ...this.state };
    newState.selectedRankingType = Object.keys(rankingTypes).filter(key => {
      if (rankingTypes[key] === rankingTypeString) return true;
    })[0];
    newState.selectedRankingType = parseInt(newState.selectedRankingType);
    this.setState(newState, () => {
      this.rankDrivers();
    });
  }

  rankDrivers() {
    // given selectedRankingType and speedIdlingDataByDriver, rank drivers from best to worst accordingly
    this.setState({ isLoading: true, isDriversFetched: false }, () => {
      const { selectedRankingType, speedIdlingDataByDriver } = this.state;
      const newState = { ...this.state };

      setTimeout(() => {
        // fake progress to hide UI lag
        newState.isLoading = false;
        newState.driverListingOrder = [];
        newState.isDriversFetched = true;
        // first create an array of objects, based on each object in speedIdlingDataByDriver
        const speedIdlingDataByDriverArr = Object.keys(speedIdlingDataByDriver).map(driverObjectId => {
          return speedIdlingDataByDriver[driverObjectId];
        });

        // now sort this array based on selectedRankingType
        speedIdlingDataByDriverArr.sort((driverSpeedIdlingDataA, driverSpeedIdlingDataB) => {
          const driverSpeedIdlingDataASpeedingToDrivingRatio = driverSpeedIdlingDataA.speedingToDrivingRatio;
          const driverSpeedIdlingDataAIdlingToDrivingRatio = driverSpeedIdlingDataA.idlingToDrivingRatio;

          const driverSpeedIdlingDataBSpeedingToDrivingRatio = driverSpeedIdlingDataB.speedingToDrivingRatio;
          const driverSpeedIdlingDataBIdlingToDrivingRatio = driverSpeedIdlingDataB.idlingToDrivingRatio;


          if (selectedRankingType === 0) { // speeding
            return driverSpeedIdlingDataA.speedViolationMillis - driverSpeedIdlingDataB.speedViolationMillis;
          }
          if (selectedRankingType === 1) { // idling
            return driverSpeedIdlingDataA.idleTimeMillis - driverSpeedIdlingDataB.idleTimeMillis;
          }
          if (selectedRankingType === 2) { // speeding/driving
            if (driverSpeedIdlingDataASpeedingToDrivingRatio === driverSpeedIdlingDataBSpeedingToDrivingRatio) { // if the same, the one with less violation Millis is ranked ahead
              return driverSpeedIdlingDataA.speedViolationMillis - driverSpeedIdlingDataB.speedViolationMillis;
            }
            return driverSpeedIdlingDataASpeedingToDrivingRatio - driverSpeedIdlingDataBSpeedingToDrivingRatio;
          }
          if (selectedRankingType === 3) { // idling/driving
            if (driverSpeedIdlingDataAIdlingToDrivingRatio === driverSpeedIdlingDataBIdlingToDrivingRatio) {
              return driverSpeedIdlingDataA.idleTimeMillis - driverSpeedIdlingDataB.idleTimeMillis;
            }
            return driverSpeedIdlingDataAIdlingToDrivingRatio - driverSpeedIdlingDataBIdlingToDrivingRatio;
          }
        });

        // now that speedIdlingDataByDriverArr is sorted by given ranking, lets sort driverListingOrder according to speedIdlingDataByDriverArr
        let lastSeenRank = 1;
        newState.driverListingOrder = speedIdlingDataByDriverArr.map((speedIdlingDataByDriver, index) => {
          let rank = lastSeenRank;
          const previousSpeedIdlingDataByDriver = speedIdlingDataByDriverArr[index - 1];

          if (previousSpeedIdlingDataByDriver) {
            if ((selectedRankingType === 0) && (speedIdlingDataByDriver.speedViolationMillis !== previousSpeedIdlingDataByDriver.speedViolationMillis)) { // speeding
              rank++;
            }
            else if ((selectedRankingType === 1) && (speedIdlingDataByDriver.idleTimeMillis !== previousSpeedIdlingDataByDriver.idleTimeMillis)) { // idling
              rank++;
            }
            else if ((selectedRankingType === 2) && (speedIdlingDataByDriver.speedingToDrivingRatio !== previousSpeedIdlingDataByDriver.speedingToDrivingRatio)) { // speeding/driving
              rank++;
            }
            else if ((selectedRankingType === 3) && (speedIdlingDataByDriver.idlingToDrivingRatio !== previousSpeedIdlingDataByDriver.idlingToDrivingRatio)) { // idling/driving
              rank++;
            }
          }

          lastSeenRank = rank;

          return {
            driverObjectId: speedIdlingDataByDriver.driver.objectId,
            user_fullName: speedIdlingDataByDriver.driver.user_fullName,
            rank,
          }
        }).reverse();
        this.setState(newState);
      }, 300);

    });
  }

  handleFilterDriverListingOrderByDriverName(value) {
    // given string value input, filter the driverListingOrder by value substr on driver user_fullName
    let userFullNameSubstr = value;
    if (!isStringEmpty(userFullNameSubstr)) {
      userFullNameSubstr = userFullNameSubstr.trim();
    } else {
      userFullNameSubstr = '';
    }

    setTimeout(() => {
      this.setState({ ...this.state, userFullNameSubstr });
    }, 500);
  }


  render() {
    const { title, handleLazyLoad, height } = this.props;
    const { intervalTypes, selectedIntervalType, dataInterval, isLoading, isDriversFetched, speedIdlingDataByDriver, rankingTypes, selectedRankingType, driverListingOrder, userFullNameSubstr } = this.state;

    const intervalSelectorStyle = { marginRight: '.5em', width: '8em', textAlign: 'left' };
    const tableStyles = {
      rank: { width: '5%' },
      driverName: { width: '21%' },
      speeding: { width: '16%' },
      idling: { width: '16%' },
      driving: { width: '16%' },
      speedingToDriving: { width: '13%' },
      idlingToDriving: { width: '13%' },
    };
    const activeColumnStyle = {
      fontWeight: '600',
    };
    const columnStyle = {
      fontSize: '.85em',
    };
    // const columnCategoryStyle = {
    //   display: 'inline-block',
    //   width: '9.5em',
    // };

    const headerRows = [{ key: 'driverRankingsTableHeader', columns: [
      { element: <div>Rank</div>, props: { style: tableStyles.rank } },
      { element: <div>Driver</div>, props: { style: tableStyles.driverName } },
      { element: <div>Speeding Time</div>, props: { style: tableStyles.speeding } },
      { element: <div>Idling Time</div>, props: { style: tableStyles.idling } },
      { element: <div>Driving Time</div>, props: { style: tableStyles.driving } },
      { element: <div>Speed/Dr</div>, props: { style: tableStyles.speedingToDriving, tooltip: 'Speeding vs Driving' } },
      { element: <div>Idle/Dr</div>, props: { style: tableStyles.idlingToDriving, tooltip: 'Idling vs Driving' } },
    ]}];

    let bodyRows = [];

    if (driverListingOrder.length > 0) { // means we have drivers as well
      // first filter by userFullNameSubstr
      const driverListingOrderFiltered = driverListingOrder.filter(orderObject => {
        const driverFullName = orderObject.user_fullName;
        return driverFullName.indexOf(userFullNameSubstr) !== -1;
      });

      driverListingOrderFiltered.map((rankingObject, index) => {
        const driverObjectId = rankingObject.driverObjectId;
        const rank = rankingObject.rank || '-';
        const driverSpeedIdlingData = speedIdlingDataByDriver[driverObjectId];
        const driver = driverSpeedIdlingData.driver;
        const drivingTimeMillis = driverSpeedIdlingData.drivingTimeMillis;
        const speedViolationMillis = driverSpeedIdlingData.speedViolationMillis;
        const idleTimeMillis = driverSpeedIdlingData.idleTimeMillis;

        let speedingToDriving = driverSpeedIdlingData.speedingToDrivingRatio;
        let idlingToDriving = driverSpeedIdlingData.idlingToDrivingRatio;

        speedingToDriving = (speedingToDriving * 100).toFixed(2);
        idlingToDriving = (idlingToDriving * 100).toFixed(2);

        const columns = [
          {
            props: { style: tableStyles.rank },
            element: <div>{rank}</div>
          },
          {
            props: { style: tableStyles.driverName },
            element: <div>{driver.user_fullName}</div>
          },
          {
            props: { style: tableStyles.speeding },
            element: (
              <div style={(selectedRankingType === 0) ? activeColumnStyle : columnStyle}>
                <div>{ formatDurationString(speedViolationMillis) }</div>
              </div>
            )
          },
          {
            props: { style: tableStyles.idling },
            element: (
              <div style={(selectedRankingType === 1) ? activeColumnStyle : columnStyle}>
                <div>{ formatDurationString(idleTimeMillis) }</div>
              </div>
            )
          },
          {
            props: { style: tableStyles.driving },
            element: (
              <div style={columnStyle}>
                <div>{ formatDurationString(drivingTimeMillis) }</div>
              </div>
            )
          },
          {
            props: { style: tableStyles.speedingToDriving },
            element: (
              <div style={(selectedRankingType === 2) ? activeColumnStyle : columnStyle}>
                <div>{ (speedingToDriving || '0.00') + '%' }</div>
              </div>
            )
          },
          {
            props: { style: tableStyles.idlingToDriving },
            element: (
              <div style={(selectedRankingType === 3) ? activeColumnStyle : columnStyle}>
                <div>{ (idlingToDriving || '0.00') + '%' }</div>
              </div>
            )
          },
        ];

        bodyRows.push({
          key: driver.id,
          props: {
            id: driver.id,
            selectable: false,
          },
          columns,
        });
      });
    }

    /**
     * NOTES BLOCK
     */
    const blockMessages = [
      <div>
        <div style={{ fontWeight: '400' }}>Speed/Dr:&nbsp;</div>
        <div className="d-inline-block">
          <div>Time spent speeding as a % of driving within the interval - <span style={{ fontWeight: '400' }}>Higher % is worse</span></div>
          <div>Indicates how often your drivers are exceeding the speed limit, therefore increasing wear and tear</div>
          <div>on your vehicle, and wasting fuel, ultimately costing you money.</div>
        </div>
      </div>,
      <div>
        <div style={{ fontWeight: '400' }}>Idle/Dr:&nbsp;</div>
        <div className="d-inline-block">
          <div>Time spent idling as a % of driving within the interval - <span style={{ fontWeight: '400' }}>Higher % is worse</span></div>
          <div>Indicates how often your drivers are running their engines when they could be turned off, increasing your fuel costs.</div>
        </div>
      </div>,
    ];


    return (
      <div>
        <SBBlock
          title="Summary Notes"
          header={
            <div>
              <div>Using data collected by Switchboard, you are shown drivers with a history of speeding and idling within the given interval to our best approximations</div>
              <div style={{ marginTop: '.25em' }}>
                <span className="font-weight-500">*</span> The best indicators of good and bad driving behavior are <span className="font-weight-500">Speed/Dr</span> and <span className="font-weight-500">Idle/Dr</span>
              </div>
            </div>
          }
          messages={blockMessages}
          listMessages={true}
        />

        <Card className="mb-4">
          <CardHeader title="Select Data Interval" />
          <CardText>
            { !isLoading &&
              <div>
                <div className="inlineBlock" style={{ verticalAlign: 'middle' }}>
                  <ListSelectField
                    onChange={(e, index, intervalTypeName) => this.handleIntervalTypeChange(intervalTypeName)}
                    floatingLabelText="Interval"
                    value={intervalTypes[selectedIntervalType]}
                    maxHeight={200}
                    list={Object.keys(intervalTypes).map(type => intervalTypes[type])}
                    style={{ ...intervalSelectorStyle, width: '7em' }}
                  />
                </div>

                <div className="inlineBlock" style={{ verticalAlign: 'middle', top: '-1em', marginTop: '.5em', paddingTop: '3em' }}>
                  <DatePicker
                    id="intervalStart"
                    hintText={moment(dataInterval.startDateTime).format('MMM DD YYYY')}
                    value={dataInterval.startDateTime}
                    onChange={(e, date) => this.handleDateChange(date)}
                    formatDate={(date) => moment(date).format('MMM DD, YYYY')}
                    style={{ display: 'inline-block' }}
                    textFieldStyle={{ width: '8em' }}
                    mode="landscape"
                  />

                  <div className="inlineBlock">
                    <RaisedButton
                      key="filterByDate"
                      label="Confirm"
                      onClick={() => this.getDriverSpeedIdlingSummaries()}
                      primary
                    />
                  </div>
                </div>
              </div>
            }

            { isLoading && (
              <MDBRow>
                <MDBCol className="text-left" sm="6">
                  <CircularProgress />
                </MDBCol>
                <MDBCol className="text-right" sm="6">
                  {selectedIntervalType
                    ? 'Loading may take a minute'
                    : 'Loading may take some seconds' }
                </MDBCol>
              </MDBRow>
            )}

            <div style={{ marginTop: '.5em' }}>
              Currently Selected Interval: <b>{ moment(dataInterval.startDateTime).format('MMMM DD YYYY') } - { moment(dataInterval.endDateTime).format('MMMM DD YYYY') }</b>
            </div>
          </CardText>
        </Card>

        { isDriversFetched &&
          <Card className="mb-4">
            <CardHeader title="Rankings" />
            <CardText>
              <div>
                <ListSelectField
                  onChange={(e, index, rankingTypeString) => this.handleRankingTypeChange(rankingTypeString)}
                  floatingLabelText="Driver Ranking By"
                  value={rankingTypes[selectedRankingType]}
                  maxHeight={200}
                  list={Object.keys(rankingTypes).map(rankingTypeInt => rankingTypes[rankingTypeInt])}
                  disabled={isLoading}
                  style={{ display: 'inline-block', top: '-1em', width: '15em', textAlign: 'left' }}
                  />

                  <TextField
                    id="driverFilterTextField"
                    defaultValue={''}
                    hintText="Filter by Driver Name"
                    onChange={(e, value) => this.handleFilterDriverListingOrderByDriverName(value)}
                    disabled={isLoading}
                    style={{ display: 'inline-block', top: '-2.05em', marginLeft: '1em', width: '15em', textAlign: 'left' }}
                  />
              </div>

              <LazyLoadingTable
                height={height}
                tableHeaderRows={headerRows}
                tableBodyRows={bodyRows}
                selectable={false}
                multiSelectable={false}
                enableSelectAll={false}
                showCheckboxes={false}
              />
            </CardText>
          </Card>
        }
      </div>
    );
  }
}


DriverRankingsTable.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  isLazyLoading: PropTypes.bool,
  height: PropTypes.any,
  handleLazyLoad: PropTypes.func,
  startDateTimeUTC: PropTypes.instanceOf(Date),
};

export default DriverRankingsTable;
