import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';

// API
import { Months } from 'api/Lists/Months';

// Components
import Title from 'components/LayoutTitle/view/Title';
import FilterForm from 'components/FilterForm/container/FilterForm';

class CarrierPointsGraph extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      displayFilterForm: false,
    };
    this.showFilterForm = this.showFilterForm.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.state.filterFields = [
      // { attrName: 'category', fullName: 'Category', placeholder: '.....', type: 'text ' },
      { attrName: 'fromDate', fullName: 'From', placeholder: '.....', type: 'date' },
      { attrName: 'toDate', fullName: 'To', placeholder: '.....', type: 'date' },
    ];
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.carrierProfileObjects !== this.props.carrierProfileObjects) {
      return true;
    }
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.carrierProfileObject) && (nextProps.carrierProfileObject !== this.props.carrierProfileObject)) {
      // retrieve and correspond the scores of the past 12 months respectively (in order), for data
      const months = [];
      const data = [];

      for (let i = 0; i < Months.length; i++) {
        const month = Months[i];
        for (let j = 0; j < nextProps.carrierProfileObjects.length; j++) {
          const carrierProfileObject = nextProps.carrierProfileObjects[j];
          if (i === carrierProfileObject.get('carrierScoreDate').getMonth()) {
            // if the index of our month corresponds with the index of getMonth
            months.push(month);
            data.push(parseFloat(carrierProfileObject.get('carrierScore')));
          }
        }
      }

      this.state.chartData = {
        labels: months,
        datasets: [
          {
            label: 'Profile Scores by Month',
            fill: false,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10,
            data,
          }
        ]
      };

    }
  }

  showFilterForm() {
    this.setState({ ...this.state, displayFilterForm: !this.state.displayFilterForm });
  }

  filterSubmit() {

  }

  clearFilter() {

  }

  render() {
    return (
      <div>
        <Title className="h3" title="Monthly Points Trend">
          {/* <button className="buttonDefault" onClick={this.showFilterForm}>Filter</button> */}
        </Title>
        {this.state.displayFilterForm &&
          <span>
            <FilterForm
              className="floatRight"
              handleFilter={this.filterSubmit}
              clearFilter={this.clearFilter}
              fields={this.state.filterFields}
            />
            <div className="clearBoth" />
          </span>
        }

        {!this.state.chartData &&
          <div className="subTable">
            <Table responsive hover>
              <tbody>
                <tr>
                  <td>Upload a Carrier Profile to begin seeing trends</td>
                </tr>
              </tbody>
            </Table>
          </div>
        }

        {this.state.chartData &&
          <Line
              data={this.state.chartData}
              width={100}
              height={50}
              options={{
                  maintainAspectRatio: false
              }}
          />
        }
      </div>
    );
  }
}

export default CarrierPointsGraph;
