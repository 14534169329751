import React from 'react';
import PropTypes from 'prop-types';

// Components
import HelpDialog from 'components/HelpDialog/container/HelpDialog';

class IFTAHelpDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const uListStyle = { marginTop: '1em', paddingLeft: '1.2em', fontSize: '.85em' };
    const listStyle = { marginBottom: '.5em' };

    const subUListStyle = { ...uListStyle, paddingBottom: '1em', fontSize: '1em' };
    const subListStyle = { marginBottom: '2em' };

    return (
      <HelpDialog title="IFTA Reports" buttonStyle={this.props.buttonStyle}>
        <div style={{ marginTop: '1em', marginBottom: '2em' }}>
          How does our IFTA work?

          <div style={{ marginTop: '1em', fontSize: '.85em' }}>
            Using data logged from your drivers and vehicles, we are able to make calculations and summaries to help with your fuel tax reporting
            <br /><br />
            We use a combination of GPS point calculations and odometer readings from your vehicles, in addition to any fuel cards you
            want to apply, to generate data and printable reports. Further, you are able to edit this data and fuel/mileage calculations to suit your reporting needs
            <br /><br />
            Note: Our reports are not legal financial documents nor are they replacements for them
            <br /><br />
            Below is a summary of how to use this feature...
            <br /><br />

            <ul style={{ ...uListStyle, fontSize: '1em' }}>
              <li style={listStyle}>
                You can begin generating your IFTA report by first starting at the <b>Generate IFTA Data</b> tab on the left sidebar
              </li>
              <li style={listStyle}>
                Now select an interval for the data you want to view (for example, all data between January-March 2018 or just for March 2018)
              </li>
              <li style={listStyle}>
                Select a distance unit and fuel unit to view your data/reports in. For example, selecting Miles ('mi') will show your mileage data in miles when editing data and generating reports (mi = miles, km = kilometres). Similarly, fuel units include Gallons and Litres
              </li>
              <li style={listStyle}>
                After you are satisfied with the interval of data you want to view and the measurement units you want to view them in, select <b>Load</b>
              </li>
              <li style={listStyle}>
                Now, if you have any data between the interval you selected, you will be shown a summary of all your vehicles and their total fuel/distance data. This is known as the <b>Vehicle Totals Summary</b>. At this point you can do 3 things: Upload fuel cards to update that data by selecting <b>Apply Fuel Cards</b> and then choosing the fuel card type; Reset the number of gallons to 0 by selecting <b>Reset Gallons</b>; Or selecting <b>View/Edit Breakdown</b> for a vehicle to see how their totals were calculated and the ability to edit their fuel/distance information on your own. Editing this information only applies to reports. Any editing you do here does not affect anything other than the way you will see your reports
              </li>
              <li style={listStyle}>
                Note: Your report information is automatically saved. So every time you make a change, a save automatically happens
              </li>
              <li style={listStyle}>
                Going into a vehicle&#39;s breakdown, you can edit their fuel/distance information in addition to printing that individual vehicle&#39;s breakdown. To get a glimpse of some of the locations used to calculate the mileage click on the question mark beside a mileage number. To see the fuel card data you have uploaded that affect that particular mileage, click on the question mark beside the fuel quantity
              </li>
              <li style={listStyle}>
                After you are satisfied with all the information shown, you may want to view reports based on this information. Recall that the report will be shown for only data within the interval you selected. To do so, click on <b>View Reports</b> in the left sidebar, or <b>View Reports</b> on the same screen but on the top-right hand side
              </li>
              <li style={listStyle}>
                Here you will be greeted with the first type of report: A summary of all your State/Province information grouped by Vehicle according to the report data you were previously inspecting/editing in <b>Generate IFTA Data</b>
              </li>
              <li style={listStyle}>
                To view the other report types, simply click on the dropdown menu labelled <b>Report Type</b> on the right-hand side of the screen. If you don&#39;t see a style of report in the dropdown that you&#39;d like, it&#39;s likely we&#39;re working on it but feel free to contact us to let us know and we&#39;ll see what we can do for you!
              </li>
              <li style={listStyle}>
                Finally, to your interest, you can choose to Print the report you are currently viewing by selecting <b>Print Report</b> on the top right-hand side
              </li>
              <li style={listStyle}>
                To go back and make changes, simply click <b>Generate IFTA Data</b> on the left sidebar again, or click <b>Back</b> on the same screen but on the top-right hand side
              </li>
              <li style={listStyle}>
                Need help finding out distances between locations? Click on <b>Mileage Calculator</b> on the left sidebar and enter the desired locations
              </li>
            </ul>
          </div>
        </div>
      </HelpDialog>
    );
  }
}

IFTAHelpDialog.propTypes = {
  buttonStyle: PropTypes.object,
};

export default IFTAHelpDialog;
