import React from 'react'
import PropTypes from 'prop-types'
import Dialog from 'material-ui/Dialog'
import { MDBBtn } from 'mdbreact'

// CSS
import styles from './ConfirmModal.module.scss'

function ConfirmModal (props) {
  let actions = []

  if (!props.closeMode) {
    actions.unshift(
      <MDBBtn color='primary' size='sm' onClick={() => props.handleModalChoice(true)}>
        {props.primaryLabel || 'Confirm'}
      </MDBBtn>
    )
  }

  if (!props.alertMode) {
    actions.unshift(
      <MDBBtn color='default' size='sm' onClick={() => props.handleModalChoice(false)}>
        {props.secondaryLabel || 'Cancel'}
      </MDBBtn>
    )
  }
  const _className = props.className;
  return (
    <Dialog
      className={styles.modalBody + _className}
      title={props.title}
      actions={actions}
      modal={false}
      open={props.show}
      onRequestClose={props.handleClose}
      onKeyDown={props.handleKeyDown}
    >
      <input
        autoFocus
        style={{ width: '0', height: '0', outline: 'none', border: '0' }}
        onKeyDown={props.handleKeyDown}
      />
      {props.body ? props.body : 'Are you sure?'}
    </Dialog>
  )
}

ConfirmModal.propTypes = {
  title: PropTypes.string,
  handleKeyDown: PropTypes.func,
  handleModalChoice: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  body: PropTypes.node,
  alertMode: PropTypes.bool,
  closeMode: PropTypes.bool,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  className: PropTypes.string,
}

export default ConfirmModal
