import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './ChooseList.module.scss';

function TrailerList(props) {
  return (
    <div className="defaultTable" onScroll={(e) => this.scrollHandler(e)}>
      <Table responsive condensed hover>
        <thead className="translate-me">
          <tr>
            <th>
              Name
            </th>
            <th>
              Location
            </th>
          </tr>
        </thead>
        <tbody>
          { props.trailers && props.trailers.length > 0 && props.trailers.map((trailer) =>
            <tr className={styles.listRow} key={trailer.id} onClick={() => props.handleModalChoice(trailer)}>
              <td>{trailer.get('unitId')}</td>
              <td>{trailer.get('vendor') && trailer.get('vendor').get('name')}</td>
            </tr>
          )}
          { !props.loaded &&
            <tr>
              <td colSpan="42" className="loadingIconPadding"><LoadingIcon /></td>
            </tr>
          }
        </tbody>
      </Table>
    </div>
  );
}

TrailerList.propTypes = {
  trailers: PropTypes.array.isRequired,
  handleModalChoice: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  loaded: PropTypes.bool.isRequired,
};

export default TrailerList;
