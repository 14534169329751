import React from 'react';
import PropTypes from 'prop-types';
// CSS
import styles from './MapButton.module.scss';

function FitToVehiclesButton(props) {
  return (
    <div className={styles.container + " d-none d-lg-block"}>
      <button onClick={() => props.fitToVehicles()} className={`${styles.button} translate-me`}>Fit To Vehicles</button>
    </div>
  );
}

FitToVehiclesButton.propTypes = {
  fitToVehicles: PropTypes.func.isRequired,
};

export default FitToVehiclesButton;
