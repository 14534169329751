import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

// Actions

// API

// Components
import SimRow from './SimRow';

// Context
import StoreContext from 'contexts/StoreContext';

// CSS
// import styles from './SimTable.module.scss';

// Lists

class SimTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <Table
        height={'250'}
        fixedHeader
        selectable={false}
        multiSelectable={false}
      >
        <TableHeader
          displaySelectAll={false}
          adjustForCheckbox={false}
          enableSelectAll={false}
        >
          <TableRow>
            <TableHeaderColumn>
              Device IMEI
            </TableHeaderColumn>
            <TableHeaderColumn>
              ICCID
            </TableHeaderColumn>
            <TableHeaderColumn>
              Status
            </TableHeaderColumn>
          </TableRow>
        </TableHeader>

        <TableBody
          displayRowCheckbox={false}
          deselectOnClickaway={false}
          showRowHover={false}
          stripedRows={false}
        >
          {this.props.Subscription.subscription &&
            this.props.simArr.filter((simObj) => (simObj.get('iccid') || simObj.get('imei'))).map((simObj) => (
              <SimRow
                key={simObj.id}
                sim={simObj}
                subscriptionObjectId={this.props.Subscription.subscription.id}
              />
            )
            )}
        </TableBody>
      </Table>
    );
  }
}

SimTable.propTypes = {
  simArr: PropTypes.array.isRequired,
  Subscription: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Subscription } = state;
  return {
    Subscription,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(SimTable);

