import React from 'react';

// Components
import { Toolbar as PRToolbar } from 'primereact/toolbar';

// Styling
import './style.scss';

/**
 * @description PrimeReact Toolbar component
 * @param {String} className - Custom container className
 */
function Toolbar({ ...props }) {
  return (
    <PRToolbar {...props} />
  );
};

export default Toolbar;