import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

// Components
import AddEditButton from 'components/AddEditModal/container/AddEditButton';

// Actions
import { updateVehicleForState } from 'actions/Vehicle';
import * as ActionConstants from 'actions/ActionConstants';

// API
import { vehicleTypes } from 'api/Lists/EquipmentTypes';
import { StateProvinces } from 'api/Lists/StateProvinces';
import { assignDashcamHardwareToVehicle } from 'api/Equipment/Vehicle.old';
import { getOrCreateELDHardwareFromSerial } from 'api/ELD/ELDHardware/ELDHardware';

// Context
import StoreContext from 'contexts/StoreContext';

function hasValueChanged(oldValue, newValue) {
  if (oldValue !== newValue && !(!oldValue && !newValue)) {
    return true;
  }
  return false;
}

function getInitialState(props) {
  const initialState = {};
  const vehicle = props.vehicle;
  const licensePlate = vehicle && vehicle.get('licensePlate');
  const vehiclePlate = (licensePlate && licensePlate.get('plate')) || (vehicle.get('plate'));
  const plateJurisdiction = licensePlate && licensePlate.get('stateProvince');

  const stateProvinces = StateProvinces.map(stateProvince => stateProvince.name).concat('Other');

  initialState.editVehicleFormFields = [
    [
      { label: 'Unit ID', value: vehicle.get('unitId') || '', type: 'text', property: 'unitId', limit: '10' },
    ],
    [
      { label: 'MVI Decal #', value: vehicle.get('MVIDecalNumber') || '', type: 'text', property: 'MVIDecalNumber' },
      { label: 'Next Inspection Date', value: vehicle.get('nextInspectionDate') ? vehicle.get('nextInspectionDate') : null, type: 'date', property: 'nextInspectionDate' },
      { label: 'Inspection Frequency (months)', value: vehicle.get('inspectionFrequency') || '', type: 'number', property: 'inspectionFrequency' }
    ],
    [
      { label: 'VIN #', value: vehicle.get('vin') || '', type: 'text', property: 'vin' },
      { label: 'Type of Vehicle', value: vehicle.get('type') || '', type: 'select', property: 'type', selectArr: vehicleTypes },
    ],
    [
      { label: 'License Plate', required: true, value: vehiclePlate || '', type: 'text', property: 'plate' },
      { label: 'Plate Jurisdiction', required: true, value: plateJurisdiction && (StateProvinces.filter(stateProvince => plateJurisdiction === stateProvince.code).length > 0 ? StateProvinces.filter(stateProvince => plateJurisdiction === stateProvince.code)[0].name : plateJurisdiction) || '', type: 'select', property: 'plateJurisdiction', selectArr: stateProvinces },
    ],
    [
      { label: 'Engine Module Hardware ID', value: (vehicle.get('eldHardware') && vehicle.get('eldHardware').get('serial')) || '', type: 'text', property: 'eldHardwareSerial' },
      { label: 'Dashcam Hardware ID', value: (vehicle.get('visionVehicle') && vehicle.get('visionVehicle').get('dashcamHardwareId')) || '', type: 'text', property: 'dashcamHardwareId' },
    ],
    [
      { label: 'Notes', value: vehicle.get('notes') || '', type: 'textarea', property: 'notes' }
    ],
  ];
  return initialState;
}

class EditVehicleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState(props);
    this.editVehicle = this.editVehicle.bind(this);
    this.refreshState = this.refreshState.bind(this);
  }

  editVehicle(formValues) {
    const promise = new Promise(async (resolve, reject) => {
      const { vehicle } = this.props;
      const licensePlate = vehicle.get('licensePlate');
      const keys = Object.keys(formValues);
      const keysLen = keys.length;

      if (keysLen > 0) {
        for (let i = 0; i < keysLen; i++) {
          const currentKey = keys[i];
          if (currentKey === 'eldHardwareSerial') {
            const eldHardware = await getOrCreateELDHardwareFromSerial(formValues[currentKey]);
            vehicle.set('eldHardware', eldHardware);
          } else if (currentKey === 'dashcamHardwareId') {
            assignDashcamHardwareToVehicle(formValues[currentKey], vehicle.id).then((vehicle) => {
              resolve(vehicle);
            }).catch((error) => {
              reject(error);
            });
          } else if ((currentKey !== 'plateJurisdiction') && hasValueChanged(formValues[currentKey], vehicle.get(currentKey))) {
            vehicle.set(currentKey, formValues[currentKey]);

            if (currentKey === 'vin') {
              vehicle.set('lockVin', true);
            }
          }
        }

        if (hasValueChanged(formValues['plate'], licensePlate.get('plate'))) {
          licensePlate.set('plate', formValues['plate']);
        }

        let chosenPlateJurisdictionCode;
        if (formValues['plateJurisdiction']) {
          chosenPlateJurisdictionCode = StateProvinces.filter(
            stateProvince => {
              return stateProvince.name === formValues.plateJurisdiction;
            }
          ).length > 0 ?
            StateProvinces.filter(
              stateProvince => {
                return stateProvince.name === formValues.plateJurisdiction;
              }
            )[0].code
            :
            formValues.plateJurisdiction
            ;
        }
        if (hasValueChanged(chosenPlateJurisdictionCode, licensePlate.get('stateProvince'))) {
          licensePlate.set('stateProvince', chosenPlateJurisdictionCode);
        }

        licensePlate.save().then(
          () => {
            vehicle.save().then((updatedVehicle) => {
              resolve(updatedVehicle);
            });
          }
        );
      } else {
        resolve();
      }
    });
    return promise;
  }

  async refreshState() {
    await this.setState(getInitialState(this.props));
    if (this.props.refreshState) {
      await this.props.refreshState(this.props.vehicle);
    }
  }

  render() {
    return (
      <AddEditButton
        btnClass={this.props.btnClass}
        refreshState={this.refreshState}
        handleAddEdit={this.editVehicle}
        title={this.props.children ? this.props.children : 'Edit'}
        formTemplate={this.state.editVehicleFormFields}
        editingInProgress={this.props.Vehicle.status === ActionConstants.ADD_VEHICLE_INPROGRESS}
      />
    );
  }
}

EditVehicleButton.propTypes = {
  vehicle: PropTypes.object.isRequired,
  Vehicle: PropTypes.object.isRequired,
  children: PropTypes.node,
  btnClass: PropTypes.string,
  refreshState: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { Vehicle } = state;
  return {
    Vehicle,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(EditVehicleButton);
