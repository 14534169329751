import React, { useEffect, useState } from 'react';

// Components
import Sidebar from 'sbCore/Sidebar/Sidebar';

// Styles
import './style.scss';

/**
 * @description Shows a drawer that displays information
 * @param {bool} [isVisible] - Determines whether the drawer is initially visible or not
 * @param {String} [position] - Position of the drawer
 * @param {*} [children] - HTML that can be shown within the drawer
 *
 * @returns
 *
 * @example
 * <DispatchingDrawer isVisible={isVisible} />
 */
function DispatchingDrawer({ ...props }) {
  // ** useStates ** //
  const [isVisible, setIsVisible] = useState(false);

  // ** useEffects ** //
  useEffect(() => {
    if (!isVisible && props.onHide) props.onHide();
  }, [isVisible]);

  useEffect(() => {
    setIsVisible(props.isVisible);
  }, [props.isVisible]);

  // ** Callbacks ** //
  function onHide() {
    setIsVisible(false);
  }

  const className = 'dispatch-legs-drawer';

  return (
    <div className={className}>
      <Sidebar
        modal
        dismissable
        visible={isVisible}
        onHide={() => onHide()}
        position={props.position ? props.position : 'right'}
        className="p-sidebar-lg"
      >
        {props.children}
      </Sidebar>
    </div>
  );
}

export default DispatchingDrawer;
