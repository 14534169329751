import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';
import moment from 'moment-timezone';
import { Table } from 'react-bootstrap';

// API
import * as WSB from 'api/WeighStationBypass';
import { getCurrentUser } from 'api/Getters';
import { uploadImage } from 'api/Setters';
import { formatName } from 'api/Helpers';
import * as Analytics from 'api/Analytics';
import * as Parse from 'api/Parse';
import * as Email from 'api/Email/Email';
import {
  getCompanyAnalytics,
  updateCompanyAnalytics,
} from 'api/CompanyAnalytics/CompanyAnalytics';

// Actions
import { enableVehicle, disableVehicle, deleteVehicle } from 'actions/Vehicle';

// Components
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import EditVehicleButton from 'components/AddEditEquipment/container/EditVehicleButton';
import CircularProgress from 'material-ui/CircularProgress';
import Toggle from 'material-ui/Toggle';
import Title from 'components/LayoutTitle/view/Title';
import ToggleWeighStationBypassDialog from 'components/WeighStationBypass/view/ToggleWeighStationBypassDialog';
import FileUploader from 'components/FileUploader/container/FileUploader';
import WalkthroughSelectTip from 'components/WalkthroughSelectTip/container/WalkthroughSelectTip';
import WeighStationHelpDialog from 'components/WeighStationHelpDialog/view/WeighStationHelpDialog';

// CSS
import styles from './SummaryLayout.module.scss';

class SummaryLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showResetPasswordModal: false,
      resetPasswordSent: false,
      confirmDisableModalShow: false,
      openConfirmDeleteModal: false,
      openWeighStationBypassDialog: false,
      openWeighStationBypassConfirmDialog: false,
      openUploadVehicleImageDialog: false,
      walkthroughState: {
        vehicleImageUpload: false,
      },
      errors: {
        uploadVehicleImage: '',
      },
      isLoading: false,
      userHasUsedWeighStationBypass: false,
      companyAnalytics: undefined,
    };
    this.getWeighStationBypassVehicles = this.getWeighStationBypassVehicles.bind(this);
    this.disableVehicle = this.disableVehicle.bind(this);
    this.deleteVehicle = this.deleteVehicle.bind(this);
    this.openConfirmDisableModal = this.openConfirmDisableModal.bind(this);
    this.closeConfirmDisableModal = this.closeConfirmDisableModal.bind(this);
    this.openConfirmDeleteModal = this.openConfirmDeleteModal.bind(this);
    this.closeConfirmDeleteModal = this.closeConfirmDeleteModal.bind(this);
    this.toggleWeighStationBypassDialog = this.toggleWeighStationBypassDialog.bind(this);
    this.handleWeighStationBypassToggle = this.handleWeighStationBypassToggle.bind(this);
    this.handleVehicleImageUpload = this.handleVehicleImageUpload.bind(this);
    this.toggleUploadVehicleImageDialog = this.toggleUploadVehicleImageDialog.bind(this);
    this.handleWalkthroughToggle = this.handleWalkthroughToggle.bind(this);
  }

  async componentDidMount() {
    const companyAnalytics = await getCompanyAnalytics();

    const userSpecialPermission = getCurrentUser(true).userSpecialPermission;
    if (userSpecialPermission && userSpecialPermission.disableEquipmentModule) {
      history.push({ pathname: '404' });
    } else {
      this.setState({ companyAnalytics }, () => this.getWeighStationBypassVehicles());
    }
  }

  getWeighStationBypassVehicles() {
    // used to see if any weighstation bypass vehicles exist / ie. service was used before
    const promise = new Promise(resolve => {
      this.setState({ ...this.state, isLoading: true }, () => {
        WSB.getWeighStationBypassVehicles().then(
          weighStationBypassVehicles => {
            const newState = { ...this.state, isLoading: false };
            if (weighStationBypassVehicles.length > 0) {
              newState.userHasUsedWeighStationBypass = true;
            }
            this.setState(newState, () => resolve(weighStationBypassVehicles));
          }
        );
      });
    });
    return promise;
  }

  openConfirmDisableModal() {
    const newState = { ...this.state };
    newState.confirmDisableModalShow = true;
    this.setState(newState);
  }
  closeConfirmDisableModal() {
    const newState = { ...this.state };
    newState.confirmDisableModalShow = false;
    this.setState(newState);
  }

  openConfirmDeleteModal() {
    const newState = { ...this.state };
    newState.confirmDeleteModalShow = true;
    this.setState(newState);
  }
  closeConfirmDeleteModal() {
    const newState = { ...this.state };
    newState.confirmDeleteModalShow = false;
    this.setState(newState);
  }

  disableVehicle(confirmBool) {
    const newState = { ...this.state };
    newState.confirmDisableModalShow = false;
    this.setState(newState);
    if (confirmBool) {
      history.push('equipment');
      disableVehicle(this.props.vehicle);
    }
  }

  deleteVehicle(confirmBool) {
    const newState = { ...this.state };
    newState.confirmDeleteModalShow = false;
    this.setState(newState);
    if (confirmBool) {
      history.push('equipment');
      deleteVehicle(this.props.vehicle);
    }
  }

  async toggleWeighStationBypassDialog() {
    const { state } = this;
    if (state.companyAnalytics) {
      const companyAnalytics = state.companyAnalytics;
      const VIEWBYPASS = Parse.getAttribute(companyAnalytics, 'VIEWBYPASS');
      if (!VIEWBYPASS) {
        Analytics.track('Bypass-Dialog Viewed');
        await updateCompanyAnalytics(companyAnalytics, { VIEWBYPASS: true });
      }
    }

    const promise = new Promise(resolve => {
      this.setState({ ...this.state, openWeighStationBypassDialog: !this.state.openWeighStationBypassDialog }, () => resolve(true));
    });
    return promise;
  }

  async handleWeighStationBypassToggle(confirm) {
    if (confirm) {
      const { state } = this;
      if (state.companyAnalytics) {
        const companyAnalytics = state.companyAnalytics;
        const ENBYPASS = Parse.getAttribute(companyAnalytics, 'ENBYPASS');
        if (!ENBYPASS) {
          Analytics.track('Bypass Enabled');
          await updateCompanyAnalytics(companyAnalytics, { ENBYPASS: true });
        }
      }

      const { vehicle, refreshVehicleInfo, toggleWaitingState } = this.props;
      const weighStationBypassVehicle = vehicle.get('weighStationBypassVehicle');
      let enabled = !(weighStationBypassVehicle && weighStationBypassVehicle.get('enabled'));

      if (weighStationBypassVehicle && weighStationBypassVehicle.get('enabled') && !weighStationBypassVehicle.get('isSubscriptionProduction')) {
        enabled = true;
        if (!ENABLEDBYPASS) {
          Analytics.track('Bypass Enabled');
          await updateCompanyAnalytics(companyAnalytics, { ENABLEDBYPASS: true });
        }
      }

      const company = this.props.company;
      const user = Parse.getCurrentUser();


      Analytics.track('Weigh Station Bypass Interest');
      // Email.send(
      //   'Switchboard Weigh Station Bypass',
      //   'contact@onswitchboard.com',
      //   'sales@onswitchboard.com',
      //   `[Current Customer Interest] Weigh Station Bypass - ${company.get('name')}`,
      //   `Company ${company.get('name')} is interested in Weigh Station Bypass (from Switchboard Web App). Give them a call!

      //   Name: ${user.get('firstName')} ${user.get('lastName')}
      //   Phone Number: ${user.get('phoneNumberCleaned')}
      //   Email: ${user.get('email')}
      //   `,
      // )
      this.setState({ ...this.state, openWeighStationBypassConfirmDialog: true, openWeighStationBypassDialog: false });

      // this.toggleWeighStationBypassDialog().then(
      //   () => {
      //     toggleWaitingState().then(
      //       () => {
      //         WSB.handleWSBVehicleSubscription(vehicle, enabled).then(() => {
      //           this.getWeighStationBypassVehicles().then(
      //             () => refreshVehicleInfo(vehicle.id)
      //           );
      //         });
      //       }
      //     );
      //   }
      // );
    } else {
      await this.toggleWeighStationBypassDialog();
    }
  }

  toggleUploadVehicleImageDialog(confirmBool) {
    const { vehicle } = this.props;
    const { toggleWaitingState } = this.props;
    const newState = { ...this.state, openUploadVehicleImageDialog: !this.state.openUploadVehicleImageDialog };
    this.setState(newState, () => {
      if (confirmBool) {
        this.setState({ ...this.state, openUploadVehicleImageDialog: false }, () => {
          toggleWaitingState().then(
            () => {
              this.handleVehicleImageUpload(vehicle, vehicle.get('belongsToCompany').get('defaultVehicleImage'), true);
            }
          );
        });
      } else if (confirmBool === false) {
        this.setState(newState, () => {
          document.getElementById('vehicleImageUploader').click();
        });
      } else {
        this.setState(newState);
      }
    });
  }

  handleVehicleImageUpload(vehicle, file, isParseFile) {
    const { toggleWaitingState, refreshVehicleInfo } = this.props;

    const promise = new Promise(resolve => {
      toggleWaitingState().then(
        () => {
          if (!file) {
            vehicle.unset('vehicleImage').save().then(
              () => refreshVehicleInfo(vehicle.id)
            );
          } else if (isParseFile) {
            vehicle.set('vehicleImage', file).save().then(
              () => {
                // upload to weighStationBypass
                WSB.updateWSBVehicleImage(vehicle).then(() => refreshVehicleInfo(vehicle.id));
              }
            );
          } else {
            this.setState({ ...this.state, errors: { ...this.state.errors, uploadVehicleImage: '' } }, () => {
              uploadImage(vehicle, 'vehicleImage', file).then(
                () => {
                  // upload to weighStationBypass
                  WSB.updateWSBVehicleImage(vehicle).then(() => refreshVehicleInfo(vehicle.id));
                }
              );
            });
          }
        }
      );
    });
    return promise;
  }

  handleWalkthroughToggle(walkthroughStateAttribute) {
    const { walkthroughState } = this.state;
    const newState = { ...this.state };
    newState.walkthroughState = { ...walkthroughState };
    newState.walkthroughState[walkthroughStateAttribute] = !walkthroughState[walkthroughStateAttribute];
    this.setState(newState);
  }

  render() {
    const { vehicle, refreshVehicleInfo, company } = this.props;
    const { openWeighStationBypassDialog, openWeighStationBypassConfirmDialog, openUploadVehicleImageDialog, errors, isLoading, userHasUsedWeighStationBypass, walkthroughState } = this.state;

    const userSpecialPermission = getCurrentUser(true).userSpecialPermission;
    const disableEquipmentEditEquipment = userSpecialPermission && userSpecialPermission.disableEquipmentEditEquipment;
    const disableEquipmentDeleteEquipment = userSpecialPermission && userSpecialPermission.disableEquipmentDeleteEquipment;

    const weighStationBypassModuleEnabled = false;
    const wsbEnabledCompanyIds = {
      // 'SwnozcmKIf': true,
      // 'dH24kfl6JD': true,
      bLODxFlOED: true,
      '4O9qmruD6g': true,
      jYO3JxEwyd: true,
      dHDHhVZ6bp: true,
      jDQ65mijCz: true,
      sUEIUn4kHp: true,
      '0FHFFLZQs9': true,
    };

    const licensePlate = vehicle && vehicle.get('licensePlate');
    const vehiclePlate = (licensePlate && licensePlate.get('plate')) || (vehicle && vehicle.get('plate'));
    const plateJurisdiction = licensePlate && licensePlate.get('stateProvince');

    // const wsbEnabled = wsbEnabledCompanyIds[vehicle && vehicle.get('belongsToCompany').id];
    const wsbEnabled = true;

    const weighStationBypassVehicle = vehicle && vehicle.get('weighStationBypassVehicle');
    const weighStationBypassLabel = (weighStationBypassVehicle && (weighStationBypassVehicle.get('enabled'))) ? '(Enabled)' : '(Disabled)';

    const vehicleImage = vehicle && vehicle.get('vehicleImage');
    const defaultVehicleImage = vehicle && vehicle.get('belongsToCompany') && vehicle.get('belongsToCompany').get('defaultVehicleImage');

    let toggleWeighStationBypassDialogCallback = this.toggleWeighStationBypassDialog;
    if (vehicle && !vehicle.get('vehicleImage')) {
      toggleWeighStationBypassDialogCallback = this.toggleUploadVehicleImageDialog;
    }

    return (
      <div className={styles.summaryLayout}>
        <Title superClassName={styles.title} title={<span>Vehicle Summary</span>}>
          {vehicle ?
            <div>
              {!disableEquipmentEditEquipment &&
                <EditVehicleButton vehicle={vehicle} btnClass="float-right" refreshState={() => refreshVehicleInfo(vehicle.id)}>Edit Vehicle</EditVehicleButton>
              }
              {vehicle.get('enabled') &&
                <MDBBtn size="sm" color="danger" className="float-right" onClick={this.openConfirmDisableModal} style={{ backgroundColor: '#E03857 !important' }}>Disable</MDBBtn>
              }
              {!disableEquipmentDeleteEquipment && !vehicle.get('enabled') &&
                <span className="buttonSpacedRight">
                  <MDBBtn size="sm" className="float-right" color="danger" onClick={this.openConfirmDeleteModal} style={{ backgroundColor: '#E03857 !important' }}>Delete</MDBBtn>
                </span>
              }
              {!vehicle.get('enabled') &&
                <MDBBtn size="sm" color="primary" className="float-right" onClick={() => enableVehicle(vehicle).then((vehicleObj) => refreshVehicleInfo(null, vehicleObj))} >Enable</MDBBtn>
              }
              <ConfirmModal show={this.state.confirmDisableModalShow} handleModalChoice={this.disableVehicle} handleClose={this.closeConfirmDisableModal} alertMode />
              <ConfirmModal show={this.state.confirmDeleteModalShow} handleModalChoice={this.deleteVehicle} handleClose={this.closeConfirmDeleteModal} >
                <div> Are you sure you want to delete Vehicle: {formatName(vehicle.get('unitId'))} ?</div>
              </ConfirmModal>
            </div>
            :
            <div>
              <CircularProgress />
            </div>
          }

        </Title>
        <div>
          <div className={`subTable ${styles.vehicleDetailsInfoTable}`}>
            <Table responsive hover>
              <thead>
                <tr>
                  <th width="16%">Unit ID</th>
                  <th width="28%">License Plate</th>
                  <th width="28%">Plate Jurisdiction</th>
                  <th width="28%">VIN #</th>
                </tr>
              </thead>
              <tbody>
                {vehicle &&
                  <tr>
                    <td>{vehicle.get('unitId')}</td>
                    <td>{vehiclePlate || <span className={styles.alert}>N/A</span>}</td>
                    <td>{plateJurisdiction || <span className={styles.alert}>N/A</span>}</td>
                    <td>{vehicle.get('vin') || <span className={styles.alert}>N/A</span>}</td>
                  </tr>
                }
              </tbody>
            </Table>
          </div>
          <div className={`subTable ${styles.vehicleDetailsInfoTable}`}>
            <Table responsive hover>
              <thead>
                <tr>
                  <th width="16%">Type</th>
                  <th width="28%">
                    Weigh Station Bypass
                    <div className="inlineBlock" style={{ position: 'relative' }}>
                      <WeighStationHelpDialog buttonStyle={{ top: '.15em', marginLeft: '.8em', backgroundColor: 'rgba(0, 0, 0, .25)' }} />
                    </div>
                  </th>
                  <th width="28%">Next Inspection Date</th>
                  <th width="28%">MVI Decal Number</th>
                </tr>
              </thead>
              <tbody>
                {vehicle &&
                  <tr>
                    <td>{vehicle.get('type') || <span className={styles.alert}>N/A</span>}</td>
                    <td>
                      <Toggle
                        label={weighStationBypassLabel}
                        toggled={weighStationBypassVehicle && weighStationBypassVehicle.get('enabled') && weighStationBypassVehicle.get('isSubscriptionProduction')}
                        onToggle={() => toggleWeighStationBypassDialogCallback()}
                        labelPosition="right"
                        disabled={isLoading}
                      />
                    </td>
                    <td>
                      {vehicle.get('nextInspectionDate') && moment(vehicle.get('nextInspectionDate')).subtract(1, 'months').isBefore(moment()) &&
                        <span className={styles.alert}>{moment(vehicle.get('nextInspectionDate')).format('LL')}</span>
                      }
                      {vehicle.get('nextInspectionDate') && !moment(vehicle.get('nextInspectionDate')).subtract(1, 'months').isBefore(moment()) &&
                        <span>{moment(vehicle.get('nextInspectionDate')).format('LL')}</span>
                      }
                      {!vehicle.get('nextInspectionDate') &&
                        <span className={styles.alert}>No MVI Inspection</span>
                      }
                    </td>
                    <td>{vehicle.get('MVIDecalNumber') || <span className={styles.alert}>N/A</span>}</td>
                  </tr>
                }
              </tbody>
            </Table>
          </div>
          <div className={`subTable ${styles.vehicleDetailsInfoTable}`}>
            <Table responsive hover>
              <thead>
                <tr>
                  <th width="16%">Engine Module Serial</th>
                  {/*<th width="14%">Company</th>*/}
                  <th width="28%">Dashcam Hardware ID</th>
                  <th width="28%">Added</th>
                  <th width="28%"></th>
                </tr>
              </thead>
              <tbody>
                {vehicle &&
                  <tr>
                    <td>
                      {vehicle.get('eldHardware') ? vehicle.get('eldHardware').get('serial') : 'N/A'}
                    </td>
                    <td>
                      {vehicle.get('visionVehicle') ? vehicle.get('visionVehicle').get('dashcamHardwareId') : 'N/A'}
                    </td>
                    <td>
                      <span>{moment(vehicle.get('createdAt')).format('MMMM D, YYYY')}</span>
                    </td>
                    <td>
                    </td>
                  </tr>
                }
              </tbody>
            </Table>
          </div>
          <div className={`subTable ${styles.vehicleDetailsInfoTable}`}>
            <Table responsive hover>
              <thead>
                <tr>
                  <th width="100%">Notes</th>
                </tr>
              </thead>
              <tbody>
                {vehicle &&
                  <tr>
                    <td>
                      <textarea
                        value={vehicle.get('notes')}
                        placeholder="Add a note by clicking Edit Vehicle"
                        rows="2"
                        readOnly
                      />
                    </td>
                  </tr>
                }
              </tbody>
            </Table>
          </div>


          {vehicle && (company && !company.get('disableWalkthrough')) &&
            <WalkthroughSelectTip
              showBoxTop
              handleToggle={() => this.handleWalkthroughToggle('vehicleImageUpload')}
              expand={walkthroughState.vehicleImageUpload}
              toolTipStyle={{ top: '-15em' }}
              style={{ transform: 'translate(9.2em, 3.8em)' }}
            >
              <div>
                Click <b>Upload Image</b> to upload a picture of your truck. A picture is needed for Weigh Station/Scale Bypassing
                <br /><br />
                To disable these tips, go to <b>Walkthrough Tips</b> under your <a onClick={() => history.push('/settings')} style={{ cursor: 'pointer' }}>settings</a>
              </div>
            </WalkthroughSelectTip>
          }

          <div className={`subTable ${styles.vehicleDetailsInfoTable}`} style={{ position: 'relative' }}>
            <Table responsive hover>
              <thead>
                <tr>
                  <th width="50%">
                    Vehicle Image
                  </th>
                  <th width="50%" />
                </tr>
              </thead>
              <tbody>
                {vehicle &&
                  <tr>
                    <td>
                      {errors.uploadVehicleImage &&
                        <div>{errors.uploadVehicleImage}</div>
                      }
                      {vehicleImage &&
                        <div className={styles.vehicleImage} style={{ verticalAlign: 'top', marginRight: '.5em', display: 'inline-block' }}>
                          <img role="presentation" src={vehicleImage._url} />
                        </div>
                      }
                      <div style={{ display: 'inline-block' }}>
                        {defaultVehicleImage &&
                          <div>
                            <MDBBtn size="sm" color="primary" onClick={() => this.toggleUploadVehicleImageDialog()}>Upload Image</MDBBtn>
                            <FileUploader
                              inputId="vehicleImageUploader"
                              label="Upload Image"
                              uploadHandler={(file) => this.handleVehicleImageUpload(vehicle, file)}
                              acceptedFileType="image/jpeg"
                              style={{ display: 'none' }}
                            />
                          </div>
                        }
                        {!defaultVehicleImage &&
                          <div>
                            <FileUploader
                              inputId="vehicleImageUploader"
                              label="Upload Image"
                              uploadHandler={(file) => this.handleVehicleImageUpload(vehicle, file)}
                              acceptedFileType="image/*"
                              raised
                            />
                          </div>
                        }
                        {vehicleImage &&
                          <div style={{ marginTop: '.5em' }}>
                            <MDBBtn size="sm" color="warning" onClick={() => this.handleVehicleImageUpload(vehicle, null)}>Clear Image</MDBBtn>
                          </div>
                        }
                      </div>
                      {defaultVehicleImage &&
                        <ConfirmModal
                          show={openUploadVehicleImageDialog}
                          title="Upload Vehicle Image"
                          handleModalChoice={this.toggleUploadVehicleImageDialog}
                          handleClose={() => this.toggleUploadVehicleImageDialog(undefined)}
                          secondaryLabel="Upload Other"
                        >
                          <div>{!vehicle.get('vehicleImage') && 'A picture of the vehicle is required for this service (JPEG recommended). Please upload a picture and toggle again. '}Use Default Vehicle Image?</div>
                          <div style={{ marginTop: '.5em', display: 'inline-block' }}>
                            <img style={{ height: '9em' }} role="presentation" src={defaultVehicleImage._url} />
                          </div>
                        </ConfirmModal>
                      }

                      {(!defaultVehicleImage && !vehicle.get('vehicleImage')) &&
                        <ConfirmModal
                          show={openUploadVehicleImageDialog}
                          title="Upload Vehicle Image"
                          handleModalChoice={this.toggleUploadVehicleImageDialog}
                          handleClose={() => this.toggleUploadVehicleImageDialog(undefined)}
                          secondaryLabel="Select Image"
                          closeMode
                        >
                          <div>A picture of the vehicle is required for this service (JPEG recommended). Please <b>Select Image</b> and toggle Weigh Station Bypass again</div>
                        </ConfirmModal>
                      }
                    </td>
                    <td />
                  </tr>
                }
              </tbody>
            </Table>
          </div>
        </div>

        {wsbEnabled && vehicle &&
          <div>
            <ToggleWeighStationBypassDialog
              title="Weigh Station Bypass Permission"
              open={openWeighStationBypassDialog}
              handleToggle={this.handleWeighStationBypassToggle}
              handleClose={this.toggleWeighStationBypassDialog}
              vehicle={vehicle}
              dialogType={0}
              userHasUsedWeighStationBypass={userHasUsedWeighStationBypass}
            />
            <ConfirmModal
              title="Weigh Station Bypass Module"
              show={openWeighStationBypassConfirmDialog}
              handleModalChoice={() => this.setState({ ...this.state, openWeighStationBypassConfirmDialog: false })}
              alertMode
              primaryLabel="Close"
            >
              <div>
                <div style={{ marginTop: '-1em' }}>
                  A message has been sent to the Switchboard team. One of us will reach out soon!
                  <br />
                  <br />
                  If you want to contact us directly, please:
                  <ul style={{ marginTop: '1em', paddingLeft: '1.2em', fontSize: '.85em' }}>
                    <li>Email us at <a href="mailto:team@onswitchboard.com" target="_blank">team@onswitchboard.com</a></li>
                    <li>Live Chat (chat icon on the bottom right of the screen)</li>
                    <li>Call us at 1-844-5-FLEETS (1-844-535-3387)</li>
                  </ul>
                </div>
              </div>
            </ConfirmModal>
          </div>
        }

        {(!wsbEnabled) && vehicle &&
          <ConfirmModal
            title="Weigh Station Bypass Module"
            show={openWeighStationBypassDialog}
            handleModalChoice={this.toggleWeighStationBypassDialog}
            handleClose={this.toggleWeighStationBypassDialog}
            alertMode
            primaryLabel="Close"
          >
            <div>
              <div style={{ marginTop: '-1em' }}>
                To enable (or get more information on) Weigh Station/Scale Bypassing, please:
                <ul style={{ marginTop: '1em', paddingLeft: '1.2em', fontSize: '.85em' }}>
                  <li>Email us at <a href="mailto:team@onswitchboard.com" target="_blank">team@onswitchboard.com</a></li>
                  <li>Live Chat (chat icon on the bottom right of the screen)</li>
                  <li>Call us at 1-844-5-FLEETS (1-844-535-3387)</li>
                </ul>
              </div>
            </div>
          </ConfirmModal>
        }
      </div>
    );
  }
}

SummaryLayout.propTypes = {
  company: PropTypes.object,
  vehicle: PropTypes.object,
  vehicleFetched: PropTypes.bool,
  dispatchers: PropTypes.array,
  refreshVehicleInfo: PropTypes.func,
  toggleWaitingState: PropTypes.func,
};

export default SummaryLayout;
