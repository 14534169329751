import React from 'react';
import PropTypes from 'prop-types';
// API
import * as Helpers from 'api/Helpers';
// Components
import ChooseOrAddButton from 'components/ChooseOrAddModal/container/ChooseOrAddButton';
import DatePicker from 'components/DatePicker/container/DatePicker';
// CSS
import styles from './PickupAndDropoffDetails.module.scss';

function PickupAndDropoffDetails(props) {
  const jobActionEntry = props.jobActionEntries[props.selectedIndex];
  // console.log(jobActionEntry);

  return (
    <div className={styles.pickupAndDropoffDetails}>
      <table className={styles.table}><tbody>
        <tr>
          <td>
            <div className={styles.formGroup}>
              <label className="inputLabel">{jobActionEntry.jobActionType} Number/ID</label>
              <input className="input" value={jobActionEntry.jobId} type="text" placeholder="....." onChange={(e) => props.handleFormChange('jobId', e.target.value)} />
            </div>
          </td>
        </tr>
        <tr className={styles.chooseRow}>
          <td>
            <div className={styles.formGroup}>
              <label className="inputLabel">Location</label>
              {jobActionEntry.vendor &&
                <div>
                  <div><b>{jobActionEntry.vendor.get('name')}</b></div>
                  <div>{jobActionEntry.vendor.get('address').get('address')}</div>
                  <div>{jobActionEntry.vendor.get('stateProvince') + ',' + jobActionEntry.vendor.get('country')}</div>
                </div>
              }
              <ChooseOrAddButton label="Location" property="vendor" handleChooseOrAdd={(vendorParseObj) => props.handleFormChange('vendor', vendorParseObj)}>Choose Location</ChooseOrAddButton>
            </div>
          </td>
          <td>
            <div className={styles.formGroup}>
              <label className="inputLabel">Vehicle</label>
              {jobActionEntry.vehicle &&
                <div>
                  <div><b>{jobActionEntry.vehicle.get('unitId')}</b></div>
                </div>
              }
              {jobActionEntry._primary === '' || jobActionEntry._primary === 'vehicle'
                ? <ChooseOrAddButton label="Vehicle" property="vehicle" handleChooseOrAdd={(vehicleParseObj) => props.handleFormChange('vehicle', vehicleParseObj)}>Choose Vehicle</ChooseOrAddButton>
                : <ChooseOrAddButton label="Vehicle" property="vehicle" handleChooseOrAdd={(vehicleParseObj) => props.showConfirmReassign('vehicle', vehicleParseObj)}>Choose Different Vehicle</ChooseOrAddButton>
              }

            </div>
          </td>
          <td>
            <div className={styles.formGroup}>
              <label className="inputLabel">Driver</label>
              {jobActionEntry.drivers &&
                <div>
                  <div><b>{Helpers.concatenateStringsInParseObjectsArray(jobActionEntry.drivers, 'user_fullName')}</b></div>
                </div>
              }
              {jobActionEntry._primary === '' || jobActionEntry._primary === 'drivers'
                // Bit of a hack to make single driver --> drivers
                ? <ChooseOrAddButton label="Driver" property="driver" handleChooseOrAdd={(driverParseObj) => props.handleFormChange('drivers', [driverParseObj])}>Choose Driver</ChooseOrAddButton>
                : <ChooseOrAddButton label="Driver" property="driver" handleChooseOrAdd={(driverParseObj) => props.showConfirmReassign('drivers', [driverParseObj])}>Choose Different Driver</ChooseOrAddButton>
              }
            </div>
          </td>
          {/*
          <td>
            <div className={styles.formGroup}>
              <label className="inputLabel">Trailer</label>
              { jobActionEntry.trailer &&
                <div><b>{ jobActionEntry.trailer.get('unitId') }</b></div>
              }
              { jobActionEntry._primary === '' || jobActionEntry._primary === 'trailer'
                ? <ChooseOrAddButton label="Trailer" property="trailer" handleChooseOrAdd={(trailerParseObj) => props.handleFormChange('trailer', trailerParseObj)}>Choose Trailer</ChooseOrAddButton>
                : <ChooseOrAddButton label="Trailer" property="trailer" handleChooseOrAdd={(trailerParseObj) => props.showConfirmReassign('trailer', trailerParseObj)}>Choose Different Trailer</ChooseOrAddButton>
              }
            </div>
          </td>
          */}
        </tr>
        <tr>
          <td colSpan="42">
            <div className={styles.formGroup}>
              <label className="inputLabel">Start Date</label>
              <DatePicker className="input" selected={jobActionEntry.date} placeholderText="....." onChange={(date) => props.handleFormChange('date', date)} /><br />
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="42">
            <div className={styles.formGroup}>
              <label className="inputLabel">Action Notes</label>
              <textarea value={jobActionEntry.notes} onChange={(e) => props.handleFormChange('notes', e.target.value)} placeholder="Any comments related to the action..."></textarea>
            </div>
          </td>
        </tr>
      </tbody></table>
    </div>
  );
}

PickupAndDropoffDetails.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  jobActionEntries: PropTypes.array.isRequired,
  showConfirmReassign: PropTypes.func.isRequired,
};

export default PickupAndDropoffDetails;
