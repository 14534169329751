// DO NOT CHANGE THE ORDER OR NUMBERING SYSTEM. ONLY APPEND NEW VALUES, DO NOT INSERT/INTERCHANGE
// THIS FILE MUST MATCH THE ONE ON THE SERVER SIDE

export const canada = {
  1: '70 hour / 7 Day',
  2: '120 hour / 14 Day',
  3: 'Oil Well Service',
  4: '80 hour / 7 day',
  5: '120 hour / 14 day',
  // 6: 'Alberta',
  7: 'Loggers',
};

export const canHours = {
  1: 70,
  2: 120,
  3: 0,
  4: 80,
  5: 120,
  6: 0,
  7: 80,
};

export const canDays = {
  1: 7,
  2: 14,
  3: 24,
  4: 7,
  5: 14,
  6: 0,
  7: 7,
};

export const us = {
  1: '70 hour / 8 Day',
  2: '60 hour / 7 Day',
  3: 'Oilfield 70 hour / 8 day',
  4: 'Motion Picture 60 hour / 7 day',
  5: 'Motion Picture 70 hour / 8 day',
  6: 'Construction 60 hour / 7 day',
  7: 'Construction 70 hour / 8 day',
  8: 'Ground Water Well 60 hour / 7 day',
  9: 'Ground Water Well 70 hour / 8 day',
  10: 'Intrastate 70 hour / 7 day',
  11: 'Alaska: 70 hour / 7 day',
  12: 'Alaska: 80 hour /  8 day',
  13: 'California: Intrastate 80 hour / 8 day',
  14: 'California: Motion Picture 80 hour / 8 day',
  15: 'USA: Passenger-carrying 60 hour / 7 Day',
  16: 'USA: Passenger-carrying 70 hour / 8 Day',
  17: 'Alaska: Passenger-carrying 70 hour / 7 day',
  18: 'Alaska: Passenger-carrying 80 hour / 8 day',
  19: 'USA: Oversized 60 hour / 7 day',
  20: 'USA: Oversized 70 hour / 8 day',
  21: 'California: Passenger-carrying 80 hour / 8 day',
  26: 'USA: Agricultural 60 hour / 7 Day',
  27: 'USA: Agricultural 70 hour / 8 Day'
};

export const usHours = {
  1: 70,
  2: 60,
  3: 70,
  4: 60,
  5: 70,
  6: 60,
  7: 70,
  8: 60,
  9: 70,
  10: 70,
  11: 70,
  12: 80,
  13: 80,
  14: 80,
  15: 60,
  16: 70,
  17: 70,
  18: 80,
  19: 60,
  20: 70,
  21: 80,
  26: 60,
  27: 70
};

export const usDays = {
  1: 8,
  2: 7,
  3: 8,
  4: 7,
  5: 8,
  6: 7,
  7: 8,
  8: 7,
  9: 8,
  10: 7,
  11: 7,
  12: 8,
  13: 8,
  14: 8,
  15: 7,
  16: 8,
  17: 7,
  18: 8,
  19: 7,
  20: 8,
  21: 8,
  26: 7,
  27: 8
};

/**
 * @description Get the appropriate cycle int from an exact match of the int's description
 * @param {number} countryCode - Whether to look through Canada (0), or USA (1)
 * @param {string} description
 */
export function getReferenceIntFromDescription(countryCode, description) {
  let enumRef = canada;
  if (countryCode === 1) enumRef = us;

  let referenceInt;

  Object.keys(enumRef).find(key => {
    if (enumRef[key] === description) {
      referenceInt = parseInt(key);
      return true;
    }
  });

  return referenceInt;
}
