import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import history from 'sbHistory';

// API
import { retrieveAndSaveDetailedReverseGeocode } from 'api/VehicleLocation/VehicleLocation';
import { getCurrentUser, getAttribute } from 'api/Parse';
import { getReverseGeocode } from 'api/Mapbox';
import { formatMilliTime } from 'api/ELD';
import { convertDistance } from 'api/Helpers';

// sbCore
import Card from 'sbCore/Card/Card';
import DutyStatusBadge from 'sbCore/DutyStatusBadge/DutyStatusBadge';
import Alert from 'sbCore/Alert/Alert';
import Button from 'sbCore/Button/Button';

//sb-csapi
import { formatName } from 'sb-csapi/dist/utils/String';

// enums
import { AlertStatus } from 'sb-csapi/dist/enums/Notification/AlertStatus';

// CSS
import './style.scss';

/**
 * @description Renders the active trailer/vehicle view card when a vehicle is selected on the sidebar
 * @returns
 *
 * @example
 * <ShareVehicleTrailerActiveView
    speedKm={this.state.assetObj.speedKm}
    gpsHeadingDirection={this.state.assetObj.bearing}
    formattedAddress={this.state.assetObj.formattedAddress}
    formattedAddress={this.state.assetObj.formattedAddress}
    unitId={this.state.assetObj.unitId}
    secondsToDestination={this.state.assetObj.secondsToDestination}
    destinationAddress={this.props.destinationObj.name}
    coordinates={this.state.follow.active ? this.state.follow.currentPoint : this.state.assetObj.coordinates}
  />
 */

function ShareVehicleTrailerActiveView(props) {
  const {
    temperatureCelsius,
    unitId,
    formattedAddress,
    lastUpdated,
    speedLimitStr,
    destinationAddress,
    eta,
    etaRetrievedMoment,
    speedKm,
    gpsHeadingDirection,
    distanceUnit,
    coordinates: {
      latitude,
      longitude,
    }
  } = props;

  const [reverseGeocodeAddress, setReverseGeocodeAddress] = useState(null);
  useEffect(() => {
    setReverseGeocodeAddress(null);
    async function getAndSetReverseGeocode() {
      const coordinates = props?.coordinates;
      if (coordinates && coordinates?.length > 0) {
        const longitude = coordinates[0];
        const latitude = coordinates[1];
        if (longitude && latitude) {
          let className;
          let objectId;
          if (getAttribute(props.asset, 'vehicleLocation', true)) {
            className = 'VehicleLocation';
            objectId = getAttribute(props.asset, 'vehicleLocation', true).id;
          } else if (getAttribute(props.asset, 'assetLocation', true)) {
            className = 'AssetLocation';
            objectId = getAttribute(props.asset, 'assetLocation', true).id;
          }

          props.asset;

          if (className && objectId) {
            const detailedReverseGeocodeAddress = await retrieveAndSaveDetailedReverseGeocode(
              objectId,
              className,
              longitude,
              latitude
            );
            setReverseGeocodeAddress(detailedReverseGeocodeAddress);
          } else {
            const reverseGeocodeResults = await getReverseGeocode(longitude, latitude);
            setReverseGeocodeAddress(reverseGeocodeResults);
          }
        }
      }
    }
    getAndSetReverseGeocode();
  }, [props?.activeVehicleTrailerObj?.coordinates?.latitude]);

  const speedConverted = distanceUnit && distanceUnit === 'mi' ? convertDistance(speedKm, 'km', 'mi') || 0 : speedKm;

  // Move active vehicle/trailer view to middle of the screen if sidebar is closed
  let className = 'active-card-container share-active-card-container';

  // Generate alert int and description
  let alertStatusInt;
  let alertDescription;

  // Title of the card -> Unit ID, duty status badge and alert
  const title = (
    <div className="flex flex-row align-items-center column-gap-2 ml-5 pt-2">
      <div className="mr-3">
        {unitId || '-'}
      </div>
    </div>
  )

  // Subtitle -> driver names for vehicle or temperature for trailers
  const subTitle = (
    <div className="subtitle-container">
      {temperatureCelsius &&
        (
          <div className="ml-5 font-semibold">
            <span className="material-icons mr-2 vertical-align-middle">ac_unit</span>
            <span className="vertical-align-middle">{temperatureCelsius}&#176; Celsius</span>
          </div>
        )
      }
    </div>

  )

  // Get street view with given latitude and longitude
  function getStreetView(long, lat) {
    window.open(`https://maps.google.com/maps?q=${lat},${long}&t=h&iwd=0&z=18`);
  }

  // if the company is not granted access to the driver module
  const body = (
    <div>
      <div className="flex flex-row align-items-center">
        <div className="flex flex-column row-gap-2 mr-auto pl-5 mb-4">
          <div className="flex flex-column w-full">
            <div className="text-sm font-semibold">
              {reverseGeocodeAddress || formattedAddress}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row align-items-center pl-5">
        {/* Second column */}
        <div className="flex-1 flex-column flex align-items-left border-right-1 border-gray-300 pt-2 pb-2">
          {/* container */}
          <div className="flex flex-column row-gap-2">
            <div className="font-semibold">
              <span className="material-icons mr-2 vertical-align-middle text-3xl">speed</span>
              <span className="text-sm vertical-align-middle">{speedConverted ? `${Math.round(speedConverted)} ${distanceUnit || 'km'}/hr` : 'Not Moving'}</span>
            </div>
          </div>
        </div>
        {/* Third column */}
        <div className="flex-1 flex-column flex align-items-center">
          <div className="flex flex-column row-gap-2">
            {destinationAddress &&
              (
                <div className="font-semibold">
                  <span className="material-icons mr-2 vertical-align-middle text-3xl">flag</span>
                  <span className="text-sm vertical-align-middle">{destinationAddress}</span>
                </div>
              )
            }
            {eta &&
              (
                <div className="font-semibold">
                  <table>
                    <tr>
                      <td><span className="material-icons mr-2 text-3xl vertical-align-middle">timer</span></td>
                      <td>
                        <div style={{ lineHeight: '1em' }}>
                          ETA: <b>{`${moment(eta).format('MMM D')} ${moment(eta).format('LT')}`}*</b>
                          <br />
                          TTD: <b>{formatMilliTime(moment(eta).diff(etaRetrievedMoment), true)}*</b>
                          <div style={{ fontSize: '0.6em' }}>
                            <b>*If driving consistently</b>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                    </tr>
                  </table>

                  <span className="text-sm vertical-align-middle">

                  </span>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={className}>
      <Card
        title={title}
        subTitle={subTitle}
      >
        {body}
      </Card>
    </div>
  );
}

export default ShareVehicleTrailerActiveView;
