import React from 'react';
import PropTypes from 'prop-types';

// Components
import FilterBarView from 'components/MapListSidebar/view/FilterBar';
import EquipmentDisplayFilter from 'components/Map.old/view/EquipmentDisplayFilter';

// API
import * as Helpers from 'api/Helpers';
class FilterBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      property: 'unitId',
      value: '',
      type: props.type,
    };
    this.handleTypeSelect = this.handleTypeSelect.bind(this);
    this.handleSortSelect = this.handleSortSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleCheckboxToggle = this.handleCheckboxToggle.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...this.state, type: nextProps.type });
  }

  handleTypeSelect(type = 'Vehicles') {
    const filter = [];
    this.props.handleFilter(type, filter);
  }

  handleSortSelect(sortBy, filter = []) {
    this.props.handleFilter(this.state.type, filter, sortBy);
  }

  handleChange(event) {
    this.setState({ ...this.state, value: event.target.value });
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      const filterArr = [];
      if (this.state.value === '') {
        this.props.handleFilter(this.props.type, []);
      } else {
        if (this.state.type === 'Vehicles') {
          const unitIdParts = this.state.value.replace(/ /g, '').split(',');
          for (let i = 0; i < unitIdParts.length; i++) {
            filterArr.push({
              name: 'unitId',
              queryType: 'matches',
              value: unitIdParts[i],
            });
          }
        } else if (this.state.type === 'Geofences') {
          filterArr.push({
            name: 'name',
            queryType: 'matches',
            value: this.state.value,
          });
        } else if (this.state.type === 'Trailers') {
          const unitIdParts = this.state.value.replace(/ /g, '').split(',');
          for (let i = 0; i < unitIdParts.length; i++) {
            filterArr.push({
              name: 'unitId',
              queryType: 'matches',
              value: unitIdParts[i],
            });
          }
        }
        this.props.handleFilter(this.props.type, filterArr);
      }
    }
  }

  handleCheckboxToggle(type) {
    this.props.toggleEquipmentMarkers(type);
  }

  render() {
    const { vehiclesChecked, trailersChecked } = this.state;
    const isEquipmentTypeSelected = (['Vehicles', 'Trailers'].indexOf(this.props.type) !== -1);

    return (
      <div>
        {isEquipmentTypeSelected &&
          <div className="d-none d-block" style={{ position: 'absolute', top: '.65em', left: '18em', zIndex: '99', borderRadius: '2px' }}>
            <EquipmentDisplayFilter
              handleCheckboxToggle={this.handleCheckboxToggle}
              vehiclesChecked={this.props.displayMarkers.vehicles}
              trailersChecked={this.props.displayMarkers.trailers}
            />
          </div>
        }

        <FilterBarView
          label={this.props.type}
          select={this.handleTypeSelect}
          handleChange={this.handleChange}
          handleSortSelect={this.handleSortSelect}
          sortBy={this.props.sortBy}
          value={this.state.value}
          handleKeyPress={this.handleKeyPress}
        />
      </div>

    );
  }
}

FilterBar.propTypes = {
  type: PropTypes.string.isRequired,
  sortBy: PropTypes.string,
  toggleEquipmentMarkers: PropTypes.func,
  handleFilter: PropTypes.func.isRequired,
  displayMarkers: PropTypes.object,
};

export default FilterBar;
