import React from 'react';
import PropTypes from 'prop-types';

import { MDBIcon } from 'mdbreact';

// CSS
import './style.scss';

class NotificationBadge extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { toggleDropdown, unreadCount, isPanelExpanded } = this.props;

    let className = 'notification-badge';
    if (isPanelExpanded) className += ' active';

    return (
      <div className={className} onClick={() => toggleDropdown()}>
        <MDBIcon icon="bell" />
        { (unreadCount > 0) &&
          <div className="unread-badge">{ unreadCount }</div>
        }
      </div>
    );
  }
}

NotificationBadge.defaultProps =  {
  unreadCount: 0,
  isPanelExpanded: false,
};

NotificationBadge.propTypes = {
  toggleDropdown: PropTypes.func.isRequired,
  unreadCount: PropTypes.number.isRequired,
  isPanelExpanded: PropTypes.bool,
};

export default NotificationBadge;
