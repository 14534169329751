import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// api
import { formatStringLength } from 'api/Helpers';
import { getDispatchDocuments } from 'api/Dispatch/DispatchDocument';

// csapi
import { getAttribute } from 'sb-csapi/dist/AAPI';

// CSAPI Enums
import { QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';

// CSAPI sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// components
import Email from 'sbCore/Email/Email';
import MultiSelect from 'sbCore/MultiSelect/MultiSelect';
import DispatchEmailButton from 'components/Dispatch/DispatchEmail/DispatchEmailButton';

// enums
import { getDocumentTypeFromTypeCode } from 'enums/DispatchDocument';

// styling
import './style.scss';

/**
 * @description Email client dialog pop-up
 * @param {Array} recipients - Optional. String array of recipients that can be attached to the dispatch email
 * @param {Array} ccRecipients - Optional. String array of cc recipients that can be attached to the dispatch email
 * @param {Array} bccRecipients - Optional. String array of bcc recipients that can be attached to the dispatch email
 * @param {Array} selectedDispatchDocuments - Optional. Array of parse documents that can be attached to the dispatch email
 * @param {string} subject - Optional. String subject line that can be attached to the dispatch email
 * @param {string} messageBody - Optional. HTML message inside a string that can be attached to the dispatch email
 * @param {string} dispatchJobObjectId - Required. The DispatchJobObjectId we want to get documents from
 * @param {boolean} isLoading - Whether or not to disable the email button
 * @returns
 *
 * @example
 * <DispatchEmail dispatchJobObjectId={objectId} recipients=['DenzelNasol@offswitchboard.com'] />
 */
const DispatchEmail = (props) => {

  /* useStates */
  const [showEmailDialog, setShowEmailDialog] = useState(false);

  const [dispatchDocuments, setDispatchDocuments] = useState(undefined);

  const [recentSelectableDispatchDocuments, setRecentSelectableDispatchDocuments] = useState([]);
  const [oldSelectableDispatchDocuments, setOldSelectableDispatchDocuments] = useState([]);
  const [multiSelectDispatchDocumentItems, setMultiSelectDispatchDocumentItems] = useState([]);

  const [selectedDispatchDocuments, setSelectedDispatchDocuments] = useState([]);

  /* useEffects */

  // gets dispatch documents from given dispatch job id
  useEffect(() => {
    async function _getDispatchDocuments() {
      if (!props.dispatchJobObjectId) {
        return;
      }

      let { dispatchDocuments } = await getDispatchDocuments(
        undefined,
        [new Filter(QueryRestrictionTypes.EQUAL_TO, 'dispatchJob', props.dispatchJobObjectId)],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      );
      if (!dispatchDocuments) {
        return;
      }

      dispatchDocuments = dispatchDocuments.filter(dispatchDocument => (getAttribute(dispatchDocument, 'type') !== -1) && getAttribute(dispatchDocument, 'file'));
      setDispatchDocuments(dispatchDocuments);
    }
    _getDispatchDocuments();
  }, [props.dispatchJobObjectId]);

  // separates and sorts dispatch documents between old and recent docs
  useEffect(() => {
    async function getSelectableDispatchDocuments() {

      if (!dispatchDocuments) {
        return;
      }
      const mostRecentDocumentOfEachType = {};
      dispatchDocuments.map(dispatchDocument => {
        const documentTypeInt = getAttribute(dispatchDocument, 'type');
        if (!mostRecentDocumentOfEachType[documentTypeInt]) mostRecentDocumentOfEachType[documentTypeInt] = dispatchDocument;
        const mostRecentDocumentOfTypeSoFar = mostRecentDocumentOfEachType[documentTypeInt];
        const mostRecentDateSoFar = getAttribute(mostRecentDocumentOfTypeSoFar, 'uploadedAtUTC');
        const currentDate = getAttribute(dispatchDocument, 'uploadedAtUTC');
        // if current document's date is more recent
        if (mostRecentDateSoFar && currentDate) {
          if (moment(currentDate).diff(moment(mostRecentDateSoFar)) > 0) return mostRecentDocumentOfEachType[documentTypeInt] = dispatchDocument;
        }
      });

      // first add the most recent stuff
      const recentSelectableDispatchDocuments = Object.keys(mostRecentDocumentOfEachType).map(documentTypeInt => {
        const dispatchDocument = mostRecentDocumentOfEachType[documentTypeInt];
        const objectId = getAttribute(dispatchDocument, 'objectId');
        const documentType = getDocumentTypeFromTypeCode(getAttribute(dispatchDocument, 'type'));
        const file = getAttribute(dispatchDocument, 'file');
        let fileName = file && (file._name.split('_').slice(1).join('_')); // get rid of autogen sequence at the start of every file (ex. "d656982c687c5cb88e7a60f2e437c6a2_NSC_Example.pdf")
        let shortFileName = fileName;
        if (fileName && fileName.length > 20) shortFileName = formatStringLength(fileName, 20);
        const uploadedAtUTC = getAttribute(dispatchDocument, 'uploadedAtUTC') ? `[${moment(getAttribute(dispatchDocument, 'uploadedAtUTC')).format('DD-MM-YYYY HH:mm')}]` : '';
        shortFileName = `${shortFileName} ${uploadedAtUTC}`;
        fileName = `${fileName} ${uploadedAtUTC}`;
        return {
          key: objectId,
          label: `${shortFileName}`,
          fullLabel: `${documentType.description} - ${fileName}`,
          value: objectId,
          resource: dispatchDocument,
        };
      });

      // sort most recent selectables by alphabetical order
      recentSelectableDispatchDocuments.sort((itemA, itemB) => {
        const itemAArr = itemA.label.split('[');
        const itemBArr = itemB.label.split('[');
        // if A and B have the same letters
        if (itemAArr[0] === itemBArr[0]) {
          const dateA = itemAArr[1].split(']')[0];
          const dateB = itemBArr[1].split(']')[0];
          if (moment(dateA).diff(moment(dateB)) > 0) return -1;
          if (moment(dateA).diff(moment(dateB)) < 0) return 1;
          return 0;
        }
        if (itemA.label < itemB.label) return -1;
        if (itemA.label > itemB.label) return 1;
        return 0;
      });

      // filter out most recent from selectableDispatchDocuments
      const remainingDispatchDocuments = dispatchDocuments.filter(dispatchDocument => {
        const objectId = getAttribute(dispatchDocument, 'objectId');
        const documentTypeInt = getAttribute(dispatchDocument, 'type');
        const mostRecentDocumentOfType = mostRecentDocumentOfEachType[documentTypeInt];
        if (getAttribute(mostRecentDocumentOfType, 'objectId') === objectId) {
          return false;
        }
        return true;
      });

      const oldSelectableDispatchDocuments = remainingDispatchDocuments.map(dispatchDocument => {
        const objectId = getAttribute(dispatchDocument, 'objectId');
        const documentType = getDocumentTypeFromTypeCode(getAttribute(dispatchDocument, 'type'));
        const file = getAttribute(dispatchDocument, 'file');
        let fileName = file && (file._name.split('_').slice(1).join('_')); // get rid of autogen sequence at the start of every file (ex. "d656982c687c5cb88e7a60f2e437c6a2_NSC_Example.pdf")
        let shortFileName = fileName;
        if (fileName && fileName.length > 20) shortFileName = formatStringLength(fileName, 20);
        const uploadedAtUTC = getAttribute(dispatchDocument, 'uploadedAtUTC') ? `[${moment(getAttribute(dispatchDocument, 'uploadedAtUTC')).format('DD-MM-YYYY HH:mm')}]` : '';
        shortFileName = `${shortFileName} ${uploadedAtUTC}`;
        fileName = `${fileName} ${uploadedAtUTC}`;
        return {
          key: objectId,
          label: `${shortFileName}`,
          fullLabel: `${documentType.description} - ${fileName}`,
          value: objectId,
          resource: dispatchDocument,
        };
      });

      // sort remaining selectables by alphabetical order
      oldSelectableDispatchDocuments.sort((itemA, itemB) => {
        const itemAArr = itemA.label.split('[');
        const itemBArr = itemB.label.split('[');
        // If no date exists, return
        if (itemAArr.length === 1 || itemBArr.length === 1) return 0;
        // if A and B have the same letters
        if (itemAArr[0] === itemBArr[0]) {
          const dateA = itemAArr[1].split(']')[0];
          const dateB = itemBArr[1].split(']')[0];
          if (moment(dateA).diff(moment(dateB)) > 0) return -1;
          if (moment(dateA).diff(moment(dateB)) < 0) return 1;
          return 0;
        }
        if (itemA.label < itemB.label) return -1;
        if (itemA.label > itemB.label) return 1;
        return 0;
      });

      setRecentSelectableDispatchDocuments(recentSelectableDispatchDocuments);
      setOldSelectableDispatchDocuments(oldSelectableDispatchDocuments);
    }

    getSelectableDispatchDocuments();
  }, [dispatchDocuments]);

  // converts documents into items that can be used in the MultiSelect component
  useEffect(() => {
    async function getMultiSelectDispatchDocumentItems() {

      const multiSelectDispatchDocumentItems = [];

      if (recentSelectableDispatchDocuments && recentSelectableDispatchDocuments.length > 0) {
        const multiSelectRecentDispatchDocumentItems = recentSelectableDispatchDocuments.map((documentItem) => {
          return {
            label: documentItem.label,
            value: documentItem,
          };
        });

        multiSelectDispatchDocumentItems.push(
          {
            label: 'Recent Documents',
            items: multiSelectRecentDispatchDocumentItems,
          },
        );
      } else {
        multiSelectDispatchDocumentItems.push(
          {
            label: 'No Recent Documents',
            items: [],
          },
        );
      }

      if (oldSelectableDispatchDocuments && oldSelectableDispatchDocuments.length > 0) {
        const multiSelectOldDispatchDocumentItems = oldSelectableDispatchDocuments.map((documentItem) => {
          return {
            label: documentItem.label,
            value: documentItem,
          };
        });

        multiSelectDispatchDocumentItems.push(
          {
            label: 'Previous Documents',
            items: multiSelectOldDispatchDocumentItems,
          },
        );
      } else {
        multiSelectDispatchDocumentItems.push(
          {
            label: 'No Previous Documents',
            items: [],
          },
        );
      }

      setMultiSelectDispatchDocumentItems(multiSelectDispatchDocumentItems);
    }

    getMultiSelectDispatchDocumentItems();
  }, [recentSelectableDispatchDocuments, oldSelectableDispatchDocuments]);

  // If props contain selectedDispatchDocuments, attach them to the selectedDispatchDocuments state
  useEffect(() => {
    if (!props.selectedDispatchDocuments || !props.selectedDispatchDocuments > 0) {
      return;
    }

    // finds the intersect between the two arrays
    const oldSelectedDocuments = oldSelectableDispatchDocuments.filter((oldSelectableDocument) => {
      return props.selectedDispatchDocuments.some((selectedDocument) => {
        return oldSelectableDocument.key === selectedDocument.id;
      });
    });

    const recentSelectedDocuments = recentSelectableDispatchDocuments.filter((recentSelectableDocument) => {
      return props.selectedDispatchDocuments.some((selectedDocument) => {
        return recentSelectableDocument.key === selectedDocument.id;
      });
    });

    setSelectedDispatchDocuments(oldSelectedDocuments.concat(recentSelectedDocuments));

  }, [oldSelectableDispatchDocuments, recentSelectableDispatchDocuments, props.selectedDispatchDocuments]);

  /* Callback Functions */
  const setShowEmail = () => {
    setShowEmailDialog(true);
  };

  const setHideEmail = () => {
    setShowEmailDialog(false);
  };

  const resetSelectedDispatchDocuments = () => {
    setSelectedDispatchDocuments([]);
  };

  /* Dispatch Document MultiSelect Templates */
  const attachDocumentsItemTemplate = (option) => {
    return (
      <div>{option.label}</div>
    );
  };

  const selectedDispatchDocumentTemplate = (option) => {
    if (option) {
      return (
        <div className="selected-dispatch-item">{option.label}</div>
      );
    }

    return 'Attach Document(s)';
  };

  const attachDocumentsFooterTemplate = () => {
    const selectedItems = selectedDispatchDocuments;
    const length = selectedItems ? selectedItems.length : 0;
    return (
      <div className="p-py-2 p-px-3">
        {length} item{length > 1 ? 's' : ''} selected.
      </div>
    );
  };

  /* Components */
  const dispatchDocumentsMultiSelect = (
    props.dispatchJobObjectId &&
    <MultiSelect
      value={selectedDispatchDocuments}
      options={multiSelectDispatchDocumentItems}
      onChange={(e) => setSelectedDispatchDocuments(e.value)}
      itemTemplate={attachDocumentsItemTemplate}
      selectedItemTemplate={selectedDispatchDocumentTemplate}
      panelFooterTemplate={attachDocumentsFooterTemplate}
      optionLabel="label"
      optionGroupLabel="label"
      optionGroupChildren="items"
      filter
    />
  );

  return (
    <div className="dispatch-email">
      <Email
        setHideEmail={setHideEmail}
        showEmailDialog={showEmailDialog}
        recipients={props.recipients}
        ccRecipients={props.ccRecipients}
        bccRecipients={props.bccRecipients}
        subject={props.subject}
        messageBody={props.messageBody}
        selectedDispatchDocuments={selectedDispatchDocuments}
        dispatchDocumentsMultiSelect={dispatchDocumentsMultiSelect}
        resetSelectedDispatchDocuments={resetSelectedDispatchDocuments}
      />
      <DispatchEmailButton setShowEmail={setShowEmail} isLoading={props.isLoading} />
    </div>
  );
};

export default DispatchEmail;
