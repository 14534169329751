import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';

// Helpers
import * as ELD from 'api/ELD';
import * as Helpers from 'api/Helpers';

// Components
import HOSGraph from 'components/DriverDetails/container/HOSGraph';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import FilterDropdown from 'components/FilterDropdown/view/FilterDropdown';

// CSS
import styles from 'components/ELDEdit/view/ELDEditModal.module.scss';

class AssignUnidentifiedToDriverModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      vehicleDriverHistory: [],
      eventRowState: {
        // filled with attrs of event.ids to save info based on row based on event id
      },
      errors: [],
      changesProcessing: false,
      showConfirmModal: false,
    };
    for (let i = 0; i < props.unidentifiedObject.unidentifiedDriverEvents.length; i++) {
      this.state.eventRowState[props.unidentifiedObject.unidentifiedDriverEvents[i].id] = {};
    }
    this.refreshState = this.refreshState.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAssignDriver = this.handleAssignDriver.bind(this);
    this.submitEditRequest = this.submitEditRequest.bind(this);
    this.showConfirmModal = this.showConfirmModal.bind(this);
  }

  componentDidMount() {
    this.refreshState();
  }

  refreshState() {
    const { unidentifiedObject } = this.props;
    this.setState({ ...this.state, changesProcessing: true }, () => {
      ELD.getVehicleDriverHistory(unidentifiedObject.vehicle).then(
        vehicleDriverHistory => {
          const newState = { ...this.state, changesProcessing: false, vehicleDriverHistory };
          this.setState(newState);
        }
      );
    });
  }


  submitEditRequest(returnPreview, runCallback) {
    const promise = new Promise((resolve, reject) => {
      const intermediateState = {
        ...this.state,
        changesProcessing: true,
        errors: [],
        showConfirmModal: false,
      };

      this.setState(intermediateState, () => {
        // first check to see if all drivers are approved to be edited
        const checkAllowedEditPromises = [];
        const editRequestSummary = []; // array of corresponding edit requests to our checkAllowedEditPromises

        const eventRowState = this.state.eventRowState;
        const keys = Object.keys(eventRowState);
        for (let i = 0, keyLen = keys.length; i < keyLen; i++) {
          const key = keys[i];
          if (eventRowState[key].driverObjectId) {
            const driverObjectId = eventRowState[key].driverObjectId;
            const eventDateTime = eventRowState[key].eventDateTime;
            const dutyStatus = eventRowState[key].dutyStatus;
            editRequestSummary.push({ driverObjectId, eventDateTime, dutyStatus });
            checkAllowedEditPromises.push(ELD.checkShouldDisableEdit(driverObjectId, eventDateTime));
          }
        }
        console.log('a');
        Promise.all(checkAllowedEditPromises).then(
          results => {
            console.log('b');
            const errors = [];
            for (let i = 0, resultsLen = results.length; i < resultsLen; i++) {
              const shouldDisableEdit = results[i];
              if (shouldDisableEdit) {
                const summary = editRequestSummary[i];
                const errorString = `Could not assign driver to ${summary.dutyStatus} (${moment(summary.eventDateTime).format('MMM D, YYYY')} @ ${moment(summary.eventDateTime).format('h:mm a')}). Either the day has not been completed or an edit is currently pending`;
                errors.push(errorString);
              }
            }
            if (errors.length > 0) {
              this.setState({
                ...this.state,
                changesProcessing: false,
                errors,
              }, () => reject(errors));
            } else {
              this.props.handleClose(true);

              ELD.eldEditHandler(1, this.state.eventRowState, undefined, 'Assignment of Unidentified Events', undefined, undefined, returnPreview).then(

                eldEditObject => {
                  console.log('c');
                  const newState = {
                    ...this.state,
                    changesProcessing: false,
                  };
                  resolve(eldEditObject);

                  if (runCallback) {
                    this.props.updateHandler();
                  } else {
                    this.setState(newState, () => {
                    });
                  }
                },
                error => {
                  console.log('d');
                  const newState = { ...this.state, changesProcessing: false, errors: error.message };
                  this.setState(newState, () => reject(error));
                }
              );
            }
          },
          error => {
            reject(error);
          }
        );
      });
    });
    return promise;
  }

  handleClose() {
    this.props.handleClose();
  }

  handleAssignDriver(dropdownAttrName) {
    const newState = { ...this.state };
    const unidentifiedDriverEvents = this.props.unidentifiedObject.unidentifiedDriverEvents;
    const driverObjectId = dropdownAttrName.split('|')[0];

    for (let i = 0; i < unidentifiedDriverEvents.length; i++) {
      const eldEvent = unidentifiedDriverEvents[i];
      newState.eventRowState[eldEvent.id].driverObjectId = driverObjectId;
      newState.eventRowState[eldEvent.id].eventDateTime = eldEvent.get('eventDateTime');
      newState.eventRowState[eldEvent.id].dutyStatus = ELD.getDutyStatusDesc(eldEvent.get('eldEventTypeCodeInt'));
    }
    this.setState(newState);
  }

  showConfirmModal(show) {
    const newState = { ...this.state, showConfirmModal: show };
    this.setState(newState);
  }

  render() {
    const { vehicle, unidentifiedDriverEvents } = this.props.unidentifiedObject;
    const { vehicleDriverHistory, changesProcessing } = this.state;
    const timezoneOffsetFromUTC = (this.props.timezoneOffsetFromUTC || moment.tz.guess());

    const footerButtons = (
      <span>
        <button className="buttonDefault floatRight" disabled={changesProcessing} onClick={() => this.showConfirmModal(true)}>
          { changesProcessing ?
            <span>Requesting...</span>
            :
            <span>Request Changes</span>
          }
        </button>
        <button className={'buttonDefault closeButton'} onClick={() => this.handleClose(false)}>Close</button>
      </span>
    );

    const driverDropdownItems = [];

    for (let i = 0, vehicleDriverHistoryLen = vehicleDriverHistory.length; i < vehicleDriverHistoryLen; i++) {
      const history = vehicleDriverHistory[i];
      const driver = history.get('driver');
      if (driver && driver.get('user')) {
        const driverUser = driver.get('user');
        driverDropdownItems.push({ attrName: `${history.get('driver').id}|${history.id}`, placeholder: `${driverUser.get('firstName')} ${driverUser.get('lastName')}` });
      }
    }

    const udDriverRows = unidentifiedDriverEvents.map(event => {
      const eventRowState = this.state.eventRowState[event.id];

      return (
        <tr className={styles.udDriverRows} key={event.id}>
          <td>
            <div>{ moment(event.get('eventDateTime')).tz(timezoneOffsetFromUTC).format('MMM D, YYYY') }</div>
            <div>{ moment(event.get('eventDateTime')).tz(timezoneOffsetFromUTC).format('h:mm a') }</div>
          </td>
          <td>{ ELD.getDutyStatusDesc(event.get('eldEventTypeCodeInt')) }</td>
        </tr>
      );
    });

    return (
      <Modal className={styles.assignUnidentifiedToDriverModal} show={this.props.show} backdrop="static">
        <Modal.Header>
          <Modal.Title>
            <span>Unidentified Driver Events for { vehicle.get('unitId') }</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{ marginBottom: '1.5em' }}>
            Assign events to driver:
            <FilterDropdown
              id="udDriverAssign"
              title="Select Driver"
              handleFilter={(attrName) => this.handleAssignDriver(attrName)}
              menuItems={driverDropdownItems}
              style={{ marginTop: '-.06em' }}
              disabled={false}
            />
          </div>
          <table className={styles.unidentifiedDriverTable}>
            <thead>
              <tr>
                <th width="50%">Event Date/Time</th>
                <th width="50%">Duty Status</th>
              </tr>
            </thead>
            <tbody>
              { udDriverRows }
            </tbody>
          </table>

          { this.state.errors.length > 0 &&
            <div className={`${styles.errors}`}>
              <ul>
                {
                  this.state.errors.map(error => <li key={(new Date()).getTime() + Math.random()} className="textRedMaple">{ error }</li>)
                }
              </ul>
            </div>
          }

        </Modal.Body>

        <Modal.Footer>
          {!this.state.changesProcessing ?
            footerButtons
            :
            <LoadingIcon />
          }
          <ConfirmModal show={this.state.showConfirmModal} handleModalChoice={(confirmBool) => confirmBool ? this.submitEditRequest(false, true) : this.showConfirmModal(false)} handleClose={() => this.showConfirmModal(false)}>
            <span>
              Request Edit(s)?
            </span>
          </ConfirmModal>
        </Modal.Footer>

      </Modal>
    );
  }
}

AssignUnidentifiedToDriverModal.propTypes = {
  timezoneOffsetFromUTC: PropTypes.string,
  show: PropTypes.bool,
  unidentifiedObject: PropTypes.object,
  handleClose: PropTypes.func,
  updateHandler: PropTypes.func,
};

export default AssignUnidentifiedToDriverModal;
