import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { MDBIcon } from 'mdbreact';
import { Link } from 'react-router-dom';

// API
import * as Helpers from 'api/Helpers';
import { getLocationDescriptionBreakdown } from 'api/VehicleLocation/VehicleLocation';
import { getTimezoneString, findDayViewIssues, areStatesTouching, getFuelCardData } from 'api/IFTARoute/IFTARoute.old';
import { getAttribute } from 'api/Parse';

// Components
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import SBTable from 'components/Shared/SBTable/SBTable';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import Tooltip from 'sbCore/Tooltip/Tooltip';

import VehicleRouteBreakdownDetailsView from 'components/IFTARoute/view/VehicleRouteBreakdownDetailsView';

// Style
import './styles.scss';

class VehicleRouteBreakdownDayViewTableBeta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      driverTimeZoneStr: moment.tz.guess(),
      iftaRouteDayIssuesArr: [],
      iftaRouteDayArr: [],
    };
    this.generateIFTARouteDayArr = this.generateIFTARouteDayArr.bind(this);
    this.generateDayViewTable = this.generateDayViewTable.bind(this);
  }

  async componentDidMount() {
    const { state, props } = this;
    const iftaFuelCardDataHash = await getFuelCardData(props.iftaRouteArr.map((iftaRoute) => getAttribute(iftaRoute, 'objectId')), true);
    this.setState({ ...state, isLoading: true, iftaFuelCardDataHash }, () => this.generateIFTARouteDayArr());
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (props.iftaRouteArr.length !== prevProps.iftaRouteArr.length) this.generateIFTARouteDayArr();
  }

  /**
   * @description Generates an array of objects which contain information about a day's IFTARoutes
   * @returns
   */
  generateIFTARouteDayArr() {
    const { props } = this;

    this.setState({ ...this.state, isLoading: true });
    const iftaRouteDayArr = [];
    const driverTimeZoneStr = props.iftaRouteArr && props.iftaRouteArr.length > 0 && getTimezoneString(props.iftaRouteArr[0]);

    const generateIFTARouteDayObj = (iftaRoute) => {
      // We're going to create a custom object containing all the information needed for a day's IFTARoutes
      const vehicleLocationStart = getAttribute(iftaRoute, 'vehicleLocationStart');
      const vehicleLocationEnd = getAttribute(iftaRoute, 'vehicleLocationEnd');
      const iftaRouteDriverPeriods = getAttribute(iftaRoute, 'iftaRouteDriverPeriods') || [];
      const iftaRouteDayObj = {
        objectId: getAttribute(iftaRoute, 'objectId'),
        uniqueId: `${iftaRoute.id}${moment(getAttribute(iftaRoute, 'dateStart')).tz(driverTimeZoneStr).format('YYYYMMDD')}`, // Unique identifier will be the day in which this IFTARoute represents
        driverTimeZoneStr,
        dateStart: getAttribute(iftaRoute, 'dateStart'),
        dateEnd: getAttribute(iftaRoute, 'dateEnd'),
        vehicleLocationStart: {
          stateProvince: getAttribute(vehicleLocationStart, 'stateProvince'),
          aprxShortName: getAttribute(vehicleLocationStart, 'aprxShortName'),
          locationDescriptionUS: getAttribute(vehicleLocationStart, 'locationDescriptionUS'),
        },
        vehicleLocationEnd: {
          stateProvince: getAttribute(vehicleLocationEnd, 'stateProvince'),
          aprxShortName: getAttribute(vehicleLocationEnd, 'aprxShortName'),
          locationDescriptionUS: getAttribute(vehicleLocationEnd, 'locationDescriptionUS'),
        },
        stateProvince: getAttribute(iftaRoute, 'stateProvince'),
        vehicleUnitId: getAttribute(iftaRoute, 'vehicleUnitId'),
        iftaRouteDriverPeriods: iftaRouteDriverPeriods.map((iftaRouteDriverPeriod) => {
          const driver = getAttribute(iftaRouteDriverPeriod, 'driver');

          return {
            objectId: iftaRouteDriverPeriod.id,
            dateStart: getAttribute(iftaRouteDriverPeriod, 'dateStart'),
            driver: {
              objectId: driver.id,
              user_fullName: getAttribute(driver, 'user_fullName'),
            },
          };
        }),
        totalVehicleKmStart: getAttribute(iftaRoute, 'totalVehicleKmStart') || 0,
        totalVehicleKmEnd: getAttribute(iftaRoute, 'totalVehicleKmEnd') || 0,
        totalVehicleKmDiff: getAttribute(iftaRoute, 'totalVehicleKmDiff') || 0,
        savedVehicleKm: getAttribute(iftaRoute, 'savedVehicleKm') || 0,
        distanceKm: getAttribute(iftaRoute, 'distanceKm'),
        fuelPurchases: getAttribute(iftaRoute, 'fuelPurchases'),
        // totalGallons: getAttribute(iftaRoute, 'totalGallons'),
        odometerJumpGapKm: 0,
        iftaRouteRecordsArr: [iftaRoute], // Contains all the IFTARoute records which are part of this day
        lastIFTARouteForPrevDay: undefined, // Contains the last IFTARoute record for the previous day
        firstIFTARouteForNextDay: undefined, // Contains the first IFTARoute record for the next day
      };

      return iftaRouteDayObj;
    };

    const updateIFTARouteDayObj = (iftaRouteDayObj, iftaRoute) => {
      // Obtain the relevant information from the current IFTARoute
      const dateEnd = getAttribute(iftaRoute, 'dateEnd');
      const vehicleLocationEnd = getAttribute(iftaRoute, 'vehicleLocationEnd');
      const iftaRouteDriverPeriods = getAttribute(iftaRoute, 'iftaRouteDriverPeriods');
      const totalVehicleKmEnd = getAttribute(iftaRoute, 'totalVehicleKmEnd');
      const totalVehicleKmDiff = getAttribute(iftaRoute, 'totalVehicleKmDiff');
      const distanceKm = getAttribute(iftaRoute, 'distanceKm');
      const savedVehicleKm = getAttribute(iftaRoute, 'savedVehicleKm');
      const fuelPurchases = getAttribute(iftaRoute, 'fuelPurchases');
      // const totalGallons = getAttribute(iftaRoute, 'totalGallons');

      if (iftaRouteDriverPeriods && (iftaRouteDriverPeriods.length > 1)) {
        iftaRouteDayObj.iftaRouteDriverPeriods = iftaRouteDriverPeriods.map((iftaRouteDriverPeriod) => {
          const driver = getAttribute(iftaRouteDriverPeriod, 'driver');

          return {
            objectId: iftaRouteDriverPeriod.id,
            dateStart: getAttribute(iftaRouteDriverPeriod, 'dateStart'),
            driver: {
              objectId: driver.id,
              user_fullName: getAttribute(driver, 'user_fullName'),
            },
          };
        });
      }

      iftaRouteDayObj.vehicleLocationEnd = {
        stateProvince: getAttribute(vehicleLocationEnd, 'stateProvince'),
        aprxShortName: getAttribute(vehicleLocationEnd, 'aprxShortName'),
        locationDescriptionUS: getAttribute(vehicleLocationEnd, 'locationDescriptionUS'),
      };

      iftaRouteDayObj.dateEnd = dateEnd;
      iftaRouteDayObj.savedVehicleKm += savedVehicleKm;
      iftaRouteDayObj.totalVehicleKmEnd = totalVehicleKmEnd;
      iftaRouteDayObj.totalVehicleKmDiff = totalVehicleKmEnd - iftaRouteDayObj.totalVehicleKmStart;
      iftaRouteDayObj.distanceKm += distanceKm;
      if (fuelPurchases && fuelPurchases.length > 0) {
        iftaRouteDayObj.fuelPurchases = iftaRouteDayObj.fuelPurchases.concat(...fuelPurchases);
      }
      // iftaRouteDayObj.totalGallons += totalGallons;
      iftaRouteDayObj.iftaRouteRecordsArr.push(iftaRoute);
    };

    let prevDateEndMoment;

    for (let i = 0; i < props.iftaRouteArr.length; i++) {
      const iftaRoute = props.iftaRouteArr[i]; // Obtain the current IFTARoute record
      const dateStart = getAttribute(iftaRoute, 'dateStart');
      const dateEnd = getAttribute(iftaRoute, 'dateEnd');

      const currentDateStartMoment = moment(dateStart).tz(driverTimeZoneStr);
      const currentDateEndMoment = moment(dateEnd).tz(driverTimeZoneStr);

      // Adding edge case where and IFTARoute ends at midnight - push this IFTARoute to be part of the previous day
      if (currentDateEndMoment.format('HH:ss') === '00:00') currentDateEndMoment.subtract(1, 'second');

      if (i === 0) {
        const newIFTARouteDayObj = generateIFTARouteDayObj(iftaRoute);
        iftaRouteDayArr.push(newIFTARouteDayObj);
      } else if (currentDateStartMoment.format('YYYYMMDD') === prevDateEndMoment.format('YYYYMMDD') && currentDateStartMoment.format('YYYYMMDD') === currentDateEndMoment.format('YYYYMMDD')) {
        // Merge information together, keep all the start values the same, but change the end values
        const previousIFTARouteDayObj = iftaRouteDayArr[iftaRouteDayArr.length - 1];
        updateIFTARouteDayObj(previousIFTARouteDayObj, iftaRoute);
      } else {
        // Create a new IFTARoute for the day and update the values from the previous day
        const newIFTARouteDayObj = generateIFTARouteDayObj(iftaRoute);
        const previousIFTARouteDayObj = iftaRouteDayArr[iftaRouteDayArr.length - 1];

        previousIFTARouteDayObj.odometerJumpGapKm = newIFTARouteDayObj.totalVehicleKmStart - previousIFTARouteDayObj.totalVehicleKmEnd;
        previousIFTARouteDayObj.firstIFTARouteForNextDay = iftaRoute;
        newIFTARouteDayObj.lastIFTARouteForPrevDay = props.iftaRouteArr[i - 1];

        iftaRouteDayArr.push(newIFTARouteDayObj);
      }

      prevDateEndMoment = currentDateEndMoment;
    }

    const iftaRouteDayIssuesArr = findDayViewIssues(iftaRouteDayArr);
    this.setState({ ...this.state, isLoading: false, iftaRouteDayArr, iftaRouteDayIssuesArr });
    props.setData(iftaRouteDayArr);
  }

  generateDayViewTable() {
    const { state, props } = this;

    const showOdometerValues = props.showOdometerValuesFilter.value === 'showOdometerValues';

    if (state.isLoading) {
      return [{
        key: 'loading',
        columns: [{
          element: <div><LoadingIcon /></div>,
          props: { className: 'table-body-column-style verticalAlignMiddle' },
        }],
      }];
    }
    return state.iftaRouteDayArr.map((iftaRoute, index) => {
      const {
        objectId,
        uniqueId,
        dateStart,
        dateEnd,
        vehicleLocationStart,
        vehicleLocationEnd,
        // stateProvince,
        vehicleUnitId,
        iftaRouteDriverPeriods,
        totalVehicleKmStart,
        totalVehicleKmEnd,
        // totalVehicleKmDiff,
        savedVehicleKm,
        // distanceKm,
        fuelPurchases,
        // totalGallons,
        odometerJumpGapKm,
        iftaRouteRecordsArr,
        lastIFTARouteForPrevDay,
        firstIFTARouteForNextDay,
      } = iftaRoute;

      const totalVehicleKmDiff = totalVehicleKmEnd - totalVehicleKmStart || 0;
      const dateStartFormatted = moment(dateStart).tz(state.driverTimeZoneStr).format('YYYY-MM-DD');
      const dateEndFormatted = moment(dateEnd).tz(state.driverTimeZoneStr).format('YYYY-MM-DD');

      // Filters the odometer jumps so that we don't display information which is not useful to client
      const odometerJumpGapKmFiltered = Math.abs(odometerJumpGapKm) < (((moment(dateEnd).diff(moment(dateStart), 'days')) + 1) * 2000) ? odometerJumpGapKm : 0;
      const iftaRouteDayIssuesArr = state.iftaRouteDayIssuesArr.filter((iftaRouteIssueObj) => (iftaRouteIssueObj.uniqueId).includes(uniqueId));

      let prevVehicleLocationEnd;
      let prevEndVehicleLocationStateProvince;
      let prevEndVehicleLocationAprxShortName;

      const fuelCardDataArr = [];

      // Get all the Fuel Card data from all the IFTARoutes of the day
      if (state.iftaFuelCardDataHash) {
        iftaRouteRecordsArr && iftaRouteRecordsArr.map((iftaRouteRecord) => {
          const iftaRouteObjectId = iftaRouteRecord.id;
          if (state.iftaFuelCardDataHash[iftaRouteObjectId]) {
            fuelCardDataArr.push(...state.iftaFuelCardDataHash[iftaRouteObjectId]);
          }
        });
      }

      if (index > 0) {
        prevVehicleLocationEnd = state.iftaRouteDayArr[index - 1].vehicleLocationEnd;

        // Obtain previous day's city + stateProvince
        prevEndVehicleLocationStateProvince = prevVehicleLocationEnd.stateProvince;
        prevEndVehicleLocationAprxShortName = prevVehicleLocationEnd.aprxShortName;
        const prevVehicleLocationEndString = prevVehicleLocationEnd.locationDescriptionUS;
        const prevVehicleLocationEndBreakdown = getLocationDescriptionBreakdown(prevVehicleLocationEndString);
        if (!prevEndVehicleLocationStateProvince) prevEndVehicleLocationStateProvince = prevVehicleLocationEndBreakdown.stateProvince.code.toLowerCase();
        if (!prevEndVehicleLocationAprxShortName) prevEndVehicleLocationAprxShortName = prevVehicleLocationEndBreakdown.city;
      }

      // Obtain current day's start city + stateProvince
      let startVehicleLocationStateProvince = vehicleLocationStart.stateProvince;
      let startVehicleLocationAprxShortName = vehicleLocationStart.aprxShortName;
      const startVehicleLocationString = vehicleLocationStart.locationDescriptionUS;
      const startVehicleLocationBreakdown = getLocationDescriptionBreakdown(startVehicleLocationString);
      if (!startVehicleLocationStateProvince) startVehicleLocationStateProvince = startVehicleLocationBreakdown.stateProvince.code.toLowerCase();
      if (!startVehicleLocationAprxShortName) startVehicleLocationAprxShortName = startVehicleLocationBreakdown.city;

      // Obtain current day's end city + stateProvince
      let endVehicleLocationStateProvince = vehicleLocationEnd.stateProvince;
      let endVehicleLocationAprxShortName = vehicleLocationEnd.aprxShortName;
      const endVehicleLocationString = vehicleLocationEnd.locationDescriptionUS;
      const endVehicleLocationBreakdown = getLocationDescriptionBreakdown(endVehicleLocationString);
      if (!endVehicleLocationStateProvince) endVehicleLocationStateProvince = endVehicleLocationBreakdown.stateProvince.code.toLowerCase();
      if (!endVehicleLocationAprxShortName) endVehicleLocationAprxShortName = endVehicleLocationBreakdown.city;

      let expandableContent;
      if (iftaRouteRecordsArr && iftaRouteRecordsArr.length > 0) {
        expandableContent = (
          <VehicleRouteBreakdownDetailsView
            parseTableBodyRowData={(callRefreshState) => this.props.refreshState(callRefreshState)}
            vehicleUnitId={vehicleUnitId}
            iftaRouteRecordsArr={iftaRouteRecordsArr}
            iftaRouteRecordsForTablePreview={props.iftaRouteArr}
            prevDayIFTARoute={lastIFTARouteForPrevDay}
            nextDayIFTARoute={firstIFTARouteForNextDay}
            distanceUnitFilter={props.distanceUnitFilter}
            volumeUnitFilter={props.volumeUnitFilter}
            showOdometerValuesFilter={props.showOdometerValuesFilter}
            driverTimeZoneStr={state.driverTimeZoneStr}
            iftaRouteDayIssuesArr={iftaRouteDayIssuesArr}
            hideIftaRoute={this.props.hideIftaRoute}
          />
        );
      }

      const hasFuelCardData = fuelCardDataArr && fuelCardDataArr.length !== 0;
      const hasFuelPurchaseData = fuelPurchases && fuelPurchases.length !== 0;
      const hasFuellingData = hasFuelCardData || hasFuelPurchaseData;

      const vehicleRowObj = {
        key: uniqueId,
        props: { expandableContent },
        columns: [
          {
            // Trip Start
            element: (
              <div>
                <b> {dateStartFormatted} </b>
                <br />
                {/* commented out because this keeps whitescreening the page. the conditional here, or in the <span>,
                    needs to be resolved so that the uniqueid exists when this tooltip wants to render
                  !areStatesTouching(startVehicleLocationStateProvince, prevEndVehicleLocationStateProvince) &&
                    <Tooltip target={'.' + uniqueId} />
                */}
                <span
                  className={(index === 0 || (index > 0 && (areStatesTouching(startVehicleLocationStateProvince, prevEndVehicleLocationStateProvince))) ? '' : `errorText boldText ${uniqueId}`)}
                  data-pr-tooltip="State/Province not matching previous"
                >
                  {vehicleLocationStart && `${startVehicleLocationAprxShortName}, ${startVehicleLocationStateProvince.toUpperCase()}`}
                </span>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          {
            // Trip End
            element: (
              <div>
                <b> {dateEndFormatted} </b>
                <br />
                {`${endVehicleLocationAprxShortName}, ${endVehicleLocationStateProvince.toUpperCase()}`}
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          showOdometerValues && {
            // Odometer Start
            element: (
              <div>
                <b>{props.distanceUnitFilter.value === 'km' ? (totalVehicleKmStart || 0).toFixed(0) : Helpers.convertDistance((totalVehicleKmStart || 0), 'km', 'mi').toFixed(0)}</b>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          showOdometerValues && {
            // Odometer End
            element: (
              <div>
                <b>{props.distanceUnitFilter.value === 'km' ? (totalVehicleKmEnd || 0).toFixed(0) : Helpers.convertDistance((totalVehicleKmEnd || 0), 'km', 'mi').toFixed(0)}</b>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          showOdometerValues && {
            // Odometer Diff
            element: (
              <div>
                <b> {props.distanceUnitFilter.value === 'km' ? (totalVehicleKmDiff || 0).toFixed(0) : Helpers.convertDistance((totalVehicleKmDiff || 0), 'km', 'mi').toFixed(0)} </b>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          showOdometerValues && {
            // Jumped Mileage
            element: (
              <div>
                <b> {props.distanceUnitFilter.value === 'km' ? (odometerJumpGapKmFiltered || 0).toFixed(0) : Helpers.convertDistance((odometerJumpGapKmFiltered || 0), 'km', 'mi').toFixed(0)} </b>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          {
            // Fuel Purchase and Fuel Card Transactions
            element: (
              <div className="sb-table-cell-div">

                {hasFuellingData && (
                  <div className="text-sm font-bold pb-2">Fuel Purchases</div>
                )}
                {(!hasFuelPurchaseData && hasFuellingData) && (
                  <p className="text-sm p-0">No Fuel Purchases</p>
                )}
                {fuelPurchases && fuelPurchases.map((fuelPurchase) => {
                  const timeMillis = getAttribute(fuelPurchase, 'timeMillis');
                  const volumeUnits = getAttribute(fuelPurchase, 'volumeUnits');
                  const volumePumped = getAttribute(fuelPurchase, 'volumePumped');
                  const fuelType = getAttribute(fuelPurchase, 'fuelType');
                  const currency = getAttribute(fuelPurchase, 'currency');
                  const totalPaid = getAttribute(fuelPurchase, 'totalPaid');

                  let volumePumpedString;
                  if (props.volumeUnitFilter.value === 'gal') {
                    if (volumeUnits === 'gal') {
                      volumePumpedString = volumePumped.toFixed(2);
                    } else {
                      volumePumpedString = Helpers.convertFuelUnit(volumePumped, 'l', 'gal').toFixed(2);
                    }
                  } else if (props.volumeUnitFilter.value === 'l') {
                    if (volumeUnits === 'gal') {
                      volumePumpedString = Helpers.convertFuelUnit(volumePumped, 'gal', 'l').toFixed(2);
                    } else {
                      volumePumpedString = volumePumped.toFixed(2);
                    }
                  }

                  return (
                    <div>
                      <div key={fuelPurchase.id} className="text-sm">
                        {/* {fuelPurchase && `${moment(timeMillis).format('MMM D')}: `} */}
                        <b>{`${fuelType && `${fuelType && fuelType.toUpperCase()}`}: ${volumePumpedString}`}</b>
                        &nbsp;—&nbsp;
                        <b>{`$${totalPaid} ${currency && currency.toUpperCase()}`}</b>
                      </div>
                    </div>
                  );
                })}

                {hasFuellingData && (
                  <div className="text-sm font-bold pb-2 pt-2">Fuel Card Transactions</div>
                )}
                {(!hasFuelCardData && hasFuellingData) && (
                  <p className="text-sm p-0">No Fuel Card Transactions</p>
                )}
                {fuelCardDataArr && fuelCardDataArr.map((fuelCardData) => {
                  const fuelQuantity = getAttribute(fuelCardData, 'fuelQuantity');
                  const currency = getAttribute(fuelCardData, 'currency');
                  const fuelMeasurementUnit = getAttribute(fuelCardData, 'fuelMeasurementUnit');
                  const total = getAttribute(fuelCardData, 'total');
                  const item = getAttribute(fuelCardData, 'item');

                  let volumePumpedConverted = fuelQuantity;

                  if (fuelMeasurementUnit?.toLowerCase() === 'g' && props.volumeUnitFilter.value === 'l') {
                    volumePumpedConverted = Helpers.convertFuelUnit(fuelQuantity, 'gal', 'l').toFixed(2);
                  } else if (fuelMeasurementUnit?.toLowerCase() === 'l' && props.volumeUnitFilter.value === 'gal') {
                    volumePumpedConverted = Helpers.convertFuelUnit(fuelQuantity, 'l', 'gal').toFixed(2);
                  }

                  return (
                    <div className="text-sm">
                      <div key={fuelCardData.id} className="inlineBlock">
                        <b>{`${item && `${item && item.toUpperCase()}`}: ${volumePumpedConverted}`}</b>
                        &nbsp;—&nbsp;
                        <b>{`$${total} ${currency && currency.toUpperCase()}`}</b>
                      </div>
                    </div>
                  );
                })}
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          // {
          //   // Drivers
          //   element: (
          //     <div>
          //       {iftaRouteDriverPeriods && iftaRouteDriverPeriods.map((driverPeriod) => (
          //         <div key={driverPeriod.objectId}>
          //           <Link
          //             className="inlineBlock clickable textHoverHighlight"
          //             to={{ pathname: '/driver', search: `?driver=${driverPeriod.driver.objectId}&view=hosEvents&date=${moment(driverPeriod.dateStart).tz(state.driverTimeZoneStr).format('DDMMYY')}` }}
          //           >
          //             <b>{driverPeriod.driver && Helpers.toTitleCase(driverPeriod.driver.user_fullName)}</b>
          //           </Link>
          //         </div>
          //       ))}
          //     </div>
          //   ),
          //   props: { className: 'table-body-column-style verticalAlignMiddle' },
          // },
          {
            // Calculated Mileage
            element: (
              <div>
                <b> {props.distanceUnitFilter.value === 'km' ? savedVehicleKm.toFixed(0) : Helpers.convertDistance(savedVehicleKm, 'km', 'mi').toFixed(0)} </b>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          {
            // Warning Icon
            element: (
              <div className="div-align-horizontal-center print-hide">
                {iftaRouteDayIssuesArr.length > 0 && <MDBIcon icon="exclamation-triangle" className="amber-text px-2" />}
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle print-hide-cell' },
          },
        ].filter((row) => row), // Filters out any rows that are false (hiding odometer readings)
      };

      return vehicleRowObj;
    });
  }

  render() {
    const { state, props } = this;
    const tableBodyRows = this.generateDayViewTable();

    const showOdometerValues = props.showOdometerValuesFilter.value === 'showOdometerValues';

    const tableHeaderStyles = {
      dateStart: { width: '15%' },
      dateEnd: { width: '13%' }, // This difference in width is to account for expandable content arrow
      odometer1: showOdometerValues ? { width: '10%' } : { width: '0%' },
      odometer2: showOdometerValues ? { width: '10%' } : { width: '0%' },
      odometer3: showOdometerValues ? { width: '10%' } : { width: '0%' },
      mileage1: showOdometerValues ? { width: '10%' } : { width: '0%' },
      fuel: { width: '20%' },
      mileage2: showOdometerValues ? { width: '7%' } : { width: '10%' },
      warning: { width: '5%' },
    };

    const tableHeaderRows = [{
      key: 'sb-ifta-day-header',
      columns: [
        {
          element: <div>Trip Start</div>,
          props: { style: tableHeaderStyles.dateStart },
        },
        {
          element: <div>Trip End</div>,
          props: { style: tableHeaderStyles.dateEnd },
        }, showOdometerValues && {
          element: <div className="mr-3">{`Odometer Start (${props.distanceUnitFilter.value})`}</div>,
          props: { style: tableHeaderStyles.odometer1 },
        },
        showOdometerValues && {
          element: <div className="mr-3">{`Odometer End (${props.distanceUnitFilter.value})`}</div>,
          props: { style: tableHeaderStyles.odometer2 },
        },
        showOdometerValues && {
          element: <div className="mr-3">{`Odometer Diff (${props.distanceUnitFilter.value})`}</div>,
          props: { style: tableHeaderStyles.odometer3 },
        },
        showOdometerValues && {
          element: <div className="mr-3">{`Jumped Mileage (${props.distanceUnitFilter.value})`}</div>,
          props: { style: tableHeaderStyles.mileage1 },
        },
        {
          element: <div className="mr-3">{`Fuel Purchases & Transactions (${props.volumeUnitFilter.value})`}</div>,
          props: { style: tableHeaderStyles.fuel },
        },
        {
          element: <div className="mr-3">{`Calculated Mileage (${props.distanceUnitFilter.value})`}</div>,
          props: { style: tableHeaderStyles.mileage2 },
        },
        {
          element: <div className="print-hide" />,
          props: { style: tableHeaderStyles.warning, className: "print-hide-cell" },
        },
      ].filter((row) => row), // Filters out any rows that are false (hiding odometer readings)
    }];

    return (
      <React.Fragment>
        <div>
          <h5>
            Unit {props.unitId}: Odometer Readings Breakdown
            <br />
            {`${moment(props.displayStartDate).format('LL')} - ${moment(props.displayEndDate).format('LL')}`}
          </h5>
        </div>
        {/* {state.iftaRouteDayIssuesArr.length > 0 && (
          <SBBlock
            warning
            listMessages={false}
            title="Warning"
            messages={[
              <div style={{ fontSize: '1em' }}>
                There are errors detected in the generated data which may impact your IFTA reporting. Click on the <MDBIcon icon="exclamation-triangle" className="amber-text" /> beside the affected date(s) to view more details and ways to resolve them
              </div>,
            ]}
          />
        )} */}

        {this.props.odometerReadingsDisplayingZero === true && (
          <SBBlock
            error
            listMessages={false}
            title="Note"
            messages={[
              <div style={{ fontSize: '1em' }}>
                It is detected that most or all of the routes are not displaying odometer readings. There might be connection issues with the engine module.
              </div>
            ]}
          />
        )}

        {moment(props.dateEnd).isAfter(moment().subtract(11, 'days')) && (
          <SBBlock
            title="Switchboard processes IFTA vehicle mileages up to 10 days before today's date."
            header="If you are generating reports for the previous month, make sure you generate them on the 11th of this month to get the most updated mileages."
            warning
          />
        )}

        <div>
          <SBTable
            expandable
            hover
            tableHeaderRows={tableHeaderRows}
            tableBodyRows={tableBodyRows}
            isWaiting={state.isLoading}
          />
        </div>
      </React.Fragment>
    );
  }
}

VehicleRouteBreakdownDayViewTableBeta.propTypes = {
  distanceUnitFilter: PropTypes.instanceOf(Object).isRequired,
  iftaRouteArr: PropTypes.instanceOf(Array).isRequired,
  dateStart: PropTypes.instanceOf(Object).isRequired,
  dateEnd: PropTypes.instanceOf(Object).isRequired,
  setData: PropTypes.func.isRequired,
};

export default VehicleRouteBreakdownDayViewTableBeta;
