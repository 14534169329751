import React, { useState, useEffect } from 'react';

// sbCore
import Checkbox from 'sbCore/Checkbox/Checkbox';
import Dropdown from 'sbCore/Dropdown/Dropdown';

// sappy
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Lists
import { canada as canadaCycles, us as usCycles } from 'api/Lists/HoursCycle';

// styles
import './style.scss';

function LogDownloadTypes({ addOns, onSelectAddOns, cycleObject, onSelectCycleObject, belongsToCompany }) {
  const usCyclesArr = Object.keys(usCycles).map((key) => ({ ruleint: key, name: usCycles[key], country: 'us' }));
  // Filter cycles 2, 3, and 5
  const canadaCyclesArr = Object.keys(canadaCycles).filter((key) => !['2', '3', '5'].includes(key)).map((key) => ({ ruleint: Number(key), name: canadaCycles[key], country: 'canada' }));

  function editAddOns(attribute, value) {
    const _addOns = { ...addOns, [attribute]: value };
    if (attribute === 'printRecapTableCanada' && value === true) {
      _addOns.printRecapTableUSA = false;
      onSelectCycleObject(canadaCyclesArr[0]);
    } else if (attribute === 'printRecapTableUSA' && value === true) {
      _addOns.printRecapTableCanada = false;
      onSelectCycleObject(usCyclesArr[0]);
    } else if (attribute === 'disregardLogs' && value === true) {
      _addOns.printCTPAT = true;
      _addOns.printPostTrips = true;
      _addOns.printPreTrips = true;
      _addOns.showHOSViolations = false;
      _addOns.printUDLogs = false;
      _addOns.printRecapTableCanada = false;
      _addOns.printRecapTableUSA = false;
      _addOns.printOriginalLogs = false;
    }
    onSelectAddOns(_addOns);
  }

  // ** Log information templates ** //
  const checkboxesFirstRowTemplate = (
    <div className="d-flex mt-4 mb-2">
      <div className="flex align-items-center w-25">
        <Checkbox checked={addOns.printPreTrips} onChange={(e) => editAddOns('printPreTrips', e.checked)} />
        Pre-Trip Reports
      </div>
      <div className="flex align-items-center w-25">
        <Checkbox checked={addOns.printPostTrips} onChange={(e) => editAddOns('printPostTrips', e.checked)} />
        Post-Trip Reports
      </div>
      <div className="flex align-items-center w-25">
        <Checkbox checked={addOns.printCTPAT} onChange={(e) => editAddOns('printCTPAT', e.checked)} />
        CTPAT/PIP
      </div>
      <div className="flex align-items-center w-25">
        <Checkbox checked={addOns.showHOSViolations} onChange={(e) => editAddOns('showHOSViolations', e.checked)} />
        HOS Violations
      </div>
    </div>
  );

  const checkboxesSecondRowTemplate = (
    <div className="d-flex mb-2">
      <div className="flex align-items-center w-25">
        <Checkbox checked={addOns.printUDLogs} onChange={(e) => editAddOns('printUDLogs', e.checked)} />
        Unidentified Driving Logs
      </div>
      <div className="flex align-items-center w-25">
        <Checkbox checked={addOns.printRecapTableCanada} onChange={(e) => editAddOns('printRecapTableCanada', e.checked)} />
        Recap Table (Canada)
      </div>
      <div className="flex align-items-center w-25">
        <Checkbox checked={addOns.printRecapTableUSA} onChange={(e) => editAddOns('printRecapTableUSA', e.checked)} />
        Recap Table (USA)
      </div>
      <div className="flex align-items-center w-25">
        {addOns.printRecapTableCanada && <Dropdown sbVariant="small" className="w-full" value={cycleObject} onChange={(e) => onSelectCycleObject(e.value)} options={canadaCyclesArr} optionLabel="name" />}
        {addOns.printRecapTableUSA && <Dropdown sbVariant="small" className="w-full" value={cycleObject} onChange={(e) => onSelectCycleObject(e.value)} options={usCyclesArr} optionLabel="name" />}
      </div>
    </div>
  );

  const checkboxesThirdRowTemplate = (
    <div className="d-flex mb-3">
      {/* <div className="flex align-items-center w-25">
        <Checkbox checked={addOns.printOriginalLogs} onChange={(e) => editAddOns('printOriginalLogs', e.checked)} />
        Available Unedited Logs
      </div> */}
      { (['HJIQJUrM65', 'zZoUgHqRNv', 'r58eVtdDEf'].indexOf(getAttribute(belongsToCompany, 'objectId', true)) === -1) && (
        <div className="flex align-items-center w-25">
          <Checkbox checked={addOns.printOriginalLogs} onChange={(e) => editAddOns('printOriginalLogs', e.checked)} />
          Available Unedited Logs
        </div>
      )}
      <div className="flex align-items-center w-25">
        <Checkbox checked={addOns.disregardLogs} onChange={(e) => editAddOns('disregardLogs', e.checked)} />
        Trip Inspections Only
      </div>
    </div>
  );

  const addOnsFieldTemplate = (
    <div className="modal-template-content">
      Select what additional information you would like included.
      {checkboxesFirstRowTemplate}
      {checkboxesSecondRowTemplate}
      {checkboxesThirdRowTemplate}
    </div>
  );

  return (
    <div>
      {addOnsFieldTemplate}
    </div>
  )
}

export default LogDownloadTypes;
