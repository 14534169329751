import React, { useState, useEffect } from 'react';

// sbCore
import Dropdown from 'sbCore/Dropdown/Dropdown';
import InputText from 'sbCore/InputText/InputText';
import Button from 'sbCore/Button/Button';
import Chips from 'sbCore/Chips/Chips';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Badge from 'sbCore/Badge/Badge';
import Tooltip from 'sbCore/Tooltip/Tooltip';

// enums
import { ReferenceNumberType } from 'sb-csapi/dist/enums/Dispatch/ReferenceNumber';
import { QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';

// sb-csapi
import { getAttribute, getCurrentUserSessionToken, getRecordByObjectId } from 'sb-csapi/dist/AAPI';

// sbObject
import DispatchReferenceNumber from 'sbObjects/DispatchReferenceNumber';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// api
import { getDispatchReferenceNumbers, addDispatchReferenceNumber, removeDispatchReferenceNumber } from 'api/Dispatch/DispatchReferenceNumber';
import { updateDispatchJob } from 'api/Dispatch/DispatchJob';

// style
import './style.scss';

/**
 * @description Renders input areas for reference numbers of a dispatch job
 * @param {String} title - Header of the section
 * @param {String} dispatchTransferObjectId - Dispatch transfer object ID
 * @param {String} dispatchJobObjectId - Dispatch job object ID
 * @param {DispatchJob} dispatchJob - Dispatch Job record
 * @param {Function} triggerRefreshState - Invert refreshState boolean
 *
 * @returns
 */
function DispatchReferenceNumbersInput({ ...props }) {
  const [jobReferenceNumber, setJobReferenceNumber] = useState();
  const [newReferenceNumber, setNewReferenceNumber] = useState({type: 0});
  const [referenceNumberChips, setReferenceNumberChips] = useState([]);
  const [isReferenceNumberOutdated, setIsReferenceNumberOutdated] = useState(true);
  const [referenceNumberDuplicateError, setReferenceNumberDuplicateError] = useState(false);

  const selectableReferenceNumberTypes = Object.keys(ReferenceNumberType).map((type) => (
    {
      value: ReferenceNumberType[type].value,
      label: ReferenceNumberType[type].description,
    }
  ));

  // Remove the last element in the array which is 'Seal #' if reference section is not general
  if (props.title !== 'General') {
    selectableReferenceNumberTypes.pop();
  }

  // Fetch all dispatch job reference numbers including:
  // dispatch job reference number, general dispatch reference numbers, and shipment specific reference numbers
  useEffect(() => {
    let didCancel = false;

    if (!isReferenceNumberOutdated) return;

    async function getDispatchJobReferenceNumbers() {
      // This returns reference numbers for general and shipments
      const _dispatchReferenceNumbers = await getDispatchReferenceNumbers(
        undefined, // options
        props.dispatchJobObjectId, // dispatchJobObjectId
        undefined, // filters
        undefined, // sort
        ['dispatchTransfer'], // includes
        undefined, // selects
        undefined, // page
        undefined, // limit
        true, // query all
      );

      let parsedReferenceNumbers = [];

      if (props.dispatchTransferObjectId) {
        // Shipment - filter for same dispatch transfer id from all the reference numbers related to this dispatch job
        parsedReferenceNumbers = _dispatchReferenceNumbers.filter(dispatchReferenceNumber => (
          getAttribute(dispatchReferenceNumber, 'dispatchTransfer') && getAttribute(getAttribute(dispatchReferenceNumber, 'dispatchTransfer'), 'objectId') === props.dispatchTransferObjectId
        )).map(dispatchReferenceNumber => {
          const referenceNumberType = selectableReferenceNumberTypes.find(type => type.value === getAttribute(dispatchReferenceNumber, 'type'));
          return {
            label: `${getAttribute(dispatchReferenceNumber, 'customName', true) || referenceNumberType.label}: ${getAttribute(dispatchReferenceNumber, 'value')}`,
            objectId: getAttribute(dispatchReferenceNumber, 'objectId'),
          };
        });
      } else {
        // General - filter for records that doesn't have dispatch transfer id
        parsedReferenceNumbers = _dispatchReferenceNumbers.filter(dispatchReferenceNumber => !getAttribute(dispatchReferenceNumber, 'dispatchTransfer', true)).map(dispatchReferenceNumber => {
          const referenceNumberType = selectableReferenceNumberTypes.find(type => type.value === getAttribute(dispatchReferenceNumber, 'type'));
          return {
            label: `${getAttribute(dispatchReferenceNumber, 'customName', true) || referenceNumberType.label}: ${getAttribute(dispatchReferenceNumber, 'value')}`,
            objectId: getAttribute(dispatchReferenceNumber, 'objectId'),
          };
        });

        // Seal numbers
        const formattedSealNumbersString = getAttribute(props.dispatchJob, 'sealNumbers', true);
        let sealNumbersArray;
        if (formattedSealNumbersString) {
          sealNumbersArray = formattedSealNumbersString.split(',');
          const sealNumberChips = sealNumbersArray.map(sealNumber => (
            { label: `Seal #: ${sealNumber}` }
          ));
          const _parsedReferenceNumbers = parsedReferenceNumbers.concat(sealNumberChips);
          parsedReferenceNumbers = _parsedReferenceNumbers;
        }
      }

      if (!didCancel) {
        setReferenceNumberChips(parsedReferenceNumbers);
        setJobReferenceNumber(getAttribute(props.dispatchJob, 'referenceNumber'));

        setIsReferenceNumberOutdated(false);
        // Trigger refresh state to update the document
        props.triggerRefreshState();
      }
    }

    getDispatchJobReferenceNumbers();

    return () => { didCancel = true; };

  }, [isReferenceNumberOutdated]);

  // Trigger to refetch the reference numbers when shipment tab changes
  useEffect(() => {
    setIsReferenceNumberOutdated(true);
  }, [props.dispatchTransferObjectId]);

  // Given the label of the chip, get the object Id -> find the record -> destroy the record -> set outdated to refetch referenceNumberChips
  async function handleDelete(currentReferenceNumbers) {
    const removedReferenceNumber = referenceNumberChips.find(item => !currentReferenceNumbers.includes(item.label));

    if (removedReferenceNumber.label.includes('Seal #: ') && !removedReferenceNumber.objectId) {
      handleAddDeleteSealNumber('delete', removedReferenceNumber.label);
      return;
    }

    const _dispatchReferenceNumber = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'DispatchReferenceNumber', removedReferenceNumber.objectId)

    if (_dispatchReferenceNumber) await removeDispatchReferenceNumber(undefined, _dispatchReferenceNumber)

    setIsReferenceNumberOutdated(true);
  }

  // Create and add dispatch reference number record
  async function handleAdd() {
    let _dispatchTransferRecord;

    // Temporary seal number value
    if (newReferenceNumber.type === ReferenceNumberType['SEAL'].value) {
      handleAddDeleteSealNumber('add');
      return;
    }

    const filters = [
      new Filter(QueryRestrictionTypes.EQUAL_TO, 'value', newReferenceNumber.value),
      new Filter(QueryRestrictionTypes.EQUAL_TO, 'type', newReferenceNumber.type),
    ]

    if (props.dispatchTransferObjectId) {
      _dispatchTransferRecord = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'DispatchTransfer', props.dispatchTransferObjectId);

      // Check dispatch transfer for shipment specific duplicate
      filters.push(new Filter(QueryRestrictionTypes.EQUAL_TO, 'dispatchTransfer', _dispatchTransferRecord));
    } else {
      // For general section duplicate, exclude records that has a dispatch transfer attached to them
      filters.push(new Filter(QueryRestrictionTypes.DOES_NOT_EXIST, 'dispatchTransfer'));
    }

    const matchingReferenceNumber = await getDispatchReferenceNumbers(
      undefined, // options
      props.dispatchJobObjectId, // dispatchJobObjectId
      filters, // filters
      undefined, // sort
      undefined, // includes
      undefined, // selects
      undefined, // page
      undefined, // limit
      true, // query all
    )

    // If there's matching reference numbers, then show error
    if (matchingReferenceNumber.length !== 0) {
      setReferenceNumberDuplicateError(true);
      return;
    }

    const newDispatchReferenceNumber = new DispatchReferenceNumber(
      props.dispatchJob,
      _dispatchTransferRecord || undefined,
      newReferenceNumber.value,
      newReferenceNumber.type,
      newReferenceNumber.customName,
    );

    const _newDispatchReferenceRecord = await addDispatchReferenceNumber(
      undefined, // options
      undefined, // table name
      newDispatchReferenceNumber, // dispatch reference number sb object
      undefined, // company id 
    )
    setIsReferenceNumberOutdated(true);
  }

  // Add/Delete logic specifically for seal numbers since they are different from other referencen numbers
  async function handleAddDeleteSealNumber(addEditType, deleteSealNumberLabel) {
    const currentFormattedSealNumbersString = getAttribute(props.dispatchJob, 'sealNumbers', true);

    if (addEditType === 'add') {
      const duplicateSealNumber = currentFormattedSealNumbersString.includes(newReferenceNumber.value);
      if (duplicateSealNumber) {
        setReferenceNumberDuplicateError(true);
        return;
      }

      // Add new number to the string
      let newFormattedSealNumbersString
      if (currentFormattedSealNumbersString !== '') {
        newFormattedSealNumbersString = currentFormattedSealNumbersString.concat(',', newReferenceNumber.value);
      } else {
        newFormattedSealNumbersString = currentFormattedSealNumbersString.concat(newReferenceNumber.value);
      }

      await updateDispatchJob(props.dispatchJob, undefined, { sealNumbers: newFormattedSealNumbersString }, true);

    } else if (addEditType === 'delete') {
      // extract the number from label
      const deleteSealNumberValue = deleteSealNumberLabel.replace('Seal #: ', '');

      const formattedSealNumbersArray = currentFormattedSealNumbersString.split(',');
      // remove number from the sealNumber array
      const newFormattedSealNumbersString = formattedSealNumbersArray.splice(formattedSealNumbersArray.indexOf(deleteSealNumberValue), 1);

      await updateDispatchJob(props.dispatchJob, undefined, { sealNumbers: formattedSealNumbersArray.toString() }, true);
    }

    setIsReferenceNumberOutdated(true);
  }

  // Update reference number in dispatch job record on blur
  async function handleUpdateOnBlur() {
    const _updatedDispatchJob = await updateDispatchJob(props.dispatchJob, undefined, { referenceNumber: jobReferenceNumber }, true);

    setIsReferenceNumberOutdated(true);
  }

  // tooltip text
  const tooltipGeneral = 'These numbers are for the entirety of the Job';
  const tooltipShipment = 'These numbers are specific to the currently selected shipment';

  return (
    <div className="flex flex-column row-gap-2">
      <div className="flex flex-row column-gap-2">
        <div className="text-lg font-medium">{props.title}</div>
        <Badge value="?" className={`tooltip-badge dispatch-reference-${props.title}`} tooltip="its a tooltip"/>
        <Tooltip target={`.dispatch-reference-${props.title}`}>
          {props.title === 'General' ? tooltipGeneral : tooltipShipment}
        </Tooltip>
      </div>

      {props.title === 'General' && (
        <>
          <div className="flex flex-row column-gap-1">
            <div>
              <InputLabel>Main Job Ref #</InputLabel>
              <InputText
                className="max-w-11rem"
                value={jobReferenceNumber}
                onChange={(e) => setJobReferenceNumber(e.target.value)}
                onBlur={() => handleUpdateOnBlur()}
              />
            </div>
            <div>
              <InputLabel>Customer Ref #</InputLabel>
              <div style={{ paddingTop: '0.7em'}}>
                {`${jobReferenceNumber ? jobReferenceNumber : 'Same as Job Ref #'}`}
              </div>
            </div>
          </div>
          <hr className="w-full" />
        </>
      )}

      <div className="flex flex-row column-gap-1">
        <div>
          <InputLabel>Type</InputLabel>
          <Dropdown
            value={newReferenceNumber.type}
            options={selectableReferenceNumberTypes}
            onChange={(e) => {
              setNewReferenceNumber({ ...newReferenceNumber, type: e.value});
              setReferenceNumberDuplicateError(false);
            }}
          />
        </div>
        {newReferenceNumber.type === ReferenceNumberType['CUSTOM'].value && (
          <div>
            <InputLabel>Name</InputLabel>
            <InputText
              placeholder="Custom Name"
              className="max-w-11rem"
              value={newReferenceNumber.customName}
              onChange={(e) => {
                setNewReferenceNumber({ ...newReferenceNumber, customName: e.target.value});
                setReferenceNumberDuplicateError(false);
              }}
              keyfilter="alphanum"
            />
          </div>
        )}
        <div>
          <InputLabel>Reference #</InputLabel>
          <InputText
            placeholder="Reference #"
            className="max-w-11rem"
            value={newReferenceNumber.value}
            onChange={(e) => {
              setNewReferenceNumber({ ...newReferenceNumber, value: e.target.value});
              setReferenceNumberDuplicateError(false);
            }}
            keyfilter="alphanum"
          />
        </div>
        <div className="flex align-items-end">
          <Button
            className="p-button-sm h-3rem"
            icon="pi pi-plus"
            disabled={newReferenceNumber.value === '' || newReferenceNumber.value === undefined}
            onClick={() => handleAdd()}
            label="Add"
          />
        </div>
      </div>
      {referenceNumberDuplicateError && <InputLabel error>Duplicate reference number</InputLabel>}
      <div className="w-full">
        {referenceNumberChips.length !== 0 && 
          <Chips
            className="w-full"
            value={referenceNumberChips.map(item => item.label)}
            onChange={(e) => handleDelete(e.value)} 
          />
        }
      </div>
    </div>
  )
}

export default DispatchReferenceNumbersInput;
