import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from 'sbHistory';
import { setQueryRestriction } from 'api/Parse';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';
import { FlatButton, RaisedButton } from 'material-ui';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import CircularProgress from 'material-ui/CircularProgress';

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import { getQueryParameter } from 'api/URL';
import Filter from 'sbObjects/Filter';


// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import HOSRegulationIcon from 'components/HOSRegulationIcon/view/HOSRegulationIcon';
import Title from 'components/LayoutTitle/view/Title';
import SBSelect from 'components/Shared/SBSelect/SBSelect';

import styles from './HOSViolationsLayout.module.scss';
import { getAttribute } from '../../api/Parse';

class HOSViolationsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hosViolationArr: [],
      month: props.month,
      isLoading: false,
    };

    this.tableHeight = document.documentElement.clientHeight * 0.6; // 60vh defined by CSS
    if (document.documentElement.clientHeight <= 1366) { // laptop size
      this.tableHeight = document.documentElement.clientHeight * 0.6;
    }
    this.rowNum = Math.round(this.tableHeight / 36) + 5; // amount of el's to display
    this.pageCount = 0; // result fetch multiplier

    this.getHOSViolations = this.getHOSViolations.bind(this);
    this.updateNote = this.updateNote.bind(this);
    this.hideUnhideHosViolation = this.hideUnhideHosViolation.bind(this);
  }

  componentDidMount() {
    this.getHOSViolations(this.props.startDate, this.props.endDate);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !moment(nextProps.startDate).isSame(moment(this.props.startDate)) ||
      !moment(nextProps.endDate).isSame(moment(this.props.endDate))
    ) {
      this.getHOSViolations(nextProps.startDate, nextProps.endDate);
    }
  }

  getHOSViolations(startDate, endDate) {
    // console.log(moment(this.props.month).startOf('month').toDate(),moment(this.props.month).endOf('month').toDate());
    // console.log(getAttribute(this.props.driver, 'user_fullName'),this.props.driver);
    const driverTimezone = getAttribute(this.props.driver, 'timezoneOffsetFromUTC');

    const adjustedStartDate = Helpers.referenceToTimezone(moment(startDate), driverTimezone).toDate();
    const adjustedEndDate = Helpers.referenceToTimezone(moment(endDate), driverTimezone).toDate();
    this.setState({ ...this.state, isLoading: true }, () => {
      Getters.queryCompanyObjects(
        'HOSViolation',
        this.pageCount,
        this.rowNum,
        [
          { queryType: 'equalTo', name: 'driver', value: this.props.driver },
          { queryType: 'equalTo', name: 'potential', value: false },
          { queryType: 'equalTo', name: 'disabled', value: false },
          { queryType: 'exists', name: 'regulationInt' },
          { queryType: 'greaterThanOrEqualTo', name: 'triggerTime', value: adjustedStartDate },
          { queryType: 'lessThanOrEqualTo', name: 'triggerTime', value: adjustedEndDate },
        ],
        { triggerTime: 'descending' },
        ['driver']
      ).then(hosViolationArr => {
        this.setState({
          ...this.state,
          isLoading: false,
          hosViolationArr: hosViolationArr.filter(hosViolation => (!hosViolation.get('isHidden') && !hosViolation.get('hideForever') && !hosViolation.get('potential'))),
        });
      });
    });
  }

  hideUnhideHosViolation(hosViolation, hideBool) {
    const overrideHideForever = getQueryParameter(null, 'overrideHideForever');
    if (overrideHideForever == 'true') {
      hosViolation.set('hideForever', hideBool);
    }
    hosViolation.set('isHidden', hideBool);
    this.setState({ ...this.state, isLoading: true }, async () => {
      await hosViolation.save();
      this.setState({ ...this.state, isLoading: false });
    });
  }

  updateNote(e, hosViolation) {
    hosViolation.set('note', e.target.value);
    this.setState(this.state);
    hosViolation.save();
  }

  render() {
    const { props, state } = this;
    const filteredViolations = state.hosViolationArr.filter((hosViolation) => !hosViolation.get('isHidden') && !hosViolation.get('hideForever') && !hosViolation.get('potential'));
    if (this.props.violationFilter === 'hideNoViolations' && filteredViolations.length === 0) {
      return null;
    } else {
      return (
        <TableRow>
          <TableRowColumn>
            <b>{ Helpers.toTitleCase(props.driver.get('user_fullName')) }</b>
          </TableRowColumn>
          {/* <TableRowColumn style={{ textAlign: 'center' }}>
            {moment(state.hosViolation.get('triggerTime')).format('LL')}
          </TableRowColumn> */}
          <TableRowColumn style={{ textAlign: 'center' }}>
            { !state.isLoading &&
              <div className={styles.violationIconRow}>
                { state.hosViolationArr.length > 0 && state.hosViolationArr.filter((hosViolation) => (!hosViolation.get('isHidden') && !hosViolation.get('hideForever'))).map((hosViolation) => {
                  return (
                    <div className={styles.violationIcon}>
                      <HOSRegulationIcon
                        key={hosViolation.id}
                        HOSRegulationInt={hosViolation.get('regulationInt')}
                        // dateString={moment(Helpers.referenceToTimezone(hosViolation.get('triggerTime'), props.driver.get('timezoneOffsetFromUTC'))).format('LL')} // causing issues with timezone, sometimes in day after or before?
                      />
                    </div>
                  );
                })}
                { state.hosViolationArr.length === 0 &&
                  <div className={styles.violationIcon}>
                    <HOSRegulationIcon check />
                  </div>
                }
              </div>
            }
            { state.isLoading &&
              <CircularProgress />
            }
          </TableRowColumn>
          <TableRowColumn style={{ textAlign: 'center' }}>
            <MDBBtn
              size="sm"
              color="primary"
              disabled={this.state.printInProgress}
              onClick={() => history.push({ pathname: 'driver', search: 'driver=' + props.driver.id + '&view=hosViolations&date=' + moment(Helpers.referenceToTimezone(moment(this.props.month).startOf('month').toDate(), props.driver.get('timezoneOffsetFromUTC'))).format('DDMMYY') })}
            >
              Details
            </MDBBtn>
            {/* <br />
            { state.hosViolation.get('isHidden') &&
              <MDBBtn
                size="sm"
                color="secondary"
                onClick={() => this.hideUnhideHosViolation(state.hosViolation, false)}
              >
                Unhide
              </MDBBtn>
            }
            { !state.hosViolation.get('isHidden') &&
              <MDBBtn
                size="sm"
                color="info"
                onClick={() => this.hideUnhideHosViolation(state.hosViolation, true)}
              >
                Hide
              </MDBBtn>
            }
          </TableRowColumn>
          <TableRowColumn>
            <label>
              Notes:
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows="5"
            />
            <MDBInput type="textarea" label="Notes" value={state.hosViolation.get('note')} size="sm" onChange={(e) => this.updateNote(e, state.hosViolation)} rows="1" /> */}
          </TableRowColumn>
        </TableRow>
      );
    }
  }
}

HOSViolationsRow.propTypes = {
  driver: PropTypes.object.isRequired,
  month: PropTypes.object,
  violationFilter: PropTypes.object,
};

export default HOSViolationsRow;
