import React from 'react';

// MDB
import { MDBBadge } from 'mdbreact';

// Enums
import { ELDEditCompleted } from 'enums/ELDEditType';

// CSS
import './style.scss';

class HOSEditStatus extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    const { className, status, isExpired } = this.props;
    const _containerClassName = 'sb-hos-status-badge-container';
    let _className = 'sb-hos-status-badge';

    let matchingStatusTypeObject = {};
    Object.keys(ELDEditCompleted).find(key => {
      const statusTypeObject = ELDEditCompleted[key];
      const isMatchingStatus = statusTypeObject.status === status;

      if (isExpired) {
        matchingStatusTypeObject = {
          description: 'Expired'
        };
        _className += ` bg-hos-job-expired`;
      }
      else if (isMatchingStatus) {
        const colorClassKeyExpression = statusTypeObject.colorKey.replace(/_/g, '-').toLowerCase();
        _className += ` bg-hos-job-${colorClassKeyExpression}`;
        matchingStatusTypeObject = statusTypeObject;
      }

      return isMatchingStatus;
    });

    if (className) {
      _className += ` ${className}`;
    }

    let labelForBadge = matchingStatusTypeObject.description;
    if (labelForBadge) labelForBadge = labelForBadge.toUpperCase();

    return (
      <div>
        { matchingStatusTypeObject && 
          (
            <div className={_containerClassName}>
              <MDBBadge
                className={_className}
              >
                { labelForBadge }
              </MDBBadge>
            </div>
          )
        }
      </div>
    );
  }
}

export default HOSEditStatus;
