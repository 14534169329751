import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import { MDBBtn, MDBIcon } from 'mdbreact';

// CSS
import './style.scss';


class SBEditButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      instanceId: uniqid(),
    };
  }


  render() {
    const { containerClassName, disabled, onClick, showCancelButton, showCheckButton } = this.props;
    const { instanceId } = this.state;

    let _containerClassName = `${instanceId} edit-button-container`;
    if (containerClassName) _containerClassName += ` ${containerClassName}`;

    let buttonClassName = 'edit-button';
    let buttonClassification = 'secondary';

    if (showCancelButton) {
      buttonClassName = 'cancel-edit-button';
      buttonClassification = 'danger';
    };

    if (showCheckButton) {
      buttonClassName = 'check-edit-button';
      buttonClassification = 'secondary';
    };

    return (
      <div className={_containerClassName}>
        <MDBBtn
          className={buttonClassName}
          size="sm"
          color={buttonClassification}
          disabled={disabled}
          onClick={(e) => { if (onClick) { onClick(e) } }}
        >
          { showCancelButton || showCheckButton ?
           (showCheckButton ?
            <MDBIcon icon="check" /> 
            :
            <MDBIcon icon="times" />)
            :
            <MDBIcon icon="pen" />
          
          }
        </MDBBtn>
      </div>
    );
  }
}


SBEditButton.propTypes = {
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  showCancelButton: PropTypes.bool, // determines whether to toggle between 'edit' and 'cancel' when the user clicks the button
  showCheckButton: PropTypes.bool,
};


export default SBEditButton;
