import React from 'react';
import PropTypes from 'prop-types';

// Components
import UploadErrorModal from 'components/UploadModal/view/UploadErrorModal';

// CDD
import styles from './UploadFilePicker.module.scss';

class UploadFilePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorShow: false };
    this.uploadFile = this.uploadFile.bind(this);
    this.closeErrorModal = this.closeErrorModal.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.show !== this.props.show) {
      return true;
    }
    if (nextState.errorShow !== this.state.errorShow) {
      return true;
    }
    return false;
  }

  componentWillUpdate(nextProps) {
    if (nextProps.show) {
      document.getElementById('uploadFilePickerInput').click(); // 'uploadFilePickerInput' for unique name
      this.props.handleClose();
    }
  }

  uploadFile(file) {
    if (!/\.(jpe?g|png|pdf)$/i.test(file.name)) {
      this.setState({ ...this.state, errorShow: true });
    } else {
      this.props.uploadHandler(file);
      this.props.handleClose();
    }
  }

  closeErrorModal() {
    this.setState({ ...this.state, errorShow: false });
  }

  render() {
    return (
      <span className={styles.uploadFilePicker}>
        <input className={styles.hiddenInput} type="file" id="uploadFilePickerInput" accept="application/pdf, image/*" onChange={(file) => this.uploadFile(file.target.files[0])} />
        <UploadErrorModal show={this.state.errorShow} handleClose={this.closeErrorModal} />
      </span>
    );
  }
}

UploadFilePicker.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  uploadHandler: PropTypes.func.isRequired,
};

export default UploadFilePicker;
