import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Table } from 'react-bootstrap';

// API
import { getDriverIssues } from 'api/Getters';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
// import styles from './DriverDetailsInfoTable.module.scss';

class DriverIssuesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issuesRows: undefined,
      fetched: false,
    };
    this.refreshState = this.refreshState.bind(this);
  }

  componentDidMount() {
    this.refreshState(this.props.driverObjectId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.driverObjectId !== this.props.driverObjectId) {
      this.refreshState(nextProps.driverObjectId);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.driverObjectId !== this.props.driverObjectId) {
      return true;
    }
    if (nextState !== this.state) {
      return true;
    }
    return false;
  }

  refreshState(driverObjectId) {
    const newState = { ...this.state };
    getDriverIssues(driverObjectId).then(issues => {

      // sort by date occured descending
      const dateSortedIssues = issues.sort((issueA, issueB) => {
        let issueADateTime = issueA.get('dateTime');
        let issueBDateTime = issueB.get('dateTime');
        if (!issueADateTime) {
          issueADateTime = 0;
        } else {
          issueADateTime = issueADateTime.getTime();
        }
        if (!issueBDateTime) {
          issueBDateTime = 0;
        } else {
          issueBDateTime = issueBDateTime.getTime();
        }

        return issueBDateTime - issueADateTime;
      });

      newState.issuesRows = dateSortedIssues.map(issue => {
        return (
          <tr key={issue.id}>
            <td>
              {issue.get('dateTime') &&
                <span>{ moment(issue.get('dateTime')).format('MMMM D, YYYY') }</span>
              }
            </td>
            <td>
              { issue.className.toLowerCase() === 'contravention' ?
                <span>{ `Contravention (${issue.get('contraventionType_type')[0].toUpperCase()}${issue.get('contraventionType_type').slice(1)})` }</span>
                :
                <span>{ `Accident` }</span>
              }
            </td>
            <td>
              { issue.className.toLowerCase() === 'contravention' ?
                <span>{ `Ticket ${issue.get('ticketNumber')} - ${issue.get('description')}` }</span>
                :
                <span>{ `Report ${issue.get('reportNumber')} - ${issue.get('type')} (${issue.get('fault')})` }</span>
              }
            </td>
            <td>{ issue.get('activePoints') }</td>
          </tr>
        );
      });
      newState.fetched = true;
      this.setState(newState);
    });
  }

  render() {
    const { issuesRows, fetched } = this.state;
    return (
      <div className={`subTable ${this.props.className}`}>
        <Table responsive hover>
          <thead>
            <tr>
              <th width="15%">Date</th>
              <th width="15%">Type</th>
              <th width="50%">Description</th>
              <th width="20%">Points</th>
            </tr>
          </thead>
          <tbody>
            {!fetched &&
              <tr><td colSpan="4" className="loadingIconPadding"><LoadingIcon /></td></tr>
            }
            {fetched && issuesRows.length === 0 &&
              <tr><td colSpan="4" className="emptyRowMessage">No associated Contraventions or Accidents</td></tr>
            }
            { this.state.issuesRows }
          </tbody>
        </Table>
      </div>
    );
  }
}

DriverIssuesTable.propTypes = {
  className: PropTypes.string,
  driverObjectId: PropTypes.string,
};

export default DriverIssuesTable;
