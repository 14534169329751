import React from 'react';
import PropTypes from 'prop-types';

// API
import {
  getEquipmentGroupVehicles,
  removeEquipmentFromEquipmentGroup,
  addEquipmentToEquipmentGroup,
} from 'api/Equipment/Groups.old';
import { vehicleTypes } from 'api/Lists/EquipmentTypes';
import { getAttribute } from 'api/Parse';

// Components
import Title from 'components/LayoutTitle/view/Title';
import VehiclesTable from 'components/VehiclesTable/container/VehiclesTable';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import FilterDropdown from 'components/FilterDropdown/view/FilterDropdown';
import VehicleAutocomplete from 'components/Shared/VehicleAutocomplete/VehicleAutocomplete';
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import { MDBBtn, MDBIcon, MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBContainer } from 'mdbreact';

// Enums
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

// sbObjects
import Sort from 'sbObjects/Sort';
import Filter from 'sbObjects/Filter';

class EquipmentGroupVehiclesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Vehicle: { vehicles: [] },
      sort: new Sort('unitId', QuerySortOrderTypes.DESCENDING),
      isLoading: false,
      isLazyLoading: false,
      hasLoadedAll: false,
      page: 0,
      tableHeight: '65vh',
      activeFilter: new Filter('enabled', true, QueryRestrictionTypes.EQUAL_TO),
      searchFilter: [],
      showModal: false,
      selectedVehicle: undefined,
    };
    this.filterFormFields = [
      {
        attrName: 'notes',
        fullName: 'Notes',
        placeholder: '.....',
        type: 'text',
      },
      {
        attrName: 'type',
        fullName: 'Type',
        type: 'select',
        selectArr: vehicleTypes,
      },
      {
        attrName: 'unitId_lowercase',
        fullName: 'Unit ID',
        placeholder: '.....',
        type: 'text',
      },
    ];

    this.vehicleStateTypes = [
      { attrName: '0', placeholder: 'Active Vehicles', enabled: true },
      { attrName: '1', placeholder: 'Inactive Vehicles', enabled: false },
    ];

    // To stop lazy loading in progress safely we reset these states, then we can call getVehicles
    this.freshLoadingState = { page: 0, isLoading: false, isLazyLoading: false, hasLoadedAll: false };

    this.handleRemove = this.handleRemove.bind(this);
    this.getVehicles = this.getVehicles.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    await this.getVehicles();
  }

  async componentDidUpdate(prevProps) {
    const { props } = this;
    if (prevProps.group !== props.group) {
      await this.getVehicles();
    }
  }

  async handleRemove(confirmation, vehicle) {
    const { props } = this;
    if (confirmation) {
      this.setState({ isLoading: true });

      await removeEquipmentFromEquipmentGroup(props.group, vehicle, 'Vehicle');
      this.setState({ isLoading: false, hasLoadedAll: false }, () => this.getVehicles());
    }
  }

  async handleSubmit() {
    const { props, state, freshLoadingState } = this;

    // To disable submit button
    this.setState({ selectedVehicle: undefined });

    // I/O block where user is waiting
    await addEquipmentToEquipmentGroup(props.group, state.selectedVehicle);
    this.setState(freshLoadingState);
    await this.getVehicles();

    // Close modal once complete
    this.setState({ showModal: false });
  }

  // TODO: Stress test (spam it through interaction)
  async getVehicles() {
    const { group } = this.props;
    const { sort, page, isLoading, isLazyLoading, hasLoadedAll, Vehicle, searchFilter, activeFilter } = this.state;
    let { vehicles } = Vehicle;

    if (!isLazyLoading) vehicles = [];

    if (!isLoading && !hasLoadedAll) {
      this.setState({ isLoading: true });

      if (page > 0) this.setState({ isLazyLoading: true });
      const LIMIT = 50;

      const filters = [...searchFilter, activeFilter];

      const fetchedVehiclesObj = await getEquipmentGroupVehicles(group, page, LIMIT, sort, filters);
      const fetchedVehicles = fetchedVehiclesObj.vehicleRecords;
      const fetchedVehiclesCount = fetchedVehiclesObj.vehicleRecordsCount;
      // Append to existing vehicles
      this.setState({
        Vehicle: {
          vehicles: vehicles.concat(fetchedVehicles),
        },
        page: page + 1,
      });

      // Once lazyloader is fetching nothing, stop the loading
      if (fetchedVehiclesCount < LIMIT) {
        this.setState({ hasLoadedAll: true, isLazyLoading: false, page: 0 });
      } else {
        this.setState({ isLazyLoading: true });
      }
      this.setState({ isLoading: false });
    }
  }

  clearFilter() {
    this.setState({ ...this.freshLoadingState, searchFilter: [] }, () => {
      this.getVehicles();
    });
  }

  filter(filterArr) {
    const searchFilter = filterArr.map(object => {
      const { attribute, value, type } = object;
      let queryRestriction = QueryRestrictionTypes.MATCHES;
      // We have two text filters and one selection filter
      if (type !== 'text') queryRestriction = QueryRestrictionTypes.EQUAL_TO;
      return new Filter(attribute, value, queryRestriction);
    });
    this.setState({ ...this.freshLoadingState, searchFilter }, () => {
      this.getVehicles();
    });
  }

  sortHandler(attribute) {
    // sort list of vehicles based on attribute

    const currentSort = this.state.sort; // the current sort, if defined
    let sortOrder;
    let newSort;
    if (currentSort.attribute === attribute) {
      if (currentSort.order === QuerySortOrderTypes.ASCENDING) {
        sortOrder = QuerySortOrderTypes.DESCENDING;
      } else {
        sortOrder = QuerySortOrderTypes.ASCENDING;
      }
      newSort = new Sort(currentSort.attribute, sortOrder);
    } else if (attribute === 'unitId') {
      newSort = new Sort('unitId', QuerySortOrderTypes.ASCENDING);
    } else if (attribute === 'type') {
      newSort = new Sort('type', QuerySortOrderTypes.ASCENDING);
    } else if (attribute === 'nextInspectionDate') {
      newSort = new Sort('nextInspectionDate', QuerySortOrderTypes.ASCENDING);
    }
    // Just in case fallback
    newSort = newSort || currentSort;

    this.setState({ sort: newSort, ...this.freshLoadingState }, () => {
      this.getVehicles();
    });

  }

  render() {
    const { filterFormFields, vehicleStateTypes, state, props } = this;
    const { sort, Vehicle, isLoading, isLazyLoading, tableHeight, activeFilter, showModal } = state;
    return (
      <MDBContainer fluid>
        <MDBRow>
          <MDBCol xs="12" lg="5">
            <Title title={getAttribute(props.group, 'name', true)} />
          </MDBCol>
          <MDBCol xs="12" lg="5">
            <FilterForm
              className=""
              handleFilter={this.filter}
              clearFilter={this.clearFilter}
              fields={filterFormFields}
            />
          </MDBCol>
          <MDBCol xs="12" lg="2" className="my-auto">
            <ActionsContainer>
              <MDBBtn
                size="sm"
                color="primary"
                onClick={() => this.setState({ showModal: true })}
              >
                <MDBIcon className="mr-1" icon="plus" />
                Vehicle
              </MDBBtn>
              <FilterDropdown
                id="vehiclesFilterDropdown"
                className="ml-2 mr-2 d-inline-block mt-0"
                size="md"
                title="Active Vehicles"
                handleFilter={vehicleTypeIndex => {
                  activeFilter.value = vehicleStateTypes[vehicleTypeIndex].enabled;
                  this.setState(
                    {
                      ...this.freshLoadingState,
                      activeFilter,
                    },
                    this.getVehicles,
                  );
                }}
                menuItems={vehicleStateTypes}
              />
            </ActionsContainer>
          </MDBCol>
        </MDBRow>
        <VehiclesTable
          disableVehicle={this.handleRemove}
          Vehicle={Vehicle}
          sortHandler={this.sortHandler}
          sort={sort}
          isLoading={isLoading}
          isLazyLoading={isLazyLoading}
          handleLazyLoad={this.getVehicles}
          height={tableHeight}
          isGroupView
        />
        <MDBModal isOpen={showModal} centered data-backdrop="static">
          <MDBModalHeader>
            Add Vehicle
          </MDBModalHeader>
          <MDBModalBody>
            <VehicleAutocomplete
              onVehicleSelected={(selectedVehicle) => this.setState({ selectedVehicle })}
              allowDeselect
            />
          </MDBModalBody>
          <MDBModalFooter>
            <ActionsContainer>
              <MDBBtn
                color="default"
                size="sm"
                onClick={() => this.setState({ showModal: false, selectedVehicle: undefined })}
              >
                Cancel
              </MDBBtn>
              <MDBBtn
                color="primary"
                size="sm"
                onClick={() => this.handleSubmit()}
                disabled={!state.selectedVehicle}
              >
                Submit
              </MDBBtn>
            </ActionsContainer>
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

EquipmentGroupVehiclesList.propTypes = {
  group: PropTypes.object.isRequired,
};

export default EquipmentGroupVehiclesList;
