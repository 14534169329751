// CSAPI
import { includePointers } from 'sb-csapi/dist/AAPI';

import { addRecord, createQuery, setQueryRestriction, updateRecord, find, destroyRecord, sortQuery, getDirtyHash } from 'api/Parse';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { DispatchReferenceTypes } from 'enums/DispatchReference';
import Sort from 'sbObjects/Sort';
import { getAttribute } from '../Parse';

const DISPATCH_REF_NUM_TABLE = 'DispatchReferenceNumber';

/**
 * @description Adds a reference number for a DispatchJob with given parameters
 * @param {object} DispatchReferenceNumber A sbObject of values to be inserted
 * @returns {Promise} Promise from awaiting on addRecord()
 */
async function addDispatchReferenceNumber(dispatchReferenceSBObject) {
  if (!dispatchReferenceSBObject
      || !dispatchReferenceSBObject.dispatchJob
      || !dispatchReferenceSBObject.value
      || dispatchReferenceSBObject.type === undefined) {
    throw new Error('Valid Dispatch Reference Number Object is required.');
  }

  return await addRecord(DISPATCH_REF_NUM_TABLE, dispatchReferenceSBObject);
}

/**
 * @description Fetches all reference numbers for a dispatchJob according to sortBy order
 * @param {record} dispatchJob Record whose reference numbers we are fetching
 * @param {*} sortBy Sort sbObject defining sort order
 * @returns {Promise} Array of dispatchReferenceNumber records
 */
async function getDispatchReferenceNumbers(dispatchJob, sortBy = new Sort('type', QuerySortOrderTypes.ASCENDING), includedPointers = []) {
  if (!dispatchJob) {
    throw new Error('A valid dispatchJob is required');
  }

  const dispatchReferenceNumberQuery = createQuery(DISPATCH_REF_NUM_TABLE);
  setQueryRestriction(dispatchReferenceNumberQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchJob', dispatchJob);
  sortQuery(dispatchReferenceNumberQuery, sortBy.order, sortBy.attribute);
  if (includedPointers.length > 0) includePointers(dispatchReferenceNumberQuery, includedPointers);

  return await find(dispatchReferenceNumberQuery, false, true);
}

/**
 * @description Removes a single dispatch reference number record
 * @param {record} dispatchReferenceNumber Reference number record to remove
 * @returns {Promise} Promise from awaiting on destroyRecord()
 */
async function removeDispatchReferenceNumber(dispatchReferenceNumber) {
  if (!dispatchReferenceNumber) throw new Error('Valid Dispatch Reference Number Record is required');

  return await destroyRecord(dispatchReferenceNumber);
}

/**
 * @description Updates a dispatch reference number record, optionally saving it to the server
 * @param {record} dispatchReferenceNumber Reference number record to update
 * @param {object} keyAttributeObj Object of new values for specified attributes
 * @param {boolean} save If true, saves to the server
 * @returns {Promise} The updated record from updateRecord()
 */
async function updateDispatchReferenceNumber(dispatchReferenceNumber, keyAttributeObj = {}, save) {
  if (!dispatchReferenceNumber) throw new Error('Valid Dispatch Reference Number Record is required');

  // If 1 or more keys written to -> save, also trim strings
  if (save) {
    const dirtyKeys = getDirtyHash(dispatchReferenceNumber);
    if (Object.keys(dirtyKeys).length === 0) return await updateRecord(dispatchReferenceNumber, keyAttributeObj, false);

    // If value has been written trim it
    const has = Object.prototype.hasOwnProperty;
    if (has.call(dirtyKeys, 'value')) {
      keyAttributeObj = { ...keyAttributeObj, value: dirtyKeys.value.trim() };
    }
    // If customName has been written trim, if type changed to not-custom overwrite customName to undefined
    if (has.call(dirtyKeys, 'customName') || has.call(dirtyKeys, 'type')) {
      let customName;
      if (getAttribute(dispatchReferenceNumber, 'type') === DispatchReferenceTypes.CUSTOM.value) {
        if (dirtyKeys.customName) customName = dirtyKeys.customName.trim();
      }
      keyAttributeObj = { ...keyAttributeObj, customName };
    }
  }

  return await updateRecord(dispatchReferenceNumber, keyAttributeObj, save);
}

export {
  addDispatchReferenceNumber,
  getDispatchReferenceNumbers,
  removeDispatchReferenceNumber,
  updateDispatchReferenceNumber,
};
