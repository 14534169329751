import * as ActionConstants from 'actions/ActionConstants';

export function Auth(state = { status: ActionConstants.LOGGED_OUT }, action) {
  switch (action.type) {
    case ActionConstants.LOGIN_INPROGRESS:
      return {
        status: action.type,
      };
    case ActionConstants.LOGIN_SUCCESS:
      return {
        status: action.type,
      };
    case ActionConstants.LOGIN_ERROR:
      return {
        status: action.type,
        error: action.error,
      };
    case ActionConstants.LOGGED_OUT:
      return {
        status: action.type,
      };
    default:
      return state;
  }
}
