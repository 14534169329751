import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// API
import TouchingStateProvinces from 'api/Lists/TouchingStateProvinces';
import * as Helpers from 'api/Helpers';
import history from 'sbHistory';
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL'

// Components
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import SBTable from 'components/Shared/SBTable/SBTable';
import Checkbox from 'material-ui/Checkbox';
import MapPopup from 'components/IFTARoute/container/MapPopup';
import { MDBBtn, MDBModal, MDBModalBody } from 'mdbreact';
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay';
import FuelHelpDialog from 'components/IFTARoute/view/FuelHelpDialog';

function areStatesTouching(currentStateProvince, previousStateProvince) {
  if (currentStateProvince && previousStateProvince && currentStateProvince !== previousStateProvince && TouchingStateProvinces[currentStateProvince.toUpperCase()]) {
    return (TouchingStateProvinces[currentStateProvince.toUpperCase()].indexOf(previousStateProvince.toUpperCase()) !== -1);
  } else {
    return true;
  }
}

class VehicleRouteBreakdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      selectedIftaRouteArr: [],
      selectedIftaRouteMap: {},
      mapData: {},
      tableBodyRowData: [],
    };
    this.handleSelectIftaRouteArr = this.handleSelectIftaRouteArr.bind(this);
    this.parseTableBodyRowData = this.parseTableBodyRowData.bind(this);
  }

  componentDidMount() {
    this.setState({ ...this.state, loading: true }, () => {
      this.parseTableBodyRowData();
    });
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dateStart !== this.props.dateStart || nextProps.dateEnd !== this.props.dateEnd) {
  //     this.parseTableBodyRowData();
  //   }
  // }

  async parseTableBodyRowData() {
    await this.setState({ ...this.state, loading: true });
    const parse = async () => {
        const filteredIftaRouteArr = this.props.iftaRouteArr.filter((iftaRouteObj) => iftaRouteObj.get('totalVehicleKmStart') && iftaRouteObj.get('totalVehicleKmEnd'));
        const totalSpreadVehicleKm = filteredIftaRouteArr[filteredIftaRouteArr.length - 1] && filteredIftaRouteArr[0] && filteredIftaRouteArr[filteredIftaRouteArr.length - 1].get('totalVehicleKmEnd') - filteredIftaRouteArr[0].get('totalVehicleKmStart');
        let totalSavedVehicleKm = 0;
        for (let i = 0; i < this.props.iftaRouteArr.length; i++) {
          totalSavedVehicleKm += this.props.iftaRouteArr[i].get('savedVehicleKm');
        }
        // Going through by day
        let previousDateMoment = null;
        const tableBodyRowData = [];
        for (let i = 0; i < this.props.iftaRouteArr.length; i++) {
          const iftaRouteObj = this.props.iftaRouteArr[i].toJSON();
          const currentDateMoment = moment(this.props.iftaRouteArr[i].get('dateStart'));
          if (i === 0) {
            tableBodyRowData.push({ ...iftaRouteObj });
            previousDateMoment = currentDateMoment;
          } else if (currentDateMoment.format('YYYYMMDD') === previousDateMoment.format('YYYYMMDD')) {
            // Still in the current day
            const previousIftaRouteObj = tableBodyRowData[tableBodyRowData.length - 1];
            // setting temp vars for table
            previousIftaRouteObj.dateEnd = iftaRouteObj.dateEnd;
            previousIftaRouteObj.vehicleLocationEnd = iftaRouteObj.vehicleLocationEnd;
            previousIftaRouteObj.totalVehicleKmEnd = iftaRouteObj.totalVehicleKmStart || previousIftaRouteObj.totalVehicleKmEnd;
            previousIftaRouteObj.savedVehicleKm += iftaRouteObj.savedVehicleKm;
            previousIftaRouteObj.totalVehicleKmDiff += iftaRouteObj.totalVehicleKmDiff;

            previousIftaRouteObj.iftaRouteDriverPeriods.concat(iftaRouteObj.iftaRouteDriverPeriods);
            previousIftaRouteObj.distanceKm += iftaRouteObj.distanceKm;
          } else {
            const previousIftaRouteObj = tableBodyRowData[tableBodyRowData.length - 1];
            // get jump between days
            previousIftaRouteObj.jumpGapKm = iftaRouteObj.totalVehicleKmStart - previousIftaRouteObj.totalVehicleKmEnd;
            previousIftaRouteObj.spreadKm = (previousIftaRouteObj.savedVehicleKm / totalSavedVehicleKm) * totalSpreadVehicleKm;
            tableBodyRowData.push(iftaRouteObj);
            previousDateMoment = currentDateMoment;
          }
        }
        return tableBodyRowData;
    }
    const tableBodyRowData = await parse();
    this.setState({ ...this.state, loading: false, tableBodyRowData });
    this.props.setData(tableBodyRowData);
  }

  handleSelectIftaRouteArr(selectedIftaRouteJson, checked) {
    const selectedIftaRouteArr = this.state.selectedIftaRouteArr;
    const selectedIftaRouteMap = { ...this.state.selectedIftaRouteMap };
    if (checked) {
      selectedIftaRouteArr.push(selectedIftaRouteJson);
      selectedIftaRouteMap[selectedIftaRouteJson.objectId] = true;
    } else {
      for (let i = 0; i < selectedIftaRouteArr.length; i++) {
        if (selectedIftaRouteArr[i].objectId === selectedIftaRouteJson.objectId) {
          selectedIftaRouteArr.splice(i, 1);
          selectedIftaRouteMap[selectedIftaRouteJson.objectId] = false;
          break;
        }
      }
    }

    this.props.selectedIftaRoute(selectedIftaRouteArr);
    this.setState({ ...this.state, selectedIftaRouteArr, selectedIftaRouteMap });
  }

  render() {
    const tableHeaderStyles = {
      checkbox: { width: '5%' },
      date: { width: '15%' },
      buttons: { width: '15%' },
      stateProvince: { width: '10%' },
      driver: { width: '20%' },
      fuel: { width: '20%' },
      saved: { width: '10%' },
    };
    const tableHeaderRows = [{
      key: 'sb-jobs-list',
      columns: [],
    }];
    // tableHeaderRows[0].columns.push({
    //   element: <div></div>,
    //   props: {
    //     style: tableHeaderStyles.checkbox,
    //     // handleSort: () => { this.handleSort(AttributeTypes.BATCH_ID) },
    //     // isSortActive: (fetchAttributes.sortBy.attribute === AttributeTypes.BATCH_ID),
    //     // sortOrder: fetchAttributes.sortBy.order,
    //   }
    // });
    tableHeaderRows[0].columns.push({
      element: <div>Trip Start</div>,
      props: {
        style: tableHeaderStyles.date,
        // handleSort: () => { this.handleSort(AttributeTypes.BATCH_ID) },
        // isSortActive: (fetchAttributes.sortBy.attribute === AttributeTypes.BATCH_ID),
        // sortOrder: fetchAttributes.sortBy.order,
      }
    });
    tableHeaderRows[0].columns.push({
      element: <div>Trip End</div>,
      props: {
        style: tableHeaderStyles.date,
        // handleSort: () => { this.handleSort(AttributeTypes.BATCH_ID) },
        // isSortActive: (fetchAttributes.sortBy.attribute === AttributeTypes.BATCH_ID),
        // sortOrder: fetchAttributes.sortBy.order,
      }
    });
    tableHeaderRows[0].columns.push({
      element: <div>Odometer Start</div>,
      props: {
        style: tableHeaderStyles.stateProvince,
      }
    });
    tableHeaderRows[0].columns.push({
      element: <div>Odometer End</div>,
      props: {
        style: tableHeaderStyles.stateProvince,
      }
    });
    tableHeaderRows[0].columns.push({
      element: <div>Driver</div>,
      props: {
        style: tableHeaderStyles.driver,
      }
    });
    tableHeaderRows[0].columns.push({
      element: <div>Calculated Mileage ({ this.props.distanceUnitFilter.value })</div>,
      props: {
        style: tableHeaderStyles.saved,
      }
    });
    tableHeaderRows[0].columns.push({
      element: <div>Jumped Mileage ({ this.props.distanceUnitFilter.value })</div>,
      props: {
        style: tableHeaderStyles.saved,
      }
    });

    let tableBodyRows;
    if (this.state.loading) {
      tableBodyRows = [{
        key: 'loading',
        columns: [{
          element: <div><LoadingIcon /></div>,
          props: { className: 'table-body-column-style verticalAlignMiddle' },
        }],
      }];
    } else {
      const tableBodyRowData = this.state.tableBodyRowData;
      tableBodyRows = this.state.tableBodyRowData.map((iftaRoute, index) => {
        const prevDateEnd = index > 0 && moment(tableBodyRowData[index - 1].dateEnd.iso);
        const dateStartFormatted = this.props.vehicleBreakdownFilter.value === 'dayView' ? moment(iftaRoute.dateStart.iso).format('YYYY-MM-DD'): moment(iftaRoute.dateStart.iso).format('ll LT');
        const dateEndFormatted = this.props.vehicleBreakdownFilter.value === 'dayView' ? moment(iftaRoute.dateEnd.iso).format('YYYY-MM-DD') : moment(iftaRoute.dateEnd.iso).format('ll LT');
        const stateProvince = iftaRoute.stateProvince && iftaRoute.stateProvince.toUpperCase();
        const totalVehicleKmStart = iftaRoute.totalVehicleKmStart;
        const totalVehicleKmEnd = iftaRoute.totalVehicleKmEnd;
        const totalVehicleKmStartValue = totalVehicleKmStart ? (this.props.distanceUnitFilter.value === 'km' ? totalVehicleKmStart.toFixed(0) : Helpers.convertDistance(totalVehicleKmStart, 'km', 'mi').toFixed(0)) : 0;
        const totalVehicleKmEndValue = totalVehicleKmEnd ? (this.props.distanceUnitFilter.value === 'km' ? totalVehicleKmEnd.toFixed(0) : Helpers.convertDistance(totalVehicleKmEnd, 'km', 'mi').toFixed(0)) : 0;
        const totalVehicleKmDiffValue = totalVehicleKmEndValue - totalVehicleKmStartValue;
        const vehicleLocationStart = iftaRoute.vehicleLocationStart;
        const vehicleLocationEnd = iftaRoute.vehicleLocationEnd;
        const savedVehicleKm = Math.round(iftaRoute.savedVehicleKm);
        const totalVehicleKmDiff = Math.round(iftaRoute.totalVehicleKmDiff);
        const iftaRouteDriverPeriods = iftaRoute.iftaRouteDriverPeriods;
        const fuelPurchases = iftaRoute.fuelPurchases;
        const distanceKm = Math.round(iftaRoute.distanceKm);
        const jumpGapKm = iftaRoute.jumpGapKm && Math.round(iftaRoute.jumpGapKm);

        const vehicleRowObj = {
          key: iftaRoute.objectId,
          columns: [],
          // props: { expandableContent }
        };
        vehicleRowObj.columns = [];
        // vehicleRowObj.columns.push({ element:
        //   <div>
        //     <Checkbox
        //       onCheck={(e, checked) => this.handleSelectIftaRouteArr(iftaRoute, checked)}
        //       checked={this.state.selectedIftaRouteMap[iftaRoute.objectId]}
        //       style={{ display: 'inline-block', width: '32%', fontSize: '.8em', fontWeight: '400' }}
        //     />
        //   </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        // });
        vehicleRowObj.columns.push({ element:
          <div>
            <b>
              <span className={!prevDateEnd || ((this.props.vehicleBreakdownFilter.value === 'dayView' || moment(iftaRoute.dateStart.iso).isSame(prevDateEnd)) ? '' : `errorText`)}>
                { dateStartFormatted }
              </span>
            </b>
            <br />
            { vehicleLocationStart && `${vehicleLocationStart.aprxShortName}, ${vehicleLocationStart.stateProvince.toUpperCase()}` }
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });
        vehicleRowObj.columns.push({ element:
          <div>
            <b>
              { dateEndFormatted }
            </b>
            <br />
            { vehicleLocationEnd &&
              <span className={(this.props.vehicleBreakdownFilter.value === 'dayView' || areStatesTouching(vehicleLocationStart.stateProvince, vehicleLocationEnd.stateProvince)) ? '' : `errorText boldText`}>
                {`${vehicleLocationEnd.aprxShortName}, ${vehicleLocationEnd.stateProvince.toUpperCase()}`}
              </span>
            }
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });
        if (this.props.vehicleBreakdownFilter.value !== 'dayView') {
          vehicleRowObj.columns.push({ element:
            <div>
              { stateProvince }
            </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
          });
        } else {
          vehicleRowObj.columns.push({ element:
            <div>
              <b>{ totalVehicleKmStartValue }</b>
            </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
          });
          vehicleRowObj.columns.push({ element:
            <div>
              <b>{ totalVehicleKmEndValue }</b>
            </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
          });
        }
        vehicleRowObj.columns.push({ element:
          <div>
            {/* { totalVehicleKmDiff ? (this.props.distanceUnitFilter.value === 'km' ? savedVehicleKm.toFixed(0) : Helpers.convertDistance(totalVehicleKmDiff, 'km', 'mi').toFixed(0))  : 0 } */}
            {iftaRouteDriverPeriods && iftaRouteDriverPeriods.map((driverPeriod) => {
              return (
                <div>
                  <div
                    className="inlineBlock clickable textHoverHighlight"
                    onClick={() => history.push({
                      pathname: 'driver', search: `driver=${driverPeriod.driver.objectId}&view=hosEvents&date=${moment(driverPeriod.dateStart.iso).format('DDMMYY')}`,
                    })}
                  >
                    <b>{ driverPeriod.driver && Helpers.toTitleCase(driverPeriod.driver.user_fullName) }</b>
                    {/* <span>
                      { driverPeriod.dateStart && moment(driverPeriod.dateStart).format('YYYY-MM-DD H:ss') }
                      &nbsp;—&nbsp;
                      { driverPeriod.dateEnd && moment(driverPeriod.dateEnd).format('YYYY-MM-DD H:ss') }
                    </span>
                    &nbsp; */}
                    {/* <span>
                      <MDBBtn
                        size="sm"
                        color="primary"
                        onClick={() => history.push({
                          pathname: 'driver', search: `driver=${driverPeriod.driver.objectId}&view=hosEvents&date=${moment(driverPeriod.dateStart.iso).format('DDMMYY')}`,
                        })
                      }>
                        View Logs
                      </MDBBtn>
                    </span> */}
                  </div>
                </div>
              );
            })}
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });
        vehicleRowObj.columns.push({ element:
          <div>
            <b>
              { totalVehicleKmDiffValue }
            </b>
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });
        vehicleRowObj.columns.push({ element:
          <div>
            <b>
              { jumpGapKm }
            </b>
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });

        return vehicleRowObj;
      });
    }

    return (
      <div>
        { this.state.loading &&
          <LoadingOverlay />
        }
        <div className='vehicleRouteBreakdown-printable'>
          { (moment(this.props.dateEnd).isAfter(moment().subtract(11, 'days'))) &&
            <SBBlock
              title={`Switchboard processes IFTA vehicle mileages up to 10 days before today's date.`}
              header={`If you are generating reports for the previous month, make sure you generate them on the 11th of this month to get the most updated mileages.`}
              warning
            />
          }
          <SBTable
            hover
            tableHeaderRows={tableHeaderRows}
            tableBodyRows={tableBodyRows}
            isWaiting={this.state.loading}
          />
        </div>
        <MDBModal
          centered
          isOpen={this.state.showMapPopup}
          toggle={() => this.toggleMap(false)}
        >
          <MDBModalBody>
            <div style={{ height: '30em', width: '30em' }}>
              { this.state.showMapPopup &&
                <MapPopup
                  { ...this.state.mapData}
                />
              }
            </div>
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
};

// https://miscellaneous-file-store.s3.amazonaws.com/b22bb1453645c59d0bf94bd640b55e11_950a96ad-ea05-4054-a038-8711029befe5.mp4

VehicleRouteBreakdown.propTypes = {
  distanceUnitFilter: PropTypes.object.isRequired,
  iftaRouteArr: PropTypes.array.isRequired,
  dateStart: PropTypes.object.isRequired,
  dateEnd: PropTypes.object.isRequired,
  toggleMap: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
};

export default VehicleRouteBreakdown;
