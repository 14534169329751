export default {
  all: {
    '1': { type: 'Air Compressor' },
    '2': { type: 'Air Lines' },
    '3': { type: 'Battery' },
    '4': { type: 'Belts and Hoses' },
    '5': { type: 'Body' },
    '6': { type: 'Brake Related' },
    '7': { type: 'Clutch' },
    '8': { type: 'Coupling Devices' },
    '9': { type: 'Defroster/Heater' },
    '10': { type: 'Engine' },
    '11': { type: 'Exhaust' },
    '12': { type: 'Fifth Wheel' },
    '13': { type: 'Fluid Levels' },
    '14': { type: 'Frame and Assembly' },
    '15': { type: 'Front Axle' },
    '16': { type: 'Fuel Tanks' },
    '17': { type: 'Horn(s)' },
    '18': { type: 'Lights' },
    '19': { type: 'Mirrors' },
    '20': { type: 'Muffler' },
    '21': { type: 'Oil Pressure' },
    '22': { type: 'Radiator' },
    '23': { type: 'Rear End' },
    '24': { type: 'Reflectors' },
    '25': { type: 'Safety Equipment' },
    '26': { type: 'Starter' },
    '27': { type: 'Steering' },
    '28': { type: 'Suspension' },
    '29': { type: 'Tire Chains' },
    '30': { type: 'Tires' },
    '31': { type: 'Transmission' },
    '32': { type: 'Trip Recorder' },
    '33': { type: 'Wheels and Rims' },
    '34': { type: 'Windows' },
    '35': { type: 'Windshield Wipers/Washers' },
    '36': { type: 'Other' },
    '64': { type: 'Air Brake System' },
    '65': { type: 'Cab' },
    '66': { type: 'Cargo Securement' },
    '67': { type: 'Dangerous Goods' },
    '68': { type: 'Driver Controls' },
    '69': { type: 'Driver Seat' },
    '70': { type: 'Electric Brake System' },
    '71': { type: 'Emergency Equipment and Safety Devices' },
    '72': { type: 'Frame and Cargo Body' },
    '73': { type: 'Fuel System' },
    '74': { type: 'General' },
    '75': { type: 'Glass and Mirrors' },
    '76': { type: 'Heater/Defroste' },
    '77': { type: 'Hydraulic Brake System' },
    '78': { type: 'Lamps and Reflectors' },
    '79': { type: 'Wheels, Hubs and Fasteners' },
    '80': { type: 'Accessibility Devices' },
    '81': { type: 'Doors and Emergency Exits' },
    '82': { type: 'Exterior Body and Frame' },
    '83': { type: 'Passenger Compartment' },
    '6': { type: 'Brake Related' },
    '11': { type: 'Exhaust' },
    '17': { type: 'Horn(s)' },
    '18': { type: 'Lights' },
    '24': { type: 'Reflectors' },
    '27': { type: 'Steering' },
    '28': { type: 'Suspension' },
    '30': { type: 'Tires' },
    '33': { type: 'Wheels and Rims' },
    '35': { type: 'Windshield Wipers/Washers' },
    '36': { type: 'Other' },
    '37': { type: 'Trailer Brake Connections' },
    '38': { type: 'Trailer Brake Related' },
    '39': { type: 'Doors' },
    '40': { type: 'Hitch and Safety Chains' },
    '41': { type: 'Landing Gear' },
    '42': { type: 'Roof' },
    '43': { type: 'Tarpaulin' },
    '64': { type: 'Air Brake System' },
    '65': { type: 'Cab' },
    '66': { type: 'Cargo Securement' },
    '67': { type: 'Dangerous Goods' },
    '68': { type: 'Driver Controls' },
    '69': { type: 'Driver Seat' },
    '70': { type: 'Electric Brake System' },
    '71': { type: 'Emergency Equipment and Safety Devices' },
    '72': { type: 'Frame and Cargo Body' },
    '73': { type: 'Fuel System' },
    '74': { type: 'General' },
    '75': { type: 'Glass and Mirrors' },
    '76': { type: 'Heater/Defroste' },
    '77': { type: 'Hydraulic Brake System' },
    '78': { type: 'Lamps and Reflectors' },
    '79': { type: 'Wheels, Hubs and Fasteners' },
    '80': { type: 'Accessibility Devices' },
    '81': { type: 'Doors and Emergency Exits' },
    '82': { type: 'Exterior Body and Frame' },
    '83': { type: 'Passenger Compartment' },
  },
  1: {
    vehicleDefects: [
      { referenceInt: 1, type: 'Air Compressor' },
      { referenceInt: 2, type: 'Air Lines' },
      { referenceInt: 3, type: 'Battery' },
      { referenceInt: 4, type: 'Belts and Hoses' },
      { referenceInt: 5, type: 'Body' },
      { referenceInt: 6, type: 'Brake Related' },
      { referenceInt: 7, type: 'Clutch' },
      { referenceInt: 8, type: 'Coupling Devices' },
      { referenceInt: 9, type: 'Defroster/Heater' },
      { referenceInt: 10, type: 'Engine' },
      { referenceInt: 11, type: 'Exhaust' },
      { referenceInt: 12, type: 'Fifth Wheel' },
      { referenceInt: 13, type: 'Fluid Levels' },
      { referenceInt: 14, type: 'Frame and Assembly' },
      { referenceInt: 15, type: 'Front Axle' },
      { referenceInt: 16, type: 'Fuel Tanks' },
      { referenceInt: 17, type: 'Horn(s)' },
      { referenceInt: 18, type: 'Lights' },
      { referenceInt: 19, type: 'Mirrors' },
      { referenceInt: 20, type: 'Muffler' },
      { referenceInt: 21, type: 'Oil Pressure' },
      { referenceInt: 22, type: 'Radiator' },
      { referenceInt: 23, type: 'Rear End' },
      { referenceInt: 24, type: 'Reflectors' },
      { referenceInt: 25, type: 'Safety Equipment' },
      { referenceInt: 26, type: 'Starter' },
      { referenceInt: 27, type: 'Steering' },
      { referenceInt: 28, type: 'Suspension' },
      { referenceInt: 29, type: 'Tire Chains' },
      { referenceInt: 30, type: 'Tires' },
      { referenceInt: 31, type: 'Transmission' },
      { referenceInt: 32, type: 'Trip Recorder' },
      { referenceInt: 33, type: 'Wheels and Rims' },
      { referenceInt: 34, type: 'Windows' },
      { referenceInt: 35, type: 'Windshield Wipers/Washers' },
      { referenceInt: 36, type: 'Other' },
      { referenceInt: 64, type: 'Air Brake System' },
      { referenceInt: 65, type: 'Cab' },
      { referenceInt: 66, type: 'Cargo Securement' },
      { referenceInt: 67, type: 'Dangerous Goods' },
      { referenceInt: 68, type: 'Driver Controls' },
      { referenceInt: 69, type: 'Driver Seat' },
      { referenceInt: 70, type: 'Electric Brake System' },
      { referenceInt: 71, type: 'Emergency Equipment and Safety Devices' },
      { referenceInt: 72, type: 'Frame and Cargo Body' },
      { referenceInt: 73, type: 'Fuel System' },
      { referenceInt: 74, type: 'General' },
      { referenceInt: 75, type: 'Glass and Mirrors' },
      { referenceInt: 76, type: 'Heater/Defroste' },
      { referenceInt: 77, type: 'Hydraulic Brake System' },
      { referenceInt: 78, type: 'Lamps and Reflectors' },
      { referenceInt: 79, type: 'Wheels, Hubs and Fasteners' },
    ],
    trailerDefects: [
      { referenceInt: 6, type: 'Brake Related' },
      { referenceInt: 11, type: 'Exhaust' },
      { referenceInt: 17, type: 'Horn(s)' },
      { referenceInt: 18, type: 'Lights' },
      { referenceInt: 24, type: 'Reflectors' },
      { referenceInt: 27, type: 'Steering' },
      { referenceInt: 28, type: 'Suspension' },
      { referenceInt: 30, type: 'Tires' },
      { referenceInt: 33, type: 'Wheels and Rims' },
      { referenceInt: 35, type: 'Windshield Wipers/Washers' },
      { referenceInt: 36, type: 'Other' },
      { referenceInt: 37, type: 'Trailer Brake Connections' },
      { referenceInt: 38, type: 'Trailer Brake Related' },
      { referenceInt: 39, type: 'Doors' },
      { referenceInt: 40, type: 'Hitch and Safety Chains' },
      { referenceInt: 41, type: 'Landing Gear' },
      { referenceInt: 42, type: 'Roof' },
      { referenceInt: 43, type: 'Tarpaulin' },
      { referenceInt: 64, type: 'Air Brake System' },
      { referenceInt: 65, type: 'Cab' },
      { referenceInt: 66, type: 'Cargo Securement' },
      { referenceInt: 67, type: 'Dangerous Goods' },
      { referenceInt: 68, type: 'Driver Controls' },
      { referenceInt: 69, type: 'Driver Seat' },
      { referenceInt: 70, type: 'Electric Brake System' },
      { referenceInt: 71, type: 'Emergency Equipment and Safety Devices' },
      { referenceInt: 72, type: 'Frame and Cargo Body' },
      { referenceInt: 73, type: 'Fuel System' },
      { referenceInt: 74, type: 'General' },
      { referenceInt: 75, type: 'Glass and Mirrors' },
      { referenceInt: 76, type: 'Heater/Defroste' },
      { referenceInt: 77, type: 'Hydraulic Brake System' },
      { referenceInt: 78, type: 'Lamps and Reflectors' },
      { referenceInt: 79, type: 'Wheels, Hubs and Fasteners' },
    ],
  },
  2: {
    vehicleDefects: [
      { referenceInt: 1, type: 'Air Compressor' },
      { referenceInt: 2, type: 'Air Lines' },
      { referenceInt: 3, type: 'Battery' },
      { referenceInt: 4, type: 'Belts and Hoses' },
      { referenceInt: 5, type: 'Body' },
      { referenceInt: 6, type: 'Brake Related' },
      { referenceInt: 7, type: 'Clutch' },
      { referenceInt: 8, type: 'Coupling Devices' },
      { referenceInt: 9, type: 'Defroster/Heater' },
      { referenceInt: 10, type: 'Engine' },
      { referenceInt: 11, type: 'Exhaust' },
      { referenceInt: 12, type: 'Fifth Wheel' },
      { referenceInt: 13, type: 'Fluid Levels' },
      { referenceInt: 14, type: 'Frame and Assembly' },
      { referenceInt: 15, type: 'Front Axle' },
      { referenceInt: 16, type: 'Fuel Tanks' },
      { referenceInt: 17, type: 'Horn(s)' },
      { referenceInt: 18, type: 'Lights' },
      { referenceInt: 19, type: 'Mirrors' },
      { referenceInt: 20, type: 'Muffler' },
      { referenceInt: 21, type: 'Oil Pressure' },
      { referenceInt: 22, type: 'Radiator' },
      { referenceInt: 23, type: 'Rear End' },
      { referenceInt: 24, type: 'Reflectors' },
      { referenceInt: 25, type: 'Safety Equipment' },
      { referenceInt: 26, type: 'Starter' },
      { referenceInt: 27, type: 'Steering' },
      { referenceInt: 28, type: 'Suspension' },
      { referenceInt: 29, type: 'Tire Chains' },
      { referenceInt: 30, type: 'Tires' },
      { referenceInt: 31, type: 'Transmission' },
      { referenceInt: 32, type: 'Trip Recorder' },
      { referenceInt: 33, type: 'Wheels and Rims' },
      { referenceInt: 34, type: 'Windows' },
      { referenceInt: 35, type: 'Windshield Wipers/Washers' },
      { referenceInt: 36, type: 'Other' },
      { referenceInt: 64, type: 'Air Brake System' },
      { referenceInt: 65, type: 'Cab' },
      { referenceInt: 66, type: 'Cargo Securement' },
      { referenceInt: 67, type: 'Dangerous Goods' },
      { referenceInt: 68, type: 'Driver Controls' },
      { referenceInt: 69, type: 'Driver Seat' },
      { referenceInt: 70, type: 'Electric Brake System' },
      { referenceInt: 71, type: 'Emergency Equipment and Safety Devices' },
      { referenceInt: 72, type: 'Frame and Cargo Body' },
      { referenceInt: 73, type: 'Fuel System' },
      { referenceInt: 74, type: 'General' },
      { referenceInt: 75, type: 'Glass and Mirrors' },
      { referenceInt: 76, type: 'Heater/Defroste' },
      { referenceInt: 77, type: 'Hydraulic Brake System' },
      { referenceInt: 78, type: 'Lamps and Reflectors' },
      { referenceInt: 79, type: 'Wheels, Hubs and Fasteners' },
      { referenceInt: 80, type: 'Accessibility Devices' },
      { referenceInt: 81, type: 'Doors and Emergency Exits' },
      { referenceInt: 82, type: 'Exterior Body and Frame' },
      { referenceInt: 83, type: 'Passenger Compartment' },
    ],
    trailerDefects: [
      { referenceInt: 6, type: 'Brake Related' },
      { referenceInt: 11, type: 'Exhaust' },
      { referenceInt: 17, type: 'Horn(s)' },
      { referenceInt: 18, type: 'Lights' },
      { referenceInt: 24, type: 'Reflectors' },
      { referenceInt: 27, type: 'Steering' },
      { referenceInt: 28, type: 'Suspension' },
      { referenceInt: 30, type: 'Tires' },
      { referenceInt: 33, type: 'Wheels and Rims' },
      { referenceInt: 35, type: 'Windshield Wipers/Washers' },
      { referenceInt: 36, type: 'Other' },
      { referenceInt: 37, type: 'Trailer Brake Connections' },
      { referenceInt: 38, type: 'Trailer Brake Related' },
      { referenceInt: 39, type: 'Doors' },
      { referenceInt: 40, type: 'Hitch and Safety Chains' },
      { referenceInt: 41, type: 'Landing Gear' },
      { referenceInt: 42, type: 'Roof' },
      { referenceInt: 43, type: 'Tarpaulin' },
      { referenceInt: 64, type: 'Air Brake System' },
      { referenceInt: 65, type: 'Cab' },
      { referenceInt: 66, type: 'Cargo Securement' },
      { referenceInt: 67, type: 'Dangerous Goods' },
      { referenceInt: 68, type: 'Driver Controls' },
      { referenceInt: 69, type: 'Driver Seat' },
      { referenceInt: 70, type: 'Electric Brake System' },
      { referenceInt: 71, type: 'Emergency Equipment and Safety Devices' },
      { referenceInt: 72, type: 'Frame and Cargo Body' },
      { referenceInt: 73, type: 'Fuel System' },
      { referenceInt: 74, type: 'General' },
      { referenceInt: 75, type: 'Glass and Mirrors' },
      { referenceInt: 76, type: 'Heater/Defroste' },
      { referenceInt: 77, type: 'Hydraulic Brake System' },
      { referenceInt: 78, type: 'Lamps and Reflectors' },
      { referenceInt: 79, type: 'Wheels, Hubs and Fasteners' },
      { referenceInt: 80, type: 'Accessibility Devices' },
      { referenceInt: 81, type: 'Doors and Emergency Exits' },
      { referenceInt: 82, type: 'Exterior Body and Frame' },
      { referenceInt: 83, type: 'Passenger Compartment' },
    ],
  }
};
