import React from 'react';
import PropTypes from 'prop-types';

// CSS
import './style.scss';

class ActionsContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { className, leftAlign, children } = this.props;

    let _className = 'd-flex actions-container';
    if (leftAlign) {
      _className += ` left-align`;
    }
    if (className) _className += ` ${className}`;

    return (
      <div className={_className}>
        <div className="d-inline-block">
          { children }
        </div>
      </div>
    );
  }
}

ActionsContainer.defaultProps =  {
  unreadCount: 0,
  isPanelExpanded: false,
};

ActionsContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftAlign: PropTypes.bool, // shift contents of action container to left
};

export default ActionsContainer;
