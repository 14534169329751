import React, { useEffect } from 'react';

// api
import uniqid from 'uniqid';
import { identify, group } from 'sb-csapi/dist/api/Analytics/Product';
import { getAttribute, getCurrentUser, getCurrentUserSessionToken, getRecordByObjectId } from 'sb-csapi/dist/AAPI';

/**
 * @description A component to initialize product analytics /tracking/ and also initializes subscription/permission information.
 *      Notes: Initialization of the Product Analytics API itself occurs in a different file with more global scope to handle different
 *             use-cases outside user/company. This initialization is to start the tracking of a user/company entity
 * @param {Function} onInitialized - Callback for when this component is initialized
 * @returns {JSX} - Returns Nothing
 */
function PASubPermInitializer({ ...props }) {

  const currentUser = getCurrentUser();

  // ** useEffects ** //
  useEffect(() => {
    let didCancel = false;

    // @description init/refresh sub and perm info
    async function _refreshUserSubscriptionPermission() {
      let belongsToCompany = getAttribute(currentUser, 'belongsToCompany', true); // we can't use this one since it's a shallow copy. we have to re-retrieve it
      if (currentUser && belongsToCompany) {
        belongsToCompany = await getRecordByObjectId(
          { sessionToken: getCurrentUserSessionToken() },
          'Company',
          getAttribute(belongsToCompany, 'objectId'),
        );
      }

      const shouldIdentifyAnonymousUsers = false;

      // default ids
      const uqid = uniqid();
      window.sbProdAnalEntityId = `anon-entity-${uqid}`; // anonymous user if no user object
      window.sbProdAnalGroupId = `anon-group-${uqid}`;
      window.sbProdAnalEntityIsAnon = true;
      window.sbProdAnalGroupIsAnon = true;

      // set traits/properties
      if (currentUser) {
        window.sbProdAnalEntityId = getAttribute(currentUser, 'objectId');
        window.sbProdAnalEntityIsAnon = false;
      }

      if (belongsToCompany) {
        window.sbProdAnalGroupId = getAttribute(belongsToCompany, 'objectId');
        window.sbProdAnalIsAnon = false;
      }

      window.sbProdAnalIsAnon = window.sbProdAnalEntityIsAnon || window.sbProdAnalGroupIsAnon;

      if (shouldIdentifyAnonymousUsers || !window.sbProdAnalIsAnon) {
        // identify both as the user and as the company
        identify(window.sbProdAnalEntityId, {}, getCurrentUserSessionToken(), currentUser);
        identify(window.sbProdAnalGroupId, {}, undefined, undefined, belongsToCompany);

        group(window.sbProdAnalEntityId, window.sbProdAnalGroupId, {}, belongsToCompany);
        group(window.sbProdAnalGroupId, window.sbProdAnalGroupId, {}, belongsToCompany);
      }

      if (!didCancel) props.onInitialized(true);
    }

    _refreshUserSubscriptionPermission();

    return () => { didCancel = true; };
  }, []);

  return <span />;
}

export default PASubPermInitializer;
