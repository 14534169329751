import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import history from "../../sbHistory";

import { Card, CardHeader, CardText } from 'material-ui/Card';
import Checkbox from 'material-ui/Checkbox';
import CircularProgress from 'material-ui/CircularProgress';
import { ButtonGroup, Button } from 'react-bootstrap';
import { MDBContainer, MDBBtn, MDBRow, MDBCol, MDBInput, MDBIcon } from 'mdbreact';

// sbCore
import { Chip } from 'primereact/chip';
import Chips from 'sbCore/Chips/Chips';

// Actions
import { selfUpdateCompany, uploadLogo } from 'actions/Company';

// API
import { isUserAdmin, queryCompanyObjects, getCurrentUser } from 'api/Getters';
import { updateDispatcher, createNewAddress, updateParseObject, uploadImage, addRecord } from 'api/Setters';
import { compressWhitespace, isSubscribedToModule, isInvalidEmailFormat, convertDistance, getCompanyReadWriteACL } from 'api/Helpers';
import { updateAllDriversDriverModule } from 'api/DriverModule/DriverModule';
import isValidDOTNumber from 'api/DOTNumber';
import { createQuery, findRecords, getAttribute, getCurrentUserSessionToken, getRecordByObjectId, setQueryRestriction, updateRecord } from 'sb-csapi/dist/AAPI';

// Components
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import Snackbar from 'material-ui/Snackbar';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import Toggle from 'material-ui/Toggle';
import Tooltip from 'sbCore/Tooltip/Tooltip';

import CompanyUsersTable from 'components/CompanyUsersTable/container/CompanyUsersTable';
import LinkedCompanies from 'components/LinkedCompanies/container/LinkedCompanies';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import ChangePassword from 'components/ChangePassword/container/ChangePassword';
import TimezonePicker from 'react-timezone';
import ListSelectField from 'components/ListSelectField/view/ListSelectField';
import ErrorList from 'components/ErrorList/view/ErrorList';
import PaymentModal from 'components/PaymentModal/container/paymentModal';

// CSS
import styles from './AccountLayout.module.scss';

// Lists
import { Countries } from 'api/Lists/Countries';
import { canada as canadaCycles, us as usCycles, getReferenceIntFromDescription } from 'api/Lists/HoursCycle';
import { StateProvinces } from 'api/Lists/StateProvinces';
import { CurrenciesShort } from 'api/Lists/Currencies';

// Enums
import { PCYMCountryRestriction, PCYMCountryRestrictionDescription } from 'sb-csapi/dist/enums/Driver/SpecialStatus/PCYMCountryRestriction';
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

class AccountLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userObject: (this.props.User && this.props.User.user && this.props.User.user.id) ? this.props.User.user : {},
      userNotificationSetting: {
      },
      timezone: '',
      distanceUnit: 'km',
      volumeUnit: 'gal',
      currency: 'CAD',
      company: {
        speedLimit: null,
        address: { // Home Terminal
          city: '',
          stateProvince: '',
          country: '',
          zipPostal: '',
        },
        addressBusiness: { // Principal Place of Business
          city: '',
          stateProvince: '',
          country: '',
          zipPostal: '',
        },
        eldCompliant: (this.props.company && this.props.company.id) ? this.props.company.get('eldCompliant') : undefined,
        dotNumber: '',
        nscNumber: '',
        name: '',
        disableWalkthrough: false,
        ctpat: false,
        disableDriverCACycleHourSelect: false,
        disableDriverUSCycleHourSelect: false,
        disablePCYMCountryInt: PCYMCountryRestriction.NONE,
      },
      companyModule: undefined,
      dispatcher: {

      },
      companyErrors: [],
      companyUpdating: false,
      aobrdSetting: {
        aobrdEnabled: false,
        deviceMode: 'eld',
        minSpeed: 0,
      },
      uploadLogo: {
        inProgress: false,
        error: '',
      },
      uploadDefaultVehicleImage: {
        inProgress: false,
        error: '',
      },
      loading: {
        emailNotifications: false,
        geofenceNotifications: false,
      },
      trailerLockTypes: {
        'UNLOCKED': 0,
        'LOCKED': 1,
        'HYBRID': 2,
      },
      saved: false,
      payment: {
        show: false,
      },
    };

    if (props.Dispatcher.dispatcher.id) {
      this.state.timezone = props.Dispatcher.dispatcher.get('timezoneOffsetFromUTC');
      this.state.distanceUnit = props.Dispatcher.dispatcher.get('distanceUnit') || 'mi';
      this.state.volumeUnit = props.Dispatcher.dispatcher.get('volumeUnit') || 'gal';
    }

    this.refreshState = this.refreshState.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateCompanyAddress = this.updateCompanyAddress.bind(this);
    this.updateCompany = this.updateCompany.bind(this);
    this.updateAOBRDSetting = this.updateAOBRDSetting.bind(this);
    this.handleUploadLogo = this.handleUploadLogo.bind(this);
    this.handleUploadDefaultVehicleImage = this.handleUploadDefaultVehicleImage.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    this.toggleAOBRDDriverSelfEdit = this.toggleAOBRDDriverSelfEdit.bind(this);
    this.updateUserNotificationSetting = this.updateUserNotificationSetting.bind(this);
    this.updatePCYMCountryRestriction = this.updatePCYMCountryRestriction.bind(this);
    this.updateCompanyModule = this.updateCompanyModule.bind(this);
  }

  componentDidMount() {
    this.refreshState(this.props.Dispatcher.dispatcher, this.props.company, this.props.User.user);
  }

  componentWillReceiveProps(nextProps) {
    this.refreshState(nextProps.Dispatcher.dispatcher, nextProps.company, nextProps.User.user);
  }

  async refreshState(dispatcher, company, user) {
    const aobrdSetting = company.get('aobrdSetting');

    // Fetch the companyModule of the company - if it exists
    // Even though there is a companyModule pointer on company, we are not using it in this case since there is no automatic link set up
    // between creating a companyModule record and attaching that pointer to the Company record
    const companyObjectId = getAttribute(company, 'objectId');
    const companyModuleQuery = createQuery('CompanyModule');
    setQueryRestriction(companyModuleQuery, QueryRestriction.EQUAL_TO, 'belongsToCompany', companyObjectId);
    const companyModule = await findRecords({ sessionToken: getCurrentUserSessionToken() }, companyModuleQuery, true);

    let showFifteenMinutePreTripViolation;

    if (companyModule) {
      const _showFifteenMinutePreTripViolation = getAttribute(companyModule, 'showFifteenMinutePreTripViolation');
      showFifteenMinutePreTripViolation = _showFifteenMinutePreTripViolation ? true : false; // Handles the case where showFifteenMinutePreTripViolation is undefined
    }

    if (dispatcher.id && company.id && user.id) {
      const newState = { ...this.state };

      //

      newState.userNotificationSetting = user.get('userNotificationSetting') && (await user.get('userNotificationSetting').fetch()).toJSON() || {};

      newState.userObject = user;
      newState.currency = dispatcher.get('currency') || 'CAD';
      newState.timezone = dispatcher.get('timezoneOffsetFromUTC') || '';
      newState.distanceUnit = dispatcher.get('distanceUnit') || 'mi';
      newState.company = {
        speedLimit: dispatcher.get('distanceUnit') === 'km' ? (company.get('speedLimitKm')) : Math.round(convertDistance(company.get('speedLimitKm'), 'km', 'mi')), // 5mph default,
        name: company.get('name'),
        dotNumber: company.get('dotNumber'),
        phoneNumber: company.get('phoneNumber'),
        nscNumber: company.get('nscNumber'),
        eldCompliant: company.get('eldCompliant'),
        hourCycleCanadaInt: company.get('hourCycleCanadaInt'),
        hourCycleUSAInt: company.get('hourCycleUSAInt'),
        address: {
          city: '',
          stateProvince: '',
          country: '',
          zipPostal: '',
        },
        addressBusiness: {
          city: '',
          stateProvince: '',
          country: '',
          zipPostal: '',
        },
        disableWalkthrough: company.get('disableWalkthrough'),
        ctpat: company.get('ctpat'),
        promoCode: company.get('promoCode'),
        disableDriverCACycleHourSelect: company.get('disableDriverCACycleHourSelect'),
        disableDriverUSCycleHourSelect: company.get('disableDriverUSCycleHourSelect'),
        disablePCYMCountryInt: company.get('disablePCYMCountryInt') || PCYMCountryRestriction.NONE,
      };
      newState.dispatcher = dispatcher.toJSON();
      newState.companyModule = undefined;

      if (companyModule) {
        newState.companyModule = {
          companyModuleObjectId: getAttribute(companyModule, 'objectId'),
          showFifteenMinutePreTripViolation,
          ...companyModule.toJSON(),
        };
      }

      if (company.get('address')) {
        newState.company.address.address = company.get('address').get('address');
        newState.company.address.city = company.get('address').get('city');
        newState.company.address.stateProvince = company.get('address').get('stateProvince');
        newState.company.address.country = company.get('address').get('country');
        newState.company.address.zipPostal = company.get('address').get('zipPostal');
      }

      if (company.get('addressBusiness')) {
        newState.company.addressBusiness.address = company.get('addressBusiness').get('address');
        newState.company.addressBusiness.city = company.get('addressBusiness').get('city');
        newState.company.addressBusiness.stateProvince = company.get('addressBusiness').get('stateProvince');
        newState.company.addressBusiness.country = company.get('addressBusiness').get('country');
        newState.company.addressBusiness.zipPostal = company.get('addressBusiness').get('zipPostal');
      }

      if (aobrdSetting) {
        newState.aobrdSetting = {
          aobrdEnabled: aobrdSetting.get('aobrdEnabled') || false,
          minSpeed: dispatcher.get('distanceUnit') === 'km' ? (aobrdSetting.get('minSpeedKm') || 8.04672) : Math.round(convertDistance(aobrdSetting.get('minSpeedKm'), 'km', 'mi') || 5), // 5mph default
          deviceMode: (aobrdSetting.get('aobrdEnabled') || false) ? 'aobrd' : 'eld',
          allowDriverSelfEdit: aobrdSetting.get('allowDriverSelfEdit'),
        };
      }

      this.setState(newState);
    }
  }

  handleChange(e, customId, customAttribute) {
    if (customId === 'timezone') {
      this.setState({ ...this.state, timezone: e });
      updateDispatcher(this.state.userObject.id, 'timezoneOffsetFromUTC', e);
    } else if (customId === 'currency') {
      this.setState({ ...this.state, currency: e });
      updateDispatcher(this.state.userObject.id, 'currency', e);
    } else if (customId === 'distanceUnit') {
      const distanceUnit = e.target.id;
      // const currentDistanceUnit = this.state.distanceUnit;
      const newState = { ...this.state, distanceUnit };
      if (distanceUnit !== this.state.distanceUnit) {
        if (distanceUnit === 'mi') {
          newState.aobrdSetting.minSpeed = Math.round(newState.aobrdSetting.minSpeed * 0.621371);
          newState.company.speedLimit = Math.round(newState.company.speedLimit * 0.621371);
        } else {
          newState.aobrdSetting.minSpeed = Math.round(newState.aobrdSetting.minSpeed * 1.60934);
          newState.company.speedLimit = Math.round(newState.company.speedLimit * 1.60934);
        }
      }
      this.setState(newState);
      updateDispatcher(this.state.userObject.id, 'distanceUnit', e.target.id);
    } else if (customId === 'volumeUnit') {
      const volumeUnit = e.target.id;
      // const currentDistanceUnit = this.state.distanceUnit;
      const newState = { ...this.state, volumeUnit };
      this.setState(newState);
      updateDispatcher(this.state.userObject.id, 'volumeUnit', e.target.id);
    } else if (customId === 'company') {
      if (compressWhitespace(e).length === 0) {
        e = undefined;
      }
      this.setState({
        ...this.state,
        company: {
          ...this.state.company,
          [customAttribute]: e,
        },
      });
    } else if (customId === 'dispatcher') {
      const newState = { ...this.state };
      newState.dispatcher[customAttribute] = e
      this.setState(newState);
      updateDispatcher(this.state.userObject.id, customAttribute, e);
    }
  }

  handleChangeAddress(property, value, isAddressBusiness) {
    if (compressWhitespace(value).length === 0) {
      value = '';
    }
    const newState = { ...this.state };
    if (!isAddressBusiness) { // update home terminal address
      newState.company.address[property] = value;
    } else { // update principal place business address
      newState.company.addressBusiness[property] = value;
    }
    this.setState(newState);
  }

  updateCompanyAddress(companyState) {
    const newState = { ...this.state };

    newState.companyErrors = [];
    const name = companyState.name;
    const dotNumber = companyState.dotNumber;
    const nscNumber = companyState.nscNumber;

    const companyAddress = {
      address: companyState.address.address,
      city: companyState.address.city,
      country: companyState.address.country,
      stateProvince: companyState.address.stateProvince,
      zipPostal: companyState.address.zipPostal,
    };

    const keys = Object.keys(companyAddress);
    const values = keys.map(key => companyAddress[key]);
    if (values.indexOf(undefined) !== -1) {
      newState.companyErrors.push('Please complete all Address fields');
      return this.setState(newState);
    }

    this.props.company.set('name', name);
    this.props.company.set('nscNumber', nscNumber);
    this.props.company.set('dotNumber', dotNumber);
    this.props.company.save();

    newState.companyUpdating = true;
    this.setState(newState, () => {
      const newState = { ...this.state };
      newState.companyUpdating = false;

      const companyAddress = this.props.company.get('address');
      if (this.props.company.get('address')) {
        updateParseObject(companyAddress, this.state.company.address).then(
          updatedAddress => {
            newState.saved = true;
            this.setState(newState, () => selfUpdateCompany(this.props.company));
          },
          error => {
            newState.errors.push(error.message);
            this.setState(newState);
          }
        );
      } else {
        createNewAddress(this.state.company.address).then(
          address => {
            updateParseObject(this.props.company, { address }).then(
              updatedCompany => {
                newState.saved = true;
                this.setState(newState, () => selfUpdateCompany(updatedCompany));
              },
              error => {
                newState.errors.push(error.message);
                this.setState(newState);
              }
            );
          },
          error => {
            newState.errors.push(error.message);
            this.setState(newState);
          }
        );
      }
    });
  }

  updateCompanyAddressBusiness(companyState) {
    // Just copying updateCompanyAddress
    const newState = { ...this.state };

    newState.companyErrors = [];
    const name = companyState.name;
    const dotNumber = companyState.dotNumber;
    const nscNumber = companyState.nscNumber;

    const companyAddressBusiness = {
      address: companyState.addressBusiness.address,
      city: companyState.addressBusiness.city,
      country: companyState.addressBusiness.country,
      stateProvince: companyState.addressBusiness.stateProvince,
      zipPostal: companyState.addressBusiness.zipPostal,
    };

    const keys = Object.keys(companyAddressBusiness);
    const values = keys.map(key => companyAddressBusiness[key]);
    if (values.indexOf(undefined) !== -1) {
      newState.companyErrors.push('Please complete all Address fields');
      return this.setState(newState);
    }

    this.props.company.set('name', name);
    this.props.company.set('nscNumber', nscNumber);
    this.props.company.set('dotNumber', dotNumber);
    this.props.company.save();

    newState.companyUpdating = true;
    this.setState(newState, () => {
      const newState = { ...this.state };
      newState.companyUpdating = false;

      const addressBusiness = this.props.company.get('addressBusiness');
      if (this.props.company.get('addressBusiness')) {
        updateParseObject(addressBusiness, this.state.company.addressBusiness).then(
          updatedAddress => {
            newState.saved = true;
            this.setState(newState, () => selfUpdateCompany(this.props.company));
          },
          error => {
            newState.errors.push(error.message);
            this.setState(newState);
          }
        );
      } else {
        createNewAddress(this.state.company.addressBusiness).then(
          addressBusiness => {
            updateParseObject(this.props.company, { addressBusiness }).then(
              updatedCompany => {
                newState.saved = true;
                this.setState(newState, () => selfUpdateCompany(updatedCompany));
              },
              error => {
                newState.errors.push(error.message);
                this.setState(newState);
              }
            );
          },
          error => {
            newState.errors.push(error.message);
            this.setState(newState);
          }
        );
      }
    });
  }

  handleUploadDefaultVehicleImage(event) {
    if (!event) {
      this.setState({ ...this.state, uploadDefaultVehicleImage: { ...this.state.uploadDefaultVehicleImage, inProgress: true, error: '' } }, () => {
        uploadImage(this.props.company, 'defaultVehicleImage', null).then(() => {
          this.setState({ ...this.state, uploadDefaultVehicleImage: { ...this.state.uploadDefaultVehicleImage, inProgress: false } });
        });
      });
    } else {
      const file = event.target.files[0];

      this.setState({ ...this.state, uploadDefaultVehicleImage: { ...this.state.uploadDefaultVehicleImage, inProgress: true, error: '' } }, () => {
        uploadImage(this.props.company, 'defaultVehicleImage', file).then(() => {
          this.setState({ ...this.state, uploadDefaultVehicleImage: { ...this.state.uploadDefaultVehicleImage, inProgress: false } });
        });
      });
    }
  }

  handleUploadLogo(event) {
    if (!event) {
      this.setState({ ...this.state, uploadLogo: { ...this.state.uploadLogo, inProgress: true, error: '' } }, () => {
        uploadLogo(this.props.company, null).then(() => {
          this.setState({ ...this.state, uploadLogo: { ...this.state.uploadLogo, inProgress: false } });
        }, (error) => {
          this.setState({ ...this.state, uploadLogo: { ...this.state.uploadLogo, inProgress: false, error: error.message } });
        });
      });
    } else {
      const file = event.target.files[0];
      if (file.size > 5000000) {
        this.setState({ ...this.state, uploadLogo: { ...this.state.uploadLogo, error: 'Please upload a file less than 5 MB' } });
      } else {
        this.setState({ ...this.state, uploadLogo: { ...this.state.uploadLogo, inProgress: true, error: '' } }, () => {
          uploadLogo(this.props.company, file).then(() => {
            this.setState({ ...this.state, uploadLogo: { ...this.state.uploadLogo, inProgress: false } });
          }, (error) => {
            this.setState({ ...this.state, uploadLogo: { ...this.state.uploadLogo, inProgress: false, error: error.message } });
          });
        });
      }
    }
  }

  updateAOBRDSetting(property, value) {
    const newState = { ...this.state };
    newState.aobrdSetting = { ...this.state.aobrdSetting };
    if (property === 'minSpeed') {
      newState.aobrdSetting.minSpeed = parseInt(value || 0);
    } else {
      newState.aobrdSetting[property] = value;
    }
    if (property === 'aobrdEnabled') {
      newState.aobrdSetting.deviceMode = value ? 'aobrd' : 'eld';
    }
    this.setState(newState, () => {
      let val = value;
      const aobrdSetting = this.props.company.get('aobrdSetting');
      if (aobrdSetting) {
        if (property === 'minSpeed') {
          if (newState.distanceUnit === 'mi') {
            val = value * 1.60934;
          }
          aobrdSetting.set('minSpeedKm', val).save().then(() => this.setState(this.state));
        } else {
          aobrdSetting.set(property, val).save().then(() => this.setState(this.state));
        }
      }
    });
  }

  toggleAOBRDDriverSelfEdit(e, isEnabled) {
    // console.log(isEnabled);
    this.updateAOBRDSetting('allowDriverSelfEdit', isEnabled);
    // console.log(this.props.company.get('aobrdSetting'));

  }

  async updateCompanyModule(property, value) {
    if (this.state.companyModule && this.state.companyModule.companyModuleObjectId) {
      const companyModuleRecord = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'CompanyModule', this.state.companyModule.companyModuleObjectId);
      if (companyModuleRecord) {
        updateRecord({ sessionToken: getCurrentUserSessionToken() }, companyModuleRecord, { [property]: value }, true);
      }
    } else {
      addRecord('CompanyModule', {
        belongsToCompany: this.props.company,
        [property]: value,
      }, getCompanyReadWriteACL());
    }
    this.refreshState(this.props.Dispatcher.dispatcher, this.props.company, this.props.User.user);
  }

  updateCompany(property, value) {
    const newState = { ...this.state };
    if (property === 'speedLimit') {
      newState.company[property] = parseInt(value || 0);
    } else {
      newState.company[property] = value;
    }
    this.setState(newState, () => {
      if (property === 'speedLimit') {
        if (newState.distanceUnit === 'mi') {
          this.props.company.set('speedLimitKm', convertDistance(newState.company[property], 'mi', 'km')).save();
        } else {
          this.props.company.set('speedLimitKm', newState.company[property]).save();
        }
      } else {
        this.props.company.set(property, value).save().then(() => {
          history.push('/settings');
          if (property === 'disableDriverUSCycleHourSelect' || property === 'disableDriverCACycleHourSelect') {
            const propertyToChange = (property === 'disableDriverUSCycleHourSelect') ? 'disabledUSCycleHourSelect' : 'disabledCACycleHourSelect';
            updateAllDriversDriverModule({ [propertyToChange]: value });
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    });
  }

  updateUserNotificationSetting(attribute, value) {
    const callingUser = this.props.User.user;
    const userNotificationSettingObj = callingUser.get('userNotificationSetting');
    const loadingState = { ...this.state, loading: { ...this.state.loading, emailNotifications: true }, userNotificationSettingError: '' };

    this.setState(loadingState, () => {
      if (attribute === 'emailTo' && isInvalidEmailFormat(value)) {
        return this.setState({ ...this.state, loading: { ...this.state.loading, emailNotifications: false }, userNotificationSettingError: 'Invalid Email Address' });
      }
      if (userNotificationSettingObj) {
        if (value == undefined) {
          // Unset the given attribute field if undefined is passed into the value parameter
          userNotificationSettingObj.unset(attribute).save().then(() => {
            const userNotificationSettingState = { ...this.state.userNotificationSetting };
            userNotificationSettingState[attribute] = value;
            this.setState({
              ...this.state,
              userNotificationSetting: userNotificationSettingState,
              loading: { ...this.state.loading, emailNotifications: false }
            });
          });
        } else {
          userNotificationSettingObj.set(attribute, value).save().then(() => {
            const newState = { ...this.state };
            const userNotificationSettingState = { ...this.state.userNotificationSetting };
            userNotificationSettingState[attribute] = value;
            this.setState({
              ...this.state,
              userNotificationSetting: userNotificationSettingState,
              loading: { ...this.state.loading, emailNotifications: false }
            });
          });
        }
      } else {
        this.setState(loadingState, () => {
          addRecord('UserNotificationSetting', {
            user: callingUser,
            [attribute]: value,
          }, getCompanyReadWriteACL()).then((newUserNotificationSettingObj) => {
            callingUser.set('userNotificationSetting', newUserNotificationSettingObj).save().then(savedUser => {
              this.setState({ ...this.state, loading: { ...this.state.loading, emailNotifications: false } });
            });
          });
        });
      }
    });
  }

  updateGeofenceNotification(bool) {
    this.setState(prevState => ({ loading: { ...prevState, geofenceNotifications: bool } }));
  }

  handleTrailerLockChange(type) {
    const { company } = this.props;
    const { trailerLockTypes } = this.state;

    company.set('equipmentSelectLockedBool', false);
    company.set('allowTrailerLockHybrid', false);

    if (type === trailerLockTypes.LOCKED) {
      company.set('trailerSelectLockedBool', true);
    } else if (type === trailerLockTypes.UNLOCKED) {
      company.set('trailerSelectLockedBool', false);
    } else if (type === trailerLockTypes.HYBRID) {
      company.set('trailerSelectLockedBool', false);
      company.set('allowTrailerLockHybrid', true);
    }
    company.save();
  }

  updatePCYMCountryRestriction(value) {
    const type = value && value.replace(/ /g, "_").toUpperCase(); // modifies the value to reflect the enum type: e.g., 'United States' --> 'UNITED_STATES'
    const code = type ? PCYMCountryRestriction[type] : PCYMCountryRestriction.NONE;

    this.updateCompany('disablePCYMCountryInt', code);
  }

  render() {
    const { Subscription, User } = this.props;
    const { company, aobrdSetting, ctpat, disableWalkthrough, loading, trailerLockTypes, userNotificationSetting, userObject, companyModule } = this.state;
    const callingUser = getCurrentUser(true);
    const userSpecialPermission = callingUser.userSpecialPermission;
    // special permissions
    const disableLinkedCompanyList = userSpecialPermission && userSpecialPermission.disableLinkedCompanyList;
    const disableCompanyUserFeature = userSpecialPermission && userSpecialPermission.disableCompanyUserFeature;
    const disableUserEmailNotificationHosViolations = (userSpecialPermission && userSpecialPermission.disableUserEmailNotificationHosViolations) || !isSubscribedToModule('eldModule');
    const disableUserEmailNotificationSpeedViolations = (userSpecialPermission && userSpecialPermission.disableUserEmailNotificationSpeedViolations) || !isSubscribedToModule('mapModule');
    const disableUserEmailNotificationUnreadChatMessages = (userSpecialPermission && userSpecialPermission.disableUserEmailNotificationUnreadChatMessages) || !isSubscribedToModule('eldModule');
    const disableUserEmailNotificationExpiriesReminders = (userSpecialPermission && userSpecialPermission.disableUserEmailNotificationExpiriesReminders) || !isSubscribedToModule('safetyRemindersModule');
    const disableUserEmailNotificationTripDefects = (userSpecialPermission && userSpecialPermission.disableUserEmailNotificationTripDefects) || !isSubscribedToModule('eldModule');
    const disableRealTimeEmailNotificationHosViolations = callingUser?.belongsToCompany?.objectId !== 'BOIyfwfc7k' || !isSubscribedToModule('eldModule'); // BOIyfwfc7k is the companyObjectId for Men on the Move

    let selectedTrailerLockType = trailerLockTypes.UNLOCKED;
    if (this.props.company.get('allowTrailerLockHybrid')) {
      selectedTrailerLockType = trailerLockTypes.HYBRID;
    } else if (this.props.company.get('trailerSelectLockedBool')) {
      selectedTrailerLockType = trailerLockTypes.LOCKED;
    } else {
      selectedTrailerLockType = trailerLockTypes.UNLOCKED;
    }

    // const speedLimitHelpTooltip = (
    //   <ReactTooltip place="right" effect="solid" type="dark" id="speedLimitHelpTooltip">
    //     When you set a speed limit, you will:
    //     <br />
    //     • Receive notifications when your driver is above the speed limit when inside the geofence
    //     • Show speed violations in the <a>Driver Behaviour</a> report
    //   </ReactTooltip>
    // );
    const emailToSet = new Set(userNotificationSetting && userNotificationSetting.emailToArr);
    if (userNotificationSetting && userNotificationSetting.emailTo && !isInvalidEmailFormat(userNotificationSetting.emailTo)) {
      emailToSet.add(userNotificationSetting && userNotificationSetting.emailTo);
    }
    emailToSet.add(callingUser && callingUser.email);

    return (
      <MDBContainer className={`${styles.accountLayout} mx-0 mw-100`}>
        <MDBRow>
          <MDBCol size="6">
            <Card className="translate-me">
              <CardHeader
                title="Switchboard Settings"
              />
              <CardText>
                {isSubscribedToModule('eldModule') &&
                  <div>

                    <div className={`${styles.subtitle} ${styles.thinTop}`}>Default Cycle Rules</div>
                    <ListSelectField
                      className={styles.canadaHOSCycle}
                      floatingLabelText="Canadian HOS Cycle"
                      value={canadaCycles[this.state.company.hourCycleCanadaInt]}
                      onChange={(e, index, newValue) => this.updateCompany('hourCycleCanadaInt', index + 1)}
                      maxHeight={200}
                      type="currenciesShort"
                      list={Object.keys(canadaCycles).map((key) => canadaCycles[key])}
                    />
                    <br />
                    <ListSelectField
                      className={styles.usHOSCycle}
                      floatingLabelText="US HOS Cycle"
                      value={usCycles[this.state.company.hourCycleUSAInt]}
                      onChange={(e, index, newValue) => this.updateCompany('hourCycleUSAInt', getReferenceIntFromDescription(1, newValue))}
                      maxHeight={200}
                      type="currenciesShort"
                      list={Object.keys(usCycles).map((key) => usCycles[key])}
                    />

                    <div>
                      <div className={styles.subtitle}>Prevent Driver Changing of Canada HOS Cycles</div>
                      <div>
                        <small className="ml-2">* This applies to all drivers on this account. Note it may take a little time to propogate to all drivers</small>
                      </div>
                      <Toggle
                        label="Disallowed"
                        toggled={company.disableDriverCACycleHourSelect}
                        onToggle={(e, isEnabled) => this.updateCompany('disableDriverCACycleHourSelect', isEnabled)}
                        labelPosition="right"
                        style={{ marginTop: '1em' }}
                      />
                    </div>

                    <div>
                      <Tooltip target=".pretrip-violation-tooltip" />

                      <div className={`${styles.subtitle} flex align-items-center`}>
                        Strict 15 Minute Pre-trip Inspection
                        <span className={`${styles.infoIcon} pretrip-violation-tooltip material-icons text-base ml-1`} data-pr-tooltip="Triggers a violation if a pre-trip inspection is completed within 15 minutes" data-pr-position="right">info</span>
                      </div>

                      <div>
                        <small className="ml-2">* This applies to all drivers on this account. Note it may take a little time to propogate to all drivers</small>
                      </div>

                      <Toggle
                        label={companyModule?.showFifteenMinutePreTripViolation ? "Enabled" : "Disabled"}
                        toggled={companyModule?.showFifteenMinutePreTripViolation}
                        onToggle={(e, isEnabled) => this.updateCompanyModule('showFifteenMinutePreTripViolation', isEnabled)}
                        labelPosition="right"
                        style={{ marginTop: '1em' }}
                      />
                    </div>

                    <div>
                      <div className={styles.subtitle}>Drivers PC/YM Country Restrictions</div>
                      <div>
                        <small className="ml-2">* This applies to all drivers on this account. Note it may take a little time to propogate to all drivers</small>
                      </div>
                      <ListSelectField
                        floatingLabelText="PC/YM Country Restriction"
                        // Checks for valid code within enum types, and retrieves the corresponding PCYMCountryRestrictionDescription using the key of PCYMCountryRestriction
                        value={Object.values(PCYMCountryRestriction).includes(this.state.company.disablePCYMCountryInt) && PCYMCountryRestrictionDescription[Object.entries(PCYMCountryRestriction).find(([key, value]) => value === this.state.company.disablePCYMCountryInt)[0]]}
                        onChange={(e, index, newValue) => this.updatePCYMCountryRestriction(newValue)}
                        maxHeight={200}
                        list={Object.keys(PCYMCountryRestrictionDescription).map((key) => PCYMCountryRestrictionDescription[key])}
                      />
                    </div>

                    <hr />
                  </div>
                }

                <div className={styles.subtitle}>Default Time Zone</div>
                <TimezonePicker
                  className={`${styles.timezoneInput} notranslate`}
                  value={this.state.timezone}
                  onChange={timezone => this.handleChange(timezone, 'timezone')}
                  inputProps={{
                    placeholder: 'Set Default Timezone',
                    name: 'timezone',
                  }}
                />

                <hr />

                <div className={styles.subtitle}>Units</div>
                <div>
                  <table><tbody><tr>
                    <td>
                      <div>
                        <ButtonGroup>
                          <Button id="km" onClick={(e) => { this.handleChange(e, 'distanceUnit'); }} active={this.state.distanceUnit === 'km'}>
                            Kilometers
                          </Button>
                          <Button id="mi" onClick={(e) => { this.handleChange(e, 'distanceUnit'); }} active={this.state.distanceUnit === 'mi'}>
                            Miles
                          </Button>
                        </ButtonGroup>

                      </div>
                      <br />
                      <div>
                        <ButtonGroup>
                          <Button id="l" onClick={(e) => { this.handleChange(e, 'volumeUnit'); }} active={this.state.volumeUnit === 'l'}>
                            Liters
                          </Button>
                          <Button id="gal" onClick={(e) => { this.handleChange(e, 'volumeUnit'); }} active={this.state.volumeUnit === 'gal'}>
                            Gallons
                          </Button>
                        </ButtonGroup>
                      </div>
                      <br />
                      <div>
                        <ListSelectField
                          className={styles.currencyListSelectField}
                          floatingLabelText={t('Currency')}
                          value={this.state.currency}
                          onChange={(e, index, newValue) => this.handleChange(newValue, 'currency')}
                          maxHeight={200}
                          type="currenciesShort"
                          list={CurrenciesShort}
                        />

                      </div>
                    </td>
                    <td className="notranslate">
                    </td>
                  </tr></tbody></table>
                </div>

                <hr />

                {/* <div>
                  <TextField
                    disabled
                    type="number"
                    value={aobrdSetting.minSpeed.toString()}
                    floatingLabelText={`Minimum Speed Driving Enabled (${this.state.distanceUnit})`}
                    onChange={(e, newValue) => {
                      this.updateAOBRDSetting('minSpeed', newValue);
                    }}
                    // errorText={
                    //   this.props.Dispatcher.dispatcher &&
                    //   ((aobrdSetting.minSpeed > 32 && this.props.Dispatcher.dispatcher.get('distanceUnit') === 'km') || (aobrdSetting.minSpeed > 20 && this.props.Dispatcher.dispatcher.get('distanceUnit') === 'mi')) &&
                    //   'Minimum speed must be below 32 km or 20 mi'
                    // }
                    style={{ width: '18em' }}
                  />
                </div> */}
                {this.props.Dispatcher.dispatcher && this.props.Dispatcher.dispatcher.id && isSubscribedToModule('mapModule') &&
                  <div>
                    <div className={styles.subtitle}>Hide Company Name from Shareable Map Links</div>
                    <Toggle
                      label={companyModule?.hideCompanyNameFromShareLocation ? "Enabled" : "Disabled"}
                      toggled={companyModule?.hideCompanyNameFromShareLocation}
                      onToggle={(e, isEnabled) => this.updateCompanyModule('hideCompanyNameFromShareLocation', isEnabled)}
                      labelPosition="right"
                      style={{ marginTop: '1em' }}
                    />
                    {/* <div className={styles.subtitle}>AOBRD: Enable Driver Self-Editing</div>
                    <Toggle
                      label="Enable"
                      toggled={aobrdSetting.allowDriverSelfEdit}
                      onToggle={this.toggleAOBRDDriverSelfEdit}
                      labelPosition="right"
                      style={{ marginTop: '1em' }}
                    /> */}
                  </div>
                }

                <hr />

                {this.props.Dispatcher.dispatcher && this.props.Dispatcher.dispatcher.id && isSubscribedToModule('mapModule') && (
                  <div>
                    <div className={styles.subtitle}>Geofence Notifications</div>
                    {!loading.geofenceNotifications ? (
                      <React.Fragment>
                        <MDBRow>
                          <MDBCol>
                            <Checkbox
                              label="Get Notifications on Website"
                              checked={this.props.Dispatcher.dispatcher.get('showGeofenceEventNotifications')}
                              onCheck={async (e, isChecked) => {
                                this.updateGeofenceNotification(true);
                                await this.props.Dispatcher.dispatcher.set('showGeofenceEventNotifications', isChecked).save();
                                this.updateGeofenceNotification();
                              }}
                            />
                          </MDBCol>
                          <MDBCol>
                            {/* <RadioButtonGroup
                              name="groupWebsiteGeofenceNotifications"
                              defaultValue={false}
                              onChange={(e, value) => {
                                this.props.Dispatcher.dispatcher.set('groupWebsiteGeofenceNotifications', value).save();
                              }}
                              valueSelected={this.props.Dispatcher.dispatcher.get('groupWebsiteGeofenceNotifications')}
                              style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
                            >
                              <RadioButton
                                value={false}
                                label="All (default)"
                                style={{ display: 'inline-block' }}
                              />
                              <RadioButton
                                value
                                label="Groups only"
                                style={{ display: 'inline-block' }}
                              />
                            </RadioButtonGroup> */}
                            <Toggle
                              label="Only get notifications for my groups"
                              toggled={this.props.Dispatcher.dispatcher.get('groupWebsiteGeofenceNotifications')}
                              onToggle={async (e, isEnabled) => {
                                this.updateGeofenceNotification(true);
                                await this.props.Dispatcher.dispatcher.set('groupWebsiteGeofenceNotifications', isEnabled).save();
                                this.updateGeofenceNotification();
                              }}
                              labelPosition="right"
                              style={{ lineHeight: '24px' }}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol>
                            <span className="pl-8" style={{ lineHeight: '24px' }}>Send to Email</span>
                          </MDBCol>
                          <MDBCol>
                            {/* <RadioButtonGroup
                              name="groupEmailGeofenceNotifications"
                              defaultValue={false}
                              onChange={(e, value) => {
                                this.props.Dispatcher.dispatcher.set('groupEmailGeofenceNotifications', value).save();
                              }}
                              valueSelected={this.props.Dispatcher.dispatcher.get('groupEmailGeofenceNotifications')}
                              style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
                            >
                              <RadioButton
                                value={false}
                                label="All (default)"
                                style={{ display: 'inline-block' }}
                              />
                              <RadioButton
                                value
                                label="Groups only"
                                style={{ display: 'inline-block' }}
                              />
                            </RadioButtonGroup> */}
                            <Toggle
                              label="Only get notifications for my groups"
                              toggled={this.props.Dispatcher.dispatcher.get('groupEmailGeofenceNotifications')}
                              onToggle={async (e, isEnabled) => {
                                this.updateGeofenceNotification(true);
                                await this.props.Dispatcher.dispatcher.set('groupEmailGeofenceNotifications', isEnabled).save();
                                this.updateGeofenceNotification();
                              }}
                              labelPosition="right"
                              style={{ lineHeight: '24px' }}
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol><MDBIcon icon="info-circle" /> To get Geofence notifications, <a href="https://app.onswitchboard.com/map">go to your Maps page and add/edit a geofence.</a></MDBCol>
                        </MDBRow>
                      </React.Fragment>
                    ) : (
                      <CircularProgress />
                    )}
                  </div>
                )}

                <hr />

                {this.props.Dispatcher.dispatcher && this.props.Dispatcher.dispatcher.id && isSubscribedToModule('eldModule') &&
                  <div>
                    <div className={styles.subtitle}>Website Notifications</div>
                    <Checkbox
                      label="Show Edit Notifications"
                      checked={this.props.Dispatcher.dispatcher.get('showELDEditNotifications')}
                      onCheck={(e, isChecked) => {
                        this.props.Dispatcher.dispatcher.set('showELDEditNotifications', isChecked).save();
                        this.forceUpdate();
                      }}
                    />
                    <Checkbox
                      label="Show HOS Violation Notifications"
                      checked={this.props.Dispatcher.dispatcher.get('showHOSViolationNotifications')}
                      onCheck={(e, isChecked) => {
                        this.props.Dispatcher.dispatcher.set('showHOSViolationNotifications', isChecked).save();
                        this.forceUpdate();
                      }}
                    />
                    <hr />
                  </div>
                }

                <div>
                  {!loading.emailNotifications ?
                    <div>
                      <div className={styles.subtitle}>Email Notifications</div>
                      <div>
                        {Array.from(emailToSet).map((emailAddress) => {
                          return (
                            <Chip
                              label={emailAddress}
                              removable={Array.from(emailToSet).length > 1 && (callingUser && emailAddress !== callingUser.email)}
                              onRemove={(e) => {
                                emailToSet.delete(emailAddress);
                                const updatedEmailToArr = Array.from(emailToSet);
                                this.setState({ ...this.state, userNotificationSetting: { ...this.state.userNotificationSetting, emailTo: e.target.value } });
                                this.updateUserNotificationSetting('emailToArr', updatedEmailToArr)
                              }}
                            />
                          );
                        })}
                      </div>
                      <div>
                        <div className="inlineBlock">
                          <MDBInput
                            label={t('Add a recipient')}
                            hint={'Email Address'}
                            outline
                            background
                            size="sm"
                            value={userNotificationSetting.emailToInput}
                            onChange={(e) => {
                              this.setState({ ...this.state, userNotificationSetting: { ...this.state.userNotificationSetting, emailToInput: e.target.value } });
                            }}
                            containerClass="inlineBlock notranslate"
                          />
                          &nbsp;
                          <MDBBtn
                            color="primary"
                            size="sm"
                            disabled={isInvalidEmailFormat(userNotificationSetting.emailToInput)}
                            onClick={async () => {
                              emailToSet.add(userNotificationSetting.emailToInput);
                              await this.updateUserNotificationSetting('emailToArr', Array.from(emailToSet));
                              this.setState({ ...this.state, userNotificationSetting: { ...this.state.userNotificationSetting, emailToInput: '' } });
                            }}
                          >
                            Add
                          </MDBBtn>
                        </div>
                        {this.state.userNotificationSettingError &&
                          <div className={styles.error}>
                            {this.state.userNotificationSettingError}
                            <br />
                            <br />
                          </div>
                        }
                      </div>
                      {!disableRealTimeEmailNotificationHosViolations &&
                        <Checkbox
                          label="Real Time HOS Violation Notifications"
                          checked={userNotificationSetting && userNotificationSetting.hosViolationEmailNotificationFrequency === 1}
                          onCheck={(e, isChecked) => {
                            this.updateUserNotificationSetting('hosViolationEmailNotificationFrequency', isChecked ? 1 : undefined);
                          }}
                        />
                      }
                      {!disableUserEmailNotificationHosViolations &&
                        <Checkbox
                          label="Hours of Service Violations"
                          checked={userNotificationSetting && userNotificationSetting.enableHOSViolation}
                          onCheck={(e, isChecked) => {
                            this.updateUserNotificationSetting('enableHOSViolation', isChecked);
                          }}
                        />
                      }
                      {!disableUserEmailNotificationSpeedViolations &&
                        <Checkbox
                          label="Speed Violations"
                          checked={userNotificationSetting && userNotificationSetting.enableSpeedViolation}
                          onCheck={(e, isChecked) => {
                            this.updateUserNotificationSetting('enableSpeedViolation', isChecked);
                          }}
                        />
                      }
                      {!disableUserEmailNotificationTripDefects &&
                        <Checkbox
                          label="Trip Inspection Defects"
                          checked={userNotificationSetting && userNotificationSetting.enableTripDefectEmail}
                          onCheck={(e, isChecked) => {
                            this.updateUserNotificationSetting('enableTripDefectEmail', isChecked);
                          }}
                        />
                      }
                      {!disableUserEmailNotificationUnreadChatMessages &&
                        <Checkbox
                          label="Unread Chat Messages"
                          checked={userNotificationSetting && userNotificationSetting.enableChatMessage}
                          onCheck={(e, isChecked) => {
                            this.updateUserNotificationSetting('enableChatMessage', isChecked);
                          }}
                        />
                      }
                      {!disableUserEmailNotificationExpiriesReminders &&
                        <Checkbox
                          label="Expiries & Reminders"
                          checked={userNotificationSetting && userNotificationSetting.enableExpiriesAndReminders}
                          onCheck={(e, isChecked) => {
                            this.updateUserNotificationSetting('enableExpiriesAndReminders', isChecked);
                          }}
                        />
                      }
                    </div>
                    :
                    <CircularProgress />
                  }
                  <hr />
                </div>


                {isSubscribedToModule('eldModule') &&
                  <div>
                    <div className={styles.subtitle}>Vehicle Creation By Drivers</div>
                    <RadioButtonGroup
                      name="vehicleSelectLockedBool"
                      defaultValue={false}
                      onChange={(e, value) => {
                        this.props.company.set('vehicleSelectLockedBool', value).save();
                      }}
                      valueSelected={this.props.company.get('vehicleSelectLockedBool') || false}
                      style={{ width: '100%' }}
                    >
                      <RadioButton
                        value={false}
                        label="Drivers can create new Vehicles"
                        style={{ display: 'inline-block' }}
                      />
                      <RadioButton
                        value
                        label="Drivers cannot create new vehicles"
                        style={{ display: 'inline-block' }}
                      />
                    </RadioButtonGroup>

                    <div className={styles.subtitle}>Trailer Creation By Drivers</div>
                    <RadioButtonGroup
                      name="trailerLockTypesGroup"
                      defaultValue={trailerLockTypes.UNLOCKED}
                      onChange={(e, value) => {
                        this.handleTrailerLockChange(value)
                      }}
                      valueSelected={selectedTrailerLockType}
                      style={{ width: '100%' }}
                    >
                      <RadioButton
                        value={trailerLockTypes.UNLOCKED}
                        label="Drivers can create new Trailers"
                        style={{ display: 'inline-block' }}
                      />
                      <RadioButton
                        value={trailerLockTypes.LOCKED}
                        label="Drivers cannot create new Vehicles"
                        style={{ display: 'inline-block' }}
                      />
                      <RadioButton
                        value={trailerLockTypes.HYBRID}
                        label={<div>Drivers can create new Trailers (but Driver-added trailers will not be saved)</div>}
                        style={{ display: 'inline-block' }}
                      />

                    </RadioButtonGroup>

                    <hr />
                  </div>
                }

                {this.props.company && this.props.company.id && this.props.company.get('enableAllowAobrdSelfEdit') &&
                  <div>
                    <div className={styles.subtitle}>Allow Driver Self Edits</div>
                    {this.state.savingAobrdSelfEditHours &&
                      <div style={{ margin: '1em' }}>
                        <LoadingIcon />
                      </div>
                    }
                    {!this.state.savingAobrdSelfEditHours &&
                      <RadioButtonGroup
                        name="allowAobrdSelfEditHours"
                        defaultValue={false}
                        onChange={(e, value) => {
                          this.setState({ ...this.state, savingAobrdSelfEditHours: true }, () => {
                            queryCompanyObjects('Driver', undefined, undefined, { queryType: 'equalTo', name: 'belongsToCompany', value: this.props.company }, undefined, undefined, false, false, false, true).then((driverArr) => {
                              for (let i = 0; i < driverArr.length; i++) {
                                driverArr[i].set('aobrdSelfEditHours', 10000);
                              }
                              this.props.company.set('allowAobrdSelfEdit', value);
                              Parse.Object.saveAll([].concat(driverArr, this.props.company)).then(() => {
                                this.setState({ ...this.state, savingAobrdSelfEditHours: false });
                              });
                            });
                          });
                        }}
                        valueSelected={this.props.company.get('allowAobrdSelfEdit')}
                        style={{ width: '100%' }}
                      >
                        <RadioButton
                          value
                          label="Driver Edits do not require Approval"
                          style={{ display: 'inline-block' }}
                        />
                        <RadioButton
                          value={false}
                          label="Driver Edits require Approval"
                          style={{ display: 'inline-block' }}
                        />
                      </RadioButtonGroup>
                    }

                    <hr />
                  </div>
                }


                {
                  this.props.company &&
                  this.props.company.id &&
                  this.props.User.user &&
                  this.props.Dispatcher.dispatcher &&
                  isSubscribedToModule('mapModule') &&
                  <div>
                    <div className={styles.subtitle}>Global Speed Limit (Driver Behaviour)</div>
                    <small className="ml-2">
                      • Receive notifications when your driver is above the speed limit when inside the geofence
                      <br />
                      • Show speed violations in the <a>Driver Behaviour</a> report
                    </small>
                    <div>
                      <TextField
                        type="number"
                        value={company.speedLimit && company.speedLimit.toString()}
                        floatingLabelText={`Speed Limit (${this.state.distanceUnit})`}
                        onChange={(e, newValue) => {
                          this.updateCompany('speedLimit', newValue);
                        }}
                        style={{ width: '18em' }}
                      />
                    </div>
                  </div>
                }

                {this.props.company && this.props.company.id && this.props.User.user &&
                  <div>
                    <div className={styles.subtitle}>Walkthrough Tips</div>
                    <Toggle
                      label="Enable"
                      toggled={!company.disableWalkthrough}
                      onToggle={() => this.updateCompany('disableWalkthrough', !company.disableWalkthrough)}
                      labelPosition="right"
                      style={{ marginTop: '1em' }}
                    />
                  </div>
                }

                {this.props.company && this.props.company.id && this.props.User.user &&
                  isSubscribedToModule('eldModule') &&
                  <div>
                    <div className={styles.subtitle}>C-TPAT Trip Inspections</div>
                    <Toggle
                      label="Enable"
                      toggled={!company.ctpat}
                      onToggle={() => this.updateCompany('ctpat', !company.ctpat)}
                      labelPosition="right"
                      style={{ marginTop: '1em' }}
                    />
                  </div>
                }

              </CardText>
            </Card>
          </MDBCol>
          <MDBCol size="6">
            <Card className="translate-me">
              <CardHeader
                title="Account Settings"
              />
              <CardText>
                <div className={styles.companyProperties}>
                  <TextField
                    className={styles.dotNumber}
                    value={userObject.get('firstName')}
                    floatingLabelText="First Name"
                    onChange={(e, newValue) => { userObject.set('firstName', newValue); this.setState({ ...this.state }) }}
                  />
                  <TextField
                    className={styles.nscNumber}
                    value={userObject.get('lastName')}
                    floatingLabelText="Last Name"
                    onChange={(e, newValue) => { userObject.set('lastName', newValue); this.setState({ ...this.state }) }}
                  />
                  <div style={{ marginTop: '1em' }}>
                    <RaisedButton label="Save" primary onClick={() => {
                      userObject.save().then(() => {
                        this.setState({ ...this.state, saved: true });
                      })
                    }} />
                  </div>
                  {/* <div className={`${styles.subtitle} ${styles.thinTop}`}>{ `${this.props.User.user.get('firstName')} ${this.props.User.user.get('lastName')}` }</div> */}
                </div>
                <div className={styles.companyProperties}>
                  <div className={`${styles.subtitle} ${styles.thinTop}`}>Company Name</div>
                  <TextField
                    className={styles.companyName}
                    value={company.name ? company.name : ''}
                    floatingLabelText="Company Name"
                    onChange={(e, newValue) => this.handleChange(newValue, 'company', 'name')}
                  />
                  <br />
                  <TextField
                    className={styles.companyName}
                    value={company.phoneNumber ? company.phoneNumber : ''}
                    floatingLabelText="Phone Number"
                    onChange={(e, newValue) => this.handleChange(newValue, 'company', 'phoneNumber')}
                  />
                  <br />
                  <TextField
                    className={styles.dotNumber}
                    value={company.dotNumber ? company.dotNumber : ''}
                    floatingLabelText="DOT Number"
                    errorStyle={{ position: 'absolute', right: '0px', bottom: '24px' }}
                    errorText={!company.dotNumber || isValidDOTNumber(company.dotNumber) ? '' : 'Invalid'}
                    hintText="1-9 digits"
                    onChange={(e, newValue) => this.handleChange(newValue, 'company', 'dotNumber')}
                  />
                  <TextField
                    className={styles.nscNumber}
                    value={company.nscNumber ? company.nscNumber : ''}
                    floatingLabelText="NSC/CVOR/NEQ Number"
                    onChange={(e, newValue) => this.handleChange(newValue, 'company', 'nscNumber')}
                  />
                  <div style={{ marginTop: '1em' }}>
                    <RaisedButton label="Save" primary onClick={() => {
                      Promise.all([
                        this.updateCompany('name', company.name),
                        this.updateCompany('phoneNumber', company.phoneNumber || ''),
                        this.updateCompany('dotNumber', company.dotNumber || ''),
                        this.updateCompany('nscNumber', company.nscNumber || ''),
                      ]).then(() => {
                        this.setState({ ...this.state, saved: true });
                      })

                    }} />
                    <Snackbar
                      open={this.state.saved}
                      message="Saved"
                      autoHideDuration={4000}
                      onRequestClose={() => this.setState({ ...this.state, saved: false })}
                    />
                  </div>
                </div>

                <hr />

                <div className={styles.logo}>
                  <div className={styles.subtitle}>Company Logo</div>
                  <table>
                    <tbody>
                      <tr>
                        {this.props.company && this.props.company.get('logo') &&
                          <td>
                            <img role="presentation" src={this.props.company.get('logo').url()} />
                          </td>
                        }
                        <td>
                          {!this.state.uploadLogo.inProgress &&
                            <div className={styles.customUploadButton}>
                              <div>
                                <label htmlFor="file-upload" className={styles.logoUploadButton}>
                                  <RaisedButton
                                    disabled={this.state.uploadLogo.inProgress}
                                    label="Upload Logo"
                                    onClick={() => document.getElementById('file-upload').click()}
                                  />
                                  <input
                                    id="file-upload"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={this.handleUploadLogo}
                                  />
                                </label>
                              </div>
                              {this.props.company && this.props.company.get('logo') &&
                                <div>
                                  <RaisedButton
                                    label="Clear"
                                    onClick={() => this.handleUploadLogo()}
                                  />
                                </div>
                              }
                            </div>
                          }
                          {this.state.uploadLogo.inProgress &&
                            <CircularProgress />
                          }
                          {this.state.uploadLogo.error &&
                            <div className={styles.error}>{this.state.uploadLogo.error}</div>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <hr />

                <div className={styles.companyAddress}>
                  <div className={styles.subtitle}>Address (Home Terminal)</div>
                  <TextField
                    className={styles.address}
                    value={company.address.address ? company.address.address : ''}
                    floatingLabelText="Address"
                    onChange={(e, newValue) => this.handleChangeAddress('address', newValue)}
                  />
                  <br />
                  <TextField
                    className={styles.city}
                    value={company.address.city ? company.address.city : ''}
                    floatingLabelText="City"
                    onChange={(e, newValue) => this.handleChangeAddress('city', newValue)}
                  />
                  <ListSelectField
                    className={styles.stateProvince}
                    floatingLabelText="Province/State"
                    value={company.address.stateProvince}
                    onChange={(e, index, newValue) => this.handleChangeAddress('stateProvince', newValue)}
                    maxHeight={200}
                    type="stateProvince"
                    list={StateProvinces}
                  />
                  <br />
                  <ListSelectField
                    className={styles.country}
                    floatingLabelText="Country"
                    value={company.address.country}
                    onChange={(e, index, newValue) => this.handleChangeAddress('country', newValue)}
                    maxHeight={200}
                    type="country"
                    list={Countries}
                  />
                  <br />
                  <TextField
                    className={styles.zipPostal}
                    value={company.address.zipPostal ? company.address.zipPostal : ''}
                    floatingLabelText="Postal/Zip Code"
                    onChange={(e, newValue) => this.handleChangeAddress('zipPostal', newValue)}
                  />
                  <div style={{ marginTop: '1em' }}>
                    <RaisedButton label="Save" primary onClick={() => this.updateCompanyAddress(company)} />
                    <Snackbar
                      open={this.state.saved}
                      message="Saved"
                      autoHideDuration={4000}
                      onRequestClose={() => this.setState({ ...this.state, saved: false })}
                    />
                  </div>
                  {this.state.companyErrors.length > 0 &&
                    <ErrorList errors={this.state.companyErrors} />
                  }
                </div>

                <hr />

                <div className={styles.companyAddress}>
                  <div className={styles.subtitle}>Address (Principal Place of Business)</div>
                  <div>
                    <small className="ml-2">* If this address is not given, the Hours of Service Logs will use the Home Terminal address</small>
                  </div>
                  <div></div>
                  <TextField
                    className={styles.address}
                    value={company.addressBusiness.address ? company.addressBusiness.address : ''}
                    floatingLabelText="Address"
                    onChange={(e, newValue) => this.handleChangeAddress('address', newValue, true)}
                  />
                  <br />
                  <TextField
                    className={styles.city}
                    value={company.addressBusiness.city ? company.addressBusiness.city : ''}
                    floatingLabelText="City"
                    onChange={(e, newValue) => this.handleChangeAddress('city', newValue, true)}
                  />
                  <ListSelectField
                    className={styles.stateProvince}
                    floatingLabelText="Province/State"
                    value={company.addressBusiness.stateProvince}
                    onChange={(e, index, newValue) => this.handleChangeAddress('stateProvince', newValue, true)}
                    maxHeight={200}
                    type="stateProvince"
                    list={StateProvinces}
                  />
                  <br />
                  <ListSelectField
                    className={styles.country}
                    floatingLabelText="Country"
                    value={company.addressBusiness.country}
                    onChange={(e, index, newValue) => this.handleChangeAddress('country', newValue, true)}
                    maxHeight={200}
                    type="country"
                    list={Countries}
                  />
                  <br />
                  <TextField
                    className={styles.zipPostal}
                    value={company.addressBusiness.zipPostal ? company.addressBusiness.zipPostal : ''}
                    floatingLabelText="Postal/Zip Code"
                    onChange={(e, newValue) => this.handleChangeAddress('zipPostal', newValue, true)}
                  />
                  <div style={{ marginTop: '1em' }}>
                    <RaisedButton label="Save" primary onClick={() => this.updateCompanyAddressBusiness(company)} />
                    <Snackbar
                      open={this.state.saved}
                      message="Saved"
                      autoHideDuration={4000}
                      onRequestClose={() => this.setState({ ...this.state, saved: false })}
                    />
                  </div>
                  {this.state.companyErrors.length > 0 &&
                    <ErrorList errors={this.state.companyErrors} />
                  }
                </div>

                <hr />

                <div className={styles.logo}>
                  <div className={styles.subtitle}>Default Vehicle Image</div>
                  <table>
                    <tbody>
                      <tr>
                        {this.props.company && this.props.company.get('defaultVehicleImage') &&
                          <td>
                            <img role="presentation" src={this.props.company.get('defaultVehicleImage').url()} />
                          </td>
                        }
                        <td>
                          {!this.state.uploadDefaultVehicleImage.inProgress &&
                            <div className={styles.customUploadButton}>
                              <div>
                                <label htmlFor="vehicleImageUploader" className={styles.logoUploadButton}>
                                  <RaisedButton
                                    disabled={this.state.uploadDefaultVehicleImage.inProgress}
                                    label="Upload Image"
                                    onClick={() => document.getElementById('vehicleImageUploader').click()}
                                  />
                                  <input
                                    id="vehicleImageUploader"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: 'none' }}
                                    onChange={this.handleUploadDefaultVehicleImage}
                                  />
                                </label>
                              </div>
                              {this.props.company && this.props.company.get('defaultVehicleImage') &&
                                <div>
                                  <RaisedButton
                                    label="Clear"
                                    onClick={() => this.handleUploadDefaultVehicleImage()}
                                  />
                                </div>
                              }
                            </div>
                          }
                          {this.state.uploadDefaultVehicleImage.inProgress &&
                            <CircularProgress />
                          }
                          {this.state.uploadDefaultVehicleImage.error &&
                            <div className={styles.error}>{this.state.uploadDefaultVehicleImage.error}</div>
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {isUserAdmin()
                  && !company.promoCode // Reseller clients cannot change credit card
                  &&
                  <div>
                    <hr />

                    <div className={styles.logo}>
                      <RaisedButton
                        // disabled={this.state.uploadDefaultVehicleImage.inProgress}
                        label="Update Credit Card"
                        onClick={() => this.setState({ ...this.state, payment: { ...this.state.payment, show: true } })}
                      />
                      {this.state.payment.show && Subscription &&
                        <PaymentModal
                          Subscription={Subscription}
                          paymentModalType="updateCC"
                          handleClose={() => this.setState({ ...this.state, payment: { ...this.state.payment, show: false } })}
                        />
                      }
                    </div>
                  </div>
                }
              </CardText>
            </Card>
          </MDBCol>
        </MDBRow>

        <hr />

        <MDBRow>
          <MDBCol>
            <Card>
              <CardHeader
                title="Account Settings "
                className="translate-me"
              />
              <CardText>
                <div className={`${styles.subtitle} ${styles.thinTop}`} style={{ marginTop: '-1em' }}><ChangePassword userObjectId={this.state.userObject.id} /></div>

                {isUserAdmin() && !disableCompanyUserFeature &&
                  <div className="mt-5">
                    <div className={`${styles.subtitle} translate-me`}>Company Users</div>
                    <CompanyUsersTable />
                  </div>
                }
              </CardText>
            </Card>
          </MDBCol>
        </MDBRow>

        <hr />

        {this.props.company && this.props.company.get('enableCompanyLinks') && !disableLinkedCompanyList &&
          <MDBRow>
            <MDBCol>
              <Card>
                <CardHeader
                  title="Linked DOT Numbers / Companies"
                  className="translate-me"
                />
                <CardText>
                  <LinkedCompanies />
                </CardText>
              </Card>
            </MDBCol>
          </MDBRow>
        }
      </MDBContainer>
    );
  }
}

AccountLayout.propTypes = {
  company: PropTypes.object,
  Dispatcher: PropTypes.object,
  Subscription: PropTypes.object,
  User: PropTypes.object,
};

export default AccountLayout;
