import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

// Components
import Title from 'components/LayoutTitle/view/Title';
// import FilterForm from 'components/FilterForm/container/FilterForm';

class CarrierSafetyAlertsTable extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Title className="h3" title="Alerts" />
        <div className="subTable">
          <Table responsive hover>
            {this.props.carrierProfileObjects &&
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Driver/Vehicle/Carrier</th>
                  <th>Type</th>
                  <th>Result</th>
                  <th />
                </tr>
              </thead>
            }
            <tbody>
              {!this.props.carrierProfileObjects &&
                <tr><td>Upload a Carrier Profile to begin seeing alerts</td></tr>
              }
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default CarrierSafetyAlertsTable;
