import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment-timezone';
import uniqid from 'uniqid';

// API
import { getDispatchItems } from 'api/Dispatch/DispatchItem';

// CSAPI
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Context
import DispatchJobLayoutContext from 'contexts/DispatchJobLayoutContext';

// sbCore Components
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import Toolbar from 'sbCore/Toolbar/Toolbar';
import Button from 'sbCore/Button/Button';

// Shared Document Components
import DocumentInputText from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputText/InputText';
import DocumentInputNumber from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputNumber/InputNumber';
import DocumentInputTextarea from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputTextarea/InputTextarea';

// Styling
import './style.scss';

/**
 * @description Invoice Document Generator rate table
 * @param {string} dispatchJobObjectId - dispatch job used to obtain shipper/consignee information
 * @param {Function} updateRates - callback function which updates the rates in the parent component
 * @returns
 */
const InvoiceRateTable = (props) => {

  // ** Context ** //
  const { refreshJob, dispatchDocumentRefreshToken } = useContext(DispatchJobLayoutContext);

  // ** useStates ** //
  const [dispatchItems, setDispatchItems] = useState(undefined);

  const [invoiceLines, setInvoiceLines] = useState([]);

  // ** useEffects ** //

  // Get the Dispatch Items associated with the Dispatch Job
  useEffect(() => {
    if (!props.dispatchJobObjectId) {
      return;
    }

    let didCancel = false;

    async function _getDispatchItems() {
      if (!didCancel) {
        const includedPointers = [
          'dispatchTransfer',
          'dispatchTransfer.consigneeDispatchOrganization',
          'dispatchTransfer.consigneeDisptachOrganization.address',
          'dispatchTransfer.shipperDispatchOrganization',
          'dispatchTransfer.shipperDispatchOrganization.address',
        ];

        const { dispatchItems } = await getDispatchItems(
          undefined,                  // options
          props.dispatchJobObjectId,  // dispatchJobObjectId
          undefined,                  // filters
          undefined,                  // sort by
          includedPointers,           // included pointers
          undefined,                  // selected attributes
          undefined,                  // page
          undefined,                  // limit
          true,                       // query all
        );

        // Filter out dispatch items belonging to internal dispatch transfers
        const filteredDispatchItems = dispatchItems.filter((dispatchItem) => {
          const dispatchTransfer = getAttribute(dispatchItem, 'dispatchTransfer');
          let isDispatchTransferInternal;
          if (dispatchTransfer) {
            isDispatchTransferInternal = getAttribute(dispatchTransfer, 'internal');
            return !isDispatchTransferInternal;
          }
        });

        setDispatchItems(filteredDispatchItems);
      }
    }

    _getDispatchItems();

    return () => { didCancel = true; };
  }, [dispatchDocumentRefreshToken, props.dispatchJob]);

  // Create table row objects for each Dispatch Item
  useEffect(() => {
    if (!dispatchItems) {
      return;
    }

    let didCancel = false;

    async function createInvoiceLines() {
      if (!didCancel) {
        const _invoiceLines = await Promise.all(dispatchItems.map((dispatchItem) => {
          if (!dispatchItem) {
            return;
          }

          const dispatchTransfer = getAttribute(dispatchItem, 'dispatchTransfer');

          const shipperInformation = shipperConsigneeInformation(dispatchTransfer, 'shipperDispatchOrganization');
          const consigneeInformation = shipperConsigneeInformation(dispatchTransfer, 'consigneeDispatchOrganization');

          const itemName = getAttribute(dispatchItem, 'name');

          let dropoffDateTime = getAttribute(dispatchTransfer, 'dropoffDateTime', true) || '';
          if (dropoffDateTime) {
            dropoffDateTime = moment(dropoffDateTime).format('DD-MM-YYYY');
          }

          let pickupDateTime = getAttribute(dispatchTransfer, 'pickupDateTime', true) || '';
          if (pickupDateTime) {
            pickupDateTime = moment(pickupDateTime).format('DD-MM-YYYY');
          }

          const invoiceLine = {
            id: uniqid(),
            shipperInformation,
            consigneeInformation,
            itemName,
            pickupDateTime,
            dropoffDateTime,
            rate: 0.00,
          };

          return invoiceLine;
        }));
        setInvoiceLines(_invoiceLines);
      }
    }

    createInvoiceLines();

    return () => { didCancel = true; };
  }, [dispatchItems, props.dispatchJob]);

  // Update the given rates to the parent component
  useEffect(() => {
    const rates = invoiceLines.map((invoiceLine) => {
      return invoiceLine.rate;
    });

    props.updateRates(rates);

  }, [invoiceLines]);

  // ** Functions ** //
  const shipperConsigneeInformation = (dispatchTransfer, dispatchOrganizationType) => {
    const dispatchOrganization = getAttribute(dispatchTransfer, dispatchOrganizationType, true);
    const organizationName = getAttribute(dispatchOrganization, 'organizationName', true) || '';
    let organizationInformation = null;
    const address = getAttribute(dispatchOrganization, 'address', true);

    if (address) {
      const _address = getAttribute(address, 'address') || '';
      const city = getAttribute(address, 'city') || '';
      const stateProvince = getAttribute(address, 'stateProvince') || '';
      const country = getAttribute(address, 'country') || '';
      const zipPostal = getAttribute(address, 'zipPostal') || '';

      organizationInformation = (
        `${organizationName}\n`
        + `${_address}\n`
        + `${city ? city + ',' : ''} ${stateProvince}\n`
        + `${country ? country + ',' : ''} ${zipPostal}`
      );
    }

    return organizationInformation;
  };

  const invoiceLineKeys = {
    shipperInformation: true,
    consigneeInformation: true,
    itemName: true,
    pickupDateTime: true,
    dropoffDateTime: true,
  };

  const updateInvoiceLine = (value, invoiceLine, key) => {
    const invoiceLineId = invoiceLine.id;
    const invoiceLinesIndex = invoiceLines.findIndex((invoiceLine) => invoiceLine.id === invoiceLineId);

    let selectedInvoiceLine = invoiceLines.filter((_invoiceLine) => {
      return invoiceLine.id === _invoiceLine.id;
    });
    selectedInvoiceLine = selectedInvoiceLine[0];

    let updatedInvoiceLine = null;
    if (invoiceLineKeys[key]) {
      updatedInvoiceLine = { ...selectedInvoiceLine, [key]: value };
    } else {
      updatedInvoiceLine = { ...selectedInvoiceLine, rate: value };
    }

    const _invoiceLines = [...invoiceLines];
    if (invoiceLinesIndex !== -1) {
      _invoiceLines[invoiceLinesIndex] = updatedInvoiceLine;
    }

    setInvoiceLines(_invoiceLines);
  };

  const addInvoiceLine = () => {
    const invoiceLine = {
      id: uniqid(),
      itemName: 'input name here',
      shipperInformation: 'input shipper here',
      consigneeInformation: 'input consignee here',
      pickupDateTime: 'input pickup date here',
      dropoffDateTime: 'input dropoff date here',
      rate: 0.00,
    };
    setInvoiceLines(invoiceLines => [...invoiceLines, invoiceLine]);
  };

  const deleteInvoiceLine = (invoiceLineId) => {
    const _invoiceLines = invoiceLines.filter((invoiceLine) => {
      return invoiceLine.id !== invoiceLineId;
    });
    setInvoiceLines(_invoiceLines);
  };

  // ** Table Column Templates ** //
  const itemNameTemplate = (invoiceLine) => {
    const { itemName } = invoiceLine;
    return (
      <div className="input-table-field name-field">
        <DocumentInputText
          inputClassName="text-sm"
          value={itemName}
          onChange={(value) => updateInvoiceLine(value, invoiceLine, 'itemName')}
          success={itemName}
          warning={!itemName || itemName == ''}
          isDownloaded={props.isDownloaded}
        />
      </div>
    );
  };

  const shipperInformationTemplate = (invoiceLine) => {
    const { shipperInformation } = invoiceLine;
    return (
      <div className="shipper-consignee-field input-field">
        <DocumentInputTextarea
          inputClassName="text-sm"
          value={shipperInformation !== null ? shipperInformation : ''}
          onChange={(value) => updateInvoiceLine(value, invoiceLine, 'shipperInformation')}
          success={shipperInformation}
          warning={!shipperInformation || shipperInformation == ''}
          isDownloaded={props.isDownloaded}
        />
      </div>
    );
  };

  const consigneeInformationTemplate = (invoiceLine) => {
    const { consigneeInformation } = invoiceLine;
    return (
      <div className="shipper-consignee-field input-field">
        <DocumentInputTextarea
          inputClassName="text-sm"
          value={consigneeInformation !== null ? consigneeInformation : ''}
          onChange={(value) => updateInvoiceLine(value, invoiceLine, 'consigneeInformation')}
          success={consigneeInformation}
          warning={!consigneeInformation || consigneeInformation == ''}
          isDownloaded={props.isDownloaded}
        />
      </div>
    );
  };

  const pickupDateTimeTemplate = (invoiceLine) => {
    const { pickupDateTime } = invoiceLine;
    return (
      <div className="input-table-field input-field">
        <DocumentInputText
          inputClassName="text-sm"
          value={pickupDateTime}
          onChange={(value) => updateInvoiceLine(value, invoiceLine, 'pickupDateTime')}
          success={pickupDateTime}
          warning={!pickupDateTime || pickupDateTime == ''}
          isDownloaded={props.isDownloaded}
        />
      </div>
    );
  };

  const dropoffDateTimeTemplate = (invoiceLine) => {
    const { dropoffDateTime } = invoiceLine;
    return (
      <div className="input-table-field input-field">
        <DocumentInputText
          inputClassName="text-sm"
          value={dropoffDateTime}
          onChange={(value) => updateInvoiceLine(value, invoiceLine, 'dropoffDateTime')}
          success={dropoffDateTime}
          warning={!dropoffDateTime || dropoffDateTime == ''}
          isDownloaded={props.isDownloaded}
        />
      </div>
    );
  };

  const rateTemplate = (invoiceLine) => {
    const { rate } = invoiceLine;
    return (
      <div className="input-table-field input-field rate-field">
        <DocumentInputNumber
          inputClassName="text-sm"
          value={rate}
          onChange={(value) => updateInvoiceLine(value, invoiceLine, 'rate')}
          mode="decimal"
          minFractionDigits={2}
          success={rate}
          warning={!rate || rate == ''}
          isDownloaded={props.isDownloaded}
        />
      </div>
    );
  };

  const deleteButtonTemplate = (invoiceLine) => {
    return (
      <div className="delete-button rate-table-delete-button">
        <Button
          className="p-button-rounded"
          icon="pi pi-times"
          onClick={() => deleteInvoiceLine(invoiceLine.id)}
        />
      </div>
    );
  };

  // ** Components ** //
  const leftHeaderContents = (
    <>
      <div className="pr-2 font-bold">Freight Item Rates</div>
      <div className="add-button">
        <Button className="p-button-rounded" icon="pi pi-plus" onClick={() => addInvoiceLine()} />
      </div>
    </>
  );

  const ratesHeader = (
    <div className="rate-header">
      <Toolbar left={leftHeaderContents} />
    </div>
  );

  const invoiceRateTable = (
    <DataTable value={invoiceLines} emptyMessage=" ">
      <Column body={itemNameTemplate} header="NAME" />
      <Column body={shipperInformationTemplate} header="SHIPPER" />
      <Column body={pickupDateTimeTemplate} header="PICKUP DATE" />
      <Column body={consigneeInformationTemplate} header="CONSIGNEE" />
      <Column body={dropoffDateTimeTemplate} header="DROPOFF DATE" />
      <Column body={rateTemplate} header="RATE" />
      <Column body={deleteButtonTemplate} />
    </DataTable>
  );

  return (
    <div className="invoice-rate-table mx-7 mb-5">
      <div className="border-bottom-1">
        {ratesHeader}
        {invoiceRateTable}
      </div>
    </div>
  );
};

export default InvoiceRateTable;
