import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// components
import Button from 'sbCore/Button/Button';
import Skeleton from 'sbCore/Skeleton/Skeleton';

// styles
import './style.scss';

/**
 * @description Button logic for CSVUploader
 * @param {String} className
 * @param {Function} toggleModalVisible - Function to toggle the CSVUploader Dialog
 * @param {String} [buttonLabel] - Label for the upload button
 * @param {Boolean} [isLoading] - Whether the button should be loading according to a parent
 * @param {Boolean} [disabled] - Whether the button should be disabled according to a parent
 * @param {String} [severity] - Severity color of the button (ex. "primary", "secondary", etc)
 * @returns
 */
function CSVUploaderButton({ ...props }) {

  const [isLoading, setIsLoading] = useState(false);

  // Button properties
  const [buttonLabel] = useState(props.buttonLabel || 'Upload File');
  const [disabled, setDisabled] = useState(props.disabled ?? true); // shows disabled button. should default to true so button does not initially render as active

  useEffect(() => {
    // Initializer
    setIsLoading(true);
    let didCancel = false;

    async function init() {
      const _disabled = props.disabled ?? false;
      const _isLoading = props.isLoading ?? false;

      if (!didCancel) {
        setIsLoading(_isLoading);
        setDisabled(_disabled);
      }
    }

    init();
    return () => { didCancel = true; };
  }, [props.isLoading, props.disabled]);

  let className = 'csv-uploader-btn';
  if (props.className) className += ` ${props.className}`;

  return (
    <>
      {!isLoading && (
        <Button
          className={className}
          label={buttonLabel}
          severity={props.severity}
          onClick={() => props.toggleModalVisible()}
          sbVariant="short"
          disabled={disabled}
        />
      )}
      <Skeleton
        className={(!isLoading) ? 'hidden' : `csv-uploader-btn-skeleton ${uniqid()} ${props.className}`}
        width="105px"
        height="38px"
      />
    </>
  );
}

export default CSVUploaderButton;
