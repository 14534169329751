import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardActions, CardHeader, CardText, CardTitle, FlatButton, Slider, TextField } from 'material-ui';
import { MDBInput } from 'mdbreact';
import ReactTooltip from 'react-tooltip';

// CSS
import styles from './AddEditGeofenceDetails.module.scss';

function getMax(zoom, currentValue, type) {
  if (type === 'width') {
    const calculatedMax = 230.82 * Math.exp(-0.666 * zoom) * 1.5;
    return Math.max(calculatedMax, currentValue);
  } else if (type === 'height') {
    const calculatedMax = 230.82 * Math.exp(-0.666 * zoom);
    return Math.max(calculatedMax, currentValue);
  }
  return currentValue;
}

function AddEditGeofenceDetails(props) {
  const speedLimitHelpTooltip = (
    <ReactTooltip place="right" effect="solid" type="dark" id="speedLimitHelpTooltip">
      {t('When you set a speed limit, you will:')}
      <br />
      • {t('Receive notifications when your driver is above the speed limit when inside the geofence')}
    </ReactTooltip>
  );

  return (
    <div className={props.isSidebarOpen ? `${styles.container}` : `${styles.container} ${styles.sidebarClosed}`}>
      <Card>
        <CardTitle
          title={t(`${props.type === 'edit' ? 'Edit Geofence' : 'Click on map to create a geofence'}`)}
          subtitle={props.geofenceProperties.latitude ? 'Click and Drag Geofence to edit' : ''}
        />
        { props.geofenceProperties.latitude && props.geofenceProperties.longitude && (
          <div>
            <CardText style={{ paddingTop: 0, paddingBottom: 0 }}>
              <div className={styles.sliderBlock}>
                <div className="inlineBlock">
                  <div>{t('Width')}</div>
                  <div><Slider sliderStyle={{ margin: 0, width: '100%' }} min={0.0005} max={getMax(props.zoom, props.geofenceProperties.width, 'width')} step={0.0000001} value={props.geofenceProperties.width} onChange={(e, newVal) => props.adjustGeofence('width', newVal)} /></div>
                </div>
                <div className="inlineBlock">
                  <div>{t('Height')}</div>
                  <div><Slider sliderStyle={{ margin: 0, width: '100%' }} min={0.0004} max={getMax(props.zoom, props.geofenceProperties.height, 'height')} step={0.0000001} value={props.geofenceProperties.height} onChange={(e, newVal) => props.adjustGeofence('height', newVal)} /></div>
                </div>
              </div>
              <div className={styles.helpText}>{t('It is recommended to create a slightly larger geofence')}</div>
              <TextField
                floatingLabelText={t('Enter Geofence Name')}
                value={props.geofenceProperties.name}
                onChange={(e, newVal) => props.adjustGeofence('name', newVal)}
              />
              <br />

              { props.enableSpeedLimitSetting &&
                <div
                  data-tip 
                  data-for="speedLimitHelpTooltip" 
                >
                  { speedLimitHelpTooltip }
                  <TextField
                    floatingLabelText={t('Speed Limit (km)')}
                    value={props.geofenceProperties.speedLimitKm}
                    onChange={(e, newVal) => props.adjustGeofence('speedLimitKm', parseFloat(newVal))}
                  />
                </div>
              }

              <MDBInput 
                label={t('Email when vehicle enters/exits')}
                className="h-auto w-auto" 
                type="checkbox" 
                id="checkbox-1"
                checked={props.geofenceProperties.watchedBy && props.geofenceProperties.watchedBy.map((watchedByUser) => watchedByUser.id).indexOf(props.user.id) !== -1}
                disabled={props.loadingWatch}
                onChange={(e) => {
                  props.watchUnwatchGeofence(e.target.checked)
                }}
              />

              { props.error &&
                <div className={styles.error}>{ props.error }</div>
              }
            </CardText>
            <CardActions style={{ textAlign: 'right' }}>
              { props.type === 'edit' && <FlatButton label="Delete" secondary onClick={props.delete} /> }
              <FlatButton label={t('Cancel')} onClick={props.cancel} />
              <FlatButton label={t('Save')} primary onClick={props.save} />
            </CardActions>
          </div>
        )}
        { !(props.geofenceProperties.latitude && props.geofenceProperties.longitude) && (
          <div>
            {/* <CardText style={{ paddingTop: 0, paddingBottom: 0 }}>
              <div className={styles.helpText}>{t('Click on the map to create a Geofence')}</div>
            </CardText> */}
            <CardActions style={{ textAlign: 'right' }}>
              <FlatButton label={t('Cancel')} onClick={props.cancel} />
            </CardActions>
          </div>
        )}
      </Card>
    </div>
  );
}

AddEditGeofenceDetails.propTypes = {
  error: PropTypes.string,
  geofenceProperties: PropTypes.object.isRequired,
  adjustGeofence: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  zoom: PropTypes.number.isRequired,
  enableSpeedLimitSetting: PropTypes.bool,
  watchUnwatchGeofence: PropTypes.func.isRequired,
  loadingWatch: PropTypes.bool,
  user: PropTypes.object,
};

export default AddEditGeofenceDetails;
