import moment from 'moment-timezone';

// API
import { getELDDailyCertification, sortELDEvents, formatMilliTime } from 'api/ELD';
import { createQuery, findRecords, getCurrentUserSessionToken, setQueryRestriction, getAttribute } from 'sb-csapi/dist/AAPI';

// Enums
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// Lists
import * as HoursCycle from 'api/Lists/HoursCycle';

/**
 * @memberof module:DriverHOSRecap
 * @description Determine US and Canada HOS recap given a specific driver
 *
 * @param {Driver} driver
 * @param {ELDDailyCertification} eldDailyCertification
 * @param {boolean} capHours
 */
async function getDriverHOSRecap(driver, eldDailyCertification, capHours) {
  if (!eldDailyCertification) eldDailyCertification = await getELDDailyCertification(driver, new Date(), false);

  let caCycleType;
  let caOnDutyCycleHours;
  let caDriveTimeLeft;
  let caOnDutyTimeLeft;

  let usCycleType;
  let usOnDutyCycleHours;
  let usDriveTimeLeft;
  let usOnDutyTimeLeft;
  let usBreakTime;

  if (eldDailyCertification) {
    // Canada Hours - eldDailyCertification
    // Using driver here since the field doesnt seem to be filled out in the ELDDailyCert
    caCycleType = HoursCycle.canada[getAttribute(driver, 'hourCycleCanadaInt', true)];
    if (caCycleType === undefined) caCycleType = '';

    caOnDutyCycleHours = getAttribute(eldDailyCertification, 'caOnDutyCycleHours', true);
    if (caOnDutyCycleHours === undefined) caOnDutyCycleHours = 'N/A';

    caDriveTimeLeft = getAttribute(eldDailyCertification, 'caShiftDriveTimeLeft', true);
    if (caDriveTimeLeft === undefined) caDriveTimeLeft = 'N/A';

    caOnDutyTimeLeft = getAttribute(eldDailyCertification, 'caShiftOnDutyTimeLeft', true);
    if (caOnDutyTimeLeft === undefined) caOnDutyTimeLeft = 'N/A';

    // US Hours - eldDailyCertification
    usCycleType = HoursCycle.us[getAttribute(driver, 'hourCycleUSAInt', true)];
    if (usCycleType === undefined) usCycleType = '';

    usOnDutyCycleHours = getAttribute(eldDailyCertification, 'usOnDutyCycleHours', true);
    if (usOnDutyCycleHours === undefined) usOnDutyCycleHours = 'N/A';

    usDriveTimeLeft = getAttribute(eldDailyCertification, 'usDriveTimeLeft', true);
    if (usDriveTimeLeft === undefined) usDriveTimeLeft = 'N/A';

    usOnDutyTimeLeft = getAttribute(eldDailyCertification, 'usShiftHours', true);
    if (usOnDutyTimeLeft === undefined) usOnDutyTimeLeft = 'N/A';

    usBreakTime = getAttribute(eldDailyCertification, 'usBreakTime', true);
    if (usBreakTime === undefined) usBreakTime = 'N/A';

    if (capHours) {
      caOnDutyTimeLeft = Math.min(
        getAttribute(eldDailyCertification, 'caShiftOnDutyTimeLeft') || 0,
        getAttribute(eldDailyCertification, 'caShiftHours') || 0,
        getAttribute(eldDailyCertification, 'caOnDutyCycleHours') || 0,
      );

      caDriveTimeLeft = Math.min(
        getAttribute(eldDailyCertification, 'caShiftDriveTimeLeft') || 0,
        caOnDutyTimeLeft,
      );

      usOnDutyTimeLeft = Math.min(
        getAttribute(eldDailyCertification, 'usShiftHours') || 0,
        getAttribute(eldDailyCertification, 'usOnDutyCycleHours') || 0,
      );

      usDriveTimeLeft = Math.min(
        getAttribute(eldDailyCertification, 'usDriveTimeLeft') || 0,
        getAttribute(eldDailyCertification, 'usOnDutyCycleHours') || 0,
        usOnDutyTimeLeft,
      );
    }
  } else {
    // Canada Hours - driver
    caCycleType = HoursCycle.canada[getAttribute(driver, 'hourCycleCanadaInt', true)];
    if (caCycleType === undefined) caCycleType = '';

    caOnDutyCycleHours = getAttribute(driver, 'caOnDutyCycleHours', true);
    if (caOnDutyCycleHours === undefined) caOnDutyCycleHours = 'N/A';

    caDriveTimeLeft = getAttribute(driver, 'caShiftDriveTimeLeft', true);
    if (caDriveTimeLeft === undefined) caDriveTimeLeft = 'N/A';

    caOnDutyTimeLeft = getAttribute(driver, 'caShiftOnDutyTimeLeft', true);
    if (caOnDutyTimeLeft === undefined) caOnDutyTimeLeft = 'N/A';

    // US Hours - driver
    usCycleType = HoursCycle.us[getAttribute(driver, 'hourCycleUSAInt', true)];
    if (usCycleType === undefined) usCycleType = '';

    usOnDutyCycleHours = getAttribute(driver, 'usOnDutyCycleHours', true);
    if (usOnDutyCycleHours === undefined) usOnDutyCycleHours = 'N/A';

    usDriveTimeLeft = getAttribute(driver, 'usDriveTimeLeft', true);
    if (usDriveTimeLeft === undefined) usDriveTimeLeft = 'N/A';

    usOnDutyTimeLeft = getAttribute(driver, 'usShiftHours', true);
    if (usOnDutyTimeLeft === undefined) usOnDutyTimeLeft = 'N/A';

    usBreakTime = getAttribute(driver, 'usBreakTime', true);
    if (usBreakTime === undefined) usBreakTime = 'N/A';

    if (capHours) {
      caOnDutyTimeLeft = Math.min(
        getAttribute(driver, 'caShiftOnDutyTimeLeft') || 0,
        getAttribute(driver, 'caShiftHours') || 0,
        getAttribute(driver, 'caOnDutyCycleHours') || 0,
      );

      caDriveTimeLeft = Math.min(
        getAttribute(driver, 'caShiftDriveTimeLeft') || 0,
        caOnDutyTimeLeft,
      );

      usOnDutyTimeLeft = Math.min(
        getAttribute(driver, 'usShiftHours') || 0,
        getAttribute(driver, 'usOnDutyCycleHours') || 0,
      );

      usDriveTimeLeft = Math.min(
        getAttribute(driver, 'usDriveTimeLeft') || 0,
        getAttribute(driver, 'usOnDutyCycleHours') || 0,
        usOnDutyTimeLeft,
      );
    }
  }
  // console.log(
  //   eldDailyCertification,
  //   {
  //     caCycleType,
  //     caOnDutyCycleHours, caOnDutyCycleHoursFormatted: formatMilliTime(caOnDutyCycleHours),
  //     caDriveTimeLeft, caDriveTimeLeftFormatted: formatMilliTime(caDriveTimeLeft),
  //     caOnDutyTimeLeft, caOnDutyTimeLeftFormatted: formatMilliTime(caOnDutyTimeLeft),
  //     usCycleType,
  //     usOnDutyCycleHours, usOnDutyCycleHoursFormatted: formatMilliTime(usOnDutyCycleHours),
  //     usDriveTimeLeft, usDriveTimeLeftFormatted: formatMilliTime(usDriveTimeLeft),
  //     usOnDutyTimeLeft, usOnDutyTimeLeftFormatted: formatMilliTime(usOnDutyTimeLeft),
  //     usBreakTime, usBreakTimeFormatted: formatMilliTime(usBreakTime),
  //   },
  // );
  return {
    caCycleType,
    caOnDutyCycleHours,
    caDriveTimeLeft,
    caOnDutyTimeLeft,
    usCycleType,
    usOnDutyCycleHours,
    usDriveTimeLeft,
    usOnDutyTimeLeft,
    usBreakTime,
  };
}

/**
 * This function helps calculate the cycle reset based off of eldDailyCert's ELDEvents.
 *
 * When running this function, do note that the last ~3 eldDailyCertification's cycleResetInt MAY NOT be calculated correctly.
 * This is because in order to calculate the cycle reset int, we look back at most 3 days before the current eldDailyCert. Thus, when the
 * function runs for the last few eldDailyCertifications, it might be missing some information required to calculate the correct cycle reset int.
 *
 * @param {Array} eldDailyCertificationArray - An array of eldDailyCertifications to calculate cycle reset ints. Generally, this array contains all original eldDailyCertifications + 3 extra days to account for calculation. This is under the assumption that the eldDailyCerts are sorted in descending order
 * @param {int} daysInCACycle - The number of days in the driver's CA hours cycle. This is used to determine the cycle reset for new drivers
 * @param {int} daysInUSCycle - The number of days in the driver's US hours cycle. This is used to determine the cycle reset for new drivers
 * @returns {Object} - An object containing a mapping between the eldDailyCertification id and it's corresponding cycle reset int
 */
async function calculateCycleReset(eldDailyCertificationArray, daysInCACycle, daysInUSCycle) {
  const eldEventPromises = [];
  const sleeperBerthOffDutyEventTypeCodes = [11, 12, 31]; // Include type 31 (PC) since that generates an off-duty event
  const filterableEventTypeCodes = [11, 12, 13, 14, 21, 22, 31, 32];

  // Fetch all ELDEvents from the eldDailyCertifications
  for (let i = 0; i < eldDailyCertificationArray.length; i++) {
    const eldDailyCertificationObj = eldDailyCertificationArray[i];
    const eldEventQuery = createQuery('ELDEvent');
    setQueryRestriction(eldEventQuery, QueryRestriction.EQUAL_TO, 'eldDailyCertification', eldDailyCertificationObj.id);
    setQueryRestriction(eldEventQuery, QueryRestriction.EQUAL_TO, 'eldEventRecordStatusInt', 1);

    eldEventPromises.push(findRecords({ sessionToken: getCurrentUserSessionToken() }, eldEventQuery, false, true));
  }

  // Flatten all ELDEvents
  let eldEventsArr = await Promise.all(eldEventPromises);
  eldEventsArr = eldEventsArr.flat();

  // Final return object
  const eldDailyCertResetCycleIntMap = {};

  // Filter ELDEvents for filterableEventTypeCodes - this is the same filtering as the HOS Graph
  // Also filter out co-driver events
  let filteredELDEvents = eldEventsArr.filter((eldEvent) => {
    const eldEventRecordStatusInt = getAttribute(eldEvent, 'eldEventRecordStatusInt');
    const eldEventTypeCodeInt = getAttribute(eldEvent, 'eldEventTypeCodeInt');

    const coDriverIntermELDEvent = getAttribute(eldEvent, 'coDriverIntermELDEvent');

    return filterableEventTypeCodes.includes(eldEventTypeCodeInt) && eldEventRecordStatusInt === 1 && !coDriverIntermELDEvent;
  });

  // Sort the ELDEvents in ascending order - now we have a chronological order of ELDEvents for the given eldDailyCertifications
  sortELDEvents(filteredELDEvents, 1);

  // Now go through the array, and determine the all the off duty/sleeper berth intervals
  const offDutySleeperBerthIntervals = [];

  // Determine all off-duty/sleeper berth intervals
  filteredELDEvents.forEach((eldEvent, index) => {
    if (index > 0) {
      const previousELDEvent = filteredELDEvents[index - 1];
      const previousELDEventTypeCodeInt = getAttribute(previousELDEvent, 'eldEventTypeCodeInt');

      let currentELDEventTypeCodeInt = getAttribute(eldEvent, 'eldEventTypeCodeInt');
      const isOffDutySleeperBerthInterval = sleeperBerthOffDutyEventTypeCodes.includes(previousELDEventTypeCodeInt);

      // Check to see if current event is autogenerated event for midnight
      // If it is, then use the previous event's eldEventTypeCodeInt
      // There seems to be an issue where the previous event's eldEventTypeCodeInt does not match properly
      const currentELDEventDailyCertificationId = getAttribute(eldEvent, 'eldDailyCertification').id;
      const eldDailyCertification = eldDailyCertificationArray.find((eldDailyCertification) => eldDailyCertification.id === currentELDEventDailyCertificationId);
      const eldDailyCertificationStartTimeUTC = getAttribute(eldDailyCertification, 'startTimeUTC');

      if (moment(getAttribute(eldEvent, 'eventDateTime')).isSame(moment(eldDailyCertificationStartTimeUTC))) {
        eldEvent.set('eldEventTypeCodeInt', previousELDEventTypeCodeInt); // Temporarily set eldEventTypeCodeInt to match the previous one
        currentELDEventTypeCodeInt = previousELDEventTypeCodeInt;
      }

      if (isOffDutySleeperBerthInterval) {
        const startELDEventTime = getAttribute(previousELDEvent, 'eventDateTime');
        const endELDEventTime = getAttribute(eldEvent, 'eventDateTime');

        offDutySleeperBerthIntervals.push({
          eldDailyCertificationIds: new Set([previousELDEvent.get('eldDailyCertification').id, eldEvent.get('eldDailyCertification').id]),
          startELDEvent: previousELDEvent.id,
          endELDEvent: eldEvent.id,
          startELDEventTime,
          endELDEventTime,
          startELDEventTypeCodeInt: previousELDEventTypeCodeInt,
          endELDEventTypeCodeInt: currentELDEventTypeCodeInt,
          intervalMilliseconds: moment(endELDEventTime).diff(moment(startELDEventTime), 'milliseconds'),
        });
      }
    }
  });

  const combinedOffDutySleeperBerthInterval = [];
  let previousCombinedInterval = offDutySleeperBerthIntervals[0];

  // Combine any consecutive intervals together
  for (let i = 1; i < offDutySleeperBerthIntervals.length; i++) {
    const currentOffDutySleeperBerthInterval = offDutySleeperBerthIntervals[i];

    if (previousCombinedInterval.endELDEvent === currentOffDutySleeperBerthInterval.startELDEvent) {
      // Combine intervals together
      const combinedOffDutySleeperBerthInterval = {
        ...previousCombinedInterval,
        eldDailyCertificationIds: new Set([...previousCombinedInterval.eldDailyCertificationIds, ...currentOffDutySleeperBerthInterval.eldDailyCertificationIds]),
        endELDEvent: currentOffDutySleeperBerthInterval.endELDEvent,
        endELDEventTime: currentOffDutySleeperBerthInterval.endELDEventTime,
        endELDEventTypeCodeInt: currentOffDutySleeperBerthInterval.endELDEventTypeCodeInt,
        intervalMilliseconds: moment(currentOffDutySleeperBerthInterval.endELDEventTime).diff(moment(previousCombinedInterval.startELDEventTime), 'milliseconds'),
      }

      previousCombinedInterval = combinedOffDutySleeperBerthInterval;
    } else {
      combinedOffDutySleeperBerthInterval.push(previousCombinedInterval);
      previousCombinedInterval = currentOffDutySleeperBerthInterval;
    }

    if (i === offDutySleeperBerthIntervals.length - 1) {
      combinedOffDutySleeperBerthInterval.push(previousCombinedInterval);
    }
  }

  const cadHourCycleResetMilliseconds = 129600000; // represents 36 hours in milliseconds
  const usHourCycleResetMilliseconds = 122400000; // represents 34 hours in milliseconds

  // Go through each of the eldDailyCertifications, and determine if there was a reset that occurred in that day
  for (let i = 0; i < eldDailyCertificationArray.length; i++) {
    const eldDailyCertification = eldDailyCertificationArray[i];
    const eldDailyCertificationId = eldDailyCertification.id;

    // If this is the earliest eldDailyCertification, and the total number of eldDailyCertifications is less than the driver's cycle days, then make sure its a reset. This is to account for new drivers.
    // This function: calculateCycleReset accepts an array of eldDailyCertifications + 3 extra days to calculate the cycle resets.
    // This means that if (eldDailyCertificationArray.length - 3) < driverCycleDays, reset
    if (i === eldDailyCertificationArray.length - 1) {
      if (eldDailyCertificationArray.length - 3 < daysInCACycle) {
        eldDailyCertResetCycleIntMap[eldDailyCertificationId] = 3; // Canada cycle reset
      } else if (eldDailyCertificationArray.length - 3 < daysInUSCycle) {
        eldDailyCertResetCycleIntMap[eldDailyCertificationId] = 2; // US cycle reset
      }

      continue;
    }

    // Fetch the current eldDailyCertification's endTimeUTC
    const eldDailyCertificationEndDate = getAttribute(eldDailyCertification, 'endTimeUTC');

    // 1. Fetch all the off-duty/sleeper berth intervals for that eldDailyCertification
    let _offDutySleeperBerthIntervals = combinedOffDutySleeperBerthInterval.filter((interval) => interval.eldDailyCertificationIds.has(eldDailyCertificationId));

    let cycleResetInt = 0;
    let intervalMilliseconds = -1;

    // Go through each of the intervals which contain this eldDailyCertification
    for (let j = 0; j < _offDutySleeperBerthIntervals.length; j++) {
      const offDutySleeperBerthInterval = _offDutySleeperBerthIntervals[j];

      const startELDEventTime = offDutySleeperBerthInterval.startELDEventTime;
      let endELDEventTime = offDutySleeperBerthInterval.endELDEventTime;
      intervalMilliseconds = offDutySleeperBerthInterval.intervalMilliseconds;

      // If the endELDEventTime is after the eldDailyCertificationEndDate, then we know that this interval continues after the current eldDailyCertification date
      // Thus, we want to re-calculate the endELDEventTime and intervalMilliseconds to the eldDailyCertificationEndDate
      if (moment(endELDEventTime).isAfter(moment(eldDailyCertificationEndDate))) {
        endELDEventTime = eldDailyCertificationEndDate;
        intervalMilliseconds = moment(endELDEventTime).diff(moment(startELDEventTime), 'milliseconds');
      }

      // Check to see if the given interval passes the hour cycle reset times, if it does, then stop early
      if (intervalMilliseconds >= cadHourCycleResetMilliseconds) {
        cycleResetInt = 3;
        break;
      } else if (intervalMilliseconds >= usHourCycleResetMilliseconds) {
        cycleResetInt = 2;
        break;
      }
    }

    eldDailyCertResetCycleIntMap[eldDailyCertificationId] = cycleResetInt;
  }

  return eldDailyCertResetCycleIntMap;
}

export {
  getDriverHOSRecap,
  calculateCycleReset,
};
