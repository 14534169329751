import React from 'react';
import PropTypes from 'prop-types';

// API
import { getAttribute } from 'api/Parse';
import { isAlmostOutOfHours, projectAndFormatDrivingTime, projectAndFormatOnDutyTime } from 'api/ELD';
import { convertMillisecondsToHours, formatTimeNumberToHoursMinutes } from 'api/Helpers';
import * as HoursCycle from 'api/Lists/HoursCycle';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './CAHoursView.module.scss';

function CAHoursView(props) {
  const { driver, canadaHours, capHours } = props;
  const { caCycleType, caOnDutyCycleHours, caDriveTimeLeft, caOnDutyTimeLeft, eldDailyCertification } = canadaHours;

  const caOnDutyCycleHoursMilli = Math.max(0, projectAndFormatOnDutyTime(caOnDutyCycleHours, driver, eldDailyCertification));
  const caOnDutyTimeLeftMilli = capHours ? Math.min(Math.max(0, projectAndFormatOnDutyTime(caOnDutyTimeLeft, driver, eldDailyCertification)), caOnDutyCycleHoursMilli) : Math.max(0, projectAndFormatOnDutyTime(caOnDutyTimeLeft, driver, eldDailyCertification));
  const caDriveTimeLeftMilli = capHours ? Math.min(Math.max(0, projectAndFormatDrivingTime(caDriveTimeLeft, driver, eldDailyCertification)), caOnDutyTimeLeftMilli) : Math.max(0, projectAndFormatDrivingTime(caDriveTimeLeft, driver, eldDailyCertification));

  return (
    <div className={styles.caHoursView}>
      <table className={styles.caHoursViewTable}>
        <tbody>
          <tr className={`${styles.header} translate-me`}>
            <td className={styles.title} rowSpan={2}>
              Canadian
              <br />
              Hours-of-Service
            </td>
            <td>
              Cycle Type
            </td>
            <td>
              Cycle Hours
              <br />
              Remaining
            </td>
            <td>
              Drive Time
              <br />
              Remaining
            </td>
            <td>
              On Duty Time
              <br />
              Remaining
            </td>
          </tr>
          {!props.fetched && (
            <tr>
              <td colSpan={4}><LoadingIcon /></td>
            </tr>
          )}
          {props.fetched && (
            <tr>
              <td>{caCycleType}</td>
              <td className={caOnDutyCycleHours !== 'N/A' && isAlmostOutOfHours(caOnDutyCycleHoursMilli) ? `${styles.hourNumberOut}` : null}>
                {(caOnDutyCycleHours === 'N/A' || caOnDutyCycleHours === undefined) ?
                  'N/A'
                  :
                  (
                    <span>
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(caOnDutyCycleHoursMilli))}
                      {/* <br />
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(caOnDutyCycleHours))}
                      <br />
                      {caOnDutyCycleHours} */}
                    </span>
                  )
                }
              </td>
              <td className={caDriveTimeLeft !== 'N/A' && isAlmostOutOfHours(caDriveTimeLeftMilli) ? `${styles.hourNumberOut}` : null}>
                {(caDriveTimeLeft === 'N/A' || caDriveTimeLeft === undefined) ?
                  'N/A'
                  :
                  (
                    <span>
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(caDriveTimeLeftMilli))}
                      {/* <br />
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(caDriveTimeLeft))}
                      <br />
                      {caDriveTimeLeft} */}
                    </span>
                  )
                }
              </td>
              <td className={caOnDutyTimeLeft !== 'N/A' && isAlmostOutOfHours(caOnDutyTimeLeftMilli) ? `${styles.hourNumberOut}` : null}>
                {(caOnDutyTimeLeft === 'N/A' || caOnDutyTimeLeft === undefined) ?
                  'N/A'
                  :
                  (
                    <span>
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(caOnDutyTimeLeftMilli))}
                      {/* <br />
                      {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(caOnDutyTimeLeft))}
                      <br />
                      {caOnDutyTimeLeft} */}
                    </span>
                  )
                }
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

CAHoursView.propTypes = {
  canadaHours: PropTypes.instanceOf(Object).isRequired,
  fetched: PropTypes.bool.isRequired,
  driver: PropTypes.object.isRequired,
  capHours: PropTypes.bool.isRequired,
};

export default CAHoursView;
