import React from 'react';

// components
import { Steps as PRSteps } from 'primereact/steps';

// styling
import './style.scss';

export default function Steps({ ...props }) {
  return (
    <div className="sbc-steps">
      <PRSteps {...props} />
    </div>
  );
}
