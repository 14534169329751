import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
// Components
// CSS
import './style.scss';

function TrialCountdownWarning(props) {
  const daysUntilTrialEnd = moment(props.subscription.get('ignoreSubscriptionUntil')).diff(moment(), 'days');
  return (
    <div className="trial-countdown-warning-container ml-2">
      { daysUntilTrialEnd } day(s) until trial ends
    </div>
  );
}

TrialCountdownWarning.propTypes = {
  subscription: PropTypes.object,
};

export default TrialCountdownWarning;
