import React from 'react';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';

// CSS
import styles from './DeleteXButton.module.scss';

function DeleteXButton(props) {
  return (
    <div className={`${styles.deleteButton} ${props.className}`} onClick={props.handleClick} style={props.style}>
      <FontIcon className={`material-icons ${styles.icon}`}>delete_forever</FontIcon>
    </div>
  );
}

DeleteXButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  handleClick: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default DeleteXButton;
