import { InputNumber as PRInputNumber } from 'primereact/inputnumber';

import './style.scss';

/**
 * @description Prime React InputNumber component
 */
export default function InputNumber({ warning, error, success, ...props }) {
  let className = 'sbc-inputnumber';
  if (props.className) className += ` ${props.className}`;
  if (props.variant === 'small') className += ' input-text-small';

  if (error) {
    className += ` sbc-error`;
  } else if (warning) {
    className += ` sbc-warning`;
  } else if (success) {
    className += ` sbc-success`;
  }

  return (<PRInputNumber { ...props } className={className} />);
}
