import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SBSelect from '../SBSelect/SBSelect';
import { getSuggestedDrivers } from 'api/Driver/Driver';
import { getAttribute } from 'api/Parse';
import { formatName } from 'api/Helpers';

import './style.scss';

class DriverAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      asyncDrivers: [],
      userName: ''
    };
    this.loadDrivers = this.loadDrivers.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleDriverSelection = this.handleDriverSelection.bind(this);
  }

  async loadDrivers(term) {
    let companiesToLoad;
    if (this.props.childCompanyObjectIds) {
      companiesToLoad = this.props.childCompanyObjectIds;
    }
    const drivers = await getSuggestedDrivers(term, false, companiesToLoad);
    const tempDrivers = drivers.map((driver, index) => {
      return { key: index, value: driver, label: formatName(getAttribute(driver, 'user_fullName')) };
    });
    if (this.props.allowDeselect) tempDrivers.unshift({ key: 'none', value: undefined, label: 'Clear', isSpecialItem: true, removeableItem: true });
    await this.setState({ ...this.state, asyncDrivers: tempDrivers });
    // getSuggestedDrivers(term).then((drivers) => {
    //   let tempDrivers = drivers.map((driver, index) => {
    //     return { key: index, value: driver, label: formatName(getAttribute(driver, 'user_fullName')) };
    //   });
    //   if (this.props.allowDeselect) tempDrivers.unshift({ key: 'none', value: undefined, label: 'Clear', isSpecialItem: true, removeableItem: true });
    //   this.setState({ ...this.state, asyncDrivers: tempDrivers });
    // })
  }

  handleDriverSelection(selection) {
    this.props.onDriverSelected(selection[0].value);
    const userName = getAttribute(selection[0].value, 'user_fullName');
    return this.setState({ ...this.state, userName });
  }

  handleFilterChange(text) {
    if (this.props.onChange)
      this.props.onChange(text);
  }

  render() {
    return (
      <SBSelect
        containerClassName="flex-grow-1"
        onFilterChange={this.handleFilterChange}
        items={this.state.asyncDrivers}
        getSelectedItems={this.handleDriverSelection}
        isAutocomplete
        isRequired={this.props.isRequired}
        autocompleteLabel={this.props.autocompleteLabel ? this.props.autocompleteLabel : 'Search by Name'}
        onAutocompleteFocus={this.loadDrivers}
        onValueChange={this.loadDrivers}
        placeholder={this.props.enableStatePlaceholder ? this.state.userName : this.props.placeholder}
      />
    )
  }
}

DriverAutocomplete.defaultProps = {
  isRequired: true,
  enableStatePlaceholder: false,
}

DriverAutocomplete.propTypes = {
  onDriverSelected: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  allowDeselect: PropTypes.bool,
  isRequired: PropTypes.bool, // default is true
  autocompleteLabel: PropTypes.string, // default is 'Search by Name'
  placeholder: PropTypes.string,
  childCompanyObjectIds: PropTypes.array,
  enableStatePlaceholder: PropTypes.bool,
}

export default DriverAutocomplete
