import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { Toggle, SelectField, MenuItem } from 'material-ui';

// CSS
import styles from './PastDueModal.module.scss';

function ResellerClientModal(props) {
  return (
    <div className="translate-me">
      Your subscription is currently <kbd>PAST DUE</kbd>

      <br />

      <div className={styles.description}>
        <div>
          {props?.resellerCompanyObj?.get('name') &&
            (
              <div>
                This is most likely due to an issue with <var>{props?.resellerCompanyObj?.get('name')}</var>. Please contact <var>{props?.resellerCompanyObj?.get('name')}</var> to resolve.
              </div>
            )
          }
          {!props?.resellerCompanyObj?.get('name') &&
            (
              <div>
                Please contact us at <a href="mailto:support@onswitchboard.com">support@onswitchboard.com</a> to resolve.
              </div>
            )
          }
        </div>
      </div>

      <hr />

      <div className={styles.contactUs}>
        Our team is working hard to make sure your experience with Switchboard is <b>amazing</b>
        <br />
        Please let us know about anything we can do for you at <a href="mailto:support@onswitchboard.com">support@onswitchboard.com</a>
      </div>
    </div>
  );
}

ResellerClientModal.propTypes = {
  handleEnterCC: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
};

export default ResellerClientModal;
