import React from 'react';
import PropTypes from 'prop-types';
import { FlatButton } from 'material-ui';
import { MDBRow, MDBCol, MDBBtn } from "mdbreact";

import * as Getters from 'api/Getters';

// CSS
import styles from './GeofenceRow.module.scss';

/**
 * @Deprecated - Old geofence card for sidebar
 */
function GeofenceRow(props) {
  const company = Getters.getCurrentUserCompany()

  function clickEdit(e) {
    e.stopPropagation();
    if (props.addEditGeofence.type === 'edit' && props.addEditGeofence.geofence.id === props.geofence.id) {
      props.cancelEditMode();
    } else if (!props.addEditGeofence.type) {
      props.selectGeofence();
      props.setEditMode();
    }
  }

  return (
    <div onClick={() => props.selectGeofence()}
      onMouseEnter={() => props.handleHover(props.geofence, true)}
      onMouseLeave={() => props.handleHover(props.geofence, false)}
      className={props.active ? `${styles.activeRow} ${styles.row}` : styles.row}>
      <MDBRow className="mx-0">
        <MDBCol size="9" className="font-weight-bold text-truncate px-1 align-middle">
          <div className={styles.geofenceDetails}>
            { props.geofence.get('name') }
            { company && company.get('enableCompanyLinks') &&
              <div className={styles.company}>
                { props.geofence && props.geofence.get('belongsToCompany').get('name')}
              </div>
            }
          </div>
        </MDBCol>
        <MDBCol size="3" className="px-0 translate-me">
          {!props.addEditGeofence.type &&
            <MDBBtn color="primary" size="sm" className="mx-1 float-right btn-sm-sb" onClick={clickEdit}>Edit</MDBBtn>
          }
          {props.addEditGeofence.type === 'add' &&
            <MDBBtn disabled color="primary" size="sm" className="mx-1 float-right btn-sm-sb">Edit</MDBBtn>
          }
          {props.addEditGeofence.type === 'edit' && props.addEditGeofence.geofence.id === props.geofence.id &&
            <MDBBtn color="secondary" size="sm" className="mx-1 float-right btn-sm-sb" onClick={clickEdit}>Cancel</MDBBtn>
          }
          {props.addEditGeofence.type === 'edit' && props.addEditGeofence.geofence.id !== props.geofence.id &&
            <MDBBtn disabled color="primary" size="sm" className="mx-1 float-right btn-sm-sb">Edit</MDBBtn>
          }
        </MDBCol>
      </MDBRow>
    </div>
  );
}

GeofenceRow.propTypes = {
  active: PropTypes.bool,
  addEditGeofence: PropTypes.object.isRequired,
  cancelEditMode: PropTypes.func.isRequired,
  geofence: PropTypes.object.isRequired,
  selectGeofence: PropTypes.func.isRequired,
  handleHover: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
};

export default GeofenceRow;
