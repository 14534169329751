import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// API
import * as IFTA from 'api/IFTA';
import { StateProvinces } from 'api/Lists/StateProvinces';
import * as PDF from 'api/PDFer';
import * as Helpers from 'api/Helpers';
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { getAttribute } from 'api/Parse';

// Components
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import { MDBBtn } from 'mdbreact';
import TaxReportTotalSummary from 'components/IFTARoute/view/TaxReportTotalSummary';
import SBSelect from 'components/Shared/SBSelect/SBSelect';

function convertVehicleMileagesArrToSummary(vehicleMileagesArr, vehicleOdometerReadingsObj, IFTACurrency) {
  const stateProvinceCodes = StateProvinces.map((stateProvince) => stateProvince.code).filter((stateProvinceCode) => stateProvinceCode);

  // Set up stateProvinceMileages
  const stateProvinceMileages = {};
  for (let i = 0; i < stateProvinceCodes.length; i++) {
    stateProvinceMileages[stateProvinceCodes[i]] = {
      vehicleKm: 0,
      spreadKm: 0,
      distanceKm: 0,
      fuelVolumePumped: 0,
    };
  }

  const iftaVehicleArrMiles = {};
  // Loop through Vehicles
  for (let i = 0; i < vehicleMileagesArr.length; i++) {
    // Tally up savedVehicleKm into iftaArrMiles
    const vehicleMileagesObj = vehicleMileagesArr[i];
    let totalSavedKm = 0;
    let totalDistanceKm = 0;
    let dateStart;
    let dateEnd;
    iftaVehicleArrMiles[vehicleMileagesObj.unitId] = {};
    // Loop through iftaRoutes for period
    for (let j = 0; j < vehicleMileagesObj.iftaRouteArr.length; j++) {
      const iftaRoute = vehicleMileagesObj.iftaRouteArr[j];
      if (!dateStart) dateStart = getAttribute(iftaRoute, 'dateStart', true);
      if (!dateEnd || (getAttribute(iftaRoute, 'dateEnd', true) && moment(dateEnd).isBefore(moment(getAttribute(iftaRoute, 'dateEnd', true))))) dateEnd = getAttribute(iftaRoute, 'dateEnd', true);
      const stateProvinceCode = getAttribute(iftaRoute, 'stateProvince', true).toUpperCase();
      const savedVehicleKm = getAttribute(iftaRoute, 'savedVehicleKm', true);
      const distanceKm = getAttribute(iftaRoute, 'distanceKm', true);
      const fuelPurchases = getAttribute(iftaRoute, 'fuelPurchases', true);

      let volumePumpedForRoute = 0;
      if (fuelPurchases) {
        fuelPurchases.forEach(fuelPurchase => {
          const volumeUnits = getAttribute(fuelPurchase, 'volumeUnits');
          // Tax rates for CAD are based on litres pumped, and rates for USD are based on gallons
          let volumePumped = getAttribute(fuelPurchase, 'volumePumped', true);
          if (volumePumped !== undefined) {
            if (IFTACurrency === 'CAD') {
              volumePumped = (volumeUnits === 'g') ? Helpers.convertFuelUnit(volumePumped, 'gal', 'l') : volumePumped;
            } else if (IFTACurrency === 'USD') {
              volumePumped = (volumeUnits === 'l') ? Helpers.convertFuelUnit(volumePumped, 'l', 'gal') : volumePumped;
            }
            volumePumpedForRoute += volumePumped;
          }
        });
      }

      totalSavedKm += savedVehicleKm;
      totalDistanceKm += distanceKm;
      if (iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvinceCode]) {
        iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvinceCode].vehicleKm += savedVehicleKm;
        iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvinceCode].distanceKm += distanceKm;
        iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvinceCode].fuelVolumePumped += volumePumpedForRoute;
      } else {
        iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvinceCode] = {};
        iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvinceCode].vehicleKm = savedVehicleKm || 0;
        iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvinceCode].distanceKm = distanceKm || 0;
        iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvinceCode].fuelVolumePumped = volumePumpedForRoute;
      }
    }

    const odometerReadings = vehicleOdometerReadingsObj[vehicleMileagesObj.unitId];
    let odometerDiff;
    let shouldUseSpreadKm = false;
    if (
      odometerReadings &&
      odometerReadings.odometerEnd &&
      odometerReadings.odometerStart
    ) {
      odometerDiff = odometerReadings.odometerEnd - odometerReadings.odometerStart;
      if (odometerDiff > 0 && (odometerDiff < (moment(dateEnd).diff(moment(dateStart), 'hours') * 200))) {
        shouldUseSpreadKm = true;
      }
    }

    // Use spreadKm if applicable
    for (let j = 0; j < stateProvinceCodes.length; j++) {
      const stateProvince = stateProvinceCodes[j];
      if (iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvince]) {
        const stateProvinceSavedKm = iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvince].vehicleKm;
        const stateProvinceDistanceKm = iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvince].distanceKm;
        // DEBUGCSVSTRING += `${vehicleMileagesObj.unitId},${stateProvince},${stateProvinceDistanceKm}\n`
        const spreadKm = Math.round(totalSavedKm) !== 0 ? stateProvinceSavedKm && totalSavedKm && odometerDiff && (stateProvinceSavedKm / totalSavedKm) * odometerDiff : odometerDiff;
        if (stateProvinceSavedKm) {
          stateProvinceMileages[stateProvince].vehicleKm += stateProvinceSavedKm;
        }
        if (stateProvinceDistanceKm) {
          stateProvinceMileages[stateProvince].distanceKm += stateProvinceDistanceKm;
        }
        if (shouldUseSpreadKm && spreadKm) {
          stateProvinceMileages[stateProvince].spreadKm += spreadKm;
        } else if (!shouldUseSpreadKm && stateProvinceSavedKm) {
          stateProvinceMileages[stateProvince].spreadKm += stateProvinceSavedKm;
        }
        const { fuelVolumePumped } = iftaVehicleArrMiles[vehicleMileagesObj.unitId][stateProvince];
        stateProvinceMileages[stateProvince].fuelVolumePumped += fuelVolumePumped;
      }
    }
  }
  return stateProvinceMileages;
}

class TaxReportFleetTotalsSummaryTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      printInProgress: false,
      downloadCsvInProgress: false,
      distanceUnitFilter: getPDispatcherPropertyFromState('distanceUnit') === 'mi'
        ? {
          key: 0,
          value: 'mi',
          label: 'mi',
        }
        : {
          key: 1,
          value: 'km',
          label: 'km',
        },
      distanceUnitFilterItems: [
        {
          key: 0,
          value: 'km',
          label: 'km',
        },
        {
          key: 1,
          value: 'mi',
          label: 'mi',
        },
      ],
      vehicleOdometerReadingsObj: {},
      totalStateProvinceMileages: {},
      stateProvinceMileages: {},
      loading: false,
      selectedFuelType: 'Special Diesel', // Default to most common type
      selectedCurrency: 'CAD',
    };
    this.fuelTypes = Object.keys(props.taxRates.BC.ratesDictionary).map(fuelType => ({
      key: fuelType,
      value: fuelType,
      label: fuelType,
    }));

    this.currencyTypes = [
      {
        key: 'sbselect-currency-cad',
        value: 'CAD',
        label: 'CAD',
      },
      {
        key: 'sbselect-currency-usd',
        value: 'USD',
        label: 'USD',
      },
    ];

    this.printReport = this.printReport.bind(this);
  }

  async componentDidMount() {
    const { props, state } = this;
    const promises = [];
    this.setState({ loading: true });
    for (let i = 0; i < props.vehicleMileagesArr.length; i++) {
      const vehicleMileagesObj = props.vehicleMileagesArr[i];
      promises.push((new Promise((resolve) => {
        IFTA.getOdometerReadingsForDateRange(vehicleMileagesObj.unitId, props.dateStart, props.dateEnd, 'km').then(odometerReadings => {
          const vehicleOdometerReadingsObj = this.state.vehicleOdometerReadingsObj;
          vehicleOdometerReadingsObj[vehicleMileagesObj.unitId] = odometerReadings;
          this.setState({ vehicleOdometerReadingsObj }, () => {
            resolve();
          });
        });
      })));
    }

    Promise.all(promises).then(() => {
      const totalStateProvinceMileages = convertVehicleMileagesArrToSummary(props.vehicleMileagesArr, state.vehicleOdometerReadingsObj, state.selectedCurrency);

      this.setState({ totalStateProvinceMileages, loading: false });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { props, state } = this;
    if (prevState.selectedCurrency !== state.selectedCurrency) {
      const totalStateProvinceMileages = convertVehicleMileagesArrToSummary(props.vehicleMileagesArr, state.vehicleOdometerReadingsObj, state.selectedCurrency);
      this.setState({ totalStateProvinceMileages });
    }
  }

  printReport() {
    let reportHTML;
    let reportName;
    this.setState({ ...this.state, printInProgress: true });
    reportHTML = document.querySelector('.fleetSummary-printable').outerHTML;
    reportName = 'Total Mileage Summary';
    PDF.generateTempPDFFromHTML(document.querySelector('.fleetSummary-printable'), reportName, undefined).then(
      tempFile => {
        const reportURL = tempFile.get('file')._url;
        Helpers.openDocumentLink(reportURL);
        this.setState({ ...this.state, printInProgress: false });
      },
    );
  }

  downloadCsv() {
    this.setState({ ...this.state, downloadCsvInProgress: true });

    // let csvString = `Unit ID,State/Province,Total Calculated Mileage (${this.state.distanceUnitFilter.value}),Spread Mileage (${this.state.distanceUnitFilter.value})\n`;
    // const stateProvinceCodes = StateProvinces.map((stateProvince) => stateProvince.code).filter((stateProvinceCode) => stateProvinceCode);
    // for (let i = 0; i < this.props.vehicleMileagesArr.length; i++) {
    //   const vehicleMileagesObj = this.props.vehicleMileagesArr[i];
    //   let totalSavedKm = 0;
    //   const odometerReadings = this.state.vehicleOdometerReadingsObj[vehicleMileagesObj.unitId];
    //   let odometerDiff;
    //   if (odometerReadings && odometerReadings.odometerEnd && odometerReadings.odometerStart) {
    //     odometerDiff = odometerReadings.odometerEnd - odometerReadings.odometerStart;
    //   }

    //   let shouldUseSpreadKm = false;
    //   if (
    //     odometerDiff > 0 &&
    //     (odometerDiff < (moment(this.props.dateEnd).diff(moment(this.props.dateStart), 'hours') * 200))
    //   ) {
    //     shouldUseSpreadKm = true;
    //   }

    //   const iftaRouteArr = vehicleMileagesObj.iftaRouteArr;
    //   const iftaArrMiles = {};
    //   for (let j = 0; j < iftaRouteArr.length; j++) {
    //     const iftaRoute = iftaRouteArr[j];
    //     const stateProvince = iftaRoute.get('stateProvince').toUpperCase();
    //     const savedVehicleKm = iftaRoute.get('savedVehicleKm');
    //     totalSavedKm += savedVehicleKm;
    //     if (iftaArrMiles[stateProvince]) {
    //       iftaArrMiles[stateProvince] += savedVehicleKm;
    //     } else {
    //       iftaArrMiles[stateProvince] = savedVehicleKm || 0;
    //     }
    //   }
    //   for (let j = 0; j < stateProvinceCodes.length; j++) {
    //     const stateProvince = stateProvinceCodes[j];
    //     if (iftaArrMiles[stateProvince]) {
    //       const stateProvinceSavedKm = (Math.round(iftaArrMiles[stateProvince] * 100) / 100).toFixed(0);
    //       const spreadKm = (stateProvinceSavedKm && totalSavedKm && odometerDiff) && (Math.round((stateProvinceSavedKm / totalSavedKm) * odometerDiff * 100) / 100).toFixed(0);
    //       csvString += `"${this.props.vehicleMileagesArr[i].unitId}",`;
    //       csvString += `"${stateProvince}",`;
    //       csvString += `"${(this.state.distanceUnitFilter.value === 'km' ? stateProvinceSavedKm : Helpers.convertDistance(stateProvinceSavedKm, 'km', 'mi', true).toFixed(0))}",`;
    //       csvString += `"${
    //         shouldUseSpreadKm ?
    //           (this.state.distanceUnitFilter.value === 'km' ? spreadKm : Helpers.convertDistance(spreadKm, 'km', 'mi', true).toFixed(0)) :
    //           (this.state.distanceUnitFilter.value === 'km' ? stateProvinceSavedKm : Helpers.convertDistance(stateProvinceSavedKm, 'km', 'mi', true).toFixed(0))
    //       }"\n`;
    //     }
    //   }
    // }
    let csvString = `State/Province,Calculated Mileage (${this.state.distanceUnitFilter.value})\n`;
    const totalStateProvinceMileagesObj = this.state.totalStateProvinceMileages;
    const totalStateProvinceMilesagesArr = Object.keys(totalStateProvinceMileagesObj).map((key) => ({
      stateProvince: key,
      distanceKm: totalStateProvinceMileagesObj[key].distanceKm,
      vehicleKm: totalStateProvinceMileagesObj[key].vehicleKm,
      spreadKm: totalStateProvinceMileagesObj[key].spreadKm
    }));
    let totalDistanceKm = 0;
    let totalSpreadKm = 0;
    for (let i = 0; i < totalStateProvinceMilesagesArr.length; i++) {

      if (totalStateProvinceMilesagesArr[i].spreadKm === 0 || totalStateProvinceMilesagesArr[i].distanceKm === 0) continue;

      csvString += `"${totalStateProvinceMilesagesArr[i].stateProvince}",`;
      // getting rid of gps mileage
      // csvString += `"${(this.state.distanceUnitFilter.value === 'km' ? totalStateProvinceMilesagesArr[i].distanceKm : Helpers.convertDistance(totalStateProvinceMilesagesArr[i].distanceKm, 'km', 'mi', true).toFixed(0))}",`;
      csvString += `"${(this.state.distanceUnitFilter.value === 'km' ? totalStateProvinceMilesagesArr[i].spreadKm : Helpers.convertDistance(totalStateProvinceMilesagesArr[i].spreadKm, 'km', 'mi', true).toFixed(0))}"\n`;
      totalDistanceKm += totalStateProvinceMilesagesArr[i].distanceKm;
      totalSpreadKm += totalStateProvinceMilesagesArr[i].spreadKm;
    }
    csvString += `TOTAL,`;
    // getting rid of gps mileage
    // csvString += `"${(this.state.distanceUnitFilter.value === 'km' ? totalDistanceKm : Helpers.convertDistance(totalDistanceKm, 'km', 'mi', true).toFixed(0))}",`;
    csvString += `"${(this.state.distanceUnitFilter.value === 'km' ? totalSpreadKm : Helpers.convertDistance(totalSpreadKm, 'km', 'mi', true).toFixed(0))}"\n`;
    Helpers.createCsvFile(`Switchboard - IFTA Mileage Summary`, csvString, true);
    this.setState({ ...this.state, downloadCsvInProgress: false });
  }

  render() {
    const { state, props } = this;
    return (
      <div>
        <div style={{ margin: '1em' }}>
          <SBSelect
            containerClassName="ml-2 mr-2 d-inline-block"
            className="sb-currency-list-select"
            defaultToggleText={state.selectedCurrency}
            items={this.currencyTypes}
            getSelectedItems={(selectedCurrency) => this.setState({ selectedCurrency: selectedCurrency[0].value })}
          />
          <SBSelect
            containerClassName="ml-2 mr-2 d-inline-block"
            className="sb-jobs-list-select"
            defaultToggleText="Select fuel type"
            items={this.fuelTypes}
            getSelectedItems={(fuelType) => this.setState({ selectedFuelType: fuelType[0].value })}
            showFilter
          />
          <MDBBtn
            size="sm"
            color="secondary"
            disabled={this.state.printInProgress}
            onClick={() => this.printReport()}
            style={{ marginRight: '.5em' }}
          >
            Print Report
          </MDBBtn>
          {/* <MDBBtn
            size="sm"
            color="secondary"
            disabled={this.state.downloadCsvInProgress}
            onClick={() => this.downloadCsv()}
            style={{ marginRight: '.5em', width: '14em' }}
          >
            Download CSV
          </MDBBtn> */}
        </div>
        <div className="fleetSummary-printable">
          {(moment(this.props.dateEnd).isAfter(moment().subtract(11, 'days'))) && (
            <SBBlock
              title={`Switchboard processes IFTA vehicle mileages up to 10 days before today's date.`}
              header={`If you are generating reports for the previous month, make sure you generate them on the 11th of this month to get the most updated mileages.`}
              warning
            />
          )}

          <TaxReportTotalSummary
            dateStart={this.props.dateStart}
            dateEnd={this.props.dateEnd}
            displayStartDate={this.props.displayStartDate}
            displayEndDate={this.props.displayEndDate}
            totalStateProvinceMileages={this.state.totalStateProvinceMileages}
            selectedFilter={{
              key: 1,
              value: 'hideZeroMileages',
              label: 'Hide Empty States/Provinces',
            }}
            distanceUnitFilter={this.state.distanceUnitFilter}
            isLoading={this.state.loading}
            taxRates={props.taxRates}
            fuelType={state.selectedFuelType}
            IFTACurrency={state.selectedCurrency}
          />

        </div>
      </div>
    );
  }
}

TaxReportFleetTotalsSummaryTable.propTypes = {
  taxRates: PropTypes.objectOf(PropTypes.object).isRequired,
  dateStart: PropTypes.instanceOf(Date),
  dateEnd: PropTypes.instanceOf(Date),
  vehicleMileagesArr: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object,
};

export default TaxReportFleetTotalsSummaryTable;
