import React, { useState, useEffect } from 'react';

// components
import Card from 'sbCore/Card/Card';

// styles
import './style.scss';

/**
 * @description A general card item component
 * @param {Any} [label] - A small header that appears above <leading>
 * @param {Any} leading - Consider this the main content of the card
 * @param {Any} [subheading] - A small subtitle that appears below <leading>
 * @param {Any} [trailing] - Content that is right-justified from the <leading>
 * @param {String} [sbVariant] - Supported: ["flat" for no shadows]
 * @param {Object} [style] - Custom in-line styles
 *
 * @todo Add isEditiable props for each editable aspect of the CardItem
 *
 * @example
 *  <CardItem
 *    label="Origin"
 *    leading="Vancouver, BC"
 *    subheading="(123.456, -789.123)"
 *    trailing="10:45"
 *  />
 */
function CardItem({ ...props }) {

  // useEffect(() => {

  // }, [props.tripData]);

  /**
   * JSX
   */

  let className = 'card-item';
  if (props.className) className += ` ${props.className}`;

  if (props.sbVariant === 'flat') {
    className += ' flat';
  }

  return (
    <div className={className} style={props.style}>
      <Card
        title=""
        header=""
        subTitle=""
      >
        <div className="container">
          <div className="left-half">
            <div className="label">{ props.label || <span>&nbsp;</span> }</div>
            <div className="leading">{ props.leading || <span>&nbsp;</span> }</div>
            <div className="subheading">{ props.subheading || <span>&nbsp;</span> }</div>
          </div>
          {props.trailing && (
            <div className="right-half">
              <div className="label">&nbsp;</div>
              <div className="leading">{ props.trailing || <span>&nbsp;</span> }</div>
              <div className="subheading">&nbsp;</div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}

export default CardItem;
