import store from 'store';
import * as Destroyers from 'api/Destroyers';
import * as Getters from 'api/Getters';
import * as Setters from 'api/Setters';
import * as Helpers from 'api/Helpers';
import * as Parse from 'api/Parse';
import * as ActionConstants from './ActionConstants';

function fetchingDocuments() {
  return {
    type: ActionConstants.FETCH_DOCUMENTS_INPROGRESS,
  };
}

function fetchDocumentsSuccess(documents) {
  return {
    type: ActionConstants.FETCH_DOCUMENTS_SUCCESS,
    documents,
  };
}

function fetchDocumentsError(error) {
  return {
    type: ActionConstants.FETCH_DOCUMENTS_ERROR,
    error,
  };
}

function deleteDocuments() {
  return {
    type: ActionConstants.DELETE_DOCUMENTS,
  };
}

export function fetchDocumentsForState(limitedQueryToKeyValueObjectArray) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingDocuments());
    Getters.getDocuments(limitedQueryToKeyValueObjectArray).then(
      documents => {
        resolve(documents);
        store.dispatch(fetchDocumentsSuccess(documents));
      },
      error => {
        reject(error);
        store.dispatch(fetchDocumentsError(error));
      }
    );
  });
  return promise;
}

export function deleteDocumentsForState() {
  store.dispatch(deleteDocuments());
}

function sendDocumentPush(jobActions, documentCategoryString, documentParseObj) {
  const jobActionsLen = jobActions.length;
  const nestedDriverUserArray = [];
  for (let i = 0; i < jobActionsLen; i++) {
    if (jobActions[i].toJSON().jobDriver) {
      nestedDriverUserArray.push(Helpers.getArrayOfInternalParseObjects(jobActions[i].get('jobDriver').get('driver'), 'user'));
    }
  }
  const jobIds = Helpers.concatenateStringsInParseObjectsArray(jobActions, 'jobId');
  Parse.sendPush([].concat(...nestedDriverUserArray), `New "${documentCategoryString}" for Job Id: ${jobIds}`);
  Setters.addDriverTask('New Document', jobActions[0].get('jobId'), Parse.getCurrentUser(), documentCategoryString, [].concat(...nestedDriverUserArray), 'Document', documentParseObj.id);
}

export function addDocumentToState(documentFile, jobLink, documentCategoryParseObject) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch({ type: ActionConstants.JOBLINK_DOCUMENTUPLOAD_INPROGRESS, jobLink });
    store.dispatch({ type: ActionConstants.ADD_DOCUMENTS_INPROGRESS });
    Setters.addDocument(documentFile, jobLink, documentCategoryParseObject).then(
      documentParseObj => {
        if (!jobLink.get('jobActions').completed) {
          sendDocumentPush(jobLink.get('jobActions'), documentCategoryParseObject.get('type'), documentParseObj);
        }
        store.dispatch({ type: ActionConstants.JOBLINK_DOCUMENTUPLOAD_SUCCESS, jobLink });
        store.dispatch({ type: ActionConstants.ADD_DOCUMENTS_SUCCESS, documents: [documentParseObj] });
        resolve(documentParseObj);
      },
      error => {
        store.dispatch({ type: ActionConstants.JOBLINK_DOCUMENTUPLOAD_ERROR, jobLink });
        store.dispatch({ type: ActionConstants.ADD_DOCUMENTS_ERROR, error });
        reject(error);
      }
    );
  });
  return promise;
}

function destroyDocuments(documents) {
  return {
    type: ActionConstants.DESTROY_DOCUMENTS_SUCCESS,
    documents,
  };
}

export function deleteDocument(document) {
  store.dispatch(destroyDocuments([document]));
  const promise = new Promise((resolve, reject) => {
    Destroyers.destroyDriverTasksAndNotification(document);
    // Destroy Driver Tasks on DB
    Parse.destroyRecords([document]).then((documentArr) => {
      resolve();
    });
  });
  return promise;
}

function promptDocumentsReupload(documents) {
  return {
    type: ActionConstants.DOCUMENT_REUPLOAD_PROMPT,
    documents,
  };
}

export function promptReupload(parseDocumentObj) {
  const promise = new Promise((resolve, reject) => {
    const jobIds = Helpers.concatenateStringsInParseObjectsArray(parseDocumentObj.get('jobAction'), 'jobId');
    Parse.sendPush([parseDocumentObj.get('uploadedBy').id], `Retake pictures of "${parseDocumentObj.get('documentCategory').get('type')}" for Job Id: ${jobIds}`);
    Setters.addDriverTask('Retake', parseDocumentObj.get('jobAction')[0].get('jobId'), Parse.getCurrentUser(), parseDocumentObj.get('documentCategory').get('type'), [parseDocumentObj.get('uploadedBy')], 'Document', parseDocumentObj.id).then((driverTaskParseObject) => {
      parseDocumentObj.set('promptedReupload', driverTaskParseObject);
      parseDocumentObj.save();
      resolve(parseDocumentObj);
      store.dispatch(promptDocumentsReupload([parseDocumentObj]));
    });
  });
  return promise;
}

function updatingDocument(document) {
  return {
    type: ActionConstants.UPDATE_DOCUMENT_INPROGRESS,
    updateReference: document,
  };
}

function updateDocumentSuccess() {
  return {
    type: ActionConstants.UPDATE_DOCUMENT_SUCCESS,
  };
}

function updateDocumentError(error) {
  return {
    type: ActionConstants.UPDATE_DOCUMENT_ERROR,
    error,
  };
}

export function updateDocumentForState(document, property, value) {
  // take a jobAction parse object and update it
  // since it's a parse object, we do an in-state mutation rather than extra immutable overhead
  store.dispatch(updatingDocument(document));
  document.set(property, value);
  document.save().then(
    documentObject => {
      store.dispatch(updateDocumentSuccess());
    },
    error => {
      store.dispatch(updateDocumentError(error));
    }
  );
}
