import React from 'react';
import PropTypes from 'prop-types';

// API
import { getAttribute } from 'api/Parse';
import { getSuggestedUsers } from 'api/User/User';

// Components
import SBSelect from 'components/Shared/SBSelect/SBSelect';

// CSS
import './styles.scss';

class UserAutocomplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      asyncUsers: [],
    };
    this.loadUsers = this.loadUsers.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleUserSelection = this.handleUserSelection.bind(this);
  }

  async loadUsers(term) {
    if (term && term.length > 0) {
      const users = await getSuggestedUsers(term);

      const tempUsers = users.map((user, index) => {
        return {
          key: index,
          value: user,
          label: getAttribute(user, 'firstName'),
        };
      });

      if (this.props.allowDeselect) {
        tempUsers.unshift({
          key: 'none',
          value: undefined,
          label: 'Clear',
          isSpecialItem: true,
          removeableItem: true,
        });
      }

      this.setState({ asyncUsers: tempUsers });
    }
  }

  handleUserSelection(selection) {
    const { props } = this;

    props.onUserSelected(selection[0].value);
  }

  handleFilterChange(text) {
    const { props } = this;

    if (props.onChange)
      props.onChange(text);
  }

  render() {
    const { state } = this;

    return (
      <SBSelect
        containerClassName="flex-grow-1"
        onFilterChange={this.handleFilterChange}
        items={state.asyncUsers}
        getSelectedItems={this.handleUserSelection}
        isAutocomplete
        isRequired
        autocompleteLabel="Search User by Name"
        onValueChange={this.loadUsers}
      />
    );
  }
}

UserAutocomplete.propTypes = {
  onUserSelected: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  allowDeselect: PropTypes.bool,
};

export default UserAutocomplete;
