import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';

// Actions

// API
import * as Getters from 'api/Getters';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
// import styles from './SimRow.module.scss';

// Lists

class SimRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usage: null,
      period: {
        start: null,
        end: null,
      },
      loading: true,
    };
  }

  componentDidMount() {
    // Getters.getUsageForSim(this.props.sim, this.props.subscriptionObjectId).then((response) => {
    //   this.setState({
    //     ...this.state,
    //     usage: response.usageBytes,
    //     period: {
    //       start: moment(response.startMillis).format('LL'),
    //       end: moment(response.endMillis).format('LL'),
    //     },
    //     loading: false,
    //   });
    // }, (error) => {
    //   console.log(error);
    //   this.setState({
    //     ...this.state,
    //     loading: false,
    //   });
    // });

    console.log(this.props.sim.get('iccid'));
    Getters.getSimInfoByIccid(this.props.sim.get('iccid')).then((response) => {
      this.setState({
        ...this.state,
        status: response && response.status,
        loading: false,
      });
    }, (error) => {
      console.log(error);
      this.setState({
        ...this.state,
        loading: false,
      });
    });
  }

  render() {
    return (
      <TableRow>
        <TableRowColumn><var>{this.props.sim.get('imei') || this.props.sim.get('trackerEsn')}</var></TableRowColumn>
        <TableRowColumn><var>{this.props.sim.get('iccid')}</var></TableRowColumn>
        {/* <TableRowColumn>
          { !this.state.loading && this.state.usage !== null && this.state.period && this.state.period.start !== null &&
            <div>
              <b><var>{Math.round(this.state.usage / 1000000)}</var> MB</b>, <var>{this.state.period.start} - {this.state.period.end}</var>
            </div>
          }
          { !this.state.loading && this.state.usage === null && this.state.period && this.state.period.start === null &&
            <div>
              <b>No Usage</b>
            </div>
          }
          { this.state.loading &&
            <LoadingIcon />
          }
        </TableRowColumn> */}
        <TableRowColumn>
          {this.state.status && <kbd>{this.state.status}</kbd>}
          {!this.state.loading && !this.state.status && this.props.sim.get('isActive') && <kbd>Active</kbd>}
          {!this.state.loading && !this.state.status && !this.props.sim.get('isActive') && <kbd>Inactive</kbd>}
        </TableRowColumn>
      </TableRow>
    );
  }
}

SimRow.propTypes = {
  sim: PropTypes.object.isRequired,
  subscriptionObjectId: PropTypes.string.isRequired,
};

export default SimRow;
