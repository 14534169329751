import { useEffect, useRef } from 'react';

/**
 * @description A hook that keeps the previous value for a given prop
 * @param {object} value An object representing the values that we wish to keep the previous values of
 * @returns {object} Returns the previous values for the given input parameters
 *
 * @example
 *  const { receiveAmount, sendAmount } = props;
 *  const prevAmount = usePrevious({receiveAmount, sendAmount});
 *  const hasValueChanged = prevAmount.receiveAmount !== props.receiveAmount;
 */
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export {
  usePrevious,
}
