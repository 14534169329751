export default class ELDDailyCertification {
  /**
   * 
   * @param {string} objectId 
   * @param {Date} startTimeUTC 
   * @param {Date} endTimeUTC 
   * @param {Driver} driver 
   */
  constructor(objectId, startTimeUTC, endTimeUTC, driver) {
    this.objectId = objectId;
    this.startTimeUTC = startTimeUTC;
    this.endTimeUTC = endTimeUTC;
    this.driver = driver;
  }
}