import React, { useState, useEffect } from 'react';

// Enums
import { LengthUnit } from 'sb-csapi/dist/enums/Unit';
import { Country } from 'sb-csapi/dist/enums/Country';

// SBCore
import Button from 'sbCore/Button/Button';
import Dialog from 'sbCore/Dialog/Dialog';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import InputNumber from 'sbCore/InputNumber/InputNumber';
import DistanceUnitDropdown from 'sbCore/DistanceUnitDropdown/DistanceUnitDropdown';
import StateProvinceDropdown from 'sbCore/StateProvinceDropdown/StateProvinceDropdown';
import LocationDirectionDropdown from '../LocationDirectionDropdown/LocationDirectionDropdown';

// Components
import LocationAutocomplete from 'components/Shared/LocationAutocomplete/LocationAutocomplete';

// API
import { getVehicleLocationDescription, getLocationDescriptionBreakdown } from 'api/VehicleLocation/VehicleLocation';

// Styles
import './style.scss';

/**
 * @description Handle custom location description
 * 
 * @param {String} [className] - Custom container className
 * @param {String} locationDescriptionString - Location description string
 * @param {Function} onSaveLocationDescription - Callback for saving location description
 * @param {Function} onCancelLocationDescription - Callback for cancelling changes to location description
 * @param {Boolean} visible - If the form is visible
 * 
 * @returns {JSX} Returns the location description dialog
 */

function LocationDescriptionDialog(props) {
  const [city, setCity] = useState('');
  const [stateProvince, setStateProvince] = useState('');
  const [distance, setDistance] = useState(0);
  const [distanceUnit, setDistanceUnit] = useState(LengthUnit.KM);
  const [heading, setHeading] = useState('');

  const [locationDescriptionString, setLocationDescriptionString] = useState('');

  // Initialize state based on locationDescriptionString
  useEffect(() => {
    if (props.locationDescriptionString) {
      setLocationDescriptionString(props.locationDescriptionString);

      const locationDescriptionBreakdown = getLocationDescriptionBreakdown(props.locationDescriptionString);

      setCity(locationDescriptionBreakdown.city);
      setStateProvince(locationDescriptionBreakdown.stateProvince.code);
      setDistance(locationDescriptionBreakdown.distance);
      setDistanceUnit(locationDescriptionBreakdown.distanceUnit?.toUpperCase());
      setHeading(locationDescriptionBreakdown.heading.value);
    }
  }, [props.locationDescriptionString]);

  // Update the location description string when any of the parameters change
  useEffect(() => {
    const newLocationDescriptionString = getVehicleLocationDescription(city, stateProvince, distance, distanceUnit, heading);

    if (newLocationDescriptionString !== locationDescriptionString) {
      setLocationDescriptionString(newLocationDescriptionString);
    }
  }, [city, stateProvince, distance, distanceUnit, heading]);

  // When inputting a city, update the other parameters so that they match
  function handleSearchAddress(addressSbObj) {
    setCity(addressSbObj.city);
    setStateProvince(addressSbObj.stateProvince);

    if (addressSbObj.country === Country.US) {
      setDistanceUnit(LengthUnit.MI);
    } else {
      setDistanceUnit(LengthUnit.KM);
    }
  }

  const distanceInput = (
    <>
      <InputLabel>Distance</InputLabel>
      <InputNumber
        className={`p-inputtext-sm w-full`}
        value={distance}
        onChange={(e) => setDistance(e.value)}
      />
    </>
  );

  const distanceUnitDropdown = (
    <>
      <InputLabel>Distance Unit</InputLabel>
      <DistanceUnitDropdown
        dropdownClassName="p-inputtext-sm w-full"
        unit={distanceUnit}
        onSelect={(distanceUnit) => setDistanceUnit(distanceUnit.short)}
        showOnFocus
        hideLabel
      />
    </>
  );

  const locationDirectionDropdown = (
    <>
      <InputLabel>Heading</InputLabel>
      <LocationDirectionDropdown
        dropdownClassName="p-inputtext-sm w-full"
        locationDirection={heading}
        onSelect={(locationDirection) => setHeading(locationDirection)}
        showOnFocus
        hideLabel
      />
    </>
  );

  const stateProvinceDropdown = (
    <>
      <InputLabel>State / Province</InputLabel>
      <StateProvinceDropdown
        dropdownClassName="p-inputtext-sm w-full"
        stateProvince={stateProvince}
        onSelect={(stateProvince) => {
          setStateProvince(stateProvince.code)
          
          if (stateProvince.country === Country.US) {
            setDistanceUnit(LengthUnit.MI);
          } else {
            setDistanceUnit(LengthUnit.KM);
          }
        }}
        showOnFocus
        hideLabel
      />
    </>
  );

  const cityInput = (
    <>
      <InputLabel>City</InputLabel>
      <LocationAutocomplete
        usePRStyle
        placeholder={city}
        types={['(cities)']}
        containerClass="search-address-autocomplete"
        inputClassName="p-inputtext-sm w-full"
        onLocationSelected={(addressSbObj) => handleSearchAddress(addressSbObj)}
      />
    </>
  );

  const footer = (
    <div className="flex justify-content-end">
      <Button
        className="p-button-sm p-button-secondary p-button-text uppercase"
        label="cancel"
        onClick={() => props.onCancelLocationDescription()}
      />
      <Button
        className="p-button-sm p-button-primary uppercase"
        label="update"
        onClick={() => props.onSaveLocationDescription(locationDescriptionString, getLocationDescriptionBreakdown(locationDescriptionString))}
      />
    </div>
  );

  // ** Misc ** //
  const name = 'Edit Location Description';

  let className = 'sbc-location-description-dialog';
  if (props.className) className += ` ${props.className}`;

  return (
    <Dialog
      className={className}
      visible={props.visible}
      header={name}
      footer={footer}
      onHide={() => props.onCancelLocationDescription()}
      closable={false}
      style={{ width: '60vw' }}
    >
      <div className="flex flex-column lg:flex-row lg:mb-3">
        <div className="lg:pr-3 mb-2 lg:mb-0 w-full">
          {locationDescriptionString}
        </div>
      </div>
      <div className="flex flex-column lg:flex-row lg:mb-3">
        <div className="lg:pr-3 mb-2 lg:mb-0 lg:w-20rem">
          {distanceInput}
        </div>
        <div className="lg:pr-3 mb-2 lg:mb-0 lg:w-20rem">
          {distanceUnitDropdown}
        </div>
        <div className="lg:pr-3 mb-2 lg:mb-0 lg:w-30rem">
          {locationDirectionDropdown}
        </div>
        <div className="lg:pr-3 mb-2 lg:mb-0 lg:w-20rem">
          {stateProvinceDropdown}
        </div>
        <div className="lg:pr-3 mb-2 lg:mb-0 w-full">
          {cityInput}
        </div>
      </div>
    </Dialog>
  );
}

export default LocationDescriptionDialog;
