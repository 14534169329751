/* eslint-disable max-len */
import {
  createQuery, createQueryOr, createTempPointer,
  getAttribute, getCurrentUser, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, getRecordByObjectId,
  includePointers, sortQuery,
  setQueryRestriction, setReturnSelectAttributes,
  addRecord, findRecords, updateRecord, destroyRecord,
} from 'sb-csapi/dist/AAPI';

import { QuerySortOrder, QueryRestriction } from 'sb-csapi/dist/enums/Query';

// Sort
import Sort from 'sb-csapi/dist/sbObjects/Sort';

// Filter
import Filter from 'sb-csapi/dist/sbObjects/Filter';

const EQUIPMENT_GROUP_TABLE = 'EquipmentGroup';
const EQUIPMENT_GROUP_USERS_TABLE = 'EquipmentGroupUsers';
const EQUIPMENT_GROUP_EQUIPMENT_TABLE = 'EquipmentGroupEquipment';

/** @module EquipmentGroup */

/** --------------------------- Equipment Groups --------------------------- */

/**
 * @memberof module:EquipmentGroup
 * @description Obtains equipment groups from a company
 *
 * @param {Object} [options] - See example
 * @param {String} companyObjectId - Company we wish to retrieve equipment groups for
 * @param {Boolean} [includeChildCompanies] - Include child/sub-companies of this parent company
 * @param {Array<Filter>} [filters] - Array of Filter objects
 * @param {Sort} [sortBy] - Sort object
 * @param {Array<String>} [includedPointers] - Included pointers
 * @param {Array<String>} [selectedAttributes] - Select attributes to return
 * @param {int} [page] - The current page for pagination
 * @param {int} [limit] - The limit of records obtained per pagination
 * @param {Boolean} [queryAll] - Get all records
 *
 * @returns {Object} - returns an object containing the equipment groups { equipmentGroups: [] }
 */
async function getEquipmentGroups(options = { sessionToken: getCurrentUserSessionToken() }, companyObjectId = getCurrentUserCompanyObjectId(), includeChildCompanies, filters = [], sortBy = new Sort(QuerySortOrder.ASCENDING, 'name'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  let equipmentGroupQuery = createQuery(EQUIPMENT_GROUP_TABLE);

  const _filters = [...filters];

  const companyQuery = createQuery('Company');
  setQueryRestriction(companyQuery, QueryRestriction.EQUAL_TO, 'objectId', companyObjectId);

  if (includeChildCompanies) {
    // if getting child companies' equipment groups, the additional queries
    const companyLinkQuery = createQuery('CompanyLink');

    const companyLinkFilters = [
      new Filter(QueryRestriction.MATCHES_QUERY, 'parentCompany', companyQuery),
      new Filter(QueryRestriction.EQUAL_TO, 'authorized', true),
    ];

    companyLinkFilters.map(filter => setQueryRestriction(companyLinkQuery, filter.queryRestriction, filter.attribute, filter.value));

    // now that we have all the child companies, create queries that get the equipment groups of them
    const companyLinks = await findRecords(options, companyLinkQuery);
    const childCompanyEquipmentGroupQueries = companyLinks.map(company => {
      const childCompanyEquipmentGroupQuery = createQuery(EQUIPMENT_GROUP_TABLE);
      setQueryRestriction(childCompanyEquipmentGroupQuery, QueryRestriction.EQUAL_TO, 'belongsToCompany', getAttribute(company, 'childCompany'));
      return childCompanyEquipmentGroupQuery;
    });

    // the main current user's company's equipment group query
    const mainCompanyEquipmentGroupQuery = createQuery(EQUIPMENT_GROUP_TABLE);
    setQueryRestriction(mainCompanyEquipmentGroupQuery, QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery);

    // altogether
    equipmentGroupQuery = createQueryOr([mainCompanyEquipmentGroupQuery, ...childCompanyEquipmentGroupQueries]);

  } else {
    // lock queries to the current users company
    setQueryRestriction(equipmentGroupQuery, QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery);
  }

  // set universal filters
  // _filters.push(new Filter(QueryRestriction.NOT_EQUAL_TO, 'isHidden', true));

  _filters.map(filter => setQueryRestriction(equipmentGroupQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  // const equipmentGroupCountQuery = copyQuery(equipmentGroupQuery);

  // sort
  sortQuery(equipmentGroupQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(equipmentGroupQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(equipmentGroupQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(equipmentGroupQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(equipmentGroupQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the equipment groups - ignore count query now until its needed
  const promises = [findRecords(options, equipmentGroupQuery, false, queryAll)];

  try {
    const [equipmentGroups] = await Promise.all(promises);
    return { equipmentGroups };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:EquipmentGroup
 * @description Create EquipmentGroup record
 *
 * @param {Object} [options] - See example
 * @param {String} name - Name of equipment group
 *
 * @returns {EquipmentGroup} returns the created EquipmentGroup record
 */
async function createEquipmentGroup(options = { sessionToken: getCurrentUserSessionToken() }, name) {
  if (!name) throw new Error('Equipment name is required');

  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

  const equipmentGroup = await addRecord(
    options, // options
    EQUIPMENT_GROUP_TABLE, // tableName
    {
      name,
      belongsToCompany,
      createdBy: currentUser,
      numberOfVehicles: 0,
      numberOfTrailers: 0,
    }, // keyValueObject
    getAttribute(belongsToCompany, 'objectId'), // companyObjectId
  );

  return equipmentGroup;
}

/**
 * @memberof module:EquipmentGroup
 * @description Updates an EquipmentGroup given a keyValue object
 *
 * @param {Object} [options]  - See example
 * @param {String} equipmentGroupObjectId - Equipment group we wish to update
 * @param {Object} keyValueObj - Map of attributes and their values to update
 *
 * @returns {EquipmentGroup} - returns the updated EquipmentGroup
 */
async function updateEquipmentGroup(options = { sessionToken: getCurrentUserSessionToken() }, equipmentGroupObjectId, keyValueObj) {
  if (!equipmentGroupObjectId) throw new Error('Equipment group is required');
  if (!keyValueObj) throw new Error('Key-value object is required');

  let equipmentGroup = await getRecordByObjectId(
    options, // options
    EQUIPMENT_GROUP_TABLE, // tableName
    equipmentGroupObjectId, // objectId
    undefined, // includedPointers
    undefined, // selectedAttributes
  );

  if (keyValueObj && keyValueObj.name !== undefined) {
    keyValueObj.name = keyValueObj.name.trim();
  }

  equipmentGroup = await updateRecord(options, equipmentGroup, keyValueObj, true);
  return equipmentGroup;
}

/**
 * @memberof module:EquipmentGroup
 * @description Deletes an EquipmentGroup record and their associated EquipmentGroupEquipment records
 *
 * @param {Object} [options] - See example
 * @param {String} equipmentGroupObjectId - Equipment group we wish to delete
 *
 * @returns {Object} - returns an object containing the deleted EquipmentGroup and its associated EquipmentGroupEquipment records { deletedEquipmentGroup: [], deletedEquipmentGroupEquipments: [] }
 */
async function deleteEquipmentGroup(options = { sessionToken: getCurrentUserSessionToken() }, equipmentGroupObjectId) {
  if (!equipmentGroupObjectId) throw new Error('Equipment group is required');

  // Grab the EquipmentGroup to update number of equipment
  const equipmentGroup = await getRecordByObjectId(
    options, // options
    EQUIPMENT_GROUP_TABLE, // tableName
    equipmentGroupObjectId, // objectId
    undefined, // includedPointers
    undefined, // selectedAttributes
  );

  // Get all the equipment group equipment records associated with this equipment group
  // @TODO: Add in ability to delete all users associated with EquipmentGroup
  const { equipmentGroupEquipments } = await getEquipmentGroupEquipments(
    undefined, // options
    equipmentGroupObjectId, // equipmentGroupObjectId
    undefined, // filters
    undefined, // sortBy
    undefined, // includedPointers
    undefined, // selectedAttributes
    undefined, // page
    undefined, // limit
    true, // queryAll
  );

  // Delete the EquipmentGroupEquipment records first
  const deletedEquipmentGroupEquipments = await Promise.all(equipmentGroupEquipments.map((equipmentGroupEquipment) => {
    destroyRecord(options, equipmentGroupEquipment);
  }));

  const deletedEquipmentGroup = await destroyRecord(options, equipmentGroup);

  return {
    deletedEquipmentGroup,
    deletedEquipmentGroupEquipments,
  };
}

/** --------------------------- Equipment Group Equipments --------------------------- */

/**
 * @memberof module:EquipmentGroup
 * @description Obtains equipment group equipment from a equipment group
 *
 * @param {Object} [options] - See example
 * @param {String} equipmentGroupObjectId - Equipment group we wish to retrieve equipment for
 * @param {Array<Filter>} [filters] - Array of Filter objects
 * @param {Sort} [sortBy] - Sort object
 * @param {Array<String>} [includedPointers] - Included pointers
 * @param {Array<String>} [selectedAttributes] - Select attributes to return
 * @param {int} [page] - The current page for pagination
 * @param {int} [limit] - The limit of records obtained per pagination
 * @param {Boolean} [queryAll] - Get all records
 *
 * @returns {Object} - returns an object containing the EquipmentGroupEquipments { equipmentGroupEquipments: [] }
 */
async function getEquipmentGroupEquipments(options = { sessionToken: getCurrentUserSessionToken() }, equipmentGroupObjectId, filters = [], sortBy = new Sort(QuerySortOrder.ASCENDING, 'updatedAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  const equipmentGroupEquipmentQuery = createQuery(EQUIPMENT_GROUP_EQUIPMENT_TABLE);

  // Set filter for equipment group
  setQueryRestriction(equipmentGroupEquipmentQuery, QueryRestriction.EQUAL_TO, 'equipmentGroup', equipmentGroupObjectId);

  const _filters = [...filters];

  // set universal filters
  // _filters.push(new Filter(QueryRestriction.NOT_EQUAL_TO, 'isHidden', true));

  _filters.map(filter => setQueryRestriction(equipmentGroupEquipmentQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  // const equipmentGroupEquipmentCountQuery = copyQuery(equipmentGroupEquipmentQuery);

  // sort
  sortQuery(equipmentGroupEquipmentQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(equipmentGroupEquipmentQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(equipmentGroupEquipmentQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(equipmentGroupEquipmentQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(equipmentGroupEquipmentQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get equipment group equipments - ignoring count query for now until it is needed
  const promises = [findRecords(options, equipmentGroupEquipmentQuery, false, queryAll)];

  try {
    const [equipmentGroupEquipments] = await Promise.all(promises);
    return { equipmentGroupEquipments };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:EquipmentGroup
 * @description Given an equipment group, and an equipment, add that equipment to the equipment group. Limited to only handle adding one equipment at a time.
 *
 * @param {Object} [options] - See example
 * @param {String} equipmentGroupObjectId - Equipment group we wish to add equipment to
 * @param {String} [vehicleObjectId] - Vehicle we wish to add to the equipment group
 * @param {String} [trailerObjectId] - Trailer we wish to add to the equipment group
 *
 * @returns {EquipmentGroupEquipment} - returns the newly created EquipmentGroupEquipment record
 */
async function createEquipmentGroupEquipment(options = { sessionToken: getCurrentUserSessionToken() }, equipmentGroupObjectId, vehicleObjectId, trailerObjectId) {
  if (!equipmentGroupObjectId) throw new Error('Equipment group is required');
  if (!vehicleObjectId && !trailerObjectId) throw new Error('Either vehicle or trailer is required');
  if (vehicleObjectId && trailerObjectId) throw new Error('Can only pass in vehicle or trailer, not both');

  let equipmentGroup = await getRecordByObjectId(
    options, // options
    EQUIPMENT_GROUP_TABLE, // tableName
    equipmentGroupObjectId, // objectId
    undefined, // includedPointers
    undefined, // selectedAttributes
  );

  let equipmentPointer;

  if (vehicleObjectId) {
    equipmentPointer = createTempPointer('Vehicle', vehicleObjectId);
  } else if (trailerObjectId) {
    equipmentPointer = createTempPointer('Trailer', trailerObjectId);
  }

  const equipmentGroupEquipment = await addRecord(
    options,
    EQUIPMENT_GROUP_EQUIPMENT_TABLE,
    {
      vehicle: vehicleObjectId && equipmentPointer,
      trailer: trailerObjectId && equipmentPointer,
      equipmentGroup,
    },
    getCurrentUserCompanyObjectId(),
  );

  // Update number of equipment in the equipment group only if the equipment group was added successfully
  if (equipmentGroupEquipment) {
    const numberOfVehicles = getAttribute(equipmentGroup, 'numberOfVehicles');
    const numberOfTrailers = getAttribute(equipmentGroup, 'numberOfTrailers');

    equipmentGroup = await updateRecord(
      options,
      equipmentGroup,
      {
        numberOfVehicles: vehicleObjectId ? numberOfVehicles + 1 : numberOfVehicles,
        numberOfTrailers: trailerObjectId ? numberOfTrailers + 1 : numberOfTrailers,
      },
      true,
    );
  }

  return equipmentGroupEquipment;
}

/**
 * @memberof module:EquipmentGroup
 * @description Given an equipment group, and an equipment, remove that equipment from the equipment group. Limited to only handle removing one equipment at a time.
 *
 * @param {Object} [options] - See example
 * @param {String} equipmentGroupObjectId - Equipment group we wish to delete equipment from
 * @param {String} [vehicleObjectId] - Vehicle we wish to delete from the equipment group
 * @param {String} [trailerObjectId] - Trailer we wish to delete from the equipment group
 *
 * @returns {EquipmentGroupEquipment} the deleted EquipmentGroupEquipment record
 */
async function deleteEquipmentGroupEquipment(options = { sessionToken: getCurrentUserSessionToken() }, equipmentGroupObjectId, vehicleObjectId, trailerObjectId) {
  if (!equipmentGroupObjectId) throw new Error('Equipment group is required');
  if (!vehicleObjectId && !trailerObjectId) throw new Error('Either vehicle or trailer is required');
  if (vehicleObjectId && trailerObjectId) throw new Error('Can only pass in vehicle or trailer, not both');

  const equipmentGroupEquipmentQuery = createQuery(EQUIPMENT_GROUP_EQUIPMENT_TABLE);
  setQueryRestriction(equipmentGroupEquipmentQuery, QueryRestriction.EQUAL_TO, 'equipmentGroup', equipmentGroupObjectId);

  // Find the equipment group equipment
  if (vehicleObjectId) {
    setQueryRestriction(equipmentGroupEquipmentQuery, QueryRestriction.EQUAL_TO, 'vehicle', vehicleObjectId);
  } else if (trailerObjectId) {
    setQueryRestriction(equipmentGroupEquipmentQuery, QueryRestriction.EQUAL_TO, 'trailer', trailerObjectId);
  }

  const equipmentGroupEquipment = await findRecords(
    options,
    equipmentGroupEquipmentQuery,
    true,
    false,
  );

  const deletedEquipmentGroupEquipment = await destroyRecord(options, equipmentGroupEquipment);

  // Update number of equipment in the equipment group only if the equipment group was deleted successfully
  if (deletedEquipmentGroupEquipment) {
    let equipmentGroup = await getRecordByObjectId(
      options, // options
      EQUIPMENT_GROUP_TABLE, // tableName
      equipmentGroupObjectId, // objectId
      undefined, // includedPointers
      undefined, // selectedAttributes
    );

    const numberOfVehicles = getAttribute(equipmentGroup, 'numberOfVehicles');
    const numberOfTrailers = getAttribute(equipmentGroup, 'numberOfTrailers');

    equipmentGroup = await updateRecord(
      options,
      equipmentGroup,
      {
        numberOfVehicles: vehicleObjectId ? numberOfVehicles - 1 : numberOfVehicles,
        numberOfTrailers: trailerObjectId ? numberOfTrailers - 1 : numberOfTrailers,
      },
      true,
    );
  }

  return deletedEquipmentGroupEquipment;
}

/** --------------------------- Helper Equipment Group Functions --------------------------- */

/**
 * @memberof module:EquipmentGroup
 * @description Obtains equipment groups from a company, and creates a mapping between equipment groups and its associated equipments
 *
 * The return object contains:
 *  equipmentGroupInformationMap - mapping between equipment group, and its name, vehicles, and trailers
 *  equipmentGroupEquipmentVehiclesMap - mapping between vehicle objectId, and its associated equipment groups
 *  equipmentGroupEquipmentTrailersMap - mapping between trailer objectId, and its associated equipment groups
 * @returns {object} - { equipmentGroupInformationMap, equipmentGroupEquipmentVehiclesMap, equipmentGroupEquipmentTrailersMap }
 */
async function getEquipmentGroupEquipmentMapping() {
  // The general logic for retrieving and sorting equipment into their corresponding equipment groups - Steps 1 - 3 are performed in this function
  // 1. Get all the EquipmentGroups for the company
  // 2. From the EquipmentGroups objectIds, get all the EquipmentGroupEquipments - this holds all information about which vehicles/trailers are in each group
  // 3. From EquipmentGroupEquipments, create a map of each vehicle/trailer and its associated equipment groups
  // 4. Retrieve the vehicles/trailers for the given company (currently, it is limited to 400 vehicles/trailers, or whatever the limit is set to)
  // 5. Run through each vehicle/trailer, check to see if its part of any groups, and add it to the corresponding group

  // Notes:
  // - This implementation does not take into account permissions, so retrieves all equipment groups
  // - This implementation does not take into account child companies' equipment groups, it will only retrieve the current company's equipment groups

  const equipmentGroupInformationMap = {};

  // Step 1: Fetch all the EquipmentGroups for the company
  const { equipmentGroups } = await getEquipmentGroups(
    undefined, // options
    undefined, // companyObjectId
    false, // includeChildCompanies
    undefined, // filters
    undefined, // sortBy
    undefined, // includedPointers
    ['name'], // selectedAttributes
    undefined, // page
    undefined, // limit
    true, // queryAll
  );

  const currentUser = getCurrentUser();

  const equipmentGroupUserFilters = [];

  equipmentGroupUserFilters.push(new Filter(QueryRestriction.EXISTS, 'equipmentGroup'));
  equipmentGroupUserFilters.push(new Filter(QueryRestriction.EQUAL_TO, 'user', getAttribute(currentUser, 'objectId')));
  equipmentGroupUserFilters.push(new Filter(QueryRestriction.EQUAL_TO, 'enabled', false));

  // Get any equipment groups that the user is not a part of (it has been disabled for that user)
  const { equipmentGroupUsers } = await getEquipmentGroupUsers(
    undefined, // options
    undefined, // equipmentGroupObjectId
    equipmentGroupUserFilters, // filters
    undefined, // sortBy
    undefined, // includedPointers
    undefined, // selectedAttributes
    undefined, // page
    undefined, // limit
    true, // queryAll
  );

  const disabledUserEquipmentGroups = equipmentGroupUsers.map((equipmentGroupUser) => {
    const equipmentGroup = getAttribute(equipmentGroupUser, 'equipmentGroup');
    return getAttribute(equipmentGroup, 'objectId');
  });

  // Step 1.1: Fetch all EquipmentGroup object ids and initialize the equipmentGroupInformationMap, which holds information about all the equipment groups and their associated vehicles
  let equipmentGroupObjectIds = equipmentGroups.map((equipmentGroup) => {
    const name = getAttribute(equipmentGroup, 'name');
    const equipmentGroupObjectId = getAttribute(equipmentGroup, 'objectId');

    // We'll filter out any equipment groups that the user is not a part of
    if (disabledUserEquipmentGroups.includes(getAttribute(equipmentGroup, 'objectId'))) return;

    // Set up the map for the equipment group - this contains both vehicles and trailers
    equipmentGroupInformationMap[equipmentGroupObjectId] = {
      name,
      vehicles: [],
      trailers: [],
    };

    return getAttribute(equipmentGroup, 'objectId');
  });

  equipmentGroupObjectIds = equipmentGroupObjectIds.filter((equipmentGroupObjectId) => equipmentGroupObjectId);

  // Add in the default group after all the groups have been added into the map
  equipmentGroupInformationMap.default = {
    name: 'No Group',
    vehicles: [],
    trailers: [],
  };

  // Step 2: Fetch all EquipmentGroupEquipment
  const equipmentGroupEquipmentArr = await Promise.all(
    equipmentGroupObjectIds.map((equipmentGroupObjectId) => getEquipmentGroupEquipments(
      undefined, // options
      equipmentGroupObjectId, // equipmentGroupObjectId
      undefined, // filters
      undefined, // sortBy
      undefined, // includedPointers
      undefined, // selectedAttributes
      undefined, // page
      undefined, // limit
      true, // queryAll
    )),
  );

  // Step 2.1: Since the EquipmentGroupEquipment query returns back { equipmentGroupEquipments: [] }, we need to grab the equipmentGroupEquipments from each result we obtained in step 2
  const equipmentGroupEquipments = equipmentGroupEquipmentArr.map((equipmentGroupEquipment) => equipmentGroupEquipment.equipmentGroupEquipments).flat();
  const equipmentGroupEquipmentVehiclesMap = {};
  const equipmentGroupEquipmentTrailersMap = {};

  // Step 3: Create a map of each equipment, and its associated equipment groups
  // This step enables us later to easily retrieve the EquipmentGroups for each equipment that is part of a group
  equipmentGroupEquipments.forEach((equipmentGroupEquipment) => {
    const vehicle = getAttribute(equipmentGroupEquipment, 'vehicle', true);
    const vehicleObjectId = getAttribute(vehicle, 'objectId', true);

    const trailer = getAttribute(equipmentGroupEquipment, 'trailer', true);
    const trailerObjectId = getAttribute(trailer, 'objectId', true);

    const equipmentGroup = getAttribute(equipmentGroupEquipment, 'equipmentGroup');
    const equipmentGroupObjectId = getAttribute(equipmentGroup, 'objectId', true);

    // Categorize the equipment into the appropriate map
    if (vehicle) {
      if (equipmentGroupEquipmentVehiclesMap[vehicleObjectId]) equipmentGroupEquipmentVehiclesMap[vehicleObjectId].push(equipmentGroupObjectId);
      else equipmentGroupEquipmentVehiclesMap[vehicleObjectId] = [equipmentGroupObjectId];
    } else if (trailer) {
      if (equipmentGroupEquipmentTrailersMap[trailerObjectId]) equipmentGroupEquipmentTrailersMap[trailerObjectId].push(equipmentGroupObjectId);
      else equipmentGroupEquipmentTrailersMap[trailerObjectId] = [equipmentGroupObjectId];
    }
  });

  return { equipmentGroupInformationMap, equipmentGroupEquipmentVehiclesMap, equipmentGroupEquipmentTrailersMap };
}

/**
 * @memberof module:EquipmentGroupUser
 * @description Create EquipmentGroupUser record
 *
 * @param {Object} [options] - See example
 * @param {string} equipmentGroupObjectId - The equipment group we wish to add the user to
 * @param {string} userObjectId - The user we wish to add to the equipment group
 * @param {Object} keyValueObj - Key value object for the database attributes
 *
 * @returns {EquipmentGroupUser} returns the created EquipmentGroupUser record
 */
async function createEquipmentGroupUser(options = { sessionToken: getCurrentUserSessionToken() }, equipmentGroupObjectId, userObjectId, keyValueObj) {
  if (!equipmentGroupObjectId) throw new Error('EquipmentGroupUser object id is required');
  if (!userObjectId) throw new Error('User object id is required');

  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

  // Fetch EquipmentGroup
  const equipmentGroup = await getRecordByObjectId(
    options, // options
    EQUIPMENT_GROUP_TABLE, // tableName
    equipmentGroupObjectId, // objectId
    undefined, // includedPointers
    undefined, // selectedAttributes
  );

  // Fetch User
  const user = await getRecordByObjectId(
    options, // options
    '_User', // tableName
    userObjectId, // objectId
    undefined, // includedPointers
    undefined, // selectedAttributes
  );

  const equipmentGroupUser = await addRecord(
    options, // options
    EQUIPMENT_GROUP_USERS_TABLE, // tableName
    {
      equipmentGroup: equipmentGroup,
      user: user,
      ...keyValueObj,
    },
    getAttribute(belongsToCompany, 'objectId'), // companyObjectId
  );

  return equipmentGroupUser;
}

/**
 * @memberof module:EquipmentGroupUser
 * @description Obtains equipment group users from a equipment group
 *
 * @param {Object} [options] - See example
 * @param {String} equipmentGroupObjectId - Equipment group we wish to get users from
 * @param {Array<Filter>} [filters] - Array of Filter objects
 * @param {Sort} [sortBy] - Sort object
 * @param {Array<String>} [includedPointers] - Included pointers
 * @param {Array<String>} [selectedAttributes] - Select attributes to return
 * @param {int} [page] - The current page for pagination
 * @param {int} [limit] - The limit of records obtained per pagination
 * @param {Boolean} [queryAll] - Get all records
 *
 * @returns {Object} - returns an object containing the equipment group users { equipmentGroupUsers: [] }
 */
async function getEquipmentGroupUsers(options = { sessionToken: getCurrentUserSessionToken() }, equipmentGroupObjectId, filters = [], sortBy = new Sort(QuerySortOrder.DESCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  let equipmentGroupUsersQuery = createQuery(EQUIPMENT_GROUP_USERS_TABLE);

  const _filters = [...filters];

  // set universal filters
  if (equipmentGroupObjectId) _filters.push(new Filter(QueryRestriction.EQUAL_TO, 'equipmentGroup', equipmentGroupObjectId));
  _filters.map(filter => setQueryRestriction(equipmentGroupUsersQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  // const equipmentGroupCountQuery = copyQuery(equipmentGroupQuery);

  // sort
  sortQuery(equipmentGroupUsersQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(equipmentGroupUsersQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(equipmentGroupUsersQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(equipmentGroupUsersQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(equipmentGroupUsersQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the equipment group users - ignore count query now until its needed
  const promises = [findRecords(options, equipmentGroupUsersQuery, false, queryAll)];

  try {
    const [equipmentGroupUsers] = await Promise.all(promises);
    return { equipmentGroupUsers };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:EquipmentGroupUser
 * @description Updates an EquipmentGroupUser given a keyValue object
 *
 * @param {Object} [options]  - See example
 * @param {String} equipmentGroupUserObjectId - Equipment group user we wish to update
 * @param {Object} keyValueObj - Map of attributes and their values to update
 *
 * @returns {EquipmentGroupUser} - returns the updated EquipmentGroupUser
 */
async function updateEquipmentGroupUser(options = { sessionToken: getCurrentUserSessionToken() }, equipmentGroupUserObjectId, keyValueObj) {
  if (!equipmentGroupUserObjectId) throw new Error('Equipment group user id is required');
  if (!keyValueObj) throw new Error('Key-value object is required');

  let equipmentGroupUser = await getRecordByObjectId(
    options, // options
    EQUIPMENT_GROUP_USERS_TABLE, // tableName
    equipmentGroupUserObjectId, // objectId
    undefined, // includedPointers
    undefined, // selectedAttributes
  );

  equipmentGroupUser = await updateRecord(options, equipmentGroupUser, keyValueObj, true);
  return equipmentGroupUser;
}

/**
 * @memberof module:EquipmentGroupUser
 * @description Deletes an EquipmentGroupUser
 *
 * @param {Object} [options]  - See example
 * @param {String} equipmentGroupUserObjectId - Equipment group user we wish to delete
 *
 * @returns {EquipmentGroupUser} - returns the deleted EquipmentGroupUser
 */
async function deleteEquipmentGroupUser(options = { sessionToken: getCurrentUserSessionToken() }, equipmentGroupUserObjectId) {
  if (!equipmentGroupUserObjectId) throw new Error('Equipment group user id is required');

  let equipmentGroupUser = await getRecordByObjectId(
    options, // options
    EQUIPMENT_GROUP_USERS_TABLE, // tableName
    equipmentGroupUserObjectId, // objectId
    undefined, // includedPointers
    undefined, // selectedAttributes
  );

  equipmentGroupUser = await destroyRecord(options, equipmentGroupUser);
  return equipmentGroupUser;
}

export {
  getEquipmentGroups,
  createEquipmentGroup,
  updateEquipmentGroup,
  deleteEquipmentGroup,
  getEquipmentGroupEquipments,
  createEquipmentGroupEquipment,
  deleteEquipmentGroupEquipment,
  getEquipmentGroupEquipmentMapping,
  createEquipmentGroupUser,
  getEquipmentGroupUsers,
  updateEquipmentGroupUser,
  deleteEquipmentGroupUser,
};
