import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

//api
import history from 'sbHistory';
import { getAttribute } from 'api/Parse';

//component
import SBTable from 'components/Shared/SBTable/SBTable';
import { MDBIcon, MDBBtn } from 'mdbreact';

class UncertifiedLogsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      driverObjectId: undefined ,
      activePage: 0,
      count: 15,
      pageCount: 1,
      eldUncertifiedDailyLogRecords: [],
      driverTimezone: undefined
    }

    this.handlePaginatorOnChange = this.handlePaginatorOnChange.bind(this);
  }

  componentDidMount() {
    const eldUncertifiedDailyLogsByDriverObjectId = this.props.eldUncertifiedDailyLogsByDriverObjectId
    const eldUncertifiedDailyLogRecords = eldUncertifiedDailyLogsByDriverObjectId.eldUncertifiedDailyLogRecords;
    const driverRecord = eldUncertifiedDailyLogsByDriverObjectId.driverRecord;
    const driverTimezone = getAttribute(driverRecord, 'timezoneOffsetFromUTC') || moment.tz.guess();
    const driverObjectId = this.props.driverObjectId;
    const pageCount = Math.ceil(eldUncertifiedDailyLogRecords.length/this.props.count);

    const activePage = 0;
    const count = this.props.count
    this.setState({ ...this.state, pageCount, activePage, count, eldUncertifiedDailyLogRecords, driverTimezone, driverObjectId })
  }

  handlePaginatorOnChange(type, inputValue) {
    let { activePage, pageCount } = this.state;
    if (type === 'PREVIOUS') {
      activePage--;
      if (activePage < 0) activePage = 0; // don't let values before 0
    } else if (type === 'NEXT') {
      activePage++;
      if (activePage >= pageCount - 1) activePage = pageCount - 1;
    } else if (type === 'SUBMIT') {
      let page = inputValue - 1; // the actual value is one less, since we pass +1 page in the component (so paging doesnt start at 0)

      if (page < 0) {
        page = 0;
      } else if (page >= (pageCount - 1)) {
        page = pageCount - 1;
      }
      activePage = page;
    }

    document.querySelector(`.drivers-sb-card${this.state.driverObjectId}`).scrollIntoView({ behavior: "smooth", block: "end" });

    this.setState({ ...this.state, activePage, pageCount });
  }

  render() {
    const { props, state } = this;
    const eldTableHeaderRows = [{
      key:'sb-drivers-list' + state.driverObjectId ,
      columns: [
        {
          element: <div>Date Start</div>,
          props: {
            style: { width: '35%', textAlign: 'left' }
          }
        },
        {
          element: <div>Date End</div>,
          props: {
            style: { width: '35%', textAlign: 'left' }
          }
        },
        // {
        //   element: <div>Incompleted</div>,
        //   props: {
        //     style: { width: '10%', textAlign: 'left' }
        //   }
        // },
        {
          element: <div></div>,
          props: {
            style: { width: '20%', textAlign: 'left' }
          }
        },
      ]
    }]

    const currentPageEldUncertifiedDailyLogRecordsIndexStart = state.activePage * state.count;
    const eldTableBodyRows = state.eldUncertifiedDailyLogRecords.slice(currentPageEldUncertifiedDailyLogRecordsIndexStart, currentPageEldUncertifiedDailyLogRecordsIndexStart + state.count ).map( eldUncertifiedDailyLogRecord => {
      const eldUncertifiedDailyLogId =  getAttribute(eldUncertifiedDailyLogRecord, 'objectId');
      const eldUncertifiedTimezoneOffsetFromUTC = getAttribute(eldUncertifiedDailyLogRecord, 'timezoneOffsetFromUTC');
      const _timezoneOffsetFromUTC = eldUncertifiedTimezoneOffsetFromUTC || state.driverTimezone;
      const startMoment = moment.tz(getAttribute(eldUncertifiedDailyLogRecord, 'startTimeUTC'), _timezoneOffsetFromUTC);
      const endMoment = moment.tz(getAttribute(eldUncertifiedDailyLogRecord, 'endTimeUTC'), _timezoneOffsetFromUTC);

      const startTime = startMoment.format('MMMM Do YYYY, h:mm:ss a');
      const exactCompletedEndMoment = startMoment.add(24,'hours');
      const endTime = endMoment.format('MMMM Do YYYY, h:mm:ss a');

      const completed = exactCompletedEndMoment.isSameOrBefore(endMoment, 'second') ? <div></div> : <MDBIcon icon = 'times' className = 'red-text'/>;

      const queryDate = startMoment.format('DDMMYY');

      return {
        key: eldUncertifiedDailyLogId,
        columns: [
          { element: <div> { startTime } </div> },
          { element: <div> { endTime } </div> },
          { element: <MDBBtn size='sm' color="secondary" onClick = {()=>{ history.push({ pathname: 'driver', search: "driver=" + state.driverObjectId + "&view=hosEvents" + "&date=" + queryDate}); }}>Go To Log</MDBBtn>,
            props: { style:{ textAlign:'right' }}
          }
        ],
        props: {}
      }
    })



    return(
    <SBTable
      showPaginator
      activePage = { state.activePage + 1 }
      pageCount = { state.pageCount }
      handlePaginatorOnChange = { this.handlePaginatorOnChange }
      tableHeaderRows = { eldTableHeaderRows }
      tableBodyRows = { eldTableBodyRows } />
    )
  }
}

UncertifiedLogsTable.propTypes = {
  driverObjectId: PropTypes.string,
  driverTimezone: PropTypes.string,
  eldUncertifiedDailyLogRecords: PropTypes.array,
  count: PropTypes.number
};


export default UncertifiedLogsTable;
