import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import * as ELD from 'api/ELD';
import { getTimezoneAbbreviation } from 'api/utils/timezone';

import { numberWithCommas, formatName, convertDistance } from 'api/Helpers';
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { getAttribute } from 'api/Parse';
import { getQueryParameter } from 'api/URL';
import { MDBBtn, MDBInput, MDBIcon, MDBRow, MDBCol, MDBContainer } from 'mdbreact';

import {
  Table,
  TableHeader,
  TableRow,
  TableRowColumn,
  TableHeaderColumn,
  TableBody,
} from 'material-ui';

import styles from './DrivenByDriverTable.module.scss';

import Title from 'components/LayoutTitle/view/Title';

class DrivenByDriverTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // Old Driven By Driver Table
      odometerReadingsByVehicle: {},
      firstFoundCoDriverELDEvent: undefined,
      distanceUnit: getPDispatcherPropertyFromState('distanceUnit'),
      driverDrivenIntervals: [],
      driverSBONNDIntervals: [],
      mergedDriverIntervals: [],
      filteredOdometerReadingVehicleMapObj: undefined,

      // New Driven By Driver Table
      mergedUnifiedDriverIntervals: [],
      useUnifiedOdometerReadings: false,
    };
    this.getOdometerReadings = this.getOdometerReadings.bind(this);
    this.checkIfCoDriverMileage = this.checkIfCoDriverMileage.bind(this);
    this.mergeDriverIntervals = this.mergeDriverIntervals.bind(this);
    this.mergeUnifiedDriverIntervals = this.mergeUnifiedDriverIntervals.bind(this);
  }

  async componentDidMount() {
    const { props } = this;
    const { eldEvents } = props;

    const driver = eldEvents && eldEvents.length > 0 && eldEvents[0].get('driver');
    const eldDailyCertification = eldEvents && eldEvents.length > 0 && eldEvents[0].get('eldDailyCertification');
    let timezoneOffsetFromUTC = (driver && driver.get('timezoneOffsetFromUTC')) || (eldDailyCertification && eldDailyCertification.get('timezoneOffsetFromUTC')) || moment.tz.guess();
    if (this.props.timezoneOffsetFromUTC) timezoneOffsetFromUTC = this.props.timezoneOffsetFromUTC;
    const useUnifiedOdometerReadings = eldEvents && eldEvents.length > 0 && moment(eldEvents[0].get('eventDateTime')).tz(timezoneOffsetFromUTC).isAfter(moment('2020-09-30'));

    if (useUnifiedOdometerReadings) {
      await this.mergeUnifiedDriverIntervals();
    } else {
      await this.getOdometerReadings(eldEvents);
      await this.mergeDriverIntervals();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.unifiedOdometerReadings === this.props.unifiedOdometerReadings &&
      nextState.mergedUnifiedDriverIntervals === this.state.mergedUnifiedDriverIntervals &&
      nextState.mergedDriverIntervals === this.state.mergedDriverIntervals
    ) return false;
    return true;
  }

  async componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      const { eldEvents } = nextProps;
      const driver = eldEvents && eldEvents.length > 0 && eldEvents[0].get('driver');
      const eldDailyCertification = eldEvents && eldEvents.length > 0 && eldEvents[0].get('eldDailyCertification');
      let timezoneOffsetFromUTC = (driver && driver.get('timezoneOffsetFromUTC')) || (eldDailyCertification && eldDailyCertification.get('timezoneOffsetFromUTC')) || moment.tz.guess();
      if (nextProps.timezoneOffsetFromUTC) timezoneOffsetFromUTC = nextProps.timezoneOffsetFromUTC;
      const useUnifiedOdometerReadings = eldEvents && eldEvents.length > 0 && moment(eldEvents[0].get('eventDateTime')).tz(timezoneOffsetFromUTC).isAfter(moment('2020-09-30'));
      if (useUnifiedOdometerReadings) {
        await this.mergeUnifiedDriverIntervals();
      } else {
        await this.getOdometerReadings(eldEvents);
        await this.mergeDriverIntervals();
      }
    }
  }

  async getOdometerReadings(eldEvents) {
    const distanceUnit = getPDispatcherPropertyFromState('distanceUnit');

    const filteredOdometerReadingVehicleMapObj = await ELD.filterOdometerReadingsJumpNew(eldEvents);
    let _eldEvents = eldEvents.filter(eldEvent => {
      return eldEvent.get('eldEventRecordStatusInt') === 1;
    });

    const dutyStatusTypeCodeInts = [11, 12, 13, 14, 21, 22, 30, 31, 32];
    _eldEvents = _eldEvents.filter(event => dutyStatusTypeCodeInts.indexOf(event.get('eldEventTypeCodeInt')) !== -1);

    const firstFoundCoDriverELDEvent = _eldEvents.find(eldEvent => {
      const coDriver = getAttribute(eldEvent, 'coDriver', true);
      return coDriver;
    });

    let odometerReadingsByVehicle;
    if (firstFoundCoDriverELDEvent) {
      try {
        odometerReadingsByVehicle = await ELD.getOdometerReadings(
          _eldEvents,
          distanceUnit,
          false,
          false,
          true,
          true,
          true,
        );
      } catch (error) {
        odometerReadingsByVehicle = await ELD.getOdometerReadings(
          _eldEvents,
          distanceUnit,
          false,
          false,
          true,
          true,
          false,
        );
      }
    } else {
      odometerReadingsByVehicle = await ELD.getOdometerReadings(
        _eldEvents,
        distanceUnit,
        false,
        false,
        true,
        true,
        false,
      );
    }

    const driverDrivenIntervals = [];
    const driverSBONNDIntervals = [];
    let currentSBInterval = [];
    let currentInterval = [];
    _eldEvents.map((eldEvent, index) => {

      if (currentInterval.length === 0 && [13, 21, 22].indexOf(getAttribute(eldEvent, 'eldEventTypeCodeInt')) !== -1) {
        if (driverDrivenIntervals.length === 0) {
          //if the ododmeter is 0, we should add in the start odometer for the day
          let eldEventClone = eldEvent.clone();
          eldEventClone.set('totalVehicleKm', odometerReadingsByVehicle[getAttribute(eldEvent, 'vehicleUnitId')].odometerStart);
          currentInterval.push(eldEventClone);
        }
        else {
          currentInterval.push(eldEvent);
        }
      }

      else if (currentInterval.length > 0) {
        if ([13, 21, 22].indexOf(getAttribute(eldEvent, 'eldEventTypeCodeInt')) === -1) {
          currentInterval.push(eldEvent);
          driverDrivenIntervals.push(currentInterval);
          currentInterval = [];
        }
      }

      //determine all the SB/ONND intervals here
      if (currentSBInterval.length === 0 && getAttribute(eldEvent, 'eldEventTypeCodeInt') === 12) {
        currentSBInterval.push(moment(getAttribute(eldEvent, 'eventDateTime')).format("HH:mm"));
      }
      if (currentSBInterval.length > 0 && getAttribute(eldEvent, 'eldEventTypeCodeInt') === 14) {
        currentSBInterval.push(moment(getAttribute(eldEvent, 'eventDateTime')).format("HH:mm"));
        driverSBONNDIntervals.push(currentSBInterval);
        currentSBInterval = [];
      }

      //last eldEvent, push to complete all intervals if required
      if (index === _eldEvents.length - 1 && driverDrivenIntervals.length > 0) {
        if (currentInterval.length > 0) {
          currentInterval.push(eldEvent);
          driverDrivenIntervals.push(currentInterval);
        }
        if (currentSBInterval.length > 0) {
          currentSBInterval.push(moment(getAttribute(eldEvent, 'eventDateTime')).format("HH:mm"));
          driverSBONNDIntervals.push(currentSBInterval);
        }

        const lastInterval = driverDrivenIntervals.pop();
        const lastELDEvent = lastInterval.pop();
        let eldEventClone = lastELDEvent.clone();
        if (odometerReadingsByVehicle[getAttribute(lastELDEvent, 'vehicleUnitId')].dayHasCoDriver) {
          eldEventClone.set('totalVehicleKm', odometerReadingsByVehicle[getAttribute(lastELDEvent, 'vehicleUnitId')].odometerEndTotal);
        }
        else {
          eldEventClone.set('totalVehicleKm', odometerReadingsByVehicle[getAttribute(lastELDEvent, 'vehicleUnitId')].odometerEnd);
        }
        lastInterval.push(eldEventClone);
        driverDrivenIntervals.push(lastInterval);
      }
    })

    // console.log(_eldEvents)
    // console.log(driverDrivenIntervals);
    // console.log(odometerReadingsByVehicle);
    // console.log(driverSBONNDIntervals);

    await this.setState({ ...this.state, filteredOdometerReadingVehicleMapObj, odometerReadingsByVehicle, driverDrivenIntervals, driverSBONNDIntervals, firstFoundCoDriverELDEvent, distanceUnit });
  }

  async mergeDriverIntervals() {
    const { driverDrivenIntervals, firstFoundCoDriverELDEvent, driverSBONNDIntervals } = this.state;

    //merge the intervals together per drivers
    let mergedDriverIntervals = [];
    if (firstFoundCoDriverELDEvent) {
      let driverCoDriverIntervals = []; //define all intervals with driver or codriver
      driverDrivenIntervals.map(eldEvents => {
        const startDateTime = moment(getAttribute(eldEvents[0], 'eventDateTime')).format('HH:mm');
        let isCoDriver = false;
        //check if falls within sb/onnd interval
        driverSBONNDIntervals.map(sbInterval => {
          if (moment(startDateTime, "HH:mm").isSameOrAfter(moment(sbInterval[0], "HH:mm")) && moment(startDateTime, "HH:mm").isSameOrBefore(moment(sbInterval[1], "HH:mm"))) {
            isCoDriver = true;
          }
        });
        driverCoDriverIntervals.push({ isCoDriver, eldEvents });
      })
      let currentInterval = { start: undefined, end: undefined };
      driverCoDriverIntervals.map((interval, index) => {
        if (!currentInterval.start) {
          currentInterval.start = interval.eldEvents[0];
          currentInterval.end = interval.eldEvents[1];
        }
        if (driverCoDriverIntervals.length > index + 1) {
          //if they're the same driver or codriver, merge these intervals
          if (interval.isCoDriver === driverCoDriverIntervals[index + 1].isCoDriver) {
            currentInterval.end = driverCoDriverIntervals[index + 1].eldEvents[1];
          }
          else { //if different we want to push the current interval
            mergedDriverIntervals.push([currentInterval.start, currentInterval.end]);
            currentInterval.start = undefined;
            currentInterval.end = undefined;
          }
        }
        else {
          mergedDriverIntervals.push([currentInterval.start, currentInterval.end]);
        }
      })
    }
    else { //there's no codriver so we'll lump all the intervals into one
      if (driverDrivenIntervals.length > 1) {
        mergedDriverIntervals = [[driverDrivenIntervals[0][0], driverDrivenIntervals[driverDrivenIntervals.length - 1][1]]];
      }
      else {
        mergedDriverIntervals = driverDrivenIntervals;
      }
    }

    await this.setState({ ...this.state, mergedDriverIntervals, useUnifiedOdometerReadings: false });
  }

  //if a codriver exists and driving time falls within SB time, then it's a codriver event
  checkIfCoDriverMileage(startDateTime, firstFoundCoDriverELDEvent) {
    const { driverSBONNDIntervals } = this.state;

    if (!firstFoundCoDriverELDEvent) return false;

    let isCoDriver = false;
    driverSBONNDIntervals.map(interval => {
      if (moment(startDateTime, "HH:mm").isSameOrAfter(moment(interval[0], "HH:mm")) && moment(startDateTime, "HH:mm").isSameOrBefore(moment(interval[1], "HH:mm"))) {
        isCoDriver = true;
      }
    })

    return isCoDriver;
  }

  // Merging driver intervals for new Driven By Driver Table
  mergeUnifiedDriverIntervals() {
    const { props } = this;
    const { unifiedOdometerReadings } = props;
    let mergedUnifiedDriverIntervals = [];
    let currentInterval = {};
    // console.log(unifiedOdometerReadings);
    // console.log(props.unifiedOdometerReadingsWarnings);

    unifiedOdometerReadings && Object.keys(unifiedOdometerReadings).map((vehicleUnitId) => {
      const { breakdown, totalOdometerKmStart } = unifiedOdometerReadings[vehicleUnitId];

      for (let i = 0; i < breakdown.length; i++) {
        if (!currentInterval.startDateTimeUTC) {
          currentInterval = { ...breakdown[i] };
          currentInterval.vehicleUnitId = vehicleUnitId;
          if (i == 0) currentInterval.odometerKmStart = totalOdometerKmStart;
        } else if (getAttribute(currentInterval.driver, 'objectId') === getAttribute(breakdown[i].driver, 'objectId')) {
          // Merge blocks together
          currentInterval.vehicleUnitId = vehicleUnitId;
          currentInterval.endDateTimeUTC = breakdown[i].endDateTimeUTC;
          currentInterval.odometerKmEnd = breakdown[i].odometerKmEnd;
          currentInterval.odometerKmDiff += breakdown[i].odometerKmDiff;
          currentInterval.eldEventObjectIdArr = [...currentInterval.eldEventObjectIdArr, ...breakdown[i].eldEventObjectIdArr];
        } else {
          // Push current interval and create a new one
          mergedUnifiedDriverIntervals.push(currentInterval);
          currentInterval = { ...breakdown[i] };
          currentInterval.vehicleUnitId = vehicleUnitId;
        }
      }

      // Push last interval if there is one
      if (currentInterval.startDateTimeUTC) {
        mergedUnifiedDriverIntervals.push(currentInterval);
        currentInterval = {};
      }
    });

    // Filter out all the intervals with 0 odometer difference
    this.setState({ ...this.state, mergedUnifiedDriverIntervals, useUnifiedOdometerReadings: true });
  }

  render() {
    // Shared
    const tableHeaderColumnStyle = { width: '25%' };

    // Old Driven By Driver Table
    const { eldEvents, eldDailyCertification, location, unifiedOdometerReadingsWarnings, unifiedOdometerReadings } = this.props;
    const { mergedDriverIntervals, firstFoundCoDriverELDEvent, distanceUnit, filteredOdometerReadingVehicleMapObj, odometerReadingsByVehicle, mergedUnifiedDriverIntervals, useUnifiedOdometerReadings } = this.state;

    // New Driven By Driver Table
    const driverObjectId = getQueryParameter(location.search, 'driver');
    let driverDrivenRows = mergedDriverIntervals.map((eldEvent) => {

      const driver = getAttribute(eldEvent[0], 'driver');
      const eldDailyCertication = getAttribute(eldEvent[0], 'eldDailyCertification');
      // const vehicle = getAttribute(eldEvent[0], 'vehicleUnitId');
      const vehicleUnitIds = Object.keys(odometerReadingsByVehicle);
      const vehicle = vehicleUnitIds.join(", ");

      const startELDEventObjectId = getAttribute(eldEvent[0], 'objectId');
      const endELDEventObjectId = getAttribute(eldEvent[1], 'objectId');

      let timezoneOffsetFromUTC = getAttribute(eldDailyCertication, 'timezoneOffsetFromUTC');
      if (this.props.timezoneOffsetFromUTC) timezoneOffsetFromUTC = this.props.timezoneOffsetFromUTC;
      if (!timezoneOffsetFromUTC) {
        timezoneOffsetFromUTC = (driver && getAttribute(driver, 'timezoneOffsetFromUTC')) || moment.tz.guess();
      }
      const startDateTime = moment(getAttribute(eldEvent[0], 'eventDateTime')).tz(timezoneOffsetFromUTC).format('HH:mm');
      const endDateTime = moment(getAttribute(eldEvent[1], 'eventDateTime')).tz(timezoneOffsetFromUTC).format('HH:mm');


      let odometerStart = (filteredOdometerReadingVehicleMapObj && filteredOdometerReadingVehicleMapObj.eventMap && filteredOdometerReadingVehicleMapObj.eventMap[startELDEventObjectId])
        ? filteredOdometerReadingVehicleMapObj.eventMap[startELDEventObjectId]
        : getAttribute(eldEvent[0], 'totalVehicleKm') || 0;

      let odometerEnd = (filteredOdometerReadingVehicleMapObj && filteredOdometerReadingVehicleMapObj.eventMap && filteredOdometerReadingVehicleMapObj.eventMap[endELDEventObjectId])
        ? filteredOdometerReadingVehicleMapObj.eventMap[endELDEventObjectId]
        : getAttribute(eldEvent[1], 'totalVehicleKm') || 0;

      if (firstFoundCoDriverELDEvent) {
        odometerStart = getAttribute(eldEvent[0], 'totalVehicleKm') || 0;
        odometerEnd = getAttribute(eldEvent[1], 'totalVehicleKm') || 0;
      }

      if (mergedDriverIntervals.length > 1 && distanceUnit && distanceUnit.toLowerCase() === 'mi') {
        if (eldEvent === mergedDriverIntervals[0]) {
          odometerEnd = convertDistance(odometerEnd, 'km', 'mi', true);
        }
        else if (eldEvent === mergedDriverIntervals[mergedDriverIntervals.length - 1]) {
          odometerStart = convertDistance(odometerStart, 'km', 'mi', true);
        }
        else {
          odometerStart = convertDistance(odometerStart, 'km', 'mi', true);
          odometerEnd = convertDistance(odometerEnd, 'km', 'mi', true);
        }
      }

      odometerStart = Math.floor(odometerStart);
      odometerEnd = Math.floor(odometerEnd);
      const totalDriven = Math.floor(odometerEnd - odometerStart);

      if (odometerStart !== odometerEnd) {
        return (
          <TableRow key={getAttribute(eldEvent[0], 'objectId') + odometerStart}>
            <TableRowColumn className="verticalAlignMiddle">
              {vehicle}
            </TableRowColumn>
            <TableRowColumn className="verticalAlignMiddle">
              {this.checkIfCoDriverMileage(startDateTime, firstFoundCoDriverELDEvent)
                ? <span>{formatName(getAttribute(getAttribute(firstFoundCoDriverELDEvent, 'coDriver'), 'user_fullName'))} <br />
                  <label className="label-left mb-0">(Co-Driver)</label>
                </span>
                : <span>{formatName(getAttribute(driver, 'user_fullName'))}</span>
              }
            </TableRowColumn>
            <TableRowColumn className="verticalAlignMiddle">
              {`${startDateTime} - ${endDateTime} (${getTimezoneAbbreviation(getAttribute(eldDailyCertication, 'startTimeUTC', true), timezoneOffsetFromUTC)})`}
            </TableRowColumn>
            <TableRowColumn className="verticalAlignMiddle">
              {numberWithCommas(odometerStart)}
            </TableRowColumn>
            <TableRowColumn className="verticalAlignMiddle">
              {numberWithCommas(odometerEnd)}
            </TableRowColumn>
            <TableRowColumn className="verticalAlignMiddle">
              {numberWithCommas(totalDriven)}
            </TableRowColumn>
          </TableRow>
        );
      }
    });

    if (driverDrivenRows.length === 0) {
      driverDrivenRows = (
        <TableRow><TableRowColumn colSpan="69" className="emptyRowMessage verticalAlignMiddle">
          {t('No Readings Logged This Day')}
        </TableRowColumn></TableRow>
      );
    }

    // New Driven By Driver Table
    let unifiedDriverDrivenRows = mergedUnifiedDriverIntervals.map((interval) => {
      // from hotfix: read the timezoneoffset utc from the daily cert passed from props if it exists
      // note that we are taking the value of the timezone from the prop.eldDailycertification as opposed to reading off the cert from an event
      // as the event may not be easily retrieved here. so, in the unforseen (never has happened yet) situation where somehow the event.cert and
      // prop.cert don't match, there may be an inaccuracy with the displayed time values as the timezones are different
      let timezoneOffsetFromUTC = getAttribute(eldDailyCertification, 'timezoneOffsetFromUTC');
      if (this.props.timezoneOffsetFromUTC) timezoneOffsetFromUTC = this.props.timezoneOffsetFromUTC;
      if (!timezoneOffsetFromUTC && interval.driver) {
        timezoneOffsetFromUTC = getAttribute(interval.driver, 'timezoneOffsetFromUTC');
      }
      if (!timezoneOffsetFromUTC) {
        // if still no tz
        timezoneOffsetFromUTC = moment.tz.guess();
      }
      const startDateTime = moment(interval.startDateTimeUTC).tz(timezoneOffsetFromUTC).format('HH:mm');
      const endDateTime = moment(interval.endDateTimeUTC).tz(timezoneOffsetFromUTC).format('HH:mm');

      const odometerStart = Math.floor(distanceUnit && distanceUnit.toLowerCase() === 'mi' ? convertDistance(interval.odometerKmStart, 'km', 'mi', true) : interval.odometerKmStart);
      const odometerEnd = Math.floor(distanceUnit && distanceUnit.toLowerCase() === 'mi' ? convertDistance(interval.odometerKmEnd, 'km', 'mi', true) : interval.odometerKmEnd);
      const odometerDiff = odometerEnd - odometerStart; // This is already converted to the correct units

      return (
        <TableRow key={interval.startDateTime}>
          <TableRowColumn className="verticalAlignMiddle">
            {interval.vehicleUnitId}
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle">
            {interval.driver && formatName(interval.driver.get('user_fullName'))}
            {interval.driver && getAttribute(interval.driver, 'objectId') !== driverObjectId &&
              <React.Fragment>
                <br />
                <label className="label-left mb-0">{t('(Co-Driver)')}</label>
              </React.Fragment>
            }
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle">
            {`${startDateTime} - ${endDateTime} (${getTimezoneAbbreviation(interval.startDateTimeUTC, timezoneOffsetFromUTC)})`}
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle">
            {numberWithCommas(odometerStart)}
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle">
            {numberWithCommas(odometerEnd)}
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle">
            {numberWithCommas(odometerDiff)}
          </TableRowColumn>
        </TableRow>
      );
    });

    if (!unifiedDriverDrivenRows || unifiedDriverDrivenRows.length === 0) {
      unifiedDriverDrivenRows = (
        <TableRow><TableRowColumn colSpan="69" className="emptyRowMessage verticalAlignMiddle">
          {t('No Readings Logged This Day')}
        </TableRowColumn></TableRow>
      );
    }
    window.Localize.translatePage();

    return (
      <div>
        {eldEvents && eldEvents.length > 0 && eldEvents[0].get('eldDailyCertification') &&
          <div style={{ overflowX: 'auto' }}>
            <Title className="h3" title={<span className="translate-me">{`Driven By Driver (${distanceUnit || 'KM'})`}</span>} />
            <Table wrapperStyle={{ overflowX: 'auto', minWidth: '600px' }} >
              <TableHeader
                displaySelectAll={false}
                adjustForCheckbox={false}
                enableSelectAll={false}
              >
                <TableRow className="translate-me">
                  <TableHeaderColumn style={tableHeaderColumnStyle}>
                    Vehicle
                  </TableHeaderColumn>
                  <TableHeaderColumn style={tableHeaderColumnStyle}>
                    Driver
                  </TableHeaderColumn>
                  <TableHeaderColumn style={tableHeaderColumnStyle}>
                    Time
                  </TableHeaderColumn>
                  <TableHeaderColumn style={tableHeaderColumnStyle}>
                    Odometer Start
                  </TableHeaderColumn>
                  <TableHeaderColumn style={tableHeaderColumnStyle}>
                    Odometer End
                  </TableHeaderColumn>
                  <TableHeaderColumn style={tableHeaderColumnStyle}>
                    Total Driven
                  </TableHeaderColumn>
                </TableRow>
              </TableHeader>
              <TableBody
                displayRowCheckbox={false}
                showRowHover
              >
                {useUnifiedOdometerReadings ? unifiedDriverDrivenRows : driverDrivenRows}
              </TableBody>
            </Table>
            {unifiedOdometerReadingsWarnings && mergedUnifiedDriverIntervals.length === 0 && (unifiedOdometerReadingsWarnings.LARGE_REFERENCE_ODOMETER_DIFFERENCE || unifiedOdometerReadingsWarnings.NEGATIVE_MINIMUM_REFERENCE_ODOMETER_READING) ?
              <div>
                <div style={{ textAlign: 'left', width: '100%' }}>
                  <label className={`${styles.labelLeft}`}>{t('Following Odometer Warnings Detected: ')}</label>
                  {unifiedOdometerReadingsWarnings.LARGE_REFERENCE_ODOMETER_DIFFERENCE &&
                    <label className={`ml-1 mb-0 ${styles.labelWarning}`}><MDBIcon icon="exclamation-circle" className="amber-text" /> {t('Large Odometer Difference')}</label>
                  }
                  {unifiedOdometerReadingsWarnings.NEGATIVE_MINIMUM_REFERENCE_ODOMETER_READING &&
                    <label className={`ml-1 mb-0 ${styles.labelWarning}`}><MDBIcon icon="exclamation-circle" className="amber-text" /> {t('Negative Odometer Reading')}</label>
                  }
                  {unifiedOdometerReadingsWarnings.STUCK_ODOMETER_READING &&
                    <label className={`ml-1 mb-0 ${styles.labelWarning}`}><MDBIcon icon="exclamation-circle" className="amber-text" /> {t('Stuck Odometer')}</label>
                  }
                </div>

                <div style={{ textAlign: 'left', width: '100%' }}>
                  <label className={`${styles.labelLeft}`}>{t('Odometer Difference: ')}</label>

                  {Object.keys(unifiedOdometerReadings).map(key => {
                    return (
                      <label className={`ml-1 ${styles.labelWarning}`}>
                        {`${t('Vehicle')} ${key}: ${distanceUnit === 'mi' ? convertDistance(unifiedOdometerReadings[key].totalOdometerKmDiff, 'km', 'mi') : unifiedOdometerReadings[key].totalOdometerKmDiff} ${t(distanceUnit)}`}
                      </label>
                    )
                  })}
                </div>
              </div>
              : ''}
          </div>
        }
      </div>
    );
  }

}

DrivenByDriverTable.propTypes = {
  company: PropTypes.object,
  eldDailyCertification: PropTypes.object,
  eldEvents: PropTypes.array,
  toggleEditOdometerReading: PropTypes.func,
  disableELDEdit: PropTypes.bool,
};

export default DrivenByDriverTable;
