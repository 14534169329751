import Parse from 'parse';
import moment from 'moment-timezone';
import store from '../store';
import * as ParseAPI from './Parse';
import * as Getters from './Getters';
import * as Setters from './Setters';
import * as Helpers from './Helpers';
import { getCurrentUser, updateRecord, getObjectById, getAttribute } from 'api/Parse';

/** @module Chat */

// getCompanyReadWriteACL()
// Setters.addRecord('Contravention', contraventionObjects[i], Helpers.getCompanyReadWriteACL())

/**
 * @memberof module:Chat
 *
 * @param {*} roomId
 * @param {*} friendsArr
 *
 * @returns
 */
const retrieveChatRoom = (roomId, friendsArr) => {
  const promise = new Promise(resolve => {
    let userIds = [];
    const currentUser = Parse.User.current();
    const friendUserIds = friendsArr.map(friend => friend.get('user').id);
    // // get dispatchers of all friends that arent the current user (current dispatcher)
    // let otherDispatchers = [];
    // for (let i = 0; i < friendsArr.length; i++) {
    //   const friendDispatchers = friendsArr[i].get('dispatchers').filter(dispatcher => {
    //     return dispatcher.get('user').id !== currentUser.id;
    //   });
    //   otherDispatchers = otherDispatchers.concat(friendDispatchers);
    // }
    // const otherDispatcherUserIds = otherDispatchers.map(dispatcher => dispatcher.get('user').id);
    //
    // userIds = [currentUser.id].concat(otherDispatcherUserIds, friendUserIds);
    Parse.Cloud.run('retrieveChatRoom', { roomId, userIds: friendUserIds }).then(
      chatRoom => {
        resolve(chatRoom);
      },
      error => {
        reject(error);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Chat
 *
 * @returns
 */
const retrieveChatRooms = () => {
  const promise = new Promise(resolve => {
    const chatRoomQuery = new Parse.Query('ChatRoom');
    chatRoomQuery.include('lastMessage');
    chatRoomQuery.notEqualTo('roomId', "regional-switchboard_users");
    chatRoomQuery.find().then(function (chatRooms) {
      resolve(chatRooms);
    });
  });
  return promise;
}

/**
 * @memberof module:Chat
 * @description adds the current logged in user to the seen array of a chat message
 *
 * @param {String} chatMessageObjectId
 */
async function updateChatSeenStatus(chatMessageObjectId) {
  const currentUser = getCurrentUser();
  const chatMessage = await getObjectById('ChatMessage', chatMessageObjectId, []);
  const chatMessageAttributeObj = {};
  const updatedSeenBy = getAttribute(chatMessage, 'seenBy');
  const filteredCurrentUser = updatedSeenBy.filter(user => {
    return user.id === currentUser.id;
  });
  if (filteredCurrentUser.length < 1) {
    updatedSeenBy.push(currentUser);
  }
  chatMessageAttributeObj.seenBy = updatedSeenBy;
  await updateRecord(chatMessage, chatMessageAttributeObj, true);
  // const results = await updateRecord(chatMessage, chatMessageAttributeObj, true);
}

/**
 * @memberof module:Chat
 *
 * @param {*} keyAttributeObject
 * @param {*} friendsArr
 *
 * @returns
 */
const saveChatMessage = (keyAttributeObject, friendsArr) => {
  const promise = new Promise(resolve => {
    const currentUser = Parse.User.current();
    const chatMessage = Helpers.createTempParseObject('ChatMessage', {
      message: keyAttributeObject.message,
      seenBy: [currentUser],
      sender: currentUser,
      timeSinceEpochId: `${(new Date()).getTime()}&${currentUser.id}`,
      roomId: keyAttributeObject.roomId,
      image: keyAttributeObject.image,
    });
    chatMessage.setACL(Helpers.getCompanyReadWriteACL());
    // now overwrite any of the default properties above with those in keyAttributeObject
    // ...
    chatMessage.save().then(
      chatMessage => {
        resolve(chatMessage);
        const senderUser = chatMessage.get('sender');
        const senderName = `${senderUser.get('firstName')} ${senderUser.get('lastName')}`;
        const friendUserArr = friendsArr.map(friend => friend.get('user'));
        // get dispatchers of all friends that arent the current user (current dispatcher)
        // let otherDispatchers = [];
        // for (let i = 0; i < friendsArr.length; i++) {
        //   const friendDispatchers = Getters.getDispatchersFromDriver(friendsArr[i].id).filter(dispatcher => {
        //     return dispatcher.get('user').id !== currentUser.id;
        //   })
        //   otherDispatchers = otherDispatchers.concat(friendDispatchers);
        // }
        // otherDispatchers = otherDispatchers.map(dispatcher => dispatcher.get('user'));
        ParseAPI.sendPush(
          friendUserArr,
          { type: 4, message: `${chatMessage.get('message')}`, timeSinceEpochId: chatMessage.get('timeSinceEpochId'), roomId: chatMessage.get('roomId') }
        );
      }
    );
  });
  return promise;
}

const test = (friend, roomId) => {
  // console.log(Getters.getDispatchersFromDriver(friend.id));
  Parse.Cloud.run('chatroomFerErrbodySkript').then(
    result => console.log(result)
  );

  // const chatMessage = Helpers.createTempParseObject('ChatMessage', {
  //   message: 'hey thar',
  //   seenBy: [friend.get('user')],
  //   sender: friend.get('user'),
  //   timeSinceEpochId: `${(new Date()).getTime()}&${friend.get('user').id}`,
  //   roomId,
  // });
  // chatMessage.setACL(Helpers.getCompanyReadWriteACL());
  // chatMessage.save().then(
  //   chatMessage => {
  //     resolve(chatMessage);
  //   }
  // );
}

export {
  retrieveChatRoom,
  retrieveChatRooms,
  saveChatMessage,
  updateChatSeenStatus,
  test,
};
