import React from 'react';
import history from 'sbHistory';
import uniqid from 'uniqid';
import moment from 'moment-timezone';

import { removeSpecialCharacters, referenceToTimezone, formatName } from 'api/Helpers';
import { getCurrentUser, getAttribute } from 'api/Parse';
import { getDrivers, isDriving } from 'api/Driver/Driver';
import { getSpeedingDataByDriver } from 'api/DriverPatterns/Speeding';
import { getHOSViolationDataByDriver } from 'api/DriverPatterns/Violation';
import { getEngineDiagnosticsByDriver } from 'api/DriverPatterns/VehicleDiagnostic';
import { getHarshDrivingEventsByDriver } from 'api/DriverPatterns/HarshDriving';

// import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

import { QuerySortOrderTypes } from 'enums/Query';
import { AttributeTypes } from 'enums/Driver';
import { LengthUnitTypes, LengthUnitNames, LengthConversion } from 'enums/DispatchUnit';
import { EventTypes } from 'enums/HarshDriving';
import Badge from 'sbCore/Badge/Badge';
import DutyStatusBadge from 'sbCore/DutyStatusBadge/DutyStatusBadge';

import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import SBTable from 'components/Shared/SBTable/SBTable';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import SBCardEmptyContent from 'components/Shared/SBCard/SBCardEmptyContent';
import { MDBBtn, MDBRow, MDBCol } from 'mdbreact';

// CSS
import './style.scss';

// show message explaining only drivers with stuff will show up
// current vs non-current coloring

class DriverPatternsDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      uniqid: uniqid(),
      currentUser: getCurrentUser(),

      drivers: [],
      driversCount: 0,

      driverQuery: {
        filter: [],
        sortBy: new Sort(AttributeTypes.USER_FULLNAME, QuerySortOrderTypes.ASCENDING),
      },

      units: {
        distance: `${LengthUnitTypes.KM}/h`,
      },

      speedingDataByDriver: {},
      idlingDataByDriver: {},
      hosViolationDataByDriver: {},
      vehicleDiagnosticsByDriver: {},
      harshDrivingEventsByDriver: {},

      isLoading: false,
      loadingMessage: 'Loading Data',
      errors: [],
    };

    this.refreshState = this.refreshState.bind(this);
    this.getSpeedingDataByDriver = this.getSpeedingDataByDriver.bind(this);
    this.getHOSViolationDataByDriver = this.getHOSViolationDataByDriver.bind(this);
    this.getVehicleDiagnosticsByDriver = this.getVehicleDiagnosticsByDriver.bind(this);
    this.getHarshDrivingEventsByDriver = this.getHarshDrivingEventsByDriver.bind(this);
    this.sortDriversByHosViolation = this.sortDriversByHosViolation.bind(this);
    this.setUnit = this.setUnit.bind(this);
  }

  componentDidMount() {
    this.refreshState();
  }

  async refreshState() {
    // start by getting all drivers
    await this.setState({ ...this.state, isLoading: true, drivers: [], driversCount: 0, loadingMessage: 'Obtaining Drivers' });
    const { driverQuery } = this.state;
    const { drivers, totalDriversCount } = await getDrivers(undefined, undefined, driverQuery.sortBy, driverQuery.filter, true, true, true);
    await this.setState({ ...this.state, drivers, totalDriversCount, loadingMessage: 'Obtaining Speeding Data' });

    // now get the speeding data of all the drivers
    await this.setState({ ...this.state, isLoading: true });
    const speedingDataByDriver = await this.getSpeedingDataByDriver();
    await this.setState({ ...this.state, speedingDataByDriver, loadingMessage: 'Obtaining Idling Data' });

    // idling
    const idlingDataByDriver = await this.getIdlingDataByDriver();
    await this.setState({ ...this.state, idlingDataByDriver, loadingMessage: 'Obtaining HOS Violations' });

    // violation data
    const hosViolationDataByDriver = await this.getHOSViolationDataByDriver();
    await this.setState({ ...this.state, hosViolationDataByDriver, loadingMessage: 'Obtaining Vehicle Diagnostics Data' });

    // set a new driver array based on the sorted hosilationData
    const _drivers = this.sortDriversByHosViolation();
    await this.setState({ ...this.state, drivers: _drivers });

    // vehicle diagnostic data
    const vehicleDiagnosticsByDriver = await this.getVehicleDiagnosticsByDriver();
    await this.setState({ ...this.state, vehicleDiagnosticsByDriver, isLoading: false, loadingMessage: 'Loading Data' });

    // harsh driving data
    // const harshDrivingEventsByDriver = await this.getHarshDrivingEventsByDriver();
    // await this.setState({ ...this.state, isLoading: false, harshDrivingEventsByDriver });
  }

  async getSpeedingDataByDriver() {
    const { drivers } = this.state;
    const driverObjectIds = drivers.map(driver => getAttribute(driver, 'objectId'));
    const speedingDataByDriver = await getSpeedingDataByDriver(driverObjectIds);
    return speedingDataByDriver;
  }

  sortDriversByHosViolation() {
    const { hosViolationDataByDriver, drivers } = this.state;
    // map drivers array into hash table( index: driverId, element: driverParseObject );
    const driverHashTable = {};
    drivers.map(driver => {
      const driverObjectId = getAttribute(driver, 'objectId');
      driverHashTable[driverObjectId] = driver;
    });

    //map hosViolationDataByDriver into Array in order to sort drivers by HOS violations number later
    const newDriverArry = [];
    const sortHosViolationDataByDrivers = [];
    Object.keys(hosViolationDataByDriver).map(driverId => {
      const _hosViolationDataByDriver = { driverId, hosViolationDataArry: hosViolationDataByDriver[driverId] };
      return sortHosViolationDataByDrivers.push(_hosViolationDataByDriver);
    });

    // based on sorted HOS violations array and driverHashTable to quickly sort a new drivers Array.
    sortHosViolationDataByDrivers.sort((a, b) => { return b.hosViolationDataArry.length - a.hosViolationDataArry.length; }).map(driverByHosViolation => { return newDriverArry.push(driverHashTable[driverByHosViolation.driverId]); });
    return newDriverArry;
  }

  async getIdlingDataByDriver() {
    const { drivers, speedingDataByDriver } = this.state;
    const idlingDataByDriver = {};
    drivers.filter(driver => {
      const objectId = getAttribute(driver, 'objectId');
      return (speedingDataByDriver[objectId] && isDriving(driver) && !speedingDataByDriver[objectId].speedKm);
    }).map(driver => {
      const objectId = getAttribute(driver, 'objectId');
      idlingDataByDriver[objectId] = true;
    });
    return idlingDataByDriver;
  }

  async getHOSViolationDataByDriver() {
    const { drivers } = this.state;
    const driverObjectIds = drivers.map(driver => getAttribute(driver, 'objectId'));
    const hosViolationDataByDriver = await getHOSViolationDataByDriver(driverObjectIds);
    return hosViolationDataByDriver;
  }

  async getVehicleDiagnosticsByDriver() {
    const { drivers } = this.state;
    const driverObjectIds = drivers.map(driver => getAttribute(driver, 'objectId'));
    const vehicleDiagnosticsByDriver = await getEngineDiagnosticsByDriver(driverObjectIds);
    return vehicleDiagnosticsByDriver;
  }

  async getHarshDrivingEventsByDriver() {
    const { drivers } = this.state;
    const driverObjectIds = drivers.map(driver => getAttribute(driver, 'objectId'));
    const harshDrivingEventsByDriver = await getHarshDrivingEventsByDriver(driverObjectIds, [1, 2, 3]);
    return harshDrivingEventsByDriver;
  }

  setUnit(unitObject, unitType) {
    const units = { ...this.state.units, [unitType]: unitObject.value };
    this.setState({ ...this.state, units });
  }

  render() {
    const { state } = this;

    /**
     * TABLE PROPERTIES
     */
    // const tableHeaderStyles = {
    //   driver: { width: '12%' },
    //   phoneNumber: { width: '12%' },
    //   speeding: { width: '12%' },
    //   malfunction: { width: '18%' },
    //   hosViolations: { width: '22%' },
    //   braking: { width: '12%' },
    //   swerving: { width: '12%' },
    // };
    const tableHeaderStyles = {
      driver: { width: '28%' },
      speedingIdling: { width: '14%' },
      malfunction: { width: '28%' },
      hosViolations: { width: '35%' },
    };

    const tableHeaderRows = [{
      key: 'driver-patterns-list', columns: [
        {
          element: <div>Driver</div>,
          props: {
            style: tableHeaderStyles.driver,
          }
        },
        {
          element: <div>Speeding / Idling</div>,
          props: {
            style: tableHeaderStyles.speedingIdling,
          }
        },
        {
          element: <div>Diagnostics</div>,
          props: {
            style: tableHeaderStyles.malfunction,
          }
        },
        {
          element: <div>HOS Violations</div>,
          props: {
            style: tableHeaderStyles.hosViolations,
          }
        },
        // {
        //   element: <div>Harsh Braking</div>,
        //   props: {
        //     style: tableHeaderStyles.braking,
        //   }
        // },
        // {
        //   element: <div>Swerving</div>,
        //   props: {
        //     style: tableHeaderStyles.swerving,
        //   }
        // },
      ]
    }];

    const showHideColumnData = {}; // track which driver columns data to show/hide
    const speedLimitKm = getAttribute(getAttribute(state.currentUser, 'belongsToCompany'), 'speedLimitKm') || 0;
    const filteredDrivers = state.drivers.filter(driver => {
      // get only drivers with actual violations
      const objectId = getAttribute(driver, 'objectId');
      const driverSpeedingData = state.speedingDataByDriver[objectId] || {};
      const driverIdlingData = state.idlingDataByDriver[objectId] || false;
      const driverHOSViolationData = state.hosViolationDataByDriver[objectId] || [];
      const driverVehicleDiagnostics = state.vehicleDiagnosticsByDriver[objectId] || [];
      const driverHarshDrivingEvents = state.harshDrivingEventsByDriver[objectId] || [];

      const hasSpeedingData = (driverSpeedingData.speedKm !== undefined) && (driverSpeedingData.speedKm > speedLimitKm);
      const hasIdlingData = !!driverIdlingData;
      const hasHOSViolationData = driverHOSViolationData.length > 0;
      const hasVehicleDiagnosticData = driverVehicleDiagnostics.length > 0;
      const hasDriverHarshDrivingEvents = driverHarshDrivingEvents.length > 0;

      showHideColumnData[objectId] = { name: getAttribute(driver, 'user_fullName'), hasSpeedingData, hasIdlingData, hasHOSViolationData, hasVehicleDiagnosticData, hasDriverHarshDrivingEvents };

      return (
        hasSpeedingData || hasIdlingData || hasHOSViolationData || hasVehicleDiagnosticData || hasDriverHarshDrivingEvents
      );
    });

    /**
     * ROW ORDERING - SUBJECT TO CHANGE. RIGHT NOW PRIORITIZES SPEEDING
     */
    // out of the filtered drivers, order those who have speed data at the top first
    const seenOrderedDriverObjectIds = {};
    const orderedDrivers = filteredDrivers.filter(driver => {
      const objectId = getAttribute(driver, 'objectId');
      const driverSpeedingData = state.speedingDataByDriver[objectId] || {};
      if (driverSpeedingData.speedKm && (driverSpeedingData.speedKm > speedLimitKm)) {
        seenOrderedDriverObjectIds[objectId] = true;
        return true;
      };
      return false;
    });


    filteredDrivers.map(driver => {
      const objectId = getAttribute(driver, 'objectId');
      if (!seenOrderedDriverObjectIds[objectId]) {
        orderedDrivers.push(driver);
        seenOrderedDriverObjectIds[objectId] = true;
      }
    });

    const tableBodyRows = orderedDrivers.map(driver => {
      const objectId = getAttribute(driver, 'objectId');
      const fullNameSplit = formatName((getAttribute(driver, 'user_fullName'))).split(' ');
      const displayName = `${removeSpecialCharacters(fullNameSplit[0])} ${removeSpecialCharacters(fullNameSplit[fullNameSplit.length - 1][0]).toUpperCase()}`;
      const phoneNumber = getAttribute(driver, 'user_phoneNumber') || '';
      const driverSpeedingData = state.speedingDataByDriver[objectId] || {};
      const driverIdlingData = state.idlingDataByDriver[objectId] || false;
      const driverHOSViolationData = state.hosViolationDataByDriver[objectId] || [];
      const driverVehicleDiagnostics = state.vehicleDiagnosticsByDriver[objectId] || [];
      const driverHarshDrivingEvents = state.harshDrivingEventsByDriver[objectId] || [];

      // hos violation ui
      const hosViolations = driverHOSViolationData.map((hosViolationDatum, index) => {
        const triggerTime = hosViolationDatum.triggerTime;
        const rule = hosViolationDatum.rule;
        // const regulationInt = hosViolationDatum.regulationInt;
        const eldDailyCertification = hosViolationDatum.eldDailyCertification;
        const logPath = {
          pathname: 'driver',
          search: `driver=${eldDailyCertification.driver.objectId}&view=hosEvents&date=${referenceToTimezone(eldDailyCertification.startTimeUTC, eldDailyCertification.driver.timezoneOffsetFromUTC).format('DDMMYY')}`,
        };
        const violationReportPath = {
          pathname: 'driver',
          search: `driver=${eldDailyCertification.driver.objectId}&view=hosViolations&date=${referenceToTimezone(eldDailyCertification.startTimeUTC, eldDailyCertification.driver.timezoneOffsetFromUTC).format('DDMMYY')}`,
        };

        const style = { fontSize: '.85em' };
        if (index > 0) style.marginTop = '.35em';

        return (
          <React.Fragment key={hosViolationDatum.objectId}>
            <div style={style}>
              <div className="align-middle box-styled mr-3" style={{ display: 'inline-block', width: '8.2em' }}>
                <div className="font-weight-500" style={{ fontSize: '.95em' }}>{referenceToTimezone(triggerTime, eldDailyCertification.driver.timezoneOffsetFromUTC).format('DD-MMM-YY / HH:mm')}</div>
                <div style={{ fontSize: '.9em' }}>{rule}</div>
              </div>
              <div className="align-middle" style={{ display: 'inline-block' }}>
                <MDBBtn onClick={() => history.push(logPath)} className="mr-0" color="default" size="sm" disabled={state.isLoading}>Go to Log</MDBBtn>
                <MDBBtn onClick={() => history.push(violationReportPath)} className="mr-0" color="default" size="sm" disabled={state.isLoading}>Violations</MDBBtn>
              </div>
            </div>
          </React.Fragment>
        );
      });

      // diagnostics ui
      const vehicleDiagnostics = driverVehicleDiagnostics.map((vehicleDiagnostic, index) => {
        const driver = vehicleDiagnostic.driver;
        const loggedAtUTC = referenceToTimezone(vehicleDiagnostic.loggedAtUTC, driver.timezoneOffsetFromUTC);
        const malfunctionCode = vehicleDiagnostic.malfunctionCode;
        const dtcPath = {
          pathname: 'equipment',
          search: 'view=vehicles&t=diagnostics',
        };
        const style = { fontSize: '.85em' };
        if (index > 0) style.marginTop = '.35em';

        return (
          <React.Fragment key={vehicleDiagnostic.objectId}>
            <div>
              <div className="align-middle box-styled mr-3" style={{ ...style, width: '8.2em', display: 'inline-block' }}>
                <div className="font-weight-500" style={{ fontSize: '.95em' }}>{moment(loggedAtUTC).format('DD-MMM-YY / HH:mm')}</div>
                <div style={{ fontSize: '.9em' }}>Code {malfunctionCode}</div>
              </div>
              <div className="align-middle" style={{ display: 'inline-block' }}>
                <MDBBtn onClick={() => history.push(dtcPath)} className="mr-0" color="default" size="sm" disabled={state.isLoading}>View DTC</MDBBtn>
              </div>
            </div>
          </React.Fragment>
        );
      });

      // speeding
      let speed = driverSpeedingData.speedKm;
      if (state.units.distance === LengthUnitTypes.MI) {
        speed = speed * LengthConversion.KM2MI;
        speed = speed.toFixed(2);
      }

      // harsh driving ui
      // split events based on code
      const harshBrakingElements = [];
      const swervingElements = [];

      driverHarshDrivingEvents.map(harshDrivingEvent => {
        const driver = harshDrivingEvent.driver;
        const dateTimeUTC = referenceToTimezone(harshDrivingEvent.dateTimeUTC, driver.timezoneOffsetFromUTC);

        if (harshDrivingEvent.code === EventTypes.ACCELERATION) {
          // nothing yet
        } else if (harshDrivingEvent.code === EventTypes.CORNERING) {
          const style = { fontSize: '.85em' };
          if (!swervingElements.length) style.marginTop = '.35em';

          swervingElements.push(
            <React.Fragment key={harshDrivingEvent.objectId}>
              <div className="box-styled" style={{ ...style, width: '8.2em' }}>
                <div className="font-weight-500" style={{ fontSize: '.9em' }}>{moment(dateTimeUTC).format('DD-MMM-YY / HH:mm')}</div>
              </div>
            </React.Fragment>
          );
        } else if (harshDrivingEvent.code === EventTypes.BRAKING) {
          const style = { fontSize: '.85em' };
          if (!harshBrakingElements.length) style.marginTop = '.35em';

          harshBrakingElements.push(
            <React.Fragment key={harshDrivingEvent.objectId}>
              <div className="box-styled" style={{ ...style, width: '8.2em' }}>
                <div className="font-weight-500" style={{ fontSize: '.9em' }}>{moment(dateTimeUTC).format('DD-MMM-YY / HH:mm')}</div>
              </div>
            </React.Fragment>
          );
        }
      });

      const columns = [
        {
          element: (
            <table>
              <tr>
                <td>
                  <b>{`${displayName}`}</b>
                  <br />
                  {phoneNumber}
                </td>
                <td>
                  <a
                    onClick={(e) => { e.preventDefault(); history.push({ pathname: 'driver', search: `view=hosEvents&driver=${driver.id}` }); }}
                  >
                    <DutyStatusBadge className="z-depth-0" eldStatusInt={getAttribute(driver, 'eldStatusInt')} />
                  </a>
                </td>
              </tr>
            </table>
          ),
          props: { className: '' }
        },
        {
          element: (
            <div>
              {(showHideColumnData[objectId].hasSpeedingData) &&
                (
                  <span>
                    <Badge
                      className="speeding-idling-badge"
                      value={`Speeding: ${speed}${state.units.distance}`}
                      severity="warning"
                    />
                  </span>
                )
              }
              {!(showHideColumnData[objectId].hasSpeedingData) &&
                (
                  <Badge
                    className="speeding-idling-badge"
                    value="Not Speeding"
                    severity="success"
                  />
                )
              }
              {driverIdlingData &&
                (
                  <Badge
                    className="speeding-idling-badge"
                    value="Idling"
                    severity="info"
                  />
                )
              }
              {!driverIdlingData &&
                (
                  <Badge
                    className="speeding-idling-badge"
                    value="Not Idling"
                    severity="success"
                  />
                )
              }
            </div>
          ),
          props: {}
        },
        {
          element: (
            <div>
              {vehicleDiagnostics}
            </div>
          ),
          props: { className: (vehicleDiagnostics.length > 1) ? 'align-top' : 'align-middle' }
        },
        {
          element: (
            <div>
              {hosViolations}
            </div>
          ),
          props: { className: (hosViolations.length > 1) ? 'align-top' : 'align-middle' }
        },
        // { element: <div>{ harshBrakingElements }</div>, props: { className: (harshBrakingElements.length > 1) ? 'align-top' : 'align-middle' } },
        // { element: <div>{ swervingElements }</div>, props: { className: (swervingElements.length > 1) ? 'align-top' : 'align-middle' } },
      ];

      return {
        key: objectId,
        columns,
        props: {},
      };
    });

    /**
     * ACTIONCONTAINER
     */
    const distanceUnitItems = [LengthUnitTypes.KM, LengthUnitTypes.MI].map(unit => {
      return {
        key: unit,
        label: LengthUnitNames[unit],
        value: unit,
      }
    });

    /**
     * BLOCK MESSAGES
     */
    const blockMessages = [
      <div>
        <div className="d-inline-block" style={{ fontWeight: '400', marginRight: '0.2em' }}>Speeding -</div>
        <div className="d-inline-block">Current speed and if it exceeds the speed limit</div>
      </div>,
      <div>
        <div className="d-inline-block" style={{ fontWeight: '400', marginRight: '0.2em' }}>Idling -</div>
        <div className="d-inline-block">If driver is currently idling</div>
      </div>,
      <div>
        <div className="d-inline-block" style={{ fontWeight: '400', marginRight: '0.2em' }}>Diagnostics -</div>
        <div className="d-inline-block">Recently DTC codes for vehicle</div>
      </div>,
      <div>
        <div className="d-inline-block" style={{ fontWeight: '400', marginRight: '0.2em' }}>HOS Violations -</div>
        <div className="d-inline-block">Last 14 days of driver's unresolved violations</div>
      </div>,
      // <div>
      //   <div className="d-inline-block" style={{ fontWeight: '400' }}>Harsh Braking/Swerving -&nbsp;</div>
      //   <div className="d-inline-block">Recent actions that may cause damage to the vehicle</div>
      // </div>,
    ];

    return (
      <div className="driver-patterns-dashboard mt-3">
        <MDBRow>
          <MDBCol>
            <SBBlock
              title=""
              messages={blockMessages}
              listMessages={true}
            />
          </MDBCol>
          <MDBCol>
            <div style={{ position: 'absolute', bottom: '0', right: '1em' }}>
              <ActionsContainer>
                <SBSelect
                  containerClassName="align-middle d-inline-block"
                  defaultToggleText="Speed: KM"
                  dropdownToggleText={`Unit: ${state.units.distance}`}
                  onClick={(e) => e.stopPropagation()}
                  items={distanceUnitItems}
                  getSelectedItems={(items) => { this.setUnit(items[0] || LengthUnitTypes.KM, 'distance') }}
                  disabled={state.isLoading}
                />
                <MDBBtn onClick={() => this.refreshState()} className="mr-0 translate-me" color="secondary" size="sm" disabled={state.isLoading}>Refresh Info</MDBBtn>
              </ActionsContainer>
            </div>
          </MDBCol>
        </MDBRow>

        {state.isLoading && (
          <SBCardEmptyContent isContentLoader>{state.loadingMessage}</SBCardEmptyContent>
        )}

        <SBTable
          containerClassName="dashboard-table"
          hover
          tableHeaderRows={tableHeaderRows}
          tableBodyRows={tableBodyRows}
        />

      </div>
    );
  }
}

DriverPatternsDashboard.defaultProps = {
};
DriverPatternsDashboard.propTypes = {
};

export default DriverPatternsDashboard;
