// api
import { includePointers, getCurrentUser, updateRecord, getAttribute, createQuery, setQueryRestriction, sortQuery, addRecord, find, destroyRecord, destroyRecords, count, getObjectById, increment, decrement } from 'api/Parse';

// enums
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

// sbObjects
import Sort from 'sbObjects/Sort';

const EQUIPMENT_GROUP_TABLE = 'EquipmentGroup';
const EQUIPMENT_GROUP_USERS_TABLE = 'EquipmentGroupUsers';
const EQUIPMENT_GROUP_EQUIPMENT_TABLE = 'EquipmentGroupEquipment';

/** @module EquipmentGroup */

/**
 * @memberof module:EquipmentGroup
 * @description Fetch EquipmentGroup record given its ObjectId
 * 
 * @param {string} equipmentGroupObjectId EquipmentGroup ObjectId
 * 
 * @returns {object} EquipmentGroup record
 */
async function getEquipmentGroupByObjectId(equipmentGroupObjectId) {
  return await getObjectById(EQUIPMENT_GROUP_TABLE, equipmentGroupObjectId, ['geofence']);
}

/**
 * @memberof module:EquipmentGroup
 * @description Fetch all EquipmentGroup records related to a current user
 * 
 * @param {number} page Page number for pagination
 * @param {number} limit Items limit per page for pagination
 * @param {object} sortBy Sort sbObject for sorting groups
 * @param {array} filters Array of Filter sbObjects
 * 
 * @returns {object} Object containing array of EquipmentGroup records and count
 */
async function getEquipmentGroupsForCurrentUser(page = 0, limit = 15, sortBy = new Sort('name', QuerySortOrderTypes.DESCENDING), filters = []) {
  const currentUser = getCurrentUser();
  const equipmentGroupUsersQuery = createQuery(EQUIPMENT_GROUP_USERS_TABLE);
  setQueryRestriction(equipmentGroupUsersQuery, QueryRestrictionTypes.EQUAL_TO, 'user', currentUser);

  const groupsForCurrentUser = createQuery(EQUIPMENT_GROUP_TABLE);
  setQueryRestriction(groupsForCurrentUser, QueryRestrictionTypes.MATCHES_KEY_IN_QUERY, 'objectId',
    equipmentGroupUsersQuery, 'equipmentGroup.objectId');

  setQueryRestriction(groupsForCurrentUser, QueryRestrictionTypes.LIMIT, undefined, limit);
  setQueryRestriction(groupsForCurrentUser, QueryRestrictionTypes.SKIP, undefined, page * limit);

  sortQuery(groupsForCurrentUser, sortBy.order, sortBy.attribute);

  if (filters.length !== 0) {
    filters.forEach(filterObject => {
      setQueryRestriction(
        groupsForCurrentUser,
        filterObject.queryRestriction,
        filterObject.attribute,
        filterObject.value,
      );
    });
  }

  includePointers(groupsForCurrentUser, ['geofence']);

  return {
    groupRecords: await find(groupsForCurrentUser),
    groupRecordsCount: await count(groupsForCurrentUser),
  }
}

/**
 * @memberof module:EquipmentGroup
 * @description Fetch all Vehicle records for a given EquipmentGroup record
 * 
 * @param {object} equipmentGroupRecord EquipmentGroup record
 * @param {number} page The page number for pagination
 * @param {number} limit The limit of items for pagination
 * @param {object} sortBy Sort sbObject applied to both vehicles and trailers
 * @param {array} filters Array of filter sbObjects
 * 
 * @returns {object} Object containing array of Vehicle records and count
 */
async function getEquipmentGroupVehicles(equipmentGroupRecord, page = 0, limit = 15, sortBy = new Sort('unitId', QuerySortOrderTypes.DESCENDING), filters = []) {
  const equipmentQuery = createQuery(EQUIPMENT_GROUP_EQUIPMENT_TABLE);
  setQueryRestriction(equipmentQuery, QueryRestrictionTypes.EQUAL_TO, 'equipmentGroup', equipmentGroupRecord);
  setQueryRestriction(equipmentQuery, QueryRestrictionTypes.EXISTS, 'vehicle');

  const vehiclesQuery = createQuery('Vehicle');
  setQueryRestriction(vehiclesQuery, QueryRestrictionTypes.MATCHES_KEY_IN_QUERY, 'objectId', equipmentQuery, 'vehicle.objectId');
  setQueryRestriction(vehiclesQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  setQueryRestriction(vehiclesQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
  sortQuery(vehiclesQuery, sortBy.order, sortBy.attribute);

  if (filters.length !== 0) {
    filters.forEach(filterObject => {
      setQueryRestriction(
        vehiclesQuery,
        filterObject.queryRestriction,
        filterObject.attribute,
        filterObject.value,
      );
    });
  }

  return {
    vehicleRecords: await find(vehiclesQuery),
    vehicleRecordsCount: await count(vehiclesQuery),
  };
}

/**
 * @memberof module:EquipmentGroup
 * @description Fetch all Trailer records for a given EquipmentGroup record
 * 
 * @param {object} equipmentGroupRecord EquipmentGroup record
 * @param {number} page The page number for pagination
 * @param {number} limit The limit of items for pagination
 * @param {object} sortBy Sort sbObject applied to both vehicles and trailers
 * @param {array} filters Array of filter sbObjects
 * 
 * @returns {object} Object containing array of Trailer records and count
 */
async function getEquipmentGroupTrailers(equipmentGroupRecord, page = 0, limit = 15, sortBy = new Sort('unitId', QuerySortOrderTypes.DESCENDING), filters = []) {
  const equipmentQuery = createQuery(EQUIPMENT_GROUP_EQUIPMENT_TABLE);

  setQueryRestriction(equipmentQuery, QueryRestrictionTypes.EQUAL_TO, 'equipmentGroup', equipmentGroupRecord);
  setQueryRestriction(equipmentQuery, QueryRestrictionTypes.EXISTS, 'trailer');

  const trailersQuery = createQuery('Trailer');

  setQueryRestriction(trailersQuery, QueryRestrictionTypes.MATCHES_KEY_IN_QUERY, 'objectId', equipmentQuery, 'trailer.objectId');
  setQueryRestriction(trailersQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  setQueryRestriction(trailersQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
  sortQuery(trailersQuery, sortBy.order, sortBy.attribute);

  if (filters.length !== 0) {
    filters.forEach(filterObject => {
      setQueryRestriction(
        trailersQuery,
        filterObject.queryRestriction,
        filterObject.attribute,
        filterObject.value,
      );
    });
  }

  return {
    trailerRecords: await find(trailersQuery),
    trailerRecordsCount: await count(trailersQuery),
  };
}

/**
 * @memberof module:EquipmentGroup
 * @description Fetch all User records for a given EquipmentGroup record
 * 
 * @param {object} equipmentGroupRecord EquipmentGroup record
 * @param {number} page The page number for pagination
 * @param {number} limit The limit of items for pagination
 * @param {object} sortBy Sort sbObject applied to both vehicles and trailers
 * @param {array} filters Array of filter sbObjects
 * 
 * @returns {object} Object containing array of User records and count
 */
async function getEquipmentGroupUsers(equipmentGroupRecord, page = 0, limit = 15, sortBy = new Sort('firstName', QuerySortOrderTypes.DESCENDING), filters = []) {
  const groupUsersQuery = createQuery(EQUIPMENT_GROUP_USERS_TABLE);
  setQueryRestriction(groupUsersQuery, QueryRestrictionTypes.EQUAL_TO, 'equipmentGroup', equipmentGroupRecord);

  const usersQuery = createQuery('_User');
  setQueryRestriction(usersQuery, QueryRestrictionTypes.MATCHES_KEY_IN_QUERY, 'objectId', groupUsersQuery, 'user.objectId');
  setQueryRestriction(usersQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  setQueryRestriction(usersQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);

  sortQuery(usersQuery, sortBy.order, sortBy.attribute);

  if (filters.length !== 0) {
    filters.forEach(filterObject => {
      setQueryRestriction(
        usersQuery,
        filterObject.queryRestriction,
        filterObject.attribute,
        filterObject.value,
      );
    });
  }

  return {
    userRecords: await find(usersQuery),
    userRecordsCount: await count(usersQuery),
  };
}

/**
 * @memberof module:EquipmentGroup
 * @description Adds a user to the specified EquipmentGroup
 * 
 * @param {object} equipmentGroupRecord EquipmentGroup record
 * @param {object} userRecord User record
 * 
 * @returns {object} EquipmentGroupUser record
 */
async function addUserToEquipmentGroupUser(equipmentGroupRecord, userRecord) {
  return await addRecord(EQUIPMENT_GROUP_USERS_TABLE, {
    equipmentGroup: equipmentGroupRecord,
    user: userRecord,
  });
}

/**
 * @memberof module:EquipmentGroup
 * @description Create EquipmentGroup record for the current user with the given name and add their relation
 * 
 * @param {string} name Name of equipment group
 * 
 * @returns {object} EquipmentGroup record
 */
async function createEquipmentGroup(name) {
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

  const equipmentGroup = await addRecord(EQUIPMENT_GROUP_TABLE, {
    name,
    belongsToCompany,
    createdBy: currentUser,
    numberOfVehicles: 0,
    numberOfTrailers: 0,
  });

  await addUserToEquipmentGroupUser(equipmentGroup, currentUser);

  return equipmentGroup;
}

/**
 * @memberof module:EquipmentGroup
 * @description Edit the attributes of a given EquipmentGroup record with a key-value object
 * 
 * @param {object} equipmentGroupRecord EquipmentGroup record
 * @param {object} keyValueObj The key-value object of changes
 * 
 * @returns Updated EquipmentGroup record
 */
async function updateEquipmentGroup(equipmentGroupRecord, keyValueObj) {
  if (keyValueObj && keyValueObj.name !== undefined) {
    keyValueObj.name = keyValueObj.name.trim();
  }
  return await updateRecord(equipmentGroupRecord, keyValueObj, true);
}

/**
 * @memberof module:EquipmentGroup
 * @description Adds a vehicle or trailer to the given EquipmentGroup record
 * 
 * @param {object} equipmentGroupRecord EquipmentGroup record
 * @param {object} vehicleRecord Vehicle record
 * @param {object} trailerRecord Trailer record
 * 
 * @returns {object} Object containing the number of vehicles and trailers
 */
async function addEquipmentToEquipmentGroup(equipmentGroupRecord, vehicleRecord, trailerRecord) {
  if (!equipmentGroupRecord) {
    throw new Error('Please provide a group.');
  }
  
  if (vehicleRecord) {
    await addRecord(EQUIPMENT_GROUP_EQUIPMENT_TABLE, {
      vehicle: vehicleRecord,
      equipmentGroup: equipmentGroupRecord,
    });
    await increment(equipmentGroupRecord, 'numberOfVehicles', true);
  }

  if (trailerRecord) {
    await addRecord(EQUIPMENT_GROUP_EQUIPMENT_TABLE, {
      trailer: trailerRecord,
      equipmentGroup: equipmentGroupRecord,
    });
    await increment(equipmentGroupRecord, 'numberOfTrailers', true);
  }

  return {
    numberOfVehicles: getAttribute(equipmentGroupRecord, 'numberOfVehicles'),
    numberOfTrailers: getAttribute(equipmentGroupRecord, 'numberOfTrailers'),
  }
}

/**
 * @memberof module:EquipmentGroup
 * @description Destroys an EquipmentGroup record (by removing all relations to users and vehicles/trailers)
 * 
 * @param {object} equipmentGroupRecord EquipmentGroup record
 * 
 * @returns {object} Destroyed EquipmentGroup record
 */
async function removeEquipmentGroup(equipmentGroupRecord) {
  if (!equipmentGroupRecord) return undefined;

  // Get all the equipment and users related to the group to be removed
  const userRelationQuery = createQuery(EQUIPMENT_GROUP_USERS_TABLE);
  setQueryRestriction(userRelationQuery, QueryRestrictionTypes.EQUAL_TO, 'equipmentGroup', equipmentGroupRecord);

  const equipmentRelationQuery = createQuery(EQUIPMENT_GROUP_EQUIPMENT_TABLE);
  setQueryRestriction(equipmentRelationQuery, QueryRestrictionTypes.EQUAL_TO, 'equipmentGroup', equipmentGroupRecord);

  const [relatedUsers, relatedEquipment] = await Promise.all([
    find(userRelationQuery, false, true),
    find(equipmentRelationQuery, false, true),
  ]);

  // TODO verify these checks are valid on failed Promise
  // If we have related equipment or users, destroy them all
  const destructionPromises = [];
  if (relatedUsers && relatedUsers.length && relatedUsers.length > 0) {
    destructionPromises.push(destroyRecords(relatedUsers));
  }
  if (relatedEquipment && relatedEquipment.length && relatedEquipment.length > 0) {
    destructionPromises.push(destroyRecords(relatedEquipment));
  }

  await Promise.all(destructionPromises);

  // Finally destroy the record. Could be merged with upper but if that fails we don't want this to run
  return await destroyRecord(equipmentGroupRecord);
}

/**
 * @memberof module:EquipmentGroup
 * @description Destroys an EquipmentGroupUser record (by removing user from the equipment group)
 * 
 * @param {object} equipmentGroupRecord EquipmentGroup record
 * @param {object} userRecord User record
 * 
 * @returns {object} Destroyed EquipmentGroupUser record
 */
async function removeUserFromEquipmentGroup(equipmentGroupRecord, userRecord) {
  const recordToDestroyQuery = createQuery(EQUIPMENT_GROUP_USERS_TABLE);
  setQueryRestriction(recordToDestroyQuery, QueryRestrictionTypes.EQUAL_TO, 'user', userRecord);
  setQueryRestriction(recordToDestroyQuery, QueryRestrictionTypes.EQUAL_TO, 'equipmentGroup', equipmentGroupRecord);

  const recordToDestroy = await find(recordToDestroyQuery, true);

  if (!recordToDestroy) return undefined;

  return await destroyRecord(recordToDestroy);
}

/**
 * @memberof module:EquipmentGroup
 * @description Destroys an EquipmentGroupEquipment record (by removing vehicle/trailer from the equipment group)
 * 
 * @param {object} equipmentGroupRecord EquipmentGroup record
 * @param {object} equipmentRecord Vehicle/Trailer record
 * @param {string} equipmentClassName Indicate if equipment is of className 'Vehicle' or 'Trailer'
 * 
 * @returns Destroyed EquipmentGroupEquipment record
 */
async function removeEquipmentFromEquipmentGroup(equipmentGroupRecord, equipmentRecord, equipmentClassName) {
  if (equipmentClassName !== 'Vehicle' && equipmentClassName !== 'Trailer') {
    return new Error(`${equipmentClassName} is not a valid type of equipment to destroy`);
  }

  let counterAttribute;
  const recordToDestroyQuery = createQuery(EQUIPMENT_GROUP_EQUIPMENT_TABLE);

  if (equipmentClassName === 'Vehicle') {
    setQueryRestriction(recordToDestroyQuery, QueryRestrictionTypes.EQUAL_TO, 'vehicle', equipmentRecord);
    counterAttribute = 'numberOfVehicles';
  } else {
    setQueryRestriction(recordToDestroyQuery, QueryRestrictionTypes.EQUAL_TO, 'trailer', equipmentRecord);
    counterAttribute = 'numberOfTrailers';
  }

  setQueryRestriction(recordToDestroyQuery, QueryRestrictionTypes.EQUAL_TO, 'equipmentGroup', equipmentGroupRecord);

  const recordToDestroy = await find(recordToDestroyQuery, true);

  if (!recordToDestroy) return undefined;

  const deletedRecord = await destroyRecord(recordToDestroy);
  await decrement(equipmentGroupRecord, counterAttribute, true);

  return deletedRecord;
}

export {
  getEquipmentGroupByObjectId,
  getEquipmentGroupsForCurrentUser,
  getEquipmentGroupVehicles,
  getEquipmentGroupTrailers,
  getEquipmentGroupUsers,
  addUserToEquipmentGroupUser,
  createEquipmentGroup,
  updateEquipmentGroup,
  addEquipmentToEquipmentGroup,
  removeEquipmentGroup,
  removeUserFromEquipmentGroup,
  removeEquipmentFromEquipmentGroup,
};