import React from 'react';
import PropTypes from 'prop-types';

// Components
import Title from 'components/LayoutTitle/view/Title';
import TripSummary from './TripSummary';


class ACEModule extends React.Component {
  constructor(props) {
    super(props);
    this.cancelChanges = this.cancelChanges.bind(this);
  }

  cancelChanges() {
    // Handler to cancel all changes made to the emanifest
    this.props.cancelChanges();
  }

  render() {
    return (
      <div>
        <Title title={<div>ACE eMANIFEST</div>} className="textTransformNone">
          <span className="inlineBlock">
            <button
              className="buttonDefault buttonSpacedRight"
            >
              Save <span className="textTransformNone">eMANIFEST</span>
            </button>
            <button
              className="buttonDefault"
              onClick={this.cancelChanges}
            >
              Cancel <span className="textTransformNone">eMANIFEST</span>
            </button>
          </span>
        </Title>

        <TripSummary />
      </div>
    );
  }
}

ACEModule.propTypes = {
  // params: PropTypes.object,
  cancelChanges: PropTypes.func,
};

export default ACEModule;
