/* eslint-disable max-len */
// API
import { find } from 'api/Parse';
import { getCurrentUser } from 'api/Getters';

// Enums
import { QueryRestrictionTypes } from 'enums/Query';
import { QuerySortOrder, QueryRestriction } from 'sb-csapi/dist/enums/Query';

// CSAPI
import {
  copyQuery, count, createQuery, createQueryOr, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, addRecord, destroyRecord,
} from 'sb-csapi/dist/AAPI';

// Sort
import Sort from 'sb-csapi/dist/sbObjects/Sort';

// Filter
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/** @module User */

/**
 * @memberof module:User
 * 
 * @description Fetches the top 10 users matching the search term from company
 * @param {string} searchTerm The search term
 * @returns Array of users
 */
async function getSuggestedUsers(searchTerm, limit = 10) {
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

  const userQuery = createQuery('_User');
  setQueryRestriction(userQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
  setQueryRestriction(userQuery, QueryRestrictionTypes.MATCHES, 'firstName', searchTerm);
  setQueryRestriction(userQuery, QueryRestrictionTypes.LIMIT, undefined, limit);

  const userRecords = await find(userQuery);

  return userRecords;
}

/**
 * @memberof module:User
 * @description Obtains users from a company
 * @param {object} options - See example
 * @param {string} companyObjectId - Company we wish to retrieve users for
 * @param {bool} includeChildCompanies - Include child/sub-companies of this parent company
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {int} page - The current page for pagination
 * @param {int} limit - The limit of records obtained per pagination
 * @param {bool} queryAll - Get all records
 *
 * @returns {object} - { totalUsersCount: 0, users: [] }
 */
 async function getUsers(options = { sessionToken: getCurrentUserSessionToken() }, companyObjectId = getCurrentUserCompanyObjectId(), includeChildCompanies, filters = [], sortBy = new Sort(QuerySortOrder.ASCENDING, 'firstName'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  let userQuery = createQuery('_User');

  const _filters = [...filters];

  const companyQuery = createQuery('Company');
  setQueryRestriction(companyQuery, QueryRestriction.EQUAL_TO, 'objectId', companyObjectId);

  if (includeChildCompanies) {
    // if getting child companies' users, the additional queries
    const companyLinkQuery = createQuery('CompanyLink');

    const companyLinkFilters = [
      new Filter(QueryRestriction.MATCHES_QUERY, 'parentCompany', companyQuery),
      new Filter(QueryRestriction.EQUAL_TO, 'authorized', true),
    ];

    companyLinkFilters.map(filter => setQueryRestriction(companyLinkQuery, filter.queryRestriction, filter.attribute, filter.value));

    // now that we have all the child companies, create queries that get the users of them
    const companyLinks = await findRecords(options, companyLinkQuery);
    const childCompanyUserQueries = companyLinks.map(company => {
      const childCompanyUserQuery = createQuery('_User');
      setQueryRestriction(childCompanyUserQuery, QueryRestriction.EQUAL_TO, 'belongsToCompany', getAttribute(company, 'childCompany'));
      return childCompanyUserQuery;
    });

    // the main current user's company's user query
    const mainCompanyUserQuery = createQuery('_User');
    setQueryRestriction(mainCompanyUserQuery, QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery);

    // altogether
    userQuery = createQueryOr([mainCompanyUserQuery, ...childCompanyUserQueries]);

  } else {
    // lock queries to the current users company
    setQueryRestriction(userQuery, QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery);
  }

  // set universal filters
  // _filters.push(new Filter(QueryRestriction.NOT_EQUAL_TO, 'isHidden', true));

  // mainQueryFilters are filters we want to apply to the entire/end-game query of multiple subqueries (ie. The parent query of all "OR"-subqueries)
  let mainQueryFilters = [
    new Filter(QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery),
  ];
  let secondaryUserQuery; // defined if an OR query is to be created to account firstName and lastName search

  _filters.find(filter => {
    if ((filter.attribute === 'firstName' || filter.attribute === 'lastName') && filter.value) {
      secondaryUserQuery = copyQuery(userQuery, true); // copy everything the userQuery was up to this point
      setQueryRestriction(secondaryUserQuery, QueryRestriction.MATCHES, filter.attribute === 'firstName' ? 'lastName' : 'firstName', filter.value);
      return true;
    }
    return false;
  });

  _filters.forEach(filter => {
    setQueryRestriction(userQuery, filter.queryRestriction, filter.attribute, filter.value);

    if (secondaryUserQuery && (filter.attribute !== 'firstName') && (filter.attribute !== 'lastName')) {
      // if an OR query exists for firstName/lastName, apply the very same filters as the first query (barring restrictions to firstName/lastName)
      setQueryRestriction(secondaryUserQuery, filter.queryRestriction, filter.attribute, filter.value);

      if (filter.queryRestriction === QueryRestriction.LIMIT) {
        mainQueryFilters.push(new Filter(filter.queryRestriction, undefined, filter.value));
      }
    }
  });

  if (secondaryUserQuery) {
    userQuery = createQueryOr([userQuery, secondaryUserQuery]);
    mainQueryFilters.forEach((mainQueryFilter) => {
      setQueryRestriction(userQuery, mainQueryFilter.queryRestriction, mainQueryFilter.attribute, mainQueryFilter.value);
    });
  }

  // at this point, copy current query to get the number of pages for pagination
  const userCountQuery = copyQuery(userQuery);

  // sort
  sortQuery(userQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(userQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(userQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(userQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(userQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the count and the users
  const promises = [count(options, userCountQuery), findRecords(options, userQuery, false, queryAll)];

  try {
    const [totalUsersCount, users] = await Promise.all(promises);
    return { totalUsersCount, users };
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getSuggestedUsers,
  getUsers,
};
