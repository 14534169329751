/**
 * PDF Functionality to work on local machine (as opposed to server-side)
 */
import Parse from 'parse';
import moment from 'moment-timezone';

import uniqid from 'uniqid';
import jspdf from 'jspdf';
import { getCompanyReadWriteACL } from 'api/Helpers';
import { addRecord } from 'api/Setters';

import SBFile from 'sbObjects/SBFile';

/** @module PDFLocal */

/**
 * @memberof module:PDFLocal
 *
 * @param {string} filename
 * @param {object} base64Object - { base64: ... }
 */
async function createFile(filename = '', base64Object, timestamp = moment().valueOf()) {
  const _file = new Parse.File(`${filename.replace(/[^\w\s]/gi, '')}.pdf`, base64Object, '.pdf');
  return new SBFile(undefined, _file, filename, 'pdf', timestamp);
}

/**
 * @memberof module:PDFLocal
 *
 * @param {string} htmlString - stringed html we wish to conver to pdf
 * @param {string} filename - name of the file
 * @param {bool} returnDataURI - whether or not to return the data uri string; otherwise return the file
 */
async function createFromHTML(htmlString, filename, returnDataURI) {
  let dataURI;

  try {
    // attempt to generate the dataURI
    dataURI = await Parse.Cloud.run('generatePDFFromHTML', { htmlString, filename, returnDataURI: true });
  }
  catch (err) {
    console.log(err);
    throw new Error(err);
  }

  if (returnDataURI) {
    return dataURI;
  }

  const base64String = dataURI.split('base64,')[1];
  const file = await createFile(filename, { base64: base64String });
  return file;
}

export {
  createFile,
  createFromHTML,
};
