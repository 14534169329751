// libraries
import { useState } from 'react';
import moment from 'moment-timezone';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

// enums
import { ReminderTypesInt, ReminderTypesStr, FrequencyTypesInt, FrequencyTypesStr } from 'enums/SafetyRecurringReminder';

// components
import Dropdown from 'sbCore/Dropdown/Dropdown';
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';

function PastSafetyRecurringRemindersTable(props) {
  const [filters, setFilters] = useState({
    'name': { value: null, matchMode: 'contains' },
    'reminderType': { value: null, matchMode: 'equals' },
    'frequencyType': { value: null, matchMode: 'equals' },
  });

  const reminderTypeSelectItems = [
    { label: ReminderTypesInt[0], value: ReminderTypesStr.COMPANY },
    { label: ReminderTypesInt[1], value: ReminderTypesStr.DRIVER },
    { label: ReminderTypesInt[2], value: ReminderTypesStr.VEHICLE },
  ];

  const frequencyTypeSelectItems = [
    { label: FrequencyTypesInt[0], value: FrequencyTypesStr.ONE_TIME },
    { label: FrequencyTypesInt[1], value: FrequencyTypesStr.MONTHLY },
    { label: FrequencyTypesInt[2], value: FrequencyTypesStr.ANNUALLY },
  ];

  function reminderTypeFilter(options) {
    return (
      <Dropdown
        style={{ width: '100%' }}
        className="p-column-filter"
        value={options.value}
        options={reminderTypeSelectItems}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Reminder Type"
        style={{ display: 'flex' }}
      />
    )
  }

  function frequencyTypeFilter(options) {
    return (
      <Dropdown
        style={{ width: '100%' }}
        className="p-column-filter"
        value={options.value}
        options={frequencyTypeSelectItems}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="Frequency Type"
        style={{ display: 'flex' }}
      />
    )
  }

  return (
    <DataTable
      emptyMessage="No Reminders"
      loading={props.loading}
      value={props.safetyRecurringReminders}
      selection={props.selection}
      onSelectionChange={(e) => props.onSelectionChange(e.value)}
      paginator
      rows={10}
      filters={filters}
      filterDisplay="row"
      header={
        <ActionsContainer>
          <span style={{ marginLeft: '1em' }}>
            <Button
              label="Clone"
              disabled={props.selection.length === 0}
              onClick={props.handleClone}
            />
          </span>
          <span style={{ marginLeft: '1em' }}>
            <Button
              label="Delete"
              disabled={props.selection.length === 0}
              onClick={props.handleDelete}
            />
          </span>
        </ActionsContainer>
      }
    >
        <Column selectionMode="multiple"/>
        <Column
          field="name"
          header="Name"
          body={(rowData) => <span>{ rowData.name }</span>}
          filter
          showClearButton
          showApplyButton
          showFilterMenu
          filterPlaceholder="Name"
        />
        <Column
          field="alertAt"
          header="Alert At"
          body={(rowData) => <span>{ moment(rowData.triggerDate).format('ll - HH:mm') }</span>}
        />
        <Column
          field="reminderType"
          header="Reminder Type"
          body={(rowData) => <span>{ ReminderTypesInt[rowData.reminderType] }</span>}
          filter
          showClearButton
          showApplyButton
          showFilterMenu
          filterElement={reminderTypeFilter}
        />
        <Column
          field="frequencyType"
          header="Frequency Type"
          body={(rowData) => <span>{ FrequencyTypesInt[rowData.frequencyType] }</span>}
          filter
          showClearButton
          showApplyButton
          showFilterMenu
          filterElement={frequencyTypeFilter}
        />
      <Column
        field="notes"
        header="Notes"
        body={(rowData) => <textarea readOnly>{ rowData.notes }</textarea>}
      />
      <Column
        field="unresolve"
        header=""
        body={(rowData) => (
          <ActionsContainer>
            <Button
              label="Unresolve"
              onClick={async () => await props.handleReturn(rowData.safetyRecurringReminderRecord)}
            />
          </ActionsContainer>
        )}
      />
    </DataTable>
  );
}

export default PastSafetyRecurringRemindersTable;
