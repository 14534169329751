/* global StripeCheckout */
import moment from 'moment-timezone';
import store from 'store';
import * as Helpers from 'api/Helpers';
import * as Setters from 'api/Setters';
import * as Stripe from 'api/Stripe';
import * as ActionConstants from './ActionConstants';

export function isSubscriptionInProgress() {
  return store.getState().Subscription.status === ActionConstants.SUBSCRIPTION_CREATE_INPROGRESS;
}

export function subscriptionStatusSuccess(subscriptionStatusObj) {
  return {
    type: ActionConstants.SUBSCRIPTION_STATUS_SUCCESS,
    subscriptionStatusObj,
  };
}

export function getSubscriptionStatus(subscriptionParseObject, warningIgnore) {
  // If subscription exists & customer is out of 'free mode'
  if (subscriptionParseObject) {
    // If subscription is not currently registering
    if (!isSubscriptionInProgress()) {
      // Check if subscription is active
      const promise = new Promise((resolve, reject) => {
        if (subscriptionParseObject.get('isClosed')) {
          resolve({ eligibleSubscription: false, type: 'closed' });
        } else if (
          subscriptionParseObject.get('ignoreSubscriptionUntil') &&
          moment(subscriptionParseObject.get('ignoreSubscriptionUntil')).isAfter(moment())
        ) {
          resolve({ eligibleSubscription: true });
        } else if (
          subscriptionParseObject.get('ignoreSubscription')
        ) {
          resolve({ eligibleSubscription: true });
        } else {
          Stripe.checkSubscription(subscriptionParseObject).then((subscriptionStatusObj) => {
            if (
              !subscriptionStatusObj.active &&
              subscriptionStatusObj.status === 'past_due'
            ) {
              // If customerId exists and doesn't have an active subscription
              resolve({ eligibleSubscription: subscriptionStatusObj.active, type: 'past_due' });
            } else if (subscriptionParseObject.get('subscriptionId') && !subscriptionStatusObj.active) {
              // If customerId exists and doesn't have an active subscription
              Stripe.getCurrencyFromCustomer(subscriptionParseObject).then((currency) => {
                if (currency) {
                  resolve({ eligibleSubscription: subscriptionStatusObj.active, type: 'inactiveWithValidCreditCard' });
                } else {
                  resolve({ eligibleSubscription: subscriptionStatusObj.active, type: 'inactiveWithInvalidCreditCard' });
                }
              });
              // resolve({ eligibleSubscription: subscriptionStatusObj.active, type: 'inactive' });
            } else if (subscriptionParseObject.get('documentsAdded') > subscriptionParseObject.get('documentsSupported')) {
              // If documents added is less than documents supported
              resolve({ eligibleSubscription: subscriptionStatusObj.active, type: 'documentsOverLimit' });
            } else if (subscriptionParseObject.get('documentsAdded') > (subscriptionParseObject.get('documentsSupported') - (subscriptionParseObject.get('driversAdded') * 2)) && !warningIgnore) {
              // If documents added is less than documents supported
              resolve({ eligibleSubscription: subscriptionStatusObj.active, type: 'documentsLimitWarning' });
            } else if (subscriptionParseObject.get('driversAdded') > subscriptionParseObject.get('driversSupported')) {
              // If drivers added is less than drivers supported
              resolve({ eligibleSubscription: subscriptionStatusObj.active, type: 'driversOverLimit' });
            } else if (subscriptionParseObject.get('driversAdded') > (subscriptionParseObject.get('driversSupported') - 5) && !warningIgnore) {
              resolve({ eligibleSubscription: subscriptionStatusObj.active, type: 'driversLimitWarning' });
            } else if (
              subscriptionStatusObj.status === 'nocustomer' ||
              subscriptionStatusObj.status === 'nosubscription' ||
              (!subscriptionParseObject.get('customerId') && !subscriptionStatusObj.active)
            ) {
              // If not active, and there isn't a customerId (first time)
              resolve({ eligibleSubscription: subscriptionStatusObj.active, type: 'nonexist' });
            }
            // else if (
            //   window.location.host.indexOf('app-') !== -1 &&
            //   subscriptionParseObject.get('startSubscription') &&
            //   subscriptionParseObject.get('ignoreSubscriptionUntil') &&
            //   moment(subscriptionParseObject.get('ignoreSubscriptionUntil')).isBefore(moment().subtract(3, 'months'))
            // ) {
            //   resolve({ eligibleSubscription: false, type: 'past_due' });
            // } 
            else {
              // Passes
              resolve({ eligibleSubscription: subscriptionStatusObj.active, type: undefined });
            }
            store.dispatch(subscriptionStatusSuccess(subscriptionStatusObj));
          }).catch((err) => {
            console.log(err);
            reject(err);
          });
        }
      });
      return promise;
    }
  }
  return Promise.resolve({ eligibleSubscription: true, type: undefined });
}

export function cancelSubscription(subscriptionParseObj) {
  const promise = new Promise((resolve, reject) => {
    Stripe.cancelSubscription(subscriptionParseObj).then((updatedSubscriptionParseObj) => {
      resolve(updatedSubscriptionParseObj);
      getSubscriptionStatus(subscriptionParseObj, store.getState().Subscription.driverWarningIgnore);
    });
  });
  return promise;
}

export function fetchingSubscription() {
  return {
    type: ActionConstants.FETCH_SUBSCRIPTION_INPROGRESS,
  };
}

export function fetchSubscriptionSuccess(subscription) {
  return {
    type: ActionConstants.FETCH_SUBSCRIPTION_SUCCESS,
    subscription,
  };
}

export function fetchSubscriptionError(error) {
  return {
    type: ActionConstants.FETCH_SUBSCRIPTION_ERROR,
    error,
  };
}

export function subscribingCustomer() {
  return {
    type: ActionConstants.SUBSCRIPTION_CREATE_INPROGRESS,
  };
}
export function subscribeCustomerSuccess(updatedSubscriptionParseObj) {
  return {
    type: ActionConstants.SUBSCRIPTION_CREATE_SUCCESS,
    subscription: updatedSubscriptionParseObj,
  };
}

export function addSourceToCustomer(stripeToken, subscriptionParseObject) {
  // JW
  const promise = new Promise((resolve, reject) => {
    Stripe.addSourceToCustomer(stripeToken, subscriptionParseObject).then((subscriptionParseObject) => {
      resolve();
    });
  });
  return promise;
}


export function subscribeCustomerToPlans(subscriptionParseObj) {
  // JW
  // const promise = new Promise((resolve, reject) => {
  //   Stripe.subscribeCustomerToPlans(subscriptionParseObj).then((stripeSubscription) => {
  //     resolve();
  //   });
  // });
  // return promise;
}

// export function checkRenewStatus(subscriptionParseObject) {
//   // JW
//   if (subscriptionParseObject) {
//     // If subscription is not currently registering
//     if (!isSubscriptionInProgress()) {
//       const promise = new Promise((resolve, reject) => {
//         if (subscriptionParseObject.get('customerId')) {
//           Stripe.getCurrencyFromCustomer(subscriptionParseObject).then((currency) => {
//             if (currency) {
//               resolve({ type: 'validCreditCardNCustomerId' });
//             } else {
//               resolve({ type: 'invalidCreditCard' });
//             }
//           });
//         } else { resolve({ type: 'nonCustomerId' }); }
//       });
//       return promise;
//     }
//   }
//   return Promise.resolve({ type: undefined });
// }

export function getCurrencyFromCustomer(subscriptionParseObj) {
  // JW
  const promise = new Promise((resolve, reject) => {
    Stripe.getCurrencyFromCustomer(subscriptionParseObj).then((currency) => {
      resolve(currency);
    });
  });
  return promise;
}

export function getMonthlyCharge(subscriptionParseObj) {
  // JW
  const promise = new Promise((resolve, reject) => {
    Stripe.getMonthlyCharge(subscriptionParseObj).then((monthlyCharge) => {
      resolve(monthlyCharge);
    });
  });
  return promise;
}

export function renewSubscription(subscriptionParseObj) {
  const promise = new Promise((resolve, reject) => {
    Stripe.renewSubscription(subscriptionParseObj).then((updatedSubscriptionParseObj) => {
      resolve(updatedSubscriptionParseObj);
      store.dispatch(subscribeCustomerSuccess(updatedSubscriptionParseObj));
      getSubscriptionStatus(subscriptionParseObj, store.getState().Subscription.driverWarningIgnore);
    });
  });
  return promise;
}

export function warningIgnoreTrigger() {
  store.dispatch({ type: ActionConstants.SUBSCRIPTION_WARNING_IGNORE });
}
