import * as ActionConstants from 'actions/ActionConstants';
import store from 'store';
// api
import * as Destroyers from 'api/Destroyers';
import * as Parse from 'api/Parse';
import * as Setters from 'api/Setters';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';

function fetchingJobLinks() {
  return {
    type: ActionConstants.FETCH_JOBLINKS_INPROGRESS,
  };
}

function fetchJobLinksSuccess(jobLinks) {
  return {
    type: ActionConstants.FETCH_JOBLINKS_SUCCESS,
    jobLinks,
  };
}

function fetchJobLinksError(error) {
  return {
    type: ActionConstants.FETCH_JOBLINKS_ERROR,
    error,
  };
}

function deleteJobLinks() {
  return {
    type: ActionConstants.DELETE_JOBLINKS,
  };
}

export function fetchJobLinksForState(page, limit, filter, sort) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingJobLinks());
    Getters.getAllJobLinks(page, limit, filter, sort).then(
      jobLinks => {
        resolve(jobLinks);
        store.dispatch(fetchJobLinksSuccess(jobLinks));
      },
      error => {
        reject(error);
        store.dispatch(fetchJobLinksError(error));
      }
    );
  });
  return promise;
}

export function deleteJobLinksForState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteJobLinks());
    resolve(store.getState().JobLink);
  });
  return promise;
}

function addingJobLink() {
  return {
    type: ActionConstants.ADD_JOBLINK_INPROGRESS,
  };
}

function addJobLinkSuccess(jobLink) {
  return {
    type: ActionConstants.ADD_JOBLINK_SUCCESS,
    jobLink,
  };
}

function addJobLinkError(error) {
  return {
    type: ActionConstants.ADD_JOBLINK_ERROR,
    error,
  };
}

export function addJobLinkToState(jobLinkObj) {
  return store.dispatch(addJobLinkSuccess(jobLinkObj));
}

function destroyJobLinks(jobLinks) {
  return {
    type: ActionConstants.DESTROY_JOBLINKS_SUCCESS,
    jobLinks,
  };
}
function destroyJobDocuments(documents) {
  return {
    type: ActionConstants.DESTROY_DOCUMENTS_SUCCESS,
    documents,
  };
}

export function assignJobActionsToDriver(driver, jobLink) {
  // function to assign jobActions of a jobLink to a driver's jobActions
  const promise = new Promise((resolve, reject) => {
    let updatedJobActions = [];
    const jobActions = jobLink.get('jobActions');
    const driverJobActions = driver.get('jobActions');

    if (driverJobActions) {
      // if there are existing driverJobActions
      updatedJobActions = [].concat(driverJobActions, jobActions);
    } else {
      // driverJobActions is undefined or empty
      updatedJobActions = [].concat(jobActions);
    }

    Setters.setDriverStatusActive(driver);
    driver.set('jobActions', updatedJobActions).save().then(
      () => {
        resolve(updatedJobActions);
      },
      error => reject(error)
    );
  });
  return promise;
}

export function destroyJobLink(jobLink) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(destroyJobLinks([jobLink]));
    const jobActionsToBeDestroyed = jobLink.get('jobActions');
    const jobDriversToBeDestroyed = Getters.getAllJobDriversForJobActions(jobLink.get('jobActions'));
    const jobDocumentsToBeDestroyed = [];
    const jobEntitiesToBeDestroyed = [];
    const removePromises = [];
    const jobActionToBeDestroyed = jobActionsToBeDestroyed[0];

    // send out notifications and driver tasks
    Setters.destroyJobLinkCallback(jobActionToBeDestroyed, jobLink, reject);
    // for deleting document object - need to check if it ONLY refers to jobActions being deleted before being deleted
    removePromises.push(Destroyers.removeJobActionsFromDocuments(jobActionsToBeDestroyed));
    // JobEntity object - need to remove jobAction from jobEntity + delete if jobEntity is empty
    removePromises.push(Destroyers.removeJobActionsFromJobEntity(jobActionsToBeDestroyed));

    Promise.all(removePromises).then((responses) => {
      const documentArr = responses[0];
      const jobEntityArr = responses[0];
      const documentArrLen = documentArr.length;
      for (let i = 0; i < documentArrLen; i++) {
        if (!documentArr[i].get('jobAction') || documentArr[i].get('jobAction').length === 0) {
          jobDocumentsToBeDestroyed.push(documentArr[i]);
          Destroyers.destroyDriverTasksAndNotification(documentArr[i]);
        }
      }
      const jobEntityArrLen = jobEntityArr.length;
      for (let i = 0; i < jobEntityArrLen; i++) {
        if (!jobEntityArr[i].get('jobArray') || jobEntityArr[i].get('jobArray').length === 0) {
          jobEntitiesToBeDestroyed.push(jobEntityArr[i]);
        }
      }
      let objectsToBeDestroyedArr = [].concat(...[jobLink, jobActionsToBeDestroyed, jobDriversToBeDestroyed, jobDocumentsToBeDestroyed, jobEntitiesToBeDestroyed]);
      // For deleting notifications: const objectsToBeDestroyedArrLen = objectsToBeDestroyedArr.length;
      // // Get objectIds of Job Action & Job Documents for getting notifications
      // const ids = [];
      // for (let i = 0; i < objectsToBeDestroyedArrLen; i++) {
      //   ids.push(objectsToBeDestroyedArr[i].id);
      // }
      // objectsToBeDestroyedArr = [].concat(...[objectsToBeDestroyedArr, notificationsToBeDestroyed]);
      // Destroy jobLink, jobActions, jobDriver, documents (only related to jobActions), jobEntities (only related to jobActions)
      Parse.destroyRecords(objectsToBeDestroyedArr);
      Destroyers.destroyDriverTasksAndNotification(jobLink);
      // For deleting notifications
      store.dispatch(destroyJobDocuments(jobDocumentsToBeDestroyed));
      // store.dispatch(destroyJobActions(jobActionsToBeDestroyed));
      // store.dispatch(destroyJobDrivers(jobDriversToBeDestroyed));
      // store.dispatch(destroyNotifications(notificationsToBeDestroyed));
      // store.dispatch(destroyJobEntities(jobEntitiesToBeDestroyed));
      resolve();
    });
  });
  return promise;
}

function assignJobDriverAction(jobLink) {
  return {
    type: ActionConstants.JOBLINK_ASSIGN_DRIVER,
    jobLink,
  };
}

function unassignJobDriverAction(jobLink) {
  return {
    type: ActionConstants.JOBLINK_UNASSIGN_DRIVER,
    jobLink,
  };
}

export function assignDrivers(driverObjectArr, jobLink) {
  const promise = new Promise((resolve, reject) => {
    Setters.assignDriversToJobLink(driverObjectArr, jobLink).then(
      updatedJobLink => {
        resolve(updatedJobLink);
        store.dispatch(assignJobDriverAction(updatedJobLink));
      }
    );
    // const jobActions = jobLink.get('jobActions');
    // const promises = [];
    // const jobActionLen = jobActions.length;
    // const driver = driverParseObjectArr[0];
    // const coDriver = driverParseObjectArr[1];
    // promises.push(d(driver, jobLink));
    // if (coDriver) {
    //   promises.push(assignJobActionsToDriver(coDriver, jobLink));
    // }
    // for (let i = 0; i < jobActionLen; i++) {
    //   promises.push(Setters.assignNewDriversToJobAction(jobActions[i], driverParseObjectArr));
    // }
    // Promise.all(promises).then(() => {
    //   store.dispatch(assignJobDriverAction(jobLink));
    //   if (coDriver) {
    //     Parse.sendPush([driver.get('user'), coDriver.get('user')], `New Job with Id: ${jobActions[0].get('jobId')}`);
    //     Setters.addDriverTask('New Job', jobActions[0].get('jobId'), Parse.getCurrentUser(), undefined, [driver.get('user'), coDriver.get('user')], 'JobLink', jobLink.id);
    //   } else {
    //     Parse.sendPush([driver.get('user')], `New Job with Id: ${jobActions[0].get('jobId')}`);
    //     Setters.addDriverTask('New Job', jobActions[0].get('jobId'), Parse.getCurrentUser(), undefined, [driver.get('user')], 'JobLink', jobLink.id);
    //   }
    //   resolve(jobLink);
    // });
  });
  return promise;
}

export function unassignDrivers(jobLink) {
  const promise = new Promise((resolve, reject) => {
    const jobActions = jobLink.get('jobActions');

    // for now, and in parse cloud, we're under the assumption drivers are the same throughout all jobactions in a joblink
    const jobDriver = jobActions[0].get('jobDriver');
    const drivers = jobDriver.get('driver');

    Setters.unassignDriversFromJobLink(drivers, jobLink).then(
      updatedJobLink => {
        resolve(updatedJobLink);
        store.dispatch(unassignJobDriverAction(updatedJobLink));
      }
    );
  });

  return promise;
}

function completeJobLinks(jobLinks) {
  return {
    type: ActionConstants.JOBLINKS_COMPLETED_SUCCESS,
    jobLinks,
  };
}

export function closeJobLink(jobLink) {
  const promise = new Promise((resolve, reject) => {

    Setters.closeJobLink(jobLink.id).then(
      updatedJobLink => {
        resolve(updatedJobLink);
        store.dispatch(completeJobLinks([updatedJobLink]));
      }
    );
    // const jobDriver = jobLink.get('jobActions')[0].get('jobDriver');

    // if (jobDriver) {
    //   const driver = jobDriver.get('driver')[0];
    //   const coDriver = jobDriver.get('driver')[1];

    //   Setters.unassignJobActionsFromDriver(driver, jobLink).then(
    //     undefined,
    //     error => reject(error)
    //   );

    //   if (coDriver && (coDriver.id !== driver.id)) {
    //     Setters.unassignJobActionsFromDriver(coDriver, jobLink).then(
    //       undefined,
    //       error => reject(error)
    //     );
    //   }
    // }

    // jobLink.set('jobLinkStatusInt', 2);
    // jobLink.save().then(
    //   () => {
    //     resolve(jobLink);
    //   },
    //   error => reject(error)
    // );
    // // resolve(jobLink);
    // store.dispatch(completeJobLinks([jobLink]));
  });
  return promise;
}
