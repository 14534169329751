import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Components
import AddEditButton from 'components/AddEditModal/container/AddEditButton'
// Actions
import { addTrailerToState } from 'actions/Trailer'
import { updateVehicleForState } from 'actions/Vehicle'
import * as ActionConstants from 'actions/ActionConstants'
// API
import * as Setters from 'api/Setters'
import * as Getters from 'api/Getters'
import { isTrackerUsed } from 'api/Equipment/Trailer.old'
import StoreContext from 'contexts/StoreContext'

function getInitialState () {
  const initialState = {}
  initialState.errors = []
  initialState.addTrailerFormFields = [
    [
      {
        label: 'Unit ID',
        value: '',
        type: 'text',
        required: true,
        property: 'unitId',
        limit: '10'
      }
    ],
    [
      {
        label: 'MVI Decal #',
        value: '',
        type: 'text',
        property: 'MVIDecalNumber'
      },
      {
        label: 'Next Inspection Date',
        value: null,
        type: 'date',
        property: 'nextInspectionDate'
      },
      {
        label: 'Inspection Frequency (months)',
        value: '',
        type: 'number',
        property: 'inspectionFrequency'
      }
    ],
    [
      { label: 'VIN #', value: '', type: 'text', property: 'vin' },
      { label: 'License Plate', value: '', type: 'text', property: 'plate' },
      { label: 'Type of Trailer', value: '', type: 'text', property: 'type' }
    ],
    // [{ label: 'Current Location', value: null, type: 'chooseOrAdd', property: 'vendor', displayProperty: 'name' }],
    [
      {
        label: 'Tracker Identifier',
        value: '',
        type: 'text',
        property: 'tc_devices_uniqueid'
      },
      {
        label: 'Temperature Upper Bound (Celsius)',
        value: null,
        type: 'number',
        property: 'tempUpperBoundCelsius'
      },
      {
        label: 'Temperature Lower Bound (Celsius)',
        value: null,
        type: 'text',
        property: 'tempLowerBoundCelsius'
      }
    ],
    [{ label: 'Notes', value: '', type: 'textarea', property: 'notes' }],
  ];
  return initialState
}

class AddNewTrailerButton extends React.Component {
  constructor (props) {
    super(props)
    this.state = getInitialState()
    this.addTrailer = this.addTrailer.bind(this)
    this.checkOverwriteVehicle = this.checkOverwriteVehicle.bind(this)
    this.refreshState = this.refreshState.bind(this)
  }

  checkOverwriteVehicle (vehicleParseObj) {
    // hack for driverArr --> one driver
    if (vehicleParseObj.get('trailer')) {
      const overwriteMessage = (
        <div>
          <div>
            Vehicle {vehicleParseObj.get('unitId')} is currently attached to
            trailer <b>{vehicleParseObj.get('trailer').get('unitId')}</b>.
          </div>
          <div>
            {' '}
            Are you sure you want to detach{' '}
            {vehicleParseObj.get('trailer').get('unitId')} to from{' '}
            {vehicleParseObj.get('unitId')}?
          </div>
        </div>
      )
      this.setState({ ...this.state, overwriteMessage })
      return true
    }
    return false
  }

  addTrailer (formValues) {
    const promise = new Promise((resolve, reject) => {
      const trailerValuesObject = { ...formValues }
      const unitId = (trailerValuesObject.unitId || '').trim();
      Getters.queryCompanyObjects(
        'Trailer',
        undefined,
        undefined,
        [
          {
            queryType: 'equalTo',
            name: 'unitId',
            value: unitId,
          },
          { queryType: 'equalTo', name: 'enabled', value: true }
        ],
        undefined,
        undefined,
        true,
        false
      ).then(async trailerParseObj => {
        if (trailerParseObj) {
          return reject(`Trailer with unitId ${unitId} already exists`);
        }

        if (trailerValuesObject.vehicle) {
          trailerValuesObject.vehicle_unitId = trailerValuesObject.vehicle.get(
            'unitId'
          )
          if (trailerValuesObject.vehicle_unitId) {
            trailerValuesObject.vehicle_unitId = trailerValuesObject.vehicle_unitId.toLowerCase()
          }
        }
        if (trailerValuesObject.vendor) {
          trailerValuesObject.vendor_name = trailerValuesObject.vendor.get(
            'name'
          )
        }
        trailerValuesObject.enabled = true
        isTrackerUsed(trailerValuesObject.tc_devices_uniqueid)
          .then(result => {
            if (result === false) {
              Setters.createNewTrailer(trailerValuesObject)
                .then(newTrailerParseObj => {
                  addTrailerToState(newTrailerParseObj)
                  if (trailerValuesObject.vehicle) {
                    updateVehicleForState(
                      trailerValuesObject.vehicle,
                      'trailer',
                      newTrailerParseObj
                    )
                    if (trailerParseObj.get('unitId')) {
                      updateVehicleForState(
                        trailerValuesObject.vehicle,
                        'trailer_unitId',
                        newTrailerParseObj.get('unitId').toLowerCase()
                      )
                    }
                  }
                  this.setState(getInitialState())
                  resolve()
                })
                .catch(error => {
                  reject(error.message)
                })
            } else {
              this.setState({ ...this.state, error: result }, () => {
                reject(result)
              })
            }
          })
          .catch(error => {
            reject("Can't check tracker.")
          })
      })
    })
    return promise
  }

  refreshState () {
    this.setState(getInitialState())
  }

  render () {
    return (
      <AddEditButton
        className={this.props.className}
        subtitle="* FMCSA requires Unit IDs to be less than or equal to 10 characters"
        refreshState={this.refreshState}
        handleAddEdit={this.addTrailer}
        error={this.state.error}
        title='Trailer'
        formTemplate={this.state.addTrailerFormFields}
        addingInProgress={
          this.props.Trailer.status === ActionConstants.ADD_TRAILER_INPROGRESS
        }
      />
    )
  }
}

AddNewTrailerButton.propTypes = {
  Trailer: PropTypes.object.isRequired,
  className: PropTypes.string
}

const mapStateToProps = state => {
  const { Trailer } = state
  return {
    Trailer
  }
}

export default connect(mapStateToProps, null, null, { context: StoreContext })(AddNewTrailerButton);

