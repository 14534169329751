import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';
import moment from 'moment-timezone';
import Autocomplete from 'react-google-autocomplete';

// API
import { t } from 'api/Translate';
import { isVehicleDriverDriving } from 'api/ELD';
import { getLocalReverseGeocode } from 'api/Geocode';
import { getChildCompanies, getPDispatcherPropertyFromState, queryCompanyObjects } from 'api/Getters';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import * as Parse from 'api/Parse';
import { getAttribute, getCurrentUserSessionToken } from 'sb-csapi/dist/AAPI';
import { updateELDDailyCertification } from 'sb-csapi/dist/api/ELDDailyCertification/ELDDailyCertification';

// CSS
import styles from './LinkShare.module.scss';

// Components
import Button from 'sbCore/Button/Button';
import InputText from 'sbCore/InputText/InputText';

class LinkShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      expiryLengthDays: 1,
      expiryLengthHours: 0,
      driverObj: null,
      eldDailyCertification: null,
      shippingDocumentNumber: '',
      shippingDocumentNumberLoading: false,
      existingShareLocationArr: [],
    };

    this.changeExpiryLength = this.changeExpiryLength.bind(this);
    this.handleChooseCompany = this.handleChooseCompany.bind(this);
    this.handleAutocomplete = this.handleAutocomplete.bind(this);
  }

  async componentDidMount() {
    if (this.props.vehicle) {
      // this.props.vehicle can also be a trailer x_x so will support both
      const classNameForQuery = this.props.vehicle.className === 'Vehicle' ? 'ShareVehicleLocation' : 'ShareLocation'
      const activeObjClassNameForQuery = this.props.vehicle.className === 'Vehicle' ? 'vehicle' : 'tcDevice'
      const activeObjForQuery = this.props.vehicle.className === 'Vehicle' ? this.props.vehicle : this.props.vehicle?.get('tc_devices');
      const existingShareLocationArr = await queryCompanyObjects(
        classNameForQuery,
        null,
        null,
        [
          { name: activeObjClassNameForQuery, queryType: 'equalTo', value: activeObjForQuery },
          { name: 'expireAt', queryType: 'greaterThan', value: moment().toDate() }
        ],
        null,
        ['tc_devices'],
        null,
        true,
      );
      this.setState({ ...this.state, existingShareLocationArr });
    }
    const vehicleLocation = this.props.vehicle.get('vehicleLocation');
    const promises = [];
    const driverObj = (this.props.vehicle?.get('drivers') && this.props.vehicle?.get('drivers').length > 0 ? this.props.vehicle?.get('drivers')[0] : null);
    promises.push(getChildCompanies(this.props.company))
    promises.push(getLocalReverseGeocode([[vehicleLocation.get('location').latitude, vehicleLocation.get('location').longitude]]));
    if (driverObj && driverObj.get('eldDailyCertification')) {
      promises.push(driverObj.get('eldDailyCertification').fetch());
    } else {
      promises.push(Promise.resolve(null));
    }
    Promise.all(promises).then((results) => {
      const childCompanyArr = results[0];
      const address = results[1];
      const addressString = address && address[0] && `${address[0].asciiName}, ${address[0].admin1Code.asciiName}, ${address[0].countryCode}`;
      const eldDailyCertificationObj = results[2];
      if (this.props.company) {
        this.props.shareLocationParseObj.set('company', this.props.company).save();
      }
      this.setState({
        ...this.state,
        companyArr: [this.props.company].concat(childCompanyArr),
        address: addressString,
        driverObj,
        shippingDocumentNumber: (
          eldDailyCertificationObj?.get('shippingDocumentNumber') ||
          (
            driverObj?.get('latestELDEvent')?.get('shippingDocumentNumber') !== 'empty' ?
              driverObj?.get('latestELDEvent')?.get('shippingDocumentNumber') :
              ''
          )
        ),
        eldDailyCertificationObj,
      });
    });
  }

  handleAutocomplete(place) {
    if (place && place.geometry && place.geometry.location) {
      const geoPoint = Parse.createGeoPoint(place.geometry.location.lat(), place.geometry.location.lng());
      this.props.shareLocationParseObj.set('destination', geoPoint);
      this.props.shareLocationParseObj.set('destinationName', place.formatted_address).save();
    }
  }

  handleChooseCompany(event) {
    const index = event.target.value;
    if (index) {
      const companyObj = this.state.companyArr[index];
      this.props.shareLocationParseObj.set('company', companyObj).save();
      this.setState({ ...this.state, chosenCompany: companyObj });
    }
  }

  async changeExpiryLength(value, type) {
    if (type === 'day') {
      await this.setState({ ...this.state, expiryLengthDays: value ? parseInt(value) : 0 });
    }

    if (type === 'hour') {
      await this.setState({ ...this.state, expiryLengthHours: value ? parseInt(value) : 0 });
    }

    const expireAt = moment().add((this.state.expiryLengthDays * 24) + this.state.expiryLengthHours, 'hours');
    this.props.shareLocationParseObj.set('expireAt', expireAt.toDate()).save();
  }


  render() {
    return (
      <div className={this.props.isSidebarOpen ? `${styles.linkShare}` : `${styles.linkShare} ${styles.sidebarClosed}`}>
        <div onClick={() => this.props.closeLinkShare()} className={styles.close}><MDBIcon icon="times-circle" /></div>


        <div className={styles.linkHref}>
          <b className={styles.label}>Active Tracking Links:</b>
          <div className={styles.shareLinkTable}>
            <table>
              {this.state.existingShareLocationArr.map((shareLocationObj, index) => {
                const distanceUnit = getPDispatcherPropertyFromState('distanceUnit') || 'km';
                let link;
                if (shareLocationObj.get('type') === 'asset') {
                  link = `${window.location.protocol}//${window.location.host}/share?type=asset&id=${this.props.vehicle.get('tc_devices').id}&s=t&auth=${shareLocationObj.id}&distanceUnit=${distanceUnit}`
                } else {
                  link = `${window.location.protocol}//${window.location.host}/share?id=${this.props.vehicle.id}&s=t&auth=${shareLocationObj.id}&distanceUnit=${distanceUnit}`
                }
                return (
                  <tr>
                    <td>
                      <a href={link} target="_blank">
                        {link}
                      </a>

                    </td>
                    <td>
                      <div
                        onClick={() => {
                          shareLocationObj.set('expireAt', moment().subtract(1, 'minute').toDate()).save();
                          const newexistingShareLocationArr = [...this.state.existingShareLocationArr];
                          newexistingShareLocationArr.splice(index, 1);
                          this.setState({ ...this.state, existingShareLocationArr: newexistingShareLocationArr });
                        }}
                        className={styles.delete}
                      >
                        <MDBIcon icon="times-circle" />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>

        <br />


        <div className={styles.prompt}>
          <b>Enter destination to share routing & ETA:</b>
          <Autocomplete
            className={styles.autocomplete}
            placeholder={t('Destination (Optional)')}
            types={['establishment', 'geocode']}
            componentRestrictions={{ country: ['ca', 'us'] }}
            onPlaceSelected={(place) => {
              this.handleAutocomplete(place);
            }}
            defaultValue={this.props.shareLocationParseObj.get('destinationName')}
          />
        </div>

        <br />

        {this.props.vehicle.get('unitId') &&
          (
            <div className={styles.prompt}>
              Link Expiry Settings:
              <br />
              <input className={styles.expiryInput} type="number" value={this.state.expiryLengthDays} onChange={(e) => this.changeExpiryLength(e.target.value, 'day')} /> day(s) and <input className={styles.expiryInput} type="number" value={this.state.expiryLengthHours} onChange={(e) => this.changeExpiryLength(e.target.value, 'hour')} /> hour(s)

              <br />
              <br />

              {this.state.companyArr && this.state.companyArr.length > 1 &&
                (
                  <div>
                    Choose the company to display as:
                    <select name="company" onChange={this.handleChooseCompany}>
                      {this.state.companyArr.map((childCompanyObj, index) => {
                        return (<option key={childCompanyObj.id} value={index}>{childCompanyObj.get('name')}</option>);
                      })}
                    </select>
                  </div>
                )
              }
              {moment().diff(moment(this.props.vehicle.get('vehicleLocation').get('dateTime')), 'minutes') > 30 && isVehicleDriverDriving(this.props.vehicle) &&
                (
                  <div className={styles.oldLocationError}>
                    Vehicle's location has not updated in a while
                  </div>
                )
              }
            </div>
          )
        }
        {!this.props.vehicle.get('unitId') &&
          (
            <div className={styles.prompt}>
              Link Expiry Settings:
              <br />
              <input className={styles.expiryInput} type="number" value={this.state.expiryLengthDays} onChange={(e) => this.changeExpiryLength(e.target.value, 'day')} /> day(s) and <input className={styles.expiryInput} type="number" value={this.state.expiryLengthHours} onChange={(e) => this.changeExpiryLength(e.target.value, 'hour')} /> hour(s)

              <br />
              <br />

              {this.state.companyArr && this.state.companyArr.length > 1 &&
                (
                  <div>
                    Choose the company to display as:
                    <select name="company" onChange={this.handleChooseCompany}>
                      {this.state.companyArr.map((childCompanyObj, index) => {
                        return (<option key={childCompanyObj.id} value={index}>{childCompanyObj.get('name')}</option>);
                      })}
                    </select>
                  </div>
                )
              }
              <div />
              {moment().diff(moment(this.props.vehicle.get('vehicleLocation').get('dateTime')), 'minutes') > 30 && isVehicleDriverDriving(this.props.vehicle) &&
                (
                  <div className={styles.oldLocationError}>
                    Vehicle's location has not updated in a while
                  </div>
                )
              }
            </div>
          )
        }
        {
          this.state.driverObj
          && this.state.driverObj.get('latestELDEvent_eventDateTime')
          && moment(this.state.driverObj.get('latestELDEvent_eventDateTime')).isAfter(moment().startOf('day'))
          && this.state.eldDailyCertificationObj
          &&
          (
            <div>
              <br />
              <a
                href="/settings"
                target="_blank"
                className={styles.integrations}
                onClick={(e) => { e.preventDefault(); history.push('/settings?tab=Integrations'); }}
              >
                ID for Project44, FourKites, Macropoint Integrations:
              </a>
              <div>
                <InputText
                  type="text"
                  size="0"
                  placeholder="Tracking ID"
                  className="p-inputtext-sm p-d-block p-mb-2"
                  value={this.state.shippingDocumentNumber}
                  onChange={(e) => { this.setState({ ...this.state, shippingDocumentNumber: e.target.value }) }}
                />
                <Button
                  className="back-button p-button-sm"
                  label="Update"
                  icon="pi pi-save"
                  disabled={this.state.shippingDocumentNumberLoading}
                  onClick={async () => {
                    this.setState({ ...this.state, shippingDocumentNumberLoading: true });
                    await updateELDDailyCertification({ sessionToken: getCurrentUserSessionToken() }, this.state.eldDailyCertificationObj, { shippingDocumentNumber: this.state.shippingDocumentNumber }, true)
                    this.setState({ ...this.state, shippingDocumentNumberLoading: false });
                  }}
                />
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

LinkShare.propTypes = {
  link: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired,
  vehicle: PropTypes.object.isRequired,
  shareVehicleLocationParseObj: PropTypes.object.isRequired,
  closeLinkShare: PropTypes.func.isRequired,
};


export default LinkShare;
