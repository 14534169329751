import React from 'react';
import PropTypes from 'prop-types';
import { MDBBadge } from 'mdbreact';

function DutyStatusLabel(props) {
  // Duty Status:
  // 1: Off Duty
  // 2: Sleeper
  // 3: Driving
  // 4: On Duty Not Driving
  // 5: Personal Use CMV
  // 6: Yard move
  // 7: Exempt
  if (props.eldStatusInt === 1) {
    return <MDBBadge color="light" className="translate-me">OFF DUTY</MDBBadge>;
  }
  if (props.eldStatusInt === 2) {
    return <MDBBadge color="light" className="translate-me">SLEEPER</MDBBadge>;
  }
  if (props.eldStatusInt === 3) {
    return <MDBBadge color="success" className="translate-me">DRIVING</MDBBadge>;
  }
  if (props.eldStatusInt === 4) {
    return <MDBBadge color="primary" className="translate-me">ON DUTY</MDBBadge>;
  }
  if (props.eldStatusInt === 5) {
    return <MDBBadge color="light" className="translate-me">PERSONAL</MDBBadge>;
  }
  if (props.eldStatusInt === 6) {
    return <MDBBadge color="info" className="translate-me">YARD</MDBBadge>;
  }
  if (props.eldStatusInt === 7) {
    return <MDBBadge color="light" className="translate-me">EXEMPT</MDBBadge>;
  }
  return null;
}

DutyStatusLabel.propTypes = {
  eldStatusInt: PropTypes.number,
};

export default DutyStatusLabel;
