import React from 'react';
import PropTypes from 'prop-types';

// Components
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import { MDBIcon } from 'mdbreact';
// CSS
import styles from './DeleteXButton.module.scss';

class DeleteXButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { confirmDeleteModalShow: false };
    this.openConfirmDeleteModal = this.openConfirmDeleteModal.bind(this);
    this.closeConfirmDeleteModal = this.closeConfirmDeleteModal.bind(this);
    this.handleModalChoice = this.handleModalChoice.bind(this);
  }

  openConfirmDeleteModal() {
    this.state.confirmDeleteModalShow = true;
    this.setState({ ...this.state, confirmDeleteModalShow: true });
  }
  closeConfirmDeleteModal() {
    this.state.confirmDeleteModalShow = false;
    this.setState({ ...this.state, confirmDeleteModalShow: false });
  }

  handleModalChoice(choiceBool) {
    this.setState({ ...this.state, confirmDeleteModalShow: false });
    this.props.handleChoice(choiceBool);
    // setTimeout(() => {
    //   this.props.handleChoice(choiceBool);
    // }, 275); // To allow bootstrap animation to finish before element gets unmounted
  }

  render() {
    return (
      <div className={styles.deleteButton} onClick={this.openConfirmDeleteModal}>
        <MDBIcon icon="times"></MDBIcon>
        <ConfirmModal show={this.state.confirmDeleteModalShow} handleModalChoice={this.handleModalChoice} handleClose={this.closeConfirmDeleteModal}>
          { this.props.children }
        </ConfirmModal>
      </div>
    );
  }

}

DeleteXButton.propTypes = {
  children: PropTypes.node,
  handleChoice: PropTypes.func.isRequired,
};

export default DeleteXButton;
