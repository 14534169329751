import Parse from 'parse';
import * as ActionConstants from 'actions/ActionConstants';
import * as Helpers from './Helpers';

/** @module Stripe */

/**
 * @memberof module:Stripe
 * @param {*} store 
 */
function initializeStripe(store) {
  Parse.Cloud.run('getStripeKey').then((response) => {
    store.dispatch({ type: ActionConstants.INITIALIZE_STRIPE, stripePublishableKey: response.key });
  });
}

/**
 * @memberof module:Stripe
 * @param {*} subscriptionParseObj 
 * @returns 
 */
function cancelSubscription(subscriptionParseObj) {
  return Parse.Cloud.run('cancelSubscription', { subscriptionId: subscriptionParseObj.get('subscriptionId') });
}

/**
 * @memberof module:Stripe
 * @param {*} subscriptionParseObj 
 * @returns 
 */
function checkSubscription(subscriptionParseObj) {
  if (subscriptionParseObj.get('customerId')) {
    return Parse.Cloud.run('checkSubscription', {
      customerId: subscriptionParseObj.get('customerId'),
      parseSubscriptionId: subscriptionParseObj.id,
    });
  }
  return Promise.resolve({ active: false });
}

/**
 * @memberof module:Stripe
 * @param {*} subscriptionParseObj 
 * @returns 
 */
function renewSubscription(subscriptionParseObj) {
  return Parse.Cloud.run('restartSubscription', {
    customerId: subscriptionParseObj.get('customerId'),
    subscriptionId: subscriptionParseObj.get('subscriptionId'),
    companyObjectId: subscriptionParseObj.get('company').id,
    quantity: subscriptionParseObj.get('eldsSupported'),
  });
}

/**
 * @memberof module:Stripe
 * @param {*} subscriptionParseObj 
 * @returns 
 */
function getCurrencyFromCustomer(subscriptionParseObj) {
  if (subscriptionParseObj) {
    return Parse.Cloud.run('getCurrencyFromCustomer', {
      customerId: subscriptionParseObj.get('customerId'),
    });
  }
  return Promise.resolve(null);
}

/**
 * @memberof module:Stripe
 * @param {*} token 
 * @param {*} subscriptionParseObj 
 * @returns 
 */
function addSourceToCustomer(token, subscriptionParseObj) {
  return Parse.Cloud.run('addSourceToCustomer', {
    token,
    companyObjectId: subscriptionParseObj.get('company').id,
  });
}

/**
 * @memberof module:Stripe
 * @param {*} subscriptionParseObj 
 * @returns 
 */
function getMonthlyCharge(subscriptionParseObj) {
  return Parse.Cloud.run('getMonthlyCharge', {
    companyObjectId: subscriptionParseObj.get('company').id,
  });
}

export {
  initializeStripe,
  cancelSubscription,
  addSourceToCustomer,
  checkSubscription,
  renewSubscription,
  getCurrencyFromCustomer,
  getMonthlyCharge,

};
