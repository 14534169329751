import * as ActionConstants from './ActionConstants';
import store from '../store';

import * as Setters from '../api/Setters';
import * as Getters from '../api/Getters';

export function fetchingGlobalTrailers() {
  return {
    type: ActionConstants.FETCH_GLOBALTRAILERS_INPROGRESS,
  };
}

export function fetchGlobalTrailersSuccess(trailers) {
  return {
    type: ActionConstants.FETCH_GLOBALTRAILERS_SUCCESS,
    trailers,
  };
}

export function fetchGlobalTrailersError() {
  return {
    type: ActionConstants.FETCH_GLOBALTRAILERS_ERROR,
  };
}

export function deleteGlobalTrailers() {
  return {
    type: ActionConstants.DELETE_GLOBALTRAILERS,
  };
}

export function addingGlobalTrailer() {
  return {
    type: ActionConstants.ADD_GLOBALTRAILER_INPROGRESS,
  };
}

export function addGlobalTrailerSuccess(trailer) {
  return {
    type: ActionConstants.ADD_GLOBALTRAILER_SUCCESS,
    trailer,
  };
}

export function addGlobalTrailerError(error) {
  return {
    type: ActionConstants.ADD_GLOBALTRAILER_ERROR,
    error,
  };
}

export function updatingGlobalTrailer(trailer) {
  return {
    type: ActionConstants.UPDATE_GLOBALTRAILER_INPROGRESS,
    trailer,
  };
}

export function updateGlobalTrailerSuccess(trailer) {
  return {
    type: ActionConstants.UPDATE_GLOBALTRAILER_SUCCESS,
    trailer,
  };
}

export function updateGlobalTrailerError(trailer, error) {
  return {
    type: ActionConstants.UPDATE_GLOBALTRAILER_ERROR,
    trailer,
    error,
  };
}

export function disableGlobalTrailersSuccess(trailers) {
  return {
    type: ActionConstants.DISABLE_GLOBALTRAILERS_SUCCESS,
    trailers,
  };
}

export function disableGlobalTrailer(trailerParseObj) {
  const promise = new Promise((resolve, reject) => {
    trailerParseObj.set('enabled', false).save().then(
      trailerObject => {
        resolve();
        store.dispatch(disableGlobalTrailersSuccess([trailerObject]));
      },
    );
  });
  return promise;
}

export function getGlobalTrailerIncludeArr() {
  return ['vendor', 'driver', 'vehicle', 'vehicle.licensePlate', 'licensePlate'];
}

export function fetchGlobalTrailersForState() {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingGlobalTrailers());
    const filter = [{ name: 'unitId', queryType: 'exists' }, { name: 'enabled', queryType: 'equalTo', value: true }, { name: 'isHidden', queryType: 'notEqualTo', value: true }];
    const sortBy = { unitId: 'descending' };
    Getters.queryCompanyObjects('Trailer', undefined, undefined, filter, sortBy, getGlobalTrailerIncludeArr(), undefined, undefined, undefined, true).then(
      trailers => {
        store.dispatch(fetchGlobalTrailersSuccess(trailers));
        resolve(trailers);
      },
      error => {
        store.dispatch(fetchGlobalTrailersError(error));
        reject(error);
      }
    );
  });
  return promise;
}

export function getMissingGlobalTrailerInfo() {
  const missingInfo = {};
  const GlobalTrailerState = store.getState().GlobalTrailer;
  if (GlobalTrailerState.fetched) {
    GlobalTrailerState.trailers.map((parsedTrailerObj) => {
      const key = `${parsedTrailerObj.get('unitId')}-${parsedTrailerObj.id}`;
      if (!missingInfo[key]) {
        missingInfo[key] = {};
      }
      if (!parsedTrailerObj.get('type')) {
        missingInfo[key].type = true;
      }
    });
    Object.keys(missingInfo).map((key) => {
      if (Object.keys(missingInfo[key]).length === 0) {
        delete missingInfo[key];
      }
    });
  }
  return missingInfo;
}

export function deleteGlobalTrailersForState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteGlobalTrailers());
    resolve(store.getState().GlobalTrailer);
  });
  return promise;
}

export function addGlobalTrailerToState(trailerParseObj) {
  return store.dispatch(addGlobalTrailerSuccess(trailerParseObj));
}

export function updateGlobalTrailerForState(trailerParseObj, property, value) {
  store.dispatch(updatingGlobalTrailer(trailerParseObj));
  const promise = new Promise((resolve, reject) => {
    // user-based attributes
    trailerParseObj.set(property, value).save().then(
      trailerObject => {
        Getters.fetchParseObjectArr(store.getState().GlobalTrailer.trailers).then((trailersParseObjArr) => {
          resolve(trailerObject);
          store.dispatch(updateGlobalTrailerSuccess(trailersParseObjArr));
        });
      },
      error => {
        reject(error);
        store.dispatch(updateGlobalTrailerError(error));
      }
    );
  });
  return promise;
}
