import axios from 'axios';
import store from 'store';
import L from 'mapbox.js';
import Parse from 'parse';
import polyline from '@mapbox/polyline';
import * as ActionConstants from 'actions/ActionConstants';

import * as Helpers from 'api/Helpers';
import { queryCompanyObjects } from 'api/Getters';

/** @module Mapbox */

// http://www.flatuicolorpicker.com/category/all
/**
 * @memberof module:Mapbox
 * @param {*} index 
 * @returns 
 */
function getColour(index) {
  const colourArray = [
    '#446CB3',
    '#03C9A9',
    '#674172',
    '#F1A9A0',
    '#D24D57',
    '#E67E22',
    '#F5D76E',
    '#1E824C',
    '#2C3E50',
    '#DCC6E0',
  ];
  if (index) {
    if (index < colourArray.length) {
      return colourArray[index];
    }
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`; // random
  }
  return '#ABB7B7'; // gray
}

// OLD TOKEN: pk.eyJ1Ijoic3dpdGNoYm9hcmQiLCJhIjoiY2l4OWl4YWc5MDAyZTJ0cWY2ZnphYm9hdSJ9.lugyfpqxdpfo_1eGWr_FFA
/**
 * @memberof module:Mapbox
 * @returns 
 */
function getToken() {
  return (process.env.MAPBOX_PUBLIC_TOKEN || 'pk.eyJ1Ijoic3dpdGNoYm9hcmQiLCJhIjoiY2sycGxpMDkxMDVpNzNtazQ4MHhhdXRuOCJ9.0RiJKsDy1PBKiExssnRmTQ');
}

/**
 * @memberof module:Mapbox
 * @param {*} store 
 */
function initializeMapbox(store) {
  store.dispatch({ type: ActionConstants.INITIALIZE_MAPBOX, token: getToken() });
}

// LINK to referenced code for encoding/decoding: https://github.com/mapbox/polyline/blob/master/src/polyline.js
/**
 * @memberof module:Mapbox
 * @description Helper function for getEncodedCoordinates() which returns the string of an encoded latitude or longitude of a coordinate.
 * @param {number} currentCoordinateValue - A value from a coordinate which can be a latitude or longitude.
 * @param {number} previousCoordinateValue - A value from a coordinate which can be a latitude or longitude.
 * @returns String - An encoded string value which can either represent a latitude or longitude of a coordinate.
 */
function encodeCoordinateValue(currentCoordinateValue, previousCoordinateValue) {
  currentCoordinateValue = Math.round(currentCoordinateValue * 1e5);
  previousCoordinateValue = Math.round(previousCoordinateValue * 1e5);

  let encodedCoordinateValue = '';
  let coordinateValue = currentCoordinateValue - previousCoordinateValue;
  coordinateValue <<= 1;

  if (currentCoordinateValue - previousCoordinateValue < 0) {
    coordinateValue = ~coordinateValue;
  }

  while (coordinateValue > 0x20) {
    encodedCoordinateValue += String.fromCharCode((0x20 | (coordinateValue & 0x1f)) + 63);
    coordinateValue >>= 5;
  }
  encodedCoordinateValue += String.fromCharCode(coordinateValue + 63);
  return encodedCoordinateValue;
}

/**
 * @memberof module:Mapbox
 * @description Takes in an array of coordinates containing a latitude/longitude pair and returns an encoded string representing the array
 * @param {array} coordinates - An array of coordinates each containing a latitude/longitude (ex. [[49.24754, -120.13234], ...])
 * @returns String - The encoded string of the array of coordinates (ex. "wWrfhnv")
 */
function getEncodedCoordinates(coordinates) {
  let encodedCoordinates = '';
  if (!coordinates.length) {
    return encodedCoordinates;
  }

  encodedCoordinates = encodeCoordinateValue(coordinates[0][0], 0) + encodeCoordinateValue(coordinates[0][1], 0);

  for (let i = 1; i < coordinates.length; i++) {
    const currentCoordinates = coordinates[i];
    const previousCoordinates = coordinates[i - 1];
    encodedCoordinates += encodeCoordinateValue(currentCoordinates[0], previousCoordinates[0]);
    encodedCoordinates += encodeCoordinateValue(currentCoordinates[1], previousCoordinates[1]);
  }
  return encodedCoordinates;
}

/**
 * @memberof module:Mapbox
 * @description Helper function for getDecodedCoordinates() which takes an encoded coordinate string and returns a decoded coordinate value
 * @param {string} encodedCoordinates - An encoded string of coordinates (ex. "whxkHnfhmV")
 * @param {number} index - A number used in getDecodedCoordinates() to check for the end of the encoded string
 * @returns Number - A decoded coordinate value which can be a latitude or longitude
 */
function decodeCoordinateValue(encodedCoordinates, index) {
  let result = 0;
  let shift = 0;
  let byte = null;

  do {
    byte = encodedCoordinates.charCodeAt(index++) - 63;
    result |= (byte & 0x1f) << shift;
    shift += 5;
  } while (byte >= 0x20);

  const coordinateChange = ((result & 1) ? ~(result >> 1) : (result >> 1));
  return [coordinateChange, index];
}

/**
 * @memberof module:Mapbox
 * @description Takes in an encoded string representing an array of coordinates, decodes the string, and returns the coordinates in an array
 *
 * *NOTE* passing in a lone string with an escape character ('\') will output the wrong results.
 * Bad: getDecodedCoordinates(`~o\ia@)
 * Good: let encodedCoordinates = getEncodedCoordinates("Wvfjs")
 *       getDecodedCoordinates(encodedCoordinates)
 *
 * @param {string} encodedCoordinates - An encoded string of coordinates (ex. "whxkHnfhmV")
 * @returns Array - The decoded array of coordinates (ex. [[49.24754, -120.13234], ...])
 */
function getDecodedCoordinates(encodedCoordinates) {
  let index = 0;
  let longitude = 0;
  let latitude = 0;
  const decodedCoordinates = [];

  if (!encodedCoordinates.length) {
    return decodedCoordinates;
  }

  while (index < encodedCoordinates.length) {
    const [longitudeChange, longitudeIndex] = decodeCoordinateValue(encodedCoordinates, index);
    const [latitudeChange, latitudeIndex] = decodeCoordinateValue(encodedCoordinates, longitudeIndex);

    index = latitudeIndex;
    latitude += latitudeChange;
    longitude += longitudeChange;

    decodedCoordinates.push([longitude / 1e5, latitude / 1e5]);
  }
  return decodedCoordinates;
}

/**
 * @memberof module:Mapbox
 * @param {*} coords 
 * @returns 
 */
function getBoundsFromLngLatArr(coords) {
  // Arr with array [ lng, lat ]
  if (coords && coords.length > 0) {
    if (coords.length > 0) {
      const leafletBoundsObj = new L.latLngBounds(coords);
      const width = Math.abs(leafletBoundsObj.getSouthWest().lng - leafletBoundsObj.getNorthEast().lng);
      const intendedBoxSideLength = 0.1;
      if (width < intendedBoxSideLength) {
        const centerLat = (Math.abs(leafletBoundsObj.getSouthWest().lat - leafletBoundsObj.getNorthEast().lat) / 2) + leafletBoundsObj.getSouthWest().lat;
        const centerLng = (Math.abs(leafletBoundsObj.getSouthWest().lng - leafletBoundsObj.getNorthEast().lng) / 2) + leafletBoundsObj.getSouthWest().lng;
        return [[centerLat - (intendedBoxSideLength / 2), centerLng - (intendedBoxSideLength / 2)], [centerLat + (intendedBoxSideLength / 2), centerLng + (intendedBoxSideLength / 2)]];
      }
      return [[leafletBoundsObj.getSouthWest().lat, leafletBoundsObj.getSouthWest().lng], [leafletBoundsObj.getNorthEast().lat, leafletBoundsObj.getNorthEast().lng]];
    }
  }
  return undefined;
}

/**
 * @memberof module:Mapbox
 * 
 * @param {*} lat1 
 * @param {*} lon1 
 * @param {*} lat2 
 * @param {*} lon2 
 * 
 * @returns 
 */
function distanceBetweenTwoGeopoints(lat1, lon1, lat2, lon2) {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    return dist * 1.609344;
  }
}

/**
 * @memberof module:Mapbox
 * @param {*} vehicleParseArr 
 * @returns 
 */
function getBoundsFromVehicleParseArr(vehicleParseArr) {
  if (vehicleParseArr && vehicleParseArr.length > 0) {
    const coords = [];
    const vehicleParseArrLen = vehicleParseArr.length;
    for (let i = 0; i < vehicleParseArrLen; i++) {
      if (vehicleParseArr[i].get('vehicleLocation') && vehicleParseArr[i].get('vehicleLocation').get('location') && !(vehicleParseArr[i].get('vehicleLocation').get('location').longitude === 0 && vehicleParseArr[i].get('vehicleLocation').get('location').latitude === 0)) {
        coords.push([vehicleParseArr[i].get('vehicleLocation').get('location').longitude, vehicleParseArr[i].get('vehicleLocation').get('location').latitude]);
      }
    }
    if (coords.length > 0) {
      const leafletBoundsObj = new L.latLngBounds(coords);
      const width = Math.abs(leafletBoundsObj.getSouthWest().lng - leafletBoundsObj.getNorthEast().lng);
      const intendedBoxSideLength = 0.1;
      if (width < intendedBoxSideLength) {
        const centerLat = (Math.abs(leafletBoundsObj.getSouthWest().lat - leafletBoundsObj.getNorthEast().lat) / 2) + leafletBoundsObj.getSouthWest().lat;
        const centerLng = (Math.abs(leafletBoundsObj.getSouthWest().lng - leafletBoundsObj.getNorthEast().lng) / 2) + leafletBoundsObj.getSouthWest().lng;
        return [[centerLat - (intendedBoxSideLength / 2), centerLng - (intendedBoxSideLength / 2)], [centerLat + (intendedBoxSideLength / 2), centerLng + (intendedBoxSideLength / 2)]];
      }
      return [[leafletBoundsObj.getSouthWest().lat, leafletBoundsObj.getSouthWest().lng], [leafletBoundsObj.getNorthEast().lat, leafletBoundsObj.getNorthEast().lng]];
    }
  }
  return undefined;
}

/**
 * @memberof module:Mapbox
 * 
 * @param {*} longLatArray 
 * @param {*} returnPolylineCoordsOnly 
 * @param {*} useDirections 
 * 
 * @returns 
 */
function getRoadRoute(longLatArray, returnPolylineCoordsOnly, returnAllData) {
  function getMapMatch(coordinateString) {
    return axios.get(`https://api.mapbox.com/matching/v5/mapbox/driving/${coordinateString}`, {
      params: {
        access_token: store.getState().Main.mapboxToken,
        geometries: 'polyline6',
        overview: 'full',
      },
    }).then((response) => {
      if (response && response.data.code !== 'NoMatch' && response.data.matchings && response.data.matchings[0]) {
        return { polyline: response.data.matchings[0].geometry, distance: response.data.matchings[0].distance, duration: response.data.matchings[0].duration };
      }
      return undefined;
    });
  }

  function getDirections(coordinateString, numTries) {
    return axios.get(`https://api.mapbox.com/directions/v5/mapbox/driving/${coordinateString}`, {
      params: {
        access_token: store.getState().Main.mapboxToken,
        geometries: 'polyline6',
        overview: 'full',
      },
    }).then((response) => {
      if (response && response.data.code !== 'NoRoute' && response.data.routes && response.data.routes[0]) {
        return { polyline: response.data.routes[0].geometry, distance: response.data.routes[0].distance, duration: response.data.routes[0].duration };
      } else if (!numTries || numTries < 2) {
        const array = coordinateString.split(';').filter(function (_, i) { return i % 2 === 0; });
        const updatedCoordinateString = array.join(';');
        return getDirections(updatedCoordinateString, numTries !== undefined ? numTries + 1 : 1);
      }
      return undefined;
    }, (error) => {
      console.log(error);
      return undefined
    });
  }

  const promise = new Promise((resolve, reject) => {
    // Keep points under 100 for mapbox direction query constraints
    // const cutLongLatLen = useDirections ? 25 : 100;
    const cutLongLatLen = 25; // right now use Directions, more reliable
    // const cutLongLatArray = longLatArray.length > cutLongLatLen ?
    //   longLatArray.filter((longLatEntry, index) =>
    //     !(index % (Math.ceil(longLatArray.length / cutLongLatLen)))
    //   ) :
    //   longLatArray;
    // if (cutLongLatArray[cutLongLatArray.length - 1] !== longLatArray[longLatArray.length - 1]) {
    //   cutLongLatArray[cutLongLatArray.length - 1] = longLatArray[longLatArray.length - 1];
    // }

    // Filter out points that are close togetehr
    const filteredLongLatArr = [];
    for (let i = 0; i < longLatArray.length - 1; i++) {
      const longLatObj = longLatArray[i];
      if (i === 0) filteredLongLatArr.push(longLatObj);
      else {
        const prevLongLatObj = filteredLongLatArr[filteredLongLatArr.length - 1];
        if (distanceBetweenTwoGeopoints(prevLongLatObj[1], prevLongLatObj[0], longLatObj[1], longLatObj[0]) > 0.5) {
          filteredLongLatArr.push(longLatObj);
        }
      }
    }
    filteredLongLatArr.push(longLatArray[longLatArray.length - 1]);

    const longLatCutSectionArr = [];
    let longLatCutSection = filteredLongLatArr;

    // Section into sections of 25 because that's what Mapbox takes
    while (longLatCutSection.length > 0 && longLatCutSection) {
      longLatCutSectionArr.push(longLatCutSection.slice(0, cutLongLatLen));
      longLatCutSection = longLatCutSection.slice(cutLongLatLen, longLatCutSection.length);
    }
    // Set up for multiple calls to Mapbox for route
    const routePromises = [];
    for (let i = 0; i < longLatCutSectionArr.length; i++) {
      const cutLongLatArray = longLatCutSectionArr[i];
      if (cutLongLatArray.length > 1) {
        const coordinateString = cutLongLatArray.map((longLatEntry) => `${longLatEntry[0]},${longLatEntry[1]}`).join(';');
        routePromises.push(getDirections(coordinateString)); // right now more reliable
        // routePromises.push(getMapMatch(coordinateString));
        // const request = useDirections ? getDirections(coordinateString) : getMapMatch(coordinateString);
      }
    }
    Promise.all(routePromises).then(results => {
      const polylineWaypoints = [];
      for (let i = 0; i < results.length; i++) {
        const response = results[i];
        if (response) {
          try {
            polylineWaypoints.push(...polyline.decode(response.polyline, 6).map((waypoint) => ({ longitude: waypoint[1], latitude: waypoint[0] })));
          } catch (e) {
            break;
          }
        }
      }

      if (polylineWaypoints.length === 0) {
        resolve(undefined);
      } else if (returnAllData) {
        resolve({
          results,
          polylineWaypoints,
        });
      } else if (!returnPolylineCoordsOnly) {
        resolve(results);
      } else if (returnPolylineCoordsOnly) {
        resolve(polylineWaypoints);
      }
    });
  });
  return promise;
}

/**
 * @memberof module:Mapbox
 * @param {*} vehicleParseObj 
 * @returns 
 */
function getPathToNextJob(vehicleParseObj) {
  // Get future route
  const promise = new Promise((resolve, reject) => {
    if (vehicleParseObj.get('drivers')[0] && vehicleParseObj.get('drivers')[0].get('jobActions')[0]) {
      queryCompanyObjects('JobAction', undefined, undefined, [{ name: 'objectId', value: vehicleParseObj.get('drivers')[0].get('jobActions')[0].id }], undefined, ['vendor', 'vendor.address'], true, true).then((jobActionObj) => {
        const currentLocation = vehicleParseObj.get('vehicleLocation').get('location');
        const nextJobActionGeopoint = jobActionObj.get('vendor').get('address').get('geoPoint');
        const pathPoints = [];
        pathPoints.push([currentLocation.longitude, currentLocation.latitude]);
        pathPoints.push([nextJobActionGeopoint.longitude, nextJobActionGeopoint.latitude]);
        getRoadRoute(pathPoints, true).then((routeToNextJob) => {
          resolve({ path: routeToNextJob, jobAction: jobActionObj });
        });
      });
    } else {
      resolve({});
    }
  });
  return promise;
}

/**
 * @memberof module:Mapbox
 * @param {*} vehicleLocationArr 
 * @returns 
 */
function getHistoryLegArray(vehicleLocationArr) {
  function getInitialLegObject(index, legIndex, colourIndex) {
    let colour;
    if (vehicleLocationArr[index].get('jobEntity')) {
      colour = getColour(colourIndex);
    } else {
      colour = getColour();
    }
    return {
      driver: vehicleLocationArr[index].get('driver'),
      vehicle: vehicleLocationArr[index].get('vehicle'),
      jobEntity: vehicleLocationArr[index].get('jobEntity'),
      vehicleLocationPointsArr: [],
      waypoints: undefined,
      colour,
    };
  }

  function getConnectorObject(firstPoint, secondPoint) {
    return {
      vehicleLocationPointsArr: [firstPoint, secondPoint],
      waypoints: undefined,
      connector: true,
      colour: getColour(),
    };
  }

  const promise = new Promise((resolve, reject) => {
    const legObjectArray = [];
    let legIndex = -1;
    let colourIndex = 0;
    const vehicleLocationArrLen = vehicleLocationArr.length;
    const jobEntityIds = new Set([]);
    for (let i = 0; i < vehicleLocationArrLen; i++) {
      if (legIndex === -1 || (vehicleLocationArr[i].get('jobEntity') && !jobEntityIds.has(vehicleLocationArr[i].get('jobEntity').id)) || (!vehicleLocationArr[i].get('jobEntity') && (vehicleLocationArr[i - 1].get('jobEntity')))) {
        if (legIndex !== -1) {
          const previousLegLastPoint = legObjectArray[legIndex].vehicleLocationPointsArr.slice(-1)[0];
          const nextLegFirstPoint = vehicleLocationArr[i];
          legObjectArray.push(getConnectorObject(previousLegLastPoint, nextLegFirstPoint));
          legIndex++;
        }
        legIndex++;
        if (vehicleLocationArr[i].get('jobEntity')) {
          jobEntityIds.add(vehicleLocationArr[i].get('jobEntity').id);
        }
        legObjectArray.push(getInitialLegObject(i, legIndex, colourIndex));
        colourIndex++;
      }

      if (vehicleLocationArr[i].get('location')) {
        legObjectArray[legIndex].vehicleLocationPointsArr.push(vehicleLocationArr[i]);
      }
    }

    const roadRoutePromises = [];

    for (let i = 0; i < legObjectArray.length; i++) {
      roadRoutePromises.push(getRoadRoute(Helpers.convertParseVehicleLocationArrToPointArr(legObjectArray[i].vehicleLocationPointsArr), true));
    }
    Promise.all(roadRoutePromises).then((roadRoutes) => {
      for (let i = 0; i < roadRoutes.length; i++) {
        legObjectArray[i].waypoints = roadRoutes[i];
      }
      resolve({ legs: legObjectArray });
    });
  });

  return promise;
}

/**
 * @memberof module:Mapbox
 * @param {*} vehicleLocationArr 
 * @returns 
 */
function getHistoryDutiesArray(vehicleLocationArr) {
  if (!vehicleLocationArr) return null;
  const legObject = {
    importantELDEvents: [],
    vehicleLocationPointsArr: [],
    waypoints: undefined,
    colour: getColour(),
  };

  const promise = new Promise((resolve) => {
    const vehicleLocationArrLen = vehicleLocationArr.length;
    for (let i = 0; i < vehicleLocationArrLen; i++) {
      if (vehicleLocationArr[i].get('eldEvent_eldEventTypeCodeInt') !== 21 && vehicleLocationArr[i].get('eldEvent')) {
        legObject.importantELDEvents.push({ eldEvent: vehicleLocationArr[i].get('eldEvent'), vehicleLocation: vehicleLocationArr[i] });
      }

      if (vehicleLocationArr[i].get('location')) {
        legObject.vehicleLocationPointsArr.push(vehicleLocationArr[i]);
      }
    }
    getRoadRoute(Helpers.convertParseVehicleLocationArrToPointArr(legObject.vehicleLocationPointsArr), false, true).then((roadRouteResults) => {
      legObject.results = roadRouteResults && roadRouteResults.results;
      legObject.waypoints = roadRouteResults && roadRouteResults.polylineWaypoints;
      resolve({ legs: [legObject] });
    });
  });

  return promise;
}

/**
 * @memberof module:Mapbox
 * 
 * @param {*} longitude 
 * @param {*} latitude 
 * @param {*} returnAllData 
 * 
 * @returns 
 */
function getReverseGeocode(longitude, latitude, returnAllData) {
  const promise = new Promise((resolve, reject) => {
    axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json`, {
      params: {
        access_token: store.getState().Main.mapboxToken,
      },
    }).then((response) => {
      if (response && response.data.features && response.data.features[0]) {
        if (returnAllData) {
          resolve(response.data.features[0]);
        } else {
          resolve(response.data.features[0].place_name);
        }
      } else {
        resolve(undefined);
      }
    });
  });
  return promise;
}

// function getOptimizedTripFromSequenceArray(longLatSequenceArray) {
//   const promise = new Promise((resolve, reject) => {
//     for (let i = 0; i < longLatSequenceArray.length; i++) {
//
//     }
//
//
//   });
//   return promise;
// }

/**
 * @memberof module:Mapbox
 * 
 * @param {*} addressString 
 * @param {*} dataOnly 
 * 
 * @returns 
 */
function getGeocode(addressString, dataOnly) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('getGeocode', { addressString, dataOnly }).then(
      result => {
        resolve(result);
      },
      error => {
        console.log(error);
        reject(error);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Mapbox
 * @description get a mapbox routing given routeType (default is road)
 * 
 * @param {array} longLatArray - array of coordinates formatted { latitude: <float>, longitude: <float> }
 * @param {bool} returnAsPolylineObjects - return the directions response as is, or format the output to return polyline objects (array of routes + other info)
 * @param {number} routeType - the type of routing we want to determine directions with (ex. road driving, transit, etc) - defaults to road driving
 */
async function getRoute(longLatArray = [], returnAsPolylineObjects, routeType) {
  if (longLatArray.length === 0) throw new Error('longLatArray must have at least one element');
  async function getDirections(coordinateString) {
    try {
      const response = await axios.get(`https://api.mapbox.com/directions/v5/mapbox/driving/${coordinateString}`, {
        params: {
          access_token: getToken(),
          geometries: 'polyline6',
          overview: 'full',
        },
      });

      if (response && response.data.code !== 'NoRoute' && response.data.routes && response.data.routes[0]) {
        return { polyline: response.data.routes[0].geometry, distance: response.data.routes[0].distance };
      }
      return undefined;
    } catch (err) {
      throw new Error(err);
    }
  }


  const filteredLongLatArr = longLatArray;
  const coordinateString = filteredLongLatArr.map((longLatEntry) => `${longLatEntry.longitude},${longLatEntry.latitude}`).join(';');

  try {
    const directions = await getDirections(coordinateString);
    if (!directions) return [];

    if (!returnAsPolylineObjects) {
      return directions;
    }

    const polylineObjects = polyline.decode(directions.polyline, 6).map((waypoint) => ({ longitude: waypoint[1], latitude: waypoint[0] }));
    return { polylineObjects, distance: directions.distance };

  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:Mapbox
 * 
 * @param latLngInDeg array of arrays with latitude and longtitude
 *   pairs in degrees. e.g. [[latitude1, longtitude1], [latitude2
 *   [longtitude2] ...]
 *
 * @return array with the center latitude longtitude pairs in
 *   degrees.
 */
function getLngLatCenter(lngLatInDegr) {
  function rad2degr(rad) { return rad * 180 / Math.PI; }
  function degr2rad(degr) { return degr * Math.PI / 180; }
  var LATIDX = 1;
  var LNGIDX = 0;
  var sumX = 0;
  var sumY = 0;
  var sumZ = 0;

  for (var i = 0; i < lngLatInDegr.length; i++) {
    var lat = degr2rad(lngLatInDegr[i][LATIDX]);
    var lng = degr2rad(lngLatInDegr[i][LNGIDX]);
    // sum of cartesian coordinates
    sumX += Math.cos(lat) * Math.cos(lng);
    sumY += Math.cos(lat) * Math.sin(lng);
    sumZ += Math.sin(lat);
  }

  var avgX = sumX / lngLatInDegr.length;
  var avgY = sumY / lngLatInDegr.length;
  var avgZ = sumZ / lngLatInDegr.length;

  // convert average x, y, z coordinate to latitude and longtitude
  var lng = Math.atan2(avgY, avgX);
  var hyp = Math.sqrt(avgX * avgX + avgY * avgY);
  var lat = Math.atan2(avgZ, hyp);

  return ([rad2degr(lat), rad2degr(lng)]);
}


function test() {
  getLocalReverseGeocode(-122.4194, 37.7749).then(
    result => {
      console.log(result);
    }
  );
}

async function getCountryReverseGeocode(longitude, latitude) {
  const accessToken = getToken();
  const url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
  const requestURL = `${url}${longitude},${latitude}.json?types=country&access_token=${accessToken}`;
  const revGeocodeResults = await axios.get(requestURL);
  if (revGeocodeResults.data.features.length) {
    return revGeocodeResults.data.features[0].place_name;
  }
  return undefined;
}

/**
 * @description Decodes polyline into a latitude-longitude array
 * @param {polyline} mapboxRoutePolyline MapBox Polyline
 * @param {number} precision Precision value
 * @return Array of latitude-longitude coordinates
 */
function decodeMapboxRoutePolyline(mapboxRoutePolyline, precision) {
  return polyline.decode(mapboxRoutePolyline, precision);
}

export {
  getEncodedCoordinates,
  getDecodedCoordinates,
  getToken,
  getBoundsFromLngLatArr,
  getBoundsFromVehicleParseArr,
  distanceBetweenTwoGeopoints,
  getColour,
  getGeocode,
  getHistoryDutiesArray,
  getHistoryLegArray,
  getPathToNextJob,
  getReverseGeocode,
  getRoadRoute,
  getRoute,
  initializeMapbox,
  getLngLatCenter,
  test,
  getCountryReverseGeocode,
  decodeMapboxRoutePolyline,
};
