import React from 'react';
import PropTypes from 'prop-types';

// Components
import GeofenceRow from 'components/MapListSidebar/view/GeofenceRow';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import { MDBContainer } from "mdbreact";

// CSS
import styles from './ListStyles.module.scss';

/**
 * @Deprecated - old geofence sidebar component
 */
class GeofenceList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loadedAllGeofences: false, // bool to determine if number of geofences hasnt changed
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.Geofence.fetched) {
      this.state.loadedAllGeofences = false;
      return;
    }
    this.state.loadedAllGeofences = true;
  }

  render() {
    const { loadedAllGeofences } = this.state;
    // determine when and how to show the loading icon
    let loadingIconRow;
    if (loadedAllGeofences) {
      loadingIconRow = undefined;
    } else if (!loadedAllGeofences) {
      if (!this.props.Geofence.fetched) {
        loadingIconRow = (
          <tr className={styles.loadingIconNoHover}>
            <td colSpan="7">
              <div className={styles.loadingIcon}>
                <LoadingIcon />
              </div>
            </td>
          </tr>
        );
      } else {
        loadingIconRow = undefined;
      }
    }
    return (
      <MDBContainer className="px-0">
        {this.props.Geofence.fetched === true && this.props.Geofence.geofences.length === 0 &&
          <tr>
            <td colSpan="7" className="emptyRowMessage translate-me">You have no geofences on Switchboard</td>
          </tr>
        }

        {loadingIconRow}

        {this.props.Geofence.geofences.length > 0 &&
          this.props.Geofence.geofences.map(geofence =>
            <GeofenceRow
              active={this.props.activeSidebarObject && this.props.activeSidebarObject.type && this.props.activeSidebarObject.object.id === geofence.id}
              addEditGeofence={this.props.addEditGeofence}
              setEditMode={() => this.props.setAddEditGeofence('edit', geofence)}
              cancelEditMode={() => this.props.setAddEditGeofence()}
              handleHover={this.props.hoverSidebarObject}
              selectGeofence={() => this.props.selectSidebarObject('Geofence', geofence, true)}
              geofence={geofence}
            />
          )
        }
      </MDBContainer>
    );
  }
}

GeofenceList.propTypes = {
  addEditGeofence: PropTypes.object.isRequired,
  setAddEditGeofence: PropTypes.func.isRequired,
  Geofence: PropTypes.object.isRequired,
  selectSidebarObject: PropTypes.func.isRequired,
  hoverSidebarObject: PropTypes.func.isRequired,
  activeSidebarObject: PropTypes.object,
};

export default GeofenceList;
