import React from 'react';
import PropTypes from 'prop-types';

// components
import ReferenceNumberSet from 'components/Dispatch/JobReferenceNumbers/ReferenceNumberSet';

// sbCore Components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Skeleton from 'sbCore/Skeleton/Skeleton';

// api
import { getAttribute } from 'api/Parse';
import { getDispatchReferenceNumbers } from 'api/Dispatch/DispatchReferenceNumber.old';
import { getDispatchItemTransfersByJob } from 'api/Dispatch/DispatchShipment';
import { updateDispatchJob } from 'api/Dispatch/DispatchJob';

// styles
import './styles.scss';

class JobReferenceNumbers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      referenceNumberSets: [{ referenceNumbers: [] }],
      referenceNumberValue: null,
      referenceNumber: null,
      customerDispatchOrganization: null,
    };

    this.refreshState = this.refreshState.bind(this);
  }

  async componentDidMount() {
    await this.refreshState();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.isRefreshState !== this.props.isRefreshState) {
      await this.refreshState();
    }

    if ((prevState.referenceNumberValue !== this.state.referenceNumberValue) && this.props.dispatchJob) {
      await updateDispatchJob(this.props.dispatchJob, undefined, { referenceNumber: this.state.referenceNumberValue }, true);
    }
  }

  async refreshState() {
    const { dispatchJob, setIsLoading } = this.props;
    let referenceNumbers;
    let shipments;
    const dispatchJobId = getAttribute(dispatchJob, 'objectId', true);

    await setIsLoading(true, 'Loading');
    try {
      const promises = [getDispatchReferenceNumbers(dispatchJob), getDispatchItemTransfersByJob(dispatchJobId)];
      [referenceNumbers, shipments] = await Promise.all(promises);
    } catch (err) {
      console.error(err);
    } finally {
      await setIsLoading(false);
    }
    // Background: shipment and transfer are used interchangeably, they refer to the same thing
    // a) Create a shipment -> [reference numbers array] map
    const shipmentReferenceNumbersMap = {};
    const has = Object.prototype.hasOwnProperty;
    referenceNumbers.forEach(refNum => {
      const refNumShipmentId = getAttribute(getAttribute(refNum, 'dispatchTransfer', true), 'objectId', true);
      if (!has.call(shipmentReferenceNumbersMap, refNumShipmentId)) {
        shipmentReferenceNumbersMap[refNumShipmentId] = [];
      }
      shipmentReferenceNumbersMap[refNumShipmentId].push(refNum);
    });
    // b) Iterate through shipments and extract their reference numbers from step (a)
    // The first set of ref nums will not have an associated shipment they are undefined
    const referenceNumberSets = [{
      dispatchTransfer: undefined,
      referenceNumbers: shipmentReferenceNumbersMap[undefined] || [],
    }];
    // The remaining sets are populated in order of shipments query (ascending createdAt)
    shipments.forEach(shipment => {
      const shipmentId = getAttribute(shipment, 'objectId', true);
      const associatedRefNumArray = shipmentReferenceNumbersMap[shipmentId];
      referenceNumberSets.push({
        dispatchTransfer: shipment,
        referenceNumbers: associatedRefNumArray,
      });
    });

    const referenceNumber = getAttribute(this.props.dispatchJob, 'referenceNumber');
    const customerDispatchOrganization = getAttribute(this.props.dispatchJob, 'customerDispatchOrganization');
    this.setState({ referenceNumberSets, referenceNumber, customerDispatchOrganization });
  }

  render() {
    const { props, state } = this;

    let jobIdTemplate = (
      <Skeleton width="10rem" className="p-mb-2" />
    );

    let referenceNumberTemplate = (
      <Skeleton width="10rem" className="p-mb-2" />
    );

    let customerReferenceNumberTemplate = (
      <Skeleton width="10rem" className="p-mb-2" />
    );

    // only render this if the set is 'General'
    if (props.dispatchJob && state.referenceNumberSets[0]) {
      jobIdTemplate = (
        <div className="value mb-2">
          { getAttribute(props.dispatchJob, 'batchId') }
          {!state.customerDispatchOrganization && (
            <div className="value-sub font-italic">(ID updates by selecting Customer)</div>
          )}
        </div>
      );

      referenceNumberTemplate = (
        <div className={`value${(!state.referenceNumber && !state.referenceNumberValue) ? ' text-warning' : ''} mb-2`}>{ ((state.referenceNumberValue !== null) ? state.referenceNumberValue : state.referenceNumber) || 'N/A'}</div>
      );

      customerReferenceNumberTemplate = (
        <div className={`value${(!state.referenceNumber && !state.referenceNumberValue) ? ' text-warning' : ''} mb-2`}>{ ((state.referenceNumberValue !== null) ? state.referenceNumberValue : state.referenceNumber) || 'N/A' }</div>
      );
    }

    return (
      <div className="job-reference-numbers flex flex-column">
        <div className="mb-4">
          <div className="mt-2">
            <InputLabel>Job ID</InputLabel>
            {jobIdTemplate}
          </div>
          <div>
            <InputLabel>Job Ref #</InputLabel>
            {referenceNumberTemplate}
          </div>
          <div>
            <InputLabel>Customer Ref #</InputLabel>
            {customerReferenceNumberTemplate}
          </div>
        </div>
        {state.referenceNumberSets.map((set, index) => {
          const { referenceNumbers, dispatchTransfer } = set;
          // if the set is 'General', display id and reference numbers of the dispatch job
          if (index === 0) {
            return (
              <>
                <ReferenceNumberSet
                  key={`ref-num-row-${getAttribute(set.dispatchTransfer, 'objectId', true)}`}
                  referenceNumbers={referenceNumbers}
                  order={index}
                  refreshState={this.refreshState}
                />
              </>
            );
          }

          return (
            <ReferenceNumberSet
              key={`ref-num-row-${getAttribute(set.dispatchTransfer, 'objectId', true)}`}
              referenceNumbers={referenceNumbers}
              order={index}
              refreshState={this.refreshState}
            />
          );
        })}
      </div>
    );
  }
}

JobReferenceNumbers.propTypes = {
  disabled: PropTypes.bool,
  dispatchJob: PropTypes.object,
  setIsLoading: PropTypes.func,
};

export default JobReferenceNumbers;
