import React, { useState, useEffect } from 'react';

// API
import { addDispatchAccessorial, updateDispatchAccessorial } from 'api/Dispatch/DispatchAccessorial';
import { addDispatchPayable, updateDispatchPayable, getDispatchPayables } from 'api/Dispatch/DispatchPayable';
import { updateInvoiceOutdated } from 'api/Dispatch/DispatchJob';
import { getDispatchJobAccountingRecords } from 'api/Dispatch/DispatchJobAccounting';
import { getDispatchPayees, addDispatchPayee } from 'api/Dispatch/DispatchPayee';
import { getDrivers } from 'api/Driver/Driver';
import { getDispatchOrganizations } from 'api/Dispatch/DispatchOrganization';

// CSAPI API
import { getAttribute, getCurrentUser } from 'sb-csapi/dist/AAPI';

// CSAPI Enums
import { Currency } from 'sb-csapi/dist/enums/Finance/Currency';
import { ServiceProvider } from 'sb-csapi/dist/enums/Dispatch/ServiceProvider';
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';
import { Payee } from 'sb-csapi/dist/enums/Dispatch/Payee';
import { AccountingEntryType } from 'sb-csapi/dist/enums/Dispatch/AccountingEntryType';

// CSAPI Objects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Components
import ServiceProviderTypeDropdown from 'components/Dispatch/ServiceProviderTypeDropdown/ServiceProviderTypeDropdown';
import DispatchOrganizationAutocomplete from 'components/Dispatch/DispatchOrganizationAutocomplete/DispatchOrganizationAutocomplete';
import DispatchTransferDropdown from 'components/Dispatch/DispatchTransferDropdown/DispatchTransferDropdown';
import DispatchJobAutocomplete from 'components/Dispatch/DispatchJobAutocomplete/DispatchJobAutocomplete';

// sbCore Components
import Dialog from 'sbCore/Dialog/Dialog';
import Button from 'sbCore/Button/Button';
import Autocomplete from 'sbCore/Autocomplete/Autocomplete';
import DriverAutocomplete from 'sbCore/DriverAutocomplete/DriverAutocomplete';
import InputNumber from 'sbCore/InputNumber/InputNumber';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import InputText from 'sbCore/InputText/InputText';
import Checkbox from 'sbCore/Checkbox/Checkbox';
import Divider from 'sbCore/Divider/Divider';

// Styles
import './style.scss';

/**
 * @description Dialog for adding/editing a DispatchAccessorial
 * @param {Boolean} visible - If the form is visible
 * @param {DispatchJob} dispatchJob - Passed DispatchJob
 * @param {DispatchTransfer} dispatchTransfer - Passed DispatchTransfer
 * @param {DispatchAccessorial} [dispatchAccessorial] - If passed, open the dialog in edit mode
 * @param {Function} [onCancel] - Callback for when the cancel button is triggered
 * @param {Function} [onSave] - Callback when the organization is saved
 * @param {Boolean} [isLoading] - Parent is loading state
 * @returns
 */
function DispatchAccessorialEditDialog({ ...props }) {

  // ** useStates ** //
  const [hideFromInvoice, setHideFromInvoice] = useState(false);
  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);

  const [selectedDispatchJob, setSelectedDispatchJob] = useState(null);
  const [selectedDispatchTransfer, setSelectedDispatchTransfer] = useState(null);

  const [serviceProviderType, setServiceProviderType] = useState(null);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  // ** useEffects ** //

  // If a DispatchJob's passed, updated the selected DispatchJob
  useEffect(() => {
    if (!props.dispatchJob) return;
    setSelectedDispatchJob(props.dispatchJob);
    setIsLoading(false);
  }, [props.dispatchJob]);

  // If a DispatchTransfer's passed, updated the selected DispatchTransfer
  useEffect(() => {
    if (!props.dispatchTransfer) return;
    setSelectedDispatchTransfer(props.dispatchTransfer);
  }, [props.dispatchTransfer]);

  // If decided that the accessorial is no longer hidden, reset the service provider states
  useEffect(() => {
    if (!hideFromInvoice) {
      setServiceProviderType(null);
      setSelectedDriver(null);
      setSelectedOrganization(null);
    }
  }, [hideFromInvoice]);

  // If a DispatchAccesorial's passed, update the dialog with its information
  useEffect(() => {
    if (!props.dispatchAccessorial) return;

    let didCancel = false;

    async function getDriver(filters) {
      const { drivers } = await getDrivers(
        undefined,
        undefined,
        undefined,
        filters,
        undefined,
        undefined,
        true,
      );
      const driver = (drivers && drivers.length > 0) ? drivers[0] : null;
      if (!didCancel) setSelectedDriver(driver);
    }

    async function getDispatchOrganization(filters) {
      const { dispatchOrganizations } = await getDispatchOrganizations(
        undefined,
        undefined,
        undefined,
        filters,
        undefined,
        undefined,
        undefined,
        undefined,
      );

      const dispatchOrganization = (dispatchOrganizations && dispatchOrganizations.length > 0) ? dispatchOrganizations[0] : null;
      if (!didCancel) setSelectedOrganization(dispatchOrganization);
    }

    if (!props.dispatchAccessorial) return;

    const dispatchAccessorial = props.dispatchAccessorial;

    const _name = getAttribute(dispatchAccessorial, 'name');
    const _amount = getAttribute(dispatchAccessorial, 'amount');

    const isInInvoice = getAttribute(dispatchAccessorial, 'isInInvoice');

    const dispatchJob = getAttribute(dispatchAccessorial, 'dispatchJob');
    const dispatchTransfer = getAttribute(dispatchAccessorial, 'dispatchTransfer');

    setName(_name);
    setAmount(_amount);
    setHideFromInvoice(!isInInvoice);
    setSelectedDispatchJob(dispatchJob);
    setSelectedDispatchTransfer(dispatchTransfer);

    // set states of service provider type and service provider here
    if (!isInInvoice) {
      const dispatchPayee = getAttribute(dispatchAccessorial, 'dispatchPayee');
      const payeeName = getAttribute(dispatchPayee, 'name', true);
      const payeeCode = getAttribute(dispatchPayee, 'code', true);
      const payeeType = getAttribute(dispatchPayee, 'type', true);

      const filters = [];
      if (payeeType === Payee.DRIVER.type) {
        setServiceProviderType(ServiceProvider.DRIVER.type);

        if (payeeName) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'user_fullName', payeeName));
        if (payeeCode) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'user_username', payeeCode));
        getDriver(filters);

      } else if (payeeType === Payee.COMPANY.type) {
        setServiceProviderType(ServiceProvider.ORGANIZATION.type);

        if (payeeName) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'organizationName', payeeName));
        getDispatchOrganization(filters);
      }
    }

    setIsLoading(false);
    return () => { didCancel = true; };
  }, [props.dispatchAccessorial]);

  // Set the current loading state to the parent loading state if changed
  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  // ** Callback Functions ** //

  // Creates a new DispatchAccessorial and DispatchPayable if required
  async function saveAccessorial() {
    if (!selectedDispatchJob) return;

    const dispatchAccessorialObj = {
      name,
      amount,
      dispatchJob: selectedDispatchJob,
      dispatchTransfer: selectedDispatchTransfer,
      isInInvoice: !hideFromInvoice,
      type: 15, /** @TODO type is custom. In the future, replace with Accessorial enum */
    };

    const dispatchJobObjectId = getAttribute(selectedDispatchJob, 'objectId', true);
    const { dispatchJobAccountings } = await getDispatchJobAccountingRecords(
      undefined,
      undefined,
      undefined,
      [new Filter(QueryRestriction.EQUAL_TO, 'dispatchJob', dispatchJobObjectId)],
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      true,
    );

    let dispatchJobAccounting;
    if (dispatchJobAccountings && dispatchJobAccountings.length > 0) {
      dispatchJobAccounting = dispatchJobAccountings[0];
    }
    // If a DispatchAccessorial was passed, update it. If not, create a new DispatchAccessorial
    let dispatchAccessorial = props.dispatchAccessorial;
    if (props.dispatchAccessorial) {
      dispatchAccessorial = await updateDispatchAccessorial(props.dispatchAccessorial, undefined, dispatchAccessorialObj, true);

      // If the entryType is a payable and the accessorial's being updated, void the old payable
      const entryType = getAttribute(dispatchAccessorial, 'entryType');
      if (entryType === AccountingEntryType.PAYABLE.type) {
        const filters = ([
          new Filter(QueryRestriction.EQUAL_TO, 'dispatchAccessorial', props.dispatchAccessorial),
          new Filter(QueryRestriction.NOT_EQUAL_TO, 'isVoided', true),
          new Filter(QueryRestriction.EQUAL_TO, 'dispatchJobAccounting', dispatchJobAccounting),
        ]);

        const { dispatchPayables } = await getDispatchPayables(
          undefined,
          undefined,
          filters,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true,
        );

        const previousDispatchPayable = (dispatchPayables && dispatchPayables.length > 0) ? dispatchPayables[0] : undefined;

        if (previousDispatchPayable) await updateDispatchPayable(previousDispatchPayable, undefined, { isVoided: true }, true);
        /** @TODO automatic voiding of payables/receivables should prompt the user first */
      }
    } else {
      dispatchAccessorial = await addDispatchAccessorial(dispatchAccessorialObj);
    }

    // If not hidden from the invoice, create a DispatchPayable and update the DispatchJob's isInvoiceOutdated to true
    if (!hideFromInvoice) {
      const dispatchPayableObj = {
        dispatchJobAccounting,
        dispatchAccessorial,
      };

      await addDispatchPayable(dispatchPayableObj);
      await updateInvoiceOutdated(selectedDispatchJob, true);
    }

    // If hidden from the invoice, find the associated DispatchPayee or create a new one. Associate it with
    // the DispatchAccessorial and newly created DispatchPayable
    if (hideFromInvoice && serviceProviderType !== null) {
      let dispatchPayee;
      if (serviceProviderType === ServiceProvider.DRIVER.type && selectedDriver) {
        const driverName = getAttribute(selectedDriver, 'user_fullName');
        const username = getAttribute(selectedDriver, 'user_username');
        dispatchPayee = await findAssociatedDispatchPayee(driverName, username);
        if (!dispatchPayee) dispatchPayee = await addAssociatedDispatchPayee(driverName, username, Payee.DRIVER.type);
      } else if (serviceProviderType === ServiceProvider.ORGANIZATION.type && selectedOrganization) {
        const organizationName = getAttribute(selectedOrganization, 'organizationName');
        const organizationId = getAttribute(selectedOrganization, 'organizationId');
        dispatchPayee = await findAssociatedDispatchPayee(organizationName, organizationId);
        if (!dispatchPayee) dispatchPayee = await addAssociatedDispatchPayee(organizationName, organizationId, Payee.COMPANY.type);
      }

      await updateDispatchAccessorial(dispatchAccessorial, undefined, { dispatchPayee }, true);

      // Create DispatchPayable here
      const dispatchPayableObj = {
        dispatchJobAccounting,
        dispatchAccessorial,
        dispatchPayee,
      };
      await addDispatchPayable(dispatchPayableObj);
    }

    if (props.onCancel) {
      props.onCancel();
    }

    onCancel();
  }

  // Reset the component states (except for the DispatchJob/DispatchTransfer)
  function onCancel() {
    if (props.onCancel) {
      props.onCancel();
    }

    setHideFromInvoice(false);
    setIsLoading(false);
    setName('');
    setAmount(0);
    setSelectedDispatchJob(null);
    setSelectedDispatchTransfer(null);
    setServiceProviderType(null);
    setSelectedDriver(null);
    setSelectedOrganization(null);
  }

  // Callback function to set the selected DispatchJob
  const onSelectDispatchJob = (dispatchJob) => {
    setSelectedDispatchJob(dispatchJob);

    if (!dispatchJob) setSelectedDispatchTransfer(null);
  };

  // Callback function to reset the selected providers if the provider type changes
  function onServiceProviderTypeChange(serviceProviderType) {
    setSelectedDriver(null);
    setSelectedOrganization(null);
    setServiceProviderType(serviceProviderType);
  }

  // Find the associated DispatchPayee
  async function findAssociatedDispatchPayee(name, code) {
    // Check if a DispatchPayee exists
    const filters = [];
    if (name) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'name', name));
    if (code) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'code', code));

    const { dispatchPayees } = await getDispatchPayees(
      undefined,
      undefined,
      filters,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      false,
    );
    let dispatchPayee;
    if (dispatchPayees && dispatchPayees.length > 0) dispatchPayee = dispatchPayees[0];

    return dispatchPayee;
  }

  async function addAssociatedDispatchPayee(name, code, type) {
    const currentUser = getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
    const dispatchPayeeObj = {
      name,
      code,
      type,
      belongsToCompany,
    };
    const dispatchPayee = await addDispatchPayee(dispatchPayeeObj);
    return dispatchPayee;
  }

  // Renders the footer of the Dialog
  function renderFooter() {
    return (
      <div className="dispatch-accessorial-edit-dialog-footer">
        <div className="text-right">
          {!isLoading && (
            <div className="inline-block mr-3">
              {cancelButton}
            </div>
          )}
          <div className="inline-block">
            {saveButton}
          </div>
        </div>
      </div>
    );
  }

  // ** Components ** //
  const hideFromInvoiceCheckbox = (
    <div className="flex">
      <Checkbox
        checked={hideFromInvoice}
        onChange={e => setHideFromInvoice(e.checked)}
      />
      <InputLabel className="pt-1">Hide From Invoice</InputLabel>
    </div>
  );

  const nameField = (
    <>
      <InputLabel>Name</InputLabel>
      <InputText
        className="p-inputtext-sm name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        warning={!name}
        success={name}
        placeholder="Name"
      />
    </>
  );

  const amountField = (
    <>
      <InputLabel>Amount</InputLabel>
      <InputNumber
        className="p-inputtext-sm amount"
        value={amount === 0 ? null : amount}
        onValueChange={(e) => setAmount(e.value)}
        mode="currency"
        currency={Currency.US.short}
        locale="en-US"
        warning={!amount}
        success={amount}
        placeholder="$0.00"
      />
    </>
  );

  const dispatchJobField = (
    <DispatchJobAutocomplete
      onSelectDispatchJob={onSelectDispatchJob}
      dispatchJob={selectedDispatchJob}
      warning={!selectedDispatchJob}
      success={selectedDispatchJob}
    />
  );

  const dispatchTransferField = (
    <>
      <InputLabel>Shipment</InputLabel>
      <DispatchTransferDropdown
        dispatchJob={selectedDispatchJob}
        dispatchTransfer={selectedDispatchTransfer}
        onDispatchTransferChange={(dispatchTransfer) => setSelectedDispatchTransfer(dispatchTransfer)}
      />
    </>
  );

  const serviceProviderTypeField = (
    <>
      <InputLabel>Type</InputLabel>
      <ServiceProviderTypeDropdown
        serviceProviderType={serviceProviderType}
        onServiceProviderTypeChange={(value) => onServiceProviderTypeChange(value)}
      />
    </>
  );

  const serviceProviderField = (
    <>
      <InputLabel>Provider</InputLabel>

      {serviceProviderType === null && (
        <Autocomplete
          placeholder="Select Service Provider"
          disabled
          dropdown
          warning
        />
      )}

      {serviceProviderType === ServiceProvider.DRIVER.type && (
        <DriverAutocomplete
          driver={selectedDriver}
          onSelectDriver={(driver) => setSelectedDriver(driver)}
          warning={!selectedDriver}
          success={selectedDriver}
          hideLabel
        />
      )}

      {serviceProviderType === ServiceProvider.ORGANIZATION.type && (
        <DispatchOrganizationAutocomplete
          dispatchOrganization={selectedOrganization}
          onSelectDispatchOrganization={(organization) => setSelectedOrganization(organization)}
          warning={!selectedOrganization}
          success={selectedOrganization}
          hideLabel
        />
      )}
    </>
  );

  const cancelButton = (
    <Button
      className="p-button-sm p-button-default"
      label="Cancel"
      onClick={() => onCancel()}
      disabled={isLoading}
    />
  );

  const saveButton = (
    <Button
      className="p-button-sm p-button-info"
      label={!isLoading ? 'Save' : 'Saving...'}
      onClick={async () => {
        setIsLoading(true);
        await saveAccessorial();
      }}
      disabled={isLoading || !selectedDispatchJob}
    />
  );

  // ** Misc ** //
  let className = 'dispatch-accessorial-edit-dialog';
  if (props.className) className += ` ${props.className}`;

  return (
    <Dialog
      className={className}
      visible={props.visible}
      header={`${props.dispatchAccessorial ? 'Edit' : 'Add'} Accessorial / Adjustment`}
      footer={renderFooter()}
      onHide={() => onCancel()}
      closable={false}
      style={{ width: '50vw' }}
    >
      <div className="dispatch-accessorial-edit-dialog">
        <div className="grid">

          <div className="col-12">
            {hideFromInvoiceCheckbox}
          </div>

          <div className="col-6">
            {nameField}
          </div>

          <div className="col-6">
            {amountField}
          </div>

          <div className="col-6">
            {dispatchJobField}
          </div>

          <div className="col-6">
            {dispatchTransferField}
          </div>

        </div>

        {hideFromInvoice && (
          <div className="scalein animation-linear animation-duration-500">

            <Divider />

            <div className="font-bold">Service Provider (If Applicable)</div>

            <div className="grid mt-2">

              <div className="col-6">
                {serviceProviderTypeField}
              </div>

              <div className="col-6">
                {serviceProviderField}
              </div>

            </div>
          </div>
        )}
      </div>

    </Dialog>
  );
}

export default DispatchAccessorialEditDialog;
