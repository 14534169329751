import { t } from 'api/Translate';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "../../../sbHistory";

// Components
import Button from 'sbCore/Button/Button';
import Dialog from 'sbCore/Dialog/Dialog';
import Divider from 'sbCore/Divider/Divider';
import Message from 'sbCore/Message/Message';
import InputText from 'sbCore/InputText/InputText';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import TabMenu from 'sbCore/TabMenu/TabMenu';

// CSS
import styles from './AddEditTripInspection.module.scss';

class AddEditTripInspection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabMenu: {
        items: [
          { label: '1. Equipment Selection' },
          { label: '2. Defects & Inspection' },
          { label: '3. Odometer & Location' },
          { label: '4. Signature' },
        ],
        activeIndex: 0,
      },

    };

    this.createTripInspection = this.createTripInspection.bind(this);
  }

  componentDidMount() {
  }

  createTripInspection(property, value, submit) {
    if (submit) {
      const { driver, scaleToDriverTimezone } = this.props;
      let timezoneOffsetFromUTC = (scaleToDriverTimezone && driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();
      if (scaleToDriverTimezone && this.props.timezoneOffsetFromUTC) timezoneOffsetFromUTC = this.props.timezoneOffsetFromUTC;

      return this.setState({ ...this.state, changesProcessing: true }, () => {
        const { editTripInspectionDialog } = this.state;
        TI.editTripInspection(editTripInspectionDialog.tripInspection, {
          locationDescriptionCA: editTripInspectionDialog.locationDescription,
          locationDescriptionUS: editTripInspectionDialog.locationDescription,
          dateTime: moment.tz(editTripInspectionDialog.dateTime, 'YYYY-MM-DDTHH:mm:ss', timezoneOffsetFromUTC).toDate(),
          disabled: editTripInspectionDialog.removeState,
          odometerKm: editTripInspectionDialog.distanceUnit === 'mi' ? convertDistance(editTripInspectionDialog.odometerValue, 'mi', 'km') : parseFloat(editTripInspectionDialog.odometerValue),
        }).then(
          success => this.toggleEditTripInspectionDialog()
        );
      });
    }
    const newState = { ...this.state };
    const { editTripInspectionDialog } = newState;
    let dateTime = moment(editTripInspectionDialog.dateTime);

    if (property === 'time' || property === 'date') {
      if (property === 'time') {
        dateTime.hour(value.getHours());
        dateTime.minute(value.getMinutes());
      } else {
        // property === date
        dateTime.year(value.getFullYear());
        dateTime.month(value.getMonth());
        dateTime.date(value.getDate());
      }

      dateTime = dateTime.toDate();
      editTripInspectionDialog.dateTime = dateTime;
    } else {
      editTripInspectionDialog[property] = value.trim();
    }
    this.setState(newState);
  }

  render() {
    // ** Components ** //
    const customerRequiredLabel = (
      <InputLabel warning>* Customer Field is Required</InputLabel>
    );

    const contactAutocompleteField = (
      <DispatchContactAutocompleteInput
        dispatchJob={props.dispatchJob}
        autoFocus={props.autoFocus}
        warning={props.warning}
        disabled={props.disabled}
        onSelectDispatchContact={(dispatchContact) => onSelectDispatchContact(dispatchContact)}
        customerDispatchOrganization={props.customerDispatchOrganization}
        onToggleEditDispatchOrganizationContact={() => onToggleEditDispatchOrganizationContact()}
      />
    );


    return (
      <Dialog
        header="Create Trip Inspection"
        visible={this.props.show}
        className="sm:w-11 md:w-10"
        onHide={() => this.props.hideModal()}
        // footer={createAPIModalFooter}
      >

        <TabMenu 
          model={this.state.tabMenu.items} 
          activeIndex={this.state.tabMenu.activeIndex} 
          onTabChange={(e) => this.setState({ ...this.state, tabMenu: { ...this.state.tabMenu, activeIndex: e.index } }) } 
        />

        { this.state.tabMenu.activeIndex === 0 &&
          <table>
            <td>
              <div>Vehicle</div>
              <DispatchContactAutocompleteInput
                dispatchJob={props.dispatchJob}
                autoFocus={props.autoFocus}
                warning={props.warning}
                disabled={props.disabled}
                onSelectDispatchContact={(dispatchContact) => onSelectDispatchContact(dispatchContact)}
                customerDispatchOrganization={props.customerDispatchOrganization}
                onToggleEditDispatchOrganizationContact={() => onToggleEditDispatchOrganizationContact()}
              />
            </td>

            <td>
              <div>Trailer 1</div>

            </td>

            <td>
              <div>Trailer 2</div>

            </td>
          </table>
        }


        {/* <InputLabel>API Label</InputLabel>
        <InputText
          readOnly={apiCredentials}
          type="text"
          placeholder="Label"
          className="p-inputtext-sm w-full opacity-100"
          value={apiLabel}
          onChange={(e) => setApiLabel(e.target.value)}
          error={!apiLabel}
        /> */}

        {/* <div className={`api-credential-container ${apiCredentials ? 'fadeinup' : 'hidden'}`}>
          <Divider />
          <Message
            className="w-full mb-3"
            severity="warn"
            text={(
              <p className="mb-0 ml-1">
                Make sure to copy and store your API credentials securely as you would your own password. The API key is unique and it is
                <span className="font-bold">{' non-recoverable'}</span> if lost.
              </p>
            )}
          />

          <InputLabel>API ID</InputLabel>
          <div className="flex flex-row align-items-center">
            <InputText
              disabled
              type="text"
              className="p-inputtext-sm w-full opacity-100"
              value={apiCredentials?.apiId}
            />
            <Button
              text
              className="mx-2"
              icon="pi pi-copy"
              tooltip={copiedToClipboardContent === apiCredentials?.apiId ? 'Copied!' : 'Copy to clipboard'}
              onClick={() => {
                navigator.clipboard.writeText(apiCredentials?.apiId);
                setCopiedToClipboardContent(apiCredentials?.apiId);
              }}
            />
          </div>

          <InputLabel className="mt-3">API Key</InputLabel>
          <div className="flex flex-row align-items-center">
            <span className="w-full p-input-icon-right">
              <i
                className={`pi ${showAPIKey ? 'pi-eye' : 'pi-eye-slash'} cursor-pointer`}
                onClick={() => setShowAPIKey(!showAPIKey)}
              />
              <InputText
                disabled
                type={showAPIKey ? 'text' : 'password'}
                className="p-inputtext-sm w-full opacity-100"
                value={apiCredentials?.apiKey}
              />
            </span>
            <Button
              text
              className="mx-2"
              icon="pi pi-copy"
              tooltip={copiedToClipboardContent === apiCredentials?.apiKey ? 'Copied!' : 'Copy to clipboard'}
              onClick={() => {
                navigator.clipboard.writeText(apiCredentials?.apiKey);
                setCopiedToClipboardContent(apiCredentials?.apiKey);
              }}
            />
          </div>
            </div> */}
      </Dialog>
    );
  }
}

export default AddEditTripInspection;
