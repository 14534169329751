import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import * as Setters from 'api/Setters';

// Components

// styles
import styles from './RestartSoftwareModal.module.scss';

class RestartSoftwareModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',
    };
    this.handleRestart = this.handleRestart.bind(this);
  }

  handleRestart() {
    this.setState({ ...this.state, loading: true }, () => {
      Setters.restartUserSoftware(this.props.user.id).then(() => {
        this.props.handleClose();
      }, (error) => {
        this.setState({ ...this.state, error: error.message });
      });
    });
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        keyboard={false}
        backdrop="static"
        bsSize="lg"
        onHide={this.props.handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div>
            Are you sure? The driver will be logged out.
            <br />
            <br />
            <b>Make sure your driver isn't driving before you restart your driver's software!</b>
          </div>
          <br />
          <br />
          <div>
            { this.state.error &&
              <div className={styles.error}>
                {this.state.error}
                <br />
                <br />
              </div>
            }
          </div>
          <div className={styles.selection}>
            <button className={`${styles.cancelButton} buttonDefault`} onClick={this.props.handleClose}>Cancel</button>
            <button className={`${styles.restartButton} buttonDefault`} onClick={this.handleRestart} disabled={this.state.loading}>Restart Software</button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

}

RestartSoftwareModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  show: PropTypes.bool,
};

export default RestartSoftwareModal;
