import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// Components
import ReactTooltip from 'react-tooltip';

import ELDEditModal from '../view/ELDEditModal';

import { MDBBtn } from 'mdbreact';

class ELDEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const eldDailyCertification = this.props.eldDailyCertification;
    const nextELDDailyCertification = nextProps.eldDailyCertification;
    if (eldDailyCertification !== nextELDDailyCertification) {
      this.handleClose(false);
    }
  }

  handleClose(updateParentState) {
    this.setState({ ...this.state, show: false }, () => {
      if (updateParentState) {
        this.props.updateHandler(); // send response back to parent container telling it to update eld events
      }
    });
  }

  render() {

    const {
      className, driver, company, eldDailyCertification, singleELDEventEdit, eldEvents, title, tooltip, disabled,
      associatedELDEvents, outlineAutoGeneratedDrivingTimes, outlineEdits, isOverrideELDEdit, isOverrideELDEvents,
    } = this.props;

    const eldEditTooltipId = (singleELDEventEdit) ? `${singleELDEventEdit.id}EditToolTip` : `dayEditToolTip`;
    const eldEditTooltip = (!tooltip) ? '' :
      (
        <ReactTooltip effect="solid" offset={{ left: 0 }} type="dark" id={eldEditTooltipId}>
          <strong className="translate-me">{ tooltip }</strong>
        </ReactTooltip>
      );
    window.Localize.translatePage();
    return (
      <div className="inline">

        { this.state.show && this.props.eldDailyCertification &&
          <ELDEditModal
            show={this.state.show}
            company={company}
            handleClose={this.handleClose}
            driver={driver}
            eldDailyCertification={eldDailyCertification}
            singleELDEventEdit={singleELDEventEdit}
            eldEvents={eldEvents}
            associatedELDEvents={associatedELDEvents}
            outlineAutoGeneratedDrivingTimes={outlineAutoGeneratedDrivingTimes}
            outlineEdits={outlineEdits}
            scaleToDriverTimezone
            isOverrideELDEdit={isOverrideELDEdit}
            isOverrideELDEvents={isOverrideELDEvents}
          />
        }

        <MDBBtn
          className={`${className}`}
          color="secondary"
          size="sm"
          disabled={disabled}
          onClick={() => this.setState({ ...this.state, show: true })}
          data-tip data-for={eldEditTooltipId}
        >
          { eldEditTooltip }
          <span className="translate-me">{ title || 'Edit Log' }</span>
        </MDBBtn>

      </div>
    );
  }

}


ELDEdit.propTypes = {
  className: PropTypes.string,
  company: PropTypes.object,
  disabled: PropTypes.bool,
  singleELDEventEdit: PropTypes.object, // pass in the single eld event to be edited
  updateHandler: PropTypes.func, // call it if we want the list of eld event to be updated upon change/add event
  driver: PropTypes.object,
  eldDailyCertification: PropTypes.object,
  eldEvents: PropTypes.array,
  associatedELDevents: PropTypes.array,
  outlineAutoGeneratedDrivingTimes: PropTypes.bool,
  outlineEdits: PropTypes.bool,
  title: PropTypes.any,
  tooltip: PropTypes.string,
  isOverrideELDEdit: PropTypes.bool,
};

export default ELDEdit;
