/**
 * @description Defines the structure/lookup for private URL's
 *
 * Note that we don't necessarily match the name of the route to any database related names/fields in an attempt to obscure that knowledge
 */

// Dispatch Subroutes
export const DispatchSubroute = Object.freeze({
  JOB_SUMMARY: 'summary',
  JOB_REF_NOTES: 'referencenotes',
  JOB_FREIGHT: 'freight',
  JOB_ROUTES: 'routes',
  JOB_ASSIGN: 'assign',
  JOB_ASSIGN_USERS: 'assign_users',
  JOB_HISTORY: 'history',
  JOB_COMMENTS: 'comments',
  JOB_INVOICE: 'invoice',
  JOB_RATE_CONFIRMATION: 'rateconfirmation',
  JOB_BOL: 'billoflading',
  JOB_DOCUMENTS: 'documents',
  JOB_DOCUMENT_SETTINGS: 'documentsettings',
  ACCOUNTING: 'accounting'
});
