export const AttributeTypes = Object.freeze({
  'BATCH_ID': 'batchId',
  'STATUS': 'status',
  'CREATED_AT': 'createdAt',
  'START_DATE_TIME_UTC': 'startDateTimeUTC',
  'END_DATE_TIME_UTC': 'endDateTimeUTC',
  'ASSIGNED_USER': 'user',
});

export const PaymentTerms = Object.freeze({
  0: 'Due on Receipt',
  7: '7 Days',
  15: '15 days',
  30: '30 days',
  60: '60 days',
  90: '90 days',
});

export const Taxes = Object.freeze({
  0: '0%',
  3: '3%',
  5: '5%',
  7: '7%',
  12: '12%',
  13: '13%',
  15: '15%'
})

export const TaxTable = Object.freeze({
  'AB': { name: 'Alberta (5%)', percentage: 5 },
  'BC': { name: 'British Columbia (5%)', percentage: 5 },
  'MB': { name: 'Manitoba (5%)', percentage: 5 },
  'NB': { name: 'New Brunswick (15%)', percentage: 15 },
  'NL': { name: 'Newfoundland (15%)', percentage: 15 },
  'NS': { name: 'Nova Scotia (15%)', percentage: 15 },
  'NT': { name: 'North West Territories (5%)', percentage: 5 },
  'NU': { name: 'Nunavut (5%)', percentage: 5 },
  'ON': { name: 'Ontario (13%)', percentage: 13 },
  'PE': { name: 'Prince Edward Island (15%)', percentage: 15 },
  'QC': { name: 'Quebec (5%)', percentage: 5 },
  'SK': { name: 'Saskatchewan (5%)', percentage: 5 },
  'YT': { name: 'Yukon (5%)', percentage: 5 },
  'EX': { name: 'Exempt (0%)', percentage: 0 },
});

export const StatusTypes = Object.freeze({
  'CANCELLED': {
    status: 0,
    description: 'Cancelled',
    key: 'CANCELLED',
  },
  'COMPLETED': {
    status: 1,
    description: 'Completed',
    key: 'COMPLETED',
  },
  'INITIATED': {
    status: 2,
    description: 'Initiated',
    key: 'INITIATED',
  },
  'QUOTED': {
    status: 3,
    description: 'Quoted',
    key: 'QUOTED',
  },
  'ORDERED': {
    status: 4,
    description: 'Ordered',
    key: 'ORDERED',
  },
  'DISPATCHED': {
    status: 7,
    description: 'Dispatched',
    key: 'DISPATCHED',
  },
  'IN_TRANSIT': {
    status: 8,
    description: 'In Transit',
    key: 'IN_TRANSIT',
  },
  'INVOICED': {
    status: 5,
    description: 'Invoiced',
    key: 'INVOICED',
  },
  'PAID': {
    status: 6,
    description: 'Paid',
    key: 'PAID',
  }
});

// steps of dispatch - cancelled not included in the sequence as it's exclusive behaviour
export const StatusTypesOrdered = Object.freeze([
  StatusTypes.INITIATED, StatusTypes.QUOTED, StatusTypes.ORDERED, StatusTypes.DISPATCHED, StatusTypes.IN_TRANSIT, StatusTypes.INVOICED, StatusTypes.PAID, StatusTypes.COMPLETED,
]);

export const StatusTypesOrderedFull = Object.freeze([
  StatusTypes.CANCELLED, StatusTypes.INITIATED, StatusTypes.QUOTED, StatusTypes.ORDERED, StatusTypes.DISPATCHED, StatusTypes.IN_TRANSIT, StatusTypes.INVOICED, StatusTypes.PAID, StatusTypes.COMPLETED,
]);


export function getStatusDescription(status) {
  let description = 'N/A';
  Object.keys(StatusTypes).find(key => {
    const match = StatusTypes[key].status === status;
    if (match) {
      description = StatusTypes[key].description;
    }
    return match;
  });
  return description;
}

export const AssignStatusTypes = Object.freeze({
  'UNASSIGNED': {
    status: 0,
    description: 'Unassigned',
    key: 'UNASSIGNED',
  },
  'PARTIAL': {
    status: 1,
    description: 'Partial Assigned',
    key: 'PARTIAL',
  },
  'ASSIGNED': {
    status: 2,
    description: 'Assigned',
    key: 'ASSIGNED',
  },
});
