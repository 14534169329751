import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// api
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getDispatchContacts } from 'api/Dispatch/DispatchContact';

// sbobjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// enums
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// sbcore components
import Autocomplete from 'sbCore/Autocomplete/Autocomplete';

/**
 * @description Retrieve DispatchOrganizations given a search term
 * @param {String} className - Custom container className
 * @param {Function} onSelectDispatchContact - Callback function that returns the selected dispatch organization contact
 * @param {CustomerDispatchOrganization} customerDispatchOrganization - Used to update contact list if selected dispatch organization changes
 * @param {DispatchJob} dispatchJob - Used to get the current customerDispatchOrganization from the current dispatchJob
 * @param {Object} style - Custom inline styles
 * @param {bool} warning - Makes input border yellow
 * @param {bool} autoFocus - Boolean to automatically focus on the autocomplete on load
 * @param {bool} disabled - Boolean to disable component
 * @param {Function} onToggleEditDispatchOrganizationContact - A callback function that toggles open the edit dispatch organization contact modal
 * @returns
 */
export default function DispatchContactAutocompleteInput({ ...props }) {

  // ** useStates ** //
  const [identifier] = useState(uniqid());
  const [selectedDispatchContact, setSelectedDispatchContact] = useState(undefined);
  const [dispatchContactObjs, setDispatchContactObjs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasError, setHasError] = useState(false);

  // ** useEffectCallback ** //
  // Callback used for useEffects
  const useEffectGetDispatchContactsCallback = () => {
    /*
      Logic Summary:
        - Fetch list of contacts
        - If the user enters a searchTerm, see if it exists within the preloaded results for the user to quickly select. Meanwhile, concurrently run
          a query matching the searchTerm to fetch additional results that may be outside of the preloaded results
    */
    setHasError(false);

    // Check if selectectedDispatchContact is an object and set it if it is, else continue
    if ((typeof searchTerm) === 'object') {
      return setSelectedDispatchContact(searchTerm.dispatchContact);
    }

    const searchTermTrimmed = (searchTerm || '').trim();

    let _dispatchContactObjs = [...dispatchContactObjs].filter(dispatchContactObj => {
      dispatchContactObj.name.includes(searchTermTrimmed);
    });
    setDispatchContactObjs(_dispatchContactObjs);

    let didCancel = false;

    let filters = [
      new Filter(QueryRestriction.LIMIT, undefined, 120),
    ];

    if (searchTermTrimmed.length > 0) {
      filters = [
        new Filter(QueryRestriction.LIMIT, undefined, 15),
        new Filter(QueryRestriction.MATCHES, 'name', searchTermTrimmed),
      ];
    }

    async function _getDispatchContacts() {
      const dispatchOrganization = getAttribute(props.dispatchJob, 'customerDispatchOrganization', true);
      const { dispatchContacts } = await getDispatchContacts(
        dispatchOrganization,
        undefined, // options - default
        undefined, // companyobjectid - default
        false, // include child company results
        filters, // filters
        undefined, // sort - default
        undefined, // includes
        undefined, // selects
        false, // query all
      );

      if (!didCancel) {
        _dispatchContactObjs = dispatchContacts.map(dispatchContact => {
          return {
            name: getAttribute(dispatchContact, 'name'),
            dispatchContact,
          };
        });

        if (!_dispatchContactObjs.length && searchTermTrimmed) setHasError(true);
        setDispatchContactObjs(_dispatchContactObjs);
      }
    }

    _getDispatchContacts();

    return () => { didCancel = true; };
  };

  // ** useEffects ** //
  useEffect(useEffectGetDispatchContactsCallback, []);
  useEffect(useEffectGetDispatchContactsCallback, [searchTerm, props.customerDispatchOrganization]);

  useEffect(() => {
    if (searchTerm && !selectedDispatchContact) {
      setHasError(true);
    }

    if (props.onSelectDispatchContact) {
      props.onSelectDispatchContact(selectedDispatchContact);
    }
  }, [selectedDispatchContact]);

  // ** Components ** //
  const itemTemplate = (item) => {
    return (
      <div className="contact-item">
        <div>{item.name || 'No Name Defined'}</div>
      </div>
    );
  };

  // ** Callbacks ** //
  const inputEl = (
    document.querySelector(`.dispatch-contact-autocomplete-input.${identifier} .p-inputtext.p-component.p-autocomplete-input.p-autocomplete-dd-input`)
  );

  const inputButtonEl = (
    document.querySelector(`.dispatch-contact-autocomplete-input.${identifier} button.p-button`)
  ); // the autocomplete dropdown button

  if (inputEl) {
    inputEl.autocomplete = 'disabled'; // disable the browser's default autocomplete for inputs
  }

  const triggerAutocompleteOnClick = () => {
    // open dropdown if user focuses on input
    if (inputButtonEl) {
      inputButtonEl.click();
    }
  };

  const triggerAutocompleteOnKeyPress = (e) => {
    // if the input is focused
    if (inputButtonEl) {
      const code = (e.keyCode ? e.keyCode : e.which);
      const dropdownPanelEl = document.querySelector(`.dispatch-contact-dropdown-panel.${identifier}`); // Dropdown panel element
      if (code === 13) { // Enter
        if (dropdownPanelEl && dispatchContactObjs.length) { // If the dropdown is already open
          setSelectedDispatchContact(dispatchContactObjs[0].dispatchContact); // Select the first item in dropdown
          return;
        }

        if (hasError) { // If there's no search result
          props.onToggleEditDispatchOrganizationContact();
          return;
        }

        inputButtonEl.click();
      }
    }
  };

  // ** Misc ** //
  let className = `dispatch-contact-autocomplete-input ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  const placeholder = 'Contact name';

  // if the input is not being focused on (which is for some reason dictated by the button's focus),
  // but the user left their search term without selecting a suggestion for the searchTerm
  const isNotFocusedWithText = (inputButtonEl
    && searchTerm
    && ((typeof searchTerm) !== 'object')
    && (document.activeElement !== inputButtonEl)
  );

  const _hasError = isNotFocusedWithText || hasError;

  return (
    <div className={className} style={{ ...props.style }}>
      <Autocomplete
        placeholder={placeholder}
        dropdown
        field="name"
        value={searchTerm}
        suggestions={dispatchContactObjs}
        forceSelection={false}
        itemTemplate={itemTemplate}
        autoFocus={props.autoFocus}
        warning={props.warning}
        error={_hasError}
        completeMethod={() => setDispatchContactObjs([...dispatchContactObjs])}
        onChange={(e) => setSearchTerm(e.value)}
        onClick={() => triggerAutocompleteOnClick()}
        onKeyPress={(e) => triggerAutocompleteOnKeyPress(e)}
        disabled={props.disabled}
        panelClassName={`dispatch-contact-dropdown-panel ${identifier}`}
      />
    </div>
  );
}
