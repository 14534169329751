import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { MDBBtn, MDBRow, MDBCol, MDBIcon, MDBInput } from 'mdbreact';

// API
import { compressWhitespace } from 'api/Helpers';

// Components
import Calendar from 'sbCore/Calendar/Calendar';
import DatePicker from 'components/DatePicker/container/FormControlDatePicker';

// CSS
import styles from './FilterForm.module.scss';
import './style.scss';

class FilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      buttonSwap: false, // determine if we need to hide the filter button for the clear button, or vice-versa
      activeLabels: {},
      activeInputs: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDatePickerOnChange = this.handleDatePickerOnChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.toggleComponentStatus = this.toggleComponentStatus.bind(this);
  }

  componentWillMount() {
    for (let i = 0; i < this.props.fields.length; i++) {
      const currentField = this.props.fields[i];
      if (!this.state.filter[currentField.attrName]) {
        this.state.filter[currentField.attrName] = { name: currentField.attrName, attribute: currentField.attrName };
      }
      this.state.filter[currentField.attrName].type = currentField.type;
      this.state.filter[currentField.attrName].queryType = currentField.queryType;
      this.state.filter[currentField.attrName].innerQueryClass = currentField.innerQueryClass;
      this.state.filter[currentField.attrName].innerQueryProperty = currentField.innerQueryProperty;
      this.state.filter[currentField.attrName].innerQueryType = currentField.innerQueryType;
      this.state.filter[currentField.attrName].innerQueryValue = currentField.innerQueryValue;
      this.state.filter[currentField.attrName].value = currentField.value;
      if (currentField.value) {
        this.state.activeLabels[currentField.attrName + currentField.fullName] = true;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fields !== this.props.fields) {
      for (let i = 0; i < this.props.fields.length; i++) {
        const currentField = this.props.fields[i];
        if (!this.state.filter[currentField.attrName]) {
          this.state.filter[currentField.attrName] = { name: currentField.attrName, attribute: currentField.attrName };
        }
        this.state.filter[currentField.attrName].type = currentField.type;
        this.state.filter[currentField.attrName].queryType = currentField.queryType;
        this.state.filter[currentField.attrName].value = currentField.value;
        if (currentField.value) {
          this.state.activeLabels[currentField.attrName + currentField.fullName] = true;
        }
      }
    }
  }

  handleInput(e) {
    if (e.charCode === 13 || e.key === 'Enter') {
      this.handleFilter();
    }
  }

  handleChange(e) {
    // add attr/val of user input to state
    this.state.filter[e.target.id].value = e.target.value;
    this.state.buttonSwap = false;
    this.setState(this.state, () => {
      if (this.props.submitOnSelect) {
        this.handleFilter();
      }
    });
  }

  handleDateChange(dateMoment, attrName, dateQueryType, submitOnSelect) {
    // add attr/val of user input to state
    const newState = { ...this.state };
    if (dateQueryType === 'lessThanOrEqualTo') {
      newState.filter[attrName].toValue = dateMoment;
    } else if (dateQueryType === 'greaterThanOrEqualTo') {
      newState.filter[attrName].fromValue = dateMoment;
    } else {
      newState.filter[attrName].value = dateMoment;
    }
    newState.filter[attrName].dateQueryType = dateQueryType;
    newState.buttonSwap = false;
    this.setState(newState, () => {
      if (submitOnSelect) {
        this.handleFilter();
      }
    });
  }

  handleDatePickerOnChange(dateMoment, attrName, submitOnSelect) {
    // add attr/val of user input to state
    const newState = { ...this.state };
    newState.filter[attrName].value = dateMoment;
    newState.buttonSwap = false;
    this.setState(newState, () => {
      if (submitOnSelect) {
        this.handleFilter();
      }
    });
  }

  handleFilter() {
    // pass up input to parent
    const filterKeys = Object.keys(this.state.filter); // get the keys used, which is stored as this components state
    const filterKeysLen = filterKeys.length;
    const filterArr = [];
    for (let i = 0; i < filterKeysLen; i++) {
      // if the filter key is used, add it to our filter object
      const currentKey = filterKeys[i];
      if ((this.state.filter[currentKey].queryType === 'date' || this.state.filter[currentKey].type === 'date') && (this.state.filter[currentKey].value > 0 || this.state.filter[currentKey].fromValue > 0 || this.state.filter[currentKey].toValue > 0)) {
        filterArr.push(this.state.filter[currentKey]);
      } else if ((this.state.filter[currentKey].type === 'datePicker') && (this.state.filter[currentKey].value && (this.state.filter[currentKey].value.valueOf() > 0))) {
        filterArr.push(this.state.filter[currentKey]);
      } else if (this.state.filter[currentKey].value && compressWhitespace(this.state.filter[currentKey].value) !== '') {
        filterArr.push(this.state.filter[currentKey]);
      }
    }
    if (filterArr.length > 0) {
      // if the filter is dirty, swap the button fo sho
      this.state.buttonSwap = true;
    }

    this.props.handleFilter(filterArr, false);
    this.setState(this.state);
  }

  clearFilter() {
    // parent defined clear
    this.props.clearFilter();
    this.state.buttonSwap = false;
    const newState = { ...this.state };
    for (let i = 0; i < this.props.fields.length; i++) {
      const currentField = this.props.fields[i];
      if (!this.state.filter[currentField.attrName]) {
        newState.filter[currentField.attrName] = { name: currentField.attrName, attribute: currentField.attrName };
      }
      newState.filter[currentField.attrName].queryType = currentField.queryType;
    }
    this.setState(newState);
  }

  toggleComponentStatus(compId, active, inputValue) {
    const newState = { ...this.state };
    newState.activeInputs = Object.assign({}, newState.activeInputs);
    newState.activeInputs[compId] = active;
    if (active || !inputValue || inputValue.length == 0) {
      newState.activeLabels = Object.assign({}, newState.activeLabels);
      newState.activeLabels[compId] = active;
    }
    this.setState(newState);
  }

  render() {
    // Take fields given from parent of format {fieldName: Field Display Name}
    this.formGroups = this.props.fields.map((field, index) => {
      if (field.type === 'datePicker') {
        return (
          <div className="d-table-cell px-1" key={index}>
            <div className="form-group">
              <div className="md-form">
                <DatePicker
                  dateFormat="MMM dd, yyyy"
                  className="form-control"
                  type={field.type}
                  selected={this.state.filter[field.attrName].value ? moment(this.state.filter[field.attrName].value) : undefined}
                  ref={`input${field.attrName}${field.queryType}`}
                  onChange={(date) => this.handleDatePickerOnChange(date ? moment(date).startOf('day') : undefined, field.attrName)}
                  autoComplete="off"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoFocus={field.autofocus}
                  onFocus={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, true, e.target.value); }}
                  onBlur={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, false, e.target.value); }}
                />
                <label className={(this.state.activeLabels[field.attrName + field.fullName] ? ' active' : '') + (this.state.activeInputs[field.attrName + field.fullName] ? ' blue-text' : '')} onClick={() => { this.toggleComponentStatus(field.attrName + field.fullName, true); }}>{field.fullName}</label>
              </div>
            </div>
          </div>
        );
      } else if (field.type === 'date') {
        if (field.dateQueryType === 'onDate') {
          return (
            <div className="d-flex px-1" key={index}>
              {field.includeLeftRight &&
                <MDBBtn
                  outline color="primary"
                  className={`${styles.leftRight} p-3 border-0 z-depth-0 align-self-center`}
                  disabled={this.props.disabled}
                  onClick={() => {
                    const leftDate = this.state.filter[field.attrName].value ? this.state.filter[field.attrName].value.subtract(1, 'd') : moment().subtract(1, 'd');
                    this.handleDateChange(leftDate, field.attrName, field.dateQueryType, this.props.submitOnSelect);
                  }}
                >
                  <MDBIcon icon="chevron-left" />
                </MDBBtn>
              }
              <div className="form-group d-inline-block px-1">
                <div className="md-form">
                  {/* <DatePicker
                    dateFormat="MMM dd, yyyy"
                    className="form-control"
                    type={field.type}
                    selected={moment(this.state.filter[field.attrName].value.toDate())}
                    ref={`input${field.attrName}${field.dateQueryType}`}
                    onChange={(date) => this.handleDateChange(moment(date).startOf('day'), field.attrName, field.dateQueryType, this.props.submitOnSelect)}
                    autoComplete="off"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoFocus={field.autofocus}
                    onFocus={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, true, e.target.value); }}
                    onBlur={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, false, e.target.value); }}
                  /> */}
                  <Calendar
                    value={moment(this.state.filter[field.attrName].value).toDate()}
                    onChange={(e) => this.handleDateChange(moment(e.value).startOf('day'), field.attrName, field.dateQueryType, this.props.submitOnSelect)}
                    ref={`input${field.attrName}${field.dateQueryType}`}
                    readOnlyInput
                    monthNavigator
                    dateFormat="MM dd, yy"
                  />
                  <label className={(this.state.activeLabels[field.attrName + field.fullName] ? ' active' : '') + (this.state.activeInputs[field.attrName + field.fullName] ? ' blue-text' : '')} onClick={() => { this.toggleComponentStatus(field.attrName + field.fullName, true); }}>{field.fullName}</label>
                </div>
              </div>
              {field.includeLeftRight &&
                <MDBBtn
                  outline color="primary"
                  className={`${styles.leftRight} p-3 border-0 z-depth-0 align-self-center`}
                  disabled={this.props.disabled}
                  onClick={() => {
                    const rightDate = this.state.filter[field.attrName].value ? this.state.filter[field.attrName].value.add(1, 'd') : moment().add(1, 'd');
                    this.handleDateChange(moment(rightDate).startOf('day'), field.attrName, field.dateQueryType, this.props.submitOnSelect);
                  }}
                >
                  <MDBIcon icon="chevron-right" />
                </MDBBtn>
              }
            </div>
          );
        } else if (field.dateQueryType === 'greaterThanOrEqualTo') {
          return (
            <div className="d-table-cell px-1" key={index}>
              <div className="form-group">
                <div className="md-form">
                  <DatePicker
                    dateFormat="MMM dd, yyyy"
                    className="form-control"
                    type={field.type}
                    selected={this.state.filter[field.attrName].fromValue ? moment(this.state.filter[field.attrName].fromValue) : undefined}
                    ref={`input${field.attrName}${field.dateQueryType}`}
                    onChange={(date) => this.handleDateChange(date ? moment(date).startOf('day') : undefined, field.attrName, field.dateQueryType)}
                    autoComplete="off"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoFocus={field.autofocus}
                    onFocus={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, true, e.target.value); }}
                    onBlur={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, false, e.target.value); }}
                  />
                  <label className={(this.state.activeLabels[field.attrName + field.fullName] ? ' active' : '') + (this.state.activeInputs[field.attrName + field.fullName] ? ' blue-text' : '')} onClick={() => { this.toggleComponentStatus(field.attrName + field.fullName, true); }}>{field.fullName}</label>
                </div>
              </div>
            </div>
          );
        } else if (field.dateQueryType === 'lessThanOrEqualTo') {
          return (
            <div className="d-table-cell px-1" key={index}>
              <div className="form-group">
                <div className="md-form">
                  <DatePicker
                    dateFormat="MMM dd, yyyy"
                    className="form-control"
                    type={field.type}
                    selected={this.state.filter[field.attrName].toValue ? moment(this.state.filter[field.attrName].toValue) : undefined}
                    ref={`input${field.attrName}${field.dateQueryType}`}
                    onChange={(date) => this.handleDateChange(date ? moment(date).endOf('day') : undefined, field.attrName, field.dateQueryType)}
                    autoComplete="off"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoFocus={field.autofocus}
                    onFocus={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, true, e.target.value); }}
                    onBlur={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, false, e.target.value); }}
                  />
                  <label className={(this.state.activeLabels[field.attrName + field.fullName] ? ' active' : '') + (this.state.activeInputs[field.attrName + field.fullName] ? ' blue-text' : '')} onClick={() => { this.toggleComponentStatus(field.attrName + field.fullName, true); }}>{field.fullName}</label>
                </div>
              </div>
            </div>
          );
        } else if (field.dateQueryType === 'dateMonth') {
          return (
            <div className="d-table-cell px-1" key={index}>
              {field.includeLeftRight &&
                <MDBBtn
                  outline color="primary"
                  className={`${styles.leftRight} p-3 border-0 z-depth-0`}
                  onClick={() => {
                    const leftDate = this.state.filter[field.attrName].value ? this.state.filter[field.attrName].value.subtract(1, 'months') : moment().subtract(1, 'months');
                    this.handleDateChange(leftDate, field.attrName, field.dateQueryType, this.props.submitOnSelect);
                  }}
                >
                  <MDBIcon icon="chevron-left" />
                </MDBBtn>
              }
              <div className="form-group d-inline-block my-0">
                <div className="md-form">
                  <DatePicker
                    dateFormat="MMMM yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    className="form-control"
                    type={field.type}
                    selected={moment(this.state.filter[field.attrName].value)}
                    ref={`input${field.attrName}${field.dateQueryType}`}
                    onChange={(date) => this.handleDateChange(moment(date).startOf('day'), field.attrName, field.dateQueryType, this.props.submitOnSelect)}
                    autoComplete="off"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoFocus={field.autofocus}
                    onFocus={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, true, e.target.value); }}
                    onBlur={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, false, e.target.value); }}
                  />
                  <label className={(this.state.activeLabels[field.attrName + field.fullName] ? ' active' : '') + (this.state.activeInputs[field.attrName + field.fullName] ? ' blue-text' : '')} onClick={() => { this.toggleComponentStatus(field.attrName + field.fullName, true); }}>{field.fullName}</label>
                </div>
              </div>
              {field.includeLeftRight &&
                <MDBBtn
                  outline color="primary"
                  className={`${styles.leftRight} p-3 border-0 z-depth-0`}
                  onClick={() => {
                    const rightDate = this.state.filter[field.attrName].value ? this.state.filter[field.attrName].value.add(1, 'months') : moment().add(1, 'months');
                    this.handleDateChange(moment(rightDate).startOf('day'), field.attrName, field.dateQueryType, this.props.submitOnSelect);
                  }}
                >
                  <MDBIcon icon="chevron-right" />
                </MDBBtn>
              }
            </div>
          );
        }
        return (
          <div className="d-table-cell px-1" key={index}>
            <div className="form-group">
              <div className="md-form">
                <DatePicker
                  dateFormat="MMM dd, yyyy"
                  className="form-control"
                  type={field.type}
                  selected={this.state.filter[field.attrName].toValue ? moment(this.state.filter[field.attrName].toValue) : undefined}
                  ref={`input${field.attrName}${field.dateQueryType}`}
                  onChange={(date) => this.handleDateChange(date ? moment(date).endOf('day') : undefined, field.attrName, field.dateQueryType)}
                  autoComplete="off"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  autoFocus={field.autofocus}
                  onFocus={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, true, e.target.value); }}
                  onBlur={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, false, e.target.value); }}
                />
                <label className={(this.state.activeLabels[field.attrName + field.fullName] ? ' active' : '') + (this.state.activeInputs[field.attrName + field.fullName] ? ' blue-text' : '')} onClick={() => { this.toggleComponentStatus(field.attrName + field.fullName, true); }}>{field.fullName}</label>
              </div>
            </div>
          </div>
        );
      } else if (field.type === 'select') {
        return (
          <div className="d-table-cell px-1" key={index}>
            <div className="form-group">
              <div className="md-form">
                <select
                  className={`${styles.filterSelect} custom-select`}
                  value={this.state.filter[field.attrName].value || ''}
                  onChange={this.handleChange}
                  autoFocus={field.autofocus}
                  id={field.attrName}
                  onFocus={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, true, e.target.value); }}
                  onBlur={(e) => { this.toggleComponentStatus(field.attrName + field.fullName, false, e.target.value); }}
                >
                  <option key={`${index}-option-blank`}></option>
                  {field.selectArr.map((option, optionIndex) => (
                    <option key={`${index}-option-${optionIndex}`}>{option}</option>
                  ))}
                </select>
                <label className={(this.state.activeLabels[field.attrName + field.fullName] ? ' active' : '') + (this.state.activeInputs[field.attrName + field.fullName] ? ' blue-text' : '')} onClick={() => { this.toggleComponentStatus(field.attrName + field.fullName, true); }}>{field.fullName}</label>
              </div>
            </div>
          </div>
        );
      } else if (field.type === 'textarea') {
        return (
          <div className="d-table-cell px-1" key={index}>
            <div className="form-group">
              {/* <label className={styles.filterLabel}>{}</label> */}
              <MDBInput
                className="form-control"
                label={field.fullName}
                id={field.attrName}
                type={field.type}
                value={this.state.filter[field.attrName].value || ''}
                // ref={`input${field.attrName}`}
                onChange={this.handleChange}
                onKeyPress={this.handleInput}
                autoComplete="off"
                autoFocus={field.autofocus}
              />
            </div>
          </div>
        );
      }
      // Text input
      return (
        <div className="d-table-cell px-1" key={index}>
          <div className="form-group">
            {/* <label className={styles.filterLabel}>{}</label> */}
            <MDBInput
              className="form-control"
              label={field.fullName}
              id={field.attrName}
              type={field.type}
              value={this.state.filter[field.attrName].value || ''}
              // ref={`input${field.attrName}`}
              onChange={this.handleChange}
              onKeyPress={this.handleInput}
              autoComplete="off"
              autoFocus={field.autofocus}
            />
          </div>
        </div>
      );
    });

    if (!this.state.buttonSwap) {
      this.filterOrClearButton = (
        <div className="d-table-cell px-1 my-auto">
          <MDBBtn className="sb-filter-button" color="primary" size="sm" onClick={this.handleFilter} disabled={this.props.disabled}>
            <MDBIcon icon="filter" />
          </MDBBtn>
        </div>
      );
    } else {
      this.filterOrClearButton = (
        <div className="d-table-cell px-1 my-auto">
          <MDBBtn className="sb-filter-button" outline color="danger" size="sm" onClick={this.clearFilter} disabled={this.props.disabled}>
            <MDBIcon icon="times" />
          </MDBBtn>
        </div>
      );
    }

    return (
      <MDBRow className={`sb-filter-form ${this.props.className}`}>
        <MDBCol size="12" className="d-flex flex-row-reverse">
          {this.filterOrClearButton}
          {this.formGroups}
        </MDBCol>
      </MDBRow>
    );
  }
}

FilterForm.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  newFilterType: PropTypes.bool,
  submitOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FilterForm;
