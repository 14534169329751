import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from 'material-ui/List';
import FontIcon from 'material-ui/FontIcon';
// CSS
import styles from './FriendsList.module.scss';

// import Subheader from 'material-ui/Subheader';
// import Divider from 'material-ui/Divider';
// import PermIdentity from 'material-ui/svg-icons/perm-identity';
// import CommunicationChatBubble from 'material-ui/svg-icons/communication/chat-bubble';

const FriendsList = (props) => {

  let friends = [].concat(props.Chat.friends);
  // props.setFriendsHash(props.Chat.friends);
  // filter friends list based on filter
  friends = friends.filter(friend => {
    const fullName = friend.get('user_fullName');
    const vid = friend.get('vehicle_unitId') + "";
    if (!props.filterString || props.filterString === '' || fullName.indexOf(props.filterString) !== -1 || vid.indexOf(props.filterString) !== -1) return true;
    return false;
  });

  // sort alphabetically
  friends.sort((friendA, friendB) => {
    if (friendA.get('user_fullName') < friendB.get('user_fullName')) return -1;
    if (friendA.get('user_fullName') > friendB.get('user_fullName')) return 1;
    return 0;
  });

  // for now to simulate unread notifications, check each chat to see if unreadCount > 0; if so, grab the rooms users
  // if the users correspond to one of the friends being mapped, mark the friend as unread in ui
  const chats = props.Chat.chats;
  const roomIds = Object.keys(chats);
  let unreadUsers = [];
  for (let i = 0; i < roomIds.length; i++) {
    const chatRoom = chats[roomIds[i]];
    if (chatRoom.unreadCount > 0) {
      unreadUsers = unreadUsers.concat(chatRoom.room.get('users') || []);
    }
  }
  const unreadUserIds = unreadUsers.map(user => user.id);

  // let updatedFriendListItem = [];
  const friendListItems = friends.map(friend => {

    const friendUser = friend.get('user');
    let fullName = "MISSING USER";
    let chatIconClass = 'material-icons';
    if (friendUser) {
      fullName = `${friendUser.get('firstName')} ${friendUser.get('lastName')}`;

      if (fullName.length > 18) fullName = fullName.substr(0, 18) + '...';

      if (unreadUserIds.indexOf(friendUser.id) !== -1) {
        chatIconClass += ` ${styles.unreadChatMessage}`;
      }
    }

    const vehicle = friend.get('vehicle');
    let vehicleInfo;
    if (vehicle) {
      let unitId = vehicle.get('unitId') || 'N/A';
      vehicleInfo = <div style={{ fontSize: '.8em' }}>Vehicle Unit: {unitId}</div>
    }

    const primaryText = (
      <div style={{ verticalAlign: 'middle' }}>
        {fullName}
        {vehicleInfo}
      </div>
    );

    return (
      <ListItem
        key={friend.id}
        className={styles.friendListItem}
        primaryText={primaryText}
        rightIcon={<FontIcon className={chatIconClass} style={{ verticalAlign: 'middle' }}>chat_bubble</FontIcon>}
        onClick={() => props.onFriendsSelect(friend)}
      />
    );
  });

  return (
    <div className={styles.friendsList}>
      <List>
        {friendListItems}
      </List>
    </div>
  );
}

FriendsList.propTypes = {
  Chat: PropTypes.object,
  filterString: PropTypes.any,
  onFriendsSelect: PropTypes.func,
};

export default FriendsList;
