import * as ActionConstants from 'actions/ActionConstants';
import * as Helpers from 'api/Helpers';

const initialState = {
  geofenceEvents: [],
};

function addGeofenceEvent(geofenceEvents, newGeofenceEvent) {
  const newGeofenceEvents = [].concat(geofenceEvents);
  newGeofenceEvents.unshift(newGeofenceEvent);
  return newGeofenceEvents;
}

function deleteGeofenceEvents(stateGeofenceEvents, geofenceEventsToBeDeleted) {
  const newStateGeofenceEvents = stateGeofenceEvents;
  const geofenceEventsToBeDeletedLen = geofenceEventsToBeDeleted.length;
  for (let i = 0; i < geofenceEventsToBeDeletedLen; i++) {
    const index = Helpers.findIndexOfObjArr(newStateGeofenceEvents, 'id', geofenceEventsToBeDeleted[i].id);
    newStateGeofenceEvents.splice(index, 1);
  }
  return newStateGeofenceEvents;
}

export function GeofenceEvent(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_GEOFENCEEVENTS_INPROGRESS:
      return {
        ...state,
        status: action.type,
        fetched: false,
      };
    case ActionConstants.FETCH_GEOFENCEEVENTS_SUCCESS:
      return {
        ...state,
        status: action.type,
        geofenceEvents: action.geofenceEvents,
        error: undefined,
        fetched: true,
      };
    case ActionConstants.FETCH_GEOFENCEEVENTS_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
        fetched: true,
      };
    case ActionConstants.ADD_GEOFENCEEVENT_INPROGRESS:
      return {
        ...state,
        status: action.type,
        geofenceEvents: state.geofenceEvents,
        error: undefined,
      };
    case ActionConstants.ADD_GEOFENCEEVENT_SUCCESS:
      return {
        ...state,
        status: action.type,
        geofenceEvents: addGeofenceEvent(state.geofenceEvents, action.geofenceEvent),
        error: undefined,
      };
    case ActionConstants.ADD_GEOFENCEEVENT_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    case ActionConstants.DELETE_GEOFENCEEVENTS: {
      return {
        ...initialState,
        status: action.type,
      };
    }
    case ActionConstants.DESTROY_GEOFENCEEVENTS: {
      return {
        ...state,
        status: action.type,
        geofenceEvents: deleteGeofenceEvents(state.geofenceEvents, action.geofenceEvents),
      };
    }
    case ActionConstants.UPDATE_GEOFENCEEVENT_INPROGRESS:
      return {
        ...state,
        status: action.type,
        updateReference: action.updateReference,
        error: undefined,
      };
    case ActionConstants.UPDATE_GEOFENCEEVENT_SUCCESS:
      return {
        ...state,
        status: action.type,
        error: undefined,
      };
    case ActionConstants.UPDATE_GEOFENCEEVENT_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    default:
      return state;
  }
}
