import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// CSAPI
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components

// sbCore Components
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';

// Styles
import './style.scss';

/**
 * @description Renders a list displaying seal number
 * @param {DispatchJob} dispatchJob - Dispatch job record
 * @returns
 */

function DispatchSealNumbers({ ...props }) {
  // ** useStates ** //
  const [sealNumbersObjectArr, setSealNumbersObjectArr] = useState([]);

  // ** useEffects ** //

  // Retrieves and formats the dispatch job's seal numbers
  useEffect(() => {
    if (!props.dispatchJob) return;

    // if a the job contains no seal numbers return
    const formattedSealNumbersString = getAttribute(props.dispatchJob, 'sealNumbers');

    if (!formattedSealNumbersString) {
      setSealNumbersObjectArr([]);
      return;
    }

    // format the job's seal numbers for the list
    const sealNumbersArr = formattedSealNumbersString.split(',');

    const _sealNumbersObjectArr = sealNumbersArr.map((sealNumber) => {
      const sealNumberObj = {
        id: uniqid(),
        number: sealNumber,
      };

      return sealNumberObj;
    });

    setSealNumbersObjectArr(_sealNumbersObjectArr);
  }, [props.dispatchJob, props.isRefreshState]);

  const sealNumbersTable = (
    <DataTable className="mb-3" value={sealNumbersObjectArr} emptyMessage="No Seal Numbers">
      <Column field="number" header="Seal Numbers" />
    </DataTable>
  );

  return (
    <div className="dispatch-seal-numbers">
      {sealNumbersTable}
    </div>
  );
}

export default DispatchSealNumbers;
