import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Modal } from 'react-bootstrap';

// Helpers
import { getAttribute } from 'api/Parse';
import * as ELD from 'api/ELD';
import * as Helpers from 'api/Helpers';

// enums
import { DrivingELDEventReferenceInts } from 'enums/ELDEventTypeCode';

// Components
import HOSGraph from 'components/DriverDetails/container/HOSGraph';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import { MDBRow, MDBCol } from 'mdbreact';

// CSS
import styles from './ELDEditModal.module.scss';

class ELDDrivingTimeSwapModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      driver: undefined, // holds driver information related to this state
      driverObject: undefined, // the driver parse object itself
      coDriver: undefined,
      coDriverObject: undefined,
      driverELDEvents: [],
      driverAssociatedELDEvents: [],
      driverDrivingTimes: [],
      coDriverELDEvents: [],
      coDriverAssociatedELDEvents: [],
      coDriverDrivingTimes: [],
      driverELDDailyCertification: undefined,
      driverELDDailyCertificationId: undefined,
      coDriverELDDailyCertification: undefined,
      coDriverELDDailyCertificationId: undefined,
      requestObjects: {
        driver: {
          add: [],
          remove: [],
        },
        coDriver: {
          add: [],
          remove: [],
        },
      },
      errors: [],
      changesProcessing: false,
      editRequests: [], // edit request objects we want to send to server
      hosPreview: {
        driverELDEventsForPreview: [],
        coDriverELDEventsForPreview: [],
      },
      checkboxStates: {},
      confirmRequestModal: {
        show: false,
      },
    };
    this.obtainDrivingTimeFromEvents = this.obtainDrivingTimeFromEvents.bind(this);
    this.obtainEventsFromDrivers = this.obtainEventsFromDrivers.bind(this);
    this.setActive = this.setActive.bind(this);
    this.refreshGraphPreview = this.refreshGraphPreview.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitEditRequests = this.submitEditRequests.bind(this);
    this.filterELDEvents = this.filterELDEvents.bind(this);
  }

  componentDidMount() {
    const { driver, coDriver, onDate } = this.props;
    this.obtainEventsFromDrivers(driver, coDriver, onDate);
  }

  obtainDrivingTimeFromEvents(eldEvents) {
    // given eldevents, find out which are the driving times
    const _eldEvents = [].concat(eldEvents);
    const drivingTimeEvents = [];

    // object to keep tabs of the driving interval we're currently tracking
    let currentDrivingInterval = { startsAtEvent: undefined, endsAtEvent: undefined };

    for (let i = 0; i < eldEvents.length; i++) {
      const eldEvent = eldEvents[i];
      if (!getAttribute(eldEvent, 'coDriver')) continue; // if no codriver pointer, swapping won't work

      const eldEventTypeCodeInt = getAttribute(eldEvent, 'eldEventTypeCodeInt');
      const isDrivingEvent = DrivingELDEventReferenceInts.indexOf(eldEventTypeCodeInt) !== -1;

      if ((eldEvents.length === 1) && isDrivingEvent) {
        // if just one event in the day and its a driving event
        currentDrivingInterval.startsAtEvent = eldEvent;
        currentDrivingInterval.endsAtEvent = eldEvent;
        drivingTimeEvents.push(currentDrivingInterval);
        break;
      }

      if (isDrivingEvent && !currentDrivingInterval.startsAtEvent) {
        // if driving event and an interval has not yet been set
        currentDrivingInterval.startsAtEvent = eldEvent;
      } else if (currentDrivingInterval.startsAtEvent && (!isDrivingEvent || (i === eldEvents.length - 1))) {
        // if currently in an interval and we hit a non-driving event or end of events
        currentDrivingInterval.endsAtEvent = eldEvent;
        drivingTimeEvents.push(currentDrivingInterval);

        // reset the interval
        currentDrivingInterval = { startAtEvent: undefined, endsAtEvent: undefined };
      }

    }

    return drivingTimeEvents;
  }

  filterELDEvents(eldEvents) {
    // remove all interm events that appear after duty statuses they should not be appearing after. this logic is also done in ELDEvents.js
    const mainELDEventDutyStatusTypeCodes = [11, 12, 13, 14, 31, 32];
    const ignoreIfELDEventDutyStatusTypeCodes = [11, 12, 14]; // if interm events come after one of these statuses, ignore them
    const intermELDEventTypeCodes = [21, 22];
    let lastSeenELDEventTypeCode;

    const _eldEvents = eldEvents.filter(eldEvent => {
      const eldEventTypeCodeInt = eldEvent.get('eldEventTypeCodeInt');
      const isMainELDEventTypeCode = mainELDEventDutyStatusTypeCodes.indexOf(eldEventTypeCodeInt) !== -1;
      const isIntermEvent = intermELDEventTypeCodes.indexOf(eldEventTypeCodeInt) !== -1;

      // if the lastSeenELDEventTypeCode is a duty status where we should ignore interm events
      const isLastSeenELDEventTypeCodeShouldIgnore = (lastSeenELDEventTypeCode !== undefined) && (ignoreIfELDEventDutyStatusTypeCodes.indexOf(lastSeenELDEventTypeCode) !== -1);

      if ([0, 1].indexOf(eldEvent.get('eldEventRecordStatusInt')) !== -1) { // only concerned with active events here
        if (isMainELDEventTypeCode) {
          lastSeenELDEventTypeCode = eldEventTypeCodeInt;
        } else if (isIntermEvent && isLastSeenELDEventTypeCodeShouldIgnore) {
          return false;
        }
      }


      return true;
    });

    return _eldEvents;
  }

  obtainEventsFromDrivers(driver, coDriver, onDate) {
    // obtain all eld/associated eld events from our driver/codriver on given daily cert date
    // we get active graph only
    const promise = new Promise((resolve, reject) => {
      this.setState({ ...this.state, changesProcessing: true }, () => {

        const mainELDEventDutyStatusTypeCodes = [11, 12, 13, 14, 31, 32];
        const ignoreIfELDEventDutyStatusTypeCodes = [11, 12, 14]; // if interm events come after one of these statuses, ignore them
        const intermELDEventTypeCodes = [21, 22];
        let lastSeenELDEventTypeCode;

        ELD.getDriverELDEvents(driver, { onDate }).then(
          driverELDInfo => {
            const driverELDEvents = driverELDInfo.eldEvents;
            // we do this b/c we are guaranteed at least 1 event when eld daily cert has a driver
            const driver = driverELDEvents[0].get('driver');
            const eldDailyCertification = driverELDInfo.eldDailyCertification;

            let _driverELDEvents = driverELDEvents.filter(event => {
              return event.get('eldEventRecordStatusInt') === 1;
            });

            _driverELDEvents = ELD.sortByEventDateTime(0, _driverELDEvents);

            ELD.getAssociatedELDEvents(driver, eldDailyCertification, false, true).then(
              driverAssociatedELDEvents => {

                let driverDrivingTimes = [].concat(_driverELDEvents);
                if (driverAssociatedELDEvents[1]) {
                  driverDrivingTimes.unshift(driverAssociatedELDEvents[1]);
                }
                else if (driverAssociatedELDEvents[0]) {
                  driverDrivingTimes.unshift(driverAssociatedELDEvents[0]);
                }
                // if (driverAssociatedELDEvents[2]) {
                //   // no longer exists
                //   driverDrivingTimes.push(driverAssociatedELDEvents[2]);
                // }
                const driverELDEventsForPreview = this.filterELDEvents(_driverELDEvents);

                driverDrivingTimes = this.obtainDrivingTimeFromEvents(driverELDEventsForPreview);

                ELD.getDriverELDEvents(coDriver, { onDate }).then(
                  coDriverELDInfo => {
                    // we do this b/c we are guaranteed at least 1 event when eld daily cert has a driver
                    const coDriverELDEvents = coDriverELDInfo.eldEvents;
                    const coDriver = coDriverELDEvents[0].get('driver');
                    const coDriverELDDailyCertification = coDriverELDInfo.eldDailyCertification;
                    ELD.getAssociatedELDEvents(coDriver, coDriverELDDailyCertification, false, true).then(
                      coDriverAssociatedELDEvents => {

                        let _coDriverELDEvents = coDriverELDEvents.filter(event => {
                          return event.get('eldEventRecordStatusInt') === 1;
                        });

                        _coDriverELDEvents = ELD.sortByEventDateTime(0, _coDriverELDEvents);

                        let coDriverDrivingTimes = [].concat(_coDriverELDEvents);

                        if (coDriverAssociatedELDEvents[1]) {
                          coDriverDrivingTimes.unshift(coDriverAssociatedELDEvents[1]);
                        }
                        else if (coDriverAssociatedELDEvents[0]) {
                          coDriverDrivingTimes.unshift(coDriverAssociatedELDEvents[0]);
                        }
                        // if (coDriverAssociatedELDEvents[2]) {
                        //   // no longer exists
                        //   coDriverDrivingTimes.push(coDriverAssociatedELDEvents[2]);
                        // }

                        const coDriverELDEventsForPreview = this.filterELDEvents(_coDriverELDEvents);

                        coDriverDrivingTimes = this.obtainDrivingTimeFromEvents(coDriverELDEventsForPreview);

                        // now we have all events/associated events of the driver and coDriver
                        const newState = {
                          ...this.state,
                          driverObject: driver, coDriverObject: coDriver,
                          driverELDEvents: _driverELDEvents, driverAssociatedELDEvents, driverDrivingTimes,
                          coDriverELDEvents: _coDriverELDEvents, coDriverAssociatedELDEvents, coDriverDrivingTimes,
                          driverELDDailyCertification: eldDailyCertification, driverELDDailyCertificationId: eldDailyCertification.id, coDriverELDDailyCertification, coDriverELDDailyCertificationId: coDriverELDDailyCertification.id,
                          hosPreview: {
                            driverELDEventsForPreview,
                            coDriverELDEventsForPreview,
                          },
                          driver: eldDailyCertification.get('driver'), coDriver: coDriverELDDailyCertification.get('driver'),
                          changesProcessing: false,
                        };
                        this.setState(newState, () => this.setState(this.state));

                      }
                    );
                  }
                );

              }
            );
          }
        );
      });
    });
    return promise;
  }

  submitEditRequests(returnPreview, runCallback) {
    const promise = new Promise((resolve, reject) => {
      this.setState({ ...this.state, changesProcessing: true, errors: [], confirmRequestModal: { show: false } }, () => {
        ELD.eldEditHandler(2, {
          driverELDDailyCertificationId: this.state.driverELDDailyCertificationId,
          coDriverELDDailyCertificationId: this.state.coDriverELDDailyCertificationId,
          driver: {
            id: this.state.driver.id,
            request: this.state.requestObjects.driver,
          },
          coDriver: {
            id: this.state.coDriver.id,
            request: this.state.requestObjects.coDriver,
          },
        }, undefined, 'Driving Time Swap', undefined, undefined, returnPreview).then(
          eldEditObject => {
            this.setState({ ...this.state, changesProcessing: false }, () => {
              resolve(eldEditObject);
              if (runCallback) this.props.handleClose(true);
            });
          },
          error => {
            this.setState({ ...this.state, changesProcessing: false, errors: this.state.errors.concat(error.message) });
          }
        );
      });
    });
    return promise;
  }

  refreshGraphPreview() {
    this.submitEditRequests(true).then(
      eldEventPreviewObject => {

        // hosPreview: {
        //   driverELDEventsForPreview: [],
        //   coDriverELDEventsForPreview: [],
        // },
        let driverELDEventsForPreview = [].concat(this.state.driverELDEvents);
        let coDriverELDEventsForPreview = [].concat(this.state.coDriverELDEvents);
        // from the eldEventPreviewObject, obtain both the driver/codrivers requested events and events to be inactive
        // then apply sorting (ascending order) to pass to graph
        const driverEventsToBeInactiveIds = eldEventPreviewObject.driver.eldEventsToBeInactive.map(event => {
          return event.id;
        });
        driverELDEventsForPreview = driverELDEventsForPreview.filter(event => {
          return driverEventsToBeInactiveIds.indexOf(event.id) === -1;
        });
        const driverRequestedELDEvents = eldEventPreviewObject.driver.requestedELDEvents.map(event => {
          const _event = { ...event };
          _event.eldEventRecordStatusInt = 1;
          return Helpers.createTempParseObject('ELDEvent', _event);
        });
        driverELDEventsForPreview = driverELDEventsForPreview.concat(driverRequestedELDEvents);
        driverELDEventsForPreview = ELD.sortByEventDateTime(0, driverELDEventsForPreview);
        driverELDEventsForPreview = this.filterELDEvents(driverELDEventsForPreview);
        const coDriverEventsToBeInactiveIds = eldEventPreviewObject.coDriver.eldEventsToBeInactive.map(event => {
          return event.id;
        });
        coDriverELDEventsForPreview = coDriverELDEventsForPreview.filter(event => {
          return coDriverEventsToBeInactiveIds.indexOf(event.id) === -1;
        });
        const coDriverRequestedELDEvents = eldEventPreviewObject.coDriver.requestedELDEvents.map(event => {
          const _event = { ...event };
          _event.eldEventRecordStatusInt = 1;
          return Helpers.createTempParseObject('ELDEvent', _event);
        });
        coDriverELDEventsForPreview = coDriverELDEventsForPreview.concat(coDriverRequestedELDEvents);
        coDriverELDEventsForPreview = ELD.sortByEventDateTime(0, coDriverELDEventsForPreview);
        coDriverELDEventsForPreview = this.filterELDEvents(coDriverELDEventsForPreview);

        const newState = {
          ...this.state,
          hosPreview: {
            driverELDEventsForPreview,
            coDriverELDEventsForPreview,
          },
        };

        this.setState(newState);

      }
    );
  }

  handleClose(updateParentState) {
    this.props.handleClose(updateParentState);
  }

  handleChange(e, driverInt, drivingTimeObject, key) {
    const { driver, scaleToDriverTimezone } = this.props;
    const newState = { ...this.state };
    // driverInt: 0 = Driver, 1 = CoDriver
    // key = checkbox identifier

    const timezoneOffsetFromUTC = (scaleToDriverTimezone && driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();

    const reqObject = {
      eventId: drivingTimeObject.startsAtEvent.id,
      endTime: moment(drivingTimeObject.endsAtEvent.get('eventDateTime')).tz(timezoneOffsetFromUTC).toDate(),
      endELDEventTypeCodeInt: 13,
    };

    if (!newState.checkboxStates[key]) {
      newState.checkboxStates[key] = { checked: true };
    } else {
      newState.checkboxStates[key].checked = !this.state.checkboxStates[key].checked;
    }


    if (newState.checkboxStates[key].checked) {
      // add request
      if (!driverInt) {
        // give to codriver
        newState.requestObjects.coDriver.add.push(reqObject);
        // remove from driver
        newState.requestObjects.driver.remove.push(reqObject);
      } else {
        // give to driver
        newState.requestObjects.driver.add.push(reqObject);
        // remove from coDriver
        newState.requestObjects.coDriver.remove.push(reqObject);
      }
    } else {
      // remove the remove request and remove the add request for the other
      if (!driverInt) {
        for (let i = 0; i < newState.requestObjects.driver.remove.length; i++) {
          const startId = newState.requestObjects.driver.remove[i].eventId;
          if (startId === reqObject.eventId) {
            newState.requestObjects.driver.remove.splice(i, 1);
            break;
          }
        }
        for (let j = 0; j < newState.requestObjects.coDriver.add.length; j++) {
          const startId = newState.requestObjects.coDriver.add[j].eventId;
          if (startId === reqObject.eventId) {
            newState.requestObjects.coDriver.add.splice(j, 1);
            break;
          }
        }
      } else {
        for (let i = 0; i < newState.requestObjects.coDriver.remove.length; i++) {
          const startId = newState.requestObjects.coDriver.remove[i].eventId;
          if (startId === reqObject.eventId) {
            newState.requestObjects.coDriver.remove.splice(i, 1);
            break;
          }
        }
        for (let j = 0; j < newState.requestObjects.driver.add.length; j++) {
          const startId = newState.requestObjects.driver.add[j].eventId;
          if (startId === reqObject.eventId) {
            newState.requestObjects.driver.add.splice(j, 1);
            break;
          }
        }
      }
    }

    const checkboxState = newState.checkboxStates[key];
    checkboxState.offDuty = undefined;
    checkboxState.sleeper = undefined;
    checkboxState.onDuty = undefined;
    checkboxState.personalUseCMV = undefined;
    checkboxState.yardMoves = undefined;

    this.setState(newState);
  }

  setActive(dutyStatus, startsAtEventId) {
    const newState = { ...this.state };
    const checkboxState = newState.checkboxStates[startsAtEventId];
    checkboxState.offDuty = undefined;
    checkboxState.sleeper = undefined;
    checkboxState.onDuty = undefined;
    checkboxState.personalUseCMV = undefined;
    checkboxState.yardMoves = undefined;
    checkboxState[dutyStatus] = styles.active;

    let eldEventTypeCodeInt;
    if (dutyStatus === 'offDuty') {
      eldEventTypeCodeInt = 11;
    } else if (dutyStatus === 'sleeper') {
      eldEventTypeCodeInt = 12;
    } else if (dutyStatus === 'onDuty') {
      eldEventTypeCodeInt = 14;
    } else if (dutyStatus === 'personalUseCMV') {
      eldEventTypeCodeInt = 31;
    } else if (dutyStatus === 'yardMoves') {
      eldEventTypeCodeInt = 32;
    }

    // search for driver or codriver add object to make the change to status
    for (let i = 0; i < newState.requestObjects.driver.add.length; i++) {
      if (newState.requestObjects.driver.add[i].eventId === startsAtEventId) {
        newState.requestObjects.driver.add[i] = { ...newState.requestObjects.driver.add[i], endELDEventTypeCodeInt: eldEventTypeCodeInt };
        break;
      }
    }
    for (let j = 0; j < newState.requestObjects.coDriver.add.length; j++) {
      if (newState.requestObjects.coDriver.add[j].eventId === startsAtEventId) {
        newState.requestObjects.coDriver.add[j] = { ...newState.requestObjects.coDriver.add[j], endELDEventTypeCodeInt: eldEventTypeCodeInt };
        break;
      }
    }

    this.setState(newState);
  }

  render() {

    const { driver, scaleToDriverTimezone } = this.props;

    const timezoneOffsetFromUTC = (scaleToDriverTimezone && driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();

    const driverDrivingTimeList = this.state.driverDrivingTimes.map(drivingTime => {
      const key = drivingTime.endsAtEvent.id + (new Date()).getTime();
      const startsAtEventId = drivingTime.startsAtEvent.id;
      const checkboxState = this.state.checkboxStates[startsAtEventId];

      return (
        <li key={key}>
          <input type="checkbox" checked={checkboxState && checkboxState.checked} onChange={(e) => this.handleChange(e, 0, drivingTime, startsAtEventId)} />
          <div className={styles.timeRange}>
            <div>
              {`
                ${moment(drivingTime.startsAtEvent.get('eventDateTime')).tz(timezoneOffsetFromUTC).format('h:mm a')}  -
                ${moment(drivingTime.endsAtEvent.get('eventDateTime')).tz(timezoneOffsetFromUTC).format('h:mm a')}
              `}
            </div>
            { checkboxState && checkboxState.checked &&
              <div>
                <button
                  className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${checkboxState.offDuty || ''}`}
                  onClick={() => this.setActive('offDuty', startsAtEventId)}
                >
                  {t('Off')}
                </button>
                <button
                  className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${checkboxState.sleeper || ''}`}
                  onClick={() => this.setActive('sleeper', startsAtEventId)}
                >
                  {t('SB')}
                </button>
                <button
                  className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${checkboxState.onDuty || ''}`}
                  onClick={() => this.setActive('onDuty', startsAtEventId)}
                >
                  {t('On')}
                </button>
                { this.state.driverObject && this.state.driverObject.get('personalUseCMV') &&
                  <button
                    className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${checkboxState.personalUseCMV || ''}`}
                    onClick={() => this.setActive('personalUseCMV', startsAtEventId)}
                  >
                    {t('CMV')}
                  </button>
                }
                { this.state.driverObject && this.state.driverObject.get('yardMoves') &&
                  <button
                    className={`buttonPill ${styles.buttonPill} ${checkboxState.yardMoves || ''}`}
                    onClick={() => this.setActive('yardMoves', startsAtEventId)}
                  >
                    {t('Yard')}
                  </button>
                }
              </div>
            }
          </div>
        </li>
      );
    });
    const coDriverDrivingTimeList = this.state.coDriverDrivingTimes.map(drivingTime => {
      const key = drivingTime.endsAtEvent.id + (new Date()).getTime();
      const startsAtEventId = drivingTime.startsAtEvent.id;
      const checkboxState = this.state.checkboxStates[startsAtEventId];

      return (
        <li key={key}>
          <input type="checkbox" checked={checkboxState && checkboxState.checked} onChange={(e) => this.handleChange(e, 1, drivingTime, startsAtEventId)} />
          <div className={styles.timeRange}>
            <div>
              {`
                ${moment(drivingTime.startsAtEvent.get('eventDateTime')).tz(timezoneOffsetFromUTC).format('h:mm a')}  -
                ${moment(drivingTime.endsAtEvent.get('eventDateTime')).tz(timezoneOffsetFromUTC).format('h:mm a')}
              `}
            </div>
            { checkboxState && checkboxState.checked &&
              <div>
                <button
                  className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${checkboxState.offDuty || ''}`}
                  onClick={() => this.setActive('offDuty', startsAtEventId)}
                >
                  {t('Off')}
                </button>
                <button
                  className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${checkboxState.sleeper || ''}`}
                  onClick={() => this.setActive('sleeper', startsAtEventId)}
                >
                  {t('SB')}
                </button>
                <button
                  className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${checkboxState.onDuty || ''}`}
                  onClick={() => this.setActive('onDuty', startsAtEventId)}
                >
                  {t('On')}
                </button>
                { this.state.coDriverObject && this.state.coDriverObject.get('personalUseCMV') &&
                  <button
                    className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${checkboxState.personalUseCMV || ''}`}
                    onClick={() => this.setActive('personalUseCMV', startsAtEventId)}
                  >
                    {t('CMV')}
                  </button>
                }
                { this.state.coDriverObject && this.state.coDriverObject.get('yardMoves') &&
                  <button
                    className={`buttonPill ${styles.buttonPill} ${checkboxState.yardMoves || ''}`}
                    onClick={() => this.setActive('yardMoves', startsAtEventId)}
                  >
                    {t('Yard')}
                  </button>
                }
              </div>
            }
          </div>
        </li>
      );
    });

    const footerButtons = (
      <span>
        <button className={`buttonDefault floatLeft`} onClick={this.refreshGraphPreview}>Refresh Preview</button>
        <button className={`buttonDefault closeButton`} onClick={() => this.handleClose(false)}>Cancel</button>
        <button className={`buttonDefault`} onClick={() => this.setState({ ...this.state, confirmRequestModal: { show: true } })}>Request Change</button>
      </span>
    );
    window.Localize.translatePage();
    return (
      <Modal className={styles.eldEditModal} show={this.props.show} bsSize="xl" size="xl" backdrop="static">
        <Modal.Header>
          <Modal.Title>
            <span className="translate-me">Driving Time Swap</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <table className={styles.driverSwapTable}>
            <thead>
              <tr className="translate-me"><th className={styles.tip}>
                <div>Select a checkbox to assign that driver's driving time to the other</div>
                <div>Then select its duty status to denote what event came after driving</div>
              </th></tr>
            </thead>
            <tbody>
              <tr>
                <td width="100%">
                  <MDBRow className="translate-me">
                    <MDBCol size="6">
                      { this.state.driver &&
                        <div className={styles.driverName}>
                          {`${this.state.driver.get('user_fullName')}'s Hours`}
                        </div>
                      }
                    </MDBCol>
                    <MDBCol size="6">
                      { this.state.coDriver &&
                        <div className={styles.driverName}>
                          {`${this.state.coDriver.get('user_fullName')}'s Hours`}
                        </div>
                      }
                    </MDBCol>
                  </MDBRow>
                </td>
              </tr>
              <tr>
                <td width="100%">
                  <MDBRow>
                    <MDBCol size="6">
                      { this.state.driverELDDailyCertification &&
                        <HOSGraph
                          eldDailyCertification={this.state.driverELDDailyCertification}
                          eldEvents={this.state.hosPreview.driverELDEventsForPreview}
                          associatedELDEvents={this.state.driverAssociatedELDEvents}
                          driver={this.state.driver}
                          scaleToDriverTimezone
                          shortDutyStatus
                        />
                      }
                    </MDBCol>
                    <MDBCol size="6">
                      { this.state.coDriverELDDailyCertification &&
                        <HOSGraph
                          eldDailyCertification={this.state.coDriverELDDailyCertification}
                          eldEvents={this.state.hosPreview.coDriverELDEventsForPreview}
                          associatedELDEvents={this.state.coDriverAssociatedELDEvents}
                          driver={this.state.coDriver}
                          scaleToDriverTimezone
                          shortDutyStatus
                        />
                      }
                    </MDBCol>
                  </MDBRow>
                </td>
              </tr>
              <tr>
                <td width="100%">
                  <MDBRow>
                    <MDBCol size="6">
                      <ul className={styles.drivingTimes}>
                        { driverDrivingTimeList }
                      </ul>
                    </MDBCol>
                    <MDBCol size="6">
                      <ul className={styles.drivingTimes}>
                        { coDriverDrivingTimeList }
                      </ul>
                    </MDBCol>
                  </MDBRow>
                </td>
              </tr>
            </tbody>
          </table>

          { this.state.errors.length > 0 &&
            <div className={`${styles.errors}`}>
              <hr />
              <ul>
                {
                  this.state.errors.map(error => {
                    return <li key={(new Date()).getTime() + Math.random()} className="textRedMaple">{ error }</li>;
                  })
                }
              </ul>
            </div>
          }

        </Modal.Body>

        <Modal.Footer>
          {!this.state.changesProcessing ?
            footerButtons
            :
            <LoadingIcon />
          }
        </Modal.Footer>

        <ConfirmModal show={this.state.confirmRequestModal.show} handleModalChoice={(confirmBool) => confirmBool ? this.submitEditRequests(false, true) : this.setState({ ...this.state, confirmRequestModal: { show: false } })} handleClose={() => this.setState({ ...this.state, confirmRequestModal: { show: false } })} className="translate-me">
          <span>
            Request Edit(s)?
          </span>
        </ConfirmModal>

      </Modal>
    );
  }
}

ELDDrivingTimeSwapModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  driver: PropTypes.string,
  coDriver: PropTypes.string,
  onDate: PropTypes.instanceOf(Date),
  scaleToDriverTimezone: PropTypes.bool,
};

export default ELDDrivingTimeSwapModal;
