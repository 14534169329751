/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';

// Enums
import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';
import { PermissionType } from 'sb-csapi/dist/enums/Group/GroupPermissionType';
// import { NotificationType } from 'sb-csapi/dist/enums/Group/GroupNotificationType';

// SB Objects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// API
import { getAttribute, getCurrentUser } from 'sb-csapi/dist/AAPI';
import { formatName } from 'sb-csapi/dist/utils/String';
import { getEquipmentGroupUsers } from 'api/Equipment/Groups';

// SB Core
import Dropdown from 'sbCore/Dropdown/Dropdown';
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import Button from 'sbCore/Button/Button';
import Checkbox from 'sbCore/Checkbox/Checkbox';
import UserAutocompleteInput from 'sbCore/UserAutocomplete/UserAutocompleteInput';

// Styling
import './style.scss';

/**
 * @description Renders UserGroupTable to assign users to a given group (equipment/driver)
 * 
 * @param {String} [equipmentGroupObjectId] - The equipment group object id. If this is passed in, the driverGroupObjectId must be null
 * @param {String} [driverGroupObjectId] - The driver group object id. If this is passed in, the equipmentGroupObjectId must be null
 * @param {Function} onChange - Callback function whenever there is a change to the table information (pending add/change/remove)
 * 
 * @returns {JSX} Returns a table for the given UserGroup (EquipmentGroupUsers, DriverGroupUsers)
 *
 * @example
 * <UserGroupTable
 *  equipmentGroupObjectId={equipmentGroupObjectId}
 *  onChange={([pendingGroupUsersToAdd, pendingGroupUsersToChange, pendingGroupUsersToRemove]) => saveInformation(pendingGroupUsersToAdd, pendingGroupUsersToChange, pendingGroupUsersToRemove)}
 * />
 */
function UserGroupTable({ ...props }) {
  const { driverGroupObjectId, equipmentGroupObjectId } = props;

  // These are the options for the global permissions/notifications
  const globalNotificationPermissionTypes = Object.freeze({
    ENABLE: {
      value: true,
      label: 'Enabled',
    },
    DISABLE: {
      value: false,
      label: 'Disabled',
    },
  });

  const [originalUserGroupInformationArray, setOriginalUserGroupInformationArray] = useState([]); // Holds the original state from the db
  const [userGroupInformationArray, setUserGroupInformationArray] = useState([]); // Holds all the information for the users in this group

  const [isLoading, setIsLoading] = useState(true);
  const [showAddUsersDropdown, setShowAddUsersDropdown] = useState(false);

  const [globalPermissionStatusText, setGlobalPermissionStatusText] = useState(globalNotificationPermissionTypes.ENABLE.label);
  // const [globalNotificationStatusText, setGlobalNotificationStatusText] = useState(globalNotificationPermissionTypes.ENABLE.label);

  /** --------------------------- Helper Functions --------------------------- */

  /**
   * @description Parses user records into an object for use with the table
   * @param {User} equipment - the user record
   * @returns {Object} Returns the parsed user object
   */
  function parseUser(user) {
    const userObjectId = getAttribute(user, 'objectId');
    const firstName = getAttribute(user, 'firstName');
    const lastName = getAttribute(user, 'lastName');
    const username = getAttribute(user, 'username');

    const userProperties = {
      groupObjectId: equipmentGroupObjectId || driverGroupObjectId,
      user: {
        objectId: userObjectId,
        firstName,
        lastName,
        username,
      },
      permissions: {
        enabled: true, // default to enabled
      },
      notifications: {
        enableEmailNotifications: true, // default to enabled
      },
      isPendingRemoval: false,
      isPendingAddition: true,
    };

    return userProperties;
  }

  /**
   * Takes in a equipmentUserGroup, and parses it into an object
   * @param {EquipmentGroupUser} equipmentGroupUser 
   */
  function parseEquipmentGroupUser(equipmentGroupUser) {
    const groupUserObjectId = getAttribute(equipmentGroupUser, 'objectId');

    // Fetch information about the user
    const user = getAttribute(equipmentGroupUser, 'user');
    const userObjectId = getAttribute(user, 'objectId');

    const firstName = getAttribute(user, 'firstName');
    const lastName = getAttribute(user, 'lastName');
    const username = getAttribute(user, 'username');

    // Fetch the permissions and notifications from the group
    const groupObjectId = getAttribute(equipmentGroupUser, 'objectId');
    const enableEmailNotifications = getAttribute(equipmentGroupUser, 'enableEmailNotifications');
    const enabled = getAttribute(equipmentGroupUser, 'enabled');

    const equipmentGroupUserProperties = {
      groupObjectId,
      groupUserObjectId, // This is the object id for either EquipmentGroupUser or DriverGroupUser

      user: {
        objectId: userObjectId,
        firstName,
        lastName,
        username,
      },
      permissions: {
        enabled,
      },
      notifications: {
        enableEmailNotifications
      },
      isPendingRemoval: false,
      isPendingAddition: false,
    };

    return equipmentGroupUserProperties;
  }

  /**
   * @description Given an array of userGroupInformations, sort it by ascending user first name
   * @param {Array<Object>} userGroupInformation - Array of userGroupInformation objects
   * @returns {Array} Returns the sorted userGroupInformation
   */
  function sortUserByFirstName(userProperties) {
    return userProperties.sort((userPropertiesA, userPropertiesB) => {
      const userAFirstName = userPropertiesA.user.firstName.toLowerCase();
      const userBFirstName = userPropertiesB.user.firstName.toLowerCase();

      if (userAFirstName < userBFirstName) return -1;
      if (userAFirstName > userBFirstName) return 1;
      return 0;
    });
  }

  // Determines specific classnames to apply to rows on the table
  const determineRowClassNames = (data) => {
    return ({
      'pending-user-add': data?.isPendingAddition,
      'pending-user-remove': data?.isPendingRemoval,
    })
  };

  /** --------------------------- UseEffects --------------------------- */

  useEffect(() => {
    let didCancel = false;

    async function fetchEquipmentGroupUsers() {
      setIsLoading(true);

      const equipmentGroupUserFilters = [];

      equipmentGroupUserFilters.push(new Filter(QueryRestriction.EXISTS, 'equipmentGroup'));
      equipmentGroupUserFilters.push(new Filter(QueryRestriction.EQUAL_TO, 'equipmentGroup', equipmentGroupObjectId));

      // Fetch any items related to the equipment group
      const { equipmentGroupUsers } = await getEquipmentGroupUsers(
        undefined, // options
        equipmentGroupObjectId, // equipmentGroupObjectId
        equipmentGroupUserFilters, // filters
        undefined, // sortBy
        ['user', 'equipmentGroup'], // includedPointers
        undefined, // selectedAttributes
        undefined, // page
        undefined, // limit
        true, // queryAll
      );

      // For each of the users in equipmentGroupUsers, fetch the relevant information required
      let equipmentGroupUsersInformation = equipmentGroupUsers.map((equipmentGroupUser) => {
        return parseEquipmentGroupUser(equipmentGroupUser);
      });

      equipmentGroupUsersInformation = sortUserByFirstName(equipmentGroupUsersInformation);
      equipmentGroupUsersInformation.unshift({ isGlobalRow: true }); // Add in the global row

      if (!didCancel) {
        setOriginalUserGroupInformationArray(equipmentGroupUsersInformation);
        setUserGroupInformationArray(equipmentGroupUsersInformation);
        setIsLoading(false);
      }
    }

    async function fetchDriverGroupUsers() {
      // @todo - Add in compatibility for driver groups
    }

    // Fetch the appropriate user data depending on what group is passed in
    if (equipmentGroupObjectId) fetchEquipmentGroupUsers();
    if (driverGroupObjectId) fetchDriverGroupUsers();
    return () => { didCancel = true; };
  }, []);

  // Update the parent component whenever the table changes
  useEffect(() => {
    // Update the global permission/notification status whenever there is a change
    checkGlobalPermissionStatus();
    // checkGlobalNotificationStatus();

    if (isLoading) return;

    // Go through the temp user group information array and compare it with the original to find out any changes/additions
    const pendingGroupUsersToAdd = [];
    const pendingGroupUsersToChange = [];
    const pendingGroupUsersToRemove = [];

    // Create a map of the original group information array to users
    const originalUserGroupInformationArrayMap = {};

    originalUserGroupInformationArray.forEach((userGroupInformation) => {
      if (userGroupInformation.isGlobalRow) return;
      originalUserGroupInformationArrayMap[userGroupInformation.user.objectId] = userGroupInformation;
    });

    // Now go through each entry in the userGroupInformationArrayMap and check for any differences
    userGroupInformationArray.forEach((userGroupInformation) => {
      if (userGroupInformation.isGlobalRow) return;

      if (!originalUserGroupInformationArrayMap[userGroupInformation.user.objectId]) {
        // If there is no user in the original user group information array, add it
        return pendingGroupUsersToAdd.push(userGroupInformation);
      }

      if (userGroupInformation.isPendingRemoval) {
        return pendingGroupUsersToRemove.push(userGroupInformation);
      }

      if (JSON.stringify(userGroupInformation) !== JSON.stringify(originalUserGroupInformationArrayMap[userGroupInformation.user.objectId])) {
        // If there is a difference, add it
        return pendingGroupUsersToChange.push(userGroupInformation);
      }
    });

    props.onChange([pendingGroupUsersToAdd, pendingGroupUsersToChange, pendingGroupUsersToRemove]);
  }, [userGroupInformationArray]);

  /** --------------------------- Helper Functions --------------------------- */

  /**
   * @description Adds the new users selected from the multi-autocomplete to the table
   * @param {Array<User>} users - The array of users selected within the autcocomplete
   */
  function addUsersToGroup(users) {
    const userObjectIdArray = users.map((user) => getAttribute(user, 'objectId'));

    // Get all the pending addition users
    // Remove any pending addition users that no longet exist in the users group
    const updatedUserGroupInformationArray = userGroupInformationArray.filter((userGroupInformation) => userGroupInformation.isGlobalRow || userGroupInformation.isPendingAddition === false || (userGroupInformation.isPendingAddition && userObjectIdArray.includes(userGroupInformation.user.objectId)));

    // Only add a new user that doesn't already exist
    const newUserInformationGroupArray = [...updatedUserGroupInformationArray];

    users.forEach((user) => {
      const userObjectId = getAttribute(user, 'objectId');

      const existingUserInGroup = userGroupInformationArray.find((userGroupInformation) => userGroupInformation?.user?.objectId === userObjectId);
      if (existingUserInGroup) return;

      const userUserInformationGroup = parseUser(user, true);
      newUserInformationGroupArray.push(userUserInformationGroup);
    });

    setUserGroupInformationArray(newUserInformationGroupArray);
  }

  /**
   * @description Updates a given user's permissions
   * 
   * @param {String} userObjectId - The associated user object id we are updated permissions for
   * @param {PermissionType} permissionType - The PermissionType that we want to update
   * @param {bool} value - The value for the PermissionType
   */
  function updateUserPermissions(userObjectId, permissionType, value) {
    const userGroupInformationIndex = userGroupInformationArray.findIndex((userGroupInformation) => userGroupInformation?.user?.objectId === userObjectId);
    const userGroupInformation = userGroupInformationArray[userGroupInformationIndex];
    if (!userGroupInformation) return;

    // Do a deep clone of the userGroupInformation object
    const updatedUserGroupInformation = structuredClone(userGroupInformation);
    updatedUserGroupInformation.permissions[permissionType.attribute] = value;

    const updatedUserGroupInformationArray = [...userGroupInformationArray];
    updatedUserGroupInformationArray[userGroupInformationIndex] = updatedUserGroupInformation;

    setUserGroupInformationArray([...updatedUserGroupInformationArray]);
  }

  /**
   * @description Enables or disables all user permissions
   * @param {bool} value - Determines whether to enable or disable all user permissions
   */
  function updateAllUserPermissions(value) {
    const updatedUserGroupInformationArray = userGroupInformationArray.map((userGroupInformation) => {
      // Create a clone of the userGroupInformation
      const updatedUserGroupInformation = structuredClone(userGroupInformation);

      if (userGroupInformation.isGlobalRow) return;
      if (userGroupInformation.user.objectId === getAttribute(getCurrentUser(), 'objectId')) return updatedUserGroupInformation;

      // Go through each of the permissions, and set them to enabled
      Object.keys(updatedUserGroupInformation.permissions).forEach((key) => {
        updatedUserGroupInformation.permissions[key] = value;
      });

      return updatedUserGroupInformation;
    }).filter((userGroupInformation) => userGroupInformation);

    // Add back in the global row
    setUserGroupInformationArray([userGroupInformationArray[0], ...updatedUserGroupInformationArray]);
  }

  function checkGlobalPermissionStatus() {
    // Update the status of the global row
    let globalPermissionStatusText = "Enabled";
    const globalPermissionStatusSet = new Set();

    userGroupInformationArray.forEach((userGroupInformation) => {
      if (userGroupInformation.isGlobalRow) return;
      if (userGroupInformation.user.objectId === getAttribute(getCurrentUser(), 'objectId')) {
        // Make sure to set the current user as enabled
        userGroupInformation.permissions.enabled = true;
        return;
      };

      const permissionStatuses = Object.values(userGroupInformation?.permissions);
      permissionStatuses.forEach((permissionStatus) => {
        globalPermissionStatusSet.add(permissionStatus);
      })
    });

    if (globalPermissionStatusSet.size === 0) {
      globalPermissionStatusText = "Enabled";
    } else if (globalPermissionStatusSet.has(true) && globalPermissionStatusSet.has(false)) {
      globalPermissionStatusText = "Mixed";
    } else if (globalPermissionStatusSet.has(true) && !globalPermissionStatusSet.has(false)) {
      globalPermissionStatusText = "Enabled";
    } else if (globalPermissionStatusSet.has(false) && !globalPermissionStatusSet.has(true)) {
      globalPermissionStatusText = "Disabled";
    }

    setGlobalPermissionStatusText(globalPermissionStatusText);
  }
  
  /**
   * This function updates a map of user permissions and notifications, and sets the new permissions and notifications. . Currently unused until notification logic is implemented.
   * 
   * @param {String} userObjectId - the user object id
   * @param {NotificationType} notificationType - a NotificationType from the NotificationType enum
   * @param {bool} value - the value to set for the given NotificationType
   */
  // function updateUserNotifications(userObjectId, notificationType, value) {
  //   // Check to see if this userObjectId is an existing user, or if its one that has been added
  //   const userGroupInformationIndex = userGroupInformationArray.findIndex((userGroupInformation) => userGroupInformation?.user?.objectId === userObjectId);
  //   const userGroupInformation = userGroupInformationArray[userGroupInformationIndex];
  //   const updatedUserGroupInformation = structuredClone(userGroupInformation);

  //   if (!userGroupInformation) return;

  //   updatedUserGroupInformation.notifications[notificationType.attribute] = value;

  //   const updatedUserGroupInformationArray = [...userGroupInformationArray];
  //   updatedUserGroupInformationArray[userGroupInformationIndex] = updatedUserGroupInformation;

  //   setUserGroupInformationArray([...updatedUserGroupInformationArray]);
  // }

  /**
   * @description Enables or disables all user notifications. Currently unused until notification logic is implemented.
   * @param {bool} value - Determines whether to enable or disable all user notifications
   */
  // function updateAllUserNotifications(value) {
  //   const updatedUserGroupInformationArray = userGroupInformationArray.map((userGroupInformation) => {
  //     // Create a clone of the userGroupInformation
  //     const updatedUserGroupInformation = structuredClone(userGroupInformation);

  //     if (userGroupInformation.isGlobalRow) return;

  //     // Go through each of the notifications, and set them to enabled
  //     Object.keys(updatedUserGroupInformation.notifications).forEach((key) => {
  //       updatedUserGroupInformation.notifications[key] = value;
  //     });

  //     return updatedUserGroupInformation;
  //   }).filter((userGroupInformation) => userGroupInformation);

  //   // Add back in the global row
  //   setUserGroupInformationArray([userGroupInformationArray[0], ...updatedUserGroupInformationArray]);
  // }

  /**
   * Checks and updates the status of the global row for notifications. Currently unused until notification logic is implemented.
   */
  // function checkGlobalNotificationStatus() {
  //   // Update the status of the global row
  //   let globalNotificationStatusText = "Enabled";
  //   const globalNotificationStatusSet = new Set();

  //   userGroupInformationArray.forEach((userGroupInformation) => {
  //     if (userGroupInformation.isGlobalRow) return;

  //     const notificationStatuses = Object.values(userGroupInformation?.notifications);
  //     notificationStatuses.forEach((notificationStatus) => {
  //       globalNotificationStatusSet.add(notificationStatus);

  //     })
  //   });

  //   if (globalNotificationStatusSet.size === 0) {
  //     globalNotificationStatusText = "Enabled";
  //   } else if (globalNotificationStatusSet.has(true) && globalNotificationStatusSet.has(false)) {
  //     globalNotificationStatusText = "Mixed";
  //   } else if (globalNotificationStatusSet.has(true) && !globalNotificationStatusSet.has(false)) {
  //     globalNotificationStatusText = "Enabled";
  //   } else if (globalNotificationStatusSet.has(false) && !globalNotificationStatusSet.has(true)) {
  //     globalNotificationStatusText = "Disabled";
  //   }

  //   setGlobalNotificationStatusText(globalNotificationStatusText);
  // }

  /** --------------------------- DataTable Template --------------------------- */

  // Template for the delete button in users table
  function deleteButtonTemplate(rowData) {
    if (rowData?.isGlobalRow) return; // Hide the delete button for the global row

    return (
      <div className="flex justify-content-center align-content-center">
        {!rowData.isPendingRemoval && !rowData.isPendingAddition && (
          <Button
            icon={<span className="material-icons">delete</span>}
            tooltip={`Remove ${rowData.user.firstName}'s custom permissions and notifications`}
            className="p-button-danger p-button-text p-button-rounded delete-transaction-button"
            onClick={() => {
              // Check to see if this userObjectId is an existing user, or if its one that has been added
              const userGroupInformationIndex = userGroupInformationArray.findIndex((userGroupInformation) => !userGroupInformation.isGlobalRow && userGroupInformation?.user?.objectId === rowData.user.objectId);
              const userGroupInformation = userGroupInformationArray[userGroupInformationIndex];
              const updatedUserGroupInformation = structuredClone(userGroupInformation);

              if (!userGroupInformation) return;

              updatedUserGroupInformation.isPendingRemoval = true;

              const updatedUserGroupInformationArray = [...userGroupInformationArray];
              updatedUserGroupInformationArray[userGroupInformationIndex] = updatedUserGroupInformation;

              setUserGroupInformationArray([...updatedUserGroupInformationArray]);
            }}
          />
        )}
        {rowData.isPendingRemoval && (
          <Button
            icon={<span className="material-icons">undo</span>}
            tooltip={`Undo`}
            className="p-button-secondary p-button-text p-button-rounded delete-transaction-button"
            onClick={() => {
              // Check to see if this userObjectId is an existing user, or if its one that has been added
              const userGroupInformationIndex = userGroupInformationArray.findIndex((userGroupInformation) => !userGroupInformation.isGlobalRow && userGroupInformation?.user?.objectId === rowData.user.objectId);
              const userGroupInformation = userGroupInformationArray[userGroupInformationIndex];
              const updatedUserGroupInformation = structuredClone(userGroupInformation);

              if (!userGroupInformation) return;

              updatedUserGroupInformation.isPendingRemoval = false;

              const updatedUserGroupInformationArray = [...userGroupInformationArray];
              updatedUserGroupInformationArray[userGroupInformationIndex] = updatedUserGroupInformation;

              setUserGroupInformationArray([...updatedUserGroupInformationArray]);
            }}
          />
        )}
      </div>
    );
  }

  function nameTemplate(rowData) {
    if (rowData.isGlobalRow) return <div className='font-bold'>All Group Users</div>;

    const fullName = formatName(rowData.user.firstName + ' ' + rowData.user.lastName);

    return (
      <div>
        {fullName}
      </div>
    );
  }

  function usernameTemplate(rowData) {
    if (rowData.isGlobalRow) return;

    return (
      <div>
        {rowData.user.username}
      </div>
    );
  }

  const permissionOptionTemplate = (option, rowData) => {
    return (
      <div className='flex flex-row'>
        <Checkbox
          checked={rowData.permissions[option.attribute]}
          onChange={(e) => updateUserPermissions(rowData.user.objectId, Object.values(PermissionType).find((permissionType) => permissionType.attribute === option.attribute), e.checked)}
        />
        <span className='pl-2'>{option.label}</span>
      </div>
    );
  }

  /**
   * Generates a permission template based on the provided row data.
   *
   * @param {Object} rowData - The data for the row.
   * 
   * @return {JSX} The generated permissions template.
   */
  function permissionsTemplate(rowData) {
    if (rowData.isGlobalRow) {
      return (
        <div>
          <Dropdown
            className="w-full"
            options={Object.values(globalNotificationPermissionTypes)}
            optionLabel="label"
            placeholder={globalPermissionStatusText}
            onChange={({ value }) => updateAllUserPermissions(value)}
            disabled={userGroupInformationArray.length === 1} // Disable the dropdown if there's only the global row present
          />
        </div>
      );
    }

    let permissionsAllowed = 0;

    // Determine how many permissions are enabled
    Object.entries(rowData.permissions).forEach(([key, value]) => {
      if (value == true) permissionsAllowed++;
    });

    return (
      <div>
        <Dropdown
          className="w-full"
          options={Object.values(PermissionType)}
          optionLabel="label"
          placeholder={`${permissionsAllowed} Permission(s) Allowed`}
          valueTemplate={() => <span>{`${permissionsAllowed} Permission(s) Allowed`}</span>}
          itemTemplate={(option) => permissionOptionTemplate(option, rowData)}
          disabled={rowData?.isPendingRemoval || rowData.user.objectId === getAttribute(getCurrentUser(), 'objectId')}
          onChange={({ value }) => {
            const permissionType = Object.values(PermissionType).find((permissionType) => permissionType.attribute === value.attribute);
            updateUserPermissions(rowData.user.objectId, permissionType, !rowData.permissions[value.attribute]);
          }}
        />
      </div>
    );
  }

  // This holds the checkbox associated with each notification entry. Currently unused until notification logic is implemented.
  // const notificationOptionTemplate = (option, rowData) => {
  //   return (
  //     <div className='flex flex-row'>
  //       <Checkbox
  //         checked={rowData.notifications[option.attribute]}
  //         onChange={(e) => updateUserNotifications(rowData.user.objectId, Object.values(NotificationType).find((notificationType) => notificationType.attribute === option.attribute), e.checked)}
  //       />
  //       <span className='pl-2'>{option.label}</span>
  //     </div>
  //   );
  // }

  /**
   * Generates a notifications template based on the provided row data. Currently unused until notification logic is implemented.
   *
   * @param {Object} rowData - The data for the row.
   * @return {JSX.Element} The generated notifications template.
   */
  // function notificationsTemplate(rowData) {
  //   if (rowData.isGlobalRow) {
  //     return (
  //       <div>
  //         <Dropdown
  //           className="w-full"
  //           options={Object.values(GlobalPermissionType)}
  //           optionLabel="label"
  //           placeholder={globalNotificationStatusText}
  //           onChange={({ value }) => {
  //             updateAllUserNotifications(value);
  //           }}
  //         />
  //       </div>
  //     );
  //   }

  //   let notificationsEnabled = 0;

  //   Object.entries(rowData.notifications).forEach(([key, value]) => {
  //     if (value == true) notificationsEnabled++;
  //   });

  //   return (
  //     <div>
  //       <Dropdown
  //         className="w-full"
  //         options={Object.values(NotificationType)}
  //         optionLabel="label"
  //         placeholder={`${notificationsEnabled} Selected`}
  //         valueTemplate={(option) => <span>{`${notificationsEnabled} Selected`}</span>}
  //         itemTemplate={(option) => notificationOptionTemplate(option, rowData)}
  //         disabled={rowData?.isPendingRemoval}
  //       />
  //     </div>
  //   );
  // }

  const UserGroupTable = (
    <DataTable
      value={userGroupInformationArray}
      responsiveLayout="scroll"
      dataKey="id"
      emptyMessage="No users were found for this group."
      loading={isLoading}
      className="usergroup-datatable"
      scrollable
      scrollHeight="20rem"
      rowClassName={determineRowClassNames}
    >
      <Column
        field="name"
        header="Name"
        headerClassName="max-w-7rem"
        className="max-w-5rem"
        body={(rowData) => nameTemplate(rowData)}
      />
      <Column
        field="username"
        header="Username"
        headerClassName="max-w-7rem"
        className="max-w-7rem"
        body={(rowData) => usernameTemplate(rowData)}
      />
      <Column
        field="permissions"
        header="Permissions"
        headerClassName="max-w-9rem"
        className="max-w-9rem"
        body={(rowData) => permissionsTemplate(rowData)}
      />
      {/* <Column
        field="notifications"
        header="Notifications"
        headerClassName="max-w-7rem"
        className="max-w-7rem"
        body={(rowData) => notificationsTemplate(rowData)}
      /> */}
      <Column
        headerClassName="p-0 max-w-4rem"
        className="p-0 max-w-4rem"
        body={(rowData) => deleteButtonTemplate(rowData)}
        exportable={false}
      />
    </DataTable>
  );

  return (
    <div className="user-group-user-table">
      <div className="flex column-gap-3 mt-4 mb-3">
        <div className="flex text-lg font-semibold align-items-center capitalize">Users</div>
        <Button
          icon={<span className="material-icons mr-1">add</span>}
          label="Add Users"
          sbVariant="slim"
          className="p-button-info"
          disabled={showAddUsersDropdown}
          onClick={() => setShowAddUsersDropdown(true)}
        />
        <div className="flex-grow-1">
          <hr />
        </div>
      </div>
      {showAddUsersDropdown && (
        <div className="flex w-full mb-3">
          <UserAutocompleteInput
            multiple
            className="user-table-user-autocomplete flex-grow-1 mr-1"
            placeholder="Search for Users"
            onSelectUsers={(users) => addUsersToGroup(users)}
            hideSelectedUsers
            displayOnlyAdminTypes
            excludedUserObjectIds={userGroupInformationArray.map((userProperties) => userProperties?.user?.objectId)}
          />
          <Button
            className="p-button-icon p-button-text p-button-danger"
            icon={<span className="material-icons">close</span>}
            tooltip="Cancel"
            onClick={() => {
              setShowAddUsersDropdown(false);

              // Remove all existing pending additions when the dropdown is closed
              const updatedUserGroupInformationArray = userGroupInformationArray.filter((userGroupInformation) => {
                return userGroupInformation.isGlobalRow || userGroupInformation.isPendingAddition === false;
              });

              setUserGroupInformationArray(updatedUserGroupInformationArray);
            }}
          />
        </div>
      )}
      {UserGroupTable}
    </div>
  );
}

export default UserGroupTable;
