import { useState, useEffect } from 'react';
import Parse from 'parse';

// api
import { getAttribute, getCurrentUser } from 'sb-csapi/dist/AAPI';
import { sendPush, getCurrentUserCompany } from 'sb-csapi/dist/AAPI';
import { addDriverDocument } from 'api/Driver/Documents';

// components
import Dialog from 'sbCore/Dialog/Dialog';
import Button from 'sbCore/Button/Button';
import FileUpload from 'sbCore/FileUpload/FileUpload';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import DriverAutocomplete from 'sbCore/DriverAutocomplete/DriverAutocomplete';
import Chips from 'sbCore/Chips/Chips';
import Tag from 'sbCore/Tag/Tag';
import DispatchDocumentTypeDropdown from 'components/Dispatch/DispatchDocumentTypeDropdown/DispatchDocumentTypeDropdown';

// style
import './style.scss';

/**
 * @description Allows users to upload documents
 * @param {boolean} visible - Whether or not to show the dialog
 * @param {function} onHideUploadDialog - Callback to handle hiding upload dialog
 * @param {function} onTriggerRefreshState - Callback to handle refreshing state
 *
 * @returns
 */
function GlobalDocumentsUploadDialog(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadDocumentType, setUploadDocumentType] = useState(undefined);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [documentTags, setDocumentTags] = useState([]);
  const [driverUserObjectIdsToNotify, setDriverUserObjectIdsToNotify] = useState([]);

  // state to hold which table to upload documents to, for now defaults to DriverDocument
  const [tableToUploadDocuments, setTableToUploadDocuments] = useState('DriverDocument');

  // Formats the selected documents and updates the state with them
  function handleDocumentSelection(files) {
    const _uploadedDocuments = [ ...uploadedDocuments ];

    files.forEach(file => {
      if (file.type === 'application/pdf') {
        const documentName = file.name.replace(/[^\w.]/g, ''); // Remove illegal characters from the file name
        const documentType = file.type;
        const documentFile = new Parse.File(documentName, file, documentType);

        const _file = {
          documentName,
          documentType,
          documentFile,
        };

        _uploadedDocuments.push(_file);
      }
    });

    setUploadedDocuments(_uploadedDocuments);
  };

  async function onSubmitDialog() {
    setIsLoading(true);

    if (tableToUploadDocuments === 'DriverDocument') {
      const driverDocumentPromises = [];

      uploadedDocuments.forEach(uploadedDocument => {
        const document = {
          fileName: uploadedDocument.documentName,
          documentType: uploadDocumentType,
          file: uploadedDocument.documentFile,
          uploadedBy: getCurrentUser(),
          isHidden: false,
          belongsToCompany: getCurrentUserCompany(),
          visibleToDriver: true,
          uploadedAt: new Date(),
        }
  
        driverDocumentPromises.push(addDriverDocument(document));
      });
  
      const documentRecords = await Promise.all(driverDocumentPromises);
  
      // right now only accommodates sending to 1 driver
      if (driverUserObjectIdsToNotify.length > 0) {
        documentRecords.forEach(documentRecord => {
          sendPush(
            [driverUserObjectIdsToNotify[0]],
            {
              type: 105,
              class: 'DriverDocument',
              objectId: getAttribute(documentRecord, 'objectId'),
            },
            true,
          );
        });
      }
    }

    setIsLoading(false);

    clearState();

    props.onHideUploadDialog();
    props.onTriggerRefreshState();
  }

  function handleRemoveDocument(file) {
    const _files = [ ...uploadedDocuments ];
    _files.splice(_files.indexOf(file.name), 1);
    setUploadedDocuments(_files);
  }

  function clearState() {
    setUploadDocumentType(undefined);
    setUploadedDocuments([]);
    setDocumentTags([]);
  }
  
  function onTemplateSelect(e) {
    // only allow pdf files max 1MB each when selecting or dragging/dropping
    const _files = Object.values(e.files).filter(file => file.type === 'application/pdf' && file.size <= 1000000);
    handleDocumentSelection(_files);
  }

  function onTemplateRemove(file, props) {
    handleRemoveDocument(file);
    props.onRemove();
  }

  function onTemplateClear(props) {
    setUploadedDocuments([]);
  }

  function headerTemplate(options) {
    const { className, chooseButton, cancelButton } = options;

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {cancelButton}
      </div>
    );
  }

  function itemTemplate(file, props) {
    if (file.type !== 'application/pdf' || file.size > 1000000) {
      props.onRemove();
      return;
    }

    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <span className="flex flex-column text-left">
            {file.name}
          </span>
        </div>
        <Tag value={props.formatSize} className="px-3 py-2" />
        <Button type="button" icon="pi pi-times" className="p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props)} />
      </div>
    );
  }

  function emptyTemplate() {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-file mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
        <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
          Drag and Drop Files Here
        </span>
      </div>
    );
  }

  const chooseOptions = { icon: 'pi pi-fw pi-file', iconOnly: true, className: 'p-button-rounded p-button-outlined sb-choose-button' };
  const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'p-button-rounded p-button-outlined sb-cancel-button' };

  return (
    <>
      <Dialog
        className="global-documents-upload-dialog"
        header="Upload Document"
        visible={props.visible}
        onHide={() => {
          clearState();
          props.onHideUploadDialog();
        }}
        resizeable={false}
        draggable={false}
        footer={(
          <div className="w-full">
            <Button
              className="p-button-sm p-button-text"
              label="Cancel"
              onClick={() => {
                clearState();
                props.onHideUploadDialog();
              }}
            />
            <Button
              className="p-button-sm sb-upload-button"
              label="Upload"
              onClick={async () => await onSubmitDialog()}
              disabled={uploadedDocuments.length === 0 || uploadDocumentType === undefined || isLoading}
            />
          </div>
        )}
      >
        <div className="flex flex-column" style={{ gap: '1rem'}}>
          <FileUpload
            multiple
            accept="application/pdf"
            maxFileSize={1000000}
            onSelect={onTemplateSelect}
            onClear={onTemplateClear}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            cancelOptions={cancelOptions}
          />
          <div>
            <InputLabel>Category</InputLabel>
            <DispatchDocumentTypeDropdown
              onDocumentTypeChange={(documentType) => setUploadDocumentType(documentType)}
              documentTypeInt={uploadDocumentType}
            />
          </div>
          {/* <div>
            <InputLabel>Tags</InputLabel>
            <Chips
              id="tags"
              value={documentTags}
              onChange={(e) => setDocumentTags(e.value)}
              separator=","
              placeholder="Comma-Separated Tags"
            />
          </div> */}
          <div>
            <InputLabel>Notify Driver</InputLabel>
            <div className="inline-block">
              <DriverAutocomplete hideLabel onSelectDriver={(driver) => {
                if (!driver) {
                  return setDriverUserObjectIdsToNotify([]);
                }
                const driverUser = getAttribute(driver, 'user');
                const _driverUserObjectIdsToNotify = [...driverUserObjectIdsToNotify, getAttribute(driverUser, 'objectId')];
                return setDriverUserObjectIdsToNotify(_driverUserObjectIdsToNotify);
              }} />
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default GlobalDocumentsUploadDialog;
