import React from 'react';
import PropTypes from 'prop-types';
import { MDBBtn, MDBIcon } from 'mdbreact';
import ReactTooltip from 'react-tooltip';

function AddNewGeofenceButton(props) {
  const helpTooltip = (
    <ReactTooltip effect="solid" type="dark" id="helpTooltip">
      When you create a geofence, you can:
      <br />
      • Receive enter/exit alerts
      <br />
      • Receive speeding violations
    </ReactTooltip>
  );
  
  return (
    <div
      data-tip
      data-for="helpTooltip"
      className="translate-me"
    >
      { helpTooltip }
      { props.addEditGeofence.type !== 'add' &&
        <MDBBtn className="my-2 mx-auto d-block mt-2" size="md" gradient="purple" onClick={() => props.handleClick()} disabled={props.disabled}>
          <MDBIcon icon="plus-square" className="mr-1"></MDBIcon>
          New Geofence
        </MDBBtn>
      }
      { props.addEditGeofence.type === 'add' &&
        <MDBBtn className="my-2 mx-auto d-block mt-2" size="md" gradient="peach" onClick={() => props.handleClick()} disabled={props.disabled}>
          <MDBIcon icon="times" className="mr-1"></MDBIcon>
          New Geofence
        </MDBBtn>
      }
    </div>
  );
}

AddNewGeofenceButton.propTypes = {
  addEditGeofence: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  editingGeofence: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

export default AddNewGeofenceButton;
