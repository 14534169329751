import React from 'react';

// Enums
import { EquipmentTypes } from 'enums/Equipment'; // need to move to sb-csapi

/**
 * Component that displays different states of the Sidebar. This includes "loading", "empty", and "error" states.
 * This is used by both "All Equipment" and "Equipment Group" views.
 *
 * @param {EquipmentTypes} equipmentType - The type of equipment (vehicle/trailer/geofence).
 * @param {bool} isError - Whether the sidebar is in the "error" state.
 * @param {bool} isLoading - Whether the sidebar is in the "loading" state.
 * @param {bool} isEmpty - Whether the sidebar is in the "empty" state.
 *
 * @returns Returns the appropriate UI for the given state.
 */
function SidebarState({ equipmentType, isError, isLoading, isEmpty, ...props }) {
  let emptyText = '';
  let equipmentText = '';

  switch (equipmentType) {
    case EquipmentTypes.VEHICLE:
      equipmentText = 'vehicles';
      emptyText = 'No vehicles were found. Add a vehicle to see it here.';
      break;
    case EquipmentTypes.TRAILER:
      equipmentText = 'trailers';
      emptyText = 'No trailers were found. Add a trailer with an associated tracker to see it here.';
      break;
    case 2:
      equipmentText = 'geofences';
      emptyText = 'No geofences were found. Add a geofence to see it here.';
      break;
    default:
      break;
  }

  const loadingTemplate = (
    <div className="flex h-full align-items-center justify-content-center">
      <i className="sidebar-loading-icon pi pi-spin pi-spinner" />
    </div>
  );

  const emptyTemplate = (
    <div className="flex flex-column h-full align-items-center justify-content-center ">
      <span className="sidebar-info-icon material-icons">info</span>
      <div className="px-5 py-3 text-600 text-center">{emptyText}</div>
    </div>
  );

  const errorTemplate = (
    <div className="flex flex-column h-full align-items-center justify-content-center ">
      <span className="sidebar-error-icon material-icons">error</span>
      <div className="px-5 py-3 text-600 text-center">{`An error has occurred while retrieving ${equipmentText}.`}</div>
      <div className="px-5 py-3 text-800 text-center">Refresh the page to try again.</div>
    </div>
  );

  return (
    <>
      {isError && errorTemplate}
      {isLoading && loadingTemplate}
      {isEmpty && emptyTemplate}
    </>
  );
}

export default SidebarState;
