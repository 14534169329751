import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';

// Components
import { MDBCol, MDBContainer, MDBRow, MDBInput } from 'mdbreact';

// CSS
import './style.scss';

function Sidebar(props) {
  return (
    <MDBContainer className="sidebar mx-0">
      <MDBRow className="sidebarTitle">
        <MDBCol className="title">
          <span>{props.navTitle}</span>
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBInput
            label=""
            value=""
            labelClass="text-white"
            icon="search"
            onFocus={() => props.openSearch()}
          />
        </MDBCol>
      </MDBRow>
      {props.navItems.map(navItem => {
        return (
          <MDBRow className="subViews">
            <MDBCol className={navItem.isActive ? "active option" : "option"} onClick={() => {
              history.location.pathname = '/';
              history.push(navItem.to);
            }}>
              <span className="label">{navItem.name}</span>
            </MDBCol>
          </MDBRow>
        )
      })}
    </MDBContainer>
  );
}

Sidebar.propTypes = {
  navTitle: PropTypes.string.isRequired,
  navItems: PropTypes.array.isRequired,
  openSearch: PropTypes.func,
};

export default Sidebar;
