import { getQueryParameter } from 'api/URL';
import { getAttribute, getCurrentUser, createQuery, setQueryRestriction, sortQuery, find } from 'api/Parse';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

/** @module OTP */

/**
 * @memberof module:OTP
 * @description Verify internal user authorization from the One Time Password in the URL
 * 
 * @param {object} location The location object
 * @param {boolean} [autoDelete] Should the token be deleted after verification, default is true
 * 
 * @returns {boolean} True if the token in location.search is valid, False otherwise
 */
async function verifyOTP(location, autoDelete = true) {
  if (!location || !location.search) {
    return false; // Fail silently if no location or query param passed in
  }

  const urlOtpToken = getQueryParameter(location.search, 'otp');
  if (!urlOtpToken) {
    return false;
  }
  // TODO: Spice this up with more checks (on company, session, ++) when internal tool otp generator is live
  const otpQuery = createQuery('OTPKey');
  setQueryRestriction(otpQuery, QueryRestrictionTypes.EQUAL_TO, 'token', urlOtpToken);
  // Find the most recent token
  sortQuery(otpQuery, QuerySortOrderTypes.DESCENDING, 'createdAt');
  const matchingToken = await find(otpQuery, true);

  if (matchingToken && getAttribute(matchingToken, 'token', true) === urlOtpToken) {
    // TODO: Add destroy key functionality here
    return true;
  }
  // If there was no token or the token fetched is not valid return false
  return false;
}

export {
  verifyOTP,
};
