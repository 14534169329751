import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';
import moment from 'moment-timezone';

// actions
import { addDriverToState } from 'actions/Driver'

// api
import * as Analytics from 'api/Analytics';
import { getAttribute, getCurrentUser } from 'api/Parse';
import { getCompanyDispatchers } from 'api/Dispatcher/Dispatcher';

// enums
import { QuerySortOrderTypes } from 'enums/Query';

// components
import AddDriverModal from './AddDriverModal';
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBCard, MDBCardHeader, MDBCardBody, MDBIcon } from 'mdbreact';

import './style.scss';

class AddDriver extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      dispatchers: [],
      modal: {
        show: false,
      }
    }

    this.toggleAddDriverModal = this.toggleAddDriverModal.bind(this);
    this.addDriver = this.addDriver.bind(this);
    this.callback = this.callback.bind(this);
  }

  componentDidMount() {
  }

  callback() {
    if (this.props.callback) {
      return this.props.callback();
    }
    return true;
  }

  async toggleAddDriverModal() {
    await this.setState({ ...this.state, isLoading: true });
    const newState = { ...this.state };
    const modal = { ...newState.modal, show: !newState.modal.show };

    if (modal.show) {
      // get only the current company's dispatchers
      const dispatchersInfo = await getCompanyDispatchers(undefined, undefined, undefined, [], false, true);
      newState.dispatchers = dispatchersInfo.dispatchers;
    } else {
      newState.dispatchers = [];
    }
    newState.modal = modal;
    return await this.setState({ ...newState, isLoading: false });
  }

  async addDriver(userInfo, sendTextToDriver) {
    await addDriverToState(userInfo, undefined, sendTextToDriver);
    await this.toggleAddDriverModal();
    return this.callback();
  }

  render() {
    const { props, state } = this;
    const { modal } = state;

    const currentUser = getCurrentUser();
    const belongsToCompany = currentUser && getAttribute(currentUser, 'belongsToCompany');
    const subscription = belongsToCompany && getAttribute(belongsToCompany, 'subscription');
    return (
      <div className={`add-driver-container ${props.containerClassName}`}>
        {state.isLoading &&
          <LoadingOverlay />
        }

        <MDBBtn
          color="primary"
          size="sm"
          disabled={props.disabled}
          onClick={this.toggleAddDriverModal}
          className="translate-me"
        >
          <MDBIcon icon="plus-square" className="mr-1" /> DRIVER
        </MDBBtn>

        {modal.show &&
          <AddDriverModal
            show={modal.show}
            handleClose={this.toggleAddDriverModal}
            addDriverFormHandler={this.addDriver}
            company={belongsToCompany}
            dispatchers={state.dispatchers}
            subscription={subscription}
          />
        }
      </div>
    )
  }
}

AddDriver.propTypes = {
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  callback: PropTypes.func, // what to do in parent when finished adding driver
}


export default AddDriver;
