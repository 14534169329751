import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import uniqid from 'uniqid';

// components
import DateRangeSelector from 'sbCore/DateRangeSelector/DateRangeSelector';
import DataTable from 'sbCore/DataTable/DataTable';
import Row from 'sbCore/Row/Row';
import Column from 'sbCore/Column/Column';
import ColumnGroup from 'sbCore/ColumnGroup/ColumnGroup';
import DistanceUnitDropdown from 'sbCore/DistanceUnitDropdown/DistanceUnitDropdown';
import ProgressSpinner from 'sbCore/ProgressSpinner/ProgressSpinner';
import InputSwitch from 'sbCore/InputSwitch/InputSwitch';
import Button from 'sbCore/Button/Button';

// legacy api
import { getCurrentDispatcher } from 'api/Getters';
import { getELDDailyCertificationIntervalFromDriverTZ } from 'api/ELD';

// api
import { createCsvFile } from 'api/Helpers';
import { getVehicles } from 'api/Equipment/Vehicle';
import { getIFTARoutes, getTimezoneOffsetFromUTC } from 'api/IFTARoute/IFTARoute';

// sb-csapi
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { truncateString } from 'sb-csapi/dist/utils/String';
import { getLocationDescriptionBreakdown, deriveVehicleLocation } from 'sb-csapi/dist/api/VehicleLocation/VehicleLocation';

// enums
import { Canada, United_States } from 'sb-csapi/dist/enums/StateProvince';
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';
import { LengthUnit, LengthConversion } from 'sb-csapi/dist/enums/Unit';

// sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// style
import './style.scss';

/**
 * @description The state/province data for each jurisdiction, by vehicle. Should be resuable outside IFTA
 * @param {String} [className]
 * @param {String} [scrollHeight] - Scroll height of the table
 * @param {Function} [handleFetchedIFTAData] - Give fetched data to parent
 * @param {Function} [onUpdateSettings] - Give inputs back to parent
 * @param {Function} [onSelectVehicle] - Give selected vehicle IFTA data to parent
 * @param {Array} [dateInterval] - From parent - Date range supplied
 * @param {String} [lengthUnit] - From parent - From enums LengthUnit - KM, MI
 * @param {Boolean} [hideEmptyStateProvinces] - From Parent - Whether to hide empty state/provinces
 * @returns
 */
function IFTAVehicleJurisdictionDataTable({ ...props }) {
  const defaultLengthUnit = LengthUnit.KM;

  // Refs
  const csvDataString = useRef(''); // total data for CSV export of table data
  const csvDataFooterString = useRef(''); // will be appended to csvDataString. it's a seperate string since footer info renders at a different time

  // Note users prefer all jurisdictions to be combined and sorted
  const CountryData = { ...Canada, ...United_States };
  const stateProvinceAbbrvs = Object.keys(CountryData).sort();

  const [dateInterval, setDateInterval] = useState([]);
  const [dateIntervalPlaceholders, setDateIntervalPlaceholders] = useState([]);
  const [lengthUnit, setLengthUnit] = useState(props.lengthUnit || null); // keep null to indicate the user hasn't changed it yet
  const [isLoading, setIsLoading] = useState(false);
  const [dispatcher, setDispatcher] = useState(null);
  const [timezoneOffsetFromUTC, setTimezoneOffsetFromUTC] = useState(moment.tz.guess());
  const [isFetched, setIsFetched] = useState(false); // if all init data is fetched
  const [hideEmptyStateProvinces, setHideEmptyStateProvinces] = useState((props.hideEmptyStateProvinces !== null) ? props.hideEmptyStateProvinces : true);

  // Finalized IFTA Data
  const [vehicleJurisdictionMap, setVehicleJurisdictionMap] = useState({});

  // Totals accumulated in vehicleJurisdictionMap
  const [vehicleJurisdictionTotalsData, setVehicleJurisdictionTotalsData] = useState({});

  // Array-fied version of vehicleJurisdictionMap and vehicleJurisdictionTotalsData. For Table use
  const [vehicleJurisdictionArr, setVehicleJurisdictionArr] = useState([]);

  // User selected a row
  const [selectedVehicleJurisdictionData, setSelectedVehicleJurisdictionData] = useState(null);

  // JSX
  const [tableColumns, setTableColumns] = useState([]); // table columns
  const [footerGroup, setFooterGroup] = useState([]); // footer group

  useEffect(() => {
    let didCancel = false;

    async function init() {
      setIsLoading(true);
      const dispatcher = await getCurrentDispatcher();
      const _lengthUnit = getAttribute(dispatcher, 'distanceUnit', true)?.toUpperCase() || defaultLengthUnit;

      const _dateInterval = [moment().startOf('month').toDate(), moment().endOf('month').toDate()];

      // set the default date with timezone adjustment
      const dispatcherStartTimezoneObject = getELDDailyCertificationIntervalFromDriverTZ(dispatcher, _dateInterval[0]);
      const dispatcherEndTimezoneObject = getELDDailyCertificationIntervalFromDriverTZ(dispatcher, _dateInterval[1]);
      const _timezoneOffsetFromUTC = dispatcherStartTimezoneObject.timezoneOffsetFromUTC;

      // Update dateStart and dateEnd values with timezone adjusted dates
      let dateStart = dispatcherStartTimezoneObject.dayOf;
      let dateEnd = dispatcherEndTimezoneObject.dayAfter;

      if (props.dateInterval) {
        [dateStart, dateEnd] = props.dateInterval;
      }

      if (!didCancel) {
        setDateInterval([dateStart, dateEnd]);
        setDispatcher(dispatcher);
        setTimezoneOffsetFromUTC(_timezoneOffsetFromUTC);
        setLengthUnit(_lengthUnit);
        setIsFetched(true);
        setIsLoading(false);
      }
    }

    init();

    return () => { didCancel = true; };
  }, []);

  useEffect(() => {
    // Dont allow this useEffect to run if preconditions are not met
    if (!isFetched) return;
    if (!dateInterval[0] || !dateInterval[1]) return;
    if (moment(dateInterval[1]).isBefore(dateInterval[0])) return;
    let didCancel = false;

    /**
     * @description Generates the IFTA data needed to populate the table
     */
    async function generateVehicleJurisdictionMap() {
      setIsLoading(true);
      const vehicleJurisdictionMap = {}; // contains all vehicle-jurisdiction data

      // We create an entry to accumulate stateProvince totals
      // which will be its own row in the table
      const vehicleJurisdictionTotalsData = initVehicleJurisData('Totals', true);
      initVehicleJurisDataTotalsMap(vehicleJurisdictionTotalsData.totalsMap);

      let vehicleJurisdictionArr = []; // array version of all the data

      // Now we start...
      // Get all active vehicleUnitIds in the company
      const vehiclesObject = await getVehicles(
        undefined, // default session token
        undefined, // default companyobjectid
        false,     // include child companies
        [new Filter(QueryRestriction.EQUAL_TO, 'enabled', true)], // filters
        undefined,  // default sort ascending
        undefined,  // included pointers
        ['unitId'], // selected attributes
        undefined,  // page
        undefined,  // limit
        true,       // queryall
      );

      const vehicleUnitIds = vehiclesObject.vehicles.map(vehicle => getAttribute(vehicle, 'unitId'));

      // For each unitIds, initialize in vehicleJurisdictionMap
      vehicleUnitIds.sort().map((unitId) => {
        const vehicleJurisData = initVehicleJurisData(unitId);
        initVehicleJurisDataTotalsMap(vehicleJurisData.totalsMap);

        vehicleJurisdictionMap[unitId] = {
          ...vehicleJurisData,
        };
      });

      // Get all the IFTARoutes needed. First obtain the correct timezone to use
      const dispatcherStartTimezoneObject = getELDDailyCertificationIntervalFromDriverTZ(dispatcher, dateInterval[0]);
      const dispatcherEndTimezoneObject = getELDDailyCertificationIntervalFromDriverTZ(dispatcher, dateInterval[1]);

      // Update dateStart and dateEnd values with timezone adjusted dates
      const dateStart = dispatcherStartTimezoneObject.dayOf;
      const dateEnd = dispatcherEndTimezoneObject.dayAfter;

      let iftaRoutePromises = [];

      vehicleUnitIds.map(vehicleUnitId => {
        if (!vehicleUnitId) return;

        iftaRoutePromises.push(
          getIFTARoutes(
            undefined, // options
            undefined, // companyObjectId
            undefined, // includeChildCompanies
            [
              new Filter(QueryRestriction.EQUAL_TO, 'vehicleUnitId', vehicleUnitId),
              new Filter(QueryRestriction.GREATER_THAN_OR_EQUAL_TO, 'dateStart', dateStart),
              new Filter(QueryRestriction.LESS_THAN_OR_EQUAL_TO, 'dateEnd', dateEnd),
            ], // filters
            undefined, // sortBy - default ascending dateStart
            [
              'vehicleLocationStart',
              'vehicleLocationEnd',
              'iftaRouteDriverPeriods',
              'iftaRouteDriverPeriods.driver',
              'fuelPurchases',
            ], // includedPointers
            undefined, // selectedAttributes
            undefined, // page
            undefined, // limit
            true, // queryAll
          ),
        );
      });

      let iftaRoutes = await Promise.all(iftaRoutePromises);
      iftaRoutes = iftaRoutes.map((iftaRouteResults) => iftaRouteResults.iftaRoutes).flat();

      // Now we populate vehicleJurisdictionMap with iftaRouteData
      for (let i = 0; i < iftaRoutes.length; i++) {
        const iftaRoute = iftaRoutes[i];
        const vehicleUnitId = getAttribute(iftaRoute, 'vehicleUnitId');
        const stateProvince = getAttribute(iftaRoute, 'stateProvince')?.toUpperCase();
        let dateStart = getAttribute(iftaRoute, 'dateStart');
        dateStart = formatDate(dateStart);

        // See: IFTAVehicleIntervalDataTable: getTotalsBodyObject() for conversion parody
        let totalVehicleKmStart = getAttribute(iftaRoute, 'totalVehicleKmStart');
        let totalVehicleKmEnd = getAttribute(iftaRoute, 'totalVehicleKmEnd');

        if (lengthUnit === LengthUnit.MI) {
          totalVehicleKmStart *= LengthConversion.KM2MI;
          totalVehicleKmEnd *= LengthConversion.KM2MI;
        }

        const distanceTravelledKm = Math.floor(Math.floor(totalVehicleKmEnd) - Math.floor(totalVehicleKmStart));

        const vehicleJurisdictionMapEntry = vehicleJurisdictionMap[vehicleUnitId];

        vehicleJurisdictionMapEntry.totalsMap[stateProvince] += distanceTravelledKm;

        vehicleJurisdictionMapEntry.iftaRoutes.push(iftaRoute);

        // we populate the totals entry now
        vehicleJurisdictionTotalsData.totalsMap[stateProvince] += distanceTravelledKm;

        // here we do the vehicle day map population for per-day data
        const { dayMap } = vehicleJurisdictionMap[vehicleUnitId];
        if (!dayMap[dateStart]) dayMap[dateStart] = initDayMap(dateStart);
        if (!dayMap[dateStart].totalsMap[stateProvince]) dayMap[dateStart].totalsMap[stateProvince] = 0; // mileage for the stateProvince
        dayMap[dateStart].totalsMap[stateProvince] += distanceTravelledKm;
        dayMap[dateStart].iftaRoutes.push(iftaRoute);
      }

      // now we populate dayMap.iftaRouteObjs (formatted version of iftaRoute for consistency across components)
      populateIFTARouteObjs(vehicleJurisdictionMap);

      // populate dayMap.drivers
      populateDrivers(vehicleJurisdictionMap);

      /**
       * Now we populate vehicleJurisdictionArr. The information in vehicleJurisdiction is sorted
       * (naturally by a map), so we just take the values
       */
      vehicleJurisdictionArr = [...Object.values(vehicleJurisdictionMap)];

      // 2024-02-21 - The DateRangeSelector does not show the selected dates by default, until the user interacts
      // with it. So this is a workaround, we set a placeholder to show selected dates
      const dateStartPlaceholder = moment(dateStart).format('MMMM YYYY');
      const dateEndPlaceholder = moment(dateEnd).format('MMMM YYYY');

      if (!didCancel) {
        setVehicleJurisdictionMap(vehicleJurisdictionMap);
        setVehicleJurisdictionTotalsData(vehicleJurisdictionTotalsData);
        setVehicleJurisdictionArr(vehicleJurisdictionArr);
        setDateIntervalPlaceholders([dateStartPlaceholder, dateEndPlaceholder]);

        // give the parent the data for siblings to work with
        if (props.handleFetchedIFTAData) props.handleFetchedIFTAData(vehicleJurisdictionMap);
        if (props.onUpdateSettings) props.onUpdateSettings(dateInterval, lengthUnit || defaultLengthUnit, hideEmptyStateProvinces);
        setIsLoading(false);
      }
    }

    generateVehicleJurisdictionMap();

    return () => { didCancel = true; };
  }, [dateInterval, lengthUnit, isFetched]);

  useEffect(() => {
    generateTableColumns();
  }, [vehicleJurisdictionArr]);

  useEffect(() => {
    generateFooterGroup();
  }, [vehicleJurisdictionTotalsData]);

  useEffect(() => {
    generateTableColumns();
    generateFooterGroup();
    if (props.handleFetchedIFTAData) props.handleFetchedIFTAData(vehicleJurisdictionMap);
    if (props.onUpdateSettings) props.onUpdateSettings(dateInterval, lengthUnit || defaultLengthUnit, hideEmptyStateProvinces);
  }, [hideEmptyStateProvinces]);

  /**
   * Template/JSX Functions
   */
  function getTotalsBodyObject(rowData, stateProvinceAbbrv) {
    let total = 0;
    const totalsBodyObject = {
      template: undefined,
      total: undefined,
    };

    if (rowData.totalsMap) {
      total = rowData.totalsMap[stateProvinceAbbrv];
    }

    totalsBodyObject.total = total;
    if (total === 0) total = '-';

    // csv append
    if (isValidCSVRowLength()) {
      csvDataString.current += `,${total}`;
    }

    totalsBodyObject.template = (<div>{total}</div>);

    return totalsBodyObject;
  }

  function unitIdTemplate(rowData) {
    // csv append
    if (!csvDataString.current.includes(rowData.unitId)) {
      csvDataString.current += `\n${rowData.unitId || ' '}`;
    }

    return (
      <div className="font-bold">
        {truncateString(rowData.unitId)}
      </div>
    );
  }

  function detailsButtonTemplate(rowData) {
    const shouldDisable = !isFetched;
    return (
      <Button
        label="VIEW"
        onClick={
          () => props.onSelectVehicle && props.onSelectVehicle({
            unitId: rowData.unitId,
            iftaRoutes: rowData.iftaRoutes,
            dayMap: rowData.dayMap,
            totalsMap: rowData.totalsMap,
            lengthUnit: rowData.lengthUnit,
          })
        }
        severity="primary"
        sbVariant="short"
        disabled={shouldDisable}
        tooltip={!shouldDisable ? `Click to view more IFTA data for Unit ${rowData.unitId}` : 'Loading...'}
        tooltipOptions={{ position: 'top', showOnDisabled: shouldDisable }}
      />
    );
  }

  function generateTableColumns() {
    const _tableColumns = [];

    // csv init
    csvDataString.current = 'Unit ID';

    // First, the row for select button and for Unit Ids
    _tableColumns.push(
      <Column
        key="selector"
        selectionMode="single"
        headerStyle={{ maxWidth: '50px' }}
        frozen
      />,
      <Column
        key="detailsButton"
        field="null"
        header=""
        body={(rowData) => detailsButtonTemplate(rowData)}
        frozen
      />,
      <Column
        key="unitId"
        field="unitId"
        header="Unit ID"
        body={(rowData) => unitIdTemplate(rowData)}
        headerStyle={{ minWidth: '100px' }}
        frozen
      />,
    );

    // Now, the columns for each jurisdiction
    for (let i = 0; i < stateProvinceAbbrvs.length; i++) {
      const stateProvinceAbbrv = stateProvinceAbbrvs[i];
      const fieldName = 'totalsMap.' + stateProvinceAbbrv;

      if (vehicleJurisdictionTotalsData?.totalsMap && hideEmptyStateProvinces && vehicleJurisdictionTotalsData.totalsMap[stateProvinceAbbrv] === 0) continue;

      // csv stateprovince headers
      csvDataString.current += `,${stateProvinceAbbrv}`;

      _tableColumns.push(
        <Column
          key={fieldName}
          field={fieldName}
          header={stateProvinceAbbrv}
          headerTooltip={Object.values(CountryData).find((stateProvince) => stateProvince.code === stateProvinceAbbrv)?.name}
          headerTooltipOptions={{ position: 'top', className: 'state-province-tooltip' }}
          body={(rowData) => getTotalsBodyObject(rowData, stateProvinceAbbrv).template}
          style={{ minWidth: '150px' }}
        />,
      );
    }

    setTableColumns(_tableColumns);
  }

  // Create footer group with the totals
  function generateFooterGroup() {
    // csv append
    csvDataFooterString.current = '\nOverall Totals';

    // Generate a column for each jurisdiction, follows the same sort order
    const jurisdictionColumns = stateProvinceAbbrvs.map(
      (stateProvinceAbbrv) => {
        const totalsBodyObject = getTotalsBodyObject(
          vehicleJurisdictionTotalsData,
          stateProvinceAbbrv,
        );

        if (vehicleJurisdictionTotalsData?.totalsMap && hideEmptyStateProvinces && vehicleJurisdictionTotalsData.totalsMap[stateProvinceAbbrv] === 0) return null;

        // csv append
        csvDataFooterString.current += `,${totalsBodyObject.total || '-'}`;

        return <Column key={stateProvinceAbbrv} footer={totalsBodyObject.template} style={{ minWidth: '150px' }} />;
      },
    ).filter((column) => column);

    // now putting it together in the footerGroup
    const _footerGroup = (
      <ColumnGroup>
        <Row>
          <Column
            footer="Totals:"
            style={{ minWidth: '120px' }}
            colSpan={3}
            frozen
          />
          {jurisdictionColumns}
        </Row>
      </ColumnGroup>
    );

    setFooterGroup(_footerGroup);
  }

  /**
   * Logic
   */

  /**
   * @description Helper for generateVehicleJurisdictionMap
   *              Sets initial structure of a vehicle jurisdiction data entry
   */
  function initVehicleJurisData(unitId, isFrozenRow = false) {
    return {
      unitId,
      totalsMap: {}, // Map of [stateProvince]: mileage
      dayMap: {}, // Map of [date]: mileage. Not used for the Totals row
      iftaRoutes: [], // All iftaroutes involved in the totals. Not used for the Totals row
      lengthUnit: lengthUnit || defaultLengthUnit, // what is the source distance unit being used
      timezoneOffsetFromUTC, // the timezone used to fetch all this info
      isFrozenRow, // If this data translates into a frozen row
    };
  }

  /**
   * @description Helper for generateVehicleJurisdictionMap
   *              Initializes an entry's totalsMap
  */
  function initVehicleJurisDataTotalsMap(vehicleJurisDataTotalsMap) {
    stateProvinceAbbrvs.map((stateProvinceAbbrv) => {
      // initialize every stateprovince vehicle data total to 0
      vehicleJurisDataTotalsMap[stateProvinceAbbrv] = 0;
    });
  }

  /**
   * @description Helper for generateVehicleJurisdictionMap
   *              Initializes an entry's day route mapping
  */
  function initDayMap(dateStart) {
    return {
      dateStart,
      iftaRoutes: [],
      iftaRouteObjs: [], // iftaRoutes, but formatted for consistency across components
      timezoneOffsetFromUTC, // the timezone used when fetching this info
      totalsMap: {},
      driverObjs: [], // drivers for the day, in the order found
    };
  }

  /**
   * @description Converts all vehicleJurisdictionMap.dayMap iftaRoutes to object equivalents
   *              For formatting and data consistency throughout IFTA
   * @param {Object} vehicleJurisdictionMap - The fully populated vehicleJurisdictionMap
   */
  function populateIFTARouteObjs(vehicleJurisdictionMap) {
    // array-ify the vehicle jurisdiction map (the map of vehicles to all their ifta data)
    const vehicleJurisData = Object.values(vehicleJurisdictionMap);
    // for each vehicle map object, obtain the dayMap that represents the ifta data for each day of the vehicle
    let dayMapArr = vehicleJurisData.map(vehicleJurisDatum => vehicleJurisDatum.dayMap);
    // filter out those that dont have any daily data
    dayMapArr = dayMapArr.filter(dayMap => Object.keys(dayMap).length);

    // now we do the population by iterating through each dayMap and converting their iftaRoutes
    for (let i = 0; i < dayMapArr.length; i++) {
      const dayMap = dayMapArr[i];
      Object.keys(dayMap).map(timestamp => {
        const dayMapEntry = dayMap[timestamp];
        const { iftaRoutes } = dayMapEntry;

        // Finally, we do the conversion work here
        // the timezone used for this particular day
        const timezoneOffsetFromUTC = iftaRoutes && iftaRoutes.length > 0 && getTimezoneOffsetFromUTC(iftaRoutes[0]);

        dayMapEntry.iftaRouteObjs = iftaRoutes.map(iftaRoute => {
          const uniqueId = uniqid();
          const stateProvince = getAttribute(iftaRoute, 'stateProvince');
          const vehicleUnitId = getAttribute(iftaRoute, 'vehicleUnitId');

          const vehicleLocationStart = getAttribute(iftaRoute, 'vehicleLocationStart');
          const vehicleLocationEnd = getAttribute(iftaRoute, 'vehicleLocationEnd');

          const savedVehicleKm = getAttribute(iftaRoute, 'savedVehicleKm');

          return {
            uniqueId,
            timezoneOffsetFromUTC,
            stateProvince,
            vehicleUnitId,
            derivedVehicleLocationStart: deriveVehicleLocation(vehicleLocationStart),
            derivedVehicleLocationEnd: deriveVehicleLocation(vehicleLocationEnd),
            savedVehicleKm,
            iftaRoute,
          };
        });
      });
    }
  }

  /**
   * @description Finds all vehicleJurisdictionMap.dayMap drivers
   *              Follows similar flow to populateIFTARouteObjs
   * @param {Object} vehicleJurisdictionMap - The fully populated vehicleJurisdictionMap
  */
  function populateDrivers(vehicleJurisdictionMap) {
    const vehicleJurisData = Object.values(vehicleJurisdictionMap);
    let dayMapArr = vehicleJurisData.map(vehicleJurisDatum => vehicleJurisDatum.dayMap);
    dayMapArr = dayMapArr.filter(dayMap => Object.keys(dayMap).length);

    for (let i = 0; i < dayMapArr.length; i++) {
      const dayMap = dayMapArr[i];
      Object.keys(dayMap).map(timestamp => {
        const dayMapEntry = dayMap[timestamp];
        const { iftaRoutes } = dayMapEntry;

        const seenDrivers = {}; // track which drivers we have already come across

        iftaRoutes.map(iftaRoute => {
          const iftaRouteDriverPeriods = getAttribute(iftaRoute, 'iftaRouteDriverPeriods', true);
          if (!iftaRouteDriverPeriods || (iftaRouteDriverPeriods.length === 0)) return;

          iftaRouteDriverPeriods.map(iftaRouteDriverPeriod => {
            const driver = getAttribute(iftaRouteDriverPeriod, 'driver');
            const driverObjectId = getAttribute(driver, 'driverObjectId', true);

            if (!driver) return;

            if (!seenDrivers[driverObjectId]) {
              seenDrivers[driverObjectId] = true;
              dayMapEntry.driverObjs.push({
                user_fullName: getAttribute(driver, 'user_fullName'),
                objectId: getAttribute(driver, 'objectId'),
                driver,
              });
            }
          });
        });
      });
    }
  }

  /**
   * @description Formats date to standardized format we want
   */
  function formatDate(date) {
    let _date = '';
    if (date) {
      _date = moment.parseZone(date).format('YYYY-MM-DD');
    }

    return _date;
  }

  /**
   *
   * @description Checks if a CSV row is at max length, to prevent additions to the row
   *              We do this check to avoid re-renderings from appending to the csv string
   */
  function isValidCSVRowLength() {
    const csvDataStringSplit = csvDataString.current.split('\n');
    const csvDataStringFirstRowSplit = csvDataStringSplit[0]?.split(',');
    const csvDataStringLastRowSplit = csvDataStringSplit[csvDataStringSplit.length - 1]?.split(',');

    return csvDataStringFirstRowSplit && csvDataStringLastRowSplit && (csvDataStringLastRowSplit.length < csvDataStringFirstRowSplit.length);
  }

  // Final filtered values for display purposes. if there is no data at all, return emptiness (leik if u cri everyteim)
  let _vehicleJurisdictionArr = vehicleJurisdictionArr;
  let _tableColumns = tableColumns;
  let _footerGroup = footerGroup;

  if (isFetched && vehicleJurisdictionTotalsData.totalsMap) {
    const stateProvinceTotals = Object.values(vehicleJurisdictionTotalsData.totalsMap); // array of totals
    // if all the totals of each stateprovince add to 0, we know there's no data for any of them
    const totalOfStateProvinceTotals = stateProvinceTotals.reduce((a, b) => a + b, 0);
    if (totalOfStateProvinceTotals === 0) {
      _vehicleJurisdictionArr = [];
      _tableColumns = [];
      _footerGroup = undefined;
    }
  }

  let className = 'ifta-vehicle-jur-data-table mx-2';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <div className="mb-3">
        {isLoading && (
          <ProgressSpinner className="ml-5" style={{ width: '2em' }} strokeWidth={5} />
        )}

        {!isLoading && (
          <div className="flex justify-content-between">
            <div>
              <DateRangeSelector
                className="date-range-selector"
                viewType="month"
                initialStartDate={dateInterval[0]}
                initialEndDate={dateInterval[1]}
                onStartDateSelected={(startDate) => setDateInterval([startDate, dateInterval[1]])}
                onEndDateSelected={(endDate) => setDateInterval([dateInterval[0], endDate])}
                startDatePlaceholder={dateIntervalPlaceholders[0]}
                endDatePlaceholder={dateIntervalPlaceholders[1]}
              />

              <DistanceUnitDropdown
                className="inline-block ml-5"
                unit={lengthUnit}
                onSelect={(lengthUnit) => setLengthUnit(lengthUnit.short)}
              />
            </div>

            <div className="flex align-items-center">
              <div className="sb-font-roboto text-xs text-gray-calm uppercase font-bold mr-2">
                Hide Inactive States/Provinces
              </div>
              <InputSwitch
                checked={hideEmptyStateProvinces}
                onChange={() => setHideEmptyStateProvinces(!hideEmptyStateProvinces)}
              />
              <Button
                className="ml-2"
                text
                label="Export CSV"
                severity="secondary"
                sbVariant="short"
                disabled={isLoading}
                onClick={() => createCsvFile(`Jurisdiction_Summary_${moment(dateInterval[0]).format('YYYY-MM')}_${moment(dateInterval[1]).format('YYYY-MM')}`, csvDataString.current + csvDataFooterString.current, true) }
              />
            </div>
          </div>
        )}
      </div>
      <DataTable
        value={_vehicleJurisdictionArr}
        footerColumnGroup={_footerGroup}
        selection={selectedVehicleJurisdictionData}
        // onSelectionChange={(e) => { console.log(csvDataString.current); console.log(csvDataFooterString.current); setSelectedVehicleJurisdictionData(e.value); }}
        scrollable
        scrollHeight={props.scrollHeight || '80vh'}
      >
        {_tableColumns}
      </DataTable>
    </div>
  );
}

export default IFTAVehicleJurisdictionDataTable;
