import React from 'react';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import history from '../../../sbHistory';
import { Collapse } from 'react-bootstrap';
import { Toggle } from 'material-ui';
import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact';

// API
import { toTitleCase } from 'api/Helpers';
import { getCurrentUser, getAttribute } from 'api/Parse';

// Components
import EditVehicleButton from 'components/AddEditEquipment/container/EditVehicleButton';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import DayChooser from 'components/Map.old/view/components/DayChooser';
import InputSwitch from 'sbCore/InputSwitch/InputSwitch';
import Calendar from 'sbCore/Calendar/Calendar';

import styles from './HistoryComponent.module.scss';

function HistoryComponent(props) {
  function getStreetView(vehicle) {
    // http://stackoverflow.com/questions/6018060/google-maps-url-i-want-to-create-a-url-to-google-maps-i-want-to-show-search-ne
    const long = vehicle.get('vehicleLocation').get('location').longitude;
    const lat = vehicle.get('vehicleLocation').get('location').latitude;
    window.open(`https://maps.google.com/maps?q=${lat},${long}&t=h&iwd=0&z=18`);
  }

  function pathExists() {
    if (props.activeVehicleHistory && props.activeVehicleHistory.legs && props.activeVehicleHistory.legs.length > 0) {
      for (let i = 0; i < props.activeVehicleHistory.legs.length; i++) {
        if (props.activeVehicleHistory.legs[i].waypoints && props.activeVehicleHistory.legs[i].waypoints.length > 0) return true;
      }
    }
    return false;
  }

  const currentUser = getCurrentUser();
  const userSpecialPermission = getAttribute(currentUser, 'userSpecialPermission');
  // if the company is not granted access to the driver module
  const disableMapSpeed = userSpecialPermission && getAttribute(userSpecialPermission, 'disableMapSpeed');

  // All commented out parts have been transfered to the new active trailer/vehicle cards, check VehicleTrailerActiveView for more details
  return (
    <span>
      <div className={styles.gpsHistoryDetails}>
        <div className={styles.panel}>
          <div className={styles.dayChooser}>
            <DayChooser
              date={props.activeVehicleHistory.date}
              hourStart={props.activeVehicleHistory.hourStart}
              hourEnd={props.activeVehicleHistory.hourEnd}
              handleDateChange={props.handleDateFilterChange}
              handleHourChange={props.handleHourSliderChange}
              triggerHourUpdate={props.triggerHourUpdate}
              toggleReplay={props.toggleReplay}
              replay={props.replay}
            />
          </div>

          {props.activeVehicleHistory.loading &&
            <div styles={{ margin: '1em' }}><LoadingIcon /></div>
          }


          {!props.activeVehicleHistory.loading &&
            (!props.activeVehicleHistory?.legs?.[0]?.vehicleLocationPointsArr || !props.activeVehicleHistory?.legs?.[0]?.vehicleLocationPointsArr?.length === 0) && (
              <div
                style={{ fontSize: '0.8em' }}
              >
                <kbd>No Driver History For Day</kbd>
              </div>
            )}

          {(
            props.activeVehicleHistory
            && props.activeVehicleHistory.legs
            && props.activeVehicleHistory.legs.length > 0
            && props.activeVehicleHistory.legs[0].vehicleLocationPointsArr
            && props.activeVehicleHistory.legs[0].vehicleLocationPointsArr.length > 0
            && !(props.activeVehicleHistory.loading)
          )
            &&
            <div className={styles.historyButton}>
              {/* <Toggle
                label={pathExists() ? 'Show Path' : 'Cannot generate path'}
                labelPosition="right"
                onToggle={props.togglePath}
                labelStyle={{ fontSize: '0.7em' }}
                toggled={props.activeVehicleHistory.showPath}
                disabled={!pathExists()}
                className="translate-me"
              /> */}
              {/* {!disableMapSpeed &&
                <Toggle
                  label={'Show Trip Stops'}
                  labelPosition="right"
                  onToggle={props.toggleTripStops}
                  labelStyle={{ fontSize: '0.7em' }}
                  toggled={props.activeVehicleHistory.showTripStops}
                  className="translate-me"
                />
                // <div>
                //   <div className="inputswitch-label inline-block text-right translate-me uppercase">
                //     {`Show Trip Stops`}
                //   </div>
                //   <InputSwitch
                //     checked={props.activeVehicleHistory.showTripStops}
                //     onChange={props.toggleTripStops}
                //   />
                // </div>
              } */}
              {!props.replay.loading &&
                <MDBBtn
                  color='primary'
                  size="sm"
                  disabled={props.replay.loading}
                  onClick={() => props.toggleReplay()}
                  className="translate-me"
                >
                  <MDBIcon icon="history" className="mr-1" /> REPLAY GPS
                </MDBBtn>
              }
              {props.replay.loading &&
                <MDBBtn
                  color='danger'
                  size="sm"
                  onClick={() => props.toggleReplay(true)}
                  className="translate-me"
                >
                  <MDBIcon icon="history" className="mr-1" /> Cancel
                </MDBBtn>
              }
              <MDBBtn
                color='primary'
                size="sm"
                onClick={() => props.downloadHistoryCsv()}
                className="translate-me"
              >
                <MDBIcon icon="download" className="mr-1" /> EXPORT CSV
              </MDBBtn>
            </div>
          }
        </div>
      </div>
    </span >
  );
}

HistoryComponent.propTypes = {
  activeVehicle: PropTypes.object,
  activeVehicleHistory: PropTypes.object,
  replay: PropTypes.object,
  getLinkShare: PropTypes.func,
  handleDateFilterChange: PropTypes.func,
  handleHourSliderChange: PropTypes.func,
  linkShare: PropTypes.object,
  pingGNXLocation: PropTypes.func,
  pingError: PropTypes.string,
  pingLocation: PropTypes.func,
  pinging: PropTypes.bool,
  refreshActiveVehicle: PropTypes.func,
  showGPSHistory: PropTypes.bool,
  togglePath: PropTypes.func,
  toggleGPSHistory: PropTypes.func,
  triggerHourUpdate: PropTypes.func,
  unselectDriver: PropTypes.func,
  zoomToActive: PropTypes.func,
};

export default HistoryComponent;
