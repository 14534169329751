import React, { useEffect, useState } from 'react';
// API
import { t } from 'api/Translate';

// sbCore Components
import { ConfirmPopup, confirmPopupSettings } from 'sbCore/ConfirmPopup/ConfirmPopup';
import InputText from 'sbCore/InputText/InputText';
import IconLabel from 'sbCore/IconLabel/IconLabel';

import { MDBBtn } from 'mdbreact';

// CSAPI
import { getAttribute, getCurrentUserSessionToken } from 'sb-csapi/dist/AAPI';
import { updateELDDailyCertification } from 'sb-csapi/dist/api/ELDDailyCertification/ELDDailyCertification';
import { compressWhitespace } from 'sb-csapi/dist/utils/String';

// Styles
import './style.scss';

/**
 * @description Sets the eldDailyCertification.shippingDocumentNumber for eldEvents to read off of and used for vendor integrations
 * @param {ELDDailyCertification} eldDailyCertification - Determines whether or not this feature is enabled, and updates its field
 * @returns
 */
function DefaultShippingDocumentNumberPopup({ ...props }) {
  const { eldDailyCertification } = props;

  const [hasELDDailyCertification, setHasELDDailyCertification] = useState(false);

  async function handleConfirmButtonClick() {
    try {
      const inputTextEl = document.getElementsByClassName('default-shipping-document-number-popup-input')[0];

      if (inputTextEl) {
        let shippingDocumentNumber = compressWhitespace(inputTextEl.value);
        if (!shippingDocumentNumber) shippingDocumentNumber = undefined;
        await updateELDDailyCertification({ sessionToken: getCurrentUserSessionToken() }, eldDailyCertification, { shippingDocumentNumber }, true);
      }
    } catch (err) {
      throw new Error(err);
    }
  }

  function onShow() {
    const inputTextEl = document.getElementsByClassName('default-shipping-document-number-popup-input')[0];

    if (inputTextEl && eldDailyCertification) {
      inputTextEl.value = getAttribute(eldDailyCertification, 'shippingDocumentNumber') || '';
    }
  }

  useEffect(() => {
    if (eldDailyCertification && getAttribute(eldDailyCertification, 'objectId')) setHasELDDailyCertification(true);
  }, [eldDailyCertification]);

  let className = 'default-shipping-document-number-popup';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <MDBBtn
        size="sm"
        color="secondary"
        style={{ width: '14em', marginRight: '.5em' }}
        onClick={(event) => {
          confirmPopupSettings({
            target: event.currentTarget,
            message:
            (
              <div>
                <div className="text-black">
                  Set next default Shipping ID for this day:
                  <InputText
                    type="text"
                    className="p-inputtext-sm ml-2 default-shipping-document-number-popup-input"
                    placeholder="Ex: 12345"
                  />
                </div>
                <div className="mt-3 text-warning default-shipping-document-number-popup-context-message" style={{ fontSize: '.85em' }}>
                  <IconLabel className="inline-block" iconClassName="pi pi-exclamation-triangle" /> This ID is only used for Third Party Tracking Integration and will only apply to events created after it is set
                </div>
              </div>
            ),
            acceptLabel: 'Save',
            rejectLabel: 'Cancel',
            accept: () => handleConfirmButtonClick(),
            onShow: () => onShow(),
          });
        }}
        disabled={!hasELDDailyCertification}
      >{t('Set Shipping ID')}
      </MDBBtn>
      <ConfirmPopup />
    </div>
  );
}
export default DefaultShippingDocumentNumberPopup;
