import * as ActionConstants from '../actions/ActionConstants';

export function Admin(state = {}, action) {
  switch (action.type) {
    case ActionConstants.FETCH_ADMIN_INPROGRESS:
      return {
        ...state,
        status: action.type,
        error: undefined,
      };
    case ActionConstants.FETCH_ADMIN_SUCCESS:
      return {
        ...state,
        status: action.type,
        admin: action.admin,
        error: undefined,
      };
    case ActionConstants.FETCH_ADMIN_ERROR:
      return {
        ...state,
        status: action.type,
        admin: {},
        error: action.error,
      };
    default:
      return state;
  }
}
