import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { fetchNotificationsForState, deleteNotificationsForState, readNotification } from 'actions/Notification';

// Components
import NotificationBadge from './NotificationBadge';
import NotificationPanel from './NotificationPanel';

// Context
import StoreContext from 'contexts/StoreContext';

class NotificationDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
    };
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleNotificationHover = this.handleNotificationHover.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.Notification.unreadCount !== this.props.Notification.unreadCount) {
      window.todesktop && window.todesktop.app.setBadgeCount(nextProps.Notification.unreadCount); // ToDesktop
    }
  }

  toggleDropdown() {
    this.setState({ ...this.state, isExpanded: !this.state.isExpanded });
  }

  handleNotificationHover(notification, destroy) {
    if (!notification.read) {
      readNotification(notification, destroy).then(() => {
        if (destroy) {
          // refresh notification state if user chose to remove an item
          deleteNotificationsForState().then(() => {
            fetchNotificationsForState();
          });
        }
      });
    }
  }

  render() {
    const { Notification } = this.props;
    const { isExpanded } = this.state;

    return (
      <div className="notification-dropdown-container">
        <NotificationBadge toggleDropdown={this.toggleDropdown} isPanelExpanded={isExpanded} unreadCount={Notification.unreadCount} />
        { isExpanded &&
          <NotificationPanel handleNotificationHover={this.handleNotificationHover} {...this.props.Notification} />
        }
      </div>
    );
  }
}

NotificationDropdown.propTypes = {
  Notification: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Notification } = state;
  return {
    Notification,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(NotificationDropdown);

