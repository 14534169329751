import React from 'react'
import PropTypes from 'prop-types'

// Components
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal'
import { MDBCol, MDBBtn } from 'mdbreact'

import './style.scss'

class StatusChangeButton extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = { showModal: false }
    this.openConfirmModal = this.openConfirmModal.bind(this)
    this.handleModalClose = this.handleModalClose.bind(this)
    this.handleModalChoice = this.handleModalChoice.bind(this)
  }

  handleModalChoice (choiceBool) {
    this.setState({ ...this.state, showModal: false })
    this.props.handleAction(choiceBool)
  }
  handleModalClose () {
    this.setState({ ...this.state, showModal: false })
  }
  openConfirmModal () {
    this.setState({ ...this.state, showModal: true })
  }

  render () {
    let buttonColor = 'primary'
    if (this.props.buttonColor) {
      buttonColor = this.props.buttonColor
    }
    let buttonSize = 'sm'
    if (this.props.buttonSize) {
      buttonSize = this.props.buttonSize
    }
    return (
      <MDBCol onClick={this.openConfirmModal}>
        <MDBBtn color={buttonColor} size={buttonSize}>
          {this.props.buttonText}
        </MDBBtn>
        <ConfirmModal
          show={this.state.showModal}
          handleModalChoice={this.handleModalChoice}
          handleClose={this.handleModalClose}
        >
          {this.props.confirmationContent ? this.props.confirmationContent : ''}
        </ConfirmModal>
      </MDBCol>
    )
  }
}

StatusChangeButton.propTypes = {
  handleAction: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
  buttonSize: PropTypes.string,
  confirmationContent: PropTypes.node
}

export default StatusChangeButton
