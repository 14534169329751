import React from 'react';

// CSAPI API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// CSAPI Enums
import { Payee } from 'sb-csapi/dist/enums/Dispatch/Payee';

// sbCore Components
import AutocompleteCard from 'sbCore/AutocompleteCard/AutocompleteCard';

// Styles
import './style.scss';

/**
 * @description A card displaying DispatchPayee information
 * @param {DispatchPayee} dispatchPayee - The selected DispatchPayee
 */
function DispatchPayeeCard({ ...props }) {
  if (!props.dispatchPayee) return null;

  const name = getAttribute(props.dispatchPayee, 'name');
  const code = getAttribute(props.dispatchPayee, 'code') ? `(${getAttribute(props.dispatchPayee, 'code')})` : '';
  const type = getAttribute(props.dispatchPayee, 'type');
  const payeeTypeString = (type === Payee.DRIVER.type) ? Payee.DRIVER.description : Payee.COMPANY.description;

  return (
    <AutocompleteCard className="dispatch-payee-card" isButtonsHidden>
      <div>
        <span className="font-bold name">{name}</span>
        <span className="code"> {code}</span>
      </div>

      <div className="type">
        {payeeTypeString}
      </div>

    </AutocompleteCard>
  );
}

export default DispatchPayeeCard;
