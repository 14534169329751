import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

// Components
import Title from 'components/LayoutTitle/view/Title';
import FilterForm from 'components/FilterForm/container/FilterForm';
import SortButton from 'components/SortButton/view/SortButton';

import VehicleIssuesRow from './VehicleIssuesRow';

// CSS
import styles from './IssuesTable.module.scss';

class VehicleIssuesTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: [],
      sort: { activePoints: 'descending' },
    };
    this.combineInspections = this.combineInspections.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.setSort = this.setSort.bind(this);
    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.state.filterFormFields = [
      { attrName: 'vehicle_plate', fullName: 'Plate Number', placeholder: '.....', type: 'text' },
    ];
  }

  componentDidMount() {
    this.refreshState(this.props.inspections, undefined, this.state.sort);
  }

  setSort(attribute) {
    const newState = { ...this.state };
    const currentSort = this.state.sort[attribute]; // the current sort, if defined

    if (currentSort) {
      if (currentSort === 'ascending') {
        newState.sort[attribute] = 'descending';
      } else {
        newState.sort[attribute] = 'ascending';
      }
    }
    else if (attribute === 'dateTime') {
      newState.sort = { [attribute]: 'ascending' };
    }
    else if (attribute === 'user_fullName') {
      newState.sort = { [attribute]: 'ascending' };
    }
    else if (attribute === 'activePoints') {
      newState.sort = { [attribute]: 'ascending' };
    }
    this.setState(newState, () => {
      this.refreshState(this.props.inspections, this.state.filter, this.state.sort);
    });
  }

  combineInspections(inspections) {
    // combine vehicle stats based on plate #
    const combinedInspections = [];
    // keep track of which plate/document# have already been seen (so we know which to combine)
    // we must keep track of document# as well as there can be multiple vehicles per inspection
    const seenPlates = {};

    for (let i = 0; i < inspections.length; i++) {
      const inspection = inspections[i];
      const plate = inspection.get('vehicle_plate');

      if (!plate || (inspection.get('vehicle_plate').replace(/\s+/g, '').length === 0)) {
        combinedInspections.push({
          plate: inspection.get('plate'),
          vehicle_plate: inspection.get('vehicle_plate'),
          activePoints: inspection.get('activePoints'),
          documentNumbers: [inspection.get('documentNumber')],
          id: inspection.id,
        });
      }
      else if (plate && !seenPlates[plate]) {
        // add it for first time
        seenPlates[plate] = {
          plate: inspection.get('plate'),
          vehicle_plate: inspection.get('vehicle_plate'),
          activePoints: inspection.get('activePoints'),
          documentNumbers: [inspection.get('documentNumber')],
          id: inspection.id,
        };
      }
      else if (seenPlates[plate]) {
        let plateAndDocumentAlreadyExists = false;
        for (let k = 0; k < seenPlates[plate].documentNumbers.length; k++) {
          if (seenPlates[plate].documentNumbers[k] === inspection.get('documentNumber')) {
            plateAndDocumentAlreadyExists = true;
          }
        }

        if (plateAndDocumentAlreadyExists) {
          continue;
        }

        seenPlates[plate].documentNumbers.push(inspection.get('documentNumber'));
        if (!inspection.get('activePoints') || isNaN(parseFloat(inspection.get('activePoints')))) {
          // what we want to add is not a number
          continue;
        }
        else if (isNaN(parseFloat(seenPlates[plate].activePoints)) && isNaN(parseFloat(inspection.get('activePoints')))) {
          // both are not numbers
          continue;
        }
        else if (isNaN(parseFloat(seenPlates[plate].activePoints)) && !isNaN(parseFloat(inspection.get('activePoints')))) {
          // if what we want to add is a number but the activepoints of the seen driver is not
          seenPlates[plate].activePoints = parseFloat(inspection.get('activePoints'));
        }
        else {
          // both exist and are numbers
          seenPlates[plate].activePoints = parseFloat(seenPlates[plate].activePoints) + parseFloat(inspection.get('activePoints'));
        }
      }
    }

    // now put them all in an array
    const keys = Object.keys(seenPlates);
    for (let j = 0; j < keys.length; j++) {
      combinedInspections.push(seenPlates[keys[j]]);
    }
    return combinedInspections;
  }

  refreshState(inspections, filterObject, sortBy) {
    // re-set state with inspection prop
    const newState = { ...this.state };

    if (!inspections) {
      newState.vehicleIssuesRows = <tr><td className="centerText" colSpan="3">No issues for this month</td></tr>;
      return this.setState(newState);
    }

    // Filter for inspections only this month
    const currentDate = new Date();

    const monthFilteredInspections = inspections.filter(inspection => {
      const dateTime = inspection.get('dateTime');
      return dateTime && (dateTime.getMonth() === currentDate.getMonth()) && (dateTime.getYear() === currentDate.getYear());
      // return true;
    });

    let combinedInspections = this.combineInspections(monthFilteredInspections);

    // filter applied
    if (filterObject) {
      const keys = Object.keys(filterObject);
      combinedInspections = combinedInspections.filter(issue => {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const searchValue = issue[key];
          if (!(searchValue && searchValue.indexOf(filterObject[key].toLowerCase()) > -1)) {
            return false;
          }
        }
        return true;
      });
    }

    if (sortBy) {
      if (sortBy.activePoints) {
        combinedInspections.sort((inspectionA, inspectionB) => {
          let inspectionAPoints = inspectionA.activePoints;
          let inspectionBPoints = inspectionB.activePoints;
          if (!inspectionAPoints) {
            inspectionAPoints = -9999999;
          }
          if (!inspectionBPoints) {
            inspectionBPoints = -9999999;
          }

          if (sortBy.activePoints === 'ascending') {
            return parseFloat(inspectionAPoints) - parseFloat(inspectionBPoints);
          }
          return parseFloat(inspectionBPoints) - parseFloat(inspectionAPoints);
        });
      }
      else if (sortBy.vehicle_plate) {
        combinedInspections.sort((inspectionA, inspectionB) => {
          let inspectionAPlate = inspectionA.vehicle_plate;
          let inspectionBPlate = inspectionB.vehicle_plate;
          if (!inspectionAPlate) {
            inspectionAPlate = '';
          }
          if (!inspectionBPlate) {
            inspectionBPlate = '';
          }
          if (inspectionAPlate === inspectionBPlate) {
            return 0;
          }
          if (sortBy.vehicle_plate === 'ascending') {
            if (inspectionAPlate < inspectionBPlate) {
              return -1;
            }
            return 1;
          }
          else if (sortBy.vehicle_plate === 'descending') {
            if (inspectionAPlate > inspectionBPlate) {
              return -1;
            }
            return 1;
          }
        });
      }
    }

    if (combinedInspections.length > 0) {
      newState.vehicleIssuesRows = combinedInspections.map(inspection => {
        return <VehicleIssuesRow inspection={inspection} key={inspection.id} rowKey={inspection.id} />;
      });
    } else {
      newState.vehicleIssuesRows = <tr><td className="centerText" colSpan="3">No issues for this month</td></tr>;
    }
    return this.setState(newState);
  }

  filter(filterObject) {
    this.setState({ ...this.state, filter: filterObject }, () => {
      this.refreshState(this.props.inspections, this.state.filter, this.state.sort);
    });
  }

  clearFilter() {
    this.setState({ ...this.state, filter: [] }, () => {
      this.refreshState(this.props.inspections, undefined, this.state.sort);
    });
  }

  render() {
    return (
      <div className={styles.issuesTable}>
        <Title className="h3" title="Vehicle Issues" />

        <table className={styles.titleSub}>
          <tbody>
            <tr>
              <td>
                <p>
                  Here you can view all monthly points your vehicles accumulated from each inspection
                </p>
              </td>
              <td>
                <FilterForm
                  className="floatRight"
                  handleFilter={this.filter}
                  clearFilter={this.clearFilter}
                  fields={this.state.filterFormFields}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="clearBoth" />

        <div className={`subTable ${styles.issuesSubTable}`}>
          <Table responsive hover>
            <thead>
              <tr>
                <th width="30%">
                  Plate #
                  <SortButton
                    clickHandler={() => this.setSort('vehicle_plate')}
                    isActive={false}
                  />
                </th>
                <th width="30%">
                  Points Accumulated This Month
                  <SortButton
                    clickHandler={() => this.setSort('activePoints')}
                    isActive={false}
                  />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              { this.state.vehicleIssuesRows }
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

VehicleIssuesTable.propTypes = {
  inspections: PropTypes.array,
};

export default VehicleIssuesTable;
