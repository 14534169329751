/* eslint-disable react/jsx-no-bind */
import uniqid from 'uniqid';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

// API
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getAPIAuths, updateAPIAuth } from 'api/APIAuth/APIAuth';

// Components
import Card from 'sbCore/Card/Card';
import Button from 'sbCore/Button/Button';
import Column from 'sbCore/Column/Column';
import DataTable from 'sbCore/DataTable/DataTable';
import { ConfirmPopup, confirmPopupSettings } from 'sbCore/ConfirmPopup/ConfirmPopup';

import APIKeyGeneratorModal from 'components/APIKeyGeneratorModal/APIKeyGeneratorModal';

/**
 * Holds the layout for displaying API credentials
 *
 * @returns {JSX}
 */
function APIKeyCard() {
  const [isLoading, setIsLoading] = useState(true);
  const [apiInformationArray, setAPIInformationArray] = useState([]);
  const [isAPIKeyGeneratorModalOpen, setIsAPIKeyGeneratorModalOpen] = useState(false);

  // Triggers a referesh of the API credentials
  const [refreshToken, setRefreshToken] = useState(uniqid());

  useEffect(() => {
    let didCancel = false;

    async function getAPIInformation() {
      setIsLoading(true);
      const { apiAuths } = await getAPIAuths();

      // Create an array of API information for the table
      const _apiInformationArray = apiAuths.map((apiAuth) => ({
        apiAuth,
        label: getAttribute(apiAuth, 'label'),
        apiId: getAttribute(apiAuth, 'apiId'),
        createdAt: getAttribute(apiAuth, 'createdAt'),
      }));

      if (!didCancel) {
        setAPIInformationArray(_apiInformationArray);
        setIsLoading(false);
      }
    }

    getAPIInformation();
    return () => {
      didCancel = true;
    };
  }, [refreshToken]);

  function labelBodyTemplate(rowData) {
    return rowData.label;
  }

  function idBodyTemplate(rowData) {
    return rowData.apiId;
  }

  function createdAtBodyTemplate(rowData) {
    return moment(rowData.createdAt).format('YYYY-MM-DD @ HH:mm');
  }

  function actionsBodyTemplate(rowData) {
    return (
      <div className="flex flex-row justify-content-end align-items-center">
        <Button
          text
          rounded
          className="p-button-danger"
          icon={<span className="material-icons">delete</span>}
          tooltip="Delete API Key"
          tooltipOptions={{ position: 'left' }}
          onClick={(event) => {
            confirmPopupSettings({
              target: event.currentTarget,
              message: <div>Proceed to remove this API key? This action is <span className="font-bold">irreversible</span>.</div>,
              icon: 'pi pi-exclamation-triangle',
              rejectClassName: 'p-button-text p-button-secondary',
              acceptClassName: 'p-button-danger',
              accept: async () => {
                // Remove the API Key when user confirms
                await updateAPIAuth(rowData.apiAuth, undefined, { isHidden: true }, true);
                setRefreshToken(uniqid());
              },
            });
          }}
        />
      </div>
    );
  }

  const header = (
    <div className="flex flex-row justify-content-between align-items-end px-3 pt-4">
      <h4 className="font-bold mb-0">API Keys</h4>
      <a
        href="https://api.onswitchboard.com"
        target="_blank"
      >
        <Button
          sbVariant="slim"
          className="p-button"
          icon={<span className="material-icons mr-1">help</span>}
          label="Learn how to use API Keys"
        />
      </a>
      &nbsp:
      <Button
        sbVariant="slim"
        className="p-button-secondary"
        icon={<span className="material-icons mr-1">add</span>}
        label="Create API Key"
        onClick={() => setIsAPIKeyGeneratorModalOpen(true)}
      />
    </div>
  );

  return (
    <>
      <APIKeyGeneratorModal
        isVisible={isAPIKeyGeneratorModalOpen}
        onClose={() => {
          setIsAPIKeyGeneratorModalOpen(false);
          setRefreshToken(uniqid());
        }}
      />
      <ConfirmPopup />
      <Card
        header={header}
      >
        <DataTable
          value={apiInformationArray}
          emptyMessage="No API keys were found. Create a new API key to see it here."
          loading={isLoading}
          rowHover
        >
          <Column
            field={labelBodyTemplate}
            header="Label"
          />
          <Column
            field={idBodyTemplate}
            header="ID"
          />
          <Column
            field={createdAtBodyTemplate}
            header="Created"
          />
          <Column
            field={actionsBodyTemplate}
          />
        </DataTable>
      </Card>
    </>
  );
}

export default APIKeyCard;
