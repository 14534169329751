import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';

// Components
import Title from 'components/LayoutTitle/view/Title';
import FilterForm from 'components/FilterForm/container/FilterForm';
import SortButton from 'components/SortButton/view/SortButton';

import DriverIssuesRow from './DriverIssuesRow';

// CSS
import styles from './IssuesTable.module.scss';

class DriverIssuesTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filter: [],
      sort: { activePoints: 'descending' },
    };
    this.combineDriverContraventions = this.combineDriverContraventions.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.setSort = this.setSort.bind(this);
    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.state.filterFormFields = [
      { attrName: 'user_fullName', fullName: 'Driver Name', placeholder: '.....', type: 'text' },
    ];
  }

  componentDidMount() {
    this.refreshState(this.props.contraventions, undefined, this.state.sort);
  }

  setSort(attribute) {
    const newState = { ...this.state };
    const currentSort = this.state.sort[attribute]; // the current sort, if defined

    if (currentSort) {
      if (currentSort === 'ascending') {
        newState.sort[attribute] = 'descending';
      } else {
        newState.sort[attribute] = 'ascending';
      }
    }
    else if (attribute === 'user_fullName') {
      newState.sort = { [attribute]: 'ascending' };
    }
    else if (attribute === 'activePoints') {
      newState.sort = { [attribute]: 'ascending' };
    }
    this.setState(newState, () => {
      this.refreshState(this.props.contraventions, this.state.filter, this.state.sort);
    });
  }

  combineDriverContraventions(contraventions) {
    // function to figure out and combine a driver's points
    const combinedContraventions = [];
    const seenDriversLicenses = {}; // keep track of which licenses have already been seen (so we know which to combine)

    for (let i = 0; i < contraventions.length; i++) {
      const contravention = contraventions[i];
      const driversLicense = contravention.get('driver_driversLicense');
      if (!driversLicense || (contravention.get('driver_driversLicense').replace(/\s+/g, '').length === 0)) {
        combinedContraventions.push({
          fullName: contravention.get('fullName'),
          user_fullName: contravention.get('user_fullName'),
          activePoints: contravention.get('activePoints'),
          parseContravention: contravention,
          id: contravention.id,
        });
      }
      else if (driversLicense && !seenDriversLicenses[driversLicense]) {
        // add it for first time
        seenDriversLicenses[driversLicense] = {
          fullName: contravention.get('fullName'),
          user_fullName: contravention.get('user_fullName'),
          activePoints: contravention.get('activePoints'),
          parseContravention: contravention,
          id: contravention.id,
        };
      }
      else if (seenDriversLicenses[driversLicense]) {
        // add points
        if (!contravention.get('activePoints') || isNaN(parseFloat(contravention.get('activePoints')))) {
          // what we want to add is not a number
          continue;
        }
        else if (isNaN(parseFloat(seenDriversLicenses[driversLicense].activePoints)) && isNaN(parseFloat(contravention.get('activePoints')))) {
          // both are not numbers
          continue;
        }
        else if (isNaN(parseFloat(seenDriversLicenses[driversLicense].activePoints)) && !isNaN(parseFloat(contravention.get('activePoints')))) {
          // if what we want to add is a number but the activepoints of the seen driver is not
          seenDriversLicenses[driversLicense].activePoints = parseFloat(contravention.get('activePoints'));
        }
        else {
          // both exist and are numbers
          seenDriversLicenses[driversLicense].activePoints += parseFloat(contravention.get('activePoints'));
        }
      }
    }

    // now put them all in an array
    const keys = Object.keys(seenDriversLicenses);
    for (let j = 0; j < keys.length; j++) {
      combinedContraventions.push(seenDriversLicenses[keys[j]]);
    }
    return combinedContraventions;
  }

  refreshState(contraventions, filterObject, sortBy) {
    // re-set state with contravention prop
    const newState = { ...this.state };

    if (!contraventions) {
      newState.driverIssuesRows = <tr><td className="centerText" colSpan="3">No issues for this month</td></tr>
      return this.setState(newState);
    }

    const currentDate = new Date();

    // filter for this month only
    const monthFilteredContraventions = contraventions.filter(contravention => {
      const dateTime = contravention.get('dateTime');
      return dateTime && (dateTime.getMonth() === currentDate.getMonth()) && (dateTime.getYear() === currentDate.getYear());
      // return true;
    });

    // combine the points of each driver
    let combinedContraventions = this.combineDriverContraventions(monthFilteredContraventions);

    if (filterObject) {
      const keys = Object.keys(filterObject);
      combinedContraventions = combinedContraventions.filter(contravention => {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const searchValue = contravention[key];
          if (!(searchValue && searchValue.indexOf(filterObject[key].toLowerCase()) > -1)) {
            return false;
          }
        }
        return true;
      });
    }

    if (sortBy) {
      if (sortBy.activePoints) {
        combinedContraventions.sort((contraventionA, contraventionB) => {
          let contraventionAPoints = contraventionA.activePoints;
          let contraventionBPoints = contraventionB.activePoints;
          if (!contraventionAPoints) {
            contraventionAPoints = -9999999;
          }
          if (!contraventionBPoints) {
            contraventionBPoints = -9999999;
          }

          if (sortBy.activePoints === 'ascending') {
            return parseFloat(contraventionAPoints) - parseFloat(contraventionBPoints);
          }
          return parseFloat(contraventionBPoints) - parseFloat(contraventionAPoints);
        });
      }
      else if (sortBy.user_fullName) {
        combinedContraventions.sort((contraventionA, contraventionB) => {
          let contraventionADriver = contraventionA.user_fullName;
          let contraventionBDriver = contraventionB.user_fullName;
          if (!contraventionADriver) {
            contraventionADriver = '';
          }
          if (!contraventionBDriver) {
            contraventionBDriver = '';
          }
          if (contraventionADriver === contraventionBDriver) {
            return 0;
          }
          if (sortBy.user_fullName === 'ascending') {
            if (contraventionADriver < contraventionBDriver) {
              return -1;
            }
            return 1;
          }
          else if (sortBy.user_fullName === 'descending') {
            if (contraventionADriver > contraventionBDriver) {
              return -1;
            }
            return 1;
          }
        });
      }
    }

    if (combinedContraventions.length > 0) {
      newState.driverIssuesRows = combinedContraventions.map(contravention => {
        return <DriverIssuesRow contravention={contravention} key={contravention.id} rowKey={contravention.id} />;
      });
    } else {
      newState.driverIssuesRows = <tr><td className="centerText" colSpan="3">No issues for this month</td></tr>;
    }

    return this.setState(newState);
  }

  filter(filterObject) {
    this.setState({ ...this.state, filter: filterObject }, () => {
      this.refreshState(this.props.contraventions, this.state.filter, this.state.sort);
    });
  }

  clearFilter() {
    this.setState({ ...this.state, filter: [] }, () => {
      this.refreshState(this.props.contraventions, undefined, this.state.sort);
    });
  }

  render() {
    return (
      <div className={styles.issuesTable}>
        <Title className="h3" title="Driver/Carrier Contraventions" />

        <table className={styles.titleSub}>
          <tbody>
            <tr>
              <td>
                <p>
                  Here you can view all monthly Contraventions and Accidents under your drivers
                </p>
              </td>
              <td>
                <FilterForm
                  className="floatRight"
                  handleFilter={this.filter}
                  clearFilter={this.clearFilter}
                  fields={this.state.filterFormFields}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className="clearBoth" />

        <div className={`subTable ${styles.issuesSubTable}`}>
          <Table responsive hover>
            <thead>
              <tr>
                <th width="30%">
                  Driver
                  <SortButton
                    clickHandler={() => this.setSort('user_fullName')}
                    isActive={false}
                  />
                </th>
                <th width="30%">
                  Points Accumulated This Month
                  <SortButton
                    clickHandler={() => this.setSort('activePoints')}
                    isActive={false}
                  />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              { this.state.driverIssuesRows }
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

DriverIssuesTable.propTypes = {
  contraventions: PropTypes.array,
};

export default DriverIssuesTable;
