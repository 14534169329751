import Parse from 'parse';
import store from '../store';
import * as Helpers from './Helpers';

/** @module Subscription */

/**
 * @memberof module:Subscription
 * @param {*} subscriptionParseObj 
 * @returns 
 */
function cancelSubscription(subscriptionParseObj) {
  return Parse.Cloud.run('cancelSubscription', { subscriptionId: subscriptionParseObj.get('subscriptionId') });
}

/**
 * @memberof module:Subscription
 * @param {*} subscriptionParseObj 
 * @returns 
 */
function checkSubscription(subscriptionParseObj) {
  if (subscriptionParseObj.get('customerId')) {
    return Parse.Cloud.run('checkSubscription', { customerId: subscriptionParseObj.get('customerId') });
  }
  return Promise.resolve({ active: false });
}

/**
 * @memberof module:Subscription
 * @param {*} subscriptionParseObj 
 * @returns 
 */
function renewSubscription(subscriptionParseObj) {
  return Parse.Cloud.run('restartSubscription', { customerId: subscriptionParseObj.get('customerId'), subscriptionId: subscriptionParseObj.get('subscriptionId'), companyObjectId: subscriptionParseObj.get('company').id });
}

/**
 * @memberof module:Subscription
 * 
 * @param {*} companyObj 
 * @param {*} token 
 * 
 * @returns 
 */
function addCCToCustomer(companyObj, token) {
  return Parse.Cloud.run('addCardTokenToCustomer', {
    token,
    companyObjectId: companyObj.id,
  });
}

/**
 * @memberof module:Subscription
 * @param {*} companyObj 
 * @returns 
 */
function getOutstandingInvoiceAmount(companyObj) {
  return Parse.Cloud.run('getOutstandingPayments', {
    companyObjectId: companyObj.id,
  });
}

/**
 * @memberof module:Subscription
 * @param {*} companyObj 
 * @returns 
 */
function payOutstandingInvoices(companyObj) {
  return Parse.Cloud.run('payOutstandingPayments', {
    companyObjectId: companyObj.id,
  });
}

export {
  cancelSubscription,
  checkSubscription,
  renewSubscription,
  addCCToCustomer,
  getOutstandingInvoiceAmount,
  payOutstandingInvoices,
};
