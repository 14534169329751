import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "../../../sbHistory";

// API
import * as ELD from 'api/ELD';

// Components
import HOSPreviewDialog from 'components/HOSPreviewDialog/container/HOSPreviewDialog';

import {
  Table,
  TableHeader,
  TableRow,
  TableRowColumn,
  TableHeaderColumn,
  TableBody,
} from 'material-ui';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';

class ELDEditsTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      eldEdits: [],
      toggleHOSPreview: false,
      selectedELDEdit: undefined,
      dialogDriverEdit: {
        open: false,
        acceptEdit: false,
        dialogTitle: '',
        dialogBody: '',
      },
    };

    this.toggleHOSPreview = this.toggleHOSPreview.bind(this);
    this.handleDriverEditDialogToggle = this.handleDriverEditDialogToggle.bind(this);
  }

  toggleHOSPreview(selectedELDEdit) {
    this.setState({ ...this.state, toggleHOSPreview: !this.state.toggleHOSPreview, selectedELDEdit });
  }

  handleDriverEditDialogToggle(eldEdit, acceptEdit, submit) {
    const newState = { ...this.state };
    newState.dialogDriverEdit.open = !this.state.dialogDriverEdit.open;

    const acceptEditReasonDOM = document.getElementById('driverAcceptEditReason');
    if (acceptEdit && submit) {
      const disableAcceptEdit = !acceptEditReasonDOM || !acceptEditReasonDOM.value || !acceptEditReasonDOM.value.trim();
      if (disableAcceptEdit) return;
    }

    if (!newState.dialogDriverEdit.open) {
      // if dialog is a no show or closed, reset dialogDriverEdit state
      newState.selectedELDEdit = undefined;
      newState.dialogDriverEdit.acceptEdit = false;
      newState.dialogDriverEdit.dialogTitle = '';
      newState.dialogDriverEdit.dialogBody = '';
    } else {
      newState.selectedELDEdit = eldEdit;
      newState.dialogDriverEdit.acceptEdit = acceptEdit;
      if (acceptEdit) {
        newState.dialogDriverEdit.dialogTitle = 'Accept Edit';
        newState.dialogDriverEdit.dialogBody = 'Confirming will Accept this Edit. Are you sure?';
      } else {
        newState.dialogDriverEdit.dialogTitle = 'Reject Edit';
        newState.dialogDriverEdit.dialogBody = 'Confirming will Reject this Edit. Are you sure?';
      }
    }

    if (submit) {
      return this.setState(newState, () => {
        ELD.handleDriverEdit(eldEdit, acceptEdit, acceptEditReasonDOM && acceptEditReasonDOM.value && acceptEditReasonDOM.value.trim()).then(
          () => this.props.getELDEdits()
        );
      });
    }

    this.setState(newState);
  }

  render() {

    const { selectedELDEdit, toggleHOSPreview, dialogDriverEdit } = this.state;
    const eldEdits = [].concat(this.props.eldEdits);

    const tableRows = eldEdits.map(eldEdit => {
      const driver = eldEdit.get('driver');
      const timezoneOffsetFromUTC = driver.get('timezoneOffsetFromUTC') || moment.tz.guess();
      const requestedBy = eldEdit.get('requestedBy');
      const requestedByFullName = requestedBy && `${requestedBy.get('firstName')} ${requestedBy.get('lastName')}`;

      let requestedDate;
      if (eldEdit.get('eldDailyCertification')) {
        requestedDate = moment(eldEdit.get('eldDailyCertification').get('startTimeUTC')).tz(timezoneOffsetFromUTC);
      } else if (eldEdit.get('eldDailyCertificationStartTimeUTC')) {
        requestedDate = moment(eldEdit.get('eldDailyCertificationStartTimeUTC')).tz(timezoneOffsetFromUTC);
      }

      const requestedAt = moment(eldEdit.get('requestedAt')).format('YYYY-MM-DD @ HH:mm');

      const statusStyle = {
        verticalAlign: 'middle', fontFamily: 'roboto', textTransform: 'uppercase', fontWeight: '500',
      };

      const urlLocationQueryDate = moment(requestedDate);
      const urlLocationQueryDateDate = (urlLocationQueryDate.date() < 10) ? `0${urlLocationQueryDate.date()}` : urlLocationQueryDate.date().toString();
      const urlLocationQueryDateMonth = (urlLocationQueryDate.month() < 10) ? `0${urlLocationQueryDate.month() + 1}` : (urlLocationQueryDate.month() + 1).toString();
      const urlLocationQueryDateYear = urlLocationQueryDate.year().toString().substr(2, 4);

      const disableAcceptReject = [4, 5].indexOf(eldEdit.get('completed')) !== -1;
      return (
        <TableRow key={eldEdit.id}>
          <TableRowColumn className="verticalAlignMiddle" style={{ width: '15%' }}>
            <span>{ requestedByFullName }</span>
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle" style={{ width: '20%' }}>
            <span>{ requestedDate.format('YYYY-MM-DD') }</span>
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle" style={{ width: '20%' }}>
            <span>{ requestedAt }</span>
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle" style={{ width: '15%' }}>
            { eldEdit.get('completed') === 3 &&
              <div style={{ ...statusStyle, color: '#E03857' }}>
                Unresolved
              </div>
            }

            { eldEdit.get('completed') === 4 &&
              <div style={{ ...statusStyle, color: '#656565' }}>
                Rejected - Pending
              </div>
            }

            { eldEdit.get('completed') === 5 &&
              <div style={{ ...statusStyle, color: '#0E9D3E' }}>
                Accepted - Pending
              </div>
            }
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle" style={{ textAlign: 'right', width: '55%' }}>
            <RaisedButton
              label="Accept"
              onClick={() => this.handleDriverEditDialogToggle(eldEdit, true)}
              primary
              disabled={disableAcceptReject}
              style={{ marginRight: '.5em' }}
            />
            <RaisedButton
              label="Reject"
              onClick={() => this.handleDriverEditDialogToggle(eldEdit, false)}
              secondary
              disabled={disableAcceptReject}
              style={{ marginRight: '.5em' }}
            />
            { requestedDate &&
              <RaisedButton
                label="View Preview"
                onClick={() => this.toggleHOSPreview(eldEdit)}
                style={{ marginRight: '.5em' }}
              />
            }
            { requestedDate &&
              <RaisedButton
                label="View Details"
                onClick={() => history.push({ pathname: 'driver', search: "driver="+ driver.id + "&view=hosEvents&date=" + `${urlLocationQueryDateDate}${urlLocationQueryDateMonth}${urlLocationQueryDateYear}` })}
              />
            }
          </TableRowColumn>
        </TableRow>
      );
    });

    let editDate;
    let editDriver;

    if (selectedELDEdit) {
      editDriver = selectedELDEdit.get('driver');
      editDate = (selectedELDEdit.get('eldDailyCertification')) ? selectedELDEdit.get('eldDailyCertification').get('startTimeUTC') : selectedELDEdit.get('eldDailyCertificationStartTimeUTC');
    }

    const dialogDriverEditActions = [
      <FlatButton
        label="Cancel"
        primary
        onClick={() => this.handleDriverEditDialogToggle(undefined, false)}
      />,
      <FlatButton
        label="Confirm"
        primary
        onClick={() => this.handleDriverEditDialogToggle(selectedELDEdit, dialogDriverEdit.acceptEdit, true)}
      />,
    ];

    return (
      <div>
        { this.props.loading ?
          <div style={{ textAlign: 'center', margin: '1em', fontWeight: 'bold' }}><CircularProgress /></div>
          :
          <Table
            wrapperStyle={{ overflow: 'hidden' }}
          >
            <TableHeader
              displaySelectAll={false}
              adjustForCheckbox={false}
              enableSelectAll={false}
            >
              <TableRow>
                <TableHeaderColumn style={{ width: '15%' }}>
                  Requested By
                </TableHeaderColumn>
                <TableHeaderColumn style={{ width: '20%' }}>
                  Affected Day (Driver Time)
                </TableHeaderColumn>
                <TableHeaderColumn style={{ width: '20%' }}>
                  Time of Request (Local Time)
                </TableHeaderColumn>
                <TableHeaderColumn style={{ width: '15%' }}>
                  Status
                </TableHeaderColumn>
                <TableHeaderColumn style={{ width: '55%' }} />
              </TableRow>
            </TableHeader>
            <TableBody
              displayRowCheckbox={false}
              showRowHover
            >
              { this.props.eldEdits &&
                tableRows
              }
              { this.props.eldEdits.length === 0 && !this.props.loading &&
                <TableRow>
                  <TableRowColumn
                    colSpan={42}
                  >
                    <div style={{ textAlign: 'center', margin: '1em', fontWeight: 'bold' }}>No Driver Edits to show</div>
                  </TableRowColumn>
                </TableRow>
              }
            </TableBody>
          </Table>
        }

        { toggleHOSPreview &&
          <HOSPreviewDialog
            autoFetch
            show={toggleHOSPreview}
            handleClose={() => this.toggleHOSPreview(undefined)}
            driver={editDriver}
            onDate={editDate}
            eldEdit={selectedELDEdit}
          />
        }

        { dialogDriverEdit.open &&
          <Dialog
            title={dialogDriverEdit.dialogTitle}
            actions={dialogDriverEditActions}
            contentStyle={{ width: '50%', maxWidth: 'none' }}
            open={dialogDriverEdit.open}
          >

            { dialogDriverEdit.dialogBody }

            { dialogDriverEdit.acceptEdit &&
              <div style={{ marginTop: '2em' }}>
                <div>A reason must be provided to accept:</div>
                <div>
                  <TextField
                    id="driverAcceptEditReason"
                    hintText="Enter reason for accepting"
                  />
                </div>
              </div>
            }

          </Dialog>
        }
      </div>
    );
  }

}

ELDEditsTable.propTypes = {
  eldEdits: PropTypes.array,
  loading: PropTypes.bool,
  getELDEdits: PropTypes.func,
};

export default ELDEditsTable;
