import React from 'react';

import { Dialog as PRDialog } from 'primereact/dialog';

import './style.scss';

/**
 *
 * @param {Boolean} [warning] - Changes the header to warning colour
 * @param {Boolean} [error] - Changes the header to error colour
 * @param {String} [sbVariant] - "Compact" for smaller/less white-spaced dialog
 * @returns
 */
export default function Dialog({ ...props }) {
  let className = 'sbc-dialog compact';
  if (props.className) className += ` ${props.className}`;
  if (props.sbVariant === 'compact') className += ' sbc-compact';

  if (props.warning) className += ' sbc-warning';
  if (props.error) className += ' sbc-error';

  return (<PRDialog { ...props } className={className} />);
}
