import { Provider } from 'react-redux';
import React, { useState, useCallback } from 'react';

import history from 'sbHistory';
import sbStore from 'sbStore';

// Components
import Map from 'components/Map/Map';
import Controls from 'components/Map/Controls/Controls';
import Sidebar from 'components/Map/Sidebar/Sidebar';
import PersistentDrawer from 'sbCore/PersistentDrawer/PersistentDrawer';

// API
import { getCurrentUser } from 'api/Getters';

// sb-csapi
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Styles
import './style.scss';

/**
* @description Map layout that hosts the map iteself and the non-native map controls.
* @returns
*
* @example
* <Route path="/map/:vehicleUnitId?" component={MapLayout} />
*/
function MapLayout() {
  // ** useStates ** //
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [zoom, setZoom] = useState(null);

  const [mapStyle, setMapStyle] = useState(null);

  const [isFitToVehicles, setIsFitToVehicles] = useState(false);

  const [searchBarLocationObj, setSearchBarLocationObj] = useState(null);

  // ** Functions ** //

  // Call back function that takes a vehicleUnitId, encodes it, and will focus on its location
  // function zoomToVehicle(vehicleUnitId) {
  //   const encodedVehicleUnitId = encodeURI(vehicleUnitId);
  //   history.push(`/map-2/${encodedVehicleUnitId}`);
  // }

  // function getDecodedVehicleUnitId(encodedVehicleUnitId) {
  //   const decodedVehicleUnitId = decodeURI(encodedVehicleUnitId);
  //   return decodedVehicleUnitId;
  // }

  // ** useCallbacks ** //

  // Updates the navigation info when the map is moved.
  const onNavigationInfoUpdate = useCallback((newLongitude, newLatitude, newZoom) => {
    setLongitude(newLongitude);
    setLatitude(newLatitude);
    setZoom(newZoom);
  }, []);

  // Updates the map style when a new style is chosen
  const onMapStyleUpdate = useCallback((newMapStyle) => {
    setMapStyle(newMapStyle);
  }, []);

  // Updates the isFitToVehicles state to true/false depending on the parameter
  const onIsFitToVehiclesToggle = useCallback((_isFitToVehicles) => {
    setIsFitToVehicles(_isFitToVehicles);
  }, []);

  // Updates searchBarLocationObj with the selected search bar location
  const handlePlaceSelected = useCallback((_searchBarLocationObj) => {
    setSearchBarLocationObj(_searchBarLocationObj);
  }, []);

  const currentUser = getCurrentUser();
  const userSpecialPermission = getAttribute(currentUser, 'userSpecialPermission');
  // if the company is not granted access to the map module
  if (userSpecialPermission && getAttribute(userSpecialPermission, 'disableMapModule')) {
    return history.push({ pathname: '404' });
  }

  return (
    <Provider store={sbStore}>
      <div className="map-layout flex">
        {/* Temporary Button used to simulate clicking a vehicle on the map. */}
        {/* <Button label="Focus on Vehicle Unit Id 4 20" onClick={() => zoomToVehicle('4 20')} /> */}

        {/* Temporary Button to log a decoded vehicle unit id. */}
        {/* <Button label="Log decoded id" onClick={() => console.log(getDecodedVehicleUnitId('4%2020'))} /> */}
        <PersistentDrawer
          width="350px"
          itemClassName="map-sidebar surface-0"
          forceOverlay
        >
          <Sidebar />
        </PersistentDrawer>

        <div className="w-full justify-content-center">
          <Map
            mapStyle={mapStyle}
            onNavigationInfoUpdate={onNavigationInfoUpdate}
            onIsFitToVehiclesToggle={onIsFitToVehiclesToggle}
            isFitToVehicles={isFitToVehicles}
            searchBarLocationObj={searchBarLocationObj}
          />
        </div>
      </div>

      <Controls
        longitude={longitude}
        latitude={latitude}
        zoom={zoom}
        onMapStyleUpdate={onMapStyleUpdate}
        onIsFitToVehiclesToggle={onIsFitToVehiclesToggle}
        handlePlaceSelected={handlePlaceSelected}
      />
    </Provider>
  );
}

export default MapLayout;
