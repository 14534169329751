import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import AddNewJobModalView from 'components/AddNewJob/view/AddNewJobModal';

// Actions
import { addJobLinkToState } from 'actions/JobLink';
import * as Setters from 'api/Setters';
import { getCurrentUser } from 'api/Parse';

// Context
import StoreContext from 'contexts/StoreContext';

function getInitialState() {
  const initialState = {
    addNewJobModal: { show: false },
    chooseOrAddModal: { show: false, label: '', property: '' },
    addingInProgress: false,
    validateError: false,
  };
  initialState.job = { drivers: [], customer: null, jobId: '', date: null };
  return initialState;
}

class AddNewJobModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState();
    this.addJob = this.addJob.bind(this);
    this.handleModalChoice = this.handleModalChoice.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.openChooseOrAddModal = this.openChooseOrAddModal.bind(this);
    this.closeChooseOrAddModal = this.closeChooseOrAddModal.bind(this);
    this.chooseDriversHandler = this.chooseDriversHandler.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) this.setState(getInitialState());
  }

  // Choosing Customer/Driver...
  openChooseOrAddModal(type) {
    this.state.chooseOrAddModal = { show: true, property: type, label: type };
    this.setState(this.state);
  }
  closeChooseOrAddModal() {
    this.state.chooseOrAddModal = { show: false, property: '', label: '' };
    this.setState(this.state);
  }
  handleModalChoice(parseObject, property) {
    this.state.chooseOrAddModal = { show: false, label: property, property };
    this.setState(this.state);
    const job = this.state.job;
    if (property === 'Driver') {
      job.driver = parseObject;
      this.setState({ job });
    } else if (property === 'Customer') {
      job.customer = parseObject;
      this.setState({ job });
    }
  }

  handleFormChange(jobProperty, jobValue) {
    const jobObj = this.state.job;
    jobObj[jobProperty] = jobValue;
    this.state.job = jobObj;
    this.setState(this.state);
  }

  chooseDriversHandler(driversObject) {
    // drivers object
    const newState = { ...this.state };
    newState.job.drivers = [];
    if (driversObject.mainDriver) {
      newState.job.drivers.push(driversObject.mainDriver);
    }
    if (driversObject.coDriver) {
      newState.job.drivers.push(driversObject.coDriver);
    }
    this.setState(newState);
  }

  addJob(formEntries) {
    // represents our pickup and dropoff job action
    const driverIdArr = formEntries.drivers.map(driver => { return driver.id; });
    let actionDate;
    if (formEntries.date) {
      actionDate = new Date(formEntries.date);
    }
    const jobActionRequest = {
      jobId: formEntries.jobId,
      actionDate,
      driverIdArr,
      dispatcherUserIdArr: [getCurrentUser().id],
      notes: formEntries.notes,
    };

    this.setState({ ...this.state, addingInProgress: true }, () => {
      Setters.createNewJob(jobActionRequest, jobActionRequest).then(
        jobLink => {
          addJobLinkToState(jobLink);
          this.state = getInitialState();
          this.setState(this.state, () => {
            this.props.handleClose();
          });
        },
        error => {
          this.setState({ ...this.state, validateError: true, addingInProgress: false });
        }
      );
    });
  }

  render() {
    return (
      <div>
        <AddNewJobModalView
          {...this.props}
          chooseDriverModalShow={this.state.chooseOrAddModal.show}
          validateError={this.state.validateError}
          handleFormChange={this.handleFormChange}
          job={this.state.job}
          openChooseOrAddModal={this.openChooseOrAddModal}
          closeChooseOrAddModal={this.closeChooseOrAddModal}
          onSubmit={this.addJob}
          addingInProgress={this.state.addingInProgress}
          chooseDriversHandler={this.chooseDriversHandler}
        />
      </div>
    );
  }
}

AddNewJobModal.propTypes = {
  Dispatcher: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  JobAction: PropTypes.object.isRequired,
  JobLink: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
  const { addNewJob } = state.form;
  const { JobAction, JobLink, Dispatcher } = state;
  return {
    addNewJob,
    JobAction,
    JobLink,
    Dispatcher,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(AddNewJobModal);
