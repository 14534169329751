/* eslint-disable max-len */
import moment from 'moment-timezone';
import React, { useState, useEffect } from 'react';

// Enums
import { EquipmentTypes } from 'enums/Equipment'; // need to move to sb-csapi
import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

// SB Objects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// API
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getEquipmentGroupEquipments } from 'api/Equipment/Groups';

// SB Core
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import Button from 'sbCore/Button/Button';
import TriStateCheckbox from 'sbCore/TriStateCheckbox/TriStateCheckbox';
import VehicleAutocompleteInput from 'sbCore/VehicleAutocomplete/VehicleAutocompleteInput';
import TrailerAutocompleteInput from 'sbCore/TrailerAutocomplete/TrailerAutocompleteInput';

// Styling
import './style.scss';

/**
 * @description Renders EquipmentGroupEquipment within a table
 *
 * @param {EquipmentType} equipmentType - EquipmentType enum
 * @param {String} [equipmentGroupObjectId] - The EquipmentGroup objectId if present
 * @param {Function} onChange - Callback function whenever there is a change to the table information (pending add/remove)
 * 
 * @returns {JSX} Returns a table for the given EquipmentGroupEquipment
 *
 * @example
 * <EquipmentGroupEquipmentTable
 *  equipmentType={EquipmentType.VEHICLE}
 *  equipmentGroupObjectId={equipmentGroupObjectId}
 *  onChange={(equipmentToAdd, equipmentToRemove) => saveInformation(equipmentToAdd, equipmentToRemove)}
 * />
 */
function EquipmentGroupEquipmentTable({ ...props }) {
  const { equipmentGroupObjectId, equipmentType } = props;

  const [equipmentTableData, setEquipmentTableData] = useState([]); // Holds the true state from the db
  const [equipmentTableDataPreview, setEquipmentTableDataPreview] = useState([]); // Holds a temporary view of the pending changes to the group

  const [isLoading, setIsLoading] = useState(false);
  const [equipmentLabel, setEquipmentLabel] = useState('');
  const [showAddEquipmentDropdown, setShowAddEquipmentDropdown] = useState(false);

  // These hold the temporary states of which equipment to add/remove
  const [pendingEquipmentToAdd, setPendingEquipmentToAdd] = useState([]);
  const [pendingEquipmentToRemove, setPendingEquipmentToRemove] = useState([]);

  /** --------------------------- Helper Functions --------------------------- */

  /**
   * @descripiton Parses equipment (Vehicle/Trailer) records into an object for use with the table
   *
   * @param {(Vehicle || Trailer)} equipment - the vehicle/trailer record
   * @param {Boolean} [isPendingAddition] - determines whether this equipment is pending to be added
   *
   * @returns {Object} Returns the parsed equipment object
   */
  function parseEquipment(equipment, isPendingAddition) {
    // Common attributes between vehicle/trailer
    const objectId = getAttribute(equipment, 'objectId');
    const unitId = getAttribute(equipment, 'unitId');
    const mviInspectionDate = getAttribute(equipment, 'nextInspectionDate');
    const type = getAttribute(equipment, 'type');
    const notes = getAttribute(equipment, 'notes');
    const enabled = getAttribute(equipment, 'enabled');

    // Create the return object with the common attributes
    const equipmentProperties = {
      unitId,
      mviInspectionDate,
      type: type || '-',
      notes,
      isPendingAddition: !!isPendingAddition,
      isPendingRemoval: false,
      enabled,
      objectId,
    };

    // Add in custom vehicle/trailer properties
    if (equipmentType === EquipmentTypes.VEHICLE) {
      equipmentProperties.weighStationBypassVehicle = getAttribute(equipment, 'weighStationBypassVehicle');
    } else if (equipmentType === EquipmentTypes.TRAILER) {
      equipmentProperties.trackerId = getAttribute(equipment, 'tc_devices_uniqueid') || '-';
    }

    return equipmentProperties;
  }

  /**
   * @description Given an array of parseEquipment, sort it by ascending unit id
   * @param {Array<Object>} equipmentGroupInformation - Array of parseEquipment objects
   * @returns {Array} Returns the sorted equipmentGroupInformation
   */
  function sortEquipmentByUnitId(equipmentGroupInformation) {
    return equipmentGroupInformation.sort((equipmentA, equipmentB) => {
      const unitIdA = equipmentA.unitId.toLowerCase();
      const unitIdB = equipmentB.unitId.toLowerCase();

      if (unitIdA < unitIdB) return -1;
      if (unitIdA > unitIdB) return 1;
      return 0;
    });
  }

  /**
   * @description Updates the equipment group table whenever a new equipment is added/removed (pending changes)
   */
  function updateEquipmentGroupTablePreview() {
    const parsedEquipment = pendingEquipmentToAdd.map((equipment) => parseEquipment(equipment, true));
    let _equipmentTableDataPreview = sortEquipmentByUnitId([...equipmentTableData, ...parsedEquipment]);

    // Get the object ids to remove
    const pendingEquipmentToRemoveObjectIdArr = pendingEquipmentToRemove.map((equipment) => getAttribute(equipment, 'objectId'));

    // Run through the equipment preview, and update the list of equipment to remove
    _equipmentTableDataPreview = _equipmentTableDataPreview.map((equipment) => {
      if (pendingEquipmentToRemoveObjectIdArr.includes(equipment.objectId)) {
        return {
          ...equipment,
          isPendingRemoval: true,
        };
      }
      return equipment;
    });

    setEquipmentTableDataPreview(_equipmentTableDataPreview);
  }

  // Determines specific classnames to apply to rows on the table
  const determineRowClassNames = (data) => ({
    hidden: !data.enabled, // hides rows of vehicles which are disabled
    'pending-equipment-add': data.isPendingAddition,
    'pending-equipment-remove': data.isPendingRemoval,
  });

  /** --------------------------- UseEffects --------------------------- */

  // Initial fetching of equipment based on equipmentType
  useEffect(() => {
    let didCancel = false;

    async function fetchEquipmentGroupEquipment() {
      setIsLoading(true);

      const equipmentGroupEquipmentFilters = [];

      // Apply filters to the query - this also fetches disabled equipment, but the disabled equipment is hidden from view
      if (equipmentType === EquipmentTypes.VEHICLE) {
        equipmentGroupEquipmentFilters.push(new Filter(QueryRestriction.EXISTS, 'vehicle'));
      } else if (equipmentType === EquipmentTypes.TRAILER) {
        equipmentGroupEquipmentFilters.push(new Filter(QueryRestriction.EXISTS, 'trailer'));
      }

      // Fetch any items related to the equipment group
      const { equipmentGroupEquipments } = await getEquipmentGroupEquipments(
        undefined, // options
        equipmentGroupObjectId, // equipmentGroupObjectId
        equipmentGroupEquipmentFilters, // filters
        undefined, // sortBy
        ['vehicle', 'trailer'], // includedPointers
        undefined, // selectedAttributes
        undefined, // page
        undefined, // limit
        true, // queryAll
      );

      // For each of the equipments in equipmentGroupEquipments, fetch the relevant information required
      let parsedEquipmentInformation = equipmentGroupEquipments.map((equipmentGroupEquipment) => {
        let equipment;

        if (equipmentType === EquipmentTypes.VEHICLE) {
          equipment = getAttribute(equipmentGroupEquipment, 'vehicle');
        } else if (equipmentType === EquipmentTypes.TRAILER) {
          equipment = getAttribute(equipmentGroupEquipment, 'trailer');
        }

        return parseEquipment(equipment);
      });

      parsedEquipmentInformation = sortEquipmentByUnitId(parsedEquipmentInformation);

      if (!didCancel) {
        setEquipmentTableData(parsedEquipmentInformation);
        setEquipmentTableDataPreview(parsedEquipmentInformation);
        setIsLoading(false);
      }
    }

    // When equipmentGroupObjectId is present, we know there is an existing equipment group
    if (equipmentGroupObjectId) fetchEquipmentGroupEquipment();
    return () => { didCancel = true; };
  }, []);

  // Update preview every time a vehicle is added/removed
  // This will also pass information about the changes to the parent component. The parent component will handle all saving to the db
  useEffect(() => {
    updateEquipmentGroupTablePreview();
    if (props.onChange) props.onChange(pendingEquipmentToAdd, pendingEquipmentToRemove);
  }, [pendingEquipmentToAdd, pendingEquipmentToRemove]);

  // Updates the equipment label based on the equipment type - this is so that we display the correct label on the table and associated elements
  useEffect(() => {
    let _equipmentLabel;

    if (equipmentType === EquipmentTypes.VEHICLE) {
      _equipmentLabel = 'vehicle';
    } else if (equipmentType === EquipmentTypes.TRAILER) {
      _equipmentLabel = 'trailer';
    }

    setEquipmentLabel(_equipmentLabel);
  }, [equipmentType]);

  /** --------------------------- DataTable Template --------------------------- */

  function unitIdTemplate(rowData) {
    return (
      <div>
        {rowData.unitId}
      </div>
    );
  }

  function equipmentFeatureTemplate(rowData) {
    if (equipmentType === EquipmentTypes.VEHICLE) {
      return (
        <div className="flex w-full justify-content-center align-content-center">
          <TriStateCheckbox
            value={!!rowData.weighStationBypassVehicle}
            disabled
          />
        </div>
      );
    } else if (equipmentType === EquipmentTypes.TRAILER) {
      return (
        <div>
          {rowData.trackerId}
        </div>
      );
    }

    return <div />;
  }

  // Template for the delete button in driver table
  function deleteButtonTemplate(rowData) {
    if (rowData.isPendingAddition) return <div />;

    return (
      <div className="flex justify-content-center align-content-center">
        {!rowData.isPendingRemoval && (
          <Button
            icon={<span className="material-icons">delete</span>}
            tooltip={`Remove ${rowData.unitId} from group`}
            className="p-button-danger p-button-text p-button-rounded delete-transaction-button"
            onClick={() => {
              setPendingEquipmentToRemove([...pendingEquipmentToRemove, rowData]);
            }}
          />
        )}
        {rowData.isPendingRemoval && (
          <Button
            icon={<span className="material-icons">undo</span>}
            tooltip={`Undo removal of ${rowData.unitId} from group`}
            className="p-button-secondary p-button-text p-button-rounded delete-transaction-button"
            onClick={() => {
              const _pendingEquipmentToRemove = pendingEquipmentToRemove.filter((equipment) => equipment.objectId !== rowData.objectId);
              setPendingEquipmentToRemove(_pendingEquipmentToRemove);
            }}
          />
        )}
      </div>
    );
  }

  function mviInspectionDateTemplate(rowData) {
    return (
      <div>
        {rowData.mviInspectionDate ? moment(rowData.mviInspectionDate).format('DD-MM-YYYY') : '-'}
      </div>
    );
  }

  function notesTemplate(rowData) {
    return (
      <div className="max-h-4rem overflow-auto">
        {rowData.notes}
      </div>
    );
  }

  const equipmentGroupEquipmentTable = (
    <DataTable
      value={equipmentTableDataPreview}
      responsiveLayout="scroll"
      dataKey="id"
      emptyMessage={`No ${equipmentLabel}s were found for this group.`}
      loading={isLoading}
      className="equipment-datatable"
      scrollable
      scrollHeight="20rem"
      rowClassName={determineRowClassNames}
    >
      <Column
        field="unitId"
        header="Unit ID"
        headerClassName="max-w-7rem"
        className="max-w-7rem"
        body={(rowData) => unitIdTemplate(rowData)}
      />
      <Column
        field="mviInspectionDate"
        header="Next Inspection Date"
        headerClassName="max-w-12rem"
        className="max-w-12rem"
        body={(rowData) => mviInspectionDateTemplate(rowData)}
      />
      <Column
        field="type"
        header="Type"
        headerClassName="max-w-7rem"
        className="max-w-7rem"
        body={(rowData) => rowData.type}
      />
      <Column
        field={equipmentType === EquipmentTypes.VEHICLE ? 'weighStationBypassVehicle' : 'trackerId'}
        header={equipmentType === EquipmentTypes.VEHICLE ? 'Weigh Station Bypass' : 'Tracker ID'}
        headerClassName="max-w-12rem"
        className="max-w-12rem"
        body={(rowData) => equipmentFeatureTemplate(rowData)}
      />
      <Column
        field="notes"
        header="Notes"
        body={(rowData) => notesTemplate(rowData)}
      />
      <Column
        headerClassName="p-0 max-w-4rem"
        className="p-0 max-w-4rem"
        body={(rowData) => deleteButtonTemplate(rowData)}
        exportable={false}
      />
    </DataTable>
  );

  return (
    <div className="equipment-group-equipment-table">
      <div className="flex column-gap-3 mt-4 mb-3">
        <div className="flex text-lg font-semibold align-items-center capitalize">{`${equipmentLabel}s`}</div>
        <Button
          icon={<span className="material-icons mr-1">add</span>}
          label={`Add ${equipmentLabel}s to Group`}
          sbVariant="slim"
          className="p-button-info"
          disabled={showAddEquipmentDropdown}
          onClick={() => setShowAddEquipmentDropdown(true)}
        />
        <div className="flex-grow-1">
          <hr />
        </div>
      </div>
      {showAddEquipmentDropdown && (
        <div className="flex w-full mb-3">
          {equipmentType === EquipmentTypes.VEHICLE && (
            <VehicleAutocompleteInput
              multiple
              className="equipment-table-equipment-autocomplete flex-grow-1 mr-1"
              placeholder="Search for Vehicles"
              onSelectVehicles={(vehicles) => setPendingEquipmentToAdd(vehicles)}
              hideSelectedVehicles
              excludedVehicleObjectIds={equipmentTableData.map((equipment) => equipment.objectId)}
              suggestionFilters={[new Filter(QueryRestriction.EQUAL_TO, 'enabled', true)]}
            />
          )}
          {equipmentType === EquipmentTypes.TRAILER && (
            <TrailerAutocompleteInput
              multiple
              className="equipment-table-equipment-autocomplete flex-grow-1 mr-1"
              placeholder="Search for Trailers"
              onSelectTrailers={(trailers) => setPendingEquipmentToAdd(trailers)}
              hideSelectedTrailers
              excludedTrailerObjectIds={equipmentTableData.map((equipment) => equipment.objectId)}
              suggestionFilters={[new Filter(QueryRestriction.EQUAL_TO, 'enabled', true)]}
            />
          )}
          <Button
            className="p-button-icon p-button-text p-button-danger"
            icon={<span className="material-icons">close</span>}
            tooltip="Cancel"
            onClick={() => {
              setPendingEquipmentToAdd([]);
              setShowAddEquipmentDropdown(false);
            }}
          />
        </div>
      )}
      {equipmentGroupEquipmentTable}
    </div>
  );
}

export default EquipmentGroupEquipmentTable;
