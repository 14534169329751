import React from 'react';

// sbCore Components
import Button from 'sbCore/Button/Button';

// Styles
import './style.scss';

/**
 * @description Dispatch Duplicate Job Button
 * @param {Function} showDialog - Calls function which shows duplicate job dialog when button is clicked
 * @param {boolean} [isLoading] - Whether or not to disable the duplicate job button
 * @returns
 *
 * @example
 * <DispatchDuplicateJobButton setShowDuplicateJob={setShowDuplicateJob} />
 */
function DispatchDuplicateJobButton({ ...props }) {
  return (
    <div className="dispatch-duplicate-job-button">
      <Button icon="pi pi-copy" label="Duplicate Job" disabled={props.isLoading} onClick={() => props.showDialog()} />
    </div>
  );
}

export default DispatchDuplicateJobButton;
