import Parse from 'parse';
import store from '../store';
import * as ActionConstants from './ActionConstants';

import * as Setters from 'api/Setters';

export function fetchingCloudUserPreference() {
  return {
    type: ActionConstants.FETCH_CLOUDUSERPREFERENCE_INPROGRESS,
  };
}

export function fetchCloudUserPreferenceSuccess(cloudUserPreference) {
  return {
    type: ActionConstants.FETCH_CLOUDUSERPREFERENCE_SUCCESS,
    cloudUserPreference,
  };
}

export function fetchCloudUserPreferenceError(error) {
  return {
    type: ActionConstants.FETCH_CLOUDUSERPREFERENCE_ERROR,
    error,
  };
}

export function updateCloudUserPreferenceSuccess(updatedCloudUserPreference) {
  return {
    type: ActionConstants.UPDATE_CLOUDUSERPREFERENCE_SUCCESS,
    cloudUserPreference: updatedCloudUserPreference,
  };
}

export async function updateCloudUserPreference(cloudUserPreference, property, value) {
  if (cloudUserPreference) {
    cloudUserPreference.set(property, value);
    const updatedCloudUserPreference = await cloudUserPreference.save();
    return updateCloudUserPreferenceSuccess(updatedCloudUserPreference);
  }
}
