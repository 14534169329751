import React from 'react';
import PropTypes from 'prop-types';

import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
// CSS
import './style.scss';

class LoadingOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }

  render() {
    const { text } = this.props;

    let _text = text || 'Loading';
  
    return (
      <div className="loading-overlay">
        <div className="content-container">
          <div className="text-container d-inline-block mr-2 align-middle">{ _text }</div>

          <div className="lazy-loading-spinner-container d-inline-block align-middle">
          <div className="spinner-border loading-spinner" role="status">
            <span className='sr-only'>{ _text }</span>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

LoadingOverlay.defaultProps = {
  text: 'Loading',
};
LoadingOverlay.propTypes = {
  text: PropTypes.string,
};


export default LoadingOverlay;
