import React from 'react';
import PropTypes from 'prop-types';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import { drivewyzeTOS } from 'TOS/drivewyze';

function ToggleWeighStationBypassDialog(props) {
  const { title, driver, vehicle, handleClose, handleToggle, open, dialogType, userHasUsedWeighStationBypass } = props;


  let referenceType;
  let referenceName;
  let company;
  let companyName;
  let companyDOT;
  let licensePlate;
  let error;
  let secondaryLabel;
  let closeMode;

  if (dialogType === 0) {
    company = vehicle.get('belongsToCompany');
    referenceType = 'Vehicle';
    referenceName = `${vehicle.get('unitId') || 'N/A'}`;
    companyName = company.get('name');
    companyDOT = company.get('dotNumber');
    licensePlate = vehicle.get('licensePlate');
    if (!vehicle.get('vehicleImage')) {
      secondaryLabel = 'Close';
      closeMode = true;
      error = <span>A picture of the vehicle is required for this service (JPEG recommended). Please upload a picture by selecting <b>Upload Image</b> under the Vehicle Image section</span>;
    } else if (!licensePlate || !licensePlate.get('plate') || !licensePlate.get('stateProvince')) {
      secondaryLabel = 'Close';
      closeMode = true;
      error = <span>Vehicle License Plate and Plate Jurisdiction issued is required for this service. Select <b>Edit Vehicle</b> to update</span>;
    }
  }

  // styling
  const uListStyle = { marginTop: '1em', paddingLeft: '1.2em', fontSize: '.85em' };

  return (
    <ConfirmModal
      title={title}
      show={open}
      handleModalChoice={handleToggle}
      handleClose={handleClose}
      secondaryLabel={secondaryLabel}
      closeMode={closeMode}
    >
      { error ?
        <div style={{ marginTop: '-1em' }}>{ error }</div>
        :
        <div>
          <div style={{ marginTop: '-1em' }}>You are changing the Weigh Station/Scale Bypass permissions for { referenceType } <b>{ referenceName }</b></div>
          {/* <div style={{ marginTop: '.5em' }}>This change will adjust your billing by USD$17.99 / mo (CAD$25 / mo)</div> */}
          <div style={{ marginTop: '.5em' }}>
            Only for Weigh Station Bypassing, this {`${referenceType.toLowerCase()}`}, by default, will be set to operate under Company: <b>{`${companyName}`}</b>
          </div>
          <div style={{ marginTop: '.5em' }}>
            Drivers can make changes to their Bypass information to ensure the accuracy of the vehicle and company in which they operate
          </div>

          { !userHasUsedWeighStationBypass &&
            <div style={{ marginTop: '2em', marginBottom: '2em' }}>
              By clicking <b>Confirm</b>, you <b>agree</b> to the following terms and conditions:
              { drivewyzeTOS }
            </div>
          }

          <div style={{ marginTop: '1em' }}>
            For more information on billing or Weigh Station/Scale Bypassing:
            <ul style={uListStyle}>
              <li>Email us at <a href="mailto:sales@onswitchboard.com" target="_blank">sales@onswitchboard.com</a></li>
              <li>Live Chat (chat icon on the bottom right of the screen)</li>
              <li>Call us at 1-844-5-FLEETS (1-844-535-3387)</li>
              <li>Visit our <a href="http://support.onswitchboard.com" target="_blank">knowledgebase</a></li>
            </ul>
          </div>
        </div>
      }
    </ConfirmModal>
  );
}

ToggleWeighStationBypassDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func,
  handleClose: PropTypes.func,
  driver: PropTypes.object,
  vehicle: PropTypes.object,
  dialogType: PropTypes.number, // 0 = vehicle, 1 = driver
  userHasUsedWeighStationBypass: PropTypes.bool,
};

export default ToggleWeighStationBypassDialog;
