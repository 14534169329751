import React from 'react';
import PropTypes from 'prop-types';
import history from "../../sbHistory";

import WalkthroughSelectTip from 'components/WalkthroughSelectTip/container/WalkthroughSelectTip';

// CSS
import styles from './Sidebar.module.scss';

// API
import { getAttribute, getCurrentUser } from 'sb-csapi/dist/AAPI';
import * as Helpers from 'api/Helpers';

// MDB
import { MDBBtn, MDBIcon } from 'mdbreact';

// Assets
// const summaryIcon = require('assets/images/safety/summary.png');
// const issuesIcon = require('assets/images/safety/issues.png');
// const inspectionsIcon = require('assets/images/safety/vehicle-inspections.png');

function Sidebar(props) {
  const { walkthroughState, toggleWalkthroughState, Company } = props;

  const currentUser = getCurrentUser();
  const userSpecialPermission = getAttribute(currentUser, 'userSpecialPermission', true);
  const isDisableDriversModule = getAttribute(userSpecialPermission, 'disableDriversModule', true);

  return (
    <div className={styles.sidebarContainer + (props.isOpen ? '' : ' ' + styles.hide)}>
      <div className={styles.sidebar + (props.isOpen ? '' : ' ' + styles.closed) + " translate-me"}>
        <div className={styles.sidebarTitle}>
          {props.Company.fetched ?
            <div className={styles.company}>{props.Company.company.get('name')}</div>
            :
            <div className={styles.company}>Loading...</div>
          }
          <div className={styles.subHeader}>Safety</div>
        </div>

        <div className={styles.subViews}>
          {/*
            <div className={summaryStyle} onClick={() => props.handleSubViewClick('summary')}>
              <div className={styles.icon}><Glyphicon glyph="stats" /></div>
              <div className={styles.label}>Summary &nbsp;<span className={`${styles.numberBadge} badge`}>1</span></div>
            </div>
          */}

          {
            // <div className={issuesStyle} onClick={() => props.handleSubViewClick('issues')}>
            //   <div className={styles.label}>Issues</div>
            // </div>
          }

          {
            // <div className={props.activeSubview === 'driverELDIssues' ? `${styles.active} ${styles.option}` : styles.option} onClick={() => props.handleSubViewClick('driverELDIssues')}>
            //   <div className={styles.label}>Summary</div>
            // </div>
          }

          <a href={`/safety?view=driverHoursdriverHours`} onClick={(e) => e.preventDefault()}>
            <div className={props.activeSubview === 'driverHours' ? `${styles.active} ${styles.option}` : styles.option} onClick={() => { props.handleSubViewClick('driverHours') }}>
              <div className={styles.label}>Driver Hours of Service</div>
            </div>
          </a>

          <a href={`/safety?view=tripInspections`} onClick={(e) => e.preventDefault()}>
            <div className={props.activeSubview === 'tripInspections' ? `${styles.active} ${styles.option}` : styles.option} onClick={() => { props.handleSubViewClick('tripInspections') }}>
              <div className={styles.label}>Trip Inspections</div>
            </div>
          </a>

          <a href={`/safety?view=hosEdits`} onClick={(e) => e.preventDefault()}>
            <div className={props.activeSubview === 'hosEdits' ? `${styles.active} ${styles.option}` : styles.option} onClick={() => { props.handleSubViewClick('hosEdits') }}>
              <div className={styles.label}>HOS Edit History</div>
            </div>
          </a>

          {/* <div className={props.activeSubview === 'hosViolations' ? `${styles.active} ${styles.option}` : styles.option} onClick={() => props.handleSubViewClick('hosViolations')}>
            <div className={styles.label}>Hours of Service Violations</div>
            { Company && Company.company && !Company.company.get('disableWalkthrough') &&
              <WalkthroughSelectTip
                handleToggle={() => toggleWalkthroughState('hosViolations')}
                expand={walkthroughState.hosViolations}
                showBoxBottom
                toolTipStyle={{ lineHeight: 'normal' }}
                style={{ marginTop: '.8em', left: '8em' }}
              >
                <div onClick={(e) => e.stopPropagation()}>
                  Click here to view your driver's violations and go to their respective logs for more detailed information
                  <br /><br />
                  To disable these tips, go to <b>Walkthrough Tips</b> under your <a onClick={() => history.push('settings')} style={{ cursor: 'pointer' }}>settings</a>
                </div>
              </WalkthroughSelectTip>
            }
          </div> */}

          <a href={`/safety?view=hosViolationDetails`} onClick={(e) => e.preventDefault()}>
            <div className={props.activeSubview === 'hosViolationDetails' ? `${styles.active} ${styles.option}` : styles.option} onClick={() => props.handleSubViewClick('hosViolationDetails')}>
              <div className={styles.label}>HOS Violation Details</div>
            </div>
          </a>

          <a href={`/safety?view=unidentifiedDriving`} onClick={(e) => e.preventDefault()}>
            <div className={props.activeSubview === 'unidentifiedDriving' ? `${styles.active} ${styles.option}` : styles.option} onClick={() => props.handleSubViewClick('unidentifiedDriving')}>
              <div className={styles.label}>Unidentified Driving</div>
            </div>
          </a>

          <a href={`/safety?view=odometerJumpReport`} onClick={(e) => e.preventDefault()}>
            <div className={props.activeSubview === 'odometerJumpReport' ? `${styles.active} ${styles.option}` : styles.option} onClick={() => props.handleSubViewClick('odometerJumpReport')}>
              <div className={styles.label}>Odometer Jump Report</div>
            </div>
          </a>

          {Helpers.isSubscribedToModule('driverBehaviourModule') &&
            <a href={`/safety?view=speedReport`} onClick={(e) => e.preventDefault()}>
              <div className={props.activeSubview === 'speedReport' ? `${styles.active} ${styles.option}` : styles.option} onClick={() => props.handleSubViewClick('speedReport')}>
                <div className={styles.label}>Speed Report</div>
              </div>
            </a>
          }

          {Helpers.isSubscribedToModule('safetyRemindersModule') &&
            <a href={`/safety?view=reminders`} onClick={(e) => e.preventDefault()}>
              <div className={props.activeSubview === 'reminders' ? `${styles.active} ${styles.option}` : styles.option} onClick={() => props.handleSubViewClick('reminders')}>
                <div className={styles.label}>Expiries & Reminders</div>
              </div>
            </a>
          }

          {!isDisableDriversModule &&
            <a href={`/safety?view=uncertifiedlogs`} onClick={(e) => e.preventDefault()}>
              <div className={props.activeSubview === 'uncertifiedlogs' ? `${styles.active} ${styles.option}` : styles.option} onClick={() => props.handleSubViewClick('uncertifiedlogs')}>
                <div className={styles.label}>Uncertified Logs</div>
              </div>
            </a>
          }

          {!isDisableDriversModule &&
            <a href={`/safety?view=logstodownload`} onClick={(e) => e.preventDefault()}>
              <div className={props.activeSubview === 'logstodownload' ? `${styles.active} ${styles.option}` : styles.option} onClick={() => props.handleSubViewClick('logstodownload')}>
                <div className={styles.label}>Download / Send Logs</div>
              </div>
            </a>
          }

          {window.location.host.indexOf('app-support') !== -1 &&
            <a href={`/safety?view=troubleshooting`} onClick={(e) => e.preventDefault()}>
              <div className={props.activeSubview === 'troubleshooting' ? `${styles.active} ${styles.option}` : styles.option} onClick={() => props.handleSubViewClick('troubleshooting')}>
                <div className={styles.label}>Troubleshooting</div>
              </div>
            </a>
          }

          {/*
            <div className={inspectionsStyle} onClick={() => props.handleSubViewClick('inspections')}>
              <div className={styles.icon}><img src={inspectionsIcon} /></div>
              <div className={styles.label}>Vehicle Inspections</div>
            </div>
          */}
        </div>
      </div>
      <MDBBtn size="sm" color="primary" className={styles.drawerButton} onClick={props.toggleSidebar}>
        {!props.isOpen && <MDBIcon icon="list-alt" className="mr-2"></MDBIcon>}
        {!props.isOpen && <MDBIcon icon="angle-right"></MDBIcon>}
        {props.isOpen && <MDBIcon icon="angle-left"></MDBIcon>}
      </MDBBtn>
    </div>
  );
}

Sidebar.propTypes = {
  Company: PropTypes.object,
  activeSubview: PropTypes.string.isRequired,
  handleSubViewClick: PropTypes.func,
  walkthroughState: PropTypes.object,
  toggleWalkthroughState: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired
};

export default Sidebar;
