export const ELDEventRecordOrigin = Object.freeze({
  AUTO_RECORDED: 1,
  EDITED_OR_ENTERED_BY_DRIVER: 2,
  EDIT_REQUEST_BY_AUTH_USER: 3,
  ASSUMED_FROM_UD: 4,
});

export const ELDEventRecordOriginDescription = Object.freeze({
  AUTO_RECORDED: 'Automatically recorded by ELD',
  EDITED_OR_ENTERED_BY_DRIVER: 'Edited or entered by the Driver',
  EDIT_REQUEST_BY_AUTH_USER: 'Edit requested by an Authenticated User other than the Driver',
  ASSUMED_FROM_UD: 'Assumed from Unidentified Driver Profile',
});
