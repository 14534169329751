import Parse from 'parse';
import moment from 'moment-timezone';

import { setQueryRestriction } from 'api/Parse';
import { getAllFromQuery } from 'api/Getters';

import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { AttributeTypes } from 'enums/HOSViolation';

import ViolationHOS from 'sbObjects/ViolationHOS';
import Driver from 'sbObjects/Driver';
import ELDDailyCertification from 'sbObjects/ELDDailyCertification';


/**
 * @memberof module:DriverPattern
 * @description get the hos violation data for each driver provided the intervalStart and intervalEnd
 *
 * @param {array} driverObjectIds
 * @param {Date} intervalStart
 * @param {Date} intervalEnd
 */
async function getHOSViolationDataByDriver(driverObjectIds = [], intervalStart, intervalEnd) {
  const hosViolationDataByDriver = {};
  if (driverObjectIds.length === 0) return hosViolationDataByDriver;
  const todayEnd = moment().endOf('day').toDate();
  const sevenDaysAgoStart = moment(todayEnd).subtract(14, 'days').startOf('day').toDate();

  const hosViolationPromises = driverObjectIds.map(driverObjectId => {
    const hosViolationQuery = new Parse.Query('HOSViolation');
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.EXISTS, AttributeTypes.BELONGS_TO_COMPANY);
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.EQUAL_TO, AttributeTypes.DISABLED, false);
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.EQUAL_TO, AttributeTypes.POTENTIAL, false);
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.EQUAL_TO, AttributeTypes.ISHIDDEN, false);
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.EQUAL_TO, AttributeTypes.DRIVER, driverObjectId);
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, AttributeTypes.TRIGGER_TIME, intervalStart || sevenDaysAgoStart);
    setQueryRestriction(hosViolationQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, AttributeTypes.TRIGGER_TIME, intervalEnd || todayEnd);
    hosViolationQuery[QuerySortOrderTypes.DESCENDING](AttributeTypes.TRIGGER_TIME); // get the most recent
    hosViolationQuery.include(['driver', 'driver.user', 'eldDailyCertification']);
    return getAllFromQuery(hosViolationQuery);
  });

  try {
    const hosViolations = await Promise.all(hosViolationPromises);
    driverObjectIds.map((driverObjectId, index) => {
      let correspondingHOSViolations = hosViolations[index];
      correspondingHOSViolations = correspondingHOSViolations.filter(hosViolation => hosViolation.get('eldDailyCertification'));
      correspondingHOSViolations = correspondingHOSViolations.map(hosViolation => {
        let driver = hosViolation.get('driver');
        const user = driver.get('user');

        driver = new Driver(
          driver.id,
          user.get('firstName'),
          user.get('lastName'),
          user.get('phoneNumber'),
          driver.get('driversLicense'),
          driver.get('driversLicenseCountry'),
          driver.get('licenseExpiryDate'),
          driver.get('timezoneOffsetFromUTC'),
          driver.get('eldStatusInt'),
        );
        let eldDailyCertification = hosViolation.get('eldDailyCertification');

        eldDailyCertification = new ELDDailyCertification(
          eldDailyCertification.id,
          eldDailyCertification.get('startTimeUTC'),
          eldDailyCertification.get('endTimeUTC'),
          driver,
        );

        return new ViolationHOS(
          hosViolation.id,
          hosViolation.get('regulationInt'),
          hosViolation.get('triggerTime'),
          eldDailyCertification,
        );
      });
      hosViolationDataByDriver[driverObjectId] = correspondingHOSViolations;
    });

    return hosViolationDataByDriver;
  } catch (err) {
    throw new Error(err);
  }

}

export {
  getHOSViolationDataByDriver,
};
