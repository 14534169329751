import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// sbCore
import Calendar from 'sbCore/Calendar/Calendar';
import TabMenu from 'sbCore/TabMenu/TabMenu';

// Styles
import './style.scss';

function LogDownloadDates({ dates, onSelectDates, onSelectDateFormat, dateFormat, last7DaysTemplate, last14DaysTemplate }) {
  const today = moment().startOf('day').toDate();
  // ** UseStates ** //
  const [dateTabActiveIndex, setDateTabActiveIndex] = useState(0);
  const [maxDateRange, setMaxDateRange] = useState(today);

  useEffect(() => {
    if (dateFormat) setDateTabActiveIndex(dateFormat);
  }, [dateFormat]);

  useEffect(() => {
    let didCancel = false;
    if (!dates.dateRange || dates.dateRange.length === 0) return;
    const newMaxDate = moment(dates.dateRange[0]).add(3, 'months').toDate();
    if (moment(newMaxDate).isSameOrAfter(today)) return;
    if (!didCancel) setMaxDateRange(newMaxDate);
    return () => { didCancel = true }
  }, [dates]);

  // ** Lists ** //
  const dateTypesList = [
    { label: 'Date Range' },
    { label: 'By Month' },
    { label: 'Last 7 Days' },
    { label: 'Last 14 Days' },
    { label: 'Specific Dates' },
  ];

  // ** Functions ** //
  function editDates(attribute, value) {
    const _dates = { ...dates, [attribute]: value };
    onSelectDates(_dates);
  }

  function lastXDaysTemplate(numberOfDays) {
    const currentDate = moment().startOf('day');
    const endDate = moment(currentDate);
    const startDate = moment(endDate).subtract(numberOfDays, 'days');
    if (numberOfDays === 7) editDates('last7Days', [startDate.toDate(), endDate.toDate()]);
    if (numberOfDays === 14) editDates('last14Days', [startDate.toDate(), endDate.toDate()]);
  }

  function editDateTabActiveIndex(tabIndex) {
    setDateTabActiveIndex(tabIndex);
    onSelectDateFormat(tabIndex);
    if (tabIndex === 2) lastXDaysTemplate(7);
    if (tabIndex === 3) lastXDaysTemplate(14);
  }

  // ** Templates ** //
  const dateRangeTemplate = (
    <Calendar value={dates.dateRange} onChange={(e) => editDates('dateRange', e.value)} selectionMode="range" placeholder="Start Date - End Date" className="w-25 mt-2" maxDate={maxDateRange} showIcon dateFormat="yy/mm/dd" />
  );

  const byMonthTemplate = (
    <Calendar value={dates.byMonth} onChange={(e) => editDates('byMonth', e.value)} view="month" dateFormat="yy/mm" placeholder="Select Month" className="w-25 mt-2" maxDate={today} showIcon />
  );

  const specificDatesTemplate = (
    <Calendar value={dates.selectedDates} onChange={(e) => editDates('selectedDates', e.value)} selectionMode="multiple" placeholder="Select Dates" className="w-50 mt-2" maxDate={today} showIcon dateFormat="yy/mm/dd" />
  );

  const dateFieldTemplate = (
    <div className="modal-template-content">
      Select the dates of the logs to be generated.
      <TabMenu
        model={dateTypesList}
        activeIndex={dateTabActiveIndex}
        onTabChange={(e) => editDateTabActiveIndex(e.index)}
        className="mt-2 mb-1"
      />
      { dateTabActiveIndex === 0 && dateRangeTemplate }
      { dateTabActiveIndex === 1 && byMonthTemplate }
      { dateTabActiveIndex === 2 && last7DaysTemplate }
      { dateTabActiveIndex === 3 && last14DaysTemplate }
      { dateTabActiveIndex === 4 && specificDatesTemplate }
    </div>
  );

  return (
    <div>
      {dateFieldTemplate}
    </div>
  );
}

export default LogDownloadDates;
