import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Table } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';
import TripInspectionType from 'api/Lists/TripInspectionType';
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getTimezoneAbbreviation } from 'api/utils/timezone';

// Components
import Message from 'sbCore/Message/Message';
import SimpleTableBody from 'components/SimpleTableBody/container/SimpleTableBody';
import SortButton from 'components/SortButton/view/SortButton';

// CSS
import styles from './TripInspectionsTable.module.scss';

class TripInspectionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tripInspectionRows: [] };
  }

  componentWillReceiveProps(nextProps) {
    const { timezoneOffsetFromUTC } = nextProps;
    const newState = { ...this.state };

    let keyCounter = 0;
    newState.tripInspectionRows = nextProps.tripInspections.map(tripInspection => {
      const dateTime = <span>{`${moment(tripInspection.get('dateTime')).tz(timezoneOffsetFromUTC || moment.tz.guess()).format('MMM D, YYYY')} (${getTimezoneAbbreviation(getAttribute(tripInspection, 'dateTime', true), timezoneOffsetFromUTC)})`}</span>;
      const tripInspectionRow = (
        <tr key={`${tripInspection.id}${keyCounter}`} className={nextProps.selectedTripInspection === tripInspection ? `${styles.tripInspectionRow} ${styles.activeRow}` : styles.tripInspectionRow} onClick={() => this.props.handleSelectTripInspection(tripInspection.id)}>
          <td>
            <div>{t(TripInspectionType[tripInspection.get('type')])}</div>
          </td>
          <td>
            <b className={tripInspection.get('hasUnresolvedDefects') ? styles.alert : ''}>{dateTime}</b>
            <br />
            <div className={styles.subtext}>{tripInspection.get('locationDescriptionCA')}</div>
          </td>
          {this.props.showDriver && tripInspection.get('driver') &&
            (
              <td>
                <div>{Helpers.toTitleCase(tripInspection.get('driver').get('user_fullName'))}</div>
                <div>
                  {tripInspection.get('vehicleUnitId') &&
                    <span className={styles.subtext}>Vehicle: <b>{tripInspection.get('vehicleUnitId')}</b></span>
                  }
                  {tripInspection.get('trailer1UnitId') &&
                    <span className={styles.subtext}> | Trailer: <b>{tripInspection.get('trailer1UnitId')}</b></span>
                  }
                  {tripInspection.get('trailer2UnitId') &&
                    <span className={styles.subtext}> | Trailer 2: <b>{tripInspection.get('trailer2UnitId')}</b></span>
                  }
                </div>
              </td>
            )
          }
          <td>
            {tripInspection.get('hasUnresolvedDefects') &&
              (
                <Message
                  severity="warn"
                  text={(
                    <p className="mb-0 ml-1">
                      Inspection shows Defects
                    </p>
                  )}
                />
              )
            }
            {!tripInspection.get('hasUnresolvedDefects') &&
              (
                <Message
                  severity="success"
                  text={(
                    <p className="mb-0 ml-1">
                      No Inspection Defects
                    </p>
                  )}
                />
              )
            }
          </td>
        </tr>
      );

      keyCounter++;
      return tripInspectionRow; // defaukt
    });
    this.setState(newState);
  }

  render() {
    const { timezoneOffsetFromUTC } = this.props;

    return (
      <div className="dataTable" onScroll={(e) => this.props.scrollHandler(e)}>
        <Table responsive hover>
          <thead>
            <tr>
              <th>
                {t('Type')}
                {
                  // <SortButton
                  //   clickHandler={() => this.props.sortHandler('unitId')}
                  //   isActive={this.props.sort.unitId}
                  // />
                }
              </th>
              <th>
                {t('Date')}
                {
                  // <SortButton
                  //   clickHandler={() => this.props.sortHandler('unitId')}
                  //   isActive={this.props.sort.unitId}
                  // />
                }
              </th>
              {this.props.showDriver &&
                <th>
                  {t('Driver')}
                  {
                    // <SortButton
                    //   clickHandler={() => this.props.sortHandler('unitId')}
                    //   isActive={this.props.sort.unitId}
                    // />
                  }
                </th>
              }
              <th className="centerText" />
            </tr>
          </thead>
          <SimpleTableBody rows={this.state.tripInspectionRows} loaded={this.props.loaded} columns="4" />
        </Table>
      </div>
    );
  }
}

TripInspectionsTable.propTypes = {
  showDriver: PropTypes.bool,
  tripInspections: PropTypes.array.isRequired,
  loaded: PropTypes.bool.isRequired,
  scrollHandler: PropTypes.func,
  handleSelectTripInspection: PropTypes.func.isRequired,
  selectedTripInspection: PropTypes.object,
  // sortHandler: PropTypes.func,
  sort: PropTypes.object,
  timezoneOffsetFromUTC: PropTypes.string,
};

export default TripInspectionsTable;
