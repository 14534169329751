import * as ActionConstants from 'actions/ActionConstants';
import * as Helpers from 'api/Helpers';

const initialState = {
  geofences: [],
};

function addGeofence(geofences, newGeofence) {
  const newGeofences = [].concat(geofences);
  newGeofences.unshift(newGeofence);
  return newGeofences;
}

function deleteGeofences(stateGeofences, geofencesToBeDeleted) {
  const newStateGeofences = stateGeofences;
  const geofencesToBeDeletedLen = geofencesToBeDeleted.length;
  for (let i = 0; i < geofencesToBeDeletedLen; i++) {
    const index = Helpers.findIndexOfObjArr(newStateGeofences, 'id', geofencesToBeDeleted[i].id);
    newStateGeofences.splice(index, 1);
  }
  return newStateGeofences;
}

export function Geofence(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_GEOFENCES_INPROGRESS:
      return {
        ...state,
        status: action.type,
        fetched: false,
      };
    case ActionConstants.FETCH_GEOFENCES_SUCCESS:
      return {
        ...state,
        status: action.type,
        geofences: action.geofences,
        error: undefined,
        fetched: true,
      };
    case ActionConstants.FETCH_GEOFENCES_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
        fetched: true,
      };
    case ActionConstants.ADD_GEOFENCE_INPROGRESS:
      return {
        ...state,
        status: action.type,
        geofences: state.geofences,
        error: undefined,
      };
    case ActionConstants.ADD_GEOFENCE_SUCCESS:
      return {
        ...state,
        status: action.type,
        geofences: addGeofence(state.geofences, action.geofence),
        error: undefined,
      };
    case ActionConstants.ADD_GEOFENCE_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    case ActionConstants.DELETE_GEOFENCES: {
      return {
        ...initialState,
        status: action.type,
      };
    }
    case ActionConstants.DESTROY_GEOFENCES: {
      return {
        ...state,
        status: action.type,
        geofences: deleteGeofences(state.geofences, action.geofences),
      };
    }
    case ActionConstants.UPDATE_GEOFENCE_INPROGRESS:
      return {
        ...state,
        status: action.type,
        updateReference: action.updateReference,
        error: undefined,
      };
    case ActionConstants.UPDATE_GEOFENCE_SUCCESS:
      return {
        ...state,
        status: action.type,
        error: undefined,
      };
    case ActionConstants.UPDATE_GEOFENCE_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    default:
      return state;
  }
}
