import moment from 'moment-timezone';

import * as ActionConstants from './ActionConstants';
import store from '../store';

import * as Setters from '../api/Setters';
import * as Getters from '../api/Getters';
import * as Helpers from '../api/Helpers';

import { updateDriverForState } from 'actions/Driver';
import { updateTrailerForState } from 'actions/Trailer';

export function fetchingVehicles() {
  return {
    type: ActionConstants.FETCH_VEHICLES_INPROGRESS,
  };
}

export function fetchVehiclesSuccess(vehicles) {
  return {
    type: ActionConstants.FETCH_VEHICLES_SUCCESS,
    vehicles,
  };
}

export function fetchVehiclesError(error) {
  return {
    type: ActionConstants.FETCH_VEHICLES_ERROR,
    error,
  };
}

export function deleteVehicles() {
  return {
    type: ActionConstants.DELETE_VEHICLES,
  };
}

export function addingVehicle() {
  return {
    type: ActionConstants.ADD_VEHICLE_INPROGRESS,
  };
}

export function addVehicleSuccess(vehicle) {
  return {
    type: ActionConstants.ADD_VEHICLE_SUCCESS,
    vehicle,
  };
}

export function addVehicleError(error) {
  return {
    type: ActionConstants.ADD_VEHICLE_ERROR,
    error,
  };
}

export function updatingVehicle(vehicles) {
  return {
    type: ActionConstants.UPDATE_VEHICLE_INPROGRESS,
    vehicles,
  };
}

export function updateVehicleSuccess(vehicles, updatedVehicle) {
  return {
    type: ActionConstants.UPDATE_VEHICLE_SUCCESS,
    vehicles,
    updatedVehicle,
  };
}

export function updateVehicleError(vehicle, error) {
  return {
    type: ActionConstants.UPDATE_VEHICLE_ERROR,
    vehicle,
    error,
  };
}

export function enableVehiclesSuccess(vehicles) {
  return {
    type: ActionConstants.ENABLE_VEHICLES_SUCCESS,
    vehicles,
  };
}

export function disableVehiclesSuccess(vehicles) {
  return {
    type: ActionConstants.DISABLE_VEHICLES_SUCCESS,
    vehicles,
  };
}

export function disableVehicle(vehicleParseObj) {
  const promise = new Promise((resolve, reject) => {
    vehicleParseObj.set('enabled', false).save().then(
      vehicleObject => {
        resolve();
        store.dispatch(disableVehiclesSuccess([vehicleObject]));
      },
    );
  });
  return promise;
}

export function getVehicleIncludeArr() {
  return ['vendor', 'drivers', 'vehicleLocation', 'eldHardware', 'licensePlate', 'weighStationBypassVehicle', 'belongsToCompany'];
}

export function getVehiclesForState(page, limit, filter, sortBy, ignoreEnabledBool) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingVehicles());
    let finalFilter = [].concat(filter, { name: 'unitId', queryType: 'exists' });
    finalFilter.push({ name: 'isHidden', queryType: 'notEqualTo', value: true });
    if (!ignoreEnabledBool) {
      finalFilter = finalFilter.concat({ name: 'enabled', queryType: 'equalTo', value: true });
    }
    Getters.queryCompanyObjects('Vehicle', page, limit, finalFilter, sortBy, getVehicleIncludeArr()).then(
      vehicles => {
        store.dispatch(fetchVehiclesSuccess(vehicles));
        resolve(vehicles);
      },
      error => {
        store.dispatch(fetchVehiclesError(error));
        reject(error);
      }
    );
  });
  return promise;
}

export async function getDiagnosedVehiclesForState(page, limit, filter, sortBy) {
  store.dispatch(fetchingVehicles());
  try {
    const filteredEdcs = await Getters.getEDCs(page, limit, filter, sortBy);
    let vehicles = [];
    let vehicleUnitArrSet = [];

    for (let i = 0; i < filteredEdcs.length; i++) {
      const edc = filteredEdcs[i];
      if (vehicleUnitArrSet.indexOf(edc.get('vehicle').get('unitId')) === -1) {
        vehicles.push(edc.get('vehicle'));
        vehicleUnitArrSet.push(edc.get('vehicle').get('unitId'));
      }
    }
    store.dispatch(fetchVehiclesSuccess(vehicles));
    return (filteredEdcs);
  } catch (error) {
    store.dispatch(fetchVehiclesError(error));
    return Promise.reject(error);
  }
}

export function getLinkedVehiclesForState(page, limit, filter, sortBy, ignoreEnabledBool, company) {
  // For CompanyLinks
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingVehicles());
    if (!company || !company.id) {
      store.dispatch(fetchVehiclesSuccess());
      return;
    }
    const companyLinkFilter = [
      {
        queryType: 'equalTo',
        name: 'parentCompany',
        value: company,
      }, {
        queryType: 'equalTo',
        name: 'authorized',
        value: true,
      },
    ];
    Getters.queryCompanyObjects('CompanyLink', undefined, undefined, companyLinkFilter, undefined, undefined, false, true, null, true).then((companyLinkArr) => {
      let finalFilter = [].concat(filter, { name: 'unitId', queryType: 'exists' });
      finalFilter.push({ name: 'isHidden', queryType: 'notEqualTo', value: true });
      if (!ignoreEnabledBool) finalFilter = finalFilter.concat({ name: 'enabled', queryType: 'equalTo', value: true });
      // Filter for company and child companies
      const vehicleQueryPromises = [];
      for (let i = 0; i < companyLinkArr.length; i++) {
        vehicleQueryPromises.push(Getters.queryCompanyObjects('Vehicle', undefined, undefined, finalFilter.concat({ queryType: 'equalTo', name: 'belongsToCompany', value: companyLinkArr[i].get('childCompany') }), sortBy, getVehicleIncludeArr(), false, true, false, true));
      }
      // Uses multiple filters because otherwise it overloads the server..?
      vehicleQueryPromises.push(Getters.queryCompanyObjects('Vehicle', undefined, undefined, finalFilter.concat({ queryType: 'equalTo', name: 'belongsToCompany', value: company }), sortBy, getVehicleIncludeArr(), false, true, null, true));
      Promise.all(vehicleQueryPromises).then((vehicleResults) => {
        // Get rid of duplicates
        const sortedVehicles = [].concat(...vehicleResults).sort((a, b) => {
          if (sortBy) {
            const properties = Object.keys(sortBy);
            if (properties.length > 0) {
              if (sortBy[properties[0]] === 'ascending') {
                return (b.get(sortBy) - a.get(sortBy));
              } else {
                return (b.get(sortBy) + a.get(sortBy));
              }
            }
          } else {
            return 0;
          }
        });
        const filteredVehicles = [];
        const filteredVehicleIds = [];

        for (let i = 0; i < sortedVehicles.length; i++) {
          const duplicateIndex = filteredVehicleIds.indexOf(sortedVehicles[i].get('unitId'));
          if (duplicateIndex === -1) {
            filteredVehicles.push(sortedVehicles[i]);
            filteredVehicleIds.push(sortedVehicles[i].get('unitId'));
          } else {
            const currentVehicle = sortedVehicles[i];
            const duplicateVehicle = filteredVehicles[duplicateIndex];
            // Look for most updated vehicle (if duplicate unitId)
            if (currentVehicle.get('vehicleLocation') && duplicateVehicle.get('vehicleLocation') && moment(currentVehicle.get('vehicleLocation').get('dateTime')).isAfter(moment(duplicateVehicle.get('vehicleLocation').get('dateTime')))) {
              filteredVehicles[duplicateIndex] = currentVehicle;
            } else if (currentVehicle.get('vehicleLocation') && !duplicateVehicle.get('vehicleLocation')) {
              filteredVehicles[duplicateIndex] = currentVehicle;
            } else if (moment(currentVehicle.get('updatedAt')).isAfter(moment(duplicateVehicle.get('updatedAt')))) {
              filteredVehicles[duplicateIndex] = currentVehicle;
            }
          }
        }
        store.dispatch(fetchVehiclesSuccess(filteredVehicles));
        resolve(filteredVehicles);
        // store.dispatch(fetchVehiclesSuccess([].concat(...vehicleResults)));
        // resolve([].concat(...vehicleResults));
      }, error => {
        store.dispatch(fetchVehiclesError(error));
        reject(error);
      });
    }, error => {
      store.dispatch(fetchVehiclesError(error));
      reject(error);
    });
  });
  return promise;
}

export function updateVehiclesVehicleLocationsForState(vehicleArr, sort) {
  const promise = new Promise((resolve, reject) => {
    // don't need fetching vehicles to hide loading
    // store.dispatch(fetchingVehicles());
    const vehicles = vehicleArr || store.getState().Vehicle.vehicles;
    Getters.queryCompanyObjects('Vehicle', undefined, undefined, [{ queryType: 'containedIn', name: 'objectId', value: vehicles.map((vehicle) => vehicle.id) }], sort, getVehicleIncludeArr(), false, true).then(
      (updatedVehicles) => {
        store.dispatch(fetchVehiclesSuccess(updatedVehicles));
        resolve(updatedVehicles);
      },
      error => reject(error)
    );
  });
  return promise;
}

export function deleteVehiclesForState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteVehicles());
    resolve(store.getState().Vehicle);
  });
  return promise;
}

export function enableVehicle(vehicleParseObj) {
  const promise = new Promise((resolve, reject) => {
    vehicleParseObj.set('enabled', true).save().then(
      vehicleObj => {
        resolve(vehicleObj);
        store.dispatch(enableVehiclesSuccess([vehicleObj]));
      },
    );
  });
  return promise;
}

export function deleteVehicle(vehicleParseObj) {
  const promise = new Promise((resolve, reject) => {
    vehicleParseObj.set('isHidden', true).save().then(
      vehicleObject => {
        resolve();
        store.dispatch(disableVehiclesSuccess([vehicleObject]));
      },
    );
  });
  return promise;
}


export function addVehicleToState(vehicleParseObj) {
  return store.dispatch(addVehicleSuccess(vehicleParseObj));
}

export function updateVehicleForState(vehicleParseObj, property, value) {
  store.dispatch(updatingVehicle([vehicleParseObj]));
  const promise = new Promise((resolve, reject) => {
    // user-based attributes
    const fallbackValue = vehicleParseObj.get(property);
    vehicleParseObj.set(property, value).save().then(
      vehicleObject => {
        // For handling side effects in beforeSave
        store.dispatch(updateVehicleSuccess(vehicleObject));
        resolve(vehicleObject);
      },
      error => {
        console.log(error);
        vehicleParseObj.set(property, fallbackValue);
        reject(error);
        store.dispatch(updateVehicleError(error));
      }
    );
  });
  return promise;
}

export function vehicleUpdateHandler(vehicleJSON) {
  const vehicleParseObjArr = store.getState().Vehicle.vehicles;
  const vehicleIndex = Helpers.findIndexOfObjArr(vehicleParseObjArr, 'id', vehicleJSON.objectId);
  if (vehicleIndex !== -1) {
    Getters.getObjectById('Vehicle', vehicleJSON.objectId, getVehicleIncludeArr()).then((vehicleParseObj) => {
      const newVehicleParseObjArr = vehicleParseObjArr.slice();
      newVehicleParseObjArr[vehicleIndex] = vehicleParseObj;
      store.dispatch(updateVehicleSuccess(newVehicleParseObjArr, vehicleParseObj));
    });
  }
}

export function assignDriversToVehicleForState(vehicleParseObj, driversParseArr) {
  store.dispatch(updatingVehicle([vehicleParseObj]));
  const promise = new Promise((resolve, reject) => {
    Setters.assignDriversToVehicle(vehicleParseObj, driversParseArr).then(
      vehicleObject => {
        // For handling side effects in beforeSave
        Getters.fetchParseObjectArr(store.getState().Vehicle.vehicles).then((vehiclesParseObjArr) => {
          store.dispatch(updateVehicleSuccess(vehiclesParseObjArr));
          resolve(vehicleObject);
        });
      },
      error => {
        console.log(error);
        reject(error);
        store.dispatch(updateVehicleError(error));
      }
    );
  });
  return promise;
}
