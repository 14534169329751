import React, { useState, useEffect } from 'react';

// Shared Components
import DocumentInputTextarea from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputTextarea/InputTextarea';
import DocumentInputNumber from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputNumber/InputNumber';

// sbCore
import Button from 'sbCore/Button/Button';
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';

/**
 * @description Component that allows user to input a new invoice charge
 * @param {bool} showAddCharge - Boolean that displays this component when set to true
 * @param {Function} saveCharge - Function that saves the charge given a rate and description
 * @param {Function} hideAddCharge - Function that hides this component from view
 * @returns
 */
const InvoiceAddCharge = (props) => {

  // ** useStates ** //
  const [showAddCharge, setShowAddCharge] = useState(false);

  const [description, setDescription] = useState('');
  const [rate, setRate] = useState(0);

  // ** useEffects ** //
  useEffect(() => {
    setShowAddCharge(props.showAddCharge);
  }, [props.showAddCharge]);

  // ** Callback Functions ** //
  const saveCharge = () => {
    props.saveCharge(rate, description);

    setDescription('');
    setRate(0);
  };

  // ** Components ** //
  const footer = (
    <div className="flex flex-row justify-content-end">
      <div className="pr-2">
        <Button label="Cancel" onClick={() => props.hideAddCharge()} />
      </div>
      <Button label="Save" onClick={() => saveCharge()} />
    </div>
  );

  const descriptionField = (
    <>
      <DocumentInputTextarea
        rows={5}
        cols={30}
        value={description}
        onChange={(value) => setDescription(value)}
        autoResize
        success={description}
        warning={!description || description == ''}
        isDownloaded={props.isDownloaded}
      />
    </>
  );

  const rateField = (
    <div className="rate-field">
      <div className="flex flex-row">
        <DocumentInputNumber
          label="Rate"
          value={rate}
          onChange={(value) => setRate(value)}
          mode="decimal"
          minFractionDigits={2}
          prefix="$"
          success={rate}
          warning={!rate || rate == ''}
          isDownloaded={props.isDownloaded}
        />
      </div>
    </div>
  );

  const addCharge = (
    <div className="add-charge">
      <ColoredSection title="Add Charge" footer={footer}>
        <div className="grid">
          <div className="col-7">
            {descriptionField}
          </div>
          <div className="col-5">
            {rateField}
          </div>
        </div>
      </ColoredSection>
    </div>
  );

  return (
    <div className="invoice-add-charge">
      {showAddCharge && addCharge}
    </div>
  );

};

export default InvoiceAddCharge;
