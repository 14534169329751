import React from 'react';

// PrimeReact Component
import { TriStateCheckbox as PRTriStateCheckbox } from 'primereact/tristatecheckbox';

// Styles
import './style.scss';

/**
 * @description PrimeReact tristatecheckbox component
 * @param {String} className - Custom container className
 */
function TriStateCheckbox({ ...props }) {
  // ** Misc ** //
  let className = 'sbc-tri-state-checkbox';
  if (props.className) {
    className += ` ${props.className}`;
  }
  return (
    <div className={className}>
      <PRTriStateCheckbox {...props} />
    </div>
  );
}

export default TriStateCheckbox;
