import React from 'react';

// PrimeReact Component
import { Calendar as PRCalendar } from 'primereact/calendar';

// Styles
import './style.scss';

/**
 * @description PrimeReact calendar component
 * @param {String} className - Custom container className
 */
export default function Calendar({ warning, error, success, ...props }) {

  // ** Misc ** //
  let className = 'sbc-calendar';
  if (props.className) className += ` ${props.className}`;

  if (props.variant === 'small') {
    className += ' calendar-small';
  }

  if (error) {
    className += ` sbc-error`;
  } else if (warning) {
    className += ` sbc-warning`;
  } else if (success) {
    className += ` sbc-success`;
  }

  return (<PRCalendar className={className} {...props} />);
}
