/** @module DispatchAccessorial */

import {
  callCloudFunction, copyQuery, count, createQuery, createQueryOr, destroyRecord, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, getRecordByObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, getCurrentUser, addRecord,
} from 'sb-csapi/dist/AAPI';

import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @memberof module:DispatchAccessorial
 *
 * @description Get a list of DispatchTransfers of a given DispatchJob
 *
 * @param {Object} options - See example
 * @param {DispatchJob} dispatchJob - The specific dispatch job that the DispatchAccessorials are associated with
 * @param {Array} Filters - Array of Filter objects
 * @param {Object} sortBy - Sort object
 * @param {Array} includedPointers - Included pointers
 * @param {Array} selectedAttributes - Select attributes to return
 * @param {Number} page - The current page for pagination
 * @param {Number} limit - The limit of records obtained per pagination
 * @param {Boolean} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { Object } - { totalDispatchAccessorialsCount: Number, dispatchAccessorials: [DispatchAccessorial, ...] }
 */
async function getDispatchAccessorials(options = { sessionToken: getCurrentUserSessionToken() }, dispatchJob, filters = [], sortBy = new Sort(QuerySortOrder.DESCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  if (!dispatchJob) throw new Error('No dispatch job specified');

  const dispatchAccessorialQuery = createQuery('DispatchAccessorial');
  setQueryRestriction(dispatchAccessorialQuery, QueryRestriction.EQUAL_TO, 'dispatchJob', dispatchJob);

  filters.map(filter => setQueryRestriction(dispatchAccessorialQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  const dispatchAccessorialCountQuery = copyQuery(dispatchAccessorialQuery);

  // sort
  sortQuery(dispatchAccessorialQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchAccessorialQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchAccessorialQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(dispatchAccessorialQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(dispatchAccessorialQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the count and the documents
  const promises = [count(options, dispatchAccessorialCountQuery), findRecords(options, dispatchAccessorialQuery, false, queryAll)];

  try {
    const [totalDispatchAccessorialsCount, dispatchAccessorials] = await Promise.all(promises);
    return { totalDispatchAccessorialsCount, dispatchAccessorials };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchAccessorial
 *
 * @description Add a Dispatch Accessorial
 *
 * @param {Object} keyValueObj - The select fields we wish to update
 *
 * @returns {DispatchAccessorial} - The created DispatchAccessorial
 */
async function addDispatchAccessorial(keyValueObj) {
  try {
    const dispatchAccessorial = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'DispatchAccessorial', keyValueObj, getCurrentUserCompanyObjectId());
    return dispatchAccessorial;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchAccessorial
 *
 * @description Update a DispatchAccessorial
 *
 * @param {DispatchAccessorial} dispatchAccessorial - The dispatchAccessorial to update. If not available, use parameter dispatchAccessorialObjectId instead
 * @param {String} dispatchAccessorialObjectId - The objectId of the dispatchAccessorial object we wish to update. Use if parameter dispatchAccessorial is unavailable
 * @param {Object} keyValueObj - The select fields we wish to update
 * @param {Boolean} save - If true, executes save on the changes. If false, holds the changes locally but is not saved
 *
 * @returns {DispatchAccessorial} - The updated DispatchAccessorial
 */
async function updateDispatchAccessorial(dispatchAccessorial, dispatchAccessorialObjectId, keyValueObj, save) {
  if (!dispatchAccessorial && !dispatchAccessorialObjectId) throw new Error('Missing Arguments: Must provide dispatchAccessorial or dispatchAccessorialObjectId');
  if (dispatchAccessorial && dispatchAccessorialObjectId) throw new Error('Arguments: Must provide only one of dispatchAccessorial or dispatchAccessorialObjectId');

  let _dispatchAccessorial = dispatchAccessorial;

  try {
    if (!dispatchAccessorial && dispatchAccessorialObjectId) {
      _dispatchAccessorial = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'DispatchAccessorial', dispatchAccessorialObjectId);
    }

    if (_dispatchAccessorial) {
      if (!Object.keys(keyValueObj).length) return _dispatchAccessorial;

      _dispatchAccessorial = await updateRecord({ sessionToken: getCurrentUserSessionToken() }, _dispatchAccessorial, keyValueObj, save);
      return _dispatchAccessorial;
    } else {
      throw new Error('DispatchAccessorial does not exist');
    }
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchAccessorial
 * @description Remove a DispatchAccessorial record and return the last version of it
 *
 * @param {object} record - The DispatchAccessorial record we would like to remove
 */
async function removeDispatchAccessorial(record) {
  try {
    const dispatchAccessorial = await destroyRecord({ sessionToken: getCurrentUserSessionToken() }, record);
    return dispatchAccessorial;
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getDispatchAccessorials,
  addDispatchAccessorial,
  updateDispatchAccessorial,
  removeDispatchAccessorial,
};
