import React from 'react';
import PropTypes from 'prop-types';
// import moment from 'moment-timezone';

import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import FilterForm from 'components/FilterForm/container/FilterForm.new.js';
import SBSelect from 'components/Shared/SBSelect/SBSelect';

// enums
import { QueryRestrictionTypes } from 'enums/Query';
import { UserTypes } from 'enums/UserTypes';
import { ELDEditCompleted } from 'enums/ELDEditType';

// sbobjects
import Filter from 'sbObjects/Filter';

// components
import { MDBRow, MDBBtn } from 'mdbreact';

class HOSEditFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestDateValue: undefined,
      logDateValue: undefined,
      driverNameValue: undefined,
      searchFilters: [],
      statusFilter: undefined,
      currSelectedItems: [],
      dispatcherDriverFilter: undefined,
      currentDate: undefined,
      isDispatcher: false,
      isDriver: false,
    };

    this.state.selectableStatusItems = Object.keys(ELDEditCompleted).map(statusType => {
      const statusTypeObj = ELDEditCompleted[statusType];
      const classKeyExpression = statusTypeObj.colorKey.replace(/_/g, '-').toLowerCase();
      // const labelContainerTextClassName = `hos-job-${classKeyExpression}`
      // const labelContainerHoverClassName = `bg-hos-job-${classKeyExpression}`;
      // const activeClassName = `bg-hos-job-${classKeyExpression}`;

      return {
        key: statusTypeObj.status,
        value: statusTypeObj.status,
        label: statusTypeObj.description,
        // labelContainerClassName: `${labelContainerTextClassName} ${labelContainerHoverClassName}`,
        // activeClassName,
      };
    });

    this.getAllFilters = this.getAllFilters.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.getSelectedStatusItems = this.getSelectedStatusItems.bind(this);
    this.handleDispatcherDriver = this.handleDispatcherDriver.bind(this);

  }

  handleFilter(searchFilters = []) {
    let requestDateValue;
    let logDateValue;
    let driverNameValue;
    // Must separate them so we can access date values for filterFormFields value: __date_here__
    searchFilters = searchFilters.map(filter => {
      if (filter.attribute === 'requestedAt') requestDateValue = filter.value;
      if (filter.attribute === 'eldDailyCertification.startTimeUTC') logDateValue = filter.value;
      if (filter.attribute === 'driver.user_fullName') driverNameValue = filter.value;

      if (filter.queryType === 'date') filter.queryType = filter.dateQueryType;
      return new Filter(filter.attribute, filter.value, filter.queryType);
    });

    this.setState({ searchFilters, requestDateValue, logDateValue, driverNameValue }, this.getAllFilters);
  }

  async handleDispatcherDriver(type) {
    if (type === 'dispatcher') {
      let dispatcherDriverFilter = new Filter('requestedBy', UserTypes.DRIVER.type, QueryRestrictionTypes.NOT_EQUAL_TO);
      await this.setState({ ...this.state, isDispatcher: true, isDriver: false, dispatcherDriverFilter });
      this.getAllFilters();
    }
    else if (type === 'driver') {
      let dispatcherDriverFilter = new Filter('requestedBy', [UserTypes.DRIVER.type], QueryRestrictionTypes.CONTAINED_IN);
      await this.setState({ ...this.state, isDispatcher: false, isDriver: true, dispatcherDriverFilter });
      this.getAllFilters();
    }
  }

  async getSelectedStatusItems(selectedStatusItems = []) {
    const newState = { ...this.state };

    const selectedStatuses = selectedStatusItems.map(statusItem => {
      return statusItem.value;
    });

    let statusFilter = undefined;
    if (selectedStatuses.length > 0) {
      statusFilter = new Filter('completed', selectedStatuses, QueryRestrictionTypes.CONTAINED_IN);
    }

    newState.statusFilter = statusFilter;
    newState.currSelectedItems = selectedStatusItems;
    await this.setState(newState);
    this.getAllFilters();
  }

  getAllFilters() {
    const filters = [];
    const { state, props } = this;
    if (state.statusFilter) {
      filters.push(state.statusFilter);
    }
    if (state.searchFilters && state.searchFilters.length > 0) {
      filters.push(...state.searchFilters);
    }
    if (state.dispatcherDriverFilter) {
      filters.push(state.dispatcherDriverFilter);
    }
    props.getFilters(filters);
  }

  clearAllFilters() {
    this.setState({
      ...this.state,
      searchFilters: [],
      statusFilter: undefined,
      dispatcherDriverFilter: undefined,
      currSelectedItems: [],
      isDispatcher: false,
      isDriver: false,
      requestDateValue: undefined,
      logDateValue: undefined,
      driverNameValue: undefined,
    });
    this.props.getFilters([]);
  }

  render() {
    const { props, state } = this;
    const searchFilterFields = [
      { attrName: 'eldDailyCertification.startTimeUTC',
        fullName: 'Date of Log',
        value: state.logDateValue,
        type: 'datePicker',
        queryType: 'date',
        dateQueryType: 'onDate',
        includeLeftRight: true,
      },
      { attrName: 'requestedAt',
        fullName: 'Requested On',
        value: state.requestDateValue,
        type: 'datePicker',
        queryType: 'date',
        dateQueryType: 'onDate',
        includeLeftRight: true,
      },
      { attrName: 'driver.user_fullName',
        queryType: QueryRestrictionTypes.MATCHES,
        fullName: 'Driver Name',
        type: 'text',
        value: state.driverNameValue,
      },
    ];
    return (
      <ActionsContainer>
        <MDBRow className="align-items-center justify-content-center d-flex">
          <SBSelect
            containerClassName="ml-2 mr-2 d-inline-block mt-0 mb-0"
            className="sb-jobs-list-select"
            defaultToggleText="Filter Status"
            items={state.selectableStatusItems}
            selectedItems={state.currSelectedItems}
            showFilter
            multiSelectable
            showSelectAll
            getSelectedItems={this.getSelectedStatusItems}
            // disabled={isLoading || isWaiting}
          />
          <span>
            <div className="d-inline-block mb-0 ml-2 mr-2 mt-0">
              <div className="custom-control custom-radio">
                <input type="radio" className="custom-control-input" id="dispatch" name="dispatched" onClick={() => {this.handleDispatcherDriver('dispatcher')}} checked={this.state.isDispatcher} onChange={() =>{}} />
                <label className="custom-control-label" htmlFor="dispatch">Dispatcher Requested</label>
              </div>
            </div>
            <div className="d-inline-block mb-0 mr-2 mt-0">
              <div className="custom-control custom-radio">
                <input type="radio" className="custom-control-input" id="driver" name="dispatched" onClick={() => {this.handleDispatcherDriver('driver')}} checked={this.state.isDriver} onChange={() =>{}} />
                <label className="custom-control-label" htmlFor="driver">Driver Requested</label>
              </div>
            </div>
          </span>
          <FilterForm
            // className={styles.filterForm}
            handleFilter={(filterArr) => this.handleFilter(filterArr)}
            clearFilter={() => this.handleFilter()}
            fields={searchFilterFields}
          />

          <MDBBtn
            color="primary"
            size="sm"
            type="submit"
            onClick={() => { this.clearAllFilters(); }}
            disabled={!this.state.statusFilter && !this.state.dateFilter && !this.state.dispatcherDriverFilter}
          >
            Clear Filters
          </MDBBtn>
        </MDBRow>

      </ActionsContainer>
    );
  }
}

HOSEditFilter.propTypes = {
  onDate: PropTypes.instanceOf(Date),
  filterByDate: PropTypes.func,
  filterByType: PropTypes.func,
  styles: PropTypes.any,
  disableOptions: PropTypes.bool,
};

export default HOSEditFilter;
