import React from 'react';

// Style
import './style.scss';

/**
 * @description Displays a given snapshot of a VisionVehicle
 * @param {String} url - The URL linked to the snapshot
 * @returns {JSX} - VisionSnapshot component
 */
function VisionSnapshot(props) {
  const { url } = props;

  return (
    <div className="snapshot-display">
      <img src="https://s3.amazonaws.com/onswitchboard.com/white-logo.png" className="snapshot-watermark" alt="Switchboard Watermark" />
      <div className="snapshot-badge mt-3 mr-3">
        <span className="snapshot-badge-icon material-icons mr-2 vertical-align-middle">photo_camera</span>
        <span className="snapshot-badge-label uppercase font-light text-sm">snapshot</span>
      </div>
      <img src={url} className="snapshot-img" alt="Snapshot" />
    </div>
  );
}

export default VisionSnapshot;
