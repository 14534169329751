import React from 'react';
import PropTypes from 'prop-types';
import { Marker } from 'react-mapbox-gl';

// CSS
import styles from './ComponentStyles.module.scss';

function getColourString(string) {
  if (string === 'info') {
    return '#5bc0de';
  }
  if (string === 'success') {
    return '#5cb85c';
  }
  if (string === 'default') {
    return '#777';
  }
  return undefined;
}

function ELDEventMarker(props) {
  // if (props.eldEvent.get('eldEventTypeCodeInt') === 11) {
  //   // Off Duty
  //   return (
  //     <Marker
  //       onClick={() => { if (props.handleOnClick) props.handleOnClick(); }}
  //       offset={[0, 5]}
  //       coordinates={props.coordinates}
  //     >
  //       <div><i className={`material-icons md-36 ${styles.pointOfInterest} ${styles.marker}`} style={{ color: '#DDD' }}>edit_location</i></div>
  //     </Marker>
  //   );
  // }
  // if (props.eldEvent.get('eldEventTypeCodeInt') === 12) {
  //   // Sleeper Berth
  //   return (
  //     <Marker
  //       onClick={() => { if (props.handleOnClick) props.handleOnClick(); }}
  //       offset={[0, 5]}
  //       coordinates={props.coordinates}
  //     >
  //       <div><i className={`material-icons md-36 ${styles.pointOfInterest} ${styles.marker}`} style={{ color: '#DDD' }}>edit_location</i></div>
  //     </Marker>
  //   );
  // }
  // if (props.eldEvent.get('eldEventTypeCodeInt') === 13) {
  //   // Driving
  //   return (
  //     <Marker
  //       onClick={() => { if (props.handleOnClick) props.handleOnClick(); }}
  //       offset={[0, 5]}
  //       coordinates={props.coordinates}
  //     >
  //       <div><i className={`material-icons md-36 ${styles.pointOfInterest} ${styles.marker}`} style={{ color: getColourString('success') }}>edit_location</i></div>
  //     </Marker>
  //   );
  // }
  if (props.eldEvent.get('eldEventTypeCodeInt') === 14) {
    // On Duty
    return (
      <Marker
        onClick={() => { if (props.handleOnClick) props.handleOnClick(); }}
        offset={[0, 5]}
        coordinates={props.coordinates}
      >
        <div><i className={`material-icons md-36 ${styles.pointOfInterest} ${styles.marker}`} style={{ color: getColourString('info') }}>edit_location</i></div>
      </Marker>
    );
  }
  if (props.eldEvent.get('eldEventTypeCodeInt') === 63 || props.eldEvent.get('eldEventTypeCodeInt') === 64) {
    // Engine Shutdown
    return (
      <Marker
        onClick={() => { if (props.handleOnClick) props.handleOnClick(); }}
        offset={[0, 5]}
        coordinates={props.coordinates}
      >
        <div><i className={`material-icons md-36 ${styles.pointOfInterest} ${styles.marker}`} style={{ color: '#DDD' }}>settings_input_svideo</i></div>
      </Marker>
    );
  }
  if (props.eldEvent.get('eldEventTypeCodeInt') === 61 || props.eldEvent.get('eldEventTypeCodeInt') === 62) {
    // Engine Startup
    return (
      <Marker
        onClick={() => { if (props.handleOnClick) props.handleOnClick(); }}
        offset={[0, 5]}
        coordinates={props.coordinates}
      >
        <div><i className={`material-icons md-36 ${styles.pointOfInterest} ${styles.marker}`} style={{ color: getColourString('info') }}>settings_input_svideo</i></div>
      </Marker>
    );
  }
  return null;
}

ELDEventMarker.propTypes = {
  eldEvent: PropTypes.object.isRequired,
  coordinates: PropTypes.array.isRequired,
  handleOnClick: PropTypes.func.isRequired,
};

export default ELDEventMarker;
