export const vehicleTypes = [
  '',
  'Beverage Truck',
  'Box Truck',
  'Bus',
  'Construction Vehicle',
  'Garbage Truck',
  'Panel Truck',
  'Pickup Truck',
  'Heavy Truck (semi)',
  'Limosine',
  'Van',
];
export const trailerTypes = [
  '',
  'Dry Van',
  'Refrigerated (Reefer)',
  'Flatbed',
  'Step Deck',
  'Container',
  'Tanker',
  'Lowbed',
  'Other',
];
