import React from 'react';

// Components
import Card from 'sbCore/Card/Card';

// Styling
import './style.scss';

/**
 * @description
 * 
 * @param {String} variant - 
 * @returns 
 */
export default function ColoredSection({ ...props }) {
  let className = 'sbc-colored-section'; // default styles

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (props.variant === 'compact') {
    className += ' colored-section-compact';
  }

  let sectionTitle = undefined;

  if (!props.noTitle) {
    sectionTitle = (
      <React.Fragment>
        <div className="inline-block title">{props.title}</div>
        <div className="inline-block options">{props.options}</div>
      </React.Fragment>
    );
  }

  const sectionContent = (
    <div className="content">
      {props.children}
    </div>
  );

  let sectionFooter = undefined;

  if (!props.noFooter) {
    sectionFooter = props.footer;
  }

  let sectionHeader = undefined;

  if (props.header) {
    sectionHeader = props.header;
  }

  let sectionSubTitle = undefined;
  if (props.subTitle) sectionSubTitle = props.subTitle;

  return (
    <div className={className}>
      <Card className={className} title={sectionTitle} footer={sectionFooter} header={sectionHeader} subTitle={sectionSubTitle}>
        {sectionContent}
      </Card>
    </div>
  );
}
