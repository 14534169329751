import * as ActionConstants from 'actions/ActionConstants';

// Note that at the moment, the Receipt state only holds the receipts of a given driver
const initialState = {
  receipts: [],
  fetched: false,
  driverObjectId: undefined, // Reference to the driver whom the receipts belong
};

export function Receipt(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_RECEIPTS_INPROGRESS:
      return {
        ...state,
        status: ActionConstants.FETCH_RECEIPTS_INPROGRESS,
        error: undefined,
        fetched: false,
        driverObjectId: action.driverObjectId,
      };
    case ActionConstants.FETCH_RECEIPTS_SUCCESS:
      return {
        ...state,
        status: ActionConstants.FETCH_RECEIPTS_SUCCESS,
        receipts: [].concat(action.receipts),
        error: undefined,
        fetched: true,
      };
    case ActionConstants.FETCH_RECEIPTS_ERROR:
      return {
        ...state,
        status: ActionConstants.FETCH_RECEIPTS_ERROR,
        error: action.error,
        fetched: false,
      };
    case ActionConstants.DELETE_RECEIPTS:
      return {
        ...initialState,
        status: action.type,
      };
    case ActionConstants.ADD_RECEIPT_SUCCESS: {
      // only add receipt if it belongs to the current group of a driver's receipts
      const receipt = action.receipt;
      if (receipt.get('driver').id === state.driverObjectId) {
        const updatedReceipts = [].concat(state.receipts);
        updatedReceipts.unshift(receipt);
        return {
          ...state,
          status: ActionConstants.ADD_RECEIPT_SUCCESS,
          receipts: updatedReceipts,
        };
      }
      break;
    }
    default:
      return state;
  }
}
