import React from 'react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

function DatePicker(props) {
  return (
    <ReactDatePicker
      {...props} dropdownMode="select"
    />
  );
}

export default DatePicker;