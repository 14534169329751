export default {
  AL: ['TN','GA','FL','MS'],
  AK: ['BC', 'YT'],
  AR: ['MO','TN','MS','LA','TX','OK'],
  AZ: ['UT','CO','NM','CA','NV'],
  CA: ['OR','NV','AZ','HI'],
  CO: ['WY','NE','KS','OK','NM','AZ','UT'],
  CT: ['MA','RI','NY'],
  DC: ['MD','VA'],
  DE: ['PA','NJ','MD'],
  FL: ['GA','AL'],
  GA: ['NC','SC','FL','AL','TN'],
  HI: ['CA'],
  IA: ['MN','WI','IL','MO','NE','SD'],
  ID: ['BC', 'MT','WY','UT','NV','OR','WA'],
  IL: ['WI','IN','KY','MO','IA'],
  IN: ['MI','OH','KY','IL'],
  KS: ['NE','MO','OK','CO'],
  KY: ['OH','WV','VA','TN','MO','IL','IN'],
  LA: ['AR','MS','TX'],
  MA: ['NH','RI','CT','NY','VT'],
  MD: ['PA','DE','DC','VA','WV'],
  ME: ['QC', 'NB', 'NH'],
  MI: ['ON', 'OH','IN','WI'],
  MN: ['MB', 'ON', 'WI','IA','SD','ND'],
  MO: ['IA','IL','KY','TN','AR','OK','KS','NE'],
  MS: ['TN','AL','LA','AR'],
  MT: ['BC', 'AB', 'SK', 'ND','SD','WY','ID'],
  NC: ['VA','SC','GA','TN'],
  ND: ['SK', 'MB', 'MN','SD','MT'],
  NE: ['SD','IA','MO','KS','CO','WY'],
  NH: ['QC', 'ME','MA','VT'],
  NJ: ['NY','DE','PA'],
  NM: ['CO','OK','TX','AZ','UT'],
  NV: ['ID','UT','AZ','CA','OR'],
  NY: ['ON', 'QC', 'VT','MA','CT','NJ','PA'],
  OH: ['PA','WV','KY','IN','MI'],
  OK: ['KS','MO','AR','TX','NM','CO'],
  OR: ['WA','ID','NV','CA'],
  PA: ['NY','NJ','DE','MD','WV','OH'],
  RI: ['MA','CT'],
  SC: ['NC','GA'],
  SD: ['ND','MN','IA','NE','WY','MT'],
  TN: ['KY','VA','NC','GA','AL','MS','AR','MO'],
  TX: ['OK','AR','LA','NM'],
  UT: ['ID','WY','CO','NM','AZ','NV'],
  VA: ['MD','DC','NC','TN','KY','WV'],
  VT: ['QC', 'NH','MA','NY'],
  WA: ['BC', 'AK','ID','OR'],
  WI: ['MI','IL','IA','MN'],
  WV: ['PA','MD','VA','KY','OH'],
  WY: ['MT','SD','NE','CO','UT','ID'],
  AB: ['BC', 'NT', 'SK', 'MT'],
  BC: ['WA', 'ID', 'AB', 'YT', 'NT'],
  MB: ['SK', 'NU', 'ND', 'MN', 'ON'],
  NB: ['ME', 'QC', 'PE'],
  NL: ['QC'],
  NS: ['NB'],
  NT: ['YT', 'BC', 'AB', 'SK', 'MB', 'NU'],
  NU: ['MB', 'NT'],
  ON: ['MB', 'MN', 'MI', 'NY', 'QC'],
  PE: ['NB'],
  QC: ['ON', 'NH', 'NY', 'VT', 'ME', 'NB', 'NL'],
  SK: ['NT', 'AB', 'MT', 'MB', 'ND'],
  YT: ['NT', 'AK', 'BC'],
};
