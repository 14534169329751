import React from 'react';
import PropTypes from 'prop-types';
import { Feature, Layer } from 'react-mapbox-gl';

// API
import { getColour } from 'api/Mapbox';

import styles from './ComponentStyles.module.scss';

function getCoords(geofenceParseObj) {
  return [[
    [geofenceParseObj.get('leftLong'), geofenceParseObj.get('topLat')],
    [geofenceParseObj.get('rightLong'), geofenceParseObj.get('topLat')],
    [geofenceParseObj.get('rightLong'), geofenceParseObj.get('bottomLat')],
    [geofenceParseObj.get('leftLong'), geofenceParseObj.get('bottomLat')],
    [geofenceParseObj.get('leftLong'), geofenceParseObj.get('topLat')],
  ]];
}

function getActivePaint() {
  return { 'fill-color': 'rgba(231, 76, 60, 0.4)', 'fill-outline-color': 'rgba(192, 57, 43, 1.0)' };
}

function getInactivePaint() {
  return { 'fill-color': 'rgba(89, 126, 192, 0.4)', 'fill-outline-color': '#446CB3' };
}

function Geofence(props) {
  return (
    <Layer
      onMouseMove={() => undefined}
      id={props.geofenceParseObj.id}
      type="fill"
      paint={props.active ? getActivePaint() : getInactivePaint()}
    >
      <Feature
        onClick={(e) => props.handleClick(e)}
        coordinates={getCoords(props.geofenceParseObj)}
      />
    </Layer>
  );
}

Geofence.propTypes = {
  geofenceParseObj: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

export default Geofence;
