import Parse from 'parse';
/** @module Geocode */

/**
 * @memberof module:Geocode
 * @param {*} coordinatesArray 
 * @returns 
 */
function getReverseGeocode(coordinatesArray) {
  // [[lat,lng]]
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('reverseGeocode', { coordinatesArray }).then(
      response => {
        resolve(response);
      },
      error => {
        console.log(error);
        reject(error);
      }
    );
  });
  return promise;
}

// Cheap version
/**
 * @memberof module:Geocode
 * @param {*} coordinatesArray 
 * @returns 
 */
function getLocalReverseGeocode(coordinatesArray) {
  // [[lat,lng]]
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('localReverseGeocode', { coordinatesArray }).then(
      response => {
        resolve(response);
      },
      error => {
        console.log(error);
        reject(error);
      }
    );
  });
  return promise;
}

const getDistanceAndDirectionBetweenTwoCoordinates = (latLngArr1, latLngArr2, preventRounding) => {
  // [[lat,lng]]
  // https://www.movable-type.co.uk/scripts/latlong.html

  const lat1 = latLngArr1[0];
  const lon1 = latLngArr1[1];
  const lat2 = latLngArr2[0];
  const lon2 = latLngArr2[1];

  const R = 6371e3; // metres
  const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
  const φ2 = lat2 * Math.PI / 180;
  const Δφ = (lat2 - lat1) * Math.PI / 180;
  const Δλ = (lon2 - lon1) * Math.PI / 180;

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) *
    Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  let distanceKm = (R * c) / 1000; // in km
  if (!preventRounding) {
    distanceKm = Math.round(distanceKm)
  }

  // https://stackoverflow.com/questions/11415106/issue-with-calculating-compass-bearing-between-two-gps-coordinates
  function bearing(_startLat, _startLng, _destLat, _destLng) {
    const dLon = (_destLng - _startLng);
    const y = Math.sin(dLon) * Math.cos(_destLat);
    const x = Math.cos(_startLat) * Math.sin(_destLat) - Math.sin(_startLat) * Math.cos(_destLat) * Math.cos(dLon);
    const brng = (Math.atan2(y, x)) * 180 / Math.PI;

    // clockwise, 0° being North
    return 360 - ((brng + 360) % 360);
  }

  const bearingDegrees = bearing(lat1, lon1, lat2, lon2);

  let heading;
  if (bearingDegrees < (0 * 22.5) + 11.25) heading = 'N';
  else if (bearingDegrees < (2 * 22.5) + 11.25) heading = 'NE';
  else if (bearingDegrees < (4 * 22.5) + 11.25) heading = 'E';
  else if (bearingDegrees < (6 * 22.5) + 11.25) heading = 'SE';
  else if (bearingDegrees < (8 * 22.5) + 11.25) heading = 'S';
  else if (bearingDegrees < (10 * 22.5) + 11.25) heading = 'SW';
  else if (bearingDegrees < (12 * 22.5) + 11.25) heading = 'W';
  else if (bearingDegrees < (14 * 22.5) + 11.25) heading = 'NW';
  else heading = 'N';

  return { distanceKm, bearingDegrees, heading };
}

export {
  getReverseGeocode,
  getLocalReverseGeocode,
  getDistanceAndDirectionBetweenTwoCoordinates,
};
