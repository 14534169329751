import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "../../../sbHistory";
import onClickOutside from 'react-onclickoutside';

// API
import * as Helpers from 'api/Helpers';
// import HOSRegulation from 'api/Lists/HOSRegulation';
// import GeofenceEventStateList from 'api/Lists/GeofenceEventState';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './NotificationPanel.module.scss';


function getEnterExit(geofenceEventStateInt) {
  if (geofenceEventStateInt === 1) {
    return 'entered';
  } else if (geofenceEventStateInt === 2) {
    return 'exited';
  }
  return '';
}


class NotificationPanel extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.getNotificationStyle = this.getNotificationStyle.bind(this);
  }

  handleClickOutside() {
    const { handleClickOutside } = this.props;

    if (handleClickOutside) handleClickOutside();
  }

  getNotificationStyle(notification) {
    if (notification.read) {
      return `${styles.notification}`;
    }
    return `${styles.notification} ${styles.unread}`;
  }

  render() {
    return (
      <div className={styles.panel}>
        <div className={styles.header}>
          Notifications (Latest 20)
        </div>
        <div className={styles.notificationList}>
          <span>
            {this.props.notifications.map((notification, index) => {
              if (notification.class === 'Document' && notification.type === 'Create') {
                return (
                  <div key={index} className={this.getNotificationStyle(notification)} onMouseEnter={() => this.props.handleNotificationHover(notification)}>
                    <div className={styles.viewDocument}><button className={`${styles.viewDocumentButton} buttonDefault`} onClick={() => Helpers.openDocumentLink(notification.url)}>View</button></div>
                    <div className={styles.notificationText}><b>[{notification.jobId}]:</b> {notification.name} has uploaded a "{notification.fileName}"</div>
                    <span className={styles.date}>{notification.createdAt.format('LLLL')}</span>
                  </div>
                );
              } else if (notification.class === 'JobLink' && notification.type === 'Create') {
                return (
                  <div key={index} className={this.getNotificationStyle(notification)} onMouseEnter={() => this.props.handleNotificationHover(notification)}>
                    <div className={styles.notificationText}><b>[{notification.jobId}]:</b> {notification.name} has created a new Job</div>
                    <span className={styles.date}>{notification.createdAt.format('LLLL')}</span>
                  </div>
                );
              } else if (notification.class === 'Receipt' && notification.type === 'Create') {
                return (
                  <div key={index} className={this.getNotificationStyle(notification)} onMouseEnter={() => this.props.handleNotificationHover(notification)}>
                    <div className={styles.viewDocument}><button className={`${styles.viewDocumentButton} buttonDefault`} onClick={() => Helpers.openDocumentLink(notification.url)}>View</button></div>
                    <div className={styles.notificationText}><b>[Receipt]:</b> {notification.name} has uploaded a "{notification.fileName}" Receipt</div>
                    <span className={styles.date}>{notification.createdAt.format('LLLL')}</span>
                  </div>
                );
              } else if (notification.class === 'Document' && notification.type === 'Retake') {
                return (
                  <div key={index} className={this.getNotificationStyle(notification)} onMouseEnter={() => this.props.handleNotificationHover(notification)}>
                    <div className={styles.viewDocument}><button className={`${styles.viewDocumentButton} buttonDefault`} onClick={() => Helpers.openDocumentLink(notification.url)}>View</button></div>
                    <div className={styles.notificationText}><b>[{notification.jobId}]:</b> {notification.name} has retaken a "{notification.fileName}"</div>
                    <span className={styles.date}>{notification.createdAt.format('LLLL')}</span>
                  </div>
                );
              } else if (notification.class === 'JobAction' && notification.type === 'Complete') {
                return (
                  <div key={index} className={this.getNotificationStyle(notification)} onMouseEnter={() => this.props.handleNotificationHover(notification)}>
                    <div className={styles.notificationText}><b>[{notification.jobId}]:</b> {notification.jobActionType} completed by <i>Vehicle {notification.vehicle_unitId} - {notification.user_fullName}</i></div>
                    <span className={styles.date}>{notification.createdAt.format('LLLL')}</span>
                  </div>
                );
              } else if (notification.class === 'ELDEvent' && notification.type === 'OutOfHours' && notification.description.split(':')[0].toLowerCase() === 'warning') {
                return (
                  <div key={index} className={this.getNotificationStyle(notification)} onMouseEnter={() => this.props.handleNotificationHover(notification)}>
                    <div className={styles.viewDocument}><button className={`${styles.viewDocumentButton} buttonDefault`} onClick={() => this.props.handleNotificationHover(notification, true)}>Clear</button></div>
                    <div className={styles.notificationText}><b>[Hours of Service]:</b> {notification.name} has 1 Hour left of {notification.description.split(':')[1]} Time</div>
                    <span className={styles.date}>{notification.createdAt.format('LLLL')}</span>
                  </div>
                );
              } else if (notification.class === 'ELDEvent' && notification.type === 'OutOfHours' && notification.description.split(':')[0].toLowerCase() === 'over') {
                return (
                  <div key={index} className={this.getNotificationStyle(notification)} onMouseEnter={() => this.props.handleNotificationHover(notification)}>
                    <div className={styles.viewDocument}><button className={`${styles.viewDocumentButton} buttonDefault`} onClick={() => this.props.handleNotificationHover(notification, true)}>Clear</button></div>
                    <div className={styles.notificationText}><b>[Hours of Service]:</b> {notification.name} completed hours for {notification.description.split(':')[1]} Time</div>
                    <span className={styles.date}>{notification.createdAt.format('LLLL')}</span>
                  </div>
                );
              } else if (
                notification.class === 'GeofenceEvent'
                && notification.trailerUnitId
                && notification.trailerObjectId
              ) {
                return (
                  <div key={index} className={this.getNotificationStyle(notification)} onMouseEnter={() => this.props.handleNotificationHover(notification)}>
                    <div className={styles.viewDocument}>
                      {notification.geofenceObjectId &&
                        <button className={`${styles.viewDocumentButton} buttonDefault`} onClick={() => history.push({ pathname: 'geofence', search: "geofence=" + notification.geofenceObjectId + "&date=" + moment.unix(notification.dateTime / 1000).format('DDMMYY') })}>View</button>
                      }
                    </div>
                    <div className={styles.notificationText}><b className="notranslate">{(notification.trailerUnitId || 'Trailer')}</b> <span>has {getEnterExit(notification.type)}</span> <b className="notranslate">{notification.geofenceName}</b></div>
                    <span className={`${styles.date} notranslate`}>{moment.unix(notification.dateTime / 1000).format('LLLL')}</span>
                  </div>
                );
              } else if (
                notification.class === 'GeofenceEvent'
                && notification.vehicleUnitId
                && notification.vehicleObjectId
              ) {
                return (
                  <div key={index} className={this.getNotificationStyle(notification)} onMouseEnter={() => this.props.handleNotificationHover(notification)}>
                    <div className={styles.viewDocument}><button className={`${styles.viewDocumentButton} buttonDefault`} onClick={() => history.push({ pathname: 'vehicle', search: "vehicle=" + notification.vehicleObjectId + "&view=geofenceEvents&date=" + moment.unix(notification.dateTime / 1000).format('DDMMYY') })}>View</button></div>
                    <div className={styles.notificationText}><b className="notranslate">{(notification.vehicleUnitId || 'Vehicle')}</b> <span>has {getEnterExit(notification.type)}</span> <b className="notranslate">{notification.geofenceName}</b></div>
                    <span className={`${styles.date} notranslate`}>{moment.unix(notification.dateTime / 1000).format('LLLL')}</span>
                  </div>
                );
              } else if (notification.class === 'HOSViolation') {
                // return (
                //   <div key={index} className={this.getNotificationStyle(notification)} onMouseEnter={() => this.props.handleNotificationHover(notification)}>
                //     <div className={styles.viewDocument}><button className={`${styles.viewDocumentButton} buttonDefault`} onClick={() => history.push({ pathname: 'driver', query: { driver: notification.driverObjectId, view: 'hosEvents', date: moment.unix(notification.dateTime / 1000).format('DDMMYY') } })}>View</button></div>
                //     <div className={styles.notificationText}><b>[{ Helpers.toTitleCase(notification.driver_userFullname) }]:</b> Violation Trigger - { HOSRegulation[notification.type].rule }</div>
                //     <span className={styles.date}>{ moment.unix(notification.dateTime / 1000).format('LLLL') }</span>
                //   </div>
                // );
              } else if (notification.class === 'ELDEdit') {
                return (
                  <div key={index} className={this.getNotificationStyle(notification)} onMouseEnter={() => this.props.handleNotificationHover(notification)}>
                    <div className={styles.viewDocument}><button className={`${styles.viewDocumentButton} buttonDefault`} onClick={() => { history.push({ pathname: 'drivers', search: "view=aobrdDriverEdits" }); location.reload(); }}>View</button></div>
                    <div className={styles.notificationText}><b>[{notification.driverFullName} - Edits]:</b> {notification.driverFullName} requests your approval for edits</div>
                    <span className={styles.date}>{notification.createdAt.format('LLLL')}</span>
                  </div>
                );
              }
            })}
          </span >
          <span>
            {!this.props.fetched &&
              <div className={styles.notification} style={{ textAlign: 'center !important' }}>
                <LoadingIcon />
              </div>
            }
            {this.props.fetched && this.props.notifications.length === 0 &&
              <div className={styles.notification} style={{ textAlign: 'center' }}>
                <b>No Notifications</b>
              </div>
            }
          </span>
        </div >
      </div >
    );
  }
}

NotificationPanel.propTypes = {
  notifications: PropTypes.array.isRequired,
  handleNotificationHover: PropTypes.func.isRequired,
  fetched: PropTypes.bool,
};

export default onClickOutside(NotificationPanel);
