import { t } from 'api/Translate';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "../../../sbHistory";

// Actions
import * as Trailer from 'actions/Trailer';
import * as Vehicle from 'actions/Vehicle';

// API
import { convertDistance } from 'api/Helpers';
import * as Getters from 'api/Getters';
import CtpatLoading from 'api/Lists/CtpatLoading';
import TripInspectionType from 'api/Lists/TripInspectionType';
import TripDefectType from 'api/Lists/TripDefectType';
import { getAttribute } from 'api/Parse';
import { getTimezoneAbbreviation } from 'api/utils/timezone';

// Components
import RaisedButton from 'material-ui/RaisedButton';

import DeleteXButton from 'components/DeleteXButton/view/DeleteXButton';

// CSS
import styles from './TripInspections.module.scss';
import 'rc-time-picker/assets/index.css';

class TripInspections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicle: null,
      trailerFirst: undefined,
      trailerSecond: undefined,
      container: null,
      changesProcessing: false,
    };
  }

  async componentDidMount() {
    const { tripInspection } = this.props;
    const newState = { ...this.state };
    const vehicleUnitId = getAttribute(tripInspection, 'vehicleUnitId');
    newState.trailerFirst = getAttribute(tripInspection, 'trailerFirst');
    newState.trailerSecond = getAttribute(tripInspection, 'trailerSecond');

    if (vehicleUnitId) {
      const vehicle = await Getters.queryCompanyObjects('Vehicle', null, null, [{ name: 'unitId', value: vehicleUnitId, type: 'equalTo' }], { enabled: 'descending' }, ['licensePlate'], true);
      newState.vehicle = vehicle;
    }

    this.setState(newState);
  }

  render() {
    const { distanceUnit, tripInspection, timezoneOffsetFromUTC } = this.props;

    const isCTPATInspection = getAttribute(tripInspection, 'type') === 3;
    const ctpatLoading = getAttribute(tripInspection, 'ctpatLoading') || 0; // Defaults to Loading Not Inspected if undefined
    const ctpatHasBill = getAttribute(tripInspection, 'ctpatHasBill') || undefined;
    const ctpatInspectionPlace = getAttribute(tripInspection, 'ctpatInspectionPlace') || undefined;

    const { vehicle, trailerFirst, trailerSecond } = this.state;
    const trailerFirstLicensePlate = trailerFirst && getAttribute(trailerFirst, 'licensePlate');
    const trailerSecondLicensePlate = trailerSecond && getAttribute(trailerSecond, 'licensePlate');

    return (
      <div key={tripInspection.id} className={styles.tripInspection}>
        <div className={styles.type}>
          {t(`Driver's Vehicle Inspection Report - ${TripInspectionType[tripInspection.get('type')]}`)}
          <div className={styles.date}>{`${moment(tripInspection.get('dateTime')).tz(timezoneOffsetFromUTC).format('LLL')} (${getTimezoneAbbreviation(getAttribute(tripInspection, 'dateTime', true), timezoneOffsetFromUTC)})`}</div>

          {isCTPATInspection &&
            (
              <React.Fragment>
                <div className={styles.ctpatLoading}>{CtpatLoading[ctpatLoading]}</div>
                <div className={styles.ctpatHasBill}><b>{t('C-TPAT/PIP has Bill of Lading:')} </b>{t(ctpatHasBill ? 'Yes' : 'No')}</div>
                <div className={styles.ctpatInspectionPlace}><b>{t('C-TPAT/PIP Inspection Place:')} </b>{ctpatInspectionPlace ? ctpatInspectionPlace : 'N/A'}</div>
              </React.Fragment>
            )}

          <div className={styles.equipment}>
            <b>{t('Vehicle:')} </b>
            {tripInspection.get('vehicleUnitId') || 'N/A'}{tripInspection.get('vehiclePlate') &&
              (<span>, <b>{t('Plate:')}</b> {tripInspection.get('vehiclePlate')}</span>)
            }
            {vehicle && vehicle.get('licensePlate') && vehicle.get('licensePlate').get('stateProvince') &&
              (<span> <i>({vehicle.get('licensePlate').get('stateProvince')})</i></span>)
            }
          </div>
          {tripInspection.get('trailer1UnitId') && (
            <div className={styles.equipment}>
              <b>{t('Trailer 1:')}</b> {tripInspection.get('trailer1UnitId') || 'N/A'}{tripInspection.get('trailer1Plate') && (<span>, <b>Plate:</b> {tripInspection.get('trailer1Plate')}</span>)}
              {(tripInspection.get('trailer1UnitId') && trailerFirstLicensePlate && getAttribute(trailerFirstLicensePlate, 'stateProvince')) &&
                <span> <i>({getAttribute(trailerFirstLicensePlate, 'stateProvince')})</i></span>
              }
            </div>
          )}
          {tripInspection.get('trailer2UnitId') && (
            <div className={styles.equipment}>
              <b>{t('Trailer 2:')}</b> {tripInspection.get('trailer2UnitId') || 'N/A'}{tripInspection.get('trailer2Plate') && (<span>, <b>Plate:</b> {tripInspection.get('trailer2Plate')}</span>)}
              {(tripInspection.get('trailer2UnitId') && trailerSecondLicensePlate && getAttribute(trailerSecondLicensePlate, 'stateProvince')) &&
                <span> <i>({getAttribute(trailerSecondLicensePlate, 'stateProvince')})</i></span>
              }
            </div>
          )}
          {tripInspection.get('containerUnitId') &&
            <div className={styles.equipment}><b>{t('Container:')}</b> {tripInspection.get('containerUnitId') || 'N/A'}</div>
          }
          {tripInspection.get('seals') && tripInspection.get('seals').length > 0 && (
            <div className={styles.equipment}>
              <b>{t('Seals:')}&nbsp;</b>
              {
                tripInspection.get('seals').map((seal, index) => {
                  if (index + 1 !== tripInspection.get('seals').length) {
                    return <span key={seal.id}>{seal.get('sealId')}, </span>;
                  }
                  return <span key={seal.id}>{seal.get('sealId')}</span>;
                })
              }
            </div>
          )}

          <kbd>Notes: {tripInspection.get('notes') || 'N/A'}</kbd>

          <div className={styles.location}>
            <b>{t('Location:')}</b> {tripInspection.get('locationDescriptionCA') ? tripInspection.get('locationDescriptionCA') : tripInspection.get('locationDescriptionUS') ? tripInspection.get('locationDescriptionUS') : 'N/A'}
          </div>
          <div className={styles.odometer}>
            <b>{t('Odometer:')} </b>
            {distanceUnit === 'mi' && `${Math.round(convertDistance(tripInspection.get('odometerKm'), 'km', 'mi'))} mi`}
            {distanceUnit !== 'mi' && `${Math.round(tripInspection.get('odometerKm'))} km`}
          </div>
          <div style={{ position: 'absolute', top: '0', right: '0', textAlign: 'right' }}>
            <RaisedButton
              key={`${tripInspection.id}-edit`}
              label={t('Edit Trip Inspection')}
              onClick={() => this.props.toggleEditTripInspectionDialog(tripInspection)}
            />
            <DeleteXButton
              key={`${tripInspection.id}-disable`}
              handleClick={() => this.props.toggleEditTripInspectionDialog(tripInspection, true)}
              style={{ verticalAlign: 'middle', display: 'inline-block', marginLeft: '.5em' }}
            />
          </div>
        </div>
        {tripInspection.get('tripDefects') && tripInspection.get('tripDefects').length > 0 && (
          <table className={styles.defectsTable}><tbody>{
            tripInspection.get('tripDefects').map((tripDefect) => (
              <tr key={tripDefect.id} className={styles.row}>
                <td className={styles.defect}>{t(`Defect - ${TripDefectType.all[tripDefect.get('type')]?.type}`)}</td>
                <td className={styles.notes}>{tripDefect.get('notes')}</td>
                <td>
                  {tripDefect.get('fixed') &&
                    <div className={styles.repaired}>{t('Repaired')}</div>
                  }
                  {!tripDefect.get('fixed') &&
                    <div className={styles.unresolved}>{t('Unrepaired/Unresolved')}</div>
                  }
                </td>
                <td>
                  {tripDefect.get('fixedNotes') && (
                    <div>
                      <textarea
                        value={tripDefect.get('fixedNotes') || ''}
                        placeholder={t('Defect Fix Notes...')}
                        readOnly
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))
          }
          </tbody></table>
        )}
        {(!tripInspection.get('tripDefects') || tripInspection.get('tripDefects').length === 0) &&
          <i>{t('No Defects')}</i>
        }
      </div>
    );
  }
}

TripInspections.propTypes = {
  tripInspection: PropTypes.object.isRequired,
  vehicles: PropTypes.array.isRequired,
  eldDailyCertification: PropTypes.object,
  location: PropTypes.object.isRequired,
  driver: PropTypes.object,
  scaleToDriverTimezone: PropTypes.bool,
  toggleEditTripInspectionDialog: PropTypes.func.isRequired,
};

export default TripInspections;
