import React, { useState, useEffect } from 'react';

// API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import Skeleton from 'sbCore/Skeleton/Skeleton';
import Button from 'sbCore/Button/Button';

// Styling
import './style.scss';

/**
 * @description Display a Trailer's unit id
 * @param {Trailer} trailer - The selected Trailer record
 * @param {bool} isLoading - Whether or not to show a loading skeleton
 * @param {Function} onUnselectTrailer - Callback when the remove button is clicked. Passes back the trailer if the user wants it
 * @returns
 */

function TrailerCard({ ...props }) {

  // ** useStates ** //
  const [trailer, setTrailer] = useState(undefined);

  // ** useEffects ** //
  useEffect(() => {
    setTrailer(props.trailer);
  }, [props.trailer]);

  // ** Callbacks ** //
  function onUnselectTrailer() {
    if (props.onUnselectTrailer) {
      props.onUnselectTrailer();
    }
    setTrailer(undefined);
  }

  // ** Button Components ** //
  const unselectButton = (
    <Button
      icon="pi pi-trash"
      className="p-button-rounded p-button-danger"
      onClick={() => onUnselectTrailer()}
    />
  );

  // ** Trailer Attributes ** //
  const trailerUnitId = getAttribute(trailer, 'unitId', true);

  // ** Card Text Information ** //
  const unitIdText = (
    <div>
      <div className={`name font-semibold${!trailerUnitId ? ' text-warning' : ''}`}>{trailerUnitId || 'No Id Provided'}</div>
    </div>
  );

  // ** Loading Skeleton ** //
  const skeleton = (
    <div className="custom-skeleton">
      <div className="flex">
        <Skeleton shape="circle" size="2.625rem" className="mr-2"></Skeleton>
        <div>
          <Skeleton width="8rem" height=".6rem" className="mb-2"></Skeleton>
          <Skeleton width="5rem" height=".6rem" className="mb-2"></Skeleton>
          <Skeleton height=".4rem"></Skeleton>
        </div>
      </div>
    </div>
  );

  // ** classNames ** //
  let className = 'trailer-card';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      {!props.isLoading && (
        <div className="trailer-card">
          {unselectButton}
          {unitIdText}
        </div>
      )}
      {props.isLoading && skeleton}
    </div>
  );
}

export default TrailerCard;
