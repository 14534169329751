const DispatchReferenceTypes = Object.freeze({
  'JOB': {
    value: 0,
    description: 'Job Ref #',
    key: 'JOB',
  },
  'CUSTOMER': {
    value: 1,
    description: 'Cust. Ref #',
    key: 'CUSTOMER',
  },
  'POD': {
    value: 2,
    description: 'POD #',
    key: 'POD',
  },
  'BOL': {
    value: 3,
    description: 'BOL #',
    key: 'BOL',
  },
  'CUSTOM': {
    value: 4,
    description: 'Custom',
    key: 'CUSTOM',
  },
});

const refTypeValues = ['JOB', 'CUSTOMER', 'POD', 'BOL', 'CUSTOM'];

const getRefDescriptionByTypeValue = (refTypeInt) => {
  if (refTypeInt === undefined) return undefined;
  if (refTypeInt < 0 || refTypeInt >= refTypeValues.length) return undefined;
  return DispatchReferenceTypes[refTypeValues[refTypeInt]].description;
};

export {
  DispatchReferenceTypes,
  getRefDescriptionByTypeValue,
};
