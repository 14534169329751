import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from "sbHistory";

// API
import { getVehicleById } from 'api/Getters';
import * as Helpers from 'api/Helpers';
import { getQueryParameter } from 'api/URL';

// Components
import Sidebar from './Sidebar';
import SummaryLayout from './SummaryLayout';
import GeofenceEventsLayout from './GeofenceEventsLayout';

// CSS
import styles from './VehicleDetailsLayout.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

class VehicleDetailsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Loading...',
      subViewSelected: getQueryParameter(props.location.search, "view")
    };
    this.handleSubViewClick = this.handleSubViewClick.bind(this);
    this.refreshVehicleInfo = this.refreshVehicleInfo.bind(this);
    this.toggleWaitingState = this.toggleWaitingState.bind(this);
  }

  componentDidMount() {
    document.title = 'Vehicle Details - Switchboard';
    let oldVehicle = getQueryParameter(this.props.location.search, 'vehicle');
    getVehicleById(oldVehicle).then(vehicleObject => {
      this.refreshVehicleInfo(undefined, vehicleObject);
    });
  }

  componentWillReceiveProps(nextProps) {
    let newVehicle = getQueryParameter(nextProps.location.search, 'vehicle');
    let oldVehicle = getQueryParameter(this.props.location.search, 'vehicle');
    if (newVehicle !== oldVehicle) {
      this.refreshVehicleInfo(newVehicle);
    }
    let newView = getQueryParameter(nextProps.location.search, 'view');
    this.setState({ ...this.state, subViewSelected: newView, eldModuleSubscribed: Helpers.isSubscribedToModule('eldModule') });
  }

  handleSubViewClick(subview) {
    let oldVehicle = getQueryParameter(this.props.location.search, 'vehicle');
    history.push({ pathname: 'vehicle', search: "vehicle=" + oldVehicle + "&view=" + subview });
  }

  refreshVehicleInfo(vehicleObjectId, vehicleObject) {
    // if we're supplied the vehicleObject, no need to re-query
    if (vehicleObject) {
      if (vehicleObject.get('isHidden')) {
        return history.push('/equipment');
      }
      this.setState({ ...this.state, title: vehicleObject.get('unitId'), vehicleObj: vehicleObject });
      return;
    }

    getVehicleById(vehicleObjectId).then((pVehicleObject) => {
      if (pVehicleObject.get('isHidden')) {
        return history.push('/equipment');
      }
      this.setState({ ...this.state, title: pVehicleObject.get('unitId'), vehicleObj: pVehicleObject }, () => {
      });
    });
  }

  toggleWaitingState() {
    const promise = new Promise((resolve) => {
      this.setState({ ...this.state, vehicleObj: undefined }, () => resolve(true));
    });
    return promise;
  }

  render() {
    const { Company } = this.props;
    const { eldModuleSubscribed, vehicleObj, subViewSelected } = this.state;
    return (
      <div>
        <Sidebar
          eldModuleSubscribed={eldModuleSubscribed}
          vehicle={vehicleObj}
          activeSubview={subViewSelected}
          handleSubViewClick={this.handleSubViewClick}
          title={this.state.title}
        />
        <div className={styles.content}>
          {(subViewSelected === 'summary' || !subViewSelected) &&
            <SummaryLayout
              company={Company && Company.company}
              vehicle={vehicleObj}
              refreshVehicleInfo={this.refreshVehicleInfo}
              toggleWaitingState={this.toggleWaitingState}
            />
          }
          {subViewSelected === 'geofenceEvents' && vehicleObj &&
            <GeofenceEventsLayout
              location={this.props.location}
              vehicle={vehicleObj}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { Company } = state;
  return {
    Company,
  };
};


VehicleDetailsLayout.propTypes = {
  location: PropTypes.object.isRequired,
  Company: PropTypes.object,
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(VehicleDetailsLayout);

