import { AutoComplete as PRAutoComplete } from 'primereact/autocomplete';

import './style.scss';

function Autocomplete({ warning, error, sbVariant, ...props }) {
  let className = 'sbc-autocomplete';

  if (sbVariant === 'fill-container') className += ' sb-variant-fill';

  if (error) {
    className += ` sbc-error`;
  } else if (warning) {
    className += ` sbc-warning`;
  }

  return (<PRAutoComplete { ...props } className={className} />);
}

export default Autocomplete;
