import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { connect } from 'react-redux';
import history from "../../sbHistory";

// API
import * as Main from 'actions/Main';
import * as Helpers from 'api/Helpers';
import { warningIgnoreTrigger, getSubscriptionStatus, renewSubscription } from 'actions/Subscription';

// Components
import LoginLayout from './LoginLayout';
import StartLayout from './StartLayout';
import UpsellLayout from './UpsellLayout';

// CSS
import styles from './UpgradeLayout.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

class UpgradeLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'login',
    };

    this.handleLoginSuccess = this.handleLoginSuccess.bind(this);
  }

  componentDidMount() {
    document.title = 'Upgrade - Switchboard';
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.Subscription.subscription !== this.props.Subscription.subscription) {
    //   getSubscriptionStatus(nextProps.Subscription.subscription, nextProps.Subscription.warningIgnore).then((paymentStatusObj) => {
    //     const newState = { ...this.state };
    //     newState.paymentModalType = paymentStatusObj.type;
    //     if (nextProps.Subscription.subscription.get('currency')) {
    //       this.setState(newState);
    //     } else {
    //       axios.get('http://freegeoip.net/json/').then((response) => {
    //         if (response.data && response.data.country_code === 'CA') {
    //           newState.currency = 'CAD';
    //           this.setState(newState);
    //         }
    //       }, () => {
    //         this.setState(newState);
    //       });
    //     }
    //   });
    // }
  }

  handleLoginSuccess() {
    Main.fetchAndLoadUserGlobalsForState().then(() => {
      // If eldModule === false && no customerId, Starting out
      // if (!Helpers.isSubscribedToModule('eldModule')) {
      //   this.setState({ ...this.state, view: 'start' });
      // } else {
      //   // Upgrade
      //   this.setState({ ...this.state, view: 'upsell' });
      // }
      this.setState({ ...this.state, view: 'start' });
    });
  }

  render() {
    return (
      <div className={styles.content}>
        { this.state.view !== 'login' &&
          <div className={styles.stripes}>
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
        }
        <div className={styles.stripeContent}>
          { this.state.view === 'start' &&
            <StartLayout />
          }
          { this.state.view === 'upsell' &&
            <UpsellLayout />
          }
          { this.state.view === 'login' &&
            <LoginLayout handleLoginSuccess={this.handleLoginSuccess} />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { Subscription } = state;
  return {
    Subscription,
  };
};

UpgradeLayout.propTypes = {
  Subscription: PropTypes.object.isRequired,
  // location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(UpgradeLayout);

