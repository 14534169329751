import React, { useState, useEffect, useContext } from 'react';

// scapi
import { getAttribute } from 'sb-csapi/dist/AAPI';

// sbcore components
import Button from 'sbCore/Button/Button';
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import Skeleton from 'sbCore/Skeleton/Skeleton';
import Checkbox from 'sbCore/Checkbox/Checkbox';
import InputText from 'sbCore/InputText/InputText';

// api
import { updateDispatchJob, generateBatchId } from 'api/Dispatch/DispatchJob';

// enums
import { Organization } from 'sb-csapi/dist/enums/Dispatch/Organization';
import { Status } from 'sb-csapi/dist/enums/Dispatch/Job';

// components
import DispatchOrganizationAutocomplete from 'components/Dispatch/DispatchOrganizationAutocomplete/DispatchOrganizationAutocomplete';
import DispatchContactAutocomplete from 'components/Dispatch/DispatchContactAutocomplete/DispatchContactAutocomplete';

// Context
import DispatchJobLayoutContext from 'contexts/DispatchJobLayoutContext';

// style
import './style.scss';

/**
 * 
 * @param {DispatchJob} dispatchJob - Record with the DispatchJob
 *
 * @returns
 */
function GeneralInformationSubsection({ dispatchJob }) {
  // ** Context ** //
  const { refreshJob } = useContext(DispatchJobLayoutContext);

  // ** Hooks ** //
  const [customerDispatchOrganization, setCustomerDispatchOrganization] = useState(getAttribute(dispatchJob, 'customerDispatchOrganization', true));
  const [selectedCustomerDispatchOrganization, setSelectedCustomerDispatchOrganization] = useState(getAttribute(dispatchJob, 'customerDispatchOrganization', true));
  const [dispatchJobStateObj, setDispatchJobStateObj] = useState({
    customerDispatchOrganizationContact: getAttribute(dispatchJob, 'customerDispatchOrganizationContact', true),
    status: getAttribute(dispatchJob, 'status', true),
    parsNumber: getAttribute(dispatchJob, 'parsNumber', true),
    papsNumber: getAttribute(dispatchJob, 'papsNumber', true),
    isCTPAT: getAttribute(dispatchJob, 'isCTPAT', true),
    isHAZMAT: getAttribute(dispatchJob, 'isHAZMAT', true),
    isCSA: getAttribute(dispatchJob, 'isCSA', true),
  });

  const [isEditMode, setIsEditMode] = useState(true);

  // ** Functions ** //
  function updateDispatchJobObjState(attribute, value) {
    setDispatchJobStateObj({ ...dispatchJobStateObj, [attribute]: value });
  }

  // ** UseEffects ** //
  useEffect(() => {
    setIsEditMode(false); // this prevents bug where contact info is not displayed
  }, []);

  useEffect(() => {
    setCustomerDispatchOrganization(getAttribute(dispatchJob, 'customerDispatchOrganization', true));
    setDispatchJobStateObj({
      customerDispatchOrganizationContact: getAttribute(dispatchJob, 'customerDispatchOrganizationContact', true),
      status: getAttribute(dispatchJob, 'status', true),
      parsNumber: getAttribute(dispatchJob, 'parsNumber', true),
      papsNumber: getAttribute(dispatchJob, 'papsNumber', true),
      isCTPAT: getAttribute(dispatchJob, 'isCTPAT', true),
      isHAZMAT: getAttribute(dispatchJob, 'isHAZMAT', true),
      isCSA: getAttribute(dispatchJob, 'isCSA', true),
    });
    // if (getAttribute(dispatchJob, 'customerDispatchOrganizationContact', true)) setIsEditMode(false);
  }, [dispatchJob]);

  useEffect(() => {
    let didCancel = false;

    async function _updateDispatchJob() {
      const batchId = await generateBatchId(selectedCustomerDispatchOrganization, dispatchJob);
      await updateDispatchJob(dispatchJob, undefined, { batchId, customerDispatchOrganization: selectedCustomerDispatchOrganization, isInvoiceOutdate: true }, true);
      if (!didCancel) {
        setCustomerDispatchOrganization(selectedCustomerDispatchOrganization);
      }
      await refreshJob();
    }

    _updateDispatchJob();

    return () => { didCancel = true };
  }, [selectedCustomerDispatchOrganization]);

  useEffect(() => {
    if (!dispatchJob) return;

    async function _updateDispatchJob() {
      await updateDispatchJob(dispatchJob, undefined, dispatchJobStateObj, true);
    }

    _updateDispatchJob();
  }, [dispatchJobStateObj]);

  // ** Loading templates ** //
  let customerDispatchOrganizationContactTemplate = (
    <Skeleton width="10rem" height="2rem" className="p-mb-2" />
  );

  let parsNumberTemplate = (
    <Skeleton width="10rem" height="2rem" className="p-mb-2" />
  );

  let papsNumberTemplate = (
    <Skeleton width="10rem" height="2rem" className="p-mb-2" />
  );

  let ctpatCheckboxTemplate = (
    <Skeleton width="2rem" className="p-mb-2 mt-7" />
  );

  let hazmatCheckboxTemplate = (
    <Skeleton width="2rem" className="p-mb-2 mt-7" />
  );

  let csaCheckboxTemplate = (
    <Skeleton width="2rem" className="p-mb-2 mt-7" />
  );

  // ** Field Templates ** //
  customerDispatchOrganizationContactTemplate = (
    <DispatchContactAutocomplete
      className="p-inputtext-sm"
      dispatchJob={dispatchJob}
      customerDispatchOrganization={customerDispatchOrganization}
      onSelectDispatchContact={(contact) => {
        updateDispatchJobObjState('customerDispatchOrganizationContact', contact);
        // Toggle to update document when contact field updates
      }}
      dispatchContact={dispatchJobStateObj.customerDispatchOrganizationContact}
      warning
      disabled={!customerDispatchOrganization}
      allowAddDispatchOrganizationContact={customerDispatchOrganization}
      isLoading={!dispatchJob}
      hideLabel
    />
  );

  parsNumberTemplate = (
    <InputText
      type="text"
      className="p-inputtext-sm p-d-block p-mb-2"
      success={dispatchJobStateObj.parsNumber}
      value={dispatchJobStateObj.parsNumber}
      onChange={(e) => updateDispatchJobObjState('parsNumber', e.target.value)}
    />
  );

  papsNumberTemplate = (
    <InputText
      type="text"
      className="p-inputtext-sm p-d-block p-mb-2"
      success={dispatchJobStateObj.papsNumber}
      value={dispatchJobStateObj.papsNumber}
      onChange={(e) => updateDispatchJobObjState('papsNumber', e.target.value)}
    />
  );

  ctpatCheckboxTemplate = (
    <div className="field-checkbox">
      CTPAT
      <Checkbox
        inputId="ctpat-checkbox"
        checked={dispatchJobStateObj.isCTPAT}
        onChange={(e) => updateDispatchJobObjState('isCTPAT', e.checked)}
        className="ml-1"
      />
    </div>
  );

  hazmatCheckboxTemplate = (
    <div className="field-checkbox">
      HAZMAT
      <Checkbox
        inputId="hazmat-checkbox"
        checked={dispatchJobStateObj.isHAZMAT}
        onChange={(e) => updateDispatchJobObjState('isHAZMAT', e.checked)}
        className="ml-1"
      />
    </div>
  );

  csaCheckboxTemplate = (
    <div className="field-checkbox">
      CSA
      <Checkbox
        inputId="csa-checkbox"
        checked={dispatchJobStateObj.isCSA}
        onChange={(e) => updateDispatchJobObjState('isCSA', e.checked)}
        className="ml-1"
      />
    </div>
  );

  function inPlaceButton(fieldName, field, isCheckbox = false) {
    let className = 'general-information-field';

    if (isCheckbox) {
      const checkboxArray = [];
      if (dispatchJobStateObj.isCTPAT) checkboxArray.push(' CTPAT');
      if (dispatchJobStateObj.isHAZMAT) checkboxArray.push(' HAZMAT');
      if (dispatchJobStateObj.isCSA) checkboxArray.push(' CSA');
      if (checkboxArray.length > 0) field = checkboxArray.toString();
      className = 'general-information-field mb-0';
    }
    return (
      <div className={className}>
        <span className="quote-label">{fieldName}</span>
        <span>{field ? field : '-'}</span>
      </div>
    );
  }

  const titleTemplate = (
    <div className="section-header">
      <span>1. General Information</span>
      {isEditMode ? <Button className="p-button-link p-button-secondary" label="Save" onClick={() => setIsEditMode(false)} /> : <i className="pi pi-pencil toggle-field" onClick={() => setIsEditMode(true)} />}
    </div>
  );

  // ** Section Templates ** //
  const inPlaceGeneralInfoPanelTemplate = (
    <ColoredSection
      className="general-information-subsection"
      noFooter
      title={titleTemplate}
      variant="compact"
    >
      { !isEditMode ?
        inPlaceButton('Customer', getAttribute(customerDispatchOrganization, 'organizationName', true))
        :
        (
          <div className="general-information-field">
            <span className="quote-label">Customer*: </span>
            <DispatchOrganizationAutocomplete
              className="p-inputtext-sm"
              type={Organization.CUSTOMER}
              dispatchOrganization={customerDispatchOrganization}
              onSelectDispatchOrganization={(customerDispatchOrganization) => setSelectedCustomerDispatchOrganization(customerDispatchOrganization)}
              warning
              allowAddDispatchOrganization
              isLoading={!dispatchJob}
              hideLabel
            />
          </div>
        )
      }
      { (!isEditMode ?
        inPlaceButton('Contact', getAttribute(dispatchJobStateObj.customerDispatchOrganizationContact, 'name', true), false)
        :
        (
          <div className="general-information-field">
            <span className="quote-label">Contact*: </span>
            {customerDispatchOrganizationContactTemplate}
          </div>
        )
      )
      }
      {!isEditMode ?
        inPlaceButton('PARS Number', dispatchJobStateObj.parsNumber)
        :
        (
          <div className="general-information-field">
            <span className="quote-label">PARS #: </span>
            {parsNumberTemplate}
          </div>
        )
      }
      {!isEditMode ?
        inPlaceButton('PAPS Number', dispatchJobStateObj.papsNumber)
        :
        (
          <div className="general-information-field">
            <span className="quote-label">PAPS #: </span>
            {papsNumberTemplate}
          </div>
        )
      }
      { !isEditMode ?
        inPlaceButton('CTPAT/HAZMAT/CSA', '', true)
        :
        (
          <div className="general-information-field general-information-checkboxes">
            {ctpatCheckboxTemplate}
            {hazmatCheckboxTemplate}
            {csaCheckboxTemplate}
          </div>
        )
      }
    </ColoredSection>
  );

  return (
    <div>
      { inPlaceGeneralInfoPanelTemplate }
    </div>
  );
}

export default GeneralInformationSubsection;
