import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// sb-csapi
// import { isFlagOn } from 'sb-csapi/dist/api/Analytics/Flagging';
import { getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId } from 'sb-csapi/dist/AAPI';
import { getVehicleLocations, deriveVehicleLocation, filterVehicleLocations } from 'sb-csapi/dist/api/VehicleLocation/VehicleLocation';
import { getTimezoneAbbreviation } from 'sb-csapi/dist/utils/Time';

// components
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';

// enums
import { LengthUnit, LengthConversion } from 'sb-csapi/dist/enums/Unit';
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// styles
import './style.scss';

/**
 * @description List of trips for a vehicle on a given day
 * @param {String} dayData - The IFTA data for the vehicle and specific day
 * @param {Function} [onSelect] - Propagtes the selected trip to the parent
 * @param {String} [lengthUnit] - From enums LengthUnit - KM, MI
 * @param {String} [scrollHeight] - Scroll height of the table
 */
function VehicleTripTable({ ...props }) {

  const [dayData, setDayData] = useState(props.dayData || {});
  const [selectedIFTARouteObj, setSelectedIFTARouteObj] = useState(undefined);
  const [derivedVehicleLocations, setDerivedVehicleLocations] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);

  useEffect(() => {
    const _dayData = props.dayData;
    if (!_dayData) return;

    let didCancel = false;
    async function init() {
      setIsLoading(true);

      if (!didCancel) {
        setDayData(_dayData);
        setIsLoading(false);
      }
    }

    init();

    return () => { didCancel = true; };
  }, [props.dayData]);

  useEffect(() => {
    generateTableColumns();
  }, [dayData]);

  /**
   * Functions
   */
  async function onSelect(iftaRouteObj) {
    setIsLoading(true);
    setSelectedIFTARouteObj(iftaRouteObj);

    const { derivedVehicleLocationStart, derivedVehicleLocationEnd } = iftaRouteObj;

    // let's get all points that were driven along that path between the start and end locations
    const vehicleLocationFilters = [
      new Filter(QueryRestriction.LESS_THAN, 'dateTime', derivedVehicleLocationEnd.dateTime),
      new Filter(QueryRestriction.GREATER_THAN, 'dateTime', derivedVehicleLocationStart.dateTime),
      new Filter(QueryRestriction.EQUAL_TO, 'vehicleUnitId', iftaRouteObj.vehicleUnitId),
    ];

    const { vehicleLocations } = await getVehicleLocations(
      { sessionToken: getCurrentUserSessionToken() },
      getCurrentUserCompanyObjectId(),
      false, // include child companies
      vehicleLocationFilters,
      undefined, // default sort ascending dateTime
      undefined, // includes
      ['dateTime', 'location', 'locationDescriptionCA', 'locationDescriptionUS'], // selects
      true, // queryAlls
    );

    let filteredVehicleLocations = [...vehicleLocations];

    // const isDetectVehicleLocationOutlierFlagOn = isFlagOn('flag-web-2427-detect-vehicle-location-outliers');
    const isDetectVehicleLocationOutlierFlagOn = true;

    if (isDetectVehicleLocationOutlierFlagOn) {
      const _filteredVehicleLocations = filterVehicleLocations(vehicleLocations, { showDebug: false, debugLevel: 2 });
      filteredVehicleLocations = _filteredVehicleLocations.vehicleLocations || [];
    }

    let derivedVehicleLocations = filteredVehicleLocations.map(vehicleLocation => deriveVehicleLocation(vehicleLocation));
    derivedVehicleLocations = [derivedVehicleLocationStart, ...derivedVehicleLocations, derivedVehicleLocationEnd];

    // Filter out bad locations
    derivedVehicleLocations = derivedVehicleLocations.filter(derivedVehicleLocation => derivedVehicleLocation._isValidGPSLocation);
    setDerivedVehicleLocations(derivedVehicleLocations);

    if (props.onSelect) props.onSelect(iftaRouteObj, derivedVehicleLocations);
    setIsLoading(false);
  }

  /**
   * Template/JSX Functions
   */
  function stateProvinceTemplate(rowData) {
    const { stateProvince } = rowData;
    return (
      <div className="font-bold uppercase">{stateProvince}</div>
    );
  }

  function tripTemplate(rowData) {
    const { derivedVehicleLocationStart, derivedVehicleLocationEnd } = rowData;
    const tripStartJSX = (
      <div className="inline-block">
        {`${derivedVehicleLocationStart.aprxShortName}, ${derivedVehicleLocationStart.stateProvince.toUpperCase()}`}
      </div>
    );

    const tripEndJSX = (
      <div className="inline-block">
        {`${derivedVehicleLocationEnd.aprxShortName}, ${derivedVehicleLocationEnd.stateProvince.toUpperCase()}`}
      </div>
    );

    return (
      <div>
        {tripStartJSX}
        <span className="pi pi-arrow-right mx-2" style={{ fontSize: '.65em' }} />
        {tripEndJSX}
      </div>
    );
  }

  function timeTemplate(rowData) {
    const { derivedVehicleLocationStart, derivedVehicleLocationEnd } = rowData;
    const timeStartJSX = (
      <div className="inline-block">
        {`${moment(derivedVehicleLocationStart.dateTime).format('HH:mm')}`}
      </div>
    );

    const timeEndJSX = (
      <div className="inline-block">
        {`${moment(derivedVehicleLocationEnd.dateTime).format('HH:mm')}`}
      </div>
    );

    return (
      <div>
        {timeStartJSX}
        <span className="mx-1">-</span>
        {timeEndJSX}
      </div>
    );
  }

  function distanceTravelledTemplate(rowData) {
    // Keep conversion in parody with IFTAVehicleJurisdictionDataTable: generateVehicleJurisdictionMap();
    const { iftaRoute } = rowData;

    let distanceTravelledKm = 0;
    let totalVehicleKmStart = getAttribute(iftaRoute, 'totalVehicleKmStart');
    let totalVehicleKmEnd = getAttribute(iftaRoute, 'totalVehicleKmEnd');

    if (props.lengthUnit === LengthUnit.MI) {
      totalVehicleKmStart *= LengthConversion.KM2MI;
      totalVehicleKmEnd *= LengthConversion.KM2MI;
    }

    distanceTravelledKm = Math.floor(Math.floor(totalVehicleKmEnd) - Math.floor(totalVehicleKmStart));

    if (distanceTravelledKm === 0) distanceTravelledKm = '-';

    return (<div>{distanceTravelledKm}</div>);
  }

  function generateTableColumns() {
    const _tableColumns = [];

    // First, the row for select button and for Unit Ids
    _tableColumns.push(
      <Column
        key="selector"
        selectionMode="single"
        headerStyle={{ maxWidth: '50px' }}
        frozen
      />,
      <Column
        key="stateProvince"
        field="null"
        header="State/Province"
        body={(rowData) => stateProvinceTemplate(rowData)}
        headerStyle={{ minWidth: '30px' }}
        frozen
      />,
      <Column
        key="location"
        field="null"
        header="Location"
        body={(rowData) => tripTemplate(rowData)}
        headerStyle={{ minWidth: '250px' }}
      />,
      <Column
        key="time"
        field="null"
        header={`Time (${getTimezoneAbbreviation(moment())})`}
        body={(rowData) => timeTemplate(rowData)}
        headerStyle={{ minWidth: '135px' }}
      />,
      // <Column
      //   key="fuelling"
      //   field="null"
      //   header="Fuel Pumped"
      //   body={(rowData) => dateTemplate(rowData)}
      //   headerStyle={{ minWidth: '130px' }}
      // />,
      <Column
        key="distanceTravelled"
        field="null"
        header={`Distance Travelled (${props.lengthUnit || LengthUnit.KM})`}
        body={(rowData) => distanceTravelledTemplate(rowData)}
      />,
    );

    setTableColumns(_tableColumns);
  }

  let className = 'ifta-vehicle-trip-table';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <DataTable
        value={dayData.iftaRouteObjs || []}
        selection={selectedIFTARouteObj}
        onSelectionChange={(e) => onSelect(e.value)}
        scrollable
        scrollHeight={props.scrollHeight || '40vh'}
      >
        {tableColumns}
      </DataTable>
    </div>
  );
}

export default VehicleTripTable;
