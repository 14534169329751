import React from 'react';

import { Chips as PRChips } from 'primereact/chips';

import './style.scss';

/**
 * @description An input label that accepts text
 */
export default function Chips({ warning, error, ...props }) {
  const className = 'sbc-chips';

  return (<PRChips {...props} className={className} />);
}
