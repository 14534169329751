import React, { createContext, useRef } from 'react';

// sbCore Components
import Toast from 'sbCore/Toast/Toast';

/**
 * @description Default export which creates a context to allow the sharing of state to any child component
 * @returns
 *
 * @example
 * useContext(ToastContext)
 */
const ToastContext = createContext();
export default ToastContext;

/**
 * @description Context Provider which renders custom toasts
 * @param {Object} children - The components which are children to the ToastContext
 * @returns
 *
 * @example
 * <ToastContextProvider> ... </ToastContextProvider>
 */
export function ToastContextProvider({ children }) {
  const toastRef = useRef(null);

  // Render a toast given custom toast settings.
  // There are four severity types which sets the colour of the toast: success, info, warn, and error
  // Life is the duration of the toast in milliseconds, and sticky is a boolean for whether or not the
  // toast should stay on screen until the user manually removes it
  function addToast(toastSettings) {
    const _toastSettings = {
      severity: toastSettings.severity,
      life: toastSettings.life,
      sticky: toastSettings.sticky,
      // toast-variant-*** class provides different variants to the toast
      // toast variant css can be found int sbCore/Toast/style.scss
      className: `${toastSettings.className} toast-variant-${toastSettings.variant ? toastSettings.variant : 'default'}`,
      contentClassName: toastSettings.contentClassName,
    };

    // Use content to create custom html/string toasts
    if (toastSettings.content) {
      _toastSettings.content = toastSettings.content;
    } else {
      // If all that's needed is to display a header and body, use summary and detail, respectively
      _toastSettings.summary = toastSettings.summary;
      _toastSettings.detail = toastSettings.detail;
    }

    toastRef.current.show(_toastSettings);
  }

  // Clear all toasts currently visible
  function clearToasts() {
    toastRef.current.clear();
  }

  return (
    <ToastContext.Provider value={{ addToast, clearToasts }}>
      <Toast innerRef={toastRef} />
      {children}
    </ToastContext.Provider>
  );
}
