import React from 'react';

// sbcore components
import FileUpload from 'sbCore/FileUpload/FileUpload';

// styling
import './style.scss';

/**
 * @description custom toolbar for email which includes uploading files
 * @param {FileUploadHandler} fileUploadHandler - Function which handles uploading the chosen files
 * @returns
 *
 * @example
 * <EmailEditorToolBar fileUploadHandler={uploadHandler()} />
 */
const EmailEditorToolbar = (props) => {

  const uploadButton = { label: 'Upload', icon: 'pi pi-upload' };

  // Using className rather than class for some of the buttons causes the styling to bug out, so I'll leave as is for now
  return (
    <span>
      <span className="ql-formats" aria-label="Font size">
        <select class="ql-size">
          <option value="small"></option>
          <option selected></option>
          <option value="large"></option>
          <option value="huge"></option>
        </select>
      </span>

      <span class="ql-formats" aria-label="Font">
        <select class="ql-font">
          <option selected></option>
          <option value="serif"></option>
          <option value="monospace"></option>
        </select>
      </span>

      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
        <button class="ql-strike" aria-label="Strikethrough"></button>
      </span>

      <span className="ql-formats">
        <button class="ql-script" value="sub" aria-label="Subscript"></button>
        <button class="ql-script" value="super" aria-label="Superscript"></button>
      </span>

      <span class="ql-formats" aria-label="Text color">
        <select class="ql-color">
          <option selected></option>
          <option value="#e60000"></option>
          <option value="#ff9900"></option>
          <option value="#ffff00"></option>
          <option value="#008a00"></option>
          <option value="#0066cc"></option>
          <option value="#9933ff"></option>
          <option value="#ffffff"></option>
          <option value="#facccc"></option>
          <option value="#ffebcc"></option>
          <option value="#ffffcc"></option>
          <option value="#cce8cc"></option>
          <option value="#cce0f5"></option>
          <option value="#ebd6ff"></option>
          <option value="#bbbbbb"></option>
          <option value="#f06666"></option>
          <option value="#ffc266"></option>
          <option value="#ffff66"></option>
          <option value="#66b966"></option>
          <option value="#66a3e0"></option>
          <option value="#c285ff"></option>
          <option value="#888888"></option>
          <option value="#a10000"></option>
          <option value="#b26b00"></option>
          <option value="#b2b200"></option>
          <option value="#006100"></option>
          <option value="#0047b2"></option>
          <option value="#6b24b2"></option>
          <option value="#444444"></option>
          <option value="#5c0000"></option>
          <option value="#663d00"></option>
          <option value="#666600"></option>
          <option value="#003700"></option>
          <option value="#002966"></option>
          <option value="#3d1466"></option>
        </select>
      </span>

      <span class="ql-formats" aria-label="Background color">
        <select class="ql-background">
          <option value="#000000"></option>
          <option value="#e60000"></option>
          <option value="#ff9900"></option>
          <option value="#ffff00"></option>
          <option value="#008a00"></option>
          <option value="#0066cc"></option>
          <option value="#9933ff"></option>
          <option selected></option>
          <option value="#facccc"></option>
          <option value="#ffebcc"></option>
          <option value="#ffffcc"></option>
          <option value="#cce8cc"></option>
          <option value="#cce0f5"></option>
          <option value="#ebd6ff"></option>
          <option value="#bbbbbb"></option>
          <option value="#f06666"></option>
          <option value="#ffc266"></option>
          <option value="#ffff66"></option>
          <option value="#66b966"></option>
          <option value="#66a3e0"></option>
          <option value="#c285ff"></option>
          <option value="#888888"></option>
          <option value="#a10000"></option>
          <option value="#b26b00"></option>
          <option value="#b2b200"></option>
          <option value="#006100"></option>
          <option value="#0047b2"></option>
          <option value="#6b24b2"></option>
          <option value="#444444"></option>
          <option value="#5c0000"></option>
          <option value="#663d00"></option>
          <option value="#666600"></option>
          <option value="#003700"></option>
          <option value="#002966"></option>
          <option value="#3d1466"></option>
        </select>
      </span>


      <span class="ql-formats">
        <button class="ql-list" value="ordered"></button>
        <button class="ql-list" value="bullet"></button>
        <select class="ql-align" aria-label="Alignment">
          <option selected></option>
          <option value="center"></option>
          <option value="justify"></option>
          <option value="right"></option>
        </select>
      </span>


      <span class="ql-formats">
        <button class="ql-clean" aria-label="Clear all formatting"></button>
      </span>

      <span class="ql-formats">
        <button class="ql-link" aria-label="Link"></button>
        <button class="ql-image" aria-label="Image"></button>
        <button class="ql-video" aria-label="Video"></button>
      </span>

      <span class="ql-formats">
        <div className="file-uploader">
          <FileUpload
            name="file uploader"
            mode="basic"
            customUpload
            uploadHandler={(e) => { props.fileUploadHandler(e) }}
            multiple
            auto
            chooseOptions={uploadButton}
          />
        </div>
      </span>
    </span>
  );

}

export default EmailEditorToolbar;