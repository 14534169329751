import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';


// enums
import { Canada, United_States } from 'sb-csapi/dist/enums/StateProvince';

// sbcore components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Dropdown from 'sbCore/Dropdown/Dropdown';

// styles
import './style.scss';

/**
 * @description Select a StateProvince
 * @param {String} className - Custom container className
 * @param {Object} style - Custom inline styles
 * @param {bool} warning - Makes the border yellow
 * @param {bool} autoFocus - Whether to automatically focus on the autocomplete on load
 * @param {string} stateProvince - Sets the stateProvince value to this by default. The string provided must be recognized in our enums/StateProvince
 * @param {bool} isFocusedDefault - Whether this component is the first component to be focused on. Determines if we should set isFocused to true when mounting
 * @param {bool} showOnFocus - Whether to automatically open the dropdown on focus
 * @param {bool} hideLabel - Hide the label
 * @param {bool} disabled - TODO: Whether disable the component
 * @param {Function} onSelect - A callback function that returns the selected stateProvince
 * @returns
 */
export default function StateProvinceDropdown({ ...props }) {
  const stateProvincesCanada = Object.keys(Canada).sort().map(stateProvince => Canada[stateProvince]); // canada stateprovince objects
  const stateProvincesUS = Object.keys(United_States).sort().map(stateProvince => United_States[stateProvince]);
  const _stateProvinces = [...stateProvincesCanada, ...stateProvincesUS];

  const [identifier] = useState(uniqid()); // for each of this component that exists on the same page, give it unique identifier for specific dom manipulation
  const [selectedStateProvince, setSelectedStateProvince] = useState(undefined); // the selected stateProvince
  const [stateProvinces, setStateProvinces] = useState(_stateProvinces);
  const [isFocused, setIsFocused] = useState(props.isFocusedDefault);
  const [hasError, setHasError] = useState(false); // if there is an error with the input

  // ** Hooks Section ** //
  useEffect(() => {
    let defaultStateProvince;
    if (props.stateProvince) {
      if (Canada[props.stateProvince.toUpperCase()]) {
        defaultStateProvince = Canada[props.stateProvince.toUpperCase()];
      } else if (United_States[props.stateProvince.toUpperCase()]) {
        defaultStateProvince = United_States[props.stateProvince.toUpperCase()];
      }
    }
    setSelectedStateProvince(defaultStateProvince);
  }, []);

  useEffect(() => {
    let defaultStateProvince = props.stateProvince;
    if (defaultStateProvince) {
      if (Canada[props.stateProvince.toUpperCase()]) {
        defaultStateProvince = Canada[props.stateProvince.toUpperCase()];
      } else if (United_States[props.stateProvince.toUpperCase()]) {
        defaultStateProvince = United_States[props.stateProvince.toUpperCase()];
      } else {
        defaultStateProvince = undefined;
      }
    }
    setSelectedStateProvince(defaultStateProvince);
  }, [props.stateProvince]);


  // ** Components Section ** //
  function onChange(e) {
    setSelectedStateProvince(e.value);
    if (props.onSelect) props.onSelect(e.value); // send the chosen stateProvince object back up to parent
  }

  function toggleFocus() {
    // determine whether or not this component is focused on, to enable additional functionality
    setIsFocused(!isFocused);
  }

  // if the component is focused, allow it to be prompted by keypresses
  // if (isFocused) {
  //   const inputButtonEl = document.querySelector(`.timezone-dropdown.${identifier} .p-dropdown .p-dropdown-trigger`);
  //   const dropdownPanelEl = document.querySelector(`.timezone-dropdown.${identifier} .p-dropdown-panel .p-dropdown-items`);
  //   if (inputButtonEl && !dropdownPanelEl) {
  //     if (window.sbKeydownEvent69.keyCode === 40) {
  //       inputButtonEl.click();
  //     }

  //     console.log(dropdownPanelEl);
  //     console.log(window.sbKeydownEvent69);
  //   }
  // }

  function itemTemplate(option, props) {
    return (
      <div>
        {option.code} - {option.name}
      </div>
    );
  }


  // // ** Misc ** //
  let className = `stateprovince-dropdown ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  // // if the input is not being focused on (which is for some reason dictated by the button's focus), but the user left their search term without selecting a suggestion for the searchTerm
  // const isNotFocusedWithText = inputButtonEl && searchTerm && ((typeof searchTerm) !== 'object') && (document.activeElement !== inputButtonEl);
  // const _hasError = isNotFocusedWithText || hasError;
  const _hasError = false;

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && (
        <InputLabel>State / Province</InputLabel>
      )}
      <Dropdown
        className={props.dropdownClassName}
        value={selectedStateProvince}
        options={stateProvinces}
        onChange={(e) => onChange(e)}
        onFocus={(e) => toggleFocus(e)}
        onBlur={() => toggleFocus()}
        filter
        filterBy="code"
        optionLabel="code"
        placeholder=""
        // valueTemplate={valueTemplate}
        itemTemplate={itemTemplate}
        autoFocus={props.autoFocus}
        warning={props.warning}
        error={_hasError}
        // showOnFocus={props.showOnFocus || true}
        disabled={props.disabled}
      />
    </div>
  );
}
