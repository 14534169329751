import React from 'react';
import PropTypes from 'prop-types';

// Components
import HelpDialog from 'components/HelpDialog/container/HelpDialog';

// Assets
const mileageWarning = require('assets/images/bypass/mileage-warnings.jpg');
const bypass = require('assets/images/bypass/bypass.jpg');
const pullUnlessClosed = require('assets/images/bypass/pull-unless-closed.jpg');
const followRoadSigns = require('assets/images/bypass/follow-road-signs.jpg');

function WeighStationHelpDialog(props) {
  const uListStyle = { marginTop: '1em', paddingLeft: '1.2em', fontSize: '.85em' };
  const listStyle = { marginBottom: '.5em' };

  return (
    <HelpDialog title="Weigh Station Bypass" buttonStyle={props.buttonStyle}>
      <div style={{ marginTop: '2em', marginBottom: '2em' }}>
        How Bypass works:
        <ul style={uListStyle}>
          <li style={listStyle}>
            <b>Getting close to a station</b>:
            Over 700 sites participate in the Switchboard Bypass Program. You will receive <b>2-mile</b> and <b>1-mile</b> notification.
            <img height="100em" src={mileageWarning} role="presentation" />
          </li>
          <br />
          <br />
          <li style={listStyle}>
            <b>At a station</b>:
            At these Switchboard sites you will receive one of three instructions:
            <div className="row">
              <div className="col-md-6" style={{ lineHeight: '7em' }}><b>Bypass Granted</b> You may bypass the site</div>
              <div className="col-md-6"><img height="100em" src={bypass} role="presentation" /></div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6" style={{ lineHeight: '7em' }}><b>Bypass Not Granted</b> Pull into site unless closed</div>
              <div className="col-md-6"><img height="100em" src={pullUnlessClosed} role="presentation" /></div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6" style={{ lineHeight: '7em' }}><b>Follow Road Sign</b> Bypass instruction given on electronic sign</div>
              <div className="col-md-6"><img height="100em" src={followRoadSigns} role="presentation" /></div>
            </div>
          </li>
        </ul>
        <br />
        <br />
        To enable Bypass, go into the <b>Vehicle Details</b> and <b>Enable Weigh Station Bypass</b>.
      </div>
    </HelpDialog>
  );
}

WeighStationHelpDialog.propTypes = {
  buttonStyle: PropTypes.object,
};

export default WeighStationHelpDialog;
