import { useEffect, useState } from  'react';
import moment from 'moment-timezone';

// api
import { formatName } from 'sb-csapi/dist/utils/String';
import { getAttribute, getCurrentUserCompanyObjectId } from 'sb-csapi/dist/AAPI';
import { getDrivers } from 'sb-csapi/dist/api/Driver/Driver';
import { getUsers } from 'api/User/User';
import { getDriverDocuments } from 'api/Driver/Documents';

// enums
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// components
import InputText from 'sbCore/InputText/InputText';
import Column from 'sbCore/Column/Column';
import DataTable from 'sbCore/DataTable/DataTable';
import VehicleAutocomplete from 'sbCore/VehicleAutocomplete/VehicleAutocomplete';
import UserAutocomplete from 'components/Shared/UserAutocomplete/UserAutocomplete';
import Button from 'sbCore/Button/Button';
import Calendar from 'sbCore/Calendar/Calendar';

// style
import './style.scss';

/**
 * @description Displays all company documents, including driver, dispatch, and safety documents. For now, it only displays driver documents
 * @param {boolean} triggerRefreshState - Whether to refresh the table state or not
 * @param {function} onShowUploadDialog - Callback to handle showing upload dialog
 * @param {function} onShowDeleteDialog - Callback to handle showing delete dialog
 * @param {function} onShowEditDialog - Callback to handle showing edit dialog
 * @returns
 */
function GlobalDocumentsTable(props) {
  const [isLoading, setIsLoading] = useState(false);

  const [documentsTableContent, setDocumentsTableContent] = useState([]);
  const [documentsTableContentTotalCount, setDocumentsTableContentTotalCount] = useState(0);

  const [fileName, setFileName] = useState('');
  const [uploadedAtDates, setUploadedAtDates] = useState([]);
  const [userFirstName, setUserFirstName] = useState('');
  const [vehicleUnitId, setVehicleUnitId] = useState('');

  const [fetchAttributes, setFetchAttributes] = useState({ first: 0, rows: 20, page: 0 });

  useEffect(() => {
    let didCancel = false;

    async function refreshState() {
      // wait until user finishes changing filters before fetching new documents
      await new Promise(resolve => setTimeout(resolve, 300));
      if (!didCancel) await fetchDriverDocuments();
    }

    refreshState();

    return () => { didCancel = true; };

  }, [userFirstName, vehicleUnitId, fileName, uploadedAtDates]);

  useEffect(() => {
    let didCancel = false;

    async function refreshState() {
      if (!didCancel) await fetchDriverDocuments();
    }

    refreshState();

    return () => { didCancel = true; };
  }, [fetchAttributes.page, props.triggerRefreshState]);

  function generateDocumentTableContentObj(driverDocumentObjectId, userObjectId, fileName, firstName, lastName, uploadedAt, fileUrl, driverDocumentRecord) {
    return {
      driverDocumentObjectId,
      userObjectId,
      fileName,
      firstName,
      lastName,
      uploadedAt,
      fileUrl,
      driverDocumentRecord,
    };
  }

  async function fetchDriverDocuments() {
    setIsLoading(true);

    const filters = [/* new Filter(QueryRestriction.EQUAL_TO, 'visibleToDriver', true) */];

    if (fileName !== '') filters.push(new Filter(QueryRestriction.MATCHES, 'fileName', fileName));

    if (uploadedAtDates.length !== 0) {
      let startDate = undefined;
      let endDate = undefined;

      if (uploadedAtDates[0]) {
        startDate = new Date(uploadedAtDates[0]);
        filters.push(new Filter(QueryRestriction.GREATER_THAN_OR_EQUAL_TO, 'uploadedAt', startDate));
      }

      if (uploadedAtDates[1]) {
        endDate = new Date(uploadedAtDates[1]);
        filters.push(new Filter(QueryRestriction.LESS_THAN_OR_EQUAL_TO, 'uploadedAt', endDate));
      }
    }

    const userFilters1 = [];

    if (userFirstName !== '') userFilters1.push(new Filter(QueryRestriction.MATCHES, 'firstName', userFirstName));

    const driverFilters1 = [];

    if (vehicleUnitId !== '') driverFilters1.push(new Filter(QueryRestriction.MATCHES, 'vehicle_unitId', vehicleUnitId));

    let userObjectIds1 = [];

    if (userFilters1.length !== 0) {
      const { users } = await getUsers(
        undefined,
        getCurrentUserCompanyObjectId(),
        false,
        userFilters1,
        undefined,
        [],
        undefined,
        undefined,
        undefined,
        true
      );

      userObjectIds1 = users.map(user => getAttribute(user, 'objectId'));
    }

    if (driverFilters1.length !== 0) {
      const { drivers } = await getDrivers(
        undefined,
        getCurrentUserCompanyObjectId(),
        false,
        driverFilters1,
        undefined,
        ['vehicle', 'user'],
        undefined,
        true
      );

      if (userObjectIds1.length === 0) {
        userObjectIds1 = drivers.map(driver => getAttribute(getAttribute(driver, 'user'), 'objectId'));
      } else {
        const userObjectIds = drivers.map(driver => getAttribute(getAttribute(driver, 'user'), 'objectId'));

        userObjectIds1 = userObjectIds1.filter(userObjectId1 => userObjectIds.findIndex(userObjectId => userObjectId1 === userObjectId) !== -1);
      }
    }

    if (userObjectIds1.length !== 0) filters.push(new Filter(QueryRestriction.CONTAINED_IN, 'uploadedBy', userObjectIds1));

    const { totalDriverDocumentsCount, driverDocuments } = await getDriverDocuments(
      undefined,
      filters,
      undefined,
      ['uploadedBy'],
      undefined,
      fetchAttributes.page,
      fetchAttributes.rows,
      false
    );

    const _documentsTableContent = [];
    const userObjectIds = [];

    driverDocuments.forEach(driverDocument => {
      const driverDocumentObjectId = getAttribute(driverDocument, 'objectId');

      let fileName = getAttribute(driverDocument, 'fileName') || '';
      fileName = fileName.toLowerCase();

      const uploadedBy = getAttribute(driverDocument, 'uploadedBy');
      const firstName = getAttribute(uploadedBy, 'firstName') || '';
      const lastName = getAttribute(uploadedBy, 'lastName') || '';
      const userObjectId = getAttribute(uploadedBy, 'objectId');
      const uploadedAt = moment(getAttribute(driverDocument, 'uploadedAt')).format('YYYY-MM-DD');

      let fileUrl = '';
      const file = getAttribute(driverDocument, 'file');
      if (file && file._url) fileUrl = file._url;

      userObjectIds.push(userObjectId);

      const documentTableContentObj = generateDocumentTableContentObj(
        driverDocumentObjectId,
        userObjectId,
        fileName,
        firstName,
        lastName,
        uploadedAt,
        fileUrl,
        driverDocument
      );

      _documentsTableContent.push(documentTableContentObj);
    });

    const driverFilters2 = [new Filter(QueryRestriction.CONTAINED_IN, 'user', userObjectIds)];

    const { drivers } = await getDrivers(
      undefined,
      getCurrentUserCompanyObjectId(),
      false,
      driverFilters2,
      undefined,
      ['vehicle', 'user'],
      undefined,
      true
    );

    _documentsTableContent.forEach((document, documentIndex) => {
      const driverIndex = drivers.findIndex(driver => {
        const user = getAttribute(driver, 'user');
        const userObjectId = getAttribute(user, 'objectId');

        return document.userObjectId === userObjectId;
      });

      if (driverIndex !== -1) {
        const vehicleUnitId = getAttribute(drivers[driverIndex], 'vehicle_unitId') || '';
        _documentsTableContent[documentIndex]['vehicleUnitId'] = vehicleUnitId;
      }
    });

    setDocumentsTableContent(_documentsTableContent);
    setDocumentsTableContentTotalCount(totalDriverDocumentsCount);

    setIsLoading(false);
  }

  function renderHeader() {
    return (
      <div className="flex w-full">
        <div className="flex justify-content-start w-full">
          <h5 className="text-2xl font-semibold sb-global-documents-header">Documents</h5>
        </div>
        <div className="flex justify-content-end w-full">
          <Button
            className="p-button-sm"
            label="Upload Document"
            icon="pi pi-upload"
            onClick={() => props.onShowUploadDialog()}
          />
        </div>
      </div>
    );
  }

  function fileNameBodyTemplate(rowData) {
    return (
      <div className="flex w-full">
        {rowData.fileName}
      </div>
    );
  }

  function userBodyTemplate(rowData) {
    return (
      <div className="flex w-full">
        {formatName(rowData.firstName)} {formatName(rowData.lastName)}
      </div>
    );
  }

  function vehicleBodyTemplate(rowData) {
    return (
      <div className="flex w-full">
        {rowData.vehicleUnitId}
      </div>
    );
  }

  /* HIDDEN FOR NOW */
  // function tagsBodyTemplate(rowData) {
  //   return (
  //     <div className="flex w-full">
  //       {rowData.tags}
  //     </div>
  //   );
  // }

  function dateUploadedBodyTemplate(rowData) {
    return (
      <div className="flex w-full">
        {rowData.uploadedAt}
      </div>
    );
  }

  function fileNameFilterElement(options) {
    return (
      <div className="flex w-full">
        <InputText
          onChange={(e) => setFileName(e.target.value)}
          value={fileName}
          placeholder="File Name"
        />
      </div>
    );
  }

  function userFilterElement(options) {
    return (
      <UserAutocomplete
        allowDeselect
        onUserSelected={(user) => {
          if (user) {
            const firstName = getAttribute(user, 'firstName') || '';
            setUserFirstName(firstName.toLowerCase());
          } else {
            setUserFirstName('');
          }
        }}
      />
    );
  }

  function vehicleFilterElement(options) {
    return (
      <VehicleAutocomplete
        className="flex w-full"
        hideLabel
        onSelectVehicle={(vehicle) => {
          if (vehicle) {
            const vehicleUnitId = getAttribute(vehicle, 'unitId') || '';
            setVehicleUnitId(vehicleUnitId.toLowerCase());
          } else {
            setVehicleUnitId('');
          }
        }}
      />
    );
  }

  /* HIDDEN FOR NOW */
  // function tagsFilterElement(options) {
  //   return (
  //     <div className="flex w-full">
  //       <InputText onChange={(e) => options.filterApplyCallback(e.value)} value={options.value} placeholder="Tags" />
  //     </div>
  //   );
  // }

  function dateUploadedFilterElement(options) {
    return (
      <Calendar
        value={uploadedAtDates}
        onChange={(e) => {
          if (e.value) {
            setUploadedAtDates(e.value);
          } else {
            setUploadedAtDates([]);
          }
        }}
        placeholder="Date Uploaded"
        selectionMode="range"
        showButtonBar
        dateFormat="yy/mm/dd"
      />
    );
  }

  function buttonsBodyTemplate(rowData) {
    return (
      <div className="flex justify-content-end w-full" style={{ gap: '1rem' }}>
        <Button
          className="p-button-rounded p-button-sm sb-download-button"
          icon="pi pi-external-link"
          onClick={() => window.open(rowData.fileUrl)}
        />
        {/* HIDDEN FOR NOW */}
        {/* <Button
          className="p-button-rounded p-button-sm sb-edit-button"
          icon="pi pi-pencil"
          onClick={() => props.onShowEditDialog(rowData)}
        /> */}
        <Button
          className="p-button-rounded p-button-sm sb-delete-button"
          icon="pi pi-trash"
          onClick={() => props.onShowDeleteDialog(rowData)}
        />
      </div>
    );
  }

  return (
    <div className="global-documents-table card m-3 w-full">
      <DataTable
        value={documentsTableContent}
        header={() => renderHeader()}
        paginator
        rows={fetchAttributes.rows}
        totalRecords={documentsTableContentTotalCount}
        lazy
        first={fetchAttributes.first}
        onPage={(e) => setFetchAttributes({ ...fetchAttributes, page: e.first / e.rows, first: e.first })}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
        dataKey="id"
        rowHover
        responsiveLayout="scroll"
        emptyMessage="No documents found."
        loading={isLoading}
        filterDisplay="row"
      >
        <Column
          header="File Name"
          body={fileNameBodyTemplate}
          filter
          filterElement={fileNameFilterElement}
          onFilterClear={() => setFileName('')}
          style={{ minWidth: '20rem' }}
          className="sb-global-documents-header"
        />
        <Column
          header="Uploaded By"
          body={userBodyTemplate}
          filter
          filterElement={userFilterElement}
          style={{ minWidth: '20rem' }}
          className="sb-global-documents-header"
        />
        <Column
          header="Current Vehicle"
          body={vehicleBodyTemplate}
          filter
          filterElement={vehicleFilterElement}
          style={{ minWidth: '20rem' }}
          className="sb-global-documents-header"
        />
        {/* HIDDEN FOR NOW */}
        {/* <Column
          field="tags"
          header="Tags"
          body={tagsBodyTemplate}
          filter
          showClearButton
          filterPlaceholder="Tags"
          style={{ minWidth: '20rem' }}
          className="sb-global-documents-header"
        /> */}
        <Column
          header="Date Uploaded"
          body={dateUploadedBodyTemplate}
          filter
          filterElement={dateUploadedFilterElement}
          onFilterClear={() => setUploadedAtDates([])}
          style={{ minWidth: '20rem' }}
          className="sb-global-documents-header"
        />
        <Column
          field=""
          header=""
          body={buttonsBodyTemplate}
          style={{ minWidth: '20rem' }}
          className="sb-global-documents-header"
        />
      </DataTable>
    </div>
  );
}

export default GlobalDocumentsTable;
