import React from 'react';
import PropTypes from 'prop-types';

class CommonIssuesRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showResolveModal: false,
    };
    this.handleResolveChoice = this.handleResolveChoice.bind(this);
    this.handleResolveClose = this.handleResolveClose.bind(this);
  }

  handleResolveChoice(choiceBool) {
    if (choiceBool) {
      this.state.currentIssue.set('resolved', true).save().then(issue => {
        this.setState(this.state);
      });
    }
    this.setState({ ...this.state, showResolveModal: false });
  }

  handleResolveClose() {
    this.setState({ ...this.state, showResolveModal: false });
  }

  render() {
    const { issue } = this.props;

    const type = issue.description;
    const occurences = issue.occurences;
    const activePoints = issue.activePoints;

    return (
      <tr key={this.props.rowKey}>
        <td>
          { type }
        </td>
        <td>
          { occurences }
        </td>
        <td>
          { activePoints }
        </td>
      </tr>
    );
  }
}

CommonIssuesRow.propTypes = {
  issue: PropTypes.object,
  rowKey: PropTypes.string,
};

export default CommonIssuesRow;
