import moment from 'moment';
import React, { useEffect, useState } from 'react';

// API
import { convertDistance } from 'api/Helpers';
import { getLocalReverseGeocode } from 'api/Geocode';
import { isIdling, getGPSDirection } from 'api/GPS/GPS';
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { getVehicleLocationDescription, getLocationDescriptionBreakdown } from 'sb-csapi/dist/api/VehicleLocation/VehicleLocation';

// Hooks
import { usePrevious } from 'hooks/usePrevious';

// Enums
import { EquipmentTypes } from 'enums/Equipment'; // need to move to sb-csapi
import { LengthUnit } from 'sb-csapi/dist/enums/Unit';
import { StateProvinces } from 'api/Lists/StateProvinces'; // Move to sb-csapi

// Components
import ScrollPanel from 'sbCore/ScrollPanel/ScrollPanel';
import GeofenceCard from 'components/Map/Sidebar/GeofenceCard/GeofenceCard';
import SidebarFilter from 'components/Map/Sidebar/SidebarFilter/SidebarFilter';
import SidebarState from 'components/Map/Sidebar/SidebarState/SidebarState';
import VehicleTrailerCard from 'components/Map/Sidebar/VehicleTrailerCard/VehicleTrailerCard';

/**
 * @description The sidebar content and logic for "All Equipment" view
 * 
 * @param {Object} vehicleQueryResult - The results of the vehicleAPI query
 * @param {Object} trailerQueryResult - The results of the trailerAPI query
 * @param {Object} geofenceQueryResult - The results of the geofenceAPI query
 * @param {Number} equipmentType - type of equipment to display (vehicle, trailer, geofence)
 * @param {Number} speedLimitKm - speed limit in km/h
 * @param {Object} activeGeofence - currently active geofence
 * @param {Object} activeEquipment - currently active equipment
 * @param {bool} addEditGeofence - boolean to determine whether geofences are being added/edited
 * 
 * @param {Function} handleSetAddEditGeofence - callback function for when a geofence is set to add/edit mode
 * @param {Function} isActionDisabled - callback function to see if a particular action is disabled
 * @param {Function} setActiveEquipmentInformation - callback function for handling when a equipment card is set to active
 * @param {Function} handleOnEquipmentSelect - callback function for when an equipment is selected on the sidebar
 * @param {Function} onSelectEquipment - callback function for when a geofence is selected on the sidebar
 * 
 * @returns {JSX} The sidebar view for "All Equipment" 
 */
function AllEquipmentView({ vehicleQueryResult, trailerQueryResult, geofenceQueryResult, equipmentType, ...props }) {
  // Filter and Sort
  const [searchValue, setSearchValue] = useState('');
  const [sortBy, setSortBy] = useState('Last Updated');

  // Sorted vehicles, trailers, and geofences
  const [sortedVehicles, setSortedVehicles] = useState([]);
  const [sortedTrailers, setSortedTrailers] = useState([]);
  const [sortedGeofences, setSortedGeofences] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const prevEquipmentType = usePrevious(equipmentType);
  const prevSearchValue = usePrevious(searchValue);
  const prevSortBy = usePrevious(sortBy);

  /* Functions to parse equipment */
  function parseVehicles(vehicles) {
    const distanceUnit = getPDispatcherPropertyFromState('distanceUnit') || LengthUnit.MI.toLowerCase();

    let parsedVehicles = vehicles.map((vehicle) => {
      const { objectId, unitId, drivers, vehicleLocation, belongsToCompany } = vehicle;

      const driverEldStatusInt = drivers && drivers.length > 0 && drivers[0].eldStatusInt;
      const isDriverDriving = driverEldStatusInt === 3 || driverEldStatusInt === 6;
      const speedKm = vehicleLocation?.speedKm; // Vehicle location speed is recorded in km
      const isVehicleIdling = isIdling(isDriverDriving, speedKm);
      const isVehicleSpeeding = speedKm && speedKm > props.speedLimitKm;

      // Convert speed based on the dispatcher's distance unit
      let speedStr = '-';
      if (speedKm) {
        speedStr = (distanceUnit === LengthUnit.KM.toLowerCase() ? (`${Math.round(speedKm)} ${distanceUnit}/h`) : (`${convertDistance(speedKm, 'km', 'mi')} ${distanceUnit}/h`));
      }

      // Covnert speed limit distance unit
      const speedLimitStr = (distanceUnit === LengthUnit.KM.toLowerCase() ? (`${Math.round(props.speedLimitKm)} ${distanceUnit}/h`) : (`${convertDistance(props.speedLimitKm, 'km', 'mi')} ${distanceUnit}/h`));

      const countryCode = ((vehicleLocation && vehicleLocation?.countryCode) || '').toUpperCase();
      let locationDescription;

      /**
       * This code here is used to handle cases where the VehicleLocation's stateProvince and locationDescription's stateProvince attributes do not match
       * In the original code, the locationDescription's stateProvince is replaced with the VehicleLocation's stateProvince attribute
       * However, it seems like the opposite is true: the VehicleLocation's stateProvince is the value that is incorrect
       * E.g., VehicleLocation's stateProvince = BC, locationDescription's stateProvince = WA. The correct city/stateProvince is Blaine, WA
       */
      if (countryCode === 'US') {
        const locationDescriptionUS = vehicleLocation?.locationDescriptionUS;
        locationDescription = locationDescriptionUS;

        if (locationDescriptionUS) {
          const locationDescriptionBreakdown = getLocationDescriptionBreakdown(locationDescriptionUS);
          const { distance, distanceUnit, city, heading, stateProvince } = locationDescriptionBreakdown;

          const trueStateProvince = vehicleLocation?.stateProvince?.toUpperCase();

          if (trueStateProvince?.toUpperCase() !== stateProvince?.code) {
            // locationDescription = getVehicleLocationDescription(city, trueStateProvince, distance, distanceUnit, heading.value);
            // console.log('city', city, 'stateProvince', trueStateProvince, '| locationDescription stateProvince', stateProvince?.code);
          }
        }
      } else if (countryCode === 'CA') {
        const locationDescriptionCA = vehicleLocation?.locationDescriptionCA;
        locationDescription = locationDescriptionCA;

        if (locationDescriptionCA) {
          const locationDescriptionBreakdown = getLocationDescriptionBreakdown(locationDescriptionCA);
          const { distance, distanceUnit, city, heading, stateProvince } = locationDescriptionBreakdown;

          const trueStateProvince = vehicleLocation?.stateProvince?.toUpperCase();

          if (trueStateProvince !== stateProvince?.code) {
            // locationDescription = getVehicleLocationDescription(city, trueStateProvince, distance, distanceUnit, heading.value);
            // console.log('city', city, 'stateProvince', trueStateProvince, '| locationDescription stateProvince', stateProvince?.code);
          }
        }
      }

      // GPS Heading
      let gpsHeadingDirection = '-';
      if (vehicle && vehicleLocation?.gpsHeading) {
        gpsHeadingDirection = getGPSDirection(vehicleLocation?.gpsHeading).direction;
      }

      const longitude = vehicleLocation?.location?.longitude;
      const latitude = vehicleLocation?.location?.latitude;

      return {
        objectId,
        unitId,
        drivers: drivers?.map((driver) => ({ fullName: driver.user_fullName, driverObjectId: driver.objectId, shippingDocumentNumber: driver.latestELDEvent.shippingDocumentNumber })),
        locationDescription,
        locationObj: vehicleLocation,
        lastUpdated: vehicleLocation?.dateTime?.iso,
        eldStatusInt: driverEldStatusInt,
        companyName: belongsToCompany?.name,
        isIdling: isVehicleIdling,
        isSpeeding: isVehicleSpeeding,
        speedStr,
        speedLimitStr,
        speedKm,
        gpsHeadingDirection,
        gpsHeading: vehicleLocation?.gpsHeading,
        coordinates: {
          longitude,
          latitude,
        }
      }
    });

    // Sort by Last Updated - if the sortBy is set to "Unit ID", we ignore sorting since the query performs the sort already
    if (sortBy === 'Last Updated') {
      parsedVehicles = parsedVehicles.sort((equipmentA, equipmentB) => sortSidebarObjectByLastUpdated(equipmentA, equipmentB));
    }

    return parsedVehicles;
  }

  async function parseTrailers(trailers) {
    const distanceUnit = getPDispatcherPropertyFromState('distanceUnit') || LengthUnit.MI.toLowerCase();

    let parsedTrailers = await Promise.all(trailers.map(async (trailer) => {
      // Return back only the necessary information
      const { objectId, unitId, belongsToCompany, tc_devices } = trailer;

      let locationDescription;
      let lastUpdated;

      const tc_positions = tc_devices?.tc_positions;
      const assetLocation = tc_devices?.assetLocation;

      if (tc_positions || assetLocation) {
        // Perform reverse geocode on the coordinates
        let latitude;
        let longitude;
        if (tc_positions) {
          latitude = tc_positions.location?.latitude;
          longitude = tc_positions.location?.longitude;
          lastUpdated = (tc_positions.devicetime && tc_positions.devicetime.iso) || tc_positions.updatedAt;
        } else if (assetLocation) {
          latitude = assetLocation.location?.latitude;
          longitude = assetLocation.location?.longitude;
          lastUpdated = (assetLocation.devicetime && assetLocation.devicetime.iso) || assetLocation.updatedAt;
        }

        const reverseGeocodeResults = await getLocalReverseGeocode([[latitude, longitude]]);

        if (reverseGeocodeResults && reverseGeocodeResults.length > 0) {
          const address = reverseGeocodeResults[0];
          const stateProvinces = StateProvinces.filter((value) => value.name === address?.admin1Code?.asciiName);
          locationDescription = getVehicleLocationDescription(address?.asciiName, stateProvinces && stateProvinces.length > 0 && stateProvinces[0].code);
        }
      }

      // tc_positions speed is recorded in mi
      const speedMile = tc_positions?.speed;
      // convert speed to dispatcher's preferred distance unit
      let speedStr = '-';
      if (speedMile) {
        speedStr = (distanceUnit === LengthUnit.KM.toLowerCase() ? (`${convertDistance(speedMile, 'mi', 'km')} km/h`) : (`${Math.round(speedMile)} mi/h`));
      }

      // trailer temp
      const trailerTemperatureCelsius = tc_positions?.tempCelsius;
      // trailer heading
      let gpsHeadingDirection = '-';
      if (tc_positions?.course) {
        gpsHeadingDirection = getGPSDirection(tc_positions?.course).direction;
      }
      const latitude = tc_positions?.latitude;
      const longitude = tc_positions?.longitude;

      return {
        objectId,
        unitId,
        locationDescription,
        lastUpdated,
        locationObj: tc_positions,
        companyName: belongsToCompany?.name,
        trailerTemperatureCelsius,
        gpsHeadingDirection,
        isTrailer: true,
        speedStr,
        coordinates: {
          latitude,
          longitude,
        }
      }
    }));

    // Sort by Last Updated - if the sortBy is set to "Unit ID", we ignore sorting since the query performs the sort already
    if (sortBy === 'Last Updated') {
      parsedTrailers = parsedTrailers.sort((equipmentA, equipmentB) => sortSidebarObjectByLastUpdated(equipmentA, equipmentB));
    }

    return parsedTrailers;
  }

  function parseGeofences(geofences) {
    let parsedGeofences = geofences.map((geofence) => {
      // jsonify geofence parseobjects and extract the properties we need for geofenceCard
      const { objectId, name, belongsToCompany, updatedAt } = geofence.toJSON();

      return {
        objectId,
        name,
        companyName: belongsToCompany?.name,
        lastUpdated: updatedAt
      }
    });

    // Sort by name - case insensitive since query performs case sensitive alphabetical sort 
    if (sortBy === 'Name') {
      parsedGeofences = parsedGeofences.sort((geofenceA, geofenceB) => {
        if (geofenceA?.name && geofenceB?.name) {
          return geofenceA.name.toLowerCase().localeCompare(geofenceB.name.toLowerCase())
        }

        if (!geofenceA.name) {
          return 1;
        } else if (!geofenceB.name) {
          return -1;
        }

        return 0;
      });
    } else if (sortBy === 'Last Updated') {
      parsedGeofences = parsedGeofences.sort((geofenceA, geofenceB) => sortSidebarObjectByLastUpdated(geofenceA, geofenceB));
    }

    if (searchValue) {
      parsedGeofences = parsedGeofences.filter((geofence) => geofence?.name?.toLowerCase().includes(searchValue.toLowerCase()));
    }

    return parsedGeofences;
  }

  /**
  * @description Perfoms sorting between two sidebar objects lastUpdated
  * @param {Object} objectA - A parsed Vehicle/Trailer/Geofence object
  * @param {Object} objectB - A parsed Vehicle/Trailer/Geofence object
  * @returns {int} sorting order
  */
  function sortSidebarObjectByLastUpdated(objectA, objectB) {
    if (objectA?.lastUpdated && objectB?.lastUpdated) {
      return moment(objectB.lastUpdated).valueOf() - moment(objectA.lastUpdated).valueOf();
    } else if (objectA.locationDescription) {
      return -1;
    } else if (objectB.locationDescription) {
      return 1;
    }

    if (objectA.unitId < objectB.unitId) return -1;
    if (objectA.unitID > objectB.unitId) return 1;
    return 0;
  }

  /* UseEffects */
  useEffect(() => {
    let didCancel = false;
    let filteredEquipment = [];

    function _parseVehicles(vehicles) {
      if (!didCancel && (prevEquipmentType != equipmentType || prevSortBy != sortBy || prevSearchValue != searchValue)) setIsLoading(true);

      const parsedVehicles = parseVehicles(vehicles);
      if (!didCancel) {
        setIsLoading(false);
        setSortedVehicles(parsedVehicles);
      }
    }

    async function _parseTrailers(trailers) {
      if (!didCancel && (prevEquipmentType != equipmentType || prevSortBy != sortBy || prevSearchValue != searchValue)) setIsLoading(true);

      const parsedTrailers = await parseTrailers(trailers);

      if (!didCancel) {
        setIsLoading(false);
        setSortedTrailers(parsedTrailers);
      }
    }

    // Set the equipment to the one that matches the current equipmentType we are viewing
    if (equipmentType === EquipmentTypes.VEHICLE && vehicleQueryResult.isSuccess) {
      filteredEquipment = vehicleQueryResult.data.vehicles;
    } else if (equipmentType === EquipmentTypes.TRAILER && trailerQueryResult.isSuccess) {
      filteredEquipment = trailerQueryResult.data.trailers;
    }

    // Perform filtering if needed
    if (searchValue) {
      filteredEquipment = filteredEquipment.filter((equipment) => equipment?.unitId?.toLowerCase().includes(searchValue.toLowerCase()));
    }

    // Perfom parsing on the respective equipment
    if (equipmentType === EquipmentTypes.VEHICLE && vehicleQueryResult.isSuccess) {
      _parseVehicles(filteredEquipment);
    } else if (equipmentType === EquipmentTypes.TRAILER && trailerQueryResult.isSuccess) {
      _parseTrailers(filteredEquipment);
    }

    return (() => didCancel = true);
  }, [vehicleQueryResult, trailerQueryResult, equipmentType, searchValue, sortBy]);

  // Performs filtering and sorting of geofences whenever geofences/sortby/filter is updated
  useEffect(() => {
    let didCancel = false;

    function _parseGeofences(geofences) {
      if (!didCancel && (prevEquipmentType != equipmentType || prevSortBy != sortBy || prevSearchValue != searchValue)) setIsLoading(true);

      const parsedGeofences = parseGeofences(geofences);
      if (!didCancel) {
        setIsLoading(false);
        setSortedGeofences(parsedGeofences);
      }
    }

    if (equipmentType === 2 && geofenceQueryResult.isSuccess) {
      _parseGeofences(geofenceQueryResult.data);
    }

    return (() => didCancel = true);
  }, [geofenceQueryResult, equipmentType, searchValue, sortBy]);

  return (
    <>
      <SidebarFilter
        onSearchValueChange={(value) => setSearchValue(value)}
        onSortOptionChange={(value) => setSortBy(value)}
        isDisabled={props.isActionDisabled()}
        activeIndex={equipmentType}
        searchPlaceholder={equipmentType === 2 ? 'Geofence Name' : 'Unit ID'}
      />

      {/* Equipment List */}
      {/* Each view should handle the following states: loading, empty, success, error */}
      <ScrollPanel className="sidebar-content">
        {equipmentType === EquipmentTypes.VEHICLE &&
          <>
            {/* Display error state */}
            {vehicleQueryResult.isError &&
              <SidebarState equipmentType={equipmentType} isError />
            }

            {/* Display loading state */}
            {(isLoading && !vehicleQueryResult.isError) &&
              <SidebarState equipmentType={equipmentType} isLoading />
            }

            {/* Display empty state */}
            {(!isLoading && vehicleQueryResult.isSuccess && sortedVehicles.length === 0) &&
              <SidebarState equipmentType={equipmentType} isEmpty />
            }

            {/* Display success state */}
            {(!isLoading && vehicleQueryResult.isSuccess) && sortedVehicles.map((vehicleInformation) =>
              <VehicleTrailerCard
                className="my-2 ml-2"
                key={vehicleInformation.objectId}
                isVehicle
                vehicleTrailerObj={vehicleInformation}
                handleSetActiveEquipmentInformation={() => props.setActiveEquipmentInformation(vehicleInformation)}
                onClick={() => props.handleOnEquipmentSelect(vehicleInformation)}
                isActive={vehicleInformation.unitId === props.activeEquipment}
              />
            )}
          </>
        }
        {equipmentType === EquipmentTypes.TRAILER &&
          <>
            {/* Display error state */}
            {trailerQueryResult.isError &&
              <SidebarState equipmentType={equipmentType} isError />
            }

            {/* Display loading state */}
            {(isLoading && !trailerQueryResult.isError) &&
              <SidebarState equipmentType={equipmentType} isLoading />
            }

            {/* Display empty state */}
            {(!isLoading && trailerQueryResult.isSuccess && sortedTrailers.length === 0) &&
              <SidebarState equipmentType={equipmentType} isEmpty />
            }

            {/* Display success state */}
            {(!isLoading && trailerQueryResult.isSuccess) && sortedTrailers.map((trailerInformation) => {
              return (
                <VehicleTrailerCard
                  key={trailerInformation.objectId}
                  className="my-2 ml-2"
                  isTrailer
                  vehicleTrailerObj={trailerInformation}
                  handleSetActiveEquipmentInformation={() => props.setActiveEquipmentInformation(trailerInformation)}
                  onClick={() => props.handleOnEquipmentSelect(trailerInformation)}
                  isActive={trailerInformation.unitId === props.activeEquipment}
                />
              );
            })}
          </>
        }
        {equipmentType === 2 &&
          <>
            {/* Display error state */}
            {geofenceQueryResult.isError &&
              <SidebarState equipmentType={equipmentType} isError />
            }

            {/* Display loading state */}
            {(isLoading && !geofenceQueryResult.isError) &&
              <SidebarState equipmentType={equipmentType} isLoading />
            }

            {/* Display empty state */}
            {(!isLoading && geofenceQueryResult.isSuccess && sortedGeofences.length === 0) &&
              <SidebarState equipmentType={equipmentType} isEmpty />
            }

            {/* Display success state */}
            {(!isLoading && geofenceQueryResult.isSuccess) && sortedGeofences.map((geofence) =>
              <GeofenceCard
                className="my-2 ml-2"
                geofence={geofence}
                addEditGeofence={props.addEditGeofence}
                setEditMode={() => props.handleSetAddEditGeofence('edit', geofence.objectId)}
                cancelEditMode={() => props.handleSetAddEditGeofence()}
                selectGeofence={() => props.onSelectEquipment('Geofence', geofence.objectId, true)}
                isActive={props.activeGeofence && props.activeGeofence.id === geofence.id}
              />
            )}
          </>
        }
      </ScrollPanel>
    </>
  );
}

export default AllEquipmentView;