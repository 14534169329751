/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import history from 'sbHistory';

// API
import * as Mapbox from 'api/Mapbox';
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { formatName, referenceToTimezone } from 'api/Helpers';
import { getDateTimeStringFromVisionEventVideo } from 'api/Vision/VisionEvent';

// Enums
import { LengthUnit } from 'enums/DispatchUnit';

// Components
import Button from 'sbCore/Button/Button'
import ReactMapboxGl, { Marker } from 'react-mapbox-gl';

// CSS
import './style.scss';

const ReactMap = ReactMapboxGl({
  accessToken: Mapbox.getToken(),
  renderWorldCopies: true,
});

const initialState = {
  center: [0, 0],
  driverName: 'N/A',
  title: 'N/A',
  logPath: undefined,
  dateTime: 'N/A',
  locationDescription: 'N/A',
};

/**
 * @description Card that contains information for a given VisionEvent
 * @param {String} visionEvent - The VisionEvent that we want to display information for
 * @param {bool} [disableLatestSnapshotButton] - Determines whether the Show Latest Snapshot is disabled
 * @param {Function} [onShowLatestSnapshot] - Callback function for when Show Latest Snapshot is pressed
 * @returns {JSX} VisionEventDescriptionCard component
 */
function VisionEventDescriptionCard(props) {
  const [visionEvent, setVisionEvent] = useState(undefined);
  const [state, setState] = useState({ ...initialState });

  async function refreshState() {
    const _newState = { ...initialState };

    const driver = getAttribute(visionEvent, 'driver', true);
    const vehicleLocation = getAttribute(visionEvent, 'vehicleLocation', true);

    if (driver) {
      const _driverName = formatName(getAttribute(driver, 'user_fullName'));
      _newState.driverName = _driverName;

      // currently uses driver's timezone to scale the log date. should use elddailycertification's timezone as priority once that info is clearly available
      const _logPath = `/driver?driver=${getAttribute(driver, 'objectId')}&view=hosEvents&date=${referenceToTimezone(getAttribute(visionEvent, 'dateTime'), getAttribute(driver, 'timezoneOffsetFromUTC')).format('DDMMYY')}`;
      _newState.logPath = _logPath;
    }

    const _dateTime = getDateTimeStringFromVisionEventVideo(visionEvent);
    _newState.dateTime = _dateTime;

    const distanceUnit = getPDispatcherPropertyFromState('distanceUnit') ? getPDispatcherPropertyFromState('distanceUnit').toLowerCase() : LengthUnit[3].toLowerCase();

    if (vehicleLocation) {
      const eldEvent = getAttribute(vehicleLocation, 'eldEvent', true);
      const eldDailyCertification = getAttribute(eldEvent, 'eldDailyCertification', true);
      const gpsLocation = getAttribute(vehicleLocation, 'location', true);
      const _locationDescription = (distanceUnit === LengthUnit[3].toLowerCase()) ? getAttribute(vehicleLocation, 'locationDescriptionCA') : getAttribute(vehicleLocation, 'locationDescriptionUS');
      const _center = [gpsLocation.longitude, gpsLocation.latitude];

      _newState.locationDescription = _locationDescription;
      _newState.center = _center;

      // if we currently have a path to the driver's logs, and the daily cert of this event contains a timezone, use that instead of the driver's
      if (_newState.logPath && _newState.logPath.search && eldDailyCertification && getAttribute(eldDailyCertification, 'timezoneOffsetFromUTC')) {
        const _logPath = `/driver?driver=${getAttribute(driver, 'objectId')}&view=hosEvents&date=${referenceToTimezone(getAttribute(visionEvent, 'dateTime'), getAttribute(eldDailyCertification, 'timezoneOffsetFromUTC')).format('DDMMYY')}`;
        _newState.logPath = _logPath;
      }
    }

    setState({ ..._newState });
  }

  useEffect(() => {
    setVisionEvent(props.visionEvent);
  }, [props.visionEvent]);

  useEffect(() => {
    if (!visionEvent) return;
    refreshState();
  }, [visionEvent]);

  return (
    <div className="vision-event-description-card card h-auto mb-3 px-3 py-3">
      <div className="flex align-content-center justify-content-between text-xl font-bold mb-3 translate-me">
        <span>Event Details</span>
        <div>
          <Button
            sbVariant="slim"
            className="p-button-secondary mr-2"
            label="Show Latest Snapshot"
            onClick={() => props.onShowLatestSnapshot && props.onShowLatestSnapshot()}
            disabled={props.disableLatestSnapshotButton}
          />
          <Button
            icon="pi pi-external-link"
            iconPos="right"
            sbVariant="slim"
            label="Go to Driver's Log"
            onClick={() => history.push(state.logPath)}
            disabled={!state.logPath}
          />
        </div>
      </div>
      {visionEvent && (
        <div>
          <div className="flex flex-column lg:flex-row h-full w-full">
            <div className="flex flex-column justify-content-center translate-me">
              <div className="flex justify-content-between">
                <div className="category font-bold mr-6">Driver</div>
                <div className="value">{state.driverName}</div>
              </div>
              <div className="flex justify-content-between">
                <div className="category font-bold mr-6">Location</div>
                <div className="value text-right">
                  {`${state.locationDescription}`}
                  {(!!state.center[0] && !!state.center[1]) && (
                    <div className="font-medium">{`(${state.center[1].toFixed(2)}, ${state.center[0].toFixed(2)})`}</div>
                  )}
                </div>
              </div>
              <div className="flex justify-content-between">
                <div className="category font-bold mr-6">Time of Event</div>
                <div className="value">{state.dateTime}</div>
              </div>
            </div>

            <ReactMap
              className="lg:flex-1 border-round h-8rem mt-4 lg:ml-6 lg:mt-0 shadow-4"
              containerClassName="h-10rem lg:h-auto"
              zoom={(state.center[0] !== 0 && state.center[1] !== 0) ? [10] : [1]}
              style="mapbox://styles/mapbox/navigation-preview-day-v4"
              center={(state.center[0] !== 0 && state.center[1] !== 0) ? state.center : [-90, 50]}
            >
              <Marker
                anchor="top"
                offset={[0, -5]}
                coordinates={state.center}
              >
                <div className={`${(state.center[0] === 0 && state.center[1] === 0) ? 'hidden' : ''}`}><i className="material-icons md-36">local_shipping</i></div>
              </Marker>
            </ReactMap>
          </div>
        </div>
      )}
      {!visionEvent && (
        <div className="flex w-full justify-content-center align-items-center uppercase text-sm font-bold text-600 my-5 translate-me">
          Select an event to see details
        </div>
      )}
    </div>
  );
}

export default VisionEventDescriptionCard;
