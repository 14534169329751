import React from 'react';
import moment from 'moment-timezone';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';

// API
import * as Getters from 'api/Getters';
import { getAttribute } from 'api/Parse';
import { getELDEdits, getELDEventsSnapshot, getELDEditsSnapshot } from 'api/ELD/ELDEdit/ELDEdit';


// Components
import Title from 'components/LayoutTitle/view/Title';
import SBTable from 'components/Shared/SBTable/SBTable';
import SBCard from 'components/Shared/SBCard/SBCard';
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import HOSEditFilter from 'components/ELDEdit/HOSEditFilter/HOSEditFilter';
import HOSEditSummaryList from 'components/ELDEdit/HOSEditSummaryList/HOSEditSummaryList';
import HOSEditsRequestedView from 'components/ELDEdit/HOSEditsRequestedView/HOSEditsRequestedView';
import SBCardEmptyContent from 'components/Shared/SBCard/SBCardEmptyContent';
import HOSGraph from 'components/DriverDetails/container/HOSGraph';

import { MDBContainer, MDBBtn } from 'mdbreact';


// Enums
import { QuerySortOrderTypes } from 'enums/Query';

// Sort
import Sort from 'sbObjects/Sort';

// Style
import './style.scss';



class HOSEditsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eldEdits: [],
      eldEditSnapshotHash: {}, // holds info needed to preview graph representing the eldedit, key = eldEdit object id
      loading: false,
      waiting: false,
      fetchAttributes: {
        page: 0,
        count: 15,
        filters: [],
      },
      pageCount: 1,
    };


    this.refreshState = this.refreshState.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.handlePaginatorOnChange = this.handlePaginatorOnChange.bind(this);
    this.toggleELDEditPreview = this.toggleELDEditPreview.bind(this);
  }

  componentDidMount() {
    document.title = 'HOS Edits - Switchboard';
    this.refreshState();
  }

  async refreshState() {
    const { fetchAttributes } = this.state;
    this.setState({ ...this.state, loading: true });
    const { eldEdits, totalELDEditsCount } = await getELDEdits(
      fetchAttributes.page,
      fetchAttributes.count,
      new Sort('requestedAt', QuerySortOrderTypes.DESCENDING),
      fetchAttributes.filters,
      this.props?.dispatcher?.dispatcherTimeZoneOffsetUTC
    );
    const newState = { ...this.state, eldEdits, loading: false };

    const pageCount = Math.ceil(totalELDEditsCount / fetchAttributes.count) || 1;
    if (pageCount !== newState.pageCount) newState.pageCount = pageCount; // update page count if necessary

    await this.setState(newState);
  }

  async getFilters(filters) {
    const newState = { ...this.state };
    newState.fetchAttributes = { ...newState.fetchAttributes };
    newState.fetchAttributes.filters = filters;
    newState.fetchAttributes.page = 0;

    this.setState(newState, () => {
      this.refreshState();
    });
  }

  async handlePaginatorOnChange(type, inputValue) {
    const { fetchAttributes, pageCount } = this.state;
    const newState = { ...this.state, };
    const updatedFetchAttributes = Getters.getObjectDeepCopy(fetchAttributes);
    newState.fetchAttributes = updatedFetchAttributes;

    if (type === 'PREVIOUS') {
      updatedFetchAttributes.page--;
      if (updatedFetchAttributes.page < 0) updatedFetchAttributes.page = 0; // don't let values before 0
    } else if (type === 'NEXT') {
      updatedFetchAttributes.page++;
      if (updatedFetchAttributes.page >= pageCount - 1) updatedFetchAttributes.page = pageCount - 1;
    } else if (type === 'SUBMIT') {
      let page = inputValue - 1; // the actual value is one less, since we pass +1 page in the component (so paging doesnt start at 0)

      if (page < 0) {
        page = 0;
      } else if (page >= (pageCount - 1)) {
        page = pageCount - 1;
      }
      updatedFetchAttributes.page = page;
    }

    await this.setState(newState);
    document.querySelector('.safety-hos-edit-layout').scrollTop = 0;
    return await this.refreshState();
  }

  async toggleELDEditPreview(eldEdit) {
    // toggle eldedit preview for a given eldedit
    // eldEditObjectId: { eldEditSnapshot, show: false }
    this.setState({ ...this.state, waiting: true });
    const eldEditObjectId = getAttribute(eldEdit, 'objectId');
    const newState = { ...this.state };
    newState.eldEditSnapshotHash = { ...newState.eldEditSnapshotHash };
    const eldEditSnapshotHashItem = { ...newState.eldEditSnapshotHash[eldEditObjectId] };

    eldEditSnapshotHashItem.show = !eldEditSnapshotHashItem.show;

    if (eldEditSnapshotHashItem.show) {
      // show the relevant edit information
      // get snapshot info to preview graph; requires eldEdit.completedAt to exist to give desired result
      const eldDailyCertification = getAttribute(eldEdit, 'eldDailyCertification');
      let onDateTime = getAttribute(eldEdit, 'eldDailyCertificationStartTimeUTC');
      if (eldDailyCertification) onDateTime = getAttribute(eldDailyCertification, 'startTimeUTC');
      const eldEventsSnapshot = await getELDEventsSnapshot(onDateTime, eldEdit); // returns us all the raw events info needed to make an edit snapshot
      const eldEventsUptoTheEdit = eldEventsSnapshot.simulatedELDEvents.map(matchArray => {
        // retrieve the events that are deemed to be the events up to, but not including the eldEdit we are previewing for
        return matchArray[1];
      });

      // putting together the raw events info from eldEventsSnapshot/eldEventsUptoTheEdit to generate the edit snapshot for this individual edit
      const eldEditSnapshot = await getELDEditsSnapshot(eldEventsUptoTheEdit, [eldEdit], eldEventsSnapshot.associatedELDEvents, eldEventsSnapshot.eldDailyCertification, getAttribute(eldEdit, 'driver'));
      eldEditSnapshotHashItem.eldEditSnapshot = eldEditSnapshot;
      eldEditSnapshotHashItem.eldEditSnapshot.existsAllELDEditCompletedAt = eldEventsSnapshot.existsAllELDEditCompletedAt;
    } else {
      eldEditSnapshotHashItem.eldEditSnapshot = {}; // if they turn toggle off, remove snapshot info to reduce memory usage from holding it in state (not that important tho)
    }

    newState.eldEditSnapshotHash[eldEditObjectId] = eldEditSnapshotHashItem;
    newState.waiting = false;
    this.setState(newState);
  }


  render() {
    const { state } = this;
    const { fetchAttributes } = state;

    const tableHeaderRows = [{
      key: 'sb-safety-edits-list', columns: [
        { element: <div /> },
      ]
    }];

    const tableBodyRows = state.eldEdits.map(eldEdit => {
      const objectId = getAttribute(eldEdit, 'objectId');
      const requestedAtDateTime = getAttribute(eldEdit, 'requestedAt');
      const eldEditSnapshotHashItem = state.eldEditSnapshotHash[objectId] || {};

      const driver = getAttribute(eldEdit, 'driver');
      const driverTimezoneOffsetFromUTC = getAttribute(driver, 'timezoneOffsetFromUTC');
      // dateTime: moment.tz(editTripInspectionDialog.dateTime, 'YYYY-MM-DDTHH:mm:ss', timezoneOffsetFromUTC).toDate(),

      const tableBodyRowObj = {
        key: objectId,
        columns: [],
        props: {}
      };

      tableBodyRowObj.columns = [
        {
          element: (
            <div onClick={() => this.toggleELDEditPreview(eldEdit)}>

              <SBCard className="sb-card-hover" key={objectId} isCollapsible={false} title={`Requested on ${moment.tz(requestedAtDateTime, driverTimezoneOffsetFromUTC).format('LL')}`}>
                <MDBRow>
                  <MDBCol size="1" className="d-flex justify-content-center align-items-center">
                    {eldEditSnapshotHashItem.show ?
                      <MDBIcon icon="chevron-up" /> :
                      <MDBIcon icon="chevron-down" />
                    }
                  </MDBCol>
                  <MDBCol size="11">
                    <HOSEditsRequestedView ELDEditRecord={eldEdit} />
                  </MDBCol>
                </MDBRow>

                {/* <MDBBtn
                color='primary'
                size="sm"
                disabled={state.loading}
                onClick={() => this.toggleELDEditPreview(eldEdit)}
              >
                VIEW DETAILS
              </MDBBtn> */}

                {eldEditSnapshotHashItem.show && (
                  <React.Fragment>

                    <MDBRow>
                      <MDBCol size="1" />
                      <MDBCol size="11" className="hos-edit-summary-list-column">
                        <HOSEditSummaryList eldEdit={eldEdit} />
                      </MDBCol>
                    </MDBRow>

                    {eldEditSnapshotHashItem.eldEditSnapshot.existsAllELDEditCompletedAt && (
                      <div className="edit-graph-container">
                        <HOSGraph
                          eldDailyCertification={eldEditSnapshotHashItem.eldEditSnapshot.eldDailyCertification}
                          eldEvents={eldEditSnapshotHashItem.eldEditSnapshot.eldEvents}
                          associatedELDEvents={eldEditSnapshotHashItem.eldEditSnapshot.associatedELDEvents}
                          driver={eldEditSnapshotHashItem.eldEditSnapshot.driver}
                          scaleToDriverTimezone
                          outlineEdits
                          outlineAutoGeneratedDrivingTimes
                        />
                      </div>
                    )}

                  </React.Fragment>
                )}
              </SBCard>
            </div>
          ), props: { className: 'table-body-column-style', }
        },
      ];

      return tableBodyRowObj;
    });

    return (
      <MDBContainer className="safety-hos-edit-layout px-5" fluid>
        <Title title="Hours of Service Edits" />

        <SBBlock
          title="Improved HOS Edit Summaries - Beta"
          warning
          messages={[
            'You will now be able to see summaries and previews of your edits!',
            'These new additions are still in development, so if you notice any issues please let us know!',
          ]}
          listMessages={false}
        />

        <HOSEditFilter
          getFilters={this.getFilters}
        />

        {state.waiting && (
          <SBCardEmptyContent isContentLoader>Loading Preview</SBCardEmptyContent>
        )}

        <SBTable
          containerClassName="hos-edits-table"
          tableHeaderRows={tableHeaderRows}
          tableBodyRows={tableBodyRows}
          showPaginator
          activePage={fetchAttributes.page + 1}
          pageCount={state.pageCount}
          handlePaginatorOnChange={this.handlePaginatorOnChange}
          isLoading={state.loading}
          isWaiting={state.waiting}
          emptyTableMessage={
            ((!state.loading) && (tableBodyRows.length === 0)) && <div className="text-center">No Edits found</div>
          }
          paginatorContainerClassName="paginator-container"
        />
      </MDBContainer>

    );
  }
}

export default HOSEditsLayout;
