import React from 'react';
import PropTypes from 'prop-types';

// Components
import AddEditModal from 'components/AddEditModal/container/AddEditModal';
import { MDBBtn, MDBIcon } from 'mdbreact';

class AddEditButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addEditShow: false };
    this.openAddEditModal = this.openAddEditModal.bind(this);
    this.closeAddEditModal = this.closeAddEditModal.bind(this);
  }

  async openAddEditModal() {
    await this.props.refreshState();
    this.state.addEditShow = true;
    this.setState(this.state);
  }
  async closeAddEditModal() {
    await this.props.refreshState();
    this.state.addEditShow = false;
    this.setState(this.state);
  }
  render() {
    return (
      <div className={this.props.className}>
        <AddEditModal 
          {...this.props} 
          show={this.state.addEditShow} 
          handleClose={this.closeAddEditModal} 
        />
        <MDBBtn className={"mx-auto d-inline-block " + this.props.btnClass ? this.props.btnClass : ''} size="sm" color="primary" onClick={this.openAddEditModal}>
          {!this.props.title.toLowerCase().startsWith('edit') && <MDBIcon icon="plus-square" className="mr-1"></MDBIcon>}
          {this.props.title}
        </MDBBtn>
      </div>
    );
  }
}

AddEditButton.propTypes = {
  error: PropTypes.string,
  title: PropTypes.string.isRequired,
  formTemplate: PropTypes.array.isRequired,
  handleAddEdit: PropTypes.func.isRequired,
  refreshState: PropTypes.func.isRequired,
  btnClass: PropTypes.string,
  className: PropTypes.string
};

export default AddEditButton;
