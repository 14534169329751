import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// API
import { isGoodTripNumber } from 'api/EManifest/EManifest';
import { TimeIntervals } from 'api/EManifest/TimeIntervals';

// Components
import FilterDropdown from 'components/FilterDropdown/view/FilterDropdown';
import DatePicker from 'components/DatePicker/container/FormControlDatePicker';
import ErrorModal from 'components/ErrorModal/view/ErrorModal';
import USPortCodeAutocomplete from './USPortCodeAutocomplete';

class TripSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedScacCode: undefined,
      tripNumber: undefined,
      usPortOfArrival: undefined,
      estimatedArrivalDate: undefined,
      estimatedArrivalTime: undefined,
      errors: [],
      scacCodes: [
        { attrName: 'AAAA', placeholder: 'AAAA' },
        { attrName: 'BAAA', placeholder: 'BAAA' },
        { attrName: 'BBBB', placeholder: 'BBBB' },
        { attrName: 'GGGG', placeholder: 'GGGG' },
        { attrName: 'LLLL', placeholder: 'LLLL' },
        { attrName: 'VBAA', placeholder: 'VBAA' },
        { attrName: 'VBAB', placeholder: 'VBAB' },
      ],
      timeIntervals: TimeIntervals.map(time => ({ attrName: time, placeholder: time })),
    };
    this.handleScacDropdown = this.handleScacDropdown.bind(this);
    this.handleTimeDropdown = this.handleTimeDropdown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.portCodeHandler = this.portCodeHandler.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.saveProgress = this.saveProgress.bind(this);
    this.closeErrorModal = this.closeErrorModal.bind(this);
  }

  handleScacDropdown(scacCode) {
    this.setState({ ...this.state, selectedScacCode: scacCode });
  }

  handleTimeDropdown(time) {
    this.setState({ ...this.state, estimatedArrivalTime: time });
  }

  handleChange(e) {
    const { target } = e;
    if (target.id === 'tripNumber') {
      this.state[target.id] = target.value.toUpperCase();
    } else {
      this.state[target.id] = target.value;
    }
    this.setState(this.state);
  }

  portCodeHandler(portCode) {
    this.setState({ ...this.state, usPortOfArrival: portCode });
  }

  handleDateChange(date) {
    this.setState({ ...this.state, estimatedArrivalDate: date });
  }

  closeErrorModal() {
    this.setState({ ...this.state, errors: [] });
  }

  saveProgress() {
    const {
      usPortOfArrival, selectedScacCode, tripNumber,
      estimatedArrivalDate, estimatedArrivalTime,
    } = this.state;
    const tripSummary = {};
    let errorId = 0;
    const errors = [];

    // format scac code
    if (!selectedScacCode) {
      tripSummary.tripNumber = `AAAA${tripNumber}`;
    } else {
      tripSummary.tripNumber = `${selectedScacCode}${tripNumber}`;
    }

    tripSummary.usPortOfArrival = usPortOfArrival;

    const formattedDate = moment(estimatedArrivalDate).format('YYYY-MM-DD');
    if (!estimatedArrivalTime) {
      tripSummary.estimatedArrivalDateTime = `${formattedDate} 00:00:00`;
    } else {
      tripSummary.estimatedArrivalDateTime = `${formattedDate} ${estimatedArrivalTime}:00`;
    }

    // All checks of validation
    if (!tripNumber && !isGoodTripNumber(tripSummary.tripNumber, 'ace')) {
      errors.push({ errorId, error: 'Invalid Trip Number Format' });
      errorId++;
    }

    if (!(estimatedArrivalDate && estimatedArrivalTime)) {
      errors.push({ errorId, error: 'Invalid Arrival Date or Time format' });
      errorId++;
    }

    if (errors.length > 0) {
      const newState = { ...this.state, errors };
      this.setState({ ...this.state, errors });
      console.log(newState);
      return;
    }

    // tripNumber
    console.log(tripSummary);
  }

  render() {
    return (
      <span>
        <div className="horizontalTable card">
          <table>
            <tbody>
              <tr className="title">
                <td>ACE eManifest</td>
                <td>
                  <button className="buttonDefault buttonSpacedRight" onClick={this.saveProgress}>Save</button>
                  <button className="buttonDefault">Clear</button>
                </td>
              </tr>
              <tr>
                <td>Trip Number</td>
                <td>
                  <FilterDropdown
                    id="scacCode"
                    title="AAAA"
                    handleFilter={this.handleScacDropdown}
                    menuItems={this.state.scacCodes}
                  />
                  <input type="text" id="tripNumber" value={this.state.tripNumber} onChange={(e) => this.handleChange(e)} />
                </td>
              </tr>
              <tr>
                <td>U.S. Port of Arrival</td>
                <td><USPortCodeAutocomplete portCodeHandler={this.portCodeHandler} /></td>
              </tr>
              <tr>
                <td>Estimated Arrival Date</td>
                <td>
                  <DatePicker
                    className="input"
                    type="date"
                    placeholder="12/31/16"
                    selected={this.state.estimatedArrivalDate}
                    onChange={(date) => this.handleDateChange(date)}
                    autoComplete="off"
                  />
                </td>
              </tr>
              <tr>
                <td>Estimated Arrival Time</td>
                <td>
                  <FilterDropdown
                    id="estimatedArrivalTime"
                    title="00:00"
                    handleFilter={this.handleTimeDropdown}
                    menuItems={this.state.timeIntervals}
                  />
                </td>
              </tr>
              <tr>
                <td>Assign Truck</td>
                <td>hi</td>
              </tr>
              <tr>
                <td className="textGreen">Assign Trailer</td>
                <td>hi</td>
              </tr>
              <tr>
                <td>Assign Driver</td>
                <td>hi</td>
              </tr>
            </tbody>
          </table>
        </div>
        <ErrorModal show={this.state.errors.length > 0} handleClose={this.closeErrorModal}>
          <div>There's a problem with the following required fields</div>
          <div>&nbsp;</div>
          <ul>
            {
              this.state.errors.map(errorObject => <li key={errorObject.errorId}>{errorObject.error}</li>)
            }
          </ul>
        </ErrorModal>
      </span>
    );
  }
}

TripSummary.propTypes = {
};

export default TripSummary;
