import React from 'react';
import PropTypes from 'prop-types';

// Components
import AddNewCompanyUserModal from 'components/AddNewCompanyUser/container/AddNewCompanyUserModal';
import { MDBBtn } from 'mdbreact';

class AddNewCompanyUserButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addEditShow: false };
    this.openAddNewCompanyUserModal = this.openAddNewCompanyUserModal.bind(this);
    this.closeAddNewCompanyUserModal = this.closeAddNewCompanyUserModal.bind(this);
  }

  openAddNewCompanyUserModal() {
    const newState = { ...this.state };
    newState.addEditShow = true;
    this.setState(newState);
  }
  closeAddNewCompanyUserModal() {
    const newState = { ...this.state };
    newState.addEditShow = false;
    this.setState(newState);
    this.props.refreshState();
  }
  render() {
    return (
      <div className="inline">
        <AddNewCompanyUserModal
          {...this.props}
          show={this.state.addEditShow}
          handleClose={this.closeAddNewCompanyUserModal}
        />
        <MDBBtn
          className="m-0"
          color="primary"
          size="sm"
          onClick={this.openAddNewCompanyUserModal}
          style={{ width: '12rem' }}
        >
          Add New Company User
        </MDBBtn>
      </div>
    );
  }
}

AddNewCompanyUserButton.propTypes = {
  handleAddNewCompanyUser: PropTypes.any,
  refreshState: PropTypes.func.isRequired,
  btnClass: PropTypes.string,
};

export default AddNewCompanyUserButton;
