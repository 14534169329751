// API
import moment from 'moment-timezone';

import {
  createQuery, createQueryOr, copyQuery, sortQuery,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers,
  setQueryRestriction, setReturnSelectAttributes,
  findRecords, count,
  addRecord,
  getRecordByObjectId,
  updateRecord,
} from 'sb-csapi/dist/AAPI';

// Enums
import { QuerySortOrder, QueryRestriction } from 'sb-csapi/dist/enums/Query';

// Sort
import Sort from 'sb-csapi/dist/sbObjects/Sort';

// Filter
import Filter from 'sb-csapi/dist/sbObjects/Filter';

const IFTA_ROUTE_TABLE = 'IFTARoute_Beta';

/**
 * @memberof module:IFTARoute
 * @description Obtains IFTARoutes from a company
 *
 * @param {Object} options - See example
 * @param {String} companyObjectId - Company we wish to retrieve IFTARoutes for
 * @param {bool} [includeChildCompanies] - Include child/sub-companies of this parent company
 * @param {Array} [filters] - Array of filters
 * @param {Object} [sortBy] - Sort object
 * @param {Array} [includedPointers] - Included pointers
 * @param {Array} [selectedAttributes] - Select attributes to return
 * @param {int} [page] - The current page for pagination
 * @param {int} [limit] - The limit of records obtained per pagination
 * @param {bool} [queryAll] - Get all IFTARoute records
 *
 * @returns {Object} - { totalIFTARoutesCount: 0, iftaRoutes: [] }
 */
async function getIFTARoutes(
  options = { sessionToken: getCurrentUserSessionToken() },
  companyObjectId = getCurrentUserCompanyObjectId(),
  includeChildCompanies = false,
  filters = [],
  sortBy = new Sort(QuerySortOrder.ASCENDING, 'dateStart'),
  includedPointers = [],
  selectedAttributes = [],
  page = 0,
  limit = 20,
  queryAll = false,
) {
  let iftaRouteQuery = createQuery(IFTA_ROUTE_TABLE);

  const _filters = [...filters];

  const companyQuery = createQuery('Company');
  setQueryRestriction(companyQuery, QueryRestriction.EQUAL_TO, 'objectId', companyObjectId);

  if (includeChildCompanies) {
    // Check to see if there are any child companies, and if so, create queries that get the IFTARoutes of them
    const companyLinkQuery = createQuery('CompanyLink');

    const companyLinkFilters = [
      new Filter(QueryRestriction.MATCHES_QUERY, 'parentCompany', companyQuery),
      new Filter(QueryRestriction.EQUAL_TO, 'authorized', true),
    ];

    companyLinkFilters.map(filter => setQueryRestriction(companyLinkQuery, filter.queryRestriction, filter.attribute, filter.value));

    const companyLinks = await findRecords(options, companyLinkQuery);
    const childCompanyIFTARouteQueries = companyLinks.map((company) => {
      const childCompanyIFTARouteQuery = createQuery(IFTA_ROUTE_TABLE);

      setQueryRestriction(childCompanyIFTARouteQuery, QueryRestriction.EQUAL_TO, 'belongsToCompany', getAttribute(company, 'childCompany'));
      return childCompanyIFTARouteQuery;
    });

    // The main current user's company's IFTARoute query
    const mainCompanyIFTARouteQuery = createQuery(IFTA_ROUTE_TABLE);
    setQueryRestriction(mainCompanyIFTARouteQuery, QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery);

    // Altogether
    iftaRouteQuery = createQueryOr([mainCompanyIFTARouteQuery, ...childCompanyIFTARouteQueries]);

  } else {
    // Lock queries to the current users company
    setQueryRestriction(iftaRouteQuery, QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery);
  }

  // Set universal filters
  _filters.push(new Filter(QueryRestriction.NOT_EQUAL_TO, 'isHidden', true));

  _filters.map(filter => setQueryRestriction(iftaRouteQuery, filter.queryRestriction, filter.attribute, filter.value));

  // At this point, copy current query to get the number of pages for pagination
  const iftaRouteCountQuery = copyQuery(iftaRouteQuery);

  // Sort
  sortQuery(iftaRouteQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(iftaRouteQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(iftaRouteQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(iftaRouteQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(iftaRouteQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // Now get the count and the IFTARoutes
  const promises = [count(options, iftaRouteCountQuery), findRecords(options, iftaRouteQuery, false, queryAll)];

  try {
    const [totalIFTARoutesCount, iftaRoutes] = await Promise.all(promises);
    return { totalIFTARoutesCount, iftaRoutes };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:IFTARoute
 * @description Retrieves the timezone from a driver if applicable, otherwise returns a guess of the timezone
 *
 * @param {IFTARoute} iftaRoute - iftaRoute record
 *
 * @returns {String} Returns the timezone retrieved from the driver if applicable
 */
function getTimezoneOffsetFromUTC(iftaRoute) {
  let timezoneOffsetFromUTC = moment.tz.guess();
  const iftaRouteDriverPeriods = iftaRoute && getAttribute(iftaRoute, 'iftaRouteDriverPeriods');
  const driver = iftaRouteDriverPeriods && iftaRouteDriverPeriods.length > 0 && getAttribute(iftaRouteDriverPeriods[0], 'driver');

  if (!driver) return timezoneOffsetFromUTC;

  timezoneOffsetFromUTC = getAttribute(driver, 'timezoneOffsetFromUTC') || moment.tz.guess();
  return timezoneOffsetFromUTC;
}

/**
 * @memberof module:IFTARoute
 * @description Creates a new IFTARoute record from the given object
 *
 * @param {Object} iftaRouteObject - A key value object representing the IFTARoute
 *
 * @returns {IFTARoute} Returns the created IFTARoute record
 */
async function addIFTARoute(iftaRouteObject) {
  if (!iftaRouteObject.belongsToCompany) throw new Error('Cannot create record: missing belongsToCompany pointer');

  try {
    const iftaRoute = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'IFTARoute_Beta', iftaRouteObject, getCurrentUserCompanyObjectId());
    return iftaRoute;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:IFTARoute
 *
 * @description Updates an existing IFTARoute record with the updated object
 *
 * @param {String} iftaRouteObjectId - The objectId of the IFTARoute record
 * @param {Object} iftaRouteObject - A key value object representing the IFTARoute
 * @param {bool} save - Whether to save the changes to db or not
 *
 * @returns {IFTARoute} Returns the updated IFTARoute record
 */
async function updateIFTARoute(iftaRouteObjectId, iftaRouteObject, save) {
  try {
    const iftaRoute = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'IFTARoute_Beta', iftaRouteObjectId, undefined, undefined);

    if (!iftaRoute) {
      throw new Error(`Error: No IFTARoute found with objectId ${iftaRouteObjectId}`);
    }

    return await updateRecord(
      { sessionToken: getCurrentUserSessionToken() },
      iftaRoute,
      iftaRouteObject,
      save,
    );
  } catch (err) {
    throw new Error(err);
  }
}

export {
  addIFTARoute,
  updateIFTARoute,
  getIFTARoutes,
  getTimezoneOffsetFromUTC,
};
