import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import * as Getters from 'api/Getters'
import { getAttribute } from 'api/Parse';
import { MDBBadge } from 'mdbreact';

import styles from './ChatModule.module.scss';

import * as ChatActs from 'actions/Chat';
import * as ChatAPI from 'api/Chat';

import ChatModuleAppBar from './ChatModuleAppBar';
import ChatBox from './ChatBox';

import FriendsList from '../view/FriendsList';

import Snackbar from 'material-ui/Snackbar';
import Drawer from 'material-ui/Drawer';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import { MDBIcon } from 'mdbreact';

// Context
import StoreContext from 'contexts/StoreContext';

class ChatModule extends React.Component {

  constructor(props) {
    super(props);
    // right now only this component supports 1-to-1 chat. the others are 98% cleared for multi-chat
    this.state = {
      open: false,
      filterString: '',
      selectedFriends: undefined, // current friend chosen
      roomId: undefined,
      dialogMsgAllFriends: {
        show: false,
      },
      snackbar: {
        activate: false,
        message: '',
      },
      unreadCount: 0
    };

    this.refreshComponent = this.refreshComponent.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleToggleManually = this.handleToggleManually.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleSearchInput = this.handleSearchInput.bind(this);
    this.openChatBox = this.openChatBox.bind(this);
    this.handleChatClose = this.handleChatClose.bind(this);
    this.toggleMsgAllFriendsDialog = this.toggleMsgAllFriendsDialog.bind(this);
    this.sendMessageToAll = this.sendMessageToAll.bind(this);
    this.countUnreadChat = this.countUnreadChat.bind(this);

  }

  componentDidMount = () => {
    this.refreshComponent();
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.Chat.chats !== this.props.Chat.chats) {
      const newChats = nextProps.Chat.chats
      const newChatsKeyArry = Object.keys(newChats);

      let friendsHash = {};

      const mostRecentChatsKeys = newChatsKeyArry.sort((a, b) => { if(moment(getAttribute(newChats[a].room, 'updatedAt')).isAfter(moment(getAttribute(newChats[b].room, 'updatedAt')))) return -1 });
      const mostRecentChat = newChats[mostRecentChatsKeys[0]];

      this.countUnreadChat(newChats, mostRecentChatsKeys);

      // console.log(mostRecentChat)
      if (mostRecentChat.unreadCount && this.state.selectedFriends){
        if(this.state.selectedFriends.length === 0) {
          const sender = getAttribute(getAttribute(mostRecentChat.room,'lastMessage'), 'sender');
          const senderId = getAttribute(sender, 'objectId');
          this.props.Chat.friends.map(friend=>{
            const userId = getAttribute(getAttribute(friend, 'user'), 'objectId');
            friendsHash[userId] = friend;
          })
          const selectedFriends = friendsHash[senderId];
          this.openChatBox(selectedFriends);
        }
      }
      else if (mostRecentChat.unreadCount && !this.state.selectedFriends){
        const sender = getAttribute(getAttribute(mostRecentChat.room,'lastMessage'), 'sender');
        const senderId = getAttribute(sender, 'objectId');
        this.props.Chat.friends.map(friend=>{
          const userId = getAttribute(getAttribute(friend, 'user'), 'objectId');
          friendsHash[userId] = friend;
        })
        const selectedFriends = friendsHash[senderId]
        this.openChatBox(selectedFriends);
      }
    }
  }

  componentWillUnmount() {
    // console.log('unmounted. delete state');
  }

  refreshComponent = () => {
    ChatActs.deleteChatFriendsFromState().then(
      () => {
        ChatActs.fetchChatFriendsForState();
      }
    );
  }

  handleUpdate = () => this.refreshComponent();

  handleToggle = () => this.setState({ open: !this.state.open });

  handleToggleManually = (isOpen) => this.setState({ open: isOpen });

  handleChange = (open, reason) => this.setState({ open });

  handleSearchInput = (e) => {
    if (e.target.value) {
      this.setState({ ...this.state, filterString: e.target.value.trim().toLowerCase() });
    } else {
      this.setState({ ...this.state, filterString: '' });
    }
  }

  openChatBox = (selectedFriends) => {
    // const friendUsers = selectedFriends.get('user');
    ChatActs.fetchChatRoomForState(undefined, [selectedFriends]).then(
      chatRoom => {
        this.setState({ ...this.state, selectedFriends: [selectedFriends], roomId: chatRoom.get('roomId') });
      }
    );
  }

  handleChatClose = () => {
    const promise = new Promise(resolve => {
      this.setState({ ...this.state, selectedFriends: undefined }, () => {
        resolve(true);
      });
    });
    return promise;
  }

  toggleMsgAllFriendsDialog = () => {
    const show = !this.state.dialogMsgAllFriends.show;
    this.setState({ ...this.state, dialogMsgAllFriends: { show } });
  }

  sendMessageToAll = () => {
    let message = document.getElementById('sendAllMsgTextArea').value;
    if (message) {
      message = message.trim();
      for (let i = 0; i < this.props.Chat.friends.length; i++) {
        (() => {
          const friend = this.props.Chat.friends[i];
          ChatAPI.retrieveChatRoom(undefined, [friend]).then(
            chatRoom => {
              ChatActs.sendChatMessage(chatRoom.get('roomId'), message, [friend]);
            }
          );
        })();
      }

      this.setState({ ...this.state, dialogMsgAllFriends: { show: false }, snackbar: { activate: true, message: 'Message Sent' } });
    }
  }

  countUnreadChat(chats, sortedChatsKeys){
    let unreadCount = 0;
    sortedChatsKeys.map(sortedChatsKey =>{
      if (chats[sortedChatsKey].unreadCount){
        unreadCount++;
      }
    });

    window.todesktop && window.todesktop.app.setBadgeCount(unreadCount); // ToDesktop

    this.setState({ ...this.state, unreadCount });
  }

  render() {
    const { Chat, toggleButtonStyle } = this.props;
    const { open, filterString, selectedFriends, roomId, dialogMsgAllFriends, snackbar } = this.state;
    const { props, state } = this;
    const msgAllDriversActions = [
      <FlatButton
        key="cancel"
        label="Cancel"
        default
        onClick={this.toggleMsgAllFriendsDialog}
      />,
      <FlatButton
        key="send"
        label="Send"
        primary
        onClick={this.sendMessageToAll}
      />,
    ];

    const chats = Chat.chats;
    ChatAPI.retrieveChatRooms().then(function (chatRooms) {
      var me = Getters.getCurrentUser();
      var r = 0;
      for (r = 0; r < chatRooms.length; r++) {
        var room = chatRooms[r];
        var msg = room.get('lastMessage');
        const roomId = room.get('roomId');
        if (msg && msg.get('sender')) {
          if (me.id !== msg.get('sender').id && JSON.stringify(msg.get("seenBy")).indexOf(me.id) == -1) {
            chats[roomId] = { room, messages: [], fetched: false, unreadCount: 1 };
          }
        }
      }
    });

    let unreadMessagesAlert = '';
    const roomIds = Object.keys(chats);
    for (let i = 0; i < roomIds.length; i++) {
      const chatRoom = chats[roomIds[i]];
      if (chatRoom.unreadCount > 0) {
        unreadMessagesAlert = styles.unreadMessagesAlert;
        break;
      }
    }

    return (
      <div className={styles.chatModule}>
        <a className={`${styles.sticky} translate-me`} onClick={this.handleToggle}>
          <span className={`${unreadMessagesAlert} ${styles.newMessage} glyphicon glyphicon-asterisk mr-large`}></span>
          DRIVER CHAT
          <MDBIcon icon="chevron-down" className="ml-2" />
          { state.unreadCount ?
            <MDBBadge color="danger" className="ml-2" >{ state.unreadCount }</MDBBadge>
            :
            <span></span>
          }
        </a>
        <Drawer
          containerClassName={styles.drawerContainer}
          overlayClassName={styles.overlay}
          width={300}
          openSecondary
          open={this.state.open}
          onRequestChange={this.handleChange}
        >
          <div className={styles.childrenContainer}>

            <div className={styles.chatModuleAppBarContainer}>
              <ChatModuleAppBar onLeftIconButtonTouchTap={this.handleToggle} />
            </div>

            <div className={`${styles.alertAllButtonContainer} translate-me`}>
              {Chat && Chat.friends && Chat.friends.length > 0 &&
                <RaisedButton
                  primary
                  label="Message All Drivers"
                  onClick={this.toggleMsgAllFriendsDialog}
                  buttonStyle={{ fontSize: '.8em' }}
                />
              }
            </div>

            <div className={styles.friendsListContainer}>
              <FriendsList Chat={Chat} filterString={filterString} onFriendsSelect={this.openChatBox} />
            </div>

            <div className={`${styles.userSearchTextFieldContainer} translate-me`}>
              <input
                className={styles.userSearchTextField}
                placeholder="Search User or Vehicle..."
                onChange={this.handleSearchInput}
              />
            </div>
          </div>
        </Drawer>

        {selectedFriends && selectedFriends.length > 0 &&
          <ChatBox
            chatRoom={this.props.Chat.chats[roomId]}
            roomId={roomId}
            friends={selectedFriends}
            shiftLeftForFriendsList={open}
            handleChatClose={this.handleChatClose}
          />
        }

        {dialogMsgAllFriends.show &&
          <Dialog
            title="Send Message to All Drivers"
            actions={msgAllDriversActions}
            modal
            open={dialogMsgAllFriends.show}
            onRequestClose={this.toggleMsgAllFriendsDialog}
            contentStyle={{ width: '40em' }}
            className="translate-me"
          >
            <div>Enter your message below:</div>
            <textarea id="sendAllMsgTextArea" style={{ height: '10em' }} />
          </Dialog>
        }

        <Snackbar
          open={snackbar.activate}
          message={snackbar.message}
          autoHideDuration={4000}
          onRequestClose={() => { this.setState({ ...this.state, snackbar: { activate: false, message: '' } }); }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => { // update component based on changes to Driver or Company
  const { Chat, Company } = state;
  return {
    Chat,
    Company,
  };
};

ChatModule.propTypes = {
  handleUpdate: PropTypes.func, // fn to call when want to force update chat component
  toggleButtonStyle: PropTypes.string,
  iconStyle: PropTypes.string,
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(ChatModule);
