import { removeDuplicatesByObjectId } from 'api/Helpers';
import * as ActionConstants from '../actions/ActionConstants';

const initialState = {
  vehicleLocations: [],
  fetched: false, // Whether or not drivers are loaded
};

export function VehicleLocation(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_VEHICLELOCATIONS_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.FETCH_VEHICLELOCATIONS_INPROGRESS,
        fetched: false,
        error: undefined,
      };
    }
    case ActionConstants.FETCH_VEHICLELOCATIONS_SUCCESS: {
      return {
        status: ActionConstants.FETCH_VEHICLELOCATIONS_SUCCESS,
        vehicleLocations: removeDuplicatesByObjectId(state.vehicleLocations.concat(action.vehicleLocations)),
        updated: action.vehicleLocations,
        error: undefined,
        fetched: true,
      };
    }
    case ActionConstants.FETCH_VEHICLELOCATIONS_ERROR: {
      return {
        ...state,
        status: ActionConstants.FETCH_VEHICLELOCATIONS_ERROR,
        error: action.error,
        fetched: false,
      };
    }
    case ActionConstants.DELETE_VEHICLELOCATIONS: {
      return {
        ...initialState,
        status: action.type,
      };
    }
    default:
      return state;
  }
}
