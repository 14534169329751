import React from 'react';
import Parse from 'parse';

// API
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import * as Helpers from 'api/Helpers';
import * as Analytics from 'api/Analytics';
import { getAttribute } from 'api/Parse';
import { t } from 'api/Translate';
import {
  getCompanyAnalytics,
  updateCompanyAnalytics,
} from 'api/CompanyAnalytics/CompanyAnalytics';

// Components
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import Tooltip from 'sbCore/Tooltip/Tooltip';
import IconLabel from 'sbCore/IconLabel/IconLabel';
import AssociatedTrailers from 'components/DriverDetails/AssociatedTrailers/AssociatedTrailers';

// CSS
import styles from './DriverDetailsInfoTable.module.scss';

function DriverDetailsInfoTable(props) {
  const [companyAnalytics, setCompanyAnalytics] = React.useState(undefined);
  const [showUpdateSoftwareModal, setShowUpdateSoftwareModal] = React.useState(false);

  React.useEffect(() => {
    const getAnalytics = async () => {
      const companyAnalytics = await getCompanyAnalytics();
      setCompanyAnalytics(companyAnalytics);
    }

    getAnalytics();
  }, []);

  const { driver, driverFetched } = props;

  let driverDispatcherNames = t('Assign Dispatcher by clicking Edit Driver');

  if (driver && driver.get('dispatchers')[0] !== null) {
    const filteredDispatchers = driver.get('dispatchers').filter(dispatcher => dispatcher.get('user') && dispatcher.get('user').id);

    if (driverDispatcherNames.length > 0) {
      driverDispatcherNames = filteredDispatchers.map(dispatcher => {
        const dispatcherUser = dispatcher.get('user');
        return `${dispatcherUser.get('firstName')} ${dispatcherUser.get('lastName')}`;
      });

      driverDispatcherNames = driverDispatcherNames.join(', ');
    }
  }

  let deviceMode = 'N/A';
  let eldSerial = 'N/A';
  const vehicle = driver && driver.get('vehicle');
  if (vehicle && vehicle.get('eldHardware')) {
    deviceMode = vehicle.get('eldHardware').get('aobrdEnabled') ? 'AOBRD' : 'ELD';
    eldSerial = vehicle.get('eldHardware').get('serial') || 'N/A';
  }

  const vehicle_unitId = (driver && getAttribute(driver, 'vehicle_unitId', true)) || 'N/A';
  const defaultVehicle = driver && getAttribute(driver, 'defaultVehicle', true);
  const defaultVehicle_unitId = (defaultVehicle && getAttribute(defaultVehicle, 'unitId')) || 'N/A';

  let driverDOTNumber = 'N/A';
  let driverNSCNumber = 'N/A';
  if (driver) {
    // dotNumber
    if (driver.get('dotNumber')) {
      driverDOTNumber = driver.get('dotNumber');
    } else if (driver.get('driverCompany')) {
      driverDOTNumber = driver.get('driverCompany').get('dotNumber');
    } else if (driver.get('belongsToCompany').get('dotNumber')) {
      driverDOTNumber = driver.get('belongsToCompany').get('dotNumber');
    }

    // nscNumber
    if (driver.get('nscNumber')) {
      driverNSCNumber = driver.get('nscNumber');
    } else if (driver.get('driverCompany')) {
      driverNSCNumber = driver.get('driverCompany').get('nscNumber');
    } else if (driver.get('belongsToCompany').get('nscNumber')) {
      driverNSCNumber = driver.get('belongsToCompany').get('nscNumber');
    }
  }

  window.Localize.translatePage();

  return (
    <MDBContainer className={`p-0 ${styles.driverDetailsInfoTable}`} fluid>

      <div className={`${styles.subheader} translate-me`}>General Details</div>
      <MDBContainer className={`${styles.section}`} fluid>
        <MDBRow className="translate-me">
          <MDBCol className={styles.columnName} size="3">
            <div>Username</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>Full Name</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>E-mail Address</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>Phone Number</div>
          </MDBCol>
        </MDBRow>

        {driverFetched &&
          <MDBRow>
            <MDBCol className={styles.columnValue} size="3">
              <div>{driver.get('user').get('username')}</div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div>{Helpers.formatName(`${driver.get('user').get('firstName')} ${driver.get('user').get('lastName')}`)}</div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div>{driver.get('user_email')}</div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div>{driver.get('user').get('phoneNumber')}</div>
            </MDBCol>
          </MDBRow>
        }
      </MDBContainer>


      <MDBContainer className={`${styles.section}`} fluid>
        <MDBRow className="translate-me">
          <MDBCol className={styles.columnName} size="3">
            <div>Driver&apos;s License</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>License Country</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>License State/Province</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>License Expiry</div>
          </MDBCol>
        </MDBRow>

        {driverFetched &&
          <MDBRow>
            <MDBCol className={styles.columnValue} size="3">
              <div>{driver.get('driversLicense')}</div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div>{driver.get('driversLicenseCountry')}</div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div>{driver.get('driversLicenseJurisdiction')}</div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div>
                {driver.get('licenseExpiryDate') && moment(driver.get('licenseExpiryDate')).subtract(4, 'months').isBefore(moment()) &&
                  <span className={styles.alert}>{moment(driver.get('licenseExpiryDate')).format('LL')}</span>
                }
                {driver.get('licenseExpiryDate') && !moment(driver.get('licenseExpiryDate')).subtract(4, 'months').isBefore(moment()) &&
                  <span>{moment(driver.get('licenseExpiryDate')).format('LL')}</span>
                }
                {!driver.get('licenseExpiryDate') &&
                  <span className={`${styles.alert} translate-me`}>No license expiry</span>
                }
              </div>
            </MDBCol>
          </MDBRow>
        }
      </MDBContainer>

      <MDBContainer className={`${styles.section}`} fluid>
        <MDBRow className="translate-me">
          <MDBCol className={styles.columnName} size="3">
            <div>Medical Expiry</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>Dispatchers</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>Driver DOT Number</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>Driver NSC Number</div>
          </MDBCol>

        </MDBRow>

        {driverFetched &&
          <MDBRow>
            <MDBCol className={styles.columnValue} size="3">
              <div>
                {driver.get('medicalCheckDate') && moment(driver.get('medicalCheckDate')).isBefore(moment()) &&
                  <span className={styles.alert}>{moment(driver.get('medicalCheckDate')).format('LL')}</span>
                }
                {driver.get('medicalCheckDate') && !moment(driver.get('medicalCheckDate')).isBefore(moment()) &&
                  <span>{moment(driver.get('medicalCheckDate')).format('LL')}</span>
                }
                {!driver.get('medicalCheckDate') &&
                  <span className="translate-me">No medical expiry</span>
                }
              </div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div>{driverDispatcherNames}</div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div>{driverDOTNumber}</div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div>{driverNSCNumber}</div>
            </MDBCol>
          </MDBRow>
        }
      </MDBContainer>

      <MDBContainer className={`${styles.section}`} fluid>
        <MDBRow className="translate-me">
          <MDBCol className={styles.columnName} size="3">
            <div>Notes</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>Home Terminal</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>Default Timezone</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>Date Added</div>
          </MDBCol>
        </MDBRow>

        {driverFetched &&
          <MDBRow>
            <MDBCol className={styles.columnValue} size="3">
              <div>
                <textarea
                  value={driver.get('notes')}
                  placeholder="Add a note by clicking Edit Driver"
                  readOnly
                />
              </div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div>
                {
                  (props.driver.get('altTerminalAddress') && props.driver.get('altTerminalAddress').get('address'))
                  || (props.driver.get('belongsToCompany').get('address') && props.driver.get('belongsToCompany').get('address').get('address'))
                  || 'N/A'
                }
              </div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div>{driver.get('timezoneOffsetFromUTC')}</div>
            </MDBCol>
            {/* <MDBCol className={styles.columnValue} size="3">
              <div>{moment(driver.get('createdAt')).format('MMMM D, YYYY')}</div>
            </MDBCol> */}
            <MDBCol className={styles.columnValue} size="3">
              <div>
                { (driver.id === 'bXA479CKjj') ? moment('2024-04-01').format('MMMM D, YYYY') : moment(driver.get('createdAt')).format('MMMM D, YYYY') }
              </div>
            </MDBCol>
          </MDBRow>
        }
      </MDBContainer>

      {/* Associated equipment */}
      <div className={`mt-5 ${styles.subheader} translate-me`}>Equipment</div>
      <MDBContainer className={`${styles.section} translate-me`} fluid>
        <MDBRow className="translate-me">
          <MDBCol className={styles.columnName} size="3">
            <Tooltip target=".last-used-vehicle-tt" />
            Vehicle
            <div
              className="inline-block ml-2 last-used-vehicle-tt"
              data-pr-tooltip="Most recently used vehicle by this driver"
              data-pr-position="right"
            >
              <IconLabel
                iconClassName="pi pi-question-circle text-blue"
              />
            </div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <Tooltip target=".last-used-trailer-tt" />
            Last Used Trailer
            <div
              className="inline-block ml-2 last-used-trailer-tt"
              data-pr-tooltip={`The trailer(s) last used by this Driver${(driver && driver.get('vehicle_unitId')) ? ` with Vehicle ${vehicle_unitId}` : ''}`}
              data-pr-position="right"
            >
              <IconLabel
                iconClassName="pi pi-question-circle text-blue"
              />
            </div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="6">
            <Tooltip target=".default-vehicle-tt" />
            Default Vehicle
            <div
              className="inline-block ml-2 default-vehicle-tt"
              data-pr-tooltip="This vehicle is the driver's default selection when they login"
              data-pr-position="right"
            >
              <IconLabel
                iconClassName="pi pi-question-circle text-blue"
              />
            </div>
          </MDBCol>
        </MDBRow>
        {driverFetched && (
          <MDBRow>
            <MDBCol className={styles.columnValue} size="3">
              <div>{vehicle_unitId}</div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <AssociatedTrailers driver={driver} />
            </MDBCol>
            <MDBCol className={styles.columnValue} size="6">
              <div>{defaultVehicle_unitId}</div>
            </MDBCol>
          </MDBRow>
        )}
      </MDBContainer>

      <div className={`mt-5 ${styles.subheader} translate-me`}>Device Details</div>
      <MDBContainer className={`${styles.section} translate-me`} fluid>
        <MDBRow>
          <MDBCol className={styles.columnName} size="3">
            <div>ELD/AOBRD Mode</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>ELD Serial</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>ELD Version</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>Device IMEI</div>
          </MDBCol>
        </MDBRow>

        {driverFetched &&
          (
            <MDBRow>
              <MDBCol className={styles.columnValue} size="3">
                <div>{deviceMode}</div>
              </MDBCol>
              <MDBCol className={styles.columnValue} size="3">
                <div>{eldSerial}</div>
              </MDBCol>
              <MDBCol className={styles.columnValue} size="3">
                <div>{driver.get('currentELDVersion') || 'N/A'}</div>
              </MDBCol>
              <MDBCol className={styles.columnValue} size="3">
                <table><tr>
                  <td className="verticalAlignMiddle">
                    {driver.get('deviceIMEI') || 'N/A'}
                    {props.sim.status &&
                      (
                        <div>
                          <kbd>{props.sim.status}</kbd>

                        </div>
                      )
                    }
                  </td>
                  <td className="verticalAlignMiddle">
                    {props.sim.iccid && props.sim.simVendor === 'Twilio' && props.sim.status && props.sim.status.toLowerCase() === 'active' &&
                      (
                        <div>
                          <MDBBtn
                            size="sm"
                            color="default"
                            onClick={async () => {
                              if (companyAnalytics) {
                                // Check company analytics status
                                const DRIVRESETDATA = getAttribute(companyAnalytics, 'DRIVRESETDATA');
                                if (!DRIVRESETDATA) {
                                  Analytics.track('Reset Data Selected');
                                  await updateCompanyAnalytics(companyAnalytics, { DRIVRESETDATA: true });
                                }
                              }
                              props.showResetDataModal();
                            }}
                            className="translate-me"
                          >
                            Reset Data
                          </MDBBtn>
                        </div>
                      )
                    }
                    {driver.get('deviceIMEI') && driver.get('deviceIMEI').substr(0, 4) === '3515' &&
                      (
                        <div>
                          <MDBBtn
                            size="sm"
                            color="default"
                            onClick={async () => {
                              if (companyAnalytics) {
                                // Check company analytics status
                                const DRIVFORCEUPD = getAttribute(companyAnalytics, 'DRIVFORCEUPD');
                                if (!DRIVFORCEUPD) {
                                  Analytics.track('Force Update');
                                  await updateCompanyAnalytics(companyAnalytics, { DRIVFORCEUPD: true });
                                }
                              }
                              props.showForceUpdateModal();
                            }}
                            className="translate-me"
                          >
                            Force Update
                          </MDBBtn>
                        </div>
                      )
                    }
                    {props.sim.status && props.sim.status.toLowerCase() !== 'active' &&
                      (
                        <div>
                          <div>
                            <MDBBtn
                              size="sm"
                              color="default"
                              onClick={async () => {
                                props.showActivateDeviceDialog();
                              }}
                              className="translate-me"
                            >
                              Request Activation
                            </MDBBtn>
                          </div>
                        </div>
                      )
                    }
                  </td>
                </tr></table>
              </MDBCol>
            </MDBRow>
          )
        }
      </MDBContainer>

      <MDBContainer className={`${styles.section}`} fluid>
        <MDBRow className="translate-me">
          <MDBCol className={styles.columnName} size="3">
            <div>Exempt Status</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>Exemption Reason</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>Yard Moves</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
            <div>Personal Use CMV</div>
          </MDBCol>
        </MDBRow>

        {driverFetched &&
          <MDBRow>
            <MDBCol className={styles.columnValue} size="3">
              <div className="translate-me">
                {driver.get('exempt') ?
                  <span>Exempt</span>
                  :
                  <span>Not Exempt</span>
                }
              </div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div>
                <textarea
                  value={driver.get('exemptionReason') || ''}
                  placeholder={t('Exemption Reasons only apply for Exempt Drivers')}
                  readOnly
                />
              </div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div className="translate-me">
                {driver.get('yardMoves') ?
                  <span>Allowed</span>
                  :
                  <span>Not Allowed</span>
                }
              </div>
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">
              <div className="translate-me">
                {driver.get('personalUseCMV') ?
                  <span>Allowed</span>
                  :
                  <span>Not Allowed</span>
                }
              </div>
            </MDBCol>
          </MDBRow>
        }
      </MDBContainer>

      <MDBContainer className={`${styles.section}`} fluid>
        <MDBRow>
          <MDBCol className={styles.columnName} size="3">
            <div className="translate-me">Admininistrative</div>
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
          </MDBCol>
          <MDBCol className={styles.columnName} size="3">
          </MDBCol>
        </MDBRow>

        {driverFetched && (
          <MDBRow>
            <MDBCol className={styles.columnValue} size="3">
              <MDBBtn
                size="md"
                color="default"
                onClick={() => {
                  props.showRestartSoftwareModal();
                }}
                className="translate-me"
              >
                Restart Application
              </MDBBtn>
              <br />
            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">

            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">

            </MDBCol>
            <MDBCol className={styles.columnValue} size="3">

            </MDBCol>
          </MDBRow>
        )}
      </MDBContainer>
    </MDBContainer>
  );
}

DriverDetailsInfoTable.propTypes = {
  driver: PropTypes.object,
  driverFetched: PropTypes.bool.isRequired,
  sim: PropTypes.object.isRequired,
  showResetDataModal: PropTypes.func.isRequired,
  showRestartSoftwareModal: PropTypes.func.isRequired,
};

export default DriverDetailsInfoTable;
