import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// Actions
import { warningIgnoreTrigger, getSubscriptionStatus, renewSubscription, checkRenewStatus, addSourceToCustomer, subscribeCustomerToPlans, getMonthlyCharge, getCurrencyFromCustomer } from 'actions/Subscription';

// Components
import StripeCheckoutModal from './StripeCheckoutModal';
import StripeElementsCheckoutModal from './StripeElementsCheckoutModal';
import CancelPaymentModal from '../view/CancelPaymentModal';
import RestartPaymentModal from '../view/RestartPaymentModal';
import RestartPaymentInvalidCreditCardModal from '../view/RestartPaymentInvalidCreditCardModal';
import PastDueModal from '../view/PastDueModal';
import TrialEndModal from '../view/TrialEndModal';
import CanceledModal from '../view/CanceledModal';
import ResellerClientModal from '../view/ResellerClientModal';
import StartPaymentModal from '../view/StartPaymentModal';
import UpgradeDriversModal from '../view/UpgradeDriversModal';
import UpgradeDocumentsModal from '../view/UpgradeDocumentsModal';
import DriversLimitWarningModal from '../view/DriversLimitWarningModal';
import DocumentsLimitWarningModal from '../view/DocumentsLimitWarningModal';

// API
import { addCCToCustomer, getOutstandingInvoiceAmount, payOutstandingInvoices } from 'api/Subscription';
import * as Getters from 'api/Getters';

// CSS
// import styles from './PaymentModal.module.css';

class PaymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentModalType: props.paymentModalType,
      showStripeCheckout: false,
      disableCCEntry: false,
      showCCDialog: false,
      disableButton: false,
      currency: 'USD',
      monthlyCharge: 0,
      stripePromise: null,
    };
    this.closeModal = this.closeModal.bind(this);
    this.getOptionsAvailable = this.getOptionsAvailable.bind(this);
    this.getResellerCompany = this.getResellerCompany.bind(this);
    this.renewSubscriptionValidCreditCard = this.renewSubscriptionValidCreditCard.bind(this);
    this.renewSubscriptionInvalidCreditCard = this.renewSubscriptionInvalidCreditCard.bind(this);
    this.handleStripeToken = this.handleStripeToken.bind(this);
    this.handleStripeTokenForRenew = this.handleStripeTokenForRenew.bind(this);
  }

  async componentDidMount() {
    await this.getResellerCompany();
    const newState = { ...this.state };

    if (this.props.paymentModalType) {
      newState.paymentModalType = this.props.paymentModalType;
      newState.showStripeCheckout = true;
    }

    newState.promoCode = this.props?.Subscription?.subscription?.get('company')?.get('promoCode');
    newState.stripePromise = loadStripe(`${this.props.Subscription.stripePublishableKey}`);
    this.setState(newState);
  }

  async componentWillReceiveProps(nextProps) {
    await this.getResellerCompany();
    if (
      !nextProps.paymentModalType &&
      !this.props.paymentModalType &&
      nextProps.Subscription.subscription !== this.props.Subscription.subscription
    ) {
      getSubscriptionStatus(
        nextProps.Subscription.subscription || this.props.Subscription.subscription,
        nextProps.Subscription.warningIgnore || this.props.Subscription.warningIgnore
      ).then(async (paymentStatusObj) => {
        const newState = { ...this.state };
        newState.paymentModalType = paymentStatusObj.type;
        // newState.paymentModalType = 'past_due';
        newState.disableCCEntry = nextProps.Subscription.subscription && nextProps.Subscription.subscription.get('disableCCEntry');
        // getMonthlyCharge(nextProps.Subscription.subscription).then((monthlyCharge) => {
        //   newState.monthlyCharge = monthlyCharge;
        getCurrencyFromCustomer(nextProps.Subscription.subscription).then((currency) => {
          if (currency) {
            newState.currency = currency.toUpperCase();
          }
          newState.promoCode = nextProps?.Subscription?.subscription?.get('company')?.get('promoCode');
          this.setState(newState);
        });
        // });
      });
    } else if (nextProps.paymentModalType || this.props.paymentModalType) {
      this.setState({ ...this.state, paymentModalType: nextProps.paymentModalType || this.props.paymentModalType, showStripeCheckout: true });
    } else {
      const newState = { ...this.state };
      newState.promoCode = nextProps?.Subscription?.subscription?.get('company')?.get('promoCode');
      this.setState(newState);
    }
  }

  async getResellerCompany() {
    return Getters.getResellerCompany().then((resellerCompanyObj) => {
      return this.setState({ ...this.state, resellerCompanyObj });
    });
  }

  getOptionsAvailable() {
    if (this.props.Subscription.subscription.get('monthlyCharge')) {
      return true;
    }
    return false;
  }

  closeModal() {
    warningIgnoreTrigger();
    this.setState({ ...this.state, paymentModalType: undefined });
  }

  renewSubscriptionValidCreditCard(subscriptionParseObj) {
    this.setState({ ...this.state, disableButton: true });
    renewSubscription(subscriptionParseObj).then(() => {
      this.setState({ ...this.state, paymentModalType: undefined });
    });
  }

  renewSubscriptionInvalidCreditCard(subscriptionParseObj) {
    this.setState({ ...this.state, disableButton: true });
    this.setState({ ...this.state, showStripeCheckout: true });
  }

  // renewSubscription(subscriptionParseObj).then(() => {
  //   this.setState({ ...this.state, paymentModalType: undefined });
  // });

  async handleStripeToken(token) {
    if (!token) {
      this.setState({ ...this.state, showStripeCheckout: false, disableButton: false });
    } else if (this.state.paymentModalType === 'past_due') {
      await addCCToCustomer(this.props.Subscription.subscription.get('company'), token);
      await payOutstandingInvoices(this.props.Subscription.subscription.get('company'));
      this.setState({ ...this.state, showStripeCheckout: false, paymentModalType: undefined });
    } else {
      await addCCToCustomer(this.props.Subscription.subscription.get('company'), token);
      this.setState({ ...this.state, showStripeCheckout: false, paymentModalType: undefined });
    }
  }

  handleStripeTokenForRenew(token) {
    if (!token) {
      this.setState({ ...this.state, showStripeCheckout: false, disableButton: false });
    } else {
      this.setState({ ...this.state, showStripeCheckout: false });
      addSourceToCustomer(token, this.props.Subscription.subscription).then(() => {
        subscribeCustomerToPlans(this.props.Subscription.subscription).then(() => {
          this.setState({ ...this.state, showStripeCheckout: false, paymentModalType: undefined });
        });
      });
    }
  }

  render() {
    let shouldShowModal = false;
    let paymentModalType = this.state.paymentModalType;
    let promoCode = this.state.promoCode;

    // DEBUG
    // let shouldShowModal = true;
    // let paymentModalType = 'past_due';
    // let promoCode = null;

    // not caring about documents/drivers right now
    // if (this.state.paymentModalType === 'nonexist' || this.state.paymentModalType === 'inactive' || this.state.paymentModalType === 'past_due') {
    //   shouldShowModal = true;
    // }
    if (
      paymentModalType === 'nonexist' ||
      paymentModalType === 'inactiveWithValidCreditCard' ||
      paymentModalType === 'inactiveWithInvalidCreditCard' ||
      paymentModalType === 'past_due' ||
      paymentModalType === 'trial_end' ||
      paymentModalType === 'updateCC' ||
      paymentModalType === 'closed'
    ) {
      shouldShowModal = true;
    }

    return (
      this.state.stripePromise &&
      (

        <Elements
          stripe={this.state.stripePromise}
          options={{
            appearance: {
              theme: 'stripe',
              labels: 'floating',
            },
          }}
        >
          <Dialog
            header="Switchboard"
            visible={shouldShowModal}
            modal
            closeOnEscape={false}
            closable={false}
            style={{ width: '50vw' }}
            draggable={false}
            resizable={false}
          >

            {/* <Modal
            show={shouldShowModal}
            keyboard={false}
            backdrop="static"
            bsSize="lg"
          >
            <Modal.Header>
              <Modal.Title>Switchboard</Modal.Title>
            </Modal.Header> */}

            {/* <Modal.Body> */}
            {(this.state.resellerCompanyObj || promoCode) &&
              (
                <div>
                  <ResellerClientModal
                    disableButton={this.state.disableButton}
                    handleEnterCC={() => {
                      this.setState({ ...this.state, disableButton: true }, () => {
                        getOutstandingInvoiceAmount(this.props.Subscription.subscription.get('company')).then((amount) => {
                          this.setState({ ...this.state, oustandingInvoiceAmount: amount, showStripeCheckout: true });
                        });
                      });
                    }}
                    resellerCompanyObj={this.state.resellerCompanyObj}
                  />
                </div>
              )
            }
            {!(this.state.resellerCompanyObj || promoCode) &&
              (
                <div>
                  {(
                    paymentModalType === 'nonexist'
                    || paymentModalType === 'trial_end'
                    || paymentModalType === 'nosubscription'
                  )
                    &&
                    (
                      <div>
                        <TrialEndModal
                          resellerCompanyObj={this.state.resellerCompanyObj}
                          handleEnterCC={() => {
                            this.setState({ ...this.state, disableButton: true, showStripeCheckout: true });
                          }}
                        />
                        <StripeCheckoutModal
                          monthlyFee={this.state.outstandingInvoiceAmount}
                          currency={this.state.currency}
                          label="Update Credit Card"
                          handleStripeToken={this.handleStripeToken}
                          showCheckout={this.state.showStripeCheckout}
                          Subscription={this.props.Subscription}
                        />
                      </div>
                    )
                  }

                  {(
                    paymentModalType === 'past_due'
                    || paymentModalType === 'inactiveWithValidCreditCard'
                    || paymentModalType === 'inactiveWithInvalidCreditCard')
                    &&
                    (
                      <div>
                        <PastDueModal
                          disableButton={this.state.disableButton}
                          handleEnterCC={() => {
                            this.setState({ ...this.state, disableButton: true, showCCDialog: true });
                          }}
                          resellerCompanyObj={this.state.resellerCompanyObj}
                        />
                        <StripeElementsCheckoutModal
                          visible={this.state.showCCDialog}
                          monthlyFee={this.state.monthlyCharge}
                          currency={this.state.currency}
                          handleStripeToken={async (token) => {
                            if (token) {
                              await this.handleStripeToken(token)
                            }
                            else {
                              this.props.handleClose()
                            }
                          }}
                          Subscription={this.props.Subscription}
                          cancel={() => this.setState({ ...this.state, showCCDialog: false })}
                          label="Update Credit Card"
                        />
                      </div>
                    )
                  }

                  {paymentModalType === 'updateCC' &&
                    (
                      <div>
                        <StripeElementsCheckoutModal
                          visible
                          monthlyFee={this.state.monthlyCharge}
                          currency={this.state.currency}
                          handleStripeToken={(token) => {
                            if (token) this.handleStripeToken(token)
                            else this.props.handleClose()
                          }}
                          Subscription={this.props.Subscription}
                          cancel={this.props.handleClose}
                          label="Update Credit Card"
                        />
                      </div>
                    )
                  }

                  {paymentModalType === 'closed' &&
                    (
                      <div>
                        <CanceledModal
                          resellerCompanyObj={this.state.resellerCompanyObj}
                          handleEnterCC={() => {
                            this.setState({ ...this.state, disableButton: true, showStripeCheckout: true });
                          }}
                        />
                        <StripeCheckoutModal
                          monthlyFee={this.state.outstandingInvoiceAmount}
                          currency={this.state.currency}
                          label="Update Credit Card"
                          handleStripeToken={this.handleStripeToken}
                          showCheckout={this.state.showStripeCheckout}
                          Subscription={this.props.Subscription}
                        />
                      </div>
                    )
                  }
                </div>
              )
            }
            {/* </Modal.Body> */}
            {/* </Modal> */}
          </Dialog>
        </Elements>
      )
    );
  }

}

PaymentModal.propTypes = {
  Subscription: PropTypes.object.isRequired,
  paymentModalType: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default PaymentModal;
