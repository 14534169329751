import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// API
import TouchingStateProvinces from 'api/Lists/TouchingStateProvinces';
import * as Helpers from 'api/Helpers';
import history from 'sbHistory';
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL'

// Components
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import MapPopup from 'components/IFTARoute/container/MapPopup';
import { MDBBtn, MDBModal, MDBModalBody } from 'mdbreact';
import VehicleRouteBreakdownDayViewTable from '../view/VehicleRouteBreakdownDayViewTable'
import VehicleRouteBreakdownDetailsViewTable from '../view/VehicleRouteBreakdownDetailsViewTable'
import VehicleRouteBreakdownDayViewTableBeta from '../view/VehicleRouteBreakdownDayViewTableBeta'
import VehicleRouteBreakdownDetailsViewTableBeta from '../view/VehicleRouteBreakdownDetailsViewTableBeta'
import * as IFTA from 'api/IFTA';

function areStatesTouching(currentStateProvince, previousStateProvince) {
  if (currentStateProvince && previousStateProvince && currentStateProvince !== previousStateProvince && TouchingStateProvinces[currentStateProvince.toUpperCase()]) {
    return (TouchingStateProvinces[currentStateProvince.toUpperCase()].indexOf(previousStateProvince.toUpperCase()) !== -1);
  } else {
    return true;
  }
}

class VehicleRouteBreakdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      shopMapPopup: false,
      mapData: {},
      dateStart: null,
      dateEnd: null,
    };
    this.toggleMap = this.toggleMap.bind(this);
    this.hideIftaRoute = this.hideIftaRoute.bind(this);
  }

  componentDidMount() {
    this.setState({ ...this.state, loading: true });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dateStart !== this.props.dateStart || nextProps.dateEnd !== this.props.dateEnd) {
      this.setState({ ...this.state });
    }
    if (nextProps.vehicleBreakdownFilter.value !== this.props.vehicleBreakdownFilter.value) {
      this.setState({ ...this.state });
    }
    if (nextProps.routeToNewIFTA !== this.props.routeToNewIFTA) {
      this.setState({ ...this.state });
    }
  }

  toggleMap(showBool, dateStart, dateEnd) {
    if (showBool) {
      this.setState({ ...this.state, showMapPopup: showBool, mapData: { vehicleUnitId: this.props.unitId, dateStart, dateEnd } });
    } else {
      this.setState({ ...this.state, showMapPopup: false });
    }
  }

  async hideIftaRoute(iftaRouteObjId) {
    console.log(iftaRouteObjId);
    for (let i = 0; i < this.props.iftaRouteArr.length; i++) {
      const iftaRouteObj = this.props.iftaRouteArr[i];
      if (iftaRouteObj.id === iftaRouteObjId) {
        await iftaRouteObj.set('isHidden', true).save();
        this.setState(this.state);
      }
    }
  }

  render() {
    return (
      <div>
        <div className='vehicleRouteBreakdown-printable'>
          {this.props.vehicleBreakdownFilter.value === 'dayView' && !this.props.routeToNewIFTA &&
            <VehicleRouteBreakdownDayViewTable
              iftaRouteArr={this.props.iftaRouteArr.filter((iftaRouteObj) => !iftaRouteObj.get('isHidden'))}
              vehicleBreakdownFilter={this.props.vehicleBreakdownFilter}
              distanceUnitFilter={this.props.distanceUnitFilter}
              volumeUnitFilter={this.props.volumeUnitFilter}
              toggleMap={this.toggleMap}
              dateStart={this.props.dateStart}
              dateEnd={this.props.dateEnd}
              displayStartDate={this.props.displayStartDate}
              displayEndDate={this.props.displayEndDate}
              setData={this.props.setVehicleRouteBreakdownData}
            />
          }
          {this.props.vehicleBreakdownFilter.value !== 'dayView' && !this.props.routeToNewIFTA &&
            <VehicleRouteBreakdownDetailsViewTable
              iftaRouteArr={this.props.iftaRouteArr.filter((iftaRouteObj) => !iftaRouteObj.get('isHidden'))}
              hideIftaRoute={this.hideIftaRoute}
              resetHiddenIftaRoutes={this.resetHiddenIftaRoutes}
              vehicleBreakdownFilter={this.props.vehicleBreakdownFilter}
              distanceUnitFilter={this.props.distanceUnitFilter}
              volumeUnitFilter={this.props.volumeUnitFilter}
              toggleMap={this.toggleMap}
              dateStart={this.props.dateStart}
              dateEnd={this.props.dateEnd}
              displayStartDate={this.props.displayStartDate}
              displayEndDate={this.props.displayEndDate}
              setData={this.props.setVehicleRouteBreakdownData}
              selectedIftaRoute={this.props.selectedIftaRoute}
              unitId={this.props.unitId}
              refreshState={() => this.props.refreshState()}
            />
          }

          {this.props.vehicleBreakdownFilter.value === 'dayView' && this.props.routeToNewIFTA &&
            <VehicleRouteBreakdownDayViewTableBeta
              iftaRouteArr={this.props.iftaRouteArr.filter((iftaRouteObj) => !iftaRouteObj.get('isHidden'))}
              hideIftaRoute={this.hideIftaRoute}
              vehicleBreakdownFilter={this.props.vehicleBreakdownFilter}
              distanceUnitFilter={this.props.distanceUnitFilter}
              volumeUnitFilter={this.props.volumeUnitFilter}
              showOdometerValuesFilter={this.props.showOdometerValuesFilter}
              toggleMap={this.toggleMap}
              dateStart={this.props.dateStart}
              dateEnd={this.props.dateEnd}
              displayStartDate={this.props.displayStartDate}
              displayEndDate={this.props.displayEndDate}
              setData={this.props.setVehicleRouteBreakdownData}
              selectedIftaRoute={this.props.selectedIftaRoute}
              unitId={this.props.unitId}
              refreshState={() => this.props.refreshState()}
              odometerReadingsDisplayingZero={this.props.odometerReadingsDisplayingZero}
            />
          }
          {this.props.vehicleBreakdownFilter.value !== 'dayView' && this.props.routeToNewIFTA &&
            <VehicleRouteBreakdownDetailsViewTableBeta
              iftaRouteArr={this.props.iftaRouteArr.filter((iftaRouteObj) => !iftaRouteObj.get('isHidden'))}
              hideIftaRoute={this.hideIftaRoute}
              resetHiddenIftaRoutes={this.resetHiddenIftaRoutes}
              vehicleBreakdownFilter={this.props.vehicleBreakdownFilter}
              distanceUnitFilter={this.props.distanceUnitFilter}
              volumeUnitFilter={this.props.volumeUnitFilter}
              toggleMap={this.toggleMap}
              dateStart={this.props.dateStart}
              dateEnd={this.props.dateEnd}
              displayStartDate={this.props.displayStartDate}
              displayEndDate={this.props.displayEndDate}
              setData={this.props.setVehicleRouteBreakdownData}
              selectedIftaRoute={this.props.selectedIftaRoute}
            />
          }
        </div>
        <MDBModal
          centered
          isOpen={this.state.showMapPopup}
          toggle={() => this.toggleMap(false)}
        >
          <MDBModalBody>
            <div style={{ height: '30em', width: '30em' }}>
              {this.state.showMapPopup &&
                <MapPopup
                  {...this.state.mapData}
                />
              }
            </div>
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
};

VehicleRouteBreakdown.propTypes = {
  vehicleBreakdownFilter: PropTypes.object.isRequired,
  distanceUnitFilter: PropTypes.object.isRequired,
  iftaRouteArr: PropTypes.array.isRequired,
  setVehicleRouteBreakdownData: PropTypes.func.isRequired,
  routeToNewIFTA: PropTypes.bool,
};

export default VehicleRouteBreakdown;
