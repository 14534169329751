import uniqid from 'uniqid';
import moment from 'moment-timezone';
import React, { useEffect, useState, useRef, useContext } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';

// CSAPI
import Filter from 'sb-csapi/dist/sbObjects/Filter';
import Timezones from 'sb-csapi/dist/lists/timezones';
import { CountryName } from 'sb-csapi/dist/enums/Country';
import { QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';
import { CommodityTypes } from 'sb-csapi/dist/enums/Dispatch/Commodity';
import { getAttribute, getCurrentUserCompanyObjectId, getObjectById } from 'sb-csapi/dist/AAPI';

// API
import { getDispatchItems } from 'api/Dispatch/DispatchItem';
import { getDispatchQuote } from 'api/Dispatch/DispatchQuote';
import { DispatchContactTypes } from 'enums/DispatchContact';

// ENUMS
/** @todo bring to csapi */
import { DispatchReferenceTypes } from 'enums/DispatchReference';
import { FreightCategory } from "sb-csapi/dist/enums/Dispatch/Freight";
import { Document as DocumentTypes } from 'sb-csapi/dist/enums/Dispatch/Document';

// shared components
import DocumentHeader from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/Header/Header';
import DocumentInputText from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputText/InputText';
import DocumentInputTextarea from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputTextarea/InputTextarea';
import LoadConfirmationTable from 'components/Dispatch/DispatchDocument/DispatchDocuments/Generators/LoadConfirmation/LoadConfirmationTable';
import LoadConfirmationTotal from 'components/Dispatch/DispatchDocument/DispatchDocuments/Generators/LoadConfirmation/LoadConfirmationTotal';

import { LengthUnit, MassUnit } from 'enums/DispatchUnit';

// Context
import DispatchJobLayoutContext from 'contexts/DispatchJobLayoutContext';
import DispatchDocumentsLayoutContext from 'contexts/DispatchDocumentsLayoutContext';

/**
 * @description The document generator for LoadConfirmation
 * @param {String} dispatchJobObjectId - the DispatchJob object id
 * @param {String} documentState - contains the state of the latest document in a stringified JSON format
 * @param {Array} dispatchTransfers - Dispatch transfers belonging to the dispatch job
 * @param {Object} referenceNumbersStringObj - An object containing reference numbers sorted by type and within a string separated by commas
 * @param {Function} downloadDispatchDocument - Callback function to generate a DispatchDocument with the current information
 * @param {Boolean} isDownloaded - The current isDownloaded boolean value
 * @param {Function} toggleDownloaded - Sets isDownloaded to the given boolean value
 * @returns {Component}
 */
function LoadConfirmation({ ...props }) {
  // ** UseStates ** //
  const [userCompany, setUserCompany] = useState(undefined);
  const [dispatchJob, setDispatchJob] = useState(undefined);              // DispatchJob record
  const [dispatchQuote, setDispatchQuote] = useState(undefined);
  const [loadConfirmationData, setLoadConfirmationData] = useState({});
  const [documentHasLoaded, setDocumentHasLoaded] = useState(false);

  // ** Context ** //
  const { dispatchDocumentTypesToSave, setIsDocumentOutdatedPrompt } = useContext(DispatchJobLayoutContext);
  const { saveDispatchDocumentState, checkDocumentForUnsavedChanges } = useContext(DispatchDocumentsLayoutContext);

  // State holding all fields
  const [documentStateObj, setDocumentStateObj] = useState({
    jobBatchId: '',
    referenceNumber: '',
    confirmationDate: '',
    contact: '',
    quoteRate: 0,
    taxRate: 0,
    taxAmount: 0,
    amountDue: 0,
    currency: 'CAD',
    acceptedDate: '',
    printName: '',
    notes: '',
    noticeOfClaim: 'Please contact us within 7 days should there be any discrepancies. We appreciate doing business with you.',
    referenceNumbers: '',
  });

  const pdfExportComponent = useRef(null);

  // ** Functions ** //
  // Updates the documentStateObj given an attribute and value
  function updateDocumentStateObj(attribute, value) {
    setDocumentStateObj({ ...documentStateObj, [attribute]: value });
  }

  function updateLoadConfirmationData(data) {
    setLoadConfirmationData(data);
  }

  // Download the new document with the parent callback function
  async function downloadDispatchDocument() {
    if (props.downloadDispatchDocument) await props.downloadDispatchDocument(documentStateObj);
    if (props.toggleDownloaded) props.toggleDownloaded(false);
  }

  async function saveCurrentDocumentState() {
    await saveDispatchDocumentState(documentStateObj);
  }

  // ** UseEffects ** //
  // Initial useEffect that runs when the component is mounted - obtains the DispatchJob and DispatchTransfers associated with the job
  // Also obtains the dispatch quote record associated with the job
  useEffect(() => {
    if (!props.dispatchJobObjectId) return;

    let didCancel = false;

    async function getDispatchJobandTransfers() {
      if (!didCancel) {
        const userCompanyObjectId = getCurrentUserCompanyObjectId();
        const userCompany = await getObjectById(undefined, 'Company', userCompanyObjectId);

        // Get the DispatchJob for referenceNumber (reference number), batchId (shipper number)
        const dispatchJob = await getObjectById(undefined, 'DispatchJob', props.dispatchJobObjectId);

        // Get the dispatchQuote for amount and tax
        const dispatchQuote = await getDispatchQuote(dispatchJob);

        setUserCompany(userCompany);
        setDispatchJob(dispatchJob);
        setDispatchQuote(dispatchQuote);
      }
    }

    getDispatchJobandTransfers();

    return () => { didCancel = true };
  }, [props.dispatchJobObjectId]);

  // useEffect that triggers whenever there is an update the to dispatchTransfers
  // Grabs all the dispatchItems and dispatchItemAccessorials for all the DispatchTransfers
  // Also triggers when there's an update to dispatchJob to reflect the changes in the documents without having to reload
  useEffect(() => {
    let didCancel = false;

    async function fetchLoadConfirmationTableInformation() {
      if (!didCancel) {
        const loadConfirmationDataSortedByDispatchTransferObj = {};

        const dispatchTransferDispatchItemsPromises = [];
        const dispatchTransferDispatchItemAccessorialsPromises = [];

        // Iterates through each dispatch transfer, and retrieves all the dispatch items from each transfer
        let i = 0;
        while (i < props.dispatchTransfers.length) {
          const dispatchTransfer = props.dispatchTransfers[i];
          const dispatchTransferObjectId = getAttribute(dispatchTransfer, 'objectId');

          loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId] = {};
          loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['dispatchTransferObjectId'] = dispatchTransferObjectId;
          loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['timezoneOffsetFromUTC'] = getAttribute(dispatchTransfer, 'timezoneOffsetFromUTC', true);

          const pickupDateTime = getAttribute(dispatchTransfer, 'pickupDateTime');
          const pickupDate = pickupDateTime ? moment(pickupDateTime).format('DD-MM-YYYY') : '';
          const pickupTime = pickupDateTime ? moment(pickupDateTime).format('HH:mm') : '';

          const dropoffDateTime = getAttribute(dispatchTransfer, 'dropoffDateTime');
          const dropoffDate = dropoffDateTime ? moment(dropoffDateTime).format('DD-MM-YYYY') : '';
          const dropoffTime = dropoffDateTime ? moment(dropoffDateTime).format('HH:mm') : '';

          loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['pickupDate'] = pickupDate;
          loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['pickupTime'] = pickupTime;
          loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['dropoffDate'] = dropoffDate;
          loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['dropoffTime'] = dropoffTime;

          // Fetch the shipper/consignee information for this transfer
          const consigneeDispatchOrganization = getAttribute(dispatchTransfer, 'consigneeDispatchOrganization');
          const shipperDispatchOrganization = getAttribute(dispatchTransfer, 'shipperDispatchOrganization');

          if (!loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]) loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId] = { dispatchTransferObjectId: dispatchTransferObjectId };

          // consignee
          if (consigneeDispatchOrganization) {
            const name = getAttribute(consigneeDispatchOrganization, 'organizationName', true) || '-';
            const address = getAttribute(consigneeDispatchOrganization, 'address', true) || '-';

            let addressString = '-';
            let city = '-';
            let stateProvince = '-';
            let zipPostal = '-';
            let country = '-';

            if (address) {
              addressString = getAttribute(address, 'address', true) || '-';
              city = getAttribute(address, 'city', true) || '-';
              stateProvince = getAttribute(address, 'stateProvince', true) || '-';
              zipPostal = getAttribute(address, 'zipPostal', true) || '-';
              country = CountryName[getAttribute(address, 'country', true)] || '-';
            }

            const appointmentRequired = getAttribute(consigneeDispatchOrganization, 'appointmentRequired', true);

            loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['consigneeDispatchOrganization'] = {};
            loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['consigneeDispatchOrganization']['consigneeDispatchOrganizationInformation'] = `${name}\n${addressString}\n${city}, ${stateProvince}\n${zipPostal}, ${country}`;
            loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['consigneeDispatchOrganization']['appointmentRequired'] = appointmentRequired ? 'Yes' : 'No';
          }

          // shipper
          if (shipperDispatchOrganization) {
            const name = getAttribute(shipperDispatchOrganization, 'organizationName', true) || '-';
            const address = getAttribute(shipperDispatchOrganization, 'address', true) || '-';

            let addressString = '-';
            let city = '-';
            let stateProvince = '-';
            let zipPostal = '-';
            let country = '-';

            if (address) {
              addressString = getAttribute(address, 'address', true) || '-';
              city = getAttribute(address, 'city', true) || '-';
              stateProvince = getAttribute(address, 'stateProvince', true) || '-';
              zipPostal = getAttribute(address, 'zipPostal', true) || '-';
              country = CountryName[getAttribute(address, 'country', true)] || '-';
            }

            const appointmentRequired = getAttribute(shipperDispatchOrganization, 'appointmentRequired', true);

            loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['shipperDispatchOrganization'] = {};
            loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['shipperDispatchOrganization']['shipperDispatchOrganizationInformation'] = `${name}\n${addressString}\n${city}, ${stateProvince}\n${zipPostal}, ${country}`;
            loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['shipperDispatchOrganization']['appointmentRequired'] = appointmentRequired ? 'Yes' : 'No';
          }

          const dispatchItems = getDispatchItems(
            undefined,                          // options - default
            undefined,                          // dispatchJobObjectId - default
            [new Filter(QueryRestrictionTypes.EQUAL_TO, 'dispatchTransfer', dispatchTransferObjectId)],                          // filters
            undefined,                          // sort - default
            [],                                 // includes
            undefined,                          // selects
            undefined,                          // page
            undefined,                          // limit
            true                                // query all
          );

          dispatchTransferDispatchItemsPromises.push(dispatchItems);

          i++;
        }

        const dispatchTransferDispatchItems = await Promise.all(dispatchTransferDispatchItemsPromises);

        let j = 0;
        while (j < dispatchTransferDispatchItems.length) {
          const { totalDispatchItemsCount, dispatchItems } = dispatchTransferDispatchItems[j];
          const dispatchItemsArr = [];

          if (totalDispatchItemsCount > 0) {
            const dispatchTransfer = dispatchItems && dispatchItems.length > 0 && getAttribute(dispatchItems[0], 'dispatchTransfer');
            const dispatchTransferObjectId = dispatchTransfer && getAttribute(dispatchTransfer, 'objectId');

            let k = 0;
            while (k < dispatchItems.length) {
              const dispatchItem = dispatchItems[k];

              const commodityType = getAttribute(dispatchItem, 'commodityType');
              const commodityTypeCustomName = (CommodityTypes[commodityType] === CommodityTypes[0] ? getAttribute(dispatchItem, 'commodityTypeCustomName') : '');
              const quantity = getAttribute(dispatchItem, 'quantity');
              const categoryInt = getAttribute(dispatchItem, 'category');
              const categoryStr = categoryInt ? Object.values(FreightCategory).find((category) => category.type === categoryInt)?.description : '-';
              const weight = getAttribute(dispatchItem, 'weight') || '0';
              const massUnit = getAttribute(dispatchItem, 'massUnit');
              const itemLength = getAttribute(dispatchItem, 'itemLength') || '0';
              const width = getAttribute(dispatchItem, 'width') || '0';
              const height = getAttribute(dispatchItem, 'height') || '0';
              const lengthUnit = getAttribute(dispatchItem, 'lengthUnit');
              const name = getAttribute(dispatchItem, 'name'); // but this is actually the freight notes

              const dimensionsText = `${itemLength}L x ${width}W x ${height}H`;
              const massUnitStr = massUnit ? MassUnit[massUnit].toLowerCase() : '';
              const weightText = `${weight} ${massUnitStr}`;

              const dispatchItemObj = {
                commodityType: commodityTypeCustomName || CommodityTypes[commodityType],
                quantity,
                categoryStr,
                dimensions: dimensionsText,
                weight: weightText,
                notes: name,
              };

              dispatchItemsArr.push(dispatchItemObj);

              const dispatchItemAccessorials = [];
              dispatchTransferDispatchItemAccessorialsPromises.push(dispatchItemAccessorials);

              k++;
            }

            loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['dispatchItems'] = dispatchItemsArr;
          }

          j++;
        }

        const dispatchTransferDispatchItemAccessorials = await Promise.all(dispatchTransferDispatchItemAccessorialsPromises);

        let l = 0;
        while (l < dispatchTransferDispatchItemAccessorials.length) {
          const dispatchItemAccessorials = dispatchTransferDispatchItemAccessorials[l];
          const dispatchItemAccessorialsArr = [];

          const dispatchItem = dispatchItemAccessorials && dispatchItemAccessorials.length > 0 && getAttribute(dispatchItemAccessorials[0], 'dispatchItem');
          const dispatchTransfer = dispatchItem && getAttribute(dispatchItem, 'dispatchTransfer');
          const dispatchTransferObjectId = dispatchTransfer && getAttribute(dispatchTransfer, 'objectId');

          if (dispatchItemAccessorials.length > 0) {
            let m = 0;
            while (m < dispatchItemAccessorials.length) {
              const dispatchItemAccessorial = dispatchItemAccessorials[m];

              const name = getAttribute(dispatchItemAccessorial, 'name', true);
              const quantity = getAttribute(dispatchItemAccessorial, 'quantity', true);

              const dispatchItemAccessorialObj = {
                name,
                quantity,
              };

              dispatchItemAccessorialsArr.push(dispatchItemAccessorialObj);

              m++;
            }

            loadConfirmationDataSortedByDispatchTransferObj[dispatchTransferObjectId]['dispatchItemAccessorials'] = dispatchItemAccessorialsArr;
          }

          l++;
        }

        setLoadConfirmationData(loadConfirmationDataSortedByDispatchTransferObj);
      }
    }

    if (props.dispatchTransfers) fetchLoadConfirmationTableInformation();
    return () => { didCancel = true; };
  }, [props.dispatchTransfers, props.dispatchJob]);

  // If a document state exists, update the document fields with the state's values. This also runs whenever dispatchJob updates
  // to override the default batchId, referenceNumber, and contact values with the existing document state's values.
  useEffect(() => {
    let latestDocumentStateObj = {};
    let didCancel = false;
    if (props.documentState) {
      latestDocumentStateObj = JSON.parse(props.documentState); // De-string the documentState object
      console.log('THERE IS A DOCUMENT STATE');
      console.log(latestDocumentStateObj);
    }

    let referenceNumbersString = '';
    if (props.referenceNumbersStringObj) {
      const customRefString = props.referenceNumbersStringObj[DispatchReferenceTypes.CUSTOM.key] || '';
      referenceNumbersString = `${customRefString}`;
    }
    if (!didCancel) {
      console.log('UPDATING IN DOCUMENTSTATE USEEFFECT');
      console.log(documentStateObj);
      setDocumentStateObj({
        ...documentStateObj,
        jobBatchId: latestDocumentStateObj.jobBatchId,
        referenceNumber: latestDocumentStateObj.referenceNumber,
        confirmationDate: latestDocumentStateObj.confirmationDate,
        contact: latestDocumentStateObj.contact,
        acceptedDate: latestDocumentStateObj.acceptedDate,
        printName: latestDocumentStateObj.printName,
        notes: latestDocumentStateObj.notes,
        noticeOfClaim: latestDocumentStateObj.noticeOfClaim ? latestDocumentStateObj.noticeOfClaim : documentStateObj.noticeOfClaim,
        quoteRate: latestDocumentStateObj.quoteRate ? latestDocumentStateObj.quoteRate : documentStateObj.quoteRate,
        taxRate: latestDocumentStateObj.taxRate ? latestDocumentStateObj.taxRate : documentStateObj.taxRate,
        taxAmount: latestDocumentStateObj.taxAmount ? latestDocumentStateObj.taxAmount : documentStateObj.taxAmount,
        amountDue: latestDocumentStateObj.amountDue ? latestDocumentStateObj.amountDue : documentStateObj.amountDue,
        currency: latestDocumentStateObj.currency ? latestDocumentStateObj.currency : documentStateObj.currency,
        referenceNumbers: latestDocumentStateObj.referenceNumbers ? latestDocumentStateObj.referenceNumbers : referenceNumbersString,
      });
    }
    return () => { didCancel = true; };
  }, [props.documentState]);

  // Used to update document information when General Information section has an update
  useEffect(() => {
    let didCancel = false;
    if (dispatchJob) {
      const batchId = getAttribute(dispatchJob, 'batchId');
      const referenceNumber = getAttribute(dispatchJob, 'referenceNumber');
      let customerDispatchOrganizationContactInformation = '';

      // Get the customer contact
      const customerDispatchOrganizationContact = getAttribute(dispatchJob, 'customerDispatchOrganizationContact');
      if (customerDispatchOrganizationContact) {
        const name = getAttribute(customerDispatchOrganizationContact, 'name', true) || '-';
        const title = getAttribute(customerDispatchOrganizationContact, 'title', true) || '-';
        const department = getAttribute(customerDispatchOrganizationContact, 'department', true) || '-';
        const primaryPhone = getAttribute(customerDispatchOrganizationContact, 'primaryPhone', true) || '-';
        const extension = getAttribute(customerDispatchOrganizationContact, 'extension', true) || '-';
        const email = getAttribute(customerDispatchOrganizationContact, 'email', true) || '-';

        customerDispatchOrganizationContactInformation = `${name}, ${title}\n${department}\n${primaryPhone}\n${extension}\n${email}`;
      }
      if (!didCancel) {
        console.log('UPDATING DISPATCHJOB INFO');
        console.log(batchId);
        console.log(customerDispatchOrganizationContactInformation);
        console.log(referenceNumber);
        setDocumentStateObj({
          ...documentStateObj,
          jobBatchId: batchId,
          contact: customerDispatchOrganizationContactInformation,
          referenceNumber,
        });
      }
    }
    return () => { didCancel = true; };
  }, [dispatchJob]);

  // Update Document Information based on the DispatchQuote information
  useEffect(() => {
    let didCancel = false;
    if (dispatchQuote) {
      const quoteRate = getAttribute(dispatchQuote, 'amount');
      const taxRate = getAttribute(dispatchQuote, 'tax');
      const taxAmount = quoteRate * ((taxRate / 100)).toFixed(2);
      const amountDue = taxAmount + quoteRate;

      if (!didCancel) {
        console.log('UPDATING QUOTE RATE INFO');
        console.log(quoteRate);
        console.log(taxRate);
        console.log(taxAmount);
        setDocumentStateObj({
          ...documentStateObj,
          quoteRate,
          taxRate,
          taxAmount,
          amountDue,
        });
      }
    }
    return () => { didCancel = true; };
  }, [dispatchQuote]);

  // Update reference numbers when props.referenceNumbersStringObj updates to reflect changes in the document
  useEffect(() => {
    let referenceNumbersString = '';
    if (props.referenceNumbersStringObj) {
      const jobRefString = props.referenceNumbersStringObj[DispatchReferenceTypes.JOB.key];
      const bolRefString = props.referenceNumbersStringObj[DispatchReferenceTypes.BOL.key];
      const customerRefString = props.referenceNumbersStringObj[DispatchReferenceTypes.CUSTOMER.key];
      const customRefString = props.referenceNumbersStringObj[DispatchReferenceTypes.CUSTOM.key] || '';

      const formattedJobRefString = `Job Ref #: ${jobRefString}\n`;
      const formattedBOLRefString = `BOL Ref #: ${bolRefString}\n`;
      const formattedCustomerRefString = `Customer Ref #: ${customerRefString}\n`;

      const showJobRefString = (jobRefString && formattedJobRefString) || '';
      const showBOLRefString = (bolRefString && formattedBOLRefString) || '';
      const showCustomerRefString = (customerRefString && formattedCustomerRefString) || '';

      referenceNumbersString = (`${showJobRefString}${showBOLRefString}${showCustomerRefString}${customRefString}`) || '';
    }
    setDocumentStateObj({
      ...documentStateObj,
      referenceNumbers: referenceNumbersString,
    })
  }, [props.referenceNumbersStringObj]);

  // If isDownloaded is true, generate a new invoice document
  useEffect(() => {
    if (!props.isDownloaded) return;
    downloadDispatchDocument();
  }, [props.isDownloaded]);

  // Check if dispatchDocumentTypesToSave contains this document type
  // If it does, save the current document state
  useEffect(() => {
    if (dispatchDocumentTypesToSave.includes(DocumentTypes.LOAD_CONFIRMATION.type)) {
      saveCurrentDocumentState();
    }
  }, [dispatchDocumentTypesToSave]);

  useEffect(() => {
    // Confirm the document has its latestdocumentstate loaded before we check for changes to trigger the prompt
    if (!documentHasLoaded && !checkDocumentForUnsavedChanges(JSON.stringify(documentStateObj)) && documentStateObj.jobBatchId) {
      setDocumentHasLoaded(!checkDocumentForUnsavedChanges(JSON.stringify(documentStateObj)));
    }

    if (documentHasLoaded) setIsDocumentOutdatedPrompt(true);

  }, [documentStateObj]);

  return (
    <div id="load_confirmation" className="document-generator-load-confirmation">
      <div className="pdf">
        <PDFExport
          ref={pdfExportComponent}
          margin={{ top: 10, left: 0, right: 0, bottom: 0 }}
          scale={0.5}
          paperSize="A4"
        >
          <div className="card">
            <div className="grid mx-7">
              {/* Document Header */}
              <div className="col-12">
                <DocumentHeader
                  title="Load Confirmation"
                  company={userCompany}
                />
              </div>

              {/* LoadConfirmation Header Information */}
              <div className="col-3">
                <DocumentInputText
                  label="Job ID"
                  inputClassName="w-full"
                  value={documentStateObj.jobBatchId !== undefined ? documentStateObj.jobBatchId : ''}
                  onChange={(value) => updateDocumentStateObj('jobBatchId', value)}
                  success={documentStateObj.jobBatchId}
                  warning={!documentStateObj.jobBatchId || documentStateObj.jobBatchId == ''}
                  isDownloaded={props.isDownloaded}
                />
              </div>
              <div className="col-3">
                <DocumentInputTextarea
                  label="Contact"
                  inputClassName="w-full"
                  value={documentStateObj.contact !== undefined ? documentStateObj.contact : ''}
                  onChange={(value) => updateDocumentStateObj('contact', value)}
                  success={documentStateObj.contact}
                  warning={!documentStateObj.contact || documentStateObj.contact == ''}
                  isDownloaded={props.isDownloaded}
                />
              </div>
              <div className="col-3">
                <DocumentInputText
                  label="Confirmation Date"
                  inputClassName="w-full"
                  value={documentStateObj.confirmationDate !== undefined ? documentStateObj.confirmationDate : ''}
                  onChange={(value) => updateDocumentStateObj('confirmationDate', value)}
                  success={documentStateObj.confirmationDate}
                  warning={!documentStateObj.confirmationDate || documentStateObj.confirmationDate == ''}
                  isDownloaded={props.isDownloaded}
                />
              </div>
              <div className="col-3">
                <DocumentInputText
                  label="Job Ref #"
                  inputClassName="w-full"
                  value={documentStateObj.referenceNumber !== undefined ? documentStateObj.referenceNumber : ''}
                  onChange={(value) => updateDocumentStateObj('referenceNumber', value)}
                  success={documentStateObj.referenceNumber}
                  warning={!documentStateObj.referenceNumber || documentStateObj.referenceNumber == ''}
                  isDownloaded={props.isDownloaded}
                />
              </div>

              {/* Load Confirmation Table */}
              <LoadConfirmationTable
                data={loadConfirmationData}
                updateLoadConfirmationData={updateLoadConfirmationData}
                isDownloaded={props.isDownloaded}
              />

              {/* Load Confirmation Total */}
              <div className="col-6">
                <LoadConfirmationTotal
                  updateDocumentStateObj={updateDocumentStateObj}
                  quoteRate={documentStateObj.quoteRate}
                  taxRate={documentStateObj.taxRate}
                  taxAmount={documentStateObj.taxAmount}
                  amountDue={documentStateObj.amountDue}
                  currency={documentStateObj.currency}
                  isDownloaded={props.isDownloaded}
                />
              </div>

              {/* Load Confirmation extra fields */}
              <div className="col-6">
                <DocumentInputText
                  horizontal
                  label="Accepted Date"
                  inputClassName="w-full"
                  value={documentStateObj.acceptedDate !== undefined ? documentStateObj.acceptedDate : ''}
                  onChange={(value) => updateDocumentStateObj('acceptedDate', value)}
                  success={documentStateObj.acceptedDate}
                  warning={!documentStateObj.acceptedDate || documentStateObj.acceptedDate == ''}
                  isDownloaded={props.isDownloaded}
                />
                <DocumentInputText
                  horizontal
                  label="Print Name"
                  inputClassName="w-full"
                  value={documentStateObj.printName !== undefined ? documentStateObj.printName : ''}
                  onChange={(value) => updateDocumentStateObj('printName', value)}
                  success={documentStateObj.printName}
                  warning={!documentStateObj.printName || documentStateObj.printName == ''}
                  isDownloaded={props.isDownloaded}
                />
              </div>
              <div className="col-6 my-3">
                <DocumentInputTextarea
                  label="Notes"
                  inputClassName="w-full"
                  value={documentStateObj.notes !== undefined ? documentStateObj.notes : ''}
                  onChange={(value) => updateDocumentStateObj('notes', value)}
                  success={documentStateObj.notes}
                  warning={!documentStateObj.notes || documentStateObj.notes == ''}
                  isDownloaded={props.isDownloaded}
                />
              </div>
              <div className="col-6 my-3">
                <DocumentInputTextarea
                  label="Reference Numbers"
                  inputClassName="w-full"
                  value={documentStateObj.referenceNumbers !== undefined ? documentStateObj.referenceNumbers : ''}
                  onChange={(value) => setDocumentStateObj({ ...documentStateObj, referenceNumbers: value })}
                />
              </div>
              {/* The footer information including signatures */}
              <div className="col-9">
                <DocumentInputTextarea
                  inputClassName={"w-full text-sm"}
                  value={documentStateObj.noticeOfClaim !== undefined ? documentStateObj.noticeOfClaim : ''}
                  onChange={(value) => updateDocumentStateObj('noticeOfClaim', value)}
                  success={documentStateObj.noticeOfClaim}
                  warning={!documentStateObj.noticeOfClaim || documentStateObj.noticeOfClaim == ''}
                  isDownloaded={props.isDownloaded}
                />
              </div>
              <div className="col-3">
                {/* Powered by SB component */}
              </div>
            </div>
          </div>
        </PDFExport>
      </div>
    </div>
  );
}

export default LoadConfirmation;
