import React from 'react';

// PrimeReact Component
import { Timeline as PRTimeline } from 'primereact/timeline';

// Styling
import './style.scss';

/**
 * @description PrimeReact Timeline component
 * @param {String} className - Custom container className
 */
function Timeline({ ...props }) {
  let className = 'sbc-timeline';
  if (props.className) className += ` ${props.className}`;

  return (<PRTimeline {...props} className={className} />);
}

export default Timeline;
