import store from 'store';
import Parse from 'parse';
import * as ActionConstants from './ActionConstants';
import * as Setters from 'api/Setters';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import * as ChatAPI from 'api/Chat';

const notificationAudio = require('assets/sounds/alert-sound.mp3');

export function chatMessageReceiveHandler(notificationObject) {
  new Audio(notificationAudio).play();
  // store.dispatch(receiveNotification(notificationObject));
  // Getters.getObjectsForNotification(notificationObject).then((parseObject) => {
  //   if (notificationObject.class === 'Document' && notificationObject.type === 'Create') {
  //     store.dispatch(receiveDocument(parseObject));
  //   } else if (notificationObject.class === 'JobLink' && notificationObject.type === 'Create') {
  //     store.dispatch(receiveJob(parseObject));
  //   } else if (notificationObject.class === 'Receipt' && notificationObject.type === 'Create') {
  //     store.dispatch(receiveReceipt(parseObject));
  //   } else if (notificationObject.class === 'Document' && notificationObject.type === 'Retake') {
  //     store.dispatch(retakeDocument(parseObject, notificationObject.jobId, notificationObject.fileName));
  //   } else if (notificationObject.class === 'JobAction' && notificationObject.type === 'Complete') {
  //     store.dispatch(completeJobAction(parseObject));
  //   } else if (notificationObject.class === 'GeofenceEvent') {
  //     store.dispatch(receiveGeofenceEvent(parseObject));
  //   }
  // });
}

// ----- CHAT MESSAGE HANDLING
function fetchingChatRoom() {
  return {
    type: ActionConstants.FETCH_CHATROOM_INPROGRESS,
  };
}

function fetchChatRoomSuccess(room) {
  return {
    room,
    type: ActionConstants.FETCH_CHATROOM_SUCCESS,
  };
}

function fetchChatRoomError(error) {
  return {
    type: ActionConstants.FETCH_CHATROOM_ERROR,
    error,
  };
}

export function fetchChatRoomForState(roomId, friendsArr) {
  const promise = new Promise(resolve => {
    store.dispatch(fetchingChatRoom());

    ChatAPI.retrieveChatRoom(roomId, friendsArr).then(
      chatRoom => {
        resolve(chatRoom);
        store.dispatch(fetchChatRoomSuccess(chatRoom));
      },
      error => {
        reject(error);
        store.dispatch(fetchChatRoomError(error));
      }
    );
  });
  return promise;
}

function addChatMessage(roomId, message) { // receive chat message
  return {
    roomId,
    type: ActionConstants.CHAT_MESSAGES_ADD,
    message,
  };
}

function receiveChatMessage(chatRoom, chatMessage) {
  return {
    room: chatRoom,
    type: ActionConstants.CHAT_MESSAGES_RECEIVE,
    message: chatMessage,
  };
}

export function handleChatMessageReceive(chatMessageJSON) { // receive chat message
  Getters.getObjectById('ChatMessage', chatMessageJSON.objectId).then((chatMessage) => {
    if (chatMessage) {
      const chatRoomQuery = new Parse.Query('ChatRoom');
      chatRoomQuery.equalTo('roomId', chatMessage.get('roomId'));
      chatRoomQuery.first().then(
        function (chatRoom) {
          store.dispatch(receiveChatMessage(chatRoom, chatMessage));
        }
      );
      // console.log(store.getState().Chat);
    }
  });
}

function markChatMessagesRead(roomId) {
  return {
    roomId,
    type: ActionConstants.CHAT_MESSAGES_MARK_READ,
  }
}

export function handleMarkMessagesRead(roomId) {
  store.dispatch(markChatMessagesRead(roomId));
}

function fetchingChatMessages(roomId) {
  return {
    roomId,
    type: ActionConstants.FETCH_CHAT_MESSAGES_INPROGRESS,
  };
}

function fetchChatMessagesSuccess(roomId, messages) { // fetch chat success
  return {
    roomId,
    type: ActionConstants.FETCH_CHAT_MESSAGES_SUCCESS,
    messages,
  };
}

function fetchChatMessagesError(roomId, error) {
  return {
    roomId,
    type: ActionConstants.FETCH_CHAT_MESSAGES_ERROR,
    error,
  };
}

function deleteChatMessages(roomId) {
  return {
    roomId,
    type: ActionConstants.DELETE_CHAT_MESSAGES,
  };
}

export function deleteChatMessagesFromState(roomId) {
  const promise = new Promise(resolve => {
    if (roomId) {
      store.dispatch(deleteChatMessages(roomId));
    }
    resolve(store.getState().Chat);
  });
  return promise;
}

export function fetchChatForState(roomId) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingChatMessages(roomId));
    const chatMessageQuery = new Parse.Query('ChatMessage');
    chatMessageQuery.equalTo('roomId', roomId);
    chatMessageQuery.limit(120);
    chatMessageQuery.descending('timeSinceEpochId');
    chatMessageQuery.include(['sender', 'sender.user', 'seenBy', 'seenBy.user']);
    chatMessageQuery.find().then(
      chatMessages => {
        resolve(chatMessages.reverse());
        var lastMessage = chatMessages[chatMessages.length - 1];
        if (lastMessage) {
          lastMessage.addUnique('seenBy', Parse.User.current());
          lastMessage.save();
        }
        store.dispatch(fetchChatMessagesSuccess(roomId, chatMessages));
        // console.log(store.getState().Chat);
      }
    );
  });
  return promise;
}

export function sendChatMessage(roomId, message, friendsArr, image) {
  const promise = new Promise(resolve => {
    ChatAPI.saveChatMessage({ roomId, message, image }, friendsArr).then(
      chatMessage => {
        store.dispatch(addChatMessage(roomId, chatMessage));
        // console.log(store.getState().Chat);
        resolve(chatMessage);
      }
    );
  })
  return promise;
}

export function sendFakeChatMessage(friend, message) {
  const promise = new Promise(resolve => {
    ChatAPI.saveChatMessage({ message }).then(
      chatMessage => {
        const fakemsg = chatMessage.clone();
        fakemsg.id = '12345';
        fakemsg.set('sender', friend.get('user'));
        fakemsg.set('message', 'this is a fake msg');
        fakemsg.set('timeSinceEpochId', (new Date()).getTime() + '&' + friend.get('user').id);
        store.dispatch(addChatMessage(friend, fakemsg));
        resolve(fakemsg);
      }
    );
  })
  return promise;
}

// ----- CHAT FRIENDS HANDLING
function fetchingChatFriends() {
  return {
    type: ActionConstants.FETCH_CHAT_FRIENDS_INPROGRESS,
    fetched: false,
  };
}

function fetchChatFriendsSuccess(users) {
  return {
    type: ActionConstants.FETCH_CHAT_FRIENDS_SUCCESS,
    users,
  };
}

function fetchChatFriendsError(error) {
  return {
    type: ActionConstants.FETCH_CHAT_FRIENDS_ERROR,
    error,
  };
}

export function addChatFriend(friend) {
  return {
    type: ActionConstants.ADD_CHAT_FRIEND_SUCCESS,
    friend,
  }
}

export function fetchChatFriendsForState() {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingChatFriends());
    Getters.getDrivers({ enabled: true }).then(
      drivers => {
        resolve(drivers);
        store.dispatch(fetchChatFriendsSuccess(drivers));
      },
      error => {
        reject(error);
        store.dispatch(fetchChatFriendsError(error));
      }
    );
  });
  return promise;
}

function deleteChatFriends() {
  return {
    type: ActionConstants.DELETE_CHAT_FRIENDS,
  };
}

export function deleteChatFriendsFromState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteChatFriends());
    resolve(store.getState().Chat);
  });
  return promise;
}
