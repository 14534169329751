/* eslint-disable react/jsx-no-bind */
import uniqid from 'uniqid';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

// API
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getDrivers } from 'api/Driver/Driver';
import { convertMillisecondsToHours, formatTimeNumberToHoursMinutes } from 'api/Helpers';
import { isDriverDriving } from 'api/ELD';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { AttributeTypes } from 'enums/Driver';
import Sort from 'sbObjects/Sort';
import Filter from 'sbObjects/Filter';

// Components
import DutyStatusBadge from 'sbCore/DutyStatusBadge/DutyStatusBadge';
import { FontIcon } from 'material-ui';
import Card from 'sbCore/Card/Card';
import Column from 'sbCore/Column/Column';
import DataTable from 'sbCore/DataTable/DataTable';
import Badge from 'sbCore/Badge/Badge';
import Tooltip from 'sbCore/Tooltip/Tooltip';

import styles from './DriverHoursLayout.module.scss';

/**
 * Holds the layout for displaying API credentials
 *
 * @returns {JSX}
 */
function DriversDrivingCard() {
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedIntegration, setSelectedIntegration] = useState(null);
  // const [showRequestIntegrationModal, setShowRequestIntegrationModal] = useState(false);
  const [driverInformationArr, setDriverInformationArr] = useState([]);

  async function getDriversForState() {
    const driversArr = (await getDrivers(
      undefined,
      undefined,
      new Sort(AttributeTypes.LATESTELDEVENT_EVENTDATETIME, QuerySortOrderTypes.DESCENDING),
      new Filter('latestELDEvent_eventDateTime', moment().subtract(1, 'day').toDate(), QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO)
    )).drivers;
    const _driverInformationArr = driversArr?.map((driverObj) => {
      const latestELDEvent = driverObj?.latestELDEvent;
      const latestEldDailyCertification = latestELDEvent?.get('eldDailyCertification');
      const vehicleLocation = latestELDEvent && getAttribute(latestELDEvent, 'vehicleLocation', true);
      const locationCountry = vehicleLocation?.get('countryCode');
      const lastKnownLocation = `${vehicleLocation?.get('aprxShortName')}, ${vehicleLocation?.get('stateProvince')?.toUpperCase()}`;
      const locationDateTime = vehicleLocation?.get('dateTime') || latestELDEvent?.get('eventDateTime');
      const remainingDrivingTime = locationCountry === 'us' ? latestEldDailyCertification?.get('usDriveTimeLeft') : latestEldDailyCertification?.get('caDriveTimeLeft');
      const dataConnectedBool = moment(getAttribute(latestELDEvent, 'eventDateTime', true)).isAfter(moment().subtract(1.5, 'hour'));
      const gpsConnectedBool = !getAttribute(vehicleLocation, 'isStale', true);
      const ecmConnectedBool = moment(getAttribute(latestELDEvent, 'eldLastSyncDateTime', true)).isAfter(moment().subtract(1.5, 'hour')) || (driverObj?.dbObject?.get('latestELDEvent_eldLastSyncDateTime') && moment(driverObj?.dbObject?.get('latestELDEvent_eldLastSyncDateTime')).isAfter(moment().subtract(1.5, 'hour')));
      const isDriving = driverObj?.eldStatusInt && (driverObj?.eldStatusInt === 3 || driverObj?.eldStatusInt === 6);

      const informationObj = {
        name: driverObj?.user_fullName,
        phoneNumber: driverObj?.phoneNumber,
        eldStatusInt: driverObj?.eldStatusInt,
        lastKnownLocation,
        locationDateTime, // not using location because some don't have vehicleLocations
        remainingDrivingTime,
        driverId: driverObj?.objectId,
        ecmConnectedBool,
        dataConnectedBool,
        gpsConnectedBool,
        isDriving,
      };

      return (informationObj);
    });

    setDriverInformationArr(_driverInformationArr);
    setIsLoading(false);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      getDriversForState();
    }, 5000);
    return () => clearInterval(interval);
  }, []);


  function driverBodyTemplate(rowData) {
    return (
      <div>
        <a
          href={`/driver?driver=${rowData.driverId}&view=hosEvents`}
          target="_blank"
        >
          <b>{rowData.name}</b>
        </a>
        <br />
        <div className={styles.subtitle}>
          <FontIcon className={`${styles.subtitle} material-icons`}>phone</FontIcon>
          {rowData.phoneNumber}
        </div>
      </div>
    );
  }

  function driverInfoTemplate(rowData) {
    return (
      <div className="inline">
        <span className="inlineBlock">
          <DutyStatusBadge className="z-depth-0" eldStatusInt={rowData.eldStatusInt} />
        </span>

        <span className="inlineBlock">
          {/* Data Status */}
          <Badge
            value="Data"
            className={`data-badge ${styles.badge}`}
            data-pr-tooltip={(() => {
              if (!rowData.isDriving) return 'Not Driving';
              else return (rowData.dataConnectedBool ? 'Connected' : 'Not Connected');
            })()}
            data-pr-position="top"
            severity={(() => {
              if (!rowData.isDriving) return 'secondary';
              else return (rowData.dataConnectedBool ? 'success' : 'warning');
            })()}
          />
          <Tooltip target=".data-badge" />
        </span>

        <span className="inlineBlock">
          {/* ECM Status */}
          <Badge
            value="ECM"
            className={`ecm-badge ${styles.badge}`}
            data-pr-tooltip={(() => {
              if (!rowData.isDriving) return 'Not Driving';
              else if (!rowData.dataConnectedBool) return 'Check Data Upload';
              else return (rowData.ecmConnectedBool ? 'Connected' : 'Not Connected');
            })()}
            data-pr-position="top"
            severity={(() => {
              if (!rowData.isDriving) return 'secondary';
              else if (!rowData.dataConnectedBool) return 'secondary';
              else return (rowData.ecmConnectedBool ? 'success' : 'warning');
            })()}
          />
          <Tooltip target=".ecm-badge" />
        </span>

        <span className="inlineBlock">
          {/* GPS Status */}
          <Badge
            value="GPS"
            className={`gps-badge ${styles.badge}`}
            data-pr-tooltip={(() => {
              if (!rowData.isDriving) return 'Not Driving';
              else return (rowData.gpsConnectedBool ? 'Connected' : 'Not Connected');
            })()}
            data-pr-position="top"
            severity={(() => {
              if (!rowData.isDriving) return 'secondary';
              else return (rowData.gpsConnectedBool ? 'success' : 'warning');
            })()}
          />
          <Tooltip target=".gps-badge" />
        </span>
      </div>
    );
  }


  function remainingTimeTmeplate(rowData) {
    return (
      <div>
        <div>
          <a
            href={`/driver?driver=${rowData.driverId}&view=driverHoursDetails`}
            target="_blank"
          >
            <b style={{ float: 'left', marginRight: '1em' }}>
              {formatTimeNumberToHoursMinutes(convertMillisecondsToHours(rowData.remainingDrivingTime || 0)) || '0:00'}
            </b>
          </a>
        </div>
        <div>
          <span className={styles.subtitle}>
            @ {moment(rowData.locationDateTime).format('h:mm a')}
          </span>
        </div>
      </div>
      // <Button
      //   sbVariant="slim"
      //   className="p-button-secondary"
      //   icon={<span className="material-icons mr-1">call</span>}
      //   label="Request an Integration"
      //   onClick={() => { setSelectedIntegration(rowData.label); setShowRequestIntegrationModal(true) }}
      // />
    );
  }


  const header = (
    <div className="flex flex-row justify-content-between align-items-end px-3 pt-4">
      <h6 className="font-bold mb-0">Active Drivers in the last 24 hours</h6>
    </div>
  );

  return (
    <Card
      header={header}
    >
      <DataTable
        value={driverInformationArr.filter((driver) => driver.locationDateTime && moment(driver.locationDateTime).isAfter(moment().subtract(1, 'day')))}
        emptyMessage="No active drivers found"
        loading={isLoading}
        rowHover
        paginator
        rows={5}
        size="small"
      >
        <Column
          field={driverBodyTemplate}
          header=""
        />
        <Column
          field={driverInfoTemplate}
          header=""
        />
        <Column
          field={remainingTimeTmeplate}
          header="Remaining Driving Time"
        />
        {/* <Column
          field={re}
          header=""
        /> */}
      </DataTable>
    </Card>
  );
}

export default DriversDrivingCard;
