import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { MDBRow, MDBCol } from 'mdbreact';

// Components
import SBTable from 'components/Shared/SBTable/SBTable';

// API
import * as Helpers from 'api/Helpers';

class TaxReportTotalSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { props } = this;
    const totalStateProvinceMileagesObj = props.totalStateProvinceMileages;

    let totalVehicleKm = 0;
    let totalDistanceKm = 0;
    let totalSpreadKm = 0;
    let totalFuelVolumePumped = 0;

    const totalStateProvinceMilesagesArr = Object.keys(totalStateProvinceMileagesObj).map((key) => {
      // Patching eccentricities in state codes (IFTA States codes are assigned from the 58 US and CAD states/provinces)
      let IFTAStateCode = key;
      if (key === 'NF') IFTAStateCode = 'NL';

      const currency = props.IFTACurrency;
      const stateTaxRates = props.taxRates[IFTAStateCode];
      const fuelTaxObj = (stateTaxRates && stateTaxRates.ratesDictionary[props.fuelType]);
      const fuelTaxRate = (fuelTaxObj && fuelTaxObj[currency]);

      let { vehicleKm, distanceKm, spreadKm, fuelVolumePumped } = totalStateProvinceMileagesObj[key];
      // Round values to nearest integer as is expected in the tax filing.
      vehicleKm = Math.round(vehicleKm);
      distanceKm = Math.round(distanceKm);
      spreadKm = Math.round(spreadKm);
      fuelVolumePumped = Math.round(fuelVolumePumped);

      totalVehicleKm += vehicleKm;
      totalDistanceKm += distanceKm;
      totalSpreadKm += spreadKm;
      totalFuelVolumePumped += fuelVolumePumped;

      return {
        stateProvince: key,
        vehicleKm,
        distanceKm,
        spreadKm,
        fuelVolumePumped,
        fuelTaxRate,
      };
    });

    // Total statistics
    const avgKmPerLitre = totalFuelVolumePumped ? totalSpreadKm / totalFuelVolumePumped : 0;
    let totalTaxableFuel = 0;
    let totalTaxDueCents = 0;

    // State specific statistics
    totalStateProvinceMilesagesArr.forEach(stateProvince => {
      // Note: We handled integer-wise rounding just above for totalStateProv...Arr.
      const { fuelVolumePumped, spreadKm } = stateProvince;
      let { fuelTaxRate } = stateProvince;
      // Data is not very clean... the import script could handle this.
      if (typeof fuelTaxRate === 'string') fuelTaxRate = parseFloat(fuelTaxRate);
      if (typeof fuelTaxRate === 'number') {
        // Fuel is reported in integer Liters
        const taxableFuel = avgKmPerLitre ? Math.round(spreadKm / avgKmPerLitre) : 0;
        const netTaxableFuel = taxableFuel - fuelVolumePumped;

        const taxDueCents = Math.round(netTaxableFuel * fuelTaxRate * 100);
        stateProvince.taxableFuel = taxableFuel;
        stateProvince.netTaxableFuel = netTaxableFuel;
        stateProvince.taxDueCents = taxDueCents;
        totalTaxableFuel += taxableFuel;
        totalTaxDueCents += taxDueCents;
      }
    });

    const tableHeaderStyles = {
      stateProvince: { width: '10%', overflowWrap: 'break-word' },
      taxRate: { width: '10%', overflowWrap: 'break-word' },
      gps: { width: '10%', overflowWrap: 'break-word' },
      fuel: { width: '15%', overflowWrap: 'break-word' },
      taxableLitres: { width: '10%', overflowWrap: 'break-word' },
      netTaxableLitres: { width: '10%', overflowWrap: 'break-word' },
      calculated: { width: '20%', overflowWrap: 'break-word' },
      taxDueCents: { width: '15%', overflowWrap: 'break-word' },
    };
    const volumeUnit = props.IFTACurrency === 'CAD' ? 'Litres' : 'Gallons';
    const tableHeaderRows = [{
      key: 'sb-jobs-list',
      columns: [
        {
          element: <div>State / Province</div>,
          props: {
            style: tableHeaderStyles.stateProvince,
          },
        },
        // {
        //   element: <div>GPS Mileage ({props.IFTACurrency === 'CAD' ? 'km' : 'mi'})</div>,
        //   props: {
        //     style: tableHeaderStyles.gps,
        //   },
        // },
        {
          element: <div className="centerText">Fuel Purchases ({volumeUnit})</div>,
          props: { style: tableHeaderStyles.fuel },
        },
        {
          element: <div>Calculated Mileage ({props.IFTACurrency === 'CAD' ? 'km' : 'mi'})</div>,
          props: {
            style: tableHeaderStyles.calculated,
          },
        },
        {
          element: <div className="centerText">Taxable {volumeUnit}</div>,
          props: { style: tableHeaderStyles.taxableLitres },
        },
        {
          element: <div>Net Taxable {volumeUnit}</div>,
          props: { style: tableHeaderStyles.netTaxableLitres },
        },
        {
          element: <div>Tax Rate</div>,
          props: {
            style: tableHeaderStyles.taxRate,
          },
        },
        {
          element: <div>Tax Due ({props.IFTACurrency})</div>,
          props: { style: tableHeaderStyles.taxDueCents },
        },
      ],
    }];

    let tableBodyRows = totalStateProvinceMilesagesArr
      .filter((stateProvinceData) => { if (this.props.selectedFilter.value === 'hideZeroMileages') { return stateProvinceData.vehicleKm; } return true; })
      .map(stateProvinceData => {
        const {
          taxableFuel,
          netTaxableFuel,
          taxDueCents,
          distanceKm,
          spreadKm,
          fuelVolumePumped,
          fuelTaxRate,
        } = stateProvinceData;
        const stateTaxDue = Helpers.formatCentsToDollars(taxDueCents);

        const vehicleRowObj = {
          key: stateProvinceData.stateProvince,
          columns: [],
        };
        vehicleRowObj.columns = [
          {
            element: <div>{stateProvinceData.stateProvince}</div>,
            props: { className: 'table-body-column-style' },
          },
          // {
          //   element: (
          //     <div>
          //       { distanceKm ? (props.IFTACurrency === 'CAD' ?  distanceKm.toFixed(0) : Helpers.convertDistance(distanceKm, 'km', 'mi', true).toFixed(0)) : 0 }
          //     </div>),
          //   props: { className: 'table-body-column-style' },
          // },
          {
            element: (
              <div>
                {parseFloat(fuelVolumePumped || 0)}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          {
            element: (
              <div>
                {spreadKm ? (props.IFTACurrency === 'CAD' ? stateProvinceData.spreadKm : Helpers.convertDistance(stateProvinceData.spreadKm, 'km', 'mi', true)) : 0}
              </div>),
            props: { className: 'table-body-column-style' },
          },
          // Taxable Litres
          {
            element: (
              <div>
                {taxableFuel !== undefined ? taxableFuel : ''}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          // Net taxable litres
          {
            element: (
              <div>
                {netTaxableFuel !== undefined ? netTaxableFuel : ''}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          {
            element: <div>{fuelTaxRate}</div>,
            props: { className: 'table-body-column-style' },
          },
          {
            element: (
              <div>
                {stateTaxDue}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
        ];

        return vehicleRowObj;
      });

    tableBodyRows.push({
      key: 'total',
      columns: [
        {
          element: (
            <div>
              <b>Total</b>
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        // {
        //   element: (
        //     <div>
        //       <b>{props.IFTACurrency === 'CAD' ? totalDistanceKm.toFixed(0) : Helpers.convertDistance(totalDistanceKm, 'km', 'mi', true).toFixed(0)}</b>
        //     </div>
        //   ),
        //   props: { className: 'table-body-column-style' },
        // },
        {
          element: (
            <div>
              <b>{totalFuelVolumePumped}</b>
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        {
          element: (
            <div>
              <b>
                {props.IFTACurrency === 'CAD' ? totalSpreadKm.toFixed(0) : Helpers.convertDistance(totalSpreadKm, 'km', 'mi', true).toFixed(0)}
              </b>
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        { // TAXABLE LITRES
          element: (
            <div>
              <b>
                {totalTaxableFuel}
              </b>
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        {
          element: (
            <div>
              {/* Useless value! It mathematically is equal to zero but with floating point errors it equals really small non-zero values
                  <b>{totalNetTaxableFuel}</b>
                */
              }
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        { // TAX RATE
          element: (
            <div />
          ),
          props: { className: 'table-body-column-style' },
        },
        {
          element: (
            <div>
              <b>{Helpers.formatCentsToDollars(totalTaxDueCents)}</b>
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
      ],
      // props: { expandableContent }
    });
    // TODO: Add the avg mileage and fuel type here (copy vehicle totals)
    return (
      <div className="vehicleSummary-printable">
        <MDBRow className="mb-1">
          <MDBCol sm="6">
            Fleet Total IFTA Mileages
            <br />
            {`${moment(props.displayStartDate).format('LL')} - ${moment(props.displayEndDate).format('LL')}`}
          </MDBCol>
          <MDBCol sm="6">
            {props.IFTACurrency === 'CAD' ? 'Average Kilometres Per Litre:' : 'Average Miles Per Gallon:'} <b>{(avgKmPerLitre || 0).toFixed(2)}</b>
            <br />
            Fuel type: <b>{props.fuelType}</b>
          </MDBCol>
        </MDBRow>
        <SBTable
          hover
          tableHeaderRows={tableHeaderRows}
          tableBodyRows={tableBodyRows}
          hideZ
          isLoading={this.props.isLoading}
        />
      </div>
    );
  }

};

TaxReportTotalSummary.propTypes = {
  distanceUnitFilter: PropTypes.object,
  odometerReadings: PropTypes.object,
  selectedFilter: PropTypes.object,
  unitId: PropTypes.string,
  distanceUnit: PropTypes.string,
  dateStart: PropTypes.object,
  dateEnd: PropTypes.object,
  displayStartDate: PropTypes.any,
  displayEndDate: PropTypes.any,
  iftaRouteArr: PropTypes.array,
  isLoading: PropTypes.bool,
  fuelType: PropTypes.string,
  taxRates: PropTypes.object,
};

export default TaxReportTotalSummary;
