export default class DispatchJob {

  constructor(objectId, batchId, status, belongsToCompany, { ...params }) {
    this.objectId = objectId;
    this.batchId = batchId;
    this.status = status;
    this.belongsToCompany = belongsToCompany;
    this.notes = params.notes;
    this.isHidden = params.isHidden;
    this.startDateTimeUTC = params.startDateTimeUTC;
    this.endDateTimeUTC = params.endDateTimeUTC;
    this.customerDispatchBusiness = params.customerDispatchBusiness;
    this.consigneeDispatchBusiness = params.consigneeDispatchBusiness;
    this.shipperDispatchBusiness = params.shipperDispatchBusiness;
    this.brokerDispatchBusiness = params.brokerDispatchBusiness;
    this.carrierDispatchBusiness = params.carrierDispatchBusiness;
    this.referenceNumber = params.referenceNumber;
    this.pickupNumber = params.pickupNumber;
    this.dropoffNumber = params.dropoffNumber;
    this.paymentDueInDays = params.paymentDueInDays;
    this.invoiceNotes = params.invoiceNotes;
    this.invoiceDate = params.invoiceDate;
    this.currency = params.currency;
    this.defaultDrivers = params.defaultDrivers;
    this.defaultVehicle = params.defaultVehicle;
    this.isInvoiceOutdated = params.isInvoiceOutdated;
    this.createdByUser = params.createdByUser;
  }
}
