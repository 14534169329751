import store from 'store';
import * as ActionConstants from './ActionConstants';
import * as Setters from 'api/Setters';
import * as Getters from 'api/Getters';
import * as Parse from 'api/Parse';
import * as Helpers from 'api/Helpers';

const notificationAudio = require('assets/sounds/alert-sound.mp3');

function receiveNotification(notification) {
  return {
    type: ActionConstants.NOTIFICATION_RECEIVE,
    notification,
  };
}
function receiveDocument(documentParseObject) {
  return {
    type: ActionConstants.ADD_DOCUMENTS_SUCCESS,
    documents: [documentParseObject],
  };
}
function retakeDocument(documentParseObject, jobId, fileName) {
  return {
    type: ActionConstants.ADD_DOCUMENT_RETAKE,
    document: documentParseObject,
    jobId,
    fileName,
  };
}
function receiveJob(jobLinkParseObject) {
  return {
    type: ActionConstants.ADD_JOBLINK_SUCCESS,
    jobLink: jobLinkParseObject,
  };
}
function receiveReceipt(receiptParseObject) {
  return {
    type: ActionConstants.ADD_RECEIPT_SUCCESS,
    receipt: receiptParseObject,
  };
}
function completeJobAction(jobActionParseObject) {
  return {
    type: ActionConstants.UPDATE_JOBACTION_SUCCESS,
    jobAction: jobActionParseObject,
  };
}
function receiveELDEvent(eldEventParseObject) {
  return {
    type: ActionConstants.ADD_ELDEVENT_SUCCESS,
    eldEvent: eldEventParseObject,
  };
}
function receiveELDEdit(eldEditParseObject) {
  return {
    type: ActionConstants.ADD_ELDEDIT_SUCCESS,
    eldEdit: eldEditParseObject,
  };
}
function receiveGeofenceEvent(geofenceEventParseObject) {
  return {
    type: ActionConstants.ADD_GEOFENCEEVENT_SUCCESS,
    geofenceEvent: geofenceEventParseObject,
  };
}

export function notificationReceiveHandler(notificationObject) {
  const showGeofenceEventNotifications = store.getState().Dispatcher.dispatcher.get('showGeofenceEventNotifications');
  const showELDEditNotifications = store.getState().Dispatcher.dispatcher.get('showELDEditNotifications');
  const showHOSViolationNotifications = store.getState().Dispatcher.dispatcher.get('showHOSViolationNotifications');
  const showNotificationClassArr = [];
  if (showGeofenceEventNotifications) showNotificationClassArr.push('GeofenceEvent');
  if (showELDEditNotifications) showNotificationClassArr.push('ELDEdit');
  if (showHOSViolationNotifications) showNotificationClassArr.push('HOSviolation');
  // if (showDailyCertNotifications) showNotificationClassArr.push('DailyCertification');
  if (notificationObject.message && notificationObject.message.notificationClass === 'unidentifiedDriving') {
    showNotificationClassArr.push('unidentifiedDriving');
    store.dispatch(receiveNotification(notificationObject));
  }
  if (notificationObject.message && notificationObject.message.notificationClass === 'uncertifiedLogs') {
    showNotificationClassArr.push('uncertifiedLogs');
    store.dispatch(receiveNotification(notificationObject));
  }
  if (notificationObject.class === 'EngineModuleConnectivityStatus') {
    store.dispatch(receiveNotification(notificationObject));
  }
  if (showNotificationClassArr.indexOf(notificationObject.notificationClass) !== -1) {
    store.dispatch(receiveNotification(notificationObject));
  }
  Getters.getObjectsForNotification(notificationObject).then((parseObject) => {
    if (notificationObject.class === 'Document' && notificationObject.type === 'Create') {
      new Audio(notificationAudio).play();
      store.dispatch(receiveDocument(parseObject));
    } else if (notificationObject.class === 'JobLink' && notificationObject.type === 'Create') {
      new Audio(notificationAudio).play();
      store.dispatch(receiveJob(parseObject));
    } else if (notificationObject.class === 'Receipt' && notificationObject.type === 'Create') {
      new Audio(notificationAudio).play();
      store.dispatch(receiveReceipt(parseObject));
    } else if (notificationObject.class === 'Document' && notificationObject.type === 'Retake') {
      new Audio(notificationAudio).play();
      store.dispatch(retakeDocument(parseObject, notificationObject.jobId, notificationObject.fileName));
    } else if (notificationObject.class === 'JobAction' && notificationObject.type === 'Complete') {
      new Audio(notificationAudio).play();
      store.dispatch(completeJobAction(parseObject));
    } else if (notificationObject.class === 'ELDEdit') {
      new Audio(notificationAudio).play();
      store.dispatch(receiveELDEdit(parseObject));
    }
  });
}

function fetchingNotifications() {
  return {
    type: ActionConstants.FETCH_NOTIFICATIONS_INPROGRESS,
    fetched: false,
  };
}

function fetchNotificationsSuccess(notifications) {
  return {
    type: ActionConstants.FETCH_NOTIFICATIONS_SUCCESS,
    notifications,
  };
}

function fetchNotificationsError(error) {
  return {
    type: ActionConstants.FETCH_NOTIFICATIONS_ERROR,
    error,
  };
}

function deleteNotifications() {
  return {
    type: ActionConstants.DELETE_NOTIFICATIONS,
  };
}

export function fetchNotificationsForState() {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingNotifications());
    const showGeofenceEventNotifications = store.getState().Dispatcher.dispatcher.get('showGeofenceEventNotifications');
    const showELDEditNotifications = store.getState().Dispatcher.dispatcher.get('showELDEditNotifications');
    const showHOSViolationNotifications = store.getState().Dispatcher.dispatcher.get('showHOSViolationNotifications');
    Getters.getNotifications(showGeofenceEventNotifications, showELDEditNotifications, showHOSViolationNotifications).then(
      notificationParseObjects => {
        const notificationParseObjectsLength = notificationParseObjects.length;
        const notifications = [];
        for (let i = 0; i < Math.min(20, notificationParseObjectsLength); i++) {
          const notificationObject = Helpers.formatNotificationObject(notificationParseObjects[i]);
          if (notificationObject) {
            notifications.push(notificationObject);
          }
        }
        resolve(notifications);
        store.dispatch(fetchNotificationsSuccess(notifications));
      },
      error => {
        console.log(error);
        reject(error);
        store.dispatch(fetchNotificationsError(error));
      }
    );
  });
  return promise;
}

export function deleteNotificationsForState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteNotifications());
    resolve(store.getState().Notification);
  });
  return promise;
}

function setReadNotification(notificationObject, removeFromState) {
  return {
    type: ActionConstants.NOTIFICATION_SET_READ,
    removeFromState,
    notificationObject,
  };
}

export function readNotification(notificationObject, destroy) {
  const promise = new Promise((resolve, reject) => {
    Setters.setNotificationRead(notificationObject, destroy).then(
      parseNotificationObject => {
        store.dispatch(setReadNotification(Helpers.formatNotificationObject(parseNotificationObject), destroy));
        resolve(true);
      }
    );
  });
  return promise;
}

function subscriptionSuccess(subscription) {
  return {
    type: ActionConstants.NOTIFICATION_SUBSCRIBE_SUCCESS,
    subscription,
  };
}

export function subscribeNotificationsForState() {
  const onNewNotification = (notificationObject) => {
    new Audio(notificationAudio).play();
    store.dispatch(receiveNotification(notificationObject));
  };

  const subscription = Parse.subscribeToNotifications(onNewNotification);
  store.dispatch(subscriptionSuccess(subscription));
}

function unsubscribeSuccess() {
  return {
    type: ActionConstants.NOTIFICATION_UNSUBSCRIBE_SUCCESS,
  };
}

export function unsubscribeNotificationsForState() {
  Parse.subscribeToNotifications(store.getState().Notification.subscription);
  store.dispatch(unsubscribeSuccess());
}

export function toggleNotificationPanel() {
  return store.dispatch({
    type: ActionConstants.NOTIFICATION_PANEL_TOGGLE,
  });
}
