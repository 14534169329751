import React, { useState, useEffect } from 'react';

// enums
import { LengthUnit, LengthUnitName, LengthConversion } from 'sb-csapi/dist/enums/Unit';

// sbcore
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Dropdown from 'sbCore/Dropdown/Dropdown';

// styles
import './style.scss';

/**
 * @description Distance unit dropdown
 * @param {String} className - Custom container className
 * @param {String} dropdownClassName - Custom dropdown className
 * @param {Object} style = Custom inline styles
 * @param {String} label - Custom label
 * @param {Bool} warning - Makes the border yellow
 * @param {Bool} error - Makes the border red
 * @param {String} unit - Short unit value. ex. 'MI', 'KM'. The string provided must be recognized in our enums
 * @param {Bool} hideLabel - Hide the label
 * @param {Bool} disabled - Whether to disable the dropdown or not
 * @param {Function} onSelect - Callback function that returns the selected distance unit
 * @param {String} variant - Smaller dropdown
 * @example
 * <DistanceUnitDropdown unit={ENUM.KM} onSelect={(e) => handleSelect(e)} />
 */
function DistanceUnitDropdown({ ...props }) {
  const [selectedUnit, setSelectedUnit] = useState(props.unit);

  // parse unit enum into dropdown options
  const unitOptions = [
    { short: LengthUnit.KM, name: LengthUnitName.KM, miToKmConversion: LengthConversion.MI2KM },
    { short: LengthUnit.MI, name: LengthUnitName.MI, kmToMiConversion: LengthConversion.KM2MI },
  ];

  // Set the km item as default
  const defaultOption = unitOptions[0];

  /* ------------------- useEffect ------------------ */
  // Check to see if dropdown value is changed from parent
  useEffect(() => {
    // props.unit can be passed in as either object or a string
    if (typeof props.unit === 'object') {
      setSelectedUnit(props.unit);
    }

    // string: 'KM' or 'MI'
    // Find the unit object for the string
    if (typeof props.unit === 'string') {
      const unitObj = unitOptions.find(unitOptionsObj => unitOptionsObj.short === props.unit);
      setSelectedUnit(unitObj);
    }
  }, [props.unit])

  /* ------------------- Helpers ------------------ */
  function onChange(e) {
    setSelectedUnit(e.value);
    if (props.onSelect) props.onSelect(e.value);
  }

  /* --------------------- Misc -------------------- */
  let className = 'distance-unit-dropdown';
  if (props.className) className += ` ${props.className}`;
  let variant = '';
  if (props.variant === 'small') variant += 'small';

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && (
        <InputLabel>{props.label || 'Distance Unit'}</InputLabel>
      )}
      <Dropdown
        className={props.dropdownClassName}
        value={selectedUnit || defaultOption}
        options={unitOptions}
        optionLabel="short"
        onChange={(e) => onChange(e)}
        onBlur={(e) => {
          if (props.onBlur) props.onBlur(e);
        }}
        disabled={props.disbaled}
        error={props.error}
        warning={props.warning}
        variant={variant}
      />
    </div>
  )
}

export default DistanceUnitDropdown;
