import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { MDBRow, MDBCol } from 'mdbreact';

// API
import * as Helpers from 'api/Helpers';
import { getAttribute } from 'api/Parse';
import { StateProvinces } from 'api/Lists/StateProvinces';
import { findTotalMileageIssues } from 'api/IFTARoute/IFTARoute.old';

// Components
import SBTable from 'components/Shared/SBTable/SBTable';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import ErrorMessages from 'components/IFTARoute/container/ErrorMessages';
import SBBlock from 'components/Shared/SBBlock/SBBlock';

// Enums
import ErrorTypes from 'enums/IFTARoute';

class TaxReportVehicleSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errorMessages: [],
      tableBodyRows: [],
    };
    this.fuelTypeMessage = '';
    this.generateTableBodyRowData = this.generateTableBodyRowData.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    if (prevProps.iftaRouteArr.length !== props.iftaRouteArr.length
      || prevProps.odometerReadings !== props.odometerReadings
      || prevProps.distanceUnitFilter.value !== props.distanceUnitFilter.value
      || prevProps.selectedFilter.value !== props.selectedFilter.value
      || prevProps.fuelType !== props.fuelType
      || prevProps.IFTACurrency !== props.IFTACurrency
    ) {
      this.generateTableBodyRowData();
      this.fuelTypeMessage = '';
    }
    return false;
  }

  handleScroll(e, anchorTarget) {
    // We need to grab the row element, hence we retrieve the grandparent element
    const element = document.getElementById(anchorTarget).parentElement.parentElement;
    const hasGrayBg = element.classList.contains('bg-gray-hover');

    e.preventDefault();
    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

    // Toggle the classes to temporarily remove styling (workaround due to the classes using !important)
    element.classList.toggle('error-alert');
    hasGrayBg && element.classList.toggle('bg-gray-hover');
    element.classList.toggle('hover-gray-matte');

    setTimeout(() => {
      element.classList.toggle('error-alert');
      hasGrayBg && element.classList.toggle('bg-gray-hover');
      element.classList.toggle('hover-gray-matte');
    }, 3000);
  }

  async generateTableBodyRowData() {
    this.setState({ ...this.state, isLoading: true });
    const { props } = this;

    const stateProvinceCodes = StateProvinces.map((stateProvince) => stateProvince.code).filter((stateProvinceCode) => stateProvinceCode);
    const stateProvinceDataArr = [];
    const iftaArrMiles = {}; // Contains the summed totals for each state/province

    const { IFTACurrency } = props; // Add currency selector in future
    // TotalFuelPurchases dictionary defined below would be useful if we used enums not user input strings for fuel type
    let totalVolumePumped = 0; // Units dependent on currency selected above and conditioned on below
    let totalTaxableFuel = 0;
    let totalTaxDueCents = 0;

    const totalFuelPurchases = {};
    let totalFuelTotalPaidCAD = 0;
    let totalFuelTotalPaidUSD = 0;

    let totalDistanceKm = 0;
    let totalSavedVehicleKm = 0;
    let totalSpreadKm = 0;

    // Categorize the IFTARoutes into state/provinces and combines their information
    for (let i = 0; i < props.iftaRouteArr.length; i++) {
      const iftaRoute = props.iftaRouteArr[i];
      const stateProvince = getAttribute(iftaRoute, 'stateProvince') && getAttribute(iftaRoute, 'stateProvince').toUpperCase();
      const fuelPurchases = getAttribute(iftaRoute, 'fuelPurchases');
      const totalVehicleKmDiff = getAttribute(iftaRoute, 'totalVehicleKmDiff') || 0;
      let distanceKm = getAttribute(iftaRoute, 'distanceKm') || 0;
      let savedVehicleKm = getAttribute(iftaRoute, 'savedVehicleKm') || 0;

      distanceKm = Math.round(distanceKm);
      savedVehicleKm = Math.round(savedVehicleKm);

      totalDistanceKm += distanceKm;
      totalSavedVehicleKm += savedVehicleKm;

      if (iftaArrMiles[stateProvince]) {
        iftaArrMiles[stateProvince].distanceKm += distanceKm;
        iftaArrMiles[stateProvince].totalVehicleKmDiff += totalVehicleKmDiff;
        iftaArrMiles[stateProvince].savedVehicleKm += savedVehicleKm;
      } else {
        iftaArrMiles[stateProvince] = {};
        iftaArrMiles[stateProvince].distanceKm = distanceKm;
        iftaArrMiles[stateProvince].totalVehicleKmDiff = totalVehicleKmDiff;
        iftaArrMiles[stateProvince].savedVehicleKm = savedVehicleKm;
        iftaArrMiles[stateProvince].fuelPurchaseSubtotals = 0;
        iftaArrMiles[stateProvince].fuelTotalPaidCAD = 0;
        iftaArrMiles[stateProvince].fuelTotalPaidUSD = 0;
      }

      // Fuel Purchases
      if (fuelPurchases) {
        for (let j = 0; j < fuelPurchases.length; j++) {
          const fuelPurchase = fuelPurchases[j];
          const fuelType = getAttribute(fuelPurchase, 'fuelType') && getAttribute(fuelPurchase, 'fuelType').toUpperCase();
          const totalPaid = getAttribute(fuelPurchase, 'totalPaid');
          const currency = getAttribute(fuelPurchase, 'currency');

          const volumeUnits = getAttribute(fuelPurchase, 'volumeUnits');
          // Tax rates for CAD are based on litres pumped, and rates for USD are based on gallons
          let volumePumped = getAttribute(fuelPurchase, 'volumePumped', true);
          if (IFTACurrency === 'CAD') {
            volumePumped = (volumeUnits === 'g') ? Helpers.convertFuelUnit(getAttribute(fuelPurchase, 'volumePumped'), 'gal', 'l') : getAttribute(fuelPurchase, 'volumePumped');
          } else if (IFTACurrency === 'USD') {
            volumePumped = (volumeUnits === 'l') ? Helpers.convertFuelUnit(getAttribute(fuelPurchase, 'volumePumped'), 'l', 'gal') : getAttribute(fuelPurchase, 'volumePumped');
          }
          volumePumped = Math.round(volumePumped);

          totalVolumePumped += volumePumped;
          iftaArrMiles[stateProvince].fuelPurchaseSubtotals += volumePumped;

          if (totalFuelPurchases[fuelType]) {
            totalFuelPurchases[fuelType] += volumePumped;
          } else {
            totalFuelPurchases[fuelType] = volumePumped;
          }

          if (currency === 'cad') {
            iftaArrMiles[stateProvince].fuelTotalPaidCAD += totalPaid;
            totalFuelTotalPaidCAD += totalPaid;
          } else if (currency === 'usd') {
            iftaArrMiles[stateProvince].fuelTotalPaidUSD += totalPaid;
            totalFuelTotalPaidUSD += totalPaid;
          }
        }
      }
    }

    // This is for deciding whether to spread the calculated Vehicle Km
    // Should Use SpreadKm when the odometerDifference is not negative or way too large!
    // Otherwise will just use calculatedOdometerKm
    let shouldUseSpreadKm = false;
    const odometerDiffKm = Math.round(props.odometerReadings && props.odometerReadings.odometerDiffKm) || 0;

    if (odometerDiffKm > 0
      && totalSavedVehicleKm > 0
      && (odometerDiffKm < (moment(props.dateEnd).diff(moment(props.dateStart), 'hours') * 200))
      && (totalSavedVehicleKm < (moment(props.dateEnd).diff(moment(props.dateStart), 'hours') * 200))
      && (Math.abs(totalSavedVehicleKm - odometerDiffKm) < (5000 * (moment(props.dateEnd).diff(moment(props.dateStart), 'months') + 1)))
    ) {
      shouldUseSpreadKm = true;
    }

    for (let i = 0; i < stateProvinceCodes.length; i++) {
      let stateProvince = stateProvinceCodes[i];
      if (stateProvince === 'NF') stateProvince = 'NL';

      const subtotalSavedVehicleKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].savedVehicleKm ? Math.round(iftaArrMiles[stateProvince].savedVehicleKm) : 0;
      const subtotalTotalVehicleKmDiff = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].totalVehicleKmDiff ? Math.round(iftaArrMiles[stateProvince].totalVehicleKmDiff * 100) / 100 : 0;
      const subtotalDistanceKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].distanceKm ? Math.round(iftaArrMiles[stateProvince].distanceKm) : 0;
      const fuelPurchaseSubtotals = iftaArrMiles[stateProvince] ? iftaArrMiles[stateProvince].fuelPurchaseSubtotals : 0;

      const subtotalSpreadKm = Math.round(totalSavedVehicleKm) !== 0 ? totalSavedVehicleKm && totalSavedVehicleKm && odometerDiffKm && Math.round((subtotalSavedVehicleKm / totalSavedVehicleKm) * odometerDiffKm) : subtotalSavedVehicleKm;
      totalSpreadKm += subtotalSpreadKm;
      const stateProvinceDataObj = {
        code: stateProvince,
        subtotalSavedVehicleKm,
        subtotalTotalVehicleKmDiff,
        subtotalDistanceKm,
        fuelPurchaseSubtotals,
        subtotalSpreadKm: shouldUseSpreadKm ? subtotalSpreadKm : subtotalSavedVehicleKm,
      };
      stateProvinceDataArr.push(stateProvinceDataObj);
    }

    const gpsMileageArray = stateProvinceDataArr.map((stateProvinceData) => {
      const stateProvince = stateProvinceData.code;
      const { subtotalDistanceKm } = stateProvinceData;
      return { stateProvince, subtotalDistanceKm };
    });

    const calculatedMileageArray = stateProvinceDataArr.map((stateProvinceData) => {
      const stateProvince = stateProvinceData.code;
      const subtotalCalculatedKm = shouldUseSpreadKm ? stateProvinceData.subtotalSpreadKm : stateProvinceData.subtotalSavedVehicleKm;
      return { stateProvince, subtotalCalculatedKm };
    });

    const totalCalculatedMileage = shouldUseSpreadKm ? totalSpreadKm : totalSavedVehicleKm;

    const avgKmPerLitre = totalVolumePumped ? totalCalculatedMileage / totalVolumePumped : 0;

    const tableBodyRows = stateProvinceDataArr.filter((stateProvinceData) => {
      if (props.selectedFilter.value === 'hideZeroMileages') return stateProvinceData.subtotalSavedVehicleKm;
      return true;
    }).map(stateProvinceData => {
      const rowId = `${props.unitId}-${stateProvinceData.code}`;
      let stateTaxRate = props.taxRates[stateProvinceData.code].ratesDictionary[props.fuelType];

      const { subtotalSpreadKm, fuelPurchaseSubtotals } = stateProvinceData;

      stateTaxRate = stateTaxRate ? stateTaxRate[props.IFTACurrency] : undefined;
      if (typeof stateTaxRate === 'string') stateTaxRate = parseFloat(stateTaxRate);

      let stateTaxableFuel;
      let stateNetTaxableFuel;
      let stateTaxDueCents = 0;

      if (typeof stateTaxRate === 'number') {
        // Update the total and Due
        stateTaxableFuel = avgKmPerLitre
          ? ((subtotalSpreadKm || 0) / avgKmPerLitre)
          : 0;
        stateTaxableFuel = Math.round(stateTaxableFuel);
        stateNetTaxableFuel = stateTaxableFuel - (fuelPurchaseSubtotals || 0);
        stateTaxDueCents = Math.round(stateNetTaxableFuel * stateTaxRate * 100);

        totalTaxableFuel += stateTaxableFuel;
      }
      totalTaxDueCents += stateTaxDueCents;
      const stateTaxDueDollars = Helpers.formatCentsToDollars(stateTaxDueCents);

      const vehicleRowObj = {
        key: rowId,
        columns: [
          {
            element: (
              <div id={rowId}>
                {stateProvinceData.code}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          {
            element: (
              <div>
                {stateProvinceData.subtotalDistanceKm ? (props.IFTACurrency === 'CAD' ? stateProvinceData.subtotalDistanceKm.toFixed(0) : Helpers.convertDistance(stateProvinceData.subtotalDistanceKm, 'km', 'mi', true).toFixed(0)) : 0}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          {
            element: (
              <div>
                {parseFloat(stateProvinceData.fuelPurchaseSubtotals || 0)}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          {
            element: (
              <div>
                {(stateProvinceData.subtotalSpreadKm
                  ? (props.IFTACurrency === 'CAD'
                    ? stateProvinceData.subtotalSpreadKm.toFixed(0)
                    : Helpers.convertDistance(stateProvinceData.subtotalSpreadKm, 'km', 'mi', true).toFixed(0)
                  )
                  : 0
                )}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          // Taxable
          {
            element: (
              <div>
                {stateTaxableFuel || this.fuelTypeMessage}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          // Net Taxable
          {
            element: (
              <div>
                {stateNetTaxableFuel || this.fuelTypeMessage}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          {
            element: <div>{stateTaxRate !== undefined ? stateTaxRate.toFixed(4) : ''}</div>,
            props: { className: 'table-body-column-style' },
          },
          {
            element: (
              <div>
                {props.isLoading === false ? stateTaxDueDollars : <LoadingIcon />}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
        ],
      };

      return vehicleRowObj;
    });

    tableBodyRows.push({
      key: 'total',
      columns: [
        {
          element: (
            <div>
              <b>Total</b>
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        // GPS Mileage
        // getting rid of gps mileage
        // {
        //   element: (
        //     <div>
        //       <b>{props.IFTACurrency === 'CAD' ? totalDistanceKm.toFixed(0) : Helpers.convertDistance(totalDistanceKm, 'km', 'mi', true).toFixed(0)}</b>
        //     </div>
        //   ),
        //   props: { className: 'table-body-column-style' },
        // },
        // Fuel Volume Pumped
        {
          element: (
            <div>
              <b>{totalVolumePumped}</b>
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        // TODO: What the heck is this please change it! Supposed to be total calculated a.k.a spread
        {
          element: (
            <div>
              <b>
                {shouldUseSpreadKm ? (odometerDiffKm && props.IFTACurrency === 'CAD' ? odometerDiffKm.toFixed(0) : Helpers.convertDistance(odometerDiffKm, 'km', 'mi', true).toFixed(0)) : (props.IFTACurrency === 'CAD' ? totalSavedVehicleKm.toFixed(0) : Helpers.convertDistance(totalSavedVehicleKm, 'km', 'mi', true).toFixed(0))}
              </b>
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        // Taxable Fuel
        {
          element: (
            <div>
              <b>{totalTaxableFuel}</b>
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        // Net Taxable Fuel
        {
          element: (
            <div>
              {/* Useless value! It mathematically is equal to zero but with floating point math it equals really small non-zero values
                <b>{totalNetTaxableFuel.toFixed(2)}</b>
              */}
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        // Tax Rate
        {
          element: (
            <div />
          ),
          props: { className: 'table-body-column-style' },
        },
        // Tax Due
        {
          element: (
            <div>
              <b>{Helpers.formatCentsToDollars(totalTaxDueCents)}</b>
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
      ],
    });

    const iftaRouteIssuesObject = findTotalMileageIssues(gpsMileageArray, calculatedMileageArray, totalCalculatedMileage, odometerDiffKm || 0);
    const errorMessages = this.generateErrorMessages(totalSavedVehicleKm, totalSpreadKm, odometerDiffKm, iftaRouteIssuesObject, shouldUseSpreadKm);

    this.setState({ tableBodyRows, errorMessages, isLoading: false, avgKmPerLitre });
  }

  generateErrorMessages(totalSavedKm, totalSpreadKm, odometerDiff, iftaRouteIssuesObject, shouldUseSpreadKm) {
    const { props } = this;
    const errorMessages = [];

    const generateStateProvinceLinks = (stateProvinceIssuesArray) => {
      return (stateProvinceIssuesArray.map((issue, index) => {
        const { stateProvince } = issue;
        const rowId = `${props.unitId}-${stateProvince}`;
        if (index === stateProvinceIssuesArray.length - 1) {
          return (
            <React.Fragment>
              <a
                href={`#${rowId}`}
                onClick={(e) => this.handleScroll(e, rowId)}
              >
                {stateProvince}
              </a>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <a
                href={`#${rowId}`}
                onClick={(e) => this.handleScroll(e, rowId)}
              >
                {stateProvince}
              </a>
              {', '}
            </React.Fragment>
          );
        }
      }));
    };

    if ((totalSavedKm === 0 || totalSpreadKm === 0) && odometerDiff > 0) {
      errorMessages.push(
        <div style={{ fontSize: '1em' }}>
          <ErrorMessages
            errorType={ErrorTypes.NO_MILEAGE_INFORMATION}
          />
          The mileage information may not have been uploaded yet.
        </div>,
      );
    }
    if (iftaRouteIssuesObject.gpsMileageAndCalculatedMileageOutsideThreshold || iftaRouteIssuesObject.isOdometerDifferenceAndTotalMileageDifferent) {
      // if (iftaRouteIssuesObject.gpsMileageAndCalculatedMileageOutsideThreshold.length > 0) {
      //   errorMessages.push(
      //     <div className="error-message" style={{ fontSize: '1em' }}>
      //       <ErrorMessages
      //         errorType={ErrorTypes.GPS_CALCULATED_MILEAGE_DIFFERENCE}
      //       />
      //       {'There may be mileage errors associated with the following state/provinces: '}
      //       {generateStateProvinceLinks(iftaRouteIssuesObject.gpsMileageAndCalculatedMileageOutsideThreshold)}
      //     </div>,
      //   );
      // }
      if (iftaRouteIssuesObject.isOdometerDifferenceAndTotalMileageDifferent) {
        errorMessages.push(
          <div style={{ fontSize: '1em' }}>
            <ErrorMessages
              errorType={ErrorTypes.TOTAL_ODOMETER_CALCULATED_DIFFERENCE}
            />
            The total odometer difference <b>{(odometerDiff && props.IFTACurrency === 'CAD' ? odometerDiff.toFixed(0) : Helpers.convertDistance(odometerDiff, 'km', 'mi', true).toFixed(0))}</b> does not match the total calculated mileage
            <b> {
              shouldUseSpreadKm ?
                (odometerDiff && props.IFTACurrency === 'CAD' ? odometerDiff.toFixed(0) : Helpers.convertDistance(odometerDiff, 'km', 'mi', true).toFixed(0)) :
                (props.IFTACurrency === 'CAD' ? totalSavedKm.toFixed(0) : Helpers.convertDistance(totalSavedKm, 'km', 'mi', true).toFixed(0))
            }
            </b>
          </div>,
        );
      }
    }

    return errorMessages;
  }


  render() {
    const { props, state } = this;

    const tableHeaderStyles = {
      stateProvince: { width: '10%', overflowWrap: 'break-word' },
      taxRate: { width: '10%', overflowWrap: 'break-word' },
      gps: { width: '10%', overflowWrap: 'break-word' },
      fuel: { width: '15%', overflowWrap: 'break-word' },
      taxableLitres: { width: '10%', overflowWrap: 'break-word' },
      netTaxableLitres: { width: '10%', overflowWrap: 'break-word' },
      calculated: { width: '20%', overflowWrap: 'break-word' },
      taxDue: { width: '15%', overflowWrap: 'break-word' },
    };
    const volumeUnit = props.IFTACurrency === 'CAD' ? 'Litres' : 'Gallons';
    const tableHeaderRows = [{
      key: 'sb-ifta-vehicle-summary-headers',
      columns: [
        {
          element: <div>State / Province</div>,
          props: { style: tableHeaderStyles.stateProvince },
        },
        // getting rid of gps mileage
        // {
        //   element: <div>GPS Mileage ({props.IFTACurrency === 'CAD' ? 'km' : 'mi'})</div>,
        //   props: { style: tableHeaderStyles.gps },
        // },
        {
          element: <div className="centerText">Fuel Purchases ({props.IFTACurrency === 'CAD' ? 'Litres' : 'Gallons'})</div>,
          props: { style: tableHeaderStyles.fuel },
        },
        {
          element: <div>Calculated Mileage ({props.IFTACurrency === 'CAD' ? 'km' : 'mi'})</div>,
          props: {
            style: tableHeaderStyles.calculated,
          },
        },
        {
          element: <div className="centerText">Taxable {volumeUnit}</div>,
          props: { style: tableHeaderStyles.taxableLitres },
        },
        {
          element: <div>Net Taxable {volumeUnit}</div>,
          props: { style: tableHeaderStyles.netTaxableLitres },
        },
        {
          element: <div>Tax Rate</div>,
          props: {
            style: tableHeaderStyles.taxRate,
          },
        },
        {
          element: <div>Tax Due ({props.IFTACurrency})</div>,
          props: { style: tableHeaderStyles.taxDue },
        },
      ],
    }];

    // const { tableBodyRows, errorMessages } = this.generateTableBodyRowData();

    return (
      <div>
        {state.errorMessages && state.errorMessages.length > 0 && (
          <SBBlock
            warning
            title="Notice"
            messages={state.errorMessages}
            listMessages={false}
          />
        )}
        {this.props.odometerReadingsDisplayingZero === true && (
          <SBBlock
            error
            listMessages={false}
            title="Note"
            messages={[
              <div style={{ fontSize: '1em' }}>
                It is detected that most or all of the routes are not displaying odometer readings. There might be connection issues with the engine module.
              </div>
            ]}
          />
        )}
        <MDBRow className="mb-1">
          <MDBCol sm="6">
            IFTA Mileages
            <br />
            {`${moment(props.displayStartDate).format('LL')} - ${moment(props.displayEndDate).format('LL')}`}
          </MDBCol>
          <MDBCol sm="6">
            {props.IFTACurrency === 'CAD' ? 'Average Kilometres Per Litre:' : 'Average Miles Per Gallon:'} <b>{(state.avgKmPerLitre || 0).toFixed(2)}</b>
            <br />
            Fuel type: <b>{props.fuelType}</b>
          </MDBCol>
        </MDBRow>
        <SBTable
          hover
          isLoading={state.isLoading}
          tableHeaderRows={tableHeaderRows}
          tableBodyRows={state.tableBodyRows}
        />
      </div>
    );
  }
}

TaxReportVehicleSummary.propTypes = {
  distanceUnitFilter: PropTypes.object,
  odometerReadings: PropTypes.object,
  selectedFilter: PropTypes.object,
  unitId: PropTypes.string,
  distanceUnit: PropTypes.string,
  dateStart: PropTypes.object,
  dateEnd: PropTypes.object,
  displayStartDate: PropTypes.any,
  displayEndDate: PropTypes.any,
  iftaRouteArr: PropTypes.array,
  routeToNewIFTA: PropTypes.bool,
  taxRates: PropTypes.object,
};

export default TaxReportVehicleSummary;
