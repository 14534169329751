import React from 'react';
import PropTypes from 'prop-types';
import { MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol, MDBContainer, MDBBtn, MDBIcon } from 'mdbreact';

// API
import { getEquipmentGroupTrailers, addEquipmentToEquipmentGroup, removeEquipmentFromEquipmentGroup } from 'api/Equipment/Groups.old';
import { trailerTypes } from 'api/Lists/EquipmentTypes';
import { getAttribute } from 'api/Parse';

// Enums
import { QueryRestrictionTypes, QuerySortOrderTypes } from 'enums/Query';

// sbObject
import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

// Actions
import { disableTrailer, enableTrailer } from 'actions/Trailer';

// Components
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import TrailerAutocomplete from 'components/Shared/TrailerAutocomplete/TrailerAutocomplete';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import TrailersTable from 'components/TrailersTable/container/TrailersTable';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import Title from 'components/LayoutTitle/view/Title';

class EquipmentGroupTrailersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Trailer: {
        trailers: [],
        fetched: false,
      },
      sortOrder: QuerySortOrderTypes.DESCENDING,
      sortObject: new Sort('unitId', QuerySortOrderTypes.DESCENDING),
      // filters for searching trailer by date range, notes, type, unitId
      filterFormFields: [
        {
          attrName: 'nextInspectionDate',
          queryType: 'date',
          dateQueryType: QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO,
          fullName: 'To',
          type: 'date',
        },
        {
          attrName: 'nextInspectionDate',
          queryType: 'date',
          dateQueryType: QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO,
          fullName: 'From',
          type: 'date',
        },
        {
          attrName: 'notes',
          fullName: 'Notes',
          queryType: QueryRestrictionTypes.MATCHES,
          type: 'text',
        },
        {
          attrName: 'type',
          fullName: 'Type',
          queryType: QueryRestrictionTypes.MATCHES,
          type: 'select',
          selectArr: trailerTypes,
        },
        {
          attrName: 'unitId_lowercase',
          fullName: 'Unit ID',
          queryType: QueryRestrictionTypes.MATCHES,
          type: 'text',
        },
      ],
      // search filters to apply to the parse query
      fieldFiltersToQuery: [],
      // filters for selecting between active and inactive trailers
      items: [
        {
          key: 1,
          label: 'Active Trailers',
          value: 'Active Trailers',
          filter: {
            attribute: 'enabled',
            value: true,
            queryType: QueryRestrictionTypes.EQUAL_TO,
          },
        },
        {
          key: 2,
          label: 'Inactive Trailers',
          value: 'Inactive Trailers',
          filter: {
            attribute: 'enabled',
            value: false,
            queryType: QueryRestrictionTypes.EQUAL_TO,
          },
        },
      ],
      // select filters to apply to the parse query
      itemFiltersToQuery: [
        new Filter('enabled', true, QueryRestrictionTypes.EQUAL_TO),
      ],
      selectedItems: [
        {
          key: 1,
          label: 'Active Trailers',
          value: 'Active Trailers',
          filter: {
            attribute: 'enabled',
            value: true,
            queryType: QueryRestrictionTypes.EQUAL_TO,
          },
        },
      ],
      showModal: false,
      selectedTrailer: undefined,
    };

    this.disableTrailer = this.disableTrailer.bind(this);
    this.enableTrailer = this.enableTrailer.bind(this);
    this.deleteTrailer = this.deleteTrailer.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleFieldsFilter = this.handleFieldsFilter.bind(this);
    this.handleItemsFilter = this.handleItemsFilter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    await this.refreshState();
  }

  async refreshState() {
    const { props, state } = this;

    const { trailerRecords } = await getEquipmentGroupTrailers(
      props.group,
      undefined,
      undefined,
      undefined,
      [new Filter(
        state.selectedItems[0].filter.attribute,
        state.selectedItems[0].filter.value,
        state.selectedItems[0].filter.queryType,
      )],
    );
    // Do not change the keys of this object! Unless you change TrailersTable
    const Trailer = {
      trailers: trailerRecords,
      fetched: true,
    };

    this.setState({ Trailer });
  }

  async disableTrailer(confirmBool, trailerRecord) {
    if (confirmBool) {
      disableTrailer(trailerRecord);
      await this.refreshState();
    }
  }

  async enableTrailer(confirmBool, trailerRecord) {
    if (confirmBool) {
      enableTrailer(trailerRecord);
      await this.refreshState();
    }
  }

  async deleteTrailer(confirmBool, trailerRecord) {
    const { props } = this;

    if (confirmBool) {
      await removeEquipmentFromEquipmentGroup(props.group, trailerRecord, 'Trailer');
      await this.refreshState();
    }
  }

  scrollHandler(e) {
    // Do nothing
  }

  async sortHandler(attribute) {
    const { props, state } = this;

    let sortOrder;
    let sortObject;

    if (state.sortOrder === QuerySortOrderTypes.DESCENDING) {
      sortOrder = QuerySortOrderTypes.ASCENDING;
      sortObject = new Sort(attribute, sortOrder);
    } else if (state.sortOrder === QuerySortOrderTypes.ASCENDING) {
      sortOrder = QuerySortOrderTypes.DESCENDING;
      sortObject = new Sort(attribute, sortOrder);
    }

    const { trailerRecords } = await getEquipmentGroupTrailers(
      props.group,
      undefined,
      undefined,
      sortObject,
      [...state.fieldFiltersToQuery, ...state.itemFiltersToQuery],
    )

    const Trailer = { ...state.Trailer, trailers: trailerRecords };

    this.setState({ sortOrder, sortObject, Trailer });
  }

  async handleFilter() {
    const { props, state } = this;

    const { trailerRecords } = await getEquipmentGroupTrailers(
      props.group,
      undefined,
      undefined,
      state.sortObject,
      [...state.fieldFiltersToQuery, ...state.itemFiltersToQuery],
    );

    const Trailer = { ...state.Trailer, trailers: trailerRecords };

    this.setState({ Trailer });
  }

  async handleFieldsFilter(filters = []) {
    const fieldFiltersToQuery = [];
     
    filters.forEach(filter => {
      if (filter.attribute === 'nextInspectionDate') {
        fieldFiltersToQuery.push(
          new Filter(filter.attribute, filter.fromValue._d, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO),
          new Filter(filter.attribute, filter.toValue._d, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO),
        );
      } else {
        fieldFiltersToQuery.push(
          new Filter(filter.attribute, filter.value, filter.queryType),
        );
      }
    });

    this.setState({ fieldFiltersToQuery }, async () => await this.handleFilter());
  }

  async handleItemsFilter(selectedItems) {
    const itemFiltersToQuery = selectedItems.map(item => {
      return new Filter(item.filter.attribute, item.filter.value, item.filter.queryType);
    });

    this.setState({ selectedItems, itemFiltersToQuery }, async () => await this.handleFilter());
  }

  async handleSubmit() {
    const { props, state } = this;

    await addEquipmentToEquipmentGroup(props.group, undefined, state.selectedTrailer);

    this.setState({ selectedTrailer: undefined, showModal: false }, async () => await this.refreshState());
  }

  render() {
    const { props, state } = this;

    return (
      <MDBContainer fluid>
        <MDBModal isOpen={state.showModal} centered data-backdrop="static">
          <MDBModalHeader>
            Add Trailer
          </MDBModalHeader>
          <MDBModalBody>
            <TrailerAutocomplete
              onTrailerSelected={(selectedTrailer) => this.setState({ selectedTrailer })}
              allowDeselect
            />
          </MDBModalBody>
          <MDBModalFooter>
            <ActionsContainer>
              <MDBBtn
                color="default"
                size="sm"
                onClick={() => this.setState({ selectedTrailer: undefined, showModal: false })}
              >
                Cancel
              </MDBBtn>
              <MDBBtn
                color="primary"
                size="sm"
                onClick={this.handleSubmit}
                disabled={!state.selectedTrailer}
              >
                Submit
              </MDBBtn>
            </ActionsContainer>
          </MDBModalFooter>
        </MDBModal>
        <MDBRow>
          <MDBCol xs='12' lg='5'>
            <Title title={getAttribute(props.group, 'name')} />
          </MDBCol>
          <MDBCol xs='12' lg='5'>
            <FilterForm
              className='floatRight'
              handleFilter={this.handleFieldsFilter}
              clearFilter={() => this.handleFieldsFilter([])}
              fields={this.state.filterFormFields}
            />
          </MDBCol>
          <MDBCol xs='12' lg='2'>
            <ActionsContainer>
              <MDBBtn
                size="sm"
                color="primary"
                onClick={() => this.setState({ showModal: true })}
              >
                <MDBIcon className="mr-1" icon="plus" />
                Trailer
              </MDBBtn>
              <SBSelect
                items={state.items}
                selectedItems={state.selectedItems}
                getSelectedItems={this.handleItemsFilter}
                containerClassName="ml-2 mr-2 d-inline-block mt-0"
                size="md"
              />
            </ActionsContainer>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <TrailersTable
              Trailer={state.Trailer}
              disableTrailer={this.disableTrailer}
              enableTrailer={this.enableTrailer}
              deleteTrailer={this.deleteTrailer}
              scrollHandler={this.scrollHandler}
              sortHandler={this.sortHandler}
              sort={state.sortObject}
            />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

EquipmentGroupTrailersList.propTypes = {
  group: PropTypes.object.isRequired,
}

export default EquipmentGroupTrailersList;
