import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import AlertTypes from 'enums/SBAlert';

import { deleteAlert } from 'actions/SBAlert/SBAlert';

// Components
import { MDBContainer, MDBAlert } from 'mdbreact';

// CSS
import './style.scss';

// Context
import StoreContext from 'contexts/StoreContext';


class AlertBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      instanceMillis: (new Date()).getTime(), // use this timestamp as part of dom identifiers
    };
    this.setDismissableTimeouts = this.setDismissableTimeouts.bind(this);
  }

  componentDidMount() {
    const { SBAlert } = this.props;
    this.setDismissableTimeouts(SBAlert.alerts)
  }

  componentWillReceiveProps(nextProps) {
    const { SBAlert } = nextProps;
    this.setDismissableTimeouts(SBAlert.alerts)
  }

  setDismissableTimeouts(alerts) {
    const { instanceMillis } = this.state;

    alerts.map(alert => {
      const autoDismissInMillis = alert.autoDismissInMillis;
      if (autoDismissInMillis) {
        setTimeout(() => {
          // repeat the close animation by simulating click of close button
          const altKey = `alt-${instanceMillis}-${alert.id}`;
          if (document.querySelector(`div.alert-bar.${altKey} button.close`)) document.querySelector(`div.alert-bar.${altKey} button.close`).click();

          // this .click() will automatically delete the alert from the global state as well because of the
          // onClosed handler on the MDBAlert component itself
        }, autoDismissInMillis);
      }
    });
  }


  render() {
    const { SBAlert } = this.props;
    const { alerts } = SBAlert;
    const { instanceMillis } = this.state;

    // console.log(alerts);

    const bars = alerts.map((alert, index) => {
      let className = 'alert-bar';

      switch (alert.type) {
        case AlertTypes.WARNING:
          className += ' alert-bar-warning';
          break;
        case AlertTypes.ERROR:
          className += ' alert-bar-error';
          break;
        case AlertTypes.UPDATE:
          className += ' alert-bar-update';
          break;
        case AlertTypes.SUCCESS:
          className += ' alert-bar-success';
          break;
      }

      const key = `${alert.id}` || `${index}-${(new Date()).getTime()}`;
      // alternate key if the user decides to use an integer for an id/key (which will lead to DOM issues from querySelector)
      const altKey = `alt-${instanceMillis}-${key}`;

      return (
        <MDBAlert key={key} className={`${className} ${key} ${altKey}`} dismiss={alert.showCloseButton} onClosed={() => { deleteAlert(alert) }}>
          {alert.description}
        </MDBAlert>
      );
    });

    return (
      <MDBContainer className="alert-bar-container translate-me" fluid>
        {bars}
      </MDBContainer>
    );
  }
}



AlertBar.propTypes = {
  SBAlert: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { SBAlert } = state;
  return {
    SBAlert,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(AlertBar);

