import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Line, defaults } from 'react-chartjs-2';

// API
import * as ELD from 'api/ELD';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';

class SpeedIdingGraph extends React.Component {

  constructor(props) {
    super(props);

    const cjsEventsDatasetTemplate = {
      // Our custom ChartJS dataset properties to be used for all datasets for line-drawing
      label: '',
      fill: false,
      lineTension: 0,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'bevel',
      steppedLine: true,
    };

    const cjsData = {
      // Amalgamation of our datasets and label settings to input to CJs Line Graph
      labels: [],
      datasets: [],
    };

    // Graph code array for Y-Axis
    const graphCodes = [undefined, undefined];

    const cjsOptions = {
      // Custom ChartJS options
      animation: false,
      responsive: true,
      maintainAspectRatio: false,
      bezierCurve: false,
      elements: {
        point: {
          pointStyle: 'circle',
          radius: 0, // turns the points off. default 3
          hitRadius: 0, // 1
          hoverRadius: 0, // 4
        },
      },
      scales: {
        xAxes: [{
          type: 'time',
          bounds: 'ticks',
          time: {
            displayFormats: {
              hour: 'X'
            },
            // min: '00:00:00',
            // max: '24:00:00',
            unit: 'hour',
            stepSize: 1,
            unitStepSize: 1,
            minUnit: 'hour',
          },
          ticks: {
            source: 'labels',
            fontColor: '#000000',
            fontSize: 12,
            fontFamily: 'Tahoma',
            fontStyle: 'bold'
          }
        }],

        yAxes: [
          {
            position: 'left',
            ticks: {
              min: 0,
              // max: 5 // if we're including pu-cmv and ym in the graph
              max: 2,
              fixedStepSize: 1,
              // Return an empty string to draw the tick line but hide the tick label
              // Return `null` or `undefined` to hide the tick line entirely
              userCallback(tick, index, ticks) {
                return graphCodes[tick];
              },
              fontColor: '#337AB7',
              fontSize: 10,
              fontFamily: 'Tahoma',
              fontStyle: 'bold',
            },
          },
        ],
      },
      legend: {
        display: false, // don't display chart legend labels
      },
    };

    this.state = {
      cjsEventsDatasetTemplate,
      cjsData,
      cjsOptions,
      graphCodes,
    };

    this.addGraphDatasets = this.addGraphDatasets.bind(this);
    this.refreshGraph = this.refreshGraph.bind(this);
  }

  componentDidMount() {
    // Extend graph functionality to allow single-multi-colored line which chartjs does not allow out of the box
    // This removes weird fnality associated with stacking charts on top of each other for different colors
    var originalLineDraw = Chart.controllers.line.prototype.draw;
    Chart.helpers.extend(Chart.controllers.line.prototype, {
      draw: function (ease) {

        // NOTE: This may break if more than 1 dataset
        originalLineDraw.apply(this, arguments);

        let startIndex = 0,
            meta = this.getMeta(), // metadata
            points = meta.data || [], // not sure what this is for
            area = this.chart.chartArea, // drawing area, consists of: { bottom: float, left: float, right: float, top: float }

            // the ChartElements corresponding to our data points of a Line chart, filtered for only those with valid Y
            chartElements = meta.dataset._children
              .filter(function(chartElement) {
                return !isNaN(chartElement._view.y);
              });

        // if theres no legit points, do nothing
        if (chartElements.length === 0) {
          return;
        }

        // function to set line color between two+ points
        function _setColor(newColor = 'green', meta) {
          meta.dataset._view.borderColor = newColor;
          meta.dataset._view.borderColor = newColor;
        }

        // get the cartesian data points we put in place
        const datasets = meta.controller.chart.config.data.datasets;

        // we take the last one in case we decide to do multiple datasets in which the last one will contain all coords
        // because the order goes from newest -> oldest datasets. this condition is applied across this component
        const graphData = datasets[datasets.length - 1].data || [];

        // now filter to make sure we get the same events which are actually drawable
        const graphDataFiltered = [];

        for (let i = 0; i < chartElements.length; i++) {
          const chartElementIndex = chartElements[i]._index;
          graphDataFiltered.push(graphData[chartElementIndex] || {});
        }

        /*
          now we have a 1-1 match of graphData and chartElements
          so we apply a loop to match the color that should be between 2 points
          based on the duty stauses we have from graphDataFiltered
        */
        let chartElementsLen = chartElements.length;
        let lastRecordedLineColor;
        let lastChangedColorIndex = chartElementsLen - 1;
        let lastRecordedGraphCoordType;

        while (chartElementsLen--) {
          const chartElement = chartElements[chartElementsLen];
          const graphCoord = graphDataFiltered[chartElementsLen];
          const graphCoordType = graphCoord.type;;

          // order matters to determine colors
          let lineColor = '#0DAAFF';
          if (graphCoordType === 1) { // violation
            lineColor = '#FF6666';
          } else if (graphCoordType === 2) { // idling

          } else if (graphCoordType === 3) { // driving
            lineColor = '#E4B74E';
          }

          if (graphCoordType !== lastRecordedGraphCoordType) {
            // special events type changed, so set the color appropriately from where it should start to where it should end
            _setColor(lineColor, meta);

            // slice chartElements to tell chartjs that these events are of this color
            // meta.dataset._children = chartElements.slice(0, chartElementsLen + 2);
            meta.dataset._children = chartElements.slice(0, chartElementsLen + 2);
            // if (graphCoord.isStart || graphCoord.isEnd) {
            //   // edits are sliced one less to show only the edited portion
            //   meta.dataset._children = chartElements.slice(0, chartElementsLen + 1);
            // }
            meta.dataset.draw();
            lastChangedColorIndex = chartElementsLen;
            lastRecordedLineColor = lineColor;
            lastRecordedGraphCoordType = graphCoordType;
          }
        }

        // remodify children to include all chart elements so they dont disappear
        meta.dataset._children = chartElements;

        points.forEach(function (point) {
          point.draw(area);
        });

      }
    });

    this.refreshGraph(this.props);
  }

  componentWillUnmount() {
    // Extend graph functionality to re-draw the original graph when this component dismounts because sometimes
    // colors from things like autoGeneratedDrivingTime stick around since object manipulations are in-place
    var originalLineDraw = Chart.controllers.line.prototype.draw;
    Chart.helpers.extend(Chart.controllers.line.prototype, {
      draw: function (ease) {
        originalLineDraw.apply(this, arguments);

        let startIndex = 0,
            meta = this.getMeta(),
            points = meta.data || [],
            area = this.chart.chartArea,

            chartElements = meta.dataset._children
              .filter(function(chartElement) {
                return !isNaN(chartElement._view.y);
              });

        // if theres no legit points, do nothing
        if (chartElements.length === 0) {
          return;
        }

        function _setColor(newColor = 'green', meta) {
          meta.dataset._view.borderColor = newColor;
          meta.dataset._view.borderColor = newColor;
        }

        const datasets = meta.controller.chart.config.data.datasets;

        const graphData = datasets[datasets.length - 1].data || [];
        const graphDataFiltered = [];

        for (let i = 0; i < chartElements.length; i++) {
          const chartElementIndex = chartElements[i]._index;
          graphDataFiltered.push(graphData[chartElementIndex] || {});
        }

        let chartElementsLen = chartElements.length;
        let lastRecordedLineColor;
        let lastChangedColorIndex = chartElementsLen - 1;
        let lastRecordedGraphCoordType;

        while (chartElementsLen--) {
          const chartElement = chartElements[chartElementsLen];
          const graphCoord = graphDataFiltered[chartElementsLen];
          const graphCoordType = graphCoord.type;

          // order matters to determine colors
          let lineColor = '#0DAAFF';
          if (graphCoord.type === 1) { // violation
            lineColor = '#FF6666';
          } else if (graphCoord.type === 2) { // idling
          } else if (graphCoord.type === 3) { // driving
            lineColor = '#E4B74E';
          }

          if (graphCoordType !== lastRecordedGraphCoordType) {
            // special events type changed, so set the color appropriately from where it should start to where it should end
            _setColor(lineColor, meta);

            // slice chartElements to tell chartjs that these events are of this color
            meta.dataset._children = chartElements.slice(0, chartElementsLen + 2);
            meta.dataset.draw();
            lastChangedColorIndex = chartElementsLen;
            lastRecordedLineColor = lineColor;
            lastRecordedGraphCoordType = graphCoordType;
          }
        }

        // remodify children to include all chart elements so they dont disappear
        meta.dataset._children = chartElements;

        points.forEach(function (point) {
          point.draw(area);
        });

      }
    });
  }

  componentWillReceiveProps(nextProps) {
    this.refreshGraph(nextProps);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.disableRedraw) {
      return false;
    }
    return true;
  }

  addGraphDatasets(speedIdleEvents, cjsDatasetsArray) {
    /*
      - now we have the correct, ordered, eldEvents for the 24hr graph span, convert them into cartesian data for plotting,
      apply them to cjsDatasetsArray in-place, and return the raw data
    */
    const { cjsEventsDatasetTemplate } = this.state;
    const { driver, scaleToDriverTimezone } = this.props;

    const timezoneOffsetFromUTC = (scaleToDriverTimezone && driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();
    const speedIdleGraphData = speedIdleEvents.map(datum => {
      const graphObject = {};
      graphObject.x = moment(datum.dateTime).tz(timezoneOffsetFromUTC);
      graphObject.y = 1;
      graphObject.type = datum.type;
      graphObject.isStart = datum.isStart;
      graphObject.isEnd = datum.isEnd;
      return graphObject;
    });

    const datasets = cjsDatasetsArray;
    datasets.unshift({
      ...cjsEventsDatasetTemplate,
      data: speedIdleGraphData
    }); // base data, all in one color

    return speedIdleGraphData;
  }

  refreshGraph(props) {

    const { speedIdleEvents, driver, scaleToDriverTimezone, createSpeedIdleObject } = props;
    const { cjsData, cjsOptions, graphCodes } = this.state;

    let _speedIdleEvents = [].concat(speedIdleEvents);

    const driverFirstName = driver ? driver.get('user').get('firstName') : 'Driver';

    // scale to drivers timezone
    const timezoneOffsetFromUTC = (scaleToDriverTimezone && driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();

    // get the raw start/end time utc
    const startTimeUTC = moment(this.props.startTimeUTC).tz(timezoneOffsetFromUTC);
    let endTimeUTC = moment(startTimeUTC).tz(timezoneOffsetFromUTC).add(1, 'day');
    const startTimeUTCArr = [startTimeUTC.date(), startTimeUTC.hours(), startTimeUTC.minutes(), startTimeUTC.seconds()];
    const endTimeUTCArr = [endTimeUTC.date(), endTimeUTC.hours(), endTimeUTC.minutes(), endTimeUTC.seconds()];

    // ...same as above but we adjust end time value for mobile start/end times
    const oneSecondOffEndTimeUTC = moment(endTimeUTC).tz(timezoneOffsetFromUTC).subtract(1, 'millisecond');
    const oneSecondOffEndTimeUTCArr = [oneSecondOffEndTimeUTC.date(), oneSecondOffEndTimeUTC.hours(), oneSecondOffEndTimeUTC.minutes(), oneSecondOffEndTimeUTC.seconds()];
    // --

    // filter out those that are after the endTimeUTC
    _speedIdleEvents = _speedIdleEvents.filter(speedIdleEvent => {
      return moment(speedIdleEvent.dateTime).valueOf() <= endTimeUTC.valueOf();
    });

    const chartTimeOffset = (startTimeUTC.minutes() * 60000);

    const promise = new Promise(resolve => {
      // guarantee the removal of previous datasets
      cjsData.datasets.splice(0);
      cjsData.labels.splice(0);

      for (let i = 0; i < 25; i++) {
        // iterate every hour from startTimeUTC + drivers timezone offset
        const newDate = moment(startTimeUTC);
        newDate.add(i, 'hour');
        cjsData.labels.push(newDate);
      }

      let speedIdleStartsAtStartTimeUTC, speedIdleEndsAtEndTimeUTC;
      const firstSpeedIdleEvent = _speedIdleEvents[0];
      const lastSpeedIdleEvent = _speedIdleEvents[_speedIdleEvents.length - 1];

      if (firstSpeedIdleEvent) {
        const firstSpeedIdleEventDateTime = moment(firstSpeedIdleEvent.dateTime).tz(timezoneOffsetFromUTC);
        const firstSpeedIdleEventDateTimeArr = [firstSpeedIdleEventDateTime.date(), firstSpeedIdleEventDateTime.hours(), firstSpeedIdleEventDateTime.minutes(), firstSpeedIdleEventDateTime.seconds()];
        if (Helpers.areArraysEqual(startTimeUTCArr, firstSpeedIdleEventDateTimeArr)) {
          speedIdleStartsAtStartTimeUTC = true;
        }
      }

      if (lastSpeedIdleEvent) {
        const lastSpeedIdleEventDateTime = moment(lastSpeedIdleEvent.dateTime).tz(timezoneOffsetFromUTC);
        const lastSpeedIdleEventDateTimeArr = [lastSpeedIdleEventDateTime.date(), lastSpeedIdleEventDateTime.hours(), lastSpeedIdleEventDateTime.minutes(), lastSpeedIdleEventDateTime.seconds()];
        if (Helpers.areArraysEqual(endTimeUTCArr, lastSpeedIdleEventDateTimeArr) || Helpers.areArraysEqual(oneSecondOffEndTimeUTCArr, lastSpeedIdleEventDateTimeArr)) {
          speedIdleEndsAtEndTimeUTC = true;
        }
      }

      if (!speedIdleStartsAtStartTimeUTC) {
        // place speedIdle event at start
        _speedIdleEvents.unshift(createSpeedIdleObject('dayStart', 0, startTimeUTC));
      }

      if (!speedIdleEndsAtEndTimeUTC) {
        // place speedIdle event at end
        _speedIdleEvents.push(createSpeedIdleObject('dayEnd', 0, undefined, endTimeUTC));
      }

      // get/convert the events to cartesian graph datasets, and apply them to the chart
      const speedIdleGraphData = this.addGraphDatasets(_speedIdleEvents, cjsData.datasets);

      cjsOptions.tooltips = {
        enabled: true,
        mode: 'x-axis',
        displayColors: false,
        callbacks: {
          title: function (chartElementDataset) {
            const chartElementIndex = chartElementDataset[chartElementDataset.length - 1].index;
            const associatedGraphCoord = speedIdleGraphData[chartElementIndex];
            const dateTime = moment(associatedGraphCoord.x).tz(timezoneOffsetFromUTC).add(chartTimeOffset, 'millisecond');
            const xToolTipLabel = dateTime.format('h:mm A  (dddd DD MMM, YYYY)');
            return xToolTipLabel;
          },
          label: function (chartElement, data) {
            const chartElementIndex = chartElement.index;
            const associatedGraphCoord = speedIdleGraphData[chartElementIndex];

            let yTooltipLabel = '';

            if (associatedGraphCoord.type) { // anything other than 0
              const statusLabels = [undefined, 'Violation', 'Idling', 'Driving'];
              let movementState = 'Start';
              if (associatedGraphCoord.isEnd) {
                movementState = 'End';
              }
              yTooltipLabel = `${driverFirstName} ${statusLabels[associatedGraphCoord.type]} ${movementState}`;
            }

            return yTooltipLabel;
          },
        },
      };

      // declaring the tick fn here will ensure the graph redraws the x-axis with our changes as well as use the correct startTimeUTC
      cjsOptions.scales.xAxes[0].ticks.callback = function (tick, index, ticks) {
        /*
          Each tick is a time value string (ex. '11:30 pm'); ticks is an array of moment objects of the selected date set to said time value
          A tick is what is shown on the xAxis

          if amount of ticks is 25, we know we're showing a full 24hr span. If its 26, we know the graph
          did some rounding (rounds to nearest whole hr:00) so we have to make sure the correct hour:minute is set
        */
       const tickSplit = moment.unix(tick)
        .tz(timezoneOffsetFromUTC)
        .format('h:mm a')
        .split(':')
        const hour = tickSplit[0];
        let minutes = tickSplit[1].substr(0, 2);
        const ampm = tickSplit[1].substr(-2);

        if (ticks.length === 26) {
          minutes = startTimeUTC.minutes();
          (minutes < 10) ? (minutes = `0${minutes}`) : minutes;

          // don't draw the last rounded tick which is indicated by ticks.length = 26 && index = 25
          if (index === 25) return;
        }

        return `${hour}:${minutes} ${ampm}`;
      };

      this.setState(this.state, () => { resolve(true); });

    });
    return promise;
  }


  render() {
    return (
      <div className={'subTable'} style={this.props.style}>
        <Line
          data={this.state.cjsData}
          width={120}
          height={100}
          options={this.state.cjsOptions}
          redraw
        />
      </div>
    );
  }
}

SpeedIdingGraph.propTypes = {
  speedIdleEvents: PropTypes.array.isRequired,
  createSpeedIdleObject: PropTypes.func.isRequired,
  driver: PropTypes.object, // driver is optional
  scaleToDriverTimezone: PropTypes.bool,
  startTimeUTC: PropTypes.instanceOf(Date),
  disableRedraw: PropTypes.bool,
  defaultLineColor: PropTypes.string,
  style: PropTypes.object,
};

export default SpeedIdingGraph;
