import React, { useState, useEffect } from 'react';

// CSAPI API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import JobStatusBadge from 'components/Dispatch/JobStatusBadge/JobStatusBadge';

// sbCore Components
import Skeleton from 'sbCore/Skeleton/Skeleton';
import Button from 'sbCore/Button/Button';

import './style.scss';

/**
 * @description Display a DispatchJob's information
 * @param {String} className - Custom container className
 * @param {DispatchJob} dispatchJob - DispatchJob that's information is displayed
 * @param {Function} onUnselectDispatchJob - Callback when remove button is clicked. Passes back DispatchJob if user wants it
 * @param {Boolean} isLoading - Whether or not to show loading skeleton or information card
 * @returns
 *
 * @example
 * <DispatchJobCard />
 */
function DispatchJobCard({ ...props }) {

  // ** useStates ** //
  const [dispatchJob, setDispatchJob] = useState(undefined);

  // ** useEffects ** //
  useEffect(() => {
    setDispatchJob(props.dispatchJob);
  }, [props.dispatchJob]);

  // ** Callbacks ** //
  function onUnselectDispatchJob() {
    if (props.onUnselectDispatchJob) {
      props.onUnselectDispatchJob(dispatchJob);
    }
    setDispatchJob(undefined);
  }

  // ** Dispatch Job Attributes ** //
  const batchId = getAttribute(dispatchJob, 'batchId', true);
  const status = getAttribute(dispatchJob, 'status', true);
  const referenceNumber = getAttribute(dispatchJob, 'referenceNumber', true);

  const customerDispatchOrganization = getAttribute(dispatchJob, 'customerDispatchOrganization', true);
  const customerDispatchOrganizationName = getAttribute(customerDispatchOrganization, 'organizationName', true);

  // ** Buttons ** //
  const unselectButton = (
    <Button
      icon="pi pi-trash"
      className="p-button-rounded p-button-danger"
      onClick={() => onUnselectDispatchJob()}
    />
  );

  // ** Card Text Information ** //
  const batchIdText = (batchId
    ? <div className="batch-id font-semibold">{batchId}</div>
    : <div className="batch-id font-semibold text-warning">No Job ID Provided</div>
  );

  const jobStatusBadge = ((status || status === 0)
    ? <JobStatusBadge status={status} />
    : <div className="job-status font-semibold text-warning">No Job Status Provided</div>
  );

  const customerDispatchOrganizationText = (customerDispatchOrganizationName
    ? <div className="customer font-semibold">{`Customer: ${customerDispatchOrganizationName} `}</div>
    : <div className="customer font-semibold text-warning">No Customer Provided</div>
  );

  const referenceNumberText = (referenceNumber
    ? <div className="reference-number font-semibold">{`Reference Number: ${referenceNumber}`}</div>
    : <div className="reference-number font-semibold text-warning">No Reference Number Provided</div>
  );

  // ** Loading Skeleton ** //
  const skeleton = (
    <div className="custom-skeleton">
      <div className="flex">
        <Skeleton shape="circle" size="2.625rem" className="mr-2" />
        <div>
          <Skeleton width="8rem" height=".6rem" className="mb-2" />
          <Skeleton width="5rem" height=".6rem" className="mb-2" />
          <Skeleton height=".4rem" />
        </div>
      </div>
    </div>
  );

  // ** classNames ** //
  let className = 'dispatch-job-card';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      {!props.isLoading && (
        <div className="job-card">
          {unselectButton}

          <div>
            {batchIdText}

            {jobStatusBadge}

            {customerDispatchOrganizationText}

            {referenceNumberText}
          </div>
        </div>
      )}

      {props.isLoading && skeleton}
    </div>
  );
}

export default DispatchJobCard;
