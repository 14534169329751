import Parse from 'parse';
import moment from 'moment-timezone';
import * as ActionConstants from './ActionConstants';
import store from '../store';

import * as Helpers from '../api/Helpers';
import * as Mapbox from '../api/Mapbox';
import * as Getters from '../api/Getters';

// import { isFlagOn } from 'sb-csapi/dist/api/Analytics/Flagging';
import { filterVehicleLocations } from 'sb-csapi/dist/api/VehicleLocation/VehicleLocation';

export function fetchingVehicleLocations() {
  return {
    type: ActionConstants.FETCH_VEHICLELOCATIONS_INPROGRESS,
  };
}

export function fetchVehicleLocationsSuccess(vehicleLocations) {
  return {
    type: ActionConstants.FETCH_VEHICLELOCATIONS_SUCCESS,
    vehicleLocations,
  };
}

export function fetchVehicleLocationsError() {
  return {
    type: ActionConstants.FETCH_VEHICLELOCATIONS_ERROR,
  };
}

export function deleteVehicleLocations() {
  return {
    type: ActionConstants.DELETE_VEHICLELOCATIONS,
  };
}

export function getVehicleLocationIncludeArr() {
  return ['drivers', 'drivers.user', 'vehicle', 'vehicle.licensePlate', 'jobEntity', 'jobAction', 'jobAction.jobActionType', 'eldEvent'];
}

export function getTCPositionsIncludeArr() {
  return [];
}

export function getAssetLocationIncludeArr() {
  return [];
}

export async function fetchVehicleLocationsForDateRange(vehicleParseObj, filter, showAllLocationsBool) {
  const promise = new Promise(async (resolve, reject) => {
    store.dispatch(fetchingVehicleLocations());
    const finalFilter = [].concat(filter,
      {
        name: 'vehicleUnitId',
        queryType: 'equalTo',
        value: vehicleParseObj.get('unitId'),
      },
      {
        name: 'belongsToCompany',
        queryType: 'equalTo',
        value: vehicleParseObj.get('belongsToCompany'),
      },
      {
        name: 'eldEvent_eldEventTypeCodeInt',
        queryType: 'notEqualTo',
        value: '31',
      }
    );

    // Old Filter using vehicleObj
    // finalFilter.push({
    //   name: 'location',
    //   queryType: 'notEqualTo',
    //   value: new Parse.GeoPoint({ latitude: 0, longitude: 0 }),
    // });
    try {
      let vehicleLocations = await Getters.queryCompanyObjects('VehicleLocation', undefined, undefined, finalFilter, { dateTime: 'ascending' }, getVehicleLocationIncludeArr(), false, true, undefined, true)
      if (vehicleLocations.length === 0) {
        const vehicleWithSameUnitIdFilter = [].concat(
          {
            name: 'unitId',
            queryType: 'equalTo',
            value: vehicleParseObj.get('unitId'),
          },
          {
            name: 'belongsToCompany',
            queryType: 'equalTo',
            value: vehicleParseObj.get('belongsToCompany'),
          },
        )
        const vehicleWithSameUnitId = await Getters.queryCompanyObjects('Vehicle', undefined, undefined, vehicleWithSameUnitIdFilter, undefined, undefined, false, true, undefined, true);
        const oldFilter = [].concat(filter,
          {
            name: 'vehicle',
            queryType: 'containedIn',
            value: vehicleWithSameUnitId,
          },
          {
            name: 'belongsToCompany',
            queryType: 'equalTo',
            value: vehicleParseObj.get('belongsToCompany'),
          },

        );
        vehicleLocations = await Getters.queryCompanyObjects('VehicleLocation', undefined, undefined, oldFilter, { dateTime: 'ascending' }, getVehicleLocationIncludeArr(), false, true, undefined, true);
      }

      const tmpLocations = vehicleLocations.filter((vl, index, arr) => {
        let shouldRemove = false;
        if (!showAllLocationsBool && index > 0 && index !== vehicleLocations.length - 1) {
          const previousVehicleLocation = arr[index - 1];
          const vlMoment = moment(vl.get('dateTime'));
          const previousVlMoment = moment(previousVehicleLocation.get('dateTime'));
          if (vlMoment.diff(previousVlMoment, 'minutes') < 9 && index % 2 !== 0) {
            // remove every odd vehicleLocation that is within 5 minutes of previous vehicleLocation
            shouldRemove = true;
          }
        }
        const loc = vl.get('location');
        if (
          loc.latitude === 0 &&
          loc.longitude === 0
        ) shouldRemove = true;
        return !shouldRemove;
      });

      // const isDetectVehicleLocationOutlierFlagOn = isFlagOn('flag-web-2427-detect-vehicle-location-outliers');
      const isDetectVehicleLocationOutlierFlagOn = true;
      const isSupportToolDomain = window.location.host.indexOf('app-support') !== -1;

      if (isDetectVehicleLocationOutlierFlagOn) {
        const filteredVehicleLocations = filterVehicleLocations(tmpLocations, { showDebug: isSupportToolDomain, debugLevel: 2 });
        store.dispatch(fetchVehicleLocationsSuccess(filteredVehicleLocations.vehicleLocations || []));
        resolve(filteredVehicleLocations.vehicleLocations);
      } else {
        store.dispatch(fetchVehicleLocationsSuccess(tmpLocations));
        resolve(tmpLocations);
      }
    } catch (error) {
      store.dispatch(fetchVehicleLocationsError(error));
      reject(error);
    }
  });
  return promise;
}

export function fetchTCPositionsVehicleLocationsForDateRange(trailerParseObj, filter) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingVehicleLocations());
    const tc_devices = trailerParseObj.get('tc_devices');
    if (!tc_devices) {
      resolve([]);
      fetchVehicleLocationsSuccess(vehicleLocations);
    } else {
      const tc_devices_uniqueid = tc_devices.get('id');

      const geolocationFilters = [
        {
          name: 'latitude',
          queryType: 'notEqualTo',
          value: 0,
        },
        {
          name: 'longitude',
          queryType: 'notEqualTo',
          value: 0,
        },
      ];

      const finalFilter = [].concat(
        filter,
        {
          name: 'deviceid',
          queryType: 'equalTo',
          value: tc_devices_uniqueid,
        },
        geolocationFilters
      );

      Getters.queryCompanyObjects('tc_positions', undefined, undefined, finalFilter, { devicetime: 'ascending' }, getTCPositionsIncludeArr(), false, true, undefined, true).then(
        tcPositions => {
          // simulate as vehicle locations

          // const includeEvery = 3;
          const filteredTrailerPositionsArr = [];
          for (let i = 0; i < tcPositions.length - 1; i++) {
            const tcPositionsObj = tcPositions[i];
            if (i === 0) filteredTrailerPositionsArr.push(tcPositionsObj);
            else {
              const prevTcPositionsObj = filteredTrailerPositionsArr[filteredTrailerPositionsArr.length - 1];
              if (
                Mapbox.distanceBetweenTwoGeopoints(
                  prevTcPositionsObj.get('latitude'),
                  prevTcPositionsObj.get('longitude'),
                  tcPositionsObj.get('latitude'),
                  tcPositionsObj.get('longitude')
                ) > 0.2) {
                filteredTrailerPositionsArr.push(tcPositionsObj);
              }
            }
          }

          filteredTrailerPositionsArr.push(tcPositions[tcPositions.length - 1]);
          // Keep points under 100 for mapbox direction query constraints
          // const cutLongLatLen = useDirections ? 25 : 100;
          const cutArrLen = 100; // right now use Directions, more reliable
          const cutArr = filteredTrailerPositionsArr.length > cutArrLen ?
            filteredTrailerPositionsArr.filter((longLatEntry, index) =>
              !(index % (Math.ceil(filteredTrailerPositionsArr.length / cutArrLen)))
            ) :
            filteredTrailerPositionsArr;
          if (cutArr[cutArr.length - 1] !== filteredTrailerPositionsArr[filteredTrailerPositionsArr.length - 1]) {
            cutArr[cutArr.length - 1] = filteredTrailerPositionsArr[filteredTrailerPositionsArr.length - 1];
          }
          const trailerPositions = cutArr.filter((obj) => obj !== undefined);
          const vehicleLocations = trailerPositions.map(tcPosition => {
            const vehicleLocation = Helpers.createTempParseObject('VehicleLocation', {
              ...tcPosition?.attributes,
              dateTime: moment(tcPosition.get('devicetime')).toDate(),
              speedKm: tcPosition.get('speed') * 1.852,
            });
            vehicleLocation.tc_positionId = tcPosition.id;
            return vehicleLocation;
          });

          resolve(vehicleLocations);
          store.dispatch(fetchVehicleLocationsSuccess(vehicleLocations));
        },
        error => {
          console.log(error);
          reject(error);
          store.dispatch(fetchVehicleLocationsError(error));
        }
      );
    }
  });
  return promise;
}

export function fetchAssetLocationsVehicleLocationsForDateRange(trailerParseObj, filter) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingVehicleLocations());
    const tc_devices = trailerParseObj.get('tc_devices');
    if (!tc_devices) {
      resolve([]);
      fetchVehicleLocationsSuccess([]);
    } else {
      const geolocationFilters = [
        {
          name: 'latitude',
          queryType: 'notEqualTo',
          value: 0,
        },
        {
          name: 'longitude',
          queryType: 'notEqualTo',
          value: 0,
        },
      ];

      const finalFilter = [].concat(
        filter,
        {
          queryType: 'equalTo',
          name: 'tc_devices',
          value: tc_devices,
        },
        geolocationFilters
      );

      Getters.queryCompanyObjects('AssetLocation', undefined, undefined, finalFilter, { devicetime: 'ascending' }, getAssetLocationIncludeArr(), false, true, undefined, true).then(
        assetLocationArr => {
          // simulate as vehicle locations
          // Filter out points that are close togetehr
          const filteredAssetLocationArr = [];
          for (let i = 0; i < assetLocationArr.length - 1; i++) {
            const assetLocationObj = assetLocationArr[i];
            if (i === 0) filteredAssetLocationArr.push(assetLocationObj);
            else {
              const prevAssetLocationObj = filteredAssetLocationArr[filteredAssetLocationArr.length - 1];
              if (
                Mapbox.distanceBetweenTwoGeopoints(
                  prevAssetLocationObj.get('location').latitude,
                  prevAssetLocationObj.get('location').longitude,
                  assetLocationObj.get('location').latitude,
                  assetLocationObj.get('location').longitude
                ) > 0.2) {
                filteredAssetLocationArr.push(assetLocationObj);
              }
            }
          }
          filteredAssetLocationArr.push(assetLocationArr[assetLocationArr.length - 1]);
          // Keep points under 100 for mapbox direction query constraints
          // const cutLongLatLen = useDirections ? 25 : 100;
          const cutArrLen = 100; // right now use Directions, more reliable
          const cutArr = filteredAssetLocationArr.length > cutArrLen ?
            filteredAssetLocationArr.filter((longLatEntry, index) =>
              !(index % (Math.ceil(filteredAssetLocationArr.length / cutArrLen)))
            ) :
            filteredAssetLocationArr;
          if (cutArr[cutArr.length - 1] !== filteredAssetLocationArr[filteredAssetLocationArr.length - 1]) {
            cutArr[cutArr.length - 1] = filteredAssetLocationArr[filteredAssetLocationArr.length - 1];
          }
          const trailerPositions = cutArr.filter((obj) => obj !== undefined);
          const vehicleLocations = trailerPositions.map(tcPosition => {
            const vehicleLocation = Helpers.createTempParseObject('VehicleLocation', {
              ...tcPosition?.attributes,
              dateTime: moment(tcPosition.get('devicetime')).toDate(),
              speedKm: tcPosition.get('speed') * 1.852,
            });
            vehicleLocation.tc_positionId = tcPosition.id;

            return vehicleLocation;
          });

          resolve(vehicleLocations);
          store.dispatch(fetchVehicleLocationsSuccess(vehicleLocations));
        },
        error => {
          console.log(error);
          reject(error);
          store.dispatch(fetchVehicleLocationsError(error));
        }
      );
    }
  });
  return promise;
}

export function deleteVehicleLocationsForState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteVehicleLocations());
    resolve(store.getState().VehicleLocation);
  });
  return promise;
}

export function vehicleLocationUpdateHandler(vehicleLocationJSON) {
  Getters.getObjectById('VehicleLocation', vehicleLocationJSON.objectId, getVehicleLocationIncludeArr()).then((vehicleLocationParseObj) => {
    store.dispatch(fetchVehicleLocationsSuccess([vehicleLocationParseObj]));
  });
}

export function addVehicleLocation(vehicleLocation) {
  store.dispatch(fetchVehicleLocationsSuccess([vehicleLocation]));
}
