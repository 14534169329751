import React from 'react';
import history from "../../sbHistory";
import moment from 'moment-timezone';
import { FontIcon } from 'material-ui';
import { Col, Row, Table } from 'react-bootstrap';

// API
import {
  getDriverHours,
  getUncertifiedLogDrivers,
  getELDMalfunctionDataCode,
  getDefectiveELDEvents,
  getTypeFromELDReferenceTable,
} from 'api/ELD';
import { toTitleCase } from 'api/Helpers';
import { queryCompanyObjects } from 'api/Getters';

// Components
import Title from 'components/LayoutTitle/view/Title';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './DriverELDIssuesLayout.module.scss';

class ELDIssuesLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uncertifiedLogDriverRows: [],
      fetchingUncertifiedLogs: true,
      defectiveELDEventRows: [],
      fetchingDefectiveELDEvents: true,
      allHoursDriversRows: [],
      outOfHoursDriversRows: [],
      nearOutOfHoursDriversRows: [],
      HOSViolationRows: [],
      fetchingHOSViolations: true,
      fetchingDriverHours: true,
      eldMalfunctionDataCodeTable: [], // reference table
    };
    this.refreshState = this.refreshState.bind(this);
    this.roundDriverTimeToHours = this.roundDriverTimeToHours.bind(this);
    this.updateDriverHours = this.updateDriverHours.bind(this);
    this.updateHOSViolations = this.updateHOSViolations.bind(this);
    this.updateUncertifiedLogDrivers = this.updateUncertifiedLogDrivers.bind(this);
    this.updateDefectiveELDEvents = this.updateDefectiveELDEvents.bind(this);
  }

  componentDidMount() {
    getELDMalfunctionDataCode().then(eldMalfunctionDataCodeTable => {
      this.setState({ ...this.state, eldMalfunctionDataCodeTable }, () => {
        this.refreshState();
      });
    });
  }

  refreshState(thing) {
    const newState = { ...this.state, fetchingDriverHours: true, fetchingUncertifiedLogs: true, fetchingDefectiveELDEvents: true, fetchingHOSViolations: true };
    this.setState(newState, () => {
      queryCompanyObjects('Driver', undefined, undefined, [{ name: 'eldViolation_violationsThisMonth', queryType: 'greaterThan', value: 0 }], { eldViolation_violationsThisWeek: 'descending' }, []).then(
        driverParseObjArr => {
          this.setState({ ...this.state, fetchingHOSViolations: false }, () => {
            this.updateHOSViolations(driverParseObjArr);
          });
        }
      );
      // getDriverHours().then(
      //   driverHoursObjects => {
      //     this.setState({ ...this.state, fetchingDriverHours: false }, () => {
      //       this.updateDriverHours(driverHoursObjects);
      //     });
      //   }
      // );
      getUncertifiedLogDrivers().then(
        uncertifiedLogObjects => {
          this.setState({ ...this.state, fetchingUncertifiedLogs: false }, () => {
            this.updateUncertifiedLogDrivers(uncertifiedLogObjects);
          });
        }
      );
      getDefectiveELDEvents().then(
        defectiveELDEvents => {
          this.setState({ ...this.state, fetchingDefectiveELDEvents: false }, () => {
            this.updateDefectiveELDEvents(defectiveELDEvents);
          });
        }
      );
    });
  }

  roundDriverTimeToHours(milliseconds) {
    // given a drivers remaining time in milliseconds, convert to hours and cut off decimal (2 places)
    const hours = milliseconds / 3600000;

    if (milliseconds <= 0) {
      return '0 hours';
    }
    if (milliseconds <= 3600) {
      const seconds = milliseconds / 1000;
      return `${seconds.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]} seconds`;
    }
    if (milliseconds < 3600000) {
      const minutes = milliseconds / 60000;
      return `${minutes.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]} minutes`;
    }

    return `${hours.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]} hours`;
  }

  updateDriverHours(driverHoursObjects) {
    const newState = { ...this.state };

    newState.allHoursDriversRows = driverHoursObjects.allHours.map(hoursObject => {
      const driver = hoursObject.driver;
      const driverUser = driver.get('user');
      const latestELDEvent = hoursObject.latestELDEvent;
      return (
        <tr key={`${driver.id}${latestELDEvent.id}`}>
          <td>
            {`${driverUser.get('firstName')} ${driverUser.get('lastName')}`}
          </td>
          <td>
            { latestELDEvent.get('drivingTimeRemaining') !== undefined && latestELDEvent.get('drivingTimeRemaining') >= 0 &&
              this.roundDriverTimeToHours(latestELDEvent.get('drivingTimeRemaining'))
            }
          </td>
          <td>
            { latestELDEvent.get('onDutyTimeRemaining') !== undefined && latestELDEvent.get('onDutyTimeRemaining') >= 0 &&
              this.roundDriverTimeToHours(latestELDEvent.get('onDutyTimeRemaining'))
            }
          </td>
          <td>{ moment(latestELDEvent.get('eventDateTime')).format('MMM D, YYYY @ HH:mm') }</td>
          <td>
            <button
              className="floatRight buttonDefault"
              onClick={() => history.push({ pathname: 'driver', search: "driver=" + driver.id + "&view=hosEvents" })}
            >
              Details
            </button>
          </td>
        </tr>
      );
    });

    newState.outOfHoursDriversRows = driverHoursObjects.outOfHours.map(oohObject => {
      const driver = oohObject.driver;
      const driverUser = driver.get('user');
      const latestELDEvent = oohObject.latestELDEvent;
      return (
        <tr key={`${driver.id}${latestELDEvent.id}`}>
          <td>
            {`${driverUser.get('firstName')} ${driverUser.get('lastName')}`}
          </td>
          <td>
            { latestELDEvent.get('drivingTimeRemaining') !== undefined && latestELDEvent.get('drivingTimeRemaining') >= 0 &&
              this.roundDriverTimeToHours(latestELDEvent.get('drivingTimeRemaining'))
            }
          </td>
          <td>
            { latestELDEvent.get('onDutyTimeRemaining') !== undefined && latestELDEvent.get('onDutyTimeRemaining') >= 0 &&
              this.roundDriverTimeToHours(latestELDEvent.get('onDutyTimeRemaining'))
            }
          </td>
          <td>
            <button
              className="floatRight buttonDefault"
              onClick={() => history.push({ pathname: 'driver', search: "driver="+ driver.id + "&view=hosEvents" })}
            >
              Details
            </button>
          </td>
        </tr>
      );
    });

    newState.nearOutOfHoursDriversRows = driverHoursObjects.nearOutOfHours.map(oohObject => {
      const driver = oohObject.driver;
      const driverUser = driver.get('user');
      const latestELDEvent = oohObject.latestELDEvent;

      return (
        <tr key={`${driver.id}${latestELDEvent.id}`}>
          <td>
            {`${driverUser.get('firstName')} ${driverUser.get('lastName')}`}
          </td>
          <td>
            { latestELDEvent.get('drivingTimeRemaining') !== undefined && latestELDEvent.get('drivingTimeRemaining') >= 0 &&
              this.roundDriverTimeToHours(latestELDEvent.get('drivingTimeRemaining'))
            }
          </td>
          <td>
            { latestELDEvent.get('onDutyTimeRemaining') !== undefined && latestELDEvent.get('onDutyTimeRemaining') >= 0 &&
              this.roundDriverTimeToHours(latestELDEvent.get('onDutyTimeRemaining'))
            }
          </td>
          <td>
            <button
              className="floatRight buttonDefault"
              onClick={() => history.push({ pathname: 'driver', search: "driver="+ driver.id + "&view=hosEvents" })}
            >
              Details
            </button>
          </td>
        </tr>
      );
    });
    this.setState(newState);
  }

  updateUncertifiedLogDrivers(uncertifiedLogObjects) {
    const newState = { ...this.state };

    newState.uncertifiedLogDriverRows = uncertifiedLogObjects.map(logObject => {
      const driver = logObject.driver;
      const driverUser = driver.get('user');
      const dailyCertification = logObject.eldDailyCertification;
      return (
        <tr key={`${driver.id}${dailyCertification.id}`}>
          <td>
            {`${driverUser.get('firstName')} ${driverUser.get('lastName')}`}
          </td>
          <td>{`${moment(new Date(dailyCertification.get('startTimeUTC'))).format('MMM D, YYYY @ HH:mm')}`}</td>
          <td>{`${moment(new Date(dailyCertification.get('endTimeUTC'))).format('MMM D, YYYY @ HH:mm')}`}</td>
          <td>
            <button
              className="floatRight buttonDefault"
              onClick={() => history.push({ pathname: 'driver', search: "driver="+ driver.id + "&view=hosEvents" })}
            >
              Details
            </button>
          </td>
        </tr>
      );
    });

    this.setState(newState);
  }

  updateDefectiveELDEvents(defectiveELDEvents) {
    const newState = { ...this.state };
    let key = -1;
    newState.defectiveELDEventRows = defectiveELDEvents.map(eldEvent => {
      const driver = eldEvent.get('driver');
      const driverUser = driver.get('user');
      const eldMalfunctionDataCodeType = getTypeFromELDReferenceTable(this.state.eldMalfunctionDataCodeTable, undefined, eldEvent.get('eldMalfunctionDataCodeCode'));
      const eldHardwareSerial = eldEvent.get('eldHardwareSerial');
      key++;
      return (
        <tr key={`${driver.id}${key}`}>
          <td>
            {`${driverUser.get('firstName')} ${driverUser.get('lastName')}`}
          </td>
          <td>{ eldHardwareSerial }</td>
          <td>{`${moment(new Date(eldEvent.get('eventDateTime'))).format('MMM D, YYYY @ HH:mm')}`}</td>
          <td>{ eldMalfunctionDataCodeType }</td>
          <td>
            <button
              className="floatRight buttonDefault"
              onClick={() => history.push({ pathname: 'driver', search: "driver="+ driver.id + "&view=hosEvents" })}
            >
              Details
            </button>
          </td>
        </tr>
      );
    });
    this.setState(newState);
  }

  updateHOSViolations(driverParseObjArr) {
    const newState = { ...this.state };
    let key = -1;
    newState.HOSViolationRows = driverParseObjArr.map(driverParseObj => {
      const name = toTitleCase(driverParseObj.get('user_fullName'));
      key++;
      return (
        <tr key={`${driverParseObj.id}${key}`}>
          <td>{ name }</td>
          <td>{ driverParseObj.get('eldViolation_violationsThisWeek') }</td>
          <td>{ driverParseObj.get('eldViolation_violationsThisMonth') }</td>
          <td>
            <button
              className="floatRight buttonDefault"
              onClick={() => history.push({ pathname: 'driver', search: "driver="+ driverParseObj.id + "&view=hosEvents" })}
            >
              Details
            </button>
          </td>
        </tr>
      );
    });
    this.setState(newState);
  }

  render() {
    return (
      <div className={styles.eldIssuesLayout}>
        <Title superClassName={styles.title} title="Summary">
          <button className="buttonDefault" onClick={() => this.refreshState(true)}> <FontIcon className="material-icons">refresh</FontIcon> Refresh Data</button>
        </Title>

        {
          // <Row>
          //   <Col md={12}>
          //     <div className="card">
          //       <div className="title">
          //         ALL DRIVER HOURS
          //         <div className="subTitle">
          //           Driver's Hours Remaining
          //         </div>
          //       </div>
          //       <div className={`body defaultTable ${styles.tableMaxHeight}`}>
          //         <Table responsive hover>
          //           <thead>
          //             <tr>
          //               <th width="25%">Driver</th>
          //               <th width="18%">Driving</th>
          //               <th width="18%">On Duty</th>
          //               <th width="23%">Recorded (Local Time)</th>
          //               <th width="16%" />
          //             </tr>
          //           </thead>
          //           <tbody>
          //             {this.state.fetchingDriverHours &&
          //               <tr><td className="loadingIconPadding" colSpan="5"><LoadingIcon /></td></tr>
          //             }
          //             {!this.state.fetchingDriverHours &&
          //               this.state.allHoursDriversRows
          //             }
          //             {!this.state.fetchingDriverHours && this.state.nearOutOfHoursDriversRows.length === 0 &&
          //               <tr>
          //                 <td className="centerText" colSpan="5">
          //                   No Drivers Currently In Work Cycle
          //                 </td>
          //               </tr>
          //             }
          //           </tbody>
          //         </Table>
          //       </div>
          //     </div>
          //   </Col>
          // </Row>

          // <Row className={styles.row}>
          //   <Col md={6}>
          //     <div className="card">
          //       <div className="title">
          //         NEAR OUT OF HOURS
          //         <div className="subTitle">
          //           Drivers close to no hours remaining. Remaining hours are shown
          //         </div>
          //       </div>
          //       <div className={`body defaultTable ${styles.tableMaxHeight}`}>
          //         <Table responsive hover>
          //           <thead>
          //             <tr>
          //               <th width="30%">Driver</th>
          //               <th width="25%">Driving</th>
          //               <th width="25%">On Duty</th>
          //               <th width="20%" />
          //             </tr>
          //           </thead>
          //           <tbody>
          //             {this.state.fetchingDriverHours &&
          //               <tr><td className="loadingIconPadding" colSpan="5"><LoadingIcon /></td></tr>
          //             }
          //             {!this.state.fetchingDriverHours &&
          //               this.state.nearOutOfHoursDriversRows
          //             }
          //             {!this.state.fetchingDriverHours && this.state.nearOutOfHoursDriversRows.length === 0 &&
          //               <tr>
          //                 <td className="centerText" colSpan="5">
          //                   No Drivers Nearing Out of Hours
          //                 </td>
          //               </tr>
          //             }
          //           </tbody>
          //         </Table>
          //       </div>
          //     </div>
          //   </Col>

          //   <Col md={6}>
          //     <div className="card">
          //       <div className="title">
          //         OUT OF HOURS
          //         <div className="subTitle">
          //           Drivers who are out of hours. Remaining hours are shown
          //         </div>
          //       </div>
          //       <div className={`body defaultTable ${styles.tableMaxHeight}`}>
          //         <Table responsive hover>
          //           <thead>
          //             <tr>
          //               <th width="30%">Driver</th>
          //               <th width="25%">Driving</th>
          //               <th width="25%">On Duty</th>
          //               <th width="20%" />
          //             </tr>
          //           </thead>
          //           <tbody>
          //             {this.state.fetchingDriverHours &&
          //               <tr><td className="loadingIconPadding" colSpan="5"><LoadingIcon /></td></tr>
          //             }
          //             {!this.state.fetchingDriverHours &&
          //               this.state.outOfHoursDriversRows
          //             }
          //             {!this.state.fetchingDriverHours && this.state.outOfHoursDriversRows.length === 0 &&
          //               <tr>
          //                 <td className="centerText" colSpan="5">
          //                   No Drivers Out of Hours
          //                 </td>
          //               </tr>
          //             }
          //           </tbody>
          //         </Table>
          //       </div>
          //     </div>
          //   </Col>
          // </Row>
        }

        <Row className={styles.row}>
          <Col md={12}>
            <div className="card">
              <div className="title">
                UNSIGNED LOGS
                <div className="subTitle">
                  Drivers that haven't signed off their logs yet
                </div>
              </div>
              <div className={`body defaultTable ${styles.tableMaxHeight}`}>
                <Table responsive hover>
                  <thead>
                    <tr>
                      <th width="25%">Driver</th>
                      <th width="30%">Start Time</th>
                      <th width="30%">End Time</th>
                      <th width="15%" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.fetchingUncertifiedLogs &&
                      <tr><td className="loadingIconPadding" colSpan="4"><LoadingIcon /></td></tr>
                    }
                    {!this.state.fetchingUncertifiedLogs &&
                      this.state.uncertifiedLogDriverRows
                    }
                    {!this.state.fetchingUncertifiedLogs && this.state.uncertifiedLogDriverRows.length === 0 &&
                      <tr>
                        <td className="centerText" colSpan="4">
                          No Unsigned Logs
                        </td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
          {
            // <Col md={6}>
            //   <div className="card">
            //     <div className="title">
            //       HOS VIOLATIONS
            //       <div className="subTitle">
            //         HOS Violations Per Driver
            //       </div>
            //     </div>
            //     <div className={`body defaultTable ${styles.tableMaxHeight}`}>
            //       <Table responsive hover>
            //         <thead>
            //           <tr>
            //             <th width="35%">Driver</th>
            //             <th width="25%">This Week</th>
            //             <th width="25%">This Month</th>
            //             <th width="15%" />
            //           </tr>
            //         </thead>
            //         <tbody>
            //           { this.state.fetchingHOSViolations &&
            //             <tr><td className="loadingIconPadding" colSpan="4"><LoadingIcon /></td></tr>
            //           }
            //           { !this.state.fetchingHOSViolations &&
            //             this.state.HOSViolationRows
            //           }
            //           { !this.state.fetchingHOSViolations && this.state.HOSViolationRows.length === 0 &&
            //             <tr>
            //               <td className="centerText" colSpan="4">
            //                 No Drivers With HOS Violations this month
            //               </td>
            //             </tr>
            //           }
            //         </tbody>
            //       </Table>
            //     </div>
            //   </div>
            // </Col>
          }
        </Row>

        <Row className={styles.row}>
          { this.state.defectiveELDEventRows.length > 0 &&
            <Col md={6}>
              <div className="card">
                <div className="title">
                  DEFECTIVE ELDS
                  <div className="subTitle">
                    Drivers with a defective ELD
                  </div>
                </div>
                <div className={`body defaultTable ${styles.tableMaxHeight}`}>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th width="25%">Driver</th>
                        <th width="15%">Serial #</th>
                        <th width="25%">Date/Time</th>
                        <th width="25%">Description</th>
                        <th width="10%" />
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.fetchingDefectiveELDEvents &&
                        <tr><td className="loadingIconPadding" colSpan="4"><LoadingIcon /></td></tr>
                      }
                      {!this.state.fetchingDefectiveELDEvents &&
                       this.state.defectiveELDEventRows
                      }
                      {!this.state.fetchingDefectiveELDEvents && this.state.defectiveELDEventRows.length === 0 &&
                        <tr>
                          <td className="centerText" colSpan="5">
                            No Defective ELDs
                          </td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          }
        </Row>
      </div>
    );
  }
}

export default ELDIssuesLayout;
