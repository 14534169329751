/* eslint-disable react/jsx-no-bind */
import uniqid from 'uniqid';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

// API
import { getAttribute, createQuery, setQueryRestriction, findRecords, sortQuery, getCurrentUserSessionToken } from 'sb-csapi/dist/AAPI';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

// Components
import DutyStatusBadge from 'sbCore/DutyStatusBadge/DutyStatusBadge';
import { FontIcon } from 'material-ui';
import Card from 'sbCore/Card/Card';
import Column from 'sbCore/Column/Column';
import DataTable from 'sbCore/DataTable/DataTable';
import Chip from 'sbCore/Chip/Chip';

import styles from './TroubleshootingCards.module.scss';

function StuckOdometerDriversCard() {
  const [isLoading, setIsLoading] = useState(true);
  const [informationArr, setInformationArr] = useState([]);

  const [refreshBool, setRefreshBool] = useState(false);

  useEffect(() => {
    async function getArrForState() {
      setIsLoading(true);
      const driversArr = (await Getters.getDrivers({ enabled: true })).filter((driverObj) => (
        moment(getAttribute(driverObj, 'latestELDEvent_eventDateTime')).isAfter(moment().subtract(1, 'days'))
      ));

      const _driverInformationArr = driversArr
        ?.map((driverObj) => {
          const latestELDEvent = getAttribute(driverObj, 'latestELDEvent', true);
          const vehicleLocation = latestELDEvent && getAttribute(latestELDEvent, 'vehicleLocation', true);
          const lastKnownLocation = `${vehicleLocation?.get('aprxShortName')}, ${vehicleLocation?.get('stateProvince')?.toUpperCase()}`;
          const locationDateTime = vehicleLocation?.get('dateTime') || latestELDEvent?.get('eventDateTime');
          const eventDateTime = getAttribute(latestELDEvent, 'eventDateTime', true);
          const eldLastSyncValues = getAttribute(latestELDEvent, 'eldLastSyncValues', true);
          let eldLastSyncDateTime = getAttribute(latestELDEvent, 'eldLastSyncDateTime', true);

          return {
            name: getAttribute(driverObj, 'user_fullName', true),
            phoneNumber: getAttribute(driverObj, 'user_phoneNumber', true),
            eldStatusInt: getAttribute(driverObj, 'eldStatusInt', true),
            lastKnownLocation,
            locationDateTime, // not using location because some don't have vehicleLocations
            eventDateTime,
            eldHardwareSerial: latestELDEvent && getAttribute(latestELDEvent, 'eldHardwareSerial', true),
            eldLastSyncDateTime,
            eldLastSyncValues,
            currentELDVersion: getAttribute(driverObj, 'currentELDVersion', true),
            driverId: getAttribute(driverObj, 'objectId', true),
          };
        });

      for (let i = 0; i < _driverInformationArr.length; i++) {
        const driverInformationObj = _driverInformationArr[i];
        if (!driverInformationObj.eldLastSyncValues) {
          const query = createQuery('ELDEvent');
          sortQuery(query, QuerySortOrderTypes.DESCENDING, 'eventDateTime');
          setQueryRestriction(query, QueryRestrictionTypes.EQUAL_TO, 'driver', driverInformationObj.driverId);
          setQueryRestriction(query, QueryRestrictionTypes.EXISTS, 'eldLastSyncValues');

          const eldEvent = (await findRecords({ sessionToken: getCurrentUserSessionToken() }, query, true));
          driverInformationObj.eldLastSyncDateTime = getAttribute(eldEvent, 'eldLastSyncDateTime', true);
          driverInformationObj.eldLastSyncValues = getAttribute(eldEvent, 'eldLastSyncValues', true);
        }


        const eldLastSyncValues = driverInformationObj.eldLastSyncValues;
        if (eldLastSyncValues) {
          const lastValidOdoTimeIndex = eldLastSyncValues.indexOf('lastValidOdoTime: ');
          const mIsLiveIndex = eldLastSyncValues.indexOf('mIsLive: ');

          const lastValidOdoTime =
            mIsLiveIndex !== -1 ?
              eldLastSyncValues.substring(lastValidOdoTimeIndex + 18, mIsLiveIndex - 1)
              :
              eldLastSyncValues.substring(lastValidOdoTimeIndex + 18);
          const mIsLive = eldLastSyncValues.charAt(mIsLiveIndex + 9) === 't';

          // console.log(moment(lastValidOdoTime, 'YYYY MMM DD, ddd LTS').toDate());
          // console.log(driverInformationObj.name, eldLastSyncValues, eldLastSyncValues.charAt(mIsLiveIndex + 9), lastValidOdoTime);
          driverInformationObj.lastValidOdoTime = lastValidOdoTime;
          driverInformationObj.lastValidOdoTimeMoment = moment(lastValidOdoTime, 'YYYY MMM DD, ddd LTS').isValid() ? moment(lastValidOdoTime, 'YYYY MMM DD, ddd LTS') : undefined;
          driverInformationObj.mIsLive = mIsLive;
          driverInformationObj.hasValidMIsLive = eldLastSyncValues.charAt(mIsLiveIndex + 9) === 't' || eldLastSyncValues.charAt(mIsLiveIndex + 9) === 'f';
        }
      }


      const filteredDriverInformationArr = _driverInformationArr.filter((driverInformationObj) => {
        return (
          driverInformationObj.hasValidMIsLive
          && !driverInformationObj.mIsLive
          && moment(driverInformationObj.eventDateTime).isAfter(moment().subtract(1, 'hours'))
        )
      });
      setInformationArr(filteredDriverInformationArr);
      setIsLoading(false);
    }

    getArrForState();

    return () => {
      setRefreshBool(true);
    };
  }, [refreshBool]);

  function driverBodyTemplate(rowData) {
    return (
      <div>
        <a
          href={`/driver?driver=${rowData.driverId}&view=hosEvents`}
          target="_blank"
        >
          <b>{Helpers.toTitleCase(rowData.name)}</b>
        </a>
        <br />
        <div className={styles.subtitle}>
          <FontIcon className={`${styles.subtitle} material-icons`}>phone</FontIcon>
          {rowData.phoneNumber}
        </div>
      </div>
    );
  }

  function driverInfoTemplate(rowData) {
    return (
      <div className="inline">
        {/* {rowData.lastKnownLocation} */}
        <div className="inlineBlock">
          <DutyStatusBadge className="z-depth-0" eldStatusInt={rowData.eldStatusInt} />
        </div>
        <div className="inlineBlock">
          &nbsp;<b>Latest Ping @ {moment(rowData.locationDateTime).format('h:mm a')}</b>
        </div>
      </div>
    );
  }



  const infoTemplate = (rowData) => (
    <div>
      <table>
        <tr>
          <td><b>Latest ELD Event:</b> &nbsp; &nbsp;</td>
          <td>{moment(rowData.eventDateTime).format('lll')}</td>
        </tr>
        <tr>
          <td><b>Latest ELD Odometer (UTC):</b> &nbsp; &nbsp;</td>
          <td>{rowData.lastValidOdoTimeMoment ? moment(rowData.lastValidOdoTimeMoment).format('lll') : rowData.lastValidOdoTime}</td>
        </tr>
      </table>
    </div>

  );


  const header = (
    <div className="flex flex-row justify-content-between align-items-end px-3 pt-4">
      <h6 className="font-bold mb-0">Drivers with Stuck Odometers</h6>
    </div>
  );


  return (
    <Card
      header={header}
    >
      <DataTable
        value={informationArr}
        emptyMessage="No drivers with stuck odometers. Awesome!"
        loading={isLoading}
        rowHover
        paginator
        rows={5}
        size="small"
      >
        <Column
          field={driverBodyTemplate}
          header="Driver"
        />
        <Column
          field={driverInfoTemplate}
          header=""
        />
        <Column
          field={infoTemplate}
          header=""
        />
      </DataTable>
    </Card>
  );
}

export default StuckOdometerDriversCard;
