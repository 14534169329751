import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Months } from 'api/Lists/Months';

// Components
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';


class YearMonthPicker extends React.Component {

  constructor(props) {
    super(props);
    const currentDate = new Date();
    this.state = {
      currentDate,
      month: Months[this.props.selectedMonth] || Months[currentDate.getMonth()],
      year: this.props.selectedYear || currentDate.getFullYear(),
    }

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (attribute, value) => {
    const newState = { ...this.state };
    newState[attribute] = value;
    this.setState(newState, () => {
      const chosenYearMonth = new Date(`${this.state.month} 01 ${this.state.year} 00:00`);
      const lastDateOfYearMonth = moment(chosenYearMonth).endOf('month');
      this.props.handleYearMonthSelect(lastDateOfYearMonth.toDate());
    });
  }

  render() {

    const { yearsCountdown } = this.props;
    const { currentDate, month, year } = this.state;

    const menuItemMonths = Months.map((month, index) => {
      return <MenuItem key={month} value={month} primaryText={month} />;
    });

    const menuItemYears = [];
    const yearsCount = yearsCountdown || 5;
    for (let i = 0; i < yearsCount; i++) {
      const currentYear = currentDate.getFullYear() - i;
      menuItemYears.push(
        <MenuItem key={currentYear} value={currentYear} primaryText={currentYear} />
      );
    }

    return (
      <div style={{ position: 'relative' }}>
        <DropDownMenu
          value={month}
          onChange={(e, i, val) => this.handleChange('month', val)}
          style={{ width: '10em', marginLeft: '-1.5em' }}
          autoWidth={false}
        >
          { menuItemMonths }
        </DropDownMenu>

        <DropDownMenu
          value={year}
          onChange={(e, i, val) => this.handleChange('year', val)}
          style={{ width: '8em', marginLeft: '-3.2em' }}
          autoWidth={false}
        >
          { menuItemYears }
        </DropDownMenu>
      </div>
    );
  }
}

YearMonthPicker.propTypes = {
  yearsCountdown: PropTypes.number,
  selectedMonth: PropTypes.number,
  selectedYear: PropTypes.number,
  handleYearMonthSelect: PropTypes.func.isRequired,
};

export default YearMonthPicker;
