import React from 'react';

import { ToggleButton as PRToggleButton } from 'primereact/togglebutton';

import './style.scss';

/**
 * @description PrimeReact Toggle Button component
 * @param {String} className - Custom className
 * @returns
 */
function ToggleButton({ ...props }) {
  let className = 'sbc-toggle-button';
  if (props.className) className += ` ${props.className}`;

  return (<PRToggleButton {...props} className={className} />);
}

export default ToggleButton;