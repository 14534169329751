import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment-timezone';

// components
// import DateRangeSelector from 'sbCore/DateRangeSelector/DateRangeSelector';
import DataTable from 'sbCore/DataTable/DataTable';
import Row from 'sbCore/Row/Row';
import Column from 'sbCore/Column/Column';
import ColumnGroup from 'sbCore/ColumnGroup/ColumnGroup';
import DistanceUnitDropdown from 'sbCore/DistanceUnitDropdown/DistanceUnitDropdown';
import ProgressSpinner from 'sbCore/ProgressSpinner/ProgressSpinner';
import InputSwitch from 'sbCore/InputSwitch/InputSwitch';
import Button from 'sbCore/Button/Button';

// legacy api
// import { getCurrentDispatcher } from 'api/Getters';
// import { getELDDailyCertificationIntervalFromDriverTZ } from 'api/ELD';
import { createCsvFile } from 'api/Helpers';
import { getIFTARoutesForVehicle } from 'api/IFTA';
// iftaRouteArr = (await IFTA.getIFTARoutesForVehicle(unitId, dateStart, dateEnd, queryNewIfta)).filter((iftaRouteObj) => getAttribute(iftaRouteObj, 'vehicleLocationStart'));

// sb-csapi
// enums
import { Canada, United_States } from 'sb-csapi/dist/enums/StateProvince';
import { LengthUnit, LengthConversion } from 'sb-csapi/dist/enums/Unit';

// style
import './style.scss';

/**
 * @description The state/province data for each Vehicle, by date. Can be used as a standalone component
 * @param {Object} vehicleIFTAData - The selected vehicle's IFTA data
 * @param {Array} dateInterval - Date range supplied
 * @param {String} [vehicleUnitId] - The vehicle which we want to retrieve routes for, if no parent data provided (vehicleIFTAData)
 * @param {String} [className]
 * @param {String} [scrollHeight] - Scroll height of the table
 * @param {String} [lengthUnit] - From enums LengthUnit - KM, MI
 * @param {Boolean} [hideEmptyStateProvinces] - Whether to hide empty state/provinces
 * @param {Function} [onSelectDay] - Give selected date to parent for the day view
 * @param {Function} [onBack] - What to do when Back button is clicked
 * @returns
 */
function IFTAVehicleIntervalDataTable({ ...props }) {
  const defaultLengthUnit = LengthUnit.KM;

  // Refs
  const csvDataString = useRef(''); // total data for CSV export of table data
  const csvDataFooterString = useRef(''); // will be appended to csvDataString. it's a seperate string since footer info renders at a different time

  // Note users prefer all jurisdictions to be combined and sorted
  const CountryData = { ...Canada, ...United_States };
  const stateProvinceAbbrvs = Object.keys(CountryData).sort();

  const [vehicleIFTAData, setVehicleIFTAData] = useState(props.vehicleIFTAData || null);
  const [dayMap, setDayMap] = useState({}); // derived from vehicleIFTAData. routes by day

  // Loading indicators
  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false); // if all init data is fetched

  // Toggles
  const [dateInterval, setDateInterval] = useState(props.dateInterval);
  const [lengthUnit, setLengthUnit] = useState(props.lengthUnit || defaultLengthUnit);
  const [hideEmptyStateProvinces, setHideEmptyStateProvinces] = useState((props.hideEmptyStateProvinces !== null) ? props.hideEmptyStateProvinces : true);

  // JSX
  const [tableColumns, setTableColumns] = useState([]); // table columns
  const [footerGroup, setFooterGroup] = useState([]); // footer group

  useEffect(() => {
    let didCancel = false;
    async function init() {
      setIsLoading(true);

      let _vehicleIFTAData = {};

      if (props.vehicleIFTAData) {
        _vehicleIFTAData = props.vehicleIFTAData;
      } else {
        // @todo - If we want this component to be standalone, we need to implement logic to retrieve IFTA data and conform to
        // the structure of props.vehicleIFTAData
        _vehicleIFTAData = await getIFTARoutesForVehicle(props.vehicleUnitId, dateInterval[0], dateInterval[1], true);
      }

      if (!didCancel) {
        setVehicleIFTAData(_vehicleIFTAData);
        setDayMap(_vehicleIFTAData.dayMap);
        setIsFetched(true);
        setIsLoading(false);
      }
    }

    init();

    return () => { didCancel = true; };
  }, [props.vehicleIFTAData, props.vehicleUnitId, dateInterval]);

  useEffect(() => {
    if (props.onUpdateSettings) props.onUpdateSettings(dateInterval, lengthUnit, hideEmptyStateProvinces);
  }, [dateInterval, lengthUnit, hideEmptyStateProvinces]);

  useEffect(() => {
    generateTableColumns();
  }, [dayMap]);

  useEffect(() => {
    generateTableColumns();
    generateFooterGroup();
  }, [hideEmptyStateProvinces, lengthUnit]);

  /**
   * Functions
   */
  // Checks if a CSV row is at max length, to prevent additions to the row
  // We do this check to avoid re-renderings from appending to the csv string
  function isValidCSVRowLength(isFooter) {
    const csvDataStringSplit = csvDataString.current.split('\n');
    const csvDataStringFirstRowSplit = csvDataStringSplit[0]?.split(',');
    const csvDataStringLastRowSplit = csvDataStringSplit[csvDataStringSplit.length - 1]?.split(',');

    // if (isFooter) {
    //   const csvDataFooterStringSplit = csvDataFooterString.current.split(',');
    //   return csvDataStringFirstRowSplit && (csvDataFooterStringSplit.length < csvDataStringFirstRowSplit.length);
    // }
    return csvDataStringFirstRowSplit && csvDataStringLastRowSplit && (csvDataStringLastRowSplit.length < csvDataStringFirstRowSplit.length);
  }

  /**
   * Template/JSX Functions
   */
  function detailsButtonTemplate(rowData) {
    const shouldDisable = !isFetched;
    return (
      <Button
        label="DETAILS"
        onClick={
          () => props.onSelectDay && props.onSelectDay(rowData)
        }
        severity="primary"
        sbVariant="short"
        disabled={shouldDisable}
        tooltip={!shouldDisable ? `Click to view more IFTA data on ${rowData.dateStart}` : 'Loading...'}
        tooltipOptions={{ position: 'top', showOnDisabled: shouldDisable }}
      />
    );
  }

  function dateTemplate(rowData) {
    // csv append
    if (!csvDataString.current.includes(rowData.dateStart)) {
      csvDataString.current += `\n${rowData.dateStart}`;
    }

    return (
      <div className="font-bold">{ rowData.dateStart }</div>
    );
  }

  function totalsBodyTemplate(rowData, stateProvinceAbbrv) {
    let total = rowData.totalsMap[stateProvinceAbbrv] || 0;

    // See: IFTAVehicleJurisdictionDataTable: generateVehicleJurisdictionMap() for conversion parody
    // Also: stateProvinceTotalsBodyTemplate(), dayTotalsBodyTemplate()
    if (vehicleIFTAData.lengthUnit !== lengthUnit) {
      if (lengthUnit === LengthUnit.MI) {
        total *= LengthConversion.KM2MI;
      } else {
        total *= LengthConversion.MI2KM;
      }

      total = Math.floor(total);
    }

    if (total === 0) total = '-';

    // csv append
    if (isValidCSVRowLength()) {
      csvDataString.current += `,${total}`;
    }

    return <div>{total}</div>;
  }

  function getStateProvinceTotalsBodyObject(rowData, stateProvinceAbbrv) {
    // To calculate the sum of totals for each row and totals of each column (WYSIWYG).
    // We calculate with the data we have instead of relying on vehicleIFTAData.totalsMap
    // Using vehicleIFTAData.totalsMap and converting KM<->MI will introduce
    // rounding errors (the totals wont add to the same rounded summed totals)
    let total = 0;
    let stateProvinceTotalsBodyObject = {
      template: undefined,
      total: undefined,
    };

    const dayObjects = Object.values(rowData.dayMap);

    for (let i = 0; i < dayObjects.length; i++) {
      const dayObject = dayObjects[i];
      const totalsMap = dayObject.totalsMap;
      let dayTotal = totalsMap[stateProvinceAbbrv] || 0; // total for this day for this stateProvince

      // Keep conversion in parody with totalsBodyTemplate()
      if (vehicleIFTAData.lengthUnit !== lengthUnit) {
        if (lengthUnit === LengthUnit.MI) {
          dayTotal *= LengthConversion.KM2MI;
        } else {
          dayTotal *= LengthConversion.MI2KM;
        }

        dayTotal = Math.floor(dayTotal);
      }

      total += dayTotal;
    }

    stateProvinceTotalsBodyObject.total = total;
    if (total === 0) total = '-';

    stateProvinceTotalsBodyObject.template = (<div>{total}</div>);
    // stateProvinceTotalsBodyObject.template = (() => {
    //   // csv append
    //   csvDataFooterString.current += `,${total}`;

    //   return (
    //     <div>{total}</div>
    //   );
    // })();

    return stateProvinceTotalsBodyObject;
  }

  function dayTotalsBodyTemplate(rowData) {
    let total = 0;

    const dailyTotalsArr = Object.values(rowData.totalsMap);

    for (let i = 0; i < dailyTotalsArr.length; i++) {
      let dailyTotal = dailyTotalsArr[i];

      // Keep conversion in parody with totalsBodyTemplate()
      if (vehicleIFTAData.lengthUnit !== lengthUnit) {
        if (lengthUnit === LengthUnit.MI) {
          dailyTotal *= LengthConversion.KM2MI;
        } else {
          dailyTotal *= LengthConversion.MI2KM;
        }

        dailyTotal = Math.floor(dailyTotal);
      }

      total += dailyTotal;
    }

    if (total === 0) total = '-';

    // csv append
    if (isValidCSVRowLength()) {
      csvDataString.current += `,${total}`;
    }

    return <div className="font-bold">{total}</div>;
  }

  function overallTotalsBodyTemplate(total) {
    if (total === 0) total = '-';

    // csv append overall totals
    if (!csvDataFooterString.current.includes('Overall Totals')) { // prevent duplicate appends
      csvDataFooterString.current = `\nOverall Totals,${total}${csvDataFooterString.current}`;
    }

    return <div style={{ textDecoration: 'underline', textDecorationStyle: 'double' }}>{total}</div>;
  }

  function generateTableColumns() {
    const _tableColumns = [];

    // csv init
    csvDataString.current = 'Trip Date,Totals';

    // First, the row for select button and for Unit Ids
    _tableColumns.push(
      <Column
        key="selector"
        selectionMode="single"
        headerStyle={{ maxWidth: '50px' }}
        frozen
      />,
      <Column
        key="detailsButton"
        field="null"
        header=""
        body={(rowData) => detailsButtonTemplate(rowData)}
        frozen
      />,
      <Column
        key="dateStart"
        field="null"
        header="Trip Date"
        body={(rowData) => dateTemplate(rowData)}
        headerStyle={{ minWidth: '130px' }}
        frozen
      />,
      <Column
        key="totalsColumn"
        field="null"
        header="Totals"
        headerStyle={{ minWidth: '130px' }}
        body={(rowData) => dayTotalsBodyTemplate(rowData)}
        frozen
      />,
    );

    // Now, the columns for each jurisdiction
    for (let i = 0; i < stateProvinceAbbrvs.length; i++) {
      const stateProvinceAbbrv = stateProvinceAbbrvs[i];
      const fieldName = 'totalsMap.' + stateProvinceAbbrv;

      if (vehicleIFTAData?.totalsMap && hideEmptyStateProvinces && vehicleIFTAData.totalsMap[stateProvinceAbbrv] === 0) continue;

      // csv stateprovince headers
      csvDataString.current += `,${stateProvinceAbbrv}`;

      _tableColumns.push(
        <Column
          key={fieldName}
          field={fieldName}
          header={stateProvinceAbbrv}
          headerTooltip={Object.values(CountryData).find((stateProvince) => stateProvince.code === stateProvinceAbbrv)?.name}
          headerTooltipOptions={{ position: 'top', className: 'state-province-tooltip' }}
          body={(rowData) => totalsBodyTemplate(rowData, stateProvinceAbbrv)}
          style={{ minWidth: '150px' }}
        />,
      );
    }

    setTableColumns(_tableColumns);
  }

  // Create footer group with the totals
  function generateFooterGroup() {
    // Generate a column for each jurisdiction, follows the same sort order
    let total = 0; // the total of all jurisdisctions combined

    // csv append
    csvDataFooterString.current = '';

    const jurisdictionColumns = stateProvinceAbbrvs.map(
      (stateProvinceAbbrv) => {
        const stateProvinceTotalsBodyObject = getStateProvinceTotalsBodyObject(
          vehicleIFTAData,
          stateProvinceAbbrv,
        );

        if (vehicleIFTAData?.totalsMap && hideEmptyStateProvinces && vehicleIFTAData.totalsMap[stateProvinceAbbrv] === 0) return null;

        total += stateProvinceTotalsBodyObject.total;

        // csv append
        csvDataFooterString.current += `,${stateProvinceTotalsBodyObject.total || '-'}`;

        return <Column key={stateProvinceAbbrv} footer={stateProvinceTotalsBodyObject.template} style={{ minWidth: '150px' }} />;
      },
    ).filter((column) => column);

    // now putting it together in the footerGroup
    const _footerGroup = (
      <ColumnGroup>
        <Row>
          <Column
            footer="Totals:"
            style={{ minWidth: '120px' }}
            colSpan={3}
            frozen
          />
          <Column
            footer={() => overallTotalsBodyTemplate(total)}
            style={{ minWidth: '120px' }}
            frozen
          />
          {jurisdictionColumns}
        </Row>
      </ColumnGroup>
    );

    setFooterGroup(_footerGroup);
  }

  // // Final filtered values for display purposes. if there is no data at all, return emptiness (leik if u cri everyteim)
  // let _vehicleJurisdictionArr = vehicleJurisdictionArr;
  // let _tableColumns = tableColumns;
  // let _footerGroup = footerGroup;

  // if (isFetched && vehicleJurisdictionTotalsData.totalsMap) {
  //   const stateProvinceTotals = Object.values(vehicleJurisdictionTotalsData.totalsMap); // array of totals
  //   // if all the totals of each stateprovince add to 0, we know there's no data for any of them
  //   const totalOfStateProvinceTotals = stateProvinceTotals.reduce((a, b) => a + b, 0);
  //   if (totalOfStateProvinceTotals === 0) {
  //     _vehicleJurisdictionArr = [];
  //     _tableColumns = [];
  //     _footerGroup = undefined;
  //   }
  // }

  let className = 'ifta-vehicle-interval-data-table mx-2';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <div className="mb-3">
        {isLoading && (
          <ProgressSpinner className="ml-5" style={{ width: '2em' }} strokeWidth={5} />
        )}

        {!isLoading && (
          <div className="flex justify-content-between">
            <div>
              {props.onBack && (
                <Button
                  text
                  label="GO BACK"
                  severity="secondary"
                  onClick={() => props.onBack()}
                  sbVariant="short"
                />
              )}

              <DistanceUnitDropdown
                className="inline-block ml-5"
                unit={lengthUnit}
                onSelect={(lengthUnit) => setLengthUnit(lengthUnit.short)}
              />
            </div>

            <div className="flex align-items-center">
              <div className="sb-font-roboto text-xs text-gray-calm uppercase font-bold mr-2">
                Hide Inactive States/Provinces
              </div>
              <InputSwitch
                checked={hideEmptyStateProvinces}
                onChange={() => setHideEmptyStateProvinces(!hideEmptyStateProvinces)}
              />
              <Button
                className="ml-2"
                text
                label="Export CSV"
                severity="secondary"
                sbVariant="short"
                disabled={isLoading}
                onClick={() => createCsvFile(`Unit_${vehicleIFTAData.unitId}_Summary_${moment(dateInterval[0]).format('YYYY-MM')}_${moment(dateInterval[1]).format('YYYY-MM')}`, csvDataString.current + csvDataFooterString.current, true) }
              />
            </div>
          </div>
        )}
      </div>
      <DataTable
        value={Object.values(dayMap)}
        footerColumnGroup={footerGroup}
        selection={undefined}
        // onSelectionChange={(e) => { console.log(csvDataString.current); console.log(csvDataFooterString.current); }}
        scrollable
        scrollHeight={props.scrollHeight || '80vh'}
      >
        {tableColumns}
      </DataTable>
    </div>
  );
}

export default IFTAVehicleIntervalDataTable;
