import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

import history from 'sbHistory';

// sb-csapi
import { formatName } from 'sb-csapi/dist/utils/String';
import { getLocationDescriptionBreakdown } from 'sb-csapi/dist/api/VehicleLocation/VehicleLocation';

// sbCore
import Alert from 'sbCore/Alert/Alert';
import SidebarCard from 'sbCore/Card/SidebarCard';
import DutyStatusBadge from 'sbCore/DutyStatusBadge/DutyStatusBadge';

// Enums
import { AlertStatus } from 'sb-csapi/dist/enums/Notification/AlertStatus';

import './style.scss';

/**
 * @description The card shown in the maps sidebar for both vehicle and trailer
 *
 * @param {string} className - Any custom styling to apply to cards
 * @param {object} vehicleTrailerObj - An object which contains information to be displayed on the card
 * @param {boolean} isVehicle - Whether to display vehicle specific information on the card
 * @param {boolean} isTrailer - Whether to display trailer specific information on the card
 * @param {Function} onClick - Callback function for when the card is clicked
 * @param {boolean} isActive - Whether or not the card is currently "active"
 * @param {function} handleSetActiveEquipmentInformation - Function that refreshes equipment information shown in this component
 *
 * @example
 * <VehicleTrailerCard vehicleTrailerObj={vehicleObj} isVehicle />
 */
function VehicleTrailerCard({ ...props }) {
  const [isOutdated, setIsOutdated] = useState(false);
  const [isMoving, setIsMoving] = useState(false);

  // update active equipment card information when sidebar card updates
  useEffect(() => {
    let didCancel = false;

    if (props.isActive && !didCancel) {
      props.handleSetActiveEquipmentInformation();
    }

    return (() => didCancel = true);
  }, [props.vehicleTrailerObj])

  function generateCardDriversTemplate() {
    const { drivers } = props.vehicleTrailerObj;

    const driversElement = (
      <>
        {drivers && drivers.map((driver, index) => {
          if (index === 0) {
            return (
              <a
                className="driver-link text-sm"
                href={`driver?driver=${driver.driverObjectId}`}
              >
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    // history.push(`driver?driver=${driver.driverObjectId}`)
                  }}>
                  {formatName(driver.fullName)}
                </span>
              </a>
            );
          } else {
            return (
              <span>{`, `}
                <a
                  className="driver-link text-sm"
                  href={`driver?driver=${driver.driverObjectId}`}
                >
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      // history.push(`driver?driver=${driver.driverObjectId}`)
                    }}>
                    {formatName(driver.fullName)}
                  </span>
                </a>
              </span>
            );
          }
        })}
        {(!drivers || (drivers && drivers.length === 0)) &&
          <span>-</span>
        }
      </>
    );

    // Change driver icon based on the number of drivers
    const driversIcon = drivers?.length === 2 ? 'group' : 'person';

    return (
      <div className="card-drivers flex align-items-center">
        <span className="material-icons mr-1">{driversIcon}</span>
        {driversElement}
      </div>
    );
  }

  function generateCardLocationTemplate() {
    const { vehicleTrailerObj } = props;
    let lastUpdated = '-';
    let lastUpdatedMoment = vehicleTrailerObj.lastUpdated && moment(vehicleTrailerObj.lastUpdated);
    if (lastUpdatedMoment) {
      lastUpdated = lastUpdatedMoment.format('YYYY-MM-DD @ HH:mm');
      if (moment().diff(lastUpdatedMoment, 'days') >= 3 && !isOutdated) {
        setIsOutdated(true);
      } else if (props.vehicleTrailerObj?.speedKm > 0 && !isMoving) {
        setIsMoving(true);
      }
    }

    let locationDescription = '-';
    if (vehicleTrailerObj.locationDescription) {
      const locationDescriptionBreakdown = getLocationDescriptionBreakdown(vehicleTrailerObj.locationDescription);

      if (locationDescriptionBreakdown.city && locationDescriptionBreakdown.stateProvince?.code) {
        locationDescription = `${locationDescriptionBreakdown.city}, ${locationDescriptionBreakdown.stateProvince.code}`;
      }
    }

    return (
      <div className="flex-column mb-2">
        <div className="text-sm font-bold">
          {locationDescription}
        </div>
        <div className="text-xs">
          {lastUpdated}
        </div>
      </div>
    );
  }

  // Card title left -> vehicle Unit Id
  const cardTitleLeft = (props.vehicleTrailerObj?.unitId && <span className="text-2xl">{props.vehicleTrailerObj?.unitId}</span>);

  // Card title right -> duty status badge
  function generateCardTitleRight() {
    const { vehicleTrailerObj } = props;

    let alertStatusInt;
    let alertDescription;

    if (vehicleTrailerObj?.isIdling) {
      alertStatusInt = AlertStatus.WARNING;
      alertDescription = `${vehicleTrailerObj?.unitId} is currently idling`;
    }

    if (vehicleTrailerObj?.isSpeeding) {
      alertStatusInt = AlertStatus.ERROR;
      alertDescription = `${vehicleTrailerObj?.unitId} is speeding above ${vehicleTrailerObj?.speedLimitStr}`;
    }

    return (
      <div className="flex">
        {alertStatusInt !== undefined &&
          <Alert
            alertStatusInt={alertStatusInt}
            alertDescription={alertDescription}
            containerClassName="mr-1 flex justify-content-center"
          />
        }
        {props.vehicleTrailerObj?.eldStatusInt && <DutyStatusBadge eldStatusInt={props.vehicleTrailerObj?.eldStatusInt} className="h-2rem" />}
      </div>
    )
  }

  // Card body -> vehicle location description and time
  const cardBody = (props.isVehicle && generateCardLocationTemplate());
  const cardFooter = (props.isVehicle ? generateCardDriversTemplate() : (props.isTrailer && generateCardLocationTemplate()));

  function generateCollapsibleContent() {
    const { vehicleTrailerObj, isTrailer, isVehicle } = props;
    const { drivers, companyName } = vehicleTrailerObj;

    return (
      <>
        {isTrailer && generateCardLocationTemplate()}
        {isVehicle &&
          <div className="flex flex-row pt-2">
            <div className="flex flex-column mr-2" style={{ flexBasis: '50%' }}>
              <span className="text-xs">Driver</span>
              <span className="text-sm">{(drivers?.length > 0 && formatName(drivers[0].fullName)) || '-'}</span>
            </div>
            <div className="flex flex-column" style={{ flexBasis: '50%' }}>
              <span className="text-xs">Co-driver</span>
              <span className="text-sm">{(drivers?.length > 1 && formatName(drivers[1].fullName)) || '-'}</span>
            </div>
          </div>
        }
        <div>
          <div className="flex flex-column pt-2" style={{ flexBasis: '50%' }}>
            <span className="text-xs">Company</span>
            <span className="text-sm">{companyName || '-'}</span>
          </div>
        </div>
        <div>
          <div className="flex flex-column pt-2" style={{ flexBasis: '50%' }}>
            <span className="text-xs">Shipping ID</span>
            <span className="text-sm">{(drivers?.length > 0 && drivers[0].shippingDocumentNumber) || '-'}</span>
          </div>
        </div>
      </>
    );
  }

  let className = "vehicle-trailer-card";
  if (props.className) className += ` ${props.className}`;
  if (isOutdated) className += ` outdated-card`;
  if (isMoving) className += ` moving`;

  return (
    <div
      className={className}
      onClick={() => props.onClick()}
    >
      <SidebarCard
        isActive={props.isActive}
        cardTitleLeft={cardTitleLeft}
        cardTitleRight={generateCardTitleRight()}
        body={cardBody}
        footer={cardFooter}
        expandedContent={generateCollapsibleContent()}
      />
    </div>
  );
}

export default VehicleTrailerCard;
