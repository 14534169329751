import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';
import { MDBBtn, MDBRow, MDBCol } from 'mdbreact';

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL'

// Components
import SBTable from 'components/Shared/SBTable/SBTable';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import DriverRoutes from './DriverRoutes';

const tableHeaderStyles = {
  unitId: { width: '40%' },
  end: { width: '60%' },
};
const tableHeaderRows = [{
  key: 'sb-jobs-list', columns: [
    {
      element: <div>Driver</div>,
      props: {
        style: tableHeaderStyles.unitId,
        // handleSort: () => { this.handleSort(AttributeTypes.BATCH_ID) },
        // isSortActive: (fetchAttributes.sortBy.attribute === AttributeTypes.BATCH_ID),
        // sortOrder: fetchAttributes.sortBy.order,
      }
    },
    {
      element: <div></div>,
      props: {
        style: tableHeaderStyles.end,
      }
    },
  ]
}];

class DriversTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      distanceUnitFilter: Getters.getPDispatcherPropertyFromState('distanceUnit') === 'mi' ? 
      {
        key: 0,
        value: 'mi',
        label: 'mi',
      }
      :
      {
        key: 1,
        value: 'km',
        label: 'km',
      },
      driversArr: [],
      selected: {
        driver: null,
        iftaRouteArr: [],
      },
      distanceUnitFilterItems: [
        {
          key: 0,
          value: 'km',
          label: 'km',
        },
        {
          key: 1,
          value: 'mi',
          label: 'mi',
        },
      ],
      loading: false,
    };

    this.selectDriver = this.selectDriver.bind(this);
  }

  async componentDidMount() {
    await this.setState({ ...this.state, loading: true });
    const driversArr = await Getters.getDrivers({ enabled: true });
    this.setState({ ...this.state, driversArr, loading: false }, () => {
      const driverIdFromUrl = getDeserialized(this.props.location.search).params.driver;
      if (driverIdFromUrl && (!this.state.selected.driver || driverIdFromUrl !== this.state.selected.driver.id)) {
        this.selectDriver(driverIdFromUrl);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    const driverIdFromUrl = getDeserialized(nextProps.location.search).params.driver;
    this.selectDriver(driverIdFromUrl);
  }

  selectDriver(driverId) {
    if (!driverId) {
      return this.setState({ ...this.state, selected: {
        driver: null,
        iftaRouteArr: [],
      }});
    }
    if (this.state.driversArr.length === 0) return;
    const driver = this.state.driversArr.filter((driverObj) => driverObj.id === driverId).length > 0 && this.state.driversArr.filter((driverObj) => driverObj.id === driverId)[0];
    if (driver) {
      const iftaRouteArr = [].concat(...this.props.vehicleMileagesArr.map((vehicleMileagesObj) => vehicleMileagesObj.iftaRouteArr));
      const driverIftaRouteArr = iftaRouteArr.filter((iftaRouteObj) => {
        return (
          iftaRouteObj.get('iftaRouteDriverPeriods') && 
          iftaRouteObj.get('iftaRouteDriverPeriods').length > 0 &&
          iftaRouteObj.get('iftaRouteDriverPeriods').filter((iftaRouteDriverPeriodObj) => {
            return (iftaRouteDriverPeriodObj.get('driver') && iftaRouteDriverPeriodObj.get('driver').get('user_fullName') === driver.get('user_fullName'));
          }).length > 0
        );
      });
      this.setState({ ...this.state, selected: {
        driver,
        iftaRouteArr: driverIftaRouteArr
      }});
    }
  }

  render() {
    let tableBodyRows = this.state.driversArr.map(driverObj => {
      const driverRowObj = {
        key: driverObj.id,
        columns: [],
        // props: { expandableContent }
      };
      driverRowObj.columns = [
        { element: <div className="font-weight-bold">
            { Helpers.formatName(driverObj.get('user_fullName')) }
          </div>, props: { className: 'table-body-column-style' } },
        { element: <div>
            <MDBBtn
              size="md"
              onClick={() => {
                const queryStrObj = getDeserialized(this.props.location.search).params;
                queryStrObj.driver = driverObj.id;
                history.push({ search: getSerialized(queryStrObj).query });
              }}
              // disabled={disableIFTAButtonGroup}
              style={{ fontWeight: '500' }}
              color="primary"
            >{`VIEW ${driverObj.get('user_fullName').toUpperCase()} MILEAGE REPORT`}</MDBBtn>
          </div>, props: { className: 'table-body-column-style' } },
      ];
  
      return driverRowObj;
    });
  
    return (
      <div>
        { !this.state.selected.driver &&
          <SBTable
            hover
            tableHeaderRows={tableHeaderRows}
            tableBodyRows={tableBodyRows}
            isLoading={this.state.loading}
          />
        }
        { this.state.selected.driver &&
          <div>
            <div style={{ margin: '1em' }}>
              {/* <SBSelect
                containerClassName="ml-2 mr-2 d-inline-block"
                className="sb-jobs-list-select"
                defaultToggleText={this.state.vehicleBreakdownFilter.label}
                items={this.state.selectableVehicleBreakdownFilterItems}
                getSelectedItems={(selectedFilter) => this.setState({ ...this.state, vehicleBreakdownFilter: selectedFilter[0] })}
                showFilter
              /> */}
              <SBSelect
                containerClassName="ml-2 mr-2 d-inline-block"
                className="sb-jobs-list-select"
                defaultToggleText={this.state.distanceUnitFilter.label}
                items={this.state.distanceUnitFilterItems}
                getSelectedItems={(selectedFilter) => this.setState({ ...this.state, distanceUnitFilter: selectedFilter[0] })}
                showFilter
              />
              {/* <MDBBtn 
                size="sm"
                color="secondary" 
                disabled={this.state.printInProgress}
                onClick={() => this.printReport('vehicleRouteBreakdown')} 
                style={{ marginRight: '.5em' }}
              >
                Print Report
              </MDBBtn>
              <MDBBtn 
                size="sm"
                color="secondary" 
                disabled={this.state.downloadCsvInProgress}
                onClick={() => this.downloadCsv('vehicleRouteBreakdown')} 
                style={{ marginRight: '.5em', width: '14em' }}
              >
                Download CSV
              </MDBBtn>
              { this.state.showDataButtons &&
                <MDBBtn
                  size="sm"
                  color="info" 
                  onClick={() => this.toggleMap(true)} 
                  style={{ marginRight: '.5em', width: '14em' }}
                >
                  View Map
                </MDBBtn>
              }
              { this.state.showDataButtons &&
                <MDBBtn
                  size="sm"
                  color="info" 
                  onClick={() => {
                    const sortedSelectedIftaRouteArr = this.state.selectedIftaRouteArr.sort((b, a) => a.dateStart.iso - b.dateEnd.iso);
                    this.setState({ ...this.state, 
                      vehicleMileagesIntervalSummary: { 
                        open: true,
                        dateInterval: {
                          start: sortedSelectedIftaRouteArr[0].dateStart,
                          end: sortedSelectedIftaRouteArr[sortedSelectedIftaRouteArr.length - 1].dateEnd,
                        },
                      } 
                    });
                  }}
                  style={{ marginRight: '.5em', width: '14em' }}
                >
                  View Locations
                </MDBBtn>
              } */}
            </div>
            <DriverRoutes
              driver={this.state.selected.driver}
              location={this.props.location}
              iftaRouteArr={this.state.selected.iftaRouteArr}
              dateStart={this.props.dateStart}
              dateEnd={this.props.dateEnd}
              distanceUnitFilter={this.state.distanceUnitFilter}
            />
          </div>
        }
      </div>
    );
  }
};

export default DriversTable;
