// libraries
import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// api
import { getAttribute, getCurrentUserCompanyObjectId } from 'sb-csapi/dist/AAPI';
import { getDrivers } from 'sb-csapi/dist/api/Driver/Driver';
import { formatName } from 'sb-csapi/dist/utils/String';

// sbobjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// enums
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// sbcore components
import Autocomplete from 'sbCore/Autocomplete/Autocomplete';

// styling
import './style.scss';

/**
 * @description Retrieve Drivers given a search term
 *
 * @param {String} [className] - Custom container className
 * @param {String} [panelClassName] - Custom dropdown panel className
 * @param {Object} [style] - Custom inline styles
 * @param {String} [placeholder] - Custom placeholder text
 *
 * @param {bool} [warning] - Makes the border yellow
 * @param {bool} [multiple] - Boolean to allow multiple selections
 * @param {bool} [autoFocus] - Whether to automatically focus on the autocomplete on load
 * @param {bool} [disabled] - Whether disable the component
 * @param {bool} [hideSelectedDrivers] - Whether to hide the selected driver
 *
 * @param {Array<String>} [excludedDriverObjectIds] - Array of driver objectIds to exclude from search suggestions
 * @param {Array<Filter>} [suggestionFilters]
 *
 * @param {Function} [onSelectDriver] - A callback function that returns the selected dispatch driver (when multiple is NOT true)
 * @param {Function} [onSelectDrivers] - A callback function that returns the selected dispatch drivers for multiple selections (when multiple is true)
 *
 * @returns {JSX} The autocomplete input comopnent
 */
function DriverAutocompleteInput({ ...props }) {

  const [identifier] = useState(uniqid()); // for each of this component that exists on the same page, give it unique identifier for specific dom manipulation

  const [searchTerm, setSearchTerm] = useState(''); // what the user types in to search
  const [selectedDrivers, setSelectedDrivers] = useState([]);

  const [driverSuggestions, setDriverSuggestions] = useState([]);
  const [hasError, setHasError] = useState(false); // if there is an error with the input

  async function handleOnSuggestionSearch(query) {
    setHasError(false);

    const _query = (query || '').trim();
    setSearchTerm(_query);

    let filters = [
      ...(props.suggestionFilters || []),
      new Filter(QueryRestriction.LIMIT, undefined, 120),
      new Filter(QueryRestriction.EXISTS, 'user_fullName'),
    ];

    if (_query.length > 0) {
      filters = [
        new Filter(QueryRestriction.LIMIT, undefined, 15),
        new Filter(QueryRestriction.MATCHES, 'user_fullName', _query),
        new Filter(QueryRestriction.EQUAL_TO, 'enabled', true),
      ];
    }

    const { drivers } = await getDrivers(
      undefined, // options - default
      getCurrentUserCompanyObjectId(), // companyobjectid - default
      false, // include child company results
      filters, // filters
      undefined, // sort - default
      [], // includes
      undefined, // selects
      false, // query all
    );

    let _driverSuggestions = drivers.map((driver) => ({
      objectId: getAttribute(driver, 'objectId'),
      userFullName: formatName(getAttribute(driver, 'user_fullName')),
      driver,
    }));

    if (props.hideSelectedDrivers && props.multiple) {
      const selectedDriverObjectIdArr = selectedDrivers.map((driver) => driver.objectId);
      _driverSuggestions = _driverSuggestions.filter((driver) => !selectedDriverObjectIdArr.includes(driver.objectId));
    }

    if (props.excludedDriverObjectIds) {
      _driverSuggestions = _driverSuggestions.filter((driver) => !props.excludedDriverObjectIds.includes(driver.objectId));
    }

    if (_query.length > 0) {
      _driverSuggestions = _driverSuggestions.filter((driver) => driver.userFullName && driver.userFullName.toLowerCase().includes(_query.toLowerCase()));
    }

    setDriverSuggestions(_driverSuggestions);
    if (_driverSuggestions.length !== 0 && _query) setHasError(true);
  }

  // ** useEffect ** //
  useEffect(() => {
    handleOnSuggestionSearch('');
  }, [])

  useEffect(() => {
    if (props.onSelectDriver) {
      props.onSelectDriver(selectedDrivers.driver);
    } else if (props.multiple && props.onSelectDrivers) {
      // Get only the driver records that were selected
      const _selectedDrivers = selectedDrivers.map(driver => driver.driver);
      props.onSelectDrivers(_selectedDrivers);
    }
  }, [selectedDrivers]);

  // ** Templates ** //
  // template for a list item in the autocomplete
  const itemTemplate = (item) => (
    <div className="driver-item">
      <div className="driver-id">{item.userFullName || 'No Driver defined'}</div>
    </div>
  );

  const inputEl = document.querySelector(`.driver-autocomplete-input.${identifier} input[type="text"]`);
  const inputButtonEl = document.querySelector(`.driver-autocomplete-input.${identifier} button.p-button`); // the autocomplete dropdown button

  if (inputEl) {
    inputEl.autocomplete = 'disabled'; // disable the browser's default autocomplete for inputs
  }

  function triggerAutocompleteOnClick() {
    // if the user focuses on the input, open the dropdown by simulating click of button
    if (inputButtonEl) inputButtonEl.click();
  }

  function triggerAutocompleteOnKeyPress(e) {
    // if the input is being focused on
    if (inputButtonEl) {
      const code = (e.keyCode ? e.keyCode : e.which);
      const dropdownPanelEl = document.querySelector(`.driver-autocomplete-input-panel.${identifier}`);
      // Enter
      if (code === 13) {
        if (dropdownPanelEl && driverSuggestions.length) { // If the dropdown is open and not empty
          setSelectedDrivers(driverSuggestions[0]); // Select the first item in dropdown
          return;
        }

        inputButtonEl.click();
      }
    }
  }

  // ** Misc ** //
  let className = `driver-autocomplete-input ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  // if the input is not being focused on (which is for some reason dictated by the button's focus),
  // but the user left their search term without selecting a suggestion for the searchTerm
  const isNotFocusedWithText = inputButtonEl && searchTerm && (document.activeElement !== inputButtonEl);

  let panelClassName = `driver-autocomplete-input-panel ${identifier}`;
  if (props.panelClassName) panelClassName += ` ${props.panelClassName}`;

  const _hasError = isNotFocusedWithText || hasError;

  return (
    <div className={className} style={{ ...props.style }}>
      <Autocomplete
        placeholder={props.placeholder || 'Driver Name'}
        dropdown
        field="userFullName"
        value={selectedDrivers}
        multiple={props.multiple}
        suggestions={driverSuggestions}
        forceSelection={false}
        itemTemplate={itemTemplate}
        autoFocus={props.autoFocus}
        warning={props.warning}
        error={_hasError}
        completeMethod={({ originalEvent, query }) => handleOnSuggestionSearch(query)}
        onChange={(e) => setSelectedDrivers(e.value)}
        onClick={() => triggerAutocompleteOnClick()}
        onKeyPress={(e) => triggerAutocompleteOnKeyPress(e)}
        panelClassName={panelClassName}
        disabled={props.disabled}
        className="w-full"
      />
    </div>
  );
}

export default DriverAutocompleteInput;
