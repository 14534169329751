import React, { useState, useEffect } from 'react';
import history from 'history';

import ConfirmDialog from 'sbCore/ConfirmDialog/ConfirmDialog';

// Api
import isValidDOTNumber from 'api/DOTNumber';

import { getAttribute, getCurrentUserSessionToken, updateRecord } from 'sb-csapi/dist/AAPI';

import InputText from 'sbCore/InputText/InputText';

// Styles
import './style.scss';

function MissingDOTandNSCDialog({ isConfirmDialogVisible, setIsConfirmDialogVisible, handleNextModal, fromStepper, belongsToCompany }) {

  const [dotNumber, setDotNumber] = useState(null);
  const [nscNumber, setNscNumber] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  // const [invalidDOTNumber, setInvalidDOTNumber] = useState(false);

  useEffect(() => {
    if (!belongsToCompany) return;
    if (getAttribute(belongsToCompany, 'dotNumber', true)) setDotNumber(getAttribute(belongsToCompany, 'dotNumber', true));
    if (getAttribute(belongsToCompany, 'nscNumber', true)) setNscNumber(getAttribute(belongsToCompany, 'nscNumber', true));
    setCompanyId(getAttribute(belongsToCompany, 'objectId'));
    // setInvalidDOTNumber(!isValidDOTNumber(getAttribute(belongsToCompany, 'dotNumber')));
  }, [belongsToCompany]);

  useEffect(() => {
    // setInvalidDOTNumber(!isValidDOTNumber(dotNumber));
  }, [dotNumber]);

  const message = (
    <div className="flex flex-column">
      Continue without a valid US DOT or Canada NSC Number? These are required for printing.
      <div className="flex mt-4">
        <span className="p-float-label" style={{ width: '40%', marginRight: '1rem' }}>
          <InputText
            value={dotNumber}
            onChange={(e) => setDotNumber(e.target.value)}
            placeholder="DOT Number"
            className="w-100"
            error={!dotNumber}
            useGrouping={false}
          />
          <label htmlFor="dot-number">DOT Number</label>
        </span>
        <span className="p-float-label" style={{ width: '40%' }}>
          <InputText
            value={nscNumber}
            onChange={(e) => setNscNumber(e.target.value)}
            placeholder="NSC/CVOR/NEQ Number"
            className="w-100"
            error={!nscNumber}
            useGrouping={false}
          />
          <label htmlFor="nsc-number">NSC/CVOR/NEQ Number</label>
        </span>
      </div>
    </div>
  );

  async function saveChanges() {
    console.log(dotNumber);
    await updateRecord({ sessionToken: getCurrentUserSessionToken() }, belongsToCompany, { dotNumber: dotNumber.toString(), nscNumber: nscNumber.toString() }, true);
    setTimeout(() => {
      if (fromStepper) setIsConfirmDialogVisible(false);
      else handleNextModal('ConfirmDialog');
    }, [3000]);
  }

  return (
    <ConfirmDialog
      visible={isConfirmDialogVisible}
      onHide={() => setIsConfirmDialogVisible(false)}
      message={message}
      header="Missing US DOT or Canada NSC Number"
      acceptLabel="Save Changes"
      rejectLabel="Enter It Later"
      rejectClassName="p-button-outlined"
      icon="pi pi-exclamation-triangle"
      accept={async () => await saveChanges()}
      reject={() => fromStepper ? setIsConfirmDialogVisible(false) : handleNextModal('closeConfirmDialog')}
      closable={false}
      draggable={false}
    />
  );
}

export default MissingDOTandNSCDialog;
