import * as ActionConstants from 'actions/ActionConstants';
import * as Helpers from 'api/Helpers';

const initialState = {
  subscription: undefined,
  stripePublishableKey: undefined,
  fetched: false,
};


export function Subscription(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.INITIALIZE_STRIPE:
      return {
        ...state,
        status: action.type,
        stripePublishableKey: action.stripePublishableKey,
        error: {},
      };
    case ActionConstants.FETCH_SUBSCRIPTION_INPROGRESS:
      return {
        ...state,
        status: action.type,
        subscription: undefined,
        error: {},
      };
    case ActionConstants.FETCH_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        status: action.type,
        subscription: action.subscription,
        error: {},
        fetched: true,
      };
    case ActionConstants.FETCH_SUBSCRIPTION_ERROR:
      return {
        ...state,
        status: action.type,
        subscription: undefined,
        error: action.error,
      };
    case ActionConstants.SUBSCRIPTION_CREATE_INPROGRESS:
      return {
        ...state,
        status: ActionConstants.SUBSCRIPTION_CREATE_INPROGRESS,
        error: undefined,
      };
    case ActionConstants.SUBSCRIPTION_CREATE_SUCCESS:
      return {
        ...state,
        status: action.type,
        subscription: action.subscription,
        updated: action.subscription,
      };
    case ActionConstants.SUBSCRIPTION_UPGRADE_SUCCESS:
      return {
        ...state,
        status: action.type,
        subscription: action.subscription,
        updated: action.subscription,
      };
    case ActionConstants.SUBSCRIPTION_WARNING_IGNORE:
      return {
        ...state,
        status: action.type,
        warningIgnore: true,
      };
    case ActionConstants.SUBSCRIPTION_STATUS_SUCCESS:
      return {
        ...state,
        status: action.type,
        subscriptionStatus: action.subscriptionStatusObj,
      };
    default:
      return state;
  }
}
