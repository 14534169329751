import React, { useEffect, useState } from 'react';

// Enums
import { Action } from 'sb-csapi/dist/enums/Action';
import { EquipmentTypes } from 'enums/Equipment'; // need to move to sb-csapi

// API
import { getAttribute, getCurrentUserSessionToken, getRecordByObjectId } from 'sb-csapi/dist/AAPI';
import {
  createEquipmentGroupEquipment,
  createEquipmentGroup,
  deleteEquipmentGroup,
  deleteEquipmentGroupEquipment,
  updateEquipmentGroup,
  createEquipmentGroupUser,
  deleteEquipmentGroupUser,
  getEquipmentGroupUsers,
  updateEquipmentGroupUser,
} from 'api/Equipment/Groups';

import { track } from 'sb-csapi/dist/api/Analytics/Product';
import { SubFeature, getEventString } from 'sb-csapi/dist/enums/Analytics/Product';

// Component
import InputText from 'sbCore/InputText/InputText';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import AddEditModal from 'sbCore/AddEditModal/AddEditModal';
import Message from 'sbCore/Message/Message';
import TabMenu from 'sbCore/TabMenu/TabMenu';
import UserGroupTable from 'components/Groups/UserGroupTable/UserGroupTable';
import EquipmentGroupEquipmentTable from 'components/EquipmentGroup/EquipmentGroupEquipmentTable/EquipmentGroupEquipmentTable';

// Styling
import './style.scss';

/**
 * @description Modal for adding/editing equipment groups
 *
 * @param {Action} action - action to be performed (ADD/EDIT)
 * @param {EquipmentTypes} equipmentType - type of equipment (vehicle/trailer)
 * @param {String} [equipmentGroupObjectId] - The equipment group object id
 * @param {Boolean} [isOpen] - Whether the modal is open or not
 * @param {Function} handleOnClose - callback function for when the modal is closed
 *
 * @returns {JSX} - returns the EquipmentAddEditModal
 */
function EquipmentGroupAddEditModal({ ...props }) {
  const { equipmentGroupObjectId, isOpen, equipmentType, action } = props;

  // State - General
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  // State - EquipmentGroup/EquipmentGroupEquipment
  const [equipmentGroup, setEquipmentGroup] = useState(undefined);

  // State - pending changes
  const [equipmentGroupName, setEquipmentGroupName] = useState(undefined);
  const [equipmentGroupEquipmentMap, setEquipmentGroupEquipmentMap] = useState(undefined);
  const [equipmentGroupUsersMap, setEquipmentGroupUsersMap] = useState(undefined);

  // Tab Menu
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const tabMenuItems = [
    { label: 'Equipment', icon: <i className="material-icons mr-2">local_shipping</i> },
    {
      label: 'Permissions & Notifications',
      icon: <i className="material-icons mr-2">admin_panel_settings</i>,
      disabled: !equipmentGroup,
    },
  ];

  // Fetch equipment group data
  // Fetches information again so that logic is de-coupled between sidebar and equipment group modal
  useEffect(() => {
    let didCancel = false;

    async function _fetchEquipmentGroupInformation() {
      if (!didCancel) setIsLoading(true);

      // Fetch the equipment group for the name
      const _equipmentGroup = await getRecordByObjectId(
        { sessionToken: getCurrentUserSessionToken() }, // options
        'EquipmentGroup', // tableName
        equipmentGroupObjectId, // objectId
        undefined, // includedPointers
        undefined, // selectedAttributes
      );

      const _equipmentGroupName = getAttribute(_equipmentGroup, 'name');

      if (!didCancel) {
        setEquipmentGroup(_equipmentGroup);
        setEquipmentGroupName(_equipmentGroupName);
        setIsLoading(false);
      }
    }

    if (equipmentGroupObjectId) _fetchEquipmentGroupInformation();

    return () => { didCancel = true; };
  }, [equipmentGroupObjectId]);

  // useEffect to handle checking for pending changes or errors
  useEffect(() => {
    let _hasError = false;
    let _hasPendingChanges = false;
    const equipmentGroupNameTrimmed = (equipmentGroupName || '').trim();

    if (!equipmentGroupNameTrimmed) _hasError = true;

    if (equipmentGroup && (equipmentGroupNameTrimmed !== getAttribute(equipmentGroup, 'name'))) _hasPendingChanges = true;
    if (equipmentGroupEquipmentMap?.addEquipmentArr.length > 0 || equipmentGroupEquipmentMap?.removeEquipmentArr.length > 0) _hasPendingChanges = true;
    if (equipmentGroupUsersMap?.addedEquipmentGroupUsers.length > 0 || equipmentGroupUsersMap?.changedEquipmentGroupUsers.length > 0 || equipmentGroupUsersMap?.removedEquipmentGroupUsers.length > 0) _hasPendingChanges = true;

    setHasError(_hasError);
    setHasPendingChanges(_hasPendingChanges);
  }, [equipmentGroupName, equipmentGroupEquipmentMap, equipmentGroupUsersMap]);

  // Handles creating/updating equipment group and equipment group equipment information
  async function saveEquipmentGroupInformation() {
    setIsLoading(true);

    let _equipmentGroup = equipmentGroup;
    let _equipmentGroupObjectId;
    const equipmentGroupNameTrimmed = (equipmentGroupName || '').trim();

    if (action === Action.ADD) {
      // First, check to see if we are adding a new EquipmentGroup
      _equipmentGroup = await createEquipmentGroup(
        undefined, // options
        equipmentGroupNameTrimmed, // name
      );

      if (window.sbProdAnalIsAnon === false) {
        // Don't want to track anonymous users
        track(
          window.sbProdAnalEntityId,
          getEventString(SubFeature.EQUIPGROUP, `Added new Group (${getAttribute(_equipmentGroup, 'objectId')} - ${equipmentGroupNameTrimmed})`),
        );
      }

    } else if (action === Action.EDIT && (equipmentGroupNameTrimmed !== getAttribute(equipmentGroup, 'name'))) {
      // Check for any updates to the EquipmentGroup name
      _equipmentGroup = await updateEquipmentGroup(undefined, equipmentGroupObjectId, { name: equipmentGroupNameTrimmed });
    }

    // Fetch the appropriate object id for the equipment group
    _equipmentGroupObjectId = getAttribute(_equipmentGroup, 'objectId');

    // Now, let's add the pending equipment information
    if (equipmentGroupEquipmentMap?.addEquipmentArr.length > 0) {
      const equipmentGroupEquipments = await Promise.all(equipmentGroupEquipmentMap.addEquipmentArr.map((equipment) => {
        if (equipmentType === EquipmentTypes.VEHICLE) {
          return createEquipmentGroupEquipment(undefined, _equipmentGroupObjectId, getAttribute(equipment, 'objectId'), undefined);
        } else if (equipmentType === EquipmentTypes.TRAILER) {
          return createEquipmentGroupEquipment(undefined, _equipmentGroupObjectId, undefined, getAttribute(equipment, 'objectId'));
        }
      }));
    }

    // Now, let's remove the pending equipment information
    if (equipmentGroupEquipmentMap?.removeEquipmentArr.length > 0) {
      const deletedEquipmentGroupEquipments = await Promise.all(equipmentGroupEquipmentMap.removeEquipmentArr.map((equipment) => {
        if (equipmentType === EquipmentTypes.VEHICLE) {
          return deleteEquipmentGroupEquipment(undefined, _equipmentGroupObjectId, getAttribute(equipment, 'objectId'), undefined);
        } else if (equipmentType === EquipmentTypes.TRAILER) {
          return deleteEquipmentGroupEquipment(undefined, _equipmentGroupObjectId, undefined, getAttribute(equipment, 'objectId'));
        }
      }));
    }

    setIsLoading(false);
  }

  async function deleteEquipmentGroupInformation() {
    // Deletes the equipment group and all associated equipment group equipments
    setIsLoading(true);

    const { deletedEquipmentGroup, deletedEquipmentGroupEquipments } = await deleteEquipmentGroup(undefined, equipmentGroupObjectId);

    setIsLoading(false);
  }

  async function saveEquipmentGroupUsersInformation() {
    setIsLoading(true);

    if (equipmentGroupUsersMap?.addedEquipmentGroupUsers.length > 0) {
      // Add in new users
      let equipmentGroupUsers = equipmentGroupUsersMap.addedEquipmentGroupUsers.map((equipmentGroupUserInformation) => {
        const { enabled } = equipmentGroupUserInformation.permissions;
        const { enableEmailNotifications } = equipmentGroupUserInformation.notifications;

        const equipmentGroupInformationValues = {
          enabled: enabled,
          enableEmailNotifications: enableEmailNotifications,
        }

        return createEquipmentGroupUser(undefined, equipmentGroupObjectId, equipmentGroupUserInformation.user.objectId, equipmentGroupInformationValues);
      });

      equipmentGroupUsers = await Promise.all(equipmentGroupUsers);
    }

    if (equipmentGroupUsersMap?.changedEquipmentGroupUsers.length > 0) {
      // Update existing users
      let equipmentGroupUsers = equipmentGroupUsersMap.changedEquipmentGroupUsers.map((equipmentGroupUserInformation) => {
        const { enabled } = equipmentGroupUserInformation.permissions;
        const { enableEmailNotifications } = equipmentGroupUserInformation.notifications;

        const equipmentGroupInformationValues = {
          enabled: enabled,
          enableEmailNotifications: enableEmailNotifications,
        }

        return updateEquipmentGroupUser(undefined, equipmentGroupUserInformation.groupUserObjectId, equipmentGroupInformationValues);
      });

      equipmentGroupUsers = await Promise.all(equipmentGroupUsers);
    }

    if (equipmentGroupUsersMap?.removedEquipmentGroupUsers.length > 0) {
      // Remove existing users
      let deletedEquipmentGroupUsers = equipmentGroupUsersMap.removedEquipmentGroupUsers.map((equipmentGroupUserInformation) => {
        return deleteEquipmentGroupUser(undefined, equipmentGroupUserInformation.groupUserObjectId);
      });

      deletedEquipmentGroupUsers = await Promise.all(deletedEquipmentGroupUsers);
    }

    setIsLoading(false);
  }

  async function deleteEquipmentGroupUsersInformation() {
    // Deletes all the associated equipment group users
    setIsLoading(true);

    // Fetch any items related to the equipment group
    const { equipmentGroupUsers } = await getEquipmentGroupUsers(
      undefined, // options
      equipmentGroupObjectId, // equipmentGroupObjectId
      undefined, // filters
      undefined, // sortBy
      undefined, // includedPointers
      undefined, // selectedAttributes
      undefined, // page
      undefined, // limit
      true, // queryAll
    );

    // Delete all the associated equipment group users for this equipment group
    let deletedEquipmentGroupUsers = equipmentGroupUsers.map((equipmentGroupUser) => {
      deleteEquipmentGroupUser(undefined, getAttribute(equipmentGroupUser, 'objectId'));
    });

    deletedEquipmentGroupUsers = await Promise.all(deletedEquipmentGroupUsers);

    setIsLoading(false);
  }

  // Handles main action button press behaviors (add, edit, delete)
  async function handleAddEditRemove(actionType) {
    switch (actionType) {
      case Action.ADD:
      case Action.EDIT:
        await saveEquipmentGroupInformation();
        await saveEquipmentGroupUsersInformation();
        break;
      case Action.DELETE:
        await deleteEquipmentGroupInformation();
        await deleteEquipmentGroupUsersInformation();
        break;
      default:
        break;
    }

    props.handleOnClose();
  }

  const headerTemplate = (
    <div className="flex align-items-center dialog-header-title">
      {/* Modal title group name should stay the same regardless if the user has changed it or not */}
      {action === Action.EDIT && <div>{`Edit ${getAttribute(equipmentGroup, 'name', true)}`}</div>}
      {action === Action.ADD && <div>New Equipment Group</div>}
    </div>
  );

  return (
    <AddEditModal
      isLoading={isLoading}
      action={action}
      headerTemplate={headerTemplate}
      addButtonLabel="ADD GROUP"
      deletePopupMessage={`Are you sure you want to delete ${equipmentGroupName}`}
      isOpen={isOpen}
      onDelete={() => handleAddEditRemove(Action.DELETE)}
      onUpdate={() => handleAddEditRemove(Action.EDIT)}
      onCreate={() => handleAddEditRemove(Action.ADD)}
      onCancel={() => handleAddEditRemove()}
      disableCreateButton={isLoading || hasError}
      disableUpdateButton={isLoading || hasError || !hasPendingChanges}
    >
      <div className="equipment-group-add-edit-modal px-1">
        <div className="equipment-group-name-input mb-7">
          <InputLabel>Group Name<span className="required-field-asterisk ml-1">*</span></InputLabel>
          <InputText
            value={equipmentGroupName}
            onChange={(e) => setEquipmentGroupName(e.target.value)}
            error={!equipmentGroupName}
          />
          <InputLabel className={equipmentGroupName ? 'hidden' : 'mt-1'} error>Group name is required</InputLabel>
        </div>
        <TabMenu
          model={tabMenuItems}
          activeIndex={activeTabIndex}
          onTabChange={(e) => setActiveTabIndex(e.index)}
        />

        <div className={`${activeTabIndex === 0 ? '' : 'hidden'}`}>
          <EquipmentGroupEquipmentTable
            equipmentType={equipmentType}
            equipmentGroupObjectId={equipmentGroupObjectId}
            onChange={(pendingEquipmentToAdd, pendingEquipmentToRemove) => {
              setEquipmentGroupEquipmentMap({
                addEquipmentArr: pendingEquipmentToAdd,
                removeEquipmentArr: pendingEquipmentToRemove,
              });
            }}
          />
          <Message
            className="mt-3 w-full"
            severity="info"
            text={`To edit ${equipmentType === EquipmentTypes.VEHICLE ? 'trailer' : 'vehicle'} information for this group, navigate to the appropriate tab on the sidebar and select the "Edit" button beside the respective group.`}
          />

          {!equipmentGroup &&
            <Message
              className="mt-3 w-full justify-content-start"
              severity="warn"
              text={"User permissions & notifications are only enabled after creation of the group."}
            />
          }
        </div>
        <div className={`${activeTabIndex === 1 ? '' : 'hidden'}`}>
          <UserGroupTable
            equipmentGroupObjectId={equipmentGroupObjectId}
            onChange={([pendingGroupUsersToAdd, pendingGroupUsersToChange, pendingGroupUsersToRemove]) => {
              setEquipmentGroupUsersMap({
                addedEquipmentGroupUsers: pendingGroupUsersToAdd,
                changedEquipmentGroupUsers: pendingGroupUsersToChange,
                removedEquipmentGroupUsers: pendingGroupUsersToRemove,
              })
            }}
          />
        </div>
      </div>
    </AddEditModal>
  );
}

export default EquipmentGroupAddEditModal;
