import { createContext } from 'react';

/**
 * @description Default export which creates a context to allow the sharing of state to any child component
 * @returns
 *
 * @example
 * useContext(DispatchJobLayoutContext)
 */
const DispatchJobLayoutContext = createContext(null);
export default DispatchJobLayoutContext;

/**
 * @description Used as a wrapper for the parent component to allow the passing of values to its children
 * @returns
 *
 * @example
 * <DispatchJobLayoutProvider value={...}>...</DispatchJobLayoutProvider>
 */
export const DispatchJobLayoutProvider = DispatchJobLayoutContext.Provider;
