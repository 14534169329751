
// sb-csapi
import {
  copyQuery, count, createQuery, createQueryOr, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, getCurrentUser, addRecord,
} from 'sb-csapi/dist/AAPI';

import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/** @module Document */

/**
 * @category Documents
 * @subcategory
 *
 * @description Get a list of Driver documents
 *
 * @param {object} options - See example
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {int} page - The current page for pagination
 * @param {int} limit - The limit of records obtained per pagination
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns {object} - { driverDocuments: [DriverDocument, ...], totalDriverDocumentsCount: number }
 */
 async function getDriverDocuments(options = { sessionToken: getCurrentUserSessionToken() }, filters = [], sortBy = new Sort(QuerySortOrder.DESCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  const driverDocumentsQuery = createQuery('DriverDocument');
  const _filters = [
    ...filters,
    new Filter(QueryRestriction.EQUAL_TO, 'isHidden', false),
  ];

  const belongsToCompany = getCurrentUserCompanyObjectId();
  setQueryRestriction(driverDocumentsQuery, QueryRestriction.EQUAL_TO, 'belongsToCompany', belongsToCompany);

  _filters.map(filter => setQueryRestriction(driverDocumentsQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  const driverDocumentCountQuery = copyQuery(driverDocumentsQuery);

  // sort
  sortQuery(driverDocumentsQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(driverDocumentsQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(driverDocumentsQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(driverDocumentsQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(driverDocumentsQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the count and the documents
  const promises = [count(options, driverDocumentCountQuery), findRecords(options, driverDocumentsQuery, false, queryAll)];
  
  try {
    const [totalDriverDocumentsCount, driverDocuments] = await Promise.all(promises);
    return { totalDriverDocumentsCount, driverDocuments };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @category Documents
 * @subcategory
 *
 * @description Update driver document
 *
 * @param {DriverDocument} driverDocumentRecord - DriverDocument record
 * @param {object} keyValueObj - Object containing new values
 *
 * @returns {object} - DriverDocument record
 */
async function updateDriverDocument(driverDocumentRecord, keyValueObj) {
  if (!driverDocumentRecord) throw new Error ('Must provide driverDocumentRecord');
  if (!keyValueObj) throw new Error ('Must provide keyValueObj');

  try {
    const updatedDriverDocumentRecord = await updateRecord({ sessionToken: getCurrentUserSessionToken() }, driverDocumentRecord, keyValueObj, true);
    return updatedDriverDocumentRecord;
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * @category Documents
 * @subcategory
 *
 * @description Insert driver document
 *
 * @param {object} keyValueObj - Object containing new values
 *
 * @returns {object} - DriverDocument record
 */
async function addDriverDocument(keyValueObj) {
  if (!keyValueObj) throw new Error ('Must provide keyValueObj');

  try {
    const newDriverDocumentRecord = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'DriverDocument', keyValueObj, getCurrentUserCompanyObjectId());
    return newDriverDocumentRecord;
  } catch (error) {
    throw new Error(error);
  }
}

export {
  getDriverDocuments,
  updateDriverDocument,
  addDriverDocument,
};
