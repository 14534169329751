import React, { useState } from 'react';
import moment from 'moment-timezone';
import uniqid from 'uniqid';

// Components
import Card from 'sbCore/Card/Card';
import Divider from 'sbCore/Divider/Divider';
import Accordion from 'sbCore/Accordion/Accordion';
import AccordionTab from 'sbCore/Accordion/AccordionTab';

// Styles
import './style.scss';

/**
 * @description Component responsible for generating the "cards" for each route
 * @param {Object} routePoint - Required. The routePoint object that contains the information for the card.
 * @returns
 */
function DispatchRoutePointContent({ ...props }) {
  const [activeIndex, setActiveIndex] = useState();

  return (
    <Card title="Point Detail" className="dispatch-route-point-content">
      <div className="grid">
        <div className="col">
          <div className="font-bold pb-2">Time</div>
          <div>{props.routePoint.pickupDropoffDateTime}</div>
        </div>
        <div className="col">
          <div className="font-bold pb-2">Timezone</div>
          <div>{props.routePoint.timezone || '-'}</div>
        </div>
        <div className="col">
          <div className="font-bold pb-2">Location</div>
          <div>{props.routePoint.shipperConsigneeLocation || '-'}</div>
        </div>
      </div>

      <Divider />

      {(!props.routePoint.freight || props.routePoint.freight.length === 0) &&
        <p className="mb-0 text-400 font-italic">No freight information available</p>
      }

      {props.routePoint.freight && props.routePoint.freight.length > 0 && (
        <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          <AccordionTab header="Freight">
            <div className="grid mt-3">
              <div className="col">
                <div className="font-bold pb-2">Equipment</div>
              </div>
              <div className="col">
                <div className="font-bold pb-2">Quantity</div>
              </div>
              <div className="col">
                <div className="font-bold pb-2">Category</div>
              </div>
              <div className="col">
                <div className="font-bold pb-2">Weight</div>
              </div>
              <div className="col">
                <div className="font-bold pb-2">Dimensions</div>
              </div>
              <div className="col">
                <div className="font-bold pb-2">Reference</div>
              </div>
            </div>

            {props.routePoint.freight.map((freightItem) => {
              return (
                <div key={freightItem.key} className="grid">
                  <div className="col">
                    <div>{freightItem.equipment}</div>
                  </div>
                  <div className="col">
                    <div>{freightItem.quantity}</div>
                  </div>
                  <div className="col">
                    <div>{freightItem.categoryStr}</div>
                  </div>
                  <div className="col">
                    <div>{freightItem.weightString}</div>
                  </div>
                  <div className="col">
                    <div>{freightItem.dimensionsString}</div>
                  </div>
                  <div className="col">
                    <div>{freightItem.referenceNumber}</div>
                  </div>
                </div>
              );
            })}
          </AccordionTab>
        </Accordion>
      )}
    </Card>
  );
}

export default DispatchRoutePointContent;
