// http://londoncycle.alexrieux.fr/
import React from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

// API
import * as Mapbox from 'api/Mapbox';
import * as Getters from 'api/Getters';
import * as HERE from 'api/HERE';
import { createShareVehicleLocation, createShareLocation, pingGNXLocation, pingLocation } from 'api/Setters';
import { areArraysEqual, createCsvFile, isSubscribedToModule } from 'api/Helpers';
import { getCurrentUser, getAttribute } from 'api/Parse';
import { getLocalReverseGeocode } from 'api/Geocode';
import { retrieveAndSaveDetailedReverseGeocode } from 'api/VehicleLocation/VehicleLocation';

// Actions
import { fetchVehicleLocationsForDateRange, fetchTCPositionsVehicleLocationsForDateRange, fetchAssetLocationsVehicleLocationsForDateRange, deleteVehicleLocationsForState, addVehicleLocation } from 'actions/VehicleLocation';

// Components
import ActiveVehicleDetails from 'components/Map.old/view/ActiveVehicleDetails';
import ActiveTrailerDetails from 'components/Map.old/view/ActiveTrailerDetails';
import TripList from 'components/Map.old/view/TripList';
import FitToVehiclesButton from 'components/Map.old/view/components/FitToVehiclesButton';
import ShowLSDLinesButton from 'components/Map.old/view/components/ShowLSDLinesButton';
import LinkShare from 'components/Map.old/container/components/LinkShare';
import MapStyleToggle from 'components/Map.old/view/components/MapStyleToggle';
import SearchBar from 'components/Map.old/view/components/SearchBar';
import VehicleMapView from 'components/Map.old/view/VehicleMapView';
import AddEditGeofence from './AddEditGeofence';
import VehicleTrailerActiveView from 'components/Map/VehicleTrailerActiveView/VehicleTrailerActiveView';
import TripHistoryCard from 'components/Map/TripHistoryCard/TripHistoryCard';

//Icon
import straightenGrayCalm from 'assets/icon/straighten/straighten-grayCalm.svg';
import straightenGreenActive from 'assets/icon/straighten/straighten-greenActive.svg';
import straightenMetalgrey from 'assets/icon/straighten/straighten-metalgrey.svg';
import straightenYellowTruck from 'assets/icon/straighten/straighten-yellowTruck.svg';
import localShippingGrayCalm from 'assets/icon/localShipping/localShippingGrayCalm.svg';
import localShippingMentalGray from 'assets/icon/localShipping/localShippingMentalGray.svg';
import localShippingGreenActive from 'assets/icon/localShipping/localShippingGreenActive.svg';
import localShippingYellowTruck from 'assets/icon/localShipping/localShippingYellowTruck.svg';
import directionVehicle from 'assets/icon/arrow/arrowGreen.svg';
import directionVehicleGrey from 'assets/icon/arrow/arrowGrey.svg';
import directionVehicleYellow from 'assets/icon/arrow/arrowYellow.svg';
import inactiveTrailer from 'assets/icon/straighten/inactiveTrailer.svg';
import inactiveVehicle from 'assets/icon/localShipping/inactiveVehicle.svg';

// CSS
import styles from './VehicleMap.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';
import { ContactSupportOutlined, TimelapseRounded } from '@material-ui/icons';

function hasActiveObjectChanged(oldObject, newObject) {
  if (newObject && !oldObject) return true;
  else if (newObject.id !== oldObject.id) return true;
  return false;
}

function hasObjectUpdatedLocation(objectType, oldObject, newObject) {
  if (newObject && !oldObject) return true;
  else if (newObject && oldObject && newObject.id !== oldObject.id) return true;
  return false;
}

function getLngLatArrFromVehicleLocationArr(vehicleLocationArr) {
  if (vehicleLocationArr) {
    const lngLatArr = vehicleLocationArr.map((vehicleLocation) => [vehicleLocation.get('location').longitude, vehicleLocation.get('location').latitude]);
    // if (vehicleLocationArr[0] && vehicleLocationArr[0].get('vehicle') && vehicleLocationArr[0].get('vehicle').get('vehicleLocation') && vehicleLocationArr[0].get('vehicle').get('vehicleLocation').get('location')) {
    //   lngLatArr.push([vehicleLocationArr[0].get('vehicle').get('vehicleLocation').get('location').longitude, vehicleLocationArr[0].get('vehicle').get('vehicleLocation').get('location').latitude]);
    // }
    return lngLatArr;
  }
  return [];
}

const getVehicleLocationsForActiveObject = async (equipmentParseObj, dayMomentObj, hourStart, hourEnd) => {
  // equipmentParseObj = vehicle or trailer object
  // test comment
  deleteVehicleLocationsForState();
  const fromDateValue = moment(dayMomentObj).startOf('day');
  const toDateValue = moment(dayMomentObj).endOf('day');
  if (hourStart) {
    fromDateValue.set('hour', hourStart);
    fromDateValue.set('minute', '00');
  }
  if (hourEnd) {
    toDateValue.set('hour', hourEnd);
    toDateValue.set('minute', '00');
  }

  if (equipmentParseObj?.className?.toLowerCase() === 'vehicle') {
    const filter = {
      fromValue: fromDateValue,
      toValue: toDateValue,
      name: 'dateTime',
      queryType: 'date',
    };
    return await fetchVehicleLocationsForDateRange(equipmentParseObj, filter);
  }

  // otherwise - for trailer
  const filter = {
    fromValue: fromDateValue,
    toValue: toDateValue,
    name: 'devicetime',
    queryType: 'date',
  };
  if (equipmentParseObj?.get('vehicleLocation')?.className === 'AssetLocation') {
    return await fetchAssetLocationsVehicleLocationsForDateRange(equipmentParseObj, filter);
  } else if (equipmentParseObj?.get('vehicleLocation')?.className === 'tc_positions') {
    return await fetchTCPositionsVehicleLocationsForDateRange(equipmentParseObj, filter);
  }
}

function getDefaultActiveVehicleDetailsObj() {
  return {
    vehicleLocation: null,
    type: null,
    object: null,
    linkShare: {},
    activeVehicleHistory: { date: moment(), showPath: false, tripArr: [], showTripStops: true },
    showGPSHistory: false,
    replay: { loading: false, count: 0 },
    activeVehicleFuture: {},
  };
}

function getActiveObjectDetailsObjWithDefaultHistoryProperties(activeObjDetailsObj) {
  return {
    ...activeObjDetailsObj,
    linkShare: {},
    activeVehicleHistory: { date: moment(), showPath: false, tripArr: [], showTripStops: true },
    showGPSHistory: false,
    replay: { loading: false, count: 0 },
  };
}

async function getAddressStringForVehicleLocation(vehicleLocationObj) {
  const address = await getLocalReverseGeocode([[vehicleLocationObj.get('location').latitude, vehicleLocationObj.get('location').longitude]]);
  if (address[0]) {
    return address && address[0] && `${address[0].asciiName}, ${address[0].admin1Code.asciiName}`;
  }
  return '';
}

async function parseThroughVehicleLocationPointsArr(vehicleLocationPointsArr) {
  let hasStarted = false;
  const trips = [];
  let firstStoppedVehicleLocationObj;
  let speedSum = 0;
  let speedCount = 0;
  for (let i = 0; i < vehicleLocationPointsArr.length; i++) {
    const vehicleLocationObj = vehicleLocationPointsArr[i];
    const speedKm = vehicleLocationObj.get('speedKm');
    speedSum += speedKm;
    speedCount += 1;
    if (hasStarted === false && speedKm > 15) {
      speedSum = 0;
      speedCount = 0;
      hasStarted = true;
      firstStoppedVehicleLocationObj = undefined;

      const addressString = await retrieveAndSaveDetailedReverseGeocode(
        vehicleLocationObj?.id,
        vehicleLocationObj?.className,
        vehicleLocationObj?.get('location')?.longitude,
        vehicleLocationObj?.get('location')?.latitude
      );
      trips.push({
        start: {
          vehicleLocationObj,
          addressString,
        },
        end: {
          vehicleLocationObj: undefined,
          addressString: '',
        }
      });
    } else if (hasStarted === true && speedKm < 2.5 && !firstStoppedVehicleLocationObj) {
      firstStoppedVehicleLocationObj = vehicleLocationObj;
    } else if (hasStarted === true && speedKm > 15 && firstStoppedVehicleLocationObj) {
      firstStoppedVehicleLocationObj = undefined;
    } else if (
      firstStoppedVehicleLocationObj &&
      moment(vehicleLocationObj.get('dateTime')).diff(moment(firstStoppedVehicleLocationObj.get('dateTime')), 'minutes') > 10
    ) {
      hasStarted = false;
      trips[trips.length - 1].end.vehicleLocationObj = firstStoppedVehicleLocationObj;

      const addressString = await retrieveAndSaveDetailedReverseGeocode(
        firstStoppedVehicleLocationObj?.id,
        firstStoppedVehicleLocationObj?.className,
        firstStoppedVehicleLocationObj?.get('location')?.longitude,
        firstStoppedVehicleLocationObj?.get('location')?.latitude
      );

      //   const addressString = await Mapbox.getReverseGeocode(
      //     firstStoppedVehicleLocationObj?.get('location')?.longitude, 
      //   firstStoppedVehicleLocationObj?.get('location')?.latitude
      // );
      // const addressString = await getAddressStringForVehicleLocation(firstStoppedVehicleLocationObj);
      trips[trips.length - 1].end.addressString = addressString;

      if (speedCount > 0) {
        const averageSpeedKm = speedSum / (speedCount);
        const hoursDiff = moment(trips[trips.length - 1].end.vehicleLocationObj.get('dateTime')).diff(moment(trips[trips.length - 1].start.vehicleLocationObj.get('dateTime')), 'hours', true);
        if (hoursDiff !== 0) {
          const speedApproxDistanceKm = averageSpeedKm * hoursDiff;
          trips[trips.length - 1].speedApproxDistanceKm = speedApproxDistanceKm;
        }
      }
      speedSum = 0;
      speedCount = 0;
      firstStoppedVehicleLocationObj = undefined;
    } else if (
      i === vehicleLocationPointsArr.length - 1 &&
      trips.length > 0
    ) {
      trips[trips.length - 1].end.vehicleLocationObj = vehicleLocationObj;

      const addressString = await retrieveAndSaveDetailedReverseGeocode(
        vehicleLocationObj?.id,
        vehicleLocationObj?.className,
        vehicleLocationObj?.get('location')?.longitude,
        vehicleLocationObj?.get('location')?.latitude
      );

      // const addressString = await Mapbox.getReverseGeocode(vehicleLocationObj?.get('location')?.longitude, vehicleLocationObj?.get('location')?.latitude);
      // const addressString = await getAddressStringForVehicleLocation(vehicleLocationObj);
      trips[trips.length - 1].end.addressString = addressString;

      if (
        speedCount > 0 &&
        !trips[trips.length - 1].speedApproxDistanceKm // avoid overwriting speedApproxDistanceKm
      ) {
        const averageSpeedKm = speedSum / (speedCount);
        const hoursDiff = moment(trips[trips.length - 1].end.vehicleLocationObj.get('dateTime')).diff(moment(trips[trips.length - 1].start.vehicleLocationObj.get('dateTime')), 'hours', true);
        if (hoursDiff !== 0) {
          const speedApproxDistanceKm = averageSpeedKm * hoursDiff;
          trips[trips.length - 1].speedApproxDistanceKm = speedApproxDistanceKm;
        }
      }
      speedSum = 0;
      speedCount = 0;
    }
  }
  return trips;
}

class VehicleMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: {
        center: [-96.878330, 44.127648],
        mapObject: undefined,
        style: 'street',
        useAlternateGPSMapStyle: false,
        loaded: false,
        token: this.props.Main.mapboxToken,
        zoom: [2],
        townshipCanada: {
          showSelect: isSubscribedToModule('townshipCanadaModule'),
          showLayers: false,
        },
        followObj: {},
      },
      pinging: false,
      addEditGeofence: {
        geofence: null,
        show: false,
      },
      autocompleteSearch: {},
      initialFit: false,
      locationSearch: {},
      popup: undefined,
      activeVehicleHistory: {},
      gpsPointPopup: undefined,
      fitToArr: [],
      replay: {
        loading: false,
        count: 0,
      },
      activeObjectDetails: getDefaultActiveVehicleDetailsObj(),
      showNewGPSHistory: false,
      selectedTrip: undefined, // The currently selected trip when Trip History is open
    };

    this.getLinkShare = this.getLinkShare.bind(this);
    this.handleAutocomplete = this.handleAutocomplete.bind(this);
    this.handleDateFilterChange = this.handleDateFilterChange.bind(this);
    this.getTripArr = this.getTripArr.bind(this);
    // this.handleHourSliderChange = this.handleHourSliderChange.bind(this);
    this.downloadHistoryCsv = this.downloadHistoryCsv.bind(this);
    this.handleInitialFit = this.handleInitialFit.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
    this.pingDriver = this.pingDriver.bind(this);
    this.refreshActiveVehicle = this.refreshActiveVehicle.bind(this);
    this.setPopup = this.setPopup.bind(this);
    this.setGPSPointPopup = this.setGPSPointPopup.bind(this);
    this.toggleGPSHistory = this.toggleGPSHistory.bind(this);
    this.toggleReplay = this.toggleReplay.bind(this);
    this.zoomTo = this.zoomTo.bind(this);
    this.unselectDriver = this.unselectDriver.bind(this);
    this.handleFitToBoundArr = this.handleFitToBoundArr.bind(this);
  }

  async componentWillReceiveProps(nextProps) {
    // NEW STUFF
    if (
      nextProps.activeObject &&
      nextProps.activeObject.object &&
      hasActiveObjectChanged(this.state.activeObjectDetails.object, nextProps.activeObject.object)
    ) {
      // FOR WHEN ACTIVEOBJECT HAS CHANGED (SELECTED A NEW OBJECT, OR CHANGED TO A DIFFERENT OBJECT)
      const activeObj = nextProps.activeObject.object;
      const vehicleLocation = activeObj && nextProps.activeObject.object.get('vehicleLocation');
      const type = nextProps.activeObject.type;
      // Setting activeObjectDetails
      await this.setState({
        ...this.state,
        map: {
          ...this.state.map,
          followObj: {},
        },
        activeObjectDetails: {
          ...getDefaultActiveVehicleDetailsObj(),
          vehicleLocation,
          type,
          object: activeObj,
        },
        selectedTrip: undefined,
        showNewGPSHistory: false,
      });

      // Set Popup
      const popupObj = {};
      popupObj[type.toLowerCase()] = activeObj;
      popupObj.type = type.toLowerCase();
      if (type === 'Vehicle' || type === 'Trailer') {
        // IF THE NEW ACTIVEOBJECT IS A VEHICLE OR TRAILER
        // Preload vehiclelocations (for following among other things)
        getVehicleLocationsForActiveObject(activeObj);

        // Zoom to activeObject
        if (vehicleLocation && vehicleLocation.get('location')) {
          await this.zoomTo(vehicleLocation.get('location').longitude, vehicleLocation.get('location').latitude, 6);
        }

        // Set Popup
        this.setPopup(popupObj);
      } else if (type === 'Geofence') {
        const lat = ((activeObj.get('topLat') - activeObj.get('bottomLat')) / 2) + activeObj.get('bottomLat');
        const lng = ((activeObj.get('rightLong') - activeObj.get('leftLong')) / 2) + activeObj.get('leftLong');
        await this.zoomTo(lng, lat, 12);
        popupObj.coordinates = [lng, lat];
        await this.setPopup(popupObj);
      }
    } else if (
      hasObjectUpdatedLocation('vehicle', this.state.activeObjectDetails.vehicleLocation, nextProps.activeObject && nextProps.activeObject.object && nextProps.activeObject.object.get('vehicleLocation'))
    ) {
      // UPDATING A CURRENT ACTIVEOBJECT
      // Actions: Should zoom to vehicle's new location
      await this.setState({
        ...this.state,
        activeObjectDetails: {
          ...this.state.activeObjectDetails,
          vehicleLocation: nextProps.activeObject.object.get('vehicleLocation'),
        },
      });
      addVehicleLocation(nextProps.activeObject.object.get('vehicleLocation'));
      // await this.zoomTo(nextProps.activeObject.object.get('vehicleLocation').get('location').longitude, nextProps.activeObject.object.get('vehicleLocation').get('location').latitude); // screws up with following - zooms to most recent (not current follow) point
    } else if (
      !nextProps.activeObject?.object &&
      this.props.activeObject &&
      this.props.activeObject?.object
    ) {
      // Deselected
      await this.setState({ ...this.state, activeObjectDetails: getDefaultActiveVehicleDetailsObj() });
      await this.setPopup(undefined);
    }
    // OLD STUFF
    const newState = { ...this.state };
    if (nextProps.addEditGeofence.type && !this.props.addEditGeofence.type) {
      // WENT INTO ADD/EDIT GEOFENCE "MODE"
      // Actions: Show Add/Edit Geofence Component

      // Editing GeofenceList
      newState.addEditGeofence.show = true;
      newState.addEditGeofence.geofence = nextProps.addEditGeofence.geofence;
      if (nextProps.addEditGeofence.geofence) {
        const lat = ((nextProps.addEditGeofence.geofence.get('topLat') - nextProps.addEditGeofence.geofence.get('bottomLat')) / 2) + nextProps.addEditGeofence.geofence.get('bottomLat');
        const lng = ((nextProps.addEditGeofence.geofence.get('rightLong') - nextProps.addEditGeofence.geofence.get('leftLong')) / 2) + nextProps.addEditGeofence.geofence.get('leftLong');
        await this.zoomTo(lng, lat, 12);
      }
      this.setState(newState);
    } else if (!nextProps.addEditGeofence.type && this.props.addEditGeofence.type) {
      // Adding GeofenceList
      newState.addEditGeofence = {
        geofence: null,
        show: false,
      };
      this.setState(newState);
    }

    // if (nextProps && nextProps.VehicleLocation && nextProps.VehicleLocation.vehicleLocations && nextProps.VehicleLocation.vehicleLocations.length > 0) {
    //   const vehicleLocations = nextProps.VehicleLocation.vehicleLocations;
    //   console.log(vehicleLocations.map((vehicleLocation) => vehicleLocation.get('dateTime')));
    // }
  }

  async getLinkShare(type) {
    this.setState({ ...this.state, activeObjectDetails: { ...this.state.activeObjectDetails, linkShare: { loading: true } } });
    let existingShareLocationArr;

    if (this.state.activeObjectDetails.object) {
      // this.props.vehicle can also be a trailer x_x so will support both
      const classNameForQuery = this.state.activeObjectDetails.object.className === 'Vehicle' ? 'ShareVehicleLocation' : 'ShareLocation';
      const activeObjClassNameForQuery = this.state.activeObjectDetails.object.className === 'Vehicle' ? 'vehicle' : 'tcDevice';
      const activeObjForQuery = this.state.activeObjectDetails.object.className === 'Vehicle' ? this.state.activeObjectDetails.object : this.state.activeObjectDetails.object?.get('tc_devices');

      existingShareLocationArr = await Getters.queryCompanyObjects(
        classNameForQuery,
        null,
        null,
        [
          { name: activeObjClassNameForQuery, queryType: 'equalTo', value: activeObjForQuery },
          { name: 'expireAt', queryType: 'greaterThan', value: moment().toDate() }
        ],
        null,
        null,
        null,
        true,
      );
    }

    if (existingShareLocationArr && existingShareLocationArr.length > 0) {
      this.setState({
        ...this.state,
        activeObjectDetails:
        {
          ...this.state.activeObjectDetails,
          linkShare: {
            // link: `${window.location.protocol}//${window.location.host}/share?type=asset&id=${this.state.activeObjectDetails.object.get('tc_devices').id}&s=t&auth=${shareLocationParseObj.id}&distanceUnit=${distanceUnit}`,
            shareLocationParseObj: existingShareLocationArr[0],
          }
        }
      });
    } else {
      if (type && this.state.activeObjectDetails.object && this.state.activeObjectDetails.object.get('tc_devices')) {
        createShareLocation(this.state.activeObjectDetails.object.get('tc_devices'), type).then((shareLocationParseObj) => {
          const distanceUnit = Getters.getPDispatcherPropertyFromState('distanceUnit') || 'km';
          this.setState({
            ...this.state,
            activeObjectDetails:
            {
              ...this.state.activeObjectDetails,
              linkShare: {
                // link: `${window.location.protocol}//${window.location.host}/share?type=asset&id=${this.state.activeObjectDetails.object.get('tc_devices').id}&s=t&auth=${shareLocationParseObj.id}&distanceUnit=${distanceUnit}`,
                shareLocationParseObj,
              }
            }
          });
        });
      } else {
        createShareVehicleLocation(this.state.activeObjectDetails.object).then((shareVehicleLocationParseObj) => {
          const distanceUnit = Getters.getPDispatcherPropertyFromState('distanceUnit') || 'km';
          this.setState({
            ...this.state,
            activeObjectDetails:
            {
              ...this.state.activeObjectDetails,
              linkShare: {
                // link: `${window.location.protocol}//${window.location.host}/share?id=${this.state.activeObjectDetails.object.id}&s=t&auth=${shareVehicleLocationParseObj.id}&distanceUnit=${distanceUnit}`, 
                shareVehicleLocationParseObj
              }
            }
          });
        });
      }
    }
  }

  setPopup(popupObject) {
    this.setState({ ...this.state, popup: popupObject });
  }

  async setGPSPointPopup(enterBool, popupObject) {
    // popupObj: { vehicleLocation }
    if (enterBool) {
      await this.setState({ ...this.state, gpsPointPopup: popupObject });
      getLocalReverseGeocode([[popupObject.vehicleLocation.get('location').latitude, popupObject.vehicleLocation.get('location').longitude]]).then(async (address) => {
        if (address[0]) {
          const addressString = address && address[0] && `${address[0].asciiName}, ${address[0].admin1Code.asciiName}, ${address[0].countryCode}`;
          await this.setState({ ...this.state, gpsPointPopup: { ...this.state.gpsPointPopup, addressString } });
        }
      });
    } else {
      await this.setState({ ...this.state, gpsPointPopup: undefined });
    }
  }

  handleAutocomplete(place) {
    if (place.geometry && place.geometry.location) {
      this.zoomTo(place.geometry.location.lng(), place.geometry.location.lat(), 10);
      this.setState({
        ...this.state,
        locationSearch: place,
        popup: { locationSearch: place, type: 'locationSearch' },
      });
    }
  }

  async handleDateFilterChange(date) {
    await this.setState({
      ...this.state,
      activeObjectDetails: {
        ...this.state.activeObjectDetails,
        activeVehicleHistory: {
          loading: true,
          date: moment(date),
          hourStart: 0,
          hourEnd: 24
        }
      },
      selectedTrip: undefined,
    });
    const newVehicleLocations = await getVehicleLocationsForActiveObject(this.state.activeObjectDetails.object, date, 0, 24);
    const historyObject = await Mapbox.getHistoryDutiesArray(newVehicleLocations, this.state.activeObjectDetails.object);
    await this.handleFitToBoundArr(getLngLatArrFromVehicleLocationArr(historyObject?.legs && historyObject?.legs[0]?.vehicleLocationPointsArr));
    await this.setState({ ...this.state, activeObjectDetails: { ...this.state.activeObjectDetails, activeVehicleHistory: { ...this.state.activeObjectDetails.activeVehicleHistory, loading: false, ...historyObject, showPath: true } } });
    this.getTripArr();
  }

  async getTripArr() {
    let tripArr;
    if (this.state?.activeObjectDetails?.activeVehicleHistory?.legs && this.state?.activeObjectDetails?.activeVehicleHistory?.legs[0]?.vehicleLocationPointsArr.length > 1) {
      tripArr = await parseThroughVehicleLocationPointsArr(this.state.activeObjectDetails.activeVehicleHistory.legs[0].vehicleLocationPointsArr);
    }
    await this.setState({ ...this.state, activeObjectDetails: { ...this.state.activeObjectDetails, activeVehicleHistory: { ...this.state.activeObjectDetails.activeVehicleHistory, tripArr } } });
  }

  // handleHourSliderChange(hourStart, hourEnd) {
  //   this.setState({ ...this.state, activeVehicleHistory: { ...this.state.activeObjectDetails.activeVehicleHistory, hourStart, hourEnd } });
  // }

  handleMapClick(lat, lng) {
    if (this.props.addEditGeofence.type) {
      const newAddEditGeofenceObject = { ...this.state.addEditGeofence };
      newAddEditGeofenceObject.latitude = lat;
      newAddEditGeofenceObject.longitude = lng;
      this.setState({ ...this.state, addEditGeofence: newAddEditGeofenceObject });
    } else {
      this.setPopup(undefined);
    }
  }

  handleInitialFit() {
    // this.handleFitToVehicles();
    this.handleFitToBoundArr(Mapbox.getBoundsFromVehicleParseArr(this.props.vehicles));
    this.setState({ ...this.state, initialFit: true });
  }

  pingDriver() {
    if (this.state.activeObjectDetails.object.get('drivers') && this.state.activeObjectDetails.object.get('drivers').length > 0) {
      this.setState({ ...this.state, pinging: true }, () => {
        pingLocation(this.state.activeObjectDetails.object).then(() => {
          this.setState({ ...this.state, pinging: false });
        }, (error) => {
          console.log(error);
        });
      });
    }
  }

  refreshActiveVehicle() {
    this.forceUpdate();
  }

  async toggleGPSHistory() {
    const showGPSHistory = !this.state.activeObjectDetails.showGPSHistory;
    if (!showGPSHistory) {
      await this.setState({ ...this.state, activeObjectDetails: { ...getActiveObjectDetailsObjWithDefaultHistoryProperties(this.state.activeObjectDetails) }, showNewGPSHistory: false, selectedTrip: undefined });
      this.props.onToggleTripHistory(false);
    } else {
      await this.setState({ ...this.state, activeObjectDetails: { ...this.state.activeObjectDetails, showGPSHistory } });
    }

    if (this.state.map.zoom && this.state.map.zoom[0] > 6) { await this.zoomTo(null, null, 5); }

    if (this.state.activeObjectDetails.showGPSHistory) {
      // const historyObject = await Mapbox.getHistoryDutiesArray(this.props.VehicleLocation.vehicleLocations, this.state.activeObjectDetails.object);
      // await this.setState({ ...this.state, activeObjectDetails: { ...this.state.activeObjectDetails, activeVehicleHistory: { ...this.state.activeObjectDetails.activeVehicleHistory, ...historyObject } } });
      // this.handleFitToBoundArr(getLngLatArrFromVehicleLocationArr(this.state.activeObjectDetails.activeVehicleHistory && this.state.activeObjectDetails.activeVehicleHistory.legs && this.state.activeObjectDetails.activeVehicleHistory.legs[0] && this.state.activeObjectDetails.activeVehicleHistory.legs[0].vehicleLocationPointsArr));
      this.handleDateFilterChange(moment());
    } else {
      if (this.state.activeObjectDetails.object) {
        // Reset vehicleLocations
        await getVehicleLocationsForActiveObject(this.state.activeObjectDetails.object);
      }
      await this.setState({ ...this.state, activeObjectDetails: { ...this.state.activeObjectDetails, activeVehicleHistory: { ...this.state.activeObjectDetails.activeVehicleHistory, date: moment(), hourStart: 0, hourEnd: 24, legs: null, showPath: true, showTripStops: true } } });
    }
  }

  /**
   * Toggles the GPS replay and animates the vehicle's path for the given waypoints
   * 
   * @param {bool} [cancel] - If true, this will cancel the GPS replay 
   */
  async toggleReplay(cancel) {
    if (cancel) return this.setState({ ...this.state, replay: { loading: false, count: 0 } });

    this.handleFitToBoundArr(getLngLatArrFromVehicleLocationArr(this.state.activeObjectDetails?.activeVehicleHistory?.legs?.[0]?.vehicleLocationPointsArr));
    this.setState({ ...this.state, replay: { loading: true, count: 0 } });

    const totalAnimationTimeMs = 10000;
    const originalWaypoints = [].concat(...this.state.activeObjectDetails?.activeVehicleHistory?.legs?.[0]?.waypoints);
    const timePerPoint = totalAnimationTimeMs / originalWaypoints.length;

    let timestampStart;

    const increment = async (timestamp) => {
      const { state } = this;

      if (!state.replay.loading) {
        const _state = { ...state, replay: { loading: false, count: 0 } };
        _state.activeObjectDetails.activeVehicleHistory.legs[0].waypoints = originalWaypoints;
        _state.activeObjectDetails.activeVehicleHistory.legs[0].percentageCompleted = 100;
        this.setState(_state);
      };

      if (!state.activeObjectDetails?.activeVehicleHistory?.legs[0]) {
        return this.setState({ ...this.state, replay: { loading: false, count: 0 } });
      }

      if (!timestampStart) timestampStart = timestamp;
      const timestampDiff = timestamp - timestampStart;

      if (timestampDiff < totalAnimationTimeMs) {
        const iter = Math.floor(timestampDiff / timePerPoint);
        const _state = { ...state, replay: { loading: true, count: state.replay.count + 1 } };
        _state.activeObjectDetails.activeVehicleHistory.legs[0].waypoints = originalWaypoints.slice(0, iter);
        _state.activeObjectDetails.activeVehicleHistory.legs[0].percentageCompleted = iter / originalWaypoints.length * 100;

        this.setState(_state);
        requestAnimationFrame(increment);
      } else {
        const _state = { ...state, replay: { loading: false, count: 0 } };
        _state.activeObjectDetails.activeVehicleHistory.legs[0].waypoints = originalWaypoints;
        this.setState(_state);
      }
    };

    requestAnimationFrame(increment);
  }

  async downloadHistoryCsv() {
    const activeEquipmentInformation = this.props.activeEquipmentInformation;
    const vehicleLocationPointsArr = this.state.activeObjectDetails.activeVehicleHistory.legs[0].vehicleLocationPointsArr;

    const unitId = this.props?.activeEquipmentInformation?.unitId;
    let csvString = `Date/Time,Latitude,Longitude\n`;
    for (let i = 0; i < vehicleLocationPointsArr.length; i++) {
      const vehicleLocationObj = vehicleLocationPointsArr[i];
      csvString += `${moment(vehicleLocationObj.get('dateTime')).format()},`;
      csvString += `"${vehicleLocationObj.get('location').latitude}",`;
      csvString += `"${vehicleLocationObj.get('location').longitude}",`;
      csvString += `\n`;

    }
    createCsvFile(`Unit ${unitId} - Switchboard GPS History`, csvString, true);
  }

  async zoomTo(lng, lat, zoom) {
    if (lng && lat) {
      await this.setState({
        ...this.state,
        map: {
          ...this.state.map,
          center: [lng, lat],
          zoom: (zoom && [zoom]) || this.state.map.zoom,
          fitToArr: null
        }
      });
      // this.props.resetCenterAndZoomTrigger();
    } else if (zoom) {
      await this.setState({
        ...this.state,
        map: {
          ...this.state.map,
          zoom: [zoom],
          fitToArr: null
        }
      });
    }
  }

  async handleFitToBoundArr(lngLatArr) {
    if (lngLatArr && lngLatArr.length > 0) {
      await this.setState({ ...this.state, map: { ...this.state.map, center: null, zoom: null } }); // allows for reset of center/zoom
      await this.setState({ ...this.state, fitToArr: Mapbox.getBoundsFromLngLatArr(lngLatArr) });
    }
  }

  async unselectDriver(zoomOut = true) {
    const { props } = this;
    await props.selectObject();
    if (zoomOut) await this.handleFitToBoundArr(Mapbox.getBoundsFromVehicleParseArr(props.vehicles));
  }

  render() {
    const currentUser = getCurrentUser();
    const userSpecialPermission = getAttribute(currentUser, 'userSpecialPermission');
    // if the company is not granted access to the driver module
    const disableMapSpeed = userSpecialPermission && getAttribute(userSpecialPermission, 'disableMapSpeed');
    return (
      <div className={(this.props.addEditGeofence.type ? styles.addingGeofence : styles.map) + ' flex-grow-1'}>
        {/* New trip history view */}
        {this.state.showNewGPSHistory &&
          <TripHistoryCard
            vehicle={this.state.activeObjectDetails.object}
            vehicleHistory={this.state.activeObjectDetails.activeVehicleHistory}
            isShown={this.state.showNewGPSHistory && this.state.activeObjectDetails.showGPSHistory}
            onSwitchMapStyle={() => {
              this.setState({ ...this.state, map: { ...this.state.map, useAlternateGPSMapStyle: !this.state.map.useAlternateGPSMapStyle } });
            }}
            onDateSelected={this.handleDateFilterChange}
            onReplayRoute={() => this.toggleReplay()}
            onExportCSV={() => this.downloadHistoryCsv()}
            isTripSelectionDisabled={this.state.replay.loading}
            onSelectTrip={async (trip) => {
              if (!trip) {
                const lngLatArr = getLngLatArrFromVehicleLocationArr(this.state?.activeObjectDetails?.activeVehicleHistory?.legs?.[0]?.vehicleLocationPointsArr);
                if (lngLatArr?.length > 0) await this.handleFitToBoundArr(lngLatArr);
                return this.setState({ ...this.state, selectedTrip: undefined });
              }

              const { startLocation, endLocation } = trip;
              const startDateTime = getAttribute(startLocation?.vehicleLocation, 'dateTime', true);
              const endDateTime = getAttribute(endLocation?.vehicleLocation, 'dateTime', true);

              const filteredVehicleLocations = this.state?.activeObjectDetails?.activeVehicleHistory?.legs?.[0]?.vehicleLocationPointsArr?.filter((vehicleLocation) => {
                const vehicleLocationDateTime = getAttribute(vehicleLocation, 'dateTime', true);
                if (vehicleLocationDateTime >= startDateTime && vehicleLocationDateTime <= endDateTime) return true;
              });

              const historyObject = await Mapbox.getHistoryDutiesArray(filteredVehicleLocations);

              const lngLatArr = getLngLatArrFromVehicleLocationArr(historyObject?.legs[0]?.vehicleLocationPointsArr);
              if (lngLatArr?.length > 0) await this.handleFitToBoundArr(lngLatArr);
              this.setState({ ...this.state, selectedTrip: historyObject });
            }}
          />
        }
        {/* New active vehicle/trailer view */}
        {this.props.activeEquipmentInformation &&
          <VehicleTrailerActiveView
            activeVehicleTrailerObj={this.props.activeEquipmentInformation}
            getLinkShare={this.getLinkShare}
            toggleGPSHistory={this.toggleGPSHistory}
            toggleNewGPSHistory={(enableNewGPSHistory) => {
              this.setState({ ...this.state, showNewGPSHistory: enableNewGPSHistory, selectedTrip: enableNewGPSHistory ? this.state.selectedTrip : undefined });
              this.props.onToggleTripHistory(this.state.activeObjectDetails.showGPSHistory && enableNewGPSHistory);
            }}
            showGPSHistory={this.state.activeObjectDetails.showGPSHistory}
            showNewGPSHistory={this.state.showNewGPSHistory}
            zoomToActive={() => {
              if (this.state.map.followObj && this.state.map.followObj.active && this.state.map.followObj.currentPoint) {
                this.zoomTo(this.state.map.followObj.currentPoint[0], this.state.map.followObj.currentPoint[1], 15);
              } else {
                this.zoomTo(this.state.activeObjectDetails.vehicleLocation.get('location').longitude, this.state.activeObjectDetails.vehicleLocation.get('location').latitude, 15);
              }
            }}
            setActiveEquipmentInformation={(equipmentInformation) => {
              this.props.setActiveEquipmentInformation(equipmentInformation)
              if (!equipmentInformation) {
                this.props.selectObject(null);
                this.props.onToggleTripHistory(false);
                this.setState({ ...this.state, showNewGPSHistory: false, selectedTrip: undefined });
              }
            }}
            // unselectDriver={this.unselectDriver}
            isSidebarOpen={this.props.isSidebarOpen}
            activeVehicle={this.state.activeObjectDetails.object}
            activeVehicleHistory={this.state.activeObjectDetails.activeVehicleHistory}
            handleDateFilterChange={this.handleDateFilterChange}
            togglePath={() =>
              this.setState({ ...this.state, activeObjectDetails: { ...this.state.activeObjectDetails, activeVehicleHistory: { ...this.state.activeObjectDetails.activeVehicleHistory, showPath: !this.state.activeObjectDetails.activeVehicleHistory.showPath } } })
            }
            toggleTripStops={() =>
              this.setState({ ...this.state, activeObjectDetails: { ...this.state.activeObjectDetails, activeVehicleHistory: { ...this.state.activeObjectDetails.activeVehicleHistory, showTripStops: !this.state.activeObjectDetails.activeVehicleHistory.showTripStops } } })
            }
            toggleReplay={this.toggleReplay}
            replay={this.state.replay}
            downloadHistoryCsv={this.downloadHistoryCsv}
          />}
        {/* Old active vehicle/trailer view */}
        {/* Can't remove yet since it has GPS history */}
        {/* {this.props.activeObject && this.props.activeObject.type === 'Vehicle' &&
          <ActiveVehicleDetails
            activeVehicle={this.state.activeObjectDetails.object}
            activeVehicleHistory={this.state.activeObjectDetails.activeVehicleHistory}
            getLinkShare={this.getLinkShare}
            handleDateFilterChange={this.handleDateFilterChange}
            zoomToActive={() => {
              if (this.state.map.followObj && this.state.map.followObj.active && this.state.map.followObj.currentPoint) {
                this.zoomTo(this.state.map.followObj.currentPoint[0], this.state.map.followObj.currentPoint[1], 15);
              } else {
                this.zoomTo(this.state.activeObjectDetails.vehicleLocation.get('location').longitude, this.state.activeObjectDetails.vehicleLocation.get('location').latitude, 15);
              }
            }}
            // triggerHourUpdate={() => { this.getVehicleLocationsForActiveObject(this.state.activeObjectDetails.object, this.state.activeObjectDetails.activeVehicleHistory.date, this.state.activeObjectDetails.activeVehicleHistory.hourStart, this.state.activeObjectDetails.activeVehicleHistory.hourEnd); }}
            // handleHourSliderChange={this.handleHourSliderChange}
            togglePath={() =>
              this.setState({ ...this.state, activeObjectDetails: { ...this.state.activeObjectDetails, activeVehicleHistory: { ...this.state.activeObjectDetails.activeVehicleHistory, showPath: !this.state.activeObjectDetails.activeVehicleHistory.showPath } } })
            }
            toggleTripStops={() =>
              this.setState({ ...this.state, activeObjectDetails: { ...this.state.activeObjectDetails, activeVehicleHistory: { ...this.state.activeObjectDetails.activeVehicleHistory, showTripStops: !this.state.activeObjectDetails.activeVehicleHistory.showTripStops } } })
            }
            toggleGPSHistory={this.toggleGPSHistory}
            linkShare={this.state.activeObjectDetails.linkShare}
            pingLocation={() => this.pingDriver()}
            pingError={this.state.pingError}
            pinging={this.state.pinging}
            refreshActiveVehicle={this.refreshActiveVehicle}
            showGPSHistory={this.state.activeObjectDetails.showGPSHistory}
            unselectDriver={this.unselectDriver}
            isSidebarOpen={this.props.isSidebarOpen}
            toggleReplay={this.toggleReplay}
            replay={this.state.replay}
            downloadHistoryCsv={this.downloadHistoryCsv}
          />
        } */}
        {/* {this.props.activeObject && this.props.activeObject.type === 'Trailer' &&
          <ActiveTrailerDetails
            activeVehicle={this.state.activeObjectDetails.object}
            activeVehicleHistory={this.state.activeObjectDetails.activeVehicleHistory}
            getLinkShare={this.getLinkShare}
            handleDateFilterChange={this.handleDateFilterChange}
            zoomToActive={() => {
              if (this.state.map.followObj && this.state.map.followObj.active && this.state.map.followObj.currentPoint) {
                this.zoomTo(this.state.map.followObj.currentPoint[0], this.state.map.followObj.currentPoint[1], 15);
              } else {
                this.zoomTo(this.state.activeObjectDetails.vehicleLocation.get('location').longitude, this.state.activeObjectDetails.vehicleLocation.get('location').latitude, 15);
              }
            }}
            // triggerHourUpdate={() => { this.getVehicleLocationsForActiveObject(this.state.activeObjectDetails.object, this.state.activeObjectDetails.activeVehicleHistory.date, this.state.activeObjectDetails.activeVehicleHistory.hourStart, this.state.activeObjectDetails.activeVehicleHistory.hourEnd); }}
            // handleHourSliderChange={this.handleHourSliderChange}
            togglePath={() => this.setState({ ...this.state, activeObjectDetails: { ...this.state.activeObjectDetails, activeVehicleHistory: { ...this.state.activeObjectDetails.activeVehicleHistory, showPath: !this.state.activeObjectDetails.activeVehicleHistory.showPath } } })}
            toggleTripStops={() =>
              this.setState({ ...this.state, activeObjectDetails: { ...this.state.activeObjectDetails, activeVehicleHistory: { ...this.state.activeObjectDetails.activeVehicleHistory, showTripStops: !this.state.activeObjectDetails.activeVehicleHistory.showTripStops } } })
            }
            toggleGPSHistory={this.toggleGPSHistory}
            linkShare={this.state.activeObjectDetails.linkShare}
            showGPSHistory={this.state.activeObjectDetails.showGPSHistory}
            unselectDriver={this.unselectDriver}
            isSidebarOpen={this.props.isSidebarOpen}
            toggleReplay={this.toggleReplay}
            replay={this.state.replay}
            downloadHistoryCsv={this.downloadHistoryCsv}
          />
        } */}
        {/* {this.props.activeObject && this.state.activeObjectDetails.showGPSHistory && !disableMapSpeed &&
          <TripList
            isSidebarOpen={this.props.isSidebarOpen}
            activeVehicle={this.state.activeObjectDetails.object}
            activeVehicleHistory={this.state.activeObjectDetails.activeVehicleHistory}
          />
        } */}
        {this.props.activeObject && this.state.activeObjectDetails.linkShare.shareLocationParseObj &&
          <LinkShare
            company={this.props.Company.company}
            closeLinkShare={() => this.setState({ ...this.state, activeObjectDetails: { ...this.state.activeObjectDetails, linkShare: {} } })}
            link={this.state.activeObjectDetails.linkShare.link}
            vehicle={this.state.activeObjectDetails.object}
            shareLocationParseObj={this.state.activeObjectDetails.linkShare.shareVehicleLocationParseObj || this.state.activeObjectDetails.linkShare.shareLocationParseObj}
            isSidebarOpen={this.props.isSidebarOpen}
          />
        }
        {this.state.map.token &&
          <div className={styles.map}>
            <div className={styles.mapStyleToggle}>
              <MapStyleToggle activeMapStyle={this.state.map.style} chooseMapStyle={(mapStyle) => this.setState({ ...this.state, map: { ...this.state.map, style: mapStyle } })} />
            </div>
            <div className={styles.fitToVehiclesButton}>
              <FitToVehiclesButton
                fitToVehicles={() => this.handleFitToBoundArr(Mapbox.getBoundsFromVehicleParseArr(this.props.vehicles))}
              />
            </div>
            {this.state.map.townshipCanada.showSelect &&
              <div className={styles.showLSDLinesButton}>
                <ShowLSDLinesButton
                  lsdActive={this.state.map.townshipCanada.showLayers}
                  toggleLSD={() => this.setState({
                    ...this.state,
                    map: {
                      ...this.state.map,
                      townshipCanada: {
                        ...this.state.map.townshipCanada,
                        showLayers: !this.state.map.townshipCanada.showLayers,
                      },
                    },
                  })}
                />
              </div>
            }
            <VehicleMapView
              activeObject={this.props.activeObject}
              activeVehicleHistory={this.state.activeObjectDetails.activeVehicleHistory}
              activeMapStyle={this.state.map.style}
              addEditGeofence={this.props.addEditGeofence}
              center={this.state.map.center}
              displayMarkers={this.props.displayMarkers}
              editGeofence={(geofence) => this.props.setAddEditGeofence('edit', geofence)}
              fitToArr={this.state.fitToArr}
              geofence={this.props.geofence}
              geofences={this.props.geofences}
              gpsPointPopup={this.state.gpsPointPopup}
              handleInitialFit={this.handleInitialFit}
              handleMapClick={this.handleMapClick}
              hide={this.state.hide}
              hidePopup={() => this.setState({ ...this.state, popup: { ...this.state.popup, hide: true } })}
              highlightedObjects={this.props.highlightedObjects}
              initialFit={this.state.initialFit}
              locationSearch={this.state.locationSearch}
              mapLoaded={this.state.map.loaded}
              popup={this.state.popup}
              replay={this.state.replay}
              setActiveFollowObject={(followObj) => this.setState({ ...this.state, map: { ...this.state.map, followObj } })}
              setGPSPointPopup={this.setGPSPointPopup}
              selectVehicle={(type, parseObject) => {
                this.props.selectObject(type, parseObject)
              }}
              setMapLoaded={(mapObject) => this.setState({ ...this.state, map: { ...this.state.map, mapObject, loaded: true } })}
              setPopup={this.setPopup}
              showLSD={this.state.map.townshipCanada.showLayers}
              showGPSHistory={this.state.activeObjectDetails.showGPSHistory}
              useAlternateGPSMapStyle={this.state.map.useAlternateGPSMapStyle}
              style={{ height: '100%', width: '100%' }}
              token={this.state.map.token}
              trailers={this.props.trailers}
              unselectDriver={this.unselectDriver}
              updateZoom={(zoom) => this.setState({ ...this.state, map: { ...this.state.map, zoom: [zoom] } })}
              vehicles={this.props.vehicles}
              zoom={this.state.map.zoom}
              isSidebarOpen={this.props.isSidebarOpen}
              selectedTrip={this.state.selectedTrip}
            />
          </div>
        }
        {this.state.activeObjectDetails.activeVehicleHistory.legs && this.state.activeObjectDetails.activeVehicleHistory.legs.length > 0 &&
          <div className={styles.routeWarning}>Route is interpolated from GPS history</div>
        }

        <div className={`${styles.searchBar} ${(!this.props.isSidebarOpen ? styles.sidebarClosed : '')}`}>
          {window.location.host.indexOf('app-support') === -1 &&
            <SearchBar handleAutocomplete={this.handleAutocomplete} />
          }
        </div>
        {this.state.map.loaded && this.state.addEditGeofence.show &&
          <div className={styles.addEditGeofenceDetails}>
            <AddEditGeofence
              map={this.state.map.mapObject}
              zoom={this.state.map.zoom[0]}
              type={this.props.addEditGeofence.type}
              refreshGeofences={this.props.refreshGeofences}
              geofence={this.state.addEditGeofence.geofence}
              handleClose={() => {
                this.props.setAddEditGeofence();
              }}
              isSidebarOpen={this.props.isSidebarOpen}
            />
          </div>
        }
      </div>
    );
  }
}

VehicleMap.propTypes = {
  addEditGeofence: PropTypes.object.isRequired,
  Main: PropTypes.object.isRequired,
  Company: PropTypes.object.isRequired,
  vehicles: PropTypes.array,
  trailers: PropTypes.array,
  geofences: PropTypes.array,
  center: PropTypes.array,
  highlightedObjects: PropTypes.array,
  activeObject: PropTypes.object,
  VehicleLocation: PropTypes.object.isRequired,
  centerAndZoomTrigger: PropTypes.bool,
  resetCenterAndZoomTrigger: PropTypes.func.isRequired,
  refreshGeofences: PropTypes.func.isRequired,
  setAddEditGeofence: PropTypes.func.isRequired,
  selectObject: PropTypes.func.isRequired,
  updatedVehicle: PropTypes.object,
  displayMarkers: PropTypes.object,
  isMobile: PropTypes.bool,
  isSidebarOpen: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { Company, Main, VehicleLocation } = state;
  return ({
    Company,
    Main,
    VehicleLocation,
  });
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(VehicleMap);
