import { t } from 'api/Translate';
// To Update:

// Getters.queryCompanyObjects(t('HOSRegulation', undefined, undefined, undefined, undefined, undefined, undefined, true).then((HOSRegulationArr) => {
//   const hosRegulationObj = {};
//   console.log(HOSRegulationArr);
//   for (let i = 0; i < HOSRegulationArr.length; i++) {
//     hosRegulationObj[HOSRegulationArr[i].get(t('referenceInt')] = { rule: HOSRegulationArr[i].get(t('rule'), message: HOSRegulationArr[i].get(t('message') };
//   }
//   console.log(hosRegulationObj);
// });

export default {
  0: { rule: t('Start of work shift'), message: t('A driver may not drive without first taking 10 consecutive hours off duty.') },
  1: { rule: t('14-hour period'), message: t('A driver may drive only during a period of 14 consecutive hours after coming on duty following 10 consecutive hours off duty. The driver may not drive after the end of the 14-consecutive-hour period without first taking 10 consecutive hours off duty.') },
  2: { rule: t('Driving time'), message: t('A driver may drive a total of 11 hours during the 14-hour period specified in paragraph (a)(2) of this section.') },
  3: { rule: t('Rest breaks'), message: "Except for drivers who qualify for either of the short-haul exceptions in §395.1(e)(1) or (2), driving is not permitted if more than 8 hours have passed since the end of the drivert('s last off-duty or sleeper-berth period of at least 30 minutes." },
  4: { rule: t('Cycles'), message: t('No motor carrier shall permit or require a driver â€¦ commercial motor vehicles every day of the week.') },
  5: { rule: t('Cycles'), message: t('No motor carrier shall permit or require a driver â€¦ commercial motor vehicles every day of the week.') },
  6: { rule: t('Daily Driving and On-duty Time'), message: t('No motor carrier shall request, require or allow a driver to drive and no driver shall drive after the driver has accumulated 13 hours of driving time in a day.') },
  7: { rule: t('Daily Driving and On-duty Time'), message: t('No motor carrier shall request, require or allow a driver to drive and no driver shall drive after the driver has accumulated 14 hours of on-duty time in a day.') },
  8: { rule: t('Mandatory Off-duty Time'), message: t('No motor carrier shall request, require or allow a driver to drive and no driver shall drive after the driver has accumulated 13 hours of driving time unless the driver takes at least 8 consecutive hours of off-duty time before driving again.') },
  9: { rule: t('Mandatory Off-duty Time'), message: t('No motor carrier shall request, require or allow a driver to drive and no driver shall drive after the driver has accumulated 14 hours of on-duty time unless the driver takes at least 8 consecutive hours of off-duty time before driving again.') },
  10: { rule: t('Mandatory Off-duty Time'), message: t('No motor carrier shall request, require or allow a driver to drive and no driver shall drive after 16 hours of time have elapsed between the conclusion of the most recent period of 8 or more consecutive hours of off-duty time and the beginning of the next period of 8 or more consecutive hours of off-duty time.') },
  11: { rule: t('Daily Off-duty Time'), message: t('A motor carrier shall ensure that a driver takes and the driver shall take at least 10 hours of off-duty time in a day.') },
  12: { rule: t('Daily Off-duty Time'), message: t('The total amount of off-duty time taken by a driver in a day shall include at least 2 hours of off-duty time that does not form part of a period of 8 consecutive hours of off-duty time required by section 13.') },
  13: { rule: t('Cycles'), message: t('Subject to section 28, no motor carrier shall request, require or allow a driver to drive and no driver shall drive unless the driver has taken at least 24 consecutive hours of off-duty time in the preceding 14 days.') },
  14: { rule: t('Cycles'), message: t('Subject to section 28, no motor carrier shall request, require or allow a driver who is following cycle 1 to drive and no driver who is following cycle 1 shall drive after the driver has accumulated 70 hours of on-duty time during any period of 7 days or, if the driver has reset the cycle in accordance with section 28, during the period of the cycle that was ended.') },
  15: { rule: t('Cycles'), message: t('Subject to section 28, no motor carrier shall request, require or allow a driver who is following cycle 2 to drive and no driver who is following cycle 2 shall drive after the driver has accumulated (a) 120 hours of on-duty time during any period of 14 days or, if the driver has reset the cycle in accordance with section 28, during the period of the cycle that was ended; or (b) 70 hours of on-duty time without having taken at least 24 consecutive hours of off-duty time.') },
  16: { rule: t('Oil Well Service Vehicle Permits'), message: t('The permit shall require that the driver take at least 3 periods of off-duty time, each at least 24 hours long, in any period of 24 days, the periods being taken consecutively or separated by on-duty time.') },
  17: { rule: t('Cycles'), message: t('Subject to section 28, no motor carrier shall request, require or allow a driver who is following cycle 2 to drive and no driver who is following cycle 2 shall drive after the driver has accumulated 120 hours of on-duty time during any period of 14 days or, if the driver has reset the cycle in accordance with section 28, during the period of the cycle that was ended.') },
  18: { rule: t('Cycles'), message: t('Subject to section 28, no motor carrier shall request, require or allow a driver who is following cycle 2 to drive and no driver who is following cycle 2 shall drive after the driver has accumulated 70 hours of on-duty time without having taken at least 24 consecutive hours of off-duty time.') },
  10000: { rule: t('Shift Driving'), message: t('Driver drove more than 11 hours in a shift') },
  10040: { rule: t('Shift Duration'), message: t('Driver drove after over 14 hours had elapsed since the end of the last off-duty period of at least 10 hours') },
  10060: { rule: t('30-minute Break'), message: t('Driver drove after over 8 hours had elapsed since the end of the last off-duty period of at least 30 minutes') },
  10150: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for over 60 hours in a 7-day cycle') },
  10250: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for over 70 hours in an 8-day cycle') },
  10400: { rule: t('Shift Driving'), message: t('Driver drove more than 11 hours in a shift') },
  10410: { rule: t('Shift On-Duty'), message: t('Driver was driving after being on-duty for more than 15 hours in a shift') },
  11000: { rule: t('Shift Driving'), message: t('Driver drove more than 15 hours in a shift') },
  11010: { rule: t('Shift On-Duty'), message: t('Driver was on-duty for more than 20 hours in a shift') },
  11150: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for over 70 hours in a 7-day cycle') },
  11250: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for over 80 hours in an 8-day cycle') },
  12000: { rule: t('Shift Driving'), message: t('Driver drove more than 10 hours in a shift') },
  12040: { rule: t('Shift On-Duty'), message: t('Driver was driving after being on-duty for more than 15 hours in a shift') },
  12050: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for over 80 hours in an 8-day cycle') },
  12400: { rule: t('Shift Driving'), message: t('Driver drove more than 12 hours in a shift') },
  12410: { rule: t('Shift On-Duty'), message: t('Driver was driving after being on-duty for more than 15 hours in a shift') },
  13000: { rule: t('Shift Driving'), message: t('Driver drove more than 12 hours in a shift') },
  13010: { rule: t('Shift On-Duty'), message: t('Driver was driving after being on-duty for more than 15 hours in a shift') },
  13150: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for over 70 hours in a 7-day cycle') },
  20000: { rule: t('Shift Driving'), message: t('Driver drove more than 13 hours in a shift') },
  20010: { rule: t('Shift On-Duty'), message: t('Driver was driving after being on-duty for more than 14 hours in a shift') },
  20020: { rule: t('Daily Driving'), message: t('Driver drove more than 13 hours in a day') },
  20030: { rule: t('Daily On-Duty'), message: t('Driver was driving after being on-duty for more than 14 hours in a shift') },
  20040: { rule: t('Shift Duration'), message: t('Driver drove after over 16 hours had elapsed since the end of the last off-duty period of at least 8 hours') },
  20061: { rule: t('Daily Off-Duty'), message: t('Driver took fewer than 2 hours worth of off-duty periods, each at least 30 minutes, excluding the mandatory 8-hour period') },
  20070: { rule: t('Daily Off-Duty'), message: t('Driver had fewer than 10 hours of total off-duty time in a day, in blocks of no less than 30 minutes each') },
  20080: { rule: t('24-hour Break'), message: t('Driver drove after over 14 days had elapsed since the end of the last off-duty period of at least 24 hours') },
  20150: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for over 70 hours in a 7-day cycle') },
  20350: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for over 120 hours in a 14-day cycle') },
  20351: { rule: t('Cycle On-Duty'), message: t('Driver drove after accumulating over 70 hours of on-duty time without taking an off-duty period of at least 24 hours') },
  20580: { rule: t('Oil Well 24-hour breaks'), message: t('Driver took fewer than 3 off-duty periods, each at least 24 hours long, in a period of 24 days') },
  20680: { rule: t('Logger Cycle'), message: t('Driver drove after over 65 driving hours in 7 days cycle') },
  20681: { rule: t('Logger Cycle'), message: t('Driver drove after over 80 on-duty hours in 7 days cycle') },
  21000: { rule: t('Shift Driving'), message: t('Driver drove more than 15 hours in a shift') },
  21010: { rule: t('Shift On-Duty'), message: t('Driver was on-duty for more than 18 hours in a shift') },
  21040: { rule: t('Shift Duration'), message: t('Driver drove after over 20 hours had elapsed since the end of the last off-duty period of at least 8 hours') },
  21150: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for over 80 hours in a 7-day cycle') },
  21350: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for over 120 hours in a 14-day cycle') },
  21351: { rule: t('Cycle On-Duty'), message: t('Driver drove after accumulating over 80 hours of on-duty time without taking an off-duty period of at least 24 hours') },
  100000: { rule: t('Pre-Trip Inspection'), message: t('Driver started driving without completing a pre-trip inspection') },
  14000: { rule: t('Shift Driving'), message: t('Driver drove more than 10 hours in a shift') },
  14040: { rule: t('Shift Duration'), message: t('Driver\'s shift was more than 15 hours long') },
  14150: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for more than 60 hours in a 7-day cycle') },
  14250: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for more than 70 hours in a 8-day cycle') },
  15000: { rule: t('Shift Driving'), message: t('Driver drove more than 15 hours in a shift') },
  15010: { rule: t('Shift On-Duty'), message: t('Driver was on-duty for more than 20 hours in a shift') },
  15150: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for more than 70 hours in a 7-day cycle') },
  15250: { rule: t('Cycle On-Duty'), message: t('Driver was on-duty for more than 80 hours in a 8-day cycle') },
  100001: { rule: t('Odometer'), message: t('Day has no valid odometer readings') },
  100002: { rule: t('Odometer'), message: t('Odometer jump detected: Vehicle was in motion while not connected to the logging system') },
  100004: { rule: t('Pre-Trip Inspection'), message: t('Driver took less than 15 minutes for Pre-Trip Inspection') },
  142069: { rule: t('PC Country Lock'), message: t('Driver switched to PC while they had a trailer/shipment on them') },
  142070: { rule: t('Pre-Trip Inspection'), message: t('Driver started driving without completing a pre-trip inspection or shipment') },
  16970: { rule: t('Pre-Trip Inspection'), message: t('Driver has completed pre-trip inspection in under 15 minutes.') },
};
