import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Glyphicon } from 'react-bootstrap'

// Actions
import {
  fetchTrailersForState,
  deleteTrailersForState,
  disableTrailer,
  enableTrailer,
  deleteTrailer
} from 'actions/Trailer'

// API
import { trailerTypes } from 'api/Lists/EquipmentTypes';
import { getCurrentUser, getResellerCompany } from 'api/Getters';
import * as Helpers from 'api/Helpers';
import { getQueryParameter } from 'api/URL';

// Components
import { Button } from 'primereact/button';
import Title from 'components/LayoutTitle/view/Title'
import FilterForm from 'components/FilterForm/container/FilterForm.new'
import FilterDropdown from 'components/FilterDropdown/view/FilterDropdown'
import AddNewTrailerButton from 'components/AddEditEquipment/container/AddNewTrailerButton'
import TrailersTable from 'components/TrailersTable/container/TrailersTable'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import SubNavigationBar from '../../components/Shared/SubNavigationBar/SubNavigationBar'

// Layout
import TrailerTemperatureLayout from 'layouts/EquipmentLayout/TrailerTemperatureLayout';

// CSS
import styles from './TrailersLayout.module.scss'
import StoreContext from 'contexts/StoreContext'

class TrailersLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayFilterForm: true,
      filter: [],
      sort: { unitId: 'descending' },
      activeFilter: true,
      selectedTab: 0,
      trailerTypes: [
        { attrName: '0', placeholder: 'Active Trailers', enabled: true },
        { attrName: '1', placeholder: 'Inactive Trailers', enabled: false }
      ],
    }

    this.state.filterFormFields = [
      {
        attrName: 'nextInspectionDate',
        queryType: 'date',
        dateQueryType: 'lessThanOrEqualTo',
        fullName: 'To',
        placeholder: '.....',
        type: 'date'
      },
      {
        attrName: 'nextInspectionDate',
        queryType: 'date',
        dateQueryType: 'greaterThanOrEqualTo',
        fullName: 'From',
        placeholder: '.....',
        type: 'date'
      },
      {
        attrName: 'notes',
        fullName: 'Notes',
        placeholder: '.....',
        type: 'text'
      },
      {
        attrName: 'type',
        fullName: 'Type',
        type: 'select',
        selectArr: trailerTypes
      },
      {
        attrName: 'unitId_lowercase',
        fullName: 'Unit ID',
        placeholder: '.....',
        type: 'text'
      }
    ]

    this.disableTrailer = this.disableTrailer.bind(this)
    this.enableTrailer = this.enableTrailer.bind(this)
    this.deleteTrailer = this.deleteTrailer.bind(this)
    this.filter = this.filter.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.scrollHandler = this.scrollHandler.bind(this)
    this.sortHandler = this.sortHandler.bind(this)
    this.handlePopup = this.handlePopup.bind(this)
  }

  async componentDidMount() {
    document.title = 'Trailers - Switchboard'
    const clientHeight = document.documentElement.clientHeight
    this.trailersTableHeight = document.documentElement.clientHeight * 0.7 // 70vh defined by CSS
    if (clientHeight <= 1366) {
      // laptop size
      this.trailersTableHeight = clientHeight * 0.6
    }

    const resellerCompany = await getResellerCompany();
    this.setState({ ...this.state, resellerCompany });

    this.trailersTableRowAmount = Math.round(this.trailersTableHeight / 43) + 5 // amount of el's to display
    this.pageCount = 0 // result fetch multiplier
    fetchTrailersForState(
      this.pageCount,
      this.trailersTableRowAmount,
      [],
      this.state.sort
    );
  }

  componentWillUnmount() {
    deleteTrailersForState()
  }

  componentWillReceiveProps(nextProps) {
    let tempView = getQueryParameter(nextProps.location.search, 't');
    const tabs = ['', 'temperature'];
    this.setState({ ...this.state, selectedTab: tabs.indexOf(tempView) !== -1 ? tabs.indexOf(tempView) : 0 });
  }

  disableTrailer(confirmBool, trailerParseObj) {
    const newState = { ...this.state }
    this.setState(newState)
    if (confirmBool) {
      disableTrailer(trailerParseObj)
    }
  }

  enableTrailer(confirmBool, trailerParseObj) {
    const newState = { ...this.state }
    this.setState(newState)
    if (confirmBool) {
      enableTrailer(trailerParseObj)
    }
  }

  deleteTrailer(confirmBool, trailerParseObj) {
    const newState = { ...this.state }
    this.setState(newState)
    if (confirmBool) {
      deleteTrailer(trailerParseObj)
    }
  }

  filter(filterArr) {
    this.pageCount = 0
    const newState = {
      ...this.state,
      filter: filterArr.concat({
        name: 'enabled',
        queryType: 'equalTo',
        value: this.state.activeFilter
      })
    }
    this.setState(newState)
    deleteTrailersForState().then(() => {
      fetchTrailersForState(
        this.pageCount,
        this.trailersTableRowAmount,
        newState.filter,
        this.state.sort,
        true
      )
    })
  }

  clearFilter() {
    this.pageCount = 0
    this.setState({ ...this.state, filter: [] })
    deleteTrailersForState().then(() => {
      fetchTrailersForState(
        this.pageCount,
        this.trailersTableRowAmount,
        [],
        this.state.sort
      )
    })
  }

  scrollHandler(e) {
    if (
      !this.props.Trailer.trailers ||
      Helpers.scrolledToBottom(
        e,
        10,
        this.lastScrollPos,
        this.trailersTableRowAmount,
        this.pageCount,
        this.props.Trailer.trailers.length
      )
    ) {
      this.pageCount++
      fetchTrailersForState(
        this.pageCount,
        this.trailersTableRowAmount,
        this.state.filter,
        this.state.sort,
        this.state.tableView
      )
      this.lastScrollPos = e.target.scrollTop
    }
  }

  sortHandler(attribute) {
    // sort list of trailers based on attribute
    deleteTrailersForState().then(() => {
      this.pageCount = 0
      const newState = { ...this.state }
      const currentSort = this.state.sort[attribute] // the current sort, if defined
      if (currentSort) {
        if (currentSort === 'ascending') {
          newState.sort[attribute] = 'descending'
        } else {
          newState.sort[attribute] = 'ascending'
        }
      } else if (attribute === 'unitId') {
        newState.sort = { unitId: 'ascending' }
      } else if (attribute === 'vendor_name') {
        newState.sort = { vendor_name: 'ascending' }
      } else if (attribute === 'user_fullName') {
        newState.sort = { user_fullName: 'ascending' }
      } else if (attribute === 'nextInspectionDate') {
        newState.sort = { nextInspectionDate: 'ascending' }
      } else if (attribute === 'tc_devices_uniqueid') {
        newState.sort = { tc_devices_uniqueid: 'ascending' }
      }
      this.setState({ ...this.state, sort: newState.sort })
      fetchTrailersForState(
        this.pageCount,
        this.trailersTableRowAmount,
        this.state.filter,
        newState.sort,
        this.state.tableView
      )
    })
  }

  handlePopup() {
    this.setState({ ...this.state, showPopup: true, showDrawer: false })
  }

  render() {
    const userSpecialPermission = getCurrentUser(true).userSpecialPermission;
    const disableEquipmentAddEquipment = userSpecialPermission && userSpecialPermission.disableEquipmentAddEquipment;

    let arrow;
    if (this.state.showDrawer) {
      arrow = (
        <Glyphicon
          glyph='glyphicon glyphicon-exclamation-sign'
          onClick={this.handlePopup}
        />
      )
      // mainListItemStyle = styles.mainListItem;
    }

    let navItems = [
      {
        name: 'Trailers',
        className: 'col-4',
        onClick: () => {
          this.setState({ ...this.state, selectedTab: 0 });
        },
        isActive: this.state.selectedTab === 0,
        to: '?view=trailers',
      },
      {
        name: 'Temperature',
        className: 'col-4',
        onClick: () => {
          this.setState({ ...this.state, selectedTab: 1 });
        },
        isActive: this.state.selectedTab === 1,
        to: '?view=trailers&t=temperature',
      },
    ];

    return (
      <div className={styles.trailersLayout}>
        <MDBContainer className={'mw-100 pb-0 ' + styles.vehiclesLayout}>
          <MDBRow>
            <MDBCol xs='12' lg='6'>
              <SubNavigationBar navItems={navItems} />
            </MDBCol>
            {!this.state.resellerCompany &&
              <MDBCol xs='12' lg='6'>
                <a
                  href={'https://ca.shop.onswitchboard.com/collections/all/products/trailer-tracking-device'}
                  target="_blank"
                >
                  <Button
                    label="Adding more trailers to your fleet? Click here to order!"
                    className="p-button-sm p-button-secondary p-button-raised"
                    icon="pi pi-external-link"

                  />
                </a>
              </MDBCol>
            }
          </MDBRow>
          {this.state.selectedTab === 0 &&
            <div>
              <Title title='Trailers'>
                <MDBRow className='d-flex flex-row-reverse'>
                  <MDBCol xs='12' lg='4' className='my-auto'>
                    {!disableEquipmentAddEquipment &&
                      <AddNewTrailerButton className='float-right mx-1' />
                    }
                    <FilterDropdown
                      id='trailerActiveInactiveFilter'
                      className='float-right mx-1'
                      title='Active Trailers'
                      handleFilter={trailerTypeIndex => {
                        this.setState(
                          {
                            ...this.state,
                            activeFilter: this.state.trailerTypes[trailerTypeIndex]
                              .enabled
                          },
                          () => {
                            this.filter([])
                          }
                        )
                      }}
                      menuItems={this.state.trailerTypes}
                    />
                  </MDBCol>
                  <MDBCol xs='12' lg='8'>
                    {this.state.displayFilterForm && (
                      <span>
                        <FilterForm
                          className='floatRight'
                          handleFilter={this.filter}
                          clearFilter={this.clearFilter}
                          fields={this.state.filterFormFields}
                        />
                        <div className='clearBoth' />
                      </span>
                    )}
                  </MDBCol>
                </MDBRow>
              </Title>
              <TrailersTable
                Trailer={this.props.Trailer}
                disableTrailer={this.disableTrailer}
                enableTrailer={this.enableTrailer}
                deleteTrailer={this.deleteTrailer}
                scrollHandler={this.scrollHandler}
                sortHandler={this.sortHandler}
                sort={this.state.sort}
              />
            </div>
          }
          {this.state.selectedTab === 1 &&
            <TrailerTemperatureLayout
              location={this.props.location}
            />
          }
        </MDBContainer>
      </div>
    )
  }
}

TrailersLayout.propTypes = {
  Trailer: PropTypes.object.isRequired,
  location: PropTypes.object,
}

const mapStateToProps = state => {
  const { Trailer } = state
  return {
    Trailer
  }
}

export default connect(mapStateToProps, null, null, { context: StoreContext })(TrailersLayout);

