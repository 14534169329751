import React from 'react';
import PropTypes from 'prop-types';
import history from "sbHistory";
import moment from 'moment-timezone';

// Actions
import { assignDriversToVehicleForState } from 'actions/Vehicle';

// API
import { getCurrentUser } from 'api/Getters';
import * as Helpers from 'api/Helpers';
import { getLiveEDCsForVehicles } from 'api/Equipment/Vehicle.old';

// Components
import LazyLoadingTable from 'components/LazyLoadingTable/view/LazyLoadingTable';
import Message from 'sbCore/Message/Message';
import SortButton from 'components/SortButton/view/SortButton';
import DeleteXButton from 'components/ConfirmModal/container/DeleteXButton';
import WalkthroughSelectTip from 'components/WalkthroughSelectTip/container/WalkthroughSelectTip';
import Alert from 'components/Alert/view/Alert';
import WeighStationHelpDialog from 'components/WeighStationHelpDialog/view/WeighStationHelpDialog';

// CSS
import styles from './VehiclesTable.module.scss';
import { MDBBtn, MDBIcon, MDBTooltip } from 'mdbreact';

class VehiclesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      walkthroughState: {
        aboutPreferences: false,
      },
      edcs: [],
    };
    this.assignDriversToVehicle = this.assignDriversToVehicle.bind(this);
    this.toggleWalkthoughState = this.toggleWalkthoughState.bind(this);
    this.getEDCs = this.getEDCs.bind(this);
  }

  componentDidMount() {
    this.getEDCs(this.props.Vehicle.vehicles);
  }


  getEDCs(vehicles) {
    getLiveEDCsForVehicles(vehicles).then((edcs) => {
      this.setState({ ...this.state, edcs });
    }).catch((error) => {
      console.log('Error getting EDCs:');
      console.log(error);
    });
  }

  assignDriversToVehicle(vehicleParseObj, driversObj) {
    const drivers = [driversObj.mainDriver];
    if (driversObj.coDriver) {
      drivers.push(driversObj.coDriver);
    }
    assignDriversToVehicleForState(vehicleParseObj, drivers);
  }

  toggleWalkthoughState(walkthroughStateAttribute) {
    const newState = { ...this.state };
    newState.walkthroughState = { ...this.state.walkthroughState };
    newState.walkthroughState[walkthroughStateAttribute] = !this.state.walkthroughState[walkthroughStateAttribute];
    this.setState(newState);
  }

  render() {
    const { Vehicle, company, sort, sortHandler, isLoading, isLazyLoading, handleLazyLoad, height, isGroupView } = this.props;
    const { walkthroughState } = this.state;

    const vehicles = Vehicle.vehicles;

    const userSpecialPermission = getCurrentUser(true).userSpecialPermission;
    const disableEquipmentDeleteEquipment = userSpecialPermission && userSpecialPermission.disableEquipmentDeleteEquipment;

    const columnAdjustStyle = { width: '15%' };
    const vehiclesHeaderRows = [{
      key: 'header',
      columns: [
        {
          element: (
            <div>
              Unit ID
              <SortButton
                clickHandler={() => sortHandler('unitId')}
                isActive={sort.unitId}
              />
            </div>
          ),
          props: { style: columnAdjustStyle },
        },
        {
          element: (
            <div style={{ marginLeft: '3em' }}>
              Last Location
            </div>
          ),
          props: { style: { ...columnAdjustStyle, width: '25%' } },
        },
        // {
        //   element: (
        //     <div>
        //       Weigh Station Bypass
        //       <div className="centerText" style={{ position: 'relative' }}>
        //         <WeighStationHelpDialog buttonStyle={{ top: '.15em', marginLeft: '.8em', backgroundColor: 'rgba(0, 0, 0, .25)' }} />
        //       </div>
        //     </div>
        //   ),
        //   props: { style: columnAdjustStyle },
        // },
        { element: <div>Notes</div>, props: { style: { ...columnAdjustStyle, width: '20%' } } },
        {
          element: (
            <div>
              {/* Next MVI Inspection
              <SortButton
                clickHandler={() => sortHandler('nextInspectionDate')}
                isActive={sort.nextInspectionDate}
              /> */}
            </div>
          ),
          props: { style: { ...columnAdjustStyle, width: '20%' } },
        },
        { element: <div />, props: { style: { width: '20%' } } },
        {
          element: (
            <div />
          ),
          props: { style: { ...columnAdjustStyle, width: '20%' } },
        },
      ],
    }];

    let vehiclesBodyRows = [];

    if (vehicles.length > 0) {
      // check ex. if from chid company
      const currentCompanyId = getCurrentUser().get('belongsToCompany').id;

      vehiclesBodyRows = vehicles.filter(vehicle => vehicle !== undefined).map((vehicle, index) => {
        const unitId = vehicle.get('unitId');
        const nextInspectionDate = vehicle.get('nextInspectionDate');
        const weighStationBypassEnabled = (vehicle.get('weighStationBypassVehicle') && (vehicle.get('weighStationBypassVehicle').get('enabled')) && (vehicle.get('weighStationBypassVehicle').get('isSubscriptionProduction'))) ? 'Enabled' : 'Disabled';
        let vEDCs = null;
        if ((this.state.edcs && this.state.edcs.length > 0) || (this.props.edcs && this.props.edcs.length > 0)) {
          const edcArr = (this.state.edcs && this.state.edcs.length > 0) ? this.state.edcs : this.props.edcs
          vEDCs = edcArr.filter((value, index, arr) => value.get('vehicle').get('unitId') === vehicle.get('unitId')).map((edc) => [edc.get('malfunctionCode')]);
        }
        const notes = vehicle.get('notes');
        const enabled = vehicle.get('enabled');

        const isFromAssociatedCompany = currentCompanyId !== (vehicle && vehicle.get('belongsToCompany') && vehicle.get('belongsToCompany').id);

        return ({
          key: vehicle.id,
          props: {
            id: vehicle.id,
            selectable: false,
          },
          columns: [
            {
              props: { style: columnAdjustStyle },
              element: (
                <div>
                  <a onClick={() => history.push({ pathname: 'vehicle', search: "vehicle=" + vehicle.id })}>
                    <div className="d-inline-block font-weight-500">{unitId}</div>
                    <div className={styles.vehicleType}>{vehicle.get('type')}</div>
                  </a>
                  {isFromAssociatedCompany &&
                    <MDBTooltip className={'d-inline-block'}
                      placement="right"
                      tag="div"
                      tooltipContent={`Associated Company: ${vehicle.get('belongsToCompany').get('name')}`}>
                      <MDBIcon icon="network-wired" className="ml-3" />
                    </MDBTooltip>
                  }
                </div>
              ),
            },
            {
              props: { style: { ...columnAdjustStyle, width: '25%' } },
              element: (
                <div>
                  {vehicle && vehicle?.get('vehicleLocation') && !Helpers.isZeroVehicleLocation(vehicle?.get('vehicleLocation')) &&
                    (
                      <div>
                        <a
                          href={Helpers.isSubscribedToModule('mapModule') ? `/map` : `https://maps.google.com/maps?q=${vehicle?.get('vehicleLocation')?.get('location')?.latitude},${vehicle?.get('vehicleLocation')?.get('location')?.longitude}&t=h&iwd=0&z=18`}
                          target="_blank"
                        >
                          <table style={{ width: '100%' }}><tr>
                            <td width="20%">
                              {moment(vehicle?.get('vehicleLocation').get('dateTime')).isAfter(moment().subtract(15, 'minutes')) &&
                                <i className="pi pi-circle-fill" style={{ color: 'green' }}></i>
                              }
                            </td>
                            <td width="80%">
                              <div><b>{`${vehicle?.get('vehicleLocation')?.get('aprxShortName')}, ${vehicle?.get('vehicleLocation')?.get('stateProvince')?.toUpperCase()}`}</b></div>
                              <div>{`${vehicle?.get('vehicleLocation')?.get('location')?.latitude?.toFixed(4)}, ${vehicle?.get('vehicleLocation')?.get('location')?.longitude?.toFixed(4)}`}</div>
                            </td>
                          </tr></table>
                        </a>
                      </div>
                    )
                  }
                </div>
              ),
            },
            // {
            //   props: { style: columnAdjustStyle },
            //   element: <div>{weighStationBypassEnabled}</div>,
            // },
            {
              props: { style: { ...columnAdjustStyle, width: '20%' } },
              element: <div><textarea className={styles.notes} value={notes || ''} placeholder="Vehicle Notes..." readOnly /></div>,
            },
            {
              props: { style: { ...columnAdjustStyle, width: '20%' } },
              element: (
                <div>
                  {!nextInspectionDate &&
                    (
                      <a
                        onClick={() => history.push({ pathname: 'vehicle', search: "vehicle=" + vehicle.id })}
                      >
                        <Message
                          severity="warn"
                          text={(
                            <p className="mb-0 ml-1">
                              No MVI Inspection
                            </p>
                          )}
                        />
                      </a>
                    )
                  }
                  {(nextInspectionDate && moment(nextInspectionDate).subtract(1, 'months').isBefore(moment())) &&
                    (
                      <Message
                        onClick={() => history.push({ pathname: 'vehicle', search: "vehicle=" + vehicle.id })}
                        severity="warn"
                        text={(
                          <p className="mb-0 ml-1">
                            MVI Expired
                          </p>
                        )}
                      />
                    )
                  }

                </div>
              ),
            },
            {
              props: { style: { width: '20%', whiteSpace: 'normal' } },
              element: <div>
                {vEDCs &&
                  vEDCs.length > 0 &&
                  (
                    <a
                      onClick={this.props.handleDiagnosticsClick}
                    >
                      <Message
                        handleClick={this.props.handleDiagnosticsClick}
                        severity="warn"
                        text={(
                          <p className="mb-0 ml-1">
                            {`${vEDCs.length} ${vEDCs.length > 1 ? 'DTC codes' : 'DTC code'}`}
                          </p>
                        )}
                      />
                    </a>
                  )
                }
              </div>,
            },
            {
              props: {
                style: {
                  ...columnAdjustStyle,
                  width: '20%',
                  textAlign: 'right',
                },
              },
              element: (
                <div>
                  <MDBBtn
                    size="sm"
                    onClick={() => history.push({ pathname: 'vehicle', search: "vehicle=" + vehicle.id })}
                    color="secondary"
                  >Preferences</MDBBtn>
                  {(isGroupView || (!disableEquipmentDeleteEquipment && enabled)) &&
                    <div style={{ display: 'inline-block', marginLeft: '1em', cursor: 'pointer' }}><DeleteXButton handleChoice={(confirmBool) => this.props.disableVehicle(confirmBool, vehicle)} /></div>
                  }
                </div>
              ),
            },
          ],
        });
      });
    } else {
      vehiclesBodyRows = [{
        key: 'norows',
        props: {
          selectable: false,
        },
        columns: [{ element: <div>No vehicles matching the filter. To add vehicles click the <b>Add New Vehicle</b> button</div>, props: { colSpan: 420 } }],
      }];
    }

    return (
      <div>
        {company && !company.get('disableWalkthrough') &&
          <WalkthroughSelectTip
            showBoxTop
            handleToggle={() => this.toggleWalkthoughState('aboutPreferences')}
            expand={walkthroughState.aboutPreferences}
            toolTipStyle={{ top: '-16em' }}
            style={{ top: '16em', right: '19em' }}
          >
            <div>
              Click <b>Preferences</b> for one of your vehicles below to edit information and take advantage of features such as Weigh Station/Scale Bypassing
              <br /><br />
              To disable these tips, go to <b>Walkthrough Tips</b> under your <a onClick={() => history.push('/settings')} style={{ cursor: 'pointer' }}>settings</a>
            </div>
          </WalkthroughSelectTip>
        }
        <LazyLoadingTable
          height={height}
          tableHeaderRows={vehiclesHeaderRows}
          tableBodyRows={vehiclesBodyRows}
          selectable={false}
          multiSelectable={false}
          enableSelectAll={false}
          showCheckboxes={false}
          handleLazyLoad={handleLazyLoad}
          isLoading={isLoading}
          isLazyLoading={isLazyLoading}
          lazyLoadScrollOffset={300}
        />
      </div>
    );
  }
}

VehiclesTable.propTypes = {
  Vehicle: PropTypes.object.isRequired,
  company: PropTypes.object,
  disableVehicle: PropTypes.func.isRequired,
  sortHandler: PropTypes.func,
  sort: PropTypes.object,
  isLoading: PropTypes.bool,
  isLazyLoading: PropTypes.bool,
  height: PropTypes.any,
  handleLazyLoad: PropTypes.func,
  handleDiagnosticsClick: PropTypes.func,
  isGroupView: PropTypes.bool,
};

export default VehiclesTable;
