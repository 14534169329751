import './style.scss';

/**
 * @description A small title label to put above some form field
 * @param {String} className - Custom container className
 * @param {Any} children - Anything you want to display the label as. Test, div with text, etc
 */
function InputLabel({ warning, error, ...props }) {

  // ** Misc ** //
  let className = 'sbc-input-label';
  if (props.className) className += ` ${props.className}`;

  if (warning) {
    className += ` sbc-warning-text`;
  }

  if (error) {
    className += ` sbc-error-text`;
  }

  return (
    <div className={className}>
      <div className="input-label">
        { props.children }
      </div>
    </div>
  );
}

export default InputLabel;
