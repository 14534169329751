import Parse from 'parse';

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { Paper } from 'material-ui';

// API
import * as Getters from 'api/Getters';
import * as Analytics from 'api/Analytics';
import { getAttribute, getCurrentUser } from 'api/Parse';
import {
  getCompanyAnalytics,
  updateCompanyAnalytics,
} from 'api/CompanyAnalytics/CompanyAnalytics';
import history from 'sbHistory';

// Components
import HomeHelpDialog from './HomeHelpDialog';
import DriversOutOfDateSoftwareCard from 'components/TroubleshootingCards/DriversOutOfDateSoftwareCard';
import StuckOdometerDriversCard from 'components/TroubleshootingCards/StuckOdometerDriversCard';
import StuckTabletDataCard from 'components/TroubleshootingCards/StuckTabletDataCard';

// CSS
import styles from './HomeLayout.module.scss';

// Assets
import colourLogo from 'assets/images/colour-logo-inline.png';

// Context
import StoreContext from 'contexts/StoreContext';
const googlePlay = require('assets/images/googleplay.png');

class HomeLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activated: null,
    };
  }

  async componentDidMount() {
    document.title = 'Home - Switchboard Transportation Technology';

    // Check company analytics status
    const companyAnalytics = await getCompanyAnalytics();
    const lastSyncUserPropertiesFromCompanyDate = getAttribute(companyAnalytics, 'lastSyncUserPropertiesFromCompanyDate');
    if (!lastSyncUserPropertiesFromCompanyDate || moment(lastSyncUserPropertiesFromCompanyDate).isBefore(moment().subtract(1, 'month'))) {
      Analytics.syncUserPropertiesFromCompany(getCurrentUser().id);
    }
    const VIEWHOME = getAttribute(companyAnalytics, 'VIEWHOME');
    if (!VIEWHOME) {
      Analytics.track('HomeLayout Viewed');
      await updateCompanyAnalytics(companyAnalytics, { VIEWHOME: true });
    }

    Getters.queryCompanyObjects('Driver', undefined, undefined, { type: 'equalTo', name: 'enabled', value: true }, undefined, undefined, true).then((driverObj) => {
      // this.setState({ ...this.state, activated: false });
      if (!driverObj) {
        this.setState({ ...this.state, activated: false });
      } else {
        this.setState({ ...this.state, activated: true });
      }
    });

    Analytics.identifyOnceForCompany('VIEWHOME', {
      'HomeLayout Viewed': true,
      'HomeLayoutViewDate': moment().toISOString(),
    });

    // If they only have IFTA Free, start at IFTA
    const subscription = this.props.Subscription.subscription;
    if (subscription.get('iftaFree') && !subscription.get('iftaModule')) {
      history.push('/ifta');
    }
  }

  async resellerLogoToCookie() {
    if (this.props.Company && this.props.Company.company) {
      const promoCode = this.props.Company.company.get('promoCode');
      if (promoCode) {
        const resellerCompany = await Getters.getResellerCompany();
        if (resellerCompany) {
          const resellerLogoUrl = resellerCompany?.get('logo')?.url();
          const oneYear = moment().add(1, 'years').toISOString();
          if (resellerLogoUrl) {
            document.cookie = `SwitchboardResellerLogoURL=${resellerLogoUrl};expires=${oneYear};path=/`;
          }
          return;
        }
      }
    }
    const yesterday = moment().subtract(1, 'days').toISOString();
    document.cookie = `SwitchboardResellerLogoURL=;expires=${yesterday};path=/`;
  }

  companyLogoToCookie() {
    if (this.props.Company && this.props.Company.company && this.props.Company.company.get('logo')) {
      const oneYear = moment().add(1, 'years').toISOString();
      document.cookie = `SwitchboardCompanyLogoURL=${this.props.Company.company.get('logo').url()};expires=${oneYear};path=/`;
    } else {
      // delete cookie
      const yesterday = moment().subtract(1, 'days').toISOString();
      document.cookie = `SwitchboardCompanyLogoURL=;expires=${yesterday};path=/`;
    }
  }

  render() {
    const companyName = Object.keys(this.props.Company.company).length > 0 ? this.props.Company.company.get('name') : '';
    const promoCode = this?.props?.Company?.company?.get('promoCode');
    if (this.props.Company.company && this.props.Company.company.id) {
      this.companyLogoToCookie();
      this.resellerLogoToCookie();
    }
    return (
      <div className={`${styles.homeLayout} translate-me`}>
        {window.location.host.indexOf('app-support') !== -1 &&
          (
            <div style={{ margin: '1em' }}>
              <StuckOdometerDriversCard />
              <StuckTabletDataCard />
              <DriversOutOfDateSoftwareCard />
              {/* <StuckAssetTrackersCard /> */}
              {/* <StuckDashcamDataCard /> */}
            </div>
          )
        }


        <div className={styles.poweredBy}>
          {this.state.activated === false
            && !promoCode
            && (
              <Paper zDepth={1} className={styles.startup}>
                <div className={styles.title}>Welcome to Switchboard!</div>
                <div>
                  When you're ready try out Switchboard with your drivers, you can you do one of the following:
                </div>
                <div className={styles.options}>
                  <table><tbody>
                    <tr>
                      <td>Get Switchboard on your smartphone</td>
                      <td>
                        <a href="https://play.google.com/store/apps/details?id=com.onswitchboard.eld" target="_blank">
                          <img alt="Get it on Google Play" src={googlePlay} />
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Use the Switchboard Enterprise Package</td>
                      <td>
                        <b><a href="mailto:sales@onswitchboard.com">Email us</a> for a trial device</b>
                      </td>
                    </tr>
                  </tbody></table>
                  <span />
                </div>
              </Paper>
            )}

          <div>
            <div className={styles.backdrop}>
              <table><tbody><tr>
                <td className={`${styles.company} notranslate`}><div className={styles.companyName}>{companyName}</div></td>
                <td>powered by</td>
                <td className={styles.logo}><img role="presentation" src={colourLogo} /></td>
              </tr></tbody></table>

              <div className={styles.helpSection}>
                <HomeHelpDialog
                  buttonStyle={{
                    backgroundColor: '#33b5e5 !important',
                    width: 'auto !important',
                    height: 'auto !important',
                    padding: '0.5em 1em',
                    cursor: 'pointer',
                    borderRadius: '0.5em',
                  }}
                  buttonChildren={(
                    <div style={{ position: 'relative', fontSize: '1.3em' }}>
                      Need Help? Click here!
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

HomeLayout.propTypes = {
  Company: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Company, Subscription } = state;
  return {
    Company,
    Subscription
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(HomeLayout);
