import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "../../../sbHistory";

// API
import * as SI from 'api/DriverPatterns/SpeedingIdling';
import { convertDistance, formatDurationString } from 'api/Helpers';

// Components
import Title from 'components/LayoutTitle/view/Title';
import LazyLoadingTable from 'components/LazyLoadingTable/view/LazyLoadingTable';

import SpeedIdlingGraph from './SpeedIdlingGraph';

import { Card, CardActions, CardHeader, CardMedia, CardTitle, CardText } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';

class DriverSpeedViolationsTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.speedingViolations !== this.props.speedingViolations) {
      // this.createspeedIdlingObject(nextProps.speedingViolations || []);
    }
  }


  render() {
    const { title, driverSpeedIdleData, isLoading, isLazyLoading, handleLazyLoad, height, monthView, startTimeUTC, distanceUnit } = this.props;

    const driver = driverSpeedIdleData.driver;
    const eldDailyCertifications = driverSpeedIdleData.eldDailyCertifications;
    const speedViolationsByDay = driverSpeedIdleData.speedViolationsByDay;
    const drivingTimePeriodsByDay = driverSpeedIdleData.drivingTimePeriodsByDay;
    const speedIdlingObject = driverSpeedIdleData.speedIdlingObject;

    const timezoneOffsetFromUTC = (driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();

    const totals = {
      drivingMs: 0,
      speedingMs: 0,
      idlingMs: 0,
    };

    let styles = {
      dateTime: { width: '20%', textAlign: 'left' },
      geofence: { width: '30%', textAlign: 'left' },
      speedValue: { width: '15%', textAlign: 'left' },
      vehicleUnitId: { width: '20%', textAlign: 'left' },
      duration: { width: '15%', textAlign: 'left' },
    };

    const headerRows = [{ key: 'spdvioheader', columns: [
      { element: <div>Speeding Interval</div>, props: { style: styles.dateTime } },
      { element: <div>Duration</div>, props: { style: styles.duration } },
      { element: <div>Vehicle Unit</div>, props: { style: styles.vehicleUnitId } },
      { element: <div>Speed Recorded ({distanceUnit ? distanceUnit.toUpperCase() : 'KM' })</div>, props: { style: styles.speedValue } },
      { element: <div>Geofence ({distanceUnit ? distanceUnit.toUpperCase() : 'KM' })</div>, props: { style: styles.geofence } },
    ]}];

    if (monthView) {
      styles.duration.width = '10%';
      styles.geofence.width = '25%';
      styles.dateCategory = { width: '10%', textAlign: 'left' };

      headerRows[0].columns.unshift({ element: <div>Date</div>, props: { style: styles.dateCategory } });
    }


    let bodyRows = [];

    const speedViolationsStartTimeUTCMs = Object.keys(speedViolationsByDay);
    if (speedViolationsStartTimeUTCMs.length > 0) {
      // if we gots speed violations at all, go through each day and add rows
      speedViolationsStartTimeUTCMs.map((startTimeUTCMs, index) => {

        if (!monthView && index > 0) { // in case of any errors where we're in daily view but violations extend multiple days
          return;
        }

        const speedViolations = speedViolationsByDay[startTimeUTCMs];

        let lastViolationStartDate;

        speedViolations.map(violation => {
          const vehicle = violation.get('vehicle');
          const vehicleUnitId = (vehicle && vehicle.get('unitId')) || 'N/A';

          const geofence = violation.get('geofence');
          const geofenceName = (geofence && geofence.get('name')) || 'N/A';
          let geofenceSpeedLimit = (geofence && geofence.get('speedLimitKm')) || 0;

          let recordedSpeed = violation.get('speedKm') || 0;

          if (distanceUnit === 'mi') {
            geofenceSpeedLimit = convertDistance(geofenceSpeedLimit, 'km', 'mi');
            recordedSpeed = convertDistance(recordedSpeed, 'km', 'mi');
          }

          geofenceSpeedLimit = geofenceSpeedLimit ? geofenceSpeedLimit : 'N/A';
          recordedSpeed = recordedSpeed ? Math.floor(recordedSpeed) : 'N/A';

          const violationStartTime = moment(violation.get('startTime')).tz(timezoneOffsetFromUTC);
          let violationStartDate = moment(violationStartTime).format('DD-MM-YYYY');
          let violationStart = moment(violationStartTime).format('hh:mm a');

          // account for if the violation ends on a day other than the start
          const violationEndTime = moment(violation.get('endTime')).tz(timezoneOffsetFromUTC);
          let violationEnd = 'N/A';
          if (violationEndTime) {
            violationEnd = moment(violationEndTime);
            if ((violationEnd.date() !== moment(violationStartTime).date())) {
              violationEnd = moment(violationEnd).format('hh:mm a (DD-MMM-YYYY)');
            } else {
              violationEnd = moment(violationEnd).format('hh:mm a');
            }
          }

          // accumulate totals
          let speedingMs = 0;
          if (violation.get('startTime') && violation.get('endTime')) {
            speedingMs = moment(violationEndTime).diff(violationStartTime);
            totals.speedingMs += speedingMs;
          }

          let durationString = formatDurationString(speedingMs);

          const columns = [
            {
              props: { style: styles.dateTime },
              element: <div>{`${violationStart} - ${violationEnd}`}</div>
            },
            {
              props: { style: styles.duration },
              element: <div>{ durationString }</div>
            },
            {
              props: { style: styles.vehicleUnitId },
              element: <div>{ vehicleUnitId }</div>
            },
            {
              props: { style: styles.speedValue },
              element: <div>{ recordedSpeed }</div>
            },
            {
              props: { style: styles.geofence },
              element: (
                <div>
                  <div>{`Name: ${geofenceName}`}</div>
                  <div>{`Limit: ${geofenceSpeedLimit}`}</div>
                </div>
              ),
            },
          ];

          if (monthView) {
            let dateCategoryString = '';
            if (violationStartDate !== lastViolationStartDate) {
              dateCategoryString = violationStartDate;
              lastViolationStartDate = violationStartDate;
            }

            columns.unshift(
              {
                props: { style: styles.dateCategory },
                element: <div>{ dateCategoryString }</div>
              }
            );
          }

          bodyRows.push({
            key: violation.id,
            props: {
              id: violation.id,
              selectable: false,
            },
            columns,
          });
        });

      });
    } else {
      bodyRows = [{
        key: 'norows',
        props: {
          selectable: false,
        },
        columns: [{ element: <div>No violations matching the filter</div>, props: { colSpan: 420 } }]
      }];
    }


    // calculate totals/summaries here
    let speedingDurationString = formatDurationString(totals.speedingMs);

    // getting driving time durations
    let drivingDurationString = formatDurationString(totals.drivingMs);
    if (drivingTimePeriodsByDay) {
      Object.keys(drivingTimePeriodsByDay).map(startTimeUTCMs => {
        const drivingTimePeriods = drivingTimePeriodsByDay[startTimeUTCMs];
        drivingTimePeriods.map(drivingTimePeriod => {
          const startTime = moment(drivingTimePeriod.get('startTime')).tz(timezoneOffsetFromUTC);
          const endTime = moment(drivingTimePeriod.get('endTime')).tz(timezoneOffsetFromUTC);
          const drivingMs = endTime.diff(startTime, 'milliseconds');
          totals.drivingMs += drivingMs;
        });
      });
      drivingDurationString = formatDurationString(totals.drivingMs);
    }

    // now for idling time
    eldDailyCertifications.map(eldDailyCertification => {
      const idleTimeMillis = eldDailyCertification.get('idleTimeMillis') || 0;
      totals.idlingMs += idleTimeMillis;
    });
    let idlingDurationString = formatDurationString(totals.idlingMs);

    const totalsStyle = { fontWeight: '700' };
    const totalsCategoryTitleStyle = { display: 'inline-block', width: '10.5em' };

    let speedingToDriving = ((totals.speedingMs / totals.drivingMs) * 100).toFixed(2);
    let idlingToDriving = ((totals.idlingMs / totals.drivingMs) * 100).toFixed(2);

    if (totals.drivingMs === 0) {
      speedingToDriving = 'N/A';
      idlingToDriving =  'N/A';
    }

    bodyRows.push(
      {
        key: 'divider',
        props: { selectable: false },
        columns: [{ props: { colSpan: 420 }, element: <div /> }]
      },
      {
        key: 'totalsAndRatios',
        props: {
          selectable: false,
        },
        columns: [
          {
            props: { colSpan: 420, style: { ...styles.vehicleUnitId, ...totalsStyle } },
            element: (
              <div>
                <div>
                  <span style={totalsCategoryTitleStyle}>Total Time Driving</span> { drivingDurationString }
                </div>
                <div>
                  <span style={totalsCategoryTitleStyle}>Total Time Speeding</span> { speedingDurationString }
                  <span style={{ ...totalsCategoryTitleStyle, marginLeft: '2.5em', width: '12em' }}>Time Speeding vs Driving</span> { speedingToDriving }%
                </div>
                {/*
                <div>
                  <span style={totalsCategoryTitleStyle}>Total Time Idling</span> { idlingDurationString }
                  <span style={{ ...totalsCategoryTitleStyle, marginLeft: '2.5em', width: '12em' }}>Time Idling vs Driving</span> { idlingToDriving }%
                </div>
                */}
              </div>
            )
          },
        ],
      }
    );

    let speedIdlingGraph;
    if (!monthView) {
      // since this is only for a day, there should only be 1 speedViolationObject date (or 2 if violations extend into the next day from the current day, but we only care about the current day)
      const speedViolationDateTimesMs = Object.keys(speedIdlingObject);

      if (speedViolationDateTimesMs.length > 0) {
        const speedIdleEvents = speedIdlingObject[speedViolationDateTimesMs[0]];

        // find associated daily cert
        const associatedELDDailyCertification = eldDailyCertifications.filter(eldDailyCertification => {
          return moment(eldDailyCertification.get('startTimeUTC')).valueOf() === parseInt(speedViolationDateTimesMs[0]);
        })[0];

        const graphStartTimeUTC = (associatedELDDailyCertification && moment(associatedELDDailyCertification.get('startTimeUTC'))) || moment(speedIdleEvents[0].dateTime).startOf('day').toDate();

        speedIdlingGraph = (
          <SpeedIdlingGraph
            speedIdleEvents={speedIdleEvents}
            createSpeedIdleObject={SI.createSpeedIdleObject}
            driver={driver}
            startTimeUTC={graphStartTimeUTC}
            scaleToDriverTimezone
          />
        );

        bodyRows.push({
          key: `speedIdlingGraph-${startTimeUTC.getTime()}`,
          props: {
            selectable: false,
          },
          columns: [
            {
              props: { colSpan: 420, style: totalsStyle },
              element: (
                speedIdlingGraph
              )
            },
          ],
        });
      }
    }

    // { title &&
    //   <Title className="h3" title={title} />
    // }
    let totalCategoryStyle = { display: 'inline-block', width: '12em' };

    const cardTitle = (
      <div>
        <div style={{ verticalAlign: 'middle', display: 'inline-block', width: '8em', fontSize: '1em', fontWeight: '500' }}>{ title }</div>
        <div style={{ verticalAlign: 'middle', marginLeft: '2em', display: 'inline-block', width: '18em', fontSize: '.8em', fontWeight: '500' }}>
          <div><div style={totalCategoryStyle}>Total Time Driving</div>{ drivingDurationString }</div>
          <div><div style={totalCategoryStyle}>Total Time Speeding</div>{ speedingDurationString }</div>
          <div><div style={totalCategoryStyle}>Time Speeding vs Driving</div>{ speedingToDriving }%</div>
        </div>
        { !monthView &&
          <div style={{ verticalAlign: 'middle', marginLeft: '2em', display: 'inline-block', width: '40em' }}>
            { speedIdlingGraph }
          </div>
        }
      </div>
    );
    return (
      <div>
        <Card>
          <CardHeader
            title={cardTitle}
            actAsExpander={true}
            showExpandableButton={true}
          />
          <CardText expandable={true}>
            <div style={{ fontSize: '.85em' }}>
            ( Times scaled to { timezoneOffsetFromUTC } )
            </div>
            <LazyLoadingTable
              height={height}
              tableHeaderRows={headerRows}
              tableBodyRows={bodyRows}
              selectable={false}
              multiSelectable={false}
              enableSelectAll={false}
              showCheckboxes={false}
              handleLazyLoad={handleLazyLoad}
              isLoading={isLoading}
            />
          </CardText>
        </Card>
      </div>
    );
  }
}

DriverSpeedViolationsTable.propTypes = {
  title: PropTypes.string,
  driverSpeedIdleData: PropTypes.object,
  isLoading: PropTypes.bool,
  isLazyLoading: PropTypes.bool,
  height: PropTypes.any,
  handleLazyLoad: PropTypes.func,
  monthView: PropTypes.bool,
  startTimeUTC: PropTypes.instanceOf(Date),
  distanceUnit: PropTypes.string,
};

export default DriverSpeedViolationsTable;
