import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// API
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getDispatchJobs } from 'api/Dispatch/DispatchJob';

// CSAPI sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// CSAPI Enums
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// sbCore Components
import Autocomplete from 'sbCore/Autocomplete/Autocomplete';

/**
 * @description Retrieve DispatchOrganizations given a search term
 * @param {Function} onSelectDispatchJob - Callback function that returns the selected dispatch organization job
 * @param {String} [className] - Custom container className
 * @param {DispatchJob} [dispatchJob] - Currently selected dispatchJob
 * @param {Object} [style] - Custom inline styles
 * @param {Boolean} [warning] - Makes input border yellow
 * @param {Boolean} [autoFocus] - Boolean to automatically focus on the autocomplete on load
 * @param {Boolean} [disabled] - Boolean to disable component
 * @returns
 */
function DispatchJobAutocompleteInput({ ...props }) {

  // ** useStates ** //
  const [identifier] = useState(uniqid());
  const [selectedDispatchJob, setSelectedDispatchJob] = useState(undefined);
  const [dispatchJobObjs, setDispatchJobObjs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [hasError, setHasError] = useState(false);

  // ** useEffectCallback ** //
  // Callback used for useEffects
  const useEffectGetDispatchJobsCallback = () => {
    /*
      Logic Summary:
        - Fetch list of jobs
        - If the user enters a searchTerm, see if it exists within the preloaded results for the user to quickly select. Meanwhile, concurrently run
          a query matching the searchTerm to fetch additional results that may be outside of the preloaded results
    */
    setHasError(false);

    // Check if selectectedDispatchJob is an object and set it if it is, else continue
    if ((typeof searchTerm) === 'object') {
      return setSelectedDispatchJob(searchTerm.dispatchJob);
    }

    const searchTermTrimmed = (searchTerm || '').trim();

    let _dispatchJobObjs = [...dispatchJobObjs].filter(dispatchJobObj => {
      dispatchJobObj.batchId.includes(searchTermTrimmed);
    });
    setDispatchJobObjs(_dispatchJobObjs);

    let didCancel = false;

    let filters = [
      new Filter(QueryRestriction.LIMIT, undefined, 120),
    ];

    if (searchTermTrimmed.length > 0) {
      filters = [
        new Filter(QueryRestriction.LIMIT, undefined, 15),
        new Filter(QueryRestriction.MATCHES, 'batchId', searchTermTrimmed),
      ];
    }

    async function _getDispatchJobs() {
      const { dispatchJobs } = await getDispatchJobs(
        undefined, // options - default
        undefined, // companyobjectid - default
        false, // include child company results
        filters, // filters
        undefined, // sort - default
        ['customerDispatchOrganization'], // includes
        undefined, // selects
        undefined, // page
        undefined, // limit
        false, // query all
      );

      if (!didCancel) {
        _dispatchJobObjs = dispatchJobs.map(dispatchJob => {
          return {
            batchId: getAttribute(dispatchJob, 'batchId'),
            dispatchJob,
          };
        });

        if (!_dispatchJobObjs.length && searchTermTrimmed) setHasError(true);
        setDispatchJobObjs(_dispatchJobObjs);
      }
    }

    _getDispatchJobs();

    return () => { didCancel = true; };
  };

  // ** useEffects ** //
  useEffect(useEffectGetDispatchJobsCallback, []);
  useEffect(useEffectGetDispatchJobsCallback, [searchTerm]);

  useEffect(() => {
    if (searchTerm && !selectedDispatchJob) {
      setHasError(true);
    }

    if (props.onSelectDispatchJob) {
      props.onSelectDispatchJob(selectedDispatchJob);
    }
  }, [selectedDispatchJob]);

  // ** Components ** //
  const itemTemplate = (item) => {
    return (
      <div className="job-item">
        <div>{item.batchId || 'No Job ID Defined'}</div>
      </div>
    );
  };

  // ** Callbacks ** //
  const inputEl = (
    document.querySelector(`.dispatch-job-autocomplete-input.${identifier} .p-inputtext.p-component.p-autocomplete-input.p-autocomplete-dd-input`)
  );

  const inputButtonEl = (
    document.querySelector(`.dispatch-job-autocomplete-input.${identifier} button.p-button`)
  ); // the autocomplete dropdown button

  if (inputEl) {
    inputEl.autocomplete = 'disabled'; // disable the browser's default autocomplete for inputs
  }

  const triggerAutocompleteOnClick = () => {
    // open dropdown if user focuses on input
    if (inputButtonEl) {
      inputButtonEl.click();
    }
  };

  const triggerAutocompleteOnKeyPress = (e) => {
    // if the input is focused
    if (inputButtonEl) {
      const code = (e.keyCode ? e.keyCode : e.which);
      if (code === 13) { // Enter
        inputButtonEl.click();
      }
    }
  };

  // ** Misc ** //
  let className = `dispatch-job-autocomplete-input ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  const placeholder = 'Job ID';

  // if the input is not being focused on (which is for some reason dictated by the button's focus),
  // but the user left their search term without selecting a suggestion for the searchTerm
  const isNotFocusedWithText = (inputButtonEl
    && searchTerm
    && ((typeof searchTerm) !== 'object')
    && (document.activeElement !== inputButtonEl)
  );

  const _hasError = isNotFocusedWithText || hasError;

  return (
    <div className={className} style={{ ...props.style }}>
      <Autocomplete
        placeholder={placeholder}
        dropdown
        field="name"
        value={searchTerm}
        suggestions={dispatchJobObjs}
        forceSelection={false}
        itemTemplate={itemTemplate}
        autoFocus={props.autoFocus}
        warning={props.warning}
        error={_hasError}
        completeMethod={() => setDispatchJobObjs([...dispatchJobObjs])}
        onChange={(e) => setSearchTerm(e.value)}
        onClick={() => triggerAutocompleteOnClick()}
        onKeyPress={(e) => triggerAutocompleteOnKeyPress(e)}
        disabled={props.disabled}
      />
    </div>
  );
}

export default DispatchJobAutocompleteInput;
