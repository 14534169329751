import React from 'react';

import { Message as PRMessage } from 'primereact/message';

import './style.scss';

/**
 * @description PrimeReact Messages component
 * @param {String} severity - severity of message: info, success, warn, error
 * @param {Any} text - the content of the message
 * @param {String} className - Custom className
 * @returns JSX layout
 */
function Message({ ...props }) {
  let className = 'sbc-message';
  if (props.className) className += ` ${props.className}`;

  return (<PRMessage {...props} className={className} />);
}

export default Message;
