import React, { useState, useEffect } from 'react';
import Parse from 'parse';

// CSAPI
import { getCurrentUser } from 'sb-csapi/dist/AAPI';

// API
import { addDispatchDocument } from 'api/Dispatch/DispatchDocument';

// Components
import DispatchUploadDocumentButton from 'components/Dispatch/DispatchUploadDocument/DispatchUploadDocumentButton';
import DispatchUploadDocumentTable from 'components/Dispatch/DispatchUploadDocument/DispatchUploadDocumentTable';
import DispatchDocumentTypeDropdown from 'components/Dispatch/DispatchDocumentTypeDropdown/DispatchDocumentTypeDropdown';

// sbCore Components
import FileUpload from 'sbCore/FileUpload/FileUpload';
import Dialog from 'sbCore/Dialog/Dialog';
import Button from 'sbCore/Button/Button';
import Message from 'sbCore/Message/Message';
import ProgessSpinner from 'sbCore/ProgressSpinner/ProgressSpinner';

// Styles
import './style.scss';

/**
 * @description Renders an accordion type table that displays all documents associated with a job
 * @param {DispatchJob} dispatchJob - current dispatch job being used
 * @param {Number} [documentTypeInt] - A pre-selected document type
 * @param {Boolean} [disableDocumentTypeSelection] - Whether to disable document type selection
 * @param {Function} handleDocumentUpload - Function that sets the isDocumentUploaded state using the boolean argument passed to it. If true, will refresh the all documents table
 * @param {Boolean} [multiple] - Whether or not to allow multiple files
 */
function DispatchUploadDocument({ ...props }) {

  // ** useStates ** //
  const [showUploadDocumentDialog, setShowUploadDocumentDialog] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const [documentTypeInt, setDocumentTypeInt] = useState(undefined);

  const [showInvalidFileSizeWarning, setShowInvalidFileSizeWarning] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // ** useEffects ** //
  useEffect(() => {

    if (!showInvalidFileSizeWarning) return;

    setTimeout(() => {
      setShowInvalidFileSizeWarning(false);
    }, 3500);

  }, [showInvalidFileSizeWarning]);

  useEffect(() => {
    if (props.documentTypeInt !== undefined) setDocumentTypeInt(props.documentTypeInt);
  }, [props.documentTypeInt]);

  // ** Callback Functions ** //

  // Updates the upload document dialog state to true which displays the dialog
  const showUploadDocument = () => {
    setShowUploadDocumentDialog(true);
  };

  // Formats the selected documents and updates the state with them
  const handleDocumentSelection = ({ files, options }) => {
    const formattedDocuments = files.map((document) => {
      const documentName = document.name.replace(/[^\w.]/g, ''); // Remove illegal characters from the file name
      const documentType = document.type;
      const documentFile = new Parse.File(documentName, document, documentType);

      return ({
        documentName,
        documentType,
        documentFile,
      });
    });

    setSelectedDocuments(formattedDocuments);

    // clears FileUpload's storing of files so a new files can be uploaded if needed.
    options.clear();
  };

  // Filters out the selected document to be removed
  const handleDocumentRemoval = (documentName) => {
    const filteredDocuments = selectedDocuments.filter((document) => document.documentName !== documentName);
    setSelectedDocuments(filteredDocuments);
  };

  // Creates document records with the selected documents
  const handleDocumentUpload = async () => {
    const documents = [];
    setIsLoading(true);
    const promise = selectedDocuments.map(async (selectedDocument) => {
      const uploadedBy = getCurrentUser();
      const file = selectedDocument.documentFile;
      const keyValueObj = {
        uploadedBy,
        dispatchJob: props.dispatchJob,
        type: documentTypeInt,
        file,
      };

      const document = await addDispatchDocument(keyValueObj);
      documents.push(document);
    });

    // Once all documents are added, tell parent component to update the "all documents" table
    await Promise.all(promise).then(() => {
      if (props.handleDocumentUpload) {
        props.handleDocumentUpload(true, documents);
      }

      // Reset states
      setIsLoading(false);
      setShowUploadDocumentDialog(false);
      setDocumentTypeInt(undefined);
      setSelectedDocuments([]);
    });
  };

  // Hides dialog when called
  const onHideDialog = () => {
    setShowUploadDocumentDialog(false);
  };

  // Callback function which updates the document type int
  const onDocumentTypeChange = (_documentTypeInt) => {
    if (_documentTypeInt === undefined) return;

    setDocumentTypeInt(_documentTypeInt);
  };

  // ** Components ** //
  const fileUploadButton = (
    <FileUpload
      mode="basic"
      name="documentUpload"
      accept=".pdf"
      maxFileSize="1000000" // 1MB Limit
      uploadHandler={handleDocumentSelection}
      onValidationFail={() => setShowInvalidFileSizeWarning(true)}
      chooseOptions={{ icon: 'pi pi-file-pdf', label: 'Select Document(s)' }}
      auto
      customUpload
      multiple={props.multiple}
    />
  );

  const documentTypeDropdown = (
    <DispatchDocumentTypeDropdown
      onDocumentTypeChange={onDocumentTypeChange}
      documentTypeInt={documentTypeInt}
      disableDocumentTypeSelection={props.disableDocumentTypeSelection}
    />
  );

  const selectedDocumentsTable = (
    <DispatchUploadDocumentTable documents={selectedDocuments} handleDocumentRemoval={handleDocumentRemoval} />
  );

  const uploadButton = (
    <Button
      icon="pi pi-upload"
      label="Upload"
      onClick={async () => await handleDocumentUpload()}
      disabled={selectedDocuments.length === 0 || documentTypeInt === undefined || isLoading}
    />
  );

  const documentTypeWarning = (
    <Message severity="warn" text="Document Type Is Required" />
  );

  const invalidFileSizeWarning = (
    <Message severity="error" text="File Size Must Be Less Than 1MB" />
  );

  return (
    <div className="dispatch-upload-document">
      <Dialog
        className="dispatch-upload-document-dialog"
        header="Upload Documents"
        visible={showUploadDocumentDialog}
        onHide={onHideDialog}
        resizeable={false}
        draggable={false}
      >

        <div className="flex">
          {fileUploadButton}
          {documentTypeDropdown}
        </div>

        {selectedDocumentsTable}

        <div className="formgroup-inline mt-2">
          {uploadButton}
          {isLoading && <ProgessSpinner className="upload-loading" strokeWidth={3} />}
          {(selectedDocuments.length !== 0) && (documentTypeInt === undefined) && documentTypeWarning}
          {showInvalidFileSizeWarning && invalidFileSizeWarning}
        </div>

      </Dialog>

      <DispatchUploadDocumentButton showUploadDocument={showUploadDocument} />
    </div>
  );
}

export default DispatchUploadDocument;
