import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import AddEditButton from 'components/AddEditModal/container/AddEditButton';
// Actions
import { updateVendorAddressForState, updateVendorForState } from 'actions/Vendor';
import * as ActionConstants from 'actions/ActionConstants';

// Context
import StoreContext from 'contexts/StoreContext';

function hasValueChanged(oldValue, newValue) {
  if (oldValue !== newValue && !(!oldValue && !newValue)) {
    return true;
  }
  return false;
}

function getInitialState(props) {
  const initialState = {};
  initialState.editVendorFormFields = [
    [{ label: 'Location Name', value: props.vendor.get('name') || '', type: 'text', required: true, property: 'name' }],
    [{ label: 'Address', required: true, value: { city: props.vendor.get('address').get('city') || '', stateProvince: props.vendor.get('address').get('stateProvince') || '', zipPostal: props.vendor.get('address').get('zipPostal') || '', country: props.vendor.get('address').get('country') || '', geoPoint: props.vendor.get('address').get('geoPoint') || undefined, address: props.vendor.get('address').get('address') || '' }, type: 'address', property: 'address' }],
    [{ label: 'Notes', value: props.vendor.get('notes') || '', type: 'textarea', property: 'notes' }],
  ];
  return initialState;
}

class EditVendorButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = getInitialState(props);
    this.editVendor = this.editVendor.bind(this);
    this.refreshState = this.refreshState.bind(this);
  }

  editVendor(formValues) {
    const keys = Object.keys(formValues);
    const keysLen = keys.length;
    if (keysLen > 0) {
      for (let i = 0; i < keysLen; i++) {
        const currentKey = keys[i];
        if (currentKey === 'address') {
          const addressKeys = Object.keys(formValues.address);
          const addressKeysLen = addressKeys.length;
          for (let j = 0; j < addressKeysLen; j++) {
            if (hasValueChanged(formValues.address[addressKeys[j]], this.props.vendor.get('address').get(addressKeys[j]))) {
              updateVendorAddressForState(this.props.vendor.get('address'), addressKeys[j], formValues.address[addressKeys[j]]);
            }
          }
        } else if (hasValueChanged(formValues[currentKey], this.props.vendor.get(currentKey))) {
          updateVendorForState(this.props.vendor, currentKey, formValues[currentKey]);
        }
      }
    }
  }

  refreshState() {
    this.setState(getInitialState(this.props));
  }

  render() {
    return (
      <div className="inline">
        <AddEditButton refreshState={this.refreshState} handleAddEdit={this.editVendor} title="Edit" formTemplate={this.state.editVendorFormFields} editingInProgress={this.props.Vendor.status === ActionConstants.ADD_VEHICLE_INPROGRESS} />
      </div>
    );
  }
}

EditVendorButton.propTypes = {
  vendor: PropTypes.object.isRequired,
  Vendor: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Vendor } = state;
  return {
    Vendor,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(EditVendorButton);

