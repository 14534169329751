import React from 'react';
import { MDBBtn, MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBIcon } from 'mdbreact';

class ErrorMessagesModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { props } = this;

    if (!props.errorType) {
      return (
        <MDBModal size="lg" isOpen={props.show} centered>
          <MDBModalHeader>
            <MDBIcon icon="exclamation-triangle" className="px-2" />
          </MDBModalHeader>
          <MDBModalBody>
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              size="md"
              color="primary"
              onClick={() => props.handleClose()}
            >
              Close
          </MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      );
    }

    return (
      <MDBModal size="lg" isOpen={props.show} centered>
        <MDBModalHeader>
          <MDBIcon icon="exclamation-triangle" className="px-2" />
          {props.errorType.title}
        </MDBModalHeader>
        <MDBModalBody>
          {props.errorMessageBody}

          {props.handleResolution && (
            <MDBContainer>
              <b>{`Recommended Action: ${props.recommendedActionString}`}</b>
              <br />
              <br />
              {/* {props.handleResolution} */}
            </MDBContainer>
          )}

        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn
            size="md"
            color="primary"
            onClick={() => props.handleClose()}
          >
            Close
          </MDBBtn>
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

export default ErrorMessagesModal;
