import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';

// API
import { getVisionVehicles } from 'api/Vision/VisionVehicle';
import { callCloudFunction, getAttribute } from 'sb-csapi/dist/AAPI';
import { isLivestreamPublished } from 'sb-csapi/src/api/Vision/Vision';

// Components
import Button from 'sbCore/Button/Button';

// SBObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Enums
import { QueryRestriction, QuerySortOrderTypes } from 'sb-csapi/dist/enums/Query';

// Components
import VisionSnapshot from 'components/Vision/VisionSnapshot/VisionSnapshot';
import VisionPlaylist from 'components/Vision/VisionPlaylist/VisionPlaylist';
import VisionEventDescriptionCard from 'components/Vision/VisionEventDescriptionCard/VisionEventDescriptionCard';
import VideoPlayer from 'sbCore/VideoPlayer/VideoPlayer';

// CSS
import './style.scss';

// Context
import ToastContext from 'contexts/ToastContext';

// Context
import StoreContext from 'contexts/StoreContext';

/**
 * @description Main layout component for Vision Module
 * @param {String} unitId - The selected vehicleUnitId to display information for
 * @param {Function} getSelectedVisionEvent - Callback function when a given VisionEvent is selected
 * @param {any} Main - The redux store for Main, used to retrieve the pubnub instance for notifications
 * @returns {JSX} The VisionVehicleLayout component
 */
function VisionVehicleLayout(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [visionVehicle, setVisionVehicle] = useState();

  const [visionImage, setVisionImage] = useState();
  const [visionEvent, setVisionEvent] = useState();
  const [visionFile, setVisionFile] = useState();

  const [livestreamURL, setLivestreamURL] = useState();
  const [isRequestingLivestream, setIsRequestingLivestream] = useState(false);
  const [isLivestreamAlreadyPublished, setIsLivestreamAlreadyPublished] = useState(false);

  const [showDashcamSnapshot, setShowDashcamSnapshot] = useState(false);
  const [isQueclinkDashcam, setIsQueclinkDashcam] = useState(false);

  // Notifications
  const { addToast, clearToasts } = useContext(ToastContext);

  async function getVisionVehicle() {
    const dbVisionVehicleFilters = [new Filter(QueryRestriction.EQUAL_TO, 'vehicleUnitId', props.unitId)];
    const { totalVisionVehiclesCount, visionVehicles } = await getVisionVehicles(undefined, undefined, undefined, undefined, dbVisionVehicleFilters, undefined);

    if (visionVehicles && visionVehicles.length > 0) {
      const _visionVehicle = visionVehicles[0];
      const _visionImage = getAttribute(_visionVehicle, 'latestVisionImage');
      const dashcamHardwareId = getAttribute(_visionVehicle, 'dashcamHardwareId');

      // This check could be moved into its own sb-csapi function if needed
      if (dashcamHardwareId && dashcamHardwareId.startsWith('868487')) setIsQueclinkDashcam(true);
      if (_visionImage) setShowDashcamSnapshot(true);

      setVisionVehicle(visionVehicles[0]);
      setVisionImage(_visionImage);
    }
  }

  useEffect(() => {
    document.title = 'Vision Dashcams - Switchboard';

    const livestreamListener = {
      message: (messageObj) => {
        if (messageObj.message.type === 'visionLivestream') {
          const { livestreamStatus } = messageObj.message;

          // addToast({ severity: 'info', content: `Livestream ${livestreamStatus ? 'started' : 'ended'}` });
          setIsLivestreamAlreadyPublished(livestreamStatus);
          setShowDashcamSnapshot(!livestreamStatus);
        };
      },
    }

    // Get pubnub client from redux store and add any new listeners here
    const pubNub = props.Main.pubNub;
    if (pubNub && pubNub.client) pubNub.client.addListener(livestreamListener);

    return () => { if (pubNub && pubNub.client) pubNub.client.removeListener(livestreamListener) }
  }, []);

  useEffect(() => {
    let didCancel = false;

    async function getVisionVehicleObject(unitId) {
      const filters = [new Filter(QueryRestriction.EQUAL_TO, 'vehicleUnitId', unitId)];

      const { visionVehicleCount, visionVehicles } = await getVisionVehicles(undefined, undefined, undefined, undefined, filters);

      const _visionVehicle = visionVehicles.length > 0 ? visionVehicles[0] : undefined;
      const dashcamHardwareId = _visionVehicle && getAttribute(_visionVehicle, 'dashcamHardwareId');

      let _isQueclinkDashcam = false;
      if (dashcamHardwareId && (dashcamHardwareId.substring(0, 5) === '86848' || dashcamHardwareId.startsWith('86681'))) _isQueclinkDashcam = true;

      if (!didCancel) {
        setVisionVehicle(_visionVehicle);
        setIsQueclinkDashcam(_isQueclinkDashcam);
        setIsLoading(false);
      }
    }

    // When we receive a new unitId, reset the whole state
    if (props.unitId) {
      document.title = `Unit ${props.unitId} - Vision Dashcams - Switchboard`;

      setIsLoading(true);

      setVisionVehicle(undefined);
      setVisionImage(undefined);
      setVisionEvent(undefined);
      setVisionFile(undefined);

      setLivestreamURL(undefined);
      setIsRequestingLivestream(false);
      setIsLivestreamAlreadyPublished(false);

      getVisionVehicleObject(props.unitId);
      setIsQueclinkDashcam(false);
    }

    getVisionVehicle();
    return () => { didCancel = true; };
  }, [props.unitId]);

  useEffect(() => {
    if (props.getSelectedVisionEvent) props.getSelectedVisionEvent(visionEvent);

    if (!visionEvent) {
      setVisionFile(undefined);
      return;
    }

    const video = getAttribute(visionEvent, 'video');
    setVisionFile(video);
  }, [visionEvent]);


  // We should have some extra checks here to ensure that we can start a livestream
  // 1. Check to see if the dashcam is even reachable - we can somehow check this by either sending a command to the dashcam and wait for a response, or check when the last time the dashcam was updated (lastCheckInDateTime)
  // 2. Have some sort of way to determine whether or not the dashcam received the livestream command
  // 3. Check the status of the livestream command - the TCP server receives back specific codes depending on the status of the stream
  // 4. Have some sort of way to timeout starting the livestream (in the cases where the dashcam does not receive the command)
  async function sendLivestreamCommand(isLivestreaming) {
    if (!visionVehicle) return; // Eventually add in a toast notification letting the user know there was an error

    const dashcamHardwareId = getAttribute(visionVehicle, 'dashcamHardwareId');
    const belongsToCompany = getAttribute(visionVehicle, 'belongsToCompany');
    const belongsToCompanyObjectId = belongsToCompany && getAttribute(belongsToCompany, 'objectId');

    const livestreamURL = `https://stream.onswitchboard.com/live/${dashcamHardwareId}.m3u8`;

    // Check to see if there is a livestream happening
    if (isLivestreaming) {
      const _isLivestreamPublished = await isLivestreamPublished(dashcamHardwareId);

      if (_isLivestreamPublished) {
        setIsLivestreamAlreadyPublished(true);
      } else {
        const response = await callCloudFunction('setVisionLivestream', {
          belongsToCompanyObjectId,
          dashcamHardwareId,
          isLivestreaming,
        });

        if (response.status === 200) {
          addToast({ severity: 'success', summary: 'Livestream', detail: 'Livestream requested successfully' });
        } else {
          addToast({ severity: 'error', summary: 'Livestream', detail: 'Livestream request failed' });
        }
      }

      setIsRequestingLivestream(true);
      setLivestreamURL(livestreamURL);
      setShowDashcamSnapshot(false);
    } else {
      // Stopping the livestream is handled by the scheduler - here we just reset the frontend
      setIsRequestingLivestream(false);
      setLivestreamURL(undefined);
      setShowDashcamSnapshot(true);
    }
  }

  return (
    <div className="vision-vehicle-layout px-5 pt-5 h-full w-full">
      <div className={`${props.unitId ? 'flex' : 'hidden'} vision-subheader text-4xl font-medium uppercase translate-me `}>
        UNIT <span className='ml-1'>{props.unitId}</span>
        {/* <div>
          <Button
            icon="pi pi-external-link"
            iconPos="right"
            sbVariant="slim"
            label={`View Unit ${props.unitId} on Maps`}
          />
        </div> */}
      </div>
      <div className="flex flex-wrap flex-column lg:flex-row justify-content-between mt-3">
        <div className="flex-1 justify-content-center align-items-center card w-full mr-3 p-4">

          <div className="flex w-full flex-row-reverse mb-3">
            <Button
              icon="pi pi-video"
              iconPos="left"
              sbVariant="slim"
              className={`${(isLivestreamAlreadyPublished && isRequestingLivestream) ? 'p-button-warning' : 'p-button-secondary'}${!isQueclinkDashcam ? ' hidden' : ''} w-20rem`}
              label={`${(isLivestreamAlreadyPublished && isRequestingLivestream) ? 'End' : 'Start'} Livestream`}
              badge="Beta"
              disabled={isLoading || (isRequestingLivestream && !isLivestreamAlreadyPublished)}
              onClick={() => !isRequestingLivestream ? sendLivestreamCommand(true) : sendLivestreamCommand(false)}
            />
          </div>

          {(showDashcamSnapshot && isQueclinkDashcam)
            ? (
              <VisionSnapshot
                url={getAttribute(visionImage, 'image', true) && getAttribute(visionImage, 'image')._url}
              />
            ) : (
              <VideoPlayer
                className="react-player"
                isLive={isLivestreamAlreadyPublished && isRequestingLivestream}
                url={(isLivestreamAlreadyPublished && isRequestingLivestream) ? livestreamURL : (visionFile && visionFile._url || null)}
                width="100%"
                height="100%"
                onVideoPlayerReady={() => setIsLoading(false)}
              />
            )}
        </div>
        <div className="vision-playlist-container card w-full lg:w-4 mt-3 lg:mt-0">
          <VisionPlaylist
            unitId={props.unitId}
            visionVehicle={visionVehicle}
            showDashcamSnapshot={showDashcamSnapshot}
            onSelectVisionEvent={(_visionEvent) => {
              setVisionEvent(_visionEvent);
              setShowDashcamSnapshot(false);
            }}
          />
        </div>
      </div>
      <div className="mt-3">
        <VisionEventDescriptionCard
          visionEvent={visionEvent}
          disableLatestSnapshotButton={!(isQueclinkDashcam && visionImage)}
          onShowLatestSnapshot={() => getVisionVehicle()}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { Main } = state;
  return { Main };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(VisionVehicleLayout);
