import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

// API
import * as Helpers from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './ChangePasswordModal.module.scss';

class ChangePasswordModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordInfo: {},
      disableChangePassword: true,
      changeInProgress: false,
    };
    this.keys = [
      'nextPassword', 'confirmPassword',
    ];
    this.keysLen = 2;
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  handleChange(e) {
    const { target } = e;
    const nextState = { ...this.state };
    const { passwordInfo } = nextState;
    passwordInfo[target.id] = target.value;

    // validation
    for (let i = 0; i < this.keysLen; i++) {
      const currentKey = this.keys[i];
      if (Helpers.isStringEmpty(passwordInfo[currentKey])) {
        this.state.disableChangePassword = true;
        break;
      }
      // if (currentKey === 'currentPassword' && !passwordInfo[currentKey]) {
      //   this.state.disableChangePassword = true;
      //   break;
      // }
      if (currentKey === 'nextPassword' && !passwordInfo[currentKey]) {
        this.state.disableChangePassword = true;
        break;
      } else if (currentKey === 'nextPassword' && passwordInfo[currentKey].length < 8) {
        this.state.disableChangePassword = true;
        break;
      }
      if (currentKey === 'nextPassword' && passwordInfo[currentKey] !== passwordInfo.confirmPassword) {
        this.state.disableChangePassword = true;
        break;
      }
      if (currentKey === 'confirmPassword' && passwordInfo[currentKey] !== passwordInfo.nextPassword) {
        this.state.disableChangePassword = true;
        break;
      }
      this.state.disableChangePassword = false;
    }

    this.setState(this.state);
  }

  changePassword() {
    const passwordInfoObject = Object.assign({}, this.state.passwordInfo);
    const keys = Object.keys(passwordInfoObject);
    for (let i = 0; i < keys.length; i++) {
      const currentKey = keys[i];
      passwordInfoObject[currentKey] = passwordInfoObject[currentKey].trim();
    }

    this.setState({ ...this.state, disableChangePassword: true, changeInProgress: true, passwordInfo: {} }, () => {
      this.props.changePasswordFormHandler(passwordInfoObject).then(
        () => {
          const newState = { ...this.state };
          newState.disableChangePassword = false;
          newState.changeInProgress = false;
          this.setState(newState);
        },
        (error) => {
          const newState = { ...this.state, passwordInfo: passwordInfoObject };
          newState.error = error;
          newState.disableChangePassword = false;
          newState.changeInProgress = false;
          this.setState(newState);
        }
       );
    });
  }

  handleClose() {
    const newState = { ...this.state, disableChangePassword: true, changeInProgress: false, passwordInfo: {} };
    this.setState(newState);
    this.props.handleClose();
  }

  render() {
    const { passwordInfo } = this.state;
    return (
      <Modal className={styles.changePasswordModal} show={this.props.show} onHide={this.props.handleClose} animation={false} backdropClassName={styles.changePasswordModalBackdrop}>
        <Modal.Header>
          <Modal.Title className="translate-me">Change Password</Modal.Title>
        </Modal.Header>

        <Modal.Body className="centerText">
          <div className={`${styles.inputGroup} ${this.state.prefixInputStyle}`}>
            <div>
              <span className={`modalPrefixTip ${styles.modalPrefixTip} translate-me`}>
                <ul>
                  <li>
                    Passwords must be 8 characters or longer
                  </li>
                  <li>
                    You will be logged out upon a successful change
                  </li>
                </ul>
              </span>
            </div>

            {/*<span>
              <label className={`inputLabel`}>Current Password</label>
              <input
                className={`input`}
                id="currentPassword"
                type="password"
                value={passwordInfo.currentPassword || ''}
                placeholder="....."
                onChange={this.handleChange}
                autoComplete="off"
              />
            </span>*/}
            <span>
              <label className={`inputLabel translate-me`}>Next Password</label>
              <input
                className={`input`}
                id="nextPassword"
                type="password"
                value={passwordInfo.nextPassword || ''}
                placeholder="....."
                onChange={this.handleChange}
                autoComplete="off"
              />
            </span>
            <span>
              <label className={`inputLabel translate-me`}>Confirm Password</label>
              <input
                className={`input`}
                id="confirmPassword"
                type="password"
                value={passwordInfo.confirmPassword || ''}
                placeholder="....."
                onChange={this.handleChange}
                autoComplete="off"
              />
            </span>
            </div>
          {this.state.error && this.state.error.code === 141 &&
            <div className={`${styles.error} translate-me`}>{this.state.error.message}</div>
          }
        </Modal.Body>

        <Modal.Footer>
          {!this.state.changeInProgress ?
            <span>
              <button className={`buttonDefault closeButton translate-me`} onClick={this.handleClose}>Cancel</button>
              <button className={`buttonDefault translate-me`} onClick={this.changePassword} disabled={this.state.disableChangePassword}>Confirm</button>
            </span>
            :
            <LoadingIcon />
          }
        </Modal.Footer>
      </Modal>
    );
  }
}

ChangePasswordModal.propTypes = {
  changePasswordFormHandler: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  error: PropTypes.object,
};

export default ChangePasswordModal;
