/** @module DispatchTrailer */

import {
  addRecord, copyQuery, count, createQuery, createQueryOr, destroyRecord, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord,
} from 'sb-csapi/dist/AAPI';

import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @memberof module:DispatchTrailer
 *
 * @description Get DispatchTrailers according to filter criteria and a DispatchTransfer
 *
 * @param {object} options - See example
 * @param {string} dispatchTransferObjectId - DispatchTransfer we wish to retrieve dispatch trailers for
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { object } - { totalDispatchTrailersCount: Number, dispatchTrailers: [DispatchTrailer, ...] }
 */
async function getDispatchTrailers(options = { sessionToken: getCurrentUserSessionToken() }, dispatchTransferObjectId, filters = [], sortBy = new Sort(QuerySortOrder.ASCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  if (!dispatchTransferObjectId) new Error('No DispatchTransfer specified');

  let dispatchTrailerQuery = createQuery('DispatchTrailer');

  const _filters = [...filters];

  // set universal trailer filters
  _filters.push(new Filter(QueryRestriction.EQUAL_TO, 'dispatchTransfer', dispatchTransferObjectId)); // set to specific dispatchTransfer

  _filters.map(filter => {
    setQueryRestriction(dispatchTrailerQuery, filter.queryRestriction, filter.attribute, filter.value);
  });

  // at this point, copy current query to get the number of pages for pagination
  const dispatchTrailerCountQuery = copyQuery(dispatchTrailerQuery);

  // sort
  sortQuery(dispatchTrailerQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchTrailerQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchTrailerQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(dispatchTrailerQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(dispatchTrailerQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the count and the trailers
  const promises = [count(options, dispatchTrailerCountQuery), findRecords(options, dispatchTrailerQuery, false, queryAll)];

  try {
    const [totalDispatchTrailersCount, dispatchTrailers] = await Promise.all(promises);
    return { totalDispatchTrailersCount, dispatchTrailers };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchTrailer
 * @description Add a new dispatch trailer record
 *
 * @param {object} keyValueObj - The selected fields we would like to save, should be a dispatchTransfer and trailer object
 */
async function addDispatchTrailer(keyValueObj) {
  try {
    const dispatchTrailer = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'DispatchTrailer', keyValueObj, getCurrentUserCompanyObjectId());
    return dispatchTrailer;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchTrailer
 * @description Remove a dispatch trailer record and return the last version of it
 *
 * @param {object} record - The dispatch trailer record we would like to remove
 */
async function removeDispatchTrailer(record) {
  try {
    const dispatchTrailer = await destroyRecord({ sessionToken: getCurrentUserSessionToken() }, record);
    return dispatchTrailer;
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getDispatchTrailers,
  addDispatchTrailer,
  removeDispatchTrailer,
};
