import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "sbHistory";
import { RaisedButton, Table, TableHeader, TableBody, TableRow, TableRowColumn } from 'material-ui';

// API
import * as Helpers from 'api/Helpers';

// Components
import HOSRegulationIcon from 'components/HOSRegulationIcon/view/HOSRegulationIcon';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './ViolationDaysView.module.scss';

// Enums
import { ExcludedRegulations } from 'sb-csapi/dist/enums/HOS/Regulation';

function getViolations(eldDailyCertificationArr, hideIsHiddenBool) {
  if (eldDailyCertificationArr && eldDailyCertificationArr.length > 0) {
    const violations = [];
    for (let i = 0; i < eldDailyCertificationArr.length; i++) {
      let tempHOSV = eldDailyCertificationArr[i].get('hosViolations');
      if (tempHOSV) {
        for (let j = 0; j < tempHOSV.length; j++) {
          const hosViolation = tempHOSV[j];
          if (
            !hosViolation.get('disabled') &&
            !hosViolation.get('potential') &&
            !hosViolation.get('hideForever') &&
            !(hideIsHiddenBool && hosViolation.get('isHidden')) &&
            !(ExcludedRegulations.includes(hosViolation.get('regulationInt')))
          ) {
            violations.push(tempHOSV[j]);
          }
        }
      }
    }
    return violations;
  }
  return [];
}

function ViolationDaysView(props) {
  const { timezoneOffsetFromUTC } = props;
  const hosViolations = getViolations(props.eldDailyCertificationArr, props.selectedViolationFilter.value === 'hideHidden');
  if (hosViolations.length > 0 && !props.violationsNonZeroBool) {
    props.setViolationsNonZeroBool();
  }
  console.log(props.eldDailyCertificationArr0);
  return (
    <div className={styles.table}>
      <Table
        fixedHeader
      >
        <TableHeader
          adjustForCheckbox={false}
          displaySelectAll={false}
          style={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          <TableRow>
            <TableRowColumn style={{ textAlign: 'left', width: '18em' }}>{t('Date')}</TableRowColumn>
            <TableRowColumn style={{ textAlign: 'center' }}>{t('Violations')}</TableRowColumn>
            <TableRowColumn style={{ textAlign: 'right', width: '13em' }}>
              {props.generatingHOSViolationMonthlyReport &&
                <LoadingIcon />
              }
              {!props.generatingHOSViolationMonthlyReport &&
                <RaisedButton
                  icon={<i className="material-icons">print</i>}
                  label={t('Print')}
                  onClick={props.generateReport}
                  primary
                />
              }
            </TableRowColumn>
          </TableRow>
        </TableHeader>
        <TableBody
          displayRowCheckbox={false}
        >
          {hosViolations.length > 0 && props.eldDailyCertificationArr.map((eldDailyCertification) => {
            const hosViolationsForEldDailyCertification = getViolations([eldDailyCertification], props.selectedViolationFilter.value === 'hideHidden');
            if (hosViolationsForEldDailyCertification.length > 0) {
              return (
                <TableRow key={eldDailyCertification.id}>
                  <TableRowColumn style={{ textAlign: 'left', width: '18em' }}>
                    <b>{moment(eldDailyCertification.get('startTimeUTC')).tz(timezoneOffsetFromUTC || moment.tz.guess()).format('LL')}</b>
                  </TableRowColumn>
                  <TableRowColumn style={{ textAlign: 'center' }} >
                    <div className={styles.violationIconRow}>
                      {hosViolationsForEldDailyCertification.map((hosViolation) => (
                        <div className={styles.violationIcon} key={hosViolation.id}>
                          <HOSRegulationIcon HOSRegulationInt={hosViolation.get('regulationInt')} />
                        </div>
                      ))}
                    </div>
                  </TableRowColumn>
                  <TableRowColumn style={{ textAlign: 'center', width: '13em' }} >
                    <RaisedButton
                      icon={<i className="material-icons">chevron_right</i>}
                      label={t('Go To Log')}
                      onClick={() => history.push({ pathname: 'driver', search: "driver=" + props.driver.id + "&view=hosEvents&date=" + moment(Helpers.referenceToTimezone(eldDailyCertification.get('startTimeUTC'), props.driver.get('timezoneOffsetFromUTC'))).format('DDMMYY') })}
                      primary
                      style={{ float: 'right', margin: '1em' }}
                    />
                  </TableRowColumn>
                </TableRow>
              );
            }
            return null;
          })}
          {/* props.fetched && props.eldDailyCertificationArr.length === 0 &&
            <TableRow>
              <TableRowColumn colSpan="42" style={{ textAlign: 'center', fontWeight: 'bold', verticalAlign: 'middle' }}>
                No Logs for this Month
              </TableRowColumn>
            </TableRow>
          */}
          {props.fetched && hosViolations.length === 0 &&
            <TableRow>
              <TableRowColumn colSpan="42" style={{ textAlign: 'center', fontWeight: 'bold', verticalAlign: 'middle' }}>
                {t('No Violations for this Month')}
              </TableRowColumn>
            </TableRow>
          }
          {!props.fetched &&
            <TableRow>
              <TableRowColumn colSpan="42" className="loadingIconPadding">
                <LoadingIcon />
              </TableRowColumn>
            </TableRow>
          }
        </TableBody>
      </Table>
    </div>
  );
}

ViolationDaysView.propTypes = {
  driver: PropTypes.object.isRequired,
  eldDailyCertificationArr: PropTypes.array.isRequired,
  violationsNonZeroBool: PropTypes.bool.isRequired,
  setViolationsNonZeroBool: PropTypes.func.isRequired,
  fetched: PropTypes.bool.isRequired,
  generatingHOSViolationMonthlyReport: PropTypes.bool.isRequired,
  view: PropTypes.string.isRequired,
  handleViewChange: PropTypes.func,
  generateReport: PropTypes.func.isRequired,
  timezoneOffsetFromUTC: PropTypes.string,
  selectedViolationFilter: PropTypes.object,
};

export default ViolationDaysView;
