export const StatusTypes = Object.freeze({
  'CANCELLED': {
    status: 0,
    description: 'Cancelled',
    key: 'CANCELLED',
  },
  'COMPLETED': {
    status: 1,
    description: 'Completed',
    key: 'COMPLETED',
  },
  'SCHEDULED': {
    status: 2,
    description: 'Scheduled',
    key: 'SCHEDULED',
  },
  'SCHEDULED_DELAYED': {
    status: 3,
    description: 'Scheduled - Delayed',
    key: 'SCHEDULED_DELAYED',
  },
  'IN_TRANSIT': {
    status: 4,
    description: 'In Transit',
    key: 'IN_TRANSIT',
  },
  'IN_TRANSIT_PRELOAD': {
    status: 5,
    description: 'In Transit - Preload',
    key: 'IN_TRANSIT_PRELOAD',
  },
  'IN_TRANSIT_DOCK': {
    status: 6,
    description: 'In Transit - Dock',
    key: 'IN_TRANSIT_DOCK',
  },
  'IN_TRANSIT_DELAYED': {
    status: 7,
    description: 'In Transit - Delayed',
    key: 'IN_TRANSIT_DELAYED',
  },
});

export const ActionTypes = Object.freeze({
  'PICKUP': 0,
  'DROPOFF': 1
});

export const getActionType = (value) => {
  return Object.keys(ActionTypes).find(key => ActionTypes[key] === value);
}

export const CurrentStatusTypes = Object.freeze({
  'UNASSIGNED': {
    status: 0,
    description: 'Unassigned',
    key: 'UNASSIGNED',
  },
  'ASSIGNED': {
    status: 1,
    description: 'Assigned',
    key: 'ASSIGNED',
  },
  'IN_TRANSIT': {
    status: 2,
    description: 'In Transit',
    key: 'IN_TRANSIT',
  },
  'LOADING': {
    status: 3,
    description: 'Loading',
    key: 'LOADING',
  },
  'UNLOADING': {
    status: 4,
    description: 'Unloading',
    key: 'UNLOADING',
  },
  'OFF_DUTY': {
    status: 5,
    description: 'Off Duty',
    key: 'OFF_DUTY',
  },
});
