/** @module DispatchReceivable */

// CSAPI API
import {
  callCloudFunction, copyQuery, count, createQuery, createQueryOr, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, getCurrentUser, addRecord,
} from 'sb-csapi/dist/AAPI';

// CSAPI Enums
import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

// CSAPI sbObjects
import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @memberof module:DispatchReceivable
 *
 * @description Get DispatchReceivable according to filter criteria
 *
 * @param {object} options - See example
 * @param {string} companyObjectId - Company we wish to retrieve DispatchReceivables for
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {bool} includeChildCompanies - Include child/sub-companies of this parent company
 * @param {int} page - The current page for pagination
 * @param {int} limit - The limit of records obtained per pagination
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { object } - { dispatchOrganizations: [DispatchOrganization, ...] }
 */
async function getDispatchReceivables(options = { sessionToken: getCurrentUserSessionToken() }, companyObjectId = getCurrentUserCompanyObjectId(), includeChildCompanies, filters = [], sortBy = new Sort(QuerySortOrder.DESCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  const dispatchReceivableQuery = createQuery('DispatchReceivable');

  const _filters = [...filters];

  const companyQuery = createQuery('Company');
  setQueryRestriction(companyQuery, QueryRestriction.EQUAL_TO, 'objectId', companyObjectId);

  // Lock queries to the current users company
  const dispatchJobAccountingQuery = createQuery('DispatchJobAccounting');
  setQueryRestriction(dispatchJobAccountingQuery, QueryRestriction.MATCHES_QUERY, 'belongsToCompany', companyQuery);

  // Also Lock queries to the DispatchReceivables that are linked to the DispatchJobAccounting records
  setQueryRestriction(dispatchReceivableQuery, QueryRestriction.MATCHES_QUERY, 'dispatchJobAccounting', dispatchJobAccountingQuery);

  // Filter
  _filters.map(filter => setQueryRestriction(dispatchReceivableQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  const dispatchReceivableCountQuery = copyQuery(dispatchReceivableQuery);

  // Sort
  sortQuery(dispatchReceivableQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchReceivableQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchReceivableQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(dispatchReceivableQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(dispatchReceivableQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the count and the jobs
  const promises = [count(options, dispatchReceivableCountQuery), findRecords(options, dispatchReceivableQuery, false, queryAll)];

  try {
    const [totalDispatchReceivablesCount, dispatchReceivables] = await Promise.all(promises);
    return { totalDispatchReceivablesCount, dispatchReceivables };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchReceivable
 *
 * @description Retrieve a DispatchReceivable given a DispatchDocument (must be an invoice)
 *
 * @param {Object} keyValueObj - The select fields we wish to update
 *
 * @returns {DispatchReceivable} - The created DispatchReceivable
 */
async function getDispatchReceivableByDispatchDocumentInvoice(options = { sessionToken: getCurrentUserSessionToken() }, dispatchDocumentInvoice, includedPointers = [], selectedAttributes = []) {
  if (!dispatchDocumentInvoice) throw new Error('Cannot create record: Requires dispatchDocumentInvoice argument');
  const dispatchReceivableQuery = createQuery('DispatchReceivable');
  setQueryRestriction(dispatchReceivableQuery, QueryRestriction.EQUAL_TO, 'dispatchDocumentInvoice', dispatchDocumentInvoice);

  if (includedPointers.length > 0) includePointers(dispatchReceivableQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchReceivableQuery, selectedAttributes);

  try {
    const dispatchReceivable = await findRecords(options, dispatchReceivableQuery, true);
    return dispatchReceivable;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchReceivable
 *
 * @description Update a DispatchReceivable record
 * @param {Object} options - Currently contains the current user session token
 * @param {DispatchReceivable} dispatchReceivable - The DispatchReceivable record to update. Use if dispatchReceivableObjectId is unavailable
 * @param {string} dispatchReceivableObjectId - The objectId of the dispatchReceivable record to update. Use if parameter dispatchReceivable is unavailable
 * @param {object} keyValueObj - The select fields we wish to update
 * @param {bool} save - If true, executes save on the changes. If false, holds the changes locally but is not saved
 *
 * @returns {DispatchReceivable} - The updated DispatchReceivable record
 */
async function updateDispatchReceivable(options = { sessionToken: getCurrentUserSessionToken() }, dispatchReceivable, dispatchReceivableObjectId, keyValueObj, save) {
  if (!dispatchReceivable && !dispatchReceivableObjectId) throw new Error('Missing Arguments: Must provide dispatchReceivable record or dispatchJobObjectId');
  if (dispatchReceivable && dispatchReceivableObjectId) throw new Error('Arguments: Must provide only one of dispatchReceivable record or dispatchJobObjectId');

  let _dispatchReceivable = dispatchReceivable;

  try {
    if (!dispatchReceivable && dispatchReceivableObjectId) {
      const dispatchReceivableQuery = createQuery('DispatchReceivable');
      setQueryRestriction(dispatchReceivableQuery, QueryRestriction.EQUAL_TO, 'objectId', dispatchReceivableObjectId);
      _dispatchReceivable = await findRecords(options, dispatchReceivableQuery, true);
    }

    if (_dispatchReceivable) {
      if (!Object.keys(keyValueObj).length) return _dispatchReceivable;

      _dispatchReceivable = await updateRecord(options, _dispatchReceivable, keyValueObj, save);
      return _dispatchReceivable;
    } else {
      throw new Error('DispatchReceivable record does not exist');
    }
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchReceivable
 *
 * @description Add a Dispatch Receivable
 *
 * @param {Object} keyValueObj - The select fields we wish to update
 *
 * @returns {DispatchReceivable} - The created DispatchReceivable
 */
async function addDispatchReceivable(keyValueObj) {
  try {
    const dispatchReceivable = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'DispatchReceivable', keyValueObj, getCurrentUserCompanyObjectId());
    return dispatchReceivable;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchReceivable
 *
 * @description Void a DispatchReceivable and append a given messages to its notes
 *
 * @param {DispatchReceivable} dispatchReceivable - The selected DispatchReceivable
 * @param {String} message - The message to append to the notes
 *
 * @returns {DispatchReceivable} - The created DispatchReceivable
 */
async function voidDispatchReceivable(dispatchReceivable, message) {
  const notes = getAttribute(dispatchReceivable, 'notes') || '';
  const updatedNotes = `${notes}\n ${message}`;
  try {
    const updatedDispatchReceivable = await updateDispatchReceivable(undefined, dispatchReceivable, undefined, { isVoided: true, notes: updatedNotes }, true);
    return updatedDispatchReceivable;
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getDispatchReceivables,
  getDispatchReceivableByDispatchDocumentInvoice,
  updateDispatchReceivable,
  addDispatchReceivable,
  voidDispatchReceivable,
};
