/* global StripeCheckout */
import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';

const colourLogo = require('assets/images/colour-logo.png');

class PaymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.showCheckout = this.showCheckout.bind(this);
  }

  showCheckout() {
    const subscriptionObj = this.props.Subscription.subscription;

    this.handler = StripeCheckout.configure({
      key: this.props.Subscription.stripePublishableKey,
      image: colourLogo,
      locale: window.Localize ? window.Localize.getLanguage() : 'en',
      token: (token) => {
        this.props.handleStripeToken(token);
      },
    });

    // Translate the strings we provide
    const descriptionToTranslate = this.props.label || 'Monthly Subscription';
    const panelLabelToTranslate = this.props.label || 'Start';
    // Open Checkout with further options:
    this.handler.open({
      name: 'Switchboard',
      description: t(descriptionToTranslate),
      amount: this.props.monthlyFee,
      currency: this.props.currency,
      panelLabel: t(panelLabelToTranslate),
      zipCode: true,
      billingAddress: true,
      closed: () => {
        this.props.handleStripeToken();
      },
    });
    // e.preventDefault();
  }

  render() {
    if (this.props.showCheckout) {
      this.showCheckout();
    }
    return (
      <div />
    );
  }
}

PaymentModal.propTypes = {
  monthlyFee: PropTypes.number,
  currency: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  Subscription: PropTypes.object.isRequired,
  showCheckout: PropTypes.bool.isRequired,
  handleStripeToken: PropTypes.func.isRequired,
  description: PropTypes.string,
};

export default PaymentModal;
