import React from 'react';
import PropTypes from 'prop-types';
import { MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";

// CSS
import styles from './FilterDropdown.module.scss';

class FilterDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
    };
    this.getSelectedItem = this.getSelectedItem.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps !== this.props || nextState !== this.state) {
      return true;
    }
    return false;
  }

  getSelectedItem(value, placeholder) {
    if (!this.props.disableTitleChange) {
      this.state = Object.assign({}, { title: placeholder });
    }
    this.props.handleFilter(value);
    this.setState(this.state);
  }

  render() {
    return (
      <MDBDropdown id={this.props.id} disabled={this.props.disabled} className={this.props.className}>
        <MDBDropdownToggle caret color="primary" className="w-100 translate-me">
          {this.state.title}
        </MDBDropdownToggle>
        <MDBDropdownMenu basic className="translate-me">
          {
            this.props.menuItems.map(item => {
              if (item.type === 'divider') {
                return <MDBDropdownItem key={item.attrName} divider />;
              }
              return (
                <MDBDropdownItem
                  className={styles.menuItem + ' ' + this.props.menuItemClassName}
                  onClick={(e) => this.getSelectedItem(item.attrName, item.placeholder)}
                  key={item.attrName}
                >
                  {item.placeholder}
                </MDBDropdownItem>
              );
            })
          }
        </MDBDropdownMenu>
      </MDBDropdown>
    );
  }
}

FilterDropdown.propTypes = {
  className: PropTypes.string,
  menuItemClassName: PropTypes.string,
  disabled: PropTypes.bool,
  disableTitleChange: PropTypes.bool,
  handleFilter: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  menuItems: PropTypes.array.isRequired,
  size: PropTypes.string,
  title: PropTypes.string
};

export default FilterDropdown;
