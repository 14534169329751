/** @module APIAuth */

// API
import * as Analytics from 'api/Analytics';
import {
  createQuery, findRecords, updateRecord,
  getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction, getCurrentUserCompany,
  setReturnSelectAttributes, sortQuery, getCurrentUser, getRecordByObjectId,
} from 'sb-csapi/dist/AAPI';
import * as Email from 'api/Email/Email';

// Enums
import { QuerySortOrder, QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';

// Sort
import Sort from 'sb-csapi/dist/sbObjects/Sort';

// Filter
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @memberof module:VendorIntegration
 * @description Obtains VendorIntegration record for a given company
 *
 * @param {object} [options] - Object containing the session token to use for requests
 * @param {array} [filters] - Array of Filter objects
 * @param {object} [sortBy] - Sort object
 * @param {array} [includedPointers] - Included pointers
 * @param {array} [selectedAttributes] - Select attributes to return
 *
 * @returns {object} - { apiAuths: [] }
 */
async function getVendorIntegration(
  options = { sessionToken: getCurrentUserSessionToken },
  filters = [],
  sortBy = new Sort(QuerySortOrder.DESCENDING, 'createdAt'),
  includedPointers = [],
  selectedAttributes = []
) {
  const companyObjectId = getCurrentUserCompanyObjectId();

  const _filters = [...filters];

  const query = createQuery('VendorIntegration');
  setQueryRestriction(query, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', companyObjectId);

  // Set query restrictions from filter
  _filters.map(filter => setQueryRestriction(query, filter.queryRestriction, filter.attribute, filter.value));

  if (includedPointers.length > 0) includePointers(query, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(query, selectedAttributes);

  // Sort the records by the sortBy
  sortQuery(query, sortBy.order, sortBy.attribute);
  // Find all records
  const promises = [findRecords(options, query, true, false)];

  try {
    const result = await Promise.all(promises);
    return result.length > 0 && result[0];
  } catch (err) {
    throw new Error(err);
  }
}

async function requestVendorIntegration(integrationType) {

  const company = getCurrentUserCompany();
  const user = getCurrentUser();

  Analytics.track('Integration Interest');
  Email.send(
    'Switchboard Integration Interest',
    'contact@onswitchboard.com',
    'team@onswitchboard.com',
    `[Integration Request] ${company.get('name')}`,
    `Company ${company.get('name')} is interested in ${integrationType} integration. Please give them a call.

    Name: ${user.get('firstName')} ${user.get('lastName')}
    Phone Number: ${user.get('phoneNumberCleaned') || company.get('phoneNumber')}
    Email: ${user.get('email')}
    `,
  );
}


export {
  getVendorIntegration,
  requestVendorIntegration,
};
