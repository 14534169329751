import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { numberWithCommas, convertDistance } from 'api/Helpers';
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { getOdometerReadings, sortELDEvents, isAOBRDEnabledByCount } from 'api/ELD';

import {
  Table,
  TableHeader,
  TableRow,
  TableRowColumn,
  TableHeaderColumn,
  TableBody,
  RaisedButton,
} from 'material-ui';

import Title from 'components/LayoutTitle/view/Title';

class OdometerReadings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      odometerReadingsByVehicle: {},
      firstFoundCoDriverELDEvent: undefined,
      distanceUnit: undefined,
    };
    this.getOdometerReadings = this.getOdometerReadings.bind(this);
  }

  componentDidMount() {

  }

  componentWillReceiveProps = (nextProps) => {
    this.getOdometerReadings(nextProps.eldEvents);
  }

  getOdometerReadings = async (eldEvents, stopCorrectionsBool) => {
    const distanceUnit = getPDispatcherPropertyFromState('distanceUnit');

    const _eldEvents = eldEvents.filter(eldEvent => {
      return eldEvent.get('eldEventRecordStatusInt') === 1;
    });
    const firstFoundCoDriverELDEvent = _eldEvents.find(eldEvent => {
      const coDriver = eldEvent.get('coDriver');
      return coDriver;
    });

    let odometerReadingsByVehicle;
    if (firstFoundCoDriverELDEvent) {
      try {
        odometerReadingsByVehicle = await getOdometerReadings(_eldEvents, distanceUnit, false, false, true, true, true);
      } catch (error) {
        odometerReadingsByVehicle = await getOdometerReadings(_eldEvents, distanceUnit, false, false, true, true, false);
      }
    } else {
      odometerReadingsByVehicle = await getOdometerReadings(_eldEvents, distanceUnit, false, false, true, true, false);
    }
    // console.log(odometerReadingsByVehicle);
    this.setState({ ...this.state, odometerReadingsByVehicle, distanceUnit, firstFoundCoDriverELDEvent });
  }


  render() {
    const { eldEvents, company, toggleEditOdometerReading, disableELDEdit } = this.props;
    const { odometerReadingsByVehicle, distanceUnit, firstFoundCoDriverELDEvent } = this.state;
    const tableHeaderColumnStyle = { width: '25%' };
    const aobrdSetting = company.get('aobrdSetting');


    let odometerReadingsRows = Object.keys(odometerReadingsByVehicle).map(unitId => {
      const vehicleReading = odometerReadingsByVehicle[unitId];
      const firstReading = Math.floor(vehicleReading.odometerStart);
      const rawEndReading = Math.floor(vehicleReading.odometerEnd);
      let lastReading = Math.floor(vehicleReading.odometerEnd);
      let difference = Math.floor(lastReading) - Math.floor(firstReading);
      if (vehicleReading.dayHasCoDriver) {
        lastReading = Math.floor(vehicleReading.odometerEndTotal);
        if (vehicleReading.nonCoDriverTotal) difference = Math.floor(vehicleReading.nonCoDriverTotal);
      }
      return (
        <TableRow key={vehicleReading.vehicle.id}>
          <TableRowColumn className="verticalAlignMiddle">
            <span>{vehicleReading.vehicle.get('unitId') || 'No Unit ID'}</span>
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle">
            {numberWithCommas(firstReading)}
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle">
            {numberWithCommas(lastReading)}
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle">
            {numberWithCommas(difference)}
          </TableRowColumn>
          {firstFoundCoDriverELDEvent &&
            <TableRowColumn className="verticalAlignMiddle">
              {numberWithCommas(rawEndReading)}
            </TableRowColumn>
          }
          {(aobrdSetting && aobrdSetting.get('allowOdometerEdit')) && isAOBRDEnabledByCount(eldEvents) &&
            <TableRowColumn className="verticalAlignMiddle">
              {!!(vehicleReading.odometerStart !== undefined && vehicleReading.odometerEnd !== undefined) &&
                <div style={{ float: 'right' }}>
                  <RaisedButton
                    key="editOdometer"
                    label="Edit"
                    default
                    onClick={() => toggleEditOdometerReading(vehicleReading.vehicle, vehicleReading.odometerStart, vehicleReading.odometerEnd)}
                    disabled={disableELDEdit}
                  />
                </div>
              }
            </TableRowColumn>
          }
        </TableRow>
      );
    });
    if (odometerReadingsRows.length === 0) {
      odometerReadingsRows = (
        <TableRow><TableRowColumn colSpan="69" className="emptyRowMessage verticalAlignMiddle">
          No Readings Logged This Day
        </TableRowColumn></TableRow>
      );
    }

    let unifiedOdometerReadingsRows = this.props.unifiedOdometerReadings && Object.keys(this.props.unifiedOdometerReadings).map(vehicleUnitId => {
      const vehicleReading = this.props.unifiedOdometerReadings[vehicleUnitId];
      const firstReading = vehicleReading.totalOdometerKmStart;
      const lastReading = vehicleReading.totalOdometerKmEnd;
      let difference = vehicleReading.totalOdometerKmDiff;

      return (
        <TableRow key={vehicleUnitId}>
          <TableRowColumn className="verticalAlignMiddle">
            <span>{vehicleUnitId || 'No Unit ID'}</span>
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle">
            {numberWithCommas(Math.floor(distanceUnit && distanceUnit.toLowerCase() === 'mi' ? convertDistance(firstReading, 'km', 'mi', true) : firstReading))}
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle">
            {numberWithCommas(Math.floor(distanceUnit && distanceUnit.toLowerCase() === 'mi' ? convertDistance(lastReading, 'km', 'mi', true) : lastReading))}
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle">
            {numberWithCommas(Math.floor(distanceUnit && distanceUnit.toLowerCase() === 'mi' ? convertDistance(difference, 'km', 'mi', true) : difference))}
          </TableRowColumn>
        </TableRow>
      );
    });
    if (!unifiedOdometerReadingsRows || unifiedOdometerReadingsRows.length === 0) {
      unifiedOdometerReadingsRows = (
        <TableRow><TableRowColumn colSpan="69" className="emptyRowMessage verticalAlignMiddle">
          No Readings Logged This Day
        </TableRowColumn></TableRow>
      );
    }

    const eldDailyCertification = eldEvents && eldEvents.length > 0 && eldEvents[0].get('eldDailyCertification');
    const timezoneOffsetFromUTC = (eldDailyCertification && eldDailyCertification.get('timezoneOffsetFromUTC')) || moment.tz.guess();
    const useUnifiedOdometerReadings = eldEvents && eldEvents.length > 0 && moment(eldEvents[0].get('eventDateTime')).tz(timezoneOffsetFromUTC).isAfter(moment('2020-09-30'));

    return (
      <div>
        {eldEvents && eldEvents.length > 0 && eldEvents[0].get('eldDailyCertification') &&
          <div style={{ overflowX: 'auto' }}>
            <Title className="h3" title={<span>{`Odometer Readings (${distanceUnit || 'KM'})`}</span>} />
            <Table wrapperStyle={{ overflowX: 'auto', minWidth: '600px' }} >
              <TableHeader
                displaySelectAll={false}
                adjustForCheckbox={false}
                enableSelectAll={false}
              >
                <TableRow>
                  <TableHeaderColumn style={tableHeaderColumnStyle}>
                    Vehicle
                  </TableHeaderColumn>
                  <TableHeaderColumn style={tableHeaderColumnStyle}>
                    Day Start
                  </TableHeaderColumn>
                  <TableHeaderColumn style={tableHeaderColumnStyle}>
                    Day End{firstFoundCoDriverELDEvent && ' by Driver'}
                  </TableHeaderColumn>
                  <TableHeaderColumn style={tableHeaderColumnStyle}>
                    Driven By Driver
                  </TableHeaderColumn>
                  {firstFoundCoDriverELDEvent &&
                    <TableHeaderColumn style={tableHeaderColumnStyle}>
                      Odometer End (Include CoDrivers)
                    </TableHeaderColumn>
                  }
                  {(aobrdSetting && aobrdSetting.get('allowOdometerEdit')) && isAOBRDEnabledByCount(eldEvents) &&
                    <TableHeaderColumn style={tableHeaderColumnStyle} />
                  }
                </TableRow>
              </TableHeader>
              <TableBody
                displayRowCheckbox={false}
                showRowHover
              >
                {useUnifiedOdometerReadings ? unifiedOdometerReadingsRows : odometerReadingsRows}
              </TableBody>
            </Table>
          </div>
        }
      </div>
    );
  }

}

OdometerReadings.propTypes = {
  company: PropTypes.object,
  eldEvents: PropTypes.array,
  toggleEditOdometerReading: PropTypes.func,
  disableELDEdit: PropTypes.bool,
};

export default OdometerReadings;
