import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import * as Helpers from 'api/Helpers';
import * as ELD from 'api/ELD';

import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import HOSPreviewDialogView from '../view/HOSPreviewDialogView';

class HOSPreviewDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      show: false,
      eldDailyCertification: undefined,
      compareAgainstELDEvents: [],
      eldEvents: [],
      associatedELDEvents: [],
      isLoading: false,
    }
    this.obtainEventsForPreview = this.obtainEventsForPreview.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    if (this.props.autoFetch) {
      this.obtainEventsForPreview(this);
    }
  }

  componentWillReceiveProps() {
    // this.obtainEventsForPreview();
  }

  obtainEventsForPreview(refToThis) {
    // get active eld events, associated events, and associated eld edit for preview
    if (refToThis.props.driver) {
      this.setState({ ...refToThis.state, isLoading: true }, () => {
        ELD.getDriverELDEvents(refToThis.props.driver, { onDate: refToThis.props.onDate }).then(
          function (eldEventObject) {

            ELD.getAssociatedELDEvents(refToThis.props.driver, eldEventObject.eldDailyCertification, false, true).then(
              associatedELDEvents => {
                const newState = { ...refToThis.state };
                newState.eldDailyCertification = eldEventObject.eldDailyCertification;
                newState.compareAgainstELDEvents = ELD.sortELDEvents([].concat(eldEventObject.eldEvents), 1);
                newState.eldEvents = eldEventObject.eldEvents;

                // combine the edit in with the events
                const eldEdit = refToThis.props.eldEdit;
                if (eldEdit) {
                  const eldEventsToBeInactiveIds = eldEdit.get('eldEventsToBeInactive').map(event => {
                    return event.id;
                  });
                  const requestedELDEvents = eldEdit.get('requestedELDEvents').map(event => {
                    const clonedEvent = event.clone();
                    clonedEvent.set('eldEventRecordStatusInt', 1);
                    return clonedEvent;
                  });

                  // filter out events to be inactive
                  newState.eldEvents = [].concat(newState.eldEvents).filter(event => {
                    return eldEventsToBeInactiveIds.indexOf(event.id) === -1;
                  });

                  // add in requested events and sort
                  newState.eldEvents = newState.eldEvents.concat(requestedELDEvents);
                  ELD.sortELDEvents(newState.eldEvents, 1);
                }

                newState.associatedELDEvents = associatedELDEvents;
                newState.isLoading = false;
                refToThis.setState(newState);
              }
            );

          }
        );
      });
    }
  }

  handleClose () {
    this.setState({ ...this.state, show: false }, () => {
      if (this.props.handleClose) {
        this.props.handleClose();
      }
    });
  }

  render() {

    let eldDailyCertification;
    let compareAgainstELDEvents;
    let eldEvents;
    let associatedELDEvents;

    if (this.props.autoFetch) {
      eldDailyCertification = this.state.eldDailyCertification;
      compareAgainstELDEvents = this.state.compareAgainstELDEvents;
      eldEvents = this.state.eldEvents;
      associatedELDEvents = this.state.associatedELDEvents;
    } else {
      eldDailyCertification = this.props.eldDailyCertification;
      compareAgainstELDEvents = this.props.compareAgainstELDEvents;
      eldEvents = this.props.eldEvents;
      associatedELDEvents = this.props.associatedELDEvents;
    }

    let dialogButton;
    if (this.props.autoFetch) {
      dialogButton = undefined;
    } else {
      if (this.props.raised) {
        dialogButton = (
          <RaisedButton
            className="buttonSpacedLeft buttonSpacedRight"
            label={this.props.label || t('Preview Log')}
            onClick={() => this.setState({ ...this.state, show: true })}
            primary={this.props.primary}
            secondary={this.props.secondary}
            disabled={this.props.disabled}
          />
        );
      } else {
        dialogButton = (
          <FlatButton
            className="buttonSpacedLeft buttonSpacedRight"
            label={this.props.label || t('Preview Log')}
            onClick={() => this.setState({ ...this.state, show: true })}
            primary={this.props.primary}
            secondary={this.props.secondary}
            disabled={this.props.disabled}
          />
        );
      }
    }

    return (
      <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        { dialogButton }

        <HOSPreviewDialogView
          show={this.props.show || this.state.show}
          handleClose={this.handleClose}
          title={this.props.title || t('HOS Log Preview')}
          eldDailyCertification={eldDailyCertification}
          compareAgainstELDEvents={compareAgainstELDEvents}
          eldEvents={eldEvents}
          associatedELDEvents={associatedELDEvents}
          isLoading={this.state.isLoading}
        />

      </div>
    );
  }

}

HOSPreviewDialog.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,

  driver: PropTypes.object,
  onDate: PropTypes.instanceOf(Date),

  raised: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,

  // only if we pass in our own and not autofetch
  eldDailyCertification: PropTypes.object,
  compareAgainstELDEvents: PropTypes.array,
  eldEvents: PropTypes.array,
  associatedELDEvents: PropTypes.array,

  // only if we autofetch: ie. we dont pass in our own events for preview/use a user defined button to activate
  autoFetch: PropTypes.bool,
  handleClose: PropTypes.func,
  eldEdit: PropTypes.any,
};

export default HOSPreviewDialog;
