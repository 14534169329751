
import { addRecord, createQuery, setQueryRestriction, updateRecord, find, includePointers, getAttribute } from 'api/Parse';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { destroyRecord, createTempPointer, sortQuery, callCloudFunction } from '../Parse';
import Sort from 'sbObjects/Sort';
import { AttributeTypes } from 'enums/DispatchJob';
import { Query } from 'parse';
/**
 * @description given a dispatchJobObjectId, returns all dispatchItems belonging to the job (JobID matches)
 *              otherwise, return all dispatchItems matching the filter
 * @param {string} dispatchJobObjectId
 * @returns an array of DispatchItems 
 */
async function getDispatchItems(dispatchJobObjectId, sortBy = new Sort(AttributeTypes.CREATED_AT, QuerySortOrderTypes.ASCENDING), filters = []) {
  const dispatchItemQuery = createQuery('DispatchItem');
  if (dispatchJobObjectId) setQueryRestriction(dispatchItemQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchJob', dispatchJobObjectId);  
  setQueryRestriction(dispatchItemQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'isHidden', true);
  sortQuery(dispatchItemQuery, sortBy.order, sortBy.attribute);
  filters.map(filter => {
    setQueryRestriction(dispatchItemQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
  });
  includePointers(dispatchItemQuery, ['dispatchJob', 'quotePricing', 'declaredValuePricing', 'carrierPricing', 'customerPricing', 'origin', 'destination', 'origin.address', 'destination.address', 'dispatchTransfer']);
  const dispatchItems = await find(dispatchItemQuery, false, true);
  return dispatchItems;
}

async function getDispatchItem(objectId) {
  if (!objectId) { throw new Error('The Dispatch Item objectId is required.'); }
  const dispatchItemQuery = createQuery('DispatchItem');
  setQueryRestriction(dispatchItemQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', objectId);
  setQueryRestriction(dispatchItemQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'isHidden', true);
  includePointers(dispatchItemQuery, ['quotePricing', 'declaredValuePricing', 'carrierPricing', 'customerPricing', 'origin', 'destination', 'origin.address', 'destination.address']);
  const di = await find(dispatchItemQuery, true, false);
  if (!di) { throw new Error(`The Dispatch Item ${objectId} is not recognized`); }
  return di;
}

/**
 * @description given a dispatchItem, add it to the database
 * @param {Object} dispatchItem - DispatchItem sbobject
 * @returns the newly created dispatchItem
 */
async function addDispatchItem(dispatchItem) {
  if (!dispatchItem) { throw new Error('The Dispatch Item is required'); }
  // if (!dispatchItem.dispatchJob) { throw new Error('Requires dispatchItem.dispatchJob'); }
  const _dispatchItem = { ...dispatchItem };
  // if (_dispatchItem.origin) {
  //   const dispatchItemOriginObjectId = _dispatchItem.origin.objectId;
  //   const dispatchItemOrigin = createTempPointer('Address', dispatchItemOriginObjectId);
  //   delete _dispatchItem.origin;
  //   _dispatchItem.origin = dispatchItemOrigin;
  // }
  // if (_dispatchItem.destination) {
  //   const dispatchItemDestinationObjectId = _dispatchItem.destination.objectId;
  //   const dispatchItemDestination = createTempPointer('Address', dispatchItemDestinationObjectId);
  //   delete _dispatchItem.destination;
  //   _dispatchItem.destination = dispatchItemDestination;
  // }
  _dispatchItem.detailedView = false;
  _dispatchItem.isHidden = false;
  delete _dispatchItem.objectId;
  return await addRecord('DispatchItem', _dispatchItem);
}

/**
 * @description given a dispatchItemObjectId, set the isHidden property of the corresponding dispatchItem to true
 * @param {String} dispatchItemObjectId
 * @returns the dispatchItem
 */
async function removeDispatchItem(dispatchItemObjectId) {
  if (!dispatchItemObjectId) { throw new Error('The Dispatch Item ID is required.'); }
  const dispatchItemQuery = createQuery('DispatchItem');
  setQueryRestriction(dispatchItemQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', dispatchItemObjectId);
  const dispatchItem = await find(dispatchItemQuery, true, false);
  if (!dispatchItem) { throw new Error(`The Dispatch Item ${dispatchItemObjectId} is not recognized`); }
  try {
    const removedDispatchItem = await updateRecord(dispatchItem, { isHidden: true }, true);  
    // await callCloudFunction('deleteHiddenDispatchRowFromAlgoliaIndex', { className: 'DispatchItem', objectId: dispatchItemObjectId });
    return removedDispatchItem;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @description given a dispatchItemObjectId, delete the dispatchItem from the db
 * @param {String} dispatchItemObjectId
 * @returns the dispatchItem
 */
async function destroyDispatchItem(dispatchItemObjectId) {
  if (!dispatchItemObjectId) { throw new Error('The Dispatch Item ID is required.'); }
  const dispatchItemQuery = createQuery('DispatchItem');
  setQueryRestriction(dispatchItemQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', dispatchItemObjectId);
  const dispatchItem = await find(dispatchItemQuery, true, false);
  if (!dispatchItem) { throw new Error(`The Dispatch Item ${dispatchItemObjectId} is not recognized`); }
  return await destroyRecord(dispatchItem);
}

/**
 * @param {Object} dispatchItem
 */
async function updateDispatchItem(objectId, dispatchItemAttributes) {
  if (!dispatchItemAttributes) { throw new Error('A Dispatch Item Attribute is required.'); }
  const dispatchItemQuery = createQuery('DispatchItem');
  setQueryRestriction(dispatchItemQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', objectId);
  const dispatchItemResult = await find(dispatchItemQuery, true, false);
  if (!dispatchItemResult) { throw new Error(`The Dispatch Item ${objectId} is not recognized`); }
  const _dispatchItemAttributes = { ...dispatchItemAttributes };
  // if (_dispatchItemAttributes.origin) {
  //   const originPointer = createTempPointer('Address', getAttribute(_dispatchItemAttributes.origin, 'objectId'));
  //   delete _dispatchItemAttributes.origin;
  //   _dispatchItemAttributes.origin = originPointer;
  // }
  return await updateRecord(dispatchItemResult, _dispatchItemAttributes, true);
}

async function getDispatchItemsByTransferId(dispatchTransferObjectId) {
  if (!dispatchTransferObjectId) return;
  const dispatchItemQuery = createQuery('DispatchItem');
  setQueryRestriction(dispatchItemQuery, QueryRestrictionTypes.EQUAL_TO, 'dispatchTransfer', dispatchTransferObjectId);  
  return await find(dispatchItemQuery, false, true);
}

export {
  addDispatchItem,
  getDispatchItems,
  removeDispatchItem,
  updateDispatchItem,
  getDispatchItem,
  destroyDispatchItem,
  getDispatchItemsByTransferId,
};
