import React from 'react';
import moment from 'moment-timezone';
import { MDBBtn } from 'mdbreact';
import PropTypes from 'prop-types';

// Components
import SBTable from 'components/Shared/SBTable/SBTable';
import Checkbox from 'sbCore/Checkbox/Checkbox';
import InputLabel from 'sbCore/InputLabel/InputLabel';

// API
import * as Helpers from 'api/Helpers';

const VehiclesTable = (props) => {
  const tableHeaderStyles = {
    unitId: { width: '20%' },
    vehicleDetails: {
      width: '40%',
    },
    button: { width: '40%' }
  };

  const tableHeaderRows = [{
    key: 'sb-ifta-vehicles-list', columns: [
      {
        element: 
          <div>
            Vehicle
          </div>,
        props: { style: tableHeaderStyles.unitId },
      },
      {
        element: 
        <div className="field-checkbox mb-0">
          <div className="inlineBlock">
            <Checkbox
              checked={props.showInactiveVehicles}
              onChange={(event) => props.handleChangeShowInactiveVehicles(event.checked)}
            />
          </div>
          <label>Show Inactive Vehicles</label>
        </div>,
        props: { style: tableHeaderStyles.vehicleDetails },
      },
      {
        element: <div />,
        props: { style: tableHeaderStyles.button },
      }
    ]
  }];

  let tableBodyRows = props.vehiclesObj.vehicleArr
    .filter((vehicleObj) => props.showInactiveVehicles ? 
      true 
      : 
      vehicleObj.get('vehicleLocation') && moment(vehicleObj.get('vehicleLocation').get('dateTime')).isAfter(moment(props.dateStart))
    )
    .sort((a, b) => a - b)
    .map(vehicleObj => {
    const unitId = vehicleObj.get('unitId');
    const vehicleRowObj = {
      key: vehicleObj.get('unitId'),
      columns: [
        {
          element: (
            <div className="font-weight-bold">
              {vehicleObj.get('unitId')}
            </div>
          ),
          props: {
            className: 'table-body-column-style',
            style: {
              verticalAlign: 'middle',
            }
          }
        },
        {
          element: (
            <div>
              {vehicleObj.get('vehicleLocation') &&
                (
                  <div>
                    Last Updated: <b>{moment(vehicleObj.get('vehicleLocation').get('dateTime')).format('ll')}</b>
                    &nbsp; <i>{Helpers.getFormattedLocationDescription(vehicleObj.get('vehicleLocation'))}</i>
                  </div>
                )
              }
            </div>
          ),
          props: {
            className: 'table-body-column-style',
            style: {
              width: '40%',
              fontSize: '0.9em',
              fontWeight: '400',
              verticalAlign: 'middle',
            },
          },
        },
        {
          element:
            <MDBBtn
              size="md"
              onClick={() => props.selectVehicle(unitId)}
              style={{ fontWeight: '500' }}
              color="primary"
            >
              {`VIEW ${unitId} MILEAGE REPORT`}
            </MDBBtn>,
          props: {
            className: 'table-body-column-style',
            style: {
              verticalAlign: 'middle',
            }
          }
        }
      ],
    };

    return vehicleRowObj;
  });

  return (
    <SBTable
      hover
      tableHeaderRows={tableHeaderRows}
      tableBodyRows={tableBodyRows}
    />
  );
};

export default VehiclesTable;
