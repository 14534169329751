import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// components
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import Dropdown from 'sbCore/Dropdown/Dropdown';

// styles
import './style.scss';

/**
 * Helper components (that don't need their own folder)
 */
// Dropdown for CSV headers
function HeaderDropdown({ ...props }) {
  const options = [...props.options];
  let value = '';
  if (props.value) {
    value = props.value;
  }

  return (
    <Dropdown
      value={value}
      options={options}
      onChange={(e) => props.onChange(e)}
      variant="inputsmall"
      disabled={props.isLoading}
    />
  );
}

/**
 * @description Table display for CSVUploader
 *
 * @todo 2024-05-16 - At the moment, we are only supporting 1 file at a time. So we will only use filesBreakdownArray[0]
 *
 * @param {String} className
 * @param {Array} filesBreakdownArray
 * @param {Object} headerMap
 * @param {String} miscHeaderName = The default prefix for misc columns
 * @param {Function} [getUploadContent] - Returns the current state of information for/in the upload
 * @param {Boolean} [isLoading] - Whether the button should be loading according to a parent
 * @param {Function} [onChangeHeader] - Returns updated headerMap (note: Different format than props.headerMap)
 * @returns
 */
function CSVDataTable({ ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [fileBreakdown, setFileBreakdown] = useState({});
  const [headerMap, setHeaderMap] = useState({}); // note this headerMap is changed from props.headerMap (convertHeaderMap())
  const [tableColumns, setTableColumns] = useState([]);
  const [hasChangedHeader, setHasChangedHeader] = useState(false); // whether or not the user has reassigned headers

  const [isFetched, setIsFetched] = useState(false); // indicates if all info needed is set

  useEffect(() => {
    // Initializer
    setIsLoading(true);
    setIsFetched(false);
    let didCancel = false;

    async function init() {
      const _isLoading = props.isLoading ?? false;
      const filesBreakdownArray = props.filesBreakdownArray || [];
      const _fileBreakdown = filesBreakdownArray[0] || {};

      const _headerMap = hasChangedHeader ? headerMap : convertHeaderMap(props.headerMap, _fileBreakdown);

      if (!didCancel) {
        setHeaderMap(_headerMap);

        if (props.filesBreakdownArray) {
          setFileBreakdown(_fileBreakdown);
        }

        setIsLoading(_isLoading);
        setIsFetched(true);
      }
    }

    init();
    return () => { didCancel = true; };
  }, [props.headerMap, props.filesBreakdownArray]);

  useEffect(() => {
    generateTableColumns();
  }, [isLoading, fileBreakdown, headerMap]);

  useEffect(() => {
    // At this point, we can pass the info we are displaying in the table up to the parent
    // so that it can process/validate the information
    if (isFetched) {
      if (props.getUploadContent) props.getUploadContent(headerMap, fileBreakdown);
    }
  }, [props.filesBreakdownArray, fileBreakdown, headerMap, isFetched]);

  useEffect(() => {
    const _isLoading = props.isLoading ?? false;
    setIsLoading(_isLoading);
  }, [props.isLoading]);

  /**
   * Functions
   */
  // Reformats headerMap from the parent to how this component will use it
  // Note this headerMap is different than the headerColumnMap, as headerColumnMap
  // is the mapping for each individual file. This map is for the whole datatable
  function convertHeaderMap(headerMap = {}, fileBreakdown) {
    const _headerMap = {};
    const { headerColumnMap, content } = fileBreakdown;

    // how many total columns will there be
    const contentLength = content[0] ? Object.keys(content[0]).length : 0;

    for (let i = 0; i < contentLength; i++) {
      _headerMap[i] = {
        index: i,                       // the column of the content in the datatable
        headerName: `${props.miscHeaderName} ${i + 1}`,    // name of the column in the datatable. If it is a Misc column, it may appear as a blank header
        headerColumnMapIndex: -1,       // the column index of the header in the uploaded file
      };

      // figure out if this i corresponds to an index in headerColumnMap, if so, we have a matching header
      const headerColumns = Object.values(headerColumnMap);

      for (let j = 0; j < headerColumns.length; j++) {
        const headerColumn = headerColumns[j];
        if (headerColumn.index === i) {
          _headerMap[i].headerName = headerColumn.headerName;
          _headerMap[i].headerColumnMapIndex = headerColumn.index;

          break;
        }
      }
    }

    // at this point we have created a headerMap for all columns that coincide with a column in the datatable, but not for
    // any columns in props.headerMap that don't have a matching column in the datatable. we include them here
    const headerNames = Object.values(_headerMap).map((header) => header.headerName);
    const propsHeaderNames = Object.keys(props.headerMap);
    for (let j = 0; j < propsHeaderNames.length; j++) {
      const propsHeaderName = propsHeaderNames[j];
      if (!headerNames.includes(propsHeaderName)) {
        _headerMap[j + headerNames.length] = {
          index: -1,
          headerName: propsHeaderName,
          headerColumnMapIndex: -1,
        };
      }
    }
    return _headerMap;
  }

  // When a header selection is changed, update the headerMap
  function onChangeHeader(headerName, index) {
    setHasChangedHeader(true);

    // Swap the headerNames. Ex. If they choose "date" instead of "name", the headernames for those respective
    // indicies should be swapped
    const updatedHeaderMap = { ...headerMap };
    const headerNameToBeReplaced = headerMap[index].headerName;

    // Find the column that has the chosen headerName and swap it with headerNameToBeReplaced (the current column we're changing)
    const updatedHeaderMapKeys = Object.keys(updatedHeaderMap);

    for (let i = 0; i < updatedHeaderMapKeys.length; i++) {
      const key = updatedHeaderMapKeys[i];
      const updatedHeader = updatedHeaderMap[key];
      if (updatedHeader.headerName === headerName) {
        updatedHeaderMap[key] = { ...updatedHeaderMap[key], headerName: headerNameToBeReplaced };
        break;
      }
    }

    // Give headerName to the selected column
    updatedHeaderMap[index] = { ...updatedHeaderMap[index], headerName };

    setHeaderMap(updatedHeaderMap);
    if (props.onChangeHeader) props.onChangeHeader(updatedHeaderMap);
  }

  /**
   * JSX
   */
  function headerTemplate(headerName, index) {
    let _headerName = '';
    if (headerName) _headerName = headerName;

    // just get the list of headerNames
    let options = Object.values(headerMap).map(({ headerName }) => headerName).sort();
    options = options.filter(headerName => !headerName.includes(props.miscHeaderName));

    return (
      <HeaderDropdown
        options={options}
        value={_headerName}
        onChange={(e) => onChangeHeader(e.value, index)}
        isLoading={isLoading}
      />
    );
  }

  function contentTemplate(rowData, index) {
    const values = Object.values(rowData); // values of each column of a row in the file
    const value = values[index]; // value essentially represents the "cell" of the row that we want to show

    return <div>{value}</div>;
  }

  function generateTableColumns() {
    const _tableColumns = [];

    // first for loop to create columns for the headers only (and their values)
    const headerColumns = Object.values(headerMap);
    for (let i = 0; i < headerColumns.length; i++) {
      const headerColumn = headerColumns[i];
      const { headerName, index } = headerColumn;

      if (index === -1) continue;

      const id = uniqid();

      _tableColumns.push(
        <Column
          key={id}
          field={id} // this shouldn't matter, we just need a placeholder
          header={(rowData) => headerTemplate(headerName, i)}
          body={(rowData) => contentTemplate(rowData, i)}
        />,
      );
    }

    setTableColumns(_tableColumns);
  }

  let className = 'csv-data-table';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <DataTable
        value={fileBreakdown.content || []}
        selection={undefined}
        scrollable
        scrollHeight={props.scrollHeight || '50vh'}
      >
        {tableColumns}
      </DataTable>
    </div>
  );
}

export default CSVDataTable;
