import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Table, TableHeader, TableBody, TableRow, TableRowColumn } from 'material-ui';

// API
import { convertHoursToMilliseconds } from 'api/Helpers';
import * as HoursCycle from 'api/Lists/HoursCycle';
import * as Getters from 'api/Getters';

// Components
import CycleHourCalcTableRow from '../view/CycleHourCalcTableRow';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './CycleHourCalcTable.module.scss';

const getCountryActivity = async (eldDailyCertification) => {
  const threshold = 5;

  const caEldEvents = await Getters.queryCompanyObjects('ELDEvent', undefined, threshold,
    [
      { queryType: 'equalTo', name: 'eldDailyCertification', value: eldDailyCertification },
      { queryType: 'equalTo', name: 'hosActiveCountryCode', value: 'CA' },
    ]);

  const usEldEvents = await Getters.queryCompanyObjects('ELDEvent', undefined, threshold,
    [
      { queryType: 'equalTo', name: 'eldDailyCertification', value: eldDailyCertification },
      { queryType: 'equalTo', name: 'hosActiveCountryCode', value: 'US' },
    ]);

  return {
    ca: caEldEvents.length === threshold,
    us: usEldEvents.length === threshold,
  }
}

class CycleHourCalcTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      recapDayArr: [],
      loading: false,
      eldDailyCertificationArr: [],
    };

    this.parseThroughELDDailyCertifications = this.parseThroughELDDailyCertifications.bind(this);
  }

  componentDidMount() {
    if (this.props.eldDailyCertificationArr && this.props.eldDailyCertificationArr.length > 0) {
      this.parseThroughELDDailyCertifications(this.props.eldDailyCertificationArr);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.eldDailyCertificationArr && nextProps.eldDailyCertificationArr.length > 0) {
      this.parseThroughELDDailyCertifications(nextProps.eldDailyCertificationArr);
    }
  }

  async setCountryActivity() {
    const newState = { ...this.state };
    for (let i = 0; i < this.state.recapDayArr.length; i++) {
      const recapDayObj = this.state.recapDayArr[i];

      const eldDailyCertification = recapDayObj.eldDailyCertification;
      const countryActivity = await getCountryActivity(eldDailyCertification);

      recapDayObj.workedInCanadaBool = countryActivity.ca;
      recapDayObj.workedInUSBool = countryActivity.us;

      this.setState({ ...this.state });
    }
  }

  parseThroughELDDailyCertifications(eldDailyCertificationArr) {
    let recapDayArr = [];
    const rowsToShow = Math.min(eldDailyCertificationArr.length, moment(eldDailyCertificationArr[0].get('startTimeUTC')).daysInMonth());
    const driverCycleIntCA = this.props.driver.get('hourCycleCanadaInt');
    const driverCycleIntUS = this.props.driver.get('hourCycleUSAInt');
    
    let cycleCountCA = 0;
    let cycleCountUS = 0;
    let cycleHoursRemainingCA;
    let cycleHoursRemainingUS;
    for (let i = rowsToShow - 1; i >= 0; i--) {
      // Going from latest to earliest
      let resetCABool = false;
      let resetUSBool = false;
      const eldDailyCertification = eldDailyCertificationArr[i];
      const dayBeforeEldDailyCertification = eldDailyCertificationArr[i + 1];
      const date = moment.tz(moment(eldDailyCertificationArr[i].get('startTimeUTC')), this.props.driver.get('timezoneOffsetFromUTC')).startOf('day').toDate();
      let onDutyConsumed = (eldDailyCertification.get('onDutyConsumed') || 0);

      // match between day & eldDailyCert

      // Setting remaining cycle time
      cycleHoursRemainingCA = eldDailyCertification.get('caOnDutyCycleHours');
      cycleHoursRemainingUS = eldDailyCertification.get('usOnDutyCycleHours');
      // if (!cycleHoursRemainingCA) cycleHoursRemainingCA = eldDailyCertification.get('caOnDutyCycleHours');
      // else cycleHoursRemainingCA -= onDutyConsumed;
      // if (!cycleHoursRemainingUS) cycleHoursRemainingUS = eldDailyCertification.get('usOnDutyCycleHours');
      // else cycleHoursRemainingUS -= onDutyConsumed;

      cycleHoursRemainingCA = Math.max(0, cycleHoursRemainingCA);
      cycleHoursRemainingUS = Math.max(0, cycleHoursRemainingUS);

      /*
      cycleCount: for determining if they're in the same "cycle" for separating them visually

      cycleResetInt: is if they did a reset for Canada or US:
        1: Did a reset in Canada (typically a 36 hour reset)
        2: Did a reset in the US (typically a 34 hour reset)
        3: Did a reset for both
      */

      // For Cycle Resets
      if (
        eldDailyCertification.get('cycleResetInt') === 3
        || eldDailyCertification.get('cycleResetInt') === 1
      ) {
        resetCABool = true;
        cycleCountCA++;
        if (!onDutyConsumed) cycleHoursRemainingCA = convertHoursToMilliseconds(HoursCycle.canHours[driverCycleIntCA])
        else cycleHoursRemainingCA = convertHoursToMilliseconds(HoursCycle.canHours[driverCycleIntCA]) - onDutyConsumed;
      }
      if (
        eldDailyCertification.get('cycleResetInt') === 2
        || eldDailyCertification.get('cycleResetInt') === 3
      ) {
        resetUSBool = true;
        cycleCountUS++;
        if (!onDutyConsumed) cycleHoursRemainingUS = convertHoursToMilliseconds(HoursCycle.canHours[driverCycleIntUS]);
        else cycleHoursRemainingUS = convertHoursToMilliseconds(HoursCycle.canHours[driverCycleIntUS]) - onDutyConsumed;
      }

      // console.log(
        //   date,
        //   eldDailyCertification.get('caOnDutyCycleHours'),
        //   eldDailyCertification.get('cycleResetInt'),
        //   `onDutyConsumed: ${onDutyConsumed}`,
        //   `cycleHoursRemainingCA: ${cycleHoursRemainingCA}`,
        //   `cycleHoursRemainingUS: ${cycleHoursRemainingUS}`,
      // )

      recapDayArr.unshift({
        date,
        cycleCountCA,
        cycleCountUS,
        onDutyConsumed,
        // caOnDutyCycleHours: eldDailyCertification.get('caOnDutyCycleHours'),
        // usOnDutyCycleHours: eldDailyCertification.get('usOnDutyCycleHours'),
        resetCABool,
        resetUSBool,
        cycleHoursRemainingCA,
        cycleHoursRemainingUS,
        eldDailyCertification,
      });
    }
    this.setState({ ...this.state, recapDayArr, onDutyToday: recapDayArr.length > 0 ? recapDayArr[0].onDutyConsumed : 0 }, () => {
      this.setCountryActivity();
    });
  }

  render() {
    console.log(this.state.recapDayArr);
    window.Localize.translatePage();
    return (
      <div className={styles.cycleHourCalcTable}>
        <Table
          fixedHeader
          selectable={false}
        >
          <TableHeader
            adjustForCheckbox={false}
            displaySelectAll={false}
            style={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            <TableRow className="translate-me">
              <TableRowColumn style={{ textAlign: 'center' }}>Date</TableRowColumn>
              <TableRowColumn style={{ textAlign: 'center' }}>On Duty Time</TableRowColumn>
              <TableRowColumn style={{ textAlign: 'center' }}>Worked in Canada/US</TableRowColumn>
              <TableRowColumn style={{ textAlign: 'center' }}>Canada Cycle Remaining</TableRowColumn>
              <TableRowColumn style={{ textAlign: 'center' }}>US Cycle Remaining</TableRowColumn>
            </TableRow>
          </TableHeader>
          <TableBody
            displayRowCheckbox={false}
          >
            {this.state.recapDayArr.map((recapDayObj, index) => (
              <CycleHourCalcTableRow
                key={`${index}${recapDayObj.date.toISOString()}`}
                index={index}
                driver={this.props.driver}
                daysInCycle={this.props.daysInCycle}
                recapDayObj={recapDayObj}
                onDutyToday={this.state.onDutyToday}
              />
            ))}
            {!this.props.fetched &&
              (
                <TableRow>
                  <TableRowColumn colSpan="42" className="loadingIconPadding">
                    <LoadingIcon />
                  </TableRowColumn>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </div>
    );
  }
}

CycleHourCalcTable.propTypes = {
  fetched: PropTypes.bool,
  eldDailyCertificationArr: PropTypes.array.isRequired,
  date: PropTypes.object,
  selectedCycle: PropTypes.string.isRequired,
  hoursInCycle: PropTypes.number.isRequired,
  daysInCycle: PropTypes.number.isRequired,
  driver: PropTypes.object.isRequired,
};

export default CycleHourCalcTable;
