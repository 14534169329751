import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableFooter, TableRow, TableRowColumn } from 'material-ui';

// API
import { scrolledToBottom } from 'api/Helpers';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

class LazyLoadTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lastScrollPos: 0,
      pageCount: 0,
      rowsPerFetch: props.height ? (props.height / (this.props.rowHeight)) + 10 : undefined,
    };
    this.scrollHandler = this.scrollHandler.bind(this);
  }

  componentDidMount() {
    if (!this.props.disableFetchOnInit && this.props.fetch) {
      this.props.fetch(this.state.pageCount, this.state.rowsPerFetch);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.resetBool && nextProps.resetBool) {
      // Reset limit
      this.setState({ ...this.state, pageCount: 0 });
    }
  }

  scrollHandler(e) {
    if (this.props.fetch && scrolledToBottom(e, 10, this.state.lastScrollPos)) {
      this.setState({ ...this.state, pageCount: this.state.pageCount + 1, lastScrollPos: e.target.scrollTop }, () => {
        this.props.fetch(this.state.pageCount, this.state.rowsPerFetch);
      });
    }
  }

  render() {
    return (
      <div onScroll={(e) => this.scrollHandler(e)}>
        <Table
          fixedHeader={this.props.fixedHeader}
          height={this.props.height && `${this.props.height}px`}
          bodyStyle={{ overflowY: 'overlay' }}
        >
          { this.props.children }
          { !this.props.fetched &&
            <TableFooter><TableRow>
              <TableRowColumn colSpan="42" className="loadingIconPadding">
                <LoadingIcon />
              </TableRowColumn>
            </TableRow></TableFooter>
          }
        </Table>
      </div>

    );
  }
}

LazyLoadTable.propTypes = {
  children: PropTypes.node.isRequired,
  fetched: PropTypes.bool,
  fixedHeader: PropTypes.bool,
  disableFetchOnInit: PropTypes.bool,
  fetch: PropTypes.func,
  resetBool: PropTypes.bool,
  rowHeight: PropTypes.number.isRequired,
  height: PropTypes.number,
};

export default LazyLoadTable;
