import { t } from 'api/Translate';
import React from 'react';
import moment from 'moment';
import history from 'sbHistory';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// API
import { queryCompanyObjects, getVehicles } from 'api/Getters';
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL';

// Components
import { Chip, Avatar, FontIcon } from 'material-ui';
import Title from 'components/LayoutTitle/view/Title';
import AccountLayout from './AccountLayout';
import DataLayout from './DataLayout';
import ActiveVehiclesLayout from './ActiveVehiclesLayout';
import IntegrationsLayout from './IntegrationsLayout/IntegrationsLayout';

// CSS
import styles from './SettingsLayout.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

class SettingsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: (location && getQueryParameter(location.search, 'tab')) || 'Account',
      simArr: [],
      vehicleArr: [],
    };
    this.changeTab = this.changeTab.bind(this);
  }

  async componentDidMount() {
    document.title = t('Settings - Switchboard');
    window.Localize.translatePage();

    const newState = { ...this.state };

    const vehicleArr = await getVehicles([
      { queryType: 'exists', name: 'vehicleLocation' },
      { queryType: 'exists', name: 'unitId' }
    ]);
    const simArr = await queryCompanyObjects('Sim');

    const uniqueSimArr = [];
    const uniqueIccidArr = [];

    // Unique Sims/Iccid
    for (let i = 0; i < simArr.length; i++) {
      const simObj = simArr[i];
      if (uniqueIccidArr.indexOf(simObj.get('iccid')) === -1) {
        uniqueSimArr.push(simObj);
        uniqueIccidArr.push(simObj.get('iccid'));
      }
    }

    const sortedVehicleArr = vehicleArr.sort((a, b) => {
      if (a && b && a.get('vehicleLocation') && b.get('vehicleLocation')) {
        return (b.get('vehicleLocation').get('dateTime') - a.get('vehicleLocation').get('dateTime'));
      } else if (a && a.get('vehicleLocation')) {
        return -1;
      } else if (b && b.get('vehicleLocation')) {
        return 1;
      }
      return 0;
    }).filter((vehicle) => (
      vehicle.get('vehicleLocation') &&
      vehicle.get('vehicleLocation').get('dateTime') &&
      moment(vehicle.get('vehicleLocation').get('dateTime')).isAfter(moment().subtract(6, 'months'))
    ));


    // UniqueVehicles
    this.setState({ newState, simArr: uniqueSimArr, vehicleArr: sortedVehicleArr });
  }

  changeTab(tab) {
    history.push({ search: getSerialized({ tab }).query });
    this.setState({ ...this.state, tab }, () => window.Localize.translatePage());
  }

  render() {
    const tab = (location && getQueryParameter(location.search, 'tab')) || 'Account';

    return (
      <div className={styles.settingsLayout}>
        <Title title={<div>Settings</div>} containerClassName="translate-me" />
        <div className="flex translate-me">
          <Chip
            onClick={() => this.changeTab('Account')}
            className={tab === 'Account' ? `${styles.chip} ${styles.activeTab}` : `${styles.chip}`}
          >
            <Avatar icon={<FontIcon className="material-icons">perm_identity</FontIcon>} />
            Account
          </Chip>

          {
            this.state.simArr.length > 0 &&
            (
              this.props.Company.company &&
              this.props.Company.company.get('companyModule') &&
              this.props.Company.company.get('companyModule').get('showDataUsage')
            ) &&
            (
              <Chip
                onClick={() => this.changeTab('Data')}
                className={tab === 'Data' ? `${styles.chip} ${styles.activeTab}` : `${styles.chip}`}
              >
                <Avatar icon={<FontIcon className="material-icons">sim_card</FontIcon>} />
                Devices
              </Chip>
            )
          }

          {
            this.state.vehicleArr.length > 0 &&
            (
              this.props.Company.company &&
              this.props.Company.company.get('companyModule') &&
              this.props.Company.company.get('companyModule').get('showActiveVehiclesListInSettingsPage')
            ) &&
            (
              <Chip
                onClick={() => this.changeTab('ActiveVehicles')}
                className={tab === 'ActiveVehicles' ? `${styles.chip} ${styles.activeTab}` : `${styles.chip}`}
              >
                <Avatar icon={<FontIcon className="material-icons">local_shipping</FontIcon>} />
                Active Vehicles
              </Chip>
            )
          }

          <Chip
            onClick={() => this.changeTab('Integrations')}
            className={tab === 'Integrations' ? `${styles.chip} ${styles.activeTab}` : `${styles.chip}`}
          >
            <Avatar icon={<FontIcon className="material-icons">key</FontIcon>} />
            Integrations & API Keys
          </Chip>
        </div>

        {this.props.Company.company && this.props.Company.company.id && this.props.User.user && this.props.Dispatcher.dispatcher && (
          <div>
            {tab === 'Account' && this.props.Dispatcher.dispatcher.id && this.props.Company.company.id && (
              <AccountLayout
                company={this.props.Company.company}
                User={this.props.User}
                Dispatcher={this.props.Dispatcher}
                Subscription={this.props.Subscription}
              />
            )}
            {tab === 'Subscription' && <div />
              // <SubscriptionLayout />
            }
            {tab === 'Data' && <DataLayout simArr={this.state.simArr} />}
            {tab === 'ActiveVehicles' && <ActiveVehiclesLayout vehicleArr={this.state.vehicleArr} />}
            {tab === 'Integrations' && <IntegrationsLayout />}
          </div>
        )}
      </div>
    );
  }
}

SettingsLayout.propTypes = {
  Company: PropTypes.object,
  Dispatcher: PropTypes.object,
  Subscription: PropTypes.object,
  User: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { Company, Dispatcher, User, Subscription } = state;
  return {
    Company,
    Dispatcher,
    Subscription,
    User,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(SettingsLayout);
