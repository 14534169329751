import uniqid from 'uniqid';
import React, { useState, useEffect } from 'react';

// API
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getVehicles } from 'api/Equipment/Vehicle';

// sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Enums
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// sbCore Components
import Autocomplete from 'sbCore/Autocomplete/Autocomplete';

// Styling
import './style.scss';

/**
 * @description Retrieve Vehicles given a search term
 *
 * @param {Object} [style] - Custom inline styles
 * @param {String} [className] - Custom container className
 * @param {String} [panelClassName] - Custom dropdown panel className
 * @param {String} [placeholder] - Custom placeholder text
 *
 * @param {bool} [warning] - Makes input border yellow
 * @param {bool} [multiple] - Boolean to allow multiple selections
 * @param {bool} [autoFocus] - Boolean to automatically focus on the autocomplete on load
 * @param {bool} [disabled] - Boolean to disable component
 * @param {bool} [hideSelectedVehicles] - Boolean to determine whether to hide search suggestions for already selected vehicles
 *
 * @param {Array<String>} [excludedVehicleObjectIds] - Array of vehicle objectIds to exclude from search suggestions
 * @param {Array<Filter>} [suggestionFilters] - Array of filters to apply to the search suggestions
 *
 * @param {Function} [onSelectVehicle] - Callback function that returns the selected Vehicle (when multiple is NOT true)
 * @param {Function} [onSelectVehicles] - Callback function that returns the selected Vehicles (when multiple is true)
 *
 * @returns {JSX} The autocomplete input component
 */
function VehicleAutocompleteInput({ ...props }) {
  // ** UseState ** //
  const [identifier] = useState(uniqid()); // This is in a useState so that the value does not change across renders

  const [searchTerm, setSearchTerm] = useState('');
  const [vehicleSuggestions, setVehicleSuggestions] = useState([]); // Array of vehicle suggestions
  const [selectedVehicles, setSelectedVehicles] = useState([]);

  const [hasError, setHasError] = useState(false);

  /**
   * @description Handles fetching for vehicle suggestions based on the query
   * @param {String} [query] - the query to search for (unit id of vehicle)
   */
  async function handleOnSuggestionSearch(query) {
    setHasError(false); // reset the error state

    const _query = (query || '').trim();
    setSearchTerm(_query);

    let filters = [
      ...(props.suggestionFilters || []),
      new Filter(QueryRestriction.LIMIT, undefined, 120),
      new Filter(QueryRestriction.EXISTS, 'unitId'),
    ];

    if (_query.length > 0) {
      filters = [
        ...(props.suggestionFilters || []),
        new Filter(QueryRestriction.LIMIT, undefined, 15),
        new Filter(QueryRestriction.MATCHES, 'unitId', _query),
      ];
    }

    const { vehicles } = await getVehicles(
      undefined, // options
      undefined, // companyObjectId
      undefined, // includeChildCompanies
      filters, // filters
      undefined, // sortBy
      undefined, // includedPointers
      undefined, // selectedAttributes
      undefined, // page
      undefined, // limit
      undefined, // queryAll
    );

    let _vehicleSuggestions = vehicles.map((vehicle) => ({
      objectId: getAttribute(vehicle, 'objectId'),
      unitId: getAttribute(vehicle, 'unitId'),
      vehicle,
    }));

    if (props.hideSelectedVehicles && props.multiple) {
      // Filter out any suggestions that have already been selected - this can only happen if multiple is true as the user can only select more than one vehicle at a time
      const selectedVehicleObjectIdArr = selectedVehicles.map((vehicle) => vehicle.objectId);
      _vehicleSuggestions = _vehicleSuggestions.filter((vehicle) => !selectedVehicleObjectIdArr.includes(vehicle.objectId));
    }

    if (props.excludedVehicleObjectIds) {
      // Filter out any suggestions that should be excluded
      _vehicleSuggestions = _vehicleSuggestions.filter((vehicle) => !props.excludedVehicleObjectIds.includes(vehicle.objectId));
    }

    if (_query.length > 0) {
      _vehicleSuggestions = _vehicleSuggestions.filter((vehicle) => vehicle.unitId && vehicle.unitId.includes(_query));
    }

    setVehicleSuggestions(_vehicleSuggestions);
    if (_vehicleSuggestions.length !== 0 && _query) setHasError(true);
  }

  // ** UseEffects ** //
  useEffect(() => {
    // Initial trigger to fetch vehicle suggestions
    // This also doubles as a way to allow inputEl and inputButtonEl to be fetched (otherwise, there is a slight delay where the inputEl and inputButtonEl are null)
    handleOnSuggestionSearch('');
  }, []);

  useEffect(() => {
    if (props.onSelectVehicle) {
      props.onSelectVehicle(selectedVehicles.vehicle);
    } else if (props.multiple && props.onSelectVehicles) {
      // Grab only the vehicle records that were selected
      const _selectedVehicles = selectedVehicles.map(vehicle => vehicle.vehicle);
      props.onSelectVehicles(_selectedVehicles);
    }
  }, [selectedVehicles]);

  // ** Templates ** //
  const itemTemplate = (item) => (
    <div className="vehicle-item">
      <div>{item.unitId || 'No Unit ID Defined'}</div>
    </div>
  );

  // ** Misc ** //
  const inputEl = document.querySelector(`.vehicle-autocomplete-input.${identifier} input[type="text"]`); // the autocomplete input element
  const inputButtonEl = document.querySelector(`.vehicle-autocomplete-input.${identifier} button.p-button`); // the autocomplete dropdown button

  if (inputEl) {
    inputEl.autocomplete = 'disabled'; // disable the browser's default autocomplete for inputs
  }

  const triggerAutocompleteOnClick = () => {
    // open dropdown if user focuses on input
    if (inputButtonEl) inputButtonEl.click();
  };

  const triggerAutocompleteOnKeyPress = (e) => {
    // if the input is focused
    if (inputButtonEl) {
      const code = (e.keyCode ? e.keyCode : e.which);
      const dropdownPanelEl = document.querySelector(`.vehicle-autocomplete-input-panel.${identifier}`);
      // Enter
      if (code === 13) {
        if (dropdownPanelEl && vehicleSuggestions.length) { // If the dropdown is open and not empty
          setSelectedVehicles(vehicleSuggestions[0]); // Select the first item in dropdown
          return;
        }

        inputButtonEl.click();
      }
    }
  };

  let className = `vehicle-autocomplete-input ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  let panelClassName = `vehicle-autocomplete-input-panel ${identifier}`;
  if (props.panelClassName) panelClassName += ` ${props.panelClassName}`;

  let placeholder = 'Vehicle Unit ID';
  if (props.placeholder) placeholder = props.placeholder;

  // if the input is not being focused on (which is for some reason dictated by the button's focus),
  // but the user left their search term without selecting a suggestion for the searchTerm
  const isNotFocusedWithText = inputButtonEl && searchTerm && (document.activeElement !== inputButtonEl);

  const _hasError = isNotFocusedWithText || hasError;

  return (
    <div className={className} style={{ ...props.style }}>
      <Autocomplete
        placeholder={placeholder}
        dropdown
        field="unitId"
        value={selectedVehicles}
        multiple={props.multiple}
        suggestions={vehicleSuggestions}
        forceSelection={false}
        itemTemplate={itemTemplate}
        autoFocus={props.autoFocus}
        warning={props.warning}
        error={_hasError}
        completeMethod={({ originalEvent, query }) => handleOnSuggestionSearch(query)}
        onChange={(e) => setSelectedVehicles(e.value)}
        onClick={() => triggerAutocompleteOnClick()}
        onKeyPress={(e) => triggerAutocompleteOnKeyPress(e)}
        panelClassName={panelClassName}
        disabled={props.disabled}
      />
    </div>
  );
}

export default VehicleAutocompleteInput;
