import React from 'react';
import PropTypes from 'prop-types';

// API
import * as Helpers from 'api/Helpers';

// Actions
import { fetchDocumentsForState, deleteDocumentsForState } from 'actions/Document';
import { fetchJobLinksForState, deleteJobLinksForState } from 'actions/JobLink';

// Components
import Title from 'components/LayoutTitle/view/Title';
import JobLinkDetailsSidebar from 'components/JobLinkDetails/container/JobLinkDetailsSidebar';

// CSS
import styles from './JobLinkDetailsLayout.module.scss';


class JobLinkDetailsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Loading...',
      user: {},
      driver: {},
      dispatchers: [],
      driverFetched: false,
      receipts: [],
      receiptsFetched: false,
    };
  }

  componentDidMount() {
    fetchJobLinksForState().then((jobLinks) => {
      const jobActions = jobLinks.map((jobLink) => jobLink.get('jobActions'));
      fetchDocumentsForState([{ key: 'jobAction', value: jobActions }]);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState !== this.state || nextProps.Driver !== this.props.Driver) {
      return true;
    }
    return false;
  }

  componentWillUpdate(nextProps, nextState) {
    // find out driver
    const drivers = [].concat(nextProps.Driver.drivers).concat(nextProps.Driver.driversMore);
    const driversLen = drivers.length;
    const { driverId } = this.props.params;
    const dispatchersForState = [];
    for (let i = 0; i < driversLen; i++) {
      const currentDriver = drivers[i];
      if (currentDriver.id === driverId) {
        const dispatchers = currentDriver.get('dispatchers');
        const driverUser = currentDriver.get('user');
        this.state.driver = currentDriver;
        this.state.user = driverUser;
        this.state.title = `${driverUser.get('firstName')} ${driverUser.get('lastName')}`;
        for (let j = 0; j < dispatchers.length; j++) {
          const dispatcher = dispatchers[j];
          const dispatcherUser = dispatcher.get('user');
          dispatchersForState.push(`${dispatcherUser.get('firstName')} ${dispatcherUser.get('lastName')}`);
        }
        this.state.dispatchers = dispatchersForState;
        this.state.driverFetched = true;
        break;
      }
    }
    // console.log(this.state.driver);
    // console.log(this.state.user);
    // console.log(this.state.dispatchers);
  }

  componentWillUnmount() {
    deleteJobLinksForState();
    deleteDocumentsForState();
  }

  // delete driver
 // documents (?)
  render() {
    const { user, driver, dispatchers, driverFetched } = this.state;
    return (
      <div className={styles.jobLinkDetailsLayout}>
        <JobLinkDetailsSidebar />
        <Title title={this.state.title} />
        <div>
        </div>
      </div>
    );
  }
}

JobLinkDetailsLayout.propTypes = {
  JobLink: PropTypes.object.isRequired,
};

export default JobLinkDetailsLayout;
