import { formatName } from 'api/Helpers';

export default class User {
  /**
   * 
   * @param {string} objectId
   * @param {string} username
   * @param {string} firstName
   * @param {string} lastName 
   * @param {string} email
   * @param {string} phoneNumber
   * @param {array}  userType
   * @param {object} belongsToCompany - Company
   * @param {object} userSpecialPermission - UserSpecialPermission
   * @param {object} userNotificationSetting - UserNotificationSetting
   */
	constructor(objectId, username, firstName, lastName, email, phoneNumber, userType, belongsToCompany, userSpecialPermission, userNotificationSetting) {
		this.objectId = objectId;
		this.username = username;
		this.firstName = firstName;
		this.lastName = lastName;
		this.email = email;
    this.phoneNumber = phoneNumber;
    this.userType = userType;
    this.belongsToCompany = belongsToCompany;
    this.userSpecialPermission = userSpecialPermission;
    this.userNotificationSetting = userNotificationSetting;
  }

  get fullName() {
    return formatName(`${this.firstName} ${this.lastName}`);
  }
}