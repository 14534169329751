import React, { useEffect, useState } from 'react';

// sbcore components
import Button from 'sbCore/Button/Button';
import Calendar from 'sbCore/Calendar/Calendar';
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import Divider from 'sbCore/Divider/Divider';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import InputText from 'sbCore/InputText/InputText';
import InputNumber from 'sbCore/InputNumber/InputNumber';
import Fieldset from 'sbCore/Fieldset/Fieldset';

// api
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { updateDispatchQuote, createDispatchQuote, getDispatchQuote } from 'api/Dispatch/DispatchQuote';
import { getTotalDispatchQuoteChargeAmount } from 'api/Dispatch/DispatchQuoteAdditionalCharge';
import { getTotalDispatchQuoteDiscountAmount } from 'api/Dispatch/DispatchQuoteDiscount';
import { getDispatchTransfersSum } from 'api/Dispatch/DispatchTransfer';

// csapi enums
import { Document as DocumentTypes } from 'sb-csapi/dist/enums/Dispatch/Document';

// components
import QuoteInformationValueSection from 'components/Dispatch/QuoteInformationValueSection/QuoteInformationValueSection';
import QuoteInformationAdditionalDetails from 'components/Dispatch/QuoteInformationAdditionalDetails/QuoteInformationAdditionalDetails';

// styles
import './style.scss';

/**
 * @description
 * @param {*} param0 
 * 
 * @returns 
 * 
 * @example
 */
function DispatchJobQuoteInformationLayout({ dispatchJob, dispatchJobObjectId, setActiveTabIndex, editDispatchDocumentTypeInt, editDocumentsActiveIndex }) {

  // ** useState ** //
  const [dispatchQuote, setDispatchQuote] = useState({
    objectId: null,
    amount: 0,
    tax: 0,
    unit: null,
    billingDetails: null,
    paymentDue: null,
    paymentTerms: null,
    fuelSurchargeAmount: null,
    fuelSurchargeType: null,
    isRatePerDistance: null,
    distanceUnit: null,
  });

  const [dispatchQuoteRecord, setDispatchQuoteRecord] = useState(null);

  const [additionalChargesTotal, setAdditionalChargesTotal] = useState(0);
  const [discountsTotal, setDiscountsTotal] = useState(0);
  const [totalRate, setTotalRate] = useState(0);
  const [totalFSC, setTotalFSC] = useState(0);
  const [preTaxTotal, setPreTaxTotal] = useState(0);

  const [additionalDetailsState, setAdditionalDetailsState] = useState(false);
  const [mainQuoteState, setMainQuoteState] = useState(false);

  // ** Lists ** //
  const paymentTermOptions = [
    '30 days',
    '60 days',
    '90 days',
  ];
  const fscOptions = [
    'Lump Sum',
    // 'Per Distance',
    'Percentage',
  ];

  // ** UseEffects ** //
  useEffect(() => {
    getOrCreateDispatchQuote();
  }, [dispatchJob]);

  useEffect(() => {
    async function _getDispatchTransfersSum() {
      const _totalQuote = await getDispatchTransfersSum(dispatchQuoteRecord, dispatchJobObjectId);
      setTotalRate(_totalQuote);
    }

    _getDispatchTransfersSum();
  }, [dispatchQuoteRecord, dispatchJobObjectId, mainQuoteState]);

  useEffect(() => {
    async function getTotalAdditionalCharges() {
      const _additionalChargesTotal = await getTotalDispatchQuoteChargeAmount(dispatchQuoteRecord);
      if (!_additionalChargesTotal) return;
      setAdditionalChargesTotal(_additionalChargesTotal);
    }

    async function getTotalDiscounts() {
      const _discountsTotal = await getTotalDispatchQuoteDiscountAmount(dispatchQuoteRecord);
      if (!_discountsTotal) return;
      setDiscountsTotal(_discountsTotal);
    }

    getTotalAdditionalCharges();
    getTotalDiscounts();
  }, [dispatchQuoteRecord, additionalDetailsState]);

  useEffect(() => {
    let fuelSurcharge = 0;
    const _preTaxTotal = totalRate + additionalChargesTotal - discountsTotal;
    if (dispatchQuote.fuelSurchargeType === 'Percentage') fuelSurcharge = _preTaxTotal * (dispatchQuote.fuelSurchargeAmount / 100);
    else if (dispatchQuote.fuelSurchargeType === 'Lump Sum') fuelSurcharge = dispatchQuote.fuelSurchargeAmount;

    setTotalFSC(fuelSurcharge);
    setPreTaxTotal(_preTaxTotal + fuelSurcharge);
  }, [additionalChargesTotal, discountsTotal, dispatchQuote, totalRate]);

  useEffect(() => {
    async function _editDispatchQuote() {
      await editDispatchQuote('amount', preTaxTotal + totalFSC);
    }
    if (!dispatchQuote.objectId) return;
    _editDispatchQuote();
  }, [preTaxTotal, totalFSC]);

  // ** Functions ** //
  async function editDispatchQuote(attribute, value) {
    setDispatchQuote({ ...dispatchQuote, [attribute]: value });
    await updateDispatchQuote(dispatchQuote.objectId, { [attribute]: value }, true);
  }

  async function getOrCreateDispatchQuote() {
    let dispatchQuoteParseObj = await getDispatchQuote(dispatchJob);

    if (!dispatchQuoteParseObj) {
      dispatchQuoteParseObj = await createDispatchQuote(dispatchJob);
    }

    const _dispatchQuote = {
      objectId: getAttribute(dispatchQuoteParseObj, 'objectId'),
      amount: getAttribute(dispatchQuoteParseObj, 'amount') || 0,
      tax: getAttribute(dispatchQuoteParseObj, 'tax') || 0,
      unit: getAttribute(dispatchQuoteParseObj, 'unit') || 'CAD',
      billingDetails: getAttribute(dispatchQuoteParseObj, 'billingDetails', true),
      paymentDue: getAttribute(dispatchQuoteParseObj, 'paymentDue', true),
      paymentTerms: getAttribute(dispatchQuoteParseObj, 'paymentTerms', true),
      fuelSurchargeAmount: getAttribute(dispatchQuoteParseObj, 'fuelSurchargeAmount', true) || 0,
      fuelSurchargeType: getAttribute(dispatchQuoteParseObj, 'fuelSurchargeType', true),
      isRatePerDistance: getAttribute(dispatchQuoteParseObj, 'isRatePerDistance', true),
      distanceUnit: getAttribute(dispatchQuoteParseObj, 'distanceUnit', true),
    };

    setDispatchQuote(_dispatchQuote);
    setDispatchQuoteRecord(dispatchQuoteParseObj);
  }

  function generateLoadConfirmation() {
    editDispatchDocumentTypeInt(DocumentTypes.LOAD_CONFIRMATION.type);
    setActiveTabIndex(4);
    editDocumentsActiveIndex(1);
  }

  function generateInvoice() {
    editDispatchDocumentTypeInt(DocumentTypes.INVOICE.type);
    setActiveTabIndex(4);
    editDocumentsActiveIndex(2);
  }

  // ** Templates ** //
  const paymentSectionTemplate = (
    <Fieldset legend="3. Billing & Payment Terms" toggleable collapsed className="quote-information-colored-section m-3" sbVariant="dispatch">
      <div className="quote-information-field">
        <span className="quote-label w-50">Bill To: </span>
        <InputText
          value={dispatchQuote.billingDetails}
          onChange={async (e) => await editDispatchQuote('billingDetails', e.target.value)}
          placeholder="Add Details"
          variant="small"
          className="w-50"
        />
      </div>
      <div className="quote-information-field">
        <span className="quote-label w-50">Payment Due: </span>
        <Calendar
          value={dispatchQuote.paymentDue}
          onChange={async (e) => await editDispatchQuote('paymentDue', e.target.value)}
          placeholder="Enter Date"
          className="w-50"
          variant="small"
        />
      </div>
      <div className="quote-information-field mb-0">
        <span className="quote-label w-50">Payment Terms: </span>
        <div className="w-50">
          <Dropdown
            value={dispatchQuote.paymentTerms}
            options={paymentTermOptions}
            onChange={async (e) => await editDispatchQuote('paymentTerms', e.target.value)}
            placeholder="Select Term"
            variant="small"
          />
        </div>
      </div>
    </Fieldset>
  );

  const quoteInformationSummaryTemplate = (
    <ColoredSection
      className="quote-information-colored-section quote-summary"
      title="Quote Summary"
      noFooter
      variant="compact"
    >
      <div className="quote-information-field">
        <span className="quote-label">{dispatchQuote.isRatePerDistance ? `Rate per Distance (${dispatchQuote.distanceUnit}):` : 'Total Rate:'}</span>
        <span>${totalRate.toFixed(2)}</span>
      </div>
      <div className="quote-information-field">
        <span className="quote-label">Total Additional Charges:</span>
        <span>${additionalChargesTotal.toFixed(2)}</span>
      </div>
      <div className="quote-information-field">
        <span className="quote-label">Total Discounts:</span>
        <span>-${discountsTotal.toFixed(2)}</span>
      </div>
      <div className="quote-information-field">
        <span className="quote-label w-25">Fuel Surcharge:</span>
        <div className="d-flex justify-content-end w-50">
          <div className="w-75 pr-1">
            <Dropdown
              value={dispatchQuote.fuelSurchargeType}
              onChange={async (e) => await editDispatchQuote('fuelSurchargeType', e.target.value)}
              options={fscOptions}
              placeholder="Type"
              variant="small"
            />
          </div>
        </div>
        <div className="p-inputgroup w-25">
          <span className="p-inputgroup-addon">{dispatchQuote.fuelSurchargeType === 'Percentage' ? '%' : '$'}</span>
          <InputNumber
            value={dispatchQuote.fuelSurchargeType ? dispatchQuote.fuelSurchargeAmount : 0}
            onChange={async (e) => await editDispatchQuote('fuelSurchargeAmount', e.value)}
            className="input-group-field"
            placeholder="0.00"
            disabled={!dispatchQuote.fuelSurchargeType}
          />
        </div>
      </div>
      <div className="quote-information-field">
        <span className="quote-label">Tax Rate:</span>
        <div className="p-inputgroup w-25">
          <span className="p-inputgroup-addon">%</span>
          <InputNumber
            value={dispatchQuote.tax}
            onChange={async (e) => await editDispatchQuote('tax', e.value)}
            placeholder="0.00"
          />
        </div>
      </div>
      <div className="quote-information-field mb-0">
        <span className="quote-label">Tax:</span>
        <span>${(preTaxTotal * (dispatchQuote.tax / 100)).toFixed(2)}</span>
      </div>
      <Divider className="mt-0" />
      <div className="quote-information-field mb-0">
        <span className="quote-label">Quote Total:</span>
        <span>${(preTaxTotal * (1 + (dispatchQuote.tax / 100))).toFixed(2)}</span>
      </div>
    </ColoredSection>
  );

  return (
    <div className="dispatch-job-quote-information-layout">
      <div className="first-column">
        <QuoteInformationValueSection
          dispatchJobObjectId={dispatchJobObjectId}
          dispatchQuote={dispatchQuoteRecord}
          dispatchQuoteObjectId={dispatchQuote.objectId}
          setActiveTabIndex={setActiveTabIndex}
          toggleQuoteValueState={() => setMainQuoteState(!mainQuoteState)}
        />
        <QuoteInformationAdditionalDetails
          dispatchQuote={dispatchQuoteRecord}
          toggleAdditionalDetailsState={() => setAdditionalDetailsState(!additionalDetailsState)}
        />
        {paymentSectionTemplate}
      </div>
      <div className="second-column">
        {quoteInformationSummaryTemplate}
        <div className="flex px-3">
          <Button label="Generate Load Confirmation" icon="pi pi-book" sbVariant="light" className="mr-3 p-button-success" onClick={() => generateLoadConfirmation()} />
          <Button label="Generate Invoice" icon="pi pi-book" sbVariant="light" className="p-button-secondary" onClick={() => generateInvoice()} />
        </div>
      </div>
    </div>
  );
}

export default DispatchJobQuoteInformationLayout;
