import { getAttribute, getCurrentUser, cloneRecord, createQuery, createQueryOr, setQueryRestriction, copyQuery, includePointers, sortQuery, count, find, updateRecord } from 'api/Parse';
import moment from 'moment-timezone';

// API
import { getDriverELDEvents, sortELDEvents } from 'api/ELD';
import { removeMisplacedIntermELDEvents } from 'api/ELD/ELDEvent/ELDEvent';

// SBObjects
import Sort from 'sbObjects/Sort';
import Filter from 'sbObjects/Filter';

// Enums
import { QueryRestrictionTypes, QuerySortOrderTypes } from 'enums/Query';
import { ELDEventRecordStatus } from 'enums/ELDEventRecordStatus';
import { ELDEventRecordOrigin } from 'enums/ELDEventRecordOrigin';
import { ELDEventRecordOriginator } from 'enums/ELDEventRecordOriginator';

/** @module ELDEdit */

// /**
//  * @memberof module:ELDEdit
//  * @description Function to handle all ELD Edit requests
//  *
//  * @param {record} driver - The driver affected by the edit
//  * @param {array} editRequests - Array of Requested edit objects, used to determine which events will be made and which will be disabled
//  * @param {number} defaultELDEventTypeCodeInt - The eldEventTypeCodeInt (enums/ELDEventTypeCode) to use if none available
//  * @param {string} eldEventsIdString - String of affected ELDEvent objectIds. Assumes all ids are of the same day/daily certification as they should be
//  * @param {bool} returnPreview - Return a preview object of the ELDEdit instead of actually saving
//  * @param {number} editType - Type of edit we are dealing with, from enums/ELDEditType
//  */
// async function requestELDEdits(driver, editRequests, defaultELDEventTypeCodeInt, eldEventsIdString, returnPreview, editType) {
// }

/**
 * @memberof module:ELDEdit
 * @description gets unidentified eld edits that contains the eldEventObjectIds and pending driver's approval
 *              for displaying unidentified driving events (daily certs) in the Safety tab
 *
 * @param eldEventObjectIds array of eldEventObjectIds
 */
async function getUnidentifiedELDEdits(eldEventObjects) {
  const eldEditQuery = createQuery('ELDEdit');
  setQueryRestriction(eldEditQuery, QueryRestrictionTypes.EQUAL_TO, 'completed', 0);
  setQueryRestriction(eldEditQuery, QueryRestrictionTypes.CONTAINED_IN, 'eldEventsToBeInactive', eldEventObjects);
  includePointers(eldEditQuery, [
    'eldDailyCertification',
  ]);
  const unidentifiedELDEvents = await find(eldEditQuery, false, true);
  return unidentifiedELDEvents;
}

/**
 * @memberof module:ELDEdit
 *
 * @param {*} page
 * @param {*} limit
 * @param {*} sortBy
 * @param {*} filters
 * @param {*} considerChildCompanies
 *
 * @returns
 */
async function getELDEdits(page = 0, limit = 20, sortBy = new Sort('requestedAt', QuerySortOrderTypes.DESCENDING), filters = [], considerChildCompanies, dispatcherTimeZoneOffsetUTC) {
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  const eldEditQuery = createQuery('ELDEdit');

  if (considerChildCompanies) {
    const companiesToConsider = [belongsToCompany];
    const childrenQuery = createQuery('CompanyLink');
    setQueryRestriction(childrenQuery, QueryRestrictionTypes.EQUAL_TO, 'parentCompany', belongsToCompany);
    const childCompanies = await find(childrenQuery);
    childCompanies.map((childCompany) => companiesToConsider.push(childCompany));

    setQueryRestriction(eldEditQuery, QueryRestrictionTypes.CONTAINED_IN, 'belongsToCompany', companiesToConsider);
  } else {
    setQueryRestriction(eldEditQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
  }

  filters.map(filter => {
    if (filter.attribute === 'requestedAt') {
      setQueryRestriction(eldEditQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, filter.attribute, moment(filter.value).startOf('day').toDate());
      setQueryRestriction(eldEditQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, filter.attribute, moment(filter.value).endOf('day').toDate());
    }
    else if (filter.attribute === 'requestedBy') {
      const userQuery = createQuery('_User');
      setQueryRestriction(userQuery, filter.queryRestriction, 'userType', filter.value);
      setQueryRestriction(userQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
      setQueryRestriction(eldEditQuery, QueryRestrictionTypes.EXISTS, 'requestedBy');
      setQueryRestriction(eldEditQuery, QueryRestrictionTypes.MATCHES_QUERY, 'requestedBy', userQuery);
      const eldDailyCertificationEnabledQuery = createQuery('ELDDailyCertification');
      setQueryRestriction(eldDailyCertificationEnabledQuery, QueryRestrictionTypes.EQUAL_TO, 'disabled', false);
      setQueryRestriction(eldEditQuery, QueryRestrictionTypes.MATCHES_QUERY, 'eldDailyCertification', eldDailyCertificationEnabledQuery);
    } else if (filter.attribute === 'eldDailyCertification.startTimeUTC') {
      const certificationQuery = createQuery('ELDDailyCertification');
      setQueryRestriction(certificationQuery, QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'startTimeUTC', moment.tz(filter.value, dispatcherTimeZoneOffsetUTC).startOf('day').toDate());
      setQueryRestriction(certificationQuery, QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'startTimeUTC', moment.tz(filter.value, dispatcherTimeZoneOffsetUTC).endOf('day').toDate());
      setQueryRestriction(certificationQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
      setQueryRestriction(certificationQuery, QueryRestrictionTypes.EQUAL_TO, 'disabled', false);

      setQueryRestriction(eldEditQuery, QueryRestrictionTypes.EXISTS, 'eldDailyCertification');
      setQueryRestriction(eldEditQuery, QueryRestrictionTypes.MATCHES_QUERY, 'eldDailyCertification', certificationQuery);
    } else if (filter.attribute === 'driver.user_fullName') {
      const driverQuery = createQuery('Driver');
      setQueryRestriction(driverQuery, filter.queryRestriction, 'user_fullName', filter.value);
      setQueryRestriction(driverQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);

      setQueryRestriction(eldEditQuery, QueryRestrictionTypes.EXISTS, 'driver');
      setQueryRestriction(eldEditQuery, QueryRestrictionTypes.MATCHES_QUERY, 'driver', driverQuery);

      const eldDailyCertificationEnabledQuery = createQuery('ELDDailyCertification');
      setQueryRestriction(eldDailyCertificationEnabledQuery, QueryRestrictionTypes.EQUAL_TO, 'disabled', false);
      setQueryRestriction(eldEditQuery, QueryRestrictionTypes.MATCHES_QUERY, 'eldDailyCertification', eldDailyCertificationEnabledQuery);
    } else {
      setQueryRestriction(eldEditQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);

      const eldDailyCertificationEnabledQuery = createQuery('ELDDailyCertification');
      setQueryRestriction(eldDailyCertificationEnabledQuery, QueryRestrictionTypes.EQUAL_TO, 'disabled', false);
      setQueryRestriction(eldEditQuery, QueryRestrictionTypes.MATCHES_QUERY, 'eldDailyCertification', eldDailyCertificationEnabledQuery);
    }
  });

  let eldEditsCountQuery = copyQuery(eldEditQuery);

  includePointers(eldEditQuery, [
    'eldDailyCertification',
    'requestedBy',
    'requestedBy.user',
    'driver',
    'driver.user',
    'requestedELDEvents',
    'eldEventsToBeInactive',
  ]);


  setQueryRestriction(eldEditQuery, QueryRestrictionTypes.EXISTS, 'driver');
  setQueryRestriction(eldEditQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  setQueryRestriction(eldEditQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);

  sortQuery(eldEditQuery, sortBy.order, sortBy.attribute);

  try {
    const [totalELDEditsCount, eldEdits] = await Promise.all([count(eldEditsCountQuery), find(eldEditQuery)]);
    return { eldEdits, totalELDEditsCount };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:ELDEdit
 * @description Obtains ELDEdits from a company for a given day
 *
 * @param {int} page - query page / limit skip multiplier
 * @param {int} limit  - amount of results we want
 * @param {array} sortBy - array of Sort objects
 * @param {array} filters - array of Filter objects
 * @param {bool} considerChildCompanies - whether or not to include child company results
 *
 * @returns { object } - { eldEdits: [], totalELDEditCount: int }
 */
async function getELDEditsForDate(
  page = 0,
  limit = 10,
  sortBy = new Sort('requestedAt', QuerySortOrderTypes.DESCENDING),
  filters = [],
  considerChildCompanies,
  onDateTime,
  eldDailyCertification,
) {
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  const companiesToConsider = [belongsToCompany];

  const eldEditQuery = createQuery('ELDEdit');

  const eldDailyCertificationEnabledQuery = createQuery('ELDDailyCertification');
  setQueryRestriction(eldDailyCertificationEnabledQuery, QueryRestrictionTypes.EQUAL_TO, 'disabled', false);

  if (considerChildCompanies) {
    const childrenQuery = createQuery('CompanyLink');
    setQueryRestriction(childrenQuery, QueryRestrictionTypes.EQUAL_TO, 'parentCompany', belongsToCompany);
    const childCompanies = await find(childrenQuery);
    childCompanies.map((childCompany) => companiesToConsider.push(childCompany));
    setQueryRestriction(eldEditQuery, QueryRestrictionTypes.CONTAINED_IN, 'belongsToCompany', companiesToConsider);
    setQueryRestriction(eldDailyCertificationEnabledQuery, QueryRestrictionTypes.CONTAINED_IN, 'belongsToCompany', companiesToConsider);
  } else {
    setQueryRestriction(eldEditQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
    setQueryRestriction(eldDailyCertificationEnabledQuery, QueryRestrictionTypes.CONTAINED_IN, 'belongsToCompany', companiesToConsider);
  }

  // Set query restricitons from filter
  filters.map(filter => setQueryRestriction(eldEditQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value));

  let combinedELDEditQuery;

  if (onDateTime) {
    const eldEditOnDateTimeQuery = copyQuery(eldEditQuery, true);
    setQueryRestriction(eldEditOnDateTimeQuery, QueryRestrictionTypes.EQUAL_TO, 'eldDailyCertificationStartTimeUTC', onDateTime);
    combinedELDEditQuery = eldEditOnDateTimeQuery;
  } else if (getAttribute(eldDailyCertification, 'objectId')) {
    const eldEditOnEldDailyCertificationQuery = copyQuery(eldEditQuery, true);
    setQueryRestriction(eldEditOnEldDailyCertificationQuery, QueryRestrictionTypes.EQUAL_TO, 'eldDailyCertification', eldDailyCertification);
    combinedELDEditQuery = eldEditOnEldDailyCertificationQuery;
  } else {
    combinedELDEditQuery = eldEditQuery;
  }

  setQueryRestriction(combinedELDEditQuery, QueryRestrictionTypes.MATCHES_QUERY, 'eldDailyCertification', eldDailyCertificationEnabledQuery);

  // Copy current query to get the number of pages for pagination
  const eldEditCountQuery = copyQuery(combinedELDEditQuery);

  includePointers(combinedELDEditQuery, [
    'requestedELDEvents',
    'eldEventsToBeInactive',
    'eldDailyCertification',
    'belongsToCompany',
    'driver',
    'requestedBy',
  ]);

  setQueryRestriction(combinedELDEditQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  setQueryRestriction(combinedELDEditQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);

  // Call the ascending/descending function on the query, passing in the attribute
  sortQuery(combinedELDEditQuery, sortBy.order, sortBy.attribute);

  try {
    const [totalELDEditCount, eldEdits] = await Promise.all([count(eldEditCountQuery), find(combinedELDEditQuery)]);
    return { eldEdits, totalELDEditCount };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:ELDEdit
 * @description Obtains a snapshot of of ELDEvents up to the point of the ELDEdit (with any previously completed ELDEdits)
 *
 * @param {String} onDateTime The date corresponding to the ELDEdit (should correspond to eldDailyCertificationStartTimeUTC or startTimeUTC of an eldDailyCertification)
 * @param {Record} currentELDEdit The current ELDEdit which we want to simulate events up to (but not including)
 */
async function getELDEventsSnapshot(onDateTime, currentELDEdit) {
  const driver = getAttribute(currentELDEdit, 'driver');
  const driverTimezoneOffsetFromUTC = getAttribute(driver, 'timezoneOffsetFromUTC');
  const eldDailyCertificationForEdit = getAttribute(currentELDEdit, 'eldDailyCertification');

  // For some reason, using onDate will screw up when using 
  // I think it has something to do with startOf('day') for getELDDailyCertificationIntervalFromDriverTZ get in getDriverELDEvents
  const { eldEvents, eldDailyCertification, associatedELDEvents } = await getDriverELDEvents(
    driver,
    { eldDailyCertification: eldDailyCertificationForEdit },
    undefined,
    driverTimezoneOffsetFromUTC,
    moment(onDateTime).toDate(),
    moment(onDateTime).add(1, 'day').toDate(),
  );
  const eldEventHash = {};
  eldEvents.map((eldEvent) => { eldEventHash[eldEvent.id] = eldEvent; }); // Create a hash of the ELDEvents to easily manipulate later

  // We'll find all the ELDEdits for this day (up to a max of 20 ELDEdits/driver for the day)
  const filters = [new Filter('driver', driver, QueryRestrictionTypes.EQUAL_TO)];

  const { eldEdits } = await getELDEditsForDate(undefined, 20, undefined, filters, undefined, onDateTime, eldDailyCertification);
  let existsAllELDEditCompletedAt = true;
  for (let i = 0; i < eldEdits.length; i++) {
    const eldEdit = eldEdits[i];
    if (!getAttribute(eldEdit, 'completedAt')) {
      existsAllELDEditCompletedAt = false;
      break;
    }
  }

  // We're going to attempt to remove any ELDEvents which were part of an edit
  // All ELDEvents which are created by an edit should be contained in requestedELDEvents
  for (let i = 0; i < eldEdits.length; i++) {
    const eldEdit = eldEdits[i];
    const requestedELDEvents = getAttribute(eldEdit, 'requestedELDEvents');

    for (let j = 0; j < requestedELDEvents.length; j++) {
      const requestedELDEventObjectId = requestedELDEvents[j].id;
      // If we find an ELDEvent which is a part of the requestedELDEvents, then we remove it
      if (eldEventHash[requestedELDEventObjectId]) delete eldEventHash[requestedELDEventObjectId];
    }
  }

  // At this point, eldEventHash should not have any of the requested ELDEvents (should only contain the original ELDEvents without any edits)
  // Filter out the ELDEdits for only those which are completed [2, 7]
  const completedELDEdits = eldEdits.filter((eldEdit) => [2, 7].includes(getAttribute(eldEdit, 'completed')));

  // Find the ELDEdits which happened before the current ELDEdit
  // We'll attempt to use the completedAt field if it exists, otherwise use the requestedAt field
  const referenceDateTime = getAttribute(currentELDEdit, 'completedAt') || getAttribute(currentELDEdit, 'requestedAt');
  const filteredCompletedELDEdits = completedELDEdits.filter((eldEdit) => {
    // At this point in time, we are only considering completed ELDEdits. We need a fallback to requestedAt for the old ELDEdits which dont contain a completedAt time
    const completedAtDateTime = getAttribute(eldEdit, 'completedAt') || getAttribute(eldEdit, 'requestedAt');

    return moment(completedAtDateTime).isBefore(moment(referenceDateTime));
  });

  // We'll start re-applying the completed edits up to the point of the current edit
  for (let i = 0; i < filteredCompletedELDEdits.length; i++) {
    const eldEdit = filteredCompletedELDEdits[i];
    const requestedELDEvents = getAttribute(eldEdit, 'requestedELDEvents');
    const eldEventsToBeInactive = getAttribute(eldEdit, 'eldEventsToBeInactive');

    for (let j = 0; j < requestedELDEvents.length; j++) {
      const requestedELDEventObjectId = requestedELDEvents[j].id;
      const eldEvent = requestedELDEvents[j];
      // If we find an ELDEvent which is a part of the requestedELDEvents, then we add it
      if (!eldEventHash[requestedELDEventObjectId]) eldEventHash[requestedELDEventObjectId] = eldEvent;
    }

    for (let j = 0; j < eldEventsToBeInactive.length; j++) {
      const eldEventsToBeInactiveObjectId = eldEventsToBeInactive[j].id;
      // If we find an ELDEvent which is a part of the eldEventsToBeInactive, then we remove it
      if (eldEventHash[eldEventsToBeInactiveObjectId]) delete eldEventHash[eldEventsToBeInactiveObjectId];
    }
  }

  // Now, the eldEventHash should contain the original ELDEvents + the completed ELDEdits up to the point before the current ELDEdit
  return { simulatedELDEvents: Object.entries(eldEventHash), eldDailyCertification, associatedELDEvents, existsAllELDEditCompletedAt };
}

/**
 * @memberof module:ELDEdit
 *
 * @param {*} eldEvents
 * @param {*} eldEdits
 * @param {*} associatedELDEvents
 * @param {*} eldDailyCertification
 * @param {*} driver
 *
 * @returns
 */
async function getELDEditsSnapshot(eldEvents = [], eldEdits = [], associatedELDEvents = [], eldDailyCertification, driver) {

  // eldEditPreviewInfo - the minimal info needed to render the HOS graph
  const eldEditPreviewInfo = {
    eldEvents: [],
    associatedELDEvents: [],
    eldDailyCertification: undefined,
    driver: undefined,
  };

  if (!driver) return eldEditPreviewInfo;
  if (!eldDailyCertification) return eldEditPreviewInfo;

  let eldEventsForPreview = [];
  for (let i = 0; i < eldEvents.length; i++) {
    const eldEvent = eldEvents[i];
    const eldEventCloned = await cloneRecord(eldEvent);
    await updateRecord(eldEventCloned, { eldEventRecordStatusInt: ELDEventRecordStatus.ACTIVE });
    eldEventsForPreview.push(eldEventCloned);
  }

  for (let i = 0; i < eldEdits.length; i++) {
    const eldEdit = eldEdits[i];

    // remove those events which are to be inactive
    const eldEventsToBeInactive = getAttribute(eldEdit, 'eldEventsToBeInactive');
    const inactiveELDEventObjectIds = eldEventsToBeInactive.map(eldEvent => getAttribute(eldEvent, 'objectId'));

    let j = eldEventsForPreview.length - 1;

    while (j >= 0) {
      if (inactiveELDEventObjectIds.indexOf(getAttribute(eldEventsForPreview[j], 'objectId', true)) !== -1) {
        eldEventsForPreview.splice(j, 1);
      }
      j--;
    }

    const requestedELDEvents = getAttribute(eldEdit, 'requestedELDEvents');

    // append those events to be requested
    const requestedELDEventsForPreview = [];
    for (let k = 0; k < requestedELDEvents.length; k++) {
      const eldEvent = cloneRecord(requestedELDEvents[k]);
      await updateRecord(eldEvent, {
        eldEventRecordOriginInt: ELDEventRecordOrigin.EDIT_REQUEST_BY_AUTH_USER,
        eldEventRecordOriginatorInt: ELDEventRecordOriginator.EDIT,
      });
      eldEvent.eldEventRecordStatusInt = 1; // fake active
      // _eldEvent.id = _eldEvent._localId;
      requestedELDEventsForPreview.push(eldEvent);
    }
    eldEventsForPreview.push.apply(eldEventsForPreview, requestedELDEventsForPreview);
  }

  // sort the events
  sortELDEvents(eldEventsForPreview, 1);

  // remove misplaced interm events
  eldEventsForPreview = removeMisplacedIntermELDEvents(eldEventsForPreview);

  // const eldEventsToBeInactiveIds = eldEdit.get('eldEventsToBeInactive').map(event => {
  //   return event.id;
  // });
  // const requestedELDEvents = eldEdit.get('requestedELDEvents').map(event => {
  //   const clonedEvent = event.clone();
  //   clonedEvent.set('eldEventRecordStatusInt', 1);
  //   return clonedEvent;
  // });

  // // filter out events to be inactive
  // newState.eldEvents = [].concat(newState.eldEvents).filter(event => {
  //   return eldEventsToBeInactiveIds.indexOf(event.id) === -1;
  // });

  // // add in requested events and sort
  // newState.eldEvents = newState.eldEvents.concat(requestedELDEvents);
  // ELD.sortELDEvents(newState.eldEvents, 1);

  eldEditPreviewInfo.eldEvents = eldEventsForPreview;
  eldEditPreviewInfo.associatedELDEvents = associatedELDEvents;
  eldEditPreviewInfo.eldDailyCertification = eldDailyCertification;
  eldEditPreviewInfo.driver = driver;

  return eldEditPreviewInfo;
}


export {
  getUnidentifiedELDEdits,
  getELDEdits,
  getELDEditsForDate,
  getELDEditsSnapshot,
  getELDEventsSnapshot,
};
