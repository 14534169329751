import React, { useState, useEffect, useContext } from 'react';

// components
import GeneralInformationSubsection from 'components/Dispatch/GeneralInformationSubsection/GeneralInformationSubsection';
import DocumentGenerationSubsection from 'components/Dispatch/DocumentGenerationSubsection/DocumentGenerationSubsection';
import ShipmentDetailsSubsection from 'components/Dispatch/ShipmentDetailsSubsection/ShipmentDetailsSubsection';
import QuoteInformationSubsection from 'components/Dispatch/QuoteInformationSubsection/QuoteInformationSubsection';

// style
import './style.scss';

/**
 * 
 * @param {DispatchJob} dispatchJob - Current DispatchJob Record
 * @param {string} dispatchJobObjectId - Curren DispatchJob objectId
 * @param {function} setActiveTabIndex - Function to change the current Dispatch tab
 *
 * @returns 
 */
function DispatchJobGeneralInformationLayout({ dispatchJob, dispatchJobObjectId, setActiveTabIndex }) {

  return (
    <div className="background-container">
      <div className="first-column">
        <GeneralInformationSubsection
          dispatchJob={dispatchJob}
        />
        <QuoteInformationSubsection
          dispatchJob={dispatchJob}
          dispatchJobObjectId={dispatchJobObjectId}
          setActiveTabIndex={setActiveTabIndex}
        />

      </div>
      <div className="second-column">
        <ShipmentDetailsSubsection
          dispatchJob={dispatchJob}
          dispatchJobObjectId={dispatchJobObjectId}
          setActiveTabIndex={setActiveTabIndex}
        />
        <DocumentGenerationSubsection
          dispatchJobObjectId={dispatchJobObjectId}
          setActiveTabIndex={setActiveTabIndex}
        />
      </div>
    </div>
  );
}

export default DispatchJobGeneralInformationLayout;
