import React from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Glyphicon } from 'react-bootstrap'

// Actions
import {
  fetchTrailersForState,
  deleteTrailersForState,
  disableTrailer,
  enableTrailer,
  deleteTrailer
} from 'actions/Trailer'

// API
import { trailerTypes } from 'api/Lists/EquipmentTypes';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import { getQueryParameter } from 'api/URL';

// Components
import Button from 'sbCore/Button/Button';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import SBTable from 'components/Shared/SBTable/SBTable';
import Title from 'components/LayoutTitle/view/Title'
import FilterForm from 'components/FilterForm/container/FilterForm.new'
import FilterDropdown from 'components/FilterDropdown/view/FilterDropdown'
import AddNewTrailerButton from 'components/AddEditEquipment/container/AddNewTrailerButton'
import TrailersTable from 'components/TrailersTable/container/TrailersTable'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import SubNavigationBar from '../../components/Shared/SubNavigationBar/SubNavigationBar'
import TrailerTemperatureChart from './TrailerTemperatureChart';

// CSS
import styles from './TrailerTemperatureLayout.module.scss'
import StoreContext from 'contexts/StoreContext'

class TrailersLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayFilterForm: true,
      filter: [],
      selectedTrailerId: null,
      sort: { unitId: 'descending' },
      activeFilter: true,
      selectedTab: 0,
      trailerTypes: [
        { attrName: '0', placeholder: 'Active Trailers', enabled: true },
        { attrName: '1', placeholder: 'Inactive Trailers', enabled: false }
      ],
      trailerArr: [],
      fetched: true,
      dateTempObjArr: [],
      tempUnit: 'f',
    }

    this.state.filterFormFields = [
      {
        attrName: 'devicetime',
        name: 'devicetime',
        queryType: 'date',
        fullName: 'Date',
        includeLeftRight: true,
        dateQueryType: 'onDate',
        type: 'date',
        value: moment(),
      },
      {
        attrName: 'name',
        name: 'name',
        fullName: 'Unit ID',
        queryType: 'matches',
        placeholder: '.....',
        type: 'select',
        selectArr: [],
        value: '',
      },
    ];

    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.downloadCsv = this.downloadCsv.bind(this);
  }

  async componentDidMount() {
    const trailerArr = await Getters.queryCompanyObjects('Trailer', undefined, undefined, [{ name: 'enabled', queryType: 'equalTo', value: true }], { unitId: 'ascending' }, undefined, undefined, undefined, undefined, true);
    for (let i = 0; i < this.state.filterFormFields.length; i++) {
      if (this.state.filterFormFields[i].attrName === 'name') {
        const newState = { ...this.state };
        newState.filterFormFields[i].selectArr = trailerArr.map((vehicle) => vehicle.get('unitId'));
        this.setState(newState);
        break;
      }
    }
  }

  componentWillUnmount() {
    deleteTrailersForState()
  }

  async componentWillReceiveProps(nextProps) {
    const trailerId = getQueryParameter(nextProps.location.search, 'id');
    const trailerObj = await Getters.queryCompanyObjects('Trailer', undefined, undefined, [{ name: 'enabled', queryType: 'equalTo', value: true }, { name: 'objectId', queryType: 'equalTo', value: trailerId }], undefined, undefined, true, undefined, undefined);
    if (trailerObj) {
      const date = getQueryParameter(nextProps.location.search, 'date') && moment(getQueryParameter(nextProps.location.search, 'date'), 'YYYYMMDD');
      const filterFormFields = [].concat(...this.state.filterFormFields);
      if (date) filterFormFields[0].value = date;
      filterFormFields[1].value = trailerObj.get('unitId');
      await this.setState({
        ...this.state,
        filterFormFields,
      });
      this.filter(this.state.filterFormFields);
    }
  }

  async filter(filterArr) {
    let name;
    let devicetime;

    let nameSelected = false;
    this.setState({ ...this.state, dateTempObjArr: [], fetched: false });

    const tcDeviceFilter = [];
    const locationFilter = [];
    for (let i = 0; i < filterArr.length; i++) {
      if (filterArr[i].name === 'name') {
        tcDeviceFilter.push(filterArr[i]);
        nameSelected = true;
        name = filterArr[i].value;
      }
      if (filterArr[i].name === 'devicetime') {
        locationFilter.push(filterArr[i]);
        devicetime = filterArr[i].value;
      }
    }

    const tcDeviceArr = await Getters.queryCompanyObjects('tc_devices', undefined, undefined, tcDeviceFilter, undefined, undefined, false);
    const locationQueries = [];
    for (let i = 0; i < tcDeviceArr.length; i++) {
      const tcDevice = tcDeviceArr[i];
      if (tcDevice.get('tc_positions')) {
        locationQueries.push(
          Getters.queryCompanyObjects('tc_positions', undefined, undefined, [].concat(locationFilter, [{ name: 'deviceid', queryType: 'containedIn', value: tcDeviceArr.map((tcDeviceObj) => tcDeviceObj.get('id')) }, { name: 'tempCelsius', queryType: 'exists' }]), { devicetime: 'descending' }, undefined, undefined, undefined, undefined, true)
        )
      } else if (tcDevice.get('assetLocation')) {
        locationQueries.push(
          Getters.queryCompanyObjects('AssetLocation', undefined, undefined, [].concat(locationFilter, [{ name: 'tc_devices', queryType: 'containedIn', value: tcDeviceArr }, { name: 'tempCelsius', queryType: 'exists' }]), { devicetime: 'descending' }, undefined, undefined, undefined, undefined, true)
        );
      }
    }

    if (!nameSelected) return this.setState({ ...this.state, fetched: true });

    const locationQueryResults = await Promise.all(locationQueries);
    const dateTempObjArr = [];
    for (let i = 0; i < locationQueryResults.length; i++) {
      const locationArr = locationQueryResults[i];
      // tcDevice positions
      const partialDateTempObjArr = locationArr.map((location) => {
        return ({
          devicetime: location.get('devicetime'),
          tempCelsius: location.get('tempCelsius'),
          id: location.id,
        });
      });
      dateTempObjArr.push(...partialDateTempObjArr);
    }
    this.setState({ ...this.state, dateTempObjArr, fetched: true, name, devicetime });
  }

  downloadCsv() {
    const csvArr = [`Time,Temperature (${this.state.tempUnit.toUpperCase()})`].concat(this.state.dateTempObjArr.map(dateTempObj => {
      return `${moment(dateTempObj.devicetime).format('H:mm')},${this.state.tempUnit === 'f' ? Math.round(Helpers.convertTemperature(dateTempObj.tempCelsius, 'c', 'f') * 10) / 10 : Math.round(dateTempObj.tempCelsius * 10) / 10}`
    }));

    const csvString = csvArr.join('\n');
    // Create the csv file and allow client to download it
    Helpers.createCsvFile(`[${this.state.name}] Switchboard - Temperature Summary (${this.state.devicetime.format('YYYY-MM-DD')})`, csvString, true);
  }


  clearFilter() {
    this.pageCount = 0
    this.setState({ ...this.state, filter: [] })
    deleteTrailersForState().then(() => {
      fetchTrailersForState(
        this.pageCount,
        this.trailersTableRowAmount,
        [],
        this.state.sort
      )
    })
  }

  render() {
    const tableHeaderRows = [{
      key: 'sb-users-list', columns: [
        { element: <div>Time</div>, props: { style: { width: '25%' } } },
        {
          element: (
            <div>
              {`Temperature (°${this.state.tempUnit.toUpperCase()})`}
              &nbsp;
              {/* <div className="inlineBlock">
            <SBSelect
              autocompleteLabel="Unit"
              defaultToggleText={this.state.tempUnit === 'c' ? 'Celsius' : 'Farenheit'}
              items={[
                {
                  key: 0,
                  value: 'c',
                  label: 'Celsius',
                },
                {
                  key: 1,
                  value: 'f',
                  label: 'Farenheit',
                },
              ]}
              getSelectedItems={(selection) => {
                if (selection[0].value === 'c') this.setState({ ...this.state, tempUnit: 'c' });
                else this.setState({ ...this.state, tempUnit: 'f' });
              }}
            />
          </div> */}
            </div>
          ), props: { style: { width: '25%' } }
        },
        { element: <div></div>, props: { style: { width: '25%' } } },
      ]
    }];

    const tableBodyRows = this.state.dateTempObjArr.map(dateTempObj => {
      const columns = [
        { element: <div>{moment(dateTempObj.devicetime).format('H:mm')}</div>, props: { className: 'table-body-column-style' } },
        { element: <div>{this.state.tempUnit === 'f' ? Math.round(Helpers.convertTemperature(dateTempObj.tempCelsius, 'c', 'f') * 10) / 10 : Math.round(dateTempObj.tempCelsius * 10) / 10}</div>, props: { className: 'table-body-column-style' } },
        { element: <div></div>, props: { className: 'table-body-column-style' } },
      ];

      return {
        key: dateTempObj.id,
        columns,
        props: {},
      };
    });

    return (
      <div className={styles.layout}>
        <Title title='Temperature'>
          <MDBRow className='d-flex flex-row-reverse'>
            <MDBCol xs='12' lg='10'>
              <span>
                <FilterForm
                  className='floatRight'
                  handleFilter={this.filter}
                  clearFilter={this.clearFilter}
                  fields={this.state.filterFormFields}
                  submitOnSelect
                />
                <div className='clearBoth' />
              </span>
            </MDBCol>
          </MDBRow>
        </Title>
        <TrailerTemperatureChart
          dateArr={this.state.dateTempObjArr.filter((dateTempObj) => dateTempObj.tempCelsius !== undefined && dateTempObj.devicetime).sort((a, b) => moment(a.devicetime).diff(moment(b.devicetime))).map((dateTempObj) => dateTempObj.tempCelsius !== undefined && dateTempObj.devicetime)}
          temperatureArr={this.state.dateTempObjArr.filter((dateTempObj) => dateTempObj.tempCelsius !== undefined && dateTempObj.devicetime).sort((a, b) => moment(a.devicetime).diff(moment(b.devicetime))).map((dateTempObj) => dateTempObj.tempCelsius !== undefined && (this.state.tempUnit === 'f' ? Helpers.convertTemperature(dateTempObj.tempCelsius, 'c', 'f') : dateTempObj.tempCelsius))}
          dateTemperatureArr={this.state.dateTempObjArr.filter((dateTempObj) => dateTempObj.tempCelsius !== undefined && dateTempObj.devicetime).sort((a, b) => moment(a.devicetime).diff(moment(b.devicetime)))
            .map((dateTempObj) => dateTempObj.tempCelsius !== undefined && (this.state.tempUnit === 'f' ?
              { x: dateTempObj.devicetime, y: Helpers.convertTemperature(dateTempObj.tempCelsius, 'c', 'f') }
              :
              { x: dateTempObj.devicetime, y: dateTempObj.tempCelsius }
            ))
          }
          tempUnit={this.state.tempUnit}
        />
        <div className="inlineBlock" style={{ width: '100%', textAlign: 'center' }}>
          <SBSelect
            autocompleteLabel="Unit"
            defaultToggleText={this.state.tempUnit === 'c' ? 'Celsius' : 'Farenheit'}
            items={[
              {
                key: 0,
                value: 'c',
                label: 'Celsius',
              },
              {
                key: 1,
                value: 'f',
                label: 'Farenheit',
              },
            ]}
            getSelectedItems={(selection) => {
              if (selection[0].value === 'c') this.setState({ ...this.state, tempUnit: 'c' });
              else this.setState({ ...this.state, tempUnit: 'f' });
            }}
          />
          <Button 
            icon="pi pi-download" 
            label="Download CSV" 
            disabled={!this.state.name} 
            onClick={() => this.downloadCsv()}
          />
        </div>
        <SBTable
          hover
          height={'30em'}
          responsive
          tableHeaderRows={tableHeaderRows}
          tableBodyRows={tableBodyRows}
          isLoading={!this.state.fetched}
          emptyTableMessage={
            <div>
              Select a trailer unit and date to view the historical temperature from the trailer.
              <br /><br />
              <a href="https://www.onswitchboard.com" target="_blank">Click here to purchase temperature probes at the Switchboard Store today.</a>
            </div>
          }
        />
      </div>
    )
  }
}

TrailersLayout.propTypes = {
  Trailer: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  const { Trailer } = state
  return {
    Trailer
  }
}

export default connect(mapStateToProps, null, null, { context: StoreContext })(TrailersLayout);

