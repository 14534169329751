import React from 'react';
import PropTypes from 'prop-types';

// Components
import AddEditFuelPurchasesModal from './AddEditFuelPurchasesModal';

// MDB
import { MDBBtn, MDBIcon } from 'mdbreact';

class AddEditFuelPurchases extends React.Component {
  constructor(props) {
    super();
    this.state = {
      addEditFuelPurchasesModal: {
        show: false,
      },
    };

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  showModal() {
    this.state.addEditFuelPurchasesModal.show = true;
    this.setState({
      ...this.state,
    });
  }

  closeModal() {
    this.state.addEditFuelPurchasesModal.show = false;
    this.setState({
      ...this.state,
    });
  }

  render() {
    const { isEdit, iftaRoute, updateRouteInformationWithFuelPurchases, fuelPurchaseHash, initialDate, initialStateProvince } = this.props;

    if (isEdit) {
      return (
        <React.Fragment>
          {this.state.addEditFuelPurchasesModal.show &&
            <AddEditFuelPurchasesModal
              show={this.state.addEditFuelPurchasesModal.show}
              isEdit={true}
              initialDate={initialDate}
              initialStateProvince={initialStateProvince}
              iftaRoute={iftaRoute.iftaRoute}
              updateRouteInformationWithFuelPurchases={(fuelPurchaseHash) => updateRouteInformationWithFuelPurchases(fuelPurchaseHash)}
              fuelPurchaseHash={fuelPurchaseHash}
              handleClose={this.closeModal}
            />
          }
          <MDBBtn className={'buttonDefault'} onClick={this.showModal}><MDBIcon icon="gas-pump" className="mr-2" />Fuel Purchases</MDBBtn>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {this.state.addEditFuelPurchasesModal.show &&
            <AddEditFuelPurchasesModal
              show={this.state.addEditFuelPurchasesModal.show}
              isEdit={false}
              initialDate={initialDate}
              initialStateProvince={initialStateProvince}
              iftaRoute={iftaRoute.iftaRoute}
              updateRouteInformationWithFuelPurchases={(fuelPurchaseHash) => updateRouteInformationWithFuelPurchases(fuelPurchaseHash)}
              fuelPurchaseHash={fuelPurchaseHash}
              handleClose={this.closeModal}
            />
          }
          <MDBBtn className={'buttonDefault'} onClick={this.showModal}><MDBIcon icon="gas-pump" className="mr-2" />Fuel Purchases</MDBBtn>
        </React.Fragment>
      );
    }
  }
}

export default AddEditFuelPurchases;
