import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// API
import { getPDispatcherPropertyFromState, getObjectById } from 'api/Getters';
import * as Helpers from 'api/Helpers';
import CtpatLoading from 'api/Lists/CtpatLoading';
import TripInspectionType from 'api/Lists/TripInspectionType';
import TripDefectType from 'api/Lists/TripDefectType';
import * as ELD from 'api/ELD';
import { getAttribute } from 'api/Parse';
import { getTimezoneAbbreviation } from 'api/utils/timezone';

// Components
import CloseXButton from 'components/CloseXButton/view/CloseXButton';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import DeleteXButton from 'components/DeleteXButton/view/DeleteXButton';
// import TripInspectionsPrintStyle from 'components/TripInspection/view/TripInspectionsPrintStyle';

// CSS
import styles from './TripInspectionDetails.module.scss';
import { MDBBtn } from 'mdbreact';
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay';

function TripInspectionDetails(props) {
  const { tripInspection } = props;

  const distanceUnit = getPDispatcherPropertyFromState('distanceUnit');
  const driver = tripInspection.get('driver');
  const timezoneOffsetFromUTC = (driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();
  const vehicleId = tripInspection.get('vehicleUnitId');
  const vehiclePlate = tripInspection.get('vehiclePlate');
  const vehicle = tripInspection.get('vehicle');
  const tripInspectionTypeInt = tripInspection.get('type') || 1;
  const tripInspectionVehicle = props.tripInspectionVehicle;
  const vehicleLicensePlate = tripInspectionVehicle && tripInspectionVehicle.get('licensePlate');

  const trailer1Id = tripInspection.get('trailer1UnitId');
  const trailer1Plate = tripInspection.get('trailer1Plate');
  const trailerFirst = getAttribute(tripInspection, 'trailerFirst');
  const trailerFirstLicensePlate = trailerFirst && getAttribute(trailerFirst, 'licensePlate');
  const trailerFirstHTML = (
    <div className={styles.vehicle}>
      <b>{t('Trailer 1 Unit No:')}</b> {trailer1Id}
      {trailer1Plate && (<span>, <b>{t('Plate:')}</b> {trailer1Plate}</span>)}
      {(trailer1Plate && trailerFirstLicensePlate && getAttribute(trailerFirstLicensePlate, 'stateProvince')) && (<span> <i>({getAttribute(trailerFirstLicensePlate, 'stateProvince') || 'No Jurisdiction'})</i></span>)}
    </div>
  );

  const trailer2Id = tripInspection.get('trailer2UnitId');
  const trailer2Plate = tripInspection.get('trailer2Plate');
  const trailerSecond = getAttribute(tripInspection, 'trailerSecond');
  const trailerSecondLicensePlate = trailerSecond && getAttribute(trailerSecond, 'licensePlate');
  const trailerSecondHTML = (
    <div className={styles.vehicle}>
      <b>{t('Trailer 2 Unit No:')}</b> {trailer2Id}
      {trailer2Plate && (<span>, <b>{t('Plate:')}</b> {trailer2Plate}</span>)}
      {(trailer2Plate && trailerSecondLicensePlate && getAttribute(trailerSecondLicensePlate, 'stateProvince')) && (<span> <i>({getAttribute(trailerSecondLicensePlate, 'stateProvince') || 'No Jurisdiction'})</i></span>)}
    </div>
  );

  const vehicleDefects = tripInspection.get('tripDefects').filter((tripDefect) => tripDefect.get('sectionId') === vehicleId);
  const trailer1Defects = tripInspection.get('tripDefects').filter((tripDefect) => tripDefect.get('sectionId') === trailer1Id).sort();
  const trailer2Defects = tripInspection.get('tripDefects').filter((tripDefect) => tripDefect.get('sectionId') === trailer2Id);
  const vehicleDefectTypes = TripDefectType[tripInspectionTypeInt].vehicleDefects.filter((vehicleDefect) => vehicleDefect.type);
  const trailerDefectTypes = TripDefectType[tripInspectionTypeInt].trailerDefects.filter((trailerDefect) => trailerDefect.type);
  const sealIds = (tripInspection.get('seals') && tripInspection.get('seals').map((seal, index) => {
    return seal.get('sealId');
  })) || [];

  const isCTPATInspection = getAttribute(tripInspection, 'type') === 3;
  const isPostTripInspection = getAttribute(tripInspection, 'type') === 2;
  const ctpatLoading = getAttribute(tripInspection, 'ctpatLoading') || 0; // Defaults to Loading Not Inspected if undefined
  const ctpatHasBill = getAttribute(tripInspection, 'ctpatHasBill') || undefined;
  const ctpatInspectionPlace = getAttribute(tripInspection, 'ctpatInspectionPlace') || undefined;


  return (
    <div className={`${styles.container} printable`}>
      {props.printInProgress === true &&
        <LoadingOverlay text={t('Please wait while your file is being prepared. This might take a minute...')} />
      }
      <div className={styles.xButton}><CloseXButton handleClick={() => props.handleClose()} /></div>
      <div className={`${styles.print} print-hide`}>
        {
          // props.printInProgress !== true &&
          // <RaisedButton
          //   icon={<i className="material-icons">print</i>}
          //   label="Print"
          //   primary
          //   onClick={() => props.printTripInspection()}
          // />
        }
        {/* {props.printInProgress === true &&
          <LoadingIcon />
        } */}
      </div>
      <div className={styles.header}>
        <div className={styles.title}>
          {t(`Driver's Vehicle Inspection Report - ${TripInspectionType[props.tripInspection.get('type')]}`)}
          <DeleteXButton
            key={`${props.tripInspection.id}-disable`}
            handleClick={() => props.toggleEditTripInspectionDialog(props.tripInspection, true)}
            style={{ verticalAlign: 'middle', display: 'inline-block', marginTop: '-.4em', marginLeft: '.5em' }}
          />

          <MDBBtn
            color="secondary"
            size="sm"
            style={{ width: '14em' }}
            onClick={() => {
              props.setPrintState(true);
              ELD.generateHOSLogs([props.tripInspection.get('eldDailyCertification').id], undefined, [props.tripInspection.get('driver').id], true, true, false, false, true, false, false, true, null, [], false, `Trip Inspection - ${props.tripInspection.get('driver') && Helpers.toTitleCase(props.tripInspection.get('driver').get('user_fullName'))}`).then(logParseObj => {
                props.goToDownload();
                // // this.setState({ ...this.state, generateSuccess: true });
                // let interval = setInterval(() => {
                //   getObjectById('LogDownload', logParseObj.id).then((obj) => {
                //     if (!obj.get('file')) {
                //       // still waiting
                //     } else {
                //       console.log(obj.get('file').url());
                //       window.location.replace(obj.get('file').url());
                //       clearInterval(interval);
                //       props.setPrintState(false);
                //     }
                //   });
                // }, 1000);
              });
            }}
          >
            {t('Print All Pre-Trip Inspections')}
          </MDBBtn>

          {isCTPATInspection && (
            <MDBBtn
              color="secondary"
              size="sm"
              style={{ width: '14em' }}
              onClick={() => {
                props.setPrintState(true);
                ELD.generateHOSLogs([props.tripInspection.get('eldDailyCertification').id], undefined, [props.tripInspection.get('driver').id], true, true, false, false, false, true, false, true, null, [], false, `Trip Inspection - ${props.tripInspection.get('driver') && Helpers.toTitleCase(props.tripInspection.get('driver').get('user_fullName'))}`).then(logParseObj => {
                  props.goToDownload();
                  // // this.setState({ ...this.state, generateSuccess: true });
                  // let interval = setInterval(() => {
                  //   getObjectById('LogDownload', logParseObj.id).then((obj) => {
                  //     if (!obj.get('file')) {
                  //       // still waiting
                  //     } else {
                  //       console.log(obj.get('file').url());
                  //       window.location.replace(obj.get('file').url());
                  //       clearInterval(interval);
                  //       props.setPrintState(false);
                  //     }
                  //   });
                  // }, 1000);
                });
              }}
            >
              {t('Print C-TPAT/PIP Only')}
            </MDBBtn>
          )}
          {isPostTripInspection && (
            <MDBBtn
              color="secondary"
              size="sm"
              style={{ width: '14em' }}
              onClick={() => {
                props.setPrintState(true);
                ELD.generateHOSLogs([props.tripInspection.get('eldDailyCertification').id], undefined, [props.tripInspection.get('driver').id], true, true, false, false, false, false, true, true, null, [], false, `Trip Inspection - ${props.tripInspection.get('driver') && Helpers.toTitleCase(props.tripInspection.get('driver').get('user_fullName'))}`).then(logParseObj => {
                  props.goToDownload();
                });
              }}
            >
              {t('Print Post-Trip Only')}
            </MDBBtn>
          )}
          {props.tripInspection.get('hasUnresolvedDefects') &&
            <div className={styles.issues}><div className={styles.alertIcon}><i className="material-icons">error</i></div>{t('Trip inspection shows unrepaired issues')}</div>
          }
          <br />
          <div className={styles.driver}>
            {props.tripInspection.get('driver') && Helpers.toTitleCase(props.tripInspection.get('driver').get('user_fullName'))}, {moment(props.tripInspection.get('dateTime')).tz(timezoneOffsetFromUTC).format('LLL')} ({getTimezoneAbbreviation(getAttribute(props.tripInspection, 'dateTime', true), timezoneOffsetFromUTC)})
          </div>
        </div>
      </div>
      <div className={styles.identifiers}>
        {isCTPATInspection &&
          (
            <React.Fragment>
              <div className={styles.ctpatLoading}>{CtpatLoading[ctpatLoading]}</div>
              <div className={styles.ctpatHasBill}><b>{t('C-TPAT/PIP has Bill of Lading:')} </b>{t(`${ctpatHasBill ? 'Yes' : 'No'}`)}</div>
              <div className={styles.ctpatInspectionPlace}><b>{t('C-TPAT/PIP Inspection Place:')} </b>{ctpatInspectionPlace ? ctpatInspectionPlace : 'N/A'}</div>
            </React.Fragment>
          )}
        <div>
          <div className={styles.vehicle}>
            <b>{t('Vehicle:')}</b> {vehicleId}{vehiclePlate && (<span>, <b>{t('Plate:')}</b> {vehiclePlate}</span>)}
            {tripInspectionVehicle && tripInspectionVehicle.get('licensePlate') && tripInspectionVehicle.get('licensePlate').get('stateProvince') &&
              (<span> <i>({tripInspectionVehicle.get('licensePlate').get('stateProvince')})</i></span>)
            }
          </div>
          {trailer1Id && trailerFirstHTML}
          {trailer2Id && trailerSecondHTML}
        </div>
        <div className={styles.location}>
          <b>{t('Location:')}</b> {distanceUnit === 'mi' ? (props.tripInspection.get('locationDescriptionUS') || 'N/A') : (props.tripInspection.get('locationDescriptionCA') || 'N/A')}
        </div>
        <div className={styles.odometer}>
          <b>{t('Odometer:')} </b>
          {distanceUnit === 'mi' && `${Helpers.convertDistance(props.tripInspection.get('odometerKm'), 'km', 'mi')} mi`}
          {distanceUnit !== 'mi' && `${props.tripInspection.get('odometerKm')} km`}
        </div>
        <div className={styles.odometer}>
          <b>{t('Seals:')} </b>
          {sealIds.join(',')}
        </div>

        <div className={styles.odometer}>
          <kbd>Notes: {tripInspection.get('notes') || 'N/A'}</kbd>
        </div>

      </div>
      <table className={styles.defects}><tbody><tr>
        <td>
          <div className={styles.defectTypeTitle}>{t('Vehicle Defects')}</div>
          {vehicleDefectTypes.map((vehicleDefectType) => {
            const defectIndex = vehicleDefects.map((vehicleDefect) => vehicleDefect.get('type')).indexOf(vehicleDefectType.referenceInt);
            const defect = defectIndex !== -1 ? vehicleDefects[defectIndex] : null;
            return (
              <div className={styles.defectBlock} key={vehicleDefectType.referenceInt}>
                <div className={styles.defectTitle}>{vehicleDefectType.type}</div>
                <div className={styles.defectStatus}>
                  {defect &&
                    <div>
                      {defect &&
                        <div>
                          {defect.get('fixed') !== true &&
                            <span className={styles.defectExists}>
                              {defect.get('severity') === 1 &&
                                <span>{t('Defect (Minor)')}</span>
                              }
                              {defect.get('severity') === 2 &&
                                <span>{t('Defect (Major)')}</span>
                              }
                            </span>
                          }
                          {defect.get('fixed') === true &&
                            <span className={styles.defectFixed}>

                              {t('Fixed')}
                            </span>
                          }
                        </div>
                      }
                    </div>
                  }
                  {!defect &&
                    <div><span className={styles.defectDoesNotExist}>{t('Good')}</span></div>
                  }
                </div>
              </div>
            );
          })}
        </td>
        {trailer1Id &&
          <td>
            <div className={styles.defectTypeTitle}>{t('Trailer 1 Defects')}</div>
            {trailerDefectTypes.map((trailerDefectType) => {
              const defectIndex = trailer1Defects.map((vehicleDefect) => vehicleDefect.get('type')).indexOf(trailerDefectType.referenceInt);
              const defect = defectIndex !== -1 ? vehicleDefects[defectIndex] : null;
              return (
                <div className={styles.defectBlock} key={trailerDefectType.referenceInt}>
                  <div className={styles.defectTitle}>{trailerDefectType.type}</div>
                  <div className={styles.defectStatus}>
                    {defect &&
                      <div>
                        {defect &&
                          <div>
                            {defect.get('fixed') !== true &&
                              <span className={styles.defectExists}>
                                {defect.get('severity') === 1 &&
                                  <span>{t('Defect (Minor)')}</span>
                                }
                                {defect.get('severity') === 2 &&
                                  <span>{t('Defect (Major)')}</span>
                                }
                              </span>
                            }
                            {defect.get('fixed') === true &&
                              <span className={styles.defectFixed}>

                                {t('Fixed')}
                              </span>
                            }
                          </div>
                        }
                      </div>
                    }
                    {!defect &&
                      <div><span className={styles.defectDoesNotExist}>{t('Good')}</span></div>
                    }
                  </div>
                </div>
              );
            })}
          </td>
        }
        {trailer2Id &&
          <td>
            <div className={styles.defectTypeTitle}>{t('Trailer 2 Defects')}</div>
            {trailerDefectTypes.map((trailerDefectType) => {
              const defectIndex = trailer2Defects.map((vehicleDefect) => vehicleDefect.get('type')).indexOf(trailerDefectType.referenceInt);
              const defect = defectIndex !== -1 ? vehicleDefects[defectIndex] : null;
              return (
                <div className={styles.defectBlock} key={trailerDefectType.referenceInt}>
                  <div className={styles.defectTitle}>{trailerDefectType.type}</div>
                  <div className={styles.defectStatus}>
                    {defect &&
                      <div>
                        {defect.get('fixed') !== true &&
                          <span className={styles.defectExists}>
                            {defect.get('severity') === 1 &&
                              <span>{t('Defect (Minor)')}</span>
                            }
                            {defect.get('severity') === 2 &&
                              <span>{t('Defect (Major)')}</span>
                            }
                          </span>
                        }
                        {defect.get('fixed') === true &&
                          <span className={styles.defectFixed}>

                            {t('Fixed')}
                          </span>
                        }
                      </div>
                    }
                    {!defect &&
                      <div><span className={styles.defectDoesNotExist}>{t('Good')}</span></div>
                    }
                  </div>
                </div>
              );
            })}
          </td>
        }
      </tr></tbody></table>
    </div>
  );
}

TripInspectionDetails.propTypes = {
  handleClose: PropTypes.func.isRequired,
  toggleEditTripInspectionDialog: PropTypes.func,
  tripInspectionVehicle: PropTypes.object,
  tripInspection: PropTypes.object.isRequired,
  setPrintState: PropTypes.func.isRequired,
  printInProgress: PropTypes.bool,
};

export default TripInspectionDetails;
