// libraries
import { useState } from 'react';
import moment from 'moment-timezone';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';

// api
import { getAttribute } from 'api/Parse';

// enums
import { ReminderTypesInt, ReminderTypesStr, FrequencyTypesInt, FrequencyTypesStr } from 'enums/SafetyRecurringReminder';

// components
import DateTimePicker from 'components/Shared/DateTimePicker/DateTimePicker';
import DriverAutocomplete from 'components/Shared/DriverAutocomplete/DriverAutocomplete.old';
import VehicleAutocomplete from 'components/Shared/VehicleAutocomplete/VehicleAutocomplete';

function AddEditSafetyRecurringRemindersModal(props) {
  const [name, setName] = useState('');
  const [notes, setNotes]= useState('');
  const [reminderType, setReminderType] = useState();
  const [frequencyType, setFrequencyType] = useState();
  const [triggerDate, setTriggerDate] = useState();
  const [driver, setDriver] = useState();
  const [vehicle, setVehicle] = useState();

  async function handleAddEdit() {
    if (props.isEdit) {
      await props.handleEdit({
        reminderType,
        frequencyType,
        triggerDate,
        driver,
        vehicle,
      });
    } else {
      await props.handleAdd({
        name,
        notes,
        reminderType,
        frequencyType,
        triggerDate,
        driver,
        vehicle,
        isHidden: false,
        statusInt: 0,
      });
    }

    setName('');
    setNotes('');
    setReminderType(undefined);
    setFrequencyType(undefined);
    setTriggerDate(undefined);
    setDriver(undefined);
    setVehicle(undefined);

    props.handleClose();
  }

  function handleClose() {
    setName('');
    setNotes('');
    setReminderType(undefined);
    setFrequencyType(undefined);
    setTriggerDate(undefined);
    setDriver(undefined);
    setVehicle(undefined);

    props.handleClose();
  }

  return (
    <>
      <Dialog
        header={props.isEdit ? 'Edit Reminder' : 'Add Reminder'}
        visible={props.showModal}
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={() => handleClose()}
            />
            <Button
              label="Submit"
              icon="pi pi-check"
              onClick={async () => await handleAddEdit()}
              disabled={
                props.isEdit
                  ?
                    triggerDate &&
                    (frequencyType !== undefined) &&
                    ((reminderType === ReminderTypesStr.COMPANY) || (reminderType === ReminderTypesStr.DRIVER && driver) || (reminderType === ReminderTypesStr.VEHICLE && vehicle))
                      ? false
                      : true
                  :
                    name &&
                    notes &&
                    triggerDate &&
                    (frequencyType !== undefined) &&
                    ((reminderType === ReminderTypesStr.COMPANY) || (reminderType === ReminderTypesStr.DRIVER && driver) || (reminderType === ReminderTypesStr.VEHICLE && vehicle))
                      ? false
                      : true
              }
            />
          </>
        }
        onHide={() => handleClose(false)}
        modal
        closeOnEscape={false}
        style={{ width: '50vw' }}
        draggable={false}
        resizable={false}
      >
        <div className="fluid" style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
          {!props.isEdit && (
            <div>
              <InputText
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Reminder Name"
                style={{ width: '100%' }}
              />
            </div>
          )}
          <div>
            <DateTimePicker
              label="Reminder Date*"
              selectedDate={triggerDate}
              onDateChange={(date) => setTriggerDate(date)}
              showTimePicker
              showYearDropdown
              showMonthDropdown
              dateFormat="MMM dd, yyyy - HH:mm"
              timeIntervals={30}
              shouldCloseOnSelect
              labelClassName="active"
            />
          </div>
          <div>
            <Dropdown
              value={reminderType}
              options={[
                { label: ReminderTypesInt[0], value: ReminderTypesStr.COMPANY },
                { label: ReminderTypesInt[1], value: ReminderTypesStr.DRIVER },
                { label: ReminderTypesInt[2], value: ReminderTypesStr.VEHICLE },
              ]}
              onChange={(e) => setReminderType(e.value)}
              placeholder="Reminder Type"
              style={{ display: 'flex' }}
            />
          </div>
          {reminderType === ReminderTypesStr.DRIVER && (
            <div>
              <DriverAutocomplete
                isRequired
                onDriverSelected={(driver) => {
                  setDriver(driver);
                  setVehicle(undefined);
                }}
                placeholder={driver ? getAttribute(driver, 'user_fullName') : ''}
              />
            </div>
          )}
          {reminderType === ReminderTypesStr.VEHICLE && (
            <div>
              <VehicleAutocomplete
                isRequired
                onVehicleSelected={(vehicle) => {
                  setDriver(undefined);
                  setVehicle(vehicle);
                }}
                placeholder={vehicle ? getAttribute(vehicle, 'unitId') : ''}
              />
            </div>
          )}
          <div>
            <Dropdown
              value={frequencyType}
              options={[
                { label: FrequencyTypesInt[0], value: FrequencyTypesStr.ONE_TIME },
                { label: FrequencyTypesInt[1], value: FrequencyTypesStr.MONTHLY },
                { label: FrequencyTypesInt[2], value: FrequencyTypesStr.ANNUALLY },
              ]}
              onChange={(e) => setFrequencyType(e.value)}
              placeholder="Frequency Type"
              style={{ display: 'flex' }}
            />
          </div>
          {!props.isEdit && (
            <div>
              <InputTextarea
                id="notes"
                rows={5}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Reminder Notes"
              />
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}

export default AddEditSafetyRecurringRemindersModal;
