export default {
   "0": {
      "MESSAGE": "Driver drove without taking a 10-hour break",
      "MESSAGE_LEGAL": "A driver may not drive without first taking 10 consecutive hours off duty.",
      "RULE": "Start of work shift",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_13",
      "SECTION": "CFR 395.3 (a)(1)"
   },
   "1": {
      "MESSAGE": "Driver's shift was more than 14 hours long",
      "MESSAGE_LEGAL": "A driver may drive only during a period of 14 consecutive hours after coming on duty following 10 consecutive hours off duty. The driver may not drive after the end of the 14-consecutive-hour period without first taking 10 consecutive hours off duty.",
      "RULE": "14-hour period",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_13",
      "SECTION": "CFR 395.3 (a)(2)"
   },
   "2": {
      "MESSAGE": "Driver drove more than 11 hours in a shift",
      "MESSAGE_LEGAL": "A driver may drive a total of 11 hours during the 14-hour period specified in paragraph (a)(2) of this section.",
      "RULE": "Driving time",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_13",
      "SECTION": "CFR 395.3 (a)(3)(i)"
   },
   "3": {
      "MESSAGE": "Driver went more than 8 hours without a 30-minute break",
      "MESSAGE_LEGAL": "Except for drivers who qualify for either of the short-haul exceptions in §395.1(e)(1) or (2), driving is not permitted if more than 8 hours have passed since the end of the driver's last off-duty or sleeper-berth period of at least 30 minutes.",
      "RULE": "Rest breaks",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_13",
      "SECTION": "CFR 395.3 (a)(3)(ii)"
   },
   "4": {
      "MESSAGE": "Driver was on-duty for more than 60 hours in a 7-day cycle",
      "MESSAGE_LEGAL": "No motor carrier shall permit or require a driver of a property-carrying commercial motor vehicle to drive, nor shall any driver drive a property-carrying commercial motor vehicle, regardless of the number of motor carriers using the driver's services, for any period after having been on duty 60 hours in any period of 7 consecutive days if the employing motor carrier does not operate commercial motor vehicles every day of the week.",
      "RULE": "Cycles",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_13",
      "SECTION": "CFR 395.3 (b)(1)"
   },
   "5": {
      "MESSAGE": "Driver was on-duty for more than 70 hours in an 8-day cycle",
      "MESSAGE_LEGAL": "No motor carrier shall permit or require a driver of a property-carrying commercial motor vehicle to drive, nor shall any driver drive a property-carrying commercial motor vehicle, regardless of the number of motor carriers using the driver's services, for any period after having been on duty 70 hours in any period of 8 consecutive days if the employing motor carrier operates commercial motor vehicles every day of the week.",
      "RULE": "Cycles",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_13",
      "SECTION": "CFR 395.3 (b)(2)"
   },
   "6": {
      "MESSAGE": "Driver drove more than 13 hours in a day",
      "MESSAGE_LEGAL": "No motor carrier shall request, require or allow a driver to drive and no driver shall drive after the driver has accumulated 13 hours of driving time in a day.",
      "RULE": "Daily Driving and On-duty Time",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-8",
      "SECTION": "SOR/2005-313 12(1)"
   },
   "7": {
      "MESSAGE": "Driver was driving after being on-duty for more than 15 hours in a day",
      "MESSAGE_LEGAL": "No motor carrier shall request, require or allow a driver to drive and no driver shall drive after the driver has accumulated 14 hours of on-duty time in a day.",
      "RULE": "Daily Driving and On-duty Time",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-8",
      "SECTION": "SOR/2005-313 12(2)"
   },
   "8": {
      "MESSAGE": "Driver drove more than 13 hours in a shift",
      "MESSAGE_LEGAL": "No motor carrier shall request, require or allow a driver to drive and no driver shall drive after the driver has accumulated 13 hours of driving time unless the driver takes at least 8 consecutive hours of off-duty time before driving again.",
      "RULE": "Mandatory Off-duty Time",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-9",
      "SECTION": "SOR/2005-313 13(1)"
   },
   "9": {
      "MESSAGE": "Driver was driving after being on-duty for more than 15 hours in a shift",
      "MESSAGE_LEGAL": "No motor carrier shall request, require or allow a driver to drive and no driver shall drive after the driver has accumulated 14 hours of on-duty time unless the driver takes at least 8 consecutive hours of off-duty time before driving again.",
      "RULE": "Mandatory Off-duty Time",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-9",
      "SECTION": "SOR/2005-313 13(2)"
   },
   "10": {
      "MESSAGE": "Driver's shift was more than 16 hours long",
      "MESSAGE_LEGAL": "No motor carrier shall request, require or allow a driver to drive and no driver shall drive after 16 hours of time have elapsed between the conclusion of the most recent period of 8 or more consecutive hours of off-duty time and the beginning of the next period of 8 or more consecutive hours of off-duty time.",
      "RULE": "Mandatory Off-duty Time",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-9",
      "SECTION": "SOR/2005-313 13(3)"
   },
   "11": {
      "MESSAGE": "Driver had fewer than 10 hours of total off-duty time in a day, in blocks of no less than 30 minutes each",
      "MESSAGE_LEGAL": "A motor carrier shall ensure that a driver takes and the driver shall take at least 10 hours of off-duty time in a day.",
      "RULE": "Daily Off-duty Time",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-10",
      "SECTION": "SOR/2005-313 14(1)"
   },
   "12": {
      "MESSAGE": "Driver took fewer than 2 hours worth of off-duty periods, each at least 30 minutes, excluding the mandatory 8-hour period",
      "MESSAGE_LEGAL": "The total amount of off-duty time taken by a driver in a day shall include at least 2 hours of off-duty time that does not form part of a period of 8 consecutive hours of off-duty time required by section 13.",
      "RULE": "Daily Off-duty Time",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-10",
      "SECTION": "SOR/2005-313 14(3)"
   },
   "13": {
      "MESSAGE": "Driver hasn't taken a 24-hour break in the last 14 days",
      "MESSAGE_LEGAL": "Subject to section 28, no motor carrier shall request, require or allow a driver to drive and no driver shall drive unless the driver has taken at least 24 consecutive hours of off-duty time in the preceding 14 days.",
      "RULE": "Cycles",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-15",
      "SECTION": "SOR/2005-313 25"
   },
   "14": {
      "MESSAGE": "Driver was on-duty for more than 70 hours in a 7-day cycle",
      "MESSAGE_LEGAL": "Subject to section 28, no motor carrier shall request, require or allow a driver who is following cycle 1 to drive and no driver who is following cycle 1 shall drive after the driver has accumulated 70 hours of on-duty time during any period of 7 days or, if the driver has reset the cycle in accordance with section 28, during the period of the cycle that was ended.",
      "RULE": "Cycles",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-15",
      "SECTION": "SOR/2005-313 26"
   },
   "15": {
      "MESSAGE": "Subject to section 28, no motor carrier shall request, require or allow a driver who is following cycle 2 to drive and no driver who is following cycle 2 shall drive after the driver has accumulated (a) 120 hours of on-duty time during any period of 14 days or, if the driver has reset the cycle in accordance with section 28, during the period of the cycle that was ended; or (b) 70 hours of on-duty time without having taken at least 24 consecutive hours of off-duty time.",
      "MESSAGE_LEGAL": "Subject to section 28, no motor carrier shall request, require or allow a driver who is following cycle 2 to drive and no driver who is following cycle 2 shall drive after the driver has accumulated (a) 120 hours of on-duty time during any period of 14 days or, if the driver has reset the cycle in accordance with section 28, during the period of the cycle that was ended; or (b) 70 hours of on-duty time without having taken at least 24 consecutive hours of off-duty time.",
      "RULE": "Cycles",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-15",
      "SECTION": "SOR/2005-313 27"
   },
   "16": {
      "MESSAGE": "Driver took fewer than 3 off-duty periods, each at least 24 hours long, in the previous 24 days",
      "MESSAGE_LEGAL": "The permit shall require that the driver take at least 3 periods of off-duty time, each at least 24 hours long, in any period of 24 days, the periods being taken consecutively or separated by on-duty time.",
      "RULE": "Oil Well Service Vehicle Permits",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-4.html#h-31",
      "SECTION": "SOR/2005-313 63(2)(a)"
   },
   "17": {
      "MESSAGE": "Driver was on-duty for more than 120 hours in a 14-day cycle",
      "MESSAGE_LEGAL": "Subject to section 28, no motor carrier shall request, require or allow a driver who is following cycle 2 to drive and no driver who is following cycle 2 shall drive after the driver has accumulated 120 hours of on-duty time during any period of 14 days or, if the driver has reset the cycle in accordance with section 28, during the period of the cycle that was ended.",
      "RULE": "Cycles",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-15",
      "SECTION": "SOR/2005-313 27(a)"
   },
   "18": {
      "MESSAGE": "Driver has been on-duty for more than 70 hours without taking a 24-hour off-duty break",
      "MESSAGE_LEGAL": "Subject to section 28, no motor carrier shall request, require or allow a driver who is following cycle 2 to drive and no driver who is following cycle 2 shall drive after the driver has accumulated 70 hours of on-duty time without having taken at least 24 consecutive hours of off-duty time.",
      "RULE": "Cycles",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-15",
      "SECTION": "SOR/2005-313 27(b)"
   },
   "10000": {
      "MESSAGE": "Driver drove more than 11 hours in a shift",
      "MESSAGE_LEGAL": "A driver may drive a total of 11 hours during the 14-hour period specified in paragraph (a)(2) of this section.",
      "RULE": "Shift Driving",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_13",
      "SECTION": "CFR 395.3 (a)(3)(i)"
   },
   "10040": {
      "MESSAGE": "Driver's shift was more than 14 hours long",
      "MESSAGE_LEGAL": "A driver may drive only during a period of 14 consecutive hours after coming on duty following 10 consecutive hours off duty. The driver may not drive after the end of the 14-consecutive-hour period without first taking 10 consecutive hours off duty.",
      "RULE": "Shift Duration",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_13",
      "SECTION": "CFR 395.3 (a)(2)"
   },
   "10060": {
      "MESSAGE": "Driver went more than 8 hours without a 30-minute break",
      "MESSAGE_LEGAL": "Except for drivers who qualify for either of the short-haul exceptions in §395.1(e)(1) or (2), driving is not permitted if more than 8 hours have passed since the end of the driver's last off-duty or sleeper-berth period of at least 30 minutes.",
      "RULE": "30-minute Break",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_13",
      "SECTION": "CFR 395.3 (a)(3)(ii)"
   },
   "10150": {
      "MESSAGE": "Driver was on-duty for more than 60 hours in a 7-day cycle",
      "MESSAGE_LEGAL": "No motor carrier shall permit or require a driver of a property-carrying commercial motor vehicle to drive, nor shall any driver drive a property-carrying commercial motor vehicle, regardless of the number of motor carriers using the driver's services, for any period after having been on duty 60 hours in any period of 7 consecutive days if the employing motor carrier does not operate commercial motor vehicles every day of the week.",
      "RULE": "Cycle On-Duty",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_13",
      "SECTION": "CFR 395.3 (b)(1)"
   },
   "10250": {
      "MESSAGE": "Driver was on-duty for more than 70 hours in an 8-day cycle",
      "MESSAGE_LEGAL": "No motor carrier shall permit or require a driver of a property-carrying commercial motor vehicle to drive, nor shall any driver drive a property-carrying commercial motor vehicle, regardless of the number of motor carriers using the driver's services, for any period after having been on duty 70 hours in any period of 8 consecutive days if the employing motor carrier operates commercial motor vehicles every day of the week.",
      "RULE": "Cycle On-Duty",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_13",
      "SECTION": "CFR 395.3 (b)(2)"
   },
   "10400": {
      "MESSAGE": "Driver drove more than 11 hours in a shift",
      "MESSAGE_LEGAL": "With respect to the maximum daily hours of service, such a driver may not drive (1) More than 10 hours following 8 consecutive hours off duty",
      "RULE": "Shift Driving",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_11",
      "SECTION": "CFR 395.1 (p)(1)"
   },
   "10410": {
      "MESSAGE": "Driver was driving after being on-duty for more than 15 hours in a shift",
      "MESSAGE_LEGAL": "With respect to the maximum daily hours of service, such a driver may not drive ... (2) For any period after having been on duty 15 hours following 8 consecutive hours off duty",
      "RULE": "Shift On-Duty",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_11",
      "SECTION": "CFR 395.1 (p)(2)"
   },
   "11000": {
      "MESSAGE": "Driver drove more than 15 hours in a shift",
      "MESSAGE_LEGAL": "A driver who is driving a property-carrying commercial motor vehicle in the State of Alaska must not drive or be required or permitted to drive (i) More than 15 hours following 10 consecutive hours off duty",
      "RULE": "Shift Driving",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_11",
      "SECTION": "CFR 395.1 (h)(1)(i)"
   },
   "11010": {
      "MESSAGE": "Driver was on-duty for more than 20 hours in a shift",
      "MESSAGE_LEGAL": "A driver who is driving a property-carrying commercial motor vehicle in the State of Alaska must not drive or be required or permitted to drive ... (ii) After being on duty for 20 hours or more following 10 consecutive hours off duty.",
      "RULE": "Shift On-Duty",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_11",
      "SECTION": "CFR 395.1 (h)(1)(ii)"
   },
   "11150": {
      "MESSAGE": "Driver was on-duty for more than 70 hours in a 7-day cycle",
      "MESSAGE_LEGAL": "A driver who is driving a property-carrying commercial motor vehicle in the State of Alaska must not drive or be required or permitted to drive ... (iii) After having been on duty for 70 hours in any period of 7 consecutive days, if the motor carrier for which the driver drives does not operate every day in the week",
      "RULE": "Cycle On-Duty",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_11",
      "SECTION": "CFR 395.1 (h)(1)(iii)"
   },
   "11250": {
      "MESSAGE": "Driver was on-duty for more than 80 hours in an 8-day cycle",
      "MESSAGE_LEGAL": "A driver who is driving a property-carrying commercial motor vehicle in the State of Alaska must not drive or be required or permitted to drive ... (iv) After having been on duty for 80 hours in any period of 8 consecutive days, if the motor carrier for which the driver drives operates every day in the week",
      "RULE": "Cycle On-Duty",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_11",
      "SECTION": "CFR 395.1 (h)(1)(iv)"
   },
   "12000": {
      "MESSAGE": "Driver drove more than 10 hours in a shift",
      "MESSAGE_LEGAL": "Except as provided in Sections 1212(b)(1), 1212(f), 1212(i), 1212(j), 1212(k), and 1212(l ), no motor carrier shall permit or require any driver used by it to drive nor shall any such driver drive: More than 12 cumulative hours following ten consecutive hours off duty",
      "RULE": "Shift Driving",
      "URL": "https://govt.westlaw.com/calregs/Document/I243EC0C05F7C11DFBF66AC2936A1B85A?viewType=FullText&originationContext=documenttoc&transitionType=CategoryPageItem&contextData=(sc.Default)",
      "SECTION": "13 CA ADC 1212.5 (a)(2)(A)"
   },
   "12040": {
      "MESSAGE": "Driver was driving after being on-duty for more than 15 hours in a shift",
      "MESSAGE_LEGAL": "Except as provided in Sections 1212(b)(1), 1212(f), 1212(i), 1212(j), 1212(k), and 1212(l ), no motor carrier shall permit or require any driver used by it to drive nor shall any such driver drive: ... For any period after the end of the 16th hour after coming on duty following ten consecutive hours off duty",
      "RULE": "Shift On-Duty",
      "URL": "https://govt.westlaw.com/calregs/Document/I243EC0C05F7C11DFBF66AC2936A1B85A?viewType=FullText&originationContext=documenttoc&transitionType=CategoryPageItem&contextData=(sc.Default)",
      "SECTION": "13 CA ADC 1212.5 (a)(2)(B)"
   },
   "12050": {
      "MESSAGE": "Driver was on-duty for more than 80 hours in an 8-day cycle",
      "MESSAGE_LEGAL": "No motor carrier shall permit or require a driver, regardless of the number of motor carriers using the driver's services, to drive nor shall any driver drive, except as provided in Section 1212(k), for any period after having been on duty for 80 hours in any consecutive eight days",
      "RULE": "Cycle On-Duty",
      "URL": "https://govt.westlaw.com/calregs/Document/I243EC0C05F7C11DFBF66AC2936A1B85A?viewType=FullText&originationContext=documenttoc&transitionType=CategoryPageItem&contextData=(sc.Default)",
      "SECTION": "13 CA ADC 1212.5 (a)(4)"
   },
   "12400": {
      "MESSAGE": "Driver drove more than 12 hours in a shift",
      "MESSAGE_LEGAL": "A driver of a commercial motor vehicle providing transportation of property to or from a theatrical or television motion picture production site is exempt from the requirements of Section 1212.5(a)(2) if the driver operates within a 100 air-mile radius of the location where the driver reports to and is released from work, i.e., the normal work-reporting location. With respect to the maximum daily hours of service, such a driver may not drive: More than 12 hours following 8 consecutive hours off duty",
      "RULE": "Shift Driving",
      "URL": "https://govt.westlaw.com/calregs/Document/I243EC0C05F7C11DFBF66AC2936A1B85A?viewType=FullText&originationContext=documenttoc&transitionType=CategoryPageItem&contextData=(sc.Default)",
      "SECTION": "13 CA ADC 1212 (o)(1)"
   },
   "12410": {
      "MESSAGE": "Driver was driving after being on-duty for more than 15 hours in a shift",
      "MESSAGE_LEGAL": "A driver of a commercial motor vehicle providing transportation of property to or from a theatrical or television motion picture production site is exempt from the requirements of Section 1212.5(a)(2) if the driver operates within a 100 air-mile radius of the location where the driver reports to and is released from work, i.e., the normal work-reporting location. With respect to the maximum daily hours of service, such a driver may not drive: ... For any period after having been on duty 15 hours following 8 consecutive hours off duty",
      "RULE": "Shift On-Duty",
      "URL": "https://govt.westlaw.com/calregs/Document/I243EC0C05F7C11DFBF66AC2936A1B85A?viewType=FullText&originationContext=documenttoc&transitionType=CategoryPageItem&contextData=(sc.Default)",
      "SECTION": "13 CA ADC 1212 (o)(2)"
   },
   "13000": {
      "MESSAGE": "Driver drove more than 12 hours in a shift",
      "MESSAGE_LEGAL": "Drivers in intrastate commerce will be permitted to drive 12 hours following 8 consecutive hours off duty",
      "RULE": "Shift Driving",
      "URL": "ext.TacPage?sl=R&app=2&p_dir=&p_rloc=183252&p_tloc=&p_ploc=&pg=1&p_tac=183252&ti=37&pt=1&ch=4&rl=12&dt=&z_chk=&z_contains=",
      "SECTION": "37 TAC 4.12(a)(2)"
   },
   "13010": {
      "MESSAGE": "Driver was driving after being on-duty for more than 15 hours in a shift",
      "MESSAGE_LEGAL": "Drivers in intrastate commerce may not drive after having been on duty 15 hours, following 8 consecutive hours off duty.",
      "RULE": "Shift On-Duty",
      "URL": "ext.TacPage?sl=R&app=2&p_dir=&p_rloc=183252&p_tloc=&p_ploc=&pg=1&p_tac=183252&ti=37&pt=1&ch=4&rl=12&dt=&z_chk=&z_contains=",
      "SECTION": "37 TAC 4.12(a)(2)"
   },
   "13150": {
      "MESSAGE": "Driver was on-duty for more than 70 hours in a 7-day cycle",
      "MESSAGE_LEGAL": "Drivers of vehicles under this section operating in intrastate transportation shall not be permitted to drive after having worked and/or driven for 70 hours in any consecutive seven-day period.",
      "RULE": "Cycle On-Duty",
      "URL": "ext.TacPage?sl=R&app=2&p_dir=&p_rloc=183252&p_tloc=&p_ploc=&pg=1&p_tac=183252&ti=37&pt=1&ch=4&rl=12&dt=&z_chk=&z_contains=",
      "SECTION": "37 TAC 4.12(b)(2)"
   },
   "14000": {
      "MESSAGE": "Driver drove more than 10 hours in a shift",
      "RULE": "Shift Driving",
      "URL": "https://www.ecfr.gov/cgi-bin/retrieveECFR?gp=1&ty=HTML&h=L&mc=true&=PART&n=pt49.5.395#se49.5.395_15",
      "SECTION": "CFR 395.5 (a)(1)"
   },
   "14040": {
      "MESSAGE": "Driver's shift was more than 15 hours long",
      "RULE": "Shift Duration",
      "URL": "https://www.ecfr.gov/cgi-bin/retrieveECFR?gp=1&ty=HTML&h=L&mc=true&=PART&n=pt49.5.395#se49.5.395_15",
      "SECTION": "CFR 395.5 (a)(2)"
   },
   "14150": {
      "MESSAGE": "Driver was on-duty for more than 60 hours in a 7-day cycle",
      "RULE": "Cycle On-Duty",
      "URL": "https://www.ecfr.gov/cgi-bin/retrieveECFR?gp=1&ty=HTML&h=L&mc=true&=PART&n=pt49.5.395#se49.5.395_15",
      "SECTION": "CFR 395.5 (b)(1)"
   },
   "14250": {
      "MESSAGE": "Driver was on-duty for more than 70 hours in a 8-day cycle",
      "RULE": "Cycle On-Duty",
      "URL": "https://www.ecfr.gov/cgi-bin/retrieveECFR?gp=1&ty=HTML&h=L&mc=true&=PART&n=pt49.5.395#se49.5.395_15",
      "SECTION": "CFR 395.5 (b)(2)"
   },
   "15000": {
      "MESSAGE": "Driver drove more than 15 hours in a shift",
      "RULE": "Shift Driving",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_11",
      "SECTION": "CFR 395.1 (h)(2)(i)"
   },
   "15010": {
      "MESSAGE": "Driver was on-duty for more than 20 hours in a shift",
      "RULE": "Shift On-Duty",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_11",
      "SECTION": "CFR 395.1 (h)(2)(ii)"
   },
   "15150": {
      "MESSAGE": "Driver was on-duty for more than 70 hours in a 7-day cycle",
      "RULE": "Cycle On-Duty",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_11",
      "SECTION": "CFR 395.1 (h)(2)(iii)"
   },
   "15250": {
      "MESSAGE": "Driver was on-duty for more than 80 hours in a 8-day cycle",
      "RULE": "Cycle On-Duty",
      "URL": "https://www.ecfr.gov/cgi-bin/text-idx?SID=8fa5cf9d416c107c7796351994328632&mc=true&node=pt49.5.395&rgn=div5#se49.5.395_11",
      "SECTION": "CFR 395.1 (h)(2)(iv)"
   },
   "20000": {
      "MESSAGE": "Driver drove more than 13 hours in a shift",
      "MESSAGE_LEGAL": "No motor carrier shall request, require or allow a driver to drive and no driver shall drive after the driver has accumulated 13 hours of driving time unless the driver takes at least 8 consecutive hours of off-duty time before driving again.",
      "RULE": "Shift Driving",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-9",
      "SECTION": "SOR/2005-313 13(1)"
   },
   "20010": {
      "MESSAGE": "Driver was driving after being on-duty for more than 14 hours in a shift",
      "MESSAGE_LEGAL": "No motor carrier shall request, require or allow a driver to drive and no driver shall drive after the driver has accumulated 14 hours of on-duty time unless the driver takes at least 8 consecutive hours of off-duty time before driving again.",
      "RULE": "Shift On-Duty",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-9",
      "SECTION": "SOR/2005-313 13(2)"
   },
   "20020": {
      "MESSAGE": "Driver drove more than 13 hours in a day",
      "MESSAGE_LEGAL": "No motor carrier shall request, require or allow a driver to drive and no driver shall drive after the driver has accumulated 13 hours of driving time in a day.",
      "RULE": "Daily Driving",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-8",
      "SECTION": "SOR/2005-313 12(1)"
   },
   "20030": {
      "MESSAGE": "Driver was driving after being on-duty for more than 14 hours in a day",
      "MESSAGE_LEGAL": "No motor carrier shall request, require or allow a driver to drive and no driver shall drive after the driver has accumulated 14 hours of on-duty time in a day.",
      "RULE": "Daily On-Duty",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-8",
      "SECTION": "SOR/2005-313 12(2)"
   },
   "20040": {
      "MESSAGE": "Driver's shift was more than 16 hours long",
      "MESSAGE_LEGAL": "No motor carrier shall request, require or allow a driver to drive and no driver shall drive after 16 hours of time have elapsed between the conclusion of the most recent period of 8 or more consecutive hours of off-duty time and the beginning of the next period of 8 or more consecutive hours of off-duty time.",
      "RULE": "Shift Duration",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-9",
      "SECTION": "SOR/2005-313 13(3)"
   },
   "20061": {
      "MESSAGE": "Driver took fewer than 2 hours worth of off-duty periods, each at least 30 minutes, excluding the mandatory 8-hour period",
      "MESSAGE_LEGAL": "The total amount of off-duty time taken by a driver in a day shall include at least 2 hours of off-duty time that does not form part of a period of 8 consecutive hours of off-duty time required by section 13.",
      "RULE": "Daily Off-Duty",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-10",
      "SECTION": "SOR/2005-313 14(3)"
   },
   "20070": {
      "MESSAGE": "Driver had fewer than 10 hours of total off-duty time in a day, in blocks of no less than 30 minutes each",
      "MESSAGE_LEGAL": "A motor carrier shall ensure that a driver takes and the driver shall take at least 10 hours of off-duty time in a day.",
      "RULE": "Daily Off-Duty",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-2.html#h-10",
      "SECTION": "SOR/2005-313 14(1)"
   },
   "20080": {
      "MESSAGE": "Driver hasn't taken a 24-hour break in the last 14 days",
      "MESSAGE_LEGAL": "Subject to section 28, no motor carrier shall request, require or allow a driver to drive and no driver shall drive unless the driver has taken at least 24 consecutive hours of off-duty time in the preceding 14 days.",
      "RULE": "24-hour Break",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-15",
      "SECTION": "SOR/2005-313 25"
   },
   "20150": {
      "MESSAGE": "Driver was on-duty for more than 70 hours in a 7-day cycle",
      "MESSAGE_LEGAL": "Subject to section 28, no motor carrier shall request, require or allow a driver who is following cycle 1 to drive and no driver who is following cycle 1 shall drive after the driver has accumulated 70 hours of on-duty time during any period of 7 days or, if the driver has reset the cycle in accordance with section 28, during the period of the cycle that was ended.",
      "RULE": "Cycle On-Duty",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-15",
      "SECTION": "SOR/2005-313 26"
   },
   "20350": {
      "MESSAGE": "Driver was on-duty for more than 120 hours in a 14-day cycle",
      "MESSAGE_LEGAL": "Subject to section 28, no motor carrier shall request, require or allow a driver who is following cycle 2 to drive and no driver who is following cycle 2 shall drive after the driver has accumulated 120 hours of on-duty time during any period of 14 days or, if the driver has reset the cycle in accordance with section 28, during the period of the cycle that was ended.",
      "RULE": "Cycle On-Duty",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-15",
      "SECTION": "SOR/2005-313 27(a)"
   },
   "20351": {
      "MESSAGE": "Driver has been on-duty for more than 70 hours without taking a 24-hour off-duty break",
      "MESSAGE_LEGAL": "Subject to section 28, no motor carrier shall request, require or allow a driver who is following cycle 2 to drive and no driver who is following cycle 2 shall drive after the driver has accumulated 70 hours of on-duty time without having taken at least 24 consecutive hours of off-duty time.",
      "RULE": "Cycle On-Duty",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-15",
      "SECTION": "SOR/2005-313 27(b)"
   },
   "20580": {
      "MESSAGE": "Driver took fewer than 3 off-duty periods, each at least 24 hours long, in the previous 24 days",
      "MESSAGE_LEGAL": "The permit shall require that the driver take at least 3 periods of off-duty time, each at least 24 hours long, in any period of 24 days, the periods being taken consecutively or separated by on-duty time.",
      "RULE": "Oil Well 24-hour breaks",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-4.html#h-31",
      "SECTION": "SOR/2005-313 63(2)(a)"
   },
   "20680": {
      "MESSAGE": "Driver drove after over 65 driving hours in 7 days cycle",
      "RULE": "Logger Cycle",
   },
   "20681": {
      "MESSAGE": "Driver drove after over 80 on-duty hours in 7 days cycle",
      "RULE": "Logger Cycle",
   },
   "21000": {
      "MESSAGE": "Driver drove more than 15 hours in a shift",
      "RULE": "Shift Driving",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-20",
      "SECTION": "SOR/2005-313 38(1)"
   },
   "21010": {
      "MESSAGE": "Driver was on-duty for more than 18 hours in a shift",
      "RULE": "Shift On-Duty",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-20",
      "SECTION": "SOR/2005-313 38(2)"
   },
   "21040": {
      "MESSAGE": "Driver's shift was more than 20 hours long",
      "RULE": "Shift Duration",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-21",
      "SECTION": "SOR/2005-313 39(2)"
   },
   "21150": {
      "MESSAGE": "Driver was on-duty for more than 80 hours in a 7-day cycle",
      "RULE": "Cycle On-Duty",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-25",
      "SECTION": "SOR/2005-313 51"
   },
   "21350": {
      "MESSAGE": "Driver was on-duty for more than 120 hours in a 14-day cycle",
      "RULE": "Cycle On-Duty",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-25",
      "SECTION": "SOR/2005-313 52(a)"
   },
   "21351": {
      "MESSAGE": "Driver has been on-duty for more than 80 hours without taking a 24-hour off-duty break",
      "RULE": "Cycle On-Duty",
      "URL": "http://laws-lois.justice.gc.ca/eng/regulations/SOR-2005-313/page-3.html#h-25",
      "SECTION": "SOR/2005-313 52(b)"
   },
   "100000": {
      "MESSAGE": "Driver started driving without completing a pre-trip inspection",
      "RULE": "Pre-Trip Inspection"
   },
   "100001": {
      "MESSAGE": "Day has no valid odometer readings",
      "RULE": "Odometer"
   },
   "100002": {
      "MESSAGE": "Odometer jump detected: Vehicle was in motion while not connected to the logging system",
      "RULE": "Odometer"
   },
   "100004": {
      "MESSAGE": "Driver took less than 15 minutes for Pre-Trip Inspection",
      "RULE": "Pre-Trip Inspection"
   },
   "142069": {
      "message": "Driver switched to PC while they had a trailer/shipment on them",
      "rule": "PC Country Lock"
   },
   "142070": {
      "message": "Driver started driving without completing a pre-trip inspection or shipment",
      "rule": "Pre-Trip Inspection or Shipment"
   },
   "16970": {
      "message": "Driver has completed pre-trip inspection in under 15 minutes.",
      "rule": "Pre-Trip Inspection"
   }
};
