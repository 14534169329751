import Parse from 'parse';
import * as ParseAPI from 'api/Parse';
import { addRecord, createQuery, setQueryRestriction, updateRecord, find, includePointers, getAttribute, sortQuery, getCurrentUser } from 'api/Parse';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import Sort from 'sbObjects/Sort';
import * as Helpers from 'api/Helpers';

/** @module ELDHardware */

/**
* @memberof module:ELDHardware
* @description getOrCreateELDHardwareFromSerial
*/

const getOrCreateELDHardwareFromSerial = async (serial) => {
  const belongsToCompanyPointer = ParseAPI.makeParseObjectById('Company', Parse.User.current().get('belongsToCompany').id);

  const eldHardwareQuery = new Parse.Query('ELDHardware');
  eldHardwareQuery.equalTo('serial', serial);
  eldHardwareQuery.equalTo('belongsToCompany', belongsToCompanyPointer);
  const eldHardwareObj = await eldHardwareQuery.first();
  if (eldHardwareObj) return eldHardwareObj;

  const parseInputObj = {
    serial,
  };
  parseInputObj.belongsToCompany = belongsToCompanyPointer;
  return addRecord('ELDHardware', parseInputObj, Helpers.getCompanyReadWriteACL());
}

export {
  getOrCreateELDHardwareFromSerial,
};
