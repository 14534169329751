import React from 'react';
import PropTypes from 'prop-types';

// API
import { concatenateStringsInParseObjectsArray, formatName, stopPropagation } from 'api/Helpers';

// Components
import { Table } from 'react-bootstrap';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import ChooseDriverButton from 'components/ChooseDriver/container/ChooseDriverButton';

// CSS
import styles from './ChooseList.module.scss';

function VehicleList(props) {
  return (
    <div className="defaultTable" onScroll={(e) => this.scrollHandler(e)}>
      <Table responsive condensed hover>
        <thead className="translate-me">
          <tr>
            <th width="15%">
              Unit ID
            </th>
            <th width="20%">
              Driver(s)
            </th>
            <th width="35%" />
            <th width="15%">
              Trailer
            </th>
            <th width="15%">
              Location
            </th>
          </tr>
        </thead>
        <tbody>
          { props.vehicles && props.vehicles.length > 0 && props.vehicles.map((vehicle) =>
            <tr className={styles.listRow} key={vehicle.id} onClick={() => props.handleModalChoice(vehicle)}>
              <td>{vehicle.get('unitId')}</td>
              <td>
                {vehicle.get('drivers') && vehicle.get('drivers').length > 0 &&
                  <div>
                    { formatName(vehicle.get('drivers')[0].get('user_fullName')) }
                  </div>
                }
                {vehicle.get('drivers') && vehicle.get('drivers').length > 0 && vehicle.get('drivers')[1] &&
                  <div className={styles.coDriver}>
                    { formatName(vehicle.get('drivers')[1].get('user_fullName')) }
                  </div>
                }
              </td>
              <td onClick={(e) => stopPropagation(e)}>
                <ChooseDriverButton chooseDriversHandler={(drivers) => props.chooseDriversHandler(drivers, vehicle)} />
              </td>
              <td>{vehicle.get('trailer') && vehicle.get('trailer').get('unitId')}</td>
              <td>{vehicle.get('vendor') && vehicle.get('vendor').get('name')}</td>
            </tr>
          )}
          { !props.loaded &&
            <tr>
              <td colSpan="5" className="loadingIconPadding"><LoadingIcon /></td>
            </tr>
          }
        </tbody>
      </Table>
    </div>
  );
}

VehicleList.propTypes = {
  vehicles: PropTypes.array.isRequired,
  handleModalChoice: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  loaded: PropTypes.bool.isRequired,
  chooseDriversHandler: PropTypes.func,
};


export default VehicleList;
