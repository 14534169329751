import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from "../../../sbHistory";
import moment from 'moment-timezone';

// Actions
import { registerAdmin } from 'actions/User';

// api
import * as Helpers from 'api/Helpers';
import * as Setters from 'api/Setters';
import * as Analytics from 'api/Analytics';

// Components
import OwnerOperatorRegisterScreen from '../view/OwnerOperatorRegisterScreen';
import OwnerOperatorDownloadScreen from '../view/OwnerOperatorDownloadScreen';

// CSS
import styles from './OwnerOperatorRegister.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

const generateDriverDetails = (
  companyId,
  dispatcherId,
  prefix,
  firstName,
  lastName,
  phoneNumber,
  dotNumber
) => ({
  belongsToCompanyId: companyId,
  dispatcherId: dispatcherId,
  prefix: addUserInfo.prefix,
  username: (prefix + firstName[0] + lastName[0] + phoneNumber.slice(-4)).toLowerCase(),
  firstName: firstName,
  lastName: lastName,
  phoneNumber: phoneNumber,
  driversLicense: firstName + lastName + dotNumber,
  driversLicenseJurisdiction: 'BC',
  driversLicenseCountry: 'CA',
  hourCycleCanadaInt: 1,
  hourCycleUSAInt: 1,
  licenseExpiryDate: moment().add(6, 'months').toDate(),
  timezoneOffsetFromUTC: 'America/Vancouver',
  exempt: false,
  exemptionReason: '',
  yardMoves: true,
  personalUseCMV: true,
  dotNumber: dotNumber,
  isOwnAuthority: true,
  registrationSource: 1,
});

class OwnerOperatorRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conversionTracking: {
        clientId: props.clientId,
        utm_source: props.utm_source,
        referrer_url: props.referrer_url,
      },
      // view: 'getUserDetails',
      view: 'download',
      registerProperties: {
        companyName: '',
        dotNumber: '',
        mcNumber: '',
        nscNumber: '',
        firstName: '',
        lastName: '',
        emailAddress: props.email || '',
        password: '',
        phoneNumber: '',
      },
      interestProperties: {
        fleetSize: null,
        interests: [
          { name: 'Hours of Service', id: 'hos', value: false },
          { name: 'Driver Analytics', id: 'driverBehaviour', value: false },
          { name: 'GPS/Geofencing', id: 'gps', value: false },
          { name: 'IFTA Automation', id: 'ifta', value: false },
          { name: 'Asset Tracking', id: 'assetTrucking', value: false },
          { name: 'Dashcam & Video', id: 'dashcamVideo', value: false },
          { name: 'Dispatch & Invoicing', id: 'dispatch', value: false },
          { name: 'Weigh Station Bypass', id: 'wsb', value: false },
        ],
      },
      agreeTerms: false,
      registerError: '',
    };
    this.getValidationState = this.getValidationState.bind(this);
    this.handleRegisterPropertiesChange = this.handleRegisterPropertiesChange.bind(this);
    this.handleInterestPropertiesChange = this.handleInterestPropertiesChange.bind(this);
    this.register = this.register.bind(this);
  }

  componentDidMount() {
    document.title = 'Register - Switchboard';
  }

  getValidationState(property) {
    if (this.state.buttonClicked) {
      if (property === 'companyName' || property === 'firstName' || property === 'lastName') {
        if (this.state.registerProperties[property].length === 0) {
          return 'error';
        }
      } else if (property === 'emailAddress') {
        if (Helpers.isInvalidEmailFormat(this.state.registerProperties[property]) || (this.state.registerError && this.state.registerError.code === 202)) {
          return 'error';
        }
      } else if (property === 'password') {
        if (this.state.registerProperties[property].length < 8) {
          return 'error';
        }
      }
      return 'success';
    }
    return undefined;
  }

  handleRegisterPropertiesChange(property, value) {
    const newState = { ...this.state };
    newState.registerError = undefined;
    newState.registerProperties[property] = value;
    this.setState(newState);
  }

  handleInterestPropertiesChange(property, value) {
    const newState = { ...this.state };
    newState.interestProperties[index].value = value;
    this.setState(newState);
  }

  register() {
    this.state.buttonClicked = true;
    this.setState(this.state);

    // DEBUG
    this.setState({ ...this.state, buttonClicked: true, view: 'download', buttonClicked: false });

    if (
      this.getValidationState('companyName') === 'success' &&
      this.getValidationState('firstName') === 'success' &&
      this.getValidationState('lastName') === 'success' &&
      this.getValidationState('emailAddress') === 'success' &&
      this.getValidationState('password') === 'success' &&
      this.getValidationState('promoCode') === 'success'
    ) {
      Analytics.identify('anonymous', {
        companyName,
        email: emailAddress,
      });

      const { companyName, firstName, lastName, emailAddress, password, dotNumber, mcNumber, nscNumber, phoneNumber } = this.state.registerProperties;

      // registerAdmin({
      //   companyName,
      //   dotNumber,
      //   mcNumber,
      //   nscNumber,
      //   firstName,
      //   lastName,
      //   emailAddress: emailAddress.toLowerCase(),
      //   phoneNumber,
      //   password,
      // }).then(
      //   (user) => {
      //     // Analytics
      //     if (user && user.get('belongsToCompany')) {
      //       Analytics.identify(user.get('belongsToCompany').id, {
      //         companyName,
      //         email: emailAddress,
      //       });
      //     }


      //     Setters.addDriver(userInfo, true).then(driverObject => {
      //       this.setState({ ...this.state, view: 'download', buttonClicked: false });
      //     }, error => {
      //       store.dispatch(addDriverError(error));
      //       reject(error);
      //     });
      //   },
      //   (error) => {
      //     console.log(error);
      //     this.setState({ ...this.state, registerError: error });
      //   }
      // );
    }
  }

  render() {
    return (
      <div className={styles.register}>
        {this.state.view === 'getUserDetails' &&
          <OwnerOperatorRegisterScreen
            {...this.props}
            register={this.register}
            handleChange={this.handleRegisterPropertiesChange}
            getValidationState={this.getValidationState}
            registerError={this.state.registerError}
            agreeTerms={this.state.agreeTerms}
            registerProperties={this.state.registerProperties}
            handleAgreeTerms={() => this.setState({ ...this.state, agreeTerms: !this.state.agreeTerms })}
          />
        }
        {this.state.view === 'download' &&
          <OwnerOperatorDownloadScreen
            {...this.props}
          />
        }
      </div>
    );
  }
}

OwnerOperatorRegister.propTypes = {
  User: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { User } = state;
  return {
    User,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(OwnerOperatorRegister);
