import React, { useState, useEffect } from 'react';

// CSAPI Enums
import { ServiceProvider } from 'sb-csapi/dist/enums/Dispatch/ServiceProvider';

// sbCore Components
import Dropdown from 'sbCore/Dropdown/Dropdown';

// Styles
import './style.scss';

/**
 * @description A dropdown containing a list of selectable service provider types
 * @param {Number} serviceProviderType - Number passed to pre-set the service provider selected
 * @param {Function} onServiceProviderTypeChange - Function that updates the service provider type of the parent's state
 */
function ServiceProviderTypeDropdown({ ...props }) {

  const serviceProvidersArray = Object.keys(ServiceProvider).map(key => ServiceProvider[key]);

  // ** useStates ** //
  const [selectedServiceProviderType, setSelectedServiceProviderType] = useState(undefined);

  // ** useEffects ** //
  useEffect(() => {
    if (!props.serviceProviderType && props.serviceProviderType !== 0 && !serviceProvidersArray) return;

    const serviceProviderType = serviceProvidersArray.find((serviceProvider) => serviceProvider.type === props.serviceProviderType);
    setSelectedServiceProviderType(serviceProviderType);
  }, [props.serviceProviderType, serviceProvidersArray]);

  // ** Callback Functions ** //

  // sets the selected service provider type state here and in the the parent component
  function onServiceProviderTypeChange(e) {
    setSelectedServiceProviderType(e.value);

    if (props.onServiceProviderTypeChange) {
      // send the chosen type int back up to parent
      props.onServiceProviderTypeChange(e.value ? e.value.type : e.value);
    }
  }

  return (
    <div className="service-provider-type-dropdown">
      <Dropdown
        className="dropdown"
        value={selectedServiceProviderType}
        options={serviceProvidersArray}
        onChange={(e) => onServiceProviderTypeChange(e)}
        optionLabel="description"
        placeholder="Select Service Provider Type"
        warning={!selectedServiceProviderType}
        success={selectedServiceProviderType}
      />
    </div>
  );
}

export default ServiceProviderTypeDropdown;
