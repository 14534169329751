import { addRecord, copyQuery, count, createQuery, createQueryOr, find, getAttribute, getCurrentUser, getObjectById, includePointers, setQueryRestriction, sortQuery, updateRecord } from 'api/Parse';
// http://app.localhost:3000/dispatch/job__new/xpulUf0atL

import Sort from 'sbObjects/Sort';

import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { AttributeTypes, StatusTypes } from 'enums/DispatchJob';
import { equal } from 'assert';

/** @module Company */

/**
 * @memberof module:Company
 * 
 * @param {String} prefix 
 * @param {Number} cutoff 
 */
function getAbbreviatedPrefix(prefix, cutoff) {
    if (prefix.length < cutoff) {
        return prefix.substring(0, prefix.length);
    }
    return prefix.substring(0, cutoff);
}

function getPrefix() {
    const user = getCurrentUser();
    if (!user) { throw new Error('User not found'); }
    const company = getAttribute(user, 'belongsToCompany');
    if (!company) { throw new Error('No company found for user.'); }
    return getAttribute(company, 'prefix');
}

export {
    getAbbreviatedPrefix,
    getPrefix,
}

