import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from 'rc-checkbox';

// CSS
import 'rc-checkbox/assets/index.css';
import './style.scss';

function SBCheckbox(props) {
  const className = props.className ? props.className : '';

  return (
    <div className={className}>
      <Checkbox
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </div>
  );
}

SBCheckbox.defaultProps = {
  checked: false,
  disabled: false,
};
SBCheckbox.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SBCheckbox;
