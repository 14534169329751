import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Nav, NavItem } from 'react-bootstrap';

// Actions
import { getDriversForState, deleteDriversForState } from 'actions/Driver';

// API
import { getDriverTeams } from 'api/Getters';
import { scrolledToBottom } from 'api/Helpers';

// Components
import AddDriver from 'components/AddDriver/container/AddDriver';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

import SelectDriver from '../view/SelectDriver';
import SelectTeam from '../view/SelectTeam';

// CSS
import styles from './ChooseDriverModal.module.scss';

class ChooseDriverModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDrivers: {},
      teams: [],
      activeTab: 'mainDriverTab',
      filter: [
        { name: 'enabled', queryType: 'equalTo', value: true },
      ],
      sort: {
        user_fullName: 'ascending',
      },
      loadedAllDrivers: false,
      loadedTeams: true,
    };
    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.addToSelectedDrivers = this.addToSelectedDrivers.bind(this);
    this.addTeamToSelectedDrivers = this.addTeamToSelectedDrivers.bind(this);
    this.unassignCodriver = this.unassignCodriver.bind(this);
    this.confirmDrivers = this.confirmDrivers.bind(this);
    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.state.filterFormFields = [
      { attrName: 'user_fullName', queryType: 'searchString', fullName: 'Driver Name', placeholder: '.....', type: 'text' },
    ];
  }

  componentDidMount() {
    this.driversTableHeight = document.documentElement.clientHeight * 0.32; // 32vh defined by CSS for shortTable
    this.driversTableRowAmount = Math.round(this.driversTableHeight / 43) + 5; // amount of el's to display
    this.pageCount = 0; // result fetch multiplier

    getDriversForState(this.pageCount, this.driversTableRowAmount, this.state.filter, this.state.sort);
  }

  componentWillReceiveProps(nextProps) {
    const { Driver } = nextProps;

    // if Driver state not fetched yet and there are no drivers to display
    if (!Driver.fetched && Driver.drivers.length === 0) {
      return;
    }

    // if driver state is not fetched yet and there are drivers to display
    if (!Driver.fetched) {
      return;
    }

    const loadedAllDrivers = (Driver.drivers.length === this.props.Driver.drivers.length);
    this.setState({ ...this.state, loadedAllDrivers });
  }

  componentWillUnmount() {
    deleteDriversForState();
  }

  handleTabSelect(eventKey) {
    this.setState({ ...this.state, activeTab: eventKey, loadedTeams: true });
  }

  filter(filterObject) {
    if (filterObject[0].name === 'user_fullName' && this.state.activeTab === 'teamsTab') {
      // if we're looking for teams...
      this.setState({ ...this.state, loadedTeams: false }, () => {
        getDriverTeams(filterObject[0].value).then(teams => {
          this.setState({ ...this.state, teams, loadedTeams: true });
        });
      });
    } else {
      // otherwise we're just filtering drivers
      this.pageCount = 0;
      const newState = { ...this.state, filter: this.state.filter.concat(filterObject) };
      this.setState(newState);
      deleteDriversForState().then(() => {
        getDriversForState(this.pageCount, this.driversTableRowAmount, this.state.filter.concat(filterObject), this.state.sort);
      });
    }
  }

  clearFilter() {
    this.pageCount = 0;
    this.setState({ ...this.state, filter: undefined });
    deleteDriversForState().then(() => {
      getDriversForState(this.pageCount, this.driversTableRowAmount, undefined, this.state.sort);
    });
  }

  scrollHandler(e) {
    if (scrolledToBottom(e, 10, this.lastScrollPos)) {
      this.pageCount++;
      getDriversForState(this.pageCount, this.driversTableRowAmount, this.state.filter, this.state.sort);
      this.lastScrollPos = e.target.scrollTop;
    }
  }

  addToSelectedDrivers(selectedDriver, drivingRole) {
    const newState = { ...this.state };
    newState.selectedDrivers[drivingRole] = selectedDriver;
    this.setState(newState);
  }

  addTeamToSelectedDrivers(teamObject) {
    const newState = { ...this.state };
    newState.selectedDrivers = { mainDriver: teamObject.get('driverA'), coDriver: teamObject.get('driverB') };
    this.setState(newState);
  }

  unassignCodriver() {
    const newState = { ...this.state };
    newState.selectedDrivers = { mainDriver: newState.selectedDrivers.mainDriver };
    this.setState(newState);
  }

  handleClose() {
    this.setState({ ...this.state, selectedDrivers: {}, filter: undefined, loadedAllDrivers: false }, () => {
      this.props.handleClose();
    });
  }

  confirmDrivers() {
    if (this.props.confirmDriversHandler) {
      this.props.confirmDriversHandler(this.state.selectedDrivers);
    }
  }


  render() {
    const { Driver } = this.props;
    const { activeTab, loadedAllDrivers, selectedDrivers, loadedTeams } = this.state;

    let showLoadingIcon;
    if (activeTab === 'teamsTab') {
      if (loadedTeams) {
        showLoadingIcon = false;
      } else {
        showLoadingIcon = true;
      }
    } else {
      if (loadedAllDrivers) {
        showLoadingIcon = false;
      } else if (!loadedAllDrivers) {
        if (!Driver.fetched) {
          showLoadingIcon = true;
        } else {
          showLoadingIcon = false;
        }
      }
    }
    return (
      <Modal className={styles.chooseDriverModal} show={this.props.show} onHide={this.props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Driver(s)</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={`modalPrefixTip ${styles.modalPrefixTip}`}>
            <ul>
              <li>Select one Driver, a Driver and Co-Driver, or a team of Drivers</li>
            </ul>
          </div>

          <span>
            <FilterForm
              className="floatLeft"
              handleFilter={this.filter}
              clearFilter={this.clearFilter}
              fields={this.state.filterFormFields}
            />
            <AddDriver className={`floatRight buttonSpacedRight verticalAlignMiddle ${styles.addDriverTopPadding}`} />
            <div className="clearBoth" />
          </span>

          <div className={styles.issuesNavbar}>
            <Nav variant="tabs" activeKey={this.state.activeTab} onSelect={this.handleTabSelect}>
              <NavItem eventKey="mainDriverTab">Main Driver</NavItem>
              <NavItem eventKey="coDriverTab" disabled={!selectedDrivers.mainDriver}>Co-Driver</NavItem>
              <NavItem eventKey="teamsTab">Team</NavItem>
            </Nav>
          </div>

          <div>
            {this.state.activeTab === 'mainDriverTab' &&
              <SelectDriver
                drivers={Driver.drivers}
                deleteDriverHandler={this.deleteDriverHandler}
                scrollHandler={this.scrollHandler}
                selectDriverHandler={this.addToSelectedDrivers}
                drivingRole={'mainDriver'}
              />
            }
            {this.state.activeTab === 'coDriverTab' &&
              <SelectDriver
                drivers={Driver.drivers}
                deleteDriverHandler={this.deleteDriverHandler}
                scrollHandler={this.scrollHandler}
                selectDriverHandler={this.addToSelectedDrivers}
                drivingRole={'coDriver'}
              />
            }
            {this.state.activeTab === 'teamsTab' &&
              <SelectTeam
                teams={this.state.teams}
                loadedTeams={this.state.loadedTeams}
                selectTeamHandler={this.addTeamToSelectedDrivers}
              />
            }
          </div>
        </Modal.Body>

        <Modal.Footer>
          {Object.keys(selectedDrivers).length > 0 && !showLoadingIcon &&
            <div className={`inline ${styles.selectedDriverNames}`}>
              Drivers Selected:&nbsp;
              {selectedDrivers.mainDriver &&
                <div>
                  {`${selectedDrivers.mainDriver.get('user').get('firstName')[0]}.${selectedDrivers.mainDriver.get('user').get('lastName')}`}
                </div>
              }
              {selectedDrivers.coDriver &&
                <div>
                  {`${selectedDrivers.coDriver.get('user').get('firstName')[0]}.${selectedDrivers.coDriver.get('user').get('lastName')}`}
                </div>
              }
            </div>
          }

          {selectedDrivers.coDriver && !showLoadingIcon &&
            <button className="buttonDefault buttonSpacedRight" onClick={this.unassignCodriver}>Unassign Co-Driver</button>
          }

          {!showLoadingIcon &&
            <span>
              <button className="buttonDefault buttonSpacedRight" onClick={this.props.handleClose}>Cancel</button>
              <button className="buttonDefault" onClick={this.confirmDrivers}>Confirm</button>
            </span>
          }

          {showLoadingIcon &&
            <LoadingIcon />
          }
        </Modal.Footer>
      </Modal>
    );
  }
}

ChooseDriverModal.propTypes = {
  confirmDriversHandler: PropTypes.func.isRequired,
  company: PropTypes.object,
  Driver: PropTypes.object,
  error: PropTypes.object,
  handleClose: PropTypes.func,
  show: PropTypes.bool,
  subscription: PropTypes.object,
};

export default ChooseDriverModal;
