

import React from 'react';
import PropTypes from 'prop-types';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

import './styles.scss';

class SBCardEmptyContent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { containerClassName, paddingXLevel, paddingYLevel, isContentLoader, children } = this.props;
    let _containerClassName = `sb-card-empty-content m-0 mt-1 px-${paddingXLevel} py-${paddingYLevel} align-middle text-center`;
    if (containerClassName) _containerClassName += ` ${containerClassName}`;

    let childrenClassName = 'children-container translate-me';
    if (isContentLoader) childrenClassName += ' content-loader';

    return (
      <MDBContainer fluid className={_containerClassName}>
        <div className={childrenClassName}>
          { isContentLoader &&
            <div className="spinner-border spinner-border-sm mr-2" role="status">
              <span className="sr-only"></span>
            </div>
          }
          { children }
        </div>
      </MDBContainer>
    );
  }
}


SBCardEmptyContent.defaultProps = {
  paddingXLevel: 4, // amount of padding x
  paddingYLevel: 4, // amount of padding y
};
SBCardEmptyContent.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  paddingXLevel: PropTypes.number,
  paddingYLevel: PropTypes.number,
  isContentLoader: PropTypes.bool,
  children: PropTypes.node,
}

export default SBCardEmptyContent;
