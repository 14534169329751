import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Table } from 'react-bootstrap';
import StatusIcon from 'components/StatusIcon/view/StatusIcon';

// CSS
import styles from '../view/SelectDriver.module.scss';

class SelectTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      teamRows: [],
    };
    this.refreshState = this.refreshState.bind(this);
    this.selectTeam = this.selectTeam.bind(this);
  }

  componentDidMount() {
    this.refreshState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.refreshState(nextProps);
  }

  refreshState(nextProps) {
    const nextState = { ...this.state };
    if (!nextProps.loadedTeams) {
      return;
    }

    if (nextProps.loadedTeams && nextProps.teams.length === 0) {
      nextState.teamRows = (
        <tr className={styles.listRow}>
          <td className="emptyRowMessage" colSpan="2">
            No Teams under that Driver Name. To create a Team, simply assign a Driver - Co-Driver pair
            and it'll show up next time!
          </td>
        </tr>
      );
    }

    if (nextProps.loadedTeams && nextProps.teams.length > 0) {
      nextState.teamRows = nextProps.teams.map((teamDrivers) => {
        const driverA = teamDrivers.get('driverA');
        const driverB = teamDrivers.get('driverB');
        return (
          <tr className={styles.listRow} key={teamDrivers.id} onClick={() => this.props.selectTeamHandler(teamDrivers)}>
            <td>
              {driverA.get('driverStatus') && (driverA.get('driverStatus').get('referenceInt') === 2) ?
                <StatusIcon active />
                :
                <StatusIcon active={false} />
              }
              {`${driverA.get('user').get('firstName')} ${driverA.get('user').get('lastName')}`}
            </td>
            <td>
              {driverB.get('driverStatus') && (driverB.get('driverStatus').get('referenceInt') === 2) ?
                <StatusIcon active />
                :
                <StatusIcon active={false} />
              }
              {`${driverB.get('user').get('firstName')} ${driverB.get('user').get('lastName')}`}
            </td>
          </tr>
        );
      });
    }
    this.setState(nextState);
  }

  selectTeam(driverTeam) {
    this.props.selectTeamHandler(driverTeam);
  }

  render() {
    return (
      <div className={styles.selectDriver}>
        <div className="defaultTable shortTable">
          <Table responsive condensed hover>
            <thead>
              <tr>
                <th>
                  Name
                </th>
                <th className={styles.chooseDriverLegend}>
                  <div className="inlineBlock">
                    <span className={styles.activeLegend}>&#8226;</span> In Job
                  </div>
                  <div className="inlineBlock">
                    <span className={styles.inactiveLegend}>&#8226;</span> Available
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              { this.state.teamRows }
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

SelectTeam.propTypes = {
  teams: PropTypes.array,
  loadedTeams: PropTypes.bool,
  selectTeamHandler: PropTypes.func,
};

export default SelectTeam;
