import { findIndexOfObjArr, removeDuplicatesByObjectId } from 'api/Helpers';
import * as ActionConstants from '../actions/ActionConstants';

const initialState = {
  vendors: [],
  fetched: false, // Whether or not drivers are loaded
};

function disableVendors(stateVendors, vendorsToBeDeleted) {
  const newStateVendors = [].concat(stateVendors);
  if (newStateVendors) {
    const vendorsToBeDeletedLen = vendorsToBeDeleted.length;
    for (let i = 0; i < vendorsToBeDeletedLen; i++) {
      const index = findIndexOfObjArr(newStateVendors, 'id', vendorsToBeDeleted[i].id);
      newStateVendors.splice(index, 1);
    }
  }
  return newStateVendors;
}

export function Vendor(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_VENDORS_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.FETCH_VENDORS_INPROGRESS,
        fetched: false,
        error: undefined,
      };
    }
    case ActionConstants.FETCH_VENDORS_SUCCESS: {
      return {
        status: ActionConstants.FETCH_VENDORS_SUCCESS,
        vendors: removeDuplicatesByObjectId(state.vendors.concat(action.vendors)),
        error: undefined,
        fetched: true,
      };
    }
    case ActionConstants.FETCH_VENDORS_ERROR: {
      return {
        ...state,
        status: ActionConstants.FETCH_VENDORS_ERROR,
        error: action.error,
        fetched: false,
      };
    }
    case ActionConstants.DELETE_VENDORS: {
      return {
        ...initialState,
        status: action.type,
      };
    }
    case ActionConstants.ADD_VENDOR_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.ADD_VENDOR_INPROGRESS,
        error: undefined,
      };
    }
    case ActionConstants.ADD_VENDOR_SUCCESS: {
      // Succesfully added to db
      const updatedVendors = [].concat(state.vendors);
      updatedVendors.unshift(action.vendor);
      return {
        ...state,
        status: ActionConstants.ADD_VENDOR_SUCCESS,
        vendors: updatedVendors,
        error: undefined,
      };
    }
    case ActionConstants.ADD_VENDOR_ERROR: {
      return {
        ...state,
        status: ActionConstants.ADD_VENDOR_ERROR,
        error: action.error,
      };
    }
    case ActionConstants.UPDATE_VENDOR_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.UPDATE_VENDOR_INPROGRESS,
        updateReference: action.vendor,
        error: undefined,
      };
    }
    case ActionConstants.UPDATE_VENDOR_SUCCESS: {
      return {
        ...state,
        status: ActionConstants.UPDATE_VENDOR_SUCCESS,
        error: undefined,
      };
    }
    case ActionConstants.UPDATE_VENDOR_ERROR: {
      return {
        ...state,
        status: ActionConstants.UPDATE_VENDOR_ERROR,
        error: action.error,
      };
    }
    case ActionConstants.DISABLE_VENDORS_SUCCESS: {
      return {
        ...state,
        status: ActionConstants.DISABLE_VENDORS_SUCCESS,
        vendors: disableVendors(state.vendors, action.vendors),
        error: undefined,
      };
    }
    default:
      return state;
  }
}
