import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// API
import * as IFTA from 'api/IFTA';
import { StateProvinces } from 'api/Lists/StateProvinces';
import * as PDF from 'api/PDFer';
import * as Helpers from 'api/Helpers';
import { getPDispatcherPropertyFromState } from 'api/Getters';

// Components
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBModal, MDBModalBody } from 'mdbreact';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import VehicleSummaryView from '../view/VehicleSummary';

const getFilteredTotalVehicleKmDiff = (iftaRouteObj) => {
  if (Math.abs(iftaRouteObj.get('savedVehicleKm') - iftaRouteObj.get('totalVehicleKmDiff')) > 1000) {
    return iftaRouteObj.get('savedVehicleKm');
  }
  return iftaRouteObj.get('totalVehicleKmDiff');
}

class VehicleSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      printInProgress: false,
      downloadCsvInProgress: false,
      vehicleSummaryFilter: {
        key: 1,
        value: 'hideZeroMileages',
        label: 'Hide Empty States/Provinces',
      },
      distanceUnitFilter: getPDispatcherPropertyFromState('distanceUnit') === 'km' ?
        {
          key: 0,
          value: 'km',
          label: 'km',
        }
        :
        {
          key: 1,
          value: 'mi',
          label: 'mi',
        },
      distanceUnitFilterItems: [
        {
          key: 0,
          value: 'km',
          label: 'km',
        },
        {
          key: 1,
          value: 'mi',
          label: 'mi',
        },
      ],
      selectableVehicleSummaryFilterItems: [
        {
          key: 0,
          value: 'showAllStateProvinces',
          label: 'Show All States/Provinces',
        },
        {
          key: 1,
          value: 'hideZeroMileages',
          label: 'Hide Empty States/Provinces',
        },
      ],
      odometerDiff: null,
      showDataButtons: false,
    };

    this.printReport = this.printReport.bind(this);
    this.downloadCsv = this.downloadCsv.bind(this);
  }


  componentDidMount() {
    IFTA.getOdometerReadingsForDateRange(this.props.unitId, this.props.dateStart, this.props.dateEnd, 'km').then(odometerReadings => {
      this.setState({ ...this.state, odometerReadings: (odometerReadings || {}), loading: false });
    });
  }

  printReport = (type) => {
    let reportHTML;
    let reportName;
    this.setState({ ...this.state, printInProgress: true });
    reportHTML = document.querySelector('.vehicleSummary-printable').outerHTML;
    reportName = `${this.props.unitId} - Vehicle Mileage Summary`;
    PDF.generateTempPDFFromHTML(document.querySelector('.vehicleSummary-printable'), reportName, undefined).then(
      tempFile => {
        const reportURL = tempFile.get('file')._url;
        Helpers.openDocumentLink(reportURL);
        this.setState({ ...this.state, printInProgress: false });
      }
    );
  }

  downloadCsv = async () => {
    await this.setState({ ...this.state, downloadCsvInProgress: true });
    let csvString = `State/Province,Fuel (Gallons),Calculated Mileage (${this.state.distanceUnitFilter.value})\n`;
    const stateProvinceCodes = StateProvinces.map((stateProvince) => stateProvince.code).filter((stateProvinceCode) => stateProvinceCode);

    let odometerDiff = 0;
    if (this.state.odometerReadings && this.state.odometerReadings.odometerEnd && this.state.odometerReadings.odometerStart) {
      odometerDiff = this.state.odometerReadings.odometerEnd - this.state.odometerReadings.odometerStart;
    }


    const stateProvinceDataArr = [];
    const iftaArrMiles = {};
    let totalOdometerKm = 0;
    let totalGpsKm = 0;
    let totalSavedKm = 0;
    let totalFuelPurchases = {};
    let totalFuelTotalPaidCAD = 0;
    let totalFuelTotalPaidUSD = 0;

    for (let i = 0; i < this.props.iftaRouteArr.length; i++) {
      const iftaRoute = this.props.iftaRouteArr[i];
      const stateProvince = iftaRoute.get('stateProvince').toUpperCase();
      const savedVehicleKm = (iftaRoute.get('savedVehicleKm') > 0 ? iftaRoute.get('savedVehicleKm') : 0);
      const totalVehicleKmDiff = (iftaRoute.get('totalVehicleKmDiff') > 0 ? iftaRoute.get('totalVehicleKmDiff') : 0);
      const distanceKm = (iftaRoute.get('distanceKm') > 0 ? iftaRoute.get('distanceKm') : 0);
      totalOdometerKm += totalVehicleKmDiff;
      totalGpsKm += distanceKm;
      totalSavedKm += savedVehicleKm;

      if (iftaArrMiles[stateProvince]) {
        iftaArrMiles[stateProvince].odometerKm += totalVehicleKmDiff;
        iftaArrMiles[stateProvince].gpsKm += distanceKm;
        iftaArrMiles[stateProvince].savedKm += savedVehicleKm;
      } else {
        iftaArrMiles[stateProvince] = {};
        iftaArrMiles[stateProvince].odometerKm = totalVehicleKmDiff || 0;
        iftaArrMiles[stateProvince].gpsKm = distanceKm || 0;
        iftaArrMiles[stateProvince].savedKm = savedVehicleKm || 0;
        iftaArrMiles[stateProvince].fuelPurchaseSubtotals = {};
        iftaArrMiles[stateProvince].fuelTotalPaidCAD = 0;
        iftaArrMiles[stateProvince].fuelTotalPaidUSD = 0;
      }

      // Fuel Purchases
      if (iftaRoute.get('fuelPurchases')) {
        for (let j = 0; j < iftaRoute.get('fuelPurchases').length; j++) {
          const fuelPurchase = iftaRoute.get('fuelPurchases')[j];
          const fuelType = fuelPurchase.get('fuelType') && fuelPurchase.get('fuelType').toUpperCase();
          const totalPaid = fuelPurchase.get('totalPaid');
          const currency = fuelPurchase.get('currency');
          const volumePumpedGallons = fuelPurchase.get('volumeUnits') === 'l' ? Helpers.convertFuelUnit(fuelPurchase.get('volumePumped'), 'l', 'gal') : fuelPurchase.get('volumePumped')

          if (iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType]) {
            iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType] += volumePumpedGallons;
          } else {
            iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType] = volumePumpedGallons;
          }

          if (totalFuelPurchases[fuelType]) {
            totalFuelPurchases[fuelType] += volumePumpedGallons;
          } else {
            totalFuelPurchases[fuelType] = volumePumpedGallons;
          }

          if (currency === 'cad') {
            iftaArrMiles[stateProvince].fuelTotalPaidCAD += totalPaid;
            totalFuelTotalPaidCAD += totalPaid;
          } else if (currency === 'usd') {
            iftaArrMiles[stateProvince].fuelTotalPaidUSD += totalPaid;
            totalFuelTotalPaidUSD += totalPaid;
          }
        }
      }
    }

    // This is for deciding whether to spread the calculated Vehicle Km
    // Should Use SpreadKm when the odometerDifference is not negative or way too large!
    // Otherwise will just use calculatedOdometerKm
    let shouldUseSpreadKm = false;
    if (
      odometerDiff > 0 &&
      (odometerDiff < (moment(this.props.dateEnd).diff(moment(this.props.dateStart), 'hours') * 200)) &&
      (Math.abs(totalSavedKm - odometerDiff) < (5000 * (moment(this.props.dateEnd).diff(moment(this.props.dateStart), 'months') + 1)))
    ) {
      shouldUseSpreadKm = true;
    }

    for (let i = 0; i < stateProvinceCodes.length; i++) {
      const stateProvince = stateProvinceCodes[i];
      const subtotalSavedKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].savedKm ? Math.round(iftaArrMiles[stateProvince].savedKm * 100) / 100 : 0;
      const subtotalOdometerKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].odometerKm ? Math.round(iftaArrMiles[stateProvince].odometerKm * 100) / 100 : 0;
      const subtotalGpsKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].gpsKm ? Math.round(iftaArrMiles[stateProvince].gpsKm * 100) / 100 : 0;
      const fuelPurchaseSubtotals = iftaArrMiles[stateProvince] ? iftaArrMiles[stateProvince].fuelPurchaseSubtotals : {};

      const subtotalSpreadKm = Math.round(totalSavedKm) !== 0 ? subtotalSavedKm && totalSavedKm && odometerDiff && Math.round((subtotalSavedKm / Math.round(totalSavedKm)) * odometerDiff * 100) / 100 : subtotalSavedKm;
      const stateProvinceDataObj = {
        code: stateProvince,
        subtotalSavedKm,
        subtotalOdometerKm,
        subtotalGpsKm,
        fuelPurchaseSubtotals,
        subtotalSpreadKm: shouldUseSpreadKm ? subtotalSpreadKm : subtotalSavedKm,
        odometerDiff: odometerDiff,
      };
      stateProvinceDataArr.push(stateProvinceDataObj);
    }

    stateProvinceDataArr.filter((stateProvinceData) => stateProvinceData.subtotalSavedKm)
      .map(stateProvinceData => {
        let fuelPurchaseSubtotalsString = '';

        for (let fuelType in stateProvinceData.fuelPurchaseSubtotals) {
          fuelPurchaseSubtotalsString += `${fuelType}: ${stateProvinceData.fuelPurchaseSubtotals[fuelType].toFixed(2)} `;
        }

        csvString += `"${stateProvinceData.code}",`;
        // getting rid of gps mileage
        // csvString += `"${(this.state.distanceUnitFilter.value === 'km' ? (Math.round(stateProvinceData.subtotalGpsKm * 100) / 100) : (Math.round(Helpers.convertDistance(stateProvinceData.subtotalGpsKm, 'km', 'mi', true) * 100) / 100).toFixed(0))}",`;
        csvString += `"${fuelPurchaseSubtotalsString}",`;
        csvString += `"${shouldUseSpreadKm ?
          (this.state.distanceUnitFilter.value === 'km' ? (Math.round(stateProvinceData.subtotalSpreadKm * 100) / 100) : (Math.round(Helpers.convertDistance(stateProvinceData.subtotalSpreadKm, 'km', 'mi', true) * 100) / 100).toFixed(0)) :
          (this.state.distanceUnitFilter.value === 'km' ? (Math.round(stateProvinceData.savedVehicleKm * 100) / 100) : (Math.round(Helpers.convertDistance(stateProvinceData.savedVehicleKm, 'km', 'mi', true) * 100) / 100).toFixed(0))
          }"\n`;

      });

    const totalSpreadKm = Math.round(totalSavedKm) !== 0 ? totalSavedKm && odometerDiff && Math.round((totalSavedKm / Math.round(totalSavedKm)) * odometerDiff * 100) / 100 : totalSavedKm;
    let totalFuelPurchaseString = '';

    for (let fuelType in totalFuelPurchases) {
      totalFuelPurchaseString += `${fuelType}: ${totalFuelPurchases[fuelType].toFixed(2)} `;
    }

    csvString += `TOTAL,`;
    // getting rid of gps mileage
    // csvString += `"${(this.state.distanceUnitFilter.value === 'km' ? totalGpsKm : Helpers.convertDistance(totalGpsKm, 'km', 'mi', true).toFixed(0))}",`;
    csvString += `"${totalFuelPurchaseString}",`;
    csvString += `"${shouldUseSpreadKm ?
      (this.state.distanceUnitFilter.value === 'km' ? (Math.round(totalSpreadKm * 100) / 100) : (Math.round(Helpers.convertDistance(totalSpreadKm, 'km', 'mi', true) * 100) / 100).toFixed(0)) :
      (this.state.distanceUnitFilter.value === 'km' ? (Math.round(totalSavedKm * 100) / 100) : (Math.round(Helpers.convertDistance(totalSavedKm, 'km', 'mi', true) * 100) / 100).toFixed(0))
      }"\n`;


    Helpers.createCsvFile(`${this.props.unitId} Switchboard - IFTA Mileage Summary (${this.props.dateStart && moment(this.props.dateStart).format('MMMM YYYY')} to ${this.props.dateEnd && moment(this.props.dateEnd).format('MMMM YYYY')})`, csvString, true);
    this.setState({ ...this.state, downloadCsvInProgress: false });
  }

  render() {
    return (
      <div>
        <div style={{ margin: '1em' }}>
          <SBSelect
            containerClassName="ml-2 mr-2 d-inline-block"
            className="sb-jobs-list-select"
            defaultToggleText={this.state.vehicleSummaryFilter.label}
            items={this.state.selectableVehicleSummaryFilterItems}
            getSelectedItems={(selectedFilter) => this.setState({ ...this.state, vehicleSummaryFilter: selectedFilter[0] })}
            showFilter
          />
          <SBSelect
            containerClassName="ml-2 mr-2 d-inline-block"
            className="sb-jobs-list-select"
            defaultToggleText={this.state.distanceUnitFilter.label}
            items={this.state.distanceUnitFilterItems}
            getSelectedItems={(selectedFilter) => {
              this.setState({ ...this.state, distanceUnitFilter: selectedFilter[0] })
            }}
            showFilter
          />
          <MDBBtn
            size="sm"
            color="secondary"
            disabled={this.state.printInProgress}
            onClick={() => this.printReport()}
            style={{ marginRight: '.5em' }}
          >
            Print Report
          </MDBBtn>
          <MDBBtn
            size="sm"
            color="info"
            onClick={() => this.downloadCsv()}
            style={{ marginRight: '.5em', width: '14em' }}
          >
            Download CSV
          </MDBBtn>
        </div>
        <div className="vehicleSummary-printable">
          {(moment(this.props.dateEnd).isAfter(moment().subtract(11, 'days'))) &&
            <SBBlock
              title={`Switchboard processes IFTA vehicle mileages up to 10 days before today's date.`}
              header={`If you are generating reports for the previous month, make sure you generate them on the 11th of this month to get the most updated mileages.`}
              warning
            />
          }
          <VehicleSummaryView
            unitId={this.props.unitId}
            dateStart={this.props.dateStart}
            dateEnd={this.props.dateEnd}
            displayStartDate={this.props.displayStartDate}
            displayEndDate={this.props.displayEndDate}
            iftaRouteArr={this.props.iftaRouteArr}
            stateProvinceMileages={this.props.stateProvinceMileages}
            selectedFilter={this.state.vehicleSummaryFilter}
            distanceUnitFilter={this.state.distanceUnitFilter}
            odometerReadings={this.state.odometerReadings}
          />
        </div>
      </div>
    );
  }
};

export default VehicleSummary;
