import history from 'sbHistory';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Actions
import { updateCloudUserPreference } from 'actions/CloudUserPreference';

// Enums
import { EquipmentTypes } from 'enums/Equipment'; // need to move to sb-csapi
import { Action } from 'sb-csapi/dist/enums/Action';
import { QuerySortOrderTypes, QueryRestriction } from 'sb-csapi/dist/enums/Query';

// SBObjects
import { FilterObject as Filter } from 'sb-csapi/dist/sbObjects/Filter';
import { SortObject as Sort } from 'sb-csapi/dist/sbObjects/Sort';

// API
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { useGetVehiclesQuery } from 'api/Equipment/Vehicle';
import { useGetTrailersQuery } from 'api/Equipment/Trailer';

import { track } from 'sb-csapi/dist/api/Analytics/Product';
import { SubFeature, getEventString } from 'sb-csapi/dist/enums/Analytics/Product';

// Components
import Button from 'sbCore/Button/Button';
import TabMenu from 'sbCore/TabMenu/TabMenu';
import EquipmentGroupView from 'components/Map/Sidebar/EquipmentGroupView/EquipmentGroupView';
import AllEquipmentView from 'components/Map/Sidebar/AllEquipmentView/AllEquipmentView';
import EquipmentGroupAddEditModal from 'components/EquipmentGroup/EquipmentGroupAddEditModal/EquipmentGroupAddEditModal';

// Context
import StoreContext from 'contexts/StoreContext';

// Styles
import './style.scss';

// @todo - add enum for Geofence
const tabMenuItems = [
  { label: 'VEHICLES', value: 'VEHICLE', type: EquipmentTypes.VEHICLE },
  { label: 'TRAILERS', value: 'TRAILER', type: EquipmentTypes.TRAILER },
  { label: 'GEOFENCES', value: 'GEOFENCE', type: 2 },
];

/**
 * @description The sidebar content and logic for Maps module
 *
 * @param {String} activeEquipment - string containing the active equipment unitId
 * @param {boolean} addEditGeofence - bool to determine whether geofences are being added/edited
 * @param {Function} hoverSidebarObject - callback function for when a geofence item is hovered
 * @param {Function} onSelectEquipment - callback function for when a vehicle is selected on the sidebar
 * @param {Function} onHoverGeofence - callback function for when a geofence item is hovered
 * @param {Object} Geofence - Geofence object from the redux store
 * @param {Function} getSelectedEquipment - callback function when an equipment is selected
 * @param {Object} activeGeofence - currently active geofence
 * @param {Function} handleSetAddEditGeofence - callback function for when a geofence is set to add/edit mode
 */

/**
 * @todo Update Geofence fetching logic to match vehicles/trailers, filtering/sorting is fully decoupled from how we currently fetch the geofences
 */
function Sidebar({ ...props }) {
  const defaultMapSidebarTab = props.CloudUserPreference
    && props.CloudUserPreference.cloudUserPreference
    && getAttribute(props.CloudUserPreference.cloudUserPreference, 'defaultMapSidebarTab')
    && parseInt(getAttribute(props.CloudUserPreference.cloudUserPreference, 'defaultMapSidebarTab'));
  // Menu
  const [isGroupView, setIsGroupView] = useState(true);
  const [activeTabMenuItem, setActiveMenuItem] = useState(tabMenuItems[defaultMapSidebarTab || 0]);

  const [activeEquipment, setActiveEquipment] = useState(undefined);

  // Equipment Group Edit Modal Information
  const [selectedEquipmentGroupObjectId, setSelectedEquipmentGroupObjectId] = useState(undefined);
  const [isEquipmentGroupAddEditModalOpen, setIsEquipmentGroupAddEditModalOpen] = useState(false);

  // This is mimicking the behavior of the vehicle/trailer API - eventually will be replaced with the RTK Query implementation
  const [geofenceQueryResult, setGeofenceQueryResult] = useState({
    isLoading: !props.Geofence.fetched,
    isSuccess: props.Geofence.fetched && !props.Geofence.error,
    isError: !!props.Geofence.error,
    data: props.Geofence.geofences,
  });

  const vehicleQueryResult = useGetVehiclesQuery({
    limit: 400,
    filters: [
      Filter(QueryRestriction.EQUAL_TO, 'enabled', true),
      Filter(QueryRestriction.EXISTS, 'unitId'),
      Filter(QueryRestriction.EXISTS, 'vehicleLocation'), // brought this in because some companies have like >2k vehicles and need to show them? better to implement as better search function (query on search)
    ],
    sortBy: Sort(QuerySortOrderTypes.ASCENDING, 'unitId'),
    includedPointers: ['vehicleLocation', 'drivers.latestELDEvent'],
    includeChildCompanies: true,
  }, {
    pollingInterval: 30000,
  });

  const trailerQueryResult = useGetTrailersQuery({
    limit: 400,
    filters: [
      Filter(QueryRestriction.EQUAL_TO, 'enabled', true),
      Filter(QueryRestriction.EXISTS, 'unitId'),
      Filter(QueryRestriction.EXISTS, 'tc_devices'), // brought this in because some companies have like >2k trailers and need to show? better to implement as better search function (query on search)
    ],
    sortBy: Sort(QuerySortOrderTypes.DESCENDING, 'unitId'),
    includedPointers: ['vehicleLocation', 'tc_devices', 'tc_devices.tc_positions', 'tc_devices.assetLocation'],
    includeChildCompanies: true,
    queryAll: true,
  }, {
    pollingInterval: 30000,
  });

  // This is a useEffect that gets triggered whenever the active vehicle is deselected outside of the sidebar
  // e.g., if the user clicks on the currently active vehicle
  useEffect(() => {
    setActiveEquipment(props.activeEquipment);
  }, [props.activeEquipment]);

  // Handles the main action button on the sidebar (adding vehicle, trailer, geofence)
  function handleAddEquipment() {
    switch (activeTabMenuItem.type) {
      case EquipmentTypes.VEHICLE:
        history.push('equipment?view=vehicles');
        break;
      case EquipmentTypes.TRAILER:
        history.push('equipment?view=trailers');
        break;
      case 2:
        props.handleSetAddEditGeofence('add');
        break;
      default:
        break;
    }
  }

  function handleOnTabChange(e) {
    setActiveEquipment(undefined);
    setActiveMenuItem(e.value);
    updateCloudUserPreference(
      props.CloudUserPreference && props.CloudUserPreference.cloudUserPreference,
      'defaultMapSidebarTab',
      e?.value?.type?.toString(),
    );
    props.onSelectEquipment(undefined, undefined, false);
    props.setActiveEquipmentInformation(undefined);
  }

  // Handler for when a trailer/vehicle is selected
  function handleOnEquipmentSelect(equipmentInformation) {
    if (equipmentInformation.unitId === activeEquipment) {
      setActiveEquipment(undefined);
      props.onSelectEquipment(undefined, undefined, false);
      props.setActiveEquipmentInformation(undefined);
    } else {
      setActiveEquipment(equipmentInformation.unitId);
      props.onSelectEquipment(activeTabMenuItem.type, equipmentInformation.objectId, true);
      // Pass equipment information to active trailer/vehicle card
      props.setActiveEquipmentInformation(equipmentInformation);
    }
  }

  // Determines whether actions are disabled on a tab by tab basis (this includes filtering, the bottom action, and toggling views)
  function isActionDisabled() {
    const { isError: isErrorVehicles, isLoading: isLoadingVehicles } = vehicleQueryResult;
    const { isError: isErrorTrailers, isLoading: isLoadingTrailers } = trailerQueryResult;

    if (activeTabMenuItem.type === EquipmentTypes.VEHICLE) {
      if (isErrorVehicles || isLoadingVehicles) return true;
    } else if (activeTabMenuItem.type === EquipmentTypes.TRAILER) {
      if (isErrorTrailers || isLoadingTrailers) return true;
    }

    return false;
  }

  // Determines whether we are editing an equipment group
  function handleOnEquipmentGroupAddEdit(equipmentGroupObjectId) {
    setSelectedEquipmentGroupObjectId(equipmentGroupObjectId);
    setIsEquipmentGroupAddEditModalOpen(true);

    if (window.sbProdAnalIsAnon === false) {
      // Don't want to track anonymous users
      track(window.sbProdAnalEntityId, getEventString(SubFeature.EQUIPGROUP, 'Opened Add Equipment Group modal'));
    }
  }

  // Geofence - this mimics the RTK Query hook object
  useEffect(() => {
    setGeofenceQueryResult({
      isLoading: !props.Geofence.fetched,
      isSuccess: props.Geofence.fetched && !props.Geofence.error,
      isError: !!props.Geofence.error,
      data: props.Geofence.geofences,
    });
  }, [props.Geofence.error, props.Geofence.fetched, props.Geofence.geofences]);

  return (
    <>
      {isEquipmentGroupAddEditModalOpen && (
        <EquipmentGroupAddEditModal
          action={selectedEquipmentGroupObjectId ? Action.EDIT : Action.ADD}
          isOpen={isEquipmentGroupAddEditModalOpen}
          equipmentType={activeTabMenuItem.type}
          equipmentGroupObjectId={selectedEquipmentGroupObjectId}
          handleOnClose={() => {
            // Force refetch of vehicles/trailers
            vehicleQueryResult.refetch();
            trailerQueryResult.refetch();
            setIsEquipmentGroupAddEditModalOpen(false);
          }}
        />
      )}
      <div className="map-sidebar flex flex-column">
        <TabMenu
          className="sidebar-tabmenu text-sm"
          model={tabMenuItems}
          activeIndex={activeTabMenuItem.type}
          onTabChange={(e) => handleOnTabChange(e)}
        />

        {isGroupView && (activeTabMenuItem.type === EquipmentTypes.VEHICLE || activeTabMenuItem.type === EquipmentTypes.TRAILER) ? (
          <EquipmentGroupView
            vehicleQueryResult={vehicleQueryResult}
            trailerQueryResult={trailerQueryResult}
            equipmentType={activeTabMenuItem.type}
            isActionDisabled={() => isActionDisabled()}
            setActiveEquipmentInformation={props.setActiveEquipmentInformation}
            handleOnEquipmentSelect={(equipment) => handleOnEquipmentSelect(equipment)}
            onSelectEquipment={props.onSelectEquipment}
            activeEquipment={activeEquipment}
            speedLimitKm={props.speedLimitKm}
            handleOnEquipmentGroupAddEdit={(equipmentGroupObjectId) => handleOnEquipmentGroupAddEdit(equipmentGroupObjectId)}
          />
        ) : (
          <AllEquipmentView
            vehicleQueryResult={vehicleQueryResult}
            trailerQueryResult={trailerQueryResult}
            geofenceQueryResult={geofenceQueryResult}
            equipmentType={activeTabMenuItem.type}
            addEditGeofence={props.addEditGeofence}
            isActionDisabled={() => isActionDisabled()}
            setActiveEquipmentInformation={props.setActiveEquipmentInformation}
            handleOnEquipmentSelect={(equipment) => handleOnEquipmentSelect(equipment)}
            handleSetAddEditGeofence={props.handleSetAddEditGeofence}
            onSelectEquipment={props.onSelectEquipment}
            activeEquipment={activeEquipment}
            activeGeofence={props.activeGeofence}
            speedLimitKm={props.speedLimitKm}
          />
        )}

        {/* Bottom Actions */}
        <div className="flex flex-shrink-0 p-2">
          <Button
            className="w-full mr-2 text-sm lg:text-base"
            label={`ADD ${activeTabMenuItem.value}`}
            icon={<span className="material-icons">add</span>}
            onClick={() => handleAddEquipment()}
            disabled={isActionDisabled()}
          />

          {/* Only the vehicle and trailer tabs should show the view toggles for groups/all equipment */}
          {activeTabMenuItem.type !== 2 && (
            <Button
              className="p-button-outlined p-button-secondary flex-shrink-0"
              icon={(
                <span className="material-icons">
                  {isGroupView ? 'format_list_bulleted' : 'view_quilt'}
                </span>
              )}
              tooltip={`Toggle ${isGroupView ? 'All Equipment' : 'Group'} View`}
              onClick={() => setIsGroupView(!isGroupView)}
              disabled={isActionDisabled()}
            />
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { CloudUserPreference } = state;
  return {
    CloudUserPreference,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(Sidebar);
