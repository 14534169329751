import React from 'react';
import moment from 'moment-timezone';
import history from 'sbHistory';
import PropTypes from 'prop-types';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';

// API
import { getCurrentUser, getAttribute } from 'api/Parse';
import * as ELD from 'api/ELD';
import * as IFTA from 'api/IFTA';
import * as Getters from 'api/Getters';
import * as Analytics from 'api/Analytics';
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL';
import {
  getCompanyAnalytics,
  updateCompanyAnalytics,
} from 'api/CompanyAnalytics/CompanyAnalytics';

// Modules
import CircularProgress from 'material-ui/CircularProgress';

// Components
import Title from 'components/Shared/Title/Title';
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import SubNavigationBar from 'components/Shared/SubNavigationBar/SubNavigationBar';
import InputSwitch from 'sbCore/InputSwitch/InputSwitch';
import Checkbox from 'sbCore/Checkbox/Checkbox';
import InputLabel from 'sbCore/InputLabel/InputLabel';

import ListSelectField from 'components/ListSelectField/view/ListSelectField';

import DriversTable from 'components/IFTARoute/container/DriversTable';
import VehiclesTable from 'components/IFTARoute/container/VehiclesTable';
import VehicleRoutes from 'components/IFTARoute/container/VehicleRoutes';
import FleetTotalsTable from 'components/IFTARoute/container/FleetTotalsTable';
import VehicleTotalsSummaryTable from 'components/IFTARoute/container/VehicleTotalsSummaryTable';

// Lists
import { Months } from 'api/Lists/Months';

function getYears() {
  const years = [];
  for (let i = 2017; i <= moment().year(); i++) {
    years.unshift(i);
  }
  return years;
}

class IFTARouteLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      isLoading: false,
      routeToNewIFTA: true,
      displayEndDate: undefined,
      displayStartDate: undefined,
      showInactiveVehicles: false,
      vehicles: {
        vehicleMileagesArr: [],
        showTable: false,
        selectedVehicleMileageObj: null,
        selectedVehicleUnitId: null,
      },
      filter: {
        startMonth: moment().subtract(1, 'months').format('MMMM'),
        startYear: parseInt(moment().subtract(1, 'months').format('YYYY')),
        endMonth: moment().subtract(1, 'months').format('MMMM'),
        endYear: parseInt(moment().subtract(1, 'months').format('YYYY')),
      },
      companyAnalytics: undefined,
    };

    this.getIFTAData = this.getIFTAData.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.toggleNewIFTA = this.toggleNewIFTA.bind(this);
    this.selectActiveVehicle = this.selectActiveVehicle.bind(this);
  }

  async componentDidMount() {
    const companyAnalytics = await getCompanyAnalytics();

    this.refreshState({ companyAnalytics });
  }

  refreshState(customStateUpdates = {}) {
    const { props } = this;
    const { location } = props;

    const queryStrObj = getDeserialized(location.search).params;
    const dateStart = (location && getQueryParameter(location.search, 'dateStart')) && moment(getQueryParameter(location.search, 'dateStart'), 'YYYYMMDD');
    const dateEnd = (location && getQueryParameter(location.search, 'dateEnd')) && moment(getQueryParameter(location.search, 'dateEnd'), 'YYYYMMDD');

    if (dateStart && dateEnd) {
      this.setState({
        ...this.state,
        filter: {
          ...this.state.filter,
          startMonth: moment(dateStart).format('MMMM'),
          startYear: parseInt(moment(dateStart).format('YYYY')),
          endMonth: moment(dateEnd).format('MMMM'),
          endYear: parseInt(moment(dateEnd).format('YYYY')),
        },
        ...customStateUpdates,
      }, () => {
        this.getIFTAData().then(() => {
          if (queryStrObj.vehicle) this.selectActiveVehicle(queryStrObj.vehicle);
        });
      });
    }
  }

  async componentWillReceiveProps(nextProps) {
    const newQueryStrObj = getDeserialized(nextProps.location.search).params;
    const oldQueryStrObj = getDeserialized(this.props.location.search).params;

    if (newQueryStrObj.vehicle || newQueryStrObj.driver) {
      await this.setState({ ...this.state, selected: true });
    } else {
      await this.setState({ ...this.state, selected: false });
    }

    if (newQueryStrObj.vehicle !== oldQueryStrObj.vehicle) {
      this.selectActiveVehicle(newQueryStrObj.vehicle);
    }
  }

  async getIFTAData(fuelCardTransactionsByVehicle, resetViews) {
    const { state } = this;
    const currentUser = await getCurrentUser();
    const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

    // Check company analytics status
    if (state.companyAnalytics) {
      const companyAnalytics = state.companyAnalytics;
      const GENIFTA = getAttribute(companyAnalytics, 'GENIFTA');
      if (!GENIFTA) {
        Analytics.track('IFTAData Queried', { label: 'Load Miles For Date' });
        Analytics.identify(getAttribute(belongsToCompany, 'objectId'), { IFTACREATE: true });
        await updateCompanyAnalytics(companyAnalytics, { GENIFTA: true });
      }
    }

    // Use original dateStart and dateEnd values to display correct information to client
    let dateStart = moment().month(state.filter.startMonth).startOf('month').year(state.filter.startYear).toDate();
    let dateEnd = moment().month(state.filter.endMonth).endOf('month').year(state.filter.endYear).toDate();
    this.setState({ ...this.state, isLoading: true, displayStartDate: dateStart, displayEndDate: dateEnd });

    // Obtain dispatcher timezone
    const dispatcher = await Getters.getCurrentDispatcher();
    const dispatcherStartTimezoneObject = ELD.getELDDailyCertificationIntervalFromDriverTZ(dispatcher, dateStart);
    const dispatcherEndTimezoneObject = ELD.getELDDailyCertificationIntervalFromDriverTZ(dispatcher, dateEnd);

    // Update dateStart and dateEnd values with timezone adjusted dates
    dateStart = dispatcherStartTimezoneObject.dayOf;
    dateEnd = dispatcherEndTimezoneObject.dayAfter;

    // vehicleMileageObj = { unitId: string, iftaRouteArr: array, stateProvinceMileages: [ { stateProvince (string): totalKm (number) }] }
    const vehicleArr = await IFTA.getIFTAVehicles(dateStart, true);
    const vehicleUnitIdArr = vehicleArr.map((vehicleObj) => vehicleObj.get('unitId'));
    const vehicleMileagesArr = [];
    const promiseArr = [];

    const getVehicleMileagesObj = async (unitId) => {
      const queryNewIfta = state.routeToNewIFTA;
      let iftaRouteArr;

      iftaRouteArr = (await IFTA.getIFTARoutesForVehicle(unitId, dateStart, dateEnd, queryNewIfta)).filter((iftaRouteObj) => getAttribute(iftaRouteObj, 'vehicleLocationStart'));
      return vehicleMileagesArr.push({ unitId, iftaRouteArr });
    };
    for (let i = 0; i < vehicleUnitIdArr.length; i++) {
      const vehicleUnitId = vehicleUnitIdArr[i];
      promiseArr.push(getVehicleMileagesObj(vehicleUnitId));
    }

    Promise.all(promiseArr).then(() => {
      this.setState({
        ...this.state,
        isLoading: false,
        dateStart,
        dateEnd,
        vehicles: {
          ...this.state.vehicles,
          vehicleMileagesArr,
          vehicleArr,
        }
      }, () => {
        const queryStrObj = getDeserialized(this.props.location.search).params;
        queryStrObj.dateStart = moment(this.state.displayStartDate).format('YYYYMMDD');
        queryStrObj.dateEnd = moment(this.state.displayEndDate).format('YYYYMMDD');

        if (resetViews) {
          queryStrObj.p = undefined;
          queryStrObj.v = undefined;
        }

        history.push({ search: getSerialized(queryStrObj).query });
        if (this.state.vehicles.selectedVehicleUnitId) this.selectActiveVehicle(this.state.vehicles.selectedVehicleUnitId);
      });
    });

    Analytics.identifyOnceForCompany('GENIFTA', {
      'IFTA Data Queried': true,
      'IFTAQueryDate': moment().toISOString(),
    });
  }

  selectActiveVehicle(unitId) {
    const { state } = this;

    const newState = { ...this.state };
    let selectedVehicleMileageObj = undefined;
    const vehicleMileagesArr = state.vehicles.vehicleMileagesArr;

    for (let i = 0; i < vehicleMileagesArr.length; i++) {
      if (vehicleMileagesArr[i].unitId === unitId) {
        selectedVehicleMileageObj = vehicleMileagesArr[i];
        break;
      }
    }

    newState.vehicles.selectedVehicleMileageObj = selectedVehicleMileageObj;
    newState.vehicles.selectedVehicleUnitId = unitId;
    newState.vehicles.showTable = true;
    this.setState(newState);
  }

  toggleNewIFTA() {
    this.setState({ ...this.state, routeToNewIFTA: !this.state.routeToNewIFTA }, () => {
      this.refreshState();
    });
  }

  render() {
    const { props, state } = this;

    const title = <span style={{ position: 'relative' }}> {'IFTA Mileage Data'} </span>
    const tempView = props.location ? getQueryParameter(props.location.search, 'p') : undefined;
    const queryStrObj = getDeserialized(props.location.search).params;

    const navItems = [
      {
        name: 'Mileages Per Vehicle',
        isActive: !tempView || tempView === 'vehicles',
        to: getSerialized({ ...queryStrObj, p: 'vehicles' }).query,
      },
      {
        name: 'Mileages Per Driver',
        isActive: tempView === 'drivers',
        to: getSerialized({ ...queryStrObj, p: 'drivers' }).query,
      },
      {
        name: 'Vehicle Mileages',
        isActive: tempView === 'vehicleTotals',
        to: getSerialized({ ...queryStrObj, p: 'vehicleTotals' }).query,
      },
      {
        name: 'Total Fleet Mileage',
        isActive: tempView === 'fleetTotals',
        to: getSerialized({ ...queryStrObj, p: 'fleetTotals' }).query,
      },
    ];

    return (
      <MDBContainer className={`px-7 ${props.styles.container}`} fluid>
        {/* Title */}
        <MDBRow>
          <MDBCol size="8">
            <Title className="translate-me" title={title} leftColumnStyle={props.styles.titleLeftColumnStyle} />
          </MDBCol>
          <MDBCol size="4">
            <div className="text-right pr-5">
              <div
                className="sb-font-roboto text-gray-calm inline-block uppercase font-bold underline"
                style={{ fontSize: '.75em', marginTop: '3em' }}
              >
                Toggle to try our new IFTA:
              </div>
              <div className="inline-block ml-2" style={{ position: 'absolute', marginTop: '2em' }}>
                <InputSwitch
                  checked={props.showNewIFTA}
                  onChange={() => props.toggleNewIFTA()}
                />
              </div>
            </div>
          </MDBCol>
        </MDBRow>

        {/* New IFTA Rollout Card */}
        {/* {!state.vehicles.selectedVehicleMileageObj &&
          <MDBRow>
            <SBBlock
              className="sbblock-ifta-rollout ml-3"
              title="Try Our Improved IFTA Readings!"
              messages={[
                <div style={{ fontSize: '.85em' }}>{`New, more accurate, improved IFTA readings for all your vehicles`}</div>,
                <br />,
                <div style={{ fontSize: '.85em' }}>{`It will take time for all your vehicles to be populated with the new style of readings from this quarter, from the`}</div>,
                <div style={{ fontSize: '.85em' }}>{`readings you are accustomed to seeing, so if the mileages for a vehicle do not yet look complete, simply`}</div>,
                <div style={{ fontSize: '.85em' }}>{'go back and toggle the switch off to go back to the previous style of readings'}</div>,
                <div style={{ marginTop: '.5em' }}>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitches"
                      checked={state.routeToNewIFTA}
                      onChange={() => this.toggleNewIFTA()}
                      readOnly
                    />
                    <label className="custom-control-label" htmlFor="customSwitches" style={{ paddingTop: '.3em', fontSize: '.85em', textTransform: 'uppercase' }}>
                      <div style={{ fontWeight: '500' }}>Toggle new readings</div>
                    </label>
                  </div>
                </div>,
                <br />,
                <div style={{ fontSize: '.9em' }}>{'Reports are available up to the previous month'}</div>,
                <div style={{ fontSize: '.9em' }}>{'If you do not see your mileages, contact us at support@onswitchboard.com'}</div>,
              ]}
              listMessages={false}
            />
          </MDBRow>
        } */}

        <MDBRow className={`${props.styles.iftaDataOptionsContainer} translate-me`}>
          <MDBCol className="d-flex">
            <ListSelectField
              onChange={(e, index, month) => this.setState({ ...this.state, filter: { ...state.filter, startMonth: month } })}
              floatingLabelText="Start Month"
              value={state.filter.startMonth}
              maxHeight={200}
              list={Months}
              style={{ width: '13em', marginLeft: '1em', marginRight: '1em' }}
            />
            <ListSelectField
              onChange={(e, index, year) => this.setState({ ...this.state, filter: { ...state.filter, startYear: year } })}
              floatingLabelText="Start Year"
              value={state.filter.startYear}
              maxHeight={200}
              list={getYears()}
              style={{ width: '10em', marginRight: '1em' }}
            />

            <div className="mt-auto mb-3" style={{ display: 'inline-block', marginLeft: '1em', marginRight: '1em', fontWeight: '600' }}>
              -&nbsp;to&nbsp;-
            </div>

            <ListSelectField
              onChange={(e, index, month) => this.setState({ ...this.state, filter: { ...state.filter, endMonth: month } })}
              floatingLabelText="End Month"
              value={state.filter.endMonth}
              maxHeight={200}
              list={Months}
              style={{ width: '13em', marginLeft: '1em', marginRight: '1em' }}
            />
            <ListSelectField
              onChange={(e, index, year) => this.setState({ ...this.state, filter: { ...state.filter, endYear: year } })}
              floatingLabelText="End Year"
              value={state.filter.endYear}
              maxHeight={200}
              list={getYears()}
              style={{ width: '10em', marginRight: '1em' }}
            />

            <div className="mt-auto mb-2" style={{ display: 'inline-block' }}>
              <MDBBtn
                size="sm"
                onClick={async () => await this.getIFTAData()}
                disabled={state.isLoading}
                style={{ fontWeight: '500' }}
                color="primary"
              >LOAD MILES</MDBBtn>
            </div>

            {state.selected &&
              <div className="mt-auto mb-2" style={{ display: 'inline-block' }}>
                <MDBBtn
                  size="sm"
                  onClick={() => {
                    const queryStrObj = getDeserialized(props.location.search).params;
                    queryStrObj.vehicle = undefined;
                    queryStrObj.driver = undefined;
                    history.push({ search: getSerialized(queryStrObj).query });
                  }}
                  color="secondary"
                  style={{ fontWeight: '500' }}
                >
                  BACK
                </MDBBtn>
              </div>
            }
          </MDBCol>
        </MDBRow>

        {/* IFTA Warning */}
        {!state.dateEnd &&
          <SBBlock
            title={`Switchboard processes IFTA vehicle mileages up to 10 days before today's date.`}
            header={`If you are generating reports for the previous month, make sure you generate them on the 11th of this month to get the most updated mileages.`}
            warning
          />
        }

        {/* IFTA Loading */}
        {state.isLoading &&
          <MDBRow>
            <MDBCol style={{ textAlign: 'center' }}>
              <CircularProgress />
            </MDBCol>
          </MDBRow>
        }

        {/* IFTA Tables */}
        {!state.isLoading && (state.vehicles.vehicleMileagesArr.length !== 0 && !state.vehicles.selectedVehicleMileageObj) &&
          <div>
            <MDBRow><MDBCol>
              <Title title={`IFTA Mileages for ${state.displayStartDate && moment(state.displayStartDate).format('MMMM YYYY')} to ${state.displayEndDate && moment(state.displayEndDate).format('MMMM YYYY')}`} />
            </MDBCol></MDBRow>
            {/* <MDBRow><MDBCol>
              <div className="flex field-checkbox mb-0">
                <Checkbox
                  checked={state.showInactiveVehicles}
                  onChange={(event) => this.setState({ ...this.state, showInactiveVehicles: event.checked })}
                />
                <InputLabel className="internal-leg-label">Show Inactive Vehicles</InputLabel>
              </div>
            </MDBCol></MDBRow> */}
            {!state.selected &&
              <SubNavigationBar navItems={navItems} />
            }
            {(!tempView || tempView === 'vehicles') &&
              <VehiclesTable
                vehicleUnitIdArr={state.vehicles.vehicleMileagesArr.map((vehicleMileagesObj) => vehicleMileagesObj.unitId)}
                vehiclesObj={state.vehicles}
                showInactiveVehicles={state.showInactiveVehicles}
                handleChangeShowInactiveVehicles={(show) => this.setState({ ...this.state, showInactiveVehicles: show })}
                selectVehicle={(unitId) => {
                  const queryStrObj = getDeserialized(props.location.search).params;
                  queryStrObj.vehicle = unitId;
                  history.push({ search: getSerialized(queryStrObj).query });
                }}
                dateStart={state.dateStart}
                dateEnd={state.dateEnd}
              />
            }
            {(tempView === 'drivers') &&
              <DriversTable
                vehicleMileagesArr={this.state.vehicles.vehicleMileagesArr.sort((a, b) => a.unitId - b.unitId)}
                selectDriver={(driverObj) => {
                  const queryStrObj = getDeserialized(this.props.location.search).params;
                  queryStrObj.vehicle = unitId;
                  history.push({ search: getSerialized(queryStrObj).query });
                }}
                dateStart={state.dateStart}
                dateEnd={state.dateEnd}
                location={props.location}
              />
            }
            {tempView === 'fleetTotals' &&
              <FleetTotalsTable
                vehicleMileagesArr={state.vehicles.vehicleMileagesArr.sort((a, b) => a.unitId - b.unitId)}
                dateStart={state.dateStart}
                dateEnd={state.dateEnd}
              />
            }
            {tempView === 'vehicleTotals' &&
              <VehicleTotalsSummaryTable
                vehicleMileagesArr={this.state.vehicles.vehicleMileagesArr.sort((a, b) => a.unitId - b.unitId)}
                dateStart={this.state.dateStart}
                dateEnd={this.state.dateEnd}
                displayStartDate={this.state.displayStartDate}
                displayEndDate={this.state.displayEndDate}
                routeToNewIFTA={this.state.routeToNewIFTA}
                location={this.props.location}
              />
            }
          </div>
        }

        {/* Vehicles Table */}
        {state.vehicles.selectedVehicleMileageObj &&
          <VehicleRoutes
            unitId={state.vehicles.selectedVehicleMileageObj.unitId}
            vehicleUnitIdArr={state.vehicles.vehicleMileagesArr.map((vehicleMileagesObj) => vehicleMileagesObj.unitId)}
            iftaRouteArr={state.vehicles.selectedVehicleMileageObj.iftaRouteArr}
            location={props.location}
            dateStart={state.dateStart}
            dateEnd={state.dateEnd}
            displayStartDate={state.displayStartDate}
            displayEndDate={state.displayEndDate}
            routeToNewIFTA={state.routeToNewIFTA}
            refreshState={() => this.refreshState()}
          />
        }
      </MDBContainer>
    );
  }
}

IFTARouteLayout.propTypes = {
  styles: PropTypes.any,
  location: PropTypes.instanceOf(Object),
  toggleNewIFTA: PropTypes.func,
  showNewIFTA: PropTypes.bool,
};

export default IFTARouteLayout;
