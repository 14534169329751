import React from 'react';
import PropTypes from 'prop-types';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

// CSS
// import styles from './ErrorList.module.scss';

function ListSelectField(props) {
  let list;
  const disabledItemIndices = props.disabledItemIndices;
  // if (props.type === 'ifta') {
  //   // group by country
  //   const canadianGroup = props.list.filter(item => {
  //     return item.country && item.country.toLowerCase() === 'can';
  //   });
  //   const americanGroup = props.list.filter(item => {
  //     return item.country && item.country.toLowerCase() === 'us';
  //   });
  //
  //   list = canadianGroup.concat(americanGroup);
  //
  //   list = props.list.map((item, index) => {
  //     let value;
  //     if (item.stateProvince) value = item.stateProvince.toLowerCase();
  //     let primaryText = item.stateProvince;
  //     if (item.surcharge) {
  //       value += '1';
  //       primaryText += ' (Surcharge)';
  //     }
  //     if (item.footnoteId) {
  //       primaryText != ` #${item.footnoteId}`;
  //     }
  //     return <MenuItem value={value} key={value} primaryText={primaryText} />;
  //   });
  //
  // } else if (props.type === 'iftaFuelType') {
  //   list = props.list.map((item, index) => {
  //     return <MenuItem value={item.attributeName} key={item.attributeName} primaryText={item.name} />;
  //   });
  // } else {
  list = props.list.map((item, index) => {
    const isDisabledItem = disabledItemIndices ? disabledItemIndices.indexOf(index) !== -1 : false;

    if (props.type === 'country' || props.type === 'stateProvince') {
      return <MenuItem  value={item.name} key={item.code} primaryText={item.name} disabled={isDisabledItem} innerDivStyle={props.menuItemInnerDivStyle} style={props.menuItemStyle} />;
    } else if (props.type === 'iftaReports' || props.type === 'driverPatternReports' || props.type === 'stateProvinceCode') {
      return <MenuItem value={item.code} key={item.code} primaryText={item.name} disabled={isDisabledItem} innerDivStyle={props.menuItemInnerDivStyle} style={props.menuItemStyle} />;
    }
      // else if (props.type === 'currenciesShort') {
      //   return <MenuItem value={item} key={item} primaryText={item} />;
      // }

    return <MenuItem value={item} key={item} primaryText={item} disabled={isDisabledItem} innerDivStyle={props.menuItemInnerDivStyle} style={props.menuItemStyle} />;
  });
  // }
  return (
    <SelectField
      {...props}
      maxHeight={props.maxHeight || 200}
      autoWidth
      style={props.style}
    >
      {list}
    </SelectField>
  );
}

ListSelectField.propTypes = {
  className: PropTypes.string,
  floatingLabelText: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  maxHeight: PropTypes.number,
  list: PropTypes.array,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  disabledItemIndices: PropTypes.array,
  labelStyle: PropTypes.object,
  floatingLabelStyle: PropTypes.object,
  underlineFocusStyle: PropTypes.object,
  menuStyle: PropTypes.object,
  menuItemStyle: PropTypes.object,
  style: PropTypes.object,
  menuItemInnerDivStyle: PropTypes.object,
  menuItemStyle: PropTypes.object,
};

export default ListSelectField;
