// http://londoncycle.alexrieux.fr/
import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactMapboxGl, { Feature, ZoomControl, Layer } from 'react-mapbox-gl';

// API
import * as Mapbox from 'api/Mapbox';
import * as Helpers from 'api/Helpers';
import * as GPS from 'api/GPS/GPS';
import { getAttribute } from 'api/Parse';
import { isVehicleDriverDriving } from 'api/ELD';
import { convertParseVehicleLocationArrToPointArr } from 'api/Helpers';
import * as Geocode from 'api/Geocode';

// Components
import MapStyleToggle from 'components/Map.old/view/components/MapStyleToggle';
import LinePath from 'components/Map.old/view/components/LinePath';
import directionAsset from 'assets/icon/arrow/arrowGreen.svg';
import localShippingGrayCalm from 'assets/icon/localShipping/localShippingGrayCalm.svg';
import VehiclePopup from 'components/Map.old/container/components/VehiclePopup';
import ShareVehicleTrailerActiveView from 'components/Map/VehicleTrailerActiveView/ShareVehicleTrailerActiveView';

// Context
import StoreContext from 'contexts/StoreContext';
// import GPSPointPopup from './components/GPSPointPopup';

import './style.scss';

const ReactMap = ReactMapboxGl({
  accessToken: Mapbox.getToken(),
});

const assetHasUpdatedRecently = (assetObj) => {
  return (
    moment(assetObj.locationUpdateTime).isAfter(moment().subtract(8, 'minutes')) // If location of vehicle was updated within 8 minutes from now
  );
}

const getLocationUpdateTime = (assetObj) => {
  if (assetObj && assetObj.get('tc_positions') && assetObj.get('tc_positions').get('location')) {
    return assetObj.get('devicetime');
  } else if (assetObj && assetObj.get('vehicleLocation') && assetObj.get('vehicleLocation').get('dateTime')) {
    const vehicleLocationObj = getAttribute(assetObj, 'vehicleLocation', true);
    return vehicleLocationObj.get('dateTime');
  }
}

const shouldActivateFollowing = (assetObj) => {
  return (
    assetObj.speedKm > 0 && // Make sure that speedKm is defined and > 0
    assetHasUpdatedRecently(assetObj)
  );
}

const shouldProcessAssetData = (oldLocationArr, newLocationArr) => {
  if (!oldLocationArr && newLocationArr) {
    return true;
  } else if (!oldLocationArr && !newLocationArr) {
    return false;
  } else if (oldLocationArr?.length !== newLocationArr?.length) {
    return true;
  } else if (
    (oldLocationArr && newLocationArr && oldLocationArr[0] && newLocationArr[0] && oldLocationArr[0].latitude !== newLocationArr[0].latitude) ||
    (oldLocationArr && newLocationArr && oldLocationArr[1] && newLocationArr[1] && oldLocationArr[1].longitude !== newLocationArr[1].longitude)
  ) {
    return true;
  }
  return false;
}

const getMapStyle = (style) => {
  if (style === 'street') {
    return 'mapbox://styles/mapbox/navigation-day-v1';
    // return 'mapbox://styles/mapbox/traffic-day-v2';
  } else if (style === 'streetNight') {
    return 'mapbox://styles/mapbox/streets-v11';
    // return 'mapbox://styles/mapbox/traffic-night-v2';
  } else if (style === 'satellite') {
    return 'mapbox://styles/mapbox/satellite-streets-v12';
    // return 'mapbox://styles/mapbox/satellite-streets-v10';
  }
  return 'mapbox://styles/mapbox/navigation-day-v1';
}

class VehicleMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: [-98.467324, 49.069434],
      zoom: [9],
      assetObj: {},
      follow: {},
      showVehiclePopup: true,
      showAssetPopup: true,
      mapStyleUrl: getMapStyle(),
      mapStyle: 'street',
    };

    this.setLocationData = this.setLocationData.bind(this);
    this.processAssetData = this.processAssetData.bind(this);
    this.initializeFollow = this.initializeFollow.bind(this);
    this.animateActiveObjFromStartPointToEndPoint = this.animateActiveObjFromStartPointToEndPoint.bind(this);
  }

  componentDidMount() {
    if (this.props.locationArr && this.props.locationArr.length > 0 && this.props.locationArr[0]) {
      this.processAssetData(this.props);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.locationArr
      && nextProps.locationArr[0]
      && shouldProcessAssetData(this.props.locationArr, nextProps.locationArr)
    ) {
      this.processAssetData(nextProps);
    }
  }

  async setLocationData(type, asset, locationArr, locationDataObjArr, destinationObj) {
    let tcPositionsObj;
    let vehicleLocationObj
    let eldEvent;
    let unitId;
    let location;
    let coordinates;
    let speedKm;
    let bearing;
    let mapboxProperties;
    let locationUpdateTime;
    let formattedAddress;
    if (locationDataObjArr && locationDataObjArr.length > 0) {
      const locationDataObj = locationDataObjArr[0];

      unitId = locationDataObj.unitId;
      location = locationDataObj.location;
      speedKm = locationDataObj.speedKm;
      mapboxProperties = { title: unitId };
      bearing = speedKm > 0 && locationDataObj.gpsHeading;
      locationUpdateTime = locationDataObj.dateTime;
    } else if (asset && asset.get('tc_positions') && asset.get('tc_positions').get('location')) {
      unitId = getAttribute(asset, 'name', true);
      tcPositionsObj = getAttribute(asset, 'tc_positions', true);
      location = tcPositionsObj ? getAttribute(tcPositionsObj, 'location', true) : undefined;
      if (tcPositionsObj) {
        speedKm = getAttribute(tcPositionsObj, 'speed');
      }
      mapboxProperties = { title: unitId };
      bearing = speedKm > 0 && (tcPositionsObj && getAttribute(tcPositionsObj, 'course'));
      // if (bearing) mapboxProperties.rotate = bearing + 90;
      locationUpdateTime = tcPositionsObj.get('devicetime');
    } else if (asset && asset.get('vehicleLocation') && asset.get('vehicleLocation').get('location')) {
      unitId = getAttribute(asset, 'unitId', true);
      vehicleLocationObj = getAttribute(asset, 'vehicleLocation', true);
      location = vehicleLocationObj ? getAttribute(vehicleLocationObj, 'location', true) : undefined;
      eldEvent = asset && vehicleLocationObj && getAttribute(vehicleLocationObj, 'eldEvent', true);
      speedKm = getAttribute(vehicleLocationObj, 'speedKm');
      mapboxProperties = { title: unitId };
      bearing = speedKm > 0 && ((vehicleLocationObj && getAttribute(vehicleLocationObj, 'gpsHeading')) || ((vehicleLocationObj && getAttribute(vehicleLocationObj, 'course'))));
      if (bearing) mapboxProperties.rotate = bearing - 90;
      locationUpdateTime = vehicleLocationObj.get('dateTime');
    }

    coordinates = [undefined, undefined];
    if (location) {
      coordinates = [location.longitude, location.latitude];

      const geocodeDetails = await Geocode.getReverseGeocode(
        [
          [coordinates[1], coordinates[0]],
        ],
      );
      if (geocodeDetails && geocodeDetails[0] && geocodeDetails[0].formatted_address) {
        formattedAddress = geocodeDetails[0].formatted_address;
      } else {
        formattedAddress = `${geocodeDetails[0]?.name}, ${geocodeDetails[0]?.admin1Code?.name}`;
      }
    }

    let destinationLineCoordinates = null;
    let eta = null;
    let msToDestination = null;
    if (!this.state.destinationLineCoordinates && destinationObj && destinationObj.location) {
      const roadRouteCoordinateResponse = await Mapbox.getRoadRoute([[location.longitude, location.latitude], [destinationObj.location.longitude, destinationObj.location.latitude]], false, true);
      const mapboxResult = roadRouteCoordinateResponse.results[0];
      destinationLineCoordinates = roadRouteCoordinateResponse.polylineWaypoints;
      const duration = mapboxResult.duration;
      msToDestination = duration * 1000;
      if (duration) {
        eta = moment().add(duration, 'seconds');
      }
    }
    const center =
      (this.state.follow.active && this.state.follow.currentPoint) ||
      (locationArr[0].longitude !== 0 && locationArr[0].latitude !== 0 && [locationArr[0].longitude, locationArr[0].latitude]) ||
      (location && [location.longitude, location.latitude]);

    const assetObj = {
      coordinates,
      bearing,
      asset,
      formattedAddress,
      eta,
      eldEvent,
      unitId,
      speedKm,
      mapboxProperties,
      locationArr,
      locationUpdateTime,
      type,
    };

    if (locationArr && locationArr.length > 0 && locationArr[0]?.longitude && destinationLineCoordinates) {
      destinationLineCoordinates.unshift({ longitude: locationArr[0].longitude, latitude: locationArr[0].latitude });
    }

    this.setState({
      ...this.state,
      assetObj,
      center,
      zoom: [11],
      destinationLineCoordinates,
      etaRetrievedMoment: moment(),
    }, () => {
      if (shouldActivateFollowing(this.state.assetObj) && this.state.assetObj.locationArr) {
        if (destinationObj?.location?.latitude) {
          // Project to destination
          this.initializeFollow(locationArr[1], { longitude: destinationObj.location.longitude, latitude: destinationObj.location.latitude }, destinationLineCoordinates, msToDestination);
        } else {
          // Project to next vehicle location
          this.initializeFollow(locationArr[1], locationArr[0]);
        }
      }
    });
  }

  processAssetData(props) {
    this.setState({
      ...this.state,
      asset: props.asset,
      center: (this.state.follow.active) ? this.state.follow.currentPoint : [props.locationArr[0].longitude, props.locationArr[0].latitude],
      zoom: [11],
      follow: {},
    }, () => {
      if (props.locationDataType === 'VehicleLocation') {
        this.setLocationData('vehicle', props.asset, props.locationArr, props.locationDataObjArr, props.destinationObj);
      } else if (props.locationDataType === 'asset') {
        this.setLocationData('asset', props.asset, props.locationArr, props.locationDataObjArr, props.destinationObj);
      }
    });
  }

  async initializeFollow(startLocationObj, endLocationObj, _roadRouteCoordinateArr, msToDestination) {
    if (this.state.follow.active || !startLocationObj || !endLocationObj || (startLocationObj[0] === endLocationObj[0] && startLocationObj[1] && endLocationObj[1])) return;
    /*
      Pourpose:
      - given two locationArr points, animate the asset marker between the two real location points
      - if a new location comes in, jump marker to the 2nd most recent real location point
    */
    let roadRouteCoordinateArr = _roadRouteCoordinateArr;
    if (!roadRouteCoordinateArr) {
      roadRouteCoordinateArr = await Mapbox.getRoadRoute([[startLocationObj.longitude, startLocationObj.latitude], [endLocationObj.longitude, endLocationObj.latitude]], true);
    }
    const msBetweenLocationObj = msToDestination || 210000; // controls speed of movement, 210000: 3.5 minutes between updates (more accurate, but more vehicles won't project)
    const waypointLngLatArr = roadRouteCoordinateArr.map((waypoint) => [waypoint.longitude, waypoint.latitude]);
    if (waypointLngLatArr && waypointLngLatArr.length > 2) {
      const newState = { ...this.state };
      newState.center = waypointLngLatArr[0];
      newState.follow = {
        ...newState.follow,
        active: true,
        waypointLngLatArr,
        currentPoint: waypointLngLatArr[0],
        bearing: GPS.getBearing(waypointLngLatArr[0][1], waypointLngLatArr[0][0], waypointLngLatArr[2][1], waypointLngLatArr[2][0]),
        msBetweenLocationObj,
        timestampStart: null,
      };
      await this.setState(newState, () => {
        window.requestAnimationFrame(this.animateActiveObjFromStartPointToEndPoint);
      });
    }
  }

  async animateActiveObjFromStartPointToEndPoint(_timestamp) {
    // If timestamp is undefined fallback to less precise Date ms, ok because we care about time difference only
    let timestamp = _timestamp || (Date.now());

    // Using timestamp to iterate position of activeObject (coordinates of currentPoint) from waypoint to waypoints
    const { state } = this;
    if (state.follow.active) {
      // If timestampStart not set, set to timestamp
      if (!this.state.follow.timestampStart) this.setState({ ...this.state, follow: { ...this.state.follow, timestampStart: timestamp } });
      // If timestampStart not set we are at the beginning of waypoint
      const { msBetweenLocationObj, startPoint, endPoint, waypointLngLatArr, timestampStart } = state.follow;
      let timestampDiff = timestamp - timestampStart;

      // Project between wayPoints
      const { coordinates, bearing } = GPS.interpolateGPSPoint(waypointLngLatArr, msBetweenLocationObj, timestampDiff);
      const newState = { ...this.state };
      if (bearing) {
        newState.assetObj.mapboxProperties = {
          ...newState.assetObj.mapboxProperties,
          rotate: bearing - 90,
        };
      }
      if (coordinates && coordinates[0]) {
        newState.follow = {
          ...newState.follow,
          currentPoint: coordinates,
          bearing,
        };
      }
      await this.setState(newState);
      window.requestAnimationFrame(this.animateActiveObjFromStartPointToEndPoint);

    } else {
      const newState = { ...this.state };
      newState.follow = {};
      await this.setState(newState);
    }
  }

  // description: this function is in order to convert raw svg icon into image source which will allow geoJson able to read it
  // imageId is String, which can be named as whatever you want
  // svg is the raw svg file
  generateSvgImage(imageId, svg) {
    let image = new Image(20, 20);
    image.src = svg;
    return [imageId, image];
  }

  render() {
    const { state, props } = this;

    //directionIcon
    const directionAssetGreenIcon = this.generateSvgImage('directionAssetGreenIcon', directionAsset);
    const localShippingGrayCalmIcon = this.generateSvgImage('localShippingGrayCalmIcon', localShippingGrayCalm);

    const iconLayerLayout = {
      'icon-image': 'directionAssetGreenIcon',
      'icon-rotate': ['get', 'rotate'],
      'icon-allow-overlap': true,
      'text-allow-overlap': true,
      'text-field': ['format', ['get', 'title'], { 'font-scale': 0.7 }],
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-offset': [0, 0.6],
      'text-anchor': 'top'
    }
    const iconLayerPaint = {
      'text-color': ['get', 'color'],
      "text-halo-color": "#e1e1e1",
      "text-halo-width": "10px",
      "text-halo-width": 2
    };
    // console.log(this.state);
    // const { props, state } = this;
    // // Vehicle and asset locations
    // const vehicleLocationObject = props.asset && props.vehicle.get('vehicleLocation');
    // const assetLocationObject = props.tcDevice && props.tcDevice.get('tc_positions');

    // // Local copy of tcDevice
    // const { tcDevice } = props;

    // // This code block copies setVehicleLocationOnTrailer but on the tcDevice instead (we don't have the trailer.)
    // // If asset location is defined
    // if (assetLocationObject) {
    //   const vehicleLocation = assetLocationObject.clone();
    //   // console.log(tc_positions.get('devicetime'));
    //   // console.log(moment(tc_positions.get('devicetime')).add(7, 'hours').toDate());
    //   vehicleLocation.set('dateTime', assetLocationObject.get('devicetime'));
    //   vehicleLocation.set('speedKm', assetLocationObject.get('speed') * 1.852);
    //   vehicleLocation.set('gpsHeading', assetLocationObject.get('course'));
    //   vehicleLocation.set('ignition', assetLocationObject.get('ignition'));
    //   tcDevice.set('vehicleLocation', vehicleLocation);
    //   tcDevice.set('unitId', tcDevice.get('name'));
    // }

    const setShowAssetPopup = (bool) => this.setState({ showAssetPopup: bool });
    return (
      <div style={{ height: '100%', width: '100%' }}>

        <div className="map-style-toggle">
          <MapStyleToggle
            activeMapStyle={this.state.mapStyle}
            chooseMapStyle={(mapStyle) => this.setState({ ...this.state, mapStyleUrl: getMapStyle(mapStyle), mapStyle })}
          />
        </div>

        {this.props.Main.mapboxToken && (
          <ReactMap
            style={this.state.mapStyleUrl}
            accessToken={props.Main.mapboxToken}
            movingMethod="easeTo"
            zoom={state.zoom}
            center={state.center}
            containerStyle={{ height: '100%', width: '100%' }}
          >

            <ZoomControl />


            {this.state.destinationLineCoordinates && this.state.destinationLineCoordinates.length > 0 &&
              (
                <LinePath
                  // color={legObject.colour}
                  gpsPointsShowBool={false}
                  coordinates={Helpers.convertLongitudeLatitudeObjArrToPointArr(this.state.destinationLineCoordinates)}
                />
              )
            }

            {this.state.assetObj &&
              (
                <div className='asset-layers'>
                  {this.state.assetObj && this.state.assetObj.speedKm > 0 &&
                    (
                      <Layer
                        type='symbol'
                        id='assets-markers-directionAsset'
                        images={directionAssetGreenIcon}
                        layout={{ ...iconLayerLayout, 'icon-image': 'directionAssetGreenIcon' }}
                        paint={iconLayerPaint}
                      >
                        <Feature
                          key={this.state.assetObj.unitId}
                          properties={this.state.assetObj.mapboxProperties}
                          coordinates={this.state.follow.active ? this.state.follow.currentPoint : this.state.assetObj.coordinates}
                        />
                      </Layer>
                    )
                  }
                  {this.state.assetObj && !this.state.assetObj.speedKm &&
                    (
                      <Layer
                        type='symbol'
                        id='assets-markers-inactive'
                        images={localShippingGrayCalmIcon}
                        layout={{ ...iconLayerLayout, 'icon-image': 'localShippingGrayCalmIcon' }}
                        paint={iconLayerPaint}
                      >
                        <Feature
                          key={this.state.assetObj.unitId}
                          properties={this.state.assetObj.mapboxProperties}
                          coordinates={this.state.assetObj.coordinates}
                        />
                      </Layer>
                    )
                  }
                </div>
              )
            }
            {this.state.assetObj && this.state.assetObj.coordinates && (
              <>
                {/* <TcDeviceMarker
                  tcDevice={tcDevice}
                /> */}
                {this.state.showAssetPopup && (
                  <VehiclePopup
                    coordinates={this.state.follow.active ? this.state.follow.currentPoint : this.state.assetObj.coordinates}
                    vehicle={this.props.asset}
                    hidePopup={() => setShowAssetPopup(false)}
                    followBearing={this.state.assetObj.bearing}
                    speedKm={this.state.assetObj.speedKm}
                    unitId={this.state.assetObj.unitId}
                    dateTime={this.state.assetObj.locationUpdateTime}
                    showDetails={false}
                    isMobile={false}
                  />
                )}
                <ShareVehicleTrailerActiveView
                  speedKm={this.state.assetObj.speedKm}
                  gpsHeadingDirection={this.state.assetObj.bearing}
                  formattedAddress={this.state.assetObj.formattedAddress}
                  unitId={this.state.assetObj.unitId}
                  eta={this.state.assetObj.eta}
                  distanceUnit={this.props.distanceUnit}
                  etaRetrievedMoment={this.state.assetObj.etaRetrievedMoment}
                  destinationAddress={this.props.destinationObj.name}
                  asset={this.state.assetObj?.asset}
                  coordinates={this.state.follow.active ? this.state.follow.currentPoint : this.state.assetObj.coordinates}
                />
              </>
            )}

          </ReactMap>
        )
        }
      </div>
    );
  }
}

VehicleMap.propTypes = {
  asset: PropTypes.object,
  getInfo: PropTypes.func,
  Main: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Main } = state;
  return ({
    Main,
  });
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(VehicleMap);
