import React, { useState, useEffect } from 'react';

// enums
import { Currency, getCurrencyObjectFromIndex } from 'sb-csapi/dist/enums/Finance/Currency';

// sbcore
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Dropdown from 'sbCore/Dropdown/Dropdown';

// styles
import './style.scss';

/**
 * @description Currency unit dropdown
 * @param {String} className - Custom container className
 * @param {String} dropdownClassName - Custom dropdown className
 * @param {Object} style = Custom inline styles
 * @param {String} label - Custom label
 * @param {Bool} warning - Makes the border yellow
 * @param {Bool} error - Makes the border red
 * @param {String} currency - Short currency unit value. ex. 'CAD', 'USD'. The string provided must be recognized in our enums
 * @param {Bool} hideLabel - Hide the label
 * @param {Bool} disabled - Whether to disable the dropdown or not
 * @param {Function} onSelect - Callback function that returns the selected currency unit
 * @param {String} variant - Smaller dropdown
 * @example
 * <CurrencyDropdown currency={{short: 'USD', country: 'USA', name: 'US Dollars', symbol: '$', index: 1}} onSelect={(e) => handleSelect(e)} />
 */
export default function CurrencyDropdown({ ...props }) {
  const [selectedCurrency, setSelectedCurrency] = useState(undefined);

  // parse currency enum into dropdown options
  let currencyOptions = [];
  for (const key in Currency) { 
    currencyOptions = currencyOptions.concat({
      ...Currency[key],
    })
  }

  // Set the first item in enum as default
  const defaultOption = currencyOptions[0]

  /* ------------------- useEffect ------------------ */
  // Check to see if dropdown value is changed from parent
  useEffect(() => {
    // props.currency can be passed in as either object or a string
    // object: {short: 'USD', country: 'USA', name: 'US Dollars', symbol: '$', index: 1}
    if (typeof props.currency === 'object') {
      setSelectedCurrency(props.currency)
    }

    // string: 'CAD' or 'USD'
    // Find the currency object for the string
    if (typeof props.currency === 'string') {
      const currencyObj = currencyOptions.find(currency => currency.short === props.currency)
      setSelectedCurrency(currencyObj);
    }
  }, [props.currency])

  /* ------------------- Helpers ------------------ */
  function onChange(e) {
    setSelectedCurrency(e.value);
    if (props.onSelect) props.onSelect(e.value);
  }

  /* --------------------- Misc -------------------- */
  let className = 'currency-dropdown';
  if (props.className) className += ` ${props.className}`;
  let variant = '';
  if (props.variant === 'small') variant += 'small';

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && (
        <InputLabel>{props.label || 'Currency'}</InputLabel>
      )}
      <Dropdown
        className={props.dropdownClassName}
        value={selectedCurrency || defaultOption}
        options={currencyOptions}
        optionLabel="short"
        onChange={(e) => onChange(e)}
        onBlur={(e) => {
          if (props.onBlur) props.onBlur(e);
        }}
        disabled={props.disbaled}
        error={props.error}
        warning={props.warning}
        variant={variant}
      />
    </div>
  )
}