import SBInputText from 'sbCore/InputText/InputText';

import './style.scss';

/**
 * @description customizable text input for document generation
 * @param {String} value - the value of the input for a controlled input, requires onChange callback to be specified
 * @param {Function} onChange - the callback function for a controlled input, requires the value 
 * @param {String} [label] - the label that is attached to this input
 * @param {bool} [horizontal] - determines whether the label is on the top of the input or on the left of the input
 * @param {String} [labelClassName] - styles to be applied to the label itself
 * @param {String} [inputClassName] - styles to be applied to the input
 * @param {bool} [success] - show success
 * @param {bool} [warning] - show warning
 * @param {bool} [error] - show error
 * @param {bool} [isDownloaded] - show/hide input borders when downloading pdf
 * @returns {Component}
 */
function InputText({ label, horizontal, value, success, warning, error, isDownloaded, ...props }) {
  const labelClassName = props.labelClassName;
  const inputClassName = props.inputClassName;

  return (
    <div className={`docgen-input-text${horizontal ? ' flex align-items-center' : ''}`}>
      {label && 
        <div className={`information-sub-title${labelClassName ? ` ${labelClassName}` : ''}`}>{label}</div>
      }
      <div className={`${isDownloaded ? ' invisible-border' : ''} ${horizontal ? ' horizontal-document-input-text' : ''}`}>
        <SBInputText className={inputClassName} value={value} placeholder={'-'} onChange={(e) => props.onChange(e.target.value)} success={success} warning={warning} error={error} />
      </div>
    </div>
  );
}

export default InputText;