import React, { useState, useEffect, useRef } from 'react';
import Parse from 'parse';

// api
import { send } from 'api/Email/Email';

// csapi
import { getAttribute, getCurrentUserCompanyObjectId, getCurrentUserSessionToken, getObjectById } from 'sb-csapi/dist/AAPI';

// sbcore components
import UploadedFiles from 'sbCore/UploadedFiles/UploadedFiles';
import EmailEditorToolbar from 'sbCore/Email/EmailEditorToolbar';

import Dialog from 'sbCore/Dialog/Dialog';
import Chips from 'sbCore/Chips/Chips';
import Editor from 'sbCore/Editor/Editor';
import Button from 'sbCore/Button/Button';
import InputText from 'sbCore/InputText/InputText';

// styling
import './style.scss';

/**
 * @description Email client dialog pop-up
 * @param {Array} recipients - Optional. String array of recipients that can be attached to the email
 * @param {Array} ccRecipients - Optional. String array of cc recipients that can be attached to the email
 * @param {Array} bccRecipients - Optional. String array of bcc recipients that can be attached to the email
 * @param {Array} selectedDispatchDocuments - Optional. Array of dispatch documents that will be attached to the email
 * @param {Function} setHideEmail - Required. Callback function that when called, hides the email pop-up
 * @param {Function} resetSelectedDispatchDocuments - Optional. Callback function which resets selected dispatch documents when email sent
 * @param {*} dispatchDocumentsMultiSelect - Optional. Dispatch Document MultiSelect component
 * @param {string} subject - Optional. String subject line that can be attached to the email
 * @param {string} messageBody - Optional. HTML message inside a string that can be attached to the email
 * @param {bool} showEmailDialog - Required. Boolean that displays the email pop-up when true
 *
 * @returns
 *
 * @example
 * <Email dispatchJobObjectId={objectId} recipients=['DenzelNasol@offswitchboard.com'] showEmailDialog={showEmailDialog} setHideEmail={setHideEmail} />
 */
const Email = (props) => {

  /* useStates */
  const [recipients, setRecipients] = useState([]);
  const [ccRecipients, setCCRecipients] = useState([]);
  const [bccRecipients, setBCCRecipients] = useState([]);
  const [subject, setSubject] = useState('');
  const [messageBody, setMessageBody] = useState('');

  const [selectedDispatchDocuments, setSelectedDispatchDocuments] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [disableSend, setDisableSend] = useState(true);

  const emailSent = useRef(false);

  /* useEffects */

  // Message body and recipient are required to send an email
  useEffect(() => {
    if (!recipients || !recipients.length || !messageBody) {
      setDisableSend(true);
    } else {
      setDisableSend(false);
    }
  }, [recipients, messageBody]);

  // add pre-set information for email if any
  useEffect(() => {
    if (props.recipients && props.recipients.length > 0) {
      setRecipients(props.recipients);
    }

    if (props.ccRecipients && props.ccRecipients.length > 0) {
      setCCRecipients(props.ccRecipients);
    }

    if (props.bccRecipients && props.bccRecipients.length > 0) {
      setBCCRecipients(props.bccRecipients);
    }

    if (props.subject) {
      setSubject(props.subject);
    }

    if (props.messageBody) {
      setMessageBody(props.messageBody);
    }
  }, []);

  // add pre-selected dispatch documents if any
  useEffect(() => {
    if (props.selectedDispatchDocuments || props.selectedDispatchDocuments > 0) {
      setSelectedDispatchDocuments(props.selectedDispatchDocuments);
    }
  }, [props.selectedDispatchDocuments]);

  // If emailSent is true, prepare the email and send it
  useEffect(() => {
    if (!emailSent.current) {
      return;
    }

    async function sendEmail() {
      try {
        const sessionToken = getCurrentUserSessionToken();
        const userCompanyObjectId = getCurrentUserCompanyObjectId();
        const userCompanyObject = await getObjectById({ sessionToken }, 'Company', userCompanyObjectId);
        const userCompanyName = getAttribute(userCompanyObject, 'name');

        const noReplySenderName = userCompanyName;
        const noReplySenderEmail = 'noreply@onswitchboard.com';

        // convert emails to string so the email api can read them
        const recipientEmail = recipients.toString();
        const ccEmail = ccRecipients.toString();
        const bccEmail = bccRecipients.toString();
        const additionalOptions = { ccEmail, bccEmail };

        await send(noReplySenderName, noReplySenderEmail, recipientEmail, subject, undefined, messageBody, false, false, undefined, undefined, additionalOptions, selectedFiles);
      } catch (error) {
        console.log(error);
      }
    }

    sendEmail();
    refreshInformation();
  });

  /* File Upload Handler Functions */
  const fileUploadHandler = (e) => {
    for (let i = 0; i < e.files.length; i++) {
      const file = e.files[i];
      const filename = e.files[i].name.replace(/[^\w.]/g, ''); // Remove illegal characters from the file name
      const filetype = e.files[i].type;
      const parseFile = new Parse.File(filename, file, filetype);

      parseFile.save().then(() => {
        setSelectedFiles(selectedFiles => [...selectedFiles, parseFile]);
      }, (error) => {
        console.log(error);
      });
    }

    e.options.clear();
  };

  const fileRemoveHandler = (name) => {
    const newFiles = selectedFiles.filter((file) => {
      return file._name !== name;
    });

    setSelectedFiles(newFiles);
  };

  /* Other Functions */
  const attachDocumentsToMessageBody = () => {
    if (!selectedDispatchDocuments || !selectedDispatchDocuments.length > 0) {
      return;
    }

    let documents = '<b>Attached Documents:</b> <br>';

    selectedDispatchDocuments.map((document) => {
      const file = getAttribute(document.resource, 'file');
      documents += `<a href=${file._url}>${document.fullLabel}</a> <br>`;
    });

    setMessageBody(messageBody + documents);
  };

  // triggers sending the email and hide the email dialog when send is pressed
  // attaching documents is done here so that it's attached prior to the email being sent
  const triggerSendEmail = () => {
    attachDocumentsToMessageBody();
    props.setHideEmail();
    emailSent.current = true;
  };

  // Reset states for new email message
  const refreshInformation = () => {
    setRecipients([]);
    setCCRecipients([]);
    setBCCRecipients([]);
    setSubject('');
    setMessageBody('');

    props.resetSelectedDispatchDocuments();
    setSelectedFiles([]);

    setDisableSend(true);
    emailSent.current = false;
  };

  /* Components */
  const recipientsInputField = (
    <div className="p-inputgroup">
      <span className="p-inputgroup-addon">To</span>
      <Chips
        value={recipients}
        onChange={(e) => setRecipients(e.value)}
        allowDuplicate={false}
      />
    </div>
  );

  const ccRecipientsInputField = (
    <div className="p-inputgroup">
      <span className="p-inputgroup-addon">Cc</span>
      <Chips
        value={ccRecipients}
        onChange={(e) => setCCRecipients(e.value)}
        allowDuplicate={false}
      />
    </div>
  );

  const bccRecipientsInputField = (
    <div className="p-inputgroup">
      <span className="p-inputgroup-addon">Bcc</span>
      <Chips
        value={bccRecipients}
        onChange={(e) => setBCCRecipients(e.value)}
        allowDuplicate={false}
      />
    </div>
  );

  const subjectInputField = (
    <InputText value={subject} placeholder="Subject" onChange={(e) => setSubject(e.target.value)} />
  );

  const uploadedFilesDisplay = (
    <UploadedFiles
      files={selectedFiles}
      fileRemoveHandler={fileRemoveHandler}
    />
  );

  const editorToolbar = <EmailEditorToolbar fileUploadHandler={fileUploadHandler} />;
  const textEditor = (
    <Editor
      className="h-18rem mb-6"
      value={messageBody}
      onTextChange={(e) => setMessageBody(e.htmlValue)}
      headerTemplate={editorToolbar}
    />
  );

  const sendButton = (
    <div className="flex justify-content-end send-button">
      <Button icon="pi pi-send" onClick={() => triggerSendEmail()} disabled={disableSend} />
    </div>
  );

  /* Misc */
  const title = 'Email Message';

  return (
    <div>
      <Dialog
        className="sbc-email"
        header={title}
        visible={props.showEmailDialog}
        onHide={() => { props.setHideEmail(); }}
        resizeable={false}
        draggable={false}
      >

        <div className="grid">
          <div className="col-12 chips inputgroup">
            {recipientsInputField}
          </div>
          <div className="col-12 chips inputgroup">
            {ccRecipientsInputField}
          </div>
          <div className="col-12 chips inputgroup">
            {bccRecipientsInputField}
          </div>
          <div className="col-12 subject">
            {subjectInputField}
          </div>

          {selectedFiles && selectedFiles.length > 0 && uploadedFilesDisplay}

          <div className="col-12">
            {textEditor}
          </div>

          <div className="col-10">
            {props.dispatchDocumentsMultiSelect}
          </div>
          <div className="col-2">
            {sendButton}
          </div>
        </div>

      </Dialog>
    </div>
  );
};

export default Email;
