/** @deprecated - moved to sappy */
export const CurrencyTypes = Object.freeze({
  'CA': { short: 'CAD', country: 'Canada', name: 'Canadian Dollars', symbol: 'C$', index: 0 },
  'US': { short: 'USD', country: 'USA', name: 'US Dollars', symbol: '$', index: 1 },
});

export const getCurrency = (index) => {
  return Object.keys(CurrencyTypes)
    .filter(key => CurrencyTypes[key].index === index)
    .map((key) => CurrencyTypes[key])[0];
}