import React from 'react';

// CSAPI
import { PaymentStatus } from 'sb-csapi/dist/enums/Dispatch/PaymentStatus';

// sbCore Components
import Badge from 'sbCore/Badge/Badge';

// Styles
import './style.scss';

/**
* @description Renders a status badge associated with the given status int
* @param {Number} paymentStatusInt - The payment status number associated with a certain status badge.
* @param {Boolean} isVoided - Ignore the payment status and displayed a voided badge if true.
* @returns
*
* @example
* <PaymentStatusBadge paymentStatusInt={0} />
*/
function PaymentStatusBadge({ ...props }) {
  let badgeClassName;
  let value;

  // if status doesn't exist, default to unpaid
  // Duty Status:
  // 0: Unpaid
  // 1: Partially Paid
  // 2: Paid
  switch (props.paymentStatusInt) {
    case PaymentStatus.UNPAID.status:
      badgeClassName = 'unpaid';
      value = PaymentStatus.UNPAID.description.toUpperCase();
      break;

    case PaymentStatus.PARTIALLY_PAID.status:
      badgeClassName = 'partially-paid';
      value = PaymentStatus.PARTIALLY_PAID.description.toUpperCase();
      break;

    case PaymentStatus.PAID.status:
      badgeClassName = 'paid';
      value = PaymentStatus.PAID.description.toUpperCase();
      break;

    default:
      badgeClassName = 'unpaid';
      value = 'N/A';
  }

  if (props.isVoided) {
    badgeClassName = 'voided';
    value = 'Voided';
  }

  return (
    <div className="sbc-payment-status-badge">
      <Badge className={badgeClassName} value={value} />
    </div>
  );
}

export default PaymentStatusBadge;
