import React from 'react';
import PropTypes from 'prop-types';

// Components
import HelpDialog from 'components/HelpDialog/container/HelpDialog';

class ELDEditHelpDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const uListStyle = { marginTop: '1em', paddingLeft: '1.2em', fontSize: '.85em' };
    const listStyle = { marginBottom: '.5em' };

    const subUListStyle = { ...uListStyle, paddingBottom: '1em', fontSize: '1em' };
    const subListStyle = { marginBottom: '2em' };

    return (
      <HelpDialog title="Editing Logs" buttonStyle={this.props.buttonStyle}>
        <div style={{ marginTop: '1em', marginBottom: '2em' }}>
          How do I edit this day&#39;s logs?

          <div style={{ marginTop: '.5em', marginBottom: '1.5em', fontSize: '.75em' }}>
            * Dialog = The Edit Pop-up
            <br />
            * Daily Certification = The 24 Hour span of the Driver&#39;s day you are currently viewing
          </div>

          <div style={{ marginTop: '1em', marginBottom: '2em', fontSize: '.85em' }}>
            The following are explained here:
            <br /><br />
            - The first (most important) part simply explains how to edit logs and which fields are mandatory or optional for compliance
            <br />
            - The second part explains errors and keywords you may encounter due to noncompliant (FMCSA) information
            <br />
            - The final part explains how the flow works (in terms of what happens after sending edits to the driver and vice-versa)
          </div>

          <hr />

          <ol style={{ ...subUListStyle, listStyleType: 'upper-greek' }}>
            <li style={subListStyle}>
              <b>How to start editing</b>:
              <ol style={{ ...uListStyle, listStyleType: 'upper-greek' }}>
                <li style={listStyle}>
                  The ideal way to making an edit is to start from the top of the dialog and make your way to the bottom, although following this order is not necessary nor mandatory. Filling out all fields in the dialog are mandatory unless stated otherwise
                </li>
                <li style={listStyle}>
                  At the very top, you&#39;ll see a preview of the current state of the driver&#39;s Hours of Service graph, plus any edits
                  you&#39;ve added. Note that any edits you make involving Start Time, End Time, or Duty Status will automatically refresh your graph preview to reflect a preview of the changes (these are explained more below). So as you make your edit, you&#39;ll want to check the graph preview to make sure that&#39;s how you would want it to look like
                </li>
                <li style={listStyle}>
                  After the graph is the Edit Reason. This is a mandatory field required by you for FMCSA compliance and is the reason for
                  the edit. Depending on the type of logs you send/print out, this Edit Reason will appear on the printed log. You only need to enter this once per edit. You will be unable to submit your edit if you do not provide a reason - there will be a warning prompting you about it
                </li>
                <li style={listStyle}>
                  The next section contains the Start Time and End Time of the edit you want to make. For example, you might want to make an edit saying the driver was Off Duty from 2:00PM (Start Time) to 3:00PM (End Time). Both dates/times you select must be within the 24 hour span of the driver&#39;s day (known as the Daily Certification). Further, the Start Time must be less than the End Time. Should your Start Time and End Time not comply with these conventions, you will be prompted with a warning and unable to submit your edit
                </li>
                <li style={listStyle}>
                  The next section allows you to put a custom Start location and a custom End Location (corresponding to the Start Time and End Time). These fields are optional
                </li>
                <li style={listStyle}>
                  The next section is where you can select the Duty Status (from our example above, that would be selecting Off Duty after you set the Start Time to 2:00PM and End Time to 3:00PM)
                </li>
                <li style={listStyle}>
                  The last section are any Dispatcher/Driver notes you&#39;d like to add to the events you are adding (different from the Edit Reason where that&#39;s for the edit as a whole). Dispatcher/Driver notes are optional
                </li>
                <li style={listStyle}>
                  Finally, you have 2 options: Submitting the edit by clicking the <b>Request Change(s)</b> button or adding another edit on top of the existing one by clicking <b>Add Another Edit</b>. If you choose to add another edit on top of the one you currently want to make, you will be prompted to add another edit and you will also see a history of the edits (you want to make) so far - plus the ability to remove an edit if you made a mistake. The Edit Reason you gave at the start will remain constant throughout, unless you decide to change it
                </li>
                <li style={listStyle}>
                  If the day you are editing contains violations, the dialog will list them out for you at the very bottom so that you can adjust
                  your edit accordingly
                </li>
              </ol>
            </li>

            <li style={subListStyle}>
              <b>Errors &amp; Keywords</b>:

              <div style={{ marginTop: '1em', marginBottom: '2em', fontSize: '.85em' }}>
                You may encounter the following messages preventing you from submitting an edit. Here&#39;s a breakdown:
              </div>

              <ul style={uListStyle}>
                <li style={listStyle}>
                  <b>Edit Reason is Required</b>: The Edit Reason is still blank (FMCSA requirement)
                </li>
                <li style={listStyle}>
                  <b>Please Select a Duty Status</b>: A Duty Status is not yet chosen
                </li>
                <li style={listStyle}>
                  <b>Start Time must be before End Time</b>: The selected Start Time value is greater than the End Time
                </li>
                <li style={listStyle}>
                  <b>Selected Timeframe is outside the 24 Hour Period</b>: The selected Start Time is before the start of the driver&#39;s day you are editing, and/or the selected End Time is after the driver&#39;s day you are editing
                </li>
                <li style={listStyle}>
                  <b>Cannot edit AutoGenerated Driving Time</b>: If you are editing for a day in which the driver&#39;s device was in ELD mode (as opposed to an AOBRD mode), you are not allowed to edit over driving time automatically recorded by the device. You will not see this error if it was an AOBRD mode device (FMCSA requirement)
                </li>
                <li style={listStyle}>
                  <b>Cannot edit ahead of current Date/Time</b>: If you are editing for the current day, you cannot insert an edit ahead of what the current time is (ie. determining duty statuses in advance of when they actually happen)
                </li>
              </ul>
            </li>

            <li style={subListStyle}>
              <b>Edit Flow</b>:
              <div style={{ marginTop: '1em', marginBottom: '1em', fontSize: '.85em' }}>
                What happens when sending an edit from the <b>Dispatcher</b> to <b>Driver</b>:
              </div>
              <ol style={{ ...uListStyle, listStyleType: 'upper-greek' }}>
                <li style={listStyle}>
                  As soon as request the change, the edits you made get sent to the driver (technically, the driver&#39;s device)
                </li>
                <li style={listStyle}>
                  As per FMCSA rules: If the driver&#39;s device was in AOBRD mode for they day you are editing, the edit will be automatically accepted by the device and the driver&#39;s graph will immediately update. If the device was in ELD mode, the Driver will receive the edit through the device and be prompted to either accept it or reject it on their discretion
                </li>
                <li style={listStyle}>
                  In any case, after you have requested your edits, you will be able to see your edit request in a list under the HOS Graph
                  allowing you to view what the graph looked like before and after the edits
                </li>
              </ol>


              <div style={{ marginTop: '2em', marginBottom: '1em', fontSize: '.85em' }}>
                What happens when sending an edit from the <b>Driver</b> to <b>Dispatcher</b>:
              </div>
              <ol style={{ ...uListStyle, listStyleType: 'upper-greek' }}>
                <li style={listStyle}>
                  If the Driver requests an edit where their device is in ELD mode, their changes will be automatically accepted by the device with no prompt to the Dispatcher. However, if the device is in AOBRD mode, the Driver&#39;s edit request will be sent to the Dispatcher
                  where the Dispatcher will be prompted of the edit and have the option to Accept or Reject the changes
                </li>
                <li style={listStyle}>
                  AOBRD mode: If the Dispatcher accepts the Driver&#39;s edit, the HOS graph will immediately update. If the Dispatcher rejects the edit, nothing happens and the edit request is cancelled/rejected
                </li>
              </ol>

              <div style={{ marginTop: '2em', marginBottom: '1em', fontSize: '.85em' }}>
                Depending on the device mode and type of edit that was requested, you, as a dispatcher or driver, may only be limited to 1 edit request (which can comprise of multiple edits) until the other party accepts/rejects the edit (ex. if the Dispatcher makes an ELD, not AOBRD, edit to the Driver, they have to wait for the driver&#39;s confirmation)
              </div>

            </li>
          </ol>
        </div>
      </HelpDialog>
    );
  }
}

ELDEditHelpDialog.propTypes = {
  buttonStyle: PropTypes.object,
};

export default ELDEditHelpDialog;
