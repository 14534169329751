import React from 'react';

// sbCore
import SidebarCard from 'sbCore/Card/SidebarCard';
import Button from 'sbCore/Button/Button';

// css
import './style.scss';

/**
 * @description Renders geofence card on map sidebar
 * 
 * @param {string} className - class name for the most outer container
 * @param {object} geofence - geofence parse object
 * @param {object} addEditGeofence - {type, geofence} -> determine which mode geofence is in
 * @param {Function} setEditMode - sets addEditGeofence to edit mode
 * @param {Function} cancelEditMode - resets addEditGeofence to an empty object
 * @param {Function} selectGeofence - callback function for when a geofence is selected on the sidebar
 * @param {boolean} isActive - bool to determine if this geofence is active
 * @returns
 */

function GeofenceCard({ ...props }) {
  let className = 'geofence-card';
  if (props.className) {
    className += ` ${props.className}`;
  }

  // Handles button click
  function onGeofenceButtonClick(e) {
    // stopPropagation prevents edit button to highlight the card, needs to be changed
    e.stopPropagation();
    // If geofence is already in edit mode, then cancel
    // Otherwise enter edit mode
    if (props.addEditGeofence.type === 'edit' && props.addEditGeofence.geofence.id === props.geofence?.objectId) {
      props.cancelEditMode();
    } else if (!props.addEditGeofence.type) {
      props.selectGeofence();
      props.setEditMode();
    }
  }

  // Card body
  function generateCardBody() {
    const companyName = props.geofence?.companyName || '-';

    return (
      <div className="flex flex-column pt-2">
        <span className="text-xs">Company</span> 
        <span className="text-sm">{companyName}</span>
      </div>
    )
  }

  // Hide the edit button in collapsible content
  function generateCollapsibleContent() {
    const buttonClassName = 'pt-2 pb-2 mt-2';
    return (
      <>
        {!props.addEditGeofence.type &&
          <Button label="Edit" onClick={onGeofenceButtonClick} className={buttonClassName} />}
        {props.addEditGeofence.type === 'add' &&
          <Button label="Edit" disabled className={buttonClassName} />}
        {props.addEditGeofence.type === 'edit' && props.addEditGeofence.geofence.id === props.geofence?.objectId &&
          <Button label="Cancel" onClick={onGeofenceButtonClick} className={buttonClassName} />}
        {props.addEditGeofence.type === 'edit' && props.addEditGeofence.geofence.id !== props.geofence?.objectId &&
          <Button label="Edit" disabled className={buttonClassName} />}
      </>
    )
  }

  return (
    <div 
      onClick={() => props.selectGeofence()}
      className={className}
    >
      <SidebarCard
        isActive={props.isActive}
        cardTitleLeft={props.geofence?.name || '-'}
        body={generateCardBody()}
        expandedContent={generateCollapsibleContent()}
      />
    </div>
  )
}

export default GeofenceCard;
