import React, { useState } from 'react';
import uniqid from 'uniqid';

import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import DispatchingTable from 'components/Dispatch/DispatchingTable/DispatchingTable';
import DispatchingDrawer from 'components/Dispatch/DispatchingDrawer/DispatchingDrawer';
import DispatchLegDetails from 'components/Dispatch/DispatchLegDetails/DispatchLegDetails';
import DispatchRouteTimeline from 'components/Dispatch/DispatchRouteTimeline/DispatchRouteTimeline';
import DispatchAccessorialSummary from 'components/Dispatch/DispatchAccessorialSummary/DispatchAccessorialSummary';
import TabMenu from 'sbCore/TabMenu/TabMenu';

function DispatchingLayout({ ...props }) {
  const [isDispatchingDrawerOpen, setIsDispatchingDrawerOpen] = useState(false);
  const [selectedDispatchJobRowInformation, setSelectedDispatchJobRowInformation] = useState({});
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [refreshId, setRefreshId] = useState(undefined); // This is used to refresh the dispatching table after the dispatching drawer is closed

  // Tab items for the TabMenu
  const tabItems = [
    { label: 'Legs', icon: 'pi pi-fw pi-info-circle' },
    { label: 'Route', icon: 'pi pi-fw pi-map' },
  ];

  // Callback function when clicking on a row on the DispatchingTable
  function openDispatchingDrawer(data) {
    const { dispatchJob } = data;
    const dispatchTransferObjectIdArr = data.dispatchTransfers && Object.keys(data.dispatchTransfers);

    setSelectedDispatchJobRowInformation({
      dispatchJob,
      dispatchTransferObjectIdArr,
    });

    setIsDispatchingDrawerOpen(true);
  }

  function closeDispatchingDrawer() {
    setIsDispatchingDrawerOpen(false);
    setRefreshId(uniqid()); // Creates a new id to let the dispatching table that it needs to be updated
  }

  return (
    <div>
      <DispatchingDrawer
        isVisible={isDispatchingDrawerOpen}
        onHide={() => closeDispatchingDrawer()}
      >
        <p className="text-2xl font-bold">{selectedDispatchJobRowInformation.dispatchJob && getAttribute(selectedDispatchJobRowInformation.dispatchJob, 'batchId')}</p>
        <TabMenu model={tabItems} activeIndex={selectedTabIndex} onTabChange={(e) => setSelectedTabIndex(e.index)} className="mb-3" />
        {selectedTabIndex === 0 && (
          <>
            <DispatchLegDetails
              dispatchTransferObjectIdArr={selectedDispatchJobRowInformation.dispatchTransferObjectIdArr}
              dispatchJob={selectedDispatchJobRowInformation.dispatchJob}
            />
            <div className="card" style={{ backgroundColor: 'white' }}>
              <DispatchAccessorialSummary className="dispatching-accessorial-summary" dispatchJob={selectedDispatchJobRowInformation.dispatchJob} />
            </div>
          </>
        )}
        {selectedTabIndex === 1 && (
          <DispatchRouteTimeline
            dispatchTransferObjectIdArr={selectedDispatchJobRowInformation.dispatchTransferObjectIdArr}
            dispatchJob={selectedDispatchJobRowInformation.dispatchJob}
          />
        )}
      </DispatchingDrawer>
      <DispatchingTable
        openDispatchingDrawer={openDispatchingDrawer}
        refreshContent={refreshId}
        updatedDispatchJob={selectedDispatchJobRowInformation.dispatchJob}
      />
    </div >
  );
}

export default DispatchingLayout;
