import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// sbCore Components
import InputLabel from 'sbCore/InputLabel/InputLabel';

// Components
import TrailerAutocompleteInput from 'sbCore/TrailerAutocomplete/TrailerAutocompleteInput';
import TrailerCard from 'sbCore/TrailerAutocomplete/TrailerCard';

// Styling
import './style.scss';

/**
 * @description Contains an input to filter trailers belonging to a company, and the option to select one
 * @param {String} className - Custom container className
 * @param {Object} style - Custom inline styles
 * @param {Trailer} trailer - Existing trailer object
 * @param {Function} onSelectTrailer - Callback function that sets selected trailer
 * @param {bool} warning - Makes the autocomplete border yellow
 * @param {bool} autoFocus - Whether to automatically focus on the input on load
 * @param {bool} disabled - Whether to disable the input
 * @param {bool} isLoading - Whether to show a loading indicator
 * @param {bool} hideLabel - Whether to hide the label of the autocomplete
 * @returns
 *
 * @example
 * <TrailerAutocomplete warning />
 */

function TrailerAutocomplete({ ...props }) {
  // ** useStates ** //
  const [trailer, setTrailer] = useState(undefined);

  const [identifier] = useState(uniqid()); // for each of this component that exists on the same page, give it unique identifier for specific dom manipulation
  const [isLoading, setIsLoading] = useState(false); // local version is isLoading (in addition to props.isLoading)

  // ** useEffects ** //
  useEffect(() => {
    setTrailer(props.trailer);
  }, [props.trailer]);

  // ** Callbacks ** //
  const onSelectTrailer = (trailer) => {

    setTrailer(trailer);

    // add check for trailer to prevent accidental triggering of function
    if (trailer) {
      setTrailer(trailer);
      if (props.onSelectTrailer) props.onSelectTrailer(trailer);
    }
  };

  const onUnselectTrailer = () => {
    setTrailer(undefined);
    // pass in undefined instead of trailer (which is being set to undefined above) since the state might not be updated yet.

    // we do this over using a useEffect for convenience
    if (props.onSelectTrailer) props.onSelectTrailer(undefined);
  };

  // ** Components ** //
  const label = 'Trailer';

  const trailerAutocompleteField = (
    <>
      {!props.hideLabel && <InputLabel>{label}</InputLabel>}
      <TrailerAutocompleteInput
        autoFocus={props.autoFocus}
        warning={props.warning}
        disabled={props.disabled}
        onSelectTrailer={(trailer) => onSelectTrailer(trailer)}
      />
    </>
  );

  const trailerCard = (
    <TrailerCard trailer={trailer} onUnselectTrailer={onUnselectTrailer} />
  );

  const loadingTrailerCard = (
    <TrailerCard isLoading={props.isLoading || isLoading} />
  );

  // ** Misc ** //
  let className = `trailer-autocomplete ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className} style={{ ...props.style }}>

      {!props.isLoading && !trailer && trailerAutocompleteField}

      {!props.isLoading && trailer && trailerCard}

      {props.isLoading && loadingTrailerCard}

    </div>
  );
}

export default TrailerAutocomplete;
