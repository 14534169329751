import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import ReactTooltip from 'react-tooltip';
import { Table, TableHeader, TableBody, TableRow, TableRowColumn } from 'material-ui';

// API
import { formatMilliTime } from 'api/ELD';
import { light as lightColours } from 'api/Lists/Colours';
import * as HoursCycle from 'api/Lists/HoursCycle';
import * as Helpers from 'api/Helpers';

// CSS
import styles from './CycleHourCalcTableRow.module.scss';

// Assets
const check = require('assets/images/violations/check.png');

function CycleHourCalcTableRow(props) {
  const { driver, recapDayObj, index } = props;

  const timezoneOffsetFromUTC = (driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();
  return (
    <TableRow
    // className={recapDayObj.reset === true ? styles.dayReset : undefined}
    >
      <TableRowColumn style={{ textAlign: 'center' }}>
        <b>
          <span>
            {moment(recapDayObj.date).tz(timezoneOffsetFromUTC).format('LL')}
          </span>
        </b>
      </TableRowColumn>

      <TableRowColumn style={{ textAlign: 'center' }}>
        {formatMilliTime(recapDayObj.onDutyConsumed)}
        {/* {Helpers.convertMillisecondsToHours(recapDayObj.onDutyConsumed).toFixed(2)} */}
      </TableRowColumn>

      <TableRowColumn style={{ textAlign: 'center' }}>
        {(recapDayObj.workedInCanadaBool && recapDayObj.workedInUSBool) ? <span>Canada, USA</span> : null}
        {(recapDayObj.workedInUSBool && !recapDayObj.workedInCanadaBool) ? <span>USA</span> : null}
        {(recapDayObj.workedInCanadaBool && !recapDayObj.workedInUSBool) ? <span>Canada</span> : null}
      </TableRowColumn>

      <TableRowColumn style={{ paddingLeft: '0', paddingRight: '0', textAlign: 'center', backgroundColor: lightColours[recapDayObj.cycleCountCA % lightColours.length] }}>
        {recapDayObj.resetCABool === true &&
          (
            <span style={{ paddingRight: '1em' }} data-tip data-for={`${index}${recapDayObj.date.toISOString()}ca`}>
              <ReactTooltip
                className={styles.resetTooltip}
                effect="solid"
                offset={{ left: -9 }}
                id={`${index}${recapDayObj.date.toISOString()}ca`}
              >
                <strong>{t('Reset (Canada) completed')}</strong>
              </ReactTooltip>
              <img height="20em" style={{ display: 'inline' }} src={check} alt="" />
            </span>
          )
        }
        {formatMilliTime(recapDayObj.cycleHoursRemainingCA)}
      </TableRowColumn>

      <TableRowColumn style={{ paddingLeft: '0', paddingRight: '0', textAlign: 'center', backgroundColor: lightColours[recapDayObj.cycleCountUS % lightColours.length] }}>
        {recapDayObj.resetUSBool === true &&
          (
            <span style={{ paddingRight: '1em' }} data-tip data-for={`${index}${recapDayObj.date.toISOString()}us`}>
              <ReactTooltip
                className={styles.resetTooltip}
                effect="solid"
                offset={{ left: -9 }}
                id={`${index}${recapDayObj.date.toISOString()}us`}
              >
                <strong>{t('Reset (US) completed')}</strong>
              </ReactTooltip>
              <img height="20em" style={{ display: 'inline' }} src={check} alt="" />
            </span>
          )
        }
        {formatMilliTime(recapDayObj.cycleHoursRemainingUS)}
      </TableRowColumn>
    </TableRow >
  );
}

CycleHourCalcTableRow.propTypes = {
  recapDayObj: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  driver: PropTypes.object.isRequired,
};

export default CycleHourCalcTableRow;
