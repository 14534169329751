import React from 'react';
import PropTypes from 'prop-types';

// API
import { getDiagnosticCodeDescription } from 'api/Getters';

// Components

import styles from './VehicleDiagnosticsDescription.module.css';

class VehicleDiagnosticsDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codeDescription: '',
    }
  }

  async componentDidMount() {
    if (this.props.code) {
      try {
        const codeDescription = await getDiagnosticCodeDescription(this.props.code);
        this.setState({ ...this.state, codeDescription })
      } catch (e) {
        console.log(e);
        this.setState({ ...this.state, codeDescription: '' })
      }
    }
  }

  render() {
    return (
      <div className={styles.description}>{this.state.codeDescription}</div>
    );
  }
}

export default VehicleDiagnosticsDescription;
