import React from 'react';
import PropTypes from 'prop-types';

// Actions

// API
import Getters from 'api/Getters';

// Components

// CSS
import styles from './GetUserTypeScreen.module.scss';

// Lists

class GetUserTypeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.intro}>
          Try Switchboard for free today
          <br />
          <span>Explore our ELD cloud platform and what it can do for your fleet.</span>
        </div>

        <div className={styles.choices}>

          <div className={styles.prompt}>
            I'm a:
          </div>

          <div className={styles.choice} onClick={() => this.props.handleUserType('fleet')}>
            <div>
              <div className={styles.icon}>
                <i className="material-icons">check</i>
              </div>
              <div className={styles.title} >
                Fleet or Safety Manager
                {/* <br />
                <span>Two or more vehicles</span> */}
              </div>
            </div>
          </div>

          <div className={styles.choice} onClick={() => this.props.handleUserType('oo')}>
            <div>
              <div className={styles.icon} >
                <i className="material-icons">check</i>
              </div>
              <div className={styles.title} >
                Owner-Operator
              </div>
            </div>
          </div>
        </div>

        <div>
            <small><span>Already have a Switchboard account? <a href={'/login'}>Sign in.</a></span></small>
        </div>
      </div>
    );
  }
}

GetUserTypeScreen.propTypes = {
  handleUserType: PropTypes.func.isRequired,
};

export default GetUserTypeScreen;
