import React from 'react';

import { ScrollPanel as PRScrollPanel } from 'primereact/scrollpanel';

// Styling
import './style.scss';

/**
 * @description PrimeReact ScrollPanel component
 * @param {String} className - Custom container className
 */
function ScrollPanel({ ...props }) {
  let className = 'sbc-scrollpanel';
  if (props.className) className += ` ${props.className}`;

  return (<PRScrollPanel {...props} className={className} />);
}

export default ScrollPanel;