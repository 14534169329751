// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker__month-container, .react-datepicker-popper {
  /*Ensure that Datepicker works on modal*/
  z-index: 1051 !important;
}`, "",{"version":3,"sources":["webpack://./app/styles/_react-datepicker_overwrites.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,wBAAwB;AAC1B","sourcesContent":[".react-datepicker__month-container, .react-datepicker-popper {\n  /*Ensure that Datepicker works on modal*/\n  z-index: 1051 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
