import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Popup } from 'react-mapbox-gl';

// API
import { getPDispatcherPropertyFromState } from 'api/Getters';
import * as Helpers from 'api/Helpers';
import { convertDistance } from 'api/Helpers';
import { getGPSDirection } from 'api/GPS/GPS.js';
import { getCurrentUser, getAttribute } from 'api/Parse';

// enums
import { CountryCodeLocationDescriptionTypes } from 'enums/VehicleLocation';

// // DEBUGGIN
// import Parse from 'parse';

// CSS
import styles from './ComponentStyles.module.scss';

function getSpeedString(speedKm) {
  const distanceUnit = getPDispatcherPropertyFromState('distanceUnit');
  if (distanceUnit === 'km') {
    return `${parseFloat(speedKm.toFixed(0))} km/h`;
  }
  return `${parseFloat(convertDistance(speedKm, 'km', 'mi', true).toFixed(0))} mi/h`;
}

function GPSPointPopup(props) {
  let locationDescription = props.addressString || '';
  const vehicleLocation = props.vehicleLocation;
  const countryCode = ((vehicleLocation && vehicleLocation.get('countryCode')) || '').toUpperCase();
  if (countryCode === 'US') {
    if (getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.US, true)) {
      const locationDescriptionUSArr = getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.US, true).split(' ');
      const locationDescriptionStateProvince = locationDescriptionUSArr[2];
      const trueStateProvince = getAttribute(vehicleLocation, 'stateProvince', true);
      if (trueStateProvince !== locationDescriptionStateProvince.toLowerCase()) {
        const formattedLocationDescription = `${trueStateProvince?.toUpperCase()}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      } else {
        const locationDescriptionCity = locationDescriptionUSArr.slice(3, locationDescriptionUSArr.length).join(' ');
        const formattedLocationDescription = `${locationDescriptionCity}, ${locationDescriptionStateProvince}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      }
    }
  } else {
    if (getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.CA, true)) {
      const locationDescriptionCAArr = getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.CA, true).split(' ');
      const locationDescriptionStateProvince = locationDescriptionCAArr[2];
      const trueStateProvince = getAttribute(vehicleLocation, 'stateProvince', true);
      if (trueStateProvince !== locationDescriptionStateProvince.toLowerCase()) {
        const formattedLocationDescription = `${trueStateProvince?.toUpperCase()}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      } else {
        const locationDescriptionCity = locationDescriptionCAArr.slice(3, locationDescriptionCAArr.length).join(' ');
        const formattedLocationDescription = `${locationDescriptionCity}, ${locationDescriptionStateProvince}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      }
    }
  }

  let tempCelsius = props.vehicleLocation && props.vehicleLocation.get('tempCelsius');
  let batteryRemaining = props.vehicleLocation && props.vehicleLocation.get('batteryRemaining');
  // if (countryCode && vehicleLocation.get(CountryCodeLocationDescriptionTypes[countryCode])) {
  //   locationDescription = vehicleLocation.get(CountryCodeLocationDescriptionTypes[countryCode]);
  // }
  const currentUser = getCurrentUser();
  const userSpecialPermission = getAttribute(currentUser, 'userSpecialPermission');
  // if the company is not granted access to the driver module
  const disableMapSpeed = userSpecialPermission && getAttribute(userSpecialPermission, 'disableMapSpeed');

  if (window.location.host.indexOf('app.localhost') !== -1) {
    console.log(props.vehicleLocation);
  }

  return (
    <Popup
      anchor="bottom"
      offset={{ bottom: [0, -7] }}
      coordinates={props.coordinates}
      className={styles.popup}
    >
      {props.vehicleLocation.get('speedKm') !== undefined &&
        (
          <div>
            {props.vehicleLocation.get('speedKm') !== undefined &&
              (
                <div className="inlineBlock">
                  {!disableMapSpeed && <span><span className="glyphicon glyphicon-dashboard" /> <i className="material-icons">speed</i><kbd>{getSpeedString(props.vehicleLocation.get('speedKm'))}</kbd></span>}
                  &nbsp;
                  {props.vehicleLocation.get('gpsHeading') !== 0 && getGPSDirection(props.vehicleLocation.get('gpsHeading')).direction &&
                    (
                      <span>
                        <span className="glyphicon glyphicon-dashboard" />
                        <i className="material-icons">near_me</i>
                        <kbd>{getGPSDirection(props.vehicleLocation.get('gpsHeading')).direction}</kbd>
                      </span>
                    )
                  }
                  &nbsp;
                  {tempCelsius !== null && tempCelsius !== undefined &&
                    (
                      <span>
                        <span className="glyphicon glyphicon-dashboard" />
                        <i className="material-icons">device_thermostat</i>
                        <kbd>{`${Math.round(Helpers.convertTemperature(tempCelsius, 'c', 'f'))}°F`}</kbd>
                      </span>
                    )
                  }
                  &nbsp;
                  {batteryRemaining !== null && batteryRemaining !== undefined &&
                    (
                      <span>
                        <span className="glyphicon glyphicon-dashboard" />
                        <i className="material-icons">
                          {batteryRemaining > 0 && batteryRemaining <= 20 &&
                            'battery_1_bar'
                          }
                          {batteryRemaining > 20 && batteryRemaining <= 40 &&
                            'battery_2_bar'
                          }
                          {batteryRemaining > 40 && batteryRemaining <= 60 &&
                            'battery_4_bar'
                          }
                          {batteryRemaining > 60 && batteryRemaining <= 80 &&
                            'battery_5_bar'
                          }
                          {batteryRemaining > 80 && batteryRemaining <= 100 &&
                            'battery_full'
                          }
                        </i>
                        <kbd>{`${batteryRemaining}%`}</kbd>
                      </span>
                    )
                  }
                  <span></span>
                </div>
              )
            }
            <br />
          </div>
        )
      }

      {locationDescription &&
        <div>
          <i>{locationDescription}</i>
        </div>
      }

      <div><b>{moment(props.vehicleLocation.get('dateTime')).format('LL')}</b></div>
      <div>{moment(props.vehicleLocation.get('dateTime')).format('LT')}</div>
      {
        (
          window.location.host.indexOf('app-support') !== -1
          || window.location.host.indexOf('app.localhost') !== -1
        ) &&
        (
          <div>
            [SUPPORT DEBUG DATA:]
            <div><b>vehicleLocationId:</b> {props.vehicleLocation.id}</div>
            <div><b>dateTime:</b> {moment(props.vehicleLocation.get('dateTime')).format()}</div>
            <div><b>vehicleSerial:</b> {props.vehicleLocation.get('vehicleSerial')}</div>
            <div><b>source:</b> {props.vehicleLocation.get('source')}</div>
            <div><b>DOP:</b> {props.vehicleLocation.get('dop')}</div>
            <div><b>geocoderType:</b> {props.vehicleLocation.get('geocoderType')}</div>
            <div><b>vehicleVIN:</b> {props.vehicleLocation.get('vehicleVIN')}</div>
            <div><b>altSource:</b> {props.vehicleLocation.get('altSource')}</div>
            <div><b>altDop:</b> {props.vehicleLocation.get('altDop')}</div>

          </div>
        )
      }
    </Popup>
  );
}

GPSPointPopup.propTypes = {
  coordinates: PropTypes.array.isRequired,
  vehicleLocation: PropTypes.object.isRequired,
};

export default GPSPointPopup;
