import React, { useEffect, useState } from 'react';

// Enums
import { EquipmentTypes } from 'enums/Equipment'; // need to move to sb-csapi

// Components
import Button from 'sbCore/Button/Button';
import VehicleTrailerCard from 'components/Map/Sidebar/VehicleTrailerCard/VehicleTrailerCard';

// Styling
import './style.scss';

/**
 * The container which holds a given Equipment Group's information and their associated VehicleTrailerCards
 *
 * @param {int} index - The index of the equipment group array
 * @param {Object} equipmentGroup - An object which represents the equipment group for a set of equipment
 * @param {EquipmentTypes} equipmentType - The currently selected equipment type (Vehicle/Trailer/Geofence)
 * @param {String} activeEquipment - The currently selected active equipment
 * @param {Function} setActiveEquipmentInformation - callback function for when an equipment is selected on the sidebar
 * @param {Function} handleOnEquipmentSelect - callback function for handling when a equipment is selected on the sidebar
 *
 * @returns {JSX} The equipment group elements for a particular group
 */
function EquipmentGroupCardList({ equipmentGroup, ...props }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  if (equipmentGroup) {
    return (
      <div className="equipment-group-card">
        <div className={`flex flex-row justify-content-between align-items-center mx-2${props.index !== 0 ? ' mt-4' : ' mt-1'}`}>
          <div className="card-group-title flex flex-row" onClick={() => setIsCollapsed(!isCollapsed)}>
            <div className="font-bold text-base uppercase text-800 ml-1">{equipmentGroup.equipmentGroupName}</div>
            <span className="expand-icon fadein material-icons text-500">{isCollapsed ? 'expand_more' : 'expand_less'}</span>
          </div>
          {equipmentGroup.equipmentGroupObjectId !== 'default' && (
            <Button
              className="p-button-secondary p-button-text shadow-none"
              tooltip={`Edit Group: ${equipmentGroup.equipmentGroupName}`}
              sbVariant="slim"
              icon={<span className="material-icons">edit</span>}
              onClick={() => props.onEquipmentGroupEdit(equipmentGroup.equipmentGroupObjectId)}
            />
          )}
        </div>
        {props.equipmentType === EquipmentTypes.VEHICLE && (
          <>
            {!isCollapsed && equipmentGroup.vehicles.length === 0 &&
              <div className="mx-3 py-3 text-500">{`No vehicles found for ${equipmentGroup.equipmentGroupName}.`}</div>}
            {!isCollapsed && equipmentGroup.vehicles.length > 0 && equipmentGroup.vehicles.map((vehicleInformation) => (
              <VehicleTrailerCard
                className="my-2 ml-2"
                key={vehicleInformation.objectId}
                isVehicle
                vehicleTrailerObj={vehicleInformation}
                handleSetActiveEquipmentInformation={() => props.setActiveEquipmentInformation(vehicleInformation)}
                onClick={() => props.handleOnEquipmentSelect(vehicleInformation)}
                isActive={vehicleInformation.unitId === props.activeEquipment}
              />
            ))}
          </>
        )}
        {props.equipmentType === EquipmentTypes.TRAILER && (
          <>
            {!isCollapsed && equipmentGroup.trailers.length === 0 &&
              <div className="mx-3 py-3 text-500">{`No trailers found for ${equipmentGroup.equipmentGroupName}.`}</div>}
            {!isCollapsed && equipmentGroup.trailers.length > 0 && equipmentGroup.trailers.map((trailerInformation) => (
              <VehicleTrailerCard
                key={trailerInformation.objectId}
                className="my-2 ml-2"
                isTrailer
                vehicleTrailerObj={trailerInformation}
                handleSetActiveEquipmentInformation={() => props.setActiveEquipmentInformation(trailerInformation)}
                onClick={() => props.handleOnEquipmentSelect(trailerInformation)}
                isActive={trailerInformation.unitId === props.activeEquipment}
              />
            ))}
          </>
        )}
      </div>
    );
  }

  return <div />;
}

export default EquipmentGroupCardList;
