import React from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider } from 'react-stripe-elements';

// Actions

// API
// import * as Stripe from 'api/Stripe';

// Components
// import StartCheckout from './StartCheckout';

// CSS
import styles from './UpgradeLayout.module.scss';

/**
 * @deprecated No longer used
 */
class StartLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      // <div>
      //   <div className={styles.callUs}>Have any questions? Give us a call at 1-844-535-3387 (1-844-5-FLEETS)</div>
      //   <div className={styles.title}>Let's get started.</div>
      //   <div className={styles.description}>Let's get started.</div>

      //   <StripeProvider apiKey={Stripe.getStripeKey()}>
      //     <Elements className="stripeElements">
      //       <StartCheckout />
      //     </Elements>
      //   </StripeProvider>
      // </div>
      <div />
    );
  }
}

StartLayout.propTypes = {
};

export default StartLayout;
