import React from 'react';
import PropTypes from 'prop-types';

// Components
import HelpDialog from 'components/HelpDialog/container/HelpDialog';

// Assets
import dutyChange from 'assets/images/change_duty.png';

class FuelHelpDialog extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <HelpDialog title="Fueling" buttonStyle={this.props.buttonStyle}>
        <div style={{ marginTop: '1em', marginBottom: '2em' }}>
          How do I view my vehicle's fueling?

          <div style={{ marginTop: '1em', marginBottom: '2em', fontSize: '.85em' }}>
            When your driver selects the <b>On Duty</b> status on their ELD, they can select the "Fueling" option.
            <br /><br />
            <img src={dutyChange} style={{ width: '400px' }} />
            <br /><br />
            They will be given the option to enter the volume fueled. The fueling amount will be recorded below.
          </div>
        </div>
      </HelpDialog>
    );
  }
}

FuelHelpDialog.propTypes = {
  buttonStyle: PropTypes.object,
};

export default FuelHelpDialog;
