import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import history from "../../../sbHistory";

// Actions
import { registerAdmin } from 'actions/User';

// api
import * as Helpers from 'api/Helpers';
import * as Analytics from 'api/Analytics';
import * as URL from 'api/URL';

// Components
import FleetRegisterScreen from '../view/FleetRegisterScreen';
import FleetInterestScreen from '../view/FleetInterestScreen';

// CSS
import styles from './FleetRegister.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

class FleetRegister extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conversionTracking: {
        clientId: props.clientId,
        utm_source: props.utm_source,
        referrer_url: props.referrer_url,
      },
      view: 'getUserDetails',
      registerProperties: {
        companyName: URL.getQueryParameter(props.location.search, 'company_name'),
        dotNumber: URL.getQueryParameter(props.location.search, 'dot_number'),
        mcNumber: URL.getQueryParameter(props.location.search, 'mc_number'),
        nscNumber: URL.getQueryParameter(props.location.search, 'company_name'),
        firstName: URL.getQueryParameter(props.location.search, 'first_name'),
        lastName: URL.getQueryParameter(props.location.search, 'last_name'),
        emailAddress: props.email || URL.getQueryParameter(props.location.search, 'email'),
        password: '',
        phoneNumber: URL.getQueryParameter(props.location.search, 'phone_number'),
      },
      interestProperties: {
        fleetSize: null,
        interests: [
          { name: 'Hours of Service', id: 'hos', value: URL.getQueryParameter(props.location.search, 'interest').includes('ELD') },
          { name: 'Driver Analytics', id: 'driverBehaviour', value: URL.getQueryParameter(props.location.search, 'interest').includes('Analytics') },
          { name: 'GPS/Geofencing', id: 'gps', value: URL.getQueryParameter(props.location.search, 'interest').includes('GPS') },
          { name: 'IFTA Automation', id: 'ifta', value: URL.getQueryParameter(props.location.search, 'interest').includes('IFTA') },
          { name: 'Asset Tracking', id: 'assetTrucking', value: URL.getQueryParameter(props.location.search, 'interest').includes('Tracking') },
          { name: 'Dashcam & Video', id: 'dashcamVideo', value: URL.getQueryParameter(props.location.search, 'interest').includes('Dashcam') },
          { name: 'Dispatch & Invoicing', id: 'dispatch', value: URL.getQueryParameter(props.location.search, 'interest').includes('Dispatch') },
          { name: 'Weigh Station Bypass', id: 'wsb', value: URL.getQueryParameter(props.location.search, 'interest').includes('Bypass') },
        ],
      },
      agreeTerms: false,
      registerError: '',
    };
    this.getValidationState = this.getValidationState.bind(this);
    this.handleRegisterPropertiesChange = this.handleRegisterPropertiesChange.bind(this);
    this.handleInterestPropertiesChange = this.handleInterestPropertiesChange.bind(this);
    this.register = this.register.bind(this);
  }

  componentDidMount() {
    document.title = 'Register - Switchboard';
  }

  getValidationState(property) {
    if (property === 'companyName' || property === 'firstName' || property === 'lastName') {
      if (this.state.registerProperties[property].length === 0) {
        return 'error';
      }
    } else if (property === 'emailAddress') {
      if (Helpers.isInvalidEmailFormat(this.state.registerProperties[property]) || (this.state.registerError && this.state.registerError.code === 202)) {
        return 'error';
      }
    } else if (property === 'password') {
      if (this.state.registerProperties[property].length < 8) {
        return 'error';
      }
    }
    return 'success';
  }

  handleRegisterPropertiesChange(property, value) {
    const newState = { ...this.state };
    newState.registerError = undefined;
    newState.registerProperties[property] = value;
    this.setState(newState);
  }

  handleInterestPropertiesChange(property, value) {
    const newState = { ...this.state };
    newState.interestProperties[index].value = value;
    this.setState(newState);
  }

  async register() {
    const newState = { ...this.state };
    newState.buttonClicked = true;
    this.setState(newState, () => {
      // // DEBUG
      // this.setState({ ...this.state, buttonClicked: true, view: 'fleetInterest', buttonClicked: false });

      const { companyName, firstName, lastName, emailAddress, password, dotNumber, mcNumber, nscNumber, phoneNumber } = this.state.registerProperties;

      if (
        this.getValidationState('companyName') === 'success' &&
        this.getValidationState('firstName') === 'success' &&
        this.getValidationState('lastName') === 'success' &&
        this.getValidationState('emailAddress') === 'success' &&
        this.getValidationState('password') === 'success' &&
        this.getValidationState('promoCode') === 'success'
      ) {
        Analytics.identify('anonymous', {
          companyName,
          email: emailAddress,
        });

        registerAdmin({
          companyName,
          dotNumber,
          mcNumber,
          nscNumber,
          firstName,
          lastName,
          emailAddress: emailAddress.toLowerCase(),
          phoneNumber,
          password,
        }).then((user) => {
          // Analytics
          if (user && user.get('belongsToCompany')) {
            Analytics.identifyUser(user);
          }

          history.push('/home');
          // this.setState({ ...this.state, view: 'fleetInterest', buttonClicked: false });
        }, (error) => {
          console.log(error);
          this.setState({ ...this.state, registerError: error });
        });
      }
    });
  }

  render() {
    return (
      <div className={styles.register}>
        {this.state.view === 'getUserDetails' &&
          <FleetRegisterScreen
            {...this.props}
            register={this.register}
            handleChange={this.handleRegisterPropertiesChange}
            getValidationState={this.getValidationState}
            registerError={this.state.registerError}
            agreeTerms={this.state.agreeTerms}
            registerProperties={this.state.registerProperties}
            handleAgreeTerms={() => this.setState({ ...this.state, agreeTerms: !this.state.agreeTerms })}
          />
        }
        {this.state.view === 'fleetInterest' &&
          <FleetInterestScreen
            interestProperties={this.state.interestProperties}
            handleChange={this.handle}
            launchSwitchboard={() => history.push('/home')}
          />
        }
      </div>
    );
  }
}

FleetRegister.propTypes = {
  User: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { User } = state;
  return {
    User,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(FleetRegister);
