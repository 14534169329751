/*
 * This file contains general getter functions useful throughout the app
 * Please list functions in alphabetical order and comment well unless
 * the function listing order depends on requisite functions (ex. define function before used)
 */

import Parse from 'parse';
import moment from 'moment-timezone';
import momentDurationFormat from 'moment-duration-format';
import store from '../store';
import * as Helpers from './Helpers';
import * as Setters from './Setters';
import QueryIncludeArr from 'api/Lists/QueryIncludeArr';
import { getAttribute, handleParseError } from '../api/Parse';

import User from 'sbObjects/User';
import Company from 'sbObjects/Company';
import UserSpecialPermission from 'sbObjects/UserSpecialPermission';
import UserNotificationSetting from 'sbObjects/UserNotificationSetting';

import { AdminTypes } from 'enums/UserTypes';

/** @module Getters */

/**
 * @memberof module:Getters
 * @returns
 */
function getEnvironment() {
  const storeState = store && store.getState();
  if (storeState && storeState.Main && storeState.Main.status === 'FETCH_USER_GLOBALS_SUCCESS') {
    return storeState.Main.env;
  }
  return 'dev';
}

/**
 * @memberof module:Getters
 * @returns
 */
function getCurrentUserCompany() {
  return Parse.User.current() && Parse.User.current().get('belongsToCompany');
}

/**
 * @memberof module:Getters
 * @returns
 */
async function getAllUsersFromCompany() {
  const userQuery = new Parse.Query(Parse.User);
  userQuery.equalTo('belongsToCompany', Parse.User.current().get('belongsToCompany'));
  const userQueryResults = await userQuery.find();
  return userQueryResults;
}

/**
 * @memberof module:Getters
 *
 * @param {*} documentIds
 * @param {*} uniqueId
 * @param {*} fileClass
 *
 * @returns
 */
function downloadDocuments(documentIds, uniqueId, fileClass) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('bundleSelectedDocuments', { class: fileClass, documentIds, userId: Parse.User.current().id, uniqueId, companyId: store.getState().Company.company.id }).then(
      parseObject => {
        resolve(parseObject);
      },
      err => {
        reject(err);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Getters
 *
 * @param {*} simObj
 * @param {*} subscriptionObjectId
 *
 * @returns
 */
function getUsageForSim(simObj, subscriptionObjectId) {
  return Parse.Cloud.run('getUsageForSim', {
    iccid: simObj.get('iccid'),
    subscriptionObjectId,
  });
}

/**
 * @memberof module:Getters
 *
 * @param {String} iccid
 *
 * @returns
 */
function getSimInfoByIccid(iccid) {
  return Parse.Cloud.run('getSimInfoByIccid', {
    iccid,
  });
}

/**
 * @memberof module:Getters
 *
 * @param {*} page
 * @param {*} limit
 * @param {*} filter
 * @param {*} sortBy
 * @param {*} className
 * @param {*} duplicateCheckProperty
 *
 * @returns
 */
function getLinkedObjects(page, limit, filter, sortBy, className, duplicateCheckProperty) {
  const promise = new Promise((resolve, reject) => {
    const company = getCurrentUserCompany();
    const companyLinkFilter = [
      {
        queryType: 'equalTo',
        name: 'parentCompany',
        value: company,
      }, {
        queryType: 'equalTo',
        name: 'authorized',
        value: true,
      },
    ];
    queryCompanyObjects('CompanyLink', undefined, undefined, companyLinkFilter, undefined, undefined, false, true).then((companyLinkArr) => {
      // Filter for company and child companies
      const queryPromises = [];
      for (let i = 0; i < companyLinkArr.length; i++) {
        queryPromises.push(queryCompanyObjects(
          className,
          page,
          limit,
          filter.concat({ queryType: 'equalTo', name: 'belongsToCompany', value: companyLinkArr[i].get('childCompany') }),
          sortBy,
          [], false, false
        ));
      }

      queryPromises.push(queryCompanyObjects(
        className,
        page,
        limit,
        filter,
        sortBy,
        []
      ));
      // Uses multiple filters because otherwise it overloads the server..?
      Promise.all(queryPromises).then((results) => {
        // Get rid of duplicates
        const sortedObjectArr = [].concat(...results).sort((a, b) => {
          if (sortBy && Object.keys(sortBy).length > 0) {
            const properties = Object.keys(sortBy);
            const aValue = a.get(properties[0]);
            const bValue = b.get(properties[0]);

            if (sortBy[properties[0]] === 'ascending') {
              return ((aValue > bValue) ? 1 : -1);
            } else {
              return ((aValue < bValue) ? 1 : -1);
            }
          } else {
            return 0;
          }
        });

        const filteredObjectArr = [];
        const sortedObjectIds = [];

        if (duplicateCheckProperty) {
          for (let i = 0; i < sortedObjectArr.length; i++) {
            const duplicateIndex = sortedObjectIds.indexOf(sortedObjectArr[i].get(duplicateCheckProperty));
            if (duplicateIndex === -1) {
              filteredObjectArr.push(sortedObjectArr[i]);
              sortedObjectIds.push(sortedObjectArr[i].get(duplicateCheckProperty));
            }
          }
        }
        resolve(duplicateCheckProperty ? filteredObjectArr : sortedObjectArr);
      }, error => {
        console.log(error);
        reject(error);
      });
    }, error => {
      reject(error);
    });
  });
  return promise;
}

/**
 * @memberof module:Getters
 *
 * @param {*} parseJobLinkArr
 * @param {*} documentIdArray
 *
 * @returns
 */
function downloadDocumentsFromJobLinks(parseJobLinkArr, documentIdArray) {
  const promise = new Promise((resolve, reject) => {
    const parseJobLinkArrLen = parseJobLinkArr.length;
    const jobActionIdArr = [];
    for (let i = 0; i < parseJobLinkArrLen; i++) {
      const jobActions = parseJobLinkArr[i].get('jobActions');
      const jobActionsLen = jobActions.length;
      for (let j = 0; j < jobActionsLen; j++) {
        jobActionIdArr.push(jobActions[j].id);
      }
    }
    Parse.Cloud.run('bundleDocuments', { jobActionIdArr, documentIdArray, userId: Parse.User.current().id, companyId: store.getState().Company.company.id }).then(
      parseObject => {
        resolve(parseObject);
      },
      err => {
        reject(err);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @param {*} parseReceiptArr
 * @returns
 */
function downloadReceipts(parseReceiptArr) {
  const promise = new Promise((resolve, reject) => {
    const parseReceiptArrLen = parseReceiptArr.length;
    const receiptIdArr = [];
    for (let i = 0; i < parseReceiptArrLen; i++) {
      receiptIdArr.push(parseReceiptArr[i].id);
    }
    Parse.Cloud.run('bundleReceipts', { receiptIdArr, userId: Parse.User.current().id, companyId: store.getState().Company.company.id }).then(
      parseObject => {
        resolve(parseObject);
      },
      err => {
        reject(err);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @returns
 */
async function fetchUserGlobals() {
  // Get User, UserSpecific*, Company
  const queries = [];
  const userQuery = new Parse.Query(Parse.User);
  userQuery.include(['belongsToCompany', 'belongsToCompany.address', 'belongsToCompany.companyModule', 'belongsToCompany.aobrdSetting', 'userNotificationSetting', 'userSpecialPermission']);
  userQuery.equalTo('objectId', Parse.User.current().id);
  const adminQuery = new Parse.Query('Admin');
  adminQuery.equalTo('user', Parse.User.current());
  const dispatcherQuery = new Parse.Query('Dispatcher');
  dispatcherQuery.equalTo('user', Parse.User.current());
  const safetyOfficerQuery = new Parse.Query('SafetyOfficer');
  safetyOfficerQuery.equalTo('user', Parse.User.current());
  const cloudUserPreferenceQuery = new Parse.Query('CloudUserPreference');
  cloudUserPreferenceQuery.equalTo('user', Parse.User.current());
  const subscriptionQuery = new Parse.Query('Subscription');
  subscriptionQuery.equalTo('company', Parse.User.current().get('belongsToCompany'));

  queries.push(userQuery.first());
  queries.push(adminQuery.first());
  queries.push(dispatcherQuery.first());
  queries.push(safetyOfficerQuery.first());
  queries.push(cloudUserPreferenceQuery.first());
  queries.push(subscriptionQuery.first());

  try {
    const results = await Promise.all(queries);
    let cloudUserPreference = results[4];
    if (!cloudUserPreference) {
      const parseInputObj = { user: Parse.User.current() };
      cloudUserPreference = await Setters.addRecord('CloudUserPreference', parseInputObj, Helpers.getCompanyReadWriteACL(Parse.User.current().get('belongsToCompany').id));
    }
    const responseObject = {
      user: results[0],
      company: results[0].get('belongsToCompany'),
      admin: results[1],
      dispatcher: results[2],
      safetyOfficer: results[3],
      cloudUserPreference,
      subscription: results[5],
    };
    return responseObject;
  } catch (e) {
    console.log(e);
  }
}

/**
 * @memberof module:Getters
 * @param {*} parseArr
 * @returns
 */
function fetchParseObjectArr(parseArr) {
  return Parse.Object.fetchAll(parseArr);
}

/**
 * @memberof module:Getters
 * @param {*} returnFromStore
 * @returns
 */
function getCurrentDispatcher(returnFromStore) {
  const promise = new Promise((resolve, reject) => {
    if (returnFromStore) {
      resolve(store.getState().Dispatcher.dispatcher);
    } else {
      const dispatcherQuery = new Parse.Query('Dispatcher');
      dispatcherQuery.equalTo('user', Parse.User.current());
      dispatcherQuery.include(['belongsToCompany', 'belongsToCompany.subscription']);
      dispatcherQuery.find().then(dispatcherObject => {
        resolve(dispatcherObject[0]);
      }, error => {
        reject(error);
      });
    }
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @returns
 */
function getCompanyDriverUsers() {
  const promise = new Promise((resolve, reject) => {
    const userQuery = new Parse.Query(Parse.User);
    userQuery.containedIn('userType', [4]);
    userQuery.equalTo('belongsToCompany', Parse.User.current().get('belongsToCompany'));
    userQuery.find().then(userObjectArr => {
      resolve(userObjectArr);
    });
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @returns
 */
function getCompanyNonDriverUsers() {
  const promise = new Promise((resolve, reject) => {
    // get all enabled users of valid user types and not drivers (admin, dispatcher, accountant, safety officer)
    const userOfTypeQuery = new Parse.Query(Parse.User);
    userOfTypeQuery.notContainedIn('userType', [4]);
    userOfTypeQuery.equalTo('belongsToCompany', Parse.User.current().get('belongsToCompany'));

    const dispatcherQuery = new Parse.Query('Dispatcher');
    dispatcherQuery.matchesQuery('user', userOfTypeQuery);
    dispatcherQuery.equalTo('enabled', true);
    dispatcherQuery.include(['user']);

    const safetyOfficerQuery = new Parse.Query('SafetyOfficer');
    safetyOfficerQuery.matchesQuery('user', userOfTypeQuery);
    safetyOfficerQuery.equalTo('enabled', true);
    safetyOfficerQuery.include(['user']);

    const accountantQuery = new Parse.Query('Accountant');
    accountantQuery.matchesQuery('user', userOfTypeQuery);
    accountantQuery.equalTo('enabled', true);
    accountantQuery.include(['user']);

    Promise.all([userOfTypeQuery.find(), dispatcherQuery.find(), safetyOfficerQuery.find(), accountantQuery.find()]).then(
      nonDrivers => {
        let nonDriverUsers = nonDrivers[0];

        const dispatchers = nonDrivers[1];
        const dispatcherUserIds = dispatchers.map(dispatcher => dispatcher.get('user').id);

        const safetyOfficers = nonDrivers[2];
        const safetyOfficerUserIds = safetyOfficers.map(safetyOfficer => safetyOfficer.get('user').id);

        const accountants = nonDrivers[3];
        const accountantUserIds = accountants.map(accountant => accountant.get('user').id);

        // now filter out nondriverusers for those who are admin only or contain one of the above user types user objectid
        const validUserObjectIds = Helpers.removeDuplicates(dispatcherUserIds.concat(safetyOfficerUserIds, accountantUserIds));

        // filter only those users who can be found in one of the nonDriver types (because nonDriverUsers is 'enabled'-agnostic)
        nonDriverUsers = nonDriverUsers.filter(user => {
          const userTypes = user.get('userType');
          if (
            userTypes &&
            userTypes.length === 1 &&
            (
              userTypes[0] === 0
              || userTypes[0] === 7
            )
          ) {
            return true;
          }
          if (validUserObjectIds.indexOf(user.id) !== -1) {
            return true;
          }
          return false;
        });

        resolve(nonDriverUsers);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @param {*} company
 * @returns
 */
function getChildCompanies(company) {
  const promise = new Promise((resolve) => {
    if (company.get('enableCompanyLinks')) {
      queryCompanyObjects('CompanyLink', undefined, undefined, [{ queryType: 'equalTo', name: 'parentCompany', value: company }], undefined, ['parentCompany', 'childCompany'], false, true).then((companyLinkArr) => {
        const childCompanyArr = [];
        for (let i = 0; i < companyLinkArr.length; i++) {
          childCompanyArr.push(companyLinkArr[i].get('childCompany'));
        }
        resolve(childCompanyArr);
      });
    } else {
      resolve([]);
    }
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @param {*} limitQueryKeyValuesObjectArray
 * @returns
 */
function getDocuments(limitQueryKeyValuesObjectArray) {
  const userQuery = new Parse.Query(Parse.User);
  userQuery.equalTo('objectId', Parse.User.current().id);
  const dispatcherQuery = new Parse.Query('Dispatcher');
  dispatcherQuery.matchesQuery('user', userQuery);
  const jobActionQuery = new Parse.Query('JobAction');
  jobActionQuery.matchesQuery('dispatcher', dispatcherQuery);
  const documentQuery = new Parse.Query('Document');
  documentQuery.matchesQuery('jobAction', jobActionQuery);
  documentQuery.exists('file');
  documentQuery.include(['jobLink', 'jobAction', 'uploadedBy', 'documentCategory']);
  documentQuery.descending('createdAt');
  if (limitQueryKeyValuesObjectArray) {
    const limitQueryKeyValuesObjectArrayLen = limitQueryKeyValuesObjectArray.length;
    for (let i = 0; i < limitQueryKeyValuesObjectArrayLen; i++) {
      documentQuery.containedIn(limitQueryKeyValuesObjectArray[i].key, limitQueryKeyValuesObjectArray[i].value);
    }
  }
  return documentQuery.find();
}

/**
 * @memberof module:Getters
 * @param {*} jobLink
 * @returns
 */
function getDocumentsFromJobLink(jobLink) {
  const jobActions = jobLink.get('jobActions');
  if (jobActions) {
    const userQuery = new Parse.Query(Parse.User);
    userQuery.equalTo('objectId', Parse.User.current().id);
    const dispatcherQuery = new Parse.Query('Dispatcher');
    dispatcherQuery.matchesQuery('user', userQuery);
    const jobActionQuery = new Parse.Query('JobAction');
    jobActionQuery.matchesQuery('dispatcher', dispatcherQuery);
    const jobActionIdArr = [];
    for (let i = 0; i < jobActions.length; i++) {
      jobActionIdArr.push(jobActions[i].id);
    }
    jobActionQuery.containedIn('objectId', jobActionIdArr);
    const documentQuery = new Parse.Query('Document');
    documentQuery.matchesQuery('jobAction', jobActionQuery);
    documentQuery.exists('file');
    documentQuery.include(['jobAction', 'uploadedBy', 'documentCategory']);
    documentQuery.descending('createdAt');
    return documentQuery.find();
  }
  return 0;
}

/**
 * @memberof module:Getters
 * @returns
 */
function getAllDocumentCategories() {
  const documentCategoryQuery = new Parse.Query('DocumentCategory');
  documentCategoryQuery.ascending('referenceInt');
  return documentCategoryQuery.find();
}

/**
 * @memberof module:Getters
 * @param {*} jobActionArr
 * @returns
 */
function getAllDriversFromJobActions(jobActionArr) {
  // NOTE: This is different from getAllJobDriversForJobActions
  // Function to get a list of all drivers from a list of job actions, NOT JobDrivers
  function isDuplicateDriver(driver, drivers) {
    // check to see if driver is already a part of drivers; note this is faster than running filter
    const driversLen = drivers.length;
    for (let i = 0; i < driversLen; i++) {
      if (driver.id === drivers[i].id) {
        return true;
      }
    }
    return false;
  }

  const drivers = [];
  const jobActionArrLen = jobActionArr.length;
  for (let i = 0; i < jobActionArrLen; i++) {
    const jobAction = jobActionArr[i];
    if (jobAction.get('jobDriver')) { // if there jobdriver is set for this jobaction
      const jobActionDrivers = jobAction.get('jobDriver').get('driver'); // get the drivers of the job action
      const driverLen = jobActionDrivers.length;
      for (let j = 0; j < driverLen; j++) {
        const currentDriver = jobActionDrivers[j];
        if (!isDuplicateDriver(currentDriver, drivers)) {
          drivers.push(currentDriver); // push it bb
        }
      }
    }
  }

  return drivers;
}

/**
 * @memberof module:Getters
 * @returns
 */
function getAllJobActions() {
  const userQuery = new Parse.Query(Parse.User);
  userQuery.equalTo('objectId', Parse.User.current().id);
  const dispatcherQuery = new Parse.Query('Dispatcher');
  dispatcherQuery.matchesQuery('user', userQuery);
  const jobActionQuery = new Parse.Query('JobAction');
  jobActionQuery.matchesQuery('dispatcher', dispatcherQuery);
  jobActionQuery.include(['jobDriver', 'jobActionType', 'jobCarryType', 'jobDriver.driver', 'jobDriver.driver.user']);
  return jobActionQuery.find();
}

/**
 * @memberof module:Getters
 * @param {*} page
 * @param {*} limit
 * @returns
 */
function getAllActiveJobActions(page, limit) {
  const userQuery = new Parse.Query(Parse.User);
  userQuery.equalTo('objectId', Parse.User.current().id);
  const dispatcherQuery = new Parse.Query('Dispatcher');
  dispatcherQuery.matchesQuery('user', userQuery);
  const jobActionQueryA = new Parse.Query('JobAction');
  jobActionQueryA.equalTo('completed', false);
  const jobActionQueryB = new Parse.Query('JobAction');
  jobActionQueryB.equalTo('completed', undefined);
  const jobActionQuery = Parse.Query.or(jobActionQueryA, jobActionQueryB);
  jobActionQuery.matchesQuery('dispatcher', dispatcherQuery);
  jobActionQuery.include(['jobDriver', 'jobActionType', 'jobCarryType', 'jobDriver.driver', 'jobDriver.driver.user']);
  if (page !== undefined && limit !== undefined) {
    jobActionQuery.limit(limit);
    jobActionQuery.skip(page * limit);
  }
  return jobActionQuery.find();
}

/**
 * @memberof module:Getters
 * @param {*} referenceParseObj
 * @param {*} fromDateMoment
 * @param {*} toDateMoment
 * @returns
 */
function getELDEvents(referenceParseObj, fromDateMoment, toDateMoment) {
  const ELDQuery = new Parse.Query('ELDEvent');
  ELDQuery.descending('eventDateTime');

  if (referenceParseObj.className === 'Driver') {
    ELDQuery.equalTo('driver', referenceParseObj);
  } else if (referenceParseObj.className === 'Vehicle') {
    ELDQuery.equalTo('vehicle', referenceParseObj);
  }

  ELDQuery.greaterThanOrEqualTo('eventDateTime', fromDateMoment.toDate());
  const dayAfter = new Date(toDateMoment.toDate());
  dayAfter.setDate(dayAfter.getDate() + 1);
  ELDQuery.lessThan('eventDateTime', dayAfter);
  return ELDQuery.find();
}

/**
 * @memberof module:Getters
 * @param {*} numericGuesstimates
 * @returns
 */
function getCarrierProfileScores(numericGuesstimates) {
  // WARNING: Guesstimate function
  // numericGuesstimates is a PDFer.getNumericEstimates() string on a table of carrier profile values
  let tableValues = numericGuesstimates.split(' ');
  tableValues = tableValues.filter(value => {
    const lastChar = value[value.length - 1];
    // Filter out empty string and any values ending with . or - (unterminated numbers)
    return value !== '' && lastChar !== '.' && lastChar !== '-';
  });

  // now that we have the array of values, map them into objects
  const profileCategories = [
    'average_fleet_size',
    'contraventions',
    'cvsa',
    'accidents',
    'total',
  ];

  const carrierScores = { _title: 'Carrier Scores' };
  const provincialScores = { _title: 'Provincial Scores' };
  const profileScores = [carrierScores, provincialScores];

  for (let i = 0; i < 5; i++) { // index 0-4 = carrier scores
    // carrier figures
    carrierScores[profileCategories[i]] = tableValues[i];
  }

  for (let j = 0; j < 5; j++) { // index 5-10 = carrier scores
    // carrier figures
    provincialScores[profileCategories[j]] = tableValues[j + 5];
  }

  return profileScores;
}

/**
 * @memberof module:Getters
 *
 * @param {*} showGeofenceEventNotifications
 * @param {*} showELDEditNotifications
 * @param {*} showHOSViolationNotifications
 *
 * @returns
 */
const getNotifications = async (showGeofenceEventNotifications, showELDEditNotifications, showHOSViolationNotifications) => {
  // const userQuery = new Parse.Query(Parse.User);
  // userQuery.equalTo('objectId', Parse.User.current().id);
  // console.log(Parse.User.current().id);
  const showNotificationClassArr = [];
  if (showGeofenceEventNotifications) showNotificationClassArr.push('GeofenceEvent');
  if (showELDEditNotifications) showNotificationClassArr.push('ELDEdit');
  if (showHOSViolationNotifications) showNotificationClassArr.push('HOSviolation');
  if (showNotificationClassArr.length === 0) {
    return [];
  }

  // Doing notificationClass queries after getting them because otherwise makes query slow
  const notificationRecipientQuery = new Parse.Query('NotificationRecipient');
  notificationRecipientQuery.equalTo('user', Parse.User.current());
  notificationRecipientQuery.descending('createdAt');
  const notificationRecipientArr = await notificationRecipientQuery.find();
  if (notificationRecipientArr.length === 0) return [];

  // const unreadNotificationQuery = new Parse.Query('Notification');
  // // unreadNotificationQuery.matchesQuery('recipientId', userQuery);
  // unreadNotificationQuery.containedIn('objectId', notificationRecipientArr.map((notificationRecipientObj) => notificationRecipientObj.get('notification').id));
  // unreadNotificationQuery.include(['senderId']);
  // unreadNotificationQuery.descending('createdAt');
  // unreadNotificationQuery.doesNotExist('read');

  // const readNotificationQuery = new Parse.Query('Notification');
  // // readNotificationQuery.matchesQuery('recipientId', userQuery);
  // readNotificationQuery.containedIn('objectId', notificationRecipientArr.map((notificationRecipientObj) => notificationRecipientObj.get('notification').id));
  // // readNotificationQuery.containedIn('recipientObjectIds', [Parse.User.current().id]);
  // readNotificationQuery.include(['senderId']);
  // readNotificationQuery.descending('createdAt');
  // readNotificationQuery.equalTo('read', true);

  // return Promise.all([unreadNotificationQuery.find(), readNotificationQuery.find()]).then((unreadAndReadNotifications) => {
  //   const notifications = [].concat(unreadAndReadNotifications[0], unreadAndReadNotifications[1]);
  //   // if the notification is an ELDEvent Out of Hours Warning that has expired (1 hour from createdAt)
  //   // do not show it and remove it from the database
  //   const filteredNotifications = [];
  //   for (let i = 0; i < notifications.length; i++) {
  //     const notification = notifications[i];
  //     if (notification.get('notificationClass') === 'ELDEvent') {
  //       const isHOSWarning = notification.get('text').toLowerCase().indexOf('warning') !== -1;
  //       const isExpired = ((new Date()).getTime() - notification.get('createdAt').getTime()) >= 3600000;
  //       if (isHOSWarning && isExpired) {
  //         // be currful
  //         notification.destroy();
  //       } else if (showNotificationClassArr.indexOf(notification.get('notificationClass')) !== -1) {
  //         filteredNotifications.push(notification);
  //       }
  //     } else if (showNotificationClassArr.indexOf(notification.get('notificationClass')) !== -1) {
  //       filteredNotifications.push(notification);
  //     }
  //   }
  //   return filteredNotifications;
  // });

  // Show all notifications, not splitting read & unread
  const notificationQuery = new Parse.Query('Notification');
  // readNotificationQuery.matchesQuery('recipientId', userQuery);
  notificationQuery.containedIn('objectId', notificationRecipientArr.map((notificationRecipientObj) => notificationRecipientObj.get('notification').id));
  // readNotificationQuery.containedIn('recipientObjectIds', [Parse.User.current().id]);
  notificationQuery.include(['senderId']);
  notificationQuery.descending('createdAt');

  return notificationQuery.find().then((notifications) => {
    // if the notification is an ELDEvent Out of Hours Warning that has expired (1 hour from createdAt)
    // do not show it and remove it from the database
    const filteredNotifications = [];
    for (let i = 0; i < notifications.length; i++) {
      const notification = notifications[i];
      if (notification.get('notificationClass') === 'ELDEvent') {
        const isHOSWarning = notification.get('text').toLowerCase().indexOf('warning') !== -1;
        const isExpired = ((new Date()).getTime() - notification.get('createdAt').getTime()) >= 3600000;
        if (isHOSWarning && isExpired) {
          // be currful
          notification.destroy();
        } else if (showNotificationClassArr.indexOf(notification.get('notificationClass')) !== -1) {
          filteredNotifications.push(notification);
        }
      } else if (showNotificationClassArr.indexOf(notification.get('notificationClass')) !== -1) {
        filteredNotifications.push(notification);
      }
    }
    return filteredNotifications;
  });
}

/**
 * @memberof module:Getters
 * @param {*} jobActionArr
 * @returns
 */
function getAllJobDriversForJobActions(jobActionArr) {
  const jobDrivers = [];
  for (let i = 0; i < jobActionArr.length; i++) {
    jobDrivers.push(jobActionArr[i].get('jobDriver'));
  }
  return [].concat(...jobDrivers);
}

/**
 * @memberof module:Getters
 * @returns
 */
async function getResellerCompany() {
  if (!store.getState().Company.company || !store.getState().Company.company.id) return null;
  const promoCode = store.getState().Company.company.get('promoCode');
  if (promoCode) {
    const resellerCompanyQuery = new Parse.Query('ResellerCompany');
    resellerCompanyQuery.equalTo('promoCode', promoCode);
    return await resellerCompanyQuery.first();
  }
  return null;
}

/**
 * @memberof module:Getters
 *
 * @param {*} parseClass
 * @param {*} page
 * @param {*} limit
 * @param {*} filter
 * @param {*} sortBy
 * @param {*} includeArr
 * @param {*} onlyOneBool
 * @param {*} skipCompanyQuery
 * @param {*} companyId
 * @param {*} queryAll
 *
 * @returns
 */
function queryCompanyObjects(parseClass, page, limit, filter, sortBy, includeArr, onlyOneBool, skipCompanyQuery, companyId, queryAll) {
  // Filter is arr of objects format: { name: *property name*, queryType: *query type*, value: *query value* }
  // Get query and their info
  const promise = new Promise((resolve, reject) => {
    if (!Parse.User.current()) return resolve(onlyOneBool ? null : []);

    const query = new Parse.Query(parseClass);
    if (!skipCompanyQuery) {
      const companyQuery = new Parse.Query('Company');
      companyQuery.equalTo('objectId', Parse.User.current().get('belongsToCompany').id);
      if (parseClass.toLowerCase() === 'eldevent') {
        query.matchesQuery('company', companyQuery);
      } else {
        query.matchesQuery('belongsToCompany', companyQuery);
      }
    }
    if (companyId) {
      const companyQuery = new Parse.Query('Company');
      companyQuery.equalTo('objectId', Parse.User.current().get('belongsToCompany').id);
      query.matchesQuery('belongsToCompany', companyQuery); // all vehicles of company
    }
    if (filter) {
      for (let i = 0; i < filter.length; i++) {
        if (filter[i]) {
          const attribute = { ...filter[i] };

          if (attribute.queryType === 'searchString') {
            attribute.value = attribute.value.toLowerCase();
          }
          if (attribute.name.indexOf('_lowercase') !== -1) {
            attribute.value = attribute.value.toLowerCase();
          }
          if (attribute.queryType === 'date') {
            if (attribute.fromValue) {
              query.greaterThanOrEqualTo(attribute.name, attribute.fromValue.toDate());
            }
            if (attribute.toValue) {
              query.lessThan(attribute.name, attribute.toValue.toDate());
            }
            if (!attribute.toValue && !attribute.fromValue) {
              query.greaterThanOrEqualTo(attribute.name, attribute.value.startOf('day').toDate());
              query.lessThan(attribute.name, attribute.value.endOf('day').toDate());
            }
          } else if (attribute.queryType === 'matchesQuery') {
            let innerQuery;
            if (attribute.value instanceof Parse.Query) {
              innerQuery = attribute.value;
            } else {
              innerQuery = new Parse.Query(attribute.innerQueryClass);
              innerQuery[attribute.innerQueryType](attribute.innerQueryProperty, attribute.innerQueryValue || attribute.value);
            }
            query.matchesQuery(attribute.name, innerQuery);
          } else if (attribute.queryType === 'notEqualTo') {
            query.notEqualTo(attribute.name, attribute.value);
          } else if (attribute.queryType === 'equalTo') {
            query.equalTo(attribute.name, attribute.value);
          } else if (attribute.queryType === 'lessThan') {
            query.lessThan(attribute.name, attribute.value);
          } else if (attribute.queryType === 'greaterThan') {
            query.greaterThan(attribute.name, attribute.value);
          } else if (attribute.queryType === 'lessThanOrEqualTo') {
            query.lessThanOrEqualTo(attribute.name, attribute.value);
          } else if (attribute.queryType === 'greaterThanOrEqualTo') {
            query.greaterThanOrEqualTo(attribute.name, attribute.value);
          } else if (attribute.queryType === 'doesNotExist') {
            query.doesNotExist(attribute.name);
          } else if (attribute.queryType === 'exists') {
            query.exists(attribute.name);
          } else if (attribute.queryType === 'containedIn') {
            query.containedIn(attribute.name, attribute.value);
          } else if (attribute.queryType === 'matches') {
            query.matches(attribute.name, attribute.value, 'i');
          } else if (attribute.queryType === 'distinct') {
            query.distinct(attribute.name, attribute.value);
          } else {
            query.contains(attribute.name, attribute.value);
          }
        }
      }
    }
    // now apply sorting if applicable
    if (sortBy) {
      const properties = Object.keys(sortBy);
      for (let i = 0; i < properties.length; i++) {
        const property = properties[i];
        const sortMethod = sortBy[property];
        if (sortMethod === 'ascending') {
          query.addAscending(property);
        } else if (sortMethod === 'descending') {
          query.addDescending(property);
        }
      }
    }
    if (limit) {
      query.limit(limit);
    }
    // lazy load
    if (page && limit) {
      query.skip(page * limit);
    }
    if (includeArr && includeArr.length !== 0) {
      query.include(includeArr);
    }
    if (queryAll) {
      getAllFromQuery(query).then(parseObjArr => {
        resolve(parseObjArr);
      }, error => {
        console.log(error);
        handleParseError(error);
        reject(error);
      });
    } else if (onlyOneBool) {
      query.first().then(parseObj => {
        resolve(parseObj);
      }, error => {
        console.log(error);
        handleParseError(error);
        reject(error);
      });
    } else {
      query.find().then(parseObjArr => {
        resolve(parseObjArr);
      }, error => {
        console.log(error);
        handleParseError(error);
        reject(error);
      });
    }
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @param {*} JobLink
 * @returns
 */
function getEarliestJobActionDate(JobLink) {
  let earliestDate = JobLink.get('jobActions')[0].get('actionDate');
  const jobActionsLen = JobLink.get('jobActions').length;
  for (let i = 1; i < jobActionsLen; i++) {
    const actionDate = JobLink.get('jobActions')[i].get('actionDate');
    if (actionDate < earliestDate) {
      earliestDate = actionDate;
    }
  }
  return earliestDate;
}

/**
 * @memberof module:Getters
 * @param {*} jobLinkStatusInt
 * @returns
 */
function isJobLinkComplete(jobLinkStatusInt) {
  return jobLinkStatusInt === 2;
}

/**
 * @memberof module:Getters
 *
 * @param {*} page
 * @param {*} limit
 * @param {*} filter
 * @param {*} sortBy
 *
 * @returns
 */
function getAllJobLinks(page, limit, filter, sortBy) {
  const promise = new Promise((resolve, reject) => {
    const companyQuery = new Parse.Query('Company');
    companyQuery.equalTo('objectId', Parse.User.current().get('belongsToCompany').id);
    const jobLinkQuery = new Parse.Query('JobLink');
    jobLinkQuery.matchesQuery('belongsToCompany', companyQuery);

    jobLinkQuery.include(['jobLinkStatus', 'jobEquipment', 'customer', 'jobActions', 'jobActions.jobDriver', 'jobActions.jobDriver.driver', 'jobActions.jobDriver.driver.user']);

    if (filter) {
      console.log(filter);
      const filterKeys = Object.keys(filter);

      for (let i = 0; i < filterKeys.length; i++) {
        const currentKey = filter[i].name;
        if (currentKey === 'fromDate') {
          jobLinkQuery.greaterThanOrEqualTo('jobAction_actionDate', filter[i].fromDate._d);
        } else if (currentKey === 'toDate') {
          jobLinkQuery.lessThan('jobAction_actionDate', filter[i].toDate._d);
        } else {
          jobLinkQuery.contains(currentKey, filter[i].value.toLowerCase());
        }
      }
    }

    // now apply sorting if applicable
    if (sortBy) {
      const property = Object.keys(sortBy)[0];
      const sortMethod = sortBy[property];

      if (sortMethod === 'ascending') {
        jobLinkQuery.ascending(property);
      } else if (sortMethod === 'descending') {
        jobLinkQuery.descending(property);
      }
    }

    // lazy load
    if (page !== undefined && limit !== undefined) {
      jobLinkQuery.limit(limit);
      jobLinkQuery.skip(page * limit);
    }

    jobLinkQuery.find().then(
      jobLinkObjects => {
        resolve(jobLinkObjects);
      },
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @param {*} driverObjectId
 * @returns
 */
function getDriverById(driverObjectId) {
  const driverQuery = new Parse.Query('Driver');
  driverQuery.equalTo('objectId', driverObjectId);
  driverQuery.include([
    'user',
    'dispatchers',
    'dispatchers.user',
    'belongsToCompany',
    'belongsToCompany.address',
    'belongsToCompany.subscription',
    'driverCompany',
    'driverStatus',
    'defaultVehicle',
    'vehicle',
    'vehicle.eldHardware',
    'vehicle.licensePlate',
    'latestELDEvent',
    'latestELDEvent.eldDailyCertification',
    'weighStationBypassDriver'
  ]);
  return driverQuery.first();
}

const getMostRecentEldEventForDriver = async (driverObjectId, includeArr) => {
  if (!driverObjectId) return null;
  const eldEventQuery = new Parse.Query('ELDEvent');
  const driverObj = new Parse.Object('Driver');
  driverObj.id = driverObjectId;
  eldEventQuery.equalTo('driver', driverObj);
  eldEventQuery.include([
    'eldDailyCertification',
  ]);
  eldEventQuery.descending('eventDateTime');
  return eldEventQuery.first();
}

/**
 * @memberof module:Getters
 * @param {*} referenceInt
 * @returns
 */
function getDriverStatuses(referenceInt) {
  // obtain all driver status objects or a specific one based on reference int
  const promise = new Promise((resolve, reject) => {
    const driverStatusQuery = new Parse.Query('DriverStatus');
    if (referenceInt >= 0) {
      driverStatusQuery.equalTo('referenceInt', referenceInt);
      driverStatusQuery.first().then(
        statusObject => resolve(statusObject),
        error => reject(error)
      );
    } else if (referenceInt === undefined) {
      driverStatusQuery.find().then(
        driverStatuses => resolve(driverStatuses),
        error => reject(error)
      );
    }
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @param {*} driverName
 * @param {*} page
 * @param {*} limit
 * @returns
 */
function getDriverTeams(driverName, page, limit) {
  // based on driverName, obtain teams ordered by occurences
  const promise = new Promise((resolve, reject) => {
    const lowerCaseDriverName = driverName.toLowerCase();
    const driverTeamQuery = new Parse.Query('DriverTeam');
    driverTeamQuery.equalTo('belongsToCompany', Parse.User.current().get('belongsToCompany'));
    driverTeamQuery.contains('user_fullName_driverA', lowerCaseDriverName);

    driverTeamQuery.descending('occurences');
    driverTeamQuery.include(['driverA', 'driverA.user', 'driverA.driverStatus']);
    driverTeamQuery.include(['driverB', 'driverB.user', 'driverB.driverStatus']);

    if (page !== undefined && limit !== undefined) {
      driverTeamQuery.limit(limit);
      driverTeamQuery.skip(page * limit);
    } else {
      driverTeamQuery.limit(1000);
    }

    driverTeamQuery.find().then(
      driverTeams => {
        resolve(driverTeams);
      },
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @deprecated
 * @param {*} driverObjectId
 * @param {*} page
 * @param {*} limit
 * @param {*} filter
 * @param {*} sortBy
 * @returns
 */
function getDriverReceipts(driverObjectId, page, limit, filter, sortBy) {
  // DEPRECATED
  const driverQuery = new Parse.Query('Driver');
  driverQuery.equalTo('objectId', driverObjectId);
  const receiptQuery = new Parse.Query('Receipt');
  receiptQuery.matchesQuery('driver', driverQuery);
  receiptQuery.include(['chassis', 'trailer', 'truck', 'receiptCategory']);

  if (filter) {
    const filterKeys = Object.keys(filter);
    for (let i = 0; i < filterKeys.length; i++) {
      const currentKey = filterKeys[i];

      if (currentKey === 'fromDate') {
        filter.fromDate._d.setHours(0, 0, 0, 0); // set the given date to the beginning of its day
        receiptQuery.greaterThanOrEqualTo('createdAt', filter.fromDate._d);
      } else if (currentKey === 'toDate') {
        filter.toDate._d.setHours(0, 0, 0, 0); // set the given date to the beginning of its day
        const dayAfter = new Date(filter.toDate._d);
        dayAfter.setHours(24, 0, 0, 0);
        receiptQuery.lessThan('createdAt', dayAfter);
      }
    }
  }

  const promise = new Promise((resolve, reject) => {
    if (Object.keys(sortBy).length === 0) {
      receiptQuery.descending('createdAt'); // default
    } else {
      const sortKeys = Object.keys(sortBy);
      const sortAttr = sortKeys[0];

      if (sortBy[sortAttr] === 'descending') {
        receiptQuery.descending(sortAttr);
      } else if (sortBy[sortAttr] === 'ascending') {
        receiptQuery.ascending(sortAttr);
      }
    }

    if (page !== undefined && limit !== undefined) {
      receiptQuery.limit(limit);
      receiptQuery.skip(page * limit);
    }

    receiptQuery.find().then(
      receiptObjects => {
        resolve(receiptObjects);
      },
      error => {
        reject(error);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @param {*} parseClass
 * @param {*} objectId
 * @returns
 */
function getRecord(parseClass, objectId) {
  let query;
  if (parseClass === 'User') {
    query = new Parse.Query(Parse.User);
  } else if (parseClass === 'Role') {
    query = new Parse.Query(Parse.Role);
  } else {
    query = new Parse.Query(parseClass);
  }
  return query.get(objectId);
}

/**
 * @memberof module:Getters
 * @returns
 */
function getCompanyPrefix() {
  const prefix = store.getState().Company.company.get('prefix');
  return prefix;
}

/**
 * @memberof module:Getters
 * @param {*} driverObjectId
 * @returns
 */
function getDispatchersFromDriver(driverObjectId) {
  // we use find() because driver list can be extremely long
  const driversState = store.getState().Driver.drivers;

  function checkDriver(driver) {
    return driver.id === driverObjectId;
  }

  const driver = driversState.find(checkDriver);

  return driver.get('dispatchers');
}

/**
 * @memberof module:Getters
 * @returns
 */
function getCompanyDispatchers() {
  const promise = new Promise(resolve => {
    const company = Parse.User.current().get('belongsToCompany');
    const dispatcherQuery = new Parse.Query('Dispatcher');
    dispatcherQuery.equalTo('belongsToCompany', company);
    dispatcherQuery.equalTo('enabled', true);
    dispatcherQuery.include(['user']);
    dispatcherQuery.find().then(
      dispatchers => {
        resolve(dispatchers);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @param {*} userObjectId
 * @returns
 */
function getDriverFromUserId(userObjectId) {
  const userQuery = new Parse.Query(Parse.User);
  const driverQuery = new Parse.Query('Driver');
  userQuery.equalTo('objectId', userObjectId);
  driverQuery.matchesQuery('user', userQuery);
  driverQuery.include('user');
  return driverQuery.first();
}

/**
 * @memberof module:Getters
 * @param {*} driverObjectId
 * @returns
 */
function getDriverIssues(driverObjectId) {
  // obtain all of given driver's contraventions and accidents
  const promise = new Promise((resolve, reject) => {
    const driverQuery = new Parse.Query('Driver');
    driverQuery.equalTo('objectId', driverObjectId);

    driverQuery.first().then(
      driverObject => {
        if (!driverObject) {
          resolve([]);
        } else {
          const contraventionQuery = new Parse.Query('Contravention');
          contraventionQuery.equalTo('driver', driverObject);
          const accidentQuery = new Parse.Query('Accident');
          accidentQuery.equalTo('driver', driverObject);

          Promise.all([contraventionQuery.find(), accidentQuery.find()]).then(
            results => {
              const contraventions = results[0];
              const accidents = results[1];
              const issues = [].concat(contraventions, accidents);
              resolve(issues);
            },
            error => reject(error)
          );
        }
      },
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @param {*} className
 * @param {*} objectId
 * @param {*} includeArr
 * @returns
 */
function getObjectById(className, objectId, includeArr) {
  const promise = new Promise((resolve, reject) => {
    const query = new Parse.Query(className);
    query.equalTo('objectId', objectId);
    if (includeArr) {
      query.include(includeArr);
    }
    query.first().then((obj) => {
      resolve(obj);
    }, (error) => {
      console.log(error);
      handleParseError(error);
      reject(error);
    });
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @param {*} notificationObject
 * @returns
 */
function getObjectsForNotification(notificationObject) {
  if (notificationObject.objectId) {
    if (notificationObject.class === 'Document') {
      const documentQuery = new Parse.Query('Document');
      documentQuery.include(['jobAction', 'uploadedBy', 'documentCategory']);
      return documentQuery.get(notificationObject.objectId);
    } else if (notificationObject.class === 'JobLink') {
      const jobLinkQuery = new Parse.Query('JobLink');
      jobLinkQuery.include(['jobLinkStatus', 'jobEquipment', 'customer', 'jobActions', 'jobActions.jobDriver', 'jobActions.jobDriver.driver', 'jobActions.jobDriver.driver.user']);
      return jobLinkQuery.get(notificationObject.objectId);
    } else if (notificationObject.class === 'Receipt') {
      const receiptQuery = new Parse.Query('Receipt');
      receiptQuery.include(['driver', 'driver.user', 'receiptCategory', 'trailer', 'truck']);
      return receiptQuery.get(notificationObject.objectId);
    } else if (notificationObject.class === 'VehicleLocation') {
      const vehicleLocationQuery = new Parse.Query('VehicleLocation');
      vehicleLocationQuery.include(['vehicle', 'vehicle.licensePlate', 'vehicle.vendor', 'jobAction']);
      return vehicleLocationQuery.get(notificationObject.objectId);
    } else if (notificationObject.class === 'ELDEdit') {
      const eldEditQuery = new Parse.Query('ELDEdit');
      return eldEditQuery.get(notificationObject.objectId);
    }
    const query = new Parse.Query(notificationObject.class);
    query.include(QueryIncludeArr[notificationObject.class]);
    return query.get(notificationObject.objectId);
  }

  return Promise.resolve(undefined);
}

/**
 * @memberof module:Getters
 * @param {*} objectid
 * @param {*} ParseClassName
 * @returns
 */
function getSingleParseObject(objectid, ParseClassName) {
  const ParseClass = Parse.Object.extend(ParseClassName);
  const query = new Parse.Query(ParseClass);
  return query.get(objectid);
}

/**
 * @memberof module:Getters
 * @param {*} property
 * @returns
 */
function getPDispatcherPropertyFromState(property) {
  // get attribute from the Dispatcher parse object in the Dispatcher state
  const storeState = store && store.getState();

  if (storeState && storeState.Dispatcher && storeState.Dispatcher.dispatcher && storeState.Dispatcher.status === 'FETCH_DISPATCHER_SUCCESS') {
    return storeState.Dispatcher.dispatcher.get(property);
  }
  return undefined;
}

/**
 * @memberof module:Getters
 * @param {*} vehicleObjectId
 * @returns
 */
function getVehicleById(vehicleObjectId) {
  const vehicleQuery = new Parse.Query('Vehicle');
  vehicleQuery.equalTo('objectId', vehicleObjectId);
  vehicleQuery.include(['vehicleLocation', 'drivers', 'belongsToCompany', 'eldHardware', 'weighStationBypassVehicle', 'licensePlate', 'visionVehicle']);
  return vehicleQuery.first();
}

/**
 * @memberof module:Getters
 * @returns
 */
function getVehicles(filterArr) {
  return queryCompanyObjects(
    'Vehicle',
    undefined,
    undefined,
    filterArr,
    { updatedAt: 'descending' },
    ['vehicleLocation', 'drivers', 'belongsToCompany', 'eldHardware', 'weighStationBypassVehicle', 'licensePlate', 'visionVehicle'],
    true,
    false,
    false,
    true
  );
}

/**
 * @memberof module:Getters
 * @param {*} user
 * @returns
 */
function isUserAdmin(user) {
  let currentUser = getCurrentUser(true);
  if (user) currentUser = user;
  const currentUserType = currentUser.userType;
  const adminUserTypes = Object.keys(AdminTypes).map(adminType => {
    return parseInt(AdminTypes[adminType].type);
  });

  for (let i = 0; i < currentUser.userType.length; i++) {
    const userType = currentUser.userType[i];
    if (adminUserTypes.indexOf(userType) !== -1) {
      return true;
    }
  }

  return false;
}

/**
 * @memberof module:Getters
 *
 * @param {*} query
 * @param {*} resultsLimit
 *
 * @returns
 */
function getAllFromQuery(query, resultsLimit = 1000) {
  // given a query, get all the objects pertaining to it
  let aggregateResults = []; // all results go in here
  let page = 0;

  function getAll(query, resolve) {
    query.limit(resultsLimit);
    query.find().then(results => {
      if (results.length === 0) {
        resolve(aggregateResults);
      } else {
        aggregateResults = aggregateResults.concat(results);
        if (results.length > resultsLimit) {
          resolve(results);
        } else {
          page++;
          query.skip(page * resultsLimit);
          getAll(query, resolve);
        }
      }
    }, error => {
      console.log(error);
      handleParseError(error);
    });
  }

  const promise = new Promise((resolve, reject) => {
    getAll(query, resolve);
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @param {*} dataString
 * @returns
 */
function getBase64Image(dataString) {
  const matches = dataString.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);
  const response = {};

  if (matches.length !== 3) {
    return new Error('Invalid input string');
  }

  response.type = matches[1];
  response.data = new Buffer(matches[2], 'base64');

  return response;
}

/**
 * @memberof module:Getters
 * @param {*} filterObject
 * @returns
 */
function getDrivers(filterObject) {
  // this is seperate from getDriversForState because getDriversForState may do extra processing. This is purely get all drivers
  if (!Parse.User.current()) return Promise.resolve([]);
  const promise = new Promise((resolve, reject) => {
    const driverQuery = new Parse.Query('Driver');
    driverQuery.notEqualTo('isHidden', true);
    driverQuery.equalTo('belongsToCompany', Parse.User.current().get('belongsToCompany'));
    driverQuery.ascending('user_fullName');

    if (filterObject) {
      const filterKeys = Object.keys(filterObject);
      for (let i = 0; i < filterKeys.length; i++) {
        const key = filterKeys[i];
        driverQuery.equalTo(key, filterObject[key]);
      }
    }

    driverQuery.include(['user', 'vehicle', 'vehicle.licensePlate', 'dispatchers', 'weighStationBypassDriver', 'latestELDEvent', 'latestELDEvent.vehicleLocation']);
    getAllFromQuery(driverQuery).then(
      drivers => resolve(drivers)
    );
  });

  return promise;
}

/**
 * @memberof module:Getters
 * @param {*} returnUserObject
 * @returns
 */
function getCurrentUser(returnUserObject) {
  const dbUser = Parse.User.current();

  if (returnUserObject) {
    const pBelongsToCompany = dbUser.get('belongsToCompany');
    const pUserSpecialPermission = dbUser.get('userSpecialPermission');
    const pUserNotificationSetting = dbUser.get('userNotificationSetting');

    const belongsToCompany = new Company(
      pBelongsToCompany.id,
      pBelongsToCompany.get('name'),
    );
    belongsToCompany.dbObject = pBelongsToCompany;

    let userSpecialPermission = pUserSpecialPermission ? new UserSpecialPermission(
      pUserSpecialPermission.id,
    ) : undefined;

    if (userSpecialPermission) {
      userSpecialPermission.dbObject = pUserSpecialPermission;
      userSpecialPermission = { ...pUserSpecialPermission.attributes, ...userSpecialPermission };
    }
    let userNotificationSetting = pUserNotificationSetting ? new UserNotificationSetting(
      pUserNotificationSetting.id,
      pUserNotificationSetting.get('enableSpeedViolation'),
      pUserNotificationSetting.get('enableHOSViolation'),
      pUserNotificationSetting.get('enableChatMessage'),
      pUserNotificationSetting.get('emailTo'),
    ) : undefined;

    if (userNotificationSetting) {
      userNotificationSetting.dbObject = pUserNotificationSetting;
      userNotificationSetting = { ...userNotificationSetting.attributes, ...userNotificationSetting };
    }

    const user = new User(
      dbUser.id,
      dbUser.get('username'),
      dbUser.get('firstName'),
      dbUser.get('lastName'),
      dbUser.get('email'),
      dbUser.get('phoneNumber'),
      dbUser.get('userType'),
      belongsToCompany,
      userSpecialPermission,
      userNotificationSetting
    );
    user.dbObject = dbUser;

    return user;
  }

  return dbUser;
}

/**
 * @memberof module:Getters
 *
 * @param {*} dateToConvert
 * @param {*} timezoneOffsetFromUTC
 *
 * @returns
 */
function getDateObjectScaledToTZ(dateToConvert, timezoneOffsetFromUTC) {
  /*
    Given a date (local) and timezone, return an object containing what that date/time would be in given timezone (24HR format)
    Example: Convert from PST (local) to America/Toronto
             Feb 16 22:00PST -> Feb 17 01:00EST
             Feb 16 22:00EST -> Feb 16 22:00EST
  */

  let dateToConvertScaled = moment(dateToConvert);

  if (timezoneOffsetFromUTC) {
    dateToConvertScaled = moment(dateToConvertScaled).tz(timezoneOffsetFromUTC);
  }

  const date = dateToConvertScaled.date();
  const month = dateToConvertScaled.month();
  const year = dateToConvertScaled.year();

  const hours = dateToConvertScaled.hours();
  const minutes = dateToConvertScaled.minutes();
  const seconds = dateToConvertScaled.seconds();
  const milliseconds = dateToConvertScaled.milliseconds();


  // offset: the difference (in ms) between dateToConvert and dateToConvertScaled (ie the scaled times offset from given time)
  // accounts for dst
  const dateToConvertOffset = moment(dateToConvert).utcOffset();
  const dateToConvertScaledOffset = moment(dateToConvertScaled).utcOffset();
  const offset = -1 * (dateToConvertOffset - dateToConvertScaledOffset) * 60000;

  const dateObject = {
    dateTime: dateToConvertScaled,
    utcTZString: moment.tz(dateToConvertScaled, 'YYYY-MM-DDTHH:mm:ss', timezoneOffsetFromUTC).format(), // the 'UTC' string from the TZ perspective
    date,
    month,
    year,
    hours,
    minutes,
    seconds,
    milliseconds,
    offset,
  };

  return dateObject;
}

/**
 * @memberof module:Getters
 * @param {*} imei
 * @returns
 */
function getSimByImei(imei) {
  const promise = new Promise((resolve, reject) => {
    const simQuery = new Parse.Query('Sim');
    simQuery.equalTo('imei', imei);
    simQuery.first().then(sim => {
      resolve(sim);
    }, error => reject(error));
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @deprecated
 * @param {*} drivers
 * @returns
 */
function getDriverPatternDurations(drivers) {
  // DEPRECATED
  const promise = new Promise((resolve, reject) => {
    const driverPatternDurationQuery = new Parse.Query('DriverPatternDuration');
    driverPatternDurationQuery.containedIn('driver', drivers);
    getAllFromQuery(driverPatternDurationQuery).then(
      driverPatternDurations => {
        resolve(driverPatternDurations);
      }
    );
  });
  return promise;
}

/**
 * @memberof module:Getters
 * @param {*} object
 * @returns
 */
function getObjectDeepCopy(object) {
  return JSON.parse(JSON.stringify(object));
}

/**
 * @memberof module:Getters
 * @param {*} classObject
 * @param {*} deepCopy
 * @returns
 */
function getClassObjectCopy(classObject, deepCopy) {
  // copies a class like Human(...) with all its properties; shallow copy by default
  return Object.assign(Object.create(Object.getPrototypeOf(classObject)), classObject)
}

/**
 * @memberof module:Getters
 * @param {*} userObjectId
 * @returns
 */
async function getUser(userObjectId) {
  const currentUser = getCurrentUser(true);
  const userQuery = new Parse.Query(Parse.User);
  userQuery.equalTo('belongsToCompany', currentUser.belongsToCompany.objectId);
  userQuery.equalTo('objectId', userObjectId);
  userQuery.include(['belongsToCompany', 'userSpecialPermission', 'userNotificationSetting']);
  const pUser = await userQuery.first();

  if (!pUser) {
    throw new Error(`Could not get user ${userObjectId} of same company`);
  }

  const pBelongsToCompany = pUser.get('belongsToCompany');
  const pUserSpecialPermission = pUser.get('userSpecialPermission');
  const pUserNotificationSetting = pUser.get('userNotificationSetting');

  const belongsToCompany = new Company(
    pBelongsToCompany.id,
    pBelongsToCompany.get('name'),
  );

  let userSpecialPermission = pUserSpecialPermission ? new UserSpecialPermission(
    pUserSpecialPermission.id,
  ) : undefined;

  if (userSpecialPermission) {
    userSpecialPermission.dbObject = pUserSpecialPermission;
    userSpecialPermission = { ...pUserSpecialPermission.attributes, ...userSpecialPermission };
  }

  let userNotificationSetting = pUserNotificationSetting ? new UserNotificationSetting(
    pUserNotificationSetting.id,
    pUserNotificationSetting.get('enableSpeedViolation'),
    pUserNotificationSetting.get('enableHOSViolation'),
    pUserNotificationSetting.get('emailTo'),
    pUserNotificationSetting.get('enableChatMessage'),
  ) : undefined;

  if (userNotificationSetting) {
    userNotificationSetting.dbObject = pUserNotificationSetting;
    userNotificationSetting = { ...userNotificationSetting.attributes, ...userNotificationSetting };
  }

  const user = new User(
    pUser.id,
    pUser.get('username'),
    pUser.get('firstName'),
    pUser.get('lastName'),
    pUser.get('email'),
    pUser.get('phoneNumber'),
    pUser.get('userType'),
    belongsToCompany,
    userSpecialPermission,
    userNotificationSetting
  );

  user.dbObject = pUser;

  return user;
}

/**
 * @memberof module:Getters
 * @param {*} driverObj
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
function getHOSViolations(driverObj, startDate, endDate) {
  const finalFilter = [];
  finalFilter.push({
    name: 'driver',
    queryType: 'equalTo',
    value: driverObj
  });
  finalFilter.push({
    name: 'triggerTime',
    queryType: 'greaterThanOrEqualTo',
    value: startDate
  })
  finalFilter.push({
    name: 'triggerTime',
    queryType: 'lessThanOrEqualTo',
    value: endDate
  })
  finalFilter.push({ queryType: 'equalTo', name: 'disabled', value: false });
  finalFilter.push({ queryType: 'equalTo', name: 'potential', value: false });
  return queryCompanyObjects(
    'HOSViolation',
    undefined,
    undefined,
    finalFilter,
    { triggerTime: 'descending' },
    undefined,
    false,
  );
}

/**
 * @memberof module:Getters
 * @param {*} addressString
 * @returns
 */
function getAddressBreakdownFromString(addressString = '') {
  // assuming format like that found in google: 24 Massachusetts Ave, Arlington, MA 02474, USA
  // output object that categorizes each section
  const addrSplit = addressString.split(', ');
  const street = addrSplit[0] || undefined; // splitting an empty string will give an empty string as first element. make sure its undefined for expected behaviour
  const city = addrSplit[1];
  const jurisdictionPostal = addrSplit[2];
  const country = addrSplit[3];

  return { street, city, jurisdictionPostal, country };
}

// function test1() {
//   // YeOUIPmrbt
//   // const query1 = new Parse.Query('ELDEvent');
//   // query1.equalTo('objectId', 'abc');
//   //
//   // const query2 = new Parse.Query('ELDEvent');
//   // query2.equalTo('objectId', 'YeOUIPmrbt');
//   // const getpromises = [query1.first(), query2.first()];
//   // Promise.all(getpromises).then(
//   //   things => {
//   //     console.log(things);
//   //   }
//   // );
//   // return;
//   const query = new Parse.Query('ELDEvent');
//   query.equalTo('objectId', 'YeOUIPmrbt');
//
//   const vehiclelocationquery = new Parse.Query('VehicleLocation');
//   vehiclelocationquery.equalTo('objectId', 'D6SykUo67g');
//
//   const dateTime = moment('December 08 2017, 23:58:30').toDate();
//
//   vehiclelocationquery.first().then(
//     vehicleLocation => {
//       const vehicleLocationClone = vehicleLocation.clone();
//       vehicleLocationClone.set('eldEvent', null);
//       vehicleLocationClone.set('eldEvent_eldEventTypeCodeInt', null);
//       vehicleLocationClone.set('drivers', []);
//       vehicleLocationClone.set('vehicle', null);
//       vehicleLocationClone.set('company', null);
//       vehicleLocationClone.set('vehicleVIN', null);
//       vehicleLocationClone.set('eldHardwareSerial', null);
//       vehicleLocationClone.set('dateTime', dateTime);
//       const geopoint = new Parse.GeoPoint(49.082489, -122.541385);
//       vehicleLocationClone.set('location', geopoint);
//
//       vehicleLocationClone.save().then(
//         vl => {
//           query.first().then(
//             eldEvent => {
//               const eventClone = eldEvent.clone();
//
//               eventClone.set('driverUsername', null);
//               eventClone.set('driverFirstName', null);
//               eventClone.set('driverLastName', null);
//               eventClone.set('driverLicense', null);
//               eventClone.set('driverLicenseJurisdiction', null);
//               eventClone.set('driverLicenseCountry', null);
//               eventClone.set('driverExempt', null);
//               eventClone.set('driverPersonalUseCMV', null);
//               eventClone.set('driverYardMoves', null);
//
//               eventClone.set('coDriverUsername', null);
//               eventClone.set('coDriverFirstName', null);
//               eventClone.set('coDriverLastName', null);
//               eventClone.set('coDriverLicense', null);
//               eventClone.set('coDriverLicenseJurisdiction', null);
//               eventClone.set('coDriverLicenseCountry', null);
//
//               eventClone.set('eldEventTypeCodeInt', 12);
//               eventClone.set('vehicleUnitId', 'ABC1013');
//               eventClone.set('vehicle', null);
//               eventClone.set('eldHardware', null);
//               eventClone.set('company', null);
//               eventClone.set('eventDateTime', dateTime);
//               eventClone.set('vehicleLocation', vl);
//               eventClone.set('aobrdEnabled', null);
//
//               eventClone.save().then(
//                 finalEvent => {
//                   console.log(finalEvent);
//                 }
//               );
//             }
//           );
//         }
//       );
//     }
//   );
//
// }

function test(driver) {
  // for (let i = 0; i < 15; i++) {
  //   test1();
  // }
  // Setters.addRecord('ELDDailyCertification', {
  //   startTimeUTC: new Date('September 29, 2018 00:00'),
  //   driver: driver,
  //   idleTimeMillis: 5,
  // }, Helpers.getCompanyReadWriteACL());

  // oct 1 ZJxDO7DJSN
  // sept 29 ybjBqV1WXB //2
  // const eldDailyCertificationQuery = new Parse.Query('ELDDailyCertification');
  // eldDailyCertificationQuery.equalTo('objectId', 'ybjBqV1WXB');
  // eldDailyCertificationQuery.first().then(
  //   eldDailyCertification => {
  //     console.log(eldDailyCertification);
  //     Setters.addRecord('SpeedViolation', {
  //       eldDailyCertification,
  //       driver,
  //       startTime: new Date('September 29 2018 05:00'),
  //       endTime: new Date('September 29 2018 06:00'),
  //     }, Helpers.getCompanyReadWriteACL());
  //   }
  // );

  // const dutyStatusPeriodQuery = new Parse.Query('SpeedViolation');
  // dutyStatusPeriodQuery.equalTo('objectId', 'bWeZXIOHXb');
  // dutyStatusPeriodQuery.first().then(
  //   dutyStatusPeriod => {
  //     dutyStatusPeriod.set('endTime', new Date('September 29 2018 07:00')).save();
  //   }
  // );

  // Parse.Cloud.run('echo');

  // const svQuery = new Parse.Query('ChatMessage');
  // svQuery.equalTo('objectId', 'F8xxgrd3Sw');
  // svQuery.first().then(
  //   sv => {
  //     console.log(sv);
  //     const newSvRole = sv.clone();
  //     newSvRole.set('timeSinceEpochId', `${(new Date()).getTime()}&${sv.get('sender').id}`);
  //     newSvRole.set('message', `${moment().format('HH:mm:ss')} Vancouvah`);
  //     newSvRole.set('seenBy', [sv.get('sender')]);
  //     newSvRole.save();
  //   }
  // );
}

/**
 * @memberof module:Getters
 *
 * @param {*} code
 * @param {*} type
 *
 * @returns
 */
const getDiagnosticCodeDescription = (code, type) => {
  return Parse.Cloud.run('decodeMalfunctionCode', {
    code,
    type
  });
};

const getSimUsageRecord = (imei, trackerEsn) => {
  return Parse.Cloud.run('getSimUsageRecord', {
    imei,
    trackerEsn
  });
}

const getVehiclesInGeofence = async (geofenceId) => {
  if (!geofenceId) return [];
  const geofenceObj = await (new Parse.Query('Geofence')).equalTo('objectId', geofenceId).first();

  // TODO: Get CompanyLinks
  // const companyLinkFilter = [{ queryType: 'equalTo', name: 'parentCompany', value: company }, { queryType: 'equalTo', name: 'authorized', value: true }]; 
  // const companyLinkArr = await Getters.queryCompanyObjects('CompanyLink', undefined, undefined, companyLinkFilter, undefined, undefined, false, true, null, true);

  const vehicleLocationQuery = new Parse.Query('VehicleLocation');
  vehicleLocationQuery.equalTo('belongsToCompany', getCurrentUserCompany());
  vehicleLocationQuery.greaterThanOrEqualTo('dateTime', moment().subtract(3, 'months').toDate());

  if (geofenceObj && geofenceObj.get('topLat') && geofenceObj.get('leftLong') && geofenceObj.get('bottomLat') && geofenceObj.get('rightLong')) {
    // vehicleLocationQuery.withinPolygon('location', [
    //   new Parse.GeoPoint({ latitude: geofenceObj.get('topLat'), longitude: geofenceObj.get('leftLong') }),
    //   new Parse.GeoPoint({ latitude: geofenceObj.get('topLat'), longitude: geofenceObj.get('rightLong') }),
    //   new Parse.GeoPoint({ latitude: geofenceObj.get('bottomLat'), longitude: geofenceObj.get('rightLong') }),
    //   new Parse.GeoPoint({ latitude: geofenceObj.get('bottomLat'), longitude: geofenceObj.get('leftLong') }),
    // ]);
    // const vehicleQuery = new Parse.Query('Vehicle');
    // vehicleQuery.equalTo('belongsToCompany', geofenceObj.get('belongsToCompany'));
    // vehicleQuery.equalTo('enabled', true);
    // vehicleQuery.exists('unitId');
    // vehicleQuery.matchesQuery('vehicleLocation', vehicleLocationQuery);
    // console.log('querying vehicles');
    // const vehicleArr = await getAllFromQuery(vehicleQuery);
    // console.log(vehicleArr);
    // return vehicleArr;


    // Alternative Query
    const vehicleQuery = new Parse.Query('Vehicle');
    vehicleQuery.equalTo('belongsToCompany', geofenceObj.get('belongsToCompany'));
    vehicleQuery.equalTo('enabled', true);
    vehicleQuery.exists('unitId');
    vehicleQuery.exists('vehicleLocation');
    const vehicleArr = await getAllFromQuery(vehicleQuery);

    const vehiclesWithinGeofenceArr = [];
    for (let i = 0; i < vehicleArr.length; i++) {
      const vehicleLocation = getAttribute(vehicleArr[i], 'vehicleLocation', true);
      const location = getAttribute(vehicleLocation, 'location', true);
      if (location) {
        const latitude = location.latitude;
        const longitude = location.longitude;
        if (
          latitude <= geofenceObj.get('topLat') && latitude >= geofenceObj.get('bottomLat')
          && longitude <= geofenceObj.get('rightLong') && latitude >= geofenceObj.get('leftLong')
        ) {
          vehiclesWithinGeofenceArr.push(vehicleArr[i]);
        }
      }
    }
    return vehiclesWithinGeofenceArr;
  }
  return [];
}

const getTrailersInGeofence = async (geofenceId) => {
  if (!geofenceId) return [];
  const geofenceObj = await (new Parse.Query('Geofence')).equalTo('objectId', geofenceId).first();
  if (geofenceObj && geofenceObj.get('topLat') && geofenceObj.get('leftLong') && geofenceObj.get('bottomLat') && geofenceObj.get('rightLong')) {
    // const tcPositionsQuery = new Parse.Query('tc_positions');
    // tcPositionsQuery.withinPolygon('location', [
    //   new Parse.GeoPoint({ latitude: geofenceObj.get('topLat'), longitude: geofenceObj.get('leftLong') }),
    //   new Parse.GeoPoint({ latitude: geofenceObj.get('topLat'), longitude: geofenceObj.get('rightLong') }),
    //   new Parse.GeoPoint({ latitude: geofenceObj.get('bottomLat'), longitude: geofenceObj.get('rightLong') }),
    //   new Parse.GeoPoint({ latitude: geofenceObj.get('bottomLat'), longitude: geofenceObj.get('leftLong') }),
    // ]);
    // const tcPositionsTcDevicesQuery = new Parse.Query('tc_devices');
    // tcPositionsTcDevicesQuery.equalTo('belongsToCompany', geofenceObj.get('belongsToCompany'));
    // tcPositionsTcDevicesQuery.exists('tc_positions');
    // tcPositionsTcDevicesQuery.matchesQuery('tc_positions', tcPositionsQuery);
    // tcPositionsTcDevicesQuery.greaterThanOrEqualTo('devicetime', moment().subtract(3, 'months').toDate());

    // const assetLocationQuery = new Parse.Query('AssetLocation');
    // assetLocationQuery.withinPolygon('location', [
    //   new Parse.GeoPoint({ latitude: geofenceObj.get('topLat'), longitude: geofenceObj.get('leftLong') }),
    //   new Parse.GeoPoint({ latitude: geofenceObj.get('topLat'), longitude: geofenceObj.get('rightLong') }),
    //   new Parse.GeoPoint({ latitude: geofenceObj.get('bottomLat'), longitude: geofenceObj.get('rightLong') }),
    //   new Parse.GeoPoint({ latitude: geofenceObj.get('bottomLat'), longitude: geofenceObj.get('leftLong') }),
    // ]);
    // const assetLocationTcDevicesQuery = new Parse.Query('tc_devices');
    // assetLocationTcDevicesQuery.equalTo('belongsToCompany', geofenceObj.get('belongsToCompany'));
    // assetLocationTcDevicesQuery.exists('assetLocation');
    // assetLocationTcDevicesQuery.matchesQuery('assetLocation', assetLocationQuery);
    // assetLocationTcDevicesQuery.greaterThanOrEqualTo('devicetime', moment().subtract(3, 'months').toDate());

    // const trailerQuery = new Parse.Query('Trailer');
    // trailerQuery.equalTo('enabled', true);
    // trailerQuery.equalTo('belongsToCompany', geofenceObj.get('belongsToCompany'));
    // trailerQuery.exists('unitId');
    // trailerQuery.exists('tc_devices');
    // trailerQuery.matchesQuery('tc_devices', Parse.Query.or(assetLocationTcDevicesQuery, tcPositionsTcDevicesQuery));

    // console.log('querying trailerQuery');
    // const trailerArr = await getAllFromQuery(trailerQuery);
    // console.log(trailerArr);
    // return trailerArr;

    const trailerQuery = new Parse.Query('Trailer');
    trailerQuery.equalTo('enabled', true);
    trailerQuery.equalTo('belongsToCompany', geofenceObj.get('belongsToCompany'));
    trailerQuery.exists('unitId');
    trailerQuery.exists('tc_devices');
    trailerQuery.include(['tc_devices, tc_devices.assetLocation, tc_devices.tc_positions']);
    const trailerArr = await getAllFromQuery(trailerQuery);

    const trailersWithinGeofenceArr = [];
    for (let i = 0; i < trailerArr.length; i++) {
      const tcDevices = getAttribute(trailerArr[i], 'tc_devices', true);
      const assetLocation = getAttribute(tcDevices, 'assetLocation', true);
      const tcPositions = getAttribute(tcDevices, 'tc_positions', true);

      const location = getAttribute(assetLocation, 'location', true) || getAttribute(tcPositions, 'location', true);
      if (location) {
        const latitude = location.latitude;
        const longitude = location.longitude;
        if (
          latitude <= geofenceObj.get('topLat') && latitude >= geofenceObj.get('bottomLat')
          && longitude <= geofenceObj.get('rightLong') && latitude >= geofenceObj.get('leftLong')
        ) {
          trailersWithinGeofenceArr.push(trailerArr[i]);
        }
      }
    }
    return trailersWithinGeofenceArr;
  }
  return [];
}

const getEDCs = async (page, limit, filter, sortBy) => {
  const edcs = await queryCompanyObjects('EngineDTC', page, limit, [].concat(...filter, [{ name: 'vehicle', queryType: 'exists' }]), sortBy, ['vehicle']);
  const filteredEdcs = edcs.filter((edc) => {
    return (
      edc.get('malfunctionCode') &&
      edc.get('malfunctionCode') !== '0000007F' &&
      edc.get('malfunctionCode') !== '000000FF'
    );
  });
  return filteredEdcs;
}

export {
  getCurrentUserCompany,
  downloadDocuments,
  downloadDocumentsFromJobLinks,
  downloadReceipts,
  getUsageForSim,
  getSimInfoByIccid,
  fetchUserGlobals,
  fetchParseObjectArr,
  getAddressBreakdownFromString,
  getLinkedObjects,
  getAllActiveJobActions,
  getAllDocumentCategories,
  getAllDriversFromJobActions,
  getAllJobActions,
  getAllJobDriversForJobActions,
  getAllJobLinks,
  getAllUsersFromCompany,
  getELDEvents,
  getChildCompanies,
  getClassObjectCopy,
  getResellerCompany,
  queryCompanyObjects,
  getAllFromQuery,
  getBase64Image,
  getCarrierProfileScores,
  getCompanyDriverUsers,
  getCompanyDispatchers,
  getCompanyNonDriverUsers,
  getCurrentUser,
  getDateObjectScaledToTZ,
  getDriverPatternDurations,
  getDriverStatuses,
  getDriverTeams,
  getEnvironment,
  getNotifications,
  getCompanyPrefix,
  getCurrentDispatcher,
  getDispatchersFromDriver,
  getDocuments,
  getDocumentsFromJobLink,
  getDriverById,
  getMostRecentEldEventForDriver,
  getDriverFromUserId,
  getDriverIssues,
  getDriverReceipts,
  getDrivers,
  getHOSViolations,
  getEarliestJobActionDate,
  getObjectById,
  getObjectDeepCopy,
  getObjectsForNotification,
  getPDispatcherPropertyFromState,
  getRecord,
  getSimByImei,
  getSingleParseObject,
  getUser,
  getVehicleById,
  getVehicles,
  isJobLinkComplete,
  isUserAdmin,
  getDiagnosticCodeDescription,
  getSimUsageRecord,
  getVehiclesInGeofence,
  getTrailersInGeofence,
  getEDCs,
  test,
};
