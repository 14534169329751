import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// CSAPI Enums
import { PaymentStatus } from 'sb-csapi/dist/enums/Dispatch/PaymentStatus';

// Components
import PaymentStatusBadge from 'sbCore/PaymentStatusBadge/PaymentStatusBadge';

// sbCore Components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import Skeleton from 'sbCore/Skeleton/Skeleton';

// Styles
import './style.scss';

/**
 * @description Select a Payment Status from a dropdown
 * @param {String} [className] - Custom container className
 * @param {Object} [style] - Custom inline styles
 * @param {Number} [status] - Sets the status value to this by default. The status provided must be recognized in our enums: sappy/enums/DispatchPayment Status
 * @param {Boolean} [warning] - Makes the border yellow
 * @param {Boolean} [isFocusedDefault] - Whether this component is the first component to be focused on. Determines if we should set isFocused to true when mounting
 * @param {Boolean} [showOnFocus] - Whether to automatically open the dropdown on focus
 * @param {Boolean} [hideLabel] - Hide the label
 * @param {Boolean} [isLoading] - Show a loading skeleton
 * @param {Boolean} [disabled] - TODO: Whether disable the component
 * @param {Function} [onSelect] - A callback function that returns the selected status (int)
 * @param {Boolean} [includeAllStatusesOption] - A boolean indicating whether this dropdown should show an option for all statuses
 * @returns
 */
function PaymentStatusDropdown({ ...props }) {
  const statusArray = Object.keys(PaymentStatus).map(key => PaymentStatus[key]);
  const [identifier] = useState(uniqid()); // for each of this component that exists on the same page, give it unique identifier for specific dom manipulation
  const [selectedStatus, setSelectedStatus] = useState(undefined); // the selected status object
  const [statuses] = useState((props.includeAllStatusesOption && ([{ status: -1, description: 'All Statuses', key: 'ALL' }, ...statusArray])) || statusArray);
  const [isFocused, setIsFocused] = useState(props.isFocusedDefault);
  const [hasError, setHasError] = useState(false); // if there is an error with the input

  // ** Hooks Section ** //
  useEffect(() => {
    if (!statuses || statuses.length < 0) return;
    let defaultStatus = props.status;

    if (defaultStatus !== undefined && defaultStatus !== null) {
      defaultStatus = statuses.filter(statusObj => statusObj.status === defaultStatus)[0];
    } else if (props.includeAllStatusesOption) {
      defaultStatus = statuses.filter(statusObj => statusObj.status === -1)[0]; // This int indicates all statuses
    } else {
      defaultStatus = statuses.filter(statusObj => statusObj.status === PaymentStatus.UNPAID.status)[0];
    }
    setSelectedStatus(defaultStatus);
  }, [props.status, statuses]); // This hook runs the first time it is mounted and after any changes to the status prop

  // ** Components Section ** //
  function onChange(e) {
    setSelectedStatus(e.value);
    if (props.onSelect) props.onSelect(e.value ? e.value.status : e.value); // send the chosen status int back up to parent
  }

  function toggleFocus() {
    // determine whether or not this component is focused on, to enable additional functionality
    setIsFocused(!isFocused);
  }

  function itemTemplate(option) {
    return (
      <PaymentStatusBadge paymentStatusInt={option.status} />
    );
  }

  function valueTemplate(option) {
    if (!option) return;
    return (
      <PaymentStatusBadge paymentStatusInt={option.status} />
    );
  }

  // ** Misc ** //
  let className = `payment-status-dropdown ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  // if the input is not being focused on (which is for some reason dictated by the button's focus), but the user left their search term without selecting a suggestion for the searchTerm
  // const isNotFocusedWithText = inputButtonEl && searchTerm && ((typeof searchTerm) !== 'object') && (document.activeElement !== inputButtonEl);
  // const _hasError = isNotFocusedWithText || hasError;
  const _hasError = false;

  // ** Components ** //
  const label = (
    <InputLabel>Status</InputLabel>
  );

  const skeleton = (
    <Skeleton width="10rem" height="2.5rem" />
  );

  const paymentStatusDropdown = (
    <Dropdown
      value={selectedStatus}
      options={statuses}
      onChange={(e) => onChange(e)}
      onFocus={(e) => toggleFocus(e)}
      onBlur={() => toggleFocus()}
      optionLabel="description"
      placeholder="Select Status"
      valueTemplate={valueTemplate}
      itemTemplate={itemTemplate}
      autoFocus={props.autoFocus}
      warning={props.warning}
      error={_hasError}
      showOnFocus={props.showOnFocus || true}
      disabled={props.disabled}
    />
  );

  return (
    <div className={className} style={{ ...props.style }}>

      {!props.hideLabel && label}

      {!props.isLoading && paymentStatusDropdown}

      {props.isLoading && skeleton}

    </div>
  );
}

export default PaymentStatusDropdown;
