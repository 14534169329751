import React from 'react';

// Styles
import './style.scss';

/**
* @description Renders a driving icon with a specified background color
* @param {String} backgroundColorClassName - The background color shown for the icon
* @param {Function} onClick - Callback that triggers when the map icon is clicked.
* @returns
*
* @example
* <DrivingIcon backgroundColor="driving" />
*/
function DrivingIcon({ ...props }) {
  return (
    <span className="driving-icon fa-stack fa-2x" onClick={props.onClick}>
      <i className={`fas fa-circle fa-stack-2x ${props.backgroundColorClassName}`} aria-hidden="true" />
      <i className="fas fa-location-arrow fa-stack-1x icon" />
    </span>
  );
}

export default DrivingIcon;
