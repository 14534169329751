import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';

// Components
import { Button } from 'primereact/button';
import AddNewGeofenceButton from 'components/MapListSidebar/view/AddNewGeofenceButton';
import AddNewVehicleButton from 'components/AddEditEquipment/container/AddNewVehicleButton';
import FilterBar from 'components/MapListSidebar/container/FilterBar';
import WalkthroughSelectTip from 'components/WalkthroughSelectTip/container/WalkthroughSelectTip';
import GeofenceList from './GeofenceList';
import VehicleList from './VehicleList';
import TrailerList from './TrailerList';
import { MDBBtn, MDBIcon } from 'mdbreact';

// CSS
import styles from './MapListSidebar.module.scss';

class MapListSidebar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loadedAllVehicles: false, // bool to determine if number of vehicles hasnt changed
      walkthroughState: {
        geofenceTab: false,
        vehicleLocation: false,
      }
    };
    this.toggleAddGeofenceMode = this.toggleAddGeofenceMode.bind(this);
    this.toggleWalkthroughState = this.toggleWalkthroughState.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.Vehicle.fetched) {
      this.state.loadedAllVehicles = false;
      return;
    }
    this.state.loadedAllVehicles = true;
  }

  changeRoute(route) {
    history.push(`${route}`);
  }

  toggleAddGeofenceMode() {
    if (this.props.addEditGeofence.type !== 'add') {
      this.props.setAddEditGeofence('add');
    } else {
      this.props.setAddEditGeofence();
    }
  }

  toggleWalkthroughState(walkthroughStateAttribute) {
    const newState = { ...this.state };
    newState.walkthroughState = { ...this.state.walkthroughState };
    newState.walkthroughState[walkthroughStateAttribute] = !this.state.walkthroughState[walkthroughStateAttribute];
    this.setState(newState);
  }

  render() {
    const { company } = this.props;
    const { walkthroughState } = this.state;
    return (
      <div className={styles.sidebarContainer}>
        <div className={styles.sidebar + (this.props.isOpen ? '' : ' ' + styles.closed)}>
          <div className={this.props.type === 'Vehicles' ? styles.vehicleFilter : ''} style={{ position: 'relative' }}>
            {company && !company.get('disableWalkthrough') &&
              <WalkthroughSelectTip
                style={{ top: '.6em', right: '.5em' }}
                showBoxBottom
                expand={walkthroughState.geofenceTab}
                handleToggle={() => this.toggleWalkthroughState('geofenceTab')}
              >
                <div className="translate-me">
                  Click here to add, view, and edit Geofences. Receive alerts about your vehicles and the geofences you set for them
                  <br /><br />
                  To disable these tips, go to <b>Walkthrough Tips</b> under your <a onClick={() => history.push('/settings')} style={{ cursor: 'pointer' }}>settings</a>
                </div>
              </WalkthroughSelectTip>
            }
            <FilterBar
              type={this.props.type}
              handleFilter={this.props.handleFilter}
              sortBy={this.props.sortBy}
              toggleEquipmentMarkers={this.props.toggleEquipmentMarkers} displayMarkers={this.props.displayMarkers}
            />
          </div>

          <div className={styles.mapList}>
            {this.props.type === 'Vehicles' && (company && !company.get('disableWalkthrough')) &&
              <WalkthroughSelectTip
                style={{ marginTop: '1em', left: '8em' }}
                showBoxBottom
                expand={walkthroughState.vehicleLocation}
                handleToggle={() => this.toggleWalkthroughState('vehicleLocation')}
              >
                <div className="translate-me">
                  A list of your vehicles shows up here. Click on one to view their location, GPS history, get a link to share their location, and more
                  <br /><br />
                  To disable these tips, go to <b>Walkthrough Tips</b> under your <a onClick={() => history.push('/settings')} style={{ cursor: 'pointer' }}>settings</a>
                </div>
              </WalkthroughSelectTip>
            }
            {this.props.type === 'Vehicles' &&
              <VehicleList {...this.props} />
            }
            {this.props.type === 'Trailers' &&
              <TrailerList {...this.props} />
            }
            {this.props.type === 'Geofences' &&
              <GeofenceList {...this.props} />
            }
          </div>

          {/* { this.props.type === 'Vehicles' &&
            <AddNewVehicleButton className="mx-auto" />
          } */}
          {this.props.type === 'Geofences' &&
            <AddNewGeofenceButton
              addEditGeofence={this.props.addEditGeofence}
              disabled={this.props.addEditGeofence.type === 'edit'}
              handleClick={() => this.toggleAddGeofenceMode()}
            />
          }
          {this.props.type === 'Vehicles' &&
            (
              <div className={styles.addNewVehicleButton}>
                <Button
                  label="Add Vehicle"
                  className="p-button-sm p-button-secondary"
                  icon="pi pi-plus-circle"
                  onClick={() => this.changeRoute('equipment?view=vehicles')}
                />
              </div>
            )
          }
          {this.props.type === 'Trailers' &&
            (
              <div className={styles.addNewVehicleButton}>
                <Button
                  label="Add Trailer"
                  className="p-button-sm p-button-secondary"
                  icon="pi pi-plus-circle"
                  onClick={() => this.changeRoute('equipment?view=trailers')}
                />
              </div>
            )
          }
        </div>
        <MDBBtn size="sm" color="primary" className={styles.drawerButton} onClick={this.props.toggleSidebar}>
          {!this.props.isOpen && <MDBIcon icon="shipping-fast" className="mr-2"></MDBIcon>}
          {!this.props.isOpen && <MDBIcon icon="angle-right"></MDBIcon>}
          {this.props.isOpen && <MDBIcon icon="angle-left"></MDBIcon>}
        </MDBBtn>
      </div>
    );
  }
}

MapListSidebar.propTypes = {
  company: PropTypes.object,
  addEditGeofence: PropTypes.object.isRequired,
  setAddEditGeofence: PropTypes.func.isRequired,
  Vehicle: PropTypes.object.isRequired,
  Trailer: PropTypes.object.isRequired,
  Geofence: PropTypes.object.isRequired,
  sortHandler: PropTypes.func,
  sortBy: PropTypes.string,
  selectSidebarObject: PropTypes.func.isRequired,
  hoverSidebarObject: PropTypes.func.isRequired,
  activeVehicle: PropTypes.object,
  handleFilter: PropTypes.func.isRequired,
  toggleEquipmentMarkers: PropTypes.func,
  type: PropTypes.string.isRequired,
  displayMarkers: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired
};

export default MapListSidebar;
