/** @module DOTNumber */

/**
 * @memberof module:DOTNumber
 * 
 * @param {*} USDOTNumberString 
 * 
 * @returns 
 */
function parseDOTNumber(USDOTNumberString) {
  // This parsing logic is copied from the getELDHeaderSegment in the servers eldCSV.js
  if (USDOTNumberString.toString().split('.').length > 0) {
    USDOTNumberString = USDOTNumberString.toString().split('.')[0];
  }
  // Trim whitespace
  USDOTNumberString = USDOTNumberString.trim();
  if (!USDOTNumberString) return USDOTNumberString;
  // Accept DOT numbers likes 123-456-789, parse them to 123456789
  USDOTNumberString = USDOTNumberString.split('-').join('');
  // Accept DOT numbers with spaces 123 456 789 parse them to 123456789
  USDOTNumberString = USDOTNumberString.split(' ').join('');
  return USDOTNumberString;

}

/**
 * @memberof module:DOTNumber
 * 
 * @param {*} USDOTNumberString 
 * 
 * @returns 
 */
function isValidDOTNumber(USDOTNumberString) {
  if (!USDOTNumberString) return false;
  USDOTNumberString = parseDOTNumber(USDOTNumberString);
  // Make sure it is digits only, else it is Unparseable
  if (!USDOTNumberString.match(/^[0-9]+$/)) {
    return false;
  }
  // Remove leading 0's as the FMCSA parser does
  USDOTNumberString = USDOTNumberString.replace(/^0+/, '');
  // If we are left with no characters the number is '' or '0' or '00' or '000' ...
  if (USDOTNumberString === '') {
    return false;
  }
  // Check it is at most 9 digits in decimal 1_000_000_000 > 999_999_999
  if (USDOTNumberString.length > 9) {
    return false;
  }

  return true;
}

export default isValidDOTNumber;
