import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { MenuItem, SelectField, Toggle } from 'material-ui';
import ReactTooltip from 'react-tooltip';

// API
import { getDriverHOSRecap } from 'api/DriverHOSRecap/DriverHOSRecap';
import { getAttribute } from 'api/Parse';
import { getELDCertifications, formatMilliTime } from 'api/ELD';
import * as HoursCycle from 'api/Lists/HoursCycle';
import { convertHoursToMilliseconds } from 'api/Helpers';

// Components
import Title from 'components/LayoutTitle/view/Title';
import CAHoursView from 'components/DriverHours/view/CAHoursView';
import USHoursView from 'components/DriverHours/view/USHoursView';
import CycleHourCalcTable from 'components/DriverHours/container/CycleHourCalcTable';
import DatePicker from 'components/DatePicker/container/DatePicker';

// CSS
import styles from './DriverHours.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

import { calculateCycleReset } from '../../../api/DriverHOSRecap/DriverHOSRecap';

class DriverHoursDetailsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCycle: 'us',
      hoursInCycle: 70,
      // daysInCycle: 8, // Will show current month, or 24 days (whichever's larger)
      date: moment().toDate(),
      capHours: props.Company.company.get('capHoursSetting'),
      canadaHours: {},
      usHours: {},
      loading: true,
      eldDailyCertificationArr: [],
    };

    this.refreshState = this.refreshState.bind(this);
  }

  componentDidMount() {
    this.refreshState();
  }

  componentDidUpdate(prevProps) {
    const { props } = this;

    if (prevProps.eldDailyCertification !== props.eldDailyCertification) {
      this.refreshState();
    }
  }

  async refreshState(date) {
    this.setState({ ...this.state, loading: true });
    const { props } = this;

    let startDate;
    let endDate;
    if (date) {
      startDate = moment(date).startOf('month').subtract(1, 'day').toDate();
      endDate = moment(date).endOf('month').toDate();
    }
    const eldDailyCertificationArr = await getELDCertifications(this.props.driver, startDate, endDate);

    const capHours = getAttribute(props.Company.company, 'capHoursSetting', true);


    let {
      caCycleType,
      caOnDutyCycleHours,
      caDriveTimeLeft,
      caOnDutyTimeLeft,
      usCycleType,
      usOnDutyCycleHours,
      usDriveTimeLeft,
      usOnDutyTimeLeft,
      usBreakTime,
    } = await getDriverHOSRecap(props.driver, props.eldDailyCertification, capHours);

    // Fix for ELDDailyCert - because android doesn't set these properly yet (as of 2023-02)
    // caOnDutyTimeLeft
    // usOnDutyTimeLeft
    // caOnDutyCycleHours
    // usOnDutyCycleHours
    const driverCycleIntCA = this.props.driver.get('hourCycleCanadaInt');
    const driverCycleIntUS = this.props.driver.get('hourCycleUSAInt');
    const daysInCACycle = HoursCycle.canDays[driverCycleIntCA];
    const hoursInCACycle = convertHoursToMilliseconds(HoursCycle.canHours[driverCycleIntCA]);
    const daysInUSCycle = HoursCycle.usDays[driverCycleIntUS];
    const hoursInUSCycle = convertHoursToMilliseconds(HoursCycle.usHours[driverCycleIntUS]);
    const rowsToShow = Math.min(eldDailyCertificationArr.length - 1, moment(eldDailyCertificationArr[0].get('startTimeUTC')).daysInMonth());
    // console.log(eldDailyCertificationArr, rowsToShow);

    // Calculate cycle reset since the eldDailyCert does not get filled in properly (as of 2023-05)
    let eldDailyCertificationsForCycleResetCalculation = [...eldDailyCertificationArr];

    if (startDate && endDate) {
      // We add in an extra three days here so that we can properly calculate the cycle reset hours for the earliest shown date within the UI
      const startDateCycleResetCalculation = moment(startDate).subtract(3, 'days').toDate();
      const eldDailyCertificationArrExtended = await getELDCertifications(this.props.driver, startDateCycleResetCalculation, startDate);

      // Get the ids of the eldDailyCertifications so that we dont process duplicate eldDailyCertifications
      const eldDailyCertificationArrIds = new Set(eldDailyCertificationArr.map((eldDailyCertification) => eldDailyCertification.id));
      eldDailyCertificationsForCycleResetCalculation = [...eldDailyCertificationArr, ...eldDailyCertificationArrExtended.filter((eldDailyCertification) => !eldDailyCertificationArrIds.has(eldDailyCertification.id))];
    }

    const eldDailyCertificationCycleResetIntMap = await calculateCycleReset(eldDailyCertificationsForCycleResetCalculation, daysInCACycle, daysInUSCycle);

    for (let i = 0; i < eldDailyCertificationArr.length; i++) {
      // ELDDailyCerts go from latest to earliest
      const eldDailyCertificationObj = eldDailyCertificationArr[i];

      // Set the cycleResetInt temporarily based on the previously calculated values
      const eldDailyCertificationId = eldDailyCertificationObj.id;
      if (eldDailyCertificationCycleResetIntMap[eldDailyCertificationId]) eldDailyCertificationObj.set('cycleResetInt', eldDailyCertificationCycleResetIntMap[eldDailyCertificationId]);

      // Add up onDutyConsumed for Canada Days
      let _caOnDutyCycleHours = hoursInCACycle;
      let caDaysToIterate = daysInCACycle;
      // console.log(i, daysInCACycle, eldDailyCertificationArr.length);
      if (i + daysInCACycle > rowsToShow) {
        _caOnDutyCycleHours = eldDailyCertificationArr[eldDailyCertificationArr.length - 1].get('caOnDutyCycleHours') || 0;
        caDaysToIterate = eldDailyCertificationArr.length - i;
      }
      for (let j = i + caDaysToIterate - 1; j >= i; j--) {
        const _beforeCaOnDutyCycleHours = _caOnDutyCycleHours;
        const eldDailyCertificationObjForCount = eldDailyCertificationArr[j];
        const onDutyConsumed = eldDailyCertificationObjForCount.get('onDutyConsumed') || 0;

        const resetCABool = eldDailyCertificationObjForCount.get('cycleResetInt') === 3 || eldDailyCertificationObjForCount.get('cycleResetInt') === 1;
        if (resetCABool) _caOnDutyCycleHours = hoursInCACycle;

        _caOnDutyCycleHours -= onDutyConsumed;


        // console.log(
        //   moment(moment.tz(moment(eldDailyCertificationObj.get('startTimeUTC')), this.props.driver.get('timezoneOffsetFromUTC')).startOf('day').toDate()).tz(this.props.driver.get('timezoneOffsetFromUTC')).format('LL'),
        //   moment(moment.tz(moment(eldDailyCertificationObjForCount.get('startTimeUTC')), this.props.driver.get('timezoneOffsetFromUTC')).startOf('day').toDate()).tz(this.props.driver.get('timezoneOffsetFromUTC')).format('LL'),
        //   _beforeCaOnDutyCycleHours,
        //   formatMilliTime(_beforeCaOnDutyCycleHours),
        //   onDutyConsumed,
        //   eldDailyCertificationObjForCount.get('cycleResetInt'),
        //   formatMilliTime(onDutyConsumed),
        //   _caOnDutyCycleHours,
        //   formatMilliTime(_caOnDutyCycleHours),
        // );
      }
      eldDailyCertificationObj.set('caOnDutyCycleHours', _caOnDutyCycleHours);

      let _usOnDutyCycleHours = hoursInUSCycle;
      let usDaysToIterate = daysInUSCycle;
      if (i + daysInUSCycle > rowsToShow) {
        _usOnDutyCycleHours = eldDailyCertificationArr[eldDailyCertificationArr.length - 1].get('usOnDutyCycleHours') || 0;
        usDaysToIterate = eldDailyCertificationArr.length - i;
      }
      for (let j = i + usDaysToIterate - 1; j >= i; j--) {
        const eldDailyCertificationObjForCount = eldDailyCertificationArr[j];
        const onDutyConsumed = eldDailyCertificationObjForCount.get('onDutyConsumed') || 0;

        const resetUSBool = eldDailyCertificationObjForCount.get('cycleResetInt') === 3 || eldDailyCertificationObjForCount.get('cycleResetInt') === 2;
        if (resetUSBool) {
          _usOnDutyCycleHours = hoursInUSCycle;
        }
        _usOnDutyCycleHours -= onDutyConsumed;
      }
      eldDailyCertificationObj.set('usOnDutyCycleHours', _usOnDutyCycleHours);
    }

    // console.log(eldDailyCertificationArr[0]);
    this.setState({
      canadaHours: {
        caCycleType,
        // caOnDutyCycleHours,
        caOnDutyCycleHours: eldDailyCertificationArr[0].get('caOnDutyCycleHours'), // Fix for ELDDailyCert - because android doesn't set these properly yet (as of 2023-02)
        caDriveTimeLeft,
        caOnDutyTimeLeft,
        eldDailyCertification: eldDailyCertificationArr[0]
      },
      usHours: {
        usCycleType,
        // usOnDutyCycleHo`urs,
        usOnDutyCycleHours: eldDailyCertificationArr[0].get('usOnDutyCycleHours'), // Fix for ELDDailyCert - because android doesn't set these properly yet (as of 2023-02)
        usDriveTimeLeft,
        usOnDutyTimeLeft,
        usBreakTime,
        eldDailyCertification: eldDailyCertificationArr[0]
      },
      eldDailyCertificationArr,
      loading: false,
    });
  }

  render() {
    const { state, props } = this;

    const showTrueRemainingTimeHelpTooltip = (
      <ReactTooltip effect="solid" type="dark" id="showTrueRemainingTimeHelp" className="translate-me">
        When enabled, <b>Show True Remaining Time</b> will limit remaining Driving time
        <br />
        by On-Duty time, if On-Duty time is lower than Driving.
      </ReactTooltip>
    );
    window.Localize.translatePage();
    return (
      <div>
        <div>
          <CAHoursView
            driver={props.driver}
            fetched={props.driverFetched}
            canadaHours={state.canadaHours}
            capHours={state.capHours}
            eldDailyCertification={state.eldDailyCertificationArr[0]}
          />

          <USHoursView
            driver={props.driver}
            fetched={props.driverFetched}
            usHours={state.usHours}
            capHours={state.capHours}
            eldDailyCertification={state.eldDailyCertificationArr[0]}
          />
          <div
            style={{ maxWidth: '25em', padding: '1.5em' }}
            data-tip
            data-for="showTrueRemainingTimeHelp"
          >
            {showTrueRemainingTimeHelpTooltip}
            <Toggle
              label="Show True Remaining Time"
              toggled={state.capHours}
              onToggle={(e, value) => {
                props.Company.company.set('capHoursSetting', value).save();
                this.setState({ ...state, capHours: value });
              }}
              className="translate-me"
            />
          </div>
        </div>
        <div className={styles.cycleHourCalcTable}>
          <Title title={<span className="translate-me">Cycle Hours</span>} />
          <div>
            {/* <div className={styles.cycleSelectField}>
              <SelectField
                floatingLabelText={t('Reset Type')}
                value={state.selectedCycle}
                onChange={(event, index, value) => this.setState({ ...state, selectedCycle: value })}
              >
                <MenuItem value="us" primaryText={t('US (34 hour reset)')} />
                <MenuItem value="canada" primaryText={t('Canada (36 hour reset)')} />
              </SelectField>
            </div>
            <div className={styles.cycleSelectField}>
              <SelectField
                floatingLabelText={t('Hours in Cycle')}
                value={state.hoursInCycle}
                onChange={(event, index, value) => this.setState({ ...state, hoursInCycle: value })}
              >
                <MenuItem value={120} primaryText="120" />
                <MenuItem value={80} primaryText="80" />
                <MenuItem value={70} primaryText="70" />
                <MenuItem value={60} primaryText="60" />
              </SelectField>
            </div> */}
            {/* <div className={styles.cycleSelectField}>
              <SelectField
                floatingLabelText={t('Days To Show')}
                value={state.daysInCycle}
                onChange={(event, index, value) => this.setState({ ...state, daysInCycle: value })}
              >
                <MenuItem value={31} primaryText="31" />
                <MenuItem value={14} primaryText="14" />
                <MenuItem value={8} primaryText="8" />
                <MenuItem value={7} primaryText="7" />
              </SelectField>
            </div> */}
            <div className={styles.cycleSelectField}>
              <DatePicker
                dateFormat="MMMM yyyy"
                selected={this.state.date}
                onChange={(date) => { this.setState({ ...state, date: moment(date).toDate() }, () => { this.refreshState(date) }); }}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </div>
          </div>
          {!props.loading && (
            <CycleHourCalcTable
              selectedCycle={state.selectedCycle}
              hoursInCycle={state.hoursInCycle}
              // daysInCycle={state.daysInCycle}
              date={this.state.date || null}
              eldDailyCertificationArr={this.state.eldDailyCertificationArr}
              fetched={!props.loading}
              driver={props.driver}
            />
          )}
          {this.state.loading && (
            <div className="flex justify-content-center align-items-center my-5">
              <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { Company } = state;
  return {
    Company,
  };
};

DriverHoursDetailsLayout.propTypes = {
  Company: PropTypes.object,
  driverFetched: PropTypes.bool.isRequired,
  driver: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(DriverHoursDetailsLayout);
