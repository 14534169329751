// api
import {
  getCurrentUser,
  getAttribute,
  createQuery,
  getObjectById,
  setQueryRestriction,
  sortQuery,
  count,
  find,
  updateRecord

} from 'api/Parse';
import * as Geocode from 'api/Geocode';
import { getReverseGeocode } from 'api/Mapbox';

// enums
import { LengthUnitTypes } from 'enums/DispatchUnit';
import { LocationDirectionTypes } from 'enums/LocationDirection';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

// sbObjects
import Sort from 'sbObjects/Sort';

/**
 * @description gets vehicle locations
 * @param {number} page page count
 * @param {number} limit limit count
 * @param {object} sortBy Sort object 
 * @param {array} filters array of Filter objects
 * @param {boolean} queryAll fetch all records that match the filters
 * @returns vehicle location records and count
 */
async function getVehicleLocations(page = 0, limit = 10, sortBy = new Sort('dateTime', QuerySortOrderTypes.ASCENDING), filters = [], queryAll) {
  const vehicleLocationQuery = createQuery('VehicleLocation');

  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

  setQueryRestriction(vehicleLocationQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);

  filters.forEach(filter => {
    setQueryRestriction(vehicleLocationQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value)
  });

  if (!queryAll) {
    setQueryRestriction(vehicleLocationQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
    setQueryRestriction(vehicleLocationQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
  }

  sortQuery(vehicleLocationQuery, sortBy.order, sortBy.attribute);

  const vehicleLocations = await find(vehicleLocationQuery, false, queryAll);
  const vehicleLocationsCount = await count(vehicleLocationQuery);

  return {
    vehicleLocations,
    vehicleLocationsCount,
  };
}

/** @module VehicleLocation */

/**
 * @memberof module:VehicleLocation
 * @description Given the parameters, output the best suited locationDescription string
 * 
 * @param {string} city
 * @param {string} stateProvince
 * @param {number} distance
 * @param {string} distanceUnit
 * @param {string} heading
 */
function getVehicleLocationDescription(city, stateProvince, distance = 0, distanceUnit = LengthUnitTypes.KM, heading = LocationDirectionTypes.N) {
  return `${distance}${distanceUnit?.toLowerCase()} ${heading}${stateProvince ? ` ${stateProvince}` : ''}${city ? ` ${city}` : ''}`;
}

/**
 * @memberof module:VehicleLocation
 * @description Given a locationDescription, attempt to break it down into parameters
 * 
 * @param {string} locationDescriptionString - locationDescription string of format "3mi W MD Federalsburg"
 * 
 * @returns object
 */
function getLocationDescriptionBreakdown(locationDescriptionString = '') {
  const breakdown = {
    distance: '0',
    distanceUnit: undefined,
    city: undefined,
    heading: {
      value: 'N'
    },
    stateProvince: {
      code: undefined
    }
  };

  if (!locationDescriptionString) return breakdown;
  if (!locationDescriptionString.trim()) return breakdown;

  const locationSplit = locationDescriptionString.split(' ');
  breakdown.distance = locationSplit[0].slice(0, -2);
  breakdown.distanceUnit = locationSplit[0].slice(-2);
  breakdown.heading.value = locationSplit[1];
  breakdown.stateProvince.code = locationSplit[2];
  const city = locationSplit[3] ? locationSplit.slice(3).join(' ').trim() : undefined; // ex. city name contains spaces "Baton Rouge"
  breakdown.city = city;

  return breakdown;
}

/**
 * @memberof module:VehicleLocation
 * @description Given a locationDescription, see if it is of the format "{distance}{distanceUnit} {heading} {stateProvince} {city}"
 * 
 * @param {string} locationDescriptionString - ex. "3mi W MD Federalsburg"
 */
function isValidLocationDescription(locationDescriptionString = '') {
  const reg = /^[0-9]+[a-zA-Z]{2}\s{1}[a-zA-Z]{1,}\s{1}[a-zA-Z]{2}\s{1}[a-zA-Z]{1,}/;
  const isValid = reg.test(locationDescriptionString);
  return isValid;
}

async function retrieveAndSaveDetailedReverseGeocode(locationObjId, locationObjClassName, longitude, latitude) {
  // using Json because it's all parsed into Json at AllEquipmentView/EquipmentGroupView +_+
  if (!locationObjId || !locationObjClassName) {
    // TODO: Trailer vehicle locations for some reason have a VehicleLocaiton className
    return getReverseGeocode(longitude, latitude);
  }

  const locationObj = await getObjectById(locationObjClassName, locationObjId);
  if (!locationObj) {
    return getReverseGeocode(longitude, latitude);
  }

  let detailedReverseGeocodeAddress = getAttribute(locationObj, 'detailedReverseGeocodeAddress', true);

  if (!detailedReverseGeocodeAddress && longitude && latitude) {
    detailedReverseGeocodeAddress = await getReverseGeocode(longitude, latitude);

    // save to object
    await updateRecord(locationObj, { detailedReverseGeocodeAddress }, true);
  }

  return detailedReverseGeocodeAddress;
}


export {
  getVehicleLocations,
  getLocationDescriptionBreakdown,
  getVehicleLocationDescription,
  isValidLocationDescription,
  retrieveAndSaveDetailedReverseGeocode,
};
