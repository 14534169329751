import React from 'react';
import PropTypes from 'prop-types';

// Components
import VehicleRow from 'components/MapListSidebar/container/VehicleRow';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import { MDBContainer } from "mdbreact";

// CSS
import styles from './ListStyles.module.scss';

class VehicleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedAllVehicles: false, // bool to determine if number of vehicles hasnt changed
      vehicles: [],
    };
    this.sortByVehicleLocationDate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const newState = { ...this.state };
    if ((this.props.Vehicle.fetched) || newState.vehicles.length === 0) {
      if (this.props.sortBy === 'updatedAt') {
        newState.vehicles = nextProps.Vehicle.vehicles.sort(this.sortByVehicleLocationDate);
      } else {
        newState.vehicles = nextProps.Vehicle.vehicles;
      }
    }
    if (!nextProps.Vehicle.fetched) {
      newState.loadedAllVehicles = false;
    } else {
      newState.loadedAllVehicles = true;
    }
    this.setState(newState);
  }

  sortByVehicleLocationDate(a, b) {
    if (a.get('vehicleLocation') && b.get('vehicleLocation')) {
      return b.get('vehicleLocation').get('dateTime') - a.get('vehicleLocation').get('dateTime');
    } else if (a.get('vehicleLocation')) {
      return -1;
    } else if (b.get('vehicleLocation')) {
      return 1;
    }
    if (a.get('unitId') < b.get('unitId')) return -1;
    if (a.get('unitId') > b.get('unitId')) return 1;
    return 0;
  }

  render() {
    const { loadedAllVehicles } = this.state;
    return (
      <MDBContainer className="px-0">
        {this.props.Vehicle.fetched === true && this.props.Vehicle.vehicles.length === 0 &&
          <div className="emptyRowMessage translate-me">You have no vehicles on Switchboard</div>
        }

        {loadedAllVehicles && !this.props.Vehicle.fetched && <LoadingIcon />}

        {this.state.vehicles.length > 0 &&
          this.state.vehicles.map(vehicle =>
            <VehicleRow
              active={this.props.activeSidebarObject && this.props.activeSidebarObject.type && this.props.activeSidebarObject.object.id === vehicle.id}
              handleHover={this.props.hoverSidebarObject}
              handleClick={this.props.selectSidebarObject}
              key={vehicle.id}
              vehicle={vehicle}
              globalSpeedLimitKm={this.props.speedLimitKm}
            />
          )
        }
      </MDBContainer>
    );
  }
}

VehicleList.propTypes = {
  sortBy: PropTypes.string,
  Vehicle: PropTypes.object.isRequired,
  selectSidebarObject: PropTypes.func.isRequired,
  hoverSidebarObject: PropTypes.func.isRequired,
  activeSidebarObject: PropTypes.object,
};

export default VehicleList;
