import React from 'react';
import PropTypes from 'prop-types';

// Components
import { MDBContainer, MDBRow, MDBCol, } from "mdbreact";

import './style.scss';


class Title extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { title, subtitle, children, style, containerClassName } = this.props;
    const className = "sb-title align-middle";
    const classNameSubtitle = "sb-subtitle align-middle";
    const containerClass = "sb-title-container translate-me " + containerClassName;
    return (
      <MDBContainer className={containerClass} fluid style={style}>
        <MDBRow>
          <MDBCol xs="5" md="6" className={`pl-0 my-auto`}>
            <div className={subtitle ? classNameSubtitle : className}>
              {title}
            </div>
          </MDBCol>
          <MDBCol xs="7" md="6" className="pr-0 options">
            <div className="align-middle">
              {children}
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}


Title.defaultProps = {
  style: {},
};
Title.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.node,
};

export default Title;
