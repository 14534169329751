import React from 'react';

import { InputText as PRInputText } from 'primereact/inputtext';

import './style.scss';

/**
 * @description Prime React InputText component
 */
export default function InputText({ warning, error, success, ...props }) {
  let className = 'sbc-input-text';
  if (props.className) className += ` ${props.className}`;
  if (props.variant === 'small') className += ' input-text-small';

  if (props.variant === 'small') className += ' input-text-small';

  if (error) {
    className += ` sbc-error`;
  } else if (warning) {
    className += ` sbc-warning`;
  } else if (success) {
    className += ` sbc-success`;
  }

  return (<PRInputText { ...props } className={className} />);
}
