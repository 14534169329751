import React from 'react';
import moment from 'moment-timezone';
import history from 'sbHistory'

// API
import * as PDF from 'api/PDFer';
import * as Helpers from 'api/Helpers';
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL';
import { getHosViolations } from 'api/ELD/HOSViolation/HOSViolation';
import { getAttribute, callCloudFunction } from 'api/Parse';
import HOSRegulation from 'api/Lists/HOSRegulation';

// Enums
import { ExcludedRegulations } from 'sb-csapi/dist/enums/HOS/Regulation';

// Components
import SubNavigationBar from 'components/Shared/SubNavigationBar/SubNavigationBar';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import SBCard from 'components/Shared/SBCard/SBCard';
import Title from 'components/Shared/Title/Title';
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay';
import DriverAutocomplete from 'components/Shared/DriverAutocomplete/DriverAutocomplete.old';
import { MDBContainer, MDBIcon, MDBBtn, MDBRow, MDBCol } from 'mdbreact';
import SBSelect from 'components/Shared/SBSelect/SBSelect';

// CSS
import './styles.scss';

const initialFilterFormFields = (tempDate) => {
  return [
    {
      attrName: 'month',
      fullName: 'Month',
      type: 'date',
      dateQueryType: 'dateMonth',
      includeLeftRight: true,
      value: tempDate ? moment(tempDate, 'DDMMYY') : moment(),
    },
  ];
}

class HOSViolationDetails extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(14, 'days'),
      endDate: moment(),
      driverObjArr: [],
      hosViolationArrByDriverHash: {},
      violationFilter: { key: -1, value: null, label: 'All Violations' },
      violationFilterItems: [],
      printInProgress: false,
      isLoading: false,
    };

    // Set up HOSRegulation Rules
    const ruleRegulationIntObj = [];
    const regulationIntArr = Object.keys(HOSRegulation);
    for (let i = 0; i < regulationIntArr.length; i++) {
      const hosRegulationObj = HOSRegulation[regulationIntArr[i]];
      if (!ruleRegulationIntObj[hosRegulationObj.rule]) {
        ruleRegulationIntObj[hosRegulationObj.rule] = [regulationIntArr[i]];
      } else {
        ruleRegulationIntObj[hosRegulationObj.rule].push([regulationIntArr[i]]);
      }
    }
    this.state.violationFilterItems = Object.keys(ruleRegulationIntObj).map((rule, index) => ({ key: index, value: ruleRegulationIntObj[rule], label: rule }));
    this.state.violationFilterItems.unshift({ key: -1, value: null, label: 'All Violations' });

    const tempDate = getQueryParameter(props.location.search, 'date');
    this.state.filterFormFields = initialFilterFormFields(tempDate);
    this.handleFilter = this.handleFilter.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.printReport = this.printReport.bind(this);
  }

  async componentDidMount() {
    await this.refreshState();
  }

  componentWillReceiveProps(nextProps) {
    this.processDate(nextProps);
  }

  async processDate(nextProps) {
    const props = nextProps || this.props;
    const dQuery = (props.location && getQueryParameter(props.location.search, 'd'));
    if (dQuery === 'lastTwoWeeks') {
      await this.setState(
        {
          ...this.state,
          startDate: moment().subtract(14, 'days'),
          endDate: moment(),
        },
      );
    } else if (dQuery === '24hours') {
      await this.setState(
        {
          ...this.state,
          startDate: moment().subtract(24, 'hours'),
          endDate: moment(),
        },
      );
    } else if (dQuery === 'month') {
      const dateString = getQueryParameter(props.location.search, 'date');
      await this.setState(
        {
          ...this.state,
          startDate: dateString ? moment(dateString, 'DDMMYY').startOf('month') : moment().startOf('month'),
          endDate: dateString ? moment(dateString, 'DDMMYY').endOf('month') : moment().endOf('month'),
        },
      );
    }
    this.refreshState();
  }

  async refreshState() {
    await this.setState({
      ...this.state,
      driverObjArr: [],
      hosViolationArrByDriverHash: {},
      isLoading: true,
    });

    // Get HOS Violations
    const hosViolationArr = (await getHosViolations(
      null,
      this.state.violationFilter.value,
      this.state.startDate,
      this.state.endDate,
      ExcludedRegulations
    )).filter((hosViolation) => {
      if (!hosViolation.get('driver')) return false;
      if (hosViolation.get('eldDailyCertification')) {
        const eldDailyCertificationHosViolationArr = hosViolation.get('eldDailyCertification').get('hosViolations');
        if (!eldDailyCertificationHosViolationArr || eldDailyCertificationHosViolationArr.length === 0) return false;
        for (let i = 0; i < eldDailyCertificationHosViolationArr.length; i++) {
          if (hosViolation.id === eldDailyCertificationHosViolationArr[i].id) return true;
        }
        return false;
      }
      return true;
    });

    // Sort Table by Drivers (Driver, Date, Violation Message, Go to Log)
    const hosViolationArrByDriverHash = {};
    const driverObjArr = [];
    const driverIdArr = [];
    for (let i = 0; i < hosViolationArr.length; i++) {
      const hosViolation = hosViolationArr[i];
      const driver = hosViolation.get('driver');
      if (driverIdArr.indexOf(driver.id) === -1) {
        driverIdArr.push(driver.id);
        driverObjArr.push(driver);
        hosViolationArrByDriverHash[driver.id] = [];
      }
      hosViolationArrByDriverHash[driver.id].push(hosViolation);
    }

    await this.setState({ ...this.state, driverObjArr, hosViolationArrByDriverHash, isLoading: false });

    // const unidentifiedELDEvents = await getUnidentifiedELDEvents(this.state.startDate, this.state.endDate);
    // const vehiclesCount = {};
    // const dailyCertsByVehicleHash = {};
    // const unidentifiedELDEdits = await getUnidentifiedELDEdits(unidentifiedELDEvents);
    // const _combinedEvents = unidentifiedELDEvents.map(unidentifiedELDEvent => {
    //   return getNextEldEvents(unidentifiedELDEvent);
    // });
    // const combinedEvents = await Promise.all(_combinedEvents);

    // const nextEldEventsByEldEventsId = {};
    // combinedEvents.map(combinedEvent => {
    //   const eldEventsId = getAttribute(combinedEvent.eldEvent, 'objectId');
    //   nextEldEventsByEldEventsId[eldEventsId] = combinedEvent;
    // });

    // unidentifiedELDEvents.filter(eldEvent => eldEvent && getAttribute(eldEvent, 'vehicle')).map(eldEvent => {
    //   const vehicle = getAttribute(eldEvent, 'vehicle');
    //   const vehicleObjectId = getAttribute(vehicle, 'objectId');
    //   const vehicleUnitId = getAttribute(vehicle, 'unitId');
    //   const dailyCertObject = getAttribute(eldEvent, 'eldDailyCertification');
    //   const dailyCertObjectId = getAttribute(dailyCertObject, 'objectId');
    //   const dailyCertStartTimeUTC = getAttribute(dailyCertObject, 'startTimeUTC');
    //   let editPending = false;

    //   unidentifiedELDEdits.map(eldEdit => { // filter out ELDEdits that are pending
    //     const editedELDEvents = getAttribute(eldEdit, 'eldEventsToBeInactive');
    //     editedELDEvents.map(editedELDEvent => {
    //       if (getAttribute(eldEvent, 'objectId') === getAttribute(editedELDEvent, 'objectId')) {
    //         editPending = true;
    //       }
    //     });
    //   });

    //   if (dailyCertStartTimeUTC && !editPending) { // don't include the daily certs w/o start time
    //     // const _combinedEvent = getNextEldEvents(eldEvent);
    //     // const combinedEvent = await Promise.all(_combinedEvent);
    //     // const combinedEldEvent = nextEldEventsByEldEventsId[getAttribute(eldEvent, 'objectId')];
    //     let count = 1;
    //     if (vehiclesCount[vehicleUnitId]) { // keeps track of vehicle with the same unitIds (so plate # is added to differentiate)
    //       count += vehiclesCount[vehicleUnitId];
    //     }
    //     vehiclesCount[vehicleUnitId] = count;

    //     if (!dailyCertsByVehicleHash[vehicleObjectId]) {
    //       dailyCertsByVehicleHash[vehicleObjectId] = {};
    //       dailyCertsByVehicleHash[vehicleObjectId].vehicle = vehicle;
    //       dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications = {};
    //     }

    //     if (!dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId]) {
    //       dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId] = {};
    //       dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId].dailyCertification = dailyCertObject;
    //       dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId].eldEvents = [];
    //     }
    //     dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId].eldEvents.push(eldEvent);
    //     // dailyCertsByVehicleHash[vehicleObjectId].dailyCertifications[dailyCertObjectId].combinedEvents.push(combinedEvent);
    //   }
    // });
    // await this.setState({ ...this.state, dailyCertsByVehicleHash, isLoading: false, nextEldEventsByEldEventsId });
  }

  async handleFilter(filters = []) {
    let month = moment();
    let startDate;
    let endDate;
    if (filters.length === 0) {
      this.setState({ ...this.state, filterFormFields: initialFilterFormFields(), month });
    } else {
      const filterFormFields = [].concat(...this.state.filterFormFields);
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        for (let j = 0; j < filterFormFields.length; j++) {
          if (filterFormFields[j].attrName === filter.attribute) {
            filterFormFields[j].value = filter.value;
          }
        }
        if (filter.attribute === 'month') {
          month = filter.value;
          startDate = moment(month).startOf('month');
          endDate = moment(month).endOf('month');
        }
      }
      this.setState({ ...this.state, filterFormFields, startDate, endDate }, () => {
        const queryStrObj = getDeserialized(this.props.location.search).params;
        history.push({
          search: getSerialized({ ...queryStrObj, date: moment(month).format('DDMMYY') }).query,
        });
      });
    }
  }

  async printReport() {
    let reportHTML;
    let reportName;
    this.setState({ ...this.state, printInProgress: true });
    reportHTML = document.querySelector('.hosViolationDetails-printable').outerHTML;
    reportName = `HOS Violation Summary`;
    PDF.generateTempPDFFromHTML(document.querySelector('.hosViolationDetails-printable'), reportName, undefined).then(
      tempFile => {
        const reportURL = tempFile.get('file')._url;
        Helpers.openDocumentLink(reportURL);
        this.setState({ ...this.state, printInProgress: false });
      }
    );
  }

  render() {
    const { state, props } = this;
    const driverTableHeaderRow =
      (<MDBRow className="hosViolationDetails-header p-2">
        <MDBCol sm="2">
          Date
        </MDBCol>
        <MDBCol sm="2">
          Time of Violation
        </MDBCol>
        <MDBCol sm="8">
          Violation
        </MDBCol>
      </MDBRow>
      );

    // const driverTitles = Object.keys(state.dailyCertsByVehicleHash).map(vehicleObjectId => {
    //   return getAttribute(state.dailyCertsByVehicleHash[vehicleObjectId].vehicle, 'unitId');
    // });

    const driversTable = state.driverObjArr.sort((a, b) => (getAttribute(a, 'user_fullName') - getAttribute(b, 'user_fullName'))).map(driver => {
      // const hosViolationsByDays =
      // for (i = 0; i < state.hosViolationArrByDriverHash[driver.id].length; i++) {

      // }
      const hosViolationRows = state.hosViolationArrByDriverHash[driver.id].map(hosViolationObj => {
        const time = getAttribute(hosViolationObj, 'triggerTime');
        const timezoneOffsetFromUTC = getAttribute(driver, 'timezoneOffsetFromUTC') ? getAttribute(driver, 'timezoneOffsetFromUTC') : moment.tz.guess();
        const timeUTC = moment(time).tz(timezoneOffsetFromUTC);
        return (
          <MDBRow key={hosViolationObj.id} className={"hosViolationDetails-row p-2"}>
            <MDBCol sm="2">
              <div className="mt-2">{`${moment(timeUTC).format('YYYY-MM-DD')}`}</div>
              {/* { moment(endTimeUTC).format('HH-mm-SS') !== '00-00-00' &&
                <div className="mt-2" style={{ fontSize: '.9em' }}>{`${moment(timeUTC).format('YYYY-MM-DD')} / ${moment(endTimeUTC).format('YYYY-MM-DD')}`}</div>
              } */}
              {/* <div style={{ fontSize: '.85em' }}>({ timezoneOffsetFromUTC })</div> */}
            </MDBCol>
            <MDBCol sm="2">
              <div className="mt-2">{`${moment(timeUTC).format('h:mm a')}`}</div>
            </MDBCol>
            <MDBCol className="verticalAlignMiddle" sm="6">
              {HOSRegulation[hosViolationObj.get('regulationInt')] && HOSRegulation[hosViolationObj.get('regulationInt')].message}
            </MDBCol>
            <MDBCol sm="2" className="text-right">
              <MDBBtn
                className="mt-2"
                size="sm"
                color='primary'
                size='sm'
                onClick={() => history.push({ pathname: 'driver', search: "driver=" + driver.id + "&view=hosEvents" + "&date=" + moment(time).format('DDMMYY') })}
              >
                {'Go To Log'}
              </MDBBtn>
            </MDBCol>
          </MDBRow>
        );
      });

      return (
        <SBCard key={driver.id} isCollapsible={false} className="hosViolationDetails-table" title={`Driver: ${Helpers.toTitleCase(getAttribute(driver, 'user_fullName'))}`} cardBodyStyle={{ maxHeight: 'none' }} headerBorder>
          <MDBRow className="p-0 px-2">
            <MDBCol className="p-0">
              {(hosViolationRows.length < 1)
                ? <div className="no-results-message-container">There are currently no HOS Violations detected in the time period</div>
                : (
                  <MDBRow>
                    <MDBCol>
                      {driverTableHeaderRow}
                      {hosViolationRows}
                    </MDBCol>
                  </MDBRow>
                )
              }
            </MDBCol>
          </MDBRow>
        </SBCard>
      );
    });

    const tempView = this.props.location ? getQueryParameter(this.props.location.search, 'd') : undefined;
    const queryStrObj = getDeserialized(this.props.location.search).params;
    const navItems = [
      {
        name: 'Last Two Weeks',
        isActive: !tempView || tempView === 'lastTwoWeeks',
        to: getSerialized({ ...queryStrObj, d: 'lastTwoWeeks' }).query,
      },
      {
        name: 'By Month',
        isActive: tempView === 'month',
        to: getSerialized({ ...queryStrObj, d: 'month', date: moment().format('DDMMYY') }).query,
      },
      {
        name: 'Last 24 Hours',
        isActive: tempView === '24hours',
        to: getSerialized({ ...queryStrObj, d: '24hours' }).query,
      },
    ];

    return (
      <MDBContainer className="px-5 hosViolationDetails-layout" fluid>
        {state.isLoading && <LoadingOverlay />}
        <Title title="HOS Violation Details"></Title>
        <MDBRow>
          <MDBCol sm="6">
            <SubNavigationBar navItems={navItems} />
          </MDBCol>
          <MDBCol sm="6">
            <MDBBtn
              size="sm"
              color="primary"
              className="align-middle"
              onClick={this.printReport}
              disabled={this.state.printInProgress}
            >
              <MDBIcon icon="print"></MDBIcon> Print
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol sm="12">
            {tempView === 'month' &&
              <FilterForm
                className="d-inline-block"
                handleFilter={this.handleFilter}
                clearFilter={() => this.handleFilter()}
                fields={state.filterFormFields}
                submitOnSelect
                disabled={state.isLoading}
              />
            }
            <SBSelect
              containerClassName="ml-2 mr-2 d-inline-block"
              className="sb-jobs-list-select"
              defaultToggleText={this.state.violationFilter.label}
              items={this.state.violationFilterItems}
              getSelectedItems={async (selectedFilter) => {
                await this.setState({ ...this.state, violationFilter: selectedFilter[0] });
                this.refreshState();
              }}
              showFilter
            />
          </MDBCol>
        </MDBRow>
        {state.driverObjArr.length > 0 ?
          <React.Fragment>
            <div className="hosViolationDetails-printable">
              {driversTable}
            </div>
          </React.Fragment>
          :
          <div className="no-results-message-container">There are currently no HOS Violations detected in the time period</div>
        }
      </MDBContainer>
    );
  }
}

HOSViolationDetails.propTypes = {
};

// Notes on objects in the state:

// driverAssignChanges: {
//   vehicleObjectId-dailyCertObjectId: {
//     driverObjectId: driverObject <Driver>
//     coDriverObjectId: driverObject <Driver>
//   }
// }

// dailyCertsByVehicleHash: {
//   vehicleObjectId: {
//     vehicle: vehicleObject <Vehicle>,
//     dailyCertifications: {
//       dailyCertObjectId: {
//         dailyCertification: dailyCertificationObject <ELDDailyCertification>,
//         eldEvents [array of eldEvents <ELDEvent>],
//       },
//       ...
//     }
//   },
//   ...
// }

export default HOSViolationDetails;
