import React from 'react';

import { SelectButton as PRSelectButton } from 'primereact/selectbutton';

import './style.scss';

/**
 * @description PrimeReact SelectButton component
 * @param {String} [className] - Custom className
 * @returns
 */
function SelectButton({ ...props }) {
  let className = 'sbc-select-button';
  if (props.className) className += ` ${props.className}`;

  if (props.variant === 'small') className += ' select-button-small';

  return (<PRSelectButton {...props} className={className} />);
}

export default SelectButton;
