import { formatName } from 'api/Helpers';

export default class Driver {
  /**
   * 
   * @param {string} objectId 
   * @param {string} firstName
   * @param {string} lastName 
   * @param {string} phoneNumber 
   * @param {string} driversLicense 
   * @param {string} driversLicenseCountry 
   * @param {Date}   licenseExpiryDate 
   * @param {string} timezoneOffsetFromUTC 
   * @param {number} eldStatusInt
   * @param {object} latestELDEvent
   */
  constructor(objectId, firstName, lastName, phoneNumber, driversLicense, driversLicenseCountry, licenseExpiryDate, timezoneOffsetFromUTC, eldStatusInt, latestELDEvent) {
    this.objectId = objectId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phoneNumber = phoneNumber;
    this.driversLicense = driversLicense;
    this.driversLicenseCountry = driversLicenseCountry;
    this.licenseExpiryDate = licenseExpiryDate;
    this.timezoneOffsetFromUTC = timezoneOffsetFromUTC;
    this.eldStatusInt = eldStatusInt;
    this.latestELDEvent = latestELDEvent;
  }

  get fullName() {
    return formatName(`${this.firstName} ${this.lastName}`);
  }
  get user_fullName() {
    return formatName(`${this.firstName} ${this.lastName}`);
  }

  get user_phoneNumber() {
    return this.phoneNumber;
  }
}