/** @module DispatchTransfer */

import {
  copyQuery,
  count,
  createQuery,
  createQueryOr,
  findRecords,
  getAttribute,
  getCurrentUserSessionToken,
  getCurrentUserCompanyObjectId,
  includePointers,
  setQueryRestriction,
  setReturnSelectAttributes,
  sortQuery,
  updateRecord,
  addRecord,
  destroyRecord,
} from 'sb-csapi/dist/AAPI';

import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @category Dispatch
 * @subcategory Transfer
 *
 * @description Get a list of Dispatch transfers for a given dispatch job
 *
 * @param {object} options - See example
 * @param {string} dispatchJobObjectId - The specific dispatch job that the transfer belongs to
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {int} page - The current page for pagination
 * @param {int} limit - The limit of records obtained per pagination
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { object } - { dispatchTransfers: [DispatchTransfer, ...] }
 */
async function getDispatchTransfers(
  options = { sessionToken: getCurrentUserSessionToken() },
  dispatchJobObjectId,
  filters = [],
  sortBy = new Sort(QuerySortOrder.DESCENDING, 'createdAt'),
  includedPointers = [],
  selectedAttributes = [],
  page = 0,
  limit = 20,
  queryAll
) {
  if (!dispatchJobObjectId) throw new Error('No dispatch job specified');

  let dispatchTransferQuery = createQuery('DispatchTransfer');
  setQueryRestriction(dispatchTransferQuery, QueryRestriction.EQUAL_TO, 'dispatchJob', dispatchJobObjectId);

  const _filters = [...filters];

  // set universal filters
  // _filters.push(new Filter(QueryRestriction.NOT_EQUAL_TO, 'isHidden', true)); // get all non-deleted organizations

  _filters.map((filter) =>
    setQueryRestriction(dispatchTransferQuery, filter.queryRestriction, filter.attribute, filter.value)
  );

  // at this point, copy current query to get the number of pages for pagination
  const dispatchTransferCountQuery = copyQuery(dispatchTransferQuery);

  // sort
  sortQuery(dispatchTransferQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchTransferQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchTransferQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(dispatchTransferQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(dispatchTransferQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the count and the documents
  const promises = [
    count(options, dispatchTransferCountQuery),
    findRecords(options, dispatchTransferQuery, false, queryAll),
  ];

  try {
    const [totalDispatchTransfersCount, dispatchTransfers] = await Promise.all(promises);
    return { totalDispatchTransfersCount, dispatchTransfers };
  } catch (err) {
    throw new Error(err);
  }
}

async function getDispatchTransfersSum(dispatchQuote, dispatchJobObjectId) {
  const isRatePerDistance = getAttribute(dispatchQuote, 'isRatePerDistance', true);

  const { dispatchTransfers } = await getDispatchTransfers(
    undefined,
    dispatchJobObjectId,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );

  if (!dispatchTransfers) return 0;

  let totalQuoteAmount = 0;

  if (isRatePerDistance) {
    totalQuoteAmount = dispatchTransfers.reduce((quoteTotal, dispatchTransfer) => {
      const quoteAmount = getAttribute(dispatchTransfer, 'quoteAmount', true) || 0;
      const estimatedDistance = getAttribute(dispatchTransfer, 'estimatedDistance', true) || 0;
      return quoteAmount * estimatedDistance + quoteTotal;
    }, 0);
  } else {
    totalQuoteAmount = dispatchTransfers.reduce((quoteTotal, dispatchTransfer) => {
      const quoteAmount = getAttribute(dispatchTransfer, 'quoteAmount', true) || 0;
      return quoteAmount + quoteTotal;
    }, 0);
  }

  return totalQuoteAmount;
}

/**
 * @memberof module:DispatchTransfer
 *
 * @description Update a dispatch transfer
 *
 * @param {DispatchTransfer} dispatchTransfer - The dispatchTransfer to update. If not available, use parameter dispatchTransferObjectId instead
 * @param {string} dispatchTransferObjectId - The objectId of the dispatchTransfer object we wish to update. Use if parameter dispatchTransfer is unavailable
 * @param {object} keyValueObj - The select fields we wish to update
 * @param {bool} save - If true, executes save on the changes. If false, holds the changes locally but is not saved
 *
 * @returns {DispatchTransfer} - The updated DispatchTransfer
 */
async function updateDispatchTransfer(
  dispatchTransfer,
  dispatchTransferObjectId,
  keyValueObj,
  save,
  options = { sessionToken: getCurrentUserSessionToken() }
) {
  if (!dispatchTransfer && !dispatchTransferObjectId)
    throw new Error('Missing Arguments: Must provide dispatchTransfer or dispatchTransferObjectId');
  if (dispatchTransfer && dispatchTransferObjectId)
    throw new Error('Arguments: Must provide only one of dispatchTransfer or dispatchTransferObjectId');

  let _dispatchTransfer = dispatchTransfer;

  try {
    if (!dispatchTransfer && dispatchTransferObjectId) {
      const dispatchTransferQuery = createQuery('DispatchTransfer');
      setQueryRestriction(dispatchTransferQuery, QueryRestriction.EQUAL_TO, 'objectId', dispatchTransferObjectId);
      _dispatchTransfer = await findRecords(options, dispatchTransferQuery, true);
    }

    if (_dispatchTransfer) {
      if (!Object.keys(keyValueObj).length) return _dispatchTransfer;

      _dispatchTransfer = await updateRecord(
        { sessionToken: getCurrentUserSessionToken() },
        _dispatchTransfer,
        keyValueObj,
        save
      );
      return _dispatchTransfer;
    } else {
      throw new Error(`DispatchTransfer does not exist`);
    }
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchTransfer
 *
 * @description - Update all dispatchTransfers in the same route (meant for tracking id and status)
 *
 * @param {string} routeName - routeName of dispatchTransfer to update
 * @param {DispatchJob} dispatchJob - dispatchJob of dispatchTransfer to update
 * @param {object} keyValueObj - The select fields we wish to update
 * @param {bool} save - If true, executes save on the changes. If false, holds the changes locally but is not saved
 *
 *
 */

async function updateSameRouteNameDispatchTransfers(
  options = { sessionToken: getCurrentUserSessionToken() },
  routeName,
  dispatchJob,
  keyValueObj,
  save,
) {
  if (!routeName || !dispatchJob) throw new Error('routeName or DispatchJob not found');
  const dispatchTransferQuery = createQuery('DispatchTransfer');
  setQueryRestriction(dispatchTransferQuery, QueryRestriction.EQUAL_TO, 'dispatchJob', dispatchJob);
  setQueryRestriction(dispatchTransferQuery, QueryRestriction.EQUAL_TO, 'routeName', routeName);
  const dispatchTransfers = await findRecords(options, dispatchTransferQuery, false, true);
  try {
    for (let i = 0; i < dispatchTransfers.length; i++) {
      await updateRecord(
        { sessionToken: getCurrentUserSessionToken() },
        dispatchTransfers[i],
        keyValueObj,
        save,
      );
    }
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchTransfer
 *
 * @description Add new dispatch transfer record
 * @param {object} keyValueObj - The select fields we wish to save
 *
 * @returns {DispatchTransfer} - The added DispatchTransfer
 */
async function addDispatchTransfer(keyValueObj) {
  try {
    const dispatchTransfer = await addRecord(
      { sessionToken: getCurrentUserSessionToken() },
      'DispatchTransfer',
      keyValueObj,
      getCurrentUserCompanyObjectId()
    );
    return dispatchTransfer;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:dispatchTransfer
 *
 * @description Deletes single DispatchTransfer record
 *
 * @param {DispatchReferenceNumber} dispatchTransfer - DispatchTransfer record to remove
 *
 * @returns {object} - The DispatchTransfer record that was removed
 */
async function deleteDispatchTransfer(dispatchTransfer) {
  if (!dispatchTransfer) throw new Error('Error [api/DispatchTransfer deleteDispatchTransfer]: No DispatchTransfer');

  try {
    return await destroyRecord({ sessionToken: getCurrentUserSessionToken() }, dispatchTransfer);
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getDispatchTransfers,
  getDispatchTransfersSum,
  updateDispatchTransfer,
  updateSameRouteNameDispatchTransfers,
  addDispatchTransfer,
  deleteDispatchTransfer,
};
