import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// CSAPI API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// sbCore Components
import Card from 'sbCore/Card/Card';
import Button from 'sbCore/Button/Button';
import IconLabel from 'sbCore/IconLabel/IconLabel';
import Tooltip from 'sbCore/Tooltip/Tooltip';

// Components
import DispatchPayableAddDialog from 'components/Dispatch/DispatchPayableAddDialog/DispatchPayableAddDialog';

// Styles
import './style.scss';

/**
 * @description A card indicating payment information is required. Also contains a pop up with a button to add the information
 * @param {String} [className] - Custom className
 * @param {Function} [onAddInformation] - Callback function when the tooltip button is clicked
 * @param {Function} [onSave] - Callback function when adding a payable is completed
 * @param {DispatchJob} dispatchJob - The selected DispatchJob
 * @param {DispatchPayee} dispatchPayee - The selected DispatchPayee
 * @param {Boolean} isVisible - Whether or not to show the card
 */
function PaymentInformationRequiredPrompt({ ...props }) {

  // ** useStates ** //
  const [isVisible, setIsVisible] = useState(false);
  const [dispatchPayee, setDispatchPayee] = useState(null);

  // ** useEffects ** //
  useEffect(() => {
    if (!props.dispatchPayee) return;
    setDispatchPayee(props.dispatchPayee);
  }, [props.dispatchPayee]);

  // ** Callback Functions ** //
  const onAddInformation = () => {
    if (props.onAddInformation) props.onAddInformation();
    setIsVisible(true);
  };

  const onSave = (dispatchPayable) => {
    if (props.onSave) {
      props.onSave(dispatchPayable);
    }
    setIsVisible(false);
  };

  // ** Misc ** //
  let className = 'payment-information-required-prompt';
  if (props.className) className += ` ${props.className}`;
  const tooltipId = uniqid();

  const payeeName = getAttribute(props.dispatchPayee, 'name', true);

  return (
    <>
      {props.visible && (

        <div className={`${className}`}>

          <Card className={`payment-information-card ${tooltipId}`}>
            <IconLabel iconClassName="pi pi-exclamation-circle  amber-text">
              <div className="payment-text">
                Payment Information Required
              </div>
            </IconLabel>
          </Card>

          <Tooltip className="payment-required-tooltip" target={`.${tooltipId}`} autoHide={false}>
            <div className="text-sm">
              <span className="font-bold">{payeeName}</span> requires additional payment information
            </div>
            <div className="flex justify-content-end">
              <Button className="m-2 text-xs" label="Add Information" onClick={onAddInformation} />
            </div>
          </Tooltip>

          <DispatchPayableAddDialog
            dispatchPayee={dispatchPayee}
            dispatchJob={props.dispatchJob}
            visible={isVisible}
            onCancel={() => setIsVisible(false)}
            onSave={onSave}
          />

        </div>
      )}
    </>
  );
}

export default PaymentInformationRequiredPrompt;
