import React from 'react';
import PropTypes from 'prop-types';

// CSS
import styles from './ErrorList.module.scss';

function ErrorList(props) {
  const errorList = props.errors.map((error, index) => <li key={`${(new Date()).getTime() + index}errorList`} style={props.listStyle}>{ error }</li>);

  return (
    <div className={styles.errorList} style={props.containerStyle}>
      <ul style={props.uListStyle}>
        { errorList }
      </ul>
    </div>
  );
}

ErrorList.propTypes = {
  containerStyle: PropTypes.object,
  uListStyle: PropTypes.object,
  listStyle: PropTypes.object,
  errors: PropTypes.array,
};

export default ErrorList;
