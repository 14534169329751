import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import { fetchVendorsForState, deleteVendorsForState, disableVendor } from 'actions/Vendor';

// API
import * as Helpers from 'api/Helpers';

// Components
import Title from 'components/LayoutTitle/view/Title';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import AddNewVendorButton from 'components/AddEditLocation/container/AddNewVendorButton';
import LocationsTable from 'components/LocationsTable/container/LocationsTable';

// CSS
import styles from './LocationsLayout.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

class LocationsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayFilterForm: false,
      filter: [],
      sort: { name: 'descending' },
    };

    this.state.filterFormFields = [
      { attrName: 'name', queryType: 'searchString', fullName: 'Location Name', placeholder: '.....', type: 'text' },
      { attrName: 'address_address', queryType: 'searchString', fullName: 'Address', placeholder: '.....', type: 'text' },
      { attrName: 'address_city', queryType: 'searchString', fullName: 'City', placeholder: '.....', type: 'text' },
      { attrName: 'address_country', queryType: 'searchString', fullName: 'Country', placeholder: '.....', type: 'text' },
      { attrName: 'address_stateProvince', queryType: 'searchString', fullName: 'State/Province', placeholder: '.....', type: 'text' },
    ];

    this.disableVendor = this.disableVendor.bind(this);
    this.showFilter = this.showFilter.bind(this);
    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
  }

  componentDidMount() {
    document.title = 'Locations - Switchboard';
    const clientHeight = document.documentElement.clientHeight;
    this.vendorsTableHeight = document.documentElement.clientHeight * 0.7; // 70vh defined by CSS
    if (clientHeight <= 1366) { // laptop size
      this.vendorsTableHeight = clientHeight * 0.6;
    }
    this.vendorsTableRowAmount = Math.round(this.vendorsTableHeight / 43) + 5; // amount of el's to display
    this.pageCount = 0; // result fetch multiplier
    fetchVendorsForState(this.pageCount, this.vendorsTableRowAmount, [], this.state.sort);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.Vendor !== this.props.Vendor || nextState !== this.state) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    deleteVendorsForState();
  }

  disableVendor(confirmBool, vendorParseObj) {
    const newState = { ...this.state };
    this.setState(newState);
    if (confirmBool) {
      disableVendor(vendorParseObj);
    }
  }

  showFilter() {
    this.state.displayFilterForm = !this.state.displayFilterForm;
    this.setState(this.state);
  }

  filter(filterObject) {
    this.pageCount = 0;
    const newState = { ...this.state, filter: filterObject };
    this.setState(newState);
    deleteVendorsForState().then(() => {
      fetchVendorsForState(this.pageCount, this.vendorsTableRowAmount, newState.filter, this.state.sort);
    });
  }

  clearFilter() {
    this.pageCount = 0;
    this.setState({ ...this.state, filter: [] });
    deleteVendorsForState().then(() => {
      fetchVendorsForState(this.pageCount, this.vendorsTableRowAmount, [], this.state.sort);
    });
  }

  scrollHandler(e) {
    if (Helpers.scrolledToBottom(e, 10, this.lastScrollPos)) {
      this.pageCount++;
      fetchVendorsForState(this.pageCount, this.vendorsTableRowAmount, this.state.filter, this.state.sort, this.state.tableView);
      this.lastScrollPos = e.target.scrollTop;
    }
  }

  sortHandler(attribute) {
    // sort list of vendors based on attribute
    deleteVendorsForState().then(() => {
      this.pageCount = 0;
      const newState = { ...this.state };
      const currentSort = this.state.sort[attribute]; // the current sort, if defined
      if (currentSort) {
        if (currentSort === 'ascending') {
          newState.sort[attribute] = 'descending';
        } else {
          newState.sort[attribute] = 'ascending';
        }
      } else if (attribute === 'name') {
        newState.sort = { name: 'descending' };
      } else if (attribute === 'address_address') {
        newState.sort = { address_address: 'descending' };
      }
      this.setState({ ...this.state, sort: newState.sort });
      fetchVendorsForState(this.pageCount, this.vendorsTableRowAmount, this.state.filter, newState.sort, this.state.tableView);
    });
  }

  render() {
    return (
      <div className={styles.locationsLayout}>
        <Title title="Locations">
          <div className="inlineBlock">
            <button className="inline buttonSpacedRight buttonDefault" onClick={this.showFilter}>Filter</button>
            <div className="floatRight"><AddNewVendorButton /></div>
          </div>
        </Title>
        {this.state.displayFilterForm &&
          <span>
            <FilterForm
              className="floatRight"
              handleFilter={this.filter}
              clearFilter={this.clearFilter}
              fields={this.state.filterFormFields}
            />
            <div className="clearBoth" />
          </span>
        }
        <LocationsTable
          Vendor={this.props.Vendor}
          disableVendor={this.disableVendor}
          scrollHandler={this.scrollHandler}
          sortHandler={this.sortHandler}
          sort={this.state.sort}
        />

      </div>
    );
  }
}

LocationsLayout.propTypes = {
  Vendor: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Vendor } = state;
  return {
    Vendor,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(LocationsLayout);

