import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import { Toggle, SelectField, MenuItem } from 'material-ui';

// CSS
import styles from './TrialEndModal.module.scss';

function CanceledModal(props) {
  return (
    <div className="translate-me">
      <div className={styles.description}>
        If you'd like to continue using Switchboard to easily manage your Hours of Service, IFTA, and more:
        <br />
        <br />
        {props.resellerCompanyObj &&
          <div>
            Please contact <var>{ props.resellerCompanyObj.get('name') }</var>.
          </div>
        }
        {!props.resellerCompanyObj &&
          <div>
            Please contact us at <a href="mailto:sales@onswitchboard.com"><b>sales@onswitchboard.com</b></a>.
            
            <hr />

            <div>
              <b>If you wish to use a new credit card:</b>&nbsp;&nbsp;<button className={`${styles.startButton} buttonDefault`} onClick={props.handleEnterCC} disabled={props.disableButton}>Enter New Credit Card Info</button>
            </div>
          </div>
        }
      </div>

      <hr />

      <div className={styles.contactUs}>
        Our team is working hard to make sure your experience with Switchboard is <b>amazing</b>
        <br />
        Please let us know if there's anything we can do for you!
      </div>
    </div>
  );
}

CanceledModal.propTypes = {
  handleEnterCC: PropTypes.func.isRequired,
  disableButton: PropTypes.bool,
  resellerCompanyObj: PropTypes.object,
};

export default CanceledModal;
