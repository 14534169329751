import React, { useRef, useState, useEffect } from 'react';

// Api
import { getDispatchDocuments } from 'api/Dispatch/DispatchDocument';
import { getAttribute } from 'sb-csapi/dist/AAPI';
import moment from 'moment-timezone';

// Enums
import { QueryRestrictionTypes, QuerySortOrderTypes } from 'sb-csapi/dist/enums/Query';
import { AttributeTypes, StatusTypes } from 'enums/DispatchJob';

// sbCore
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import OverlayPanel from 'sbCore/OverlayPanel/OverlayPanel';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// styles
import './style.scss';

function DocumentGenerationSubsection({ dispatchJobObjectId, setActiveTabIndex }) {

  const op = useRef(null);

  const [dispatchTableContents, setDispatchTableContents] = useState([]);
  const [fetchAttributes, setFetchAttributes] = useState({
    page: 0,
    count: 10,
    filters: [
      new Filter(QueryRestrictionTypes.EXISTS, 'file'),
      new Filter(QueryRestrictionTypes.EQUAL_TO, 'dispatchJob', dispatchJobObjectId),
    ],
  });

  async function getDispatchDocumentTableContents() {
    if (!dispatchJobObjectId) return;
    try {
      const { totalDispatchDocumentsCount, dispatchDocuments } = await getDispatchDocuments(
        undefined, // options - default
        fetchAttributes.filters, // filters
        undefined, // sort - default
        undefined, // includes
        undefined, // selectedAttributes
        undefined, // page
        fetchAttributes.count, // limit
        true, // query all
      );

      // Now that we have the DispatchDocuments - convert it to a format that is usable to render the table
      const promises = dispatchDocuments.map(async (dispatchDocument) => {
        if (!dispatchDocument) return {};

        const dispatchDocumentObjectId = getAttribute(dispatchDocument, 'objectId');
        const createdAt = getAttribute(dispatchDocument, 'createdAt', true);
        const uploadedAt = (createdAt && moment(createdAt).format('DD-MM-YYYY HH:mm:ss')) || '-';

        const file = getAttribute(dispatchDocument, 'file', true);
        const fileName = file && file.name();
        const fileURL = file && file.url();

        return ({
          fileName,
          dispatchDocumentObjectId,
          uploadedAt,
          fileURL,
        });
      });

      const tableInformation = await Promise.all(promises);
      setDispatchTableContents(tableInformation);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    let didCancel = false;

    async function refresh() {
      if (!didCancel) await getDispatchDocumentTableContents();
    }

    refresh();
    return () => { didCancel = true; };
  }, ([dispatchJobObjectId]));

  // Functions
  function downloadDocument(e, fileURL) {
    e.preventDefault();
    window.open(fileURL);
  }

  // Template
  const documentGenerationFooter = (
    <div className="d-flex justify-content-end my-2">
      <div className="tab-change-button" onClick={() => setActiveTabIndex(4)}>
        Go to Document Generation
        <span className="pi pi-arrow-right ml-1" />
      </div>
    </div>
  );

  const actionsTemplate = (rowData) => {
    return (
      <div className="d-flex">
        <span className="pi pi-external-link action-icons" onClick={(e) => downloadDocument(e, rowData.fileURL)} />
        {/* <span className="pi pi-envelope action-icons" />
        <span className="pi pi-ellipsis-v action-icons ellipsis" onClick={(e) => op.current.toggle(e)} />
        <OverlayPanel innerRef={op}>
          <div>Hello</div>
        </OverlayPanel> */}
      </div>
    );
  };

  const uploadedAtDateBodyTemplate = (rowData) => <p className={`${rowData.uploadedAt === '-' && 'text-400'} mb-0`}>{rowData.uploadedAt}</p>;

  return (
    <ColoredSection
      className="document-generation-subsection"
      title="4. Document Generation"
      footer={documentGenerationFooter}
      variant="compact"
    >
      <DataTable value={dispatchTableContents} scrollable scrollHeight="200px" className="documents-datatable">
        <Column header="File Name" field="fileName" bodyClassName="file-name" />
        <Column header="Modified" field="uploadedAt" body={uploadedAtDateBodyTemplate} className="w-25" />
        <Column header="Actions" body={actionsTemplate} className="w-25" />
      </DataTable>
    </ColoredSection>
  );
}

export default DocumentGenerationSubsection;
