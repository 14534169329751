import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-mapbox-gl';
import moment from 'moment-timezone';

// CSS
import styles from './ComponentStyles.module.scss';

function LocationSearchPopup(props) {
  return (
    <Popup
      anchor="bottom"
      offset={{ bottom: [0, -20] }}
      coordinates={props.coordinates}
    >
      <div className={styles.popup}>
        <div><b>{props.locationSearch.name}</b></div>
        <div>{props.locationSearch.vicinity}</div>
      </div>
    </Popup>
  );
}

LocationSearchPopup.propTypes = {
  coordinates: PropTypes.array.isRequired,
  locationSearch: PropTypes.object.isRequired,
};

export default LocationSearchPopup;
