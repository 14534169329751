import React from 'react';

// sbCore
import Badge from 'sbCore/Badge/Badge';

// sb-csapi
import { ELDStatus, ELDStatusDescriptionShort } from 'sb-csapi/dist/enums/ELD/ELDStatus';
import { ELDEventTypeCode, ELDEventTypeCodeDescriptionShort } from 'sb-csapi/dist/enums/ELD/ELDEventTypeCode';

// Styles
import './style.scss';

/**
 * @description Renders a status badge associated with a given eld type. If the type does not exist, return an empty <div />
 *
 * @param {int} eldEventTypeCodeInt - eldEventTypeCodeInt of a given record
 * @param {int} eldStatusInt - eldStatusInt of a given record
 * @returns
 *
 * @example
 * <DutyStatusBadge eldEventTypeCodeInt={eldEventTypeCodeInt.ON_DUTY_NOT_DRIVING} />
 */
function DutyStatusBadge(props) {
  let containerClassName = 'duty-status-badge-container';
  if (props.containerClassName) containerClassName += ` ${props.containerClassName}`;

  let className = 'duty-status-badge flex justify-content-center align-items-center';

  // figure out the value of StatusType{} from the given statusType
  let matchingStatusTypeObject = {};

  if (props.eldStatusInt !== undefined) {
    // ** CASE IF ELDSTATUSINT
    const eldStatusInt = props.eldStatusInt;

    Object.keys(ELDStatus).find(key => {
      const statusType = ELDStatus[key];
      const isMatchingStatus = statusType === eldStatusInt;

      if (isMatchingStatus) {
        // find the matching background color className (ex. SOME_VALUE -> some-value -> bg-ds-some-value)
        const colorClassKeyExpression = key.replace(/_/g, '-').toLowerCase();
        className += ` bg-ds-${colorClassKeyExpression}`;

        matchingStatusTypeObject.status = statusType;
        matchingStatusTypeObject.description = ELDStatusDescriptionShort[key];
      }
      return isMatchingStatus;
    });
  }
  else if (props.eldEventTypeCodeInt !== undefined) {
    // ** CASE IF ELDEVENTTYPECODEINT
    // If eldEventTypeCodeInt === 21 or 22, treat it as 13 (DRIVING)
    let eldEventTypeCodeInt = props.eldEventTypeCodeInt;
    if (eldEventTypeCodeInt === ELDEventTypeCode.INTERMEDIATE_LOG_WITH_CONVENTIONAL_LOCATION_PRECISION || eldEventTypeCodeInt === ELDEventTypeCode.INTERMEDIATE_LOG_WITH_REDUCED_LOCATION_PRECISION) {
      eldEventTypeCodeInt = ELDEventTypeCode.DRIVING;
    }

    Object.keys(ELDEventTypeCode).find(key => {
      const statusType = ELDEventTypeCode[key];
      const isMatchingStatus = statusType === eldEventTypeCodeInt;

      if (isMatchingStatus) {
        // find the matching background color className (ex. SOME_VALUE -> some-value -> bg-ds-some-value)
        const colorClassKeyExpression = key.replace(/_/g, '-').toLowerCase();
        className += ` bg-ds-${colorClassKeyExpression}`;

        matchingStatusTypeObject.status = statusType;
        matchingStatusTypeObject.description = ELDEventTypeCodeDescriptionShort[key];
      }
    });
  }

  if (props.className) className += ` ${props.className}`;

  if (!matchingStatusTypeObject.description) {
    return <div />
  }

  return (
    <div className={containerClassName}>
      <Badge className={className} value={matchingStatusTypeObject.description} />
    </div>
  );
}

export default DutyStatusBadge;