import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

import InvoiceAddCharge from 'components/Dispatch/DispatchDocument/DispatchDocuments/Generators/Invoice/InvoiceAddCharge';

// sbCore Components
import Toolbar from 'sbCore/Toolbar/Toolbar';
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import Button from 'sbCore/Button/Button';

// Shared Document Components
import DocumentInputNumber from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputNumber/InputNumber';
import DocumentInputTextarea from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputTextarea/InputTextarea';

// Styling
import './style.scss';

/**
 * @description InvoiceAdditionalCharges
 * @param {Function} updateAdditionalChargeRates - called to update charges in parent component when charges is updated
 *
 * @returns
 */
const InvoiceAdditionalCharges = (props) => {

  // ** useStates ** //
  const [charges, setCharges] = useState([]);
  const [showAddCharge, setShowAddCharge] = useState(false);

  // ** useEffects ** //

  // Updates the additionalChargeRates in the parent component
  useEffect(() => {
    const additionalChargeRates = charges.map((charge) => {
      return charge.rate;
    });

    props.updateAdditionalChargeRates(additionalChargeRates);
  }, [charges]);

  // ** Callback Functions ** //
  const addCharge = () => {
    setShowAddCharge(true);
  };

  const hideAddCharge = () => {
    setShowAddCharge(false);
  };

  const deleteCharge = (chargeId) => {
    const _charges = charges.filter(charge => {
      return charge.id !== chargeId;
    });
    setCharges(_charges);
  };

  const saveCharge = (rate, description) => {
    const charge = {
      id: uniqid(),
      rate,
      description,
    };

    setCharges(charges => [...charges, charge]);

    hideAddCharge();
  };

  const updateCharge = (value, charge, key) => {
    const chargeId = charge.id;
    const chargeIndex = charges.findIndex((charge) => charge.id === chargeId);

    let selectedCharge = charges.filter((_charge) => {
      return charge.id === _charge.id;
    });
    selectedCharge = selectedCharge[0];

    let updatedCharge = null;
    if (key === 'rate') {
      updatedCharge = Object.assign(selectedCharge, { rate: value });
    } else {
      updatedCharge = Object.assign(selectedCharge, { description: value });
    }

    const _charges = [...charges];

    if (chargeIndex !== -1) {
      _charges[chargeIndex] = updatedCharge;
    }

    setCharges(_charges);
  };

  // ** Table Column Templates ** //
  const rateTemplate = (charge) => {
    const rate = charge.rate;
    return (
      <div className="input-field rate-field flex flex-row">
        $
        <DocumentInputNumber
          inputClassName="text-sm"
          value={rate}
          onChange={(value) => updateCharge(value, charge, 'rate')}
          mode="decimal"
          minFractionDigits={2}
          success={rate}
          warning={!rate || rate == ''}
          isDownloaded={props.isDownloaded}
        />
      </div>
    );
  };

  const descriptionTemplate = (charge) => {
    const description = charge.description;
    return (
      <div className="input-field description-field">
        <DocumentInputTextarea
          inputClassName="text-sm"
          value={description}
          onChange={(value) => updateCharge(value, charge, 'description')}
          success={description}
          warning={!description || description == ''}
          isDownloaded={props.isDownloaded}
        />
      </div>
    );
  };

  const deleteButtonTemplate = (charge) => {
    return (
      <div className="delete-button">
        <Button
          className="p-button-rounded"
          icon="pi pi-times"
          onClick={() => deleteCharge(charge.id)}
        />
      </div>
    );
  };

  // ** Components ** //
  const leftContents = (
    <>
      <div className="pr-2 font-bold">Additional Charges</div>
      <div className="add-button">
        <Button className="p-button-rounded" icon="pi pi-plus" onClick={() => addCharge()} />
      </div>
    </>
  );

  const additionalChargesHeader = (
    <Toolbar left={leftContents} />
  );

  const addChargeModal = (
    <InvoiceAddCharge
      showAddCharge={showAddCharge}
      hideAddCharge={hideAddCharge}
      saveCharge={saveCharge}
      isDownloaded={props.isDownloaded}
    />
  );

  const additionalChargesTable = (
    <DataTable value={charges} emptyMessage=" ">
      <Column body={rateTemplate} header="RATE" />
      <Column body={descriptionTemplate} header="DESCRIPTION" />
      <Column body={deleteButtonTemplate} />
    </DataTable>
  );

  return (
    <div className="invoice-additional-charges mx-7 mb-5">
      {additionalChargesHeader}

      {addChargeModal}

      {additionalChargesTable}
    </div>
  );
};

export default InvoiceAdditionalCharges;
