import Parse from 'parse';
import moment from 'moment-timezone';
import * as jsPDF from 'jspdf';
import * as html2canvas from 'html2canvas';

import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';

import * as PDFLocal from 'api/PDF/PDFLocal';

import store from '../store';

import { addRecord, createTempPointer } from 'api/Parse';
import * as Helpers from './Helpers';

/** @module PDFer */

/**
 * @memberof module:PDFer
 * @param {*} string
 * @returns
 */
function getNumericEstimates(string) {
  // WARNING: guesstimate function. Given a string with values (ex. from a table of scores)
  // attempt to merge 'broken' values (ex. 50. 05 vs 50.05) and obtain the 'correct' numbers

  // For each '.' we find, if there is a space(s) after/before it (therefore decimal is detached from num; reattach)

  const newString = string.replace(/[^0-9\.,-\s\u2013\u2014]/gim, '') // replace alphabets with nothing
    .replace(/\u2013|\u2014/gm, '-') // replace the ms-word long dashes with normal
    .replace(/--/g, '') // replace double dashes created from the long dashes
    .replace(/\s+-\s+/g, '-') // combine hyphen-separated values
    .replace(/\s+/g, ' ') // replace multiple spaces (1+) with normal space
    .replace(/(\d+\.)(\s+)(\d+)/gim, '$1$3') // merge seperated decimal vals
    .replace(/(\d+)(\s+)(\.\d+)/gim, '$1$3'); // merge seperated decimal vals
  return newString;
}

/**
 * @memberof module:PDFer
 *
 * @param {*} pdf
 * @param {*} pageNum
 *
 * @returns
 */
function getPageContent(pdf, pageNum) {
  // get the content of a pdf given a page number
  // return page object
  const promise = new Promise((resolve, reject) => {
    pdf.getPage(pageNum).then(page => {
      page.getTextContent().then(pageContent => {
        resolve(pageContent);
      }, error => reject(error));
    });
  });
  return promise;
}

/**
 * @memberof module:PDFer
 *
 * @param {*} pdf
 * @param {*} pageStart
 * @param {*} pageEnd
 * @param {*} resolve
 * @param {*} reject
 * @param {*} _pdfContent
 */
function getDocumentContentSub(pdf, pageStart, pageEnd, resolve, reject, _pdfContent = []) {
  // Subroutine for getDocumentContent; Get content of a pdf entirely
  // pageStart determines where to start retrieving pages; pageEnd for where to terminate
  // Begin by getting content of the first page. If there are more pages to get afterwards, iterate
  getPageContent(pdf, pageStart).then(
    pageContent => {
      _pdfContent.push(pageContent);
      if (pageStart + 1 <= pageEnd) {
        getDocumentContentSub(pdf, pageStart + 1, pageEnd, resolve, reject, _pdfContent);
      } else {
        resolve(_pdfContent);
      }
    },
    error => reject(error)
  );
}

/**
 * @memberof module:PDFer
 *
 * @param {*} pdf
 * @param {*} pageStart
 * @param {*} pageEnd
 *
 * @returns
 */
function getDocumentContent(pdf, pageStart, pageEnd) {
  // a wrapper for getDocumentContentSub
  // return array of all page objects in the document
  const promise = new Promise((resolve, reject) => {
    if (!(pdf && pageStart && pageEnd)) {
      return;
    }
    getDocumentContentSub(pdf, pageStart, pageEnd, resolve, reject);
  });
  return promise;
}

/**
 * @memberof module:PDFer
 *
 * @param {*} pageObjects
 * @param {*} createNumberPadding
 * @param {*} createTextPadding
 *
 * @returns
 */
function getPageObjectsText(pageObjects, createNumberPadding, createTextPadding) {
  // function to get all text of a document/page given an array of page objects
  // createNumberPadding determines whether or not to pad numbers for separation
  const promise = new Promise(resolve => {
    let documentText = ''; // concat'd text of all pages given (through page objects)
    const pageObjectsLen = pageObjects.length;

    for (let i = 0; i < pageObjectsLen; i++) {
      const pageObjectItems = pageObjects[i].items;
      const pageObjectItemsLen = pageObjectItems.length;

      for (let j = 0; j < pageObjectItemsLen; j++) {
        const pageObjectItem = pageObjectItems[j];
        let str = pageObjectItem.str;
        // if text padding is true...
        if (createTextPadding) {
          str = ` ${pageObjectItem.str} `;
        }
        // if number padding is true, determine how to add padding accordingly...
        if (createNumberPadding && parseFloat(pageObjectItem.str)) {
          str = ` ${pageObjectItem.str} `;
        }

        documentText += str;
      }
    }
    resolve(documentText);
  });
  return promise;
}

/**
 * @memberof module:PDFer
 *
 * @param {*} docHtml
 * @param {*} filename
 * @param {*} options
 * @param {*} htmlElement
 *
 * @returns
 */
async function generatePDFFromHTML(docHtml, filename, options, htmlElement) {
  // METHOD 1 - HTML2CANVAS
  // html2canvas(docHtml).then((canvas) => {
  //   const imgData = canvas.toDataURL('image/png');
  //   const pdf = new jsPDF('p', 'px', 'a4');
  //   var width = pdf.internal.pageSize.getWidth();
  //   var height = pdf.internal.pageSize.getHeight();

  //   pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
  //   pdf.save(filename);
  // });

  // METHOD 2 - Server side
  return await Parse.Cloud.run('generatePDFFromHTML', { docHtml, filename, options });
}

/**
 * @memberof module:PDFer
 *
 * @param {*} htmlElement
 * @param {*} filename
 * @param {*} options
 *
 * @returns
 */
async function generateTempPDFFromHTML(htmlElement, filename, options = {}) {
  const drawRoot = await drawDOM(htmlElement, { paperSize: 'A4', margin: 30, scale: 0.5, ...options });
  const dataUri = await exportPDF(drawRoot);
  const file = await PDFLocal.createFile(filename, { base64: dataUri });

  const tempFile = {
    file: file.source,
  }

  // var fileData = Array.prototype.slice.call(new Buffer(readFileData), 0);

  // var parseFile = new Parse.File(filename, fileData);
  // var TempFile = Parse.Object.extend('TempFile');
  // var tempFile = new TempFile();
  // // Set Company ACL
  // var companyReadWriteACL = new Parse.ACL();
  // companyReadWriteACL.setRoleReadAccess(companyId, true);
  // companyReadWriteACL.setRoleWriteAccess(companyId, true);
  // tempFile.setACL(companyReadWriteACL);
  // tempFile.set('file', parseFile);
  // tempFile.save(null, { useMasterKey: true }).then(
  //   function (tempFileParseObject) {
  //     resolve(tempFileParseObject);
  //   },
  //   function (error) {
  //     console.log('Could not write PDF to Parse [filepath, userId, companyId]: ' + [filepath, userId, companyId]);
  //     reject(error);
  //   }
  // );


  return await addRecord('TempFile', tempFile);
}

function test() {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('pdfReadFormValues', {
      pdfURL: 'http://www2.gov.bc.ca/assets/gov/taxes/sales-taxes/forms/fin-360-international-fuel-tax-agreement-ifta-quarterly-tax-return.pdf',
      filename: `bc-tax-${Parse.User.current().id}-${Math.random()}.pdf`,
    }).then(
      result => {
        resolve(result);

        Parse.Cloud.run('pdfFillForm', {
          pdfURL: 'http://www2.gov.bc.ca/assets/gov/taxes/sales-taxes/forms/fin-360-international-fuel-tax-agreement-ifta-quarterly-tax-return.pdf',
          filename: `bc-tax-${Parse.User.current().id}-${Math.random()}.pdf`,
          fieldValuePairs: {
            name: 'Jorge Gonzales de Sanchez',
            address1: 'llllll',
            iftanumber: 'BC300-11111',
            media: 11111111,
            'C4.0': 'BC', 'C5.0': 'D', 'C6.0': 300, 'C7.0': 500, 'C9.0': 80,
          },
        }).then(
          result => {
            // console.log(result.get('file')._url);
            resolve(result);
          }
        );
      }
    );


  });
  return promise;
}

export {
  generatePDFFromHTML,
  generateTempPDFFromHTML,
  getDocumentContent,
  getNumericEstimates,
  getPageContent,
  getPageObjectsText,
  test,
};
