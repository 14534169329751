import React from 'react';

import { DataView as PRDataView } from 'primereact/dataview';

import './style.scss';

/**
 * DataView component (from PrimeReact)
 * 
 * @param {String} [className] - Custom className overrides for DataView
 * @returns {JSX} Returns the data view component
 */
export default function DataView({ ...props }) {
  let className = 'sbc-dataview';
  if (props.className) className += ` ${props.className}`;

  return (<PRDataView {...props} className={className} />);
}
