import React from 'react';
import moment from 'moment-timezone';
import uniqid from 'uniqid';
import { Link } from 'react-router-dom';

// Components
import SBTable from 'components/Shared/SBTable/SBTable';
import Tooltip from 'sbCore/Tooltip/Tooltip';

// API
import * as Helpers from '../../../api/Helpers';
import { getLocationDescriptionBreakdown } from 'api/VehicleLocation/VehicleLocation';
import { createTempRecord, getCurrentUser, getAttribute, updateRecord, addRecord, createTempPointer, createQuery, setQueryRestriction, find } from 'api/Parse';


class AddEditRouteModalTablePreview extends React.Component {
  constructor(props) {
    super();
    this.generateDetailsViewTable = this.generateDetailsViewTable.bind(this);
  }

  /**
   * @description Generates the table preview using the iftaRoutes passed in from the addEditRouteModal
   */
  generateDetailsViewTable() {
    const { props } = this;

    return props.iftaRouteRecordsForTablePreview.map((iftaRoute, index) => {
      const objectId = iftaRoute.id || iftaRoute._localId;
      const dateStart = getAttribute(iftaRoute, 'dateStart') || 'N/A';
      const dateEnd = getAttribute(iftaRoute, 'dateEnd') || 'N/A';
      const vehicleLocationStart = getAttribute(iftaRoute, 'vehicleLocationStart') || 'Undefined';
      const vehicleLocationEnd = getAttribute(iftaRoute, 'vehicleLocationEnd') || 'Undefined';
      const fuelPurchases = getAttribute(iftaRoute, 'fuelPurchases') || 'N/A';
      const savedVehicleKm = getAttribute(iftaRoute, 'savedVehicleKm') || 0;
      const iftaRouteDriverPeriods = getAttribute(iftaRoute, 'iftaRouteDriverPeriods');
      const totalVehicleKmStart = getAttribute(iftaRoute, 'totalVehicleKmStart') || 0;
      const totalVehicleKmEnd = getAttribute(iftaRoute, 'totalVehicleKmEnd') || 0;
      // const stateProvince = getAttribute(iftaRoute, 'stateProvince');
      // const vehicleUnitId = getAttribute(iftaRoute, 'vehicleUnitId');
      // const totalVehicleKmDiff = getAttribute(iftaRoute, 'totalVehicleKmDiff');
      // const distanceKm = getAttribute(iftaRoute, 'distanceKm');
      // const totalGallons = getAttribute(iftaRoute, 'totalGallons');

      const prevDateEnd = index > 0 && moment(getAttribute(props.iftaRouteRecordsForTablePreview[index - 1], 'dateEnd')).tz(props.driverTimeZoneStr);
      const dateStartFormatted = moment(dateStart).format('YYYY-MM-DD HH:mm');
      const dateEndFormatted = moment(dateEnd).format('YYYY-MM-DD HH:mm');

      let prevVehicleLocationEnd;
      let prevEndVehicleLocationStateProvince;
      let prevEndVehicleLocationAprxShortName;

      // Ensure we have sufficient information to be able to display the row in the table preview
      // if (
      //   (dateStart !== undefined && dateEnd !== undefined &&
      //   vehicleLocationStart !== undefined && getAttribute(vehicleLocationStart, 'stateProvince') !== undefined && getAttribute(vehicleLocationStart, 'aprxShortName') !== undefined && getAttribute(vehicleLocationStart, 'locationDescriptionUS') !== undefined &&
      //   vehicleLocationEnd !== undefined && getAttribute(vehicleLocationEnd, 'stateProvince') !== undefined && getAttribute(vehicleLocationEnd, 'aprxShortName') !== undefined && getAttribute(vehicleLocationEnd, 'locationDescriptionUS') !== undefined &&
      //   fuelPurchases !== undefined &&
      //   savedVehicleKm !== undefined &&
      //   iftaRouteDriverPeriods !== undefined &&
      //   totalVehicleKmStart !== undefined && totalVehicleKmEnd !== undefined) || objectId !== undefined
      // ) {

      if (index > 0) {
        prevVehicleLocationEnd = getAttribute(props.iftaRouteRecordsForTablePreview[index - 1], 'vehicleLocationEnd');

        // Obtain previous day's city + stateProvince
        prevEndVehicleLocationStateProvince = getAttribute(prevVehicleLocationEnd, 'stateProvince');
        prevEndVehicleLocationAprxShortName = getAttribute(prevVehicleLocationEnd, 'aprxShortName');
        const prevVehicleLocationEndString = getAttribute(prevVehicleLocationEnd, 'locationDescriptionUS');
        const prevVehicleLocationEndBreakdown = getLocationDescriptionBreakdown(prevVehicleLocationEndString);
        if (!prevEndVehicleLocationStateProvince) prevEndVehicleLocationStateProvince = prevVehicleLocationEndBreakdown.stateProvince.code !== undefined ? prevVehicleLocationEndBreakdown.stateProvince.code.toLowerCase() : 'N/A';
        if (!prevEndVehicleLocationAprxShortName) prevEndVehicleLocationAprxShortName = prevVehicleLocationEndBreakdown.city !== undefined ? prevVehicleLocationEndBreakdown.city : 'N/A';
      }

      // Obtain current day's start city + stateProvince
      let startVehicleLocationStateProvince = getAttribute(vehicleLocationStart, 'stateProvince');
      let startVehicleLocationAprxShortName = getAttribute(vehicleLocationStart, 'aprxShortName');
      const startVehicleLocationString = getAttribute(vehicleLocationStart, 'locationDescriptionUS');
      const startVehicleLocationBreakdown = getLocationDescriptionBreakdown(startVehicleLocationString);
      if (!startVehicleLocationStateProvince) startVehicleLocationStateProvince = startVehicleLocationBreakdown.stateProvince.code !== undefined ? startVehicleLocationBreakdown.stateProvince.code.toLowerCase() : 'N/A';
      if (!startVehicleLocationAprxShortName) startVehicleLocationAprxShortName = startVehicleLocationBreakdown.city !== undefined ? startVehicleLocationBreakdown.city : 'N/A';

      // check if the next start date matches with the previous end date
      const isDateStartMatchPrevDateEnd = (!prevDateEnd || (moment(dateStart).tz(props.driverTimeZoneStr).isSame(prevDateEnd) || (moment(dateStart).tz(props.driverTimeZoneStr).diff(prevDateEnd, 'minutes') <= 1)));

      // Obtain current day's end city + stateProvince
      let endVehicleLocationStateProvince = getAttribute(vehicleLocationEnd, 'stateProvince');
      let endVehicleLocationAprxShortName = getAttribute(vehicleLocationEnd, 'aprxShortName');
      const endVehicleLocationString = getAttribute(vehicleLocationEnd, 'locationDescriptionUS');
      const endVehicleLocationBreakdown = getLocationDescriptionBreakdown(endVehicleLocationString);
      if (!endVehicleLocationStateProvince) endVehicleLocationStateProvince = endVehicleLocationBreakdown.stateProvince.code !== undefined ? endVehicleLocationBreakdown.stateProvince.code.toLowerCase() : 'N/A';
      if (!endVehicleLocationAprxShortName) endVehicleLocationAprxShortName = endVehicleLocationBreakdown.city !== undefined ? endVehicleLocationBreakdown.city : 'N/A';

      const vehicleRowObj = {
        key: objectId,
        props: { className: 'sb-table-row' },
        columns: [
          {
            // Trip Start
            element: (
              <div className="sb-table-cell-div" id={objectId}>
                <b>
                  {!isDateStartMatchPrevDateEnd && (
                    <Tooltip target={`.dateTime${objectId}`} />
                  )}
                  <span
                    className={isDateStartMatchPrevDateEnd ? '' : `errorText dateTime${objectId}`}
                    data-pr-tooltip="Date/Time not matching previous"
                  >
                    {dateStartFormatted}
                  </span>
                </b>
                <br />
                {!(index === 0 || (index > 0 && (startVehicleLocationStateProvince === prevEndVehicleLocationStateProvince))) &&
                  <Tooltip target={`.stateProvince${objectId}`} />
                }
                <span
                  className={(index === 0 || (index > 0 && (startVehicleLocationStateProvince === prevEndVehicleLocationStateProvince))) ? '' : `errorText boldText stateProvince${objectId}`}
                  data-pr-tooltip="State/Province not matching previous"
                >
                  {vehicleLocationStart && `${startVehicleLocationAprxShortName}, ${startVehicleLocationStateProvince !== 'undefined' ? startVehicleLocationStateProvince.toUpperCase() : 'undefined'}`}
                </span>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          {
            // Trip End
            element: (
              <div className="sb-table-cell-div">
                <b> {dateEndFormatted} </b>
                <br />
                {`${endVehicleLocationAprxShortName}, ${endVehicleLocationStateProvince !== "undefined" ? endVehicleLocationStateProvince.toUpperCase() : 'undefined'}`}
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          {
            // Odometer Start
            element: (
              <div className="sb-table-cell-div">
                <b>{props.distanceUnitFilter.value === 'km' ? totalVehicleKmStart.toFixed(0) : Helpers.convertDistance(totalVehicleKmStart, 'km', 'mi').toFixed(0)}</b>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          {
            // Odometer End
            element: (
              <div className="sb-table-cell-div">
                <b>{props.distanceUnitFilter.value === 'km' ? totalVehicleKmEnd.toFixed(0) : Helpers.convertDistance(totalVehicleKmEnd, 'km', 'mi').toFixed(0)}</b>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          {
            // Drivers
            element: (
              <div className="sb-table-cell-div">
                {iftaRouteDriverPeriods !== 'undefined' && iftaRouteDriverPeriods &&
                  iftaRouteDriverPeriods.map((iftaRouteDriverPeriod) => {
                    const driver = getAttribute(iftaRouteDriverPeriod, 'driver');
                    if (!driver) {
                      const driverForPreview = iftaRouteDriverPeriod;
                      return (
                        <div key={iftaRouteDriverPeriod.id}>
                          <Link
                            className="inlineBlock clickable textHoverHighlight"
                            to={{ pathname: '/driver', search: `?driver=${driverForPreview.objectId}&view=hosEvents&date=${moment(getAttribute(driverForPreview, 'dateStart')).format('DDMMYY')}` }}
                          >
                            <b>{Helpers.toTitleCase(getAttribute(driverForPreview, 'user_fullName'))}</b>
                          </Link>
                        </div>
                      );
                    } else {
                      return (
                        <div key={iftaRouteDriverPeriod.id}>
                          <Link
                            className="inlineBlock clickable textHoverHighlight"
                            to={{ pathname: '/driver', search: `?driver=${driver.objectId}&view=hosEvents&date=${moment(getAttribute(driver, 'dateStart')).format('DDMMYY')}` }}
                          >
                            <b>{Helpers.toTitleCase(getAttribute(driver, 'user_fullName'))}</b>
                          </Link>
                        </div>
                      );
                    }
                  })}
                {iftaRouteDriverPeriods === 'undefined' && iftaRouteDriverPeriods &&
                  'undefined'
                }
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle' },
          },
          {
            // Fuel
            element: (
              <div className="sb-table-cell-div">
                {fuelPurchases && fuelPurchases.map((fuelPurchase) => {
                  const timeMillis = getAttribute(fuelPurchase, 'timeMillis');
                  const volumeUnits = getAttribute(fuelPurchase, 'volumeUnits');
                  const volumePumped = getAttribute(fuelPurchase, 'volumePumped');
                  const fuelType = getAttribute(fuelPurchase, 'fuelType');
                  const currency = getAttribute(fuelPurchase, 'currency');
                  const totalPaid = getAttribute(fuelPurchase, 'totalPaid');

                  return (
                    <div>
                      <div className="inlineBlock">
                        {fuelPurchase && `${moment(timeMillis).format('MMM D')}: `}
                        <b>{`${volumeUnits === 'l' ? Helpers.convertFuelUnit(volumePumped, 'l', 'gal').toFixed(2) : volumePumped.toFixed(2)} ${fuelType && `(${fuelType && fuelType.toUpperCase()})`}`}</b>
                        &nbsp;—&nbsp;
                        <b>{`${currency && currency.toUpperCase()}$${totalPaid} `}</b>
                      </div>
                    </div>
                  );
                })}
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
          {
            // Calculated Mileage
            element: (
              <div className="sb-table-cell-div">
                <b>
                  {props.distanceUnitFilter.value === 'km' ? savedVehicleKm.toFixed(0) : Helpers.convertDistance(savedVehicleKm, 'km', 'mi').toFixed(0)}
                </b>
              </div>
            ),
            props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
          },
        ],
      };

      return vehicleRowObj;
      // } else {
      //   const vehicleRowObj = {
      //     key: uniqid(),
      //     props: { className: 'sb-table-row' },
      //     columns: [{
      //       element: (
      //         <div>Insufficient Information Provided to Display Route Preview</div>
      //       ),
      //       props: { className: 'table-body-column-style verticalAlignMiddle sb-table-cell' },
      //     }],
      //   };

      //   return vehicleRowObj;
      // }
    });
  }

  render() {
    const { props } = this;

    const tableHeaderStyles = {
      date: { width: '10%' },
      odometer: { width: '8%' },
      button: { width: '5%' },
      driver: { width: '10%' },
      fuel: { width: '8%' },
      saved: { width: '8%' },
    };

    const tableHeaderRows = [{
      key: 'sb-ifta-details-header',
      columns: [
        {
          element: <div>Trip Start</div>,
          props: { style: tableHeaderStyles.date },
        },
        {
          element: <div>Trip End</div>,
          props: { style: tableHeaderStyles.date },
        },
        {
          element: <div>Odometer Start</div>,
          props: { style: tableHeaderStyles.odometer },
        },
        {
          element: <div>Odometer End</div>,
          props: { style: tableHeaderStyles.odometer },
        },
        {
          element: <div>Driver</div>,
          props: { style: tableHeaderStyles.driver },
        },
        {
          element: <div>Fuel (Gallons)</div>,
          props: { style: tableHeaderStyles.fuel },
        },
        {
          element: <div>Calculated Mileage ({props.distanceUnitFilter.value})</div>,
          props: { style: tableHeaderStyles.saved },
        },
      ],
    }];

    const tableBodyRows = this.generateDetailsViewTable();

    return (
      <div className="table-preview">
        <SBTable
          hover
          tableHeaderRows={tableHeaderRows}
          tableBodyRows={tableBodyRows}
        />
      </div>
    );
  }
}

export default AddEditRouteModalTablePreview;
