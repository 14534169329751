import React from 'react';

// CSAPI API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// CSAPI Enums
import { Currency } from 'sb-csapi/dist/enums/Finance/Currency';

// sbCore Components
import AutocompleteCard from 'sbCore/AutocompleteCard/AutocompleteCard';
import Divider from 'sbCore/Divider/Divider';

// Styles
import './style.scss';

/**
 * @description Displays a DispatchAccessorial as a card
 * @param {String} className - Custom container className
 * @param {DispatchAccessorial} dispatchAccessorial - DispatchAccessorials to display
 * @param {Boolean} isLoading - Parent's isLoading state
 * @param {Function} openAccessorialEditDialog - Function that opens the add/edit accessorial dialog
 * @param {Function} deleteDispatchAccessorial - Function called to delete a DispatchAccessorial
 *
 * @returns
 *
 * @example
 * <DispatchAccessorialCard />
 */
function DispatchAccessorialCard({ ...props }) {

  const name = getAttribute(props.dispatchAccessorial, 'name', true);
  const amount = getAttribute(props.dispatchAccessorial, 'amount', true);

  const dispatchTransfer = getAttribute(props.dispatchAccessorial, 'dispatchTransfer');
  const shipperDispatchOrganization = getAttribute(dispatchTransfer, 'shipperDispatchOrganization', true);
  const shipperOrganizationId = getAttribute(shipperDispatchOrganization, 'organizationId', true) || '**';
  const consigneeDispatchOrganization = getAttribute(dispatchTransfer, 'consigneeDispatchOrganization', true);
  const consigneeOrganizationId = getAttribute(consigneeDispatchOrganization, 'organizationId', true) || '**';

  const isInInvoice = getAttribute(props.dispatchAccessorial, 'isInInvoice', true);
  const dispatchPayee = getAttribute(props.dispatchAccessorial, 'dispatchPayee', true);
  const payeeName = getAttribute(dispatchPayee, 'name', true) || '';
  const payeeCode = getAttribute(dispatchPayee, 'code', true) ? `(${getAttribute(dispatchPayee, 'code', true)})` : '';

  // ** Components ** //
  const cardTitle = (
    <div className="card-title">
      {`${shipperOrganizationId}-${consigneeOrganizationId}`}
    </div>
  );

  const accessorialName = (
    <div className={`accessorial-name ${!name ? ' text-warning' : ''}`}>
      {name || 'No Name Provided'}
    </div>
  );

  const accessorialAmount = (
    <div className={`accessorial-amount ${!amount ? ' text-warning' : ''}`}>
      {amount ? Intl.NumberFormat('en-US', { style: 'currency', currency: Currency.US.short }).format(amount) : 'No Amount Provided'}
    </div>
  );

  const payeeText = (
    <div className={`payee-text ${!payeeName && !payeeCode ? ' text-warning' : ''}`}>
      {(payeeName) ? `${payeeName} ${payeeCode}` : 'Missing Service Provider'}
    </div>
  );

  const serviceProviderSection = (
    <div className="service-provider-section">

      <Divider />
      <div className="service-requested-from-text">Service Requested From:</div>
      {payeeText}
    </div>
  );

  // ** Misc ** //
  let className = 'dispatch-accessorial-card p-2';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <AutocompleteCard
        onToggleEditItem={() => props.openAccessorialEditDialog(props.dispatchAccessorial)}
        onUnselectItem={() => props.deleteDispatchAccessorial(props.dispatchAccessorial)}
      >
        <div className="card-title">
          {cardTitle}
          {accessorialName}
          {accessorialAmount}

          {!isInInvoice && serviceProviderSection}
        </div>
      </AutocompleteCard>
    </div>
  );
}

export default DispatchAccessorialCard;
