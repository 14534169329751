import React from 'react';
import PropTypes from 'prop-types';
// API
import { getLocalReverseGeocode } from 'api/Geocode';
import { StateProvinces } from 'api/Lists/StateProvinces';

// Components
import VehicleRowView from 'components/MapListSidebar/view/VehicleRow';

class VehicleRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { latestELDEvent: {} };
    this.setReverseGeocode = this.setReverseGeocode.bind(this);
  }

  componentWillMount() {
    this.setReverseGeocode(this.props.vehicle.get('vehicleLocation'));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.vehicle.get('vehicleLocation') && this.props.vehicle.get('vehicleLocation')) {
      if (nextProps.vehicle.get('vehicleLocation').id !== this.props.vehicle.get('vehicleLocation').id) {
        this.setReverseGeocode(nextProps.vehicle.get('vehicleLocation'));
      }
    } else if (nextProps.vehicle.get('vehicleLocation') && !this.props.vehicle.get('vehicleLocation')) {
      this.setReverseGeocode(nextProps.vehicle.get('vehicleLocation'));
    }
  }

  setReverseGeocode(vehicleLocation) {
    if (vehicleLocation && vehicleLocation.get('location')) {
      getLocalReverseGeocode([[vehicleLocation.get('location').latitude, vehicleLocation.get('location').longitude]]).then((address) => {
        if (address[0]) {
          let stateCode = StateProvinces.filter((value) => {
            return value.name === address[0].admin1Code.asciiName;
          }).map((value) => {
            return value.code;
          });
          const addressString = address && address[0] && `${address[0].asciiName}, ${stateCode}`;//, ${address[0].countryCode}
          this.setState({ ...this.state, address: addressString });
        }
      });
    }
  }

  render() {
    return (
      <VehicleRowView {...this.props} vehicle={this.props.vehicle} address={this.state.address} />
    );
  }
}

VehicleRow.propTypes = {
  vehicle: PropTypes.object.isRequired,
};

export default VehicleRow;
