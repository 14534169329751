import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getAttribute, getCurrentUser, getCurrentUserSessionToken } from 'sb-csapi/dist/AAPI';

import { getUserSubscriptionPermission } from 'sb-csapi/src/api/Subscription/Subscription';

const initialState = {
  initialized: false,
  userObjectId: undefined,
  belongsToCompanyObjectId: undefined,
  modules: {
    map: {
      enabled: undefined,
      townshipCanada: { enabled: undefined },
    },
    dispatch: { enabled: undefined },
    document: { enabled: undefined },
    drivers: { enabled: undefined },
    eld: { enabled: undefined },
    equipment: { enabled: undefined },
    driverBehaviour: { enabled: undefined },
    ifta: { enabled: undefined },
    vision: { enabled: undefined },
    driverDocument: { enabled: undefined },
    safety: { enabled: undefined },
    safetyReminders: { enabled: undefined },
    weighStationBypass: { enabled: undefined },
    carrierProfile: { enabled: undefined },
  },
  permissions: {
    userEmailNotificationTripDefects: { enabled: undefined },
    userEmailNotificationSpeedViolations: { enabled: undefined },
    eldIncludeUneditedLogs: { enabled: undefined },
    userEmailNotificationHosViolations: { enabled: undefined },
    driversAddDriver: { enabled: undefined },
    userEmailNotificationUnreadChatMessages: { enabled: undefined },
    companyUserAddUser: { enabled: undefined },
    eldEditButton: { enabled: undefined },
    dispatchEditJob: { enabled: undefined },
    equipmentAddEquipment: { enabled: undefined },
    driversEditDriver: { enabled: undefined },
    companyUserEditUser: { enabled: undefined },
    violations: { enabled: undefined },
    companyUserFeature: { enabled: undefined },
    companyUserDeleteUser: { enabled: undefined },
    driversDeleteDriver: { enabled: undefined },
    eldEditFeature: { enabled: undefined },
    recapHours: { enabled: undefined },
    eldShowEditHistory: { enabled: undefined },
    linkedCompanyList: { enabled: undefined },
    equipmentDeleteEquipment: { enabled: undefined },
    equipmentEditEquipment: { enabled: undefined },
  },
}

export const subscriptionPermissionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(refreshUserSubscriptionPermission.fulfilled, (state, action) => {
      state.initialized = true;
      state.belongsToCompanyObjectId = action.payload.belongsToCompanyObjectId;
      state.modules = action.payload.modules;
      state.permissions = action.payload.permissions;
      state.userObjectId = action.payload.userObjectId;
    });
  },
});

// Create thunks for refreshing the user's subscriptions/permissions
export const refreshUserSubscriptionPermission = createAsyncThunk(
  'subscription/refreshUserSubscriptionPermissionStatus',
  async (_, thunkAPI) => {
    const currentUser = getCurrentUser();
    const userObjectId = getAttribute(currentUser, 'objectId');
    const sessionToken = getCurrentUserSessionToken();

    const subscriptionPermission = await getUserSubscriptionPermission({ sessionToken },  userObjectId);
    return subscriptionPermission;
  }
);

export default subscriptionPermissionSlice.reducer;