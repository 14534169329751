import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// Components
import DispatchJobAutocompleteInput from 'components/Dispatch/DispatchJobAutocomplete/DispatchJobAutocompleteInput';
import DispatchJobCard from 'components/Dispatch/DispatchJobAutocomplete/DispatchJobCard';

// sbCore Components
import InputLabel from 'sbCore/InputLabel/InputLabel';

/**
 * @description Contains an input to filter jobs and select one
 * @param {Function} onSelectDispatchJob - Callback which returns the selected dispatch job
 * @param {String} [className] - Custom container className
 * @param {Object} [style] - Custom styling
 * @param {DispatchJob} [dispatchJob] - A DispatchJob passed that may be shown as a card
 * @param {Boolean} [disabled] - Selects whether or not to disable an input
 * @param {Boolean} [autoFocus] - autoFocus on input field on load
 * @param {Boolean} [warning] - Switches input border colour to yellow
 * @param {Boolean} [isLoading] - Displays loading signs depending on if loading
 * @param {Boolean} [hideLabel] - Whether or not to hide the label
 *
 * @returns
 *
 * @example
 * <DispatchJobAutocomplete />
 */
function DispatchJobAutocomplete({ ...props }) {

  // ** useStates ** //
  const [identifier] = useState(uniqid());
  const [dispatchJob, setDispatchJob] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false); // local version is isLoading (in addition to props.isLoading)

  // ** useEffects ** //
  useEffect(() => {
    setDispatchJob(props.dispatchJob);
  }, [props.dispatchJob]);

  // ** Callbacks ** //
  const onSelectDispatchJob = (dispatchJob) => {
    // add check for dispatchJob to prevent accidental triggering of function
    if (dispatchJob) {
      setDispatchJob(dispatchJob);
      if (props.onSelectDispatchJob) props.onSelectDispatchJob(dispatchJob);
    }
  };

  const onUnselectDispatchJob = () => {
    setDispatchJob(undefined);

    // pass in undefined instead of dispatchJob (which is being set to undefined above) since the state might not be updated yet. we do this over using a useEffect for convenience
    if (props.onSelectDispatchJob) props.onSelectDispatchJob(undefined);
  };

  // ** Components ** //
  const dispatchJobAutocompleteInput = (
    <DispatchJobAutocompleteInput
      dispatchJob={props.dispatchJob}
      autoFocus={props.autoFocus}
      warning={props.warning}
      disabled={props.disabled}
      onSelectDispatchJob={onSelectDispatchJob}
      customerDispatchOrganization={props.customerDispatchOrganization}
    />
  );

  const dispatchJobCard = (
    <DispatchJobCard
      dispatchJob={dispatchJob}
      onUnselectDispatchJob={onUnselectDispatchJob}
    />
  );

  const loadingDispatchJobCard = (
    <DispatchJobCard isLoading={props.isLoading || isLoading} />
  );

  // ** Misc ** //
  let className = `dispatch-job-autocomplete ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  const label = 'Job';

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && <InputLabel>{label}</InputLabel>}

      {!props.isLoading && !dispatchJob && dispatchJobAutocompleteInput}

      {!props.isLoading && !props.disabled && dispatchJob && dispatchJobCard}

      {props.isLoading && loadingDispatchJobCard}

    </div>
  );
}

export default DispatchJobAutocomplete;
