/*
 * This file contains general getter functions useful throughout the app
 * Please list functions in alphabetical order and comment well unless
 * the function listing order depends on requisite functions (ex. define function before used)
 */

import Parse from 'parse';
import JsPDF from 'jspdf';
import moment from 'moment';
import momentTz from 'moment-timezone';
import momentDurationFormat from 'moment-duration-format';
import store from '../store';

// API
import { getAttribute } from './Parse';
import { CountryCodeLocationDescriptionTypes } from 'enums/VehicleLocation';

/** @module Helpers */

/**
 * @memberof module:Helpers
 * @param {*} moduleName
 * @returns
 */
function isSubscribedToModule(moduleName) {
  // determine if a user is subscribed to a certain module
  const storeCheck = store.getState().Subscription.subscription && store.getState().Subscription.subscription.get(moduleName);
  const parseCheck = Parse.User.current() && Parse.User.current().get('belongsToCompany') && Parse.User.current().get('belongsToCompany').get('subscription') && Parse.User.current().get('belongsToCompany').get('subscription').get(moduleName);
  return storeCheck || parseCheck;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} arr1
 * @param {*} arr2
 *
 * @returns
 */
function areArraysEqual(arr1, arr2) {
  return (arr1.length === arr2.length) && arr1.every((element, index) =>
    element === arr2[index]
  );
}

/**
 * @memberof module:Helpers
 * @param {*} Value
 * @returns
 */
// Converts numeric degrees to radians
function toRad(Value) {
  return Value * Math.PI / 180;
}

// Haversine formula
// see: https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates/18883819#18883819
/**
 * @memberof module:Helpers
 *
 * @param {*} degLat1
 * @param {*} degLon1
 * @param {*} degLat2
 * @param {*} degLon2
 *
 * @returns
 */
function calculateDistanceKm(degLat1, degLon1, degLat2, degLon2) {
  const R = 6371; // km
  const dLat = toRad(degLat2 - degLat1);
  const dLon = toRad(degLon2 - degLon1);
  const lat1 = toRad(degLat1);
  const lat2 = toRad(degLat2);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

/**
 * @memberof module:Helpers
 * @param {*} str
 * @returns
 */
function compressWhitespace(str) {
  // Given a string/text, find out if it is just all whitespace. If so, shrink it down to ''
  const finalString = str.replace(/^\s+/, '').replace(/\s+$/, '');
  return finalString;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} array
 * @param {*} key
 * @param {*} repeatBool
 *
 * @returns
 */
function concatenateStringsInParseObjectsArray(array, key, repeatBool) {
  let concatenatedStrings = '';
  const stringIterable = repeatBool ? array.map((parseObject) => parseObject.get(key)) : new Set(array.map((parseObject) => parseObject.get(key)));
  for (const string of stringIterable) {
    concatenatedStrings += `${string}, `;
  }
  concatenatedStrings = concatenatedStrings.substring(0, concatenatedStrings.length - 2);
  return concatenatedStrings;
}

/**
 * @memberof module:Helpers
 * @param {*} array
 * @returns
 */
function convertArrayToCSVString(array) {
  const lineArray = [];
  const arrayLen = array.length;
  for (let i = 0; i < arrayLen; i++) {
    const line = array[i].map((entry) => {
      if (entry) return entry.replace(/\\/g, '\\\\').replace(/\n/g, '\\n').replace(/,/g, '\\,');
      return entry;
    });
    lineArray.push(line);
  }
  return lineArray.join('\n');
}

/**
 * @memberof module:Helpers
 * @param {*} milli
 * @returns
 */
function convertMillisecondsToHours(milli) {
  return milli / 3600000;
}

/**
 * @memberof module:Helpers
 * @param {*} hours
 * @returns
 */
function convertHoursToMilliseconds(hours) {
  return hours * 3600000;
}

/**
 * @memberof module:Helpers
 * @param {*} array
 * @returns
 */
function concatenateStringsInArray(array) {
  if (!array) return;
  let concatenatedStrings = '';
  const arrayLen = array.length;
  for (let i = 0; i < arrayLen; i++) {
    concatenatedStrings += `${array[i]}, `;
  }
  concatenatedStrings = concatenatedStrings.substring(0, concatenatedStrings.length - 2);
  return concatenatedStrings;
}

/**
 * @memberof module:Helpers
 * @param {*} vehicleLocationArr
 * @param {*} singleBool
 * @returns
 */
function convertParseVehicleLocationArrToPointArr(vehicleLocationArr, singleBool) {
  // returns: [ [ longitude, latitude ] ]
  let vehiclePointArr;
  if (singleBool) {
    const location = getAttribute(vehicleLocationArr[0], 'location');
    const longitude = location.longitude;
    const latitude = location.latitude;
    vehiclePointArr = [longitude, latitude];
    return vehiclePointArr;
  }
  vehiclePointArr = vehicleLocationArr.filter((vehicleLocation) => {
    if (!vehicleLocation) {
      return;
    }
    return getAttribute(vehicleLocation, 'location');
  }).map((vehicleLocation) => {
    if (!vehicleLocation) {
      return;
    }
    const location = getAttribute(vehicleLocation, 'location');
    const longitude = location.longitude;
    const latitude = location.latitude;
    return [longitude, latitude];
  });
  return vehiclePointArr;
}

/**
 * @memberof module:Helpers
 * @param {*} objArr
 * @returns
 */
function convertLongitudeLatitudeObjArrToPointArr(objArr) {
  return objArr.map((locationPoint) => [locationPoint.longitude, locationPoint.latitude]);
}

/**
 * @memberof module:Helpers
 *
 * @param {*} topLat
 * @param {*} bottomLat
 * @param {*} leftLong
 * @param {*} rightLong
 *
 * @returns
 */
function convertCornersToCenterAndLength(topLat, bottomLat, leftLong, rightLong) {
  return {
    longitude: ((rightLong - leftLong) / 2) + leftLong,
    latitude: ((topLat - bottomLat) / 2) + bottomLat,
    width: rightLong - leftLong,
    height: topLat - bottomLat,
  };
}

/**
 * @memberof module:Helpers
 *
 * @param {*} filename
 * @param {*} content
 * @param {*} downloadBool
 *
 * @returns
 */
function createCsvFile(filename, content, downloadBool) {
  const csvContent = `data:text/csv;charset=utf-8,${content}`;
  if (!downloadBool) {
    return csvContent;
  }

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  const formattedFilename = filename;
  if (filename.substr(filename.length - 4) !== '.csv') formattedFilename.concat(`${filename}.csv`);
  link.setAttribute('download', `${filename}.csv`);
  document.body.appendChild(link); // Required for FF
  return link.click();
}

/**
 * @memberof module:Helpers
 *
 * @param {*} arr1
 * @param {*} arr2
 *
 * @returns
 */
function doParseObjectArraysIntersect(arr1, arr2) {
  let shorterArr = arr1;
  let longerArr = arr2;
  if (arr1.length > arr2.length) {
    shorterArr = arr2;
    longerArr = arr1;
  }
  const shorterArrLen = shorterArr.length;
  for (let i = 0; i < shorterArrLen; i++) {
    if (longerArr.map((x) => x.id).indexOf(shorterArr[i].id) !== -1) {
      return true;
    }
  }
  return false;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} arr
 * @param {*} key
 * @param {*} value
 *
 * @returns
 */
function findIndexOfObjArr(arr, key, value) {
  // search array of objects to see if key  === value. return index of object
  if (arr) {
    const arrLen = arr.length;
    for (let i = 0; i < arrLen; i++) {
      if (arr[i] && arr[i][key] === value) {
        return i;
      }
    }
  }
  return -1;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} arr
 * @param {*} key
 * @param {*} value
 *
 * @returns
 */
function findIndexOfParseObjArr(arr, key, value) {
  // search array of objects to see if key  === value. return index of object
  if (arr) {
    const arrLen = arr.length;
    for (let i = 0; i < arrLen; i++) {
      if (arr[i].get('key') === value) {
        return i;
      }
    }
  }
  return -1;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} date
 * @param {*} format
 *
 * @returns
 */
function formatDate(date, format) {
  // date must be a valid date format
  const dateISO = date.toISOString();
  if (format === 'YYYY-MM-DD') {
    return dateISO.slice(0, 10);
  }
  return 0;
}

/**
 * @memberof module:Helpers
 * @param {*} addressParseObjJSON
 * @returns
 */
function formatAddress(addressParseObjJSON) {
  return `${addressParseObjJSON.address}, ${addressParseObjJSON.city ? addressParseObjJSON.city : ''}, ${addressParseObjJSON.stateProvince ? addressParseObjJSON.stateProvince : ''}, ${addressParseObjJSON.zipPostal ? addressParseObjJSON.zipPostal : ''} ${addressParseObjJSON.country}`;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} number
 * @param {*} decimalPlaces
 *
 * @returns
 */
function formatDollars(number, decimalPlaces = 2) {
  if (typeof number !== 'number') return undefined;
  return `${number < 0 ? '−' : ''}$${Math.abs(number).toFixed(decimalPlaces)}`;
}

/**
 * @memberof module:Helpers
 * @param {*} integer
 * @returns
 */
function formatCentsToDollars(integer) {
  if (typeof integer !== 'number') return undefined;
  if (!Number.isInteger(integer)) integer = Math.round(integer);
  const sign = integer < 0 ? '-' : '';
  integer = Math.abs(integer);

  const integerString = integer.toString();
  const dollars = integerString.length > 2 ? integerString.slice(0, integerString.length - 2) : '0';
  let cents = '00';
  if (integerString.length >= 2) cents = integerString.slice(integerString.length - 2 /* to end */);
  if (integerString.length === 1) cents = `0${integerString}`;
  return `${sign}$${dollars}.${cents}`;
}

/**
 * @memberof module:Helpers
 * @param {*} messageOrParseObject
 * @returns
 */
function formatNotificationObject(messageOrParseObject) {
  const notificationText = messageOrParseObject.get('text');
  if (notificationText) {
    const objectText = notificationText.substring(2, notificationText.length - 11);
    try {
      const notificationObject = JSON.parse(objectText);
      notificationObject.read = messageOrParseObject.get('read');
      notificationObject.createdAt = momentTz(messageOrParseObject.get('createdAt'));
      return notificationObject;
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
}

/**
 * @memberof module:Helpers
 * @param {*} messageObj
 * @returns
 */
function formatPubNubNotificationObject(messageObj) {
  const parseNotificationObject = messageObj.message;
  const messageTextNotificationObject = messageObj.message.text;
  let notificationObject = messageTextNotificationObject.substring(2, messageTextNotificationObject.length - 11);

  notificationObject = JSON.parse(notificationObject);
  notificationObject.createdAt = momentTz(parseNotificationObject.createdAt);
  notificationObject.read = parseNotificationObject.read;
  return notificationObject;
}

/**
 * @memberof module:Helpers
 * @param {*} number
 * @returns
 */
function formatTimeNumberToHoursMinutes(number) {
  if (number) {
    const hours = Math.floor(number);
    const minutes = (`0${Math.round((number - Math.floor(number)) * 60)}`).slice(-2);
    return `${hours}:${minutes}`;
  }
  return number;
}

const getHostnameFromRegex = (url) => {
  const host = new URL(url).host;
  const dots = host.split('.');
  return dots.at(-2);
}

function getFormattedLocationDescription(vehicleLocation) {
  let locationDescription = '';
  const countryCode = ((vehicleLocation && vehicleLocation.get('countryCode')) || '').toUpperCase();
  if (countryCode === 'US') {
    if (getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.US, true)) {
      const locationDescriptionUSArr = getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.US, true).split(' ');
      const locationDescriptionStateProvince = locationDescriptionUSArr[2];
      const trueStateProvince = getAttribute(vehicleLocation, 'stateProvince', true);
      if (trueStateProvince && trueStateProvince !== locationDescriptionStateProvince.toLowerCase()) {
        const formattedLocationDescription = `${trueStateProvince.toUpperCase()}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      } else {
        const locationDescriptionCity = locationDescriptionUSArr.slice(3, locationDescriptionUSArr.length).join(' ');
        const formattedLocationDescription = `${locationDescriptionCity}, ${locationDescriptionStateProvince}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      }
    }
  } else {
    if (getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.CA, true)) {
      const locationDescriptionCAArr = getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.CA, true).split(' ');
      const locationDescriptionStateProvince = locationDescriptionCAArr[2];
      const trueStateProvince = getAttribute(vehicleLocation, 'stateProvince', true);
      if (trueStateProvince && trueStateProvince !== (locationDescriptionStateProvince && locationDescriptionStateProvince.toLowerCase())) {
        const formattedLocationDescription = `${trueStateProvince.toUpperCase()}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      } else {
        const locationDescriptionCity = locationDescriptionCAArr.slice(3, locationDescriptionCAArr.length).join(' ');
        const formattedLocationDescription = `${locationDescriptionCity}, ${locationDescriptionStateProvince}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      }
    }
  }

  return locationDescription;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} date
 * @param {*} timezoneOffsetFromUTC
 *
 * @returns
 */
function referenceToTimezone(date, timezoneOffsetFromUTC) {
  if (timezoneOffsetFromUTC) {
    return momentTz.tz(date, timezoneOffsetFromUTC);
  }
  return momentTz.tz(date, momentTz.tz.guess());
}

/**
 * @memberof module:Helpers
 * @returns
 */
function generatePassword() {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('generatePassword').then(
      response => resolve(response),
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} parseArray
 * @param {*} internalParseObjectProperty
 *
 * @returns
 */
function getArrayOfInternalParseObjects(parseArray, internalParseObjectProperty) {
  const parseArrayLength = parseArray.length;
  const internalObjectArray = [];
  for (let i = 0; i < parseArrayLength; i++) {
    internalObjectArray.push(parseArray[i].get(internalParseObjectProperty));
  }
  return internalObjectArray;
}

/**
 * @memberof module:Helpers
 * @returns
 */
function getCompanyReadWriteACL(companyId) {
  const companyACL = new Parse.ACL();
  companyACL.setRoleWriteAccess(companyId || store.getState().Company.company.id, true);
  companyACL.setRoleReadAccess(companyId || store.getState().Company.company.id, true);
  return companyACL;
}

/**
 * @memberof module:Helpers
 * @returns
 */
function getNotificationTimeSinceEpoch() {
  return `${momentTz().format('x')}${Parse.User.current().id}`;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} driver
 * @param {*} activeDriverArr
 *
 * @returns
 */
function isActiveDriver(driver, activeDriverArr) {
  // see if driver belongs to activeDriverArr
  const activeDriverLen = activeDriverArr.length;
  for (let i = 0; i < activeDriverLen; i++) {
    if (driver.id === activeDriverArr[i].id) {
      return true;
    }
  }
  return false;
}

/**
 * @memberof module:Helpers
 * @returns
 */
function isAdmin() {
  return Parse.User.current().toJSON().userType.indexOf(0) > -1;
}

/**
 * @memberof module:Helpers
 * @param {*} string
 * @returns
 */
function isAlphanumeric(string) {
  const regexp = new RegExp('[^a-zA-Z0-9]', 'gi');
  return !regexp.test(string);
}

/**
 * @memberof module:Helpers
 * @param {*} string
 * @returns
 */
function isAlphanumericPlusSymbols(string) {
  const regexp = new RegExp('[^a-zA-Z0-9~@#$^*()_+=[\]{}|\\,.?: -]', 'gi');
  return !regexp.test(string);
}


/**
 * @memberof module:Helpers
 *
 * @param {*} dispatcherUserId
 * @param {*} driver
 *
 * @returns
 */
function isDispatchersDriver(dispatcherUserId, driver) {
  // Function that finds whether a given driver belongs to the dispatcher
  const dispatchers = driver.toJSON().dispatchers;
  if (dispatchers) {
    const dispatchersLen = dispatchers.length;
    for (let i = 0; i < dispatchersLen; i++) {
      const dispatcher = dispatchers[i];
      if (dispatcherUserId === dispatcher.objectId) {
        return true;
      }
    }
  }
  return false;
}

/**
 * @memberof module:Helpers
 * @param {*} string
 * @returns
 */
function isNumeric(string) {
  // check if a string contains all digits
  return /^\d+$/.test(string);
}

/**
 * @memberof module:Helpers
 * @param {*} object
 * @returns
 */
function isObjectEmpty(object) {
  return object && Object.keys(object).length === 0 && object.constructor === Object;
}

/**
 * @memberof module:Helpers
 * @param {*} string
 * @returns
 */
function isStringEmpty(string) {
  // determine if this string exists or not
  return !(string && string !== '' && string.length > 0 && compressWhitespace(string) !== '');
}

/**
 * @memberof module:Helpers
 * @param {*} email
 * @returns
 */
function isInvalidEmailFormat(email) {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(email);
}

/**
 * @memberof module:Helpers
 *
 * @param {*} url
 * @param {*} target
 *
 * @returns
 */
function openDocumentLink(url, target = '') {
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  if (store.getState().Main.env === 'prod') {
    link.href = `${url.replace(/^http:\/\//i, 'https://')}`;
    // return window.open(`${url.replace(/^http:\/\//i, 'https://')}`, target);
  } else {
    link.href = url;
  }
  link.download = url.substring(url.lastIndexOf('/') + 1).substr(33);
  document.body.appendChild(link);
  link.click();
  return document.body.removeChild(link);
  // return window.open(url, target);
}

/**
 * @memberof module:Helpers
 * @param {*} companyPrefix
 * @returns
 */
function prefixExists(companyPrefix) {
  // checks to see if the prefix specified already exists
  const companyQuery = new Parse.Query('Company');
  companyQuery.equalTo('prefix', companyPrefix.toLowerCase());

  const promise = new Promise((resolve, reject) => {
    companyQuery.first().then(
      () => resolve(true),
      () => reject(false),
    );
  });
  return promise;
}

/**
 * @memberof module:Helpers
 * @param {*} placeObj
 * @returns
 */
function parsePlaceObject(placeObj) {
  const placeObjComponents = placeObj.address_components;
  const placeObjComponentsLen = placeObjComponents.length;
  const parsedObj = {
    address: placeObj.name,
    city: undefined,
    stateProvince: undefined,
    zipPostal: undefined,
    country: undefined,
    geoPoint: new Parse.GeoPoint({ latitude: placeObj.geometry.location.lat(), longitude: placeObj.geometry.location.lng() }),
  };
  for (let i = 0; i < placeObjComponentsLen; i++) {
    if (placeObjComponents[i].types[0] === 'locality') { parsedObj.city = placeObjComponents[i].long_name; continue; }
    if (placeObjComponents[i].types[0] === 'administrative_area_level_1') { parsedObj.stateProvince = placeObjComponents[i].long_name; continue; }
    if (placeObjComponents[i].types[0] === 'country') { parsedObj.country = placeObjComponents[i].long_name; continue; }
    if (placeObjComponents[i].types[0] === 'postal_code') { parsedObj.zipPostal = placeObjComponents[i].long_name; continue; }
    if (placeObjComponents[i].types[0] === 'postal_code_prefix') { parsedObj.zipPostal = placeObjComponents[i].long_name; continue; }
  }
  return parsedObj;
}

/**
 * @memberof module:Helpers
 * @param {*} array
 * @returns
 */
function removeDuplicatesByObjectId(array) {
  // remove duplicate objects from an array using objectId comparisons
  const seen = {};
  if (array) {
    return array.filter((item) => {
      const objectId = item.id || item._localId;
      if (seen[objectId]) {
        return false;
      }
      seen[objectId] = true;
      return true;
    });
  }
  return array;
}

/**
 * @memberof module:Helpers
 * @param {*} array
 * @returns
 */
function removeDuplicates(array) {
  const seen = [];
  return array.filter((item) => {
    if (seen.indexOf(item) !== -1) {
      return false;
    }
    seen.push(item);
    return true;
  });
}

/**
 * @memberof module:Helpers
 * @param {*} array
 * @returns
 */
function removeDuplicatesByJobId(array) {
  // remove duplicate objects from an array using objectId comparisons
  const seen = {};

  return array.filter((item) => {
    const loadNumber = item.get('jobId');
    if (seen[loadNumber]) {
      return false;
    }
    seen[loadNumber] = true;
    return true;
  });
}

/**
 * @memberof module:Helpers
 *
 * @param {*} jobLinks
 * @param {*} loadedJobLinks
 *
 * @returns
 */
function removeDuplicatesByJobLinks(jobLinks, loadedJobLinks) {
  // Remove jobLinks that already exist in loadedJobLinks
  if (!loadedJobLinks || loadedJobLinks.length === 0) {
    return jobLinks;
  }

  const filteredJobLinks = [];
  const jobLinksLen = jobLinks.length;
  const loadedJobLinksLen = loadedJobLinks.length;
  for (let i = 0; i < jobLinksLen; i++) {
    const jobLink = jobLinks[i];
    let duplicate = false;
    for (let j = 0; j < loadedJobLinksLen; j++) {
      if (jobLink.id === loadedJobLinks[j].id) {
        duplicate = true;
        break;
      }
    }

    if (!duplicate) {
      filteredJobLinks.push(jobLink);
    }
  }
  return filteredJobLinks;
}

/**
 * @memberof module:Helpers
 * @param {*} array
 * @returns
 */
function removeDuplicateStringsInArray(array) {
  const seen = {};
  return array.filter(item => seen.hasOwnProperty(item) ? false : (seen[item] = true));
}

/**
 * @memberof module:Helpers
 *
 * @param {*} object
 * @param {*} array
 *
 * @returns
 */
function parseObjectBelongsTo(object, array) {
  // function to check if parse object is a part of an parse object array
  const arrayLen = array.length;
  for (let i = 0; i < arrayLen; i++) {
    if (array[i].id === object.id) {
      return true;
    }
  }
  return false;
}

/**
 * @memberof module:Helpers
 * @returns
 */
function removeDuplicateForVehicles() {
  const promise = new Promise((resolve, reject) => {
    // console.log('hi');
    Parse.Cloud.run('queryTest').then(
      response => {
        // console.log(response);
        resolve(response);
      },
      error => reject(error)
    );
  });

  return promise;


  // const queryVehicle = new Parse.Query('Vehicle');

  // queryVehicle.string('', 'rL2Z7RvfWE');
  // queryVehicle.find().then((ELDEventArr) => { console.log(queryVehicle); });
}

/**
 * @memberof module:Helpers
 * @param {*} string
 * @returns
 */
function removeSpecialCharacters(string) {
  // Remove all special characters from a string. If keepExceptions is true, keep letters, numbers, and whitespace
  return string.replace(/[^a-zA-Z0-9]/g, '');
}

/**
 * @memberof module:Helpers
 *
 * @param {*} arr
 * @param {*} reverseBool
 *
 * @returns
 */
function rotateArray(arr, reverseBool) {
  const rotatedArray = arr;
  if (reverseBool) {
    rotatedArray.unshift(rotatedArray.pop());
  } else {
    rotatedArray.push(rotatedArray.shift());
  }
  return rotatedArray;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} file
 * @param {*} size
 *
 * @returns
 */
function scaleImage(file, size) {
  const promise = new Promise((resolve, reject) => {
    if (file.type.match(/image.*/)) {
      // Load the image
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        const image = new Image();
        image.onload = (imageEvent) => {
          // Resize the image
          const canvas = document.createElement('canvas');
          const maxSize = size;
          let width = image.width;
          let height = image.height;
          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else if (height > maxSize) {
            width *= maxSize / height;
            height = maxSize;
          }
          canvas.width = width;
          canvas.height = height;
          canvas.getContext('2d').drawImage(image, 0, 0, width, height);
          resolve(canvas.toDataURL('image/png'));
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      reject('Did not upload image');
    }
  });
  return promise;
}

/**
 * @memberof module:Helpers
 * @param {*} pictureFile
 * @returns
 */
function scaleAndConvertToPDF(pictureFile) {
  const promise = new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      const doc = new JsPDF('p', 'pt', 'letter');
      const img = new Image();
      img.src = reader.result;
      const imageRotateValue = 0;
      const pointsPerPixel = 0.75;
      let imageScaleFactor;
      // if (img.width > img.height) {
      //   imageRotateValue = 90;
      // }
      // letter size is 612 x 792 pts (8.5 x 11)
      const margin = 15; // 10 points
      if ((img.width / img.height) > (612 / 792)) {
        // Constrained by width
        imageScaleFactor = (((612 - (margin * 2)) / pointsPerPixel) / (img.width)) * pointsPerPixel;
      } else {
        // Constrained by height
        imageScaleFactor = (((792 - (margin * 2)) / pointsPerPixel) / (img.height)) * pointsPerPixel;
      }

      doc.addImage({
        imageData: img.src,
        angle: imageRotateValue,
        x: margin,
        y: margin,
        w: img.width * imageScaleFactor,
        h: img.height * imageScaleFactor,
      });
      resolve(doc.output('datauristring'));
    }, false);

    reader.readAsDataURL(pictureFile);
  });
  return promise;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} e
 * @param {*} scrollOffset
 * @param {*} previousPos
 * @param {*} amountPaged
 * @param {*} pageCount
 * @param {*} objectCount
 *
 * @returns
 */
function scrolledToBottom(e, scrollOffset, previousPos, amountPaged, pageCount, objectCount) {
  // given the event from a scroll, determine if the scroll passes the bottom of it's container
  // scrollOffset is given as a modifier to how high above the bottom of container to activate
  // previousPos is optional. if given, it lets us determine whether the user scrolled up or down
  // previousPos is the last saved position of lazy load activation
  const { scrollHeight, offsetHeight, scrollTop } = e.target;
  const scrollBottom = scrollHeight - offsetHeight;
  // To avoid lazyloading like crazy: if paging much more, then will stop lazy load
  if ((amountPaged * pageCount) > objectCount) {
    return false;
  }
  if (previousPos >= 0) {
    // if we scrolled down and also scrolled past bottom of container. prevents lazyload from working when the
    // user is scrolling up (b/c scrollTop can still be >= scrollTop of container when scrolling back up)
    if ((scrollTop > previousPos) && scrollTop > (scrollBottom - scrollOffset)) {
      return true;
    }
  }
  if (scrollTop > (scrollBottom - scrollOffset)) {
    // if we scroll past the bottom scroll index of the container with no given direction
    return true;
  }
  return false;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} parseObject
 * @param {*} keyValuesObject
 *
 * @returns
 */
function setParseObjectToKeyValues(parseObject, keyValuesObject) {
  const keyArray = Object.keys(keyValuesObject);
  const keyArrayLen = keyArray.length;
  for (let i = 0; i < keyArrayLen; i++) {
    parseObject.set(keyArray[i], keyValuesObject[keyArray[i]]);
  }
  return parseObject;
}

/**
 * @memberof module:Helpers
 * @param {*} e
 * @returns
 */
function stopPropagation(e) {
  return e.stopPropagation();
}

/**
 * @memberof module:Helpers
 *
 * @param {*} string
 * @param {*} search
 * @param {*} replacement
 *
 * @returns
 */
function stringReplaceAll(string, search, replacement) {
  return string.replace(new RegExp(search, 'g'), replacement);
}

/**
 * @memberof module:Helpers
 * @param {*} image
 * @returns
 */
function toDataURL(image) {
  // Create an empty canvas element
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;

  // Copy the image contents to the canvas
  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0);

  // Get the data-URL formatted image
  // Firefox supports PNG and JPEG. You could check img.src to
  // guess the original format, but be aware the using "image/jpg"
  // will re-encode the image.
  const dataURL = canvas.toDataURL('image/jpg');

  return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
}

/**
 * @memberof module:Helpers
 * @param {*} str
 * @returns
 */
function toTitleCase(str) {
  if (!str) return;
  return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

/**
 * @memberof module:Helpers
 * @param {*} anonPromiseFunctionArr
 * @returns
 */
function executePromisesSynchronously(anonPromiseFunctionArr) {
  // WARNING: Because it behaves synchronously, ensure that you are passing non-executable
  //          functions (ie. function refs/anonymous functions). Also beware of accidentally calling a function
  //          before/while passing it to promiseArr
  // Example input #1: [anonfn1, anonfn2, anonfn3, ...]
  // Example input #2: [() => { return promisefn() }, () => { return promisefn2() }, ...]
  // Acts like Promise.all() except synchronous. Executes each promise in promiseArr
  // one at a time, in order
  const _anonPromiseFunctionArr = [].concat(anonPromiseFunctionArr);
  function executePromiseSub(results, resolve, reject) {
    if (_anonPromiseFunctionArr.length === 0) {
      // finished executing all promises
      resolve(results);
    } else {
      // otherwise, call the promise, obtain result, and move on
      const fn = _anonPromiseFunctionArr[0];
      if (fn.constructor === Function) {
        // since we know that the function inside the anonymous function returns a promise fn,
        // or that the function itself is a reference to a promise function, run it
        fn().then(
          (result) => {
            const _results = results.concat(result);
            _anonPromiseFunctionArr.splice(0, 1);
            executePromiseSub(_results, resolve, reject);
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        // we were not passed a function reference/anonymous function
        reject('Element within passed array argument is not of constructor Function');
      }
    }
  }

  const promise = new Promise((resolve, reject) => {
    const orderedResults = [];
    executePromiseSub(orderedResults, resolve, reject);
  });
  return promise;
}

/**
 * @memberof module:Helpers
 * @param {*} name
 * @returns
 */
function formatName(name) {
  // given a full name, no matter the case, attempt to reconstruct a proper format (ex. user_fullName)
  // ex. jorge ciruela -> Jorge Ciruela
  if (name) {
    let _name = name.replace(/\s+/g, ' '); // replace excess whitespace
    _name = _name.split(' ');
    for (let i = 0; i < _name.length; i++) {
      let namePart = _name[i];
      if (namePart[i]) {
        namePart = namePart[0].toUpperCase() + namePart.substr(1).toLowerCase();
        _name[i] = namePart;
      }
    }
    return _name.join(' ');
  }
  return name;
}

/**
 * @memberof module:Helpers
 * @param {*} milliseconds
 * @param {*} formatString
 * @returns
 */
function msToTimeString(milliseconds, formatString) {
  // from milliseconds given, outputs a time string format like hh:mm:ss
  return moment.duration(milliseconds, 'milliseconds').format(formatString);
}

/**
 * @memberof module:Helpers
 * @param {*} milliseconds
 * @returns
 */
function formatDurationString(milliseconds) {
  // format to HH:mm since we end up using this format a lot
  let durationString = msToTimeString(milliseconds, 'HH:mm');
  durationString = durationString.split(':');
  const hours = !durationString[1] ? '00' : durationString[0];
  const minutes = !durationString[1] ? durationString[0] : durationString[1];
  durationString = `${hours}H ${minutes}M`;
  return durationString;
}

/**
 * @memberof module:Helpers
 * @param {*} className
 * @param {*} attributes
 * @returns
 */
function createTempParseObject(className, attributes) {
  // creates a parse object not yet saved to the db
  const Class = new Parse.Object.extend(className);
  const object = new Class();

  const keys = Object.keys(attributes);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    object.set(key, attributes[key]);
  }

  return object;
}

/**
 * @memberof module:Helpers
 * @param {*} xmlString
 * @returns
 */
function parseToXML(xmlString) {
  // parse an xml string to XML
  let xmlDoc;
  if (document.implementation && document.implementation.createDocument) {
    xmlDoc = new DOMParser().parseFromString(xmlString, 'text/xml');
  } else if (window.ActiveXObject) { // for IE compatibility
    xmlDoc = new ActiveXObject('Microsoft.XMLDOM');
    xmlDoc.loadXML(xmlString);
  } else {
    alert('Your browser is not compatible with a script to read XML Data');
    return null;
  }
  return xmlDoc;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} inputDistance
 * @param {*} inputUnit
 * @param {*} outputUnit
 * @param {*} skipRounding
 *
 * @returns
 */
function convertDistance(inputDistance, inputUnit, outputUnit, skipRounding) {
  // assumes distance passed in is in KM
  let result = inputDistance || 0;
  if (inputDistance && inputUnit === 'km' && outputUnit === 'mi') {
    result = (inputDistance * 0.621371);
  } else if (inputDistance && inputUnit === 'mi' && outputUnit === 'km') {
    result = (inputDistance / 0.621371);
  }

  if (!skipRounding) {
    result = parseFloat(result.toFixed(2));
  }

  return result;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} amount
 * @param {*} inputUnit
 * @param {*} outputUnit
 * @param {*} skipRounding
 *
 * @returns
 */
function convertFuelUnit(amount, inputUnit, outputUnit, skipRounding) {
  // assumes distance passed in is in KM
  let result = amount || 0;
  if (amount && inputUnit === 'gal' && outputUnit === 'l') {
    result = (amount * 3.78541);
  } else if (amount && inputUnit === 'l' && outputUnit === 'gal') {
    result = (amount / 3.78541);
  }

  if (!skipRounding) {
    result = parseFloat(result.toFixed(2));
  }

  return result;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} amount
 * @param {*} inputUnit
 * @param {*} outputUnit
 * @param {*} skipRounding
 *
 * @returns
 */
function convertTemperature(amount, inputUnit, outputUnit, skipRounding) {
  // assumes distance passed in is in KM
  let result = amount || 0;
  if (amount && inputUnit === 'c' && outputUnit === 'f') {
    result = ((amount * 1.8) + 32);
  } else if (amount && inputUnit === 'f' && outputUnit === 'c') {
    result = ((amount - 32) / 1.8);
  }

  if (!skipRounding) {
    result = parseFloat(result.toFixed(2));
  }

  return result;
}

/**
 * @memberof module:Helpers
 * @param {*} seenObject
 * @returns
 */
function rng(seenObject) {
  // creates a random number and as long as it hasnt been in seenObject, it will return it
  const timeSinceEpoch = (new Date()).getTime();
  const randomFlag = Math.floor(Math.random() * (999 - 0)) + 0;
  const randomNumber = `${timeSinceEpoch}+${randomFlag}`;
  if (!seenObject[randomNumber]) {
    return randomNumber;
  }
  return rng([seenObject]);
}

/**
 * @memberof module:Helpers
 * @param {*} number
 * @returns
 */
function numberWithCommas(number) {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

/**
 * @memberof module:Helpers
 * @param {*} vehicleLocation
 * @returns
 */
function isZeroVehicleLocation(vehicleLocation) {
  // determines if a vehiclelocation is valid and its location is non-zero
  if (vehicleLocation && vehicleLocation.get('location')) {
    return vehicleLocation.get('location')._latitude === 0 && vehicleLocation.get('location').longitude === 0;
  }
  return true;
}

/**
 * @memberof module:Helpers
 *
 * @param {*} year
 * @param {*} month
 *
 * @returns
 */
function getStartEndOfMonth(year, month) {
  const startDate = momentTz([year, month]);
  const endDate = momentTz(startDate).endOf('month');
  return { start: startDate.toDate(), end: endDate.toDate() };
}

/**
 * @memberof module:Helpers
 * @description Given a string and desired number of characters, cut off at length. Ex: "My Company" -> "My Comp..."
 *
 * @param {string} inputString
 * @param {number} length
 */
function formatStringLength(inputString = '', length = 0) {
  if (inputString.length <= length) {
    return inputString;
  }
  return `${inputString.substr(0, length)}...`;
}

export {
  areArraysEqual,
  calculateDistanceKm,
  compressWhitespace,
  concatenateStringsInParseObjectsArray,
  convertArrayToCSVString,
  convertMillisecondsToHours,
  convertHoursToMilliseconds,
  concatenateStringsInArray,
  convertDistance,
  convertFuelUnit,
  convertTemperature,
  convertParseVehicleLocationArrToPointArr,
  convertLongitudeLatitudeObjArrToPointArr,
  convertCornersToCenterAndLength,
  createCsvFile,
  createTempParseObject,
  doParseObjectArraysIntersect,
  executePromisesSynchronously,
  findIndexOfObjArr,
  findIndexOfParseObjArr,
  formatAddress,
  formatCentsToDollars,
  formatDate,
  formatDollars,
  formatDurationString,
  formatName,
  formatNotificationObject,
  formatPubNubNotificationObject,
  formatStringLength,
  formatTimeNumberToHoursMinutes,
  getHostnameFromRegex,
  getFormattedLocationDescription,
  getArrayOfInternalParseObjects,
  getCompanyReadWriteACL,
  getNotificationTimeSinceEpoch,
  generatePassword,
  getStartEndOfMonth,
  isActiveDriver,
  isAdmin,
  isAlphanumeric,
  isAlphanumericPlusSymbols,
  isDispatchersDriver,
  isInvalidEmailFormat,
  isNumeric,
  isObjectEmpty,
  isStringEmpty,
  isSubscribedToModule,
  isZeroVehicleLocation,
  msToTimeString,
  numberWithCommas,
  openDocumentLink,
  parseObjectBelongsTo,
  parsePlaceObject,
  parseToXML,
  prefixExists,
  referenceToTimezone,
  removeDuplicates,
  removeDuplicatesByObjectId,
  removeDuplicatesByJobId,
  removeDuplicatesByJobLinks,
  removeDuplicateStringsInArray,
  removeDuplicateForVehicles,
  removeSpecialCharacters,
  rng,
  rotateArray,
  scaleImage,
  scaleAndConvertToPDF,
  scrolledToBottom,
  setParseObjectToKeyValues,
  stopPropagation,
  stringReplaceAll,
  toDataURL,
  toTitleCase,
};
