import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

// CSS
import styles from './ErrorModal.module.scss';

class ErrorModal extends React.Component {

  shouldComponentUpdate(nextProps) {
    if (nextProps !== this.props) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Modal className={styles.errorModal} show={this.props.show}>
        <Modal.Header className="error" closeButton>
          <Modal.Title>Error {this.props.errorCode}</Modal.Title>
        </Modal.Header>

        <Modal.Body className={`centerText ${styles.modalContent}`}>
          <div className={styles.oops}>Oops!</div>
          <div className={styles.errorMessage}>
            {this.props.children}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="buttonDefault closeButton" onClick={this.props.handleClose}>Close</button>
        </Modal.Footer>
      </Modal>
    );
  }

}

ErrorModal.propTypes = {
  children: PropTypes.node,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  errorCode: PropTypes.any,
};

export default ErrorModal;
