import React from 'react';
import history from 'sbHistory';

import 'primeflex/primeflex.css';
import './style.scss';

import ScrollPanel from 'sbCore/ScrollPanel/ScrollPanel';
import Image from 'sbCore/Image/Image';

/**
 * @description Catches uncaught errors and renders a fallback UI stating something has gone wrong
 *              instead of unmounting the component tree.
 * @param {Any} children - Hosts the component(s) that we want to catch errors with
 * @param {String} className - Classname for the error boundary container
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      isRouteError: false, // if the error is a result of routing (ex. invalid route)
    };
  }

  componentDidMount() {
    const { props, state } = this;
    // A listener is created here such that if the user navigates to another page,
    // the hasError state will be updated and will no longer display the fallback UI
    this.unlisten = history.listen((location, action) => {
      if (state.hasError) {
        this.setState({ hasError: false, isRouteError: false });
      }
    });
  }

  // componentWillUnmount() {
  //   this.unlisten();
  // }

  static getDerivedStateFromError(error) {
    // Return state based on the caught error.
    const errorState = { hasError: true };

    // development environment errors
    if (error.message.includes('Check the render method of `Route`')) {
      // This is an error with routing (ex. going to a page that doesnt exist)
      errorState.isRouteError = true;
    }

    // minified production environment code errors
    if (error.message.includes('Minified React error #130')) {
      // This is an error with routing (ex. going to a page that doesnt exist)
      errorState.isRouteError = true;
    }
    return errorState;
  }

  componentDidCatch(error, errorInfo) {
    // Log errors to a service here
    this.setState({ ...this.state, error });
  }

  render() {
    const { props, state } = this;
    let fallbackDisplay = props.children;

    let className = 'error-boundary';

    // Because error-boundary is a wrapper component that has its own styles, it can clash with the styling of the child components it wraps
    // therefore, we use .show to apply the error-boundary style when the error boundary is actually supposed to come into effect
    if (state.hasError) className += ' show';

    if (props.className) className += ` ${props.className}`;

    // Errors that are caught
    if (state.hasError && state.isRouteError) {
      fallbackDisplay = (
        <div className="fallback-display">
          <div className="message-container">
            <div className="title">Oh, hello!</div>
            <div className="sub-title">It appears you&apos;ve found our office pup</div>
            <div className="message">
              <div>Unfortunately, the page you&apos;re looking for doesn&apos;t exist or you don&apos;t have access to view it</div>
              <div>
                If you&apos;re sure you should be seeing something else besides our goodest boy, contact your
                account administrator for access or our Support Team for a resolution
              </div>
            </div>
            <div className="mt-3 sb-font-secondary text-gray-calm" style={{ fontSize: '.9em' }}>Thank you,</div>
            <div className="message-logo-container">
              <Image className="message-logo" src="https://s3.amazonaws.com/onswitchboard.com/white-logo.png" alt="Image" width="130" />
            </div>
          </div>

          <div className="error-image-container">
            <Image className="error-image" src="https://s3.amazonaws.com/onswitchboard.com/misc/walle2.jpg" alt="Image" width="850" />
          </div>

        </div>
      );
    } else if (state.hasError) {
      fallbackDisplay = (
        <div className="fallback-display">
          <div className="message-container">
            <div className="title">Oops, hello!</div>
            <div className="sub-title">It appears you&apos;ve found our office pup</div>
            <div className="message">
              <div>Unfortunately, an error occurred. We&apos;re working on it with this goodest boy!</div>
              <div>
                Try refreshing the page, or come back later to see if the problem resolved itself. If not, contact our Support Team for a resolution
              </div>
            </div>
            <div className="mt-3 sb-font-secondary text-gray-calm" style={{ fontSize: '.9em' }}>Thank you,</div>
            <div className="message-logo-container">
              <Image className="message-logo" src="https://s3.amazonaws.com/onswitchboard.com/white-logo.png" alt="Image" width="130" />
            </div>
            <div className="mt-3 sb-font-secondary text-gray-calm message-stacktrace-container">
              <b>Some useful information for the Switchboard team:</b>
              <ScrollPanel className="message-stacktrace">
                {`${state.error?.stack}`}
              </ScrollPanel>
            </div>
          </div>

          <div className="error-image-container">
            <Image className="error-image" src="https://s3.amazonaws.com/onswitchboard.com/misc/walle1.jpg" alt="Image" width="850" />
          </div>

        </div>
      );
    }

    return (
      <div className={className}>
        {fallbackDisplay}
      </div>
    );
  }
}

export default ErrorBoundary;
