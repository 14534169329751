import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// API
import * as Helpers from 'api/Helpers';
import { canada as canadaCycles, us as usCycles } from 'api/Lists/HoursCycle';

// Components
import { Tabs, Tab } from 'material-ui/Tabs';
import { Card, CardHeader, CardText } from 'material-ui/Card';
import CircularProgress from 'material-ui/CircularProgress';
import DatePicker from 'material-ui/DatePicker';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import Checkbox from 'material-ui/Checkbox';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import TextField from 'material-ui/TextField';
import { MDBBadge, MDBIcon, MDBRow, MDBCol } from 'mdbreact';


import DeleteXButton from 'components/DeleteXButton/view/DeleteXButton';
import YearMonthPicker from 'components/YearMonthPicker/container/YearMonthPicker';
import ListSelectField from 'components/ListSelectField/view/ListSelectField';
import SBSelect from 'components/Shared/SBSelect/SBSelect';

// CSS
import styles from './BundleLogsModal.module.scss';

class BundleLogsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dayRadioSelector: 'customDays',
      datePickerStates: {}, // holding the values from each corresponding datepicker
      inProgress: false,
      recapTableType: 0, // none 1: canada 2: us
      countryCycles: {}, // will be canadaCycles or usCycles
      cycleTypeInt: 1, // default 1
    };

    this.handleDayRadioSelector = this.handleDayRadioSelector.bind(this);
    this.handleYearMonthSelect = this.handleYearMonthSelect.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addDatePicker = this.addDatePicker.bind(this);
    this.removeDatePicker = this.removeDatePicker.bind(this);
    this.bundleHandler = this.bundleHandler.bind(this);
    this.toggleRecapTable = this.toggleRecapTable.bind(this);
    this.handleCycleSelect = this.handleCycleSelect.bind(this);
  }

  componentDidMount() {
    const newState = { ...this.state, datePickerStates: this.props.eldLogs };
    this.setState(newState, () => {
      if (this.props.miscInfo && this.props.miscInfo.modalOpenDate) {
        this.addDatePicker(undefined, this.props.miscInfo.modalOpenDate);
      } else {
        this.addDatePicker(undefined, moment().toDate());
      }
    });
  }

  handleDayRadioSelector(e, dayRadioSelector, chosenYearMonth) {
    const newState = { ...this.state, dayRadioSelector };
    newState.datePickerStates = {};

    const currentDate = moment().format('YYYY-MM-DD 00:00:00'); // get rid of the time

    let daysCount = 0;
    if (dayRadioSelector === 'customDays' && (this.state.dayRadioSelector !== 'customDays')) {
      // nada
      return this.setState(newState, () => {
        if (this.props.miscInfo && this.props.miscInfo.modalOpenDate) {
          this.addDatePicker(undefined, this.props.miscInfo.modalOpenDate);
        } else {
          this.addDatePicker(undefined, moment().toDate());
        }
      });
    } else if (dayRadioSelector === 'customDateRange') {
      newState.datePickerStates.dateRangeStart = { id: 'dateRangeStart', date: moment(currentDate).toDate() };
      newState.datePickerStates.dateRangeEnd = { id: 'dateRangeEnd', date: moment(currentDate).add(1, 'day').toDate() };
      return this.setState(newState);
    } else if (dayRadioSelector === 'customYearMonth') {
      daysCount = moment(chosenYearMonth || currentDate).daysInMonth();
    } else if (dayRadioSelector === 'last7Days') {
      daysCount = 7;
    } else if (dayRadioSelector === 'last14Days') {
      daysCount = 14;
    } else if (dayRadioSelector === 'last6Months') {
      // month lengths can be variable. get the num of days of each past 6 months and total them
      for (let i = 0; i < 6; i++) {
        const month = moment(currentDate).subtract(i, 'months');
        daysCount += month.daysInMonth();
      }
    }

    // now that we have the amount of days we want to print off, add each day to datepickerstates
    let date = moment(currentDate);
    if (dayRadioSelector === 'customYearMonth') {
      date = moment(chosenYearMonth || currentDate).endOf('month');
      date = date.startOf('day');
    }
    for (let j = 0; j < daysCount; j++) {
      const previousDate = moment(date).subtract(j, 'days');
      const id = previousDate.unix().toString();
      newState.datePickerStates[id] = {
        id,
        date: previousDate.toDate(),
      };
    }

    this.setState(newState);
  }

  handleYearMonthSelect(chosenYearMonth) {
    let chosenDate = moment(chosenYearMonth);
    chosenDate = chosenDate.startOf('day');
    this.handleDayRadioSelector(undefined, 'customYearMonth', chosenDate);
  }

  handleDateRangeChange(e, date, rangeId) {
    const { datePickerStates } = this.state;
    const newState = { ...this.state };

    const currentDateRangeStart = moment(datePickerStates.dateRangeStart.date);
    const currentDateRangeEnd = moment(datePickerStates.dateRangeEnd.date);

    newState.datePickerStates = {
      dateRangeStart: { id: 'dateRangeStart', date: currentDateRangeStart.toDate() },
      dateRangeEnd: { id: 'dateRangeEnd', date: currentDateRangeEnd.toDate() },
    };

    // if the user tries to pick a start date that exceeds 3 months from the end date, cut them off
    // for the opposite case to handle the end date limit, it is handled at UI level in the datepicker component props
    if ((rangeId === 'dateRangeStart') && (moment(currentDateRangeEnd).diff(moment(date), 'months') >= 3)) {
      return this.setState(newState);
    }

    newState.datePickerStates[rangeId].date = date;

    // if user selects start date that is past the end date, change the end date
    if (moment(newState.datePickerStates.dateRangeStart.date).valueOf() >= moment(newState.datePickerStates.dateRangeEnd.date).valueOf()) {
      newState.datePickerStates.dateRangeEnd.date = moment(newState.datePickerStates.dateRangeStart.date).add(1, 'day').toDate();
    }

    const newDateRangeStart = newState.datePickerStates.dateRangeStart.date;
    const newDateRangeEnd = newState.datePickerStates.dateRangeEnd.date;

    // now that we have the correct start and end date range, create states for all dates in-between
    const daysInBetween = moment(newDateRangeEnd).diff(newDateRangeStart, 'days');

    for (let i = 1; i < daysInBetween; i++) {
      const newDate = moment(newDateRangeStart).add(i, 'day');
      newState.datePickerStates[newDate.valueOf()] = { id: newDate.valueOf(), date: newDate.toDate() };
    }

    this.setState(newState);
  }

  handleChange(e, date, id) {
    const newState = { ...this.state };
    newState.datePickerStates[id].date = date;
    this.setState(newState, () => {
      this.props.updateHandler(this.state.datePickerStates);
    });
  }

  addDatePicker(e, date, customId) {
    const newState = { ...this.state };

    if (Object.keys(newState.datePickerStates).length >= 183) {
      // if number of date pickers is aprx 183 (ie 6 months)
      return;
    }

    const id = customId || (new Date()).getTime().toString();
    if (!newState.datePickerStates[id]) {
      newState.datePickerStates[id] = {};
    }
    newState.datePickerStates[id].id = id;
    if (date) {
      newState.datePickerStates[id].date = new Date(date);
    } else {
      newState.datePickerStates[id].date = new Date();
    }
    newState.datePickerStates[id].date.setHours(0, 0, 0, 0);

    this.setState(newState, () => {
      this.props.updateHandler(this.state.datePickerStates);
    });
  }

  removeDatePicker(datePickerId) {
    const newState = { ...this.state };
    if ((Object.keys(newState.datePickerStates).length - 1) === 0) {
      // if we are to remove this datepicker but it results in no datepickers, disallow
      return;
    }
    delete newState.datePickerStates[datePickerId];
    this.setState(newState, () => {
      this.props.updateHandler(this.state.datePickerStates);
    });
  }

  bundleHandler() {
    const { datePickerStates, recapTableType, cycleTypeInt } = this.state;
    let datesChosen = Object.keys(datePickerStates).map(id => {
      console.log(id);
      console.log();
      return datePickerStates[id].date;
    });

    if (datePickerStates.dateRangeStart) {
      // means this was a range-picked date and we need to order accordingly (ascending) rather than what is given
      datesChosen = datesChosen.sort((dateA, dateB) => moment(dateA).valueOf() - moment(dateB).valueOf());
    }

    const cycleTypeObject = (recapTableType) ? { country: ({ 1: 'canada', 2: 'us' })[recapTableType], ruleInt: cycleTypeInt } : undefined;

    this.setState({ ...this.state, inProgress: true }, () => {
      this.props.bundleHandler(datesChosen, cycleTypeObject, (datesChosen.length > 1 || this.props.selectedDriverItems.length > 1)).then(() => {
        this.setState({ ...this.state, inProgress: false });
      });
    });
  }

  toggleRecapTable(enable, type) {
    const newState = { ...this.state };
    if (enable) {
      newState.recapTableType = type;

      if (type === 1) {
        newState.countryCycles = canadaCycles;
      } else if (type === 2) {
        newState.countryCycles = usCycles;
      }
    } else {
      newState.recapTableType = 0;
      newState.countryCycles = {};
    }
    newState.cycleTypeInt = 1;

    this.setState(newState);
  }

  handleCycleSelect(cycleName) {
    const { countryCycles } = this.state;
    const newState = { ...this.state };

    const cycleTypeInts = Object.keys(countryCycles);

    const cycleTypeInt = cycleTypeInts.filter(cycleInt => countryCycles[cycleInt].toLowerCase() === cycleName.toLowerCase())[0];

    newState.cycleTypeInt = parseInt(cycleTypeInt);

    this.setState(newState);
  }


  render() {
    const { dayRadioSelector, recapTableType, countryCycles, cycleTypeInt } = this.state;
    const { driver, drivers, disableELDEditFeature, selectedDriverItems, deleteDriver } = this.props;

    const chosenDates = []; // holds the dates user wants to bundle for ui display
    let selectedDriversRows = [];
    if (selectedDriverItems.length === 0) {
      selectedDriversRows.push(<div>{t('No drivers selected. Please enter a driver above')}</div>);
    } else {
      selectedDriversRows = selectedDriverItems.map((driverItem) => {
        const driverParseObj = driverItem.driver;
        const str = Helpers.toTitleCase(driverParseObj.get('user_fullName'));

        return (
          <div className="mx-2 d-inline-block" style={{ fontSize: '1.25em', cursor: 'pointer' }} onClick={() => deleteDriver(driverParseObj)}>
            <MDBBadge className={styles.driverBadge} pill>{str} <div className="ml-1 d-inline-block"><MDBIcon icon="times" /></div></MDBBadge>
          </div>
        );
      });
    }

    const actions = [];
    if (!this.state.inProgress) {
      actions.push(
        <div className={styles.errorList}>
          {this.props.errors.map((error) => (
            <ul>
              <li>{error}</li>
            </ul>
          ))}
        </div>,
        <FlatButton
          key="generate"
          label={t('Generate')}
          primary
          keyboardFocused
          onClick={() => this.bundleHandler()}
          disabled={Object.keys(this.props.eldLogs).length === 0 || (this.props.sendType === 'nsc' && this.props.toNSCEmailAddress?.length === 0)}
        />,
        <FlatButton
          key="close"
          label={t('Close')}
          default
          onClick={this.props.handleClose}
        />
      );
    } else {
      actions.push(<CircularProgress />);
    }

    const dayRadioStyle = {
      display: 'inline-block',
      width: '16.5%',
      fontSize: '.8em',
      fontWeight: '400',
    };

    let countryCyclesKeys = Object.keys(countryCycles);

    if (recapTableType === 1) {
      // filter out 14 and 24 day cycles for now
      countryCyclesKeys = countryCyclesKeys.filter((key) => [2, 3, 5].indexOf(parseInt(key)) === -1);
    }

    const cyclesList = countryCyclesKeys.map((key) => countryCycles[key]);

    const selectableDriverItems = drivers.map(driver => {

      const driverFullName = this.props.company && this.props.company.get('enableCompanyLinks') ?
        `${Helpers.toTitleCase(driver.get('user_fullName'))} - ${driver.get('belongsToCompany') && driver.get('belongsToCompany').get('name')}`
        :
        `${Helpers.toTitleCase(driver.get('user_fullName'))}`;

      return {
        key: driver.id,
        value: driver.id,
        label: driverFullName,
        driver,
      };
    });


    return (
      <div className={styles.bundleLogsModal}>
        <Dialog
          className={styles.bundleLogsModal}
          title={t('Generate Logs')}
          actions={actions}
          modal
          open={this.props.show || false}
          onRequestClose={this.props.handleClose}
          contentStyle={{ maxWidth: 'none' }}
          autoScrollBodyContent
        >

          <br />

          <Card>
            <CardHeader
              title={t('Logs for')}
            />

            <CardText>
              <MDBRow>
                <MDBCol>
                  <SBSelect
                    containerClassName={styles.autocomplete}
                    isAutocomplete
                    autocompleteLabel={t('Enter Driver Name')}
                    placeholder={(driver) ? Helpers.toTitleCase(driver.get('user_fullName')) : ''}
                    items={selectableDriverItems}
                    selectedItems={selectedDriverItems}
                    showFilter
                    multiSelectable
                    showSelectAll
                    getSelectedItems={this.props.getSelectedDriverItems}
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <div style={{ marginTop: '2em', fontWeight: '600' }}>{t('Printing Logs for Drivers:')} </div>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <div >{selectedDriversRows}</div>
                </MDBCol>
              </MDBRow>
            </CardText>
          </Card>

          <br />

          <Card>
            <CardHeader
              title={
                <div>
                  {t('Date')}
                  {(dayRadioSelector && dayRadioSelector.indexOf('custom') !== -1) &&
                    <div style={{ marginTop: '.5em', fontSize: '.75em' }}>
                      {t('Note: Logs requested passed the driver\'s current date will not be generated')}
                    </div>
                  }
                </div>
              }
            />
            <CardText>
              <div>
                <RadioButtonGroup
                  name="daySelectors"
                  defaultSelected="customDays"
                  onChange={this.handleDayRadioSelector}
                  style={{ marginTop: '1em', marginBottom: '1em', width: '100%' }}
                >
                  <RadioButton
                    value="customDays"
                    label={t('Custom')}
                    disabled={this.state.inProgress}
                    style={dayRadioStyle}
                  />
                  <RadioButton
                    value="customDateRange"
                    label={t('Date Range')}
                    disabled={this.state.inProgress}
                    style={dayRadioStyle}
                  />
                  <RadioButton
                    value="customYearMonth"
                    label={t('Select Month')}
                    disabled={this.state.inProgress}
                    style={dayRadioStyle}
                  />
                  <RadioButton
                    value="last7Days"
                    label={t('Last 7 Days')}
                    disabled={this.state.inProgress}
                    style={dayRadioStyle}
                  />
                  <RadioButton
                    value="last14Days"
                    label={t('Last 14 Days')}
                    disabled={this.state.inProgress}
                    style={dayRadioStyle}
                  />
                  {/*
                  <RadioButton
                    value="last6Months"
                    label="Last 6 Months"
                    disabled={this.state.inProgress}
                    style={dayRadioStyle}
                  />
                  */}
                </RadioButtonGroup>
              </div>
              {dayRadioSelector === 'customDays' &&
                <div>
                  {Object.keys(this.state.datePickerStates).map((id, index) => {
                    chosenDates.push(
                      <div key={`${id}chosenDate`} className={styles.chosenDates}>
                        {moment(this.state.datePickerStates[id].date).format('MMMM D, YYYY')}
                      </div>
                    );

                    return (
                      <div key={`${id}container`} className={styles.datePickerContainer}>
                        <div className={styles.left}>
                          <DatePicker
                            key={id}
                            className={styles.datePicker}
                            mode="landscape"
                            container="inline"
                            floatingLabelText={t('Daily Certification')}
                            formatDate={(date) => moment(date).format('MMMM D, YYYY')}
                            value={this.state.datePickerStates[id].date}
                            onChange={(e, date) => this.handleChange(e, date, id)}
                            autoOk
                          />
                          <DeleteXButton key={`${id}deleteButton`} className={styles.deleteButton} handleClick={() => this.removeDatePicker(id)} />
                        </div>

                        {index === (Object.keys(this.state.datePickerStates).length - 1) &&
                          <div className={`${styles.right} ${styles.addDateButtonContainer}`}>
                            <RaisedButton
                              key="addDate"
                              label={t('Add Date')}
                              primary
                              onClick={(e) => this.addDatePicker(e)}
                            />
                          </div>
                        }
                      </div>
                    );
                  })}
                </div>
              }

              {dayRadioSelector === 'customDateRange' &&
                <div>
                  <div style={{ marginTop: '3.5em', marginBottom: '1em' }}>{t('Select the Date Range of the Logs you wish to print')}</div>
                  <div>
                    <DatePicker
                      className={styles.datePicker}
                      mode="landscape"
                      container="inline"
                      floatingLabelText={t('Range Start')}
                      formatDate={(date) => moment(date).format('MMMM D, YYYY')}
                      value={this.state.datePickerStates.dateRangeStart.date}
                      onChange={(e, date) => this.handleDateRangeChange(e, date, 'dateRangeStart')}
                      style={{ display: 'inline-block', marginRight: '1em' }}
                      autoOk
                    />

                    <DatePicker
                      className={styles.datePicker}
                      mode="landscape"
                      container="inline"
                      floatingLabelText={t('Range End')}
                      formatDate={(date) => moment(date).format('MMMM D, YYYY')}
                      value={this.state.datePickerStates.dateRangeEnd.date}
                      onChange={(e, date) => this.handleDateRangeChange(e, date, 'dateRangeEnd')}
                      minDate={moment(this.state.datePickerStates.dateRangeStart.date).add(1, 'day').toDate()}
                      maxDate={moment(this.state.datePickerStates.dateRangeStart.date).add(3, 'month').toDate()}
                      style={{ display: 'inline-block', marginRight: '1em' }}
                      autoOk
                    />
                  </div>
                </div>
              }

              {dayRadioSelector === 'customYearMonth' &&
                <div>
                  <div style={{ marginTop: '3.5em' }}>{t('Select the Month and Year of Logs below')}</div>
                  <div><YearMonthPicker handleYearMonthSelect={this.handleYearMonthSelect} /></div>
                </div>
              }
            </CardText>
          </Card>

          <br />

          <Card>
            <CardHeader
              title={t('Method')}
            />
            <CardText>
              <MDBCol className="w-100">

                {/* First Row of Check Boxes */}
                <MDBRow className="w-100">
                  {!this.props.disregardLogs && !disableELDEditFeature && (
                    <MDBCol className="w-25">
                      <Checkbox
                        label={t('Include Original Unedited Logs if available')}
                        onCheck={this.props.togglePrintOriginalLogsCheckbox}
                        checked={this.props.printOriginalLogs}
                        disabled={this.state.inProgress}
                        style={{ display: 'inline-block', fontSize: '.8em', fontWeight: '400' }}
                      />
                    </MDBCol>
                  )}

                  {!this.props.disregardLogs && (
                    <MDBCol className="w-25">
                      <Checkbox
                        label={t('Print available Pre-Trip Reports')}
                        onCheck={this.props.togglePrintPreTripsCheckbox}
                        checked={this.props.printPreTrips}
                        disabled={this.state.inProgress}
                        style={{ display: 'inline-block', fontSize: '.8em', fontWeight: '400' }}
                      />
                    </MDBCol>
                  )}

                  {this.props.printPreTrips && (
                    <MDBCol className="w-25">
                      <Checkbox
                        label={t('Only Print Pre-Trip Reports')}
                        onCheck={(e, bool) => this.props.toggleDisregardLogs(bool, 0)}
                        checked={this.props.disregardLogs}
                        disabled={this.state.inProgress}
                        style={{ display: 'inline-block', fontSize: '.8em', fontWeight: '400' }}
                      />
                    </MDBCol>
                  )}

                  {this.props.printCTPAT && (
                    <MDBCol className="w-25">
                      <Checkbox
                        label={t('Only Print C-TPAT/PIP')}
                        onCheck={(e, bool) => this.props.toggleDisregardLogs(bool, 1)}
                        checked={this.props.disregardLogs}
                        disabled={this.state.inProgress}
                        style={{ display: 'inline-block', fontSize: '.8em', fontWeight: '400' }}
                      />
                    </MDBCol>
                  )}

                </MDBRow>

                {/* Second Row of Check Boxes  */}
                <MDBRow className="w-100 mb-3">
                  {this.props.printPostTrips && (
                    <MDBCol className="w-25">
                      <Checkbox
                        label={t('Only Print Post-Trip Reports')}
                        onCheck={(e, bool) => this.props.toggleDisregardLogs(bool, 2)}
                        checked={(this.props.disregardLogs)}
                        disabled={this.state.inProgress}
                        style={{ display: 'inline-block', fontSize: '.8em', fontWeight: '400' }}
                      />
                    </MDBCol>
                  )}
                  { !this.props.disregardLogs && (
                    <>
                      <MDBCol className="w-25">
                        <Checkbox
                          label={t('Show HOS Violations')}
                          onCheck={this.props.toggleShowHOSViolations}
                          checked={this.props.showHOSViolations}
                          disabled={this.state.inProgress}
                          style={{ display: 'inline-block', fontSize: '.8em', fontWeight: '400' }}
                        />
                      </MDBCol>
                      <MDBCol className="w-25">
                        <Checkbox
                          label={t('Include Recap Table (Canada)')}
                          onCheck={(_e, bool) => this.toggleRecapTable(bool, 1)}
                          checked={recapTableType === 1}
                          disabled={this.state.inProgress}
                          style={{ display: 'inline-block', fontSize: '.8em', fontWeight: '400' }}
                        />
                      </MDBCol>
                      <MDBCol className="w-25">
                        <Checkbox
                          label={t('Include Recap Table (USA)')}
                          onCheck={(_e, bool) => this.toggleRecapTable(bool, 2)}
                          checked={recapTableType === 2}
                          disabled={this.state.inProgress}
                          style={{ display: 'inline-block', fontSize: '.8em', fontWeight: '400' }}
                        />
                      </MDBCol>
                    </>
                  )}
                </MDBRow>

                {/* List Select Field */}
                {(!this.props.disregardLogs && recapTableType !== 0) && (
                  <MDBRow className="w-100">
                    <ListSelectField
                      floatingLabelText={t('Select Cycle Type')}
                      value={countryCycles[cycleTypeInt]}
                      onChange={(e, index, cycleName) => this.handleCycleSelect(cycleName)}
                      maxHeight={200}
                      type="recapCycleSelector"
                      list={cyclesList}
                    />
                  </MDBRow>
                )}

                {/* Tabs */}
                <MDBRow className="w-100">
                  <MDBCol className="w-100">
                    <Tabs
                      onChange={(value) => this.props.selectSendType(value)}
                      value={this.props.sendType}
                    >
                      <Tab
                        icon={<i className="material-icons">email</i>}
                        label={t('EMAIL')}
                        value="email"
                      />
                      {
                        // <Tab
                        //   icon={<i className="material-icons">perm_phone_msg</i>}
                        //   label="FAX"
                        //   value="fax"
                        // />
                      }
                      <Tab
                        icon={<i className="material-icons">account_balance</i>}
                        label={t('SEND TO FMCSA')}
                        value="fmcsa"
                      />
                      <Tab
                        icon={<i className="material-icons">account_balance</i>}
                        label={t('SEND TO NSC')}
                        value="nsc"
                      />
                      <Tab
                        icon={<i className="material-icons">picture_as_pdf</i>}
                        label={t('DOWNLOAD PDF')}
                        value="downloadPdf"
                      />
                      {this.props.showEldCsvDownload && (
                        <Tab
                          icon={<i className="material-icons">file_download</i>}
                          label={t('EXPORT CSV')}
                          value="exportCsv"
                        />
                      )}
                    </Tabs>
                    {this.props.sendType === 'email' && (
                      <TextField
                        value={this.props.emailValue}
                        hintText={t('name@email.com')}
                        floatingLabelText={t('Email Address')}
                        onChange={(e, value) => this.props.updateEmailValue(value)}
                      />
                    )}
                    {this.props.sendType === 'fmcsa' && (
                      <TextField
                        value={this.props.outputFileComment}
                        hintText={t('Output Comment')}
                        floatingLabelText={t('FMCSA Output File Comment')}
                        onChange={(e, value) => this.props.updateOutputComment(value)}
                      />
                    )}
                    {this.props.sendType === 'nsc' && (
                      <>
                        <TextField
                          value={this.props.outputFileComment}
                          hintText={t('Output Comment')}
                          floatingLabelText="NSC Output File Comment"
                          onChange={(e, value) => this.props.updateOutputComment(value)}
                        />
                        <TextField
                          value={this.props.toNSCEmailAddress}
                          hintText={t('Email Address')}
                          floatingLabelText="Email Address (required)"
                          onChange={(e, value) => this.props.updateToNSCEmailAddress(value)}
                        />
                      </>
                    )}
                    {
                      // this.props.sendType === 'fax' &&
                      // <TextField
                      //   id="faxInput"
                      //   defaultValue={this.props.faxValue}
                      //   hintText="1 888 888 8888"
                      //   floatingLabelText="Fax Number"
                      // />
                    }
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </CardText>
          </Card>
        </Dialog>
      </div>
    );
  }
}

BundleLogsModal.propTypes = {
  driver: PropTypes.object,
  drivers: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  emailValue: PropTypes.string,
  selectDriver: PropTypes.func.isRequired,
  getSelectedDriverItems: PropTypes.func.isRequired,
  selectedDrivers: PropTypes.array.isRequired,
  bundleHandler: PropTypes.func.isRequired,
  eldLogs: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  miscInfo: PropTypes.object,
  show: PropTypes.bool.isRequired,
  updateHandler: PropTypes.func,
  selectSendType: PropTypes.func,
  sendType: PropTypes.string,
  togglePrintOriginalLogsCheckbox: PropTypes.func,
  toggleDisregardLogs: PropTypes.func,
  toggleShowHOSViolations: PropTypes.func,
  showHOSViolations: PropTypes.bool,
  disregardLogs: PropTypes.bool,
  printOriginalLogs: PropTypes.bool,
  updateEmailValue: PropTypes.func.isRequired,
  outputFileComment: PropTypes.string,
  updateOutputComment: PropTypes.func,
  disableELDEditFeature: PropTypes.bool,
  showEldCsvDownload: PropTypes.bool,
};

export default BundleLogsModal;
