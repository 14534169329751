import Parse from 'parse';
import moment from 'moment-timezone';
import store from '../store';
import * as Helpers from './Helpers';

/** @module File */

/**
 * @memberof module:File
 *
 * @param {*} file
 * @param {*} format
 *
 * @returns
 */
function readFileAsText(file, format = 'UTF-8') {
  if (typeof window.FileReader !== 'function') {
    return console.log('The File API is not supported in this browser version');
  }
  const promise = new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsText(file, format);
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.onerror = (e) => {
      alert('Error with Reading File');
      reject('Error with Reading File');
    };
  });
  return promise;
}

export {
  readFileAsText,
};
// var reader = new FileReader();
// reader.readAsText(file, "UTF-8");
// reader.onload = function (evt) {
//     console.log(evt.target.result);
// }
// reader.onerror = function (evt) {
//   alert('blegh');
// }
