/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import { t } from 'api/Translate';
import uniqid from 'uniqid';
import moment from 'moment-timezone';

// API
import { createGeoPoint, getAttribute } from 'sb-csapi/dist/AAPI';
import { getLocationDescriptionBreakdown } from 'api/VehicleLocation/VehicleLocation';
import { convertDistance } from 'api/Helpers';
import * as Geocode from 'api/Geocode';
import * as Mapbox from 'api/Mapbox';

// Hooks
import { usePrevious } from 'hooks/usePrevious';

// SBCore components
import Chip from 'sbCore/Chip/Chip';
import Button from 'sbCore/Button/Button';
import DataView from 'sbCore/DataView/DataView';
import Calendar from 'sbCore/Calendar/Calendar';
import InputText from 'sbCore/InputText/InputText';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import AddressDialog from 'sbCore/AddressDialog/AddressDialog';
import DutyStatusDropdown from 'sbCore/DutyStatusDropdown/DutyStatusDropdown';
import LocationDescriptionDialog from 'sbCore/LocationDescriptionDialog/LocationDescriptionDialog';

// Styles
import './style.scss';

/**
 * Handles adding, editing, and deleting potential edits.
 *
 * @param {Array} [editObjects] - The initial editObjects to be passed in to fill the edit information
 * @param {String} [className] - Custom className
 * @param {Function} onUpdate - Callback to update the parent component with the editObjects
 * @param {ELDEvent} [eldEvent] - The single event for single event edit
 * @param {Array} [agdtIntervals] - Array of start-end AGDT to be considered. Used for time-snapping values to nearest AGDT
 * @param {Boolean} [disableAddAnotherEditButton] - Disable the add another edit button
 */
function ELDEditModalEditBody({ ...props }) {
  const LocationType = Object.freeze({ START: 0, END: 1 });

  /** State */

  // The array of edit objects to be saved as an eldEdit. The order of the array is important.
  // The order of the edits should be applied as a queue (FIFO). Any edits that overlap an interval will follow this FIFO precedence.
  // An individual editObject has the following properties: { id, startDateTime, endDateTime, eldEventTypeCodeInt, notes, startAddress, endAddress, startAddressLocationDescription, endAddressLocationDescription }
  const [editObjects, setEditObjects] = useState([]);

  // Used for determining if the editObjects have changed
  // This is required because the parent can pass in a new set of editObjects and we don't want to get into a render loop
  const prevEditObjects = usePrevious(editObjects);

  // Used to determine if the address dialog should be shown
  const [showAddressDialog, setShowAddressDialog] = useState(false);

  // Use to set Address Dialog addresses
  const [addressDialogAddress, setAddressDialogAddress] = useState({ geoPoint: createGeoPoint(0, 0) });

  // Used to determine if the location description dialog should be shown
  const [showLocationDescriptionDialog, setShowLocationDescriptionDialog] = useState(false);

  // Used to determine the current edit object being edited (for use with address dialog)
  const [currentEditObjectId, setCurrentEditObjectId] = useState(null);

  // Used to determine time-snapping the chosen time value to the nearest AGDT dateTime to assist the user
  const [agdtIntervals, setAGDTIntervals] = useState(props.agdtIntervals || []);
  const [agdtIntervalsFlat, setAGDTIntervalsFlat] = useState(agdtIntervals.flat().sort((a, b) => a - b)); // array of all agdt start/end times sorted ascending)
  const [agdtAdjustedEditObjectMap, setAGDTAdjustedEditObjectMap] = useState({}); // determines which start/endtimes have already been adjusted

  // Used to determine the which location we are changing (start/end)
  const [locationType, setLocationType] = useState(null);

  // Used to determine max width of location chip
  const startCalendarInputRef = useRef(null);
  const [chipMaxWidth, setChipMaxWidth] = useState(null);

  // Called on component mount. Adds an initial edit object to the array.
  useEffect(() => {
    if (props.editObjects?.length !== 0) {
      setEditObjects([...props.editObjects]);
    } else if (editObjects.length === 0) {
      addEditObject();
    }
  }, []);

  useEffect(() => {
    if (props.editObjects.length === 0) return;

    // Note: This useEffect only cares about changed information in [startAddress, endAddress, startAddressLocationDescription, and endAddressLocationDescription]
    // All other fields are ignored because they are not editable in the parent component
    const _filteredEditObjects = editObjects.map((editObject) => ({
      startAddress: editObject.startAddress,
      endAddress: editObject.endAddress,
      startAddressLocationDescription: editObject.startAddressLocationDescription,
      endAddressLocationDescription: editObject.endAddressLocationDescription
    }));

    const _filteredParentEditObjects = props.editObjects.map((editObject) => ({
      startAddress: editObject.startAddress,
      endAddress: editObject.endAddress,
      startAddressLocationDescription: editObject.startAddressLocationDescription,
      endAddressLocationDescription: editObject.endAddressLocationDescription,
    }));

    if (JSON.stringify(_filteredEditObjects) !== JSON.stringify(_filteredParentEditObjects)) {
      setEditObjects([...props.editObjects]);
    }
  }, [props.editObjects]);

  useEffect(() => {
    setAGDTIntervals(props.agdtIntervals);
    setAGDTIntervalsFlat(props.agdtIntervals.flat().sort((a, b) => a - b));
  }, [props.agdtIntervals]);

  // Called when the ref changes. Used to determine max width of location chip
  useEffect(() => {
    const calendarInputWidth = startCalendarInputRef?.current?.offsetWidth;
    setChipMaxWidth(calendarInputWidth);
  }, [startCalendarInputRef.current]);

  // Send the array of edit objects to the parent component whenever its updated (with a debounce delay)
  // We don't perform any error checking here because the parent handles error checking
  useEffect(() => {
    const delay = 150;

    const timerId = setTimeout(() => {
      if (JSON.stringify(prevEditObjects) !== JSON.stringify(editObjects) && props.onUpdate) props.onUpdate(editObjects);
    }, delay);

    return () => clearTimeout(timerId);
  }, [editObjects]);

  // Called whenever we want to add a new edit object to the array
  function addEditObject() {
    let startDateTime;
    let endDateTime;

    // For the first edit object, default to 00:00:00 to get the user started. Looks nicer
    if (editObjects.length === 0) {
      startDateTime = moment().startOf('day').toDate();
    }

    if (props.eldEvent) {
      startDateTime = moment.parseZone(props.eldEvent.eventDateTime);
    }

    endDateTime = startDateTime;
    setEditObjects([
      ...editObjects,
      {
        id: uniqid(),
        startDateTime,
        endDateTime,
        eldEventTypeCodeInt: undefined,
        notes: undefined,
        startAddress: undefined,
        endAddress: undefined,
      },
    ]);

    // after adding a new edit row, scroll to the bottom after "making sure" the row has rendered
    setTimeout(() => {
      const modalBodyHTML = document.querySelector('.eld-edit-modal-content');
      if (modalBodyHTML) {
        modalBodyHTML.style.scrollBehavior = 'smooth';
        modalBodyHTML.scrollTop = modalBodyHTML.scrollHeight;
        // modalBodyHTML.scrollTo({ top: modalBodyHTML.scrollHeight, behavior: 'smooth' });
      }
    }, 50);

  }

  // Called whenever we want to update an attribute for a given edit object
  function updateEditObject(id, key, value) {
    const currentEditObjectIndex = editObjects.findIndex((editObject) => editObject.id === id);

    const newEditObject = { ...editObjects[currentEditObjectIndex] };

    if (key === 'startAddressLocationDescription' || key === 'endAddressLocationDescription') {
      // Override any existing addresses with the location description
      newEditObject[key] = value.updatedLocationDescriptionString;

      const { city, stateProvince, distance, distanceUnit, heading } = value.updatedLocationDescriptionBreakdown;

      if (key === 'startAddressLocationDescription') newEditObject.startAddress = { city, stateProvince: stateProvince.code, distance, distanceUnit, heading: heading.value, isCustomAddress: true };
      if (key === 'endAddressLocationDescription') newEditObject.endAddress = { city, stateProvince: stateProvince.code, distance, distanceUnit, heading: heading.value, isCustomAddress: true };
    } else if ((key === 'startDateTime') || (key === 'endDateTime')) {
      newEditObject[key] = adjustDateTimeToClosestAGDT(newEditObject, key, value);
    } else {
      newEditObject[key] = value;
    }

    setEditObjects([...editObjects.slice(0, currentEditObjectIndex), newEditObject, ...editObjects.slice(currentEditObjectIndex + 1)]);
  }

  // Called whenever we want to remove an edit object from the array
  function removeEditObject(id) {
    setEditObjects([...editObjects.filter((editObject) => editObject.id !== id)]);
  }

  /**
   * @description Adjust the given dateTime to the nearest AGDT value
   * @param {Object} editObject - The edit object being updated
   * @param {String} key - One of 'startDateTime' or 'endDateTime'
   * @param {Date} [dateTime] - DateTime value of the <key>
   */
  function adjustDateTimeToClosestAGDT(editObject, key, dateTime) {
    if (dateTime === null) return editObject[key]; // if invalid time, go back to what it was before
    let _dateTime = moment(dateTime);
    const editObjectId = editObject.id;
    const THRESHOLD_MINS = 30; // 30 minute threshold
    const _agdtAdjustedEditObjectMap = structuredClone(agdtAdjustedEditObjectMap);

    if (!_agdtAdjustedEditObjectMap[editObjectId]) {
      _agdtAdjustedEditObjectMap[editObjectId] = {
        startDateTime: false,
        endDateTime: false,
      };
    }

    // search through the agdtIntervalsFlat to determine if one is within 5 mins of given dateTime
    for (let i = 0; i < agdtIntervalsFlat.length; i++) {
      const agdtIntervalItem = agdtIntervalsFlat[i];
      const agdtIntervalValue = moment.parseZone(agdtIntervalItem); // retain timezone without needing to know it
      const intervalValueHours = agdtIntervalValue.hours();
      const intervalValueMinutes = agdtIntervalValue.minutes();
      // const intervalValueSeconds = agdtIntervalValue.seconds();
      // const intervalValueMilliseconds = agdtIntervalValue.milliseconds();
      const dateTimeHours = _dateTime.hours();
      const dateTimeMinutes = _dateTime.minutes();

      if (dateTimeHours !== intervalValueHours) {
        continue;
      }

      // the hours match with an agdt interval hour. check to see if the minutes are within the interval
      const minuteDifference = Math.abs(dateTimeMinutes - intervalValueMinutes);
      if (minuteDifference > THRESHOLD_MINS) {
        continue;
      } else {
        // The hours match and minutes are within threshold, we've hit an AGDT value we want to copy
        // But if this edit time has already been adjusted to the same value as this agdt value, let the user override
        if (_agdtAdjustedEditObjectMap[editObjectId][key] === agdtIntervalItem) break;

        _dateTime = moment(agdtIntervalValue);

        // Now that the time is copied to match/snap to AGDT, we want to figure out if this is the
        // start time of an interval because if it is, we do not want to overwrite the AGDT eldEvent
        // in an edit (violation of rules). The end is fine as-is because the end of AGDT is just a normal eldEvent
        for (let j = 0; j < agdtIntervals.length; j++) {
          const agdtInterval = agdtIntervals[j];
          const agdtIntervalStart = agdtInterval[0];
          const agdtIntervalEnd = agdtInterval[1];
          if (agdtIntervalItem === agdtIntervalEnd) break;
          if (agdtIntervalItem !== agdtIntervalStart) continue;

          // the adjusted value does indeed match a start of an interval
          _dateTime = moment(agdtIntervalValue.subtract(1, 'second'));
        }

        _dateTime = moment(_dateTime.toDate());
        _dateTime.hours(agdtIntervalValue.hours());

        _agdtAdjustedEditObjectMap[editObjectId][key] = agdtIntervalItem;
        break;
      }
    }

    setAGDTAdjustedEditObjectMap(_agdtAdjustedEditObjectMap);

    return _dateTime.toDate();
  }

  // The template for a single row in the edit data view
  const itemTemplate = (edit) => (
    <div className="col-12 mb-3 border-none">
      <div className="flex align-items-start gap-3" key={edit.id}>
        {!props.eldEvent && (
          <>
            <div className="flex align-items-start flex-column">
              <InputLabel>Edit Starts At</InputLabel>
              <Calendar
                inputRef={startCalendarInputRef} // Used to determine max width of location chip. Only need to apply the ref to the first calendar item as the other one will be the same width
                timeOnly
                placeholder="hh:mm"
                value={edit.startDateTime || null}
                onChange={(e) => updateEditObject(edit.id, 'startDateTime', e.value)}
                inputClassName="p-inputtext-sm"
              />
              <div className="mt-1 mr-1">
                {edit.startAddress && (
                  <Chip
                    key={uniqid()}
                    style={{ maxWidth: chipMaxWidth }}
                    className={!edit.startAddress?.isCustomAddress ? 'hidden' : 'location-chip text-sm'}
                    label={`${edit?.startAddress?.city}, ${edit?.startAddress?.stateProvince}`}
                    removable
                    onRemove={() => updateEditObject(edit.id, 'startAddress', undefined)}
                  />
                )}
                <span
                  className={edit.startAddress?.isCustomAddress ? 'hidden' : 'address-label-button text-xs'}
                  onClick={() => {
                    setCurrentEditObjectId(edit.id);
                    setLocationType(LocationType.START);
                    setShowAddressDialog(true);
                  }}
                >
                  + Add Start Location
                </span>
              </div>
              {edit.startAddressLocationDescription && (
                <div>
                  <span
                    style={{ maxWidth: chipMaxWidth }}
                    className="text-xs pt-1 font-italic cursor-pointer white-space-nowrap overflow-hidden text-overflow-ellipsis"
                    onClick={() => {
                      setCurrentEditObjectId(edit.id);
                      setLocationType(LocationType.START);
                      setShowLocationDescriptionDialog(true);
                    }}
                  >
                    {edit.startAddressLocationDescription}
                  </span>
                </div>
              )}
            </div>
            <div className="flex align-items-start flex-column">
              <InputLabel>Edit Ends At</InputLabel>
              <Calendar
                timeOnly
                placeholder="hh:mm"
                value={edit.endDateTime || null}
                onChange={(e) => updateEditObject(edit.id, 'endDateTime', e.value)}
                inputClassName="p-inputtext-sm"
              />
              <div className="mt-1 mr-1">
                {edit.endAddress && (
                  <Chip
                    key={uniqid()}
                    style={{ maxWidth: chipMaxWidth }}
                    className={!edit.endAddress?.isCustomAddress ? 'hidden' : 'location-chip text-sm'}
                    label={`${edit?.endAddress?.city}, ${edit?.endAddress?.stateProvince}`}
                    removable
                    onRemove={() => updateEditObject(edit.id, 'endAddress', undefined)}
                  />
                )}
                <span
                  className={edit.endAddress?.isCustomAddress ? 'hidden' : 'address-label-button text-xs'}
                  onClick={() => {
                    setCurrentEditObjectId(edit.id);
                    setLocationType(LocationType.END);
                    setShowAddressDialog(true);
                  }}
                >
                  + Add End Location
                </span>
              </div>
              {edit.endAddressLocationDescription && (
                <div>
                  <span
                    style={{ maxWidth: chipMaxWidth }}
                    className="text-xs pt-1 font-italic cursor-pointer white-space-nowrap overflow-hidden text-overflow-ellipsis"
                    onClick={() => {
                      setCurrentEditObjectId(edit.id);
                      setLocationType(LocationType.END);
                      setShowLocationDescriptionDialog(true);
                    }}
                  >
                    {edit.endAddressLocationDescription}
                  </span>
                </div>
              )}
            </div>
          </>
        )}
        <div className="flex align-items-start flex-column">
          <DutyStatusDropdown
            className="eldedit-modal-dutystatus-dropdown"
            useShortDescription
            eldEventTypeCode={edit.eldEventTypeCodeInt}
            onSelect={(eldEventTypeCodeInt) => updateEditObject(edit.id, 'eldEventTypeCodeInt', eldEventTypeCodeInt)}
          />
          {props.eldEvent && (
            <>
              <div className="mt-1 mr-1">
                {edit.startAddress && (
                  <Chip
                    key={uniqid()}
                    style={{ maxWidth: chipMaxWidth }}
                    className={!edit.startAddress ? 'hidden' : 'location-chip text-sm'}
                    label={`${edit?.startAddress?.city}, ${edit?.startAddress?.stateProvince}`}
                    removable
                    onRemove={() => updateEditObject(edit.id, 'startAddress', undefined)}
                  />
                )}
                <span
                  className={edit.startAddress ? 'hidden' : 'address-label-button text-xs'}
                  onClick={() => {
                    setCurrentEditObjectId(edit.id);
                    setLocationType(LocationType.START);
                    setShowAddressDialog(true);
                  }}
                >
                  + Add Custom Location
                </span>

              </div>
              {edit.startAddressLocationDescription && (
                <span
                  style={{ maxWidth: chipMaxWidth }}
                  className="text-xs pt-1 font-italic cursor-pointer white-space-nowrap overflow-hidden text-overflow-ellipsis"
                  onClick={() => {
                    setCurrentEditObjectId(edit.id);
                    setLocationType(LocationType.START);
                    setShowLocationDescriptionDialog(true);
                  }}
                >
                  {edit.startAddressLocationDescription}
                </span>
              )}
            </>
          )}
        </div>
        <div className="flex flex-grow-1 flex-column">
          <InputLabel>Notes</InputLabel>
          <InputText
            className="p-inputtext-sm"
            value={edit.notes}
            onChange={(event) => updateEditObject(edit.id, 'notes', event.target.value)}
          />
        </div>
        <div className="flex mt-3 pt-1 align-items-center">
          <Button
            className={editObjects.length < 2 ? 'hidden' : 'p-button-danger'}
            rounded
            text
            icon="pi pi-times"
            onClick={() => removeEditObject(edit.id)}
          />
        </div>
      </div>
    </div>
  );

  // JSX
  let className = 'eldedit-modal-edit-body';
  if (props.className) className += ` ${props.className}`;

  return (
    <>
      <div className={className}>
        <AddressDialog
          forceValidLatLng
          visible={showAddressDialog}
          addressObject={addressDialogAddress} // Set this to an empty address so that it resets the form whenever the dialog is shown
          onSaveAddress={async (_, _addressObj) => {

            const latitude = _addressObj?.geoPoint?.latitude;
            const longitude = _addressObj?.geoPoint?.longitude;

            let city = _addressObj.city;
            let stateProvince = _addressObj.stateProvince;
            let country = _addressObj.country;

            if (
              !city
              && latitude
              && longitude
            ) {
              const reverseGeocode = await Geocode.getLocalReverseGeocode([[latitude, longitude]]);
              if (reverseGeocode && reverseGeocode.length > 0) {
                city = reverseGeocode[0]?.asciiName;
                stateProvince = reverseGeocode[0]?.admin1Code?.asciiName;
              }
            }

            const addressObj = {
              city,
              stateProvince,
              distance: undefined,
              distanceUnit: undefined,
              heading: undefined,
              isCustomAddress: true,
            };

            const addressCoordinates = { latitude, longitude };
            // Get location of nearest city
            const cityCenterGeocode = await Mapbox.getGeocode(`${city}, ${stateProvince}`, true);

            if (
              cityCenterGeocode?.features
              && cityCenterGeocode?.features?.length > 0
              && cityCenterGeocode?.features[0]?.geometry?.coordinates
            ) {
              const cityCenterCoordinates = { latitude: cityCenterGeocode?.features[0]?.geometry?.coordinates[1], longitude: cityCenterGeocode?.features[0]?.geometry?.coordinates[0] };
              const distanceAndBearingObj = Geocode.getDistanceAndDirectionBetweenTwoCoordinates(
                [cityCenterCoordinates.latitude, cityCenterCoordinates.longitude],
                [addressCoordinates.latitude, addressCoordinates.longitude]
              );

              if (distanceAndBearingObj && distanceAndBearingObj.distanceKm) {
                let distance = distanceAndBearingObj.distanceKm;
                let distanceUnit = 'KM';
                let heading = distanceAndBearingObj.heading;
                if (country === 'US') {
                  distance = convertDistance(distance, 'km', 'mi');
                  distanceUnit = 'MI';
                }
                addressObj.distance = Math.round(distance);
                addressObj.distanceUnit = distanceUnit;
                addressObj.heading = heading;
              }
            }
            setCurrentEditObjectId(null);
            updateEditObject(currentEditObjectId, locationType === LocationType.START ? 'startAddress' : 'endAddress', addressObj);
            setLocationType(null);
            setShowAddressDialog(false);
          }}
          onCancelAddress={() => {
            setCurrentEditObjectId(null);
            setShowAddressDialog(false);
            setLocationType(null);
          }}
        />
        <LocationDescriptionDialog
          visible={showLocationDescriptionDialog}
          locationDescriptionString={locationType === LocationType.START ? editObjects.find((editObject) => editObject.id === currentEditObjectId)?.startAddressLocationDescription : editObjects.find((editObject) => editObject.id === currentEditObjectId)?.endAddressLocationDescription}
          onSaveLocationDescription={(updatedLocationDescriptionString, updatedLocationDescriptionBreakdown) => {
            updateEditObject(currentEditObjectId, locationType === LocationType.START ? 'startAddressLocationDescription' : 'endAddressLocationDescription', { updatedLocationDescriptionString, updatedLocationDescriptionBreakdown });

            setCurrentEditObjectId(null);
            setShowLocationDescriptionDialog(false);
            setLocationType(null);
          }}
          onCancelLocationDescription={() => {
            setCurrentEditObjectId(null);
            setShowLocationDescriptionDialog(false);
            setLocationType(null);
          }}
        />
        <DataView className="eldedit-modal-dataview" value={editObjects} itemTemplate={itemTemplate} />
      </div>
      {!props.eldEvent && (
        <div className="w-100 text-right">
          <Button
            text
            sbVariant="short"
            severity="secondary"
            icon="pi pi-plus"
            label={t('Add Another Edit')}
            onClick={() => addEditObject()}
            disabled={props.disableAddAnotherEditButton}
            tooltip={props.disableAddAnotherEditButton && 'Please resolve the issues listed above before continuing'}
            tooltipOptions={{ position: 'left', showOnDisabled: props.disableAddAnotherEditButton }}
          />
        </div>
      )}
    </>
  );
}

export default ELDEditModalEditBody;
