import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// api
import { addRecord, createTempRecord, getAttribute, getRecordByObjectId, getCurrentUserCompanyObjectId, getCurrentUserSessionToken, getCurrentUserCompany, updateRecord } from 'sb-csapi/dist/AAPI';
import { getAddresses } from 'sb-csapi/dist/api/Address/Address';

// sbobjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// enums
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

import { Organization, OrganizationName } from 'sb-csapi/dist/enums/Dispatch/Organization';

// components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import AddressDialog from 'sbCore/AddressDialog/AddressDialog';
import Button from 'sbCore/Button/Button';

import './style.scss';

/**
 * @description Retrieves addresses to select from
 * @param {String} [className] - Custom container className
 * @param {String} [panelClassName] - Custom dropdown panel className
 * @param {Object} [style] - Custom inline styles
 * @param {Boolean} [isLoading] - Whether the button should be loading according to a parent
 * @param {Boolean} [disabled] - Whether the button should be disabled according to a parent
 * @param {bool} [label] - Label of the dropdown and also used in the Add/Edit button
 * @param {bool} [hideLabel] - Whether to hide the label on top of the dropdown only, not the Add/Edit button
 * @param {Address} [address] - Address record to display/select by default
 *
 * @param {Boolean} [isCompanyTerminal] - Whether to retrieve addresses that are only company terminals
 * @param {bool} [allowAddAddress] - Shows the text button under the input to allow the adding of an address
 * @param {Function} [onSaveAddress] - If provided, updates the dispatch transfer address with the custom address
 * @param {Function} [onSelectAddress] - If provided, callback that returns the selected address
 * @param {Function} [onToggleAddressDialog] - If provided, callback that activates when the AddressDialog is toggled
 *
 * @example
 * <AddressDropdown />
 *
 * <AddressDropdown
 *  allowAddAddress
 *  onSaveAddress={(address, keyValueObj) => {
 *    console.log(address)
 *  }}
 *  address={getAttribute(belongsToCompany, 'address', true)}
 *  isCompanyTerminal
/>
 */
function AddressDropdown({ ...props }) {

  const [identifier] = useState(uniqid()); // for each of this component that exists on the same page, give it unique identifier for specific dom manipulation
  const [addresses, setAddresses] = useState([]); // the list of options for addresses
  const [address, setAddress] = useState(null); // the currently selected address

  const [isLoading, setIsLoading] = useState(props.isLoading ?? false);
  const [disabled, setDisabled] = useState(props.disabled ?? false);
  const [initCounter, setInitCounter] = useState(0); // a manual trigger to re-init

  const [showAddressDialog, setShowAddressDialog] = useState({ show: false, isEditMode: false }); // whether or not to show the address add / edit form

  useEffect(() => {
    let didCancel = false;

    async function init() {
      const _disabled = props.disabled ?? false;
      const _isLoading = props.isLoading ?? false;
      const propsAddress = props.address && await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'Address', getAttribute(props.address, 'objectId'));
      const _address = convertToAddressObject(propsAddress || null);

      const belongsToCompany = await getRecordByObjectId(
        { sessionToken: getCurrentUserSessionToken() },
        'Company',
        getCurrentUserCompanyObjectId(),
        ['address'],
        ['address'],
      );

      const companyAddress = getAttribute(belongsToCompany, 'address'); // the main company address

      const filters = [];

      if (props.isCompanyTerminal) {
        filters.push(new Filter(QueryRestriction.EQUAL_TO, 'isCompanyTerminal', true));
      }

      // now get the other terminals
      const { addresses } = await getAddresses(
        { sessionToken: getCurrentUserSessionToken() },  // options
        getCurrentUserCompanyObjectId(),  // companyobjectid
        false,      // include child company results
        filters, // filters
        undefined,  // sort - default
        undefined,  // includes
        undefined,  // selects
        true       // query all
      );

      let _addresses = [companyAddress, ...addresses];

      // filter out any addresses that are "empty"
      _addresses = _addresses.filter(address => getAttribute(address, 'address'));

      // standardize the addresses
      _addresses = _addresses.map(_address => convertToAddressObject(_address));

      // remove duplicates
      const seenAddressObjectMap = {};
      _addresses = _addresses.filter(_address => {
        const objectId = _address.objectId;
        if (seenAddressObjectMap[objectId]) return false;

        seenAddressObjectMap[objectId] = true;
        return true;
      });

      if (!didCancel) {
        setDisabled(_disabled);
        setIsLoading(_isLoading);
        if (props.address) setAddress(_address);
        setAddresses(_addresses);
      }
    }

    init();

    return () => { didCancel = true; };

  }, [props.address, initCounter]);

  useEffect(() => {
    if (!props.address) return;

    const _address = convertToAddressObject(props.address);
    setAddress(_address);

  }, [props.address]);

  // ** Callbacks ** //
  // Standardized address output
  function convertToAddressObject(address) {
    const addressObject = {};
    if (!address) return addressObject;

    addressObject.objectId = getAttribute(address, 'objectId');
    addressObject.address = getAttribute(address, 'address');
    addressObject.Address = address;

    return addressObject;
  }
  // if the user selected an address from the input
  function onSelectAddress(e) {
    const _address = e.value;
    if (_address) {
      setAddress(_address);
      const addressRecord = _address.Address;
      // only propagate up if it's a legit address
      if (props.onSelectAddress && getAttribute(addressRecord, 'objectId', true)) props.onSelectAddress(addressRecord);
    }
  }

  function onToggleAddressDialog(isEditMode) {
    setShowAddressDialog({ show: !showAddressDialog.show, isEditMode });
    if (props.onToggleAddressDialog) props.onToggleAddressDialog({ show: !showAddressDialog.show, isEditMode });
  }

  async function onSaveAddress(address, keyValueObj) {
    onToggleAddressDialog(false);
    setIsLoading(true);
    let _address = address;
    let _keyValueObj = keyValueObj || {};
    const isAddressEdited = Object.keys(keyValueObj); // if edits were made to an existing address

    _keyValueObj.belongsToCompany = getCurrentUserCompany();
    if (props.isCompanyTerminal) {
      _keyValueObj.isCompanyTerminal = true;
    }

    if (!address) { // means we are adding a new address
      _address = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'Address', _keyValueObj, getCurrentUserCompanyObjectId());
    } else if (isAddressEdited) { // updating an address
      await updateRecord({ sessionToken: getCurrentUserSessionToken() }, address, _keyValueObj, true);
    }

    setInitCounter(initCounter + 1);
    setIsLoading(false);

    if (props.onSaveAddress) props.onSaveAddress(_address, keyValueObj);
  }

  // ** Templates ** //
  const itemTemplate = (address) => {
    const addressString = address ? address.address : '';
    return (
      <div>
        { addressString }
      </div>
    );
  };

  // ** Misc ** //
  let className = `address-dropdown inline-block ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  const label = props.label || 'Address';
  let placeholder = '';
  if (address) placeholder = address.address;

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && <InputLabel>{ label }</InputLabel>}

      {(!props.isLoading) && (
        <Dropdown
          panelClassName={`address-dropdown-panel${props.panelClassName ? ` ${props.panelClassName}` : ''}`}
          value={address}
          itemTemplate={itemTemplate}
          options={addresses}
          optionLabel="address"
          placeholder={placeholder}
          onChange={(address) => onSelectAddress(address)}
          disabled={disabled}
          variant="inputsmall"
          emptyMessage="No Addresses Found"
        />
      )}

      {(!props.isLoading && props.allowAddAddress) && (
        <div className="flex justify-content-between">
          <span className="m-0 p-0 label-button" onClick={() => onToggleAddressDialog()}>+ Create new { label }</span>
          { address && (
            <span className="m-0 p-0 mr-1 label-button" onClick={() => onToggleAddressDialog(true)}>Edit</span>
          )}
        </div>
      )}

      {(showAddressDialog.show) && (
        <AddressDialog
          visible={showAddressDialog}
          address={(showAddressDialog.isEditMode && address) ? address.Address : undefined}
          onCancelAddress={() => onToggleAddressDialog()}
          onSaveAddress={(address, keyValueObj) => onSaveAddress(address, keyValueObj)}
        />
      )}
    </div>
  );
}

export default AddressDropdown;
