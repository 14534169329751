// libraries
import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';

// api
import { getAttribute } from 'api/Parse';

// enums
import { FrequencyTypesStr, StatusInt } from 'enums/SafetyRecurringReminder';

function ResolveSafetyRecurringReminderModal(props) {
  const [notes, setNotes] = useState('');
  const [nextTriggerDate, setNextTriggerDate] = useState();

  useEffect(function() {
      if (props.safetyRecurringReminderRecord) {
        const triggerDate = getAttribute(props.safetyRecurringReminderRecord, 'triggerDate');
        const frequencyType = getAttribute(props.safetyRecurringReminderRecord, 'frequencyType');

        let notes = getAttribute(props.safetyRecurringReminderRecord, 'notes');
        if (notes) {
          notes = `${notes}\n\nResolved on ${moment().format('MM/DD/YYYY')}\n`;
        } else {
          notes = `Resolved on ${moment().format('MM/DD/YYYY')}\n`;
        }

        let nextTriggerDate = new Date(triggerDate);
        if (frequencyType === FrequencyTypesStr.ONE_TIME) {
          nextTriggerDate = moment(triggerDate).add(1, 'day').toDate();
        } else if (frequencyType === FrequencyTypesStr.MONTHLY) {
          nextTriggerDate = moment(triggerDate).add(1, 'month').toDate();
        } else if (frequencyType === FrequencyTypesStr.ANNUALLY) {
          nextTriggerDate = moment(triggerDate).add(1, 'year').toDate();
        }

        setNotes(notes);
        setNextTriggerDate(nextTriggerDate);
      }
  }, [props]);

  async function handleResolve() {
    await props.handleResolve({
      notes,
      triggerDate: nextTriggerDate,
      statusInt: StatusInt.PAST,
    });

    setNotes('');
    setNextTriggerDate();

    props.handleClose();
  }

  return (
    <>
      <Dialog
        header="Resolve Reminder"
        visible={props.showModal}
        footer={
          <>
            <Button
              label="Cancel"
              icon="pi pi-times"
              onClick={() => props.handleClose()}
            />
            <Button
              label="Resolve"
              icon="pi pi-check"
              onClick={async () => await handleResolve()}
            />
          </>
        }
        onHide={() => props.handleClose(false)}
        modal
        closeOnEscape={false}
        style={{width: '50vw'}}
        draggable={false}
        resizable={false}
      >
        <div className="fluid">
          <div className="field">
            <label htmlFor="calendar">Next Reminder Date</label>
            <Calendar
              id="calendar"
              value={nextTriggerDate}
              onChange={(e) => setNextTriggerDate(e.value)}
              inline
            />
          </div>

          <div className="field">
            <label htmlfor="notes">Notes</label>
            <InputTextarea
              id="notes"
              rows={5}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default ResolveSafetyRecurringReminderModal;
