import { formatName } from 'api/Helpers';

export default class SafetyRecurringReminder {
  /**
   * 
   * @param {string}  name 
   * @param {string}  type 
   * @param {date}    triggerDate
   * @param {string}  frequencyType 
   * @param {array}   drivers
   * @param {array}   vehicles 
   * @param {string}  notes 
   */
  constructor(
    objectId, 
    name,
    type,
    triggerDate,
    frequencyType,
    driver,
    vehicles,
    notes,
  ) {
    this.objectId = objectId;
    this.name = name;
    this.type = type;
    this.triggerDate = triggerDate;
    this.frequencyType = frequencyType;
    this.driver = driver;
    this.vehicles = vehicles;
    this.notes = notes;
  }
}