import React from 'react';
import PropTypes from 'prop-types';
import { Layer, Feature } from 'react-mapbox-gl';

// API
import { getColour } from 'api/Mapbox';

import styles from './ComponentStyles.module.scss';

function LinePath(props) {
  const paintObj = {
    'line-color': '#00BCD4',
    // 'line-color': props.color ? props.color : getColour(), 
    'line-width': props.width ? props.width : 5
  };
  if (props.lineDash) paintObj['line-dasharray'] = props.lineDash;
  return (
    <Layer
      type="line"
      id="gps-path"
      before={props.gpsPointsShowBool ? 'gps-points' : undefined}
      onMouseMove={() => undefined}
      layout={{ 'line-cap': 'round', 'line-join': 'round' }}
      paint={paintObj}
    >
      <Feature coordinates={props.coordinates} />
    </Layer>
  );
}

LinePath.propTypes = {
  coordinates: PropTypes.array.isRequired,
  color: PropTypes.string,
  width: PropTypes.number,
  lineDash: PropTypes.array,
  gpsPointsShowBool: PropTypes.bool,
};

export default LinePath;
