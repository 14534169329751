export const AttributeTypes = Object.freeze({
  'TRIGGER_DATE': 'triggerDate',
  'BELONGS_TO_COMPANY': 'belongsToCompany',
});

export const ReminderTypesStr = Object.freeze({
  'COMPANY': 0,
  'DRIVER': 1,
  'VEHICLE': 2,
});

export const ReminderTypesInt = Object.freeze({
  0: 'Company',
  1: 'Driver',
  2: 'Vehicle',
});

export const FrequencyTypesStr = Object.freeze({
  'ONE_TIME': 0,
  'MONTHLY': 1,
  'ANNUALLY': 2,
});

export const FrequencyTypesInt = Object.freeze({
  0: 'One-Time',
  1: 'Monthly',
  2: 'Annually',
});

export const StatusInt = Object.freeze({
  'UPCOMING': 0,
  'PAST': 1,
});
