import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "../../../sbHistory";

// API
import * as SI from 'api/DriverPatterns/SpeedingIdling';
import { convertDistance, formatDurationString } from 'api/Helpers';

// Components
import Title from 'components/LayoutTitle/view/Title';
import LazyLoadingTable from 'components/LazyLoadingTable/view/LazyLoadingTable';

import SpeedIdingGraph from './SpeedIdlingGraph';
import { Card, CardActions, CardHeader, CardMedia, CardTitle, CardText } from 'material-ui/Card';

class DriverIdlingTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };

  }

  render() {
    const { title, driverSpeedIdleData, isLoading, isLazyLoading, handleLazyLoad, height, monthView, startTimeUTC, distanceUnit } = this.props;

    const driver = driverSpeedIdleData.driver;
    const eldDailyCertifications = driverSpeedIdleData.eldDailyCertifications;
    const drivingTimePeriodsByDay = driverSpeedIdleData.drivingTimePeriodsByDay;
    const speedIdlingObject = driverSpeedIdleData.speedIdlingObject;

    const timezoneOffsetFromUTC = (driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();

    const totals = {
      drivingMs: 0,
      idlingMs: 0,
    };

    let styles = {
      dateTime: { width: '30%', textAlign: 'left' },
      duration: { width: '35%', textAlign: 'left' },
      idling: { width: '35%', textAlign: 'left' },
    };

    const headerRows = [{ key: 'spdvioheader', columns: [
      { element: <div>Driving Interval</div>, props: { style: styles.dateTime } },
      { element: <div>Duration Driving</div>, props: { style: styles.duration } },
      { element: <div>Idling Time this Day</div>, props: { style: styles.idling } },
    ]}];

    if (monthView) {
      styles.dateCategory = { width: '15%', textAlign: 'left' };
      styles.duration.width = '30%';
      styles.dateTime.width = '25%';
      styles.idling.width = '30%';
      headerRows[0].columns.unshift(
        { element: <div>Date</div>, props: { style: styles.dateCategory } }
      );
    }


    let bodyRows = [];

    const drivingTimePeriodsStartTimeUTCMs = drivingTimePeriodsByDay && Object.keys(drivingTimePeriodsByDay); // show rows only if data is finished loading
    let lastSeenELDDailyCertificationId;

    if (drivingTimePeriodsStartTimeUTCMs && drivingTimePeriodsStartTimeUTCMs.length > 0) {
      // if we gots speed violations at all, go through each day and add rows
      drivingTimePeriodsStartTimeUTCMs.map((startTimeUTCMs, index) => {

        if (!monthView && index > 0) { // in case of any errors where we're in daily view but violations extend multiple days
          return;
        }

        // find associated idleTimeMillis for day
        // const associatedELDDailyCertification = eldDailyCertifications.filter(eldDailyCertification => {
        //   return moment(eldDailyCertification.get('startTimeUTC')).valueOf() === startTimeUTCMs;
        // })[0];

        // note we calculate the idling time totals seperately because its for the entire interval whereas this is just for given violations
        // const idleTimeMillis = (associatedELDDailyCertification && associatedELDDailyCertification.get('idleTimeMillis')) || 0;

        const drivingTimePeriods = drivingTimePeriodsByDay[startTimeUTCMs];

        let lastSeenDate;
        drivingTimePeriods.map((drivingTimePeriod) => {

          const eldDailyCertification = drivingTimePeriod.get('eldDailyCertification');
          const idleTimeMs = eldDailyCertification.get('idleTimeMillis') || 0;

          const eldDailyCertificationId = eldDailyCertification.id || eldDailyCertification._localId;
          const isDifferentELDDailyCertification = lastSeenELDDailyCertificationId !== eldDailyCertificationId;

          const drivingTimePeriodStartTime = moment(drivingTimePeriod.get('startTime')).tz(timezoneOffsetFromUTC);
          const drivingTimePeriodEndTime = moment(drivingTimePeriod.get('endTime') || moment()).tz(timezoneOffsetFromUTC);
          const currentDate = moment(drivingTimePeriodStartTime).format('DD-MM-YYYY');
          let periodStart = moment(drivingTimePeriodStartTime).format('hh:mm a');
          let periodEnd = moment(drivingTimePeriodEndTime).format('hh:mm a');

          // accumulate totals
          let drivingMs = 0;
          if (drivingTimePeriod.get('startTime') && drivingTimePeriod.get('endTime')) {
            drivingMs = moment(drivingTimePeriod.get('endTime')).diff(drivingTimePeriod.get('startTime'));
            totals.drivingMs += drivingMs;
          }

          let drivingDurationString = formatDurationString(drivingMs);
          let idlingDurationString = formatDurationString(idleTimeMs);

          const columns = [
            {
              props: { style: styles.dateTime },
              element: <div>{`${periodStart} - ${periodEnd}`}</div>
            },
            {
              props: { style: styles.duration },
              element: <div>{ drivingDurationString }</div>
            },
            {
              props: { style: styles.duration },
              element: (
                <div>
                  { isDifferentELDDailyCertification &&
                    <span>{ idlingDurationString }</span>
                  }
                </div>
              )
            }
          ];

          if (monthView) {
            let dateCategoryString = '';
            if (currentDate !== lastSeenDate) {
              dateCategoryString = currentDate;
              lastSeenDate = currentDate;
            }

            columns.unshift(
              {
                props: { style: styles.dateCategory },
                element: <div>{ dateCategoryString }</div>
              }
            );
          }

          bodyRows.push({
            key: drivingTimePeriod.id,
            props: {
              id: drivingTimePeriod.id,
              selectable: false,
            },
            columns,
          });

          lastSeenELDDailyCertificationId = eldDailyCertificationId;
        });

      });
    } else if (drivingTimePeriodsStartTimeUTCMs) { // only show when info is fully loaded and no data found
      bodyRows = [{
        key: 'norows',
        props: {
          selectable: false,
        },
        columns: [{ element: <div>No violations matching the filter</div>, props: { colSpan: 420 } }]
      }];
    }


    // calculate totals/summaries here
    let drivingDurationString = formatDurationString(totals.drivingMs);

    // now for idling time
    eldDailyCertifications.map(eldDailyCertification => {
      const idleTimeMillis = eldDailyCertification.get('idleTimeMillis') || 0;
      totals.idlingMs += idleTimeMillis;
    });
    let idlingDurationString = formatDurationString(totals.idlingMs);

    const totalsStyle = { fontWeight: '700' };
    const totalsCategoryTitleStyle = { display: 'inline-block', width: '10.5em' };

    let idlingToDriving = ((totals.idlingMs / totals.drivingMs) * 100).toFixed(2);

    if (totals.drivingMs === 0) {
      idlingToDriving =  'N/A';
    }

    bodyRows.push(
        {
          key: 'divider',
          props: { selectable: false },
          columns: [{ props: { colSpan: 420 }, element: <div /> }]
        },
        {
        key: 'totalsAndRatios',
        props: {
          selectable: false,
        },
        columns: [
          {
            props: { colSpan: 420, style: { ...styles.vehicleUnitId, ...totalsStyle } },
            element: (
              <div>
                <div>
                  <span style={totalsCategoryTitleStyle}>Total Time Driving</span> { drivingDurationString }
                </div>
                <div>
                  <span style={totalsCategoryTitleStyle}>Total Time Idling</span> { idlingDurationString }
                  <span style={{ ...totalsCategoryTitleStyle, marginLeft: '2.5em', width: '12em' }}>Time Idling vs Driving</span> { idlingToDriving }%
                </div>
              </div>
            )
          },
        ],
      }
    );

    let speedIdlingGraph;
    if (!monthView) {
      // since this is only for a day, there should only be 1 speedViolationObject date (or 2 if violations extend into the next day from the current day, but we only care about the current day)
      const drivingSequencesStartTimeUTCMs = Object.keys(speedIdlingObject);

      if (drivingSequencesStartTimeUTCMs.length > 0) {
        const speedIdleEvents = speedIdlingObject[drivingSequencesStartTimeUTCMs[0]];

        // find associated daily cert
        const associatedELDDailyCertification = eldDailyCertifications.filter(eldDailyCertification => {
          return moment(eldDailyCertification.get('startTimeUTC')).valueOf() === parseInt(drivingSequencesStartTimeUTCMs[0]);
        })[0];

        const graphStartTimeUTC = (associatedELDDailyCertification && moment(associatedELDDailyCertification.get('startTimeUTC'))) || moment(speedIdleEvents[0].dateTime).startOf('day').toDate();

        speedIdlingGraph = (
          <SpeedIdingGraph
            speedIdleEvents={speedIdleEvents}
            createSpeedIdleObject={SI.createSpeedIdleObject}
            driver={driver}
            startTimeUTC={graphStartTimeUTC}
            scaleToDriverTimezone
          />
        );

        bodyRows.push({
          key: `speedIdlingGraph-${startTimeUTC.getTime()}`,
          props: {
            selectable: false,
          },
          columns: [
            {
              props: { colSpan: 420, style: totalsStyle }, // ratios and all dat
              element: (
                speedIdlingGraph
              )
            },
          ],
        });
      }
    }

    let totalCategoryStyle = { display: 'inline-block', width: '12em' };

    const cardTitle = (
      <div>
        <div style={{ verticalAlign: 'middle', display: 'inline-block', width: '8em', fontSize: '1em', fontWeight: '500' }}>{ title }</div>
        <div style={{ verticalAlign: 'middle', marginLeft: '2em', display: 'inline-block', width: '18em', fontSize: '.8em', fontWeight: '500' }}>
          <div><div style={totalCategoryStyle}>Total Time Driving</div>{ drivingDurationString }</div>
          <div><div style={totalCategoryStyle}>Total Time Idling</div>{ idlingDurationString }</div>
          <div><div style={totalCategoryStyle}>Time Idling vs Driving</div>{ idlingToDriving }%</div>
        </div>
        { !monthView &&
          <div style={{ verticalAlign: 'middle', marginLeft: '2em', display: 'inline-block', width: '40em' }}>
            { speedIdlingGraph }
          </div>
        }
      </div>
    );

    return (
      <div>
        <Card>
          <CardHeader
            title={cardTitle}
            actAsExpander={true}
            showExpandableButton={true}
          />
          <CardText expandable={true}>
            <div style={{ fontSize: '.85em' }}>
            ( Times scaled to { timezoneOffsetFromUTC } )
            </div>
            <LazyLoadingTable
              height={height}
              tableHeaderRows={headerRows}
              tableBodyRows={bodyRows}
              selectable={false}
              multiSelectable={false}
              enableSelectAll={false}
              showCheckboxes={false}
              handleLazyLoad={handleLazyLoad}
              isLoading={isLoading}
            />
          </CardText>
        </Card>
      </div>
    );
  }
}

DriverIdlingTable.propTypes = {
  title: PropTypes.string,
  driverSpeedIdleData: PropTypes.object,
  isLoading: PropTypes.bool,
  isLazyLoading: PropTypes.bool,
  height: PropTypes.any,
  handleLazyLoad: PropTypes.func,
  monthView: PropTypes.bool,
  startTimeUTC: PropTypes.instanceOf(Date),
  distanceUnit: PropTypes.string,
};

export default DriverIdlingTable;
