import React from 'react';
import PropTypes from 'prop-types';
// API
import * as Helpers from 'api/Helpers';
// Components
import ChooseOrAddButton from 'components/ChooseOrAddModal/container/ChooseOrAddButton';
import DatePicker from 'components/DatePicker/container/DatePicker';
// CSS
import styles from './JobActionBlock.module.scss';

function JobActionBlock(props) {
  const jobActionBlockEntry = props.jobActionBlock;
  const jobActionTypeLabel = props.type.toUpperCase();
  return (
    <div className={styles.jobActionBlock}>
      <div className={styles.typeTitle}>{jobActionTypeLabel}</div>
      <table className={styles.table}><tbody>
        <tr className={styles.chooseRow}>
          <td>
            <div className={styles.formGroup}>
              <label className="inputLabel">{jobActionTypeLabel} Location<span className={styles.required}>&nbsp;*</span></label>
              {jobActionBlockEntry.vendor &&
                <div>
                  <div><b>{jobActionBlockEntry.vendor.get('name')}</b></div>
                  <div>{jobActionBlockEntry.vendor.get('address').get('address')}</div>
                  <div>{jobActionBlockEntry.vendor.get('stateProvince') + ',' + jobActionBlockEntry.vendor.get('country')}</div>
                </div>
              }
              <ChooseOrAddButton label="Location" property="vendor" handleChooseOrAdd={(vendorParseObj) => props.handleFormChange('vendor', vendorParseObj, props.type)}>Choose Location</ChooseOrAddButton>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="42">
            <div className={styles.formGroup}>
              <label className="inputLabel">{jobActionTypeLabel} Date</label>
              <DatePicker className="input" selected={jobActionBlockEntry.date} placeholderText="....." onChange={(date) => props.handleFormChange('date', date, props.type)} /><br />
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="42">
            <div className={styles.formGroup}>
              <label className="inputLabel">{jobActionTypeLabel} Notes</label>
              <textarea value={jobActionBlockEntry.notes} onChange={(e) => props.handleFormChange('notes', e.target.value, props.type)} placeholder={`Any comments related to the ${props.type}...`} />
            </div>
          </td>
        </tr>
      </tbody></table>
    </div>
  );
}

JobActionBlock.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  jobActionBlock: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default JobActionBlock;
