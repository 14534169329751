import React, { useState, useEffect } from 'react';

// api
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { createDispatchQuote, getDispatchQuote } from 'api/Dispatch/DispatchQuote';
import { getTotalDispatchQuoteChargeAmount } from 'api/Dispatch/DispatchQuoteAdditionalCharge';
import { getTotalDispatchQuoteDiscountAmount } from 'api/Dispatch/DispatchQuoteDiscount';
import { getDispatchTransfersSum } from 'api/Dispatch/DispatchTransfer';

// sbcore components
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import Divider from 'sbCore/Divider/Divider';

// style
import './style.scss';

function QuoteInformationSubsection({ setActiveTabIndex, dispatchJob, dispatchJobObjectId }) {
  // ** Hooks ** //
  const [dispatchQuote, setDispatchQuote] = useState({
    objectId: null,
    amount: 0,
    tax: 0,
    unit: null,
    fuelSurchargeAmount: null,
    fuelSurchargeType: null,
    isRatePerDistance: null,
    distanceUnit: null,
  });

  const [dispatchQuoteRecord, setDispatchQuoteRecord] = useState(null);

  const [additionalChargesTotal, setAdditionalChargesTotal] = useState(0);
  const [discountsTotal, setDiscountsTotal] = useState(0);
  const [fuelSurchargeTotal, setFuelSurchargeTotal] = useState(0);
  const [totalRate, setTotalRate] = useState(0);
  const [preTaxTotal, setPreTaxTotal] = useState(0);

  // ** Functions ** //
  async function getOrCreateDispatchQuote() {
    let dispatchQuoteParseObj = await getDispatchQuote(dispatchJob);

    if (!dispatchQuoteParseObj) {
      dispatchQuoteParseObj = await createDispatchQuote(dispatchJob);
    }

    setDispatchQuote({
      objectId: getAttribute(dispatchQuoteParseObj, 'objectId'),
      amount: getAttribute(dispatchQuoteParseObj, 'amount') || 0,
      unit: getAttribute(dispatchQuoteParseObj, 'unit') || 'CAD',
      tax: getAttribute(dispatchQuoteParseObj, 'tax') || 0,
      fuelSurchargeAmount: getAttribute(dispatchQuoteParseObj, 'fuelSurchargeAmount', true) || 0,
      fuelSurchargeType: getAttribute(dispatchQuoteParseObj, 'fuelSurchargeType', true),
      isRatePerDistance: getAttribute(dispatchQuoteParseObj, 'isRatePerDistance', true),
      distanceUnit: getAttribute(dispatchQuoteParseObj, 'distanceUnit', true),
    });
    setDispatchQuoteRecord(dispatchQuoteParseObj);
  }

  // ** UseEffect ** //
  useEffect(() => {
    getOrCreateDispatchQuote();
  }, [dispatchJob]);

  useEffect(() => {
    async function _getDispatchTransfersSum() {
      const _totalQuote = await getDispatchTransfersSum(dispatchQuoteRecord, dispatchJobObjectId);
      setTotalRate(_totalQuote);
    }

    _getDispatchTransfersSum();
  }, [dispatchQuoteRecord, dispatchJobObjectId]);

  useEffect(() => {
    async function getTotalAdditionalCharges() {
      const _additionalChargesTotal = await getTotalDispatchQuoteChargeAmount(dispatchQuoteRecord);
      if (!_additionalChargesTotal) return;
      setAdditionalChargesTotal(_additionalChargesTotal);
    }

    async function getTotalDiscounts() {
      const _discountsTotal = await getTotalDispatchQuoteDiscountAmount(dispatchQuoteRecord);
      if (!_discountsTotal) return;
      setDiscountsTotal(_discountsTotal);
    }

    getTotalAdditionalCharges();
    getTotalDiscounts();
  }, [dispatchQuoteRecord]);

  useEffect(() => {
    let fuelSurcharge = 0;
    const _preTaxTotal = totalRate + additionalChargesTotal - discountsTotal;
    if (dispatchQuote.fuelSurchargeType === 'Percentage') fuelSurcharge = _preTaxTotal * (dispatchQuote.fuelSurchargeAmount / 100);
    else if (dispatchQuote.fuelSurchargeType === 'Lump Sum') fuelSurcharge = dispatchQuote.fuelSurchargeAmount;
    setFuelSurchargeTotal(fuelSurcharge);
    setPreTaxTotal(_preTaxTotal + fuelSurcharge);
  }, [additionalChargesTotal, discountsTotal, dispatchQuote, totalRate]);

  // ** Templates ** //

  const quoteInformationFooter = (
    <div className="d-flex justify-content-end my-2">
      <div className="tab-change-button" onClick={() => setActiveTabIndex(2)}>
        Go to Quote Information
        <span className="pi pi-arrow-right ml-1" />
      </div>
    </div>
  );

  const quoteInformationPanelTemplate = (
    <ColoredSection
      className="quote-information-subsection"
      title="3. Quote Information"
      footer={quoteInformationFooter}
      variant="compact"
    >
      <div className="general-information-field">
        <span className="quote-label">{dispatchQuote.isRatePerDistance ? `Rate per Distance (${dispatchQuote.distanceUnit}):` : 'Total Rate:'}</span>
        <span>${totalRate.toFixed(2)}</span>
      </div>
      <div className="general-information-field">
        <span className="quote-label">Total Additional Charges (+FSC):</span>
        <span>${(additionalChargesTotal + fuelSurchargeTotal).toFixed(2)}</span>
      </div>
      <div className="general-information-field">
        <span className="quote-label">Total Discounts:</span>
        <span>-${discountsTotal.toFixed(2)}</span>
      </div>
      <div className="general-information-field mb-0">
        <span className="quote-label">Tax ({dispatchQuote.tax}%):</span>
        <span>${(preTaxTotal * (dispatchQuote.tax / 100)).toFixed(2)}</span>
      </div>
      <Divider className="mt-0" />
      <div className="general-information-field mb-0">
        <span className="quote-label">Quote Total:</span>
        <span>${(preTaxTotal * (1 + (dispatchQuote.tax / 100))).toFixed(2)}</span>
      </div>
    </ColoredSection>
  );

  return (
    <div>
      { quoteInformationPanelTemplate }
    </div>
  );
}

export default QuoteInformationSubsection;
