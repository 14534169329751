import React from 'react';
import PropTypes from 'prop-types';
import history from "../../../sbHistory";
import moment from 'moment-timezone';

import * as Getters from 'api/Getters';

import LazyLoadingTable from 'components/LazyLoadingTable/view/LazyLoadingTable';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';

import {
  Table,
  TableBody,
  TableFooter,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';


class DriverVehiclesTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      queryPage: 0,
      queryFilter: [
        { queryType: 'equalTo', name: 'driver', value: props.driver },
        { queryType: 'exists', name: 'vehicle' },
      ],
      isLoading: false,
      isLazyLoading: false,
      hasLoadedAll: false,

      tableHeight: '50vh',

      driverVehiclePairs: [],
    };

    this.getDriverVehiclePairs = this.getDriverVehiclePairs.bind(this);
    this.handleSelectVehicle = this.handleSelectVehicle.bind(this);
  }

  componentWillMount = () => {
    if (this.props.driver) {
      this.getDriverVehiclePairs();
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if ((this.props.driver !== nextProps.driver)) {
      const newQueryFilter = this.state.queryFilter.map((queryParameter, index) => {
        if (index === 0) {
          return { queryType: 'equalTo', name: 'driver', value: nextProps.driver };
        }
        return { ...queryParameter };
      });
      this.setState({ ...this.state, driverVehiclePairs: [], queryFilter: newQueryFilter }, () => {
        this.getDriverVehiclePairs();
      });
    }

    else if ((nextProps.updateInt !== this.props.updateInt)) {
      this.setState({ ...this.state, driverVehiclePairs: [], queryFilter: this.state.queryFilter, queryPage: 0, hasLoadedAll: false }, () => {
        this.getDriverVehiclePairs();
      });
    }
  }

  getDriverVehiclePairs = () => {
    const { queryPage, queryFilter, isLoading, isLazyLoading, hasLoadedAll } = this.state;

    if (!isLoading && !isLazyLoading && !hasLoadedAll) {
      let loadingState = { ...this.state, isLoading: true };
      if (queryPage > 0) {
        loadingState = { ...this.state, isLazyLoading: true };
      }

      this.setState(loadingState, () => {
        const queryIncludes = ['vehicle', 'vehicle.vehicleLocation', 'vehicle.licensePlate', 'vehicle.eldHardware', 'vehicle.trailer', 'vehicle.trailer.licensePlate'];
        Getters.queryCompanyObjects('VehicleDriverHistory', queryPage, 150, queryFilter, { eventDateTime: 'descending' }, queryIncludes, false, true).then((driverVehiclePairs) => {
          // edge case: VehicleDriverHistory has vehicle pointer, but pointer no longer exists. filter them out
          const _driverVehiclePairs = driverVehiclePairs.filter(driverVehiclePair => driverVehiclePair.get('vehicle') && driverVehiclePair.get('vehicle').id);
          const newState = { ...this.state, driverVehiclePairs: this.state.driverVehiclePairs.concat(_driverVehiclePairs), isLoading: false, isLazyLoading: false, queryPage: queryPage + 1 };

          if (driverVehiclePairs.length === 0) {
            newState.hasLoadedAll = true; // we've queried everything, so stop
          }
          this.setState(newState);
        });
      });
    }
  }

  handleSelectVehicle = (vehicle) => {
    this.props.handleSelectVehicle(vehicle);
  }

  render() {

    const { driver } = this.props;
    const { isLoading, isLazyLoading, driverVehiclePairs, tableHeight } = this.state;

    const columnAdjustStyle = { width: '15%' };
    const driverVehiclesHeaderRows = [{ key: 'header', columns: [
      { element: <div>Unit ID</div>, props: { style: columnAdjustStyle } },
      { element: <div>VIN</div>, props: { style: columnAdjustStyle } },
      { element: <div>Plate</div>, props: { style: columnAdjustStyle } },
      { element: <div>Weigh Station Bypass</div>, props: { style: columnAdjustStyle } },
      { element: <div />, props: { style: { ...columnAdjustStyle, width: '40%' } } }
    ]}];

    let driverVehiclesBodyRows = [];

    if (driverVehiclePairs.length > 0) {
      driverVehiclesBodyRows = driverVehiclePairs.map((driverVehiclePair, index) => {
        const vehicle = driverVehiclePair.get('vehicle');
        const licensePlate = vehicle.get('licensePlate');
        const weighStationBypassVehicle = vehicle.get('weighStationBypassVehicle');
        const weighStationBypassVehicleEnabled = (weighStationBypassVehicle && weighStationBypassVehicle.get('enabled')) ? 'Enabled' : 'Disabled';

        return ({
          key: driverVehiclePair.id,
          props: {
            id: driverVehiclePair.id,
            selectable: false,
          },
          columns: [
            {
              props: { style: columnAdjustStyle },
              element: <div>{ vehicle.get('unitId') }</div>
            },
            {
              props: { style: columnAdjustStyle },
              element: <div>{ vehicle.get('vin') }</div>
            },
            {
              props: { style: columnAdjustStyle },
              element: <div>{ licensePlate.get('plate') }</div>
            },
            {
              props: { style: columnAdjustStyle },
              element: <div>{ weighStationBypassVehicleEnabled }</div>
            },
            {
              props: {
                style: {
                  ...columnAdjustStyle,
                  width: '40%',
                  textAlign: 'right',
                }
              },
              element: (
                <div>
                  { /*
                  <RaisedButton
                    label="Preferences"
                    style={{ display: 'inline-block', marginRight: '.5em' }}
                    onClick={() => this.handleSelectVehicle(vehicle)}
                    primary
                  />
                  */ }

                  <RaisedButton
                    label="Vehicle Details"
                    style={{ display: 'inline-block' }}
                    onClick={() => history.push({ pathname: 'vehicle', search: "vehicle=" + vehicle.id  })}
                    default
                  />
                </div>
              )
            },
          ]
        });
      });
    }
    else {
      driverVehiclesBodyRows = [{
        key: 'norows',
        props: {
          selectable: false,
        },
        columns: [{ element: <div>Driver has no associated vehicles. Once a driver uses a vehicle, preferences will appear here</div>, props: { colSpan: 420 } }]
      }];
    }

    return (
      <div>
        <LazyLoadingTable
          height={tableHeight}
          tableHeaderRows={driverVehiclesHeaderRows}
          tableBodyRows={driverVehiclesBodyRows}
          selectable={false}
          multiSelectable={false}
          enableSelectAll={false}
          showCheckboxes={false}
          handleLazyLoad={this.getDriverVehiclePairs}
          isLoading={isLoading}
          isLazyLoading={isLazyLoading}
          lazyLoadScrollOffset={300}
        />
      </div>
    );
  }
}

DriverVehiclesTable.propTypes = {
  driver: PropTypes.object,
  handleSelectVehicle: PropTypes.func,
  updateInt: PropTypes.number,
};

export default DriverVehiclesTable;
