import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontIcon } from 'material-ui';

// Actions
import { getLinkedDriversForState, deleteDriversForState } from 'actions/Driver';

// Components
import Title from 'components/LayoutTitle/view/Title';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import ViolationsSummaryTable from 'components/ViolationsSummaryTable/view/ViolationsSummaryTable';

// CSS
import styles from './ViolationsSummaryLayout.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

class ViolationsSummaryLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: [
        // onDate: props.location.query.date ? moment(props.location.query.date, 'DDMMYY') : moment(new Date()),
      ],
      sort: { user_fullName: 'ascending' },
    };

    this.state.filterFormFields = [
      { attrName: 'user_fullName', fullName: 'Driver Name', placeholder: '.....', type: 'text' },
    ];

    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
  }

  componentDidMount() {
    document.title = 'Violations Summary - Switchboard';
    this.getDrivers();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.Driver !== this.props.Driver || nextState !== this.state) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    deleteDriversForState();
  }

  getDrivers() {
    const promise = new Promise(resolve => {
      const { Company } = this.props;
      const { sort, queryPage, filter, isLoading, isLazyLoading, hasLoadedAll, tableView, driverType } = this.state;

      // page, limit, filter, sortBy, view, disabled, company
      if (!isLoading && !isLazyLoading && !hasLoadedAll) {
        let loadingState = { ...this.state, isLoading: true };
        if (queryPage > 0) {
          loadingState = { ...this.state, isLazyLoading: true };
        }

        this.setState(loadingState, () => {
          const self = this;
          getLinkedDriversForState(undefined, undefined, filter, sort, tableView, false, Company.company).then(
            (drivers) => {
              const newState = { ...loadingState, isLoading: false, isLazyLoading: false, queryPage: queryPage + 1 };

              newState.hasLoadedAll = true; // we've queried everything, so stop

              self.setState(newState, () => resolve(true));
            }
          );
        });
      }
    });
    return promise;
  }

  filter(filterObject) {
    this.pageCount = 0;
    const newState = { ...this.state, filter: filterObject };
    deleteDriversForState().then(() => {
      this.setState(newState, () => {
        this.fetchDriversAndViolations(undefined, 20);
      });
    });
  }

  clearFilter() {
    this.pageCount = 0;
    deleteDriversForState().then(() => {
      this.setState({ ...this.state, filter: [] }, () => {
        this.fetchDriversAndViolations(undefined, 20);
      });
    });
  }

  render() {
    return (
      <div className={styles.container}>
        <div>
          <FilterForm
            className="align-middle d-inline-block"
            handleFilter={this.filter}
            clearFilter={this.clearFilter}
            fields={this.state.filterFormFields}
          />
          <button className="buttonDefault align-middle" onClick={() => this.filter(this.state.filter)}> <FontIcon className="material-icons">refresh</FontIcon> Refresh Data</button>
        </div>

        <div className={styles.driverHoursTable}>
          <ViolationsSummaryTable
            Driver={this.props.Driver}
            sortHandler={this.sortHandler}
            sort={this.state.sort}
          />
        </div>
      </div>
    );
  }
}

ViolationsSummaryLayout.propTypes = {
  Driver: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Company, Driver } = state;
  return {
    Company,
    Driver,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(ViolationsSummaryLayout);

