import React, { useState, useEffect } from 'react';

// sbCore Components
import Column from 'sbCore/Column/Column';
import DataTable from 'sbCore/DataTable/DataTable';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import InputText from 'sbCore/InputText/InputText';
import InputNumber from 'sbCore/InputNumber/InputNumber';

// api
import { getDispatchQuoteAdditionalCharge, createDispatchQuoteAdditionalCharge, updateDispatchQuoteAdditionalCharge, deleteDispatchQuoteAdditionalCharge } from 'api/Dispatch/DispatchQuoteAdditionalCharge';
import { getAttribute } from 'sb-csapi/dist/AAPI';

// styles
import './style.scss';

function QuoteInformationAdditionalCharges({ dispatchQuote, toggleAdditionalDetailsState }) {

  // ** Use States ** //
  const [additionalCharges, setAdditionalCharges] = useState([]);
  const [additionalChargesRecords, setAdditionalChargesRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // ** use effects ** //
  useEffect(() => {
    setIsLoading(false);
    console.log(additionalCharges);
  }, [additionalCharges]);

  useEffect(() => {
    async function _getDispatchQuoteAdditionalCharge() {
      const dispatchQuoteAdditionalCharges = await getDispatchQuoteAdditionalCharge(dispatchQuote);
      console.log(dispatchQuoteAdditionalCharges);
      if (!dispatchQuoteAdditionalCharges) return;
      setAdditionalCharges(dispatchQuoteAdditionalCharges.map((additionalCharge) => {
        return {
          objectId: getAttribute(additionalCharge, 'objectId'),
          chargeType: getAttribute(additionalCharge, 'chargeType', true) || '',
          description: getAttribute(additionalCharge, 'description', true) || '',
          rate: getAttribute(additionalCharge, 'rate', true) || 0,
        }
      }));
      setAdditionalChargesRecords(dispatchQuoteAdditionalCharges);
    }

    _getDispatchQuoteAdditionalCharge();
  }, [dispatchQuote, isLoading]);

  // ** Enums ** //
  const chargeTypes = [
    'Extra Stop',
    'Wait Time',
    'Other',
  ];

  // ** Functions ** //

  async function addCharge() {
    setIsLoading(true);
    await createDispatchQuoteAdditionalCharge(dispatchQuote);
  }

  async function deleteCharge(rowIndex) {
    setIsLoading(true);
    const _additionalCharges = [...additionalCharges];
    _additionalCharges.splice(rowIndex, 1);
    setAdditionalCharges(_additionalCharges);
    await deleteDispatchQuoteAdditionalCharge(additionalChargesRecords[rowIndex]);
    toggleAdditionalDetailsState();
  }

  function onRowEditComplete(e) {
    const _additionalCharges = [...additionalCharges];
    const { newData, index } = e;
    _additionalCharges[index] = newData;

    updateDispatchQuoteAdditionalCharge(_additionalCharges[index].objectId, _additionalCharges[index], true);

    setAdditionalCharges(_additionalCharges);
    toggleAdditionalDetailsState();
  }

  // ** Templates ** //

  function chargeTypeEditor(options) {
    return (
      <Dropdown
        value={options.value}
        options={chargeTypes}
        onChange={(e) => options.editorCallback(e.value)}
        variant="small"
        placeholder="Type"
      />
    );
  }

  function chargeDescriptionEditor(options) {
    return (
      <InputText
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        variant="small"
        placeholder="Details"
      />
    );
  }

  function chargeRateEditor(options) {
    return (
      <div className="p-inputgroup">
        <span className="p-inputgroup-addon">$</span>
        <InputNumber
          value={options.value}
          onChange={(e) => options.editorCallback(e.value)}
          placeholder="0.00"
          className="input-group-field"
          minFractionDigits={2}
        />
      </div>
    );
  }

  const chargeFooterTemplate = (
    <div className="add-detail-button" onClick={() => addCharge()}>
      <span className="pi pi-plus-circle" /> Add Charge
    </div>
  );

  const chargeRateTemplate = (rowData) => {
    return <div>$ {rowData.rate.toFixed(2)}</div>
  };

  function deleteAdditionalChargesTemplate(rowData, options) {
    return (
      <span className="pi pi-trash delete-button" onClick={() => deleteCharge(options.rowIndex)} />
    );
  }

  return (
    <DataTable
      value={additionalCharges}
      editMode="row"
      footer={chargeFooterTemplate}
      loading={isLoading}
      variant="compact"
      onRowEditComplete={(e) => onRowEditComplete(e)}
      className="mb-4"
    >
      <Column field="chargeType" header="Charge Type" className="w-25" editor={(options) => chargeTypeEditor(options)} />
      <Column field="description" header="Description" headerStyle={{ width: '35%' }} editor={(options) => chargeDescriptionEditor(options)} />
      <Column field="rate" header="Rate" className="w-25" body={chargeRateTemplate} editor={(options) => chargeRateEditor(options)} />
      <Column headerStyle={{ width: '5%' }} body={(rowData, options) => deleteAdditionalChargesTemplate(rowData, options)} />
      <Column rowEditor headerStyle={{ minWidth: '1.5rem' }} bodyStyle={{ textAlign: 'center' }} />
    </DataTable>
  );
}
export default QuoteInformationAdditionalCharges;
