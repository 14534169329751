import React from 'react';
import PropTypes from 'prop-types';

// CSS
import styles from './CloseXButton.module.scss';
import { MDBIcon } from 'mdbreact';

function CloseXButton(props) {
  return (
    <div className={`${styles.closeButton} ${props.className}`} onClick={props.handleClick} style={props.style}>
      <MDBIcon icon="times"></MDBIcon>
    </div>
  );
}

CloseXButton.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  handleClick: PropTypes.func.isRequired,
};

export default CloseXButton;
