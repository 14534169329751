import React from 'react';

// PrimeReact Component
import { Editor as PREditor } from 'primereact/editor';

// Styles
import './style.scss';

/**
 * @description PrimeReact editor component
 * @param {String} className - Custom container className
 */
export default function Editor({ ...props }) {
  // ** Misc ** //
  let className = 'sbc-editor';

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div className={className}>
      <PREditor {...props} />
    </div>
  );
}
