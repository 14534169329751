import * as ActionConstants from 'actions/ActionConstants';

const initialState = {
  cloudUserPreference: {},
  fetched: false,
};

export function CloudUserPreference(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_CLOUDUSERPREFERENCE_INPROGRESS:
      return {
        ...state,
        status: ActionConstants.FETCH_CLOUDUSERPREFERENCE_INPROGRESS,
        cloudUserPreference: undefined,
        error: undefined,
        fetched: false,
      };
    case ActionConstants.FETCH_CLOUDUSERPREFERENCE_SUCCESS:
      return {
        ...state,
        status: ActionConstants.FETCH_CLOUDUSERPREFERENCE_SUCCESS,
        cloudUserPreference: action.cloudUserPreference,
        error: undefined,
        fetched: true,
      };
    case ActionConstants.FETCH_CLOUDUSERPREFERENCE_ERROR:
      return {
        ...state,
        status: ActionConstants.FETCH_CLOUDUSERPREFERENCE_ERROR,
        error: action.error,
        fetched: false,
      };
    case ActionConstants.UPDATE_CLOUDUSERPREFERENCE_SUCCESS:
      return {
        ...state,
        status: ActionConstants.UPDATE_CLOUDUSERPREFERENCE_SUCCESS,
        cloudUserPreference: action.cloudUserPreference || state.cloudUserPreference,
      };
    default:
      return state;
  }
}
