import uniqid from 'uniqid';
import React, { useState, useEffect } from 'react';

// API
import { getUsers } from 'api/User/User';
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { formatName } from 'sb-csapi/dist/utils/String';

// sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Enums
import { AdminTypes } from 'enums/UserTypes';
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// sbCore Components
import Autocomplete from 'sbCore/Autocomplete/Autocomplete';

// Styling
import './style.scss';

/**
 * @description Retrieve Users given a search term
 *
 * @param {Object} [style] - Custom inline styles
 * @param {String} [className] - Custom container className
 * @param {String} [panelClassName] - Custom dropdown panel className
 * @param {String} [placeholder] - Custom placeholder text
 *
 * @param {bool} [warning] - Makes input border yellow
 * @param {bool} [multiple] - Boolean to allow multiple selections
 * @param {bool} [autoFocus] - Boolean to automatically focus on the autocomplete on load
 * @param {bool} [disabled] - Boolean to disable component
 * @param {bool} [hideSelectedUsers] - Boolean to determine whether to hide search suggestions for already selected users
 * @param {bool} [displayOnlyAdminTypes] - Boolean to display only admin type users
 *
 * @param {Array<String>} [excludedUserObjectIds] - Array of user objectIds to exclude from search suggestions
 * @param {Array<Filter>} [suggestionFilters] - Array of filters to apply to the search suggestions
 *
 * @param {Function} [onSelectUser] - Callback function that returns the selected User (when multiple is NOT true)
 * @param {Function} [onSelectUsers] - Callback function that returns the selected Users (when multiple is true)
 *
 * @returns {JSX} The autocomplete input component
 */
function UserAutocompleteInput({ ...props }) {
  // ** UseState ** //
  const [identifier] = useState(uniqid()); // This is in a useState so that the value does not change across renders

  const [searchTerm, setSearchTerm] = useState('');
  const [userSuggestions, setUserSuggestions] = useState([]); // Array of user suggestions
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [hasError, setHasError] = useState(false);

  /**
   * @description Handles fetching for user suggestions based on the query
   * @param {String} [query] - the query to search for (name of user)
   */
  async function handleOnSuggestionSearch(query) {
    setHasError(false); // reset the error state

    const _query = (query || '').trim();
    setSearchTerm(_query);

    let filters = [
      ...(props.suggestionFilters || []),
      new Filter(QueryRestriction.LIMIT, undefined, 120),
    ];

    if (_query.length > 0) {
      filters = [
        ...(props.suggestionFilters || []),
        new Filter(QueryRestriction.LIMIT, undefined, 15),
        new Filter(QueryRestriction.MATCHES, 'firstName', _query),
      ];
    }

    props.displayOnlyAdminTypes && filters.push(new Filter(QueryRestriction.CONTAINED_IN, 'userType', Object.values(AdminTypes).map((admintType) => admintType.type)));

    const { users } = await getUsers(
      undefined, // options
      undefined, // companyObjectId
      undefined, // includeChildCompanies
      filters, // filters
      undefined, // sortBy
      undefined, // includedPointers
      undefined, // selectedAttributes
      undefined, // page
      undefined, // limit
      undefined, // queryAll
    );

    let _userSuggestions = users.map((user) => ({
      objectId: getAttribute(user, 'objectId'),
      fullName: formatName(`${getAttribute(user, 'firstName')} ${getAttribute(user, 'lastName')}`),
      user,
    }));

    if (props.hideSelectedUsers && props.multiple) {
      // Filter out any suggestions that have already been selected - this can only happen if multiple is true as the user can only select more than one user at a time
      const selectedUserObjectIdArr = selectedUsers.map((user) => user.objectId);
      _userSuggestions = _userSuggestions.filter((user) => !selectedUserObjectIdArr.includes(user.objectId));
    }

    if (props.excludedUserObjectIds) {
      // Filter out any suggestions that should be excluded
      _userSuggestions = _userSuggestions.filter((user) => !props.excludedUserObjectIds.includes(user.objectId));
    }

    if (_query.length > 0) {
      _userSuggestions = _userSuggestions.filter((user) => user.fullName && user.fullName.toLowerCase().includes(_query.toLowerCase()));
    }

    setUserSuggestions(_userSuggestions);
    if (_userSuggestions.length !== 0 && _query) setHasError(true);
  }

  // ** UseEffects ** //
  useEffect(() => {
    // Initial trigger to fetch user suggestions
    // This also doubles as a way to allow inputEl and inputButtonEl to be fetched (otherwise, there is a slight delay where the inputEl and inputButtonEl are null)
    handleOnSuggestionSearch('');
  }, []);

  useEffect(() => {
    if (props.onSelectUser) {
      props.onSelectUser(selectedUsers.user);
    } else if (props.multiple && props.onSelectUsers) {
      // Grab only the user records that were selected
      const _selectedUsers = selectedUsers.map(user => user.user);
      props.onSelectUsers(_selectedUsers);
    }
  }, [selectedUsers]);

  // ** Templates ** //
  const itemTemplate = (item) => (
    <div className="user-item">
      <div>{item.fullName || 'Missing name'}</div>
    </div>
  );

  // ** Misc ** //
  const inputEl = document.querySelector(`.user-autocomplete-input.${identifier} input[type="text"]`); // the autocomplete input element
  const inputButtonEl = document.querySelector(`.user-autocomplete-input.${identifier} button.p-button`); // the autocomplete dropdown button

  if (inputEl) {
    inputEl.autocomplete = 'disabled'; // disable the browser's default autocomplete for inputs
  }

  const triggerAutocompleteOnClick = () => {
    // open dropdown if user focuses on input
    if (inputButtonEl) inputButtonEl.click();
  };

  const triggerAutocompleteOnKeyPress = (e) => {
    // if the input is focused
    if (inputButtonEl) {
      const code = (e.keyCode ? e.keyCode : e.which);
      if (code === 13) inputButtonEl.click();
    }
  };

  let className = `user-autocomplete-input ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  let panelClassName = 'user-autocomplete-input-panel';
  if (props.panelClassName) panelClassName += ` ${props.panelClassName}`;

  let placeholder = 'User';
  if (props.placeholder) placeholder = props.placeholder;

  // if the input is not being focused on (which is for some reason dictated by the button's focus),
  // but the user left their search term without selecting a suggestion for the searchTerm
  const isNotFocusedWithText = inputButtonEl && searchTerm && (document.activeElement !== inputButtonEl);

  const _hasError = isNotFocusedWithText || hasError;

  return (
    <div className={className} style={{ ...props.style }}>
      <Autocomplete
        placeholder={placeholder}
        dropdown
        field="fullName"
        value={selectedUsers}
        multiple={props.multiple}
        suggestions={userSuggestions}
        forceSelection={false}
        itemTemplate={itemTemplate}
        autoFocus={props.autoFocus}
        warning={props.warning}
        error={_hasError}
        completeMethod={({ originalEvent, query }) => handleOnSuggestionSearch(query)}
        onChange={(e) => setSelectedUsers(e.value)}
        onClick={() => triggerAutocompleteOnClick()}
        onKeyPress={(e) => triggerAutocompleteOnKeyPress(e)}
        panelClassName={panelClassName}
        disabled={props.disabled}
      />
    </div>
  );
}

export default UserAutocompleteInput;
