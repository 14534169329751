import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from "../../../sbHistory";

import { Modal } from 'react-bootstrap';

// Helpers
import * as ELD from 'api/ELD';
import * as Helpers from 'api/Helpers';
import { checkAndSetAOBRDELDHardwares } from 'api/Setters';
import { getCurrentUser } from 'api/Parse';
import { getVehicleLocationDescription, getLocationDescriptionBreakdown, isValidLocationDescription } from 'api/VehicleLocation/VehicleLocation'
import { getAttribute } from 'sb-csapi/dist/AAPI';

// enums
import { LocationDirectionTypes, LocationDirectionTypeDescription } from 'enums/LocationDirection';
import { LengthUnit, LengthUnitNames, LengthUnitTypes } from 'enums/DispatchUnit';
import { ELDEditType } from 'enums/ELDEditType';
import { CountryCodeLocationDescriptionTypes } from 'enums/VehicleLocation';
import { StateProvince } from 'enums/StateProvince';

// Components
import Dialog from 'material-ui/Dialog';
import Divider from 'material-ui/Divider';
import CircularProgress from 'material-ui/CircularProgress';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'rc-time-picker';
import Checkbox from 'material-ui/Checkbox';
import Snackbar from 'material-ui/Snackbar';

import HOSGraph from 'components/DriverDetails/container/HOSGraph';
import HOSViolationsTable from 'components/DriverDetails/view/HOSViolationsTable';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import WalkthroughSelectTip from 'components/WalkthroughSelectTip/container/WalkthroughSelectTip';
import ELDEditHelpDialog from './ELDEditHelpDialog';
import VehicleAutocompleteInput from 'sbCore/VehicleAutocomplete/VehicleAutocompleteInput';

import SBSelect from 'components/Shared/SBSelect/SBSelect';
import { StateProvinceSelector } from 'components/Shared/StateProvinceSelector/StateProvinceSelector';
import { MDBRow, MDBCol, MDBInput } from 'mdbreact';


// CSS
import 'rc-time-picker/assets/index.css';
import styles from './ELDEditModal.module.scss';

class ELDEditModal extends React.Component {
  constructor(props) {
    super(props);
    // based on the order of ELDEventTypeCode relation
    // this.eldEventTypeCodes = [];
    // this.eldEventTypeCodes[11] = 'offDuty';
    // this.eldEventTypeCodes[12] = 'sleeper';
    // this.eldEventTypeCodes[13] = 'driving';
    // this.eldEventTypeCodes[14] = 'onDuty';
    // this.eldEventTypeCodes[31] = 'personalUseCMV';
    // this.eldEventTypeCodes[32] = 'yardMoves';
    const { driver, eldDailyCertification, scaleToDriverTimezone } = props;
    const timezoneOffsetFromUTC = (scaleToDriverTimezone && driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();

    const startTimeUTC = moment(eldDailyCertification.get('startTimeUTC')).tz(timezoneOffsetFromUTC);
    const endTimeUTC = moment(startTimeUTC).tz(timezoneOffsetFromUTC).add(1, 'day');
    endTimeUTC.subtract(1, 'millisecond');

    let defaultELDEventTypeCodeInt = 11; // when requesting an edit so we know which status to start off from if we lack info. by default Off-Duty
    if (props.associatedELDEvents[1]) {
      defaultELDEventTypeCodeInt = props.associatedELDEvents[1].get('eldEventTypeCodeInt');
    } else if (props.associatedELDEvents[0]) {
      defaultELDEventTypeCodeInt = props.associatedELDEvents[0].get('eldEventTypeCodeInt');
    }

    if (props.eldEvents[0] && !props.eldEvents[0].id) {
      // the start event is fake (ex. a patch to replace a bad event, or an eld edit simulation event)
      // in which case, we want to take on that status of the fake event as default
      defaultELDEventTypeCodeInt = props.eldEvents[0].get('eldEventTypeCodeInt');
    }

    this.state = {
      changesProcessing: false,
      eldEventTypeCodeInts: {
        11: 'OFF',
        12: 'SB',
        13: 'D',
        14: 'ON',
        31: 'CMV',
        32: 'YARD',
      },
      eldEventsForPreview: [],
      editInfo: {
        eldEventTypeCodeInt: undefined,
        startDateTime: startTimeUTC.toDate(),
        endDateTime: startTimeUTC.toDate(),
        editNote: '',
        eventsNote: '',
        localId: (new Date()).getTime() + driver.id,
        driverId: props.driver.id,
        eldDailyCertificationStartTimeUTC: startTimeUTC.toDate(),
        startCustomLocation: undefined,
        endCustomLocation: undefined,
      },
      customLocationBuilder: {
        startCustomLocationObject: {
          distance: '0',
          heading: {
            type: LocationDirectionTypes.N,
          },
          stateProvince: {
            code: undefined,
          }
        },
        endCustomLocationObject: {
          distance: '0',
          heading: {
            type: LocationDirectionTypes.N,
          },
          stateProvince: {
            code: undefined,
          }
        },
        isManuallyEntered: false, // if the location was manually entered or autofilled
      },
      timezoneOffsetFromUTC,
      endTimeUTC: endTimeUTC.toDate(),
      defaultELDEventTypeCodeInt,
      isEntireDayEdit: false,
      editRequests: [], // client side requests to send to server
      eldEditResponse: undefined, // server response to edit requests
      errors: [],
      confirmRequestModal: {
        show: false,
      },
      disableSubmitRequest: true,
      disableGraphRefresh: true,
      editRequestAdded: false, // prompts user when they can add another edit request
      disableGraphRedraw: false,
      interactionCounter: 0, // keeping track of user interface interactions
      showViolations: true,
      walkthroughState: {
        addAnotherEdit: false,
      }
    };

    this.setStateOnMount = this.setStateOnMount.bind(this);
    this.getELDEventsIdString = this.getELDEventsIdString.bind(this);
    this.checkForErrors = this.checkForErrors.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setDOMTextValues = this.setDOMTextValues.bind(this);
    this.resetDOMTextValues = this.resetDOMTextValues.bind(this);
    this.refreshGraphPreview = this.refreshGraphPreview.bind(this);
    this.getRecentVehicleLocationInfo = this.getRecentVehicleLocationInfo.bind(this);
    this.updateCustomLocation = this.updateCustomLocation.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleEntireDayEdit = this.toggleEntireDayEdit.bind(this);
    this.setDutyStatus = this.setDutyStatus.bind(this);
    this.getEditErrors = this.getEditErrors.bind(this);
    this.handleEditReason = this.handleEditReason.bind(this);
    this.addToEditRequests = this.addToEditRequests.bind(this);
    this.submitEditRequests = this.submitEditRequests.bind(this);
    this.toggleWalkthroughState = this.toggleWalkthroughState.bind(this);
    this.updateVehicleInformation = this.updateVehicleInformation.bind(this);
  }

  componentDidMount() {
    const { driver } = this.props;
    this.setStateOnMount();
    if (driver) {
      checkAndSetAOBRDELDHardwares(driver.id);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.show !== this.props.show) {
      return true;
    }
    if (nextState !== this.state) {
      return true;
    }
    if (nextProps.eldEvents !== this.props.eldEvents) {
      return true;
    }
    if (nextProps.associatedELDEvents !== this.props.associatedELDEvents) {
      return true;
    }
    if (nextProps.eldDailyCertification !== this.props.eldDailyCertification) {
      return true;
    }
    return false;
  }

  setStateOnMount() {
    const { driver, scaleToDriverTimezone, eldDailyCertification, singleELDEventEdit, eldEvents } = this.props;
    const { timezoneOffsetFromUTC } = this.state;
    const newState = { ...this.state };
    newState.eldEventsForPreview = [].concat(eldEvents);

    if (singleELDEventEdit) {
      const { breakdown, startCustomLocation } = this.getRecentVehicleLocationInfo();

      newState.editInfo = {
        ...this.state.editInfo,
        eldEventTypeCodeInt: singleELDEventEdit.get('eldEventTypeCodeInt'),
        startDateTime: singleELDEventEdit.get('eventDateTime'),
        endDateTime: singleELDEventEdit.get('eventDateTime'),
        editNote: '',
        eventsNote: singleELDEventEdit.get('note'),
        localId: (new Date()).getTime() + driver.id,
        driverId: driver.id,
        eldDailyCertificationStartTimeUTC: eldDailyCertification.get('startTimeUTC'),
        startCustomLocation: startCustomLocation,
        endCustomLocation: undefined, // keep end location undefined
      };

      newState.customLocationBuilder = {
        startCustomLocationObject: startCustomLocation ? breakdown : {
          distance: '0',
          heading: {
            type: LocationDirectionTypes.N,
          },
          stateProvince: {
            code: undefined,
          }
        }, // only set as breakdown if the location description is legit
        endCustomLocationObject: {},
      }
    }

    this.setState(newState);
  }

  getELDEventsIdString(exemptELDEvents) {
    /*
      - generates a string of events ids we want to pass for edits (that way we dont rely on 2 seperate queries on
      the front and back end to try and achieve the same result)
      - it filters for active and relevant duty-status only events except for the exemptELDEvents given
    */
    const exemptELDEventIds = (exemptELDEvents || []).map(eldEvent => eldEvent.id);
    const eldEvents = this.props.eldEvents.filter(eldEvent => {
      const eldEventRecordStatusInt = getAttribute(eldEvent, 'eldEventRecordStatusInt');
      const eldEventTypeCodeInt = getAttribute(eldEvent, 'eldEventTypeCodeInt');

      if (exemptELDEventIds.indexOf(eldEvent.id) !== -1) return true;

      const isActiveEvent = [0, 1].indexOf(eldEventRecordStatusInt) !== -1;
      const isDutyStatusEvent = [11, 12, 13, 14, 21, 22, 30, 31, 32].indexOf(eldEventTypeCodeInt) !== -1;

      let isOfDailyCert = true;
      if (eldEvent.get('eldDailyCertification')) {
        isOfDailyCert = eldEvent.get('eldDailyCertification') && eldEvent.get('eldDailyCertification').id && (eldEvent.get('eldDailyCertification').id === this.props.eldDailyCertification.id);
      }

      // 2023-07-19 - If the support team is editing with the overrideeldevents url param, we allow for all events to be edited, excluding the following:
      // driver certification events (41-49), off-duty time deferrals (200-202), cycle sets (211/212)
      if (this.props.isOverrideELDEvents) {
        const driverCertificationELDEventTypeCodeInts = [41, 42, 43, 44, 45, 46, 47, 48, 49];
        const offDutyDeferralELDEventTypeCodeInts = [200, 201, 202];
        const canadianCycleSetELDEventTypeCodeInts = [211, 212];

        if (!driverCertificationELDEventTypeCodeInts.includes(eldEventTypeCodeInt) && !offDutyDeferralELDEventTypeCodeInts.includes(eldEventTypeCodeInt) && !canadianCycleSetELDEventTypeCodeInts.includes(eldEventTypeCodeInt)) return true;
      }

      if (isActiveEvent && isDutyStatusEvent && isOfDailyCert) return true;
      return false;
    });
    let eldEventsIdString = eldEvents.map(eldEvent => eldEvent.id);
    eldEventsIdString = eldEventsIdString.join(',');
    return eldEventsIdString;
  }

  getEditErrors(editChecksArr) {
    // editChecksArr: [hasEditComment, hasDutyStatus, isStartGreaterThanEnd, isEditWithinDailyCert, isNotDrivingViolation, isEditNotAheadOfCurrentDay]
    let errors = [];
    if (!editChecksArr[0]) errors[0] = t('Edit Reason is Required');
    if (!editChecksArr[1]) errors[1] = t('Please Select a Duty Status');
    if (!editChecksArr[2]) errors[2] = t('Start Time must be before End Time');
    if (!editChecksArr[3]) errors[3] = t('Selected Timeframe is outside the 24 Hour Period');
    if (!editChecksArr[4]) errors[4] = t('Cannot edit AutoGenerated Driving Time'); // COMMENT THIS OUT IF YOU WANT TO EDIT AUTOGENERATED
    if (!editChecksArr[5]) errors[3] = t('Cannot edit ahead of current Date/Time');

    errors = errors.filter(error => error !== (undefined || null));
    return errors;
  }

  async checkForErrors(graphRefreshExemption) {
    const promise = new Promise(async (resolve) => {
      const { eldDailyCertification, eldEvents, singleELDEventEdit, driver, scaleToDriverTimezone } = this.props;
      const { editInfo, interactionCounter, endTimeUTC, timezoneOffsetFromUTC } = this.state;
      let errors = [];
      const startTimeUTCMs = moment(eldDailyCertification.get('startTimeUTC')).tz(timezoneOffsetFromUTC).valueOf();
      const endTimeUTCMs = moment(endTimeUTC).tz(timezoneOffsetFromUTC).valueOf();
      let editStartMs = moment(editInfo.startDateTime).tz(timezoneOffsetFromUTC).valueOf();
      let editEndMs = moment(editInfo.endDateTime).tz(timezoneOffsetFromUTC).valueOf();
      const oneDayFromStartTimeMs = moment(eldDailyCertification.get('endTimeUTC')).tz(timezoneOffsetFromUTC).valueOf();
      // if the edit start/end time is at
      // midnight (of the next day) change it to 23:59.99999
      // (i.e. subtract one millisecond from the edit start/end time)
      if (editStartMs === oneDayFromStartTimeMs) {
        editStartMs = moment(editStartMs).tz(timezoneOffsetFromUTC).subtract(1, 'millisecond').valueOf();
      }
      if (editEndMs === oneDayFromStartTimeMs) {
        editEndMs = moment(editEndMs).tz(timezoneOffsetFromUTC).subtract(1, 'millisecond').valueOf();
      }
      const currentDriverTime = moment().tz(timezoneOffsetFromUTC);
      const hasEditComment = document.getElementById('editNote') && !!document.getElementById('editNote').value && (document.getElementById('editNote').value.trim()).length > 0;
      const hasDutyStatus = (editInfo.eldEventTypeCodeInt !== undefined);
      let isStartGreaterThanEnd = editStartMs < editEndMs;
      if (singleELDEventEdit) isStartGreaterThanEnd = true;
      const isEditNotAheadOfCurrentDay = !((editStartMs > currentDriverTime.valueOf()) || (editEndMs > currentDriverTime.valueOf()));
      const isEditWithinDailyCert = (editStartMs >= startTimeUTCMs) && (editStartMs <= endTimeUTCMs) && (editEndMs >= startTimeUTCMs) && (editEndMs <= endTimeUTCMs);

      // aobrdEnabled check for entire day instead of previous (where only check the edit interval)
      const aobrdEnabled = ELD.isAOBRDEnabledByCount(eldEvents);
      const isWithinAutogeneratedBypassDate = await ELD.isWithinAutogeneratedBypassDate(driver);

      // Bypass isDrivingViolation if isOverrideELDEvents is true (overrideeldevents)
      let isDrivingViolation = this.props.isOverrideELDEvents ? false : ELD.isDrivingEditViolation(eldEvents, editInfo.startDateTime, editInfo.endDateTime);

      const isELDDrivingViolation = !(!aobrdEnabled && isDrivingViolation && !isWithinAutogeneratedBypassDate);
      if (interactionCounter > 0) {
        errors = this.getEditErrors([hasEditComment, hasDutyStatus, isStartGreaterThanEnd, isEditWithinDailyCert, isELDDrivingViolation, isEditNotAheadOfCurrentDay]);
      }

      // indicates user cannot add another edit or request one. also disables graph refresh unless graphRefreshExemption
      // in which case it falls to another check
      const disableSubmitRequest = (errors.length > 0 || interactionCounter === 0);

      // indicates that the preview should still update even though there are errors, but still disallow requesting overall
      const disableGraphRefresh = !(hasDutyStatus && isStartGreaterThanEnd && isEditWithinDailyCert && isELDDrivingViolation && isEditNotAheadOfCurrentDay);

      this.setState({ ...this.state, errors, disableSubmitRequest, disableGraphRefresh }, () => {
        if (graphRefreshExemption) {
          resolve(disableGraphRefresh);
        } else {
          resolve(disableSubmitRequest);
        }
      });
    });
    return promise;
  }

  handleClose(updateParentState) {
    this.props.handleClose(updateParentState);
  }

  setDOMTextValues(customEditInfo) {
    // fill out edit info with dom text values to avoid heavy ui-rerendering
    const promise = new Promise(resolve => {
      const editInfo = customEditInfo || { ...this.state.editInfo };
      editInfo.editNote = document.getElementById('editNote').value;
      editInfo.eventsNote = document.getElementById('eventsNote').value;
      // editInfo.startCustomLocation = document.getElementById('startCustomLocation').value;
      // editInfo.endCustomLocation = document.getElementById('endCustomLocation') ? document.getElementById('endCustomLocation').value : '';

      if (customEditInfo) {
        resolve(editInfo);
      } else {
        this.setState({ ...this.state, editInfo }, () => resolve(editInfo));
      }
    });
    return promise;
  }

  resetDOMTextValues(editInfo = {}) {
    // reset the dom text fields we only wish to reset
    document.getElementById('eventsNote').value = editInfo.eventsNote || '';
    // document.getElementById('startCustomLocation').value = editInfo.startCustomLocation || '';
    // if (document.getElementById('endCustomLocation')) {
    //   document.getElementById('endCustomLocation').value = editInfo.endCustomLocation || '';
    // }
  }

  refreshGraphPreview(skipCreateRequest) {
    const promise = new Promise((resolve, reject) => {
      // when it gets to this point we should have a legit edit request object
      this.setDOMTextValues(this.state.editInfo).then(
        currentEditRequest => {
          const editRequests = this.state.editRequests.concat(currentEditRequest);
          const eldEventsIdString = this.getELDEventsIdString(); // derived from filtered props.eldEvents

          this.setState({ ...this.state, changesProcessing: true }, () => {
            ELD.requestELDEdits(this.props.driver, editRequests, this.state.defaultELDEventTypeCodeInt, eldEventsIdString, true).then(
              eldEditResponse => {
                const newState = { ...this.state, changesProcessing: false, eldEditResponse };
                newState.eldEventsForPreview.splice(0);
                newState.eldEventsForPreview = [].concat(this.props.eldEvents);

                // remove those events which are to be inactive
                const inactiveEventIds = eldEditResponse.eldEventsToBeInactive.map(event => event.id);

                let i = newState.eldEventsForPreview.length - 1;
                while (i >= 0) {
                  if (inactiveEventIds.indexOf(newState.eldEventsForPreview[i].id) !== -1) {
                    newState.eldEventsForPreview.splice(i, 1);
                  }
                  i--;
                }

                // append those events to be requested
                const requestedELDEvents = eldEditResponse.requestedELDEvents.map(event => {
                  const _event = event;
                  _event.eldEventRecordStatusInt = 1; // fake active
                  return Helpers.createTempParseObject('ELDEvent', _event);
                });
                newState.eldEventsForPreview.push.apply(newState.eldEventsForPreview, requestedELDEvents);

                this.setState(newState, () => {
                  resolve(eldEditResponse);
                });
              }
            );
          });
        }
      );
    });
    return promise;
  }

  getRecentVehicleLocationInfo(eldEventDateTime, timezoneOffsetFromUTC) {
    // get the most recent vehicle location prior to eldEventDateTime and return the strings and the breakdown
    const { singleELDEventEdit } = this.props;
    const { eldEventsForPreview } = this.state;

    const _eldEventsForPreview = ELD.sortELDEvents([].concat(eldEventsForPreview), 1);
    let mostRecentVehicleLocation = undefined;

    // the return value
    const result = {
      breakdown: getLocationDescriptionBreakdown(''), // by default return the default vehiclelocation breakdown
      startCustomLocation: undefined,
      endCustomLocation: undefined,
    }

    if (singleELDEventEdit) {
      // if single event edit, just get the location off the event
      const vehicleLocation = singleELDEventEdit.get('vehicleLocation');
      if (vehicleLocation) {
        mostRecentVehicleLocation = vehicleLocation;
      }
    } else if (_eldEventsForPreview.length) {
      const _eldEventDateTime = moment(eldEventDateTime).tz(timezoneOffsetFromUTC);
      const eldEventDateTimeMillis = _eldEventDateTime.valueOf();

      for (let i = 0; i < _eldEventsForPreview.length; i++) {
        const _eldEvent = _eldEventsForPreview[i];
        const _eldEventDateTimeMillis = moment(_eldEvent.get('eventDateTime')).valueOf();
        const vehicleLocation = _eldEvent.get('vehicleLocation');

        if (vehicleLocation && (_eldEventDateTimeMillis <= eldEventDateTimeMillis)) {
          mostRecentVehicleLocation = vehicleLocation;
        }
      }
    }


    // finally, if we have a most recent location prior to eldEventDateTime, get the breakdown if it is of valid format
    if (mostRecentVehicleLocation) {
      const countryCode = (mostRecentVehicleLocation.get('countryCode') || 'ca').toUpperCase();
      const locationDescription = mostRecentVehicleLocation.get(CountryCodeLocationDescriptionTypes[countryCode]);
      const isValid = isValidLocationDescription(locationDescription);
      if (isValid) {
        result.breakdown = getLocationDescriptionBreakdown(locationDescription);
        result.startCustomLocation = locationDescription;
        result.endCustomLocation = locationDescription;
      }
    }

    return result;
  }

  handleChange(e, timeType) {
    // handles text and date/time chooser changes
    const newState = { ...this.state };
    newState.interactionCounter++;
    newState.disableGraphRedraw = false;

    if (!timeType) {
      const { target } = e;
      const { editInfo } = newState;

      if (target.id === 'eventsNote' || target.id === 'editNote' || target.id.includes('CustomLocation')) newState.disableGraphRedraw = true;

      editInfo[target.id] = target.value;
    } else {
      const timezoneOffsetFromUTC = this.state.timezoneOffsetFromUTC;
      const dateTime = moment(e);
      dateTime.second(0);
      dateTime.millisecond(0);

      if (timeType === 'startDate') {
        newState.editInfo.startDateTime = moment(newState.editInfo.startDateTime);
        newState.editInfo.startDateTime.date(dateTime.date());
        newState.editInfo.startDateTime.month(dateTime.month());
        newState.editInfo.startDateTime.year(dateTime.year());
        newState.editInfo.startDateTime = moment.tz(newState.editInfo.startDateTime, 'YYYY-MM-DDTHH:mm:ss', timezoneOffsetFromUTC).toDate();
      } else if (timeType === 'startTime') {
        newState.editInfo.startDateTime = moment(newState.editInfo.startDateTime);
        newState.editInfo.startDateTime.date(dateTime.date());
        newState.editInfo.startDateTime.month(dateTime.month());
        newState.editInfo.startDateTime.year(dateTime.year());
        newState.editInfo.startDateTime.hour(dateTime.hour());
        newState.editInfo.startDateTime.minute(dateTime.minute());
        newState.editInfo.startDateTime.second(dateTime.second());
        newState.editInfo.startDateTime.millisecond(dateTime.millisecond());
        newState.editInfo.startDateTime = moment.tz(newState.editInfo.startDateTime, 'YYYY-MM-DDTHH:mm:ss', timezoneOffsetFromUTC).toDate();
      } else if (timeType === 'endDate') {
        newState.editInfo.endDateTime = moment(newState.editInfo.endDateTime);
        newState.editInfo.endDateTime.date(dateTime.date());
        newState.editInfo.endDateTime.month(dateTime.month());
        newState.editInfo.endDateTime.year(dateTime.year());
        newState.editInfo.endDateTime = moment.tz(newState.editInfo.endDateTime, 'YYYY-MM-DDTHH:mm:ss', timezoneOffsetFromUTC).toDate();
      } else if (timeType === 'endTime') {
        newState.editInfo.endDateTime = moment(newState.editInfo.endDateTime);
        newState.editInfo.endDateTime.date(dateTime.date());
        newState.editInfo.endDateTime.month(dateTime.month());
        newState.editInfo.endDateTime.year(dateTime.year());
        newState.editInfo.endDateTime.hour(dateTime.hour());
        newState.editInfo.endDateTime.minute(dateTime.minute());
        if (dateTime.minute() === 59) {
          newState.editInfo.endDateTime.second(59);
          newState.editInfo.endDateTime.millisecond(59);
        } else {
          newState.editInfo.endDateTime.second(dateTime.second());
          newState.editInfo.endDateTime.millisecond(dateTime.millisecond());
        }
        newState.editInfo.endDateTime = moment.tz(newState.editInfo.endDateTime, 'YYYY-MM-DDTHH:mm:ss', timezoneOffsetFromUTC).toDate();
      }

      if (!newState.customLocationBuilder.isManuallyEntered) {
        // if the user did not manually enter a location, update to autofill the most recent location to the startTime
        const { breakdown, startCustomLocation, endCustomLocation } = this.getRecentVehicleLocationInfo(newState.editInfo.startDateTime, timezoneOffsetFromUTC);
        if (startCustomLocation) {
          // if there is a startcustomlocation that means that the location is valid because the breakdown went through
          newState.customLocationBuilder = {
            ...newState.customLocationBuilder,
            startCustomLocationObject: breakdown,
            endCustomLocationObject: breakdown,
          };
          newState.editInfo.startCustomLocation = startCustomLocation;
          newState.editInfo.endCustomLocation = endCustomLocation;
        }

        this.resetDOMTextValues(newState.editInfo);
      }
    }


    this.setState(newState, () => {
      this.checkForErrors(true).then(
        hasErrors => {
          if (!hasErrors && !this.state.disableGraphRedraw) {
            this.refreshGraphPreview();
          }
        }
      );
    });
  }

  toggleEntireDayEdit() {
    // determine what/whether or not current edit spans the entire day
    const { timezoneOffsetFromUTC } = this.state;
    const newState = { ...this.state };
    const isEntireDayEdit = !this.state.isEntireDayEdit;
    const startTimeUTC = moment(this.props.eldDailyCertification.get('startTimeUTC')).tz(timezoneOffsetFromUTC);
    newState.interactionCounter++;

    if (isEntireDayEdit) {
      newState.editInfo.startDateTime = startTimeUTC.toDate();
      newState.editInfo.endDateTime = moment(newState.endTimeUTC).toDate();
    } else {
      newState.editInfo.startDateTime = startTimeUTC.toDate();
      newState.editInfo.endDateTime = startTimeUTC.toDate();
    }

    newState.isEntireDayEdit = isEntireDayEdit;

    this.setState(newState, () => {
      this.checkForErrors(true).then(hasErrors => {
        if (!hasErrors) {
          if (!isEntireDayEdit) {
            this.refreshGraphPreview(true);
          } else {
            this.refreshGraphPreview();
          }
        }
      });
    });
  }

  setDutyStatus(eldEventTypeCodeInt) {
    const promise = new Promise(resolve => {
      const newState = { ...this.state };
      newState.interactionCounter++;
      newState.disableGraphRedraw = false;
      newState.editInfo = { ...this.state.editInfo };
      newState.editInfo.eldEventTypeCodeInt = eldEventTypeCodeInt;
      this.setState(newState, () => {
        this.checkForErrors(true).then(hasErrors => {
          if (!hasErrors) this.refreshGraphPreview(true);
        });
      });
    });
    return promise;
  }

  handleEditReason(e) {
    const { disableSubmitRequest } = this.state;
    const { singleELDEventEdit } = this.props;
    if (singleELDEventEdit) {
      const text = e.target.value && e.target.value.trim();
      if (text && text.length > 0) {
        this.checkForErrors().then(hasErrors => {
          if (!hasErrors) this.setState({ ...this.state, disableSubmitRequest: false });
          // if (disableSubmitRequest) this.setState({ ...this.state, disableSubmitRequest: false });
        });
      } else {
        this.setState({ ...this.state, disableSubmitRequest: true });
      }
    }
  }

  addToEditRequests() {
    const { driver, eldDailyCertification } = this.props;
    const { timezoneOffsetFromUTC, editInfo, editRequests } = this.state;
    const startTimeUTC = moment(eldDailyCertification.get('startTimeUTC')).tz(timezoneOffsetFromUTC);
    this.setDOMTextValues().then(
      currentEditRequest => {
        this.setState({
          ...this.state,
          editInfo: {
            eldEventTypeCodeInt: undefined,
            startDateTime: startTimeUTC.toDate(),
            endDateTime: startTimeUTC.toDate(),
            editNote: editInfo.editNote,
            eventsNote: '',
            localId: (new Date()).getTime() + driver.id,
            driverUserId: driver.id,
            eldDailyCertificationStartTimeUTC: startTimeUTC.toDate(),
            startCustomLocation: undefined,
            endCustomLocation: undefined,
            vehicleObjectId: editInfo.vehicleObjectId,
            vehicleUnitId: editInfo.vehicleUnitId,
            vehiclePlate: editInfo.vehiclePlate,
            vehicleVIN: editInfo.vehicleVIN,
          },
          customLocationBuilder: {
            startCustomLocationObject: {
              distance: '0',
              heading: {
                type: LocationDirectionTypes.N,
              }
            },
            endCustomLocationObject: {
              distance: '0',
              heading: {
                type: LocationDirectionTypes.N,
              }
            },
            isManuallyEntered: false,
          },
          editRequests: editRequests.concat(currentEditRequest),
          isEntireDayEdit: false,
          editRequestAdded: true, // alert user they can add another edit now
          interactionCounter: 0,
          disableSubmitRequest: true,
        }, () => {
          this.resetDOMTextValues();
        });
      }
    );

    // scroll to the top of the dialog
    document.querySelector('.dialog-eld-edit').scrollTop = 0;
  }

  removeFromEditRequests(localId) {
    // remove edit request from editRequests
    const { editRequests } = this.state;
    for (let i = 0; i < editRequests.length; i++) {
      const editRequest = { ...editRequests[i] };
      if (editRequest.localId === localId) {
        editRequests.splice(i, 1);
        return this.setState({
          ...this.state,
          editRequests,
          customLocationBuilder: {
            startCustomLocationObject: getLocationDescriptionBreakdown(''),
            endCustomLocationObject: getLocationDescriptionBreakdown(''),
          },
        }, () => {
          this.resetDOMTextValues({});
          this.refreshGraphPreview(true);
        });
      }
    }
  }

  submitEditRequests() {
    const promise = new Promise((resolve, reject) => {
      this.setDOMTextValues().then(
        currentEditRequest => {
          this.setState({ ...this.state, changesProcessing: true, confirmRequestModal: { show: false }, interactionCounter: 0 }, async () => {
            const { props, state } = this;
            const { startDateTime, endDateTime } = state.editInfo;

            const editRequests = this.state.editRequests.concat(currentEditRequest);
            let eldEventsIdString = this.getELDEventsIdString(); // derived from filtered props.eldEvents

            // Logic that handles editing ignition events inside ADGT interval
            // First, do a check to see if support is editing ADGT (using isWithinAutogeneratedBypassDate)
            // Next, we check to see if the edits are made within an interval of time that contains some sort of ADGT
            // If both of these are true, then we need to figure out which ignition events lie inside of the ADGT interval
            const isWithinAutogeneratedBypassDate = await ELD.isWithinAutogeneratedBypassDate(props.driver);
            const isEditingAGDTEvents = ELD.isDrivingEditViolation(props.eldEvents, startDateTime, endDateTime);

            if (isWithinAutogeneratedBypassDate && isEditingAGDTEvents) {
              // Now, figure out whether or not there are any ignition events that occur between the ADGT intervals
              const agdtIntervals = ELD.getAGDTIntervals(props.eldEvents, startDateTime, endDateTime, true);

              // Filter out for any ignition events
              const ignitionELDEvents = props.eldEvents.filter((eldEvent) => [61, 62, 63, 64].includes(getAttribute(eldEvent, 'eldEventTypeCodeInt')));

              // Filtered ignition events to only the portion of the edited areas
              const ignitionELDEventsFiltered = ignitionELDEvents.filter((eldEvent) => {
                const startDateTimeMs = moment(startDateTime).valueOf();
                const endDateTimeMs = moment(endDateTime).valueOf();
                const eldEventDateTimeMs = moment(getAttribute(eldEvent, 'eventDateTime')).valueOf();

                return eldEventDateTimeMs >= startDateTimeMs && eldEventDateTimeMs <= endDateTimeMs;
              });

              ignitionELDEventsFiltered.map((eldEvent) => {
                const eldEventDateTimeMs = moment(getAttribute(eldEvent, 'eventDateTime')).valueOf();

                for (let i = 0; i < agdtIntervals.length; i++) {
                  const agdtStartIntervalMs = agdtIntervals[i][0];
                  const agdtEndIntervalMs = agdtIntervals[i][1];

                  if (eldEventDateTimeMs >= agdtStartIntervalMs && eldEventDateTimeMs <= agdtEndIntervalMs) {
                    if (eldEventsIdString) {
                      eldEventsIdString += `,${eldEvent.id}`;
                    } else {
                      eldEventsIdString += eldEvent.id;
                    }
                    break;
                  }
                }
              });
            }

            let editType = ELDEditType.MAJOR;
            let singleEdit = this.props.singleELDEventEdit;
            if (singleEdit) {
              for (let i = 0; i < editRequests.length; i++) {
                let editRequest = editRequests[i];
                // Note: only checking eldEventTypeCodeInt because in singleEdit mode the time cannot be changed
                if (singleEdit.get('eldEventTypeCodeInt') !== editRequest.eldEventTypeCodeInt) {
                  editType = ELDEditType.MAJOR;
                  break;
                } else if (singleEdit.eventsNote !== editRequest.eventsNote) {
                  editType = ELDEditType.ADD_NOTE;
                }
              }
            }

            // isOverrideELDEvents will go through the normal flow of edits where the driver has to approve of them
            // isOverrideELDEdit will bypass driver approval
            if (this.props.isOverrideELDEdit) {
              editType = ELDEditType.OVERRIDE_MAJOR;
            }

            editRequests.map(editRequest => {
              editRequest.editNote = editRequest.editNote.trim();
              if (editRequest.startCustomLocation && isValidLocationDescription(editRequest.startCustomLocation)) {
                editRequest.startCustomLocation = editRequest.startCustomLocation.trim();
              } else if (editRequest.startCustomLocation) {
                editRequest.startCustomLocation = undefined;
              }
              if (editRequest.endCustomLocation && isValidLocationDescription(editRequest.endCustomLocation)) {
                editRequest.endCustomLocation = editRequest.endCustomLocation.trim();
              } else if (editRequest.endCustomLocation) {
                editRequest.endCustomLocation = undefined;
              }
            });
            ELD.requestELDEdits(
              this.props.driver,
              editRequests,
              this.state.defaultELDEventTypeCodeInt,
              eldEventsIdString,
              undefined,
              editType,
              window.location.host.indexOf('app-support') !== -1,
            ).then(
              eldEdit => {
                this.setState({ ...this.state, changesProcessing: false }, () => {
                  this.props.handleClose(true);
                });
              }
            );
          });
        }
      );
    });
    return promise;
  }

  toggleWalkthroughState(walkthroughStateAttribute) {
    const newState = { ...this.state };
    newState.walkthroughState = { ...this.state.walkthroughState };
    newState.walkthroughState[walkthroughStateAttribute] = !this.state.walkthroughState[walkthroughStateAttribute];
    this.setState(newState);
  }

  updateCustomLocation(value, attribute, type) {
    // type 0: startCustomLocation, type 1: endCustomLocation
    const newState = { ...this.state };
    newState.editInfo = { ...newState.editInfo };
    newState.customLocationBuilder = { ...newState.customLocationBuilder };
    const startCustomLocationObject = newState.customLocationBuilder.startCustomLocationObject = {
      ...newState.customLocationBuilder.startCustomLocationObject,
      heading: {
        ...newState.customLocationBuilder.startCustomLocationObject.heading,
      },
      stateProvince: {
        ...newState.customLocationBuilder.startCustomLocationObject.stateProvince,
      }
    };
    const endCustomLocationObject = newState.customLocationBuilder.endCustomLocationObject = {
      ...newState.customLocationBuilder.endCustomLocationObject,
      heading: {
        ...newState.customLocationBuilder.endCustomLocationObject.heading,
      },
      stateProvince: {
        ...newState.customLocationBuilder.endCustomLocationObject.stateProvince,
      }
    };

    if (!type) {
      if (attribute === 'distance') {
        newState.customLocationBuilder.startCustomLocationObject[attribute] = (value || '0').toString();
      } else if (attribute === 'heading') {
        newState.customLocationBuilder.startCustomLocationObject[attribute].value = (value || '').toString();
      } else if (attribute.toLowerCase() === 'distanceUnit') {
        newState.customLocationBuilder.startCustomLocationObject[attribute] = (value || '').toString();
      } else if (attribute.toLowerCase() === 'stateprovince') {
        newState.customLocationBuilder.startCustomLocationObject[attribute].code = (value || '').toString();
      } else {
        newState.customLocationBuilder.startCustomLocationObject[attribute] = (value || '').toString();
      }

      newState.editInfo.startCustomLocation = getVehicleLocationDescription(
        startCustomLocationObject.city,
        startCustomLocationObject.stateProvince && startCustomLocationObject.stateProvince.code,
        startCustomLocationObject.distance,
        startCustomLocationObject.distanceUnit,
        startCustomLocationObject.heading && startCustomLocationObject.heading.value
      );
    } else {
      if (attribute === 'distance') {
        newState.customLocationBuilder.endCustomLocationObject[attribute] = (value || '0').toString();
      } else if (attribute === 'heading') {
        newState.customLocationBuilder.endCustomLocationObject[attribute].value = (value || '').toString();
      } else if (attribute.toLowerCase() === 'distanceUnit') {
        newState.customLocationBuilder.endCustomLocationObject[attribute] = (value || '').toString();
      } else if (attribute.toLowerCase() === 'stateprovince') {
        newState.customLocationBuilder.endCustomLocationObject[attribute].code = (value || '').toString();
      } else {
        newState.customLocationBuilder.endCustomLocationObject[attribute] = (value || '').toString();
      }

      newState.editInfo.endCustomLocation = getVehicleLocationDescription(
        endCustomLocationObject.city,
        endCustomLocationObject.stateProvince && endCustomLocationObject.stateProvince.code,
        endCustomLocationObject.distance,
        endCustomLocationObject.distanceUnit,
        endCustomLocationObject.heading && endCustomLocationObject.heading.value
      );
    }

    newState.customLocationBuilder.isManuallyEntered = true;
    this.setState(newState);
  }

  updateVehicleInformation() {
    const { state } = this;
    const { vehicle } = state;

    if (!vehicle) return;

    // These 4 attributes are needed to fill in the eld events when overridehoslogs is enabled
    const vehicleObjectId = getAttribute(vehicle, 'objectId');
    const vehicleUnitId = getAttribute(vehicle, 'unitId');
    const vehiclePlate = getAttribute(vehicle, 'plate');
    const vehicleVIN = getAttribute(vehicle, 'vin');

    // Go through each of the edit requests, and append the new values
    // Create a deep copy and set the state with the updated values
    const _editInfo = structuredClone(state.editInfo);
    const _editRequests = structuredClone(state.editRequests);

    _editInfo.vehicleObjectId = vehicleObjectId;
    _editInfo.vehicleUnitId = vehicleUnitId;
    _editInfo.vehiclePlate = vehiclePlate;
    _editInfo.vehicleVIN = vehicleVIN;

    _editRequests.forEach((editRequest) => {
      editRequest.vehicleObjectId = vehicleObjectId;
      editRequest.vehicleUnitId = vehicleUnitId;
      editRequest.vehiclePlate = vehiclePlate;
      editRequest.vehicleVIN = vehicleVIN;
    });

    this.setState({ ...this.state, editInfo: _editInfo, editRequests: _editRequests });
  }

  render() {
    const { props, state } = this;
    const { editInfo, eldEventsForPreview, editRequests, isEntireDayEdit, disableGraphRedraw, disableSubmitRequest, changesProcessing, confirmRequestModal, endTimeUTC, editRequestAdded, eldEventTypeCodeInts, interactionCounter, errors, showViolations, timezoneOffsetFromUTC, walkthroughState } = this.state;
    const { show, driver, company, eldDailyCertification, eldEvents, associatedELDEvents, singleELDEventEdit, outlineEdits, outlineAutoGeneratedDrivingTimes, scaleToDriverTimezone, isOverrideELDEdit, isOverrideELDEvents } = this.props;
    // console.log('********');
    // console.log(editInfo);
    // console.log(eldDailyCertification && (moment(eldDailyCertification.get('startTimeUTC')).tz(timezoneOffsetFromUTC).format('YYYY-MM-DD HH:mm:ss')));
    // console.log(editInfo && (moment(editInfo.startDateTime).tz(timezoneOffsetFromUTC).format('YYYY-MM-DD HH:mm:ss')));
    // console.log(editInfo && (moment(editInfo.endDateTime).tz(timezoneOffsetFromUTC).format('YYYY-MM-DD HH:mm:ss')));

    const callingUser = getCurrentUser();
    const userSpecialPermission = callingUser && callingUser.get('userSpecialPermission');
    const disableViolations = userSpecialPermission && userSpecialPermission.get('disableViolations');

    const showHOSGraphInHeader = window.innerHeight > 765;
console.log(state);
    // determine if valid custom location
    const isValidStartLocationDescription = isValidLocationDescription(state.editInfo.startCustomLocation);
    const isValidEndLocationDescription = isValidLocationDescription(state.editInfo.endCustomLocation);

    // distance unit dropdown items
    const distanceUnitItems = [LengthUnitTypes.KM, LengthUnitTypes.MI].map(unit => {
      return {
        key: unit,
        label: unit,
        value: unit,
      }
    });
    const selectedStartLocationUnit = distanceUnitItems.filter(item => {
      return item.value.toLowerCase() === (state.customLocationBuilder.startCustomLocationObject.distanceUnit || '').toLowerCase();
    })[0] || distanceUnitItems[0];

    const selectedEndLocationUnit = distanceUnitItems.filter(item => {
      return item.value.toLowerCase() === (state.customLocationBuilder.endCustomLocationObject.distanceUnit || '').toLowerCase();
    })[0] || distanceUnitItems[0];


    // heading dropdown items
    const headingItems = Object.keys(LocationDirectionTypes).map(heading => {
      return {
        key: heading,
        label: LocationDirectionTypeDescription[heading],
        value: heading,
      }
    });
    const selectedStartLocationHeading = headingItems.filter(item => {
      const customStartLocationObjectHeading = state.customLocationBuilder.startCustomLocationObject.heading || {};
      return item.value.toLowerCase() === (customStartLocationObjectHeading.value || '').toLowerCase();
    })[0] || headingItems[0];

    const selectedEndLocationHeading = headingItems.filter(item => {
      const customEndLocationObjectHeading = state.customLocationBuilder.endCustomLocationObject.heading || {};
      return item.value.toLowerCase() === (customEndLocationObjectHeading.value || '').toLowerCase();
    })[0] || headingItems[0];

    // stateprovince dropdown items
    const stateProvinceItems = Object.keys(StateProvince).map(stateProvinceKey => {
      const stateProvinceObject = StateProvince[stateProvinceKey];
      return {
        key: stateProvinceObject.code,
        label: stateProvinceObject.name,
        value: stateProvinceObject.code,
      }
    });
    const selectedStartLocationStateProvince = stateProvinceItems.filter(item => {
      const customStartLocationObjectStateProvince = state.customLocationBuilder.startCustomLocationObject.stateProvince || {};
      return item.value.toLowerCase() === (customStartLocationObjectStateProvince.code || '').toLowerCase();
    })[0];

    const selectedEndLocationStateProvince = stateProvinceItems.filter(item => {
      const customEndLocationObjectStateProvince = state.customLocationBuilder.endCustomLocationObject.stateProvince || {};
      return item.value.toLowerCase() === (customEndLocationObjectStateProvince.code || '').toLowerCase();
    })[0];


    // ui rendering
    const addAnotherEditButton = (
      <RaisedButton
        key="addAnotherEdit"
        label={t('Add Another Edit')}
        primary
        default
        onClick={this.addToEditRequests}
        style={{ float: 'left' }}
        disabled={disableSubmitRequest}
      />
    );
    const cancelButton = (
      <FlatButton
        key="cancel"
        label={t('Cancel')}
        default
        onClick={() => this.handleClose(false)}
      />
    );
    const requestEditButton = (
      <RaisedButton
        key="requestInsert"
        label={t('Request Change(s)')}
        secondary
        onClick={() => this.setState({ ...this.state, confirmRequestModal: { show: true } })}
        style={{ marginLeft: '.5em' }}
        disabled={disableSubmitRequest}
      />
    );

    let actions = [addAnotherEditButton, cancelButton, requestEditButton];

    if (singleELDEventEdit) actions = [cancelButton, requestEditButton];

    if (changesProcessing) {
      actions = [<CircularProgress />];
    }

    const editRequestsList = this.state.editRequests.map((request, index) => (
      <div className={styles.editSummary} key={index.toString()}>
        <div className={styles.typeCode}>{eldEventTypeCodeInts[request.eldEventTypeCodeInt]}</div>
        <div className={styles.infoString}>
          ({moment(request.startDateTime).format('HH:mm')} - {moment(request.endDateTime).format('HH:mm')})
        </div>
        <a className={styles.buttonRemove} onClick={() => this.removeFromEditRequests(request.localId)}>REMOVE</a>
      </div>
    ));

    let dialogTitleDate = moment(eldDailyCertification.get('startTimeUTC')).tz(timezoneOffsetFromUTC).format('MMM D, YYYY');
    if (singleELDEventEdit) dialogTitleDate = moment(singleELDEventEdit.get('eventDateTime')).tz(timezoneOffsetFromUTC).format('HH:mm (MMM D, YYYY)');


    const hosGraph = (
      <React.Fragment>
        <div className={styles.eldEditModalChartContainer} style={{ marginTop: '2em', fontSize: showHOSGraphInHeader ? '.65em' : 'initial' }}>
          <div className={`${styles.legend} translate-me`}>
            <span className={styles.legendItem} style={{ float: 'left', marginRight: '-10em' }}>Edit Preview</span>
            <span className={styles.legendItem}><span className={styles.pucmv}>&mdash;</span>Personal Use CMV</span>
            <span className={styles.legendItem}><span className={styles.ym}>&mdash;</span>Yard Moves</span>
            <span className={styles.legendItem}><span className={styles.agdt}>&mdash;</span>AutoGenerated Driving Time</span>
            <span className={styles.legendItem}><span className={styles.edit}>&mdash;</span>Your Edit</span>
          </div>
          <HOSGraph
            eldDailyCertification={eldDailyCertification}
            eldEvents={eldEventsForPreview}
            associatedELDEvents={associatedELDEvents}
            driver={driver}
            scaleToDriverTimezone={scaleToDriverTimezone}
            disableRedraw={disableGraphRedraw}
            outlineEdits={outlineEdits}
            outlineAutoGeneratedDrivingTimes={outlineAutoGeneratedDrivingTimes}
            shortDutyStatus
          />
        </div>

        <div style={{ marginTop: '0em', fontSize: showHOSGraphInHeader ? '.65em' : 'initial' }}>
          <div className='flex justify-content-between align-items-center gap-3'>
            <div className='flex-grow-1'>
              <div style={{ marginBottom: showHOSGraphInHeader ? '.5em' : '2em', fontSize: '.75rem' }} className="translate-me">Edit Reason (For FMCSA Compliance) <span className={styles.required}>*</span></div>
              <textarea
                className="textarea"
                onChange={(e) => this.handleEditReason(e)}
                id="editNote"
                placeholder={t('Enter Reason for the Edit...')}
                style={{ overflow: 'hidden', height: '2.5em', marginTop: '-3em', borderLeft: '0em', borderRight: '0em', borderTop: '0em', fontSize: '0.9rem' }}
              />
            </div>

            {isOverrideELDEdit && (
              <VehicleAutocompleteInput
                panelClassName={styles.vehicleAutocompletePanel}
                onSelectVehicle={(vehicle) => {
                  this.setState({ ...this.state, vehicle: vehicle }, () => {
                    this.updateVehicleInformation(vehicle);
                  });
                }}
              />
            )}
          </div>

        </div>
      </React.Fragment>
    );

    return (
      <div>
        <Dialog
          className={styles.eldEditModal}
          bodyClassName="dialog-eld-edit"
          titleStyle={{ paddingBottom: '0em' }}
          title={
            <div>
              <div className="translate-me">Edit Log for {dialogTitleDate}</div>
              {showHOSGraphInHeader && hosGraph}
            </div>
          }
          actions={actions}
          open={show}
          onRequestClose={() => this.handleClose(false)}
          autoScrollBodyContent
          contentStyle={{ position: 'relative', maxWidth: '80%' }}
          modal
        >
          {!singleELDEventEdit && (company && !company.get('disableWalkthrough')) &&
            <WalkthroughSelectTip
              handleToggle={() => this.toggleWalkthroughState('addAnotherEdit')}
              expand={walkthroughState.addAnotherEdit}
              showBoxTop
              toolTipStyle={{ top: '-11.5em', fontSize: '1.2em' }}
              style={{ bottom: '0em', left: '5.5em' }}
            >
              <div className="translate-me">
                Click here to make another edit on top of ones you're currently making. Simply select <b>Add Another Edit</b> and enter your new Duty Status information
                <br /><br />
                To disable these tips, go to <b>Walkthrough Tips</b> under your <a onClick={() => history.push('/settings')} style={{ cursor: 'pointer' }}>settings</a>
              </div>
            </WalkthroughSelectTip>
          }

          <div style={{ position: 'absolute', top: '1.8em', right: '1.5em' }}>
            <ELDEditHelpDialog />
          </div>

          {!showHOSGraphInHeader && hosGraph}

          {errors.length > 0 &&
            <div style={{ marginTop: '1em', marginBottom: '1em' }}>
              <span>
                <ul style={{ paddingLeft: '1.2em' }}>
                  {
                    errors.map((error, index) => <li key={index} className="textRedMaple">{error}</li>)
                  }
                </ul>
              </span>
            </div>
          }
          {/** TODO: DatePicker and TimePicker should be localized if not too hard */}
          {!singleELDEventEdit &&
            <div>
              <div style={{ display: 'inline-block', marginBottom: '2em' }}>
                <div className="translate-me">Start Time <span className={`${styles.required} notranslate`}>*</span></div>
                <DatePicker
                  id="startDate"
                  hintText={moment(eldDailyCertification.get('startTimeUTC')).tz(timezoneOffsetFromUTC).format('MMM D, YYYY')}
                  value={editInfo.startDateTime}
                  onChange={(e, date) => this.handleChange(date, 'startDate')}
                  formatDate={(date) => moment(date).tz(timezoneOffsetFromUTC).format('MMM D, YYYY')}
                  style={{ display: 'inline-block' }}
                  textFieldStyle={{ width: '8em' }}
                  mode="landscape"
                  disabled={changesProcessing || isEntireDayEdit}
                />
                <TimePicker
                  showSecond={false}
                  format="h:mm a"
                  value={moment(editInfo.startDateTime).tz(timezoneOffsetFromUTC)}
                  onChange={(time) => this.handleChange(time ? time.toDate() : editInfo.startDateTime, 'startTime')}
                />
              </div>

              <div style={{ display: 'inline-block', padding: '3em' }} />

              <div style={{ display: 'inline-block' }}>
                <div className="translate-me">End Time <span className={`${styles.required} notranslate`}>*</span></div>
                <DatePicker
                  id="endDate"
                  hintText={moment(eldDailyCertification.get('startTimeUTC')).tz(timezoneOffsetFromUTC).format('MMM D, YYYY')}
                  value={editInfo.endDateTime}
                  onChange={(e, date) => this.handleChange(date, 'endDate')}
                  formatDate={(date) => moment(date).tz(timezoneOffsetFromUTC).format('MMM D, YYYY')}
                  style={{ display: 'inline-block' }}
                  textFieldStyle={{ width: '8em' }}
                  mode="landscape"
                  disabled={changesProcessing || isEntireDayEdit}
                />
                <TimePicker
                  showSecond={false}
                  format="h:mm a"
                  value={moment(editInfo.endDateTime).tz(timezoneOffsetFromUTC)}
                  onChange={(time) => this.handleChange(time ? time.toDate() : editInfo.endDateTime, 'endTime')}
                />
              </div>

              <Checkbox
                label="Apply this edit for the entire day (Midnight to Midnight)"
                onCheck={this.toggleEntireDayEdit}
                checked={isEntireDayEdit}
                disabled={changesProcessing}
                style={{ marginTop: '-2.2em', marginBottom: '1em', width: '28em', color: '#333333', fontSize: '.9em', fontWeight: '400' }}
                className="translate-me"
              />
            </div>
          }

          {!singleELDEventEdit ?
            <React.Fragment>
              <div>
                <div className="mt-2 translate-me" style={{ fontSize: '.9em' }}>Start Location</div>
                <MDBRow>
                  <MDBCol className="translate-me">
                    <div className="d-inline-block align-middle mr-1">
                      <MDBInput
                        className={styles.cityInput}
                        label="City"
                        labelClass="active"
                        value={state.customLocationBuilder.startCustomLocationObject.city || ''}
                        getValue={(value) => this.updateCustomLocation(value, 'city', 0)}
                      />
                    </div>
                    <div class="md-form d-inline-block align-middle mr-1">
                      <SBSelect
                        className={styles.stateProvinceSelector}
                        onClick={(e) => e.stopPropagation()}
                        items={stateProvinceItems}
                        selectedItems={selectedStartLocationStateProvince ? [selectedStartLocationStateProvince] : []}
                        getSelectedItems={(items) => { this.updateCustomLocation(items[0].value.toUpperCase(), 'stateProvince', 0) }}
                        showFilter
                      />
                      <label class="active" data-error data-success>State/Province</label>
                    </div>
                    <div className="d-inline-block align-middle mr-1">
                      <MDBInput
                        className={styles.unitInput}
                        type="number"
                        label="Distance"
                        labelClass="active"
                        value={parseInt(state.customLocationBuilder.startCustomLocationObject.distance || 0)}
                        getValue={(value) => this.updateCustomLocation(value, 'distance', 0)}
                      />
                    </div>
                    <div class="md-form d-inline-block align-middle mr-1">
                      <SBSelect
                        className={styles.unitSelector}
                        onClick={(e) => e.stopPropagation()}
                        items={distanceUnitItems}
                        selectedItems={[selectedStartLocationUnit]}
                        getSelectedItems={(items) => { this.updateCustomLocation(items[0].value.toUpperCase(), 'distanceUnit', 0) }}
                        showFilter
                      />
                      <label class="active" data-error data-success>Unit</label>
                    </div>
                    <div class="md-form d-inline-block align-middle">
                      <SBSelect
                        className={styles.headingSelector}
                        onClick={(e) => e.stopPropagation()}
                        items={headingItems}
                        selectedItems={[selectedStartLocationHeading]}
                        getSelectedItems={(items) => { this.updateCustomLocation(items[0].value.toUpperCase(), 'heading', 0) }}
                        showFilter
                      />
                      <label class="active" data-error data-success>Heading</label>
                    </div>
                  </MDBCol>
                </MDBRow>

                <div className="mt-2" style={{ fontSize: '.9em' }}>End Location</div>
                <MDBRow>
                  <MDBCol className="translate-me">
                    <div className="d-inline-block align-middle mr-1">
                      <MDBInput
                        className={styles.cityInput}
                        label="City"
                        labelClass="active"
                        value={state.customLocationBuilder.endCustomLocationObject.city || ''}
                        getValue={(value) => this.updateCustomLocation(value, 'city', 1)}
                      />
                    </div>
                    <div class="md-form d-inline-block align-middle mr-1">
                      <SBSelect
                        className={styles.stateProvinceSelector}
                        onClick={(e) => e.stopPropagation()}
                        items={stateProvinceItems}
                        selectedItems={selectedEndLocationStateProvince ? [selectedEndLocationStateProvince] : []}
                        getSelectedItems={(items) => { this.updateCustomLocation(items[0].value.toUpperCase(), 'stateProvince', 1) }}
                        showFilter
                      />
                      <label class="active" data-error data-success>State/Province</label>
                    </div>
                    <div className="d-inline-block align-middle mr-1">
                      <MDBInput
                        className={styles.unitInput}
                        type="number"
                        label="Distance"
                        labelClass="active"
                        value={parseInt(state.customLocationBuilder.endCustomLocationObject.distance || 0)}
                        getValue={(value) => this.updateCustomLocation(value, 'distance', 1)}
                      />
                    </div>
                    <div class="md-form d-inline-block align-middle mr-1">
                      <SBSelect
                        className={styles.unitSelector}
                        onClick={(e) => e.stopPropagation()}
                        items={distanceUnitItems}
                        selectedItems={[selectedEndLocationUnit]}
                        getSelectedItems={(items) => { this.updateCustomLocation(items[0].value.toUpperCase(), 'distanceUnit', 1) }}
                        showFilter
                      />
                      <label class="active" data-error data-success>Unit</label>
                    </div>
                    <div class="md-form d-inline-block align-middle">
                      <SBSelect
                        className={styles.headingSelector}
                        onClick={(e) => e.stopPropagation()}
                        items={headingItems}
                        selectedItems={[selectedEndLocationHeading]}
                        getSelectedItems={(items) => { this.updateCustomLocation(items[0].value.toUpperCase(), 'heading', 1) }}
                        showFilter
                      />
                      <label class="active" data-error data-success>Heading</label>
                    </div>
                  </MDBCol>
                </MDBRow>
              </div>
            </React.Fragment>
            :
            <div style={{ display: 'inline-block', marginTop: '2em', marginBottom: '3em' }}>
              <div className="mt-2 translate-me" style={{ fontSize: '.9em' }}>Custom Location Description</div>
              <MDBRow>
                <MDBCol className="translate-me">
                  <div className="d-inline-block align-middle mr-1">
                    <MDBInput
                      className={styles.cityInput}
                      label="City"
                      labelClass="active"
                      value={state.customLocationBuilder.startCustomLocationObject.city || ''}
                      getValue={(value) => this.updateCustomLocation(value, 'city', 0)}
                    />
                  </div>
                  <div class="md-form d-inline-block align-middle mr-1">
                    <SBSelect
                      className={styles.stateProvinceSelector}
                      onClick={(e) => e.stopPropagation()}
                      items={stateProvinceItems}
                      selectedItems={selectedStartLocationStateProvince ? [selectedStartLocationStateProvince] : []}
                      getSelectedItems={(items) => { this.updateCustomLocation(items[0].value.toUpperCase(), 'stateProvince', 0) }}
                      showFilter
                    />
                    <label class="active" data-error data-success>State/Province</label>
                  </div>
                  <div className="d-inline-block align-middle mr-1">
                    <MDBInput
                      className={styles.unitInput}
                      type="number"
                      label="Distance"
                      labelClass="active"
                      value={parseInt(state.customLocationBuilder.startCustomLocationObject.distance || 0)}
                      getValue={(value) => this.updateCustomLocation(value, 'distance', 0)}
                    />
                  </div>
                  <div class="md-form d-inline-block align-middle mr-1">
                    <SBSelect
                      className={styles.unitSelector}
                      onClick={(e) => e.stopPropagation()}
                      items={distanceUnitItems}
                      selectedItems={[selectedStartLocationUnit]}
                      getSelectedItems={(items) => { this.updateCustomLocation(items[0].value.toUpperCase(), 'distanceUnit', 0) }}
                      showFilter
                    />
                    <label class="active" data-error data-success>Unit</label>
                  </div>
                  <div class="md-form d-inline-block align-middle">
                    <SBSelect
                      className={styles.headingSelector}
                      onClick={(e) => e.stopPropagation()}
                      items={headingItems}
                      selectedItems={[selectedStartLocationHeading]}
                      getSelectedItems={(items) => { this.updateCustomLocation(items[0].value.toUpperCase(), 'heading', 0) }}
                      showFilter
                    />
                    <label class="active" data-error data-success>Heading</label>
                  </div>
                </MDBCol>
              </MDBRow>
            </div>
          }

          <div style={{ marginBottom: '2em' }} className="translate-me">
            <div style={{ marginBottom: '.5em' }}>Choose Duty Status <span className={styles.required}>*</span></div>
            <button
              className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${(editInfo.eldEventTypeCodeInt === 11) ? styles.active : ''}`}
              onClick={() => this.setDutyStatus(11)}
              disabled={changesProcessing}
            >
              Off Duty
            </button>
            <button
              className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${(editInfo.eldEventTypeCodeInt === 12) ? styles.active : ''}`}
              onClick={() => this.setDutyStatus(12)}
              disabled={changesProcessing}
            >
              Sleeper
            </button>
            <button
              className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${(editInfo.eldEventTypeCodeInt === 13) ? styles.active : ''}`}
              onClick={() => this.setDutyStatus(13)}
              disabled={changesProcessing}
            >
              Driving
            </button>
            <button
              className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${(editInfo.eldEventTypeCodeInt === 14) ? styles.active : ''}`}
              onClick={() => this.setDutyStatus(14)}
              disabled={changesProcessing}
            >
              On Duty
            </button>
            {driver.get('personalUseCMV') &&
              <button
                className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${(editInfo.eldEventTypeCodeInt === 31) ? styles.active : ''}`}
                onClick={() => this.setDutyStatus(31)}
                disabled={changesProcessing}
              >
                Personal Use CMV
              </button>
            }
            {driver.get('yardMoves') &&
              <button
                className={`buttonPill buttonSpacedRight ${styles.buttonPill} ${(editInfo.eldEventTypeCodeInt === 32) ? styles.active : ''}`}
                onClick={() => this.setDutyStatus(32)}
                disabled={changesProcessing}
              >
                Yard Moves
              </button>
            }
          </div>

          <div style={{ marginBottom: '2em' }}>

            <div style={{ marginBottom: '.5em' }} className="translate-me">Dispatcher/Driver Notes</div>
            <textarea
              className="textarea"
              id="eventsNote"
              placeholder={t('Enter Comments for the ELD Events (Optional)...')}
            />
          </div>

          {editRequests.length > 0 &&
            <div>
              <div className="translate-me">Change Summary</div>
              <div>
                {editRequestsList}
              </div>
            </div>
          }

          {!disableViolations && eldDailyCertification.get('hosViolations') &&
            <div style={{ marginTop: '4em' }}>
              <Divider />
              <HOSViolationsTable
                show={showViolations}
                toggleShowViolations={() => this.setState({ ...this.state, showViolations: !showViolations })}
                hosViolations={eldDailyCertification.get('hosViolations')}
                driver={driver}
                scaleToDriverTimezone
              />
            </div>
          }

          <ConfirmModal show={confirmRequestModal.show} handleModalChoice={(confirmBool) => confirmBool ? this.submitEditRequests() : this.setState({ ...this.state, confirmRequestModal: { show: false } })} handleClose={() => this.setState({ ...this.state, confirmRequestModal: { show: false } })} className="translate-me">
            <span>
              {isOverrideELDEdit && (
                <div style={{ color: '#E03857', fontWeight: '600', textTransform: 'uppercase' }}>
                  !! Warning !!:
                  <br /><br />
                  You are attempting to force override driver logs. By confirming, you acknowledge that Switchboard
                  and the company of the driver are aware of the liabilities and laws surrounding such an edit
                  <br /><br />
                  This tool should only be used for the purposes of creating driver logs during inactive days, past 14 days ago. To edit for a day
                  with driver activity (or once the manual logs are created), the normal Edit function should be used
                </div>
              )}
              {isOverrideELDEvents && (
                <div style={{ color: '#E03857', fontWeight: '600', textTransform: 'uppercase' }}>
                  !! Warning !!:
                  <br /><br />
                  You are attempting to force override driver logs. By confirming, you acknowledge that Switchboard
                  and the company of the driver are aware of the liabilities and laws surrounding such an edit
                  <br /><br />
                  This tool should only be used for the purposes of overriding HOS graph issues. Otherwise, the normal edit function should be used
                </div>
              )}
              {!isOverrideELDEdit && !isOverrideELDEvents && (
                <React.Fragment>
                  <div style={{ color: '#E03857', fontWeight: '600' }}>
                    Warning:
                    <br /><br />
                    When confirming: Until the respective driver receives/accepts your changes...
                    <br /><br />
                    <div>- You will not be able to request further edits</div>
                  </div>

                  <br /><br />
                  Request Edit(s)?
                </React.Fragment>
              )}
            </span>
          </ConfirmModal>
        </Dialog>

        <Snackbar
          open={editRequestAdded}
          message={t('Ready to add another edit')}
          autoHideDuration={1500}
          onRequestClose={() => { this.setState({ ...this.state, editRequestAdded: false }); }}
        />

      </div>
    );
  }
}

ELDEditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  eldDailyCertification: PropTypes.object.isRequired,
  company: PropTypes.object,
  eldEvents: PropTypes.array,
  associatedELDEvents: PropTypes.array,
  singleELDEventEdit: PropTypes.object,
  driver: PropTypes.object,
  scaleToDriverTimezone: PropTypes.bool,
  outlineEdits: PropTypes.bool,
  outlineAutoGeneratedDrivingTimes: PropTypes.bool,
  isOverrideELDEdit: PropTypes.bool,
  isOverrideELDEvents: PropTypes.bool,
};

export default ELDEditModal;
