/* eslint-disable max-len */
import React from 'react';

// api
import Timezones from 'sb-csapi/dist/lists/timezones';

// sbCore
import Divider from 'sbCore/Divider/Divider';

// shared components
import DocumentInputText from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputText/InputText';
import DocumentInputNumber from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputNumber/InputNumber';
import DocumentInputTextarea from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputTextarea/InputTextarea';
import DocumentInputDropdown from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/Dropdown/Dropdown';

import './style.scss';

/**
 * @description The Load Confirmation table for the document generator
 * @param {Array} data - an array of objects which corresponds to the rows of the table
 * @returns {Component}
 */
function LoadConfirmationTable({ ...props }) {
  const shipperInformationTemplate = (data) => {
    const { shipperDispatchOrganization, dispatchTransferObjectId } = data;

    const shipperDispatchOrganizationInformation = shipperDispatchOrganization ? shipperDispatchOrganization.shipperDispatchOrganizationInformation : '';

    return (
      <DocumentInputTextarea
        label="Shipper"
        horizontal
        inputClassName={"w-13rem text-sm"}
        value={shipperDispatchOrganizationInformation}
        onChange={(value) => {
          const updatedLoadConfirmationData = {};
          Object.assign(updatedLoadConfirmationData, props.data);
          updatedLoadConfirmationData[dispatchTransferObjectId]['shipperDispatchOrganization']['shipperDispatchOrganizationInformation'] = value;

          props.updateLoadConfirmationData(updatedLoadConfirmationData);
        }}
        success={shipperDispatchOrganizationInformation}
        warning={!shipperDispatchOrganizationInformation || shipperDispatchOrganizationInformation == ''}
        isDownloaded={props.isDownloaded}
      />
    );
  };

  const consigneeInformationTemplate = (data) => {
    const { consigneeDispatchOrganization, dispatchTransferObjectId } = data;

    const consigneeDispatchOrganizationInformation = consigneeDispatchOrganization ? consigneeDispatchOrganization.consigneeDispatchOrganizationInformation : '';

    return (
      <DocumentInputTextarea
        label="Consignee"
        horizontal
        inputClassName={"w-13rem text-sm"}
        value={consigneeDispatchOrganizationInformation}
        onChange={(value) => {
          const updatedLoadConfirmationData = {};
          Object.assign(updatedLoadConfirmationData, props.data);
          updatedLoadConfirmationData[dispatchTransferObjectId]['consigneeDispatchOrganization']['consigneeDispatchOrganizationInformation'] = value;

          props.updateLoadConfirmationData(updatedLoadConfirmationData);
        }}
        success={consigneeDispatchOrganizationInformation}
        warning={!consigneeDispatchOrganizationInformation || consigneeDispatchOrganizationInformation == ''}
        isDownloaded={props.isDownloaded}
      />
    );
  };

  const commoditiesTemplate = (data) => {
    const { dispatchTransferObjectId, dispatchItems } = data;

    return (
      <div>
        <div><strong>Commodities:</strong></div>
        {!dispatchItems && (
          <div>
            No commodities
          </div>
        )}
        {dispatchItems && dispatchItems.map((dispatchItem, index) => (
          <div>
            <DocumentInputText
              label="Freight Type"
              horizontal
              inputClassName="w-full"
              value={dispatchItem.commodityType}
              onChange={(value) => {
                const updatedLoadConfirmationData = {};
                Object.assign(updatedLoadConfirmationData, props.data);
                updatedLoadConfirmationData[dispatchTransferObjectId]['dispatchItems'][index]['commodityType'] = value;

                props.updateLoadConfirmationData(updatedLoadConfirmationData);
              }}
              success={dispatchItem.commodityType}
              warning={!dispatchItem.commodityType || dispatchItem.commodityType === ''}
              isDownloaded={props.isDownloaded}
            />
            <DocumentInputNumber
              label="Quantity"
              horizontal
              inputClassName="w-full"
              value={dispatchItem.quantity}
              onChange={(value) => {
                const updatedLoadConfirmationData = {};
                Object.assign(updatedLoadConfirmationData, props.data);
                updatedLoadConfirmationData[dispatchTransferObjectId]['dispatchItems'][index]['quantity'] = value;

                props.updateLoadConfirmationData(updatedLoadConfirmationData);
              }}
              success={dispatchItem.quantity}
              warning={!dispatchItem.quantity || dispatchItem.quantity === ''}
              isDownloaded={props.isDownloaded}
            />
            <DocumentInputText
              label="Category"
              horizontal
              inputClassName="w-full"
              value={dispatchItem.categoryStr}
              onChange={(value) => {
                const updatedLoadConfirmationData = {};
                Object.assign(updatedLoadConfirmationData, props.data);
                updatedLoadConfirmationData[dispatchTransferObjectId]['dispatchItems'][index]['categoryStr'] = value;

                props.updateLoadConfirmationData(updatedLoadConfirmationData);
              }}
              success={dispatchItem.categoryStr}
              warning={!dispatchItem.categoryStr || dispatchItem.categoryStr === ''}
              isDownloaded={props.isDownloaded}
            />
            <DocumentInputText
              label="Weight"
              horizontal
              inputClassName="w-full"
              value={dispatchItem.weight}
              onChange={(value) => {
                const updatedLoadConfirmationData = {};
                Object.assign(updatedLoadConfirmationData, props.data);
                updatedLoadConfirmationData[dispatchTransferObjectId]['dispatchItems'][index]['weight'] = value;

                props.updateLoadConfirmationData(updatedLoadConfirmationData);
              }}
              success={dispatchItem.weight}
              warning={!dispatchItem.weight || dispatchItem.weight === ''}
              isDownloaded={props.isDownloaded}
            />
            <DocumentInputText
              label="Dimensions"
              horizontal
              inputClassName="w-full"
              value={dispatchItem.dimensions}
              onChange={(value) => {
                const updatedLoadConfirmationData = {};
                Object.assign(updatedLoadConfirmationData, props.data);
                updatedLoadConfirmationData[dispatchTransferObjectId]['dispatchItems'][index]['dimensions'] = value;

                props.updateLoadConfirmationData(updatedLoadConfirmationData);
              }}
              success={dispatchItem.dimensions}
              warning={!dispatchItem.dimensions || dispatchItem.dimensions === ''}
              isDownloaded={props.isDownloaded}
            />
            <DocumentInputTextarea
              label="Notes"
              horizontal
              inputClassName="w-full"
              value={dispatchItem.notes}
              onChange={(value) => {
                const updatedLoadConfirmationData = {};
                Object.assign(updatedLoadConfirmationData, props.data);
                updatedLoadConfirmationData[dispatchTransferObjectId]['dispatchItems'][index]['notes'] = value;

                props.updateLoadConfirmationData(updatedLoadConfirmationData);
              }}
              success={dispatchItem.notes}
              warning={!dispatchItem.notes || dispatchItem.notes === ''}
              isDownloaded={props.isDownloaded}
            />
          </div>
        ))}
      </div>
    );
  }

  const accessorialsTemplate = (data) => {
    const { dispatchTransferObjectId, dispatchItemAccessorials } = data;

    return (
      <div>
        <div><strong>Accessorials:</strong></div>
        {!dispatchItemAccessorials && (
          <div>
            No accessorials
          </div>
        )}
        {dispatchItemAccessorials && dispatchItemAccessorials.map((dispatchItemAccessorial, index) => (
          <div>
            <DocumentInputText
              label="Name"
              horizontal
              inputClassName="w-full"
              value={dispatchItemAccessorial.name}
              onChange={(value) => {
                const updatedLoadConfirmationData = {};
                Object.assign(updatedLoadConfirmationData, props.data);
                updatedLoadConfirmationData[dispatchTransferObjectId]['dispatchItemAccessorials'][index]['name'] = value;

                props.updateLoadConfirmationData(updatedLoadConfirmationData);
              }}
              success={dispatchItemAccessorial.name}
              warning={!dispatchItemAccessorial.name || dispatchItemAccessorial.name === ''}
              isDownloaded={props.isDownloaded}
            />
            <DocumentInputNumber
              label="Quantity"
              horizontal
              inputClassName="w-full"
              value={dispatchItemAccessorial.quantity}
              onChange={(value) => {
                const updatedLoadConfirmationData = {};
                Object.assign(updatedLoadConfirmationData, props.data);
                updatedLoadConfirmationData[dispatchTransferObjectId]['dispatchItemAccessorials'][index]['quantity'] = value;

                props.updateLoadConfirmationData(updatedLoadConfirmationData);
              }}
              success={dispatchItemAccessorial.quantity}
              warning={!dispatchItemAccessorial.quantity || dispatchItemAccessorial.quantity === ''}
              isDownloaded={props.isDownloaded}
            />
          </div>
        ))}
      </div>
    );
  }

  const additionalInformationTemplate = (data) => {
    const {
      dispatchTransferObjectId,
      shipperDispatchOrganization,
      consigneeDispatchOrganization,
      pickupDate,
      pickupTime,
      dropoffDate,
      dropoffTime,
      timezoneOffsetFromUTC
    } = data;

    return (
      <div>
        <DocumentInputText
          label="Pickup Date"
          horizontal
          inputClassName="w-full"
          value={pickupDate}
          onChange={(value) => {
            const updatedLoadConfirmationData = {};
            Object.assign(updatedLoadConfirmationData, props.data);
            updatedLoadConfirmationData[dispatchTransferObjectId]['pickupDate'] = value;

            props.updateLoadConfirmationData(updatedLoadConfirmationData);
          }}
          success={pickupDate}
          warning={!pickupDate || pickupDate === ''}
          isDownloaded={props.isDownloaded}
        />
        <DocumentInputText
          label="Pickup Time"
          horizontal
          inputClassName="w-full"
          value={pickupTime}
          onChange={(value) => {
            const updatedLoadConfirmationData = {};
            Object.assign(updatedLoadConfirmationData, props.data);
            updatedLoadConfirmationData[dispatchTransferObjectId]['pickupTime'] = value;

            props.updateLoadConfirmationData(updatedLoadConfirmationData);
          }}
          success={pickupTime}
          warning={!pickupTime || pickupTime === ''}
          isDownloaded={props.isDownloaded}
        />
        <DocumentInputText
          label="Dropoff Date"
          inputClassName="w-full"
          horizontal
          value={dropoffDate}
          onChange={(value) => {
            const updatedLoadConfirmationData = {};
            Object.assign(updatedLoadConfirmationData, props.data);
            updatedLoadConfirmationData[dispatchTransferObjectId]['dropoffDate'] = value;

            props.updateLoadConfirmationData(updatedLoadConfirmationData);
          }}
          success={dropoffDate}
          warning={!dropoffDate || dropoffDate === ''}
          isDownloaded={props.isDownloaded}
        />
        <DocumentInputText
          label="Dropoff Time"
          inputClassName="w-full"
          horizontal
          value={dropoffTime}
          onChange={(value) => {
            const updatedLoadConfirmationData = {};
            Object.assign(updatedLoadConfirmationData, props.data);
            updatedLoadConfirmationData[dispatchTransferObjectId]['dropoffTime'] = value;

            props.updateLoadConfirmationData(updatedLoadConfirmationData);
          }}
          success={dropoffTime}
          warning={!dropoffTime || dropoffTime === ''}
          isDownloaded={props.isDownloaded}
        />
        <DocumentInputDropdown
          label="Timezone"
          inputClassName="w-full"
          horizontal
          value={timezoneOffsetFromUTC}
          options={Timezones}
          onChange={(value) => {
            const updatedLoadConfirmationData = {};
            Object.assign(updatedLoadConfirmationData, props.data);
            updatedLoadConfirmationData[dispatchTransferObjectId]['timezoneOffsetFromUTC'] = value;

            props.updateLoadConfirmationData(updatedLoadConfirmationData);
          }}
          success={timezoneOffsetFromUTC}
          warning={!timezoneOffsetFromUTC || timezoneOffsetFromUTC === ''}
          isDownloaded={props.isDownloaded}
        />
        <DocumentInputText
          label="Shipper Appointment Required"
          inputClassName="w-full"
          horizontal
          value={shipperDispatchOrganization && shipperDispatchOrganization.appointmentRequired}
          onChange={(value) => {
            const updatedLoadConfirmationData = {};
            Object.assign(updatedLoadConfirmationData, props.data);
            updatedLoadConfirmationData[dispatchTransferObjectId]['shipperDispatchOrganization']['appointmentRequired'] = value;

            props.updateLoadConfirmationData(updatedLoadConfirmationData);
          }}
          success={shipperDispatchOrganization && shipperDispatchOrganization.appointmentRequired}
          warning={!(shipperDispatchOrganization && shipperDispatchOrganization.appointmentRequired) || (shipperDispatchOrganization && shipperDispatchOrganization.appointmentRequired === '')}
          isDownloaded={props.isDownloaded}
        />
        <DocumentInputText
          label="Consignee Appointment Required"
          inputClassName="w-full"
          horizontal
          value={consigneeDispatchOrganization && consigneeDispatchOrganization.appointmentRequired}
          onChange={(value) => {
            const updatedLoadConfirmationData = {};
            Object.assign(updatedLoadConfirmationData, props.data);
            updatedLoadConfirmationData[dispatchTransferObjectId]['consigneeDispatchOrganization']['appointmentRequired'] = value;

            props.updateLoadConfirmationData(updatedLoadConfirmationData);
          }}
          success={consigneeDispatchOrganization && consigneeDispatchOrganization.appointmentRequired}
          warning={!(consigneeDispatchOrganization && consigneeDispatchOrganization.appointmentRequired) || (consigneeDispatchOrganization && consigneeDispatchOrganization.appointmentRequired === '')}
          isDownloaded={props.isDownloaded}
        />
      </div>
    );
  }

  return (
    <div className="w-full mt-2 mb-4">
      <Divider />
      {Object.values(props.data).map((data, index) => (
        <>
          <div className="flex flex-row">
            <div className="flex flex-column">
              <span className="load-confirmation-body-header">STOP {index + 1}</span>
              {shipperInformationTemplate(data)}
              {consigneeInformationTemplate(data)}
            </div>
            <Divider layout="vertical" />
            <div className="flex flex-row flex-grow-1">
              <div className="flex flex-column flex-grow-1">
                {commoditiesTemplate(data)}
                {accessorialsTemplate(data)}
              </div>
              <div className="flex flex-column flex-grow-1">
                {additionalInformationTemplate(data)}
              </div>
            </div>
          </div>
          <Divider />
        </>
      ))}
    </div>
  );
}

export default LoadConfirmationTable;
