import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import Papa from 'papaparse';

// api
import { compressWhitespace, getStringSimilarityScore } from 'sb-csapi/dist/utils/String';

// components
import CSVUploaderButton from 'sbCore/CSVUploader/CSVUploaderButton/CSVUploaderButton';
import CSVDataTable from 'sbCore/CSVUploader/CSVDataTable/CSVDataTable';

import Message from 'sbCore/Message/Message';
import FileUpload from 'sbCore/FileUpload/FileUpload';
import ProgressBar from 'sbCore/ProgressBar/ProgressBar';
import Tag from 'sbCore/Tag/Tag';
import Dialog from 'sbCore/Dialog/Dialog';
import Button from 'sbCore/Button/Button';

// styles
import './style.scss';

/**
 * @description A base component for uploading CSV/XLSX files, extendable to other components for CSV/XLSX uploading
 *              such as Fuel Cards
 *
 * @todo Unless mentioned otherwise, multiple file upload (and multiple sheets per file) are supported / accounted for in each function/component
 *
 * @param {String} [className]
 * @param {Object} headerMap - A map where [key] is the name of a header you define and [value] is array of keywords
 *                             that could map to it from the file (keywords must be lower-cased).
 *                             The keywords should be ordered by strongest match (least ambiguity) to weakest.
 *                             They [key]s are also used for column selection.
 *                             See example below
 * @param {Function} validateAndUploadFiles - Callback to validate and upload the file(s). Must be Async and return True if the file(s) was validated and uploaded, otherwise False
 *                                            Returns the current state of information for/in the upload as with getUploadContent()
 * @param {Function} [getUploadContent] - Returns the current state of information for/in the upload.
 *                                        Returns { [fileId]: id given to file, [header]: header/column info, [body]: row/column info, [raw]: original parsed file information }
 * @param {Function} [onClose] - Callback for when the uploader dialog is closed
 * @param {Object} [overrideCellMap] - A map where the key is the cell location and value is what we want to show in that cell
 *                                      Example: Want to replace the value at Row 2 Column 13 -> { "2:13": "ayy lmao" }
 *
 * @param {Array} [errors] - An array of errors from the parent. Each error can be of any type
 * @param {Any} [options] - Anything. It appears under the DataTable
 * @param {String} [buttonClassName] - className for the upload button
 * @param {String} [buttonLabel] - Label for the upload button
 * @param {String} [buttonSeverity] - Severity color of the button (ex. "primary", "secondary", etc)
 * @param {Any} [successMessage] - The message to display after a successful upload
 * @param {Boolean} [isLoading] - Whether the button should be loading according to a parent
 * @param {Boolean} [disabled] - Whether the button should be disabled according to a parent
 * @returns
 *
 * @example
 * Minimal viable setup example of fuel card upload content
 *
 * const headerMap = {
 *  'Unit ID': ['unit id', 'unit #', 'unit number', 'unit#', 'vehicle unit', 'vehicle'],
 *  'State/Province': ['state/province', 'state/prov', 'state/ prov', 'merchant state', 'merchant province', 'state', 'province'],
 *  'Date': ['transaction date', 'tran date', 'purchase date', 'date'],
 *  'Time': ['transaction time', 'tran time', 'purchase time', 'time'],
 *  'Fuel Name': ['item name', 'item', 'fuel', 'tractor fuel', 'product name', 'product description', 'product'],
 *  'Quantity': ['qty', 'volume'],
 *  'Measurement': ['uom'],
 *  'Cost': ['total amt', 'amt', 'amount $', 'total transaction', 'total amount',  'amount'],
 *  'Currency': ['currency'],
 * };
 *
 * <CSVUploader
 *  headerMap={headerMap}
 *  validateAndUploadFiles={(uploadInformation) => validateAndUploadFiles(uploadInformation)}
 * />
 *
 */
function CSVUploader({ ...props }) {
  const allowableFileTypes = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'xls', 'xlsx'];
  const defaultHeaderMap = {
    'Unit ID': ['unit id', 'unit #', 'unit number', 'unit#', 'vehicle unit', 'vehicle'],
    // 'State/Province': ['state/province', 'state/prov', 'state/ prov', 'merchant state', 'merchant province', 'state', 'province'],
    // 'Date': ['transaction date', 'tran date', 'purchase date', 'date'],
    // 'Time': ['transaction time', 'tran time', 'purchase time', 'time'],
    // 'Fuel Name': ['item name', 'item', 'fuel', 'tractor fuel', 'product name', 'product description', 'product'],
    // 'Quantity': ['qty', 'volume'],
    // 'Measurement': ['uom'],
    // 'Cost': ['total amt', 'amt', 'amount $', 'total transaction', 'total amount',  'amount'],
    // 'Currency': ['currency'],
  };

  const defaultKey = '__EMPTY'; // this is the key used when reading/writing columns from the xlsx/csv library
  const miscHeaderName = 'Misc 69sb'; // the default prefix for misc columns

  // Dialog/Uploader enums
  const StepMap = Object.freeze({
    FILE_SELECTION: {
      type: 1,
      jsx: (
        <div>
          To begin, please <b>drag and drop</b> your CSV/XLS file into the box below
        </div>
      ),
    },
    FILES_SELECTED: {
      type: 2,
      jsx: (
        <div>
          When ready, click <b>Next</b> to review the upload prior to submission
        </div>
      ),
    },
    UPLOAD_PREVIEW: {
      type: 3,
      jsx: (
        <div style={{ fontSize: '.9em' }}>
          <div>
            <b>Please review the column Headers for correctness</b>.
            &nbsp;If a Header is incorrectly assigned, click on it to reassign to one of the provided options
          </div>
          <div className="mt-2">If the Headers look correct, click <b>Upload</b> to complete the process</div>
        </div>
      ),
    },
    FINAL: {
      type: 4,
      jsx: (
        <div>
          Done! Your file has been successfully uploaded
        </div>
      ),
    },
  });

  const Error = Object.freeze({
    FILE_NUMBER_LIMIT: 'Please note only one file can be uploaded at a time',
    FILE_UNSUPPORTED: 'Please note only CSV and Excel files can be uploaded',
    FILE_EMPTY: 'It appears the file is empty. Please upload a file with data content',
  });

  // General properties
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage] = useState(props.successMessage || 'Done! Your file has been successfully uploaded');

  // Button properties
  const [buttonLabel, setButtonLabel] = useState(props.buttonLabel);
  const [disabled, setDisabled] = useState(props.disabled ?? true); // shows disabled button. should default to true so button does not initially render as active

  const [currentStep, setCurrentStep] = useState(StepMap.FILE_SELECTION); // track the current step
  const [currentError, setCurrentError] = useState(null); // from Error
  const [totalSize, setTotalSize] = useState(0); // track the total size of all selected files
  const [fileUploadVisible, setFileUploadVisible] = useState(false);
  const [disabledNextButton, setDisabledNextButton] = useState(true); // whether or not to allow the user to proceed to the next step
  const [footerTemplate, setFooterTemplate] = useState(null);

  // Files and data manipulation
  const [headerMap, setHeaderMap] = useState(defaultHeaderMap); // to be overwritten by props.headerMap
  const [files, setFiles] = useState([]); // the uploaded files which get parsed and goes into filesBreakdownArray
  const [filesBreakdownArray, setFilesBreakdownArray] = useState([]); // this is the main file data we are working with before setting it as uploadContent
  const [uploadContent, setUploadContent] = useState({}); // the current state of information for/in the upload for other components (parents) to use
  const uploadContentCache = useRef({}); // Perhaps should replace uploadContent. Same as uploadContent, but since it is not state, it does not take as long to update and pass to the parent

  useEffect(() => {
    // Initializer
    setIsLoading(true);
    let didCancel = false;

    async function init() {
      const _disabled = props.disabled ?? false;
      const _isLoading = props.isLoading ?? false;

      if (!didCancel) {
        setButtonLabel(props.buttonLabel || 'Upload File');
        setHeaderMap(props.headerMap || defaultHeaderMap);
        setDisabled(_disabled);
        setDisabledNextButton(true);
        setIsLoading(_isLoading);
      }
    }

    init();
    return () => { didCancel = true; };
  }, []);

  useEffect(() => {
    // determines whether or not to disable the Next/Upload button
    let _totalSize = 0;
    files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    let _currentError = null;
    let _disabledNextButton = !areAcceptedFilesOfValidType(files);
    if (_disabledNextButton) {
      _currentError = Error.FILE_UNSUPPORTED;
    }

    if (files.length > 1) {
      // @todo 2024-05-16 - Adding and removing multiple files/sheets are already supported in this uploader, but for the CSVDataTable
      //                    we only want to support 1 file for now, so we limit the user here
      _disabledNextButton = true;
      _currentError = Error.FILE_NUMBER_LIMIT;
    }

    if (files.length === 0) {
      setCurrentStep(StepMap.FILE_SELECTION);
      _disabledNextButton = true;
    }

    if (isLoading) {
      _disabledNextButton = true;
    }

    if ((props.errors || []).length > 0) {
      // Errors from the parent to display here
      _currentError = props.errors;

      if (currentStep.type !== StepMap.UPLOAD_PREVIEW.type) { // let the user continue to hit the Validation / Uplaod button
        _disabledNextButton = true;
      }
    }

    setCurrentError(_currentError);
    setDisabledNextButton(_disabledNextButton);
    setTotalSize(_totalSize);
  }, [files, props.errors, isLoading]);

  useEffect(() => {
    // if the parent decides to change the header, we need to rerun the file processing like in onSelect()
    let didCancel = false;

    async function refreshHeaderMap() {
      setIsLoading(true);
      setHeaderMap(props.headerMap);

      const _filesBreakdownArray = await getFilesBreakdownArray(files);

      if (!didCancel) {
        setFilesBreakdownArray([..._filesBreakdownArray]);
        setIsLoading(false);
      }
    }

    const isHeaderMapChanged = (JSON.stringify(props.headerMap) !== JSON.stringify(headerMap)) || (props.headerMap !== headerMap) || (currentStep.type === StepMap.UPLOAD_PREVIEW.type);
    const hasOverrideCellMap = Object.keys(props.overrideCellMap || {}).length > 0;
    if (files.length && (isHeaderMapChanged || hasOverrideCellMap)) {
      refreshHeaderMap();
    }

    return () => { didCancel = true; };
  }, [currentStep, props.headerMap, props.overrideCellMap]);

  // useEffect(() => {
  // console.log(filesBreakdownArray);
  // // return to the parent for any override processing and correcting

  // }, [filesBreakdownArray]);

  useEffect(() => {
    generateFooterTemplate();
  }, [fileUploadVisible, currentStep, currentError, disabledNextButton]);

  useEffect(() => {
    if (!uploadContent.fileId) return; // if no file id, this suggests that no processing has been done yet (ex. first mount)

    if (!Object.keys(uploadContent.body).length) {
      // empty file
      setCurrentError(Error.FILE_EMPTY);
      setDisabledNextButton(true);
    }

    if (props.getUploadContent) props.getUploadContent(uploadContent);
  }, [uploadContent]);

  /**
   * Helper Functions
   */
  function resetState() {
    // sets appropriate state variables to what they should be when the modal is closed (unmount)
    // note that some resets already happen in the 'initializer' useEffect when it's triggered via useEffect props
    // if you want to reset those states, we suggest to re-trigger the initializer than coding the reset here
    setFiles([]);
    setFilesBreakdownArray([]);
    setHeaderMap(props.headerMap || defaultHeaderMap);
    setCurrentStep(StepMap.FILE_SELECTION);
    setCurrentError(null);
  }

  function toggleFileUploadVisible() {
    if (fileUploadVisible) {
      resetState();
      if (props.onClose) props.onClose();
    }
    setFileUploadVisible(!fileUploadVisible);
  }

  // Whenever the user attempts to bypass the <fileupload> restriction, we do double verification here
  function areAcceptedFilesOfValidType(files = []) {
    for (let i = 0; i < files.length; i++) {
      if (allowableFileTypes.indexOf(files[i].type) === -1) {
        return false;
      }
    }
    return true;
  }

  // A helper to create an identifier based on file content so we can associate which file content belong to which file
  function getFileId(file) {
    return `${compressWhitespace(file.name)}${file.size}${file.lastModified}`;
  }

  // A helper for getFileAsRowObjectArray()
  function getCSVData(eventData) {
    // Parsing CSV files
    return new Promise((resolve, reject) => {
      Papa.parse(eventData, {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: false,
        complete: function(results) {
          // results.data contains the parsed CSV data as an array of objects
          resolve(results.data);
        },
        error: function(error) {
          console.error('Error parsing CSV:', error);
          reject([]);
        }
      });
    });
  }

  // A helper for getFilesAsRowObjectArray
  // Takes an individual file and returns an array of objects representing each row
  function getFileAsRowObjectArray(file) {
    return new Promise((resolve, reject) => {
      const fileId = getFileId(file);
      const isCSV = file.type === 'text/csv';

      const reader = new FileReader();
      if (!isCSV) {
        reader.readAsArrayBuffer(file);
      } else {
        reader.readAsText(file);
      }

      reader.onload = async (event) => {
        let data = event.target.result;
        const fileAsRowObjectArray = []; // all the row objects generated below

        if (!isCSV) { // handles excel files
          data = new Uint8Array(event.target.result);
          const workbook = window.XLSX.read(data, { type: 'array', cellDates: true });

          // for each worksheet, create rowObjects array
          workbook.SheetNames.forEach((sheetName) => {
            const rowObjects = window.XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { defval: '' });

            // go through each cell, and if it appears to be a date, we want to preserve the string as is, without any conversions
            rowObjects.forEach((row) => {
              Object.keys(row).forEach((key) => {
                const value = row[key]; // the cell's value
                const isDate = Object.prototype.toString.call(value) === '[object Date]';
                if (value && isDate) {
                  // some dates can be like
                  row[key] = moment(value).format('YYYY-MM-DD HH:mm:ss');
                }
              });
            });

            // we'll add a custom entry at the start that retains the id in the results. let's call this the file id object
            rowObjects.unshift({ id: fileId });
            fileAsRowObjectArray.push(rowObjects);
          });
        } else {
          // Note: The XLSX library also handles CSV files, however there are times where it mishandles date/time values, so we branch off CSV processing here
          const rowObjects = await getCSVData(data);
          rowObjects.unshift({ id: fileId });
          fileAsRowObjectArray.push(rowObjects);
        }

        // There is a weird edge case where some uploaded CSV files give a different output for rowObjects
        // This means we have the following cases that return different output formats:
        // Uploading Excel Files, Uploading CSV Files that were converted from Excel Files, and Uploading CSV Files
        for (let i = 0; i < fileAsRowObjectArray.length; i++) {
          // iterate through each sheet or file in fileAsRowObjectArray
          const sheetAsRowObjectArray = fileAsRowObjectArray[i];
          const firstRowAfterFileIdObject = sheetAsRowObjectArray[1];

          if (firstRowAfterFileIdObject) {
            // if the keys of the output are not as we expect from XLSX.sheet_to_json, we have to standardize them
            const keys = Object.keys(firstRowAfterFileIdObject);
            if (keys.length && keys[0] !== defaultKey) {
              // This case likely means that the rows are mappings where { [key as Header Name]: value, ... }
              const _sheetAsRowObjectArray = [];
              _sheetAsRowObjectArray[0] = { ...sheetAsRowObjectArray[0] };
              _sheetAsRowObjectArray[1] = {};
              keys.map((key, index) => {
                if (index === 0) {
                  _sheetAsRowObjectArray[1][defaultKey] = key;
                } else {
                  _sheetAsRowObjectArray[1][`${defaultKey}_${index}`] = key;
                }
              });

              for (let k = 1; k < sheetAsRowObjectArray.length; k++) {
                const rowObjectArray = sheetAsRowObjectArray[k];
                const _rowObject = {};

                Object.values(rowObjectArray).map((value, index) => {
                  if (index === 0) {
                    _rowObject[defaultKey] = value;
                  } else {
                    _rowObject[`${defaultKey}_${index}`] = value;
                  }
                });

                _sheetAsRowObjectArray.push(_rowObject);
              }

              // now that we've converted to the format we expect, re-assign the value
              fileAsRowObjectArray[i] = _sheetAsRowObjectArray;
            }
          }
        }

        resolve(fileAsRowObjectArray);
      };
    });
  }

  // A helper for getFilesBreakdownArray
  // Turns the content of each file into an array of objects representing each row. This logic works for both CSV and Excel files
  // Example 2 file output: [Array(12), Array(10)];, each Array representing a file broken down into rows
  async function getFilesAsRowObjectArray(files = []) {
    let _filesAsRowObjectArray = [];

    const getFileAsRowObjectArrayPromises = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { type } = file;

      // if the file is not an accepted type, skip it
      if (!allowableFileTypes.includes(type)) continue;
      getFileAsRowObjectArrayPromises.push(getFileAsRowObjectArray(file));
    }

    _filesAsRowObjectArray = await Promise.all(getFileAsRowObjectArrayPromises);

    // Sometimes an excel file can have multiple worksheets. We take this into account here
    // Ex. Output from a CSV and an Excel file with 2 worksheets: [Array(12), [Array(5), Array(10)]]. In this example, want to flatten this to 3 entries
    _filesAsRowObjectArray = _filesAsRowObjectArray.flat(1);

    return _filesAsRowObjectArray;
  }

  // Helper for getFilesBreakdownArray
  // Algorithm to best predict where the main file content begins, using the number of hits in the headerMap keywords
  function getContentStartIndex(fileAsRowObjectArray) {
    const headerValueKeywords = Object.values(headerMap).flat(1); // get a list of all the keywords together
    const debug_hitMap = {}; // just meant for debugging. Seeing which values matched with which keywords

    // the indexes of the array corresponds directly to the row #, the value corresponds to how many hits in headerValueKeywords
    // are in that row
    const indexArray = [];

    for (let i = 0; i < fileAsRowObjectArray.length; i++) {
      const rowObject = fileAsRowObjectArray[i];
      const values = Object.values(rowObject);
      let hitCounter = 0; // number of hits of headerValueKeywords in this row

      for (let k = 0; k < values.length; k++) {
        const value = (values[k] || '').toString().toLowerCase();
        if (value === '') continue;

        // Check if any element in 'headerValueKeywords' includes the current 'value' as a substring
        headerValueKeywords.map(keyword => {
          if (keyword.includes(value)) {
            hitCounter++;
            debug_hitMap[value] = keyword;
          }
        });
      }

      indexArray.push(hitCounter);
    }
    // console.log(debug_hitMap);
    // now that we have our indexArray filled out, we want to get the index of the element representing the largest number of hits
    const maxElement = Math.max(...indexArray);
    return indexArray.indexOf(maxElement);
  }

  // Compares the headers we want in headerMap to the headers in the file and returns a best-guess mapping to that column index
  // Note: "Header" references the headers defined in headerMap. "File Header" references the header as defined by the uploaded file(s)
  function getHeaderColumnMap(headerMap, fileHeader) {
    const headerColumnMap = {};

    // at the moment the algorithm we use to determine the mapping is based on first hit in headerMap,
    // where we compare a entry in file header to the first hit in headerMap
    Object.keys(headerMap).map((headerName) => {
      headerColumnMap[headerName] = {
        headerName,             // the column name we want, from headerMap
        fileHeaderName: '',     // the column name as shown on the file, that we think maps to headerName
        keyword: '',            // the keyword used to match to headerName
        index: -1,              // the index of where fileHeaderName column is, in the row
        similarityScore: -1,    // the degree of confidence we think this header matching is correct. mainly for debugging
      };
    });

    // next we create the reverse of headerMap for quicker look-up. It maps each keyword in headerMap to the [key] which is the header name(s)
    // ex. { "amount $": ['Amount', 'Cost'], ... }
    const reverseHeaderMap = {};
    for (const headerName in headerMap) {
      if (headerMap.hasOwnProperty(headerName)) {
        const values = headerMap[headerName];

        // Iterate over each element in the array
        // Take into account that there can be the same keyword for several headerNames
        values.forEach(value => {
          if (!reverseHeaderMap[value]) {
            reverseHeaderMap[value] = [];
          }
          reverseHeaderMap[value].push(headerName);
        });
      }
    }

    // for each file header name, check to see which key from reverseHeaderMap it matches most closely.
    // this will give us the closest match to a Header we defined in headerMap
    const fileHeaderNames = Object.values(fileHeader);
    const reverseHeaderMapKeys = Object.keys(reverseHeaderMap);
    const removedHeaderMap = {}; // here we track which headers we no longer want to match

    for (let i = 0; i < fileHeaderNames.length; i++) {
      const fileHeaderName = (fileHeaderNames[i] || '').toString().trim().toLowerCase();
      if (fileHeaderName === '') continue;

      let reverseHeaderMapKey = ''; // the closest matched keyword from reverseHeaderMapKeys
      let matchedHeaderName = ''; // the closest match so far to a header in headerMap
      let matchedFileHeaderName = '';
      let similarityScore = -1; // how likely headerName matches fileHeaderName
      let index = -1; // the index of fileHeaderName

      for (let k = 0; k < reverseHeaderMapKeys.length; k++) {
        const _reverseHeaderMapKey = reverseHeaderMapKeys[k];

        const _similarityScore = getStringSimilarityScore(fileHeaderName, _reverseHeaderMapKey);

        if (_similarityScore < 0.45) continue; // low degree of confidence, don't bother guessing cause we're more likely to get an incorrect result than correct

        if (_similarityScore > similarityScore) {
          const headerName = reverseHeaderMap[_reverseHeaderMapKey][0];
          if (removedHeaderMap[headerName]) continue; // we have already found a match for this header

          matchedFileHeaderName = fileHeaderName;
          similarityScore = _similarityScore;
          reverseHeaderMapKey = _reverseHeaderMapKey;
          matchedHeaderName = headerName;
          index = i;

          if (_similarityScore === 1) {
            // we found an exact match
            removedHeaderMap[matchedHeaderName] = true;
            break;
          }
        }
      }

      // now we have the closest match
      if (matchedHeaderName) {
        headerColumnMap[matchedHeaderName] = {
          ...headerColumnMap[matchedHeaderName],
          fileHeaderName: matchedFileHeaderName,
          keyword: reverseHeaderMapKey, // the keyword used to match to headerName
          index, // where the column is for this headerName
          similarityScore,
        };
      }
    }

    return headerColumnMap;
  }

  // Takes in files and outputs an array of objects representing finalized/processed content of each file
  async function getFilesBreakdownArray(files) {
    const filesBreakdownArray = [];

    // break down the file(s) into row contents
    const filesAsRowObjectArray = await getFilesAsRowObjectArray(files); // [Array(13), Array(6), ...]

    // from the row contents, discern the header fields and the content fields, and convert them into object format
    for (let i = 0; i < filesAsRowObjectArray.length; i++) {
      const fileAsRowObjectArray = [...filesAsRowObjectArray[i]];
      const fileDesc = fileAsRowObjectArray[0]; // The first entry contains the file id according to getFileAsRowObjectArray
      const fileBreakdown = {
        id: fileDesc.id,
        header: {}, // the header
        content: [], // the content after the header
        miscRows: [], // will contain the rows with no home :(
      };

      fileBreakdown.id = fileDesc.id;
      fileBreakdown.miscRows = [];

      // figure out where the header and associated content start
      const contentStartIndex = getContentStartIndex(fileAsRowObjectArray);

      // this means the header starts at contentStartIndex, and the rows that comes after are content
      fileBreakdown.header = fileAsRowObjectArray[contentStartIndex];

      // now that we have the header, attempt to map the file header (columns) with the headers defined by the keys in headerMap
      fileBreakdown.headerColumnMap = getHeaderColumnMap(props.headerMap || headerMap, fileBreakdown.header);

      // write a function that gets all elements in an array after a given index
      fileBreakdown.content = fileAsRowObjectArray.slice(contentStartIndex + 1);

      // if there is a props.overrideCellMap, we want to override the content values with those given in the map
      const cellPositions = Object.keys(props.overrideCellMap || {});
      cellPositions.map(cellPosition => {
        const cellPositionSplit = cellPosition.split(':');
        const row = cellPositionSplit[0];
        let column = cellPositionSplit[1].toString();

        if (column === '0') {
          column = defaultKey;
        } else {
          column = `${defaultKey}_${column}`;
        }

        if (fileBreakdown.content[row] && fileBreakdown.content[row][column]) {
          fileBreakdown.content = [...fileBreakdown.content];
          fileBreakdown.content[row] = { ...fileBreakdown.content[row], [column]: props.overrideCellMap[cellPosition] };
        }
      });

      // misc stuff
      fileBreakdown.miscRows = fileAsRowObjectArray.slice(0, contentStartIndex);

      filesBreakdownArray.push(fileBreakdown);
    }

    return filesBreakdownArray;
  }

  // Handles file selection. The whole file processing flow should start here
  async function onSelect(e) {
    setIsLoading(true);

    const _files = e.files || [];
    const _filesBreakdownArray = await getFilesBreakdownArray(_files);

    setFiles([...files, ..._files]);
    setFilesBreakdownArray([...filesBreakdownArray, ..._filesBreakdownArray]);
    setCurrentStep(StepMap.FILES_SELECTED);
    setIsLoading(false);
  }

  // Handles file validatiion and upload if validated
  // @todo 2024-05-22 - Only accounts for 1 file being processed
  async function validateAndUploadFiles() {
    setIsLoading(true);
    // Format the file information for the parent to validate

    const isUploadValid = await props.validateAndUploadFiles(uploadContentCache.current);
    setIsLoading(false);

    if (isUploadValid) {
      setCurrentStep(StepMap.FINAL);
    }
  }

  // Handles file removal
  function onRemove(file, callback) {
    const fileId = getFileId(file);

    // remove entry from list of files
    const _files = [...files];
    for (let i = 0; i < _files.length; i++) {
      const _file = _files[i];
      const _fileId = getFileId(_file);
      if (_fileId === fileId) {
        _files.splice(i, 1);
        break;
      }
    }

    // remove entries from filesBreakdownArray
    const _filesBreakdownArray = [...filesBreakdownArray];
    for (let k = 0; k < _filesBreakdownArray.length; k++) {
      const _fileBreakdown = _filesBreakdownArray[k];
      if (_fileBreakdown.id === fileId) {
        _filesBreakdownArray.splice(k, 1);
        k--;
      }
    }

    setFiles(_files);
    setFilesBreakdownArray(_filesBreakdownArray);
    callback();
  }

  // Handles clear of all files
  function onClear() {
    setTotalSize(0);
  }

  function getUploadContent(headerMap, fileBreakdown) {
    // @todo 2024-05-23 - Only 1 file upload at a time is supported atm
    const fileId = fileBreakdown.id;
    if (!fileId) return; // if no file id, this suggests that no processing has been done yet (ex. first mount)

    // here we want to format this information nicely for the parent
    // but also give the parent the original "raw" information for context
    const _uploadContent = {
      fileId,
      raw: { headerMap, fileBreakdown },
    };

    // format the headerMap nicely for the user, only giving them the header names and assigned indexes
    _uploadContent.header = {};

    Object.keys(headerMap).map((key) => {
      const header = headerMap[key];
      const { headerName, index } = header; // remember: index is the actual column index. the key is not necessarily the column index all the time
      if (headerName.includes(miscHeaderName)) return; // dont show misc column headers
      _uploadContent.header[headerName] = {
        name: headerName,        // name of header
        columnIndex: index,      // which column number
      };
    });

    _uploadContent.body = {};
    const { content } = fileBreakdown;
    content?.map((rowObject, rowIndex) => {
      _uploadContent.body[rowIndex] = {
        rowIndex,                          // row number
        content: Object.values(rowObject), // arrayified version of row
      };
    });

    // this should trigger the uploadContent useEffect
    uploadContentCache.current = _uploadContent;
    setUploadContent(_uploadContent);
  }

  /**
   * Template Functions
   */

  const chooseOptions = {
    className: 'custom-choose-btn p-button-rounded p-button-outlined',
  };
  const uploadOptions = {
    className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined',
  };
  const cancelOptions = {
    className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const _totalSize = totalSize / 10000;

    // For some reason, the "Choose" button does not work properly. Chosen files do get selected, however the e.files.length does not change at all
    return (<div />);

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {[StepMap.FILE_SELECTION.type, StepMap.FILES_SELECTED.type].indexOf(currentStep.type) !== -1 && chooseButton}
      </div>
    );
  };

  const itemTemplate = (file, props) => (
    <div className="flex align-items-center flex-wrap">
      <div className="flex align-items-center" style={{ width: '40%' }}>
        <img
          alt={file.name}
          role="presentation"
          src={file.objectURL}
          width={100}
        />
        <span className="flex flex-column text-left ml-3">
          {file.name}
          <small>{new Date().toLocaleDateString()}</small>
        </span>
      </div>
      <Tag
        value={props.formatSize}
        severity="warning"
        className="px-3 py-2"
      />
      <Button
        type="button"
        icon="pi pi-times"
        className="p-button-outlined p-button-rounded p-button-danger ml-auto"
        onClick={() => onRemove(file, props.onRemove)}
      />
    </div>
  );

  const emptyTemplate = () => (
    <div className="flex align-items-center flex-column">
      <i
        className="pi pi-file mt-3 p-5"
        style={{
          fontSize: '5em',
          borderRadius: '50%',
          backgroundColor: 'var(--surface-b)',
          color: 'var(--surface-d)',
        }}
      />
      <span
        style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
        className="my-5"
      >
        Drag and Drop CSV/XLS files here
      </span>
    </div>
  );

  const showFileUpload = [StepMap.FILE_SELECTION.type, StepMap.FILES_SELECTED.type].indexOf(currentStep.type) !== -1;
  const showDataTable = [StepMap.UPLOAD_PREVIEW.type].indexOf(currentStep.type) !== -1;
  const showSuccessUpload = StepMap.FINAL.type === currentStep.type;

  function generateFooterTemplate() {
    const footerTemplate = (
      <div className="csv-uploader-modal-footer mt-3">
        {showFileUpload && (
          <Button
            label="Next"
            severity="primary"
            onClick={() => setCurrentStep(StepMap.UPLOAD_PREVIEW)}
            sbVariant="short"
            disabled={disabledNextButton}
            tooltip={disabledNextButton && currentError}
            tooltipOptions={{ position: 'mouse', showOnDisabled: disabledNextButton }}
            disableTranslate // for some reason, translation bugs out in this csvuploader component. for now, we disable it
          />
        )}

        {showDataTable && (
          <Button
            label="Upload"
            severity="primary"
            onClick={() => validateAndUploadFiles()}
            sbVariant="short"
            disabled={disabledNextButton}
            disableTranslate
          />
        )}

        <Button
          text
          label={showSuccessUpload ? 'Close' : 'Cancel'}
          severity="secondary"
          onClick={() => toggleFileUploadVisible()}
          sbVariant="short"
        />
      </div>
    );

    setFooterTemplate(footerTemplate);
  }

  let className = 'csv-uploader';
  if (props.className) className += ` ${props.className}`;

  let messageSeverity = 'info';
  if (currentError) {
    messageSeverity = 'error';
  }

  return (
    <div className={className}>
      {fileUploadVisible && (
        <Dialog
          className={className}
          header={buttonLabel}
          footer={footerTemplate}
          contentClassName="csv-uploader-modal-content"
          visible={fileUploadVisible}
          style={{ width: '75em' }} // TODO: Add responsiveness to the width based on the screen size
          closable={false}
          sbVariant="compact"
        >
          {!showSuccessUpload && (
            <Message
              className="w-100 mb-3"
              severity={messageSeverity}
              content={currentError ?? currentStep.jsx}
            />
          )}

          {showFileUpload && (
            <FileUpload
              name="demo[]"
              url="/api/upload"
              multiple
              // customUpload
              // uploadHandler={async (e) => {
              //   setIsLoadingIFTAFuelCardTable(true);
              //   await props.fuelCardUploadHandler(e.files[0]);
              //   e.options.clear();
              // }}
              accept={allowableFileTypes}
              maxFileSize={1000000}
              onUpload={(e) => onUpload(e)}
              onSelect={(e) => onSelect(e)}
              onError={() => onClear()}
              onClear={() => onClear()}
              headerTemplate={headerTemplate}
              itemTemplate={itemTemplate}
              emptyTemplate={emptyTemplate}
              chooseOptions={chooseOptions}
              uploadOptions={uploadOptions}
              cancelOptions={cancelOptions}
            />
          )}
          {showDataTable && (
            <>
              <CSVDataTable
                filesBreakdownArray={filesBreakdownArray}
                headerMap={headerMap}
                getUploadContent={(updatedHeaderMap, updatedFileBreakdown) => getUploadContent(updatedHeaderMap, updatedFileBreakdown)}
                isLoading={isLoading}
                miscHeaderName={miscHeaderName}
              />
              { props.options && <div className="w-100 mt-5">{ props.options }</div> }
            </>
          )}
          {showSuccessUpload && (
            <div className="mt-5 w-full flex flex-column justify-content-center align-items-center">
              <i className="pi pi-check-circle success-icon" />
              <span className="font-weight-bold mb-2" style={{ fontSize: '1.5rem' }}>Success!</span>
              { props.successMessage ? props.successMessage : currentStep.jsx }
            </div>
          )}
        </Dialog>
      )}
      <CSVUploaderButton
        className={props.buttonClassName}
        buttonLabel={buttonLabel}
        isLoading={isLoading}
        disabled={disabled}
        severity={props.buttonSeverity || 'primary'}
        toggleModalVisible={() => toggleFileUploadVisible()}
      />
    </div>
  );
}

export default CSVUploader;
