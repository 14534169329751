import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';

import { MDBInput } from 'mdbreact';

import './styles.scss';

class DateTimePicker extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { props } = this;
    let customItem = null;
    let inputContainerClassName = 'dtp-input-container';
    if (props.containerClassName) inputContainerClassName += ` ${props.containerClassName}`;
    if (props.textRight) inputContainerClassName += ` shift-right`;
    if (this.props.isRequired) inputContainerClassName += ' required';

    let inputClassName = 'dtp-input';
    if (props.inputClassName) inputClassName += ` ${props.inputClassName}`;

    if (this.props.label) {
      let labelClassName = props.labelClassName;
      customItem = <MDBInput type="text" className={inputClassName} containerClass={inputContainerClassName} label={this.props.label} labelClass={labelClassName}/>;
    } else {
      customItem = <MDBInput type="text" className={inputClassName} containerClass={inputContainerClassName}/>;
    }
    return (
      <ReactDatePicker
        dropdownMode="select"
        onChange={this.props.onDateChange}
        selected={this.props.selectedDate}
        showTimeSelect={this.props.showTimePicker}
        showTimeSelectOnly={this.props.showTimePickerOnly}
        dateFormat={this.props.dateFormat}
        timeFormat="HH:mm"
        timeIntervals={this.props.timeIntervals || 30}
        className={this.props.className}
        showYearDropdown={this.props.showYearDropdown}
        showMonthDropdown={this.props.showMonthDropdown}
        showMonthYearPicker={this.props.showMonthYearPicker}
        showFullMonthYearPicker={this.props.showFullMonthYearPicker}
        customInput={customItem}
        shouldCloseOnSelect={this.props.shouldCloseOnSelect}
        popperPlacement={props.popperPlacement}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        disabled={this.props.disabled}
        required={this.props.required}
      />
    );
  }
}

DateTimePicker.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  showTimePicker: PropTypes.bool,
  showTimePickerOnly: PropTypes.bool,
  timeIntervals: PropTypes.number,
  showYearDropdown: PropTypes.bool,
  showMonthDropdown: PropTypes.bool,
  dateFormat: PropTypes.string,
  shouldCloseOnSelect: PropTypes.bool,
  textRight: PropTypes.bool,
  popperPlacement: PropTypes.string,
  required: PropTypes.bool,
}

export default DateTimePicker;