// api
import { addRecord, find, createQuery, sortQuery, setQueryRestriction, updateRecord, getAttribute, createQueryOr } from 'api/Parse';
import { getCurrentUserCompany } from 'api/Getters';

// enums
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

// sbObjects
import Sort from 'sbObjects/Sort';

/** @module Geofence */

/**
 * @memberof module:Geofence
 * 
 * @param {number} page
 * @param {number} limit
 * @param {object[]} filters Array of Filter SBObjects
 * @param {object} sort Sort SBObject
 * @param {boolean} getAllGeofences Fetch all the geofences, ignoring pagination (default: false)
 * @param {boolean} showEnabledOnly Show only enabled geofences (default: true)
 * 
 * @returns {array} Array of Geofence records
 */
async function getGeofences(
  page = 0,
  limit = 15,
  filters = [],
  sort = new Sort('name', QuerySortOrderTypes.ASCENDING),
  getAllGeofences = false,
  showEnabledOnly = true,
) {
  const geofenceQuery = createQuery('Geofence');

  sortQuery(geofenceQuery, sort.order, sort.attribute);

  const belongsToCompany = getCurrentUserCompany();
  setQueryRestriction(geofenceQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);

  if (showEnabledOnly) {
    setQueryRestriction(geofenceQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'enabled', false);
  }
  filters.forEach(filter => {
    setQueryRestriction(geofenceQuery, filter.queryType || filter.queryRestriction, filter.attribute, filter.value);
  });
  if (!getAllGeofences) {
    setQueryRestriction(geofenceQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
    setQueryRestriction(geofenceQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  }
  return await find(geofenceQuery, false, getAllGeofences);
}

/**
 * @memberof module:Geofence
 * 
 * @param {object} geofence Geofence SBObject to add
 * 
 * @returns {object} Geofence record
 */
async function addGeofence(geofenceObject) {
  if (!geofenceObject) { throw new Error('The Geofence is required.'); }
  const belongsToCompany = getCurrentUserCompany();

  const _geofence = { ...geofenceObject };
  _geofence.show = true;
  _geofence.enabled = true;
  _geofence.belongsToCompany = belongsToCompany;
  return await addRecord('Geofence', _geofence);
}

/**
 * @memberof module:Geofence
 * 
 * @param {object} geofenceRecord Geofence record
 * @param {object} geofenceAttributes Object with key-value pairs to update
 * 
 * @returns {object} Updated Geofence record
 */
async function updateGeofence(geofenceRecord, geofenceAttributes) {
  if (!geofenceRecord) throw new Error('The Geofence is required.');
  if (!geofenceAttributes) return geofenceRecord;

  return await updateRecord(geofenceRecord, geofenceAttributes, true);
}

/**
 * @memberof module:Geofence
 * 
 * @param {object} geofence Geofence record to be removed
 * 
 * @returns {object} Updated Geofence record
 */
async function removeGeofence(geofenceRecord) {
  return await updateGeofence(geofenceRecord, { enabled: false });
}

/**
 * @memberof module:Geofence
 * 
 * @param {string} searchTerm Search string
 * @param {object} company Company record
 * 
 * @returns Parse Query on geofences belonging to `company`
 */
function getGeofenceQuery(searchTerm, company) {
  const geofenceQuery = createQuery('Geofence');
  if (searchTerm) geofenceQuery.matches('name', searchTerm, 'i');
  geofenceQuery.equalTo('belongsToCompany', company);
  return geofenceQuery;
}

/**
 * @memberof module:Geofence
 * 
 * @param {number} page
 * @param {number} limit
 * @param {string} searchTerm Search string
 * @param {boolean} considerChildCompanies
 * 
 * @returns {array} `limit` number of geofence records on page `page`
 */
async function getSuggestedGeofences(page = 0, limit = 15, searchTerm, considerChildCompanies) {
  const currentUserCompany = getCurrentUserCompany();

  const queries = [];
  queries.push(getGeofenceQuery(searchTerm, currentUserCompany));
  if (considerChildCompanies) {
    const childrenQuery = createQuery('CompanyLink');
    setQueryRestriction(childrenQuery, QueryRestrictionTypes.EQUAL_TO, 'parentCompany', currentUserCompany);
    const childCompanies = await find(childrenQuery);
    childCompanies.forEach(child => {
      queries.push(getGeofenceQuery(searchTerm, getAttribute(child, 'childCompany').id));
    });
  }
  const allGeofencesQuery = createQueryOr(queries);
  setQueryRestriction(allGeofencesQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
  setQueryRestriction(allGeofencesQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  const geofences = await find(allGeofencesQuery);
  return geofences;
}

export {
  getGeofences,
  removeGeofence,
  addGeofence,
  updateGeofence,
  getSuggestedGeofences,
};
