import uniqid from 'uniqid';
import React, { useState, useEffect } from 'react';

// Enums
import { Organization, OrganizationName } from 'sb-csapi/dist/enums/Dispatch/Organization';

// SBCore Components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Dropdown from 'sbCore/Dropdown/Dropdown';

// Style
import './style.scss';

/**
 * @description Select an organizaiton type
 *
 * @param {Organization} type - Sets the default org type from Organization enum.
 * @param {Array<Organization>} [includedTypes] - The types to include in the dropdown. When this is provided, only these statuses will be shown.
 * @param {Array<Organization>} [excludedTypes] - The types to exclude from the dropdown. When this is provided, these statuses will not be shown.
 *
 * @param {String} [className] - Custom container className
 * @param {Object} [style] - Custom inline styles
 * @param {bool} [pluralizeTypes] - In the dropdown, append 's' to the name of the type. Ex. Customer -> Customers
 * @param {bool} [warning] - Makes the border yellow
 * @param {bool} [hideLabel] - Hide the label
 * @param {bool} [disabled] - Whether disable the component
 * @param {Function} [onSelect] - A callback function that returns the selected type
 *
 * @returns {JSX.Element}
 */
function OrganizationTypeDropdown({ ...props }) {
  const [identifier] = useState(uniqid()); // Give it unique identifier for specific dom manipulation

  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(undefined);

  // ** Hooks Section ** //
  useEffect(() => {
    // These are the default statuses available if none are provided in includedELDEventTypeCodes or excludedELDEventTypeCodes
    let defaultTypes = [
      Organization.CARRIER,
      Organization.CONSIGNEE,
      Organization.CUSTOMER,
      Organization.SHIPPER,
    ];

    // If includedTypes is provided, use those types only
    if (props.includedTypes) {
      defaultTypes = props.includedTypes;
    }

    // If excludedTypes is provided, exclude those types from the entire list
    if (props.excludedTypes) {
      defaultTypes = Object.values(Organization).filter((type) => !props.excludedTypes.includes(type));
    }

    // Build the options for the dropdown
    const _types = Object.entries(Organization)
      .map(([key, value]) => ({
        label: props.pluralizeTypes ? `${OrganizationName[key]}s` : OrganizationName[key],
        value,
      }))
      .filter((type) => defaultTypes.includes(type.value));

    setTypes(_types);
    setSelectedType(props.type);
  }, []);

  useEffect(() => {
    setSelectedType(props.type);
  }, [props.type]);

  // ** Components Section ** //
  function onChange(e) {
    setSelectedType(e.value);
    if (props.onSelect) props.onSelect(e.value); // Send the chosen type back up to parent
  }

  // ** Misc ** //
  let className = `organization-type-dropdown ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && (
        <InputLabel>Organization Type</InputLabel>
      )}
      <Dropdown
        className={props.dropdownClassName}
        value={selectedType}
        options={types}
        onChange={(e) => onChange(e)}
        warning={props.warning}
        disabled={props.disabled}
        variant="inputsmall"
      />
    </div>
  );
}

export default OrganizationTypeDropdown;
