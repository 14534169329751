import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// sbCore Components
import InputLabel from 'sbCore/InputLabel/InputLabel';

// Components
import VehicleAutocompleteInput from 'sbCore/VehicleAutocomplete/VehicleAutocompleteInput';
import VehicleCard from 'sbCore/VehicleAutocomplete/VehicleCard';

// Styling
import './style.scss';

/**
 * @description Contains an input to filter vehicles belonging to a company, and the option to select one
 * @param {String} className - Custom container className
 * @param {String} [panelClassName] - Custom dropdown panel className
 * @param {Object} style - Custom inline styles
 * @param {bool} warning - Makes the autocomplete border yellow
 * @param {bool} autoFocus - Whether to automatically focus on the input on load
 * @param {bool} disabled - Whether to disable the input
 * @param {bool} isLoading - Whether to show a loading indicator
 * @param {bool} hideLabel - Whether to hide the label of the autocomplete
 *
 * @param {Array<Filter>} [suggestionFilters] - Array of filters to apply to the search suggestions
 * @returns
 *
 * @example
 * <VehicleAutocomplete warning />
 */
function VehicleAutocomplete({ ...props }) {

  // ** useStates ** //
  const [vehicle, setVehicle] = useState(undefined);
  const [identifier] = useState(uniqid()); // for each of this component that exists on the same page, give it unique identifier for specific dom manipulation
  const [isLoading, setIsLoading] = useState(false); // local version is isLoading (in addition to props.isLoading)

  // ** useEffects ** //
  useEffect(() => {
    setVehicle(props.vehicle);
  }, [props.vehicle]);

  // ** Callbacks ** //
  const onSelectVehicle = (vehicle) => {
    setVehicle(vehicle);

    // add check for vehicle to prevent accidental triggering of function
    if (vehicle) {
      setVehicle(vehicle);
      if (props.onSelectVehicle) props.onSelectVehicle(vehicle);
    }
  };

  const onUnselectVehicle = () => {
    setVehicle(undefined);

    // pass in undefined instead of vehicle (which is being set to undefined above) since the state might not be updated yet.
    // we do this over using a useEffect for convenience
    if (props.onSelectVehicle) props.onSelectVehicle(undefined);
  };

  // ** Components ** //
  const label = 'Vehicle';

  const vehicleAutocompleteField = (
    <>
      {!props.hideLabel && <InputLabel>{label}</InputLabel>}
      <VehicleAutocompleteInput
        panelClassName={props.panelClassName}
        autoFocus={props.autoFocus}
        warning={props.warning}
        disabled={props.disabled}
        onSelectVehicle={(vehicle) => onSelectVehicle(vehicle)}
        suggestionFilters={props.suggestionFilters || []}
      />
    </>
  );

  const vehicleCard = (
    <VehicleCard vehicle={vehicle} onUnselectVehicle={onUnselectVehicle} />
  );

  const loadingVehicleCard = (
    <VehicleCard isLoading={props.isLoading || isLoading} />
  );

  // ** Misc ** //
  let className = `vehicle-autocomplete ${identifier}`;
  if (props.className) className += ` ${props.className}`;


  return (
    <div className={className} style={{ ...props.style }}>

      {!props.isLoading && !vehicle && vehicleAutocompleteField}

      {!props.isLoading && vehicle && vehicleCard}

      {props.isLoading && loadingVehicleCard}

    </div>
  );

}

export default VehicleAutocomplete;
