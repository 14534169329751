import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// Components
import OdometerReadingsTable from 'components/IFTARoute/container/OdometerReadingsTable';
import SBTable from 'components/Shared/SBTable/SBTable';

// API
import * as IFTA from 'api/IFTA';
import { StateProvinces } from 'api/Lists/StateProvinces';
import * as Helpers from 'api/Helpers';

class TotalSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const totalStateProvinceMileagesObj = this.props.totalStateProvinceMileages;
    const totalStateProvinceMilesagesArr = Object.keys(totalStateProvinceMileagesObj).map((key) => ({
      stateProvince: key,
      vehicleKm: totalStateProvinceMileagesObj[key].vehicleKm,
      distanceKm: totalStateProvinceMileagesObj[key].distanceKm,
      spreadKm: totalStateProvinceMileagesObj[key].spreadKm
    }));

    let totalVehicleKm = 0;
    let totalDistanceKm = 0;
    let totalSpreadKm = 0;
    for (let i = 0; i < totalStateProvinceMilesagesArr.length; i++) {
      totalVehicleKm += totalStateProvinceMilesagesArr[i].vehicleKm;
      totalDistanceKm += totalStateProvinceMilesagesArr[i].distanceKm;
      totalSpreadKm += totalStateProvinceMilesagesArr[i].spreadKm;
    }

    const tableHeaderStyles = {
      stateProvince: { width: '50%' },
      mileage: { width: '50%' },
    };
    const tableHeaderRows = [{
      key: 'sb-jobs-list', columns: [
        {
          element: <div>State / Province</div>,
          props: {
            style: tableHeaderStyles.stateProvince,
          }
        },
        // getting rid of gps mileage
        // {
        //   element: <div>{`GPS Mileage (${this.props.distanceUnitFilter.value})`}</div>,
        //   props: {
        //     style: tableHeaderStyles.saved,
        //   }
        // },
        {
          element: <div>{`Calculated Mileage (${this.props.distanceUnitFilter.value})`}</div>,
          props: {
            style: tableHeaderStyles.saved,
          }
        },
      ]
    }];

    let tableBodyRows = totalStateProvinceMilesagesArr.filter((stateProvinceData) => { if (this.props.selectedFilter.value === 'hideZeroMileages') { return stateProvinceData.vehicleKm; } return true; }).map(stateProvinceData => {
      const vehicleRowObj = {
        key: stateProvinceData.stateProvince,
        columns: [],
        // props: { expandableContent }
      };
      vehicleRowObj.columns = [
        {
          element:
            <div>
              {stateProvinceData.stateProvince}
            </div>, props: { className: 'table-body-column-style' }
        },
        // getting rid of gps mileage
        // {
        //   element:
        //     <div>
        //       {stateProvinceData.distanceKm ? (this.props.distanceUnitFilter.value === 'km' ? stateProvinceData.distanceKm.toFixed(0) : Helpers.convertDistance(stateProvinceData.distanceKm, 'km', 'mi', true).toFixed(0)) : 0}
        //     </div>, props: { className: 'table-body-column-style' }
        // },
        {
          element:
            <div>
              {stateProvinceData.spreadKm ? (this.props.distanceUnitFilter.value === 'km' ? stateProvinceData.spreadKm.toFixed(0) : Helpers.convertDistance(stateProvinceData.spreadKm, 'km', 'mi', true).toFixed(0)) : 0}
            </div>, props: { className: 'table-body-column-style' }
        },
      ];

      return vehicleRowObj;
    });

    tableBodyRows.push({
      key: 'total',
      columns: [
        {
          element:
            <div>
              <b>Total</b>
            </div>, props: { className: 'table-body-column-style' }
        },
        // getting rid of gps mileage
        // {
        //   element:
        //     <div>
        //       <b>{this.props.distanceUnitFilter.value === 'km' ? totalDistanceKm.toFixed(0) : Helpers.convertDistance(totalDistanceKm, 'km', 'mi', true).toFixed(0)}</b>
        //     </div>, props: { className: 'table-body-column-style' }
        // },
        {
          element:
            <div>
              <b>{this.props.distanceUnitFilter.value === 'km' ? totalSpreadKm.toFixed(0) : Helpers.convertDistance(totalSpreadKm, 'km', 'mi', true).toFixed(0)}</b>
            </div>, props: { className: 'table-body-column-style' }
        },
      ],
      // props: { expandableContent }
    });



    return (
      <div className='vehicleSummary-printable'>
        <div><h5>
          Fleet Total IFTA Mileages
          <br />
          {moment(this.props.dateStart).format('LL')} - {moment(this.props.dateEnd).format('LL')}
        </h5></div>
        <SBTable
          hover
          tableHeaderRows={tableHeaderRows}
          tableBodyRows={tableBodyRows}
          hideZ
          isLoading={this.props.isLoading}
        />
      </div>
    );
  }

};

TotalSummary.propTypes = {
  distanceUnitFilter: PropTypes.object,
  odometerReadings: PropTypes.object,
  selectedFilter: PropTypes.object,
  unitId: PropTypes.string,
  distanceUnit: PropTypes.string,
  dateStart: PropTypes.object,
  dateEnd: PropTypes.object,
  iftaRouteArr: PropTypes.array,
  isLoading: PropTypes.bool,
};

export default TotalSummary;
