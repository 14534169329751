import Parse from 'parse';
import PDFJS from 'pdfjs-dist';
import 'pdfjs-dist/build/pdf.worker'; // need to import worker since pdfjs auto-import worker code is sketch

import * as ParseAPI from 'api/Parse';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import * as Setters from 'api/Setters';
import * as PDFer from 'api/PDFer';

/** @module CarrierProfile */

// WARNING: All functions here are Guesstimate functions

/**
 * @memberof module:CarrierProfile
 * 
 * @returns 
 */
function getRecentCarrierProfile() {
  // using the company object id, obtain the most recent carrier profile and associated reports
  const promise = new Promise((resolve, reject) => {
    const companyQuery = new Parse.Query('Company');
    companyQuery.equalTo('objectId', Parse.User.current().get('belongsToCompany').id);
    const carrierProfileQuery = new Parse.Query('CarrierProfile');
    carrierProfileQuery.matchesQuery('belongsToCompany', companyQuery);
    carrierProfileQuery.include(['belongsToCompany', 'uploadedBy']);
    carrierProfileQuery.descending('createdAt');
    carrierProfileQuery.first().then(
      carrierProfileObject => {
        const contraventionQuery = new Parse.Query('Contravention');
        contraventionQuery.equalTo('carrierProfile', carrierProfileObject);

        const accidentQuery = new Parse.Query('Accident');
        accidentQuery.equalTo('carrierProfile', carrierProfileObject);

        const vehicleInspectionQuery = new Parse.Query('VehicleInspection');
        vehicleInspectionQuery.equalTo('carrierProfile', carrierProfileObject);

        const queryArr = [contraventionQuery.find(), accidentQuery.find(), vehicleInspectionQuery.find()];
        Promise.all(queryArr).then(carrierProfileReportObjects => {
          const contraventions = carrierProfileReportObjects[0];
          const driverContraventions = [];
          const carrierContraventions = [];
          const driverAccidents = carrierProfileReportObjects[1];
          const vehicleInspections = carrierProfileReportObjects[2];

          for (let i = 0; i < contraventions.length; i++) {
            const contravention = contraventions[i];
            if (contravention.get('contraventionType_type') === 'driver') {
              driverContraventions.push(contravention);
            } else {
              carrierContraventions.push(contravention);
            }
          }

          const carrierProfileReport = {
            carrierProfileObject,
            driverContraventions,
            carrierContraventions,
            driverAccidents,
            vehicleInspections,
          };
          resolve(carrierProfileReport);
        });
      },
      error => reject(error)
    );
  });
  return promise;
}

/**
 * @memberof module:CarrierProfile
 * 
 * @param {*} pdfText 
 * @param {*} carrierProfileObject 
 * 
 * @returns 
 */
function setDriverAccidentsInformation(pdfText, carrierProfileObject) {
  // function to get and set driver accidents info from carrier profile text
  function getInfractionIndexes(accidentsTableArr) {
    // function to get the indexes where each accidents object should begin parsing in the array
    // for each sequence that begins in the order accident date -> time, create a new row representing an accident
    const indexArr = [];
    const accidentsTableArrLen = accidentsTableArr.length;
    for (var i = 0; i < accidentsTableArrLen; i++) {
      const currentWord = accidentsTableArr[i];
      if (currentWord.toLowerCase() === 'accident') {
        // if the first index after it is 'date' and the second or third index after is 'time'
        if (accidentsTableArr[i+1].toLowerCase() === 'date' && (accidentsTableArr[i+2].toLowerCase() === 'time' || accidentsTableArr[i+3].toLowerCase() === 'time')) {
          indexArr.push(i);
        }
      }
    }
    return indexArr;
  }

  function createAccidentObjects(objectIndexes, accidentsTableArr) {
    /*
     * using the accidentsTableArr and indices where each object supposedly starts and ends
     * construct each accident object
     * we want the accident date, time, report#, location, jurisdiction, type, fault, active points,
     * driver name, dl#/jur, plate/jur, regi #, vehicle desc, charges laid
     * for each of the starting indexes, do the deed
    */

    const accidentObjects = [];
    let counter = 0;
    while (counter < objectIndexes.length) {
      const newAccidentObject = {};
      const currentObjectIndex = objectIndexes[counter];
      let terminateIndex = objectIndexes[counter + 1]; // terminate at the next object index
      if (counter === objectIndexes.length - 1) {
        // or if this is the last index, we need to use .length as the terminus
        terminateIndex = accidentsTableArr.length;
      }

      let j = 0; // re-usable iterator
      let error = -1; // index error counting
      for (let i = currentObjectIndex; i < terminateIndex; i++) {
        // do the deed
        const currentElement = accidentsTableArr[i];

        if (/([a-zA-Z]|\d)*(-|\/)([a-zA-Z]|\d)*(-|\/)([a-zA-Z]|\d)*/.test(currentElement)) {
          // if this is a date-convertible format ?? !isNaN((new Date(currentElement)).getTime())
          const date = currentElement;

          // this should be a time (d)d:dd
          const time = accidentsTableArr[i+1];

          // combine the date and time
          newAccidentObject.dateTime = new Date(`${date.trim()} ${time.trim()}`);

          // the report number should be immediately after the time field
          newAccidentObject.reportNumber = accidentsTableArr[i+2];

          // location should be after report number
          let location = '';
          for (j = i + 3; j < terminateIndex; j++) {
            const isJurisdiction = /([A-Z]{2}|[a-z]{2})/.test(accidentsTableArr[j]);
            if (isJurisdiction && accidentsTableArr[j].length === 2) {
              // until we hit the jurisdiction, this is the name of the location
              j = 0;
              i+=error;
              break;
            }
            location = location + ' ' + accidentsTableArr[j];
            error++; // account for error
          }
          newAccidentObject.location = location.trim();

          // jurisdiction should be after location
          newAccidentObject.jurisdiction = accidentsTableArr[i+4];

          // type should be after jurisdiction
          newAccidentObject.type = accidentsTableArr[i+5];

          // fault status should be after jur
          if (accidentsTableArr[i+6].toLowerCase().indexOf('no') > -1 || accidentsTableArr[i+6].toLowerCase().indexOf('not') > -1) {
            if ((accidentsTableArr[i+7].toLowerCase().indexOf('at') > -1 && accidentsTableArr[i+8].toLowerCase().indexOf('fault') > -1) || accidentsTableArr[i+7].toLowerCase().indexOf('fault') > -1) {
              newAccidentObject.fault = 'Not at Fault';
            }
          }
          else if ((accidentsTableArr[i+6].toLowerCase().indexOf('at') > -1 && accidentsTableArr[i+7].toLowerCase().indexOf('fault') > -1) || (accidentsTableArr[i+6].toLowerCase().indexOf('fault') > -1 && accidentsTableArr[i+5] === newAccidentObject.type)) {
              newAccidentObject.fault = 'At Fault';
          } else if (((accidentsTableArr[i+6].toLowerCase().indexOf('fault') > -1 && accidentsTableArr[i+7].toLowerCase().indexOf('unknown') > -1)) || (accidentsTableArr[i+6].toLowerCase().indexOf('unknown') > -1 && accidentsTableArr[i+7].toLowerCase().indexOf('fault') > -1)) {
              newAccidentObject.fault = 'Fault Unknown';
          }
        }
        else if (currentElement.toLowerCase() === 'driver' && accidentsTableArr[i+1].toLowerCase() === 'name') {
          // if the field is driver name (then the field before it must either be active points if available, or fault type)
          if (/\d*\.*\d+/.test(accidentsTableArr[i-1])) {
            newAccidentObject.activePoints = accidentsTableArr[i-1];
          }
        }
        else if (currentElement.toLowerCase() === 'plate/jur' || (currentElement + accidentsTableArr[i+1]).toLowerCase() === 'plate/jur') {
          // using the plate/jur identifier, we can check for the DRIVER license, DRIVER license jurisdiction
          // and driver name more accurately
          // the attribute before should be the plate jur, and before that the plate, and before that the driver name
          const driversLicenseJurisdiction = accidentsTableArr[i-1];
          newAccidentObject.driversLicenseJurisdiction = driversLicenseJurisdiction;
          newAccidentObject.driver_driversLicenseJurisdiction = driversLicenseJurisdiction.toLowerCase();

          const driversLicense = accidentsTableArr[i-2];
          newAccidentObject.driversLicense = driversLicense;
          newAccidentObject.driver_driversLicense = driversLicense.toLowerCase();

          let driverName = '';
          for (j = i-3; accidentsTableArr[j].toLowerCase() !== 'dl#/jur'; j--) {
            let namePart = accidentsTableArr[j].toLowerCase();
            namePart = namePart[0].toUpperCase() + namePart.substring(1);
            driverName = driverName + ' ' + namePart; // Alex Matthew George Li,
            driverName = driverName.trim();
          }
          j = 0;
          driverName = driverName.split(' ').reverse(); // [Li,,George,Matthew,Alex]
          driverName = driverName.join(' ') // Li, George Matthew Alex
                                 .split(', ') // [Li, George Matthew Alex]
                                 .reverse()
                                 .join(' '); // George Matthew Alex Li
          newAccidentObject.fullName = driverName;
          newAccidentObject.user_fullName = driverName.toLowerCase();

          // let nameIterator = i-3;
          // const nameArr = [];
          // while (accidentsTableArr[nameIterator].toLowerCase() !== 'dl#/jur') {
          //   nameArr.push(accidentsTableArr[nameIterator]);
          //   nameIterator = nameIterator - 1;
          // }

          // let name = nameArr.join(' ').toLowerCase().split(' ').reverse(); // li, george matthew
          // for (let i = 0; i < name.length; i++) {
          //   name[i] = name[i][0].toUpperCase() + name[i].slice(1, name[i].length);
          // }
          // name = name.join(' ') // Li, George Matthew
          //            .split(', ')
          //            .reverse()
          //            .join(' '); // George Matthew Li
          // newAccidentObject.user_fullName = name;
        }
        else if (currentElement.toLowerCase() === 'charges' && accidentsTableArr[i+1].toLowerCase() === 'laid') {
          // plate info and registration num succeeds 'charges laid'
          const vehiclePlate = accidentsTableArr[i+2];
          newAccidentObject.plate = vehiclePlate;
          newAccidentObject.vehicle_plate = vehiclePlate.toLowerCase();

          const plateJurisdiction = accidentsTableArr[i+3];
          newAccidentObject.plateJurisdiction = plateJurisdiction;
          newAccidentObject.vehicle_plateJurisdiction = plateJurisdiction.toLowerCase();

          const registrationNumber = accidentsTableArr[i+4];
          newAccidentObject.registrationNumber = registrationNumber;
        }
        else if (i === terminateIndex - 1) {
          // everything between the terminus index and 'Charges Laid' contains the plate info (as above, so we should index
          // it out), the vehicle desc, and charges laid
          const descriptionArr = [];
          let descriptionIterator = i;

          while (accidentsTableArr[descriptionIterator-1].toLowerCase() !== 'charges' && accidentsTableArr[descriptionIterator].toLowerCase() !== 'laid') {
            descriptionArr.unshift(accidentsTableArr[descriptionIterator]);
            descriptionIterator = descriptionIterator - 1;
          }

          if (terminateIndex === accidentsTableArr.length) {
            // if we're dealing with the end of the accidents table text, remove the trailing strings produced by the initial
            // regex
            descriptionArr.splice(-5, 5);
          }

          // now get rid of the first 3 elements (which correspond to plate, plateJur, regi#) to get only the description and
          // charges laid
          descriptionArr.splice(0, 3);

          let description = '';
          for (let i = 0; i < descriptionArr.length - 1; i++) { // descriptionArr.length - 1 to cut off the chargesLaid portion
            description = description + ' ' + descriptionArr[i];
          }
          newAccidentObject.vehicleDescription = description.trim();
          newAccidentObject.vehicle_description = description.toLowerCase().trim();
          newAccidentObject.chargesLaid = descriptionArr[descriptionArr.length - 1];

        }

        newAccidentObject.carrierProfile = carrierProfileObject;
        newAccidentObject.resolved = false;
      }

      accidentObjects.push(newAccidentObject);
      counter++;
    }

    // console.log(accidentObjects);
    return accidentObjects;
  }

  const promise = new Promise((resolve, reject) => {
    const accidentsTableRegex = /This\s*section\s*lists\s*all\s*police-reported\s*accidents\s*involving\s*commercial\s*vehicles\s*operating\s*under\s*this\s*safety\s*certificate(.*)Section\s*7/gim;
    let accidentsTableText = pdfText.match(accidentsTableRegex)[0];
    accidentsTableText = accidentsTableText.match(/(?:Details\s*)Accident(.*)(?:Section\s*6)/gim)[0];

    accidentsTableText = accidentsTableText.replace(/\s+-\s+/g, '-') // combine hyphen-separated values
                                           .replace(/\s+/g, ' '); // replace multiple spaces (1+) with normal space

    // now that we have a string of accident table values, store each 'word'
    const accidentsTableArrSplit = accidentsTableText.split('Enforcement')[0]; // first cut off the string even more
    const accidentsTableArr = accidentsTableArrSplit.split(' ');
    const accidentObjectIndexes = getInfractionIndexes(accidentsTableArr);

    // now that we have the supposed indexes where the objects begin (and end), attempt to create them
    const accidentObjects = createAccidentObjects(accidentObjectIndexes, accidentsTableArr);
    // console.log(accidentObjects);

    // save all objects in db
    const promisesArr = [];
    for (let i = 0; i < accidentObjects.length; i++) {
      promisesArr.push(Setters.addRecord('Accident', accidentObjects[i], Helpers.getCompanyReadWriteACL()));
    }
    Promise.all(promisesArr).then(parseAccidentObjects => {
      resolve(parseAccidentObjects);
    });
  });

  return promise;
}

/**
 * @memberof module:CarrierProfile
 * 
 * @param {*} pdfText 
 * @param {*} carrierProfileObject 
 * @param {*} type 
 * 
 * @returns 
 */
function setContraventionsInformation(pdfText, carrierProfileObject, type) {
  // function to get and set driver accidents info from carrier profile text
  // type can be 'driver' or 'carrier'
  // this function is most likely to break if there are multiple acts per contravention
  // cannot predict this behaviour until it actually arises, so we make the assumption this case does not exist
  function getInfractionIndexes(contraventionsTableArr) {
    // function to get the indexes where each accidents object should begin parsing in the array
    // for each sequence that begins in the order accident date -> time, create a new row representing an accident
    const indexArr = [];
    const contraventionsTableArrLen = contraventionsTableArr.length;
    for (var i = 0; i < contraventionsTableArrLen; i++) {
      const currentWord = contraventionsTableArr[i];
      if (currentWord.toLowerCase() === 'violation') {
        // if the first index after it is 'date' and the second or third index after is 'time'
        if (contraventionsTableArr[i+1].toLowerCase() === 'date' && (contraventionsTableArr[i+2].toLowerCase() === 'time' || contraventionsTableArr[i+3].toLowerCase() === 'ticket')) {
          indexArr.push(i);
        }
      }
    }
    return indexArr;
  }

  function createContraventionObjects(objectIndexes, contraventionsTableArr, type) {
    /*
     * using the contraventionsTableArr and indices where each object supposedly starts and ends
     * construct each contravention object
     * we want the date, time, ticketNumber, truck_plate, truck_plateJurisdiction, location, jurisdiction,
     * dispositionDate, act, section, description, equivalencyCode_type, activePoints, contraventionType_type (independent)
     * for each of the starting indexes, do the deed
    */

    const contraventionObjects = [];
    let counter = 0;

    while (counter < objectIndexes.length) {
      const newContraventionObject = {};
      const currentObjectIndex = objectIndexes[counter];
      let terminateIndex = objectIndexes[counter + 1]
      if (counter === objectIndexes.length - 1) {
        terminateIndex = contraventionsTableArr.length;
      }

      let j = 0; // re-usable iterator
      let error = -1; // index error counting
      for (let i = currentObjectIndex; i < terminateIndex; i++) {
        // do da deed
        const currentElement = contraventionsTableArr[i];

        if (/([a-zA-Z]|\d)*(-|\/)([a-zA-Z]|\d)*(-|\/)([a-zA-Z]|\d)*/.test(currentElement) && contraventionsTableArr[i-2].toLowerCase() === 'disposition') {
          // if this is a date-convertible format ?? !isNaN((new Date(currentElement)).getTime())
          const date = currentElement;

          // this should be a time (d)d:dd
          const time = contraventionsTableArr[i+1];

          // combine the date and time
          newContraventionObject.dateTime = new Date(`${date.trim()} ${time.trim()}`);

          // the ticket number should be immediately after the time field
          newContraventionObject.ticketNumber = contraventionsTableArr[i+2];

          const plate = contraventionsTableArr[i+3];
          newContraventionObject.plate = plate;
          newContraventionObject.vehicle_plate = plate.toLowerCase();

          const plateJurisdiction = contraventionsTableArr[i+4];
          newContraventionObject.plateJurisdiction = plateJurisdiction;
          newContraventionObject.vehicle_plateJurisdiction = plateJurisdiction.toLowerCase();

          // location should be after report number
          let location = '';
          for (j = i + 5; j < terminateIndex; j++) {
            const isJurisdiction = /([A-Z]{2}|[a-z]{2})/.test(contraventionsTableArr[j]);
            if (isJurisdiction && contraventionsTableArr[j].length === 2) {
              // until we hit the jurisdiction, this is the name of the location
              j = 0;
              i+=error;
              break;
            }
            location = location + ' ' + contraventionsTableArr[j];
            error++; // account for error
          }
          newContraventionObject.location = location.trim();

          newContraventionObject.jurisdiction = contraventionsTableArr[i+6];

          // ensure time value is added so javascript doesn't guess and convert the date based on our current date/location
          newContraventionObject.dispositionDate = new Date(`${contraventionsTableArr[i+7]} 00:00`);

          // the next elements should be: Act, Section, Desc, <Equiv, Code>, <Active, Points> -> (next row of categories)
          // so skip these (6) elements
          newContraventionObject.act = contraventionsTableArr[i+15];

          newContraventionObject.section = contraventionsTableArr[i+16];

          // figure out the description, equiv code and disposition date now that we know where the section is
          const descriptionArr = [];
          for (j = i+17; j < terminateIndex; j++) {
            const element = contraventionsTableArr[j];
            const activePointsTestLen1 = /\d/.test(element);
            const activePointsTestLen2 = /\d{2}/.test(element);
            const isActivePoints = (activePointsTestLen1 && element.length === 1) || (activePointsTestLen2 && element.length === 2);
            // part of the regex that got included but isn't part of our data
            const isRegexDangling = element.toLowerCase() === 'commercial' && contraventionsTableArr[j+1].toLowerCase() === 'vehicle' && contraventionsTableArr[j+2].toLowerCase() === 'safety' && contraventionsTableArr[j+3].toLowerCase() === 'and' && contraventionsTableArr[j+4].toLowerCase() === 'enforcement';

            if (isRegexDangling) {
              break;
            }
            else if (/\d{4}/.test(element) && element.length === 4) {
              // this should be the equiv code (4 digit length) followed by 1-2 digit num (score), 'violation' or 'commercial'
              if (contraventionsTableArr[j+1].toLowerCase() === 'violation' || contraventionsTableArr[j+1].toLowerCase() === 'commercial' || (parseInt(contraventionsTableArr[j+1]) && contraventionsTableArr[j+1].length === 1) || (parseInt(contraventionsTableArr[j+1]) && contraventionsTableArr[j+1].length === 2) || (contraventionsTable[j+1] === '')) {
                newContraventionObject.equivCode = element;
              }
            }
            else if (isActivePoints) {
              newContraventionObject.activePoints = element;
            }
            else {
              descriptionArr.push(element);
            }

          }
          j = 0;

          newContraventionObject.description = descriptionArr.join(' ');

          newContraventionObject.contraventionType_type = type;
        }
      }
      newContraventionObject.carrierProfile = carrierProfileObject;
      newContraventionObject.resolved = false;
      contraventionObjects.push(newContraventionObject);
      counter++;
    }
    return contraventionObjects;
  }

  const promise = new Promise((resolve, reject) => {
    let contraventionsTableRegex; // regex depends on whether we're looking for driver or carrier. all else is same
    if (type === 'driver') {
      contraventionsTableRegex = /Section\s+4\.1\s+-\s+Driver\s+Contraventions\s+\(Guilty\)\s+This\s+section\s+lists(.*)Section\s+4.2/gim;
    } else {
     contraventionsTableRegex = /Section\s+4\.2\s+-\s+Carrier\s+Contraventions\s+\(Guilty\)\s+This\s+section\s+lists(.*)Section\s+4.3/gim;
    }

    let contraventionsTableText = pdfText.match(contraventionsTableRegex)[0];
    contraventionsTableText = contraventionsTableText.replace(/\s+-\s+/g, '-') // combine hyphen-separated values
                                                     .replace(/\s+/g, ' '); // replace multiple spaces (1+) with normal space

    // trim down table text even more
    contraventionsTableText = contraventionsTableText.match(/scores\.(\s*|\t*)Details(.*)/gim)[0];

    const contraventionsTableArr = contraventionsTableText.split(' ');
    const contraventionObjectIndexes = getInfractionIndexes(contraventionsTableArr);

    // now that we have the supposed indexes where the objects begin (and end), attempt to create them
    const contraventionObjects = createContraventionObjects(contraventionObjectIndexes, contraventionsTableArr, type);
    // console.log(contraventionObjects);

    // save all objects in db
    const promisesArr = [];
    for (let i = 0; i < contraventionObjects.length; i++) {
      promisesArr.push(Setters.addRecord('Contravention', contraventionObjects[i], Helpers.getCompanyReadWriteACL()));
    }
    Promise.all(promisesArr).then(parseContraventionObjects => {
      resolve(parseContraventionObjects);
    });
  });
  return promise;
}

/**
 * @memberof module:CarrierProfile
 * 
 * @param {*} pdfText 
 * @param {*} carrierProfileObject 
 * 
 * @returns 
 */
function setCVSAInformation(pdfText, carrierProfileObject) {
  // function to get and set cvsa inspection result info from carrier profile text

  function getInfractionIndexes(cvsaTableArr) {
    // function to get the indexes where each accidents object should begin parsing in the array
    // for each sequence that begins in the order accident date -> time, create a new row representing an accident
    const indexArr = [];
    const cvsaTableArrLen = cvsaTableArr.length;
    for (var i = 0; i < cvsaTableArrLen; i++) {
      const currentWord = cvsaTableArr[i];
      if (currentWord.toLowerCase() === 'inspection') {
        // if the first index after it is 'date' and the second or third index after is 'time'
        if (cvsaTableArr[i+1].toLowerCase() === 'date' && (cvsaTableArr[i+2].toLowerCase() === 'time' || cvsaTableArr[i+3].toLowerCase() === 'document')) {
          indexArr.push(i);
        }
      }
    }
    return indexArr;
  }

  function isResult(str) {
    // determines if the given string is a Result
    return str.toLowerCase() === 'fail' || str.toLowerCase() === 'oos' || str.toLowerCase() === 'pass';
  }

  function isItemDefectNum(str) {
    // guesstimate checks if a string is of the item defect number format
    // we use a function here because regex does not combine well with conditional statements
    const test1 = /\d+/.test(str.substring(0,2));
    const test2 = /\d+/.test(str.substring(str.length-2));
    return (test1 && test2);
  }

  function createCVSAObjects(objectIndexes, cvsaTableArr) {
    /*
     * using the cvsaTableArr and indices where each object supposedly starts and ends
     * construct each cvsa inspection object
     * we want the Inspection date, time, document #, location, jur, level, result, active points, user_fullName (driver name),
     * drivers license, drivers license jur
     * Then for each unit inspected, vehicle, plate/jur, registration #, vechicle desc, result, inspection item defect
     * for each of the starting indexes, do the deed
    */

    const cvsaObjects = [];
    let counter = 0;

    while (counter < objectIndexes.length) {
      const newCVSAObject = {};
      const currentObjectIndex = objectIndexes[counter];
      let terminateIndex = objectIndexes[counter + 1]
      if (counter === objectIndexes.length - 1) {
        terminateIndex = cvsaTableArr.length;
      }

      for (let i = currentObjectIndex; i < terminateIndex; i++) {
        // do da deed
        const currentElement = cvsaTableArr[i];
        let error = 0; // account for errors (ex. Richmond vs New Westminster)
        let j; // re-usable iterator

        if (/([a-zA-Z]|\d)*(-|\/)([a-zA-Z]|\d)*(-|\/)([a-zA-Z]|\d)*/.test(currentElement) && cvsaTableArr[i-1].toLowerCase() === 'points') {
          // if this is a date-convertible format ?? !isNaN((new Date(currentElement)).getTime())
          const date = currentElement;

          // this should be a time (d)d:dd
          const time = cvsaTableArr[i+1];

          // combine the date and time
          newCVSAObject.dateTime = new Date(`${date.trim()} ${time.trim()}`);

          // the document number should be immediately after the time field
          newCVSAObject.documentNumber = cvsaTableArr[i+2];

          let location = '';
          for (j = i + 3; j < terminateIndex; j++) {
            const isJurisdiction = /([A-Z]{2}|[a-z]{2})/.test(cvsaTableArr[j]);
            if (isJurisdiction && cvsaTableArr[j].length === 2) {
              // until we hit the jurisdiction, this is the name of the location
              j = 0;
              break;
            }
            location = location + ' ' + cvsaTableArr[j];
            error++; // account for error
          }
          newCVSAObject.location = location.trim();

          i+=error;
          newCVSAObject.jurisdiction = cvsaTableArr[i+3];

          newCVSAObject.type = cvsaTableArr[i+4];

          const result = cvsaTableArr[i+5];
          newCVSAObject.result = result;
          newCVSAObject.cvsaResult_type = result.toLowerCase();

          const activePoints = cvsaTableArr[i+6];
          const isActivePoints = /\d{1,2}/.test(activePoints);
          if ((isActivePoints && activePoints.length === 1) || (isActivePoints && activePoints.length === 2)) {
            newCVSAObject.activePoints = activePoints;
          }
        }
        else if (cvsaTableArr[i].toLowerCase() === 'vehicle' && cvsaTableArr[i+1].toLowerCase() === 'plate/jur') {
          // getting the driver name, license number, and license jurisdiction
          const driversLicenseJurisdiction = cvsaTableArr[i-1];
          newCVSAObject.driversLicenseJurisdiction = driversLicenseJurisdiction;
          newCVSAObject.driver_driversLicenseJurisdiction = driversLicenseJurisdiction.toLowerCase();

          const driversLicense = cvsaTableArr[i-2];
          newCVSAObject.driversLicense = driversLicense;
          newCVSAObject.driver_driversLicense = driversLicense.toLowerCase();

          let driverName = '';
          for (j = i-3; cvsaTableArr[j].toLowerCase() !== 'dl#/jur'; j--) {
            let namePart = cvsaTableArr[j].toLowerCase();
            namePart = namePart[0].toUpperCase() + namePart.substring(1);
            driverName = driverName + ' ' + namePart; // Alex Matthew George Li,
            driverName = driverName.trim();
          }
          j = 0;
          driverName = driverName.split(' ').reverse(); // [Li,,George,Matthew,Alex]
          driverName = driverName.join(' ') // Li, George Matthew Alex
                                 .split(', ') // [Li, George Matthew Alex]
                                 .reverse()
                                 .join(' '); // George Matthew Alex Li
          newCVSAObject.fullName = driverName;
          newCVSAObject.user_fullName = driverName.toLowerCase();

          newCVSAObject.carrierProfile = carrierProfileObject;
          newCVSAObject.resolved = false;
        }
        else if (currentElement.toLowerCase() === 'inspection' && cvsaTableArr[i+1].toLowerCase() === 'item' && cvsaTableArr[i+2].toLowerCase() === 'defect') {
          // here we check each vehicle inspected, put that unit in an object, and insert into cvsaObjects
          // remember each inspection can have multiple units inspected

          // we begin by checking for the Result as our anchor
          for (j = i+3; j < terminateIndex; j++) {
            if (isResult(cvsaTableArr[j]) && !(isResult(cvsaTableArr[j-1]) || isResult(cvsaTableArr[j-2]))) {

              const vehicleItems = [];
              const firstVehicleItem = {};
              firstVehicleItem.vehicleResult = cvsaTableArr[j];
              firstVehicleItem.cvsaResult_type_vehicleResult = cvsaTableArr[j].toLowerCase();

              // reverse search from the Result back to where it says 'defect' or a previous Result which is our terminus
              for (let k = j; k > i+2; k--) {
                if (isResult(cvsaTableArr[k-1]) || isResult(cvsaTableArr[k-2])) {
                  break;
                }
                if (/([A-Z]{2}|[a-z]{2})/.test(cvsaTableArr[k]) && cvsaTableArr[k].length === 2) {
                  firstVehicleItem.plateJurisdiction = cvsaTableArr[k];
                  firstVehicleItem.vehicle_plateJurisdiction = cvsaTableArr[k].toLowerCase();
                  firstVehicleItem.plate = cvsaTableArr[k-1];
                  firstVehicleItem.vehicle_plate = cvsaTableArr[k-1].toLowerCase();
                  firstVehicleItem.registrationNumber = cvsaTableArr[k+1];
                  firstVehicleItem.vehicle_registrationNumber = cvsaTableArr[k+1].toLowerCase();

                  // everything after registration number but before result is the vehicle description
                  const vehicleDescriptionArr = [];
                  let descIndex = k+2;
                  while (!isResult(cvsaTableArr[descIndex])) {
                    vehicleDescriptionArr.push(cvsaTableArr[descIndex]);
                    descIndex++;
                  }
                  firstVehicleItem.vehicleDescription = vehicleDescriptionArr.join(' ');
                }
              }

              if (isItemDefectNum(cvsaTableArr[j + 1])) {
                firstVehicleItem.inspectionItemDefect = cvsaTableArr[j + 1];
              }
              vehicleItems.push(firstVehicleItem);

              // if the next result is actually a part of the first (same vehicle). (We only account for max 2 instances)
              if (isResult(cvsaTableArr[j + 1].toLowerCase())) {
                const secondVehicleItem = { ...firstVehicleItem };
                secondVehicleItem.vehicleResult = cvsaTableArr[j + 1];
                secondVehicleItem.cvsaResult_type_vehicleResult = cvsaTableArr[j + 1].toLowerCase();
                if (isItemDefectNum(cvsaTableArr[j + 2])) {
                  secondVehicleItem.inspectionItemDefect = cvsaTableArr[j + 2];
                }
                vehicleItems.push(secondVehicleItem);
              }
              if (isResult(cvsaTableArr[j + 2].toLowerCase())) {
                const secondVehicleItem = { ...firstVehicleItem };
                secondVehicleItem.vehicleResult = cvsaTableArr[j + 2];
                secondVehicleItem.cvsaResult_type_vehicleResult = cvsaTableArr[j + 2].toLowerCase();
                if (isItemDefectNum(cvsaTableArr[j + 3])) {
                  secondVehicleItem.inspectionItemDefect = cvsaTableArr[j + 3];
                }
                vehicleItems.push(secondVehicleItem);
              }

              for (let l = 0; l < vehicleItems.length; l++) {
                const combinedCVSAItem = Object.assign({}, newCVSAObject, vehicleItems[l]);
                // console.log(combinedCVSAItem);
                cvsaObjects.push(combinedCVSAItem);
              }
            }
          }
          j = 0;

        }
      }

      counter++;
    }
    // console.log(cvsaObjects);
    return cvsaObjects;
  }

  const promise = new Promise((resolve, reject) => {
    let cvsaTableRegex = /Section\s+5\s+-\s+CVSA\s+Inspection\s+Results\s+This\s+section\s+lists(.*)Section\s+6/gim;

    let cvsaTableText = pdfText.match(cvsaTableRegex)[0];
    cvsaTableText = cvsaTableText.replace(/\s+-\s+/g, '-') // combine hyphen-separated values
                                 .replace(/\s+/g, ' '); // replace multiple spaces (1+) with normal space

    // trim down table text even more
    cvsaTableText = cvsaTableText.match(/Inspection\s*Date\s*Time(.*)Section\s*6.*Accident\s*Information/im)[0];

    const cvsaTableArr = cvsaTableText.split(' ');
    const cvsaObjectIndexes = getInfractionIndexes(cvsaTableArr);

    // now that we have the supposed indexes where the objects begin (and end), attempt to create them
    const cvsaObjects = createCVSAObjects(cvsaObjectIndexes, cvsaTableArr);

    // save all objects in db
    const promisesArr = [];
    for (let i = 0; i < cvsaObjects.length; i++) {
      promisesArr.push(Setters.addRecord('VehicleInspection', cvsaObjects[i], Helpers.getCompanyReadWriteACL()));
    }
    Promise.all(promisesArr).then(parseCVSAObjects => {
      resolve(parseCVSAObjects);
    });

    // console.log(cvsaTableArr);
    // console.log(cvsaObjectIndexes);
  });
  return promise;
}

/**
 * @memberof module:CarrierProfile
 * 
 * @param {*} file 
 * 
 * @returns 
 */
function uploadToCarrierProfile(file) {
  const currentUser = Parse.User.current();
  const newFileName = 'Carrier_Profile.pdf';

  const promise = new Promise((resolve, reject) => {
    if (/\.(jpe?g|png)$/i.test(file.name)) {
      Helpers.scaleAndConvertToPDF(file).then((dataURI) => {
        const base64 = dataURI.split('base64,')[1];
        const parseFile = new Parse.File(newFileName, { base64 });
        resolve(Setters.addRecord('CarrierProfile', {
          file: parseFile,
          uploadedBy: Parse.User.current(),
          belongsToCompany: currentUser.get('belongsToCompany'),
        }, Helpers.getCompanyReadWriteACL()));
      });
    } else {
      const parseFile = new Parse.File(newFileName, file, file.type);
      resolve(Setters.addRecord('CarrierProfile', {
        file: parseFile,
        uploadedBy: Parse.User.current(),
        belongsToCompany: currentUser.get('belongsToCompany'),
      }, Helpers.getCompanyReadWriteACL()));
    }
  });

  return promise;
}

/**
 * @memberof module:CarrierProfile
 * 
 * @param {*} file 
 * 
 * @returns 
 */
function uploadCarrierProfile(file) {
  const promise = new Promise ((resolve, reject) => {
    // first upload carrier profile to CarrierProfile
    uploadToCarrierProfile(file).then(carrierProfileObject => {
      // next extract the carrier profile summary
      const profileURL = carrierProfileObject.get('file')._url;

      // Use link to read PDF
      PDFJS.getDocument(profileURL).then(pdf => {
        PDFer.getDocumentContent(pdf, 1, pdf.numPages).then(pageObjects => {
          PDFer.getPageObjectsText(pageObjects, true, true).then(documentText => {
            // get profile summary
            const profileSummaryRegex = /Please(.*)report\1any\1items\1believed\1to\1be(.|\n)*Section(.*)1\1.+\1Carrier\1Information(.|\n)*Demographic\1Information(.|\n)*The\1carrier.*s\1profile\1status\1is\1set\1based\1on\1the\1score\1ranges\1listed\1below/gim;
            let profileSummaryText = documentText.match(profileSummaryRegex)[0];
            const profileSummaryRegexReduced = /Jurisdiction:.*/gim;
            profileSummaryText = profileSummaryText.match(profileSummaryRegexReduced)[0];

            const profileSummary = {
              jurisdiction: (/Jurisdiction:(.*)Primary/gm).exec(profileSummaryText)[1].trim(),
              certificateStatus: (/Certificate.*Status:(.*)Safety/gm).exec(profileSummaryText)[1].trim(),
              safetyRating: (/Rating:(.*)Profile.*Status/gm).exec(profileSummaryText)[1].trim(),
              profileStatus: (/Profile.*Status:(.*)Audit.*Status/gm).exec(profileSummaryText)[1].trim(),
              auditStatus: (/Audit.*Status:(.*)Compliance/gm).exec(profileSummaryText)[1].trim(),
            };

            const complianceReview = (/Compliance.*Review:(.*)Current/gim).exec(profileSummaryText)[1].trim();
            if (complianceReview.length > 0) {
              // if there's actually some kind of date string, normalize it
              profileSummary.complianceReview = new Date(complianceReview);
            }

            // seperate regex for carrier scores, to make sure we encapsulate much of *only* the table as possible
            const carrierScoresRegex = /current(\s*|\t*)profile\1scores\1as\1of(.+)carrier.{1}s(\s*|\t*)/gim;
            const carrierScoresText = profileSummaryText.match(carrierScoresRegex)[0];
            const carrierScores = Getters.getCarrierProfileScores(PDFer.getNumericEstimates(carrierScoresText));
            profileSummary.carrierScore = carrierScores[0].total;

            // now lets save all this info
            const profileSummaryKeys = Object.keys(profileSummary);
            for (let i = 0; i < profileSummaryKeys.length; i++) {
              const currentKey = profileSummaryKeys[i];
              carrierProfileObject.set(currentKey, profileSummary[currentKey]);
            }
            carrierProfileObject.save().then(
              finalProfileObject => {

                // retrieve all info after saving to db
                const promisesArr = [
                  setContraventionsInformation(documentText, finalProfileObject, 'driver'),
                  setContraventionsInformation(documentText, finalProfileObject, 'carrier'),
                  setDriverAccidentsInformation(documentText, finalProfileObject),
                  setCVSAInformation(documentText, finalProfileObject),
                ];

                Promise.all(promisesArr).then(carrierProfileReportObjects => {
                  const carrierProfileReport = {
                    carrierProfileObject: finalProfileObject,
                    driverContraventions: carrierProfileReportObjects[0],
                    carrierContraventions: carrierProfileReportObjects[1],
                    driverAccidents: carrierProfileReportObjects[2],
                    vehicleInspections: carrierProfileReportObjects[3],
                  };
                  resolve(carrierProfileReport);
                });

              },
              error => reject(error)
            );
          });
        });
      });
    });
  });
  return promise;
}


export {
  getRecentCarrierProfile,
  uploadCarrierProfile,
};