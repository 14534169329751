/** @module DispatchContact */

// api
import {
  addRecord, createQuery, findRecords,
  getCurrentUserSessionToken, getCurrentUserCompanyObjectId, setQueryRestriction,
  sortQuery, updateRecord,
} from 'sb-csapi/dist/AAPI';

// enums
import { QuerySortOrder, QueryRestrictionTypes, QueryRestriction } from 'sb-csapi/dist/enums/Query';

// sbObjects
import Sort from 'sb-csapi/dist/sbObjects/Sort';

/**
 * @memberof module:DispatchContact
 *
 * @description Get DispatchContacts according to filter criteria
 *
 * @param {DispatchOrganization} dispatchOrganizationRecord - Record used to find which contacts are linked to it
 * @param {object} options - See example
 * @param {string} companyObjectId - Company we wish to retrieve contact for (currently not used)
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers (currently not used)
 * @param {array} selectedAttributes - Select attributes to return (currently not used)
 * @param {bool} includeChildCompanies - Include child/sub-companies of this parent company (currently not used)
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { object } - { dispatchContacts: [DispatchOrganizationContact, ...] }
 */
async function getDispatchContacts(
  dispatchOrganizationRecord,
  options = { sessionToken: getCurrentUserSessionToken() },
  companyObjectId = getCurrentUserCompanyObjectId(),
  includeChildCompanies,
  filters = [],
  sortBy = new Sort(QuerySortOrder.ASCENDING, 'name'),
  includedPointers = [],
  selectedAttributes = [],
  queryAll,
) {

  const dispatchContactQuery = createQuery('DispatchOrganizationContact');
  setQueryRestriction(dispatchContactQuery, QueryRestrictionTypes.EQUAL_TO, 'organization', dispatchOrganizationRecord);

  if (filters.length > 0) {
    filters.forEach(filterObject => {
      setQueryRestriction(
        dispatchContactQuery,
        filterObject.queryRestriction,
        filterObject.attribute,
        filterObject.value,
      );
    });
  }

  sortQuery(dispatchContactQuery, sortBy.order, sortBy.attribute);

  const promises = [findRecords(options, dispatchContactQuery, false, queryAll)];

  try {
    const [dispatchContacts] = await Promise.all(promises);

    return { dispatchContacts };

  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchContact
 *
 * @description Add a Dispatch Contact
 *
 * @param {object} keyValueObj - The select fields we wish to update
 *
 * @returns {DispatchContact} - The created dispatchContact
 */
async function addDispatchContact(keyValueObj) {
  try {
    const dispatchContact = await addRecord(
      { sessionToken: getCurrentUserSessionToken() },
      'DispatchOrganizationContact',
      keyValueObj,
      getCurrentUserCompanyObjectId(),
    );
    return dispatchContact;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:DispatchContact
 *
 * @description Update a dispatch organization's contact
 *
 * @param {dispatchOrganization} dispatchOrganizationContact - The dispatchOrganizationContact to update. If not available, use parameter dispatchOrganizationContactObjectId instead
 * @param {string} dispatchOrganizationContactObjectId - The objectId of the dispatchOrganizationContact object we wish to update. Use if parameter dispatchOrganizationContact is unavailable
 * @param {object} keyValueObj - The select fields we wish to update
 * @param {bool} save - If true, executes save on the changes. If false, holds the changes locally but is not saved
 *
 * @returns {DispatchOrganizationContact} - The updated dispatchOrganizationContact
 */
async function updateDispatchContact(dispatchOrganizationContact, dispatchOrganizationContactObjectId, keyValueObj, save) {
  if (!dispatchOrganizationContact && !dispatchOrganizationContactObjectId) {
    throw new Error('Missing Arguments: Must provide dispatchContact or dispatchOrganizationContactObjectId');
  }
  if (dispatchOrganizationContact && dispatchOrganizationContactObjectId) {
    throw new Error('Arguments: Must provide only one of dispatchOrganizationContact or dispatchOrganizationContactObjectId');
  }
  let _dispatchOrganizationContact = dispatchOrganizationContact;

  try {
    if (!dispatchOrganizationContact && dispatchOrganizationContactObjectId) {
      const dispatchOrganizationContactQuery = createQuery('dispatchOrganizationContact');
      setQueryRestriction(dispatchOrganizationContactQuery, QueryRestriction.EQUAL_TO, 'objectId', dispatchOrganizationContactObjectId);
      _dispatchOrganizationContact = await findRecords(
        { sessionToken: getCurrentUserSessionToken() },
        dispatchOrganizationContactQuery,
        true,
      );
    }

    if (_dispatchOrganizationContact) {
      if (!Object.keys(keyValueObj).length) return _dispatchOrganizationContact;

      _dispatchOrganizationContact = await updateRecord(
        { sessionToken: getCurrentUserSessionToken() },
        _dispatchOrganizationContact, keyValueObj, save,
      );
      return _dispatchOrganizationContact;
    } else {
      throw new Error('DispatchOrganizationContact does not exist');
    }
  } catch (err) {
    throw new Error(err);
  }
}

// /**
//  * @description removes a dispatch contact record permanently
//  * @param {record} dispatchContactRecord the dispatch contact record to be destroyed
//  * @returns Promise from destroying the record
//  */
// async function removeDispatchContact(dispatchContactRecord) {
//   return await destroyRecord(dispatchContactRecord);
// }

export {
  getDispatchContacts,
  addDispatchContact,
  updateDispatchContact,
  // removeDispatchContact,
};
