import React from 'react';
import PropTypes from 'prop-types';
import history from "sbHistory";

// API
import { getObjectById } from 'api/Getters';
import * as Helpers from 'api/Helpers';
import { getQueryParameter } from 'api/URL';

// Components
import Sidebar from './Sidebar';
import GeofenceEventsLayout from './GeofenceEventsLayout';

// CSS
import styles from './GeofenceDetailsLayout.module.scss';

class GeofenceDetailsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Loading...',
      subViewSelected: getQueryParameter(props.location.search, "view")
    };
    this.handleSubViewClick = this.handleSubViewClick.bind(this);
  }

  componentDidMount() {
    document.title = 'Geofence Details - Switchboard';
    getObjectById('Geofence', getQueryParameter(this.props.location.search, 'geofence'), ['belongsToCompany']).then(geofenceObject => {
      this.setState({ ...this.state, title: geofenceObject.get('name'), geofenceObj: geofenceObject });
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...this.state, subViewSelected: getQueryParameter(nextProps.location.search, 'view'), eldModuleSubscribed: Helpers.isSubscribedToModule('eldModule') });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.location.search !== nextProps.location.search) return true;
    if (this.state.subViewSelected !== nextState.subViewSelected) return true;
    if (nextState.geofenceObj !== this.state.geofenceObj) return true;
    return false;
  }

  handleSubViewClick(subview) {
    history.push({ pathname: 'geofence', search: "geofence=" + getQueryParameter(this.props.location.search, 'geofence') + "&view=" + subview });
  }

  render() {
    const { eldModuleSubscribed, geofenceObj, subViewSelected } = this.state;
    return (
      <div>
        <Sidebar
          eldModuleSubscribed={eldModuleSubscribed}
          geofence={geofenceObj}
          activeSubview={subViewSelected}
          handleSubViewClick={this.handleSubViewClick}
          title={this.state.title}
        />
        { geofenceObj && 
          <div className={styles.content}>
            <GeofenceEventsLayout
              location={this.props.location}
              geofence={geofenceObj}
            />
          </div>
        }
      </div>
    );
  }
}

GeofenceDetailsLayout.propTypes = {
  location: PropTypes.object.isRequired,
};

export default GeofenceDetailsLayout;
