import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import uniqid from 'uniqid';

// CSAPI
import { getAttribute, getCurrentUser, getCurrentUserSessionToken, getRecordByObjectId, updateRecord } from 'sb-csapi/dist/AAPI';
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// Enums
import { Organization } from 'sb-csapi/dist/enums/Dispatch/Organization';
import { CommodityTypes } from 'sb-csapi/dist/enums/Dispatch/Commodity';
import { MassUnit, LengthUnit } from 'sb-csapi/dist/enums/Unit';
import { Payee } from 'sb-csapi/dist/enums/Dispatch/Payee';
import { AssignStatus } from 'sb-csapi/dist/enums/Dispatch/Job';
import { FreightCategory } from 'sb-csapi/dist/enums/Dispatch/Freight';

// API
import { getDispatchTransfers } from 'api/Dispatch/DispatchTransfer';
import { getDispatchItems } from 'api/Dispatch/DispatchItem';
import { getDispatchPayees, addDispatchPayee } from 'api/Dispatch/DispatchPayee';
import { getDispatchPayables, voidDispatchPayable } from 'api/Dispatch/DispatchPayable';
import { getDispatchJobAccountingRecords } from 'api/Dispatch/DispatchJobAccounting';
import { getDispatchDrivers } from 'api/Dispatch/DispatchDriver';
import { getDispatchVehicles } from 'api/Dispatch/DispatchVehicle';
import { getDispatchTrailers } from 'api/Dispatch/DispatchTrailer';

// Components
import Card from 'sbCore/Card/Card';
import Badge from 'sbCore/Badge/Badge';
import Button from 'sbCore/Button/Button';
import Message from 'sbCore/Message/Message';
import Accordion from 'sbCore/Accordion/Accordion';
import AccordionTab from 'sbCore/Accordion/AccordionTab';
import DispatchLegsContent from 'components/Dispatch/DispatchLegsContent/DispatchLegsContent';
import DispatchOrganizationAutocomplete from 'components/Dispatch/DispatchOrganizationAutocomplete/DispatchOrganizationAutocomplete';
import PaymentInformationRequiredPrompt from 'components/Dispatch/PaymentInformationRequiredPrompt/PaymentInformationRequiredPrompt';

// sbObjects
import DispatchLeg from 'sbObjects/DispatchLeg';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Styles
import './style.scss';


/**
 * @description Gets information about the dispatch transfers and dispatch items for a specific dispatch job
 * @param {String} dispatchJobObjectId - Required. The dispatch job we want to obtain dispatch transfers (legs) from
 * @param {DispatchJob} [dispatchJob] - The dispatch job
 *
 * @returns
 */
function DispatchLegDetails({ ...props }) {
  // ** useStates **//
  const [dispatchTransfers, setDispatchTransfers] = useState([]);
  const [dispatchItems, setDispatchItems] = useState([]);
  const [dispatchLegs, setDispatchLegs] = useState([]);
  const [dispatchJob, setDispatchJob] = useState(undefined);
  const [dispatchJobAccounting, setDispatchJobAccounting] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [carrierDispatchOrganization, setCarrierDispatchOrganization] = useState(undefined);
  const [showCarrierSelection, setShowCarrierSelection] = useState(false);
  const [isPaymentInformationRequired, setIsPaymentInformationRequired] = useState(false);

  const [dispatchPayee, setDispatchPayee] = useState(null);
  const [activeIndex, setActiveIndex] = useState([0]);
  const [isRefreshNeeded, setIsRefreshNeeded] = useState(false);

  // ** useEffects ** //
  useEffect(() => {
    let didCancel = false;

    async function _getDispatchJob() {
      const dispatchJob = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'DispatchJob', props.dispatchJobObjectId);
      if (dispatchJob) getDispatchJobAccounting(dispatchJob);
      if (!didCancel) {
        setDispatchJob(dispatchJob);
      }
    }

    async function getDispatchJobAccounting(dispatchJob) {
      const dispatchJobObjectId = getAttribute(dispatchJob, 'objectId');
      const { dispatchJobAccountings } = await getDispatchJobAccountingRecords(
        undefined,
        undefined,
        undefined,
        [new Filter(QueryRestriction.EQUAL_TO, 'dispatchJob', dispatchJobObjectId)],
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      );

      let _dispatchJobAccounting;
      if (dispatchJobAccountings && dispatchJobAccountings.length > 0) {
        _dispatchJobAccounting = dispatchJobAccountings[0];
      }

      if (!didCancel) {
        setDispatchJobAccounting(_dispatchJobAccounting);
      }
    }

    if (props.dispatchJob) {
      getDispatchJobAccounting(props.dispatchJob);
      setDispatchJob(props.dispatchJob);
    } else {
      _getDispatchJob();
    }

    return () => { didCancel = true; };
  }, [props.dispatchJob, props.dispatchJobObjectId])

  useEffect(() => {
    let didCancel = false;

    // Use to get the dispatch transfers from an array of object ids
    async function _getDispatchTransfersAndDispatchItems() {
      const dispatchJobObjectId = getAttribute(dispatchJob, 'objectId');
      const includedPointers = ['shipperDispatchOrganization', 'consigneeDispatchOrganization'];

      const { dispatchTransfers } = await getDispatchTransfers(
        undefined,                        // options - default
        dispatchJobObjectId,              // dispatchJobObjectId - default
        undefined,                        // filters
        undefined,                        // sort - default
        includedPointers,                 // includes
        undefined,                        // selects
        undefined,                        // page
        undefined,                        // limit
        true                              // query all
      );

      const dispatchTransferObjectIdArr = dispatchTransfers.map((dispatchTransfer) => getAttribute(dispatchTransfer, 'objectId'));
      const dispatchItemQueryFilter = [new Filter(QueryRestriction.CONTAINED_IN, 'dispatchTransfer', dispatchTransferObjectIdArr)];

      const { dispatchItems } = await getDispatchItems(
        undefined,                        // options - default
        undefined,                        // dispatchJobObjectId
        dispatchItemQueryFilter,          // filters
        undefined,                        // sort - default
        undefined,                        // includes
        undefined,                        // selects
        undefined,                        // page
        undefined,                        // limit
        true                              // query all
      );
      if (!didCancel) {
        setDispatchTransfers(dispatchTransfers);
        setDispatchItems(dispatchItems);
      }
    }

    async function getDispatchPayee(carrierDispatchOrganization) {
      const organizationName = getAttribute(carrierDispatchOrganization, 'organizationName');
      const organizationId = getAttribute(carrierDispatchOrganization, 'organizationId');
      let _dispatchPayee;

      const filters = [];
      if (organizationName) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'name', organizationName));
      if (organizationId) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'code', organizationId));

      const { dispatchPayees } = await getDispatchPayees(
        undefined,
        undefined,
        filters,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        false,
      );
      if (dispatchPayees && dispatchPayees.length > 0) _dispatchPayee = dispatchPayees[0];
      if (!didCancel) setDispatchPayee(_dispatchPayee);
    }

    if (dispatchJob) {
      setIsLoading(true);

      _getDispatchTransfersAndDispatchItems();
      const _carrierDispatchOrganization = getAttribute(dispatchJob, 'carrierDispatchOrganization');
      if (_carrierDispatchOrganization) {
        getDispatchPayee(_carrierDispatchOrganization);
        setCarrierDispatchOrganization(_carrierDispatchOrganization);
        setShowCarrierSelection(true);
      }

      setIsLoading(false);
    }

    return () => { didCancel = true; };
  }, [dispatchJob]);

  useEffect(() => {
    if (!dispatchJobAccounting) return;
    let didCancel = false;

    async function checkIfPaymentInformationRequired() {
      const filters = [
        new Filter(QueryRestriction.EQUAL_TO, 'dispatchAccessorial', undefined),
        new Filter(QueryRestriction.NOT_EQUAL_TO, 'isVoided', true),
        new Filter(QueryRestriction.EQUAL_TO, 'dispatchJobAccounting', dispatchJobAccounting),
      ];
      const { dispatchPayables } = await getDispatchPayables(
        undefined,
        undefined,
        filters,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      );

      if (!didCancel && !dispatchPayables.length > 0 && carrierDispatchOrganization) setIsPaymentInformationRequired(true);
    }

    checkIfPaymentInformationRequired();

    return () => { didCancel = true; };
  }, [dispatchJobAccounting]);

  // Retrieve the required information for each dispatch leg and create a new leg object with it
  useEffect(() => {
    let didCancel = false;
    if (!dispatchTransfers || dispatchTransfers.length === 0) return;

    async function updateDispatchLegsInformation() {
      const _dispatchLegs = [];

      for (let i = 0; i < dispatchTransfers.length; i ++) {
        const dispatchTransferObjectId = getAttribute(dispatchTransfers[i], 'objectId');
        // Third argument is used to determine which suffix letter to give the leg
        const dispatchLeg = generateDispatchLeg(dispatchTransfers[i], dispatchTransferObjectId, (dispatchTransfers.length - 1) - i);

        const dispatchDrivers = await getDispatchDrivers({ sessionToken: getCurrentUserSessionToken() }, dispatchTransferObjectId);
        if (dispatchDrivers.totalDispatchDriversCount === 0) {
          dispatchLeg.hasDriver = false;
        } else {
          dispatchLeg.hasDriver = true;
        }

        const dispatchVehicles = await getDispatchVehicles({ sessionToken: getCurrentUserSessionToken() }, dispatchTransferObjectId);
        if (dispatchVehicles.totalDispatchVehiclesCount === 0) {
          dispatchLeg.hasVehicle = false;
        } else {
          dispatchLeg.hasVehicle = true;
        }

        const dispatchTrailers = await getDispatchTrailers({ sessionToken: getCurrentUserSessionToken() }, dispatchTransferObjectId);
        if (dispatchTrailers.totalDispatchTrailersCount === 0) {
          dispatchLeg.hasTrailer = false;
        } else {
          dispatchLeg.hasTrailer = true;
        }

        if (dispatchItems && dispatchItems.length > 0) {
          // filter for dispatchItems linked to the current dispatchTransfer
          const _dispatchItems = dispatchItems.filter((dispatchItem) => {
            const dispatchItemTransfer = getAttribute(dispatchItem, 'dispatchTransfer');
            const dispatchItemTransferObjectId = getAttribute(dispatchItemTransfer, 'objectId', true);

            return dispatchTransferObjectId === dispatchItemTransferObjectId;
          });

          if (_dispatchItems && _dispatchItems.length > 0) {
            const freight = generateFreightInformation(_dispatchItems);
            dispatchLeg.freight = freight;
          }
        }

        _dispatchLegs.unshift(dispatchLeg);
      }

      if (!didCancel) {
        setDispatchLegs(_dispatchLegs);
      }
    }

    updateDispatchLegsInformation();

    return () => { didCancel = true }
  }, [dispatchTransfers, dispatchItems, isRefreshNeeded]);

  // ** Functions ** //
  function generateDispatchLeg(dispatchTransfer, dispatchTransferObjectId, index) {
    const dispatchLegId = `${getAttribute(dispatchJob, 'batchId')}-${String.fromCharCode(index + 65)}`; // Generates the names of the individual legs

    const shipperDispatchOrganization = getAttribute(dispatchTransfer, 'shipperDispatchOrganization');
    const shipperName = shipperDispatchOrganization && getAttribute(shipperDispatchOrganization, 'organizationName', true) || '-';

    const consigneeDispatchOrganization = getAttribute(dispatchTransfer, 'consigneeDispatchOrganization');
    const consigneeName = consigneeDispatchOrganization && getAttribute(consigneeDispatchOrganization, 'organizationName', true) || '-';

    const pickupDateTime = getAttribute(dispatchTransfer, 'pickupDateTime');
    const dropoffDateTime = getAttribute(dispatchTransfer, 'dropoffDateTime');

    const pickupDateTimeString = pickupDateTime ? moment(pickupDateTime).format('DD-MM-YYYY @ HH:mm') : '-';
    const dropoffDateTimeString = dropoffDateTime ? moment(dropoffDateTime).format('DD-MM-YYYY @ HH:mm') : '-';

    const timezone = getAttribute(dispatchTransfer, 'timezoneOffsetFromUTC');

    const isStatusModified = getAttribute(dispatchTransfer, 'isStatusModified');

    const shippingDocumentNumber = getAttribute(dispatchTransfer, 'shippingDocumentNumber', true) || '';

    const routeName = getAttribute(dispatchTransfer, 'routeName', true) || '';

    const dispatchLeg = new DispatchLeg(
      dispatchTransferObjectId,
      dispatchLegId,
      shipperName,
      consigneeName,
      pickupDateTimeString,
      dropoffDateTimeString,
      timezone,
      undefined,
      isStatusModified,
      shippingDocumentNumber,
      routeName,
    );

    return dispatchLeg;
  }

  function generateFreightInformation(dispatchItems) {
    const freight = [];

    dispatchItems.map((dispatchItem) => {
      const commodityType = getAttribute(dispatchItem, 'commodityType');
      const commodityTypeCustomName = getAttribute(dispatchItem, 'commodityTypeCustomName');
      const equipment = commodityTypeCustomName ? commodityTypeCustomName : (commodityType !== undefined && CommodityTypes[commodityType] || '-');

      const categoryInt = getAttribute(dispatchItem, 'category', true);
      const categoryStr = categoryInt ? Object.values(FreightCategory).find((category) => category.type === categoryInt)?.description : '-';
      const quantity = getAttribute(dispatchItem, 'quantity', true);

      const weight = getAttribute(dispatchItem, 'weight') || '-';
      const weightUnit = getAttribute(dispatchItem, 'massUnit');

      // Convert MassUnit enum to array and retrieve the weight based on the index
      const massUnitArr = Object.values(MassUnit);
      const weightUnitString = (weightUnit < massUnitArr.length) ? `${massUnitArr[weightUnit].toLowerCase()}(s)` : '';

      const weightString = `${weight} ${weightUnitString}`;

      const length = getAttribute(dispatchItem, 'itemLength') || '-';
      const width = getAttribute(dispatchItem, 'width') || '-';
      const height = getAttribute(dispatchItem, 'height') || '-';

      const dimensionsUnit = getAttribute(dispatchItem, 'lengthUnit');

      // Convert LengthUnit enum to array and retrieve the unit based on the index
      const lengthUnitArr = Object.values(LengthUnit);
      const dimensionsUnitString = (dimensionsUnit < lengthUnitArr.length) ? lengthUnitArr[dimensionsUnit].toLowerCase() : '';

      const dimensionsString = `${length} x ${width} x ${height} ${dimensionsUnitString}`;

      const referenceNumber = dispatchJob && getAttribute(dispatchJob, 'referenceNumber');
      const referenceNumberString = referenceNumber ? `#${referenceNumber}` : '-';

      const freightObject = {
        key: uniqid(),
        equipment,
        quantity,
        categoryStr,
        weightString,
        dimensionsString,
        referenceNumber: referenceNumberString,
      };

      freight.push(freightObject);
    });

    return freight;
  }

  async function updateDispatchJobCarrierOrganization(carrierDispatchOrganization) {
    // Logic to update the dispatch job's carrier organization
    const keyValueObj = { 'carrierDispatchOrganization': carrierDispatchOrganization };
    const sessionToken = getCurrentUserSessionToken();

    updateRecord({ sessionToken }, dispatchJob, keyValueObj, true);

    const user = getCurrentUser();
    const username = getAttribute(user, 'username', true);
    const currentDateTime = moment().format('DD-MM-YYYY HH:mm');
    const message = `- Payable voided by ${username} on ${currentDateTime}`;

    if (carrierDispatchOrganization) {
      // Void all existing DispatchPayables for the job once a organization is selected
      if (dispatchJobAccounting) {
        const filters = [
          new Filter(QueryRestriction.EQUAL_TO, 'dispatchAccessorial', undefined),
          new Filter(QueryRestriction.NOT_EQUAL_TO, 'isVoided', true),
          new Filter(QueryRestriction.EQUAL_TO, 'dispatchJobAccounting', dispatchJobAccounting),
        ];
        const { dispatchPayables } = await getDispatchPayables(
          undefined,
          undefined,
          filters,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true,
        );
      }

      // Check if a DispatchPayee exists. If not, create a new one
      const organizationName = getAttribute(carrierDispatchOrganization, 'organizationName');
      const organizationId = getAttribute(carrierDispatchOrganization, 'organizationId');
      let dispatchPayee;

      const filters = [];
      if (organizationName) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'name', organizationName));
      if (organizationId) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'code', organizationId));

      const { dispatchPayees } = await getDispatchPayees(
        undefined,
        undefined,
        filters,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        false,
      );
      if (dispatchPayees && dispatchPayees.length > 0) dispatchPayee = dispatchPayees[0];

      if (!dispatchPayee) {
        const currentUser = getCurrentUser();
        const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
        const dispatchPayeeObj = {
          name: organizationName,
          code: organizationId,
          type: Payee.COMPANY.type,
          belongsToCompany,
        };
        dispatchPayee = await addDispatchPayee(dispatchPayeeObj);
      }
      setDispatchPayee(dispatchPayee);
      setIsPaymentInformationRequired(true);
    } else {

      // Void the DispatchPayable once the organization is removed
      if (dispatchJobAccounting) {
        const { dispatchPayables } = await getDispatchPayables(
          undefined,
          undefined,
          [new Filter(QueryRestriction.NOT_EQUAL_TO, 'isVoided', true), new Filter(QueryRestriction.EQUAL_TO, 'dispatchJobAccounting', dispatchJobAccounting)],
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true,
        );

        if (dispatchPayables && dispatchPayables.length > 0) {
          const dispatchPayable = dispatchPayables[0];
          voidDispatchPayable(dispatchPayable, message);
        }
      }

      setIsPaymentInformationRequired(false);
      setDispatchPayee(null);
    }
    setCarrierDispatchOrganization(carrierDispatchOrganization);
  }

  function handleRefresh() {
    setIsRefreshNeeded(!isRefreshNeeded);
  }

  function accordionHeaderTemplate(dispatchLeg) {
    // Check if dispatchLeg has driver/vehicle/trailer and assign className to the badges
    let status = AssignStatus.UNASSIGNED;

    if (dispatchLeg.hasDriver && dispatchLeg.hasVehicle && dispatchLeg.hasTrailer) {
      status = AssignStatus.ASSIGNED;
    } else if (dispatchLeg.hasDriver || dispatchLeg.hasVehicle || dispatchLeg.hasTrailer) {
      status = AssignStatus.PARTIAL;
    }

    return (
      <span className="accordion-tab-header">
        <React.Fragment>
          <Badge value={status.key} className={`accordion-status-badge ${status.key.toLowerCase()}`} />
          {dispatchLeg.dispatchLegId}
        </React.Fragment>
      </span>
    )
  }

  return (
    <div className="dispatch-leg-details">
      <Card className="dispatch-carrier-card bg-indigo-50 mb-2">
        <div className="flex align-items-center">
          <i className="pi pi-info-circle mr-2" />
          Assign drivers and equipment to the job or
          <Button icon="pi pi-reply" className="ml-2 p-button-sm text-sm py-1" label="Dispatch to Carrier" onClick={() => setShowCarrierSelection(!showCarrierSelection)} />
        </div>

        {showCarrierSelection &&
          <div className="carrier-selection-container flex align-items-center my-3">
            <DispatchOrganizationAutocomplete
              className="p-inputtext-sm"
              type={Organization.CARRIER}
              dispatchOrganization={carrierDispatchOrganization}
              onSelectDispatchOrganization={async (carrierDispatchOrganization) => await updateDispatchJobCarrierOrganization(carrierDispatchOrganization)}
              warning
              allowAddDispatchOrganization
              isLoading={isLoading}
            />
            {!carrierDispatchOrganization && <Message className="h-3rem ml-3" severity="warn" text="Selecting a carrier will remove all currently assigned drivers and equipment from this job" />
            }
            <PaymentInformationRequiredPrompt
              className="pl-1"
              dispatchPayee={dispatchPayee}
              dispatchJob={dispatchJob}
              onSave={() => setIsPaymentInformationRequired(false)}
              visible={isPaymentInformationRequired}
            />
          </div>
        }
      </Card>

      {!isLoading && (
        <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
          {dispatchLegs.map((dispatchLeg, index) => (
            <AccordionTab header={accordionHeaderTemplate(dispatchLeg)}>
              <DispatchLegsContent key={dispatchLeg.dispatchTransferObjectId} dispatchLeg={dispatchLeg} carrierDispatchOrganization={carrierDispatchOrganization} dispatchJob={dispatchJob} legIndex={index} handleRefresh={handleRefresh} />
            </AccordionTab>
          ))}
        </Accordion>
      )}
    </div>
  );
}

export default DispatchLegDetails;
