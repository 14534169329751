import React from 'react'
import PropTypes from 'prop-types'
import history from '../../sbHistory'

// API
import * as Getters from 'api/Getters'
import * as Helpers from 'api/Helpers'
import * as PDF from 'api/PDFer'
import * as TI from 'api/TripInspection'
import { addReplaceQueryParameter, getQueryParameter } from 'api/URL'

// Components
import Title from 'components/LayoutTitle/view/Title'
import FilterForm from 'components/FilterForm/container/FilterForm.new.js'
import FilterDropdown from 'components/FilterDropdown/view/FilterDropdown'
import TripInspectionsTable from 'components/TripInspection/container/TripInspectionsTable'
import TripInspectionDetails from 'components/TripInspection/view/TripInspectionDetails'
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal'
import { MDBRow, MDBCol, MDBBtn } from 'mdbreact'

// CSS
import styles from './TripInspectionsLayout.module.scss'

class TripInspectionsLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tripInspections: [],
      selectedTripInspection: undefined,
      sort: { dateTime: 'descending' },
      loaded: false,
      print: {
        inProgress: false
      },
      editTripInspectionDialog: {
        show: false
      }
    }

    this.state.dropdownFilterFields = [
      { attrName: 'all', placeholder: 'View All Inspections' },
      {
        attrName: 'unresolvedDefects',
        placeholder: 'View Unresolved Defects'
      },
      {
        attrName: 'noDefects',
        placeholder: 'View No/Resolved Defects'
      },
      {
        attrName: 'ctpat',
        placeholder: 'View C-TPAT/PIP Inspections'
      }
    ]

    this.state.filterFormFields = [
      {
        attrName: 'seals',
        queryType: 'matchesQuery',
        innerQueryClass: 'Seal',
        innerQueryProperty: 'sealId',
        innerQueryType: 'equalTo',
        placeholder: '.....',
        fullName: 'Seal ID',
        type: 'text'
      },
      {
        attrName: 'dateTime',
        queryType: 'date',
        dateQueryType: 'lessThanOrEqualTo',
        fullName: 'To',
        placeholder: '.....',
        type: 'date'
      },
      {
        attrName: 'dateTime',
        queryType: 'date',
        dateQueryType: 'greaterThanOrEqualTo',
        fullName: 'From',
        placeholder: '.....',
        type: 'date'
      },
      {
        attrName: 'vehicleUnitId',
        fullName: 'Vehicle',
        placeholder: '.....',
        type: 'text'
      }
    ]

    this.filter = this.filter.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.scrollHandler = this.scrollHandler.bind(this)
    this.handleSelectTripInspection = this.handleSelectTripInspection.bind(this)
    this.setPrintState = this.setPrintState.bind(this)
    this.handleDropdownView = this.handleDropdownView.bind(this)
    this.sortHandler = this.sortHandler.bind(this)
    this.fetchTripInspection = this.fetchTripInspection.bind(this)
    this.query = this.query.bind(this)
    this.toggleEditTripInspectionDialog = this.toggleEditTripInspectionDialog.bind(
      this
    )
    this.deleteTripInspection = this.deleteTripInspection.bind(this)
  }

  componentDidMount() {
    document.title = 'Trip Inspections - Switchboard'
    const clientHeight = document.documentElement.clientHeight
    this.tripInspectionTripTableHeight =
      document.documentElement.clientHeight * 0.6 // 60vh defined by CSS
    if (clientHeight <= 1366) {
      // laptop size
      this.tripInspectionTripTableHeight = clientHeight * 0.6
    }
    this.tripInspectionTripTableRowAmount =
      Math.round(this.tripInspectionTripTableHeight / 36) + 5 // amount of el's to display
    this.pageCount = 0 // result fetch multiplier
    this.query()
  }

  componentWillReceiveProps(nextProps) {
    let newTrip = getQueryParameter(nextProps.location.search, 'tripInspection')
    if (newTrip) {
      for (let i = 0; i < this.state.tripInspections.length; i++) {
        if (this.state.tripInspections[i].id === newTrip) {
          this.setState(
            {
              ...this.state,
              selectedTripInspection: this.state.tripInspections[i]
            },
            () => {
              Getters.queryCompanyObjects(
                'Vehicle',
                undefined,
                undefined,
                [
                  {
                    queryType: 'equalTo',
                    name: 'unitId',
                    value: this.state.tripInspections[i].get('vehicleUnitId')
                  },
                  { queryType: 'exists', name: 'plate' },
                  { queryType: 'equalTo', name: 'enabled', value: true }
                ],
                undefined,
                ['licensePlate'],
                true
              ).then(vehicle => {
                this.setState({ ...this.state, tripInspectionVehicle: vehicle })
              })
            }
          )
          break
        }
      }
    } else {
      this.setState({
        ...this.state,
        selectedTripInspection: undefined,
        tripInspectionVehicle: undefined
      })
    }
  }

  setPrintState(bool) {
    // const reportHTML = document.querySelector('.printable').outerHTML
    // const reportName = 'Trip Inspection Report'
    this.setState({ ...this.state, print: { ...this.state.print, inProgress: bool } });
    // this.setState(
    //   { ...this.state, print: { ...this.state.print, inProgress: true } },
    //   () => {
    //     PDF.generatePDFFromHTML(reportHTML, reportName, {
    //       orientation: 'portrait',
    //       styles
    //     }).then(
    //       tempFile => {
    //         const reportURL = tempFile.get('file')._url
    //         // console.log(reportURL);
    //         const completeMessage = 'Document Created'
    //         Helpers.openDocumentLink(reportURL)
    //         this.setState({
    //           ...this.state,
    //           print: { reportURL, inProgress: false, completeMessage }
    //         })
    //       },
    //       error => {
    //         console.log(error)
    //         const completeMessage =
    //           'Sorry, there was an error with printing. Please try again'
    //         this.setState({
    //           ...this.state,
    //           print: {
    //             reportURL: undefined,
    //             inProgress: false,
    //             completeMessage
    //           }
    //         })
    //       }
    //     )
    //   }
    // )
    this.setState({ ...this.state, print: { ...this.state.print, inProgress: bool } });
  }

  clearFilter() {
    this.pageCount = 0
    this.setState({ ...this.state, tripInspections: [], filter: {} }, () => {
      this.query()
    })
  }

  filter(filterObject) {
    this.pageCount = 0
    const newState = {
      ...this.state,
      tripInspections: [],
      filter: filterObject
    }
    this.setState(newState, () => {
      this.query()
    })
  }

  handleDropdownView(tableView) {
    this.pageCount = 0
    this.setState({ ...this.state, tripInspections: [], tableView }, () => {
      this.query();
    })
  }

  handleSelectTripInspection(tripInspectionId) {
    if (tripInspectionId) {
      history.push({
        pathname: this.props.location.pathname,
        search: this.props.location.search
          ? addReplaceQueryParameter(
            this.props.location.search,
            'tripInspection',
            tripInspectionId
          )
          : 'tripInspection=' + tripInspectionId
      })
    } else {
      history.push({
        pathname: this.props.location.pathname,
        search: this.props.location.search
          ? addReplaceQueryParameter(
            this.props.location.search,
            'tripInspection',
            ''
          )
          : 'tripInspection='
      })
    }
  }

  fetchTripInspection(tripInspectionParseObj) {
    tripInspectionParseObj.fetch().then(() => {
      this.setState({ ...this.state })
    })
  }

  scrollHandler(e) {
    if (Helpers.scrolledToBottom(e, 10, this.lastScrollPos)) {
      this.pageCount++;
      this.lastScrollPos = e.target.scrollTop;
      this.query();
    }
  }

  query() {
    this.setState({ ...this.state, loaded: false })
    const filter = [].concat(
      { name: 'disabled', queryType: 'notEqualTo', value: true },
      this.state.filter
    )
    if (this.state.tableView === 'unresolvedDefects') {
      filter.push({
        name: 'hasUnresolvedDefects',
        queryType: 'equalTo',
        value: true
      })
    } else if (this.state.tableView === 'noDefects') {
      filter.push({
        name: 'hasUnresolvedDefects',
        queryType: 'equalTo',
        value: false
      })
    } else if (this.state.tableView === 'ctpat') {
      filter.push({
        name: 'type',
        queryType: 'equalTo',
        value: 3,
      })
    }
    Getters.queryCompanyObjects(
      'TripInspection',
      this.pageCount,
      this.tripInspectionTripTableRowAmount,
      filter,
      this.state.sort,
      ['tripDefects', 'seals', 'trailerFirst', 'trailerSecond', 'trailerFirst.licensePlate', 'trailerSecond.licensePlate'],
      false,
      false
    ).then(tripInspectionParseArr => {
      this.setState({
        ...this.state,
        tripInspections: this.state.tripInspections.concat(
          tripInspectionParseArr
        ),
        loaded: true
      })
    })
  }

  sortHandler(attribute) {
    // // sort list of vehicles based on attribute
    // deleteVehiclesForState().then(() => {
    //   this.pageCount = 0;
    //   const newState = { ...this.state };
    //   const currentSort = this.state.sort[attribute]; // the current sort, if defined
    //   if (currentSort) {
    //     if (currentSort === 'ascending') {
    //       newState.sort[attribute] = 'descending';
    //     } else {
    //       newState.sort[attribute] = 'ascending';
    //     }
    //   } else if (attribute === 'unitId') {
    //     newState.sort = { unitId: 'ascending' };
    //   } else if (attribute === 'vendor_name') {
    //     newState.sort = { vendor_name: 'ascending' };
    //   } else if (attribute === 'user_fullName') {
    //     newState.sort = { user_fullName: 'ascending' };
    //   } else if (attribute === 'nextInspectionDate') {
    //     newState.sort = { nextInspectionDate: 'ascending' };
    //   }
    //   this.setState({ ...this.state, sort: newState.sort });
    //   getVehiclesForState(this.pageCount, this.tripInspectionTripTableRowAmount, this.state.filter, newState.sort, this.state.tableView);
    // });
  }

  toggleEditTripInspectionDialog(tripInspection, show) {
    const newState = { ...this.state }
    newState.editTripInspectionDialog = { ...newState.editTripInspectionDialog }
    newState.editTripInspectionDialog.show = show
    this.setState(newState)
  }

  deleteTripInspection(bool) {
    if (bool) {
      const { selectedTripInspection } = this.state
      const newState = { ...this.state }
      const tripInspections = [].concat(newState.tripInspections)
      for (let i = 0; i < tripInspections.length; i++) {
        if (tripInspections[i].id === selectedTripInspection.id) {
          tripInspections.splice(i, 1)
          break
        }
      }

      newState.tripInspections = tripInspections

      this.setState(newState, () => {
        this.toggleEditTripInspectionDialog(undefined, false)
      })

      return TI.editTripInspection(selectedTripInspection, {
        disabled: true
      })
    }
    this.toggleEditTripInspectionDialog(undefined, false)
  }

  render() {
    const { editTripInspectionDialog } = this.state

    return (
      <div>
        <div
          className={
            this.state.selectedTripInspection
              ? `${styles.table} ${styles.tripInspectionSelected}`
              : styles.table
          }
        >
          <Title title='Trip Inspections'>
            <MDBRow className='d-flex flex-row-reverse'>
              <MDBCol xs='12' lg='4' className='my-auto'>
                <FilterDropdown
                  id='tripInspectionFilterDropdown'
                  className='float-right mx-1'
                  title='Trip Inspections - All'
                  handleFilter={this.handleDropdownView}
                  menuItems={this.state.dropdownFilterFields}
                  disabled={this.state.disableViewDropdown}
                />
              </MDBCol>
              <MDBCol xs='12' lg='8'>
                <FilterForm
                  className='floatRight'
                  handleFilter={this.filter}
                  clearFilter={this.clearFilter}
                  fields={this.state.filterFormFields}
                />
              </MDBCol>
            </MDBRow>
          </Title>
          <TripInspectionsTable
            showDriver
            tripInspections={this.state.tripInspections}
            loaded={this.state.loaded}
            handleSelectTripInspection={this.handleSelectTripInspection}
            selectedTripInspection={this.state.selectedTripInspection}
            scrollHandler={this.scrollHandler}
          />
        </div>
        {this.state.selectedTripInspection && (
          <div className={styles.details}>
            <TripInspectionDetails
              handleClose={() => this.handleSelectTripInspection()}
              tripInspection={this.state.selectedTripInspection}
              tripInspectionVehicle={this.state.tripInspectionVehicle}
              fetchTripInspection={this.fetchTripInspection}
              printInProgress={this.state.print.inProgress}
              setPrintState={this.setPrintState}
              goToDownload={() => history.push({ pathname: 'safety', search: 'view=logstodownload' })}
              toggleEditTripInspectionDialog={
                this.toggleEditTripInspectionDialog
              }
            />
          </div>
        )}

        {editTripInspectionDialog.show && (
          <ConfirmModal
            show={editTripInspectionDialog.show}
            handleModalChoice={bool => this.deleteTripInspection(bool)}
            handleClose={() =>
              this.toggleEditTripInspectionDialog(undefined, false)
            }
          >
            Are you sure you want to delete this Trip Inspection?
          </ConfirmModal>
        )}
      </div>
    )
  }
}

TripInspectionsLayout.propTypes = {
  location: PropTypes.object.isRequired
}

export default TripInspectionsLayout
