import { useContext } from 'react';

// Contexts
import ToastContext from 'contexts/ToastContext';

/**
 * @description A toast context abstraction which allows the usage of the toast context wherever imported
 * @returns
 *
 * @example
 * const { addToast, clearToasts } = useToastContext();
 */
export default function useToastContext() {
  return useContext(ToastContext);
}
