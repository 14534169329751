import React from 'react';
import PropTypes from 'prop-types';
import Parse from 'parse';
import { connect } from 'react-redux';
import history from "sbHistory";
import { withRouter } from "react-router";

// Actions
import { tryLogin, doLogout } from 'actions/Auth';

// API
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL'
import { resetDispatcherPassword } from 'api/Setters';

// Helpers
import { getCurrentUser } from 'api/Parse';

// Components
import LoginForm from '../view/LoginForm';
import ForgotPasswordModal from '../view/ForgotPasswordModal';

// Context
import StoreContext from 'contexts/StoreContext';

function getUsername() {
  const user = getCurrentUser();
  if (user) {
    return user.get('username');
  }
  return null;
}

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPasswordModal: false,
      fields: {
        email: getUsername() ? getUsername() : '',
        password: '',
      },
      error: '',
      disableLogin: false,
      hideLoginFields: false,
      loading: false,
    };
    this.tryLogin = this.tryLogin.bind(this);
    this.emailHandler = this.emailHandler.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
  }

  async componentDidMount() {
    // Wialon / Astreon login
    let hostUrl = getQueryParameter(this.props.location.search, 'hostUrl');

    // if there is auth & id (for internal tool / support tool logins)
    let auth = getQueryParameter(this.props.location.search, "auth");
    let id = getQueryParameter(this.props.location.search, "id");
    let supportUserId = getQueryParameter(this.props.location.search, "user");
    let error = getQueryParameter(this.props.location.search, "error");

    if (supportUserId) {
      document.cookie = `supportUserId=${supportUserId}`;
    }

    if (
      this.props.location &&
      getQueryParameter(this.props.location.search, 'authHash') &&
      getQueryParameter(this.props.location.search, 'user')
    ) {
      // Wialon / Astreon login
      this.setState({ ...this.state, hideLoginFields: true, loading: true });
      const authHash = getQueryParameter(this.props.location.search, 'authHash');
      const wialonUsername = getQueryParameter(this.props.location.search, 'user');

      try {
        const sessionToken = await Parse.Cloud.run('getUserSessionWithWialonLogin', { authHash, wialonUsername });
        Parse.User.become(sessionToken).then(() => {
          if (Parse.User.current().authenticated()) {
            history.push('/home');
          }
        }, (error) => {
          if (error.code === 209) {
            this.setState({ ...this.state, error: 'user needs to log in first', loading: false });
            console.log(error);
          }
        });
      } catch (e) {
        this.setState({ ...this.state, error: e.message, loading: false });
      }
    } else if (this.props.location && auth && id) {
      // if there is auth & id (for internal tool / support tool logins)
      Parse.User.become(auth).then(() => {
        if (Parse.User.current().authenticated() && Parse.User.current().id === id) {
          history.push('/home');
        }
      }, (error) => {
        if (error.code === 209) {
          this.setState({ ...this.state, error: 'user needs to log in first', loading: false });
        }
      });
    } else if (this.props.location && error) {
      this.setState({ ...this.state, error });
    } else if (getCurrentUser() && this.props.location.pathname !== '/login') {
      history.push('/home');
    } else if (error) {
      if (error === '209') this.setState({ ...this.state, error: 'Session has expired. Please login again.', loading: false });
    }
  }

  tryLogin() {
    this.setState({ ...this.state, disableLogin: true, error: '' });
    tryLogin(this.state.fields).then(() => {
      this.setState({ ...this.state, disableLogin: false });
      history.push('/home');
    }, () => {
      this.setState({ ...this.state, disableLogin: false });
    });
  }

  handleFormChange(property, value) {
    const newState = { ...this.state };
    newState.fields[property] = value;
    this.setState(newState);
  }

  emailHandler(email) {
    // opens modal to confirm that the user does want to go through with it
    this.setState({ ...this.state, showForgotPasswordModal: true, email });
  }

  sendEmail(email) {
    const promise = new Promise((resolve, reject) => {
      resetDispatcherPassword(email).then(userObject => {
        resolve(userObject);
      }, error => reject(error));
    });
    return promise;
  }

  handleClose() {
    this.setState({ ...this.state, showForgotPasswordModal: false });
  }

  handleSignup() {
    const queryStrObj = getDeserialized(this.props.location.search).params;
    queryStrObj.v = 'fleet';
    queryStrObj.email = this.state.fields.email;
    history.push({ pathname: 'register', search: getSerialized(queryStrObj).query });
  }

  render() {
    let error = this.state.error;
    if (this.props.Auth && this.props.Auth.error) {
      error = this.props.Auth.error.message;
    }
    return (
      <div>
        <LoginForm
          error={error}
          disableLogin={this.state.disableLogin}
          emailHandler={this.emailHandler}
          handleSubmit={this.tryLogin}
          handleFormChange={this.handleFormChange}
          fields={this.state.fields}
          hideLoginFields={this.state.hideLoginFields}
          handleSignup={this.handleSignup}
          loading={this.state.loading}
        />
        <ForgotPasswordModal
          show={this.state.showForgotPasswordModal}
          email={this.state.fields.email}
          forgotPasswordFormHandler={this.sendEmail}
          handleClose={this.handleClose}
        />
      </div>
    );
  }
}

Login.propTypes = {
  location: PropTypes.object,
  Auth: PropTypes.object,
};

const mapStateToProps = (state) => {
  if (state) {
    const { Auth } = state;
    return {
      Auth,
    };
  }
  return 0;
};

export default withRouter(connect(mapStateToProps, null, null, { context: StoreContext })(Login));

