/**
 * Todos:
 * Add organization form
 */

import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// api
import { updateDispatchJob } from 'api/Dispatch/DispatchJob';
import { updateDispatchTransfer } from 'api/Dispatch/DispatchTransfer';

// enums
import { Organization, OrganizationName } from 'sb-csapi/dist/enums/Dispatch/Organization';

// components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import AddressDialog from 'sbCore/AddressDialog/AddressDialog';
import DispatchOrganizationAutocompleteInput from 'components/Dispatch/DispatchOrganizationAutocomplete/DispatchOrganizationAutocompleteInput';
import DispatchOrganizationCard from 'components/Dispatch/DispatchOrganizationAutocomplete/DispatchOrganizationCard';
import DispatchOrganizationEditDialog from 'components/Dispatch/DispatchOrganizationAutocomplete/DispatchOrganizationEditDialog';

import './style.scss';

/**
 * @description Contains an input to filter organizations, a button to add a new organization, and the form to add a new organization
 * @param {String} className - Custom container className
 * @param {Object} style - Custom inline styles
 * @param {DispatchJob} dispatchJob - If provided, the DispatchOrganization chosen will be set to the DispatchJob
 * @param {DispatchShipment} dispatchShipment - If provided, the dispatchShipment chosen will be set to the DispatchJob
 * @param {DispatchOrganization} dispatchOrganization - If provided, sets it as the state's dispatchOrganization to show the organization info
 * @param {Function} [onSaveAddress] - If provided, updates the dispatch transfer address with the custom address
 * @param {Address} [address] - Address record
 * @param {Function} onSelectDispatchOrganization - If provided, callback that returns the selected dispatchOrganization
 * @param {Function} onToggleEditDispatchOrganization - If provided, callback that lets parent know the add/edit organization form was toggled and the bool value
 * @param {Int} type - The type of organization. If provided, specifically limits queries to that type only. sappy enums/Dispatch/Organization
 * @param {bool} allowAddDispatchOrganization - Shows the text button under the input to allow the adding of an organization
 * @param {bool} warning - Makes the input border yellow
 * @param {bool} autoFocus - Whether to automatically focus on the input on load
 * @param {bool} disabled - Whether disable the input
 * @param {bool} isLoading - Whether to show a loading indicator
 * @param {bool} hideLabel - Whether to hide the label of the autocomplete
 * @returns
 *
 * @example
 * <DispatchOrganizationAutocomplete type={Organization.CUSTOMER} warning />
 */
export default function DispatchOrganizationAutocomplete({ ...props }) {

  const [identifier] = useState(uniqid()); // for each of this component that exists on the same page, give it unique identifier for specific dom manipulation
  const [dispatchOrganization, setDispatchOrganization] = useState(undefined);
  const [dispatchOrganizationTrigger, setDispatchOrganizationTrigger] = useState(undefined); // 0: remove the organization from the job. 1: add the selected organization to the job
  const [isLoading, setIsLoading] = useState(false); // local version is isLoading (in addition to props.isLoading)
  const [showEditForm, setShowEditForm] = useState(false); // whether or not to show the organization add / edit form
  const [showAddressDialog, setShowAddressDialog] = useState(false);

  useEffect(() => {
    setDispatchOrganization(props.dispatchOrganization);
  }, [props.dispatchOrganization]);

  useEffect(() => {

    if (dispatchOrganizationTrigger === undefined) return;

    let didCancel = false;

    let keyValueObj = {}; // the key-value map of properties we wish to update for the job or transfer
    if (props.type === Organization.CARRIER) {
      keyValueObj.carrierDispatchOrganization = dispatchOrganization;
    } else if (props.type === Organization.CUSTOMER) {
      keyValueObj.customerDispatchOrganization = dispatchOrganization;
    } else if (props.type === Organization.SHIPPER) {
      keyValueObj.shipperDispatchOrganization = dispatchOrganization;
    } else if (props.type === Organization.CONSIGNEE) {
      keyValueObj.consigneeDispatchOrganization = dispatchOrganization;
    }

    if ((props.type !== undefined) && props.dispatchJob) {
      // update the job with the selected (or unselected) organization. right now, only dispatchJob.customerDispatchOrganization is supported
      async function _updateDispatchJob() {
        setIsLoading(true);
        const dispatchJob = await updateDispatchJob(undefined, props.dispatchJob, undefined, keyValueObj, true);

        if (!didCancel) {
          setIsLoading(false);
        }
        setDispatchOrganizationTrigger(undefined); // don't forget to reset the trigger so it no longer triggers an action
      }

      _updateDispatchJob();
    }

    if ((props.type !== undefined) && props.dispatchShipment) {
      // update the transfer with the selected (or unselected) shipment. right now, only consigneeDispatchOrganization and shipperDispatchOrganization are supported
      async function _updateDispatchTransfer() {
        setIsLoading(true);
        const dispatchTransfer = await updateDispatchTransfer(props.dispatchShipment, undefined, keyValueObj, true);

        if (!didCancel) {
          setIsLoading(false);
        }
        setDispatchOrganizationTrigger(undefined); // don't forget to reset the trigger so it no longer triggers an action
      }

      _updateDispatchTransfer();
    }

    return () => { didCancel = true; };

  }, [dispatchOrganizationTrigger]);

  // ** Callbacks ** //
  // if the user selected an organization from the input
  function onSelectDispatchOrganization(dispatchOrganization) {
    // if props.dispatchJob is passed, assign the organization to it (handled in useEffect)
    // there's a weird case where the autocomplete's input's onSelectDispatchOrganization can be triggered by something, that ends up triggering this
    // even though no suggestions were selected that would prompt this function. so we add the if(dispatchOrganization) check
    if (dispatchOrganization) {
      setDispatchOrganization(dispatchOrganization);
      setShowEditForm(false); // if the add/edit form was open, close it
      if (props.onSelectDispatchOrganization) props.onSelectDispatchOrganization(dispatchOrganization);
      if (props.dispatchJob || props.dispatchShipment) setDispatchOrganizationTrigger(1);
    }
  }

  function onUnselectDispatchOrganization() {
    // if props.dispatchJob is passed, remove the organization from it (handled in useEffect)
    setDispatchOrganization(undefined);

    // pass in undefined instead of dispatchOrganization (which is being set to undefined above) since the state might not be updated yet. we do this over using a useEffect for convenience
    if (props.onSelectDispatchOrganization) props.onSelectDispatchOrganization(undefined);

    if (props.dispatchJob || props.dispatchShipment) setDispatchOrganizationTrigger(0);
  }

  function onToggleEditDispatchOrganization() {
    // toggle the add/edit organization form. also invoke the prop callback if provided
    setShowEditForm(!showEditForm);

    if (props.onToggleEditDispatchOrganization) {
      props.onToggleEditDispatchOrganization(showEditForm);
    }
  }

  function onToggleAddressDialog() {
    setShowAddressDialog(!showAddressDialog);
  }

  function onSaveAddress(address, keyValueObj) {
    setShowAddressDialog(false);
    if (props.onSaveAddress) props.onSaveAddress(address, keyValueObj);
  }

  // ** Misc ** //
  let className = `dispatch-organization-autocomplete ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  let label = 'Organization';
  if (props.type === Organization.CARRIER) {
    label = `${OrganizationName.CARRIER}`;
  } else if (props.type === Organization.CUSTOMER) {
    label = `${OrganizationName.CUSTOMER}`;
  } else if (props.type === Organization.SHIPPER) {
    label = `${OrganizationName.SHIPPER}`;
  } else if (props.type === Organization.CONSIGNEE) {
    label = `${OrganizationName.CONSIGNEE}`;
  }

  // disable if parent says so. if opening the Add Organization form, force disable as well
  let disabled = props.disabled;
  if (!disabled && showEditForm) {
    disabled = true; // if showing edit form, disable the input
  }

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && <InputLabel>{ label }</InputLabel>}

      { (!props.isLoading && !dispatchOrganization) && (
        <DispatchOrganizationAutocompleteInput
          type={props.type}
          autoFocus={props.autoFocus}
          warning={props.warning}
          disabled={disabled}
          onSelectDispatchOrganization={(dispatchOrganization) => onSelectDispatchOrganization(dispatchOrganization)}
          onToggleEditDispatchOrganization={() => onToggleEditDispatchOrganization()}
        />
      )}

      { (!props.isLoading && !dispatchOrganization && props.allowAddDispatchOrganization) && (
        <span className="label-button" onClick={() => onToggleEditDispatchOrganization()}>+ Create new { label.toLowerCase() }</span>
      )}

      { (!props.isLoading && dispatchOrganization) && (
        <DispatchOrganizationCard
          dispatchOrganization={dispatchOrganization}
          onUnselectDispatchOrganization={() => onUnselectDispatchOrganization()}
          onToggleEditDispatchOrganization={() => onToggleEditDispatchOrganization()}
          customAddress={props.address}
          onToggleAddressDialog={() => onToggleAddressDialog()}
        />
      )}

      { props.isLoading && (
        <DispatchOrganizationCard isLoading={props.isLoading || isLoading} />
      )}

      <DispatchOrganizationEditDialog
        visible={showEditForm}
        dispatchOrganization={dispatchOrganization}
        type={props.type}
        onCancel={() => onToggleEditDispatchOrganization()}
        onSave={(dispatchOrganization) => onSelectDispatchOrganization(dispatchOrganization)}
      />

      <AddressDialog
        visible={showAddressDialog}
        address={props.address}
        onCancelAddress={() => onToggleAddressDialog()}
        onSaveAddress={(address, keyValueObj) => onSaveAddress(address, keyValueObj)}
      />
    </div>
  );
}
