import React from 'react';

import { ConfirmDialog as PRConfirmDialog } from 'primereact/confirmdialog';

import './style.scss';

/**
 * @description PrimeReact's ConfirmDialog component
 * @param {String} [className] - Custom container className
 * @returns
 */
function ConfirmDialog({ ...props }) {
  let className = 'sbc-confirm-dialog';
  if (props.className) className += ` ${props.className}`;

  return (<PRConfirmDialog {...props} className={className} />);
}

export default ConfirmDialog;
