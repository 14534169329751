// http://londoncycle.alexrieux.fr/
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// API
import { addGeofence } from 'api/Setters';
import * as Getters from 'api/Getters';
import { getCompanyReadWriteACL, convertCornersToCenterAndLength, isSubscribedToModule } from 'api/Helpers';
import { addGeofenceToState, updateGeofenceForState } from 'actions/Geofence';
import * as Analytics from 'api/Analytics';

// Components
import GeofenceObject from 'components/Map.old/container/components/GeofenceObject';
import AddEditGeofenceDetails from 'components/Map.old/view/components/AddEditGeofenceDetails';

class AddEditGeofence extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      geofenceProperties: {},
      loadingWatch: false,
    };
    if (props.geofence) {
      // Existing Geofence
      const topLat = props.geofence.get('topLat');
      const bottomLat = props.geofence.get('bottomLat');
      const leftLong = props.geofence.get('leftLong');
      const rightLong = props.geofence.get('rightLong');
      const centerAndLengthObj = convertCornersToCenterAndLength(topLat, bottomLat, leftLong, rightLong);
      this.state.geofenceProperties.width = centerAndLengthObj.width;
      this.state.geofenceProperties.height = centerAndLengthObj.height;
      this.state.geofenceProperties.longitude = centerAndLengthObj.longitude;
      this.state.geofenceProperties.latitude = centerAndLengthObj.latitude;
      this.state.geofenceProperties.name = props.geofence.get('name');
      this.state.geofenceProperties.speedLimitKm = props.geofence.get('speedLimitKm');
      this.state.geofenceProperties.watchedBy = props.geofence.get('watchedBy');
    } else {
      // New Geofence
      this.state.geofenceProperties.latitude = undefined;
      this.state.geofenceProperties.longitude = undefined;
      this.state.geofenceProperties.width = 100.82 * Math.exp(-0.866 * props.zoom) * 1.5;
      this.state.geofenceProperties.height = 100.82 * Math.exp(-0.866 * props.zoom);
      this.state.geofenceProperties.name = '';
      this.state.geofenceProperties.speedLimitKm = null;
      this.state.geofenceProperties.watchedBy = [Getters.getCurrentUser()];
    }

    this.onClick = this.onClick.bind(this);
    this.adjustGeofence = this.adjustGeofence.bind(this);
    this.watchUnwatchGeofence = this.watchUnwatchGeofence.bind(this);
    this.save = this.save.bind(this);
    this.delete = this.delete.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  componentWillMount() {
    this.props.map.on('click', this.onClick);
  }

  componentWillUnmount() {
    this.props.map.off('click', this.onClick);
  }

  onClick(e) {
    const coords = e.lngLat;
    this.setState({ ...this.state, geofenceProperties: { ...this.state.geofenceProperties, latitude: coords.lat, longitude: coords.lng } });
  }

  adjustGeofence(property, value) {
    const newState = { ...this.state };
    newState.geofenceProperties[property] = value;
    this.setState(newState);
  }

  watchUnwatchGeofence(watchBool) {
    // console.log(watchBool);
    const user = Getters.getCurrentUser();

    const getUserIndexInWatchedBy = (user) => {
      const watchedByArr = this.state.geofenceProperties.watchedBy;
      for (let i = 0; i < watchedByArr.length; i++) {
        const watchedByObj = watchedByArr[i];
        if (watchedByObj.id === user.id) {
          return i;
        }
      }
      return -1;
    }

    const newState = { ...this.state };
    if (watchBool) {
      newState.geofenceProperties.watchedBy.push(user);
    } else {
      const index = getUserIndexInWatchedBy(user);
      if (index !== -1) newState.geofenceProperties.watchedBy.splice(index, 1);
    }
    this.setState(newState);
  }

  save() {
    if (!this.state.geofenceProperties.name) {
      this.setState({ ...this.state, error: 'Need to enter a name' });
      return;
    }
    const topLat = this.state.geofenceProperties.latitude + (this.state.geofenceProperties.height / 2.0);
    const bottomLat = this.state.geofenceProperties.latitude - (this.state.geofenceProperties.height / 2.0);
    const leftLong = this.state.geofenceProperties.longitude - (this.state.geofenceProperties.width / 2.0);
    const rightLong = this.state.geofenceProperties.longitude + (this.state.geofenceProperties.width / 2.0);
    if (this.props.type === 'add') {
      addGeofence({
        name: this.state.geofenceProperties.name,
        speedLimitKm: this.state.geofenceProperties.speedLimitKm,
        watchedBy: this.state.geofenceProperties.watchedBy,
        topLat,
        bottomLat,
        leftLong,
        rightLong,
      }).then((parseObj) => {
        addGeofenceToState(parseObj);
        this.props.handleClose();

        Analytics.identifyOnceForCompany('GEOFCREATE', {
          'Geofence Created': true,
          'GeofenceCreateDate': moment().toISOString(),
        });
      });
    } else if (this.props.type === 'edit') {
      this.props.geofence.set('name', this.state.geofenceProperties.name);
      this.props.geofence.set('speedLimitKm', this.state.geofenceProperties.speedLimitKm);
      this.props.geofence.set('topLat', topLat);
      this.props.geofence.set('bottomLat', bottomLat);
      this.props.geofence.set('leftLong', leftLong);
      this.props.geofence.set('rightLong', rightLong);
      this.props.geofence.set('watchedBy', this.state.geofenceProperties.watchedBy);
      this.props.geofence.save().then((updatedGeofence) => {
        updateGeofenceForState(updatedGeofence).then(() => {
          this.props.handleClose();
        });
      });
    }
  }

  delete() {
    this.props.geofence.set('enabled', false);
    this.props.geofence.save().then(() => {
      this.props.refreshGeofences();
    });
    this.props.handleClose();
  }

  cancel() {
    this.props.handleClose();
  }

  render() {
    return (
      <div>
        <GeofenceObject
          map={this.props.map}
          latitude={this.state.geofenceProperties.latitude}
          longitude={this.state.geofenceProperties.longitude}
          width={this.state.geofenceProperties.width}
          height={this.state.geofenceProperties.height}
          movePoint={(latitude, longitude) => this.setState({ ...this.state, geofenceProperties: { ...this.state.geofenceProperties, latitude, longitude } })}
        />
        <AddEditGeofenceDetails
          geofenceProperties={this.state.geofenceProperties}
          type={this.props.type}
          error={this.state.error}
          adjustGeofence={this.adjustGeofence}
          delete={this.delete}
          cancel={this.cancel}
          save={this.save}
          zoom={this.props.zoom}
          enableSpeedLimitSetting={isSubscribedToModule('driverBehaviourModule')}
          isSidebarOpen={this.props.isSidebarOpen}
          watchUnwatchGeofence={this.watchUnwatchGeofence}
          laodingWatch={this.state.loadingWatch}
          user={Getters.getCurrentUser()}
        />
      </div>
    );
  }
}

AddEditGeofence.propTypes = {
  map: PropTypes.object.isRequired,
  zoom: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  geofence: PropTypes.object,
  refreshGeofences: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AddEditGeofence;
