import React, { useState, useEffect } from 'react';

// Enums
import { AppointmentType } from 'sb-csapi/dist/enums/Dispatch/Appointment';

// sbCore components
import Dropdown from 'sbCore/Dropdown/Dropdown';
import InputLabel from 'sbCore/InputLabel/InputLabel';

// styles
import './style.scss';

/**
 * @description Appointment type dropdown
 * @param {String} className - Custom container panelClassName
 * @param {String} dropdownClassName - Custom dropdown className
 * @param {Object} style - Custom inline styles
 * @param {String} label - Custom label
 * @param {Bool} warning - Makes the border yellow
 * @param {Bool} error - Makes the border red
 * @param {Function} onSelect - Callback function that returns the selected appointment object
 * @param {int} appointment - the value of appointment enum. The int provided must be recognized in our enums
 * @param {Bool} hideLabel - Hide the label
 * @param {Bool} disabled - Whether to disbale the dropdown or not
 * @example
 * <DispatchAppointmentDropdown appointment={0} onSelect={(e) => handleSelect(e)} />
 */
function DispatchAppointmentDropdown({ ...props }) {
  // Stores the value of enum
  const [selectedAppointmentType, setSelectedAppointmentType] = useState();

  const appointmentTypeOptions = Object.keys(AppointmentType).map((type) => ({
    value: AppointmentType[type].value,
    label: AppointmentType[type].description,
  }));

  // Set the first item in enum as default
  const defaultOption = appointmentTypeOptions[0].value;

  /* -------------------------------- useEffect ------------------------------- */
  useEffect(() => {
    setSelectedAppointmentType(props.appointment);
  }, [props.appointment]);

  /* --------------------------------- Helper --------------------------------- */
  function onChange(e) {
    setSelectedAppointmentType(e.value);
    if (props.onSelect)
      props.onSelect(appointmentTypeOptions.find((appointmentType) => appointmentType.value === e.value));
  }

  /* ---------------------------------- Misc ---------------------------------- */
  let className = 'appointment-type-dropdown';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && <InputLabel>{props.label || 'Appointment'}</InputLabel>}
      <Dropdown
        className={props.dropdownClassName}
        value={selectedAppointmentType || defaultOption}
        options={appointmentTypeOptions}
        optionLabel="label"
        onChange={(e) => onChange(e)}
        onBlur={(e) => {
          if (props.onBlur) props.onBlur(e);
        }}
        disabled={props.disabled}
        error={props.error}
        warning={props.warning}
      />
    </div>
  );
}

export default DispatchAppointmentDropdown;
