import React from 'react';
import PropTypes from 'prop-types';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBIcon, MDBInput } from 'mdbreact';

//api
import { getAttribute, getObjectById, updateRecord } from 'api/Parse';

//components
import SBCard from 'components/Shared/SBCard/SBCard';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';

//style
import './styles.scss';

class DispatchConfirmModal extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      isBusy: false, 
      dispatchJob: undefined,
      status: undefined
    };
    // this.handleClose = this.handleClose.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidMount() {
    this.refreshState();
  }

  async refreshState() {
    //clear fields after editing
    const dispatchJob = await getObjectById('DispatchJob', this.props.dispatchJobObjectId, undefined);
    const status = this.props.status;

    return await this.setState({ ...this.state, dispatchJob, status });
  }

  async handleSubmit(confirmBool) { 
    //updateJob
    if (confirmBool ) {
      this.props.handleSubmit();
    }
    this.props.handleClose();
  } 

  handleToggle() {
    return;
  }

  render() {
    const { state, props } = this;
    let isEdit = false;

    return (
      <ConfirmModal show={props.show} handleModalChoice={(confirmBool) => this.handleSubmit(confirmBool)} handleClose={this.props.handleClose}>
      {props.confirmText}
      </ConfirmModal>
    );
  }
}

DispatchConfirmModal.propTypes = {
  dispatchJobObjectId: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func
}

export default DispatchConfirmModal;