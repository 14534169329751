import React from 'react';
import PropTypes from 'prop-types';
// CSS
import styles from './MapStyleToggle.module.scss';

function MapStyleToggle(props) {
  return (
    <div className={styles.container + " d-none d-lg-block translate-me"}>
      <div onClick={() => props.activeMapStyle === 'street' ? props.chooseMapStyle('streetNight') : props.chooseMapStyle('street')} className={(props.activeMapStyle === 'street' || props.activeMapStyle === 'streetNight') ? `${styles.active} ${styles.button}` : styles.button}>Street</div>
      <div onClick={() => props.chooseMapStyle('satellite')} className={props.activeMapStyle === 'satellite' ? `${styles.active} ${styles.button}` : styles.button}>Satellite</div>
    </div>
  );
}

MapStyleToggle.propTypes = {
  activeMapStyle: PropTypes.string.isRequired,
  chooseMapStyle: PropTypes.func.isRequired,
};

export default MapStyleToggle;
