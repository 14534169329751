import Parse from 'parse';
import PubNub from 'pubnub';

import * as Notification from 'actions/Notification';
import * as Constants from 'api/Lists/Constants';
import { formatPubNubNotificationObject } from 'api/Helpers';
import { vehicleUpdateHandler } from 'actions/Vehicle';
import { vehicleLocationUpdateHandler } from 'actions/VehicleLocation';
import { handleChatMessageReceive } from 'actions/Chat';

import store from '../store';

/** @module Pubnub */

/**
 * @memberof module:Pubnub
 * @param {*} userParseObj 
 * @returns 
 */
function setUpPubNubClient(userParseObj) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('getPubNubKey').then((keyObject) => {
      const pubnub = new PubNub({
        subscribeKey: keyObject.subscribeKey,
        publishKey: keyObject.publishKey,
        authKey: userParseObj.id,
        ssl: true,
      });
      pubnub.subscribe({
        channels: [userParseObj.get('belongsToCompany').id, userParseObj.id, 'Switchboard'],
      });
      pubnub.addListener({
        message: (messageObj) => {
          // VehicleLocation Updates
          if (messageObj.message.type === 'refresh') {
            location.reload();
          } else if (messageObj.subscribedChannel === `${userParseObj.get('belongsToCompany').id}VehicleLocation`) {
            const vehicleLocationJSON = messageObj.message;
              // Updating Vehicle from VehicleLocation Update
            if (vehicleLocationJSON.vehicle) { vehicleUpdateHandler(vehicleLocationJSON.vehicle); }
              // Updating VehicleLocation from VehicleLocation Update
            vehicleLocationUpdateHandler(vehicleLocationJSON);
          } else if (messageObj.message && messageObj.message.type === 'chatMessage') {
              // chat message. dont do anything if the sender is this current user
            const response = messageObj.message.response;
            if (response.sender.objectId !== userParseObj.id) {
              handleChatMessageReceive(response);
            }
          } else if (messageObj.subscribedChannel === `${userParseObj.get('belongsToCompany').id}`) {
            if (messageObj && messageObj.message && messageObj.message.notificationClass === 'EngineModuleConnectivityStatus') {
              const notificationObject = formatPubNubNotificationObject(messageObj);
              Notification.notificationReceiveHandler(notificationObject);
            } else {
              // const notificationObject = formatPubNubNotificationObject(messageObj);
              Notification.notificationReceiveHandler(messageObj);
            }
          } else if (messageObj.subscribedChannel === `${userParseObj.get('belongsToCompany').id}Admin`) {
              // our special formatted notification text representing notificationObject
            const notificationObject = formatPubNubNotificationObject(messageObj);
            Notification.notificationReceiveHandler(notificationObject);
          }
        },
      });
      resolve(pubnub);
    });
  });
  return promise;
}

/**
 * @memberof module:Pubnub
 * @param {*} channelName 
 * @param {*} subscribeBool 
 */
function subscribeUnsubscribeChannel(channelName, subscribeBool) {
  store.getState().Main.pubNub.setupPromise.then(() => {
    const pubNubClient = store.getState().Main.pubNub.client;
    if (subscribeBool) {
      pubNubClient.subscribe({
        channels: [channelName],
      });
    } else {
      pubNubClient.unsubscribe({
        channels: [channelName],
      });
    }
  });
}

/**
 * @memberof module:Pubnub 
 */
function unsubscribeAll() {
  if (store.getState().Main.pubNub && store.getState().Main.pubNub.setupPromise) {
    const pubNub = store.getState().Main.pubNub;
    const pubNubClient = pubNub.client;
    pubNubClient.unsubscribeAll();
  }
}

export {
  setUpPubNubClient,
  subscribeUnsubscribeChannel,
  unsubscribeAll,
};
