import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

// API
import { getVisionEvents, getDateTimeStringFromVisionEventVideo } from 'api/Vision/VisionEvent';
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import Column from 'sbCore/Column/Column';
import DataTable from 'sbCore/DataTable/DataTable';
import EventTypeChip from 'components/Vision/EventTypeChip/EventTypeChip';

// SBObject
import Sort from 'sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Enums
import { QueryRestriction, QuerySortOrderTypes } from 'sb-csapi/dist/enums/Query';

// Hooks
import { usePrevious } from 'hooks/usePrevious';

// CSS
import './style.scss';

const initialFetchAttributes = {
  first: 0,
  rows: 5,
  page: 0,
  sortField: null,
  sortOrder: null,
  filters: {},
  dbSort: new Sort('eventDateTime', QuerySortOrderTypes.DESCENDING),
  dbFilters: [new Filter(QueryRestriction.EXISTS, 'video', undefined)],
};

/**
 * @description Creates the Events List card for Vision Module
 * @param {String} unitId - The vehicle unitId that we want to retrieve VisionEvents for
 * @param {bool} showDashcamSnapshot - Determines whether we are displaying the dashcam snapshot
 * @param {Function} onSelectVisionEvent - Callback function when a vision event is selected
 * @returns {JSX} The VisionPlaylist component
 */
function VisionPlaylist(props) {
  const [isLoading, setIsLoading] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [selectedVisionEventObject, setSelectedVisionEventObject] = useState(undefined);
  const [totalVisionEventsCount, setTotalVisionEventsCount] = useState();
  const [fetchAttributes, setFetchAttributes] = useState(initialFetchAttributes);
  const [lastCheckInDateTime, setLastCheckInDateTime] = useState(undefined);

  const prevUnitId = usePrevious(props.unitId);

  function resetState() {
    setIsLoading(false);
    setTableData([]);
    setSelectedVisionEventObject(undefined);
    setTotalVisionEventsCount(0);
    setFetchAttributes(initialFetchAttributes);
    setLastCheckInDateTime(undefined);
  }

  async function _getVisionEvents() {
    const { unitId } = props;

    setIsLoading(true);
    const { totalVisionEventsCount, visionEvents } = await getVisionEvents(unitId, prevUnitId === unitId ? fetchAttributes.page : 0, fetchAttributes.rows, fetchAttributes.dbSort, fetchAttributes.dbFilters);

    if (!visionEvents || visionEvents.length === 0) {
      resetState(); // Reset the state
      return;
    }

    const _tableData = visionEvents.map((visionEvent) => {
      const visionEventObjectId = getAttribute(visionEvent, 'objectId');
      const eventDateTime = getAttribute(visionEvent, 'eventDateTime');
      const eventCodeInt = getAttribute(visionEvent, 'eventCodeInt');

      return {
        visionEvent,
        visionEventObjectId,
        eventDateTime: moment.utc(eventDateTime),
        eventCodeInt,
      };
    });

    // Previously, whenever a new vehicle is selected, it would autoplay the first video
    // This is now changed so that the user has to select a video first before it starts playing
    // The change was made to allow the dashcam snapshot to be the default view (if a snapshot exists)
    if (prevUnitId !== unitId || !selectedVisionEventObject) {
      setSelectedVisionEventObject(undefined);
      // setSelectedVisionEventObject(_tableData[0]);
      // props.onSelectVisionEvent && props.onSelectVisionEvent(visionEvents[0]);
    }

    setTotalVisionEventsCount(totalVisionEventsCount);
    setTableData(_tableData);
    setIsLoading(false);
  }

  // Whenever a new unitId is passed in, reset the state and refetch the events
  useEffect(() => {
    resetState();
    if (props.unitId) _getVisionEvents();
  }, [props.unitId]);

  // Whenever we switch to displaying the dashcam snapshot, reset the selected vision event
  useEffect(() => {
    if (props.showDashcamSnapshot) {
      setSelectedVisionEventObject(undefined);
    }
  }, [props.showDashcamSnapshot]);

  // Whenever one of the filter attributes are changed on the data table, refetch the events
  useEffect(() => {
    if (props.unitId) _getVisionEvents();
  }, [fetchAttributes]);

  useEffect(() => {
    const { unitId, visionVehicle } = props;

    if (visionVehicle && getAttribute(visionVehicle, 'vehicleUnitId') === unitId) {
      const _lastCheckInDateTime = getAttribute(visionVehicle, 'lastCheckInDateTime');
      setLastCheckInDateTime(_lastCheckInDateTime);
    }
  }, [props.visionVehicle]);

  function handleSelectionChange(e) {
    props.onSelectVisionEvent(e.value.visionEvent);
    setSelectedVisionEventObject(e.value);
  }

  function itemBodyTemplate(rowData) {
    const { visionEvent } = rowData;

    return (
      <div className="w-full flex align-items-center">
        {/* The video snapshots will be introduced later after we have it implemented */}
        {/* <div className="vision-event-snapshot mr-2" /> */}
        <div className="vision-event-datetime my-3">
          {getDateTimeStringFromVisionEventVideo(visionEvent)}
          {/* {eventDateTime && eventDateTime.format('YYYY-MM-DD HH:mm')} */}
        </div>
      </div>
    );
  }

  function eventTypeBodyTemplate(rowData) {
    const { eventCodeInt } = rowData;

    return (
      <div className="w-full flex align-items-center justify-content-between">
        <EventTypeChip eventCodeInt={eventCodeInt} className="flex-1 justify-content-center" />
      </div>
    );
  }

  return (
    <div className="vision-playlist flex flex-column h-full px-2">
      <div className="flex flex-column lg:flex-row py-2 lg:align-items-center justify-content-between translate-me">
        <div className="text-xl font-bold">
          Events List
        </div>
        <div className={`text-sm text-500${!lastCheckInDateTime ? ' hidden' : ''}`}>
          {`Last Updated: ${moment.utc(lastCheckInDateTime).format('YYYY-MM-DD @ HH:mm')}`}
        </div>
      </div>
      <DataTable
        value={tableData}
        cellClassName="py-2"
        rowClassName="vision-playlist-datatable-row"
        selectionMode="single"
        selection={selectedVisionEventObject}
        onSelectionChange={e => handleSelectionChange(e)}
        dataKey="visionEventObjectId"
        rowHover
        paginator
        rows={fetchAttributes.rows}
        totalRecords={totalVisionEventsCount}
        lazy
        scrollable
        scrollHeight="flex"
        first={fetchAttributes.first}
        onPage={(e) => setFetchAttributes({ ...fetchAttributes, page: e.first / e.rows, first: e.first })}
        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
        pageLinkSize={4}
        responsiveLayout="scroll"
        emptyMessage="No dashcam events found"
        loading={isLoading}
      >
        <Column
          field="eventDateTime"
          header="Date"
          style={{ minWidth: '10rem', width: '10rem', maxWidth: '10rem' }}
          body={itemBodyTemplate}
        />
        <Column
          field="eventCodeInt"
          header="Event Type"
          style={{ minWidth: '7rem', width: '7rem' }}
          body={eventTypeBodyTemplate}
        />
      </DataTable>
      <div className="text-right font-light text-sm mt-2 translate-me">
        Note: Dashcam times are in UTC
      </div>
    </div>
  );
}

export default VisionPlaylist;
