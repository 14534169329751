import Parse from 'parse';
import history from 'sbHistory';

import * as ACL from 'api/ACL';
import { initializeCSAPI } from 'sb-csapi';
import { QueryRestrictionTypes } from 'enums/Query';

/** @module Parse */

/**
 * @memberof module:Parse
 *
 * @param {*} parseAppId
 * @param {*} parseJSKey
 * @param {*} parseServerURL
 */
export function start(parseAppId, parseJSKey, parseServerURL) {
  Parse.serverURL = parseServerURL;
  Parse.initialize(parseAppId, parseJSKey);
  initializeCSAPI(Parse);
}

/**
 * @memberof module:Parse
 *
 * @param {*} email
 * @param {*} password
 *
 * @returns
 */
export function login(email, password) {
  const formattedEmail = email.toLowerCase();
  return Parse.User.logIn(formattedEmail, password);
}

/**
 * @memberof module:Parse
 * @returns
 */
export async function logout() {
  if (!Parse.User.current() || !Parse.User.current().authenticated()) {
    return null;
  }
  return Parse.User.logOut();
}

/**
 * @memberof module:Parse
 * @returns
 */
export function getCurrentUser() {
  if (!Parse.User.current()) {
    return null;
  }
  return Parse.User.current();
}

/**
 * @memberof module:Parse
 *
 * @param {*} parseClass
 * @param {*} objectId
 * @param {*} wantPointerBool
 *
 * @returns
 */
export function makeParseObjectById(parseClass, objectId, wantPointerBool) {
  const pointer = new Parse.Object(parseClass);
  pointer.id = objectId;
  if (wantPointerBool) {
    return pointer.toPointer();
  }
  return pointer;
}

/**
 * @memberof module:Parse
 * @param {*} err
 */
export function handleParseError(err) {
  switch (err.code) {
    case Parse.Error.INVALID_SESSION_TOKEN:
      Parse.User.logOut();
      window.location.assign(`/login?error=${err.code}`); // Redirects to login page while refreshing the page
      break;
    default:
      throw new Error(err);
  }
}

/**
 * @memberof module:Parse
 * @param {*} eventHandler
 * @returns
 */
export function subscribeToNotifications(eventHandler) {
  const userQuery = new Parse.Query(Parse.User);
  userQuery.equalTo('objectId', Parse.User.current().id);
  const dispatcherQuery = new Parse.Query('Dispatcher');
  dispatcherQuery.matchesQuery('user', userQuery);
  const notificationQuery = new Parse.Query('Notification');
  notificationQuery.matchesQuery('recipientId', dispatcherQuery);
  notificationQuery.include(['senderId']);
  notificationQuery.descending('createdAt');

  try {
    const subscription = notificationQuery.subscribe();
    subscription.on('create', object => eventHandler(object));
    return subscription;
  } catch (err) {
    handleParseError(err);
  }

}

/**
 * @memberof module:Parse
 * @param {*} subscription
 */
export function unsubscribeFromLivequery(subscription) {
  try {
    subscription.unsubscribe();
  } catch (err) {
    handleParseError(err);
  }
}

/**
 * @memberof module:Parse
 *
 * @param {*} parseUserOrUserIdArray
 * @param {*} message
 * @param {*} isIdArray
 *
 * @returns
 */
export function sendPush(parseUserOrUserIdArray, message, isIdArray) {
  // Checks if id of userObjects or userIds
  if (isIdArray) {
    return Parse.Cloud.run('sendPush', { userIdArray: parseUserOrUserIdArray, message });
  }
  const parseUserArrayLength = parseUserOrUserIdArray.length;
  const userIdArray = [];
  for (let i = 0; i < parseUserArrayLength; i++) {
    if (parseUserOrUserIdArray[i].id) {
      userIdArray.push(parseUserOrUserIdArray[i].id);
    }
  }
  return Parse.Cloud.run('sendPush', { userIdArray, message });
}

/**
 * @memberof module:Parse
 * @param {*} parseQueryObj
 * @returns
 */
export function queryLongWithSkip(parseQueryObj) {
  parseQueryObj.limit(1000);
  const result = [];
  let skip = 0;
  const promise = new Promise((resolve) => {
    const pushToResultAndRequeryIfNecessary = function (parseObjectArr, parseQueryObject) {
      result.concat(...[parseObjectArr]);
      if (parseObjectArr.length === 1000) {
        skip++;
        queryWithSkip(parseQueryObject, skip);
      } else {
        resolve(result);
      }
    };

    const queryWithSkip = (parseQueryObject, skipInt) => {
      const parseQueryWithSkip = parseQueryObject.skip(1000 * skipInt);
      parseQueryWithSkip.find()
        .then((parseObjectArr) => pushToResultAndRequeryIfNecessary(parseObjectArr, parseQueryObject))
        .catch((err) => handleParseError(err));
    };

    queryWithSkip(parseQueryObj, 0);
  });
  return promise;
}

/**
 * @memberof module:Parse
 * @param {*} registerValuesObject
 * @returns
 */
export function registerAdminToParse(registerValuesObject) {
  const promise = new Promise((resolve, reject) => {
    const user = new Parse.User();
    user.set('username', registerValuesObject.emailAddress);
    user.set('password', registerValuesObject.password);
    user.set('userType', [0]);
    user.signUp().then(
      parseUser => {
        Parse.Cloud.run('registerNewCompanyAndUser', { ...registerValuesObject, userId: parseUser.id }).then(
          updatedParseUser => resolve(updatedParseUser),
          error => {
            console.log(error);
            reject(error);
          }
        );
      },
      error => {
        console.log(error);
        reject(error);
      }
    );
  });
  return promise;
}


/**
 * Database API Abstractions
 */
const dbHelpers = {
  findAllFromQuery: async function (query, limit = 500) {
    // given a query, retrieve all without output limit restrictions
    let aggregateResults = []; // all results go in here
    let page = 0;
    async function findAll(query) {
      setQueryRestriction(query, QueryRestrictionTypes.LIMIT, undefined, limit);
      const results = await find(query);
      aggregateResults = aggregateResults.concat(results);
      if (results.length < limit) {
        return aggregateResults;
      } else {
        page++;
        setQueryRestriction(query, QueryRestrictionTypes.SKIP, undefined, page * limit);
        return await findAll(query);
      }
    }
    try {
      return await findAll(query);
    } catch (err) {
      handleParseError(err);
    }
  },
};

/**
 * @memberof module:Parse
 * @param {*} tableName
 * @returns
 */
export function createQuery(tableName) {
  if (!tableName) throw new Error('Must provide Table tableName to query');
  return new Parse.Query(tableName);
}

/**
 * @memberof module:Parse
 *
 * @param {*} query
 * @param {*} isDeepClone
 *
 * @returns
 */
export function copyQuery(query, isDeepClone) {
  if (!query) throw new Error('Must provide query to copy');
  if (!query.className) throw new Error('Query must provide certain Table/className');
  let queryClone = query.toJSON();
  queryClone = Parse.Query.fromJSON(query.className, queryClone);
  if (isDeepClone) queryClone._where = { ...query._where };
  return queryClone;
}

/**
 * @memberof module:Parse
 * @description modifies a query in-place given the appropriate restrictions (see: QueryRestrictionTypes)
 *
 * @param {*} query - query to be modified
 * @param {*} queryRestriction - particular restriction from QueryRestrictionTypes
 * @param {*} attribute - name of attribute to check
 * @param {*} value - value of attribute
 * @param {*} matchingAttribute - foreign attribute to match for matchesKeyInQuery
 */
export function setQueryRestriction(query, queryRestriction, attribute, value, matchingAttribute) {
  if ([QueryRestrictionTypes.EXISTS, QueryRestrictionTypes.DOES_NOT_EXIST].indexOf(queryRestriction) !== -1) {
    query[queryRestriction](attribute);
  } else if (queryRestriction === QueryRestrictionTypes.MATCHES) {
    query[queryRestriction](attribute, value, 'i');
  } else if (queryRestriction === QueryRestrictionTypes.MATCHES_KEY_IN_QUERY) {
    query[queryRestriction](attribute, matchingAttribute, value);
  } else if ((queryRestriction === QueryRestrictionTypes.LIMIT) || (queryRestriction === QueryRestrictionTypes.SKIP)) {
    query[queryRestriction](value);
  } else {
    query[queryRestriction](attribute, value);
  }
}

/**
 * @memberof module:Parse
 * @description Given a query, execute it. Will return an n-length array
 *
 * @param {object} query
 * @param {bool} returnFirstResult - return only the first result of the query
 * @param {bool} findAll - return all results of a query without limit restrictions
 */
export async function find(query, returnFirstResult, findAll) {
  if (returnFirstResult && findAll) {
    throw new Error('Cannot execute both returnFirstResult and findAll');
  }

  try {
    if (findAll) return dbHelpers.findAllFromQuery(query);
    if (returnFirstResult) {
      return await query.first();
    }

    const results = await query.find();
    return results;
  } catch (err) {
    handleParseError(err);
  }
}
export const findRecords = find;

/**
 * @memberof module:Parse
 * @description overwrite certain record's attributes with those given in keyAttributeObj
 *
 * @param {object} record
 * @param {object} keyAttributeObj
 * @param {bool} save
 */
export async function setParseObject(record, keyAttributeObj, save) {
  const keys = Object.keys(keyAttributeObj);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (keyAttributeObj[key] != undefined) {
      record.set(key, keyAttributeObj[key]);
    } else {
      record.unset(key);
    }
  }

  if (save) {
    try {
      return record.save();
    } catch (err) {
      handleParseError(err);
    }
  } else {
    return record;
  }
}
export const updateRecord = setParseObject;

/**
 * @memberof module:Parse
 * @description given a table name in the database, and key-val pairs of attributes and their values, create a new record/obj in the Table
 *
 * @param {string} tableName - ex. 'Person'
 * @param {object} keyValueObject - ex. { firstName: 'Jorge', lastName: 'Switchboard', 'phoneNumber': 1234567890 }
 * @param {object} acl - record permissions
 * @param {object} tempRecord - Temporary record that we wish to save
 */
export async function addRecord(tableName, keyValueObject, acl = ACL.getCompanyReadWriteACL(), tempRecord) {
  if (tableName === 'User') throw new Error('Special permissions required to add User');
  const Record = Parse.Object.extend(tableName);
  let record = tempRecord || new Record();
  const keys = Object.keys(keyValueObject);
  const keysLen = keys.length;
  for (let i = 0; i < keysLen; i++) {
    if (keyValueObject[keys[i]] !== undefined) {
      record.set(keys[i], keyValueObject[keys[i]]);
    }
  }
  record.setACL(acl);
  try {
    return await record.save();
  } catch (err) {
    handleParseError(err);
  }
}

/**
 * @memberof module:Parse
 * @description Gets a particular object of table tableName by objectId
 *
 * @param {string} tableName  - name of table
 * @param {string} objectId   - objectId of record
 * @param {array} includedPointers - included pointers
 */
export async function getObjectById(tableName, objectId, includedPointers) {
  const query = createQuery(tableName);
  setQueryRestriction(query, QueryRestrictionTypes.EQUAL_TO, 'objectId', objectId);
  includePointers(query, includedPointers);

  try {
    return await find(query, true);
  } catch (err) {
    handleParseError(err);
  }
}

/**
 * @memberof module:Parse
 * @description creates a temp record not yet saved to the db
 *
 * @param {string} tableName - ex. 'Person'
 * @param {object} keyValueObject - ex. { firstName: 'Jorge', lastName: 'Switchboard', 'phoneNumber': 1234567890 }
 */
export function createTempRecord(tableName, keyValueObject) {
  const Record = new Parse.Object.extend(tableName);
  const record = new Record();

  const keys = Object.keys(keyValueObject);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    record.set(key, keyValueObject[key]);
  }

  return record;
}

/**
 * @memberof module:Parse
 * @description create a fake pointer to desired object of tableName without recreating/cloning the entire record/pointer
 *
 * @param {*} tableName - string
 * @param {*} objectId - string
 */
export function createTempPointer(tableName, objectId) {
  if (!tableName || !objectId) throw new Error('Must provide tableName and objectId');
  return { __type: 'Pointer', className: tableName, objectId };
}

/**
 * @memberof module:Parse
 * @description include given pointers in the query
 *
 * @param {object} query
 * @param {array} includePointerNames
 */
export function includePointers(query, includePointerNames = []) {
  query.include(includePointerNames);
  return query;
}


/**
 * @memberof module:Parse
 * @description Call server-side function
 *
 * @param {string} functionName - the name of the function on the server
 * @param {object} params - hash of key-value pairs representing the parameters of the function functionName and its values (ex. { driverObjectId: 'sda342', vehicleObjectId: '2131df2' })
 * Above example would map to functionName(driverObjectId, vehicleObjectId) -> functionName('sda342', '2131df2')
 */
export async function callCloudFunction(functionName, params = {}) {
  if (!functionName) {
    throw new Error('Must provide server function name');
  }

  try {
    const result = await Parse.Cloud.run(functionName, params);
    return result;
  } catch (error) {
    throw new Error(`Error with cloud call to ${functionName}: ${error}`);
  }
};

/**
 * @memberof module:Parse
 * @description Given a record, return a clone of it
 * @param {object} record
 */
export function cloneRecord(record) {
  if (!record) {
    throw new Error('Must provide record');
  }

  return record.clone();
}

/**
 * @memberof module:Parse
 * @description obtain property/attribute from record
 *
 * @param {object} record
 * @param {string} attribute
 * @param {bool} returnUndefinedOnError - return undefined instead of an error
 */
export function getAttribute(record, attribute = '', returnUndefinedOnError) {
  if (!record && returnUndefinedOnError) return undefined;
  if (!record) throw new Error('Must provide record');
  if (attribute === 'objectId') return record.id || record.objectId;
  return record.get(attribute);
}
// Parse.Object.prototype.getAttr = function (attribute = '', returnUndefinedOnError) {
//   const record = this;
//   return getAttribute(record, attribute, returnUndefinedOnError);
// }

/**
 * @memberof module:Parse
 * @description destroy/full delete a record from the database and return the last version of it
 * @param {object} record
 */
export async function destroyRecord(record) {
  if (!record) throw new Error('Must provide record');

  try {
    const deletedRecord = await record.destroy();
    return deletedRecord;
  } catch (err) {
    handleParseError(err);
  }
}

/**
 * @memberof module:Parse
 * @description destroy/full delete records from the database
 * @param {object} records
*/
export function destroyRecords(records) {
  if (!records) throw new Error('Must provide records');

  try {
    return Parse.Object.destroyAll(records.filter(Boolean));
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:Parse
 * @description You'd usually use this function if you wanted to run multiple query restrictions on the same table on the same attributes. Ex. get me all users where age < 10 OR where age > 50
 * @param {array} queries - array of queries
 */
export function createQueryOr(queries = []) {
  const query = Parse.Query.or(...queries);
  return query;
}

/**
 * @memberof module:Parse
 * @description apply sorting order to query
 *
 * @param {object} query
 * @param {string} order - key from QuerySortOrderTypes (ex. QuerySortOrderTypes.DESCENDING)
 * @param {string} attribute - attribute to apply order on (ex. QuerySortOrderTypes.DESCENDING on createdAt)
 */
export function sortQuery(query, order, attribute) {
  return query[order](attribute);
}

/**
 * @memberof module:Parse
 * @description Given a query, get the total number of records
 * @param {object} query
 */
export async function count(query) {
  if (!query) throw new Error('Requires: query');
  try {
    return await query.count();
  } catch (err) {
    handleParseError(err);
  }
}

/**
 * @memberof module:Parse
 * @description creates a Parse GeoPoint object from latitude, longitude
 *
 * @param {Number} latitude
 * @param {Number} longitude
 * @returns the Parse GeoPoint Object
 */
export function createGeoPoint(latitude = 0, longitude = 0) {
  return new Parse.GeoPoint(latitude, longitude);
}

/**
 * @memberof module:Parse
 * @description Clears any changes to this record made since its last call to save. Does NOT save after doing so
 *
 * @param {object} record
 * @param {array} attributes - changes to these attributes we want to revert back. if empty, reverts the entire record
 * @returns the reverted record
 */
export function revertRecord(record, attributes = []) {
  if (attributes.length) {
    return record.revert(...attributes);
  }
  return record.revert();
}

/**
 * @memberof module:Parse
 * @description Get a hash of all { attribute: value } in the record that have been unsaved (ie. dirty)
 * @param {object} record
 */
export function getDirtyHash(record) {
  const dirtyHash = {};
  const dirtyKeys = record.dirtyKeys();

  for (let i = 0; i < dirtyKeys.length; i++) {
    const key = dirtyKeys[i];
    dirtyHash[key] = getAttribute(record, key);
  }

  return dirtyHash;
}

/**
 * @memberof module:Parse
 * @description - Given a query, have the results return only these select attributes
 * @example returnSelectAttributes(driverQuery, ['user', 'driversLicense']) will return drivers only with their user pointers and license
 *
 * @param {object} query
 * @param {array} attributes
 *
 * @returns Nothing, applies object restrictions
 */
export function setReturnSelectAttributes(query, attributes = []) {
  if (attributes.length) return query.select(...attributes);
}

/**
 * @memberof module:Parse
 * @description Given a query does a containedIn search on a list of distinct values in parallel batches for speed
 *
 * @param {object} query
 * @param {string} attribute attribute the contained in query will match against
 * @param {array} values an array of values of any length that will be contained in the attribute (repeated values
 * may cause repeated records to be returned)
 *
 * @returns A single array concatenating responses from all batched containedIn queries
 */
export async function batchContainedInQuery(query, attribute, values = []) {
  // Batch size parameter to be tuned for max performance
  const BATCH_SIZE = 50;

  if (values.length === 0) return [];

  // Batch the queries into batch_size chunks and push them into a Promise array to be awaited on in parallel
  const batchedQueryPromises = [];

  for (let batchStart = 0; batchStart < values.length; batchStart += BATCH_SIZE) {
    const batchQuery = copyQuery(query);
    // Because deeply nested objects are being set by reference in our internal query functions they bleed through this loop
    // we must overwrite what we want to mutate (the containedIn array stored in *attribute*.$in)
    batchQuery._where = { ...query._where };
    batchQuery._where[attribute] = { $in: {} };
    const batchEnd = (batchStart + BATCH_SIZE >= values.length) ? values.length : batchStart + BATCH_SIZE;
    setQueryRestriction(batchQuery, QueryRestrictionTypes.CONTAINED_IN, attribute, values.slice(batchStart, batchEnd));

    batchedQueryPromises.push(find(batchQuery, false, true));
  }

  const batchedQueryResponses = await Promise.all(batchedQueryPromises);

  let aggregateQueryResponse = [];
  batchedQueryResponses.forEach(batchResponse => {
    aggregateQueryResponse = aggregateQueryResponse.concat(batchResponse);
  });

  return aggregateQueryResponse;
}

/**
 * @memberof module:Parse
 * @description Increments a record's attribute, by 1 as default, and optionally saves to db
 *
 * @param {object} record
 * @param {string} attribute Attribute to increment (must be a Number type)
 * @param {boolean} save
 * @param {number} incrementBy Amount to increment by (must be an Integer)
 *
 * @returns Record
 */
export async function increment(record, attribute, save, incrementBy = 1) {
  if (!record) return undefined;

  await record.increment(attribute, incrementBy);
  if (save) {
    return await record.save();
  }
  return record;
}

/**
 * @memberof module:Parse
 * @description Decrements a record's attribute, by 1 as default, and optionally saves to db
 *
 * @param {object} record
 * @param {string} attribute Attribute to decrement (must be a Number type)
 * @param {boolean} save
 * @param {number} decrementBy Amount to decrement by (must be an Integer)
 *
 * @returns Record
 */
export async function decrement(record, attribute, save, decrementBy = 1) {
  if (!record) return undefined;

  await record.decrement(attribute, decrementBy);
  if (save) {
    return await record.save();
  }
  return record;
}
