import React from 'react';
import PropTypes from 'prop-types';
import { FontIcon } from 'material-ui';
import Autocomplete from 'react-google-autocomplete';
import { formatAddress, parsePlaceObject, concatenateStringsInParseObjectsArray } from 'api/Helpers';
// Components
import DatePicker from 'components/DatePicker/container/DatePicker';
import ChooseOrAddButton from 'components/ChooseOrAddModal/container/ChooseOrAddButton';
// CSS
import styles from './AddEditForm.module.scss';

function AddEditForm(props) {
  function getVendorWarningString(addressObj) {
    let warningString = 'Warning: missing';
    if (!addressObj.city) { warningString = warningString.concat(' city, '); }
    if (!addressObj.country) { warningString = warningString.concat(' country, '); }
    if (!addressObj.zipPostal) { warningString = warningString.concat(' ZIP/Postal code, '); }
    return warningString.substring(0, warningString.length - 2).concat('.');
  }

  function displayArrayProperty(column) {
    if (Object.prototype.toString.call(column.value) === '[object Array]') {
      return concatenateStringsInParseObjectsArray(column.value, column.displayProperty, false);
    }
    return column.value.get(column.displayProperty);
  }

  return (
    <div className={styles.body}>
      <table className={styles.table}><tbody>
        {props.formEntries.map((rows, rowIndex) => {
          const row = rows.map((column, columnIndex) => {
            if (column.type === 'date') {
              return (
                <td key={`${column.property}${columnIndex}`}>
                  <div className={styles.formGroup}>
                    <label className={`${styles.inputLabel} inputLabel`}>{column.label} {column.required ? <span className={styles.required}>&nbsp;*</span> : undefined}</label>
                    <DatePicker
                      className="input notranslate"
                      selected={column.value}
                      placeholderText="....."
                      showMonthDropdown
                      showYearDropdown
                      onChange={(date) => props.handleFormChange(rowIndex, columnIndex, date)}
                    />
                  </div>
                </td>
              );
            } else if ((column.type === 'text' || column.type === 'number')) {
              return (
                <td key={`${column.property}${columnIndex}`}>
                  <div className={styles.formGroup}>
                    <label className={`${styles.inputLabel} inputLabel`}>{column.label} {column.required ? <span className={styles.required}>&nbsp;*</span> : undefined}</label>
                    <input className="input" value={column.value} type={column.type} maxLength={column.limit} placeholder="....." onChange={(e) => props.handleFormChange(rowIndex, columnIndex, e.target.value)} />
                  </div>
                </td>
              );
            } else if (column.type === 'textarea') {
              // magic 42 number, if more than 42 rows then go above
              return (
                <td key={`${column.property}${columnIndex}`} colSpan="42">
                  <div className={styles.formGroupTextarea}>
                    <label className={`${styles.inputLabel} inputLabel`}>{column.label} {column.required ? <span className={styles.required}>&nbsp;*</span> : undefined}</label>
                    <textarea value={column.value} type={column.type} placeholder="....." onChange={(e) => props.handleFormChange(rowIndex, columnIndex, e.target.value)} />
                  </div>
                </td>
              );
            } else if (column.type === 'address') {
              return (
                <td key={`${column.property}${columnIndex}`}>
                  <div className={styles.formGroup}>
                    <label className={`${styles.inputLabel} inputLabel`}>{column.label} {column.required ? <span className={styles.required}>&nbsp;*</span> : undefined}</label>
                    <Autocomplete
                      className={`input ${styles.autocomplete}`}
                      placeholder={column.value.address ? formatAddress(column.value) : '.....'}
                      types={['address']}
                      componentRestrictions={{ country: ['ca', 'us'] }}
                      onPlaceSelected={(place) => {
                        const addressObject = parsePlaceObject(place);
                        props.handleFormChange(rowIndex, columnIndex, addressObject);
                      }}
                    />
                    {column.value && column.value.address && (!column.value.city || !column.value.country || !column.value.zipPostal) &&
                      <span className={styles.warning}>
                        <FontIcon className="material-icons">error</FontIcon>
                        {getVendorWarningString(column.value)}</span>
                    }
                  </div>
                </td>
              );
            } else if (column.type === 'chooseOrAdd') {
              return (
                <td key={`${column.property}${columnIndex}`}>
                  <div className={styles.formGroup}>
                    <label className={`${styles.inputLabel} inputLabel`}>{column.label} {column.required ? <span className={styles.required}>&nbsp;*</span> : undefined}</label>
                    <div className={styles.chooseOrAddValue}>{column.value &&
                      displayArrayProperty(column)
                    }</div>
                    <div className={styles.chooseOrAddButton}><ChooseOrAddButton label={column.label} property={column.property} handleChooseOrAdd={(value) => props.handleFormChange(rowIndex, columnIndex, value)} /></div>
                  </div>
                </td>
              );
            } else if (column.type === 'select') {
              return (
                <td key={`${column.property}${columnIndex}`}>
                  <div className={styles.formGroup}>
                    <label className={`${styles.inputLabel} inputLabel`}>{column.label} {column.required ? <span className={styles.required}>&nbsp;*</span> : undefined}</label>
                    <select className={styles.select} value={column.value} onChange={(e) => props.handleFormChange(rowIndex, columnIndex, e.target.value)}>
                      {column.selectArr.map((option) => (
                        <option key={option}>{option}</option>
                      ))}
                    </select>
                  </div>
                </td>
              );
            }
          });
          return <tr key={rowIndex}>{row}</tr>;
        })}
      </tbody></table>
    </div>
  );
}

AddEditForm.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  formEntries: PropTypes.array.isRequired,
};

export default AddEditForm;
