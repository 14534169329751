import React from 'react';

// PrimeReact Component
import { Checkbox as PRCheckbox } from 'primereact/checkbox';

// Styles
import './style.scss';

/**
 * @description PrimeReact checkbox component
 * @param {String} className - Custom container className
 */
export default function Checkbox({ ...props }) {
  // ** Misc ** //
  let className = 'sbc-checkbox';
  if (props.className) {
    className += ` ${props.className}`;
  }
  return (
    <div className={className}>
      <PRCheckbox {...props} />
    </div>
  );
}
