import React from 'react';
import moment from 'moment-timezone';
import history from '../../../sbHistory';


// API
import * as Helpers from 'api/Helpers';
import { getAttribute } from 'api/Parse';

// CSS
import './style.scss';

// MDB
import { MDBRow, MDBCol } from 'mdbreact';

// Material
import { RaisedButton } from 'material-ui';

// Components
import HOSEditStatus from 'components/ELDEdit/HOSEditStatus/HOSEditStatus';

class HOSEditsRequestedView extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const ELDEditRecord = this.props.ELDEditRecord;
    const requestedAt = moment(getAttribute(ELDEditRecord, 'requestedAt', true)).format('MMM DD, YYYY');
    let requestedBy = getAttribute(ELDEditRecord, 'requestedBy', true) || '';
    if (requestedBy) {
      requestedBy = `${getAttribute(requestedBy, 'firstName', true)} ${getAttribute(requestedBy, 'lastName', true)}`;
    }

    const ELDDailyCertificationRecord = getAttribute(ELDEditRecord, 'eldDailyCertification', true);
    const driver = getAttribute(ELDEditRecord, 'driver', true);
    const driverTimezoneOffsetFromUTC = getAttribute(driver, 'timezoneOffsetFromUTC');
    const driverUser = driver && getAttribute(driver, 'user');
    let driverUserShortName = 'N/A';

    if (driverUser) {
      driverUserShortName = `${getAttribute(driverUser, 'firstName')} ${getAttribute(driverUser, 'lastName')}`;
    }

    let forDate;
    let forDateString = 'N/A';
    const driverTimeZoneStr = getAttribute(driver, 'timezoneOffsetFromUTC', true) || moment.tz.guess(); // if no timezone, guess local
    if (ELDDailyCertificationRecord) {
      forDate = moment.tz(getAttribute(ELDDailyCertificationRecord, 'startTimeUTC', true), driverTimezoneOffsetFromUTC);
    } else if (getAttribute(ELDEditRecord, 'eldDailyCertificationStartTimeUTC', true)) {
      forDate = moment.tz(getAttribute(ELDEditRecord, 'eldDailyCertificationStartTimeUTC', true), driverTimezoneOffsetFromUTC);
    }
    if (forDate) {
      forDateString = moment.tz(forDate, driverTimezoneOffsetFromUTC).format('MMM DD, YYYY');
    }

    const completed = getAttribute(ELDEditRecord, 'completed', true);
    const isExpired = (moment().diff(requestedAt, 'days') > 14) && ([0, 3, 4, 5].includes(completed));

    return (
      <MDBRow>

        {/* Edit Status Badge */}
        <MDBCol className="pr-5 pb-1 pt-1">
          <HOSEditStatus status={completed} isExpired={isExpired} />
          {isExpired &&
            <label className="label-left mt-1">
              Edit not approved or rejected within 14 day period
            </label>
          }
        </MDBCol>

        {/* Requested For */}
        <MDBCol>
          <MDBRow><b>Driver & Log Date</b></MDBRow>
          <MDBRow>
            <div style={{ marginRight: '1em', fontSize: '0.75em' }}><b>{driverUserShortName}</b></div>
            <div style={{ fontSize: '0.75em' }}>{forDateString}</div>
          </MDBRow>
        </MDBCol>

        {/* Requested By */}
        <MDBCol>
          <MDBRow><b>Requested By</b></MDBRow>
          <MDBRow>
            <div style={{ marginRight: '1em', fontSize: '0.75em' }}><b>{requestedBy}</b></div>
            {/* <div style={{ fontSize: '0.75em' }}>{requestedAt}</div> */}
            {window.location.host.indexOf('app-support') !== -1 && getAttribute(ELDEditRecord, 'isSupport', true) &&
              <div style={{ fontSize: '0.75em', color: 'red' }}>Edit made by Support Team</div>
            }
          </MDBRow>
        </MDBCol>

        {/* Go To Log Button */}
        {(driver && forDate) &&
          (
            <MDBCol className="pt-2 pl-1 pb-1">
              <RaisedButton
                icon={<i className="material-icons">chevron_right</i>}
                label="Go To Log"
                onClick={() => history.push({
                  pathname: 'driver',
                  search: 'driver=' +
                    getAttribute(driver, 'objectId') +
                    '&view=hosEvents&date=' +
                    moment(Helpers.referenceToTimezone(forDate, getAttribute(driver, 'timezoneOffsetFromUTC'))).format('DDMMYY')
                })}
                primary
              />
            </MDBCol>
          )
        }
        {!driver && (
          <MDBCol className="pt-2 pl-1 pb-1" />
        )}

      </MDBRow>
    );
  }
}

export default HOSEditsRequestedView;
