import React, { useRef } from 'react';

import Button from 'sbCore/Button/Button';
import OverlayPanel from 'sbCore/OverlayPanel/OverlayPanel';
import TieredMenu from 'sbCore/TieredMenu/TieredMenu';

function LogDownloadButton({ showModal }) {

  const op = useRef(null);

  const generateLogsItems = [
    {
      label: 'Download PDF',
      icon: 'pi pi-download',
      command: () => showModal('downloadPdf'),
    },
    {
      label: 'Download CSV',
      icon: 'pi pi-download',
      command: () => showModal('downloadCsv'),
    },
    {
      label: 'Email',
      icon: 'pi pi-envelope',
      command: () => showModal('email'),
    },
    {
      label: 'Send to FMCSA',
      icon: 'pi pi-send',
      command: () => showModal('fmcsa'),
    },
    {
      label: 'Send to NSC',
      icon: 'pi pi-send',
      command: () => showModal('nsc'),
    },
  ];

  return (
    <div className="flex">
      <Button type="button" icon="pi pi-book" label="Generate / Send Logs" onClick={(e) => op.current.toggle(e)} sbVariant="short" />
      <OverlayPanel innerRef={op} sbVariant="compact">
        <TieredMenu model={generateLogsItems} />
      </OverlayPanel>
    </div>
  );
}

export default LogDownloadButton;
