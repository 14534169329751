import React, { useState, useEffect } from 'react';

// API
import { addDispatchVehicle, getDispatchVehicles, removeDispatchVehicle } from 'api/Dispatch/DispatchVehicle';

// CSAPI
import { getRecordByObjectId, getCurrentUserSessionToken, getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import VehicleAutocomplete from 'sbCore/VehicleAutocomplete/VehicleAutocomplete';

// Styling
import './style.scss';

/**
 * @description Contains a filter to select a vehicle and create a dispatch vehicle using the vehicle and a dispatch transfer
 * @param {string} dispatchTransferObjectId - The objectId of the dispatchTransfer object we wish to update.
 * @param {bool} [hideLabel] - Whether to hide the label of the autocomplete
 * @param {bool} [clearDispatchVehicle] - If passed in, will clear the dispatch vehicle if present
 * @param {function} [onSelectDispatchVehicle] - Returns the added/removed dispatchVehicle
 * @returns
 *
 * @example
 * <DispatchVehicleAutocomplete dispatchTransfer={dispatchTransfer} warning />
*/
function DispatchVehicleAutocomplete({ ...props }) {

  // ** useStates ** //
  const [selectedVehicle, setSelectedVehicle] = useState(undefined);
  const [dispatchVehicle, setDispatchVehicle] = useState(undefined);
  const [dispatchTransfer, setDispatchTransfer] = useState(undefined);

  // ** useEffects ** //
  useEffect(() => {
    let didCancel = false;

    async function _getDispatchTransfer() {
      if (!didCancel) {
        const dispatchTransfer = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'DispatchTransfer', props.dispatchTransferObjectId);
        setDispatchTransfer(dispatchTransfer);
      }
    }

    _getDispatchTransfer();

    return () => { didCancel = true; };
  }, [props.dispatchTransferObjectId]);

  useEffect(() => {
    let didCancel = false;

    if (!dispatchTransfer) return;

    async function _getDispatchVehicle() {
      if (!didCancel) {
        const dispatchTransferObjectId = getAttribute(dispatchTransfer, 'objectId');
        const includedPointers = ['vehicle', 'transfer'];

        const { dispatchVehicles } = await getDispatchVehicles(undefined, dispatchTransferObjectId, undefined, undefined, includedPointers);

        if (dispatchVehicles && dispatchVehicles.length > 0) {
          // A transfer should only contain one dispatch vehicle, so get first element from the array.
          const vehicle = getAttribute(dispatchVehicles[0], 'vehicle', true);

          setDispatchVehicle(dispatchVehicles[0]);
          setSelectedVehicle(vehicle);
        }
      }
    }

    _getDispatchVehicle();

    return () => { didCancel = true; };
  }, [dispatchTransfer]);

  useEffect(() => {
    let didCancel = false;

    async function _addDispatchVehicle() {
      if (!didCancel) {
        const keyValueObj = {
          dispatchTransfer,
          vehicle: selectedVehicle,
        };
        const dispatchVehicle = await addDispatchVehicle(keyValueObj);
        setDispatchVehicle(dispatchVehicle);

        if (props.onSelectDispatchVehicle) props.onSelectDispatchVehicle(dispatchVehicle);
      }
    }

    async function _removeDispatchVehicle() {
      if (!didCancel) {
        await removeDispatchVehicle(dispatchVehicle);
        setDispatchVehicle(undefined);

        if (props.onSelectDispatchVehicle) props.onSelectDispatchVehicle(dispatchVehicle);
      }
    }

    if (selectedVehicle && !dispatchVehicle && dispatchTransfer) {
      _addDispatchVehicle();
    }

    if (!selectedVehicle && dispatchVehicle) {
      _removeDispatchVehicle();
    }

    return () => { didCancel = true; };
  }, [selectedVehicle]);

  useEffect(() => {
    if (props.clearDispatchVehicle) setSelectedVehicle(undefined);
  }, [props.clearDispatchVehicle]);

  // ** Callbacks ** //
  function onSelectVehicle(vehicle) {
    setSelectedVehicle(vehicle);
  }

  return (
    <VehicleAutocomplete hideLabel={props.hideLabel} onSelectVehicle={(vehicle) => onSelectVehicle(vehicle)} vehicle={selectedVehicle} disabled={props.disabled} warning />
  );
}

export default DispatchVehicleAutocomplete;
