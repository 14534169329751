import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Table } from 'react-bootstrap';
import StatusIcon from 'components/StatusIcon/view/StatusIcon';

// CSS
import styles from './SelectDriver.module.scss';

class SelectDriver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.selectDriver = this.selectDriver.bind(this);
  }

  selectDriver(driver) {
    this.props.selectDriverHandler(driver, this.props.drivingRole);
  }

  render() {
    return (
      <div className={styles.selectDriver}>
        <div className="defaultTable shortTable" onScroll={(e) => this.props.scrollHandler(e)}>
          <Table responsive condensed hover>
            <thead>
              <tr>
                <th>
                  Name
                </th>
                <th className={styles.chooseDriverLegend}>
                  <div className="inlineBlock">
                    <span className={styles.activeLegend}>&#8226;</span> In Job
                  </div>
                  <div className="inlineBlock">
                    <span className={styles.inactiveLegend}>&#8226;</span> Available
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.drivers.map((driver) =>
                <tr className={styles.listRow} key={driver.id} onClick={() => this.selectDriver(driver)}>
                  <td>{`${driver.get('user').get('firstName')} ${driver.get('user').get('lastName')}`}</td>
                  <td className={`${styles.listColumn} rightText`}>
                    {driver.get('driverStatus') && (driver.get('driverStatus').get('referenceInt') === 2) ?
                      <StatusIcon active />
                      :
                      <StatusIcon active={false} />
                    }
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

SelectDriver.propTypes = {
  drivers: PropTypes.array,
  deleteDriverHandler: PropTypes.func,
  drivingRole: PropTypes.string,
  scrollHandler: PropTypes.func,
  selectDriverHandler: PropTypes.func,
};

export default SelectDriver;
