import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Slider from 'rc-slider';

// Components
import DatePicker from 'components/DatePicker/container/FormControlDatePicker';

// CSS
import styles from './DayChooser.module.scss';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

function getTimeFromHour(twentyFourHour) {
  let ampm = 'am';
  if (twentyFourHour > 11) {
    ampm = 'pm';
  }
  if (twentyFourHour > 12) {
    return `${twentyFourHour - 12}:00${ampm}`;
  }
  return `${twentyFourHour}:00${ampm}`;
}

function DayChooser(props) {
  const date = props.date ? props.date : moment();
  const hourStart = props.hourStart !== undefined ? props.hourStart : 0;
  const hourEnd = props.hourEnd !== undefined ? props.hourEnd : 24;
  return (
    <div className={styles.dayChooser}>
      <label className={`${styles.label} translate-me`}>Date for GPS History:</label>
      <DatePicker
        dateFormat="MMM dd, yyyy"
        className={styles.dateInput}
        selected={props.date ? moment(props.date) : moment()}
        onChange={pickerDate => {
          props.handleDateChange(moment(pickerDate));
        }}
        autoComplete="off"
        disabled={props.disabled}
      />

      {/* <div className={styles.hourSlider}>
        <Range
          step={1}
          min={0}
          max={24}
          value={[hourStart, hourEnd]}
          onChange={rangeArr =>
            props.handleHourChange(rangeArr[0], rangeArr[1])
          }
          onAfterChange={rangeArr => props.triggerHourUpdate()}
          tipFormatter={value => getTimeFromHour(value)}
          pushable
          allowCross={false}
        />
      </div> */}
    </div>
  );
}

DayChooser.propTypes = {
  date: PropTypes.object,
  disabled: PropTypes.bool,
  hourStart: PropTypes.number,
  hourEnd: PropTypes.number,
  handleDateChange: PropTypes.func.isRequired,
  handleHourChange: PropTypes.func,
  triggerHourUpdate: PropTypes.func,
  toggleReplay: PropTypes.func.isRequired,
  replay: PropTypes.object.isRequired,
};

export default DayChooser;
