import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import { CountryCodeLocationDescriptionTypes } from 'enums/VehicleLocation';

// API
import * as Getters from 'api/Getters';
import * as GPS from 'api/GPS/GPS';
import { toTitleCase, concatenateStringsInParseObjectsArray, convertDistance } from 'api/Helpers';
import { getReverseGeocode, isVehicleDriverDriving, isVehicleDriverOnDuty } from 'api/ELD';
import { getAttribute } from 'api/Parse';

// Components
import EditVehicleButton from 'components/AddEditEquipment/container/EditVehicleButton';
import Alert from 'components/Alert/view/Alert';
import { MDBRow, MDBCol, MDBTooltip, MDBIcon } from "mdbreact";

// CSS
import styles from './VehicleRow.module.scss';

// Assets
// const tractor = require('assets/images/equipment/tractor-compressed.png');

function VehicleRow(props) {
  const { vehicle, address } = props;

  function getDriverBoxStyle(vehicle) {
    // console.log(
    //   vehicle && vehicle.get('vehicleLocation') && GPS.isIdling(isVehicleDriverDriving(vehicle), vehicle.get('vehicleLocation').get('speedKm'))
    // );
    if (vehicle && vehicle.get('vehicleLocation') && GPS.isIdling(isVehicleDriverDriving(vehicle), vehicle.get('vehicleLocation').get('speedKm'))) {
      return styles.isIdling;
    } else if (isVehicleDriverDriving(vehicle)) {
      return styles.driverIsDriving;
    } else if (isVehicleDriverOnDuty(vehicle)) {
      return styles.driverIsOnDuty;
    }
    return styles.driverIsOffDuty;
  }

  function getDriverStatus(vehicle) {
    if (vehicle && vehicle.get('vehicleLocation') && GPS.isIdling(isVehicleDriverDriving(vehicle), vehicle.get('vehicleLocation').get('speedKm'))) {
      return "Idling";
    } else if (isVehicleDriverDriving(vehicle)) {
      return "Driving";
    } else if (isVehicleDriverOnDuty(vehicle)) {
      return "On Duty";
    }
    return "Off Duty";
  }

  const vehicleLocation = vehicle && vehicle.get('vehicleLocation');
  const countryCode = ((vehicleLocation && vehicleLocation.get('countryCode')) || '').toUpperCase();

  let locationDescription = address;
  if (countryCode === 'US') {
    if (getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.US, true)) {
      const locationDescriptionUSArr = getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.US, true).split(' ');
      const locationDescriptionStateProvince = locationDescriptionUSArr[2];
      const trueStateProvince = getAttribute(vehicleLocation, 'stateProvince', true);
      if (trueStateProvince !== locationDescriptionStateProvince.toLowerCase()) {
        const formattedLocationDescription = `${trueStateProvince && trueStateProvince.toUpperCase()}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      } else {
        const locationDescriptionCity = locationDescriptionUSArr.slice(3, locationDescriptionUSArr.length).join(' ');
        const formattedLocationDescription = `${locationDescriptionCity}, ${locationDescriptionStateProvince}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      }
    }
  } else {
    if (getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.CA, true)) {
      const locationDescriptionCAArr = getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.CA, true).split(' ');
      const locationDescriptionStateProvince = locationDescriptionCAArr[2];
      const trueStateProvince = getAttribute(vehicleLocation, 'stateProvince', true);
      if (trueStateProvince !== locationDescriptionStateProvince.toLowerCase()) {
        const formattedLocationDescription = `${trueStateProvince && trueStateProvince.toUpperCase()}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      } else {
        const locationDescriptionCity = locationDescriptionCAArr.slice(3, locationDescriptionCAArr.length).join(' ');
        const formattedLocationDescription = `${locationDescriptionCity}, ${locationDescriptionStateProvince}, ${countryCode}`;
        locationDescription = formattedLocationDescription;
      }
    }
  }

  const distanceUnit = Getters.getPDispatcherPropertyFromState('distanceUnit'); // km or mi
  let drivers = props.vehicle.get('drivers') ? toTitleCase(concatenateStringsInParseObjectsArray(props.vehicle.get('drivers'), 'user_fullName')) : "";

  // check ex. if from child company
  let isFromAssociatedCompany;
  const currentUserCompany = Getters.getCurrentUser().get('belongsToCompany');
  const vehicleCompany = getAttribute(vehicle, 'belongsToCompany', true);
  if (currentUserCompany && vehicleCompany) {
    isFromAssociatedCompany = currentUserCompany.id !== vehicleCompany.id;
  } else {
    isFromAssociatedCompany = false;
  }

  return (
    <div
      onClick={() => props.handleClick('Vehicle', props.vehicle, true)}
      className={(props.active ? `${styles.activeRow} ${styles.row}` : styles.row) + " pt-1"}
    >
      <MDBRow className="mx-0">
        <MDBCol size="5" className="font-weight-bold">
          <div className={styles.vehicleUnitId}>
            {!props.vehicle.get('unitId') && <EditVehicleButton btnClass={styles.editButtonClass} vehicle={props.vehicle}>Add Unit Id</EditVehicleButton>}
            {props.vehicle && props.vehicle.get('unitId')}
          </div>
        </MDBCol>
        {props.vehicle && props.vehicle.get('vehicleLocation') &&
          <MDBCol size="7">
            <div className={props.active ? `${styles.activeUpdated}` : undefined}>
              <div className={styles.address + ' text-truncate'}>{locationDescription}</div>
              <div className={styles.time}>
                { moment(props.vehicle.get('vehicleLocation').get('dateTime')).format('lll') }
                {
                  // moment().diff(moment(props.vehicle.get('vehicleLocation').get('dateTime')), 'minutes') > 30 && isVehicleDriverDriving(props.vehicle) &&
                  // <span className={styles.oldLocationError}>&nbsp; Vehicle's location has not updated in a while</span>
                }
              </div>
            </div>
          </MDBCol>
        }
      </MDBRow>
      <MDBRow>
        <MDBCol size="12" style={{ margin: '0rem', paddingTop: '0rem', paddingBottom: '0rem' }}>
          {(drivers && drivers.length > 0) &&
            <React.Fragment>
              <MDBTooltip className={getDriverBoxStyle(props.vehicle) + " ml-3 d-inline-block translate-me"}
                placement="right"
                tag="div"
                tooltipContent={getDriverStatus(props.vehicle)}>
                <MDBIcon icon="id-card" />
              </MDBTooltip>
              <div className={"d-inline-block ml-2 text-truncate " + styles.smallText}>{drivers}</div>
            </React.Fragment>
          }
        </MDBCol>
      </MDBRow>
      {(!drivers || drivers.length === 0) &&
        <MDBRow>
          <MDBCol size="12" style={{ margin: '0rem', paddingTop: '0rem', paddingBottom: '0rem' }}>
            <MDBTooltip className={getDriverBoxStyle(props.vehicle) + " d-inline-block ml-3 disabledText translate-me"}
              placement="right"
              tag="div"
              tooltipContent={"No Driver"}>
              <MDBIcon icon="id-card" />
            </MDBTooltip>
          </MDBCol>
        </MDBRow>
      }
      <MDBRow>
        <MDBCol size="12" style={{ margin: '0rem', paddingTop: '0rem', paddingBottom: '0rem' }}>
          {(props.globalSpeedLimitKm && props.vehicle.get('vehicleLocation') && !isNaN(props.vehicle.get('vehicleLocation').get('speedKm')) &&
            props.vehicle.get('vehicleLocation').get('speedKm') > props.globalSpeedLimitKm) ?
            <Alert iconClass="ml-3" tooltipClass="ml-4 translate-me"
              tooltipString={`${props.vehicle.get('unitId')} is speeding above ${distanceUnit === 'km' ?
                (`${Math.round(props.globalSpeedLimitKm)} km`) :
                (`${convertDistance(props.globalSpeedLimitKm, 'km', 'mi')} mi`)
                }`}
            />
            :
            null
          }
          {(isFromAssociatedCompany) &&
            <MDBTooltip className={'d-inline-block translate-me'}
              placement="top"
              tag="div"
              tooltipContent={`Associated Company: ${vehicle.get('belongsToCompany').get('name')}`}>
              <MDBIcon icon="network-wired" className="ml-3" />
            </MDBTooltip>
          }
        </MDBCol>
      </MDBRow>
      {/* <MDBRow className={getDriverBoxStyle(props.vehicle) + " mx-3"}>
        <MDBCol size="4" className="text-left">
          Driver:
        </MDBCol>
        <MDBCol size="8" className="text-right">
          {props.vehicle.get('drivers') &&
            <span>{toTitleCase(concatenateStringsInParseObjectsArray(props.vehicle.get('drivers'), 'user_fullName'))}</span>
          }
          {!props.vehicle.get('drivers') &&
            <span>No Driver</span>
          }
        </MDBCol>
      </MDBRow> */}
    </div>
  );
}

VehicleRow.propTypes = {
  active: PropTypes.bool,
  vehicle: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleHover: PropTypes.func.isRequired,
  address: PropTypes.string,
  globalSpeedLimitKm: PropTypes.number,
};

export default VehicleRow;
