import React from 'react';
import PropTypes from 'prop-types';
import { MDBIcon } from 'mdbreact';
import { Modal } from 'react-bootstrap';

import styles from './NotificationBox.module.scss';

class NotificationBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    // this.toggleShow = this.toggleShow.bind(this);
  }


  // render() {
  //   const { children, title, show } = this.props;
  //   const paperStyle = { margin: '0 auto', maxWidth: '40%', paddingBottom: '1em', position: 'relative', background: '#FFFFFF' };
  //   return (
  //     <div className={styles.notificationBox}>
  //       {!show &&
  //       <div className={styles.notificationIconContainer} onClick={() => { this.props.toggleShow(); }}>
  //         <FontIcon className={`material-icons ${styles.openIcon}`} >expand_more</FontIcon></div>
  //       }
  //       {show &&
  //       <Paper zDepth={2} style={paperStyle} >
  //         <Title
  //           className="h3"
  //           title={<div>{title}</div>}
  //           containerStyle={{ paddingLeft: '.5em', paddingRight: '.5em' }}
  //           leftColumnStyle={{ width: '99%' }}
  //           optionsStyle={{ verticalAlign: 'middle', width: '1%', textAlign: 'right' }}
  //         />
  //         <Divider style={{ marginTop: '-2em', marginBottom: '1em' }} />
  //         <div style={{ paddingLeft: '.5em', paddingRight: '.5em', overflowY: 'scroll', maxHeight: '40vh' }} >
  //           {children}
  //         </div>
  //         <div className={styles.closeIcon} onClick={() => { this.props.toggleShow(); }}>
  //           <FontIcon className={'material-icons'} >close</FontIcon>
  //         </div>
  //       </Paper>
  //       }
  //     </div>
  //   );
  // }
  render() {
    const { children, title, show, iconContainerClassName, iconContainerStyle } = this.props;
    return (
      <div className={styles.notificationBox}>
        {!show &&
          <div className={`${styles.notificationIconContainer} ${iconContainerClassName}`} onClick={() => { this.props.toggleShow(); }} style={{...iconContainerStyle}}>
            <MDBIcon icon="chevron-down" className={styles.openIcon} /></div>
        }

        <Modal
          backdrop="static"
          show={show}
          onHide={() => this.props.toggleShow()}
          className="translate-me">

          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {/* <Divider style={{ marginTop: '-2em', marginBottom: '1em' }} /> */}
            <div style={{ paddingLeft: '.5em', paddingRight: '.5em', overflowY: 'scroll', maxHeight: '40vh' }} >
              {children}
            </div>

          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

NotificationBox.defaultProps = {
  iconContainerStyle: {},
};

NotificationBox.protoTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool,
  toggleShow: PropTypes.func,
  iconContainerClassName: PropTypes.string,
  iconContainerStyle: PropTypes.object,
};
export default NotificationBox;
