import {
  copyQuery, count, createQuery, find, getAttribute, getCurrentUser,
  includePointers, setQueryRestriction, sortQuery, updateRecord,
} from 'api/Parse';

import moment from 'moment-timezone';

import Sort from 'sbObjects/Sort';

import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

/** @module VisionEvent */

/**
 * @memberof module:VisionEvent
 * @description Get VisionEvents and applicable information
 *
 * @param {string} unitId - unitId to query events for. Special exception to most getters being the first argument since it requires query matching
 * @param {int} page - query page / limit skip multiplier
 * @param {int} limit  - amount of results we want
 * @param {array} sortBy - array of Sort objects
 * @param {array} filters - array of Filter objects
 * @param {bool} considerChildCompanies - whether or not to include child company results
 *
 * @returns { object } - { visionEvents: [], totalVisionEventsCount: int }
 */
async function getVisionEvents(unitId, page = 0, limit = 10, sortBy = new Sort('dateTime', QuerySortOrderTypes.DESCENDING), filters = [], considerChildCompanies) {
  if (!unitId) throw new Error('Parameter unitId is required');

  const currentUser = getCurrentUser();
  const visionEventQuery = createQuery('VisionEvent');
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  const companiesToConsider = [belongsToCompany];

  const vehicleQuery = createQuery('Vehicle');
  setQueryRestriction(vehicleQuery, QueryRestrictionTypes.EQUAL_TO, 'unitId', unitId);

  setQueryRestriction(visionEventQuery, QueryRestrictionTypes.MATCHES_QUERY, 'vehicle', vehicleQuery);

  if (considerChildCompanies) {
    const childrenQuery = createQuery('CompanyLink');
    setQueryRestriction(childrenQuery, QueryRestrictionTypes.EQUAL_TO, 'parentCompany', belongsToCompany);
    const childCompanies = await find(childrenQuery);
    childCompanies.map((childCompany) => {
      companiesToConsider.push(childCompany);
    });

    setQueryRestriction(visionEventQuery, QueryRestrictionTypes.CONTAINED_IN, 'belongsToCompany', companiesToConsider);
  } else {
    setQueryRestriction(visionEventQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
  }

  // set query restricitons from filter
  filters.map(filter => {
    setQueryRestriction(visionEventQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
  });

  // at this point, copy current query to get the number of pages for pagination
  const visionEventCountQuery = copyQuery(visionEventQuery);

  // now do the includes (we do them after copying the visionEventQuery so the visionEventCountQuery doesn't have the overhead of these includes/unnecessary query additions)
  includePointers(visionEventQuery, [
    'vehicle',
    'vehicleLocation',
    'vehicleLocation.eldEvent',
    'vehicleLocation.eldEvent.eldDailyCertification',
    'driver',
  ]);

  setQueryRestriction(visionEventQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  setQueryRestriction(visionEventQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
  // call the ascending/descending function on the query, passing in the attribute
  sortQuery(visionEventQuery, sortBy.order, sortBy.attribute);

  try {
    const [totalVisionEventsCount, visionEvents] = await Promise.all([count(visionEventCountQuery), find(visionEventQuery)]);
    return { visionEvents, totalVisionEventsCount };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:VisionEvent
 * @description Update a vision event
 *
 * @param {object} record - the record we want to update
 * @param {object} hash   - the hash of key-value's we want to update
 * @param {bool}   save   - whether to save on-call. defaults to true
 */
async function updateVisionEvent(record, hash, save = true) {
  try {
    return await updateRecord(record, hash, save);
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:VisionEvent
 * @description get date of vision event from video name string
 *
 * @param {object} visionEvent - vision event record
 *
 * @returns {string} date string
 */
function getDateTimeStringFromVisionEventVideo(visionEvent) {
  const visionVid = getAttribute(visionEvent, 'video');
  const visionDate = moment(visionVid._name.split('_')[2]).format('YYYY-MM-DD') + ' ' + moment(visionVid._name.split('_')[3], 'Hmmss').format('HH:mm');
  return visionDate;
}

export {
  getVisionEvents,
  updateVisionEvent,
  getDateTimeStringFromVisionEventVideo,
};
