// sb-csapi
import {
  createQuery,
  findRecords,
  getAttribute,
  getCurrentUserSessionToken,
  getCurrentUserCompanyObjectId,
  setQueryRestriction,
  sortQuery,
  updateRecord,
  addRecord,
  destroyRecord,
  getCurrentUser,
  getRecordByObjectId,
  includePointers
} from 'sb-csapi/dist/AAPI';

// Query
import { QueryRestriction, QueryRestrictionTypes, QuerySortOrder } from 'sb-csapi/dist/enums/Query';
import { getDrivers } from 'sb-csapi/dist/api/Driver/Driver';

// Sort
import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

const DRIVER_GROUP_TABLE = 'DriverGroup';
const DRIVER_GROUP_DRIVER_TABLE = 'DriverGroupDriver';

/**
 * @description Fetch DriverGroup record within user's company
 * 
 * @param {object} filters - Array of Filter sbObject
 * @param {object} sortBy - Sort sbObject
 * 
 * @returns {object} Array of DriverGroup records
 */
async function getDriverGroups(filters = [], sortBy = new Sort(QuerySortOrder.DESCENDING, 'name')) {
  const driverGroupQuery = createQuery(DRIVER_GROUP_TABLE);
  setQueryRestriction(driverGroupQuery, QueryRestriction.EQUAL_TO, 'belongsToCompany', getCurrentUserCompanyObjectId());

  filters.map(filter => setQueryRestriction(driverGroupQuery, filter.queryRestriction, filter.attribute, filter.value));

  sortQuery(driverGroupQuery, sortBy.order, sortBy.attribute);

  return await findRecords({ sessionToken: getCurrentUserSessionToken() }, driverGroupQuery, false, true);
}

/**
 * @description Create a new DriverGroup recrod for the current user with the given name
 * 
 * @param {string} name - DriverGroup name
 * 
 * @returns {DriverGroup} The new DriverGroup record
 */
async function createDriverGroup(name) {
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

  let driverGroup = {
    createdBy: currentUser,
    belongsToCompany,
    name,
    numberOfDrivers: 0,
  };

  driverGroup = await addRecord(
    { sessionToken: getCurrentUserSessionToken() },
    DRIVER_GROUP_TABLE,
    driverGroup,
    getAttribute(belongsToCompany, 'objectId')
  );

  return driverGroup;
}

/**
 * @description Update the attributes of a given DriverGroup record with the given key-value object
 * 
 * @param {object} driverGroup - DriverGroup record (record || objectId)
 * @param {object} keyValueObj - The key-value object we wish to update
 * 
 * @returns {DriverGroup} The updated DriverGroup record
 */
async function updateDriverGroup(driverGroup, keyValueObj) {
  if (!driverGroup) throw new Error('Valid DriverGroup record is required');

  // Enable the ability to pass in driverGroup object or the object ID
  const driverGroupRecord = await parseInput(driverGroup, 'record');

  const updatedDriverGroup = await updateRecord(
    { sessionToken: getCurrentUserSessionToken() },
    driverGroupRecord,
    keyValueObj,
    true,
  )

  return updatedDriverGroup;
}

/**
 * @description Create a new DriverGroupDriver record for the current user with given DriverGroup and Driver record
 * 
 * @param {object} driverGroup - DriverGroup record (record || objectId)
 * @param {object} driverRecord - Driver record
 * 
 * @returns {object} The new DriverGroupDriver record
 */
// TODO: Add ways to check if the added driver is already in the group to avoid duplicate
async function addDriverToDriverGroup(driverGroup, driverRecord) {
  if (!driverGroup) throw new Error('Valid DriverGroup record is required');
  if (!driverRecord) throw new Error('Valid Driver record is required');

  // Enable the ability to pass in driverGroup object or the object ID
  const driverGroupRecord = await parseInput(driverGroup, 'record');
  
  const driverGroupDriver = await addRecord(
    { sessionToken: getCurrentUserSessionToken() },
    DRIVER_GROUP_DRIVER_TABLE,
    {
      driver: driverRecord,
      driverGroup: driverGroupRecord,
    },
   getAttribute(getAttribute(driverGroupRecord, 'belongsToCompany'), 'objectId'),
  )

  // update numberOfDrivers
  await updateNumberOfDrivers(driverGroupRecord);

  return driverGroupDriver;
}

/**
 * @description Given a DriverGroup record or object ID, delete all related records from DriverGroup and DriverGroupDriver
 * 
 * @param {object} driverGroup - DriverGroup record (record || objectId)
 * 
 * @return {object} Destroyed DriverGroup record
 */
async function removeDriverGroup(driverGroup) {
  if (!driverGroup) throw new Error('Valid DriverGroup record is required');

  // Enable the ability to pass in driverGroup object or the object ID
  const driverGroupRecord = await parseInput(driverGroup, 'record');

  // Find all drivers for the group
  const driverGroupDrivers = await getDriverGroupDrivers(driverGroupRecord);

  // Destroy all the DriverGroupDriver record that were found
  if (driverGroupDrivers.length > 0) {
    await Promise.all(driverGroupDrivers.map(async (driverGroupDriver) => {
      const deletedDriverGroupDriver = await destroyRecord({ sessionToken: getCurrentUserSessionToken() }, driverGroupDriver);
    }));
  }

  // Destroy DriverGroup record at the end
  return await destroyRecord({ sessionToken: getCurrentUserSessionToken() }, driverGroupRecord);
}

/**
 * @description Given a DriverGroup record or object ID, get all drivers for the group
 * 
 * @param {object} driverGroup - DriverGroup record (record || objectId)
 * 
 * @return {object} An array of Driver records
 */
async function getDriverGroupDrivers(driverGroup) {
  if (!driverGroup) throw new Error('Valid DriverGroup record is required');

  // Extract objectId if a record is passed in
  const driverGroupObjectId = await parseInput(driverGroup, 'objectId');

  // Find all records in DriverGroupDriver that relates to the given DriverGroup
  const driverGroupDriverQuery = createQuery(DRIVER_GROUP_DRIVER_TABLE);
  setQueryRestriction(driverGroupDriverQuery, QueryRestrictionTypes.EQUAL_TO, 'driverGroup', driverGroupObjectId);

  const driverGroupDrivers = await findRecords(
    { sessionToken: getCurrentUserSessionToken() },
    driverGroupDriverQuery,
    false,
    true,
  );

  return driverGroupDrivers;
}

/**
 * @description Given a Driver and DriverGroup record, remove the driver from the driver group
 * 
 * @param {object} driverGroup - DriverGroup record (record || objectId)
 * @param {object} driverRecord - Driver record
 * 
 * @returns {DriverGorupDriver} Removed driver record
 */
async function removeDriverFromDriverGroup(driverGroup, driverRecord) {
  if (!driverGroup) throw new Error('Valid DriverGroup record is required');
  if (!driverRecord) throw new Error('Valid Driver record is required');

  // Extract objectId if a record is passed in
  const driverGroupObjectId = await parseInput(driverGroup, 'objectId');

  // Find and delete record
  const removeDriverGroupDriverQuery = createQuery(DRIVER_GROUP_DRIVER_TABLE);
  setQueryRestriction(removeDriverGroupDriverQuery, QueryRestrictionTypes.EQUAL_TO, 'driverGroup', driverGroupObjectId);
  setQueryRestriction(removeDriverGroupDriverQuery, QueryRestrictionTypes.EQUAL_TO, 'driver', getAttribute(driverRecord, 'objectId'));

  const driverGroupDriver = await findRecords(
    { sessionToken: getCurrentUserSessionToken() },
    removeDriverGroupDriverQuery,
    true,
    false,
  );

  const deletedDriverGroupDriver = await destroyRecord({ sessionToken: getCurrentUserSessionToken() }, driverGroupDriver);

  // get remaining drivers in the group and update numberOfDrivers
  await updateNumberOfDrivers(driverGroup);
  return deletedDriverGroupDriver;
}

/**
 * @description Update the number of drivers for a driver group
 * 
 * @param {object} driverGroupRecord - DriverGroup record
 * 
 * @returns {DriverGroup} The updated driver group record
 */
async function updateNumberOfDrivers(driverGroup) {
  const driverGroupRecord = await parseInput(driverGroup, 'record');

  const driverGroupDrivers = await getDriverGroupDrivers(driverGroupRecord);
  const updatedDriverGroupRecord = await updateDriverGroup(driverGroupRecord, { numberOfDrivers: driverGroupDrivers.length });

  return updatedDriverGroupRecord;
}

/**
 * @description Pass in a DriverGroup record/objectId and return record/objectId based on the return type
 * 
 * @param {object} driverGroup - DriverGroup record (record || objectId)
 * @param {string} returnType - The type we wish to parse the input to ('record' || 'objectId')
 *
 * @returns {DriverGroup} DriverGroup record
 */
async function parseInput(driverGroup, returnType) {
  let _driverGroup = driverGroup;

  if (typeof driverGroup === 'string' && returnType === 'record') {
    _driverGroup = await getRecordByObjectId(
      { sessionToken: getCurrentUserSessionToken() },
      DRIVER_GROUP_TABLE,
      driverGroup,
    )
  }

  if (typeof driverGroup !== 'string' && returnType === 'objectId') {
    _driverGroup = getAttribute(driverGroup, 'objectId');
  }

  return _driverGroup
}

/**
 * @description Given a Driver's user full name, get an all driver groups that contain that driver
 * 
 * @param {string} userFullName - Driver's user full name
 * 
 * @return {object} An array of Driver records
 */
async function getIndividualDriverGroups(userFullName) {
  if (!userFullName) throw new Error('Valid userFullName string is required');

  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

  // Find all Driver records
  const { drivers } = await getDrivers(
    { sessionToken: getCurrentUserSessionToken() },
    getAttribute(belongsToCompany, 'objectId'),
    undefined,
    [new Filter(QueryRestriction.CONTAINS, 'user_fullName', userFullName)],
    undefined,
    undefined,
    undefined,
    true,
  );

  // Map driver objectIds
  const driverObjectIds = drivers.map(driver => getAttribute(driver, 'objectId'));

  // Find all DriverGroupDriver records
  const driverGroupDriverQuery = createQuery(DRIVER_GROUP_DRIVER_TABLE);
  setQueryRestriction(driverGroupDriverQuery, QueryRestrictionTypes.CONTAINED_IN, 'driver', driverObjectIds);
  includePointers(driverGroupDriverQuery, ['driverGroup']);

  const driverGroupDrivers = await findRecords(
    { sessionToken: getCurrentUserSessionToken() },
    driverGroupDriverQuery,
    false,
    true,
  );

  // Map driverGroups
  const driverGroups = driverGroupDrivers.map(driverGroupDriver => getAttribute(driverGroupDriver, 'driverGroup'))

  return driverGroups;
}

export {
  getDriverGroups,
  createDriverGroup,
  updateDriverGroup,
  removeDriverGroup,
  getDriverGroupDrivers,
  addDriverToDriverGroup,
  removeDriverFromDriverGroup,
  updateNumberOfDrivers,
  getIndividualDriverGroups,
};
