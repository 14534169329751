// http://londoncycle.alexrieux.fr/
import React from 'react';
import PropTypes from 'prop-types';

function getGeoJSONFence(longitude, latitude, width, height) {
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: [[
        [longitude - (width / 2.0), latitude + (height / 2.0)], // top left
        [longitude + (width / 2.0), latitude + (height / 2.0)], // top right
        [longitude + (width / 2.0), latitude - (height / 2.0)], // bottom right
        [longitude - (width / 2.0), latitude - (height / 2.0)], // bottom left
        [longitude - (width / 2.0), latitude + (height / 2.0)], // top left
      ]],
    },
  };
}

function createFence(map, sourceId, longitude, latitude, width, height) {
  map.addSource(`${sourceId}FenceSource`, {
    type: 'geojson',
    data: getGeoJSONFence(longitude, latitude, width, height),
  });

  map.addLayer({
    id: `${sourceId}FenceLayer`,
    type: 'fill',
    source: `${sourceId}FenceSource`,
    paint: {
      'fill-color': 'rgba(200, 100, 240, 0.4)',
      'fill-outline-color': 'rgba(200, 100, 240, 1)',
    },
  });
}


class GeofenceObject extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sourceId: 'CreateGeofence',
    };

    this.mouseDown = this.mouseDown.bind(this);
    this.onEnter = this.onEnter.bind(this);
    this.onLeave = this.onLeave.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onUp = this.onUp.bind(this);
    this.reset = this.reset.bind(this);
  }

  componentWillMount() {
    if (!this.props.map.getSource(`${this.state.sourceId}FenceSource`)) {
      createFence(this.props.map, this.state.sourceId, this.props.longitude, this.props.latitude, this.props.width, this.props.height);
    }
    // When the cursor enters a feature in the point layer, prepare for dragging.
    this.props.map.on('mouseenter', `${this.state.sourceId}FenceLayer`, this.onEnter);
    this.props.map.on('mouseleave', `${this.state.sourceId}FenceLayer`, this.onLeave);
    this.props.map.on('mousedown', this.mouseDown);
  }

  componentWillReceiveProps(nextProps) {
    this.props.map.getSource(`${this.state.sourceId}FenceSource`).setData(getGeoJSONFence(nextProps.longitude, nextProps.latitude, nextProps.width, nextProps.height));
  }

  componentWillUnmount() {
    this.reset();
  }

  onMove(e) {
    if (!this.state.isDragging) return;
    const coords = e.lngLat;
    // Set a UI indicator for dragging.
    this.props.map.getCanvasContainer().style.cursor = 'grabbing';
    // Update the Point feature in `geojson` coordinates
    // and call setData to the source layer `point` on it.
    this.props.movePoint(coords.lat, coords.lng);
  }

  onUp(e) {
    if (!this.state.isDragging) return;
    this.props.map.getCanvasContainer().style.cursor = '';
    this.setState({ ...this.state, isDragging: false });
    // Unbind mouse events
    this.props.map.off('mousemove', this.onMove);
  }

  onEnter(e) {
    // this.props.map.setPaintProperty(`${this.state.sourceId}FenceLayer`, 'fill-color', '#3bb2d0');
    this.props.map.getCanvasContainer().style.cursor = 'move';
    this.setState({ ...this.state, isCursorOverPoint: true });
    this.props.map.dragPan.disable();
  }

  onLeave(e) {
    // this.props.map.setPaintProperty(`${this.state.sourceId}FenceLayer`, 'fill-color', 'rgba(200, 100, 240, 0.4)');
    this.props.map.getCanvasContainer().style.cursor = '';
    this.setState({ ...this.state, isCursorOverPoint: false });
    this.props.map.dragPan.enable();
  }

  mouseDown() {
    if (!this.state.isCursorOverPoint) return;
    this.setState({ ...this.state, isDragging: true });
    // Set a cursor indicator
    this.props.map.getCanvasContainer().style.cursor = 'grab';
    // Mouse events
    this.props.map.on('mousemove', this.onMove);
    this.props.map.once('mouseup', this.onUp);
  }

  reset() {
    this.props.map.off('mouseenter', `${this.state.sourceId}FenceLayer`, this.onEnter);
    this.props.map.off('mouseleave', `${this.state.sourceId}FenceLayer`, this.onLeave);
    this.props.map.off('mousemove', this.onMove);
    this.props.map.off('mouseup', this.onUp);
    this.props.map.off('mousedown', this.mousedown);
    try {
      this.props.map.removeLayer(`${this.state.sourceId}FenceLayer`);
      this.props.map.removeSource(`${this.state.sourceId}FenceSource`);
    } catch (err) {
      console.log('changing routes');
    }
  }

  render() {
    return (
      <div />
    );
  }
}

GeofenceObject.propTypes = {
  map: PropTypes.object.isRequired,
  longitude: PropTypes.number,
  latitude: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  movePoint: PropTypes.func.isRequired,
};

export default GeofenceObject;
