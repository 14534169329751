// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stripeElements * input, .stripeElements * .StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace !important;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.stripeElements * input::placeholder {
  color: #aab7c4;
}

.stripeElements * input:focus,
.stripeElements * .StripeElement--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
`, "",{"version":3,"sources":["webpack://./app/styles/_stripe.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,oDAAoD;EACpD,uFAAuF;EACvF,SAAS;EACT,UAAU;EACV,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;;EAEE,wFAAwF;EACxF,kCAAkC;EAClC,0BAA0B;AAC5B","sourcesContent":[".stripeElements * input, .stripeElements * .StripeElement {\n  display: block;\n  margin: 10px 0 20px 0;\n  max-width: 500px;\n  padding: 10px 14px;\n  font-size: 1em;\n  font-family: 'Source Code Pro', monospace !important;\n  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;\n  border: 0;\n  outline: 0;\n  border-radius: 4px;\n  background: white;\n}\n\n.stripeElements * input::placeholder {\n  color: #aab7c4;\n}\n\n.stripeElements * input:focus,\n.stripeElements * .StripeElement--focus {\n  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;\n  -webkit-transition: all 150ms ease;\n  transition: all 150ms ease;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
