import React from 'react';
import { Badge as PRBadge } from 'primereact/badge';

// Styles
import './style.scss';

/**
 * @description A wrapper component for the PrimeReact Badge
 * @param {String} className - Custom container className
 */
function Badge({ ...props }) {

  let className = 'sbc-badge';

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div className={className}>
      <PRBadge {...props} />
    </div>
  );
}

export default Badge;
