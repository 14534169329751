import * as ActionConstants from '../actions/ActionConstants';

const initialState = {
  eldViolations: [],
  fetched: false,
};

export function ELDViolation(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_ELDVIOLATIONS_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.FETCH_ELDVIOLATIONS_INPROGRESS,
        fetched: false,
        error: undefined,
      };
    }
    case ActionConstants.FETCH_ELDVIOLATIONS_SUCCESS: {
      return {
        status: ActionConstants.FETCH_ELDVIOLATIONS_SUCCESS,
        eldViolations: state.eldViolations.concat(action.eldViolations),
        error: undefined,
        fetched: true,
      };
    }
    case ActionConstants.FETCH_ELDVIOLATIONS_ERROR: {
      return {
        ...state,
        status: ActionConstants.FETCH_ELDVIOLATIONS_ERROR,
        error: action.error,
        fetched: false,
      };
    }
    case ActionConstants.DELETE_ELDVIOLATIONS: {
      return {
        ...initialState,
        status: action.type,
      };
    }
    case ActionConstants.ADD_ELDVIOLATION_SUCCESS: {
      const updatedELDViolations = [].concat(state.eldViolations);
      updatedELDViolations.unshift(action.eldViolation);
      return {
        ...state,
        status: action.type,
        eldViolations: updatedELDViolations,
        error: undefined,
      };
    }
    default:
      return state;
  }
}
