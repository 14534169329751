export default class HarshDrivingEvent {
  /**
   * 
   * @param {string} objectId 
   * @param {Driver} driver 
   * @param {number} code 
   * @param {Date} dateTimeUTC 
   * @param {ELDDailyCertification} eldDailyCertification
   */
  constructor(objectId, driver, code, dateTimeUTC, eldDailyCertification) {
    this.objectId = objectId;
    this.driver = driver;
    this.code = code;
    this.dateTimeUTC = dateTimeUTC;
    this.eldDailyCertification = eldDailyCertification;
  }
}