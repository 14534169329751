/* global analytics */
import * as moment from 'moment';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import * as ParseHelpers from 'api/Parse';
import * as CompanyAnalytics from 'api/CompanyAnalytics/CompanyAnalytics';

/** @module Analytics */


function getAnalyticsUser() {
  return analytics && analytics.user && analytics.user();
}

/**
 * @memberof module:Analytics
 * 
 * @param {*} id 
 * @param {*} traits 
 * 
 * @returns 
 */
function identify(_id, traits) {
  if (window.location.hostname.includes('app.onswitchboard')) {
    let id = _id;
    if (!_id && ParseHelpers.getCurrentUser()) {
      id = ParseHelpers.getCurrentUser().get('belongsToCompany').id;
    }
    return analytics.identify(id, traits);
  }
}

/**
 * @memberof module:Analytics
 * 
 * @param {*} user 
 * 
 * @returns 
 */
const identifyUser = async (user) => {
  if (window.location.hostname.includes('app.onswitchboard')) {
    if ((user && user.id)) {
      const company = user.get('belongsToCompany');
      const firstName = user.get('firstName');
      const lastName = user.get('lastName');
      const email = `${user.get('email')}`;
      const companyName = `${company.get('name')}`;
      const phoneNumber = `${user.get('phoneNumber') || ''}` || `${company.get('phoneNumber') || ''}`;
      const promoCode = `${company.get('promoCode')}`;
      const createdAt = user.get('createdAt');
      const updatedAt = user.get('updatedAt');
      const companyId = `${company.id}`;
      const userId = `${user.id}`;

      return analytics.identify(userId, {
        email,
        companyName,
        companyId,
        promoCode,
        lastLoggedIn: moment().toISOString(),
        phoneNumber,
        createdAt,
        updatedAt,
      });
    }
    return false;
  }
}

/**
 * @memberof module:Analytics
 * 
 * @param {*} event 
 * @param {*} properties 
 * 
 * @returns 
 */
function track(event, properties) {
  if (
    getAnalyticsUser()?.id()
  ) {
    return analytics.track(event, properties);
  }
}

/**
 * @memberof module:Analytics
 * 
 * @returns 
 */
function page() {
  if (
    window.location.hostname.includes('app.onswitchboard')
    && getAnalyticsUser()?.id()
  ) {
    return analytics.page();
  }
}

/**
 * @memberof module:Analytics
 * 
 * @param {*} eventName 
 * @param {*} propertiesObj 
 * 
 * @returns 
 */
function trackForCompany(eventName, propertiesObj) {
  if (
    window.location.hostname.includes('app.onswitchboard')
    && getAnalyticsUser()?.id()
  ) {
    const companyObj = Getters.getCurrentUserCompany();
    return ParseHelpers.callCloudFunction('trackForCompany', {
      companyId: companyObj && companyObj.id,
      eventName,
      propertiesObj
    });
  }
}

/**
 * @memberof module:Analytics
 * 
 * @param {*} identifier 
 * @param {*} traitsObj 
 * 
 * @returns 
 */
function identifyOnceForCompany(identifier, traitsObj) {
  if (
    window.location.hostname.includes('app.onswitchboard')
    && getAnalyticsUser()?.id()
  ) {
    const companyObj = Getters.getCurrentUserCompany();
    if (
      companyObj && companyObj.id &&
      identifier &&
      traitsObj
    ) {
      return ParseHelpers.callCloudFunction('identifyOnceForCompany', {
        companyId: companyObj && companyObj.id,
        identifier,
        traitsObj
      });
    }
    return false;
  }
}

/**
 * @memberof module:Analytics
 * 
 * @returns 
 */
function identifyUserForCompany(companyId, userId) {
  if (
    // window.location.hostname.includes('app.onswitchboard')
    getAnalyticsUser()?.id()
  ) {
    const companyObj = Getters.getCurrentUserCompany();
    return ParseHelpers.callCloudFunction('identifyUserForCompany', {
      companyId: companyObj && companyObj.id,
      userId,
    });
  }
}

/**
 * @memberof module:Analytics
 * 
 * @returns 
 */
function syncUserPropertiesFromCompany(userId) {
  if (
    window.location.hostname.includes('app.onswitchboard')
    && getAnalyticsUser()?.id()
  ) {
    return ParseHelpers.callCloudFunction('syncUserPropertiesFromCompany', {
      userId,
    });
  }
}

export {
  getAnalyticsUser,
  identify,
  identifyUser,
  page,
  track,
  identifyOnceForCompany,
  trackForCompany,
  identifyUserForCompany,
  syncUserPropertiesFromCompany,
};
