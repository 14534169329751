import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';

import sbStore from 'sbStore';
import history from 'sbHistory';

// Enums
import { EquipmentTypes } from 'enums/Equipment';

// Actions
import { getLinkedVehiclesForState, deleteVehiclesForState, updateVehiclesVehicleLocationsForState } from 'actions/Vehicle';
import { getLinkedTrailersForState, deleteTrailersForState, updateTrailersLocationsForState } from 'actions/Trailer';
import { fetchGeofencesForState, fetchLinkedGeofencesForState, deleteGeofencesForState } from 'actions/Geofence';

// API
import { getCurrentUser } from 'api/Parse';
import { subscribeUnsubscribeChannel } from 'api/PubNub';
import { getLocalReverseGeocode } from 'api/Geocode';

// sb-csapi
import { getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import PersistentDrawer from 'sbCore/PersistentDrawer/PersistentDrawer';
import VehicleMap from 'components/Map.old/container/VehicleMap';
import Sidebar from 'components/Map/Sidebar/Sidebar';
import MapListSidebar from 'components/MapListSidebar/container/MapListSidebar';
import EquipmentDisplayFilter from 'components/Map.old/view/EquipmentDisplayFilter';

// CSS
import styles from './MapLayout.module.css';

// Context
import StoreContext from 'contexts/StoreContext';

class MapLayout extends React.Component {
  constructor(props) {
    super(props);
    const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    const isMobile = (!(width > 768));
    this.state = {
      displayMarkers: {
        vehicles: true,
        trailers: true,
      },
      filter: {
        type: 'Vehicles',
        filterArr: [],
      },
      addEditGeofence: {},
      sort: { updatedAt: 'descending' },
      mapCenter: undefined,
      activeSidebarObject: {
        object: null,
        type: null,
        addressString: null,
      },
      highlightedSidebarObjects: [],
      centerAndZoomTrigger: false,
      driverBehaviour: {
        speedLimit: props.Company.company.get('speedLimitKm'),
      },
      isMobile,
      isSidebarOpen: undefined,
      activeEquipmentInformation: undefined,
    };
    this.refreshMapInfoTimer = null;
    this.fetchVehicles = this.fetchVehicles.bind(this);
    this.fetchTrailers = this.fetchTrailers.bind(this);
    this.selectSidebarObject = this.selectSidebarObject.bind(this);
    this.hoverSidebarObject = this.hoverSidebarObject.bind(this);
    this.filter = this.filter.bind(this);
    this.updateAssets = this.updateAssets.bind(this);
    // this.addVehicleLocation = this.addVehicleLocation.bind(this);
    this.setAddEditGeofence = this.setAddEditGeofence.bind(this);
    this.toggleEquipmentMarkers = this.toggleEquipmentMarkers.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.setActiveEquipmentInformation = this.setActiveEquipmentInformation.bind(this);
  }

  componentWillMount() {
    document.title = t('Map - Switchboard');
    const clientHeight = document.documentElement.clientHeight;
    this.vehiclesTableHeight = document.documentElement.clientHeight * 0.7; // 70vh defined by CSS
    if (clientHeight <= 1366) { // laptop size
      this.vehiclesTableHeight = clientHeight * 0.6;
    }
    this.vehiclesTableRowAmount = Math.round(this.vehiclesTableHeight / 43) + 5; // amount of el's to display
    this.pageCount = 0; // result fetch multiplier
    this.fetchVehicles();
    this.fetchTrailers();
    fetchLinkedGeofencesForState(undefined, undefined, undefined, { name: 'ascending' });
    // subscribeUnsubscribeChannel(`${getCurrentUser().get('belongsToCompany').id}VehicleLocation`, true);
  }

  componentDidMount() {
    this.updateAssets();
    const refreshMapInfoTimer = setInterval(() => {
      this.updateAssets();
    }, 30000); // refresh every 30 seconds 30000

    this.refreshMapInfoTimer = refreshMapInfoTimer;
  }

  componentWillReceiveProps(nextProps) {
    // if (this.state.activeSidebarObject && this.state.activeSidebarObject.type && nextProps.Vehicle.status === 'UPDATE_VEHICLE_SUCCESS' && nextProps.Vehicle.updateReference && nextProps.Vehicle.updateReference.id === this.state.activeSidebarObject.id) {
    //   this.updateAssets();
    // } else if ((this.props.Vehicle.vehicles.length === 0 && nextProps.Vehicle.vehicles.length !== 0) || (this.props.Trailer.trailers.length === 0 && nextProps.Trailer.trailers.length !==0)) {
    //   this.updateAssets();
    // }
    // Refreshing every 30 seconds, don't need pubnub stuff
    if ((this.props.Vehicle.vehicles.length === 0 && nextProps.Vehicle.vehicles.length !== 0) || (this.props.Trailer.trailers.length === 0 && nextProps.Trailer.trailers.length !== 0)) {
      this.updateAssets();
    }
    this.setState({ ...this.setState });
  }

  componentWillUnmount() {
    deleteVehiclesForState();
    deleteTrailersForState();
    clearTimeout(this.refreshMapInfoTimer);
    // if (getCurrentUser()) {
    //   subscribeUnsubscribeChannel(`${getCurrentUser().get('belongsToCompany').id}VehicleLocation`, false);
    // }
  }

  updateAssets() {
    const currentUser = getCurrentUser();
    const userSpecialPermission = getAttribute(currentUser, 'userSpecialPermission');
    // if the company is not granted access to the map module
    if (userSpecialPermission && getAttribute(userSpecialPermission, 'disableMapModule')) {
      return history.push({ pathname: '404' });
    }

    const { Vehicle, Trailer } = this.props;
    const { filter, sort } = this.state;
    const getMapInfoPromises = [];

    if (Vehicle.vehicles.length === 0) {
      getMapInfoPromises.push(this.fetchVehicles());
    } else {
      getMapInfoPromises.push(updateVehiclesVehicleLocationsForState(Vehicle.vehicles, filter.type === 'Vehicles' && sort));
    }

    if (Trailer.trailers.length === 0) {
      getMapInfoPromises.push(this.fetchTrailers());
    } else {
      getMapInfoPromises.push(updateTrailersLocationsForState(Trailer.trailers, filter.type === 'Trailers' && sort));
    }

    Promise.all(getMapInfoPromises).then(
      ([vehicles, trailers]) => {
        this.setState(this.state);
      }
    ).catch((error) => {
      console.log(error);
    });
  }

  fetchVehicles() {
    const promise = new Promise((resolve, reject) => {
      getLinkedVehiclesForState(
        undefined,
        undefined,
        [].concat(...this.state.filter.filterArr, { queryType: 'equalTo', name: 'enabled', value: true }),
        this.state.filter.type === 'Vehicles' && this.state.sort,
        undefined, this.props.Company.company
      ).then(
        vehicles => {
          resolve(vehicles);
        },
        error => {
          reject(error);
        }
      );
    });
    return promise;
  }

  fetchTrailers() {
    const promise = new Promise((resolve, reject) => {
      getLinkedTrailersForState(undefined, undefined, [].concat(
        ...this.state.filter.filterArr,
        { queryType: 'exists', name: 'tc_devices' },
      ), this.state.filter.type === 'Trailers' && this.state.sort, undefined, this.props.Company.company)
        .then(
          trailers => {
            resolve(trailers);
          },
          error => {
            reject(error);
          }
        );
    });
    return promise;
  }

  setAddEditGeofence(type, geofence) {
    const promise = new Promise((resolve) => {
      if (type) {
        this.setState({ ...this.state, addEditGeofence: { type, geofence } }, () => {
          resolve();
        });
      } else {
        this.setState({ ...this.state, addEditGeofence: {} }, () => {
          resolve();
        });
      }
    });
    return promise;
  }

  filter(type, filterArr, sortBy) {
    const sort = {};
    if (sortBy && this.state.sort[sortBy] && this.state.sort[sortBy] === 'ascending') {
      sort[sortBy] = 'descending';
    } else if (sortBy) {
      sort[sortBy] = 'ascending';
    }
    this.setState({ ...this.state, filter: { type, filterArr }, sort }, () => {
      if (type === 'Vehicles') {
        if (!sortBy) {
          sort.updatedAt = 'descending';
        }
        deleteVehiclesForState().then(() => {
          this.pageCount = 0;
          this.fetchVehicles();
        });
      } else if (type === 'Trailers') {
        if (!sortBy) {
          sort.updatedAt = 'descending';
        }
        deleteTrailersForState().then(() => {
          this.pageCount = 0;
          this.fetchTrailers();
        });
      } else if (type === 'Geofences') {
        if (!sortBy) {
          sort.name = 'ascending';
        }
        deleteGeofencesForState().then(() => {
          this.pageCount = 0;
          fetchLinkedGeofencesForState(undefined, undefined, this.state.filter.filterArr, sort, undefined, this.props.Company.company);
        });
      }
    });
  }

  async selectSidebarObject(type, parseObj, centerAndZoomTrigger) {
    if (!parseObj || (parseObj && this.state.activeSidebarObject.object && parseObj.id === this.state.activeSidebarObject.object.id)) {

      // i DON'T KNOW why you need two set states to null the activeSidebarObject... but if you have a better solution please let me know - michael
      await this.setState({
        ...this.state,
        activeSidebarObject: { type: null, object: null, addressString: null },
        activeEquipmentInformation: null,
        centerAndZoomTrigger
      });
      return await this.setState({
        ...this.state,
        activeSidebarObject: { type: null, object: null, addressString: null },
        activeEquipmentInformation: null,
        centerAndZoomTrigger
      });
    } else {
      await this.setState({ ...this.state, activeSidebarObject: { type, object: parseObj, addressString: null }, centerAndZoomTrigger });
      const isVehicleOrTrailer = ['Vehicle', 'Trailer'].indexOf(type) !== -1;
      if (isVehicleOrTrailer && parseObj.get('vehicleLocation') && parseObj.get('vehicleLocation').get('location')) {
        const address = await getLocalReverseGeocode([[parseObj.get('vehicleLocation').get('location').latitude, parseObj.get('vehicleLocation').get('location').longitude]]);
        if (address[0]) {
          const addressString = address && address[0] && `${address[0].asciiName}, ${address[0].admin1Code.asciiName}, ${address[0].countryCode}`;
          return this.setState({ ...this.state, activeSidebarObject: { type, object: parseObj, addressString }, centerAndZoomTrigger: false });
        }
      }
    }
  }

  hoverSidebarObject(parseObj, enterBool) {
    if (enterBool && parseObj) {
      this.setState({ ...this.state, highlightedSidebarObjects: [parseObj] });
    } else {
      this.setState({ ...this.state, highlightedSidebarObjects: [] });
    }
  }

  toggleEquipmentMarkers(type) {
    const newState = { ...this.state };
    newState.displayMarkers = { ...newState.displayMarkers };

    if (type === 'Vehicles') {
      newState.displayMarkers.vehicles = !this.state.displayMarkers.vehicles;
    } else {
      newState.displayMarkers.trailers = !this.state.displayMarkers.trailers;
    }

    this.setState(newState);
  }

  toggleSidebar() {
    this.setState({ ...this.state, isSidebarOpen: !this.state.isSidebarOpen });
  }

  setActiveEquipmentInformation(activeEquipmentInformation) {
    this.setState({ ...this.state, activeEquipmentInformation })
  }

  render() {
    const { Company, Vehicle, Trailer, Geofence } = this.props;
    (() => {
      window.Localize.translatePage();
    })();
    return (
      <div className="d-flex mw-100">
        <Provider store={sbStore}>
          <PersistentDrawer
            width="350px"
            itemClassName="map-sidebar surface-0"
            isExpanded={!this.state.isTripHistoryOpen}
            onActiveChanged={(_isDrawerExpanded) => this.setState({ ...this.state, isSidebarOpen: _isDrawerExpanded })}
          >
            <Sidebar
              Geofence={this.props.Geofence} // This is temporarily here to pass in geofences into the new sidebar
              addEditGeofence={this.state.addEditGeofence}
              setAddEditGeofence={this.setAddEditGeofence}
              onHoverGeofence={this.hoverSidebarObject}
              activeGeofence={this.state.activeSidebarObject.object}
              onSelectEquipment={(type, objectId, centerAndZoomTrigger) => {
                // Fetch the appropriate equipment with objectId - this is to enable compatibility with old maps
                let _type;
                let equipmentObject;

                if (type === EquipmentTypes.VEHICLE) {
                  _type = 'Vehicle';
                  equipmentObject = Vehicle.vehicles.find((vehicle) => vehicle.id === objectId);
                } else if (type === EquipmentTypes.TRAILER) {
                  _type = 'Trailer';
                  equipmentObject = Trailer.trailers.find((trailer) => trailer.id === objectId);
                } else if (type === 'Geofence') {
                  _type = 'Geofence';
                  equipmentObject = Geofence.geofences.find((geofence) => geofence.id === objectId);
                }

                this.selectSidebarObject(_type, equipmentObject, centerAndZoomTrigger);
              }}
              handleSetAddEditGeofence={(type, objectId) => this.setAddEditGeofence(type, Geofence.geofences.find((geofence) => geofence.id === objectId))}
              activeEquipment={getAttribute(this.state.activeSidebarObject.object, 'unitId', true)}
              // Active equipment information obtained from sidebar
              setActiveEquipmentInformation={(activeEquipmentInformation) => this.setActiveEquipmentInformation(activeEquipmentInformation)}
              speedLimitKm={this.state.driverBehaviour.speedLimit}
            />
          </PersistentDrawer>
        </Provider>

        <EquipmentDisplayFilter
          className={`${styles.equipmentDisplayFilter} ${!this.state.isSidebarOpen ? styles.sidebarClosed : ''}`}
          handleCheckboxToggle={this.toggleEquipmentMarkers}
          vehiclesChecked={this.state.displayMarkers.vehicles}
          trailersChecked={this.state.displayMarkers.trailers}
        />

        {/* <MapListSidebar
          company={Company && Company.company}
          activeSidebarObject={this.state.activeSidebarObject}
          handleFilter={this.filter}
          hoverSidebarObject={this.hoverSidebarObject}
          selectSidebarObject={this.selectSidebarObject}
          type={this.state.filter.type}
          sortBy={Object.keys(this.state.sort).length > 0 ? Object.keys(this.state.sort)[0] : ''}
          Vehicle={this.props.Vehicle}
          Trailer={this.props.Trailer}
          Geofence={this.props.Geofence}
          addEditGeofence={this.state.addEditGeofence}
          setAddEditGeofence={this.setAddEditGeofence}
          speedLimitKm={this.state.driverBehaviour.speedLimit}
          toggleEquipmentMarkers={this.toggleEquipmentMarkers}
          displayMarkers={this.state.displayMarkers}
          isOpen={this.state.isSidebarOpen}
          toggleSidebar={this.toggleSidebar}
        /> */}
        <VehicleMap
          activeObject={this.state.activeSidebarObject}
          addEditGeofence={this.state.addEditGeofence}
          geofences={this.props.Geofence.geofences}
          vehicles={this.props.Vehicle.vehicles}
          updatedVehicle={this.props.Vehicle.updateReference}
          trailers={Trailer.trailers}
          updatedTrailer={Trailer.updateReference}
          highlightedObjects={this.state.highlightedSidebarObjects}
          centerAndZoomTrigger={this.state.centerAndZoomTrigger}
          resetCenterAndZoomTrigger={() => this.setState({ ...this.state, centerAndZoomTrigger: false })}
          refreshGeofences={() => fetchLinkedGeofencesForState(undefined, undefined, this.state.filter.filterArr, this.state.sort)}
          setAddEditGeofence={this.setAddEditGeofence}
          selectObject={this.selectSidebarObject}
          displayMarkers={this.state.displayMarkers}
          isSidebarOpen={this.state.isSidebarOpen}
          isMobile={this.state.isMobile}
          // Active equipment information passed from sidebar
          activeEquipmentInformation={this.state.activeEquipmentInformation}
          setActiveEquipmentInformation={(equipmentInformation) => this.setActiveEquipmentInformation(equipmentInformation)}
          onToggleTripHistory={(isTripHistoryOpen) => {
            this.setState({ ...this.state, isTripHistoryOpen: isTripHistoryOpen === true ? true : undefined })
          }}
        />
      </div>
    );
  }
}

MapLayout.propTypes = {
  Company: PropTypes.object.isRequired,
  Geofence: PropTypes.object.isRequired,
  Vehicle: PropTypes.object.isRequired,
  Trailer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Company, CloudUserPreferences, Geofence, Vehicle, Trailer } = state;
  return {
    Company,
    Geofence,
    Vehicle,
    Trailer,
    CloudUserPreferences,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(MapLayout);
