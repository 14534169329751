import React, { useState } from 'react';

// sbCore
import Autocomplete from 'sbCore/Autocomplete/Autocomplete';

// styles
import './style.scss';

function LogDownloadDrivers({ selectedDrivers, selectableDrivers, editSelectedDrivers }) {
  const [filteredDrivers, setFilteredDrivers] = useState([]);

  const _selectableDrivers = [...selectableDrivers];
  // if there are more than 1 drivers, allow an option to Select All
  if (_selectableDrivers.length > 1) {
    _selectableDrivers.unshift({
      user_fullName: (
        <div
          className="text-black-lighten border-gray-calm-lighten"
          style={{
            borderBottom: '.05em solid',
          }}
        >
          <div style={{ paddingTop: '.25em', paddingBottom: '.25em' }}>Select All Drivers</div>
        </div>
      ),
      objectId: 'selectall49520bb',
    });
  }

  function autocompleteSearch(stringSearch) {
    let _filteredDrivers = [..._selectableDrivers];
    if (stringSearch.trim().length) {
      _filteredDrivers = selectableDrivers.filter((driver) => {
        const fullName = driver.user_fullName.trim().toLowerCase();
        const nameSearch = stringSearch.trim().toLowerCase();
        if (fullName.includes(nameSearch)) return true;
        return false;
      });
    }

    setFilteredDrivers(_filteredDrivers);
  }

  function selectDriver(selectedDrivers) {
    let _selectedDrivers = [...selectedDrivers];

    // if the user did a Select All, select all items and filter out that custom item
    const selectAllItem = _selectedDrivers.find((driverObject) => driverObject.objectId === 'selectall49520bb');
    if (selectAllItem) {
      _selectedDrivers = _selectableDrivers.slice(1);
    }

    // get rid of duplicate selections
    const seenSelectedDrivers = {};
    _selectedDrivers = _selectedDrivers.filter(driverObject => {
      if (!seenSelectedDrivers[driverObject.objectId]) {
        seenSelectedDrivers[driverObject.objectId] = true;
        return true;
      }
      return false;
    });

    editSelectedDrivers(_selectedDrivers);
  }

  const driversFieldTemplate = (
    <div className="modal-template-content">
      Generate driver logs in just a few steps! To get started, enter the names of drivers whose logs are needed.
      <div className="mt-4 w-75">
        <Autocomplete
          field="user_fullName"
          value={selectedDrivers}
          suggestions={filteredDrivers}
          onChange={(e, f, g) => selectDriver(e.value)}
          completeMethod={(event) => autocompleteSearch(event.query)}
          multiple
          dropdown
          placeholder="Enter Driver Names"
          sbVariant="fill-container"
        />
      </div>
    </div>
  );

  return (
    <div>
      {driversFieldTemplate}
    </div>
  );
}

export default LogDownloadDrivers;
