import React, { useState, useEffect } from 'react';
import moment from 'moment';
import momentTz from 'moment-timezone';

// CSAPI API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// API
import { getDispatchReceivables } from 'api/Dispatch/DispatchReceivable';

// CSAPI Enums
import { PaymentStatus } from 'sb-csapi/dist/enums/Dispatch/PaymentStatus';
import { QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';

// CSAPI sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Components
import ReceivablesDrawer from 'components/Dispatch/AccountingReceivablesTable/ReceivablesDrawer/ReceivablesDrawer';
import PaymentTermMultiSelect from 'components/Dispatch/PaymentTermMultiSelect/PaymentTermMultiSelect';

// sbCore Components
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import PaymentStatusBadge from 'sbCore/PaymentStatusBadge/PaymentStatusBadge';
import Button from 'sbCore/Button/Button';
import InputTextarea from 'sbCore/InputTextarea/InputTextarea';

// Styles
import './style.scss';

/**
 * @description Renders a table of jobs and the payables and receivables that need to be actioned
 * @returns
 */
function AccountingReceivablesTable({ ...props }) {
  // ** useStates ** //
  const [selectedReceivable, setSelectedReceivable] = useState(null);

  const [dispatchReceivableTableObjectsArr, setDispatchReceivableTableObjectsArr] = useState([]);
  const [totalDispatchReceivablesCount, setTotalDispatchReceivablesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  // DataTable pagination and filtering attributes
  const [fetchAttributes, setFetchAttributes] = useState({
    first: 0,
    page: 0,
    rows: 20,
    filters: {
      paymentTermType: { value: undefined, matchMode: 'equals' },
    },
  });

  // ** useEffects ** //

  // Triggers on intial render and when filters are updated
  useEffect(() => {
    let didCancel = false;

    async function refresh() {
      const { dispatchReceivableFilters } = await getFilters();
      _getDispatchReceivables(dispatchReceivableFilters);
    }

    async function _getDispatchReceivables(dispatchReceivableFilters) {
      const _dispatchReceivableTableObjectsArr = [];
      const { totalDispatchReceivablesCount, dispatchReceivables } = await getDispatchReceivables(
        undefined, // options - default
        undefined, // companyobjectid - default
        false, // include child company results
        dispatchReceivableFilters, // filters
        undefined, // sort - default
        ['dispatchJobAccounting', 'dispatchJobAccounting.dispatchJob', 'dispatchJobAccounting.dispatchJob.customerDispatchOrganization', 'dispatchDocumentInvoice'], // includes
        undefined, // selects
        fetchAttributes.page, // page
        fetchAttributes.rows, // limit
        false, // query all
      );

      // Create dispatch job objects to be used in the datatable
      dispatchReceivables.forEach((dispatchReceivable) => {
        if (!dispatchReceivable) return;
        const dispatchJobAccounting = getAttribute(dispatchReceivable, 'dispatchJobAccounting');
        let dispatchJob;
        let dispatchJobObjectId;
        let batchId;
        if (dispatchJobAccounting) {
          dispatchJob = getAttribute(dispatchJobAccounting, 'dispatchJob');
          if (!dispatchJob) return; // this shouldn't happen, but it definitely does for test records
          dispatchJobObjectId = getAttribute(dispatchJob, 'objectId');
          batchId = getAttribute(dispatchJob, 'batchId');
        }

        const notes = getAttribute(dispatchReceivable, 'notes');
        const invoicedDateTime = getAttribute(dispatchReceivable, 'invoicedDateTime');
        const paymentTermType = getAttribute(dispatchReceivable, 'paymentTermType');
        const status = getAttribute(dispatchReceivable, 'status');
        const isVoided = getAttribute(dispatchReceivable, 'isVoided');

        const invoiceDaysOutstanding = getInvoiceDaysOutstanding(invoicedDateTime);

        const dispatchJobTableObject = {
          dispatchJob,
          dispatchReceivable,
          dispatchJobObjectId,
          jobId: batchId || '-',
          invoicedDateTime: invoicedDateTime ? momentTz(invoicedDateTime).format('DD-MM-YYYY') : '-',
          paymentTermType: paymentTermType || '-',
          paymentStatusInt: (status || status === 0) ? status : '-',
          invoiceDaysOutstanding: invoiceDaysOutstanding,
          notes: notes || '-',
          isVoided,
        };

        _dispatchReceivableTableObjectsArr.push(dispatchJobTableObject);
      });

      if (!didCancel) {
        setDispatchReceivableTableObjectsArr(_dispatchReceivableTableObjectsArr);
        setTotalDispatchReceivablesCount(totalDispatchReceivablesCount);
        setIsLoading(false);
      }
    }

    async function getFilters() {
      const datatableFilters = fetchAttributes.filters;

      // Don't displayed voided receivables
      const dispatchReceivableFilters = [new Filter(QueryRestrictionTypes.NOT_EQUAL_TO, 'isVoided', true)];

      Object.entries(datatableFilters).forEach(async ([key, filter]) => {
        if (filter.value === null || filter.value === '') return;
        if (key === 'paymentTermType') {
          const paymentTermTypes = filter.value;
          if (paymentTermTypes && paymentTermTypes.length > 0) {
            dispatchReceivableFilters.push(new Filter(QueryRestrictionTypes.CONTAINED_IN, 'paymentTermType', paymentTermTypes));
          }
        }
      });
      return { dispatchReceivableFilters };
    }

    refresh();
    return () => { didCancel = true; };
  }, [fetchAttributes, selectedReceivable]);

  // ** Helper Functions ** //

  // Return the number of days since the invoice has been sent and hasn't been fully paid
  function getInvoiceDaysOutstanding(invoicedDateTime) {
    if (!invoicedDateTime) return;
    const currentDateTime = momentTz();

    const duration = moment.duration(currentDateTime.diff(momentTz(invoicedDateTime)));
    const differenceInDays = Math.floor(duration.asDays());

    return differenceInDays;
  }

  // ** DataTable Templates ** //

  function paymentStatusTemplate(rowData) {
    const paymentStatusBadge = (
      <PaymentStatusBadge className="z-depth-0" paymentStatusInt={rowData.paymentStatusInt} isVoided={rowData.isVoided} />
    );

    return (
      <div className="flex">
        {paymentStatusBadge}
      </div>
    );
  }

  function jobIdBodyTemplate(rowData) {
    const dispatchItemRoute = `/dispatch/job/${rowData.dispatchJobObjectId}`;
    return (rowData.jobId === '-'
      ? <div className="text-400">{rowData.jobId}</div>
      : <Button label={rowData.jobId} className="p-button-text job-id-btn" onClick={() => history.push(dispatchItemRoute)} />
    );
  }

  function invoicedDateTimeBodyTemplate(rowData) {
    let invoiceDaysOutstanding = rowData.invoiceDaysOutstanding ? ` (${rowData.invoiceDaysOutstanding} days past due)` : '';
    if ((rowData.paymentStatusInt === PaymentStatus.PAID.status) || rowData.invoiceDaysOutstanding < 0) invoiceDaysOutstanding = '';
    return (
      <div className="flex">
        {rowData.invoicedDateTime}
        {invoiceDaysOutstanding}
      </div>
    );
  }

  function paymentTermTemplate(rowData) {
    const paymentTermString = rowData.paymentTermType !== '-' ? `${rowData.paymentTermType} days` : rowData.paymentTermType;
    return (
      <div>{paymentTermString}</div>
    );
  }

  function notesBodyTemplate(rowData) {
    return (
      <InputTextarea
        className="text-sm notes-body"
        value={rowData.notes}
        rows={5}
        cols={30}
        disabled
        autoResize
      />
    );
  }

  // ** DataTable Filters ** //
  function paymentTermFilter(options) {
    return (
      <PaymentTermMultiSelect
        selectedPaymentTerms={options.value}
        onChange={(value) => options.filterApplyCallback(value)}
      />
    );
  }

  // ** DataTable Callback Functions ** //

  // Triggers when the filter is updated
  function onFilter(event) {
    event.first = 0;
    setFetchAttributes(event);
  }

  function renderHeader() {
    return (
      <div className="flex justify-content-between align-items-center">
        <h5 className="m-0 text-2xl font-semibold">Receivables</h5>
      </div>
    );
  }

  // Updates table when new page is selected
  function onPage(event) {
    setIsLoading(true);
    setFetchAttributes(event);
  }

  // ** Components ** //
  const dispatchJobsAccountingTable = (
    <DataTable
      value={dispatchReceivableTableObjectsArr}
      header={() => renderHeader()}
      responsiveLayout="scroll"
      emptyMessage="No Receivables Found"
      loading={isLoading}
      rowHover
      // Selection Props
      selectionMode="single"
      selection={selectedReceivable}
      onSelectionChange={e => setSelectedReceivable(e.value)}
      // Table Pagination Props
      paginator
      rows={fetchAttributes.rows}
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
      // Lazy Loading Props
      lazy
      first={fetchAttributes.first}
      totalRecords={totalDispatchReceivablesCount}
      onPage={onPage}
      // Filter Props
      filterDisplay="row"
      onFilter={(event) => onFilter(event)}
      filters={fetchAttributes.filters}
    >
      <Column body={paymentStatusTemplate} header="Payment Status" style={{ minWidth: '16rem' }} />
      <Column body={jobIdBodyTemplate} header="Job ID" style={{ minWidth: '16rem' }} />
      <Column body={invoicedDateTimeBodyTemplate} field="invoicedDateTime" header="Invoice Date" style={{ minWidth: '16rem' }} />
      <Column
        header="Payment Term"
        body={paymentTermTemplate}
        field="paymentTermType"
        style={{ minWidth: '16rem' }}
        filter
        filterElement={paymentTermFilter}
        showClearButton={(![undefined, null, -1].includes(fetchAttributes.filters.paymentTermType.value))}
      />
      <Column field={notesBodyTemplate} header="Notes" style={{ minWidth: '16rem' }} />
    </DataTable>
  );

  const receivablesDrawer = (
    <ReceivablesDrawer
      dispatchReceivable={selectedReceivable && selectedReceivable.dispatchReceivable}
      dispatchJob={selectedReceivable && selectedReceivable.dispatchJob}
      isDrawerOpen={selectedReceivable}
      closeDrawer={() => setSelectedReceivable(null)}
    />
  );

  return (
    <div className="card mt-3 accounting-receivables-table">
      {dispatchJobsAccountingTable}
      {receivablesDrawer}
    </div>
  );
}

export default AccountingReceivablesTable;
