import React from 'react';
import PropTypes from 'prop-types';
import history from 'sbHistory';

// API
import { getSerialized, getDeserialized } from 'api/URL';

// Components
import IFTAHelpDialog from './IFTAHelpDialog';

// CSS
import styles from './Sidebar.module.scss';

function Sidebar(props) {
  const activeStyle = `${styles.active} ${styles.option}`;

  const { subviews } = props;
  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarTitle}>
        <div className={styles.title}>
          <span>IFTA</span>
        </div>
      </div>

      <div className={styles.subViews + " translate-me"}>
        {Object.values(subviews).map(view => {
          let style = styles.option;
          if (view.value === props.activeSubview) style = activeStyle;

          if (view.value === ('mileages')) {
            const queryStrObj = getDeserialized(props.location.search).params;
            queryStrObj.vehicle = undefined;
            queryStrObj.view = 'mileages';
            if (props.company) {
              return (
                <div
                  key={`IFTA_Sidebar_${view.value}`}
                  className={style}
                  onClick={() => {
                    history.push({ search: getSerialized(queryStrObj).query });
                  }}
                >
                  <div className={styles.label}>{view.label}</div>
                </div>
              );
            }
          } else {
            return (
              <div
                key={`IFTA_Sidebar_${view.value}`}
                className={style}
                onClick={() => {
                  history.push({ pathname: 'ifta', search: `view=${view.value}` });
                }}
              >
                <div className={styles.label}>{view.label}</div>
              </div>
            );
          }
        })}
      </div>

      <div style={{ position: 'absolute', bottom: '2em', width: '100%', textAlign: 'center', }}>
        <IFTAHelpDialog />
      </div>
    </div>
  );
}

Sidebar.propTypes = {
  title: PropTypes.string,
  company: PropTypes.object,
  activeSubview: PropTypes.string,
  handleSubViewClick: PropTypes.func,
  subviews: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }).isRequired,
};

export default Sidebar;
