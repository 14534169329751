import React from 'react';

// Styling
import './style.scss';

/**
 * @description An icon component that includes text beside it
 * @param {String} className - Custom container className
 * @param {String} iconClassName - Custom styling for the icon itself
 * @param {any} children - Preferrably text to display beside the icon
 */
function IconLabel({ ...props }) {

  // ** Misc ** //
  let className = 'sbc-icon-label';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <i className={`icon ${props.iconClassName}`} /><span>{props.children}</span>
    </div>
  );
}

export default IconLabel;
