import { addRecord, createQuery, find, setQueryRestriction, updateRecord } from 'api/Parse';
// http://app.localhost:3000/dispatch/job__new/xpulUf0atL

import AttributeTypes from 'enums/DispatchJobNotes';
import { QueryRestrictionTypes } from 'enums/Query';

/**
 * @description Get all job notes for a DispatchJob given it's objectId
 * @param {record} dispatchJob
 * @returns {record} a dispatchJobNotes record, initializing it if not in database
 */
async function getJobNotes(dispatchJob) {
  if (!dispatchJob) {
    throw new Error('No objectId provided to getJobNotes');
  }

  const { DISPATCH_JOB } = AttributeTypes;
  const dispatchJobNotesQuery = createQuery('DispatchJobNotes');
  setQueryRestriction(dispatchJobNotesQuery, QueryRestrictionTypes.EQUAL_TO, DISPATCH_JOB, dispatchJob);
  const dispatchJobNotes = await find(dispatchJobNotesQuery, true, false);
  if (dispatchJobNotes) {
    return dispatchJobNotes;
  }
  const dispatchJobNotesRecord = {};
  dispatchJobNotesRecord[DISPATCH_JOB] = dispatchJob;
  return await addRecord('DispatchJobNotes', dispatchJobNotesRecord);
}

/**
 * @description Update job notes for a DispatchJob for the given attributes
 * @param {record} dispatchJobNotes
 * @param {object} keyAttributeObj
 * @param {boolean} save
 * @returns a Promise if dispatchJobNotes is truthy, else throws an Error
 */
async function updateJobNotes(dispatchJobNotes, keyAttributeObj = {}, save) {
  if (dispatchJobNotes) {
    return await updateRecord(dispatchJobNotes, keyAttributeObj, save);
  }
  throw new Error('No Dispatch Job Note provided');
}

/**
 * @description Adds a new dispatch job notes record
 * @param {object} dispatchJobNotesObj Dispatch job notes sbObject
 * @return Dispatch job notes record
 */
async function addJobNotes(dispatchJobNotesObj) {
  const dispatchJobNotesRecord = await addRecord('DispatchJobNotes', dispatchJobNotesObj);

  return dispatchJobNotesRecord;
}

export {
  getJobNotes,
  addJobNotes,
  updateJobNotes,
};
