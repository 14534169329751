import React from 'react';

// sbcore components
import Chip from 'sbCore/Chip/Chip';

// styling
import './style.scss';

/**
 * @description Component which displays all uploaded files on the UI
 * @param {FileRemoveHandler} fileRemoveHandler - Required. Function which handles removing uploaded files by the user
 * @param {Files} files - Required. Array of uploaded files which will be displayed on the UI as chips
 * @returns
 *
 * @example
 * <UploadedFiles fileRemoveHandler={removeHandler()} files={[File1, File2, ...]} />
 */
const UploadedFiles = (props) => {
  let uploadedFilesTemplate;

  const files = props.files;

  if (files) {
    const chips = files.map(file => {
      const filename = file._name;

      const chip = (
        <div className="mr-2 mb-1 uploaded-file-item" key={filename}>
          <Chip label={filename} onRemove={() => props.fileRemoveHandler(filename)} removable />
        </div>
      );

      return chip;
    });

    uploadedFilesTemplate = (
      <div className="flex">
        {chips}
      </div>
    );
  }

  return (
    <div className="uploaded-files">
      {files && uploadedFilesTemplate}
    </div>
  );
};

export default UploadedFiles;
