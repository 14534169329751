import * as ActionConstants from 'actions/ActionConstants';

const initialState = {
  status: '',
  user: {},
  fetched: false,
};

export function User(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_USER_INPROGRESS:
      return {
        ...state,
        status: action.type,
      };
    case ActionConstants.FETCH_USER_SUCCESS:
      return {
        ...state,
        status: action.type,
        user: action.user,
        error: undefined,
        fetched: true,
      };
    case ActionConstants.FETCH_USER_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
        fetched: false,
      };
    case ActionConstants.REGISTER_USER_INPROGRESS:
      return {
        ...state,
        status: action.type,
        user: {},
        error: undefined,
      };
    case ActionConstants.REGISTER_USER_SUCCESS:
      return {
        ...state,
        status: action.type,
        user: action.user,
        error: undefined,
      };
    case ActionConstants.REGISTER_USER_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    default:
      return state;
  }
}
