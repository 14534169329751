import Parse from 'parse';

function getSimInfo(iccid) {
  const promise = new Promise((resolve, reject) => {
    Parse.Cloud.run('getSimInfoByIccid', {
      iccid,
    }).then((infoObj) => {
      resolve(infoObj || {});
    }, (error) => {
      reject(error);
    });
  });
  return promise;
}

export {
  getSimInfo,
};
