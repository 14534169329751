import React from 'react';
import PropTypes from 'prop-types';
import { MDBContainer, MDBBtn, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCard, MDBCardBody, MDBCardText, MDBRow, MDBCol } from 'mdbreact';
import moment from 'moment-timezone';

// API
import * as Helpers from 'api/Helpers';
import * as Analytics from 'api/Analytics';

// Components
import DatePicker from 'components/DatePicker/container/DatePicker';
import ListSelectField from 'components/ListSelectField/view/ListSelectField';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import TimezonePicker from 'react-timezone';

// Lists
import { canada as canadaCycles, us as usCycles, getReferenceIntFromDescription } from 'api/Lists/HoursCycle';
import { StateProvinces } from 'api/Lists/StateProvinces';

// CSS
import styles from './AddDriverModal.module.scss';
import './style.scss';
function getInitialUserInfo() {
  return { // contains things like prefix, username, etc
    exempt: undefined, // should start as undefined as per specs
    yardMoves: true,
    personalUseCMV: true,
    exemptionReason: '',
    licenseExpiryDate: undefined,
    dispatchers: [],
    dotNumber: '',
    hourCycleCanadaInt: undefined,
    hourCycleUSAInt: undefined,
    exemptionReason: '',
  };
}

class AddDriverModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userInfo: getInitialUserInfo(),
      disableAddDriver: true, // disable add driver button
      addingInProgress: false, // whether the driver is currently being added
      prefix: undefined,
      prefixInputStyle: '',
      stateProvinces: [],
      countries: [{ name: 'Canada', code: 'CA' }, { name: 'United States', code: 'US' }, { name: 'Mexico', code: 'MX' }],
      error: '',
    };
    this.keys = ['prefix', 'firstName', 'lastName', 'phoneNumber', 'timezone'];
    this.keysLen = this.keys.length;
    this.handleChange = this.handleChange.bind(this);
    // this.handleExemptionReasonChange = this.handleExemptionReasonChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.editDispatchers = this.editDispatchers.bind(this);
    this.addDriver = this.addDriver.bind(this);
    this.setELDPermission = this.setELDPermission.bind(this);

    // group stateProvinces by country
    const canadianJurs = [];
    const americanJurs = [];
    const mexicanJurs = [];
    for (let i = 0; i < StateProvinces.length; i++) {
      const stateProvince = StateProvinces[i];
      if (stateProvince.country === 'CA') {
        canadianJurs.push(stateProvince);
      } else if (stateProvince.country === 'US') {
        americanJurs.push(stateProvince);
      } else if (stateProvince.country === 'MX') {
        mexicanJurs.push(stateProvince);
      }
    }
    this.state.stateProvinces = [].concat(canadianJurs, americanJurs, mexicanJurs);
  }

  componentWillReceiveProps(nextProps) {
    // guaranteed to update since company fetching is async and props will always update (show or not show modal)
    const prefix = nextProps.company.toJSON().prefix;
    const newState = { ...this.state };
    if (prefix) {
      newState.prefix = prefix;
      newState.prefixInputStyle = styles.prefixInputStyle;
      this.keys = [
        'firstName',
        'lastName',
        'phoneNumber',
        'driversLicense',
        'driversLicenseJurisdiction',
        'driversLicenseCountry',
        'licenseExpiryDate',
        'timezone',
      ];
      this.keysLen = this.keys.length;
    }

    if ((nextProps.show !== this.props.show) && nextProps.dispatchers[0]) {
      newState.userInfo.timezone = nextProps.dispatchers[0].get('timezoneOffsetFromUTC');
    }

    if (nextProps.company && nextProps.company.id) {
      newState.userInfo.dotNumber = nextProps.company.get('dotNumber');
    }

    if (!nextProps.show) {
      newState.addingInProgress = false;
    }

    if (nextProps.dispatchers[0] && nextProps.dispatchers[0].id) {
      // when we know we have a dispatcher given in advance
      newState.userInfo.timezone = nextProps.dispatchers[0].get('timezoneOffsetFromUTC') || 'America/Vancouver';
    }

    if (nextProps.company && nextProps.company.get('hourCycleCanadaInt')) {
      newState.userInfo.hourCycleCanadaInt = nextProps.company.get('hourCycleCanadaInt');
    }

    if (nextProps.company && nextProps.company.get('hourCycleUSAInt')) {
      newState.userInfo.hourCycleUSAInt = nextProps.company.get('hourCycleUSAInt');
    }

    this.setState(newState);
  }

  async handleChange(e, customId, _value) {
    let value;
    let targetId;
    if (_value !== undefined) {
      value = _value;
    } else {
      value = e.target.value;
      targetId = e.target.id;
    }

    await this.setState({ ...this.state, error: '' });
    const newState = { ...this.state };
    const { userInfo } = newState;
    if (customId && _value !== undefined) {
      userInfo[customId] = value;
    } else if (targetId) {
      userInfo[targetId] = value;
    }

    newState.disableAddDriver = false;

    if (customId === 'driversLicenseCountry') {
      userInfo.driversLicenseCountry = target.textContent;
    }

    if (customId === 'driversLicenseJurisdiction') {
      userInfo.driversLicenseJurisdiction = target.textContent;
    }

    if (customId === 'timezone') {
      userInfo.timezone = e;
    }


    if (userInfo.exempt && (!userInfo.exemptionReason || Helpers.isStringEmpty(userInfo.exemptionReason))) {
      newState.disableAddDriver = true;
    }

    if ((!userInfo.dispatchers || userInfo.dispatchers.length === 0) && !userInfo.dispatcherId) {
      newState.disableAddDriver = true;
    }

    // validation
    for (let i = 0; i < this.keysLen; i++) {
      const currentKey = this.keys[i];
      if (Helpers.isStringEmpty(userInfo[currentKey]) && (currentKey !== 'timezone') && currentKey !== 'licenseExpiryDate') {
        newState.disableAddDriver = true;
        break;
      }
      // if the user is subscribed to eldmodule and the timezone field is empty, disable add driver
      if (currentKey === 'timezone' && (Helpers.isStringEmpty(userInfo[currentKey]) && Helpers.isStringEmpty(userInfo.timezone)) && Helpers.isSubscribedToModule('eldModule')) {
        newState.disableAddDriver = true;
        break;
      }
      if (currentKey === 'prefix' && userInfo.prefix && userInfo.prefix.length > 5) {
        newState.disableAddDriver = true;
        break;
      }
      if (currentKey === 'driversLicense' && !userInfo[currentKey]) {
        newState.disableAddDriver = true;
        break;
      } else if (currentKey === 'driversLicenseJurisdiction' && !userInfo[currentKey]) {
        newState.disableAddDriver = true;
        break;
      } else if (currentKey === 'driversLicenseCountry' && !userInfo[currentKey]) {
        newState.disableAddDriver = true;
        break;
      } else if (currentKey === 'timezone' && !userInfo[currentKey]) {
        newState.disableAddDriver = true;
        break;
      } else if (currentKey === 'password' && !userInfo[currentKey]) {
        newState.error = 'Password should be at least 4 characters';
        newState.disableAddDriver = true;
        break;
      }
    }

    this.setState(newState);
  }

  setELDPermission(permissionAttr) {
    const newState = { ...this.state };
    if (!newState.userInfo[permissionAttr]) {
      newState.userInfo[permissionAttr] = true;
      // disable adding until an exemption reason is provided (in handlechange)
      if (permissionAttr === 'exempt') newState.disableAddDriver = true;
    } else {
      newState.userInfo[permissionAttr] = undefined; // set status to undefined instead of false (attr should not be set)
      if (permissionAttr === 'exempt') {
        newState.disableAddDriver = false;
        newState.exemptionReason = '';
      }
    }
    this.setState(newState);
  }

  editDispatchers(index, value) {
    const driverDispatchers = [].concat(this.state.userInfo.dispatchers);
    const selectedDispatcher = this.props.dispatchers.filter((dispatcher, i) => i === index)[0];
    if (selectedDispatcher) {
      driverDispatchers[0] = selectedDispatcher;
    }
    this.setState({ ...this.state, selectedDispatcher: value, userInfo: { ...this.state.userInfo, dispatchers: driverDispatchers } }, () => {
      this.handleChange({}, 'dispatchers');
    });
  }


  addDriver() {
    let isValidated = true;
    const userInfoObject = Object.assign({}, this.state.userInfo);
    const keys = Object.keys(userInfoObject);

    // validation again for bug when user closes modal with info, without adding driver
    if ((!userInfoObject.dispatchers || userInfoObject.dispatchers.length === 0) && !userInfoObject.dispatcherId) {
      isValidated = false;
    }
    if (!userInfoObject.timezone || Helpers.isStringEmpty(userInfoObject.timezone)) {
      isValidated = false;
    }

    if (!isValidated) {
      return;
    }

    for (let i = 0; i < keys.length; i++) {
      const currentKey = keys[i];
      if (userInfoObject[currentKey] && (currentKey !== 'dispatchers' && currentKey !== 'hourCycleCanadaInt' && currentKey !== 'hourCycleUSAInt' && currentKey !== 'licenseExpiryDate' && currentKey !== 'exempt' && currentKey !== 'yardMoves' && currentKey !== 'personalUseCMV' && currentKey !== 'checkPrefix')) {
        userInfoObject[currentKey] = userInfoObject[currentKey].trim();
      }
      if (currentKey === 'licenseExpiryDate' && userInfoObject[currentKey]) {
        userInfoObject[currentKey] = userInfoObject[currentKey];
      }

      if (currentKey === 'exemptionReason' && (!userInfoObject[currentKey] || userInfoObject[currentKey].length === 0)) {
        userInfoObject[currentKey] = undefined;
      }
      if (currentKey === 'driversLicenseCountry' && userInfoObject[currentKey]) {
        userInfoObject[currentKey] = this.state.countries.filter(country => country.name === userInfoObject[currentKey])[0].code;
      }
      if (currentKey === 'driversLicenseJurisdiction' && userInfoObject[currentKey]) {
        userInfoObject[currentKey] = this.state.stateProvinces.filter(state => (`${state.code} - ${state.name}`) === userInfoObject[currentKey])[0].code;
      }
    }

    if (this.state.prefix) {
      // if this company already has a prefix
      userInfoObject.prefix = this.state.prefix;
    } else {
      // otherwise, this is the first time adding a prefix; let the server know
      userInfoObject.checkPrefix = true;
    }

    this.setState({ ...this.state, disableAddDriver: true, addingInProgress: true, userInfo: {}, error: undefined }, () => {
      Analytics.identifyOnceForCompany('DRIVERCREA', {
        'Driver Created': true,
        'DriverCreateDate': moment().toISOString(),
      });
      this.props.addDriverFormHandler(userInfoObject).then(
        () => {
          const newState = Object.assign({}, this.state);
          newState.userInfo = getInitialUserInfo();
          newState.disableAddDriver = false;
          newState.addingInProgress = false;
          this.setState(newState);
        },
        (error) => {
          const newState = { ...this.state, userInfo: userInfoObject };
          newState.error = error;
          newState.disableAddDriver = false;
          newState.addingInProgress = false;
          this.setState(newState);
        }
      );
    });
  }

  handleClose() {
    const newState = { ...this.state, disableAddDriver: true, addingInProgress: false, userInfo: {} };
    this.setState(newState);
    this.props.handleClose();
  }

  render() {
    const { dispatchers } = this.props;
    const { userInfo } = this.state;

    let showELDOptions = false;
    if (this.props.subscription && Helpers.isSubscribedToModule('eldModule')) {
      showELDOptions = true;
    }

    const countriesDropdownItems = this.state.countries.map(country => country.name);

    const stateDropdownItems = this.state.stateProvinces.map(stateProvince => `${stateProvince.code} - ${stateProvince.name}`);

    const dispatcherDropdownItems = dispatchers.filter(dispatcher => dispatcher.get('user')).map(dispatcher => {
      const dispatcherUser = dispatcher.get('user');
      return `${dispatcherUser.get('firstName')} ${dispatcherUser.get('lastName')}`;
    });

    return (
      <MDBModal className={'adjust-to-old-material ' + styles.addDriverModal} isOpen={this.props.show} toggle={this.props.handleClose} centered backdrop={false}>
        <MDBModalHeader toggle={this.props.handleClose}>Add Driver to Switchboard</MDBModalHeader>
        <MDBModalBody>
          <MDBCard className="z-depth-0 text-left">
            <MDBCardBody className="p-0">
              <MDBCardText className="mb-0">Personal Details</MDBCardText>
              <MDBRow>
                <MDBCol>
                  <div className="form-group required">
                    <MDBInput label="First Name" id="firstName" value={userInfo.firstName || ''} onChange={this.handleChange} autoComplete="off" />
                  </div>
                </MDBCol>
                <MDBCol>
                  <div className="form-group required">
                    <MDBInput label="Last Name" id="lastName" value={userInfo.lastName || ''} onChange={this.handleChange} autoComplete="off" />
                  </div>
                </MDBCol>
                <MDBCol>
                  <div className="form-group required">
                    <MDBInput label="Phone Number" id="phoneNumber" value={userInfo.phoneNumber || ''} onChange={this.handleChange} autoComplete="off" />
                  </div>
                </MDBCol>
                <MDBCol>
                  <div className="form-group">
                    <MDBInput label="Email Address" id="email" value={userInfo.email || ''} onChange={this.handleChange} autoComplete="off" />
                  </div>
                </MDBCol>
                <MDBCol size="10" className="offset-1 timezone-picker">
                  <div className="form-group required">
                    <label className="sb-label">Time Zone</label>
                    <TimezonePicker
                      className={styles.timezonePicker}
                      value={this.state.userInfo.timezone}
                      onChange={timezone => this.handleChange(timezone, 'timezone')}
                      inputProps={{
                        placeholder: 'Select a Time Zone',
                        name: 'timezone',
                      }}
                    />
                    <small>Driver Logs are set/retrieved using this Time Zone</small>
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>

          <MDBCard className="z-depth-0 text-left">
            <MDBCardBody className="p-0">
              <MDBCardText className="mb-0">License Details</MDBCardText>
              <MDBRow>
                <MDBCol size="3">
                  <div className="form-group required">
                    <MDBInput label="License #" id="driversLicense" value={userInfo.driversLicense || ''} onChange={this.handleChange} autoComplete="off" />
                  </div>
                </MDBCol>
                <MDBCol size="3">
                  <div className="form-group required">
                    <ListSelectField
                      className="w-100"
                      value={this.state.userInfo.driversLicenseCountry}
                      floatingLabelText="Country"
                      onChange={(e, index, newValue) => this.handleChange(null, 'driversLicenseCountry', e.textContent)}
                      list={countriesDropdownItems}
                      underlineFocusStyle={{ borderColor: '#4285f4' }}
                    />
                  </div>
                </MDBCol>
                <MDBCol size="3">
                  <div className="form-group required">
                    <ListSelectField
                      className="w-100"
                      value={this.state.userInfo.driversLicenseJurisdiction}
                      floatingLabelText="State/Province"
                      onChange={(e, index, newValue) => this.handleChange(null, 'driversLicenseJurisdiction', e.textContent)}
                      list={stateDropdownItems}
                      underlineFocusStyle={{ borderColor: '#4285f4' }}
                    />
                  </div>
                </MDBCol>
                <MDBCol size="3">
                  <div className="form-group pt-2">
                    <label className="sb-label mb-0">Expiry Date</label>
                    <DatePicker
                      className="sb-input" showMonthDropdown showYearDropdown id="licenseExpiryDate" dateFormat="MMM dd, yyyy"
                      selected={userInfo.licenseExpiryDate || null} onChange={(date) => this.handleChange(null, 'licenseExpiryDate', date)}
                    />
                    {/* <MDBInput className="h-auto" id="licenseExpiryDate" type="date" onChange={this.handleChange} label="Expiry Date" checked /> */}
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>

          <MDBCard className="z-depth-0 text-left">
            <MDBCardBody className="p-0">
              <MDBCardText className="mb-0">Company Details</MDBCardText>
              <MDBRow>
                {/* <MDBCol size="3" className="offset-2">
                  <div className="form-group">
                    <MDBInput label="DOT Number" id="dotNumber" value={userInfo.dotNumber || ''} onChange={this.handleChange} autoComplete="off" />
                  </div>
                </MDBCol> */}
                <MDBCol size="3" className="offset-2">
                  <div className="form-group required">
                    <ListSelectField
                      className="w-100 makeBlue"
                      value={this.state.selectedDispatcher}
                      floatingLabelText="Dispatcher"
                      onChange={(e, index, newValue) => this.editDispatchers(index, newValue)}
                      list={dispatcherDropdownItems}
                      underlineFocusStyle={{ borderColor: '#4285f4' }}
                    />
                  </div>
                </MDBCol>
              </MDBRow>
              {!this.state.prefix &&
                <MDBContainer>
                  <MDBRow>
                    <MDBCol size="4" className="offset-4">
                      <div className="form-group required">
                        <MDBInput label="Abbreviation" id="prefix" value={userInfo.prefix || ''} onChange={this.handleChange} autoComplete="off" />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol size="10" className="offset-1">
                      <small><li>Choose a company abbreviation that will be the start of every drivers' username</li></small>
                      <small><li>Abbreviations only need to be added once and are limited to 5 characters</li></small>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              }
            </MDBCardBody>
          </MDBCard>

          <MDBCard className="z-depth-0 text-left">
            <MDBCardBody className="p-0">
              <MDBCardText className="mb-0">HOS & ELD Details</MDBCardText>
              <MDBRow>
                <MDBCol size="4" className="offset-1">
                  <div className="form-group required">
                    <ListSelectField
                      className="w-100"
                      floatingLabelText="Canadian HOS Cycle"
                      value={this.state.userInfo.hourCycleCanadaInt && canadaCycles[this.state.userInfo.hourCycleCanadaInt]}
                      onChange={(e, index, newValue) => this.handleChange(null, 'hourCycleCanadaInt', index + 1)}
                      list={Object.values(canadaCycles)}
                      underlineFocusStyle={{ borderColor: '#4285f4' }}
                    />
                  </div>
                </MDBCol>
                <MDBCol size="4" className="offset-2">
                  <div className="form-group">
                    <ListSelectField
                      className="w-100"
                      floatingLabelText="US HOS Cycle"
                      value={this.state.userInfo.hourCycleUSAInt && usCycles[this.state.userInfo.hourCycleUSAInt]}
                      onChange={(e, index, newValue) => this.handleChange(null, 'hourCycleUSAInt', getReferenceIntFromDescription(1, newValue))}
                      list={Object.values(usCycles)}
                      underlineFocusStyle={{ borderColor: '#4285f4' }}
                    />
                  </div>
                </MDBCol>
              </MDBRow>

              {showELDOptions &&
                <span>
                  <MDBRow>
                    <MDBCol size="3" className="offset-1">
                      <div className="form-group">
                        <MDBInput className="h-auto w-auto" id="exempt" type="checkbox" onChange={() => this.handleChangeDriverInfo(null, 'exempt', !userInfo.exempt)} filled label="Exempt Driver" checked={!!this.state.userInfo.exempt} />
                      </div>
                    </MDBCol>
                    <MDBCol size="3">
                      <div className="form-group">
                        <MDBInput className="h-auto w-auto" id="yardMoves" type="checkbox" onChange={() => this.handleChangeDriverInfo(null, 'yardMoves', !userInfo.yardMoves)} filled label="Yard Moves" checked={!!this.state.userInfo.yardMoves} />
                      </div>
                    </MDBCol>
                    <MDBCol size="3">
                      <div className="form-group">
                        <MDBInput className="h-auto w-auto" id="personalUseCMV" type="checkbox" onChange={() => this.handleChangeDriverInfo(null, 'personalUseCMV', !userInfo.personalUseCMV)} filled label="Personal Use CMV" checked={!!this.state.userInfo.personalUseCMV} />
                      </div>
                    </MDBCol>
                    <MDBCol size="3">
                      <div className="form-group">
                        <MDBInput className="h-auto w-auto" id="disabledELDEdit" type="checkbox" onChange={() => this.handleChangeDriverInfo(null, 'disabledELDEdit', !userInfo.disabledELDEdit)} filled label="Disable Driver Edits" checked={!!this.state.userInfo.disabledELDEdit} />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  {this.state.userInfo.exempt &&
                    <MDBRow>
                      <MDBCol size="8" className="offset-2">
                        <div className="form-group required">
                          <MDBInput
                            type="textarea" label="Exemption Reason" rows="1" className="mr-0"
                            value={this.state.userInfo.exemptionReason} onChange={this.handleChange}
                          />
                          <small className={styles.smallNote}>Mandatory Field for Exempt Drivers</small>
                        </div>
                      </MDBCol>
                    </MDBRow>}
                </span>
              }
            </MDBCardBody>
          </MDBCard>
          {this.state.error &&
            <div className={styles.error}>{this.state.error.message}</div>
          }

        </MDBModalBody>
        <MDBModalFooter>
          {!this.state.addingInProgress && !this.props.fetchingData ?
            <div>
              <span className="errorText">
                {this.state.error}
              </span>
              <span>
                <MDBBtn className={'closeButton'} onClick={this.handleClose}>Cancel</MDBBtn>
                <MDBBtn className={'buttonDefault'} onClick={this.addDriver} disabled={this.state.disableAddDriver}>Add Driver</MDBBtn>
              </span>
            </div>
            :
            <LoadingIcon />
          }
        </MDBModalFooter>
      </MDBModal>
    );
  }
}

AddDriverModal.propTypes = {
  addDriverFormHandler: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  dispatchers: PropTypes.array.isRequired,
  subscription: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  error: PropTypes.object,
  fechingData: PropTypes.bool,
};

export default AddDriverModal;
