// libraries
import { useState, useEffect } from 'react';
import uniqid from 'uniqid';

// components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import DriverAutocompleteInput from 'sbCore/DriverAutocomplete/DriverAutocompleteInput';
import DriverCard from 'sbCore/DriverAutocomplete/DriverCard';

// styles
import './style.scss';

/**
* @description Contains an input to filter drivers, a button to add a new driver, and the form to add a new driver
* @param {String} className - Custom container className
* @param {String} [panelClassName] - Custom dropdown panel className
* @param {Object} style - Custom inline styles
* @param {Driver} driver - If provided, sets it as the state's driver to show the driver info
* @param {function} onSelectDriver - If provided, callback that returns the selected driver
* @param {function} onToggleEditDriver - If provided, callback that lets parent know the add/edit driver form was toggled and the bool value
* @param {bool} allowAddDriver - Shows the text button under the input to allow the adding of an driver
* @param {bool} warning - Makes the input border yellow
* @param {bool} autoFocus - Whether to automatically focus on the input on load
* @param {bool} disabled - Whether disable the input
* @param {bool} isLoading - Whether to show a loading indicator
* @param {bool} hideLabel - Whether to hide the label of the autocomplete
* @returns
*
* @example
* <DriverAutocomplete warning />
*/
function DriverAutocomplete({ ...props }) {
  // ** useStates ** //
  const [identifier] = useState(uniqid()); // for each of this component that exists on the same page, give it unique identifier for specific dom manipulation
  const [driver, setDriver] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false); // local version is isLoading (in addition to props.isLoading)
  const [showEditForm, setShowEditForm] = useState(false); // whether or not to show the driver add / edit form

  // ** useEffects ** //
  useEffect(() => {
    setDriver(props.driver);
  }, [props.driver]);

  // ** Callbacks ** //
  // if the user selected an driver from the input
  function onSelectDriver(driver) {
    if (driver) {
      setDriver(driver);
      setShowEditForm(false); // if the add/edit form was open, close it
      if (props.onSelectDriver) props.onSelectDriver(driver);
    }
  }

  function onUnselectDriver() {
    setDriver(undefined);

    // pass in undefined instead of driver (which is being set to undefined above) since the state might not be updated yet. we do this over using a useEffect for convenience
    if (props.onSelectDriver) props.onSelectDriver(undefined);
  }

  function onToggleEditDriver() {
    // toggle the add/edit driver form. also invoke the prop callback if provided
    setShowEditForm(!showEditForm);

    if (props.onToggleEditDriver) {
      props.onToggleEditDriver(showEditForm);
    }
  }


  // ** Misc ** //
  let className = `driver-autocomplete ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  let label = 'Driver';

  // disable if parent says so. if opening the Add Driver form, force disable as well
  let disabled = props.disabled;
  if (!disabled && showEditForm) {
    disabled = true; // if showing edit form, disable the input
  }

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && <InputLabel>{ label }</InputLabel>}

      { (!props.isLoading && !driver) && (
        <DriverAutocompleteInput
          panelClassName={props.panelClassName}
          autoFocus={props.autoFocus}
          warning={props.warning}
          disabled={disabled}
          onSelectDriver={(driver) => onSelectDriver(driver)}
        />
      )}

      {/* { (!props.isLoading && !driver && props.allowAddDriver) && (
        <span className="label-button" onClick={() => onToggleEditDriver()}>+ Create new { label.toLowerCase() }</span>
      )} */}

      { (!props.isLoading && driver) && (
        <DriverCard
          driver={driver}
          onUnselectDriver={() => onUnselectDriver()}
          onToggleEditDriver={() => onToggleEditDriver()}
        />
      )}

      { props.isLoading && (
        <DriverCard isLoading={props.isLoading || isLoading} />
      )}
    </div>
  );
}

export default DriverAutocomplete;
