import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';

import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import CircularProgress from 'material-ui/CircularProgress';

import HOSGraph from 'components/DriverDetails/container/HOSGraph';

import styles from './HOSPreviewDialogView.module.scss';

const HOSPreviewDialogView = (props) => {

  const actions = [
    <FlatButton
      key="close"
      label={t('Close')}
      default={true}
      onClick={props.handleClose}
    />,
  ];

  const titleStyle = {
    fontSize: '.9em',
  };

  return (
    <Dialog
      className={styles.hosPreviewDialogView}
      open={props.show}
      onRequestClose={props.handleClose}
      title={props.title}
      actions={actions}
    >
      { !props.isLoading && props.eldDailyCertification ?
        <div>
          <span style={titleStyle}>{t('Before Edit')}</span>
          <HOSGraph
            eldDailyCertification={props.eldDailyCertification}
            eldEvents={props.compareAgainstELDEvents}
            associatedELDEvents={props.associatedELDEvents}
            driver={props.eldDailyCertification.get('driver')}
            scaleToDriverTimezone
            shortDutyStatus
            style={{ marginBottom: '2em' }}
          />

          <span style={titleStyle}>{t('After Edit')}</span>
          <HOSGraph
            eldDailyCertification={props.eldDailyCertification}
            eldEvents={props.eldEvents}
            associatedELDEvents={props.associatedELDEvents}
            driver={props.eldDailyCertification.get('driver')}
            scaleToDriverTimezone
            shortDutyStatus
          />
        </div>
        :
        <CircularProgress />
      }
    </Dialog>
  );
};

HOSPreviewDialogView.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  eldDailyCertification: PropTypes.object,
  compareAgainstELDEvents: PropTypes.array,
  eldEvents: PropTypes.array,
  associatedELDEvents: PropTypes.array,
}

export default HOSPreviewDialogView;
