import React from 'react';
import PropTypes from 'prop-types';

import * as Getters from 'api/Getters';

// CSS
import styles from './Sidebar.module.scss';

function Sidebar(props) {
  const company = Getters.getCurrentUserCompany()
  let summaryStyle = styles.option;
  let geofenceEventsStyle = styles.option;
  if (props.activeSubview === 'summary' || !props.activeSubview) {
    summaryStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'geofenceEvents') {
    geofenceEventsStyle = `${styles.active} ${styles.option}`;
  }
  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarTitle}>
        <div className={styles.title}>
          <span>{props.title}</span>
        </div>
        <div className={styles.subHeader}>Geofence Details</div>
      </div>

      { company && company.get('enableCompanyLinks') &&
        <div className={styles.company}>
          <hr />
          {props.geofence && props.geofence.get('belongsToCompany').get('name')}
          <hr />
        </div>
      }

      <div className={styles.subViews}>
        <div className={summaryStyle} onClick={() => props.handleSubViewClick('summary')}>
          <div className={styles.label}>Summary</div>
        </div>
        {
          // <div className={geofenceEventsStyle} onClick={() => props.handleSubViewClick('geofenceEvents')}>
          //   <div className={styles.label}>Geofence Events</div>
          // </div>
        }

      </div>
    </div>
  );
}

Sidebar.propTypes = {
  title: PropTypes.string,
  geofence: PropTypes.object,
  vehicleFetched: PropTypes.bool,
  activeSubview: PropTypes.string,
  handleSubViewClick: PropTypes.func,
  eldModuleSubscribed: PropTypes.bool,
};

export default Sidebar;
