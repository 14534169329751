/* eslint-disable react/jsx-no-bind */
import uniqid from 'uniqid';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

// API
import { getAttribute, createQuery, setQueryRestriction, findRecords, sortQuery, getCurrentUserSessionToken } from 'sb-csapi/dist/AAPI';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

// Components
import DutyStatusBadge from 'sbCore/DutyStatusBadge/DutyStatusBadge';
import { FontIcon } from 'material-ui';
import Card from 'sbCore/Card/Card';
import Column from 'sbCore/Column/Column';
import DataTable from 'sbCore/DataTable/DataTable';
import Chip from 'sbCore/Chip/Chip';

import styles from './TroubleshootingCards.module.scss';

function StuckTabletDataCard() {
  const [isLoading, setIsLoading] = useState(true);
  const [informationArr, setInformationArr] = useState([]);
  const [eldVersionToCompare, setEldVersionToCompare] = useState('');

  const [refreshBool, setRefreshBool] = useState(false);

  useEffect(() => {
    async function getArrForState() {
      setIsLoading(true);
      const driversArr = await Getters.getDrivers({ enabled: true });

      const _driverInformationArr = driversArr
        ?.map((driverObj) => {
          const latestELDEvent = getAttribute(driverObj, 'latestELDEvent', true);
          const vehicleLocation = latestELDEvent && getAttribute(latestELDEvent, 'vehicleLocation', true);
          const lastKnownLocation = vehicleLocation?.get('aprxShortName') && `${vehicleLocation?.get('aprxShortName')}, ${vehicleLocation?.get('stateProvince')?.toUpperCase()}`;
          const locationDateTime = vehicleLocation?.get('dateTime') || latestELDEvent?.get('eventDateTime');
          const eldStatusInt = getAttribute(driverObj, 'eldStatusInt', true);
          const isDriving = [3].indexOf(eldStatusInt) !== -1;

          return {
            name: getAttribute(driverObj, 'user_fullName', true),
            phoneNumber: getAttribute(driverObj, 'user_phoneNumber', true),
            eldStatusInt,
            lastKnownLocation,
            locationDateTime, // not using location because some don't have vehicleLocations
            isDriving,
            driverId: getAttribute(driverObj, 'objectId', true),
            currentELDVersion: getAttribute(driverObj, 'currentELDVersion', true),
            deviceIMEI: getAttribute(driverObj, 'deviceIMEI', true),
          };
        });

      const filteredDriverInformationArr = _driverInformationArr.filter((driverInformationObj) => {
        return (
          driverInformationObj.locationDateTime
          && moment(driverInformationObj.locationDateTime).isAfter(moment().subtract(1, 'days'))
          && moment(driverInformationObj.locationDateTime).isBefore(moment().subtract(1, 'hours'))
          && driverInformationObj.isDriving
        )
      });

      setInformationArr(filteredDriverInformationArr);
      setEldVersionToCompare(eldVersionToCompare)
      setIsLoading(false);
    }

    getArrForState();

    return () => {
      setRefreshBool(true);
    };
  }, [refreshBool]);

  function driverBodyTemplate(rowData) {
    return (
      <div>
        <a
          href={`/driver?driver=${rowData.driverId}&view=hosEvents`}
          target="_blank"
        >
          <b>{Helpers.toTitleCase(rowData.name)}</b>
        </a>
        <br />
        <div className={styles.subtitle}>
          <FontIcon className={`${styles.subtitle} material-icons`}>phone</FontIcon>
          {rowData.phoneNumber}
        </div>
      </div>
    );
  }

  function driverInfoTemplate(rowData) {
    return (
      <div className="inline">
        {/* {rowData.lastKnownLocation} */}
        <div className="inlineBlock">
          <DutyStatusBadge className="z-depth-0" eldStatusInt={rowData.eldStatusInt} />
        </div>
        {/* <div className="inlineBlock">
          &nbsp;<b>Latest Ping @ {moment(rowData.locationDateTime).format('h:mm a')}</b>
        </div> */}
      </div>
    );
  }

  const softwareVersionChipTemplate = (currentELDVersion) => (
    <div className="text-xs flex">
      {currentELDVersion}
    </div>
  );

  const infoTemplate = (rowData) => (
    <div>
      <table>
        <tr>
          <td><b>Latest ELD Event:</b> &nbsp; &nbsp;</td>
          <td>{moment(rowData.locationDateTime).format('lll')}</td>
        </tr>
        <tr>
          <td><b>IMEI:</b> &nbsp; &nbsp;</td>
          <td>{rowData.deviceIMEI}</td>
        </tr>
        <tr>
          <td><b>ELD Version:</b> &nbsp; &nbsp;</td>
          <td>{rowData.currentELDVersion}</td>
        </tr>
      </table>
    </div>
  );


  const header = (
    <div className="flex flex-row justify-content-between align-items-end px-3 pt-4">
      <h6 className="font-bold mb-0">Drivers Delayed Upload</h6>
    </div>
  );


  return (
    <Card
      header={header}
    >
      <DataTable
        value={informationArr}
        emptyMessage="No drivers with delayed upload. Awesome!"
        loading={isLoading}
        rowHover
        paginator
        rows={5}
        size="small"
      >
        <Column
          field={driverBodyTemplate}
          header="Driver"
        />
        <Column
          field={driverInfoTemplate}
          header=""
        />
        <Column
          field={infoTemplate}
          header=""
        />
      </DataTable>
    </Card>
  );
}

export default StuckTabletDataCard;
