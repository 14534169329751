import React, { useState, useEffect } from 'react';

// api
import { getAttribute } from 'sb-csapi/dist/AAPI';

// components
import InputSwitch from 'sbCore/InputSwitch/InputSwitch';

// style
import './style.scss';

/**
 * @description A toggle that lets the parent know what type of edit override is being enabled
 * @param {ELDDailyCertification} eldDailyCertification - The daily cert of the logs
 * @param {Function} onChange - Callback to tell the parent what type of override is enabled, if any
 * @param {Function} isChecked - If it's automatically turned on by the parent (that sounds pretty awkward sorry)
 *
 * @returns {String}
 *   Currently supported:
 *    - 'generate': Generate logs from scratch
 *    - 'override': Allow any edits on existing logs
 */
function ELDOverrideInputSwitch({ ...props }) {
  const ELDOverrideType = Object.freeze({
    OFF: '',
    GENERATE: 'generate',
    OVERRIDE: 'override',
  });

  const [isChecked, setIsChecked] = useState(props.isChecked || false);
  const [inputLabel, setInputLabel] = useState('');
  const [eldOverrideType, setELDOverrideType] = useState(ELDOverrideType.OFF);

  useEffect(() => {
    const { eldDailyCertification } = props;
    let _inputLabel = '';

    // If the daily cert has no objectId, it means we want to generate logs from scratch
    const eldDailyCertificationObjectId = getAttribute(eldDailyCertification, 'objectId', true);
    if (!eldDailyCertificationObjectId) {
      _inputLabel = 'Generate Logs';
    } else {
      _inputLabel = 'Override Logs';
    }

    setInputLabel(_inputLabel);
  }, [props.eldDailyCertification]);

  useEffect(() => {
    const { eldDailyCertification } = props;
    let _eldOverrideType = ELDOverrideType.OFF;

    // if is not checked, that means overrides are off
    if (!isChecked) {
      setELDOverrideType(_eldOverrideType);
      return props.onChange(_eldOverrideType);
    }

    // If the daily cert has no objectId, it means we want to generate logs from scratch
    const eldDailyCertificationObjectId = getAttribute(eldDailyCertification, 'objectId', true);
    if (!eldDailyCertificationObjectId) {
      _eldOverrideType = ELDOverrideType.GENERATE;
    } else {
      // otherwise it is an edit override
      _eldOverrideType = ELDOverrideType.OVERRIDE;
    }

    setELDOverrideType(_eldOverrideType);
    return props.onChange(_eldOverrideType);
  }, [isChecked]);

  const className = 'eld-override-input-switch';

  return (
    <div className={className}>
      <div className="inputswitch-label inline-block text-right uppercase">
        { inputLabel }
      </div>
      <InputSwitch
        checked={isChecked}
        onChange={(e) => setIsChecked(e.value)}
      />
    </div>
  );
}

export default ELDOverrideInputSwitch;
