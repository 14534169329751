import { createContext } from 'react';

/**
 * @description Default export that creates a context for the provider
 */
const DispatchDocumentsLayoutContext = createContext(null);
export default DispatchDocumentsLayoutContext;

/** 
 * @description Wrapper for the parent component to pass values to its children
 * @returns
 * 
 * @example
 * <DispatchDocumentProvider value={...}>...>/DispatchDocumentProvider>
 */
export const DispatchDocumentsLayoutProvider = DispatchDocumentsLayoutContext.Provider;