// Takes in array of data from fuel card file and formats them according to type
import moment from 'moment-timezone';
import { StateProvinces } from 'api/Lists/StateProvinces';

/** @module IFTA */

/*
  Sometimes fields can contain strings with commas in them which breaks the parsing indicies (split by ',' creates one extra index)
  ..so we look for them and convert them here to output the expected format
  The reason why this isnt in validations is because its not necessarily invalid, just inconsistent. and this inconsistency could
  appear many times -> forcing the user to change all these fields in validations could be annoying when we can more easily parse the fields for them

  The easiest approach to doing this instead of shifting indices and tracking would be to replace invalid fields with a special string, then filter it out
*/

/**
 * @memberof module:IFTA
 *
 * @param {*} fuelCardDataArray
 *
 * @returns
 */
export const formatFromBVD = (fuelCardDataArray) => {
  const transactions = [];

  for (let i = 1, arrayLen = fuelCardDataArray.length; i < arrayLen; i++) {
    const stringData = fuelCardDataArray[i];
    const splitData = stringData.split(',');

    // sometimes it can return an array with one empty element (indicating the end of file)
    if (splitData.length <= 1) continue;

    const siteNumber = splitData[5] ? splitData[5].replace(/"/g, '') : ''; // remove quoting any quotes
    const siteName = splitData[6] ? splitData[6].replace(/"/g, '') : '';
    const stateProvinceFullName = splitData[8] ? splitData[8].replace(/"/g, '') : '';

    // the stateprovince given in this fuel card is the full state/province name
    let stateProvinceAbbrv;
    const stateProvinceData = StateProvinces.filter(stateProvinceObject => {
      if (stateProvinceObject.name.toLowerCase() === stateProvinceFullName.toLowerCase()) {
        return true;
      }
      return false;
    })[0];

    if (stateProvinceData) stateProvinceAbbrv = stateProvinceData.code;

    const transaction = {
      cardNo: splitData[0],
      transactionDateTime: moment.utc(`${splitData[3]} ${splitData[4]}`).toDate(),
      unitId: splitData[1],
      odometer: undefined,
      locationName: siteNumber ? `${siteNumber} ${siteName}` : `${siteName}`,
      city: splitData[7].replace(/"/g, ''),
      stateProvinceAbbrv,
      items: { tractorFuel: splitData[9], reeferFuel: splitData[10] },
      unitPrice: parseFloat(splitData[13]),
      fuelQuantity: parseFloat(splitData[11]),
      total: parseFloat(splitData[23]), // the total is the unitPrice * fuelQuantity + tax
      currency: splitData[24],
      fuelMeasurementUnit: splitData[12].toLowerCase(),
      transactionRow: i + 1,
    };

    transactions.push(transaction);
  }

  return transactions;
}

/**
 * @memberof module:IFTA
 *
 * @param {*} fuelCardDataArray
 *
 * @returns
 */
export const formatFromESSO = (fuelCardDataArray) => {
  const transactions = [];

  for (let i = 1, arrayLen = fuelCardDataArray.length; i < arrayLen; i++) {
    const stringData = fuelCardDataArray[i];
    const splitData = stringData.split(',');

    // sometimes it can return an array with one empty element (indicating the end of file)
    if (splitData.length <= 1) continue;

    // currencyUnit represents CAD/Litres or USD/Gallons
    const currencyUnit = (splitData[15] && splitData[15].split('/')) || [];
    const currency = currencyUnit[0] && currencyUnit[0].toLowerCase();
    const fuelMeasurementUnit = currencyUnit[1] && (currencyUnit[1].trim().toLowerCase() === 'liters') ? 'l' : 'g';

    const transaction = {
      cardNo: splitData[0],
      // this fuel card has no time property hence we take at face-value rather than UTC
      transactionDateTime: new Date(`${splitData[1]} 00:00:00`),
      unitId: splitData[3],
      odometer: splitData[5] ? parseFloat(splitData[5]) : undefined,
      locationName: splitData[6],
      city: splitData[7],
      stateProvinceAbbrv: splitData[8],
      item: splitData[10],
      unitPrice: parseFloat(splitData[11]),
      fuelQuantity: parseFloat(splitData[12]),
      total: parseFloat(splitData[13]),
      currency,
      fuelMeasurementUnit,
      transactionRow: i + 1,
    };

    transactions.push(transaction);
  }

  return transactions;
}

/**
 * @memberof module:IFTA
 *
 * @param {*} fuelCardDataArray
 *
 * @returns
 */
export const formatFromTCH = (fuelCardDataArray) => {
  const transactions = [];

  for (let i = 1, arrayLen = fuelCardDataArray.length; i < arrayLen; i++) {
    const stringData = fuelCardDataArray[i];
    let splitData = stringData.split(',');

    // sometimes it can return an array with one empty element (indicating the end of file)
    if (splitData.length <= 1) continue;

    // sometimes the names are encoded as "LASTNAME, FIRSTNAME" in which case messes up the parsing...
    if (isNaN(parseFloat(splitData[6]))) {
      const lastName = splitData[6].trim();
      splitData[5] = `${splitData[5]} ${lastName}`.replace(/"/g, '');
      splitData[6] = 'g_parse_out';
    }

    splitData = splitData.filter(val => val !== 'g_parse_out');

    // currencyUnit represents CAD/Litres or USD/Gallons
    const currencyUnit = (splitData[16] && splitData[16].split('/')) || [];
    const currency = currencyUnit[0] && currencyUnit[0].toLowerCase();
    const fuelMeasurementUnit = currencyUnit[1] && (currencyUnit[1].trim().toLowerCase() === 'liters') ? 'l' : 'g';

    const transaction = {
      cardNo: splitData[0],
      transactionDateTime: moment.utc(`${splitData[1]} ${splitData[2]}`).toDate(),
      unitId: splitData[4],
      odometer: splitData[6] ? parseFloat(splitData[6]) : 0,
      locationName: splitData[7],
      city: splitData[8],
      stateProvinceAbbrv: splitData[9],
      item: splitData[11],
      unitPrice: parseFloat(splitData[12]),
      fuelQuantity: parseFloat(splitData[13]),
      total: parseFloat(splitData[14]),
      currency,
      fuelMeasurementUnit,
      transactionRow: i + 1,
    };

    transactions.push(transaction);
  }

  return transactions;
}

/**
 * @memberof module:IFTA
 *
 * @param {*} fuelCardDataArray
 *
 * @returns
 */
export const formatFromHuskyComdata = (fuelCardDataArray) => {
  const transactions = [];

  for (let i = 1, arrayLen = fuelCardDataArray.length; i < arrayLen; i++) {
    const stringData = fuelCardDataArray[i];
    const splitData = stringData.split(',');

    // sometimes it can return an array with one empty element (indicating the end of file)
    if (splitData.length <= 1) continue;

    // husky cards have date format DD-MM-YYY which doesnt register in js or moment correctly (it expects YY-MM-DD or MM-DD-YY), so we have to parse for this
    let transactionDate = (splitData[4] && splitData[4].split('-')) || [];
    const date = transactionDate[0];
    const month = transactionDate[1];
    const year = transactionDate[2];
    transactionDate = `${month}/${date}/${year}`;
    let transactionTime = splitData[5] ? splitData[5].replace(/\./g, ':') : '00:00:00';
    const totalTax = splitData[30] ? parseFloat(splitData[30]) : 0;

    const transaction = {
      cardNo: splitData[11],
      transactionDateTime: moment.utc(`${transactionDate} ${transactionTime}`).toDate(),
      unitId: splitData[0],
      odometer: splitData[19] ? parseFloat(splitData[19]) : 0,
      locationName: splitData[7],
      city: splitData[8],
      stateProvinceAbbrv: splitData[9],
      item: splitData[20],
      unitPrice: parseFloat(splitData[22]),
      fuelQuantity: parseFloat(splitData[21]),
      total: parseFloat(splitData[23]) + totalTax, // amount + total tax
      currency: 'CAD',
      fuelMeasurementUnit: 'l',
      transactionRow: i + 1,
    };

    transactions.push(transaction);
  }

  return transactions;
}

/**
 * @memberof module:IFTA
 *
 * @param {*} fuelCardDataArray
 *
 * @returns
 */
export const formatFromNationwide = (fuelCardDataArray) => {
  const transactions = [];

  for (let i = 1, arrayLen = fuelCardDataArray.length; i < arrayLen; i++) {
    const stringData = fuelCardDataArray[i];
    const splitData = stringData.split(',');

    // sometimes it can return an array with one empty element (indicating the end of file)
    if (splitData.length <= 1) continue;

    // nationwide have date format DD-MM-YYY which doesnt register in js or moment correctly (it expects YY-MM-DD or MM-DD-YY), so we have to parse for this
    let transactionDate = (splitData[4] && splitData[4].split('-')) || [];
    const date = transactionDate[0];
    const month = transactionDate[1];
    const year = transactionDate[2];
    transactionDate = `${month}/${date}/${year}`;
    let transactionTime = splitData[5] ? splitData[5].replace(/-/g, ':') : '00:00:00';

    const transaction = {
      cardNo: splitData[2],
      transactionDateTime: moment.utc(`${transactionDate} ${transactionTime}`).toDate(),
      unitId: splitData[3],
      odometer: 0, // none for this fcard
      locationName: splitData[6],
      city: undefined,
      stateProvinceAbbrv: splitData[7],
      unitPrice: parseFloat(splitData[10]),
      fuelQuantity: parseFloat(splitData[9]),
      total: parseFloat(splitData[13]),
      currency: 'CAD',
      fuelMeasurementUnit: 'l',
      transactionRow: i + 1,
    };

    transactions.push(transaction);
  }

  return transactions;
}

/**
 * @memberof module:IFTA
 *
 * @param {*} fuelCardDataArray
 *
 * @returns
 */
export const formatFromFCL = (fuelCardDataArray) => {
  const transactions = [];
  for (let i = 1, arrayLen = fuelCardDataArray.length - 1; i < arrayLen; i++) {
    const stringData = fuelCardDataArray[i];
    const splitData = stringData.split(',');

    // sometimes it can return an array with one empty element (indicating the end of file)
    if (splitData.length <= 1) continue;

    let transactionTime = moment(splitData[11] + 'T000000').toDate();

    const transaction = {
      cardNo: splitData[7],
      // this fuel card has no time property hence we take at face-value rather than UTC
      transactionDateTime: transactionTime,
      unitId: splitData[8].trim(),
      odometer: splitData[9] ? parseFloat(splitData[9]) : undefined,
      locationName: splitData[5],
      city: undefined,
      stateProvinceAbbrv: splitData[6],
      item: splitData[15],
      unitPrice: parseFloat(splitData[17]),
      fuelQuantity: parseFloat(splitData[16]),
      total: parseFloat(splitData[27]),
      currency: 'CAD',
      fuelMeasurementUnit: 'l',
      transactionRow: i + 1,
    };

    transactions.push(transaction);
  }

  return transactions;
}

/**
 * @memberof module:IFTA
 *
 * @param {*} fuelCardDataArray
 *
 * @returns
 */
export const formatFromTCH2 = (fuelCardDataArray) => {
  const transactions = [];

  // array length - 1 because the last row is a totals row
  for (let i = 1, arrayLen = fuelCardDataArray.length - 1; i < arrayLen; i++) {
    const stringData = fuelCardDataArray[i];
    const splitData = stringData.split(',');

    // sometimes it can return an array with one empty element (indicating the end of file)
    if (splitData.length <= 1) continue;

    const locationName = splitData[16] ? splitData[16].replace(/"/g, '') : '';
    const city = splitData[17];
    const stateProvinceAbbrv = splitData[18];

    // nationwide have date format DD-MM-YYY which doesnt register in js or moment correctly (it expects YY-MM-DD or MM-DD-YY), so we have to parse for this
    let transactionDate = (splitData[3] && splitData[3].split('-')) || [];
    const date = transactionDate[0];
    const month = transactionDate[1];
    const year = transactionDate[2];
    transactionDate = `${month}/${date}/${year}`;
    let transactionTime = splitData[4] ? splitData[4].replace(/-/g, ':') : '00:00:00';

    const transaction = {
      cardNo: splitData[2],
      transactionDateTime: moment.utc(`${splitData[3]} ${splitData[4]}`).toDate(),
      unitId: splitData[7],
      odometer: undefined,
      locationName,
      city,
      stateProvinceAbbrv,
      item: splitData[22],
      unitPrice: parseFloat(splitData[24]),
      fuelQuantity: parseFloat(splitData[25]),
      total: parseFloat(splitData[46]), // the total is the unitPrice * fuelQuantity + tax
      currency: splitData[21],
      fuelMeasurementUnit: 'g',
      transactionRow: i + 1,
    };

    transactions.push(transaction);
  }

  return transactions;
}

/**
 * @memberof module:IFTA
 *
 * @param {*} fuelCardDataArray
 *
 * @returns
 */
 export const formatCSV = (header, fuelCardDataArray) => {
  const transactions = [];

  const headers = header.split(',');

  const headerDate = headers.find(header => header.toLowerCase().includes('date')); // cases: "date", "tran date", "transaction date"
  const headerDateIndex = headers.indexOf(headerDate);

  const headerTime = headers.find(header => header.toLowerCase().includes('time')); // cases: "time", "tran time", "transaction time"
  const headerTimeIndex = headers.indexOf(headerTime);

  for (let i = 1, arrayLen = fuelCardDataArray.length; i < arrayLen; i++) {
    const stringData = fuelCardDataArray[i];
    const splitData = stringData.split(',');

    // sometimes it can return an array with one empty element (indicating the end of file)
    if (splitData.length <= 1) continue;

    const transactionData = {};
    const transactionRowData = [];

    let transactionDate;
    if (headerDateIndex !== -1) {
      const date = splitData[headerDateIndex];

      const dateValuesCase0 = date.split('-'); // intended result
      const dateValuesCase1 = date.split('/'); // case 1 where date is not delimitted by '-'

      let value1;
      let value2;
      let value3;

      if (dateValuesCase0.length === 3) {
        transactionDate = date;
      } else if (dateValuesCase1.length === 3) {
        value1 = dateValuesCase1[0];
        value2 = dateValuesCase1[1];
        value3 = dateValuesCase1[2];

        let dateValue = `${value1}-${value2}-${value3}`;
        transactionDate = dateValue;
      }

      transactionRowData.push(transactionDate);
    }

    let transactionTime;
    if (headerTimeIndex !== -1) {
      const time = splitData[headerTimeIndex];

      const timeValuesCase0 = time.split(':'); // intended result
      const timeValuesCase1 = time.split('-'); // case 1 where time is not delimitted by ':'
      const timeValuesCase2 = time.split('.'); // case 2 where time is not delimitted by ':'

      let value1;
      let value2;
      let value3;

      if (timeValuesCase0.length >= 2 && timeValuesCase0.length <= 3) {
        transactionTime = time;
      } else if (timeValuesCase1.length >= 2 && timeValuesCase1.length <= 3) {
        value1 = timeValuesCase1[0];
        value2 = timeValuesCase1[1];
        value3 = timeValuesCase1[2];

        let timeValue = `${value1}:${value2}`;
        if (value3) timeValue = timeValue.concat(`:${value3}`);
        transactionTime = timeValue;
      } else if (timeValuesCase2.length >= 2 && timeValuesCase2.length <= 3) {
        value1 = timeValuesCase2[0];
        value2 = timeValuesCase2[1];
        value3 = timeValuesCase2[2];

        let timeValue = `${value1}:${value2}`;
        if (value3) timeValue = timeValue.concat(`:${value3}`);
        transactionTime = timeValue;
      }
    } else {
      transactionTime = '00:00:00';
    }
    transactionRowData.push(transactionTime);

    let transactionFuelMeasurement;
    let transactionCurrency;
    let transactionFuelMeasurementAndCurrency;

    splitData.forEach((data, index) => {
      let _data = data.replace(/"/g, '');

      if (index === splitData.length-1) _data = _data.replace('\r', '');

      if (
        _data.toLowerCase() === 'l' ||
        _data.toLowerCase() === 'g' ||
        _data.toLowerCase() === 'liters' ||
        _data.toLowerCase() === 'litres' ||
        _data.toLowerCase() === 'gallons'
      ) {
        transactionFuelMeasurement = _data;
      } else if (
        _data.toLowerCase() === 'cad' ||
        _data.toLowerCase() === 'usd'
      ) {
        transactionCurrency = _data;
      } else if (
        _data.toLowerCase() === 'cad/liters' ||
        _data.toLowerCase() === 'cad/litres' ||
        _data.toLowerCase() === 'usd/gallons' ||
        _data.toLowerCase() === 'liters/cad' ||
        _data.toLowerCase() === 'litres/cad' ||
        _data.toLowerCase() === 'gallons/usd'
      ) {
        transactionFuelMeasurementAndCurrency = _data;
      } else if ((index !== headerDateIndex) && (index !== headerTimeIndex)) {
        transactionRowData.push(_data);
      }
    });

    if (!transactionFuelMeasurementAndCurrency && transactionCurrency && transactionFuelMeasurement) {
      transactionRowData.push(transactionFuelMeasurement, transactionCurrency);
    }

    if (!transactionFuelMeasurementAndCurrency && !transactionCurrency && transactionFuelMeasurement) {
      if (transactionFuelMeasurement.toLowerCase().startsWith('l')) {
        transactionCurrency = 'CAD';
      } else if (transactionFuelMeasurement.toLowerCase().startsWith('g')) {
        transactionCurrency = 'USD';
      }
      transactionRowData.push(transactionFuelMeasurement, transactionCurrency);
    }

    if (!transactionFuelMeasurementAndCurrency && !transactionFuelMeasurement && transactionCurrency) {
      if (transactionCurrency.toLowerCase().startsWith('c')) {
        transactionFuelMeasurement = 'Litres';
      } else if (transactionCurrency.toLowerCase().startsWith('u')) {
        transactionFuelMeasurement = 'Gallons';
      }
      transactionRowData.push(transactionFuelMeasurement, transactionCurrency);
    }

    if (transactionFuelMeasurementAndCurrency) {
      const part1 = transactionFuelMeasurementAndCurrency.split('/')[0];
      const part2 = transactionFuelMeasurementAndCurrency.split('/')[1];
      transactionRowData.push(part1, part2);
    }

    Object.assign(transactionData, transactionRowData);

    transactions.push(transactionData);
  }

  return transactions;
}
