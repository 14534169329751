/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';

// API
import { callCloudFunction } from 'sb-csapi/dist/AAPI';

// Components
import Button from 'sbCore/Button/Button';
import Dialog from 'sbCore/Dialog/Dialog';
import Divider from 'sbCore/Divider/Divider';
import Message from 'sbCore/Message/Message';
import InputText from 'sbCore/InputText/InputText';
import InputLabel from 'sbCore/InputLabel/InputLabel';

// Style
import './style.scss';

/**
 * Modal for generating new API credentials
 *
 * @param {boolean} isVisible - determines if the modal is visible
 * @param {function} onClose - callback function to call when the modal is closed
 *
 * @returns {JSX}
 */
function APIKeyGeneratorModal({ isVisible, onClose, ...props }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showAPIKey, setShowAPIKey] = useState(false);
  const [copiedToClipboardContent, setCopiedToClipboardContent] = useState();

  // API Information
  const [apiLabel, setApiLabel] = useState('');
  const [apiCredentials, setAPICredentials] = useState(undefined);

  async function generateAPICredentials() {
    setIsLoading(true);

    // Generate API credentials with default scopes
    const _apiCredentials = await callCloudFunction('generateAPIAuth', {
      label: apiLabel,
      scope: {
        equipment: {
          read: true,
          write: true,
        },
        driver: {
          read: true,
          write: true,
        },
      },
    });

    setAPICredentials(_apiCredentials);
    setIsLoading(false);
  }

  function onHideModal() {
    onClose();

    // Add a bit of delay before resetting state to reduce layout jank
    setTimeout(() => {
      setIsLoading(false);
      setShowAPIKey(false);
      setApiLabel('');
      setAPICredentials(undefined);
    }, 150);
  }

  const createAPIModalFooter = (
    <div className="flex flex-row justify-content-end">
      <Button
        text
        className="p-button-secondary"
        label={apiCredentials ? 'Close' : 'Cancel'}
        onClick={() => onHideModal()}
      />
      {!apiCredentials && (
        <Button
          className="p-button-primary"
          label="Generate Credentials"
          icon={`${isLoading ? 'pi pi-spin pi-spinner' : ''}`}
          disabled={!apiLabel}
          onClick={() => generateAPICredentials()}
        />
      )}
    </div>
  );

  return (
    <Dialog
      header="Create API Key"
      visible={isVisible}
      className=" api-credential-modal sm:w-11 md:w-10"
      onHide={() => onHideModal()}
      footer={createAPIModalFooter}
    >
      <p>
        {"Generating an API key will grant you access to Switchboard's API endpoints. For more information regarding Switchboard's APIs and relevant documentation, visit "}
        <a href="https://api.onswitchboard.com" target="_blank" rel="noreferrer"><span className="font-bold">api.onswitchboard.com</span></a>
        <span className="material-icons ml-1 text-base">open_in_new</span>
      </p>

      <InputLabel>API Label</InputLabel>
      <InputText
        readOnly={apiCredentials}
        type="text"
        placeholder="Label"
        className="p-inputtext-sm w-full opacity-100"
        value={apiLabel}
        onChange={(e) => setApiLabel(e.target.value)}
        error={!apiLabel}
      />

      <div className={`api-credential-container ${apiCredentials ? 'fadeinup' : 'hidden'}`}>
        <Divider />
        <Message
          className="w-full mb-3"
          severity="warn"
          text={(
            <p className="mb-0 ml-1">
              Make sure to copy and store your API credentials securely as you would your own password. The API key is unique and it is
              <span className="font-bold">{' non-recoverable'}</span> if lost.
            </p>
          )}
        />

        <InputLabel>API ID</InputLabel>
        <div className="flex flex-row align-items-center">
          <InputText
            disabled
            type="text"
            className="p-inputtext-sm w-full opacity-100"
            value={apiCredentials?.apiId}
          />
          <Button
            text
            className="mx-2"
            icon="pi pi-copy"
            tooltip={copiedToClipboardContent === apiCredentials?.apiId ? 'Copied!' : 'Copy to clipboard'}
            onClick={() => {
              navigator.clipboard.writeText(apiCredentials?.apiId);
              setCopiedToClipboardContent(apiCredentials?.apiId);
            }}
          />
        </div>

        <InputLabel className="mt-3">API Key</InputLabel>
        <div className="flex flex-row align-items-center">
          <span className="w-full p-input-icon-right">
            <i
              className={`pi ${showAPIKey ? 'pi-eye' : 'pi-eye-slash'} cursor-pointer`}
              onClick={() => setShowAPIKey(!showAPIKey)}
            />
            <InputText
              disabled
              type={showAPIKey ? 'text' : 'password'}
              className="p-inputtext-sm w-full opacity-100"
              value={apiCredentials?.apiKey}
            />
          </span>
          <Button
            text
            className="mx-2"
            icon="pi pi-copy"
            tooltip={copiedToClipboardContent === apiCredentials?.apiKey ? 'Copied!' : 'Copy to clipboard'}
            onClick={() => {
              navigator.clipboard.writeText(apiCredentials?.apiKey);
              setCopiedToClipboardContent(apiCredentials?.apiKey);
            }}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default APIKeyGeneratorModal;
