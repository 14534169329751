import React from 'react';

// Components
import PaymentTermDropdown from 'components/Dispatch/PaymentTermDropdown/PaymentTermDropdown';

// Shared Document Components
import DocumentInputText from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputText/InputText';
import DocumentInputTextarea from 'components/Dispatch/DispatchDocument/DispatchDocuments/Shared/InputTextarea/InputTextarea';

// Styling
import './style.scss';

/**
 * @description Invoice Document Generator job information
 * @param {Function} updateDocumentStateObj - Callback function that's passed back an attribute and value to update its parent's state
 * @param {String} [batchId] - The current batch id value
 * @param {String} [invoiceDate] - The current invoice date value
 * @param {String} [paymentDue] - The current payment due value
 * @param {String} [billTo] - The current bill to value
 * @param {Number} [paymentTermType] - The current payment term type
 * @returns
 */
const InvoiceJobInformation = ({ ...props }) => {

  // ** Functions ** //
  // Updates the parent documentStateObj given an attribute and value
  function updateDocumentStateObj(attribute, value) {
    if (props.updateDocumentStateObj) props.updateDocumentStateObj(attribute, value);
  }

  // ** Field Information ** //
  const invoiceNumberField = (
    <>
      <div className="information-sub-title">Job ID</div>
      <div className="input-field">
        <DocumentInputText
          inputClassName="w-10rem text-sm"
          value={props.batchId}
          onChange={(value) => updateDocumentStateObj('batchId', value)}
          success={props.batchId}
          warning={!props.batchId || props.batchId == ''}
          isDownloaded={props.isDownloaded}
        />
      </div>
    </>
  );

  const invoiceDateField = (
    <>
      <div className="information-sub-title">Invoice Date</div>
      <div className="input-field">
        <DocumentInputText
          inputClassName="w-10rem text-sm"
          value={props.invoiceDate}
          onChange={(value) => updateDocumentStateObj('invoiceDate', value)}
          success={props.invoiceDate}
          warning={!props.invoiceDate || props.invoiceDate == ''}
          isDownloaded={props.isDownloaded}
        />
      </div>
    </>
  );

  const paymentTermsField = (
    <>
      <div className="information-sub-title">Payment Terms</div>
      <PaymentTermDropdown
        paymentTermInt={props.paymentTermType}
        onPaymentTermChange={(value) => updateDocumentStateObj('paymentTermType', value)}
        isDownloaded={props.isDownloaded}
        includeNoPaymentTermsOption
      />
    </>
  );

  const paymentDueField = (
    <>
      <div className="information-sub-title">Payment Due</div>
      <div className="input-field">
        <DocumentInputText
          inputClassName="w-10rem text-sm"
          value={props.paymentDue}
          onChange={(value) => updateDocumentStateObj('paymentDue', value)}
          success={props.paymentDue}
          warning={!props.paymentDue || props.paymentDue == ''}
          isDownloaded={props.isDownloaded}
        />
      </div>
    </>
  );

  const billToText = (
    <div className="information-sub-title pr-0">Bill To:</div>
  );

  const billToTextarea = (
    <div className="input-field bill-to-field">
      <DocumentInputTextarea
        inputClassName="w-10rem text-sm"
        rows={5}
        cols={30}
        value={props.billTo}
        onChange={(value) => updateDocumentStateObj('billTo', value)}
        success={props.billTo}
        warning={!props.billTo || props.billTo == ''}
        isDownloaded={props.isDownloaded}
      />
    </div>
  );

  return (
    <div className="invoice-job-information ml-8 mr-4 ">

      <div className="grid ml-3 mb-4">

        <div className="col-3">
          {invoiceNumberField}
        </div>
        <div className="col-3">
          {invoiceDateField}
        </div>
        <div className="col-3">
          {paymentTermsField}
        </div>
        <div className="col-3">
          {paymentDueField}
        </div>

      </div>

      <div className="grid ml-2">

        <div className="col-1">
          {billToText}
        </div>
        <div className="col-5">
          {billToTextarea}
        </div>

      </div>

    </div>
  );
};

export default InvoiceJobInformation;
