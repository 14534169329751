import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ChooseDriverModal from './ChooseDriverModal';

// Context
import StoreContext from 'contexts/StoreContext';

// CSS
// import styles from './ChooseDriverButton.module.scss';

class ChooseDriverButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.confirmDrivers = this.confirmDrivers.bind(this);
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextProps.Company !== this.props.Company || nextState !== this.state) {
  //     return true;
  //   }
  //   return false;
  // }

  showModal() {
    this.setState({ ...this.state, show: true });
  }

  closeModal() {
    this.setState({ ...this.state, show: false });
  }

  confirmDrivers(drivers) {
    // obtain the driver(s)/team of drivers selected
    if (this.props.chooseDriversHandler) {
      this.props.chooseDriversHandler(drivers);
    }
    this.closeModal();
  }

  render() {
    const { Company, Driver, className } = this.props;

    return (
      <div className={`inline`}>
        {this.state.show && Company && Company.company &&
          <ChooseDriverModal
            show={this.state.show}
            handleClose={this.closeModal}
            confirmDriversHandler={this.confirmDrivers}
            company={this.props.Company.company}
            Driver={this.props.Driver}
          />
        }
        <button className={`buttonDefault ${className} translate-me`} onClick={this.showModal} disabled={!this.props.Company.fetched}>{this.props.children || 'Choose Driver(s)'}</button>
      </div>
    );
  }
}

ChooseDriverButton.propTypes = {
  Company: PropTypes.object.isRequired,
  Driver: PropTypes.object.isRequired,
  Subscription: PropTypes.object.isRequired,
  children: PropTypes.node,
  chooseDriversHandler: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const mapStateToProps = state => {
  const { Company, Driver, Subscription } = state;
  return {
    Company,
    Driver,
    Subscription,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(ChooseDriverButton);

