import {
  copyQuery, count, createQuery, find, getAttribute, getCurrentUser,
  includePointers, setQueryRestriction, sortQuery, updateRecord,
} from 'api/Parse';

import moment from 'moment-timezone';

import Sort from 'sbObjects/Sort';

import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

/** @module VisionVehicle */

/**
 * @memberof module:VisionVehicle
 * @description Get VisionVehicle and applicable information
 *
 * @param {int} page - query page / limit skip multiplier
 * @param {int} limit  - amount of results we want
 * @param {array} sortBy - array of Sort objects
 * @param {array} filters - array of Filter objects
 * @param {bool} considerChildCompanies - whether or not to include child company results
 *
 * @returns { object } - { visionVehicles: [], totalVisionVehiclesCount: int }
 */
async function getVisionVehicles(unitId, page = 0, limit = 10, sortBy = new Sort('updatedAt', QuerySortOrderTypes.DESCENDING), filters = [], considerChildCompanies) {
  const currentUser = getCurrentUser();
  const visionVehicleQuery = createQuery('VisionVehicle');
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  const companiesToConsider = [belongsToCompany];

  if (considerChildCompanies) {
    const childrenQuery = createQuery('CompanyLink');
    setQueryRestriction(childrenQuery, QueryRestrictionTypes.EQUAL_TO, 'parentCompany', belongsToCompany);
    const childCompanies = await find(childrenQuery);
    childCompanies.map((childCompany) => {
      companiesToConsider.push(childCompany);
    });

    setQueryRestriction(visionVehicleQuery, QueryRestrictionTypes.CONTAINED_IN, 'belongsToCompany', companiesToConsider);
  } else {
    setQueryRestriction(visionVehicleQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
  }

  // set query restricitons from filter
  filters.map(filter => {
    setQueryRestriction(visionVehicleQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
  });

  // at this point, copy current query to get the number of pages for pagination
  const visionVehicleCountQuery = copyQuery(visionVehicleQuery);

  // now do the includes (we do them after copying the visionVehicleQuery so the visionEventCountQuery doesn't have the overhead of these includes/unnecessary query additions)
  includePointers(visionVehicleQuery, [
    'latestVisionImage',
  ]);

  setQueryRestriction(visionVehicleQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  setQueryRestriction(visionVehicleQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
  // call the ascending/descending function on the query, passing in the attribute
  sortQuery(visionVehicleQuery, sortBy.order, sortBy.attribute);

  try {
    const [totalVisionVehiclesCount, visionVehicles] = await Promise.all([count(visionVehicleCountQuery), find(visionVehicleQuery)]);
    return { visionVehicles, totalVisionVehiclesCount };
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getVisionVehicles,
};
