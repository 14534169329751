import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

// API
import history from 'sbHistory';
import {
  getAttribute,
  getCurrentUser,
} from 'api/Parse';
import * as Analytics from 'api/Analytics';
import * as Helpers from 'api/Helpers';
import { getQueryParameter } from 'api/URL';
import {
  getCompanyAnalytics,
  updateCompanyAnalytics,
} from 'api/CompanyAnalytics/CompanyAnalytics';
import { verifyOTP } from 'api/OTP';

// Components
import Sidebar from 'layouts/IFTALayout.old/Sidebar';
import IFTARouteLayout from 'layouts/IFTALayout.old/IFTARouteLayout';
import CalculatorLayout from 'layouts/IFTALayout.old/CalculatorLayout';
import IFTAReportLayout from 'layouts/IFTALayout.old/IFTAReportLayout';

// CSS
import styles from 'layouts/IFTALayout.old/IFTALayout.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

class IFTALayoutOld extends React.Component {
  constructor(props) {
    super(props);

    const iftaSubViews = [
      {
        value: 'calculator',
        label: 'Mileage Calculator',
      },
    ];

    if (
      props.Subscription.subscription &&
      props.Subscription.subscription.get('iftaModule')
    ) {
      iftaSubViews.unshift({
        value: 'mileages',
        label: 'IFTA Mileages',
      });
    }

    const iftaSubViewsList = iftaSubViews.map(obj => obj.value);

    this.DEFAULT_VIEW = Helpers.isSubscribedToModule('iftaFree') ? 'calculator' : 'mileages';
    let currentView = getQueryParameter(props.location.search, 'view');
    if (!currentView || !iftaSubViewsList.includes(currentView)) currentView = this.DEFAULT_VIEW;

    this.state = {
      subview: currentView,
      iftaSubViews,
      iftaSubViewsList,
    };

    this.handleSubview = this.handleSubview.bind(this);
    this.checkIFTAPermission = this.checkIFTAPermission.bind(this);
  }

  async componentDidMount() {
    document.title = 'IFTA - Switchboard';

    // Check company analytics status
    const companyAnalytics = await getCompanyAnalytics();
    const VIEWIFTA = getAttribute(companyAnalytics, 'VIEWIFTA');
    if (!VIEWIFTA) {
      Analytics.track('IFTA Viewed');
      await updateCompanyAnalytics(companyAnalytics, { VIEWIFTA: true });
    }

    this.checkIFTAPermission();

    // Check privilege for use of IFTA Report by internal team, while using a user's account (different to subscription check)
    const { props, state } = this;
    let currentView = getQueryParameter(props.location.search, 'view') || this.DEFAULT_VIEW;

    const INTERNAL_TOOL_PRIVILEGE = await verifyOTP(props.location, false); // TODO: Set 2nd arg to true to auto-delete token after use
    if (INTERNAL_TOOL_PRIVILEGE) {
      // Add INTERNAL USE ONLY IFTA Report module if the OTP is valid
      const iftaSubViews = {
        ...state.iftaSubViews,
        REPORTS_INTERNAL: {
          value: 'reports_internal',
          label: 'IFTA Reports',
        },
      };
      const iftaSubViewsList = Object.values(iftaSubViews).map(obj => obj.value);
      this.setState(
        {
          iftaSubViews,
          iftaSubViewsList,
        },
        // If the view param is set to reports we can only render it after reports is added to the subviews
        () => { this.handleSubview(currentView); },
      );
    }

    Analytics.identifyOnceForCompany('VIEWIFTA', {
      'IFTA Viewed': true,
      'IFTAViewDate': moment().toISOString(),
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { state } = this;
    const newView = getQueryParameter(nextProps.location.search, 'view');
    // If your new views are not rendering this may be why
    if (!newView || !state.iftaSubViewsList.includes(newView)) return;

    this.setState({ subview: newView });
  }

  handleSubview(subview) {
    const { state } = this;
    if (state.iftaSubViewsList.includes(subview)) this.setState({ subview });
  }

  checkIFTAPermission() {
    // verify user whether have the privillege for the IFTAmodule
    const user = getCurrentUser();
    const belongsToCompany = user.get('belongsToCompany');
    const subscription = this.props.Subscription.subscription;
    const isiftaNotSubscribed = (subscription && !subscription.get('iftaModule') && !subscription.get('iftaFree'));

    const userSpecialPermission = user.get('userSpecialPermission');
    const iftaIsNotAllowed = userSpecialPermission && userSpecialPermission.get('disableIftaModule');

    if (iftaIsNotAllowed) {
      history.push({ pathname: '404' });
    }
    if (isiftaNotSubscribed) {
      history.push({ pathname: '404' });
    }
  }

  render() {
    const { state, props } = this;
    const { Company } = props;

    return (
      <div className={styles.iftaLayout}>
        <Sidebar
          company={Company.company}
          location={props.location}
          subviews={state.iftaSubViews}
          handleSubViewClick={this.handleSubview}
          activeSubview={state.subview}
        />

        <div className={styles.content}>
          {state.subview === ('mileages') && (
            <IFTARouteLayout
              styles={styles}
              location={props.location}
              showNewIFTA={props.showNewIFTA}
              toggleNewIFTA={() => props.toggleNewIFTA()}
            />
          )}
          {state.subview === 'calculator' && (
            <div>
              <CalculatorLayout />
            </div>
          )}
          {state.subview === 'reports_internal' && (
            <div>
              <IFTAReportLayout
                styles={styles}
                location={props.location}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { Company, Subscription } = state;
  return {
    Company,
    Subscription,
  };
};

IFTALayoutOld.propTypes = {
  Company: PropTypes.object,
  Subscription: PropTypes.object,
  location: PropTypes.object,
  toggleNewIFTA: PropTypes.func,
  showNewIFTA: PropTypes.bool,
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(IFTALayoutOld);
