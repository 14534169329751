/* eslint-disable react/jsx-no-bind */
import uniqid from 'uniqid';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

// API
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getHosViolations } from 'api/ELD/HOSViolation/HOSViolation';
import { convertMillisecondsToHours, formatTimeNumberToHoursMinutes, toTitleCase } from 'api/Helpers';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import HOSRegulation from 'api/Lists/HOSRegulation';

// Components
import DutyStatusBadge from 'sbCore/DutyStatusBadge/DutyStatusBadge';
import { FontIcon } from 'material-ui';
import Card from 'sbCore/Card/Card';
import Button from 'sbCore/Button/Button';
import Column from 'sbCore/Column/Column';
import DataTable from 'sbCore/DataTable/DataTable';
import { ConfirmPopup, confirmPopupSettings } from 'sbCore/ConfirmPopup/ConfirmPopup';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';
import Chip from 'sbCore/Chip/Chip';

import styles from './DriverHoursLayout.module.scss';

/**
 * Holds the layout for displaying API credentials
 *
 * @returns {JSX}
 */
function ViolationsTodayCard() {
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedIntegration, setSelectedIntegration] = useState(null);
  // const [showRequestIntegrationModal, setShowRequestIntegrationModal] = useState(false);
  const [informationArr, setInformationArr] = useState([]);

  // Triggers a referesh of the API credentials
  const [refreshToken, setRefreshToken] = useState(uniqid());

  useEffect(() => {
    let didCancel = false;

    async function getHOSViolationsForState() {
      setIsLoading(true);
      const hosViolationsArr = (await getHosViolations(undefined, undefined, moment().startOf('day')));
      const _hosViolationInformationArr = hosViolationsArr?.map((hosViolationObj) => {
        const hosRegulation = HOSRegulation[hosViolationObj.get('regulationInt')];
        return ({
          driverName: toTitleCase(hosViolationObj?.get('driver')?.get('user_fullName')),
          phoneNumber: hosViolationObj?.get('driver')?.get('user_phoneNumber'),
          eldStatusInt: hosViolationObj?.get('driver')?.get('eldStatusInt'),
          dateTime: hosViolationObj?.get('triggerTime'),
          violationDescription: hosRegulation.message,
          driverId: hosViolationObj?.get('driver')?.id,
        });
      });

      // [
      //   // {
      //   //   label: 'ISG / ASKK',
      //   //   enabled: !!getAttribute(vendorIntegrationObj, 'isg', true),
      //   // },
      //   // {
      //   //   label: 'FourKites',
      //   //   enabled: !!getAttribute(vendorIntegrationObj, 'fourKites', true),
      //   // },
      //   // {
      //   //   label: 'Project44',
      //   //   enabled: !!getAttribute(vendorIntegrationObj, 'p44', true),
      //   // },
      //   // {
      //   //   label: 'Trucker Tools',
      //   //   enabled: !!getAttribute(vendorIntegrationObj, 'truckerTools', true),
      //   // },
      //   // {
      //   //   label: 'Macropoint',
      //   //   enabled: !!getAttribute(vendorIntegrationObj, 'macropointUsername', true),
      //   // },
      // ];

      if (!didCancel) {
        setInformationArr(_hosViolationInformationArr);
        setIsLoading(false);
      }
    }

    getHOSViolationsForState();
    return () => {
      didCancel = true;
    };
  }, [refreshToken]);

  function driverBodyTemplate(rowData) {
    return (
      <div>
        <a
          href={`/driver?driver=${rowData.driverId}&view=hosEvents`}
          target="_blank"
        >
          <b>{rowData.driverName}</b>
        </a>
        <br />
        <div className={styles.subtitle}>
          <FontIcon className={`${styles.subtitle} material-icons`}>phone</FontIcon>
          {rowData.phoneNumber}
        </div>
      </div>
    );
  }

  function driverInfoTemplate(rowData) {
    return (
      <DutyStatusBadge className="z-depth-0" eldStatusInt={rowData.eldStatusInt} />
    );
  }


  function violationsInfoTemplate(rowData) {
    return (
      <div>
        <div>
          <a
            href={`/driver?driver=${rowData.driverId}&view=hosViolations`}
            target="_blank"
          >
            {rowData.violationDescription}
          </a>
        </div>
        <span className={styles.subtitle}>
          @ {moment(rowData.dateTime).format('h:mm a')}
        </span>
      </div>
      // <Button
      //   sbVariant="slim"
      //   className="p-button-secondary"
      //   icon={<span className="material-icons mr-1">call</span>}
      //   label="Request an Integration"
      //   onClick={() => { setSelectedIntegration(rowData.label); setShowRequestIntegrationModal(true) }}
      // />
    );
  }


  const header = (
    <div className="flex flex-row justify-content-between align-items-end px-3 pt-4">
      <h6 className="font-bold mb-0">HOS Violations Today</h6>
    </div>
  );


  return (
    <Card
      header={header}
    >
      <DataTable
        value={informationArr}
        emptyMessage="No HOS violations today. Awesome!"
        loading={isLoading}
        rowHover
        paginator
        rows={5}
        size="small"
      >
        <Column
          field={driverBodyTemplate}
          header=""
        />
        <Column
          field={driverInfoTemplate}
          header=""
        />
        <Column
          field={violationsInfoTemplate}
          header=""
        />
      </DataTable>
    </Card>
  );
}

export default ViolationsTodayCard;
