import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { Form, FormControl, FormGroup } from 'react-bootstrap';
import {
  MDBBtn,
  MDBRow,
  MDBIcon,
} from 'mdbreact';

// API
import { compressWhitespace } from 'api/Helpers';

// Components
import DatePicker from 'components/DatePicker/container/FormControlDatePicker';

// CSS
import styles from './FilterForm.module.scss';

class FilterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {},
      buttonSwap: false, // determine if we need to hide the filter button for the clear button, or vice-versa
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (nextState !== this.state) {
  //     return true;
  //   }
  //   return false;
  // }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fields) {
      for (let i = 0; i < nextProps.fields.length; i++) {
        // this.state
      }
    }
  }

  onSubmit(e) {
    // prevent page from reloading when form submits
    e.preventDefault();
  }

  handleChange(e) {
    // add attr/val of user input to state
    this.state.filter[e.target.id] = e.target.value;
    this.state.buttonSwap = false;
    this.setState(this.state);
  }

  handleDateChange(dateMoment, attrName, submitOnSelect) {
    // add attr/val of user input to state
    // console.log(dateMoment);
    // this.state.filter[attrName] = dateMoment;
    // this.state.buttonSwap = false;
    // this.setState(this.state);

    if (submitOnSelect) {
      this.handleFilter();
    }
  }

  handleFilter() {
    // pass up input to parent
    const filterObject = {};
    const filterKeys = Object.keys(this.state.filter); // get the keys used, which is stored as this components state
    const filterKeysLen = filterKeys.length;
    for (let i = 0; i < filterKeysLen; i++) {
      // if the filter key is used, add it to our filter object
      const currentKey = filterKeys[i];
      const currentValue = this.state.filter[currentKey];
      if (
        (typeof currentValue === 'string' &&
          (currentValue.length > 0 &&
            compressWhitespace(currentValue) !== '')) ||
        (typeof currentValue === 'object' &&
          currentValue &&
          Object.keys(currentValue).length > 0)
      ) {
        filterObject[currentKey] = currentValue;
      }
    }
    if (Object.keys(filterObject).length > 0) {
      // if the filter is dirty, swap the button fo sho
      this.state.buttonSwap = true;
    }
    this.props.handleFilter(filterObject);
    this.setState(this.state);
  }

  clearFilter() {
    // parent defined clear
    this.props.clearFilter();
    this.state.buttonSwap = false;
    this.setState({ ...this.state, filter: {} });
  }

  render() {
    // Take fields given from parent of format {fieldName: Field Display Name}
    this.formGroups = this.props.fields.map(field => {
      if (field.type === 'date') {
        return (
          <FormGroup key={field.attrName} controlId={field.attrName}>
            <label className={styles.filterLabel}>{field.fullName}</label>
            <DatePicker
              id={`input${field.attrName}`}
              className={styles.filterInput}
              dateFormat="MMMM yyyy"
              type={field.type}
              placeholder={field.placeholder}
              selected={moment(this.state.filter[field.attrName])}
              ref={`input${field.attrName}`}
              onChange={date =>
                this.handleDateChange(
                  date,
                  field.attrName,
                  this.props.submitOnSelect
                )
              }
              autoComplete="off"
            />
          </FormGroup>
        );
      }
      return (
        <FormGroup key={field.attrName} controlId={field.attrName}>
          <label className={styles.filterLabel}>{field.fullName}</label>
          <FormControl
            className={styles.filterInput}
            type={field.type}
            placeholder={field.placeholder}
            value={this.state.filter[field.attrName] || ''}
            ref={`input${field.attrName}`}
            onChange={this.handleChange}
            autoComplete="off"
          />
        </FormGroup>
      );
    });

    if (!this.state.buttonSwap) {
      this.filterOrClearButton = (
        <MDBBtn
          color="primary"
          size="sm"
          type="submit"
          onClick={this.handleFilter}
          disabled={this.props.disabled}
        >
          <MDBIcon icon="filter" />
        </MDBBtn>
      );
    } else {
      this.filterOrClearButton = (
        <MDBBtn
          outline
          color="danger"
          size="sm"
          onClick={this.clearFilter}
          disabled={this.props.disabled}
        >
          <MDBIcon icon="times" />
        </MDBBtn>
      );
    }

    return (
      <MDBRow className={this.props.className}>
        <Form inline className={styles.filterForm} onSubmit={this.onSubmit}>
          {this.formGroups}
          {this.filterOrClearButton}
        </Form>
      </MDBRow>
    );
  }
}

FilterForm.propTypes = {
  className: PropTypes.string,
  fields: PropTypes.array.isRequired,
  handleFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
  newFilterType: PropTypes.bool,
  submitOnSelect: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FilterForm;
