import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import history from 'sbHistory';

// Components
import AlertBar from 'components/Shared/AlertBar/AlertBar';
import UserHeaderBar from 'components/Shared/UserHeaderBar/UserHeaderBar';
import FeatureHeaderBar from 'components/Shared/FeatureHeaderBar/FeatureHeaderBar';
import HeaderNotificationBox from 'components/Shared/HeaderNotificationBox/HeaderNotificationBox';

// API
import * as Helpers from 'api/Helpers';

// CSS
import './style.scss';

// Context
import StoreContext from 'contexts/StoreContext';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabStates: { documents: false, drivers: false, map: false, equipment: false },
      walkthroughState: {
        chatAndNotification: false,
      },
    };

    this.refreshState = this.refreshState.bind(this);
    this.changeRoute = this.changeRoute.bind(this);
    this.toggleWalkthroughState = this.toggleWalkthroughState.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { User } = nextProps;

    if ((User && User.user) && (nextProps.Subscription.subscription && !this.props.Subscription.Subscription)) {
      this.refreshState(User.user);
    }
  }

  shouldComponentUpdate(nextProps) {
    const { User, Company, Subscription, location } = nextProps;
    const isUserChanged = User.fetched !== this.props.User.fetched;
    const isLocationChanged = location !== this.props.location;
    const isSubscriptionChanged = Subscription.subscription && !this.props.Subscription.Subscription;
    const isCompanyChanged = Company.company && !this.props.Company.company;

    if (isUserChanged) return true;
    if (isLocationChanged) return true;
    if (isSubscriptionChanged) return true;
    if (isCompanyChanged) return true;

    return false;
  }

  refreshState(user) {
    const newState = { ...this.state };
    const userSpecialPermission = user && user.id && user.get('userSpecialPermission'); // need id check for initial log-in (default is {} not undefined)


    if (Helpers.isSubscribedToModule('eldModule')) {
      newState.tabStates.drivers = true;
      newState.tabStates.equipment = true;
      newState.tabStates.safety = true;
    }
    if (Helpers.isSubscribedToModule('iftaModule')) {
      newState.tabStates.equipment = true;
      newState.tabStates.ifta = true;
    }
    if (Helpers.isSubscribedToModule('iftaFree')) {
      newState.tabStates.ifta = true;
    }
    if (Helpers.isSubscribedToModule('mapModule')) {
      newState.tabStates.map = true;
    }
    if (Helpers.isSubscribedToModule('driverDocumentModule')) {
      newState.tabStates.drivers = true;
      newState.tabStates.documents = true;
    }
    if (Helpers.isSubscribedToModule('driverBehaviourModule')) {
      newState.tabStates.driverbehaviour = true;
    }
    if (Helpers.isSubscribedToModule('dispatchModule')) {
      newState.tabStates.dispatch = true;
    }
    if (Helpers.isSubscribedToModule('visionModule')) {
      newState.tabStates.vision = true;
    }

    if (userSpecialPermission) {
      const disableMapModule = userSpecialPermission.get('disableMapModule');
      const disableDriversModule = userSpecialPermission.get('disableDriversModule');
      const disableDispatchModule = userSpecialPermission.get('disableDispatchModule');
      const disableEquipmentModule = userSpecialPermission.get('disableEquipmentModule');
      const disableSafetyModule = userSpecialPermission.get('disableSafetyModule');
      const disableIftaModule = userSpecialPermission.get('disableIftaModule');
      const disableDriverBehaviourModule = userSpecialPermission.get('disableDriverBehaviourModule');

      if (disableMapModule) {
        newState.tabStates.map = false;
      }
      if (disableDriversModule) {
        newState.tabStates.drivers = false;
      }
      if (disableDispatchModule) {
        newState.tabStates.dispatch = false;
      }
      if (disableEquipmentModule) {
        newState.tabStates.equipment = false;
      }
      if (disableSafetyModule) {
        newState.tabStates.safety = false;
      }
      if (disableIftaModule) {
        newState.tabStates.ifta = false;
      }
      if (disableDriverBehaviourModule) {
        newState.tabStates.driverbehaviour = false;
      }
    }

    this.setState(newState);
  }

  changeRoute(route) {
    const { location } = this.props;
    if (location.pathname !== route && location.pathname !== `/${route}`) {
      history.push(`${route}`);
    }
  }

  toggleWalkthroughState(walkthroughStateAttribute) {
    const newState = { ...this.state };
    newState.walkthroughState = { ...this.state.walkthroughState };
    newState.walkthroughState[walkthroughStateAttribute] = !this.state.walkthroughState[walkthroughStateAttribute];
    this.setState(newState);
  }

  render() {
    const { Company, Subscription, User, location } = this.props;
    const { tabStates } = this.state;

    const user = (User.user && User.user.id) && User.user;
    const company = (Company && Company.company.id) && Company.company;
    const subscription = (Subscription && Subscription.subscription) && Subscription.subscription;
    const address = company && company.get('address');

    return (
      <div className="header-container">
        <AlertBar />
        <UserHeaderBar user={user} company={company} subscription={subscription} />
        <FeatureHeaderBar company={company} tabStates={tabStates} location={location} />
        {/* <HeaderNotificationBox title="Missing Important Information" address={address} /> */}
      </div>
    );
  }
}

Header.propTypes = {
  Company: PropTypes.object.isRequired,
  User: PropTypes.object.isRequired,
  Subscription: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { Company, Subscription, User } = state;
  return {
    Company,
    Subscription,
    User
  };
};

export default withRouter(connect(mapStateToProps, null, null, { context: StoreContext })(Header));
