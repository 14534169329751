import React from 'react';
import PropTypes from 'prop-types';
import { MDBBtn, MDBContainer } from 'mdbreact';

// Enums
import ErrorTypes from 'enums/IFTARoute';

// Components
import ErrorMessagesModal from 'components/IFTARoute/view/ErrorMessagesModal';

class ErrorMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessagesModal: {
        show: false,
      },
    };

    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.generateErrorMessageBody = this.generateErrorMessageBody.bind(this);
  }

  showModal() {
    this.state.errorMessagesModal.show = true;
    this.setState({ ...this.state });
  }

  closeModal() {
    this.state.errorMessagesModal.show = false;
    this.setState({ ...this.state });
  }

  generateErrorMessageBody() {
    const { props } = this;
    let errorMessageBody = '';

    switch (props.errorType) {
      case ErrorTypes.JUMPED_MILEAGES:
        errorMessageBody = (
          <MDBContainer>
            Large odometer jumps were detected between days. This error occurs whenever a jump in mileage above 50km is detected.
            <br /><br />
            Reasons for why this problem may have occurred could be one of the following:
            <ul>
              <li>The driver may have driven the vehicle after the signing out or turning off the ELD tablet</li>
              <li>The mileages may not have been uploaded yet</li>
            </ul>
            Negative or abnormally high jump mileage values may indicate:
            <ul>
              <li>The driver may have selected the wrong vehicle</li>
              <li>A problem with the ELD device causing odometer reading issues</li>
            </ul>
            The best way to resolve this issue is to modify the existing routes containing this error and apply the jumped mileages to the affected route&apos;s odometer end reading.
            <br />
            <br />
            {/* show list of errors with edit button beside it */}
          </MDBContainer>
        );
        break;
      case ErrorTypes.UNUSUAL_ODOMETER_READING:
        errorMessageBody = (
          <MDBContainer>
            Unusual odometer readings were detected in the generated data. This error occurs when we notice an unusually large odometer difference for the day.
            <br /><br />
            Reasons for why this problem may have occurred could be one of the following:
            <ul>
              <li>The driver may have selected the wrong vehicle</li>
              <li>The ELD may have obtained incorrect odometer readings from the engine module</li>
              <li>The mileages may not have been uploaded yet</li>
            </ul>
            The best way to resolve this issue is to modify the existing routes containing this error and apply the corrected odometer start/end readings.
            <br /><br />
          </MDBContainer>
        );
        break;
      // case ErrorTypes.STATE_PROVINCE_NOT_ADJACENT:
      //   errorMessageBody = (
      //     <MDBContainer>
      //       This error occurred because we detected potential missing data. The end location of one day does not match the start location of the next day. This difference
      //       indicates that there was activity between days which was not accounted.
      //       <br /><br />
      //       Reasons for why this problem may have occurred could be one of the following:
      //       <ul>
      //         <li>The driver may have driven the vehicle after the signing out or turning off the ELD tablet</li>
      //         <li>There was a malfunction in the ELD tablet during this time</li>
      //         <li>The mileages may not have been uploaded yet</li>
      //       </ul>
      //       The best way to resolve this issue is to add new routes to account for this missing data.
      //       {/* show list of errors with add button beside it */}
      //     </MDBContainer>
      //   );
      //   break;
      case ErrorTypes.UNIDENTIFIED_DRIVING:
        // In the future, we can have a direct link that will open up the vehicle history route directly
        errorMessageBody = (
          <MDBContainer>
            No driver was detected in the generated data. Reasons for why this problem may have occurred could be one of the following:
            <br /><br />
            <ul>
              <li>The driver did not login to the ELD tablet</li>
              <li>There was unidentified driving that occurred during this period</li>
            </ul>
            The best way to resolve this issue is to modify the existing routes containing this error and assign a driver to the affected dates.
            To figure out the potential driver for this day, you can try one of the following options:
            <br /><br />
            <b>Map Module</b>
            You can use the Map Module to see the path the vehicle took during this time. This might allow you to determine the driver.
            <ol>
              <li>Navigate to the Map Module</li>
              <li>Use vehicle history and go to the affected date</li>
              <li>Using the vehicle path history, determine which driver drove the vehicle</li>
            </ol>
            <b>Utilize Previous Routes</b>
            If there are existing routes for this vehicle, you can check the driver responsible for the other routes. In many cases, the driver for this day will correspond to
            one of the previous drivers from previous routes.
            {/* show list of errors with edit button beside it */}
          </MDBContainer>
        );
        break;
      // case ErrorTypes.DAY_JUMP_WITH_MISSING_MILEAGES:
      //   errorMessageBody = (
      //     <MDBContainer>
      //       This error occurred because we detected potential missing data. The odometer end reading did not match the odometer start reading for the next available day. This odometer difference
      //       indicates that there was activity within this interval which was not accounted.
      //       <br /><br />
      //       Reasons for why this problem may have occurred could be one of the following:
      //       <ul>
      //         <li>The driver may have driven the vehicle after the signing out or turning off the ELD tablet</li>
      //         <li>The odometer values retrieved by the ELD tablet may not correspond to the correct reading</li>
      //         <li>The mileages may not have been uploaded yet</li>
      //       </ul>
      //       The best way to resolve this issue is to add new routes to account for this missing data.
      //       {/* show list of errors with add button beside it */}
      //     </MDBContainer>
      //   );
      //   break;
      case ErrorTypes.GPS_CALCULATED_MILEAGE_DIFFERENCE:
        errorMessageBody = (
          <MDBContainer>
            This warning occurred because large differences between the GPS mileage and calculated mileage values were detected for some state/provinces. This is a warning and might not necessarily
            indicate an error.
            <br /><br />
            Reasons for why this problem may have occurred could be one of the following:
            <ul>
              <li>The mileages may not have been uploaded or processed yet</li>
            </ul>
            The best way to resolve this issue is double check the values shown on the table and make sure that they make sense. If the values make sense, then you may ignore this error.
          </MDBContainer>
        );
        break;
      case ErrorTypes.TOTAL_ODOMETER_CALCULATED_DIFFERENCE:
        errorMessageBody = (
          <MDBContainer>
            This error occurred because we detected a difference between the total odometer difference and the total calculated odometer mileages. This odometer difference
            indicates that there was activity between days which was not accounted. If your total odometer difference does not match your total calculated mileage, your IFTA filing will be incorrect.
            <br /><br />
            Reasons for why this problem may have occurred could be one of the following:
            <ul>
              <li>The driver may have driven the vehicle after the signing out or turning off the ELD tablet</li>
              <li>There was unidentified driving during this interval</li>
              <li>The mileages may not have been uploaded or processed yet</li>
            </ul>
            The best way to resolve this issue is to check the generated information that is currently available for this vehicle and find any potential errors. This can be done by navigating to the Chronological View Tab.
            {/* show list of errors with add button beside it */}
          </MDBContainer>
        );
        break;
      case ErrorTypes.NO_MILEAGE_INFORMATION:
        errorMessageBody = (
          <MDBContainer>
            This error occurred because we detected no processed mileage information for this interval. Reasons for why this problem may have occurred could be one of the following:
            <br /><br />
            <ul>
              <li>The mileages may not have been uploaded or processed yet</li>
            </ul>
            The best way to resolve this issue is to come back in a few days to check if the information has been processed. If there is still no information available, and it is after the 11th day of the month,
            you can request the information to be processed in the Chronological View Tab.
            {/* give directions for how to re-process ifta routes */}
          </MDBContainer>
        );
        break;
      case ErrorTypes.MISSING_MILEAGE_INFORMATION:
        errorMessageBody = (
          <MDBContainer>
            This warning occurred because we detected a jump in odometer readings between days with different start/end locations. This problem usually indicates that there is missing route information in our system.
            <br /><br />
            Other potential reasons could be one of the following:
            <ul>
              <li>The mileages may not have been uploaded or processed yet</li>
              <li>The ELD may have been turned off for a period of time</li>
              <li>The driver may have driven the vehicle after the signing out or turning off the ELD tablet</li>
            </ul>
              There are two options to resolve this issue:
            <ul>
              <li>A new route can be added which accounts for the missing gap of information.</li>
              <li>Wait a couple of days to see if the missing data is processed in our system</li>
            </ul>
          </MDBContainer>
        );
        break;
      default:
        break;
    }

    return errorMessageBody;
  }

  render() {
    const { state, props } = this;
    const errorMessageBody = props.errorType && this.generateErrorMessageBody();

    return (
      <div style={{ display: 'inline' }}>
        <ErrorMessagesModal
          handleResolution={props.handleResolution}
          show={state.errorMessagesModal.show}
          handleClose={this.closeModal}
          errorType={props.errorType}
          errorMessageBody={errorMessageBody}
          recommendedActionString={props.recommendedActionString}
        />
        <MDBBtn
          size="sm"
          color="primary"
          onClick={this.showModal}
          style={{ width: '14em' }}
        >
          Issue Details
        </MDBBtn>
      </div>
    );
  }
}

export default ErrorMessages;
