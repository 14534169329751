import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

// API
import { addDispatchPayable } from 'api/Dispatch/DispatchPayable';
import { getDispatchJobAccountingRecords } from 'api/Dispatch/DispatchJobAccounting';

// CSAPI API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// CSAPI Enums
import { Currency } from 'sb-csapi/dist/enums/Finance/Currency';
import { Payee } from 'sb-csapi/dist/enums/Dispatch/Payee';
import { PaymentStatus } from 'sb-csapi/dist/enums/Dispatch/PaymentStatus';
import { PayableMethodType } from 'sb-csapi/dist/enums/Dispatch/PayableMethodType';
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// CSAPI sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Components
import DispatchJobAutocomplete from 'components/Dispatch/DispatchJobAutocomplete/DispatchJobAutocomplete';
import DispatchPayeeCard from 'components/Dispatch/DispatchPayeeCard/DispatchPayeeCard';
import PayableMethodTypeDropdown from 'components/Dispatch/PayableMethodTypeDropdown/PayableMethodTypeDropdown';
import PaymentTermDropdown from 'components/Dispatch/PaymentTermDropdown/PaymentTermDropdown';
import PaymentStatusDropdown from 'components/Dispatch/PaymentStatusDropdown/PaymentStatusDropdown';

// sbCore Components
import Button from 'sbCore/Button/Button';
import Dialog from 'sbCore/Dialog/Dialog';
import Divider from 'sbCore/Divider/Divider';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import InputNumber from 'sbCore/InputNumber/InputNumber';
import InputMask from 'sbCore/InputMask/InputMask';
import InputTextarea from 'sbCore/InputTextarea/InputTextarea';

// Styles
import './style.scss';

/**
 * @description A dialog that allows the creation of a DispatchPayable
 * @param {Boolean} [visible] - Determines whether the dialog is visible
 * @param {DispatchJob} dispatchJob - The selected DispatchJob
 * @param {DispatchPayee} dispatchPayee - The selected DispatchPayee
 * @param {Function} [onCancel] - Callback for when the cancel button is triggered
 * @param {Function} [onSave] - Callback when the organization is saved
 * @param {String} [className] - Custom container className
 * @param {Boolean} [isLoading] - Parent isLoading state
 *
 * @returns
 *
 * @example
 * <DispatchingDrawer visible={visible} dispatchJob={dispatchJob} dispatchPayee={dispatchPayee}/>
 */
function DispatchPayableAddDialog({ ...props }) {

  // ** useStates ** //
  const [selectedDispatchPayee, setSelectedDispatchPayee] = useState(null);
  const [payableMethodTypeInt, setPayableMethodTypeInt] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentTermInt, setPaymentTermInt] = useState(null);
  const [invoicedDateTime, setInvoicedDateTime] = useState(null);
  const [selectedDispatchJob, setSelectedDispatchJob] = useState(null);
  const [paymentStatusInt, setPaymentStatusInt] = useState(PaymentStatus.UNPAID.status);
  const [notes, setNotes] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  // ** useEffects ** //
  useEffect(() => {
    if (!props.dispatchJob) return;

    setSelectedDispatchJob(props.dispatchJob);
    setIsLoading(false);
  }, [props.dispatchJob]);

  useEffect(() => {
    if (!props.dispatchPayee) return;

    const type = getAttribute(props.dispatchPayee, 'type');
    if (type === Payee.COMPANY.type) setPayableMethodTypeInt(PayableMethodType.FLAT_FEE.type);

    setSelectedDispatchPayee(props.dispatchPayee);
  }, [props.dispatchPayee]);

  // ** Callback Functions ** //
  function onCancel() {
    if (props.onCancel) {
      props.onCancel();
    }

    setTotalAmount(0);
    setPaymentTermInt(null);
    setPayableMethodTypeInt(null);
    setPaymentStatusInt(PaymentStatus.UNPAID.status);
    setInvoicedDateTime(null);
    setNotes('');
    setIsLoading(false);
  }

  async function savePayable() {
    const dispatchJobObjectId = getAttribute(selectedDispatchJob, 'objectId', true);
    const { dispatchJobAccountings } = await getDispatchJobAccountingRecords(
      undefined,
      undefined,
      undefined,
      [new Filter(QueryRestriction.EQUAL_TO, 'dispatchJob', dispatchJobObjectId)],
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      true,
    );

    let dispatchJobAccounting;
    if (dispatchJobAccountings && dispatchJobAccountings.length > 0) {
      dispatchJobAccounting = dispatchJobAccountings[0];
    }

    let _invoicedDateTime;
    if (invoicedDateTime && checkValidDateTime(invoicedDateTime)) {
      _invoicedDateTime = moment(invoicedDateTime, 'DD-MM-YYYY').toDate();
    }

    const dispatchPayableObj = {
      status: paymentStatusInt,
      invoiceTotal: totalAmount,
      invoiceDateTime: _invoicedDateTime,
      dispatchPayee: selectedDispatchPayee,
      methodType: payableMethodTypeInt,
      notes,
      paymentTermType: paymentTermInt,
      dispatchJobAccounting,
    };

    const dispatchPayable = await addDispatchPayable(dispatchPayableObj);
    if (props.onSave) props.onSave(dispatchPayable);

    onCancel();
  }

  function checkValidDateTime(value) {
    const isValidDateTime = moment(value, 'DD-MM-YYYY', true).isValid();
    const isDateTimeContainCharacters = /[a-zA-Z]/.test(value);
    const isDateTimeEmpty = !(/\d/.test(value));
    let isDateTimeSuccess;
    if (!isValidDateTime && !isDateTimeContainCharacters && !isDateTimeEmpty) {
      // If the date time field is invalid, is fully filled in, and isn't empty, trigger an error border
      isDateTimeSuccess = false;
    } else if (isValidDateTime) {
      // Trigger a success border
      isDateTimeSuccess = true;
    }

    return isDateTimeSuccess;
  }

  // Renders the footer of the Dialog
  function renderFooter() {
    return (
      <div className="dispatch-payable-add-dialog-footer">
        <div className="text-right">
          {!isLoading && (
            <div className="inline-block mr-3">
              {cancelButton}
            </div>
          )}
          <div className="inline-block">
            {saveButton}
          </div>
        </div>
      </div>
    );
  }

  // ** Components ** //
  const payeeField = (
    <div className="payee-field">
      <InputLabel>Payee</InputLabel>
      <DispatchPayeeCard
        dispatchPayee={selectedDispatchPayee}
      />
    </div>
  );

  const paymentMethodTypeField = (
    <>
      <InputLabel>Payment Type</InputLabel>
      <PayableMethodTypeDropdown
        className="dropdown"
        payableMethodType={payableMethodTypeInt}
        onPayableMethodTypeChange={(typeInt) => setPayableMethodTypeInt(typeInt)}
        disabled={getAttribute(selectedDispatchPayee, 'type', true) === Payee.COMPANY.type}
      />
    </>
  );

  const totalAmountField = (
    <>
      <InputLabel>Total Amount</InputLabel>
      <InputNumber
        className="p-inputtext-sm w-15rem"
        value={totalAmount}
        onValueChange={(e) => setTotalAmount(e.value)}
        mode="currency"
        currency={Currency.US.short}
        locale="en-US"
        warning={!totalAmount}
        success={totalAmount}
        placeholder="$0.00"
      />
    </>
  );

  const paymentTermsField = (
    <>
      <InputLabel>Payment Terms</InputLabel>
      <PaymentTermDropdown
        className="dropdown"
        paymentTermInt={paymentTermInt}
        onPaymentTermChange={(termInt) => setPaymentTermInt(termInt)}
      />
    </>
  );

  const isDateTimeError = !checkValidDateTime(invoicedDateTime) && !(/[a-zA-Z]/.test(invoicedDateTime)) && /\d/.test(invoicedDateTime);
  const invoicedDateField = (
    <>
      <InputLabel>Invoiced Date</InputLabel>
      <InputMask
        className="p-inputtext-sm w-15rem"
        // className={`p-inputtext-sm ${isDateTimeError && 'invalid-date'}`}
        mask="99-99-9999"
        value={invoicedDateTime}
        placeholder="dd-mm-yyyy"
        slotChar="dd/mm/yyyy"
        onChange={(e) => setInvoicedDateTime(e.target.value)}
        warning={!invoicedDateTime}
        error={isDateTimeError}
        success={checkValidDateTime(invoicedDateTime)}
      />
      {
        isDateTimeError && (
          <InputLabel error>Invalid dates will not be saved</InputLabel>
        )
      }
    </>
  );

  const jobField = (
    <DispatchJobAutocomplete
      onSelectDispatchJob={(dispatchJob) => setSelectedDispatchJob(dispatchJob)}
      dispatchJob={selectedDispatchJob}
      warning={!selectedDispatchJob}
      success={selectedDispatchJob}
    />
  );

  const payableStatusField = (
    <>
      <InputLabel>Payable Status</InputLabel>
      <PaymentStatusDropdown
        className="dropdown"
        status={paymentStatusInt}
        onSelect={(statusInt) => setPaymentStatusInt(statusInt)}
      />
    </>
  );

  const notesField = (
    <>
      <InputLabel>Notes</InputLabel>
      <InputTextarea
        className="notes"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        rows={6}
        cols={30}
      />
    </>
  );

  const saveButton = (
    <Button
      className="p-button-sm p-button-info"
      label={!isLoading ? 'Save' : 'Saving...'}
      onClick={async () => {
        setIsLoading(true);
        await savePayable();
      }}
      disabled={isLoading || !selectedDispatchPayee || !selectedDispatchJob}
    />
  );

  const cancelButton = (
    <Button
      className="p-button-sm p-button-default"
      label="Cancel"
      onClick={() => onCancel()}
      disabled={isLoading}
    />
  );

  // ** Misc ** //
  let className = 'dispatch-payable-add-dialog pl-2';
  if (props.className) className += ` ${props.className}`;

  return (
    <Dialog
      className={className}
      header="Add Payable"
      visible={props.visible}
      footer={renderFooter()}
      onHide={() => onCancel()}
      closable={false}
      style={{ width: '65vw' }}
    >

      <div className={className}>

        <div className="grid">

          <div className="col-4">
            {payeeField}
          </div>

          <div className="col-4">
            {paymentMethodTypeField}
          </div>

          <div className="col-4">
            {totalAmountField}
          </div>

        </div>

        <Divider />

        <div className="grid">

          <div className="col-4">
            {paymentTermsField}
          </div>

          <div className="col-4">
            {invoicedDateField}
          </div>

          <div className="col-4">
            {jobField}
          </div>

          <div className="col-4">
            {payableStatusField}
          </div>

          <div className="col-8">
            {notesField}
          </div>

        </div>

      </div>

    </Dialog>
  );
}

export default DispatchPayableAddDialog;
