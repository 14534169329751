/* global StripeCheckout */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { Modal } from 'react-bootstrap';
import Dialog from 'sbCore/Dialog/Dialog';

import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import Button from 'sbCore/Button/Button';

import styles from './styles.module.scss';

// https://stripe.com/docs/payments/card-element?client=react
function StripeElementsCheckoutModal(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const cardStyle = {
    style: {
      theme: 'stripe',
      base: {
        color: '#32325d',
        fontFamily: 'Open Sans, Roboto, sans-serif',
        fontSmoothing: 'antialiased',
        fontWeight: '800',
        fontSize: '18px',
        '::placeholder': {
          color: '#32325d'
        }
      },
      invalid: {
        fontFamily: 'Open Sans, Roboto, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const payload = await stripe.createToken(elements.getElement(CardElement));
      if (payload.token) {
        await props.handleStripeToken(payload.token);
        setLoading(false);
      } else if (payload.error) {
        setLoading(false);
        setError(payload?.error?.message || payload?.error);
      }
    } catch (e) {
      console.log(e);
    }
  }


  return (
    <Dialog
      // className={className}
      visible={props.visible}
      header="Payment Information"
      // footer={renderFooter()}
      onHide={() => props.cancel()}
      closable
      style={{ width: '50vw' }}
    >
      <CardElement
        id="card-element"
        options={cardStyle}
      />
      <div className="errorText">
        {error}
        <br />
      </div>
      {!isLoading &&
        <div>
          <Button icon="pi pi-times" label="Cancel" disabled={isLoading} onClick={() => props.cancel()} />
          &nbsp;
          <Button icon="pi pi-check" label="Save Card" disabled={isLoading} onClick={() => handleSubmit()} />

          {/* <button onClick={() => handleSubmit()}>Save CC</button>
          <button onClick={() => props.cancel()}>Cancel</button> */}
        </div>
      }
      {isLoading &&
        <LoadingIcon />
      }
    </Dialog>

  );
}

StripeElementsCheckoutModal.propTypes = {
  Subscription: PropTypes.object.isRequired,
  handleStripeToken: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

export default StripeElementsCheckoutModal;
