/**
 *
 * @param {string} objectId
 * @param {string || object} dispatchJob - could also be the objectId of job
 * @param {string} name
 * @param {number} depthCm
 * @param {number} heightCm
 * @param {number} widthCm
 * @param {number} weightKg
 * @param {number} quantity
 * @param {string} notes
 * @param {bool} isHidden
 */
// export default class DispatchItem {
//   constructor(objectId, dispatchJob, name, depthCm, heightCm, widthCm, weightKg, quantity, notes, isHidden = false) {
//     this.objectId = objectId;
//     this.name = name;
//     this.notes = notes;
//     this.depthCm = depthCm;
//     this.heightCm = heightCm;
//     this.widthCm = widthCm;
//     this.weightKg = weightKg;
//     this.dispatchJob = dispatchJob;
//     this.quantity = quantity;
//     this.isHidden = isHidden;
//   }
// }

export default class DispatchItem {
  constructor(objectId, dispatchJob, dispatchTransfer, quantity, category, weight, massUnit, itemLength, width, height, lengthUnit,
    name, commodityTypeInt, commodityTypeCustomName) {
    this.objectId = objectId;
    this.dispatchJob = dispatchJob;
    this.dispatchTransfer = dispatchTransfer;
    this.quantity = quantity;
    this.category = category;
    this.weight = weight;
    this.massUnit = massUnit;
    this.itemLength = itemLength;
    this.width = width;
    this.height = height;
    this.lengthUnit = lengthUnit;
    this.name = name;
    this.commodityType = commodityTypeInt;
    this.commodityTypeCustomName = commodityTypeCustomName;
  }
}
