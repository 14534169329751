import React from 'react';
import PropTypes from 'prop-types';

import { Table } from 'react-bootstrap';

// API
import * as ELD from 'api/ELD';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import AssignUnassignedToDriver from './AssignUnassignedToDriver';

// CSS
import styles from './UnidentifiedDriversTable.module.scss';

class UnidentifiedDriversTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      unidentifiedDrivers: [],
    };
    this.getUnidentifiedDrivers = this.getUnidentifiedDrivers.bind(this);
    this.updateHandler = this.updateHandler.bind(this);
  }

  componentDidMount() {
    this.getUnidentifiedDrivers();
  }

  getUnidentifiedDrivers() {
    this.setState({ ...this.state, isLoading: true }, () => {
      ELD.getUnidentifiedDrivers().then(
        unidentifiedDrivers => { // unidentified driver objects
          this.setState({ ...this.state, isLoading: false, unidentifiedDrivers });
        }
      );
    });
  }

  updateHandler() {
    this.getUnidentifiedDrivers();
  }

  render() {
    const { unidentifiedDrivers } = this.state;

    let unidentifiedDriverRows = unidentifiedDrivers.map(unidentifiedObject => {
      const vehicle = unidentifiedObject.vehicle;
      return (
        <tr key={vehicle.id}>
          <td>{ vehicle.get('unitId') }</td>
          <td>{ vehicle.get('vin') }</td>
          <td>
            <AssignUnassignedToDriver
              timezoneOffsetFromUTC={(this.props.dispatcher && this.props.dispatcher.id && this.props.dispatcher.get('timezoneOffsetFromUTC')) || ''}
              unidentifiedObject={unidentifiedObject}
              updateHandler={this.updateHandler}
              className="floatRight"
            />
          </td>
        </tr>
      );
    });

    if (!this.state.isLoading && unidentifiedDriverRows.length === 0) {
      unidentifiedDriverRows = (
        <tr>
          <td colSpan="3" className="emptyRowMessage">No Unidentified Drivers to Display</td>
        </tr>
      );
    }

    return (
      <div className="defaultTable">
        <Table responsive hover>
          <thead>
            <tr>
              <th width="30%">Vehicle Unit</th>
              <th width="30%">VIN</th>
              <th width="40%" />
            </tr>
          </thead>
          <tbody>
            { unidentifiedDriverRows }
            { this.state.isLoading &&
              <tr className={styles.loadingIconNoHover}>
                <td colSpan="3" className="loadingIconPadding"><LoadingIcon /></td>
              </tr>
            }
          </tbody>
        </Table>
      </div>
    );


        // this.state.driverRows = this.drivers.map(driver => {
        //   const driverUser = driver.get('user');
        //   const username = driverUser.get('username');
        //   const fullName = `${driverUser.get('firstName')} ${driverUser.get('lastName')}`;
        //   const email = driver.get('user_email');
        //   const phoneNumber = driverUser.get('phoneNumber');
        //   let isActive = false;
        //   const status = driver.get('driverStatus');
        //   if (status && status.get('referenceInt') === 2) {
        //     isActive = true;
        //   }
        //   const driverRow = (
        //     <tr key={`${driver.id}${keyCounter}`} className={styles.driverRow}>
        //       <td>
        //         <StatusIcon active={isActive} />
        //       </td>
        //       <td>
        //         {username}
        //       </td>
        //       <td>{fullName}</td>
        //       <td>{email}</td>
        //       <td>{phoneNumber}</td>
        //       <td className="centerText">
        //         <button
        //           className="floatRight buttonDefault"
        //           onClick={() => history.push({ pathname: 'driver', query: { driver: driver.id } })}
        //         >
        //           View Details
        //         </button>
        //       </td>
        //     </tr>
        //   );
        //
        //   return driverRow; // defaukt
        // });
  }
}

UnidentifiedDriversTable.propTypes = {
  dispatcher: PropTypes.object.isRequired,
};

export default UnidentifiedDriversTable;
