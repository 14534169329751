import React from 'react';

// Components
import { Card as PRCard } from 'primereact/card';

// Styling
import './style.scss';

export default function Card({ ...props }) {
  let className = "sbc-card";
  if (props.className) className += ` ${props.className}`;

  return (
    <div ref={props.cardRef} className={className}>
      <PRCard {...props} />
    </div>
  );
}