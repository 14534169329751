/**
 * @param {record} dispatchJob
 * @param {record} dispatchTransfer
 * @param {string} value
 * @param {number} type
 * @param {string} customName
 */
export default class DispatchReferenceNumber {
  constructor(dispatchJob, dispatchTransfer, value, type, customName) {
    this.dispatchJob = dispatchJob;
    this.dispatchTransfer = dispatchTransfer;
    this.value = value;
    this.type = type;
    this.customName = customName;
  }
}
