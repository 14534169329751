import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// API
import * as Helpers from 'api/Helpers';
import { getAttribute } from 'api/Parse';
import { StateProvinces } from 'api/Lists/StateProvinces';
import { findTotalMileageIssues, getFuelCardData } from 'api/IFTARoute/IFTARoute.old';

// Components
import OdometerReadingsTableBeta from 'components/IFTARoute/container/OdometerReadingsTableBeta';
import SBTable from 'components/Shared/SBTable/SBTable';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import FuelHelpDialog from 'components/IFTARoute/view/FuelHelpDialog';
import ErrorMessages from 'components/IFTARoute/container/ErrorMessages';
import SBBlock from 'components/Shared/SBBlock/SBBlock';

// Enums
import ErrorTypes from 'enums/IFTARoute';

class VehicleSummaryViewBeta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errorMessages: [],
      tableBodyRows: [],
    };

    this.generateTableBodyRowData = this.generateTableBodyRowData.bind(this);
  }

  componentDidUpdate(nextProps) {
    if
      (nextProps.iftaRouteArr.length !== this.props.iftaRouteArr.length
      || nextProps.odometerReadings !== this.props.odometerReadings
      || nextProps.distanceUnitFilter.value !== this.props.distanceUnitFilter.value
      || ((nextProps.volumeUnitFilter && this.props.volumeUnitFilter) && (nextProps.volumeUnitFilter.value !== this.props.volumeUnitFilter.value))
      || nextProps.selectedFilter.value !== this.props.selectedFilter.value
    ) this.generateTableBodyRowData();
    return false;
  }

  handleScroll(e, anchorTarget) {
    // We need to grab the row element, hence we retrieve the grandparent element
    const element = document.getElementById(anchorTarget).parentElement.parentElement;
    const hasGrayBg = element.classList.contains('bg-gray-hover');

    e.preventDefault();
    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

    // Toggle the classes to temporarily remove styling (workaround due to the classes using !important)
    element.classList.toggle('error-alert');
    hasGrayBg && element.classList.toggle('bg-gray-hover');
    element.classList.toggle('hover-gray-matte');

    setTimeout(() => {
      element.classList.toggle('error-alert');
      hasGrayBg && element.classList.toggle('bg-gray-hover');
      element.classList.toggle('hover-gray-matte');
    }, 3000);
  }

  async generateTableBodyRowData() {
    this.setState({ ...this.state, isLoading: true });
    const { props } = this;

    const iftaFuelCardDataHash = await getFuelCardData(props.iftaRouteArr.map((iftaRoute) => getAttribute(iftaRoute, 'objectId')), props.routeToNewIFTA);
    const stateProvinceCodes = StateProvinces.map((stateProvince) => stateProvince.code).filter((stateProvinceCode) => stateProvinceCode);
    const stateProvinceDataArr = [];
    const iftaArrMiles = {}; // Contains the summed totals for each state/province

    const totalFuelPurchases = {};
    let totalFuelTotalPaidCAD = 0;
    let totalFuelTotalPaidUSD = 0;

    const totalFuelCardData = {};
    let totalFuelCardPaidCAD = 0;
    let totalFuelCardPaidUSD = 0;

    let totalDistanceKm = 0;
    let totalSavedVehicleKm = 0;
    let totalSpreadKm = 0;

    // Categorize the IFTARoutes into state/provinces and combines their information
    for (let i = 0; i < props.iftaRouteArr.length; i++) {
      const iftaRoute = props.iftaRouteArr[i];
      const stateProvince = getAttribute(iftaRoute, 'stateProvince') && getAttribute(iftaRoute, 'stateProvince').toUpperCase();
      const distanceKm = getAttribute(iftaRoute, 'distanceKm') || 0;
      const totalVehicleKmDiff = getAttribute(iftaRoute, 'totalVehicleKmDiff') || 0;
      const savedVehicleKm = getAttribute(iftaRoute, 'savedVehicleKm') || 0;
      const fuelPurchases = getAttribute(iftaRoute, 'fuelPurchases');

      totalSavedVehicleKm += savedVehicleKm;
      totalDistanceKm += distanceKm;

      if (iftaArrMiles[stateProvince]) {
        iftaArrMiles[stateProvince].distanceKm += distanceKm;
        iftaArrMiles[stateProvince].totalVehicleKmDiff += totalVehicleKmDiff;
        iftaArrMiles[stateProvince].savedVehicleKm += savedVehicleKm;
      } else {
        iftaArrMiles[stateProvince] = {};
        iftaArrMiles[stateProvince].distanceKm = distanceKm;
        iftaArrMiles[stateProvince].totalVehicleKmDiff = totalVehicleKmDiff;
        iftaArrMiles[stateProvince].savedVehicleKm = savedVehicleKm;
        iftaArrMiles[stateProvince].fuelPurchaseSubtotals = {};
        iftaArrMiles[stateProvince].fuelCardSubtotals = {};
        iftaArrMiles[stateProvince].fuelTotalPaidCAD = 0;
        iftaArrMiles[stateProvince].fuelTotalPaidUSD = 0;
        iftaArrMiles[stateProvince].fuelCardTotalPaidCAD = 0;
        iftaArrMiles[stateProvince].fuelCardTotalPaidUSD = 0;
      }

      // Fuel Purchases
      if (fuelPurchases) {
        for (let j = 0; j < fuelPurchases.length; j++) {
          const fuelPurchase = fuelPurchases[j];
          const fuelType = getAttribute(fuelPurchase, 'fuelType') && getAttribute(fuelPurchase, 'fuelType').toUpperCase();
          const totalPaid = getAttribute(fuelPurchase, 'totalPaid');
          const currency = getAttribute(fuelPurchase, 'currency');

          const volumeUnits = getAttribute(fuelPurchase, 'volumeUnits');
          const volumePumpedGallons = (volumeUnits === 'l') ? Helpers.convertFuelUnit(getAttribute(fuelPurchase, 'volumePumped'), 'l', 'gal') : getAttribute(fuelPurchase, 'volumePumped');

          if (iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType]) {
            iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType] += volumePumpedGallons;
          } else {
            iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType] = volumePumpedGallons;
          }

          if (totalFuelPurchases[fuelType]) {
            totalFuelPurchases[fuelType] += volumePumpedGallons;
          } else {
            totalFuelPurchases[fuelType] = volumePumpedGallons;
          }

          if (currency === 'cad') {
            iftaArrMiles[stateProvince].fuelTotalPaidCAD += totalPaid;
            totalFuelTotalPaidCAD += totalPaid;
          } else if (currency === 'usd') {
            iftaArrMiles[stateProvince].fuelTotalPaidUSD += totalPaid;
            totalFuelTotalPaidUSD += totalPaid;
          }
        }
      }

      // Fuel Card
      if (iftaFuelCardDataHash && iftaFuelCardDataHash[getAttribute(iftaRoute, 'objectId')]) {
        const fuelCardDataArr = iftaFuelCardDataHash[getAttribute(iftaRoute, 'objectId')];

        for (let j = 0; j < fuelCardDataArr.length; j++) {
          const fuelCardData = fuelCardDataArr[j];
          const fuelQuantity = getAttribute(fuelCardData, 'fuelQuantity');
          const currency = getAttribute(fuelCardData, 'currency');
          const fuelMeasurementUnit = getAttribute(fuelCardData, 'fuelMeasurementUnit');
          const total = getAttribute(fuelCardData, 'total');
          const item = getAttribute(fuelCardData, 'item');
          const stateProvinceAbbrv = getAttribute(fuelCardData, 'stateProvinceAbbrv') && getAttribute(fuelCardData, 'stateProvinceAbbrv').toUpperCase();

          if (iftaArrMiles[stateProvinceAbbrv].fuelCardSubtotals[item]) {
            iftaArrMiles[stateProvinceAbbrv].fuelCardSubtotals[item] += (fuelMeasurementUnit?.toLowerCase() === 'l') ? Helpers.convertFuelUnit(fuelQuantity, 'l', 'gal') : fuelQuantity;
          } else {
            iftaArrMiles[stateProvinceAbbrv].fuelCardSubtotals[item] = (fuelMeasurementUnit?.toLowerCase() === 'l') ? Helpers.convertFuelUnit(fuelQuantity, 'l', 'gal') : fuelQuantity;
          }

          if (totalFuelCardData[item]) {
            totalFuelCardData[item] += (fuelMeasurementUnit?.toLowerCase() === 'l') ? Helpers.convertFuelUnit(fuelQuantity, 'l', 'gal') : fuelQuantity;
          } else {
            totalFuelCardData[item] = (fuelMeasurementUnit?.toLowerCase() === 'l') ? Helpers.convertFuelUnit(fuelQuantity, 'l', 'gal') : fuelQuantity;
          }

          if (currency && currency.toUpperCase() === 'CAD') {
            iftaArrMiles[stateProvinceAbbrv].fuelCardTotalPaidCAD += total;
            totalFuelCardPaidCAD += total;
          } else if (currency && currency.toUpperCase() === 'USD') {
            iftaArrMiles[stateProvinceAbbrv].fuelCardTotalPaidUSD += total;
            totalFuelCardPaidUSD += total;
          }
        }
      }
    }

    // This is for deciding whether to spread the calculated Vehicle Km
    // Should Use SpreadKm when the odometerDifference is not negative or way too large!
    // Otherwise will just use calculatedOdometerKm
    let shouldUseSpreadKm = false;
    const { odometerDiffKm } = props.odometerReadings || 0;

    if (odometerDiffKm > 0
      && totalSavedVehicleKm > 0
      && (odometerDiffKm < (moment(props.dateEnd).diff(moment(props.dateStart), 'hours') * 200))
      && (totalSavedVehicleKm < (moment(props.dateEnd).diff(moment(props.dateStart), 'hours') * 200))
      && (Math.abs(totalSavedVehicleKm - odometerDiffKm) < (5000 * (moment(props.dateEnd).diff(moment(props.dateStart), 'months') + 1)))
    ) {
      shouldUseSpreadKm = true;
    }

    for (let i = 0; i < stateProvinceCodes.length; i++) {
      const stateProvince = stateProvinceCodes[i];
      const subtotalSavedVehicleKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].savedVehicleKm ? Math.round(iftaArrMiles[stateProvince].savedVehicleKm * 100) / 100 : 0;
      const subtotalTotalVehicleKmDiff = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].totalVehicleKmDiff ? Math.round(iftaArrMiles[stateProvince].totalVehicleKmDiff * 100) / 100 : 0;
      const subtotalDistanceKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].distanceKm ? Math.round(iftaArrMiles[stateProvince].distanceKm * 100) / 100 : 0;
      const fuelPurchaseSubtotals = iftaArrMiles[stateProvince] ? iftaArrMiles[stateProvince].fuelPurchaseSubtotals : {};
      const fuelCardSubtotals = iftaArrMiles[stateProvince] ? iftaArrMiles[stateProvince].fuelCardSubtotals : {};

      const subtotalSpreadKm = Math.round(totalSavedVehicleKm) !== 0 ? totalSavedVehicleKm && totalSavedVehicleKm && odometerDiffKm && Math.round((subtotalSavedVehicleKm / Math.round(totalSavedVehicleKm)) * odometerDiffKm * 100) / 100 : subtotalSavedVehicleKm;
      totalSpreadKm += subtotalSpreadKm;
      const stateProvinceDataObj = {
        code: stateProvince,
        subtotalSavedVehicleKm,
        subtotalTotalVehicleKmDiff,
        subtotalDistanceKm,
        fuelPurchaseSubtotals,
        fuelCardSubtotals,
        subtotalSpreadKm: shouldUseSpreadKm ? subtotalSpreadKm : subtotalSavedVehicleKm,
      };
      stateProvinceDataArr.push(stateProvinceDataObj);
    }

    const gpsMileageArray = stateProvinceDataArr.map((stateProvinceData) => {
      const stateProvince = stateProvinceData.code;
      const { subtotalDistanceKm } = stateProvinceData;
      return { stateProvince, subtotalDistanceKm };
    });

    const calculatedMileageArray = stateProvinceDataArr.map((stateProvinceData) => {
      const stateProvince = stateProvinceData.code;
      const subtotalCalculatedKm = shouldUseSpreadKm ? stateProvinceData.subtotalSpreadKm : stateProvinceData.subtotalSavedVehicleKm;
      return { stateProvince, subtotalCalculatedKm };
    });

    const totalCalculatedMileage = shouldUseSpreadKm ? totalSpreadKm : totalSavedVehicleKm;

    const tableBodyRows = stateProvinceDataArr.filter((stateProvinceData) => {
      if (props.selectedFilter.value === 'hideZeroMileages') return stateProvinceData.subtotalSavedVehicleKm;
      return true;
    }).map(stateProvinceData => {
      const rowId = `${props.unitId}-${stateProvinceData.code}`;

      const vehicleRowObj = {
        key: rowId,
        columns: [
          {
            element: (
              <div id={rowId}>
                {stateProvinceData.code}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          // {
          //   element: (
          //     <div>
          //       {stateProvinceData.subtotalDistanceKm ?
          //         (props.distanceUnitFilter.value === 'km' ?
          //           stateProvinceData.subtotalDistanceKm.toFixed(0) :
          //           Helpers.convertDistance(stateProvinceData.subtotalDistanceKm, 'km', 'mi', true).toFixed(0)
          //         )
          //         : 0
          //       }
          //     </div>
          //   ),
          //   props: { className: 'table-body-column-style' },
          // },
          {
            element: (
              <div>
                {Object.keys(stateProvinceData.fuelPurchaseSubtotals).map((fuelPurchaseSubtotalType) => {
                  if (props.volumeUnitFilter && (props.volumeUnitFilter.value === 'gal')) {
                    // already gallons
                    return (
                      <div>
                        <b>{fuelPurchaseSubtotalType}</b>: {stateProvinceData.fuelPurchaseSubtotals[fuelPurchaseSubtotalType].toFixed(2)}
                      </div>
                    );
                  } else if (props.volumeUnitFilter && (props.volumeUnitFilter.value === 'l')) {
                    return (
                      <div>
                        <b>{fuelPurchaseSubtotalType}</b>: {Helpers.convertFuelUnit(stateProvinceData.fuelPurchaseSubtotals[fuelPurchaseSubtotalType], 'gal', 'l').toFixed(2)}
                      </div>
                    );
                  }
                })}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          {
            element: (
              <div>
                {Object.keys(stateProvinceData.fuelCardSubtotals).map((fuelCardSubtotalType) => {
                  if (props.volumeUnitFilter && (props.volumeUnitFilter.value === 'gal')) {
                    // already gallons
                    return (
                      <div>
                        <b>{fuelCardSubtotalType}</b>: {stateProvinceData.fuelCardSubtotals[fuelCardSubtotalType].toFixed(2)}
                      </div>
                    );
                  } else if (props.volumeUnitFilter && (props.volumeUnitFilter.value === 'l')) {
                    return (
                      <div>
                        <b>{fuelCardSubtotalType}</b>: {Helpers.convertFuelUnit(stateProvinceData.fuelCardSubtotals[fuelCardSubtotalType], 'gal', 'l').toFixed(2)}
                      </div>
                    );
                  }
                })}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          {
            element: (
              <div>
                <b>
                  {stateProvinceData.subtotalSpreadKm ? (props.distanceUnitFilter.value === 'km' ? stateProvinceData.subtotalSpreadKm.toFixed(0) : Helpers.convertDistance(stateProvinceData.subtotalSpreadKm, 'km', 'mi', true).toFixed(0)) : 0}
                </b>
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
        ],
      };

      return vehicleRowObj;
    });

    tableBodyRows.push({
      key: 'total',
      columns: [
        {
          element: (
            <div>
              <b>Total</b>
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        // {
        //   element: (
        //     <div>
        //       <b>{props.distanceUnitFilter.value === 'km' ? totalDistanceKm.toFixed(0) : Helpers.convertDistance(totalDistanceKm, 'km', 'mi', true).toFixed(0)}</b>
        //     </div>
        //   ),
        //   props: { className: 'table-body-column-style' },
        // },
        {
          element: (
            <div>
              {Object.keys(totalFuelPurchases).map((totalFuelPurchaseType) => {
                if (props.volumeUnitFilter && (props.volumeUnitFilter.value === 'gal')) {
                  // already gallons
                  return (
                    <div>
                      <b>{totalFuelPurchaseType}</b>: {totalFuelPurchases[totalFuelPurchaseType].toFixed(2)}
                    </div>
                  );
                } else if (props.volumeUnitFilter && (props.volumeUnitFilter.value === 'l')) {
                  return (
                    <div>
                      <b>{totalFuelPurchaseType}</b>: {Helpers.convertFuelUnit(totalFuelPurchases[totalFuelPurchaseType], 'gal', 'l').toFixed(2)}
                    </div>
                  );
                }
              })}
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        {
          element: (
            <div>
              {Object.keys(totalFuelCardData).map((totalFuelCardType) => {
                if (props.volumeUnitFilter && (props.volumeUnitFilter.value === 'gal')) {
                  // already gallons
                  return (
                    <div>
                      <b>{totalFuelCardType}</b>: {totalFuelCardData[totalFuelCardType].toFixed(2)}
                    </div>
                  );
                } else if (props.volumeUnitFilter && (props.volumeUnitFilter.value === 'l')) {
                  return (
                    <div>
                      <b>{totalFuelCardType}</b>: {Helpers.convertFuelUnit(totalFuelCardData[totalFuelCardType], 'gal', 'l').toFixed(2)}
                    </div>
                  );
                }
              })}
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        {
          element: (
            <div>
              <b>
                {shouldUseSpreadKm ?
                  (odometerDiffKm && props.distanceUnitFilter.value === 'km' ?
                    odometerDiffKm.toFixed(0) : Helpers.convertDistance(odometerDiffKm, 'km', 'mi', true).toFixed(0)
                  )
                  :
                  (props.distanceUnitFilter.value === 'km' ? totalSavedVehicleKm.toFixed(0) : Helpers.convertDistance(totalSavedVehicleKm, 'km', 'mi', true).toFixed(0))
                }
              </b>
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
      ],
    });

    const iftaRouteIssuesObject = findTotalMileageIssues(gpsMileageArray, calculatedMileageArray, totalCalculatedMileage, odometerDiffKm || 0);
    const errorMessages = this.generateErrorMessages(totalSavedVehicleKm, totalSpreadKm, odometerDiffKm, iftaRouteIssuesObject, shouldUseSpreadKm);

    this.setState({ ...this.state, tableBodyRows, errorMessages, isLoading: false });
  }

  generateErrorMessages(totalSavedKm, totalSpreadKm, odometerDiff, iftaRouteIssuesObject, shouldUseSpreadKm) {
    const { props } = this;
    const errorMessages = [];

    const generateStateProvinceLinks = (stateProvinceIssuesArray) => {
      return (stateProvinceIssuesArray.map((issue, index) => {
        const { stateProvince } = issue;
        const rowId = `${props.unitId}-${stateProvince}`;
        if (index === stateProvinceIssuesArray.length - 1) {
          return (
            <React.Fragment>
              <a
                href={`#${rowId}`}
                onClick={(e) => this.handleScroll(e, rowId)}
              >
                {stateProvince}
              </a>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <a
                href={`#${rowId}`}
                onClick={(e) => this.handleScroll(e, rowId)}
              >
                {stateProvince}
              </a>
              {', '}
            </React.Fragment>
          );
        }
      }));
    };

    if ((totalSavedKm === 0 || totalSpreadKm === 0) && odometerDiff > 0) {
      errorMessages.push(
        <div style={{ fontSize: '1em' }}>
          <ErrorMessages
            errorType={ErrorTypes.NO_MILEAGE_INFORMATION}
          />
          The mileage information may not have been uploaded yet.
        </div>,
      );
    }
    if (iftaRouteIssuesObject.gpsMileageAndCalculatedMileageOutsideThreshold || iftaRouteIssuesObject.isOdometerDifferenceAndTotalMileageDifferent) {
      // if (iftaRouteIssuesObject.gpsMileageAndCalculatedMileageOutsideThreshold.length > 0) {
      //   errorMessages.push(
      //     <div className="error-message" style={{ fontSize: '1em' }}>
      //       <ErrorMessages
      //         errorType={ErrorTypes.GPS_CALCULATED_MILEAGE_DIFFERENCE}
      //       />
      //       {'There may be mileage errors associated with the following state/provinces: '}
      //       {generateStateProvinceLinks(iftaRouteIssuesObject.gpsMileageAndCalculatedMileageOutsideThreshold)}
      //     </div>,
      //   );
      // }
      if (iftaRouteIssuesObject.isOdometerDifferenceAndTotalMileageDifferent) {
        errorMessages.push(
          <div style={{ fontSize: '1em' }}>
            <ErrorMessages
              errorType={ErrorTypes.TOTAL_ODOMETER_CALCULATED_DIFFERENCE}
            />
            The total odometer difference <b>{(odometerDiff && props.distanceUnitFilter.value === 'km' ? odometerDiff.toFixed(0) : Helpers.convertDistance(odometerDiff, 'km', 'mi', true).toFixed(0))}</b> does not match the total calculated mileage
            <b> {
              shouldUseSpreadKm ?
                (odometerDiff && props.distanceUnitFilter.value === 'km' ? odometerDiff.toFixed(0) : Helpers.convertDistance(odometerDiff, 'km', 'mi', true).toFixed(0)) :
                (props.distanceUnitFilter.value === 'km' ? totalSavedKm.toFixed(0) : Helpers.convertDistance(totalSavedKm, 'km', 'mi', true).toFixed(0))
            }
            </b>
          </div>,
        );
      }
    }

    return errorMessages;
  }


  render() {
    const { props, state } = this;
    const showOdometerValues = props.showOdometerValuesFilter.value === 'showOdometerValues';

    const tableHeaderStyles = {
      stateProvince: { width: '20%' },
      gps: { width: '20%' },
      fuel: { width: '20%' },
      saved: { width: '20%' },
    };

    const tableHeaderRows = [{
      key: 'sb-ifta-vehicle-summary-headers',
      columns: [
        {
          element: <div>State / Province</div>,
          props: { style: tableHeaderStyles.stateProvince },
        },
        // {
        //   element: <div>{`GPS Mileage (${props.distanceUnitFilter.value})`}</div>,
        //   props: { style: tableHeaderStyles.gps },
        // },
        {
          element: (
            <div className="centerText">
              {`Fuel Purchases ${props.volumeUnitFilter ? `(${props.volumeUnitFilter.value})` : ''}`}
              <div className="print-hide">
                <FuelHelpDialog />
              </div>
            </div>
          ),
          props: { style: tableHeaderStyles.fuel },
        },
        {
          element: <div className="centerText">{`Fuel Card Transactions ${props.volumeUnitFilter ? `(${props.volumeUnitFilter.value})` : ''}`}</div>,
          props: { style: tableHeaderStyles.fuel },
        },
        {
          element: <div>{`Calculated Mileage (${props.distanceUnitFilter.value})`}</div>,
          props: { style: tableHeaderStyles.saved },
        },
      ],
    }];

    // const { tableBodyRows, errorMessages } = this.generateTableBodyRowData();
    // console.log(props.displayStartDate);
    return (
      <div>
        {state.errorMessages && state.errorMessages.length > 0 && (
          <SBBlock
            warning
            title="Notice"
            messages={state.errorMessages}
            listMessages={false}
          />
        )}
        {this.props.odometerReadingsDisplayingZero === true && (
          <SBBlock
            error
            listMessages={false}
            title="Note"
            messages={[
              <div style={{ fontSize: '1em' }}>
                It is detected that most or all of the routes are not displaying odometer readings. There might be connection issues with the engine module.
              </div>
            ]}
          />
        )}
        {showOdometerValues && (
          <React.Fragment>
            <div>
              <h5>
                Unit {props.unitId}: Start / End Odometer Readings
                <br />
                {`${moment(props.displayStartDate).format('LL')} - ${moment(props.displayEndDate).format('LL')}`}
              </h5>
            </div>
            <OdometerReadingsTableBeta
              distanceUnitFilter={props.distanceUnitFilter}
              odometerReadings={props.odometerReadings}
            />
          </React.Fragment>
        )}
        <div>
          <h5>
            Unit {props.unitId}: IFTA Mileages
            <br />
            {`${moment(props.displayStartDate).format('LL')} - ${moment(props.displayEndDate).format('LL')}`}
          </h5>
        </div>
        <SBTable
          hover
          isLoading={state.isLoading}
          tableHeaderRows={tableHeaderRows}
          tableBodyRows={state.tableBodyRows}
        />
      </div>
    );
  }
}

VehicleSummaryViewBeta.propTypes = {
  distanceUnitFilter: PropTypes.object,
  odometerReadings: PropTypes.object,
  selectedFilter: PropTypes.object,
  unitId: PropTypes.string,
  distanceUnit: PropTypes.string,
  volumeUnitFilter: PropTypes.string,
  dateStart: PropTypes.object,
  dateEnd: PropTypes.object,
  iftaRouteArr: PropTypes.array,
  routeToNewIFTA: PropTypes.bool,
};

export default VehicleSummaryViewBeta;
