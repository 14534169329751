import React, { useState, useEffect } from 'react';

// CSAPI Enums
import { NumberOfDays } from 'sb-csapi/dist/enums/Dispatch/Invoice';

// sbCore Components
import Dropdown from 'sbCore/Dropdown/Dropdown';

// Styles
import './style.scss';

/**
 * @description A dropdown containing a list of selectable payment terms
 * @param {String} [className] - Custom className
 * @param {Number} [paymentTermInt] - The selected paymentTerm number
 * @param {Boolean} [disabled] - Whether or not to disable the dropdown
 * @param {Function} [onPaymentTermChange] - Function that updates the selected payment term of the parent's state
 * @param {Boolean} [isDownloaded] - show/hide input borders when downloading pdf
 * @param {Boolean} [includeNoPaymentTermsOption] - A boolean indicating whether this dropdown should show an option for no payment term
 */
function PaymentTermDropdown({ ...props }) {
  // Skip the first element which is zero days
  let paymentTerms = Object.keys(NumberOfDays).slice(1).map(key => NumberOfDays[key]);
  if (props.includeNoPaymentTermsOption) {
    paymentTerms = [{ value: null, description: 'No Payment Term', key: 'NONE' }, ...paymentTerms];
  }

  // ** useStates ** //
  const [selectedPaymentTermInt, setSelectedPaymentTermInt] = useState(null);

  // ** useEffects ** //
  useEffect(() => {
    setSelectedPaymentTermInt(props.paymentTermInt);
  }, [props.paymentTermInt]);

  // ** Callback Functions ** //

  // sets the selected payment term here and in the the parent component
  const onPaymentTermChange = (e) => {
    setSelectedPaymentTermInt(e.value);
    if (props.onPaymentTermChange) {
      // send the chosen payment term back up to parent
      props.onPaymentTermChange(e.value);
    }
  };

  // ** Misc ** //
  let className = 'payment-term-dropdown';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <Dropdown
        className={`${className} ${!selectedPaymentTermInt ? 'warning-dropdown' : ''} ${props.isDownloaded ? 'invisible-border' : ''}`}
        value={selectedPaymentTermInt}
        options={paymentTerms}
        onChange={onPaymentTermChange}
        optionLabel="description"
        placeholder="Select Term"
        warning={!selectedPaymentTermInt}
        success={selectedPaymentTermInt}
      />
    </div>
  );
}

export default PaymentTermDropdown;
