import React from 'react';
import ReactDatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import history from '../../../sbHistory';
import { Collapse } from 'react-bootstrap';
import { Toggle } from 'material-ui';
import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact';

// API
import { toTitleCase } from 'api/Helpers';
import { getCurrentUser, getAttribute } from 'api/Parse';

// Components
import EditVehicleButton from 'components/AddEditEquipment/container/EditVehicleButton';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import DayChooser from 'components/Map.old/view/components/DayChooser';

import styles from './ActiveVehicleDetails.module.scss';

function ActiveVehicleDetails(props) {
  function getStreetView(vehicle) {
    // http://stackoverflow.com/questions/6018060/google-maps-url-i-want-to-create-a-url-to-google-maps-i-want-to-show-search-ne
    const long = vehicle.get('vehicleLocation').get('location').longitude;
    const lat = vehicle.get('vehicleLocation').get('location').latitude;
    window.open(`https://maps.google.com/maps?q=${lat},${long}&t=h&iwd=0&z=18`);
  }

  function pathExists() {
    if (props.activeVehicleHistory && props.activeVehicleHistory.legs && props.activeVehicleHistory.legs.length > 0) {
      for (let i = 0; i < props.activeVehicleHistory.legs.length; i++) {
        if (props.activeVehicleHistory.legs[i].waypoints && props.activeVehicleHistory.legs[i].waypoints.length > 0) return true;
      }
    }
    return false;
  }

  const currentUser = getCurrentUser();
  const userSpecialPermission = getAttribute(currentUser, 'userSpecialPermission');
  // if the company is not granted access to the driver module
  const disableMapSpeed = userSpecialPermission && getAttribute(userSpecialPermission, 'disableMapSpeed');

  // All commented out parts have been transfered to the new active trailer/vehicle cards, check VehicleTrailerActiveView for more details
  return (
    <span>
      {props.showGPSHistory &&
        <div className={props.isSidebarOpen ? `${styles.vehicleAndDriver} d-none d-lg-block` : `${styles.vehicleAndDriver} ${styles.sidebarClosed} d-none d-lg-block`}>
          {/* <div className={styles.unitId}>
          {!props.activeVehicle.get('unitId') && <EditVehicleButton refreshState={(vehicle) => props.refreshActiveVehicle(vehicle)} vehicle={props.activeVehicle}>Add Unit Id</EditVehicleButton>}
          {props.activeVehicle && props.activeVehicle.get('unitId')}
        </div> */}
          {/* <div
          className={styles.hideButton}
          onClick={(event) => {
            event.stopPropagation();
            props.unselectDriver(false);
          }}
          onKeyPress={(event) => {
            if (event.nativeEvent.key === ' ') {
              event.stopPropagation();
              props.unselectDriver(false);
            }
          }}
          role="button"
          tabIndex={0}
        >
          <MDBIcon icon="minus-circle" />
        </div>
        <div
          className={styles.deleteButton}
          onClick={(event) => {
            event.stopPropagation();
            props.unselectDriver();
          }}
          onKeyPress={(event) => {
            if (event.nativeEvent.key === ' ') {
              event.stopPropagation();
              props.unselectDriver();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <MDBIcon icon="times-circle" />
        </div> */}
          {/* <div className={styles.drivers}>
          {props.activeVehicle.get('drivers') &&
            props.activeVehicle.get('drivers').map((driverParseObj, index) => {
              if (index === 0) {
                return <a key={`${index}${driverParseObj.id}`} href={`driver?driver=${driverParseObj.id}`}><div className={styles.activeDriver} onClick={(e) => { e.preventDefault(); history.push({ pathname: 'driver', search: `driver=${driverParseObj.id}` }); }}>{toTitleCase(driverParseObj.get('user_fullName'))}</div></a>;
              }
              return <a key={`${index}${driverParseObj.id}`} href={`driver?driver=${driverParseObj.id}`}><div className={styles.coDriver} onClick={(e) => { e.preventDefault(); history.push({ pathname: 'driver', search: `driver=${driverParseObj.id}` }); }}>{toTitleCase(driverParseObj.get('user_fullName'))}</div></a>;
            })
          }
        </div> */}
          {/* <div onClick={() => props.zoomToActive()} className={styles.shareLink}>
          <span className="translate-me"><i className="material-icons notranslate">my_location</i> Zoom to Equipment</span>
        </div>
        {props.pingError &&
          <div className={styles.pingError}>{props.pingError}</div>
        }
        {props.activeVehicle.get('vehicleLocation') && (
          <div onClick={() => props.getLinkShare()} className={styles.shareLink}>
            {!props.linkShare.loading &&
              <span className="translate-me"><i className="material-icons notranslate">link</i> Get link to share location</span>
            }
            {props.linkShare.loading &&
              <LoadingIcon />
            }
          </div>
        )} */}
          {/* {props.activeVehicle.get('vehicleLocation') && (
          <div onClick={() => getStreetView(props.activeVehicle)} className={styles.streetView}>
            <span className="translate-me"><i className="material-icons notranslate">streetview</i> Google Maps & Street View</span>
          </div>
        )} */}
          <div className={styles.gpsHistoryDetails}>
            {/* <div className={`${styles.label} translate-me`} onClick={() => props.toggleGPSHistory()}>
            {props.showGPSHistory && <i className="material-icons notranslate">arrow_drop_up</i>}
            {!props.showGPSHistory && <i className="material-icons notranslate">arrow_drop_down</i>}
            Show GPS History
          </div> */}
            {/* <Collapse in={props.showGPSHistory}> */}
            {props.showGPSHistory &&
              <div className={styles.panel}>
                <div className={styles.dayChooser}>
                  <DayChooser
                    date={props.activeVehicleHistory.date}
                    hourStart={props.activeVehicleHistory.hourStart}
                    hourEnd={props.activeVehicleHistory.hourEnd}
                    handleDateChange={props.handleDateFilterChange}
                    handleHourChange={props.handleHourSliderChange}
                    triggerHourUpdate={props.triggerHourUpdate}
                    toggleReplay={props.toggleReplay}
                    replay={props.replay}
                  />
                </div>

                {props.activeVehicleHistory.loading &&
                  <div styles={{ margin: '1em' }}><LoadingIcon /></div>
                }


                {!props.activeVehicleHistory.loading &&
                  (!props.activeVehicleHistory?.legs?.[0]?.vehicleLocationPointsArr || !props.activeVehicleHistory?.legs?.[0]?.vehicleLocationPointsArr?.length === 0) && (
                    <div
                      style={{ fontSize: '0.8em' }}
                    >
                      <kbd>No Driver History For Day</kbd>
                    </div>
                  )}

                {((props.activeVehicleHistory && props.activeVehicleHistory.legs && props.activeVehicleHistory.legs.length > 0 && props.activeVehicleHistory.legs[0].vehicleLocationPointsArr && props.activeVehicleHistory.legs[0].vehicleLocationPointsArr.length > 0)
                  && !(props.activeVehicleHistory.loading)) &&
                  <div>
                    <Toggle
                      label={pathExists() ? 'Show Path' : 'Cannot generate path'}
                      labelPosition="right"
                      onToggle={props.togglePath}
                      labelStyle={{ fontSize: '0.7em' }}
                      toggled={props.activeVehicleHistory.showPath}
                      disabled={!pathExists()}
                      className="translate-me"
                    />
                    {!disableMapSpeed &&
                      <Toggle
                        label={'Show Trip Stops'}
                        labelPosition="right"
                        onToggle={props.toggleTripStops}
                        labelStyle={{ fontSize: '0.7em' }}
                        toggled={props.activeVehicleHistory.showTripStops}
                        className="translate-me"
                      />
                    }
                    {!props.replay.loading &&
                      <MDBBtn
                        color='primary'
                        size="sm"
                        disabled={props.replay.loading}
                        onClick={() => props.toggleReplay()}
                        className="translate-me"
                      >
                        <MDBIcon icon="history" className="mr-1" /> REPLAY
                      </MDBBtn>
                    }
                    {props.replay.loading &&
                      <MDBBtn
                        color='danger'
                        size="sm"
                        onClick={() => props.toggleReplay(true)}
                        className="translate-me"
                      >
                        <MDBIcon icon="history" className="mr-1" /> Cancel
                      </MDBBtn>
                    }
                    <MDBBtn
                      color='primary'
                      size="sm"
                      onClick={() => props.downloadHistoryCsv()}
                      className="translate-me"
                    >
                      <MDBIcon icon="download" className="mr-1" /> EXPORT CSV
                    </MDBBtn>

                  </div>
                }
              </div>
            }
            {/* </Collapse> */}
          </div>
        </div>}
    </span >
  );
}

ActiveVehicleDetails.propTypes = {
  activeVehicle: PropTypes.object.isRequired,
  activeVehicleHistory: PropTypes.object,
  replay: PropTypes.object,
  getLinkShare: PropTypes.func.isRequired,
  handleDateFilterChange: PropTypes.func,
  handleHourSliderChange: PropTypes.func,
  linkShare: PropTypes.object.isRequired,
  pingGNXLocation: PropTypes.func,
  pingError: PropTypes.string,
  pingLocation: PropTypes.func,
  pinging: PropTypes.bool.isRequired,
  refreshActiveVehicle: PropTypes.func.isRequired,
  showGPSHistory: PropTypes.bool.isRequired,
  togglePath: PropTypes.func.isRequired,
  toggleGPSHistory: PropTypes.func.isRequired,
  triggerHourUpdate: PropTypes.func,
  unselectDriver: PropTypes.func.isRequired,
  zoomToActive: PropTypes.func.isRequired,
};

export default ActiveVehicleDetails;
