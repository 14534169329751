/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import history from 'sbHistory';

// API
import { getAttribute, getCurrentUser } from 'sb-csapi/dist/AAPI';
import { formatName } from 'sb-csapi/dist/utils/String';
import { getHistory, addHistory } from 'api/Dispatch/DispatchHistory';
import { addDispatchJobUserTag } from 'api/Dispatch/DispatchJobUserTag';
import { getDispatchOrganizations } from 'api/Dispatch/DispatchOrganization';
import { getDispatchJobs, addDispatchJob, generateBatchId } from 'api/Dispatch/DispatchJob';
import { addDispatchTransfer } from 'api/Dispatch/DispatchTransfer';
import { addJobNotes } from 'api/Dispatch/DispatchJobNotes';

// SBObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';
import DispatchJob from 'sbObjects/DispatchJob';
import DispatchHistory from 'sbObjects/DispatchHistory';
import DispatchTransfer from 'sbObjects/DispatchTransfer';
import DispatchJobNotes from 'sbObjects/DispatchJobNotes';

// Enums
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';
import { AttributeTypes, StatusTypes } from 'enums/DispatchJob';
import { Status as TransferStatus } from 'sb-csapi/dist/enums/Dispatch/Transfer';

// Components
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';
import JobStatusBadge from 'components/Dispatch/JobStatusBadge/JobStatusBadge';
import Button from 'sbCore/Button/Button';
import Skeleton from 'sbCore/Skeleton/Skeleton';
import TriStateCheckbox from 'sbCore/TriStateCheckbox/TriStateCheckbox';
import JobStatusDropdown from 'components/Dispatch/JobStatusDropdown/JobStatusDropdown';
import Calendar from 'sbCore/Calendar/Calendar';

import './style.scss';

function JobsList(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [totalDispatchJobsCount, setTotalDispatchJobsCount] = useState(undefined);
  const [dispatchTableContents, setDispatchTableContents] = useState({});

  // This is used in conjunction with the DataTable lazy loading
  // Do NOT add any custom attributes to this object as it will be overwritten by the DataTable
  const [fetchAttributes, setFetchAttributes] = useState({
    first: 0,
    page: 0,
    rows: 10,
    sortField: null,
    sortOrder: null,
    filters: {
      createdByUser_fullName: { value: null, matchMode: 'contains' },
      status: { value: -1, matchMode: 'equals' },
      jobId: { value: null, matchMode: 'contains' },
      orderNo: { value: null, matchMode: 'contains' },
      'customer.organizationName': { value: null, matchMode: 'contains' },
      'shipper.organizationName': { value: null, matchMode: 'contains' },
      'consignee.organizationName': { value: null, matchMode: 'contains' },
      startDate: { value: null, matchMode: 'equals' },
      endDate: { value: null, matchMode: 'equals' },
      isCTPAT: { value: null, matchMode: 'equals' },
      isHAZMAT: { value: null, matchMode: 'equals' },
      isCSA: { value: null, matchMode: 'equals' },
      sealNumbers: { value: null, matchMode: 'contains' },
      papsNumber: { value: null, matchMode: 'contains' },
      parsNumber: { value: null, matchMode: 'contains' },
    },
  });

  /**
   * @description Given a filter, obtain all the dispatchJobs and related information needed for the table
   * @param {Object} filters An array of Filter objects
   */
  async function getDispatchTableContents(filters = []) {
    setIsLoading(true);

    const { totalDispatchJobsCount, dispatchJobs } = await getDispatchJobs(
      undefined,                          // options - default
      undefined,                          // companyObjectId - default
      false,                              // include child company results
      filters,                            // filters
      undefined,                          // sort - default
      ['customerDispatchOrganization', 'firstShipperDispatchOrganization', 'lastConsigneeDispatchOrganization'],   // includes
      undefined,                          // selects
      fetchAttributes.page,               // page
      fetchAttributes.rows,               // limit
      false,                              // query all
    );

    // Now that we have the DispatchJobs - convert it to a format that is usable to render the table
    const tableInformation = {};

    dispatchJobs.map((dispatchJob) => {
      const dispatchJobObjectId = getAttribute(dispatchJob, 'objectId');
      const status = getAttribute(dispatchJob, 'status') === undefined ? '-' : getAttribute(dispatchJob, 'status');

      const batchId = getAttribute(dispatchJob, 'batchId', true) || '-';
      const referenceNumber = getAttribute(dispatchJob, 'referenceNumber', true) || '-';

      // Customer
      const customer = getAttribute(dispatchJob, 'customerDispatchOrganization');
      const customerOrganizationName = (customer && getAttribute(customer, 'organizationName')) || '-';

      const isCTPAT = getAttribute(dispatchJob, 'isCTPAT');
      const isHAZMAT = getAttribute(dispatchJob, 'isHAZMAT');
      const isCSA = getAttribute(dispatchJob, 'isCSA');

      let sealNumbers = getAttribute(dispatchJob, 'sealNumbers');
      if (sealNumbers) sealNumbers = sealNumbers.split(',');

      const parsNumber = getAttribute(dispatchJob, 'parsNumber') || '-';
      const papsNumber = getAttribute(dispatchJob, 'papsNumber') || '-';

      // Shipper
      const firstShipperDispatchOrganization = getAttribute(dispatchJob, 'firstShipperDispatchOrganization');
      const shipperOrganizationName = (firstShipperDispatchOrganization && getAttribute(firstShipperDispatchOrganization, 'organizationName')) || '-';
      const earliestPickupDateTime = getAttribute(dispatchJob, 'earliestPickupDateTime');
      const startDate = (earliestPickupDateTime && moment(earliestPickupDateTime).format('DD-MM-YYYY')) || '-';

      // Consignee
      const lastConsigneeDispatchOrganization = getAttribute(dispatchJob, 'lastConsigneeDispatchOrganization');
      const consigneeOrganizationName = (lastConsigneeDispatchOrganization && getAttribute(lastConsigneeDispatchOrganization, 'organizationName')) || '-';
      const latestDropoffDateTime = getAttribute(dispatchJob, 'latestDropoffDateTime');
      const endDate = (latestDropoffDateTime && moment(latestDropoffDateTime).format('DD-MM-YYYY')) || '-';

      // Created By User information
      const createdByUser_fullName = getAttribute(dispatchJob, 'createdByUser_fullName', true) ? formatName(getAttribute(dispatchJob, 'createdByUser_fullName')) : '-';

      tableInformation[dispatchJobObjectId] = {
        createdByUser_fullName,
        dispatchJobObjectId,
        status,
        jobId: batchId,
        orderNo: referenceNumber,
        isCSAJob: isCSA,
        customer: {
          organizationName: customerOrganizationName,
        },
        shipper: {
          organizationName: shipperOrganizationName,
        },
        consignee: {
          organizationName: consigneeOrganizationName,
        },
        startDate,
        endDate,
        isCTPAT,
        isHAZMAT,
        isCSA,
        sealNumbers,
        parsNumber,
        papsNumber,
      };
    });

    setIsLoading(false);
    setTotalDispatchJobsCount(totalDispatchJobsCount);
    setDispatchTableContents(tableInformation);
  }

  const addJob = async () => {
    const currentUser = getCurrentUser();

    // generate a basic batch id
    const batchId = await generateBatchId();

    // create empty dispatch job
    let dispatchJob = new DispatchJob(undefined, batchId, StatusTypes.INITIATED.status, getAttribute(currentUser, 'belongsToCompany'), { isInvoiceOutdated: false, createdByUser: currentUser });
    dispatchJob = await addDispatchJob(dispatchJob);

    // add assigned user
    await addDispatchJobUserTag({ dispatchJob, belongsToCompany: getAttribute(currentUser, 'belongsToCompany'), user: currentUser })
    // create empty dispatch transfer
    let dispatchTransfer = new DispatchTransfer(undefined, dispatchJob, undefined, TransferStatus.SCHEDULED.status, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, 'America/Vancouver');
    dispatchTransfer = await addDispatchTransfer(dispatchTransfer);

    // create empty dispatch job notes
    let dispatchJobNotes = new DispatchJobNotes(dispatchJob);
    dispatchJobNotes = await addJobNotes(dispatchJobNotes);

    const currentUserFullName = formatName(`${getAttribute(currentUser, 'firstName')} ${getAttribute(currentUser, 'lastName')}`);
    const historyDescription = `${currentUserFullName} created Job ${getAttribute(dispatchJob, 'name')}`;
    const dispatchHistory = new DispatchHistory(undefined, historyDescription, new Date(), 0, 'DispatchJob', getAttribute(dispatchJob, 'objectId'), dispatchJob);
    await addHistory(dispatchHistory);

    // Redirect user to dispatch job order summary page
    history.push(`/dispatch/job/${getAttribute(dispatchJob, 'objectId')}`);
  };

  function onFilter(event) {
    event.first = 0;
    setFetchAttributes(event);
  }

  /**
   * @description Aggregates all the filter properties and creates filters for the database fetch
   * @returns {Array} An array of Filter objects
   */
  async function getFilters() {
    const dataTableFilters = fetchAttributes.filters;

    const filters = [];
    let customerOrganizationFilter;
    let shipperOrganizationFilter;
    let consigneeOrganizationFilter;

    Object.entries(dataTableFilters).forEach(([key, filter]) => {
      if (filter.value === null || filter.value === '') return;

      if (key === 'createdByUser_fullName') filters.push(new Filter(QueryRestrictionTypes.CONTAINS, 'createdByUser_fullName', filter.value.toLowerCase()));
      if (key === 'status') filter.value !== -1 && filters.push(new Filter(QueryRestrictionTypes.EQUAL_TO, 'status', filter.value));
      if (key === 'jobId') filters.push(new Filter(QueryRestrictionTypes.CONTAINS, 'batchId', filter.value));
      if (key === 'orderNo') filters.push(new Filter(QueryRestrictionTypes.CONTAINS, 'referenceNumber', filter.value));
      if (key === 'customer.organizationName') customerOrganizationFilter = new Filter(QueryRestrictionTypes.CONTAINS, 'organizationName', filter.value);
      if (key === 'shipper.organizationName') shipperOrganizationFilter = new Filter(QueryRestrictionTypes.CONTAINS, 'organizationName', filter.value);
      if (key === 'consignee.organizationName') consigneeOrganizationFilter = new Filter(QueryRestrictionTypes.CONTAINS, 'organizationName', filter.value);
      if (key === 'isCTPAT') filters.push(new Filter(QueryRestrictionTypes.EQUAL_TO, 'isCTPAT', filter.value));
      if (key === 'isHAZMAT') filters.push(new Filter(QueryRestrictionTypes.EQUAL_TO, 'isHAZMAT', filter.value));
      if (key === 'isCSA') filters.push(new Filter(QueryRestrictionTypes.EQUAL_TO, 'isCSA', filter.value));
      if (key === 'sealNumbers') filters.push(new Filter(QueryRestrictionTypes.CONTAINS, 'sealNumbers', filter.value));
      if (key === 'papsNumber') filters.push(new Filter(QueryRestrictionTypes.CONTAINS, 'papsNumber', filter.value));
      if (key === 'parsNumber') filters.push(new Filter(QueryRestrictionTypes.CONTAINS, 'parsNumber', filter.value));

      // For start and end dates, check for the range of the full day
      if (key === 'startDate') {
        filters.push(
          new Filter(QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'earliestPickupDateTime', moment(filter.value).startOf('day').toISOString()),
          new Filter(QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'earliestPickupDateTime', moment(filter.value).endOf('day').toISOString()),
        );
      }
      if (key === 'endDate') {
        filters.push(
          new Filter(QueryRestrictionTypes.GREATER_THAN_OR_EQUAL_TO, 'latestDropoffDateTime', moment(filter.value).startOf('day').toISOString()),
          new Filter(QueryRestrictionTypes.LESS_THAN_OR_EQUAL_TO, 'latestDropoffDateTime', moment(filter.value).endOf('day').toISOString()),
        );
      }
    });

    // Check for special conditions with customer, shipper, and consignee
    if (customerOrganizationFilter) {
      const { dispatchOrganizations } = await getDispatchOrganizations(
        undefined,                          // options - default
        undefined,                          // companyobjectid - default
        false,                              // include child company results
        [customerOrganizationFilter],       // filters
        undefined,                          // sort - default
        undefined,                          // includes
        undefined,                          // selects
        true,                               // query all
      );
      filters.push(new Filter(QueryRestrictionTypes.CONTAINED_IN, 'customerDispatchOrganization', dispatchOrganizations));
    }

    if (shipperOrganizationFilter) {
      const { dispatchOrganizations } = await getDispatchOrganizations(
        undefined,                          // options - default
        undefined,                          // companyobjectid - default
        false,                              // include child company results
        [shipperOrganizationFilter],        // filters
        undefined,                          // sort - default
        undefined,                          // includes
        undefined,                          // selects
        true,                               // query all
      );
      filters.push(new Filter(QueryRestrictionTypes.CONTAINED_IN, 'firstShipperDispatchOrganization', dispatchOrganizations));
    }

    if (consigneeOrganizationFilter) {
      const { dispatchOrganizations } = await getDispatchOrganizations(
        undefined,                          // options - default
        undefined,                          // companyobjectid - default
        false,                              // include child company results
        [consigneeOrganizationFilter],      // filters
        undefined,                          // sort - default
        undefined,                          // includes
        undefined,                          // selects
        true,                               // query all
      );
      filters.push(new Filter(QueryRestrictionTypes.CONTAINED_IN, 'lastConsigneeDispatchOrganization', dispatchOrganizations));
    }

    return filters;
  }

  useEffect(() => {
    let unmounted = false;

    async function refresh() {
      if (!unmounted) {
        const filters = await getFilters();
        getDispatchTableContents(filters);
      }
    }

    refresh();
    return () => { unmounted = true; };
  }, [fetchAttributes]);

  // Custom styling templates for the different columns of the table
  const rowCellSkeleton = <Skeleton height="2rem" className="mb-2" />;
  const statusBodyTemplate = (rowData) => <JobStatusBadge className="z-depth-0 translate-me" status={rowData.status} />;
  const jobIdBodyTemplate = (rowData) => {
    const dispatchItemRoute = `/dispatch/job/${rowData.dispatchJobObjectId}`;
    return rowData.jobId === '-'
      ? <p className="text-400">{rowData.jobId}</p>
      : <Button label={rowData.jobId} className="p-button-text job-id-btn" onClick={() => history.push(dispatchItemRoute)} />;
  };
  const orderNoBodyTemplate = (rowData) => <p className={`${rowData.orderNo === '-' && 'text-400'}`}>{rowData.orderNo}</p>;
  const customerBodyTemplate = (rowData) => <p className={`${rowData.customer.organizationName === '-' && 'text-400'}`}>{rowData.customer.organizationName}</p>;
  const shipperBodyTemplate = (rowData) => (rowData.shipper ? <p className={`${rowData.shipper.organizationName === '-' && 'text-400'}`}>{rowData.shipper.organizationName}</p> : rowCellSkeleton);
  const startDateBodyTemplate = (rowData) => (rowData.startDate ? <p className={`${rowData.startDate === '-' && 'text-400'}`}>{rowData.startDate}</p> : rowCellSkeleton);
  const consigneeBodyTemplate = (rowData) => (rowData.consignee ? <p className={`${rowData.consignee.organizationName === '-' && 'text-400'}`}>{rowData.consignee.organizationName}</p> : rowCellSkeleton);
  const endDateBodyTemplate = (rowData) => (rowData.endDate ? <p className={`${rowData.endDate === '-' && 'text-400'}`}>{rowData.endDate}</p> : rowCellSkeleton);
  const ctpatBodyTemplate = (rowData) => <span>{rowData.isCTPAT ? <i className="pi pi-check" /> : <p className="text-400">-</p>}</span>;
  const hazmatBodyTemplate = (rowData) => <span>{rowData.isHAZMAT ? <i className="pi pi-check" /> : <p className="text-400">-</p>}</span>;
  const csaBodyTemplate = (rowData) => <span>{rowData.isCSA ? <i className="pi pi-check" /> : <p className="text-400">-</p>}</span>;
  const sealNumbersBodyTemplate = (rowData) => <div>{rowData.sealNumbers ? rowData.sealNumbers.map((sealNumber) => <p className="mb-0">{sealNumber}</p>) : <p className="text-400">-</p>}</div>;
  const parsNumberBodyTemplate = (rowData) => <p className={`${rowData.parsNumber === '-' && 'text-400'}`}>{rowData.parsNumber}</p>;
  const papsNumberBodyTemplate = (rowData) => <p className={`${rowData.papsNumber === '-' && 'text-400'}`}>{rowData.papsNumber}</p>;
  const createdByUserFullNameBodyTemplate = (rowData) => <p className={`${rowData.createdByUser_fullName === '-' && 'text-400'}`}>{rowData.createdByUser_fullName}</p>;

  function statusFilter(options) {
    return (
      <JobStatusDropdown
        hideLabel
        status={options.value}
        className="status-filter-dropdown"
        includeAllStatusesOption
        onSelect={(value) => options.filterApplyCallback(value)}
      />
    );
  }

  function triStateCheckboxFilter(options) {
    return (
      <TriStateCheckbox
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  }

  function dateFilter(options) {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => e.value && options.filterApplyCallback(e.value)}
        dateFormat="mm-dd-yy"
        placeholder="mm-dd-yyyy"
      />
    );
  }

  return (
    <div className="sb-jobs-list-container my-2">
      <div className="text-right">
        <Button
          label="Add Job"
          icon="pi pi-plus"
          sbVariant="short"
          severity="primary"
          onClick={() => addJob()}
        />
      </div>
      <div className="card mt-3">
        <DataTable
          value={Object.values(dispatchTableContents)}
          lazy
          filterDisplay="row"
          responsiveLayout="scroll"
          dataKey="id"
          emptyMessage="No jobs found."
          paginator
          first={fetchAttributes.first}
          rows={fetchAttributes.rows}
          totalRecords={totalDispatchJobsCount}
          onPage={(event) => setFetchAttributes(event)}
          rowHover
          onFilter={(event) => onFilter(event)}
          filters={fetchAttributes.filters}
          loading={isLoading}
          reorderableColumns
        >
          <Column
            filterHeaderClassName="filter-header-hide-button"
            filter
            showClearButton={(![undefined, null, -1].includes(fetchAttributes.filters.status.value))} // This is to hide the clear filter button when the status is "All Statuses"
            field="status"
            header="Status"
            style={{ minWidth: '16rem' }}
            body={statusBodyTemplate}
            filterElement={statusFilter}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="jobId"
            header="Job ID"
            filter
            showClearButton
            filterPlaceholder="Job ID"
            style={{ minWidth: '16rem' }}
            body={jobIdBodyTemplate}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="orderNo"
            header="Order No."
            filter
            showClearButton
            filterPlaceholder="Order No."
            style={{ minWidth: '16rem' }}
            body={orderNoBodyTemplate}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="customer.organizationName"
            header="Customer"
            filter
            showClearButton
            filterPlaceholder="Customer"
            style={{ minWidth: '16rem' }}
            body={customerBodyTemplate}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="shipper.organizationName"
            header="Shipper"
            filter
            showClearButton
            filterPlaceholder="Shipper"
            style={{ minWidth: '16rem' }}
            body={shipperBodyTemplate}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="startDate"
            header="Start Date"
            filter
            showClearButton
            style={{ minWidth: '16rem' }}
            body={startDateBodyTemplate}
            filterElement={dateFilter}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="consignee.organizationName"
            header="Consignee"
            filter
            showClearButton
            filterPlaceholder="Consignee"
            style={{ minWidth: '16rem' }}
            body={consigneeBodyTemplate}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="endDate"
            header="End Date"
            filter
            showClearButton
            style={{ minWidth: '16rem' }}
            body={endDateBodyTemplate}
            filterElement={dateFilter}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="isCTPAT"
            header="CTPAT"
            filter
            style={{ minWidth: '8rem' }}
            body={ctpatBodyTemplate}
            filterElement={triStateCheckboxFilter}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="isHAZMAT"
            header="HAZMAT"
            filter
            style={{ minWidth: '8rem' }}
            body={hazmatBodyTemplate}
            filterElement={triStateCheckboxFilter}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="isCSA"
            header="CSA"
            filter
            style={{ minWidth: '8rem' }}
            body={csaBodyTemplate}
            filterElement={triStateCheckboxFilter}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="sealNumbers"
            header="Seal Numbers"
            filter
            showClearButton
            filterPlaceholder="Seal Numbers"
            style={{ minWidth: '16rem' }}
            body={sealNumbersBodyTemplate}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="parsNumber"
            header="PARS Number"
            filter
            showClearButton
            filterPlaceholder="PARS Number"
            style={{ minWidth: '16rem' }}
            body={parsNumberBodyTemplate}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="papsNumber"
            header="PAPS Number"
            filter
            showClearButton
            filterPlaceholder="PAPS Number"
            style={{ minWidth: '16rem' }}
            body={papsNumberBodyTemplate}
          />
          <Column
            filterHeaderClassName="filter-header-hide-button"
            field="createdByUser_fullName"
            header="Created By"
            filter
            showClearButton
            filterPlaceholder="Jamie Doe"
            style={{ minWidth: '16rem' }}
            body={createdByUserFullNameBodyTemplate}
          />
        </DataTable>
      </div>
    </div>
  );
}

export default JobsList;
