import React from 'react';
import PropTypes from 'prop-types';

// CSS
import './styles.scss';
import Address from 'sbObjects/Address';

class LocationAutocomplete extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isGoogleLocationValid: true, // empty (no input) location can count as valid
    };
    this.refreshState = this.refreshState.bind(this);
    this.disableAutofill = this.disableAutofill.bind(this);
    this.convertGooglePlaceObject = this.convertGooglePlaceObject.bind(this);
    this.autocomplete = null;
    this.event = null;
  }

  componentDidMount() {
    this.refreshState();
  }

  componentWillUnmount() {
    this.event.remove();
  }

  refreshState() {
    const { types = this.props.types || ['establishment', 'geocode'], componentRestrictions = { country: ['ca', 'us'] } } = this.props;

    // Field restriction stops Google Places API from calling "Atmosphere" and "Contact" data.
    // Maybe if needed, we'll query but as a general case will only return address
    const config = {
      types,
      componentRestrictions,
      fields: [
        'formatted_address',
        'address_components',
        'geometry',
      ],
    };
    this.disableAutofill();
    this.autocomplete = new google.maps.places.Autocomplete(this.refs.input, config);
    this.event = this.autocomplete.addListener('place_changed', this.onSelected.bind(this));
    // this.event = this.autocomplete.addListener('place_changed', this.handleKeyDownSelect.bind(this));
  }

  disableAutofill() {
    // Autofill workaround adapted from https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3/49161445#49161445
    if (window.MutationObserver) {
      const observerHack = new MutationObserver(() => {
        observerHack.disconnect();
        if (this.refs.input) {
          this.refs.input.autocomplete = 'disable-autofill';
        }
      });
      observerHack.observe(this.refs.input, {
        attributes: true,
        attributeFilter: ['autocomplete'],
      });
    }
  }

  convertGooglePlaceObject(place) {
    let city = null;
    let country = null;
    let zip = null;
    let state = null;
    if (!place.address_components) return this.props.manualInputAddressObject;
    for (let i = 0; i < place.address_components.length; i++) {
      let comp = place.address_components[i];
      let types = comp.types;
      for (let j = 0; j < types.length; j++) {
        switch (types[j]) {
          case "locality":
            city = comp.short_name;
            break;
          case "administrative_area_level_1":
            state = comp.short_name;
            break;
          case "country":
            country = comp.short_name;
            break;
          case "postal_code":
            zip = comp.short_name;
            break;
        }
      }
    }
    return new Address(
      undefined,
      place.formatted_address,
      place.geometry.location.lat(),
      place.geometry.location.lng(),
      zip,
      city,
      state,
      country,
      undefined
    );
  }

  onSelected() {
    const googlePlaceObject = this.convertGooglePlaceObject(this.autocomplete.getPlace());
    this.props.onLocationSelected(googlePlaceObject);
    const isGoogleLocationValid = !!(googlePlaceObject && googlePlaceObject.address);
    this.setState({
      isGoogleLocationValid,
    }, () => {
      if (this.props.isGoogleLocationValid) this.props.isGoogleLocationValid(isGoogleLocationValid);
    });
  }

  // handleKeyDownSelect() {

  //   if ( e.key === 'Enter') {
  //     this.onSelected();
  //   }
  // }



  render() {
    const { props } = this;

    let containerClassName = 'location-autocomplete-container';
    let inputClassName = 'location-autocomplete-input';

    if (!props.usePRStyle) {
      containerClassName += ' md-form';
      inputClassName += ' form-control';
    } else {
      containerClassName += ' my-0';
      inputClassName += ' p-inputtext p-component sbc-input-text';
    }

    if (props.containerClass) containerClassName += ` ${props.containerClass}`;
    if (props.inputClassName) inputClassName += ` ${props.inputClassName}`;

    return (
      <div className={containerClassName}>
        {/* onKeyDown={ this.props.handleKeyDown }  */}
        <input type="text" id='address-input' className={inputClassName} placeholder={this.props.placeholder} ref="input" />
        {this.props.label &&
          <label className="active">{this.props.label}</label>
        }
      </div>
    );
  }
}

LocationAutocomplete.propTypes = {
  onLocationSelected: PropTypes.func.isRequired,
  isGoogleLocationValid: PropTypes.func,
  label: PropTypes.string,
  types: PropTypes.array,
  containerClass: PropTypes.string,
  componentRestrictions: PropTypes.object,
  placeholder: PropTypes.string,
  handleKeyDown: PropTypes.func,
  manualInputAddressObject: PropTypes.object
};


export default LocationAutocomplete;
