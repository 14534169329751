import { destroyRecord, addRecord, copyQuery, count, createQuery, createQueryOr, find, getAttribute, getCurrentUser, getObjectById, includePointers, setQueryRestriction, sortQuery, updateRecord, callCloudFunction } from 'api/Parse';
// http://app.localhost:3000/dispatch/job__new/xpulUf0atL

import Sort from 'sbObjects/Sort';

import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { AttributeTypes, StatusTypes } from 'enums/DispatchJob';

/**
 * 
 * @param {int} page - query page / limit skip multiplier
 * @param {int} limit  - amount of results we want
 * @param {array} sortBy - array of Sort objects
 * @param {array} filters - array of Filter objects
 */
async function getJobs(page = 0, limit = 20, sortBy = new Sort(AttributeTypes.BATCH_ID, QuerySortOrderTypes.DESCENDING), filters = [], includes = []) {
  const currentUser = getCurrentUser();
  let dispatchJobQuery = createQuery('DispatchJob');
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  // Filters: find out if there needs to be a subquery filter and create a query for that class only once (so we need to track to see if its occured already)
  // special filters look like this Classname:Property. Ex if DispatchBusiness:type, we need to create a DispatchBusiness subquery for DispatchJob
  const seenFilterTableHash = {};
  filters.map(filter => {
    const filterSubQueryTableName = (filter.attribute.indexOf(':') !== -1) ? filter.attribute.split(':')[0] : undefined;
    if (filterSubQueryTableName && !seenFilterTableHash[filterSubQueryTableName]) {
      const subQuery = createQuery(filterSubQueryTableName);
      seenFilterTableHash[filterSubQueryTableName] = subQuery;

      if (filterSubQueryTableName.toLowerCase() === 'dispatchbusiness') {
        const dispatchJobCarrierQuery = createQuery('DispatchJob');
        const dispatchJobCustomerQuery = createQuery('DispatchJob');
        const dispatchJobConsigneeQuery = createQuery('DispatchJob');
        const dispatchJobShipperQuery = createQuery('DispatchJob');
        const dispatchJobBrokerQuery = createQuery('DispatchJob');

        // set subquery restriction
        setQueryRestriction(subQuery, (filter.queryRestriction || filter.queryType), filter.attribute.split(':')[1], filter.value);

        // now match the subquery to the corresponding OR queries
        setQueryRestriction(dispatchJobCarrierQuery, QueryRestrictionTypes.MATCHES_QUERY, 'carrierDispatchBusiness', subQuery);
        setQueryRestriction(dispatchJobCarrierQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
        setQueryRestriction(dispatchJobCarrierQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'isHidden', true);

        setQueryRestriction(dispatchJobCustomerQuery, QueryRestrictionTypes.MATCHES_QUERY, 'customerDispatchBusiness', subQuery);
        setQueryRestriction(dispatchJobCustomerQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
        setQueryRestriction(dispatchJobCustomerQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'isHidden', true);

        setQueryRestriction(dispatchJobConsigneeQuery, QueryRestrictionTypes.MATCHES_QUERY, 'consigneeDispatchBusiness', subQuery);
        setQueryRestriction(dispatchJobConsigneeQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
        setQueryRestriction(dispatchJobConsigneeQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'isHidden', true);

        setQueryRestriction(dispatchJobShipperQuery, QueryRestrictionTypes.MATCHES_QUERY, 'shipperDispatchBusiness', subQuery);
        setQueryRestriction(dispatchJobShipperQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
        setQueryRestriction(dispatchJobShipperQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'isHidden', true);

        setQueryRestriction(dispatchJobBrokerQuery, QueryRestrictionTypes.MATCHES_QUERY, 'brokerDispatchBusiness', subQuery);
        setQueryRestriction(dispatchJobBrokerQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
        setQueryRestriction(dispatchJobBrokerQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'isHidden', true);

        dispatchJobQuery = createQueryOr([dispatchJobCarrierQuery, dispatchJobCustomerQuery, dispatchJobConsigneeQuery, dispatchJobShipperQuery, dispatchJobBrokerQuery]);
        seenFilterTableHash[filterSubQueryTableName] = dispatchJobQuery;
      }
    } else if (filterSubQueryTableName) {
      // subquery for table seen again
    }
  });

  // apply the rest of the non special filters - this is in a seperate map for seperation of concerns / readability
  const remainingFilters = [];
  let userFilterPresent = false;
  let userFilter;
  filters.map(filter => {
    const filterSubQueryTableName = (filter.attribute.indexOf(':') !== -1) ? filter.attribute.split(':')[0] : undefined;
    if (!filterSubQueryTableName && (filter.attribute === 'user')) {
      userFilterPresent = true;
      userFilter = { ...filter };
    } else {
      return remainingFilters.push(filter);
    }
  });
  if (userFilterPresent) {
    const dispatchJobUserTagQuery = createQuery('DispatchJobUserTag');
    setQueryRestriction(dispatchJobUserTagQuery, (userFilter.queryRestriction || userFilter.queryType), userFilter.attribute, userFilter.value);
    const dispatchJobUserTagQueryResults = await find(dispatchJobUserTagQuery, false, true);
    const jobObjectIds = dispatchJobUserTagQueryResults.map(userTag => {
      return getAttribute(getAttribute(userTag, 'dispatchJob'), 'objectId');
    });
    // filters.push({ attribute: 'objectId', value: jobObjectIds, queryRestriction: 'equalTo' });
    setQueryRestriction(dispatchJobQuery, (userFilter.queryRestriction || userFilter.queryType), 'objectId', jobObjectIds);
  }

  remainingFilters.map(filter => {
    const filterSubQueryTableName = (filter.attribute.indexOf(':') !== -1) ? filter.attribute.split(':')[0] : undefined;
    if (!filterSubQueryTableName) {
      setQueryRestriction(dispatchJobQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value);
    }
  });

  setQueryRestriction(dispatchJobQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
  setQueryRestriction(dispatchJobQuery, QueryRestrictionTypes.NOT_EQUAL_TO, 'isHidden', true);

  // at this point, copy current query to get the number of pages for pagination
  let dispatchJobCountQuery = copyQuery(dispatchJobQuery);

  // now do the includes (we do them after copying the jobQuery so the dispatchJobCountQuery doesn't have the overhead of these includes/unnecessary query additions)
  includePointers(dispatchJobQuery, [
    'customerDispatchOrganization',
    'customerDispatchBusiness',
    'consigneeDispatchBusiness',
    'shipperDispatchBusiness',
    'brokerDispatchBusiness',
    'carrierDispatchBusiness',
    'invoiceCustomerPricing',
    'defaultVehicle',
    ...includes,
  ]);

  setQueryRestriction(dispatchJobQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  setQueryRestriction(dispatchJobQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
  // call the ascending/descending function on the query, passing in the attribute
  sortQuery(dispatchJobQuery, sortBy.order, sortBy.attribute);
  if (sortBy.attribute !== AttributeTypes.BATCH_ID) {
    sortQuery(dispatchJobQuery, QuerySortOrderTypes.ADD_DESCENDING, AttributeTypes.BATCH_ID);  // secondary sorting if first one yields the same result
  }

  try {
    const [totalJobsCount, dispatchJobs] = await Promise.all([count(dispatchJobCountQuery), find(dispatchJobQuery)]);
    return { dispatchJobs, totalJobsCount };
  } catch (err) {
    throw new Error(err);
  }
}

const getJobQuery = (searchTerm, company) => {
  const dispatchJobQuery = createQuery('DispatchJob');
  if (searchTerm) dispatchJobQuery.matches('batchId', searchTerm, 'i');
  dispatchJobQuery.equalTo('belongsToCompany', company);
  return dispatchJobQuery;
}
/**
 * Fetches the top 10 jobs matching the search term
 * @param {*} searchTerm The search term
 */
async function getSuggestedJobs(searchTerm, considerChildCompanies){
  let queries = [];
  queries.push(getJobQuery(searchTerm, getAttribute(getCurrentUser(),'belongsToCompany')));
  if (considerChildCompanies) {
    const childrenQuery = createQuery('CompanyLink');
    setQueryRestriction(childrenQuery, QueryRestrictionTypes.EQUAL_TO, 'parentCompany', getAttribute(getCurrentUser(),'belongsToCompany'));
    const childCompanies = await find(childrenQuery);
    childCompanies.map((child) => {
      queries.push(getJobQuery(searchTerm, getAttribute(child, 'childCompany').id));
    })
  }
  let allJobsQuery = createQueryOr(queries);
  setQueryRestriction(allJobsQuery, QueryRestrictionTypes.LIMIT, undefined, 10);
  const drivers = await find(allJobsQuery);
  return drivers;
}

/**
 * @description gets the total number of created jobs (including deleted) in a current company
 */
async function countJobs() {
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  const dispatchJobQuery = createQuery('DispatchJob');
  setQueryRestriction(dispatchJobQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
  return await count(dispatchJobQuery);
}

/**
 * 
 * @param {object} dispatchJob - sbobject Dispatchjob
 */
async function addJob(dispatchJob) {
  if (!dispatchJob) { throw new Error('DispatchJob is required'); }
  const _dispatchJob = { ...dispatchJob };
  delete _dispatchJob.objectId;

  return await addRecord('DispatchJob', _dispatchJob);
}

/**
 * @description "Delete" the job by hiding it
 * @param {string} dispatchJobObjectId
 */
async function removeJob(dispatchJobObjectId) {
  if (!dispatchJobObjectId) throw new Error('dispatchJobObjectId required');

  const dispatchJob = await getObjectById('DispatchJob', dispatchJobObjectId);
  if (!dispatchJob) throw new Error(`Unable to remove Job ${dispatchJobObjectId} - Does not exist`);

  try {
    const removedJob = await updateRecord(dispatchJob, { isHidden: true }, true);
    await callCloudFunction('deleteHiddenDispatchRowFromAlgoliaIndex', { className: 'DispatchJob', objectId: dispatchJobObjectId });
    return removedJob;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @description "Delete" the job by deleting it from the db
 * @param {string} dispatchJobObjectId
 */
async function destroyJob(dispatchJobObjectId) {
  if (!dispatchJobObjectId) throw new Error('dispatchJobObjectId required');

  const dispatchJob = await getObjectById('DispatchJob', dispatchJobObjectId);
  if (!dispatchJob) throw new Error(`Unable to remove Job ${dispatchJobObjectId} - Does not exist`);

  try {
    return await destroyRecord(dispatchJob);
    
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @description Update a DispatchJob given it's objectId
 * @param {string} dispatchJobObjectId
 * @param {object} keyAttributeObj
 */
async function updateJob(dispatchJobObjectId, keyAttributeObj = {}) {
  const dispatchJobQuery = createQuery('DispatchJob');
  setQueryRestriction(dispatchJobQuery, QueryRestrictionTypes.EQUAL_TO, 'objectId', dispatchJobObjectId);
  try {
    const dispatchJob = await find(dispatchJobQuery, true);
    if (!Object.keys(keyAttributeObj).length) return dispatchJob;
   
    if (dispatchJob) {
      const updatedJob = await updateRecord(dispatchJob, keyAttributeObj, true);
      return updatedJob;
    } else {
      throw new Error(`Job of objectId ${dispatchJobObjectId} does not exist`);
    }
  } catch (err) {
    throw new Error(err);
  }
}

// /**
//  * @description Update the DispatchJob
//  * @param {string} objectId The ID of the job to be updated
//  */
// async function updateJob(dispatchJob, keyAttributeObj, skipHistory) {

//   const dispatchJobQuery = new Parse.Query('DispatchJob');
//   dispatchJobQuery.equalTo('objectId', dispatchJob.objectId);
//   const qDispatchJob = await dispatchJobQuery.first();

//   if (!qDispatchJob) {
//     return dispatchJob;
//   }

//   // we got the queried dispatch job, now set the values and save
//   return setParseObject(qDispatchJob, keyAttributeObj, true).then(
//     qDispatchJob => {
//       const triggeredAtUTC = new Date();
//       const currentUser = getCurrentUser(true);
//       const currentUserFullName = formatName(`${currentUser.firstName} ${currentUser.lastName}`);

//       if (!skipHistory) {
//         addHistory(createTempParseObject('DispatchJob', { 'objectId': qDispatchJob.id }), {
//           dispatchJob: qDispatchJob,
//           description: `${currentUserFullName} updated Job ${qDispatchJob.get('batchId')}`,
//           dispatchClass: 'DispatchJob',
//           dispatchClassObjectId: qDispatchJob.id,
//           level: 0,
//           triggeredAtUTC,
//         });
//       }
//       let customer = qDispatchJob.get('customerDispatchBusiness');
//       let customerLoca = customer ? customer.get('location') : undefined;
//       let cutomerPoint = customerLoca ? customerLoca.get('geoPoint') : undefined;

//       let consignee = qDispatchJob.get('consigneeDispatchBusiness');
//       let consigneeLoca = consignee ? consignee.get('location') : undefined;
//       let consigneePoint = consigneeLoca ? consigneeLoca.get('geoPoint') : undefined;

//       let shipper = qDispatchJob.get('shipperDispatchBusiness');
//       let shipperLoca = shipper ? shipper.get('location') : undefined;
//       let shipperPoint = shipperLoca ? shipperLoca.get('geoPoint') : undefined;

//       let broker = qDispatchJob.get('brokerDispatchBusiness');
//       let brokerLoca = broker ? broker.get('location') : undefined;
//       let brokerPoint = brokerLoca ? brokerLoca.get('geoPoint') : undefined;
//       const dispatchJob = new DispatchJob(
//         qDispatchJob.id,
//         qDispatchJob.get('batchId'),
//         qDispatchJob.get('notes'),
//         qDispatchJob.get('status'),
//         qDispatchJob.get('startDateTimeUTC'),
//         qDispatchJob.get('endDateTimeUTC'),
//         customer ? new DispatchBusiness(customer.id,
//           formatName(customer.get('name')),
//           formatName(customer.get('contactName')),
//           customer.get('notes'),
//           customer.get('phoneNumber'),
//           customer.get('email'),
//           customerLoca ? new Address(customerLoca.id,
//             customerLoca.get('address'),
//             cutomerPoint ? cutomerPoint.latitude : undefined,
//             cutomerPoint ? cutomerPoint.longitude : undefined,
//             customerLoca.get('zipPostal'),
//             customerLoca.get('city'),
//             customerLoca.get('stateProvince'),
//             customerLoca.get('country'),
//             customerLoca.get('name')
//           ) : undefined,
//           []) : undefined,
//         shipper ? new DispatchBusiness(shipper.id,
//           formatName(shipper.get('name')),
//           formatName(shipper.get('contactName')),
//           shipper.get('notes'),
//           shipper.get('phoneNumber'),
//           shipper.get('email'),
//           shipperLoca ? new Address(shipperLoca.id,
//             shipperLoca.get('address'),
//             shipperPoint ? shipperPoint.latitude : undefined,
//             shipperPoint ? shipperPoint.longitude : undefined,
//             shipperLoca.get('zipPostal'),
//             shipperLoca.get('city'),
//             shipperLoca.get('stateProvince'),
//             shipperLoca.get('country'),
//             shipperLoca.get('name')
//           ) : undefined,
//           []) : undefined,
//         consignee ? new DispatchBusiness(consignee.id,
//           formatName(consignee.get('name')),
//           formatName(consignee.get('contactName')),
//           consignee.get('notes'),
//           consignee.get('phoneNumber'),
//           consignee.get('email'),
//           consigneeLoca ? new Address(consigneeLoca.id,
//             consigneeLoca.get('address'),
//             consigneePoint ? consigneePoint.latitude : undefined,
//             consigneePoint ? consigneePoint.longitude : undefined,
//             consigneeLoca.get('zipPostal'),
//             consigneeLoca.get('city'),
//             consigneeLoca.get('stateProvince'),
//             consigneeLoca.get('country'),
//             consigneeLoca.get('name')
//           ) : undefined,
//           []) : undefined,
//         broker ? new DispatchBusiness(broker.id,
//           formatName(broker.get('name')),
//           formatName(broker.get('contactName')),
//           broker.get('notes'),
//           broker.get('phoneNumber'),
//           broker.get('email'),
//           brokerLoca ? new Address(brokerLoca.id,
//             brokerLoca.get('address'),
//             brokerPoint ? brokerPoint.latitude : undefined,
//             brokerPoint ? brokerPoint.longitude : undefined,
//             brokerLoca.get('zipPostal'),
//             brokerLoca.get('city'),
//             brokerLoca.get('stateProvince'),
//             brokerLoca.get('country'),
//             brokerLoca.get('name')
//           ) : undefined,
//           []) : undefined,
//         qDispatchJob.get('referenceNumber'),
//         qDispatchJob.get('pickupNumber'),
//         qDispatchJob.get('dropoffNumber'),
//         qDispatchJob.get('invoiceNotes'),
//         qDispatchJob.get('invoiceDate'),
//         qDispatchJob.get('paymentDueInDays'),
//         qDispatchJob.get('currency')
//       );
//       return dispatchJob;
//     }
//   ).catch(error => {
//     throw new Error(error);
//   });
// }

// /**
//  * @description Fetches the Job object from database
//  * @param {string} objectId The ID of the job to be fetched
//  */
// async function getJob(objectId) {

//   if (!objectId) throw new Error('Job objectId is required');

//   const dispatchJobQuery = new Parse.Query('DispatchJob');
//   dispatchJobQuery.include(['customerDispatchBusiness',
//     'customerDispatchBusiness.location',
//     'consigneeDispatchBusiness',
//     'consigneeDispatchBusiness.location',
//     'brokerDispatchBusiness',
//     'brokerDispatchBusiness.location',
//     'shipperDispatchBusiness',
//     'shipperDispatchBusiness.location']);
//   dispatchJobQuery.equalTo('objectId', objectId);
//   dispatchJobQuery.notEqualTo('isHidden', true);

//   return dispatchJobQuery.first().then(
//     dispatchJob => {
//       if (dispatchJob) {
//         let customer = dispatchJob.get('customerDispatchBusiness');
//         let customerLoca = customer ? customer.get('location') : undefined;
//         let cutomerPoint = customerLoca ? customerLoca.get('geoPoint') : undefined;
//         let consignee = dispatchJob.get('consigneeDispatchBusiness');
//         let consigneeLoca = consignee ? consignee.get('location') : undefined;
//         let consigneePoint = consigneeLoca ? consigneeLoca.get('geoPoint') : undefined;
//         let shipper = dispatchJob.get('shipperDispatchBusiness');
//         let shipperLoca = shipper ? shipper.get('location') : undefined;
//         let shipperPoint = shipperLoca ? shipperLoca.get('geoPoint') : undefined;
//         let broker = dispatchJob.get('brokerDispatchBusiness');
//         let brokerLoca = broker ? broker.get('location') : undefined;
//         let brokerPoint = brokerLoca ? brokerLoca.get('geoPoint') : undefined;

//         let _dispatchJob = new DispatchJob(
//           dispatchJob.id,
//           dispatchJob.get('batchId'),
//           dispatchJob.get('notes'),
//           dispatchJob.get('status'),
//           dispatchJob.get('startDateTimeUTC'),
//           dispatchJob.get('endDateTimeUTC'),
//           customer ? new DispatchBusiness(customer.id,
//             formatName(customer.get('name')),
//             formatName(customer.get('contactName')),
//             customer.get('notes'),
//             customer.get('phoneNumber'),
//             customer.get('email'),
//             customerLoca ? new Address(customerLoca.id,
//               customerLoca.get('address'),
//               cutomerPoint ? cutomerPoint.latitude : undefined,
//               cutomerPoint ? cutomerPoint.longitude : undefined,
//               customerLoca.get('zipPostal'),
//               customerLoca.get('city'),
//               customerLoca.get('stateProvince'),
//               customerLoca.get('country'),
//               customerLoca.get('name')
//             ) : undefined,
//             []) : undefined,
//           shipper ? new DispatchBusiness(shipper.id,
//             formatName(shipper.get('name')),
//             formatName(shipper.get('contactName')),
//             shipper.get('notes'),
//             shipper.get('phoneNumber'),
//             shipper.get('email'),
//             shipperLoca ? new Address(shipperLoca.id,
//               shipperLoca.get('address'),
//               shipperPoint ? shipperPoint.latitude : undefined,
//               shipperPoint ? shipperPoint.longitude : undefined,
//               shipperLoca.get('zipPostal'),
//               shipperLoca.get('city'),
//               shipperLoca.get('stateProvince'),
//               shipperLoca.get('country'),
//               shipperLoca.get('name')
//             ) : undefined,
//             []) : undefined,
//           consignee ? new DispatchBusiness(consignee.id,
//             formatName(consignee.get('name')),
//             formatName(consignee.get('contactName')),
//             consignee.get('notes'),
//             consignee.get('phoneNumber'),
//             consignee.get('email'),
//             consigneeLoca ? new Address(consigneeLoca.id,
//               consigneeLoca.get('address'),
//               consigneePoint ? consigneePoint.latitude : undefined,
//               consigneePoint ? consigneePoint.longitude : undefined,
//               consigneeLoca.get('zipPostal'),
//               consigneeLoca.get('city'),
//               consigneeLoca.get('stateProvince'),
//               consigneeLoca.get('country'),
//               consigneeLoca.get('name')
//             ) : undefined,
//             []) : undefined,
//           broker ? new DispatchBusiness(broker.id,
//             formatName(broker.get('name')),
//             formatName(broker.get('contactName')),
//             broker.get('notes'),
//             broker.get('phoneNumber'),
//             broker.get('email'),
//             brokerLoca ? new Address(brokerLoca.id,
//               brokerLoca.get('address'),
//               brokerPoint ? brokerPoint.latitude : undefined,
//               brokerPoint ? brokerPoint.longitude : undefined,
//               brokerLoca.get('zipPostal'),
//               brokerLoca.get('city'),
//               brokerLoca.get('stateProvince'),
//               brokerLoca.get('country'),
//               brokerLoca.get('name')
//             ) : undefined,
//             []) : undefined,
//           dispatchJob.get('referenceNumber'),
//           dispatchJob.get('pickupNumber'),
//           dispatchJob.get('dropoffNumber'),
//           dispatchJob.get('invoiceNotes'),
//           dispatchJob.get('invoiceDate'),
//           dispatchJob.get('paymentDueInDays'),
//           dispatchJob.get('currency')
//         );
//         if (_dispatchJob.dispatchActionSequence && _dispatchJob.dispatchActionSequence.length !== 0) {
//           let actionArr = _dispatchJob.dispatchActionSequence.split(',');
//           let actionSequencePromises = [];
//           for (let i = 0; i < actionArr.length; i++) {
//             actionSequencePromises.push(getJobAction(actionArr[i]));
//           }
//           return Promise.all(actionSequencePromises).then(dispatchActions => {
//             _dispatchJob.dispatchActions = dispatchActions;
//             return _dispatchJob;
//           });
//         } else {
//           return _dispatchJob;
//         }
//       } else {
//         throw new Error(`getJob - Could not find DispatchJob ${objectId}`);
//       }
//     }
//   ).catch(error => {
//     throw new Error(error);
//   });
// }

export {
  addJob,
  countJobs,
  // getJob,
  getJobs,
  getSuggestedJobs,
  removeJob,
  destroyJob,
  updateJob,
};
