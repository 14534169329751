import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// Components
import AddEditModalView from 'components/AddEditModal/view/AddEditModal';

function deepCopyFormTemplate(formTemplate) {
  const copy = [];
  const formTemplateLen = formTemplate.length;
  for (let i = 0; i < formTemplateLen; i++) {
    const row = [];
    const rowLen = formTemplate[i].length;
    for (let j = 0; j < rowLen; j++) {
      row.push(Object.assign({}, formTemplate[i][j]));
    }
    copy.push(row);
  }
  return copy;
}

class AddEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addEditModal: { show: false }, addingInProgress: false, disableAdd: true };
    this.state.formEntries = deepCopyFormTemplate(props.formTemplate);
    this.checkRequiredFilled = this.checkRequiredFilled.bind(this);
    this.handleAddEdit = this.handleAddEdit.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.formTemplate !== this.state.formEntries) {
      const newState = { ...this.state };
      newState.formEntries = deepCopyFormTemplate(nextProps.formTemplate);
      this.setState(newState);
    } else if (nextProps.addingInProgress === true) {
      const newState = { ...this.state };
      newState.disableAdd = true;
      this.setState(newState);
    } else {
      this.checkRequiredFilled();
    }
  }

  checkRequiredFilled() {
    const newState = { ...this.state };
    const formEntriesLen = this.state.formEntries.length;
    for (let i = 0; i < formEntriesLen; i++) {
      const rowLen = this.state.formEntries[i].length;
      for (let j = 0; j < rowLen; j++) {
        if (this.state.formEntries[i][j].required && (!this.state.formEntries[i][j].value || (this.state.formEntries[i][j].type === 'address' && !this.state.formEntries[i][j].value.address))) {
          newState.disableAdd = true;
          return this.setState(newState);
        }
      }
    }
    newState.disableAdd = false;
    return this.setState(newState);
  }

  handleFormChange(row, column, newValue) {
    const newState = { ...this.state };
    newState.formEntries[row][column].value = newValue;
    this.setState(newState, () => {
      this.checkRequiredFilled();
    });
  }

  handleAddEdit() {
    const formValues = {};
    this.setState({ ...this.state, disableAdd: true });
    for (let i = 0; i < this.state.formEntries.length; i++) {
      for (let j = 0; j < this.state.formEntries[i].length; j++) {
        if (this.state.formEntries[i][j].type === 'number' && typeof this.state.formEntries[i][j].value === 'string') {
          formValues[this.state.formEntries[i][j].property] = parseInt(this.state.formEntries[i][j].value, 10);
        } else if (this.state.formEntries[i][j].type === 'date' && this.state.formEntries[i][j].value !== null) {
          formValues[this.state.formEntries[i][j].property] = this.state.formEntries[i][j].value;
        } else {
          formValues[this.state.formEntries[i][j].property] = this.state.formEntries[i][j].value;
        }
      }
    }
    this.props.handleAddEdit(formValues).then(() => {
      this.checkRequiredFilled();
      this.closeModal();
    }, (error) => {
      this.setState({ ...this.state, error });
      this.checkRequiredFilled();
    });
  }

  closeModal() {
    this.setState({ ...this.state, error: undefined }, () => {
      this.props.handleClose();
    });
  }

  render() {
    return (
      <AddEditModalView
        {...this.props}
        handleClose={this.closeModal}
        formEntries={this.state.formEntries}
        error={this.state.error}
        handleFormChange={this.handleFormChange}
        handleAddEdit={this.handleAddEdit}
        disableAdd={this.state.disableAdd}
      />
    );
  }
}

AddEditModal.propTypes = {
  addingInProgress: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  handleAddEdit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  formTemplate: PropTypes.array.isRequired,
};

export default AddEditModal;
