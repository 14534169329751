import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, FlatButton, Dialog, TextField } from 'material-ui';

// API
import { createCompanyLink } from 'api/Setters';

// Styles
import styles from './LinkCompanyModal.module.scss';

class LinkCompanyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dotNumber: '',
      nscNumber: '',
      linkingInProgress: false,
      error: '',
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.linkCompany = this.linkCompany.bind(this);
  }

  handleUpdate(property, value) {
    const newState = { ...this.state };
    newState[property] = value;
    this.setState(newState);
  }

  linkCompany() {
    this.setState({ ...this.state, linkingInProgress: true }, () => {
      createCompanyLink(this.state.dotNumber, this.state.nscNumber).then(() => {
        this.setState({ ...this.state, linkingInProgress: false, dotNumber: '' }, () => {
          this.props.handleClose();
        });
      }, (error) => {
        this.setState({ ...this.state, linkingInProgress: false, error: error.message });
      });
    });
  }

  render() {
    const actions = [
      <FlatButton
        label="Cancel"
        primary
        onClick={() => this.setState({ ...this.state, linkingInProgress: false, dotNumber: '' }, () => this.props.handleClose())}
      />,
      <FlatButton
        label="Submit"
        primary
        disabled={!this.state.dotNumber && !this.state.nscNumber}
        onClick={this.linkCompany}
      />,
    ];

    return (
      <Dialog
        title="Link Company"
        actions={this.state.linkingInProgress ? [<CircularProgress />] : actions}
        modal
        open={this.props.show}
        className="translate-me"
      >
        <TextField
          floatingLabelText="DOT Number"
          value={this.state.dotNumber}
          onChange={(e, newValue) => this.handleUpdate('dotNumber', newValue)}
        />
        <br />
        <TextField
          floatingLabelText="NSC Number"
          value={this.state.nscNumber}
          onChange={(e, newValue) => this.handleUpdate('nscNumber', newValue)}
        />

        <hr />

        <div>
          You will need authorization from the company to complete the link.
          <br /><br />
          To obtain authorization, log in to the other company's Switchboard account, and approve the link in the <b>Settings</b> page.
        </div>

        { this.state.error &&
          <div className={styles.error}>
            { this.state.error }
          </div>
        }
      </Dialog>
    );
  }
}

LinkCompanyModal.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
};

export default LinkCompanyModal;
