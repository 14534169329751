import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// API
import { addDispatchDriver, getDispatchDrivers, removeDispatchDriver } from 'api/Dispatch/DispatchDriver';
import { voidDispatchPayable } from 'api/Dispatch/DispatchPayable';

// CSAPI
import { getRecordByObjectId, getCurrentUser, getCurrentUserSessionToken, getAttribute } from 'sb-csapi/dist/AAPI';

// Components
import DriverAutocomplete from 'sbCore/DriverAutocomplete/DriverAutocomplete';

// Styling
import './style.scss';

/**
 * @description Contains a filter to select a driver and create a dispatch driver using the driver and a dispatch transfer
 * @param {string} dispatchTransferObjectId - The objectId of the dispatchTransfer object we wish to update.
 * @param {bool} [hideLabel] - Whether to hide the label of the autocomplete
 * @param {bool} [clearDispatchDriver] - If passed in, will clear the dispatch driver if present
 * @returns
 *
 * @example
 * <DispatchDriverAutocomplete dispatchTransfer={dispatchTransfer} warning />
*/
function DispatchDriverAutocomplete({ ...props }) {
  // ** useStates ** //
  const [selectedDriver, setSelectedDriver] = useState(undefined);
  const [dispatchDriver, setDispatchDriver] = useState(undefined);
  const [dispatchTransfer, setDispatchTransfer] = useState(undefined);

  // ** useEffects ** //
  useEffect(() => {
    let didCancel = false;

    async function _getDispatchTransfer() {
      if (!didCancel) {
        const dispatchTransfer = await getRecordByObjectId({ sessionToken: getCurrentUserSessionToken() }, 'DispatchTransfer', props.dispatchTransferObjectId);
        setDispatchTransfer(dispatchTransfer);
      }
    }

    _getDispatchTransfer();

    return () => { didCancel = true; };
  }, [props.dispatchTransferObjectId]);

  useEffect(() => {
    let didCancel = false;

    if (!dispatchTransfer) return;

    async function _getDispatchDrivers() {
      if (!didCancel) {
        const dispatchTransferObjectId = getAttribute(dispatchTransfer, 'objectId');
        const includedPointers = ['driver', 'dispatchTransfer', 'dispatchPayable'];

        const { dispatchDrivers } = await getDispatchDrivers(undefined, dispatchTransferObjectId, undefined, undefined, includedPointers);
        if (dispatchDrivers && dispatchDrivers.length > 0) {
          // A transfer should only contain one dispatch trailer, so get first element from the array.
          const driver = getAttribute(dispatchDrivers[0], 'driver', true);

          setDispatchDriver(dispatchDrivers[0]);
          setSelectedDriver(driver);
        }
      }
    }

    _getDispatchDrivers();

    return () => { didCancel = true; };
  }, [dispatchTransfer]);

  useEffect(() => {
    let didCancel = false;

    async function _addDispatchDriver() {
      if (!didCancel) {
        const keyValueObj = {
          dispatchTransfer,
          driver: selectedDriver,
        };
        const dispatchDriver = await addDispatchDriver(keyValueObj);
        setDispatchDriver(dispatchDriver);

        if (props.onSelectDriver) props.onSelectDriver(selectedDriver, dispatchDriver);
      }
    }

    async function _removeDispatchDriver() {
      if (!didCancel) {
        if (props.onSelectDriver) props.onSelectDriver();
        const dispatchPayable = getAttribute(dispatchDriver, 'dispatchPayable', true);
        if (dispatchPayable) {
          const user = getCurrentUser();
          const username = getAttribute(user, 'username', true);
          const currentDateTime = moment().format('DD-MM-YYYY HH:mm');
          const message = `- Payable voided by ${username} on ${currentDateTime}`;
          await voidDispatchPayable(dispatchPayable, message);
        }
        await removeDispatchDriver(dispatchDriver);
        setDispatchDriver(undefined);
      }
    }

    if (selectedDriver && !dispatchDriver && dispatchTransfer) {
      _addDispatchDriver();
    }

    if (!selectedDriver && dispatchDriver) {
      _removeDispatchDriver();
    }

    return () => { didCancel = true; };
  }, [selectedDriver]);

  useEffect(() => {
    if (props.clearDispatchDriver) setSelectedDriver(undefined);
  }, [props.clearDispatchDriver]);

  // ** Callbacks ** //
  function onSelectDriver(driver) {
    setSelectedDriver(driver);
  }

  return (
    <DriverAutocomplete
      hideLabel={props.hideLabel}
      onSelectDriver={(driver) => onSelectDriver(driver)}
      driver={selectedDriver}
      disabled={props.disabled}
      warning
    />
  );
}

export default DispatchDriverAutocomplete;
