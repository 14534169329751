import React, { useState, useEffect } from 'react';

// sbCore
import InputLabel from 'sbCore/InputLabel/InputLabel';
import InputText from 'sbCore/InputText/InputText';
import AddEditModal from 'sbCore/AddEditModal/AddEditModal';

// sb-csapi
import { Action } from 'sb-csapi/dist/enums/Action';
import { getAttribute } from 'sb-csapi/dist/AAPI';

// api
import { createDriverGroup, removeDriverGroup, updateDriverGroup } from 'api/Driver/DriverGroup';

// components
import DriverGroupDriverTable from 'components/DriverGroup/DriverGroupDriverTable/DriverGroupDriverTable';

/**
 * @description Renders a modal that contains driver group details
 *
 * @param {function} setVisible - sets the visibility of the modal
 * @param {object} driverGroup - An object which contains driver group info
 * @param {int} type - Action enum
 * @param {bool} isOpen - indicates whether the modal is open
 * @param {function} triggerRefresh - invert isRefresh bool
 *
 * @example
 * <DriverGroupModal
 *  setVisible={(bool) => setIsModalOpen(bool)}
 *  driverGroup={{ groupName, obejctId, createdBy, numberOfDrivers, objectId }}
 *  type={Action.ADD}
 *  isOpen={true}
 *  triggerRefresh={() => triggerRefresh()}
 * />
 */
function DriverGroupModal({ ...props }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [groupName, setGroupName] = useState();
  const [driverGroup, setDriverGroup] = useState(props.driverGroup);

  // If it's a 'add new group' modal, set is loading to false, else
  // Once driver group is successfully fetched and not undefined, set isloading to false
  useEffect(() => {
    let didCancel = false;

    if (props.isOpen && !didCancel) {
      if (props.type === Action.ADD) {
        setIsLoading(false);
        setGroupName();
        setDriverGroup();
      }
      if (props.driverGroup) {
        setIsLoading(false);
        setGroupName(props.driverGroup.groupName);
        setDriverGroup(props.driverGroup);
      }
    }

    return () => { didCancel = true; };

  }, [props.isOpen])

  // Handles main action button press behaviors (add and edit)
  async function handleAddEditRemove(actionType) {
    // Handle all driver group logic here, and set isSaving to let DriverGroupDriverTable handle all the driver list logic
    switch (actionType) {
      case Action.ADD:
        const _newDriverGroup = await createDriverGroup(groupName.trim());
        // set the objectID for the new driverGroup state if the user wants to add a new group and drivers at the same time so we can pass the new object ID to DriverGroupDriverTable
        setDriverGroup({ ...driverGroup, objectId: getAttribute(_newDriverGroup, 'objectId') })
        setIsSaving(true);
        break;
      case Action.EDIT:
        await updateDriverGroup(driverGroup.objectId, { name: groupName.trim() });
        setIsSaving(true);
        break;
      case Action.DELETE:
        await removeDriverGroup(driverGroup.objectId);
        props.triggerRefresh();
        break;
    }
    props.setVisible(false); // hide the modal
  }

  // Only trigger refresh driver group card once the group is updated (i.e. not in saving state anymore)
  useEffect(() => {
    if (!isSaving) props.triggerRefresh();
  }, [isSaving])

  const headerTemplate = (
    <div className="flex align-items-center dialog-header-title">
      {/* Modal title group name should stay the same regardless if the user has changed it or not */}
      {props.type === Action.EDIT && <div>Edit {driverGroup.groupName}</div>}
      {props.type === Action.ADD && <div>New Driver Group</div>}
    </div>
  )

  function generateNameInputWarning() {
    const showWarning = !groupName || groupName.trim() === '';
    if (showWarning) {
      return <InputLabel error className="text-xs">Name is required</InputLabel>
    }
  }

  return (
    <AddEditModal
      isLoading={isLoading}
      action={props.type}
      headerTemplate={headerTemplate}
      addButtonLabel="ADD GROUP"
      deletePopupMessage={`Are you sure you want to delete ${driverGroup?.groupName}`}
      isOpen={props.isOpen}
      onDelete={() => handleAddEditRemove(Action.DELETE)}
      onUpdate={() => handleAddEditRemove(Action.EDIT)}
      onCreate={() => handleAddEditRemove(Action.ADD)}
      onCancel={() => handleAddEditRemove()}
      disableCreateButton={generateNameInputWarning() ? true : false}
      disableUpdateButton={generateNameInputWarning() ? true : false}
    >
      <div>
        <InputLabel>Group Name</InputLabel>
        <InputText
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          error={!groupName || groupName.trim() === ''}
        />
        {generateNameInputWarning()}
      </div>

      <DriverGroupDriverTable
        type={props.type}
        driverGroup={driverGroup}
        isSaving={isSaving}
        setIsSaving={(bool) => setIsSaving(bool)}
      />
    </AddEditModal>
  );
}

export default DriverGroupModal;
