import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { createTempParseObject } from 'api/Helpers';
import { Card, CardActions, CardHeader, CardText } from 'material-ui/Card';
import FlatButton from 'material-ui/FlatButton';
import FontIcon from 'material-ui/FontIcon';
import CircularProgress from 'material-ui/CircularProgress';
import { MDBIcon } from 'mdbreact';

import FileUpload from 'sbCore/FileUpload/FileUpload';
import Image from 'sbCore/Image/Image';
import Button from 'sbCore/Button/Button';
import ProgressBar from 'sbCore/ProgressBar/ProgressBar';

import styles from './ChatBoxView.module.scss';
import './style.scss';

const ChatBoxView = (props) => {
  const friendUser = props.friends[0].get('user');
  let fullName = `${friendUser.get('firstName')} ${friendUser.get('lastName')}`;
  if (fullName.length > 17) fullName = fullName.substr(0, 17) + '...';

  // -- Initial Styles
  let className = styles.chatBoxView;
  if (props.shiftLeftForFriendsList) className = `${styles.chatBoxView} ${styles.shiftLeftForFriendsList}`;
  const headerStyles = { paddingLeft: '.5em', paddingTop: '.6em', paddingBottom: '.55em', fontFamily: 'roboto' };
  const titleStyle = { color: '#585858' };
  let minimizerStyle = `material-icons ${styles.minimizer}`;
  let closeStyle = `material-icons ${styles.close}`;

  // -- Initial Content
  let title = <span className={styles.chatBoxTitle} onClick={() => props.handleExpandChange(true)}>{fullName}</span>;
  let headerActions = [
    <button key="ha1" className={`${styles.headerAction}`}>
      <FontIcon className={minimizerStyle}>expand_more</FontIcon>
    </button>,
    <button key="ha2" className={`${styles.headerAction} ${styles.chatCloser}`} onClick={(e) => { e.stopPropagation(); props.handleChatClose(); }}>
      <FontIcon className={closeStyle}>close</FontIcon>
    </button>,
  ];

  const imagePreview = props.previewImage && (
    <div className="img-preview">
      <Image className="message-image" src={props.previewImage.objectURL} preview alt="failed to display" />
      <Button icon="pi pi-times" onClick={() => { props.removeImage(); }} className="p-button-rounded p-button-danger p-button-text p-button-sm cancel-img-btn" />
    </div>
  );

  if (props.expanded) {
    headerStyles.color = '#FBFBFB';
    headerStyles.background = '#337AB7';
    titleStyle.color = '#FBFBFB';
    minimizerStyle += ` ${styles.expanded}`;
    closeStyle += ` ${styles.expanded}`;

    title = (
      <button
        className={`${styles.chatBoxTitle} ${styles.driverProfileLinkButton}`}
        onClick={(e) => { e.stopPropagation(); props.handleGoToProfile(props.friends[0]); }}>
        {fullName}
      </button>
    );

    headerActions = [
      <button key="ha1" className={`${styles.headerAction}`}>
        <FontIcon className={minimizerStyle}>expand_less</FontIcon>
      </button>,
      <button key="ha2" className={`${styles.headerAction} ${styles.chatCloser}`} onClick={(e) => { e.stopPropagation(); props.handleChatClose(); }}>
        <FontIcon className={closeStyle}>close</FontIcon>
      </button>,
    ];
  }

  // This is where we change what is shown based on if the chatbox is a loadingbox
  let expandedChat = props.expanded;
  let handleExpandChange = props.handleExpandChange;
  let chatMessageText;
  let textAreaPlaceholder = 'Enter message...';
  let disableChatBasedOnELDVersion = false;
  let disableUploadImage = false;

  if (props.loadingBox) {
    headerActions = [];
    title = (
      <span className={`${styles.chatBoxTitle} ${styles.driverProfileLinkButton}`}>{fullName}</span>
    );
    chatMessageText = <div className={styles.circularProgressContainer}><CircularProgress /></div>;
    expandedChat = true;
    handleExpandChange = () => { };
    textAreaPlaceholder = '';
  } else {
    // MESSAGES --- order messages accordingly
    let chatMessages = props.chatMessages.filter(chatMessage => {
      return !chatMessage.get('hidden');
    });

    chatMessages.sort((chatMessageA, chatMessageB) => {
      // sort messages ascending
      const chatMessageATimeSinceEpoch = parseFloat(chatMessageA.get('timeSinceEpochId').split('&')[0]);
      const chatMessageBTimeSinceEpoch = parseFloat(chatMessageB.get('timeSinceEpochId').split('&')[0]);
      return chatMessageATimeSinceEpoch - chatMessageBTimeSinceEpoch;
    });

    // now map messages to html
    chatMessageText = chatMessages.map((chatMessage, index) => {
      const sender = chatMessage.get('sender');
      const senderName = `${sender.get('firstName')[0]}. ${sender.get('lastName')}`;
      const dateTime = moment(new Date(parseFloat(chatMessage.get('timeSinceEpochId').split('&')[0]))).format('DD-MM-YY HH:mm');
      let chatMessageContainerClass = styles.chatMessageContainer;
      const senderId = sender.id || sender._localId;
      if (senderId !== props.currentUser.id) {
        chatMessageContainerClass = `${styles.chatMessageContainer} ${styles.friendChatMessage}`; // different styling for friend-sent
      }

      // if the next message was sent by someone different, provide extra bottom margin
      const lastMessage = chatMessages[index - 1];
      if (lastMessage && ((lastMessage.get('sender').id || lastMessage.get('sender')._localId) !== senderId)) {
        chatMessageContainerClass += ` ${styles.differentUserMessage}`;
      }

      const message = chatMessage.get('message');
      const image = chatMessage.get('image');
      const seenBy = chatMessage.get('seenBy') || [];
      const isSeenByEveryone = props.friends.length === (seenBy.length - 1);

      return (
        <div key={chatMessage.id || chatMessage._localId} className={`${chatMessageContainerClass} chat-box-view`}>
          <div>
            {image && <Image className="message-image" src={image._url} preview alt="failed to display" />}
          </div>
          <div className={styles.message}>{message}</div>
          <div className={styles.name}>
            <span style={{ marginRight: '1em' }}>{senderName}</span>
            <span>
              {isSeenByEveryone && (senderId === props.currentUser.id) && (
                <MDBIcon icon="check-double" />
              )}
              {!isSeenByEveryone && (senderId === props.currentUser.id) && (
                <MDBIcon icon="check" />
              )}
            </span>
          </div>
          <div className={styles.timestamp}>{dateTime}</div>
        </div>
      );
    });
  }

  const uploadImageIcon = { icon: 'pi pi-fw pi-images', iconOnly: true };

  const imageValidationFailText = (
    <div className="image-validation-fail">
      Invalid file. Size must be less than 3.5 MB
    </div>
  );

  const imageValidationSuccessText = (
    <div className="image-begin-upload">
      File successfully validated
    </div>
  );

  return (
    <div className={`${className} chat-box-view`}>
      <Card onExpandChange={handleExpandChange} expanded={expandedChat}>
        <CardHeader
          className={styles.cardHeader}
          titleStyle={titleStyle}
          style={headerStyles}
          actAsExpander={true}>
        </CardHeader>

        {headerActions[1]}

        {title}

        {props.expanded &&
          <div className={styles.conversationContainer}>
            <div id={`${props.roomId}-chatbox-conversation`} className={`${styles.conversation}`}>
              <CardText>
                {chatMessageText}
              </CardText>
            </div>
            <div className={`grid ${styles.textAreaContainer}`}>
              {props.isImageValidationFail &&
                <div className="col-12 image-upload-notification">
                  {imageValidationFailText}
                </div>
              }
              {props.isImageValidationSuccess &&
                <div className="col-12 image-upload-notification">
                  {imageValidationSuccessText}
                </div>
              }
              {/* <div className="col-fixed">
                <FileUpload
                  className="img-upload-column"
                  mode="basic"
                  name="imageUpload"
                  accept="image/*"
                  disabled={disableUploadImage}
                  maxFileSize="3670016" // 3.5MB Limit
                  uploadHandler={props.handleImageUpload}
                  onValidationFail={props.handleImageValidationFail}
                  chooseOptions={uploadImageIcon}
                  auto
                  customUpload
                  />
              </div> */}
              <textarea
                id={`${props.roomId}-chatbox-textarea`}
                placeholder={textAreaPlaceholder}
                value={props.textInput}
                onClick={(e) => props.handleTextInputClick(e)}
                onChange={props.handleTextInputChange}
                onKeyPress={props.handleTextInputKeyPress}
                readOnly={props.loadingBox || disableChatBasedOnELDVersion}
                disabled={disableChatBasedOnELDVersion}
                autoFocus
                className="translate-me col"
              />
            </div>
            {props.previewImage && imagePreview}
            {props.isMessageUploading && <ProgressBar className="image-upload-progress-bar" mode="indeterminate" />}
          </div>
        }
      </Card>
    </div>
  );
};

ChatBoxView.propTypes = {
  loadingBox: PropTypes.bool,
  roomId: PropTypes.string,
  currentUser: PropTypes.object,
  friends: PropTypes.array,
  chatMessages: PropTypes.array,
  handleExpandChange: PropTypes.func,
  shiftLeftForFriendsList: PropTypes.bool,
  expanded: PropTypes.bool,
  textInput: PropTypes.string,
  handleTextInputClick: PropTypes.func,
  handleTextInputChange: PropTypes.func,
  handleTextInputKeyPress: PropTypes.func,
  handleGoToProfile: PropTypes.func,
  handleChatClose: PropTypes.func,
};

export default ChatBoxView;
