import React from 'react';
import PropTypes from 'prop-types';

// API
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL'
import * as Analytics from 'api/Analytics';

// Components
import FleetRegister from 'components/Register/container/FleetRegister';
import GetUserTypeScreen from 'components/Register/container/GetUserTypeScreen';
import OwnerOperatorRegister from 'components/Register/container/OwnerOperatorRegister';

// CSS
import styles from './RegistrationLayout.module.scss';

// Assets
const switchboardHorizontal = require('assets/images/switchboard-horizontal.png');

// Actions


class RegistrationLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: undefined,
      email: undefined,
      utm_source: undefined,
      referrer_url: undefined,
      clientId: undefined,
    };
  }

  componentDidMount() {
    // Analytics
    analytics.ready(function () {
      ga('require', 'linker');
      ga('linker:autoLink', ['www.onswitchboard.com']);
    });
    const queryStrObj = getDeserialized(this.props.location.search).params;
    if (queryStrObj.v === 'fleet') {
      const utm_source = queryStrObj.utm_source;
      const referrer_url = queryStrObj.referrer_url;
      this.setState({ ...this.state, 
        userType: 'fleet', 
        email: queryStrObj.email,
        utm_source,
        referrer_url,
      });
    }
    
    // Google Analytics Tracking
    ga((tracker) => {
      this.setState({ ...this.state, 
        clientId: tracker && tracker.get('clientId') ,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    return ( 
      <div className={styles.container}>
        <div className={styles.header}>
          <img className={styles.logo} role="presentation" src={switchboardHorizontal} />
        </div>
        <div className={styles.content}>
          {!this.state.userType &&
            <GetUserTypeScreen handleUserType={(type) => this.setState({ ...this.state, userType: type })} />
          }
          {this.state.userType === 'fleet' &&
            <FleetRegister 
              handleBack={() => this.setState({ ...this.state, userType: undefined })} 
              handleUserTypeChange={(userType) => this.setState({ ...this.state, userType: userType })} 
              email={this.state.email} 
              clientId={this.state.clientId}
              utm_source={this.state.utm_source}
              referrer_url={this.state.referrer_url}
              location={this.props.location}
            />
          }
          {this.state.userType === 'oo' &&
            <OwnerOperatorRegister 
              handleBack={() => this.setState({ ...this.state, userType: undefined })} 
              handleUserTypeChange={(userType) => this.setState({ ...this.state, userType: userType })} 
              email={this.state.email} 
              clientId={this.state.clientId}
              utm_source={this.state.utm_source}
              referrer_url={this.state.referrer_url}
            />
          }
        </div>
      </div>
    );
  }
}

RegistrationLayout.propTypes = {
};

export default RegistrationLayout;

