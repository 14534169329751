import React from 'react';
import PropTypes from 'prop-types';

// api
import { getAttribute, getCurrentUser } from 'api/Parse';
import { getGeofences } from 'api/Geofence/Geofence';

// enums
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { AttributeTypes } from 'enums/Driver';

// sbobjects
import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

// components
import SBCardEmptyContent from 'components/Shared/SBCard/SBCardEmptyContent';
import SBTable from 'components/Shared/SBTable/SBTable';
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import Alert from 'components/Alert/view/Alert';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from 'mdbreact';

// css
import './style.scss';

class GeofencesTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      geofences: [],
      page: 0,
      limit: 20,
      sortBy: new Sort('name', QuerySortOrderTypes.ASCENDING),
      filters: [],
      pageCount: 1,
    };

    this.state.filterFormFields = [
      {
        attrName: 'name',
        queryType: QueryRestrictionTypes.MATCHES,
        fullName: 'Name',
        type: 'text',
      },
    ];

    this.refreshState = this.refreshState.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handlePaginatorOnChange = this.handlePaginatorOnChange.bind(this);
  }

  componentDidMount() {
    // this.handleFilter();
    this.refreshState();
  }

  componentDidUpdate(prevProps) {
    // if (prevProps.parentId !== this.props.parentId) {
    //   // the parent refreshed it's state - we should trigger refreshState here as well
    //   this.handleFilter();
    // }
  }

  async handleFilter(filters = []) {

    filters = filters.map(filterObject => {
      const filter = new Filter(filterObject.attribute, filterObject.value, filterObject.queryType || filterObject.queryRestriction);
      return filter;
    });

    this.setState({ filters, page: 0 }, () => this.refreshState());
    // document.querySelector('.drivers-layout').scrollTop = 0;
  }

  async handleSort(attribute) {
    const { sortBy } = this.state;

    let { order } = sortBy;
    if (attribute === sortBy.attribute) {
      // selected the same attribute to sort, which means sort inverse order
      if (order === QuerySortOrderTypes.DESCENDING) {
        order = QuerySortOrderTypes.ASCENDING;
      } else {
        order = QuerySortOrderTypes.DESCENDING;
      }
    } else {
      // default to descending order if different
      order = QuerySortOrderTypes.DESCENDING;
    }

    this.setState({ sortBy: new Sort(attribute, order), page: 0 }, () => this.refreshState());
  }

  async handlePaginatorOnChange(type, inputValue) {
    const { pageCount } = this.state;
    const { page } = this.state;
    let newPage = page;
    if (type === 'PREVIOUS') {
      newPage--;
      if (newPage < 0) newPage = 0; // don't let values before 0
    } else if (type === 'NEXT') {
      newPage++;
      if (newPage >= pageCount - 1) newPage = pageCount - 1;
    } else if (type === 'SUBMIT') {
      newPage = inputValue - 1; // the actual value is one less, since we pass +1 newPage in the component (so paging doesnt start at 0)

      if (newPage < 0) {
        newPage = 0;
      } else if (newPage >= (pageCount - 1)) {
        newPage = pageCount - 1;
      }
    }
    if (newPage !== page) {
      this.setState({ page: newPage }, () => this.refreshState());
    }

    // document.querySelector('.drivers-list-select').scrollTop = 0;
    // document.getElementsByTagName('body').scrollTop;
    // window.scrollTo(0,0)
  }

  async refreshState() {
    this.setState({ isLoading: true });
    const { page, limit, filters, sortBy } = this.state;
    const geofences = await getGeofences(page, limit, filters, sortBy);

    const pageCount = Math.ceil(geofences.length / limit);

    this.setState({ geofences, pageCount, isLoading: false });
    if (document.querySelector('.geofences-table')) document.querySelector('.geofences-table').scrollTop = 0;
  }

  render() {
    const { props, state } = this;

    // TODO: Add a column for adding the geofence to the group, remember there can only be one

    /**
     * TABLE STYLES
     */
    const tableHeaderStyles = {
      name: { width: '60%' },
      shape: { width: '20%' },
      actions: { width: '20%' },
    };

    /**
     * TABLE HEADER/BODY
     */
    const tableHeaderColumns = [
      {
        element: <div>Geofence Name</div>,
        props: {
          style: tableHeaderStyles.name,
          // handleSort: () => { this.handleSort('name') },
          // isSortActive: (fetchAttributes.sortBy.attribute === AttributeTypes.USER_FULLNAME),
          // sortOrder: fetchAttributes.sortBy.order,
        },
      },
      { element: <div>Shape</div>, props: { style: tableHeaderStyles.shape } },
      { element: <div />, props: { style: tableHeaderStyles.actions } },
    ];

    const tableHeaderRows = [{
      key: 'geofences-list', columns: tableHeaderColumns,
    }];

    const tableBodyRows = state.geofences.map(geofence => {
      const name = getAttribute(geofence, 'name', true);
      const shape = getAttribute(geofence, 'getPoints', true) ? 'Polygon' : 'Rectangle';
      const objectId = getAttribute(geofence, 'objectId', true);

      const geofenceRowObj = {
        key: objectId,
        columns: [],
        props: {},
      };

      geofenceRowObj.columns = [
        { element: <div>{ name }</div>, props: { className: 'table-body-column-style' } },
        { element: <div>{ shape }</div>, props: { className: 'table-body-column-style' } },
        {
          element: (
            <ActionsContainer>
              {props.addCallback && (
                <MDBBtn
                  size="sm"
                  color="secondary"
                  onClick={async () => await props.addCallback(geofence)}
                >
                  <MDBIcon className="mr-1" icon="plus" />
                  {props.addButtonMessage}
                </MDBBtn>
              )}
              {props.removeCallback && (
                <MDBBtn
                  size="sm"
                  color="danger"
                  onClick={() => props.removeCallback(geofence)}
                >
                  <MDBIcon className="mr-1" icon="trash" />
                  {props.removeButtonMessage}
                </MDBBtn>
              )}
            </ActionsContainer>
          ),
          props: { className: 'table-body-column-style' },
        },
      ];

      return geofenceRowObj;
    });

    return (
      <MDBContainer className="geofences-table p-0" fluid>
        <ActionsContainer>
          <FilterForm
            className="d-inline-block"
            handleFilter={this.handleFilter}
            clearFilter={() => this.handleFilter([])}
            fields={state.filterFormFields}
          />
        </ActionsContainer>

        { state.isLoading && (
          <SBCardEmptyContent isContentLoader>Refreshing Geofences</SBCardEmptyContent>
        )}

        <MDBRow><MDBCol>
          <SBTable
            hover
            tableHeaderRows={tableHeaderRows}
            tableBodyRows={tableBodyRows}
            showPaginator
            activePage={state.page + 1}
            pageCount={state.pageCount}
            handlePaginatorOnChange={this.handlePaginatorOnChange}
            paginatorContainerClassName="paginator-container"
            emptyTableMessage={(
              <div>
                { ((!state.isLoading) && (tableBodyRows.length === 0)) && <div className="text-center">No geofences found. You can add a geofence by clicking the <b>Add geofence</b> button on Map</div> }
                { ((state.isLoading)) && <SBCardEmptyContent containerClassName="empty-table-loader" isContentLoader>{ 'Refreshing geofences' }</SBCardEmptyContent> }
              </div>
            )}
          />
        </MDBCol></MDBRow>
      </MDBContainer>
    );
  }
}

GeofencesTable.propTypes = {
  addCallback: PropTypes.func,
  removeCallback: PropTypes.func,
  addButtonMessage: PropTypes.string,
  removeButtonMessage: PropTypes.string,
};

export default GeofencesTable;
