import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Table } from 'react-bootstrap';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import StatusIcon from 'components/StatusIcon/view/StatusIcon';

// CSS
import styles from './DriverList.module.scss';

function DriverList(props) {
  return (
    <div className="defaultTable" onScroll={(e) => props.scrollHandler(e)}>
      <Table responsive condensed hover>
        <thead>
          <tr>
            <th>
              Name
            </th>
            <th className={styles.driverLegend}>
              <div className="inlineBlock">
                <span className={styles.activeLegend}>&#8226;</span> In Job
              </div>
              <div className="inlineBlock">
                <span className={styles.inactiveLegend}>&#8226;</span> Available
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          { props.drivers.length > 0 && props.drivers.filter((driver) => driver.get('enabled')).map((driver) =>
            <tr className={styles.listRow} key={driver.id} onClick={() => { return props.property === 'drivers' ? props.handleModalChoice([driver]) : props.handleModalChoice(driver); }}>
              <td>{ `${driver.get('user').get('firstName')} ${driver.get('user').get('lastName')}` }</td>
              <td className={`${styles.listColumn} rightText`}>
                { driver.get('driverStatus') && (driver.get('driverStatus').get('referenceInt') === 2) ?
                  <StatusIcon active />
                  :
                  <StatusIcon active={false} />
                }
              </td>
            </tr>
          )}
          { !props.loaded &&
            <tr>
              <td colSpan="42" className="loadingIconPadding"><LoadingIcon /></td>
            </tr>
          }
        </tbody>
      </Table>
    </div>
  );
}

DriverList.propTypes = {
  drivers: PropTypes.array.isRequired,
  handleModalChoice: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  loaded: PropTypes.bool.isRequired,
};

export default DriverList;
