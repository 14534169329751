import React, { useEffect, useState } from 'react';

// sbCore
import Dialog from 'sbCore/Dialog/Dialog';
import ScrollPanel from 'sbCore/ScrollPanel/ScrollPanel';
import Button from 'sbCore/Button/Button';
import { ConfirmPopup, confirmPopupSettings } from 'sbCore/ConfirmPopup/ConfirmPopup';

// enums
import { Action } from 'sb-csapi/dist/enums/Action';

import './style.scss';


/**
 * @description Renders a standardized modal with a header, body, and footer
 *
 * @param {int} action - Action enum, see sb-csapi/enums/Action
 * @param {JSX} headerTemplate - JSX to be displayed in the header
 * @param {string} addButtonLabel - Label for the add button
 * @param {String} [deletePopupMessage] - Message for the delete confirm popup
 * @param {bool} isLoading - Whether or not the modal is in the loading state
 * @param {bool} isOpen - Indicates if the modal is open/visible
 * @param {func} onDelete - Callback for when the delete button is triggered
 * @param {bool} [disableDeleteButton] - Indicates if the delete button should be disabled
 * @param {func} onUpdate - Callback for when the update button is triggered
 * @param {bool} [disableUpdateButton] - Indicates if the update button should be disabled
 * @param {func} onCreate - Callback for when the create button is triggered
 * @param {bool} [disableCreateButton] - Indicates if the update button should be disabled
 * @param {func} onCancel - Callback for when the cancel button is triggered
 * @param {bool} [hideDeleteButton] - Indicates if the delete button should be hidden
 * @param {string} sbVariant - Indicates for different styling
 * @param {string} cancelButtonLabel - Label for the cancel button
 * @param {string} isErrorMessage - Make cancel button red
 * @param {bool} outlineCancelButton - Use outline style for cancel button
 * @returns
 */
export default function AddEditModal({ ...props }) {
  const {
    children,
    action,
    headerTemplate,
    addButtonLabel,
    isLoading,
    isOpen,
    onDelete,
    disableDeleteButton,
    onUpdate,
    disableUpdateButton,
    onCreate,
    disableCreateButton,
    onCancel,
    hideDeleteButton,
    sbVariant,
    cancelButtonLabel,
    isErrorMessage,
    outlineCancelButton,
  } = props;

  const [isVisible, setIsVisible] = useState();

  let className = 'sbc-add-edit-modal';

  if (sbVariant === 'compact') className += ' sb-variant-compact';
  if (sbVariant === 'square') className += ' sb-variant-square';

  let cancelButtonClassName = 'p-button-text';

  if (sbVariant === 'square') cancelButtonClassName = '';
  if (outlineCancelButton) cancelButtonClassName = 'p-button-outlined';

  // if isOpen is updated outside of this component, update isVisible state
  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen])

  /* ------------------ Helpers ------------------- */
  /**
   * @description handles the initial delete button click
   */
  function handleDeleteButtonClick(event) {
    return (
      confirmPopupSettings({
        target: event.currentTarget,
        message: props.deletePopupMessage || 'Are you sure you want to proceed?',
        icon: 'pi pi-exclamation-triangle',
        acceptClassName: 'p-button-danger',
        accept: () => handleButtonClick(Action.DELETE),
      })
    )
  }

  /**
   * @description handles all the main actions of the modal
   */
  function handleButtonClick(actionType) {
    switch (actionType) {
      case Action.ADD:
        if (onCreate) onCreate();
        break;
      case Action.DELETE:
        if (onDelete) onDelete();
        break;
      case Action.EDIT:
        if (onUpdate) onUpdate();
        break;
      case Action.HIDE:
        if (onCancel) onCancel();
        break;
    }
  }

  const _headerTemplate = (
    <div className="sbc-add-edit-modal-header">
      {isLoading && "Processing..."}
      {!isLoading && headerTemplate}
    </div>
  );

  const _footerTemplate = (
    <div className={sbVariant === 'square' ? 'flex justify-content-center align-items-center mt-1 mb-2' : 'flex justify-content-between align-items-center mt-1 mb-2'}>
      {!isLoading && (
        <>
          <div>
            {/* ConfirmPopup component needs to be present on the page to interact with the confirmPopupSettings function */}
            <ConfirmPopup />
            {action === Action.EDIT && !hideDeleteButton && (
              <Button
                label="DELETE"
                className="p-button-danger"
                onClick={(event) => handleDeleteButtonClick(event)}
                disabled={!!disableDeleteButton}
              />
            )}
          </div>
          <div>
            <Button
              label={cancelButtonLabel || 'CANCEL'}
              className={cancelButtonClassName}
              severity={isErrorMessage ? 'danger' : 'primary'}
              onClick={() => handleButtonClick(Action.HIDE)}
            />
            {action === Action.ADD && (
              <Button
                label={addButtonLabel}
                onClick={() => handleButtonClick(Action.ADD)}
                disabled={!!disableCreateButton}
              />
            )}
            {action === Action.EDIT && (
              <Button
                label="SAVE CHANGES"
                onClick={() => handleButtonClick(Action.EDIT)}
                disabled={!!disableUpdateButton}
              />
            )}
          </div>
        </>
      )}
    </div>
  )

  return (
    <Dialog
      className={className}
      header={_headerTemplate}
      footer={_footerTemplate}
      contentClassName="sbc-add-edit-modal-content"
      visible={isVisible}
      style={{ width: '70em' }} // TODO: Add responsiveness to the width based on the screen size
      closable={false}
    >
      {isLoading && (
        <div className="flex flex-column h-full w-full justify-content-center align-items-center">
          <i className="pi pi-spin pi-spinner" />
        </div>
      )}
      {!isLoading && (
        <ScrollPanel className="sbc-add-edit-modal-scrollpanel">
          <div className="flex flex-column">
            {children}
          </div>
        </ScrollPanel>
      )}
    </Dialog>
  );
}
