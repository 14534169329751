import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Components
import ConfirmModalView from 'components/ConfirmModal/view/ConfirmModal';

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  handleKeyDown(event) {
    if (event && event.which === 13) {
      this.props.handleModalChoice(true);
    }
  }

  render() {
    const { title, handleModalChoice, handleClose, show } = this.props;
    return (
      <ConfirmModalView title={title} show={show} handleModalChoice={handleModalChoice} handleClose={handleClose} handleKeyDown={this.handleKeyDown} body={this.props.children} alertMode={this.props.alertMode} primaryLabel={this.props.primaryLabel} secondaryLabel={this.props.secondaryLabel} closeMode={this.props.closeMode} />
    );
  }
}

ConfirmModal.propTypes = {
  title: PropTypes.string,
  handleModalChoice: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node,
  alertMode: PropTypes.bool,
  closeMode: PropTypes.bool,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
};

// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators({
//     // fetchJobsIfNeeded,
//     fetchDocumentsForState,
//   }, dispatch),
// });

export default ConfirmModal;
