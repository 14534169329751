// libraries
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Glyphicon } from 'react-bootstrap';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

// api
import { getQueryParameter } from 'api/URL';
import * as Analytics from 'api/Analytics';
import { getAttribute } from 'api/Parse';
import {
  getCompanyAnalytics,
  updateCompanyAnalytics,
} from 'api/CompanyAnalytics/CompanyAnalytics';

// components
import Title from 'components/Shared/Title/Title';
import SubNavigationBar from 'components/Shared/SubNavigationBar/SubNavigationBar';
import UpcomingSafetyRecurringRemindersView from 'components/SafetyRecurringReminder/UpcomingSafetyRecurringRemindersView/UpcomingSafetyRecurringRemindersView';
import PastSafetyRecurringRemindersView from 'components/SafetyRecurringReminder/PastSafetyRecurringRemindersView/PastSafetyRecurringRemindersView';

// styles
import styles from './RemindersLayout.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

class RemindersLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: 0,
      reminderTypes: [
        { attrName: '0', placeholder: 'Active Reminders', enabled: true },
        { attrName: '1', placeholder: 'Inactive Reminders', enabled: false }
      ],
    }

    // TODO: add filters to each of the views below - will need these filters
    // this.state.filterFormFields = [
    //   {
    //     attrName: 'triggerDate',
    //     queryType: 'date',
    //     dateQueryType: 'dateMonth',
    //     fullName: 'Month',
    //     placeholder: '.....',
    //     type: 'date'
    //   },
    //   {
    //     attrName: 'reminderType',
    //     fullName: 'Type',
    //     type: 'select',
    //     selectArr: reminderTypes
    //   },
    //   {
    //     attrName: 'name',
    //     fullName: 'Name',
    //     placeholder: '.....',
    //     type: 'text'
    //   }
    // ];

    this.handlePopup = this.handlePopup.bind(this);
  }

  async componentDidMount() {
    document.title = 'Reminders - Switchboard'
    const clientHeight = document.documentElement.clientHeight
    this.remindersTableHeight = document.documentElement.clientHeight * 0.7 // 70vh defined by CSS
    if (clientHeight <= 1366) {
      // laptop size
      this.remindersTableHeight = clientHeight * 0.6
    }
    this.remindersTableRowAmount = Math.round(this.remindersTableHeight / 43) + 5 // amount of el's to display
    this.pageCount = 0 // result fetch multiplier

    // Check company analytics status
    const companyAnalytics = await getCompanyAnalytics();
    const VIEWREMINDERS = getAttribute(companyAnalytics, 'VIEWREMINDERS');
    if (!VIEWREMINDERS) {
      Analytics.track('Viewed Reminders & Expiries');
      updateCompanyAnalytics(companyAnalytics, { VIEWREMINDERS: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    let tempView = getQueryParameter(nextProps.location.search, 't');
    const tabs = ['', 'past'];
    this.setState({ ...this.state, selectedTab: tabs.indexOf(tempView) !== -1 ? tabs.indexOf(tempView) : 0 });
  }

  handlePopup() {
    this.setState({ ...this.state, showPopup: true, showDrawer: false })
  }

  render() {
    let arrow;
    if (this.state.showDrawer) {
      arrow = (
        <Glyphicon
          glyph='glyphicon glyphicon-exclamation-sign'
          onClick={this.handlePopup}
        />
      );
    }

    let navItems = [
      {
        name: 'Upcoming Reminders',
        className: 'col-4',
        onClick: () => {
          this.setState({ ...this.state, selectedTab: 0 });
        },
        isActive: this.state.selectedTab === 0,
        to: '?view=reminders',
      },
      {
        name: 'Past Reminders',
        className: 'col-4',
        onClick: () => {
          this.setState({ ...this.state, selectedTab: 1 });
        },
        isActive: this.state.selectedTab === 1,
        to: '?view=reminders&t=past',
      },
    ];

    return (
      <div className={styles.RemindersLayout}>
        <MDBContainer className={'mw-100 pb-0 ' + styles.container}>
          <MDBRow>
            <MDBCol>
              <Title title="Expiries & Reminders" />
            </MDBCol>
          </MDBRow>

          <MDBRow style={{ marginBottom: '2em' }}>
            <MDBCol>
              <SubNavigationBar navItems={navItems} />
            </MDBCol>
          </MDBRow>

          {this.state.selectedTab === 0 && (
            <UpcomingSafetyRecurringRemindersView />
          )}

          {this.state.selectedTab === 1 && (
            <PastSafetyRecurringRemindersView />
          )}
        </MDBContainer>
      </div>
    )
  }
}

RemindersLayout.propTypes = {
  Reminder: PropTypes.object.isRequired,
  location: PropTypes.object,
}

const mapStateToProps = state => {
  const { Reminder } = state
  return {
    Reminder
  }
}

export default connect(mapStateToProps, null, null, { context: StoreContext })(RemindersLayout);

