import React from 'react';
import moment from 'moment-timezone';

// sbCore Components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import PaymentStatusBadge from 'sbCore/PaymentStatusBadge/PaymentStatusBadge';

// components
import DispatchContactAutocomplete from 'components/Dispatch/DispatchContactAutocomplete/DispatchContactAutocomplete';
import DispatchOrganizationCard from 'components/Dispatch/DispatchOrganizationAutocomplete/DispatchOrganizationCard';


// sb-csapi
import { getAttribute } from 'sb-csapi/dist/AAPI';

// api
import { updateDispatchReceivable } from 'api/Dispatch/DispatchReceivable';

// Styles
import './style.scss';

/**
 * @description Section that allows editing job accounting information
 * @param {DispatchReceivable} dispatchReceivable - The selected DispatchReceivable
 * @param {Date} invoicedDate - The date the job's status was changed to invoiced
 * @param {Function} handleInvoicedDateChange - Updates the record's invoicedDate field
 * @param {Boolean} isVoided - Whether or not the DispatchReceivable is voided
 * @param {Boolean} [isInvoicedDateInvalid] - Boolean for if the invoice date's invalid
 * @returns
 *
 * @example
 * <ReceivableInformation dispatchJob={dispatchJob} />
 */
function ReceivableInformation({ ...props }) {
  // ** UI Components ** //
  const accountingInformationHeader = (
    <div>Receivable Information</div>
  );

  const paymentStatus = (
    <>
      <InputLabel>Payment Status</InputLabel>
      <PaymentStatusBadge className="z-depth-0" paymentStatusInt={props.paymentStatusInt} isVoided={props.isVoided} />
    </>
  );

  const invoicedDate = (
    <>
      <InputLabel>Invoiced Date</InputLabel>
      <div className="text-sm">
        {props.invoicedDate ? moment(props.invoicedDate).format('DD-MM-YYYY') : ''}
      </div>
    </>
  );

  const dispatchJobAccounting = getAttribute(props.dispatchReceivable, 'dispatchJobAccounting', true);
  const dispatchReceivableContact = getAttribute(props.dispatchReceivable, 'contact', true);
  const dispatchJob = getAttribute(dispatchJobAccounting, 'dispatchJob', true);
  const dispatchOrganization = getAttribute(dispatchJob, 'customerDispatchOrganization', true);

  async function updateDispatchReceivableContact(contact) {
    return await updateDispatchReceivable(
      undefined, // options
      props.dispatchReceivable, // dispatchReceivable
      undefined, // dispatchReceivableObjectId
      { contact }, // keyValueObj
      true, // save
    )
  }
  
  return (
    <ColoredSection title={accountingInformationHeader} className="receivable-information">

      <div className="flex flex-column">
        <div className="flex">
          <div className="mr-7">
            {paymentStatus}
          </div>
          <div className="mr-7">
            {invoicedDate}
          </div>
        </div>
        <div className="mt-2 mb-2">
          <hr />
        </div>
        <div className="flex column-gap-8">
          <div className="flex flex-column">
            <InputLabel>Organization</InputLabel>
            <DispatchOrganizationCard
              dispatchOrganization={dispatchOrganization}
              disableActions
            />
          </div>
          <DispatchContactAutocomplete
            onSelectDispatchContact={(contact) => updateDispatchReceivableContact(contact)}
            customerDispatchOrganization={dispatchOrganization}
            dispatchJob={dispatchJob}
            dispatchContact={dispatchReceivableContact}
            allowAddDispatchOrganizationContact={!!dispatchOrganization}
            disabled={!(!!dispatchOrganization)}
          />
        </div>
      </div>

    </ColoredSection>
  );
}

export default ReceivableInformation;
