import React from 'react';
import PropTypes from 'prop-types';

// API
import * as Helpers from 'api/Helpers';

// Components
import SBTable from 'components/Shared/SBTable/SBTable';

class OdometerReadingsTableBeta extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.generateTableBodyRowData = this.generateTableBodyRowData.bind(this);
  }

  // componentDidUpdate(prevProps) {
  //   const { props } = this;
  //   if (prevProps.odometerReadings !== props.odometerReadings) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  generateTableBodyRowData() {
    const { props } = this;
    let tableBodyRows;
    if (props.odometerReadings !== undefined) {
      tableBodyRows = [{
        key: 0,
        columns: [
          {
            // Odometer Start
            element: (
              <div>
                {(props.odometerReadings.odometerStartKm && props.distanceUnitFilter.value === 'km') ? props.odometerReadings.odometerStartKm.toFixed(0) : Helpers.convertDistance(props.odometerReadings.odometerStartKm, 'km', 'mi').toFixed(0)}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          {
            // Odometer End
            element: (
              <div>
                {(props.odometerReadings.odometerEndKm && props.distanceUnitFilter.value === 'km') ? props.odometerReadings.odometerEndKm.toFixed(0) : Helpers.convertDistance(props.odometerReadings.odometerEndKm, 'km', 'mi').toFixed(0)}
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
          {
            // Odometer Difference
            element: (
              <div>
                <b> {(props.odometerReadings.odometerDiffKm && props.distanceUnitFilter.value === 'km') ? props.odometerReadings.odometerDiffKm.toFixed(0) : Helpers.convertDistance(props.odometerReadings.odometerDiffKm, 'km', 'mi').toFixed(0)} </b>
              </div>
            ),
            props: { className: 'table-body-column-style' },
          },
        ],
      }];
    } else {
      tableBodyRows = [{
        key: 0,
        columns: [],
      }];
    }

    return tableBodyRows;
  }

  render() {
    const { props } = this;

    const tableHeaderStyles = {
      odometer: { width: '30%' },
      odometerDifference: { width: '40%' },
    };

    const tableHeaderRows = [{
      key: 'sb-ifta-odometer-header',
      columns: [
        {
          element: <div>{`Start Odometer (${props.distanceUnitFilter.value})`}</div>,
          props: { style: tableHeaderStyles.odometer },
        },
        {
          element: <div>{`End Odometer (${props.distanceUnitFilter.value})`}</div>,
          props: { style: tableHeaderStyles.odometer },
        },
        {
          element: <div>{`Total Odometer Difference (${props.distanceUnitFilter.value})`}</div>,
          props: { style: tableHeaderStyles.odometerDifference },
        },
      ],
    }];

    const tableBodyRows = this.generateTableBodyRowData();

    return (
      <div>
        <SBTable
          tableHeaderRows={tableHeaderRows}
          tableBodyRows={tableBodyRows}
          isLoading={props.odometerReadings !== undefined ? (props.odometerReadings.odometerDiffKm === undefined || props.odometerReadings.odometerStartKm === undefined || props.odometerReadings.odometerEndKm === undefined) : true}
        />
      </div>
    );
  }
}

OdometerReadingsTableBeta.propTypes = {
  distanceUnitFilter: PropTypes.instanceOf(Object),
};

export default OdometerReadingsTableBeta;
