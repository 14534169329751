import React from 'react';
import PropTypes from 'prop-types';
import { Popup } from 'react-mapbox-gl';
import moment from 'moment-timezone';

// Component
import DutyStatusLabel from 'components/DutyStatusLabel/view/DutyStatusLabel';

// CSS
import styles from './ComponentStyles.module.scss';

function ELDEventPopup(props) {
  if (props.eldEvent.get('eldEventTypeCodeInt') === 11 || props.eldEvent.get('eldEventTypeCodeInt') === 31) {
    // Off Duty
    return (
      <Popup
        anchor="bottom"
        offset={{ bottom: [0, -20] }}
        coordinates={props.coordinates}
      >
        <div className={styles.popup}>
          <b>Switched to <DutyStatusLabel eldStatusInt={1} /></b>
          <br />
          <i>{ props.eldEvent.get('note')}</i>
          <div style={{ fontSize: '0.8em', marginTop: '0.5em' }}>
            <b>{moment(props.eldEvent.get('eventDateTime')).format('LL')}</b>
            <br />
            {moment(props.eldEvent.get('eventDateTime')).format('LT')}
          </div>
        </div>
      </Popup>
    );
  }
  if (props.eldEvent.get('eldEventTypeCodeInt') === 12) {
    // Sleeper Berth
    return (
      <Popup
        anchor="bottom"
        offset={{ bottom: [0, -20] }}
        coordinates={props.coordinates}
      >
        <div className={styles.popup}>
          <b>Switched to <DutyStatusLabel eldStatusInt={2} /></b>
          <br />
          <i>{ props.eldEvent.get('note')}</i>
          <div style={{ fontSize: '0.8em', marginTop: '0.5em' }}>
            <b>{moment(props.eldEvent.get('eventDateTime')).format('LL')}</b>
            <br />
            {moment(props.eldEvent.get('eventDateTime')).format('LT')}
          </div>
        </div>
      </Popup>
    );
  }
  if (props.eldEvent.get('eldEventTypeCodeInt') === 13) {
    // Driving
    return (
      <Popup
        anchor="bottom"
        offset={{ bottom: [0, -20] }}
        coordinates={props.coordinates}
      >
        <div className={styles.popup}>
          <b>Switched to <DutyStatusLabel eldStatusInt={3} /></b>
          <br />
          <i>{ props.eldEvent.get('note')}</i>
          <div style={{ fontSize: '0.8em', marginTop: '0.5em' }}>
            <b>{moment(props.eldEvent.get('eventDateTime')).format('LL')}</b>
            <br />
            {moment(props.eldEvent.get('eventDateTime')).format('LT')}
          </div>
        </div>
      </Popup>
    );
  }
  if (props.eldEvent.get('eldEventTypeCodeInt') === 14 || props.eldEvent.get('eldEventTypeCodeInt') === 32) {
    // On Duty
    return (
      <Popup
        anchor="bottom"
        offset={{ bottom: [0, -20] }}
        coordinates={props.coordinates}
      >
        <div className={styles.popup}>
          <b>Switched to <DutyStatusLabel eldStatusInt={4} /></b>
          <br />
          <i>{ props.eldEvent.get('note')}</i>
          <div style={{ fontSize: '0.8em', marginTop: '0.5em' }}>
            <b>{moment(props.eldEvent.get('eventDateTime')).format('LL')}</b>
            <br />
            {moment(props.eldEvent.get('eventDateTime')).format('LT')}
          </div>
        </div>
      </Popup>
    );
  }
  if (props.eldEvent.get('eldEventTypeCodeInt') === 63 || props.eldEvent.get('eldEventTypeCodeInt') === 64) {
    // Engine Shutdown
    return (
      <Popup
        anchor="bottom"
        offset={{ bottom: [0, -20] }}
        coordinates={props.coordinates}
      >
        <div className={styles.popup}>
          <b>Engine Shutdown (Ignition OFF)</b>
          <br />
          <i>{ props.eldEvent.get('note')}</i>
          <div style={{ fontSize: '0.8em', marginTop: '0.5em' }}>
            <b>{moment(props.eldEvent.get('eventDateTime')).format('LL')}</b>
            <br />
            {moment(props.eldEvent.get('eventDateTime')).format('LT')}
          </div>
        </div>
      </Popup>
    );
  }
  if (props.eldEvent.get('eldEventTypeCodeInt') === 61 || props.eldEvent.get('eldEventTypeCodeInt') === 62) {
    // Engine Startup
    return (
      <Popup
        anchor="bottom"
        offset={{ bottom: [0, -20] }}
        coordinates={props.coordinates}
      >
        <div className={styles.popup}>
          <b>Engine Startup (Ignition ON)</b>
          <br />
          <i>{ props.eldEvent.get('note')}</i>
          <div style={{ fontSize: '0.8em', marginTop: '0.5em' }}>
            <b>{moment(props.eldEvent.get('eventDateTime')).format('LL')}</b>
            <br />
            {moment(props.eldEvent.get('eventDateTime')).format('LT')}
          </div>
        </div>
      </Popup>
    );
  }
  return <div />;
}

ELDEventPopup.propTypes = {
  coordinates: PropTypes.array.isRequired,
  eldEvent: PropTypes.object.isRequired,
};

export default ELDEventPopup;
