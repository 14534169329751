import Parse from 'parse';
import React from 'react';
import PropTypes from 'prop-types';
import history from "../../../sbHistory";
import uniqid from 'uniqid';

import { updateChatSeenStatus } from 'api/Chat';
import { compressWhitespace } from 'api/Helpers';
import { getCurrentUser } from 'api/Parse';
// import { getCurrentUser } from 'api/Getters';
import * as ChatActs from 'actions/Chat';

import ChatBoxView from '../view/ChatBoxView';

import styles from './ChatBox.module.scss';

class ChatBox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openChatLoading: false,
      expanded: true,
      roomId: undefined,
      textInput: undefined,
      chatMessages: [],
      disableScrollDown: false, // disable scrolling conversation to the bottom
      previewImage: undefined,
      parseImage: undefined,
      isImageValidationFail: false,
      isImageValidationSuccess: false,
      isMessageUploading: false,
    };
    this.refreshState = this.refreshState.bind(this);
    this.handleExpandChange = this.handleExpandChange.bind(this);
    this.handleTextInputClick = this.handleTextInputClick.bind(this);
    this.handleTextInputChange = this.handleTextInputChange.bind(this);
    this.handleTextInputKeyPress = this.handleTextInputKeyPress.bind(this);
    this.goToProfile = this.goToProfile.bind(this);
    this.closeChat = this.closeChat.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.handleImageValidationFail = this.handleImageValidationFail.bind(this);
  }

  componentDidMount() {
    if (this.props.roomId) {
      this.refreshState(this.props.roomId);
    }
  }

  // shouldComponentUpdate = (nextProps, nextState) => {
  //   const nextChatRoom = nextProps.chatRoom;
  //   const thisChatRoom = this.props.chatRoom;
  //
  //   if (nextProps.roomId && nextProps.roomId !== this.props.roomId) {
  //     console.log('good')
  //   }
  //   if (nextChatRoom && thisChatRoom) {
  //     if ((nextChatRoom.status !== thisChatRoom.status) || (nextChatRoom.messages.length !== thisChatRoom.messages.length)) {
  //       console.log('gooder');
  //     }
  //   }
  //   return false;
  // }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.roomId && (nextProps.roomId !== this.props.roomId)) {
      this.refreshState(nextProps.roomId);
    } else if (nextProps.chatRoom && this.props.chatRoom) {
      const nextChatRoom = nextProps.chatRoom;
      const thisChatRoom = this.props.chatRoom;
      if ((nextChatRoom.status !== thisChatRoom.status)
        || (nextChatRoom.messages && thisChatRoom.messages && nextChatRoom.messages.length !== thisChatRoom.messages.length)
        || ((nextChatRoom.messages && !thisChatRoom.messages) || (!nextChatRoom.messages && thisChatRoom.messages))) {
        this.refreshState(undefined, true, nextProps);
      }
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { roomId, disableScrollDown } = this.state;
    const conversationElement = document.getElementById(`${roomId}-chatbox-conversation`);
    const activateScrollDown = !disableScrollDown && (prevState !== this.state);

    if (activateScrollDown && conversationElement) {
      // scroll to bottom of conversation automagically
      document.getElementById(`${roomId}-chatbox-conversation`).scrollTop = conversationElement.scrollHeight;
    }
  }

  refreshState = async (roomId, messageOnlyUpdate, nextProps) => {
    if (messageOnlyUpdate && nextProps.chatRoom) {
    //   console.log('chat props')
    // console.log(nextProps.chatRoom)
      this.setState({ ...this.state, chatMessages: [].concat(nextProps.chatRoom.messages) });
      return;
    }
    
    this.setState({ ...this.state, roomId, textInput: undefined, chatMessages: [], openChatLoading: true }, () => {
      if (document.getElementById(`${roomId}-chatbox-textarea`)) document.getElementById(`${roomId}-chatbox-textarea`).value = '';

      if (roomId) {
        ChatActs.deleteChatMessagesFromState(roomId).then(
          () => {
            ChatActs.fetchChatForState(roomId).then(async chatMessages => {
              const updatedChatSeenPromises = [];
              chatMessages.map(chatMessage => {
                updatedChatSeenPromises.push(updateChatSeenStatus(chatMessage.id));
              });
              await this.setState({ ...this.state, chatMessages, openChatLoading: false });
              await Promise.all(updatedChatSeenPromises);
            }
            );
          }
        );
      }
    });
  }

  handleExpandChange = (expanded) => {
    this.setState({ ...this.state, expanded });
  }

  handleTextInputClick = (e) => {
    ChatActs.handleMarkMessagesRead(this.props.roomId);
  }

  handleTextInputChange = (e) => {
    // console.log(e.target.value);
    let value = e.target.value;
    if (!value) return this.setState({ ...this.state, textInput: undefined });
    if (compressWhitespace(value) === '') value = '';
    this.setState({ ...this.state, textInput: value, disableScrollDown: true });
  }

  handleTextInputKeyPress = (e) => {
    // ignore shift + enter; only submit on 'pure' Enter
    if (e.key === 'Enter' && !e.shiftKey && !this.state.isMessageUploading) {
      // console.log(e.key, e.shiftKey);
      // clear input -> add msg to state <-> upload to db
      document.getElementById(`${this.state.roomId}-chatbox-textarea`).value = '';
      const message = this.state.textInput;
      const parseImage = this.state.parseImage;
      // const friendUsers = this.props.friends.map(friend => friend.get('user'));
      this.setState({ ...this.state, textInput: undefined, parseImage: undefined, previewImage: undefined, isMessageUploading: true }, () => {
        // for now, send all msgs to codrivers as well
        let allCoDrivers = [];
        for (let i = 0; i < this.props.friends.length; i++) {
          const friend = this.props.friends[i];
          const vehicle = friend.get('vehicle');
          if (vehicle && vehicle.get('drivers') && vehicle.get('drivers').length > 0) {
            // make sure we arent sending to the same driver twice
            const coDrivers = vehicle.get('drivers').filter(coDriver => coDriver.id !== friend.id);
            allCoDrivers = allCoDrivers.concat(coDrivers);
          }
        }

        const friends = [].concat(this.props.friends, allCoDrivers);

        ChatActs.sendChatMessage(this.state.roomId, message, friends, parseImage).then(
          () => {
            this.setState({ ...this.state, disableScrollDown: false, isMessageUploading: false });
          }
        );
      });
    }
  }
  
  handleImageUpload = ({files, options}) => {
    const file = files[0];
    const fileName = uniqid();
    const fileType = file.type
    const parseImageFile = new Parse.File(fileName, file, fileType);

    this.setState({ ...this.state, parseImage: parseImageFile, previewImage: file, isImageValidationSuccess: true });

    setTimeout(function () {
      this.setState({ ...this.state, isImageValidationSuccess: false})
    }.bind(this), 3000);

    // focus on text area once image uploads
    document.getElementById(`${this.state.roomId}-chatbox-textarea`).focus();

    // clears FileUpload's storing of image so a new image can be uploaded if needed.
    options.clear();
  }

  handleImageValidationFail =  () => {
    this.setState({ ...this.state, isImageValidationFail: true})

    setTimeout(function () {
      this.setState({ ...this.state, isImageValidationFail: false})
    }.bind(this), 3000);
  }

  removeImage = () => {
    this.setState({ ...this.state, parseImage: undefined, previewImage: undefined });
  }

  goToProfile = (driver) => {
    history.push({ pathname: 'driver', search: "driver=" + driver.id });
  }
  // 20
  closeChat = () => {
    // x button
    const { roomId } = this.state;

    if (document.getElementById(`${roomId}-chatbox-textarea`))
      document.getElementById(`${roomId}-chatbox-textarea`).value = '';
    this.props.handleChatClose().then(
      () => {
        this.setState({ ...this.state, textInput: undefined, chatMessages: [] }, () => {
          ChatActs.deleteChatMessagesFromState(roomId);
        });
      }
    );
  }


  render() {
    const { Chat, friends, shiftLeftForFriendsList } = this.props;
    const { expanded, textInput, roomId, chatMessages, openChatLoading, previewImage, isImageValidationFail, isImageValidationSuccess, isMessageUploading } = this.state;

    return (
      <div className={styles.chatBox}>
        <ChatBoxView
          loadingBox={openChatLoading}
          roomId={roomId}
          currentUser={getCurrentUser()}
          friends={friends}
          chatMessages={chatMessages}
          shiftLeftForFriendsList={shiftLeftForFriendsList}
          handleExpandChange={this.handleExpandChange}
          expanded={expanded}
          textInput={textInput}
          handleTextInputClick={this.handleTextInputClick}
          handleTextInputChange={this.handleTextInputChange}
          handleTextInputKeyPress={this.handleTextInputKeyPress}
          handleGoToProfile={this.goToProfile}
          handleChatClose={this.closeChat}
          handleImageUpload={this.handleImageUpload}
          handleImageValidationFail={this.handleImageValidationFail}
          previewImage={previewImage}
          removeImage={this.removeImage}
          isImageValidationFail={isImageValidationFail}
          isImageValidationSuccess={isImageValidationSuccess}
          isMessageUploading={isMessageUploading}
        />
      </div>
    );
  }
}



//  <button onClick={() => ChatAPI.test(friends[0], this.state.roomId)}>lol</button>
ChatBox.propTypes = {
  chatRoom: PropTypes.object, // although chatroom can encompass roomid and friends, we pass the seperately for optimization
  roomId: PropTypes.string,
  friends: PropTypes.array,
  shiftLeftForFriendsList: PropTypes.bool,
  handleChatClose: PropTypes.func,
};

export default ChatBox;
