import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';


//api
import { getUncertifiedELDDailyCetifications } from 'api/ELD/ELDDailyCertification';
import { getAttribute } from 'api/Parse';
import * as ChatActs from 'actions/Chat';
import { getDrivers } from 'api/Driver/Driver';
import { formatName } from 'api/Helpers';
//import history from 'sbHistory';


//components
import SBCard from'components/Shared/SBCard/SBCard';
import { MDBRow, MDBCol, MDBBtn, MDBIcon, MDBContainer } from 'mdbreact';
import ChatBox from 'components/Chat/container/ChatBox';
//import SBTable from 'components/Shared/SBTable/SBTable';
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay';
import UncertifiedLogsTable from "components/UncertifiedLogs/UncertifiedLogsTable";

//sbObject
import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

//enums
import { AttributeTypes } from 'enums/Driver';
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

//style
import './style.scss';

// Context
import StoreContext from 'contexts/StoreContext';
// import moment = require('moment');


class UncertifiedLogs extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      //lowercase for the variable name
      eldUncertifiedDailyLogsByDriverObjectId: {},
      driver: undefined,
      roomId: undefined,
      isLoading: false,
      activePage: 0,
      count: 15,
      pageCount: 1
    }
    this.refreshState = this.refreshState.bind(this);
    this.openChatBox = this.openChatBox.bind(this);
    this.handleChatClose = this.handleChatClose.bind(this);
  }

  async componentDidMount() {
    return await this.refreshState();
  }

  async refreshState() {
    this.setState({ ...this.state, isLoading: true })
    const eldUncertifiedDailyLogRecords = await getUncertifiedELDDailyCetifications();

    let eldUncertifiedDailyLogsByDriverObjectId = {};
    eldUncertifiedDailyLogRecords.map( eldUncertifiedDailyLogRecord =>{
      const driverRecord = getAttribute(eldUncertifiedDailyLogRecord, 'driver');
      const driverObjectId = getAttribute(driverRecord, 'objectId');

      if (eldUncertifiedDailyLogsByDriverObjectId[driverObjectId]) {
        eldUncertifiedDailyLogsByDriverObjectId[driverObjectId].eldUncertifiedDailyLogRecords.push(eldUncertifiedDailyLogRecord);
      }
      else {
        eldUncertifiedDailyLogsByDriverObjectId[driverObjectId] = { driverRecord, eldUncertifiedDailyLogRecords:[eldUncertifiedDailyLogRecord]}
      }
    });

    return await this.setState({ ...this.state, eldUncertifiedDailyLogsByDriverObjectId, isLoading: false });
  }



  async openChatBox( driverObjectId ) {
    const driverConstraint = new Filter('objectId', driverObjectId, QueryRestrictionTypes.EQUAL_TO);
    const { drivers }= await getDrivers(0, 1, new Sort(AttributeTypes.USER_FULLNAME, QuerySortOrderTypes.DESCENDING), [driverConstraint], false, false, true)

    const chatroom = await ChatActs.fetchChatRoomForState(undefined, drivers);

    const roomId = getAttribute(chatroom, 'roomId');
    return await this.setState({ ...this.state, driver: drivers[0], roomId });
  }

  async handleChatClose() {
      return await this.setState({ ...this.state, driver: undefined });
  }

  render() {
    const { props, state } = this;

    const driverObjectIds = Object.keys(state.eldUncertifiedDailyLogsByDriverObjectId);

    const driverSections = driverObjectIds.map((driverObjectId, index) => {
      const driverRecord = state.eldUncertifiedDailyLogsByDriverObjectId[driverObjectId].driverRecord;
      const driverUser = getAttribute(driverRecord, 'user');
      // const driverTimezone = getAttribute(driverRecord, 'timezoneOffsetFromUTC') || moment.tz.guess();
      const driverName = getAttribute(driverRecord, 'user_fullName');
      const driverUserName = getAttribute(driverUser, 'username', true) || 'N/A';
      const driverPhone = getAttribute(driverUser, 'phoneNumber', true) || 'N/A';
      const driverEmail = getAttribute(driverUser, 'email', true) || 'N/A';
      // const eldUncertifiedDailyLogRecords = state.eldUncertifiedDailyLogsByDriverObjectId[driverObjectId].eldUncertifiedDailyLogRecords;
      const tableClassName = `drivers-sb-card${ driverObjectId }`;

      return(
        <SBCard
          className = { tableClassName }
          title = { formatName(driverName) }
          isCollapsible
          isCollapsed
          headerBorder
          key = { 'SBCard' + index } >
          <SBCard
            isCollapsible={false}
            title = "Contact Information" >
            <MDBRow>
              <MDBCol>
                <div><b>Username: </b>{ driverUserName }</div>
              </MDBCol>
              <MDBCol>
                <div><b>Email: </b>{ driverEmail }</div>
              </MDBCol>
              <MDBCol>
                <div><b>Phone: </b>{ driverPhone }</div>
              </MDBCol>
              <MDBCol className="text-right">
                <MDBBtn onClick = {()=>{ this.openChatBox(driverObjectId) }} size = 'sm' color = 'primary'>
                  Message
                </MDBBtn>
              </MDBCol>
            </MDBRow>
          </SBCard>
          <UncertifiedLogsTable
            eldUncertifiedDailyLogsByDriverObjectId = { state.eldUncertifiedDailyLogsByDriverObjectId[driverObjectId] }
            driverObjectId = { driverObjectId }
            count = { 10 } />
        </SBCard>
      );
    });


    return (
      <MDBContainer className = 'uncertified-log-list'>
        <React.Fragment>
          { driverSections }
        </React.Fragment>

        { state.driver &&
          <ChatBox
            chatRoom={this.props.Chat.chats[ state.roomId ]}
            roomId={ state.roomId }
            friends={ [state.driver] }
            shiftLeftForFriendsList={ false }
            handleChatClose = { ()=>{ this.handleChatClose() }}
          />
        }
        {
          state.isLoading &&
          <LoadingOverlay/>
          }
      </MDBContainer>
    );
  }
}

const mapStateToProps = (state) => { // update component based on changes to Driver or Company
  const { Chat } = state;
  return {
    Chat
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(UncertifiedLogs);
