import React from 'react';
import PropTypes from 'prop-types';

// Components
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';

// CSS
import styles from './SimpleTableBody.module.scss';

class SimpleTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { rows: null };
    this.loadingRow = (
      <tr className={styles.loadingIconNoHover}>
        <td colSpan="42" className="loadingIconPadding"><LoadingIcon /></td>
      </tr>
    );
  }

  componentWillReceiveProps(nextProps) {
    const newState = { ...this.state };
    if (nextProps.loaded && nextProps.rows.length === 0) {
      newState.rows = (
        <tr>
          <td colSpan="42" className="emptyRowMessage">Nothing to Display</td>
        </tr>
      );
    } else {
      newState.rows = nextProps.rows;
    }
    this.setState(newState);
  }

  render() {
    return (
      <tbody>
        { this.state.rows }
        { !this.props.loaded && this.loadingRow }
      </tbody>
    );
  }
}

SimpleTable.propTypes = {
  rows: PropTypes.array.isRequired,
  loaded: PropTypes.bool.isRequired,
  columns: PropTypes.string.isRequired,
};

export default SimpleTable;

