export const StateProvince = Object.freeze({
  CA_AB: {
    code: 'AB',
    name: 'Alberta',
    country: 'CA',
  },
  CA_BC: {
    code: 'BC',
    name: 'British Columbia',
    country: 'CA'
  },
  CA_MB: {
    code: 'MB',
    name: 'Manitoba',
    country: 'CA'
  },
  CA_NB: {
    code: 'NB',
    name: 'New Brunswick',
    country: 'CA'
  },
  CA_NF: {
    code: 'NF',
    name: 'New Foundland and Labrador',
    country: 'CA'
  },
  CA_NT: {
    code: 'NT',
    name: 'Northwest Territories',
    country: 'CA'
  },
  CA_NS: {
    code: 'NS',
    name: 'Nova Scotia',
    country: 'CA'
  },
  CA_NU: {
    code: 'NU',
    name: 'Nunavut',
    country: 'CA'
  },
  CA_ON: {
    code: 'ON',
    name: 'Ontario',
    country: 'CA'
  },
  CA_PE: {
    code: 'PE',
    name: 'Prince Edward Island',
    country: 'CA'
  },
  CA_QC: {
    code: 'QC',
    name: 'Quebec',
    country: 'CA'
  },

  CA_SK: {
    code: 'SK',
    name: 'Saskatchewan',
    country: 'CA'
  },
  CA_YT: {
    code: 'YT',
    name: 'Yukon Territory',
    country: 'CA'
  },
  MX_AG: {
    code: 'AG',
    name: 'Aguascalientes',
    country: 'MX'
  },
  US_AL: {
    code: 'AL',
    name: 'Alabama',
    country: 'US'
  },
  US_AK: {
    code: 'AK',
    name: 'Alaska',
    country: 'US'
  },

  US_AS: {
    code: 'AS',
    name: 'American Samoa',
    country: 'US'
  },
  US_AZ: {
    code: 'AZ',
    name: 'Arizona',
    country: 'US'
  },
  US_AR: {
    code: 'AR',
    name: 'Arkansas',
    country: 'US'
  },
  MX_BN: {
    code: 'BN',
    name: 'Baja California Nord',
    country: 'MX'
  },
  MX_BS: {
    code: 'BS',
    name: 'Baja California Sur',
    country: 'MX'
  },
  US_CA: {
    code: 'CA',
    name: 'California',
    country: 'US'
  },
  MX_CP: {
    code: 'CP',
    name: 'Campeche',
    country: 'MX'
  },
  MX_CS: {
    code: 'CS',
    name: 'Chiapas',
    country: 'MX'
  },
  MX_CI: {
    code: 'CI',
    name: 'Chihuahua',
    country: 'MX'
  },
  MX_CH: {
    code: 'CH',
    name: 'Coahuila',
    country: 'MX'
  },
  MX_CL: {
    code: 'CL',
    name: 'Colima',
    country: 'MX'
  },
  US_CO: {
    code: 'CO',
    name: 'Colorado',
    country: 'US'
  },
  US_CT: {
    code: 'CT',
    name: 'Connecticut',
    country: 'US'
  },
  US_DE: {
    code: 'DE',
    name: 'Delaware',
    country: 'US'
  },
  MX_DF: {
    code: 'DF',
    name: 'Districto Federal',
    country: 'MX'
  },
  US_DC: {
    code: 'DC',
    name: 'District of Columbia',
    country: 'US'
  },
  MX_DIF: {
    code: 'DIF',
    name: 'Distrito Federal',
    country: 'MX'
  },
  MX_DG: {
    code: 'DG',
    name: 'Durango',
    country: 'MX'
  },
  US_FL: {
    code: 'FL',
    name: 'Florida',
    country: 'US'
  },
  US_GA: {
    code: 'GA',
    name: 'Georgia',
    country: 'US'
  },
  US_GU: {
    code: 'GU',
    name: 'Guam',
    country: 'US'
  },
  MX_GJ: {
    code: 'GJ',
    name: 'Guanajuato',
    country: 'MX'
  },
  MX_GE: {
    code: 'GE',
    name: 'Guerrero',
    country: 'MX'
  },
  US_HI: {
    code: 'HI',
    name: 'Hawaii',
    country: 'US'
  },
  MX_HD: {
    code: 'HD',
    name: 'Hidalgo',
    country: 'MX'
  },
  US_ID: {
    code: 'ID',
    name: 'Idaho',
    country: 'US'
  },
  US_IL: {
    code: 'IL',
    name: 'Illinois',
    country: 'US'
  },
  US_IN: {
    code: 'IN',
    name: 'Indiana',
    country: 'US'
  },
  US_IA: {
    code: 'IA',
    name: 'Iowa',
    country: 'US'
  },
  MX_JA: {
    code: 'JA',
    name: 'Jalisco',
    country: 'MX'
  },
  US_KS: {
    code: 'KS',
    name: 'Kansas',
    country: 'US'
  },
  US_KY: {
    code: 'KY',
    name: 'Kentucky',
    country: 'US'
  },
  US_LA: {
    code: 'LA',
    name: 'Louisiana',
    country: 'US'
  },
  US_ME: {
    code: 'ME',
    name: 'Maine',
    country: 'US'
  },
  US_MD: {
    code: 'MD',
    name: 'Maryland',
    country: 'US'
  },
  US_MA: {
    code: 'MA',
    name: 'Massachusetts',
    country: 'US'
  },
  MX_MX: {
    code: 'MX',
    name: 'Mexico State',
    country: 'MX'
  },
  US_MI: {
    code: 'MI',
    name: 'Michigan',
    country: 'US'
  },
  MX_MC: {
    code: 'MC',
    name: 'Michoacan',
    country: 'MX'
  },
  US_MN: {
    code: 'MN',
    name: 'Minnesota',
    country: 'US'
  },
  US_MS: {
    code: 'MS',
    name: 'Mississippi',
    country: 'US'
  },
  US_MO: {
    code: 'MO',
    name: 'Missouri',
    country: 'US'
  },
  US_MT: {
    code: 'MT',
    name: 'Montana',
    country: 'US'
  },
  MX_MR: {
    code: 'MR',
    name: 'Morelos',
    country: 'MX'
  },
  MX_NA: {
    code: 'NA',
    name: 'Nayarit',
    country: 'MX'
  },
  US_NE: {
    code: 'NE',
    name: 'Nebraska',
    country: 'US'
  },
  US_NV: {
    code: 'NV',
    name: 'Nevada',
    country: 'US'
  },
  US_NH: {
    code: 'NH',
    name: 'New Hampshire',
    country: 'US',
  },
  US_NJ: {
    code: 'NJ',
    name: 'New Jersey',
    country: 'US',
  },
  US_NM: {
    code: 'NM',
    name: 'New Mexico',
    country: 'US',
  },
  US_NY: {
    code: 'NY',
    name: 'New York',
    country: 'US',
  },
  US_NP: {
    code: 'NP',
    name: 'No Plate',
    country: 'US',
  },
  US_NC: {
    code: 'NC',
    name: 'North Carolina',
    country: 'US'
  },
  US_ND: {
    code: 'ND',
    name: 'North Dakota',
    country: 'US'
  },
  US_MP: {
    code: 'MP',
    name: 'Northern Marianas',
    country: 'US'
  },
  MX_NL: {
    code: 'NL',
    name: 'Nuevo Leon',
    country: 'MX'
  },
  MX_OA: {
    code: 'OA',
    name: 'Oaxaca',
    country: 'MX'
  },
  US_OH: {
    code: 'OH',
    name: 'Ohio',
    country: 'US'
  },

  US_OK: {
    code: 'OK',
    name: 'Oklahoma',
    country: 'US'
  },
  US_OR: {
    code: 'OR',
    name: 'Oregon',
    country: 'US'
  },
  US_PA: {
    code: 'PA',
    name: 'Pennsylvania',
    country: 'US'
  },
  MX_PU: {
    code: 'PU',
    name: 'Puebla',
    country: 'MX'
  },
  US_PR: {
    code: 'PR',
    name: 'Puerto Rico',
    country: 'US'
  },
  MX_QE: {
    code: 'QE',
    name: 'Queretaro',
    country: 'MX'
  },
  MX_QI: {
    code: 'QI',
    name: 'Quintana Roo',
    country: 'MX'
  },
  US_RI: {
    code: 'RI',
    name: 'Rhode Island',
    country: 'US'
  },
  MX_SL: {
    code: 'SL',
    name: 'San Luis Potosi',
    country: 'MX'
  },
  MX_SI: {
    code: 'SI',
    name: 'Sinaloa',
    country: 'MX'
  },
  MX_SO: {
    code: 'SO',
    name: 'Sonora',
    country: 'MX'
  },
  US_SC: {
    code: 'SC',
    name: 'South Carolina',
    country: 'US'
  },
  US_SD: {
    code: 'SD',
    name: 'South Dakota',
    country: 'US'
  },
  MX_TB: {
    code: 'TB',
    name: 'Tabasco',
    country: 'MX'
  },
  MX_TA: {
    code: 'TA',
    name: 'Tamaulipas',
    country: 'MX'
  },
  US_TN: {
    code: 'TN',
    name: 'Tennessee',
    country: 'US'
  },
  US_TX: {
    code: 'TX',
    name: 'Texas',
    country: 'US'
  },
  MX_TL: {
    code: 'TL',
    name: 'Tlaxcala',
    country: 'MX'
  },
  US_UT: {
    code: 'UT',
    name: 'Utah',
    country: 'US'
  },
  MX_VC: {
    code: 'VC',
    name: 'Veracruz',
    country: 'MX'
  },
  US_VT: {
    code: 'VT',
    name: 'Vermont',
    country: 'US'
  },
  US_VA: {
    code: 'VA',
    name: 'Virginia',
    country: 'US'
  },
  US_VI: {
    code: 'VI',
    name: 'Virgin Islands',
    country: 'US'
  },
  US_WA: {
    code: 'WA',
    name: 'Washington',
    country: 'US'
  },
  US_WV: {
    code: 'WV',
    name: 'West Virginia',
    country: 'US'
  },
  US_WI: {
    code: 'WI',
    name: 'Wisconsin',
    country: 'US'
  },
  US_WY: {
    code: 'WY',
    name: 'Wyoming',
    country: 'US'
  },
  MX_YU: {
    code: 'YU',
    name: 'Yucatan',
    country: 'MX'
  },
  MX_ZA: {
    code: 'ZA',
    name: 'Zacatecas',
    country: 'MX'
  }
});
