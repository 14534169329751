import React from 'react';
import PropTypes from 'prop-types';

// Components
import UploadFilePicker from './UploadFilePicker';

class UploadFileButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showFilePicker: false };
    this.openFilePicker = this.openFilePicker.bind(this);
    this.closeFilePicker = this.closeFilePicker.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.showFilePicker !== this.state.showFilePicker) {
      return true;
    }
    return false;
  }

  openFilePicker() {
    this.setState({ ...this.state, showFilePicker: true });
  }

  closeFilePicker() {
    this.setState({ ...this.state, showFilePicker: false });
  }

  render() {
    return (
      <div className={`${this.props.className}`}>
        <button className="buttonDefault" onClick={this.openFilePicker}>{this.props.children}</button>
        <UploadFilePicker uploadHandler={this.props.uploadHandler} show={this.state.showFilePicker} handleClose={this.closeFilePicker} />
      </div>
    );
  }
}

UploadFileButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.string.isRequired,
  uploadHandler: PropTypes.func.isRequired, // returns the parent the file chosen
};

export default UploadFileButton;
