import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';


// lists
import Timezones from 'sb-csapi/dist/lists/timezones';

// sbcore components
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Dropdown from 'sbCore/Dropdown/Dropdown';

// styles
import './style.scss';

/**
 * @description Select a timezone
 * @param {String} className - Custom container className
 * @param {Object} style - Custom inline styles
 * @param {bool} warning - Makes the border yellow
 * @param {bool} autoFocus - Whether to automatically focus on the autocomplete on load
 * @param {string} timezone - Sets the timezone value to this by default. The string provided must be recognized in our timezones list. Ex. "America/Vancouver"
 * @param {bool} isFocusedDefault - Whether this component is the first component to be focused on. Determines if we should set isFocused to true when mounting
 * @param {bool} showOnFocus - Whether to automatically open the dropdown on focus
 * @param {bool} hideLabel - Hide the label
 * @param {bool} disabled - TODO: Whether disable the component
 * @param {Function} onSelect - A callback function that returns the selected timezone
 * @returns
 */
export default function TimezoneDropdown({ ...props }) {

  const [identifier] = useState(uniqid()); // for each of this component that exists on the same page, give it unique identifier for specific dom manipulation
  const [selectedTimezone, setSelectedTimezone] = useState(undefined); // the selected timezone
  const [timezones, setTimezones] = useState([...Timezones]);
  const [isFocused, setIsFocused] = useState(props.isFocusedDefault);
  const [hasError, setHasError] = useState(false); // if there is an error with the input

  // ** Hooks Section ** //
  useEffect(() => {
    let defaultTimezone;
    if (props.timezone) defaultTimezone = Timezones.filter(timezone => timezone.name.toLowerCase() === props.timezone.toLowerCase())[0];
    // if (!defaultTimezone) defaultTimezone = Timezones.filter(timezone => timezone.name.toLowerCase() === 'america/vancouver')[0];
    setSelectedTimezone(defaultTimezone);
  }, []);

  useEffect(() => {
    let defaultTimezone = props.timezone;
    if (defaultTimezone) {
      defaultTimezone = Timezones.filter(timezone => timezone.name.toLowerCase() === defaultTimezone.toLowerCase())[0];
    }
    setSelectedTimezone(defaultTimezone);
  }, [props.timezone]);


  // ** Components Section ** //
  function onChange(e) {
    setSelectedTimezone(e.value);
    if (props.onSelect) props.onSelect(e.value ? e.value.name : e.value); // send the chosen timezone name back up to parent
  }

  function toggleFocus() {
    // determine whether or not this component is focused on, to enable additional functionality
    setIsFocused(!isFocused);
  }

  // if the component is focused, allow it to be prompted by keypresses
  // if (isFocused) {
  //   const inputButtonEl = document.querySelector(`.timezone-dropdown.${identifier} .p-dropdown .p-dropdown-trigger`);
  //   const dropdownPanelEl = document.querySelector(`.timezone-dropdown.${identifier} .p-dropdown-panel .p-dropdown-items`);
  //   if (inputButtonEl && !dropdownPanelEl) {
  //     if (window.sbKeydownEvent69.keyCode === 40) {
  //       inputButtonEl.click();
  //     }

  //     console.log(dropdownPanelEl);
  //     console.log(window.sbKeydownEvent69);
  //   }
  // }

  function itemTemplate(option, props) {
    return (
      <div>
        { option.name }
      </div>
    );
  }


  // // ** Misc ** //
  let className = `timezone-dropdown ${identifier}`;
  if (props.className) className += ` ${props.className}`;

  // // if the input is not being focused on (which is for some reason dictated by the button's focus), but the user left their search term without selecting a suggestion for the searchTerm
  // const isNotFocusedWithText = inputButtonEl && searchTerm && ((typeof searchTerm) !== 'object') && (document.activeElement !== inputButtonEl);
  // const _hasError = isNotFocusedWithText || hasError;
  const _hasError = false;

  return (
    <div className={className} style={{ ...props.style }}>
      {!props.hideLabel && (
        <InputLabel>Timezone</InputLabel>
      )}
      <Dropdown
        value={selectedTimezone}
        options={timezones}
        onChange={(e) => onChange(e)}
        onFocus={(e) => toggleFocus(e)}
        onBlur={() => toggleFocus()}
        filter
        filterBy="name"
        resetFilterOnHide
        optionLabel="name"
        placeholder="Select Time Zone"
        // valueTemplate={valueTemplate}
        itemTemplate={itemTemplate}
        autoFocus={props.autoFocus}
        success={props.success}
        warning={props.warning}
        error={_hasError}
        // showOnFocus={props.showOnFocus || true}
        disabled={props.disabled}
      />
    </div>
  );
}
