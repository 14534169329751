import React from 'react';
import PropTypes from 'prop-types';

// API
import { updateEquipmentGroup } from 'api/Equipment/Groups.old';
import { getAttribute } from 'api/Parse';

// Components
import Title from 'components/LayoutTitle/view/Title';
import GeofencesTable from 'components/GeofencesTable/GeofencesTable';
import SBTable from 'components/Shared/SBTable/SBTable';
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import { MDBRow, MDBCol, MDBContainer, MDBBtn, MDBIcon } from 'mdbreact';

// css
import './style.scss';

class EquipmentGroupView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      groupGeofence: getAttribute(props.group, 'geofence', true),
    };

    this.addGeofence = this.addGeofence.bind(this);
  }

  async addGeofence(geofenceRecord) {
    const { group } = this.props;
    await updateEquipmentGroup(group, { geofence: geofenceRecord });
    this.setState({ groupGeofence: getAttribute(group, 'geofence', true) });
  }

  async removeGeofence() {
    const { group } = this.props;
    await updateEquipmentGroup(group, { geofence: undefined });
    this.setState({ groupGeofence: getAttribute(group, 'geofence', true) });
  }

  render() {
    const { state, props } = this;

    // TABLE STYLES
    const tableHeaderStyles = {
      name: { width: '60%' },
      shape: { width: '20%' },
      actions: { width: '20%' },
    };

    // TABLE HEADER/BODY
    const tableHeaderColumns = [
      { element: <div>Geofence Name</div>, props: { style: tableHeaderStyles.name } },
      { element: <div>Shape</div>, props: { style: tableHeaderStyles.shape } },
      { element: <div />, props: { style: tableHeaderStyles.actions } },
    ];

    const tableHeaderRows = [{
      key: 'group-geofence', columns: tableHeaderColumns,
    }];

    const name = getAttribute(state.groupGeofence, 'name', true);
    const shape = getAttribute(state.groupGeofence, 'getPoints', true) ? 'Polygon' : 'Rectangle';
    const objectId = getAttribute(state.groupGeofence, 'objectId', true);

    const tableBodyRows = [{
      key: objectId,
      props: {},
      columns: [
        { element: <div>{ name }</div>, props: { className: 'table-body-column-style' } },
        { element: <div>{ shape }</div>, props: { className: 'table-body-column-style' } },
        {
          element: (
            <ActionsContainer>
              <MDBBtn
                size="sm"
                color="danger"
                onClick={() => this.removeGeofence()}
              >
                <MDBIcon className="mr-1" icon="trash" />
              </MDBBtn>
            </ActionsContainer>
          ),
          props: { className: 'table-body-column-style' },
        },
      ],
    }];

    return (
      <MDBContainer fluid>
        <MDBRow>
          <MDBCol xs="12" lg="5">
            <Title title={getAttribute(props.group, 'name', true)} />
          </MDBCol>
        </MDBRow>
        <MDBRow>
          {!state.groupGeofence && <GeofencesTable addCallback={this.addGeofence} />}
          {state.groupGeofence && (
            <MDBCol>
              <SBTable
                hover
                tableHeaderRows={tableHeaderRows}
                tableBodyRows={tableBodyRows}
                emptyTableMessage={(
                  <div>
                    { ((tableBodyRows.length === 0)) && <div className="text-center">Error finding geofence</div> }
                  </div>
                )}
              />
            </MDBCol>
          )}
        </MDBRow>
      </MDBContainer>
    );
  }
}

EquipmentGroupView.propTypes = {
  group: PropTypes.object.isRequired,
};

export default EquipmentGroupView;
