import SBDropdown from 'sbCore/Dropdown/Dropdown';

import './style.scss';

/**
 * @description customizable dropdown for document generation
 * @param {String} value - the value of the input for a controlled input, requires onChange callback to be specified
 * @param {Function} onChange - the callback function for a controlled input, requires the value 
 * @param {Object} options - an object containing the possible dropdown options
 * @param {String} [label] - the label that is attached to this input
 * @param {bool} [horizontal] - determines whether the label is on the top of the input or on the left of the input
 * @param {String} [labelClassName] - styles to be applied to the label itself
 * @param {String} [inputClassName] - styles to be applied to the input
 * @returns {Component}
 */
function Dropdown({ label, horizontal, value, options, ...props }) {
  const labelClassName = props.labelClassName;
  const inputClassName = props.inputClassName;

  return (
    <div className={`docgen-input-dropdown${horizontal ? ' flex align-items-center' : ''}`}>
      {label && 
        <div className={`information-sub-title${labelClassName ? ` ${labelClassName}` : ''}`}>{label}</div>
      }
      <div className={`invisible-border${horizontal ? ' horizontal-document-input-text' : ''}`}>
        <SBDropdown
          value={value}
          options={options}
          onChange={(e) => props.onChange(e.value)}
          className={inputClassName}
        />
      </div>
    </div>
  );
}

export default Dropdown;