import React from 'react';
import PropTypes from 'prop-types';

// Components
import ChooseOrAddModal from 'components/ChooseOrAddModal/container/ChooseOrAddModal';

class ChooseOrAddButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { chooseOrAddModalShow: false };
    this.openChooseOrAddModal = this.openChooseOrAddModal.bind(this);
    this.closeChooseOrAddModal = this.closeChooseOrAddModal.bind(this);
  }

  openChooseOrAddModal() {
    this.state.chooseOrAddModalShow = true;
    this.setState(this.state);
  }
  closeChooseOrAddModal() {
    this.state.chooseOrAddModalShow = false;
    this.setState(this.state);
  }

  render() {
    return (
      <div className="inline">
        <ChooseOrAddModal {...this.props} show={this.state.chooseOrAddModalShow} handleClose={this.closeChooseOrAddModal} />
        <button className={this.props.btnClass} className="buttonDefault" onClick={this.openChooseOrAddModal}>
          { this.props.children }
          { !this.props.children &&
            'Choose'
          }
        </button>
      </div>
    );
  }
}

ChooseOrAddButton.propTypes = {
  btnClass: PropTypes.object,
  children: PropTypes.node,
};

export default ChooseOrAddButton;
