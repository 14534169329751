import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// Components
import OdometerReadingsTable from 'components/IFTARoute/container/OdometerReadingsTable';
import SBTable from 'components/Shared/SBTable/SBTable';
import { MDBFormInline, MDBInput } from 'mdbreact';
import FuelHelpDialog from './FuelHelpDialog';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import ErrorMessages from 'components/IFTARoute/container/ErrorMessages';

// API
import * as IFTA from 'api/IFTA';
import { StateProvinces } from 'api/Lists/StateProvinces';
import * as Helpers from 'api/Helpers';
import * as IFTARoute from 'api/IFTARoute/IFTARoute.old';

// Style
import './styles.scss';

// Enums
import ErrorTypes from 'enums/IFTARoute';

const getFilteredTotalVehicleKmDiff = (iftaRouteObj) => {
  if (Math.abs(iftaRouteObj.get('savedVehicleKm') - iftaRouteObj.get('totalVehicleKmDiff')) > 1000) {
    return iftaRouteObj.get('savedVehicleKm');
  }
  return iftaRouteObj.get('totalVehicleKmDiff');
};

class VehicleSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldSpreadKmDecimal: false,
      loadingOdometerReadings: false,
    };

    this.generateTableBodyRowData = this.generateTableBodyRowData.bind(this);
    this.generateErrorMessages = this.generateErrorMessages.bind(this);
  }

  async componentDidMount() {
    if (!this.props.odometerReadings) {
      await this.setState({ ...this.state, loadingOdometerReadings: true });
      const odometerReadings = await IFTA.getOdometerReadingsForDateRange(this.props.unitId, this.props.dateStart, this.props.dateEnd, 'km');
      await this.setState({ ...this.state, odometerReadings: (odometerReadings || {}), loadingOdometerReadings: false });
    } else {
      this.setState({ ...this.state, odometerReadings: (this.props.odometerReadings || {}), loadingOdometerReadings: this.props.loading });
    }
  }

  async componentDidUpdate(prevProps) {
    const { props } = this;

    if (props.iftaRouteArr.length !== prevProps.iftaRouteArr.length) {
      this.setState({ ...this.state, odometerReadings: {}, loadingOdometerReadings: true }, async () => {
        const odometerReadings = await IFTA.getOdometerReadingsForDateRange(props.unitId, props.dateStart, props.dateEnd, 'km');
        this.setState({ ...this.state, odometerReadings: (odometerReadings || {}), loadingOdometerReadings: false });
      });
    }
  }

  generateTableBodyRowData() {
    const { state, props } = this;

    let odometerDiff = 0;
    if (state.odometerReadings && state.odometerReadings.odometerEnd && state.odometerReadings.odometerStart) {
      odometerDiff = state.odometerReadings.odometerEnd - state.odometerReadings.odometerStart;
    }

    const stateProvinceCodes = StateProvinces.map((stateProvince) => stateProvince.code).filter((stateProvinceCode) => stateProvinceCode);
    const stateProvinceDataArr = [];
    const iftaArrMiles = {};

    let totalOdometerKm = 0;
    let totalGpsKm = 0;
    let totalSavedKm = 0;
    let totalFuelPurchases = {};
    let totalSpreadKm = 0;
    let totalFuelTotalPaidCAD = 0;
    let totalFuelTotalPaidUSD = 0;
    let totalGallons = 0;

    for (let i = 0; i < props.iftaRouteArr.length; i++) {
      const iftaRoute = props.iftaRouteArr[i];
      const stateProvince = iftaRoute.get('stateProvince').toUpperCase();
      const savedVehicleKm = (iftaRoute.get('savedVehicleKm') > 0 ? iftaRoute.get('savedVehicleKm') : 0);
      const totalVehicleKmDiff = (iftaRoute.get('totalVehicleKmDiff') > 0 ? getFilteredTotalVehicleKmDiff(iftaRoute) : 0);
      const distanceKm = (iftaRoute.get('distanceKm') > 0 ? iftaRoute.get('distanceKm') : 0);
      const totalGallonsFromIFTARoute = (iftaRoute.get('totalGallons') > 0 ? iftaRoute.get('totalGallons') : 0);
      totalOdometerKm += totalVehicleKmDiff;
      totalGpsKm += distanceKm;
      totalSavedKm += savedVehicleKm;
      totalGallons += totalGallonsFromIFTARoute;
      if (iftaArrMiles[stateProvince]) {
        iftaArrMiles[stateProvince].odometerKm += totalVehicleKmDiff;
        iftaArrMiles[stateProvince].gpsKm += distanceKm;
        iftaArrMiles[stateProvince].savedKm += savedVehicleKm;
        iftaArrMiles[stateProvince].totalGallons += totalGallonsFromIFTARoute;
      } else {
        iftaArrMiles[stateProvince] = {};
        iftaArrMiles[stateProvince].odometerKm = totalVehicleKmDiff || 0;
        iftaArrMiles[stateProvince].gpsKm = distanceKm || 0;
        iftaArrMiles[stateProvince].savedKm = savedVehicleKm || 0;
        iftaArrMiles[stateProvince].fuelPurchaseSubtotals = {};
        iftaArrMiles[stateProvince].fuelTotalPaidCAD = 0;
        iftaArrMiles[stateProvince].fuelTotalPaidUSD = 0;
        iftaArrMiles[stateProvince].totalGallons = totalGallonsFromIFTARoute || 0;
      }

      // Fuel Purchases
      if (iftaRoute.get('fuelPurchases')) {
        for (let j = 0; j < iftaRoute.get('fuelPurchases').length; j++) {
          const fuelPurchase = iftaRoute.get('fuelPurchases')[j];
          const fuelType = fuelPurchase.get('fuelType') && fuelPurchase.get('fuelType').toUpperCase();
          const totalPaid = fuelPurchase.get('totalPaid');
          const currency = fuelPurchase.get('currency');
          const volumePumpedGallons = fuelPurchase.get('volumeUnits') === 'l' ? Helpers.convertFuelUnit(fuelPurchase.get('volumePumped'), 'l', 'gal') : fuelPurchase.get('volumePumped')
          if (iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType]) {
            iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType] += volumePumpedGallons;
          } else {
            iftaArrMiles[stateProvince].fuelPurchaseSubtotals[fuelType] = volumePumpedGallons;
          }
          if (totalFuelPurchases[fuelType]) {
            totalFuelPurchases[fuelType] += volumePumpedGallons;
          } else {
            totalFuelPurchases[fuelType] = volumePumpedGallons;
          }
          if (currency === 'cad') {
            iftaArrMiles[stateProvince].fuelTotalPaidCAD += totalPaid;
            totalFuelTotalPaidCAD += totalPaid;
          } else if (currency === 'usd') {
            iftaArrMiles[stateProvince].fuelTotalPaidUSD += totalPaid;
            totalFuelTotalPaidUSD += totalPaid;
          }
        }
      }
    }

    // This is for deciding whether to spread the calculated Vehicle Km
    // Should Use SpreadKm when the odometerDifference is not negative or way too large!
    // Otherwise will just use calculatedOdometerKm
    let shouldUseSpreadKm = false;
    if (
      odometerDiff > 0 &&
      (odometerDiff < (moment(this.props.dateEnd).diff(moment(this.props.dateStart), 'hours') * 200)) &&
      (Math.abs(totalSavedKm - odometerDiff) < (5000 * (moment(this.props.dateEnd).diff(moment(this.props.dateStart), 'months') + 1)))
    ) {
      shouldUseSpreadKm = true;
    }

    for (let i = 0; i < stateProvinceCodes.length; i++) {
      const stateProvince = stateProvinceCodes[i];
      const subtotalSavedKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].savedKm ? Math.round(iftaArrMiles[stateProvince].savedKm * 100) / 100 : 0;
      const subtotalOdometerKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].odometerKm ? Math.round(iftaArrMiles[stateProvince].odometerKm * 100) / 100 : 0;
      const subtotalGpsKm = iftaArrMiles[stateProvince] && iftaArrMiles[stateProvince].gpsKm ? Math.round(iftaArrMiles[stateProvince].gpsKm * 100) / 100 : 0;
      const fuelPurchaseSubtotals = iftaArrMiles[stateProvince] ? iftaArrMiles[stateProvince].fuelPurchaseSubtotals : {};
      const totalGallons = iftaArrMiles[stateProvince] ? iftaArrMiles[stateProvince].totalGallons : 0;

      const subtotalSpreadKm = Math.round(totalSavedKm) !== 0 ? subtotalSavedKm && totalSavedKm && odometerDiff && Math.round((subtotalSavedKm / Math.round(totalSavedKm)) * odometerDiff * 100) / 100 : subtotalSavedKm;
      totalSpreadKm += subtotalSpreadKm;
      const stateProvinceDataObj = {
        code: stateProvince,
        subtotalSavedKm,
        subtotalOdometerKm,
        subtotalGpsKm,
        fuelPurchaseSubtotals,
        totalGallons,
        subtotalSpreadKm: shouldUseSpreadKm ? subtotalSpreadKm : subtotalSavedKm,
      };
      stateProvinceDataArr.push(stateProvinceDataObj);
    }

    const gpsMileageArray = stateProvinceDataArr.map((stateProvinceData) => {
      const stateProvince = stateProvinceData.code;
      const subtotalGPSKm = stateProvinceData.subtotalGpsKm;
      return { stateProvince, subtotalGPSKm };
    });

    const calculatedMileageArray = stateProvinceDataArr.map((stateProvinceData) => {
      const stateProvince = stateProvinceData.code;
      const subtotalCalculatedKm = shouldUseSpreadKm ? stateProvinceData.subtotalSpreadKm : stateProvinceData.subtotalSavedKm;
      return { stateProvince, subtotalCalculatedKm };
    });

    const totalCalculatedMileage = shouldUseSpreadKm ? totalSpreadKm : totalSavedKm;

    const iftaRouteIssuesObject = IFTARoute.findTotalMileageIssues(gpsMileageArray, calculatedMileageArray, totalCalculatedMileage, odometerDiff);
    return { totalGpsKm, totalSavedKm, totalSpreadKm, totalFuelPurchases, odometerDiff, shouldUseSpreadKm, stateProvinceDataArr, iftaRouteIssuesObject };
  }

  handleScroll(e, anchorTarget) {
    // We need to grab the row element, hence we retrieve the grandparent element
    const element = document.getElementById(anchorTarget).parentElement.parentElement;
    const hasGrayBg = element.classList.contains('bg-gray-hover');

    e.preventDefault();
    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });

    // Toggle the classes to temporarily remove styling (workaround due to the classes using !important)
    element.classList.toggle('error-alert');
    hasGrayBg && element.classList.toggle('bg-gray-hover');
    element.classList.toggle('hover-gray-matte');

    setTimeout(() => {
      element.classList.toggle('error-alert');
      hasGrayBg && element.classList.toggle('bg-gray-hover');
      element.classList.toggle('hover-gray-matte');
    }, 3000);
  }

  generateErrorMessages(totalSavedKm, totalSpreadKm, odometerDiff, iftaRouteIssuesObject, shouldUseSpreadKm) {
    const { props } = this;
    const errorMessages = [];

    const generateStateProvinceLinks = (stateProvinceIssuesArray) => {
      return (stateProvinceIssuesArray.map((issue, index) => {
        const { stateProvince } = issue;
        if (index === stateProvinceIssuesArray.length - 1) {
          return (
            <React.Fragment>
              <a
                href={`#${stateProvince}`}
                onClick={(e) => this.handleScroll(e, stateProvince)}
              >
                {stateProvince}
              </a>
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment>
              <a
                href={`#${stateProvince}`}
                onClick={(e) => this.handleScroll(e, stateProvince)}
              >
                {stateProvince}
              </a>
              {', '}
            </React.Fragment>
          );
        }
      }));
    };

    if ((totalSavedKm === 0 || totalSpreadKm === 0) && odometerDiff > 0) {
      errorMessages.push(
        <div style={{ fontSize: '1em' }}>
          <ErrorMessages
            errorType={ErrorTypes.NO_MILEAGE_INFORMATION}
          />
          The mileage information may not have been uploaded yet.
        </div>,
      );
    }
    if (iftaRouteIssuesObject.gpsMileageAndCalculatedMileageOutsideThreshold || iftaRouteIssuesObject.isOdometerDifferenceAndTotalMileageDifferent) {
      // if (iftaRouteIssuesObject.gpsMileageAndCalculatedMileageOutsideThreshold.length > 0) {
      //   errorMessages.push(
      //     <div className="error-message" style={{ fontSize: '1em' }}>
      //       <ErrorMessages
      //         errorType={ErrorTypes.GPS_CALCULATED_MILEAGE_DIFFERENCE}
      //       />
      //       {'There may be mileage errors associated with the following state/provinces: '}
      //       {generateStateProvinceLinks(iftaRouteIssuesObject.gpsMileageAndCalculatedMileageOutsideThreshold)}
      //     </div>,
      //   );
      // }
      if (iftaRouteIssuesObject.isOdometerDifferenceAndTotalMileageDifferent) {
        errorMessages.push(
          <div style={{ fontSize: '1em' }}>
            <ErrorMessages
              errorType={ErrorTypes.TOTAL_ODOMETER_CALCULATED_DIFFERENCE}
            />
            The total odometer difference <b>{(odometerDiff && props.distanceUnitFilter.value === 'km' ? odometerDiff.toFixed(0) : Helpers.convertDistance(odometerDiff, 'km', 'mi', true).toFixed(0))}</b> does not match the total calculated mileage
            <b> {
              shouldUseSpreadKm ?
                (odometerDiff && props.distanceUnitFilter.value === 'km' ? odometerDiff.toFixed(0) : Helpers.convertDistance(odometerDiff, 'km', 'mi', true).toFixed(0)) :
                (props.distanceUnitFilter.value === 'km' ? totalSavedKm.toFixed(0) : Helpers.convertDistance(totalSavedKm, 'km', 'mi', true).toFixed(0))
            }
            </b>
          </div>,
        );
      }
    }

    return errorMessages;
  }

  render() {
    const { state, props } = this;
    const { totalGpsKm, totalSavedKm, totalSpreadKm, totalFuelPurchases, odometerDiff, shouldUseSpreadKm, stateProvinceDataArr, iftaRouteIssuesObject } = this.generateTableBodyRowData();

    const tableHeaderStyles = {
      stateProvince: { width: '20%', verticalAlign: 'middle' },
      odometer: { width: '20%', verticalAlign: 'middle' },
      gps: { width: '20%', verticalAlign: 'middle' },
      saved: { width: '20%', verticalAlign: 'middle' },
      spread: { width: '0%', verticalAlign: 'middle' },
      fuelCard: { width: '20%', verticalAlign: 'middle' }
    };

    const tableHeaderRows = [{
      key: 'sb-jobs-list',
      columns: [
        {
          element: <div>State / Province</div>,
          props: {
            style: tableHeaderStyles.stateProvince,
          },
        },
        // getting rid of gps mileage
        // {
        //   element: <div>GPS Mileage ({props.distanceUnitFilter.value})</div>,
        //   props: {
        //     style: tableHeaderStyles.gps,
        //   },
        // },
        {
          element: <div className="centerText">Fuel (Gallons) <FuelHelpDialog /></div>,
          props: {
            style: tableHeaderStyles.odometer,
          },
        },
        {
          element: <div className="centerText">Fuel Card Uploads (Gallons)</div>,
          props: {
            style: tableHeaderStyles.fuelCard,
          }
        },
        // {
        //   element: <div>Calculated Mileage ({ this.props.distanceUnitFilter.value })</div>,
        //   props: {
        //     style: tableHeaderStyles.saved,
        //   }
        // },
        {
          element:
            <div>
              Calculated Mileage ({props.distanceUnitFilter.value})
              <div className="print-hide">
                <MDBFormInline styles={{ margin: 0 }}>
                  <MDBInput
                    label="Show Decimal"
                    filled
                    onClick={() => { this.setState({ ...this.state, shouldSpreadKmDecimal: !state.shouldSpreadKmDecimal }); }}
                    type="checkbox"
                    checked={state.shouldSpreadKmDecimal}
                    containerClass="mt-n1 mb-n1"
                  />
                </MDBFormInline>
              </div>
            </div>,
          props: {
            style: tableHeaderStyles.saved,
          },
        },
      ],
    }];

    const tableBodyRows = stateProvinceDataArr.filter((stateProvinceData) => { if (props.selectedFilter.value === 'hideZeroMileages') { return stateProvinceData.subtotalSavedKm; } return true; }).map(stateProvinceData => {
      const vehicleRowObj = {
        key: stateProvinceData.stateProvince,
        columns: [],
      };
      vehicleRowObj.columns = [
        {
          element:
            <div id={stateProvinceData.code}>
              {stateProvinceData.code}
            </div>,
          props: { className: 'table-body-column-style' },
        },
        {
          element:
            <div>
              {
                stateProvinceData.subtotalGpsKm ?
                  (
                    this.props.distanceUnitFilter.value === 'km' ?
                      stateProvinceData.subtotalGpsKm.toFixed(0) : Helpers.convertDistance(stateProvinceData.subtotalGpsKm, 'km', 'mi', true).toFixed(0)
                  ) : 0
              }
            </div>,
          props: { className: 'table-body-column-style' },
        },
        {
          element:
            <div>
              {Object.keys(stateProvinceData.fuelPurchaseSubtotals).map((fuelPurchaseSubtotalType) => {
                return (
                  <div>
                    <b>{fuelPurchaseSubtotalType}</b>: {stateProvinceData.fuelPurchaseSubtotals[fuelPurchaseSubtotalType].toFixed(2)}
                  </div>
                )
              })}
            </div>,
          props: { className: 'table-body-column-style' },
        },
        // { element:
        //   <div>
        //     <b>{
        //       stateProvinceData.subtotalSavedKm ?
        //       (
        //         this.props.distanceUnitFilter.value === 'km' ?
        //         stateProvinceData.subtotalSavedKm.toFixed(0) : Helpers.convertDistance(stateProvinceData.subtotalSavedKm, 'km', 'mi', true).toFixed(0)
        //       ) : 0
        //     }</b>
        //   </div>, props: { className: 'table-body-column-style' }
        // },
        {
          element:
            <div>
              {Math.round(stateProvinceData.totalGallons)}
            </div>, props: { className: 'table-body-column-style' }
        },
        {
          element:
            <div>
              <b>{
                this.state.loadingOdometerReadings ?
                  <LoadingIcon /> :
                  (
                    stateProvinceData.subtotalSpreadKm ?
                      (
                        this.props.distanceUnitFilter.value === 'km' ?
                          stateProvinceData.subtotalSpreadKm.toFixed(0) : Helpers.convertDistance(stateProvinceData.subtotalSpreadKm, 'km', 'mi', true).toFixed(this.state.shouldSpreadKmDecimal ? 1 : 0)
                      ) : 0
                  )
              }</b>
            </div>, props: { className: 'table-body-column-style' }
        },
      ];

      return vehicleRowObj;
    });

    tableBodyRows.push({
      key: 'total',
      columns: [
        {
          element: (
            <div>
              <b>Total</b>
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        // getting rid of gps mileage
        // {
        //   element: (
        //     <div>
        //       <b>{props.distanceUnitFilter.value === 'km' ? totalGpsKm.toFixed(0) : Helpers.convertDistance(totalGpsKm, 'km', 'mi', true).toFixed(0)}</b>
        //     </div>
        //   ),
        //   props: { className: 'table-body-column-style' },
        // },
        {
          element: (
            <div>
              {Object.keys(totalFuelPurchases).map((totalFuelPurchaseType) => {
                return (
                  <div>
                    <b>{totalFuelPurchaseType}</b>: {totalFuelPurchases[totalFuelPurchaseType].toFixed(2)}
                  </div>
                );
              })}
            </div>
          ),
          props: { className: 'table-body-column-style' },
        },
        // { element:
        //   <div>
        //     <b>{ this.props.distanceUnitFilter.value === 'km' ?  totalSavedKm.toFixed(0) : Helpers.convertDistance(totalSavedKm, 'km', 'mi', true).toFixed(0) }</b>
        //   </div>, props: { className: 'table-body-column-style' }
        // },
        {
          element:
            <div />, props: { className: 'tabled-body-column-style' }
        },
        {
          element:
            <div>
              <b>{
                this.state.loadingOdometerReadings ?
                  <LoadingIcon /> :
                  (
                    shouldUseSpreadKm ?
                      (odometerDiff && this.props.distanceUnitFilter.value === 'km' ? odometerDiff.toFixed(0) : Helpers.convertDistance(odometerDiff, 'km', 'mi', true).toFixed(0)) :
                      (this.props.distanceUnitFilter.value === 'km' ? totalSavedKm.toFixed(0) : Helpers.convertDistance(totalSavedKm, 'km', 'mi', true).toFixed(0))
                  )
              }</b>
            </div>, props: { className: 'table-body-column-style' }
        },
      ],
    });

    const errorMessages = this.generateErrorMessages(totalSavedKm, totalSpreadKm, odometerDiff, iftaRouteIssuesObject, shouldUseSpreadKm);

    return (
      <div>
        {errorMessages.length > 0 && (
          <SBBlock
            warning
            title="Notice"
            messages={errorMessages}
            listMessages={false}
          />
        )}
        <div>
          <h5>
            Unit {props.unitId}: Start / End Odometer Readings
            <br />
            {`${moment(this.props.displayStartDate).format('LL')} - ${moment(this.props.displayEndDate).format('LL')}`}
          </h5>
        </div>
        <OdometerReadingsTable
          dateStart={props.dateStart}
          dateEnd={props.dateEnd}
          vehicleUnitId={props.unitId}
          distanceUnit={props.distanceUnitFilter.value}
        />
        <div>
          <h5>
            Unit {props.unitId}: IFTA Mileages
            <br />
            {`${moment(this.props.displayStartDate).format('LL')} - ${moment(this.props.displayEndDate).format('LL')}`}
          </h5>
        </div>
        <SBTable
          hover
          tableHeaderRows={tableHeaderRows}
          tableBodyRows={tableBodyRows}
          hideZ
        />
      </div>
    );
  }
}

VehicleSummary.propTypes = {
  distanceUnitFilter: PropTypes.object,
  odometerReadings: PropTypes.object,
  selectedFilter: PropTypes.object,
  unitId: PropTypes.string,
  distanceUnit: PropTypes.string,
  dateStart: PropTypes.object,
  dateEnd: PropTypes.object,
  iftaRouteArr: PropTypes.array,
};

export default VehicleSummary;
