import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { RaisedButton, FontIcon } from 'material-ui';

// API
import { getDriverById } from 'api/Getters';
import { getELDDailyCertification } from 'api/ELD';
import { addReplaceQueryParameter, getQueryParameter } from 'api/URL';

// sbObjects
import history from 'sbHistory';

// Components
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import DriverHours from 'components/DriverHours/container/DriverHours';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

// Styles
import styles from './DriverHoursDetailsLayout.module.scss';

class DriverHoursDetailsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.refresh = this.refresh.bind(this);
    this.state = {
      certificationAndLastEventArr: [],
      certificationAndLastEventArrFetched: false,
      eldDailyCertification: undefined,
    };
    const tempDate = getQueryParameter(props.location.search, 'date');
    this.state.date = tempDate ? moment(tempDate, 'DDMMYY') : moment();
    this.state.dateFilterFields = [{
      attrName: 'onDate',
      fullName: t('Recap Date'),
      type: 'date',
      queryType: 'date',
      dateQueryType: 'onDate',
      value: tempDate ? moment(tempDate, 'DDMMYY') : moment(),
      includeLeftRight: true,
    }];
  }

  async componentDidMount() {
    const { props, state } = this;

    getDriverById(props.driver.id);
    const eldDailyCertification = await getELDDailyCertification(props.driver);
    this.setState({ ...state, eldDailyCertification });
  }

  async handleFilter(filterArray) {
    const date = filterArray[0].value;

    if (date) {
      const { props, state } = this;

      history.push({
        pathname: props.location.pathname,
        search: props.location.search.length > 0
          ? addReplaceQueryParameter(props.location.search, 'date', date.format('DDMMYY'))
          : `date=${date.format('DDMMYY')}`,
      });

      const eldDailyCertification = await getELDDailyCertification(props.driver, date);

      this.setState({ ...state, eldDailyCertification });
    }
  }

  refresh() {
    const { props, state } = this;
  }

  clearFilter() {
    const { state, props } = this;

    history.push({
      pathname: props.location.pathname,
      search: props.location.search.length > 0
        ? addReplaceQueryParameter(props.location.search, 'date', moment().format('DDMMYY'))
        : `date=${moment().format('DDMMYY')}`,
    });

    this.setState({ ...state, eldDailyCertification: undefined });
  }

  render() {
    const { state, props } = this;

    const lastUpdatedDateTime = state.eldDailyCertification && moment(state.eldDailyCertification.get('updatedAt')).format('LLL');
    window.Localize.translatePage();
    return (
      <MDBContainer fluid>
        <MDBRow>
          {/* <MDBCol sm="8" style={{ padding: '1.5em', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} className="translate-me">
            <span className={props.driver.get('latestELDEvent') && moment().diff(moment(props.driver.get('latestELDEvent').get('eventDateTime')), 'minutes') > 120 ? styles.oldDateTime : ''}>
              Last Updated @ <var>{lastUpdatedDateTime || 'N/A'}</var> (Local)
            </span>
            <RaisedButton
              icon={<FontIcon className="material-icons notranslate">refresh</FontIcon>}
              label="Refresh"
              onClick={() => this.refresh()}
              primary
            />
          </MDBCol> */}
          <MDBCol sm="4" style={{ padding: '1.5em', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {/* <FilterForm
              handleFilter={(filterArray) => this.handleFilter(filterArray)}
              clearFilter={() => this.clearFilter()}
              fields={state.dateFilterFields}
              submitOnSelect
            /> */}
          </MDBCol>
        </MDBRow>
        {props.driverFetched && (
          <DriverHours
            eldDailyCertification={state.eldDailyCertification}
            driver={props.driver}
            driverFetched={props.driverFetched}
          />
        )}
      </MDBContainer>
    );
  }
}

DriverHoursDetailsLayout.propTypes = {
  driver: PropTypes.object.isRequired,
  driverFetched: PropTypes.bool.isRequired,
};

export default DriverHoursDetailsLayout;
