import React from 'react';
import moment from 'moment-timezone';
import { MDBContainer, MDBCol, MDBRow } from 'mdbreact';

// API
import { getAttribute } from 'api/Parse';
import { ELDEventTypeCode, ELDEventTypeCodeDescriptions } from 'enums/ELDEventTypeCode';

// Style
import './style.scss';

class HOSEditSummaryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { props } = this;
    const { eldEdit } = props;
    const note = getAttribute(eldEdit, 'note');
    const requestedELDEvents = getAttribute(eldEdit, 'requestedELDEvents');
    const requestedELDEventsLength = requestedELDEvents && requestedELDEvents.length;
    const driver = getAttribute(eldEdit, 'driver', true);
    const driverTimezoneOffsetFromUTC = getAttribute(driver, 'timezoneOffsetFromUTC');

    return (
      <MDBContainer className="py-2" fluid>
        {
          requestedELDEvents.map((eldEvent, index) => {
            const eldEventTypeCodeInt = getAttribute(eldEvent, 'eldEventTypeCodeInt');
            const startDateTime = getAttribute(eldEvent, 'eventDateTime');
            const endDateTime = (requestedELDEventsLength > 1 && (index < requestedELDEventsLength - 1)) ? getAttribute(requestedELDEvents[index + 1], 'eventDateTime') : getAttribute(eldEvent, 'eventDateTime');
            let eldEventStatusString;

            switch (eldEventTypeCodeInt) {
              case ELDEventTypeCode.OFF_DUTY:
                eldEventStatusString = ELDEventTypeCodeDescriptions.OFF_DUTY;
                break;
              case ELDEventTypeCode.SLEEPER_BERTH:
                eldEventStatusString = ELDEventTypeCodeDescriptions.SLEEPER_BERTH;
                break;
              case ELDEventTypeCode.DRIVING:
                eldEventStatusString = ELDEventTypeCodeDescriptions.DRIVING;
                break;
              case ELDEventTypeCode.ON_DUTY_NOT_DRIVING:
                eldEventStatusString = ELDEventTypeCodeDescriptions.ON_DUTY_NOT_DRIVING;
                break;
              case ELDEventTypeCode.INTERMEDIATE_LOG_WITH_CONVENTIONAL_LOCATION_PRECISION:
                eldEventStatusString = ELDEventTypeCodeDescriptions.INTERMEDIATE_LOG_WITH_CONVENTIONAL_LOCATION_PRECISION;
                break;
              case ELDEventTypeCode.INTERMEDIATE_LOG_WITH_REDUCED_LOCATION_PRECISION:
                eldEventStatusString = ELDEventTypeCodeDescriptions.INTERMEDIATE_LOG_WITH_REDUCED_LOCATION_PRECISION;
                break;
              case ELDEventTypeCode.PC_YM_WT_CLEARED:
                eldEventStatusString = ELDEventTypeCodeDescriptions.PC_YM_WT_CLEARED;
                break;
              case ELDEventTypeCode.AUTHORIZED_PERSONAL_USE_OF_CMV:
                eldEventStatusString = ELDEventTypeCodeDescriptions.AUTHORIZED_PERSONAL_USE_OF_CMV;
                break;
              case ELDEventTypeCode.YARD_MOVES:
                eldEventStatusString = ELDEventTypeCodeDescriptions.YARD_MOVES;
                break;
              default:
                eldEventStatusString = 'N/A';
                break;
            }

            return (
              <MDBRow key={getAttribute(eldEvent, 'objectId')} className="py-1 hos-summary-list">
                <MDBCol className="hos-summary-list-index pr-0">
                  <MDBRow className="hos-summary-list-header"> {(requestedELDEventsLength > 1) && index + 1} </MDBRow>
                  <MDBRow />
                </MDBCol>
                <MDBCol size="3">
                  <MDBRow className="hos-summary-list-header"> STATUS </MDBRow>
                  <MDBRow> {eldEventStatusString} </MDBRow>
                </MDBCol>
                <MDBCol size="3">
                  <MDBRow className="hos-summary-list-header"> TIME </MDBRow>
                  <MDBRow> {`${moment.tz(startDateTime, driverTimezoneOffsetFromUTC).format('HH:mm z')} - ${moment.tz(endDateTime, driverTimezoneOffsetFromUTC).format('HH:mm z')}`}</MDBRow>
                </MDBCol>
                <MDBCol size="2">
                  {index === 0 && (
                    <React.Fragment>
                      <MDBRow className="hos-summary-list-header"> REASON </MDBRow>
                      <MDBRow> {note || '-'} </MDBRow>
                    </React.Fragment>
                  )}
                </MDBCol>
              </MDBRow>
            );
          })
        }
      </MDBContainer>
    );
  }
}

export default HOSEditSummaryList;
