import { addRecord, copyQuery, count, createQuery, createQueryOr, find, getAttribute, getCurrentUser, getObjectById, includePointers, setQueryRestriction, sortQuery, updateRecord } from 'api/Parse';

import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

/** @module Dispatcher */

/**
 * @memberof module:Dispatcher
 * @description Get current company's dispatchers
 * 
 * @param {int} page 
 * @param {int} limit 
 * @param {object} sortBy - Sort object
 * @param {array} filters - array of Filter objects
 * @param {bool} includeChildCompanies - include child/sub-companies of this parent company
 * @param {bool} queryAll
 */
async function getCompanyDispatchers(page = 0, limit = 20, sortBy = new Sort('createdAt', QuerySortOrderTypes.DESCENDING), filters = [], includeChildCompanies, queryAll) {
  let dispatcherQuery = createQuery('Dispatcher');

  const _filters = [].concat(filters);
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  const pointersArr = ['user'];

  if (includeChildCompanies) {
    // if getting child companies' drivers, the additional queries
    const companyLinkQuery = createQuery('CompanyLink');

    const companyLinkFilters = [
      new Filter('parentCompany', belongsToCompany, QueryRestrictionTypes.EQUAL_TO),
      new Filter('authorized', true, QueryRestrictionTypes.EQUAL_TO),
    ];

    companyLinkFilters.map(filter => {
      setQueryRestriction(companyLinkQuery, filter.queryRestriction, filter.attribute, filter.value);
    });

    // now that we have all the child companies, create queries that get the drivers of them
    const companyLinks = await find(companyLinkQuery);
    const childCompanyDispatcherQueries = companyLinks.map(company => {
      const childCompanyDispatcherQuery = createQuery('Dispatcher');
      setQueryRestriction(childCompanyDispatcherQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', getAttribute(company, 'childCompany'));
      return childCompanyDispatcherQuery;
    })

    // the main current user's company's driver query
    const mainCompanyDispatcherQuery = createQuery('Driver');
    setQueryRestriction(mainCompanyDispatcherQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);

    // altogether
    dispatcherQuery = createQueryOr([mainCompanyDispatcherQuery, ...childCompanyDispatcherQueries]);

  } else {
    setQueryRestriction(dispatcherQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
  }

  // set universal driver filters
  // _filters.push(new Filter('isHidden', true, QueryRestrictionTypes.NOT_EQUAL_TO)); // get all non-deleted users

  _filters.map(filter => {
    setQueryRestriction(dispatcherQuery, filter.queryRestriction, filter.attribute, filter.value);
  });

  setQueryRestriction(dispatcherQuery, QueryRestrictionTypes.EQUAL_TO, 'enabled', true);

  // at this point, copy current query to get the number of pages for pagination
  let dispatcherCountQuery = copyQuery(dispatcherQuery);
  
  // sort
  sortQuery(dispatcherQuery, sortBy.order, sortBy.attribute);

  if (!queryAll) {
    setQueryRestriction(dispatcherQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
    setQueryRestriction(dispatcherQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
  }

  includePointers(dispatcherQuery, pointersArr);

  // now get the count and the drivers
  const promises = [count(dispatcherCountQuery), find(dispatcherQuery, false, queryAll)];

  try {
    const [totalDispatcherCount, dispatchers] = await Promise.all(promises);
    return { dispatchers, totalDispatcherCount }

  } catch(err) {
    throw new Error(err);
  }
}

export {
  getCompanyDispatchers,
}