import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from 'store';
import sbStore from 'sbStore';
import router from 'router';
import { initialize } from 'actions/Main';

// Buffer import which is required for some packages
import { Buffer } from 'buffer';
window.Buffer = window.Buffer || Buffer;

// Context
import StoreContext from 'contexts/StoreContext';
import SBStoreContext from 'contexts/SBStoreContext';

// Material-ui wrappers
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

// PrimeReact UI / Grid library
require('primereact/resources/themes/lara-light-blue/theme.css');
require('primereact/resources/primereact.min.css');
require('primeicons/primeicons.css');
require('primeflex/primeflex.min.css');

// Mapbox
require('mapbox-gl/dist/mapbox-gl.css');

// GA
import ReactGA from 'react-ga';
ReactGA.initialize('UA-69715806-5');
ReactGA.pageview(window.location.pathname + window.location.search);

// Product Analytics
import { initProductAnalytics } from 'sb-csapi/dist/api/Analytics/Product';
initProductAnalytics('yAczClZaZfSZanhrwJHu1Aik3p5kQOta');

// Feature Flagging
// import { initFlagging } from 'sb-csapi/dist/api/Analytics/Flagging';
// initFlagging('sdk-hkV0KxVnDJe08Vdh', undefined, true);

require('styles/master.scss');
require('styles/_card.scss');
require('styles/_modal.scss');
require('styles/_table.scss');
require('styles/_bootstrap_overwrites.scss');
require('styles/_rc-slider_overwrites.css');
require('styles/_react_autosuggest_overwrites.scss');
require('styles/_react-datepicker_overwrites.css');
require('styles/_rc-time-picker_overwrites.scss');
require('styles/_stripe.css');
require('styles/_sb_bootstrap.scss');
require('styles/_navigation.scss');
require('styles/_form.scss');
require('styles/_mapbox.css');

initialize().then(() => {
  // Provider is a top-level component that wrapps our entire application, including
  // the Router. We pass it a reference to the store so we can use react-redux's
  // connect() method for Component Containers.

  ReactDOM.render(
    <Provider context={StoreContext} store={store}>
      <Provider context={SBStoreContext} store={sbStore}>
        <MuiThemeProvider>
          {router}
        </MuiThemeProvider>
      </Provider>
    </Provider>,
    document.getElementById('root')
  );
});
