import * as ActionConstants from './ActionConstants';
import store from '../store';

import * as Setters from '../api/Setters';
import * as Getters from '../api/Getters';

export function fetchingVendors() {
  return {
    type: ActionConstants.FETCH_VENDORS_INPROGRESS,
  };
}

export function fetchVendorsSuccess(vendors) {
  return {
    type: ActionConstants.FETCH_VENDORS_SUCCESS,
    vendors,
  };
}

export function fetchVendorsError() {
  return {
    type: ActionConstants.FETCH_VENDORS_ERROR,
  };
}

export function deleteVendors() {
  return {
    type: ActionConstants.DELETE_VENDORS,
  };
}

export function addingVendor() {
  return {
    type: ActionConstants.ADD_VENDOR_INPROGRESS,
  };
}

export function addVendorSuccess(vendor) {
  return {
    type: ActionConstants.ADD_VENDOR_SUCCESS,
    vendor,
  };
}

export function addVendorError(error) {
  return {
    type: ActionConstants.ADD_VENDOR_ERROR,
    error,
  };
}

export function updatingVendor(vendor) {
  return {
    type: ActionConstants.UPDATE_VENDOR_INPROGRESS,
    vendor,
  };
}

export function updateVendorSuccess(vendor) {
  return {
    type: ActionConstants.UPDATE_VENDOR_SUCCESS,
    vendor,
  };
}

export function updateVendorError(vendor, error) {
  return {
    type: ActionConstants.UPDATE_VENDOR_ERROR,
    vendor,
    error,
  };
}

export function disableVendorsSuccess(vendors) {
  return {
    type: ActionConstants.DISABLE_VENDORS_SUCCESS,
    vendors,
  };
}

export function disableVendor(vendorParseObj) {
  const promise = new Promise((resolve, reject) => {
    vendorParseObj.set('enabled', false).save().then(
      vendorObject => {
        resolve();
        store.dispatch(disableVendorsSuccess([vendorObject]));
      },
    );
  });
  return promise;
}

export function getVendorIncludeArr() {
  return ['address'];
}

export function fetchVendorsForState(page, limit, filter, sortBy) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingVendors());
    filter.push({ name: 'enabled', queryType: 'equalTo', value: true });
    Getters.queryCompanyObjects('Vendor', page, limit, filter, sortBy, getVendorIncludeArr()).then(
      vendors => {
        store.dispatch(fetchVendorsSuccess(vendors));
        resolve(vendors);
      },
      error => {
        store.dispatch(fetchVendorsError(error));
        reject(error);
      }
    );
  });
  return promise;
}

export function deleteVendorsForState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteVendors());
    resolve(store.getState().Vendor);
  });
  return promise;
}

export function addVendorToState(vendorParseObj) {
  return store.dispatch(addVendorSuccess(vendorParseObj));
}

export function updateVendorForState(vendorParseObj, property, value) {
  store.dispatch(updatingVendor(vendorParseObj));
  const promise = new Promise((resolve, reject) => {
    // user-based attributes
    vendorParseObj.set(property, value).save().then(
      vendorObject => {
        Getters.fetchParseObjectArr(store.getState().Vendor.vendors).then((vendorsParseObjArr) => {
          resolve(vendorObject);
          store.dispatch(updateVendorSuccess(vendorsParseObjArr));
        });
      },
      error => {
        console.log(error);
        reject(error);
        store.dispatch(updateVendorError(error));
      }
    );
  });
  return promise;
}

export function updateVendorAddressForState(addressParseObj, property, value) {
  store.dispatch(updatingVendor(addressParseObj));
  const promise = new Promise((resolve, reject) => {
    // user-based attributes
    addressParseObj.set(property, value).save().then(
      addressObject => {
        Getters.fetchParseObjectArr(store.getState().Vendor.vendors).then((vendorsParseObjArr) => {
          resolve(addressObject);
          store.dispatch(updateVendorSuccess(vendorsParseObjArr));
        });
      },
      error => {
        console.log(error);
        reject(error);
        store.dispatch(updateVendorError(error));
      }
    );
  });
  return promise;
}
