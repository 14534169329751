import React, { useState, useEffect, useRef } from 'react';

// API
import { getDispatchJobTransactions, updateDispatchJobTransaction } from 'api/Dispatch/DispatchJobTransactions';
import { updateDispatchReceivable } from 'api/Dispatch/DispatchReceivable';
import { updateDispatchPayable } from 'api/Dispatch/DispatchPayable';

// CSAPI API
import { getRecordByObjectId, getAttribute, getCurrentUserSessionToken, addRecord, getCurrentUserCompanyObjectId } from 'sb-csapi/dist/AAPI';

// CSAPI ENUMS
import { Currency } from 'sb-csapi/dist/enums/Finance/Currency';
import { PaymentStatus } from 'sb-csapi/dist/enums/Dispatch/PaymentStatus';
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// CSAPI sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Components
import TransactionHistoryTable from 'components/Dispatch/TransactionHistoryTable/TransactionHistoryTable';
import InputNumber from 'sbCore/InputNumber/InputNumber';

// sbCore Components
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import SelectButton from 'sbCore/SelectButton/SelectButton';
import Skeleton from 'sbCore/Skeleton/Skeleton';

// Styles
import './style.scss';

/**
 * @description Renders information pertaining to the transaction information as well as a transaction history table
 * @param {DispatchReceivable} [dispatchReceivable] - The DispatchReceivable to retrieve transactions from
 * @param {DispatchPayable} [dispatchPayable] - The DispatchPayable to retrieve transactions from
 * @param {DispatchJobAccounting} dispatchJobAccounting - The DispatchJobAccounting record to the DispatchReceivable or DispatchPayable
 * @param {Function} [toggleRefresh] - Refreshes the state of the parent component when triggered
 * @param {Function} [onShowVoidConfirmationModal] - Displays the void receivable confirmation modal when ran
 * @param {Boolean} [isVoided] - Whether or not the DispatchReceivable is voided
 * @param {Boolean} [disableVoiding] - Whether or not to disable voiding the payable/receivable
 * @returns
 */
function TransactionsContainer({ ...props }) {

  const paymentOptions = [Currency.CA.short, Currency.US.short];

  // ** useRefs ** //
  // If its the first update, don't update the payment status when calculating the amount outstanding.
  // This is so that the calculated amount outstanding doesn't change a manually updated payment status
  const firstUpdate = useRef(true);

  // ** useStates ** //
  const [dispatchJobTransactions, setDispatchJobTransactions] = useState([]);

  const [invoiceTotal, setInvoiceTotal] = useState(null);
  const [currencyInt, setCurrencyInt] = useState(null);
  const [amountOutstanding, setAmountOutstanding] = useState(null);

  const [refreshInt, setRefreshInt] = useState(0); // Refreshses state when triggered
  const [lastAction, setLastAction] = useState(''); // Triggers the edit mode of a newly added transaction

  // The initialLoading state is used when initially loading the invoice amount and amount outstanding,
  // whereas isLoading is also used within the child components
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  // ** useEffects ** //

  // Initialize states with required data when a DispatchJobAccounting record's passed, or when a refresh is triggered
  useEffect(() => {
    let didCancel = false;
    setIsLoading(true);

    async function getInvoiceInformation() {
      if (!props.dispatchJobAccounting || (!props.dispatchReceivable && !props.dispatchPayable)) return;

      const filters = [new Filter(QueryRestriction.NOT_EQUAL_TO, 'isHidden', true)];
      if (props.dispatchPayable) {
        filters.push(new Filter(QueryRestriction.EQUAL_TO, 'dispatchPayable', props.dispatchPayable));
      } else if (props.dispatchReceivable) {
        filters.push(new Filter(QueryRestriction.EQUAL_TO, 'dispatchReceivable', props.dispatchReceivable));
      }

      const _dispatchJobTransactions = await getDispatchJobTransactions(
        undefined,
        filters,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      );

      if (props.dispatchReceivable) {
        // Parse the string invoice document state object if it exists
        const dispatchDocumentInvoice = getAttribute(props.dispatchReceivable, 'dispatchDocumentInvoice');
        let invoiceDocumentStateMap;
        if (dispatchDocumentInvoice) {
          const stateJSONStringed = getAttribute(dispatchDocumentInvoice, 'stateJSONStringed');
          if (stateJSONStringed) invoiceDocumentStateMap = JSON.parse(stateJSONStringed);
        }

        if (!didCancel) {
          // Update states only if an invoice document exists
          if (invoiceDocumentStateMap) {

            // Default to US currency if not otherwise stated
            if (invoiceDocumentStateMap.currency === Currency.CA.short) {
              setCurrencyInt(Currency.CA.index);
            } else {
              setCurrencyInt(Currency.US.index);
            }

            setInvoiceTotal(invoiceDocumentStateMap.amountDue);

            const _amountOutstanding = calculateAmountOutstanding(_dispatchJobTransactions, invoiceDocumentStateMap.amountDue, props.dispatchReceivable);
            setAmountOutstanding(_amountOutstanding);
          }

          setDispatchJobTransactions(_dispatchJobTransactions);
          setIsInitialLoading(false);

          // If triggered, open the edit mode of the newly added transaction
          if (lastAction === 'addtransaction') {
            setTimeout(() => {
              // Retrieves the edit button element of the first transaction in the table
              const firstRowEditButtonEL = document.querySelector('.p-row-editor-init.p-link');
              setIsLoading(false);
              if (firstRowEditButtonEL) firstRowEditButtonEL.click();
            }, 2000);
            setLastAction('');
          } else {
            setIsLoading(false);
          }

        }
      }

      if (props.dispatchPayable) {
        if (!didCancel) {
          const _invoiceTotal = getAttribute(props.dispatchPayable, 'invoiceTotal');
          setInvoiceTotal(_invoiceTotal);

          const _amountOutstanding = calculateAmountOutstanding(_dispatchJobTransactions, _invoiceTotal, props.dispatchPayable);
          setAmountOutstanding(_amountOutstanding);

          setDispatchJobTransactions(_dispatchJobTransactions);
          setIsInitialLoading(false);

          // If triggered, open the edit mode of the newly added transaction
          if (lastAction === 'addtransaction') {
            setTimeout(() => {
              // Retrieves the edit button element of the first transaction in the table
              const firstRowEditButtonEL = document.querySelector('.p-row-editor-init.p-link');
              setIsLoading(false);
              if (firstRowEditButtonEL) firstRowEditButtonEL.click();
            }, 2000);
            setLastAction('');
          } else {
            setIsLoading(false);
          }
        }
      }
    }

    getInvoiceInformation();
    return () => { didCancel = true; };
  }, [props.dispatchJobAccounting, props.dispatchReceivable, props.dispatchPayable, refreshInt]);

  // Update the amount outstanding as well as the record's invoiceTotal if changed
  // Commented out since invoice total can't be updated at the moment
  useEffect(() => {
    // const _amountOutstanding = calculateAmountOutstanding(dispatchJobTransactions, invoiceTotal, dispatchJobAccountingCustomer);
    // setAmountOutstanding(_amountOutstanding);

    // const recordInvoiceTotal = getAttribute(dispatchJobAccountingCustomer, 'invoiceTotal', true);
    // if (invoiceTotal === recordInvoiceTotal) return;
    /** @TODO: Replace function with the DispatchReceivable equivalent  */
    // updateDispatchJobAccountingCustomer(undefined, dispatchJobAccountingCustomer, undefined, { invoiceTotal }, true);
  }, [invoiceTotal]);

  // Update the currency being used by the customer record and transaction records if changed
  // Commented out since currency can't be updated at the moment
  useEffect(() => {
    // const recordCurrency = getAttribute(dispatchJobAccountingCustomer, 'currency', true);
    // if (currencyInt === recordCurrency) return;
    /** @TODO: Replace function with the DispatchReceivable equivalent  */
    // updateDispatchJobAccountingCustomer(undefined, dispatchJobAccountingCustomer, undefined, { currency: currencyInt }, true);

    // dispatchJobTransactions.forEach((dispatchJobTransaction) => {
    //   updateDispatchJobTransaction(undefined, dispatchJobTransaction, undefined, { currency: currencyInt }, true);
    // });
  }, [currencyInt]);

  // ** Helper Functions ** //

  // Calculates the amount outstanding and updates the payment status if required
  function calculateAmountOutstanding(dispatchJobTransactions, invoiceTotal, dispatchReceivable, dispatchPayable) {
    let amountPaid = 0;
    dispatchJobTransactions.forEach((dispatchJobTransaction) => {
      const amount = getAttribute(dispatchJobTransaction, 'amount');
      if (!amount) return;
      amountPaid += amount;
    });

    if (!invoiceTotal) invoiceTotal = 0;

    const _amountOutstanding = invoiceTotal - amountPaid;
    // Update the payment status of the customer depending on the amount outstanding
    let paymentStatusInt;
    if (dispatchReceivable) paymentStatusInt = getAttribute(dispatchReceivable, 'status');
    else if (dispatchPayable) paymentStatusInt = getAttribute(dispatchPayable, 'status');
    let newPaymentStatusInt;
    if (_amountOutstanding <= 0) {
      newPaymentStatusInt = PaymentStatus.PAID.status;
    } else if (_amountOutstanding === invoiceTotal) {
      newPaymentStatusInt = PaymentStatus.UNPAID.status;
    } else if (_amountOutstanding > 0 && _amountOutstanding < invoiceTotal) {
      newPaymentStatusInt = PaymentStatus.PARTIALLY_PAID.status;
    }

    // Check if its the first update so that manually updated payment statuses aren't overwritten
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return _amountOutstanding;
    }

    if ((newPaymentStatusInt || newPaymentStatusInt === 0) && newPaymentStatusInt !== paymentStatusInt) {
      if (dispatchReceivable) {
        updateDispatchReceivable(undefined, dispatchReceivable, undefined, { status: newPaymentStatusInt }, true);
      } else if (dispatchPayable) {
        updateDispatchPayable(dispatchPayable, undefined, { status: newPaymentStatusInt }, true);
      }
      if (props.toggleRefresh) props.toggleRefresh();
    }
    return _amountOutstanding;
  }

  // ** Callback Functions ** //

  // Set the currency int state depending on the selected currency
  function onCurrencyChange(value) {
    if (value === Currency.CA.short) {
      setCurrencyInt(Currency.CA.index);
    } else if (value === Currency.US.short) {
      setCurrencyInt(Currency.US.index);
    }
  }

  // Updates state with the last action used - currently triggers the edit mode of the newly added transaction
  function onLastActionChange(action) {
    setLastAction(action);
  }

  // Refresh the state if refreshInt state is updated
  function toggleRefresh() {
    setRefreshInt(!refreshInt);
  }

  // ** Components ** //
  const totalInvoiceAmountDisplay = (
    <>
      <InputLabel>Total Invoice Amount</InputLabel>

      {isInitialLoading && (
        <Skeleton width="10rem" height="2rem" className="p-mb-2" />
      )}

      {!isInitialLoading && (
        // Replaced with text to prevent editing
        // <InputNumber
        //   className="invisible-border p-inputtext-sm"
        //   value={invoiceTotal}
        //   onValueChange={(e) => setInvoiceTotal(e.value)}
        //   mode="currency"
        //   currency={currencyInt === Currency.CA.index ? Currency.CA.short : Currency.US.short}
        //   locale="en-US"
        //   placeholder={Intl.NumberFormat('en-US', { style: 'currency', currency: currencyInt === Currency.CA.index ? Currency.CA.short : Currency.US.short }).format(0)}
        // />
        <div className="amount-outstanding text-sm">
          {Intl.NumberFormat('en-US', { style: 'currency', currency: currencyInt === Currency.CA.index ? Currency.CA.short : Currency.US.short }).format(invoiceTotal)}
        </div>
      )}
    </>
  );

  // Replaced with a currency display to prevent editing
  const currencySelectButton = (
    <>
      {isInitialLoading && (
        <Skeleton width="4rem" height="2rem" className="p-mb-2 currency-select-skeleton" />
      )}

      {!isInitialLoading && (
        <SelectButton
          className="p-button-sm select-currency"
          value={currencyInt === Currency.CA.index ? Currency.CA.short : Currency.US.short}
          options={paymentOptions}
          onChange={(e) => onCurrencyChange(e.value)}
          disabled
        />
      )}
    </>
  );

  const currencyDisplay = (
    <>
      <InputLabel>Currency</InputLabel>

      {isInitialLoading && (
        <Skeleton width="10rem" height="2rem" className="p-mb-2" />
      )}

      {!isInitialLoading && (
        <div className="amount-outstanding text-sm">
          {currencyInt === Currency.CA.index ? Currency.CA.short : Currency.US.short}
        </div>
      )}
    </>
  );

  const amountOutstandingDisplay = (
    <>
      <InputLabel>Amount Outstanding</InputLabel>

      {isInitialLoading && (
        <Skeleton width="10rem" height="2rem" className="p-mb-2" />
      )}

      {!isInitialLoading && (
        <div className="amount-outstanding text-sm">
          {Intl.NumberFormat('en-US', { style: 'currency', currency: currencyInt === Currency.CA.index ? Currency.CA.short : Currency.US.short }).format(amountOutstanding)}
        </div>
      )}
    </>
  );

  const transactionHistoryTable = (
    <TransactionHistoryTable
      dispatchJobTransactions={dispatchJobTransactions}
      dispatchPayable={props.dispatchPayable}
      dispatchReceivable={props.dispatchReceivable}
      toggleRefresh={toggleRefresh}
      isLoading={isLoading}
      onLastActionChange={onLastActionChange}
      currencyInt={currencyInt}
      onShowVoidConfirmationModal={(value) => props.onShowVoidConfirmationModal(value)}
      isVoided={props.isVoided}
      disableVoiding={props.disableVoiding}
    />
  );

  return (
    <ColoredSection title="Transaction Information" className="transaction-container">

      <div className="flex flex-row mb-3">

        <div>{totalInvoiceAmountDisplay}</div>

        {/* <div className="mt-4">{currencySelectButton}</div> */}
        <div className="ml-7">{currencyDisplay}</div>

        <div className="ml-7">{amountOutstandingDisplay}</div>

      </div>

      {transactionHistoryTable}

    </ColoredSection>
  );
}

export default TransactionsContainer;
