import React, { useState, useEffect } from 'react';

// sbCore Components
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import Fieldset from 'sbCore/Fieldset/Fieldset';

// styles
import './style.scss';
import QuoteInformationDiscounts from '../QuoteInformationDiscounts/QuoteInformationDiscounts';
import QuoteInformationAdditionalCharges from '../QuoteInformationAdditionalCharges/QuoteInformationAdditionalCharges';

function QuoteInformationAdditionalDetails({ dispatchQuote, toggleAdditionalDetailsState }) {
  return (
    // <ColoredSection
    //   className="quote-information-additional-details-section"
    //   title="Additional Charges & Discounts"
    //   noFooter
    //   variant="compact"
    // >
    //   <QuoteInformationAdditionalCharges dispatchQuote={dispatchQuote} toggleAdditionalDetailsState={toggleAdditionalDetailsState} />
    //   <QuoteInformationDiscounts dispatchQuote={dispatchQuote} toggleAdditionalDetailsState={toggleAdditionalDetailsState} />
    // </ColoredSection>
    <Fieldset
      toggleable
      collapsed
      legend="2. Additional Charges & Discounts"
      className="quote-information-additional-details-section mx-3"
      sbVariant="dispatch"
    >
      <QuoteInformationAdditionalCharges dispatchQuote={dispatchQuote} toggleAdditionalDetailsState={toggleAdditionalDetailsState} />
      <QuoteInformationDiscounts dispatchQuote={dispatchQuote} toggleAdditionalDetailsState={toggleAdditionalDetailsState} />
    </Fieldset>
  );
}

export default QuoteInformationAdditionalDetails;
