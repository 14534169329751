import React from 'react';
import PropTypes from 'prop-types';
// Components
import ChooseOrAddButton from 'components/ChooseOrAddModal/container/ChooseOrAddButton';
import DatePicker from 'components/DatePicker/container/DatePicker';
// CSS
import styles from './GlobalJobDetails.module.scss';

function GlobalJobDetails(props) {
  const globalJobDetails = props.globalJobDetails;

  return (
    <div className={styles.globalJobDetails}>
      <table className={styles.table}><tbody>
        <tr>
          <td>
            <div>
              <label className="inputLabel">Job ID</label>
              <input className="input" value={globalJobDetails.batchId} type="text" placeholder="....." onChange={(e) => props.handleFormChange('batchId', e.target.value, true)} />
            </div>
            <div className={styles.formGroup}>
              <label className="inputLabel">Job Notes</label>
              <textarea value={globalJobDetails.notes} onChange={(e) => props.handleFormChange('notes', e.target.value, true)} placeholder="Any comments related to the action..."></textarea>
            </div>
          </td>
          {/*
          <td>
            <div>
              <label className="inputLabel">Customer</label>
              { globalJobDetails.customer &&
                <div><b>{ globalJobDetails.customer.get('name') }</b></div>
              }
              <div className={styles.chooseOrAdd}><ChooseOrAddButton label="Customer" property="vendor" handleChooseOrAdd={(vendorParseObj) => props.handleFormChange('customer', vendorParseObj, true)}>Choose Customer</ChooseOrAddButton></div>
            </div>
          </td>
          */}
        </tr>
      </tbody></table>
    </div>
  );
}

GlobalJobDetails.propTypes = {
  openChooseOrAddModal: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  globalJobDetails: PropTypes.object.isRequired,
};

export default GlobalJobDetails;
