import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';

// API
import TouchingStateProvinces from 'api/Lists/TouchingStateProvinces';
import * as Helpers from 'api/Helpers';
import history from 'sbHistory';
import { getLocationDescriptionBreakdown } from 'api/VehicleLocation/VehicleLocation';
import { getTimezoneString } from 'api/IFTARoute/IFTARoute.old';

// Components
import SBBlock from 'components/Shared/SBBlock/SBBlock';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import SBTable from 'components/Shared/SBTable/SBTable';
import Checkbox from 'material-ui/Checkbox';
import MapPopup from 'components/IFTARoute/container/MapPopup';
import { MDBBtn, MDBModal, MDBModalBody } from 'mdbreact';
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay';
import FuelHelpDialog from 'components/IFTARoute/view/FuelHelpDialog';
import DeleteXButton from 'components/ConfirmModal/container/DeleteXButton';

function areStatesTouching(currentStateProvince, previousStateProvince) {
  if (currentStateProvince && previousStateProvince && currentStateProvince !== previousStateProvince && TouchingStateProvinces[currentStateProvince.toUpperCase()]) {
    return (TouchingStateProvinces[currentStateProvince.toUpperCase()].indexOf(previousStateProvince.toUpperCase()) !== -1);
  } else {
    return true;
  }
}

class VehicleRouteBreakdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      selectedIftaRouteArr: [],
      selectedIftaRouteMap: {},
      shopMapPopup: false,
      mapData: {},
      tableBodyRowData: [],
      driverTimeZoneStr: moment.tz.guess(),
    };
    this.handleSelectIftaRouteArr = this.handleSelectIftaRouteArr.bind(this);
    this.parseTableBodyRowData = this.parseTableBodyRowData.bind(this);
  }

  componentDidMount() {
    this.setState({ ...this.state, loading: true }, () => {
      this.parseTableBodyRowData();
    });
  }

  componentWillReceiveProps(nextProps) {
    const iftaRouteArr = this.props.iftaRouteArr;
    const nextIftaRouteArr = nextProps.iftaRouteArr;
    if (this.props.vehicleBreakdownFilter !== nextProps.vehicleBreakdownFilter) {
      this.parseTableBodyRowData();
    }
    if (iftaRouteArr.length !== nextIftaRouteArr.length) return this.parseTableBodyRowData();
  }

  async parseTableBodyRowData() {
    await this.setState({ ...this.state, loading: true });
    const parse = async () => {
      const driverTimeZoneStr = this.props.iftaRouteArr && getTimezoneString(this.props.iftaRouteArr[0]);
      await this.setState({ ...this.state, driverTimeZoneStr });

      if (this.props.vehicleBreakdownFilter.value === 'showAllDetails') {
        return this.props.iftaRouteArr.map((iftaRouteObj) => iftaRouteObj.toJSON());
      } else if (this.props.vehicleBreakdownFilter.value === 'hideZeroMileages') {
        return this.props.iftaRouteArr.filter((iftaRoute) => (iftaRoute.get('savedVehicleKm') && Math.round(iftaRoute.get('savedVehicleKm')) !== 0)).map((iftaRouteObj) => iftaRouteObj.toJSON());
      } else if (this.props.vehicleBreakdownFilter.value === 'combineStateProvinces') {
        let previousStateProvince = null;
        const tableBodyRowData = [];
        for (let i = 0; i < this.props.iftaRouteArr.length; i++) {
          const iftaRouteObj = this.props.iftaRouteArr[i].toJSON();
          const currentStateProvince = iftaRouteObj.stateProvince;
          if (i === 0) {
            tableBodyRowData.push({ ...iftaRouteObj });
            previousStateProvince = currentStateProvince;
          } else if (currentStateProvince === previousStateProvince) {
            const previousIftaRouteObj = tableBodyRowData[tableBodyRowData.length - 1];
            // setting temp vars for table
            previousIftaRouteObj.dateEnd = iftaRouteObj.dateEnd;
            previousIftaRouteObj.vehicleLocationEnd = iftaRouteObj.vehicleLocationEnd;
            previousIftaRouteObj.savedVehicleKm += iftaRouteObj.savedVehicleKm;
            previousIftaRouteObj.totalVehicleKmDiff += iftaRouteObj.totalVehicleKmDiff;
            previousIftaRouteObj.distanceKm += iftaRouteObj.distanceKm;
          } else {
            tableBodyRowData.push(iftaRouteObj);
            previousStateProvince = currentStateProvince;
          }
        }
        return tableBodyRowData;
      }
    }
    const tableBodyRowData = await parse();
    this.props.setData(tableBodyRowData);
    this.setState({ ...this.state, loading: false, tableBodyRowData });
  }

  handleSelectIftaRouteArr(selectedIftaRouteJson, checked) {
    const selectedIftaRouteArr = this.state.selectedIftaRouteArr;
    const selectedIftaRouteMap = { ...this.state.selectedIftaRouteMap };
    if (checked) {
      selectedIftaRouteArr.push(selectedIftaRouteJson);
      selectedIftaRouteMap[selectedIftaRouteJson.objectId] = true;
    } else {
      for (let i = 0; i < selectedIftaRouteArr.length; i++) {
        if (selectedIftaRouteArr[i].objectId === selectedIftaRouteJson.objectId) {
          selectedIftaRouteArr.splice(i, 1);
          selectedIftaRouteMap[selectedIftaRouteJson.objectId] = false;
          break;
        }
      }
    }

    this.props.selectedIftaRoute(selectedIftaRouteArr);
    this.setState({ ...this.state, selectedIftaRouteArr, selectedIftaRouteMap });
  }

  render() {
    const tableHeaderStyles = {
      checkbox: { width: '5%' },
      date: { width: '15%' },
      buttons: { width: '15%' },
      stateProvince: { width: '10%' },
      driver: { width: '15%' },
      fuel: { width: '15%' },
      fuelCard: { width: '15%' },
      saved: { width: '10%' },
    };

    const tableHeaderRows = [{
      key: 'sb-jobs-list',
      columns: [],
    }];

    tableHeaderRows[0].columns.push({
      element: <div></div>,
      props: {
        style: tableHeaderStyles.checkbox,
        // handleSort: () => { this.handleSort(AttributeTypes.BATCH_ID) },
        // isSortActive: (fetchAttributes.sortBy.attribute === AttributeTypes.BATCH_ID),
        // sortOrder: fetchAttributes.sortBy.order,
      }
    });

    tableHeaderRows[0].columns.push({
      element: <div>Trip Start</div>,
      props: {
        style: tableHeaderStyles.date,
        // handleSort: () => { this.handleSort(AttributeTypes.BATCH_ID) },
        // isSortActive: (fetchAttributes.sortBy.attribute === AttributeTypes.BATCH_ID),
        // sortOrder: fetchAttributes.sortBy.order,
      }
    });

    tableHeaderRows[0].columns.push({
      element: <div>Trip End</div>,
      props: {
        style: tableHeaderStyles.date,
        // handleSort: () => { this.handleSort(AttributeTypes.BATCH_ID) },
        // isSortActive: (fetchAttributes.sortBy.attribute === AttributeTypes.BATCH_ID),
        // sortOrder: fetchAttributes.sortBy.order,
      }
    });

    tableHeaderRows[0].columns.push({
      element: <div>State / Province</div>,
      props: {
        style: tableHeaderStyles.stateProvince,
      }
    });

    tableHeaderRows[0].columns.push({
      element: <div>Driver</div>,
      props: {
        style: tableHeaderStyles.driver,
      }
    });

    tableHeaderRows[0].columns.push({
      element: <div className="centerText">Fuel (Gallons) <FuelHelpDialog /></div>,
      props: {
        style: tableHeaderStyles.fuel,
      }
    });

    tableHeaderRows[0].columns.push({
      element: <div className="centerText">Fuel Card Uploads (Gallons)</div>,
      props: {
        style: tableHeaderStyles.fuelCard,
      }
    });

    tableHeaderRows[0].columns.push({
      element: <div>Calculated Mileage ({ this.props.distanceUnitFilter.value })</div>,
      props: {
        style: tableHeaderStyles.saved,
      }
    });

    tableHeaderRows[0].columns.push({
      element: <div></div>,
      props: {
        style: tableHeaderStyles.checkbox,
      }
    });

    let tableBodyRows;

    if (this.state.loading) {
      tableBodyRows = [{
        key: 'loading',
        columns: [{
          element: <div><LoadingIcon /></div>,
          props: { className: 'table-body-column-style verticalAlignMiddle' },
        }],
      }];
    } else {
      const tableBodyRowData = this.state.tableBodyRowData;
      tableBodyRows = this.state.tableBodyRowData.map((iftaRoute, index) => {
        const prevDateEnd = index > 0 && moment(tableBodyRowData[index - 1].dateEnd.iso).tz(this.state.driverTimeZoneStr);
        const dateStartFormatted = moment(iftaRoute.dateStart.iso).tz(this.state.driverTimeZoneStr).format('ll LT');
        const dateEndFormatted = moment(iftaRoute.dateEnd.iso).tz(this.state.driverTimeZoneStr).format('ll LT');
        const stateProvince = iftaRoute.stateProvince && iftaRoute.stateProvince.toUpperCase();
        const totalVehicleKmStart = iftaRoute.totalVehicleKmStart;
        const totalVehicleKmEnd = iftaRoute.totalVehicleKmEnd;
        const totalVehicleKmStartValue = totalVehicleKmStart ? (this.props.distanceUnitFilter.value === 'km' ? totalVehicleKmStart.toFixed(0) : Helpers.convertDistance(totalVehicleKmStart, 'km', 'mi').toFixed(0)) : 0;
        const totalVehicleKmEndValue = totalVehicleKmEnd ? (this.props.distanceUnitFilter.value === 'km' ? totalVehicleKmEnd.toFixed(0) : Helpers.convertDistance(totalVehicleKmEnd, 'km', 'mi').toFixed(0)) : 0;
        const totalVehicleKmDiffValue = totalVehicleKmEndValue - totalVehicleKmStartValue;
        const vehicleLocationStart = iftaRoute.vehicleLocationStart;
        const vehicleLocationEnd = iftaRoute.vehicleLocationEnd;
        const savedVehicleKm = Math.round(iftaRoute.savedVehicleKm);
        const totalVehicleKmDiff = Math.round(iftaRoute.totalVehicleKmDiff);
        const iftaRouteDriverPeriods = iftaRoute.iftaRouteDriverPeriods;
        const fuelPurchases = iftaRoute.fuelPurchases;
        const distanceKm = Math.round(iftaRoute.distanceKm);
        const totalGallons = iftaRoute.totalGallons;

        let prevVehicleLocationEnd;
        let prevEndVehicleLocationStateProvince;
        let prevEndVehicleLocationAprxShortName;

        if (index > 0) {
          prevVehicleLocationEnd = this.state.tableBodyRowData[index - 1].vehicleLocationEnd;
          prevEndVehicleLocationStateProvince = prevVehicleLocationEnd.stateProvince;
          prevEndVehicleLocationAprxShortName = prevVehicleLocationEnd.aprxShortName;

          const prevVehicleLocationEndString = prevVehicleLocationEnd.locationDescriptionUS;
          const prevVehicleLocationEndBreakdown = getLocationDescriptionBreakdown(prevVehicleLocationEndString);

          if (!prevEndVehicleLocationStateProvince) prevEndVehicleLocationStateProvince = prevVehicleLocationEndBreakdown.stateProvince.code.toLowerCase();
          if (!prevEndVehicleLocationAprxShortName) prevEndVehicleLocationAprxShortName = prevVehicleLocationEndBreakdown.city;
        }

        let startVehicleLocationStateProvince = vehicleLocationStart.stateProvince;
        let endVehicleLocationStateProvince = vehicleLocationEnd.stateProvince;
        let startVehicleLocationAprxShortName = vehicleLocationStart.aprxShortName;
        let endVehicleLocationAprxShortName = vehicleLocationEnd.aprxShortName;

        const startVehicleLocationString = vehicleLocationStart.locationDescriptionUS;
        const endVehicleLocationString = vehicleLocationEnd.locationDescriptionUS;
        const startVehicleLocationBreakdown = getLocationDescriptionBreakdown(startVehicleLocationString);
        const endVehicleLocationBreakdown = getLocationDescriptionBreakdown(endVehicleLocationString);

        if (!startVehicleLocationStateProvince) startVehicleLocationStateProvince = startVehicleLocationBreakdown.stateProvince.code.toLowerCase();
        if (!endVehicleLocationStateProvince) endVehicleLocationStateProvince = endVehicleLocationBreakdown.stateProvince.code.toLowerCase();
        if (!startVehicleLocationAprxShortName) startVehicleLocationAprxShortName = startVehicleLocationBreakdown.city;
        if (!endVehicleLocationAprxShortName) endVehicleLocationAprxShortName = endVehicleLocationBreakdown.city;

        const vehicleRowObj = {
          key: iftaRoute.objectId,
          columns: [],
          // props: { expandableContent }
        };

        vehicleRowObj.columns = [];

        // Checkbox
        vehicleRowObj.columns.push({ element:
          <div>
            <Checkbox
              onCheck={(e, checked) => this.handleSelectIftaRouteArr(iftaRoute, checked)}
              checked={this.state.selectedIftaRouteMap[iftaRoute.objectId]}
              style={{ display: 'inline-block', width: '32%', fontSize: '.8em', fontWeight: '400' }}
            />
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });

        // Trip Start
        vehicleRowObj.columns.push({ element:
          <div>
            <b>
              <span className={!prevDateEnd || (moment(iftaRoute.dateStart.iso).tz(this.state.driverTimeZoneStr).isSame(prevDateEnd) || moment(iftaRoute.dateStart.iso).tz(this.state.driverTimeZoneStr).startOf('day').diff(prevDateEnd.startOf('day'), 'days') >= 1) ? '' : `errorText`}>
                { dateStartFormatted }
              </span>
            </b>
            <br />
            <span className={index > 0 && ((startVehicleLocationStateProvince === prevEndVehicleLocationStateProvince) ? '' : `errorText boldText`)}>
                {vehicleLocationStart && `${startVehicleLocationAprxShortName}, ${startVehicleLocationStateProvince.toUpperCase()}`}
              </span>
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });

        // Trip End
        vehicleRowObj.columns.push({ element:
          <div>
            <b> { dateEndFormatted } </b>
            <br />
            {`${endVehicleLocationAprxShortName}, ${endVehicleLocationStateProvince.toUpperCase()}`}
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });

        // State/Province
        vehicleRowObj.columns.push({ element:
          <div>
            { stateProvince }
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });

        // Drivers
        vehicleRowObj.columns.push({ element:
          <div>
            {iftaRouteDriverPeriods && iftaRouteDriverPeriods.map((driverPeriod) => {
              return (
                <div>
                  <div
                    className="inlineBlock clickable textHoverHighlight"
                    onClick={() => history.push({ pathname: 'driver', search: `driver=${driverPeriod.driver.objectId}&view=hosEvents&date=${moment(driverPeriod.dateStart.iso).format('DDMMYY')}` })}
                  >
                    <b>{ driverPeriod.driver && Helpers.toTitleCase(driverPeriod.driver.user_fullName) }</b>
                  </div>
                </div>
              );
            })}
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });

        // Fuel
        vehicleRowObj.columns.push({ element:
          <div>
            { fuelPurchases && fuelPurchases.map((fuelPurchase) => {
              return (
                <div>
                  <div className="inlineBlock">
                    { fuelPurchase && `${moment(fuelPurchase.timeMillis.iso).format('MMM D')}: `}
                    <b>{`${fuelPurchase.volumeUnits === 'l' ? Helpers.convertFuelUnit(fuelPurchase.volumePumped, 'l', 'gal').toFixed(2) : fuelPurchase.volumePumped.toFixed(2)} ${fuelPurchase.fuelType && `(${fuelPurchase.fuelType && fuelPurchase.fuelType.toUpperCase()})`}` }</b>
                    &nbsp;—&nbsp;
                    <b>{`${fuelPurchase.currency && fuelPurchase.currency.toUpperCase()}$${fuelPurchase.totalPaid} `}</b>
                  </div>
                </div>
              );
            })}
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });

        // Fuel Card Upload
        vehicleRowObj.columns.push({ element:
          <div>
            <b>
              {
                totalGallons === 0 || !totalGallons ? " " : Math.round(totalGallons)
              }
            </b>
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });

        // Mileages
        vehicleRowObj.columns.push({ element:
          <div>
            <b>
              { savedVehicleKm ? (this.props.distanceUnitFilter.value === 'km' ? savedVehicleKm.toFixed(0) : Helpers.convertDistance(savedVehicleKm, 'km', 'mi').toFixed(0))  : 0 }
            </b>
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });

        // Delete
        vehicleRowObj.columns.push({ element:
          <div>
            <span><DeleteXButton handleChoice={(confirmBool) => confirmBool && this.props.hideIftaRoute(iftaRoute.objectId)} /></span>
          </div>, props: { className: 'table-body-column-style verticalAlignMiddle' }
        });

        return vehicleRowObj;
      });
    }

    return (
      <div>
        { this.state.loading &&
          <LoadingOverlay />
        }
        <div className='vehicleRouteBreakdown-printable'>
          { (moment(this.props.dateEnd).isAfter(moment().subtract(11, 'days'))) &&
            <SBBlock
              title={`Switchboard processes IFTA vehicle mileages up to 10 days before today's date.`}
              header={`If you are generating reports for the previous month, make sure you generate them on the 11th of this month to get the most updated mileages.`}
              warning
            />
          }
          <SBTable
            hover
            tableHeaderRows={tableHeaderRows}
            tableBodyRows={tableBodyRows}
            isWaiting={this.state.loading}
          />
        </div>
        <MDBModal
          centered
          isOpen={this.state.showMapPopup}
          toggle={() => this.toggleMap(false)}
        >
          <MDBModalBody>
            <div style={{ height: '30em', width: '30em' }}>
              { this.state.showMapPopup &&
                <MapPopup
                  { ...this.state.mapData}
                />
              }
            </div>
          </MDBModalBody>
        </MDBModal>
      </div>
    );
  }
};

VehicleRouteBreakdown.propTypes = {
  vehicleBreakdownFilter: PropTypes.object.isRequired,
  distanceUnitFilter: PropTypes.object.isRequired,
  iftaRouteArr: PropTypes.array.isRequired,
  dateStart: PropTypes.object.isRequired,
  dateEnd: PropTypes.object.isRequired,
  toggleMap: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
};

export default VehicleRouteBreakdown;
