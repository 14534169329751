// API
import { updateUser } from 'api/Setters';

// CSAPI
import {
  copyQuery, count, createQuery, createQueryOr, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, addRecord, destroyRecord, getRecordByObjectId,
} from 'sb-csapi/dist/AAPI';

async function updateUserType(userObjectId, selectedUserTypes = []) {
  if (!userObjectId) throw new Error('User object id is required!');

  try {
    await updateUser(userObjectId, 'userType', selectedUserTypes);
  } catch (err) {
    throw new Error(err);
  }
}

export {
  updateUserType,
};
