import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// components
import ProgressSpinner from 'sbCore/ProgressSpinner/ProgressSpinner';
import Button from 'sbCore/Button/Button';

import IFTAVehicleDayBreakdownCard from 'components/IFTA/IFTAVehicleDayView/IFTAVehicleDayBreakdownCard/IFTAVehicleDayBreakdownCard';
import IFTAVehicleDayDetailsCard from 'components/IFTA/IFTAVehicleDayView/IFTAVehicleDayDetailsCard/IFTAVehicleDayDetailsCard';

// styles
import './style.scss';

/**
 * @param {Object} vehicleIFTAData - The selected vehicle's IFTA data
 * @param {String} dayData - The IFTA data for the vehicle and specific day
 * @param {String} [lengthUnit] - From enums LengthUnit - KM, MI
 * @param {Function} [onBack] - What to do when Back button is clicked
 */
function IFTAVehicleDayView({ ...props }) {

  const [isLoading, setIsLoading] = useState(false);
  const [dayData, setDayData] = useState(undefined); // data for a specific day
  const [tripData, setTripData] = useState(undefined); // data for a specific trip in a given day

  useEffect(() => {
    // Format the given props.dayData so there is consistent data/formatting across child components
    let didCancel = false;
    async function init() {
      setIsLoading(true);

      const { dayData } = props;

      const _dayData = {
        dateStart: dayData.dateStart,
        iftaRouteObjs: dayData.iftaRouteObjs,
        driverObjs: dayData.driverObjs,
      };

      if (!didCancel) {
        setDayData(_dayData);
        setIsLoading(false);
      }
    }

    init();

    return () => { didCancel = true; };
  }, [props.dayData]);

  /**
   * Functions
   */
  function onSelectTrip(iftaRouteObj, vehicleLocations) {
    setTripData({ iftaRouteObj, vehicleLocations });
  }

  let className = 'ifta-vehicle-day-view';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <div className="mb-3">
        {isLoading && (
          <ProgressSpinner className="ml-5" style={{ width: '2em' }} strokeWidth={5} />
        )}

        {!isLoading && (
          <div className="flex justify-content-between">
            <div>
              {props.onBack && (
                <Button
                  text
                  label="GO BACK"
                  severity="secondary"
                  onClick={() => props.onBack()}
                  sbVariant="short"
                />
              )}
            </div>

            <div className="flex align-items-center" />
          </div>
        )}
      </div>

      <div className="ifta-day-details-container">
        <IFTAVehicleDayBreakdownCard
          className="ifta-day-section-card"
          dayData={dayData}
          lengthUnit={props.lengthUnit}
          onSelectTrip={(iftaRouteObj, vehicleLocations) => onSelectTrip(iftaRouteObj, vehicleLocations)}
        />
        <IFTAVehicleDayDetailsCard
          className="ifta-day-section-card"
          dayData={dayData}
          tripData={tripData}
          lengthUnit={props.lengthUnit}
        />
      </div>
    </div>
  );
}

export default IFTAVehicleDayView;
