import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment-timezone';
import { MDBBtn } from 'mdbreact'

// API
import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';

// Components
import Title from 'components/LayoutTitle/view/Title'
import FilterForm from 'components/FilterForm/container/FilterForm.new'
import GeofenceEventsTable from './GeofenceEventsTable'

// Context
import StoreContext from 'contexts/StoreContext';


class GeofenceEvents extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: [{
        attribute: "dateTime",
        name: "dateTime",
        queryType: "date",
        type: "date",
        value: moment(),
      }],
      sort: { unitId: 'descending' },
      tableHeight: '50vh',
      isLoading: false,
      isLazyLoading: false,
      hasLoadedAll: false,
      queryPage: 0,
      displayFilterForm: true,
      geofenceEvents: [],
    }
    this.state.filterFormFields = [
      {
        attrName: 'dateTime',
        fullName: 'Date',
        queryType: 'date',
        dateQueryType: 'onDate',
        placeholder: '.....',
        type: 'date',
        value: moment()
      },
      {
        attrName: 'vehicleUnitId',
        fullName: 'Vehicle',
        queryType: 'startsWith',
        placeholder: '.....',
      },
      {
        attrName: 'geofence_name',
        fullName: 'Location',
        queryType: 'startsWith',
        placeholder: '.....',
      },
      // { attrName: 'type', fullName: 'Type', placeholder: '.....', type: 'text' },
    ];
    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.getGeofenceEvents = this.getGeofenceEvents.bind(this);
    this.downloadEvents = this.downloadEvents.bind(this);
  }

  componentDidMount() {
    this.getGeofenceEvents();
  }

  componentWillUnmount() {
  }

  render() {
    const {
      isLoading,
      isLazyLoading,
      tableHeight,
      filterFormFields,
      filter
    } = this.state
    return (
      <div>
        <Title title='Location Reports'>
          <FilterForm
            className='floatRight'
            handleFilter={this.filter}
            clearFilter={this.clearFilter}
            fields={filterFormFields}
            submitOnSelect
          />
        </Title>
        <div className='mb-4'>
          To record more location events, create new geofences on the Map
          <div className="floatRight">
            <MDBBtn
              className={'buttonDefault'}
              onClick={this.downloadEvents}
            >Download</MDBBtn>
          </div>
        </div>
        <GeofenceEventsTable
          geofenceEvents={this.state.geofenceEvents}
          handleLazyLoad={this.getVehiclesAndEDCs}
          isLoading={isLoading}
          isLazyLoading={isLazyLoading}
          filter={filter}
        />
      </div>
    )
  }

  getGeofenceEvents = () => {
    const {
      queryPage,
      filter,
      isLoading,
      isLazyLoading,
      hasLoadedAll
    } = this.state;
    const self = this;

    if (!isLoading && !isLazyLoading && !hasLoadedAll) {
      let loadingState = { ...this.state, isLoading: true }
      if (queryPage > 0) {
        loadingState = { ...this.state, isLazyLoading: true }
      }
      this.setState(loadingState, () => {
        Getters.queryCompanyObjects('GeofenceEvent', queryPage, 100, filter, { dateTime: 'descending' }).then(
          (geofenceEvents) => {
            const newState = {
              ...loadingState,
              isLoading: false,
              isLazyLoading: false,
              queryPage: queryPage + 1,
              geofenceEvents: geofenceEvents
            }
            if (geofenceEvents.length === 0) {
              newState.hasLoadedAll = true // we've queried everything, so stop
            }
            self.setState(newState);
          }
        );
      });
    }
  }

  filter(filterArr) {
    const newState = {
      ...this.state,
      isLoading: false,
      filter: filterArr,
      queryPage: 0,
      hasLoadedAll: false
    }
    this.setState(newState, () => {
      this.getGeofenceEvents()
    })
  }

  clearFilter() {
    const newState = {
      ...this.state,
      filter: [],
      isLoading: false,
      queryPage: 0,
      hasLoadedAll: false
    }
    this.setState(newState, () => {
      this.getGeofenceEvents(true)
    })
  }

  downloadEvents() {
    let csvString = 'date,vehicle,location,enter/exit\n';
    for (let i = 0; i < this.state.geofenceEvents.length; i++) {
      const geofenceEvent = this.state.geofenceEvents[i];
      csvString += `${moment(geofenceEvent.get('dateTime')).format('h:mm a')},`;
      csvString += `${geofenceEvent.get('vehicleUnitId') || geofenceEvent.get('trailerUnitId')},`;
      csvString += `${geofenceEvent.get('geofence_name')},`;
      csvString += `${geofenceEvent.get('geofenceEventStateInt') === 1 ? 'Enter' : 'Exit'}\n`;
    }
    Helpers.createCsvFile('Switchboard - LocationRecords', csvString, true);
  }
}

GeofenceEvents.propTypes = {
  Company: PropTypes.object,
  isLoading: PropTypes.bool,
  isLazyLoading: PropTypes.bool,
  height: PropTypes.any
}

const mapStateToProps = state => {
  const { Vehicle } = state
  return {
    Vehicle
  }
}

export default connect(mapStateToProps, null, null, { context: StoreContext })(GeofenceEvents);
