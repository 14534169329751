/** @module Translate */

/**
 * @memberof module:Translate
 * @description Abstracted function for wrapping text to be translated
 * @param {String} text Text to translate
 * @returns Translated string if translation exists to current language, else `text`
 */
function t(text) {
  return window.Localize ? window.Localize.translate(text) : text;
}

export {
  t,
};
