import React from 'react';
import PropTypes from 'prop-types';

import { MDBBadge } from 'mdbreact';

import { ELDStatusTypes, ELDStatusTypeDescriptionsCondensed } from 'enums/ELDStatus';

// CSS
import './style.scss';

class DutyStatusBadge extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { containerClassName, className, eldStatusInt } = this.props;

    let _containerClassName = 'duty-status-badge-container';
    if (containerClassName) _containerClassName += ` ${containerClassName}`;

    let _className = 'duty-status-badge';

    // figure out the value of StatusType{} from the given statusType
    let matchingStatusTypeObject = {};
    Object.keys(ELDStatusTypes).find(key => {
      const statusType = ELDStatusTypes[key];
      const isMatchingStatus = statusType === eldStatusInt;

      if (isMatchingStatus) {
        // find the matching background color className (ex. SOME_VALUE -> some-value -> bg-ds-some-value)
        const colorClassKeyExpression = key.replace(/_/g, '-').toLowerCase();
        _className += ` bg-ds-${colorClassKeyExpression}`;

        matchingStatusTypeObject.status = statusType;
        matchingStatusTypeObject.description = ELDStatusTypeDescriptionsCondensed[key];
      }
      return isMatchingStatus;
    });

    // add any final classNames (we do the assign here so the user can overwrite our own properties on className)
    if (className) _className += ` ${className}`;

    return (
      <div className={_containerClassName}>
        { (matchingStatusTypeObject.status !== undefined) &&
          <React.Fragment>
            <MDBBadge className={_className}>{ matchingStatusTypeObject.description }</MDBBadge>
          </React.Fragment>
        }
      </div>
    );
  }
}

DutyStatusBadge.defaultProps =  {
};

DutyStatusBadge.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  eldStatusInt: PropTypes.number.isRequired, // ex. 0, or preferrably StatusType.CONST_NAME
};

export default DutyStatusBadge;