import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Popup, Marker } from 'react-mapbox-gl';
import { Panel } from 'react-bootstrap';
import history from "../../../../sbHistory";
import { MDBBtn, MDBIcon } from 'mdbreact';

import { CountryCodeLocationDescriptionTypes } from 'enums/VehicleLocation';

// API
import { getPDispatcherPropertyFromState } from 'api/Getters';
import { isVehicleDriverDriving } from 'api/ELD';
import * as Helpers from 'api/Helpers';
import * as GPS from 'api/GPS/GPS';
import { getGPSDirection } from 'api/GPS/GPS.js';
import { getAttribute } from 'api/Parse';

// Components
import DutyStatusLabel from 'components/DutyStatusLabel/view/DutyStatusLabel';

// CSS
import styles from './ComponentStyles.module.scss';

function getSpeedString(speedKm) {
  const distanceUnit = getPDispatcherPropertyFromState('distanceUnit');
  if (distanceUnit === 'km') {
    return `${speedKm.toFixed(0)} km/h`;
  }
  return `${parseFloat(Helpers.convertDistance(speedKm, 'km', 'mi', true).toFixed(0))} mi/h`;
}

function isTrailer(object) {
  return object.className === 'Trailer';
}

function goToTemperatureReport(trailerId) {
  history.push({ pathname: 'equipment', search: 'view=trailers&t=temperature&id=' + trailerId });
}

function getTempCelsius(assetObj) {
  if (
    assetObj.get('tc_devices') &&
    assetObj.get('tc_devices').get('tc_positions') &&
    assetObj.get('tc_devices').get('tc_positions').get('tempCelsius')
  ) {
    return assetObj.get('tc_devices').get('tc_positions').get('tempCelsius');
  } else if (
    assetObj.get('tc_devices').get('assetLocation') &&
    assetObj.get('tc_devices').get('assetLocation').get('tempCelsius')
  ) {
    return assetObj.get('tc_devices').get('assetLocation').get('tempCelsius');
  }
}

function VehiclePopup(props) {
  const { vehicle, address } = props;
  const vehicleLocation = vehicle && vehicle.get('vehicleLocation');
  const countryCode = ((vehicleLocation && vehicleLocation.get('countryCode')) || '').toUpperCase();
  const eldEvent = vehicleLocation && vehicleLocation.get('eldEvent');
  const drivers = vehicleLocation && vehicleLocation.get('drivers');

  let tempCelsius = null;
  if (isTrailer(props.vehicle || props.asset)) {
    tempCelsius = getTempCelsius(props.vehicle || props.asset);
  }
  let locationDescription = props.formattedAddress || address;
  if (countryCode === 'US') {
    if (getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.US, true)) {
      const locationDescriptionUSArr = getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.US, true).split(' ');
      let formattedLocationDescription;
      const locationDescriptionStateProvince = locationDescriptionUSArr[2];
      const trueStateProvince = getAttribute(vehicleLocation, 'stateProvince', true);
      if (trueStateProvince !== locationDescriptionStateProvince.toLowerCase()) {
        formattedLocationDescription = `${trueStateProvince.toUpperCase()}, ${countryCode}`;
      } else {
        const locationDescriptionCity = locationDescriptionUSArr.slice(3, locationDescriptionUSArr.length).join(' ');
        formattedLocationDescription = `${locationDescriptionCity}, ${locationDescriptionStateProvince}, ${countryCode}`;
      }
      locationDescription = props.formattedAddress || formattedLocationDescription;
    }
  } else if (countryCode === 'CA') {
    if (getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.CA, true)) {
      const locationDescriptionCAArr = getAttribute(vehicleLocation, CountryCodeLocationDescriptionTypes.CA, true).split(' ');
      let formattedLocationDescription;
      const locationDescriptionStateProvince = locationDescriptionCAArr[2];
      const trueStateProvince = getAttribute(vehicleLocation, 'stateProvince', true);
      if (trueStateProvince !== locationDescriptionStateProvince.toLowerCase()) {
        formattedLocationDescription = `${trueStateProvince.toUpperCase()}, ${countryCode}`;
      } else {
        const locationDescriptionCity = locationDescriptionCAArr.slice(3, locationDescriptionCAArr.length).join(' ');
        formattedLocationDescription = `${locationDescriptionCity}, ${locationDescriptionStateProvince}, ${countryCode}`;
      }
      locationDescription = props.formattedAddress || formattedLocationDescription;
    } else {
      locationDescription = props.formattedAddress;
    }
  }

  return (
    // Temporary marker to replace the popup before
    <Marker
      anchor="bottom"
      offset={{ bottom: [0, -10] }}
      coordinates={props.coordinates}
    >
      <span className={`material-icons text-6xl ${styles.vehicleMarker}`}>location_on</span>
    </Marker>
    // <Popup
    //   anchor="bottom"
    //   offset={{ bottom: [0, -20] }}
    //   coordinates={props.coordinates}
    //   className={styles.popup}
    //   key={`${getAttribute(props.vehicle || props.asset, 'objectId', true)}-popup`}
    // >
    //   {props.isMobile && (
    //     <React.Fragment>
    //       <div
    //         className={styles.hideButton}
    //         onClick={(event) => {
    //           event.stopPropagation();
    //           props.unselectDriver(false);
    //         }}
    //         onKeyPress={(event) => {
    //           if (event.nativeEvent.key === ' ') {
    //             event.stopPropagation();
    //             props.unselectDriver(false);
    //           }
    //         }}
    //         role="button"
    //         tabIndex={0}
    //       >
    //         <MDBIcon icon="minus-circle" />
    //       </div>
    //       <div
    //         className={styles.deleteButton}
    //         onClick={(event) => {
    //           event.stopPropagation();
    //           props.unselectDriver();
    //         }}
    //         onKeyPress={(event) => {
    //           if (event.nativeEvent.key === ' ') {
    //             event.stopPropagation();
    //             props.unselectDriver();
    //           }
    //         }}
    //         role="button"
    //         tabIndex={0}
    //       >
    //         <MDBIcon icon="times-circle" />
    //       </div>
    //     </React.Fragment>
    //   )}
    //   <div className={styles.vehicle}>
    //     <div className={styles.unitId}>
    //       <b>{props.unitId || props.vehicle.get('unitId')}</b>
    //     </div>

    //     {drivers && (
    //       <>
    //         {drivers.map((driverParseObj, index) => {
    //           if (index === 0) {
    //             return (
    //               <div className={styles.drivers}>
    //                 <a href={`driver?driver=${driverParseObj.id}`}>
    //                   <div
    //                     className={styles.activeDriver}
    //                     onClick={(e) => {
    //                       e.preventDefault();
    //                       history.push({ pathname: 'driver', search: `driver=${driverParseObj.id}` });
    //                     }}
    //                   >
    //                     {Helpers.toTitleCase(driverParseObj.get('user_fullName'))}
    //                   </div>
    //                 </a>
    //               </div>
    //             )
    //           }
    //           return (
    //             <div className={styles.drivers}>
    //               <a href={`driver?driver=${driverParseObj.id}`}>
    //                 <div
    //                   className={styles.coDriver}
    //                   onClick={(e) => {
    //                     e.preventDefault();
    //                     history.push({ pathname: 'driver', search: `driver=${driverParseObj.id}` });
    //                   }}
    //                 >
    //                   {Helpers.toTitleCase(driverParseObj.get('user_fullName'))}
    //                 </div>
    //               </a>
    //             </div>
    //           )
    //         })}
    //       </>
    //     )}

    //     <div className="inline">
    //       {(props.speedKm || ((props.vehicle.get('vehicleLocation') && props.vehicle.get('vehicleLocation').get('speedKm')) !== undefined)) && (
    //         <div className="inlineBlock">
    //           <span><span className="glyphicon glyphicon-dashboard" /> <i className="material-icons">speed</i><kbd>{getSpeedString(props.speedKm || (props.vehicle.get('vehicleLocation') && props.vehicle.get('vehicleLocation').get('speedKm')))}</kbd></span>
    //         </div>
    //       )}
    //       &nbsp;
    //       {(props.followBearing !== undefined && props.vehicle.get('vehicleLocation') && props.vehicle.get('vehicleLocation').get('gpsHeading') !== undefined) && (
    //         <div className="inlineBlock">
    //           <span><span className="glyphicon glyphicon-dashboard" /> <i className="material-icons">near_me</i><kbd>{getGPSDirection(props.followBearing || props.vehicle.get('vehicleLocation') && props.vehicle.get('vehicleLocation').get('gpsHeading')).direction}</kbd></span>
    //         </div>
    //       )}
    //       &nbsp;
    //       {tempCelsius !== null && tempCelsius !== undefined && (
    //         <div className="inlineBlock">
    //           <span><span className="glyphicon glyphicon-dashboard" /> <i className="material-icons">ac_unit</i><kbd>{`${Math.round(Helpers.convertTemperature(tempCelsius, 'c', 'f'))}°F`}</kbd></span>
    //         </div>
    //       )}

    //       {props.vehicle.get('drivers') && props.vehicle.get('drivers')[0] && props.vehicle.get('drivers')[0].get('eldStatusInt') && (
    //         <div className="inlineBlock" style={{ verticalAlign: 'middle', float: 'right' }}>
    //           <div className={styles.dutyStatusLabel}>
    //             <DutyStatusLabel eldStatusInt={props.vehicle.get('drivers')[0].get('eldStatusInt')} />
    //           </div>
    //         </div>
    //       )}
    //     </div>


    //     {locationDescription && (
    //       <div>
    //         <i>{locationDescription}</i>
    //       </div>
    //     )}

    //     <div className={styles.dateTime}>
    //       <div><b>{moment(props.dateTime || (props.vehicle.get('vehicleLocation') && props.vehicle.get('vehicleLocation').get('dateTime'))).format('LL')}</b></div>
    //       <div>{moment(props.dateTime || (props.vehicle.get('vehicleLocation') && props.vehicle.get('vehicleLocation').get('dateTime'))).format('LT')}</div>
    //     </div>

    //     {tempCelsius !== null && tempCelsius !== undefined && (
    //       <MDBBtn size="sm" color="info" style={{ padding: '0.5em' }} className="translate-me" onClick={() => goToTemperatureReport(props.vehicle.id)}>
    //         Temperature Report
    //       </MDBBtn>
    //     )}

    //     <div>
    //       {
    //         (isTrailer(props.vehicle) ?
    //           GPS.isIdling(props.vehicle.get('vehicleLocation') && props.vehicle.get('vehicleLocation').get('ignition'), props.speedKm || ((props.vehicle.get('vehicleLocation') && props.vehicle.get('vehicleLocation').get('speedKm'))))
    //           :
    //           GPS.isIdling(isVehicleDriverDriving(props.vehicle), props.speedKm || (props.vehicle.get('vehicleLocation') && props.vehicle.get('vehicleLocation').get('speedKm'))))
    //         && (
    //           <div className={styles.dateTime}>
    //             <div className={styles.idling}>
    //               <b className="translate-me">Is currently idling</b>
    //             </div>
    //             {props.lastActiveDate && (
    //               <div className={`${styles.idling} translate-me`}>
    //                 Last Active: <var>{moment(props.lastActiveDate).format('LLL')}</var>
    //               </div>
    //             )}
    //           </div>
    //         )}
    //     </div>

    //   </div>
    // </Popup>
  );
}

VehiclePopup.propTypes = {
  coordinates: PropTypes.array.isRequired,
  vehicle: PropTypes.object.isRequired,
  address: PropTypes.string,
  showDetails: PropTypes.bool.isRequired,
  hidePopup: PropTypes.func.isRequired,
  unselectDriver: PropTypes.func,
  isMobile: PropTypes.bool,
  speedKm: PropTypes.number,
  followBearing: PropTypes.number,
  dateTime: PropTypes.object,
  unitId: PropTypes.string,
};

export default VehiclePopup;
