import {
  copyQuery, count, createQuery, createQueryOr, findRecords,
  getAttribute, getCurrentUserSessionToken, getCurrentUserCompanyObjectId, includePointers, setQueryRestriction,
  setReturnSelectAttributes, sortQuery, updateRecord, getCurrentUser, addRecord,
} from 'sb-csapi/dist/AAPI';

import { QueryRestriction, QuerySortOrder } from 'sb-csapi/dist/enums/Query';

import Sort from 'sb-csapi/dist/sbObjects/Sort';
import Filter from 'sb-csapi/dist/sbObjects/Filter';

/**
 * @category Dispatch
 * @subcategory Documents
 *
 * @description Get a list of Dispatch documents for a given dispatch job
 *
 * @param {object} options - See example
 * @param {string} dispatchJobObjectId - The specific dispatch job that the document belongs to
 * @param {array} filters - array of Filter objects
 * @param {object} sortBy - Sort object
 * @param {array} includedPointers - Included pointers
 * @param {array} selectedAttributes - Select attributes to return
 * @param {int} page - The current page for pagination
 * @param {int} limit - The limit of records obtained per pagination
 * @param {bool} queryAll - Get all records, ignoring pages/limits
 *
 * @returns { object } - { dispatchDocuments: [DispatchDocument, ...] }
 */
async function getDispatchDocuments(options = { sessionToken: getCurrentUserSessionToken() }, filters = [], sortBy = new Sort(QuerySortOrder.DESCENDING, 'createdAt'), includedPointers = [], selectedAttributes = [], page = 0, limit = 20, queryAll) {
  const dispatchDocumentQuery = createQuery('DispatchDocument');
  const _filters = [...filters];

  // set universal filters
  // _filters.push(new Filter(QueryRestriction.NOT_EQUAL_TO, 'isHidden', true)); // get all non-deleted organizations

  _filters.map(filter => setQueryRestriction(dispatchDocumentQuery, filter.queryRestriction, filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  const dispatchDocumentCountQuery = copyQuery(dispatchDocumentQuery);

  // sort
  sortQuery(dispatchDocumentQuery, sortBy.order, sortBy.attribute);

  if (includedPointers.length > 0) includePointers(dispatchDocumentQuery, includedPointers);
  if (selectedAttributes.length > 0) setReturnSelectAttributes(dispatchDocumentQuery, selectedAttributes);

  if (!queryAll) {
    setQueryRestriction(dispatchDocumentQuery, QueryRestriction.LIMIT, undefined, limit);
    setQueryRestriction(dispatchDocumentQuery, QueryRestriction.SKIP, undefined, page * limit);
  }

  // now get the count and the documents
  const promises = [count(options, dispatchDocumentCountQuery), findRecords(options, dispatchDocumentQuery, false, queryAll)];

  try {
    const [totalDispatchDocumentsCount, dispatchDocuments] = await Promise.all(promises);
    return { totalDispatchDocumentsCount, dispatchDocuments };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @category Dispatch
 * @subcategory Documents
 *
 * @description Add new dispatch document record
 * @param {object} keyValueObj - The select fields we wish to save
 *
 * @returns {DispatchDocument} - The added DispatchDocument
 */
async function addDispatchDocument(keyValueObj) {
  try {
    const dispatchDocument = await addRecord({ sessionToken: getCurrentUserSessionToken() }, 'DispatchDocument', keyValueObj, getCurrentUserCompanyObjectId());
    return dispatchDocument;
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @category Dispatch
 * @subcategory Documents
 *
 * @description Retrieve the latest DispatchDocument of a certain type
 * @param {object} options - Currently contains the current user session token
 * @param {DispatchJob} dispatchJob - The dispatch job that the document belongs to
 * @param {Number} dispatchDocumentTypeInt - The document type number
 *
 * @returns {DispatchDocument} - The latest DispatchDocument
 */
async function getLatestDispatchDocument(options = { sessionToken: getCurrentUserSessionToken() }, dispatchJob, dispatchDocumentTypeInt) {
  if (!dispatchJob || !dispatchDocumentTypeInt) throw new Error('Cannot create record: Requires dispatchJob and dispatchDocumentTypeInt argument');

  const dispatchDocumentQuery = createQuery('DispatchDocument');
  setQueryRestriction(dispatchDocumentQuery, QueryRestriction.EQUAL_TO, 'dispatchJob', dispatchJob);
  setQueryRestriction(dispatchDocumentQuery, QueryRestriction.EQUAL_TO, 'type', dispatchDocumentTypeInt);
  sortQuery(dispatchDocumentQuery, QuerySortOrder.DESCENDING, 'createdAt');

  try {
    const dispatchDocument = await findRecords(options, dispatchDocumentQuery, true, false); // Return the first result
    return dispatchDocument;
  } catch (err) {
    throw new Error(err);
  }
}

export {
  getDispatchDocuments,
  addDispatchDocument,
  getLatestDispatchDocument,
};
