import React from 'react';
import uniqid from 'uniqid';

// sbCore
import Badge from 'sbCore/Badge/Badge';
import Tooltip from 'sbCore/Tooltip/Tooltip';

// Enums
import { AlertStatus } from 'sb-csapi/dist/enums/Notification/AlertStatus';

// CSS
import './style.scss';

/**
 * @description Creates an alert badge with a given tooltip information
 * 
 * @param {int} alertStatusInt - an AlertStatus enum value
 * @param {string} alertDescription - description displayed in tooltip when user hovers the alert icon
 * @param {string} [alertIconClassName] - icon of the alert badge ex. pi pi-exclamation-circle
 * @param {string} [className] - styles to apply to the badge
 * @param {string} [containerClassName] - styles to apply to the alert container
 * @returns
 * 
 * @example
 * <Alert alertStatusInt={AlertStatus.ERROR} alertDescription="Error" />
 */

function Alert(props) {
  const alertId = uniqid(); // Create this to uniquely identify a given alert (used with tooltip)

  let alertIconClassName = 'alert-icon';

  if (props.alertIconClassName) {
    alertIconClassName += ` ${props.alertIconClassName}`;
  } else {
    alertIconClassName += ` pi pi-exclamation-circle`;
  }

  let containerClassName = 'alert-badge-container';
  if (props.containerClassName) containerClassName += ` ${props.containerClassName}`;

  let className = `alert-badge flex align-items-center justify-content-center alert-${alertId}`;
  if (props.className) className += ` ${props.className}`;

  // figure out the key form the given alertInt
  Object.keys(AlertStatus).find(key => {
    const alertStatus = AlertStatus[key];
    const isMatchingAlert = alertStatus === props.alertStatusInt;

    if (isMatchingAlert) {
      // set the key to lowercase and use that key to set the badge color 
      const colorClassName = key.toLowerCase();
      className += ` bg-${colorClassName}`;
    }
  })

  const alertIconElement = (
    <i className={alertIconClassName}></i>
  )

  return (
    <React.Fragment>
      {props.alertDescription && <Tooltip target={`.alert-${alertId}`} content={props.alertDescription} />}
      <div className={containerClassName}>
        <Badge className={className} value={alertIconElement} />
      </div>
    </React.Fragment>
  )
}

export default Alert;