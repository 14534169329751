import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';
import moment from 'moment-timezone';

// api
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { isAOBRDEnabledByCount } from 'sb-csapi/dist/api/ELDEvent/ELDEvent';

import { isWithinAutogeneratedBypassDate, isDrivingEditViolation } from 'api/ELD';

// components
import Message from 'sbCore/Message/Message';

// styles
import './style.scss';

/**
 * @description Handles the display of all information / errors / warnings about the edits
 *
 * @param {ELDDailyCertification} eldDailyCertification - The daily cert of the logs
 * @param {Driver} driver - The driver of whoms logs we are validating. Should be of eldDailyCertification derived in the parent
 * @param {String} timezoneOffsetFromUTC - The timezone to use for validation. Should be of eldDailyCertification/driver derived in the parent
 * @param {Array} editObjects - The array of edits objects to be made and validated against
 * @param {Array} eldEvents - The initial array of eldEvents prior to preview
 * @param {Array} eldEventsForPreview - The array of eldEvents, including additions/subtractions, to be previewed and validated
 * @param {ELDEvent} [eldEvent] - Determines that this is the only event we are validating out of n-events. Editing single events have a different flow
 * @param {Function} [onValidationCompleted] - Callback to tell the parent if errors exist, and if so, also the list of generated errors
 * @param {Boolean} [isELDOverride] - If this edit is meant to override logs
 */
function ELDEditModalMessageBody({ ...props }) {

  const [isFetched, setIsFetched] = useState(false);
  const [isDriverWithinAutogeneratedBypassDate, setIsDriverWithinAutogeneratedBypassDate] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showClearedMessage, setShowClearedMessage] = useState(false);

  // Error enum to define what types of errors are possible and to remove duplicate errors from appearing
  // Keep in mind these can be customer-facing, so they need to make sense in layman terms
  const Error = Object.freeze({
    NEW_EDIT_INITIALIZED: 'Begin by entering the Start Time, End time, and Duty Status of the edit. Your proposed changes will be shown on the graph in Red',
    NEW_EDIT_INITIALIZED_SINGLE_EVENT_EDIT: 'Begin by entering the Duty Status you want this event to be. Your proposed changes will be shown on the graph in Red',
    ELDDAILYCERTIFICATION_REQUIRED: 'Cannot edit a day with no activity - The driver or vehicle needs to be active this day', // (it can be possible, we don't allow it yet)
    FMCSA_REASON_REQUIRED: 'Missing FMCSA Required Edit Reason - Please provide an edit reason in the input before submitting',
    DUTY_STATUS_REQUIRED: 'Missing Duty Status - Please select a duty status',
    START_TIME_END_TIME_REQUIRED: 'Missing Start and End Time - Please begin by providing a Start and End time for this edit',
    START_TIME_AFTER_END_TIME: 'Incompatible Times - The chosen Start Time must be before End Time',
    OUTSIDE_24_HOUR_PERIOD: 'Incompatible Edit Period - The chosen Start and End Times should be within the 24 Hour Log Period',
    OUTSIDE_CURRENT_DAY: 'Incompatible Edit Period - The chosen Start and End Times should be within the driver\'s current log',
    AGDT_VIOLATION: 'Incompatible Driving Time - Rules indicate AutoGenerated Driving Time cannot be edited',
  });

  /**
   * @description Create a human-friendly error to associate an edit with an issue
   * @param {Any} editNumber - The string or number of the edit in question
   * @param {Error} error - Error enum
   *
   * @returns {String}
   * @example
   * Example output:
   *  {
   *    error:  "Edit 2: Missing Duty Status - Please select a duty status",
   *    errorJSX: <div>Edit 2:</div> Missing Duty Status - Please select a duty status
   *  }
   */
  function createErrorMessageObject(editNumber, error) {
    const errorObject = {
      error,
      errorJSX: <div>{error}</div>,
    };

    // if no editNumber, just return the error string
    if (editNumber === undefined) return errorObject;

    errorObject.error = `Edit ${editNumber}: ${error}`;
    errorObject.errorJSX = (
      <div className="error-message">
        <div className="error-message-title">Edit {editNumber}:</div>
        {error}
      </div>
    );
    return errorObject;
  }

  /**
   * @description Create a human-friendly OK message to associate an edit with 0 edit issues
   *              output Format should copy that of createErrorMessageObject
   */
  function createClearedMessage() {
    return (
      <div className="error-message">
        <div className="error-message-title" />
        All good to go! Click the &quot;<b>Add Another Edit</b>&quot; button to add more edits to this log, or &quot;<b>Continue</b>&quot; to begin submitting your changes
      </div>
    );
  }

  function validateProposedEdits() {
    const { eldDailyCertification, eldEvent, timezoneOffsetFromUTC, editObjects, isELDOverride } = props;
    const _errors = [];
    const validEditObjectLocalIdMap = {}; // keeps track of which edit object doesnt have errors
    setShowClearedMessage(false);

    // Start by telling the parent to assume there are errors, since we don't want to say
    // edits are error-free prior to having actually validated it
    if (props.onValidationCompleted) {
      props.onValidationCompleted(true, errors, validEditObjectLocalIdMap);

      if (!eldDailyCertification && !isELDOverride) return props.onValidationCompleted(true, createErrorMessageObject(undefined, Error.ELDDAILYCERTIFICATION_REQUIRED), validEditObjectLocalIdMap);
      if (!editObjects) return props.onValidationCompleted(true, errors, validEditObjectLocalIdMap);
      if (editObjects.length === 0) return props.onValidationCompleted(true, errors, validEditObjectLocalIdMap);
      if (!timezoneOffsetFromUTC) return props.onValidationCompleted(true, errors, validEditObjectLocalIdMap);
    }

    const currentDriverDateTimeMs = moment().tz(timezoneOffsetFromUTC).valueOf(); // the driver's current date/time

    // daily cert values
    const startTimeUTC = moment(getAttribute(eldDailyCertification, 'startTimeUTC')).tz(timezoneOffsetFromUTC);
    const endTimeUTC = moment(startTimeUTC).tz(timezoneOffsetFromUTC).add(1, 'day');
    endTimeUTC.subtract(1, 'millisecond'); // this is what endTimeUTC should be. different from eldDailyCertification.endTimeUTC
    const startTimeUTCMs = moment(startTimeUTC).tz(timezoneOffsetFromUTC).valueOf();
    const endTimeUTCMs = moment(endTimeUTC).tz(timezoneOffsetFromUTC).valueOf();

    // check each edit object for veritification. note the errors are stacked in-order
    for (let i = 0; i < editObjects.length; i++) {
      const editObject = editObjects[i];
      const hasDutyStatus = (editObject.eldEventTypeCodeInt !== undefined);

      // if they just began their edit, there exists no start or end times or duty status, so let the user
      // know we should start with that before proceeding with the rest of the validation
      const isEditMissingStartOrEndDateTime = !editObject.startDateTime || !editObject.endDateTime;

      if ((isEditMissingStartOrEndDateTime || !hasDutyStatus) && (editObjects.length === 1)) {
        if (!props.eldEvent) {
          _errors.push(createErrorMessageObject(i + 1, Error.NEW_EDIT_INITIALIZED));
        } else {
          _errors.push(createErrorMessageObject(i + 1, Error.NEW_EDIT_INITIALIZED_SINGLE_EVENT_EDIT));
        }
      } else if (isEditMissingStartOrEndDateTime) {
        _errors.push(createErrorMessageObject(i + 1, Error.START_TIME_END_TIME_REQUIRED));
      } else {
        // now validate
        const editStartDateTimeMs = moment(editObject.startDateTime).tz(timezoneOffsetFromUTC).valueOf();
        const editEndDateTimeMs = moment(editObject.endDateTime).tz(timezoneOffsetFromUTC).valueOf();

        // Derive values for validation
        // if the editStartDateTimeMs/editEndDateTimeMs is at midnight of the next day, we want to default them to 23:59:59.99999
        // so verification stays within the daily certification period
        const oneDayFromStartTimeUTCMs = moment(getAttribute(eldDailyCertification, 'endTimeUTC')).tz(timezoneOffsetFromUTC).valueOf();
        if (editStartDateTimeMs === oneDayFromStartTimeUTCMs) {
          moment(editStartDateTimeMs).tz(timezoneOffsetFromUTC).subtract(1, 'millisecond').valueOf();
        }

        if (editEndDateTimeMs === oneDayFromStartTimeUTCMs) {
          moment(editEndDateTimeMs).tz(timezoneOffsetFromUTC).subtract(1, 'millisecond').valueOf();
        }

        // Populate error array
        if (!hasDutyStatus) {
          _errors.push(createErrorMessageObject(i + 1, Error.DUTY_STATUS_REQUIRED));
        }

        let isEditStartLessThanEnd = editStartDateTimeMs < editEndDateTimeMs;
        if (eldEvent) isEditStartLessThanEnd = true; // if a single-event edit then this is true
        if (!isEditStartLessThanEnd) {
          _errors.push(createErrorMessageObject(i + 1, Error.START_TIME_AFTER_END_TIME));
        }

        let isEditWithinCurrentDriverDateTime = (editStartDateTimeMs < currentDriverDateTimeMs) && (editEndDateTimeMs < currentDriverDateTimeMs);
        if (props.isELDOverride) isEditWithinCurrentDriverDateTime = true;
        if (!isEditWithinCurrentDriverDateTime) {
          _errors.push(createErrorMessageObject(i + 1, Error.OUTSIDE_CURRENT_DAY));
        }

        const isEditWithinELDDailyCertification =
          (editStartDateTimeMs >= startTimeUTCMs)
          && (editStartDateTimeMs <= endTimeUTCMs)
          && (editEndDateTimeMs >= startTimeUTCMs)
          && (editEndDateTimeMs <= endTimeUTCMs);
        if (!isEditWithinELDDailyCertification) {
          _errors.push(createErrorMessageObject(i + 1, Error.OUTSIDE_24_HOUR_PERIOD));
        }

        // Determine whether the edit violates AGDT. Bypass this check if it is a (Support) edit override
        // First, do an aobrdEnabled check on the given events to see if this rule matters or not, on this "day"
        const isAOBRDEnabled = isAOBRDEnabledByCount(props.eldEvents);
        // Now check if the edit lies within any AGDT time
        const _isDrivingEditViolation = isDrivingEditViolation(props.eldEvents, editObject.startDateTime, editObject.endDateTime);
        let isEditELDDrivingViolation = !isAOBRDEnabled && _isDrivingEditViolation && !isDriverWithinAutogeneratedBypassDate;
        //   let isDrivingViolation = this.props.isOverrideELDEvents ? false : ELD.isDrivingEditViolation(eldEvents, editInfo.startDateTime, editInfo.endDateTime);
        if (props.isELDOverride) isEditELDDrivingViolation = false;

        if (isEditELDDrivingViolation) {
          _errors.push(createErrorMessageObject(i + 1, Error.AGDT_VIOLATION));
        }
      }

      if (_errors.length === 0) {
        validEditObjectLocalIdMap[editObject.localId] = editObject;
      }
    }

    // We check/validate the edit reason in <ELDEditConfirmationMessage /> so that the edit reason
    // is scoped into its own component and doesn't rely on this one in order to be used
    setErrors(_errors);

    // callback to tell the parent if there are errors to be dealt with
    let hasErrors = _errors.length > 0;
    if (!isFetched) hasErrors = true; // if we cannot perform a full validation yet, consider there to be errors
    if (props.onValidationCompleted) props.onValidationCompleted(hasErrors, _errors, validEditObjectLocalIdMap);

    // after all is said and done, if there are indeed no errors and we tell the parent there are no errors
    // we can say everything is cleared
    if (!hasErrors) {
      setShowClearedMessage(true);
    }

    return { hasErrors, _errors, validEditObjectLocalIdMap };
  }

  useEffect(() => {
    let didCancel = false;
    const { driver } = props;

    async function init() {
      if (!driver) return; // dont bother running anything if we dont have a driver yet

      const _isDriverWithinAutogeneratedBypassDate = await isWithinAutogeneratedBypassDate(driver);

      if (!didCancel) {
        setIsDriverWithinAutogeneratedBypassDate(_isDriverWithinAutogeneratedBypassDate);
        setIsFetched(true);
      }
    }

    init();
    return () => { didCancel = true; };
  }, [props.driver]);

  useEffect(() => {
    validateProposedEdits();
  }, [props.eldEventsForPreview, props.editObjects]);

  // JSX
  // Create the error message elements
  let messages = errors.map((error) => {
    let severity = 'error';
    if (error.error.includes(Error.NEW_EDIT_INITIALIZED)) severity = 'info';
    if (error.error.includes(Error.NEW_EDIT_INITIALIZED_SINGLE_EVENT_EDIT)) severity = 'info';
    const content = error.errorJSX;

    return (
      <div className="mb-2 w-100 text-left" key={uniqid()}>
        <Message className="w-100 text-left" severity={severity} content={content} />
      </div>
    );
  });

  messages = messages.slice(0, 1); // just show 1 errors at a time to not overwhelm user

  if (showClearedMessage) {
    const clearedMessageContent = createClearedMessage();
    messages = [
      <div className="mb-2 w-100 text-left" key={uniqid()}>
        <Message className="w-100 text-left" severity="info" content={clearedMessageContent} />
      </div>,
    ];
  }

  let className = 'eldedit-modal-message-body';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <div className="flex flex-column">
        {messages}
      </div>
    </div>
  );
}

export default ELDEditModalMessageBody;
