import React, { useState, useEffect } from 'react';

// CSAPI API
import { getAttribute } from 'sb-csapi/dist/AAPI';

// API
import { getDispatchJobAccountingRecords } from 'api/Dispatch/DispatchJobAccounting';
import { getDispatchPayables } from 'api/Dispatch/DispatchPayable';
import { getDispatchTransfers } from 'api/Dispatch/DispatchTransfer';

// CSAPI sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// CSAPI Enums
import { QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';
import { Payee } from 'sb-csapi/dist/enums/Dispatch/Payee';

// sbCore Components
import WarningIcon from 'sbCore/WarningIcon/WarningIcon';

// Styles
import './style.scss';

/**
 * @description Component which displays a warning icon if a job's missing a payable
 * @param {DispatchJob} dispatchJob - The selected DispatchJob
 * @param {String} className - Custom className
 * @returns
 */
function PayableMissingWarning({ ...props }) {

  // ** useStates ** //
  const [showWarning, setShowWarning] = useState(false);

  // ** useEffects ** //
  useEffect(() => {
    if (!props.dispatchJob) return;

    let didCancel = false;

    let isPayableCompany;
    let numberOfDriverPayees = 0;
    async function checkIfWarningNeeded() {
      // Retrieve the DispatchJobAccounting
      const dispatchJobObjectId = getAttribute(props.dispatchJob, 'objectId');
      const { dispatchJobAccountings } = await getDispatchJobAccountingRecords(
        undefined,
        undefined,
        undefined,
        [new Filter(QueryRestrictionTypes.EQUAL_TO, 'dispatchJob', dispatchJobObjectId)],
        undefined,
        ['isVoided', 'isHidden', 'dispatchAccessorial'],
        undefined,
        undefined,
        undefined,
      );

      // Retrieve the payables associated with the job
      if (dispatchJobAccountings && dispatchJobAccountings.length > 0) {
        const dispatchJobAccounting = dispatchJobAccountings[0];
        const filters = ([
          new Filter(QueryRestrictionTypes.EQUAL_TO, 'dispatchJobAccounting', dispatchJobAccounting),
          new Filter(QueryRestrictionTypes.NOT_EQUAL_TO, 'isVoided', true),
          new Filter(QueryRestrictionTypes.NOT_EQUAL_TO, 'isHidden', true),
          new Filter(QueryRestrictionTypes.EQUAL_TO, 'dispatchAccessorial', undefined),
        ]);

        const { dispatchPayables } = await getDispatchPayables(
          undefined,
          undefined,
          filters,
          undefined,
          ['dispatchPayee'],
          undefined,
          undefined,
          undefined,
          true,
        );

        // Count the number of driver payees active in the job, and if there exists a payee company
        if (dispatchPayables || dispatchPayables.length > 0) {

          dispatchPayables.map((dispatchPayable) => {
            if (!dispatchPayable) return;

            const dispatchPayee = getAttribute(dispatchPayable, 'dispatchPayee');
            if (dispatchPayee) {
              const type = getAttribute(dispatchPayee, 'type');
              if (type === Payee.COMPANY.type) {
                isPayableCompany = true;
              } else if (type === Payee.DRIVER.type) {
                numberOfDriverPayees += 1;
              }
            }
          });
        }
      }

      // Retrieve the number of DispatchTransfers associated with the job
      const { dispatchTransfers } = await getDispatchTransfers(
        undefined,
        dispatchJobObjectId,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      );

      // If there isn't a carrier payable or an active driver payable for each transfer, show the warning
      if (!isPayableCompany && dispatchTransfers.length !== numberOfDriverPayees && !didCancel) {
        setShowWarning(true);
      } else {
        setShowWarning(false);
      }
    }

    checkIfWarningNeeded();
    return () => { didCancel = true; };
  }, [props.dispatchJob]);
  // console.log('showwarning: ', showWarning)
  // ** Misc ** //
  let className = 'payable-missing-warning';
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className}>
      <WarningIcon
        className="ml-3"
        tooltipText="Payable missing for this job"
        showWarning={showWarning}
        showTooltip
      />
    </div>
  );
}

export default PayableMissingWarning;
