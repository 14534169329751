import React, { useState, useEffect } from 'react';
import uniqid from 'uniqid';
import { Link } from 'react-router-dom';

// sbCore
import Button from 'sbCore/Button/Button';
import InputText from 'sbCore/InputText/InputText';

// CSAPI API
import { getAttribute, getCurrentUser } from 'sb-csapi/dist/AAPI';

// API
import { getDispatchDrivers, updateDispatchDriver } from 'api/Dispatch/DispatchDriver';
import { getDispatchPayees, addDispatchPayee } from 'api/Dispatch/DispatchPayee';
import { updateSameRouteNameDispatchTransfers } from 'api/Dispatch/DispatchTransfer';

// CSAPI Enums
import { Payee } from 'sb-csapi/dist/enums/Dispatch/Payee';
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';

// Components
import Card from 'sbCore/Card/Card';
import Divider from 'sbCore/Divider/Divider';
import Accordion from 'sbCore/Accordion/Accordion';
import AccordionTab from 'sbCore/Accordion/AccordionTab';
import Message from 'sbCore/Message/Message';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import DispatchDriverAutocomplete from 'components/Dispatch/DispatchDriverAutocomplete/DispatchDriverAutocomplete';
import DispatchVehicleAutocomplete from 'components/Dispatch/DispatchVehicleAutocomplete/DispatchVehicleAutocomplete';
import DispatchTrailerAutocomplete from 'components/Dispatch/DispatchTrailerAutocomplete/DispatchTrailerAutocomplete';
import DispatchTransferStatusDropdown from 'components/Dispatch/DispatchTransferStatusDropdown/DispatchTransferStatusDropdown';
import PaymentInformationRequiredPrompt from 'components/Dispatch/PaymentInformationRequiredPrompt/PaymentInformationRequiredPrompt';

// CSAPI sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Styles
import './style.scss';

/**
 * @description Component responsible for generating the "cards" for each dispatch leg
 * @param {Object} dispatchLeg - Required. The leg object that contains the information for the card.
 * @param {DispatchJob} dispatchJob - The current DispatchJob
 * @param {DispatchOrganization} [carrierDispatchOrganization] - If provided, disables the ability to assign driver and equipment
 * @param {DispatchJob} - Required. Used for linking to shipment details
 * @param {legIndex} - Required. Used for linking to shipment details
 * @param {Function} [handleRefresh] - Refreshes the state of the parent component
 * @returns
 */
function DispatchLegsContent({ ...props }) {
  const [activeIndex, setActiveIndex] = useState();

  // ** useStates ** //
  const [isPaymentInformationRequired, setIsPaymentInformationRequired] = useState(false);
  const [dispatchPayee, setDispatchPayee] = useState(null);
  const [dispatchDriver, setDispatchDriver] = useState(null);
  const [shippingDocumentNumber, setShippingDocumentNumber] = useState(null);

  // ** useEffects ** //
  useEffect(() => {
    let didCancel = false;
    if (!props.dispatchLeg) return;

    async function _getDispatchDriver() {
      if (!didCancel) {
        const dispatchTransferObjectId = props.dispatchLeg.dispatchTransferObjectId;
        if (!dispatchTransferObjectId) return;
        const includedPointers = ['driver', 'dispatchTransfer', 'dispatchPayable'];
        const { dispatchDrivers } = await getDispatchDrivers(undefined, dispatchTransferObjectId, undefined, undefined, includedPointers);

        if (dispatchDrivers && dispatchDrivers.length > 0) {
          // A transfer should only contain one dispatch trailer, so get first element from the array.
          const dispatchDriver = dispatchDrivers[0];

          const driver = getAttribute(dispatchDriver, 'driver');
          if (driver) {
            const fullName = getAttribute(driver, 'user_fullName');
            const username = getAttribute(driver, 'user_username');
            let _dispatchPayee;

            const filters = [];
            if (fullName) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'name', fullName));
            if (username) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'code', username));

            const { dispatchPayees } = await getDispatchPayees(
              undefined,
              undefined,
              filters,
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              false,
            );
            if (dispatchPayees && dispatchPayees.length > 0) _dispatchPayee = dispatchPayees[0];
            setDispatchPayee(_dispatchPayee);
          }

          const dispatchPayable = getAttribute(dispatchDriver, 'dispatchPayable', true);
          if (!dispatchPayable) {
            setIsPaymentInformationRequired(true);
          }

          // const driver = getAttribute(dispatchDriver, 'driver');
          // if (driver) {
          //   const fullName = getAttribute(driver, 'user_fullName');
          //   const username = getAttribute(driver, 'user_username');
          //   let dispatchPayee;

          //   // Check if a DispatchPayee exists. If not, create a new one
          //   const filters = [];
          //   if (fullName) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'name', fullName));
          //   if (username) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'code', username));

          //   const { dispatchPayees } = await getDispatchPayees(
          //     undefined,
          //     undefined,
          //     filters,
          //     undefined,
          //     undefined,
          //     undefined,
          //     undefined,
          //     undefined,
          //     false,
          //   );

          //   if (dispatchPayees && dispatchPayees.length > 0) {
          //     dispatchPayee = dispatchPayees[0];
          //     setDispatchPayee(dispatchPayee);
          //   }
          // }
          setDispatchDriver(dispatchDrivers[0]);
        }
      }
    }

    _getDispatchDriver();
    return () => { didCancel = true; };
  }, [props.dispatchLeg]);

  useEffect(() => {
    setShippingDocumentNumber(props.dispatchLeg.shippingDocumentNumber);
  }, [props.dispatchLeg]);

  // ** Callback Functions ** //
  const onSelectDriver = async (driver, dispatchDriver) => {
    if (driver) {
      const fullName = getAttribute(driver, 'user_fullName');
      const username = getAttribute(driver, 'user_username');
      let dispatchPayee;

      // Check if a DispatchPayee exists. If not, create a new one
      const filters = [];
      if (fullName) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'name', fullName));
      if (username) filters.push(new Filter(QueryRestriction.EQUAL_TO, 'code', username));

      const { dispatchPayees } = await getDispatchPayees(
        undefined,
        undefined,
        filters,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        false,
      );

      if (dispatchPayees && dispatchPayees.length > 0) dispatchPayee = dispatchPayees[0];

      if (!dispatchPayee) {
        const currentUser = getCurrentUser();
        const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
        const dispatchPayeeObj = {
          name: fullName,
          code: username,
          type: Payee.DRIVER.type,
          belongsToCompany,
        };
        dispatchPayee = await addDispatchPayee(dispatchPayeeObj);
      }
      setDispatchPayee(dispatchPayee);
      setDispatchDriver(dispatchDriver);
      setIsPaymentInformationRequired(true);
    } else {
      setDispatchPayee(null);
      setDispatchDriver(null);
      setIsPaymentInformationRequired(false);
    }

    props.handleRefresh();
  };

  function onSelectDispatchVehicle(dispatchVehicle) {
    props.handleRefresh();
    return dispatchVehicle
  }

  function onSelectDispatchTrailer(dispatchTrailer) {
    props.handleRefresh();
    return dispatchTrailer
  }

  async function onSelectShippingId() {
    await updateSameRouteNameDispatchTransfers(undefined, props.dispatchLeg.routeName, props.dispatchJob, { shippingDocumentNumber }, true);
  }

  const onSave = (dispatchPayable) => {
    updateDispatchDriver(dispatchDriver, undefined, { dispatchPayable }, true);
    setIsPaymentInformationRequired(false);
  };

  const title = (
    <div className="flex flex-row" style={{ gap: '1em' }}>
      <div className="dispatch-leg-id">{props.dispatchLeg.dispatchLegId}</div>

      <div className="grid">
        <div className="col">
          <DispatchTransferStatusDropdown hideLabel dispatchTransferObjectId={props.dispatchLeg.dispatchTransferObjectId} handleRefresh={props.handleRefresh} />
          {!(props.dispatchLeg.isStatusModified) && <InputLabel warning>* Current status is an approximation based off leg's dates</InputLabel>}
        </div>
      </div>
      <div className="dispatch-leg-id">
        <Link
          target="_blank"
          to={`/dispatch/job/${getAttribute(props.dispatchJob, 'objectId')}#${props.dispatchLeg.dispatchTransferObjectId}-${props.legIndex}`}
        >
          Shipment Details
        </Link>
      </div>
    </div>
  );

  return (
    <div className="dispatch-legs-content">
      <Card title={title} className="mb-2">
        {(!props.dispatchLeg.hasDriver || !props.dispatchLeg.hasVehicle || !props.dispatchLeg.hasTrailer) &&(
          <div className="message-container" >
            <Message severity="warn" text="Please complete leg information" />
          </div>
        )}
        <div className="grid">
          <div className="col">
            <div className="font-bold pb-2">Shipper</div>
            <div>{props.dispatchLeg.shipperName}</div>
          </div>
          <div className="col">
            <div className="font-bold pb-2">Pickup Time</div>
            <div>{props.dispatchLeg.pickupDateTimeString}</div>
          </div>
          <div className="col">
            <div className="font-bold pb-2">Consignee</div>
            <div>{props.dispatchLeg.consigneeName}</div>
          </div>
          <div className="col">
            <div className="font-bold pb-2">Dropoff Time</div>
            <div>{props.dispatchLeg.dropoffDateTimeString}</div>
          </div>
          <div className="col">
            <div className="font-bold pb-2">Timezone</div>
            <div>{props.dispatchLeg.timezone}</div>
          </div>
        </div>

        <div className="grid mt-2">
          <div className="col-4">
            <div className="font-bold pb-2">Driver</div>
            <DispatchDriverAutocomplete
              hideLabel
              dispatchTransferObjectId={props.dispatchLeg.dispatchTransferObjectId}
              clearDispatchDriver={props.carrierDispatchOrganization}
              disabled={props.carrierDispatchOrganization}
              onSelectDriver={onSelectDriver}
            />
            <PaymentInformationRequiredPrompt
              className="driver-payment-information-required"
              dispatchPayee={dispatchPayee}
              dispatchJob={props.dispatchJob}
              onSave={onSave}
              visible={isPaymentInformationRequired}
            />
          </div>
          <div className="col-4">
            <div className="font-bold pb-2">Vehicle</div>
            <DispatchVehicleAutocomplete
              hideLabel
              dispatchTransferObjectId={props.dispatchLeg.dispatchTransferObjectId}
              clearDispatchVehicle={props.carrierDispatchOrganization}
              disabled={props.carrierDispatchOrganization}
              onSelectDispatchVehicle={(dispatchVehicle) => onSelectDispatchVehicle(dispatchVehicle)}
            />
          </div>
          <div className="col-4">
            <div className="font-bold pb-2">Trailer</div>
            <DispatchTrailerAutocomplete
              hideLabel
              dispatchTransferObjectId={props.dispatchLeg.dispatchTransferObjectId}
              clearDispatchTrailer={props.carrierDispatchOrganization}
              disabled={props.carrierDispatchOrganization}
              onSelectDispatchTrailer={(dispatchTrailer) => onSelectDispatchTrailer(dispatchTrailer)}
            />
          </div>
          <div className="col-4">
            <div className="font-bold pb-2">Shipping ID</div>
            <InputText
              value={shippingDocumentNumber}
              onChange={(e) => setShippingDocumentNumber(e.target.value)}
              onBlur={() => onSelectShippingId()}
            />
          </div>
        </div>

        <Divider />

        {(!props.dispatchLeg.freight || props.dispatchLeg.freight.length === 0) &&
          <p className="mb-0 text-400 font-italic">No freight information available</p>
        }

        {props.dispatchLeg.freight && props.dispatchLeg.freight.length > 0 && (
          <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            <AccordionTab header="Freight">
              <div className="grid mt-3">
                <div className="col">
                  <div className="font-bold pb-2">Equipment</div>
                </div>
                <div className="col">
                  <div className="font-bold pb-2">Quantity</div>
                </div>
                <div className="col">
                  <div className="font-bold pb-2">Category</div>
                </div>
                <div className="col">
                  <div className="font-bold pb-2">Weight</div>
                </div>
                <div className="col">
                  <div className="font-bold pb-2">Dimensions</div>
                </div>
                <div className="col">
                  <div className="font-bold pb-2">Reference</div>
                </div>
              </div>
              {props.dispatchLeg.freight.map((freightItem) => {
                return (
                  <div className="grid" key={freightItem.key}>
                    <div className="col">
                      <div>{freightItem.equipment}</div>
                    </div>
                    <div className="col">
                      <div>{freightItem.quantity}</div>
                    </div>
                    <div className="col">
                      <div>{freightItem.categoryStr}</div>
                    </div>
                    <div className="col">
                      <div>{freightItem.weightString}</div>
                    </div>
                    <div className="col">
                      <div>{freightItem.dimensionsString}</div>
                    </div>
                    <div className="col">
                      <div>{freightItem.referenceNumber}</div>
                    </div>
                  </div>
                );
              })}
            </AccordionTab>
          </Accordion>
        )}
      </Card>
    </div>
  );
}

export default DispatchLegsContent;
