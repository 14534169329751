import React from 'react';

// Enums
import { Document } from 'sb-csapi/dist/enums/Dispatch/Document';

// Helper Functions
import { openDocumentLink } from 'api/Helpers';

// sbCore Components
import DataTable from 'sbCore/DataTable/DataTable';
import Column from 'sbCore/Column/Column';

// Components
import Button from 'sbCore/Button/Button';

// Styles
import './style.scss';

/**
 * @description Renders a data table of documents
 * @param {Array} documents - An array of document objects formatted to be rendered within the datatable
 */
function DocumentDataTable({ ...props }) {

  const documentsArray = Object.keys(Document).map(key => {
    return Document[key];
  });

  // ** Data Table Templates ** //
  const downloadFieldTemplate = (rowData) => {
    return (
      <>
        {rowData.fileURL && (
          <Button className="p-button-rounded mx-1" icon="pi pi-external-link" onClick={() => openDocumentLink(rowData.fileURL)} />
        )}
      </>
    );
  };

  const documentTypeTemplate = (rowData) => {
    const documentType = documentsArray.find((_documentType) => _documentType.type === rowData.dispatchDocumentTypeInt);

    return (
      <div>
        {documentType && documentType.description}
      </div>
    );
  };

  return (
    <DataTable
      value={props.documents}
      className="dispatch-document-data-table"
      rowGroupMode="subheader"
      groupRowsBy="documentType"
      sortMode="single"
      sortField="documentType"
      sortOrder={1}
      scrollable
      scrollHeight="600px"
      responsiveLayout="scroll"
      emptyMessage="No Documents Available"
    >
      <Column field="uploadedAt" header="Uploaded Date" />
      <Column field="uploadedByFullName" header="Uploaded By" />
      <Column header="Document Type" body={documentTypeTemplate} />
      <Column field="" header="" body={downloadFieldTemplate} />
    </DataTable>
  );
}

export default DocumentDataTable;
