// api
import {
  getAttribute,
  createQuery,
  addRecord,
  updateRecord,
  setQueryRestriction,
  find,
  getCurrentUser,
} from 'api/Parse';

// enums
import { QueryRestrictionTypes } from 'enums/Query';

/** @module CompanyAnalytics */

/**
 * @memberof module:CompanyAnalytics
 * @description gets company analytics record for a user's company. If it does not exist, a new record is created and returned
 * 
 * @returns company analytics parse object
 */
async function getCompanyAnalytics() {
  const query = createQuery('CompanyAnalytics');

  const currentUser = await getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');

  setQueryRestriction(
    query,
    QueryRestrictionTypes.EQUAL_TO,
    'belongsToCompany',
    belongsToCompany,
  );

  let record = await find(query, true, false);

  if (!record) {
    const user = getCurrentUser();
    const belongsToCompany = getAttribute(user, 'belongsToCompany');
    record = await addRecord('CompanyAnalytics', { belongsToCompany });
  }

  return record;
}

/**
 * @memberof module:CompanyAnalytics
 * @description updates a company analytics parse object with the given properties
 * 
 * @param {*} companyAnalyticsObj company analytics parse object
 * @param {*} keyValueObj key value pairs object
 * 
 * @returns updated company analytics parse object
 */
async function updateCompanyAnalytics(companyAnalyticsObj, keyValueObj = {}) {
  if (!companyAnalyticsObj) {
    throw new Error('Must provide company analytics parse object');
  }

  const record = await updateRecord(companyAnalyticsObj, keyValueObj, true);

  return record;
}

export {
  getCompanyAnalytics,
  updateCompanyAnalytics,
}
