const DispatchContactTypes = Object.freeze({
  'ACCOUNTING': {
    value: 0,
    description: 'Accounting',
  },
  'AFTER_HOURS': {
    value: 1,
    description: 'After Hours',
  },
  'DISPATCHER': {
    value: 2,
    description: 'Dispatcher',
  },
  'EMERGENCY': {
    value: 3,
    description: 'Emergency',
  },
  'OFFICE': {
    value: 4,
    description: 'Office',
  },
  'REPORT': {
    value: 5,
    description: 'Report',
  },
  'TRACKING': {
    value: 6,
    description: 'Tracking',
  },
});

const DispatchContactTypeKeys = ['ACCOUNTING', 'AFTER_HOURS', 'DISPATCHER', 'EMERGENCY', 'OFFICE', 'REPORT', 'TRACKING'];

export {
  DispatchContactTypes,
  DispatchContactTypeKeys,
};
