import React from 'react';
import PropTypes from 'prop-types';

// Components
import AddNewJobButton from 'components/AddNewJob/container/AddNewJobButton';

// CSS
import styles from './Sidebar.module.scss';

// Assets
const tractor = require('assets/images/equipment/tractor-compressed.png');
const dryVan = require('assets/images/equipment/dry-van-compressed.png');

function Sidebar(props) {
  let expiredEquipmentStyle = styles.option;
  let trailersStyle = styles.option;
  let tractorsStyle = styles.option;
  let locationsStyle = styles.option;
  let groupsStyle = styles.option;
  if (props.activeSubview === 'expiredEquipment' || !props.activeSubview) {
    expiredEquipmentStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'vehicles') {
    tractorsStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'trailers') {
    trailersStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'locations') {
    locationsStyle = `${styles.active} ${styles.option}`;
  } else if (props.activeSubview === 'groups') {
    groupsStyle = `${styles.active} ${styles.option}`;
  }

  return (
    <div className={styles.sidebar + ' translate-me'}>
      <div className={styles.sidebarTitle}>
        <div className={styles.company}>{props.companyName}</div>
        <div className={styles.subHeader}>Equipment</div>
      </div>

      <div className={styles.subViews}>
        <a href={`/equipment?view=vehicles`} onClick={(e) => e.preventDefault()}>
          <div className={tractorsStyle} onClick={() => props.handleSubViewClick('vehicles')}>
            <div className={styles.icon}><img src={tractor} /></div>
            <div className={styles.label}>Vehicles</div>
          </div>
        </a>

        <a href={`/equipment?view=trailers`} onClick={(e) => e.preventDefault()}>
          <div className={trailersStyle} onClick={() => props.handleSubViewClick('trailers')}>
            <div className={styles.icon}><img src={dryVan} /></div>
            <div className={styles.label}>Trailers</div>
          </div>
        </a>

        <a href={`/equipment?view=groups`} onClick={(e) => e.preventDefault()}>
          <div className={groupsStyle} onClick={() => props.handleSubViewClick('groups')}>
            {/* <div className={styles.icon}><img src={dryVan} /></div> */}
            <div className={styles.label}>Groups</div>
          </div>
        </a>

        {
          // <div className={expiredEquipmentStyle} onClick={() => props.handleSubViewClick('expiredEquipment')}>
          //   <div className={styles.icon}><i className="material-icons">date_range</i></div>
          //   <div className={styles.label}>
          //     Expired Equipment &nbsp;
          //     { props.alerts > 0 &&
          //       <span className={`${styles.numberBadge} badge`}>{props.alerts}</span>
          //     }
          //   </div>
          // </div>
        }

        {
          // <div className={locationsStyle} onClick={() => props.handleSubViewClick('locations')}>
          //   <div className={styles.icon}><Glyphicon glyph="map-marker" /></div>
          //   <div className={styles.label}>Locations</div>
          // </div>
        }
      </div>
      {
        // <div className={styles.addJobButton}>
        //   <AddNewJobButton pickupAndDropoff />
        // </div>
      }
    </div>
  );
}

Sidebar.propTypes = {
  companyName: PropTypes.string,
  activeSubview: PropTypes.string.isRequired,
  handleSubViewClick: PropTypes.func,
  alerts: PropTypes.number,
};

export default Sidebar;
