import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

// API
import { getDispatchDocuments } from 'api/Dispatch/DispatchDocument';

// CSAPI
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { formatName } from 'sb-csapi/dist/utils/String';

// CSAPI Enums
import { Document } from 'sb-csapi/dist/enums/Dispatch/Document';
import { QueryRestrictionTypes } from 'sb-csapi/dist/enums/Query';

// CSAPI sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// Components
import DispatchDocumentDataTable from 'components/Dispatch/DispatchDocumentDataTable/DispatchDocumentDataTable';

// sbCore Components
import Message from 'sbCore/Message/Message';
import ProgessSpinner from 'sbCore/ProgressSpinner/ProgressSpinner';
import Accordion from 'sbCore/Accordion/Accordion';
import AccordionTab from 'sbCore/Accordion/AccordionTab';


// Styles
import './style.scss';

/**
 * @description Renders an accordion type table that displays all documents associated with a job
 * @param {String} dispatchJobObjectId - Dispatch job object id
 * @param {Boolean} isDocumentUploaded - State that holds whether or not documents have been uploaded
 * @param {Function} handleDocumentUpload - Function that sets the isDocumentUploaded state using the boolean argument passed to it. If true, will refresh the all documents table
 */
function DispatchAllDocumentsTable({ ...props }) {

  // retrieve an array of document types
  const documentsArray = Object.keys(Document).map(key => {
    return Document[key];
  });

  // ** useStates ** //
  const [activeIndex, setActiveIndex] = useState(0);
  const [filteredDocumentsObject, setFilteredDocumentsObject] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // ** useEffects ** //

  // Once a dispatch object id is received, retrieve the dispatch documents
  useEffect(() => {
    let didCancel = false;

    if (!props.dispatchJobObjectId) return;

    _getDispatchDocuments(didCancel);

    return () => { didCancel = true; };
  }, [props.dispatchJobObjectId]);

  // Updates the document data tables whenever new documents are uploaded
  useEffect(() => {
    let didCancel = false;

    if (!props.isDocumentUploaded) return;

    _getDispatchDocuments(didCancel);
    if (props.handleDocumentUpload) props.handleDocumentUpload(false);

    return () => { didCancel = true; };
  }, [props.isDocumentUploaded]);

  // ** Helper Functions ** //

  // Retrieves documents and filters them depending on their type
  async function _getDispatchDocuments(didCancel) {
    const { dispatchDocuments } = await getDispatchDocuments(
      undefined, // options - default
      [new Filter(QueryRestrictionTypes.EQUAL_TO, 'dispatchJob', props.dispatchJobObjectId)], // filters
      undefined, // sort - default
      ['uploadedBy'], // includes
      undefined, // selects
      undefined, // page
      undefined, // limit
      true, // query all
    );

    const formattedDispatchDocuments = dispatchDocuments.map((dispatchDocument) => {
      const dispatchDocumentObjectId = getAttribute(dispatchDocument, 'objectId');

      const dispatchDocumentTypeInt = getAttribute(dispatchDocument, 'type');

      const createdAt = getAttribute(dispatchDocument, 'createdAt', true);
      const uploadedAt = (createdAt && moment(createdAt).format('DD-MM-YYYY HH:mm:ss')) || '-';

      const uploadedBy = getAttribute(dispatchDocument, 'uploadedBy', true);
      const uploadedByFirstName = uploadedBy && getAttribute(uploadedBy, 'firstName', true);
      const uploadedByLastName = uploadedBy && getAttribute(uploadedBy, 'firstName', true);
      const uploadedByFullName = (uploadedByFirstName && uploadedByLastName) ? formatName(`${uploadedByFirstName} ${uploadedByLastName}`) : '-';

      const file = getAttribute(dispatchDocument, 'file');
      const fileURL = file && file.url();

      return ({
        dispatchDocumentObjectId,
        dispatchDocumentTypeInt,
        uploadedAt,
        uploadedByFullName,
        fileURL,
      });
    });

    const _filteredDocumentsObject = {};
    documentsArray.forEach((document) => {
      const documentTypeInt = document.type;
      const filteredDocuments = filterDocuments(formattedDispatchDocuments, documentTypeInt);

      if (filteredDocuments.length > 0) {
        _filteredDocumentsObject[documentTypeInt] = filteredDocuments;
      }
    });

    if (!didCancel) {
      setFilteredDocumentsObject(_filteredDocumentsObject);
      setIsLoading(false);
    }
  }

  // Filters documents depending on the document type argument given. If document type is null
  // and not one of the main three types, should be categorized as 'other'
  function filterDocuments(dispatchDocuments, documentTypeInt) {
    const filteredDocuments = dispatchDocuments.filter((dispatchDocument) => {
      if (!dispatchDocument) return;

      if (dispatchDocument.dispatchDocumentTypeInt === documentTypeInt) {
        return dispatchDocument;
      }
    });

    return filteredDocuments;
  }

  return (
    <div className="dispatch-all-documents-table card">
      {
        filteredDocumentsObject && Object.keys(filteredDocumentsObject).length > 0 && (
          <Accordion multiple activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
            {
              Object.keys(filteredDocumentsObject).length > 0 && (
                documentsArray.map((document) => {
                  const filteredDocumentsArrayLength = filteredDocumentsObject[document.type] && filteredDocumentsObject[document.type].length;
                  if (!filteredDocumentsArrayLength > 0) return;

                  let header = document.description;

                  // Change header to reflect the correct name for the BOL
                  if (document.type === Document.CUSTOMER_BILL_OF_LADING.type) {
                    header = 'Bill of Lading';
                  }

                  return (
                    <AccordionTab header={header}>
                      <DispatchDocumentDataTable
                        documents={filteredDocumentsObject[document.type]}
                      />
                    </AccordionTab>
                  );
                })
              )
            }
          </Accordion>
        )
      }

      {
        filteredDocumentsObject && !Object.keys(filteredDocumentsObject).length > 0 && (
          <Message severity="info" text="No Documents Available To Display" />
        )
      }

      {isLoading && <ProgessSpinner className="loading" strokeWidth={3} />}
    </div>
  );
}

export default DispatchAllDocumentsTable;
