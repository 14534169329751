import React from 'react';
import PropTypes from 'prop-types';

// api
import { getAttribute } from 'api/Parse';
import { getJobNotes, updateJobNotes } from 'api/Dispatch/DispatchJobNotes';
import AttributeTypes from 'enums/DispatchJobNotes';

// components
import JobReferenceNumbers from 'components/Dispatch/JobReferenceNumbers/JobReferenceNumbers';
import DispatchSealNumbers from 'components/Dispatch/DispatchSealNumbers/DispatchSealNumbers';

// sbCore
import ColoredSection from 'sbCore/ColoredSection/ColoredSection';
import InputTextarea from 'sbCore/InputTextarea/InputTextarea';
import Divider from 'sbCore/Divider/Divider';

// styles
import './style.scss';

class DispatchReferenceNotesView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dirtyNote: false,
      dispatchJobNotes: undefined,
      isLoading: true,
      loadingMessage: 'Loading',
    };
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.handleNotesSave = this.handleNotesSave.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.setIsLoading = this.setIsLoading.bind(this);
  }

  async componentDidMount() {
    await this.refreshState();
  }

  async handleNotesChange(noteValue, notesType) {
    const { dispatchJobNotes, dirtyNote } = this.state;
    const updatedNote = {};
    updatedNote[notesType] = noteValue;

    if (!dirtyNote) {
      this.setState({ ...this.state, dirtyNote: true });
    }
    await updateJobNotes(dispatchJobNotes, updatedNote);
  }

  async handleNotesSave(noteValue, notesType) {
    const { dispatchJobNotes, dirtyNote } = this.state;
    // If there has been no changes don't save.
    if (!dirtyNote) return;

    const updatedNote = {};
    updatedNote[notesType] = noteValue;

    this.setState({ ...this.state, isLoading: true, loadingMessage: 'Saving Notes' });
    try {
      await updateJobNotes(dispatchJobNotes, updatedNote, true);
      this.setState({ ...this.state, dirtyNote: false });
      await this.refreshState();
    } catch (err) {
      console.error(err);
    } finally {
      this.setState({ ...this.state, isLoading: false });
    }
  }

  async setIsLoading(isLoading, loadingMessage = 'Saving Notes') {
    return new Promise((resolve) => {
      this.setState({ ...this.state, isLoading, loadingMessage }, resolve);
    });
  }

  async refreshState() {
    const { dispatchJob } = this.props;
    let { dispatchJobNotes } = this.state;

    this.setState({ ...this.state, isLoading: true });
    try {
      dispatchJobNotes = dispatchJob && await getJobNotes(dispatchJob);
    } catch (err) {
      console.error(err);
    }
    this.setState({ ...this.state, dispatchJobNotes, isLoading: false });
  }

  render() {
    const { dispatchJobNotes, isLoading, loadingMessage } = this.state;
    const { dispatchJob } = this.props;

    return (
      <ColoredSection
        className=""
        title="Reference Summary & Notes"
        options=""
      >
        {dispatchJobNotes && (
          <div className="flex dispatch-reference-notes-container" style={{ gap: '2em' }}>
            <div className="flex flex-column flex-grow-1 job-reference-col">
              <JobReferenceNumbers
                className="w-100"
                setIsLoading={this.setIsLoading}
                dispatchJob={dispatchJob}
                disabled={isLoading}
                isRefreshState={this.props.isRefreshState}
                triggerRefreshState={this.props.triggerRefreshState}
              />
              {/* <Divider /> */}
              <div className="flex">
                <DispatchSealNumbers dispatchJob={dispatchJob} isRefreshState={this.props.isRefreshState} />
              </div>
            </div>

            <div className="flex flex-column flex-grow-1" style={{ gap: '1em', height: '100%' }}>
              <div className="flex flex-column flex-grow-1">
                <label>Notes for Job</label>
                <InputTextarea
                  className="form-control"
                  rows="4"
                  defaultValue={getAttribute(dispatchJobNotes, AttributeTypes.NOTES_GENERAL)}
                  onBlur={(event) => this.handleNotesSave(event.target.value, AttributeTypes.NOTES_GENERAL)}
                  onChange={(event) => this.handleNotesChange(event.target.value, AttributeTypes.NOTES_GENERAL)}
                  placeholder="No notes for Job."
                  disabled={isLoading}
                  tabIndex={isLoading ? -1 : 1}
                />
              </div>

              <div className="flex flex-grow-1" style={{ gap: '1em' }}>
                <div className="flex flex-column flex-grow-1">
                  <label>Notes for Dispatch</label>
                  <InputTextarea
                    className="form-control"
                    rows="4"
                    defaultValue={getAttribute(dispatchJobNotes, AttributeTypes.NOTES_DISPATCH)}
                    onBlur={(event) => this.handleNotesSave(event.target.value, AttributeTypes.NOTES_DISPATCH)}
                    onChange={(event) => this.handleNotesChange(event.target.value, AttributeTypes.NOTES_DISPATCH)}
                    placeholder="No notes for Dispatch."
                    disabled={isLoading}
                    tabIndex={isLoading ? -1 : 2}
                  />
                </div>

                <div className="flex flex-column flex-grow-1">
                  <label>Notes for Driver</label>
                  <InputTextarea
                    className="form-control"
                    rows="4"
                    defaultValue={getAttribute(dispatchJobNotes, AttributeTypes.NOTES_DRIVER)}
                    onBlur={(event) => this.handleNotesSave(event.target.value, AttributeTypes.NOTES_DRIVER)}
                    onChange={(event) => this.handleNotesChange(event.target.value, AttributeTypes.NOTES_DRIVER)}
                    placeholder="No notes for Driver."
                    disabled={isLoading}
                    tabIndex={isLoading ? -1 : 3}
                  />
                </div>
              </div>

              <div className="flex flex-grow-1" style={{ gap: '1em' }}>
                <div className="flex flex-column flex-grow-1">
                  <label>Notes for Invoice</label>
                  <InputTextarea
                    className="form-control"
                    rows="4"
                    defaultValue={getAttribute(dispatchJobNotes, AttributeTypes.NOTES_INVOICE)}
                    onBlur={(event) => this.handleNotesSave(event.target.value, AttributeTypes.NOTES_INVOICE)}
                    onChange={(event) => this.handleNotesChange(event.target.value, AttributeTypes.NOTES_INVOICE)}
                    placeholder="No notes for Invoice."
                    disabled={isLoading}
                    tabIndex={isLoading ? -1 : 4}
                  />
                </div>

                <div className="flex flex-column flex-grow-1">
                  <label>Notes for Billing</label>
                  <InputTextarea
                    className="form-control"
                    rows="4"
                    defaultValue={getAttribute(dispatchJobNotes, AttributeTypes.NOTES_BILLING)}
                    onBlur={(event) => this.handleNotesSave(event.target.value, AttributeTypes.NOTES_BILLING)}
                    onChange={(event) => this.handleNotesChange(event.target.value, AttributeTypes.NOTES_BILLING)}
                    placeholder="No notes for Billing."
                    disabled={isLoading}
                    tabIndex={isLoading ? -1 : 5}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </ColoredSection>
    );
  }
}

DispatchReferenceNotesView.propTypes = {
  dispatchJobObjectId: PropTypes.string,
  dispatchJob: PropTypes.object,
};

export default DispatchReferenceNotesView;
