import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { MDBRow, MDBCol } from 'mdbreact';

// API
import * as Helpers from 'api/Helpers';
import * as CanadaHours from 'api/CycleHour/Canada/Hours';
import * as USAHours from 'api/CycleHour/USA/Hours';
import { getAttribute, getDirtyHash } from 'api/Parse';
import { getDriverModule, updateDriverModule } from 'api/DriverModule/DriverModule';

// Components
import DatePicker from 'components/DatePicker/container/DatePicker';
import TimezonePicker from 'react-timezone';
import FilterDropdown from 'components/FilterDropdown/view/FilterDropdown';
import ListSelectField from 'components/ListSelectField/view/ListSelectField';
import LoadingOverlay from 'components/Shared/LoadingOverlay/LoadingOverlay';
import VehicleAutocomplete from 'sbCore/VehicleAutocomplete/VehicleAutocomplete';
import AddressDropdown from 'sbCore/AddressDropdown/AddressDropdown';

// sbCore
import Dropdown from 'sbCore/Dropdown/Dropdown';

// Lists
import { StateProvinces } from 'api/Lists/StateProvinces';
import { canada as canadaCycles, us as usCycles, getReferenceIntFromDescription } from 'api/Lists/HoursCycle';

// Enums
import { QueryRestriction } from 'sb-csapi/dist/enums/Query';
import { PCYMCountryRestriction, PCYMCountryRestrictionDescription } from 'sb-csapi/dist/enums/Driver/SpecialStatus/PCYMCountryRestriction';

// sbObjects
import Filter from 'sb-csapi/dist/sbObjects/Filter';

// CSS
import styles from './EditDriverDetailsModal.module.scss';
import './style.scss';

class EditDriverDetailsModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      driver: props.driver,
      driverUser: props.driver.get('user'),
      driverInfo: {
        password: '',
        username: props.driver.get('user_username'),
        email: props.driver.get('user_email') || '',
        phoneNumber: props.driver.get('user').get('phoneNumber') || '',
        driversLicense: props.driver.get('driversLicense') || '',
        licenseExpiryDate: props.driver.get('licenseExpiryDate') ? props.driver.get('licenseExpiryDate') : undefined,
        medicalCheckDate: props.driver.get('medicalCheckDate') ? props.driver.get('medicalCheckDate') : undefined,
        driversLicenseJurisdiction: props.driver.get('driversLicenseJurisdiction'),
        driversLicenseCountry: props.driver.get('driversLicenseCountry'),
        notes: props.driver.get('notes') || '',
        exempt: props.driver.get('exempt') || false,
        yardMoves: props.driver.get('yardMoves') || false,
        personalUseCMV: props.driver.get('personalUseCMV') || false,
        exemptionReason: props.driver.get('exemptionReason') || '',
        dispatchers: props.driver.get('dispatchers') || [],
        dotNumber: props.driver.get('dotNumber') || '',
        nscNumber: props.driver.get('nscNumber') || '',
        hourCycleCanadaInt: props.driver.get('hourCycleCanadaInt') || undefined,
        hourCycleUSAInt: props.driver.get('hourCycleUSAInt') || undefined,
        defaultVehicle: props.driver.get('defaultVehicle') || undefined,
        altTerminalAddress: props.driver.get('altTerminalAddress') || props.driver.get('belongsToCompany').get('address') || undefined,
      },
      driverModuleObj: null,
      error: undefined,
      displayCADCycleEdit: undefined,
      displayUSACycleEdit: undefined,
      isLoading: false,
      sendTextToDriver: true,
      disablePCYMCountryOptions: [
        {label: PCYMCountryRestrictionDescription.NONE, value: PCYMCountryRestriction.NONE},
        {label: PCYMCountryRestrictionDescription.CANADA, value: PCYMCountryRestriction.CANADA},
        {label: PCYMCountryRestrictionDescription.UNITED_STATES, value: PCYMCountryRestriction.UNITED_STATES},
      ],
      isAddressDialogOpen: false,
    };
    if (props.driver.id) {
      // when we know we have a dispatcher given in advance
      this.state.driverInfo.timezoneOffsetFromUTC = props.driver.get('timezoneOffsetFromUTC') || 'America/Vancouver';
    }
    this.refreshState = this.refreshState.bind(this);
    this.handleChangeDriverInfo = this.handleChangeDriverInfo.bind(this);
    this.editDispatchers = this.editDispatchers.bind(this);
    this.updateDriver = this.updateDriver.bind(this);
    this.handleClose = this.handleClose.bind(this);

    // group stateProvinces by country
    const canadianJurs = [];
    const americanJurs = [];
    const mexicanJurs = [];
    for (let i = 0; i < StateProvinces.length; i++) {
      const stateProvince = StateProvinces[i];
      if (stateProvince.country === 'CA') {
        canadianJurs.push(stateProvince);
      } else if (stateProvince.country === 'US') {
        americanJurs.push(stateProvince);
      } else if (stateProvince.country === 'MX') {
        mexicanJurs.push(stateProvince);
      }
    }
    this.state.stateProvinces = [].concat(canadianJurs, americanJurs, mexicanJurs);
  }

  async componentDidMount() {
    this.setState({ ...this.state, isLoading: true });
    const driverModuleObj = await getDriverModule(undefined, getAttribute(this.props.driver, 'objectId'));
    const displayCADCycleEdit = await CanadaHours.isAllowedChangeCycles(this.props.driver);
    const displayUSACycleEdit = await USAHours.isAllowedChangeCycles(this.props.driver);
    this.setState({
      ...this.state,
      driverModuleObj,
      displayCADCycleEdit,
      displayUSACycleEdit,
      isLoading: false,
    });
  }

  refreshState(props) {
    const promise = new Promise((resolve) => {
      const newState = {
        ...this.state,
        driver: props.driver,
        driverUser: props.driver.get('user'),
        driverInfo: {
          username: props.driver.get('user').get('user_username'),
          email: props.driver.get('user').get('user_email') || '',
          phoneNumber: props.driver.get('user').get('phoneNumber') || '',
          driversLicense: props.driver.get('driversLicense') || '',
          licenseExpiryDate: props.driver.get('licenseExpiryDate') ? props.driver.get('licenseExpiryDate') : '',
          medicalCheckDate: props.driver.get('medicalCheckDate') ? props.driver.get('medicalCheckDate') : '',
          driversLicenseJurisdiction: props.driver.get('driversLicenseJurisdiction'),
          driversLicenseCountry: props.driver.get('driversLicenseCountry'),
          dotNumber: props.driver.get('dotNumber'),
          nscNumber: props.driver.get('nscNumber'),
          timezoneOffsetFromUTC: props.driver.get('timezoneOffsetFromUTC'),
          notes: props.driver.get('notes') || '',
          exempt: props.driver.get('exempt') || false,
          yardMoves: props.driver.get('yardMoves') || false,
          personalUseCMV: props.driver.get('personalUseCMV') || false,
          exemptionReason: props.driver.get('exemptionReason') || '',
          hourCycleCanadaInt: props.driver.get('hourCycleCanadaInt') || undefined,
          hourCycleUSAInt: props.driver.get('hourCycleUSAInt') || undefined,
          defaultVehicle: props.driver.get('defaultVehicle') || undefined,
          altTerminalAddress: props.driver.get('altTerminalAddress') || props.driver.get('belongsToCompany').get('address') || undefined,
        },
        isLoading: false,
      };
      this.setState(newState, () => resolve(true));
    });
    return promise;
  }

  async handleChangeDriverInfo(e, customId, _value) {
    let value;
    let targetId;
    if (_value !== undefined) {
      value = _value;
    } else if ((customId === 'defaultVehicle') && (_value === undefined)) {
      value = undefined;
    } else if ((customId === 'altTerminalAddress') && (_value === undefined)) {
      value = undefined;
    } else {
      value = e.target.value;
      targetId = e.target.id;
    }

    await this.setState({ ...this.state, error: '' });
    const newState = { ...this.state };
    const { driverInfo } = newState;
    if (customId && _value !== undefined) {
      driverInfo[customId] = value;
    } else if ((customId === 'defaultVehicle') && (value === undefined)) {
      driverInfo[customId] = value;
    } else if ((customId === 'altTerminalAddress') && (value === undefined)) {
      driverInfo[customId] = value;
    } else if (targetId) {
      driverInfo[targetId] = value;
    }

    // VALIDATION
    newState.disableUpdateDriver = false;
    if (driverInfo.exempt && (!driverInfo.exemptionReason || Helpers.isStringEmpty(driverInfo.exemptionReason))) {
      newState.error = 'Exemption reason should be filled';
      newState.disableUpdateDriver = true;
    }

    if (driverInfo.dispatchers.length === 0) {
      newState.disableUpdateDriver = true;
    }

    // since license jur/country is forced by the UI, we only need to check if driver's license is empty
    if (!driverInfo.driversLicense || Helpers.isStringEmpty(driverInfo.driversLicense)) {
      newState.disableUpdateDriver = true;
    }

    const keys = Object.keys(driverInfo);
    for (let i = 0; i < keys.length; i++) {
      const currentKey = keys[i];

      // if the user is subscribed to eldmodule and the timezone field is empty, disable add driver
      if (currentKey === 'timezoneOffsetFromUTC' && (Helpers.isStringEmpty(driverInfo[currentKey]) && Helpers.isStringEmpty(driverInfo.timezoneOffsetFromUTC)) && Helpers.isSubscribedToModule('eldModule')) {
        this.state.disableUpdateDriver = true;
        break;
      }
      if (currentKey === 'driversLicense' && (!driverInfo[currentKey] || Helpers.isStringEmpty(driverInfo[currentKey]))) {
        newState.disableUpdateDriver = true;
        break;
      }
      if (currentKey === 'password' && driverInfo[currentKey]) {
        let pwd = driverInfo[currentKey] || '';

        const isNotEmpty = !Helpers.isStringEmpty(pwd.trim());
        const isAtLeastMinimumLength = (pwd.length >= 4);
        const isAlphanumericPlusSymbols = Helpers.isAlphanumericPlusSymbols(pwd);

        if (!isNotEmpty || !isAtLeastMinimumLength) {
          newState.disableUpdateDriver = true;
          newState.error = "Password needs to be at least 4 characters long";
          break;
        } else if (!isAlphanumericPlusSymbols) {
          newState.disableUpdateDriver = true;
          newState.error = "Password needs to be alphanumeric and symbols only";
          break;
        }
      }
    }

    this.setState(newState);
  }

  editDispatchers(dispatcherId, index) {
    const driverDispatchers = [].concat(this.state.driverInfo.dispatchers);
    const selectedDispatcher = this.props.dispatchers.filter(dispatcher => dispatcher.id === dispatcherId)[0];
    if (selectedDispatcher) {
      driverDispatchers[index] = selectedDispatcher;
    }
    this.setState({ ...this.state, driverInfo: { ...this.state.driverInfo, dispatchers: driverDispatchers } }, () => {
      this.handleChangeDriverInfo({}, 'dispatchers');
    });
  }

  updateDriver() {
    const loadingState = { ...this.state, isLoading: true };
    this.setState(loadingState, () => {
      const driverInfoObject = Object.assign({}, this.state.driverInfo);

      if (this.state.driverModuleObj) {
        const driverModuleDirtyHash = getDirtyHash(this.state.driverModuleObj);

        if (Object.keys(driverModuleDirtyHash).length > 0) {
          updateDriverModule([getAttribute(this.state.driver, 'objectId')], driverModuleDirtyHash);
        }
      } else {
        // if for whatever reason there is no driver module, but there should be, add a new drivermodule for this driver
        updateDriverModule([getAttribute(this.state.driver, 'objectId')], {});
      }
      const keys = Object.keys(driverInfoObject);
      for (let i = 0; i < keys.length; i++) {
        const currentKey = keys[i];
        if (driverInfoObject[currentKey] &&
          (
            currentKey !== 'exempt' &&
            currentKey !== 'yardMoves' &&
            currentKey !== 'personalUseCMV' &&
            currentKey !== 'disabledELDEdit' &&
            currentKey !== 'dispatchers' &&
            currentKey !== 'licenseExpiryDate' &&
            currentKey !== 'medicalCheckDate' &&
            currentKey !== 'hourCycleCanadaInt' &&
            currentKey !== 'hourCycleUSAInt' &&
            currentKey !== 'disabledCACycleHourSelect' &&
            currentKey !== 'disabledUSCycleHourSelect' &&
            currentKey !== 'defaultVehicle' &&
            currentKey !== 'altTerminalAddress'
          )
        ) {
          driverInfoObject[currentKey] = driverInfoObject[currentKey].trim();
        } else if (currentKey === 'licenseExpiryDate' && driverInfoObject[currentKey]) {
          driverInfoObject[currentKey] = driverInfoObject[currentKey];
        }

        if ((currentKey === 'exemptionReason' || currentKey === 'password') && (!driverInfoObject[currentKey] || driverInfoObject[currentKey].length === 0)) {
          driverInfoObject[currentKey] = null;
        }
      }

      if (this.state.driver.get('yardMoves') !== driverInfoObject.yardMoves) {
        driverInfoObject.yardMovesChangeAcknowledged = false;
      }

      if (this.state.driver.get('personalUseCMV') !== driverInfoObject.personalUseCMV) {
        driverInfoObject.personalUseCMVChangeAcknowledged = false;
      }

      this.props.editDriverFormHandler(driverInfoObject, this.state.sendTextToDriver).then(
        () => {
          const newState = { ...this.state, isLoading: false };
          this.setState(newState);
          this.handleClose();
        },
        (error) => {
          const newState = { ...this.state, isLoading: false };
          newState.error = error;
          this.setState(newState);
        }
      );
    });
  }

  handleClose() {
    if (this.state.driverModuleObj) this.state.driverModuleObj.revert();

    this.refreshState(this.props).then(() => {
      this.props.handleClose();
    });
  }

  render() {
    const { dispatchers } = this.props;
    const { driverInfo, driverModuleInfo, driver, driverUser, isLoading } = this.state;

    let showEldOptions = false;
    if (Helpers.isSubscribedToModule('eldModule')) {
      showEldOptions = true;
    }

    const dispatcherDropdownItems = dispatchers.filter(dispatcher => dispatcher.get('user')).map(dispatcher => {
      const dispatcherUser = dispatcher.get('user');
      return { attrName: dispatcher.id, placeholder: `${dispatcherUser.get('firstName')} ${dispatcherUser.get('lastName')}` };
    });

    let noUserDispatcherCounter = 0;
    let dispatcherSelectors = [];

    if (driver.get('dispatchers')[0] !== null) {
      // exception when mobile adds driver check
      dispatcherSelectors = driver.get('dispatchers').map((dispatcher, index) => {
        const dispatcherUser = dispatcher.get('user');
        let title = 'N/A';
        if (dispatcherUser && dispatcherUser.id) {
          title = `${dispatcherUser.get('firstName')} ${dispatcherUser.get('lastName')}`;
        } else {
          noUserDispatcherCounter++;
        }

        return (
          <FilterDropdown
            key={dispatcher.id}
            id={`dispatcher-${dispatcher.id}`}
            title={title}
            handleFilter={(dispatcherId) => this.editDispatchers(dispatcherId, index)}
            menuItems={dispatcherDropdownItems}
            disabled={!(dispatcherUser && dispatcherUser.id)}
            className="notranslate"
          />
        );
      });
    }

    if (!driver.get('dispatchers') || driver.get('dispatchers').length === 0 || noUserDispatcherCounter > 0 || driver.get('dispatchers')[0] === null) {
      dispatcherSelectors = (
        <FilterDropdown
          id={'dispatcher'}
          title={t('Select Dispatcher')}
          handleFilter={(dispatcherId) => this.editDispatchers(dispatcherId, 0)}
          menuItems={dispatcherDropdownItems}
          className="notranslate"
        />
      );
    }

    window.Localize.translatePage();

    let className = `edit-driver-details-modal ${styles.editDriverDetailsModal}`;
    if (this.state.isAddressDialogOpen) { // hides the driver details dialog in favour of another dialog
      className += ` ${styles.hideModal}`;
    }

    return (
      <Modal className={className} show={this.props.show} onHide={this.props.handleClose} animation={false} backdropClassName={styles.editDriverDetailsModalBackdrop}>
        <Modal.Header>
          <Modal.Title>{driverUser.get('firstName')} {driverUser.get('lastName')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className={styles.inputGroup}>
            <span>
              <label className={'inputLabel'}>{t('E-mail Address')}</label>
              <input
                className={'input'}
                id="email"
                type="text"
                value={driverInfo.email}
                placeholder="....."
                onChange={this.handleChangeDriverInfo}
                autoComplete="off"
              />
            </span>
            <span>
              <label className={'inputLabel'}>{t('Phone Number')}</label>
              <input
                className={'input'}
                id="phoneNumber"
                type="text"
                value={driverInfo.phoneNumber}
                placeholder="....."
                onChange={this.handleChangeDriverInfo}
                autoComplete="off"
              />
            </span>
            <span>
              <label className={'inputLabel'}>{t('username')}</label>
              <input
                className={'input'}
                id="username"
                type="text"
                value={driverInfo.username}
                placeholder="....."
                onChange={this.handleChangeDriverInfo}
                autoComplete="off"
              />
            </span>
          </div>

          <div className={`${styles.inputGroup}`}>
            <span>
              <label className={'inputLabel'}>{t('Driver\'s License Number')}</label>
              <input
                className={'input'}
                id="driversLicense"
                type="text"
                value={driverInfo.driversLicense}
                placeholder="....."
                onChange={this.handleChangeDriverInfo}
                autoComplete="off"
              />
            </span>
            <span>
              <label className={'inputLabel'}>{t('License Jurisdiction')}</label>
              <select id="driversLicenseCountry" className="input" onChange={this.handleChangeDriverInfo} value={driverInfo.driversLicenseCountry}>
                <option value="CA">{t('Canada')}</option>
                <option value="US">{t('United States')}</option>
                <option value="MX">{t('Mexico')}</option>
              </select>
            </span>
            <span>
              <select id="driversLicenseJurisdiction" className="input" onChange={this.handleChangeDriverInfo} value={driverInfo.driversLicenseJurisdiction}>
                {
                  this.state.stateProvinces.map(stateProvince => (
                    <option
                      key={`${stateProvince.code}${stateProvince.country}`}
                      value={stateProvince.code}
                    >
                      {`${stateProvince.code} - ${stateProvince.name}`}
                    </option>
                  ))
                }
              </select>
            </span>
          </div>

          <MDBRow className={`${styles.inputGroup}`}>
            <MDBCol size="3" className="pl-0">
              <label className={'inputLabel'}>{t('License Expiry Date')}</label>
              <DatePicker
                className="input"
                showMonthDropdown
                showYearDropdown
                id="licenseExpiryDate"
                dateFormat="MMM dd, yyyy"
                selected={driverInfo.licenseExpiryDate || null}
                placeholderText="....."
                onChange={(date) => this.handleChangeDriverInfo(null, 'licenseExpiryDate', date)}
              />
            </MDBCol>
            <MDBCol size="3" className="ml-2">
              <label className={'inputLabel'}>{t('Medical Expiry Date')}</label>
              <DatePicker
                className="input"
                showMonthDropdown
                showYearDropdown
                id="medicalCheckDate"
                dateFormat="MMM dd, yyyy"
                selected={driverInfo.medicalCheckDate || null}
                placeholderText="....."
                onChange={(date) => this.handleChangeDriverInfo(null, 'medicalCheckDate', date)}
              />
            </MDBCol>
          </MDBRow>

          <div className={`${styles.inputGroup}`}>
            <span>
              <label className={'inputLabel'}>{t('Driver\'s DOT Number')}</label>
              <input
                className={'input'}
                id="dotNumber"
                type="text"
                value={driverInfo.dotNumber}
                placeholder="....."
                onChange={this.handleChangeDriverInfo}
                autoComplete="off"
              />
            </span>

            <span>
              <label className={'inputLabel'}>{t('Driver\'s NSC Number')}</label>
              <input
                className={'input'}
                id="nscNumber"
                type="text"
                value={driverInfo.nscNumber}
                placeholder="....."
                onChange={this.handleChangeDriverInfo}
                autoComplete="off"
              />
            </span>
          </div>

          <hr />

          <div className={`${styles.inputGroup} ${styles.timezonepicker}`}>
            <span>
              <label className={'inputLabel'}>{t('Driver\'s Time Zone - Driver Logs are set/retrieved using this Time Zone')}</label>
              <TimezonePicker
                className={styles.timezoneInput}
                value={this.state.driverInfo.timezoneOffsetFromUTC}
                onChange={timezone => this.handleChangeDriverInfo(timezone, 'timezoneOffsetFromUTC', timezone)}
                inputProps={{
                  placeholder: 'Select a Time Zone',
                  name: 'timezone',
                }}
              />
            </span>
          </div>

          <div className={styles.inputGroup}>
            <span>
              <label className={'inputLabel notranslate'}>{t('Dispatchers')}</label>
              { dispatcherSelectors }
            </span>
          </div>

          {/* copying styles.inputGroup instead of using the className because it overrides properties in the vehicle autocomplete */}
          <div className="flex justify-content-between" style={{ marginTop: '4em', marginBottom: '4em', marginLeft: '1em', paddingLeft: '1.5em', paddingRight: '1.5em', width: '95%' }}>
            <div>
              <label className={'inputLabel'}>{t('Default Vehicle')}</label>
              <VehicleAutocomplete
                panelClassName={styles.editDriverDetailsModalVehicleDropdown}
                vehicle={this.state.driverInfo.defaultVehicle}
                onSelectVehicle={vehicle => this.handleChangeDriverInfo(vehicle, 'defaultVehicle', vehicle)}
                onUnselectVehicle={vehicle => this.handleChangeDriverInfo(undefined, 'defaultVehicle', undefined)}
                suggestionFilters={[new Filter(QueryRestriction.EQUAL_TO, 'enabled', true)]}
                hideLabel
              />
            </div>
            <div>
              <label className={'inputLabel'}>{t('Home Terminal')}</label>
              <AddressDropdown
                className={styles.driverAddress}
                allowAddAddress
                onSelectAddress={(address) => this.handleChangeDriverInfo(address, 'altTerminalAddress', address)}
                onToggleAddressDialog={({ show }) => this.setState({ isAddressDialogOpen: show })}
                address={this.state.driverInfo.altTerminalAddress}
                isCompanyTerminal
                hideLabel
                panelClassName={styles.editDriverAddressDropdown}
              />
            </div>
          </div>

          <hr />

          <div className={`${styles.inputGroup} ${styles.timezonepicker}`}>
            <span>
              <label className={'inputLabel'}>{t('HOS Cycle Details')}</label>
              <MDBRow className="w-100 pt-3">
                <MDBCol className="w-50">
                  {this.state.displayCADCycleEdit && (
                    <ListSelectField
                      className="w-100"
                      floatingLabelText={t('Canadian HOS Cycle')}
                      value={this.state.driverInfo.hourCycleCanadaInt && canadaCycles[this.state.driverInfo.hourCycleCanadaInt]}
                      onChange={(e, index, newValue) => this.handleChangeDriverInfo(index + 1, 'hourCycleCanadaInt', getReferenceIntFromDescription(0, newValue) ? parseInt(getReferenceIntFromDescription(0, newValue)) : undefined)}
                      list={Object.values(canadaCycles)}
                      underlineFocusStyle={{ borderColor: '#4285f4' }}
                    />
                  )}
                  {!this.state.displayCADCycleEdit && (
                    <div>{t('Not enough consecutive hours of off-duty to edit CAN HOS Cycle')}</div>
                  )}
                </MDBCol>
                <MDBCol className="w-50">
                  {this.state.displayUSACycleEdit && (
                    <ListSelectField
                      className="w-100"
                      floatingLabelText={t('US HOS Cycle')}
                      value={this.state.driverInfo.hourCycleUSAInt && usCycles[this.state.driverInfo.hourCycleUSAInt]}
                      onChange={(e, index, newValue) => this.handleChangeDriverInfo(null, 'hourCycleUSAInt', getReferenceIntFromDescription(1, newValue) ? parseInt(getReferenceIntFromDescription(1, newValue)) : undefined)}
                      list={Object.values(usCycles)}
                      underlineFocusStyle={{ borderColor: '#4285f4' }}
                    />
                  )}
                  {!this.state.displayUSACycleEdit && (
                    <div>{t('Not enough consecutive hours of off-duty to edit USA HOS Cycle')}</div>
                  )}
                </MDBCol>
              </MDBRow>

              <div className={`${styles.inputGroup} p-0`} style={{ marginTop: '.5em', marginLeft: '0em' }}>
                <span className={`checkboxInline ${styles.checkboxInline}`}>
                  <input id="disabledCACycleHourSelect"
                    type="checkbox"
                    onChange={() => {
                      this.state.driverModuleObj && this.state.driverModuleObj.set('disabledCACycleHourSelect', !this.state.driverModuleObj.get('disabledCACycleHourSelect'));
                      this.setState({ ...this.state })
                    }}
                    checked={this.state.driverModuleObj && this.state.driverModuleObj.get('disabledCACycleHourSelect')}
                  />
                  <span>{t('Disallow Driver Change of CAN Cycle')}</span>
                </span>
              </div>

            </span>
          </div>

          <hr />

          <div className={styles.inputGroup}>
            <span className="textareaLabel">{t('Notes')}</span>
            <textarea
              className="textarea"
              id="notes"
              value={driverInfo.notes}
              placeholder="....."
              onChange={this.handleChangeDriverInfo}
            />
          </div>

          <hr />

          <div className={styles.inputGroup}>

            <span>
              <label className={'inputLabel'}>{t('password')}</label>
              <input
                className={'input'}
                id="password"
                type="text"
                value={driverInfo.password}
                placeholder="....."
                onChange={this.handleChangeDriverInfo}
                autoComplete="off"
              />
            </span>
            {(driverInfo.password && (driverInfo.password.length > 0)) && (
              <span className={`checkboxInline ${styles.checkboxInline} w-full`}>
                <input id="sendTextToDriver" type="checkbox" onChange={() => this.setState({ sendTextToDriver: !this.state.sendTextToDriver })} checked={this.state.sendTextToDriver} />
                <span>{t('Send Password to Driver')}</span>
              </span>
            )}
          </div>

          {showEldOptions &&
            <div className={`${styles.inputGroup} translate-me`}>
              <span className={`checkboxInline ${styles.checkboxInline}`}>
                <input id="exempt" type="checkbox" onChange={() => this.handleChangeDriverInfo(null, 'exempt', !driverInfo.exempt)} checked={!!driverInfo.exempt} />
                <span>{t('Exempt Driver')}</span>
              </span>
              <span className={`checkboxInline ${styles.checkboxInline}`}>
                <input id="yardMoves" type="checkbox" onChange={() => this.handleChangeDriverInfo(null, 'yardMoves', !driverInfo.yardMoves)} checked={!!driverInfo.yardMoves} />
                <span>{t('Yard Moves')}</span>
              </span>
              <span className={`checkboxInline ${styles.checkboxInline}`}>
                <input id="personalUseCMV" type="checkbox" onChange={() => this.handleChangeDriverInfo(null, 'personalUseCMV', !driverInfo.personalUseCMV)} checked={!!driverInfo.personalUseCMV} />
                <span>{t('Personal Use CMV')}</span>
              </span>
              <br />
              <br />
              <span className={`checkboxInline ${styles.checkboxInline}`}>
                <input
                  id="disabledELDEdit"
                  type="checkbox"
                  onChange={() => { this.state.driverModuleObj && this.state.driverModuleObj.set('disabledELDEdit', !this.state.driverModuleObj.get('disabledELDEdit')); this.setState({ ...this.state })}}
                  checked={this.state.driverModuleObj && this.state.driverModuleObj.get('disabledELDEdit')}
                />
                <span>{t('Disable Edits')}</span>
              </span>
            </div>
          }
          {driverInfo.exempt &&
            <div className={`${styles.inputGroup}`}>
              <span className="textareaLabel">
                <span>{t('Exemption Reason (Mandatory Field for Exempt Drivers)')}</span>
                <textarea
                  id="exemptionReason"
                  className="textarea"
                  value={driverInfo.exemptionReason}
                  onChange={this.handleChangeDriverInfo}
                  placeholder={t('Reason for the exemption...')}
                />
              </span>
            </div>
          }
          {this.state.error &&
            <div className={`${styles.error} translate-me`}>{this.state.error.message}</div>
          }

          <hr />

          <div className={`${styles.inputGroup} flex flex-row column-gap-7`}>
            <div className="flex flex-column row-gap-3">
              <span>{t(`PC Country Restriction`)}</span>
              <Dropdown
                panelClassName={styles.pcymDropdown}
                options={this.state.disablePCYMCountryOptions}
                value={this.state.driverModuleObj && this.state.driverModuleObj.get('disablePCCountryInt')}
                onChange={(e) => {
                  this.state.driverModuleObj && this.state.driverModuleObj.set('disablePCCountryInt', e.value);
                  this.setState({ ...this.state });
                }}
              />
            </div>
            <div className="flex flex-column row-gap-3">
              <span>{t(`YM Country Restriction`)}</span>
              <Dropdown
                panelClassName={styles.pcymDropdown}
                options={this.state.disablePCYMCountryOptions}
                value={this.state.driverModuleObj && this.state.driverModuleObj.get('disableYMCountryInt')}
                onChange={(e) => {
                  this.state.driverModuleObj && this.state.driverModuleObj.set('disableYMCountryInt', e.value);
                  this.setState({ ...this.state });
                }}
              />
            </div>
          </div>

        </Modal.Body>

        {(isLoading) &&
          <LoadingOverlay />
        }

        <Modal.Footer>
          <span className="errorText">
            { this.state.error }
          </span>
          <span>
            <button className={'buttonDefault closeButton'} onClick={this.handleClose}>{t('Cancel')}</button>
            <button className={'buttonDefault'} onClick={this.updateDriver} disabled={this.state.disableUpdateDriver}>{t('Update Info')}</button>
          </span>
        </Modal.Footer>
      </Modal>
    );
  }
}

EditDriverDetailsModal.propTypes = {
  editDriverFormHandler: PropTypes.func.isRequired,
  driver: PropTypes.object.isRequired,
  driverModule: PropTypes.object.isRequired,
  dispatchers: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default EditDriverDetailsModal;
