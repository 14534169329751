import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';

// components
import DriverGroupCard from 'components/DriverGroup/DriverGroupCard/DriverGroupCard';

// sbCore
import InputText from 'sbCore/InputText/InputText';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import Dropdown from 'sbCore/Dropdown/Dropdown';

// api
import { getDriverGroups, getIndividualDriverGroups } from 'api/Driver/DriverGroup';

// sb-csapi
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { Action } from 'sb-csapi/dist/enums/Action';
import { formatName } from 'sb-csapi/dist/utils/String';

// styling
import './style.scss';

/**
 * @description Renders the container which holds driver group cards
 */
function DriverGroupContainer({ ...props }) {
  const [driverGroupName, setDriverGroupName] = useState('');
  const [driverName, setDriverName] = useState('');
  const [sortBy, setSortBy] = useState('Last Updated');
  const [driverGroups, setDriverGroups] = useState([]); // true state of the driver groups
  const [filteredDriverGroups, setFilteredDriverGroups] = useState([]); // driver groups to display after filter and sort
  const [isRefresh, setIsRefresh] = useState(false);

  const sortByOptions = [
    { value: 'Last Updated', icon: 'update' },
    { value: 'Group Name', icon: 'groups'}
  ]

  function triggerRefresh() {
    setIsRefresh(!isRefresh);
  }

  /* ------ useEffects ------ */
  useEffect(() => {
    let didCancel = false;

    async function fetchDriverGroups() {
      const _driverGroups = await getDriverGroups(
        undefined, // filters
        undefined, // sortBy
      )

      const parsedDriverGroups = _driverGroups.map(driverGroup => {
        const groupName = getAttribute(driverGroup, 'name');
        const objectId = getAttribute(driverGroup, 'objectId');

        // get user full name
        const createdByParseObj = getAttribute(driverGroup, 'createdBy');
        const firstName = getAttribute(createdByParseObj, 'firstName');
        const lastName = getAttribute(createdByParseObj, 'lastName');
        const createdBy = `${firstName} ${lastName}`;
        const updatedAt = getAttribute(driverGroup, 'updatedAt');

        const numberOfDrivers = getAttribute(driverGroup, 'numberOfDrivers');

        return {
          groupName,
          objectId,
          createdBy: formatName(createdBy),
          numberOfDrivers,
          lastUpdated: updatedAt,
        }
      });

      if (!didCancel) setDriverGroups(parsedDriverGroups);
    }

    fetchDriverGroups();

    return () => { didCancel = true; };

  }, [isRefresh]);

  // handle filtering for driver name
  useEffect(() => {
    let didCancel = false;

    async function _getIndividualDriverGroups(driverName) {
      const individualDriverGroups = await getIndividualDriverGroups(driverName);

      const parsedDriverGroups = individualDriverGroups.map(driverGroup => {
        const groupName = getAttribute(driverGroup, 'name');
        const objectId = getAttribute(driverGroup, 'objectId');

        // get user full name
        const createdByParseObj = getAttribute(driverGroup, 'createdBy');
        const firstName = getAttribute(createdByParseObj, 'firstName');
        const lastName = getAttribute(createdByParseObj, 'lastName');
        const createdBy = `${firstName} ${lastName}`;
        const updatedAt = getAttribute(driverGroup, 'updatedAt');

        const numberOfDrivers = getAttribute(driverGroup, 'numberOfDrivers');

        return {
          groupName,
          objectId,
          createdBy: formatName(createdBy),
          numberOfDrivers,
          lastUpdated: updatedAt,
        }
      });

      // Filter group name by search value - not case sensitive, and trim search value
      const _filteredDriverGroups = parsedDriverGroups.filter(driverGroup => driverGroup.groupName.toLowerCase().includes(driverGroupName.trim().toLowerCase()));

      // Then sort the groups
      if (sortBy === 'Group Name') {
        _filteredDriverGroups.sort((driverGroupA, driverGroupB) => {
          const groupNameA = driverGroupA.groupName.toLowerCase();
          const groupNameB = driverGroupB.groupName.toLowerCase();

          if (groupNameA < groupNameB) return -1;
          if (groupNameA > groupNameB) return 1;

          return 0;
        })
      } else if (sortBy === 'Last Updated') {
        _filteredDriverGroups.sort((driverGroupA, driverGroupB) => {
          return moment(driverGroupB.lastUpdated).valueOf() - moment(driverGroupA.lastUpdated).valueOf();
        })
      }
        
      if (!didCancel) setFilteredDriverGroups(_filteredDriverGroups);
    }

    const _driverName = driverName && driverName.trim().toLowerCase();

    if (_driverName) {
      _getIndividualDriverGroups(_driverName);
    } else {
      triggerRefresh();
    }

    return () => { didCancel = true; };
  }, [driverName, driverGroupName, sortBy, driverGroups]);
  
  // handle filtering and sorting for group name
  useEffect(() => {
    let didCancel = false;

    // Filter group name by search value - not case sensitive, and trim search value
    const _filteredDriverGroups = driverGroups.filter(driverGroup => driverGroup.groupName.toLowerCase().includes(driverGroupName.trim().toLowerCase()));

    // Then sort the groups
    if (sortBy === 'Group Name') {
      _filteredDriverGroups.sort((driverGroupA, driverGroupB) => {
        const groupNameA = driverGroupA.groupName.toLowerCase();
        const groupNameB = driverGroupB.groupName.toLowerCase();

        if (groupNameA < groupNameB) return -1;
        if (groupNameA > groupNameB) return 1;

        return 0;
      })
    } else if (sortBy === 'Last Updated') {
      _filteredDriverGroups.sort((driverGroupA, driverGroupB) => {
        return moment(driverGroupB.lastUpdated).valueOf() - moment(driverGroupA.lastUpdated).valueOf();
      })
    }
      
    if (!didCancel) setFilteredDriverGroups(_filteredDriverGroups);

    return () => { didCancel = true; };

  }, [driverGroupName, sortBy, driverGroups]);

  /* ------ Template ------ */
  const sortByTemplate = (option) => {
    return (
      <div>
        <span className="material-icons mr-2">{option.icon}</span>
        {option.value}
      </div>
    )
  }

  return (
    <div className="driver-group-container">
      <div className="flex mb-4 column-gap-3">
        <div className="w-2">
          <InputLabel>Search By Group Name</InputLabel>
          <span className="p-input-icon-left w-full">
            <i className="pi pi-search" />
            <InputText
              placeholder="Group Name"
              className="w-full"
              value={driverGroupName}
              onChange={(e) => setDriverGroupName(e.target.value)}
            />
          </span>
        </div>
        <div className="w-2">
          <InputLabel>Search By Driver Name</InputLabel>
          <span className="p-input-icon-left w-full">
            <i className="pi pi-search" />
            <InputText
              placeholder="Driver Name"
              className="w-full"
              value={driverName}
              onChange={(e) => setDriverName(e.target.value)}
            />
          </span>
        </div>
        <div>
          <InputLabel>Sort By</InputLabel>
          <Dropdown
            value={sortBy}
            options={sortByOptions}
            optionLabel="value"
            tooltip={sortByOptions.find((option) => option.value === sortBy)?.value}
            onChange={(e) => setSortBy(e.value)}
            itemTemplate={sortByTemplate}
            valueTemplate={sortByTemplate}
          />
        </div> 
      </div>
      <div className="grid">
        {/* "Add" group card */}
        <DriverGroupCard
          type={Action.ADD}
          triggerRefresh={() => triggerRefresh()}
        />
        {/* driver group summary card */}
        {driverGroups && filteredDriverGroups.map((driverGroup) =>
          <DriverGroupCard
            driverGroup={driverGroup}
            type={Action.EDIT}
            triggerRefresh={() => triggerRefresh()}
          />
        )}
      </div>
    </div>
  );
}

export default DriverGroupContainer;
