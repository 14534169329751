import * as ActionConstants from './ActionConstants';

import * as Getters from 'api/Getters';
import * as Helpers from 'api/Helpers';
import * as Parse from 'api/Parse';
import * as Setters from 'api/Setters';
import * as Destroyers from 'api/Destroyers';
import store from 'store';

export function fetchingGeofenceEvents() {
  return {
    type: ActionConstants.FETCH_GEOFENCEEVENTS_INPROGRESS,
  };
}

export function fetchGeofenceEventsSuccess(geofenceEvents) {
  return {
    type: ActionConstants.FETCH_GEOFENCEEVENTS_SUCCESS,
    geofenceEvents,
  };
}

export function fetchGeofenceEventsError(error) {
  return {
    type: ActionConstants.FETCH_GEOFENCEEVENTS_ERROR,
    error,
  };
}

export function addingGeofenceEvent() {
  return {
    type: ActionConstants.ADD_GEOFENCEEVENT_INPROGRESS,
  };
}

export function addGeofenceEventSuccess(geofenceEvent) {
  return {
    type: ActionConstants.ADD_GEOFENCEEVENT_SUCCESS,
    geofenceEvent,
  };
}

export function addGeofenceEventError(error) {
  return {
    type: ActionConstants.ADD_GEOFENCEEVENT_ERROR,
    error,
  };
}

export function deleteGeofenceEvents() {
  return {
    type: ActionConstants.DELETE_GEOFENCEEVENTS,
  };
}

export function destroyGeofenceEvents(geofenceEventParseArr) {
  return {
    type: ActionConstants.DESTROY_GEOFENCEEVENTS,
    geofenceEvents: geofenceEventParseArr,
  };
}

export function updatingGeofenceEvent(geofenceEvent) {
  return {
    type: ActionConstants.UPDATE_GEOFENCEEVENT_INPROGRESS,
    updateReference: geofenceEvent,
  };
}

export function updateGeofenceEventSuccess() {
  return {
    type: ActionConstants.UPDATE_GEOFENCEEVENT_SUCCESS,
  };
}

export function updateGeofenceEventError(error) {
  return {
    type: ActionConstants.UPDATE_GEOFENCEEVENT_ERROR,
    error,
  };
}

export function getGeofenceEventIncludeArr() {
  return ['geofence', 'vehicle', 'vehicle.licensePlate'];
}

export function fetchGeofenceEventsForState(page, limit, filter, sortBy) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingGeofenceEvents());
    Getters.queryCompanyObjects('GeofenceEvent', page, limit, filter, sortBy, getGeofenceEventIncludeArr()).then(
      geofenceEvents => {
        store.dispatch(fetchGeofenceEventsSuccess(geofenceEvents));
        resolve(geofenceEvents);
      },
      error => {
        store.dispatch(fetchGeofenceEventsError(error));
        reject(error);
      }
    );
  });
  return promise;
}

export function fetchLinkedGeofenceEventsForState(page, limit, filter, sortBy) {
  const promise = new Promise((resolve, reject) => {
    store.dispatch(fetchingGeofenceEvents());
    
    Getters.getLinkedObjects(page, limit, filter, sortBy, 'GeofenceEvent').then(
      geofenceEvents => {
        console.log(geofenceEvents);
        store.dispatch(fetchGeofenceEventsSuccess(geofenceEvents));
        resolve(geofenceEvents);
      },
      error => {
        store.dispatch(fetchGeofenceEventsSuccess(error));
        reject(error);
      }
    );
  });
  return promise;
}

export function addGeofenceEventToState(geofenceEventParseObj) {
  return store.dispatch(addGeofenceEventSuccess(geofenceEventParseObj));
}

export function destroyGeofenceEvent(geofenceEventParseObj) {
  store.dispatch(destroyGeofenceEvents([geofenceEventParseObj]));
  const promise = new Promise((resolve, reject) => {
    Parse.destroyRecords([geofenceEventParseObj]).then(() => {
      resolve();
    });
  });
  return promise;
}

export function deleteGeofenceEventsForState() {
  const promise = new Promise(resolve => {
    store.dispatch(deleteGeofenceEvents());
    resolve(store.getState().GeofenceEvent);
  });
  return promise;
}

export function updateGeofenceEventForState(geofenceEventParseObj) {
  store.dispatch(updateGeofenceEventSuccess(geofenceEventParseObj));
}
