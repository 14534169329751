// Api
import { getAttribute, createQuery, setQueryRestriction, find, sortQuery } from 'api/Parse';

// Enums
import { QueryRestrictionTypes, QuerySortOrderTypes } from 'enums/Query';
import { ELDEventTypeCode } from 'enums/ELDEventTypeCode';
import { ELDEventRecordStatus } from 'enums/ELDEventRecordStatus';

/** @module CycleHourUSA */

/**
 * @memberof module:CycleHourUSA
 * 
 * @param {Record} driver 
 */
export async function isAllowedChangeCycles(driver) {
  const cycleDutyStatusesToCheck = [   // These are the statuses we want to pull events for to check what the most recent duty event is
    ELDEventTypeCode.OFF_DUTY,
    ELDEventTypeCode.SLEEPER_BERTH,
    ELDEventTypeCode.DRIVING,
    ELDEventTypeCode.ON_DUTY_NOT_DRIVING,
    ELDEventTypeCode.AUTHORIZED_PERSONAL_USE_OF_CMV,
    ELDEventTypeCode.YARD_MOVES,
  ];

  const eldEventQuery = createQuery('ELDEvent');
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'driver', getAttribute(driver, 'objectId'));
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.CONTAINED_IN, 'eldEventTypeCodeInt', cycleDutyStatusesToCheck); // Check for most relevant statuses
  setQueryRestriction(eldEventQuery, QueryRestrictionTypes.EQUAL_TO, 'eldEventRecordStatusInt', ELDEventRecordStatus.ACTIVE); // Check for most recent active event
  sortQuery(eldEventQuery, QuerySortOrderTypes.DESCENDING, 'eventDateTime');
  const mostRecentDutyStatusELDEvent = await find(eldEventQuery, true); // Obtain the most recent active driver event

  // If the most recent event is off duty, we can determine how long the driver has been off-duty
  if (mostRecentDutyStatusELDEvent && getAttribute(mostRecentDutyStatusELDEvent, 'eldEventTypeCodeInt') === ELDEventTypeCode.OFF_DUTY) {
    const currentDateInMilliseconds = new Date().getTime();
    const mostRecentDutyStatusELDEventDateInMilliseconds = getAttribute(mostRecentDutyStatusELDEvent, 'eventDateTime').getTime();
    const calculatedOffDutyTimeInHours = ((currentDateInMilliseconds - mostRecentDutyStatusELDEventDateInMilliseconds) / (1000 * 60 * 60));
    const hourCycleUSAInt = getAttribute(driver, 'hourCycleUSAInt');

    if (calculatedOffDutyTimeInHours >= 34) { // Driver must have been off duty for at least 34 consecutive hours
      return true;
    }
  }
  return false;
}
