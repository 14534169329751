import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// Api
import { migrateIFTARoute, getTimezoneString } from 'api/IFTARoute/IFTARoute.old';
import { getAttribute } from 'api/Parse';

// Components
import { MDBBtn, MDBIcon, MDBModal, MDBModalHeader, MDBModalBody, MDBModalFooter, MDBRow, MDBCol } from 'mdbreact';
import Button from 'sbCore/Button/Button';
import VehicleAutocomplete from 'components/Shared/VehicleAutocomplete/VehicleAutocomplete';
import DateTimePicker from 'components/Shared/DateTimePicker/DateTimePicker';

class MigrateRoutes extends React.Component {
  constructor(props) {
    super(props);
    const driverTimeZoneStr = (props.iftaRoutes && props.iftaRoutes.length > 0 && getTimezoneString(props.iftaRoutes[0]))
      || moment.tz.guess();
    // These two are in the local timezone of the user as they originate from a datePicker
    const momentIFTAStart = driverTimeZoneStr
      ? moment(props.dateStart).tz(driverTimeZoneStr)
      : moment(props.dateStart);
    const momentIFTAEnd = driverTimeZoneStr
      ? moment(props.dateEnd).endOf('month').tz(driverTimeZoneStr)
      : moment(props.dateEnd).endOf('month');

    this.state = {
      modalIsOpen: false,
      migrationError: false,
      startIsValid: true,
      endIsValid: true,
      datesAreValid: true,
      newVehicleForRoute: undefined,
      selectedStart: momentIFTAStart,
      selectedEnd: momentIFTAStart.clone().add(13, 'days'),
      iftaRoutesToMigrate: [],
      driverTimeZoneStr,
      momentIFTAStart,
      momentIFTAEnd,
    };

    this.pickerTypes = Object.freeze({ start: 'start', end: 'end' });
    this.toggleModal = this.toggleModal.bind(this);
    this.handleVehicleSelection = this.handleVehicleSelection.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.migrateRoutes = this.migrateRoutes.bind(this);
    this.validateDates = this.validateDates.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { props } = this;
    // Last condition is for if we change to a different vehicle being viewed
    if (prevProps.dateStart !== props.dateStart || prevProps.dateEnd !== props.dateEnd || prevProps.unitId !== props.unitId) {
      const driverTimeZoneStr = props.iftaRoutes && props.iftaRoutes.length > 0 && getTimezoneString(props.iftaRoutes[0]);
      // These two are in the local timezone of the user as they originate from a datePicker
      const momentIFTAStart = driverTimeZoneStr
        ? moment(props.dateStart).tz(driverTimeZoneStr)
        : moment(props.dateStart);
      const momentIFTAEnd = driverTimeZoneStr
        ? moment(props.dateEnd).endOf('month').tz(driverTimeZoneStr)
        : moment(props.dateEnd).endOf('month');
      this.setState({
        driverTimeZoneStr,
        momentIFTAStart,
        momentIFTAEnd,
        selectedStart: momentIFTAStart,
        selectedEnd: momentIFTAStart.clone().add(13, 'days'),
        iftaRoutesToMigrate: [],
        newVehicleForRoute: undefined,
      });
    }
  }

  handleVehicleSelection(vehicle) {
    this.setState({ newVehicleForRoute: vehicle });
  }

  handleDateChange(type, dateValue) {
    const { state } = this;
    // The true keeps the time and changes the timezone
    const dateInDriverTimeZone = moment(dateValue).tz(state.driverTimeZoneStr, true);
    // Set the according state field
    if (type === this.pickerTypes.start) {
      this.setState({ selectedStart: dateInDriverTimeZone }, () => this.validateDates());
    } else if (type === this.pickerTypes.end) {
      this.setState({ selectedEnd: dateInDriverTimeZone }, () => this.validateDates());
    }
  }

  getRoutesWithinSelectedPeriod() {
    const { driverTimeZoneStr, selectedStart, selectedEnd } = this.state;
    const { iftaRoutes } = this.props;

    if (!driverTimeZoneStr || !selectedStart || !selectedEnd) {
      return [];
    }
    const iftaRoutesToMigrate = [];

    iftaRoutes.forEach((route) => {
      const dateStartUTC = getAttribute(route, 'dateStart', true);
      const dateStart = dateStartUTC && moment(dateStartUTC).tz(driverTimeZoneStr);

      const dateEndUTC = getAttribute(route, 'dateEnd', true);
      const dateEnd = dateEndUTC && moment(dateEndUTC).tz(driverTimeZoneStr);

      if (dateStart && !dateStart.isBefore(selectedStart) && dateEnd && !dateEnd.isAfter(selectedEnd)) {
        iftaRoutesToMigrate.push(route);
      }
    });
    return iftaRoutesToMigrate;
  }

  async migrateRoutes() {
    const { props, state } = this;

    const iftaRouteMigrationPromises = state.iftaRoutesToMigrate.map(route => migrateIFTARoute(route, state.newVehicleForRoute));
    try {
      await Promise.all(iftaRouteMigrationPromises);
      this.toggleModal(false);
      props.refreshState();
    } catch (e) {
      this.setState({ migrationError: true });
    }
  }

  toggleModal(bool) {
    this.setState({ modalIsOpen: bool });
    if (!bool) {
      const { momentIFTAStart } = this.state;
      this.setState({
        startIsValid: true,
        endIsValid: true,
        datesAreValid: true,
        migrationError: false,
        newVehicleForRoute: undefined,
        selectedStart: momentIFTAStart,
        selectedEnd: momentIFTAStart.clone().add(13, 'days'),
        iftaRoutesToMigrate: [],
      });
    }
  }

  validateDates() {
    const { selectedStart, selectedEnd, momentIFTAStart, momentIFTAEnd } = this.state;
    // Do boundary checks
    const startIsValid = !selectedStart.isBefore(momentIFTAStart);
    const endIsValid = !selectedEnd.isAfter(momentIFTAEnd);
    // Check they do not cross over and they are within two weeks of each other, 15 is used as exactly two weeks throws false
    const twoWeeksMaxRange = Math.abs(selectedEnd.diff(selectedStart, 'days')) + 1 <= 15;
    const datesAreValid = selectedStart.isBefore(selectedEnd) && twoWeeksMaxRange; // Must be strictly less than

    let iftaRoutesToMigrate = [];
    if (startIsValid && endIsValid && datesAreValid) iftaRoutesToMigrate = this.getRoutesWithinSelectedPeriod();

    this.setState({ startIsValid, endIsValid, datesAreValid, iftaRoutesToMigrate });
  }

  render() {
    const { props, state, pickerTypes } = this;
    const { unitId } = props;
    const { momentIFTAStart, momentIFTAEnd, newVehicleForRoute, startIsValid, endIsValid, datesAreValid } = state;

    const everythingValid = newVehicleForRoute && startIsValid && endIsValid && datesAreValid;
    return (
      <div style={{ display: 'inline-block' }}>
        <Button
          text
          sbVariant="short"
          onClick={() => this.toggleModal(true)}
          label={(
            <span>
              <MDBIcon icon="exchange-alt" className="mr-2" />
              Migrate Routes
            </span>
          )}
          disabled={!props.iftaRoutes || props.iftaRoutes.length === 0}
        />
        <MDBModal centered isOpen={state.modalIsOpen}>
          <MDBModalHeader toggle={() => this.toggleModal(false)}>{`Migrate routes for ${unitId}`}</MDBModalHeader>
          <MDBModalBody>
            <MDBRow>
              <MDBCol>
                {`Select a two week period by selecting a start and end date and time within the current interval ${
                  momentIFTAStart.format('LL')} - ${momentIFTAEnd.format('LL')}`}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <DateTimePicker
                  label="Start Date"
                  isRequired
                  showTimePicker
                  selectedDate={(state.selectedStart || momentIFTAStart).toDate()}
                  labelClassName="active"
                  onDateChange={(date) => this.handleDateChange(pickerTypes.start, date)}
                />
              </MDBCol>
              <MDBCol>
                <DateTimePicker
                  label="End Date"
                  isRequired
                  showTimePicker
                  selectedDate={(state.selectedEnd || momentIFTAEnd).toDate()}
                  labelClassName="active"
                  onDateChange={(date) => this.handleDateChange(pickerTypes.end, date)}
                />
              </MDBCol>
            </MDBRow>
            {!(startIsValid && endIsValid && datesAreValid) && (
            <MDBRow>
              <MDBCol className="text-danger">
                <ul className="list-unstyled">
                  {!startIsValid && (<li>The first date is earlier than the current IFTA interval start</li>)}
                  {!endIsValid && (<li>The end date is later than the current IFTA interval end</li>)}
                  {!datesAreValid && (<li>Only two weeks of routes can be migrated at a time, and the start time must be before the end time</li>)}
                  {!(startIsValid && endIsValid) && (
                  <li>
                    In order to migrate from {state.selectedStart.format('LL')} to {state.selectedEnd.format('LL')} change the current interval {startIsValid ? 'start' : ''}
                    {(startIsValid && endIsValid) ? ' and ' : ''}{endIsValid ? 'end' : '' } values
                  </li>
                  )}
                </ul>
              </MDBCol>
            </MDBRow>
            )}
            <MDBRow>
              <MDBCol>
                <VehicleAutocomplete
                  onVehicleSelected={(vehicle) => this.handleVehicleSelection(vehicle)}
                  placeholder={getAttribute(newVehicleForRoute, 'unitId', true) || 'Select A Vehicle'}
                />
              </MDBCol>
            </MDBRow>
            <MDBRow>
              {everythingValid && (
              <MDBCol>
                <h2>Overview</h2>
                <p>
                  Migrating IFTA Routes and fuel purchases from:
                  <b> {unitId}</b> to <b>{getAttribute(newVehicleForRoute, 'unitId', true)}</b>
                </p>
                <p>
                  Migrating every IFTA Route and fuel purchase between:
                  <b> {state.selectedStart.format('LLL')}</b> to <b>{state.selectedEnd.format('LLL')}</b>
                </p>
                <p>There are a total of <b>{state.iftaRoutesToMigrate.length}</b> route(s) being migrated</p>
              </MDBCol>
              )}
            </MDBRow>
          </MDBModalBody>
          <MDBModalFooter>
            {state.migrationError && (<span className="mr-auto text-danger">There was an error when migrating</span>)}
            <MDBBtn disabled={!everythingValid} onClick={() => { this.migrateRoutes(); }}>Migrate Routes</MDBBtn>
            <MDBBtn color="primary" onClick={() => { this.toggleModal(false); }}>Cancel</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </div>
    );
  }
}

MigrateRoutes.propTypes = {
  dateStart: PropTypes.instanceOf(Date),
  dateEnd: PropTypes.instanceOf(Date),
  unitId: PropTypes.string,
  iftaRoutes: PropTypes.arrayOf(PropTypes.object),
  refreshState: PropTypes.func,
};

export default MigrateRoutes;
