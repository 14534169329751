import * as ActionConstants from 'actions/ActionConstants';
import * as Helpers from 'api/Helpers';

const initialState = {
  documents: [],
};

function destroyDocuments(stateDocuments, documentsToBeDeleted) {
  const pDocuments = [].concat(stateDocuments);
  if (pDocuments) {
    const documentsToBeDeletedLen = documentsToBeDeleted.length;
    for (let i = 0; i < documentsToBeDeletedLen; i++) {
      const index = Helpers.findIndexOfObjArr(stateDocuments, 'id', documentsToBeDeleted[i].id);
      pDocuments.splice(index, 1);
    }
  }
  return pDocuments;
}

function documentRetake(stateDocuments, retakeParseDocument, jobId, fileName) {
  const newDocuments = [].concat(stateDocuments);
  newDocuments.unshift(retakeParseDocument);
  if (newDocuments) {
    const newDocumentsLen = newDocuments.length;
    for (let i = 0; i < newDocumentsLen; i++) {
      if (newDocuments[i].get('jobAction')[0].get('jobId') === jobId && newDocuments[i].get('documentCategory').get('type') === fileName) {
        newDocuments[i].set('promptedReupload', undefined);
      }
    }
  }
  return newDocuments;
}

export function Document(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_DOCUMENTS_INPROGRESS:
      return {
        ...state,
        status: action.type,
        fetched: false,
      };
    case ActionConstants.FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        status: action.type,
        documents: action.documents,
        fetched: true,
        error: undefined,
      };
    case ActionConstants.FETCH_DOCUMENTS_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
        fetched: false,
      };
    case ActionConstants.DELETE_DOCUMENTS:
      return initialState;
    case ActionConstants.ADD_DOCUMENTS_SUCCESS: {
      const updatedDocuments = [].concat(action.documents).concat(state.documents);
      return {
        ...state,
        status: action.type,
        documents: updatedDocuments,
        updated: action.documents,
      };
    }
    case ActionConstants.ADD_DOCUMENTS_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    case ActionConstants.DESTROY_DOCUMENTS_SUCCESS:
      return {
        ...state,
        status: action.type,
        documents: destroyDocuments(state.documents, action.documents),
        updated: action.documents,
      };
    case ActionConstants.UPDATE_DOCUMENT_INPROGRESS:
      return {
        ...state,
        status: action.type,
        updateReference: action.updateReference,
        error: undefined,
      };
    case ActionConstants.UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        status: action.type,
        error: undefined,
      };
    case ActionConstants.UPDATE_DOCUMENT_ERROR:
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
    case ActionConstants.DOCUMENT_REUPLOAD_PROMPT:
      return {
        ...state,
        status: action.type,
        updated: action.documents,
      };
    case ActionConstants.ADD_DOCUMENT_RETAKE:
      return {
        ...state,
        documents: documentRetake(state.documents, action.document, action.jobId, action.fileName),
        status: action.type,
        updated: action.documents,
      };
    default:
      return state;
  }
}
