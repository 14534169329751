// api
import Parse from 'parse';
import { createQuery, find, getAttribute, includePointers, setQueryRestriction, getCurrentUser } from 'api/Parse';
import { getCurrentUserCompanyObjectId, callCloudFunction } from 'sb-csapi/dist/AAPI';

// enums
import { QueryRestrictionTypes } from 'enums/Query';

/** @module Trailer */

/**
 * @memberof module:Trailer
 * 
 * @param {string} trackerId
 * @param {string} trailerId
 *  
 * @returns 
 */
const assignTrackerToTrailer = (trackerId, trailerId) => {
  return Parse.Cloud.run('assignDeviceToTrailer', {
    'tid': trailerId,
    'did': isNaN(trackerId) ? trackerId.toLowerCase() : trackerId
  }).catch((error) => {
    return Promise.reject(error.message);
  });
}

/**
 * @memberof module:Trailer
 * 
 * This function checks to see if a trailer tracker is already attached to a trailer (across companies, not just current company)
 * @param {string} trackerId The tracker id
 * 
 * @returns 
 */
const isTrackerUsed = async (trackerId) => {
  if (!trackerId || trackerId.trim() === "") return Promise.resolve(false);

  const trackerResult = await callCloudFunction('isTrackerUsed', {
    'companyObjectId': getCurrentUserCompanyObjectId(),
    'trackerId': trackerId.toLowerCase(),
  });

  if (trackerResult.isTrackerUsed === false) {
    return Promise.resolve(false);
  } else {
    return Promise.resolve(trackerResult.message); // Tracker is attached to another trailer
  }
}

/**
 * @memberof module:Trailer
 * 
 * @param {string} searchTerm
 * 
 * @returns {array} `limit` number of Trailer records on page `page`
 */
async function getTrailerSuggestions(searchTerm) {
  const trailerQuery = createQuery('Trailer');

  includePointers(trailerQuery, ['licensePlate', 'vehicle']);

  if (searchTerm) setQueryRestriction(trailerQuery, QueryRestrictionTypes.MATCHES, 'unitId', searchTerm);

  setQueryRestriction(trailerQuery, QueryRestrictionTypes.LIMIT, undefined, 10);
  setQueryRestriction(trailerQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', getAttribute(getCurrentUser(), 'belongsToCompany'));
  
  return await find(trailerQuery);
}

export {
    assignTrackerToTrailer,
    isTrackerUsed,
    getTrailerSuggestions,
};