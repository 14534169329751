import React from 'react';

import { ConfirmPopup as PRConfirmPopup, confirmPopup } from 'primereact/confirmpopup';

import './style.scss';

/**
 * @description PrimeReact ConfirmPopup component
 * 
 * @param {String} [className] - styles to apply to the input switch 
 * 
 * @returns
 * 
 * @example
 * <ConfirmPopup className="..." />
 */
function ConfirmPopup({ ...props }) {
  let className = 'sbc-confirm-popup';
  if (props.className) className += ` ${props.className}`;

  return (
    <PRConfirmPopup
      {...props}
      className={className}
    />
  )
}

/**
 * @description Configuration object to customize ConfirmPopup
 * 
 * @example
 * confirmPopupSettings({ target, message, icon, accept, reject })
 */
function confirmPopupSettings({ ...props }) {
  return confirmPopup({ ...props })
}

export {
  ConfirmPopup,
  confirmPopupSettings,
}
