import React from 'react';
import PropTypes from 'prop-types';

// Components
import TrailerRow from 'components/MapListSidebar/container/TrailerRow';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import { MDBContainer } from "mdbreact";

// CSS
import styles from './ListStyles.module.scss';

class TrailerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedAllTrailers: false, // bool to determine if number of vehicles hasnt changed
      trailers: [],
    };
    this.sortByVehicleLocationDate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { Trailer } = nextProps;
    const newState = { ...this.state };
    let trailers = Trailer.trailers;
    // trailers = trailers.filter(trailer => {
    //   const tc_devices = trailer.get('tc_devices');
    //   const hasValidPosition = tc_devices && tc_devices.get('tc_positions');
    //   if (hasValidPosition) {
    //     const tc_positions = tc_devices.get('tc_positions');
    //     const vehicleLocation = tc_positions.clone();
    //     vehicleLocation.set('dateTime', tc_positions.get('devicetime'));
    //     trailer.set('vehicleLocation', vehicleLocation);
    //     return true;
    //   }
    //   return false;
    // });

    if ((Trailer.fetched) || newState.trailers.length === 0) {
      if (this.props.sortBy === 'updatedAt') {
        newState.trailers = trailers.sort(this.sortByVehicleLocationDate);
      } else {
        newState.trailers = trailers;
      }
    }
    if (!Trailer.fetched) {
      newState.loadedAllTrailers = false;
    } else {
      newState.loadedAllTrailers = true;
    }
    this.setState(newState);
  }

  sortByVehicleLocationDate(a, b) {
    if (a.get('vehicleLocation') && b.get('vehicleLocation')) {
      return b.get('vehicleLocation').get('dateTime') - a.get('vehicleLocation').get('dateTime');
    } else if (a.get('vehicleLocation')) {
      return -1;
    } else if (b.get('vehicleLocation')) {
      return 1;
    }
    if (a.get('unitId') < b.get('unitId')) return -1;
    if (a.get('unitId') > b.get('unitId')) return 1;
    return 0;
  }

  render() {
    const { Trailer, activeSidebarObject, hoverSidebarObject, selectSidebarObject, speedLimitKm } = this.props;
    const { loadedAllTrailers, trailers } = this.state;
    // determine when and how to show the loading icon
    let loadingIconRow;
    if (loadedAllTrailers) {
      loadingIconRow = undefined;
    } else if (!loadedAllTrailers) {
      if (!Trailer.fetched) {
        loadingIconRow = (
          <tr className={styles.loadingIconNoHover}>
            <td colSpan="7">
              <div className={styles.loadingIcon}>
                <LoadingIcon />
              </div>
            </td>
          </tr>
        );
      } else {
        loadingIconRow = undefined;
      }
    }
    return (
      <MDBContainer className="px-0">
        {Trailer.fetched === true && Trailer.trailers.length === 0 &&
          <tr>
            <td colSpan="7" className="emptyRowMessage">You have no trailers with trackers on Switchboard</td>
          </tr>
        }

        {loadingIconRow}

        {trailers.length > 0 &&
          trailers.map(trailer =>
            <TrailerRow
              active={activeSidebarObject && activeSidebarObject.type && activeSidebarObject.object.id === trailer.id}
              handleHover={hoverSidebarObject}
              handleClick={selectSidebarObject}
              key={trailer.id}
              trailer={trailer}
              globalSpeedLimitKm={speedLimitKm}
            />
          )
        }
      </MDBContainer>
    );
  }
}

TrailerList.propTypes = {
  sortBy: PropTypes.string,
  Trailer: PropTypes.object.isRequired,
  selectSidebarObject: PropTypes.func.isRequired,
  hoverSidebarObject: PropTypes.func.isRequired,
  activeSidebarObject: PropTypes.object,
};

export default TrailerList;
