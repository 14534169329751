import React from 'react';

// PrimeReact Component
import { Dropdown as PRDropdown } from 'primereact/dropdown';

// Styles
import './style.scss';

/**
 * @description PrimeReact dropdown component
 * @param {String} className - Custom dropdown className
 * @param {String} [panelClassName] - Custom panel className
 * @param {bool} error - Show red border
 * @param {bool} warniing - Show yellow border
 * @param {bool} success - Show green border
 * @param {String} variant - Smaller dropdown
 * @param {Object} [style] - Custom style object
 */
export default function Dropdown({ ...props }) {
  // ** Misc ** //
  let className = 'sbc-dropdown';
  let panelClassName = 'sbc-dropdown-panel';

  if (props.sbVariant === 'small') {
    className += ' sb-variant-dropdown-small';
  }

  if (props.error) {
    className += ` sbc-error`;
  } else if (props.warning) {
    className += ` sbc-warning`;
  } else if (props.success) {
    className += ` sbc-success`;
  }

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (props.variant === 'small') {
    className += ' dropdown-small';
  }

  if (props.variant === 'inputsmall') {
    className += ' p-inputtext-sm';
  }

  if (props.variant === 'transparent') {
    className += ' dropdown-transparent';
  }

  if (props.variant === 'caret') {
    className += ' dropdown-caret';
  }

  if (props.panelClassName) {
    panelClassName += ` ${props.panelClassName}`;
  }

  const _props = { ...props };
  const { style } = _props;
  delete _props.style;

  return (
    <div className={className} style={style}>
      <PRDropdown
        {..._props}
        className={className}
        panelClassName={panelClassName}
      />
    </div>
  );
}
