import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import history from 'sbHistory';
import ReactTooltip from 'react-tooltip';
import {
  Table,
  TableHeader,
  TableRow,
  TableRowColumn,
  TableHeaderColumn,
  TableBody,
  FlatButton,
  RaisedButton
} from 'material-ui';

// API
import HOSRegulation from 'api/Lists/HOSRegulation';
import * as Helpers from 'api/Helpers';
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { getTimezoneAbbreviation } from 'api/utils/timezone';

// Components
import Title from 'components/LayoutTitle/view/Title';

function compareDate(a, b) {
  if (a && b && a.get('triggerTime') && b.get('triggerTime')) {
    return moment(a.get('triggerTime')).isBefore(moment(b.get('triggerTime')));
  }
  return false;
}

const HOSViolationsTable = (props) => {
  const { hosViolations, show, driver, scaleToDriverTimezone } = props;
  const tableHeaderColumnStyle = { width: '50%' };
  let timezoneOffsetFromUTC = (scaleToDriverTimezone && driver && driver.get('timezoneOffsetFromUTC')) || moment.tz.guess();
  if (scaleToDriverTimezone && props.timezoneOffsetFromUTC) timezoneOffsetFromUTC = props.timezoneOffsetFromUTC;
  let HOSViolationsTableRows;
  if (props.hosViolations) {
    HOSViolationsTableRows = [].concat(hosViolations).sort(compareDate).map(hosViolation => {
      const hosRegulation = HOSRegulation[hosViolation.get('regulationInt')];
      let message,
        rule;
      if (hosRegulation) {
        message = hosRegulation.message;
        rule = hosRegulation.rule;
      }
      const messageToolTip = (<ReactTooltip effect="solid" type="dark" id={`${hosViolation.id}Message`}>
        <div style={{ width: '30em', whiteSpace: 'initial' }}>{ message }</div>
      </ReactTooltip>);
      return (
        <TableRow key={hosViolation.id}>
          <TableRowColumn className="verticalAlignMiddle" style={{ width: '20%' }}>
            <span>{ moment(hosViolation.get('triggerTime')).tz(timezoneOffsetFromUTC).format('LT') }</span>
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle" data-tip data-for={`${hosViolation.id}Message`} style={{ width: '50%' }}>
            { messageToolTip }
            { message }
          </TableRowColumn>
          <TableRowColumn className="verticalAlignMiddle" style={{ width: '30%' }}>
            <FlatButton label={t('Hide')} primary onClick={() => props.hideHosViolation(hosViolation)} />
            <RaisedButton label={t('View Report')} primary onClick={() => history.push({ pathname: 'driver', search: 'driver=' + hosViolation.get('driver').id + '&view=hosViolations&date=' + moment(Helpers.referenceToTimezone(hosViolation.get('triggerTime'), hosViolation.get('driver').get('timezoneOffsetFromUTC'))).format('DDMMYY') })} />
          </TableRowColumn>
        </TableRow>
      );
    });
  }
  if (!props.hosViolations || HOSViolationsTableRows.length === 0) {
    HOSViolationsTableRows = (
      <TableRow><TableRowColumn colSpan="69" className="emptyRowMessage verticalAlignMiddle">
        {t('No Violations This Day')}
      </TableRowColumn></TableRow>
    );
  }

  return (
    <div>
      <div>
        <span>
          <Title className="h3 translate-me" title={<span>Violations This Day</span>}>
            <RaisedButton
              key="toggleViolations"
              label={t(`${props.show ? 'Hide Violations' : 'Show Violations'}`)}
              default
              onClick={props.toggleShowViolations}
            />
          </Title>
          { show && (
            <Table
              wrapperStyle={{ 'overflowY': 'auto', maxHeight: '12em' }}
            >
              <TableHeader
                displaySelectAll={false}
                adjustForCheckbox={false}
                enableSelectAll={false}
              >
                <TableRow>
                  <TableHeaderColumn style={{ width: '20%' }}>{t(`Triggered At ${hosViolations?.length > 0 ? `(${getTimezoneAbbreviation(getAttribute(hosViolations[0], 'triggerTime', true), timezoneOffsetFromUTC)})` : ''}`)}</TableHeaderColumn>
                  <TableHeaderColumn style={{ width: '50%' }}>{t('Violation Type')}</TableHeaderColumn>
                  <TableHeaderColumn style={{ width: '30%' }} />
                </TableRow>
              </TableHeader>
              <TableBody
                displayRowCheckbox={false}
                showRowHover
              >
                { HOSViolationsTableRows }
              </TableBody>
            </Table>
          )}
        </span>
      </div>
    </div>
  );
};

HOSViolationsTable.propTypes = {
  hosViolations: PropTypes.array,
  hideHosViolation: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  toggleShowViolations: PropTypes.func.isRequired,
  driver: PropTypes.object,
  scaleToDriverTimezone: PropTypes.bool,
};

export default HOSViolationsTable;
