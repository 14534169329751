import React from 'react';
import PropTypes from 'prop-types';
import { FontIcon } from 'material-ui';
import moment from 'moment-timezone';

// API
import { stopPropagation } from 'api/Helpers';

// CSS
import styles from './DriverReceiptsInfoTableBody.module.scss';


function DriverReceiptsInfoTableBody(props) {
  const { receipt, reveal } = props;
  return (
    <tbody className={styles.driverReceiptsInfoTableBody} onClick={props.revealHandler}>
      <tr>
        <td>
          {!reveal ?
            <FontIcon className="material-icons">arrow_right</FontIcon>
            :
            <FontIcon className="material-icons">arrow_drop_down</FontIcon>
          }
        </td>
        <td>
          {receipt.get('receiptCategory') &&
            <span>{receipt.get('receiptCategory').get('type')}</span>
          }
        </td>
        <td>
          {receipt.get('createdAt') &&
            <span>{moment(receipt.get('createdAt')).format('YYYY-MM-D')}</span>
          }
        </td>
        <td>
          {receipt.get('truck') &&
            <span>{receipt.get('truck').get('license')}</span>
          }
        </td>
        <td>
          {receipt.get('trailer') &&
            <span>{receipt.get('trailer').get('trailerNumber')}</span>
          }
        </td>
        <td>
          {receipt.get('chassis') &&
            <span>{receipt.get('chassis').get('chassisNumber')}</span>
          }
        </td>
        <td>
          {receipt.get('currency') &&
            <span>{receipt.get('currency')}&nbsp;</span>
          }
          {receipt.get('amount') &&
            <span>${receipt.get('amount')}</span>
          }
        </td>
        <td onClick={(e) => stopPropagation(e)}>
          {receipt.get('file') ?
            <a
              className="buttonDefault block"
              href={receipt.get('file')._url}
              rel="noopener noreferrer"
              target="_blank"
            >
              View Receipt
            </a>
            :
            <a className="buttonDefault block disabled">
              Not Uploaded
            </a>
          }
        </td>
        <td onClick={(e) => stopPropagation(e)}>
          <input type="checkbox" onClick={() => props.receiptsToDownload(receipt)} />
        </td>
      </tr>
      {reveal &&
        <tr className={styles.revealRow}>
          <td colSpan="9">
            <table>
              <tbody className={styles.driverReceiptsInfoTableBody}>
                <tr>
                  <td width="5%" />
                  <td width="15%" />
                  <td width="15%" />
                  <td width="12%" />
                  <td width="12%" />
                  <td width="12%" />
                  <td className={styles.receiptNotes} colSpan="3" width="29%">
                    <span>Receipt Notes</span>
                    <textarea
                      value={receipt.get('notes')}
                      placeholder="No notes given"
                      readOnly
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      }
    </tbody>
  );
}

DriverReceiptsInfoTableBody.propTypes = {
  receipt: PropTypes.object.isRequired,
  reveal: PropTypes.bool,
  revealHandler: PropTypes.func,
  receiptsToDownload: PropTypes.func,
};

export default DriverReceiptsInfoTableBody;
