import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Actions
import {
  getDiagnosedVehiclesForState,
  deleteVehiclesForState
} from 'actions/Vehicle';

// API
import * as Analytics from 'api/Analytics';

// Components
import Title from 'components/LayoutTitle/view/Title';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import VehicleDiagnosticsTable from './VehicleDiagnosticsTable';

// CSS
import styles from '../view/VehicleDiagnostics.module.scss';

// Context
import StoreContext from 'contexts/StoreContext';

class VehicleDiagnostics extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: [],
      sort: { unitId: 'descending' },
      tableHeight: '50vh',
      isLoading: false,
      isLazyLoading: false,
      hasLoadedAll: false,
      queryPage: 0,
      displayFilterForm: true
    }
    this.state.filterFormFields = [
      {
        attrName: 'loggedAtUTC',
        fullName: 'To',
        queryType: 'date',
        dateQueryType: 'lessThanOrEqualTo',
        placeholder: '.....',
        type: 'date'
      },
      {
        attrName: 'loggedAtUTC',
        fullName: 'From',
        queryType: 'date',
        dateQueryType: 'greaterThanOrEqualTo',
        placeholder: '.....',
        type: 'date'
      },
      {
        attrName: 'malfunctionCode',
        fullName: 'Fault Code',
        placeholder: '.....',
        type: 'text'
      }
      // { attrName: 'type', fullName: 'Type', placeholder: '.....', type: 'text' },
    ]
    this.filter = this.filter.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.getVehiclesAndEDCs = this.getVehiclesAndEDCs.bind(this)
  }

  componentDidMount() {
    deleteVehiclesForState().then(() => this.getVehiclesAndEDCs(true))
  }

  componentWillUnmount() {
    deleteVehiclesForState()
  }

  render() {
    const {
      isLoading,
      isLazyLoading,
      tableHeight,
      filterFormFields,
      filter
    } = this.state;
    return (
      <div>
        <Title title='Vehicle Diagnostics'>
          <FilterForm
            className='floatRight'
            handleFilter={this.filter}
            clearFilter={this.clearFilter}
            fields={filterFormFields}
          />
        </Title>
        {/* <div className='mb-4 translate-me'>
          Note: Only vehicles containing PT30s (engine module serial starting
          ‘3B’) record diagnostic information
        </div> */}
        <VehicleDiagnosticsTable
          edcs={this.state.edcs}
          vehicles={this.props.Vehicle.vehicles}
          handleLazyLoad={this.getVehiclesAndEDCs}
          isLoading={isLoading}
          isLazyLoading={isLazyLoading}
          filter={filter}
        />
      </div>
    )
  }

  getVehiclesAndEDCs = live => {
    const {
      queryPage,
      filter,
      isLoading,
      isLazyLoading,
      hasLoadedAll
    } = this.state
    if (!isLoading && !isLazyLoading && !hasLoadedAll) {
      let loadingState = { ...this.state, isLoading: true }
      if (queryPage > 0) {
        loadingState = { ...this.state, isLazyLoading: true }
      }
      this.setState(loadingState, () => {
        let self = this
        let tf = filter;
        if (live) {
          tf = tf.concat(
            { name: 'set', queryType: 'equalTo', value: live },
            { name: 'loggedAtUTC', queryType: 'date', dateQueryType: 'greaterThanOrEqualTo', fromValue: moment().subtract(7, 'days') }
          );
        }
        getDiagnosedVehiclesForState(queryPage, 100, tf, undefined).then(
          function (edcs) {
            const newState = {
              ...loadingState,
              isLoading: false,
              isLazyLoading: false,
              queryPage: queryPage + 1,
              edcs: edcs
            }
            if (edcs.length === 0) {
              newState.hasLoadedAll = true // we've queried everything, so stop
            } else {
              Analytics.identifyOnceForCompany('VIEWVEHDIAG', {
                'Diagnostic Viewed': true,
                'DiagnosticsViewDate': moment().toISOString(),
              });
            }
            self.setState(newState)
          }
        ), (error) => console.log(error);
      })
    }
  }

  filter(filterArr) {
    const newState = {
      ...this.state,
      filter: filterArr,
      queryPage: 0,
      hasLoadedAll: false
    }
    this.setState(newState, () => {
      deleteVehiclesForState().then(() => {
        this.getVehiclesAndEDCs();
      })
    })
  }

  clearFilter() {
    const newState = {
      ...this.state,
      filter: [],
      queryPage: 0,
    }
    this.setState(newState, () => {
      deleteVehiclesForState().then(() => {
        this.getVehiclesAndEDCs(true)
      })
    })
  }
}

VehicleDiagnostics.propTypes = {
  Company: PropTypes.object,
  disableVehicle: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isLazyLoading: PropTypes.bool,
  height: PropTypes.any
}

const mapStateToProps = state => {
  const { Vehicle } = state
  return {
    Vehicle
  }
}

export default connect(mapStateToProps, null, null, { context: StoreContext })(VehicleDiagnostics);
