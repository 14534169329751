import React, { useState, useEffect } from 'react';

// api
import { getAttribute } from 'sb-csapi/dist/AAPI';
import { addDispatchContact, updateDispatchContact } from 'api/Dispatch/DispatchContact';

// sbcore components
import Dialog from 'sbCore/Dialog/Dialog';
import InputText from 'sbCore/InputText/InputText';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import InputMask from 'sbCore/InputMask/InputMask';
import Button from 'sbCore/Button/Button';
import Dropdown from 'sbCore/Dropdown/Dropdown';
import InputTextarea from 'sbCore/InputTextarea/InputTextarea';

// sb-csapi
import { ContactType } from 'sb-csapi/dist/enums/Dispatch/Contact';

/**
 * @description Add / Edit DispatchContact dialog pop-up
 * @param {CustomerDispatchOrganization} customerDispatchOrganization - The CustomerDispatchOrganization we want to link the new dispatchOrganizationContact to
 * @param {DispatchOrganizationContact} dispatchContact - The DispatchOrganizationContact we want to edit. If nothing's passed, treat as an add DispatchOrganizationContact
 * @param {Function} onSelectDispatchContact - Required. Callback for setting a new dispatch contact
 * @param {bool} visible - If the form is visible
 * @param {Function} onCancel - Required. Callback for the cancel button
 * @param {Function} onSave - Callback when the contact is saved. Passes back the saved contact
 * @returns
 *
 * @example
 * <DispatchContactEditDialog />
 */
export default function DispatchContactEditDialog({ ...props }) {

  // ** useStates ** //
  const [dispatchContact, setDispatchContact] = useState(null);
  const [dispatchContactObj, setDispatchContactObj] = useState({});
  const [customerDispatchOrganization, setCustomerDispatchOrganization] = useState(null);
  const [saveTrigger, setSaveTrigger] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  // ** useEffects ** //
  useEffect(() => {
    const customerDispatchOrganization = props.customerDispatchOrganization;
    setCustomerDispatchOrganization(customerDispatchOrganization);

    const dispatchContact = props.dispatchContact;
    setDispatchContact(dispatchContact);

    setDispatchContactObj({
      organization: customerDispatchOrganization,
      type: getAttribute(dispatchContact, 'type', true),
      name: getAttribute(dispatchContact, 'name', true),
      title: getAttribute(dispatchContact, 'title', true),
      department: getAttribute(dispatchContact, 'department', true),
      primaryPhone: getAttribute(dispatchContact, 'primaryPhone', true),
      extension: getAttribute(dispatchContact, 'extension', true),
      cellPhone: getAttribute(dispatchContact, 'cellPhone', true),
      email: getAttribute(dispatchContact, 'email', true),
      notes: getAttribute(dispatchContact, 'notes', true),
    });
    setSaveTrigger(undefined); // reset the trigger

  }, [props.dispatchContact, props.visible]); // props.visible is here so it resets the form to initial state when "closed"

  useEffect(() => {
    if (saveTrigger === undefined) return;
    let didCancel = false;

    async function _updateDispatchContact() {
      setIsLoading(true);
      // first save the address
      let updatedDispatchContact;
      if (dispatchContact) {
        updatedDispatchContact = await updateDispatchContact(dispatchContact, undefined, dispatchContactObj, true);
      } else {
        updatedDispatchContact = await addDispatchContact(dispatchContactObj);
      }

      if (!didCancel) {
        setIsLoading(false);
        if (props.onSave) {
          props.onSave(updatedDispatchContact);
        }
      }
    }

    _updateDispatchContact();

    return () => { didCancel = true; };
  }, [saveTrigger]);

  // ** Callbacks ** //
  const onCancel = () => {
    props.onCancel();
  };

  const handleInput = (attribute, value) => {
    let _value = value;
    if (attribute === 'primaryPhone' || attribute === 'cellPhone') {
      _value.replace('()-', '');

      // Without mask, format the input automatically for phone numbers
      // const extractedValue = value.replaceAll('(','').replaceAll(')','').replaceAll('-','').replaceAll('_','');
      // const areaCode = extractedValue.substring(0, 3);
      // const middle = extractedValue.substring(3, 6);
      // const last = extractedValue.substring(6, 10);

      // if (extractedValue.length > 6) {
      //   value = `(${areaCode})-${middle}-${last}`;
      // } else if (extractedValue.length > 3) {
      //   value = `(${areaCode})-${middle}`;
      // } else if (extractedValue.length > 0) {
      //   value = `(${areaCode}`;
      // }
    }
    if (attribute === 'notes') {
      _value = value.substr(0, 300);
    }
    const _dispatchContactObj = { ...dispatchContactObj };
    _dispatchContactObj[attribute] = _value;
    setDispatchContactObj(_dispatchContactObj);
  };

  // ** Enums ** //
  const contactOptions = Object.keys(ContactType).map((type) => (
    {
      label: ContactType[type].description,
      value: ContactType[type].value,
    }
  ));

  // Should also be moved into sappy enums?
  const titleTypes = {
    Manager: 'Manager',
    Operations: 'Operations',
    Dispatcher: 'Dispatcher',
    Owner: 'Owner',
    Accountant: 'Accountant',
  };
  const TitleTypes = Object.keys(titleTypes).map((key) => ({ label: key, value: key }));

  // ** Components ** //
  const footer = (
    <span className="flex justify-content-end">
      {!isLoading && (
        <Button
          label="Cancel"
          icon="pi pi-times"
          className="mr-2 p-button-sm p-button-default"
          onClick={() => onCancel()}
          disabled={isLoading}
        />
      )}
      <Button
        label={!isLoading ? 'Save' : 'Saving...'}
        icon="pi pi-plus"
        className="p-button-sm p-button-info"
        onClick={() => setSaveTrigger((!saveTrigger) ? 1 : 0)}
        disabled={!dispatchContactObj.name || isLoading}
      />
    </span>
  );

  const nameField = (
    <InputText
      value={dispatchContactObj.name}
      onChange={(e) => handleInput('name', e.target.value)}
      placeholder="Name"
      className="w-full"
      warning={!dispatchContactObj.name}
      success={dispatchContactObj.name}
      autoFocus
    />
  );

  const titleTypeDropdown = (
    <Dropdown
      value={dispatchContactObj.title}
      options={TitleTypes}
      onChange={(e) => handleInput('title', e.target.value)}
      placeholder="Title"
      className="w-10"
      warning={dispatchContactObj.title === undefined}
      success={dispatchContactObj.title !== undefined}
      showOnFocus
    />
  );

  const departmentField = (
    <InputText
      value={dispatchContactObj.department}
      keyfilter="alpha"
      onChange={(e) => handleInput('department', e.target.value)}
      placeholder="Department"
      className="w-full"
      warning={!dispatchContactObj.department}
      success={dispatchContactObj.department}
    />
  );

  const primaryPhoneNumberField = (
    <InputText
      value={dispatchContactObj.primaryPhone}
      placeholder="(999)-999-9999"
      onChange={(e) => {
        handleInput('primaryPhone', e.target.value);
      }}
      keyfilter="int"
      warning={!dispatchContactObj.primaryPhone}
      success={dispatchContactObj.primaryPhone}
    />
  );

  const extensionField = (
    <InputText
      value={dispatchContactObj.extension}
      keyfilter="pint"
      onChange={(e) => handleInput('extension', e.target.value)}
      placeholder="99999"
      className="w-full"
      warning={!dispatchContactObj.extension}
      success={dispatchContactObj.extension}
    />
  );

  const cellPhoneField = (
    <InputText
      value={dispatchContactObj.cellPhone}
      placeholder="(999)-999-9999"
      onChange={(e) => handleInput('cellPhone', e.target.value)}
      keyfilter="int"
      warning={!dispatchContactObj.cellPhone}
      success={dispatchContactObj.cellPhone}
    />
  );

  const emailField = (
    <InputText
      value={dispatchContactObj.email}
      keyfilter="email"
      onChange={(e) => handleInput('email', e.target.value)}
      placeholder="Email"
      className="w-full"
      warning={!dispatchContactObj.email}
      success={dispatchContactObj.email}
    />
  );

  const contactTypeDropdown = (
    <Dropdown
      value={dispatchContactObj.type}
      options={contactOptions}
      onChange={(e) => handleInput('type', e.target.value)}
      placeholder="Contact Type"
      className="w-10"
      warning={dispatchContactObj.type === undefined}
      success={dispatchContactObj.type !== undefined}
      showOnFocus
    />
  );

  // ** classNames ** //
  let className = 'dispatch-contact-edit-dialog';
  if (props.className) className += ` ${props.className}`;

  const label = 'Contact';

  return (
    <Dialog
      className={className}
      visible={props.visible}
      header={dispatchContact ? `Edit ${label}` : `Add a new ${label}`}
      footer={footer}
      onHide={() => onCancel()}
      closable={false}
      style={{ width: '70vw' }}
    >
      <div className="grid">

        {/* General */}
        <div className="col-12 flex">
          <div className="col-3">
            <InputLabel>Name</InputLabel>
            {nameField}
          </div>
          <div className="col-3">
            <InputLabel>Title</InputLabel>
            {titleTypeDropdown}
          </div>
          <div className="col-3">
            <InputLabel>Department</InputLabel>
            {departmentField}
          </div>
          <div className="col-3">
            <InputLabel>Primary Phone Number</InputLabel>
            {primaryPhoneNumberField}
          </div>
        </div>

        <div className="col-12 flex">
          <div className="col-3">
            <InputLabel>Extension</InputLabel>
            {extensionField}
          </div>
          <div className="col-3">
            <InputLabel>Cell Phone</InputLabel>
            {cellPhoneField}
          </div>
          <div className="col-3">
            <InputLabel>Email</InputLabel>
            {emailField}
          </div>
          <div className="col-3">
            <InputLabel>Contact Type</InputLabel>
            {contactTypeDropdown}
          </div>
        </div>

        <div className="col-12 flex">
          <div className="col-6">
            <InputLabel>Notes</InputLabel>
            <InputTextarea
              value={dispatchContactObj.notes}
              onChange={(e) => handleInput('notes', e.target.value)}
              placeholder="Notes"
              className="w-10 h-10rem"
              warning={!dispatchContactObj.notes}
              success={dispatchContactObj.notes}
            />
          </div>
        </div>

      </div>
    </Dialog>
  );
}
