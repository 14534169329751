import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// API
import * as Setters from 'api/Setters';
import * as Helpers from 'api/Helpers';

// Components
import AddNewJobModalAdvancedView from 'components/AddNewJob/view/AddNewJobModalAdvanced';
import ConfirmModal from 'components/ConfirmModal/container/ConfirmModal';

// Actions
import { addJobLinkToState, assignJobActionsToDriver } from 'actions/JobLink';
import { updateVehicleForState } from 'actions/Vehicle';
import { updateDriverForState } from 'actions/Driver';
import { updateTrailerForState } from 'actions/Trailer';

// Context
import StoreContext from 'contexts/StoreContext';

function getInitialState(initialJobActionId) {
  const initialState = {
    addingInProgress: false,
    globalJobDetails: {
      batchId: '',
      notes: '',
      customer: null,
    },
    jobActionId: initialJobActionId,
    jobActionEntries: [
      getInitialJobActionEntry(initialJobActionId),
    ],
    reassign: { show: false },
    selectedJobActionEntryIndex: 0,
    errors: [],
  };
  return initialState;
}

function getInitialJobActionEntry(jobActionId) {
  return {
    jobActionType: 'Pickup',
    jobActionId,
    jobId: '',
    location: null,
    vehicle: null,
    drivers: null,
    trailer: null,
    startDate: null,
    notes: '',
    _primary: '',
  };
}

function linkVehicleDriverTrailer(jobActionEntries, property) {
  const newJobActionEntries = { ...jobActionEntries };
  if (property === 'vehicle') {
    newJobActionEntries.drivers = jobActionEntries.vehicle && jobActionEntries.vehicle.get('drivers') ? jobActionEntries.vehicle.get('drivers') : null;
    newJobActionEntries.trailer = jobActionEntries.vehicle ? jobActionEntries.vehicle.get('trailer') : null;
    newJobActionEntries._primary = 'vehicle';
  } else if (property === 'drivers') {
    newJobActionEntries.vehicle = jobActionEntries.drivers ? jobActionEntries.drivers[0].get('vehicle') : null;
    newJobActionEntries.trailer = jobActionEntries.vehicle ? jobActionEntries.vehicle.get('trailer') : null;
    newJobActionEntries._primary = 'drivers';
  } else if (property === 'trailer') {
    newJobActionEntries.vehicle = jobActionEntries.trailer ? jobActionEntries.trailer.get('vehicle') : null;
    newJobActionEntries.drivers = jobActionEntries.vehicle ? jobActionEntries.vehicle.get('drivers') : null;
    newJobActionEntries._primary = 'trailer';
  }
  if (!jobActionEntries[property]) {
    newJobActionEntries._primary = '';
  }
  return newJobActionEntries;
}

class AddNewJobModalAdvanced extends React.Component {
  constructor(props) {
    super(props);
    const initialJobActionId = 1;

    this.state = {
      addingInProgress: false,
      globalJobDetails: {
        batchId: '',
        notes: '',
        customer: null,
      },
      jobActionId: initialJobActionId,
      jobActionEntries: [
        getInitialJobActionEntry(initialJobActionId),
      ],
      reassign: { show: false },
      selectedJobActionEntryIndex: 0,
      errors: [],
    };
    this.addJobAction = this.addJobAction.bind(this);
    this.deleteJobAction = this.deleteJobAction.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.moveJobAction = this.moveJobAction.bind(this);
    this.showConfirmReassign = this.showConfirmReassign.bind(this);
    this.closeConfirmReassign = this.closeConfirmReassign.bind(this);
    this.openChooseOrAddModal = this.openChooseOrAddModal.bind(this);
    this.closeChooseOrAddModal = this.closeChooseOrAddModal.bind(this);
    this.selectJobActionEntryIndex = this.selectJobActionEntryIndex.bind(this);
    this.addJob = this.addJob.bind(this);
  }

  addJobAction() {
    const newState = { ...this.state };
    newState.jobActionId += 1;
    newState.jobActionEntries = [].concat(this.state.jobActionEntries, [getInitialJobActionEntry(newState.jobActionId)]);
    newState.selectedJobActionEntryIndex = (newState.jobActionEntries.length - 1);
    this.setState(newState);
  }

  deleteJobAction(jobActionEntry) {
    const { selectedJobActionEntryIndex } = this.state;
    const newState = { ...this.state };

    let index;
    this.state.jobActionEntries.some((entry, i) => {
      index = i;
      return entry.jobActionId === jobActionEntry.jobActionId;
    });

    newState.jobActionEntries = [].concat(this.state.jobActionEntries);
    newState.jobActionEntries.splice(index, 1);

    if (selectedJobActionEntryIndex > 0) {
      newState.selectedJobActionEntryIndex = selectedJobActionEntryIndex - 1;
    } else {
      newState.selectedJobActionEntryIndex = 0;
    }

    this.setState(newState);
  }

  moveJobAction(direction, jobActionEntry) {
    const newState = { ...this.state };
    const index = Helpers.findIndexOfObjArr(newState.jobActionEntries, 'jobActionId', jobActionEntry.jobActionId);
    if (direction === 'up' && index !== 0) {
      newState.jobActionEntries.splice(index - 1, 2, jobActionEntry, this.state.jobActionEntries[index - 1]);
      this.setState(newState);
    } else if (direction === 'down' && index !== newState.jobActionEntries.length - 1) {
      newState.jobActionEntries.splice(index, 2, this.state.jobActionEntries[index + 1], jobActionEntry);
      this.setState(newState);
    }
  }

  selectJobActionEntryIndex(jobActionEntry) {
    const index = Helpers.findIndexOfObjArr(this.state.jobActionEntries, 'jobActionId', jobActionEntry.jobActionId);
    this.setState({ ...this.state, selectedJobActionEntryIndex: index });
  }

  // Choosing Customer/Driver...
  openChooseOrAddModal(type) {
    this.state.chooseOrAddModal = { show: true, property: type, label: type };
    this.setState(this.state);
  }
  closeChooseOrAddModal() {
    this.state.chooseOrAddModal = { show: false, property: '', label: '' };
    this.setState(this.state);
  }

  handleFormChange(jobProperty, jobValue, globalJobDetailBool) {
    const newState = { ...this.state };
    if (globalJobDetailBool) {
      newState.globalJobDetails[jobProperty] = jobValue;
    } else {
      newState.jobActionEntries[this.state.selectedJobActionEntryIndex][jobProperty] = jobValue;
      newState.jobActionEntries[this.state.selectedJobActionEntryIndex] = linkVehicleDriverTrailer(newState.jobActionEntries[this.state.selectedJobActionEntryIndex], jobProperty);
    }
    this.setState(newState);
  }

  showConfirmReassign(jobProperty, jobValue) {
    const newState = { ...this.state };
    newState.reassign = { show: true, jobProperty, jobValue };
    this.setState(newState);
  }
  handleReassign(confirmBool) {
    const newState = { ...this.state };
    newState.reassign = { show: false };

  }
  closeConfirmReassign() {
    const newState = { ...this.state };
    newState.reassign.show = false;
    this.setState(newState);
  }

  addJob() {
    const { globalJobDetails, jobActionEntries } = this.state;
    const errors = [];

    if (!globalJobDetails.batchId) errors.push('Job ID is Required');
    jobActionEntries.some(jobActionEntry => {
      const hasNoJobId = !jobActionEntry.jobId;
      const hasNoDrivers = !jobActionEntry.drivers || jobActionEntry.drivers.length === 0;

      if (hasNoJobId) {
        errors.push('Every Pickup or Dropoff must have a Number/ID');
      }
      if (hasNoDrivers) {
        errors.push('Every Pickup or Dropoff must have one or more Drivers');
      }

      return hasNoJobId || hasNoDrivers;
    });

    if (errors.length > 0) {
      return this.setState({ ...this.state, errors });
    }

    this.setState({ ...this.state, errors: [], isLoading: true }, () => {
      Setters.createJob(globalJobDetails, jobActionEntries).then(
        (jobLink) => {
          this.setState({ ...this.state, isLoading: false }, () => {
            addJobLinkToState(jobLink);
            this.state = getInitialState(1);

            this.setState(this.state, () => {
              this.props.handleClose();
            });
          });
        }
      );
    });
  }

  render() {
    return (
      <div>
        <AddNewJobModalAdvancedView
          {...this.props}
          globalJobDetails={this.state.globalJobDetails}
          addJobAction={this.addJobAction}
          deleteJobAction={this.deleteJobAction}
          validateError={this.state.validateError}
          handleFormChange={this.handleFormChange}
          jobActionEntries={this.state.jobActionEntries}
          moveJobAction={this.moveJobAction}
          showConfirmReassign={this.showConfirmReassign}
          confirmConfirmReassign={this.confirmConfirmReassign}
          openChooseOrAddModal={this.openChooseOrAddModal}
          onSubmit={this.addJob}
          addingInProgress={this.state.addingInProgress}
          selectIndex={this.selectJobActionEntryIndex}
          selectedIndex={this.state.selectedJobActionEntryIndex}
          isLoading={this.state.isLoading}
          errors={this.state.errors}
        />
        <ConfirmModal show={this.state.reassign.show} handleModalChoice={(confirmBool) => confirmBool && this.handleFormChange(this.state.reassign.jobProperty, this.state.reassign.jobValue)} handleClose={() => this.closeConfirmReassign()}>
          Are you sure you want to delete this?
        </ConfirmModal>
      </div>
    );
  }
}

AddNewJobModalAdvanced.propTypes = {
  Dispatcher: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  JobAction: PropTypes.object.isRequired,
  JobLink: PropTypes.object.isRequired,
};


const mapStateToProps = (state) => {
  const { addNewJob } = state.form;
  const { JobAction, JobLink, Dispatcher } = state;
  return {
    addNewJob,
    JobAction,
    JobLink,
    Dispatcher,
  };
};

export default connect(mapStateToProps, null, null, { context: StoreContext })(AddNewJobModalAdvanced);

