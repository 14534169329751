// api
import Parse from 'parse';
import moment from 'moment-timezone';
import { createQuery, find, getAttribute, includePointers, setQueryRestriction, getCurrentUser, copyQuery, sortQuery, count } from 'api/Parse';
import { getRecord, queryCompanyObjects } from 'api/Getters';

// enums
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';

// sbObjects
import Sort from 'sbObjects/Sort';

/** @module Vehicle */

/**
 * @memberof module:Vehicle
 *
 * @param {array} vehicles
 *
 * @returns
 */
const getLiveEDCsForVehicles = async (vehicles) => {
  const filter = [
    { queryType: 'greaterThanOrEqualTo', name: 'loggedAtUTC', value: moment().subtract(7, 'days').toDate() },
    { queryType: 'equalTo', name: 'set', value: true },
  ]
  const edcs = await queryCompanyObjects('EngineDTC', 0, 100, filter, null, ['vehicle'], null, null, null, true);
  const filteredEdcs = edcs.filter((edc) => {
    return (
      edc.get('malfunctionCode') &&
      edc.get('malfunctionCode') !== '0000007F' &&
      edc.get('malfunctionCode') !== '000000FF'
    );
  });
  return filteredEdcs;
}

/**
 * @memberof module:Vehicle
 *
 * @param {string} edcId
 * @param {string} note
 *
 * @returns
 */
const saveNoteToEDC = (edcId, note) => {
  return new Promise((resolve, reject) => {
    getRecord('EngineDTC', edcId).then((edc) => {
      edc.set('note', note);
      edc.save();
      resolve(edc);
    }).catch((error) => {
      reject(error);
    });
  });
}

/**
 * @memberof module:Vehicle
 *
 * @param {string} searchTerm
 *
 * @returns {array} `limit` number of Vehicle records on page `page`
 */
async function getVehicleSuggestions(searchTerm) {
  const vehicleQuery = createQuery('Vehicle');

  includePointers(vehicleQuery, ['vehicleLocation', 'licensePlate', 'trailer']);

  if (searchTerm) setQueryRestriction(vehicleQuery, QueryRestrictionTypes.MATCHES, 'unitId', searchTerm);

  setQueryRestriction(vehicleQuery, QueryRestrictionTypes.LIMIT, undefined, 10);
  setQueryRestriction(vehicleQuery, QueryRestrictionTypes.EXISTS, 'unitId');
  setQueryRestriction(vehicleQuery, QueryRestrictionTypes.EQUAL_TO, 'enabled', true);
  setQueryRestriction(vehicleQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', getAttribute(getCurrentUser(), 'belongsToCompany'));

  return await find(vehicleQuery);
}

/**
 * @memberof module:Vehicle
 * @description Obtains vehicles from a company
 *
 * @param {int} page - query page / limit skip multiplier
 * @param {int} limit  - amount of results we want
 * @param {array} sortBy - array of Sort objects
 * @param {array} filters - array of Filter objects
 * @param {bool} considerChildCompanies - whether or not to include child company results
 *
 * @returns {object} - { vehicles: [], totalVehiclesCount: int }
 */
async function getVehicles(page = 0, limit = 10, sortBy = new Sort('unitId', QuerySortOrderTypes.ASCENDING), filters = [], considerChildCompanies) {
  const currentUser = getCurrentUser();
  const belongsToCompany = getAttribute(currentUser, 'belongsToCompany');
  const companiesToConsider = [belongsToCompany];

  const vehicleQuery = createQuery('Vehicle');

  if (considerChildCompanies) {
    const childrenQuery = createQuery('CompanyLink');
    setQueryRestriction(childrenQuery, QueryRestrictionTypes.EQUAL_TO, 'parentCompany', belongsToCompany);
    const childCompanies = await find(childrenQuery);
    childCompanies.map((childCompany) => companiesToConsider.push(childCompany));

    setQueryRestriction(vehicleQuery, QueryRestrictionTypes.CONTAINED_IN, 'belongsToCompany', companiesToConsider);
  } else {
    setQueryRestriction(vehicleQuery, QueryRestrictionTypes.EQUAL_TO, 'belongsToCompany', belongsToCompany);
  }

  // set query restricitons from filter
  filters.map(filter => setQueryRestriction(vehicleQuery, (filter.queryRestriction || filter.queryType), filter.attribute, filter.value));

  // at this point, copy current query to get the number of pages for pagination
  const vehiclesCountQuery = copyQuery(vehicleQuery);

  includePointers(vehicleQuery, [
    'belongsToCompany',
    'licensePlate',
    'weighStationBypassVehicle',
    'tc_devices',
    'trailer',
    'vehicleLocation',
    'vehicleLocation.eldEvent',
    'vehicleLocation.eldEvent.eldDailyCertification',
    'hiddenBy',
    'eldHardware',
    'vendor',
    'updatedBy',
    'createdBy',
  ]);

  setQueryRestriction(vehicleQuery, QueryRestrictionTypes.LIMIT, undefined, limit);
  setQueryRestriction(vehicleQuery, QueryRestrictionTypes.SKIP, undefined, page * limit);
  // call the ascending/descending function on the query, passing in the attribute
  sortQuery(vehicleQuery, sortBy.order, sortBy.attribute);

  try {
    const [totalVehicleCount, vehicles] = await Promise.all([count(vehiclesCountQuery), find(vehicleQuery)]);
    return { vehicles, totalVehicleCount };
  } catch (err) {
    throw new Error(err);
  }
}

/**
 * @memberof module:Vehicle
 *
 * @param {*} dashcamHardwareId
 * @param {*} vehicleObjectId
 * @param {*} type
 *
 * @returns
 */
const assignDashcamHardwareToVehicle = (dashcamHardwareId, vehicleObjectId, type) => {
  return Parse.Cloud.run('assignDashcamHardwareToVehicle', {
    dashcamHardwareId,
    vehicleObjectId,
    type
  }).catch((error) => {
    return Promise.reject(error.message);
  });
}

export {
  getLiveEDCsForVehicles,
  getVehicleSuggestions,
  saveNoteToEDC,
  getVehicles,
  assignDashcamHardwareToVehicle
};
