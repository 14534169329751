import React from 'react';

// PrimeReact Component
import { Divider as PRDivider } from 'primereact/divider';

// Styles
import './style.scss';

export default function Divider({ style, ...props }) {

  let className = `sbc-divider`;
  if (props.className) className += ` ${props.className}`;

  return (
    <div className={className} style={{ ...style }}>
      <PRDivider {...props} />
    </div>
  );
}
