import React from 'react';
import PropTypes from 'prop-types';

// Actions
import { getDriverIncludeArr } from 'actions/Driver';
import { getTrailerIncludeArr } from 'actions/Trailer';
import { getVehicleIncludeArr, updateVehicleForState } from 'actions/Vehicle';
import { getVendorIncludeArr } from 'actions/Vendor';

// API
import { queryCompanyObjects } from 'api/Getters';
import { scrolledToBottom } from 'api/Helpers';

// Components
import AddNewDriverButton from 'components/AddDriver/container/AddDriver';
import AddNewVehicleButton from 'components/AddEditEquipment/container/AddNewVehicleButton';
import AddNewVendorButton from 'components/AddEditLocation/container/AddNewVendorButton';
import AddNewTrailerButton from 'components/AddEditEquipment/container/AddNewTrailerButton';
import DriverList from 'components/ChooseOrAddModal/view/DriverList';
import VendorList from 'components/ChooseOrAddModal/view/VendorList';
import VehicleList from 'components/ChooseOrAddModal/view/VehicleList';
import TrailerList from 'components/ChooseOrAddModal/view/TrailerList';
import FilterForm from 'components/FilterForm/container/FilterForm.new';

// CSS
import styles from './ChooseOrAddList.module.scss';

class ChooseOrAddList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showConfirmModal: false, showFilter: false, loaded: false, filter: [], sort: {}, lastScrollPos: 0, objects: [] };
    this.refreshState = this.refreshState.bind(this);
    this.filter = this.filter.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.chooseDriversFromVehicleList = this.chooseDriversFromVehicleList.bind(this);
  }

  componentWillMount() {
    this.tableHeight = document.documentElement.clientHeight * 0.4; // 626 height of modal, 70vh defined by CSS
    this.tableRowAmount = Math.round(this.tableHeight / 30) + 5; // amount of el's to display
    this.pageCount = 0; // result fetch multiplier
    this.refreshState();
  }

  componentDidMount() {
    queryCompanyObjects(this.state.class, this.pageCount, this.tableRowAmount, [], this.state.sort, this.state.includeArr).then(
      (parseObjArr) => {
        this.setState({ ...this.state, objects: parseObjArr, loaded: true });
      }
    );
  }

  refreshState() {
    const newState = { ...this.state };
    if (this.props.property === 'driver' || this.props.property === 'drivers') {
      newState.class = 'Driver';
      newState.filterFormFields = [
        { attrName: 'user_fullName', fullName: 'Driver Name', placeholder: '.....', type: 'text', autofocus: true },
      ];
      newState.sort = { user_fullName: 'ascending' };
      newState.includeArr = getDriverIncludeArr();
      newState.addButton = <AddNewDriverButton />;
    } else if (this.props.property === 'vendor') {
      newState.class = 'Vendor';
      newState.filterFormFields = [
        { attrName: 'name', fullName: 'Name', placeholder: '.....', type: 'text', autofocus: true },
        { attrName: 'address_address', queryType: 'searchString', fullName: 'Address', placeholder: '.....', type: 'text' },
        { attrName: 'address_city', queryType: 'searchString', fullName: 'City', placeholder: '.....', type: 'text' },
      ];
      newState.sort = { name: 'ascending' };
      newState.includeArr = getVendorIncludeArr();
      newState.addButton = <AddNewVendorButton />;
    } else if (this.props.property === 'vehicle') {
      newState.class = 'Vehicle';
      newState.filterFormFields = [
        { attrName: 'user_fullName', fullName: 'Vehicle Name', placeholder: '.....', type: 'text', autofocus: true },
      ];
      newState.sort = { unitId: 'ascending' };
      newState.includeArr = getVehicleIncludeArr();
      newState.addButton = <AddNewVehicleButton />;
    } else if (this.props.property === 'trailer') {
      newState.class = 'Trailer';
      newState.filterFormFields = [
        { attrName: 'user_fullName', fullName: 'Trailer Name', placeholder: '.....', type: 'text', autofocus: true },
      ];
      newState.sort = { unitId: 'ascending' };
      newState.includeArr = getTrailerIncludeArr();
      newState.addButton = <AddNewTrailerButton />;
    }
    this.setState(newState);
  }

  filter(filterObject) {
    this.pageCount = 0;
    const newState = { ...this.state, filter: filterObject, objects: [], loaded: false };
    this.setState(newState);
    queryCompanyObjects(this.state.class, this.pageCount, this.tableRowAmount, filterObject, this.state.sort, this.state.includeArr).then((parseObjArr) => {
      this.setState({ ...this.state, objects: parseObjArr, loaded: true });
    });
  }

  clearFilter() {
    this.pageCount = 0;
    this.setState({ ...this.state, filter: [], objects: [], loaded: false });
    queryCompanyObjects(this.state.class, this.pageCount, this.tableRowAmount, [], this.state.sort, this.state.includeArr).then((parseObjArr) => {
      this.setState({ ...this.state, objects: parseObjArr, loaded: true });
    });
  }

  scrollHandler(e) {
    if (scrolledToBottom(e, 10, this.lastScrollPos)) {
      this.pageCount++;
      queryCompanyObjects(this.state.class, this.pageCount, this.tableRowAmount, this.state.filter, this.state.sort, this.state.includeArr).then((parseObjArr) => {
        this.setState({ ...this.state, objects: [].concat(...[this.state.objects, parseObjArr]), loaded: true });
      });
      this.lastScrollPos = e.target.scrollTop;
    }
  }

  chooseDriversFromVehicleList(drivers, vehicle) {
    const vehicleDrivers = [drivers.mainDriver];
    if (drivers.coDriver) {
      vehicleDrivers.push(drivers.coDriver);
    }
    updateVehicleForState(vehicle, 'drivers', vehicleDrivers).then(
      () => this.refreshState()
    );
  }

  render() {
    return (
      <div>
        <div className={styles.header}>
          <div className="inlineBlock">{ this.state.addButton }</div>
          <span>
            <FilterForm

              handleFilter={this.filter}
              clearFilter={this.clearFilter}
              fields={this.state.filterFormFields}
            />
            <div className="clearBoth" />
          </span>
        </div>
        { (this.props.property === 'driver' || this.props.property === 'drivers') &&
          <DriverList {...this.props} scrollHandler={this.scrollHandler} drivers={this.state.objects} toggleFilter={this.toggleFilter} loaded={this.state.loaded} />
        }
        { this.props.property === 'vendor' &&
          <VendorList {...this.props} scrollHandler={this.scrollHandler} vendors={this.state.objects} toggleFilter={this.toggleFilter} loaded={this.state.loaded} />
        }
        { this.props.property === 'vehicle' &&
          <VehicleList {...this.props} scrollHandler={this.scrollHandler} vehicles={this.state.objects} toggleFilter={this.toggleFilter} loaded={this.state.loaded} chooseDriversHandler={this.chooseDriversFromVehicleList} />
        }
        { this.props.property === 'trailer' &&
          <TrailerList {...this.props} scrollHandler={this.scrollHandler} trailers={this.state.objects} toggleFilter={this.toggleFilter} loaded={this.state.loaded} />
        }
      </div>
    );
  }
}

ChooseOrAddList.propTypes = {
  label: PropTypes.string.isRequired,
  property: PropTypes.string.isRequired,
};

export default ChooseOrAddList;
