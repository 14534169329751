import React from 'react';
import PropTypes from 'prop-types';

// API
import { downloadReceipts } from 'api/Getters';

// Components
import Title from 'components/LayoutTitle/view/Title';
import FilterForm from 'components/FilterForm/container/FilterForm';
import DriverReceiptsInfoTable from 'components/DriverDetails/container/DriverReceiptsInfoTable';

// CSS
import styles from './ReceiptsLayout.module.scss';

class ReceiptsLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receiptsToDownload: [],
      receiptDownloadStatus: 0, // 0: nothing, 1: loading, 2: ready
      receiptFilter: undefined,
    };
    this.state.filterFields = [
      { attrName: 'fromDate', fullName: 'From', placeholder: '.....', type: 'date' },
      { attrName: 'toDate', fullName: 'To', placeholder: '.....', type: 'date' },
    ];
    this.downloadReceipts = this.downloadReceipts.bind(this);
    this.filterSubmit = this.filterSubmit.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.addToReceiptsToDownload = this.addToReceiptsToDownload.bind(this);
  }

  downloadReceipts() {
    let newState = { ...this.state, receiptDownloadStatus: 1 };
    this.setState(newState, () => {
      downloadReceipts(this.state.receiptsToDownload).then((parseReceiptsObject) => {
        newState = { ...this.state, receiptDownloadStatus: 2 };
        newState.receiptDownloadUrl = parseReceiptsObject.get('file').url();
        this.setState(newState);
      });
    });
  }

  filterSubmit(filterObject) {
    this.setState({ ...this.state, receiptFilter: filterObject });
  }

  clearFilter() {
    this.setState({ ...this.state, receiptFilter: undefined });
  }

  addToReceiptsToDownload(receipt) {
    // if receipt is already in state, remove it (toggled)
    const newState = { ...this.state };
    const { receiptsToDownload } = this.state;

    let receiptIndex = -1;
    for (let i = 0; i < receiptsToDownload.length; i++) {
      if (receiptsToDownload[i].id === receipt.id) {
        receiptIndex = i;
        break;
      }
    }
    if (receiptIndex === -1) {
      newState.receiptsToDownload.push(receipt);
    } else {
      newState.receiptsToDownload.splice(receiptIndex, 1);
    }
    newState.receiptDownloadStatus = 0;
    this.setState(newState);
  }

  render() {

    const { driverObjectId } = this.props;

    return (
      <div className={styles.receiptsLayout}>
        <Title superClassName={styles.title} title="Receipts">
          <span>
            <FilterForm
              className={styles.filterForm}
              handleFilter={this.filterSubmit}
              clearFilter={this.clearFilter}
              fields={this.state.filterFields}
            />
          </span>

          <span className={styles.buttonGroup}>
            {this.state.receiptsToDownload.length > 0 && this.state.receiptDownloadStatus === 0 &&
              <button
                className="buttonDefault buttonSpacedRight backgroundRed"
                onClick={() => this.downloadReceipts()}
              >Queue For Download</button>
            }
            {this.state.receiptsToDownload.length > 0 && this.state.receiptDownloadStatus === 1 &&
              <button className="buttonDefault buttonSpacedRight" disabled>Loading...</button>
            }
            {this.state.receiptsToDownload.length > 0 && this.state.receiptDownloadStatus === 2 &&
              <button className="buttonDefault buttonSpacedRight">
                <a className="textWhite" href={this.state.receiptDownloadUrl}>Download Selected</a>
              </button>
            }
          </span>
        </Title>

        <DriverReceiptsInfoTable
          className={styles.maxTableHeight}
          driverObjectId={driverObjectId}
          filter={this.state.receiptFilter}
          receiptsToDownload={this.addToReceiptsToDownload}
        />
      </div>
    );
  }
}

ReceiptsLayout.propTypes = {
  driverObjectId: PropTypes.string,
};

export default ReceiptsLayout;
