import * as ActionConstants from '../actions/ActionConstants';

const initialState = {
  eldEvents: [],
  eldDailyCertification: undefined,
  nextELDDailyCertification: undefined,
  previousELDDailyCertification: undefined,
  eldEdits: [],
  fetched: false,
};

export function ELDEvent(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.FETCH_ELDEVENTS_INPROGRESS: {
      return {
        ...state,
        status: ActionConstants.FETCH_ELDEVENTS_INPROGRESS,
        error: undefined,
      };
    }
    case ActionConstants.FETCH_ELDEVENTS_SUCCESS: {
      return {
        status: ActionConstants.FETCH_ELDEVENTS_SUCCESS,
        eldEvents: state.eldEvents.concat(action.eldEventsInfo.eldEvents),
        eldDailyCertification: action.eldEventsInfo.eldDailyCertification,
        nextELDDailyCertification: action.eldEventsInfo.nextELDDailyCertification,
        previousELDDailyCertification: action.eldEventsInfo.previousELDDailyCertification,
        eldEdits: action.eldEventsInfo.eldEdits,
        error: undefined,
        fetched: true,
      };
    }
    case ActionConstants.FETCH_ELDEVENTS_ERROR: {
      return {
        ...state,
        status: ActionConstants.FETCH_ELDEVENTS_ERROR,
        error: action.error,
        fetched: false,
      };
    }
    case ActionConstants.DELETE_ELDEVENTS: {
      return {
        ...initialState,
        status: action.type,
      };
    }
    // case ActionConstants.ADD_ELDEVENT_SUCCESS: {
    //   const updatedELDEvents = [].concat(state.eldEvents);
    //   updatedELDEvents.unshift(action.eldEvent);
    //   return {
    //     ...state,
    //     status: ActionConstants.type,
    //     eldEvents: updatedELDEvents,
    //     error: undefined,
    //   };
    // }
    default:
      return state;
  }
}
