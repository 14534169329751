import React from 'react';
import PropTypes from 'prop-types';
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBBtn,
  MDBIcon,
} from 'mdbreact';

// API
import { getAttribute, getCurrentUser } from 'api/Parse';
import {
  getEquipmentGroupUsers,
  addUserToEquipmentGroupUser,
  removeUserFromEquipmentGroup,
} from 'api/Equipment/Groups.old';

// Enums
import { QueryRestrictionTypes } from 'enums/Query';

// sbObjects
import Filter from 'sbObjects/Filter';

// Components
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import UserAutocomplete from 'components/Shared/UserAutocomplete/UserAutocomplete';
import SBTable from 'components/Shared/SBTable/SBTable';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import Title from 'components/LayoutTitle/view/Title';
import LoadingIcon from 'components/LoadingIcon/view/LoadingIcon';
import ConfirmModal from 'components/ConfirmModal/view/ConfirmModal';

class EquipmentGroupUsersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showAddUserModal: false,
      showConfirmModal: false,
      userRecord: undefined,
      userRecords: [],
      selectedUser: undefined,
      page: 0,
      pageCount: 0,
      pageLimit: 15,
      filterFormFields: [
        {
          attrName: 'lastName',
          fullName: 'Last Name',
          queryType: QueryRestrictionTypes.MATCHES,
          type: 'text',
        },
        {
          attrName: 'firstName',
          fullName: 'First Name',
          queryType: QueryRestrictionTypes.MATCHES,
          type: 'text',
        },
      ],
      fieldFiltersToQuery: [],
    };

    this.refreshState = this.refreshState.bind(this);
    this.handlePaginatorOnChange = this.handlePaginatorOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleFieldsFilter = this.handleFieldsFilter.bind(this);
  }

  componentDidMount() {
    this.refreshState();
  }

  async refreshState() {
    const { props, state } = this;

    this.setState({ isLoading: true });

    const { userRecords, userRecordsCount } = await getEquipmentGroupUsers(
      props.group,
      state.page,
      undefined,
      undefined,
      state.fieldFiltersToQuery,
    );

    const pageCount = Math.ceil(userRecordsCount / state.pageLimit);

    this.setState({ userRecords, pageCount, isLoading: false });
  }

  async handlePaginatorOnChange(type, inputValue) {
    const { state } = this;

    let page = state.page;

    if (type === 'PREVIOUS') {
      page--;

      if (page < 0) {
        page = 0;
      }
    } else if (type === 'NEXT') {
      page++;

      if (page >= state.pageCount - 1) {
        page = state.pageCount - 1;
      }
    } else if (type === 'SUBMIT') {
      let newPage = inputValue - 1;

      if (newPage < 0) {
        newPage = 0;
      } else if (newPage >= (state.pageCount - 1)) {
        newPage = state.pageCount - 1;
      }

      page = newPage;
    }

    this.setState({ page }, async () => await this.refreshState());
  }

  async handleSubmit() {
    const { props, state } = this;

    await addUserToEquipmentGroupUser(props.group, state.selectedUser);

    this.setState({ selectedUser: undefined, showAddUserModal: false }, async () => await this.refreshState());
  }

  async handleDelete(bool) {
    const { props, state } = this;

    if (bool) {
      await removeUserFromEquipmentGroup(props.group, state.userRecord);
      this.setState({ showConfirmModal: false }, async () => await this.refreshState());
    } else {
      this.setState({ showConfirmModal: false });
    }
  }

  async handleFieldsFilter(filters = []) {
    const fieldFiltersToQuery = filters.map(filter => {
      return new Filter(filter.attribute, filter.value, filter.queryType);
    });

    this.setState({ fieldFiltersToQuery }, async () => await this.refreshState());
  }

  render() {
    const { props, state } = this;

    const tableHeaderRows = [{
      key: 'group-users-list',
      columns: [
        {
          element: <div>First Name</div>,
          props: {
            style: {
              width: '33%',
            },
          },
        },
        {
          element: <div>Last Name</div>,
          props: {
            style: {
              width: '33%',
            },
          },
        },
        {
          element: <div />,
          props: {
            style: {
              width: '34%',
            },
          },
        },
      ],
    }];

    let tableBodyRows = state.userRecords.map(record => {
      const objectId = getAttribute(record, 'objectId');

      const firstName = getAttribute(record, 'firstName');
      const lastName = getAttribute(record, 'lastName');

      const createdByUser = getAttribute(props.group, 'createdBy');
      const createdByUserObjectId = getAttribute(createdByUser, 'objectId');

      const currentUser = getCurrentUser();
      const currentUserObjectId = getAttribute(currentUser, 'objectId');

      return {
        key: objectId,
        columns: [
          {
            element: <div>{ firstName }</div>,
          },
          {
            element: <div>{ lastName }</div>,
          },
          {
            element: (
              <ActionsContainer>
                {createdByUserObjectId === currentUserObjectId && currentUserObjectId !== objectId && (
                  <MDBBtn
                  size="sm"
                  color="danger"
                  onClick={() => this.setState({ userRecord: record, showConfirmModal: true })}
                  >
                    <MDBIcon className="mr-1" icon="trash" />
                    Remove
                  </MDBBtn>
                )}
                {createdByUserObjectId !== currentUserObjectId && objectId === currentUserObjectId && (
                  <MDBBtn
                  size="sm"
                  color="danger"
                  onClick={() => this.setState({ userRecord: record, showConfirmModal: true })}
                  >
                    <MDBIcon className="mr-1" icon="trash" />
                    Leave
                  </MDBBtn>
                )}
              </ActionsContainer>
            ),
          }
        ],
      }
    });

    return (
      <MDBContainer fluid>
        <MDBModal isOpen={state.showAddUserModal} centered data-backdrop="static">
          <MDBModalHeader>
            Add User
          </MDBModalHeader>
          <MDBModalBody>
            <UserAutocomplete
              onUserSelected={(selectedUser) => this.setState({ selectedUser })}
              allowDeselect
            />
          </MDBModalBody>
          <MDBModalFooter>
            <ActionsContainer>
              <MDBBtn
                color="default"
                size="sm"
                onClick={() => this.setState({ selectedUser: undefined, showAddUserModal: false })}
              >
                Cancel
              </MDBBtn>
              <MDBBtn
                color="primary"
                size="sm"
                onClick={this.handleSubmit}
                disabled={!state.selectedUser}
              >
                Submit
              </MDBBtn>
            </ActionsContainer>
          </MDBModalFooter>
        </MDBModal>
        {state.userRecord && getAttribute(state.userRecord, 'objectId') === getAttribute(getCurrentUser(),'objectId') && (
          <ConfirmModal
            show={state.showConfirmModal}
            title="Leave Group"
            body="Are you sure you want to leave this group?"
            handleClose={() => this.setState({ showConfirmModal: false })}
            handleModalChoice={async (bool) => await this.handleDelete(bool)}
          />
        )}
        {state.userRecord && getAttribute(state.userRecord, 'objectId') !== getAttribute(getCurrentUser(), 'objectId') && (
          <ConfirmModal
            show={state.showConfirmModal}
            title="Remove User"
            body="Are you sure you want to remove this user?"
            handleClose={() => this.setState({ showConfirmModal: false })}
            handleModalChoice={async (bool) => await this.handleDelete(bool)}
          />
        )}
        <MDBRow  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <MDBCol xs='12' lg='5'>
            <Title title={getAttribute(props.group, 'name')} />
          </MDBCol>
          <MDBCol xs='12' lg='5'>
            <FilterForm
              fields={state.filterFormFields}
              handleFilter={this.handleFieldsFilter}
              clearFilter={() => this.handleFieldsFilter([])}
            />
          </MDBCol>
          <MDBCol xs='12' lg='2'>
            <ActionsContainer>
              <MDBBtn
                size="sm"
                color="primary"
                onClick={() => this.setState({ showAddUserModal: true })}
              >
                <MDBIcon className="mr-1" icon="plus" />
                User
              </MDBBtn>
            </ActionsContainer>
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol>
            <SBTable
              tableHeaderRows={tableHeaderRows}
              tableBodyRows={tableBodyRows}
              showPaginator
              activePage={state.page + 1}
              pageCount={state.pageCount}
              handlePaginatorOnChange={this.handlePaginatorOnChange}
              paginatorContainerClassName="paginator-container"
            />
          </MDBCol>
        </MDBRow>
        {state.isLoading && (
          <LoadingIcon />
        )}
      </MDBContainer>
    );
  }
}

EquipmentGroupUsersList.propTypes = {
  group: PropTypes.object.isRequired,
};

export default EquipmentGroupUsersList;
