import { t } from 'api/Translate';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentTz from 'moment-timezone';
import history from 'sbHistory';

// api
import { getAttribute, getCurrentUser } from 'api/Parse';
import { getObjectDeepCopy } from 'api/Getters';
import { formatName, formatStringLength, isSubscribedToModule, isZeroVehicleLocation } from 'api/Helpers';
import { getDrivers } from 'api/Driver/Driver';
import { mapELDDates, getCurrentELDVersion, setELDEventDataOnELDEvents } from 'api/ELD';


// enums
import { QuerySortOrderTypes, QueryRestrictionTypes } from 'enums/Query';
import { AttributeTypes } from 'enums/Driver';

// sbobjects
import Filter from 'sbObjects/Filter';
import Sort from 'sbObjects/Sort';

// components
import SBCardEmptyContent from 'components/Shared/SBCard/SBCardEmptyContent';
import SBTable from 'components/Shared/SBTable/SBTable';
import ActionsContainer from 'components/Shared/ActionsContainer/ActionsContainer';
import FilterForm from 'components/FilterForm/container/FilterForm.new';
import SBSelect from 'components/Shared/SBSelect/SBSelect';
import DutyStatusBadge from 'sbCore/DutyStatusBadge/DutyStatusBadge';
import Alert from 'components/Alert/view/Alert';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';

// css
import styles from './style.module.scss';

class DriversTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      drivers: [],
      latestELDEvents: [],
      currentELDVersion: -1,
      fetchAttributes: {
        page: 0,
        count: 6,
        sortBy: new Sort(AttributeTypes.USER_FULLNAME, QuerySortOrderTypes.ASCENDING),
        filters: [],
        driverTypeFilter: new Filter('enabled', true, QueryRestrictionTypes.EQUAL_TO),
      },
      pageCount: 1,
      version: [],
    };

    this.state.filterFormFields = [
      {
        attrName: 'user_username',
        queryType: QueryRestrictionTypes.MATCHES,
        fullName: t('Username'),
        type: 'text',
      },
      {
        attrName: 'vehicle_unitId',
        queryType: QueryRestrictionTypes.MATCHES,
        fullName: t('Vehicle'),
        type: 'text',
      },
      {
        attrName: 'user_fullName',
        queryType: QueryRestrictionTypes.MATCHES,
        fullName: t('Driver Name'),
        type: 'text',
      },
    ];

    this.state.selectableDriverTypes = [
      {
        key: 'driver-type-active',
        value: true, // enabled to true
        label: t('Active Drivers'),
      },
      {
        key: 'driver-type-inactive',
        value: false, // enabled to false
        label: t('Inactive Drivers'),
      },
    ];

    this.toggleLoadingState = this.toggleLoadingState.bind(this);
    this.refreshState = this.refreshState.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handlePaginatorOnChange = this.handlePaginatorOnChange.bind(this);
    this.getSelectedDriverType = this.getSelectedDriverType.bind(this);
  }

  componentDidMount() {
    // this.handleFilter();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.parentId !== this.props.parentId) {
      // the parent refreshed it's state - we should trigger refreshState here as well
      this.handleFilter();
    }
  }

  async toggleLoadingState(state) {
    return await this.setState({ ...state, isLoading: !state.isLoading });
  }

  async refreshState() {
    await this.toggleLoadingState(this.state);
    const { fetchAttributes } = this.state;
    const newState = { ...this.state };

    // fetch all drivers (child companies included)
    const driversInfo = await getDrivers(
      fetchAttributes.page,
      fetchAttributes.count,
      fetchAttributes.sortBy,
      fetchAttributes.filters,
      true,
      false,
      true,
    );
    const { drivers, totalDriversCount } = driversInfo;
    const latestELDEventPromises = drivers.map(async driver => {
      const latestELDEvent = getAttribute(driver, 'latestELDEvent');
      if (latestELDEvent) await setELDEventDataOnELDEvents([latestELDEvent]);
      return latestELDEvent;
    });
    const latestELDEvents = await Promise.all(latestELDEventPromises);
    const eldDates = await mapELDDates(drivers);
    const currentELDVersion = await getCurrentELDVersion();

    newState.drivers = drivers;
    newState.latestELDEvents = latestELDEvents;
    newState.eldDates = eldDates;
    newState.currentELDVersion = currentELDVersion;
    const pageCount = Math.ceil(totalDriversCount / fetchAttributes.count) || 1;
    if (pageCount !== newState.pageCount) newState.pageCount = pageCount;

    await this.toggleLoadingState(newState);
    if (document.querySelector('.drivers-table')) document.querySelector('.drivers-table').scrollTop = 0;
  }

  async handleFilter(filters = []) {
    const newState = { ...this.state };
    newState.fetchAttributes = getObjectDeepCopy(newState.fetchAttributes);

    newState.fetchAttributes.filters = filters.map(filterObject => {
      const filter = new Filter(filterObject.attribute, filterObject.value, filterObject.queryType || filterObject.queryRestriction);
      return filter;
    });

    const driverTypeFilter = this.state.fetchAttributes.driverTypeFilter;
    if (driverTypeFilter) newState.fetchAttributes.filters.push(driverTypeFilter);
    newState.fetchAttributes.page = 0;

    await this.setState(newState);
    // document.querySelector('.drivers-layout').scrollTop = 0;
    return await this.refreshState();
  }

  async handleSort(attribute) {
    const newState = { ...this.state };
    const fetchAttributes = getObjectDeepCopy(newState.fetchAttributes);

    let order = fetchAttributes.sortBy.order;
    if (attribute === fetchAttributes.sortBy.attribute) {
      // selected the same attribute to sort, which means sort inverse order
      if (order === QuerySortOrderTypes.DESCENDING) {
        order = QuerySortOrderTypes.ASCENDING;
      } else {
        order = QuerySortOrderTypes.DESCENDING;
      }
    } else {
      // default to descending order if different
      order = QuerySortOrderTypes.DESCENDING;
    }

    fetchAttributes.sortBy = new Sort(attribute, order);

    // reset page
    fetchAttributes.page = 0;

    newState.fetchAttributes = fetchAttributes;

    await this.setState(newState);
    return await this.refreshState();
  }

  async handlePaginatorOnChange(type, inputValue) {
    const { fetchAttributes, pageCount } = this.state;
    const newState = { ...this.state };
    const updatedFetchAttributes = getObjectDeepCopy(fetchAttributes);
    newState.fetchAttributes = updatedFetchAttributes;

    if (type === 'PREVIOUS') {
      updatedFetchAttributes.page--;
      if (updatedFetchAttributes.page < 0) updatedFetchAttributes.page = 0; // don't let values before 0
    } else if (type === 'NEXT') {
      updatedFetchAttributes.page++;
      if (updatedFetchAttributes.page >= pageCount - 1) updatedFetchAttributes.page = pageCount - 1;
    } else if (type === 'SUBMIT') {
      let page = inputValue - 1; // the actual value is one less, since we pass +1 page in the component (so paging doesnt start at 0)

      if (page < 0) {
        page = 0;
      } else if (page >= (pageCount - 1)) {
        page = pageCount - 1;
      }
      updatedFetchAttributes.page = page;
    }

    await this.setState(newState);
    // document.querySelector('.drivers-list-select').scrollTop = 0;
    // document.getElementsByTagName('body').scrollTop;
    // window.scrollTo(0,0)
    await this.refreshState();
  }

  async getSelectedDriverType(selectedDriverType) {
    // additional filters to append to the current filters
    const driverTypeFilter = new Filter('enabled', selectedDriverType.value, QueryRestrictionTypes.EQUAL_TO);

    const newState = { ...this.state };
    newState.fetchAttributes = getObjectDeepCopy(this.state.fetchAttributes);
    newState.fetchAttributes.driverTypeFilter = driverTypeFilter;
    await this.setState(newState);
    return await this.handleFilter(this.state.fetchAttributes.filters);
  }

  render() {
    const { state } = this;
    const { fetchAttributes } = state;

    const currentUser = getCurrentUser();
    const belongsToCompany = currentUser && getAttribute(currentUser, 'belongsToCompany');
    const enableCompanyLinks = belongsToCompany && getAttribute(belongsToCompany, 'enableCompanyLinks');

    /**
     * TABLE STYLES
     */
    let tableHeaderStyles = {
      dutyStatusLabel: { width: '10%' },
      driverName: { width: '20%' },
      alert: { width: '10%' },
      vehicleUnitId: { width: '15%' },
      shippingDocumentNumber: { width: '15%' },
      phoneNumber: { width: '10%' },
      options: { width: '20%' },
    };

    if (enableCompanyLinks) {
      tableHeaderStyles = {
        dutyStatusLabel: { width: '10%' },
        driverName: { width: '15%' },
        alert: { width: '5%' },
        companyName: { width: '15%' },
        vehicleUnitId: { width: '15%' },
        shippingDocumentNumber: { width: '10%' },
        phoneNumber: { width: '15%' },
        options: { width: '15%' },
      };
    }

    /**
     * TABLE HEADER/BODY
     */
    let tableHeaderColumns = [
      {
        element: <div />,
        props: {
          style: tableHeaderStyles.alert,
        },
      },
      {
        element: <div>Driver</div>,
        props: {
          style: tableHeaderStyles.driverName,
          handleSort: () => { this.handleSort(AttributeTypes.USER_FULLNAME); },
          isSortActive: (fetchAttributes.sortBy.attribute === AttributeTypes.USER_FULLNAME),
          sortOrder: fetchAttributes.sortBy.order,
        },
      },
      {
        element: <div></div>,
        props: {
          style: tableHeaderStyles.phoneNumber,
          // handleSort: () => { this.handleSort(AttributeTypes.USER_PHONE_NUMBER); },
          isSortActive: (fetchAttributes.sortBy.attribute === AttributeTypes.USER_PHONE_NUMBER),
          // sortOrder: fetchAttributes.sortBy.order,
        },
      },
      {
        element: <div />,
        props: {
          style: tableHeaderStyles.dutyStatusLabel,
        },
      },
      {
        element: <div>Vehicle</div>,
        props: {
          style: tableHeaderStyles.vehicleUnitId,
          handleSort: () => { this.handleSort(AttributeTypes.VEHICLE_UNIT_ID); },
          isSortActive: (fetchAttributes.sortBy.attribute === AttributeTypes.VEHICLE_UNIT_ID),
          sortOrder: fetchAttributes.sortBy.order,
        },
      },
      {
        element: <div></div>,
        props: {
          style: tableHeaderStyles.companyName,
        },
        id: 'companyName',
      },
      {
        element: <div>Job / Shipment</div>,
        props: {
          style: tableHeaderStyles.shippingDocumentNumber,
        },
      },
      { element: <div />, props: { style: tableHeaderStyles.options } },
    ];

    // if don't show company names, filter out the column that shows it (id: companyName)
    if (!enableCompanyLinks) tableHeaderColumns = tableHeaderColumns.filter(column => column.id !== 'companyName');

    const tableHeaderRows = [{
      key: 'drivers-list', columns: tableHeaderColumns,
    }];

    const tableBodyRows = state.drivers.map((driver, index) => {
      const objectId = getAttribute(driver, 'objectId');
      const driverUser = getAttribute(driver, 'user');
      const belongsToCompany = getAttribute(driver, 'belongsToCompany');
      let belongsToCompanyName = getAttribute(belongsToCompany, 'name');
      if (belongsToCompanyName.length > 22) belongsToCompanyName = formatStringLength(belongsToCompanyName, 19);

      let fullName = driverUser ? formatName(`${getAttribute(driverUser, 'firstName')} ${getAttribute(driverUser, 'lastName')}`) : '';
      if (fullName.length > 22) fullName = formatStringLength(fullName, 19);

      let phoneNumber = driverUser ? getAttribute(driverUser, 'phoneNumber') : '';
      if (phoneNumber && phoneNumber.length > 15) phoneNumber = formatStringLength(phoneNumber, 12);

      let vehicleUnitId = getAttribute(driver, 'vehicle_unitId') || '';
      if (vehicleUnitId.length > 15) vehicleUnitId = formatStringLength(vehicleUnitId, 12);

      const latestELDEvent = state.latestELDEvents[index];
      const shippingDocumentNumber = latestELDEvent?.shippingDocumentNumber || getAttribute(latestELDEvent, 'shippingDocumentNumber', true) || '-';

      const licenseExpiryDate = getAttribute(driver, 'licenseExpiryDate');
      // const driverCurrentELDVersion = getAttribute(driver, 'currentELDVersion');
      const eldStatusInt = getAttribute(driver, 'eldStatusInt');
      const latestELDEvent_eventDateTime = getAttribute(driver, 'latestELDEvent_eventDateTime');
      const vehicleLocation = getAttribute(latestELDEvent, 'vehicleLocation', true);
      const status = getAttribute(driver, 'driverStatus');

      let isDriverActive = false;
      if (status && (getAttribute(status, 'referenceInt') === 2)) isDriverActive = true;

      // figuring out if device is out of date
      let deviceUpdateNeeded = true;
      const eldObject = state.eldDates.find(function (eldObject) {
        return eldObject.id === objectId;
      });
      if (eldObject && eldObject.date) {
        const durationDiff = Date.now() - eldObject.date;
        const duration = moment.duration(durationDiff, 'milliseconds');
        const weeks = duration.asWeeks();
        if (weeks < 2) {
          deviceUpdateNeeded = false;
        }
      } else {
        deviceUpdateNeeded = false;
      }
      const outOfDateMessage = (
        <p className="m-0 p-0">
          {t('Version may be out of date, update to')} {state.currentELDVersion}
        </p>
      );
      const alertMessageWithExp = (
        <div>
          {t('License expires')} {momentTz(licenseExpiryDate).format('LL')} {deviceUpdateNeeded ? outOfDateMessage : null}
        </div>
      );
      const alertMessageNoExp = (
        <div>
          {t('No Drivers License Expiry')} {deviceUpdateNeeded ? outOfDateMessage : null}
        </div>
      );

      const driverRowObj = {
        key: objectId,
        columns: [],
        props: {},
      };

      driverRowObj.columns = [
        {
          element: (
            <div>
              {licenseExpiryDate && momentTz(licenseExpiryDate).subtract(4, 'months').isBefore(momentTz()) &&
                <Alert tooltipContent={alertMessageWithExp} handleClick={() => { history.push({ pathname: 'driver', search: "driver=" + objectId }); }} />
              }
              {!licenseExpiryDate &&
                <Alert tooltipContent={alertMessageNoExp} handleClick={() => { history.push({ pathname: 'driver', search: "driver=" + objectId }); }} />
              }
            </div>
          ),
          props: { className: 'table-body-column-style' }
        },
        {
          element: (
            <a
              onClick={(e) => { e.preventDefault(); history.push({ pathname: 'driver', search: "driver=" + objectId }); }}
            >
              <div>
                <b>{fullName}</b>
              </div>
            </a>
          ),
          props: { className: 'table-body-column-style' }
        },
        {
          element: (
            <div>
              <div>{phoneNumber}</div>
              <div className={styles.companyName}>{belongsToCompanyName}</div>
            </div>
          ),
          props: { className: 'table-body-column-style' }
        },
        {
          element: (
            <div>
              {(eldStatusInt !== undefined) && (latestELDEvent_eventDateTime && momentTz(latestELDEvent_eventDateTime).isAfter(momentTz().startOf('day'))) &&
                <a
                  onClick={(e) => { e.preventDefault(); history.push({ pathname: 'driver', search: `view=hosEvents&driver=${objectId}` }); }}
                >
                  <DutyStatusBadge className="z-depth-0" eldStatusInt={eldStatusInt} />
                </a>
              }
            </div>
          ), props: { className: 'table-body-column-style' }
        },
        {
          element: (
            <div>
              {
                (eldStatusInt !== undefined) &&
                (latestELDEvent_eventDateTime && momentTz(latestELDEvent_eventDateTime).isAfter(momentTz().startOf('day'))) &&
                (
                  <div>
                    {vehicleUnitId}
                  </div>
                )
              }
            </div>
          ),
          props: { className: 'table-body-column-style' }
        },
        {
          element: (
            <div>
              {
                vehicleLocation
                && !isZeroVehicleLocation(vehicleLocation)
                && (eldStatusInt !== undefined)
                && (latestELDEvent_eventDateTime
                  && momentTz(latestELDEvent_eventDateTime).isAfter(momentTz().startOf('day')))
                &&
                (
                  <div>
                    <a
                      href={isSubscribedToModule('mapModule') ? `/map` : `https://maps.google.com/maps?q=${vehicleLocation?.get('location')?.latitude},${vehicleLocation?.get('location')?.longitude}&t=h&iwd=0&z=18`}
                      target="_blank"
                    >
                      <div><b>{`${vehicleLocation?.get('aprxShortName')}, ${vehicleLocation?.get('stateProvince')?.toUpperCase()}`}</b></div>
                      <div>{`${vehicleLocation?.get('location')?.latitude?.toFixed(4)}, ${vehicleLocation?.get('location')?.longitude?.toFixed(4)}`}</div>
                    </a>
                  </div>
                )
              }
            </div>
          ),
          props: { className: 'table-body-column-style' },
          id: 'companyName'
        },
        {
          element: (
            <div>
              {
                latestELDEvent_eventDateTime
                && momentTz(latestELDEvent_eventDateTime).isAfter(momentTz().startOf('day'))
                &&
                (
                  <div>
                    {shippingDocumentNumber}
                  </div>
                )
              }
            </div>
          ),
          props: { className: 'table-body-column-style' }
        },
        {
          element: (
            <div>
              <MDBBtn
                size="sm"
                color="secondary"
                target="_blank"
                className="translate-me"
                href={`/driver?driver=${objectId}`}
                onClick={(e) => { e.preventDefault(); history.push({ pathname: 'driver', search: "driver=" + objectId }); }}
              >
                View Details
              </MDBBtn>
            </div>
          ),
          props: { className: 'table-body-column-style', style: { textAlign: 'right' } },
        },
      ];

      // if don't show company names, filter out the column that shows it (id: companyName)
      if (!enableCompanyLinks) driverRowObj.columns = driverRowObj.columns.filter(column => column.id !== 'companyName');

      return driverRowObj;
    });

    return (
      <MDBContainer className="drivers-table p-0" fluid>
        <ActionsContainer>
          <FilterForm
            className="d-inline-block translate-me"
            handleFilter={this.handleFilter}
            clearFilter={() => this.handleFilter([])}
            fields={state.filterFormFields}
          />

          <SBSelect
            containerClassName="ml-2 d-inline-block"
            className="drivers-list-select translate-me"
            defaultToggleText="Active Drivers"
            items={state.selectableDriverTypes}
            getSelectedItems={(selectedItems) => this.getSelectedDriverType(selectedItems[0])}
          />
        </ActionsContainer>

        <MDBRow>
          <MDBCol>
            <div className={styles.driversTable}>
              <SBTable
                hover
                height="47vh"
                tableHeaderRows={tableHeaderRows}
                tableBodyRows={tableBodyRows}
                showPaginator
                activePage={state.fetchAttributes.page + 1}
                pageCount={state.pageCount}
                handlePaginatorOnChange={this.handlePaginatorOnChange}
                paginatorContainerClassName={styles.paginatorContainer}
                emptyTableMessage={(
                  <div>
                    {((!state.isLoading) && (tableBodyRows.length === 0)) && <div className="text-center translate-me">No Drivers found. You can add a Driver by clicking the <b>Add Driver</b> button</div>}
                    {((state.isLoading)) && <SBCardEmptyContent containerClassName="empty-table-loader translate-me" isContentLoader>{'Refreshing Drivers'}</SBCardEmptyContent>}
                  </div>
                )}
              />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

DriversTable.propTypes = {
  parentId: PropTypes.any.isRequired, // uniqid of parent
};

export default DriversTable;
