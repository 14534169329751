/**
 * Behrang Sabeghi 2019-11-02
 */

/** @module URL */

/**
 * @memberof module:URL
 * @description Adds a query parameter to the url or replaces the value if it already exists.
 * @param {*} url
 * @param {*} paramName
 * @param {*} paramValue
 */
const addReplaceQueryParameter = (url, paramName, paramValue) => {
    let hasQ = url.indexOf("?");
    if (hasQ > 0) {
        console.error("Strange input for URL, the returned value is not gauranteed!");
    }
    let query = url.substring(hasQ + 1);
    let vars = query.split('&');
    let result = "";
    let exists = false;
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        result += (i != 0 ? "&" : "") + pair[0] + "=";
        if (decodeURIComponent(pair[0]) == paramName) {
            result += paramValue;
            exists = true;
        } else {
            result += pair[1];
        }
    }
    if (!exists) {
        result = (result.length > 0 ?
            result + "&" + encodeURIComponent(paramName) + "=" + encodeURIComponent(paramValue) :
            encodeURIComponent(paramName) + "=" + encodeURIComponent(paramValue));
    }
    if (hasQ > -1) {
        return url.substring(0, hasQ) + result;
    }
    return result;
}

/**
 * @memberof module:URL
 * @description Given an object, it returns a string containing the object key/value paires in URL parameter format
 * @param {*} object
 */
const getQueryFromObject = (object) => {
    let keys = Object.keys(object);
    let query = "";
    for (let i = 0; i < keys.length; i++) {
        let current = keys[i] + "=" + object[keys[i]];
        if (i == 0) {
            query = current;
        } else {
            query += "&" + current;
        }
    }
    return query;
}

/**
 * @memberof module:URL
 * @description Returns the value of the query parameter or undefined if paramName did not match a query parameter. Query String/Querystring stuff
 * @param {*} url
 * @param {*} paramName
 */
const getQueryParameter = (url, paramName) => {
    let urlString = url || window.location.search;
    let query = urlString.substring(urlString.indexOf("?") + 1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == paramName) {
            return decodeURIComponent(pair[1]);
        }
    }
    return '';
}

/**
 * @memberof module:URL
 * @description Checks if the provided URL contains any query parameters
 * @param {*} url
 */
const hasQuery = (url) => {
    let index = url.indexOf("?");
    return index != -1 ? url.trim().substring(index).length > 1 : false;
}

/**
 * @memberof module:URL
 * @description given an object of params and their values, return a serialized version of params and the resulting query string
 * @param {object} params
 */
function getSerialized(params = {}) {
	const serialized = {
  	query: '', // ex. "?origin=49.2848%2C-123.1119&destination=49.2778%2C-123.1088&simhrs=14"
    params: {}, // serialized params ex. { origin: 49.2848%2C-123.1119, ... }
  };

  Object.keys(params).map((p, index) => {
    if (params[p]) {
      const serializedParam = encodeURIComponent(params[p]);
      if (!index) {
        serialized.query += `?${p}=${serializedParam}`;
      } else {
        serialized.query += `&${p}=${serializedParam}`;
      }
      serialized.params[p] = serializedParam;
    }
  });

  return serialized;
}

/**
 * @memberof module:URL
 * @description given a serialized string, deserialize it -and- deconstruct the url to object params (if applicable)
 * Reminder that if you just want to decode a string you can simply use decodeURIComponent(str)
 * @param {string} serializedStr
 */
function getDeserialized(serializedStr = '') {
    const deserialized = {
        result: '',
        params: {},
    };

    deserialized.result = decodeURIComponent(serializedStr);

    let resultSplit = deserialized.result.split('?'); // get rid of question mark if provided ['?', someSerializedString]
    resultSplit = resultSplit[1] ? resultSplit[1] : resultSplit[0];
    resultSplit = resultSplit.split('&');

    resultSplit.map(paramPair => {
        if (paramPair) { // resultSplit could have an empty string element
            const paramPairSplit = paramPair.split('=');
            deserialized.params[paramPairSplit[0]] = paramPairSplit[1];
        }
    });

    return deserialized;
}

/**
 * @memberof module:URL
 * @param {String} hostname - A URL hostname. Example: app.localhost, app.onswitchboard, etc
 * @returns {Array} - Array breakdown of the hostname. Example: ['app', 'localhost']
 */
function getDestructuredHostname(hostname = '') {
    const destructedHostname = hostname.split('.');
    return destructedHostname;
}

export {
    addReplaceQueryParameter,
    getDeserialized,
    getDestructuredHostname,
    getSerialized,
    getQueryFromObject,
    getQueryParameter,
    hasQuery
};
