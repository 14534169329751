
import { useState } from 'react';

// api
import { updateDriverDocument } from 'api/Driver/Documents';

// components
import Dialog from 'sbCore/Dialog/Dialog';
import Button from 'sbCore/Button/Button';

// style
import './style.scss';

/**
 * @description Allows users to delete (hide) documents
 * @param {boolean} visible - Whether or not to show the dialog
 * @param {function} onHideDeleteDialog - Callback to handle hiding delete dialog
 * @param {function} onTriggerRefreshState - Callback to handle refreshing state
 * @param {object} selectedDocumentTableContentObj - Selected document object
 * 
 * @returns
 */
function GlobalDocumentsDeleteDialog(props) {
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmitDialog() {
    setIsLoading(true);

    await updateDriverDocument(props.selectedDocumentTableContentObj.driverDocumentRecord, { isHidden: true });

    setIsLoading(false);

    props.onHideDeleteDialog();
    props.onTriggerRefreshState();
  }

  return (
    <>
      <Dialog
        className="global-documents-delete-dialog"
        header="Delete Document"
        visible={props.visible}
        onHide={props.onHideDeleteDialog}
        resizeable={false}
        draggable={false}
        footer={(
          <div className="w-full">
            <Button
              className="p-button-sm sb-delete-button"
              label="Delete"
              onClick={async () => await onSubmitDialog()}
              disabled={isLoading}
            />
            <Button
              className="p-button-sm p-button-text"
              label="Cancel"
              onClick={() => props.onHideDeleteDialog()}
            />
          </div>
        )}
      >
        {props.selectedDocumentTableContentObj && (
          <p>Are you sure you want to delete <strong>{props.selectedDocumentTableContentObj.fileName}</strong>?</p>
        )}
      </Dialog>
    </>
  );
}

export default GlobalDocumentsDeleteDialog;
