import React, { useState, useEffect } from 'react';

// Components
import Dialog from 'sbCore/Dialog/Dialog';
import Button from 'sbCore/Button/Button';
import InputLabel from 'sbCore/InputLabel/InputLabel';
import InputText from 'sbCore/InputText/InputText';

// API
import * as Setters from 'api/Setters';

// styles
import styles from './_modal.module.scss';

function ToggleSimModal({ ...props }) {
  const [imei, setImei] = useState('');
  const [imeiConfirm, setImeiConfirm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  async function handleActivate() {
    setIsLoading(true);
    if (imei !== imeiConfirm) {
      setError('imei does not match');
      setIsLoading(false);
      return;
    }
    Setters.activateSwitchboardDevice(
      imei,
    ).then(() => {
      setIsLoading(false);
      Setters.sendEmail(
        'billing@onswitchboard.com',
        'Activate/Deactivate Device by Support Tool - Request Billing Update',
        'This is to inform that the Support Tool has activated/deactivated IMEI ' + imei + '. Please update the billing accordingly.'
      );
      props.handleClose();
    }, (error) => {
      setError(error && error.message);
      setIsLoading(false);
      console.log(error);
    });
  }

  // Renders the footer of the Dialog
  const footer = (
    <div className="dispatch-accessorial-edit-dialog-footer">
      <div className="text-right">
        {!isLoading && (
          <div className="inline-block mr-3">
            <Button
              className="p-button-sm p-button-default"
              label="Cancel"
              onClick={() => props.handleClose()}
            />
          </div>
        )}
        <div className="inline-block">
          <Button
            className="p-button-sm p-button-default"
            label="Activate"
            onClick={() => handleActivate()}
            disabled={isLoading && imei.length === 15 && imei == imeiConfirm}
          />
        </div>
      </div>
    </div>
  );

  console.log('cool');
  return (
    <Dialog
      visible={props.show}
      header={`Activate Switchboard Device`}
      footer={footer}
      onHide={() => props.handleClose()}
      closable
      style={{ width: '50vw' }}
    >
      <div className={styles.inputs}>
        {/* <InputLabel>Device IMEI</InputLabel> */}
        <InputText
          className="p-inputtext-sm name"
          value={imei}
          onChange={(e) => setImei(e.target.value)}
          placeholder="Device IMEI"
        />
        <br />
        <br />

        {/* <InputLabel>Confirm Device IMEI</InputLabel> */}
        <InputText
          className="p-inputtext-sm name"
          value={imeiConfirm}
          onChange={(e) => setImeiConfirm(e.target.value)}
          placeholder="Confirm Device IMEI"
        />


        {/* <TextField
          floatingLabelText="Device IMEI"
          onChange={(event, newValue) => this.handleChangeInfo('imei', newValue)}
          value={this.state.accountInfo.imei}
        />
        <br />
        <TextField
          floatingLabelText="Confirm Device IMEI"
          onChange={(event, newValue) => this.handleChangeInfo('imeiConfirm', newValue)}
          value={this.state.accountInfo.imeiConfirm}
        /> */}
      </div>
      <div>
        {error &&
          (<div className={styles.error}>
            {error}
          </div>)
        }
      </div>
      <br />
      <div><i>This may affect your billing, please contact us at support@onswitchboard.com if you have any questions.</i></div>

    </Dialog>
  );
}

export default ToggleSimModal;
