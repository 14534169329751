import * as ActionConstants from 'actions/ActionConstants';

const initialState = {
  status: '',
  temp: {
    eldLogs: {},
  },
};

export function Temp(state = initialState, action) {
  switch (action.type) {
    case ActionConstants.DELETE_TEMP:
      return {
        ...initialState,
        status: action.type,
      };
    case ActionConstants.UPDATE_TEMP_INPROGRESS: {
      return {
        ...state,
        status: action.type,
        error: undefined,
      };
      break;
    }
    case ActionConstants.UPDATE_TEMP_SUCCESS: {
      const attribute = action.attribute;
      const value = action.value;
      const updatedTemp = Object.assign({}, state.temp); // note this does not deep clone
      updatedTemp[attribute] = value;
      return {
        ...state,
        status: action.type,
        temp: updatedTemp,
      };
      break;
    }
    case ActionConstants.UPDATE_TEMP_ERROR: {
      return {
        ...state,
        status: action.type,
        error: action.error,
      };
      break;
    }
    default:
      return state;
  }
}
