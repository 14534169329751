import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Actions
import {
  getVehiclesForState,
  deleteVehiclesForState,
  disableVehicle
} from 'actions/Vehicle'

// API
import { getQueryParameter, getSerialized, getDeserialized } from 'api/URL';
import { vehicleTypes } from 'api/Lists/EquipmentTypes'
import { getCurrentUser, getResellerCompany } from 'api/Getters';
import * as Analytics from 'api/Analytics';
import { getAttribute } from 'api/Parse';
import {
  getCompanyAnalytics,
  updateCompanyAnalytics,
} from 'api/CompanyAnalytics/CompanyAnalytics';
import { getLiveEDCsForVehicles } from 'api/Equipment/Vehicle.old';

// Components
import { Button } from 'primereact/button';
import Title from 'components/LayoutTitle/view/Title'
import FilterForm from 'components/FilterForm/container/FilterForm.new'
import FilterDropdown from 'components/FilterDropdown/view/FilterDropdown'
import AddNewVehicleButton from 'components/AddEditEquipment/container/AddNewVehicleButton'
import VehiclesTable from 'components/VehiclesTable/container/VehiclesTable'
import VehicleDiagnostics from 'components/VehicleDiagnostics/container/VehicleDiagnostics'
import GeofenceEvents from 'components/GeofenceEvents/container/GeofenceEvents'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'

// CSS
import styles from './VehiclesLayout.module.scss'
import SubNavigationBar from '../../components/Shared/SubNavigationBar/SubNavigationBar'
import StoreContext from 'contexts/StoreContext'

class VehiclesLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayFilterForm: true,
      activeItemPills: '1',
      queryPage: 0,
      isLoading: false,
      isLazyLoading: false,
      tableHeight: '55vh',
      hasLoadedAll: false,
      activeFilter: true,
      filter: [],
      sort: { unitId: 'descending' },
      selectedTab: 0,
      selectedTabView: 'vehicles',
      vehicleTypes: [
        { attrName: '0', placeholder: 'Active Vehicles', enabled: true },
        { attrName: '1', placeholder: 'Inactive Vehicles', enabled: false }
      ],
      companyAnalytics: undefined,
      resellerCompany: undefined,
      edcs: [],
    }

    this.state.filterFormFields = [
      {
        attrName: 'notes',
        fullName: 'Notes',
        placeholder: '.....',
        type: 'text'
      },
      {
        attrName: 'type',
        fullName: 'Type',
        type: 'select',
        selectArr: vehicleTypes
      },
      {
        attrName: 'unitId_lowercase',
        fullName: 'Unit ID',
        placeholder: '.....',
        type: 'text'
      }
    ]

    this.getVehicles = this.getVehicles.bind(this)
    this.disableVehicle = this.disableVehicle.bind(this)
    this.filter = this.filter.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.sortHandler = this.sortHandler.bind(this)
  }

  async componentDidMount() {
    document.title = 'Vehicles - Switchboard'

    // Check company analytics status
    const companyAnalytics = await getCompanyAnalytics();
    if (companyAnalytics) {
      const VIEWVEH = getAttribute(companyAnalytics, 'VIEWVEH');
      if (!VIEWVEH) {
        Analytics.track('VehiclesLayout Viewed');
        await updateCompanyAnalytics(companyAnalytics, { VIEWVEH: true });
      }
      this.setState({ companyAnalytics });
    }

    const resellerCompany = await getResellerCompany();
    this.setState({ ...this.state, resellerCompany });

    deleteVehiclesForState().then(() => this.getVehicles(true));
  }

  componentWillUnmount() {
    deleteVehiclesForState()
  }

  componentWillReceiveProps(nextProps) {
    let newTempView = getQueryParameter(nextProps.location.search, 't');
    this.setState({ ...this.state, selectedTabView: newTempView ? newTempView : 'vehicles' });
  }

  getVehicles = async (resetBool) => {
    if (resetBool) await this.setState({ ...this.state, queryPage: 0 });

    const {
      sort,
      queryPage,
      filter,
      isLoading,
      isLazyLoading,
      hasLoadedAll,
      activeFilter
    } = this.state;
    if (resetBool || (!isLoading && !isLazyLoading && !hasLoadedAll)) {
      let loadingState = { ...this.state, isLoading: true }
      if (queryPage > 0) {
        loadingState = { ...this.state, isLazyLoading: true }
      }
      const self = this
      this.setState(loadingState, async () => {
        getVehiclesForState(queryPage, 100, filter, sort, !activeFilter).then(
          async (vehicles) => {
            const newState = {
              ...loadingState,
              isLoading: false,
              isLazyLoading: false,
              queryPage: queryPage + 1
            }

            if (vehicles.length === 0) {
              newState.hasLoadedAll = true; // we've queried everything, so stop
            }

            try {
              const edcs = await getLiveEDCsForVehicles(this.props.Vehicle.vehicles);
              self.setState({ ...newState, edcs });
            } catch (e) {
              console.log('Error getting EDCs:');
              console.log(e);
            }
          }
        )
      })
    }
  }

  disableVehicle(confirmBool, vehicleParseObj) {
    const newState = { ...this.state }
    this.setState(newState)
    if (confirmBool) {
      disableVehicle(vehicleParseObj)
    }
  }

  filter(filterArr) {
    const newState = {
      ...this.state,
      isLoading: false,
      filter: filterArr.concat({
        name: 'enabled',
        queryType: 'equalTo',
        value: this.state.activeFilter
      }),
      queryPage: 0,
      hasLoadedAll: false
    }
    this.setState(newState, () => {
      deleteVehiclesForState().then(() => {
        this.getVehicles(true)
      })
    })
  }

  clearFilter() {
    const newState = {
      ...this.state,
      filter: [],
      isLoading: false,
      queryPage: 0,
      hasLoadedAll: false
    }
    this.setState(newState, () => {
      deleteVehiclesForState().then(() => {
        this.getVehicles(true);
      });
    })
  }

  sortHandler(attribute) {
    // sort list of vehicles based on attribute
    this.setState(
      { ...this.state, queryPage: 0, hasLoadedAll: false, isLoading: true },
      () => {
        deleteVehiclesForState().then(() => {
          const newState = { ...this.state }
          const currentSort = this.state.sort[attribute] // the current sort, if defined
          if (currentSort) {
            if (currentSort === 'ascending') {
              newState.sort[attribute] = 'descending'
            } else {
              newState.sort[attribute] = 'ascending'
            }
          } else if (attribute === 'unitId') {
            newState.sort = { unitId: 'ascending' }
          } else if (attribute === 'vendor_name') {
            newState.sort = { vendor_name: 'ascending' }
          } else if (attribute === 'user_fullName') {
            newState.sort = { user_fullName: 'ascending' }
          } else if (attribute === 'nextInspectionDate') {
            newState.sort = { nextInspectionDate: 'ascending' }
          }
          newState.isLoading = false
          this.setState(newState, () => {
            this.getVehicles(true)
          })
        })
      }
    )
  }

  async handleTabChange(value) {
    if (value == 'vehicles') {
      this.setState({
        ...this.state,
        selectedTabView: value,
        isLoading: false,
        queryPage: 0,
        hasLoadedAll: false
      });

      deleteVehiclesForState().then(() => {
        this.getVehicles(true);
      });

      return;
    }
    if (value === 'diagnostics') {
      // Check company analytics status
      const companyAnalytics = this.state.companyAnalytics;
      if (companyAnalytics) {
        const VIEWVEHDIAG = getAttribute(companyAnalytics, 'VIEWVEHDIAG');
        if (!VIEWVEHDIAG) {
          Analytics.track('VehiclesLayout-Diagnostics Viewed');
          await updateCompanyAnalytics(companyAnalytics, { VIEWVEHDIAG: true });
        }
      }
    }


    this.setState({ ...this.state, selectedTabView: value });
  }

  togglePills = tab => () => {
    if (this.state.activePills !== tab) {
      this.setState({
        activeItemPills: tab
      })
    }
  }

  render() {
    const {
      isLoading,
      isLazyLoading,
      tableHeight,
      vehicleTypes,
      displayFilterForm,
      filterFormFields,
      selectedTabView,
      sort,
    } = this.state;

    const userSpecialPermission = getCurrentUser(true).userSpecialPermission;
    const disableEquipmentAddEquipment = userSpecialPermission && userSpecialPermission.disableEquipmentAddEquipment;
    const disableEquipmentDeleteEquipment = userSpecialPermission && userSpecialPermission.disableEquipmentDeleteEquipment;

    let navItems = [
      {
        name: 'Vehicles',
        className: 'col-4',
        onClick: async () => {
          await this.handleTabChange('vehicles')
        },
        isActive: selectedTabView === 'vehicles',
        to: '?view=vehicles&t=vehicles',
      },
      {
        name: 'Diagnostics',
        className: 'col-4',
        onClick: async () => {
          await this.handleTabChange('diagnostics')
        },
        isActive: selectedTabView === 'diagnostics',
        to: '?view=vehicles&t=diagnostics',
      },
      {
        name: 'Location Reports',
        className: 'col-4',
        onClick: async () => {
          await this.handleTabChange('locationReports')
        },
        isActive: selectedTabView === 'locationReports',
        to: '?view=vehicles&t=locationReports',
      }
    ]

    return (
      <MDBContainer className={'mw-100 pb-0 ' + styles.vehiclesLayout}>
        <MDBRow>
          <MDBCol xs='12' lg='8'>
            <SubNavigationBar navItems={navItems} />
          </MDBCol>
          {!this.state.resellerCompany &&
            <MDBCol xs='12' lg='4'>
              <a
                href={'https://ca.shop.onswitchboard.com/collections/switchboard-packages/products/switchboard-enterprise-package-new'}
                target="_blank"
              >
                <Button
                  label="Need to order more devices?"
                  className="p-button-sm p-button-secondary p-button-raised translate-me"
                  icon="pi pi-external-link"

                />
              </a>
            </MDBCol>
          }
        </MDBRow>
        {selectedTabView === 'vehicles' && (
          <span>
            <Title className='translate-me' title='Vehicles'>
              <MDBRow className='d-flex flex-row-reverse'>
                <MDBCol xs='12' lg='4' className='my-auto'>
                  {!disableEquipmentAddEquipment &&
                    <AddNewVehicleButton className='float-right mx-1 translate-me' />
                  }
                  <FilterDropdown
                    id='vehiclesFilterDropdown'
                    className='float-right mx-1'
                    title='Active Vehicles'
                    handleFilter={vehicleTypeIndex => {
                      this.setState(
                        {
                          ...this.state,
                          isLoading: true,
                          activeFilter: vehicleTypes[vehicleTypeIndex].enabled,
                          queryPage: 0,
                          hasLoadedAll: false
                        },
                        () => {
                          this.filter([])
                        }
                      )
                    }}
                    menuItems={vehicleTypes}
                  />
                </MDBCol>
                <MDBCol xs='12' lg='8'>
                  {displayFilterForm && (
                    <FilterForm
                      className='translate-me'
                      handleFilter={this.filter}
                      clearFilter={this.clearFilter}
                      fields={filterFormFields}
                    />
                  )}
                </MDBCol>
              </MDBRow>
            </Title>
            <VehiclesTable
              company={this.props.Company && this.props.Company.company}
              Vehicle={this.props.Vehicle}
              edcs={this.state.edcs}
              disableVehicle={this.disableVehicle}
              deleteVehicle={this.deleteVehicle}
              sortHandler={this.sortHandler}
              handleLazyLoad={this.getVehicles}
              height={tableHeight}
              handleDiagnosticsClick={async () => await this.handleTabChange(1)}
              isLoading={isLoading}
              isLazyLoading={isLazyLoading}
              sort={sort}
            />
          </span>
        )}

        {selectedTabView === 'diagnostics' && (
          <span>
            <VehicleDiagnostics
              company={this.props.Company && this.props.Company.company}
              disableVehicle={this.disableVehicle}
              height={tableHeight}
              isLoading={isLoading}
              isLazyLoading={isLazyLoading}
            />
          </span>
        )}
        {selectedTabView === 'locationReports' && (
          <span>
            <GeofenceEvents
              company={this.props.Company && this.props.Company.company}
              height={tableHeight}
              isLoading={isLoading}
              isLazyLoading={isLazyLoading}
            />
          </span>
        )}
      </MDBContainer>
    )
  }
}

VehiclesLayout.propTypes = {
  Company: PropTypes.object,
  Vehicle: PropTypes.object.isRequired
}

const mapStateToProps = state => {
  const { Company, Vehicle } = state
  return {
    Company,
    Vehicle
  }
}

export default connect(mapStateToProps, null, null, { context: StoreContext })(VehiclesLayout);

