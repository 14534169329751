import React from 'react';

import { Accordion as PRAccordion } from 'primereact/accordion';

import './style.scss';

export default function Accordion({ ...props }) {
  let className = 'sbc-accordion';
  if (props.className) className += ` ${props.className}`;

  return (<PRAccordion { ...props } className={className} />);
}
