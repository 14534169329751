import React, { useState, useEffect } from 'react';

// CSAPI
import { getAttribute, getCurrentUser } from 'sb-csapi/dist/AAPI';
import { formatName } from 'sb-csapi/dist/utils/String';

// enums
import { Action } from 'sb-csapi/dist/enums/Action';
import { isUserTypeHigherRank, AdminTypes } from 'enums/UserTypes';

// sbCore
import AddEditModal from 'sbCore/AddEditModal/AddEditModal';
import Autocomplete from 'sbCore/Autocomplete/Autocomplete';
import { ConfirmPopup, confirmPopupSettings } from 'sbCore/ConfirmPopup/ConfirmPopup';
import Message from 'sbCore/Message/Message';

// API
import { updateUserType } from 'api/User/UserType';

// styles
import './style.scss';

/**
 * @description Modal pop-up to change user admin type
 *
 * @param {Boolean} isOpen
 * @param {Object} user - user record
 * @param {Function} handleOnClose - Callback function on modal close
 *
 * @returns
 */

function EditUserTypeModal({ ...props }) {
  const { isOpen, user } = props;
  const currentUser = getCurrentUser();
  const currentUserTypes = getAttribute(currentUser, 'userType');
  const userTypes = getAttribute(user, 'userType');
  const mainAdminType = AdminTypes.ADMIN.type;
  const isUserHigherRank = isUserTypeHigherRank(userTypes, currentUserTypes, true);
  const isCurrentUserAdmin = currentUserTypes.indexOf(mainAdminType) !== -1;
  const isCurrentUserAdminLimited = currentUserTypes.indexOf(7) !== -1;

  // states
  const [previousNonAdminUserTypes, setPreviousNonAdminUserTypes] = useState([]);
  const [selectedUserTypes, setSelectedUserTypes] = useState(userTypes);
  const [selectedUserTypeDescriptions, setSelectedUserTypeDescriptions] = useState([]);
  const [suggestedUserTypeDescriptions, setSuggestedUserTypeDescriptions] = useState([]);
  const [showAdminTransferWarningMessage, setShowAdminTransferWarningMessage] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  // Maps to change from user type (number) and user description (string)
  const adminTypeToDescriptionMap = Object.values(AdminTypes).reduce((previousAdminTypesMap, adminType) => ({ ...previousAdminTypesMap, [adminType.type]: adminType.description }), {});
  const adminDescriptionToTypeMap = Object.values(AdminTypes).reduce((previousAdminTypesMap, adminType) => ({ ...previousAdminTypesMap, [adminType.description]: adminType.type }), {} );

  // Handle methods
  async function handleConfirmButtonClick() {
    const _currentUserTypes = [...currentUserTypes];
    _currentUserTypes.splice(_currentUserTypes.indexOf(AdminTypes.ADMIN.type), 1);
    if (!isCurrentUserAdminLimited) _currentUserTypes.push(7);
    await updateUserType(getAttribute(currentUser, 'objectId'), _currentUserTypes);
    await updateUserType(getAttribute(user, 'objectId'), selectedUserTypes);
    window.location.reload(); // refresh as current user type changes (from admin to admin-limited)
  }

  async function handleEdit(actionType) {
    selectedUserTypes.sort();
    userTypes.sort();
    const unchangedUserTypes = selectedUserTypes.length === userTypes.length && selectedUserTypes.every((selectedUserType, i) => selectedUserType === userTypes[i] );
    if (actionType && !unchangedUserTypes) {
      if (showAdminTransferWarningMessage) {
        return (
          confirmPopupSettings({
            message: 'Are you sure you want to proceed?',
            icon: 'pi pi-exclamation-triangle',
            acceptClassName: 'p-button-danger',
            accept: () => handleConfirmButtonClick(),
          })
        );
      }
      await updateUserType(getAttribute(user, 'objectId'), selectedUserTypes);
    }
    props.handleOnClose();
  }

  async function handleOnSuggestionSearch(query) {
    const _query = (query || '').trim();
    const _suggestedUserTypes = Object.keys(adminTypeToDescriptionMap).filter((userType) => !selectedUserTypes.includes(Number(userType)));
    if (!isCurrentUserAdmin) {
      _suggestedUserTypes.splice(_suggestedUserTypes.indexOf(String(mainAdminType)), 1);
    } // check if current user can give Admin status
    const _suggestedUserTypeDescriptions = _suggestedUserTypes.map((userType) => (adminTypeToDescriptionMap[userType]));
    setSuggestedUserTypeDescriptions(_suggestedUserTypeDescriptions.filter((userType) => userType.includes(_query)));
  }

  async function handleInput(inputUserTypeDescriptions) {
    setSelectedUserTypeDescriptions(inputUserTypeDescriptions);
    const _selectedUserTypes = inputUserTypeDescriptions.map((inputUserTypeDescription) => adminDescriptionToTypeMap[inputUserTypeDescription]);
    setSelectedUserTypes(_selectedUserTypes.concat(previousNonAdminUserTypes));
    setShowAdminTransferWarningMessage(false);
    if (userTypes.indexOf(mainAdminType) === -1 && inputUserTypeDescriptions.indexOf(adminTypeToDescriptionMap[mainAdminType]) !== -1) {
      setShowAdminTransferWarningMessage(true);
    } // check if transferring admin status
  }

  // UseEffect to set current user types and save non-admin user types the user has
  useEffect(() => {
    const _previousNonAdminUserTypes = userTypes.filter((userType) => !(userType in adminTypeToDescriptionMap));
    setPreviousNonAdminUserTypes(_previousNonAdminUserTypes);
    const _selectedUserTypeDescriptions = userTypes.map((userType) => adminTypeToDescriptionMap[userType]).filter((userType) => userType);
    setSelectedUserTypeDescriptions(_selectedUserTypeDescriptions);
  }, []);

  // UseEffect for visual updates and disabling/enabling save changed button
  useEffect(() => {
    selectedUserTypes.sort();
    userTypes.sort();
    setHasChanged(false);
    const unchangedUserTypes = selectedUserTypes.length === userTypes.length && selectedUserTypes.every((selectedUserType, i) => selectedUserType === userTypes[i] );
    if (!unchangedUserTypes) setHasChanged(true);
  }, [showAdminTransferWarningMessage, selectedUserTypeDescriptions, suggestedUserTypeDescriptions]);

  const headerTemplate = (
    <div>
      User Type - {formatName(`${getAttribute(user, 'firstName')} ${formatName(getAttribute(user, 'lastName'))}`)}
    </div>
  );

  return (
    <AddEditModal
      isOpen={isOpen}
      action={Action.EDIT}
      headerTemplate={headerTemplate}
      onUpdate={() => handleEdit(Action.EDIT)}
      onCancel={() => handleEdit()}
      disableUpdateButton={selectedUserTypeDescriptions.length < 1 || isUserHigherRank || !hasChanged} // disable if nothing has changed
      disableDeleteButton
      hideDeleteButton
    >
      <div>Type</div>
      <Autocomplete
        placeholder="User Type"
        value={selectedUserTypeDescriptions}
        suggestions={suggestedUserTypeDescriptions}
        dropdown
        multiple
        completeMethod={({ originalEvent, query }) => handleOnSuggestionSearch(query)}
        onChange={(e) => handleInput(e.value)}
        disabled={isUserHigherRank}
      />
      {showAdminTransferWarningMessage &&
        <Message severity="warn" text="This will revoke your status as the Main Administrator to Administrator (Limited)" />
      }
      {isUserHigherRank &&
        <Message classname="w-auto" severity="error" text="You do not have permission to edit this user" />
      }
      <ConfirmPopup />
    </AddEditModal>
  );
}

export default EditUserTypeModal;
